import React from "react";

import { MonetizationPlansType } from "shared/types";
import { Box, Text } from "shared/ui-kit";

import { IllustrationCard, MonetizeCardContainer, StyledBody, StyledBtn, Title } from "./style";

interface MonetizationPlanCardProps {
	plan: MonetizationPlansType;

	fullMode?: boolean;
	onSelect: () => void;
	buttonId?: string;
}

const MonetizationPlanCard: React.FC<MonetizationPlanCardProps> = ({ plan, fullMode, onSelect, buttonId }) => (
	<MonetizeCardContainer disabled={plan.disabled} fullMode={fullMode}>
		<div className="send-button-down">
			{plan.selected && (
				<Box className="current-method">
					<Text>Current method</Text>
				</Box>
			)}
			<IllustrationCard src={plan.image} />
			<Title>{plan.name}</Title>
			<StyledBody>{plan.description}</StyledBody>
		</div>
		<StyledBtn
			className="chooseMethodBtn"
			palette="basic"
			disabled={plan.disabled}
			buttonTheme="light"
			onClick={onSelect}
			id={buttonId}
		>
			{plan.selected ? <>Edit Current Method</> : plan.disabled ? <>Unavailable</> : <> Choose Method</>}
		</StyledBtn>
	</MonetizeCardContainer>
);

export default MonetizationPlanCard;
