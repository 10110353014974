import { useMemo } from "react";

import useHttpActions from "shared/services/api/core/useHttpActions";

import BaseConverter from "shared/services/converters/baseConverter";

import { NotificationsListConvertor } from "./convertors";

import { API_URLS } from "../../constants";
import { EngageNotificationsModel } from "../types";
import {
	EngageNotificationAPiResponse,
	EngageNotificationModel,
	getEngageNotificationsDataType
} from "../types/EngageNotificationModel";

const useNotificationApiService = () => {
	const actions = useHttpActions();

	return useMemo(
		() => ({
			getNotifications: async (data: getEngageNotificationsDataType): Promise<EngageNotificationsModel[]> => {
				return NotificationsListConvertor.convertNotificationsResponse(
					await actions.get(API_URLS.NOTIFICATION.GET, data)
				);
			},

			createNotification: async (data: EngageNotificationModel): Promise<EngageNotificationAPiResponse> => {
				return BaseConverter.convert(await actions.post(API_URLS.NOTIFICATION.CREATE, data));
			},

			updateNotification: async (
				notificationId: string,
				data: EngageNotificationModel
			): Promise<EngageNotificationAPiResponse> => {
				return BaseConverter.convert(await actions.put(`${API_URLS.NOTIFICATION.UPDATE}/${notificationId}`, data));
			},

			deleteNotification: async (notificationId: string) => {
				return BaseConverter.convert(await actions.delete(`${API_URLS.NOTIFICATION.DELETE}/${notificationId}`));
			},

			getTotalNotificationsCount: async (data): Promise<{ totalCount: number }> => {
				return BaseConverter.convert(await actions.get(API_URLS.NOTIFICATION.COUNT, data));
			}
		}),
		[actions]
	);
};

export default useNotificationApiService;
