import * as R from "ramda";

import { WorkspaceProductModel } from "shared/types";

export const convertCreatedSubscriptionPlansToJoinedPlan = (
	products: WorkspaceProductModel[]
): WorkspaceProductModel => {
	const createdProduct = products[0];
	const monthlyProduct = products.find(x => x.interval === "monthly");
	const annuallyProduct = products.find(x => x.interval === "annually");

	return {
		...R.pick(
			["name", "description", "freeTrailDays", "freeTrailEnabled", "type", "subscriptionId", "activeAt"],
			createdProduct
		),
		annually: {
			_id: annuallyProduct?._id,
			active: annuallyProduct?.active,
			price: annuallyProduct!.price,
			stripePriceId: annuallyProduct!.stripePriceId,
			stripeProductId: annuallyProduct!.stripeProductId,
			totalSubscribers: annuallyProduct!.totalSubscribers
		},
		monthly: {
			_id: monthlyProduct?._id,
			active: monthlyProduct?.active,
			price: monthlyProduct!.price,
			stripePriceId: monthlyProduct!.stripePriceId,
			stripeProductId: monthlyProduct!.stripeProductId,
			totalSubscribers: monthlyProduct!.totalSubscribers
		}
	};
};
