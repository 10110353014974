import React, { useCallback, useMemo } from "react";

import { DateTime } from "luxon";
import styled, { css } from "styled-components";

import { useAnalytics } from "shared/hooks";

import { StatsItemKey } from "shared/types";
import { Box } from "shared/ui-kit";

import BlockHeader from "../BlockHeader";
import NoDataFound from "../NoDataFound";
import { SingleLineChart } from "../index";

const Wrapper = styled(Box)`
	display: flex;
	flex-direction: column;

	width: 100%;
`;

const BodyWrapper = styled(Box)<{ centeredContent: boolean }>`
	min-height: 200px;
	${({ centeredContent }) => {
		if (centeredContent) {
			return css`
				align-items: center;
				justify-content: center;
				display: flex;
			`;
		}
	}}
`;

const SignUps: React.FC = () => {
	const { getSignUps, getSignUpsCSVFile, getData: getAnalyticsData } = useAnalytics();
	const { totalSignUps, signUpsData } = getAnalyticsData();

	const changePeriod = useCallback(
		(requestedFrom: DateTime, requestedTo: DateTime) => {
			getSignUps(requestedFrom, requestedTo);
		},
		[getSignUps]
	);

	const Graph = useMemo(
		() => (
			<SingleLineChart
				data={signUpsData}
				dataKey={StatsItemKey.signups}
				gradientId={"signUpsGradientId"}
				color={"#00acff"}
				stopColor={"rgba(0, 172, 255, 0)"}
			/>
		),
		[signUpsData]
	);

	return (
		<Wrapper>
			<BlockHeader
				title={totalSignUps ? totalSignUps.toString() : "0"}
				subtitle={"Signups"}
				showDropdown
				onChangePeriod={changePeriod}
				onDownloadCsv={getSignUpsCSVFile}
				csvFilenamePrefix="signups_report"
			/>
			<BodyWrapper centeredContent={!signUpsData.length}>
				{signUpsData.length > 0 ? <>{Graph}</> : <NoDataFound />}
			</BodyWrapper>
		</Wrapper>
	);
};

export default SignUps;
