import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";

import { DateTime } from "luxon";

import "dz-daterangepicker-material/dist/index.css";

import { PageTemplate } from "modules/Manage/View/Components";
import { useTransactions } from "modules/Payments/Data";

import { DateRangeModel, DateRangeSelector, PlaceholderImage, SmartInput, TableWrapper } from "shared/Components";
import { TableColumnLoaderType } from "shared/Components/NewTable/Components";
import { Cell } from "shared/Components/NewTable/style";
import { useCommunity, useDebounce } from "shared/hooks";
import { PlaceholderImageType, ProfilePhotoSizes, TransactionModel } from "shared/types";
import { Button, Icon } from "shared/ui-kit";

import { getResizedImage } from "utils/serviceUtils/cdnImages";
import { convertToDecimal } from "utils/serviceUtils/helpers";

const Table = React.lazy(() => import("shared/Components/NewTable"));

import { StyledIcon } from "./style";

const dateFormat = "yyyy-MM-dd";

const Transactions: React.FC = memo(() => {
	const {
		getTransactions,
		getTransactionsCount,
		setPage,
		setTransactionShowPerPage,
		saveCSV,
		getData: getTransactionsData
	} = useTransactions();
	const { transactions, page, transactionShowPerPage, totalCount, loading } = getTransactionsData();

	const { getData: getCommunityData } = useCommunity();
	const { workspace } = getCommunityData();

	const [searchText, setSearchText] = useState<string>("");
	const [dateRange, setDateRange] = useState<{ startDate?: Date; endDate?: Date }>({});
	const [dateFilter, setDateFilter] = useState("allTime");
	const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);

	const selectWrapperRef = useRef<HTMLDivElement | null>(null);

	const openDatepicker = useCallback(() => {
		if (selectWrapperRef.current) {
			setAnchorEl(selectWrapperRef.current);
		}
	}, []);

	const closeDatepicker = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);

	const debouncedSearchText = useDebounce(searchText, 400);

	const filterOptions = useMemo(
		() => [
			{ label: "All Time", value: "allTime" },
			{ label: "Today", value: "today" },
			{ label: "This Week", value: "thisWeek" },
			{ label: "This Month", value: "thisMonth" },
			{ label: "Date Range", value: "dateRange", onClick: () => openDatepicker() }
		],
		[openDatepicker]
	);

	const onChange = useCallback((range?: DateRangeModel) => {
		setDateRange(
			!!range
				? {
						startDate: range.startDate!.toJSDate(),
						endDate: range.endDate!.toJSDate()
				  }
				: {}
		);
		closeDatepicker();
	}, []);

	useEffect(() => {
		const startDate = dateRange.startDate && DateTime.fromJSDate(dateRange.startDate).toFormat(dateFormat);
		const endDate = dateRange.endDate && DateTime.fromJSDate(dateRange.endDate).toFormat(dateFormat);

		getTransactions({
			keyword: debouncedSearchText,
			offset: page,
			limit: transactionShowPerPage,
			startDate,
			endDate
		});
	}, [getTransactions, page, debouncedSearchText, transactionShowPerPage, dateRange]);

	useEffect(() => {
		const startDate = dateRange.startDate && DateTime.fromJSDate(dateRange.startDate).toFormat(dateFormat);
		const endDate = dateRange.endDate && DateTime.fromJSDate(dateRange.endDate).toFormat(dateFormat);

		getTransactionsCount({ keyword: debouncedSearchText, startDate, endDate });
	}, [debouncedSearchText, getTransactionsCount, dateRange]);

	const handleDateFilter = (optionVal: string) => {
		let time;
		const today = DateTime.local();

		setDateFilter(optionVal);

		switch (optionVal) {
			case "today":
				time = today;
				break;
			case "thisWeek":
				time = DateTime.local().minus({ week: 1 });
				break;
			case "thisMonth":
				time = DateTime.local().minus({ month: 1 });
				break;
			case "dateRange":
				time = null;
				break;
			default:
				return;
		}

		time && onChange({ startDate: time, endDate: today });
	};

	const handleSearch = useCallback(
		(val: string) => {
			const keyword = val;
			if (keyword === "" || keyword.length > 2) {
				setSearchText(keyword);
				setPage(1);
			}
		},
		[setPage]
	);

	const coinValue = useMemo(() => workspace?.coinValue || 0.99, [workspace]);

	const tableColumns = useMemo(
		() => [
			{
				alignment: "left",
				minWidth: 200,
				label: <Cell.HeaderText>Community Member</Cell.HeaderText>,
				Cell: ({ rowData: { firstName, lastName, photos, email } }: { rowData: TransactionModel }) => {
					const icon = photos?.length ? photos[0]?.profilePicture : undefined;
					const coverImgUrl = !!icon ? getResizedImage(icon, ProfilePhotoSizes.size200x200) : undefined;

					return (
						<Cell.Wrapper>
							<Cell.ImageWrapper>
								{coverImgUrl ? (
									<Cell.Image src={coverImgUrl} />
								) : (
									<PlaceholderImage
										type={PlaceholderImageType.PROFILE_IMAGE}
										width={40}
										height={40}
										viewBox={"0 0 400 400"}
									/>
								)}
							</Cell.ImageWrapper>
							<Cell.Wrapper className="column with-image">
								<Cell.Text>
									{firstName} {lastName}
								</Cell.Text>
								<Cell.Text className="light">{email ?? ""}</Cell.Text>
							</Cell.Wrapper>
						</Cell.Wrapper>
					);
				},
				loaderTemplate: TableColumnLoaderType.imageWthOneTextRow,
				dataKey: "cid"
			},
			{
				alignment: "left",
				minWidth: 130,
				label: <Cell.HeaderText>Date</Cell.HeaderText>,
				Cell: ({ rowData: { purchaseDate } }: { rowData: TransactionModel }) => (
					<Cell.Wrapper>
						<Cell.Text>{DateTime.fromISO(purchaseDate).toFormat("MMM d, yyyy")}</Cell.Text>
					</Cell.Wrapper>
				),
				dataKey: "cid"
			},
			{
				alignment: "left",
				minWidth: 150,
				label: <Cell.HeaderText>Type</Cell.HeaderText>,
				Cell: ({ rowData: { purpose } }: { rowData: TransactionModel }) => (
					<Cell.Wrapper className="column">
						<Cell.Text>{purpose.heading}</Cell.Text>
						<Cell.Text className="light">{purpose.desc}</Cell.Text>
					</Cell.Wrapper>
				),
				loaderTemplate: TableColumnLoaderType.twoTextRows,
				dataKey: "purpose"
			},
			{
				alignment: "left",
				label: <Cell.HeaderText>Amount</Cell.HeaderText>,
				Cell: ({ rowData: { amountPaid, receipt } }: { rowData: TransactionModel }) => (
					<Cell.Wrapper className="column">
						<Cell.Text className="bold">
							{amountPaid > 0
								? `$${convertToDecimal(amountPaid)}`
								: receipt.coins > 0
								? `$${receipt.coins * coinValue}`
								: "-"}
						</Cell.Text>
						{receipt.coins > 0 && <Cell.Text className="light">{receipt.coins} Coins</Cell.Text>}
					</Cell.Wrapper>
				),
				loaderTemplate: TableColumnLoaderType.oneTextRow,
				dataKey: "cid"
			},
			{
				alignment: "left",
				minWidth: 150,
				label: <Cell.HeaderText>Status</Cell.HeaderText>,
				Cell: ({ rowData: { status } }: { rowData: TransactionModel }) => (
					<Cell.Wrapper>
						<Icon
							group={"filled"}
							fill={status === "pending" ? "#4a99f9" : status === "success" ? "#23b34a" : "#db2c33"}
							name={status === "pending" ? "clock" : status === "success" ? "check-mark" : "close"}
						/>
						<Cell.Text>{status}</Cell.Text>
					</Cell.Wrapper>
				),
				dataKey: "cid"
			}
		],
		[coinValue]
	);

	const handleChangePage = useCallback(
		(newPage: number) => {
			setPage(newPage);
		},
		[setPage]
	);

	const handleChangePageSize = useCallback(
		(newPageSize: number) => {
			setTransactionShowPerPage(newPageSize);
			setPage(1);
		},
		[setTransactionShowPerPage, setPage]
	);

	const TableBlock = useMemo(
		() => (
			<TableWrapper sizes={{ horizontalTablet: 1350 }}>
				<Table
					columns={tableColumns}
					data={transactions || []}
					loading={loading}
					paginated
					totalDataCount={totalCount}
					page={page}
					pageSize={transactionShowPerPage}
					onChangePage={handleChangePage}
					onChangePageSize={handleChangePageSize}
				/>
			</TableWrapper>
		),
		[
			tableColumns,
			transactions,
			loading,
			totalCount,
			page,
			transactionShowPerPage,
			handleChangePage,
			handleChangePageSize
		]
	);

	const customSelectedRender = useCallback(
		(option: any) => {
			if (option?.value === "dateRange" && dateRange) {
				const startPeriod = DateTime.fromJSDate(dateRange.startDate!);
				const endPeriod = DateTime.fromJSDate(dateRange.endDate!);
				const format = "LLL d";
				return `${startPeriod.toFormat(format)} - ${endPeriod.toFormat(format)}`;
			}
			return option?.label;
		},
		[dateRange]
	);

	return (
		<>
			<PageTemplate
				title={"Payment Transactions"}
				isLoading={loading}
				isNoData={!transactions || !transactions?.length}
				emptyText={"You don’t have any Transactions in your community yet."}
				searchPlaceholder={"Search Transaction"}
				onSearchUpdate={handleSearch}
				extraAction={
					<div className="flex items-center gap-4 w-max">
						<div ref={selectWrapperRef}>
							<SmartInput
								dropdown
								id={"period-selector-id"}
								placeholder="Select"
								value={dateFilter}
								options={filterOptions}
								onChange={handleDateFilter}
								customSelectedRender={customSelectedRender}
							/>
						</div>
						<DateRangeSelector
							open={open}
							anchorEl={anchorEl}
							onClose={closeDatepicker}
							onChange={onChange}
							minDate={workspace?.createdAt}
							maxDate={DateTime.local().toJSDate()}
						/>
						<Button
							removeSideMargin
							buttonTheme={"light"}
							palette={"success"}
							onClick={() => saveCSV(searchText, dateRange.startDate, dateRange.endDate)}
							leftIcon={<StyledIcon group={"color"} name={"app-excel"} />}
						>
							Download Excel
						</Button>
					</div>
				}
			>
				{TableBlock}
			</PageTemplate>
		</>
	);
});

export default Transactions;
