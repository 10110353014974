import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { EventType, UserEventType } from "types";

import { useEvent, useOnScreen } from "shared/hooks";

import { EventModel } from "shared/types";
import { Text } from "shared/ui-kit";

import LoadingItems from "../LoadingItems";
import EventBlock from "../blocks/EventBlock";

interface Props {
	searchTerm: string;
	active?: boolean;
	selectedEvent: EventModel | undefined;
	setSelectedEvent: React.Dispatch<React.SetStateAction<EventModel | undefined>>;
}

const EventsTab: React.FC<Props> = ({ searchTerm, active, selectedEvent, setSelectedEvent }) => {
	const [filteredEvents, setFilteredEvents] = useState<EventModel[]>([]);
	const [eventCount, setEventCount] = useState(0);
	const { getEvents, getData: getEventData } = useEvent();
	const { loading } = getEventData();

	const lastEventRef = useRef<HTMLDivElement>(null);
	const [page, setPage] = useState(1);

	const onScreen = useOnScreen(lastEventRef);

	const hasMoreData = useMemo(() => eventCount > filteredEvents.length, [filteredEvents.length, eventCount]);

	const handleToggleEvent = useCallback(
		(event: EventModel) => {
			setSelectedEvent(event);
		},
		[setSelectedEvent]
	);

	useEffect(() => {
		if (onScreen && hasMoreData) {
			setPage(state => state + 1);
		}
	}, [hasMoreData, onScreen]);

	useEffect(() => {
		if (!!searchTerm.length) setPage(1);
	}, [setPage, searchTerm.length]);

	const getEventsHandler = useCallback(async () => {
		if (active) {
			const { events, totalEventsFound } = await getEvents({
				keyword: searchTerm,
				limit: 10,
				page: !!searchTerm.length ? 1 : page,
				eventType: "myevents" as UserEventType,
				eventSchedule: EventType.All,
				startDate: undefined,
				endDate: undefined
			});
			setFilteredEvents(prevEvents => (!!searchTerm.length || page === 1 ? events : [...prevEvents, ...events]));
			setEventCount(totalEventsFound);
		}
	}, [active, getEvents, page, searchTerm, setFilteredEvents, setEventCount]);

	useEffect(() => {
		getEventsHandler();
	}, [getEventsHandler]);

	return (
		<>
			{!filteredEvents?.length && !loading && <Text variant="h5">No Results</Text>}
			{loading && !filteredEvents?.length ? (
				<LoadingItems />
			) : (
				!!filteredEvents?.length &&
				filteredEvents?.map(event => (
					<EventBlock
						handleToggleEvent={handleToggleEvent}
						key={event.eventId}
						event={event}
						selectedEvent={selectedEvent}
						lastEventRef={lastEventRef}
					/>
				))
			)}
		</>
	);
};

export default EventsTab;
