import config from "config/appConfig";
import { ReportContextValuesType } from "types";

import { IStateProps } from "utils/StateOf";

import createStore from "utils/createStore";

const localStorageKey = `${config.GLOBAL_CONSTANTS.LOCAL_STORAGE_PREFIX}${config.HOOK_STORAGE_KEYS.REPORT_DATA}`;

export const initialState: IStateProps<ReportContextValuesType> = {
	totalCount: 0,
	page: 1,
	reportShowPerPage: 5,
	loading: false
};

export default createStore<ReportContextValuesType>({
	initialState,
	persistant: true,
	persistantName: localStorageKey
});
