import { ButtonBase, MenuItem, Select, TextField, styled as muiStyled } from "@material-ui/core";

import { KeyboardDatePicker } from "@material-ui/pickers";
import styled, { css } from "styled-components";

import { ReactComponent as ChevronIcon } from "assets/icons/icon-chevron-down.svg";

import { Text } from "shared/ui-kit";
import colors from "theme/colors";

const MuiInputLabelStyles = css`
	// color: colors.palette.colors.hint};

	&.Mui-focused {
		// color: colors.palette.colors.primary[500]};
	}

	&.Mui-error {
		// color: colors.palette.colors.red[600]};
	}

	&.Mui-disabled {
		// color: colors.palette.colors.inactive};
	}
`;

const MuiOutlinedInputStyles = css`
	font-size: 1rem;
	line-height: 1.5rem;
	border-radius: 8px;

	textarea,
	input {
		color: ${colors.palette.colors.gray["700"]};

		&::placeholder {
			font-size: 1rem;
			line-height: 1.5rem;
			color: ${colors.palette.colors.gray["350"]};
			opacity: 1;
		}
	}

	&.Mui-disabled {
		input {
			cursor: not-allowed;
		}
	}

	&.Mui-focused {
		fieldset,
		input {
			border-width: 1px;
		}
	}
`;

const baseStyles = css`
	width: 100%;

	border-radius: 8px;

	.MuiFormLabel-root {
		line-height: 0.5;

		&.MuiInputLabel-shrink {
			line-height: 1.5;
		}
	}

	.MuiInputLabel-root {
		${MuiInputLabelStyles}
	}

	.MuiOutlinedInput-root {
		${MuiOutlinedInputStyles}
	}

	&.size {
		&_small {
			.MuiInputBase-root {
				height: 40px;
			}
		}

		&_medium {
			.MuiInputBase-root {
				height: 48px;
			}
		}
	}
`;

export const InputWrapper = styled("div")`
	position: relative;

	&.should-style {
		// border: 1px solid colors.palette.colors.gray[200];
	}

	.MuiFormHelperText-root {
		position: absolute;
		top: calc(100% + 4px);
		left: 0;
		font-size: 12px;

		margin: 0;
	}
`;

export const UIKitInput = styled(TextField)`
	${baseStyles};

	&.hideBorders {
		.MuiOutlinedInput-root fieldset,
		&:hover .MuiOutlinedInput-root fieldset {
			border: 0;
		}
	}

	&.gray-bg {
		background-color: #f7f9fc;
	}

	&.narrow {
		width: 75%;

		.MuiInputBase-root {
			height: 100%;
		}

		input {
			font-size: 13px;
			font-weight: 600;
			padding: 7px 8px;

			&::placeholder {
				font-size: 13px;
			}
		}
	}

	&.cursor-pointer {
		div,
		input {
			cursor: pointer;
		}

		.MuiOutlinedInput-notchedOutline {
			top: -5px;
		}
	}
`;

export const UIKitDropdown = styled(Select)`
	border-radius: 8px;

	&.size {
		&_small {
			height: 40px;
		}

		&_medium {
			height: 48px;
		}

		&_large {
			height: 88px;
		}
	}

	&.MuiOutlinedInput-root {
		// ${MuiOutlinedInputStyles}
	}

	.MuiPaper-root {
		border-radius: 20px;
	}

	&:hover {
		svg.member-styles {
			path:last-child {
				fill: #222b45;
			}
		}
	}
`;

export const UIKitDropdownPaperStyles = {
	borderRadius: "12px",
	boxShadow: "0px 0px 12px rgba(0, 0, 0, 0.1)",
	border: "1px solid rgba(220, 229, 245, 0.5)",
	padding: "2px 8px"
};

export const StyledMenuItem = styled(MenuItem)`
	border-radius: 8px;

	padding: 10px 8px;

	&:hover {
		background-color: ${colors.palette.colors.gray["300"]};
		&.member-styles {
			background-color: #edf1f7;
		}
	}
`;

export const StyledChevronIcon = styled(ChevronIcon)`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	top: 0;

	width: 20px;

	transition: transform 0.15s ease-in-out;

	&.member-styles {
		width: 16px;
		path:last-child {
			fill: #c5cee0;
		}
	}

	&.MuiSelect-iconOpen {
		transform: rotate(-180deg) !important;
	}
`;

export const InfoText = styled(Text)`
	pointer-events: none;
	&.styles_default {
		color: ${colors.palette.colors.gray["300"]};
	}
`;

export const UiKitDropdownOptionPaper = styled("div")`
	${UIKitDropdownPaperStyles};

	z-index: 10000;
	max-height: 200px;
	overflow-y: scroll;

	background-color: white;
`;

export const UIKitDateInput = muiStyled(KeyboardDatePicker)(() => ({
	"border-radius": "8px"
}));

export const Headline = styled(Text)`
	font-size: 13px;
	font-weight: 600;
	line-height: 1.85;
	color: #222b45;
`;

export const Counter = styled(Text)`
	position: absolute;
	top: calc(100% + 4px);
	right: 0;
	font-size: 12px;
	color: ${colors.palette.colors.gray["300"]};

	margin: 0;
`;

export const TimepickerWrapper = styled("div")`
	&.disabled {
		opacity: 0.32;

		.MuiButtonBase-root {
			pointer-events: none;
		}
	}
`;

export const TimepickerIconBase = styled(ButtonBase)`
	padding: 8px;

	border-radius: 50%;

	margin-right: 4px;

	&:hover {
		background-color: rgba(0, 0, 0, 0.04);
	}
`;
