import React, { useCallback, useEffect, useMemo, useState } from "react";

import { useMediaQuery } from "@material-ui/core";

import { useHistory, useLocation } from "react-router-dom";

import { CreateUpdateSeriesWrapper } from "shared/Components";
import { useGroup, useSeries } from "shared/hooks";
import { GroupModel } from "shared/types";
import { GroupSortBy, groupType } from "shared/types/GroupModel";
import * as appTheme from "theme/default";

import { capitalizeFirstLetter } from "utils/capitaliseFirstLetter";

import { updateUrlSearchParams } from "utils/updateUrlSearchParams";

import { FilterBlock, FilterOptionType, ModelBlock, PageWrapper } from "../../Components";
import { ModelBlockType } from "../../Components/ModelBlock";
import { ManInLotus, MessageText, NoConnectionsBlock, StyledButton } from "../Connections/style";

const defaultFilters = {
	limit: 20,
	keyword: ""
};

export interface CollectionsPageProps {
	sortBy?: GroupSortBy;
	groupType: groupType;
}

const AllCollectionsPage: React.FC<CollectionsPageProps> = ({ sortBy, groupType }) => {
	const { getGroups, getData: getGroupData, getTotalGroupsCount } = useGroup();
	const { loading, totalFoundGroups = 0 } = getGroupData();
	const { search } = useLocation();
	const history = useHistory();

	const { isUserCouldManageSeries, setSeriesDialog } = useSeries();

	const [offset, setOffset] = useState(1);
	const [groups, setGroups] = useState<GroupModel[]>([]);
	const [filters, setFilters] = useState<{
		sortBy?: GroupSortBy;
		groupType: groupType;
		isMyGroup: boolean;
	}>({
		sortBy: sortBy,
		groupType: groupType,
		isMyGroup: false
	});

	const hasMoreItems = useMemo(() => totalFoundGroups > groups.length, [totalFoundGroups, groups.length]);
	const isMobile = useMediaQuery(appTheme.default.breakpoints.down("sm"));

	useEffect(() => {
		getGroups({
			...defaultFilters,
			isMyGroups: filters.isMyGroup || false,
			sortBy: filters.sortBy || GroupSortBy.NEWEST,
			groupType: filters.groupType,
			page: offset
		}).then(({ groups: groupsData }) => {
			setGroups(ctx => (offset === 1 ? groupsData : [...ctx, ...groupsData]));
		});
	}, [getGroups, offset, filters]);

	useEffect(() => {
		getTotalGroupsCount({
			isMyGroups: filters.isMyGroup || false,
			sortBy: filters.sortBy || GroupSortBy.NEWEST,
			groupType: filters.groupType
		});
	}, [getTotalGroupsCount, filters]);

	const onEndScroll = useCallback(() => {
		if (hasMoreItems) {
			setOffset(offset => offset + 1);
		}
	}, [hasMoreItems]);

	const createSeries = () => {
		setSeriesDialog({ open: true });
	};

	const applyFilters = useCallback(
		(name: string, item?: string) => {
			const query = updateUrlSearchParams(name, item, search);
			history.replace(`?${query.toString()}`);
		},
		[history, search]
	);

	const groupTypeClickHandler = useCallback(
		item => {
			setFilters(ctx => ({
				...ctx,
				groupType: typeof item === "string" && item ? (((item || "All") as string)?.toLowerCase() as groupType) : "all"
			}));
			setOffset(1);

			applyFilters("groupType", typeof item === "string" && item !== "all" ? item : undefined);
		},
		[setFilters, applyFilters]
	);

	const filterOptions: FilterOptionType[] = useMemo(
		() => [
			{
				label: "Sort By",
				inlineOptions: false,
				onClick: item => {
					const _item = typeof item === "string" && item ? (item as GroupSortBy)?.toUpperCase() : undefined;
					setFilters(ctx => ({ ...ctx, sortBy: GroupSortBy[_item as GroupSortBy] }));
					setOffset(1);

					applyFilters("sortBy", typeof item === "string" && item ? item : undefined);
				},
				listOptions: Object.keys(GroupSortBy)
					.filter(a => GroupSortBy[a as GroupSortBy] !== GroupSortBy.PLACEHOLDER)
					.map(key => ({
						label: capitalizeFirstLetter(GroupSortBy[key]),
						value: capitalizeFirstLetter(GroupSortBy[key]),
						default: key === (sortBy ? sortBy.toUpperCase() : "")
					}))
			},
			{
				label: "Group Type",
				inlineOptions: false,
				onClick: groupTypeClickHandler,
				listOptions: [
					{ label: "Public", value: "Public", default: groupType === "public" },
					{ label: "Private", value: "Private", default: groupType === "private" }
				]
			},
			{
				label: "My Group",
				inlineOptions: true,
				onClick: () => {
					setFilters(ctx => ({ ...ctx, isMyGroup: !ctx.isMyGroup }));
					setOffset(1);
				},
				listOptions: [{ label: "My Group", value: "myGroup", id: "myGroup" }]
			}
		],
		[setFilters, groupTypeClickHandler, groupType, sortBy, applyFilters]
	);

	return (
		<PageWrapper>
			<FilterBlock options={filterOptions} createOption={undefined} />
			<ModelBlock
				autoFit={totalFoundGroups >= 3 ? 260 : undefined}
				gridGap={16}
				title={filters.sortBy ? `${capitalizeFirstLetter(filters.sortBy)} Groups` : "Groups"}
				type={ModelBlockType.group}
				items={groups || []}
				loading={loading}
				keepCurrentData={loading && offset > 1}
				onEndScroll={onEndScroll}
				inlineView={!isMobile}
				noContent={
					<NoConnectionsBlock>
						<ManInLotus />
						<MessageText>You don’t have any Series & Collections yet.</MessageText>
						{isUserCouldManageSeries({}) && (
							<StyledButton buttonTheme="light" palette="light" size="medium" removeSideMargin onClick={createSeries}>
								+ Create New Series
							</StyledButton>
						)}
					</NoConnectionsBlock>
				}
			/>
			<CreateUpdateSeriesWrapper />
		</PageWrapper>
	);
};

export default AllCollectionsPage;
