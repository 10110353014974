import Button from "@material-ui/core/Button";
import styled from "styled-components";

const Container = styled.div`
	margin-top: 24px;
	padding: 0 24px 24px 24px;
	flex: 1;
	overflow: hidden;
`;

const BlockedUserWrapper = styled.div`
	border-radius: 12px;
	height: 100%;
	overflow: auto;
`;

const BlockedUserRow = styled.div`
	display: flex;
	align-items: center;
	padding: 8px 16px;
	border: 1px solid #c5cee0;
	border-bottom-width: 0;

	&:first-child {
		border-top-left-radius: 12px;
		border-top-right-radius: 12px;
	}

	&:last-child {
		border-bottom-left-radius: 12px;
		border-bottom-right-radius: 12px;
		border-bottom-width: 1px;
	}
`;

const BlockedUserAvatarWrapper = styled.div`
	width: 48px;
	height: 48px;
	overflow: hidden;
	border-radius: 50%;
`;

const BlockedUserAvatar = styled.img`
	object-fit: cover;
	width: 100%;
	height: 100%;
`;

const BlockedUserInfo = styled.div`
	margin-left: 16px;
`;

const BlockedUserName = styled.div`
	font-size: 15px;
	font-weight: 600;
`;

const BlockedUserJobTitle = styled.div`
	margin-top: 2px;
	color: #8f9bb3;
	font-size: 13px;
	font-weight: 600;
`;

const BlockedUserUnblockButton = styled(Button)`
	margin-left: auto;

	&.MuiButton-containedPrimary {
		background: red;
		text-transform: none;
		padding: 8px 17px;
		border-radius: 8px;
		border: 1px solid #c9cfff;
		background-color: #ebecff;
		color: #5e70ff;
		min-width: 84px;

		span {
			font-weight: 600;
			font-size: 12px;
		}
	}
`;

const BlockedUsersEmpty = styled.div`
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-top: -55px;
`;

const BlockedUsersEmptyText = styled.div`
	margin-top: 24px;
`;

export {
	Container,
	BlockedUserWrapper,
	BlockedUserRow,
	BlockedUserAvatarWrapper,
	BlockedUserAvatar,
	BlockedUserInfo,
	BlockedUserName,
	BlockedUserUnblockButton,
	BlockedUsersEmpty,
	BlockedUsersEmptyText,
	BlockedUserJobTitle
};
