import React, { FC, useCallback, useMemo } from "react";

import { css } from "styled-components";

import { useCategories } from "modules/Marketplace/Data";
import { Dialog } from "shared/Components";
import { Text } from "shared/ui-kit";

import { CTAButton, ContentWrapper, DeleteText, DialogFooter } from "../style";

const DeleteCategoryDialog: FC<{ debouncedSearchText: string; onDelete: () => void }> = ({ onDelete }) => {
	const { setDeleteDialog, deleteCategory, getData: getCategoriesData } = useCategories();
	const { deleteDialog } = getCategoriesData();

	const handleClose = useCallback(() => setDeleteDialog({ data: undefined }), [setDeleteDialog]);

	const cantDelete = useMemo(
		() => (
			<>
				<ContentWrapper>
					<DeleteText>
						This category still has active companies, it cannot be deleted until the companies are removed.
					</DeleteText>
				</ContentWrapper>
				<DialogFooter mt={20}>
					<CTAButton wide onClick={handleClose}>
						Okay
					</CTAButton>
				</DialogFooter>
			</>
		),
		[handleClose]
	);

	const formDelete = useMemo(
		() => (
			<>
				<ContentWrapper>
					<DeleteText>Are you sure you want to permanently delete {deleteDialog?.name}?</DeleteText>
				</ContentWrapper>
				<DialogFooter mt={20}>
					<CTAButton onClick={handleClose} buttonTheme="outline" wide>
						Keep
					</CTAButton>
					{deleteDialog && (
						<CTAButton
							id="deleteConfirmation"
							wide
							onClick={async () => {
								await deleteCategory(deleteDialog);
								onDelete();
							}}
						>
							Delete
						</CTAButton>
					)}
				</DialogFooter>
			</>
		),
		[deleteCategory, deleteDialog, handleClose, onDelete]
	);

	return (
		<Dialog
			bodyCustomStyles={css`
				padding: 0 !important;
			`}
			title={
				<Text align="left" variant="h6">
					Delete Category?
				</Text>
			}
			open={!!deleteDialog}
			onClose={handleClose}
			hasBackButton={false}
		>
			{deleteDialog?.totalCompanies ? cantDelete : formDelete}
		</Dialog>
	);
};

export default DeleteCategoryDialog;
