import React, { ReactNode, createRef, useCallback, useEffect, useState } from "react";

import { Controller, useForm } from "react-hook-form";

import styled from "styled-components";

import { PostBlockEventType } from "types";

import { PostPortal } from "modules/Post/View/Components";
import Toolbar from "modules/Post/View/Components/Toolbar";
import { default as getComponentBlock } from "shared/Components/Blocks/getBlock";
import useConfirmLeavePage from "shared/Components/ConfirmLeave/hooks/useConfirmLeavePage";
import Dialogs from "shared/Components/Dialogs";
import { useCommunity, useNotification, useSmartBlock } from "shared/hooks";

import { ToolbarActions } from "shared/types";
import { Box, Button, RichEditor, Text } from "shared/ui-kit";
import { extractContent, generateLimitedHTML } from "shared/ui-kit/utils/helper";
import { getValidationMessage } from "utils/getValidationMessage";
import { extractContentFromHTML, generateFakeId } from "utils/serviceUtils/helpers";

import { usePrevious } from "utils/usePrevious";

const Wrapper = styled(Box)`
	display: flex;
	flex-direction: row;
	flex: 1;
	margin: 30px 0 0 0;
	align-items: flex-end;
	padding-bottom: 70px;
	${props => props.theme.breakpoints.down("sm")} {
		flex-direction: column;
		width: 100%;
	}
`;

const ButtonWrapper = styled(Wrapper)`
	align-items: flex-end;
	justify-content: flex-end;
	margin: 0;
	padding-bottom: 0;
`;

const Subtitle = styled(Text)`
	margin-top: 15px;
	margin-bottom: 15px;
`;
const SubmitButton = styled(Button)`
	height: 48px;
	margin-right: 10px;
`;

const StyledToolbar = styled(Toolbar)`
	margin: 0;
	width: 100%;
	max-width: initial;
`;

const EditorWrapper = styled(Box)`
	display: flex;
	flex-direction: column;
	margin: 40px 0 16px 0;
	.ql-editor {
		min-height: 167px;
	}
`;

export const StyledRichEditor = styled(RichEditor)`
	.ql-blank {
		font-size: 18px;
		font-weight: 600;
		line-height: 1.33;
		color: #8f9bb3;
	}
`;

const About = () => {
	const { updateWorkspaceMeta, getData: getCommunityData } = useCommunity();
	const { workspace } = getCommunityData();

	const { showMessage } = useNotification();

	const { getDataAttributeName, getBlock, encodeEditorContent } = useSmartBlock();

	const [portals, setPortals] = useState<{ id: string; html: ReactNode }[]>([]);

	const [action, setAction] = useState<ToolbarActions | null>(null);
	const contentRef = createRef<any>();
	const [contentIndex, setContentIndex] = useState(0);
	const { control, register, handleSubmit, formState, setValue, getValues } = useForm({
		mode: "all",
		defaultValues: {
			content: "",
			contentHidden: ""
		}
	});

	const { provideHandleCloseValues } = useConfirmLeavePage({});

	useEffect(() => {
		register("contentHidden");
	}, [register]);

	const prevAbout = usePrevious(workspace?.meta?.about);

	useEffect(() => {
		if (workspace?.meta?.about && (!prevAbout || prevAbout !== workspace?.meta?.about)) {
			const content = new DOMParser().parseFromString(workspace.meta.about, "text/html");
			const blocks = content.querySelectorAll("span[data-name]");
			const videos = content.querySelectorAll("video");

			const renderBlocks: {
				id: string;
				comp: ReactNode;
			}[] = [];

			const parseBlock = b => {
				const { attributes, parentNode } = b;

				if (!attributes["data-name"]?.value) return;

				if (attributes["poster"]) {
					const src = attributes["src"].value;
					const poster = attributes["poster"].value;
					const id = `custom-embedded-${generateFakeId()}`;

					const comp = getBlock("video", { src, poster }, b);

					renderBlocks.push({ id, comp });

					const wrapper = document.createElement("p");
					wrapper.innerHTML = id;

					parentNode!.replaceChild(wrapper, b);
				}

				const elementName = attributes["data-name"].value;
				const dataAttributeName = getDataAttributeName(elementName);
				const decodedData = decodeURIComponent(attributes[dataAttributeName].value);
				const data = elementName === "text" ? decodedData : JSON.parse(decodedData);
				const correctComponent = getBlock(elementName, data, b);

				const id = `custom-embedded-${generateFakeId()}`;

				if (elementName === "text") {
					const textContent = new DOMParser().parseFromString(data, "text/html");
					const textContentHTML = textContent.documentElement.getElementsByTagName("body")[0];

					const wrapper = document.createElement("p");
					Array.from(textContentHTML.children).forEach(item => {
						const span = document.createElement("p");
						span.innerHTML = item.innerHTML;
						wrapper.appendChild(span);
					});
					parentNode!.replaceChild(wrapper, b);
				} else {
					renderBlocks.push({
						id,
						comp: correctComponent
					});

					const wrapper = document.createElement("p");
					wrapper.innerHTML = id;

					parentNode!.replaceChild(wrapper, b);
				}
			};

			blocks.forEach(b => {
				parseBlock(b);
			});

			videos.forEach(b => {
				parseBlock(b);
			});

			const displayContent = content.documentElement.getElementsByTagName("body")[0].innerHTML;
			setValue("content", displayContent);

			setTimeout(() => {
				const editor = contentRef.current.getEditor();

				if (editor) {
					const text = editor.getText();
					renderBlocks.reverse().forEach((item, index) => {
						const position = text.indexOf(item.id);
						if (position > -1) {
							editor.deleteText(position, item.id.length + 1);
							editor.insertEmbed(position || index, "AppPanelEmbed", `<div id="${item.id}"></div>`, "user");
							setPortals(portals => [...portals, { id: item.id, html: item.comp }]);
						}
					});
				}
			}, 100);
		}
	}, [workspace, setValue, getDataAttributeName, getBlock, contentRef, prevAbout]);

	const submit = useCallback(
		async data => {
			await updateWorkspaceMeta({
				meta: {
					about: encodeEditorContent(data.contentHidden)
				}
			});
			showMessage(
				getValidationMessage({
					name: "Your changes have been successfully saved ✨"
				}),
				3
			);
		},
		[encodeEditorContent, showMessage, updateWorkspaceMeta]
	);

	const onEvent = useCallback((eventType: PostBlockEventType, id: string) => {
		const element = document.getElementById(id);
		if (element) {
			if (eventType === PostBlockEventType.delete) {
				element.parentElement?.remove();
			}
		}
	}, []);

	const onSetContent = useCallback(
		(content?: any, customAction?: ToolbarActions) => {
			const editor = contentRef.current.getEditor();
			const index = contentIndex;
			const id = `custom-embedded-${generateFakeId()}`;
			const properAction = customAction || action;
			const html = getComponentBlock(properAction, content, id, onEvent);
			editor.insertEmbed(index || editor.getLength(), "AppPanelEmbed", `<div id="${id}"></div>`, "user");
			setPortals(portals => [...portals, { id, html }]);
			setAction(null);
		},
		[action, contentIndex, contentRef, onEvent]
	);

	const addElementFn = useCallback(
		(action: ToolbarActions | null, data?: any) => {
			setAction(action);

			if (action === ToolbarActions.ADD_IMAGE && data && data instanceof FileList) {
				onSetContent(
					{
						fileList: data
					},
					action
				);
			}
		},
		[onSetContent]
	);

	return (
		<form onSubmit={handleSubmit(submit)}>
			<Subtitle variant={"subtitle1"}>About Community</Subtitle>
			<Text>
				When people visit your community page, they need to understand more about you and what your community has to
				offer. Make sure to be expressive and paint a compelling picture for people to know what to expect when joining
				your community.
			</Text>
			<EditorWrapper>
				<Controller
					name="content"
					control={control}
					rules={{
						validate: () => {
							const content = extractContentFromHTML(getValues("contentHidden"));
							return content.length >= 1 || "Content text can't be empty";
						}
					}}
					render={({ value, onBlur }) => (
						<StyledRichEditor
							placeholder="Start typing here…"
							config={{ toolbar: { items: [] } }}
							value={value || ""}
							ref={contentRef}
							onChange={(e, c) => {
								const limit = 500;

								let customContent = c.getData();
								if (extractContent(customContent).length > limit) {
									const div = document.createElement("div");
									div.innerHTML = customContent.trim();

									customContent = generateLimitedHTML(div, limit, 0).innerHTML;
									// It's a hack to force update this component
									if (value === customContent) {
										setValue("content", undefined);
									}
									setTimeout(() => {
										setValue("content", customContent, { shouldDirty: true, shouldValidate: true });
									}, 10);
								}

								setValue("contentHidden", customContent, { shouldDirty: true });
								setContentIndex(c.getSelection()?.index);
								provideHandleCloseValues(true);
							}}
							onBlur={onBlur}
							id="aboutCommunity"
						/>
					)}
				/>
				<StyledToolbar type="teaser" onClick={addElementFn} />
			</EditorWrapper>
			<ButtonWrapper>
				<SubmitButton type={"submit"} disabled={!formState.isDirty} id="saveChangesAboveCommunityPage">
					Save changes
				</SubmitButton>
			</ButtonWrapper>
			<Dialogs action={action} setAction={setAction} onSetContent={onSetContent} />
			{portals.map(item => (
				<PostPortal key={item.id} id={item.id}>
					{item.html}
				</PostPortal>
			))}
		</form>
	);
};

export default About;
