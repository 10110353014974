import React, { useEffect, useMemo, useState } from "react";

import { ReactComponent as EditIcon } from "assets/icons/iconTopicLinear.svg";
import { useCommunity, usePlayer, usePosts } from "shared/hooks";

import { AddPostBtnWrapper } from "./style";

const AddPostBtn = () => {
	const { communityColors } = useCommunity();
	const { getData: getPlayerData } = usePlayer();
	const { playTracks } = getPlayerData();
	const { setCreateUpdatePostDialog } = usePosts();

	const tracksList = useMemo(
		() => playTracks.tracks?.items || playTracks.series?.items || [],
		[playTracks.series, playTracks.tracks]
	);

	const [color, setColor] = useState<string>();

	useEffect(() => {
		setColor(communityColors.primary);
	}, [communityColors.primary]);

	const handleCreatePost = () => {
		setCreateUpdatePostDialog({ isOpen: true });
	};

	return (
		<AddPostBtnWrapper
			onClick={handleCreatePost}
			bgColor={color}
			data-topnav="createPost"
			className={!!tracksList.length ? "track-player-open" : ""}
		>
			<EditIcon />
		</AddPostBtnWrapper>
	);
};

export default AddPostBtn;
