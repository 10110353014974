import React from "react";

import { useLocation, useParams } from "react-router";

import { view } from "modules/MemberHome";

const VideosPage = () => {
	const { id } = useParams() as { id: string };
	const { search } = useLocation();
	const query = new URLSearchParams(search);
	const groupId = query.get("groupId") || undefined;

	return <view.Video id={id} groupId={groupId} />;
};

export default VideosPage;
