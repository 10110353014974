import React, { FC, useCallback, useMemo } from "react";

import { Box } from "@material-ui/core";
import { DateTime } from "luxon";

import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";

import { ReactComponent as OfficeChair } from "assets/icons/chair-office.svg";
import { MemberDialog } from "modules/MemberHome/View/shared";

import { MemberDialogContentWrapper } from "modules/MemberHome/View/shared/style";

import { useFirebase, useMessaging } from "modules/Messaging/Data";
import { PlaceholderImage } from "shared/Components";
import { useCarpool, useCommunity, useMemberRoutes, useUser } from "shared/hooks";

import { PlaceholderImageType, ProfilePhotoSizes } from "shared/types";
import { CarpoolMessage } from "shared/types/CarpoolTypes";
import { EventRequests, OfferRequest } from "shared/types/EventModel";
import { Icon, Text } from "shared/ui-kit";

import { EventDetails, IconWrapper, SecondaryText } from "../../Components/EventDetails/styles";
import CarpoolMap from "../../Components/GoogleMap/CarpoolMap";

interface SignUpRequestDialogProps {
	open: boolean;
	onClose: () => void;
	eventDetail?: EventRequests;
	request?: OfferRequest;
	isCarpoolPage?: boolean;
}

const SignUpRequestDialog: FC<SignUpRequestDialogProps> = ({
	open,
	onClose,
	eventDetail,
	request,
	isCarpoolPage = false
}) => {
	const history = useHistory();
	const { communityColors } = useCommunity();
	const { signUpCarpoolRequest } = useCarpool();
	const { getData: getUserData, getResizedProfileImages } = useUser();
	const { user } = getUserData();

	const { getMemberRoutesData } = useMemberRoutes();
	const { routes } = getMemberRoutesData();

	const { onSend } = useFirebase();

	const { createConnection } = useMessaging();

	const { handleSubmit } = useForm({ mode: "onChange" });

	const onSubmit = useCallback(async () => {
		if (eventDetail?._id && request?._id && user?.personaDocId) {
			const { success } = await signUpCarpoolRequest(
				request._id,
				eventDetail._id,
				user?.personaDocId,
				onClose,
				isCarpoolPage
			);

			const carpool: CarpoolMessage = {
				type: "offer",
				name: user?.profiles[0].firstName,
				title: eventDetail?.name,
				quantity: Number(request.seats),
				seats: request?.seats,
				seatsLeft: request?.seatsLeft,
				eventDate: eventDetail?.eventDateAndTime,
				notes: request?.description,
				address: request?.location?.address,
				latitude: request?.location?.location?.lat,
				longitude: request?.location?.location?.lon,
				notifyToken: request?.creator?.notifyToken
			};

			if (success && user?.personaDocId !== request?.creator._id) {
				const chatResponse = await createConnection(request?.creator.personaId);

				if (chatResponse) {
					onSend({
						messages: [
							{
								text: "",
								user: {
									_id: user?.profiles[0].chatUserId,
									name: carpool.name,
									avatar: getResizedProfileImages(user, ProfilePhotoSizes.size50x50)
								},
								carpool
							}
						],
						chat: chatResponse
					});
					history.push(`${routes?.member.messaging.getPath()}?messageTo=${request.creator.personaId}`);
				}
			}
		}
	}, [
		signUpCarpoolRequest,
		request,
		eventDetail,
		onClose,
		user,
		isCarpoolPage,
		onSend,
		routes?.member,
		history,
		createConnection,
		getResizedProfileImages
	]);

	const customHandleSubmit = useCallback(
		e => {
			e?.preventDefault && e.preventDefault();
			e?.stopPropagation && e.stopPropagation();
			handleSubmit(onSubmit)();
		},
		[handleSubmit, onSubmit]
	);

	const zone = useMemo(() => Intl.DateTimeFormat().resolvedOptions().timeZone, []);

	const formatDate = eventDateAndTime =>
		DateTime.fromISO(eventDateAndTime || "", { zone }).toLocaleString({
			month: "short",
			day: "2-digit",
			hour: "2-digit",
			minute: "2-digit"
		});

	return (
		<>
			<MemberDialog
				customWidth={512}
				title="Carpool Request"
				open={open}
				onClose={onClose}
				modalHeight={600}
				footerPrimary={{
					text: "Sign Up",
					loading: false,
					onClick: handleSubmit(onSubmit)
				}}
				isXsFooterFlex
				footerLeftContent={
					<SecondaryText className="pl-2 w-3/4">
						Once you sign up, you&apos;ll be connected with the requester to discuss carpool details.
					</SecondaryText>
				}
			>
				<form onSubmit={customHandleSubmit}>
					<MemberDialogContentWrapper className="pv-20">
						<Box className="flex mb-6">
							<Box className="w-10 h-10 overflow-hidden rounded-md">
								{!!eventDetail?.eventImages?.length ? (
									<img src={eventDetail!.eventImages[0]} alt={eventDetail!.name} className="w-10 h-10" />
								) : (
									<PlaceholderImage width={120} height={60} type={PlaceholderImageType.EVENT_DETAILS} />
								)}
							</Box>
							<Box className="ml-4">
								<p className="grid">
									<Text className="font-semibold truncate">{eventDetail?.name}</Text>
									<SecondaryText className="">{eventDetail?.location.name}</SecondaryText>
								</p>
							</Box>
						</Box>
						<Box className="flex mb-6">
							<Box className="w-10 h-10 overflow-hidden rounded-md">
								{!!request?.creator.photos.length ? (
									<img
										src={request?.creator.photos[0].profilePicture}
										alt={request?.creator.firstName}
										className="w-10 h-10"
									/>
								) : (
									<PlaceholderImage width={120} height={60} type={PlaceholderImageType.PROFILE_IMAGE} />
								)}
							</Box>
							<Box className="ml-4">
								<p className="grid">
									<Text className="font-semibold truncate">{`${request?.creator.firstName} ${request?.creator.lastName}`}</Text>
									<SecondaryText className="">{request?.location.name}</SecondaryText>
								</p>
							</Box>
						</Box>
						<Box className="flex">
							<EventDetails.EventInfoRow className="w-full">
								<IconWrapper>
									<OfficeChair fill={communityColors.primary} />
								</IconWrapper>
								<Text>{(request?.seats || 0) - (request?.seatsLeft || 0)} Seats Needed</Text>
							</EventDetails.EventInfoRow>
							<EventDetails.EventInfoRow className="w-full">
								<IconWrapper>
									<Icon name="clock" fill={communityColors.primary} />
								</IconWrapper>
								<Text>{formatDate(eventDetail?.eventDateAndTime)}</Text>
							</EventDetails.EventInfoRow>
						</Box>
						<Text className="font-semibold mb-2">Pick Up Location</Text>
						{request && request.location && (
							<CarpoolMap
								lat={request.location.location.lat}
								lng={request.location.location.lon}
								defaultMapOptions={{
									fullscreenControl: false,
									draggable: false,
									zoomControl: false,
									streetViewControl: false,
									mapTypeControl: false
								}}
							/>
						)}
						{request?.description && (
							<>
								<Text className="font-semibold mb-2">Notes</Text>
								<Text className="font-normal">{request?.description}</Text>
							</>
						)}
					</MemberDialogContentWrapper>
				</form>
			</MemberDialog>
		</>
	);
};

export default SignUpRequestDialog;
