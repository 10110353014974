import React from "react";

import { useLocation, useParams } from "react-router";

import { view } from "modules/Fundraisers";

const FundraisersPage = () => {
	const { id } = useParams() as { id: string };
	const { search } = useLocation();
	const query = new URLSearchParams(search);

	const fundraiser = query.get("fundraiser") || undefined;

	return <view.DonationsView id={id} fundraiser={fundraiser} />;
};

export default FundraisersPage;
