import React, { FC, useMemo, useState } from "react";

import { Icon, Text } from "shared/ui-kit";

import ChooseBoardsDialog, { AllSelectedItemInterface } from "./ChooseBoardsDialog";

import { Wrapper } from "./style";

const PostLocationSelector: FC<{
	value: AllSelectedItemInterface;
	onChange: (val: AllSelectedItemInterface) => void;
}> = ({ value, onChange }) => {
	const [dialogOpen, setDialogOpen] = useState(false);

	console.log(value);

	const summaryTitle = useMemo(() => {
		if (!value) {
			return "Choose a category";
		}

		let allTitles: string[] = [];

		if (value.boardIds) {
			allTitles = [...allTitles, ...value.boardIds.map(b => b.name)];
		}

		if (value.rootPostIds) {
			allTitles = [...allTitles, ...value.rootPostIds.map(b => b.name)];
		}

		let title = `${allTitles[0]}`;
		const plus = allTitles.length - 1;

		if (plus) title += `, +${plus}`;

		return title;
	}, [value]);

	return (
		<>
			<Wrapper
				onClick={e => {
					e.stopPropagation();
					setDialogOpen(true);
				}}
				type="button"
			>
				<Text>{summaryTitle}</Text>
				<Icon name="chevron-down" group="filled" width={16} height={16} />
			</Wrapper>
			{dialogOpen && <ChooseBoardsDialog handleClose={() => setDialogOpen(false)} value={value} onChange={onChange} />}
		</>
	);
};

export default PostLocationSelector;
