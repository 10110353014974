import React, { useMemo } from "react";

import { DateTime } from "luxon";

import CarpoolMap from "modules/MemberHome/View/Components/GoogleMap/CarpoolMap";

import { RelativeWrapper } from "shared/styles";
import { Box, Text } from "shared/ui-kit";

import { formatCount } from "utils/serviceUtils/helpers";

import { SecondaryText } from "../../../../../MemberHome/View/Containers/Requests/style";
import { CarpoolBox, CarpoolMapBox } from "../styles";

const CarpoolBubble = props => {
	const { carpool, isCurrentUser } = props;

	const zone = useMemo(() => Intl.DateTimeFormat().resolvedOptions().timeZone, []);

	const formatDate = eventDateAndTime => {
		return DateTime.fromISO(eventDateAndTime || "", { zone }).toLocaleString({
			month: "short",
			day: "2-digit",
			hour: "2-digit",
			minute: "2-digit"
		});
	};

	return (
		<RelativeWrapper className="w-9/12 m-auto rounded-lg my-4">
			<Box className="text-center mb-8">
				{carpool.isUpdated ? (
					<SecondaryText className="font-medium text-xs">Carpool booking has been updated</SecondaryText>
				) : (
					<SecondaryText className="font-medium text-xs">🚘 Carpool chat was created</SecondaryText>
				)}
			</Box>

			<CarpoolBox className="p-4">
				<Text>
					- {isCurrentUser ? "You" : carpool?.name}
					{` ${carpool.type === "request" ? "Requested a Ride" : "Offered a Ride"} for ${formatCount(
						carpool?.quantity,
						"seat"
					)}`}
				</Text>
				<Text>- To: {carpool?.title}</Text>
				<Text>- Event Date: {formatDate(carpool?.eventDate)}</Text>
				<Text>
					- {carpool.type === "request" ? "Pick Up" : ""} Location: {carpool?.address}
				</Text>
				{carpool?.notes && <Text>- Notes: {carpool.notes}</Text>}
			</CarpoolBox>
			<CarpoolMapBox>
				<CarpoolMap
					lat={carpool.latitude}
					lng={carpool.longitude}
					defaultMapOptions={{
						fullscreenControl: false,
						draggable: false,
						zoomControl: false,
						streetViewControl: false,
						mapTypeControl: false
					}}
				/>
			</CarpoolMapBox>
		</RelativeWrapper>
	);
};

export default CarpoolBubble;
