import React, { HTMLAttributes } from "react";

import { CircularProgressProps, makeStyles } from "@material-ui/core";
import MuiCircularProgress from "@material-ui/core/CircularProgress";
import LinearProgress, { LinearProgressProps } from "@material-ui/core/LinearProgress";
import styled from "styled-components";

import { IExtendedTheme } from "shared/ui-kit/themes/default";

const useStyles = makeStyles((theme: IExtendedTheme) => ({
	bottom: {
		animationDuration: "550ms",
		left: 0,
		position: "absolute",
		top: 0
	},
	top: {
		color: theme.palette.colors.warning[500],
		position: "absolute",
		top: 0
	}
}));

const Wrapper = styled.div<HTMLAttributes<HTMLDivElement> & { size?: number }>`
	position: relative;
	width: ${props => (props.size ? `${props.size}px` : "100px")};
	height: ${props => (props.size ? `${props.size}px` : "100px")};
`;

const CicrularProgress = styled(MuiCircularProgress)<CircularProgressProps & { palette?: number | string }>`
	color: ${props =>
		props.palette ? props.theme.palette[props.palette].main : props.theme.palette.colors.warning[500]};
	stroke-linecap: round;
`;

// noinspection Stylelint
const LineProgress = styled(LinearProgress)<LinearProgressProps & { palette?: number | string }>`
  div {
    background-color: ${props =>
			props.palette ? props.theme.palette[props.palette].main : props.theme.palette.colors.warning[500]};
  border-radius: 2px;
`;

const ProgressTextIndicator = styled.div<HTMLAttributes<HTMLDivElement> & { palette: number | string; size?: number }>`
	font-size: ${({ size }) => (size ? `calc(${size}/ 40 * 80%)` : "40px")};
	padding: 16px 14px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	color: ${props =>
		props.palette ? props.theme.palette[props.palette].main : props.theme.palette.colors.warning[500]};
`;

export type ICircularProgress = {
	InnerComponent?: React.Component;
	isLineProgress?: boolean;
	palette: number | string;
	size?: number;
	value: any;
};

export default ({ InnerComponent, value, isLineProgress, ...props }: ICircularProgress): JSX.Element => {
	const classes = useStyles(props);

	const renderProgress = () => {
		if (isLineProgress) {
			return <LineProgress variant="determinate" value={value} palette={props.palette} />;
		} else {
			return (
				<>
					<ProgressTextIndicator palette={props.palette} size={props.size || 48}>
						{InnerComponent ? InnerComponent : <>{value}%</>}
					</ProgressTextIndicator>
					<CicrularProgress
						variant="static"
						value={100}
						palette="primary"
						className={classes.top}
						size={props.size || 48}
						thickness={3}
					/>
					<CicrularProgress
						{...props}
						variant="static"
						value={value}
						palette={props.palette}
						className={classes.bottom}
						size={props.size || 48}
						thickness={3}
					/>
				</>
			);
		}
	};

	return <Wrapper {...props}>{renderProgress()}</Wrapper>;
};
