import { Chip, Select } from "@material-ui/core";
import styled, { css } from "styled-components";

import { Box } from "shared/ui-kit";

export const InputWrapper = styled(Box)`
	margin-bottom: 20px;
	${({ width }) =>
		width &&
		css`
			width: ${width};
		`}
	.fill-second-path path:nth-child(2) {
		fill: #c5cee0;
	}
`;

export const FilterWrapper = styled(Box)`
	margin: 16px 0;
	display: flex;
	flex-direction: row;
	align-content: center;
	justify-content: flex-start;

	${({ inline }) =>
		!inline &&
		css`
			div,
			button :not(:first-child) {
				margin-left: 8px;
			}

			${props => props.theme.breakpoints.down("sm")} {
				display: none;
			}
		`}
`;

export const TrackItemsWrapper = styled(Box)`
	${props => props.theme.breakpoints.down("sm")} {
		display: flex;
		flex-direction: column;
		margin-top: 15px;
	}
`;

export const CustomChip = styled(Chip)`
	height: 36px;
	margin-top: 4px;
`;

export const CustomSelect = styled(Select)`
	height: 40px;
	border-radius: 20px;

	.Mui-focused {
		background: transparent !important;
	}
`;

export const ContentWrapper = styled(Box)`
	height: ${({ isMemberView }) => (isMemberView ? "340px" : "50vh")};
`;
