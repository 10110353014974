import { useMemo } from "react";

import { InboxItem } from "modules/Messaging/Data/types";
import useHttpActions from "shared/services/api/core/useHttpActions";

import { InvitationConvertor, MembersListConvertor, UserSearchConvertor } from "shared/services/converters";
import BaseConverter from "shared/services/converters/baseConverter";

import { EmailStateModel, getGroupChatMembersDataType } from "shared/types";

import { API_URLS } from "../constants";

import {
	CancelInviteMember,
	ContributorModel,
	EditRoleMember,
	InvitationModel,
	InviteMember,
	MembersModel,
	ResendInviteMember,
	SearchUsersModel,
	getAllShareableLinksDataType,
	getAndCreateShareableLinkDataType,
	getMembersDataType,
	getUserSearchDataType
} from "../types";

const useMembersListApiService = () => {
	const actions = useHttpActions();

	return useMemo(
		() => ({
			getMembers: async (data: getMembersDataType, addLoyaltyDetails?: boolean): Promise<MembersModel[]> => {
				return MembersListConvertor.convertUsersResponse(
					await actions.post(API_URLS.GLOBAL.GET_MEMBERS, { ...data, addLoyaltyDetails })
				);
			},
			getContributors: async (data: {
				offset: number;
				limit: number;
				sortBy: string;
			}): Promise<{ users: ContributorModel[]; totalUsersFound: number }> => {
				return BaseConverter.convert(await actions.get(API_URLS.CONTRIBUTORS.GET_ALL, data));
			},
			getMembersToInvite: async (data: getMembersDataType): Promise<SearchUsersModel[]> => {
				return MembersListConvertor.convertMembersResponse(
					await actions.get(API_URLS.GLOBAL.GET_MEMBERS_TO_INVITE, data)
				);
			},
			getMembersToInviteCount: async data => {
				return BaseConverter.convert<{ totalCount: number }>(
					await actions.get(API_URLS.GLOBAL.COUNT_INVIETABLE_MEMBERS, data)
				);
			},
			getGroupChatMembers: async (data: getGroupChatMembersDataType): Promise<InboxItem[]> => {
				return (
					BaseConverter.convert(await actions.post(API_URLS.GROUP_CHAT.GET_MEMBERS, data)) as {
						members: InboxItem[];
					}
				).members;
			},
			deleteMember: async (ids: string[]): Promise<CancelInviteMember> => {
				return BaseConverter.convert(await actions.delete(API_URLS.USER.DELETE_MEMBER, { userId: null, ids }));
			},
			banMember: async (ids: string[]): Promise<CancelInviteMember> => {
				return BaseConverter.convert(
					await actions.delete(API_URLS.USER.DELETE_MEMBER, { userId: null, ids, action: "ban" })
				);
			},
			unbanMember: async (ids: string[]): Promise<CancelInviteMember> => {
				return BaseConverter.convert(await actions.put(API_URLS.USER.UNBAN_MEMBER, { userIds: ids }));
			},
			validateEmailMember: async (emails: string[]): Promise<EmailStateModel[]> => {
				return BaseConverter.convert(await actions.post(`${API_URLS.GLOBAL.POST_VALIDATE_EMAIL_MEMBER}`, { emails }));
			},
			cancelInviteMember: async (userIds: string[]): Promise<CancelInviteMember> => {
				return BaseConverter.convert(await actions.put(API_URLS.GLOBAL.PUT_CANCEL_INVITE_MEMBER, { userIds }));
			},

			resendInviteMember: async (userIds: string[]): Promise<ResendInviteMember> => {
				return BaseConverter.convert(await actions.post(API_URLS.GLOBAL.POST_RESEND_INVITE_MEMBER, { userIds }));
			},
			inviteMember: async (data: {
				emails: string[];
				slug: string | null;
				role: string | null;
			}): Promise<InviteMember> => {
				return BaseConverter.convert(await actions.post(API_URLS.GLOBAL.POST_INVITE_MEMBER, data));
			},
			getUserSearch: async (data: getUserSearchDataType): Promise<SearchUsersModel[]> => {
				return UserSearchConvertor.convertUsersResponse(await actions.get(API_URLS.ADMIN.GET_USER_SEARCH, data));
			},
			getShareableLink: async (data: getAndCreateShareableLinkDataType) => {
				return InvitationConvertor.convertSingleInvitaionResponse(
					await actions.get(API_URLS.INVITATION.CREATE_AND_GET, data)
				);
			},
			updateShareableLink: async (data: getAndCreateShareableLinkDataType) => {
				return InvitationConvertor.convertSingleInvitaionResponse(await actions.put(API_URLS.INVITATION.UPDATE, data));
			},
			getAllShareableLinks: async (data: getAllShareableLinksDataType): Promise<InvitationModel[]> => {
				return InvitationConvertor.convertInvitationsResponse(await actions.get(API_URLS.INVITATION.GET_ALL, data));
			},
			deleteShareableLink: async (id: string): Promise<InvitationModel[]> => {
				return InvitationConvertor.convertInvitationsResponse(
					await actions.delete(API_URLS.INVITATION.DELETE, { invitationId: id })
				);
			},
			getCommunityDetails: async (): Promise<{
				communityPageUrl?: string;
				qrCodeUrl?: string;
				name?: string;
				firebaseUrl?: string;
				firebaseDbUrl?: string;
				firebaseWebApiKey?: string;
			}> => {
				return BaseConverter.convert(await actions.get(API_URLS.ADMIN.GET_COMMUNITY_DETAILS));
			},
			markArchive: async (data: { userIds: string[]; value: boolean }): Promise<{ success?: any }> => {
				return BaseConverter.convert(await actions.post(API_URLS.ADMIN.POST_MARK_ARCHIVE, data));
			},
			editRoleMember: async (data: EditRoleMember): Promise<EditRoleMember> => {
				return BaseConverter.convert(await actions.put(API_URLS.ADMIN.PUT_EDIT_ROLE_MEMBER, data));
			},
			getTotalMemberCount: async (data): Promise<{ totalCount: number }> => {
				return BaseConverter.convert(await actions.post(API_URLS.GLOBAL.POST_MEMBER_COUNT, data));
			}
		}),
		[actions]
	);
};

export default useMembersListApiService;
