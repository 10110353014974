import React, { FC, useEffect, useMemo, useRef, useState } from "react";

import { Box } from "@material-ui/core";

import { PostScheduleType } from "types";

import { Post } from "shared/Organisms";
import { useOnScreen, usePost, usePosts } from "shared/hooks";
import { Text } from "shared/ui-kit";

import { formatCount } from "utils/serviceUtils/helpers";

import { HashtagWrapper } from "./style";

import { ContributorsCard, Hashtags, PostSkeleton } from "../../Components";

const HashtagPage: FC<{ routes: any; id: string }> = ({ routes, id }) => {
	const { getPosts, getData: getPostsData } = usePosts();
	const { posts, loading } = getPostsData();

	const { findHashtagData } = usePost();
	const [tag, setTag] = useState<{ used: number; followed: boolean } | null>(null);
	const [offset, setOffset] = useState(1);

	const lastItemRef = useRef<HTMLDivElement>(null);
	const onScreen = useOnScreen(lastItemRef);
	const hasMoreItems = useMemo(() => posts.length < (tag?.used || 0), [posts.length, tag]);

	useEffect(() => {
		getPosts({ postType: PostScheduleType.Posted, limit: 6, offset, tags: [id], paginate: true });
	}, [getPosts, id, offset]);

	useEffect(() => {
		if (hasMoreItems && onScreen && posts.length >= 6) {
			setOffset(ctx => ctx + 1);
		}
	}, [hasMoreItems, onScreen, posts.length]);

	useEffect(() => {
		findHashtagData(id).then(data => {
			if (data[0]) setTag(data[0]);
		});
	}, [id, findHashtagData]);

	return (
		<HashtagWrapper>
			<HashtagWrapper.PageContent>
				<HashtagWrapper.PageHeader>
					<Box>
						<Text variant="h4">#{id}</Text>
						{tag && <Text>{formatCount(tag.used, "post")} with this hashtag</Text>}
					</Box>
					{/* <Button size="medium" buttonTheme="light">
						Follow
					</Button> */}
				</HashtagWrapper.PageHeader>
				{(!loading || offset > 1) &&
					posts.map((post, i) => (
						<HashtagWrapper.PostWrapper key={post.pid} ref={i + 1 === posts.length ? lastItemRef : null}>
							<Post fullMode post={post} />
						</HashtagWrapper.PostWrapper>
					))}
				{loading && (
					<>
						<HashtagWrapper.PostWrapper>
							<PostSkeleton />
						</HashtagWrapper.PostWrapper>
						<HashtagWrapper.PostWrapper>
							<PostSkeleton />
						</HashtagWrapper.PostWrapper>
					</>
				)}
			</HashtagWrapper.PageContent>
			<HashtagWrapper.SidePanel>
				<Hashtags hashtagsUrl={routes?.member.hashtags.getPath()} ignoreHashtag={id} />
				<ContributorsCard preview contributorsUrl={routes?.member.contributors.getPath()} />
			</HashtagWrapper.SidePanel>
		</HashtagWrapper>
	);
};

export default HashtagPage;
