import React, { SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from "react";

import {
	Box,
	IconButton,
	InputAdornment,
	InputBase,
	InputLabel,
	MenuItem,
	FormControl as MuiFormControl,
	Select,
	TextField
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Controller, useForm } from "react-hook-form";

import styled, { css } from "styled-components";

import { MemberDialog, SelectInput, VyooSwitch } from "modules/MemberHome/View/shared";
import { MemberDialogContentWrapper } from "modules/MemberHome/View/shared/style";
import { Dialog } from "shared/Components";
import FlexItem from "shared/Components/FlexItem";
import { useNotification, usePoll, useUser } from "shared/hooks";

import { PollModelResponse } from "shared/types";
import { Button, Icon, Switch, Text } from "shared/ui-kit";

const Title = <Text variant="h7">Create Poll</Text>;

const CreateButton = styled(Button)`
	height: 48px;
	font-size: 16px;
	font-weight: 600;
	line-height: 1.25;
	color: #6173fe;
`;

const FormControl = styled(MuiFormControl)`
	margin-top: 12px;
	margin-bottom: 12px;
`;

const FormGroupControl = styled(FormControl)`
	display: grid;
	grid-template-columns: 40px auto 60px;
	padding: ${({ isMemberView }) => (isMemberView ? "0 8px" : "0 24px")};
	align-items: center;

	${props => props.theme.breakpoints.down("sm")} {
		padding: 0;
	}
`;

const Marker = styled(Box)`
	background: #edf1f7;
	width: 24px;
	height: 24px;
	margin: 0 8px 0 0;
	border-radius: 4px;
	font-size: 15px;
	font-weight: 600;
	line-height: 1.6;
	text-align: center;
	color: #8f9bb3;

	${props =>
		!!props.dashed &&
		css`
			background: transparent;
			border: 1px dashed #e4e9f2;
		`}
`;

const genCharArray = (charA, charZ) => {
	const a: any[] = [];
	let i = charA.charCodeAt(0);
	const j = charZ.charCodeAt(0);
	for (; i <= j; ++i) {
		a.push(String?.fromCharCode(i));
	}
	return a;
};

const GhostInput = styled(TextField)`
	fieldset {
		border-style: dashed;
	}
`;

interface OptionsProps {
	items: (string | null)[];
	setItems: SetStateAction<any>;
}

const Options: React.FC<OptionsProps> = ({ items, setItems }) => {
	const alphabet = genCharArray("a", "z");
	const inputRef = useRef<HTMLInputElement[]>([]);
	const lastIndex = useMemo(() => items.length - 1, [items]);
	const [focus, setFocus] = useState(false);

	const handleChange = useCallback(
		(index, value) => {
			setFocus(index === items.length);
			setItems([...items.slice(0, index), value, ...items.slice(index + 1)], {});
		},
		[items, setItems, setFocus]
	);

	useEffect(() => {
		if (focus) inputRef.current[lastIndex].focus();
	}, [lastIndex, focus]);

	const handleDelete = useCallback(
		index => setItems([...items.slice(0, index), ...items.slice(index + 1)]),
		[items, setItems]
	);

	const onDragEnd = useCallback(
		result => {
			if (!result.destination) {
				return;
			}

			const reorder = (list, startIndex, endIndex) => {
				const result = Array.from(list);
				const [removed] = result.splice(startIndex, 1);
				result.splice(endIndex, 0, removed);

				return result;
			};

			const newItemOrder = reorder(items, result.source.index, result.destination.index);
			setItems(newItemOrder);
		},
		[items, setItems]
	);

	return (
		<>
			<DragDropContext onDragEnd={onDragEnd}>
				<Droppable droppableId="droppable">
					{provided => (
						<div {...provided.droppableProps} ref={provided.innerRef}>
							{items.map((item, index) => (
								<Draggable key={index} draggableId={`${index}`} index={index}>
									{provided => (
										<div ref={provided.innerRef} {...provided.draggableProps}>
											<FlexItem key={index} wrap={false} style={{ marginBottom: 8 }}>
												<TextField
													onChange={event => handleChange(index, event.target.value)}
													value={item}
													variant="outlined"
													inputRef={el => (inputRef.current[index] = el as HTMLInputElement)}
													placeholder={`Option ${index + 1}`}
													InputProps={{
														startAdornment: (
															<InputAdornment position="start">
																<Marker>{alphabet[index].toUpperCase()}</Marker>
															</InputAdornment>
														)
													}}
												/>
												<IconButton
													aria-label="delete"
													data-index={index}
													style={{ marginLeft: 9, marginRight: 9, padding: 9 }}
													onClick={() => handleDelete(index)}
												>
													<Icon fill="#8f9bb3" group="filled" name="close-circle" />
												</IconButton>
												<IconButton
													style={{ cursor: "grab", marginLeft: -9, marginRight: -9, padding: 9 }}
													{...provided.dragHandleProps}
												>
													<Icon fill="#8f9bb3" group="filled" name="reorder" />
												</IconButton>
											</FlexItem>
										</div>
									)}
								</Draggable>
							))}
							{provided.placeholder}
						</div>
					)}
				</Droppable>
			</DragDropContext>
			<FlexItem wrap={false} style={{ marginBottom: 8, marginRight: 84 }}>
				<GhostInput
					onChange={event => handleChange(lastIndex + 1, event.target.value)}
					value={""}
					variant="outlined"
					placeholder={`Option ${lastIndex + 2}`}
					style={{ borderStyle: "dashed" }}
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<Marker dashed>{alphabet[lastIndex + 1].toUpperCase()}</Marker>
							</InputAdornment>
						)
					}}
				/>
			</FlexItem>
		</>
	);
};

const QuestionWrapper = styled(Box)`
	border: 1px solid #e4e9f2;
	border-radius: 4px;
	padding: 16px;
	margin-top: 32px;
	margin-bottom: 32px;
	&.rounded-corners {
		border-radius: 16px;
	}
`;

const QuestionInput = styled(InputBase)`
	width: 100%;
	margin-bottom: 24px;
`;

interface Props {
	onSelect: (poll: Omit<PollModelResponse, "totalResponses">) => void;
	onClose: () => void;
	open: boolean;
}

const CreatePoll: React.FC<Props> = ({ onSelect, onClose, open }) => {
	const { createPool } = usePoll();
	const { showMessage } = useNotification();
	const { handleSubmit, register, control, errors, watch } = useForm();
	const contentRef = useRef<HTMLDivElement>();

	const { getData: getUserData } = useUser();
	const { isMemberView } = getUserData();

	const { description, duration, answer } = watch(["description", "duration", "answer"]);

	const [loading, setLoading] = useState(false);

	const onSubmit = useCallback(
		async data => {
			setLoading(true);
			const result = await createPool({
				...data,
				duration: isMemberView ? parseInt(data.duration.value) : data.duration
			});
			setLoading(false);
			onSelect(result);
		},
		[createPool, isMemberView, onSelect]
	);

	const valid = useMemo(() => answer && answer.length > 1 && answer.every(x => x.length), [answer]);

	useEffect(() => {
		if (Object.keys(errors).length) {
			showMessage(
				<ul>
					{Object.values(errors).map((p, i) => (
						<li key={i}>{p.message}</li>
					))}
				</ul>,
				3
			);
		}
	}, [errors, showMessage]);

	const durationOptions = useMemo(
		() => [
			{ label: "1 day", value: 1 },
			{ label: "7 days", value: 7 },
			{ label: "14 days", value: 14 }
		],
		[]
	);

	const dialogContent = useMemo(
		() => (
			<form onSubmit={handleSubmit(onSubmit)}>
				<QuestionWrapper className={isMemberView && "rounded-corners"}>
					<QuestionInput
						name="description"
						placeholder="Ask a question"
						inputRef={register}
						required
						autoFocus={true}
					/>
					<Controller
						name="answer"
						control={control}
						defaultValue={["", ""]}
						render={({ value, onChange }) => <Options items={value} setItems={onChange} />}
					/>
				</QuestionWrapper>
				<FormGroupControl isMemberView={isMemberView}>
					<Icon name="message-double-check" group="filled" fill="#8f9bb3" />
					<Text>Allow multiple selection</Text>
					{isMemberView ? (
						<Controller
							name="mode"
							control={control}
							defaultValue={false}
							render={({ value, onChange }) => (
								<VyooSwitch checked={value} onChange={(_, checked: boolean) => onChange(checked)} />
							)}
						/>
					) : (
						<Switch name="mode" inputRef={register} />
					)}
				</FormGroupControl>
				<FormControl variant="outlined">
					{isMemberView ? (
						<Controller
							labelId="poll-duration-label"
							name="duration"
							control={control}
							rules={{ required: "Duration is required!" }}
							defaultValue={{ label: durationOptions[0].label, value: durationOptions[0].value }}
							render={({ value, onChange, ref }) => (
								<SelectInput
									label="Poll duration *"
									name="duration"
									value={value && value.label}
									onChange={onChange}
									placeholder=" "
									options={durationOptions.map(d => ({
										...d,
										value: `${d.value}`
									}))}
									maxHeight={180}
									displayOnly
									error={errors?.duration?.message}
									selectInputRef={ref}
									handleSelectWillOpen={() => {
										setTimeout(() => {
											if (contentRef?.current) contentRef.current?.parentElement?.scrollTo(0, 150);
										});
									}}
								/>
							)}
						/>
					) : (
						<>
							<InputLabel id="poll-duration-label">Poll Duration</InputLabel>
							<Controller
								labelId="poll-duration-label"
								name="duration"
								label="Poll Duration"
								as={Select}
								control={control}
								rules={{ required: "Duration is required!" }}
								IconComponent={ExpandMoreIcon}
							>
								{durationOptions.map((item, index) => (
									<MenuItem key={index} className="menuItem" value={item.value}>
										<Text>{item.label}</Text>
									</MenuItem>
								))}
							</Controller>
						</>
					)}
				</FormControl>
			</form>
		),
		[control, durationOptions, errors, handleSubmit, isMemberView, onSubmit, register]
	);

	return isMemberView ? (
		<MemberDialog
			title="Create Poll"
			open={open}
			onClose={onClose}
			customWidth={512}
			footerPrimary={{
				text: "Done",
				disabled: loading || !description || duration === "none" || !valid,
				onClick: handleSubmit(onSubmit)
			}}
			contentRef={contentRef as React.RefObject<HTMLDivElement>}
		>
			<MemberDialogContentWrapper>{dialogContent}</MemberDialogContentWrapper>
		</MemberDialog>
	) : (
		<Dialog
			title={Title}
			open={open}
			onClose={onClose}
			footer={
				<CreateButton
					buttonTheme="light"
					onClick={handleSubmit(onSubmit)}
					disabled={loading || !description || duration === "none" || !valid}
				>
					Done
				</CreateButton>
			}
		>
			{dialogContent}
		</Dialog>
	);
};

export default CreatePoll;
