import styled from "styled-components";

import { Box, Button, Text } from "shared/ui-kit";

export const DialogContent = styled.form`
	padding: 42px;
`;

export const TextBlock = styled(Text)`
	font-size: 18px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.33;
	letter-spacing: normal;
	text-align: center;
	color: #222b45;

	span {
		color: #6173fe;
	}
`;

export const ErrorText = styled(Text)`
	font-size: 18px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.33;
	letter-spacing: normal;
	text-align: center;
	color: #ff6138;

	margin-top: 20px;
`;

export const FormBlock = styled(Box)`
	display: flex;
	align-items: center;
	justify-content: center;

	margin-top: 32px;
`;

FormBlock.InputWrapper = styled(Box)`
	width: 48px;
	height: 62px;

	margin: 0 12px;
	padding: 0;

	border-radius: 8px;

	border: solid 1px #e4e9f2;

	overflow: hidden;

	&:focus-within {
		border-color: #151a30;
	}
`;

FormBlock.Input = styled.input`
	width: 100%;
	height: 100%;

	display: flex;
	justify-content: center;
	align-items: center;

	border: none;
	box-shadow: none;
	outline: none;

	font-size: 15px;
	font-weight: 600;
	line-height: 1.6;
	letter-spacing: normal;
	color: #8f9bb3;
	text-align: center;

	&::placeholder {
		color: #8f9bb3;
	}
`;

export const StyledActionButton = styled(Button)`
	height: 48px;
`;
