import { HTMLAttributes } from "react";

import Skeleton from "@material-ui/lab/Skeleton";
import styled, { css } from "styled-components";

import { ReactComponent as PlacesIcon } from "assets/icons/places.svg";
import { Box } from "shared/ui-kit";

export const Wrapper = styled(Box)<{ isMemberView?: boolean; autoheight?: boolean }>`
	width: calc(100% - 32px);
	height: ${({ autoheight }) => (autoheight ? "fit-content" : "112px")};

	margin: 16px auto 16px 0;
	padding: 16px;
	border-radius: 4px;
	box-shadow: inset 0 0 0 0.5px #c5cee0;
	position: relative;

	&.messageBlockCurrentUser {
		background-color: #4a99f9;
		box-shadow: unset;
		border-radius: 8px;
	}

	${({ isMemberView }) =>
		isMemberView &&
		css`
			border-radius: 8px;
			margin: 16px;
		`}

	${({ messagingView }) =>
		messagingView &&
		css`
			padding: 8px 16px;
			height: auto;
			width: 280px;
			margin: 0;
			margin-bottom: 6px;
		`}

	${({ maxWidth }) =>
		maxWidth &&
		css`
			max-width: 440px;
		`}

	${props => props.theme.breakpoints.up("sm")} {
		${({ messagingView }) =>
			messagingView &&
			css`
				padding: 16px;
				min-width: 360px;
			`}
	}
`;

export const Places = styled(PlacesIcon)`
	width: 100%;
	height: 100%;

	path {
		&:nth-child(2) {
			fill: #8f9bb3;
		}
	}
`;

export const Item = styled(Box)``;

Item.Type = styled(Box)`
	position: absolute;
	top: -8px;
	left: -8px;
	border-radius: 99%;
	background-color: #ffffff;
	width: 24px;
	height: 24px;
	display: flex;
	justify-content: center;
	align-items: center;
	svg {
		width: 16px;
		height: 16px;
	}
`;

Item.AvatarWrapper = styled(Box)`
	width: 80px;
	min-width: 80px;
	height: 80px;
	border-radius: 8px;
	position: relative;

	${props => props.theme.breakpoints.down("sm")} {
		&.messagingView {
			width: 56px;
			min-width: 56px;
			height: 56px;
		}
	}

	&.messagingView {
		svg {
			max-width: 100%;
			max-height: 100%;
		}
	}
`;

Item.Avatar = styled<HTMLAttributes<HTMLDivElement> & { img: string }>(Box)`
	width: 100%;
	height: 100%;

	background-color: ${({ img }) => (img ? "white" : "#f7f9fc")};

	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;

	${({ img }) => {
		return img
			? css`
					background-image: url(${img});
			  `
			: css`
					display: flex;
					justify-content: center;
					align-items: center;
			  `;
	}}
`;

Item.Body = styled(Box)`
	display: flex;
	justify-content: space-between;
	width: 100%;
`;

Item.MainBlock = styled(Box)`
	display: flex;
	width: 100%;

	&.align-center {
		align-items: center;
	}
	.part-size {
	}
`;

Item.EntityInfo = styled(Box)`
	width: 100%;
	height: 100%;

	padding: 5px 16px;

	display: flex;
	flex-direction: column;
	justify-content: space-between;

	&.center {
		justify-content: center;
	}

	${props => props.theme.breakpoints.down("sm")} {
		&.chatPadding {
			padding-left: 12px;
		}
	}
`;

Item.Pretitle = styled.span`
	font-size: 13px;
	line-height: 1.23;
	color: #222b45;
	&.white-text {
		color: #ffffff;
	}
	${props => props.theme.breakpoints.down("sm")} {
		&.messaging-pretitle {
			font-size: 12px;
		}
	}
`;

Item.Title = styled.span`
	text-transform: capitalize;
	font-size: 15px;
	font-weight: 600;
	line-height: 1.4;
	color: #222b45;
	margin: 0;
	&.white-text {
		color: #ffffff;
	}
	display: block;
	display: -webkit-box;
	-webkit-line-clamp: 2; /* if you change this, make sure to change the fallback line-height and height */
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;

	${props => props.theme.breakpoints.down("sm")} {
		&.messaging-title {
			font-size: 13px;
		}
	}
`;

Item.Subtitle = styled.span`
	font-size: 13px;
	line-height: 1.23;
	color: #8f9bb3;
	margin: 0;

	&.gray-text {
		color: #ffffff;
		opacity: 0.7;
	}

	display: block;
	display: -webkit-box;
	-webkit-line-clamp: 2; /* if you change this, make sure to change the fallback line-height and height */
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;

	${props => props.theme.breakpoints.down("sm")} {
		&.messaging-subtitle {
			font-size: 12px;
		}
	}
`;

Item.Members = styled(Box)`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;

	.member-avatar {
		width: 16px;
		height: 16px;
	}
`;

Item.ButtonWrapper = styled(Box)`
	text-align: right;
	margin: 16px 0;
`;

Item.MembersCounter = styled.p`
	margin: 0 0 0 8px;
	font-size: 13px;
	line-height: 1.23;
	color: #8f9bb3;
`;

export const Skelet = styled(Box)``;

Skelet.Avatar = styled(Skeleton)`
	width: 80px;
	min-width: 80px;
	height: 80px;

	transform: scale(1);

	border-radius: 8px;
`;

Skelet.Text = styled(Skeleton)`
	transform: scale(1);
`;
