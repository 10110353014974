import appConfig from "config/appConfig";

export const isAdSafe = (url?: string) => {
	if (!url) return false;
	const div = document.createElement("div");
	div.innerHTML = url;

	return (
		!!div.firstElementChild &&
		div.firstElementChild.tagName === "IFRAME" &&
		(div.firstElementChild as HTMLIFrameElement).src.startsWith(appConfig.GLOBAL_CONSTANTS.ADS_SERVER_URL as string)
	);
};
