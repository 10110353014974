import React, { useCallback, useEffect } from "react";

import { debounce } from "@material-ui/core/utils";
import config from "config/appConfig";
// eslint-disable-next-line import/no-named-as-default
import ReCAPTCHA from "react-google-recaptcha";
import { useHistory } from "react-router-dom";
import styled, { css } from "styled-components";

import { ReactComponent as SuccessIcon } from "assets/icons/illustration-success.svg";
import { ReactComponent as SuccessSportsIcon } from "assets/icons/winners.svg";

import { ConfettiParticles } from "shared/Components";
import { useCommunity, useGTM, useUser } from "shared/hooks";
import { Box, Button, FormFooter, Loader, Text } from "shared/ui-kit";

import { ContentWrapper, FormBlock } from "../../Components";

const IconWrapper = styled(Box)`
	display: flex;
	justify-content: center;
	align-items: center;

	margin-top: 25px;

	${props => props.theme.breakpoints.down("sm")} {
		margin-top: 56px;
	}
`;

const FormBlockWrapper = styled.section`
	margin-top: 8px;
`;

const Title = styled(Text)<{ modifyFont: boolean }>`
	margin: 0 auto;
	max-width: 350px;

	${props => props.theme.breakpoints.down("sm")} {
		text-align: left;
	}
	${({ modifyFont }) => {
		if (modifyFont) {
			return css`
				font-size: 1.4rem;
			`;
		}
	}}
`;

const TextInfo = styled(Text)`
	margin-top: 45px;

	${props => props.theme.breakpoints.down("sm")} {
		text-align: center;

		max-width: 250px;

		margin: 35px auto 0 auto;
	}
`;

const CaptchaContainer = styled(Box)`
	margin: 20px 0 12px 0;
`;

export interface CongratulationProps {
	homeUrl: string;
	submitUrl: string;
}

const Congratulation: React.FC<CongratulationProps> = ({ homeUrl, submitUrl }) => {
	const history = useHistory();

	const { setConfirmedCaptcha, setSubmittedCreatedCommunity, getData: getCommunityData } = useCommunity();
	const { isConfirmedCaptcha, creating, isSportsMode } = getCommunityData();

	const { addEvent } = useGTM();

	const { clearWorkspaces } = useUser();

	useEffect(() => {
		addEvent({ Action: "Create Community", Label: "Community Set Up Finalized" });
	}, [addEvent]);

	const submit = useCallback(() => {
		clearWorkspaces();
		const debounced = debounce(() => {
			setSubmittedCreatedCommunity();
			history.push(homeUrl);
		}, 500);
		debounced();
	}, [clearWorkspaces, history, homeUrl, setSubmittedCreatedCommunity]);

	return (
		<>
			{isConfirmedCaptcha && <ConfettiParticles />}
			<ContentWrapper>
				<FormBlockWrapper>
					<FormBlock customStyle={"padding-bottom: 50px;"}>
						<Title variant="h2" modifyFont={!isConfirmedCaptcha} align={"center"}>
							{isConfirmedCaptcha
								? "Your community has been set up!"
								: "Hang tight, we are creating your awesome community now"}
						</Title>
						<IconWrapper>{isSportsMode ? <SuccessSportsIcon /> : <SuccessIcon />}</IconWrapper>
						<TextInfo variant="body1">Manage features, subscriptions packages & invite users!</TextInfo>
						<FormFooter>
							<CaptchaContainer>
								<ReCAPTCHA
									onExpired={() => setConfirmedCaptcha(false)}
									onChange={() => {
										setConfirmedCaptcha(true);
										addEvent({ Action: "Create Community", Label: "Community Set Up Submitted" });
										history.push(submitUrl);
									}}
									sitekey={`${config.GLOBAL_CONSTANTS.RECAPTCHA_SECRET_KEY}`}
								/>
							</CaptchaContainer>
							<Button
								removeSideMargin
								fullWidth
								disabled={!isConfirmedCaptcha || creating}
								size="large"
								onClick={submit}
							>
								{!creating ? (
									<>{isSportsMode ? "Start" : "Continue"}</>
								) : (
									<Loader size="1rem" show={true} color="inherit" variant="indeterminate" />
								)}
							</Button>
						</FormFooter>
					</FormBlock>
				</FormBlockWrapper>
			</ContentWrapper>
		</>
	);
};

export default Congratulation;
