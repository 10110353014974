import styled from "styled-components";

export const HiddenInput = styled.input`
	position: absolute;
	top: 0;
	left: 0;

	opacity: 0;

	width: 100%;
	height: 100%;

	cursor: pointer;

	z-index: 5;

	&::-webkit-file-upload-button {
		cursor: pointer;
	}
`;
