export enum actionTypes {
	create = "create",
	update = "update",
	delete = "delete",
	required = "required",
	invited = "invited",
	archived = "archived",
	unarchived = "unarchived",
	removed = "removed",
	resent = "resent",
	cancelled = "canceled",
	invalidEmailAddress = "invalidEmailAddress",
	duplicate = "duplicate",
	enabled = "enabled",
	disabled = "disabled",
	CRUD = "CRUD",
	CRUDUser = "CRUDUser"
}

export enum actionMethod {
	created = "created",
	updated = "updated",
	deleted = "deleted",
	donated = "donated",
	saved = "saved",
	notSaved = "not saved",
	invited = "invited",
	archived = "archived",
	deactivated = "deactivated",
	unarchived = "unarchived",
	activated = "activated",
	removed = "removed",
	resent = "resent",
	cancelled = "cancelled",
	enabled = "enabled",
	disabled = "disabled",
	banned = "banned",
	unbanned = "unbanned",
	blocked = "blocked",
	active = "activate",
	deactive = "deactivate",
	pinned = "pinned",
	unpinned = "unpinned",
	sent = "sent"
}

interface IShowValidationMessage {
	name?: string;
	actionType?: actionTypes;
	actionMethod?: actionMethod;
	emoji?: any;
	multiple?: boolean;
}

export const getValidationMessage = ({ name, actionType, actionMethod, emoji, multiple }: IShowValidationMessage) => {
	switch (actionType) {
		case actionTypes.CRUD:
			return `${name || ""} ${multiple ? "have" : "has"} been successfully ${actionMethod} ${!!emoji ? emoji : ""}`;
		case actionTypes.required:
			return `${name || ""} is required`;
		case actionTypes.CRUDUser:
			return `${name || ""} ${multiple ? "have" : "has"} been ${actionMethod} ${!!emoji ? emoji : ""}`;
		default:
			return `${name || ""} ${!!emoji ? emoji : ""}`;
	}
};
