import React, { ReactNode, useCallback, useEffect, useMemo, useRef } from "react";

import { Box } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

import Masonry from "react-masonry-css";
import { FileType } from "types/FilesContextValuesType";

import { Podcast } from "types/PodcastsContextValuesType";

import { ConversationWithEvent } from "modules/LiveConversation/Data/types";
import { SeriesCollection } from "modules/Manage/Data/types";
import { useOnScreen, usePopulateWithAds } from "shared/hooks";
import { AlbumModel } from "shared/services/useAlbumApiService";
import { AdWrapper } from "shared/styles";
import {
	EventModel,
	FundraiserModel,
	GroupModel,
	PersonStoryModel,
	ProfileType,
	TrackModel,
	VideoModel,
	VolunteerSlot
} from "shared/types";

import { AdZone } from "utils/ads/getRandomAd";

import {
	EmptyBox,
	ItemWrapper,
	ListWrapper,
	MessageText,
	SkeletonConnection,
	SkeletonWrapper,
	VolunteersAvatar
} from "./style";

import {
	BoxItem,
	ConnectionCard,
	EventCard,
	FileCard,
	FundraiserCard,
	GroupCard,
	LiveConvoCard,
	SeriesCard,
	Slider,
	TrackCard,
	VideoCard,
	VolunteerCard
} from "../index";

const breakpointColumnsObj = {
	default: 3,
	1100: 3,
	700: 2,
	500: 1
};

export enum ModelBlockType {
	connection,
	event,
	group,
	track,
	file,
	video,
	series,
	album,
	liveconvo,
	liveUpcomingEvent,
	podcasts,
	fundraisers,
	volunteerSlot
}

interface ModelBlockProps {
	title?: string;
	idPrefix?: string;
	inlineView?: boolean;
	onEndScroll?: () => void;
	noContent?: string | ReactNode;
	type: ModelBlockType;
	items: (
		| ProfileType
		| EventModel
		| GroupModel
		| TrackModel
		| FileType
		| VideoModel
		| SeriesCollection
		| AlbumModel
		| ConversationWithEvent
		| PersonStoryModel
		| Podcast
		| FundraiserModel
		| VolunteerSlot
	)[];
	modelType?: string;
	onManage?: (data: any) => void;
	autoFit?: boolean | number;
	gridGap?: number;
	loading?: boolean;
	skeletonNum?: number;
	keepCurrentData?: boolean;
	isMasonry?: boolean;
	customProp?;
}

const ModelBlock: React.FC<ModelBlockProps> = ({
	title,
	idPrefix,
	inlineView,
	onEndScroll,
	noContent,
	items,
	type,
	modelType,
	autoFit,
	gridGap,
	loading,
	// skeletonNum,
	keepCurrentData,
	isMasonry,
	customProp,
	...rest
}) => {
	const lastItemRef = useRef<HTMLDivElement>(null);
	const onScreen = useOnScreen(lastItemRef);

	useEffect(() => {
		if (onScreen && onEndScroll) {
			onEndScroll();
		}
	}, [onEndScroll, onScreen]);

	const renderItem = useCallback(
		(
			item:
				| AlbumModel
				| ProfileType
				| EventModel
				| GroupModel
				| TrackModel
				| FileType
				| VideoModel
				| SeriesCollection
				| ConversationWithEvent
				| PersonStoryModel
				| Podcast
				| FundraiserModel
				| VolunteerSlot
				| string,
			index: number
		): ReactNode => {
			if (typeof item === "string") {
				return <AdWrapper className="see_all--wrapper" dangerouslySetInnerHTML={{ __html: item }} />;
			}

			if (type === ModelBlockType.connection) {
				return (
					<ConnectionCard
						connection={item as ProfileType}
						isRequest={modelType === "request"}
						isSuggestion={modelType === "suggestion"}
						isConnected={modelType === "connected"}
						{...rest}
					/>
				);
			}

			if ([ModelBlockType.event, ModelBlockType.liveUpcomingEvent].includes(type)) {
				return <EventCard event={item as EventModel} isLiveUpcoming={type === ModelBlockType.liveUpcomingEvent} />;
			}

			if (type === ModelBlockType.group) {
				return <GroupCard group={item as GroupModel} index={index} idPrefix={idPrefix} />;
			}

			if (type === ModelBlockType.track) {
				return (
					<TrackCard
						type={ModelBlockType.track}
						item={item as TrackModel}
						allItems={items as (TrackModel | AlbumModel)[]}
					/>
				);
			}

			if (type === ModelBlockType.album) {
				return (
					<TrackCard
						hidePlay={!(item as AlbumModel).musicIds.length || (item as AlbumModel).locked}
						type={ModelBlockType.album}
						item={item as AlbumModel}
						allItems={items as (TrackModel | AlbumModel)[]}
					/>
				);
			}

			if (type === ModelBlockType.video) {
				return <VideoCard video={item as VideoModel} highlightOnHover />;
			}

			if (type === ModelBlockType.series) {
				return <SeriesCard series={item as SeriesCollection} />;
			}

			if (type === ModelBlockType.file) {
				return <FileCard file={item as FileType} highlightOnHover />;
			}
			if (type === ModelBlockType.liveconvo) {
				return <LiveConvoCard conversation={item as ConversationWithEvent} />;
			}

			if (type === ModelBlockType.podcasts) {
				return <TrackCard hidePlay type={ModelBlockType.podcasts} item={item as Podcast} />;
			}

			if (type === ModelBlockType.fundraisers) {
				return <FundraiserCard fundraiser={item as FundraiserModel} />;
			}

			if (type === ModelBlockType.volunteerSlot) {
				return <VolunteerCard volunteerSlot={item as VolunteerSlot} customProp={customProp} />;
			}

			return null;
		},
		[type, modelType, rest, items, idPrefix, customProp]
	);

	const renderSkeletonItem = useMemo((): ReactNode => {
		if (type === ModelBlockType.connection) {
			return (
				<SkeletonWrapper>
					<SkeletonConnection.Header>
						<Skeleton variant="rect" animation="wave" width={"100%"} height={64} />
						<SkeletonConnection.HeaderAvatar>
							<Skeleton variant="circle" animation="wave" width={80} height={80} />
						</SkeletonConnection.HeaderAvatar>
					</SkeletonConnection.Header>
					<SkeletonConnection.Body>
						<Skeleton variant="text" animation="wave" width={"80%"} height={26} />
						<SkeletonConnection.BodyExtra>
							<Skeleton variant="text" animation="wave" width={"80%"} height={20} />
						</SkeletonConnection.BodyExtra>
						<Skeleton variant="text" animation="wave" width={"90%"} height={40} />
					</SkeletonConnection.Body>
				</SkeletonWrapper>
			);
		}

		if (type === ModelBlockType.event || type === ModelBlockType.liveconvo || type === ModelBlockType.fundraisers) {
			return (
				<SkeletonWrapper>
					<Skeleton variant="rect" animation="wave" width={"100%"} height={160} />
					<Skeleton variant="text" animation="wave" width={"40%"} height={16} />
					<Skeleton variant="text" animation="wave" width={"80%"} height={24} />
					<Skeleton variant="text" animation="wave" width={"90%"} height={16} />
				</SkeletonWrapper>
			);
		}

		if (type === ModelBlockType.group) {
			return (
				<SkeletonWrapper>
					<Skeleton variant="rect" animation="wave" width={"100%"} height={160} />
					<Skeleton variant="text" animation="wave" width={"30%"} height={16} />
					<Skeleton variant="text" animation="wave" width={"60%"} height={22} />
					<Skeleton variant="text" animation="wave" width={"80%"} height={32} />
					<Skeleton variant="text" animation="wave" width={100} height={40} />
				</SkeletonWrapper>
			);
		}

		if (type === ModelBlockType.track || type === ModelBlockType.album) {
			return (
				<SkeletonWrapper>
					<Skeleton variant="rect" animation="wave" width={"100%"} height={164} />
					<Skeleton variant="text" animation="wave" width={"50%"} height={16} />
					<Skeleton variant="text" animation="wave" width={"80%"} height={24} />
					<Skeleton variant="text" animation="wave" width={"30%"} height={16} />
				</SkeletonWrapper>
			);
		}

		if (type === ModelBlockType.video) {
			return (
				<SkeletonWrapper>
					<Skeleton variant="rect" animation="wave" width={"100%"} height={160} />
					<Skeleton variant="text" animation="wave" width={"50%"} height={16} />
					<Skeleton variant="text" animation="wave" width={"60%"} height={22} />
				</SkeletonWrapper>
			);
		}

		if (type === ModelBlockType.series) {
			return (
				<SkeletonWrapper>
					<Skeleton variant="rect" animation="wave" width={"100%"} height={160} />
					<Skeleton variant="text" animation="wave" width={"60%"} height={22} />
				</SkeletonWrapper>
			);
		}

		if (type === ModelBlockType.file) {
			return (
				<SkeletonWrapper>
					<Skeleton variant="rect" animation="wave" width={"100%"} height={160} />
					<Skeleton variant="text" animation="wave" width={"50%"} height={16} />
					<Skeleton variant="text" animation="wave" width={"60%"} height={22} />
				</SkeletonWrapper>
			);
		}

		if (type === ModelBlockType.volunteerSlot) {
			return (
				<SkeletonWrapper marginBottom={0} borderRadius={"6px"} className="p-4" isWhiteBg>
					<Box className="flex mb-5">
						<Skeleton variant="text" animation="wave" width={40} height={40} />
						<Box className="flex flex-col justify-between px-4 w-10/12">
							<Skeleton variant="text" animation="wave" width={"70%"} height={10} />
							<Skeleton variant="text" animation="wave" width={"50%"} height={10} />
						</Box>
						<VolunteersAvatar className="flex">
							<Skeleton variant="circle" animation="wave" width={20} height={20} />
							<Skeleton variant="circle" animation="wave" width={20} height={20} />
							<Skeleton variant="circle" animation="wave" width={20} height={20} />
						</VolunteersAvatar>
					</Box>
					<Skeleton variant="text" animation="wave" width={"100%"} height={40} />
				</SkeletonWrapper>
			);
		}

		return null;
	}, [type]);

	const itemClass = useMemo(() => {
		switch (type) {
			case ModelBlockType.track:
			case ModelBlockType.album:
				return "track";

			case ModelBlockType.video:
				return "video";

			case ModelBlockType.series:
				return "series";

			case ModelBlockType.event:
			case ModelBlockType.fundraisers:
				return "event";

			case ModelBlockType.group:
				return "group";

			case ModelBlockType.connection:
				return "connection";

			case ModelBlockType.file:
				return "file";

			case ModelBlockType.volunteerSlot:
				return "w-full mx-0";
		}
	}, [type]);

	const itemSize = useMemo(() => {
		switch (type) {
			case ModelBlockType.video:
			case ModelBlockType.track:
			case ModelBlockType.album:
				return 258;

			case ModelBlockType.event:
			case ModelBlockType.group:
			case ModelBlockType.series:
				return 320;

			case ModelBlockType.connection:
				return 208;
		}
	}, [type]);

	const itemsWithAds = usePopulateWithAds({
		list: items,
		zone: AdZone.seeAllZone,
		every: 10,
		skip: 5
	});

	return (
		<BoxItem title={title} pageItem>
			{!!itemsWithAds.length && (!loading || keepCurrentData) && (
				<ListWrapper autoFit={autoFit} gridGap={gridGap}>
					{inlineView ? (
						<>
							{itemsWithAds.map((item, index) => (
								<ItemWrapper
									ref={index + 1 === itemsWithAds.length ? lastItemRef : null}
									key={index}
									className={`v-inline ${autoFit ? "auto-fit" : itemClass}`}
								>
									{renderItem(item, index)}
								</ItemWrapper>
							))}
						</>
					) : isMasonry ? (
						<Masonry
							breakpointCols={breakpointColumnsObj}
							className="my-masonry-grid"
							columnClassName="my-masonry-grid_column"
						>
							{itemsWithAds.map((item, index) => (
								<ItemWrapper key={index} className={`v-inline ${autoFit ? "auto-fit" : itemClass}`}>
									{renderItem(item, index)}
								</ItemWrapper>
							))}
							<div ref={itemsWithAds.length ? lastItemRef : null} />
							{loading &&
								Array.from(Array(8).keys()).map((_, i) => (
									<ItemWrapper key={i} className={`v-inline ${itemClass}`}>
										{renderSkeletonItem}
									</ItemWrapper>
								))}
						</Masonry>
					) : (
						<Slider count={itemsWithAds.length} itemSize={itemSize} onEndScroll={onEndScroll} hideFrom={2}>
							{itemsWithAds.map((item, index) => (
								<ItemWrapper key={index} className={autoFit ? "auto-fit" : itemClass}>
									{renderItem(item, index)}
								</ItemWrapper>
							))}
							{loading &&
								Array.from(Array(8).keys()).map((_, i) => (
									<ItemWrapper key={i} className={`v-inline ${itemClass}`}>
										{renderSkeletonItem}
									</ItemWrapper>
								))}
						</Slider>
					)}
				</ListWrapper>
			)}
			{loading && (
				<>
					{inlineView ? (
						<>
							<ListWrapper autoFit={autoFit} gridGap={gridGap}>
								{Array.from(Array(8).keys()).map((_, i) => (
									<ItemWrapper key={i} className={`v-inline ${autoFit ? "auto-fit" : itemClass}`}>
										{renderSkeletonItem}
									</ItemWrapper>
								))}
							</ListWrapper>
						</>
					) : isMasonry && !itemsWithAds.length ? (
						<Masonry
							breakpointCols={breakpointColumnsObj}
							className="my-masonry-grid"
							columnClassName="my-masonry-grid_column"
						>
							{Array.from(Array(8).keys()).map((_, i) => (
								<Box key={i} className="mb-4">
									{renderSkeletonItem}
								</Box>
							))}
						</Masonry>
					) : !keepCurrentData ? (
						<Slider count={8} itemSize={(itemSize || 258) - 10}>
							{Array.from(Array(8).keys()).map((_, i) => (
								<ItemWrapper key={i} className={`v-inline ${itemClass}`}>
									{renderSkeletonItem}
								</ItemWrapper>
							))}
						</Slider>
					) : null}
				</>
			)}
			{!itemsWithAds.length && !loading && noContent && (
				<EmptyBox>{typeof noContent === "string" ? <MessageText>{noContent}</MessageText> : noContent}</EmptyBox>
			)}
		</BoxItem>
	);
};

export default ModelBlock;
