import React, { useMemo } from "react";

import { Box, useMediaQuery } from "@material-ui/core";

import Skeleton from "@material-ui/lab/Skeleton";

import { ReactComponent as IconFile } from "assets/icons/icon-file.svg";
import { ReactComponent as IconVideo } from "assets/icons/icon-video.svg";
import { ReactComponent as SoundWaveIcon } from "assets/icons/iconSoundWave.svg";
import { ItemId } from "modules/Manage/Data/types/SeriesTypes";
import { UnlockPremiumBtn } from "shared/Components";
import { TrackMeta, UnlockContentType } from "shared/types";
import * as appTheme from "theme/default";

import RowTemplate from "./RowTemplate";

import { SkeletonWrapper } from "../../Containers/Collection/style";

export enum CollectionSkeletonTypesEnum {
	DEFAULT,
	PODCAST
}
interface Props {
	item?: ItemId;
	order?: number;
	mini?: boolean;
	skeleton?: boolean;
	skeletonType?: CollectionSkeletonTypesEnum;
	onClick?: () => void;
	current?: boolean;
	onUnlock?: () => void;
	canEdit?: boolean;
	allowDownload?: boolean;
}

const CollectionRow: React.FC<Props> = ({
	item,
	order,
	onClick,
	skeleton,
	skeletonType = CollectionSkeletonTypesEnum.DEFAULT,
	mini,
	current,
	canEdit,
	onUnlock,
	allowDownload
}) => {
	const isMobile = useMediaQuery(appTheme.default.breakpoints.down("sm"));

	const skeletonBase = useMemo(
		() => (
			<Box className={`row ${skeletonType === CollectionSkeletonTypesEnum.PODCAST ? "podcast-row" : ""}`}>
				{!isMobile && <Skeleton width={20} height={20} variant="circle" className="number" />}
				<Skeleton width={40} height={40} className="thumbnail" />
				<Box>
					<Skeleton width={240} height={21} className="headline" />
					<Skeleton width={140} height={16} />
				</Box>
			</Box>
		),
		[isMobile, skeletonType]
	);

	const skeletonRow = useMemo(
		() =>
			skeletonType === CollectionSkeletonTypesEnum.PODCAST ? (
				<SkeletonWrapper className="podcast-skeleton">
					{skeletonBase}
					{!isMobile && (
						<Box className="row podcast-right-row">
							<Skeleton width={24} height={24} className="headline" />
							<Skeleton width={120} height={24} className="headline" />
							<Box className="row">
								<Skeleton width={32} height={32} variant="circle" className="play" />
								<Skeleton width={4} height={24} />
							</Box>
						</Box>
					)}
				</SkeletonWrapper>
			) : (
				<SkeletonWrapper>
					{skeletonBase}
					{!mini && !isMobile && (
						<>
							<Box className="row">
								<Skeleton width={40} height={40} className="headline" />
							</Box>
							<Box className="row">
								<Skeleton width={280} height={40} className="headline" />
							</Box>
						</>
					)}
				</SkeletonWrapper>
			),
		[skeletonType, skeletonBase, mini, isMobile]
	);

	if (!item || !order || skeleton) {
		return skeletonRow;
	}

	if (item.type === "VIDEO" && item.video) {
		return (
			<RowTemplate
				order={order}
				name={item.video.title}
				icon={<IconVideo />}
				category={item.video.category ? item.video.category.name : ""}
				createdAt={item.video.createdAt}
				duration={item.video.duration}
				image={item.video?.meta?.artwork?.url}
				key={item._id}
				type={item.type}
				mini={mini}
				handleClick={onClick}
				locked={item.video.locked}
				stream={item.video.stream}
				current={current}
				canEdit={canEdit}
				downloadUrl={allowDownload ? item.video.meta.video.url : undefined}
				unlockButton={
					item.video.locked && !!item.video.priceTags.length && !!onUnlock ? (
						<UnlockPremiumBtn
							premiumObject={item.video}
							premiumObjectType={UnlockContentType.VIDEO}
							onUnlock={onUnlock}
						/>
					) : undefined
				}
			/>
		);
	}

	if (item.type === "FILE" && item.file) {
		return (
			<RowTemplate
				order={order}
				name={item.file.title || ""}
				icon={<IconFile />}
				category={item.file.category?.name || ""}
				createdAt={item.file.createdAt}
				image={item.file.coverPhoto.url}
				type={item.type}
				mini={mini}
				handleClick={onClick}
				locked={item.file.locked || false}
				current={current}
				canEdit={canEdit}
				downloadUrl={allowDownload ? item.file.file.url : undefined}
				unlockButton={
					item.file.locked && item.file.priceTags && !!item.file?.priceTags.length && !!onUnlock ? (
						<UnlockPremiumBtn
							premiumObject={item.file}
							premiumObjectType={UnlockContentType.FILE}
							onUnlock={onUnlock}
						/>
					) : undefined
				}
			/>
		);
	}

	if (item.type === "TRACK" && item.music) {
		return (
			<RowTemplate
				order={order}
				name={item.music.title}
				icon={<SoundWaveIcon />}
				category={item.music.genre[0]}
				image={(item.music.meta as TrackMeta).artwork.url || ""}
				createdAt={item.music.createdAt}
				duration={item.music.duration}
				type={item.type}
				mini={mini}
				handleClick={onClick}
				locked={item.music.locked}
				stream={item.music.stream}
				current={current}
				canEdit={canEdit}
				downloadUrl={allowDownload ? (item.music.meta as TrackMeta).track.url : undefined}
				unlockButton={
					item.music.locked && item.music.priceTags && !!item.music?.priceTags.length && !!onUnlock ? (
						<UnlockPremiumBtn
							premiumObject={item.music}
							premiumObjectType={UnlockContentType.TRACK}
							onUnlock={onUnlock}
						/>
					) : undefined
				}
			/>
		);
	}

	return null;
};

export default CollectionRow;
