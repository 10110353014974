import { IStateProps } from "utils/StateOf";

import createStore from "utils/createStore";

import { OfferRequest } from "../../types/EventModel";

export interface Values {
	creating: boolean;
	carpoolRequests: OfferRequest[];
	carpoolOffers: OfferRequest[];
}

export const initialState: IStateProps<Values> = {
	creating: false,
	carpoolRequests: [],
	carpoolOffers: []
};

export default createStore<Values>({
	initialState
});
