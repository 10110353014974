import React, { useCallback, useMemo, useState } from "react";

import * as R from "ramda";

import { ReactComponent as BlockIcon } from "assets/icons/liveconvo/block.svg";
import { ReactComponent as EarIcon } from "assets/icons/liveconvo/ear.svg";
import { ReactComponent as HandOffIcon } from "assets/icons/liveconvo/hand-off.svg";
// import { ReactComponent as MessageIcon } from "assets/icons/liveconvo/message.svg";
import { ReactComponent as MicOffIcon } from "assets/icons/liveconvo/mic-off.svg";
import { ReactComponent as MicroPhoneMutedIcon } from "assets/icons/liveconvo/mic_muted.svg";
import { ReactComponent as MicroPhoneFilledIcon } from "assets/icons/liveconvo/microphone-filled.svg";
import { ReactComponent as PinIcon } from "assets/icons/liveconvo/pin.svg";
import { ReactComponent as ProfileIcon } from "assets/icons/liveconvo/profile.svg";
import { ReactComponent as RaiseHandIcon } from "assets/icons/liveconvo/raise-hand.svg";
import { ReactComponent as RemoveIcon } from "assets/icons/liveconvo/remove.svg";
import { ReactComponent as ReportIcon } from "assets/icons/liveconvo/report.svg";
import { ReactComponent as UnpinIcon } from "assets/icons/liveconvo/unpin.svg";

import { useFirebaseLiveConversation, useLiveConversation } from "modules/LiveConversation/Data/hooks";

import { MenuDots, MenuDotsOption } from "shared/Components";
import { useDebounce, useMemberRoutes, useReportContent } from "shared/hooks";

import { ProfilePhotoSizes, ReportContentType } from "shared/types";
import { getResizedImage } from "utils/serviceUtils/cdnImages";

import { ParticipantItem } from "./style";

import { ConvoUserRoles, FirebaseParticipant, SidebarType } from "../../../Data/types";

import { SideBar } from "../../Components/Sidebar/style";
import EmptyBox from "../Comments/Empty";
import ConfirmAlertModal from "../ConfirmAlertModal";
import { InviteParticipants, SidebarHeader } from "../index";

const iconProps = {
	fill: "#8F9BB3",
	width: 20,
	height: 20
};

interface ParticipantsProps {
	personaId: number;
	isAdmin: boolean;
	sidebarType: SidebarType;
}

const Participants: React.FC<ParticipantsProps> = ({ personaId: myPersonaId, isAdmin, sidebarType }) => {
	const { setPinnedUser, blockUserByEntity, getData: getLiveConversationData } = useLiveConversation();
	const { firebaseParticipants, controls, raisedHands, config, event, pinnedPersonaId } = getLiveConversationData();

	const { getReportTypeOptions } = useReportContent();

	const { muteParticipant, lowerRaisedHand, toggleSpeaker, removeParticipant, updateParticipant } =
		useFirebaseLiveConversation();

	const { getMemberRoutesData } = useMemberRoutes();
	const { routes } = getMemberRoutesData();

	const [selectedParticipant, setSelectedParticipant] = useState<FirebaseParticipant>();
	const [showInvite, setShowInvite] = useState(false);
	const [searchKeyword, setSearchKeyword] = useState("");

	const debouncedKeyWord = useDebounce(searchKeyword, 250);

	const totalParticipants = firebaseParticipants.length;

	const toggleShowInvite = () => {
		setShowInvite(!showInvite);
	};

	const getParticipantOptions = (participant: FirebaseParticipant) => {
		const { firstName, lastName, personaId, isSpeaker, userRole } = participant;
		const isAdminCurrent = userRole === ConvoUserRoles.admin;

		const actions: MenuDotsOption[] = [];

		if (isSpeaker && totalParticipants > 1) {
			const isPinned = pinnedPersonaId === personaId;
			actions.push({
				name: `${isPinned ? "Unpin" : "Pin"} ${firstName}`,
				onClick: () => {
					setPinnedUser(isPinned ? undefined : personaId);
				},
				icon: isPinned ? <UnpinIcon {...iconProps} /> : <PinIcon {...iconProps} />
			});
		}

		if (isAdmin) {
			if (!controls[personaId]?.muted && !isAdminCurrent && isSpeaker) {
				actions.push({
					name: `Mute ${firstName}`,
					onClick: () => {
						muteParticipant(personaId);
					},
					icon: <MicOffIcon {...iconProps} />
				});
			}

			if (!isAdminCurrent) {
				actions.push({
					name: `Make ${firstName} a ${isSpeaker ? "Listener" : "Speaker"}`,
					onClick: () => {
						toggleSpeaker(personaId, !isSpeaker);
					},
					icon: <EarIcon {...iconProps} />
				});

				actions.push({
					name: "Remove from the Live Conversation",
					onClick: () => {
						removeParticipant(personaId);
					},
					icon: <RemoveIcon {...iconProps} />
				});
			}

			if (raisedHands[personaId]) {
				actions.push({
					name: `Lower ${firstName}'s hand`,
					onClick: () => {
						lowerRaisedHand(personaId);
					},
					icon: <HandOffIcon {...iconProps} />
				});
			}

			actions.push({
				name: "Block",
				onClick: () => setSelectedParticipant(participant),
				icon: <BlockIcon {...iconProps} />
			});
		}

		/*
			once UI is ready for below action, we should uncomment.
			actions.push({
				name: "Send Private Message",
				onClick: () => {
					alert("screen WIP");
				},
				icon: <MessageIcon {...iconProps} />
			}); */
		actions.push({
			name: `View ${firstName}'s Profile`,
			onClick: () => {
				window.open(`${routes?.member.profile.getPath()}/${personaId}`, "_blank");
			},
			icon: <ProfileIcon {...iconProps} />
		});

		actions.push({
			name: "Report",
			icon: <ReportIcon {...iconProps} />,
			onClick: () => {},
			submenuOptions: getReportTypeOptions({
				reportType: ReportContentType.LIVE_PARTICIPANT,
				reportContentId: `${participant.personaId}`,
				reportContentName: `${firstName} ${lastName}`
			})
		});

		return actions;
	};

	const renderParticipant = (participant: FirebaseParticipant) => {
		const { firstName, lastName, isSpeaker, userRole, avatarUrl, personaId } = participant;
		const isMuted = !!controls[personaId]?.muted;
		const raisedHand = !!raisedHands[personaId];
		const isMe = myPersonaId === personaId;

		const imgUrl = avatarUrl ? getResizedImage(avatarUrl, ProfilePhotoSizes.size50x50) : "";

		return (
			<ParticipantItem.Wrapper key={personaId}>
				<ParticipantItem>
					<ParticipantItem.Info>
						<ParticipantItem.AvatarWrapper>
							<ParticipantItem.Avatar style={{ backgroundImage: `url(${imgUrl})` }} />
							{isSpeaker ? (
								<ParticipantItem.AvatarIconWrapper>
									{isMuted ? (
										<MicroPhoneMutedIcon fill="#a8afbe" width={12} height={12} />
									) : (
										<MicroPhoneFilledIcon fill="#41b34a" width={12} height={12} />
									)}
								</ParticipantItem.AvatarIconWrapper>
							) : raisedHand ? (
								<ParticipantItem.AvatarIconWrapper>
									<RaiseHandIcon width={12} height={12} />
								</ParticipantItem.AvatarIconWrapper>
							) : null}
						</ParticipantItem.AvatarWrapper>
						<ParticipantItem.BaseInfo>
							<ParticipantItem.Name>
								{firstName} {lastName} {isMe ? "(You)" : ""}
							</ParticipantItem.Name>
							{(isSpeaker || raisedHand) && (
								<ParticipantItem.Action>
									{isSpeaker
										? userRole === ConvoUserRoles.admin
											? "Admin"
											: isSpeaker
											? "Speaker"
											: "Participant"
										: "Raised Hand"}
								</ParticipantItem.Action>
							)}
						</ParticipantItem.BaseInfo>
					</ParticipantItem.Info>
					{!isMe && (
						<MenuDots options={getParticipantOptions(participant)} inverted memberView size={{ small: true }} />
					)}
				</ParticipantItem>
			</ParticipantItem.Wrapper>
		);
	};

	const blockParticipant = () => {
		if (event?.eventId && selectedParticipant?.personaId) {
			updateParticipant(selectedParticipant.personaId, {
				blocked: true
			});

			blockUserByEntity({
				personaId: selectedParticipant.personaId,
				entity: "event",
				id: event?.eventId
			});
			setSelectedParticipant(undefined);
		}
	};

	const queryExist = useCallback(
		(params: { firstName: string; lastName: string }) => {
			if (!debouncedKeyWord) return true;
			const q = debouncedKeyWord.toLowerCase();
			const fName = params.firstName.toLowerCase();
			const lName = params.lastName.toLowerCase();
			return fName.includes(q) || lName.includes(q);
		},
		[debouncedKeyWord]
	);

	const speakers = useMemo(
		() =>
			firebaseParticipants.filter(
				({ isSpeaker, firstName, lastName }) => isSpeaker && queryExist({ firstName, lastName })
			),
		[firebaseParticipants, queryExist]
	);

	const raisedUsers = useMemo(() => {
		const users = config.enableHandRaise
			? firebaseParticipants.filter(
					({ isSpeaker, firstName, lastName, personaId }) =>
						!isSpeaker && raisedHands[personaId] && queryExist({ firstName, lastName })
			  )
			: [];

		return R.sortBy(user => {
			const time = raisedHands[user.personaId];
			return typeof time === "boolean" ? 0 : time;
		}, users);
	}, [firebaseParticipants, raisedHands, config.enableHandRaise, queryExist]);

	const listeners = useMemo(
		() =>
			firebaseParticipants.filter(
				({ isSpeaker, firstName, lastName, personaId }) =>
					!isSpeaker && !raisedHands[personaId] && queryExist({ firstName, lastName })
			),
		[firebaseParticipants, raisedHands, queryExist]
	);

	const totalRaisedUsers = raisedUsers.length;
	const totalListeners = listeners.length;
	const isParticipantsList = sidebarType === SidebarType.participants;

	return (
		<>
			<SidebarHeader
				title={isParticipantsList ? "Participants" : "Raised Hands"}
				isSearch={isParticipantsList}
				onChangeSearch={setSearchKeyword}
				showAdd={isParticipantsList}
				onAdd={toggleShowInvite}
			/>
			<SideBar.Content>
				{isParticipantsList ? (
					<>
						<SideBar.ContentTitle>Speakers ({speakers.length})</SideBar.ContentTitle>
						<SideBar.ListWrapper>{speakers.map(renderParticipant)}</SideBar.ListWrapper>
					</>
				) : !totalRaisedUsers ? (
					<EmptyBox hand />
				) : null}
				{!!totalRaisedUsers && (
					<>
						<SideBar.ContentTitle>Raised Hands ({totalRaisedUsers})</SideBar.ContentTitle>
						<SideBar.ListWrapper>{raisedUsers.map(renderParticipant)}</SideBar.ListWrapper>
					</>
				)}
				{!!totalListeners && isParticipantsList && (
					<>
						<SideBar.ContentTitle>Listeners ({totalListeners})</SideBar.ContentTitle>
						<SideBar.ListWrapper>{listeners.map(renderParticipant)}</SideBar.ListWrapper>
					</>
				)}
			</SideBar.Content>
			{myPersonaId && showInvite && <InviteParticipants onHide={toggleShowInvite} visible personaId={myPersonaId} />}
			<ConfirmAlertModal
				open={!!selectedParticipant}
				onClose={() => setSelectedParticipant(undefined)}
				onConfirm={blockParticipant}
				title="Block User"
				message={
					isAdmin
						? "User won't be able to see the event. Are you sure you want to block the user?"
						: "You won't be able to connect or message this user. Are you sure you want to block?"
				}
				okText="Block"
			/>
		</>
	);
};

export default Participants;
