import React, { memo, useMemo } from "react";

import { SeriesCollection } from "modules/Manage/Data/types";

import { PlaceholderImage } from "shared/Components";
import { useMemberRoutes } from "shared/hooks";
import { PlaceholderImageType } from "shared/types";

import { OpenCard } from "../../shared";
import { ModelItem } from "../index";

interface SeriesCardProps {
	series: SeriesCollection;
	widget?: boolean;
	highlightOnHover?: boolean;
}

const SeriesCard: React.FC<SeriesCardProps> = memo(({ series, widget, highlightOnHover }) => {
	const { getMemberRoutesData } = useMemberRoutes();
	const { routes } = getMemberRoutesData();

	const getAction = useMemo(() => {
		return <OpenCard text="Open" />;
	}, []);

	const href = useMemo(
		() => `${routes?.member.collection.getPath()}/${series._id}${series?.groupId ? `?groupId=${series.groupId}` : ""}`,
		[routes?.member.collection, series._id, series?.groupId]
	);

	return (
		<ModelItem
			noSidePadding={!!widget}
			autoHeight={!!widget}
			item={{
				_id: series._id,
				wrapByHref: true,
				href,
				img: !!series?.meta?.artwork ? series.meta.artwork?.url : undefined,
				title: series.title,
				action: !widget ? getAction : undefined,
				subtitle: widget ? series.description : "",
				minimalistic: !!widget,
				largeThumbnail: true,
				highlightOnHover: highlightOnHover || false,
				locked: series.locked,
				summary: !widget
					? `${series.category ? `${series.category.name} • ` : ""}${series.itemIds?.length || 0} Items`
					: undefined,
				placeholderImg: (
					<PlaceholderImage
						type={PlaceholderImageType.SERIES_PREVIEW}
						width={360}
						height={185}
						viewBox={"0 0 720 320"}
					/>
				)
			}}
		/>
	);
});

export default SeriesCard;
