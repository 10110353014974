import { useHistory } from "react-router";

import { FeatureFlags } from "shared/Components/MemberNavPanel/constants";
import { useMemberRoutes } from "shared/hooks";

import useFeature from "./useFeature";

export const useCheckIfEnabled = (flag: FeatureFlags | FeatureFlags[], isCommunityFeature = false) => {
	const { isFeatureEnabled, isCommunityFeatureEnabled } = useFeature();
	const history = useHistory();
	const { getMemberRoutesData } = useMemberRoutes();
	const { routes } = getMemberRoutesData();

	typeof flag === "string"
		? !isFeatureEnabled(flag) && history.push(routes?.member.home.getPath())
		: isCommunityFeature &&
		  flag.map(f => isCommunityFeatureEnabled(f)).every(v => !v) &&
		  history.push(routes?.member.home.getPath());
};
export default useCheckIfEnabled;
