import React, { FC, useEffect, useState } from "react";

import { Controller, useForm } from "react-hook-form";
import { Link, useHistory } from "react-router-dom";

import { VARS } from "apps/Auth/constants";
import { useUser } from "shared/hooks";
import { Box, Input, Loader, Text } from "shared/ui-kit";

import { ContactUsText, OtherLoginOptions, Subtitle } from "./style";

import useJwt from "../../../../../shared/services/api/core/jwt";

import { useAuth } from "../../../Data";
import AuthError from "../../Components/AuthError";
import { AuthPagesContainer, ContentWrapperBlock, InputWrapper, ManageBtn } from "../style";

export interface CreatePasswordProps {
	signInLink: string;
	communitiesListPage: string;
	dashboardRoute?: string;
}

const CreatePassword: FC<CreatePasswordProps> = ({ signInLink, dashboardRoute, communitiesListPage }) => {
	const history = useHistory();
	const [authError, setAuthError] = useState("");

	const { updateUserPassword, logOut, getData: getAuthData } = useAuth();
	const { isLoading } = getAuthData();

	const { getJwt } = useJwt();

	const { getData: getUserData } = useUser();
	const { user, globalUserId, workspaces } = getUserData();

	const { handleSubmit, control, errors, formState, getValues } = useForm({ mode: "onChange" });

	useEffect(() => {
		if (!globalUserId) {
			logOut().then(() => {
				history.push(signInLink);
			});
		}
	}, [globalUserId, history, logOut, signInLink]);

	if (!user) {
		return <Text>Loading your data..</Text>;
	}

	const onSubmit = async data => {
		if (user) {
			try {
				const status = await updateUserPassword({
					password: data.password as string,
					fromDeepLink: true,
					token: getJwt()
				});

				if (status) {
					if (!!workspaces?.length && communitiesListPage) {
						history.push(communitiesListPage);
					} else {
						history.push(dashboardRoute || communitiesListPage);
					}
				}
			} catch (error) {
				setAuthError((error as Error).message);
			}
		}
	};

	return (
		<ContentWrapperBlock>
			<AuthPagesContainer>
				<Box className="header">
					<Text variant="h2">Create your password.</Text>
					<Subtitle variant="body1">Password should have 6 or more characters.</Subtitle>
				</Box>
				<Box className="inputs">
					<AuthError message={authError} />
					<form onSubmit={handleSubmit(onSubmit)}>
						<Controller
							name="password"
							control={control}
							rules={{
								required: "Field is required.",
								minLength: {
									value: 6,
									message: "Password should be at least 6 characters"
								}
							}}
							render={({ onChange, value }) => (
								<InputWrapper>
									<Input
										label="Password"
										errorText={errors.password?.message}
										type="password"
										value={value}
										onChange={onChange}
									/>
								</InputWrapper>
							)}
						/>
						<Controller
							name="passwordConfirm"
							control={control}
							rules={{
								required: "Field is required.",
								minLength: {
									value: 6,
									message: "Password should be at least 6 characters"
								},
								validate: value => {
									if (value !== getValues("password")) {
										return "Passwords are not equal";
									}
								}
							}}
							render={({ onChange, value }) => (
								<InputWrapper>
									<Input
										label="Confirm Password"
										errorText={errors.passwordConfirm?.message}
										type="password"
										value={value}
										onChange={onChange}
									/>
								</InputWrapper>
							)}
						/>
						<ManageBtn type="submit" removeSideMargin fullWidth size="large" disabled={!formState.isValid}>
							{isLoading ? <Loader size="15px" color="secondary" show variant="intermediate" /> : "Continue"}
						</ManageBtn>
					</form>

					<OtherLoginOptions>
						<Link to={signInLink}>Return to Login </Link>
					</OtherLoginOptions>
				</Box>
			</AuthPagesContainer>
			<ContactUsText href={`mailto:${VARS.CONTACT_US_EMAIL}?subject=Community help`}>Contact Us</ContactUsText>
		</ContentWrapperBlock>
	);
};

export default CreatePassword;
