import { useCallback, useMemo } from "react";

import { useCommunity, useNotification, useUser } from "shared/hooks";
import { usePersonaApiService } from "shared/services";
import { ProfileType } from "shared/types";

import { useFirebase } from "../../modules/Messaging/Data";

import { usePersonaStore } from "../contexts";

const usePersona = () => {
	const { showMessage } = useNotification();

	const { authenticateUser } = useFirebase();

	const { getData: getWorkspaceData } = useCommunity();
	const { workspace } = getWorkspaceData();

	const personaService = usePersonaApiService();

	const { updateUserChatId } = useUser();

	const store = usePersonaStore();
	const { setState, setInitial } = usePersonaStore();

	const methods = useMemo(
		() => ({
			setPersona(personaInfo: Partial<ProfileType>, isPartial = false) {
				setState(ctx => ({ persona: isPartial ? { ...ctx.persona, ...personaInfo } : personaInfo }));
			},
			async updatePersonaAddress({
				city,
				country,
				state,
				userId
			}: {
				city?: string;
				country?: string;
				state?: string;
				userId: string;
			}) {
				return personaService.updatePersonaAddress({ city, country, state, userId });
			},
			async updatePersona(persona: Partial<ProfileType>, updateState?: boolean) {
				delete persona.externalId;
				delete persona.blocked;

				if (updateState) {
					setState(ctx => ({
						persona: ctx.persona
							? {
									...ctx.persona,
									...persona
							  }
							: { ...(persona as ProfileType) }
					}));
				}

				await personaService.updateCommunityPersona({ ...persona });
			},
			async verifySelfie(profileImage: string, verificationImage: string) {
				try {
					return await personaService.verifySelfie(profileImage, verificationImage);
				} catch {
					return {
						detectCustomlabels: {
							verified: false
						},
						comparison: {
							verified: false
						}
					};
				}
			},
			resetStore() {
				setInitial();
			}
		}),
		[setState, personaService, setInitial]
	);

	const createAutomatedConnection = useCallback(async () => {
		return await personaService.createAutomatedConnection();
	}, [personaService]);

	const createPersona = useCallback(
		async (firstName: string, lastName: string) => {
			try {
				const response = await personaService.createCommunityPersona({
					firstName,
					lastName
				});

				authenticateUser(response.personaId, workspace!).then(async res => {
					if (res) {
						await updateUserChatId(response.personaId.toString(), res.user.uid);
						createAutomatedConnection();
					}
				});

				return response;
			} catch (err) {
				console.log(err);
				showMessage("something went wrong, please try again.");
			}
		},
		[personaService, showMessage, createAutomatedConnection, workspace, updateUserChatId, authenticateUser]
	);

	const getData = useCallback(() => {
		return { ...store };
	}, [store]);

	return { ...methods, getData, createPersona };
};

export default usePersona;
