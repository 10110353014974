import React, { useEffect, useState } from "react";

import { ReactComponent as MicroPhoneMutedIcon } from "assets/icons/liveconvo/mic_muted.svg";
import { ReactComponent as MicroPhoneFilledIcon } from "assets/icons/liveconvo/microphone-filled.svg";

import { useLiveConversation } from "modules/LiveConversation/Data/hooks";
import { ProfilePhotoSizes } from "shared/types";

import { getResizedImage } from "utils/serviceUtils/cdnImages";

import VideoStream from "./VideoStream";

import { Speaker } from "./style";

import { ConvoParticipant } from "../../../Data/types";

const iconProps = {
	height: 11,
	width: 11,
	fill: "#fff"
};

interface SpeakerViewProps {
	participant: ConvoParticipant;
	isMe: boolean;
}

const SpeakerView: React.FC<SpeakerViewProps> = ({ participant, isMe }) => {
	const { stream, firstName, lastName, personaId, avatarUrl } = participant;

	const { getData: getLiveConversationData } = useLiveConversation();
	const { controls, audioLevels } = getLiveConversationData();

	const control = controls[personaId];

	const [speaking, setSpeaking] = useState(false);

	useEffect(() => {
		let isSpeak = false;

		if (!control?.muted) {
			const audioLevel = audioLevels.find(level => level.personaId === personaId);

			if (audioLevel && parseFloat(`${audioLevel.volume}`)) {
				isSpeak = true;
			}
		}

		setSpeaking(isSpeak);
	}, [control?.muted, audioLevels, personaId]);

	const avatarImgUrl = avatarUrl ? getResizedImage(avatarUrl, ProfilePhotoSizes.size200x200) : "";

	return (
		<Speaker className={speaking ? "speaking" : ""}>
			{control?.videoOn ? (
				<VideoStream stream={stream} isMe={isMe} />
			) : (
				<Speaker.ImageWrapper className={speaking ? "speaking" : ""}>
					<Speaker.Avatar height={120} width={120} image={avatarImgUrl} title={`${firstName}`} variant="circle" />
				</Speaker.ImageWrapper>
			)}
			<Speaker.NameChip>
				<Speaker.Name>
					{firstName} {lastName}
				</Speaker.Name>
				<Speaker.MuteStatusIconWrapper>
					{control?.muted ? <MicroPhoneMutedIcon {...iconProps} /> : <MicroPhoneFilledIcon {...iconProps} />}
				</Speaker.MuteStatusIconWrapper>
			</Speaker.NameChip>
		</Speaker>
	);
};

export default SpeakerView;
