import { useMemo } from "react";

import { useUser } from "shared/hooks";
import useJwt from "shared/services/api/core/jwt";
import { useS3Uploader } from "shared/services/s3Uploader";

import useMediaApiService from "shared/services/useMediaApiService";
import { MediaModel, MediaType } from "shared/types";

const useMedia = () => {
	const service = useMediaApiService();
	const { uploadFile } = useS3Uploader();

	const { getWorkspace } = useJwt();
	const workSpace = getWorkspace();

	const { getData: getUserData } = useUser();
	const { user } = getUserData();
	const personaId = user?.profiles[0]?.personaId || "";

	return useMemo(
		() => ({
			createMediaForUploaded: async ({
				fileUrl,
				type = MediaType.photo,
				personaId: customPersonaId
			}: {
				fileUrl: string;
				type?: MediaType;
				personaId?: number;
			}): Promise<MediaModel | null> => {
				const defaultQuery = {
					personaId: customPersonaId || (personaId as number),
					expireAt: "infinite" as number | "infinite"
				};

				const media = await service.createMedia({ ...defaultQuery, uri: fileUrl, type });

				if (!media) return null;
				let m: any = media[0];
				if (type === MediaType.profilePhoto) {
					m = {
						mediaId: m._id,
						id: `${m.type}_${m._id}`,
						name: `${m.type}_${m._id}`,
						active: true,
						profilePicture: m.uri
					};
				} else if (type === MediaType.coverPhoto) {
					m = {
						active: true,
						mediaId: m._id,
						uri: m.uri
					};
				}
				return m;
			},
			uploadFiles: async ({
				files,
				checkProgress,
				type = MediaType.photo,
				communityName,
				personaId: customPersonaId
			}: {
				files: FileList | null | File[];
				checkProgress?: boolean;
				type?: MediaType;
				communityName?: string;
				personaId?: number;
			}): Promise<MediaModel[]> => {
				if (!files) return [];

				const res = [] as MediaModel[];

				const defaultQuery = {
					personaId: customPersonaId || (personaId as number),
					expireAt: "infinite" as number | "infinite"
				};

				await Promise.all(
					Object.values(files).map(async v => {
						const data = await uploadFile({ file: v, communityName: communityName || workSpace, checkProgress });

						if (!data) return;

						const media = await service.createMedia({ ...defaultQuery, uri: data.publicUrl, type });

						if (!media) return;
						let m: any = media[0];
						if (type === MediaType.profilePhoto) {
							m = {
								mediaId: m._id,
								id: `${m.type}_${m._id}`,
								name: `${m.type}_${m._id}`,
								active: true,
								profilePicture: m.uri
							};
						} else if (type === MediaType.coverPhoto) {
							m = {
								active: true,
								mediaId: m._id,
								uri: m.uri
							};
						}
						res.push(m);
					})
				);
				return res;
			}
		}),
		[personaId, service, uploadFile, workSpace]
	);
};

export default useMedia;
