import { Box, IconButton } from "@material-ui/core";
import styled, { css, keyframes } from "styled-components";

import { Button } from "shared/ui-kit";

export const ArrowContainer = styled(Box)`
	position: absolute;

	${({
		currentStepRect,
		isChecklist,
		isCreatePost,
		isNotification,
		displayFromBottom,
		isMobile,
		isProfile,
		isMemberRole,
		isMember
	}) =>
		currentStepRect && !displayFromBottom
			? css`
					top: -10px;
					transform: rotate(30deg);
					left: ${isNotification
						? currentStepRect.left + 2 + "px"
						: isChecklist || isCreatePost
						? currentStepRect.left - 4 + "px"
						: (isMobile && isProfile && isMember) || (!isMemberRole && !isProfile)
						? currentStepRect.left + 8 + "px"
						: currentStepRect.left - 8 + "px"};
			  `
			: css`
					top: calc(100% - 13px);
					transform: rotate(90deg);
					${!isMemberRole && !isChecklist
						? css`
								left: 12px;
						  `
						: css`
								right: 12px;
						  `};
			  `}

	${props => props.theme.breakpoints.up("sm")} {
		${({ displayFromBottom }) =>
			displayFromBottom
				? css`
						top: calc(100% - 12px);
						left: 12px;
						transform: rotate(90deg);
				  `
				: css`
						top: -12px;
						left: 12px;
						transform: rotate(30deg);
				  `}
		${({ displayFromRight }) =>
			displayFromRight &&
			css`
				left: calc(100% - 32px);
			`}
	}

	${props => props.theme.breakpoints.up("md")} {
		${({ displayFromBottom }) =>
			displayFromBottom
				? css`
						bottom: 12px;
						top: unset;
				  `
				: css`
						top: 12px;
						bottom: unset;
				  `}
		${({ displayFromRight }) =>
			displayFromRight
				? css`
						left: calc(100% - 12px);
						transform: rotate(0);
				  `
				: css`
						left: -12px;
						transform: rotate(180deg);
				  `}
	}
`;

export const BlockContainer = styled(Box)`
	width: 280px;
	position: fixed;
	padding: 24px;
	box-shadow: 0 2px 8px 0 rgba(97, 115, 254, 0.16);
	border: solid 1px #fff;
	background-image: linear-gradient(9deg, #6173fe 16%, #4a99f9 86%);
	z-index: 2500;
	border-radius: ${({ isMember }) => (isMember ? "12px" : "6px")};
	color: white;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	opacity: 0;
	transform: translateY(15px);
	transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
	&.show {
		opacity: 1;
		transform: translateY(0);
	}

	${props => props.theme.breakpoints.down("xs")} {
		width: 90%;
		left: 15px;
	}

	${({ position, isMobile, currentStep, isMember, isMemberRole }) =>
		position.top && isMobile && isMember && currentStep === "profile"
			? css`
					top: ${isMember && isMemberRole ? 210 : 260}px;
			  `
			: css`
					top: ${position.top}px;
			  `}
	${({ position }) =>
		position.left &&
		css`
			left: ${position.left + "px"};
		`}
		${({ position }) =>
		position.right &&
		css`
			right: ${position.right + "px"};
		`}
	${({ position }) =>
		position.bottom &&
		css`
			bottom: ${position.bottom + "px"};
		`}
`;

export const ClosePopupButton = styled(IconButton)`
	position: absolute;
	top: 16px;
	right: 16px;
`;

export const BlockHeader = styled(Box)`
	display: flex;
	align-items: center;
	img {
		width: 40px;
		height: 40px;
		border: solid 2px #fff;
		object-fit: cover;
		z-index: 2;
		border-radius: 99%;
	}
	p {
		font-size: 13px;
		font-weight: 600;
		line-height: 1.85;
	}
	span {
		color: #fff;
		font-size: 12px;
		opacity: 0.72;
	}
	.info {
		margin-left: 8px;
	}
`;

export const BlockContent = styled(Box)`
	flex: 1;
	padding: 12px 0;
	.content {
		font-weight: normal;
		font-size: 13px;
		line-height: 1.3;
	}
	${props => props.theme.breakpoints.up("sm")} {
		padding: 24px 0;
		h6 {
			font-size: 18px;
		}
		.content {
			font-size: 15px;
			line-height: 1.6;
		}
	}
`;

export const BlockFooter = styled(Box)`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

export const pulse = keyframes`
	0% {
		transform: scale(0.99);
		box-shadow: 0 0 0 0 rgba(52, 172, 224, 0.7);
	}
	
	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(52, 172, 224, 0);
	}
	
	100% {
		transform: scale(0.99);
		box-shadow: 0 0 0 0 rgba(52, 172, 224, 0);
	}
`;

export const BlockWrapper = styled(Box)`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	z-index: 3000;
`;

export const HighlightRing = styled(Box)`
	display: none;
	${({
		currentStepRect,
		fullWidth,
		isChecklist,
		isCreatePost,
		isMember,
		isNotification,
		isMobile,
		isProfile,
		isMemberRole,
		isSwitcher,
		isAllNotificationRead
	}) =>
		currentStepRect &&
		css`
			display: block;
			position: fixed;
			width: ${isAllNotificationRead
				? currentStepRect.width - 2 + "px"
				: isNotification
				? currentStepRect.width + 4 + "px"
				: isChecklist || isCreatePost
				? currentStepRect.width + 8 + "px"
				: isMobile && isSwitcher && isMember
				? currentStepRect.width + 12 + "px"
				: isMobile && isProfile && isMember
				? 90 + "%"
				: fullWidth
				? currentStepRect.width + "px"
				: currentStepRect.width - 8 + "px"};
			height: ${isAllNotificationRead
				? currentStepRect.height - 2 + "px"
				: isNotification
				? currentStepRect.height + 4 + "px"
				: isChecklist || isCreatePost
				? currentStepRect.height + 8 + "px"
				: isMobile && isSwitcher && isMember
				? currentStepRect.height + 12 + "px"
				: isMobile && isProfile && isMember
				? currentStepRect.height + 50 + "px"
				: currentStepRect.height + "px"};
			top: ${isAllNotificationRead
				? currentStepRect.top + 0 + "px"
				: isNotification || (isMobile && isSwitcher && isMember)
				? currentStepRect.top - 6 + "px"
				: isChecklist || isCreatePost
				? currentStepRect.top - 4 + "px"
				: isMobile && isProfile && isMember
				? isMemberRole
					? currentStepRect.top + 145 + "px"
					: currentStepRect.top + 200 + "px"
				: currentStepRect.top + "px"};
			left: ${isNotification
				? currentStepRect.left + 2 + "px"
				: isChecklist || isCreatePost
				? currentStepRect.left - 4 + "px"
				: isMobile && isSwitcher && isMember
				? currentStepRect.left - 6 + "px"
				: isMobile && isProfile && isMember
				? currentStepRect.left + 15 + "px"
				: fullWidth
				? currentStepRect.left + "px"
				: currentStepRect.left + 8 + "px"};
			z-index: 10;
			border-radius: ${isChecklist ? "26px" : isCreatePost ? "99%" : isMember ? "12px" : "8px"};
			border: solid 2px #6173fe;
			transform: scale(0.99);
			animation: ${pulse} 2s ease-in-out infinite;
			z-index: 3000;
			opacity: 0;
			transition: opacity 0.2s ease-in-out;
			&.show {
				opacity: 1;
			}
		`}
`;

export const OutlinedButton = styled(Button)`
	box-shadow: 0 0 0 1px #c5cee0;
	background-color: transparent;
	color: white;

	&:hover {
		box-shadow: 0 0 0 1px #c5cee0;
	}

	.MuiButton-label {
		font-weight: ${({ isMember }) => (isMember ? "bold" : "regular")};
	}
`;

export const NextButton = styled(Button)`
	background-color: white;
	color: #5f75fd;
	box-shadow: none;
	.MuiButton-label {
		font-weight: ${({ isMember }) => (isMember ? "bold" : "regular")};
	}

	&:hover {
		background-color: white;
		color: #5f75fd;
	}
`;

export const DialogContentWrapper = styled(Box)`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	padding-top: 48px;
	position: relative;
`;

export const WelcomePopupCloseButton = styled(IconButton)`
	position: absolute;
	top: 8px;
	right: 13px;
`;

export const DialogWrapper = styled(Box)`
	.MuiDialog-container {
		background-color: rgba(25, 36, 53, 0.1);
		position: fixed;
		height: 100vh;
		width: 100%;
	}
	.MuiDialogContent-root {
		padding: 8px 0;
	}
	.paper-full-width {
		padding: 0;
		width: 100%;
		margin: 0 16px;
		${({ isMember }) =>
			isMember &&
			css`
				border-radius: 12px;
				box-shadow: 0 4px 40px 0 rgba(0, 0, 0, 0.16);
				max-width: 512px;
			`}
	}
`;

export const MessageBubble = styled(Box)`
	padding: 24px;
	width: 85%;
	max-width: 320px;
	margin: 46px auto 32px;
	min-height: 128px;
	background-color: #ebecff;
	border-radius: 15px;
	position: relative;
	.user-profile-wrapper {
		position: absolute;
		top: -20px;
		left: 0;
		width: 100%;
		display: flex;
		justify-content: center;
		img {
			width: 40px;
			height: 40px;
			border: solid 2px #fff;
			object-fit: cover;
			z-index: 2;
			border-radius: 99%;
		}
	}
	p {
		position: relative;
		z-index: 1;
	}
	svg {
		position: absolute;
		top: 95%;
		left: -2px;
	}
`;

export const DialogFooter = styled(Box)`
	padding: 16px;
	border-top: 1px solid #edf1f7;
	display: flex;
	justify-content: flex-end;
	&.member {
		padding: 8px 16px 4px;
		button {
			background-color: #6173fe;
			border-radius: 8px;
			font-size: 14px;
			font-weight: bold;
			line-height: 1.14;
			padding: 12px 16px;
		}
	}
`;
