import React from "react";

import CircularProgress from "@material-ui/core/CircularProgress";
import { Controller, useForm } from "react-hook-form";

import { LabeledInput } from "modules/MemberHome/View/shared";
import { useUser } from "shared/hooks";
import { Icon } from "shared/ui-kit";

import { validators } from "shared/ui-kit/utils/dynamicForm/validators";

import {
	CloseButton,
	Form,
	FormActions,
	FormRow,
	InputWrapper,
	SubmitButton,
	Subtitle,
	Title,
	ToggleShowButton,
	Wrapper
} from "./styles";

interface Props {
	onClose: () => void;
}

interface FormValues {
	currentPassword: string;
	newPassword: string;
	confirmPassword: string;
}

export const ChangePasswordDialog: React.FC<Props> = ({ onClose }: Props) => {
	const {
		control,
		handleSubmit,
		errors,
		getValues,
		formState: { isValid, isSubmitting }
	} = useForm<FormValues>({ mode: "onChange" });
	const [showPassword, setShowPassword] = React.useState(false);
	const [showNewPassword, setShowNewPassword] = React.useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
	const { changePassword, getData: getUserData } = useUser();
	const { globalUserId } = getUserData();

	const toggleShowPassword = () => {
		setShowPassword(!showPassword);
	};

	const toggleShowNewPassword = () => {
		setShowNewPassword(!showNewPassword);
	};

	const toggleShowConfirmPassword = () => {
		setShowConfirmPassword(!showConfirmPassword);
	};

	const onSubmit = handleSubmit(async data => {
		if (globalUserId) {
			await changePassword({
				currentPassword: data.currentPassword,
				password: data.newPassword,
				globalUserId
			});
			onClose();
		}
	});

	return (
		<Wrapper open={true}>
			<Title>Change Password</Title>
			<Subtitle>Confirm your current password to be able to change it.</Subtitle>
			<Form onSubmit={onSubmit}>
				<Controller
					name="currentPassword"
					control={control}
					rules={{ required: "Field is required" }}
					render={({ onChange, value, name }) => (
						<FormRow>
							<InputWrapper>
								<LabeledInput
									label="Current password"
									placeholder=" "
									type={`${showPassword ? "text" : "password"}`}
									name={name}
									onChange={onChange}
									value={value}
									error={errors?.currentPassword?.message}
								/>
								<ToggleShowButton onClick={toggleShowPassword}>
									<Icon name={showPassword ? "eye-slash" : "eye"} group="filled" fill="#c5cee0" />
								</ToggleShowButton>
							</InputWrapper>
						</FormRow>
					)}
				/>
				<Controller
					name="newPassword"
					control={control}
					rules={{
						required: "Field is required.",
						minLength: {
							value: 8,
							message: "Password length should be between 8 to 20"
						},
						maxLength: {
							value: 20,
							message: "Password length should be between 8 to 20"
						},
						validate: validators.password
					}}
					render={({ onChange, value, name }) => (
						<FormRow>
							<InputWrapper>
								<LabeledInput
									label="New password"
									placeholder=" "
									type={`${showNewPassword ? "text" : "password"}`}
									name={name}
									onChange={onChange}
									value={value}
									error={errors?.newPassword?.message}
								/>
								<ToggleShowButton onClick={toggleShowNewPassword}>
									<Icon name={showNewPassword ? "eye-slash" : "eye"} group="filled" fill="#c5cee0" />
								</ToggleShowButton>
							</InputWrapper>
						</FormRow>
					)}
				/>
				<Controller
					name="confirmPassword"
					control={control}
					rules={{
						required: "Field is required.",
						validate: value => {
							if (value !== getValues("newPassword")) {
								return "Passwords are not equal";
							}
						}
					}}
					render={({ onChange, value, name }) => (
						<FormRow>
							<InputWrapper>
								<LabeledInput
									label="Confirm new password"
									placeholder=" "
									type={`${showConfirmPassword ? "text" : "password"}`}
									name={name}
									onChange={onChange}
									value={value}
									error={errors?.confirmPassword?.message}
								/>
								<ToggleShowButton onClick={toggleShowConfirmPassword}>
									<Icon name={showConfirmPassword ? "eye-slash" : "eye"} group="filled" fill="#c5cee0" />
								</ToggleShowButton>
							</InputWrapper>
						</FormRow>
					)}
				/>
				<FormActions>
					<SubmitButton type="submit" disabled={!isValid || isSubmitting} fullWidth buttonTheme="light">
						{isSubmitting ? <CircularProgress size={20} /> : "Save"}
					</SubmitButton>
				</FormActions>
			</Form>
			<CloseButton type="button" onClick={onClose}>
				<Icon name="close" group="filled" fill="#8f9bb3" />
			</CloseButton>
		</Wrapper>
	);
};
