import React from "react";

import { Grid } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import styled from "styled-components";

const TableGridContainer = styled(Grid)`
	flex-wrap: nowrap;
	-webkit-overflow-scrolling: touch;
	-ms-overflow-style: -ms-autohiding-scrollbar;
`;

const TableSkeleton = styled(Skeleton)`
	width: ${props => (props.circle ? "24px" : "66.66%")};
	height: ${props => (props.circle ? "24px" : "40px")};
	flex: 0 0 auto;
	margin-right: 12px;

	@media (min-width: 768px) {
		width: ${props => (props.primaryInput ? "260px" : props.circle ? "24px" : "140px")};
		margin-right: 16px;
	}
`;

const SkeletonRow = () => (
	<TableGridContainer container alignItems="center">
		<TableSkeleton primaryInput />
		<TableSkeleton />
		<TableSkeleton />
		<TableSkeleton />
		<TableSkeleton variant="circle" circle />
	</TableGridContainer>
);

export default SkeletonRow;
