import {
	ContributorModel,
	EmailStateModel,
	InvitationModel,
	MembersModel,
	SearchUsersModel,
	UserStatus
} from "shared/types";
import { IStateProps } from "utils/StateOf";

import createStore from "utils/createStore";

export interface Values {
	users: MembersModel[];
	personas: SearchUsersModel[];
	allShareableLinks: InvitationModel[];
	allQRCodes: InvitationModel[];
	sharableLink?: InvitationModel;
	usersEmails: string[];
	toastMessage?: string;
	modalTitle?: string;
	modalContent?: string;
	isOpen?: boolean;
	modalFooterActionsText?: { cancelText: string; submitText: string };
	emailAddresses: EmailStateModel[];
	role?: string;
	isInvited?: boolean;
	showToastMessage?: boolean;
	editMemberRole?: boolean;
	selectMembers: string[];
	communityShareableLink?: string;
	communityQRCode?: string;
	loadingMembers: boolean;
	actionConfirmMessage?: string;
	apiRequest?: any;
	apiRequestParams?: any;
	totalCount: number;
	page: number;
	sharaeableLinkPage: number;
	memberShowPerPage: number;
	statusFilter: UserStatus[];
	selectedMembers?: number;
	enabledActions: string[];
	communityName?: string;
	isLoading?: boolean;
	firebaseUrl?: string;
	firebaseDbUrl?: string;
	firebaseWebApiKey?: string;
	stopLoadingLinks: boolean;
	stopLoadingQR: boolean;
	viewType: string;
	selectedQRCode?: string;
	editableModel?: InvitationModel;
	contributors: ContributorModel[];
	totalContributors: number;
	showInviteNotAllowedDialog: boolean;
	noUsersFound: boolean;
	successCallback?: () => void;
}

export const initialState: IStateProps<Values> = {
	users: [],
	personas: [],
	allShareableLinks: [],
	allQRCodes: [],
	stopLoadingLinks: false,
	stopLoadingQR: false,
	role: "",
	viewType: "",
	selectedQRCode: "",
	modalContent: "default",
	selectMembers: [],
	loadingMembers: false,
	totalCount: 0,
	page: 1,
	sharaeableLinkPage: 1,
	memberShowPerPage: 20,
	statusFilter: [],
	usersEmails: [],
	emailAddresses: [],
	enabledActions: [],
	contributors: [],
	totalContributors: 0,
	showInviteNotAllowedDialog: false,
	noUsersFound: false,
	successCallback: undefined
};

export default createStore<Values>({
	initialState
});
