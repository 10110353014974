import React, { useCallback, useMemo } from "react";

import { useMediaQuery } from "@material-ui/core";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";

import { useFirebase, useMessaging } from "modules/Messaging/Data";
import { ChatMessage } from "modules/Messaging/Data/types";
import { RelativeWrapper } from "shared/styles";
import { Icon } from "shared/ui-kit";
import * as appTheme from "theme/default";
import { downloadFileByLink } from "utils/serviceUtils/helpers";

import Actions from "./Actions";

import ImageWithOverlay from "./ImageWithOverlay";

import Reactions from "./Reactions";

import { RenderActionType } from "..";
import { FilePreview } from "../styles";

const FileBubble = props => {
	const { onSend } = useFirebase();
	const { getData: getMessagingData, setPreviewDialog } = useMessaging();
	const { currentChat } = getMessagingData();

	const handleDelete = useCallback(() => {
		if (!currentChat) return;

		const msg = {
			...props.currentMessage,
			edited: props.currentMessage,
			files: (props.currentMessage.files || []).filter(fi => fi.id !== props.fileItem.id)
		} as ChatMessage;

		onSend({ messages: [msg], chat: currentChat });
	}, [currentChat, onSend, props.currentMessage, props.fileItem.id]);

	const isPDF = useMemo(() => props.fileItem.type === "application/pdf", [props.fileItem.type]);

	const handleOpenPreview = useCallback(() => {
		setPreviewDialog({
			user: {
				avatar: props.user.avatar,
				name: props.user.name
			},
			file: {
				name: props.fileItem.name,
				url: props.fileItem.url,
				type: "PDF",
				createdAt: props.currentMessage.createdAt
			}
		});
	}, [
		props.currentMessage.createdAt,
		props.fileItem.name,
		props.fileItem.url,
		props.user.avatar,
		props.user.name,
		setPreviewDialog
	]);

	const isMobile = useMediaQuery(appTheme.default.breakpoints.down("sm"));

	return (
		<RelativeWrapper data-message={props.fileItem.id}>
			{isPDF && (
				<ImageWithOverlay
					fileBubbleView
					handleOpenPreview={handleOpenPreview}
					bg={
						<Document file={props.fileItem.url}>
							<Page width={isMobile ? 280 : 360} height={40} pageNumber={1} />
						</Document>
					}
				/>
			)}
			<FilePreview.Wrapper
				className={`${props.messageBlockCurrentUser && "messageBlockCurrentUser"} ${isPDF && "withPDFThumbnail"}`}
			>
				{isPDF && props.messageBlockCurrentUser && (
					<Icon className="pdf-icon" name="app-pdf" group="color" width="32px" height="32px" />
				)}
				<FilePreview.Info>
					<FilePreview.Name className={props.messageBlockCurrentUser && "white-message-text"}>
						{props.fileItem?.name}
					</FilePreview.Name>
					{props.fileItem?.size && (
						<FilePreview.Description className={props.messageBlockCurrentUser && "gray-message-text"}>
							{(props.fileItem.size / (1024 * 1024)).toFixed(1)} Mb
						</FilePreview.Description>
					)}
				</FilePreview.Info>
				<FilePreview.DownloadWrapper onClick={() => downloadFileByLink(props.fileItem.url)}>
					<Icon group={"filled"} fill={"#8f9bb3"} name={"arrow-alt-down"} width={16} height={16} />
				</FilePreview.DownloadWrapper>
			</FilePreview.Wrapper>
			{props.fileItem.recentLikes && (
				<Reactions list={props.fileItem.recentLikes} isCurrentUser={props.isCurrentUser} />
			)}
			<Actions
				replyItem={{
					id: props.fileItem.id,
					text: "file"
				}}
				isCurrentUser={props.isCurrentUser}
				onDelete={handleDelete}
				onEdit={props.onEdit}
				onForward={props.onForward}
				onReply={props.onReply}
				onLike={props.onLike}
				currentMessage={props.currentMessage}
				type={RenderActionType.FILE}
			/>
		</RelativeWrapper>
	);
};

export default FileBubble;
