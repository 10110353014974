import { IStateProps } from "utils/StateOf";

import createStore from "utils/createStore";

export interface Values {
	showConfirmPopup: boolean;
	providedValuesToCheck: boolean;
}

export const initialState: IStateProps<Values> = {
	showConfirmPopup: false,
	providedValuesToCheck: false
};

export default createStore<Values>({
	initialState
});
