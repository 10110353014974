import React, { FC, useEffect, useMemo, useRef } from "react";

import { ProgressIndicatorWrapper, StyledMultipleStoriesProgress } from "./style";

const ProgressIndicator: FC<{
	isActive: boolean;
	pauseStory: boolean;
	storiesCount: number;
	currentStoryNum: number;
	handleMoveNext: () => void;
}> = ({ isActive, pauseStory, storiesCount, currentStoryNum, handleMoveNext }) => {
	const progressRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		progressRef.current?.addEventListener("animationend", handleMoveNext, false);
	}, [handleMoveNext]);

	const widthVal = useMemo(() => 100 / storiesCount - (storiesCount > 15 ? 0.5 : 0.25), [storiesCount]);

	return !!storiesCount ? (
		<StyledMultipleStoriesProgress>
			{Array.from(Array(storiesCount).keys()).map((_, i) => (
				<ProgressIndicatorWrapper key={i} widthVal={widthVal} pauseStory={pauseStory} variant="determinate">
					<div
						ref={i === currentStoryNum ? progressRef : null}
						className={`progress-bar ${
							isActive ? (currentStoryNum === i ? "start" : currentStoryNum > i ? "seen" : "") : ""
						}`}
					/>
				</ProgressIndicatorWrapper>
			))}
		</StyledMultipleStoriesProgress>
	) : (
		<ProgressIndicatorWrapper pauseStory={pauseStory}>
			<div ref={progressRef} className={`progress-bar ${isActive ? "start" : ""}`}></div>
		</ProgressIndicatorWrapper>
	);
};

export default ProgressIndicator;
