import React from "react";

import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";

import { ReactComponent as EmailIcon } from "assets/icons/email-illustration.svg";
import { Box, Text } from "shared/ui-kit";

import { ContentWrapper } from "../../../../Community/View/Components";
import { AuthPagesContainer } from "../SignIn/styles";
import { OtherLoginOptions } from "../style";

const EmailIconWrapper = styled(Box)`
	margin: 20px 0;
	text-align: center;
	display: flex;
	justify-content: center;
`;

const SubtitleWrapper = styled(Text)`
	margin-top: 10px;
	font-size: 15px;
	${props => props.theme.breakpoints.down("xs")} {
		margin-top: 17px;
	}
`;

export interface VerificationLinkProps {
	signInLink: string;
	forgotPasswordLink: string;
	email?: string;
}

const ResetPasswordLink: React.FC<VerificationLinkProps> = ({ signInLink, forgotPasswordLink, email }) => {
	const history = useHistory();

	if (!email) {
		history.push(forgotPasswordLink);
	}

	return (
		<ContentWrapper>
			<AuthPagesContainer equalPadding>
				<Box className="header">
					<Text variant="h2">Forgot Password?</Text>
					<SubtitleWrapper variant="body1">
						We sent a reset link to you at <b>{email}</b> <br />
						Please check your inbox.
					</SubtitleWrapper>
				</Box>
				<EmailIconWrapper>
					<EmailIcon />
				</EmailIconWrapper>
				<OtherLoginOptions>
					<Link to={signInLink}>Return to Login </Link>
				</OtherLoginOptions>
			</AuthPagesContainer>
		</ContentWrapper>
	);
};

export default ResetPasswordLink;
