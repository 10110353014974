import React, { useCallback, useMemo, useState } from "react";

import { Podcast } from "types/PodcastsContextValuesType";

import { ReactComponent as IconPause } from "assets/icons/pause-track.svg";
import { ReactComponent as PlayCircleIcon } from "assets/icons/play-circle-icon.svg";
import { PlaceholderImage } from "shared/Components";
import { useAlbums, useCommunity, useMemberRoutes, usePlayer, useSeries } from "shared/hooks";
import { AlbumModel } from "shared/services/useAlbumApiService";
import { PlaceholderImageType, TrackMeta, TrackModel } from "shared/types";

import { HrefLink, Item } from "./style";

import { PremiumContentIndicator } from "../../shared";

import { ModelBlockType } from "../ModelBlock";

interface TrackCardProps {
	item: TrackModel | AlbumModel | Podcast;
	type: ModelBlockType;
	grayHover?: boolean;
	hidePlay?: boolean;
	allItems?: (TrackModel | AlbumModel)[];
	customHandlePlayTracks?: (id: string) => void;
}

const TrackCard: React.FC<TrackCardProps> = ({ item, type, grayHover, hidePlay, allItems, customHandlePlayTracks }) => {
	const { setPlayTracks, setAlbumPlaying, setPlay, getData: getPlayerData } = usePlayer();
	const { albumPlaying, play, playTracks } = getPlayerData();

	const { getAlbum } = useAlbums();
	const { setSeriesPlayState } = useSeries();

	const { communityColors } = useCommunity();

	const { getMemberRoutesData } = useMemberRoutes();
	const { routes } = getMemberRoutesData();

	const handlePlayTracks = useCallback(
		async (id: string) => {
			const idx = allItems?.findIndex(it => it._id === id);
			// idx can be 0, therefore added specific check by checking idx is not undefined
			if (typeof idx === "number" && idx > -1) {
				if (customHandlePlayTracks) {
					customHandlePlayTracks(`${idx}`);
				} else if (type === ModelBlockType.track) {
					setPlayTracks({
						idx,
						tracks: {
							items: allItems as TrackModel[]
						}
					});
					setSeriesPlayState();
				} else {
					const album = await getAlbum({ id: (item as AlbumModel)._id, shouldReturn: true });
					if (!album) return;
					const filteredMusics = album.musics.filter(m => !m.locked);
					setPlayTracks({
						idx: 0,
						tracks: {
							albumId: album._id,
							items: filteredMusics
						}
					});
					setSeriesPlayState();
					setAlbumPlaying(album._id);
				}
			}
		},
		[customHandlePlayTracks, type, setPlayTracks, allItems, setSeriesPlayState, getAlbum, item, setAlbumPlaying]
	);

	const imgUrl = useMemo(() => {
		return type === ModelBlockType.podcasts
			? (item as Podcast).image
			: type === ModelBlockType.track
			? ((item as TrackModel)?.meta as TrackMeta)?.artwork?.url
			: type === ModelBlockType.album
			? (item as AlbumModel).meta.url
			: "";
	}, [item, type]);

	const genre = useMemo(() => {
		return type === ModelBlockType.podcasts
			? (item as Podcast).author || "Podcast"
			: !!(item as TrackModel)?.genre?.length
			? (item as TrackModel).genre[0]
			: "";
	}, [item, type]);

	const artist = useMemo(() => {
		return type !== ModelBlockType.podcasts
			? ((item as TrackModel)?.meta as TrackMeta).artist || (item as AlbumModel).artist || ""
			: undefined;
	}, [item, type]);

	const isPlaying = useMemo(
		() =>
			(!!playTracks.tracks?.items?.length && playTracks.tracks.items[playTracks.idx]._id === item._id) ||
			playTracks.tracks?.albumId === (item as AlbumModel)._id,
		[playTracks, item]
	);

	const [showPlayIcon, setShowPlayIcon] = useState(false);
	const handlePauseTracks = useCallback(() => setPlay(!play), [play, setPlay]);
	const content = useMemo(
		() => (
			<Item.InfoBox>
				{genre && <Item.BeforeTitle>{genre}</Item.BeforeTitle>}
				<Item.Title>{item.title}</Item.Title>
				{artist && <Item.Subtitle>{artist}</Item.Subtitle>}
			</Item.InfoBox>
		),
		[artist, genre, item.title]
	);

	const href = useMemo(
		() =>
			`${
				type === ModelBlockType.podcasts
					? routes?.member.podcasts.getPath()
					: type === ModelBlockType.album
					? routes?.member.album.getPath()
					: routes?.member.track.getPath()
			}/${item._id}${
				type === ModelBlockType.track && (item as TrackModel)?.groupId
					? `?groupId=${(item as TrackModel)?.groupId}`
					: ""
			}`,
		[item, routes?.member, type]
	);

	return (
		<Item.Wrapper
			grayHover={grayHover}
			onMouseEnter={() => setShowPlayIcon(true)}
			onMouseLeave={() => setShowPlayIcon(false)}
		>
			{(item as TrackModel).locked && <PremiumContentIndicator />}
			{!hidePlay && handlePlayTracks && !(item as TrackModel).locked && (
				<Item.PlayTrack
					bgColor={communityColors.btn}
					show={showPlayIcon || isPlaying || albumPlaying === item._id}
					onClick={() => (isPlaying || albumPlaying === item._id ? handlePauseTracks() : handlePlayTracks(item._id))}
				>
					{(type === ModelBlockType.track ? play && isPlaying : albumPlaying === item._id && play) ? (
						<IconPause fill="white" />
					) : (
						<PlayCircleIcon />
					)}
				</Item.PlayTrack>
			)}
			<Item.IconWrapper imgUrl={imgUrl}>
				{!imgUrl && (
					<PlaceholderImage
						type={
							type === ModelBlockType.album ? PlaceholderImageType.ALBUM_PREVIEW : PlaceholderImageType.TRACK_PREVIEW
						}
						width={360}
						height={164}
						viewBox={"0 0 720 320"}
					/>
				)}
			</Item.IconWrapper>
			<HrefLink to={href}>{content}</HrefLink>
		</Item.Wrapper>
	);
};

export default TrackCard;
