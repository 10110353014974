import React from "react";

import { PodcastMeta, TrackModel } from "shared/types";

import { ModelItem } from "../index";

interface EpisodeCardProps {
	episode: TrackModel;
	onPlay?: () => void;
}

const EpisodeCard: React.FC<EpisodeCardProps> = ({ episode, onPlay }) => (
	<ModelItem
		noSidePadding
		autoHeight
		maxHeight={300}
		item={{
			_id: episode._id,
			img: (episode.meta as PodcastMeta)?.image,
			beforeTitle: episode.genre.length ? episode.genre[0] : "",
			title: episode.title,
			subtitle: episode.description,
			minimalistic: true,
			highlightOnHover: true,
			locked: episode.locked,
			onPlay
		}}
	/>
);

export default EpisodeCard;
