import React from "react";

import { view } from "modules/MemberHome";
import { useMemberRoutes } from "shared/hooks";

const FillProfilePage = () => {
	const { getMemberRoutesData } = useMemberRoutes();
	const { routes } = getMemberRoutesData();

	return (
		<view.FillProfileBirthday
			fillProfileBaseUrl={routes?.member.fillProfile.getPath()}
			memberHomeUrl={routes?.member.home.getPath()}
			maritalStatusPageUrl={routes?.member.fillProfile.marital.getPath()}
		/>
	);
};

export default FillProfilePage;
