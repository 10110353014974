import React from "react";

import { Radio, RadioProps, withStyles } from "@material-ui/core";

const VyooRadio = withStyles({
	root: {
		"&.Mui-checked": {
			color: "#6173fe"
		}
	}
})((props: RadioProps) => <Radio {...props} />);

export default VyooRadio;
