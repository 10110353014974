import React, { FC } from "react";

import { Box } from "@material-ui/core";

import { ReactComponent as IconGift } from "assets/icons/icon-gift.svg";
import { Receipt } from "shared/types/TransactionModel";
import { Text } from "shared/ui-kit";

import RightSide from "./RightSide";

const Award: FC<{ item: Receipt }> = ({ item }) => (
	<>
		<Box className="left-side">
			<Box className="icon-wrapper">
				<IconGift />
			</Box>
			<Box>
				<Text className="event-description">Awarded</Text>
				<Text className="event-description-second">By {item.firstName}</Text>
			</Box>
		</Box>
		<RightSide item={item} />
	</>
);

export default Award;
