import styled from "styled-components";

import { Box } from "shared/ui-kit";

export const DateRangePickerWrapper = styled(Box)`
	background: white;
	z-index: 1000;
	display: flex;
	flex-direction: column;
	border-radius: 4px;
	box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.24);

	.dz-calendar {
		.dz-calendar-header {
			margin: 0;
			padding: 0 16px;

			.header__content {
				margin: 0;
			}
		}

		.dz-calendar__table__body {
			.dz-calendar-table-body-week {
				.dz-calendar__table__body__cell {
					&.dz-calendar__table__body__cell--begin-range {
						background-color: rgba(97, 115, 254, 0.16);

						.dz-calendar__table__body__cell__content {
							background-color: #6173fe;
							border: none;
						}
					}

					&.dz-calendar__table__body__cell--end-range {
						background-color: rgba(97, 115, 254, 0.16);

						.dz-calendar__table__body__cell__content {
							background-color: #6173fe;
							border: none;
						}
					}

					&.dz-calendar__table__body__cell--semi-selected {
						background-color: rgba(97, 115, 254, 0.16);
					}
				}
			}
		}

		.dz-calendar-content {
			padding: 0;

			.dz-calendar__table {
				.dz-calendar__table__header {
					opacity: 1;

					tr {
						&:first-of-type {
							height: 50px;
							background-color: #f7f9fc;

							th {
								padding: 0;
							}
						}
					}
				}
			}
		}
	}
`;
