import React, { memo, useEffect, useMemo, useState } from "react";

import { memberMenuItems } from "shared/Components/MemberNavPanel/constants";
import { useCommunity, useFeature, useMemberRoutes, useUser } from "shared/hooks";

import { Item, ListWrapper } from "./styles";

import { BoxItem } from "../index";

const Menu: React.FC = memo(() => {
	const { communityColors, getData: getCommunityData } = useCommunity();
	const { workspace } = getCommunityData();

	const { isCommunityFeatureEnabled } = useFeature();
	const { getData: getUserData } = useUser();
	const { user } = getUserData();

	const { getMemberRoutesData } = useMemberRoutes();
	const { routes } = getMemberRoutesData();

	const [color, setColor] = useState<string>();

	const menuItems = useMemo(
		() => (workspace?.name ? memberMenuItems(workspace.name, routes) : null),
		[workspace?.name, routes]
	);

	useEffect(() => {
		setColor(communityColors.primary);
	}, [communityColors.primary]);

	return (
		<BoxItem customPadding="12px 8px 4px" ml="8px" title={"Menu"}>
			<ListWrapper>
				{menuItems &&
					menuItems
						.filter(x =>
							x.featureValueName
								? typeof x.featureValueName === "string"
									? isCommunityFeatureEnabled(x.featureValueName as string)
									: x.featureValueName.map(f => isCommunityFeatureEnabled(f as string)).includes(true)
								: true
						)
						.map((item, index) => (
							<Item.Link key={index} to={item.link} id={item.title}>
								<Item.IconWrapper color={color} isRequestIcon={item.title === "Requests"}>
									{item.icon}
								</Item.IconWrapper>
								<Item.Title>{item.title}</Item.Title>
								{item.title === "Requests" && (
									<Item.Badge badgeContent={user?.profiles[0]?.counters.requestCount} color="error" />
								)}
							</Item.Link>
						))}
			</ListWrapper>
		</BoxItem>
	);
});

export default Menu;
