import { Link } from "react-router-dom";
import styled from "styled-components";

import { PageContentWrapper } from "shared/templates/BaseLayout/style";
import { Box, Text } from "shared/ui-kit";
import { customBreakpoints } from "theme/default";

export const ContentWrapperBlock = styled(PageContentWrapper)`
	margin: 96px auto 0 auto;

	${props => props.theme.breakpoints.down(customBreakpoints.tabletVerticalMax)} {
		margin-top: 32px;
	}
`;

export const OtherLoginOptions = styled(Box)`
	text-align: center;
	margin-top: 16px;

	p {
		margin-top: 8px;
	}
`;

export const AuthPagesContainer = styled(Box)`
	background-color: white;
	max-width: 560px;
	margin: 0 16px;
	border-radius: 4px;
	box-shadow: inset 0 0 0 0.5px #c5cee0;
	padding: 24px 0;
	.header,
	.google-login,
	.inputs {
		padding-left: 16px;
		padding-right: 16px;

		${props => props.theme.breakpoints.up("sm")} {
			padding-left: 78px;
			padding-right: 78px;
		}
	}

	${props => props.theme.breakpoints.up("sm")} {
		margin: auto;
		width: 560px;
		padding: ${({ equalPadding }) => (equalPadding ? "40px 0" : "56px 0 40px")};
	}

	&.broken-link {
		max-width: 544px;
		padding: 62px 72px 35px 72px;
		border-radius: 12px;
	}
`;

export const AgreementText = styled(Text)`
	margin-bottom: 12px;
	display: block;
`;

export const StyledLink = styled(Link)<{ color?: string }>`
	font-weight: 600;
	color: ${({ color }) => color || "#6173fe"};
`;
