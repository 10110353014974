import { Box } from "@material-ui/core";
import styled from "styled-components";

export const ItemWrapper = styled(Box)`
	width: 200px;
	min-width: 200px;
	margin-right: 12px;
`;

export const ListWrapper = styled(Box)`
	margin: 16px 0 0 -16px;
	width: calc(100% + 32px);
	display: flex;
	align-items: flex-start;
	.slick-list {
		padding: 0 16px 8px !important;
	}
	.btn-base {
		border-radius: 12px;
	}
`;
