import React, { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from "react";

import AttachFileIcon from "@material-ui/icons/AttachFile";
import Skeleton from "@material-ui/lab/Skeleton";

import { DateTime } from "luxon";

import * as R from "ramda";

import { FileDrop } from "react-file-drop";

import { BubbleProps, GiftedChat, IMessage, utils } from "react-gifted-chat";

import { ReactComponent as IconFile } from "assets/icons/file-icon.svg";
import { ReactComponent as IconGroup } from "assets/icons/groups.svg";
import { ReactComponent as IconAudio } from "assets/icons/iconAudioTrack.svg";
import { ReactComponent as IconCalendar } from "assets/icons/iconCalendar.svg";
import { ReactComponent as IconDollar } from "assets/icons/iconDollarFilled.svg";
import { ReactComponent as IconGif } from "assets/icons/iconGif.svg";
import { ReactComponent as IconVideo } from "assets/icons/video.svg";

import { useFirebase, useMessaging } from "modules/Messaging/Data";

import { MenuDots } from "shared/Components";

import ConfirmLeavePopup from "shared/Components/ConfirmLeave";
import useConfirmLeavePage from "shared/Components/ConfirmLeave/hooks/useConfirmLeavePage";
import Dialogs from "shared/Components/Dialogs";
import { TransformOriginOpts } from "shared/Components/MenuDots";
import TypingIndicator from "shared/Components/TypingLoader";
import { useCommunity, useFeature, useStream, useUser } from "shared/hooks";
import { useS3Uploader } from "shared/services/s3Uploader";
import { EventModel, ToolbarActions, TrackMeta, TrackModel } from "shared/types";
import { Avatar, CircularProgress, Icon, Loader, Text } from "shared/ui-kit";

import {
	Attachment,
	AvatarWrapper,
	ChatInput,
	DayTimestamp,
	GiftedChatWrapper,
	MemberMenuIconWrapper,
	SkeletonBox,
	UserAvatarName
} from "./styles";

import { ChatMessage, InboxItem } from "../../../Data/types";
import { RenderActionType } from "../ChatBubble";
import { ChatBubble, ChatReply, ForwardChat } from "../index";

interface AttachmentsProgressInterface {
	type: string;
	fileId: string;
	progress: number;
}

const correctFileTypes = ["video/*,image/*,application/pdf,audio/*"];

const Chat: React.FC = () => {
	const [attachedFiles, setAttachedFiles] = useState<Partial<ChatMessage> | null>(null);
	const [replyInfo, setReplyInfo] = useState<{
		id: string;
		user: { id: string; name: string };
		info;
		type: RenderActionType;
	} | null>(null);
	const [editableMessage, setEditableMessage] = useState<ChatMessage>();
	const [forwardMessage, setForwardMessage] = useState<ChatMessage>();
	const [forwardChatPopupOpen, setForwardChatPopupOpen] = useState(false);
	const [dragOver, setDragOver] = useState(false);
	const [inputWrapperHeight, setInputWrapperHeight] = useState(88);
	const [messageText, setMessageText] = useState("");
	const [action, setAction] = useState<ToolbarActions | null>(null);

	const [attachmentsProgress, setAttachmentsProgress] = useState<AttachmentsProgressInterface[]>([]);
	const [loadingAttachments, setLoadingAttachments] = useState(false);

	const { isFeatureEnabled } = useFeature();
	const { readFile } = useStream();

	const { getData: getCommunityData } = useCommunity();
	const { workspace } = getCommunityData();

	const { updateInbox, getData: getMessagingData } = useMessaging();
	const { messages, currentChat, typingChat, loadingMessages } = getMessagingData();

	const { uploadFile, aboutRequestsById } = useS3Uploader();
	const {
		loadChatMessages,
		listenForMessages,
		clearMessages,
		onSend,
		listenForTyping,
		deleteChatMessage,
		notifyUserStartTyping,
		notifyUserStopTyping
	} = useFirebase();

	const { getActiveProfile, getProfilePicture, getData: getUserData } = useUser();
	const { user, isMemberView } = getUserData();

	const hiddenFileInput = useRef<HTMLInputElement>(null);
	const chatInputWrapperRef = useRef<HTMLDivElement>(null);
	const chatInputRef = useRef<HTMLTextAreaElement>();
	const wrapperRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (!loadingMessages && wrapperRef.current) {
			setTimeout(() => {
				const messageToObserve = wrapperRef.current?.querySelectorAll('div[data-observe="true"]');

				const observer = new IntersectionObserver(
					items => {
						if (items[0].isIntersecting) {
							const nextPageStartAt = messages[messages.length - 1].order + 1;
							loadChatMessages(nextPageStartAt, false, currentChat);
						}
					},
					{
						root: null,
						rootMargin: "0px",
						threshold: 1
					}
				);

				!!messageToObserve?.length && observer.observe(messageToObserve[messageToObserve.length - 1]);
			}, 400);
		}
	}, [currentChat, loadChatMessages, loadingMessages, messages]);

	const activeProfile = useMemo(() => getActiveProfile(user), [getActiveProfile, user]);

	const handleClick = () => {
		hiddenFileInput?.current?.click();
	};

	const shareOptions = useMemo(() => {
		const options = [
			{
				name: "Request Money",
				value: 1,
				comp: "",
				icon: isMemberView ? <IconDollar className="fill-both-paths" /> : undefined,
				onClick: () => setAction(ToolbarActions.ADD_REQUEST_MONEY)
			},
			{
				name: "Attach Files",
				value: 2,
				comp: "",
				icon: isMemberView ? <IconFile /> : undefined,
				onClick: handleClick
			},
			{
				name: "Send Gif",
				value: 3,
				comp: "",
				icon: isMemberView ? <IconGif className="fill-second-path" /> : undefined,
				onClick: () => setAction(ToolbarActions.ADD_GIF)
			}
		];
		options.unshift({
			name: "Share Files",
			value: 8,
			comp: "",
			icon: isMemberView ? <IconFile /> : undefined,
			onClick: () => setAction(ToolbarActions.SELECT_FILE)
		});
		if (isFeatureEnabled("enableAudioTracks")) {
			options.unshift({
				name: "Share Audio Track",
				value: 7,
				comp: "",
				icon: isMemberView ? <IconAudio className="fill-second-path" /> : undefined,
				onClick: () => setAction(ToolbarActions.SELECT_TRACK)
			});
		}
		if (isFeatureEnabled("enableEvents")) {
			options.unshift({
				name: "Share Event",
				value: 6,
				comp: "",
				icon: isMemberView ? <IconCalendar className="fill-use" /> : undefined,
				onClick: () => setAction(ToolbarActions.SELECT_EVENT)
			});
		}
		if (isFeatureEnabled("enableGroups")) {
			options.unshift({
				name: "Share Group",
				value: 5,
				comp: "",
				icon: isMemberView ? <IconGroup className="fill-first-path" /> : undefined,
				onClick: () => setAction(ToolbarActions.SELECT_GROUP)
			});
		}

		options.unshift({
			name: "Share Video",
			value: 4,
			comp: "",
			icon: isMemberView ? <IconVideo className="fill-first-path" /> : undefined,
			onClick: () => setAction(ToolbarActions.SEND_VIDEO)
		});

		return options;
	}, [isFeatureEnabled, isMemberView]);

	useEffect(() => {
		if (currentChat) {
			const startAt = -new Date().getTime();
			clearMessages();
			loadChatMessages(startAt, !!currentChat?.deletedAt, currentChat);
			listenForMessages(startAt, currentChat);
		}
		chatInputRef?.current?.focus();
	}, [clearMessages, currentChat, activeProfile, listenForMessages, loadChatMessages, updateInbox, user]);

	useEffect(() => {
		if (currentChat) {
			const startAt = -new Date().getTime();
			listenForTyping(startAt, currentChat);
		}
		return () => {
			notifyUserStopTyping(currentChat);
		};
	}, [currentChat, listenForTyping, notifyUserStopTyping]);

	const recalculateSizes = useCallback(() => {
		const wrapper = chatInputWrapperRef.current;
		let height = 48;
		if (wrapper) {
			Array.from(wrapper.children).forEach(ch => {
				height += ch.clientHeight;
			});
			setInputWrapperHeight(height);
		}
	}, []);

	useEffect(() => {
		recalculateSizes();
	}, [attachedFiles, replyInfo, editableMessage, recalculateSizes]);

	const INPUT_HEIGHT = useMemo(() => (isMemberView ? 64 : 40), [isMemberView]);

	useEffect(() => {
		if (chatInputRef.current) {
			const lines = messageText.split(/\r|\r\n|\n/);
			const count = lines.length >= 5 ? 5 : lines.length;
			const newHeight = `${INPUT_HEIGHT + (count - 1) * 19}px`;
			if (newHeight !== chatInputRef.current.style.height) {
				chatInputRef.current.style.height = newHeight;
				recalculateSizes();
			}

			// let newHeight = chatInputRef.current.scrollHeight;
			// newHeight = newHeight > 113 ? 113 : newHeight;
			// if (`${newHeight}px` !== chatInputRef.current.style.height) {
			// 	chatInputRef.current.style.height = `${newHeight}px`;
			// 	recalculateSizes();
			// }
		}
	}, [INPUT_HEIGHT, messageText, recalculateSizes]);

	const sendMessage = useCallback(
		async (message: Partial<ChatMessage>) => {
			if (currentChat) {
				let sendingMessage = { ...message };
				if (replyInfo) {
					if (replyInfo) {
						sendingMessage.reply = {
							id: replyInfo.id || "",
							uid: replyInfo.user.id || "",
							name: replyInfo.user.name || "",
							text: replyInfo.info,
							type: replyInfo.type
						} as any;
					}
				}

				if (editableMessage) {
					sendingMessage.edited = editableMessage;
					if (editableMessage.reply) sendingMessage.reply = editableMessage.reply;
				}

				if (attachedFiles) {
					sendingMessage = {
						...sendingMessage,
						_id: attachedFiles._id,
						images: attachedFiles.images,
						videos: attachedFiles.videos,
						files: attachedFiles.files,
						audios: attachedFiles.audios
					};
					setAttachedFiles(null);
				}

				onSend({ messages: [sendingMessage], chat: currentChat });
				setReplyInfo(null);
				setEditableMessage(undefined);
			}
		},
		[attachedFiles, currentChat, editableMessage, onSend, replyInfo]
	);

	const onEditMessage = useCallback((msg: ChatMessage) => {
		setEditableMessage(msg);
		setMessageText(msg?.text || "");
	}, []);

	const onForwardChat = useCallback(msg => {
		setForwardMessage(msg);
		setForwardChatPopupOpen(true);
	}, []);

	const undoEdit = useCallback(() => {
		setEditableMessage(undefined);
	}, []);

	const handleTransformAttachments = useCallback(
		async (files: FileList | null) => {
			if (files?.length) {
				const fileList: File[] = !!files?.length ? Array.from(files).slice(0, 20) : [];

				const msg: Partial<ChatMessage> = {};

				const now = new Date().getTime();
				msg._id = now;

				const uploads: (Promise<any> | null)[] = [];

				fileList.forEach((item, index) => {
					const timestamp = new Date().getTime();
					let fileId = "";
					let fileType = "";

					if (item.type === "image/png" || item.type === "image/jpeg" || item.type === "image/webp") {
						fileId = `photo_${timestamp}`;
						fileType = "image";
						msg.images = msg.images || [];
						msg.images.push({
							id: fileId,
							image: URL.createObjectURL(item),
							name: fileList[index].name,
							type: fileList[index].type,
							size: fileList[index].size
						});
					} else if (item.type.includes("video/")) {
						fileId = `video_${timestamp}`;
						fileType = "video";
						msg.videos = msg.videos || [];
						msg.videos.push({
							id: fileId,
							url: URL.createObjectURL(item),
							name: fileList[index].name,
							type: fileList[index].type,
							size: fileList[index].size
						});
					} else if (item.type.includes("audio/")) {
						fileId = `audio_${timestamp}`;
						fileType = "audio";
						msg.audios = msg.audios || [];
						msg.audios.push({
							id: fileId,
							url: URL.createObjectURL(item),
							name: fileList[index].name,
							type: fileList[index].type,
							size: fileList[index].size
						});
					} else {
						const isDOCX =
							fileList[index].type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document";

						fileId = `file_${timestamp}`;
						fileType = "file";
						msg.files = msg.files || [];
						msg.files.push({
							id: fileId,
							url: URL.createObjectURL(item),
							name: fileList[index].name,
							type: isDOCX ? "application/docx" : fileList[index].type,
							size: fileList[index].size
						});
					}

					uploads.push(
						new Promise(async resolve => {
							const res = await uploadFile({
								file: item,
								communityName: `${workspace?.communityUrl}`,
								refId: fileId,
								handleProgressUpdate: progress => {
									const newItem = { type: item.type, fileId, progress };

									setAttachmentsProgress(ctx => {
										const itemExists = ctx.findIndex(item => item.fileId === fileId);
										return itemExists > -1 ? ctx.map((it, i) => (itemExists === i ? newItem : it)) : [...ctx, newItem];
									});
								}
							});
							resolve({
								fileId,
								fileType,
								fileInfo: res
							});
						})
					);
				});

				setAttachedFiles(old => ({
					...msg,
					images: [...(old?.images || []), ...(msg.images || [])],
					videos: [...(old?.videos || []), ...(msg.videos || [])],
					audios: [...(old?.audios || []), ...(msg.audios || [])],
					files: [...(old?.files || []), ...(msg.files || [])]
				}));

				setReplyInfo(null);

				setLoadingAttachments(true);

				await Promise.all(uploads).then(async values => {
					values.forEach(({ fileId, fileType, fileInfo }) => {
						if (fileType === "image") {
							const img = msg.images.find(x => x.id === fileId);
							if (img) {
								img.image = fileInfo.publicUrl;
							}
						}

						if (fileType === "video") {
							const video = msg.videos.find(x => x.id === fileId);
							if (video) {
								video.url = fileInfo.publicUrl;
							}
						}

						if (fileType === "file") {
							const file = msg.files.find(x => x.id === fileId);
							if (file) {
								file.url = fileInfo.publicUrl;
							}
						}
					});
				});

				setLoadingAttachments(false);
			}
		},
		[uploadFile, workspace]
	);

	const onUpload = useCallback(
		async (e: React.ChangeEvent<HTMLInputElement>) => {
			handleTransformAttachments(e.target.files);
		},
		[handleTransformAttachments]
	);

	const handleRemoveAttachment = useCallback(
		file => {
			const isImage = file.type.startsWith("image/");
			const isVideo = file.type.startsWith("video/");
			const isAudio = file.type.startsWith("audio/");

			setAttachedFiles(msg => ({
				...msg,
				images: isImage ? msg?.images.filter(img => img.id !== file.id) : msg?.images,
				videos: isVideo ? msg?.videos.filter(vid => vid.id !== file.id) : msg?.videos,
				audios: isAudio ? msg?.audios.filter(aud => aud.id !== file.id) : msg?.audios,
				files: !isImage && !isVideo && !isAudio ? msg?.files.filter(fil => fil.id !== file.id) : msg?.files
			}));

			setAttachmentsProgress(ctx => ctx.filter(at => at.fileId !== file.id));
			aboutRequestsById([file.id]);
		},
		[aboutRequestsById]
	);

	const populateReaction = useCallback(
		(obj, newReaction) => {
			if (!activeProfile) return;

			let attachment = obj;

			if (!obj.recentLikes) {
				attachment = { ...attachment, recentLikes: [newReaction] };
			} else {
				const reactIdx = attachment.recentLikes.findIndex(like => like.chatUserId === activeProfile.chatUserId);
				reactIdx >= 0 ? (attachment.recentLikes[reactIdx] = newReaction) : attachment.recentLikes.push(newReaction);
			}

			return attachment;
		},
		[activeProfile]
	);

	const handleReactToMessage = useCallback(
		(reaction: string, msg, type: RenderActionType, id: string) => {
			if (!activeProfile) return;
			let newMessage = { ...msg, reacted: true };
			const newReaction = { chatUserId: activeProfile.chatUserId, reaction };

			switch (type) {
				case RenderActionType.VIDEO:
					const vidIdx = newMessage.videos.findIndex(v => v.id === id);

					if (vidIdx !== -1) {
						newMessage.videos[vidIdx] = populateReaction(newMessage.videos[vidIdx], newReaction);
					}

					break;
				case RenderActionType.FILE:
					const fileIdx = newMessage.files.findIndex(v => v.id === id);

					if (fileIdx !== -1) {
						newMessage.files[fileIdx] = populateReaction(newMessage.files[fileIdx], newReaction);
					}

					break;
				case RenderActionType.TRACK:
					newMessage.track = populateReaction(newMessage.track, newReaction);

					break;
				case RenderActionType.AUDIO:
					const audioIdx = newMessage.audios.findIndex(v => v.id === id);

					if (audioIdx !== -1) {
						newMessage.audios[audioIdx] = populateReaction(newMessage.audios[audioIdx], newReaction);
					}

					break;
				case RenderActionType.EVENT:
					newMessage.place = populateReaction(newMessage.place, newReaction);

					break;
				case RenderActionType.GROUP:
					newMessage.group = populateReaction(newMessage.group, newReaction);

					break;
				case RenderActionType.IMAGE:
					const imageIdx = newMessage.images.findIndex(v => v.id === id);

					if (imageIdx !== -1) {
						newMessage.images[imageIdx] = populateReaction(newMessage.images[imageIdx], newReaction);
					}

					break;
				default:
					if (!newMessage.recentLikes) {
						newMessage = { ...newMessage, recentLikes: [newReaction] };
					} else {
						const reactIdx = newMessage.recentLikes.findIndex(like => like.chatUserId === activeProfile.chatUserId);
						reactIdx >= 0 ? (newMessage.recentLikes[reactIdx] = newReaction) : newMessage.recentLikes.push(newReaction);
					}
			}

			sendMessage(newMessage);
		},
		[activeProfile, populateReaction, sendMessage]
	);

	const renderBubble = (props: BubbleProps) => {
		const isDifferentUser = props.currentMessage?.uid !== props.previousMessage?.uid;

		return (
			<ChatBubble
				isDifferentUser={isDifferentUser}
				currentUserId={activeProfile?.chatUserId}
				onReply={(item, type: RenderActionType) => {
					if (!props.currentMessage?._id) return;
					setReplyInfo({
						id: props.currentMessage._id,
						user: {
							id: props.currentMessage.user._id,
							name: props.currentMessage.user.name || ""
						},
						info: item,
						type
					});
				}}
				onEdit={onEditMessage}
				onForward={onForwardChat}
				onLike={(reaction: string, type: RenderActionType) =>
					handleReactToMessage(reaction, props.currentMessage, type, props.currentMessage?._id)
				}
				onDelete={msg => deleteChatMessage(currentChat, msg)}
				isCarpool={props?.currentMessage?.carpool || false}
				isCanceledCarpool={props?.currentMessage?.canceledCarpool || false}
				{...props}
			/>
		);
	};

	const renderFileAttachment = useCallback(
		(file, img?: boolean) => {
			const uploadProgress = attachmentsProgress.find(f => f.fileId === file.id);
			return (
				<ChatInput.AttachmentWrapper>
					<Attachment.Wrapper>
						{img ? (
							<Attachment.ImageWrapper key={file.id} style={{ backgroundImage: `url(${file.image})` }} />
						) : (
							<Attachment.FileWrapper key={file.id}>
								<Attachment.FileTypeIconWrapper>
									<Icon
										group="color"
										width={24}
										height={24}
										name={file.type.includes("application/pdf") ? "app-pdf" : "file-types-mp-4"}
									/>
								</Attachment.FileTypeIconWrapper>
								<Attachment.FileInfoWrapper>
									<Attachment.FileName>{file.name}</Attachment.FileName>
									<Attachment.FileExtraInfo>{(file.size / (1024 * 1024)).toFixed(1)} Mb</Attachment.FileExtraInfo>
								</Attachment.FileInfoWrapper>
							</Attachment.FileWrapper>
						)}
						{uploadProgress && uploadProgress.progress !== 100 && (
							<Attachment.ProgressWrapper>
								<CircularProgress size={24} palette="secondary" value={uploadProgress.progress} />
							</Attachment.ProgressWrapper>
						)}
						<Attachment.DeleteIconWrapper className={"delete-icon"} onClick={() => handleRemoveAttachment(file)}>
							<Icon fill="#ff6138" group="filled" height={12} width={12} name="close" />
						</Attachment.DeleteIconWrapper>
					</Attachment.Wrapper>
				</ChatInput.AttachmentWrapper>
			);
		},
		[attachmentsProgress, handleRemoveAttachment]
	);

	const hasMoreThan20Attachments = useMemo(
		() =>
			(attachedFiles?.audios || []).length +
			(attachedFiles?.videos || []).length +
			(attachedFiles?.files || []).length +
			(attachedFiles?.images || []).length,
		[attachedFiles]
	);

	const attachmentsBlock = useMemo(
		() => (
			<>
				{hasMoreThan20Attachments > 20 && <Text>{"You can't add more than 20 attachments"}</Text>}
				{attachedFiles && (
					<ChatInput.AttachmentBlock className={isMemberView && "member-block"}>
						{attachedFiles.videos && attachedFiles.videos.map(file => renderFileAttachment(file))}
						{attachedFiles.images && attachedFiles.images.map(file => renderFileAttachment(file, true))}
						{attachedFiles.audios && attachedFiles.audios.map(file => renderFileAttachment(file))}
						{attachedFiles.files && attachedFiles.files.map(file => renderFileAttachment(file))}
					</ChatInput.AttachmentBlock>
				)}
			</>
		),
		[attachedFiles, hasMoreThan20Attachments, isMemberView, renderFileAttachment]
	);

	const renderFooter: () => ReactNode = useMemo(() => {
		const personaId = activeProfile?.personaId;

		if (typingChat && personaId && currentChat) {
			const typingUser = Object.values(typingChat).filter(
				item => item.senderId !== personaId && item.chatId === (currentChat?._id || currentChat?.chatUserId)
			);

			const getSenderName = arr => {
				return arr?.map(e => e.senderName).join(", ");
			};

			if (typingUser.length) {
				return () => <TypingIndicator name={getSenderName(typingUser)} />;
			}
		}

		// if (replyInfo || editedChat) {
		// 	const replyMsg = getCurrentMessage(replyInfo || editedChat?._id);
		//
		//
		// 	return () => (
		// 		<ChatReply
		// 			currentUserId={activeProfile?.chatUserId}
		// 			replyMsg={replyMsg}
		// 			onClose={() => (replyInfo ? setReplyInfo(null) : undoEdit())}
		// 			isEdit={editedChat}
		// 		/>
		// 	);
		// }

		return () => <></>;
	}, [activeProfile, typingChat, currentChat]);

	const renderAvatar = useCallback(
		(message: IMessage): ReactNode => {
			const { user } = message;
			return (
				<AvatarWrapper className={isMemberView && "rounded-corners"}>
					<Avatar width={40} height={40} title={user?.name || ""} image={user?.avatar} />
					<UserAvatarName>{user?.name}</UserAvatarName>
				</AvatarWrapper>
			);
		},
		[isMemberView]
	);

	const renderDay = useCallback(({ currentMessage, previousMessage }) => {
		if (utils.isSameDay(currentMessage, previousMessage)) {
			return null;
		}
		const updateAtYear = currentMessage?.updatedAt
			? DateTime.fromMillis(currentMessage?.updatedAt).toFormat("yyyy")
			: DateTime.now().toFormat("yyyy");
		const currentYear = DateTime.now().toFormat("yyyy");

		const getCalendarFormat = (myDateTime, now) => {
			const diff = myDateTime.diff(now.startOf("day"), "days").as("days");
			return diff < -6
				? updateAtYear === currentYear
					? { format: "LLLL, d" }
					: { format: "LLLL d, yyyy" }
				: diff < -1
				? { format: "cccc" }
				: diff < 0
				? { text: "Yesterday" }
				: diff < 1
				? { text: "Today" }
				: diff < 2
				? { text: "Tomorrow" }
				: diff < 7
				? { format: "cccc" }
				: updateAtYear === currentYear
				? { format: "LLLL, d" }
				: { format: "LLLL d, yyyy" };
		};

		const currentMsgTimestamp = DateTime.fromMillis(currentMessage.createdAt);

		const format = getCalendarFormat(currentMsgTimestamp, DateTime.now());
		return format?.text ? (
			<DayTimestamp key={"some-day"}>{format?.text}</DayTimestamp>
		) : (
			<DayTimestamp key={"some-day"}>{currentMsgTimestamp.toFormat(`${format.format}`)}</DayTimestamp>
		);
	}, []);

	const {
		handleLeavePageConfirmed,
		closeConfirmPopup,
		getData: getConfirmLeavePopupData
	} = useConfirmLeavePage({
		valuesToCheck: !!messageText.length
	});
	const { showConfirmPopup } = getConfirmLeavePopupData();

	const handleSubmit = useCallback(
		(messageText: string) => {
			if (!!messageText.trim() || attachedFiles) {
				sendMessage({
					text: messageText || "",
					user: {
						_id: activeProfile?.chatUserId,
						name: activeProfile?.firstName,
						avatar: getProfilePicture(user)
					}
				});
				setMessageText("");
			}
		},
		[attachedFiles, sendMessage, activeProfile, getProfilePicture, user]
	);

	const handleOnDrop = useCallback(
		files => {
			setDragOver(false);
			handleTransformAttachments(files);
		},
		[handleTransformAttachments]
	);

	const handleCloseReply = useCallback(() => {
		replyInfo ? setReplyInfo(null) : undoEdit();
		setMessageText("");
	}, [replyInfo, undoEdit]);

	const MemberMenuIcon = useMemo(
		() => (
			<MemberMenuIconWrapper>
				<Icon name="plus" fill="#ffffff" />
			</MemberMenuIconWrapper>
		),
		[]
	);

	const welcomeMessage: IMessage = useMemo(() => {
		const now = currentChat?.createdAt ? new Date(currentChat?.createdAt).getTime() : new Date().getTime();
		const text =
			currentChat?.type === "connection"
				? "This is the beginning of your conversation"
				: currentChat?.type === "group"
				? "group chat has started"
				: currentChat?.type === "communityChat"
				? "community chat has started"
				: "Chat started";
		return {
			system: true,
			createdAt: now,
			order: -now,
			text,
			user: { _id: undefined, name: undefined },
			_id: now
		};
	}, [currentChat]);

	const handleUserStartTyping = useCallback(
		(currentChat: InboxItem) => {
			if (typingChat) {
				const alreadyWriting = Object.values(typingChat).every(item => item.senderId === activeProfile?.personaId);
				if (alreadyWriting) return;
			}

			notifyUserStartTyping(currentChat);
		},
		[activeProfile?.personaId, notifyUserStartTyping, typingChat]
	);

	const handleWriting = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			if (currentChat) {
				const val = e.target.value;
				setMessageText(val);
				!!val.length ? handleUserStartTyping(currentChat) : notifyUserStopTyping(currentChat);
			}
		},
		[currentChat, handleUserStartTyping, notifyUserStopTyping]
	);

	return (
		<>
			<ConfirmLeavePopup
				handleLeavePage={handleLeavePageConfirmed}
				open={showConfirmPopup}
				onClose={closeConfirmPopup}
			/>

			{!currentChat || loadingMessages ? (
				<SkeletonBox.Wrapper>
					<SkeletonBox.ChatBox>
						<SkeletonBox.MessageSection>
							<SkeletonBox.Message className={"current-user"}>
								<Skeleton width="27%" height={40} />
							</SkeletonBox.Message>
							<SkeletonBox.Message className={"current-user"}>
								<Skeleton width="46%" height={40} />
							</SkeletonBox.Message>
						</SkeletonBox.MessageSection>
						<SkeletonBox.MessageSection>
							<SkeletonBox.Message>
								<Skeleton width="23%" height={40} />
							</SkeletonBox.Message>
							<SkeletonBox.Message>
								<Skeleton width="46%" height={120} />
							</SkeletonBox.Message>
						</SkeletonBox.MessageSection>
						<SkeletonBox.MessageSection>
							<SkeletonBox.Message className={"current-user"}>
								<Skeleton width="46%" height={80} />
							</SkeletonBox.Message>
							<SkeletonBox.Message className={"current-user"}>
								<Skeleton width="40%" height={40} />
							</SkeletonBox.Message>
						</SkeletonBox.MessageSection>
						<SkeletonBox.MessageSection>
							<SkeletonBox.Message>
								<Skeleton width="46%" height={40} />
							</SkeletonBox.Message>
							<SkeletonBox.Message>
								<Skeleton width="11%" height={40} />
							</SkeletonBox.Message>
						</SkeletonBox.MessageSection>
					</SkeletonBox.ChatBox>
					<SkeletonBox.InputBox>
						<Skeleton width="100%" height={66} />
					</SkeletonBox.InputBox>
				</SkeletonBox.Wrapper>
			) : (
				<GiftedChatWrapper ref={wrapperRef} inputWrapperHeight={inputWrapperHeight}>
					{dragOver && (
						<GiftedChatWrapper.DragOverlay>
							<Text>Drop Files Here</Text>
						</GiftedChatWrapper.DragOverlay>
					)}

					<FileDrop onDragOver={() => setDragOver(true)} onDragLeave={() => setDragOver(false)} onDrop={handleOnDrop}>
						<GiftedChat
							messages={!!messages.length ? ([...messages, welcomeMessage] as IMessage[]) : [welcomeMessage]}
							renderAvatarOnTop
							user={{
								_id: activeProfile?.chatUserId,
								name: activeProfile?.firstName,
								avatar: getProfilePicture(user)
							}}
							renderBubble={renderBubble}
							renderFooter={renderFooter}
							renderAvatar={props => renderAvatar(props.currentMessage)}
							renderDay={renderDay}
							renderInputToolbar={() => (
								<ChatInput.Wrapper
									className={replyInfo || editableMessage ? "lessPadd" : ""}
									ref={chatInputWrapperRef}
									onSubmit={e => {
										e.preventDefault();
										handleSubmit(messageText);
									}}
								>
									{(replyInfo || editableMessage) && (
										<ChatInput.ReplyBlock>
											<ChatReply
												currentUserId={activeProfile?.chatUserId}
												replyMsg={replyInfo || editableMessage}
												onClose={handleCloseReply}
												isEdit={editableMessage}
											/>
										</ChatInput.ReplyBlock>
									)}
									<ChatInput.InputBlock className={isMemberView && "center"}>
										{isMemberView ? (
											<ChatInput.MemberShare>
												<MenuDots
													options={shareOptions}
													transformOrigin={TransformOriginOpts.BOTTOM_LEFT}
													customImage={MemberMenuIcon}
													customWidth={240}
													customOptionHeight={40}
													alignLeft
													removeBg
													removeshadow
													memberView
													circular
												/>
											</ChatInput.MemberShare>
										) : (
											<ChatInput.ShareIconWrapper
												className="more"
												onClick={e => {
													e.stopPropagation();
												}}
											>
												<MenuDots
													options={shareOptions}
													transformOrigin={TransformOriginOpts.BOTTOM_LEFT}
													customImage={<AttachFileIcon htmlColor="#8f9bb3" />}
													customWidth={160}
													vertical
													alignLeft
													removeBg
													removeshadow
												/>
											</ChatInput.ShareIconWrapper>
										)}
										<ChatInput.InputWrapper className={isMemberView && "member-radius"}>
											<ChatInput.Input
												autoFocus
												ref={chatInputRef}
												type={"text"}
												placeholder={"Type Your Message..."}
												value={messageText}
												onChange={handleWriting}
												className={isMemberView ? "member-input" : ""}
												onKeyPress={e => {
													if (e.which === 13 && !loadingAttachments && !e.shiftKey && hasMoreThan20Attachments <= 20) {
														e.preventDefault();
														handleSubmit(messageText);
													}
												}}
											/>
											<ChatInput.SendButton
												disabled={loadingAttachments || hasMoreThan20Attachments > 20}
												palette={"control"}
												buttonTheme={"ghost"}
												type={"submit"}
											>
												{loadingAttachments ? (
													<Loader show variant="indeterminate" size="1rem" />
												) : replyInfo ? (
													"Reply"
												) : editableMessage ? (
													"Save"
												) : (
													"Send"
												)}
											</ChatInput.SendButton>
										</ChatInput.InputWrapper>
									</ChatInput.InputBlock>
									{attachmentsBlock}
								</ChatInput.Wrapper>
							)}
						/>
						{forwardChatPopupOpen && (
							<ForwardChat
								open={forwardChatPopupOpen}
								onClose={(e, type) => {
									setForwardChatPopupOpen(false);
									if (type === "group") {
										updateInbox({ offset: 1, limit: 20, personaId: activeProfile?.personaId });
									}
								}}
								currentMessage={forwardMessage}
							/>
						)}
						<Dialogs
							action={action}
							setAction={setAction}
							onSetContent={async content => {
								if (content) {
									const sendingMessage = {
										text: "",
										user: {
											_id: activeProfile?.chatUserId,
											name: activeProfile?.firstName,
											avatar: getProfilePicture(user)
										}
									};
									switch (action) {
										case ToolbarActions.ADD_REQUEST_MONEY:
											sendingMessage["payreq"] = content;
											break;

										case ToolbarActions.SELECT_TRACK:
										case ToolbarActions.ADD_TRACK:
											sendingMessage["track"] = {
												...R.pick(["description", "duration", "genre", "guid", "title", "type"], content as TrackModel),
												id: (content as TrackModel)._id,
												url: ((content as TrackModel).meta as TrackMeta).track.url,
												artist: ((content as TrackModel).meta as TrackMeta).artist,
												date: (content as TrackModel).createdAt,
												trackObj: ((content as TrackModel).meta as TrackMeta).track,
												artObj: ((content as TrackModel).meta as TrackMeta).artwork,
												artwork: ((content as TrackModel).meta as TrackMeta).artwork.url
											};
											break;

										case ToolbarActions.SELECT_EVENT:
										case ToolbarActions.ADD_EVENT:
											sendingMessage["place"] = {
												id: (content as EventModel).eventId,
												image: (content as EventModel).eventImages?.length
													? (content as EventModel).eventImages[0]
													: "",
												name: (content as EventModel).title,
												location: (content as EventModel).location
											};
											break;

										case ToolbarActions.SELECT_GROUP:
										case ToolbarActions.ADD_GROUP:
											sendingMessage["group"] = content;
											break;

										case ToolbarActions.ADD_GIF:
											sendingMessage["gif"] = content;
											break;

										case ToolbarActions.ADD_VIDEO:
										case ToolbarActions.SEND_VIDEO:
											sendingMessage["videos"] = [
												{
													id: content._id,
													name: content.title,
													url: content.meta.video.url
												}
											];
											break;

										case ToolbarActions.ADD_FILE:
										case ToolbarActions.SELECT_FILE:
											const fileType = await (await readFile(content.file.url)).headers["content-type"];

											if (fileType.startsWith("image/")) {
												sendingMessage["images"] = [
													{
														id: content._id,
														name: content.title,
														image: content.file.url
													}
												];
											} else if (fileType.startsWith("video/")) {
												sendingMessage["videos"] = [
													{
														id: content._id,
														name: content.title,
														url: content.file.url
													}
												];
											} else if (fileType.startsWith("audio/")) {
												sendingMessage["audios"] = [
													{
														id: content._id,
														name: content.title,
														url: content.file.url
													}
												];
											} else {
												sendingMessage["files"] = [
													{
														id: content._id,
														name: content.title,
														url: content.file.url,
														type: fileType
													}
												];
											}

											break;
									}

									sendMessage(sendingMessage);
									setAction(null);
								}
							}}
						/>
					</FileDrop>
				</GiftedChatWrapper>
			)}
			<input
				type="file"
				ref={hiddenFileInput}
				onChange={onUpload}
				accept={correctFileTypes.join(",")}
				style={{ display: "none" }}
				multiple
			/>
		</>
	);
};

export default Chat;
