import React from "react";

import { useIntegrations } from "modules/Integrations/Data/hooks";

import { generateSequence } from "utils/serviceUtils/helpers";

import LoadingSkeleton from "../Cards/LoadingSkeleton";
import IntegrationCard from "../IntegrationCard";
import IntegrationRow from "../IntegrationRow";

const PodcastRow = () => {
	const { getData: getIntegrationsData } = useIntegrations();
	const { podcasts, isLoading } = getIntegrationsData();

	return (
		<IntegrationRow title="Podcasts">
			{!podcasts.length && isLoading
				? Array.from(generateSequence(1, 4)).map(skeleton => <LoadingSkeleton key={skeleton} />)
				: podcasts.map(podcast => <IntegrationCard content={podcast} key={podcast._id} />)}
		</IntegrationRow>
	);
};

export default PodcastRow;
