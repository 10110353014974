import React, { memo, useCallback, useEffect, useMemo, useState } from "react";

import { DateTime } from "luxon";
import { EventType } from "types";

import { CreateEvent, MenuDots, PlaceholderImage, TableWrapper } from "shared/Components";
import { TableColumnLoaderType } from "shared/Components/NewTable/Components";
import { Cell } from "shared/Components/NewTable/style";
import { useDebounce, useEvent } from "shared/hooks";
import { EventModel, PlaceholderImageType } from "shared/types";
import { Select, Text } from "shared/ui-kit";
import { formatCount } from "utils/serviceUtils/helpers";

import { SelectWrapper } from "./style";

import { ConfirmDelete, PageTemplate } from "../../Components";

const Table = React.lazy(() => import("shared/Components/NewTable"));

const defaultFilters = {
	page: 1,
	limit: 10,
	keyword: "",
	eventSchedule: EventType.All
};

export interface ManageEventsProps {}

export interface eventModalType {
	open: boolean;
	model?: EventModel;
}

const ManageEvents: React.FC<ManageEventsProps> = memo(() => {
	const { getPaginatedEvents, deleteEvent, getData: getEventData } = useEvent();
	const { filteredEvents, loading, eventCount, page, eventsShowPerPage } = getEventData();

	const [open, setOpen] = useState(false);
	const [eventInfoToDelete, setEventInfoToDelete] = useState<{ id: string | null; name: string }>({
		id: null,
		name: ""
	});
	const [eventInfoPopup, setEventInfoPopup] = useState<eventModalType>({
		open: false
	});
	const [refresh] = useState(0);

	const [filter, setFilter] = useState({
		...defaultFilters
	});

	useEffect(() => {
		if (refresh) {
			setFilter({ ...defaultFilters });
		}
	}, [refresh]);

	const handleSearch = v => {
		if (v === "" || v?.length >= 3) {
			setFilter({ ...filter, page: 1, keyword: v });
		}
	};

	const debouncedSearchTerm = useDebounce(filter, 400);

	useEffect(() => {
		getPaginatedEvents({ ...debouncedSearchTerm });
	}, [debouncedSearchTerm, getPaginatedEvents]);

	const getOptions = useCallback((rowData: EventModel, rowIndex: number) => {
		const options: {
			name: string;
			onClick: () => void;
			id?: string;
		}[] = [
			{
				name: "Edit",
				id: `editEvent_${rowIndex + 1}`,
				onClick: () => {
					setEventInfoPopup({ open: true, model: rowData });
				}
			},
			{
				name: "Delete",
				id: `deleteEvent_${rowIndex + 1}`,
				onClick: () => {
					setEventInfoToDelete({
						id: rowData.eventId || null,
						name: rowData.title
					});
					setOpen(true);
				}
			}
		];

		return options;
	}, []);

	const tableColumns = useMemo(
		() => [
			{
				alignment: "left",
				width: 250,
				label: <Cell.HeaderText id="quantity">{formatCount(eventCount, "Event")}</Cell.HeaderText>,
				Cell: ({ rowData: { title, eventImages, by } }: { rowData: EventModel }) => {
					const image = eventImages && !!eventImages.length ? eventImages[0] : undefined;

					return (
						<Cell.Wrapper>
							<Cell.ImageWrapper>
								{image ? (
									<Cell.Image src={image} />
								) : (
									<PlaceholderImage
										type={PlaceholderImageType.EVENT_DETAILS}
										width={40}
										height={40}
										viewBox={"0 0 400 400"}
									/>
								)}
							</Cell.ImageWrapper>
							<Cell.Wrapper className="column with-image">
								<Cell.Text>{title}</Cell.Text>
								<Cell.Text className="light">{by}</Cell.Text>
							</Cell.Wrapper>
						</Cell.Wrapper>
					);
				},
				loaderTemplate: TableColumnLoaderType.imageWthTwoTextRows,
				dataKey: "content"
			},
			{
				alignment: "left",
				label: <Cell.HeaderText>Location</Cell.HeaderText>,
				Cell: ({ rowData: { location } }: { rowData: EventModel }) => (
					<Cell.Wrapper>
						<Cell.Text>{location?.name ? location.name : "-"}</Cell.Text>
					</Cell.Wrapper>
				),
				dataKey: "date"
			},
			{
				alignment: "left",
				label: <Cell.HeaderText>Attendees</Cell.HeaderText>,
				Cell: ({ rowData: { totalAttendess } }: { rowData: EventModel }) => (
					<Cell.Wrapper>
						<Cell.Text>{totalAttendess}</Cell.Text>
					</Cell.Wrapper>
				),
				dataKey: "reactions"
			},
			{
				alignment: "left",
				label: <Cell.HeaderText>Start Date</Cell.HeaderText>,
				width: 120,
				Cell: ({ rowData: { startTime } }: { rowData: EventModel }) => {
					if (startTime) {
						const correctDate = DateTime.fromISO(startTime.toString());
						return (
							<Cell.Wrapper className="column with-image">
								<Cell.Text>{correctDate.toFormat("MMM d, yyyy")}</Cell.Text>
								<Cell.Text className="light">{correctDate.toFormat("h:mm a")}</Cell.Text>
							</Cell.Wrapper>
						);
					}
					return "-";
				},
				loaderTemplate: TableColumnLoaderType.twoTextRows,
				dataKey: "comments"
			},
			{
				alignment: "left",
				label: <Cell.HeaderText>End Date</Cell.HeaderText>,
				width: 120,
				Cell: ({ rowData: { endTime } }: { rowData: EventModel }) => {
					if (endTime) {
						const correctDate = DateTime.fromISO(endTime.toString());
						return (
							<Cell.Wrapper className="column with-image">
								<Cell.Text>{correctDate.toFormat("MMM d, yyyy")}</Cell.Text>
								<Cell.Text className="light">{correctDate.toFormat("h:mm a")}</Cell.Text>
							</Cell.Wrapper>
						);
					}
					return "-";
				},
				loaderTemplate: TableColumnLoaderType.twoTextRows,
				dataKey: "comments"
			},
			{
				alignment: "right",
				label: "",
				width: 100,
				Cell: ({ rowData, rowIndex }: { rowData: EventModel; rowIndex: number }) => (
					<MenuDots
						menuId={`moreActions_${rowIndex + 1}`}
						options={getOptions(rowData, rowIndex)}
						vertical
						removeBg
						removeshadow
						removeSideMargin
					/>
				),
				loaderTemplate: TableColumnLoaderType.menuDots,
				dataKey: "menu"
			}
		],
		[eventCount, getOptions]
	);

	const confirmPopupAction = useCallback(
		async (confirm: boolean) => {
			setOpen(false);
			if (confirm && eventInfoToDelete.id) {
				await deleteEvent(eventInfoToDelete.id);
				getPaginatedEvents({ ...filter });
			}
		},
		[deleteEvent, eventInfoToDelete.id, filter, getPaginatedEvents]
	);

	const eventTypeOptions = useMemo(() => {
		return [
			{
				value: EventType.Happening,
				label: <Text>{EventType.Happening}</Text>
			},
			{
				value: EventType.Upcoming,
				label: <Text>{EventType.Upcoming}</Text>
			},
			{
				value: EventType.Past,
				label: <Text>{EventType.Past}</Text>
			},
			{
				value: EventType.All,
				label: <Text>{EventType.All}</Text>
			}
		];
	}, []);

	const handleChangePage = useCallback((newPage: number) => {
		setFilter(filter => ({ ...filter, page: newPage }));
	}, []);

	const handleChangePageSize = useCallback((newPageSize: number) => {
		setFilter(filter => ({ ...filter, page: 1, limit: newPageSize }));
	}, []);

	const TableBlock = useMemo(
		() => (
			<TableWrapper sizes={{ horizontalTablet: 900 }}>
				<Table
					columns={tableColumns}
					data={filteredEvents || []}
					loading={loading}
					paginated
					totalDataCount={eventCount || 10}
					page={page}
					pageSize={eventsShowPerPage}
					onChangePage={handleChangePage}
					onChangePageSize={handleChangePageSize}
				/>
			</TableWrapper>
		),
		[tableColumns, filteredEvents, eventCount, loading, page, eventsShowPerPage, handleChangePage, handleChangePageSize]
	);

	const FilterSelectItem = useMemo(
		() => (
			<SelectWrapper>
				<Select
					showInputLabel={{ show: true }}
					options={eventTypeOptions}
					value={filter.eventSchedule}
					label={"Event Type"}
					id={"event-type-select-id"}
					onChange={option => setFilter({ ...filter, eventSchedule: option })}
					showIcon
				/>
			</SelectWrapper>
		),
		[filter, eventTypeOptions]
	);

	return (
		<>
			<PageTemplate
				title={"Events"}
				showReportedLink
				isLoading={loading}
				isNoData={!filteredEvents}
				emptyText={"You don’t have any Events in your community yet."}
				searchPlaceholder={"Search Events"}
				onSearchUpdate={handleSearch}
				actionText={"Create Event"}
				actionId="createEvent"
				searchId="searchEvents"
				onCreateClick={() => setEventInfoPopup({ open: true, model: undefined })}
				extraAction={FilterSelectItem}
			>
				{TableBlock}
			</PageTemplate>
			{open && (
				<ConfirmDelete
					headline="Event"
					onClose={() => confirmPopupAction(false)}
					onDelete={() => confirmPopupAction(true)}
					deleteBtnId={"delete"}
				/>
			)}
			{eventInfoPopup.open && (
				<CreateEvent
					open={eventInfoPopup.open}
					editableModel={eventInfoPopup.model}
					onClose={e => {
						setEventInfoPopup({ open: false });
						if (e.created) {
							getPaginatedEvents({ ...filter });
						}
					}}
				/>
			)}
		</>
	);
});

export default ManageEvents;
