import React, { ReactNode, useCallback, useEffect, useMemo, useState } from "react";

import { useMediaQuery } from "@material-ui/core";
import clsx from "clsx";

import config from "config/appConfig";

import * as R from "ramda";

import MetaTags from "react-meta-tags";
import { useHistory, useLocation } from "react-router-dom";

import { StyledVyooLogo } from "apps/Auth/Layouts/style";
import { routes as authRoutes } from "apps/Auth/constants";
import { routes as onboardingRoutes } from "apps/Onboarding/constants";
import { routes as adminRoutes } from "apps/RegularUser/constants";
import { useAuth } from "modules/App/Data/hooks";

import { useCommunity, useScrollbar } from "shared/hooks";
import { Stepper, StepperStepProps, Text } from "shared/ui-kit";
import theme, { customBreakpoints } from "theme/default";

import {
	ContactBlock,
	ContactLink,
	ContentWrapper,
	FooterWrapper,
	Main,
	StepperBlock,
	TopBar,
	TopBarContainer
} from "./style";

interface OnboardingLayoutProps {
	children: ReactNode;
}

const OnboardingLayout: React.FC<OnboardingLayoutProps> = ({ children }) => {
	const { pathname } = useLocation();
	const history = useHistory();

	const { getData: getCommunityData } = useCommunity();
	const {
		name,
		url,
		takenUrl,
		selectedTypes,
		creating,
		created,
		isSubmittedCreatedCommunity,
		isConfirmedCaptcha,
		isWhitelabelAppMode,
		isAkinaMode,
		isReliasMode,
		isSportsMode,
		mainAdminColor
	} = getCommunityData();

	const { getData: getAuthData } = useAuth();
	const { isInvitationMode } = getAuthData();
	const { scrollBarToTop } = useScrollbar();

	useEffect(() => {
		scrollBarToTop();
	}, [pathname, scrollBarToTop]);

	const smallScreen = useMediaQuery(theme.breakpoints.down(customBreakpoints.tabletHorizontalMax));

	const activeStepIconProps = useMemo(
		() => ({
			fill: mainAdminColor || "#23b34a",
			group: "filled",
			name: "circle",
			width: 24,
			height: 24,
			viewBox: "-1 -1 26 26"
		}),
		[mainAdminColor]
	);

	const completedStepIconProps = useMemo(
		() => ({
			fill: mainAdminColor || "#23b34a",
			group: "",
			name: "check",
			width: 24,
			height: 24,
			viewBox: "-1 -2 27 27"
		}),
		[mainAdminColor]
	);

	const [steps, setSteps] = useState<StepperStepProps[]>([]);

	useEffect(() => {
		const allSteps = [
			{
				id: 1,
				label: "signUp",
				width: 22,
				widthOffset: 30,
				completed: false,
				activeStepIconProps,
				completedStepIconProps
			},
			{
				id: 2,
				label: "community/profile",
				width: 22,
				widthOffset: 30,
				completed: false,
				activeStepIconProps,
				completedStepIconProps
			},
			{
				id: 3,
				label: "community/create",
				width: 22,
				widthOffset: 30,
				completed: false,
				activeStepIconProps,
				completedStepIconProps
			},
			{
				id: 4,
				label: "community/type",
				width: 22,
				widthOffset: 30,
				completed: false,
				activeStepIconProps,
				completedStepIconProps
			},
			{
				id: 5,
				label: "community/feature",
				width: 22,
				widthOffset: 30,
				completed: false,
				activeStepIconProps,
				completedStepIconProps
			},
			{
				id: 6,
				label: "community/theme",
				width: 22,
				widthOffset: 30,
				completed: false,
				activeStepIconProps,
				completedStepIconProps
			}
		];
		setSteps(isSportsMode ? allSteps.filter(x => x.id !== 5) : allSteps);
	}, [isSportsMode, activeStepIconProps, completedStepIconProps]);

	const getActiveStep = React.useMemo(
		() => (): number => {
			let activeStep = 1;
			if (!isInvitationMode && steps.length) {
				switch (pathname) {
					case authRoutes.auth.signUp.getPath():
						activeStep = steps[0].id;
						break;

					case onboardingRoutes.onboarding.community.profile.getPath():
						activeStep = steps[1].id;
						break;

					case onboardingRoutes.onboarding.community.create.getPath():
						activeStep = steps[2].id;
						break;

					case onboardingRoutes.onboarding.community.about.getPath():
						activeStep = steps[3].id;
						break;

					case onboardingRoutes.onboarding.community.feature.getPath():
						activeStep = steps[4].id;
						break;

					case onboardingRoutes.onboarding.community.theme.getPath():
						activeStep = steps.find(x => x.id === 6)!.id;
						break;

					default:
						activeStep = 0;
				}
			}
			return activeStep;
		},
		[pathname, steps, isInvitationMode]
	);

	const [activeStep, setActiveStep] = useState<number>(getActiveStep() || 0);

	const goToStep = useCallback(
		(stepId: number): void => {
			switch (stepId) {
				case 1:
					history.push(authRoutes.auth.signUp.getPath());
					break;

				case 2:
					history.push(onboardingRoutes.onboarding.community.profile.getPath());
					break;

				case 3:
					history.push(onboardingRoutes.onboarding.community.create.getPath());
					break;

				case 4:
					history.push(onboardingRoutes.onboarding.community.about.getPath());
					break;

				case 5:
					history.push(onboardingRoutes.onboarding.community.feature.getPath());
					break;

				case 6:
					history.push(onboardingRoutes.onboarding.community.theme.getPath());
					break;

				case 7:
					history.push(onboardingRoutes.onboarding.congratulation.getPath());
					break;

				case 8:
					history.push(onboardingRoutes.onboarding.congratulation.confirmed.getPath());
					break;
			}
		},
		[history]
	);

	useEffect(() => {
		if (creating || created) {
			if (isSubmittedCreatedCommunity) {
				history.push(adminRoutes.dashboard.getPath());
			} else if (isConfirmedCaptcha) {
				goToStep(9);
			} else {
				goToStep(8);
			}
		} else {
			const activeStep = getActiveStep();
			const correctSteps = steps.map(step => {
				return {
					...step,
					completed: step.id < activeStep
				};
			});
			setActiveStep(activeStep);
			if (!R.equals(steps, correctSteps)) {
				setSteps([...correctSteps]);
			}
		}
	}, [
		pathname,
		getActiveStep,
		steps,
		creating,
		created,
		goToStep,
		isSubmittedCreatedCommunity,
		isConfirmedCaptcha,
		history
	]);

	useEffect(() => {
		if (isInvitationMode) {
			setSteps([]);
		}
	}, [isInvitationMode]);

	const selectOtherStep = useCallback(
		(newStepId: number): void => {
			const activeStepId = getActiveStep();
			if (activeStepId === 2 && newStepId === 1) {
				goToStep(newStepId);
			}

			if (activeStepId > 2 && newStepId > 2) {
				if (newStepId < activeStepId) {
					goToStep(newStepId);
				} else if (name && url && !takenUrl) {
					if (newStepId === 4 || (newStepId === 5 && selectedTypes.length)) {
						goToStep(newStepId);
					}
				}
			}
		},
		[getActiveStep, goToStep, name, selectedTypes, takenUrl, url]
	);

	const StepperComp = (
		<StepperBlock>
			<Stepper
				completed={false}
				steps={steps}
				clickable={true}
				variant={"circle"}
				activeStepKey={activeStep || 1}
				handleClick={step => {
					step?.id && selectOtherStep(step.id);
				}}
			/>
		</StepperBlock>
	);

	const showFooter = pathname.includes(onboardingRoutes.onboarding.community.getPath());

	return (
		<ContentWrapper>
			{isSportsMode && (
				<MetaTags>
					<title>Create a Sports Community to Connect, Fundraise and Broadcast in minutes.</title>
					<link rel="icon" href="/vs-favicon.ico" />
					<link rel="apple-touch-icon" sizes="180x180" href="/vs-apple-touch-icon.png" />
					<link rel="icon" type="image/png" sizes="32x32" href="/vs-favicon-32x32.png" />
					<link rel="icon" type="image/png" sizes="16x16" href="/vs-favicon-16x16.png" />
				</MetaTags>
			)}
			<TopBar className={isAkinaMode ? "workspace-kijijimoms" : isReliasMode ? "workspace-holliblu" : ""}>
				<TopBarContainer>
					<div className="left-side">
						<div className="brand">
							<div className="brand-image">
								{!isWhitelabelAppMode && <StyledVyooLogo className={clsx(isSportsMode && "sports-mode")} />}
							</div>
						</div>
					</div>
				</TopBarContainer>
			</TopBar>
			{!!activeStep && smallScreen && <>{StepperComp}</>}
			<Main>{children}</Main>
			{showFooter && (
				<FooterWrapper>
					{!!activeStep && !smallScreen && <>{StepperComp}</>}
					<ContactBlock>
						<ContactLink href={`mailto:${config.GLOBAL_CONSTANTS.CONTACT_US_EMAIL}?subject=Community help`}>
							<Text customStyle={() => "font-size: 12px;"}>Contact Us</Text>
						</ContactLink>
					</ContactBlock>
				</FooterWrapper>
			)}
		</ContentWrapper>
	);
};

export default OnboardingLayout;
