import styled from "styled-components";

const Wrapper = styled.div`
	height: 100%;
	position: relative;
`;

const Iframe = styled.iframe`
	width: 100%;
	height: 100%;
`;

const SpinnerWrapper = styled.div`
	position: absolute;
	top: 40%;
	left: 50%;
	transform: translateX(-50%);
`;

export { Wrapper, Iframe, SpinnerWrapper };
