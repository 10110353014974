import React from "react";

import styled from "styled-components";

import { ReactComponent as ChartIcon } from "assets/icons/icon-chart-column.svg";
import { Box } from "shared/ui-kit";

const EmptyContainer = styled(Box)`
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const EmptyText = styled.span`
	width: 105px;
	height: 42px;
	margin: 12px 0 0 0;
	font-size: 15px;
	font-weight: 600;
	line-height: 1.4;
	text-align: center;
	color: #222b45;
`;

const NoDataFound: React.FC = () => {
	return (
		<EmptyContainer>
			<ChartIcon />
			<EmptyText>{"We didn't find any results."}</EmptyText>
		</EmptyContainer>
	);
};

export default NoDataFound;
