import React, { useEffect, useState } from "react";

import { DateTime } from "luxon";

import { ReactComponent as RadarIcon } from "assets/icons/liveconvo/radar-live.svg";

import { Wrapper } from "./style";

interface ConversationDurationProps {
	startedAt: string;
}

const ConversationDuration: React.FC<ConversationDurationProps> = ({ startedAt }) => {
	const [duration, setDuration] = useState("");

	useEffect(() => {
		if (startedAt) {
			const startTime = DateTime.fromISO(startedAt);

			const interval = setInterval(() => {
				const diff = DateTime.now().diff(startTime, ["hours", "minutes", "seconds"]).toObject();

				const { hours = 0, minutes, seconds } = diff;
				const hour = hours > 0 ? `${hours}:` : "";
				const sec = seconds?.toString().split(".")[0] || "0";

				setDuration(`${hour}${minutes}:${sec?.length === 1 ? `0${sec}` : sec}`);
			}, 1000);

			return () => clearInterval(interval);
		}
	}, [startedAt]);

	return (
		<Wrapper>
			<RadarIcon fill={"#ffffff"} />
			<Wrapper.Text>LIVE {duration}</Wrapper.Text>
		</Wrapper>
	);
};

export default ConversationDuration;
