import React, { useCallback, useMemo } from "react";

import { Box, useMediaQuery } from "@material-ui/core";
import PhoneRoundedIcon from "@material-ui/icons/PhoneRounded";
import Skeleton from "@material-ui/lab/Skeleton";

import { useHistory } from "react-router";

import { ReactComponent as IconBin } from "assets/icons/icon-bin.svg";
import { ReactComponent as IconPinFilled } from "assets/icons/pin-angle-fill.svg";

import { ReactComponent as IconProfile } from "assets/icons/profile1.svg";
import { useMessaging } from "modules/Messaging/Data";
import { InboxItem } from "modules/Messaging/Data/types";
import { CHAT_STATUSES } from "modules/Messaging/constants";
import { MenuDots, PreviewDialog } from "shared/Components";
import { MenuDotsOption, TransformOriginOpts } from "shared/Components/MenuDots";

import { useMemberRoutes, useUser } from "shared/hooks";
import { Icon, Tooltip } from "shared/ui-kit";
import * as appTheme from "theme/default";
import { getGroupMemberAvatar } from "utils/getGroupMemberAvatar";

import {
	BackBtnWrapper,
	ChatAction,
	ChatIconGroup,
	ChatIconWrapper,
	ChatInfo,
	ChatInfoBlock,
	ChatStatus,
	ChatTitle,
	ChatWrapper,
	StyledChat,
	StyledIconButton
} from "./styles";

import Chat from "../Chat";
import { GroupdAvatarContainer, StyledGroupAvatar } from "../Inbox/style";

const ChatPanel: React.FC = () => {
	const {
		setActivePanel,
		getData: getMessagingData,
		deleteChat,
		deleteChatByConnectionId,
		getInbox,
		pinChat
	} = useMessaging();
	const { currentChat, previewDialog } = getMessagingData();

	const { getMemberRoutesData } = useMemberRoutes();
	const { routes } = getMemberRoutesData();

	const { getActiveProfile, getData: getUserData } = useUser();
	const { user, isMemberView } = getUserData();

	const history = useHistory();

	const activeProfile = useMemo(() => getActiveProfile(user), [getActiveProfile, user]);

	const isMobile = useMediaQuery(appTheme.default.breakpoints.down("sm"));

	const chatInfo = useMemo(() => {
		let name = currentChat?._id ? currentChat.name : `${currentChat?.firstName || ""} ${currentChat?.lastName || ""}`;

		if (!name && currentChat?.members?.length) {
			const maxCount = isMobile ? 1 : 5;
			name = "";
			currentChat.members.slice(0, maxCount).forEach((item, index) => {
				name += `${item.firstName}${item?.lastName ? ` ${item?.lastName}` : ""}${
					index + 1 < Math.min(currentChat.members.length, maxCount)
						? ", "
						: currentChat.members.length > maxCount
						? `+${currentChat.members.length - maxCount} others`
						: ""
				}`;
			});
		}

		return {
			status: currentChat?.status === CHAT_STATUSES.ACCEPTED ? "Active Now" : null,
			name,
			photo: currentChat?.profilePhoto || currentChat?.photo?.url || undefined
		};
	}, [currentChat, isMobile]);

	const getChatName = useCallback((item: InboxItem) => {
		return item.firstName || item.name
			? `${item.firstName || item.name}${item?.lastName ? " " + item.lastName : ""}`
			: item.members
					?.filter(item => !item.isOwner)
					.map(u => u.firstName || u.lastName)
					.join();
	}, []);

	const renderAvatar = useMemo(() => {
		if (currentChat?.type === "group") {
			const chatName = getChatName(currentChat as InboxItem);

			return (
				<GroupdAvatarContainer
					className={isMemberView && "member-margins no-ml"}
					overlap="circle"
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "right"
					}}
					badgeContent={
						<StyledGroupAvatar
							title={chatName}
							image={getGroupMemberAvatar(1, currentChat)}
							className={isMemberView && "large-avatar"}
						/>
					}
				>
					<StyledGroupAvatar
						title={chatName}
						image={getGroupMemberAvatar(0, currentChat)}
						className={isMemberView && "large-avatar"}
					/>
				</GroupdAvatarContainer>
			);
		} else {
			const photo = currentChat?.profilePhoto || currentChat?.photo?.url || undefined;
			return <img src={photo} alt={chatInfo.name} />;
		}
	}, [chatInfo.name, currentChat, getChatName, isMemberView]);

	const moreOptionsMenu = useMemo(() => {
		const opts: MenuDotsOption[] = [
			{
				name: "Delete chat",
				value: 5,
				icon: isMemberView ? <IconBin className="fill-first-path" /> : undefined,
				onClick: () => {
					currentChat && currentChat._id
						? deleteChat(`${currentChat._id}`)
						: deleteChatByConnectionId(`${currentChat?.connectionId}`);
				}
			}
		];

		// { name: "Pin chat", value: 1, onClick: () => {} },
		// { name: "Mark as unread", value: 2, onClick: () => {} },
		// { name: "Mute notifications", value: 3, onClick: () => {} },
		// { name: "Archive chat", value: 4, onClick: () => {} },

		if (currentChat?.type === "connection") {
			opts.unshift({
				icon: isMemberView ? <IconProfile className="fill-first-path" /> : undefined,
				name: "View Profile",
				value: 1,
				onClick: () => history.push(`${routes?.member.profile.getPath()}/${currentChat.personaId}`)
			});
		}

		return opts;
	}, [currentChat, deleteChat, deleteChatByConnectionId, history, isMemberView, routes?.member.profile]);

	const handlePinChat = useCallback(async () => {
		if (currentChat?.connectionId) {
			pinChat(currentChat.connectionId, !currentChat?.pinned, activeProfile?.personaId);
			getInbox({
				offset: 1,
				personaId: activeProfile?.personaId,
				limit: 20
			});
		}
	}, [currentChat, pinChat, activeProfile, getInbox]);

	return (
		<>
			<ChatWrapper>
				<ChatAction className={!currentChat && "loading"}>
					{!currentChat ? (
						<>
							<Skeleton width="27%" height={32} />
							<Skeleton width="9%" height={22} />
						</>
					) : (
						<>
							<ChatInfoBlock>
								<BackBtnWrapper onClick={() => setActivePanel("inbox")}>
									<Icon name="arrow-left" group="filled" fill="#c5cee0" width={20} />
								</BackBtnWrapper>
								<ChatInfo>
									{isMemberView && renderAvatar}
									<Box>
										<ChatTitle>{chatInfo.name}</ChatTitle>
										<ChatStatus>{chatInfo.status}</ChatStatus>
									</Box>
								</ChatInfo>
							</ChatInfoBlock>
							<ChatIconGroup>
								{!isMobile && currentChat?.type === "connection" && (
									<ChatIconWrapper>
										<Tooltip text="Pin Chat">
											<StyledIconButton className={currentChat?.pinned && "active"} onClick={handlePinChat}>
												<IconPinFilled />
											</StyledIconButton>
										</Tooltip>
									</ChatIconWrapper>
								)}
								<ChatIconWrapper>
									<Tooltip text="Coming Soon">
										<StyledIconButton className={"phone"}>
											<PhoneRoundedIcon htmlColor="#8f9bb3" />
										</StyledIconButton>
									</Tooltip>
								</ChatIconWrapper>
								<ChatIconWrapper>
									<Tooltip text="Coming Soon">
										<StyledIconButton className={"camera"}>
											<Icon name="video" group="filled" fill="#8f9bb3" />
										</StyledIconButton>
									</Tooltip>
								</ChatIconWrapper>
								<ChatIconWrapper>
									<MenuDots
										vertical
										alignLeft
										removeshadow
										customWidth={240}
										customOptionHeight={40}
										memberView={isMemberView}
										transformOrigin={TransformOriginOpts.TOP_RIGHT}
										options={moreOptionsMenu}
									>
										<Tooltip text="More actions" closeOnClick>
											<Icon
												fill="#c5cee0"
												group="filled"
												height={24}
												name="ellipsis"
												width={24}
												style={{ marginLeft: "10px" }}
												aria-controls="long-menu"
												aria-haspopup="true"
												aria-label="more"
											/>
										</Tooltip>
									</MenuDots>
								</ChatIconWrapper>
							</ChatIconGroup>
						</>
					)}
				</ChatAction>
				<StyledChat>
					<Chat />
				</StyledChat>
			</ChatWrapper>
			{!!previewDialog && <PreviewDialog />}
		</>
	);
};

export default ChatPanel;
