import { Box, ButtonBase, IconButton } from "@material-ui/core";
import styled, { css } from "styled-components";

export const ModalOverlay = styled(Box)`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	background-color: rgba(25, 36, 53, 0.1);
	z-index: 3000;
	.MuiDialogContent-root {
		padding: 0;
		overflow: ${({ allowOverflow }) => !allowOverflow && "hidden"};
	}
	.MuiDialog-root {
		height: 100%;
	}
	${({ customMaxWidth }) =>
		customMaxWidth &&
		css`
			.MuiDialog-paperWidthMd {
				max-width: ${customMaxWidth};
			}
		`}
	${props => props.theme.breakpoints.down("xs")} {
		.MuiDialog-paperScrollPaper {
			margin: 0;
			padding: 0;
			width: calc(100% - 32px);
		}
	}
`;

export const ClosePopupButton = styled(IconButton)`
	position: absolute;
	right: 12px;
	top: 0;
	height: 100%;
	&:hover {
		background-color: transparent;
	}
`;

export const ModalHeader = styled(Box)`
	text-align: center;
	padding: 16px;
	border-bottom: 1px solid #edf1f7;
	position: sticky;
	top: 0;
	z-index: 10;
	background-color: #ffffff;
`;

export const ModalContent = styled(Box)`
	max-height: 380px;
	padding: 16px;
	display: flex;
	flex-direction: column;

	${props => props.theme.breakpoints.up("sm")} {
		max-height: 540px;
		padding: 16px 40px;
	}
`;

export const SearchTracksWrapper = styled(Box)`
	border-radius: 4px;
	border: solid 0.5px #c6cfe0;
	background-color: ${({ whiteBG }) => (whiteBG ? "white" : "#f7f9fc")};
	color: #8f9bb3;
	margin-bottom: 24px;
`;

export const TracksScrollableList = styled(Box)`
	overflow-y: auto;
	scroll-behavior: smooth;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	::-webkit-scrollbar {
		width: 6px;
	}
	::-webkit-scrollbar-button {
		display: none;
	}
	::-webkit-scrollbar-track-piece {
		background: #f7f9fc
	}
	::-webkit-scrollbar-thumb {
		background: #c6cfe0;
		border-radius: 8px;
	}​
`;

export const TrackRow = styled(ButtonBase)`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 8px 16px;
	margin-bottom: 8px;
	background-color: ${({ checked }) => (checked ? "#f7f9fc" : "transparent")};
	.track-info {
		display: flex;
		align-items: center;
		width: 80%;
		.track-no-img {
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: #edf1f7;
		}
		.track-no-img,
		img {
			width: 40px;
			height: 40px;
			object-fit: cover;
			border-radius: 8px;
		}
		.track-name {
			display: flex;
			flex-direction: column;
			margin-left: 16px;
			color: #222b45;
			text-align: left;
			width: calc(100% - 56px);
			h6 {
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
			p {
				color: #8f9bb3;
			}
		}
		.checkbox {
			width: 20%;
		}
	}

	label {
		margin: 0;
	}

	&:hover {
		background-color: #f7f9fc;
	}

	${props => props.theme.breakpoints.down("sm")} {
		.track-info .track-name h6 {
			font-size: 12px;
		}
	}
`;

export const ModalFooter = styled(Box)`
	position: sticky;
	bottom: 0;
	background-color: white;
	button {
		width: 100%;
	}
	padding: ${({ noPadding }) => (noPadding ? "16px 0" : "16px")};
	border-top: 1px solid #edf1f7;
	.with-dropdown {
		position: relative;
		.dropdown {
			position: absolute;
			bottom: 100%;
			left: 2.5%;
			width: 95%;
			border-radius: 4px;
			background-color: #fff;
			box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.16);
			button {
				display: block;
				width: 100%;
				padding: 12px 24px;
				text-align: left;
				&:hover {
					background-color: #edf1f7;
				}
			}
		}
	}
	${props => props.theme.breakpoints.up("sm")} {
		display: flex;
		justify-content: flex-end;
		button {
			width: auto;
		}
	}
`;

export const LoadingTracksWrapper = styled(Box)`
	padding: 24px 16px;
	display: flex;
	align-items: center;
	justify-content: center;
	p {
		margin-left: 12px;
	}
`;
