import React, { useCallback, useEffect, useState } from "react";

import styled from "styled-components";

import useConfirmLeavePage from "shared/Components/ConfirmLeave/hooks/useConfirmLeavePage";
import { useCommunity } from "shared/hooks";
import { Box, Input, Text } from "shared/ui-kit";

import { correctName } from "utils/serviceUtils/helpers";

import SubmitButton from "../SubmitButton";

const Wrapper = styled(Box)`
	display: flex;
	flex-direction: row;
	flex: 1;
	justify-content: space-between;
	margin: 30px 10px 0 0;
	${props => props.theme.breakpoints.down("sm")} {
		flex-direction: column;
		width: 100%;
	}
`;

const StyledInput = styled(Input)`
	flex: 1;
	margin-right: 40px;
	max-width: 400px;
`;

const CommunityName = () => {
	const [name, setName] = useState("");
	const [showError, setShowError] = useState<boolean>(false);

	const { updateWorkspaceMeta, getData: getCommunityData } = useCommunity();
	const { workspace } = getCommunityData();

	const { provideHandleCloseValues } = useConfirmLeavePage({});

	const handleNameChange = useCallback(
		name => {
			setName(correctName(name));
			setShowError(!name || name.length > 60 || name.length < 2);
		},
		[setName]
	);

	useEffect(() => {
		if (workspace) {
			handleNameChange(workspace.meta?.displayName);
		}
	}, [workspace, handleNameChange]);

	useEffect(() => {
		provideHandleCloseValues(name !== workspace?.meta?.displayName);
	}, [name, workspace, provideHandleCloseValues]);

	const onSave = async () => {
		await updateWorkspaceMeta({
			meta: {
				displayName: name
			}
		});
	};
	return (
		<>
			<Text>
				Your community members will know you by your community name, and it will be displayed in different locations in
				your community. Your community can be or include your name, the name of your organization, or any other name of
				choice.
			</Text>
			<Wrapper>
				<StyledInput
					placeholder="Type community name here*"
					errorText={showError ? "Community name should be between 2 and 60 characters" : ""}
					value={name}
					onChange={e => handleNameChange(e.target.value)}
					id="communityName"
				/>
				<SubmitButton
					onClick={onSave}
					disabled={!name || name === workspace?.meta?.displayName || showError}
					id="saveChangesCommunityName"
				>
					Save changes
				</SubmitButton>
			</Wrapper>
		</>
	);
};

export default CommunityName;
