import styled from "styled-components";

import { Box } from "shared/ui-kit";

export const LoadingAlbums = styled(Box)`
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 32px 0;
	h6 {
		margin-left: 12px;
	}
`;
