import React, { FC, useCallback, useMemo } from "react";

import { IconButton } from "@material-ui/core";

import { useConnection, useNotification, useStory } from "shared/hooks";
import { PersonStoryModel } from "shared/types";
import { Button, Icon, Loader, Text, Tooltip } from "shared/ui-kit";

import { InteractWithMatch } from "./style";

import { ConnectedType } from "./index";

const MatchesActions: FC<{
	showControls: boolean;
	personsStoryList: PersonStoryModel[];
	currentSlide: number;
	filterMatch?: (personaId: number) => void;
	filteredStories: number[];
	displayPosition: number;
	handleSlideRight: () => void;
	connectUser?: {
		loading: boolean;
		connected: ConnectedType[];
	};
	setConnectUser?: React.Dispatch<
		React.SetStateAction<{
			loading: boolean;
			connected: ConnectedType[];
		}>
	>;
}> = ({
	showControls,
	personsStoryList,
	currentSlide,
	filterMatch,
	displayPosition,
	handleSlideRight,
	connectUser,
	setConnectUser,
	filteredStories
}) => {
	const { setCurrentStorySlide } = useStory();
	const { requestConnection, setMatchIntent } = useConnection();
	const { showMessage } = useNotification();

	const isConnected = useMemo(
		() => connectUser && connectUser.connected.some(c => c.id === personsStoryList[currentSlide].personaId),
		[connectUser, currentSlide, personsStoryList]
	);

	const filteredStoryList = useMemo(
		() => personsStoryList.filter(p => !filteredStories.some(f => f === p.personaId)),
		[filteredStories, personsStoryList]
	);

	const handleDismiss = useCallback(async () => {
		const data = {
			date: new Date().getTime().toString(),
			displayPosition,
			response: "nope",
			displayedPersonaId: filteredStoryList[currentSlide].personaId
		};

		await setMatchIntent(data);

		setCurrentStorySlide(0);
		filterMatch && filterMatch(filteredStoryList[currentSlide].personaId);
	}, [currentSlide, displayPosition, filteredStoryList, setCurrentStorySlide, filterMatch, setMatchIntent]);

	const handleConnect = useCallback(async () => {
		try {
			setConnectUser && setConnectUser(ctx => ({ ...ctx, loading: true }));
			await requestConnection(personsStoryList[currentSlide].personaId);
			showMessage("Connect request was successfully sent.");
			handleSlideRight();
		} catch (e: any) {
			showMessage(e.message);
		}
		setConnectUser &&
			setConnectUser(ctx => ({
				...ctx,
				loading: false,
				connected: [
					...ctx.connected,
					{
						id: personsStoryList[currentSlide].personaId
					}
				]
			}));
	}, [currentSlide, handleSlideRight, personsStoryList, requestConnection, setConnectUser, showMessage]);

	if (!connectUser || !setConnectUser) return null;

	return (
		<InteractWithMatch className={showControls && "visible"}>
			<IconButton
				size="small"
				className="outline"
				onClick={handleDismiss}
				disabled={connectUser.loading || isConnected}
			>
				<Icon name="close" group="filled" fill="#fff" />
			</IconButton>
			<Button
				buttonTheme="ghost"
				palette="basic"
				className="connect"
				onClick={connectUser.loading || isConnected ? () => {} : handleConnect}
			>
				{connectUser.loading ? (
					<Loader show variant="indeterminate" color="inherit" size="24px" />
				) : isConnected ? (
					<>
						<Icon name="message-check" group="filled" fill="#222b45" />
						<Text>Request Sent</Text>
					</>
				) : (
					<>
						<Icon name="user-plus" group="filled" />
						<Text>Connect</Text>
					</>
				)}
			</Button>
			<Tooltip text="Coming soon">
				<IconButton size="small" className="outline">
					<Icon name="comment" fill="#fff" />
				</IconButton>
			</Tooltip>
		</InteractWithMatch>
	);
};

export default MatchesActions;
