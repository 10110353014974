import React from "react";

import { useHistory } from "react-router";

import { routes } from "apps/Auth/constants";
import { ReactComponent as EmailVerifiedIcon } from "assets/icons/email-verified.svg";
import { ReactComponent as VyooLogo } from "assets/icons/vyoo-logo.svg";
import { useCommunity, useMemberRoutes, useUser } from "shared/hooks";
import { Button, Text } from "shared/ui-kit";

import { EmailVerified, EmailVerifiedWrapper, NavbarWrapper } from "./style";

const EmailVerifiedPage = () => {
	const { getData: getUserData } = useUser();
	const { user } = getUserData();

	const { getMemberRoutesData } = useMemberRoutes();
	const { routes: memberRoutes } = getMemberRoutesData();

	const { getData: getCommunityData } = useCommunity();
	const { workspace, isAkinaMode, isReliasMode, isBitcoinMode } = getCommunityData();

	const history = useHistory();

	const handleClick = () => {
		if (!user) return history.push(routes.auth.signIn.getPath());
		if (!workspace) return history.push(routes.auth.communities.getPath());
		return history.push(memberRoutes?.member.home.getPath());
	};

	return (
		<>
			<NavbarWrapper className={isAkinaMode ? "kijiji" : isReliasMode ? "holliblu" : isBitcoinMode ? "bitcoin" : ""}>
				{isAkinaMode ? (
					<i className={"akina-logo"} />
				) : isReliasMode ? (
					<i className={"relias-logo"} />
				) : isBitcoinMode ? (
					<i className={"bitcoin-logo"} />
				) : (
					<VyooLogo />
				)}
			</NavbarWrapper>
			<EmailVerifiedWrapper>
				<EmailVerified>
					<EmailVerifiedIcon className="mx-auto" />
					<Text variant="h1">Email Verified</Text>
					<Text>Your email address has been successfully verified.</Text>
					{!isReliasMode && (
						<Button palette="primary" onClick={handleClick}>
							{!user ? "Login" : !workspace ? "Login to Community" : "Go to Community"}
						</Button>
					)}
				</EmailVerified>
			</EmailVerifiedWrapper>
		</>
	);
};

export default EmailVerifiedPage;
