import { Box } from "@material-ui/core";
import styled from "styled-components";

export const BackgroundWrapper = styled(Box)`
	padding: 16px 20px;
`;

BackgroundWrapper.Section = styled(Box)`
	margin: 16px 0;
`;

BackgroundWrapper.Headline = styled(Box)`
	display: flex;
	align-items: center;
	justify-content: space-between;
	h3 {
		font-size: 18px;
		font-weight: 600;
		color: #222b45;
	}
`;

BackgroundWrapper.NoExperienceHeadline = styled(Box)`
	font-size: 12px;
	color: #222b45;
	margin: 6px 0 12px;
`;

BackgroundWrapper.ExpRow = styled(Box)`
	position: relative;
	button {
		position: absolute;
		top: 50%;
		right: 0;
		transform: translateY(-50%);
		border-radius: 99%;
	}

	.content {
		width: 80%;
	}
`;
