import React from "react";

import { DateTime } from "luxon";

import { EventModel, PlaceholderImageType } from "shared/types";

import SelectItemTemplate from "../Blocks/SelectItemTemplate";
import { PlaceholderImage } from "../index";

const getDateText = (date, start, end) => {
	return `${DateTime.fromISO(date).toFormat("ccc")}, ${DateTime.fromISO(start).toFormat("MMM d")} - ${DateTime.fromISO(
		end
	).toFormat("MMM d")}`;
};

const EventItem: React.FC<EventModel & { onSelect: () => void }> = ({
	title,
	eventImages,
	date,
	startTime,
	endTime,
	location,
	onSelect
}) => (
	<SelectItemTemplate
		coverUrl={eventImages?.length ? eventImages[0] : ""}
		onSelect={onSelect}
		pretitle={getDateText(date, startTime, endTime)}
		title={title}
		subtitle={location ? location.name : undefined}
		placeholderImg={
			<PlaceholderImage type={PlaceholderImageType.EVENT_DETAILS} width={80} height={80} viewBox={"0 0 400 400"} />
		}
	/>
);

export default EventItem;
