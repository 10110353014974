import React, { ReactNode } from "react";

import styled from "styled-components";

import { Button, ButtonProps } from "shared/ui-kit";

interface Props extends Omit<ButtonProps, "theme"> {
	icon: ReactNode;
	onClick: () => void;
	borderWidth?: number;
}

const StyledButton = styled(Button)<{ borderWidth: number }>`
	width: 56px;
	height: 56px;

	margin: 0;

	background-color: transparent;
	border: ${({ borderWidth }) => borderWidth}px solid #e4e9f2;

	&:hover {
		background-color: ${({ borderWidth }) => (borderWidth ? "#CCC" : "#444")};
	}
`;

const MediaActionButton: React.FC<Props> = props => {
	const { icon, onClick, borderWidth = 0, ...rest } = props;

	return (
		<StyledButton
			borderWidth={borderWidth}
			buttonTheme="light"
			onClick={onClick}
			oval
			palette="primary"
			rightIcon={icon}
			size="large"
			preventIconSize
			{...rest}
		/>
	);
};

export default MediaActionButton;
