import React, { memo, useEffect, useMemo, useState } from "react";

import { DateTime } from "luxon";

import { PostBlockEventType } from "types";

import { useEvent } from "shared/hooks";
import { EventModel, PlaceholderImageType } from "shared/types";

import { Icon } from "shared/ui-kit";

import ItemTemplate from "./ItemTemplate";

import { EventMessageModel } from "../../types/EventModel";
import { PlaceholderImage } from "../index";

const convertDateOffset = (date, locationOffset?: number): DateTime | undefined =>
	locationOffset ? DateTime.fromISO(date).plus({ minute: locationOffset }) : DateTime.fromISO(date);

const getDateText = (event?: EventMessageModel | EventModel) => {
	if ((event as EventModel).eventId) {
		const eventInfo = event as EventModel;
		const st = convertDateOffset(eventInfo.startTime, eventInfo.location?.utcOffset);

		return `${convertDateOffset(eventInfo.date, eventInfo.location?.utcOffset)?.toFormat("ccc")}, ${st?.toFormat(
			"MMM d"
		)} - ${convertDateOffset(eventInfo.endTime, eventInfo.location?.utcOffset)?.toFormat("MMM d")} at ${st?.toFormat(
			"t"
		)}`;
	} else {
		return "";
	}
};

interface Props {
	event?: EventModel | EventMessageModel;
	eventId?: string;
	id?: string;
	onEvent?: (eventType: PostBlockEventType, id: string) => void;
	maxWidth?: boolean;
	postView?: boolean;
	messageBlockCurrentUser?: boolean;
	link?: string;
	isMarketing?: boolean;
}

const EventBlock: React.FC<Props> = memo(
	({ event, eventId, maxWidth, id, onEvent, postView, messageBlockCurrentUser, link, isMarketing }) => {
		const [eventInfo, setEventInfo] = useState<EventModel | EventMessageModel | undefined>(event);
		const [loading, setLoading] = useState(false);
		const [isEventDeleted, setIsEventDeleted] = useState(false);

		const { getEvent } = useEvent(isMarketing);

		useEffect(() => {
			if (eventId) {
				(async () => {
					setLoading(true);
					const data = await getEvent(eventId);
					if (data) {
						setEventInfo(data);
					} else {
						setIsEventDeleted(true);
					}
					setLoading(false);
				})();
			}
		}, [getEvent, eventId]);

		const img = useMemo(() => {
			return (
				(!!(eventInfo as EventModel)?.eventImages?.length && (eventInfo as EventModel).eventImages[0]) ||
				(eventInfo as EventMessageModel).image ||
				""
			);
		}, [eventInfo]);

		return (
			<ItemTemplate
				link={link}
				featureName={"enableEvents"}
				id={id}
				wrapperProps={{
					maxWidth,
					postView,
					id,
					onEvent,
					messageBlockCurrentUser,
					"data-name": "event",
					"data-event": encodeURIComponent(
						JSON.stringify({
							...eventInfo,
							thumbnail: img
						})
					)
				}}
				indicatorIcon={<Icon name="calendar" group="filled" />}
				loading={loading}
				onClose={() => onEvent && id && onEvent(PostBlockEventType.delete, id)}
				coverUrl={img}
				pretitle={getDateText(eventInfo)}
				title={(eventInfo as EventModel)?.title || (eventInfo as EventMessageModel)?.name}
				subtitle={eventInfo?.location?.name}
				placeholderImg={
					<PlaceholderImage type={PlaceholderImageType.EVENT_DETAILS} width={80} height={80} viewBox={"0 0 400 400"} />
				}
				isDeleted={isEventDeleted}
			/>
		);
	}
);

export default EventBlock;
