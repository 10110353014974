import styled, { css } from "styled-components";

import { Avatar, Box, FormFooter, Text } from "shared/ui-kit";

export const ContentBlock = styled.section`
	display: flex;
	align-items: center;
	width: 100%;

	${props => props.theme.breakpoints.down("sm")} {
		flex-direction: column;
	}
`;

export const FormBlockWrapper = styled(Box)`
	width: 100%;
	display: flex;
	justify-content: center;
	${props => props.theme.breakpoints.down("sm")} {
		width: 100%;
	}
`;

export const InputContainerWrapper = styled(Box)`
	margin: 15px 0 0 0;

	display: flex;
	flex-direction: row;
	justify-content: space-between;

	${props => props.theme.breakpoints.down("sm")} {
		margin-top: 30px;
	}
`;

export const InputWrapper = styled(Box)`
	display: flex;
	width: 48%;

	.MuiFormHelperText-root {
		margin-left: 0px;
	}
`;

InputWrapper.Birthday = styled(Box)`
	position: relative;

	.input-error {
		white-space: nowrap;
	}
`;

export const UploaderCoverWrapper = styled(Box)`
	width: 100%;
	margin-top: 24px;

	position: relative;
`;

export const StyledError = styled(Text)`
	font-size: 12px;
	color: red;
`;

export const UploaderProfileWrapper = styled(Box)`
	width: 90px;

	margin-top: -45px;
	margin-left: 40px;

	display: inline-block;

	align-items: center;

	background-color: white;

	justify-content: center;

	position: relative;

	border-radius: 8px;

	overflow: hidden;

	padding: 5px;

	z-index: 10;
`;

UploaderProfileWrapper.Initials = styled(Text)`
	font-size: 36px;
	font-weight: bold;
	line-height: 1.33;
	text-align: center;
	color: #fff;
	text-transform: uppercase;
`;

export const DescriptionText = styled(Text)`
	font-size: 15px;
	color: #222b45;
	margin-top: 8px;
	font-weight: normal;
`;

export const TitleText = styled(Text)`
	color: #222b45;
	font-size: 36px;
`;

export const LogoWrapper = styled.div<{ color?: string }>`
	${({ color }) =>
		color &&
		css`
			#logo-svg-wrapper-id {
				path {
					fill: ${color};
				}
			}
		`}
`;

export const WelcomeText = styled(Text)<{ color?: string }>`
	margin-top: 12px;
	font-size: 18px;
	color: ${({ color }) => color || "#6173fe"};
`;

export const FormFooterWrapper = styled(FormFooter)`
	margin-top: 37px;

	${props => props.theme.breakpoints.down("sm")} {
		margin-top: 40px;
	}
`;

export const StyledAvatar = styled(Avatar)`
	border-radius: 15px;
	background: #6173fe;
	color: white;
`;

export const StyledForm = styled.form`
	width: 100%;
`;

export const BrokenLinkContainer = styled(Box)`
	text-align: center;
	h2 {
		margin: 24px 0 16px;
	}
`;
