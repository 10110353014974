import React from "react";

import { useCommunity } from "shared/hooks";

import { Label, LabelText, StyledSwitch } from "./styles";
interface Props {
	checked: boolean;
	onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const NotificationSwitch: React.FC<Props> = ({ checked, onChange }) => {
	const { communityColors } = useCommunity();
	return (
		<Label>
			<LabelText checked={checked} color={communityColors.primary}>
				{checked ? "On" : "Off"}
			</LabelText>
			<StyledSwitch checked={checked} onChange={onChange} color={communityColors.primary} disableRipple />
		</Label>
	);
};
