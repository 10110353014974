import React, { memo } from "react";

import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import { DateTime } from "luxon";
import styled from "styled-components";

import { PostBlockEventType } from "types";

import { PaymentRequestModel, PlaceholderImageType } from "shared/types";
import { Tooltip } from "shared/ui-kit";

import ItemTemplate from "./ItemTemplate";

import { PlaceholderImage } from "../index";

type Props = {
	payReq: Pick<PaymentRequestModel, "amountPaid" | "purpose" | "dueDate" | "artObj">;
	id?: string;
	maxWidth?: boolean;
	onEvent?: (eventType: PostBlockEventType, id: string) => void;
	messageBlockCurrentUser?: boolean;
	postView?: boolean;
};

const PayButton = styled.button`
	height: 32px;

	margin: 24px 0 24px 7px;
	padding: 8px 14px 8px 15px;

	border-radius: 4px;

	background-color: #6173fe;

	font-size: 12px;
	font-weight: bold;
	line-height: 1.33;
	color: #ffffff;
	text-align: center;
	white-space: nowrap;
	border: 0;

	&.messageBlockCurrentUser {
		width: 120px;
		background-color: #ffffff;
		color: #4a99f9;
		border-radius: 8px;
		box-shadow: 0 1px 5px 0 rgb(0 0 0 / 8%);
		height: 40px;
	}

	${props => props.theme.breakpoints.up("sm")} {
		width: 140px;
	}
`;

const RequestMoneyBlock: React.FC<Props> = memo(
	({ payReq, id, onEvent, maxWidth, messageBlockCurrentUser, postView }) => {
		const { artObj, amountPaid, purpose, dueDate } = payReq;

		const isValidDate = React.useMemo(() => {
			return DateTime.fromJSDate(dueDate).isValid;
		}, [dueDate]);

		return (
			<ItemTemplate
				id={id}
				wrapperProps={{
					id,
					onEvent,
					"data-name": "payreq",
					"data-payreq": encodeURIComponent(JSON.stringify(payReq)),
					maxWidth,
					messageBlockCurrentUser,
					postView
				}}
				onClose={() => onEvent && id && onEvent(PostBlockEventType.delete, id)}
				coverUrl={artObj?.url}
				indicatorIcon={<MonetizationOnIcon />}
				title={purpose}
				subtitle={
					isValidDate
						? `${DateTime.fromJSDate(dueDate).toFormat("MM/dd/YYYY")} ${DateTime.fromJSDate(dueDate).toFormat("t")}`
						: ""
				}
				placeholderImg={
					<PlaceholderImage type={PlaceholderImageType.REQUEST_MONEY} width={80} height={80} viewBox={"0 0 400 400"} />
				}
				extraBlock={
					<Tooltip text={"Coming soon"}>
						<PayButton
							onClick={e => e.preventDefault()}
							className={messageBlockCurrentUser && "messageBlockCurrentUser"}
						>
							Pay ${parseFloat(amountPaid).toFixed(2)}
						</PayButton>
					</Tooltip>
				}
			/>
		);
	}
);

export default RequestMoneyBlock;
