import React, { useCallback, useEffect, useMemo, useState } from "react";

import { InputAdornment, TextField, debounce } from "@material-ui/core";

import styled, { css } from "styled-components";

import { UserFileSortBy } from "types";

import { FileType } from "types/FilesContextValuesType";

import { ReactComponent as SearchIcon } from "assets/icons/iconSearch.svg";
import { FilterBlock, FilterOptionType } from "modules/MemberHome/View/Components";
import { FilterType } from "modules/MemberHome/View/Containers/Files";
import { TracksFilters } from "modules/MemberHome/View/Containers/Tracks";
import { useFiles, useUser } from "shared/hooks";
import { Box } from "shared/ui-kit";

import FileItem from "./FileItem";

import SelectItemTemplate from "../Blocks/SelectItemTemplate";
import { EmptyBody, ScrollableBlock } from "../index";

const FilterWrapper = styled(Box)`
	margin: 16px 0;
	display: flex;
	flex-direction: row;
	align-content: center;
	justify-content: flex-start;

	${({ inline }) =>
		!inline &&
		css`
			div,
			button :not(:first-child) {
				margin-left: 8px;
			}

			${props => props.theme.breakpoints.down("sm")} {
				display: none;
			}
		`}
`;

const ContentWrapper = styled(Box)`
	height: ${({ isMemberView }) => (isMemberView ? "340px" : "50vh")};
	padding-top: ${({ isMemberView }) => (!isMemberView ? "20px" : "0")};
`;

const FileItemsWrapper = styled(Box)`
	${props => props.theme.breakpoints.down("sm")} {
		display: flex;
		flex-direction: column;
		margin-top: 15px;
	}
`;

const LoaderBlock = () => (
	<>
		<SelectItemTemplate loading />
		<SelectItemTemplate loading />
		<SelectItemTemplate loading />
	</>
);

interface Props {
	onSelect: (g: FileType) => void;
}

const defaultFilters = {
	limit: 20,
	keyword: ""
};

const SelectFileBody: React.FC<Props> = ({ onSelect }) => {
	const { getFiles, getAllFilesCount, getData: getFileData } = useFiles();
	const { files, isLoading, filesTotalCount } = getFileData();

	const [filters, setFilters] = useState<FilterType>({ offset: 1, sortBy: UserFileSortBy.timestamp });

	const { getData: getUserData } = useUser();
	const { isMemberView } = getUserData();

	useEffect(() => {
		getFiles({ ...defaultFilters, ...filters, paginate: true });
	}, [getFiles, filters]);

	useEffect(() => {
		getAllFilesCount({});
	}, [getAllFilesCount, filters]);

	const handleSearchChange = useCallback(
		e => {
			const {
				target: { value }
			} = e;

			debounce(() => setFilters({ ...filters, keyword: value }), 500)();
		},
		[filters]
	);

	const filterOptions: FilterOptionType[] = useMemo(
		() => [
			{
				inlineOptions: true,
				onClick: item => {
					setFilters({
						sortBy: item === TracksFilters.MOST_POPULAR ? UserFileSortBy.popular : UserFileSortBy.timestamp,
						offset: 1
					});
				},
				listOptions: [
					{ label: "Most Recent", value: TracksFilters.MOST_RECENT, default: true },
					{ label: "Most Popular", value: TracksFilters.MOST_POPULAR }
				]
			}
		],
		[]
	);

	return (
		<>
			<TextField
				onChange={handleSearchChange}
				name="keyword"
				placeholder="Search"
				variant="outlined"
				className="search-wrapper"
				InputProps={{
					startAdornment: (
						<InputAdornment position="start">
							<SearchIcon />
						</InputAdornment>
					)
				}}
			/>
			{isMemberView && (
				<FilterWrapper inline>
					<FilterBlock inline options={filterOptions} />
				</FilterWrapper>
			)}
			<ContentWrapper isMemberView={isMemberView}>
				<ScrollableBlock
					onEndScroll={() =>
						!isLoading && (files?.length || 0) < filesTotalCount && setFilters(x => ({ ...x, offset: x.offset + 1 }))
					}
				>
					{files?.length ? (
						<FileItemsWrapper>
							{files.map((e, i) => (
								<FileItem key={i} {...e} onSelect={() => onSelect(e)} />
							))}
							{isLoading && <LoaderBlock />}
						</FileItemsWrapper>
					) : (
						<>{isLoading ? <LoaderBlock /> : <EmptyBody>Videos</EmptyBody>}</>
					)}
				</ScrollableBlock>
			</ContentWrapper>
		</>
	);
};

export default SelectFileBody;
