import React from "react";

import { useParams } from "react-router";

import { view } from "modules/MemberHome";

import { FeatureFlags } from "shared/Components/MemberNavPanel/constants";
import { useMemberRoutes } from "shared/hooks";
import useCheckIfEnabled from "shared/hooks/useCheckIfFlagEnabled";

const GroupPage = () => {
	useCheckIfEnabled(FeatureFlags.groups);

	const { getMemberRoutesData } = useMemberRoutes();
	const { routes } = getMemberRoutesData();

	const { id } = useParams() as { id: string };
	return <view.Group id={id} routes={routes} />;
};

export default GroupPage;
