import React, { useCallback, useState } from "react";

import { CircularProgress } from "@material-ui/core";
import styled from "styled-components";

import useConfirmLeavePage from "shared/Components/ConfirmLeave/hooks/useConfirmLeavePage";
import { useCommunity } from "shared/hooks";

import useNotification from "shared/hooks/useNotification";
import { useS3Uploader } from "shared/services/s3Uploader/hooks";
import { Box, Button, Icon, Text, Uploader, orientationConst } from "shared/ui-kit";
import { getValidationMessage } from "utils/getValidationMessage";

const Wrapper = styled(Box)`
	display: flex;
	flex-direction: row;
	flex: 1;
	margin: 30px 0 0 0;
	align-items: flex-end;
	padding-bottom: 70px;

	${props => props.theme.breakpoints.down("sm")} {
		flex-direction: column;
		width: 100%;
	}
`;

const ButtonWrapper = styled(Wrapper)`
	align-items: flex-end;
	justify-content: flex-end;
	margin: 0;
	padding-bottom: 0;
`;

const Subtitle = styled(Text)`
	margin-top: 15px;
	margin-bottom: 15px;
`;
const SubmitButton = styled(Button)`
	height: 48px;
	margin-right: 10px;
`;

const CoverImage = () => {
	const { updateWorkspaceMeta, getData: getCommunityData } = useCommunity();
	const { workspace } = getCommunityData();
	const [image, setImage] = useState<Partial<File>>();
	const { uploadFile } = useS3Uploader();
	const { showMessage } = useNotification();
	const [saving, setSaving] = useState(false);
	const { provideHandleCloseValues } = useConfirmLeavePage({});

	const onSaveCoverImage = useCallback(async () => {
		if (image) {
			setSaving(true);
			const data = await uploadFile({
				file: image as File,
				communityName: workspace?.meta?.displayName
			});
			if (data?.publicUrl) {
				await updateWorkspaceMeta({
					meta: {
						coverImage: data?.publicUrl
					}
				});

				showMessage(
					getValidationMessage({
						name: " Your changes have been successfully saved ✨"
					}),
					3
				);
			}
			setSaving(false);
		}
	}, [image, showMessage, workspace, uploadFile, updateWorkspaceMeta]);

	const handleImage = (files: Array<Partial<File>>) => {
		setImage(files[0]);
		provideHandleCloseValues(true);
	};

	return (
		<>
			<Subtitle variant={"subtitle1"}>Cover image</Subtitle>
			<Text>
				This will be the cover image on your community marketing page. We recommend an image at least 1200px wide and
				400px tall.
			</Text>
			<Wrapper>
				<Uploader
					urls={workspace?.meta?.coverImage ? [workspace.meta.coverImage] : []}
					description={
						<>
							Drag and drop file here or <span className="anchor">browse</span> to choose a file
						</>
					}
					icon={<Icon group={""} fill={"#c5cee0"} name={"cloud-upload"} width={64} height={50} viewBox={"4 2 18 19"} />}
					accept={[
						{
							fileType: "image/png, image/jpeg, image/x-eps",
							name: "png, jpg, eps"
						}
					]}
					label={"Upload Logo"}
					onChange={handleImage}
					orientation={window.outerWidth < 550 ? orientationConst.vertical : orientationConst.horizontal}
					width={window.outerWidth < 550 ? window.outerWidth * 0.65 : undefined}
					closeIconId="deleteLogoCommunityPage"
					uploaderId="uploadLogoCommunityPage"
				/>
				<ButtonWrapper>
					<SubmitButton
						disabled={!image || saving}
						leftIcon={saving ? <CircularProgress size={20} /> : null}
						onClick={onSaveCoverImage}
						id="saveChangesCommunityPage"
					>
						Save changes
					</SubmitButton>
				</ButtonWrapper>
			</Wrapper>
		</>
	);
};

export default CoverImage;
