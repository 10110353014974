import { ButtonBase } from "@material-ui/core";
import styled, { css } from "styled-components";

import { Box, Button } from "shared/ui-kit";

export const Wrapper = styled(Box)`
	width: 100%;
	position: relative;

	${({ inline }) =>
		!inline &&
		css`
			height: 64px;
			padding: 12px 16px;
			margin-bottom: 16px;
			border-radius: 12px;
			box-shadow: inset 0 0 0 0.5px #c5cee0, 0 2px 4px 0 rgba(0, 0, 0, 0.08);
			background-color: #fff;
		`}

	display: flex;
	align-items: center;
	flex-direction: row-reverse;
	justify-content: flex-end;

	${props => props.theme.breakpoints.up("md")} {
		flex-direction: row;
		justify-content: space-between;
	}
`;

export const Option = styled(Box)``;

Option.List = styled(Box)`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	padding-right: 0;
	padding-left: 0;

	${props => props.theme.breakpoints.down("xs")} {
		overflow-x: scroll;
		overflow-y: visible;
		padding-right: 8px;
		padding-left: 4px;
		-ms-overflow-style: none;
		scrollbar-width: none; /* Firefox */
		::-webkit-scrollbar {
			display: none;
		}
	}
`;

Option.Footer = styled(Box)`
	padding: 8px;
	box-shadow: inset 0 0 0 0.5px #c5cee0;
	background-color: #fff;
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

Option.ItemWrapper = styled(Box)`
	flex-shrink: 0;
	position: unset;
	&:not(:last-child) {
		margin-right: 8px;
	}

	${props => props.theme.breakpoints.up("sm")} {
		position: relative;
	}
`;

Option.CheckboxWrapper = styled(Box)`
	.MuiCheckbox-root {
		padding: 0;
	}
	.MuiFormControlLabel-root {
		margin: 0;
	}
	.Mui-checked svg {
		fill: #6173fe;
	}
`;

Option.Item = styled(Button)`
	border-radius: 8px;
	font-size: 15px;
	font-weight: 600;
	line-height: 1.6;
	color: #222b45;
	padding: 8px 16px;
	margin: 0;
	box-shadow: inset 0 0 0 0.5px #c5cee0;
	&:hover {
		color: #222b45;
	}
	${({ active }) =>
		active &&
		css`
			box-shadow: none;
			border: solid 1px #192038;
			background-color: #f7f9fc;
		`}
`;

Option.ItemOptions = styled(Box)`
	position: absolute;
	top: calc(100% + 4px);
	left: ${({ left }) => (left ? `${left}px` : "0")};
	width: 240px;
	overflow-y: auto;
	border-radius: 12px;
	box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12), inset 0 0 0 0.5px #c5cee0;
	background-color: #fff;
	z-index: 10;
	&::-webkit-scrollbar {
		display: none;
	}
`;

Option.Items = styled(Box)`
	padding: 8px 8px 0;
	max-height: 260px;
	overflow-y: auto;
`;

Option.OptionRow = styled(ButtonBase)`
	padding: ${({ skeleton }) => (!skeleton ? "8px" : "4px 0")};
	border-radius: 8px;
	font-size: 13px;
	font-weight: 600;
	line-height: 1.85;
	color: #222b45;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	&.checked {
		background-color: #f7f9fc;
	}
	&:hover {
		background-color: #f7f9fc;
	}
`;

export const Action = styled(Box)``;

Action.Wrapper = styled(Box)`
	flex-shrink: 0;
	display: flex;
`;

Action.Item = styled(Button)`
	margin: 0;
	border-radius: 8px;
	margin-right: 0;
	span.label {
		display: block;
	}

	${({ bgColor, cta }) =>
		bgColor &&
		cta &&
		css`
			color: ${bgColor};
			background-color: ${bgColor}33;
		`}

	&:hover {
		color: #ffffff;
		svg {
			fill: #ffffff;
			&.fill-first-child path:nth-child(1) {
				fill: #ffffff;
			}
			&.fill-second-child path:nth-child(2) {
				fill: #ffffff;
			}
		}
	}

	${props => props.theme.breakpoints.down("sm")} {
		min-width: 40px;
		margin-right: 4px;

		span.label {
			display: none;
		}
		span.icon {
			padding: 0;
		}
	}
`;
