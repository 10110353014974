import React from "react";

import styled, { css } from "styled-components";

import { Box } from "shared/ui-kit";

import FrameCircle from "./FrameCircle";

interface FrameHeaderProps {
	color: string;
	customStyle?: string;
}

const FrameHeaderWrapper = styled(Box)<FrameHeaderProps>`
	display: flex;
	justify-content: space-between;
	align-items: center;

	${({ customStyle }) => {
		return customStyle
			? css`
					${customStyle}
			  `
			: "";
	}}
`;

const FrameHeaderRightBlock = styled(Box)`
	display: flex;
	justify-content: flex-end;
	align-items: center;
`;

const ToggleWrapper = styled(Box)`
	position: relative;

	width: 34px;
	height: 24px;

	border-radius: 12px;

	overflow: hidden;

	background: #e4e9f2;
`;

const ToggleCircleWrapper = styled(Box)`
	position: absolute;
	top: 2px;
	right: 2px;
`;

const FrameHeader: React.FC<FrameHeaderProps> = (props: FrameHeaderProps): JSX.Element => {
	return (
		<FrameHeaderWrapper {...props}>
			<FrameCircle size={24} />
			<FrameHeaderRightBlock>
				<ToggleWrapper>
					<ToggleCircleWrapper>
						<FrameCircle size={20} color={props.color} />
					</ToggleCircleWrapper>
				</ToggleWrapper>
			</FrameHeaderRightBlock>
		</FrameHeaderWrapper>
	);
};

export default FrameHeader;
