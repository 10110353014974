import React from "react";

import { useLocation } from "react-router";

import { useHistory } from "react-router-dom";

import { view } from "modules/App";
import { useMemberRoutes } from "shared/hooks";

const UnsubscribePage = () => {
	const history = useHistory();

	const { search } = useLocation();
	const query = new URLSearchParams(search);
	const contactId = query.get("contactId");
	const workspace = query.get("workspace");
	const workspaceId = query.get("workspaceId");

	const { getMemberRoutesData } = useMemberRoutes();
	const { routes } = getMemberRoutesData();

	if (contactId && workspace && workspaceId) {
		return <view.Unsubscribe contactId={contactId} workspace={workspace} workspaceId={workspaceId} />;
	}

	return history.push(routes?.member.home.getPath());
};

export default UnsubscribePage;
