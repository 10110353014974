import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import DateFnsAdapter from "@date-io/date-fns";

import { Box } from "@material-ui/core";

import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

import ConfirmLeavePopup from "shared/Components/ConfirmLeave";
import useConfirmLeavePopup from "shared/Components/ConfirmLeave/hooks/useConfirmLeavePopup";
import { useAlbums, useCommunity, useOnScreen, useTrack } from "shared/hooks";
import { TrackModel } from "shared/types";
import { Button, Icon, InPlaceModal, Loader, Text } from "shared/ui-kit";

import TrackInfo from "./TrackInfo";
import { AlbumDetailsModalContent, PreviewAudio } from "./style";

import LoadingTracks from "../AddTracksToPlaylist/LoadingTracks";
import { ClosePopupButton, ModalFooter, ModalHeader, ModalOverlay } from "../AddTracksToPlaylist/style";
import { LoadingAlbums } from "../CreateUpdateAlbumDialog/style";

const dateFns = new DateFnsAdapter();

export const reorder = (list, startIndex, endIndex) => {
	const result = Array.from(list);
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);

	return result;
};

const AlbumDetailsDialog = () => {
	const {
		setPlayTrack,
		setAlbumDetailsDialog,
		getAlbum,
		setEditAlbumDetails,
		updateAlbum,
		updateTracksOrder,
		setTracksChanged,
		getMoreAlbumTracks,
		getData: getAlbumsData
	} = useAlbums();

	const { setTrackInfoPopup } = useTrack();
	const { playTrack, albumDetailsDialog, submitting, isLoading, tracksChanged } = getAlbumsData();

	const [reorderedTracks, setReorderedTracks] = useState<TrackModel[]>([]);
	const [offset, setOffset] = useState(1);
	const [loadData, setLoadData] = useState(true);
	const [tracksReordered, setTracksReordered] = useState(false);
	const lastTrackRef = useRef<HTMLDivElement>(null);
	const onScreen = useOnScreen(lastTrackRef);

	const { communityColors } = useCommunity();

	const trackCount = (albumDetailsDialog.data && albumDetailsDialog.data.musicIds.length) || 0;

	const hasMoreItems = useMemo(
		() => trackCount > (albumDetailsDialog.data ? albumDetailsDialog.data.musics.length : 0),
		[albumDetailsDialog.data, trackCount]
	);

	useEffect(() => {
		if (onScreen && hasMoreItems) {
			setOffset(state => state + 1);
			setLoadData(true);
		}
	}, [hasMoreItems, onScreen]);

	useEffect(() => {
		if (albumDetailsDialog.id && !albumDetailsDialog.data) {
			getAlbum({ id: albumDetailsDialog.id });
		}
	}, [albumDetailsDialog.data, albumDetailsDialog.id, getAlbum]);

	useEffect(() => {
		if (onScreen && loadData && albumDetailsDialog.id && hasMoreItems && offset > 1) {
			getMoreAlbumTracks({ id: albumDetailsDialog.id, limit: 10, offset }).then(() => {
				setLoadData(false);
			});
		}
	}, [albumDetailsDialog.id, getMoreAlbumTracks, hasMoreItems, loadData, offset, onScreen]);

	const handleAddTracks = useCallback(() => {
		if (albumDetailsDialog.data) {
			setPlayTrack();
			setAlbumDetailsDialog({
				...albumDetailsDialog,
				open: false,
				addTracks: true
			});
		}
	}, [albumDetailsDialog, setAlbumDetailsDialog, setPlayTrack]);

	useEffect(() => {
		if (albumDetailsDialog.data && !albumDetailsDialog.data.musics.length) {
			handleAddTracks();
		}
	}, [albumDetailsDialog.data, handleAddTracks]);

	useEffect(() => {
		if (albumDetailsDialog.data) {
			setReorderedTracks([
				...albumDetailsDialog.data.musics.filter(m => !albumDetailsDialog.deletedTracks.some(d => m._id === d))
			]);
		}
	}, [albumDetailsDialog.data, albumDetailsDialog.deletedTracks, albumDetailsDialog.newTracks, setReorderedTracks]);

	const onDragEnd = useCallback(result => {
		if (!result.destination) {
			return;
		}

		if (result.destination.index === result.source.index) {
			return;
		}

		setReorderedTracks(
			reorderedTracks => reorder(reorderedTracks, result.source.index, result.destination.index) as TrackModel[]
		);
		setTracksReordered(true);
	}, []);

	const closeAlbumDetails = () => {
		setPlayTrack();
		setReorderedTracks([]);
		setTrackInfoPopup({ open: false });
		setTracksReordered(false);
		setTracksChanged(false);
		setAlbumDetailsDialog({
			open: false,
			deletedTracks: []
		});
	};

	const handleEditDetails = () => {
		if (albumDetailsDialog.data) {
			setEditAlbumDetails(albumDetailsDialog.data);
		}
	};

	const handleUpdateAlbum = async () => {
		if (albumDetailsDialog.data) {
			const currentVisibleTracksIds = reorderedTracks.map(t => t._id);
			const musicIds = [
				...currentVisibleTracksIds,
				...albumDetailsDialog.data.musicIds.filter(
					m => !currentVisibleTracksIds.some(c => c === m) && !albumDetailsDialog.deletedTracks.some(c => c === m)
				)
			];

			const itemIds = [
				...reorderedTracks,
				...albumDetailsDialog.data.musics.filter(oI => reorderedTracks.some(item => item._id === oI._id))
			];

			let musics: TrackModel[] = [];

			if (!!reorderedTracks.length) {
				musics = itemIds.filter((value, index, self) => index === self.findIndex(t => t._id === value._id));
			}

			if (tracksChanged) {
				if (albumDetailsDialog.data.scheduleDate && new Date(albumDetailsDialog.data.scheduleDate) < new Date()) {
					albumDetailsDialog.data.scheduleDate = "";
				}

				await updateAlbum(albumDetailsDialog.data._id, {
					musicIds,
					musics
				});
			} else {
				await updateTracksOrder(albumDetailsDialog.data._id, {
					musicIds
				});
			}

			closeAlbumDetails();
		}
	};

	const getItemStyle = useCallback(
		(isDragging, draggableStyle) => ({
			// some basic styles to make the items look a bit nicer
			userSelect: "none",
			// styles we need to apply on draggables
			...draggableStyle
		}),
		[]
	);

	const {
		handleClose,
		handleLeavePageConfirmed,
		closeConfirmPopup,
		getData: getConfirmLeavePopupData
	} = useConfirmLeavePopup({
		onClose: closeAlbumDetails,
		open: albumDetailsDialog.open
	});

	const { showConfirmPopup } = getConfirmLeavePopupData();

	return (
		<>
			<ConfirmLeavePopup
				handleLeavePage={handleLeavePageConfirmed}
				open={showConfirmPopup}
				onClose={closeConfirmPopup}
				popup
			/>
			<ModalOverlay allowOverflow customMaxWidth="720px">
				<InPlaceModal
					open={albumDetailsDialog.open}
					onClose={() => handleClose(tracksChanged || tracksReordered)}
					maxWidth="md"
					modalHeight={700}
				>
					<ModalHeader>
						<Text variant="h6">{albumDetailsDialog.data ? albumDetailsDialog.data.title : "Album"} Details</Text>
						<ClosePopupButton onClick={() => handleClose(tracksChanged || tracksReordered)} size="small">
							<Icon name="close" group="filled" fill="#c5cee0" />
						</ClosePopupButton>
					</ModalHeader>
					{albumDetailsDialog.data ? (
						<AlbumDetailsModalContent>
							<Box className="album-details-image">
								<img src={albumDetailsDialog.data.meta.url} alt={albumDetailsDialog.data.title} />
								<Box className="album-details">
									<Box>
										<Text variant="h5">{albumDetailsDialog.data.title}</Text>
										<Text variant="caption">
											Released: {dateFns.format(new Date(albumDetailsDialog.data.releaseDate), "MMM do, yyyy" as any)}
										</Text>

										<Text variant="body1">{albumDetailsDialog.data.artist}</Text>
									</Box>

									<Button size="small" palette="light" buttonTheme="light" onClick={handleEditDetails}>
										Edit Details
									</Button>
								</Box>
							</Box>
							<Box className="description">
								<Text variant="subtitle1">Description</Text>
								<Text variant="caption">{albumDetailsDialog.data.description}</Text>
							</Box>
							{!!reorderedTracks.length && (
								<Box className="tracks">
									<Box display="flex" justifyContent="space-between" alignItems="center">
										<Text variant="subtitle1">Album Tracks</Text>
										<Button size="small" palette="light" buttonTheme="light" onClick={handleAddTracks}>
											<Box display="flex" alignItems="flex-end">
												<Icon name="plus" width={16} height={16} fill={communityColors.primary} /> Add Tracks
											</Box>
										</Button>
									</Box>
									{playTrack && <PreviewAudio loop autoPlay src={playTrack} />}
									{reorderedTracks.length === 1 ? (
										<TrackInfo
											lastTrackRef={null}
											track={reorderedTracks[0]}
											lastItem={true}
											order={1}
											key={reorderedTracks[0]._id}
											hideDND
										/>
									) : (
										<DragDropContext onDragEnd={onDragEnd}>
											<Droppable droppableId="playlist-droppable">
												{provided => (
													<Box {...provided.droppableProps} ref={provided.innerRef} className="tracks-list">
														{reorderedTracks.map((track, i) => (
															<Draggable key={track._id} draggableId={track._id} index={i}>
																{(provided, snapshot) => (
																	<Box
																		ref={provided.innerRef}
																		{...provided.draggableProps}
																		{...provided.dragHandleProps}
																		style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
																	>
																		<TrackInfo
																			lastTrackRef={i + 1 === reorderedTracks.length ? lastTrackRef : null}
																			track={track}
																			lastItem={albumDetailsDialog.data?.musics.length === i + 1}
																			order={i + 1}
																		/>
																	</Box>
																)}
															</Draggable>
														))}
														{provided.placeholder}
														{isLoading && <LoadingTracks page={2} />}
													</Box>
												)}
											</Droppable>
										</DragDropContext>
									)}
								</Box>
							)}
							<ModalFooter noPadding>
								<Button disabled={submitting || (!tracksReordered && !tracksChanged)} onClick={handleUpdateAlbum}>
									{submitting ? <Loader color="inherit" size="16px" show variant="indeterminate" /> : "Save Changes"}
								</Button>
							</ModalFooter>
						</AlbumDetailsModalContent>
					) : (
						<LoadingAlbums>
							<Loader show variant="indeterminate" size="16px" />
							<Text variant="h6">Loading Album Details</Text>
						</LoadingAlbums>
					)}
				</InPlaceModal>
			</ModalOverlay>
		</>
	);
};

export default AlbumDetailsDialog;
