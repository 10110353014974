import { Link } from "react-router-dom";
import styled from "styled-components";

import { Box, Button, Text } from "shared/ui-kit";

export const InvitationButton = styled(Button)`
	width: 140px;
`;

export const DiscussionsWrapper = styled(Box)`
	width: 100%;
	${props => props.theme.breakpoints.up("sm")} {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	${props => props.theme.breakpoints.up("md")} {
		flex-direction: row;
		align-items: flex-start;
		justify-content: center;
	}
`;

DiscussionsWrapper.Discussions = styled(Box)`
	max-width: 568px;
	width: 100%;
	margin-bottom: 16px;
	${props => props.theme.breakpoints.up("md")} {
		margin-bottom: 0;
		padding: 0 16px;
	}
`;

DiscussionsWrapper.CreatePost = styled(Box)`
	padding: 14px 16px;

	border-radius: 12px;
	border: 1px solid rgba(197, 206, 224, 0.5);

	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);

	background-color: #fff;
	display: flex;
	align-items: center;

	img {
		width: 48px;
		height: 48px;
		object-fit: cover;
		border-radius: 99%;
	}

	input {
		margin-left: 16px;
		padding: 14px 16px;
		flex: 1;
		border-radius: 8px;
		border: 1px solid rgba(197, 206, 224, 0.5);
		background-color: #f7f9fc;
	}
`;

DiscussionsWrapper.PostWrapper = styled(Box)`
	margin-bottom: 20px;
`;

DiscussionsWrapper.RightPanel = styled(Box)`
	width: 100%;
	margin-bottom: 20px;
	${props => props.theme.breakpoints.up("sm")} {
		width: 320px;
		margin-bottom: 0;
	}
`;

export const SectionWrapper = styled(Box)`
	width: 100%;
`;

export const GroupSectionHeadline = styled(Box)`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 5px 0 10px;
	h3 {
		font-size: 22px;
		font-weight: bold;
		line-height: 1.45;
		color: #222b45;
	}
	button {
		display: flex;
		align-items: center;
		svg {
			margin-right: 6px;
			fill: ${({ bgColor }) => bgColor};
			&.fill-first-path path:first-child {
				fill: ${({ bgColor }) => bgColor};
			}
			&.fill-last-path path:last-child {
				fill: ${({ bgColor }) => bgColor};
			}
		}
		border-radius: 8px;
		font-size: 14px;
		font-weight: bold;
		line-height: 1.14;
		background-color: ${({ bgColor }) => `${bgColor}33`};
		color: ${({ bgColor }) => bgColor};

		&:hover {
			svg {
				fill: #ffffff;
				&.fill-first-path path:first-child {
					fill: #ffffff;
				}
				&.fill-last-path path:last-child {
					fill: #ffffff;
				}
			}
		}
	}
`;

export const InvitationBox = styled(Box)`
	display: flex;
	align-items: center;
	justify-content: space-between;

	margin-top: 16px;
	padding: 12px 16px;

	border-radius: 12px;

	background-color: #f7f9fc;
`;

InvitationBox.InfoWrapper = styled(Box)`
	display: flex;
	align-items: center;
`;

InvitationBox.Avatar = styled(Link)`
	width: 40px;
	height: 40px;

	border-radius: 50%;

	overflow: hidden;

	margin-right: 16px;

	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;

	cursor: pointer;
`;

InvitationBox.Text = styled(Text)`
	font-size: 15px;
	font-weight: normal;
	line-height: 1.47;
	color: #222b45;

	b {
		font-weight: bold;
	}
`;

export const CreatePostWrapper = styled(Box)`
	background: white;
	border: 1px solid rgba(197, 206, 224, 0.5);
	border-radius: 12px;
	box-shadow: 0 1px 1px 0 rgb(0 0 0 / 8%);
`;

export const CreatePostInput = styled(Box)`
	background: #f7f9fc;
	border: 1px solid #c5cee0;
	border-radius: 12px;
	color: #8f9bb3;
	font-size: 15px;
	display: flex;
	align-items: center;
`;
