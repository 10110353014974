import React, { FC } from "react";

import { Icon, Text } from "shared/ui-kit";

import { SectionHeader } from "../../shared/style";
import { MemberListProps } from "../MembersList";
import { Wrapper } from "../MembersList/style";
interface Social {
	name: string;
	icon: JSX.Element;
	url?: string;
	bg: string;
}
interface Props extends MemberListProps {
	list: string[] | Social[];
	displayLimit: number;
	editable?: () => void;
	languageView?: boolean;
	socialView?: boolean;
}

const InterestsBlock: FC<Props> = ({
	title,
	list,
	displayLimit,
	socialView,
	languageView,
	primaryButton,
	editable,
	marginBottom,
	marginTop
}) => {
	return (
		<Wrapper marginBottom={marginBottom} marginTop={marginTop}>
			{editable && (
				<Wrapper.EditButton onClick={editable}>
					<Icon name={!!list.length ? "pencil" : "plus"} group="filled" fill="#8f9bb3" />
				</Wrapper.EditButton>
			)}
			<SectionHeader variant="h4" className="mb-4 ml-2">
				{title}
			</SectionHeader>
			{socialView ? (
				(list as Social[])
					.filter(item => !!item.url)
					.map((item, i) => (
						<Wrapper.SocialItem bg={item.bg} href={item.url} target="_blank" rel="noopener noreferrer" key={i}>
							{item.icon}
							<Text>{item.name}</Text>
						</Wrapper.SocialItem>
					))
			) : languageView ? (
				<Wrapper.LanguageRow>
					{(list as string[]).slice(0, displayLimit).map((item, i) => (
						<Wrapper.Language last={list.length === i + 1} key={i}>
							{item}
						</Wrapper.Language>
					))}
				</Wrapper.LanguageRow>
			) : (
				<Wrapper.Tags>
					{(list as string[]).slice(0, displayLimit).map((item, i) => (
						<Wrapper.Tag key={i}>{item}</Wrapper.Tag>
					))}
				</Wrapper.Tags>
			)}
			{primaryButton && (
				<Wrapper.PrimaryButton palette="outlined" buttonTheme="outline" onClick={primaryButton.onClick}>
					{primaryButton.text}
				</Wrapper.PrimaryButton>
			)}
		</Wrapper>
	);
};

export default InterestsBlock;
