import { EventModel, VolunteerSlot } from "shared/types";
import { IStateProps } from "utils/StateOf";

import createStore from "utils/createStore";

export interface Values {
	loading: boolean;
	volunteersSlots: VolunteerSlot[];
	createUpdateVolunteerDialog?: CreateUpdateVolunteerDialog;
	volunteerDeleteId?: string;
	cancelVolunteerSlot?: VolunteerSlot;
	signUpDialog?: { open: boolean; signUpVolunteerDetail?: VolunteerSlot; eventDetail?: EventModel };
	creating?: boolean;
	shouldRequestStop: boolean;
}

export interface CreateUpdateVolunteerDialog {
	open: boolean;
	editDetails?: VolunteerSlot;
	action?: "create" | "update" | "view";
}

export const initialState: IStateProps<Values> = {
	loading: false,
	creating: false,
	volunteersSlots: [],
	shouldRequestStop: false
};

export default createStore<Values>({
	initialState
});
