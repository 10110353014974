import React, { FC, useCallback, useEffect, useRef, useState } from "react";

import { Box, ClickAwayListener } from "@material-ui/core";
import { FileDrop } from "react-file-drop";

import { ReactComponent as IconBin } from "assets/icons/icon-bin.svg";
import { ReactComponent as IconCamera } from "assets/icons/icon-camera.svg";
import { ReactComponent as IconLandscape } from "assets/icons/icon-landscape.svg";
import { Icon, Loader, Text } from "shared/ui-kit";

import { filterFiles } from "utils/serviceUtils/helpers";

import { Container, ImageUploaderWrapper, UploaderError } from "./style";

interface ImageUploaderProps {
	errorText?: string;
	accept?: string[];
	onChange: (files: FileList) => void;
	defaultPreviewUrl?: string;
	uploading?: boolean;
	onDelete?: () => void;
}

const ImageUploader: FC<ImageUploaderProps> = ({
	errorText,
	onChange,
	accept,
	defaultPreviewUrl,
	uploading,
	onDelete
}) => {
	const [previewURL, setPreviewURL] = useState<string | null>(null);
	const [dragOver, setDragOver] = useState(false);
	const [frameEntered, setFrameEntered] = useState(false);
	const [showOptions, setShowOptions] = useState(false);

	const inputRef = useRef<HTMLInputElement>(null);

	const handleOpenFileChoice = useCallback(() => {
		if (!previewURL) {
			inputRef.current?.click();
		} else {
			setShowOptions(true);
		}
	}, [previewURL]);

	const handleImagePicked = useCallback(
		(files: FileList | null) => {
			if (files) {
				const acceptedFiles = accept ? filterFiles(files, accept) : files;

				const file = acceptedFiles.length ? acceptedFiles[0] : null;
				if (file && file.type.startsWith("image/")) {
					const previewURL = URL.createObjectURL(file);
					setPreviewURL(previewURL);

					onChange(acceptedFiles);
				}
			}
		},
		[onChange, accept]
	);

	const handleClosePreview = useCallback(() => {
		onDelete && onDelete();
		setPreviewURL(null);
		if (inputRef.current) {
			inputRef.current.value = "";
			setShowOptions(false);
		}
	}, [onDelete]);

	const handleOnDrop = useCallback(
		files => {
			setDragOver(false);
			handleImagePicked(files);
		},
		[handleImagePicked]
	);

	useEffect(() => {
		if (defaultPreviewUrl) {
			setPreviewURL(defaultPreviewUrl);
		}
	}, [defaultPreviewUrl]);

	return (
		<FileDrop
			onDragOver={() => setDragOver(true)}
			onDragLeave={() => setDragOver(false)}
			onFrameDragEnter={() => setFrameEntered(true)}
			onDrop={handleOnDrop}
		>
			<Container>
				<ImageUploaderWrapper uploading={uploading} dragOver={dragOver} onClick={handleOpenFileChoice}>
					{previewURL && <ImageUploaderWrapper.Image src={previewURL} />}
					{!previewURL && (
						<Box>
							<IconLandscape />
							{frameEntered && <Text>Drop photo here!</Text>}
						</Box>
					)}
					<input type="file" hidden ref={inputRef} accept="image/*" onChange={e => handleImagePicked(e.target.files)} />
				</ImageUploaderWrapper>
				<ImageUploaderWrapper.EditButtonWrapper className="edit-image">
					{uploading ? (
						<Loader show size="24px" variant="indeterminate" color="secondary" />
					) : (
						<>
							<ImageUploaderWrapper.EditButton onClick={handleOpenFileChoice}>
								<Icon width={16} height={16} name="pencil" group="filled" fill="#ffffff" />
							</ImageUploaderWrapper.EditButton>
							{showOptions && (
								<ClickAwayListener onClickAway={() => setShowOptions(false)}>
									<ImageUploaderWrapper.ButtonOptions>
										<ImageUploaderWrapper.Option onClick={() => inputRef.current?.click()}>
											<IconCamera width={20} height={20} /> Change Photo
										</ImageUploaderWrapper.Option>
										<ImageUploaderWrapper.Option onClick={handleClosePreview}>
											<IconBin width={20} height={20} /> Delete Photo
										</ImageUploaderWrapper.Option>
									</ImageUploaderWrapper.ButtonOptions>
								</ClickAwayListener>
							)}
						</>
					)}
				</ImageUploaderWrapper.EditButtonWrapper>
				{errorText && <UploaderError>{errorText}</UploaderError>}
			</Container>
		</FileDrop>
	);
};

export default ImageUploader;
