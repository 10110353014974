import { useCallback, useEffect, useMemo } from "react";

import appConfig from "config/appConfig";
import { CommunityModel, DefaultResponseType, EnvApplicationNames, IManageFeature } from "types";

import { useAuth } from "modules/App/Data/hooks";
import { sportsFeatureList } from "shared/constants";
import useLocalStorage from "shared/services/localStorage/localStorage";

import { DismissCheckListType, ProfileType, WorkspaceProductModel } from "shared/types";
import monetizationPlansType from "shared/types/MonetizationPlansType";
import { WorkspaceProductPayloadType } from "shared/types/WorkspaceProductModel";

import { actionMethod, actionTypes, getValidationMessage } from "utils/getValidationMessage";

import useNotification from "./useNotification";

import { GLOBAL_VARS, originalFeatures } from "../constants";
import { useCommunityStore } from "../contexts";

import { convertCreatedSubscriptionPlansToJoinedPlan } from "../services/converters/WorkspaceProductConverter";

import useCommunityApiService from "../services/useCommunityApiService";

import {
	CommunityThemeType,
	CommunityType,
	CreateCommunityType,
	CreateMonetizationPlanType,
	SubscriptionUpdate,
	WorkspaceMetaPayloadType
} from "../types";
import { useFeature } from "./index";

const useCommunity = () => {
	const { showMessage } = useNotification();

	const communityService = useCommunityApiService();

	const { manageMultipleFeatures } = useFeature();
	const { login } = useAuth();

	const isAkinaMode = useMemo(() => appConfig.GLOBAL_CONSTANTS.ENV_APPLICATION_NAME === EnvApplicationNames.Akina, []);
	const isReliasMode = useMemo(
		() => appConfig.GLOBAL_CONSTANTS.ENV_APPLICATION_NAME === EnvApplicationNames.Relias,
		[]
	);
	const isBitcoinMode = useMemo(
		() => appConfig.GLOBAL_CONSTANTS.ENV_APPLICATION_NAME === EnvApplicationNames.Bitcoin,
		[]
	);

	const isWhitelabelAppMode = useMemo(
		() => isAkinaMode || isReliasMode || isBitcoinMode,
		[isAkinaMode, isReliasMode, isBitcoinMode]
	);

	const localStorage = useLocalStorage();

	const store = useCommunityStore();
	const { setState, setInitial } = useCommunityStore();

	const methods = useMemo(
		() => ({
			getSuggestionUrlList: async (name: string) => {
				setState(ctx => ({ ...ctx, loadingSuggestionList: true }));
				const data = await communityService.getUrlSuggestions(name);
				if (data) {
					setState(ctx => ({
						...ctx,
						takenUrl: !data.success,
						urlSuggestionList: data.availableDomains,
						loadingSuggestionList: false
					}));
				}
			},
			getCommunities: async ({
				keyword,
				offset = 1,
				limit = 10
			}: {
				keyword?: string;
				offset?: number;
				limit?: number;
			}) => {
				const { workspaces } = await communityService.searchCommunities({ keyword, offset, limit });
				return workspaces;
			},
			searchCommunities: async ({
				keyword,
				offset = 1,
				limit = 10
			}: {
				keyword?: string;
				offset?: number;
				limit?: number;
			}) => {
				setState(ctx => ({ ...ctx, searching: true }));
				const communities = await methods.getCommunities({ keyword, offset, limit });
				setState(ctx => ({
					...ctx,
					searchedCommunities: offset === 1 ? communities || [] : ctx.searchedCommunities.concat(communities || []),
					searching: false
				}));
			},
			getCheckList: async (globalUserId: string) => {
				setState(ctx => ({ ...ctx, loadingCheckList: true }));
				const data = await communityService.getCheckList(globalUserId);
				if (data) {
					setState(ctx => ({
						...ctx,
						loadingCheckList: false,
						checklist: data.checklist
					}));
				}
			},
			manageChecklist: async (
				payload: { features: DismissCheckListType[] },
				name: string
			): Promise<DefaultResponseType> => {
				const resp = await communityService.manageChecklist(payload);
				if (resp.success) {
					setState(ctx => ({
						...ctx,
						checklist: ctx.checklist.filter(x => x.name !== name)
					}));
				}
				return resp;
			},
			updateWorkspaceMeta: async (payload: WorkspaceMetaPayloadType = { meta: {} }, displayMessage = true) => {
				await communityService.updateWorkspaceMeta(payload);
				methods.setMeta(payload);
				if (displayMessage) {
					showMessage(
						getValidationMessage({
							name: "Your changes",
							actionType: actionTypes.CRUD,
							actionMethod: actionMethod.saved,
							emoji: "✨",
							multiple: true
						}),
						3
					);
				}
			},
			getAllMonetization: async () => {
				const data = await communityService.getAllMonetization();
				if (data && data.subscriptions) {
					setState({
						monetizationPlans: data.subscriptions,
						isFree: !data.subscriptions.some(x => x.selected)
					});
				}
			},
			withdrawFunds: async () => {
				try {
					const { success } = await communityService.withdrawFunds();
					if (success) {
						showMessage("Funds request received successfully.");
					}
				} catch (error) {
					showMessage((error as Error).message || "Funds request failed.");
				}
			},
			updateSubscriptionType: async (payload: { subscriptionId: string }) => {
				await communityService.updateSubscriptionType(payload);
			},
			updateWorkspaceMetaState: async meta => {
				setState(ctx => ({
					...ctx,
					workspace: ctx.workspace && {
						...ctx.workspace,
						meta
					}
				}));
			},
			createSubscriptionProduct: async (payload: CreateMonetizationPlanType) => {
				setState(ctx => ({ ...ctx, isLoading: true }));
				const { products } = await communityService.createMonetizationPlan(payload);
				setState(ctx => ({ ...ctx, isLoading: false }));
				if (products?.length) {
					const newProduct = convertCreatedSubscriptionPlansToJoinedPlan(products);
					setState(ctx => ({
						...ctx,
						subscriptionProducts: ctx.subscriptionProducts.concat([newProduct])
					}));
				}
				showMessage("Your changes have been successfully saved ✨");
			},
			updateSubscriptionProduct: async (payload: SubscriptionUpdate) => {
				await communityService.updateSubscriptionProduct(payload);
				setState(ctx => ({
					...ctx,
					subscriptionProducts: ctx.subscriptionProducts.map(x => {
						return x.monthly?._id === payload.monthly._id && x.annually?._id === payload.annually._id
							? (payload as WorkspaceProductModel)
							: x;
					})
				}));
				showMessage(
					getValidationMessage({
						name: "Subscription Plan",
						actionType: actionTypes.CRUD,
						actionMethod: actionMethod.saved,
						emoji: "✨"
					}),
					3
				);
			},
			getTypeList: async (purpose?: "sports" | "general" | "all") => {
				const data = await communityService.getTypeList(purpose);
				if (data) {
					setState({ typeList: data.types });
				}
			},
			createCommunity: async ({
				newCommunity,
				selectedFeatures
			}: // workspaceImg,
			// personaInfo
			{
				newCommunity: CreateCommunityType;
				selectedFeatures: string[];
				workspaceImg: File | string | null;
				personaInfo: ProfileType;
			}) => {
				setState(ctx => ({ ...ctx, creating: true }));
				const data = await communityService.createCommunity(newCommunity);
				if (data) {
					setState(ctx => ({ ...ctx, created: !!data._id }));
				}

				if (data.token && data.name) {
					await login(data.token, data.name);

					methods.getTypeList("all");

					// if (workspaceImg) {
					// 	methods.updateWorkspaceIcon(newCommunity.url, workspaceImg);
					// }
					//
					// methods.updatePersonaInfo(data, personaInfo);

					let featuresList: IManageFeature[] = originalFeatures.map(x => {
						const selected = selectedFeatures.some(f => f === x.value);
						return { ...x, enabled: selected || false };
					});
					// TODO: temp solution. Will be fixed later on backend + mobile
					if (selectedFeatures.some(f => f === "enableAudioTracks")) {
						featuresList.push({
							...originalFeatures.find(x => x.value === "enableAudioTracks"),
							enabled: true
						} as IManageFeature);
					}

					if (newCommunity?.isSportType) {
						// setup specific features
						featuresList = sportsFeatureList.map(x => ({
							title: x.name,
							value: x.name,
							text: x.name,
							shortDesc: x.name,
							enabled: x.isEnabled
						}));
					}
					manageMultipleFeatures(featuresList);
				}

				methods.getWorkspaceDetails();
				setState({ creating: false });
				return { ...data, url: newCommunity.url };
			},
			getWorkspaceDetails: async () => {
				try {
					const workspace = await communityService.getWorkspaceDetails();
					if (workspace) {
						setState({ workspace });
						localStorage.set("cdnUrl", workspace?.cdnUrl);
						const isSportsMode = workspace?.meta?.types?.some(x => x.isSportType);
						setState({ isSportsMode, mainAdminColor: isSportsMode ? appConfig.sportsAdminMainColor : undefined });
						methods.getTypeList("all");

						return workspace;
					}
					return null;
				} catch (err) {
					console.error(err);
					return null;
				}
			},
			getCustomWorkspaceDetails: async (name: string) => {
				const workspace = await communityService.getCustomWorkspaceDetails(name);
				return workspace;
			},
			getSubscriptionProducts: async (listFormat?: boolean) => {
				const data = await communityService.getSubscriptionProducts(listFormat);
				if (data) {
					setState({
						subscriptionProducts: data.products,
						canMakeFree: data.canMakeFree,
						haveActiveMembers: data.haveActiveMembers
					});
				}
			},
			resetSubscriptionProducts: () => {
				setState(ctx => ({ ...ctx, subscriptionProducts: [] }));
			},
			subscriptionSwitchToFreeStatus: async () => {
				const data = await communityService.getSubscriptionCanMakeFree();

				if (data) setState(ctx => ({ ...ctx, subscriptionCanMakeFree: data.canMakeFree }));
			},
			subscriptionSwitchToFree: async (subscriptionId: string) => {
				try {
					await communityService.putSubscriptionCanMakeFree(subscriptionId);
					showMessage(
						getValidationMessage({
							actionType: actionTypes.CRUD,
							actionMethod: actionMethod.saved,
							emoji: "✨"
						}),
						3
					);
				} catch (error) {
					showMessage(
						getValidationMessage({
							actionType: actionTypes.CRUD,
							actionMethod: actionMethod.notSaved
						}),
						3
					);
				}
			},
			getDefaultPremiumProducts: async () => {
				const data = await communityService.getDefaultPremiumProducts();
				if (data) {
					setState(ctx => ({ ...ctx, defaultTags: data.tags }));
				}
			},
			getCoinsList: async () => {
				setState(ctx => ({ ...ctx, isLoading: true }));
				const data = await communityService.getCoinsList();
				if (data) {
					setState({ coins: data.products, isLoading: false });
				}
			},
			getBagsList: async () => {
				setState(ctx => ({ ...ctx, isLoading: true }));
				const data = await communityService.getBagsList();
				if (data) {
					setState(ctx => ({ ...ctx, bags: data.products, isLoading: false }));
				}
			},
			updatePremiumProduct: async (payload: { data: WorkspaceProductPayloadType[] }, coinBags: boolean) => {
				setState(ctx => ({ ...ctx, isLoading: true }));
				try {
					await communityService.updatePremiumProduct(payload);
					const { products } = coinBags ? await communityService.getBagsList() : await communityService.getCoinsList();

					setState(ctx => ({ ...ctx, [coinBags ? "bags" : "coins"]: products, isLoading: false }));
				} catch (error) {
					console.log(error);
					setState(ctx => ({ ...ctx, isLoading: false }));
				}
			},
			setMonetizationPlans(monetization: monetizationPlansType[]) {
				setState(ctx => ({ ...ctx, monetizationPlans: monetization }));
			},
			setName(name: string) {
				setState(ctx => ({ ...ctx, name }));
			},
			setIsPrivate(isPrivate: boolean) {
				setState(ctx => ({ ...ctx, isPrivate }));
			},
			setMeta(payload: WorkspaceMetaPayloadType) {
				setState(ctx => ({
					...ctx,
					workspace: {
						...ctx.workspace,
						meta: { ...ctx.workspace?.meta, ...payload.meta },
						isPrivate: payload.isPrivate,
						reportContentSettings: payload?.reportContentSettings || ctx.workspace?.reportContentSettings || undefined
					}
				}));
			},
			setUrl(url: string) {
				setState(ctx => ({ ...ctx, url: `${url}${GLOBAL_VARS.COMMUNITY.EXTENSION_LINK}` }));
			},
			getUrlExtension(): string {
				return GLOBAL_VARS.COMMUNITY.EXTENSION_LINK;
			},
			setImage(img: string) {
				setState(ctx => ({ ...ctx, img }));
			},
			setTypeList(types: CommunityType[]) {
				setState(ctx => ({ ...ctx, selectedTypes: types }));
			},
			setTheme(theme: CommunityThemeType) {
				setState(ctx => ({ ...ctx, theme }));
			},
			setConfirmedCaptcha(isConfirmedCaptcha: boolean): void {
				setState(ctx => ({ ...ctx, isConfirmedCaptcha }));
			},
			setSubmittedCreatedCommunity(): void {
				setState(ctx => ({ ...ctx, isSubmittedCreatedCommunity: true }));
			},
			setShowJoinPopup(open: boolean) {
				setState(ctx => ({ ...ctx, showJoinPopup: open }));
			},
			clearState(): void {
				setState(null as any);
			},
			setWorkspace: (workspace?: CommunityModel) => {
				setState({ workspace });
			},
			async getCommunitySmartLink(): Promise<string> {
				try {
					const { link } = isAkinaMode
						? { link: appConfig.GLOBAL_CONSTANTS.KIJIJI_APP_STORE_SMART_LINK }
						: isReliasMode
						? { link: appConfig.GLOBAL_CONSTANTS.HOLLIBLU_APP_STORE_SMART_LINK }
						: await communityService.getCommunitySmartLink();
					return link || "";
				} catch {
					return appConfig.GLOBAL_CONSTANTS.VYOO_APP_STORE_SMART_LINK || "";
				}
			},
			setSportsMode(isSportsMode?: boolean) {
				setState({ isSportsMode, mainAdminColor: isSportsMode ? appConfig.sportsAdminMainColor : undefined });
			},
			setMainAdminColor(mainAdminColor?: string) {
				setState({ mainAdminColor });
			},
			resetState() {
				setState({
					persona: undefined,
					workspace: undefined
				});
			},
			resetStore() {
				setInitial();
				methods.resetState();
			}
		}),
		[
			setState,
			communityService,
			showMessage,
			login,
			manageMultipleFeatures,
			localStorage,
			isAkinaMode,
			isReliasMode,
			setInitial
		]
	);

	const getData = useCallback(() => {
		return { ...store, isWhitelabelAppMode, isAkinaMode, isReliasMode, isBitcoinMode };
	}, [isAkinaMode, isReliasMode, isWhitelabelAppMode, isBitcoinMode, store]);

	const communityColors = useMemo(() => {
		return {
			primary: store.workspace?.meta?.theme?.primaryColor || "#6173fe",
			btn: store.workspace?.meta?.theme?.primaryBtnColor || "#6173fe"
		};
	}, [store.workspace?.meta?.theme?.primaryBtnColor, store.workspace?.meta?.theme?.primaryColor]);

	useEffect(() => {
		if (store?.workspace?.meta?.types?.length) {
			const isSportsMode = store?.typeList?.length
				? store?.typeList?.find(x => x.type === store?.workspace?.meta?.types[0]?.type)?.isSportType
				: false;
			setState({ isSportsMode, mainAdminColor: isSportsMode ? appConfig.sportsAdminMainColor : undefined });
		}
	}, [store?.workspace?.meta?.types, store?.typeList, setState]);

	return { ...methods, getData, communityColors };
};

export default useCommunity;
