import { useMemo } from "react";

import { API_URLS } from "shared/constants";
import useHttpActions from "shared/services/api/core/useHttpActions";

import { BaseConverter } from "shared/services/converters";

const useLiveConversationApiService = (isMarketing = false) => {
	const params = useMemo(() => (isMarketing ? { customErrorHandlers: [] } : undefined), [isMarketing]);

	const actions = useHttpActions(params);

	return useMemo(
		() => ({
			notifyOnStart: async (liveConversationId: string) => {
				return BaseConverter.convert<{ success: boolean }>(
					await actions.get(API_URLS.LIVE_CONVERSATION.NOTIFY_ON_START(liveConversationId))
				);
			}
		}),
		[actions]
	);
};

export default useLiveConversationApiService;
