import React from "react";

import { Text } from "shared/ui-kit";

import { useTeam } from "../../../../Data";
import { StyledButton, StyledForm, StyledFormFooter } from "../style";

const TeamMemberActions = () => {
	const { setStateOfModal, getTeamMembers, getData: getTeamData } = useTeam();
	const { apiRequest, apiRequestParams, confirmActionMessage, modalFooterActionsText, page, teamMemberShowPerPage } =
		getTeamData();

	return (
		<StyledForm onSubmit={e => e.preventDefault()}>
			<Text align="center" display="initial" variant="h5">
				{confirmActionMessage}
			</Text>
			{modalFooterActionsText && (
				<StyledFormFooter textAlign="right">
					<StyledButton
						buttonTheme="outline"
						type="submit"
						palette="control"
						size="large"
						transparent
						onClick={() => {
							setStateOfModal({ isOpen: false });
						}}
						id={modalFooterActionsText?.cancelId}
					>
						{modalFooterActionsText?.cancelText}
					</StyledButton>
					<StyledButton
						buttonTheme="main"
						type="submit"
						palette="primary"
						size="large"
						onClick={async () => {
							setStateOfModal({ isOpen: false });
							await apiRequest(apiRequestParams);
							getTeamMembers({
								offset: page,
								limit: teamMemberShowPerPage,
								type: "team",
								keyword: null
							});
						}}
						id={modalFooterActionsText?.submitId}
					>
						{modalFooterActionsText?.submitText}
					</StyledButton>
				</StyledFormFooter>
			)}
		</StyledForm>
	);
};

export default TeamMemberActions;
