import React from "react";

import IFeature from "types/IFeature";

import { ReactComponent as AutoConnectIcon } from "assets/icons/auto-connect-icon.svg";
import { ReactComponent as AutoMessagetIcon } from "assets/icons/auto-message-icon.svg";
import { ReactComponent as FeatureChatIcon } from "assets/icons/features-chat.svg";
import { ReactComponent as FeatureCollectionsIcon } from "assets/icons/features-collections.svg";
import { ReactComponent as FeatureConnectinIcon } from "assets/icons/features-connecting.svg";
import { ReactComponent as FeatureCVIcon } from "assets/icons/features-cv.svg";
import { ReactComponent as FeatureDistanceLimitationIcon } from "assets/icons/features-distance-limitation.svg";
import { ReactComponent as FeatureEventsIcon } from "assets/icons/features-events.svg";
import { ReactComponent as FeatureForumIcon } from "assets/icons/features-forum.svg";
import { ReactComponent as FeatureGlobalChatIcon } from "assets/icons/features-global-chat.svg";
import { ReactComponent as FeatureGroupsIcon } from "assets/icons/features-groups.svg";
import { ReactComponent as FeatureMarketPlace } from "assets/icons/features-marketplace.svg";
import { ReactComponent as FeatureMatchingIcon } from "assets/icons/features-matching.svg";
import { ReactComponent as FeaturePlacesIcon } from "assets/icons/features-places.svg";
import { ReactComponent as FeaturePollsIcon } from "assets/icons/features-polls.svg";
import { ReactComponent as FeatureRewardsIcon } from "assets/icons/features-rewards.svg";
// import { ReactComponent as FeatureShopIcon } from "assets/icons/features-shop.svg";
import { ReactComponent as FeatureStoriesIcon } from "assets/icons/features-stories.svg";
import { ReactComponent as FeatureTracksIcon } from "assets/icons/features-tracks.svg";

import { ReactComponent as ReactAngryIcon } from "assets/icons/reactions/reactionAngry.svg";
import { ReactComponent as ReactDislikeIcon } from "assets/icons/reactions/reactionDislike.svg";
import { ReactComponent as ReactLoveIcon } from "assets/icons/reactions/reactionHeart.svg";
import { ReactComponent as ReactLaughIcon } from "assets/icons/reactions/reactionLaughing.svg";
import { ReactComponent as ReactLikeIcon } from "assets/icons/reactions/reactionLike.svg";
import { ReactComponent as ReactLovingIcon } from "assets/icons/reactions/reactionLoved.svg";
import { ReactComponent as ReactSadIcon } from "assets/icons/reactions/reactionSad.svg";
import { ReactComponent as ReactWowIcon } from "assets/icons/reactions/reactionWow.svg";

import { EmotionIconType, ThemeProps } from "./types";

export const API_URLS = {
	GLOBAL: {
		GET_MEMBERS: "/globaluser/members",
		POST_VALIDATE_EMAIL_MEMBER: "/globaluser/email/validate",
		PUT_CANCEL_INVITE_MEMBER: "/globaluser/cancel/invite",
		POST_INVITE_USER: "/globaluser/invite",
		POST_RESEND_INVITE_MEMBER: "/globaluser/resend",
		POST_INVITE_MEMBER: "/globaluser/invite",
		POST_MEMBER_COUNT: "globaluser/members/count",
		GET_MEMBERS_TO_INVITE: "/nodebb/group/inviteSearch",
		COUNT_INVIETABLE_MEMBERS: "/nodebb/group/inviteSearch/count"
	},
	ADMIN: {
		GET_COMMUNITY_DETAILS: "/admin/workspace/detail",
		GET_USER_SEARCH: "/admin/dashboard/users/list/search",
		POST_MARK_ARCHIVE: "admin/dashboard/users/archive",
		PUT_EDIT_ROLE_MEMBER: "/nodebb/group/members"
	},
	GROUP_CHAT: { GET_MEMBERS: "groupchat/v2/members/detail" },
	INVITATION: {
		CREATE_AND_GET: "/invitation/code",
		UPDATE: "/invitation/update",
		GET_ALL: "/invitation",
		DELETE: "invitation/delete"
	},
	CONTRIBUTORS: {
		GET_ALL: "/nodebb/boards/groups"
	},
	WORKSPACE: {
		INFO: "/admin/workspace/detail",
		POSTS: "/nodebb/recentPublicPostsV3",
		SUBSCRIPTIONS: "/admin/products?type=subscription&format=listing",
		STATISTIC: "/admin/workspace/statistic",
		SMS_LINK: "/user/sendmessage",
		MONETIZATION: "/subscriptiontype"
	},
	WORKSPACE_SETTINGS: {
		UPDATE_FEATURES: "/admin/workspace/meta",
		DETAILS: "/admin/workspace/detail"
	},
	WORKSPACE_ANALYTICS: {
		SUMMARY: "/admin/dashboard/summary",
		SUMMARY_CSV: "/admin/dashboard/summary/csv",
		NEW_USERS: "/admin/dashboard/users",
		NEW_USERS_CSV: "/admin/dashboard/users/csv",
		SIGN_UPS: "/admin/dashboard/signups",
		SIGN_UPS_CSV: "/admin/dashboard/signups/csv",
		SALES: "/admin/dashboard/sales",
		ACTIVE_USERS: "/admin/dashboard/activeusers",
		ACTIVE_USERS_CSV: "/admin/dashboard/activeusers/csv",
		REVENUE: "/admin/dashboard/revenue",
		TOP_REFEREES: "/invitation/analytics",
		TOP_REFEREES_CSV: "/invitation/analytics/csv",
		METRICS: "/admin/dashboard/metrics",
		METRICS_CSV: "/admin/dashboard/metrics/csv"
	},
	WORKSPACE_CHECKLIST: {
		GET_CHECKLIST: (userId: string) => `/admin/workspace/checklist/${userId}`,
		UPDATE_CHECKLIST_META: "/admin/workspace/meta"
	},
	COMMUNITY: {
		GET_SUGGESTIONS: (url: string) => `/admin/workspace/suggestions?name=${url}`,
		GET_JOB_SUGGESTIONS: (type: string, value: string) => `/suggestions/search?type=${type}&value=${value}`,
		GET_SKILLS_SUGGESTIONS: (type: string, limit: number, offset: number) =>
			`/suggestions/listing?type=${type}&limit=${limit}&offset=${offset}`,
		NEW_SUGGESTION: "/suggestions",
		GET_TYPE_LIST: "/admin/workspace/types",
		CREATE_COMMUNITY: "/admin/workspace",
		UPDATE_WORKSPACE_META: "/admin/workspace/meta",
		CREATE_WORKSPACE_PERSONA: "/persona/create",
		UPDATE_WORKSPACE_PERSONA: "/persona/update",
		GET_USERS_FROM_PERSONAS: "/persona/getByIds",
		VERIFY_WORKSPACE_PERSONA_SELFIE: "/persona/verify/photo",
		WITHDRAW_FUNDS: "/admin/workspace/withdraw/funds",
		SEARCH: "/admin/workspace/search",
		AUTOMATED_CONNECTION: "/persona/automatedconnection",
		UPDATE_USER_ADDRESS: "/user/update/address",
		COMMUNITY_SMART_LINK: "/admin/workspace/getCommunitySmartLink"
	},
	MONETIZATION: {
		GET_ALL_MONETIZATION: "/subscriptiontype",
		CREATE_MONETIZATION_PLAN: "/admin/products/create",
		LIST_SUBSCRIPTION_PRODUCTS: "/admin/products",
		GET_DEFAULT_PRODUCTS: "/products/tags",
		LIST_PREMIUM_PRODUCTS: "/admin/products",
		UPDATE_PREMIUM_PRODUCTS: "/products/premium",
		UPDATE_SUBSCRIPTION_PRODUCTS: "/admin/subscription/edit",
		UPDATE_SUBSCRIPTION_TYPE: "/subscriptiontype/select",
		GET_SUBSCRIPTION_CAN_MAKE_IT_FREE: "/admin/products?type=subscription&format=listing",
		PUT_SUBSCRIPTION_CAN_MAKE_IT_FREE: "/products/archived"
	},
	GENERAL_SETTINGS: {
		UPDATE_WORKSPACE_META: "/admin/workspace/meta"
	},
	USER: {
		CONNECTIONS: "/connection",
		GIFS: "/user/gifs",
		GOALS: "/user/goals",
		INTERESTS: "/user/interests",
		WORKSPACES: "/globaluser/communities",
		COMMUNITY_APP_LINK: "/user/link",
		PREVIEW_LINK: "/user/previewlink",
		UPDATE_CHAT_ID: "/persona/chatid",
		FIREBASE_TOKEN: "/persona/chatToken",
		PASSED_GUIDED_TOUR: (globaluserId: string) => `/globaluser/passedguidedtour/${globaluserId}`,
		BLOCK_USER: "/user/block",
		BULK_NOTIFY_USERS: "/user/bulk/notifications",
		PROFILE_DETAIL: "/persona/profileDetail",
		BLOCKED_USERS: "/user/blockedUsers",
		UNBLOCK_USER: "/persona/unblock",
		BLOCK_PERSONA: "/persona/block",
		REPORT_BUG: "/bug",
		CHANGE_PASSWORD: "/globaluser/update/password",
		DELETE_MEMBER: "/user/delete",
		UNBAN_MEMBER: "/user/unban",
		DELETE_CONNECTION: "/user/connections",
		CLEAR_COUNTER: "/persona/clear/counter",
		ACTIVATE: "/user/activate",
		DELETE: "/user/delete",
		REPORT_USER: "/persona/report",
		DELETE_GLOBAL: "/globaluser"
	},
	LIVE_CONVERSATION: {
		NOTIFY_ON_START: (id: string) => `/liveconversation/started/${id}`
	},
	PODCASTS: {
		GET_PODCASTS: "/show",
		FOLLOW: (id: string) => `/show/follow/${id}`,
		UNFOLLOW: (id: string) => `/show/unfollow/${id}`
	},
	EVENTS: {
		GET: "/event/eventDetail",
		GET_ALL: "/user/dashboard/discovery",
		GET_MY: "/event/myevents",
		CREATE: "/event/create",
		UPDATE: "/event/update",
		GET_CATEGORIES: "/event/categories",
		DELETE: "/event/delete",
		GET_COUNT: "/user/dashboard/discovery",
		SET_INTENT: "/event/setEventIntent",
		NOTIFY_ALL_USERS: "/event/notify/allusers",
		EVENT_PERSONAS: "/event/personas",
		GET_WITH_REQUESTS: "/event/withrequests",
		GET_COUNT_WITH_REQUESTS: "/event/withrequests/count",
		INVITE_TO_EVENT: "/event/invite"
	},
	CARPOOL: {
		CREATE: "carpool",
		OFFER_A_RIDE: (id: string) => `carpool/${id}/offer`,
		CANCEL_OFFER: (id: string) => `carpool/${id}/canceloffer`,
		BOOK_SEAT: (id: string) => `carpool/${id}/book`,
		CANCEL_BOOKING: (id: string) => `carpool/${id}/cancelbooking`,
		CHANGE_BOOKING: (id: string) => `carpool/${id}/updatebooking`
	},
	COLLECTIONS: {
		GET_POST: "/collection",
		COUNT: "/collection/count",
		GET_UPDATE_DELETE_COLLECTION: (id: string) => `/collection/${id}`,
		REORDER: (id: string) => `/collection/${id}/reorder`,
		URLS: (id: string) => `/collection/${id}/itemurls`
	},
	LOCATIONS: {
		GET_SUGGESTIONS: "/place/autoSuggest",
		GET_DETAILS: "/place/detail"
	},
	TRACKS: {
		GET: "/music",
		GET_ALL: "/music",
		CREATE: "/music",
		UPDATE: "/music",
		GET_COUNT: "/music/total",
		DELETE: (id: string) => `/music/${id}`
	},
	VIDEOS: {
		GET_BY_ID: (id: string) => `/video/${id}`,
		GET_ALL: "/video",
		CREATE: "/video",
		UPDATE: (id: string) => `/video/${id}`,
		GET_COUNT: "/video/count",
		DELETE: (id: string) => `/video/${id}`
	},
	VOLUNTEERS: {
		CREATE: "/volunteerslot",
		UPDATE: (id: string) => `/volunteerslot/${id}`,
		GET: (id: string) => `/volunteerslot/${id}`,
		DELETE: (id: string) => `/volunteerslot/${id}`,
		JOIN: (id: string) => `/volunteerslot/join/${id}`
	},
	REPORT_CONTENT: {
		REPORT: "/reportContent",
		REMOVE_REPORT: "/reportContent",
		REMOVE_CONTENT: "/reportContent/deleteContent",
		GET_REPORTS: "/reportContent",
		GET_REPORTS_COUNT: "/reportContent/count"
	},
	ALBUMS: {
		GET_CREATE_ALBUMS: "/album",
		GET_ALBUMS_COUNT: "/album/count",
		GET_UPDATE_DELETE_ALBUM: (id: string) => `/album/${id}`,
		UPDATE_ALBUM_ORDER: (id: string) => `/album/${id}/reorder`,
		MUSIC_URLS: (id: string) => `/album/${id}/musicurls`
	},
	FILES: {
		GET_FILE: (id: string) => `/userfile/${id}`,
		GET_FILES: "/userfile",
		GET_FILES_COUNT: "/userfile/count",
		UPDATE_DELETE_FILE: (id: string) => `/userfile/${id}`,
		REPORT: (id: string) => `/userfile/report/${id}`
	},
	GROUPS: {
		GET_ALL: "/nodebb/groups/listing",
		GET_ALL_COUNT: "/nodebb/groups/listing/count",
		CREATE: "/nodebb/groups",
		INVITE: "/nodebb/group/inviteall",
		GET_ADMINS: "/nodebb/boards/groups",
		JOIN: "/nodebb/group/members",
		TOP_CONTRIB: "/nodebb/boards/groups/topcontributors",
		ACCEPT_ALL: "/nodebb/groups/approveall",
		DECLINED_ALL: "/nodebb/groups/disapproveall"
	},
	PRODUCT: {
		GET_PRODUCT: "admin/products"
	},
	MARKETPLACE: {
		CATEGORIES: {
			BASE: "/category",
			COUNT: "/category/count"
		},
		COMPANIES: {
			BASE: "/company",
			COUNT: "/company/count"
		}
	},
	TOPIC: {
		CREATE_TOPIC: "/nodebb/topics",
		UPDATE_TOPIC: "/nodebb/topics",
		GET_TOPIC: "/nodebb/topics"
	},
	CATEGORIES: {
		GET_ALL: "/nodebb/fetch?path=/categories&method=GET"
	},
	MEDIA: {
		BASE: "/media",
		CREATE: "/media/create",
		CONNECTIONS: "/media/connections"
	},
	STORY: {
		WATCH: "/watched/save"
	},
	POOL: {
		GET_ALL: "/poll",
		GET: (id: string): string => `/poll/${id}`,
		CREATE: "/poll",
		ANSWER: "/poll"
	},
	PAYMENT: {
		CREATE: "/payment"
	},
	FOLLOW: (id: string) => `/nodebb/followBy/${id}`,
	BOARD: {
		GET_LIST: "/nodebb/fetch",
		CREATE: "/nodebb/groups",
		DELETE: "/nodebb/groups",
		UPDATE: "/nodebb/groups"
	},
	POST: {
		GET_LIST: "/nodebb/topics",
		DELETE: "/nodebb/topics"
	},
	TAGS: {
		CREATE: "/nodebb/topics",
		TAG_DETAILS: (title: string) => `nodebb/fetch?path=/tag?title=${title}&method=GET`,
		GET_TAGS: (keyword: string, limit: number, offset: number) =>
			`nodebb/fetch?path=/tag?keyword=${keyword || ""}%26limit=${limit}%26offset=${offset}&method=GET`
	},
	CONNECTION: {
		TOP: "/connection/top",
		REQUEST: "/connection/request",
		PENDING: "/connection/pending",
		RECOMMENDED: "/persona/nearby",
		PREFERENCE: "/persona/preference/save",
		USER_CONNECTIONS: "/connection",
		DELETE: "/connection/chat/delete",
		REPORT: "/persona/report",
		MANAGE: (isAccept: boolean) => `/connection/${isAccept ? "accept" : "reject"}`,
		UNDO: (preferenceId: string) => `/persona/preference/undo?preferenceId=${preferenceId}`
	},
	STRIPE: {
		CARDS: "/stripe/card",
		CARD_TOKEN: "/stripe/cardtoken",
		CARD_CHARGE: "/stripe/charges",
		SUBSCRIPE: "/stripe/subscription"
	},
	PURCHASE: {
		BUY_COINS: "/purchase/save"
	},
	STREAM: {
		START: (id: string) => `/stream/started/${id}`,
		PAUSE: (id: string) => `/stream/paused/${id}`
	},
	FUNDRAISER: {
		DONATE: "/fundraising/donate",
		BASE: "/fundraising/fundraisers",
		COUNT: "/fundraising/fundraisers/count",
		DONATIONS_COUNT: "/fundraising/donations/count",
		MEMBERS: (id: string) => `/fundraising/fundraisers/${id}/members`,
		CONTACTS: "/fundraising/contacts",
		CONTACT_INFO: (id: string) => `/fundraising/contacts/${id}`,
		PAUSE: (id: string) => `/fundraising/fundraisers/${id}/pause`,
		DETAILS: (id: string) => `/fundraising/fundraisers/${id}`,
		LEADERBOARD_CONTACTS: (id: string) => `/fundraising/fundraisers/${id}/leaderboard/members`,
		LEADERBOARD_GROUPS: (id: string) => `/fundraising/fundraisers/${id}/leaderboard/groups`,
		LEADERBOARD_SPONSORS: (id: string) => `/fundraising/fundraisers/${id}/leaderboard/sponsors`,
		DONATIONS_LIST: "fundraising/donations",
		DONATIONS_LIST_CSV: "fundraising/donations/csv",
		OPEN_CONTACT: (id: string) => `fundraising/contacts/${id}/opened`,
		UNSUBSCRIBE: (contactId: string) => `fundraising/contacts/${contactId}/unsub`,
		PAYMENT_INTENT: "/fundraising/v2/donate",
		DONATION_CONFIRM: "/fundraising/v2/confirmdonation"
	}
};

export const originalFeatures: IFeature[] = [
	{
		title: "Series & Collections",
		value: "enableCollections",
		text: "Create bundles of different content formats, including audio, video, and files, to share with the community.",
		shortDesc:
			"Create bundles of different content formats, including audio, video, and files, to share with the community.",
		icon: <FeatureCollectionsIcon />
	},
	{
		title: "Partners Marketplace",
		value: "enableMarketplace",
		text: "Create marketplace where your community members can find companies that you partner with in different product categories, and go to the company's website and purchase products at a discount.",
		shortDesc:
			"Create marketplace where your community members can find companies that you partner with in different product categories.",
		icon: <FeatureMarketPlace />
	},
	{
		title: "Matching",
		value: "matchingEnabled",
		text: "Allow members in your community to match with others with similar goals, and interests. Enabling the Matching feature will add additional steps to the onboarding experience. We will ask users to provide info regarding the profession, goals, interests, etc. It will also unlock a section on the discovery screen where people will see other members' suggestions to match with.",
		shortDesc: "Allow members in your community to match with others with similar likes, goals, and interests.",
		icon: <FeatureMatchingIcon />
	},
	{
		title: "Events",
		value: "enableEvents",
		text: "Create and manage events in your community. Enabling Events will allow you and the members of your community to create events, invite people to events, and see events created by others. Events can be hosted in-person or online, and you will be able to share the URL for the online event in the app. You can create public or private events in your community, allowing you to control the event's attendance. By enabling Events, you will also have access to creating event chats, allowing you to converse and chat with the attendees of the event.",
		shortDesc: "Create and manage events in your community.",
		icon: <FeatureEventsIcon />
	},
	{
		title: "Groups",
		value: "enableGroups",
		text: "Create and manage groups in your community. Enabling Groups will allow you and your community members to create groups, invite people to groups and join groups within the community. Groups can be public or private, allowing the group's creator or admin to curate who joins the group. By enabling Groups, you will also have access to creating group chats that will allow you to converse and chat with the group members.",
		shortDesc: "Create and manage groups in your community.",
		icon: <FeatureGroupsIcon />
	},
	{
		title: "Places",
		value: "enablePlaces",
		text: "By enabling places, you and your community members will be able to see places near by and check in. If you have matching enabled, here you’ll also be able to find matches who have checked into a place so you can go and say hi!",
		shortDesc: "Find places nearby and check-in.",
		icon: <FeaturePlacesIcon />
	},
	{
		title: "Chat/Messaging",
		value: "enableChat",
		text: "Connect with members via instant chat messaging. Enabling Chat will allow you and your community members to message one another instantly. Messaging allows you to share videos, images, and GIFs with others to have engaging discussions. ",
		shortDesc: "Connect with members via instant chat messaging.",
		icon: <FeatureChatIcon />
	},
	{
		title: "Tracks",
		value: "enableAudioTracks",
		text: "Upload compositions, podcasts, and more via Tracks to your community. By enabling Tracks, you and your community members will be able to upload and post tracks to the community.",
		shortDesc: "Upload compositions, podcasts, and more via Tracks to your community.",
		icon: <FeatureTracksIcon />
	},
	{
		title: "Loyalty & Rewards",
		value: "isLoyaltyEnabled",
		text: "Allow your community members to be rewarded by enabling this feature! Members will be able to earn points per interaction, achieve badges, and follow admin created rules to earn more!",
		shortDesc: "Allow your community members to be rewarded by enabling this feature!",
		icon: <FeatureRewardsIcon />,
		hideForNonWhitelabel: true
	},
	{
		title: "Forum/Message Board ",
		value: "enableForum",
		text: "By enabling Forums, you can create specific categories where people can post; this will allow you and your community members to have more in-depth conversations around various topics.",
		shortDesc: "Create categories where you can post and have in depth discussions.",
		icon: <FeatureForumIcon />
	},
	// {
	// 	title: "Shop",
	// 	value: "shopifyStoreEnabled",
	// 	text:
	// 		"Do you have an e-commerce shop? By enabling Shop, you can connect your Shopify store to your community, and you can sell your merch and/or products directly on your platform. ",
	// 	shortDesc: "Connect your Shopify store to your community.",
	// 	icon: <FeatureShopIcon />
	// },
	{
		title: "Polls",
		value: "enablePolls",
		text: "Create Polls to engage with your community for fun or collect feedback from your community to serve them better. By enabling Polls, you and your community members will be able to create polls that others can respond to within the community. ",
		shortDesc: "Create polls that others can respond to within the community.",
		icon: <FeaturePollsIcon />
	},
	{
		title: "Stories",
		value: "enableStories",
		text: "Create engaging stories to tell your community more about what you do; give them sneak peeks and behind-the-scenes footage. By enabling Stories, you and your community members will be able to share your stories within the community, which can be accessible for 24hrs of indefinitely, and it’s all up to you!",
		shortDesc: "Create engaging stories to tell your community more about what you do",
		icon: <FeatureStoriesIcon />
	},
	{
		title: "Global Community Chat",
		value: "communityChatEnabled",
		text: "Do you want to be in a group chat with all of your community members? Now you can! By enabling Global Community Chat, you now can instantly message all of your community members at once and engage in a conversation where everyone feels included.",
		shortDesc: "Instantly message all of your community members at once.",
		icon: <FeatureGlobalChatIcon />
	},
	{
		title: "Connecting",
		value: "enableConnecting",
		text: "Do you want to connect with your community members and allow them to connect? By enabling the connection feature, you allow community members to connect.",
		shortDesc: "Allow community members to connect.",
		icon: <FeatureConnectinIcon />
	},
	{
		title: "Distance Limitation",
		value: "enableDistanceLimitation",
		text: "Do you want to apply distance limits in your community? By enabling the distance limitation, you and your community members will only be able to find groups, events, posts, people, etc., that are near you. The maximum distance radius which will be allowed when enabled is 100 miles. ",
		shortDesc: "Limit your community to find groups, events, people and more within 100 miles. ",
		icon: <FeatureDistanceLimitationIcon />
	},
	{
		title: "CV Generator",
		value: "enableCvGenerator",
		text: "Allow community members to export their profiles as resumes that they can use outside of the platform.",
		shortDesc: "Allow community members to export their profiles as resumes.",
		icon: <FeatureCVIcon />
	}
];

export const AutoConnectFeature: IFeature = {
	title: "Autoconnect",
	value: "autoconnect",
	text: "When a user joins your community you can choose who from the admin team will be automatically connected to them. You can add multiple Admins to connect with. ",
	shortDesc:
		"When a user joins your community you can choose who from the admin team will be automatically connected to them.",
	icon: <AutoConnectIcon />
};

export const AutomatedMessagingFeature: IFeature = {
	title: "Automated message",
	value: "autoMessage",
	text: "When a user joins the community you can automatically send a message to welcome them to your community and explain the rules.",
	shortDesc:
		"When a user joins the community you can automatically send a message to welcome them to your community and explain the rules.",
	icon: <AutoMessagetIcon />
};

export const GLOBAL_VARS = {
	COMMUNITY: {
		EXTENSION_LINK: ".vyoo.me"
	}
};

export const groupThemeList: ThemeProps[] = [
	{
		id: 1,
		primaryColor: "#4a99f9",
		primaryBtnColor: "#4a99f9",
		isShort: false,
		options: [{ label: "Bright", value: "bright" }],
		ref: React.createRef(),
		themeId: "bright"
	},
	{
		id: 2,
		primaryColor: "#189646",
		primaryBtnColor: "#189646",
		isShort: false,
		options: [{ label: "Eco", value: "eco" }],
		ref: React.createRef(),
		themeId: "eco"
	},
	{
		id: 3,
		primaryColor: "#222b45",
		primaryBtnColor: "#222b45",
		isShort: false,
		options: [{ label: "Classic", value: "classic" }],
		ref: React.createRef(),
		themeId: "classic"
	},
	{
		id: 4,
		primaryColor: "#6173fe",
		primaryBtnColor: "#6173fe",
		isShort: false,
		options: [{ label: "Royal blue", value: "royal" }],
		ref: React.createRef(),
		themeId: "royalBlue"
	},
	{
		id: 5,
		primaryColor: "#09643c",
		primaryBtnColor: "#09643c",
		isShort: false,
		options: [{ label: "Wild forest", value: "forest" }],
		ref: React.createRef(),
		themeId: "wildForest"
	},
	{
		id: 6,
		primaryColor: "#ffd044",
		primaryBtnColor: "#ffd044",
		isShort: false,
		options: [{ label: "Busy bee", value: "bee" }],
		ref: React.createRef(),
		themeId: "busyBee"
	}
];

export const emotionIcons: EmotionIconType[] = [
	{
		value: "ic_react_love",
		icon: <ReactLoveIcon />,
		label: "Love",
		color: "#FA5F5F"
	},
	{
		value: "ic_react_like",
		icon: <ReactLikeIcon />,
		label: "Like",
		color: "#FFD16C"
	},
	{
		value: "ic_react_laugh",
		icon: <ReactLaughIcon />,
		label: "Haha",
		color: "#FFD16C"
	},
	{
		value: "ic_react_loving",
		icon: <ReactLovingIcon />,
		label: "Loving",
		color: "#FFD16C"
	},
	{
		value: "ic_react_wow",
		icon: <ReactWowIcon />,
		label: "Wow",
		color: "#FFD16C"
	},
	{
		value: "ic_react_sad",
		icon: <ReactSadIcon />,
		label: "Sad",
		color: "#D3D3D3"
	},
	{
		value: "ic_react_angry",
		icon: <ReactAngryIcon />,
		label: "Angry",
		color: "#FA5F5F"
	},
	{
		value: "ic_react_dislike",
		icon: <ReactDislikeIcon />,
		label: "Dislike",
		color: "#FFD16C"
	}
];

export const avatarUrlList = [
	"https://vyoo-staging.s3.us-west-2.amazonaws.com/uploads/animalavatars/Cat.png",
	"https://vyoo-staging.s3.us-west-2.amazonaws.com/uploads/animalavatars/Dinosaur.png",
	"https://vyoo-staging.s3.us-west-2.amazonaws.com/uploads/animalavatars/Dog.png",
	"https://vyoo-staging.s3.us-west-2.amazonaws.com/uploads/animalavatars/Elephant.png",
	"https://vyoo-staging.s3.us-west-2.amazonaws.com/uploads/animalavatars/Fish.png",
	"https://vyoo-staging.s3.us-west-2.amazonaws.com/uploads/animalavatars/Gorilla.png",
	"https://vyoo-staging.s3.us-west-2.amazonaws.com/uploads/animalavatars/Lion.png",
	"https://vyoo-staging.s3.us-west-2.amazonaws.com/uploads/animalavatars/Monkey.png",
	"https://vyoo-staging.s3.us-west-2.amazonaws.com/uploads/animalavatars/Moos.png"
	// "https://vyoo-staging.s3.us-west-2.amazonaws.com/uploads/animalavatars/Weil.png"
];

export const sportsFeatureList = [
	{
		name: "emailEnabled",
		isEnabled: true
	},
	{
		name: "licenseEnabled",
		isEnabled: false
	},
	{
		name: "allowExternalConnect",
		isEnabled: false
	},
	{
		name: "virtualEventsEnabled",
		isEnabled: true
	},
	{
		name: "industryFilterEnabled",
		isEnabled: true
	},
	{
		name: "skillFilterEnabled",
		isEnabled: false
	},
	{
		name: "specializationFilterEnabled",
		isEnabled: true
	},
	{
		name: "titleFilterEnabled",
		isEnabled: true
	},
	{
		name: "disablePotentialMatchForPeopleSearch",
		isEnabled: true
	},
	{
		name: "disable24HourConnection",
		isEnabled: false
	},
	{
		name: "isFacebookEnabled",
		isEnabled: true
	},
	{
		name: "selectAllEnabled",
		isEnabled: false
	},
	{
		name: "isInstagramEnabled",
		isEnabled: false
	},
	{
		name: "isEnabledPhoneLogin",
		isEnabled: true
	},
	{
		name: "isEnabledPasswordLogin",
		isEnabled: false
	},
	{
		name: "isEnabledOtherLogin",
		isEnabled: true
	},
	{
		name: "isEnabledEmailPasswordSignup",
		isEnabled: false
	},
	{
		name: "isPodcastEnabled",
		isEnabled: false
	},
	{
		name: "enableAudioTracks",
		isEnabled: false
	},
	{
		name: "enableRecentPosts",
		isEnabled: true
	},
	{
		name: "enableDashboardFilters",
		isEnabled: false
	},
	{
		name: "enableGroups",
		isEnabled: true
	},
	{
		name: "enablePlaces",
		isEnabled: false
	},
	{
		name: "enableEvents",
		isEnabled: true
	},
	{
		name: "enableMatches",
		isEnabled: false
	},
	{
		name: "isLinkedinLoginEnabled",
		isEnabled: false
	},
	{
		name: "isLoyaltyEnabled",
		isEnabled: false
	},
	{
		name: "enablePolls",
		isEnabled: true
	},
	{
		name: "enableYouTube",
		isEnabled: true
	},
	{
		name: "enableBlogsAndArticles",
		isEnabled: false
	},
	{
		name: "enableEditorV2",
		isEnabled: true
	},
	{
		name: "enableReqPayment",
		isEnabled: true
	},
	{
		name: "enableConversations",
		isEnabled: true
	},
	{
		name: "enableCvGenerator",
		isEnabled: false
	},
	{
		name: "enableDistanceLimitation",
		isEnabled: false
	},
	{
		name: "enableConnecting",
		isEnabled: true
	},
	{
		name: "enableStories",
		isEnabled: true
	},
	{
		name: "enableForum",
		isEnabled: true
	},
	{
		name: "enableChat",
		isEnabled: true
	},
	{
		name: "matchingEnabled",
		isEnabled: false
	},
	{
		name: "communityChatEnabled",
		isEnabled: true
	},
	{
		name: "isAppDown",
		isEnabled: false
	},
	{
		name: "enableEditor",
		isEnabled: true
	},
	{
		name: "enablePayPal",
		isEnabled: false
	},
	{
		name: "journeyEnabled",
		isEnabled: false
	},
	{
		name: "kidsEnabled",
		isEnabled: false
	},
	{
		name: "maritalStatusEnabled",
		isEnabled: false
	},
	{
		name: "enableBannerAds",
		isEnabled: false
	},
	{
		name: "enablePhotoVerification",
		isEnabled: false
	},
	{
		name: "enableInAppPurchases",
		isEnabled: false
	},
	{
		name: "enableTicketPurchase",
		isEnabled: true
	},
	{
		name: "enableCoinRedeem",
		isEnabled: false
	},
	{
		name: "enableShowInviteCode",
		isEnabled: false
	},
	{
		name: "isAppleEnabled",
		isEnabled: false
	},
	{
		name: "enableLongInviteCode",
		isEnabled: false
	},
	{
		name: "enableProfileLite",
		isEnabled: true
	},
	{
		name: "enablePodcastShows",
		isEnabled: false
	},
	{
		name: "enableDiscoverComments",
		isEnabled: true
	},
	{
		name: "enableMarketplace",
		isEnabled: false
	},
	{
		name: "enableDisplayName",
		isEnabled: false
	},
	{
		name: "enableAudioAlbums",
		isEnabled: false
	},
	{
		name: "getSms",
		isEnabled: false
	},
	{
		name: "isEnabledPasswordLoginV2",
		isEnabled: false
	},
	{
		name: "enableAutoConnectAll",
		isEnabled: true
	},
	{
		name: "setMonetization",
		isEnabled: true
	},
	{
		name: "enableDirectMessage",
		isEnabled: true
	},
	{
		name: "enableDisconnect",
		isEnabled: true
	},
	{
		name: "enableConvertPoints",
		isEnabled: false
	},
	{
		name: "enableMatchedConnections",
		isEnabled: true
	},
	{
		name: "enableSubGroups",
		isEnabled: false
	},
	{
		name: "enableFiles",
		isEnabled: true
	},
	{
		name: "enableVideos",
		isEnabled: true
	},
	{
		name: "enableCollections",
		isEnabled: false
	},
	{
		name: "enableGroupMedia",
		isEnabled: true
	},
	{
		name: "enableGroupEvents",
		isEnabled: true
	},
	{
		name: "enableLiveTab",
		isEnabled: true
	},
	{
		name: "enablePricing",
		isEnabled: false
	},
	{
		name: "enableTour",
		isEnabled: true
	},
	{
		name: "recoveryEmailRequired",
		isEnabled: false
	},
	{
		name: "markEmailVerifiedByDefault",
		isEnabled: false
	},
	{
		name: "disableProfileFields",
		isEnabled: false
	},
	{
		name: "disableProfileNameUpdate",
		isEnabled: false
	},
	{
		name: "hideBuyCoinButton",
		isEnabled: true
	},
	{
		name: "enableVolunteers",
		isEnabled: true
	},
	{
		name: "enableEventsTab",
		isEnabled: true
	},
	{
		name: "enableChatHdrBtn",
		isEnabled: false
	},
	{
		name: "shopifyStoreEnabled",
		isEnabled: false
	}
];
