import React from "react";

import { ContentWrapper } from "../../../../Community/View/Components";

const LandingPage: React.FC = () => (
	<ContentWrapper customStyle={"margin-top: 96px;"}>
		<p>TEMP LANDING PAGE</p>
	</ContentWrapper>
);

export default LandingPage;
