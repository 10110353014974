import React, { FC, useEffect, useMemo } from "react";

import { Box } from "@material-ui/core";
import { Controller, useForm } from "react-hook-form";

import { SuggestionTypes } from "shared/contexts/UserContext/UserContext";
import { months } from "shared/data/months";
import { useNotification, usePersona, useUser } from "shared/hooks";
import { ProfileType } from "shared/types";
import { CertificationType } from "shared/types/UserModel";
import { getYearsList } from "utils/getYearsList";

import { EnableFeature, MemberDialog, SelectInput } from "../../shared";
import LabeledInput from "../../shared/LabeledInput";
import { InputWrapper } from "../../shared/style";
import { EditProfileWrapper } from "../EditProfileDialog/style";

const NewLicenseDialog: FC<{ item?: CertificationType | undefined }> = ({ item }) => {
	const {
		control,
		watch,
		formState: { isDirty, errors, isSubmitting },
		handleSubmit,
		setValue,
		getValues,
		setError
	} = useForm({ mode: "onChange" });

	const { searchSuggestions, setNewLicenseDialog, setMyExperienceDialog, getData: getUserData } = useUser();
	const { licenseSuggestions, newLicenseDialog, licenseOrganizationSuggestions } = getUserData();

	const { updatePersona, getData: getPersonaData } = usePersona();
	const { persona: profile } = getPersonaData();

	const { showMessage } = useNotification();
	const { issueat, expireAt, noExpiration } = watch(["issueat", "expireAt", "noExpiration"]);

	useEffect(() => {
		if (issueat && issueat > expireAt) {
			setValue("expireAt", issueat);
		}
	}, [expireAt, setValue, issueat]);

	useEffect(() => {
		item && setValue("noExpiration", true);
	}, [item, setValue]);

	const handleDeleteLicense = () => {
		updatePersona(
			{
				...profile,
				licensesAndCertifications: profile?.licensesAndCertifications?.filter(
					l =>
						l.credentialId !== item?.credentialId &&
						l.credentialUrl !== item?.credentialUrl &&
						l.issueAt !== item?.issueAt
				)
			},
			true
		);

		setNewLicenseDialog();
		setMyExperienceDialog(true);
	};

	const onSubmit = async data => {
		const startAt = new Date().setFullYear(parseInt(data.startYear.value), parseInt(data.startMonth.value));
		const endAt = data.endYear
			? new Date().setFullYear(parseInt(data.endYear.value), parseInt(data.endMonth.value))
			: null;

		if (endAt && startAt > endAt) {
			setError("endYear", {
				message: "End date can't be before start date"
			});
			return;
		}

		const exp = {
			credentialId: data.credentialId,
			credentialUrl: data.credentialUrl,
			expire: noExpiration,
			expireAt: noExpiration ? null : endAt,
			license: {
				_id: data.license.value,
				label: data.license.label
			},
			organization: {
				_id: data.organization.value,
				label: data.organization.label
			},
			state: ""
		};

		if (!profile) return;

		if (item) {
			const newPersona: ProfileType = {
				...profile,
				licensesAndCertifications: profile.licensesAndCertifications
					? profile.licensesAndCertifications.map(job =>
							job.credentialId === item.credentialId && job.organization._id === item.organization._id ? exp : job
					  )
					: [exp]
			};
			await updatePersona(newPersona, true);
		} else {
			const newPersona: ProfileType = {
				...profile,
				licensesAndCertifications: profile.licensesAndCertifications
					? [...profile.licensesAndCertifications, exp]
					: [exp]
			};
			await updatePersona(newPersona, true);
		}
		showMessage("Successfully updated your volunteer experience");
		setNewLicenseDialog();
		setMyExperienceDialog(true);
	};

	const years = useMemo(() => getYearsList().map(year => ({ label: year.toString(), value: year.toString() })), []);
	if (!profile) return null;

	return (
		<MemberDialog
			title="License & certifications"
			open={newLicenseDialog?.open || false}
			onClose={() => setNewLicenseDialog()}
			confirmLeave={isDirty}
			goBack={() => {
				setNewLicenseDialog();
				setMyExperienceDialog(true);
			}}
			customWidth={512}
			footerPrimary={{
				text: "Save changes",
				disabled: !isDirty || isSubmitting,
				loading: isSubmitting,
				onClick: handleSubmit(onSubmit)
			}}
			footerSecondary={item ? { text: "Delete", onClick: handleDeleteLicense } : undefined}
		>
			<form onSubmit={handleSubmit(onSubmit)}>
				<EditProfileWrapper>
					<Controller
						name="license"
						control={control}
						defaultValue={item && { value: item.license._id, label: item.license.label }}
						rules={{
							required: "License is required",
							validate: val => {
								if (!val.value || !val.label) {
									return "Please choose a license from the options";
								}
							}
						}}
						render={({ onChange, value, ref }) => (
							<InputWrapper>
								<SelectInput
									name="license"
									onChange={onChange}
									value={value ? value.label : null}
									placeholder=" "
									label="License name *"
									loadSuggestions={val => searchSuggestions(SuggestionTypes.licenseName, val)}
									maxHeight={260}
									error={errors?.license?.message}
									noOptionsHeadline="Type your license name ( ex: Yale )"
									options={
										licenseSuggestions
											? licenseSuggestions.map(suggestion => ({
													value: suggestion._id,
													label: suggestion.label
											  }))
											: []
									}
									selectInputRef={ref}
								/>
							</InputWrapper>
						)}
					/>
					<Controller
						name="organization"
						control={control}
						defaultValue={item && { value: item.organization._id, label: item.organization.label }}
						rules={{
							required: "Organization is required",
							validate: val => {
								if (!val.value || !val.label) {
									return "Please choose a organization from the options";
								}
							}
						}}
						render={({ onChange, value, ref }) => (
							<InputWrapper>
								<SelectInput
									name="organization"
									onChange={onChange}
									value={value ? value.label : null}
									placeholder=" "
									label="Issuing Organization *"
									loadSuggestions={val => searchSuggestions(SuggestionTypes.licenseOrganization, val)}
									maxHeight={260}
									error={errors?.organization?.message}
									noOptionsHeadline="Type your organization ( ex: NCSBN )"
									options={
										licenseOrganizationSuggestions
											? licenseOrganizationSuggestions.map(suggestion => ({
													value: suggestion._id,
													label: suggestion.label
											  }))
											: []
									}
									selectInputRef={ref}
								/>
							</InputWrapper>
						)}
					/>
					<Controller
						name="credentialId"
						control={control}
						defaultValue={item && item.credentialId}
						render={({ onChange, value, ref }) => (
							<InputWrapper>
								<LabeledInput
									name="credentialId"
									onChange={onChange}
									value={value ? value.label : null}
									placeholder=" "
									label="Credential ID"
									error={errors?.credentialId?.message}
									inputRef={ref}
								/>
							</InputWrapper>
						)}
					/>
					<Controller
						name="credentialUrl"
						control={control}
						defaultValue={item && item.credentialUrl}
						render={({ onChange, value, ref }) => (
							<InputWrapper>
								<LabeledInput
									type="url"
									name="credentialUrl"
									onChange={onChange}
									value={value ? value.label : null}
									placeholder=" "
									label="Credential URL"
									error={errors?.credentialUrl?.message}
									inputRef={ref}
								/>
							</InputWrapper>
						)}
					/>
					<Box className="two-inputs-row flex-end">
						<Controller
							name="startYear"
							control={control}
							rules={{
								required: "Start Year is required"
							}}
							defaultValue={years.find(y => y.value === `${new Date().getFullYear()}`)}
							render={({ onChange, value, ref }) => (
								<InputWrapper width="49%">
									<SelectInput
										label="Issue date *"
										name="date"
										value={value && value.label}
										onChange={onChange}
										error={errors?.startYear?.message}
										placeholder="Year"
										options={years}
										maxHeight={180}
										selectInputRef={ref}
									/>
								</InputWrapper>
							)}
						/>
						<Controller
							name="startMonth"
							control={control}
							rules={{
								required: "Start month is required"
							}}
							defaultValue={months[0]}
							render={({ onChange, value, ref }) => (
								<InputWrapper width="49%">
									<SelectInput
										label=" "
										name="date"
										value={value && value.label}
										onChange={onChange}
										error={errors?.startAt?.message}
										placeholder="Month"
										options={months}
										maxHeight={180}
										selectInputRef={ref}
									/>
								</InputWrapper>
							)}
						/>
					</Box>
					{!noExpiration && (
						<Box className="two-inputs-row flex-end">
							<Controller
								name="endYear"
								control={control}
								rules={{
									required: "End Year is required",
									validate: ({ value }) => {
										const startVal = parseInt(getValues("startYear").value);
										if (startVal > parseInt(value)) return "End date can't be before start date";
										return undefined;
									}
								}}
								defaultValue={years.find(y => y.value === `${new Date().getFullYear()}`)}
								render={({ onChange, value, ref }) => (
									<InputWrapper width="49%">
										<SelectInput
											label="Expire date *"
											name="date"
											value={value && value.label}
											onChange={onChange}
											error={errors?.endYear?.message}
											placeholder="Year"
											options={years}
											maxHeight={180}
											selectInputRef={ref}
										/>
									</InputWrapper>
								)}
							/>
							<Controller
								name="endMonth"
								control={control}
								rules={{
									required: "End month is required"
								}}
								defaultValue={months[0]}
								render={({ onChange, value, ref }) => (
									<InputWrapper width="49%">
										<SelectInput
											label=" "
											name="date"
											value={value && value.label}
											onChange={onChange}
											error={errors?.startAt?.message}
											placeholder="Month"
											options={months}
											maxHeight={180}
											selectInputRef={ref}
										/>
									</InputWrapper>
								)}
							/>
						</Box>
					)}
					<Controller
						name="noExpiration"
						control={control}
						render={({ onChange, value }) => (
							<InputWrapper>
								<EnableFeature
									title="No expiration"
									description="This credential does not expire"
									onChange={onChange}
									value={value}
									verticalPadding
								/>
							</InputWrapper>
						)}
					/>
				</EditProfileWrapper>
			</form>
		</MemberDialog>
	);
};

export default NewLicenseDialog;
