import React, { memo, useEffect } from "react";

import { EventType } from "types";

import { useCommunity, useEvent } from "shared/hooks";

import { ItemWrapper, ListWrapper } from "./style";

import { BoxItem, EventCard, SeeAllBtn, Slider } from "../index";

const defaultFilters = {
	page: 1,
	limit: 10,
	keyword: "",
	eventSchedule: EventType.Happening
};

interface EventsProps {
	noContainer?: boolean;
	routes: any;
}

const Events: React.FC<EventsProps> = memo(({ noContainer, routes }) => {
	const { getData: getCommunityData } = useCommunity();
	const { workspace } = getCommunityData();

	const { getPaginatedEvents, getData: getEventData } = useEvent();
	const { eventCount, filteredEvents, loading } = getEventData();

	useEffect(() => {
		getPaginatedEvents({ ...defaultFilters });
	}, [getPaginatedEvents, workspace]);

	if (!eventCount) return null;

	if (noContainer) {
		return (
			<Slider slickPadding count={(filteredEvents || []).length} itemSize={360}>
				{(filteredEvents || []).map((event, index) => (
					<ItemWrapper key={index}>
						<EventCard event={event} loading={loading} />
					</ItemWrapper>
				))}
			</Slider>
		);
	}

	const eventsPageUrl = routes?.member.events.getPath();

	return (
		<BoxItem title={"Popular events"} customPadding="12px 16px 0">
			<ListWrapper customPadding="0 0 12px 16px">
				<Slider slickPadding count={(filteredEvents || []).length} itemSize={360}>
					{(filteredEvents || []).map((event, index) => (
						<ItemWrapper key={index}>
							<EventCard event={event} loading={loading} />
						</ItemWrapper>
					))}
				</Slider>
			</ListWrapper>
			{eventCount > 10 && eventsPageUrl && <SeeAllBtn link={eventsPageUrl} />}
		</BoxItem>
	);
});

export default Events;
