import React, { useCallback, useEffect, useState } from "react";

import { useMediaQuery } from "@material-ui/core";

import { useConnection, useUser } from "shared/hooks";

import { PersonStoryModel, ProfileType } from "shared/types";

import * as appTheme from "theme/default";

import { ConnectionBlockWrapper, ManInLotus, MessageText, NoConnectionsBlock } from "./style";

import { Delimiter, ModelBlock, PageWrapper } from "../../Components";
import { ConnectionAction } from "../../Components/Connections/ConnectionCard";
import { ModelBlockType } from "../../Components/ModelBlock";

const defaultFilters = {
	limit: 10
};

export interface ConnectionsPageProps {}

const ConnectionsPage: React.FC<ConnectionsPageProps> = () => {
	const {
		getUsersConnections,
		getPendingConnections,
		getRecommendedConnections,
		manageConnection,
		requestConnection,
		deleteUserConnection
	} = useConnection();
	const { getPotentialMatchIds, getData: getUserData } = useUser();
	const { user } = getUserData();

	const isMobile = useMediaQuery(appTheme.default.breakpoints.down("sm"));

	const [myConnections, setMyConnections] = useState<ProfileType[]>([]);
	const [myConnectionsCount, setMyConnectionsCount] = useState<number>();
	const [loadingMyConnections, setLoadingMyConnections] = useState(true);

	const [recommendedConnections, setRecommendedConnections] = useState<PersonStoryModel[]>([]);
	const [recommendedConnectionsCount, setRecommendedConnectionsCount] = useState<number>();
	const [loadingRecommendedConnections, setLoadingRecommendedConnections] = useState(true);

	const [connectionRequests, setConnectionRequests] = useState<ProfileType[]>([]);
	const [connectionRequestsCount, setConnectionRequestsCount] = useState<number>();
	const [loadingRequestConnections, setLoadingRequestConnections] = useState(true);

	const [potentialMatchingIds, setPotentialMatchingIds] = useState<string[]>([]);

	const fetchConnections = useCallback(
		async ({
			isUserConnections = false,
			isPending = false,
			isRecommended = false,
			page = 1,
			matchingIds = []
		}: {
			isUserConnections?: boolean;
			isPending?: boolean;
			isRecommended?: boolean;
			page?: number;
			matchingIds?: string[];
		}) => {
			if (isUserConnections) {
				setLoadingMyConnections(true);
				const data = await getUsersConnections({ ...defaultFilters, offset: page });
				setMyConnections(connections => (page === 1 ? data.connections : connections.concat(data.connections)));
				setMyConnectionsCount(data.total);
				setLoadingMyConnections(false);
			}
			if (isRecommended) {
				setLoadingRecommendedConnections(true);
				const data = await getRecommendedConnections({
					...defaultFilters,
					offset: page,
					potentialMatchingIds: matchingIds
				});
				setRecommendedConnections(connections => (page === 1 ? data.stories : connections.concat(data.stories)));
				setRecommendedConnectionsCount(data.total);
				setLoadingRecommendedConnections(false);
			}
			if (isPending) {
				setLoadingRequestConnections(true);
				const data = await getPendingConnections({ ...defaultFilters, offset: page });
				setConnectionRequests(connections => (page === 1 ? data.connections : connections.concat(data.connections)));
				setConnectionRequestsCount(data.total);
				setLoadingRequestConnections(false);
			}
		},
		[getUsersConnections, getPendingConnections, getRecommendedConnections]
	);

	useEffect(() => {
		const matchingIds = getPotentialMatchIds(user);
		setPotentialMatchingIds(matchingIds);
		fetchConnections({ isUserConnections: true });
		fetchConnections({ isPending: true });
		fetchConnections({ isRecommended: true, matchingIds });
	}, [fetchConnections, getPotentialMatchIds, user]);

	const handleManage = useCallback(
		({ connection, action }: { connection: ProfileType; action: ConnectionAction }) => {
			if (connection) {
				switch (action) {
					case ConnectionAction.accept:
						manageConnection({ connectionId: `${connection?.connection?._id}`, isAccept: true });
						break;

					case ConnectionAction.decline:
						manageConnection({ connectionId: `${connection?.connection?._id}`, isAccept: false });
						break;

					case ConnectionAction.remove:
						connection.connection && deleteUserConnection(connection.connection._id);
						break;

					case ConnectionAction.requestConnect:
						requestConnection(connection.personaId);
						break;
				}
			}
		},
		[deleteUserConnection, manageConnection, requestConnection]
	);

	const connectionRequestOnEnd = useCallback(() => {
		() => {
			if (connectionRequestsCount && connectionRequests.length < connectionRequestsCount) {
				fetchConnections({
					isPending: true,
					page: Math.ceil(connectionRequests.length / defaultFilters.limit) + 1
				});
			}
		};
	}, [fetchConnections, connectionRequests.length, connectionRequestsCount]);

	const greatMatchesOnEnd = useCallback(() => {
		() => {
			if (recommendedConnectionsCount && recommendedConnections.length < recommendedConnectionsCount) {
				fetchConnections({
					isRecommended: true,
					matchingIds: potentialMatchingIds,
					page: Math.ceil(recommendedConnections.length / defaultFilters.limit) + 1
				});
			}
		};
	}, [fetchConnections, recommendedConnections.length, recommendedConnectionsCount, potentialMatchingIds]);

	const myConnectionOnEnd = useCallback(() => {
		if (myConnectionsCount && myConnections.length < myConnectionsCount) {
			fetchConnections({
				isUserConnections: true,
				page: Math.ceil(myConnections.length / defaultFilters.limit) + 1
			});
		}
	}, [fetchConnections, myConnections.length, myConnectionsCount]);

	return (
		<PageWrapper>
			<ConnectionBlockWrapper>
				<ModelBlock
					title={"Connection Requests"}
					type={ModelBlockType.connection}
					items={connectionRequests}
					loading={loadingRequestConnections}
					modelType={"request"}
					onManage={handleManage}
					noContent={"You don’t have any pending connection requests."}
					keepCurrentData={
						loadingRequestConnections && Math.ceil(connectionRequests.length / defaultFilters.limit) + 1 > 1
					}
					onEndScroll={connectionRequestOnEnd}
				/>
				{!!connectionRequests.length && <Delimiter />}
			</ConnectionBlockWrapper>
			<ConnectionBlockWrapper>
				<ModelBlock
					title={"Great Matches for You"}
					type={ModelBlockType.connection}
					items={recommendedConnections}
					loading={loadingRecommendedConnections}
					modelType={"suggestion"}
					onManage={handleManage}
					noContent={"You don’t have any suggested matches to connect with."}
					keepCurrentData={
						loadingRecommendedConnections && Math.ceil(recommendedConnections.length / defaultFilters.limit) + 1 > 1
					}
					onEndScroll={greatMatchesOnEnd}
				/>
				{!!recommendedConnections.length && <Delimiter />}
			</ConnectionBlockWrapper>
			<ModelBlock
				title={"My Connections"}
				type={ModelBlockType.connection}
				items={myConnections}
				loading={loadingMyConnections}
				inlineView={!isMobile}
				modelType={"connected"}
				onManage={handleManage}
				noContent={
					<NoConnectionsBlock>
						<ManInLotus />
						<MessageText>You don’t have any connections yet.</MessageText>
						{/*TODO: hide for now*/}
						{/*<StyledButton buttonTheme="light" palette="primary" size="medium" removeSideMargin>*/}
						{/*	+ Invite a Friend*/}
						{/*</StyledButton>*/}
					</NoConnectionsBlock>
				}
				keepCurrentData={loadingMyConnections && !!myConnections.length}
				onEndScroll={myConnectionOnEnd}
			/>
		</PageWrapper>
	);
};

export default ConnectionsPage;
