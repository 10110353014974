import { useCallback, useMemo } from "react";

import useNotification from "shared/hooks/useNotification";
import { actionMethod, actionTypes, getValidationMessage } from "utils/getValidationMessage";

import { useEngageNotificationStore } from "../contexts/EngageNotificationContext";

import { useNotificationApiService } from "../services";
import { EngageNotificationsModel } from "../types";
import { EngageNotificationModel, getEngageNotificationsDataType } from "../types/EngageNotificationModel";

const useEngageNotifications = () => {
	const { showMessage } = useNotification();

	const notificationService = useNotificationApiService();

	const store = useEngageNotificationStore();
	const { setState } = useEngageNotificationStore();

	const methods = useMemo(
		() => ({
			createNotification: async (data: EngageNotificationModel) => {
				try {
					const { success } = await notificationService.createNotification(data);
					if (success) {
						showMessage(
							getValidationMessage({
								name: "Notification",
								actionType: actionTypes.CRUD,
								actionMethod: actionMethod.created,
								emoji: "🎉"
							}),
							3
						);
					}
					return true;
				} catch (err) {
					console.error(err);
					return false;
				}
			},

			updateNotification: async (id: string, data: EngageNotificationModel) => {
				try {
					const { success } = await notificationService.updateNotification(id, data);
					if (success) {
						showMessage(
							getValidationMessage({
								name: "Notification",
								actionType: actionTypes.CRUD,
								actionMethod: actionMethod.updated,
								emoji: "🎉"
							}),
							3
						);
					}
					return true;
				} catch (err) {
					console.error(err);
					return false;
				}
			},

			getNotifications: async (data: getEngageNotificationsDataType) => {
				try {
					setState(ctx => ({ ...ctx, loadingNotifications: true }));
					const notifications = await notificationService.getNotifications(data);
					setState(ctx => ({
						...ctx,
						notifications,
						keyword: data.keyword,
						loadingNotifications: false
					}));
				} catch (error) {
					showMessage((error as Error).message);
				}
			},

			setDeleteDialog: ({ data }: { data?: EngageNotificationsModel }) => {
				setState(ctx => ({ ...ctx, deleteNotificationDialog: data }));
			},

			async deleteNotification(id: string) {
				const data = await notificationService.deleteNotification(id);
				if (data) {
					setState(ctx => ({
						...ctx,
						deleteNotificationDialog: undefined,
						notifications: ctx.notifications?.filter(notif => notif._id !== id),
						totalCount: ctx.totalCount - 1
					}));
					showMessage(
						getValidationMessage({
							name: "Notification",
							actionType: actionTypes.CRUD,
							actionMethod: actionMethod.deleted,
							emoji: "🗑"
						}),
						3
					);
				}
			},

			getNotificationsTotalCount: async data => {
				try {
					const { totalCount } = await notificationService.getTotalNotificationsCount(data);
					setState(ctx => ({ ...ctx, totalCount }));
				} catch (error) {
					showMessage((error as Error).message);
				}
			},

			setPage: async (page: number) => {
				setState(ctx => ({ ...ctx, page }));
			},

			setNotificationShowPerPage: async (notificationShowPerPage: number) => {
				setState(ctx => ({ ...ctx, notificationShowPerPage }));
			}
		}),
		[notificationService, setState, showMessage]
	);

	const getData = useCallback(() => {
		return store;
	}, [store]);

	return { ...methods, getData };
};

export default useEngageNotifications;
