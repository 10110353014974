import React, { FC, useEffect, useState } from "react";

import Popover from "@material-ui/core/Popover";
import DateRangePicker from "dz-daterangepicker-material";
import { DateTime } from "luxon";

import { Button, Text } from "shared/ui-kit";

import { DateRangePickerWrapper } from "./style";

export interface DateRangeModel {
	startDate?: DateTime;
	endDate?: DateTime;
}

interface DateRangeSelectorProps {
	id?: string;
	open: boolean;
	anchorEl?: HTMLElement | null;
	onClose: () => void;
	onChange: (data?: DateRangeModel) => void;
	minDate?: Date;
	maxDate?: Date;
}

const DateRangeSelector: FC<DateRangeSelectorProps> = ({
	id = "analytics-date-range-id",
	open,
	anchorEl,
	onClose,
	onChange,
	minDate,
	maxDate
}) => {
	const [tempDateRange, setTempDateRange] = useState<DateRangeModel>();

	useEffect(() => {
		if (open) {
			setTempDateRange(undefined);
		}
	}, [open]);

	return (
		<Popover
			id={id}
			open={open}
			anchorEl={anchorEl}
			onClose={onClose}
			anchorOrigin={{
				vertical: "top",
				horizontal: "right"
			}}
			transformOrigin={{
				vertical: "top",
				horizontal: "right"
			}}
		>
			<DateRangePickerWrapper>
				<div className="py-3 px-6 border-b-1 border-gray-400">
					<Text variant="p" className="text-lg font-bold">
						Custom
					</Text>
					<p className="flex items-center gap-2 text-[13px] font-semibold">
						<Text variant="span" className="">
							{tempDateRange?.startDate ? tempDateRange?.startDate.toFormat("LLL d, y") : "From"}
						</Text>
						-
						<Text variant="span" className="">
							{tempDateRange?.endDate ? tempDateRange?.endDate.toFormat("LLL d, y") : "To"}
						</Text>
					</p>
				</div>
				<DateRangePicker
					onChange={(startDate, endDate) => {
						setTempDateRange({
							startDate: DateTime.fromJSDate(startDate),
							endDate: DateTime.fromJSDate(endDate)
						});
					}}
					startWeek={"monday"}
					datePicker={false}
					locale="us-en"
					minDate={minDate}
					maxDate={maxDate}
				/>
				<div className="flex items-center justify-end py-4 px-6 gap-6 border-t-1 border-gray-400">
					<Button
						buttonTheme="ghost"
						palette="basic"
						size="tiny"
						onClick={() => onChange(undefined)}
						className={"capitalize"}
						removeSideMargin
					>
						Cancel
					</Button>
					<Button
						buttonTheme="main"
						palette="primary"
						size="tiny"
						onClick={() => onChange(tempDateRange)}
						className={"capitalize"}
						removeSideMargin
					>
						Done
					</Button>
				</div>
			</DateRangePickerWrapper>
		</Popover>
	);
};

export default DateRangeSelector;
