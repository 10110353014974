import { ButtonGroup } from "@material-ui/core";
import styled, { css } from "styled-components";

import { Box, Button, InfoCard, Input, Tabs, Text } from "shared/ui-kit";

import { customBreakpoints } from "theme/default";

export const Container = styled(Box)`
	background-color: white;
	width: 100%;
	position: relative;

	${props => props.theme.breakpoints.up(customBreakpoints.laptopLgScreen)} {
		padding: 0 20px;
	}
`;

export const Heading = styled(Text)`
	color: #222b45;
	font-weight: bold;
	font-size: 32px;
	display: flex;
`;

export const TabContainer = styled(Tabs)<{ isNotice: boolean }>`
	font-size: 15px;
	font-weight: 600;
	line-height: 1.6;
	letter-spacing: normal;
	color: #6173fe;
	border-bottom: 1px solid #e4e9f2;
	margin-bottom: ${({ isNotice }) => (isNotice ? "24px" : "80px")};

	.MuiTab-wrapper {
		transform: translate(-13px, 0px);
	}
`;

export const Bar = styled(Box)`
	width: 4px;
	background-color: #6173fe;
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;

	transform: rotate(270deg);
	height: 154px;
	position: absolute;
	top: 91px;
	left: 192px;

	${props => props.theme.breakpoints.down("sm")} {
		height: 120px;
		top: 117px;
		left: 69px;
	}
`;

export const StyledMonetizationContainer = styled.form`
	${props => props.theme.breakpoints.down("sm")} {
		padding: 0 8px;
	}

	${props => props.theme.breakpoints.up(customBreakpoints.iPadPro)} {
		display: flex;
		justify-content: space-between;
	}
`;

export const FlexContainer = styled(Box)<{ gap?: boolean }>`
	display: flex;
	margin-bottom: 32px;

	${props => props.theme.breakpoints.down("sm")} {
		flex-direction: column;
	}

	${({ gap }) =>
		gap &&
		css`
			gap: 8px;
			margin-bottom: 20px;
		`};
`;

export const FieldsContainer = styled(Box)`
	width: 53%;

	${props => props.theme.breakpoints.down("sm")} {
		width: 100%;
	}
`;

export const Calculation = styled(Box)``;

Calculation.Container = styled(Box)`
	width: 100%;

	${props => props.theme.breakpoints.up(customBreakpoints.iPadPro)} {
		padding-left: 10px;
		width: 45%;
	}
`;

Calculation.Header = styled(Box)`
	display: flex;
	align-items: center;
	justify-content: space-between;

	width: 100%;
`;

Calculation.Body = styled(Box)`
	margin-top: 8px;

	width: 100%;
`;

export const StyledSubFormContainer = styled(Box)`
	width: 100%;
	display: flex;
	flex-direction: column;

	${props => props.theme.breakpoints.up(customBreakpoints.iPadPro)} {
		width: 55%;
		padding-right: 10px;
	}
`;

export const SubField = styled(Box)``;

SubField.Container = styled(Box)`
	margin-bottom: 32px;

	display: flex;
	align-items: center;
	justify-content: space-between;
`;

SubField.ItemWrapper = styled(Box)`
	width: 100%;

	display: flex;
	align-items: center;
	justify-content: flex-start;

	&.right {
		justify-content: flex-end;
	}

	&.between {
		justify-content: space-between;
	}

	&.w-50 {
		width: 50%;
	}

	&.w-43 {
		width: 43%;
	}

	&.w-33 {
		width: 33%;
	}

	&.w-58 {
		width: 58%;
	}

	&.w-20 {
		width: 20%;
	}

	&.w-35 {
		width: 35%;
	}

	&.w-40 {
		width: 40%;
	}

	&.w-60 {
		width: 60%;
	}

	&.w-75 {
		width: 75%;
	}

	&.half {
		width: 20%;
	}

	.Mui-disabled {
		fieldset {
			background-color: #f6f7f9;
		}
	}
`;

SubField.Label = styled(Box)`
	font-size: 15px;
	padding: 8px 0;
	font-weight: 600;
	line-height: 1.6;
	color: #222b45;

	${props => props.theme.breakpoints.down("sm")} {
		width: 100%;
		text-align: left;
		padding-bottom: 0;
	}
`;

SubField.Input = styled(Input)`
	margin: 0;

	width: 100%;

	.MuiFormHelperText-root {
		position: absolute;
		top: 100%;
	}
`;

export const DescriptionLabel = styled(Text)`
	margin-right: 9px;

	${props => props.theme.breakpoints.down("sm")} {
		margin: 0;
	}
`;

export const NameLabel = styled(Text)`
	margin-left: 22px;

	${props => props.theme.breakpoints.down("sm")} {
		margin: 0;
	}
`;

export const CalculationLabel = styled(Text)`
	padding: 0;
	margin-bottom: 16px;
`;

export const BlockContainer = styled(Box)`
	border-radius: 4px;

	width: 100%;

	box-shadow: inset 0 0 0 0.5px #c5cee0;
`;

export const Block = styled(Box)`
	color: #222b45;
	margin: 0 32px;
	padding: 27px 0;
	font-size: 15px;
	display: flex;
	border-bottom: solid 0.5px #c5cee0;
`;

export const BlockNoBorder = styled(Block)`
	border-bottom: none;
`;

export const TotalBlock = styled(BlockNoBorder)`
	background-color: #f6f7f9;
	margin: 0;
	padding: 27px 32px;
	border-top: solid 0.5px #c5cee0;
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
`;

export const Title = styled(Box)`
	width: 60%;
`;

export const Value = styled(Box)`
	margin-left: auto;
	margin-right: 4px;
`;

export const Optional = styled(Text)`
	color: #8f9bb3;
	font-size: 13px;
`;

export const StyledInfoCard = styled(InfoCard)`
	margin-bottom: 24px !important;
`;

export const FreeTrialDiv = styled(Box)`
	display: flex;
`;

export const RightButtonGroup = styled(ButtonGroup)`
	display: flex;
	flex-direction: row;
	justify-content: flex-end;

	border-radius: 4px;

	box-shadow: inset 0 0 0 0.5px #c5cee0;

	background-color: #f7f9fc;

	height: 100%;

	padding: 2px;
`;

export const FilterButton = styled(Button)<{ active?: boolean }>`
	font-size: 13px;
	font-weight: normal;
	line-height: 1.23;
	text-align: center;
	color: #222b45;

	padding: 6px;
	margin: 0;

	min-width: 74px;

	border-radius: 3px;

	background-color: transparent;
	border: none;

	&:not(:first-child) {
		border-top-left-radius: 3px;
		border-bottom-left-radius: 3px;
	}

	&:not(:last-child) {
		border-top-right-radius: 3px;
		border-bottom-right-radius: 3px;
	}

	&:hover {
		background-color: inherit;
		color: inherit;
	}

	&.active {
		background-color: white;

		box-shadow: inset 0 0 0 0.5px rgba(198, 207, 224, 0.4), 0 2px 4px 0 rgba(0, 0, 0, 0.08);
	}
`;
