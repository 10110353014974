import { useMemo } from "react";

import { useStreamApiService } from "../services";
import { StreamEntityType } from "../types";

const useStream = () => {
	const service = useStreamApiService();

	const methods = useMemo(
		() => ({
			async startStream({ entityId, type = StreamEntityType.MUSIC }: { entityId: string; type?: StreamEntityType }) {
				await service.startStream({ entityId, type });
			},
			async pauseStream({
				entityId,
				current,
				type = StreamEntityType.MUSIC
			}: {
				entityId: string;
				current: number;
				type?: StreamEntityType;
			}) {
				await service.pauseStream({ entityId, current, type });
			},
			readFile(url: string) {
				return service.readFile(url);
			}
		}),
		[service]
	);

	return useMemo(
		() => ({
			...methods
		}),
		[methods]
	);
};

export default useStream;
