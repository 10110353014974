export const API_URLS = {
	TRANSACTIONS: {
		GET_LIST: "/purchase/payments",
		GET_COUNT: "/purchase/payments/count"
	},
	REPORTS: {
		GET_LIST: "/admin/workspace/funds",
		GET_COUNT: "/admin/workspace/funds/count"
	}
};

export const stripeSupportCountries = [
	"Australia",
	"Austria",
	"Belgium",
	"Brazil",
	"Bulgaria",
	"Canada",
	"Cyprus",
	"Czech Republic",
	"Denmark",
	"Estonia",
	"Finland",
	"France",
	"Germany",
	"Greece",
	"Hong Kong",
	"Hungary",
	"India PREVIEW",
	"Ireland",
	"Italy",
	"Japan",
	"Latvia",
	"Lithuania",
	"Luxembourg",
	"Malaysia",
	"Malta",
	"Mexico",
	"Netherlands",
	"New Zealand",
	"Norway",
	"Poland",
	"Portugal",
	"Romania",
	"Singapore",
	"Slovakia",
	"Slovenia",
	"Spain",
	"Sweden",
	"Switzerland",
	"United Arab Emirates",
	"United Kingdom",
	"United States"
];
