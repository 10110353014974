const warning = {
	100: "#fff9d9",
	200: "#fff2b4",
	300: "#ffe88e",
	400: "#ffdf72",
	500: "#ffd044",
	600: "#dbac31",
	700: "#b78a22",
	800: "#936a15",
	900: "#7a530d"
};

export default warning;
