import { AxiosResponse } from "axios";

import BaseConverter from "shared/services/converters/baseConverter";

import { TransactionModel, TransactionModelResponse } from "shared/types";

export const convertTransactions = (data: AxiosResponse<TransactionModelResponse>): TransactionModel[] =>
	BaseConverter.convert<TransactionModelResponse>(data).purchases;

export const convertTransactionsCount = (data: AxiosResponse<{ totalCount: number }>): number =>
	BaseConverter.convert<{ totalCount: number }>(data).totalCount;
