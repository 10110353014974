import { RefObject } from "react";

// USED TO UPDATE TEXTAREA HEIGHT UNTIL A CERTAIN ROW COUNT IS REACHED
export const updateTextareaEl = (textareaRef: RefObject<HTMLTextAreaElement>, maxRows: number) => {
	if (!textareaRef.current) return;
	const previous_height = textareaRef.current.style.height;
	textareaRef.current.style.height = "0";
	const rows = Math.min(
		textareaRef.current.scrollHeight / parseInt(getComputedStyle(textareaRef.current).lineHeight),
		maxRows
	);
	textareaRef.current.style.height = previous_height;
	textareaRef.current.setAttribute("rows", rows.toString());
};
