import { useCallback, useMemo } from "react";

import useNotification from "shared/hooks/useNotification";
import useUser from "shared/hooks/useUser";

import { useStripeApiService } from "shared/services";
import { Card } from "shared/types/CardsType";
import { formatCount } from "utils/serviceUtils/helpers";

import { useStripeStore } from "../contexts";

import { PremiumObjectType, UnlockContentType, WorkspaceProductModel } from "../types";

const useStripe = () => {
	const apiService = useStripeApiService();
	const { showMessage } = useNotification();

	const { setUser, getData: getUserData } = useUser();
	const { user } = getUserData();

	const store = useStripeStore();
	const { setState } = useStripeStore();

	const methods = useMemo(
		() => ({
			getCards: async () => {
				setState(ctx => ({ ...ctx, loadingCards: true }));

				try {
					const { data } = await apiService.getCards();

					setState(state => ({ ...state, cards: !!data.length ? data : [] }));
				} catch (e) {
					console.log("Can not get cards: ", (e as Error).message);
					setState(state => ({ ...state, cards: [] }));
				}

				setState(ctx => ({ ...ctx, loadingCards: false }));
			},
			newCard: async (data, cb?: () => void) => {
				setState(ctx => ({ ...ctx, submitting: true }));
				try {
					const { id } = await apiService.newCardToken(data);
					const card = await apiService.registerCard(id);
					setState(ctx => ({
						...ctx,
						cards: ctx.cards.some(c => c.id === card.id) ? ctx.cards : [card, ...ctx.cards]
					}));
					showMessage("Card added successfully");
					cb && cb();
				} catch {
					showMessage("Failed to validate your card.", 5, true);
				}
				setState(ctx => ({ ...ctx, submitting: false }));
			},
			updateCard: async (id: string, data, cb?: () => void) => {
				setState(ctx => ({ ...ctx, submitting: true }));
				try {
					await apiService.updateCard(id, data);
					setState(ctx => ({
						...ctx,
						cards: ctx.cards.map(c => (c.id === id ? { ...c, ...data } : c))
					}));
					showMessage("Card updated successfully");
					cb && cb();
				} catch {
					showMessage("Failed to update your card.", 5, true);
				}
				setState(ctx => ({ ...ctx, submitting: false }));
			},
			deleteCard: async (id: string) => {
				setState(ctx => ({ ...ctx, submitting: true }));
				try {
					await apiService.deleteCard(id);
					setState(ctx => ({
						...ctx,
						cards: ctx.cards.filter(card => card.id !== id)
					}));
					showMessage("Your card was successfully deleted");
				} catch (error) {
					showMessage("Unfortunately, it was not possible to delete this card.", 5, true);
				}
				setState(ctx => ({ ...ctx, submitting: false }));
			},
			chargeCard: async payload => {
				setState(ctx => ({ ...ctx, submitting: true }));
				return await apiService.chargeCard(payload);
			},
			buyCoins: async ({
				chargeCardPayload,
				buyCoinsPayload,
				cb
			}: {
				chargeCardPayload: {
					amount: number;
					currency: string;
					description: string;
					source: string | undefined;
				};
				buyCoinsPayload: {
					purchaseDate: Date;
					productId: string;
					amountPaid: number;
					receipt: {
						refId: string;
						type: string;
						coins: number;
						transactionReceipt?;
					};
				};
				cb?: () => void;
			}) => {
				setState(ctx => ({ ...ctx, submitting: true }));

				try {
					const transactionReceipt = await methods.chargeCard(chargeCardPayload);

					if (transactionReceipt) {
						buyCoinsPayload.receipt.transactionReceipt = transactionReceipt;
						await apiService.buyCoins(buyCoinsPayload);

						showMessage(`Successfully bought ${formatCount(buyCoinsPayload.receipt.coins, "Coin")}.`);

						if (user) {
							setUser({
								...user,
								purchase: { ...user.purchase, coinBalance: user.purchase.coinBalance + buyCoinsPayload.receipt.coins }
							});
						}

						cb && cb();
						setState(ctx => ({ ...ctx, buyCoinsDialog: { open: false } }));
					}
				} catch {
					showMessage("Failed to buy coins, please try again.", 5, true);
				}

				setState(ctx => ({ ...ctx, submitting: false }));
			},
			newSubscription: async (priceId: string) => {
				setState(ctx => ({ ...ctx, submitting: true }));
				try {
					await apiService.newSubscription(priceId);
					showMessage("You have successfully subscribed.");
				} catch {
					showMessage("Failed to subscribe to the community.", 5, true);
				} finally {
					setState({ submitting: false });
				}
			},
			unlockPremiumObject: async ({
				premiumObject,
				unlockContentType,
				coins,
				productId,
				subscriptionId
			}: {
				premiumObject?: any;
				unlockContentType?: UnlockContentType;
				coins: number;
				productId: string;
				subscriptionId: string;
			}) => {
				const receipt: {
					refId?: string;
					coins?: number;
					type?: string;
				} = {
					coins
				};

				let orderType = "";

				if (unlockContentType === UnlockContentType.POST) {
					receipt.refId = premiumObject?.pid;
					orderType = "premium";
					receipt.type = "post";
				}

				if (unlockContentType === UnlockContentType.VIDEO) {
					receipt.refId = premiumObject?._id;
					receipt.type = orderType = "video";
				}

				if (unlockContentType === UnlockContentType.FILE) {
					receipt.refId = premiumObject?._id;
					receipt.type = orderType = "userfile";
				}

				if (unlockContentType === UnlockContentType.ALBUM) {
					receipt.refId = premiumObject?._id;
					receipt.type = orderType = "album";
				}

				if (unlockContentType === UnlockContentType.TRACK) {
					receipt.refId = premiumObject?._id;
					receipt.type = orderType = "music";
				}

				if (unlockContentType === UnlockContentType.COLLECTION) {
					receipt.refId = premiumObject?._id;
					receipt.type = orderType = "collection";
				}

				if (unlockContentType === UnlockContentType.EVENT) {
					receipt.refId = premiumObject?.eventId;
					receipt.type = orderType = "event";
				}

				if (unlockContentType === UnlockContentType.GROUP) {
					receipt.refId = premiumObject?._id;
					receipt.type = orderType = "group";
				}

				const data = {
					purchaseDate: new Date(),
					productId,
					amountPaid: 0,
					subscriptionId,
					orderType,
					receipt
				};

				const { success } = await apiService.buyCoins(data);

				if (!success) {
					showMessage("Failed to unlock content, please try again.", 5, true);
					return;
				}

				setState(ctx => ({ ...ctx, unlockContentDialog: undefined }));
				showMessage("Content successfully unlocked.");
			},
			setBuyCoinsDialog: (
				buyCoinsDialog: {
					open: boolean;
					id?: string;
				},
				onUnlock?: () => void
			) => {
				setState(ctx => ({ ...ctx, buyCoinsDialog, onUnlock }));
			},
			setSubscribeDialog: (
				data:
					| {
							premiumObject?: PremiumObjectType<any>;
							priceInfo?: WorkspaceProductModel;
							unlockContentType?: UnlockContentType;
							onUnlock?: () => void;
					  }
					| undefined
			) => {
				setState(ctx => ({
					...ctx,
					subscribeDialog: data
						? {
								premiumObject: data.premiumObject,
								priceInfo: data.priceInfo,
								unlockContentType: data.unlockContentType
						  }
						: undefined,
					onUnlock: data?.onUnlock
				}));
			},
			setChosenCard: (chosenCard?: Card) => {
				setState(ctx => ({ ...ctx, chosenCard }));
			},
			setUnlockContentDialog: (
				data:
					| {
							premiumObject?: PremiumObjectType<any>;
							priceInfo?: WorkspaceProductModel;
							unlockContentType?: UnlockContentType;
							teaserContent?: string;
							onUnlock?: () => void;
					  }
					| undefined
			) => {
				setState(ctx => ({
					...ctx,
					unlockContentDialog: data
						? {
								premiumObject: data.premiumObject,
								priceInfo: data.priceInfo,
								unlockContentType: data.unlockContentType,
								teaserContent: data.teaserContent
						  }
						: undefined,
					onUnlock: data?.onUnlock
				}));
			}
		}),
		[apiService, setState, setUser, showMessage, user]
	);

	const getData = useCallback(() => {
		return store;
	}, [store]);

	return { ...methods, getData };
};

export default useStripe;
