import React from "react";

import { Icon } from "shared/ui-kit";

import { DropdownButton, DropdownItem, DropdownItemIcon, DropdownPopover } from "./styles";

export interface DropdownOption {
	name: string;
	icon: React.ReactNode;
	handler: () => void;
}
interface Props {
	options: DropdownOption[];
}

// TODO: replace it with MenuDots component later
function SettingsOptionDropdown(props: Props) {
	const { options } = props;
	const [anchorEl, setAnchorEl] = React.useState(null);

	const closePopup = () => {
		setAnchorEl(null);
	};

	const handleClick = event => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		closePopup();
	};

	const handleOptionClick = (callback: () => void) => {
		closePopup();
		callback();
	};

	const open = Boolean(anchorEl);
	const id = open ? "settings-option-dropdown" : undefined;

	return (
		<>
			<DropdownButton aria-describedby={id} onClick={handleClick}>
				<Icon
					fill="#c5cee0"
					group="filled"
					height={24}
					name="ellipsis"
					width={24}
					style={{ marginLeft: "10px" }}
					aria-controls="long-menu"
					aria-haspopup="true"
					aria-label="more"
				/>
			</DropdownButton>
			<DropdownPopover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right"
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "right"
				}}
			>
				{options.map(option => {
					return (
						<DropdownItem key={option.name} onClick={() => handleOptionClick(option.handler)}>
							<DropdownItemIcon>{option.icon}</DropdownItemIcon>
							<span>{option.name}</span>
						</DropdownItem>
					);
				})}
			</DropdownPopover>
		</>
	);
}

export { SettingsOptionDropdown };
