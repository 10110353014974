import React, { ReactNode, createRef, useEffect, useMemo, useState } from "react";

import styled from "styled-components";

import { ReactComponent as GearIcon } from "assets/icons/gear.svg";

import { WidgetCustomThemeCard } from "shared/Components";

import { groupThemeList } from "shared/constants";
import { useCommunity } from "shared/hooks";

import { ThemePageProps } from "shared/types";
import { Box, ColorPicker, Radio, Text, ThemeCard, positions } from "shared/ui-kit";

import useConfirmLeavePage from "../ConfirmLeave/hooks/useConfirmLeavePage";
import FormBlock from "../FormBlock";

import SubmitButton from "../SubmitButton";

const Wrapper = styled(Box)`
	display: flex;
	flex-direction: row;
	flex: 1;
	margin: 0;
	align-items: flex-end;
	justify-content: space-between;
	${props => props.customStyle || ""};
	${props => props.theme.breakpoints.down("sm")} {
		flex-direction: column;
		width: 100%;
	}
`;

const ThemeSectionWrapper = styled.section`
	margin-top: 15px;
	margin-bottom: 4px;

	${props => props.theme.breakpoints.down("sm")} {
		margin-top: 31px;
	}
`;

const ThemeCardWrapper = styled(Box)<{
	isShort: boolean;
}>`
	display: inline-block;
	margin: ${({ isShort }) => (isShort ? "14px" : 0)} 7px 12px 0;

	cursor: pointer;

	width: 32%;

	${props => props.theme.breakpoints.up("xs")} {
		&:nth-child(3n) {
			margin-right: 0;
		}
	}

	${props => props.theme.breakpoints.down("xs")} {
		width: 49%;
		margin-right: 6px;

		&:nth-child(3n) {
			margin-right: 6px;
		}

		&:nth-child(2n) {
			margin-right: 0;
		}

		& > div {
			width: 100%;
			max-width: 100%;
		}
	}

	${props => props.theme.breakpoints.down("sm")} {
		margin-bottom: 7px;
	}

	.theme-card__body {
		margin-left: ${({ isShort }) => (isShort ? 0 : "auto")};
	}
`;

const CustomThemeColorRow = styled(Box)`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const StyledFormBlock = styled(FormBlock)`
	margin: 0;
	padding: 0;
	overflow: hidden;
	border: 0;
`;

const CommunityTheme: React.FC<ThemePageProps> = () => {
	const { updateWorkspaceMeta, communityColors } = useCommunity();

	const [customPrimaryColor, setCustomPrimaryColor] = React.useState<string>("#f03aaa");
	const [customPrimaryBtnColor, setCustomPrimaryBtnColor] = React.useState<string>("#f03aaa");
	const [activeTheme, setActiveTheme] = useState<number | null>(null);

	useEffect(() => {
		const color = communityColors.primary;
		const btnColor = communityColors.btn;

		if (color) {
			const idx = groupThemeList.findIndex(it => it.primaryColor === color);
			if (idx > -1) {
				setActiveTheme(idx);
			} else {
				setActiveTheme(6);
				setCustomPrimaryColor(color);
				btnColor && setCustomPrimaryBtnColor(btnColor);
			}
		}
	}, [communityColors]);

	const { provideHandleCloseValues } = useConfirmLeavePage({});

	useEffect(() => {
		if (communityColors.primary && communityColors.btn) {
			setCustomPrimaryColor(communityColors.primary);
			setCustomPrimaryBtnColor(communityColors.btn);
			let themeIndex = 6;
			groupThemeList.forEach((group, index) => {
				if (group.primaryColor === communityColors.primary && group.primaryBtnColor === communityColors.btn) {
					themeIndex = index;
				}
			});
			setActiveTheme(themeIndex);
		}
	}, [communityColors]);

	const selectedTheme = useMemo(() => {
		if (activeTheme === null) {
			return {};
		}
		return {
			primaryColor: activeTheme === 6 ? customPrimaryColor : groupThemeList[activeTheme].primaryColor,
			primaryBtnColor: activeTheme === 6 ? customPrimaryBtnColor : groupThemeList[activeTheme].primaryBtnColor
		};
	}, [customPrimaryColor, customPrimaryBtnColor, activeTheme]);

	const isDirty = useMemo(() => {
		return (
			communityColors.primary !== selectedTheme.primaryColor || communityColors.btn !== selectedTheme.primaryBtnColor
		);
	}, [selectedTheme, communityColors]);

	const onSave = async () => {
		if (activeTheme != null) {
			await updateWorkspaceMeta({
				meta: {
					// @ts-expect-error: valid params
					theme: selectedTheme
				}
			});
		}
	};

	const handleChangeActiveTheme = (num: number) => {
		setActiveTheme(num);
		provideHandleCloseValues(true);
	};

	const customThemeHeader: ReactNode = (
		<React.Fragment>
			<Radio
				options={[{ label: "Create a custom theme", value: "custom" }]}
				value={activeTheme === 6 ? "custom" : ""}
				onChange={() => {
					handleChangeActiveTheme(6);
				}}
				register={createRef()}
				id="custom"
			/>
			<GearIcon />
		</React.Fragment>
	);

	return (
		<Wrapper>
			<StyledFormBlock>
				<Text>
					Choose colors that reflect your community; avoid choosing colors with low contrast for better visibility and
					user experience. i.e., Avoid using light button colors with a light primary color.{" "}
				</Text>
				<ThemeSectionWrapper>
					{groupThemeList.map((propTheme, index) => (
						<ThemeCardWrapper
							key={index}
							onClick={() => handleChangeActiveTheme(index)}
							isShort={activeTheme === 6}
							id={propTheme.themeId}
						>
							<ThemeCard {...propTheme} isShort={activeTheme === 6} />
							<Radio
								options={propTheme.options}
								value={activeTheme === index ? propTheme.options[0].value : ""}
								onChange={() => {
									handleChangeActiveTheme(index);
								}}
								register={propTheme.ref}
							/>
						</ThemeCardWrapper>
					))}
				</ThemeSectionWrapper>
				<WidgetCustomThemeCard header={customThemeHeader} showBody={activeTheme === 6}>
					<CustomThemeColorRow>
						<Text variant="body2">Your app primary color</Text>
						<ColorPicker
							color={customPrimaryColor}
							onChange={c => {
								setCustomPrimaryColor(c!.hex);
								provideHandleCloseValues(true);
							}}
							position={positions.top}
							disableAlpha
							expanded={true}
						/>
					</CustomThemeColorRow>
					<CustomThemeColorRow>
						<Text variant="body2">Your primary button color</Text>
						<ColorPicker
							color={customPrimaryBtnColor}
							onChange={c => {
								setCustomPrimaryBtnColor(c!.hex);
								provideHandleCloseValues(true);
							}}
							position={positions.top}
							disableAlpha
							expanded={true}
						/>
					</CustomThemeColorRow>
				</WidgetCustomThemeCard>
			</StyledFormBlock>
			<Wrapper customStyle={"justify-content: flex-end;"}>
				<SubmitButton WithRightMargin onClick={onSave} disabled={!isDirty} id="saveChangesThemeSelection">
					Save changes
				</SubmitButton>
			</Wrapper>
		</Wrapper>
	);
};

export default CommunityTheme;
