import React, { ReactNode } from "react";

import styled, { css } from "styled-components";

import { Box } from "shared/ui-kit";

interface FrameRowProps {
	children: ReactNode;
	customStyle?: string;
}

const FrameRowWrapper = styled(Box)<FrameRowProps>`
	display: flex;
	align-items: center;

	${({ customStyle }) => {
		return customStyle
			? css`
					${customStyle}
			  `
			: "";
	}}
`;

const FrameRow: React.FC<FrameRowProps> = ({ children, ...rest }: FrameRowProps): JSX.Element => {
	return <FrameRowWrapper {...rest}>{children}</FrameRowWrapper>;
};

export default FrameRow;
