/* eslint-disable react/no-unescaped-entities */
import React, { useCallback, useEffect, useMemo } from "react";

import { useMediaQuery } from "@material-ui/core";

import styled from "styled-components";

import { MemberDialog } from "modules/MemberHome/View/shared";
import { MemberDialogContentWrapper } from "modules/MemberHome/View/shared/style";
import { Dialog } from "shared/Components";
import { useTrack, useUser } from "shared/hooks";
import { useS3Uploader } from "shared/services/s3Uploader";
import { ToolbarActions, TrackModel } from "shared/types";
import { Button, Text } from "shared/ui-kit";

import * as appTheme from "theme/default";
import { dataUrlToFile, filterFiles } from "utils/serviceUtils/helpers";

import SelectTrackBody from "./SelectTrackBody";

const CreateButton = styled(Button)`
	height: 48px;
	font-size: 16px;
	font-weight: 600;
	line-height: 1.25;
	color: #6173fe;
`;

const correctFileTypes = ["audio/*"];

interface Props {
	onClose: (track: {}, reason: string) => void;
	onSelect: (track: TrackModel) => void;
	open: boolean;
}

const SelectTrack: React.FC<Props> = ({ onClose, onSelect, open }) => {
	const { uploadFile, getData: getS3UploaderData } = useS3Uploader();
	const { uploading } = getS3UploaderData();

	const { setTrackFileUrl, setTrackName, getData: getTrackData } = useTrack();
	const { trackUploadUrl } = getTrackData();

	const { getData: getUserData } = useUser();
	const { isMemberView } = getUserData();

	const isMobile = useMediaQuery(appTheme.default.breakpoints.down("sm"));

	useEffect(() => {
		setTrackFileUrl(null);
		setTrackName(null);
	}, [setTrackFileUrl, setTrackName]);

	const handleUpload = () => {
		const el: any = document.getElementById("trackFileUrl");
		if (el) {
			el.value = null;
			el.click();
		}
	};

	const onUpload = useCallback(
		async (e: React.ChangeEvent<HTMLInputElement>) => {
			const { target } = e;
			const { files } = target;

			if (files) {
				const filteredFiles = filterFiles(files, correctFileTypes);

				const trackFile = filteredFiles?.length ? files[0] : null;
				if (trackFile) {
					const correctFile = typeof trackFile === "string" ? await dataUrlToFile(trackFile, "test") : trackFile;
					uploadFile({
						file: correctFile as File,
						communityName: trackUploadUrl,
						checkProgress: true,
						customCallback: data => setTrackFileUrl(data?.publicUrl)
					});
					setTrackName(trackFile?.name?.split(".").slice(0, -1).join("."));

					onClose(e, ToolbarActions.ADD_TRACK);
				}
			}
		},
		[onClose, setTrackFileUrl, setTrackName, trackUploadUrl, uploadFile]
	);

	const onSelectTrack = useCallback(
		(data: TrackModel) => {
			setTimeout(() => {
				onSelect(data);
			}, 10);
		},
		[onSelect]
	);

	const dialogContent = useMemo(
		() => (
			<>
				<input
					id="trackFileUrl"
					type="file"
					onChange={onUpload}
					name="trackFileUrl"
					hidden
					accept={correctFileTypes.join(",")}
				/>
				<SelectTrackBody onSelect={onSelectTrack} />
			</>
		),
		[onSelectTrack, onUpload]
	);

	return isMemberView ? (
		<MemberDialog
			customWidth={512}
			title="Select a Track"
			open={open}
			onClose={onClose}
			footerPrimary={{ text: "Upload a new Track", onClick: handleUpload }}
		>
			<MemberDialogContentWrapper className="pt-5">{dialogContent}</MemberDialogContentWrapper>
		</MemberDialog>
	) : (
		<Dialog
			title={<Text variant={isMobile ? "h5" : "h3"}>Select a Track</Text>}
			open={open}
			onClose={onClose}
			footer={
				<CreateButton buttonTheme="light" fullWidth onClick={handleUpload} disabled={uploading}>
					Upload a new Track
				</CreateButton>
			}
		>
			{dialogContent}
		</Dialog>
	);
};

export default SelectTrack;
