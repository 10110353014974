import React, { ReactNode, useCallback, useMemo, useState } from "react";

import { useHistory } from "react-router-dom";

import { routes as adminRoutes } from "apps/RegularUser/constants";
import { ReactComponent as CatIllustrationIcon } from "assets/icons/cat-illustration.svg";
import { Icon, Loader, Search } from "shared/ui-kit";

import { ActionButton, Body, EmptyBox, Header, Skelet, Wrapper } from "./style";

interface PageTemplateProps {
	title: string;
	isLoading: boolean;
	isNoData: boolean;
	emptyText?: string;

	searchPlaceholder: string;
	searchId?: string;
	onSearchUpdate: (text: string) => void;

	actionText?: string;
	actionId?: string;
	onCreateClick?: () => void;
	isDisabledCreateAction?: boolean;
	isLoadingAction?: boolean;

	extraAction?: ReactNode;

	columnCount?: number;

	isSubpage?: boolean;

	children?: ReactNode;

	customHeader?: ReactNode;

	searchbarCustomActions?: ReactNode;

	showReportedLink?: boolean;

	hideHeader?: boolean;
}

const PageTemplate: React.FC<PageTemplateProps> = ({
	title,
	isLoading,
	isNoData,
	emptyText = "No Results Found",

	searchPlaceholder,
	onSearchUpdate,

	actionText,
	onCreateClick,
	isDisabledCreateAction = false,
	isLoadingAction = false,

	extraAction,

	isSubpage = false,

	children,
	customHeader,
	searchbarCustomActions,
	showReportedLink = false,

	hideHeader = false,
	actionId,
	searchId
}) => {
	const history = useHistory();

	const [keyword, setKeyword] = useState("");
	const [isDirty, setDirty] = useState(false);

	const markDirty = () => {
		setDirty(true);
	};

	const updateKeyword = useCallback(
		e => {
			const val = e.target.value;
			setKeyword(val);
			onSearchUpdate(val);
			setDirty(true);
		},
		[onSearchUpdate]
	);

	const handleCreateClick = useCallback(() => {
		onCreateClick && onCreateClick();
	}, [onCreateClick]);

	const actionBtn = useMemo(
		() => (
			<ActionButton
				removeSideMargin
				size="large"
				onClick={handleCreateClick}
				disabled={isDisabledCreateAction}
				id={actionId}
			>
				{!isLoadingAction ? <>{actionText}</> : <Loader size="1rem" show color="inherit" variant="indeterminate" />}
			</ActionButton>
		),
		[handleCreateClick, isDisabledCreateAction, isLoadingAction, actionText, actionId]
	);

	return (
		<Wrapper>
			<Header.Wrapper>
				<Header.Text variant="h1" className={isSubpage && "subpage"}>
					{title}
				</Header.Text>
				{showReportedLink && (
					<Header.ReportedContentButton
						removeSideMargin
						size="medium"
						palette="primary"
						buttonTheme="outline"
						rightIcon={<Icon fill={"#8f9bb3"} name={"exclamation"} />}
						onClick={() => history.push(adminRoutes.manage.reportedContent.getPath())}
					>
						Reported content
					</Header.ReportedContentButton>
				)}
			</Header.Wrapper>
			{customHeader}
			<Body.Wrapper className={isSubpage ? "subpage" : ""}>
				{!isLoading && isNoData && !isDirty ? (
					<EmptyBox.Body>
						<CatIllustrationIcon />
						<EmptyBox.Note>{emptyText}</EmptyBox.Note>
						{actionText && <EmptyBox.ActionWrapper>{actionBtn}</EmptyBox.ActionWrapper>}
					</EmptyBox.Body>
				) : (
					<>
						{!hideHeader && (
							<>
								{isLoading && !keyword && !isDirty ? (
									<Skelet.Form>
										<Skelet.Header>
											<Skelet.Skeleton width={260} height={40} />
											<Skelet.Skeleton width={120} height={40} />
										</Skelet.Header>
									</Skelet.Form>
								) : (
									<Body.Header>
										<Body.HeaderLeft>
											<Body.SeachbarWrapper>
												<Search
													id={searchId}
													autoComplete="off"
													defaultValue={keyword}
													onChange={updateKeyword}
													placeholder={searchPlaceholder}
												/>
											</Body.SeachbarWrapper>
											<Body.SearchbarCustomActionsWrapper>{searchbarCustomActions}</Body.SearchbarCustomActionsWrapper>
										</Body.HeaderLeft>
										<Body.HeaderActionBlock className="flex-shrink-0">
											{extraAction && (
												<Body.HeaderActionWrapper onClick={markDirty}>{extraAction}</Body.HeaderActionWrapper>
											)}
											{actionText && <Body.HeaderActionWrapper>{actionBtn}</Body.HeaderActionWrapper>}
										</Body.HeaderActionBlock>
									</Body.Header>
								)}
							</>
						)}
						<Body.Content>
							{children}
							{!isLoading && isNoData && isDirty && (
								<EmptyBox.Body>
									<CatIllustrationIcon />
									<EmptyBox.Note>No Results Found</EmptyBox.Note>
								</EmptyBox.Body>
							)}
						</Body.Content>
					</>
				)}
			</Body.Wrapper>
		</Wrapper>
	);
};

export default PageTemplate;
