import React, { memo } from "react";

import styled from "styled-components";

import { ReactComponent as PinIcon } from "assets/icons/icon-pin.svg";
import { Box, Switch, Text } from "shared/ui-kit";

const Container = styled(Box)`
	flex: 1;
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const Item = styled(Box)``;

Item.Wrapper = styled(Box)`
	display: flex;
	align-items: center;
`;

Item.IconWrapper = styled(Box)`
	display: flex;
	justify-content: center;
	align-items: center;

	margin-right: 9px;
`;

Item.Title = styled(Text)`
	font-size: 15px;
	font-weight: 600;
`;

interface Props {
	value: boolean;
	onChange: (value: boolean) => void;
}

const IsPrimaryBoardField: React.FC<Props> = memo(({ value = false, onChange }: Props) => (
	<Container>
		<Item.Wrapper>
			<Item.IconWrapper>
				<PinIcon />
			</Item.IconWrapper>
			<Item.Title>Set as default</Item.Title>
		</Item.Wrapper>
		<Switch
			checked={value}
			onChange={el => {
				onChange(el.target.checked);
			}}
		/>
	</Container>
));

export default IsPrimaryBoardField;
