import styled from "styled-components";

import { Box } from "shared/ui-kit";

export const Wrapper = styled(Box)`
	padding: 22px 16px;
`;

export const Comment = styled(Box);

Comment.Wrapper = styled.form`
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
`;

Comment.AvatarWrapper = styled(Box)`
	width: 40px;
	height: 40px;

	border-radius: 50%;

	overflow: hidden;

	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;

	margin-right: 8px;

	display: inline-flex;

	&.subreply {
		width: 24px;
		height: 24px;
	}
`;

Comment.InputWrapper = styled(Box)`
	display: block;
	width: calc(100% - 40px);

	border: 1px solid rgba(197, 206, 224, 0.5);

	background-color: #f7f9fc;

	border-radius: 8px;

	overflow: hidden;

	.author {
		white-space: nowrap;
	}

	&.subreply {
		min-width: 300px;
	}
`;
