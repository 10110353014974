import React, { useCallback } from "react";

import { SeriesCollection } from "modules/Manage/Data/types/SeriesTypes";

import { AddContentToSeries, CreateFileDialog, CreateUpdateSeriesDialog, SeriesDetailsDialog } from "shared/Components";
import { useFiles, useSeries, useTrack, useVideo } from "shared/hooks";

import { CreateTrack, CreateVideo } from "../index";

interface CreateUpdateSeriesWrapperProps {
	groupId?: string;
	eventId?: string;
	onUpdateSeriesDialog?: (updatedCollection?: SeriesCollection, isCreateUpdate?: boolean) => void;
	onUpdateSeriesContent?: () => void;
	onlyAddSelfContent?: boolean;
	shouldUpdateIgnore?: boolean;
}

const CreateUpdateSeriesWrapper: React.FC<CreateUpdateSeriesWrapperProps> = ({
	groupId,
	eventId,
	onUpdateSeriesDialog,
	onUpdateSeriesContent,
	onlyAddSelfContent,
	shouldUpdateIgnore = false
}) => {
	const { getData: getSeriesData } = useSeries();
	const { createEditSeriesDialog, seriesDetailsDialog } = getSeriesData();

	const { setTrackInfoPopup, setRefresh, setTrackDuration, getData: getTrackData } = useTrack();
	const { trackInfoPopup, refresh } = getTrackData();

	const { setVideoInfoPopup, getData: getVideoData } = useVideo();
	const { videoInfoPopup } = getVideoData();

	const { setFileInfoPopup, getData: getFilesData } = useFiles();
	const { fileInfoPopup } = getFilesData();

	const handleOnUpdateSeries = useCallback(
		(updatedCollection?: SeriesCollection, isCreateUpdate?: boolean) => {
			onUpdateSeriesDialog && onUpdateSeriesDialog(updatedCollection, isCreateUpdate);
		},
		[onUpdateSeriesDialog]
	);

	return (
		<>
			{createEditSeriesDialog.open && (
				<CreateUpdateSeriesDialog groupId={groupId} eventId={eventId} onUpdate={handleOnUpdateSeries} />
			)}
			{seriesDetailsDialog && seriesDetailsDialog.addContent && (
				<AddContentToSeries onlyAddSelfContent={onlyAddSelfContent} />
			)}
			{seriesDetailsDialog && !!seriesDetailsDialog.seriesDetails && !seriesDetailsDialog.addContent && (
				<SeriesDetailsDialog
					onUpdate={handleOnUpdateSeries}
					onUpdateSeriesContent={onUpdateSeriesContent}
					shouldUpdateIgnore={shouldUpdateIgnore}
				/>
			)}
			<CreateTrack
				open={!!trackInfoPopup.open}
				editableModel={trackInfoPopup.model}
				album={trackInfoPopup.album}
				seriesView
				onClose={e => {
					setTrackInfoPopup({ open: false });
					if (e?.created) {
						setRefresh(refresh + 1);
					}
					setTrackDuration();
				}}
			/>
			<CreateVideo
				open={!!videoInfoPopup.open}
				editableModel={videoInfoPopup.model}
				onClose={() => {
					setVideoInfoPopup({ open: false });
				}}
				seriesView
			/>
			<CreateFileDialog
				open={!!fileInfoPopup.open}
				editableModel={fileInfoPopup.model}
				onClose={() => {
					setFileInfoPopup({ open: false });
				}}
				seriesView
			/>
		</>
	);
};

export default CreateUpdateSeriesWrapper;
