import React from "react";

import { useMediaQuery } from "@material-ui/core";

import { useMessaging } from "modules/Messaging/Data";
import { MessagingLayout } from "shared/Components";

import * as appTheme from "theme/default";

import { ChatWrapper, ContentWrapper, InboxPanelWrapper, Title } from "./styles";

import { ChatPanel, InboxPanel } from "../../Components";

interface MessagingProps {
	messageTo?: string;
}

const Messaging: React.FC<MessagingProps> = ({ messageTo }) => {
	const { getData: getMessagingData } = useMessaging();
	const { activePanel } = getMessagingData();

	const isMobile = useMediaQuery(appTheme.default.breakpoints.down("sm"));

	return (
		<MessagingLayout>
			{!isMobile && <Title variant="h1">Messages</Title>}
			<ContentWrapper container spacing={0}>
				<InboxPanelWrapper className={activePanel === "inbox" && "active_inbox"}>
					<InboxPanel messageTo={messageTo} />
				</InboxPanelWrapper>
				<ChatWrapper className={activePanel === "inbox" && "active_inbox"}>
					<ChatPanel />
				</ChatWrapper>
			</ContentWrapper>
		</MessagingLayout>
	);
};

export default Messaging;
