import React from "react";

import { Box } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import styled from "styled-components";

const Wrapper = styled(Box)`
	display: flex;
	align-items: center;
`;

const StyledSkeleton = styled(Skeleton)`
	transform: scale(1);
	&.mb {
		margin-bottom: 4px;
	}
`;

const LogoSkeleton = () => {
	return (
		<Wrapper>
			<StyledSkeleton width={40} height={40} />
			<Box marginLeft={1}>
				<StyledSkeleton className="mb" width={90} height={10} />
				<StyledSkeleton width={60} height={10} />
			</Box>
		</Wrapper>
	);
};

export default LogoSkeleton;
