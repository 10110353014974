import React, { FC, useCallback, useEffect, useMemo, useState } from "react";

import clsx from "clsx";

import { ReactComponent as IconCrown } from "assets/icons/icon-crown.svg";
import { TableColumnLoaderType } from "shared/Components/NewTable/Components";
import { Cell } from "shared/Components/NewTable/style";
import { useFundraisers } from "shared/hooks";
import { LeaderboardSponsor } from "shared/types";
import { GroupRelation, OnBehalfOfLeaderboardPersona } from "shared/types/FundraiserLeaderboardTypes";
import { Icon } from "shared/ui-kit";
import { formatMoney } from "utils/formatMoney";
import { formatCount } from "utils/serviceUtils/helpers";

const Table = React.lazy(() => import("shared/Components/NewTable"));

const SponsorsTable: FC<{ show: boolean; workspace?: string | null }> = ({ show, workspace }) => {
	const [tableData, setTableData] = useState<LeaderboardSponsor[]>([]);
	const [isLoading, setIsLoading] = useState(true);

	const { getLeaderboardSponsors, getData: getFundraiserData } = useFundraisers();
	const { fundraiser } = getFundraiserData();

	useEffect(() => {
		if (show && !tableData.length && fundraiser) {
			setIsLoading(true);
			getLeaderboardSponsors(fundraiser._id, workspace).then(data => {
				data && setTableData(data.filter(g => !!g.amountRaised));
				setIsLoading(false);
			});
		}
	}, [fundraiser, getLeaderboardSponsors, show, tableData.length, workspace]);

	const renderGroups = useCallback(
		(group: GroupRelation[]) => {
			const groupNames = group
				.map(g => {
					const name = fundraiser?.groups.find(group => group._id === g.groupId)?.name;
					if (name) return name;
				})
				.filter(name => typeof name === "string");

			return groupNames.join(", ");
		},
		[fundraiser?.groups]
	);

	const renderOnBehalfOf = useCallback(
		(onBehalf: OnBehalfOfLeaderboardPersona[]) => onBehalf.map(o => `${o.firstName} ${o.lastName}`).join(", "),
		[]
	);

	const tableColumns = useMemo(
		() => [
			{
				alignment: "left",
				width: 338,
				label: <Cell.HeaderText>{formatCount(tableData.length, "Sponsor")}</Cell.HeaderText>,
				Cell: ({ rowData, rowIndex }: { rowData: LeaderboardSponsor; rowIndex: number }) => (
					<Cell.Wrapper>
						<Cell.Order className="mr-3">{rowIndex + 1}.</Cell.Order>
						<div className="relative">
							<Cell.ImageWrapper>
								{!!rowData.image.url ? (
									<Cell.Image src={rowData.image.url} />
								) : (
									<Icon name="user" group="filled" fill="#8F9BB3" />
								)}
							</Cell.ImageWrapper>
							{rowIndex < 3 && (
								<div className="absolute -top-2 -left-2 bg-white rounded-full p-1">
									<IconCrown
										className={clsx(`leaderboard-crown-${rowIndex + 1}`, rowIndex === 0 ? "h-5 w-5" : "h-4 w-4")}
									/>
								</div>
							)}
						</div>
						<Cell.Wrapper className="column with-image">
							<Cell.Text>{rowData.name}</Cell.Text>
						</Cell.Wrapper>
					</Cell.Wrapper>
				),
				loaderTemplate: TableColumnLoaderType.imageWthOneTextRow,
				dataKey: "content"
			},
			{
				alignment: "left",
				minWidth: 120,
				label: <Cell.HeaderText>Donated</Cell.HeaderText>,
				Cell: ({ rowData }: { rowData: LeaderboardSponsor }) => (
					<Cell.Wrapper>
						<Cell.Text>{rowData.amountRaised ? formatMoney(rowData.amountRaised) : "-"}</Cell.Text>
					</Cell.Wrapper>
				),
				dataKey: "raisedAmount"
			},
			{
				alignment: "left",
				minWidth: 120,
				label: <Cell.HeaderText>Groups</Cell.HeaderText>,
				Cell: ({ rowData }: { rowData: LeaderboardSponsor }) => (
					<Cell.Wrapper>
						{!!rowData.onBehalfOfPersona.length && !!rowData.onBehalfOfPersona[0].group.length
							? renderGroups(rowData.onBehalfOfPersona[0].group)
							: "-"}
					</Cell.Wrapper>
				),
				dataKey: "Group"
			},
			{
				alignment: "left",
				minWidth: 120,
				label: <Cell.HeaderText>On behalf of</Cell.HeaderText>,
				Cell: ({ rowData }: { rowData: LeaderboardSponsor }) => (
					<Cell.Wrapper>
						<Cell.Text>
							{!!rowData.onBehalfOfPersona.length ? renderOnBehalfOf(rowData.onBehalfOfPersona) : "-"}
						</Cell.Text>
					</Cell.Wrapper>
				),
				dataKey: "onBehalfOf"
			}
		],
		[renderGroups, renderOnBehalfOf, tableData.length]
	);

	return (
		<Table
			columns={tableColumns}
			data={tableData}
			loading={isLoading}
			totalDataCount={20}
			page={1}
			pageSize={20}
			onChangePage={() => {}}
			onChangePageSize={() => {}}
		/>
	);
};

export default SponsorsTable;
