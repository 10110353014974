import React from "react";

import CircularProgress from "@material-ui/core/CircularProgress";
import { Controller, SubmitHandler, useForm } from "react-hook-form";

import { ReactComponent as AmericanExpressCardIcon } from "assets/icons/american-express.svg";
import { ReactComponent as LockIcon } from "assets/icons/icon-lock.svg";
import { ReactComponent as MasterCardIcon } from "assets/icons/mastercard.svg";
import { ReactComponent as VisaCardIcon } from "assets/icons/visa.svg";
import { LabeledInput } from "modules/MemberHome/View/shared";
import CVVInput from "modules/MemberHome/View/shared/CVVInput";
import CreditCardInput from "modules/MemberHome/View/shared/CreditCardInput";
import ExpirationDateInput from "modules/MemberHome/View/shared/ExpirationDateInput";
import { useStripe } from "shared/hooks";

import { Card } from "shared/types/CardsType";

import { CardList, Form, FormActions, FormField, FormTwoColumns, LockIconWrapper, Wrapper } from "./styles";

import { SettingsActionButton, SettingsCancelButton } from "../../commonStyles";

interface FormValues {
	name: string;
	cardNumber: string;
	expiration: string;
	cvv: string;
}
interface Props {
	onCancel: () => void;
	cardToBeChanged: Card | null;
}

export function PaymentMethodsForm(props: Props) {
	const { onCancel, cardToBeChanged } = props;
	const {
		control,
		formState: { errors, isValid },
		handleSubmit,
		reset
	} = useForm<FormValues>({ mode: "onChange" });
	const { newCard, updateCard, getData } = useStripe();
	const { submitting } = getData();

	const isChange = !!cardToBeChanged;

	const onSubmit: SubmitHandler<FormValues> = async data => {
		if (isChange) {
			const expiration = data.expiration.split("/");
			const expirationMonth =
				expiration[0].length === 2 && expiration[0].split("")[0] === "0" ? expiration[0].slice(1) : expiration[0];
			const expirationYear = `20${expiration[1]}`;
			const cardData = {
				name: data.name,
				exp_month: expirationMonth,
				exp_year: expirationYear
			};
			await updateCard(cardToBeChanged.id, cardData, () => onCancel());
		} else {
			const cardData = {
				number: data.cardNumber.replace(/\s/g, ""),
				exp_month: data.expiration.split("/")[0],
				exp_year: data.expiration.split("/")[1],
				cvc: data.cvv,
				name: data.name
			};
			await newCard(cardData, () => onCancel());
		}
	};

	React.useEffect(() => {
		if (isChange) {
			const expirationString =
				String(cardToBeChanged.exp_month).padStart(2, "0") + "/" + String(cardToBeChanged.exp_year).slice(-2);
			reset({
				name: cardToBeChanged.name || "",
				expiration: expirationString
			});
		}
	}, [isChange, reset, cardToBeChanged]);

	return (
		<Wrapper>
			<CardList className="flex">
				<VisaCardIcon />
				<MasterCardIcon />
				<AmericanExpressCardIcon />
			</CardList>
			<Form onSubmit={handleSubmit(onSubmit)}>
				<FormField>
					<Controller
						name="name"
						control={control}
						rules={{ required: "Name is required" }}
						render={({ onChange, value, name }) => (
							<LabeledInput
								label="Name *"
								name={name}
								onChange={onChange}
								value={value}
								error={errors?.name?.message}
							/>
						)}
					/>
				</FormField>
				<FormField>
					<Controller
						name="cardNumber"
						rules={
							isChange
								? {}
								: {
										required: "Card number is required",
										minLength: { value: 16, message: "Invalid Card Number." },
										maxLength: { value: 19, message: "Invalid Card Number." }
								  }
						}
						control={control}
						render={({ onChange, value }) => (
							<CreditCardInput
								label="Card Number"
								onChange={onChange}
								value={value}
								disabled={!!isChange}
								placeholder={isChange ? `**** **** **** ${cardToBeChanged.last4}` : "1234 5678 9012 3456"}
								name="cardNumber"
								rightIcon={{
									el: (
										<LockIconWrapper>
											<LockIcon />
										</LockIconWrapper>
									),
									active: true
								}}
								error={errors?.cardNumber?.message}
							/>
						)}
					/>
				</FormField>
				<FormTwoColumns>
					<FormField>
						<Controller
							name="expiration"
							rules={{
								required: "Expiration is required",
								validate: val => {
									const valArray = val.split("/");
									const month = valArray[0];
									const year = valArray[1];
									if (!month || parseInt(month) > 12 || !year || parseInt(year) > 28 || parseInt(year) < 22) {
										return "Invalid Expiration Date.";
									}
									return true;
								}
							}}
							control={control}
							render={({ onChange, value }) => (
								<ExpirationDateInput
									label="Expiration"
									onChange={onChange}
									value={value}
									placeholder="01/25"
									name="expiration"
									error={errors?.expiration?.message}
								/>
							)}
						/>
					</FormField>
					<FormField>
						<Controller
							name="cvv"
							rules={
								isChange
									? {}
									: {
											required: "CVV is required",
											maxLength: { value: 3, message: "Invalid CVV Number." }
									  }
							}
							control={control}
							render={({ onChange, value }) => (
								<CVVInput
									label="CVV"
									onChange={onChange}
									value={value}
									placeholder={isChange ? "***" : "123"}
									name="cvv"
									error={errors?.cvv?.message}
									disabled={!!isChange}
								/>
							)}
						/>
					</FormField>
				</FormTwoColumns>
				<FormActions>
					<SettingsCancelButton type="button" onClick={onCancel}>
						Cancel
					</SettingsCancelButton>
					<SettingsActionButton type="submit" disabled={!isValid || submitting}>
						{submitting ? <CircularProgress size={16} /> : <>{isChange ? "Save" : "Add"}</>}
					</SettingsActionButton>
				</FormActions>
			</Form>
		</Wrapper>
	);
}
