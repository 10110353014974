import { Skeleton } from "@material-ui/lab";
import styled from "styled-components";

export const SkeletonForm = styled(Skeleton)`
	&.side-offset {
		margin: 0 5px;
	}

	&.full-scale {
		transform: scale(1);
	}
`;
