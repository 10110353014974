export default {
	TOKEN_KEY: "token",
	USER_KEY: "user",
	AUTH_DATA: "AuthData",
	COORDINATE: "Coord",
	WORKSPACE: "workspace",
	CORE_API_URLS: {
		LOGIN: "/auth/signIn",
		ADMIN: "/dashboard",
		NOT_FOUND: "/404"
	},
	HOOK_STORAGE_KEYS: {
		USER_DATA: "UserData",
		AUTH_DATA: "AuthData",
		NEW_COMMUNITY_DATA: "NewCommunityData",
		PERSONA_DATA: "PersonaData",
		FEATURE_DATA: "FeatureData",
		BOARD_DATA: "BoardData",
		SERIES_DATA: "SeriesData",
		FILES_DATA: "FilesData",
		VIDEO_DATA: "VideoData",
		GROUP_DATA: "GroupData",
		EVENT_DATA: "EventData",
		ROUTES_DATA: "RoutesData",
		STORY_DATA: "StoryData",
		ALBUM_DATA: "AlbumData",
		POSTS_DATA: "PostsData",
		TRANSACTION_DATA: "TransactionData",
		REPORT_DATA: "ReportData",
		SUPPORT_DATA: "SupportData",
		MARKETING_DATA: "MarketingData",
		INTEGRATION_DATA: "IntegrationData",
		LOYALTY_DATA: "LoyaltyData",
		CATEGORIES_DATA: "CategoriesData",
		COMPANIES_DATA: "CompaniesData",
		GLOBAL_USER_ID: "GlobalUserId",
		LIVE_CONVERSATION_DATA: "LiveConversationData",
		LIVE_CONVERSATION_HELPER_DATA: "LiveConversationHelperData",
		ANALYTICS_DATA: "AnalyticsData",
		FUNDRAISER_DATA: "FundraiserData"
	},
	GLOBAL_CONSTANTS: {
		BACKEND_API: process.env.REACT_APP_BACKEND_API,
		CONTACT_US_EMAIL: process.env.REACT_APP_CONTACT_US_EMAIL,
		TERMS_AND_CONDITIONS_LINK: process.env.REACT_APP_TERMS_AND_CONDITIONS_LINK,
		PRIVACY_POLICY_LINK: process.env.REACT_APP_PRIVACY_POLICY_LINK,
		COOKIE_USE_LINK: process.env.REACT_APP_COOKIE_USE_LINK,
		LOCAL_STORAGE_PREFIX: process.env.REACT_APP_LOCAL_STORAGE_PREFIX,
		GOOGLE_CLIENT_ID: process.env.REACT_APP_GOOGLE_CLIENT_ID,
		GOOGLE_STORE_APP_URL: process.env.REACT_APP_GOOGLE_STORE_APP_URL,
		APPLE_STORE_APP_URL: process.env.REACT_APP_APPLE_STORE_APP_URL,
		APPLE_APP_ID: process.env.REACT_APP_APPLE_APP_ID,
		VYOO_MARKETING_SITE_URL: process.env.REACT_APP_VYOO_MARKETING_SITE_URL,
		GOOGLE_YOUTUBE_API_KEY: process.env.REACT_APP_GOOGLE_YOUTUBE_API_KEY,
		GOOGLE_YOUTUBE_API_LINK: process.env.REACT_APP_GOOGLE_YOUTUBE_API_LINK,
		VYOO_APP_STORE_SMART_LINK: process.env.REACT_APP_VYOO_APP_STORE_SMART_LINK,
		RECAPTCHA_SECRET_KEY: process.env.REACT_APP_RECAPTCHA_SECRET_KEY,
		ADS_SERVER_URL: process.env.REACT_APP_ADS_SERVER_URL,
		IP_API_BASE_URL: process.env.REACT_APP_IP_API_BASE_URL,
		IP_API_KEY: process.env.REACT_APP_IP_API_KEY,
		STRIPE_PUB_KEY: process.env.REACT_APP_STRIPE_PUB_KEY,

		// VYOO - DAFAULT
		FIRBASE_PROJECT_ID: process.env.REACT_APP_FIRBASE_PROJECT_ID,
		FIRBASE_API_KEY: process.env.REACT_APP_FIRBASE_DB_API_KEY,
		FIRBASE_APP_ID: process.env.REACT_APP_FIRBASE_DB_APP_ID,
		FIRBASE_AUTH_DOMAIN: process.env.REACT_APP_FIRBASE_DB_AUTH_DOMAIN,
		FIRBASE_URL: process.env.REACT_APP_FIRBASE_DB_URL,
		FIRBASE_STORAGE_BUCKET: process.env.REACT_APP_FIRBASE_DB_STORAGE_BUCKET,

		// VYOOUNIVERSITY
		VYOOUNIVERSITY_FIRBASE_PROJECT_ID: process.env.REACT_APP_VYOOUNIVERSITY_FIRBASE_PROJECT_ID,
		VYOOUNIVERSITY_FIRBASE_API_KEY: process.env.REACT_APP_VYOOUNIVERSITY_FIRBASE_DB_API_KEY,
		VYOOUNIVERSITY_FIRBASE_APP_ID: process.env.REACT_APP_VYOOUNIVERSITY_FIRBASE_DB_APP_ID,
		VYOOUNIVERSITY_FIRBASE_AUTH_DOMAIN: process.env.REACT_APP_VYOOUNIVERSITY_FIRBASE_DB_AUTH_DOMAIN,
		VYOOUNIVERSITY_FIRBASE_URL: process.env.REACT_APP_VYOOUNIVERSITY_FIRBASE_DB_URL,
		VYOOUNIVERSITY_FIRBASE_STORAGE_BUCKET: process.env.REACT_APP_VYOOUNIVERSITY_FIRBASE_DB_STORAGE_BUCKET,

		// KIJIJI
		KIJIJI_FIRBASE_PROJECT_ID: process.env.REACT_APP_KIJIJI_FIRBASE_PROJECT_ID,
		KIJIJI_FIRBASE_API_KEY: process.env.REACT_APP_KIJIJI_FIRBASE_DB_API_KEY,
		KIJIJI_FIRBASE_APP_ID: process.env.REACT_APP_KIJIJI_FIRBASE_DB_APP_ID,
		KIJIJI_FIRBASE_AUTH_DOMAIN: process.env.REACT_APP_KIJIJI_FIRBASE_DB_AUTH_DOMAIN,
		KIJIJI_FIRBASE_URL: process.env.REACT_APP_KIJIJI_FIRBASE_DB_URL,
		KIJIJI_FIRBASE_STORAGE_BUCKET: process.env.REACT_APP_KIJIJI_FIRBASE_DB_STORAGE_BUCKET,
		KIJIJI_APP_STORE_SMART_LINK: process.env.REACT_APP_KIJIJI_APP_STORE_SMART_LINK,

		// HOLLIBLU
		HOLLIBLU_FIRBASE_PROJECT_ID: process.env.REACT_APP_HOLLIBLU_FIRBASE_PROJECT_ID,
		HOLLIBLU_FIRBASE_API_KEY: process.env.REACT_APP_HOLLIBLU_FIRBASE_DB_API_KEY,
		HOLLIBLU_FIRBASE_APP_ID: process.env.REACT_APP_HOLLIBLU_FIRBASE_DB_APP_ID,
		HOLLIBLU_FIRBASE_AUTH_DOMAIN: process.env.REACT_APP_HOLLIBLU_FIRBASE_DB_AUTH_DOMAIN,
		HOLLIBLU_FIRBASE_URL: process.env.REACT_APP_HOLLIBLU_FIRBASE_DB_URL,
		HOLLIBLU_FIRBASE_STORAGE_BUCKET: process.env.REACT_APP_HOLLIBLU_FIRBASE_DB_STORAGE_BUCKET,
		HOLLIBLU_APP_STORE_SMART_LINK: process.env.REACT_APP_HOLLIBLU_APP_STORE_SMART_LINK,

		VYOO_DOMAIN: process.env.REACT_APP_VYOO_DOMAIN,
		VYOO_UNIVERSITY_DOMAIN: process.env.REACT_APP_VYOO_UNIVERSITY_DOMAIN,
		MAKEEN_COMMUNITY_DOMAIN: process.env.REACT_APP_MAKEEN_COMMUNITY_DOMAIN,
		KIJIJI_DOMAIN: process.env.REACT_APP_KIJIJI_DOMAIN,
		HOLLIBLU_DOMAIN: process.env.REACT_APP_HOLLIBLU_DOMAIN,
		USE_DATA_LAYER_EVENTS: JSON.parse(JSON.stringify(process.env.REACT_APP_USE_DATA_LAYER_EVENTS)) || false,

		ENV_APPLICATION_NAME: process.env.REACT_APP_ENV_APPLICATION_NAME || "Vyoo",
		AKINA_WORKSPACE: process.env.REACT_APP_AKINA_WORKSPACE || "",
		HOLLIBLU_WORKSPACE: process.env.REACT_APP_HOLLIBLU_WORKSPACE || "",
		BITCOIN_WORKSPACE: process.env.REACT_APP_BITCOIN_WORKSPACE || "",
		THIRD_PARTY_SOURCE_PAGE_URL: process.env.REACT_APP_THIRD_PARTY_SOURCE_PAGE_URL || "",

		GOOGLE_MAPS_KEY: process.env.REACT_APP_GOOGLE_MAPS_KEY || "",
		LIVE_CONVO_WEB_SOCKET_URL: process.env.REACT_APP_LIVE_CONVO_WEB_SOCKET_URL || "",
		LIVE_CONVO_ICE_SERVE_URL: process.env.REACT_APP_LIVE_CONVO_ICE_SERVE_URL || "",

		IS_STAGE: process.env.REACT_APP_IS_STAGE ? JSON.parse(process.env.REACT_APP_IS_STAGE) : false,
		HIDE_MEMBERS_APP: process.env.REACT_APP_HIDE_MEMBERS_APP
			? JSON.parse(process.env.REACT_APP_HIDE_MEMBERS_APP)
			: false,
		SUBGROUPS_LIMIT_COUNT: 20
	},
	sportsAdminMainColor: "#409d99"
};
