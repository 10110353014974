import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";

import { Box, ButtonBase } from "@material-ui/core";

import styled from "styled-components";

import { ReactComponent as SoundWaveIcon } from "assets/icons/iconSoundWave.svg";
import { useStream } from "shared/hooks";
import { StreamEntityType, TrackMeta, TrackModel } from "shared/types";

import { NextPlaylistItem } from "..";

const Wrapper = styled(Box)`
	margin-bottom: 12px;
	height: 240px;
	background: #f1f3f4;
	position: relative;

	audio {
		height: 50px;
		width: 100%;
		background: #f1f3f4;
	}

	${props => props.theme.breakpoints.up("sm")} {
		height: 450px;
	}
`;

Wrapper.Thumb = styled(ButtonBase)`
	width: 100%;
	img,
	.icon {
		height: 190px;
		width: 100%;
		${props => props.theme.breakpoints.up("sm")} {
			height: 400px;
		}
	}
	img {
		object-fit: cover;
	}
	.icon {
		display: flex;
		align-items: center;
		justify-content: center;
		svg {
			width: 64px;
			height: 64px;
		}
	}
`;

const TrackPlayer: FC<{
	track: TrackModel;
	nextItem?: {
		title: string;
		subtitle: string;
	};
	autoPlay?: boolean;
}> = ({ track, nextItem, autoPlay }) => {
	const audioRef = useRef<HTMLAudioElement>(null);
	const { startStream, pauseStream } = useStream();

	const [trackNextClosed, setTrackNextClosed] = useState(false);
	const [trackEnded, setTrackEnded] = useState(false);

	const handleTrackEnded = useCallback(() => {
		setTrackEnded(true);
	}, []);

	useEffect(() => {
		if (audioRef.current) {
			audioRef.current.currentTime = track.stream ? track.stream.current : 0;
			audioRef.current.addEventListener("ended", handleTrackEnded, false);
		}
	}, [handleTrackEnded, track.stream]);

	useEffect(() => {
		const audio = audioRef.current;
		return () => {
			if (audio) {
				startStream({ entityId: track._id, type: StreamEntityType.MUSIC });
			}
		};
	}, [startStream, track._id]);

	const handleClose = useCallback(() => {
		setTrackNextClosed(true);
	}, []);

	const handleClick = useCallback(() => {
		if (audioRef.current?.paused && autoPlay) {
			audioRef.current.play();
		} else {
			audioRef.current?.pause();
		}
	}, [autoPlay]);

	const thumbnail = useMemo(() => (track.meta as TrackMeta).artwork.url, [track.meta]);

	const handleTrackPlay = useCallback(() => {
		startStream({ entityId: track._id, type: StreamEntityType.MUSIC });
	}, [startStream, track._id]);

	const handleTrackPaused = useCallback(
		e => {
			pauseStream({ entityId: track._id, current: e.target.currentTime, type: StreamEntityType.MUSIC });
		},
		[pauseStream, track._id]
	);

	return (
		<Wrapper>
			{nextItem && !trackNextClosed && trackEnded && (
				<NextPlaylistItem title={nextItem.title} subtitle={nextItem.subtitle} handleClose={handleClose} />
			)}
			<Wrapper.Thumb onClick={handleClick}>
				{thumbnail ? (
					<img src={thumbnail} alt={track.title} />
				) : (
					<Box className="icon">
						<SoundWaveIcon />
					</Box>
				)}
			</Wrapper.Thumb>
			<audio
				controls={autoPlay}
				ref={audioRef}
				autoPlay={autoPlay}
				onPlay={handleTrackPlay}
				onPause={handleTrackPaused}
			>
				<source src={(track.meta as TrackMeta).track.url} />
			</audio>
		</Wrapper>
	);
};

export default TrackPlayer;
