import styled, { keyframes } from "styled-components";

import { Avatar, Box, Text } from "shared/ui-kit";

export const Speaker = styled(Box)`
	position: relative;

	display: flex;
	justify-content: center;
	align-items: center;

	height: 100%;

	border-radius: 12px;

	background-color: #1f1f1f;

	&:before {
		content: "";

		position: absolute;
		top: -4px;
		left: -4px;

		z-index: 2;

		width: calc(100% + 8px);
		height: calc(100% + 8px);

		border-radius: 16px;

		border: none;

		overflow: hidden;
	}

	&.speaking {
		&:before {
			border: 2px solid #6173fe;
		}
	}
`;

Speaker.Video = styled.video`
	width: 100%;
	height: 100%;

	object-fit: cover;

	border-radius: inherit;

	&.myVideo {
		transform: scaleX(-1);
	}
`;

const rippleColor = "204 204 204";
const ripple = keyframes`
0% {
    box-shadow: 0 0 0 0 rgba(${rippleColor}/30%),
                0 0 0 5px rgba(${rippleColor}/30%),
                0 0 0 10px rgba(${rippleColor}/30%),
                0 0 0 20px rgba(${rippleColor}/30%);
  }
  100% {
    box-shadow: 0 0 0 5px rgba(${rippleColor}/30%),
                0 0 0 10px rgba(${rippleColor}/30%),
                0 0 0 20px rgba(${rippleColor}/30%),
                0 0 0 40px rgba(${rippleColor}/ 0);
  }
`;

Speaker.ImageWrapper = styled(Box)`
	width: 120px;
	height: 120px;

	border-radius: 50%;

	position: relative;

	&:before {
		content: "";

		background-color: #35ffc3;

		width: 100%;
		height: 100%;

		position: absolute;
		top: 0;
		left: 0;

		border-radius: 50%;

		display: block;

		z-index: 2;
	}

	&.speaking {
		&:before {
			animation: ${ripple} 0.7s linear infinite;
		}
	}
`;

Speaker.Avatar = styled(Avatar)`
	position: relative;
	z-index: 5;

	border-radius: 50%;

	overflow: hidden;
`;

Speaker.NameChip = styled(Box)`
	padding: 1px 6px;

	position: absolute;
	left: 16px;
	bottom: 16px;

	border-radius: 6px;

	backdrop-filter: blur(20px);

	border: solid 1px rgba(255, 255, 255, 0.15);

	background-color: rgba(0, 0, 0, 0.32);

	display: flex;
	align-items: center;

	z-index: 5;
`;

Speaker.Name = styled(Text)`
	font-size: 12px;
	font-weight: 600;
	line-height: 1.33;
	color: #fff;
`;

Speaker.MuteStatusIconWrapper = styled(Box)`
	margin-left: 4px;
`;
