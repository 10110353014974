import React, { memo, useCallback, useMemo } from "react";

import { PlaceholderImage } from "shared/Components";
import { useMedia, usePersona, useUser } from "shared/hooks";

import { MediaModel, MediaType, PlaceholderImageType, ProfileType, UserModel } from "shared/types";

import { Content, PageContainer, ProfilePhotoWrapper, Title } from "./style";

import { InputField, UploaderWithCheck } from "../../Components";

const Profile = () => {
	// const { uploadFile } = useS3Uploader();
	const { setUser, getData: getUserData } = useUser();
	const { user } = getUserData();

	const { updatePersona, getData: getPersonaData } = usePersona();
	const { persona: communityPersona } = getPersonaData();

	const { uploadFiles } = useMedia();

	const persona = useMemo(() => {
		if (user) {
			return user.profiles?.length ? user.profiles[0] : ({} as ProfileType);
		}
		return {} as ProfileType;
	}, [user]);

	const userMapped = useMemo(() => {
		if (persona) {
			return {
				firstName: persona.firstName,
				lastName: persona.lastName,
				coverPhoto: persona.coverPhotos?.length
					? (persona.coverPhotos[0] as MediaModel)?.uri
					: communityPersona?.coverImage
					? window.URL.createObjectURL(communityPersona.coverImage)
					: undefined,
				profilePhoto: persona.photos?.length
					? (persona.photos[0] as MediaModel)?.profilePicture
					: communityPersona?.profileImage
					? window.URL.createObjectURL(communityPersona.profileImage)
					: undefined,
				email: user?.email,
				role: user?.role
			};
		}
	}, [persona, user, communityPersona]);

	const update = useCallback(
		async (updateObj: {}) => {
			const newPersona = { ...persona, ...updateObj };
			await updatePersona(newPersona);
			setUser({ ...user, profiles: [newPersona] } as UserModel);
		},
		[updatePersona, persona, user, setUser]
	);

	const updateImage = useCallback(
		async (type: MediaType, photo?: Partial<File>) => {
			if (type === MediaType.coverPhoto) {
				if (photo) {
					// const res = await uploadFile({
					// 	file: photo as File,
					// 	communityName: url
					// });
					// const coverPhotos = await createMedia(Number(persona.personaId), MediaType.coverPhoto, res?.publicUrl);
					const coverPhotos = await uploadFiles({
						files: [photo as File],
						type: MediaType.coverPhoto
					});
					update({ coverPhotos });
				} else {
					update({
						coverPhotos: []
					});
				}
			} else {
				if (photo) {
					// const res = await uploadFile({
					// 	file: photo as File,
					// 	communityName: url
					// });
					// const photos = await createMedia(Number(persona.personaId), MediaType.profilePhoto, res?.publicUrl);
					const photos = await uploadFiles({
						files: [photo as File],
						type: MediaType.profilePhoto
					});
					update({ photos });
				} else {
					update({
						photos: []
					});
				}
			}
		},
		[update, uploadFiles]
	);

	return (
		<PageContainer>
			<Title variant="h1">Profile details</Title>
			<Content>
				<UploaderWithCheck
					mediaType={MediaType.coverPhoto}
					updateImage={updateImage}
					value={userMapped?.coverPhoto}
					placeholderImg={<PlaceholderImage type={PlaceholderImageType.PROFILE_COVER} viewBox={"100 0 1112 400"} />}
				/>
				<ProfilePhotoWrapper>
					<UploaderWithCheck
						mediaType={MediaType.profilePhoto}
						type={"square"}
						updateImage={updateImage}
						height={120}
						value={userMapped?.profilePhoto}
						placeholderImg={
							<PlaceholderImage
								type={PlaceholderImageType.PROFILE_IMAGE}
								width={120}
								height={120}
								viewBox={"0 0 400 400"}
							/>
						}
					/>
				</ProfilePhotoWrapper>
				<InputField
					onSubmit={update}
					defaultValue={userMapped?.firstName}
					name="firstName"
					label="First name"
					icon="user-edit"
					iconProps={{ group: "filled" }}
				/>
				<InputField
					onSubmit={update}
					defaultValue={userMapped?.lastName}
					name="lastName"
					label="Last name"
					icon="user-edit"
					iconProps={{ group: "filled" }}
				/>
				<InputField
					defaultValue={userMapped?.role}
					disabled={true}
					label="Role"
					icon="users-crown-gray"
					iconProps={{ group: "color" }}
				/>
				<InputField defaultValue={userMapped?.email} disabled={true} label="Email address" icon="email" />
			</Content>
		</PageContainer>
	);
};

export default memo(Profile);
