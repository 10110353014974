import { useMemo } from "react";

import { DateTime } from "luxon";

import { API_URLS } from "shared/constants";
import useHttpActions from "shared/services/api/core/useHttpActions";
import BaseConverter from "shared/services/converters/baseConverter";
import {
	EventModel,
	EventSearchParams,
	GetSearchEventsTotalType,
	Location,
	LocationDetail,
	MembersModel
} from "shared/types";

import { EventFormData, EventRequests, LabelValueType } from "shared/types/EventModel";

import { convertCategories, convertCreateUpdateEventRequest } from "./converters/EventConverter";

import { DefaultResponseType, EventType, UserEventType } from "../../types";
import { useLocationApiService } from "./index";

const useEventApiService = (isMarketing = false) => {
	const params = useMemo(() => (isMarketing ? { customErrorHandlers: [] } : undefined), [isMarketing]);

	const actions = useHttpActions(params);
	const locationService = useLocationApiService();

	const methods = useMemo(
		() => ({
			getEvent: async (eventId: string) => {
				return BaseConverter.convert<EventModel>(await actions.get(API_URLS.EVENTS.GET, { eventId }));
			},
			getEventsWithRequests: async (offset: number, limit: number) => {
				return BaseConverter.convert<{ events: EventRequests[] }>(
					await actions.get(API_URLS.EVENTS.GET_WITH_REQUESTS, {
						offset,
						limit
					})
				);
			},
			getEventAdmins: async (eventId: string) => {
				return BaseConverter.convert<{
					personas: {
						personas: MembersModel[];
					};
				}>(
					await actions.get(API_URLS.EVENTS.EVENT_PERSONAS, {
						eventId,
						limit: 5,
						filterBy: "admins",
						connectedOnly: false
					})
				);
			},
			inviteToEvent: async (eventId: string, personaIds: number[]) => {
				return BaseConverter.convert<{ success: boolean; invited: number[] }>(
					await actions.put(API_URLS.EVENTS.INVITE_TO_EVENT, {
						eventId,
						personaIds
					})
				);
			},
			prepareGetQuery: ({
				offset = 1,
				limit = 12,
				keyword,
				groupId,
				liveConversationIds,
				eventType,
				eventSchedule,
				sortBy = "createdAt",
				sortOrder,
				categories = [],
				startDate,
				endDate
			}: EventSearchParams) => {
				const query: {
					radius: number;
					lat: number;
					lon: number;
					purpose: string;
					potentialMatchingIds?: never[];
					sortBy: string;
					sortOrder?: number;
					offset: number;
					limit: number;
					keyword?: string;
					happeningNowTime?: string;
					startDate?: string;
					endDate?: string;
					startTime?: string | number;
					eventType?: UserEventType;
					groupId?: string;
					liveConversationIds?: string[];
					categories?: string[];
				} = {
					radius: 0,
					lat: 0,
					lon: 0,
					purpose: "business",
					potentialMatchingIds: [],
					sortBy,
					sortOrder,
					offset,
					limit,
					keyword,
					groupId,
					liveConversationIds,
					eventType,
					categories
				};

				if (startDate || endDate) {
					query.startDate = startDate;
					query.endDate = endDate;
				} else {
					switch (eventSchedule) {
						case EventType.Happening:
							query.happeningNowTime = DateTime.now().toISO();
							break;

						case EventType.Upcoming:
							query.endDate = DateTime.now().plus({ years: 10 }).toFormat("yyyy-LL-dd");
							break;

						case EventType.Past:
							query.startTime = 1;
							query.eventType = "past";
							break;

						case EventType.All:
							break;

						default:
							query.startTime = 1;
					}
				}

				if (!query.eventType) {
					query.eventType = "all";
				}

				return query;
			},
			getCategories: async () => {
				return convertCategories(await actions.get(API_URLS.EVENTS.GET_CATEGORIES));
			},
			getEvents: async ({
				offset,
				limit,
				keyword = "",
				eventSchedule,
				groupId,
				liveConversationIds,
				eventType,
				sortBy,
				sortOrder,
				categories,
				startDate,
				endDate,
				isMyEvents
			}: EventSearchParams) => {
				const query = methods.prepareGetQuery({
					offset,
					limit,
					keyword,
					eventSchedule,
					groupId,
					liveConversationIds,
					eventType,
					sortBy,
					sortOrder,
					categories,
					startDate,
					endDate,
					isMyEvents
				});
				return BaseConverter.convert<GetSearchEventsTotalType>(await actions.post(API_URLS.EVENTS.GET_ALL, query));
			},
			getUserEvents: async (offset, limit, personaId, potentialMatchingIds) => {
				return BaseConverter.convert<{
					hosted: EventModel[];
					interested: EventModel[];
					going: EventModel[];
					past: EventModel[];
				}>(
					await actions.post(API_URLS.EVENTS.GET_MY, {
						lat: 0,
						lon: 0,
						offset,
						limit,
						personaId,
						potentialMatchingIds
					})
				);
			},
			createEvent: async (data: EventFormData) => {
				const { location, isMemberView, isOnlineEvent } = data;

				let placeDetail: LocationDetail | undefined;

				if (location && !isOnlineEvent) {
					placeDetail = await locationService.getLocationDetails(
						isMemberView
							? (location as LabelValueType)?.value || (location as Location).place_id
							: (location as Location).place_id
					);
				}

				const event = BaseConverter.convert<EventModel>(
					await actions.post(API_URLS.EVENTS.CREATE, convertCreateUpdateEventRequest(data, placeDetail))
				);

				await methods.setIntent(event.eventId);
				return event;
			},
			updateEvent: async (data: Partial<EventFormData>) => {
				const { location, isMemberView, isOnlineEvent } = data;

				let placeDetail: LocationDetail | undefined;

				if (location && !isOnlineEvent) {
					placeDetail = await locationService.getLocationDetails(
						(location as Location).placeId
							? (location as Location).placeId
							: isMemberView
							? (location as LabelValueType).value || (location as Location).place_id
							: (location as Location).place_id
					);
				}

				return BaseConverter.convert<EventModel>(
					await actions.put(API_URLS.EVENTS.UPDATE, convertCreateUpdateEventRequest(data as EventFormData, placeDetail))
				);
			},
			setIntent: async (eventId: string) => {
				await actions.post(API_URLS.EVENTS.SET_INTENT, {
					eventId,
					interested: false,
					attending: true
				});
			},
			deleteEvent: async (id: string) => {
				return BaseConverter.convert<DefaultResponseType>(
					await actions.delete(API_URLS.EVENTS.DELETE, {
						eventId: id
					})
				);
			},
			setEventIntent: async (attending: boolean, eventId: string, interested: boolean) => {
				return BaseConverter.convert(
					await actions.post(API_URLS.EVENTS.SET_INTENT, {
						eventId,
						interested,
						attending
					})
				);
			}
		}),
		[actions, locationService]
	);

	return useMemo(
		() => ({
			...methods
		}),
		[methods]
	);
};

export default useEventApiService;
