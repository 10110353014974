import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from "react";

import { IconButton, useMediaQuery } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Slider from "react-slick";

import styled, { css } from "styled-components";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { ReactComponent as LeftArrowIcon } from "assets/icons/icon-arrow-left-bold.svg";
import { ReactComponent as RightArrowIcon } from "assets/icons/icon-arrow-right-bold.svg";

import { useUser } from "shared/hooks";
import { Box, Text } from "shared/ui-kit";

import * as appTheme from "theme/default";

import { ItemTypes, localItemType } from "./ImageVideoBlock/ImageVideoBlock";

import { Dialog } from "../index";

const Header = styled(Box)`
	display: flex;
	justify-content: space-between;
	align-items: center;

	padding: 10px 16px;

	width: 100%;
	height: 56px;

	border-bottom: 1px solid #edf1f7;

	${props => props.theme.breakpoints.down("sm")} {
		border-bottom: none;

		height: 104px;

		padding-bottom: 24px;

		position: relative;
		z-index: 5;
	}
`;

const ProgressTest = styled(Text)`
	font-size: 18px;
	font-weight: bold;

	${props => props.theme.breakpoints.down("sm")} {
		color: white;
	}
`;

const CloseIconWrapper = styled(CloseIcon)`
	${props => props.theme.breakpoints.down("sm")} {
		path {
			fill: white;
		}
	}
`;

const SliderWrapper = styled(Box)`
	padding: 20px 70px;

	overflow: hidden;

	${props => props.theme.breakpoints.down("sm")} {
		height: 100vh;

		display: flex;
		flex-direction: column;
		justify-content: center;

		margin: -104px 0 0 0;
		padding: 0;

		position: relative;

		&:after {
			content: "";
			position: absolute;
			top: 0;
			left: 0;

			width: 100%;
			height: 45px;

			z-index: 2;

			box-shadow: inset 0 30px 20px -7px black;
		}

		.slick {
			&-slider {
				height: 100%;
			}

			&-list {
				height: 100%;
			}

			&-track {
				height: 100%;
			}

			&-slide {
				height: 100%;
				& > div {
					height: 100%;
				}
			}
		}
	}
`;

const Image = styled(props => <Box {...props} />)<{ uri: string }>`
	width: 100%;
	height: 500px;
	background-image: url("${({ uri }) => uri}");
	background-size: contain;
	border-radius: 4px;
	background-position: center;
	background-repeat: no-repeat;

	${props => props.theme.breakpoints.down("sm")} {
		height: 100%;
	}
`;

const VideoBlock = styled(props => <Box {...props} />)`
	width: 100%;
	//height: 250px;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;

	video {
		width: 100%;
	}
`;

const NavIcon = styled(Box)<{ isNext: boolean }>`
	width: 40px;
	height: 40px;

	display: flex;
	align-items: center;
	justify-content: center;

	cursor: pointer;

	border-radius: 50%;
	border: solid 1px #c5cee0;

	position: absolute;
	top: calc(50% - 20px);

	${({ isNext }) =>
		isNext
			? css`
					right: -40px;
			  `
			: css`
					left: -40px;
			  `}
`;

const PrevArrow = ({ onClick }: any) => (
	<NavIcon onClick={onClick} isNext={false}>
		<LeftArrowIcon />
	</NavIcon>
);

const NextArrow = ({ onClick }: any) => (
	<NavIcon onClick={onClick} isNext={true}>
		<RightArrowIcon />
	</NavIcon>
);

interface Props {
	fileList: localItemType[];
	open?: boolean;
	defaultIndex?: number;
	onClose?: (open: boolean) => void;
	highzIndex?: boolean;
}

const ImageSlider: React.FC<Props> = ({ open, fileList, defaultIndex, onClose, highzIndex }) => {
	const sliderRef = useRef<any>();

	const { getData: getUserData } = useUser();
	const { isMemberView } = getUserData();

	const [sliderIndex, setSliderIndex] = useState(defaultIndex ? defaultIndex + 1 : 1);
	const isMobile = useMediaQuery(appTheme.default.breakpoints.down("sm"));

	useEffect(() => {
		setSliderIndex((defaultIndex || 0) + 1);
		if (sliderRef && sliderRef.current) {
			sliderRef.current!.slickGoTo(defaultIndex);
		}
	}, [defaultIndex, sliderRef]);

	useEffect(() => {
		if (open) {
			setTimeout(() => {
				if (sliderRef?.current) {
					(document.getElementById(`video_${defaultIndex || 0}`) as HTMLVideoElement)?.play();
					sliderRef.current.innerSlider.list.setAttribute("tabindex", 0);
					sliderRef.current.innerSlider.list.focus();
				}
			}, 100);
		}
	}, [open, sliderRef, defaultIndex]);

	const settings = useMemo(() => {
		return {
			dots: false,
			infinite: true,
			speed: 0,
			lazyLoad: true,
			slidesToShow: 1,
			slidesToScroll: 1,
			swipeToSlide: true,
			arrows: !isMobile,
			nextArrow: isMobile ? undefined : <NextArrow />,
			prevArrow: isMobile ? undefined : <PrevArrow />,
			initialSlide: defaultIndex,
			beforeChange: oldIndex => {
				(document.getElementById(`video_${oldIndex}`) as HTMLVideoElement)?.pause();
			},
			afterChange: index => {
				(document.getElementById(`video_${index}`) as HTMLVideoElement)?.play();
				setSliderIndex(index + 1);
			}
		};
	}, [defaultIndex, isMobile]);

	const handleClose = useCallback(() => {
		onClose && onClose(false);
	}, [onClose]);

	return (
		<Dialog
			title={"Slider"}
			open={!!open}
			onClose={handleClose}
			hasBackButton={false}
			showHead={false}
			maxWidth={"md"}
			fullScreenMode={isMobile}
			isMemberView={isMemberView}
			highzIndex={highzIndex}
			bodyCustomStyles={css`
				padding: 0 !important;
				overflow: hidden;
			`}
		>
			<Header>
				<ProgressTest variant="h5">
					{sliderIndex} of {fileList.length}
				</ProgressTest>
				{onClose ? (
					<IconButton aria-label="close" onClick={handleClose}>
						<CloseIconWrapper />
					</IconButton>
				) : null}
			</Header>
			<SliderWrapper>
				<Slider {...settings} ref={sliderRef}>
					{fileList.map((item, index) => (
						<Fragment key={index}>
							{item.type === ItemTypes.image && <Image uri={item.url} />}
							{item.type === ItemTypes.video && (
								<VideoBlock>
									<video controls id={`video_${index}`}>
										<source src={item.url} type="video/mp4" />
										Your browser does not support the video tag.
									</video>
								</VideoBlock>
							)}
						</Fragment>
					))}
				</Slider>
			</SliderWrapper>
		</Dialog>
	);
};

export default ImageSlider;
