import React, { FC, LegacyRef, useCallback, useEffect, useMemo, useRef, useState } from "react";

import { Box, TextField } from "@material-ui/core";

import Autocomplete from "@material-ui/lab/Autocomplete";
import { Controller, useForm } from "react-hook-form";

import { ReactComponent as DownloadIcon } from "assets/icons/icon-download.svg";
import {
	EnableFeature,
	LabeledInput,
	MemberDialog,
	MultiselectInput,
	RectangleUploader
} from "modules/MemberHome/View/shared";
import { LabelHeadline } from "modules/MemberHome/View/shared/MultiselectInput/style";
import { MemberDialogContentWrapper } from "modules/MemberHome/View/shared/style";

import { useAlbums, useDebounce, useTrack, useUser } from "shared/hooks";

import useScrollToXPosition from "shared/hooks/useScrollToXPosition";
import { useS3Uploader } from "shared/services/s3Uploader";
import { CrudType, TrackModel, genres } from "shared/types";
import { Icon } from "shared/ui-kit";

import { InputWrapper } from "./style";

import { SetupPrice } from "..";

interface Props {
	open: boolean;
	submitTrack: (data: any) => Promise<void>;
	onClose: () => void;
	onUploaderChange: (files: FileList, onChange: (...event: any[]) => void) => Promise<void>;
	uploading: boolean;
	editableModel?: Partial<TrackModel>;
	album?: {
		value: string;
		label: string;
	};
	trackFileUrl: string | null;
}

const NewTracksModel: FC<Props> = ({
	open,
	submitTrack,
	onClose,
	uploading,
	onUploaderChange,
	editableModel,
	album,
	trackFileUrl
}) => {
	const formRef = useRef<HTMLFormElement>();
	const scrollToXPositionOnFocus = useScrollToXPosition(formRef?.current?.parentElement);
	const {
		control,
		formState: { isDirty, errors, isValid },
		handleSubmit
	} = useForm({ mode: "onChange" });

	const { getData: getUserData } = useUser();
	const { user } = getUserData();

	const { getData: getTrackData } = useTrack();
	const { trackName, creating } = getTrackData();

	const { getData: getS3UploaderData } = useS3Uploader();
	const { uploadingProgress } = getS3UploaderData();

	const { getAlbums, isUserCouldManageAlbum, getData: getAlbumsData } = useAlbums();
	const { albums } = getAlbumsData();

	const [keyword, setKeyword] = useState("");

	const debouncedSearchText = useDebounce(keyword, 400);

	useEffect(() => {
		if (open) {
			getAlbums({ limit: 10, offset: 1, keyword: debouncedSearchText });
		}
	}, [debouncedSearchText, getAlbums, open]);

	const albumsList = useMemo(
		() =>
			(albums || []).map(album => ({
				value: album._id,
				label: album.title
			})),
		[albums]
	);

	type SetupPriceMethodsHandlers = React.ElementRef<typeof SetupPrice>;
	const setupPriceFormRef = useRef<SetupPriceMethodsHandlers>(null);

	const showPremiumAccess = useMemo(
		() => user?.isGlobalAdmin || user?.isGlobalOwner || user?.isGlobalModerator,
		[user]
	);

	useEffect(() => {
		if (user?.personaDocId) {
			getAlbums({ offset: 1, creatorId: user.personaDocId });
		}
	}, [getAlbums, user]);

	const onSubmit = useCallback(
		async data => {
			delete data.priceChanged;

			const priceTags = setupPriceFormRef.current?.getPriceTags();
			await submitTrack({ ...data, priceTags });
		},
		[submitTrack]
	);

	const customHandleSubmit = useCallback(
		e => {
			e?.preventDefault && e.preventDefault();
			e?.stopPropagation && e.stopPropagation();
			handleSubmit(onSubmit)();
		},
		[handleSubmit, onSubmit]
	);

	return (
		<MemberDialog
			customWidth={512}
			uploadingProgress={uploadingProgress}
			title="Create a Track"
			open={open}
			onClose={onClose}
			confirmLeave
			footerPrimary={{
				text: "Create",
				disabled: !isDirty || !!Object.keys(errors).length || uploading || !isValid || !trackFileUrl || creating,
				onClick: handleSubmit(onSubmit)
			}}
		>
			<form onSubmit={customHandleSubmit} ref={formRef as LegacyRef<HTMLFormElement>}>
				<Controller
					name="trackImageUrl"
					control={control}
					render={({ onChange }) => (
						<InputWrapper>
							<RectangleUploader
								onChange={files => onUploaderChange(files, onChange)}
								uploading={uploading}
								errorText={errors?.photo?.message}
							/>
						</InputWrapper>
					)}
				/>
				<MemberDialogContentWrapper>
					<Controller
						name="title"
						control={control}
						defaultValue={trackName}
						rules={{
							required: "Track title is required",
							maxLength: {
								value: 100,
								message: "Title should not be more than 100 letters"
							}
						}}
						render={({ onChange, value, ref }) => (
							<InputWrapper>
								<LabeledInput
									label="Track title *"
									placeholder=" "
									onChange={onChange}
									value={value}
									name="title"
									maxLength={100}
									showCounter={100}
									error={errors?.title?.message}
									inputRef={ref}
								/>
							</InputWrapper>
						)}
					/>
					<Controller
						name="artist"
						control={control}
						rules={{
							required: "Artist is required"
						}}
						render={({ onChange, value, ref }) => (
							<InputWrapper>
								<LabeledInput
									label="Artist *"
									placeholder=" "
									onChange={onChange}
									value={value}
									name="artist"
									error={errors?.artist?.message}
									inputRef={ref}
								/>
							</InputWrapper>
						)}
					/>
					<Controller
						name="genre"
						control={control}
						rules={{
							required: "Genre is required"
						}}
						render={({ onChange, value }) => (
							<InputWrapper onFocus={scrollToXPositionOnFocus}>
								<MultiselectInput value={value} onChange={onChange} options={genres} error={errors?.genre?.message} />
							</InputWrapper>
						)}
					/>
					<Controller
						name="description"
						control={control}
						render={({ onChange, value, ref }) => (
							<InputWrapper>
								<LabeledInput
									label="Description"
									placeholder=" "
									onChange={onChange}
									value={value}
									name="description"
									maxLength={1000}
									showCounter={1000}
									textarea
									inputRef={ref}
								/>
							</InputWrapper>
						)}
					/>
					{isUserCouldManageAlbum({
						type: editableModel ? CrudType.UPDATE : CrudType.CREATE,
						isCreator: editableModel?.creator
					}) && (
						<Controller
							name="album"
							control={control}
							defaultValue={album ? (Array.isArray(album) ? album : [album]) : []}
							render={({ onChange, value }) => (
								<InputWrapper>
									<Autocomplete
										multiple
										id="selectAlbum"
										options={albumsList}
										getOptionLabel={(option: { label: string }) => option.label}
										onChange={(_, newValue) => onChange(newValue)}
										filterSelectedOptions
										value={value}
										className="mb-5"
										renderInput={params => (
											<>
												<LabelHeadline>Select album</LabelHeadline>
												<TextField {...params} variant="outlined" onChange={e => setKeyword(e.target.value)} />
											</>
										)}
										onFocus={scrollToXPositionOnFocus}
									/>
								</InputWrapper>
							)}
						/>
					)}
					{showPremiumAccess && (
						<InputWrapper>
							<Controller
								name="priceChanged"
								control={control}
								render={({ onChange }) => (
									<SetupPrice
										ref={setupPriceFormRef}
										defaultValue={editableModel?.priceTags}
										onPriceChanged={() => onChange(true)}
									/>
								)}
							/>
						</InputWrapper>
					)}
					<Controller
						name="downloadable"
						control={control}
						render={({ onChange, value }) => (
							<InputWrapper>
								<EnableFeature
									icon={
										<Box className="fill-second-path">
											<DownloadIcon />
										</Box>
									}
									fill="#c5cee0"
									title="Allow Download"
									onChange={onChange}
									value={value}
								/>
							</InputWrapper>
						)}
					/>
					<Controller
						name="private"
						control={control}
						render={({ onChange, value }) => (
							<InputWrapper>
								<EnableFeature
									icon={<Icon name="eye" group="filled" width={24} height={24} fill="#c5cee0" />}
									title="Private"
									description="Only invited members will be able to find this track"
									onChange={onChange}
									value={value}
									verticalPadding
								/>
							</InputWrapper>
						)}
					/>
				</MemberDialogContentWrapper>
			</form>
		</MemberDialog>
	);
};

export default NewTracksModel;
