import React from "react";

import styled, { css } from "styled-components";

import { ReactComponent as LockIcon } from "assets/icons/lock.svg";
import { Box, Icon, Radio } from "shared/ui-kit";
import theme from "theme/default";

const Wrapper = styled(Box)`
	display: flex;
	flex-direction: row;

	${props => props.theme.breakpoints.down("sm")} {
		margin-right: 0;
		flex-direction: column;
	}
`;

const RadioWrapper = styled(Box)<{ withRightMargin: boolean }>`
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 0 10px 0 20px;
	background-color: #f7f9fc;
	height: 48px;
	border-radius: 4px;
	flex: 1;
	border: 0.5px solid #c5cee0;

	${({ withRightMargin }) => withRightMargin && "margin-right: 20px;"}

	${props => props.theme.breakpoints.down("sm")} {
		margin: 5px 0 0;
	}
`;

const StyledRadio = styled(Radio)<{ color?: string }>`
	margin-right: 10px;
	padding-right: 0;

	${({ color }) =>
		color &&
		css`
			svg {
				path {
					fill: ${color};
				}
			}
		`}
`;

const StyledLockIcon = styled(LockIcon)<{ fill?: string }>`
	${({ fill }) =>
		fill &&
		css`
			> g {
				> path {
					fill: ${fill};
				}
			}
		`}
`;

interface PrivacyBlockProps {
	color?: string;
	isPrivate: boolean;
	setIsPrivate: (isPrivate: boolean) => void;
}

const Privacy: React.FC<PrivacyBlockProps> = ({ isPrivate = false, setIsPrivate, color, ...props }): JSX.Element => (
	<Wrapper {...props}>
		<RadioWrapper withRightMargin>
			<StyledRadio
				color={color}
				value={isPrivate ? "private" : "public"}
				onChange={() => setIsPrivate(false)}
				options={[{ label: "Public", value: "public" }]}
				id={"public"}
			/>
			<Icon fill={color || theme.palette.primary.main} name={"globe"} group="linear" />
		</RadioWrapper>
		<RadioWrapper>
			<StyledRadio
				color={color}
				value={isPrivate ? "private" : "public"}
				onChange={() => setIsPrivate(true)}
				options={[{ label: "Private", value: "private" }]}
				id={"private"}
			/>
			<StyledLockIcon fill={color} />
		</RadioWrapper>
	</Wrapper>
);

export default Privacy;
