import styled from "styled-components";

import { Box, Text } from "shared/ui-kit";

import { customBreakpoints } from "theme/default";

export const Wrapper = styled(Box)`
	display: flex;
	flex-direction: column;

	width: 100%;
`;

export const BodyWrapper = styled(Box)`
	padding: 34px 25px 1px 25px;

	box-shadow: inset 0 1px 0px -0.5px #c5cee0;
`;

export const Metric = styled(Box);

Metric.Item = styled(Box)`
	width: 33.3%;
	height: 40px;

	display: inline-flex;
	align-items: center;
	vertical-align: top;

	margin-bottom: 31px;

	${props => props.theme.breakpoints.down(customBreakpoints.tabletHorizontalMax)} {
		width: 50%;
	}

	${props => props.theme.breakpoints.down("sm")} {
		width: 100%;
	}
`;

Metric.ItemIconWrapper = styled(Box)`
	width: 58px;
	height: 58px;

	display: flex;
	justify-content: center;
	align-items: center;

	margin-right: 12px;

	&.with-icon {
		background: #f7f9fc;

		border-radius: 50%;
	}
`;

Metric.ValueWrapper = styled(Box)`
	display: block;
`;

Metric.ValueRow = styled(Box)`
	display: flex;
	align-items: center;
`;

Metric.Value = styled(Text)`
	font-size: 15px;
	font-weight: 600;
	line-height: 1.6;
	color: #222b45;
`;

Metric.Label = styled(Text)`
	font-size: 12px;
	font-weight: normal;
	line-height: 1.4;
	color: #8f9bb3;
`;

export const Progress = styled(Box)``;

Progress.Wrapper = styled(Box)<{ size: number }>`
	display: flex;
	justify-content: center;
	align-items: center;

	width: ${({ size }) => size}px;
	height: ${({ size }) => size}px;

	position: relative;

	cursor: pointer;

	.progress-ring__circle {
		transition: 0.2s stroke-dashoffset;
		// axis compensation
		transform: rotate(-90deg);
		transform-origin: 50% 50%;
	}
`;

Progress.Text = styled(Box)`
	position: absolute;

	left: 0;
	top: calc(50% - 6px);

	width: 100%;
	height: 15px;

	font-size: 13px;
	font-weight: bold;
	line-height: 1.2;
	text-align: center;
	color: #6173fe;
`;
