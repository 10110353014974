import React, { useCallback, useMemo, useState } from "react";

import { Link } from "react-router-dom";

import { Btn } from "modules/MemberHome/View/shared/style";
import { PlaceholderImage } from "shared/Components";
import { useGroup, useMemberRoutes, useUser } from "shared/hooks";
import { GroupModel, PlaceholderImageType, ProfilePhotoSizes } from "shared/types";

import { getResizedImage } from "utils/serviceUtils/cdnImages";
import { formatCount } from "utils/serviceUtils/helpers";

import { ModelItem } from "../index";

interface GroupCardProps {
	group: GroupModel;
	index: number;
	idPrefix?: string;
	isPotentialMatches?: boolean;
	wrapByHref?: boolean;
	chatThumbnail?: boolean;
	messageBlockCurrentUser?: boolean;
}

const GroupCard: React.FC<GroupCardProps> = ({
	group,
	index,
	idPrefix,
	isPotentialMatches = false,
	wrapByHref,
	chatThumbnail,
	messageBlockCurrentUser
}) => {
	const { getData: getUserData } = useUser();
	const { user } = getUserData();
	const { joinGroup } = useGroup();
	const { getMemberRoutesData } = useMemberRoutes();
	const { routes } = getMemberRoutesData();

	const [submitting, setSubmitting] = useState(false);
	const [joined, setJoined] = useState(false);

	const isMember = useMemo(
		() => group?.roles?.isMember || group?.roles?.isOwner || group?.roles?.isAdmin || group?.roles?.isModerator,
		[group]
	);

	const isGroupLocked = useMemo(() => group?.locked || false, [group?.locked]);

	const handleJoin = useCallback(async () => {
		if (!user) return;

		setSubmitting(true);
		await joinGroup({
			slug: group.slug,
			personaId: user.activeProfile || user.profiles[0].personaId,
			isPrivate: group.private === 1
		});

		setSubmitting(false);
		setJoined(true);
	}, [group, joinGroup, user]);

	const handleClick = useCallback(
		(isView?: boolean) => {
			if (!isView && !group?.roles?.isPending) {
				handleJoin();
			}
		},
		[handleJoin, group]
	);

	const JoinBtn = useMemo(() => {
		const optionalProps: Record<string, unknown> = {};
		if (isMember || joined || isGroupLocked) {
			optionalProps.to = `${routes?.member.group.getPath()}/${group.slug}`;
			optionalProps.component = Link;
		}

		return (
			<Btn
				{...optionalProps}
				removeSideMargin
				fullWidth
				size="medium"
				buttonTheme="main"
				palette="primary"
				onClick={() => handleClick(isMember || joined || isGroupLocked)}
				disabled={submitting}
				id={
					isMember || joined || group?.roles?.isPending || isGroupLocked
						? `${idPrefix ? `${idPrefix}_` : ""}view_${index + 1}`.replaceAll(" ", "")
						: `${idPrefix ? `${idPrefix}_` : ""}joinNow_${index + 1}`.replaceAll(" ", "")
				}
			>
				{isMember || joined || group?.roles?.isPending || isGroupLocked
					? "View"
					: submitting
					? "Joining.."
					: "Join Now"}
			</Btn>
		);
	}, [
		isMember,
		joined,
		isGroupLocked,
		submitting,
		group?.roles?.isPending,
		group.slug,
		idPrefix,
		index,
		routes?.member.group,
		handleClick
	]);

	return (
		<ModelItem
			item={{
				_id: group._id,
				wrapByHref: wrapByHref,
				href: `${routes?.member.group.getPath()}/${group.slug}`,
				img: group.coverUrl,
				placeholderImg: (
					<PlaceholderImage
						type={PlaceholderImageType.GROUP_DETAILS}
						width={360}
						height={160}
						viewBox={"0 0 720 320"}
					/>
				),
				beforeTitle: group.private ? "Private Group" : "Public Group",
				title: group.name,
				subtitle: group.location,
				peopleList:
					isPotentialMatches && !!group?.potentialMatches?.length
						? group.potentialMatches.map(x =>
								x?.photos?.length
									? getResizedImage(x.photos[0].profilePicture as string, ProfilePhotoSizes.size50x50)
									: ""
						  )
						: [],
				peopleListText:
					isPotentialMatches && group?.totalPotentialMatches
						? Number(group.totalPotentialMatches) > 4
							? `+ ${formatCount(Number(group.totalPotentialMatches), "member")}`
							: undefined
						: undefined,
				action: wrapByHref ? undefined : JoinBtn,
				locked: group?.locked,
				isWrapBorder: wrapByHref,
				chatThumbnail: chatThumbnail,
				chatCurrentUser: messageBlockCurrentUser
			}}
		/>
	);
};

export default GroupCard;
