import React, { ReactNode } from "react";

import clsx from "clsx";

import { Item, Wrapper } from "./style";

interface RadioItemProps {
	label: string;
	control: any;
	value: string | boolean;
	icon?: ReactNode;
	helpText?: string;
	isControlLeftSide?: boolean;
	isActive?: boolean;
}

const RadioItem: React.FC<RadioItemProps> = ({
	label,
	control,
	value,
	icon,
	helpText,
	isControlLeftSide = false,
	isActive = false
}) => (
	<Wrapper className={clsx(!helpText && "vertical-center", isActive ? "active" : "")}>
		{isControlLeftSide && <Item.ActionColumn>{React.cloneElement(control, { value })}</Item.ActionColumn>}
		<Item.InfoColumn>
			<Item.IconWrapper>{icon}</Item.IconWrapper>
			<Item.TextBlock>
				<Item.Title>{label}</Item.Title>
				<Item.Description>{helpText}</Item.Description>
			</Item.TextBlock>
		</Item.InfoColumn>
		{!isControlLeftSide && <Item.ActionColumn>{React.cloneElement(control, { value })}</Item.ActionColumn>}
	</Wrapper>
);

export default RadioItem;
