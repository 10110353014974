import React from "react";

import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";
import styled from "styled-components";

import { useDebounce } from "shared/hooks";

const DarkTooltip = withStyles(() => ({
	tooltip: {
		backgroundColor: "#232f3e",
		color: "White",
		boxShadow: "none",
		fontSize: 12,
		fontWeight: "normal",
		padding: "4px 8px",
		borderRadius: "4px",
		lineHeight: "1.33",
		marginTop: "1px"
	}
}))(Tooltip);

const ChildrenWrapper = styled.div`
	display: inline-block;
`;

export type TooltipProps = {
	text: string;
	children: any;
	enabled?: boolean;
	placement?:
		| "bottom-end"
		| "bottom-start"
		| "bottom"
		| "left-end"
		| "left-start"
		| "left"
		| "right-end"
		| "right-start"
		| "right"
		| "top-end"
		| "top-start"
		| "top";
	closeOnClick?: boolean;
	debounce?: number;
};

export default ({
	text = "",
	children,
	enabled = true,
	placement = "bottom",
	closeOnClick = false,
	debounce = 0
}: TooltipProps): JSX.Element => {
	const [open, setOpen] = React.useState(false);

	const handleClose = () => {
		setOpen(false);
	};

	const handleOpen = () => {
		setOpen(!!enabled);
	};

	const show = useDebounce(open, debounce);

	// open={open && show} <- this allow to show tooltip with delay if we debounce it, but hide immediately
	return (
		<DarkTooltip open={open && show} onClose={handleClose} onOpen={handleOpen} title={text} placement={placement}>
			<ChildrenWrapper onClick={() => closeOnClick && handleClose()}>{children}</ChildrenWrapper>
		</DarkTooltip>
	);
};
