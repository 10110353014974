import { useMemo } from "react";

import { API_URLS } from "shared/constants";
import useHttpActions from "shared/services/api/core/useHttpActions";
import BaseConverter from "shared/services/converters/baseConverter";

import { Location, LocationDetail } from "shared/types";

const useLocationApiService = () => {
	const actions = useHttpActions();

	return useMemo(
		() => ({
			getLocationDetails: async place_id => {
				return BaseConverter.convert(
					await actions.get<LocationDetail>(API_URLS.LOCATIONS.GET_DETAILS, {
						placeId: place_id,
						locationId: "undefined",
						provider: "google"
					})
				);
			},
			getLocationSuggestions: async (query: string, { lat = 1, lon = 1 }: { lat?: number; lon?: number }) => {
				return BaseConverter.convert(
					await actions.get<Location[]>(API_URLS.LOCATIONS.GET_SUGGESTIONS, {
						types: "",
						provider: "google",
						query,
						lat,
						lon,
						radius: 1
					})
				);
			}
		}),
		[actions]
	);
};

export default useLocationApiService;
