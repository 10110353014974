import { useCallback, useMemo } from "react";

import { STORIES_OPEN_TYPE } from "shared/contexts/StoryContext/StoryContext";

import { useStoryApiService } from "shared/services";
import { PersonStoryModel } from "shared/types";

import { useStoryStore } from "../contexts";

import { useUser } from "./index";

const useStory = () => {
	const store = useStoryStore();
	const { setState } = useStoryStore();

	const service = useStoryApiService();

	const { getData: getUserData } = useUser();
	const { user } = getUserData();

	const methods = useMemo(
		() => ({
			getPersonsStories: async (offset: number, limit: number) => {
				const personsStoryList = await service.getPersonsStories(offset, limit, user?.activeProfile);
				setState(ctx => ({ ...ctx, personsStoryList: personsStoryList.stories }));
			},
			watchStory: async (publisherPersonaId: string, storyId: string, type: string) => {
				await service.watchStory({
					publisherPersonaId,
					storyId,
					type,
					viewerPersonaId: user?.activeProfile
				});
			},
			getMyStories: async () => {
				if (user?.activeProfile) {
					const { stories } = await service.getMyStories(user.activeProfile);
					return stories;
				}
			},
			populateStories: stories => {
				setState(ctx => ({ ...ctx, personsStoryList: [...stories, ...ctx.personsStoryList] }));
			},
			openStories: ({
				isOpen,
				storyId,
				slideId,
				dontRequestdata
			}: {
				isOpen: STORIES_OPEN_TYPE;
				storyId?: string;
				slideId?: string;
				dontRequestdata?: boolean;
			}) => {
				setState(ctx => ({ ...ctx, isOpen, defaultStoryId: storyId, slideId, pauseStory: false, dontRequestdata }));
			},
			setCurrentStorySlide(index: number) {
				setState(ctx => ({ ...ctx, currentStorySlide: index }));
			},
			setIsOpen(isOpen?: STORIES_OPEN_TYPE) {
				setState(ctx => ({ ...ctx, isOpen }));
			},
			setPauseStory(pauseStory: boolean) {
				setState(ctx => ({ ...ctx, pauseStory }));
			},
			setMuteStory(muteStory: boolean) {
				setState(ctx => ({ ...ctx, muteStory }));
			},
			setReportDialog(reportDialog?: { reporteeId: number; reportType: string; reason?: string }) {
				setState(ctx => ({ ...ctx, reportDialog }));
			},
			setRemovePersonConnection(removePersonConnection?: { connectionId: string; name: string }) {
				setState(ctx => ({ ...ctx, removePersonConnection }));
			},
			setPersonsStoryList(personsStoryList: PersonStoryModel[]) {
				setState(ctx => ({ ...ctx, personsStoryList }));
			},
			setDontRequestdata(dontRequestdata?: boolean) {
				setState({ dontRequestdata });
			}
		}),
		[service, setState, user]
	);

	const getData = useCallback(() => {
		return store;
	}, [store]);

	return { ...methods, getData };
};

export default useStory;
