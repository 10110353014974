import React, { memo, useEffect, useMemo, useState } from "react";

import { useMediaQuery } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { useLocation } from "react-router";
import YouTube from "react-youtube";
import styled from "styled-components";

import { PostBlockEventType } from "types";

import { routes } from "apps/RegularUser/constants";
import Close from "shared/Components/Blocks/Close";
import { getVideoId } from "shared/Components/Youtube/AddYoutube";

import { useMemberRoutes, useUser } from "shared/hooks";

import * as appTheme from "../../../theme/default";

const Wrapper = styled(({ children, id, isMemberView, onEvent, ...rest }) => (
	<Box {...rest}>
		{id && !isMemberView && <Close onClick={() => onEvent(PostBlockEventType.delete, id)} />}
		{children}
	</Box>
))`
	width: 100%;
	min-width: 280px;
	height: 315px;
	overflow: hidden;
	margin: 12px 0;
	position: relative;

	${props => props.theme.breakpoints.down("sm")} {
		height: 234px;
	}

	iframe {
		width: 100%;
		height: 100%;
	}

	.youtube-wrapper {
		width: 100%;
		height: 100%;

		& > div {
			&:first-child {
				width: 100%;
				height: 100%;
			}
		}
	}

	&.messaging-styles {
		width: 280px;
		height: 160px;
		margin: 0;
		margin-bottom: 6px;
		${props => props.theme.breakpoints.up("sm")} {
			width: 360px;
			height: 210px;
		}
	}
`;

interface Props {
	url: string;
	id?: string;
	playableObjectId?: string;
	onEvent?: (eventType: PostBlockEventType, id: string) => void;
}

const YouTubeBlock: React.FC<Props> = memo(({ url, id, onEvent, playableObjectId }) => {
	const isMobile = useMediaQuery(appTheme.default.breakpoints.down("sm"));
	const [youtubeCtrl, setYoutubeCtrl] = useState<any>();

	const { getData: getUserData } = useUser();
	const { isMemberView } = getUserData();

	const { getMemberRoutesData } = useMemberRoutes();
	const { routes: memberRoutes } = getMemberRoutesData();

	const location = useLocation();

	const isMessagingView = useMemo(
		() =>
			location.pathname.startsWith(routes.messaging.getPath()) ||
			location.pathname.startsWith(memberRoutes?.member.messaging.getPath()),
		[location.pathname, memberRoutes?.member.messaging]
	);

	const videoId = getVideoId(url);

	useEffect(() => {
		if (playableObjectId && ![id, videoId].includes(playableObjectId)) {
			youtubeCtrl?.target?.pauseVideo();
		}
	}, [playableObjectId, youtubeCtrl, id, videoId]);

	return (
		<Wrapper
			className={isMessagingView && "messaging-styles"}
			id={id}
			isMemberView={isMemberView}
			onEvent={onEvent}
			data-name="youtube"
			data-video={encodeURIComponent(
				JSON.stringify({ videoId, thumbnail: `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg` })
			)}
		>
			<Box className={"youtube-wrapper"}>
				<YouTube
					videoId={`${videoId}`}
					opts={{
						height: isMobile ? "210" : "291",
						playerVars: {
							autoplay: 0
						}
					}}
					onReady={setYoutubeCtrl}
					onPlay={() => onEvent && onEvent(PostBlockEventType.youtubeClick, id || videoId || "")}
				/>
			</Box>
		</Wrapper>
	);
});

export default YouTubeBlock;
