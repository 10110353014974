import { ButtonBase } from "@material-ui/core";
import { Link } from "react-router-dom";
import TimeAgo from "react-timeago";
import styled, { css } from "styled-components";

import { Avatar, Box, Text } from "shared/ui-kit";

export const Wrapper = styled(Box)`
	width: 100%;

	img {
		max-width: 100%;
		vertical-align: top;
		margin-top: 12px;
	}

	&.fullMode {
		background: white;

		border: 1px solid rgba(197, 206, 224, 0.5);
		border-radius: 12px;

		box-shadow: 0 1px 1px 0 rgb(0 0 0 / 8%);
	}
`;

export const MentionedHashtag = styled(Box)`
	border-bottom: 1px solid #c5cee0;
	padding-bottom: 12px;
	margin-bottom: 16px;
	position: relative;
	p {
		font-size: 13px;
		line-height: 1.23;
		color: #222b45;
	}
	.hashtag-summary-popup {
		position: absolute;
		bottom: 100%;
		left: -10px;
		border-radius: 12px;
		box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12);
		box-shadow: inset 0 0 0 0.5px #c5cee0;
		background-color: #fff;
		padding: 12px 16px 16px;
		width: 256px;
		h4 {
			font-size: 22px;
			font-weight: bold;
			line-height: 1.45;
			color: #222b45;
			margin: 0;
			margin-bottom: 4px;
		}
		p {
			font-weight: 600;
			line-height: 1.85;
			color: #8f9bb3;
			margin: 0;
			margin-bottom: 12px;
		}
		button {
			width: 100%;
			border-radius: 8px;
			margin: 0;
		}
	}
`;

export const Header = styled(Box)`
	width: 100%;

	display: flex;
	align-items: center;

	padding: 16px;

	&.between {
		justify-content: space-between;
	}
`;

Header.BaseInfo = styled(Box)`
	display: flex;
	align-items: center;
`;

export const StyledAvatar = styled(Avatar)`
	width: 48px;
	height: 48px;
	border-radius: 50%;
	margin-right: 8px;
`;

export const AuthorName = styled(Link)`
	font-size: 13px;
	font-weight: 600;
	color: #222b45;
	margin-right: 2px;
	text-decoration: none;
	&:hover {
		text-decoration: underline;
	}
`;

export const CategoryWrapper = styled(Box)`
	position: relative;
`;

export const Category = styled(ButtonBase)`
	height: 28px;
	padding: 2px 8px;

	border-radius: 8px;
	background-color: #f7f9fc;
	box-shadow: 0.5px 0.5px 2px #c5cee0, -0.5px 0 2px #c5cee0;
	display: flex;
	align-items: center;
	justify-content: center;

	${props => props.theme.breakpoints.down("sm")} {
		margin-left: 0;
		padding: 0 8px;
	}
`;

export const CategoryIconWrapper = styled(Box)`
	display: inline-flex;
	justify-content: center;
	align-items: center;

	width: 20px;
	height: 20px;

	.svg {
		width: 100%;
		height: 100%;
		padding: 0;
		margin: 0 !important;
	}
	svg {
		fill: ${({ fill }) => (fill ? fill : "#222b45")};
	}
`;

export const CategoryName = styled(Text)`
	font-size: 13px;
	font-weight: 600;
	line-height: 1.85;
	color: #222b45;

	padding-left: 8px;
`;

export const Body = styled(Box)`
	width: 100%;

	video {
		max-width: 100%;
	}

	${props => props.theme.breakpoints.down("sm")} {
		padding-top: 8px;
	}
`;

export const ContentWrapper = styled(Box)<{ communityprimarycolor?: string }>`
	> div {
		display: inline-block;
		word-break: break-word;
	}

	> p {
		font-size: 15px;
		line-height: 19px;

		margin: 0;
	}

	> img {
		margin: 12px 0 12px 0;
		border-radius: 4px;
		overflow: hidden;
	}

	a {
		color: #6173fe;
		font-weight: initial;
		text-decoration: none;
	}

	${({ communityprimarycolor }) =>
		communityprimarycolor &&
		css`
			a {
				color: ${communityprimarycolor};
			}
		`};

	.hashtag {
		color: inherit;
		text-decoration: none;
		font-weight: bold;
	}

	.author {
		color: #6173fe;
		font-weight: initial;
		text-decoration: none;
	}

	.content {
		width: 100%;

		> div {
			display: block;
			word-break: break-word;
			margin-left: auto;
			margin-right: auto;
		}

		> p {
			font-size: 15px;
			line-height: 19px;

			margin: 0;
		}

		> img {
			margin: 12px 0 12px 0;
			border-radius: 4px;
			overflow: hidden;
		}
	}
`;

export const InfoContentWrapper = styled.div`
	display: block;
`;

export const InfoContent = styled.div`
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	gap: 4px;

	.posted-in {
		margin-right: 4px;
	}
`;

export const PostDate = styled(TimeAgo)`
	font-size: 12px;
	line-height: 1.33;
	letter-spacing: -0.1px;
	color: #8f9bb3;

	${props => props.theme.breakpoints.down("sm")} {
		display: block;
		margin-top: 3px;
	}
`;

export const Footer = styled(Box)`
	width: 100%;
`;

Footer.InfoRow = styled(Box)`
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;

	padding: 4px 12px 8px;
`;

Footer.ManageRow = styled(Box)`
	width: 100%;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	box-shadow: 1px -1px 2px 0 rgb(0 0 0 / 8%);
	padding: 4px;
	border-end-end-radius: 12px;
`;

Footer.ManageColumn = styled(Box)`
	width: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	padding: 6px 6px;
	background-color: transparent;
	transition: background-color 0.25s linear;
	position: relative;
	border-radius: 8px;
	&:hover {
		background-color: #f7f9fc;
	}
`;

Footer.IconWrapper = styled(Box)<{ url: string }>`
	margin-right: 10px;

	width: 24px;

	svg {
		height: 24px;
		fill: ${({ reactionColor }) => reactionColor || "#ffd16d"};
	}
`;

Footer.Text = styled(Text)<{ color?: string }>`
	font-size: 15px;
	font-weight: 600;
	color: ${({ color }) => color || "#222b45"};

	${({ clickable }) =>
		clickable &&
		css`
			cursor: pointer;
			&:hover {
				text-decoration: underline;
			}
		`}
`;

Footer.LikeList = styled(Box)`
	display: flex;
	align-items: center;
	margin-right: 8px;
	&.button {
		margin-right: 0;
		width: 100%;
		justify-content: center;
	}
`;

Footer.LikeBlock = styled.button`
	display: flex;
	align-items: center;

	margin-right: 10px;
`;

Footer.Reaction = styled(Box)`
	border-radius: 50%;
	overflow: hidden;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-left: -6px;
	border: 1px solid white;
	background-color: white;
	z-index: ${({ zIndex }) => (zIndex ? zIndex : 1)};

	&:first-child {
		margin-left: 0;
	}

	svg {
		width: 20px;
		height: 20px;
		fill: ${({ reactionColor }) => reactionColor || "#ffd16d"};

		g {
			width: 100%;
			height: 100%;
		}
	}
`;

Footer.CommentsBlock = styled(Box)`
	border-top: 1px solid #c5cee050;
`;

export const PostTitle = styled(Text)`
	padding: 0 16px;
`;
