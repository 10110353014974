import React, { FC, useEffect, useRef, useState } from "react";

import { Box, InputAdornment, Switch, TextField, withStyles } from "@material-ui/core";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import VisibilityIcon from "@material-ui/icons/Visibility";
import WatchLaterIcon from "@material-ui/icons/WatchLater";
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";
import { DatePicker, TimePicker } from "@material-ui/pickers";
import { DateTime } from "luxon";
import { Controller, useForm } from "react-hook-form";

import { ReactComponent as DownloadIcon } from "assets/icons/icon-download.svg";
import { Dialog, SetupPrice } from "shared/Components";
import ConfirmLeavePopup from "shared/Components/ConfirmLeave";
import useConfirmLeavePopup from "shared/Components/ConfirmLeave/hooks/useConfirmLeavePopup";
import { SetupPriceMethods } from "shared/Components/SetupPrice";
import useScrollToXPosition from "shared/hooks/useScrollToXPosition";
import { AlbumModel } from "shared/services/useAlbumApiService";
import { GetAlbumResponse, genres } from "shared/types";
import { Button, Icon, Input, Text, Uploader, orientationConst } from "shared/ui-kit";

import { SubText } from ".";
import { ErrorText } from "../../Containers/ManageAlbums/style";
import { FeatureBlock, FooterWrapper, InputWrapper, UploaderWrapper } from "../../Containers/style";

export interface CreateAlbumProps {
	onUploaderChange: (files: FileList, onChange: (...event: any[]) => void) => Promise<void>;
	onClose: () => void;
	open: boolean;
	editAlbumDetails: AlbumModel | GetAlbumResponse | null;
	uploadingCover: boolean;
	handleCreateAlbum: (data: any) => Promise<void>;
	setupPriceFormRef: React.RefObject<SetupPriceMethods>;
	isFreeCommunity?: boolean;
}

const CreateAlbumManageView: FC<CreateAlbumProps> = ({
	onUploaderChange,
	onClose,
	open,
	editAlbumDetails,
	uploadingCover,
	handleCreateAlbum,
	setupPriceFormRef,
	isFreeCommunity = false
}) => {
	const bodyRef = useRef<HTMLFormElement>();
	const scrollToXPositionOnFocus = useScrollToXPosition(bodyRef?.current);
	const {
		handleClose,
		handleLeavePageConfirmed,
		closeConfirmPopup,
		getData: getConfirmLeavePopupData
	} = useConfirmLeavePopup({
		onClose,
		open
	});
	const { showConfirmPopup } = getConfirmLeavePopupData();

	const [step, setStep] = useState(1);

	const {
		formState: { errors, isDirty, isSubmitting },
		watch,
		setError,
		handleSubmit,
		control
	} = useForm({
		mode: "onChange"
	});

	const onSubmit = data => {
		if (step === 1) {
			setStep(2);
		} else {
			if (editAlbumDetails && editAlbumDetails.meta && typeof data.albumPhoto === "string")
				data["meta"] = editAlbumDetails.meta;
			else if (data.albumPhoto) data["meta"] = data.albumPhoto;
			else data["meta"] = { mediaId: null, url: null };

			handleCreateAlbum(data);
		}
	};

	const { albumScheduleDate, albumScheduleTime, price, schedule } = watch([
		"albumScheduleTime",
		"albumScheduleDate",
		"price",
		"schedule"
	]);

	useEffect(() => {
		if (albumScheduleDate && albumScheduleTime) {
			const date = new Date(albumScheduleDate).setHours(
				new Date(albumScheduleTime).getHours(),
				new Date(albumScheduleTime).getMinutes()
			);
			if (date < new Date().getTime()) {
				setError("albumScheduleDate", {
					message: "Date can't be in the past"
				});
				setError("albumScheduleTime", {
					message: " "
				});
			}
		}
	}, [albumScheduleDate, albumScheduleTime, setError]);

	useEffect(() => {
		if (open) {
			setStep(1);
		}
	}, [open]);

	const StyledAutoComplete = withStyles({
		tag: {
			backgroundColor: "#f7f9fc"
		}
	})(Autocomplete);

	return (
		<>
			<ConfirmLeavePopup
				handleLeavePage={handleLeavePageConfirmed}
				open={showConfirmPopup}
				onClose={closeConfirmPopup}
				popup
			/>
			<Dialog
				highzIndex
				bodyCustomStyles="overflow-x: hidden;"
				hasBackButton={step === 2}
				onBack={() => setStep(1)}
				open={open}
				onClose={() => handleClose(isDirty)}
				title={
					<>
						<Text variant="h7">{!!editAlbumDetails ? "Update" : "Create"} Album</Text>{" "}
						<SubText>• Step {step}/2</SubText>
					</>
				}
				bodyRef={bodyRef}
				footer={
					<FooterWrapper>
						<Button
							id={step === 1 ? "albumNext" : !!editAlbumDetails ? "updateAlbum" : "createAlbum"}
							type="submit"
							size="large"
							disabled={(editAlbumDetails && step === 2 && !isDirty) || isSubmitting || uploadingCover}
							onClick={handleSubmit(onSubmit)}
						>
							{uploadingCover
								? "Uploading Image.."
								: step === 1
								? "Next"
								: !!editAlbumDetails
								? "Update Album"
								: "Create Album"}
						</Button>
					</FooterWrapper>
				}
			>
				<form onSubmit={handleSubmit(onSubmit)}>
					<Box style={{ display: step === 1 ? "block" : "none" }}>
						<UploaderWrapper>
							<Controller
								name="albumPhoto"
								control={control}
								defaultValue={editAlbumDetails && editAlbumDetails.meta.url}
								render={({ onChange, value }) => (
									<Uploader
										urls={value ? [value] : undefined}
										onChange={(files: any) => onUploaderChange(files, onChange)}
										label="Upload Album Photo"
										orientation={orientationConst.horizontal}
										width="100%"
										height="auto"
										description={
											<>
												Drag and Drop File Here or <span className="anchor">Browse</span> to Choose a File
											</>
										}
										accept={[
											{
												fileType: "image/png, image/jpeg, image/x-eps",
												name: "png, jpg, eps"
											}
										]}
										icon={<Icon fill="#c5cee0" name="cloud-upload" width={64} height={50} viewBox="4 2 18 19" />}
									/>
								)}
							/>
							{errors.albumPhoto && <ErrorText>{errors.albumPhoto.message}</ErrorText>}
						</UploaderWrapper>
						<InputWrapper>
							<Controller
								name="albumTitle"
								control={control}
								defaultValue={editAlbumDetails && editAlbumDetails.title}
								rules={{
									required: "Album Title is required.",
									maxLength: {
										value: 100,
										message: "Title can't be more than 100 letters"
									}
								}}
								render={({ onChange, value }) => (
									<Input
										id="albumTitle"
										errorText={errors.albumTitle?.message}
										label="Album title *"
										onChange={onChange}
										value={value}
										onFocus={scrollToXPositionOnFocus}
									/>
								)}
							/>
						</InputWrapper>
						<InputWrapper>
							<Controller
								name="artist"
								control={control}
								defaultValue={editAlbumDetails && editAlbumDetails.artist}
								rules={{
									required: "Artist name is required.",
									maxLength: {
										value: 100,
										message: "Artist name can't be more than 100 letters"
									}
								}}
								render={({ onChange, value }) => (
									<Input
										id="albumArtist"
										errorText={errors.artist?.message}
										label="Artist *"
										onChange={onChange}
										value={value}
										onFocus={scrollToXPositionOnFocus}
									/>
								)}
							/>
						</InputWrapper>
						<InputWrapper noLabel>
							<Controller
								name="genre"
								variant="outlined"
								rules={{ required: "Genre is required!", validate: value => !!value?.length }}
								control={control}
								defaultValue={editAlbumDetails && editAlbumDetails.genre}
								render={({ onChange, value }) => {
									const values = value
										? value.filter(v => {
												if (typeof v === "object") return v;
												if (typeof v === "string") {
													const item = genres.find(g => g.key === v);
													if (item) return item;
												}
												return null;
										  })
										: [];

									return (
										<StyledAutoComplete
											multiple
											ListboxProps={{ style: { maxHeight: "180px" }, position: "bottom-start" }}
											renderInput={params => (
												<TextField
													{...params}
													error={!!errors.genre}
													helperText={errors.genre?.message}
													label="Genres *"
													variant="outlined"
												/>
											)}
											getOptionLabel={(option: any) => option && (typeof option === "string" ? option : option.value)}
											id="albumGenres"
											filterOptions={createFilterOptions({
												matchFrom: "start",
												stringify: (option: any) => option.value
											})}
											includeInputInList
											filterSelectedOptions
											options={genres}
											value={values}
											onChange={(event, newValue) => onChange(newValue)}
											onFocus={scrollToXPositionOnFocus}
										/>
									);
								}}
							/>
						</InputWrapper>
						<InputWrapper>
							<Controller
								name="description"
								control={control}
								defaultValue={editAlbumDetails && editAlbumDetails.description}
								rules={{
									required: "Description is required.",
									maxLength: {
										value: 1000,
										message: "Description can't be more than 1000 letters"
									}
								}}
								render={({ onChange, value }) => (
									<TextField
										id="albumDescription"
										error={errors.description}
										helperText={errors.description?.message}
										name="description"
										onChange={onChange}
										value={value}
										label="Description *"
										variant="outlined"
										multiline
										rows={3}
										onFocus={scrollToXPositionOnFocus}
									/>
								)}
							/>
						</InputWrapper>
					</Box>
					<Box style={{ display: step === 2 ? "block" : "none" }}>
						<Controller
							name="releaseDate"
							control={control}
							defaultValue={editAlbumDetails && editAlbumDetails.releaseDate}
							render={({ onChange, value = null }) => (
								<DatePicker
									id="albumReleaseDate"
									label="Release Date"
									placeholder="Release Date"
									autoOk
									inputVariant="outlined"
									variant="inline"
									helperText={errors.releaseDate?.message}
									error={!!errors.releaseDate}
									value={value}
									onChange={d => onChange(d)}
									InputProps={{
										startAdornment: (
											<InputAdornment position="start">
												<Icon name="calender" fill={"#8f9bb3"} />
											</InputAdornment>
										)
									}}
									onFocus={scrollToXPositionOnFocus}
								/>
							)}
						/>
						{!isFreeCommunity && (
							<Controller
								name="price"
								control={control}
								defaultValue={!!editAlbumDetails?.priceTags?.length}
								render={({ onChange, value }) => (
									<FeatureBlock>
										<Box className="head">
											<Box className="left-side">
												<MonetizationOnIcon />
												<Text variant="subtitle1">Set a Price</Text>
											</Box>
											<Switch checked={value} onChange={e => onChange(e.target.checked)} id="albumPrice" />
										</Box>
										{price && (
											<Controller
												name="priceChanged"
												control={control}
												render={({ onChange }) => (
													<SetupPrice
														ref={setupPriceFormRef}
														title={"Who can access this album?"}
														defaultValue={editAlbumDetails?.priceTags}
														onPriceChanged={() => onChange(true)}
													/>
												)}
											/>
										)}
									</FeatureBlock>
								)}
							/>
						)}
						{price && (
							<Controller
								name="individualAccess"
								control={control}
								defaultValue={editAlbumDetails?.individualAccess}
								render={({ onChange, value }) => (
									<FeatureBlock className="pb-8">
										<Box className="head">
											<Box className="left-side">
												<Icon name="folder-open-gray" group="color" />
												<Box>
													<Text variant="subtitle1">Allow access to content in Album</Text>
													<Text variant="body2">
														Allow users to buy or access the individual content in the album without buying entire
														album.
													</Text>
												</Box>
											</Box>
											<Switch checked={value} onChange={e => onChange(e.target.checked)} id="albumIndividualAccess" />
										</Box>
									</FeatureBlock>
								)}
							/>
						)}
						<Controller
							name="schedule"
							control={control}
							defaultValue={!!editAlbumDetails?.scheduleDate}
							render={({ onChange, value }) => (
								<FeatureBlock>
									<Box className="head">
										<Box className="left-side">
											<WatchLaterIcon />
											<Text variant="subtitle1">Schedule Album</Text>
										</Box>
										<Switch checked={value} onChange={e => onChange(e.target.checked)} id="albumSchedule" />
									</Box>
									{schedule && (
										<Box className="input multiple">
											<Controller
												name="albumScheduleDate"
												rules={{ required: schedule ? "Schedule Date is required" : false }}
												control={control}
												defaultValue={editAlbumDetails && editAlbumDetails.scheduleDate}
												render={({ onChange, value = null }) => (
													<DatePicker
														id="albumScheduleDate"
														placeholder="Schedule Date"
														autoOk
														minDate={DateTime.now()}
														inputVariant="outlined"
														variant="inline"
														value={value}
														onChange={d => onChange(d)}
														error={!!errors.albumScheduleDate}
														helperText={errors.albumScheduleDate?.message}
														onFocus={scrollToXPositionOnFocus}
													/>
												)}
											/>
											<Controller
												name="albumScheduleTime"
												rules={{
													required: schedule ? "Schedule Time is required" : false
												}}
												control={control}
												defaultValue={editAlbumDetails && editAlbumDetails.scheduleDate}
												render={({ onChange, value = null }) => (
													<TimePicker
														id="albumScheduleTime"
														placeholder="Select Time"
														autoOk
														inputVariant="outlined"
														variant="inline"
														value={value}
														onChange={d => onChange(d)}
														error={!!errors.albumScheduleTime}
														helperText={errors.albumScheduleTime?.message}
														onFocus={scrollToXPositionOnFocus}
													/>
												)}
											/>
										</Box>
									)}
								</FeatureBlock>
							)}
						/>
						<Controller
							name="downloadable"
							control={control}
							defaultValue={editAlbumDetails?.canBeDownloaded}
							render={({ onChange, value }) => (
								<FeatureBlock>
									<Box className="head">
										<Box className="left-side">
											<DownloadIcon />
											<Text variant="subtitle1">Allow Download</Text>
										</Box>
										<Switch checked={value} onChange={e => onChange(e.target.checked)} id="albumDownloadable" />
									</Box>
								</FeatureBlock>
							)}
						/>
						<Controller
							name="private"
							control={control}
							defaultValue={editAlbumDetails?.private}
							render={({ onChange, value }) => (
								<FeatureBlock noBorder>
									<Box className="head">
										<Box className="left-side">
											<VisibilityIcon />
											<Box>
												<Text variant="subtitle1">Set as Private</Text>
												<Text variant="body2">Choose this option to hide from others in the community</Text>
											</Box>
										</Box>
										<Switch checked={value} onChange={e => onChange(e.target.checked)} id="albumPrivate" />
									</Box>
								</FeatureBlock>
							)}
						/>
					</Box>
				</form>
			</Dialog>
		</>
	);
};

export default CreateAlbumManageView;
