const colors = {
	variants: {
		dark: "dark",
		light: "light",
		transparent: "transparent"
	},
	palette: {
		colors: {
			purple: "#6173fe",
			lightPurple: "#ebecff",
			gray: {
				300: "#8f9bb3",
				350: "#8f9cb3",
				500: "#c5cee0",
				700: "#222b45",
				800: "#192038"
			},
			green: {
				400: "#409d99"
			}
		}
	}
};

export default colors;
