import React from "react";

import { Scrollbars } from "react-custom-scrollbars";
import { Route, Switch } from "react-router";

import IApp from "types/IApp";

import LandingLayout from "apps/Auth/Layouts/LandingLayout";
import SecureRoute from "shared/services/AuthDecorator/SecureRoute";
import wrapByLayout from "utils/wrapByLayout";

import ManageGlobalUserPage from "./Pages/Manage";
import { routes } from "./constants";

class GlobalUserApp implements IApp {
	getRoutes = () => {
		return [
			<Route path={routes.globalUser.getPath()} key="global-user-routes">
				<Scrollbars>
					<Switch>{this.getSecuredRoutes()}</Switch>
				</Scrollbars>
			</Route>
		];
	};

	getSecuredRoutes = () => {
		const routeList = [
			<Route
				exact
				key={routes.globalUser.manage.getElementKey()}
				path={routes.globalUser.manage.getPath()}
				component={wrapByLayout(LandingLayout)(ManageGlobalUserPage)}
			/>
		];

		return <SecureRoute routes={routeList} />;
	};
}

export default GlobalUserApp;
