import styled, { css } from "styled-components";

import { Box, Text } from "shared/ui-kit";

export const EmptyBox = styled(Box)`
	width: 100%;
	min-height: 64px;

	border-radius: 12px;

	border: 1px solid rgba(197, 206, 224, 0.5);

	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);

	background-color: #fff;

	display: flex;
	flex-direction: column;
	justify-content: center;

	margin-top: 16px;
	padding: 22px 24px;
`;

export const ListWrapper = styled(Box)`
	padding: 12px 0;

	.slick-list {
		padding-bottom: 10px;
	}

	${({ autoFit }) =>
		autoFit &&
		css`
			display: grid;
			grid-template-columns: ${({ autoFit }) =>
				typeof autoFit === "number"
					? `repeat(auto-fit, minmax(${autoFit}px, 1fr))`
					: "repeat(auto-fit, minmax(250px, 1fr))"};
			grid-gap: ${({ gridGap }) => (gridGap ? `${gridGap}px` : "16px")};
			align-items: flex-start;
		`}

	.MuiButtonBase-root {
		vertical-align: top;
	}
`;

export const SkeletonWrapper = styled(Box)`
	width: 100%;
	height: 100%;
	${({ isWhiteBg }) =>
		isWhiteBg &&
		css`
			background-color: white;
		`}

	border-radius: ${({ borderRadius }) =>
		borderRadius
			? css`
					${borderRadius}
			  `
			: css`12px`};

	overflow: hidden;

	.MuiSkeleton-text {
		transform: scale(1, 1);
	}

	.MuiSkeleton-root {
		margin-bottom: ${({ marginBottom }) => (marginBottom ? marginBottom : "6px")};
	}
`;

export const ItemWrapper = styled(Box)`
	width: 208px;
	min-width: 208px;
	text-align: left;

	margin: 0 8px;

	&.v-inline {
		display: inline-flex;
		padding-bottom: 16px;
		vertical-align: top;
	}

	&.event,
	&.group,
	&.series {
		width: 320px;
		min-width: 320px;
	}

	&.track {
		width: 258px;
		min-width: 258px;
	}

	&.file,
	&.video {
		width: 266px;
		min-width: 266px;
	}

	&.auto-fit {
		width: 100%;
		min-width: 100%;
		margin: 0;
	}
`;

export const MessageText = styled(Text)`
	font-size: 15px;
	font-weight: normal;
	line-height: 1.33;
	letter-spacing: normal;
	color: #222b45;
`;

export const SkeletonConnection = styled(Box)``;

SkeletonConnection.Header = styled(Box)`
	position: relative;

	margin-bottom: 60px;
`;

SkeletonConnection.HeaderAvatar = styled(Box)`
	position: absolute;

	bottom: -40px;
	left: calc(50% - 40px);

	width: 80px;
	height: 80px;
	border-radius: 50%;
	overflow: hidden;
`;

SkeletonConnection.Body = styled(Box)`
	margin-top: 20px;
	display: flex;
	flex-direction: column;
	align-items: center;
`;

SkeletonConnection.BodyExtra = styled(Box)`
	width: 100%;

	margin: 40px auto 20px auto;

	display: flex;
	justify-content: center;
`;

export const VolunteersAvatar = styled(Box)`
	position: relative;
	span:nth-child(2) {
		left: -2px;
	}
	span:nth-child(3) {
		left: -4px;
	}
`;
