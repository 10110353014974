import React, { FC } from "react";

import { Box } from "@material-ui/core";

import { ReactComponent as IconFile } from "assets/icons/icon-file.svg";
import { TableIcon } from "modules/Manage/View/Containers/ManageFiles/style";
import { CategoryModel } from "shared/types/CategoryModel";
import { Text } from "shared/ui-kit";
import getCountMessage from "utils/getCountMessage";

import { CustomRadio, CustomRow } from "../style";

interface Props {
	board: CategoryModel;
	handleToggleBoard: (board: CategoryModel) => void;
	lastBoardRef?: React.RefObject<HTMLDivElement>;
	selectedBoard?: CategoryModel;
}

const BoardBlock: FC<Props> = ({ board, lastBoardRef, handleToggleBoard, selectedBoard }) => {
	return (
		<CustomRow
			ref={lastBoardRef}
			onClick={() => handleToggleBoard(board)}
			className={`${selectedBoard?.cid === board.cid ? "active" : ""}`}
		>
			<Box className="info">
				<TableIcon withBg className="dark-bg">
					<IconFile />
				</TableIcon>
				<Box className="name">
					<Text variant="subtitle1">{board.name}</Text>
					<Text className="description">{getCountMessage(board.totalTopicCount, "post")}</Text>
				</Box>
			</Box>
			<Box className="checkbox">
				<CustomRadio checked={selectedBoard?.cid === board.cid} />
			</Box>
		</CustomRow>
	);
};

export default BoardBlock;
