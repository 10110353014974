import React, { FC, useEffect, useRef, useState } from "react";

import { Box } from "@material-ui/core";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";

import { useHistory, useLocation } from "react-router";

import { useIntegrations } from "modules/Integrations/Data/hooks";
import { Button, Text } from "shared/ui-kit";

import { IntegrationRowContainer, ScrollableRow, SliderArrows } from "../Containers/Integrations/styles";

const IntegrationRow: FC<{ title: string }> = ({ title, children }) => {
	const [slideCount, setSlideCount] = useState(1);
	const [lastVisibleItem, setLastVisibleItem] = useState({
		initial: 0,
		last: 0
	});
	const [showLeftArrow, setShowLeftArrow] = useState(false);
	const [showRightArrow, setShowRightArrow] = useState(false);

	const { getData: getIntegrationsData } = useIntegrations();
	const { podcasts, searchResults, integrationsUrl } = getIntegrationsData();

	const rowRef = useRef<HTMLDivElement>(null);
	const rowContainerRef = useRef<HTMLDivElement>(null);

	const handleCards = () => {
		const cards = rowRef.current?.getElementsByClassName("card-r");
		const cardW = cards?.length && cards[0].clientWidth;
		const containerW = rowRef.current?.offsetWidth;

		if (cardW && containerW) {
			const amountOfCards = Math.round(containerW / cardW);

			if (cards && cards.length > amountOfCards) {
				setShowRightArrow(true);
			} else {
				setShowLeftArrow(false);
				setShowRightArrow(false);
			}

			setLastVisibleItem({
				initial: amountOfCards,
				last: amountOfCards
			});
		}
	};

	const { search } = useLocation();
	const query = new URLSearchParams(search);

	const view = query.get("view");
	const fullGridView = view === title;

	useEffect(() => {
		handleCards();
	}, [searchResults, podcasts]);

	useEffect(() => {
		window.addEventListener("resize", handleCards);

		return () => window.removeEventListener("resize", handleCards);
	}, []);

	useEffect(() => {
		if (fullGridView && rowContainerRef.current) {
			window.scrollTo({
				top: rowContainerRef.current.offsetTop,
				behavior: "smooth"
			});
		}
	}, [fullGridView]);

	const { replace } = useHistory();

	const moveLeft = (rowRef: React.RefObject<HTMLDivElement>, itemW: number, cardsLength: number) => {
		if (rowRef.current && lastVisibleItem.last < cardsLength) {
			rowRef.current.scrollLeft += itemW;
			setSlideCount(slideCount + 1);
			setLastVisibleItem({
				initial: lastVisibleItem.initial,
				last: lastVisibleItem.last + 1
			});
		}
	};

	const moveRight = (rowRef: React.RefObject<HTMLDivElement>, itemW: number) => {
		if (rowRef.current && lastVisibleItem.initial <= lastVisibleItem.last) {
			rowRef.current.scrollLeft -= itemW;
			setSlideCount(slideCount - 1);
			setLastVisibleItem({
				initial: lastVisibleItem.initial,
				last: lastVisibleItem.last - 1
			});
		}
	};

	const checkDisplayArrowState = (cardsLength: number) => {
		slideCount >= 1 ? setShowLeftArrow(true) : setShowLeftArrow(false);
		lastVisibleItem.last === cardsLength ? setShowRightArrow(false) : setShowRightArrow(true);
	};

	const handleMove = (direction: "right" | "left") => {
		if (rowRef.current) {
			const cards = rowRef.current.getElementsByClassName("card-r");
			const itemW = cards[0].clientWidth;

			if (itemW && lastVisibleItem) {
				direction === "left" ? moveLeft(rowRef, itemW, cards.length) : moveRight(rowRef, itemW);
			}

			checkDisplayArrowState(cards.length);
		}
	};

	const handleSeeAll = () => {
		if (fullGridView) {
			replace(integrationsUrl);
			return;
		}

		replace(`${integrationsUrl}?view=${title}`);
	};

	return (
		<IntegrationRowContainer ref={rowContainerRef}>
			<Box className="header">
				<Text variant="h5">{title}</Text>
				{showRightArrow && (
					<Button buttonTheme="ghost" onClick={handleSeeAll}>
						{fullGridView ? "Hide items" : "See All"}
					</Button>
				)}
			</Box>
			{showLeftArrow && !fullGridView && (
				<SliderArrows onClick={() => handleMove("right")} variant="outlined" left>
					<KeyboardArrowRightIcon />
				</SliderArrows>
			)}

			<ScrollableRow noScroll={fullGridView} ref={rowRef}>
				{children}
			</ScrollableRow>

			{showRightArrow && !fullGridView && (
				<SliderArrows onClick={() => handleMove("left")} variant="outlined" right>
					<KeyboardArrowRightIcon />
				</SliderArrows>
			)}
		</IntegrationRowContainer>
	);
};

export default IntegrationRow;
