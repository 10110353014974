import React from "react";

import { view } from "modules/MemberHome";

import { useMemberRoutes } from "shared/hooks";

const FillProfileSelfiePage = () => {
	const { getMemberRoutesData } = useMemberRoutes();
	const { routes } = getMemberRoutesData();
	return <view.FillProfileSelfie fillProfileWelcomeUrl={routes?.member.fillProfile.welcome.getPath()} />;
};

export default FillProfileSelfiePage;
