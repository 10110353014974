import { Box } from "@material-ui/core";
import styled from "styled-components";

import { Text } from "shared/ui-kit";

export const LabelHeadline = styled(Text)`
	font-size: 13px;
	font-weight: 600;
	line-height: 1.85;
	color: #222b45;
	margin-bottom: 2px;
`;

export const MultiselectWrapper = styled(Box)`
	.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
		border-color: ${({ color }) => color || "#192038"} !important;
	}
	.MuiInputBase-root {
		border-radius: 8px;

		&:focus {
			outline: 0;
			box-shadow: none;
		}
	}

	.MuiFormHelperText-root {
		margin-left: 0px;
	}
`;

MultiselectWrapper.Item = styled(Box)`
	border-radius: 8px;
	background-color: ${({ color }) => `${color}1a` || "#edf1f7"};
	display: flex;
	align-items: center;
	min-height: 24px;
	padding: 8px;
	margin-right: 6px;
	button {
		padding: 0;
	}
	p {
		font-size: 12px;
		color: #222b45;
		margin-right: 4px;
	}
`;
