import React, { FC, useCallback, useEffect, useRef } from "react";

import LabeledInput, { LabelInputProps } from "../LabeledInput";

const CreditCardInput: FC<LabelInputProps> = ({
	label,
	onChange,
	name,
	placeholder,
	value,
	rightIcon,
	error,
	disabled
}) => {
	const inputRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		const input = inputRef.current;

		const handlePaste = e => {
			const value = e.clipboardData.getData("Text");

			if (value.length === 19) return;

			const formattedValue = value
				.replace(/[^0-9]/g, "")
				.replace(/\W/gi, "")
				.replace(/(.{4})/g, "$1 ");

			if (inputRef.current) {
				inputRef.current.value = formattedValue;
			}
		};

		if (input) {
			input.addEventListener("paste", handlePaste);
		}

		return () => input?.removeEventListener("paste", handlePaste);
	}, []);

	const handleChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			if (e.target.value.replace(/\s/g, "").length > 16) return;

			const formattedValue = e.target.value
				.replace(/[^0-9]/g, "")
				.replace(/\W/gi, "")
				.replace(/(.{4})/g, "$1 ")
				.trim();

			if (inputRef.current) {
				onChange(formattedValue);
			}
		},
		[onChange]
	);

	return (
		<LabeledInput
			label={label}
			onChange={handleChange}
			name={name}
			placeholder={placeholder}
			value={value}
			rightIcon={rightIcon}
			inputRef={inputRef}
			maxLength={19}
			error={error}
			disabled={disabled}
		/>
	);
};

export default CreditCardInput;
