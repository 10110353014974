import React, { useEffect, useState } from "react";

import { Box, IconButton } from "@material-ui/core";

import { Controller, useForm } from "react-hook-form";

import ShopifyImg from "assets/images/shopify.png";
import { useIntegrations } from "modules/Integrations/Data/hooks";

import { useCommunity } from "shared/hooks";
import { Button, Icon, Input, Loader, Text } from "shared/ui-kit";

import IntegrationDialogWrapper from "./IntegrationDialogWrapper";

const ShopifyDialog = () => {
	const { setOpenShopifyDialog, updateShopifyInfo, getData: getIntegrationsData } = useIntegrations();
	const { isLoading, openShopifyDialog } = getIntegrationsData();

	const [disableFields, setDisableFields] = useState({
		domain: false,
		accessToken: false
	});
	const { getData: getCommunityData } = useCommunity();
	const { workspace } = getCommunityData();

	const connected = !!workspace?.shopifyDomain && !!workspace.storefrontAccessToken;

	const {
		handleSubmit,
		control,
		reset,
		formState: { errors }
	} = useForm();

	useEffect(() => {
		if (connected && openShopifyDialog) {
			setDisableFields({
				domain: true,
				accessToken: true
			});
		}
	}, [connected, openShopifyDialog]);

	const handleDisconnect = async () => {
		await updateShopifyInfo();
		handleClose();
	};

	const handleClose = async () => {
		reset();
		setDisableFields({
			accessToken: false,
			domain: false
		});
		setOpenShopifyDialog(false);
	};

	const onSubmit = async data => {
		await updateShopifyInfo(data.domain, data.accessToken);
		handleClose();
	};

	return (
		<IntegrationDialogWrapper onClose={handleClose} open={openShopifyDialog}>
			<form onSubmit={handleSubmit(onSubmit)}>
				<Box className="dialog-header">
					<Text variant="h4">Shopify Integration</Text>
					<IconButton onClick={handleClose} className="close-popup">
						<Icon fill="#c5cee0" height={14} width={14} group="filled" name="close" />
					</IconButton>
				</Box>
				<Box className="dialog-content">
					<Box className="integration-status">
						<img src={ShopifyImg} alt="Shopify" />
						<Box className="status-wrapper">{connected && <Box className="status green">Connected</Box>}</Box>
					</Box>
					<Box className="access-info">
						<Controller
							name="domain"
							control={control}
							defaultValue={workspace?.shopifyDomain}
							rules={{ required: "Domain is required" }}
							render={({ onChange, value }) => (
								<Box className="input-wrapper">
									<Input
										label="Domain"
										value={value}
										className="access-field-input"
										errorText={errors.domain?.message}
										disabled={disableFields.domain}
										onChange={onChange}
									/>
									<IconButton onClick={() => setDisableFields(ctx => ({ ...ctx, domain: !ctx.domain }))} size="small">
										<Icon name="pencil" group="filled" fill="#c5cbd5" />
									</IconButton>
								</Box>
							)}
						/>
						<Controller
							name="accessToken"
							control={control}
							defaultValue={workspace?.storefrontAccessToken}
							rules={{ required: "Access token is required" }}
							render={({ onChange, value }) => (
								<Box className="input-wrapper">
									<Input
										type="password"
										label="Access Token"
										value={value}
										className="access-field-input"
										errorText={errors.accessToken?.message}
										disabled={disableFields.accessToken}
										onChange={onChange}
									/>
									<IconButton
										onClick={() => setDisableFields(ctx => ({ ...ctx, accessToken: !ctx.accessToken }))}
										size="small"
									>
										<Icon name="pencil" group="filled" fill="#c5cbd5" />
									</IconButton>
								</Box>
							)}
						/>
					</Box>
				</Box>
				<Box className="dialog-footer">
					{connected && (
						<Button size="large" buttonTheme="light" palette="danger" onClick={handleDisconnect} disabled={isLoading}>
							{isLoading ? <Loader size="20px" color="inherit" variant="indeterminate" show /> : "Disconnect"}
						</Button>
					)}
					<Button size="large" type="submit" disabled={!!Object.keys(errors).length || isLoading}>
						{isLoading ? <Loader size="20px" color="inherit" variant="indeterminate" show /> : "Save"}
					</Button>
				</Box>
			</form>
		</IntegrationDialogWrapper>
	);
};

export default ShopifyDialog;
