import React, { FC } from "react";

import { ReactComponent as IconAlertFill } from "assets/icons/icon-alert-fill.svg";

import { ItemWrapper } from "./style";

interface Props {
	contentType: string;
}

const DeletedItemTemplate: FC<Props> = ({ contentType }) => (
	<ItemWrapper>
		<ItemWrapper.IconContainer>
			<IconAlertFill />
		</ItemWrapper.IconContainer>
		<ItemWrapper.TextContainer>
			<ItemWrapper.Heading>Content not found</ItemWrapper.Heading>
			<ItemWrapper.Text>This {contentType} is unavailable</ItemWrapper.Text>
		</ItemWrapper.TextContainer>
	</ItemWrapper>
);

export default DeletedItemTemplate;
