import React, { useEffect } from "react";

import { ReactComponent as AmericanExpressCardIcon } from "assets/icons/american-express.svg";
import { ReactComponent as DeleteIcon } from "assets/icons/icon-delete.svg";
import { ReactComponent as FlipIcon } from "assets/icons/icon-flip.svg";
import { ReactComponent as MasterCardIcon } from "assets/icons/mastercard.svg";
import { ReactComponent as VisaCardIcon } from "assets/icons/visa.svg";

import ConfirmActionModal from "shared/Components/ConfirmActionModal";
import { useStripe } from "shared/hooks";

import { Card } from "shared/types/CardsType";
import { Icon } from "shared/ui-kit";

import { PaymentMethodsForm } from "./PaymentMethodsForm";
import { AddNewCardButton, AddPaymentMethod, CardList, CardNumber } from "./styles";

import { SettingsOptionDropdown } from "../SettingsOptionDropdown";
import { SettingsOption, SettingsSectionContainer } from "../commonStyles";

const CARD_BRANDS = {
	Visa: <VisaCardIcon />,
	MasterCard: <MasterCardIcon />,
	AmericanExpress: <AmericanExpressCardIcon />
};

enum ScreenKind {
	MAIN = "MAIN",
	FORM = "FORM"
}

const initialState = {
	isOpen: false,
	cardToBeDeleted: null
};

export function PaymentMethods() {
	const [activeScreen, setActiveScreen] = React.useState<ScreenKind>(ScreenKind.MAIN);
	const [confirmDeleteDialog, setConfirmDeleteDialog] = React.useState<{
		isOpen: boolean;
		cardToBeDeleted: Card | null;
	}>(initialState);
	const [cardToBeChanged, setCardToBeChanged] = React.useState<Card | null>(null);

	const { getData, deleteCard, getCards } = useStripe();
	const { cards } = getData();

	useEffect(() => {
		getCards();
	}, [getCards]);

	const handleAddNewMethodButtonClick = () => {
		setActiveScreen(ScreenKind.FORM);
	};

	const handleChangePaymentDetails = (card: Card) => {
		setCardToBeChanged(card);
		setActiveScreen(ScreenKind.FORM);
	};

	const handleCancelPaymentMethodForm = () => {
		setActiveScreen(ScreenKind.MAIN);
		setCardToBeChanged(null);
	};

	const handleConfirmDeleteAction = () => {
		setConfirmDeleteDialog({ isOpen: false, cardToBeDeleted: null });
		const data = confirmDeleteDialog.cardToBeDeleted;
		if (data?.id) {
			deleteCard(data?.id);
		}
	};

	const handleDeleteMethod = (card: Card) => {
		setConfirmDeleteDialog({ isOpen: true, cardToBeDeleted: card });
	};

	React.useEffect(() => {
		return () => {
			setCardToBeChanged(null);
		};
	}, []);

	return (
		<>
			<SettingsSectionContainer>
				{activeScreen === ScreenKind.MAIN && (
					<>
						{cards &&
							cards.map(card => (
								<SettingsOption key={card.id}>
									{CARD_BRANDS[card.brand]}
									<CardNumber>•••• {card.last4}</CardNumber>
									<SettingsOption.More>
										<SettingsOptionDropdown
											options={[
												{ icon: <FlipIcon />, handler: () => handleChangePaymentDetails(card), name: "Change Details" },
												{ icon: <DeleteIcon />, handler: () => handleDeleteMethod(card), name: "Delete Method" }
											]}
										/>
									</SettingsOption.More>
								</SettingsOption>
							))}
						<SettingsOption>
							<CardList>
								<VisaCardIcon />
								<MasterCardIcon />
								<AmericanExpressCardIcon />
							</CardList>
							<AddPaymentMethod onClick={handleAddNewMethodButtonClick}>
								<AddNewCardButton>
									<Icon group={"filled"} width="16px" height="16px" fill={"black"} name={"plus"} />
									<span>Add New Method</span>
								</AddNewCardButton>
							</AddPaymentMethod>
						</SettingsOption>
					</>
				)}
				{activeScreen === ScreenKind.FORM && (
					<PaymentMethodsForm onCancel={handleCancelPaymentMethodForm} cardToBeChanged={cardToBeChanged} />
				)}
			</SettingsSectionContainer>
			<ConfirmActionModal
				open={confirmDeleteDialog.isOpen}
				onClose={() => setConfirmDeleteDialog(initialState)}
				title="Delete method"
				bodyText="Are you sure you want to delete this payment method?"
				onConfirm={handleConfirmDeleteAction}
				confirmText="Delete"
			/>
		</>
	);
}
