import { ButtonBase } from "@material-ui/core";
import styled from "styled-components";

import { Box, Text } from "shared/ui-kit";

export const ListWrapper = styled(Box)`
	margin-top: 32px;

	padding-bottom: 24px;
`;

export const Item = styled(Box)``;

Item.Link = styled(ButtonBase)`
	display: inline-flex;
	justify-content: center;
	align-items: center;

	padding: 1px 7px;

	border-radius: 8px;
	border: solid 1px #c5cee0;

	background-color: #f7f9fc;

	margin: 0 8px 8px 0;
`;

Item.Title = styled(Text)`
	font-size: 13px;
	font-weight: 600;
	line-height: 1.85;
	color: #222b45;
`;
