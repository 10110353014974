import { IconButton } from "@material-ui/core";
import styled from "styled-components";

import { Box, Button, Text } from "shared/ui-kit";

export const FileDropWrapper = styled(Box)`
	height: 100%;
`;

export const GiftedChatWrapper = styled(Box)<{ inputWrapperHeight: number }>`
	width: 100%;
	height: 100%;
	position: relative;

	& > div {
		& > div {
			&:first-child {
				& > div {
					&:first-child {
						& > div {
							&:first-child {
								padding: 0 17px;

								${props => props.theme.breakpoints.down("sm")} {
									padding: 0;
								}
							}
						}
					}
				}
			}
		}
	}

	.file-drop {
		height: 100%;
		.file-drop-target {
			height: 100% !important;
			> div {
				height: 100%;
				> div {
					height: ${({ inputWrapperHeight }) =>
						inputWrapperHeight && `calc(100% - ${inputWrapperHeight}px)`} !important;
				}
				.edit-image {
					height: unset !important;
				}
			}
		}
	}
`;

GiftedChatWrapper.DragOverlay = styled(Box)`
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	border-radius: 4px;
	border: solid 2px #ffd044;
	background-color: rgba(255, 255, 255, 0.8);
	z-index: 10;
	display: flex;
	align-items: center;
	justify-content: center;
	pointer-events: none;
`;

export const Attachment = styled(Box)``;

Attachment.Wrapper = styled(Box)`
	border-radius: 4px;
	border: solid 1px #e4e9f2;

	position: relative;

	width: fit-content;
	height: 48px;

	&:hover {
		.delete-icon {
			opacity: 1;
			pointer-events: auto;
		}
	}
`;

Attachment.ProgressWrapper = styled(Box)`
	border-radius: 99%;
	position: absolute;
	top: -10px;
	right: -10px;
	cursor: pointer;
	background-color: #ffffff;
`;

Attachment.DeleteIconWrapper = styled(Box)`
	width: 24px;
	height: 24px;

	border-radius: 50%;

	background-color: #fff8f2;

	overflow: hidden;

	opacity: 0;

	pointer-events: none;

	transition: opacity 0.25s linear;

	display: flex;
	justify-content: center;
	align-items: center;

	position: absolute;
	top: -10px;
	right: -10px;

	cursor: pointer;
`;

Attachment.ImageWrapper = styled(Box)`
	width: 46px;
	height: 100%;

	overflow: hidden;

	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;

	border-radius: 3px;
`;

Attachment.FileWrapper = styled(Box)`
	background: white;

	padding: 12px 21px 12px 9px;

	display: flex;
	align-items: center;

	height: 100%;

	border-radius: 4px;
`;

Attachment.FileTypeIconWrapper = styled(Box)`
	margin-right: 10px;
`;

Attachment.FileInfoWrapper = styled(Box)`
	display: block;
`;

Attachment.FileName = styled(Text)`
	font-size: 12px;
	font-weight: 600;
	line-height: 1.33;
	letter-spacing: normal;
	color: #222b45;

	max-width: 140px;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
`;

Attachment.FileExtraInfo = styled(Text)`
	font-size: 10px;
	font-weight: 600;
	color: #8f9bb3;

	margin-top: 1px;
`;

export const ComposerBox = styled(Box)`
	width: 95%;
`;

export const MemberMenuIconWrapper = styled(Box)`
	padding: 4px;
	background-color: #6173fe;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 99%;
`;

export const ChatInput = styled(Box)``;

ChatInput.MemberShare = styled(Box)`
	margin-right: 16px;
`;

ChatInput.Wrapper = styled.form`
	padding: 24px 24px 24px 10px;

	width: 100%;

	min-height: 88px;

	border-top: 1px solid #edf1f7;

	&.lessPadd {
		padding-top: 12px;
	}

	${props => props.theme.breakpoints.down("sm")} {
		padding-left: 16px;
		padding-right: 16px;
	}
`;

ChatInput.ReplyBlock = styled(Box)``;

ChatInput.InputBlock = styled(Box)`
	display: flex;
	align-items: flex-start;
	justify-content: space-between;

	&.center {
		align-items: center;
	}
`;

ChatInput.ShareIconWrapper = styled(IconButton)`
	display: flex;
	justify-content: center;
	align-items: center;

	width: 40px;
	height: 40px;

	cursor: pointer;

	margin: 0 2px 0 0;
	padding: 0;

	border-radius: 8px;

	transition: background-color 0.25s linear;

	&:hover {
		background: #f7f9fc;
	}

	.MuiIconButton-label {
		margin: auto;

		width: 100%;
		height: 100%;

		& > div {
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;

			& > div {
				display: inline-flex;
			}
		}
	}

	&.space-right {
		margin-right: 20px;
	}

	${props => props.theme.breakpoints.down("sm")} {
		margin-right: 8px;
	}
`;

ChatInput.AttachmentIconWrapper = styled(Box)`
	display: flex;
	justify-content: center;
	align-items: center;

	width: 40px;
	height: 40px;

	cursor: pointer;

	margin: 0 15px 0 0;

	border-radius: 8px;

	transition: background-color 0.25s linear;

	&:hover {
		background: #f7f9fc;
	}

	svg {
		transform: rotate(45deg);
	}

	${props => props.theme.breakpoints.down("sm")} {
		margin-right: 5px;
	}
`;

ChatInput.InputWrapper = styled(Box)`
	width: 100%;
	min-height: 40px;

	border-radius: 4px;
	background-color: #f7f9fc;

	display: flex;
	align-items: center;
	justify-content: space-between;

	&.member-radius {
		border-radius: 8px;
	}
`;

ChatInput.Input = styled.textarea`
	padding: 9px 15px;

	width: calc(100% - 86px);
	height: 40px;
	max-height: 116px;
	overflow-y: scroll;

	border: none;
	background: transparent;

	font-size: 15px;
	font-weight: normal;
	line-height: 1.33;
	color: #222b45;

	resize: none;

	-ms-overflow-style: none;
	scrollbar-width: none;
	&::-webkit-scrollbar {
		display: none;
	}

	&.member-input {
		height: 54px;
	}

	&::placeholder {
		color: #8f9bb3;
	}
`;

ChatInput.SendButton = styled(Button)`
	width: 86px;
	height: 100%;

	margin: 0;

	background: transparent;

	font-size: 14px;
	text-align: center;
	color: #6173fe;
	text-transform: capitalize;
	border: none;

	> span {
		font-weight: bold;
	}
	${props => props.theme.breakpoints.down("sm")} {
		width: 64px;
	}
`;

ChatInput.AttachmentBlock = styled(Box)`
	margin-top: 16px;
	width: 100%;
	padding-left: 40px;

	&.member-block {
		padding-left: 64px;
	}
`;

ChatInput.AttachmentWrapper = styled(Box)`
	display: inline-flex;

	vertical-align: top;

	margin-bottom: 10px;

	&:not(:last-child) {
		margin-right: 17px;
	}
`;

export const AvatarWrapper = styled(Box)`
	position: relative;
	/* margin: 30px 0 3px 0; */
	padding-top: 20px;
	&.rounded-corners .MuiAvatar-root {
		border-radius: 8px;
	}
`;

export const UserAvatarName = styled(Text)`
	font-size: 12px;
	font-weight: normal;
	line-height: 1.33;
	color: #8f9bb3;

	white-space: nowrap;

	position: absolute;
	bottom: calc(100% - 16px);
	left: 50px;
`;

export const SkeletonBox = styled(Box)``;

SkeletonBox.Wrapper = styled(Box)`
	width: 100%;
	height: 100%;
`;

SkeletonBox.ChatBox = styled(Box)`
	width: 100%;
	height: calc(100% - 88px);

	padding: 24px 25px;
	overflow: hidden;
`;

SkeletonBox.InputBox = styled(Box)`
	width: 100%;
	height: 88px;

	border-top: 1px solid #edf1f7;

	padding: 8px 24px;
`;

SkeletonBox.MessageSection = styled(Box)`
	width: 100%;
	margin-bottom: 40px;

	display: flex;
	flex-direction: column;

	&.current-user {
		align-items: flex-end;
	}
`;

SkeletonBox.Message = styled(Box)`
	width: 100%;

	display: flex;

	&.current-user {
		justify-content: flex-end;
	}

	&:not(:last-child) {
		margin-bottom: 8px;
	}

	.MuiSkeleton-root {
		transform: scale(1);
	}
`;

export const DayTimestamp = styled(Text)`
	font-size: 12px;
	font-weight: 600;
	line-height: 1.33;
	letter-spacing: normal;
	text-align: center;
	color: #8f9bb3;
	margin-bottom: 8px;
`;
