import config from "config/appConfig";
import { PodcastsContextValuesType } from "types";

import { IStateProps } from "utils/StateOf";
import createStore from "utils/createStore";

const localStorageKey = `${config.GLOBAL_CONSTANTS.LOCAL_STORAGE_PREFIX}${config.HOOK_STORAGE_KEYS.FILES_DATA}`;

export const initialState: IStateProps<PodcastsContextValuesType> = {
	isLoading: false,
	podcasts: []
};

export default createStore<PodcastsContextValuesType>({
	initialState,
	persistant: true,
	persistantName: localStorageKey
});
