import React, { useCallback, useEffect } from "react";

import { Dialog, DialogContent } from "@material-ui/core";
import styled from "styled-components";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const StyledDialog = styled(({ className, modalHeight, customStyle, fullScreen, closeAction, openState, ...rest }) => {
	return (
		<Dialog
			disablePortal={true}
			onClose={closeAction}
			open={openState}
			className={className}
			classes={{ container: "container", paperFullWidth: "paper-full-width" }}
			BackdropProps={{ classes: { root: "backdrop" } }}
			style={{ position: "absolute" }}
			fullScreen={fullScreen}
			{...rest}
		/>
	);
})`
	position: absolute;
	height: ${({ modalHeight }) => (modalHeight && isNaN(modalHeight) ? modalHeight : `${modalHeight}px`)};
	max-width: ${({ maxWidth }) => (maxWidth && isNaN(maxWidth) ? maxWidth : `${maxWidth}px`)};
	& .paper-full-width {
		width: ${({ fullWidth, maxWidth }) => (!fullWidth ? "auto" : maxWidth)}px;
	}
	& .container {
		overflow: hidden;
		max-width: 100%;
	}
	& .backdrop {
		position: absolute;
		background-color: transparent;
	}

	.MuiDialog-paperScrollPaper {
		max-height: 100%;
	}
`;

const StyledDialogContent = styled(DialogContent)<{ customStyle?: string }>`
	${({ customStyle }) => customStyle};
`;

export type InPlaceModalProps = {
	children: React.ReactNode;
	modalHeight: number;
	maxWidth?: string;
	fullWidth?: boolean;
	onClose: (data?: any) => void;
	open: boolean;
	customStyle?: string;
	fullScreen?: boolean;
};

export default ({
	onClose,
	open,
	modalHeight = 500,
	maxWidth = "lg",
	fullWidth = true,
	children,
	customStyle,
	fullScreen
}: InPlaceModalProps): JSX.Element => {
	const getScrollWrapper: any = document?.querySelector("#scrollWrapper");

	useEffect(() => {
		if (getScrollWrapper?.firstElementChild) {
			open
				? (getScrollWrapper.firstElementChild.style.overflow = "hidden")
				: (getScrollWrapper.firstElementChild.style.overflow = "scroll");
		}

		return () => {
			if (getScrollWrapper?.firstElementChild) {
				getScrollWrapper.firstElementChild.style.overflow = "scroll";
			}
		};
	}, [getScrollWrapper, open]);

	const onCloseHandler = useCallback(
		e => {
			onClose && onClose(e);
		},
		[onClose]
	);

	return (
		<StyledDialog
			closeAction={onCloseHandler}
			openState={open}
			modalHeight={modalHeight}
			maxWidth={maxWidth}
			fullWidth={fullWidth}
			fullScreen={fullScreen}
		>
			<StyledDialogContent customStyle={customStyle}>{children}</StyledDialogContent>
		</StyledDialog>
	);
};
