import React, { useCallback, useEffect, useMemo } from "react";

import { DateTime } from "luxon";

import { SeriesCollection } from "modules/Manage/Data/types";
import { useCategories } from "modules/Marketplace/Data";
import { CreateUpdateSeriesWrapper, MenuDots, PlaceholderImage, TableWrapper } from "shared/Components";
import { TableColumnLoaderType } from "shared/Components/NewTable/Components";
import { Cell } from "shared/Components/NewTable/style";
import { useDebounce, usePremiumContent, useSeries } from "shared/hooks";
import { PlaceholderImageType } from "shared/types";

import { ConfirmDelete, PageTemplate } from "../../Components";

const Table = React.lazy(() => import("shared/Components/NewTable"));

const ManageSeries = () => {
	const {
		getCollections,
		getAllCollectionsCount,
		setSeriesPerPage,
		setPage,
		setSeriesDialog,
		setDeleteSeriesDialog,
		deleteCollection,
		setSeriesDetailsDialog,
		setSearchText,
		setSeries,
		getData: getSeriesData
	} = useSeries();
	const {
		seriesCount,
		seriesPerPage,
		page,
		series,
		isLoading,
		deleteSeriesDialog,
		searchText,
		createEditSeriesDialog
	} = getSeriesData();

	const { getData: getCategoriesData } = useCategories();
	const { categories } = getCategoriesData();

	const { getCoinPrice } = usePremiumContent();

	const debouncedSearchText = useDebounce(searchText, 400);

	useEffect(() => {
		getCollections({
			limit: seriesPerPage,
			offset: page,
			keyword: debouncedSearchText,
			sortBy: "timestamp"
		});
	}, [debouncedSearchText, getCollections, page, seriesPerPage]);

	useEffect(() => {
		getAllCollectionsCount({ keyword: debouncedSearchText });
	}, [getAllCollectionsCount, debouncedSearchText]);

	const handleSearch = useCallback(
		(val: string) => {
			if (val === "" || val.length >= 3) {
				setSearchText(val);
			}
		},
		[setSearchText]
	);

	const onUpdate = useCallback(
		(updatedSeriesData?: SeriesCollection, isCreateUpdate?: boolean) => {
			if (updatedSeriesData && isCreateUpdate) {
				setSeries(
					series.map(ctx =>
						ctx._id === createEditSeriesDialog.data?._id
							? {
									...ctx,
									category: updatedSeriesData.category,
									title: updatedSeriesData.title,
									meta: updatedSeriesData.meta,
									description: updatedSeriesData.description,
									priceTags: updatedSeriesData.priceTags
							  }
							: ctx
					)
				);
			} else if (updatedSeriesData && !isCreateUpdate) {
				setSeries(series.map(ctx => (ctx._id === updatedSeriesData?._id ? updatedSeriesData : ctx)));
			}
		},
		[createEditSeriesDialog, series, setSeries]
	);

	const getOptions = useCallback(
		(data: SeriesCollection, rowIndex: number) => {
			const options: {
				name: string;
				onClick: () => void;
				id: string;
			}[] = [
				{
					name: "Edit Series Details",
					onClick: () => setSeriesDialog({ open: true, data, isEditingDetail: true }),
					id: `editSeriesDetail${rowIndex + 1}`
				},
				{
					name: "Edit Series Content",
					onClick: () =>
						setSeriesDetailsDialog({
							seriesDetails: data._id,
							addContent: true,
							data
						}),
					id: `editSeriesContent${rowIndex + 1}`
				},
				{
					name: "Delete",
					onClick: () => setDeleteSeriesDialog(data._id),
					id: `deleteSerie${rowIndex + 1}`
				}
			];

			return options;
		},
		[setDeleteSeriesDialog, setSeriesDetailsDialog, setSeriesDialog]
	);

	const tableColumns = useMemo(
		() => [
			{
				alignment: "left",
				minWidth: 280,
				width: 300,
				label: <Cell.HeaderText>{seriesCount} Series & Collections</Cell.HeaderText>,
				Cell: ({ rowData: { title, filecreator, meta } }: { rowData: SeriesCollection }) => (
					<Cell.Wrapper>
						<Cell.ImageWrapper>
							{meta?.artwork?.url ? (
								<Cell.Image src={meta.artwork.url} alt={title} />
							) : (
								<PlaceholderImage
									type={PlaceholderImageType.SERIES_PREVIEW}
									width={40}
									height={40}
									viewBox={"0 0 400 400"}
								/>
							)}
						</Cell.ImageWrapper>
						<Cell.Wrapper className="column with-image">
							<Cell.Text>{title}</Cell.Text>
							{filecreator?.firstName && <Cell.Text className="light">By {filecreator.firstName}</Cell.Text>}
						</Cell.Wrapper>
					</Cell.Wrapper>
				),
				loaderTemplate: TableColumnLoaderType.imageWthTwoTextRows,
				dataKey: "series"
			},
			{
				alignment: "left",
				label: <Cell.HeaderText>Category</Cell.HeaderText>,
				minWidth: 160,
				Cell: ({ rowData: { category, categoryId } }: { rowData: SeriesCollection }) => {
					const cat = category ? category.name : categories.find(category => category._id === categoryId)?.name || "-";
					return (
						<Cell.Wrapper>
							<Cell.Text>{cat}</Cell.Text>
						</Cell.Wrapper>
					);
				},
				dataKey: "category"
			},
			{
				alignment: "left",
				minWidth: 120,
				label: <Cell.HeaderText>Content</Cell.HeaderText>,
				Cell: ({ rowData: { itemIds } }: { rowData: SeriesCollection }) => (
					<Cell.Wrapper>
						<Cell.Text>{itemIds.length}</Cell.Text>
					</Cell.Wrapper>
				),
				dataKey: "content"
			},
			{
				alignment: "left",
				minWidth: 120,
				label: <Cell.HeaderText>Price</Cell.HeaderText>,
				Cell: ({ rowData: { priceTags } }: { rowData: SeriesCollection }) => {
					const price = getCoinPrice(priceTags);
					return (
						<Cell.Wrapper>
							<Cell.Text>{price ? `${price} Coins` : "Free"}</Cell.Text>
						</Cell.Wrapper>
					);
				},
				dataKey: "price"
			},
			{
				alignment: "left",
				label: <Cell.HeaderText>Created Date</Cell.HeaderText>,
				minWidth: 160,
				Cell: ({ rowData: { createdAt } }: { rowData: SeriesCollection }) => {
					return (
						<Cell.Wrapper className="column with-image">
							<Cell.Text>{DateTime.fromISO(createdAt).toFormat("MMM d, yyyy")}</Cell.Text>
							<Cell.Text className="light">{DateTime.fromISO(createdAt).toFormat("t")}</Cell.Text>
						</Cell.Wrapper>
					);
				},
				loaderTemplate: TableColumnLoaderType.twoTextRows,
				dataKey: "createdAt"
			},
			{
				alignment: "right",
				label: "",
				width: 50,
				Cell: ({ rowData, rowIndex }: { rowData: SeriesCollection; rowIndex: number }) => (
					<MenuDots
						options={getOptions(rowData, rowIndex)}
						vertical
						removeBg
						removeshadow
						removeSideMargin
						menuId={`moreActions${rowIndex + 1}`}
					/>
				),
				loaderTemplate: TableColumnLoaderType.menuDots,
				dataKey: "menu"
			}
		],
		[getCoinPrice, getOptions, seriesCount, categories]
	);

	const handleCreateNew = () => {
		setSeriesDialog({ open: true });
	};

	const filteredSeries = useMemo(() => series.filter((_, i) => i < seriesPerPage), [series, seriesPerPage]);

	const handleChangeRowsPerPage = useCallback(
		(newPageSize: number) => {
			setSeriesPerPage(newPageSize);
			setPage(1);
		},
		[setSeriesPerPage, setPage]
	);

	const TableBlock = useMemo(
		() => (
			<TableWrapper sizes={{ horizontalTablet: 900 }}>
				<Table
					rowHeight={72}
					columns={tableColumns}
					data={filteredSeries}
					loading={isLoading}
					paginated
					totalDataCount={seriesCount}
					page={page}
					pageSize={seriesPerPage}
					onChangePage={setPage}
					onChangePageSize={handleChangeRowsPerPage}
				/>
			</TableWrapper>
		),
		[tableColumns, filteredSeries, seriesCount, isLoading, page, seriesPerPage, setPage, handleChangeRowsPerPage]
	);

	return (
		<>
			<PageTemplate
				title="Series & Collections"
				showReportedLink
				isLoading={isLoading}
				isNoData={!filteredSeries.length}
				emptyText="You don't have any Series in your community yet."
				searchPlaceholder="Search Series"
				onSearchUpdate={handleSearch}
				actionText="Create Series"
				onCreateClick={handleCreateNew}
				searchId="searchSeries"
				actionId="createSeries"
			>
				{TableBlock}
			</PageTemplate>
			{deleteSeriesDialog && (
				<ConfirmDelete
					headline="Collection"
					onClose={() => setDeleteSeriesDialog()}
					onDelete={async () => {
						await deleteCollection(deleteSeriesDialog);
						getCollections({ limit: seriesPerPage, offset: page, keyword: debouncedSearchText });
					}}
					deleteBtnId="deleteSeriesConfirmation"
				/>
			)}
			<CreateUpdateSeriesWrapper shouldUpdateIgnore onUpdateSeriesDialog={onUpdate} />
		</>
	);
};

export default ManageSeries;
