import React, { useCallback, useEffect, useState } from "react";

import { useHistory, useLocation } from "react-router-dom";

import { routes as authRoutes } from "apps/Auth/constants";
import { routes as adminRoutes } from "apps/RegularUser/constants";
import { useAuth } from "modules/App/Data";
import { view } from "modules/MemberHome";

import { useMemberRoutes, useRoutes, useUser } from "shared/hooks";
import useJwt from "shared/services/api/core/jwt";
import { UserStatus } from "shared/types";

const FillProfilePage = () => {
	const history = useHistory();
	const { search } = useLocation();
	const jwtService = useJwt();

	const { getMemberRoutesData } = useMemberRoutes();
	const { routes } = getMemberRoutesData();

	const { setGlobalUserId, resetState, getData: getUserData } = useUser();
	const { globalUserId } = getUserData();

	const { clearStorage } = useRoutes();
	const { clearState, login, getUser, setEmail, setInvitationData } = useAuth();

	const query = new URLSearchParams(search);
	const token = query.get("token");
	const workspace = query.get("workspace");
	const guId = query.get("globalUserId");
	const communityUrl = query.get("communityUrl");
	const invitationId = query.get("invitationId");
	const switchCommunity = query.get("switchCommunity");

	const [preparing, setPreparing] = useState(!!token);
	const [initialised, setInitialised] = useState(false);

	const verifyData = useCallback(async () => {
		try {
			const user = await getUser();
			setEmail(user.email);
			setPreparing(false);

			if (user.status === UserStatus.ON_BOARDED) {
				history.push(routes?.member.home.getPath());
			}
		} catch (error) {
			console.log(error);
			history.push(authRoutes.auth.signIn.getPath());
		}
	}, [getUser, setEmail, history, routes?.member.home]);

	useEffect(() => {
		if (token && workspace && !initialised) {
			clearStorage();
			clearState();
			resetState();
			login(token, workspace);

			verifyData();

			setInitialised(true);
		}
	}, [clearState, clearStorage, resetState, token, workspace, login, verifyData, initialised]);

	useEffect(() => {
		if (guId && !globalUserId) {
			setGlobalUserId(guId);
		}
	}, [globalUserId, guId, setGlobalUserId]);

	useEffect(() => {
		if (initialised && invitationId && communityUrl) {
			setInvitationData({
				invitationId,
				communityUrl
			});
		}
	}, [initialised, invitationId, communityUrl, setInvitationData]);

	if (!jwtService.getJwt() && !token && !workspace) {
		history.push(authRoutes.auth.signIn.getPath());
	}

	return preparing ? (
		<p>LOADING...</p>
	) : (
		<view.FillProfile
			switchCommunity={!!switchCommunity}
			fillProfileBirthdayUrl={routes?.member.fillProfile.date.getPath()}
			adminDashboardUrl={adminRoutes.dashboard.getPath()}
		/>
	);
};

export default FillProfilePage;
