import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";

import { Podcast } from "types/PodcastsContextValuesType";

import { ReactComponent as CopyIcon } from "assets/icons/copy.svg";
import { ReactComponent as SoundWaveIcon } from "assets/icons/iconSoundWave.svg";
import { MenuDotsOption, PlaceholderImage } from "shared/Components";
import { useNotification, useOnScreen, usePlayer } from "shared/hooks";
import usePodcasts from "shared/hooks/usePodcasts";

import { PlaceholderImageType, TrackModel } from "shared/types";

import { Button, Loader } from "shared/ui-kit";

import { CollectionRow, EntityTemplate } from "../../Components";
import { CollectionSkeletonTypesEnum } from "../../Components/CollectionRow";
import RowTemplate from "../../Components/CollectionRow/RowTemplate";
import { RefBox } from "../../shared/MemberDialog/style";
import { Container, ContentHeader, ContentHeaderWrapper } from "../Collection/style";
import { ContentTitle } from "../Collections/style";

const PodcastPage: FC<{ id: string }> = ({ id }) => {
	const [filters, setFilters] = useState({ offset: 1 });
	const [loading, setLoading] = useState({
		episodes: false,
		following: false
	});
	const [noMoreItems, setNoMoreItems] = useState(false);

	const [podcast, setPodcast] = useState<Podcast | null>(null);
	const [episodes, setEpisodes] = useState<TrackModel[]>([]);

	const { showMessage } = useNotification();

	const { setPlayTracks, setPlay, getData: getPlayerData } = usePlayer();
	const { playTracks, play } = getPlayerData();

	const { getPodcasts, getPodcastsData, getEpisodes, followPodcast } = usePodcasts();
	const { isLoading } = getPodcastsData();

	useEffect(() => {
		getPodcasts({ offset: 1, limit: 1, showId: id, noStateUpdate: true }).then(data => {
			if (data && !!data.length) {
				setPodcast(data[0]);
			}
		});
	}, [getPodcasts, id]);

	useEffect(() => {
		const getEpisodesData = async () => {
			setLoading(ctx => ({
				...ctx,
				episodes: true
			}));
			const eps = await getEpisodes({ offset: filters.offset, showId: id });
			if (!eps?.length) {
				setNoMoreItems(true);
			} else {
				setEpisodes(ctx => (filters.offset > 1 ? [...ctx, ...eps] : eps));
			}

			setLoading(ctx => ({ ...ctx, episodes: false }));
		};

		getEpisodesData();
	}, [filters.offset, getEpisodes, id]);

	const options: MenuDotsOption[] = useMemo(
		() => [
			// TODO: Share with members when messaging on member side is done ( https://zpl.io/L48GGBQ )
			// {
			// 	name: "Share with Connections",
			// 	onClick: () => {},
			// 	icon: <ShareIcon />
			// },
			{
				name: "Copy Link",
				onClick: async () => {
					await navigator.clipboard.writeText(window.location.href);
					showMessage("Successfully copied link to your clipboard");
				},
				icon: <CopyIcon />
			}
		],
		[showMessage]
	);

	const lastItemRef = useRef<HTMLDivElement>(null);
	const onScreen = useOnScreen(lastItemRef);

	useEffect(() => {
		if (!loading.episodes && episodes.length >= 20 && onScreen && !noMoreItems) {
			setFilters(ctx => ({ ...ctx, offset: ctx.offset + 1 }));
		}
	}, [episodes.length, loading.episodes, noMoreItems, onScreen]);

	const handlePlayShow = useCallback(
		(episode: string) => {
			setPlayTracks({
				idx: episodes.findIndex(m => m._id === episode),
				tracks: {
					albumId: id,
					items: episodes
				}
			});
		},
		[episodes, id, setPlayTracks]
	);

	const renderPodcastContent = useCallback(() => {
		const title = <ContentTitle variant="subtitle1">All Episodes</ContentTitle>;
		return (
			<>
				<ContentHeaderWrapper>
					<ContentHeader>{title}</ContentHeader>
				</ContentHeaderWrapper>
				{episodes.map((item, i) => (
					<RowTemplate
						key={item._id}
						id={item._id}
						order={i + 1}
						image={item.artwork}
						duration={item.duration}
						icon={<SoundWaveIcon />}
						name={item.title}
						locked={false}
						createdAt={item.createdAt}
						type="TRACK"
						handleClick={() =>
							playTracks.tracks?.albumId === id && playTracks.idx === i ? setPlay(!play) : handlePlayShow(item._id)
						}
						category={item.artist}
						updatePlayButton
						current={playTracks.tracks?.albumId === id && playTracks.idx === i}
					/>
				))}
				{!loading.episodes && <RefBox ref={lastItemRef} />}
			</>
		);
	}, [episodes, handlePlayShow, id, play, playTracks, setPlay, loading.episodes]);

	const handleFollowPodcast = useCallback(async () => {
		if (podcast) {
			setLoading(ctx => ({ ...ctx, following: true }));
			const { success } = await followPodcast({ showId: podcast._id, isFollowing: podcast.isFollowing });
			setLoading(ctx => ({ ...ctx, following: false }));

			if (success) {
				showMessage(`Successfully ${podcast.isFollowing ? "Unfollowed" : "followed"} podcast.`);
				setPodcast(ctx => (ctx ? { ...ctx, isFollowing: !ctx.isFollowing } : null));
			}
		}
	}, [followPodcast, podcast, showMessage]);

	if (!isLoading && !podcast) return null;

	return (
		<Container>
			<EntityTemplate
				loading={isLoading && filters.offset === 1}
				coverPhoto={podcast?.image}
				placeholderImg={
					<PlaceholderImage
						type={PlaceholderImageType.SERIES_PREVIEW}
						width={224}
						height={224}
						viewBox={"0 0 400 400"}
					/>
				}
				pretitle={podcast?.author}
				title={podcast?.title || ""}
				description={podcast?.description}
				options={options}
				unlockedBlock={
					<Button disabled={loading.following} onClick={handleFollowPodcast}>
						{loading.following ? (
							<Loader show variant="indeterminate" color="inherit" size="16px" />
						) : podcast?.isFollowing ? (
							"Unfollow"
						) : (
							"Follow"
						)}
					</Button>
				}
			>
				{renderPodcastContent()}
				{loading.episodes && (
					<>
						<CollectionRow skeleton skeletonType={CollectionSkeletonTypesEnum.PODCAST} />
						<CollectionRow skeleton skeletonType={CollectionSkeletonTypesEnum.PODCAST} />
						<CollectionRow skeleton skeletonType={CollectionSkeletonTypesEnum.PODCAST} />
						<CollectionRow skeleton skeletonType={CollectionSkeletonTypesEnum.PODCAST} />
						<CollectionRow skeleton skeletonType={CollectionSkeletonTypesEnum.PODCAST} />
					</>
				)}
			</EntityTemplate>
		</Container>
	);
};

export default PodcastPage;
