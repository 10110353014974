import React, { useCallback, useMemo, useState } from "react";

import * as R from "ramda";
import { useHistory, useLocation } from "react-router-dom";

import { UserRoles } from "types";

import { useGroup, useMemberRoutes, useUser } from "shared/hooks";
import { useS3Uploader } from "shared/services/s3Uploader";
import { GroupModel, ToolbarActions } from "shared/types";
import { dataUrlToFile } from "utils/serviceUtils/helpers";

import CreateGroupManageView from "./ManageView";
import CreateGroupMemberView from "./MemberView";

import { SetupPriceMethodsHandlers } from "../SetupPrice";

interface Props {
	onClose: (event: { status?: "updated" | "created"; group?: GroupModel }, reason: string) => void;
	isSubgroup?: boolean;
	editableModel?: GroupModel;
	defaultParent?: GroupModel;
	open: boolean;
	onSelect?: (content: any) => void;
}

const CreateGroup: React.FC<Props> = ({ open, onClose, editableModel, defaultParent, onSelect, isSubgroup }) => {
	const { uploadFile } = useS3Uploader();
	const location = useLocation();
	const history = useHistory();
	const { getMemberRoutesData } = useMemberRoutes();
	const { routes } = getMemberRoutesData();

	const [groupHidden, setGroupHidden] = useState(false);
	const setupPriceFormRef = React.useRef<SetupPriceMethodsHandlers>(null);

	const { createGroup, inviteMembers, updateGroup, getData: getGroupData } = useGroup();
	const { groupImageUploadUrl } = getGroupData();

	const { getData: getUserData } = useUser();
	const { isMemberView, user } = getUserData();

	const isMemberRole = useMemo(() => user?.role === UserRoles.Member || false, [user?.role]);

	const [uploadingImg, setUploadingImg] = useState(false);

	const onSubmit = async data => {
		delete data.priceChanged;

		const filteredData = R.omit(["priceChanged", "adminIds"], data);

		const parentId = data?.parentGroup?._id;
		const priceTags = setupPriceFormRef.current?.getPriceTags();

		const group = editableModel
			? await updateGroup({
					...editableModel,
					...filteredData,
					disablePosts: filteredData.disablePosts === "permitted",
					hidden: groupHidden,
					parentId: parentId || editableModel?.parentId,
					photoChanged: filteredData.coverUrl !== editableModel?.coverUrl,
					placeChanged: filteredData?.location?.place_id !== editableModel?.placeId
			  })
			: await createGroup({
					...filteredData,
					disablePosts: filteredData.disablePosts === "permitted",
					hidden: groupHidden,
					parentId,
					priceTags,
					audience: filteredData?.audience?.key || filteredData?.audience
			  });

		if (group) {
			onClose &&
				onClose(
					{
						status: editableModel ? "updated" : "created",
						group: editableModel
							? {
									...editableModel,
									...group,
									isNational: (filteredData?.audience?.key || filteredData?.audience) === "national",
									chatEnabled: filteredData.chatEnabled,
									createdAt: new Date().toISOString()
							  }
							: {
									...group,
									isNational: filteredData?.audience?.key || filteredData?.audience,
									createdAt: new Date().toISOString()
							  }
					},
					ToolbarActions.SELECT_GROUP
				);
			onSelect && onSelect(group);

			if (data.adminIds) {
				inviteMembers(
					data.adminIds.map(admin => admin.key),
					group.slug
				);
			}

			location.pathname === routes?.member.groups.getPath() &&
				history.push(`${routes?.member.group.getPath()}/${group.slug}`);
		}
	};

	const onUploaderChange = useCallback(
		async (files: any[], onChange: (...event: any[]) => void) => {
			const img = files && files.length ? files[0] : null;
			const correctFile = typeof img === "string" ? await dataUrlToFile(img, "test") : img;
			if (img) {
				setUploadingImg(true);

				const data = await uploadFile({
					file: correctFile as File,
					communityName: groupImageUploadUrl
				});
				onChange(data?.publicUrl);

				setUploadingImg(false);
			} else {
				onChange(null);
			}
		},
		[groupImageUploadUrl, uploadFile]
	);

	if (isMemberView) {
		return (
			<CreateGroupMemberView
				open={open}
				onClose={onClose}
				editableModel={editableModel}
				onUploaderChange={onUploaderChange}
				handleCreateGroup={onSubmit}
				setupPriceFormRef={setupPriceFormRef}
				defaultParent={defaultParent}
				groupHidden={groupHidden}
				setGroupHidden={setGroupHidden}
				isMember={isMemberRole}
				isUploading={uploadingImg}
			/>
		);
	}

	return (
		<CreateGroupManageView
			open={open}
			onClose={onClose}
			editableModel={editableModel}
			onUploaderChange={onUploaderChange}
			handleCreateGroup={onSubmit}
			defaultParent={defaultParent}
			groupHidden={groupHidden}
			setGroupHidden={setGroupHidden}
			isSubgroup={isSubgroup}
			isUploading={uploadingImg}
		/>
	);
};

export default CreateGroup;
