import config from "config/appConfig";

import { IWorkspace, Location, UserIPDetailsType, UserModel } from "shared/types";
import {
	BlockedUser,
	CertificationType,
	ExperienceLocationType,
	ProfileEducationType,
	ProfileJobType,
	VolunteerType
} from "shared/types/UserModel";
import { IStateProps } from "utils/StateOf";
import createStore from "utils/createStore";

const localStorageKey = `${config.GLOBAL_CONSTANTS.LOCAL_STORAGE_PREFIX}${config.HOOK_STORAGE_KEYS.USER_DATA}`;

export interface Suggestion {
	_id: string;
	label: string;
}
export interface Values {
	user?: UserModel;
	workspaces?: IWorkspace[];
	communityAppLink: string;
	globalUserId?: string;
	userVerified: boolean;
	ignoreGuidedTour: boolean;
	jobTitleSuggestions: Suggestion[];
	industrySuggestions: Suggestion[];
	companiesSuggestions: Suggestion[];
	causeSuggestions: Suggestion[];
	skillsSuggestions: Suggestion[];
	languagesSuggestions: Suggestion[];
	volunteerOrganizationSuggestions: Suggestion[];
	schoolSuggestions: Suggestion[];
	degreeSuggestions: Suggestion[];
	specializationSuggestions: Suggestion[];
	licenseSuggestions: Suggestion[];
	licenseOrganizationSuggestions: Suggestion[];
	profileDetailsDialog: boolean;
	updateUserDialog: boolean;
	addSkillsDialog: boolean;
	addGoalsDialog: boolean;
	addInterestsDialog: boolean;
	addSocialAccountsDialog: boolean;
	addLanguagesDialog: boolean;
	myConnectionsDialog: boolean;
	myExperienceDialog: boolean;
	experienceDialogLocation?: ExperienceLocationType;
	newJobDialog?: {
		open: boolean;
		item?: ProfileJobType;
	};
	newVolunteerDialog?: {
		open: boolean;
		item?: VolunteerType;
	};
	newEducationDialog?: {
		open: boolean;
		item?: ProfileEducationType;
	};
	newLicenseDialog?: {
		open: boolean;
		item?: CertificationType;
	};
	loading: boolean;
	goals: {
		category: string;
		ids: Suggestion[];
	}[];
	interests: {
		category: string;
		ids: Suggestion[];
	}[];
	suggestedLocations: Location[];
	isMemberView: boolean;
	blockedUsers: BlockedUser[];
	userIPDetails: Partial<UserIPDetailsType>;
}

export enum SuggestionTypes {
	volunteerOrganization = "volunteerOrganization",
	jobTitle = "jobTitle",
	industry = "industry",
	company = "company",
	cause = "cause",
	school = "school",
	degree = "degree",
	specialization = "specialization",
	license = "license",
	licenseOrganization = "licenseOrganization",
	licenseName = "licenseName",
	skill = "skill",
	language = "language"
}

export const initialState: IStateProps<Values> = {
	user: undefined,
	workspaces: [],
	communityAppLink: "",
	userVerified: true,
	ignoreGuidedTour: false,
	jobTitleSuggestions: [],
	industrySuggestions: [],
	companiesSuggestions: [],
	causeSuggestions: [],
	skillsSuggestions: [],
	languagesSuggestions: [],
	volunteerOrganizationSuggestions: [],
	schoolSuggestions: [],
	degreeSuggestions: [],
	specializationSuggestions: [],
	licenseSuggestions: [],
	licenseOrganizationSuggestions: [],
	profileDetailsDialog: false,
	updateUserDialog: false,
	addSkillsDialog: false,
	addGoalsDialog: false,
	addInterestsDialog: false,
	addLanguagesDialog: false,
	myConnectionsDialog: false,
	addSocialAccountsDialog: false,
	myExperienceDialog: false,
	loading: false,
	goals: [],
	interests: [],
	suggestedLocations: [],
	isMemberView: false,
	blockedUsers: [],
	userIPDetails: { lat: 0, lon: 0 }
};

export default createStore<Values>({
	initialState,
	persistant: true,
	persistantName: localStorageKey
});
