import styled, { css } from "styled-components";

import { Box, Text } from "shared/ui-kit";

export const Container = styled(Box)`
	padding: 0 8px;
	margin: 0 auto 15px auto;

	display: flex;
	justify-content: center;

	&.column {
		flex-direction: column;
		justify-content: flex-start;

		max-width: 912px;
	}
`;

export const BoxItemWrapper = styled(Box)`
	&.highlight {
		div:first-child {
			background-color: #edf1f7;
			transition: ease-out 0.1s;
			border-radius: 12px;
		}
	}

	:not(:last-child) {
		margin-bottom: 24px;
	}

	&.no-margin {
		margin-bottom: 0;
	}
`;

export const Column = styled(Box)<{ left?: boolean; middle?: boolean; right?: boolean; withMargin?: boolean }>`
	width: ${({ left, middle }) => (left ? 320 : middle ? 568 : 320)}px;

	${props => props.theme.breakpoints.down("sm")} {
		${({ middle }) =>
			middle &&
			css`
				display: none;
			`}
		margin-right: 0;
		margin-top: 8px;
		width: 100%;
	}

	${({ withMargin }) =>
		withMargin &&
		css`
			margin-right: 16px;
		`}
`;

export const PostMenu = styled(Box)`
	width: 320px;

	${props => props.theme.breakpoints.down("sm")} {
		width: 100%;
	}

	padding: 12px 8px 22px 8px;

	border-radius: 12px;

	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08), inset 0 0 0 0.5px #c5cee0;

	background-color: #fff;
`;

PostMenu.Title = styled(Text)`
	font-size: 22px;
	font-weight: bold;
	line-height: 1.45;
	letter-spacing: normal;
	color: #222b45;
`;

PostMenu.Body = styled(Box)`
	margin-top: 20px;
`;

export const PostPreview = styled(Box)``;

PostPreview.Wrapper = styled(Box)`
	display: flex;
	align-items: center;
	justify-content: space-between;

	padding: 12px 8px;

	border-radius: 8px;

	background-color: transparent;

	transition: background-color 0.25s linear;

	&:hover {
		background-color: #edf1f7;
	}
`;

PostPreview.MainBlock = styled(Box)`
	display: flex;
	align-items: center;
`;

PostPreview.IconWrapper = styled(Box)<{ imgUrl: string }>`
	width: 48px;
	height: 48px;

	border-radius: 50%;

	overflow: hidden;

	background-color: #c5cee0;

	background-image: ${({ imgUrl }) => (imgUrl ? `url(${imgUrl})` : "none")};
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
`;

PostPreview.Body = styled(Box)`
	margin-left: 16px;
`;

PostPreview.CategoryWrapper = styled(Box)`
	padding: 2px 8px;

	border-radius: 8px;

	box-shadow: inset 0 0 0 0.5px #c5cee0;

	background-color: #f7f9fc;

	display: flex;
	align-items: center;

	width: fit-content;
`;

PostPreview.CategoryIconWrapper = styled(Box)`
	width: 16px;
	height: 16px;

	margin-right: 4px;

	svg {
		width: 100%;
		height: 100%;

		fill: ${({ color }) => color};

		padding: 0;
		margin: 0 !important;
	}
`;

PostPreview.CategoryName = styled(Text)`
	font-size: 13px;
	font-weight: 600;
	line-height: 1.85;
	letter-spacing: normal;
	color: #222b45;
`;

PostPreview.DateTextRow = styled(Box)`
	display: flex;
	align-items: center;

	margin-top: 6px;
`;

PostPreview.DateText = styled(Text)`
	font-size: 12px;
	font-weight: normal;
	line-height: 1.33;
	letter-spacing: normal;
	color: #8f9bb3;
`;

PostPreview.Date = styled(Text)`
	font-size: 12px;
	font-weight: 600;
	line-height: 1.33;
	letter-spacing: normal;
	color: #222b45;

	margin-left: 4px;
`;

PostPreview.ManageBlock = styled(Box)``;
