import React, { useMemo } from "react";

import { useActivities } from "modules/Activity/Data";

import { ActivitySettingsModel, ActivitySettingsType } from "modules/Activity/Data/types/ActivityModel";

import { ScrollBarWrapper } from "modules/MemberHome/View/shared/style";
import { useCommunity, useUser } from "shared/hooks";

import { NotificationSwitch } from "./NotificationSwitch";
import { NOTIFICATION_SETTING } from "./constants";

import {
	Inner,
	Notification,
	NotificationDescription,
	NotificationIcon,
	NotificationInfo,
	NotificationSwitchWrapper,
	NotificationTitle,
	Wrapper
} from "./styles";

export function Notifications() {
	const { changeSettings } = useActivities();

	const { getData: getUserData } = useUser();
	const { user } = getUserData();

	const { getData: getCommnunityData } = useCommunity();
	const { isWhitelabelAppMode, workspace } = getCommnunityData();

	const workspaceName = useMemo(() => workspace?.meta?.displayName || workspace?.name || "", [workspace]);

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>, type: ActivitySettingsType) => {
		event.persist();

		if (activitySettings) {
			const newSettings = { ...activitySettings };
			newSettings[type] = event.target.checked;
			changeSettings(newSettings);
		}
	};

	const activitySettings: ActivitySettingsModel | undefined = useMemo(
		() => (user?.profiles?.length ? user?.profiles[0].notificationSettings : undefined),
		[user?.profiles]
	);

	return (
		<Wrapper>
			<ScrollBarWrapper className="scrollWrapper" height="100%">
				<Inner>
					{NOTIFICATION_SETTING.filter(x =>
						x?.hideForNonWhitelabel ? isWhitelabelAppMode || workspaceName.toLowerCase() === "vyoo" : true
					).map(item => (
						<Notification key={item.type}>
							<NotificationIcon type={item.type}>{item.icon}</NotificationIcon>
							<NotificationInfo>
								<NotificationTitle>{item.title}</NotificationTitle>
								<NotificationDescription>{item.description}</NotificationDescription>
							</NotificationInfo>
							<NotificationSwitchWrapper>
								<NotificationSwitch
									checked={!!activitySettings?.[item.type]}
									onChange={e => handleChange(e, item.type)}
								/>
							</NotificationSwitchWrapper>
						</Notification>
					))}
				</Inner>
			</ScrollBarWrapper>
		</Wrapper>
	);
}
