import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";

import { Switch, TextField, useMediaQuery } from "@material-ui/core";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { DatePicker, TimePicker } from "@material-ui/pickers";

import { DateTime } from "luxon";

import { Controller, useForm, useWatch } from "react-hook-form";

import { css } from "styled-components";

import { ReactComponent as DownloadIcon } from "assets/icons/icon-download.svg";
import { SeriesCollection } from "modules/Manage/Data/types";
import { TrackVideoPreviewCutter } from "modules/Manage/View/Components";
import { SubText } from "modules/Manage/View/Components/CreateUpdateAlbumDialog";
import { FeatureBlock } from "modules/Manage/View/Containers/style";
import { CategoryProps } from "modules/Marketplace/Data/types";

import { Dialog, SetupPrice, UploadProgressBar } from "shared/Components";
import { useNotification, useVideo } from "shared/hooks";
import useScrollToXPosition from "shared/hooks/useScrollToXPosition";
import { useS3Uploader } from "shared/services/s3Uploader";
import { GroupModel, VideoModel } from "shared/types";
import { Box, Icon, Loader, Text, Uploader, orientationConst } from "shared/ui-kit";
import * as appTheme from "theme/default";

import { mergeDateAndTime } from "utils/serviceUtils/helpers";

import {
	AutocompleteWrapper,
	FormControl,
	IconWrapper,
	StepBox,
	StepColumn,
	StyledActionButton,
	UploadProgressBarWrapper,
	UploaderWrapper,
	VideoPreviewWrapper
} from "./style";

import ConfirmLeavePopup from "../ConfirmLeave";
import useConfirmLeavePopup from "../ConfirmLeave/hooks/useConfirmLeavePopup";
import RadioItem from "../RadioItem";
import { SetupPriceMethods } from "../SetupPrice";

interface Props {
	open: boolean;
	onClose: () => void;
	editableModel?: VideoModel;
	suggestedCategories: CategoryProps[];
	loadSuggestedCategories: (name: string) => Promise<void>;
	loadSuggestedCollections: (name: string) => Promise<void>;
	suggestedCollections: SeriesCollection[];
	onUploaderChange: (files: any[], onChange: (...event: any[]) => void) => Promise<void>;
	setPreview: React.Dispatch<
		React.SetStateAction<{
			start: number;
			end: number;
		}>
	>;
	preview: {
		start: number;
		end: number;
	};
	setupPriceFormRef: React.RefObject<SetupPriceMethods>;
	createVideo: (data: any) => Promise<void>;
	videoFileUrl?: string;
}

const CreateVideoManageView: FC<Props> = ({
	open,
	onClose,
	editableModel,
	setPreview,
	preview,
	loadSuggestedCategories,
	suggestedCategories,
	loadSuggestedCollections,
	suggestedCollections,
	onUploaderChange,
	setupPriceFormRef,
	createVideo
}) => {
	const bodyRef = useRef<HTMLFormElement>();
	const scrollToXPositionOnFocus = useScrollToXPosition(bodyRef?.current);

	const [step, setStep] = useState(1);

	const {
		handleSubmit,
		control,
		reset,
		errors,
		watch,
		formState: { isValid, isDirty }
	} = useForm({
		mode: "onChange"
	});

	const isMobile = useMediaQuery(appTheme.default.breakpoints.down("sm"));

	const { getData: getS3UploaderData } = useS3Uploader();
	const { loadingSignedUrl, uploading, uploadingProgress } = getS3UploaderData();

	const { setVideoFileUrl, getData: getVideoData } = useVideo();
	const { videoName, videoFileUrl, creating } = getVideoData();

	const { showMessage } = useNotification();

	const category = useWatch({ control, name: "category" });

	const { description } = watch(["description"]);

	useEffect(() => {
		open && setStep(1);
	}, [open]);

	useEffect(() => {
		if (Object.keys(errors).length) {
			showMessage(
				<ul>
					{Object.values(errors).map((p, i) => (
						<li key={i}>{p.message}</li>
					))}
				</ul>,
				3
			);
		}
	}, [errors, showMessage]);

	const selectedCollections = useCallback((): { key: string; value: string }[] | undefined => {
		if (!editableModel?.collections || !editableModel?.collections.length) return undefined;
		const collections: { key: string; value: string }[] = [];

		editableModel.collections.forEach(obj => {
			collections.push({ key: obj._id, value: obj.title });
		});

		return collections;
	}, [editableModel]);

	useEffect(() => {
		if (editableModel) {
			reset({
				...editableModel,
				videoFileUrl: editableModel.meta.video.url,
				videoImageUrl: editableModel.meta.artwork.url,
				category: editableModel?.categoryId
					? { name: editableModel?.category?.name, _id: editableModel?.category?._id }
					: undefined,
				collection: selectedCollections()
			});
			setPreview({
				start: editableModel.meta.previewStart,
				end: editableModel.meta.previewStop
			});
			setVideoFileUrl(editableModel.meta?.video.url);
		} else {
			reset(
				videoName
					? {
							title: videoName
					  }
					: {}
			);
		}
	}, [editableModel, reset, videoName, setVideoFileUrl, setPreview, selectedCollections]);

	const {
		handleLeavePageConfirmed,
		closeConfirmPopup,
		handleClose,
		getData: getConfirmLeavePopupData
	} = useConfirmLeavePopup({
		onClose,
		open
	});
	const { showConfirmPopup } = getConfirmLeavePopupData();

	const onSubmit = data => {
		delete data.priceChanged;

		let scheduleDate: Date | undefined;

		if (data.scheduleDate && data.scheduleTime) {
			scheduleDate = mergeDateAndTime(data.scheduleDate, data.scheduleTime);
		}

		const collectionIds: string[] = [];
		if (data.collection) {
			data.collection.forEach(obj => collectionIds.push(obj.key));
		}

		createVideo({
			...data,
			scheduleDate,
			categoryId: data?.category._id,
			collectionIds
		});
	};

	const ActionButton = (
		<StyledActionButton
			type="submit"
			onClick={() => (step === 1 ? setStep(2) : handleSubmit(onSubmit)())}
			leftIcon={
				creating || uploading || loadingSignedUrl ? (
					<Loader size="1rem" show={true} color="primary" variant="indeterminate" />
				) : undefined
			}
			disabled={
				(step === 1
					? errors?.title || errors?.category || errors?.description || !description || !category
					: !isValid || !isDirty) ||
				uploading ||
				loadingSignedUrl ||
				creating ||
				!videoFileUrl
			}
		>
			{step === 1 ? "Next" : editableModel ? "Update Video" : "Create Video"}
		</StyledActionButton>
	);

	const Title = useMemo(
		() => (
			<>
				<Text variant="h7">{editableModel ? "Update" : "Create"} Video</Text> <SubText>• Step {step}/2</SubText>
			</>
		),
		[editableModel, step]
	);

	const UploaderBlock = useMemo(
		() => (
			<StepColumn className={"narrow"}>
				<UploaderWrapper>
					<Controller
						name="videoImageUrl"
						control={control}
						render={({ value, onChange }) => (
							<Uploader
								urls={
									!value && editableModel && editableModel?.meta?.artwork.url
										? [`${editableModel.meta.artwork.url}`]
										: value
										? [value]
										: undefined
								}
								onChange={(files: any) => onUploaderChange(files, onChange)}
								label="Upload Thumbnail"
								orientation={orientationConst.vertical}
								width={"100%"}
								description={
									<>
										Drag and Drop File Here or <span className="anchor">Browse</span> to Choose a File
									</>
								}
								accept={[
									{
										fileType: "image/png, image/jpeg, image/svg+xml, image/x-eps",
										name: "png, jpg, svg, eps"
									}
								]}
								icon={
									<Icon
										group={""}
										fill={"#c5cee0"}
										name={"cloud-upload"}
										width={64}
										height={50}
										viewBox={"4 2 18 19"}
									/>
								}
							/>
						)}
					/>
				</UploaderWrapper>
			</StepColumn>
		),
		[control, onUploaderChange, editableModel]
	);

	return (
		<>
			{open && (
				<ConfirmLeavePopup
					handleLeavePage={handleLeavePageConfirmed}
					open={showConfirmPopup}
					onClose={closeConfirmPopup}
				/>
			)}
			<form onSubmit={handleSubmit(onSubmit)}>
				<Dialog
					title={Title}
					hasBackButton={step === 2}
					onBack={() => setStep(1)}
					open={open}
					onClose={() => handleClose(true)}
					footer={ActionButton}
					bodyCustomStyles={css`
						position: relative;
					`}
					maxWidth={"md"}
					bodyRef={bodyRef}
				>
					<UploadProgressBarWrapper>
						<UploadProgressBar
							progress={uploadingProgress}
							successText={"Your Video has been successfully uploaded!"}
						/>
					</UploadProgressBarWrapper>
					<StepBox className={`row ${step === 1 ? "active" : "hidden"}`}>
						<StepColumn className={"wide"}>
							<FormControl>
								<VideoPreviewWrapper>
									<video src={videoFileUrl} controls height={"100%"}></video>
								</VideoPreviewWrapper>
							</FormControl>
							{isMobile && <FormControl>{UploaderBlock}</FormControl>}
							<FormControl>
								<Controller
									name="title"
									control={control}
									rules={{ required: "Title is required!" }}
									render={({ onChange, value }) => (
										<TextField
											value={value}
											onChange={onChange}
											label="Video title*"
											variant="outlined"
											error={errors?.title?.message}
											helperText={errors?.title?.message}
											onFocus={scrollToXPositionOnFocus}
										/>
									)}
								/>
							</FormControl>
							<FormControl>
								<Controller
									name="category"
									control={control}
									rules={{ required: "Category is required!", validate: value => !!value }}
									render={({ onChange, value }) => (
										<Autocomplete
											getOptionLabel={option => (typeof option === "string" ? option : option.name)}
											options={suggestedCategories}
											autoComplete
											fullWidth
											includeInputInList
											filterSelectedOptions
											value={value}
											onChange={(event, newValue: GroupModel | null) => onChange(newValue)}
											onInputChange={(group, newInputValue) => loadSuggestedCategories(newInputValue)}
											renderInput={params => (
												<TextField
													{...params}
													label="Select Category*"
													variant="outlined"
													fullWidth
													error={errors?.category}
													helperText={errors?.category?.message}
												/>
											)}
											renderOption={option => option.name}
											onFocus={scrollToXPositionOnFocus}
										/>
									)}
								/>
							</FormControl>
							<FormControl>
								<Controller
									name="collection"
									variant="outlined"
									control={control}
									render={({ onChange, value }) => (
										<AutocompleteWrapper>
											<Autocomplete
												multiple
												autoComplete
												ListboxProps={{ style: { maxHeight: "180px" }, position: "bottom-start" }}
												renderInput={params => <TextField {...params} label="Add to Series" variant="outlined" />}
												getOptionLabel={option => (typeof option === "string" ? option : option.value)}
												includeInputInList
												filterSelectedOptions
												options={suggestedCollections.map(obj => ({ key: obj._id, value: obj.title }))}
												value={value}
												onChange={(event, newValue) => onChange(newValue)}
												onInputChange={(group, newInputValue) => loadSuggestedCollections(newInputValue)}
												renderOption={option => option.value}
												onFocus={scrollToXPositionOnFocus}
											/>
										</AutocompleteWrapper>
									)}
								/>
							</FormControl>
							<FormControl>
								<Controller
									name="description"
									control={control}
									rules={{
										required: "Description is required!",
										maxLength: {
											value: 50,
											message: "Description should not exceed 50 characters"
										},
										validate: value => !!value
									}}
									render={({ onChange, value }) => (
										<TextField
											value={value}
											onChange={onChange}
											label="Description*"
											variant="outlined"
											error={errors?.description?.message}
											helperText={errors?.description?.message}
											multiline
											rows={3}
											onFocus={scrollToXPositionOnFocus}
										/>
									)}
								/>
							</FormControl>
						</StepColumn>
						{!isMobile && UploaderBlock}
					</StepBox>
					<StepBox className={step === 2 ? "active" : "hidden"}>
						<StepColumn className={"wide-center"}>
							{/*<Controller*/}
							{/*	name="collection"*/}
							{/*	control={control}*/}
							{/*	render={({ onChange, value }) => (*/}
							{/*		<FormControl variant="outlined" className={classes.formControl}>*/}
							{/*			<Autocomplete*/}
							{/*				id="selectCollection"*/}
							{/*				options={[]}*/}
							{/*				getOptionLabel={(option: { label: string }) => option.label}*/}
							{/*				onChange={(_, newValue) => onChange(newValue)}*/}
							{/*				value={value}*/}
							{/*				renderInput={params => (*/}
							{/*					<TextField*/}
							{/*						{...params}*/}
							{/*						label="Choose Collection/Series"*/}
							{/*						variant="outlined"*/}
							{/*						onChange={e => setCollectionSearchText(e.target.value)}*/}
							{/*					/>*/}
							{/*				)}*/}
							{/*			/>*/}
							{/*		</FormControl>*/}
							{/*	)}*/}
							{/*/>*/}
							<Controller
								name="price"
								control={control}
								defaultValue={!!editableModel?.priceTags?.length}
								render={({ onChange, value }) => (
									<FeatureBlock>
										<RadioItem
											control={<Switch defaultChecked={value} onChange={e => onChange(e.target.checked)} />}
											value={value}
											label="Set a Price"
											icon={<MonetizationOnIcon />}
										/>

										{value && (
											<Controller
												name="priceChanged"
												control={control}
												render={({ onChange }) => (
													<SetupPrice
														ref={setupPriceFormRef}
														title={"Who can access this video?"}
														defaultValue={editableModel?.priceTags}
														onPriceChanged={() => onChange(true)}
													/>
												)}
											/>
										)}

										<Box
											style={{
												opacity: value ? 1 : 0,
												height: value ? "auto" : 0
											}}
										>
											<FeatureBlock noBorder>
												<Box className="head">
													<Box className="left-side">
														<Text variant="subtitle1">Preview</Text>
													</Box>
												</Box>
											</FeatureBlock>
											<TrackVideoPreviewCutter preview={preview} setPreview={setPreview} url={videoFileUrl} isVideo />
										</Box>
									</FeatureBlock>
								)}
							/>{" "}
							<Controller
								name="scheduled"
								control={control}
								defaultValue={!!editableModel?.meta?.scheduleDate}
								render={({ onChange, value }) => (
									<FeatureBlock>
										<RadioItem
											control={<Switch defaultChecked={value} onChange={e => onChange(e.target.checked)} />}
											value={value}
											label="Schedule Video"
											icon={<Icon group={""} fill={"#8f9bb3"} name={"clock"} width={24} height={24} />}
										/>
										{value && (
											<Box className="input multiple">
												<Controller
													name="scheduleDate"
													rules={{ required: step === 2 && value ? "Schedule Date is required" : false }}
													control={control}
													defaultValue={editableModel?.meta?.scheduleDate}
													render={({ onChange, value = null }) => (
														<DatePicker
															placeholder="Schedule Date"
															autoOk
															minDate={DateTime.now()}
															inputVariant="outlined"
															variant="inline"
															value={value}
															onChange={d => onChange(d)}
															error={!!errors.scheduleDate}
															helperText={errors.scheduleDate?.message}
														/>
													)}
												/>
												<Controller
													name="scheduleTime"
													rules={{ required: step === 2 && value ? "Schedule Time is required" : false }}
													control={control}
													defaultValue={editableModel?.meta?.scheduleDate}
													render={({ onChange, value = null }) => (
														<TimePicker
															placeholder="Select Time"
															autoOk
															inputVariant="outlined"
															variant="inline"
															value={value}
															onChange={d => onChange(d)}
															error={!!errors.scheduleTime}
															helperText={errors.scheduleTime?.message}
														/>
													)}
												/>
											</Box>
										)}
									</FeatureBlock>
								)}
							/>
							<Controller
								name="canBeDownloaded"
								control={control}
								defaultValue={editableModel?.meta.canBeDownloaded}
								render={({ onChange, value }) => (
									<FeatureBlock>
										<RadioItem
											control={<Switch defaultChecked={value} onChange={e => onChange(e.target.checked)} />}
											value={value}
											label="Allow Download"
											icon={
												<IconWrapper className={"fill-second"}>
													<DownloadIcon />
												</IconWrapper>
											}
										/>
									</FeatureBlock>
								)}
							/>
							<Controller
								name="private"
								control={control}
								defaultValue={editableModel?.meta?.private}
								render={({ onChange, value }) => (
									<FeatureBlock noBorder>
										<RadioItem
											control={<Switch defaultChecked={value} onChange={e => onChange(e.target.checked)} />}
											value={value}
											label="Set as Private"
											icon={
												<IconWrapper>
													<VisibilityIcon />
												</IconWrapper>
											}
											helpText={"Choose this option to hide from others in the community"}
										/>
									</FeatureBlock>
								)}
							/>
						</StepColumn>
					</StepBox>
				</Dialog>
			</form>
		</>
	);
};

export default CreateVideoManageView;
