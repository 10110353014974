import { useCallback, useMemo } from "react";

import * as R from "ramda";

import { SuggestionTypes } from "shared/contexts/UserContext/UserContext";
import { ProfilePhotoSizes, ProfileType } from "shared/types";

import {
	CertificationType,
	ChangePasswordRequest,
	ExperienceLocationType,
	ProfileEducationType,
	ProfileJobType,
	ReportBugRequest,
	VolunteerType
} from "shared/types/UserModel";

import { actionMethod, actionTypes, getValidationMessage } from "utils/getValidationMessage";

import { getResizedImage } from "utils/serviceUtils/cdnImages";

import useNotification from "./useNotification";

import { ActivitySettingsModel } from "../../modules/Activity/Data/types/ActivityModel";

import { useUserStore } from "../contexts";

import { useLocationApiService, useUserApiService, useUserIPApiService } from "../services";
import { IWorkspace, UserModel } from "../types";

const useUser = () => {
	const userService = useUserApiService();
	const userIPApiService = useUserIPApiService();
	const locationService = useLocationApiService();
	const { showMessage } = useNotification();

	const store = useUserStore();
	const { setState, setInitial } = useUserStore();

	const methods = useMemo(() => {
		return {
			getActiveProfile(user): ProfileType | undefined {
				return user?.profiles[0];
			},
			setUser(user: UserModel) {
				setState({ user });
			},
			setUserWorkspaces(workspaces: IWorkspace[]) {
				setState({ workspaces });
			},
			joinWorkspace(workspace: IWorkspace) {
				setState(ctx => ({ workspaces: ctx.workspaces?.concat([workspace]) }));
			},

			async getUserWorkspaces(email: string) {
				const workspaces = await userService.getUserWorkspaces(email);
				methods.setUserWorkspaces(workspaces);
			},
			async updateUserChatId(personaId: string, chatId: string) {
				await userService.updateUserChatId(personaId, chatId);
			},
			async getUserFirebaseToken(personaId: number) {
				return await userService.getUserFirebaseToken(personaId);
			},
			clearWorkspaces() {
				setState({ workspaces: undefined });
			},
			async getTopConnections() {
				return await userService.getTopConnections();
			},
			getUsersFromPersonaIds(viewerPersonaId: number, personaIds: number[]) {
				return userService.getUsersFromPersonaIds(viewerPersonaId, personaIds);
			},
			getUsersReaction(pid: string, limit: number, offset: number) {
				return userService.getUserReaction(pid, limit, offset);
			},
			getProfilePicture(user): string {
				const activeProfile = methods.getActiveProfile(user);
				if (activeProfile) {
					const photo = activeProfile.photos[0];
					if (typeof photo == "string") return photo;
					return photo?.profilePicture || "";
				}
				return "";
			},
			getResizedProfileImages(user, size: ProfilePhotoSizes): string {
				const image = methods.getProfilePicture(user);
				return getResizedImage(image as string, size);
			},
			async searchSuggestions(type: SuggestionTypes, keyword: string) {
				if (!keyword) return;

				try {
					setState({ loading: true });

					const { success, suggestions } = await userService.searchSuggestions(type, keyword);
					if (!success) throw new Error();

					switch (type) {
						case SuggestionTypes.jobTitle:
							setState({ jobTitleSuggestions: suggestions });
							break;
						case SuggestionTypes.industry:
							setState({ industrySuggestions: suggestions });
							break;
						case SuggestionTypes.company:
							setState({ companiesSuggestions: suggestions });
							break;
						case SuggestionTypes.cause:
							setState({ causeSuggestions: suggestions });
							break;
						case SuggestionTypes.volunteerOrganization:
							setState({ volunteerOrganizationSuggestions: suggestions });
							break;
						case SuggestionTypes.school:
							setState({ schoolSuggestions: suggestions });
							break;
						case SuggestionTypes.degree:
							setState({ degreeSuggestions: suggestions });
							break;
						case SuggestionTypes.specialization:
							setState({ specializationSuggestions: suggestions });
							break;
						case SuggestionTypes.licenseName:
							setState({ licenseSuggestions: suggestions });
							break;
						case SuggestionTypes.licenseOrganization:
							setState({ licenseOrganizationSuggestions: suggestions });
							break;
						case SuggestionTypes.skill:
							setState({ skillsSuggestions: suggestions });
							break;
						case SuggestionTypes.language:
							setState({ languagesSuggestions: suggestions });
							break;
					}
				} catch (error) {
					console.log(error);
				}

				setState({ loading: false });
			},
			async createNewSuggestion(type: SuggestionTypes, label: string) {
				return userService.createNewSuggestion(type, label);
			},
			async listSuggestions(type: "skill" | "language", limit: number, offset: number) {
				setState({ loading: true });

				try {
					const { suggestions } = await userService.listSuggestions(type, limit, offset);
					if (type === "skill") {
						setState(ctx => ({
							...ctx,
							skillsSuggestions: offset > 1 ? [...ctx.skillsSuggestions, ...suggestions] : suggestions
						}));
					}
					if (type === "language") {
						setState(ctx => ({
							...ctx,
							languagesSuggestions: offset > 1 ? [...ctx.languagesSuggestions, ...suggestions] : suggestions
						}));
					}
				} catch (error) {
					console.log(error);
				}

				setState({ loading: false });
			},
			getMutualConnections(viewerPersonaId: number, displayedPersonaId: number, limit: number, offset: number) {
				return userService.getMutualConnections(viewerPersonaId, displayedPersonaId, limit, offset);
			},
			async fetchGoals() {
				try {
					const { success, goals } = await userService.fetchGoals();
					if (!success) throw new Error();

					setState({ goals });
				} catch (error) {
					console.log(error);
				}
			},
			async fetchInterests() {
				try {
					const { success, interests } = await userService.fetchInterests();
					if (!success) throw new Error();

					setState({ interests });
				} catch (error) {
					console.log(error);
				}
			},
			async getCommunityAppLink() {
				try {
					const response = await userService.getCommunityAppLink();
					setState({ communityAppLink: response.link.toString() });
				} catch (error) {
					console.log(error);
				}
			},
			getPotentialMatchIds(user) {
				const profile = methods.getActiveProfile(user);

				const potentialMatchingIds: string[] = [];

				(profile?.education || []).forEach(e =>
					potentialMatchingIds.push(e.school._id, e.degree._id, e.specialization._id)
				);

				(profile?.jobs || []).forEach(j => potentialMatchingIds.push(j.company._id, j.jobTitle._id, j.industry._id));

				(profile?.goals || []).concat(profile?.interests || []).forEach(value => potentialMatchingIds.push(value._id));

				return potentialMatchingIds.filter(id => !R.isEmpty(id));
			},
			async getUserInfo(displayedPersonaId: number, viewerPersonaId: number) {
				return await userService.getUserInfo(displayedPersonaId, viewerPersonaId);
			},
			setUserVerified(userVerified: boolean) {
				setState({ userVerified });
			},
			setGlobalUserId(globalUserId: string) {
				setState({ globalUserId });
			},
			setNewJobDialog(newJobDialog?: { open: boolean; item?: ProfileJobType }) {
				setState({ newJobDialog });
			},
			setNewVolunteerJobDialog(newVolunteerDialog?: { open: boolean; item?: VolunteerType }) {
				setState({ newVolunteerDialog });
			},
			setNewEducationDialog(newEducationDialog?: { open: boolean; item?: ProfileEducationType }) {
				setState({ newEducationDialog });
			},
			setNewLicenseDialog(newLicenseDialog?: { open: boolean; item?: CertificationType }) {
				setState({ newLicenseDialog });
			},
			setUserSkinTone(skinTone: string | null) {
				setState(ctx => ({
					...ctx,
					user: ctx.user && {
						...ctx.user,
						profiles: [{ ...ctx.user.profiles[0], skinTone }]
					}
				}));
			},
			setPassedGuidedTour(passedGuidedTour: boolean) {
				setState({
					ignoreGuidedTour: passedGuidedTour
				});
			},
			setUpdateUserDialog(updateUserDialog: boolean) {
				setState({ updateUserDialog });
			},
			setAddSkillsDialog(addSkillsDialog: boolean) {
				setState({ addSkillsDialog });
			},
			setAddGoalsDialog(addGoalsDialog: boolean) {
				setState({ addGoalsDialog });
			},
			setAddInterestsDialog(addInterestsDialog: boolean) {
				setState({ addInterestsDialog });
			},
			setAddLanguagesDialog(addLanguagesDialog: boolean) {
				setState({ addLanguagesDialog });
			},
			setAddSocialAccountsDialog(addSocialAccountsDialog: boolean) {
				setState({ addSocialAccountsDialog });
			},
			setMyConnectionsDialog(myConnectionsDialog: boolean) {
				setState({ myConnectionsDialog });
			},
			setMyExperienceDialog(myExperienceDialog: boolean, location?: ExperienceLocationType) {
				setState({ myExperienceDialog, experienceDialogLocation: location });
			},
			setProfileDetailsDialog(profileDetailsDialog: boolean) {
				setState({ profileDetailsDialog });
			},
			setIsMemberView(isMemberView: boolean) {
				setState({ isMemberView });
			},
			async getBlockedUsers(userId: string) {
				setState({ loading: true });
				try {
					const res = await userService.getBlockedUsers(userId);
					setState({ loading: false, blockedUsers: res });
				} catch (error) {
					setState({ loading: false });
					showMessage("Failed to get blocked users");
					console.error(error);
				}
			},
			async blockUser(personaId: number) {
				try {
					const data = await userService.blockUser(personaId);
					if (data?.blockedUsers?.length) {
						setState(ctx => ({
							blockedUsers: [...ctx.blockedUsers, data.blockedUsers]
						}));

						showMessage(
							getValidationMessage({
								name: "Persona",
								actionType: actionTypes.CRUD,
								actionMethod: actionMethod.blocked,
								emoji: "🚫"
							}),
							3
						);
					}
				} catch (error) {
					showMessage("Failed to block user");
					console.error(error);
				}
			},
			async unblockUser(personaId: number) {
				try {
					await userService.unblockUser(personaId);
					setState(ctx => ({
						...ctx,
						blockedUsers: ctx.blockedUsers.filter(u => u.personaId !== personaId)
					}));
				} catch (error) {
					showMessage("Failed to unblock user");
					console.error(error);
				}
			},
			reportBug(params: ReportBugRequest) {
				return userService.reportBug(params);
			},
			async changePassword(params: ChangePasswordRequest) {
				try {
					setState({ loading: true });
					await userService.changePassword(params);
					setState({ loading: false });
				} catch (error) {
					setState({ loading: false });
					showMessage("Failed to change password");
					console.error(error);
				}
			},
			updateUserPhoneState(params: { phoneCode: string; phoneNumber: string }) {
				setState(ctx => {
					if (ctx.user) {
						return {
							...ctx,
							user: {
								...ctx.user,
								phoneCode: params.phoneCode,
								phoneNumber: params.phoneNumber
							}
						};
					}

					return ctx;
				});
			},
			clearCounter(counter = "totalCount") {
				userService.clearCounter(counter);

				setState(ctx => {
					if (ctx.user?.profiles?.length) {
						return {
							...ctx,
							user: {
								...ctx.user,
								profiles: [
									{
										...ctx.user?.profiles[0],
										counters: {
											...ctx.user?.profiles[0].counters,
											[counter]: 0
										}
									}
								]
							}
						};
					}

					return ctx;
				});
			},
			updateNotificationPreferences(activitySettings: ActivitySettingsModel) {
				setState(ctx => {
					if (ctx.user?.profiles?.length) {
						return {
							...ctx,
							user: {
								...ctx.user,
								profiles: [
									{
										...ctx.user?.profiles[0],
										notificationSettings: activitySettings
									}
								]
							}
						};
					}

					return ctx;
				});
			},
			async activateAccount(action: "activate" | "deactivate") {
				return await userService.activateAccount(action);
			},
			async deleteAccount(userId: string) {
				return await userService.deleteAccount(userId);
			},
			async deleteGlobalAccount() {
				return await userService.deleteGlobalAccount();
			},
			resetState() {
				setState({
					user: undefined,
					globalUserId: undefined,
					workspaces: [],
					communityAppLink: "",
					userVerified: true,
					ignoreGuidedTour: false
				});
			},
			resetStore() {
				setInitial();
				methods.resetState();
			}
		};
	}, [setState, userService, showMessage, setInitial]);

	const loadSuggestedLocations = useCallback(
		async (keyWord: string) => {
			if (keyWord.length < 3) return;
			const suggestedLocations = await locationService.getLocationSuggestions(keyWord, {
				lat: store.userIPDetails.lat,
				lon: store.userIPDetails.lon
			}); // separate it to the it's own service

			setState({ suggestedLocations });
		},
		[locationService, store.userIPDetails, setState]
	);

	const getUserIPDetails = useCallback(async () => {
		try {
			const userIPDetails = await userIPApiService.getUserIPDetails();

			if (!userIPDetails.hasOwnProperty("countryCode")) {
				throw Error();
			}

			setState({ userIPDetails });
		} catch (error) {
			setState({ userIPDetails: { lat: 0, lon: 0 } });
			console.error(error);
		}
	}, [userIPApiService, setState]);

	const getData = useCallback(() => {
		return store;
	}, [store]);

	const isPrivilegedRole = useMemo(() => {
		return store?.user?.isGlobalOwner || store?.user?.isGlobalAdmin || store?.user?.isGlobalModerator;
	}, [store.user]);

	const activeProfileId = useMemo(
		() => (store?.user?.profiles?.length ? store?.user.profiles[0].personaId || -1 : -1),
		[store?.user?.profiles]
	);

	return { ...methods, getUserIPDetails, getData, isPrivilegedRole, activeProfileId, loadSuggestedLocations };
};

export default useUser;
