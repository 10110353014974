import { Box } from "@material-ui/core";
import styled, { css } from "styled-components";

export const NotVerifiedNotification = styled(Box)`
	width: 100%;
	background-color: ${({ bgColor }) => bgColor};
	position: relative;
	color: white;
	padding: 12px 16px;
	svg {
		width: 24px;
		height: 24px;
	}
	p {
		margin-left: 10px;
	}
	button {
		border-color: white;
		color: white;
		margin: 0;
		margin-top: 8px;
		text-transform: none;
		margin-left: 26px;
		font-weight: ${({ isMember }) => (isMember ? "bold" : "regular")};
		&:disabled {
			border-color: #dedede;
			color: #dedede;
		}
		&:hover {
			border-color: white;
		}
	}
	.counter {
		font-size: 15px;
	}

	${({ isMember }) =>
		isMember &&
		css`
			width: calc(100% - 32px);
			border-radius: 16px;
			margin-left: 16px;
			margin-bottom: 20px;
		`}

	${props => props.theme.breakpoints.up("sm")} {
		border-radius: ${({ isMember }) => (isMember ? "12px" : 0)};
		button {
			margin-left: 36px;
		}
	}
	${props => props.theme.breakpoints.up("md")} {
		width: auto;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 48px;
		padding: ${({ isMember }) => (isMember ? "0 16px" : "0")};
		max-width: ${({ isMember }) => (isMember ? "1172px" : "auto")};
		margin: auto;
		margin-bottom: ${({ isMember }) => (isMember ? "16px" : 0)};
		button {
			position: absolute;
			right: 10px;
			margin-top: 0;
			margin-left: 0;
		}
		.counter {
			margin-left: 4px;
		}
	}

	&.kijiji {
		background-color: #a0a0f2;
	}
`;
