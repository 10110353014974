import React, { useEffect, useState } from "react";

import Fade from "@material-ui/core/Fade";
import Grow from "@material-ui/core/Grow";
import IconButton from "@material-ui/core/IconButton";
import LinearProgress from "@material-ui/core/LinearProgress";
import { default as MuiSlide } from "@material-ui/core/Slide";
import Snackbar, { SnackbarProps as MuiSnackbarProps, SnackbarOrigin } from "@material-ui/core/Snackbar";
import CloseIcon from "@material-ui/icons/Close";
import styled from "styled-components";

import { default as theme } from "../../themes/default";

export type SnackbarPositionType = SnackbarOrigin;

export const transitions = {
	fade: Fade,
	grow: Grow,
	slide: MuiSlide
};

const defaultPosition: SnackbarPositionType = {
	horizontal: "right",
	vertical: "bottom"
};

const LineProgress = styled(({ autoHideDuration, className }: MuiSnackbarProps) => {
	const [progress, setProgress] = useState(0);
	useEffect(() => {
		const tick = () => {
			setProgress(oldProgress => (oldProgress >= 100 ? 100 : oldProgress + 1));
		};

		const interval = autoHideDuration ? autoHideDuration / 100 : 20;
		const timer = setInterval(tick, interval);

		return () => clearInterval(timer);
	}, [autoHideDuration]);

	return (
		<div className={className}>
			<LinearProgress variant="determinate" value={progress} />
		</div>
	);
})`
	position: absolute;
	bottom: 10px;
	left: 16px;
	right: 16px;

	.MuiLinearProgress-colorPrimary {
		background-color: rgb(143, 155, 179, 0.24);
	}
`;

export type SnackbarProps = MuiSnackbarProps & {
	className?: string;
	onClose?: (event: React.SyntheticEvent<any>) => void;
	position?: SnackbarPositionType;
	showCloseButton?: boolean;
	slideDirection?: "left" | "up" | "right" | "down";
	transition?: "fade" | "grow" | "slide";
	withLineProgress?: boolean;
};

export default styled(
	({
		position,
		className,
		transition = "fade",
		showCloseButton = false,
		action,
		withLineProgress,
		...rest
	}: Readonly<SnackbarProps>): JSX.Element => {
		const snackbar = position || defaultPosition;
		const handleClose = React.useCallback(
			(event: React.SyntheticEvent | MouseEvent) => {
				if (!rest.onClose) {
					return;
				}

				rest.onClose(event as React.SyntheticEvent);
			},
			[rest]
		);

		const actionsBlock: JSX.Element = (
			<React.Fragment>
				{action}
				{showCloseButton && (
					<IconButton aria-label="close" color="inherit" onClick={handleClose}>
						<CloseIcon />
					</IconButton>
				)}
				{withLineProgress && <LineProgress autoHideDuration={rest.autoHideDuration} />}
			</React.Fragment>
		);

		return (
			<div className={className}>
				<Snackbar
					anchorOrigin={snackbar}
					TransitionComponent={transitions[transition]}
					action={actionsBlock}
					{...rest}
				/>
			</div>
		);
	}
)`
	.MuiSnackbar-root {
		border-radius: 4px;
	}

	.MuiSnackbarContent-root {
		background-color: ${theme.palette.snackbar.backgroundColor};
		box-shadow: none;
		position: relative;
		padding-bottom: ${props => (props.withLineProgress ? "20px" : "6px")};
	}

	.MuiAlert-root {
		background-color: ${theme.palette.snackbar.backgroundColor};
		color: ${theme.palette.snackbar.textColor};

		.MuiAlert-icon {
			color: ${theme.palette.snackbar.textColor};
		}
	}

	.MuiSnackbarContent-message {
		font-size: ${theme.sizes.snackbar.fontSize};
	}
`;
