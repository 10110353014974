import { useMemo } from "react";

import { PodcastIntegration } from "modules/Integrations/Data/types/PodcastsResponse";

import { API_URLS } from "modules/Integrations/constants";
import useHttpActions from "shared/services/api/core/useHttpActions";
import BaseConverter from "shared/services/converters/baseConverter";

import { PodcastsResponse } from "./../types/PodcastsResponse";

const useMarketingService = () => {
	const actions = useHttpActions();

	return useMemo(
		() => ({
			getPodcasts: async () => {
				return BaseConverter.convert<PodcastsResponse>(await actions.get(API_URLS.GET_PODCASTS));
			},
			updatePodcastRss: async (integrationId: string, links: string[]) => {
				return BaseConverter.convert<{
					success: boolean;
					podcastIntegration: PodcastIntegration;
					failedLinks: { link: string }[];
				}>(
					await actions.put(API_URLS.UPDATE_DELETE_PODCAST_RSS(integrationId), {
						links
					})
				);
			},
			deleteRssLinks: async (integrationId: string, linkIds: string[]) => {
				return BaseConverter.convert<{ success: boolean; podcastIntegration: PodcastIntegration }>(
					await actions.delete(API_URLS.UPDATE_DELETE_PODCAST_RSS(integrationId), {
						linkIds
					})
				);
			}
		}),
		[actions]
	);
};

export default useMarketingService;
