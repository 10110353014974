import styled from "styled-components";

import { Box, Button } from "shared/ui-kit";

export const IconWrapper = styled(Box)`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 70px;
	height: 70px;
	border-radius: ${({ isSquareShape }) => (isSquareShape ? "10px" : "50%")};
	background-color: #edf1f7;
	margin-right: 16px;
	flex-shrink: 0;
`;

export const RequestList = styled(Box)`
	width: 33.33%;

	@media (max-width: 1096px) {
		width: 50%;
	}

	@media (max-width: 768px) {
		width: 100%;
	}

	img {
		width: 48px;
		height: 48px;
		border-radius: 100px;
		object-fit: cover;
	}
	h6 {
		font-size: 15px;
		font-weight: 600;
		line-height: 1.6;
		color: #222b45;
	}
	p {
		font-size: 13px;
		font-weight: 600;
		line-height: 1.85;
		color: #8f9bb3;
	}
`;

export const TextBadge = styled(Box)`
	background-color: ${({ color }) => `#${color}30`};
	color: ${({ color }) => `#${color}`};
	width: fit-content;

	font-size: 10px;
	padding: 2px 8px;
`;

export const SignUpButton = styled(Button)`
	&.cancel {
		color: #000000;
		background-color: #eeeeee;
	}
`;
