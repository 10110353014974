import { useCallback, useMemo } from "react";

import { ReportSpamType, UserFileSortBy } from "types";
import { FileFormModel, FileType, UpdateFileFormModel } from "types/FilesContextValuesType";

import { useNotification, useUser } from "shared/hooks";

import { useFilesStore } from "../contexts";

import useFilesApiService from "../services/useFilesApiService";

const useFiles = (isMarketing = false) => {
	const { showMessage } = useNotification();
	const filesApiService = useFilesApiService(isMarketing);

	const { getData: getUserData } = useUser();
	const { user } = getUserData();

	const store = useFilesStore();
	const { setState } = useFilesStore();

	const personaId = user?.profiles[0]?.personaId || "";

	const methods = useMemo(
		() => ({
			createFile: async (data: FileFormModel) => {
				try {
					const { success, userFile } = await filesApiService.createFile(data, personaId);
					if (!success) {
						throw new Error("creating file failed.");
					}

					showMessage("Your file is successfully created 🎉.", 3);
					setState(ctx => ({
						...ctx,
						files: [{ ...data, ...userFile }, ...ctx.files]
					}));

					return userFile;
				} catch {
					showMessage("Could not create your file, please try again.", 3);
				}
			},
			updateFile: async (data: UpdateFileFormModel) => {
				try {
					const { success, userFile } = await filesApiService.updateFile(data, personaId);

					if (!success) {
						throw new Error("updating file failed.");
					}

					showMessage("Your file is successfully updated 🎉.", 3);

					setState(ctx => ({
						...ctx,
						files: ctx.files.map(file =>
							file._id === data._id
								? {
										...data,
										...userFile
								  }
								: file
						)
					}));
				} catch {
					showMessage("Could not update your file, please try again.", 3);
				}
			},
			getFileInfoById: async (id: string) => {
				try {
					const { userFile } = await filesApiService.getFileById(id);
					return userFile;
				} catch {
					return undefined;
				}
			},
			getFileById: async (id: string) => {
				setState(ctx => ({ ...ctx, isLoading: true }));

				try {
					const userFile = await methods.getFileInfoById(id);
					if (userFile) {
						setState(ctx => ({
							...ctx,
							files: ctx.files?.some(x => x._id === id) ? ctx.files.map(x => (x._id === id ? userFile : x)) : ctx.files
						}));
					}

					return userFile;
				} catch {
					showMessage("Could not fetch file by id, please try again.", 3);
				}

				setState(ctx => ({ ...ctx, isLoading: false }));
			},
			getFiles: async ({
				limit = 10,
				keyword = "",
				offset,
				sortBy,
				groupId,
				categoryId,
				eventId,
				paginate,
				personaId
			}: {
				limit: number;
				keyword?: string;
				offset: number;
				sortBy?: UserFileSortBy;
				categoryId?: string;
				eventId?: string;
				groupId?: string;
				paginate?: boolean;
				personaId?: string | number;
			}) => {
				setState({ isLoading: true });

				let userFileList: FileType[] = [];
				try {
					const { userFiles } = await filesApiService.getFiles({
						limit,
						keyword,
						offset,
						sortBy,
						categoryId,
						eventId,
						groupId,
						personaId
					});
					userFileList = userFiles;

					setState(ctx => ({
						files: !paginate || offset === 1 ? userFiles : [...ctx.files, ...userFiles],
						shouldStopSearching: userFiles.length < limit
					}));
				} catch {
					showMessage("Could not fetch files data, please try again.", 3);
				} finally {
					setState({ isLoading: false });
					return userFileList;
				}
			},
			getAllFilesCount: async ({
				categoryId,
				groupId,
				eventId,
				searchTerm
			}: {
				categoryId?: string;
				groupId?: string;
				eventId?: string;
				searchTerm?: string;
			}) => {
				try {
					const { totalCount } = await filesApiService.getAllFilesCount({ categoryId, groupId, eventId, searchTerm });

					setState(ctx => ({ ...ctx, filesTotalCount: totalCount }));
				} catch {
					showMessage("Could not fetch files count, please try again.", 3);
				}
			},
			setDeleteFileDetails: (deleteFileDetails?: { id: string; name: string }) => {
				setState(ctx => ({ ...ctx, deleteFileDetails }));
			},
			deleteFile: async (id: string) => {
				const { success } = await filesApiService.deleteFile(id);
				if (success) {
					showMessage("File successfully deleted. 🗑", 3);
					setState(ctx => ({
						...ctx,
						files: ctx.files.filter(file => file._id !== id),
						deleteFileDetails: undefined
					}));
				}
			},
			report: async ({ id, reportType, reason }: { id: string; reportType: ReportSpamType; reason?: string }) => {
				const { success } = await filesApiService.reportFile({ id, reportType, reason });
				if (success) {
					showMessage("File successfully reported.", 3);
				}
			},
			setPage: (page: number) => {
				setState(ctx => ({ ...ctx, page }));
			},
			setFilesShowPerPage: (filesShowPerPage: number) => {
				setState(ctx => ({ ...ctx, filesShowPerPage }));
			},
			setFileName: (fileName: string) => {
				setState(ctx => ({
					...ctx,
					fileName
				}));
			},
			setFileExtension: (extension: string) => {
				setState(ctx => ({
					...ctx,
					extension
				}));
			},
			setFileUrl: fileUrl => setState(ctx => ({ ...ctx, fileUrl })),
			setFileInfoPopup: (fileInfoPopup: { open: boolean; model?: FileType }) => {
				setState(ctx => ({ ...ctx, fileInfoPopup }));
			}
		}),
		[filesApiService, setState, showMessage, personaId]
	);

	const getData = useCallback(() => {
		return store;
	}, [store]);

	return { ...methods, getData };
};

export default useFiles;
