import React from "react";

import { ReactComponent as SofaIllustrationAkina } from "assets/icons/sofa-illustration-akina.svg";
import { ReactComponent as SofaIllustration } from "assets/icons/sofa-illustration.svg";

import { useCommunity } from "shared/hooks";

import { ReloadButton, Text, TextWrapper, Title, Wrapper } from "./styles";

export function NoInternet() {
	const { getData: getCommunityData } = useCommunity();
	const { isAkinaMode } = getCommunityData();

	const handleReloadButtonClick = () => {
		window.location.reload();
	};

	return (
		<Wrapper>
			{isAkinaMode ? <SofaIllustrationAkina /> : <SofaIllustration />}
			<TextWrapper>
				<Title>Connect to the internet</Title>
				<Text>You’re offline. Check your connection.</Text>
				<ReloadButton onClick={handleReloadButtonClick} buttonTheme="light">
					Retry
				</ReloadButton>
			</TextWrapper>
		</Wrapper>
	);
}
