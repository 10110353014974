import React, { useLocation } from "react-router";

import { view } from "modules/Messaging";
import { FeatureFlags } from "shared/Components/MemberNavPanel/constants";
import useCheckIfEnabled from "shared/hooks/useCheckIfFlagEnabled";

const MessagingPage = () => {
	useCheckIfEnabled(FeatureFlags.chat);

	const { search } = useLocation();

	const query = new URLSearchParams(search);
	const messageTo = query.get("messageTo") || undefined;

	return <view.Messaging messageTo={messageTo} />;
};

export default MessagingPage;
