import { IStateProps } from "utils/StateOf";

import createStore from "utils/createStore";

import { FaqContextValues } from "../types";

export const initialState: IStateProps<FaqContextValues> = {
	faqs: [],
	modalTitle: "Add",
	modalContent: "",
	modalFooterActionsText: { submitText: "" },
	confirmActionMessage: "",
	isOpen: false,
	isModalOpen: false,
	order: 0,
	faq: { _id: "", title: "", description: "", order: 0 },
	isLoading: true
};

export default createStore<FaqContextValues>({
	initialState
});
