import React, { FC } from "react";

import { Box, ButtonBase } from "@material-ui/core";
import styled from "styled-components";

import { Text } from "shared/ui-kit";

import NextVideoCircularProgress from "../VideoPlayer/NextVideoCircularProgress";

const Wrapper = styled(Box)`
	position: relative;
`;
Wrapper.CancelButton = styled(ButtonBase)`
	width: 80px;
	height: 32px;
	padding: 8px 0;
	margin-top: 12px;
	border-radius: 8px;
	background-color: rgba(255, 255, 255, 0.25);
`;

Wrapper.NextVideo = styled(Box)`
	display: flex;
	flex-direction: column;
	padding: 16px;
	justify-content: flex-end;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 10;
	color: #fff;
	background-color: #000;
	${props => props.theme.breakpoints.up("sm")} {
		background-color: rgba(0, 0, 0, 0.5);
		align-items: center;
		justify-content: center;
	}
`;

Wrapper.Headline = styled(Box)`
	position: absolute;
	top: 0;
	left: 0;
	padding: 16px;
	p {
		font-size: 13px;
		line-height: 1.23;
		color: #8f9bb3;
	}
	h2 {
		font-size: 14px;
		font-weight: 600;
		line-height: 1.29;
		color: #fff;
		margin-top: 4px;
	}
`;

const NextPlaylistItem: FC<{ title: string; subtitle: string; handleClose: () => void }> = ({
	title,
	subtitle,
	handleClose
}) => (
	<Wrapper.NextVideo>
		<Wrapper.Headline>
			<Text>{title}</Text>
			<Text variant="h2">{subtitle}</Text>
		</Wrapper.Headline>
		<NextVideoCircularProgress />
		<Wrapper.CancelButton onClick={handleClose}>Cancel</Wrapper.CancelButton>
	</Wrapper.NextVideo>
);

export default NextPlaylistItem;
