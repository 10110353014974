import styled from "styled-components";

import { Box } from "shared/ui-kit";

export const Container = styled(Box)`
	width: 1200px;
	padding: 0 16px;
	margin: 0 auto;
	display: flex;
	align-items: flex-start;
	justify-content: center;

	${props => props.theme.breakpoints.down("sm")} {
		flex-direction: column;
		justify-content: flex-start;

		width: 100%;

		padding: 16px 0;
	}
`;

export const Column = styled(Box)<{ left?: boolean; middle?: boolean; right?: boolean }>`
	width: 568px;

	${props => props.theme.breakpoints.down("sm")} {
		width: 100%;
		margin-bottom: 24px;
		overflow: hidden;
	}
`;
