import React, { FC } from "react";

import { Box } from "@material-ui/core";
import styled from "styled-components";

import { Icon, Text } from "shared/ui-kit";

const AuthErrorWrapper = styled(Box)`
	display: flex;
	align-items: center;
	justify-content: center;
	padding-bottom: 12px;
	h6 {
		font-size: 13px;
		color: #db2c33;
		margin-left: 8px;
	}
`;

const AuthError: FC<{ message: string }> = ({ message }) => {
	if (!message) return null;

	return (
		<AuthErrorWrapper>
			<Icon name="exclamation-triangle" group="filled" fill="#db2c33" />
			<Text variant="h6">{message}</Text>
		</AuthErrorWrapper>
	);
};

export default AuthError;
