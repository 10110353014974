import timezones from "timezones-list";

export const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

export const generateFakeId = () => {
	let text = "";
	const possible = "abcdefghijklmnopqrstuvwxyz0123456789";

	for (let i = 0; i < 5; i++) {
		//eslint-disable-line
		text += possible.charAt(Math.floor(Math.random() * possible.length));
	}

	return text;
};

export const uuidv4 = () => {
	// @ts-expect-error: special code
	return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
		(c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
	);
};

export function* generateSequence(start, end) {
	for (let i = start; i <= end; i++) yield i;
}

export function randomInteger(min, max) {
	return Math.floor(Math.random() * (max - min)) + min;
}

const toBase64 = (file: File): Promise<string> =>
	new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result as string);
		reader.onerror = error => reject(error);
	});

async function dataUrlToFile(dataUrl: string, fileName: string): Promise<File> {
	const res: Response = await fetch(dataUrl);
	const blob: Blob = await res.blob();
	return new File([blob], fileName, { type: "image/png" });
}

const extractContentFromHTML = (s: string): string => {
	const span = document.createElement("span");
	span.innerHTML = s;

	const hasInsertedSmartComponent =
		span.querySelectorAll("div[data-name]").length || span.querySelectorAll("img").length;

	return span.textContent || span.innerText || (hasInsertedSmartComponent ? "...content" : "");
};

const maxNameLength = 60;
const correctName = (name = "", length = maxNameLength) =>
	name
		.replace(/^ {1}/gm, "") // Remove first spaces
		.replace(/[^\s&/\\#,+()$~%.'":;*?<>{}`[\]|!@^\-a-zA-Z0-9]/g, "_") // Replace special characters with _
		.replace(/\s\s+/g, " ") // replace multi whitespaces into one whitespace
		.slice(0, length);

const safelyParseJSON = <T>(json): T => {
	let parsed;

	try {
		parsed = JSON.parse(json);
	} catch (error) {}

	return parsed as T;
};

const filterFiles = (files: FileList, types: string[]): FileList => {
	const dt = new DataTransfer();
	const correctTypes = types.map(x => (x.includes("/*") ? x.replace("/*", "/") : x));
	Array.from(files).forEach(file => {
		if (correctTypes.some(x => file.type.startsWith(x))) {
			dt.items.add(file);
		}
	});
	return dt.files;
};

const convertToNumberFormat = (val: string): string => {
	if (val) {
		let modified = val.replace(/[^0-9.]/g, "").replace(/\.{2,}/g, ".");
		const dotCount = modified.split(".").length - 1;
		if (dotCount > 1) {
			let updated = "";
			modified.split(".").forEach((item, i) => {
				updated += `${item}${!i ? "." : ""}`;
			});
			modified = updated;
		}
		return !modified || isNaN(Number(modified))
			? ""
			: modified.endsWith(".")
			? `${Number(modified).toString()}.`
			: Number(modified).toString();
	}
	return "";
};

// Take an image URL, downscale it to the given width, and return a new image URL.
const downscaleImage = async (
	dataUrl: string,
	newWidth: number,
	imageType: string,
	imageArguments: number
): Promise<string> => {
	const imgType = imageType || "image/jpeg";
	const imgArguments = imageArguments || 0.7;

	// Create a temporary image so that we can compute the height of the downscaled image.
	const image = new Image();
	image.src = dataUrl;

	return new Promise(resolve => {
		image.onload = () => {
			const oldWidth = image.width;
			const oldHeight = image.height;
			const newHeight = Math.floor((oldHeight / oldWidth) * newWidth);

			// Create a temporary canvas to draw the downscaled image on.
			const canvas = document.createElement("canvas");
			canvas.width = newWidth;
			canvas.height = newHeight;

			// Draw the downscaled image on the canvas and return the new data URL.
			const ctx = canvas.getContext("2d");
			ctx!.drawImage(image, 0, 0, newWidth, newHeight);
			resolve(canvas.toDataURL(imgType, imgArguments));
		};
	});
};

export function convertToDecimal(amount) {
	const correctAmount = typeof amount === "string" ? Number(amount) : amount;
	return isNaN(correctAmount) ? amount : amount.toFixed(2);
}

const numberWithCommas = (x: number | string): string => {
	if (x) {
		const parts = x.toString().split(".");
		parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		return parts.join(".");
	}
	return "";
};

const formatCount = (n: number, singular: string, multiple?: string, addPrefix = false): string => {
	if (n === 0) return `No ${multiple || singular + "s"}`;
	if (n === 1) return `${n} ${singular}`;
	if (n > 1 && n <= 3) return `${n} ${multiple || singular + "s"}`;

	if (n < 1e3) return `${addPrefix ? "+ " : ""}${n} ${multiple || singular + "s"}`;
	if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + `K ${multiple || singular + "s"}`;
	if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + `M ${multiple || singular + "s"}`;

	return `${n}`;
};

const isMobileSafari = () => {
	const isIOS =
		(/iPad|iPhone|iPod/.test(navigator.platform) ||
			(navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1)) &&
		!(window as any).MSStream;
	return isIOS && navigator.userAgent.match(/AppleWebKit/);
};

const isRealMobile = {
	Android: function () {
		return navigator.userAgent.match(/Android/i);
	},
	BlackBerry: function () {
		return navigator.userAgent.match(/BlackBerry/i);
	},
	iOS: function () {
		return navigator.userAgent.match(/iPhone|iPad|iPod/i);
	},
	Opera: function () {
		return navigator.userAgent.match(/Opera Mini/i);
	},
	Windows: function () {
		return navigator.userAgent.match(/IEMobile/i);
	},
	any: function () {
		return (
			isRealMobile.Android() ||
			isRealMobile.BlackBerry() ||
			isRealMobile.iOS() ||
			isRealMobile.Opera() ||
			isRealMobile.Windows()
		);
	}
};

const hashCode = str => {
	let hash = 0,
		i,
		chr;
	if (str.length === 0) return hash;
	for (i = 0; i < str.length; i++) {
		chr = str.charCodeAt(i);
		hash = (hash << 5) - hash + chr;
		hash |= 0; // Convert to 32bit integer
	}
	return hash;
};

const openFileByLink = (url: string) => {
	const link = document.createElement("a");
	link.href = url;
	link.setAttribute("target", "_blank");
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
};

const downloadFileByLink = (url: string) => {
	const link = document.createElement("a");
	link.href = url;
	link.setAttribute("download", "qr_code.png");
	link.setAttribute("target", "_blank");
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
};

const secondsToHumanTime = (secs: string) => {
	const sec_num = parseInt(secs, 10);
	const hours = Math.floor(sec_num / 3600);
	const minutes = Math.floor(sec_num / 60) % 60;
	const seconds = sec_num % 60;

	return `${hours ? `${hours}h` : ""}${minutes ? `${hours ? " " : ""}${minutes}m` : ""}${
		seconds ? `${minutes ? " " : ""}${seconds}s` : ""
	}`;
};

const mergeDateAndTime = (scheduleDate: Date | string, scheduleTime: Date | string) => {
	const scheduleDateData = new Date(scheduleDate);
	const scheduleTimeData = new Date(scheduleTime);

	return new Date(
		scheduleDateData.getFullYear(),
		scheduleDateData.getMonth(),
		scheduleDateData.getDate(),
		scheduleTimeData.getHours(),
		scheduleTimeData.getMinutes(),
		scheduleTimeData.getSeconds()
	);
};

const adjustColor = (color: string, amount: number) => {
	return (
		"#" +
		color
			.replace(/^#/, "")
			.replace(/../g, color => ("0" + Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2))
	);
};

export const formatSizeUnits = (bytes?: number) => {
	let size = "";
	if (bytes && !isNaN(bytes)) {
		if (bytes >= 1073741824) {
			size = (bytes / 1073741824).toFixed(2) + " GB";
		} else if (bytes >= 1048576) {
			size = (bytes / 1048576).toFixed(2) + " MB";
		} else if (bytes >= 1024) {
			size = (bytes / 1024).toFixed(2) + " KB";
		} else if (bytes > 1) {
			size = bytes + " bytes";
		} else if (bytes === 1) {
			size = bytes + " byte";
		} else {
			size = "0 bytes";
		}
	}
	return size;
};

export const updateElSize = (baseEl?: HTMLElement, val = "", extraWidth = 0) => {
	if (baseEl) {
		const tempDiv = document.createElement("div");
		tempDiv.innerText = val;
		// tempDiv.style.cssText = window.getComputedStyle(inputRef.current, "").cssText;
		const styles = window.getComputedStyle(baseEl);
		if (styles.cssText !== "") {
			tempDiv.style.cssText = styles.cssText;
		} else {
			tempDiv.style.cssText = Object.values(styles).reduce(
				(css, propertyName) => `${css}${propertyName}:${styles.getPropertyValue(propertyName)};`
			);
		}
		tempDiv.style.opacity = "0";
		tempDiv.style.overflow = "hidden";
		tempDiv.style.maxHeight = "0";
		tempDiv.style.width = "fit-content";
		document.body.appendChild(tempDiv);
		(baseEl as HTMLInputElement).style.width = `${tempDiv.clientWidth + extraWidth}px`;
		document.body.removeChild(tempDiv);
	}
};

const uniqBy = <T>(array: T[], key: keyof T) => {
	const seen = new Set();
	return array.filter(item => {
		const el = item[key];
		return seen.has(el) ? false : seen.add(el);
	});
};

export const getUserTimezone = () => {
	const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
	let tz = timezones.find(t => t.tzCode.toLowerCase() === userTimezone.toLowerCase());

	if (!tz?.tzCode && userTimezone.includes("/")) {
		const paths = userTimezone.split("/");
		const lastPart = paths[paths.length - 1];

		tz = timezones.find(t => t.tzCode.toLowerCase().includes(lastPart.toLowerCase()));
	}

	return {
		label: tz?.label || "",
		value: tz?.tzCode || ""
	};
};

export {
	toBase64,
	dataUrlToFile,
	correctName,
	extractContentFromHTML,
	safelyParseJSON,
	filterFiles,
	convertToNumberFormat,
	downscaleImage,
	numberWithCommas,
	formatCount,
	isMobileSafari,
	hashCode,
	openFileByLink,
	downloadFileByLink,
	secondsToHumanTime,
	mergeDateAndTime,
	adjustColor,
	uniqBy,
	isRealMobile
};
