import React, { FC, useState } from "react";

import { ButtonBase } from "@material-ui/core";

import clsx from "clsx";

import { StyledAbout, StyledWrapper } from "modules/MemberHome/View/shared/style";

import { TableWrapper } from "shared/Components";
import { useFundraisers } from "shared/hooks";
import { Text } from "shared/ui-kit";

import { formatMoney } from "utils/formatMoney";

import GroupsTable from "./Tables/GroupsTable";
import MembersTable from "./Tables/MembersTable";
import SponsorsTable from "./Tables/SponsorsTable";

import { DonationCard } from "../../../FundraiserDonationDialog/style";

enum TableMode {
	MEMBER,
	GROUP,
	SPONSOR
}

const LeaderboardSection: FC<{ nonRegisteredView?: boolean; workspace?: string | null }> = ({
	nonRegisteredView,
	workspace
}) => {
	const [mode, setMode] = useState<TableMode>(TableMode.MEMBER);

	const { getData: getFundraiserData } = useFundraisers();
	const { fundraiser } = getFundraiserData();

	if (!fundraiser) return null;

	return (
		<div className="flex items-start justify-start flex-col gap-4 w-full">
			{!nonRegisteredView && (
				<StyledAbout className="w-full">
					<DonationCard className="p-0 m-0 noBorder noShadow flex flex-wrap gap-4 w-full">
						<div className="on-behalf-of flex-1 mt-3 p-4">
							<Text variant="body2" className="font-semibold">
								Total Raised
							</Text>
							<div className="flex items-end gap-1 mt-3 mb-2">
								<Text variant="h5" className="leading-5">
									{formatMoney(fundraiser.totalDonated)}
								</Text>
								<Text variant="body2" className="total">
									/ {formatMoney(fundraiser.goalAmount)}
								</Text>
							</div>
						</div>
						<div className="on-behalf-of flex-1 mt-3 p-4">
							<Text variant="body2" className="font-semibold">
								Sponsors
							</Text>
							<Text variant="h5" className="mt-1">
								{fundraiser.totalDonations}
							</Text>
						</div>
						{/* <div className="on-behalf-of flex-1 mt-3 p-4">
						<Text variant="body2" className="font-semibold">
							Monthly subscribers
						</Text>
						<Text variant="h5" className="mt-1">
							0
						</Text>
					</div> */}
						<div className="on-behalf-of flex-1 mt-3 p-4">
							<Text variant="body2" className="font-semibold">
								Avg donation amount
							</Text>
							<Text variant="h5" className="mt-1">
								$
								{fundraiser.totalDonated > 0 && fundraiser.totalDonations > 0
									? (fundraiser.totalDonated / fundraiser.totalDonations).toFixed(2)
									: 0}
							</Text>
						</div>
					</DonationCard>
				</StyledAbout>
			)}
			<StyledAbout className="w-full p-0 pb-2">
				<div className="px-4 pt-6">
					<Text variant="h5">Leaderboard</Text>
					<StyledWrapper.Sections className="p-0 shadow-none overflow-visible border-0">
						<ButtonBase
							onClick={() => setMode(TableMode.MEMBER)}
							className={clsx(`section ${mode === TableMode.MEMBER && "active"}`)}
						>
							Members
						</ButtonBase>
						<ButtonBase
							onClick={() => setMode(TableMode.GROUP)}
							className={clsx(`section ${mode === TableMode.GROUP && "active"}`)}
						>
							Groups
						</ButtonBase>
						<ButtonBase
							onClick={() => setMode(TableMode.SPONSOR)}
							className={clsx(`section ${mode === TableMode.SPONSOR && "active"}`)}
						>
							Sponsors
						</ButtonBase>
					</StyledWrapper.Sections>
				</div>
				<TableWrapper sizes={{ horizontalTablet: 900 }}>
					<div className={clsx(mode === TableMode.MEMBER ? "visible h-auto" : "invisible h-0")}>
						<MembersTable workspace={workspace} show={mode === TableMode.MEMBER} />
					</div>
					<div className={clsx(mode === TableMode.GROUP ? "visible h-auto" : "invisible h-0")}>
						<GroupsTable workspace={workspace} show={mode === TableMode.GROUP} />
					</div>
					<div className={clsx(mode === TableMode.SPONSOR ? "visible h-auto" : "invisible h-0")}>
						<SponsorsTable workspace={workspace} show={mode === TableMode.SPONSOR} />
					</div>
				</TableWrapper>
			</StyledAbout>
		</div>
	);
};

export default LeaderboardSection;
