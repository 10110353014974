import React, { memo, useEffect, useState } from "react";

import styled from "styled-components";

import { PrivacyField } from "shared/Components";
import { useCommunity } from "shared/hooks";
import { Box, Text } from "shared/ui-kit";

import useConfirmLeavePage from "../ConfirmLeave/hooks/useConfirmLeavePage";
import SubmitButton from "../SubmitButton";

const Wrapper = styled(Box)`
	display: flex;
	flex-direction: row;
	flex: 1;
	margin: 30px 10px 0 0;
	justify-content: space-between;
	${props => props.theme.breakpoints.down("sm")} {
		flex-direction: column;
		width: 100%;
	}
`;

const StyledPrivacyField = styled(PrivacyField)`
	margin-right: 20px;
`;

const CommunityPrivacy = memo(() => {
	const { updateWorkspaceMeta, getData: getCommunityData } = useCommunity();
	const { workspace } = getCommunityData();

	const [isPrivate, setIsPrivate] = useState<boolean>(false);
	const { provideHandleCloseValues } = useConfirmLeavePage({});

	const onSave = async () => {
		await updateWorkspaceMeta({
			isPrivate
		});
	};

	useEffect(() => {
		if (workspace) {
			setIsPrivate(!!workspace?.isPrivate);
		}
	}, [workspace]);

	useEffect(() => {
		provideHandleCloseValues(workspace?.isPrivate !== isPrivate);
	}, [workspace, provideHandleCloseValues, isPrivate]);

	return (
		<>
			<Text>
				Choose whether you want your community to be public or private. Private communities are invited only
				communities, and users will not be able to join unless they receive an invitation.
			</Text>
			<Wrapper>
				<StyledPrivacyField isPrivate={isPrivate} setIsPrivate={setIsPrivate} />
				<SubmitButton
					onClick={onSave}
					WithLeftMargin
					disabled={workspace?.isPrivate === isPrivate}
					id="saveChangesCommunityPrivacy"
				>
					Save changes
				</SubmitButton>
			</Wrapper>
		</>
	);
});

export default CommunityPrivacy;
