import React from "react";

import { AddTeamMemberContent, ChangeMemberRoleContent, TeamMemberActions } from "./index";

const switchContent = (modalContent: string) => {
	switch (modalContent) {
		case "inviteViaEmail":
			return <AddTeamMemberContent />;
		case "changeRole":
			return <ChangeMemberRoleContent />;
		default:
			return <TeamMemberActions />;
	}
};

const ModalContent = ({ modalContent }) => {
	return <>{switchContent(modalContent)}</>;
};

export default ModalContent;
