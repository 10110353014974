import React, { ReactNode, useCallback, useMemo } from "react";

import TimeAgo from "react-timeago";
import { FileType } from "types/FilesContextValuesType";

import { CategoryProps } from "modules/Marketplace/Data/types";

import { PlaceholderImage } from "shared/Components";
import { useMemberRoutes } from "shared/hooks";
import { PlaceholderImageType } from "shared/types";
import { getFileIconAndType } from "utils/serviceUtils/fileExtension";

import { ModelItem } from "../index";

interface FileCardProps {
	file: FileType;
	highlightOnHover?: boolean;
	noPB?: boolean;
}

const FileCard: React.FC<FileCardProps> = ({ file, highlightOnHover, noPB }) => {
	const { getMemberRoutesData } = useMemberRoutes();
	const { routes } = getMemberRoutesData();

	const getTimeTitle = useCallback((createdAt: string, category?: CategoryProps): ReactNode => {
		return (
			<>
				{category ? `${category.name} • ` : ""}
				<TimeAgo
					date={createdAt}
					live={false}
					formatter={(value, unit, suffix) =>
						value < 60 && unit === "second" ? "Just now" : `${value} ${unit} ${suffix}`
					}
				/>
			</>
		);
	}, []);

	const href = useMemo(
		() => `${routes?.member.file.getPath()}/${file._id}${file?.groupId ? `?groupId=${file.groupId}` : ""}`,
		[routes?.member, file._id, file?.groupId]
	);

	return (
		<ModelItem
			noPB={noPB}
			autoHeight
			item={{
				_id: file._id,
				wrapByHref: true,
				href,
				img: file.coverPhoto?.url,
				logo: getFileIconAndType(file?.file?.url)?.icon,
				beforeTitle: getTimeTitle(file.createdAt, file.category),
				title: file.title,
				minimalistic: true,
				highlightOnHover,
				locked: file.locked,
				placeholderImg: (
					<PlaceholderImage type={PlaceholderImageType.FILE_PREVIEW} width={360} height={160} viewBox={"0 0 720 320"} />
				)
			}}
		/>
	);
};

export default FileCard;
