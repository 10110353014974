import { useMemo } from "react";

import { API_URLS } from "shared/constants";
import useHttpActions from "shared/services/api/core/useHttpActions";
import BaseConverter from "shared/services/converters/baseConverter";
import { CarpoolType, CreateCarpool } from "shared/types/CarpoolTypes";

import { useLocationApiService } from "./index";

const useCarpoolApiService = () => {
	const actions = useHttpActions();
	const locationService = useLocationApiService();

	const methods = useMemo(
		() => ({
			createCarpool: async (data: CreateCarpool) => {
				const placeDetail = await locationService.getLocationDetails(data.location.placeId);
				const location = {
					id: placeDetail.place_id,
					name: placeDetail.name,
					lat: placeDetail.geometry.location.lat,
					lon: placeDetail.geometry.location.lng,
					icon: placeDetail.icon,
					placeId: placeDetail.place_id,
					address: placeDetail.name
				};

				const carpool = BaseConverter.convert<{ carpool: CarpoolType }>(
					await actions.post(API_URLS.CARPOOL.CREATE, {
						...data,
						location
					})
				);

				return { location, ...carpool };
			},
			signUpCarpoolRequest: async (id: string) => {
				return BaseConverter.convert<{ success: boolean; connection }>(
					await actions.put(API_URLS.CARPOOL.OFFER_A_RIDE(id), {})
				);
			},
			cancelCarpoolRequest: async (id: string) => {
				return BaseConverter.convert<{ success: boolean; connection }>(
					await actions.put(API_URLS.CARPOOL.CANCEL_OFFER(id), {})
				);
			},
			bookASeatCarpoolRequest: async (id: string, seatsCount: number) => {
				return BaseConverter.convert<{ success: boolean; connection }>(
					await actions.put(API_URLS.CARPOOL.BOOK_SEAT(id), { seatsCount })
				);
			},
			updateBookingCarpoolRequest: async (id: string, seatsCount: number) => {
				return BaseConverter.convert<{ success: boolean; connection }>(
					await actions.put(API_URLS.CARPOOL.CHANGE_BOOKING(id), { seatsCount })
				);
			},
			cancelBookingCarpoolRequest: async (id: string) => {
				return BaseConverter.convert<{ success: boolean; connection }>(
					await actions.put(API_URLS.CARPOOL.CANCEL_BOOKING(id), {})
				);
			},
			changeBookingCarpoolRequest: async (id: string, seatsCount: number) => {
				return BaseConverter.convert<{ success: boolean; connection }>(
					await actions.put(API_URLS.CARPOOL.CHANGE_BOOKING(id), { seatsCount })
				);
			}
		}),
		[actions, locationService]
	);

	return useMemo(
		() => ({
			...methods
		}),
		[methods]
	);
};

export default useCarpoolApiService;
