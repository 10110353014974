import React from "react";

import { InputProps as MuiInputProps } from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import { InputLabelProps } from "@material-ui/core/InputLabel/InputLabel";
import MuiTextField from "@material-ui/core/TextField";
import { StandardTextFieldProps } from "@material-ui/core/TextField/TextField";
import styled from "styled-components";

import { IExtendedTheme } from "../../themes/default";

export type InputProps = StandardTextFieldProps & {
	customStyle?: () => void;
	errorText?: string;
	helper?: string;
	leftIcon?: any;
	prefix?: string;
	readOnly?: boolean;
	register?: React.Ref<any>;
	rightIcon?: any;
	rows?: number;
	success?: boolean;
	theme: IExtendedTheme;
	fakeDisabled?: boolean;
};

export default styled(
	({
		id,
		errorText,
		// readOnly,
		register,
		className,
		prefix,
		leftIcon,
		rightIcon,
		helper,
		success,
		type = "text",
		fakeDisabled,
		...rest
	}: Readonly<InputProps>): JSX.Element => {
		const inputProps: MuiInputProps = {
			autoComplete: "off",
			endAdornment: null,
			startAdornment: null,
			type
		};

		if (prefix) {
			inputProps.startAdornment = <InputAdornment position="start">{prefix}</InputAdornment>;
		}

		if (leftIcon) {
			inputProps.startAdornment = <InputAdornment position="start">{leftIcon}</InputAdornment>;
		}

		if (rightIcon) {
			inputProps.endAdornment = <InputAdornment position="end">{rightIcon}</InputAdornment>;
		}

		const classNames = `${className} ${success ? "success" : ""} ${fakeDisabled ? "fake-disabled" : ""}`;

		const inputLabelProps: InputLabelProps = {};
		const alwaysShrinkInputTypes = ["time", "week"];
		if (alwaysShrinkInputTypes.includes(type as string)) {
			inputLabelProps.shrink = true;
		}

		return (
			<div className={classNames}>
				<MuiTextField
					{...rest}
					multiline={rest.rows ? rest.rows > 1 : false}
					id={id}
					fullWidth={true}
					helperText={errorText || helper}
					inputRef={register}
					InputProps={inputProps}
					variant="outlined"
					error={!!errorText}
					InputLabelProps={inputLabelProps}
				/>
			</div>
		);
	}
)`
	${({ customStyle }: InputProps) => (customStyle ? customStyle() : "")}

	& {
		&.success {
			.MuiOutlinedInput-notchedOutline {
				border-color: ${({ theme }) => theme.palette.inputColors.outlinedBorderColor};
			}
		}

		&.fake-disabled {
			.MuiInputBase-root {
				color: ${({ theme }) => theme.palette.inputColors.disabledColor};
				background-color: ${({ theme }) => theme.palette.inputColors.disabledBackgroundColor};
			}
		}

		.MuiInputBase-root.Mui-disabled {
			color: ${({ theme }) => theme.palette.inputColors.disabledColor};
			background-color: ${({ theme }) => theme.palette.inputColors.disabledBackgroundColor};
		}

		.MuiFormLabel-root.Mui-focused {
			color: ${({ theme }) => theme.palette.inputColors.focusedColor};
		}

		.MuiOutlinedInput-notchedOutline {
			legend {
				height: 5px;
			}
		}

		.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
			border-color: ${({ theme }) => theme.palette.inputColors.focusedOutlinedBorderColor};
			border-width: 1px;
		}

		.MuiInputAdornment-positionEnd {
			margin-right: 16px;
		}

		.MuiInputBase-input {
			height: 20px;
		}

		.MuiInputBase-inputMultiline {
			height: auto;
		}

		.MuiInputAdornment-root p {
			font-size: 15px;
			line-height: 1.33;
		}

		.MuiFormHelperText-root {
			font-size: 12px;
			line-height: 1.33;
			margin-top: 4px;
			margin-left: 16px;
		}
	}
`;
