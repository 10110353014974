import React, { useCallback, useEffect, useMemo } from "react";

import { Box, FormControl, InputAdornment, TextField } from "@material-ui/core";
import { useForm } from "react-hook-form";

import styled, { css } from "styled-components";

import { MemberDialog } from "modules/MemberHome/View/shared";
import { MemberDialogContentWrapper } from "modules/MemberHome/View/shared/style";
import { Dialog } from "shared/Components";
import { useUser, useYoutube } from "shared/hooks";
import { PollModelResponse } from "shared/types";
import { Button, Icon, Text } from "shared/ui-kit";
import { ValidationAdornmentIcon } from "utils/urlValidator";

const Title = <Text variant="h7">Add YouTube video</Text>;

const CreateButton = styled(Button)`
	height: 48px;
	font-size: 16px;
	font-weight: 600;
	line-height: 1.25;
	color: #6173fe;
`;

const VideoPreview = styled(Box)`
	text-align: center;
	width: 100%;
	${({ isMemberView }) =>
		isMemberView
			? css`
					margin-top: 16px;
			  `
			: css`
					padding: 24px;
					${props => props.theme.breakpoints.down("sm")} {
						padding: 24px 0 0 0;
					}
			  `}
`;

const StyledImg = styled.img`
	width: ${({ isMemberView }) => (isMemberView ? "100%" : "400px")};
	border-radius: ${({ isMemberView }) => (isMemberView ? "8px" : "0")};
	${props => props.theme.breakpoints.down("sm")} {
		width: 250px;
	}
`;

//eslint-disable-next-line no-useless-escape
const regExp =
	/^https?:\/\/(?:www\.youtube(?:-nocookie)?\.com\/|m\.youtube\.com\/|youtube\.com\/)?(?:ytscreeningroom\?vi?=|youtu\.be\/|vi?\/|user\/.+\/u\/\w{1,2}\/|embed\/|watch\?(?:.*&)?vi?=|&vi?=|\?(?:.*&)?vi?=)([^#&?\n\/<>"']*)/i;

export const getVideoId = (url: string) => {
	const match = url.match(regExp);
	return match && match[1].length === 11 ? match[1] : false;
};

interface Props {
	onSelect: (url: Omit<PollModelResponse, "totalResponses">) => void;
	onClose: (url: {}, reason: string) => void;
	open: boolean;
}

const AddYoutube: React.FC<Props> = ({ onSelect, onClose, open }) => {
	const { validateVideoById, setIsValidVideo, getData: getYoutubeData } = useYoutube();
	const { isValidVideo, validating } = getYoutubeData();

	const { getData: getUserData } = useUser();
	const { isMemberView } = getUserData();

	const { handleSubmit, register, watch, setValue } = useForm();

	const url = watch("url", "");

	useEffect(() => {
		setValue("url", "");
		setIsValidVideo(false);
	}, [setValue, setIsValidVideo]);

	const handleChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			const url = event.target.value;

			if (!regExp.test(url) && isValidVideo) {
				setIsValidVideo(false);
				return;
			}

			const splitPart = url.includes("youtu.be/") ? "youtu.be/" : url.includes("/watch?v=") ? "/watch?v=" : "";
			if (splitPart) {
				const videoId = url.split(splitPart)[1];
				if (videoId) {
					validateVideoById(videoId.split("&")[0]);
				}
			}
		},
		[isValidVideo, setIsValidVideo, validateVideoById]
	);

	const onSubmit = data => {
		onSelect(data);
	};

	const dialogContent = useMemo(
		() => (
			<>
				<FormControl>
					<TextField
						name="url"
						autoComplete="off"
						error={url && !isValidVideo}
						helperText={url && !isValidVideo && "Video with this URL does not exist"}
						placeholder="Paste YouTube video URL here…"
						variant="outlined"
						inputRef={register}
						required
						onChange={handleChange}
						className={isMemberView ? "isMemberView" : ""}
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<Icon name="link" group="filled" />
								</InputAdornment>
							),
							endAdornment: (
								<InputAdornment position="end" style={{ marginRight: "10px" }}>
									<ValidationAdornmentIcon
										status={validating ? "loading" : isValidVideo ? "valid" : url ? "invalid" : ""}
									/>
								</InputAdornment>
							)
						}}
					/>
				</FormControl>
				{isValidVideo && (
					<VideoPreview isMemberView={isMemberView}>
						<StyledImg
							isMemberView={isMemberView}
							src={`https://img.youtube.com/vi/${getVideoId(url)}/maxresdefault.jpg`}
							alt={""}
						/>
					</VideoPreview>
				)}
			</>
		),
		[handleChange, isValidVideo, register, url, validating, isMemberView]
	);

	return isMemberView ? (
		<MemberDialog
			title="Add YouTube video"
			open={open}
			onClose={onClose}
			customWidth={480}
			footerPrimary={{ text: "Done", disabled: !url || validating || !isValidVideo, onClick: handleSubmit(onSubmit) }}
		>
			<MemberDialogContentWrapper className="pv-20">{dialogContent}</MemberDialogContentWrapper>
		</MemberDialog>
	) : (
		<Dialog
			title={Title}
			open={open}
			onClose={onClose}
			footer={
				<CreateButton
					buttonTheme="light"
					onClick={handleSubmit(onSubmit)}
					disabled={!url || validating || !isValidVideo}
				>
					Done
				</CreateButton>
			}
		>
			{dialogContent}
		</Dialog>
	);
};

export default AddYoutube;
