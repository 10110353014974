import React, { ReactNode } from "react";

import { BodyWrapper, Title, TitleWrapper, Wrapper } from "./style";

interface BoxItemProps {
	customPadding?: string;
	title?: string;
	children?: ReactNode;
	pageBlock?: boolean;
	pageItem?: boolean;
	ml?: string;
	smallTitle?: boolean;
	className?: string;
	customTitle?: ReactNode;
}

const BoxItem: React.FC<BoxItemProps> = ({
	ml,
	className,
	customPadding,
	title,
	children,
	pageBlock,
	pageItem,
	smallTitle,
	customTitle
}) => (
	<Wrapper
		customPadding={customPadding}
		className={`box-item ${pageBlock ? "pageBlock" : ""} ${pageItem ? "pageItem" : ""} ${className}`}
	>
		{title && (
			<TitleWrapper className={`box-item ${pageBlock ? "pageBlock" : ""}`}>
				<Title smallTitle={smallTitle} ml={ml}>
					{title}
				</Title>
			</TitleWrapper>
		)}
		{customTitle}
		<BodyWrapper>{children}</BodyWrapper>
	</Wrapper>
);

export default BoxItem;
