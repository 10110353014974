import React, { FC, useCallback, useEffect, useMemo, useState } from "react";

import { ReactComponent as CatIcon } from "assets/images/cat-illustration.svg";
import { List } from "modules/MemberHome/View/Components";
import { MemberDialog } from "modules/MemberHome/View/shared";
import { useFirebase, useMessaging } from "modules/Messaging/Data";
import { useConnection, useDebounce, useMembers, useUser } from "shared/hooks";
import { EventModel, GroupModel, MediaModel, MembersModel, ProfileType, UserStatus } from "shared/types";
import { EventMessageModel } from "shared/types/EventModel";
import { Box, Button, Search, Text } from "shared/ui-kit";

interface Props {
	open: boolean;
	setShareViaMessageDialog: React.Dispatch<React.SetStateAction<boolean>>;
	group?: GroupModel;
	event?: EventModel;
}

const ShareGroupViaMessageDialog: FC<Props> = ({ open, setShareViaMessageDialog, group, event }) => {
	const [keyword, setKeyword] = useState<string>("");
	const [connections, setConnections] = useState<ProfileType[]>([]);
	const [connectionsCount, setConnectionsCount] = useState(0);
	const [page, setPage] = useState(1);
	const { getMembers, getMemberTotalCount, setUsers, getData: getMembersData, setLoadingMembers } = useMembers();
	const { users, totalCount, loadingMembers } = getMembersData();

	const { createAutoConnection, createConnection } = useMessaging();
	const { getData: getUserData, isPrivilegedRole } = useUser();
	const { user } = getUserData();
	const { onSend } = useFirebase();

	const { getUsersConnections } = useConnection();

	const debouncedKeyword = useDebounce(keyword, 300);

	const usersConnections = useCallback(
		async keyword => {
			try {
				setLoadingMembers(true);
				const { total, connections } = await getUsersConnections({ keyword, offset: page });
				if (!!connections?.length) {
					setConnections(ctx => (page === 1 ? connections : [...ctx, ...connections]));
					setConnectionsCount(total);
				}
			} catch (error) {
				console.error(error);
			} finally {
				setLoadingMembers(false);
			}
		},
		[getUsersConnections, page, setLoadingMembers]
	);

	useEffect(() => {
		if (open) {
			if (isPrivilegedRole) {
				getMembers(
					{
						offset: page,
						limit: 10,
						type: "all",
						keyword: debouncedKeyword,
						filterBy: [UserStatus.ON_BOARDED],
						sortBy: "connections"
					},
					false,
					true
				);
			} else usersConnections(debouncedKeyword);
		}
	}, [open, debouncedKeyword, getMembers, page, isPrivilegedRole, usersConnections]);

	useEffect(() => {
		if (open && isPrivilegedRole)
			getMemberTotalCount({
				type: "all",
				filterBy: [UserStatus.ON_BOARDED],
				keyword: debouncedKeyword
			});
	}, [getMemberTotalCount, debouncedKeyword, open, isPrivilegedRole]);

	const place: EventMessageModel | undefined = useMemo(
		() =>
			event
				? {
						id: event.eventId,
						image: event.eventImages[0],
						name: event.title,
						location: event.location
				  }
				: undefined,
		[event]
	);

	const shareButtonHandler = useCallback(
		async (item: MembersModel & { isShared: boolean }) => {
			if (user?.activeProfile && item.personaId) {
				await createAutoConnection(String(item.personaId), String(user?.activeProfile));
				const chatResponse = await createConnection(item.personaId);
				if (chatResponse && (group || place)) {
					onSend({
						messages: [
							{
								text: "",
								user: {
									_id: user?.profiles[0].chatUserId,
									name: user?.profiles[0].firstName,
									avatar: (user?.profiles[0].photos[0] as MediaModel).profilePicture
								},
								group,
								place
							}
						],
						chat: chatResponse
					});

					isPrivilegedRole
						? setUsers(users.map(u => (u._id === item._id ? { ...u, isShared: true } : u)))
						: setConnections(ctx => ctx.map(u => (u._id === item._id ? { ...u, isShared: true } : u)));
				}
			}
		},
		[
			isPrivilegedRole,
			place,
			createAutoConnection,
			createConnection,
			user?.profiles,
			user?.activeProfile,
			onSend,
			users,
			setUsers,
			group
		]
	);

	const RenderItem = useCallback(
		(item: MembersModel & { isShared: boolean }) => (
			<Box className="flex justify-between items-centers w-full py-2">
				<Box className="flex items-center w-3/4">
					<img
						className="w-10 h-10 rounded-md"
						src={item?.profilePhoto || item?.photos?.[0]?.profilePicture}
						alt="avatar"
					/>
					<Box className="grid ml-4">
						<Text className="truncate">{`${item.firstName} ${item.lastName}`}</Text>
					</Box>
				</Box>
				<Button buttonTheme={"light"} palette="light" onClick={() => shareButtonHandler(item)} disabled={item.isShared}>
					{item.isShared ? "Shared" : "Share"}
				</Button>
			</Box>
		),
		[shareButtonHandler]
	);

	const loadMore = useCallback(() => {
		if (!loadingMembers) {
			setPage(page => page + 1);
		}
	}, [setPage, loadingMembers]);

	const MemberList = useMemo(
		() => (
			<List
				title={""}
				items={isPrivilegedRole ? users : connections}
				renderItem={RenderItem}
				loading={loadingMembers}
				totalCount={isPrivilegedRole ? totalCount : connectionsCount}
				fullBodySize
				onLoadMore={loadMore}
				noContentBlock={
					<Box className="w-full flex flex-col items-center my-20">
						<CatIcon />
						<Text className="mt-6">No Pending Request Found</Text>
					</Box>
				}
				skeletonSizeSmall
				wrapperClassName="shadow-none"
				itemWrapClassName=" "
			/>
		),
		[RenderItem, users, loadingMembers, totalCount, loadMore, isPrivilegedRole, connections, connectionsCount]
	);

	return (
		<MemberDialog
			title={"Share via Vyoo Messages"}
			open={open}
			onClose={() => {
				setShareViaMessageDialog(false);
				setUsers([]);
				setConnections([]);
				setConnectionsCount(0);
				setPage(1);
			}}
			noFooter
			customWidth={512}
		>
			<Box>
				<Box className="bg-gray-100 mt-4 mx-4 overflow-hidden">
					<Search
						placeholder="Search"
						fullWidth
						onChange={e => {
							setKeyword(e.target.value);
							setPage(1);
						}}
					/>
				</Box>
				{MemberList}
			</Box>
		</MemberDialog>
	);
};

export default ShareGroupViaMessageDialog;
