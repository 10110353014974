import React, { useCallback, useEffect, useMemo, useState } from "react";

import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	InputAdornment,
	MenuItem,
	useMediaQuery
} from "@material-ui/core";
import { AddCircleRounded, DragIndicator, ExpandMore, MoreVert } from "@material-ui/icons";

import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

import { ReactComponent as SearchIcon } from "assets/icons/iconSearch.svg";
import { FaqItemType } from "modules/Settings/Data/types";

import { Dialog } from "shared/Components";
import { useDebounce, useNotification } from "shared/hooks";
import { Button, Icon, Text, Tooltip } from "shared/ui-kit";
import * as appTheme from "theme/default";
import theme from "theme/default";
import { actionMethod, actionTypes, getValidationMessage } from "utils/getValidationMessage";

import {
	ButtonWrapper,
	Container,
	ContentContainer,
	DropdownMenu,
	FaqItemContainer,
	IconContainer,
	NoResultsWrapper,
	PopupDescription,
	PopupTitle,
	Row,
	SearchField,
	SkeletonContainer,
	SkeletonQuestionWrapper,
	SkeletonRow,
	Splitter,
	StyledButton,
	StyledIcon,
	Title,
	TitleContainer
} from "./style";

import { useFaq } from "../../../Data";

import { AddFaqModal } from "../../Components/AddFaqModal";

const Faqs = () => {
	const [faqToDelete, setFaqToDelete] = useState("");
	const [deleteOpen, setDeleteOpen] = useState(false);
	const {
		getFaqItems,
		setStateOfModal,
		createFaqItem,
		deleteFaq,
		setNewFaq,
		reOrderFaqItem,
		getData: getFaqData
	} = useFaq();
	const { faqs, isLoading } = getFaqData();

	const [keyword, setKeyword] = useState("");
	const [anchorEl, setAnchorEl] = useState(null);
	const [focusedFaqEl, setFocusedFaqEl] = useState<FaqItemType | null>(null);
	const isMobile = useMediaQuery(appTheme.default.breakpoints.down("sm"));

	const handleSearch = e => {
		const keyword = e.target.value;
		if (keyword === "" || keyword.length >= 3) {
			setKeyword(keyword);
		}
	};

	const handleFaqEdit = useCallback(
		faq => {
			setStateOfModal({
				modalTitle: "Edit Question",
				apiRequest: createFaqItem,
				modalFooterActionsText: { submitText: "Save Changes" },
				faq: faq,
				order: 0
			});
		},
		[createFaqItem, setStateOfModal]
	);

	const { showMessage } = useNotification();

	const debouncedSearchTerm = useDebounce(keyword, 300);

	useEffect(() => {
		getFaqItems(debouncedSearchTerm);
	}, [debouncedSearchTerm, getFaqItems]);

	const getIcon = () => <Icon fill={theme.palette.primary.main} name={"question"} group="filled" />;

	const create = useCallback(
		(order?) => {
			setStateOfModal({
				modalTitle: "Create Question",
				apiRequest: createFaqItem,
				modalFooterActionsText: { submitText: "Create Question" },
				faq: { _id: "", title: "", description: "", order: 0 },
				order
			});
		},
		[setStateOfModal, createFaqItem]
	);

	const confirmPopupAction = useCallback(
		async (confirm: boolean) => {
			setDeleteOpen(false);
			if (confirm && faqToDelete) {
				await deleteFaq(faqToDelete);
				showMessage(
					getValidationMessage({
						name: "FAQ",
						actionType: actionTypes.CRUD,
						actionMethod: actionMethod.deleted,
						emoji: "🗑"
					}),
					3
				);
			}
		},
		[deleteFaq, faqToDelete, showMessage]
	);

	const ActionFooter = useMemo(
		() => (
			<>
				<Button buttonTheme={"outline"} size={"large"} onClick={() => confirmPopupAction(false)}>
					Keep
				</Button>
				<Button size={"large"} onClick={() => confirmPopupAction(true)}>
					Delete
				</Button>
			</>
		),
		[confirmPopupAction]
	);

	const handleDelete = useCallback(id => {
		if (id) {
			setFaqToDelete(id);
			setDeleteOpen(true);
		}
	}, []);

	const onDragEnd = useCallback(
		result => {
			if (!result.destination) {
				return;
			}

			const reorder = (list, startIndex, endIndex) => {
				const result: FaqItemType[] = Array.from(list);
				const [removed] = result.splice(startIndex, 1);
				result.splice(endIndex, 0, removed);

				return result;
			};

			const faqItem = faqs[result.source.index];

			reOrderFaqItem({
				title: faqItem.title,
				description: faqItem.description,
				order: result.source.index + 1,
				targetOrder: result.destination.index + 1,
				_id: faqItem._id
			});

			const newFaqOrder = reorder(faqs, result.source.index, result.destination.index);
			newFaqOrder[result.destination.index].order = result.destination.index + 1;
			setNewFaq({
				newFaqOrder,
				result
			});
		},
		[faqs, setNewFaq, reOrderFaqItem]
	);

	const handleClick = (e, el: FaqItemType) => {
		setAnchorEl(e.currentTarget);
		setFocusedFaqEl(el);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const content = useMemo(
		() =>
			keyword && !faqs.length ? (
				<NoResultsWrapper>
					<Text variant="h6">We did not find any results</Text>
					<Text>Make sure everything is spelled correctly or try different keywords.</Text>
				</NoResultsWrapper>
			) : (
				<DragDropContext onDragEnd={onDragEnd}>
					<Droppable droppableId="droppable">
						{provided => (
							<div {...provided.droppableProps} ref={provided.innerRef} className="drag-container">
								{faqs.map((el, index) => (
									<Draggable key={index} draggableId={`${index}`} index={index}>
										{provided => (
											<FaqItemContainer ref={provided.innerRef} {...provided.draggableProps}>
												<div className="reorder-button">
													<IconContainer {...provided.dragHandleProps}>
														<Tooltip text="Reorder">
															<DragIndicator htmlColor="#c5cee0" />
														</Tooltip>
													</IconContainer>
												</div>
												<Accordion key={index} id={`expandFAQ${index + 1}`}>
													<AccordionSummary
														expandIcon={!isMobile && <ExpandMore />}
														aria-label="Expand"
														aria-controls="additional-actions1-content"
														id="additional-actions1-header"
													>
														<div className="reorder-button-sm">
															<IconContainer {...provided.dragHandleProps}>
																<Tooltip text="Reorder">
																	<DragIndicator htmlColor="#c5cee0" />
																</Tooltip>
															</IconContainer>
														</div>
														<TitleContainer>
															<div className="left-side">
																<div className="icon">{getIcon()}</div>
																<div className="title">{el.title}</div>
															</div>
															<div className="dropdown">
																<Button
																	aria-owns={anchorEl ? "dropdown-menu" : undefined}
																	aria-haspopup="true"
																	onClick={e => handleClick(e, el)}
																	size="small"
																>
																	<MoreVert htmlColor="#c5cee0" />
																</Button>
															</div>
														</TitleContainer>
													</AccordionSummary>
													<AccordionDetails>
														<ContentContainer>
															<Splitter />
															{el.description}
														</ContentContainer>
													</AccordionDetails>
												</Accordion>
												<div className="edit-buttons">
													<IconContainer>
														<Tooltip text="Create">
															<AddCircleRounded
																className="fill-first-path"
																htmlColor="#c5cee0"
																onClick={() => create(el.order)}
																id={`createFAQicon${index + 1}`}
															/>
														</Tooltip>
													</IconContainer>
													<IconContainer>
														<Tooltip text="Edit">
															<StyledIcon
																className="fill-second-path"
																fill="#c5cee0"
																name={"pencil"}
																group="filled"
																onClick={() => {
																	handleFaqEdit(el);
																}}
																id={`editFAQ${index + 1}`}
															/>
														</Tooltip>
													</IconContainer>
													<IconContainer>
														<Tooltip text="Delete">
															<StyledIcon
																className="fill-second-path"
																fill="#c5cee0"
																name={"trash"}
																group="linear"
																onClick={() => {
																	handleDelete(el._id);
																}}
																id={`deleteFAQ${index + 1}`}
															/>
														</Tooltip>
													</IconContainer>
												</div>
											</FaqItemContainer>
										)}
									</Draggable>
								))}
								<DropdownMenu id="dropdown-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
									<MenuItem
										onClick={() => {
											create();
											handleClose();
										}}
									>
										<AddCircleRounded htmlColor="#c5cee0" onClick={() => {}} />
										<Text variant="body2">Create</Text>
									</MenuItem>
									<MenuItem
										onClick={() => {
											handleFaqEdit(focusedFaqEl);
											handleClose();
										}}
									>
										<StyledIcon fill="#c5cee0" name={"pencil"} group="filled" onClick={() => {}} />
										<Text variant="body2">Edit</Text>
									</MenuItem>
									<MenuItem
										onClick={() => {
											handleDelete(focusedFaqEl?._id);
										}}
									>
										<StyledIcon fill="#c5cee0" name={"trash"} group="linear" onClick={() => {}} />
										<Text variant="body2">Delete</Text>
									</MenuItem>
								</DropdownMenu>
								{provided.placeholder}
							</div>
						)}
					</Droppable>
				</DragDropContext>
			),
		[keyword, faqs, onDragEnd, isMobile, anchorEl, create, handleFaqEdit, handleDelete, focusedFaqEl]
	);

	return (
		<Container>
			<AddFaqModal handleDelete={handleDelete} />
			<Dialog
				title={<PopupTitle>Delete FAQ?</PopupTitle>}
				open={deleteOpen}
				onClose={() => confirmPopupAction(false)}
				footer={ActionFooter}
				hasBackButton={false}
				onBack={() => confirmPopupAction(false)}
			>
				<PopupDescription>Are you sure you want to permanently delete this question?</PopupDescription>
			</Dialog>
			{isLoading ? (
				<SkeletonContainer>
					<Row className={"no-margin"}>
						<SkeletonRow animation="wave" width={260} height={46} />
						<div className="right-side">
							<SkeletonRow animation="wave" width={230} height={46} />
							<ButtonWrapper>
								<SkeletonRow animation="wave" width={96} height={46} />
							</ButtonWrapper>
						</div>
					</Row>
					{[...Array.from(Array(5).keys())].map(i => (
						<SkeletonQuestionWrapper key={i}>
							<SkeletonRow animation="wave" width={"100%"} height={72} />
						</SkeletonQuestionWrapper>
					))}
				</SkeletonContainer>
			) : (
				<>
					<Row>
						<Title variant="h3">Community FAQ</Title>
						<div className="right-side">
							<SearchField
								defaultValue={keyword}
								onChange={handleSearch}
								name="name"
								autoComplete="off"
								placeholder="Search FAQ"
								variant="outlined"
								InputProps={{
									startAdornment: (
										<InputAdornment position="start">
											<SearchIcon fill="#475b7f" />
										</InputAdornment>
									)
								}}
								id="searchFAQ"
							/>
							<ButtonWrapper>
								<StyledButton buttonTheme="main" palette="primary" S onClick={() => create()} id="createFAQ">
									Create
								</StyledButton>
							</ButtonWrapper>
						</div>
					</Row>
					{content}
				</>
			)}
		</Container>
	);
};

export default Faqs;
