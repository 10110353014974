import React, { useMemo } from "react";

import { routes } from "apps/RegularUser/constants";

import { ActiveUsers, NewUsers, TopReferees } from "modules/Analytics/View/Components";
import { view as ManageViews } from "modules/Manage";
import { useFeature, useUser } from "shared/hooks";
import { FormColumn, Text } from "shared/ui-kit";
import { capitalizeFirstLetter } from "utils/capitaliseFirstLetter";

import { AnalyticContentWrapper, BlockTitle, BlockWrapper, Container, ManagePostBlock, Subtitle } from "./style";

export interface DashboardPageProps {}

const Dashboard: React.FC<DashboardPageProps> = () => {
	const { getData: getUserData } = useUser();
	const { user } = getUserData();

	const { isFeatureEnabled } = useFeature();

	const supportForum = useMemo(() => isFeatureEnabled("enableForum"), [isFeatureEnabled]);

	return (
		<Container>
			<Text variant="h3">
				Hi, {capitalizeFirstLetter(String(!!user?.profiles?.length ? user.profiles[0]?.firstName : ""))}!
			</Text>
			<Subtitle>Here are the main actions happening in your community</Subtitle>
			<BlockTitle>Stats</BlockTitle>
			<AnalyticContentWrapper container spacing={3}>
				<FormColumn xs={12} md={6} lg={4}>
					<BlockWrapper>
						<NewUsers displayCsvDownload={false} />
					</BlockWrapper>
				</FormColumn>
				<FormColumn xs={12} md={6} lg={4}>
					<BlockWrapper>
						<ActiveUsers displayCsvDownload={false} />
					</BlockWrapper>
				</FormColumn>
				<FormColumn xs={12} md={6} lg={4}>
					<BlockWrapper>
						<TopReferees displayCsvDownload={false} />
					</BlockWrapper>
				</FormColumn>
			</AnalyticContentWrapper>
			{supportForum && (
				<ManagePostBlock>
					<ManageViews.ManagePosts
						createPostRoute={routes.create.post.getPath()}
						editPostRoute={routes.edit.post.getPath()}
						isSubpage
					/>
				</ManagePostBlock>
			)}
		</Container>
	);
};

export default Dashboard;
