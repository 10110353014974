import React from "react";

import { useLocation } from "react-router-dom";

import { view } from "modules/MemberHome";
import { GroupSortBy, groupType } from "shared/types/GroupModel";

const AllGroupsPage = () => {
	const { search } = useLocation();
	const query = new URLSearchParams(search);

	const sortBy: GroupSortBy = (query.get("sortBy") as GroupSortBy) || undefined;
	const groupType: groupType = (query.get("groupType") || "all") as groupType;

	return <view.AllGroups sortBy={sortBy} groupType={groupType} />;
};

export default AllGroupsPage;
