import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";

import { Skeleton } from "@material-ui/lab";

import { useHistory } from "react-router";

import { useConnection, useMemberRoutes, useOnScreen, useUser } from "shared/hooks";

import { MediaModel, ProfileType } from "shared/types";

import { MutualConnection } from "shared/types/UserModel";

import { MemberDialog } from "../../shared";
import { RefBox } from "../../shared/MemberDialog/style";
import { ProfileListingDialogWrapper } from "../../shared/style";

const MyConnectionsDialog: FC<{ isMyProfile: boolean; viewerPersonaId?: number; displayedPersonaId: number }> = ({
	isMyProfile,
	viewerPersonaId,
	displayedPersonaId
}) => {
	const { setMyConnectionsDialog, getMutualConnections, getData: getUserData } = useUser();
	const { myConnectionsDialog } = getUserData();

	const { getUsersConnections } = useConnection();

	const [connections, setConnections] = useState<(ProfileType | MutualConnection)[]>([]);
	const [total, setTotal] = useState<number | null>(null);
	const [offset, setOffset] = useState(1);
	const [loadData, setLoadData] = useState(true);
	const [loading, setLoading] = useState(false);

	const lastConnRef = useRef<HTMLDivElement>(null);

	const onScreen = useOnScreen(lastConnRef);

	const skeleton = useMemo(
		() => (
			<ProfileListingDialogWrapper.UserOption skeleton disabled={true}>
				<Skeleton variant="circle" width={36} height={36} />
				<Skeleton width={160} height={18} />
			</ProfileListingDialogWrapper.UserOption>
		),
		[]
	);

	const history = useHistory();

	const { getMemberRoutesData } = useMemberRoutes();
	const { routes } = getMemberRoutesData();

	const hasMemo = useMemo(() => total && total > connections.length, [connections.length, total]);

	useEffect(() => {
		if (onScreen && hasMemo) {
			setOffset(state => state + 1);
			setLoadData(true);
		}
	}, [onScreen, hasMemo]);

	useEffect(() => {
		if (loadData) {
			setLoading(true);

			if (isMyProfile) {
				getUsersConnections({ limit: 20, offset }).then(({ total, connections }) => {
					offset > 1 ? setConnections(ctx => [...ctx, ...connections]) : setConnections(connections);
					setTotal(total);
					setLoadData(false);
					setLoading(false);
				});
			} else if (viewerPersonaId && displayedPersonaId) {
				getMutualConnections(viewerPersonaId, displayedPersonaId, 20, offset).then(({ connections, total }) => {
					offset > 1 ? setConnections(ctx => [...ctx, ...connections]) : setConnections(connections);
					setTotal(total);
					setLoadData(false);
					setLoading(false);
				});
			}
		}
	}, [displayedPersonaId, getMutualConnections, getUsersConnections, isMyProfile, loadData, offset, viewerPersonaId]);

	const handleGoToUser = useCallback(
		(pId: number) => {
			setMyConnectionsDialog(false);
			history.push(`${routes?.member.profile.getPath()}/${pId}`);
		},
		[history, routes?.member.profile, setMyConnectionsDialog]
	);

	return (
		<MemberDialog
			title={`${isMyProfile ? "My" : "Mutual"} Connections`}
			open={myConnectionsDialog}
			onClose={() => setMyConnectionsDialog(false)}
			footerPrimary={{
				text: "Close",
				onClick: () => setMyConnectionsDialog(false)
			}}
		>
			{connections.map((con, i) => (
				<RefBox key={con._id} ref={i + 1 === connections.length ? lastConnRef : null}>
					<ProfileListingDialogWrapper.UserOption onClick={() => handleGoToUser(con.personaId)}>
						{con.photos[0] && <img src={(con.photos[0] as MediaModel).profilePicture} alt={con.firstName} />}
						{con.firstName} {con.lastName}
					</ProfileListingDialogWrapper.UserOption>
				</RefBox>
			))}
			{loading && (
				<>
					{skeleton}
					{skeleton}
					{skeleton}
					{skeleton}
				</>
			)}
		</MemberDialog>
	);
};

export default MyConnectionsDialog;
