import { Counter } from "types/ForumPersonaModel";
import { Connection } from "types/StoriesContextValuesType";

import { MediaModel } from "shared/types";

import { CoverPhotosType } from "./MediaModel";

import { EducationJobModel } from "./TopRefereeModel";

import { ActivitySettingsModel } from "../../modules/Activity/Data/types/ActivityModel";

import { CommunityModel } from "../../types";

export enum UserStatus {
	PENDING = "PENDING",
	JOINED = "JOINED",
	ON_BOARDED = "ON_BOARDED",
	ARCHIVE = "ARCHIVE",
	PAID_SUBSCRIPTION = "PAID_SUBSCRIPTION",
	BANNED = "BANNED",
	DELETED = "DELETED"
}

export enum ExperienceLocationType {
	Experience = "Experience",
	Volunteer = "Volunteer",
	Education = "Education",
	License = "License"
}
export interface ProfileSelectableField {
	_id: string;
	label: string;
}

export interface ProfileExperienceField {
	startAt: string;
	endAt: string | null;
	description: string;
	present: boolean;
	active: boolean;
}

export interface ProfileEducationType extends ProfileExperienceField {
	school: ProfileSelectableField;
	degree: ProfileSelectableField;
	specialization: ProfileSelectableField;
}

export interface ProfileJobType extends ProfileExperienceField {
	location?: string;
	company: ProfileSelectableField;
	jobTitle: ProfileSelectableField;
	industry: ProfileSelectableField;
}

export interface ProfileGoalType extends ProfileSelectableField {
	isTop?: boolean;
	label: string;
}

export interface Cause {
	_id: string;
	label: string;
}

export interface Role {
	_id: string;
	label: string;
}

export interface Organization {
	_id: string;
	label: string;
}

export interface VolunteerType {
	location: string;
	startAt: Date;
	endAt?: any;
	description: string;
	present: boolean;
	active: boolean;
	cause: Cause;
	role: Role;
	organization: Organization;
}

export interface License {
	_id: string;
	label: string;
}

export interface CertificationType {
	credentialId: string;
	credentialUrl: string;
	issueAt?: any;
	expireAt?: any;
	expire: boolean;
	state: string;
	license: License;
	organization: Organization;
}

export interface ProfileType {
	_id: string;
	aboutMe?: string;
	address?: string;
	phoneCode?: string;
	phoneNumber?: string;
	blocked: any;
	portfolioUrl?: string;
	contactCount?: number;
	personaDocId: string;
	stories?: { _id: string; uri: string }[];
	externalId?: string;
	intro: {
		education?: ProfileEducationType[];
		job?: { company: ProfileSelectableField; jobTitle: ProfileSelectableField; industry: ProfileSelectableField }[];
	};
	personalInfo?: {
		birthday?: Date | string;
		gender?: string;
		birthdayHidden?: boolean;
		genderHidden?: boolean;
	};
	contactInfo?: {
		email: string;
		emailForDownloadLinks?: any;
		phoneNumber?: any;
		phoneCode?: any;
		phoneHidden: boolean;
		emailHidden: boolean;
		address?: any;
	};
	personaId: number;
	firstName: string;
	lastName: string;
	photos: string[] | Partial<MediaModel>[];
	coverPhotos?: string[] | Partial<CoverPhotosType>[];
	coverImage?: File;
	profileImage?: File;
	chatUserId?: string;
	active?: boolean;
	connections?: ProfileType[];
	education?: ProfileEducationType[];
	jobs?: ProfileJobType[];
	volunterExperience?: VolunteerType[];
	skills?: ProfileGoalType[];
	languages?: ProfileGoalType[];
	goals?: ProfileGoalType[];
	interests?: ProfileGoalType[];
	licensesAndCertifications?: CertificationType[];
	skinTone?: string | null;
	linkedAccount?: {
		facebook: string;
		linkedin: string;
		instagram: string;
		twitter: string;
		tiktok: string;
	};
	vyooable: boolean;
	connection?: Connection;
	maritalStatus?: string;
	journey: any[];
	counters: Counter;
	notificationSettings: ActivitySettingsModel;
	groupId?: string;
	group?: {
		coverUrl: string | null;
		name: string;
		slug: string;
		totalMembers: number;
		_id: string;
	};
}

export default interface UserModel {
	id: string;
	userId: string;
	loginId: string;
	email: string;
	role: string;
	profiles: ProfileType[];
	passedGuidedTour: boolean;
	activeProfile?: number;
	phoneCode?: string;
	phoneNumber?: string;
	isGlobalModerator?: boolean;
	isGlobalOwner?: boolean;
	isGlobalMember?: boolean;
	isGlobalAdmin?: boolean;
	isInfluencer?: boolean;
	gracePeriodExpired: boolean;
	personaDocId?: string;
	purchase: {
		coinBalance: number;
		productId: string | null;
		vyooRecruiterExpireDate: string | null;
		vyooPlusExpireDate: string | null;
		requestBalance: 0;
		subscriptionId: string | null;
	};
	fundraiserParticipantOfIds?: { _id: string; name: string; startDate: string }[];
	workspaces?: CommunityModel[];
	status?: UserStatus;
	token?: string;
	refreshToken?: string;
	tokenExpiresInDays?: number;
	verified?: boolean;
}

export interface Initiator {
	deleted: boolean;
	at?: any;
}

export interface Connectee {
	deleted: boolean;
	at: string;
}

export interface Deleted {
	initiator: Initiator;
	connectee: Connectee;
}

export interface UnReadCount {
	initiator: number;
	connectee: number;
}

export interface Photo {
	id: string;
	name: string;
	profilePicture: string;
	mediaId: string;
	active: boolean;
}

export interface CurrentCoordinates {
	lat: number;
	lon: number;
}

export interface CurrentLocation {
	locationId: string;
	name: string;
}

export interface MutualConnection {
	_id: string;
	initiatorId: number;
	connecteeId: number;
	status: string;
	acceptedAt: Date;
	expireAt?: Date;
	extend: string;
	lastMessage: string;
	deleted: Deleted;
	unReadCount: UnReadCount;
	updatedAt: Date;
	createdAt: Date;
	connectedTo: number;
	remainingHours: number;
	firstName: string;
	lastName: string;
	purpose: string;
	photos: Photo[];
	chatUserId: string;
	currentCoordinates: CurrentCoordinates;
	currentLocation: CurrentLocation;
	personaId: number;
	notifyToken: string;
	locationId: string;
}

export interface UserSelfieVerificationModel {
	comparison: {
		verified: boolean;
		confidence: number;
		err?: {
			message: string;
			code: string;
			time: string;
			statusCode: number;
			retryable: boolean;
			retryDelay: number;
		};
	};
	detectCustomlabels: {
		verified: boolean;
	};
}

export interface BlockedUser {
	name: string;
	personaId: number;
	profilePhoto: string;
	jobTitle: string;
}
export interface BlockedUserResponse {
	name: string;
	personaId: number;
	profilePhoto: string;
	intro: EducationJobModel;
}

export interface ReportBugRequest {
	personaId: number;
	message: string;
	photos: { uri: string }[];
}

export interface ChangePasswordRequest {
	globalUserId: string;
	currentPassword: string;
	password: string;
}

export interface UserReaction {
	pid: string;
	cid: string;
	reaction: {
		personaId: number;
		reaction: string;
	};
	firstName: string;
	lastName: string;
	photos: {
		mediaId: string;
		id: string;
		name: string;
		active: boolean;
		profilePicture: string;
	}[];
	personaId: number;
	intro: {
		education: EducationJobModel[];
		job: {
			active: boolean;
			companyId: string;
			titleId: string;
			industryId: string;
			companyLabel: string;
			titleLabel: string;
			industryLabel: string;
		}[];
	};
}
