import styled from "styled-components";

import { Box } from "shared/ui-kit";

export const Wrapper = styled(Box)`
	max-width: 240px;
	height: fit-content;
	max-height: 240px;

	position: relative;

	border-radius: 8px;

	overflow: hidden;

	margin: 16px auto 16px 0;

	img {
		margin: 0;
	}

	&.video-wrapper {
		max-width: 240px;
		height: auto;
		max-height: 240px;

		video {
			margin: 0;
		}
	}
`;

export const LoaderWrapper = styled(Box)`
	position: absolute;
	top: 0;
	left: 0;

	width: 100%;
	height: 100%;

	display: flex;
	justify-content: center;
	align-items: center;

	background-color: rgba(0, 0, 0, 0.8);
`;

export const StyledImg = styled.img`
	max-width: 100%;
	max-height: 100%;

	object-fit: cover;
	object-position: center;

	margin: 0;
`;

export const StyledVideo = styled.video`
	width: 100%;
	height: 100%;

	object-fit: cover;
	object-position: center;

	border-radius: inherit;

	margin: 0;
`;

export const CloseIconWrapper = styled(Box)`
	position: absolute;
	top: 8px;
	right: 8px;

	width: 24px;
	height: 24px;

	cursor: pointer;

	z-index: 2;

	svg {
		width: 100%;
		height: 100%;
	}
`;

export const ContentTypeWrapper = styled(Box)`
	position: absolute;
	bottom: 8px;
	left: 0;

	padding: 0 8px;

	width: fit-content;
	height: 24px;

	font-weight: bold;
	font-size: 18px;
	text-transform: uppercase;

	border-top-right-radius: 8px;
	border-bottom-right-radius: 8px;

	background-color: #fff;

	z-index: 2;
`;
