import React, { ReactNode, useMemo, useRef, useState } from "react";

import Slider from "react-slick";

import { ReactComponent as LeftArrowIcon } from "assets/icons/icon-arrow-left-bold.svg";
import { ReactComponent as RightArrowIcon } from "assets/icons/icon-arrow-right-bold.svg";

import { NavIcon, SliderWrapper } from "./style";

const PrevArrow = ({ onClick, show }: any) => (
	<NavIcon className="arrows arrow-prev" onClick={onClick} isNext={false} show={show}>
		<LeftArrowIcon width={13} />
	</NavIcon>
);

const NextArrow = ({ onClick, show }: any) => (
	<NavIcon className="arrows arrow-next" onClick={onClick} isNext={true} show={show}>
		<RightArrowIcon width={13} />
	</NavIcon>
);

interface SliderWrapProps {
	count?: number;
	itemSize?: number;
	children?: ReactNode;
	onEndScroll?: () => void;
	slickPadding?: boolean;
	hideFrom?: number;
	slidesPerRow?: number;
	slidesToShow?: number;
	speed?: number;
}

const SliderWrap: React.FC<SliderWrapProps> = ({
	count = 0,
	itemSize = 360,
	children,
	onEndScroll,
	slickPadding,
	hideFrom = 0,
	slidesPerRow = 1,
	slidesToShow = 1,
	speed = 150
}) => {
	const [sliderIndex, setSliderIndex] = useState(0);
	const sliderRef = useRef<any>(null);

	const settings = useMemo(() => {
		return {
			dots: false,
			infinite: false,
			speed,
			lazyLoad: false,
			slidesToShow,
			slidesToScroll: 1,
			slidesPerRow,
			swipeToSlide: true,
			arrows: true,
			variableWidth: true,
			prevArrow: <PrevArrow show={sliderIndex > 1} />,
			nextArrow: <NextArrow show={sliderIndex < count - hideFrom} />,
			initialSlide: sliderIndex,
			afterChange: index => {
				setSliderIndex(index + 1);
				index + 1 === count - hideFrom && onEndScroll && onEndScroll();
			}
		};
	}, [speed, slidesToShow, slidesPerRow, sliderIndex, count, hideFrom, onEndScroll]);

	return (
		<SliderWrapper slickPadding={slickPadding} itemSize={itemSize}>
			<Slider ref={sliderRef} {...settings}>
				{children}
			</Slider>
		</SliderWrapper>
	);
};

export default SliderWrap;
