import React, { useCallback, useState } from "react";

import { CircularProgress } from "@material-ui/core";
import styled from "styled-components";

import { SubmitButton } from "shared/Components";
import useConfirmLeavePage from "shared/Components/ConfirmLeave/hooks/useConfirmLeavePage";
import { useCommunity } from "shared/hooks";

import { useS3Uploader } from "shared/services/s3Uploader/hooks";
import { Box, Icon, Text, Uploader, orientationConst } from "shared/ui-kit";

import Bullet from "../Bullet";

const Wrapper = styled(Box)`
	display: flex;
	flex-direction: row;
	flex: 1;
	margin: 30px 10px 0 0;
	align-items: flex-end;
	justify-content: space-between;

	${props => props.theme.breakpoints.down("sm")} {
		flex-direction: column;
		width: 100%;
	}
`;
const Subtitle = styled(Text)`
	margin-top: 15px;
	margin-bottom: 15px;
`;

const CommunityLogo = () => {
	const { updateWorkspaceMeta, getData: getCommunityData } = useCommunity();
	const { workspace } = getCommunityData();

	const [image, setImage] = useState<Partial<File>>();
	const { uploadFile } = useS3Uploader();
	const [saving, setSaving] = useState(false);
	const { provideHandleCloseValues } = useConfirmLeavePage({});

	const onSave = useCallback(async () => {
		if (image) {
			setSaving(true);
			const data = await uploadFile({
				file: image as File,
				communityName: workspace?.meta?.displayName
			});
			if (data?.publicUrl) {
				await updateWorkspaceMeta({
					meta: {
						img: data?.publicUrl
					}
				});
			}
			setSaving(false);
		}
	}, [image, updateWorkspaceMeta, uploadFile, workspace]);

	const handleImage = (files: Array<Partial<File>>) => {
		provideHandleCloseValues(true);
		setImage(files[0]);
	};

	return (
		<>
			<Text>
				Uploading a community logo will help your community members identify your community quickly and allow you to
				build more awareness around your community.
			</Text>
			<Subtitle variant={"subtitle1"}>Some tips on choosing a good icon:</Subtitle>
			<Bullet text={"Use a solid background color."} />
			<Bullet text={"Use a graphical logo or image rather than text."} />
			<Bullet text={"Leave some space around your icon."} />
			<Bullet text={"Upload an image that is 200px square or larger."} />
			<Wrapper>
				<Uploader
					urls={workspace?.meta?.img ? [workspace.meta.img] : []}
					description={
						<>
							Drag and drop file here or <span className="anchor">browse</span> to choose a file
						</>
					}
					icon={<Icon group={""} fill={"#c5cee0"} name={"cloud-upload"} width={64} height={50} viewBox={"4 2 18 19"} />}
					accept={[
						{
							fileType: "image/png, image/jpeg, image/x-eps",
							name: "png, jpg, eps"
						}
					]}
					label={"Upload Logo"}
					onChange={handleImage}
					orientation={window.outerWidth < 550 ? orientationConst.vertical : orientationConst.horizontal}
					width={window.outerWidth < 550 ? window.outerWidth * 0.65 : undefined}
					closeIconId="deleteCommunityLogo"
					uploaderId="uploadCommunityLogo"
				/>
				<SubmitButton
					disabled={!image || saving}
					leftIcon={saving ? <CircularProgress size={20} /> : null}
					onClick={onSave}
					id="saveChangesCommunityLogo"
				>
					Save changes
				</SubmitButton>
			</Wrapper>
		</>
	);
};

export default CommunityLogo;
