import React, { useEffect, useState } from "react";

import CircularProgress, { CircularProgressProps } from "@material-ui/core/CircularProgress";
import styled from "styled-components";

import { IExtendedTheme } from "../../themes/default";

type VariantType = "determinate" | "static" | "indeterminate";

export type LoaderProps = CircularProgressProps & {
	show: boolean;
	theme: IExtendedTheme;
	value?: number;
	variant?: VariantType;
};

export default styled(
	({
		show,
		variant: defaultVariant = "determinate",
		value,
		className,
		...rest
	}: Readonly<LoaderProps>): JSX.Element => {
		const [progress, setProgress] = useState(value || 0);
		useEffect(() => {
			const tick = () => {
				// reset when reaching 100%
				setProgress(oldProgress => (oldProgress >= 100 ? 0 : oldProgress + 1));
			};
			const timer = setInterval(tick, 20);
			return () => clearInterval(timer);
		}, []);

		return (
			<div className={className}>
				{show ? <CircularProgress variant={defaultVariant} value={progress} {...rest} /> : null}
			</div>
		);
	}
)`
	& .MuiCircularProgress-colorPrimary {
		color: ${props => props.theme.palette.labelColorPrimary};
	}

	& .MuiCircularProgress-colorSecondary {
		color: ${props => props.theme.palette.labelColorSecondary};
	}
`;
