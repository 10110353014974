import styled, { css } from "styled-components";

import { Box, Button } from "shared/ui-kit";

const rowItemHeight = 128;

export const Wrapper = styled(Box)`
	display: flex;
	flex-direction: row;

	width: 100%;

	background: white;

	border-radius: 12px;
	border: 1px solid rgba(197, 206, 224, 0.5);

	box-shadow: 0 1px 1px 0 rgb(0 0 0 / 8%);

	padding: 16px 0 16px 16px;
`;

export const JoinBtn = styled(Button)<{ disabled?: boolean }>`
	width: 72px;
	height: 32px;
	margin: 0;
	border-radius: 8px;
	color: white;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);

	${({ disabled }) =>
		!disabled &&
		css`
			background-color: #6173fe;
		`}
`;

export const StartBox = styled(Box)`
	width: 88px;
	height: ${rowItemHeight}px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-direction: column;
	border: 1px solid #c5cee0;
	border-radius: 12px;
	padding: 8px;
	margin-right: 8px;
`;

export const ConvoBox = styled(Box)<{ imgUrl: string }>`
	width: 240px;
	height: ${rowItemHeight}px;
	border: 1px solid #c5cee0;
	border-radius: 12px;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	background-color: #edf1f7;
	background-image: ${({ imgUrl }) => (imgUrl ? `url(${imgUrl})` : "none")};
	color: white;
	overflow: hidden;
	position: relative;
`;

ConvoBox.Content = styled(Box)`
	display: flex;
	background-color: rgba(0, 0, 0, 0.5);
	flex-direction: column;
	justify-content: space-between;
	padding: 8px;
	flex: 1;
	height: 100%;
	position: relative;
`;

ConvoBox.MemberIcon = styled(Box)<{ bgUrl?: string }>`
	width: 28px;
	height: 28px;
	border: 2px solid white;
	border-radius: 50%;
	overflow: hidden;
	background-color: grey;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	background-image: ${({ bgUrl }) => (bgUrl ? `url(${bgUrl})` : "none")};

	&:not(:first-child) {
		margin-left: -6px;
	}
`;

ConvoBox.Title = styled(Box)`
	margin-left: 8px;
	margin-right: 8px;
`;

ConvoBox.Members = styled(Box)`
	display: flex;
	flex-direction: row;
	align-items: center;
`;

ConvoBox.ActionBar = styled(Box)`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
`;

ConvoBox.ParticipantsCounter = styled(Box)`
	display: flex;
	flex-direction: row;
	align-items: center;
`;

ConvoBox.Placeholder = styled(Box)`
	position: absolute;
	z-index: 0;
	left: 0;
	right: 0;
	bottom: 0;
	top: 0;
`;
