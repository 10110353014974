import { useMemo } from "react";

import { DefaultResponseType } from "types";

import useHttpActions from "shared/services/api/core/useHttpActions";

import BaseConverter from "shared/services/converters/baseConverter";

import { API_URLS } from "../../constants";
import { FaqItemType } from "../types";

interface useFaqServiceType {
	getFaqItems: (keyword?: string) => Promise<{
		faqs: Array<FaqItemType>;
	}>;
	createFaq: (data: { title: string; description: string; order?: number }) => Promise<{
		title: string;
		description: string;
	}>;
	updateFaq: (data: {
		id: string;
		title: string;
		description: string;
		order: number | null;
		targetOrder: number | null;
	}) => Promise<{
		title: string;
		description: string;
	}>;
	reorderFaq: (data: {
		id?: string;
		title: string;
		description: string;
		order: number;
		targetOrder: number;
	}) => Promise<{
		title: string;
		description: string;
	}>;
	deleteFaq: (id: string) => Promise<DefaultResponseType>;
}

const useFaqService = (): useFaqServiceType => {
	const actions = useHttpActions();
	return useMemo(
		() => ({
			getFaqItems: async keyword => {
				return BaseConverter.convert(await actions.get(API_URLS.GET_FAQ_ITEMS, { offset: 1, limit: 250, keyword }));
			},
			createFaq: async data => {
				return BaseConverter.convert(await actions.post(API_URLS.POST_FAQ_ITEM, data));
			},
			updateFaq: async data => {
				return BaseConverter.convert(await actions.put(API_URLS.UPDATE_FAQ_ITEM, data));
			},
			reorderFaq: async data => {
				return BaseConverter.convert(await actions.put(API_URLS.UPDATE_FAQ_ITEM, data));
			},
			deleteFaq: async id => {
				return BaseConverter.convert(await actions.delete(API_URLS.DELETE_FAQ_ITEM(id), {}));
			}
		}),
		[actions]
	);
};

export default useFaqService;
