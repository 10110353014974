import React, { FC, useCallback, useEffect, useMemo, useState } from "react";

import config from "config/appConfig";
import { ForumPersonaModel } from "types";

import { GroupHeader } from "modules/MemberHome/View/Components/GroupDetails";
import { HeaderLoader, Sections } from "modules/MemberHome/View/shared";
import { StyledWrapper } from "modules/MemberHome/View/shared/style";

import { NotFound } from "shared/Errorpages";
import { useFeature, useGroup, useSeries } from "shared/hooks";
import { GroupMembersType, GroupModel, MemberStatus } from "shared/types/GroupModel";

import { UsersListDialog } from "../../Components";
import { InviteToGroup, NewSubGroup } from "../../Components/GroupDetails/components";
import {
	About,
	Discussions,
	Events,
	Files,
	Series,
	SubGroups,
	Tracks,
	Videos
} from "../../Components/GroupDetails/sections";

enum GroupSectionsEnum {
	About = "About",
	Discussions = "Discussions",
	SubGroups = "Sub-groups",
	GroupEvents = "Group Events",
	Series = "Series",
	Videos = "Videos",
	Tracks = "Tracks",
	Files = "Files"
}

const GroupDetails: FC<{ id: string; routes: any }> = ({ id, routes }) => {
	const [subGroups, setSubGroups] = useState<GroupModel[]>([]);

	const { getGroupFromListing, getGroupAdmins, getData: getGroupData } = useGroup();
	const { group, loadingGroup } = getGroupData();

	const { isCommunityFeatureEnabled } = useFeature();

	const { isUserCouldManageSeries } = useSeries();

	const [admins, setAdmins] = useState<GroupMembersType>({
		loading: true,
		data: []
	});
	const [members, setMembers] = useState<GroupMembersType>({
		loading: true,
		data: []
	});
	const [current, setCurrent] = useState("About");
	const [groupPopup, setGroupPopup] = useState<{ open: boolean; group?: GroupModel }>({ open: false });
	const [invitePeople, setInvitePeople] = useState<{ open: boolean; group?: GroupModel }>({ open: false });
	const [membersDialog, setMembersDialog] = useState<{
		open: boolean;
		admins: boolean;
		list: ForumPersonaModel[];
	}>({
		open: false,
		admins: false,
		list: []
	});

	const isSubgroupEnabled = useMemo(() => isCommunityFeatureEnabled("enableSubGroups"), [isCommunityFeatureEnabled]);

	const sections = useMemo(() => {
		const BASE_SECTIONS = [
			{
				name: "About",
				visible: true
			},
			{
				name: "Discussions",
				visible: !!group?.postId
			},
			{
				name: "Sub-groups",
				visible: !group?.parentId && isSubgroupEnabled
			},
			{ name: "Group Events", visible: isCommunityFeatureEnabled("enableEvents") },
			{ name: "Series", visible: isCommunityFeatureEnabled("enableCollections") },
			{ name: "Videos", visible: isCommunityFeatureEnabled("enableVideos") },
			{ name: "Tracks", visible: isCommunityFeatureEnabled("enableAudioTracks") },
			{ name: "Files", visible: isCommunityFeatureEnabled("enableFiles") }
		];

		return BASE_SECTIONS.filter(section => section.visible).map(section => section.name);
	}, [group, isSubgroupEnabled, isCommunityFeatureEnabled]);

	const handleSwitchSection = useCallback((section: string) => {
		setCurrent(section);
	}, []);

	const isPrivateClosedGroup = useMemo(() => group?.private === 1 && !group?.roles?.isMember, [group]);
	const isPrivateGroup = useMemo(() => group?.private === 1, [group?.private]);
	const isMemberOrOwner = useMemo(() => group?.roles?.isMember || group?.roles?.isOwner, [group]);

	useEffect(() => {
		if (isMemberOrOwner && group?.postId) {
			setCurrent("Discussions");
		} else {
			setCurrent("About");
		}
	}, [group, isMemberOrOwner]);

	const currentSection = useMemo(() => {
		switch (current) {
			case GroupSectionsEnum.About:
				return (
					<About
						setMembersDialog={setMembersDialog}
						isPrivateClosedGroup={isPrivateClosedGroup}
						admins={admins}
						members={members}
					/>
				);
			case GroupSectionsEnum.Discussions:
				return <Discussions routes={routes} totalPosts={group?.totalPosts} slug={id} />;
			case GroupSectionsEnum.SubGroups:
				return (
					<SubGroups
						canCreate={isMemberOrOwner && (group?.totalSubGroups || 0) < config.GLOBAL_CONSTANTS.SUBGROUPS_LIMIT_COUNT}
						totalSubGroups={group?.totalSubGroups}
						groupId={group?._id}
						handleActionButton={() => setGroupPopup({ open: true })}
						subGroups={subGroups}
						setSubGroups={setSubGroups}
					/>
				);
			case GroupSectionsEnum.GroupEvents:
				return <Events canCreate={isMemberOrOwner} id={group?._id || ""} />;
			case GroupSectionsEnum.Series:
				return <Series canCreate={isMemberOrOwner && isUserCouldManageSeries({})} id={group?._id || ""} />;
			case GroupSectionsEnum.Videos:
				return <Videos canCreate={isMemberOrOwner} id={group?._id || ""} />;
			case GroupSectionsEnum.Tracks:
				return <Tracks canCreate={isMemberOrOwner} id={group?._id || ""} />;
			case GroupSectionsEnum.Files:
				return <Files canCreate={isMemberOrOwner} id={group?._id || ""} />;
			default:
				return null;
		}
	}, [
		current,
		isPrivateClosedGroup,
		admins,
		members,
		routes,
		group,
		id,
		isMemberOrOwner,
		isUserCouldManageSeries,
		subGroups,
		setSubGroups
	]);

	useEffect(() => {
		getGroupFromListing(id);

		return () => {
			setCurrent("About");
		};
	}, [getGroupFromListing, id]);

	useEffect(() => {
		getGroupAdmins({ slug: id, status: MemberStatus.admins }).then(admins => {
			setAdmins({
				loading: false,
				data: admins.users
			});
		});

		getGroupAdmins({ slug: id, status: MemberStatus.members }).then(members => {
			setMembers({
				loading: false,
				data: members.users
			});
		});
	}, [getGroupAdmins, id]);

	return !group || loadingGroup ? (
		<>
			<StyledWrapper>
				<HeaderLoader />
				<Sections sections={[]} loading current={current} handleSwitchSection={handleSwitchSection} />
			</StyledWrapper>
		</>
	) : !!group.parentId && !isSubgroupEnabled ? (
		<NotFound />
	) : (
		<>
			<StyledWrapper>
				<GroupHeader
					id={id}
					members={members.data}
					isPrivateClosedGroup={isPrivateClosedGroup}
					isPrivateGroup={isPrivateGroup}
					groupChatId={group?.groupChatId}
					handleInvite={() =>
						setInvitePeople({
							open: true,
							group: group!
						})
					}
					handleEditGroup={() => setGroupPopup({ open: true, group: group! })}
				/>

				{!isPrivateClosedGroup && (
					<Sections sections={sections} current={current} handleSwitchSection={handleSwitchSection} />
				)}
				<StyledWrapper.Content mt={isPrivateClosedGroup}>{currentSection}</StyledWrapper.Content>
			</StyledWrapper>
			{groupPopup.open && (
				<NewSubGroup
					groupDetails={groupPopup.group}
					group={group}
					handleClose={() => setGroupPopup({ open: false })}
					subGroups={subGroups}
					setSubGroups={setSubGroups}
				/>
			)}
			{invitePeople.open && group && (
				<InviteToGroup group={group!} handleClose={() => setInvitePeople({ open: false })} />
			)}
			{membersDialog.open && (
				<UsersListDialog
					title={`${group?.name}'s ${membersDialog.admins ? "Admins" : "Members"}`}
					handleClose={() =>
						setMembersDialog({
							open: false,
							admins: false,
							list: []
						})
					}
					list={membersDialog.list}
				/>
			)}
		</>
	);
};

export default GroupDetails;
