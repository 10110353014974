import React from "react";

import { useMembers } from "shared/hooks";
import { Text } from "shared/ui-kit";

import { StyledButton, StyledForm, StyledFormFooter } from "../style";

const MemberActions = () => {
	const { getMembers, setModalState, getData: getMembersData } = useMembers();
	const {
		apiRequest,
		apiRequestParams,
		actionConfirmMessage,
		page,
		memberShowPerPage,
		statusFilter,
		modalFooterActionsText,
		successCallback
	} = getMembersData();

	return (
		<StyledForm onSubmit={e => e.preventDefault()}>
			<Text align="center" display="initial" variant="h5">
				{actionConfirmMessage}
			</Text>
			{modalFooterActionsText && (
				<StyledFormFooter textAlign="right">
					<StyledButton
						buttonTheme="outline"
						type="submit"
						palette="control"
						size="large"
						transparent
						onClick={() => {
							setModalState({ isOpen: false });
						}}
					>
						{modalFooterActionsText?.cancelText}
					</StyledButton>
					<StyledButton
						buttonTheme="main"
						type="submit"
						palette="primary"
						size="large"
						onClick={async () => {
							setModalState({ isOpen: false });
							await apiRequest(apiRequestParams);
							successCallback && successCallback();
							getMembers({
								offset: page,
								limit: memberShowPerPage,
								type: "members",
								keyword: null,
								filterBy: statusFilter
							});
						}}
					>
						{modalFooterActionsText?.submitText}
					</StyledButton>
				</StyledFormFooter>
			)}
		</StyledForm>
	);
};

export default MemberActions;
