import { Box, ButtonBase } from "@material-ui/core";
import styled from "styled-components";

import { Button } from "shared/ui-kit";

export const Container = styled(Box)`
	max-width: 960px;
	margin: 32px 16px 120px;
	${props => props.theme.breakpoints.up("md")} {
		margin: 32px auto 120px;
	}
`;

Container.SliderHeader = styled(Box)`
	display: flex;
	align-items: center;
	justify-content: space-between;
	button {
		width: 100px;
		height: 40px;
		padding: 12px 0;
		border-radius: 8px;
		background-color: #edf1f7;
		font-size: 14px;
		font-weight: bold;
		line-height: 1.14;
		color: #222b45;
	}
`;

Container.SeeAllButton = styled(Button)`
	border-radius: 8px;
	background-color: #edf1f7;
	color: #222b45;
`;

Container.SliderWrapper = styled(Box)`
	margin-top: 24px;
	.arrows {
		top: 74px;
		backdrop-filter: blur(20px);
		background-color: rgba(0, 0, 0, 0.24);
		&.arrow-prev {
			left: -16px;
		}
		&.arrow-next {
			right: -16px;
		}
	}
`;

export const RecommendedAlbumWrapper = styled(ButtonBase)`
	display: block;
	text-align: left;
	img {
		width: 266px;
		height: 164px;
		object-fit: cover;
	}
	img,
	svg {
		border-radius: 12px;
		margin-bottom: 16px;
	}
	.genre {
		color: #8f9bb3;
	}
`;
