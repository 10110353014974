import React, { Fragment, ReactNode, useCallback, useMemo, useState } from "react";

import { DateTime } from "luxon";
import styled from "styled-components";

import { ReactComponent as CalendarIcon } from "assets/icons/calendar.svg";
import { ReactComponent as ConnectionIcon } from "assets/icons/connection.svg";
import { ReactComponent as EyeIcon } from "assets/icons/eye.svg";
import { ReactComponent as ProfileIcon } from "assets/icons/profile.svg";
import { ReactComponent as SlashIcon } from "assets/icons/slash.svg";
import { ReactComponent as TimeIcon } from "assets/icons/timeIcon.svg";

import SmartScroll from "shared/Components/SmartScroll";
import { useAnalytics } from "shared/hooks";
import { Box, Text } from "shared/ui-kit";

import BlockHeader from "../BlockHeader";

const Wrapper = styled(Box)`
	display: flex;
	flex-direction: column;

	width: 100%;
`;

const StatItem = styled(Box)`
	display: flex;
	justify-content: space-between;

	padding: 18px 24px 19px 24px;

	&:not(:last-child) {
		border-bottom: solid 0.5px #c5cee0;
	}
`;

const StatItemInfo = styled(Box)`
	display: flex;
	align-items: center;
`;

const StatItemIconWrapper = styled(Box)<{ bg: string }>`
	width: 32px;
	height: 32px;

	border-radius: 4px;
	background-color: ${({ bg }) => bg};

	margin-right: 16px;

	display: flex;
	justify-content: center;
	align-items: center;
`;

const StatItemLabel = styled(Text)`
	font-size: 13px;
	font-weight: 600;
	line-height: 1.85;
	color: #222b45;
`;

const StatItemValueWrapper = styled(Box)`
	display: flex;
	justify-content: flex-end;
`;

const StatItemValue = styled(Text)`
	font-size: 13px;
	font-weight: 600;
	line-height: 1.85;
	text-align: right;
	color: #222b45;
`;

interface IStat {
	label: string;
	key: string;
	key2?: string;
	color?: string;
	iconBgColor: string;
	icon: ReactNode;
}

const SummaryBlock: React.FC = () => {
	const { getSummary, getSummaryCSVFile, getData: getAnalyticsData } = useAnalytics();
	const { summary } = getAnalyticsData();

	const [stats] = useState<IStat[]>([
		{
			label: "Became vyooable",
			key: "vyooable",
			color: "#6173fe",
			icon: <EyeIcon />,
			iconBgColor: "#dfe3ff"
		},
		{
			label: "Profile view",
			key: "profileViews",
			color: "#f87e51",
			icon: <ProfileIcon />,
			iconBgColor: "#ffe3d8"
		},
		{
			label: "Connection requests sent / accepted",
			key: "connectionRequestsSent",
			key2: "acceptedConnectionRequests",
			color: "#6173fe",
			icon: <ConnectionIcon />,
			iconBgColor: "#dfe3ff"
		},
		{
			label: "Connections extended",
			key: "connectionsExtended",
			icon: <TimeIcon />,
			iconBgColor: "#e3fcce"
		},
		// {
		// 	label: "Events shared",
		// 	key: "eventShared",
		// 	color: "#ffc023",
		// 	icon: <SendIcon />,
		// 	iconBgColor: "#fff5dc"
		// },
		{
			label: "Events interested / going",
			key: "eventInterested",
			key2: "eventGoing",
			icon: <CalendarIcon />,
			iconBgColor: "#dfe3ff"
		},
		{
			label: "Events reported",
			key: "eventReported",
			icon: <SlashIcon />,
			iconBgColor: "#ffe3d8"
		},
		{
			label: "Users blocked",
			key: "userBlocked",
			icon: <SlashIcon />,
			iconBgColor: "#ffe3d8"
		}
	]);

	const changePeriod = useCallback(
		(requestedFrom: DateTime, requestedTo: DateTime) => {
			getSummary(requestedFrom, requestedTo);
		},
		[getSummary]
	);

	const Body = useMemo(
		() => (
			<>
				{stats.map((stat, index) => (
					<StatItem key={index}>
						<StatItemInfo>
							<StatItemIconWrapper bg={stat.iconBgColor}>{stat.icon}</StatItemIconWrapper>
							<StatItemLabel>{stat.label}</StatItemLabel>
						</StatItemInfo>
						<StatItemValueWrapper>
							<StatItemValue>
								{summary.monthlySummaryAverage?.[stat.key]}
								{stat.key2 && <Fragment> / {summary.monthlySummaryAverage?.[stat.key2]}</Fragment>}
							</StatItemValue>
						</StatItemValueWrapper>
					</StatItem>
				))}
			</>
		),
		[stats, summary.monthlySummaryAverage]
	);

	return (
		<Wrapper>
			<BlockHeader
				title={"Other Metrics"}
				showDropdown
				onChangePeriod={changePeriod}
				onDownloadCsv={getSummaryCSVFile}
				csvFilenamePrefix="other_metrics_report"
			/>
			<SmartScroll height="350px">{Body}</SmartScroll>
		</Wrapper>
	);
};

export default SummaryBlock;
