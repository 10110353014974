import React, { useEffect, useState } from "react";

import { useLocation } from "react-router-dom";

import { routes as adminRoutes } from "apps/RegularUser/constants";
import { view } from "modules/App";

import { useAuth } from "modules/App/Data";
import { useCommunity, useMemberRoutes, useRoutes, useUser } from "shared/hooks";

import { VARS, routes } from "../../constants";

const SignInPage = () => {
	const [initialised, setInitialised] = useState(false);

	const { getMemberRoutesData } = useMemberRoutes();
	const { routes: memberRoutes } = getMemberRoutesData();

	const { clearStorage } = useRoutes();
	const { resetState } = useUser();
	const { clearState } = useAuth();

	const { setSportsMode } = useCommunity();

	const { search } = useLocation();
	const query = new URLSearchParams(decodeURIComponent(search.replace(/\+/g, "%2B")));
	const isSportsMode = query.get("sportsmode");
	const redirectUrl = query.get("redirectUrl");

	useEffect(() => {
		if (!initialised) {
			clearStorage();
			clearState();
			resetState();
			setInitialised(true);
			setSportsMode(isSportsMode !== null);
		}
	}, [clearStorage, clearState, resetState, initialised, isSportsMode, setSportsMode]);

	return (
		<view.SignIn
			TERMS_AND_CONDITION={VARS.TERMS_AND_CONDITIONS_LINK}
			PRIVACY_POLICY_LINK={VARS.PRIVACY_POLICY_LINK}
			COOKIE_USE_LINK={VARS.COOKIE_USE_LINK}
			forgotPassword={routes.auth.forgotPassword.getPath()}
			communityListRoute={routes.auth.communities.getPath()}
			memberHomePage={memberRoutes?.member.home.getPath()}
			memberSignInPage={adminRoutes.user.signIn.getPath()}
			memberFillProfilePage={memberRoutes?.member.fillProfile.getPath()}
			invitationCodePage={routes.auth.invitationCode.getPath()}
			joinCommunitiesUrl={adminRoutes.communities.getPath()}
			redirectUrl={redirectUrl}
		/>
	);
};

export default SignInPage;
