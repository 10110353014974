import { AxiosResponse } from "axios";
import * as R from "ramda";

import { WeblinkPreview, WeblinkPreviewResponse } from "shared/types";

import { BaseConverter } from "./index";

export const convertWeblinkPreview = (data: AxiosResponse<WeblinkPreviewResponse>): WeblinkPreview => {
	if (data.status !== 200) return {} as WeblinkPreview;

	const linkData = BaseConverter.convert(data).data;

	return {
		...R.pick(["url", "title", "description", "favicons"], linkData),
		cover: linkData?.images.length ? linkData.images[0] : null
	};
};
