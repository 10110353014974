export const MODULE_SLUG = "Auth";
export const MODULE_NAME = "Authentication";

export const API_URLS = {
	LOGIN: {
		GET_USER: "/user/me",
		POST_LOGOUT: "/user/logout",
		GET_VERIFICATION_LINK: "/globaluser/auth",
		VERIFY_LINK: "/globaluser/verify",
		JOIN: "/globaluser/community/join"
	},
	EMAIL: {
		EMAIL_VERIFIED: (globalUserId: string): string => `/globaluser/email/verified/${globalUserId}`,
		RESEND_VERIFICATION: (globalUserId: string): string => `/globaluser/email/resend/${globalUserId}`,
		RESEND_WHITELABEL_VERIFICATION: (whitelabelUserId: string): string => `/auth/email/resend/${whitelabelUserId}`,
		REFRESH_TOKEN: "/auth/refreshToken"
	},
	PASSWORD: {
		UPDATE_PASSWORD_V2: "/globaluser/v2/password",
		FORGOT_PASSWORD: "globaluser/forgotpassword",
		RESET_PASSWORD: "/globaluser/resetpassword"
	},
	WHITELABEL_USER: {
		FORGOT_PASSWORD: "/user/forgotpassword",
		UPDATE_PASSWORD_V2: "/auth/v2/password",
		GET_VERIFICATION_LINK: "/auth/account/email",
		EMAIL_VERIFY: "/auth/account/email/verify",
		RESET_PASSWORD: "/user/resetpassword",
		UPDATE_EMAIL: (globalUserId: string): string => `/globaluser/email/${globalUserId}`
	},
	TOKEN: {
		VERIFY_ONBOARDING_TOKEN: "/globaluser/invitation/confirm",
		VERIFY_INVITATION_TOKEN: "/invitation/validate"
	},
	WORKSPACES: "/communities",
	PHONE: {
		REQUEST: "/auth/phone/request",
		VERIFY: "/auth/phone/verify"
	}
};

export const LOGIN_STEPS = {
	EMAIL: 1,
	CODE: 2
};
