import React, { FC, useCallback, useEffect, useMemo, useState } from "react";

import DateFnsAdapter from "@date-io/date-fns";

import { ButtonBase } from "@material-ui/core";
import YouTube from "react-youtube";

import { NavbarWrapper } from "apps/RegularUser/Pages/TracksHelp/style";
import { ReactComponent as ShareIcon } from "assets/icons/fundraiser-share.svg";
import { ReactComponent as PlayIcon } from "assets/icons/play-circle-icon.svg";
import { ReactComponent as VyooLogo } from "assets/icons/vyoo-logo.svg";
import {
	ChangeMemberDialog,
	FundraiserDonationDialog,
	FundraiserSuccessfulDialog,
	PageWrapper
} from "modules/MemberHome/View/Components";
import { Leaderboard } from "modules/MemberHome/View/Components/FundraiserDetails/sections";
import { DonationCard } from "modules/MemberHome/View/Components/FundraiserDonationDialog/style";
import MemberNotification from "modules/MemberHome/View/shared/MemberNotification";
import { Headline, StyledAbout, StyledWrapper } from "modules/MemberHome/View/shared/style";
import {
	FullScreenVideoDialog,
	FundraiserErrorHeader,
	Members,
	MenuDots,
	PlaceholderImage,
	ShareModal,
	SkeletonBase
} from "shared/Components";
import { getVideoId } from "shared/Components/Youtube/AddYoutube";
import { useCommunity, useFundraisers, useMarketing, useNotification } from "shared/hooks";
import { PlaceholderImageType, ProfilePhotoSizes } from "shared/types";
import { FundraiserStatusEnum } from "shared/types/FundraiserModel";
import { Button, Icon, ReadMore, Text } from "shared/ui-kit";
import { formatMoney } from "utils/formatMoney";
import { getFileFromVideoUrl } from "utils/getFileFromVideoUrl";
import { getVideoImage } from "utils/getVideoImage";
import { getResizedImage } from "utils/serviceUtils/cdnImages";

import { validateYoutube } from "utils/serviceUtils/validators";

import { FundraiserItemsHeader, ProgressBar, YoutubeWrapper } from "./style";

import { FundraiserAboutLoader, FundraiserItemsHeaderLoader } from "../Components";

const dateFns = new DateFnsAdapter();

const FundraiserPage: FC<{
	id: string;
	workspace: string | null;
	contactId?: string | null;
}> = ({ id, workspace, contactId }) => {
	const [coverImg, setCoverImg] = useState<string>("");
	const [shareModal, setShareModal] = useState(false);
	const [checkoutEnabled, setCheckoutEnabled] = useState(false);

	const { communityColors } = useCommunity();
	const { getWorkspaceByName } = useMarketing();
	const {
		getData: getFundraisersData,
		getFundraiser,
		getContactInfo,
		setDonationDialog,
		setFullScreenVideoDialog,
		setSelectMemberDialog,
		pauseFundraiser,
		setOnBehalfOf
	} = useFundraisers();

	const {
		fundraiser,
		onBehalfOf,
		donationDialog,
		donateSuccessfulDialog,
		fullScreenVideoDialog,
		selectMemberDialog,
		loading
	} = getFundraisersData();

	const { showMessage, getData: getNotificationData } = useNotification();
	const { message, isError } = getNotificationData();

	useEffect(() => {
		if (id) getFundraiser(id, workspace || undefined);
	}, [id, getFundraiser, workspace]);

	useEffect(() => {
		if (contactId) getContactInfo(contactId, workspace || undefined);
		return () => {
			setOnBehalfOf();
		};
	}, [contactId, getContactInfo, setOnBehalfOf, workspace]);

	const hasVideoUrl = useMemo(() => !!fundraiser?.videoUrl, [fundraiser?.videoUrl]);
	const isYoutubeUrl = useMemo(
		() => hasVideoUrl && validateYoutube(fundraiser?.videoUrl),
		[hasVideoUrl, fundraiser?.videoUrl]
	);
	const hasThumbnailUrl = useMemo(() => !!fundraiser?.thumbnail?.url, [fundraiser?.thumbnail?.url]);

	const getVideoCover = useCallback(async (url: string) => {
		const file = await getFileFromVideoUrl(url);
		await getVideoImage(file, 2)
			.then(data => URL.createObjectURL(data))
			.then(url => setCoverImg(url))
			.catch(e => console.error({ e }));
	}, []);

	useEffect(() => {
		if (!!fundraiser?.videoUrl && !hasThumbnailUrl && hasVideoUrl && !isYoutubeUrl) getVideoCover(fundraiser?.videoUrl);
	}, [fundraiser?.videoUrl, hasThumbnailUrl, hasVideoUrl, isYoutubeUrl, getVideoCover]);

	const setupWorkspace = useCallback(async () => {
		const workspaceInfo = await getWorkspaceByName(workspace!);
		const feature = workspaceInfo?.features.find(f => f.name == "enableStripeCheckout");

		if (feature) {
			setCheckoutEnabled(feature.isEnabled);
		}
	}, [getWorkspaceByName, workspace, setCheckoutEnabled]);

	useEffect(() => {
		setupWorkspace();
	}, [setupWorkspace]);

	const renderPlayIcon = useMemo(
		() => (
			<StyledWrapper.PlayIcon className="absolute z-20 top-0 left-0 w-full h-full flex items-center justify-center play-icon">
				<ButtonBase
					onClick={() =>
						setFullScreenVideoDialog({
							open: true,
							url: fundraiser?.videoUrl || ""
						})
					}
					className="p-0 rounded-full"
				>
					<PlayIcon width={80} height={80} />
				</ButtonBase>
			</StyledWrapper.PlayIcon>
		),
		[setFullScreenVideoDialog, fundraiser?.videoUrl]
	);

	const renderThumbnailCover = useMemo(
		() => <img src={fundraiser?.thumbnail?.url || ""} alt={fundraiser?.name || ""} className="main-img" />,
		[fundraiser?.thumbnail?.url, fundraiser?.name]
	);

	const renderYoutubePlayer = useMemo(
		() => (
			<YoutubeWrapper>
				<YouTube
					videoId={`${getVideoId(fundraiser?.videoUrl || "")}`}
					opts={{
						width: "100%",
						height: "100%",
						playerVars: {
							autoplay: 0
						}
					}}
				/>
			</YoutubeWrapper>
		),
		[fundraiser?.videoUrl]
	);

	const renderCoverImg = useMemo(() => {
		return coverImg ? (
			<img src={coverImg} alt={"cover-image"} className="w-full h-auto" />
		) : (
			<SkeletonBase variant="rect" animation="wave" width={"100%"} height={"100%"} />
		);
	}, [coverImg]);

	const renderPlaceholderCover = useMemo(
		() => (
			<PlaceholderImage
				wrapClassName="w-full h-full"
				className="w-full h-full"
				type={PlaceholderImageType.FUNDRAISER_DETAILS}
				width={"auto"}
				height={"auto"}
				viewBox={"0 0 990 405"}
			/>
		),
		[]
	);

	const renderCover = useMemo(() => {
		if (hasThumbnailUrl) {
			return hasVideoUrl ? (
				/* show play icon on thumbnail */
				<>
					{renderPlayIcon}
					{renderThumbnailCover}
				</>
			) : (
				/* show thumbnail */
				renderThumbnailCover
			);
		} else if (hasVideoUrl) {
			return isYoutubeUrl ? (
				/* show youtube player */
				renderYoutubePlayer
			) : (
				/* show play icon on video img */
				<>
					{renderPlayIcon}
					{renderCoverImg}
				</>
			);
		} else {
			/* show placeholder */
			return renderPlaceholderCover;
		}
	}, [
		hasThumbnailUrl,
		hasVideoUrl,
		renderPlayIcon,
		renderThumbnailCover,
		isYoutubeUrl,
		renderYoutubePlayer,
		renderCoverImg,
		renderPlaceholderCover
	]);

	// const RenderItem = useCallback(
	// 	(item: TopContrib) => (
	// 		<InfoItem
	// 			iconWrapperClassName="mr-2"
	// 			placeholderImg={<NoProfileIcon className="w-12 h-12" />}
	// 			avatarUrl={item.image || undefined}
	// 			title={item.name}
	// 			subtitle={formatMoney(item.amount)}
	// 		/>
	// 	),
	// 	[]
	// );

	const showMembers = useMemo(
		() =>
			fundraiser && !!fundraiser?.top?.length
				? fundraiser?.top.map(x => (x.image ? getResizedImage(x.image as string, ProfilePhotoSizes.size50x50) : null))
				: [],
		[fundraiser]
	);

	const handleDonateNow = useCallback(() => {
		if (onBehalfOf) {
			setDonationDialog({
				open: true,
				behalf: {
					member: {
						name: `${onBehalfOf.persona.firstName} ${onBehalfOf.persona.lastName}`,
						img: onBehalfOf.persona.profilePicture,
						personaId: onBehalfOf.persona.personaId
					},
					group: {
						img: onBehalfOf.group.coverUrl || "",
						name: onBehalfOf.group.name
					}
				}
			});
		} else {
			setDonationDialog(undefined);
			setSelectMemberDialog(true);
		}
	}, [onBehalfOf, setDonationDialog, setSelectMemberDialog]);

	const progress = useMemo(
		() => (fundraiser ? (fundraiser.totalDonated * 100) / fundraiser.goalAmount : 0),
		[fundraiser]
	);

	const isDonateDisabled = useMemo(
		() =>
			fundraiser?.status === FundraiserStatusEnum.ENDED.toUpperCase() ||
			fundraiser?.status === FundraiserStatusEnum.PAUSED.toUpperCase() ||
			fundraiser?.status === FundraiserStatusEnum.PENDING.toUpperCase() ||
			fundraiser?.status === FundraiserStatusEnum.ARCHIVED.toUpperCase(),
		[fundraiser?.status]
	);

	const handleActive = useCallback(async () => {
		await pauseFundraiser(id, "unpause");
		getFundraiser(id);
	}, [getFundraiser, id, pauseFundraiser]);

	const handleShare = useCallback(() => {
		if (navigator.share && fundraiser?.name) {
			navigator.share({
				title: fundraiser.name,
				url: document.location.href
			});
		} else {
			setShareModal(true);
		}
	}, [fundraiser?.name]);

	return (
		<>
			<div className="pb-6 sm:pb-0">
				<NavbarWrapper className="mb-4">
					<VyooLogo />
				</NavbarWrapper>
				<PageWrapper>
					<FundraiserErrorHeader status={fundraiser?.status} handleActive={handleActive} />
					<FundraiserItemsHeader className="mb-4 p-0 flex flex-col lg:flex-row overflow-hidden">
						{!fundraiser || loading ? (
							<FundraiserItemsHeaderLoader />
						) : (
							<>
								<div className="w-full relative lg:w-3/5">{renderCover}</div>
								<div className="w-full p-4 md:p-6 lg:w-2/5">
									<DonationCard className="p-0 m-0 noBorder noShadow">
										{onBehalfOf && (
											<div className="on-behalf-of relative p-3 flex items-center justify-between gap-4">
												<div className="flex items-center gap-4">
													{onBehalfOf.persona.profilePicture && (
														<img
															className="profile-rounded flex-shrink-0"
															src={onBehalfOf.persona.profilePicture}
															alt={onBehalfOf.persona.firstName}
														/>
													)}
													<Text className="support-title">
														Support <b>{`${onBehalfOf.persona.firstName} ${onBehalfOf.persona.lastName}'s`}</b> team
													</Text>
												</div>
												<MenuDots
													vertical
													memberView
													removeBg
													removeshadow
													options={[
														{
															icon: <Icon name="user-edit" group="filled" fill="#919db4" />,
															name: "Change member",
															onClick: () => setSelectMemberDialog(true)
														}
													]}
												/>
											</div>
										)}
										<div className="on-behalf-of mt-3 text-center p-4">
											<Text variant="body2" className="font-semibold">
												Total Raised
											</Text>
											<div className="flex items-end justify-center gap-1 mt-3 mb-2">
												<Text variant="h3" className="leading-7">
													{`${formatMoney(fundraiser.totalDonated)} `}
												</Text>
												<Text variant="body2" className="total">
													{`/ ${formatMoney(fundraiser.goalAmount)}`}
												</Text>
											</div>

											<ProgressBar
												className="w-full h-auto mt-5 mb-2 block"
												palette="info"
												isLineProgress
												size={fundraiser.goalAmount}
												value={progress > 100 ? 100 : progress}
											/>
										</div>
										<div className="flex gap-2">
											<div className="on-behalf-of flex-1 mt-3 p-4 flex flex-col justify-between">
												<Text variant="body2" className="font-semibold">
													Sponsors
												</Text>
												<Text variant="h5">{fundraiser.totalDonations}</Text>
											</div>
											<div className="on-behalf-of flex-1 mt-3 p-4 flex flex-col justify-between">
												<Text variant="body2" className="font-semibold">
													Avg donation amount
												</Text>
												<Text variant="h5">
													$
													{fundraiser.totalDonated > 0 && fundraiser.totalDonations > 0
														? (fundraiser.totalDonated / fundraiser.totalDonations).toFixed(2)
														: 0}
												</Text>
											</div>
											{/* <div className="on-behalf-of flex-1 mt-3 p-4">
												<Text variant="body2" className="font-semibold">
													Monthly subscribers
												</Text>
												<Text variant="h5" className="mt-1">
													0
												</Text>
											</div> */}
										</div>

										<div className="flex items-center w-full my-2">
											<Button disabled={isDonateDisabled} className="flex-1" onClick={handleDonateNow}>
												Donate Now
											</Button>
											<Button buttonTheme="light" onClick={handleShare} className="flex items-center">
												<ShareIcon />
												Share
											</Button>
										</div>
									</DonationCard>
								</div>
							</>
						)}
					</FundraiserItemsHeader>
					<div className="flex flex-col lg:flex-row gap-4 mb-4">
						<StyledAbout fillColor={communityColors.primary} className="flex flex-col flex-1">
							{!fundraiser || loading ? (
								<FundraiserAboutLoader />
							) : (
								<>
									<StyledWrapper.HeaderInfoRow>
										<StyledWrapper.TypeWrapper>
											<Icon name={"fundraiser"} width={18} heigth={18} group="color" fill="#8f9bb3" />
											<StyledWrapper.TypeText className="mt-1">Fundraiser</StyledWrapper.TypeText>
										</StyledWrapper.TypeWrapper>
									</StyledWrapper.HeaderInfoRow>
									<StyledWrapper.Title variant="h1">{fundraiser.name}</StyledWrapper.Title>
									<div className="details-row">
										<div className="details-item mt-1">
											<div className="icon">
												<Icon name="clock" group="filled" fill={communityColors.primary} />
											</div>
											<div>
												<Text variant="h5">
													From {dateFns.format(new Date(fundraiser.createdAt || ""), "LL/d/y" as any)} -{" "}
													{dateFns.format(new Date(fundraiser.endDate || ""), "LL/d/y" as any)}
												</Text>
											</div>
										</div>
									</div>
									<StyledWrapper.MembersBlock className="mb-2">
										<Members memberImgs={showMembers} total={fundraiser!.totalDonations} isFundraiser />
									</StyledWrapper.MembersBlock>
									<Headline variant="h3" className="mb-1">
										About Fundraiser
									</Headline>
									<ReadMore text={fundraiser.description} showCharCount={300} wrapClassName="" wrapTextClassName="" />
								</>
							)}
						</StyledAbout>
						{/*Hide this section as we have leaderboard now*/}
						{/*<div className="w-full sm:w-80">*/}
						{/*	{fundraiser && !loading ? (*/}
						{/*		<List*/}
						{/*			title={`Top ${fundraiser?.top?.length === 1 ? "Sponsor" : "Sponsors"}`}*/}
						{/*			items={!!fundraiser?.top?.length ? fundraiser.top.slice(0, 3) : []}*/}
						{/*			onSeeAllClick={fundraiser?.top?.length > 3 ? () => {} : undefined}*/}
						{/*			totalCount={10}*/}
						{/*			renderItem={RenderItem}*/}
						{/*			noContentBlock={<Text className="p-2">No top sponsors for this fundraiser yet.</Text>}*/}
						{/*			preview*/}
						{/*		/>*/}
						{/*	) : (*/}
						{/*		<FundraiserSponsorsListLoader />*/}
						{/*	)}*/}
						{/*</div>*/}
					</div>
					<Leaderboard workspace={workspace} nonRegisteredView />
				</PageWrapper>
			</div>
			{donationDialog?.open && (
				<FundraiserDonationDialog
					id={id}
					contactId={contactId || ""}
					workspace={workspace || undefined}
					useStripeCheckout={checkoutEnabled}
				/>
			)}
			{donateSuccessfulDialog?.open && <FundraiserSuccessfulDialog />}
			{fullScreenVideoDialog?.open && (
				<FullScreenVideoDialog url={fullScreenVideoDialog.url} handleClose={setFullScreenVideoDialog} autoplay />
			)}
			{selectMemberDialog && !donationDialog && (
				<ChangeMemberDialog updateOnBehalf goToDonation id={id} workspace={workspace || undefined} />
			)}
			<MemberNotification message={message} open={!!message} onClose={() => showMessage("")} isError={isError} />
			{shareModal && <ShareModal title="Share Fundraiser" onClose={() => setShareModal(false)} />}
		</>
	);
};

export default FundraiserPage;
