import React, { memo, useCallback, useEffect, useMemo } from "react";

import { DateTime } from "luxon";

import "dz-daterangepicker-material/dist/index.css";

import { PageTemplate } from "modules/Manage/View/Components";
import { useReports } from "modules/Payments/Data";

import { TableWrapper } from "shared/Components";
import { Cell } from "shared/Components/NewTable/style";

import { WithdrawRequestModel } from "shared/types";

const Table = React.lazy(() => import("shared/Components/NewTable"));

const Reports: React.FC = memo(() => {
	const { getReports, getReportsCount, setReportsShowPerPage, setPage, getData: getReportsData } = useReports();
	const { reports, page, reportShowPerPage, loading, totalCount } = getReportsData();

	useEffect(() => {
		getReports({
			offset: page,
			limit: reportShowPerPage
		});
		getReportsCount();
	}, [getReports, page, reportShowPerPage, getReportsCount]);

	const tableColumns = useMemo(
		() => [
			{
				alignment: "left",
				label: <Cell.HeaderText>Ref ID</Cell.HeaderText>,
				Cell: ({ rowData: { _id } }: { rowData: WithdrawRequestModel }) => (
					<Cell.Wrapper>
						<Cell.Text>{_id}</Cell.Text>
					</Cell.Wrapper>
				),
				dataKey: "cid"
			},
			{
				alignment: "left",
				width: 120,
				label: <Cell.HeaderText>Amount</Cell.HeaderText>,
				Cell: ({ rowData: { amount } }: { rowData: WithdrawRequestModel }) => (
					<Cell.Wrapper>
						<Cell.Text className="bold">${Number(amount.toFixed(4))}</Cell.Text>
					</Cell.Wrapper>
				),
				dataKey: "cid"
			},
			{
				alignment: "left",
				width: 110,
				label: <Cell.HeaderText>Status</Cell.HeaderText>,
				Cell: ({ rowData: { status } }: { rowData: WithdrawRequestModel }) => (
					<Cell.Wrapper>
						<Cell.Text className="capitalize">{status}</Cell.Text>
					</Cell.Wrapper>
				),
				dataKey: "cid"
			},
			{
				alignment: "left",
				width: 80,
				label: <Cell.HeaderText>Type</Cell.HeaderText>,
				Cell: ({ rowData: { processor } }: { rowData: WithdrawRequestModel }) => (
					<Cell.Wrapper>
						<Cell.Text className="capitalize">{processor}</Cell.Text>
					</Cell.Wrapper>
				),
				dataKey: "cid"
			},
			{
				alignment: "left",
				label: <Cell.HeaderText>Description</Cell.HeaderText>,
				Cell: ({ rowData: { description } }: { rowData: WithdrawRequestModel }) => (
					<Cell.Wrapper>
						<Cell.Text className="capitalize">{description || "-"}</Cell.Text>
					</Cell.Wrapper>
				),
				dataKey: "cid"
			},
			{
				alignment: "left",
				width: 150,
				label: <Cell.HeaderText>Date</Cell.HeaderText>,
				Cell: ({ rowData: { createdAt } }: { rowData: WithdrawRequestModel }) => (
					<Cell.Wrapper>
						<Cell.Text>{DateTime.fromISO(createdAt).toFormat("MMM d, yyyy")}</Cell.Text>
					</Cell.Wrapper>
				),
				dataKey: "cid"
			}
		],
		[]
	);

	const handleChangePage = useCallback(
		(newPage: number) => {
			setPage(newPage);
		},
		[setPage]
	);

	const handleChangePageSize = useCallback(
		(newPageSize: number) => {
			setReportsShowPerPage(newPageSize);
			setPage(1);
		},
		[setPage, setReportsShowPerPage]
	);

	const TableBlock = useMemo(
		() => (
			<TableWrapper sizes={{ horizontalTablet: 900 }}>
				<Table
					columns={tableColumns}
					data={reports || []}
					loading={loading}
					paginated
					totalDataCount={totalCount || 10}
					page={page}
					pageSize={reportShowPerPage}
					onChangePage={handleChangePage}
					onChangePageSize={handleChangePageSize}
				/>
			</TableWrapper>
		),
		[tableColumns, reports, loading, page, totalCount, reportShowPerPage, handleChangePage, handleChangePageSize]
	);

	return (
		<PageTemplate
			title={"Reports"}
			isLoading={loading}
			isNoData={!reports}
			emptyText={"You don’t have any Reports in your community yet."}
			searchPlaceholder={""}
			onSearchUpdate={() => {}}
			hideHeader
		>
			{TableBlock}
		</PageTemplate>
	);
});

export default Reports;
