import styled from "styled-components";

import { Box } from "shared/ui-kit";

export const Wrapper = styled(Box)`
	display: flex;
	flex-direction: column;

	position: relative;
`;

export const UploaderWrapper = styled(Box)<{ customBackColor?: string }>`
	width: 100%;

	position: relative;

	.uploader-wrapper {
		> div > div {
			border: none;
			border-radius: 4px;

			overflow: hidden;
		}

		.preview-wrapper {
			border: none;

			padding: 0;

			.item-img-section {
				border: none;

				padding: 0;
			}
		}
	}

	.item-description {
		display: none;
	}

	.preview-section {
		.item-img-section {
			width: 100%;
			height: 100%;
			img {
				object-fit: cover;
				object-position: center;
				width: 100%;
			}
		}
	}

	.explorer-uploader {
		background-color: ${({ customBackColor }) => customBackColor || "#92ccfd"};

		display: flex;
		justify-content: center;
		align-items: center;

		padding: 0;

		.uploader-content {
			display: none;
		}
	}

	.explorer-uploader .description .anchor {
		color: #6173fe;
	}

	.close {
		display: none;
	}

	&:hover {
		.replace-wrapper {
			&.active {
				opacity: 1;
				pointer-events: auto;
			}
		}
	}

	&.custom-placeholder-img {
		.explorer-uploader {
			background-color: transparent;
		}
	}
`;

UploaderWrapper.ReplaceWrapper = styled(Box)`
	position: absolute;

	top: 0;
	left: 0;

	width: 100%;
	height: 100%;

	opacity: 0;

	transition: opacity 0.25s ease;

	pointer-events: none;

	background-color: rgba(0, 0, 0, 0.4);

	border-radius: 4px;

	display: flex;
	align-items: center;
	justify-content: flex-end;

	padding-right: 40px;

	z-index: 10;

	&.center {
		justify-content: center;
		padding: 0;
	}
`;

UploaderWrapper.PlaceholderImg = styled(Box)`
	width: 100%;
	height: 100%;

	position: absolute;

	top: 0;
	left: 0;

	overflow: hidden;

	border-radius: 4px;

	pointer-events: none;

	z-index: 5;
`;

export const ActionBox = styled(Box)`
	padding-top: 5px;
	display: flex;
	justify-content: flex-end;
	.MuiIconButton-root {
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
		background-color: white;
		padding: 12px;
		height: 40px;
		width: 40px;
		margin-left: 4px;
		border-radius: 4px;
	}
`;

export const ButtonsWrapper = styled(Box)`
	position: absolute;
	top: 100%;
	right: 0;

	z-index: 20;
`;
