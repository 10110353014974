import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";

import { DateTime } from "luxon";

import { useHistory } from "react-router";

import { SeriesCollection } from "modules/Manage/Data/types";
import { UnlockPremiumBtn } from "shared/Components";
import { useOnScreen, useSeries } from "shared/hooks";
import { UnlockContentType } from "shared/types";
import { Text } from "shared/ui-kit";

import { Wrapper } from "./style";

import { CollectionRow } from "../../Components";
import { RefBox } from "../../shared/MemberDialog/style";
import ItemDetailsTemplate from "../ItemDetailsTemplate";

const StartConnection: FC<{ id: string }> = ({ id }) => {
	const { getCollection, setSeriesPlayState, getData: getSeriesData } = useSeries();
	const { seriesPlayState, isLoading } = getSeriesData();
	const [collection, setCollection] = useState<SeriesCollection | null>(null);
	const [offset, setOffset] = useState(1);
	const [loadData, setLoadData] = useState(true);

	const lastItemRef = useRef<HTMLDivElement>(null);

	const hasMoreItems = useMemo(
		() => collection && collection.itemIds.length < collection.originalItemIds.length,
		[collection]
	);

	const history = useHistory();

	const onScreen = useOnScreen(lastItemRef);

	useEffect(() => {
		if (onScreen && hasMoreItems) {
			setOffset(ctx => ctx + 1);
			setLoadData(true);
		}
	}, [hasMoreItems, onScreen]);

	useEffect(() => {
		if (!loadData) return;

		getCollection({
			id,
			offset,
			paginate: true,
			limit: (seriesPlayState?.currentIdx || 0) > 10 ? (seriesPlayState?.currentIdx || 10) + 1 : 10
		}).then(series => {
			if (series) {
				if (series.locked && !series.meta.individualAccess) return history.go(-1);
				setCollection(ctx =>
					offset > 1 && ctx ? { ...series, itemIds: [...ctx.itemIds, ...series.itemIds] } : series
				);
				if (!seriesPlayState || seriesPlayState.sId !== series._id)
					setSeriesPlayState({ sId: series._id, currentIdx: 0 });
			}

			setLoadData(false);
		});
	}, [getCollection, id, seriesPlayState, setSeriesPlayState, offset, loadData, history]);

	const currentItem = useMemo(
		() => collection?.itemIds[seriesPlayState?.currentIdx || 0],
		[collection, seriesPlayState]
	);

	const isLocked = useMemo(
		() => currentItem?.file?.locked || currentItem?.music?.locked || currentItem?.video?.locked,
		[currentItem]
	);

	const nextItem = useMemo(() => {
		if (!collection) return undefined;
		if (collection.originalItemIds.length === (seriesPlayState?.currentIdx || 0) + 1) {
			return {
				title: `End of playlist ${collection.originalItemIds.length}/${collection.originalItemIds.length}`,
				subtitle: ""
			};
		}

		const item = collection?.itemIds[(seriesPlayState?.currentIdx || 0) + 1];

		if (item)
			return {
				title: `Up Next: ${(seriesPlayState?.currentIdx || 0) + 2}/${collection.originalItemIds.length}`,
				subtitle: item.music?.title || item.video?.title || item.file?.title
			};
	}, [collection, seriesPlayState]);

	const onUnlock = useCallback(() => {
		setOffset(1);

		getCollection({
			id,
			offset,
			paginate: true,
			limit: collection?.itemIds.length || 10
		}).then(series => {
			if (series) {
				setCollection(ctx =>
					offset > 1 && ctx ? { ...series, itemIds: [...ctx.itemIds, ...series.itemIds] } : series
				);
				if (!seriesPlayState || seriesPlayState.sId !== series._id)
					setSeriesPlayState({ sId: series._id, currentIdx: 0 });
			}
		});
	}, [getCollection, id, offset, collection, seriesPlayState, setSeriesPlayState]);

	const unlockPremiumBtn = useMemo(
		() =>
			(currentItem?.video?.locked && !!currentItem?.video?.priceTags.length) ||
			(currentItem?.file?.locked && !!currentItem?.file?.priceTags?.length) ||
			(currentItem?.music?.locked && !!currentItem?.music?.priceTags?.length) ? (
				<UnlockPremiumBtn
					premiumObject={currentItem.video || currentItem.file || currentItem.music}
					premiumObjectType={
						currentItem.video
							? UnlockContentType.VIDEO
							: currentItem.file
							? UnlockContentType.FILE
							: UnlockContentType.TRACK
					}
					onUnlock={onUnlock}
				/>
			) : undefined,
		[currentItem, onUnlock]
	);

	if (!seriesPlayState || !currentItem) return null;

	const title = currentItem.file?.title || currentItem.music?.title || currentItem.video?.title;

	const fileUrlArr = currentItem.file?.file.url.split(".");
	const fileType = fileUrlArr && fileUrlArr[fileUrlArr.length - 1].toLowerCase();

	const pdfViewer = fileType === "pdf" ? currentItem.file?.file.url : undefined;
	const imageViewer =
		currentItem.file && (fileType === "jpg" || fileType === "png" || fileType === "jpeg" || fileType === "webp")
			? currentItem.file?.locked
				? currentItem.file.coverPhoto?.url
				: currentItem.file?.file.url
			: undefined;

	const summary = `${
		currentItem.video?.totalPlays || currentItem.file?.totalViewed || currentItem.music?.totalPlays || 0
	} views •`;

	if (collection && collection.locked && !collection.meta.individualAccess) return null;

	return (
		<Wrapper>
			<Wrapper.CurrentPlaying>
				{currentItem && (
					<ItemDetailsTemplate
						key={seriesPlayState.currentIdx}
						fileType={fileType}
						image={imageViewer}
						pdf={pdfViewer}
						locked={isLocked}
						track={currentItem.type === "TRACK" ? currentItem.music : undefined}
						video={currentItem.type === "VIDEO" ? currentItem.video : undefined}
						nextItem={nextItem}
						title={title}
						summary={`${currentItem.type !== "FILE" ? summary : ""} ${DateTime.fromJSDate(
							new Date(currentItem.music?.createdAt || currentItem.file?.createdAt || currentItem.video?.createdAt)
						).toRelative()}`}
						subtitle={currentItem.video?.category?.name || currentItem.file?.category?.name || currentItem.music?.genre}
						description={
							currentItem.file?.description || currentItem.music?.description || currentItem.video?.description
						}
						fullWidthDescription={true}
						unlockPremiumBlock={unlockPremiumBtn}
						allowDownload={collection?.meta?.canBeDownloaded}
					/>
				)}
			</Wrapper.CurrentPlaying>
			<Wrapper.SeriesContent>
				<Text variant="h3" className="header">
					{collection?.title}
				</Text>
				<Text className="series-summary">
					Series content - {seriesPlayState.currentIdx + 1}/{collection?.originalItemIds.length || 0}
				</Text>
				<Wrapper.HR />
				<Wrapper.CollectionItems>
					{collection?.itemIds.map((item, i) => (
						<RefBox key={i} ref={i + 1 === collection.itemIds.length ? lastItemRef : null}>
							<CollectionRow
								mini
								current={currentItem._id === item._id}
								item={item}
								order={i + 1}
								onClick={() =>
									setSeriesPlayState({
										sId: seriesPlayState.sId,
										currentIdx: i
									})
								}
							/>
						</RefBox>
					))}
					{isLoading && (
						<>
							<CollectionRow mini skeleton />
							<CollectionRow mini skeleton />
							<CollectionRow mini skeleton />
							<CollectionRow mini skeleton />
						</>
					)}
				</Wrapper.CollectionItems>
			</Wrapper.SeriesContent>
		</Wrapper>
	);
};

export default StartConnection;
