import React, { memo, useCallback, useEffect, useMemo, useRef } from "react";

import { Controller, useForm } from "react-hook-form";

import styled from "styled-components";

import ConfirmLeavePopup from "shared/Components/ConfirmLeave";
import useConfirmLeavePopup from "shared/Components/ConfirmLeave/hooks/useConfirmLeavePopup";
import SmartScroll from "shared/Components/SmartScroll";
import { useBoards, useScrollbar } from "shared/hooks";
import useScrollToXPosition from "shared/hooks/useScrollToXPosition";
import { ManageDialogFormWrapper } from "shared/styles";
import { CategoryModel } from "shared/types";
import { Box, Button, Icon, InPlaceModal, InPlaceModalProps, Input, Text } from "shared/ui-kit";

import {
	IsDefaultBoardField,
	IsPrimaryBoardField,
	SelectIconField,
	TitleRequiredBoardField,
	WhoCanPostField
} from "../../Components";
import NotifyCommunityField from "../../Components/notifyCommunityField";

export const ModalWrapper = styled(Box)`
	.MuiDialogContent-root {
		padding: 8px 0;
		overflow-y: hidden;
	}
	.MuiDialogContent-root:first-child {
		padding-top: 8px;
	}
	.backdrop {
		position: fixed;
		background-color: rgba(0, 0, 0, 0.5);
	}
	${props => props.theme.breakpoints.down("sm")} {
		.paper-full-width {
			margin: auto;
			width: 90%;
		}
		.MuiDialog-scrollPaper {
			display: inline;
		}
	}
	${props => props.theme.breakpoints.up("sm")} {
		.MuiDialog-paperWidthSm {
			max-width: ${props => (props.dialogWidth ? props.dialogWidth : "648px")};
		}
		.MuiDialog-scrollPaper {
			display: flex;
			height: 100vh;
		}
	}
`;

const HeaderWrapper = styled(Box)`
	border: solid #edf1f7;
	border-width: 0 0 1px 0;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 8px 16px;
	width: 100%;
	${props => props.theme.breakpoints.up("sm")} {
		padding: 16px;
	}
`;

const IconWrapper = styled(Box)`
	position: absolute;
	right: 16px;
`;

const Title = styled(Text)`
	font-size: 18px;
	justify-self: center;
`;

const FooterWrapper = styled(Box)`
	padding: 8px 16px;
	border: solid #edf1f7;
	border-width: 1px 0 0 0;
	display: flex;

	${props => props.theme.breakpoints.up("sm")} {
		justify-content: flex-end;
	}
`;

const SidePadding = styled(Box)`
	padding-left: 24px;
	padding-right: 24px;
`;

const StyledInput = styled(Input)`
	flex: 1;
	margin-bottom: 16px;
`;

const Delimiter = styled.hr`
	border: 0;
	border-bottom: 1px solid #e4e9f2;
	margin: 20px 0 12px 0;
`;

const PrimaryBlockWrapper = styled(Box)`
	${props => props.theme.breakpoints.down("sm")} {
		p {
			font-size: 13px;
		}
	}
`;

const TitleBlockWrapper = styled(Box)`
	${props => props.theme.breakpoints.down("sm")} {
		p {
			font-size: 12px;
		}
	}
`;

const SubmitButton = styled(Button)`
	height: 48px;
	flex: 1;
	${props => props.theme.breakpoints.up("sm")} {
		flex: unset;
	}
`;

interface Props extends InPlaceModalProps {
	board?: CategoryModel;
}

const CreateUpdateBoardModal: React.FC<Partial<Props>> = memo(({ onClose, board, ...props }: Partial<Props>) => {
	const formRef = useRef<HTMLFormElement>();
	const scrollToXPositionOnFocus = useScrollToXPosition(formRef?.current);

	const { createBoard, updateBoard, getData: getBoardsData } = useBoards();
	const { isLoading, defaultBoard } = getBoardsData();

	const isEditMode = useMemo(() => !!board, [board]);

	const { control, handleSubmit, formState, errors, reset } = useForm({
		mode: "onChange"
	});

	const { showScroll, hideScroll } = useScrollbar();

	useEffect(() => {
		props.open ? hideScroll() : showScroll();

		return () => {
			showScroll();
		};
	}, [showScroll, hideScroll, props.open]);

	useEffect(() => {
		if (board) {
			reset({
				cid: board?.cid,
				name: board?.name,
				description: board?.description,
				iconName: board?.iconName,
				contributorLevel: board?.contributorLevel,
				isPrimary: board?.isPrimary,
				default: board?.cid === defaultBoard?.cid,
				titleRequired: board?.titleRequired,
				notifyCommunity: board?.notifyCommunity
			});
		} else {
			reset({
				contributorLevel: "ANYONE"
			});
		}
	}, [board, reset, defaultBoard]);

	const onSubmit = useCallback(
		data => {
			if (data) {
				isEditMode ? updateBoard(data) : createBoard(data);
				if (onClose) {
					onClose({ created: !isEditMode });
				}
			}
		},
		[isEditMode, updateBoard, createBoard, onClose]
	);

	const {
		handleLeavePageConfirmed,
		closeConfirmPopup,
		handleClose,
		getData: getConfirmLeavePopupData
	} = useConfirmLeavePopup({
		onClose: () => (onClose ? onClose() : {}),
		open: props.open
	});
	const { showConfirmPopup } = getConfirmLeavePopupData();

	const { isDirty } = formState;

	const onCloseHandler = useCallback(() => {
		handleClose(isDirty);
	}, [handleClose, isDirty]);

	return (
		<>
			<ConfirmLeavePopup
				handleLeavePage={handleLeavePageConfirmed}
				open={showConfirmPopup}
				onClose={closeConfirmPopup}
				popup
			/>
			<ModalWrapper>
				<InPlaceModal {...props} open={props.open || false} onClose={onCloseHandler} maxWidth={"sm"} modalHeight={600}>
					<form onSubmit={handleSubmit(onSubmit)}>
						<HeaderWrapper>
							<Title variant="h6">{isEditMode ? "Edit Board" : "Create Board"}</Title>
							<IconWrapper>
								<Icon
									fill="#c5cee0"
									group="filled"
									height={24}
									name="close"
									width={24}
									style={{ marginLeft: "10px", textAlign: "right", cursor: "pointer" }}
									onClick={onCloseHandler}
								/>
							</IconWrapper>
						</HeaderWrapper>
						<ManageDialogFormWrapper containerRef={ref => (formRef.current = ref)} customStyle="padding: 10px 0;">
							<SmartScroll height="400px">
								<SidePadding>
									<Controller name={"cid"} control={control} render={() => <></>} />
									<Controller
										name={"name"}
										control={control}
										rules={{
											required: "Board name is required",
											maxLength: {
												value: 40,
												message: "Board name should not exceed 40 characters."
											}
										}}
										render={({ onChange, value, ref }) => (
											<StyledInput
												id="boardName"
												placeholder="Board name*"
												value={value}
												errorText={errors["name"]?.message}
												onChange={onChange}
												ref={ref}
												onFocus={scrollToXPositionOnFocus}
											/>
										)}
									/>
									<Controller
										name={"description"}
										control={control}
										rules={{
											required: "Board description is required.",
											maxLength: {
												value: 60,
												message: "Board description should not exceed 60 characters."
											}
										}}
										render={({ onChange, value, ref }) => (
											<StyledInput
												id="boardDescription"
												placeholder="Short description*"
												value={value}
												errorText={errors["description"]?.message}
												onChange={onChange}
												ref={ref}
												onFocus={scrollToXPositionOnFocus}
											/>
										)}
									/>
									<Controller
										name={"iconName"}
										control={control}
										rules={{
											required: "Field is required."
										}}
										render={({ onChange, value }) => <SelectIconField value={value} onChange={onChange} />}
									/>
									<Controller
										name={"contributorLevel"}
										control={control}
										rules={{
											required: "Field is required."
										}}
										render={({ onChange, value }) => <WhoCanPostField value={value} onChange={onChange} />}
									/>
								</SidePadding>
								<Delimiter />
								<SidePadding>
									<Controller
										name={"isPrimary"}
										control={control}
										defaultValue={false}
										render={({ onChange, value }) => (
											<PrimaryBlockWrapper>
												<IsPrimaryBoardField value={value} onChange={onChange} />
											</PrimaryBlockWrapper>
										)}
									/>
								</SidePadding>
								<Delimiter />
								<SidePadding>
									<Controller
										name={"default"}
										control={control}
										defaultValue={false}
										render={({ onChange, value }) => (
											<PrimaryBlockWrapper>
												<IsDefaultBoardField value={value} onChange={onChange} />
											</PrimaryBlockWrapper>
										)}
									/>
								</SidePadding>
								<Delimiter />
								<SidePadding>
									<Controller
										name={"titleRequired"}
										control={control}
										defaultValue={false}
										render={({ onChange, value }) => (
											<TitleBlockWrapper>
												<TitleRequiredBoardField value={value} onChange={onChange} />
											</TitleBlockWrapper>
										)}
									/>
									<Controller
										name={"notifyCommunity"}
										control={control}
										defaultValue={false}
										render={({ onChange, value }) => (
											<TitleBlockWrapper>
												<NotifyCommunityField value={value} onChange={onChange} />
											</TitleBlockWrapper>
										)}
									/>
								</SidePadding>
							</SmartScroll>
						</ManageDialogFormWrapper>
						<FooterWrapper>
							<SubmitButton
								type="submit"
								disabled={!formState.isValid || !formState.isDirty || isLoading}
								id={isEditMode ? "updateBoard" : "createBoard"}
							>
								{isEditMode ? "Update Board" : "Create Board"}
							</SubmitButton>
						</FooterWrapper>
					</form>
				</InPlaceModal>
			</ModalWrapper>
		</>
	);
});

export default CreateUpdateBoardModal;
