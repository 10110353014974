import React, { useCallback, useEffect, useMemo, useState } from "react";

import { InputAdornment, TextField } from "@material-ui/core";

import Skeleton from "@material-ui/lab/Skeleton";

import { Scrollbars } from "react-custom-scrollbars";

import { ReactComponent as SearchIcon } from "assets/icons/iconSearch.svg";
import { MemberDialog } from "modules/MemberHome/View/shared";
import { MemberDialogContentWrapper } from "modules/MemberHome/View/shared/style";
import { useFirebase, useMessaging } from "modules/Messaging/Data";
import SmartScroll from "shared/Components/SmartScroll";
import { useDebounce, useNotification, useUser } from "shared/hooks";
import { GroupModel } from "shared/types";
import { Button, Checkbox, Text } from "shared/ui-kit";

import { CustomDialog, FooterWrapper, Persona, PersonaList, SkeletonInfoWrapper, SkeletonRowWrapper } from "./style";

import { ChatType } from "../../../../Data/types";

interface Props {
	group?: GroupModel;
	open: boolean;
	onClose: (e: Event, r: string) => void;
}

const CreateChat: React.FC<Props> = ({ group, open = true, onClose }) => {
	const [keyword, setKeyword] = useState<string | null>(null);
	const { showMessage } = useNotification();
	const { setCurrentChat } = useFirebase();

	const {
		getRecentConnections,
		createGroupChatWithMembers,
		createConnection,
		getGroupChatId,
		getData: getMessagingData
	} = useMessaging();
	const { inbox, connectionList, loadingConnections, searchingConnections, allowLoadMoreConnections } =
		getMessagingData();

	const { getActiveProfile, getData: getUserData } = useUser();
	const { user, isMemberView } = getUserData();

	const [offset, setOffset] = useState(1);
	const [selected, setSelected] = useState<number[]>([]);

	const personaId = useMemo(() => getActiveProfile(user)?.personaId, [getActiveProfile, user]);

	const debouncedKeyword = useDebounce(keyword, 400);

	const loadData = useCallback(
		({ offset = 1, keyword = "" }) => {
			getRecentConnections({
				offset,
				personaId,
				limit: 20,
				keyword
			});
		},
		[getRecentConnections, personaId]
	);

	useEffect(() => {
		loadData({ offset: 1, keyword: debouncedKeyword });
	}, [debouncedKeyword, loadData]);

	const handleCreateChat = useCallback(async () => {
		const selectedMembers = selected;

		const members = [Number(personaId), ...selectedMembers].map(item => ({
			personaId: item,
			isOwner: personaId === item,
			isMember: true,
			notificationEnabed: true
		}));

		if (members.length > 2) {
			const groupChatExists = await getGroupChatId(selectedMembers);

			if (groupChatExists?.groupChatId) {
				const findConversation = inbox?.find(item => item._id === groupChatExists.groupChatId);
				findConversation && setCurrentChat(findConversation);
			} else {
				await createGroupChatWithMembers(members, personaId!)?.then(groupChatResponse => {
					if (groupChatResponse?.groupChat._id) {
						setCurrentChat({ ...groupChatResponse.groupChat, type: ChatType.GROUP });
						showMessage("Group chat was created! ✨");
					}
				});
			}

			await onClose({} as Event, "group");
		} else {
			createConnection(members[1].personaId).then(chatResponse => chatResponse && setCurrentChat(chatResponse));
			await onClose({} as Event, "");
		}
	}, [
		createConnection,
		createGroupChatWithMembers,
		getGroupChatId,
		inbox,
		onClose,
		personaId,
		selected,
		setCurrentChat,
		showMessage
	]);

	const footer = useMemo(
		() => (
			<FooterWrapper>
				<Text variant="subtitle2">You can add max 50 connections.</Text>
				<Button disabled={!selected.length || selected.length > 50} onClick={handleCreateChat}>
					Done
				</Button>
			</FooterWrapper>
		),
		[handleCreateChat, selected]
	);

	const loadMore = useCallback(() => {
		setOffset(offset + 1);
		loadData({ offset: offset + 1, keyword: debouncedKeyword });
	}, [loadData, debouncedKeyword, offset]);

	const skeletonRow = useCallback(
		(index: number, style?: any) => (
			<SkeletonRowWrapper key={index} style={style || {}}>
				<Skeleton variant="rect" width={40} height={40} />
				<SkeletonInfoWrapper>
					<Skeleton width="35%" height={30} />
				</SkeletonInfoWrapper>
			</SkeletonRowWrapper>
		),
		[]
	);

	const ConnectionListItem = React.useCallback(
		({ index, style }) => {
			if (index < connectionList.length) {
				const currentItem = connectionList[index];
				const { profilePhoto, firstName, lastName, personaId, photos } = currentItem;
				// const disabled = !group?.members.some(m => m.personaId === personaId);
				const disabled = false;

				const avatar = profilePhoto || (!!photos?.length && photos[0].profilePicture) || "";

				return (
					<Persona.Wrapper key={currentItem._id} style={style}>
						<Persona.InfoColumn>
							<Persona.Avatar title={firstName} image={avatar} />
							<Persona.Name>
								{firstName ? `${firstName} ${lastName}` : ""}{" "}
								{group?.members.some(m => m.personaId === personaId) && "(Already invited)"}
							</Persona.Name>
						</Persona.InfoColumn>
						<Persona.CheckboxWrapper>
							<Checkbox
								checked={selected.some(x => x === Number(personaId))}
								onChange={e => {
									if (!disabled) {
										setSelected(sel =>
											e.target.checked
												? sel.length < 50
													? sel.concat([Number(personaId)])
													: sel
												: sel.filter(x => x !== Number(personaId))
										);
									}
								}}
								disabled={disabled}
							/>
						</Persona.CheckboxWrapper>
					</Persona.Wrapper>
				);
			}

			return <>{skeletonRow(index, style)}</>;
		},
		[connectionList, skeletonRow, group, selected]
	);

	const content = useMemo(
		() =>
			(loadingConnections || searchingConnections) && offset === 1 ? (
				<>
					<Skeleton width="100%" height={60} />
					{[...Array.from(Array(5).keys())].map((i, index) => (
						<>{skeletonRow(index)}</>
					))}
				</>
			) : (
				<>
					<TextField
						defaultValue={keyword}
						onChange={e => {
							setKeyword(e.target.value);
						}}
						placeholder="Search"
						variant="outlined"
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<SearchIcon />
								</InputAdornment>
							)
						}}
					/>
					<PersonaList
						height={350}
						itemCount={loadingConnections || searchingConnections ? connectionList.length + 5 : connectionList.length}
						itemSize={72}
						onScroll={scroll => {
							if (
								scroll.scrollOffset >= 350 &&
								!loadingConnections &&
								!searchingConnections &&
								allowLoadMoreConnections
							) {
								loadMore();
							}
						}}
						outerElementType={Scrollbars}
					>
						{ConnectionListItem}
					</PersonaList>
				</>
			),
		[
			ConnectionListItem,
			allowLoadMoreConnections,
			connectionList.length,
			keyword,
			loadMore,
			loadingConnections,
			offset,
			searchingConnections,
			skeletonRow
		]
	);

	if (isMemberView)
		return (
			<MemberDialog
				customWidth={500}
				modalHeight={680}
				title="Choose people"
				open={open}
				onClose={onClose}
				footerPrimary={{
					disabled: !selected.length || selected.length > 50,
					onClick: handleCreateChat,
					text: "Done"
				}}
			>
				<SmartScroll height="720px">
					<MemberDialogContentWrapper className="pv-20">{content}</MemberDialogContentWrapper>
				</SmartScroll>
			</MemberDialog>
		);

	return (
		<CustomDialog
			title="Choose people"
			open={open}
			footer={footer}
			onClose={onClose}
			bodyCustomStyles={"padding: 16px 40px 0 40px;"}
		>
			{content}
		</CustomDialog>
	);
};

export default CreateChat;
