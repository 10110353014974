import React, { FC, useCallback, useEffect, useState } from "react";

import { Box } from "@material-ui/core";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import VisibilityIcon from "@material-ui/icons/Visibility";
import * as R from "ramda";
import { Controller, useForm, useWatch } from "react-hook-form";

import { FileFormModel, FileType, UpdateFileFormModel } from "types/FilesContextValuesType";

import { ReactComponent as DownloadIcon } from "assets/icons/icon-download.svg";
import { useCategories } from "modules/Marketplace/Data";
import { CategoryProps } from "modules/Marketplace/Data/types";
import { IconWrapper } from "shared/Components/Video/style";
import { useCommunity, useFiles, useNotification } from "shared/hooks";
import { useS3Uploader } from "shared/services/s3Uploader";
import { CategorySort } from "shared/types";
import { Icon } from "shared/ui-kit";

import { mergeDateAndTime } from "utils/serviceUtils/helpers";

import { BodyWrapper } from "./style";

import { DateInput, EnableFeature, MemberDialog, RectangleUploader, SelectInput, TimeInput } from "../../shared";

import LabeledInput from "../../shared/LabeledInput";
import { InputWrapper } from "../../shared/style";

interface CreateFileDialogProps {
	editableFile?: FileType;
	open: boolean;
	onClose: () => void;
}

const CreateFileDialog: FC<CreateFileDialogProps> = ({ open, onClose, editableFile }) => {
	const {
		control,
		formState: { isDirty, errors, isSubmitting, isValid },
		handleSubmit,
		reset
	} = useForm({ mode: "onChange" });
	const { uploadFile, getData: getS3UploaderData } = useS3Uploader();
	const { uploading, uploadingProgress } = getS3UploaderData();

	const { createFile, updateFile, setFileUrl, getData: getFilesData } = useFiles();
	const { fileUrl, extension, fileUploadUrl } = getFilesData();

	const { getCategories } = useCategories();
	const { getCoinsList, getData: getCommunityData } = useCommunity();
	const { coins, isFree } = getCommunityData();
	const { showMessage } = useNotification();

	const [suggestedCategories, setSuggestedCategories] = useState<CategoryProps[]>([]);
	const [coinsList, setCoinsList] = useState<{ label: string; value: string }[]>([]);

	const { productEnabled, scheduled } = useWatch({ control, name: ["productEnabled", "scheduled"] });

	const loadSuggestedCategories = useCallback(
		async (name: string) => {
			const { categories } = await getCategories({
				limit: 20,
				offset: 1,
				keyword: name,
				sortBy: CategorySort.name,
				order: 1
			});

			setSuggestedCategories(categories);
		},
		[getCategories]
	);

	useEffect(() => {
		if (open) {
			getCoinsList();
			loadSuggestedCategories("");
		}
	}, [open, getCoinsList, loadSuggestedCategories]);

	useEffect(() => {
		if (editableFile) {
			reset({
				...R.pick(["title", "description", "canBeDownloaded", "private", "scheduleDate"], editableFile),
				photo: editableFile.coverPhoto?.url,
				categoryId: editableFile?.category
					? {
							label: editableFile.category.name,
							value: editableFile.category._id
					  }
					: undefined,
				productEnabled: !!editableFile?.product?.length,
				productId: editableFile?.product?.length
					? {
							label: `${editableFile.product[0].coins} Coins - Equivalent to $ ${editableFile.product[0].price}`,
							value: editableFile.product[0]._id
					  }
					: undefined,
				scheduled: editableFile?.scheduleDate,
				scheduleTime: editableFile?.scheduleDate
			});
			setFileUrl(editableFile?.file?.url);
		}
	}, [editableFile, reset, setFileUrl]);

	useEffect(() => {
		if (coins.length) {
			const coinsL = coins.map(coin => ({
				value: coin._id,
				label: `${coin.coins} Coins - Equivalent to $ ${coin.price}`
			}));

			setCoinsList(coinsL);
		}
	}, [coins, coins.length]);

	const onSubmit = async data => {
		let scheduleDate: Date | undefined;

		if (data.scheduleDate && data.scheduleTime) {
			scheduleDate = mergeDateAndTime(data.scheduleDate, data.scheduleTime);
		}

		const body = {
			...R.pick(["title", "description", "canBeDownloaded", "private"], data),
			fileUrl,
			extension,
			scheduleDate,
			categoryId: data?.categoryId.value,
			productId: data?.productId?.value
		};

		if (data.photo) {
			body.coverPhotoUrl = data.photo;
		}

		if (editableFile) {
			body._id = editableFile._id;
		}

		editableFile ? await updateFile(body as UpdateFileFormModel) : await createFile(body as FileFormModel);

		showMessage(`File successfully ${!!editableFile ? "updated" : "created"}`);

		onClose();
	};

	const uploadPhoto = useCallback(
		async (files: FileList, onChange: (...event: any[]) => void) => {
			if (files.length) {
				const data = await uploadFile({
					file: files[0] as File,
					communityName: fileUploadUrl
				});
				onChange(data?.publicUrl);
			}
		},
		[uploadFile, fileUploadUrl]
	);

	return (
		<MemberDialog
			title="Create File"
			open={open}
			onClose={onClose}
			confirmLeave={true}
			uploadingProgress={uploadingProgress}
			customWidth={512}
			footerPrimary={{
				text: editableFile ? "Update" : "Create",
				disabled: !isDirty || uploading || isSubmitting || !isValid,
				loading: isSubmitting || uploading,
				onClick: handleSubmit(onSubmit)
			}}
		>
			<form onSubmit={handleSubmit(onSubmit)}>
				<Controller
					name="photo"
					control={control}
					render={({ onChange }) => (
						<RectangleUploader
							defaultPreviewUrl={editableFile?.coverPhoto?.url}
							onChange={files => uploadPhoto(files, onChange)}
							errorText={errors?.photo?.message}
						/>
					)}
				/>
				<BodyWrapper>
					<Controller
						name="title"
						control={control}
						rules={{
							required: "Title is required",
							maxLength: {
								value: 100,
								message: "Title should be less than 100 characters."
							}
						}}
						render={({ onChange, value, ref }) => (
							<InputWrapper>
								<LabeledInput
									name="title"
									onChange={onChange}
									value={value}
									placeholder=" "
									label="File Title *"
									error={errors?.title?.message}
									showCounter={100}
									inputRef={ref}
								/>
							</InputWrapper>
						)}
					/>
					<Controller
						name="description"
						control={control}
						rules={{
							required: "Description is required",
							maxLength: {
								value: 1000,
								message: "Title should be less than 1000 characters."
							}
						}}
						render={({ onChange, value, ref }) => (
							<InputWrapper>
								<LabeledInput
									name="description"
									onChange={onChange}
									value={value}
									placeholder=" "
									label="Description *"
									error={errors?.description?.message}
									textarea
									showCounter={1000}
									inputRef={ref}
								/>
							</InputWrapper>
						)}
					/>
					<Controller
						name="categoryId"
						control={control}
						rules={{
							required: "Category is required",
							validate: val => (!val.value || !val.label ? "Please choose a category" : true)
						}}
						render={({ onChange, value, ref }) => (
							<InputWrapper>
								<SelectInput
									name="categoryId"
									onChange={onChange}
									value={value && value.label}
									placeholder=" "
									label="Category *"
									loadSuggestions={val => loadSuggestedCategories(val)}
									maxHeight={260}
									error={errors?.categoryId?.message}
									noOptionsHeadline="Type your category title ( ex: UX Designer )"
									options={
										suggestedCategories
											? suggestedCategories.map(category => ({
													value: category._id,
													label: category.name
											  }))
											: []
									}
									selectInputRef={ref}
								/>
							</InputWrapper>
						)}
					/>
					{!isFree && (
						<>
							<Controller
								name="productEnabled"
								defaultValue={false}
								control={control}
								render={({ onChange, value }) => (
									<InputWrapper>
										<EnableFeature
											title="Set a Price"
											onChange={onChange}
											value={value}
											icon={
												<IconWrapper>
													<MonetizationOnIcon />
												</IconWrapper>
											}
										/>
									</InputWrapper>
								)}
							/>
							{productEnabled && (
								<>
									<Controller
										name="productId"
										control={control}
										rules={{
											required: "Product is required",
											validate: val => (!val.value || !val.label ? "Please choose a product" : true)
										}}
										render={({ onChange, value }) => (
											<InputWrapper>
												<SelectInput
													name="productId"
													onChange={onChange}
													value={value && value.label}
													placeholder="Choose price tag *"
													maxHeight={260}
													error={errors?.productId?.message}
													options={coinsList}
												/>
											</InputWrapper>
										)}
									/>
								</>
							)}
						</>
					)}
					<Controller
						name="scheduled"
						control={control}
						defaultValue={false}
						render={({ onChange, value }) => (
							<InputWrapper>
								<EnableFeature
									title="Publish File Later"
									onChange={onChange}
									value={value}
									icon={<Icon group={""} fill={"#8f9bb3"} name={"clock"} width={24} height={24} />}
								/>
							</InputWrapper>
						)}
					/>
					{scheduled && (
						<Box className="two-inputs-row">
							<Controller
								name="scheduleDate"
								// rules={{ required: features.schedule ? "Schedule Date is required" : false }}
								control={control}
								render={({ onChange, value = null }) => (
									<InputWrapper width="48%">
										<DateInput
											name="date"
											value={value}
											onChange={onChange}
											error={errors?.scheduled?.message}
											placeholder="Select Date"
											displayOnly
										/>
									</InputWrapper>
								)}
							/>
							<Controller
								name="scheduleTime"
								// rules={{ required: features.schedule ? "Schedule Time is required" : false }}
								control={control}
								// defaultValue={editableModel?.scheduleDate}
								render={({ onChange, value = null }) => (
									<InputWrapper width="48%">
										<TimeInput
											name="time"
											value={value}
											onChange={onChange}
											error={errors?.scheduleTime?.message}
											placeholder="Select Time"
										/>
									</InputWrapper>
								)}
							/>
						</Box>
					)}
					<Controller
						name="canBeDownloaded"
						control={control}
						defaultValue={false}
						render={({ onChange, value }) => (
							<InputWrapper>
								<EnableFeature
									title="Allow Download"
									onChange={onChange}
									value={value}
									icon={
										<IconWrapper className={"fill-second"}>
											<DownloadIcon />
										</IconWrapper>
									}
								/>
							</InputWrapper>
						)}
					/>
					<Controller
						name="private"
						control={control}
						defaultValue={false}
						render={({ onChange, value }) => (
							<InputWrapper>
								<EnableFeature
									title="Private"
									description={"Only invited members will be able to find this file"}
									onChange={onChange}
									value={value}
									icon={
										<IconWrapper>
											<VisibilityIcon />
										</IconWrapper>
									}
									verticalPadding
								/>
							</InputWrapper>
						)}
					/>
				</BodyWrapper>
			</form>
		</MemberDialog>
	);
};

export default CreateFileDialog;
