/* eslint-disable react/no-unescaped-entities */
import React, { useCallback, useEffect, useMemo, useState } from "react";

import { Controller, useFieldArray, useForm } from "react-hook-form";
import { css } from "styled-components";

import { useAuth } from "modules/App/Data";
import { Dialog } from "shared/Components";
import { useMarketing } from "shared/hooks";
import { IWorkspace } from "shared/types";
import { Loader } from "shared/ui-kit";

import { DialogContent, ErrorText, FormBlock, StyledActionButton, TextBlock } from "./styles";

export interface JoinPrivateCommunityModalProps {
	open: boolean;
	userId: string;
	onClose: (workspaceInfo?: IWorkspace) => void;
}

export const JoinPrivateCommunityModal: React.FC<JoinPrivateCommunityModalProps> = ({ open, userId, onClose }) => {
	const { getData: getMarketingData } = useMarketing();
	const { workspace } = getMarketingData();

	const { verifyOnboardingToken } = useAuth();

	const [error, setError] = useState(false);

	const {
		control,
		reset,
		handleSubmit,
		formState: { isValid, isSubmitting, isDirty }
	} = useForm({
		mode: "onChange"
	});

	const { fields } = useFieldArray({ name: "digits", control });

	useEffect(() => {
		if (open) {
			reset({
				digits: ["", "", "", ""]
			});
		}
	}, [open, reset]);

	const onSubmit = useCallback(
		async (data: any) => {
			setError(false);

			const digitCode = data?.digits.map(x => x.value).join("");
			const verificationInfo = await verifyOnboardingToken({
				communityUrl: `${workspace?.communityUrl}`,
				invitationId: digitCode,
				userId
			});

			// @ts-expect-error: correct validation
			if (verificationInfo?.token) {
				onClose(verificationInfo as IWorkspace);
			} else {
				setError(true);
			}
		},
		[userId, verifyOnboardingToken, onClose, workspace]
	);

	const ActionButton = useMemo(() => {
		return (
			<StyledActionButton
				type="submit"
				disabled={isSubmitting || !isValid || !isDirty}
				leftIcon={isSubmitting ? <Loader size="1rem" show={true} color="primary" variant="indeterminate" /> : undefined}
				onClick={handleSubmit(onSubmit)}
			>
				Next
			</StyledActionButton>
		);
	}, [isSubmitting, isValid, handleSubmit, onSubmit, isDirty]);

	const keyHandler = (e, i, ref) => {
		const codeValue = e.target.value.replace(/\D/g, "").slice(0, 1);

		if ((e.key === "Backspace" || e.keyDown === 8) && i !== 0) {
			ref.current.form[i - 1].focus();
		} else if (e.keyDown !== 9 && codeValue && i !== fields.length - 1) {
			ref.current.form[i + 1].focus();
		}
	};

	const RenderRows = (p, i: number) => {
		return (
			<FormBlock.InputWrapper>
				<Controller
					name={`digits[${i}].value`}
					control={control}
					rules={{
						required: "Required"
					}}
					defaultValue={""}
					render={({ value, onChange, ref }) => (
						<FormBlock.Input
							value={value}
							ref={ref}
							onChange={e => onChange(e.target.value.replace(/\D/g, "").slice(0, 1))}
							onKeyUp={e => keyHandler(e, i, ref)}
						/>
					)}
				/>
			</FormBlock.InputWrapper>
		);
	};

	return (
		<>
			<Dialog
				title={"Join Private Community"}
				open={open}
				onClose={() => onClose()}
				footer={ActionButton}
				bodyCustomStyles={css`
					position: relative;
				`}
				maxWidth={"sm"}
			>
				<DialogContent>
					<TextBlock>
						Please enter <span>4-digit</span> invitation code here.
					</TextBlock>
					<FormBlock>{fields.map((field, i) => RenderRows(field as any, i))}</FormBlock>
					{error && <ErrorText>Incorrect code</ErrorText>}
				</DialogContent>
			</Dialog>
		</>
	);
};

export default JoinPrivateCommunityModal;
