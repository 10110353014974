import React from "react";

import { CircularProgress } from "@material-ui/core";
import { debounce } from "@material-ui/core/utils";

import { Icon } from "shared/ui-kit";

import theme from "theme/default";

export type ValidationStatus = "" | "loading" | "valid" | "invalid";

export const isValidYoutubeUrl = (url: string): boolean => {
	const pattern =
		/^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
	const matches = url.match(pattern);
	return !!matches;
};

export const ValidationAdornmentIcon = ({ status }: { status: ValidationStatus }) => {
	switch (status) {
		case "loading":
			return <CircularProgress size={24} />;
		case "valid":
			return <Icon fill={theme.palette.colors.success[600]} group="filled" name="check-circle" />;
		case "invalid":
			return <Icon fill={theme.palette.colors.danger[500]} group="filled" name="exclamation-triangle" />;
		default:
			return null;
	}
};

export const validateNetworkRequest = (url, setStatus) => {
	setStatus("loading");
	fetch(url, { mode: "no-cors" })
		.then(() => setStatus("valid"))
		.catch(() => setStatus("invalid"));
};

const validateYtUrl = (url, setStatus) => {
	if (isValidYoutubeUrl(url)) {
		validateNetworkRequest(url, setStatus);
	} else {
		setStatus("invalid");
	}
};

const timeout = 1000;
export const debouncedValidateYtUrl = debounce(validateYtUrl, timeout);
