import { Chip, TextField } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { Autocomplete } from "@material-ui/lab";
import styled from "styled-components";

import { Button, FormColumn, FormFooter, Input, Loader, Text } from "shared/ui-kit";

export const Container = styled(Box)`
	position: relative;
	max-width: 950px;
	margin: auto;

	.MuiDialog-root {
		height: 600px !important;
	}

	.MuiBackdrop-root {
		background: rgba(25, 36, 53, 0.1) !important;
		position: fixed !important;
	}

	${props => props.theme.breakpoints.down("sm")} {
		padding: 15px 16px;
	}
`;

export const Header = styled(Box)`
	margin-bottom: 32px;
	width: 100%;
	display: flex;
`;

export const HeaderText = styled(Text)`
	color: #222b45;
	font-weight: bold;
	font-size: 32px;
`;

export const TeamBodyWrapper = styled(Box)`
	margin: 0 0 56px;
	border-radius: 4px;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
`;

export const SearchbarWrapper = styled(Box)`
	display: flex;
	flex-wrap: wrap;
	padding: 12px 24px;
	background: #fff;
	justify-content: flex-end;
	align-items: center;

	> div {
		max-width: 260px !important;
		border-radius: 4px;
		border: solid 0.5px #c6cfe0;
		background-color: #f7f9fc;

		${props => props.theme.breakpoints.down("sm")} {
			max-width: 100% !important;
		}
	}

	.search-icon {
		color: #475b7f;
	}

	.MuiInputBase-input {
		max-width: 450px !important;
		width: 100% !important;
	}

	${props => props.theme.breakpoints.up("sm")} {
		flex-wrap: nowrap;
		justify-content: space-between;
	}

	${props => props.theme.breakpoints.down("sm")} {
		padding: 12px 16px;
	}
`;

export const StyledButton = styled(Button)<{ color?: string }>`
	background-color: ${({ transparent }) => transparent || "#6173fe"};
	color: ${({ transparent, color }) => transparent || color};
	font-size: 12px;

	&.Mui-disabled {
		color: ${({ disabled }) => disabled && "#8f9bb3"};
		background-color: ${({ disabled }) => disabled && "#edf1f7"};
	}

	@media (min-width: 991px) {
		font-size: 16px;
	}
`;

export const UserDetails = styled(Box)``;

UserDetails.Wrapper = styled(Box)`
	display: flex;
	align-items: center;

	&.pointer {
		cursor: pointer;
	}
`;

UserDetails.Text = styled(Text)`
	color: #222b45;
	font-size: 13px;
	line-height: 16px;

	margin-bottom: 0;
`;

UserDetails.IconWrapper = styled(Box)`
	display: flex;
	align-items: center;

	margin-left: 9px;

	width: 16px;
`;

export const AddMemberModalWrapper = styled(Box)`
	${props => props.theme.breakpoints.down("sm")} {
		.paper-full-width {
			width: 100%;
			margin: 0;
		}
	}
`;

export const AddMemberModalHeader = styled(Box)`
	display: flex;
	padding: 0 16px 16px;
	margin-bottom: 16px;
	justify-content: space-between;
	border-bottom: 1px solid #edf1f7;
	h6 {
		font-size: 18px;
	}

	${props => props.theme.breakpoints.up("sm")} {
		padding-left: 32px;
		padding-right: 32px;
	}
`;

export const StyledFormContainer = styled.form`
	.form-column {
		&:first-child {
			margin-bottom: 8px;
		}
		&:last-child {
			margin-top: 8px;
		}
	}
	${props => props.theme.breakpoints.up("sm")} {
		.form-column {
			&:first-child {
				padding-right: 8px;
				margin: 0;
			}
			&:last-child {
				padding-left: 8px;
				margin: 0;
			}
		}
	}
`;

export const StyledFormColumns = styled(Box)`
	padding: 0 16px;

	display: flex;
	align-items: flex-start;

	${props => props.theme.breakpoints.up("sm")} {
		padding: 0 32px;
	}
`;

export const StyledInput = styled(Input)`
	.MuiFormControl-root {
		top: 2px;
	}

	.MuiInputBase-input {
		height: 24px;
	}
`;

export const StyledForm = styled.form`
	width: 100%;
`;

export const StyledFormColumn = styled(FormColumn)`
	width: 100%;
`;

export const StyledAutoComplete = styled(Autocomplete)<{ isNotInvited: boolean }>`
	.MuiOutlinedInput-notchedOutline {
		border-color: ${({ isNotInvited }) => (isNotInvited ? "#c5cee0" : "#ff463d")} !important;
	}

	.MuiInputLabel-outlined.MuiInputLabel-shrink {
		color: ${({ isNotInvited }) => (isNotInvited ? "#c5cee0" : "#ff463d")} !important;
	}
`;

export const StyledTextField = styled(TextField)<{ isInvited: boolean }>`
	.MuiChip-root {
		background: none;
	}

	.MuiInputBase-input {
		display: flex;
		align-items: center;
	}
`;

export const StyledChip = styled(Chip)<{ isInvited: boolean }>`
	span {
		color: ${({ isInvited }) => (isInvited ? "#222b45" : "#ff463d")};
		border: 1px solid ${({ isInvited }) => (isInvited ? "#c5cee0" : "#ff463d")};

		border-radius: 30px;
		padding-right: 20px;
		padding-top: 3px;
		padding-bottom: 3px;
	}

	.MuiChip-deleteIcon {
		width: 12px;
		height: 12px;
		margin: 0 5px 0 -17px;
		color: ${({ isInvited }) => (isInvited ? "#8f9bb3" : "#ff463d")};
	}
`;

export const StyledErrorMessage = styled(Box)`
	color: #ff463d;
	font-size: 12px;
	padding-top: 4px;
	margin-left: 20px;
`;

export const StyledFormFooter = styled(FormFooter)`
	border-top: 1px solid #edf1f7;
	padding: 8px 0;
	button {
		margin-right: 16px;
	}

	${props => props.theme.breakpoints.up("sm")} {
		button {
			margin-right: 32px;
		}
	}
`;

export const PaginationWrapper = styled(Box)`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	padding: 10px 24px;

	.MuiPagination-root {
		.MuiPagination-ul {
			li {
				&:first-child,
				&:last-child {
					background-color: #f0f3fe;

					button {
						color: #6173fe;
					}
				}

				.MuiButtonBase-root {
					color: #222b45;
				}

				.MuiPaginationItem-page.Mui-disabled {
					svg {
						color: #8f9bb3;
					}
				}

				.MuiPaginationItem-page.Mui-selected {
					color: #fff;
					background: #6173fe;
				}

				.MuiPaginationItem-icon {
					font-size: 1.8rem;
				}
			}
		}
	}

	.MuiToolbar-root {
		.MuiTablePagination-selectRoot {
			padding: 0 6px;
			background: #f7f9fc;
			display: flex;
			align-items: center;
		}

		.MuiSelect-select {
			color: #6173fe;
			text-align-last: center;
		}

		.MuiSelect-icon {
			right: 7px;
		}

		.MuiTablePagination-actions {
			display: none;
		}
	}
`;

export const StyledLoader = styled(Loader)`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 100px;
`;

export const StyledInvalidEmailAddressText = styled(Text)`
	font-size: 12px;
	color: #ff463d;
	margin-top: 4px;
	margin-left: 15px;
	margin-bottom: 0;
`;

export const StyledUserRoleInput = styled(Input)`
	.MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline {
		border-color: #e4e9f2;
	}
`;

export const SelectWrapper = styled(Box)`
	display: flex;

	.MuiSelect-select.Mui-disabled {
		padding-left: 0;
	}
`;

export const EmptyBody = styled(Box)`
	width: 100%;
	min-height: 600px;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	background: white;
`;

export const EmptyNote = styled(Text)`
	font-size: 18px;
	font-weight: bold;
	line-height: 1.33;
	letter-spacing: normal;
	text-align: center;
	color: #222b45;

	margin: 22px auto 0 auto;

	max-width: 220px;
`;

export const GuideNote = styled(Text)`
	color: #99a4ba;
`;
