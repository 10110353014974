export default ({ count, singular, plural }: { count: number; singular: string; plural: string }): string => {
	switch (count) {
		case 0: {
			return `No ${plural}`;
		}
		case 1: {
			return `${count} ${singular}`;
		}
		default: {
			return `${count} ${plural}`;
		}
	}
};
