import React, { memo, useEffect, useState } from "react";

import { PostBlockEventType } from "types";

import { FileType } from "types/FilesContextValuesType";

import { useFiles } from "shared/hooks";
import { formatSizeUnits } from "utils/serviceUtils/helpers";

import ItemTemplate from "./ItemTemplate";

import { PlaceholderImageType } from "../../types";
import { PlaceholderImage } from "../index";

interface Props {
	file?: FileType;
	fileId?: string;
	id?: string;
	onEvent?: (eventType: PostBlockEventType, id: string) => void;
	maxWidth?: boolean;
	link?: string;
	isMarketing?: boolean;
	postView?: boolean;
}

const FileBlock: React.FC<Props> = memo(({ file, fileId, maxWidth, id, onEvent, link, isMarketing, postView }) => {
	const [fileInfo, setFileInfo] = useState<FileType | undefined>(file);
	const [loading, setLoading] = useState(false);
	const [isFileDeleted, setIsFileDeleted] = useState(false);
	const { getFileInfoById } = useFiles(isMarketing);

	useEffect(() => {
		if (fileId) {
			(async () => {
				setLoading(true);
				const data = await getFileInfoById(fileId);
				if (data && !!Object.keys(data).length) {
					setFileInfo(data);
				} else {
					setIsFileDeleted(true);
				}
				setLoading(false);
			})();
		}
	}, [getFileInfoById, fileId]);

	return (
		<ItemTemplate
			link={link}
			id={id}
			wrapperProps={{
				maxWidth,
				id,
				onEvent,
				postView,
				"data-name": "file",
				"data-file": encodeURIComponent(
					JSON.stringify({
						...fileInfo
					})
				)
			}}
			loading={loading}
			onClose={() => onEvent && id && onEvent(PostBlockEventType.delete, id)}
			coverUrl={fileInfo?.coverPhoto?.url}
			title={fileInfo?.title}
			subtitle={formatSizeUnits(fileInfo?.file?.size)}
			placeholderImg={
				<PlaceholderImage type={PlaceholderImageType.FILE_PREVIEW} width={80} height={80} viewBox={"0 0 400 400"} />
			}
			isDeleted={isFileDeleted}
		/>
	);
});

export default FileBlock;
