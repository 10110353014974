import React, { FC, useMemo } from "react";

import { Controller, useForm } from "react-hook-form";

import { ReactComponent as ClockIcon } from "assets/icons/clock-filled.svg";
import { DateInput, LabeledInput, MemberDialog, RectangleUploader, SelectInput } from "modules/MemberHome/View/shared";
import { InputWrapper, MemberDialogContentWrapper } from "modules/MemberHome/View/shared/style";

import { useS3Uploader } from "shared/services/s3Uploader";

import { times } from "shared/types/times";
import { getDefaultTime } from "utils/getDefaultTime";

import { RequestMoneyViewProps } from "./ManageView";

import { InputsRowWrapper } from "../Event/style";

const RequestMoneyMemberView: FC<RequestMoneyViewProps> = ({ open, onClose, onSubmit, loading, onUploaderChange }) => {
	const { handleSubmit, formState, control, errors } = useForm({ mode: "onChange" });
	const { getData: getS3UploaderData } = useS3Uploader();
	const { loadingSignedUrl, uploading } = getS3UploaderData();

	const today = useMemo(() => new Date(), []);

	return (
		<MemberDialog
			title="Request Money"
			open={open}
			onClose={onClose}
			customWidth={512}
			footerPrimary={{
				text: "Create",
				disabled: !formState.isDirty || !formState.isValid || uploading || loadingSignedUrl || loading,
				onClick: handleSubmit(onSubmit)
			}}
		>
			<Controller
				name="imageUrl"
				control={control}
				render={({ onChange, value }) => (
					<RectangleUploader
						defaultPreviewUrl={value}
						uploading={uploading}
						onChange={file => onUploaderChange(file, onChange)}
					/>
				)}
			/>
			<MemberDialogContentWrapper className="pv-20">
				<Controller
					name="amountPaid"
					control={control}
					rules={{
						required: "Amount is required",
						min: { value: 0, message: "Amount cannot be negative" },
						max: { value: 99999, message: "Amount cannot be higher than $99,999" }
					}}
					render={({ onChange, value, ref }) => (
						<InputWrapper>
							<LabeledInput
								label="Amount"
								name="amountPaid"
								type="number"
								value={value}
								onChange={val => onChange(val)}
								placeholder="Amount"
								error={errors?.amountPaid?.message}
								inputRef={ref}
							/>
						</InputWrapper>
					)}
				/>
				<Controller
					name="purpose"
					control={control}
					rules={{
						required: "Reason field is required",
						minLength: { value: 3, message: "At least 3 characters are required" }
					}}
					render={({ onChange, value, ref }) => (
						<InputWrapper>
							<LabeledInput
								name="purpose"
								label="What is this for?"
								value={value}
								onChange={onChange}
								error={errors.purpose?.message}
								inputRef={ref}
							/>
						</InputWrapper>
					)}
				/>
				<InputsRowWrapper>
					<Controller
						name="dueDate"
						control={control}
						defaultValue={today}
						render={({ onChange, value }) => (
							<InputWrapper>
								<DateInput
									label="Due date *"
									name="date"
									value={value}
									onChange={onChange}
									placeholder=" "
									error={errors?.date?.message}
									displayOnly
								/>
							</InputWrapper>
						)}
					/>
					<Controller
						name="dueTime"
						control={control}
						defaultValue={getDefaultTime()}
						render={({ onChange, value }) => (
							<InputWrapper>
								<SelectInput
									label="Due time *"
									name="time"
									value={value && value.label}
									onChange={onChange}
									placeholder=" "
									options={times}
									error={errors?.time?.message}
									maxHeight={180}
									rightIcon={{
										el: <ClockIcon />,
										active: false
									}}
									displayOnly
								/>
							</InputWrapper>
						)}
					/>
				</InputsRowWrapper>
			</MemberDialogContentWrapper>
		</MemberDialog>
	);
};

export default RequestMoneyMemberView;
