import styled from "styled-components";

const Fields = styled.div``;

const Input = styled.input`
	width: 48px;
	height: 64px;
	margin-right: 24px;
	padding: 20px 20px 20px 19px;
	border-radius: 8px;
	border: 1px solid #e4e9f2;
	transition: border-color 0.2s ease-in-out;

	&:last-child {
		margin-right: 0;
	}

	&:focus {
		border-color: #151a30;
	}
`;

export { Fields, Input };
