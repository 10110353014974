import Button from "@material-ui/core/Button";
import Popover from "@material-ui/core/Popover";
import styled from "styled-components";

const DropdownButton = styled(Button)`
	min-width: 40px;
	max-width: 40px;
	height: 40px;
	margin: 0;
	padding: 0;
	border-radius: 8px;

	.MuiButton-label {
		display: flex;
		justify-content: center;
		align-items: center;

		& > svg {
			margin: 0 !important;
		}
	}
`;

const DropdownPopover = styled(Popover)`
	.MuiPopover-paper {
		padding: 8px;
		border-radius: 8px;
		min-width: 256px;
		box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12);
	}
`;

const DropdownItem = styled.div`
	font-weight: 600;
	border-radius: 8px;
	padding: 10px 12px;
	cursor: pointer;
	display: flex;
	align-items: center;

	&:hover {
		background-color: #f7f9fc;
	}
`;

const DropdownItemIcon = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 6px;
`;

export { DropdownButton, DropdownPopover, DropdownItem, DropdownItemIcon };
