import React, { FC } from "react";

import { ButtonBase } from "@material-ui/core";

import { Icon, Text } from "shared/ui-kit";

import { MessageImageWrapper } from "../styles";

const ImageWithOverlay: FC<{ handleOpenPreview: () => void; bg: JSX.Element; fileBubbleView?: boolean }> = ({
	handleOpenPreview,
	bg,
	fileBubbleView
}) => (
	<MessageImageWrapper>
		<MessageImageWrapper.Overlay className={fileBubbleView ? "fileBubbleView" : ""}>
			<ButtonBase onClick={handleOpenPreview}>
				<Icon name="eye" group="filled" width={20} height={20} />
				<Text>View {fileBubbleView ? "PDF" : "Image"}</Text>
			</ButtonBase>
		</MessageImageWrapper.Overlay>
		{bg}
	</MessageImageWrapper>
);

export default ImageWithOverlay;
