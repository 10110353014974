import { AxiosResponse } from "axios";

import BaseConverter from "shared/services/converters/baseConverter";

import { CategoryModel } from "shared/types";

const convertToApiBoard = (data: Partial<CategoryModel>) => ({
	...data,
	desc: data.description,
	imageCssClass: data.iconName
});

export const convertBoardsCount = (data: AxiosResponse<{ count: number }>): number =>
	BaseConverter.convert<{ count: number }>(data).count;

export const convertToCreateBoardRequest = (data: Partial<CategoryModel>) => ({
	payload: convertToApiBoard(data),
	path: "/v3/categories",
	method: "POST",
	token: null
});

export const convertCreateBoardResponse = (data: AxiosResponse<{ payload: CategoryModel }>): CategoryModel =>
	BaseConverter.convert<{ payload: CategoryModel }>(data).payload;

export const convertToUpdateBoardRequest = (data: CategoryModel) => ({
	payload: convertToApiBoard(data),
	path: "/v3/categories",
	method: "PUT",
	token: null
});

export const convertToDeleteBoardRequest = (data: CategoryModel) => ({
	path: `/v2/categories/${data.cid}`,
	method: "DELETE",
	token: null
});
