import React, { FC, useState } from "react";

import clsx from "clsx";

import { MemberDialog } from "modules/MemberHome/View/shared";

import { avatarUrlList } from "shared/constants";
import { Icon } from "shared/ui-kit";

import { AvatarWrapper, CheckboxWrapper, Wrapper } from "./style";

interface SelectAnimalAvatarModalProps {
	onClose: (imgUrl?: string) => void;
	defaultImgUrl?: string;
}

const SelectAnimalAvatarModal: FC<SelectAnimalAvatarModalProps> = ({ onClose, defaultImgUrl }) => {
	const [activeUrl, setActiveUrl] = useState<string | undefined>(defaultImgUrl);

	const toggleAvatar = imgUrl => {
		if (activeUrl === imgUrl) {
			setActiveUrl(undefined);
		} else {
			setActiveUrl(imgUrl);
			onClose(imgUrl);
		}
	};

	return (
		<>
			<MemberDialog title="Choose Avatar" open onClose={() => onClose(activeUrl)} customWidth={550} noFooter>
				<div className="grid grid-cols-3">
					{avatarUrlList.map((imgUrl, i) => (
						<Wrapper key={i} onClick={() => toggleAvatar(imgUrl)}>
							<CheckboxWrapper className={clsx(activeUrl === imgUrl && "active")}>
								<Icon name="check-circle" group="filled" fill={"#fff"} />
							</CheckboxWrapper>
							<AvatarWrapper style={{ backgroundImage: `url(${imgUrl})` }} />
						</Wrapper>
					))}
				</div>
			</MemberDialog>
		</>
	);
};

export default SelectAnimalAvatarModal;
