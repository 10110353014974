import { Box } from "@material-ui/core";

import styled from "styled-components";

import { Text } from "shared/ui-kit";

export const TitleContainer = styled(Box)`
	display: flex;
`;

export const ContentContainer = styled(Box)`
	width: 94%;
	margin-left: 4%;
	margin-right: 4%;
	padding-bottom: 24px;
`;

export const TitleText = styled(Text)`
	margin-left: 24px;
	font-size: 18px;
	color: #000000;
	letter-spacing: 0.1px;
`;

export const Wrapper = styled(Box)`
	display: flex;
	flex-direction: row;
	flex: 1;
	justify-content: space-between;
	margin: 30px 10px 0 0;
`;
