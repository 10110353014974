import { Box } from "@material-ui/core";
import styled from "styled-components";

export const FlexContainer = styled(Box)`
	display: flex;
	align-items: center;

	&.between {
		justify-content: space-between;
	}

	&.full {
		width: 100%;
	}

	&.flex-end {
		justify-content: flex-end;
	}

	.page-card {
		&:not(:last-child) {
			margin-right: 5px;
		}
	}

	.page-size-card {
		margin-right: 16px;
	}
`;

FlexContainer.Column = styled(Box)`
	width: 100%;

	display: flex;
	flex-direction: column;

	&.with-image {
		width: calc(100% - 56px);
		margin-left: 16px;
	}
`;
