import { useCallback, useMemo } from "react";

import { useNotification } from "shared/hooks";
import { CategoryModel } from "shared/types";

import { actionMethod, actionTypes, getValidationMessage } from "utils/getValidationMessage";

import { useBoardStore } from "../contexts";

import { useBoardApiService } from "../services";

const useBoards = () => {
	const boardApiService = useBoardApiService();
	const { showMessage } = useNotification();

	const store = useBoardStore();
	const { setState } = useBoardStore();

	const methods = useMemo(
		() => ({
			fetchBoards: async ({ limit = 20, keyword = "", offset, supportDefault = false }) => {
				return await boardApiService.getBoards(offset, limit, keyword, supportDefault);
			},
			getBoards: async ({ limit = 20, keyword = "", offset = 1, paginated = false }) => {
				setState(ctx => ({ ...ctx, isLoading: true }));
				const boards = await methods.fetchBoards({ limit, keyword, offset });
				setState(ctx => ({ ...ctx, isLoading: false }));

				if (boards) {
					setState(ctx => ({ boards: !paginated || offset === 1 ? boards : ctx.boards.concat(boards) }));
				}
			},
			followBoard: async (id: string, unfollow = false) => {
				return await boardApiService.followBoard(id, unfollow);
			},
			getBoard: async (id: string) => {
				try {
					return await boardApiService.getBoard(id);
				} catch {
					return null;
				}
			},
			getDefaultBoard: async ({ limit = 20, offset = 1 }) => {
				setState(ctx => ({ ...ctx, isLoading: true }));
				const boards = await methods.fetchBoards({ limit, offset, supportDefault: true });
				setState(ctx => ({ ...ctx, isLoading: false }));

				if (boards) {
					setState(ctx => ({ ...ctx, defaultBoard: boards[0] }));
				}
			},
			createBoard: async (board: CategoryModel) => {
				setState(ctx => ({ ...ctx, isLoading: true }));
				const data = await boardApiService.createBoard(board);
				if (data) {
					setState(ctx => ({
						...ctx,
						isLoading: false
					}));

					if (board.default) {
						setState(ctx => ({
							...ctx,
							defaultBoard: data
						}));
					}
				}
				showMessage(
					getValidationMessage({
						name: "Board",
						actionType: actionTypes.CRUD,
						actionMethod: actionMethod.created,
						emoji: "🎉"
					}),
					3
				);
			},
			async updateBoard(board: CategoryModel) {
				setState(ctx => ({ ...ctx, isLoading: true }));
				const data = await boardApiService.updateBoard(board);
				if (data) {
					setState(ctx => ({
						...ctx,
						isLoading: false,
						boards: ctx.boards?.map(boardItem => {
							if (boardItem.cid === board.cid) {
								return {
									...boardItem,
									...board
								};
							}
							return boardItem;
						}),
						defaultBoard: board.default ? board : ctx?.defaultBoard?.cid === board.cid ? undefined : ctx.defaultBoard
					}));

					showMessage(
						getValidationMessage({
							name: "Board",
							actionType: actionTypes.CRUD,
							actionMethod: actionMethod.updated,
							emoji: "✨"
						}),
						3
					);
				}
			},
			deleteBoard: async (board: CategoryModel) => {
				const data = await boardApiService.deleteBoard(board);
				if (data) {
					setState(ctx => ({
						...ctx,
						boards: ctx.boards?.filter(boardItem => boardItem.cid !== board.cid),
						boardsCount: (ctx.boardsCount || 1) - 1
					}));
					showMessage(
						getValidationMessage({
							name: "Board",
							actionType: actionTypes.CRUD,
							actionMethod: actionMethod.deleted,
							emoji: "🗑"
						}),
						3
					);
				}
			},
			async getBoardsCount({ keyword = "" }) {
				const boardsCount = await boardApiService.getBoardsCount(keyword);
				setState(ctx => ({ ...ctx, boardsCount }));
			},
			setPage: (page: number) => {
				setState(ctx => ({ ...ctx, page }));
			},
			setBoardsPerPage: (boardsPerPage: number) => {
				setState(ctx => ({ ...ctx, boardsPerPage }));
			},
			clear() {
				setState(ctx => ({ ...ctx, boardsCount: undefined, boards: undefined }));
			}
		}),
		[boardApiService, setState, showMessage]
	);

	const getData = useCallback(() => {
		return store;
	}, [store]);

	return { ...methods, getData };
};

export default useBoards;
