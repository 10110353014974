import React, { ReactNode, useEffect, useRef, useState } from "react";

import ReactDOM from "react-dom";

interface PostPortalProps {
	children: ReactNode;
	id: string;
}

const PostPortal: React.FC<PostPortalProps> = React.memo(({ children, id }) => {
	const el = useRef(document.getElementById(id) || document.createElement("div"));
	const [dynamic] = useState(!el.current.parentElement);

	useEffect(() => {
		if (dynamic) {
			el.current.id = id;
			document.body.appendChild(el.current);
		}
		return () => {
			if (dynamic && el.current.parentElement) {
				// eslint-disable-next-line
				el.current.parentElement.removeChild(el.current);
			}
		};
		// eslint-disable-next-line
	}, [id]);

	return ReactDOM.createPortal(children, el.current);
});

export default PostPortal;
