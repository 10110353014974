import { Box } from "@material-ui/core";
import styled from "styled-components";

import { ReactComponent as VyooLogo } from "assets/icons/vyoo-logo.svg";
import AkinaLogo from "assets/images/akinaLogoNew.png";
import BitcoinLogo from "assets/images/bitcoin-logo.webp";
import ReliasLogo from "assets/images/relias.webp";
import { Button } from "shared/ui-kit";
import colors from "theme/colors";
import theme, { customBreakpoints } from "theme/default";

export const PageWrapper = styled(Box)`
	height: 100vh;
`;

export const TopBar = styled.header`
	width: 100%;
	height: 64px;
	position: sticky;
	top: 0;
	z-index: 1200;

	display: flex;
	align-items: center;

	backdrop-filter: blur(20px);
	box-shadow: 0 0.5px 0 0 ${theme.palette.colors.basic[500]};
	background-color: white;

	${props => props.theme.breakpoints.down(customBreakpoints.tabletHorizontalMax)} {
		height: 72px;
	}

	&.workspace-kijijimoms {
		height: 96px;

		background: #a0a0f2;

		box-shadow: none;

		backdrop-filter: none;

		.header {
			&__container {
				width: 1190px;
				height: 100%;

				margin: 0 auto;
				padding: 0;
			}

			&__action-icon-wrapper {
				width: 40px;
				height: 40px;

				padding: 2px;

				margin: 0 9px;
			}

			&__left-side {
				margin: 0 0 0 -8px;
				padding: 0;
			}

			&__right-side {
				margin: 0 -16px 0 0;
				padding: 0;
			}

			&__create-cmt-btn {
				background-color: #a20080;
				color: white;
			}
		}

		.profile__name {
			color: white;
		}

		.akina-logo {
			display: block;

			width: 190px;
			height: 80px;

			background-image: url(${AkinaLogo});
			background-position: center;
			background-repeat: no-repeat;
			background-size: contain;
		}
	}

	&.workspace-holliblu {
		.relias-logo {
			display: block;

			width: 170px;
			height: 46px;

			background-image: url(${ReliasLogo});
			background-position: center;
			background-repeat: no-repeat;
			background-size: contain;

			${props => props.theme.breakpoints.down("sm")} {
				width: 120px;
				height: 34px;

				margin-left: 10px;
			}
		}
	}

	&.workspace-bitcoin {
		.header__left-side {
			${props => props.theme.breakpoints.down("sm")} {
				display: block;
			}
		}

		.bitcoin-logo {
			display: block;

			width: 55px;
			height: 50px;

			background-image: url(${BitcoinLogo});
			background-position: center;
			background-repeat: no-repeat;
			background-size: contain;

			${props => props.theme.breakpoints.down("sm")} {
				width: 55px;
				height: 50px;

				margin-left: 10px;
			}
		}
	}
`;

export const TopBarContainer = styled(Box)`
	width: 100%;

	margin: 0 auto;

	padding: 12px 8px;
	display: flex;
	align-items: center;

	.left-side {
		display: flex;
		flex: 2 1 auto;

		${props => props.theme.breakpoints.down("sm")} {
			display: none;
		}
	}

	.right-side {
		display: flex;
		justify-content: flex-end;
		margin-left: auto;
		min-width: 480px;

		${props => props.theme.breakpoints.down("sm")} {
			min-width: 0;
		}

		button {
			margin: auto 10px;

			&:last-child {
				margin-right: 0;
			}
		}
	}

	.middle {
		flex: 2 1 auto;
		transform: translateX(30%);

		${props => props.theme.breakpoints.down("sm")} {
			min-width: 0;
			transform: translateX(0%);

			> div {
				width: -webkit-fill-available;
			}

			.MuiInputBase-input {
				width: 100%;
			}
		}
	}

	.brand {
		display: flex;

		&-meta {
			margin-left: 12px;
		}
	}
`;

export const CreateCmtButton = styled(Button)`
	background-color: ${colors.palette.colors.purple};
`;

export const Main = styled.main`
	overflow: auto;
	background-color: ${theme.palette.colors.basic[200]};
	display: flex;
	justify-content: space-between;
`;

export const CommunityBlockWrapper = styled(Box)`
	width: 300px;

	padding: 10px 10px;

	cursor: pointer;

	&:hover {
		background: #edf1f7;
	}
`;

export const LaunchBtn = styled(Button)`
	width: 300px;
	display: flex;

	&:not(:last-child) {
		margin-bottom: 10px;
	}
`;

export const StyledVyooLogo = styled(VyooLogo)`
	&.sports-mode {
		#vyoo-logo-v-wrapper-id {
			fill: ${colors.palette.colors.green["400"]};
		}
	}
`;
