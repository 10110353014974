import React, { FC } from "react";

import { InputAdornment } from "@material-ui/core";
import { TimePicker } from "@material-ui/pickers";

import { ReactComponent as CalendarIcon } from "assets/icons/clock.svg";

import { InputWrapper } from "./style";

import { LabelInputProps } from "../LabeledInput";

interface TimeInputProps extends LabelInputProps {
	minDate?: Date;
	acceptPast?: boolean;
}

const TimeInput: FC<TimeInputProps> = ({ onChange, value, label, error, placeholder }) => (
	<InputWrapper>
		{label && <InputWrapper.Label>{label}</InputWrapper.Label>}
		<TimePicker
			placeholder={placeholder || "Select Time"}
			autoOk
			inputVariant="outlined"
			variant="inline"
			label=" "
			value={value}
			onChange={d => onChange(d)}
			error={!!error}
			helperText={error}
			InputProps={{
				endAdornment: (
					<InputAdornment position="end">
						<CalendarIcon />
					</InputAdornment>
				)
			}}
		/>
	</InputWrapper>
);

export default TimeInput;
