import { Box } from "@material-ui/core";
import styled from "styled-components";

export const PlayerContainer = styled(Box)`
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	backdrop-filter: blur(20px);
	box-shadow: 0 2px 4px 0 rgb(0 0 0 / 8%), 0 -0.5px 0 #c6cfe0;
	background-color: #fff;
	z-index: 1500;
	padding: 18px 0;
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const Player = styled(Box)`
	width: 100%;
	max-width: 960px;
	margin: auto;
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

Player.TrackInfo = styled(Box)`
	display: flex;
	align-items: center;
	text-align: left;
	width: 50%;
	cursor: pointer;
	h6 {
		max-width: 120px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		font-weight: 600;
		line-height: 1.5;
		${props => props.theme.breakpoints.up("md")} {
			max-width: 150px;
		}
	}
	.artistName {
		color: #8f9bb3;
	}
	.track-no-img {
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: #edf1f7;
		svg {
			fill: #c5cee0;
		}
	}
	img,
	.track-no-img {
		flex-shrink: 0;
		margin: 0 16px;
		width: 40px;
		height: 40px;
		border-radius: 8px;
		object-fit: cover;
	}
	video {
		flex-shrink: 0;
		margin: 0 16px;
		width: 65px;
		height: 40px;
		border-radius: 8px;
		object-fit: cover;
	}

	${props => props.theme.breakpoints.up("sm")} {
		width: 220px;
		margin-right: 20px;
	}
`;

Player.TrackControls = styled(Box)`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	padding-right: 16px;
	width: 50%;
	button {
		margin: 0 8px;
		&.hideMobile {
			display: none;
		}
		&.noMl {
			margin-left: 0;
		}
		&.noMr {
			margin-right: 0;
		}

		&.active svg {
			fill: ${({ bgColor }) => bgColor};
		}
	}

	svg {
		fill: #8f9bb3;
		&.fw {
			fill: white;
		}
		&.flip-svg {
			transform: rotate(180deg);
		}
	}
	${props => props.theme.breakpoints.up("sm")} {
		width: auto;
		button.hideMobile {
			display: block;
		}
	}
`;

Player.CurrentTrack = styled(Box)`
	display: none;
	${props => props.theme.breakpoints.up("sm")} {
		padding: 0 16px;
		flex: 1;
		display: flex;
		align-items: center;
		input {
			flex: 1;
			height: 3px;
			border-radius: 46px;
			border: 0;
			margin: 0 12px;
			background-color: #e4e9f2;
			transition: all ease-in-out 0.2s;
		}
		.duration {
			font-size: 12px;
			font-weight: 600;
			line-height: 1.33;
			&.played {
				color: ${({ bgColor }) => bgColor};
			}
		}
		.input-range {
			width: 80%;
			margin: auto;
		}
		.input-range__slider {
			background-color: ${({ bgColor }) => bgColor} !important;
			border-color: ${({ bgColor }) => bgColor} !important;
		}
		.input-range__track {
			background-color: #e4e9f2 !important;
		}
		.input-range__track--active {
			background-color: ${({ bgColor }) => bgColor} !important;
		}
		.input-range__label-container {
			display: none;
		}
	}
`;

export const SoundControlWrapper = styled(Box)`
	background-color: white;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
	box-shadow: inset 0 0 0 0.5px #c5cee0;
	position: absolute;
	bottom: 30px;
	left: 0px;
	border-radius: 4px;
	padding: 10px 0;
`;
