import React, { FC, memo } from "react";

import { IconWrapper } from "./style";

export enum FillOptionsEnum {
	SVG = "svg",
	FIRST_PATH = "firstPath",
	LAST_PATH = "lastPath",
	ALL_PATHS = "allPaths",
	ALL_RECTS = "allRects"
}

interface IconRendererProps {
	children: JSX.Element;
	fillOptions?: FillOptionsEnum;
	fillColor?: string;
	strokeColor?: string;
	className?: string;
}

const IconRenderer: FC<IconRendererProps> = memo(
	({ children, fillOptions = FillOptionsEnum.SVG, fillColor, strokeColor, className }) => (
		<IconWrapper fillColor={fillColor} fillOptions={fillOptions} strokeColor={strokeColor} className={className}>
			{children}
		</IconWrapper>
	)
);

export default IconRenderer;
