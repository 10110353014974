import styled from "styled-components";

import { Avatar, Box, Button, Text } from "shared/ui-kit";

export const ContentBlock = styled(Box)`
	display: block;

	padding-bottom: 24px;
`;

export const Back = styled(Box)<{ coverImgUrl?: string }>`
	width: 100%;
	height: 400px;

	position: relative;

	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;

	display: flex;
	justify-content: center;
	align-items: center;

	.banner-image {
		position: absolute;

		top: 0;
		left: 0;

		width: 100%;
		height: 100%;
	}

	&:after {
		content: "";
		position: absolute;
		top: 0;
		left: 0;

		width: 100%;
		height: 100%;

		background-image: url(${({ coverImgUrl }) => coverImgUrl || ""});
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;

		${props => props.theme.breakpoints.up("lg")} {
			border-bottom-left-radius: 4px;
			border-bottom-right-radius: 4px;
		}

		z-index: 1;
	}

	${props => props.theme.breakpoints.down("sm")} {
		height: 120px;
	}
`;

export const StyledAvatar = styled(Avatar)`
	width: 170px !important;
	height: 170px !important;
	border-radius: 50%;
	background: #f0faff;
	overflow: hidden;
	position: absolute !important;
	bottom: -85px;
	left: calc(50% - 85px);
	border: 5px solid white;
	font-size: 40px;
	z-index: 5;

	${props => props.theme.breakpoints.down("sm")} {
		width: 80px !important;
		height: 80px !important;

		bottom: -40px;
		left: calc(50% - 40px);

		font-size: 32px;

		border-width: 3px;
	}
`;

export const InfoWrapper = styled(Box)`
	display: flex;
	flex-direction: column;
	align-items: center;

	margin-top: 80px;

	${props => props.theme.breakpoints.down("sm")} {
		margin-top: 36px;
	}
`;

export const Title = styled(Text)`
	font-size: 32px;
	font-weight: bold;
	line-height: 1.25;
	color: #222b45;

	margin-top: 16px;

	${props => props.theme.breakpoints.down("sm")} {
		font-size: 22px;
	}
`;

export const SubTitle = styled(Text)`
	font-size: 18px;
	font-weight: bold;
	line-height: 1.33;
	text-align: center;
	color: #8f9bb3;

	margin-top: 9px;

	${props => props.theme.breakpoints.down("sm")} {
		font-size: 16px;
		margin-top: 4px;
	}
`;

export const PrivateBlock = styled(Box)`
	display: flex;
	flex-direction: column;
	align-items: center;

	margin: 16px auto 0 auto;

	width: calc(100% - 32px);

	padding: 32px 30px 44px 30px;

	border-radius: 8px;

	background-color: #f7f9fc;
`;

PrivateBlock.LockIconWrapper = styled(Box)`
	display: flex;
	justify-content: center;

	path {
		fill: #6173fe;
	}
`;

PrivateBlock.Title = styled(Text)`
	font-size: 15px;
	font-weight: 600;
	line-height: 1.6;
	letter-spacing: normal;
	text-align: center;
	color: #222b45;

	margin-top: 8px;
`;

PrivateBlock.Text = styled(Text)`
	font-weight: normal;
	line-height: 1.47;
	letter-spacing: normal;
	text-align: center;
	color: #222b45;

	margin-right: 8px;
`;

export const ManageBtnList = styled(Box)`
	display: flex;
	justify-content: center;
	align-items: center;

	margin: 21px auto 0 auto;

	${props => props.theme.breakpoints.down("sm")} {
		justify-content: center;
		order: 0;
	}
`;

export const JoinButton = styled(Button)`
	margin: 0 0 0 10px;

	path {
		fill: #ffffff;
	}

	${props => props.theme.breakpoints.down("sm")} {
		width: 120px;

		margin: 0;
	}
`;

export const StyledButton = styled(Button)`
	border-radius: 8px;

	margin: 0;
	padding: 10px 70px;
`;
