const success = {
	100: "#ddfcd5",
	200: "#b5faad",
	300: "#82f181",
	400: "#60e36b",
	500: "#30d14d",
	600: "#23b34a",
	700: "#189646",
	800: "#0f7940",
	900: "#09643c"
};

export default success;
