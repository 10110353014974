import config from "config/appConfig";
import AlbumContextValuesType from "types/AlbumContextValuesType";

import { IStateProps } from "utils/StateOf";

import createStore from "utils/createStore";

const localStorageKey = `${config.GLOBAL_CONSTANTS.LOCAL_STORAGE_PREFIX}${config.HOOK_STORAGE_KEYS.ALBUM_DATA}`;

export const initialState: IStateProps<AlbumContextValuesType> = {
	isLoading: false,
	loadingData: false,
	albumCreateOpen: false,
	page: 1,
	albums: [],
	albumsShowPerPage: 20,
	editAlbumDetails: null,
	albumDetailsDialog: {
		open: false,
		deletedTracks: []
	},
	submitting: false,
	trackDownloaded: false,
	totalAlbumsCount: 0,
	tracksChanged: false,
	trackProgressDialog: []
};

export default createStore<AlbumContextValuesType>({
	initialState,
	persistant: true,
	persistantName: localStorageKey
});
