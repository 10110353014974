export const EmailTemplateItems = [
	{
		title: "Invitation email",
		description: "Every time you will invite people to join your community they will receive this email.",
		templateType: "invitationEmailTemplate",
		id: 1,
		expandId: "expandInvitationEmail",
		emailSubjectId: "emailSubjectInvitationEmail",
		emailBodyId: "emailBodyInvitationEmail",
		emailCancelId: "cancelInvitationEmail",
		emailSaveChangeId: "saveChangesInvitationEmail"
	},
	{
		title: "Invite a friend template (In-App)",
		description:
			"Every time community member will invite other people to join your community they will see this email template which they can customize.",
		templateType: "invitationInAppTemplate",
		id: 2,
		expandId: "expandInviteFriend",
		emailSubjectId: "emailSubjectInviteFriend",
		emailBodyId: "emailBodyInviteFriend",
		emailCancelId: "cancelInviteFriend",
		emailSaveChangeId: "saveChangesInviteFriend"
	}
];

export const Texts = {
	mainHeading: "Email Templates",
	description: "Customize emails for community members"
};
