import React, { ReactNode } from "react";

import { Avatar, useMediaQuery } from "@material-ui/core";

import { PrimaryMemberButton } from "modules/MemberHome/View/shared";
import { SkeletonBase } from "shared/Components";
import { useUser } from "shared/hooks";
import { Button } from "shared/ui-kit";
import * as appTheme from "theme/default";
import getCountMessage from "utils/getCountMessage";

import { Item, Places } from "./styles";

interface Props {
	coverUrl?: string;
	previewIcon?: ReactNode;
	pretitle?: string;
	title?: string;
	subtitle?: string;
	memberList?: any[];
	totalMembers?: number;
	onSelect?: () => void;
	loading?: boolean;
	placeholderImg?: ReactNode;
	infoBlockClassName?: string;
}

const SelectItemTemplate: React.FC<Props> = ({
	coverUrl,
	previewIcon,
	pretitle,
	title,
	subtitle,
	memberList,
	totalMembers,
	onSelect,
	loading,
	placeholderImg,
	infoBlockClassName
}) => {
	const isMobile = useMediaQuery(appTheme.default.breakpoints.down("sm"));

	const { getData: getUserData } = useUser();
	const { isMemberView } = getUserData();

	return (
		<Item.Wrapper onClick={isMobile ? onSelect : undefined}>
			<Item.MainBody>
				<Item.AvatarWrapper>
					{loading ? (
						<SkeletonBase variant={"rect"} />
					) : !coverUrl && placeholderImg ? (
						placeholderImg
					) : (
						<Item.Avatar img={coverUrl}>{!coverUrl && (previewIcon || <Places />)}</Item.Avatar>
					)}
				</Item.AvatarWrapper>
				<Item.InfoBlock isMemberView={isMemberView} className={infoBlockClassName}>
					{loading ? (
						<>
							<SkeletonBase width={100} height={20} />
							<SkeletonBase width={200} height={28} />
							<SkeletonBase width={150} height={20} />
						</>
					) : (
						<>
							{pretitle && <Item.Pretitle>{pretitle}</Item.Pretitle>}
							{title && <Item.Title>{title}</Item.Title>}
							{subtitle && <Item.Subtitle>{subtitle}</Item.Subtitle>}
							{!!memberList?.length && (
								<Item.Members>
									{memberList.slice(0, 5).map(m => (
										<>
											<Avatar className="member-avatar" key={m.bbUserId} /> &nbsp;
										</>
									))}
									<Item.MembersCounter>{getCountMessage(totalMembers || 0, "member")} here</Item.MembersCounter>
								</Item.Members>
							)}
						</>
					)}
				</Item.InfoBlock>
			</Item.MainBody>
			<Item.ButtonWrapper>
				{loading ? (
					<SkeletonBase fullScale width={80} height={40} />
				) : isMemberView ? (
					<PrimaryMemberButton onClick={onSelect}>Select</PrimaryMemberButton>
				) : (
					<Button size="medium" onClick={onSelect}>
						Select
					</Button>
				)}
			</Item.ButtonWrapper>
		</Item.Wrapper>
	);
};

export default SelectItemTemplate;
