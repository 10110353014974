import styled, { css } from "styled-components";

import { Box } from "shared/ui-kit";

export const SliderWrapper = styled(Box)<{ itemSize: number }>`
	width: 100%;

	.slick {
		&-slider {
			height: 100%;
			width: 100%;
		}

		&-list {
			height: 100%;
			padding: ${({ slickPadding }) => (slickPadding ? "0 0 16px 4px" : 0)};
		}

		&-track {
			height: 100%;
		}

		&-slide {
			${({ minItemSize, itemSize }) =>
				minItemSize
					? css`
							min-width: ${minItemSize}px;
							max-width: ${itemSize + 16}px;
					  `
					: css`
							width: ${({ itemSize }) => `${itemSize + 16}px`};
					  `}
			height: 100%;

			& > div {
				height: 100%;
				width: calc(100% - 16px);
			}
		}
	}
`;

export const NavIcon = styled(Box)<{ isNext: boolean; show: boolean }>`
	width: 32px;
	height: 32px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	border-radius: 50%;
	position: absolute;
	top: calc(50% - 16px);
	box-shadow: inset 0 0 0 0.5px #fff;
	z-index: 5;
	transition: opacity 0.25s ease-in-out;
	opacity: ${({ show }) => (show ? 1 : 0)};
	pointer-events: ${({ show }) => (show ? "auto" : "none")};

	backdrop-filter: blur(20px);
	background-color: rgba(0, 0, 0, 0.24);

	${({ isNext }) =>
		isNext
			? css`
					right: 16px;
			  `
			: css`
					left: 16px;
			  `}

	svg {
		path {
			&:nth-child(2) {
				fill: white;
			}
		}
	}
`;
