import React, { Suspense, lazy, memo, useCallback, useEffect, useMemo, useRef, useState } from "react";

import { ForumPostModel, PostScheduleType } from "types";

import { EnableOnVisible } from "shared/Components";
import { Post } from "shared/Organisms";
import { Wrapper } from "shared/Organisms/Post/style";
import { useCommunity, useFeature, useOnScreen, usePopulateWithAds, usePosts } from "shared/hooks";

import { AdWrapper } from "shared/styles";
import { AdZone } from "utils/ads/getRandomAd";

import {
	Albums,
	Episodes,
	Files,
	GetStartedBlock,
	PostSkeleton,
	Series,
	SeriesCategories,
	SmartBoxItemWrapper
} from "../../Components";

const History = lazy(() => import("../../Components/History"));
const Events = lazy(() => import("../../Components/Events"));
const Groups = lazy(() => import("../../Components/Groups"));
const Matches = lazy(() => import("../../Components/Matches"));

import LiveConvoWidget from "../../Components/LiveConvoWidget/Widget";

export interface MainSectionProps {
	routes: any;
}

const MainSection: React.FC<MainSectionProps> = memo(({ routes }) => {
	const { getData: getCommunityData } = useCommunity();
	const { workspace } = getCommunityData();

	const { getPosts, getPostCount, getData: getPostsData } = usePosts();
	const { posts, postCount, loading } = getPostsData();

	const { isFeatureEnabled } = useFeature();

	const loaderRef = useRef<HTMLDivElement>(null);
	const scrolledToBottom = useOnScreen(loaderRef);

	const [offset, setOffset] = useState(1);

	const postsWithAds = usePopulateWithAds({
		list: posts,
		zone: AdZone.postZone,
		every: 7,
		skip: 3
	});

	useEffect(() => {
		getPosts({
			postType: PostScheduleType.Posted,
			limit: 7,
			offset,
			paginate: true,
			extraRepliesCount: 3
		});
	}, [workspace?.communityUrl, getPosts, offset]);

	useEffect(() => {
		setOffset(1);
		getPostCount({ postType: PostScheduleType.Posted });
	}, [workspace?.communityUrl, getPostCount]);

	useEffect(() => {
		document.onreadystatechange = () => {
			scrollTo(0, 0);
		};
	}, []);

	useEffect(() => {
		if (scrolledToBottom && !loading && postCount > postsWithAds.length) {
			setOffset(n => n + 1);
		}
	}, [loading, postCount, postsWithAds.length, scrolledToBottom]);

	const forumEnabled = useMemo(() => {
		return isFeatureEnabled("enableForum");
	}, [isFeatureEnabled]);

	const postBoardLink = useMemo(() => `${routes?.member.board.getPath()}/`, [routes]);
	const postGroupLink = useMemo(() => `${routes?.member.group.getPath()}/`, [routes]);

	const renderPost = useCallback(
		(post: string | ForumPostModel) =>
			typeof post === "string" ? (
				<Wrapper className="fullMode">
					<AdWrapper className="post_ad--wrapper" dangerouslySetInnerHTML={{ __html: post }} />
				</Wrapper>
			) : (
				<Post boardPathPrefix={postBoardLink} groupPathPrefix={postGroupLink} fullMode post={post} />
			),
		[postBoardLink, postGroupLink]
	);

	return (
		<>
			{isFeatureEnabled("enableStories") && (
				<Suspense fallback={null}>
					<SmartBoxItemWrapper>
						<History />
					</SmartBoxItemWrapper>
				</Suspense>
			)}
			<SmartBoxItemWrapper>
				<GetStartedBlock />
			</SmartBoxItemWrapper>
			<SmartBoxItemWrapper>
				<LiveConvoWidget />
			</SmartBoxItemWrapper>
			{forumEnabled && !!postsWithAds.length && (
				<EnableOnVisible key={typeof postsWithAds[0] === "string" ? postsWithAds[0] : postsWithAds[0].pid}>
					<SmartBoxItemWrapper noValidate>{renderPost(postsWithAds[0])}</SmartBoxItemWrapper>
				</EnableOnVisible>
			)}
			<EnableOnVisible>
				<SmartBoxItemWrapper>
					<Albums />
				</SmartBoxItemWrapper>
			</EnableOnVisible>
			<EnableOnVisible>
				<SmartBoxItemWrapper>
					<Files routes={routes} />
				</SmartBoxItemWrapper>
			</EnableOnVisible>
			{isFeatureEnabled("enableEvents") && (
				<Suspense fallback={null}>
					<EnableOnVisible>
						<SmartBoxItemWrapper>
							<Events routes={routes} />
						</SmartBoxItemWrapper>
					</EnableOnVisible>
				</Suspense>
			)}
			{forumEnabled && postsWithAds.length >= 2 && (
				<EnableOnVisible key={typeof postsWithAds[1] === "string" ? postsWithAds[1] : postsWithAds[1].pid}>
					<SmartBoxItemWrapper noValidate>{renderPost(postsWithAds[1])}</SmartBoxItemWrapper>
				</EnableOnVisible>
			)}
			{isFeatureEnabled("enableGroups") && (
				<Suspense fallback={null}>
					<EnableOnVisible>
						<SmartBoxItemWrapper>
							<Groups groupsPageUrl={routes?.member.groups.getPath()} />
						</SmartBoxItemWrapper>
					</EnableOnVisible>
				</Suspense>
			)}
			{isFeatureEnabled("matchingEnabled") && (
				<Suspense fallback={null}>
					<EnableOnVisible>
						<SmartBoxItemWrapper>
							<Matches />
						</SmartBoxItemWrapper>
					</EnableOnVisible>
				</Suspense>
			)}
			{forumEnabled && postsWithAds.length >= 3 && (
				<EnableOnVisible key={typeof postsWithAds[2] === "string" ? postsWithAds[2] : postsWithAds[2].pid}>
					<SmartBoxItemWrapper noValidate>{renderPost(postsWithAds[2])}</SmartBoxItemWrapper>
				</EnableOnVisible>
			)}
			<EnableOnVisible>
				<SmartBoxItemWrapper>
					<Episodes />
				</SmartBoxItemWrapper>
			</EnableOnVisible>
			{isFeatureEnabled("matchingEnabled") && (
				<Suspense fallback={null}>
					<EnableOnVisible>
						<SmartBoxItemWrapper>
							<Matches recommended />
						</SmartBoxItemWrapper>
					</EnableOnVisible>
				</Suspense>
			)}
			<EnableOnVisible>
				<SmartBoxItemWrapper>
					<Episodes recent />
				</SmartBoxItemWrapper>
			</EnableOnVisible>
			{forumEnabled && postsWithAds.length >= 4 && (
				<EnableOnVisible key={typeof postsWithAds[3] === "string" ? postsWithAds[3] : postsWithAds[3].pid}>
					<SmartBoxItemWrapper noValidate>{renderPost(postsWithAds[3])}</SmartBoxItemWrapper>
				</EnableOnVisible>
			)}

			<EnableOnVisible>
				<SmartBoxItemWrapper>
					<SeriesCategories seriesPageUrl={routes?.member.collections.getPath()} />
				</SmartBoxItemWrapper>
			</EnableOnVisible>

			<EnableOnVisible>
				<SmartBoxItemWrapper>
					<Series seriesPageUrl={routes?.member.collections.getPath()} />
				</SmartBoxItemWrapper>
			</EnableOnVisible>

			<EnableOnVisible>
				<SmartBoxItemWrapper>
					<Series popular seriesPageUrl={routes?.member.collections.getPath()} />
				</SmartBoxItemWrapper>
			</EnableOnVisible>

			{forumEnabled && (
				<>
					{postsWithAds.slice(4, postsWithAds.length).map(post => (
						<EnableOnVisible key={typeof post === "string" ? post : post!.pid}>
							<SmartBoxItemWrapper noValidate>{renderPost(post)}</SmartBoxItemWrapper>
						</EnableOnVisible>
					))}
					<div ref={loaderRef} />
					{postCount > postsWithAds.length &&
						Array.from(Array(2).keys()).map((_, index) => (
							<SmartBoxItemWrapper key={index}>
								<PostSkeleton />
							</SmartBoxItemWrapper>
						))}
				</>
			)}
		</>
	);
});

export default MainSection;
