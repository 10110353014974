import React, { useEffect, useMemo, useState } from "react";

import styled from "styled-components";

import { TypeCard } from "modules/Community/View/Components";
import { useCommunity } from "shared/hooks";

import { CommunityType } from "shared/types";
import { Box, Text } from "shared/ui-kit";

import useConfirmLeavePage from "../ConfirmLeave/hooks/useConfirmLeavePage";
import SubmitButton from "../SubmitButton";

export interface AboutProps {}

export interface GroupType {
	id: number;
	iconUrl: string;
	title: string;
	active?: boolean;
	description?: string;
	imageUrl?: string;
}

const ContentForm = styled.section`
	display: flex;
	align-items: flex-end;
	flex: 1;
	justify-content: space-between;

	${props => props.theme.breakpoints.down("sm")} {
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
	}
`;

const TypeListWrapper = styled.section`
	margin-top: 28px;
`;

const TypeCardWrapper = styled(Box)`
	display: inline-block;
	margin: 0 7px 6px 0;
	width: 40%;

	cursor: pointer;

	${props => props.theme.breakpoints.down("sm")} {
		width: 100%;
	}
`;

const Title = styled(Text)`
	margin-top: 12px;
`;

const Wrapper = styled(Box)``;

const CommunityTypes: React.FC<AboutProps> = () => {
	const { getTypeList, updateWorkspaceMeta, getData: getCommunityData } = useCommunity();
	const { workspace, typeList, isSportsMode } = getCommunityData();

	const [types, setTypes] = useState<CommunityType[]>([]);
	const { provideHandleCloseValues } = useConfirmLeavePage({});

	const onSave = async () => {
		await updateWorkspaceMeta({
			meta: {
				types: types.filter(type => type.active)
			}
		});
	};
	const toggleSelectedType = itemType => {
		const updated = types.map(communityType => {
			communityType.active = itemType === communityType.type;
			return communityType;
		});
		setTypes([...updated]);
		provideHandleCloseValues(true);
	};

	const mappedTypes = useMemo(() => {
		if (workspace && typeList) {
			return typeList.map((x, i) => ({
				id: i,
				...x,
				active: !!workspace?.meta?.types?.find(st => st.type === x.type)
			}));
		}
	}, [typeList, workspace]);

	const isDirty = useMemo(() => {
		const selected = types
			.filter(type => type.active)
			.reduce(
				(prev, current) => {
					return current;
				},
				{ type: "" }
			);
		if (selected?.type && !workspace?.meta?.types) {
			return true;
		}
		if (!workspace?.meta?.types || workspace.meta.types[0].type === selected.type) {
			return false;
		}
		const initial = workspace.meta.types
			.filter(type => type.active)
			.reduce(
				(prev, current) => {
					return current;
				},
				{ type: "" }
			);
		return selected.type !== initial.type;
	}, [workspace, types]);

	useEffect(() => {
		if (!mappedTypes) {
			getTypeList();
		} else {
			setTypes(mappedTypes);
		}
	}, [mappedTypes, getTypeList]);

	return (
		<ContentForm>
			<Wrapper>
				<Title variant="body1">
					Please select one of the options below that describes you best; this will allow us to recommend tailor-fit
					features for your community.
				</Title>
				<TypeListWrapper>
					{types
						.filter(x => x?.isSportType === isSportsMode)
						.map(({ type, active, image }, index) => (
							<TypeCardWrapper key={index} onClick={() => toggleSelectedType(type)} id={type.replace(" ", "")}>
								<TypeCard iconUrl={image} title={type} active={active} />
							</TypeCardWrapper>
						))}
				</TypeListWrapper>
			</Wrapper>
			<SubmitButton WithRightMargin disabled={!isDirty} onClick={onSave} id="saveChangesCommunityType">
				Save changes
			</SubmitButton>
		</ContentForm>
	);
};

export default CommunityTypes;
