import { useCallback, useMemo } from "react";

import { useLiveConversationApiService, useUserApiService } from "shared/services";
import { EventModel } from "shared/types";

import useLiveConversationHelper from "./useLiveConversationHelper";

import { useLiveConversationStore } from "../contexts/LiveConversationContext";

import { initialState } from "../contexts/LiveConversationContext/LiveConversationContext";

import {
	AudioLevel,
	ConversationComment,
	ConversationWithEvent,
	ConvoConfig,
	ConvoControlWithKey,
	FirebaseParticipant,
	LiveConversationContextValues,
	RaisedHand,
	SidebarType
} from "../types";

const useLiveConversation = () => {
	const store = useLiveConversationStore();
	const { setState } = useLiveConversationStore();

	const { getVariable, setupVariable } = useLiveConversationHelper();

	const event = useMemo(() => {
		return store.event;
	}, [store]);

	const service = useUserApiService();

	const liveConvoService = useLiveConversationApiService();

	const methods = useMemo(
		() => ({
			setRoomId: (roomId: string) => {
				setState({ roomId });
			},
			setIsVideoOn: (isVideoOn: boolean) => {
				setState({ isVideoOn });
			},
			setIsMicOn: (isMicOn: boolean) => {
				setState({ isMicOn });
			},
			setEvent: (eventData: EventModel) => {
				setState({ event: eventData });
			},
			getLocalStream: async () => {
				try {
					const localStream = await navigator.mediaDevices.getUserMedia({
						audio: true,
						video: true
					});

					setState({ localStream, permissionGranted: true });
				} catch (error) {
					console.log(error);
				}
			},
			updateParticipants: (firebaseParticipants: FirebaseParticipant[]) => {
				setState({ firebaseParticipants });
			},
			updateConfig: (config: ConvoConfig) => {
				setState({ config });
			},
			updateControls: (controls: ConvoControlWithKey) => {
				setState({ controls });
			},
			updateRaisedHands: (raisedHands: RaisedHand) => {
				setState({ raisedHands });
			},
			toggleSideBar: (sidebarType: SidebarType) => {
				setState(ctx => ({
					sidebarType: ctx.sidebarType === sidebarType ? undefined : sidebarType
				}));
			},
			resetConvo: (refreshHelpValue?: boolean) => {
				if (refreshHelpValue !== undefined) {
					setupVariable(refreshHelpValue);
				}
				setState(initialState);
			},
			checkResetHelper: () => {
				const isNeedRefresh = getVariable();
				if (isNeedRefresh) {
					setupVariable(false);
					setState(initialState);
				}
			},
			updateCommentsCount: (count: number) => {
				setState({ totalComments: count });
			},
			toggleTileMode: () => {
				setState(ctx => ({
					...ctx,
					isTiles: !ctx.isTiles
				}));
			},
			setPinnedUser: (personaId?: number) => {
				setState({ pinnedPersonaId: personaId });
			},
			blockUserByEntity: async (payload: { personaId: number; entity: "event"; block?: boolean; id: string }) => {
				await service.blockUserByEntity(payload);
			},
			notifyOnStart: async (liveConversationId: string) => {
				await liveConvoService.notifyOnStart(liveConversationId);
			},
			bulkNotifyUsers: async (personaIds: number[]) => {
				if (event) {
					const notification = {
						name: "Conversation Invitation",
						body: "You have been invited to a conversation",
						params: {
							event: {
								liveConversationId: event.liveConversationId,
								eventId: event.eventId,
								admins: event.admins,
								personaId: event.personaId,
								title: event.title
							},
							isInvite: true
						},
						type: "conversation_invite"
					};
					await service.bulkNofifyUsers({ personaIds, notification });
				}
			},
			updateAudioLevels: (audioLevels: AudioLevel[]) => {
				setState({ audioLevels });
			},
			updateEndAlertConfig: (
				config: Partial<Pick<LiveConversationContextValues, "convoEndsInSecs" | "showExtend" | "convoExtended">>
			) => {
				setState(ctx => ({ ...ctx, ...config }));
			},
			setComments: (comments: ConversationComment[]) => {
				setState({ comments });
			},
			setOngoingLiveConvos: (liveConvos: ConversationWithEvent[]) => {
				setState({ ongoingLiveConvos: liveConvos });
			},
			setOngoingLiveConvosLoading: (isLoading: boolean) => {
				setState({ ongoingLiveConvosLoading: isLoading });
			}
		}),
		[setState, service, event, getVariable, setupVariable, liveConvoService]
	);

	const getData = useCallback(() => {
		return store;
	}, [store]);

	return { ...methods, getData };
};

export default useLiveConversation;
