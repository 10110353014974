import React, { FC, useEffect, useMemo, useState } from "react";

import { Box, IconButton } from "@material-ui/core";

import { Controller, useFieldArray, useForm } from "react-hook-form";

import { useIntegrations } from "modules/Integrations/Data/hooks";
import { PodcastIntegration } from "modules/Integrations/Data/types/PodcastsResponse";

import ConfirmLeavePopup from "shared/Components/ConfirmLeave";
import useConfirmLeavePopup from "shared/Components/ConfirmLeave/hooks/useConfirmLeavePopup";
import { useNotification } from "shared/hooks";
import { Button, Icon, Input, Loader, Text } from "shared/ui-kit";

import IntegrationDialogWrapper from "./IntegrationDialogWrapper";

interface Props {
	content: PodcastIntegration;
}

const IntegrationDialog: FC<Props> = ({ content }) => {
	const {
		closeIntegrationPopup,
		updatePodcastRss,
		setIsLoading,
		setConfirmDisconnect,
		setShouldUpdateFields,
		getData: getIntegrationsData
	} = useIntegrations();
	const { openDialog, isLoading, podcasts, shouldUpdateFields } = getIntegrationsData();

	const [apiErrors, setApiErrors] = useState("");
	const [failedLinks, setFailedLinks] = useState<string[]>([]);
	const [dataChanged, setDataChanged] = useState(false);
	const { showMessage } = useNotification();

	const open = !!openDialog && openDialog === content.name;

	const renderStatus = useMemo(
		() =>
			!!content.links.length ? (
				<Box className="status green">Connected</Box>
			) : (
				<Box className="status red">Disconnected</Box>
			),
		[content.links.length]
	);

	const {
		handleSubmit,
		control,
		reset,
		formState: { errors }
	} = useForm();

	const { fields, append, remove } = useFieldArray({ name: "rss", control });

	useEffect(() => {
		if (open && shouldUpdateFields) {
			reset();
			setShouldUpdateFields(false);
		}
	}, [open, reset, setShouldUpdateFields, shouldUpdateFields]);

	useEffect(() => {
		!!content.links.length
			? content.links.forEach(val => {
					append({ value: val.link });
			  })
			: append({ value: "" });
	}, [append, content.links]);

	const onClose = async () => {
		setApiErrors("");
		setFailedLinks([]);
		reset();
		closeIntegrationPopup();
		setDataChanged(false);
	};

	const handleDisconnect = () => {
		setConfirmDisconnect(content);
		onClose();
	};

	const handleRemove = (i: number, url: string) => {
		const link = content.links.find(({ link }) => link === url);

		if (!link) {
			remove(i);
		} else {
			setConfirmDisconnect(content, url);
		}
	};

	const onSubmit = async data => {
		const linksExist = Object.values(data).filter(link => {
			const ps = podcasts.filter(podcast => {
				if (podcast._id !== content._id && podcast.links.find(l => l.link === link)) {
					return podcast;
				}
				return null;
			});

			if (!!ps.length) {
				return link;
			}

			return null;
		});

		if (linksExist[0]) {
			setApiErrors(`Link already added before ${linksExist[0]}`);
		} else {
			const links = Object.values(data).map(data => data) as string[];
			setIsLoading(true);

			const { success, apiError, failedLinks } = await updatePodcastRss(content._id, links);

			if (success) {
				setApiErrors("");
				showMessage("Integration successfully added. 🎉");
				!failedLinks?.length && onClose();
			}

			if (apiError) {
				setApiErrors(apiError);
			}

			if (failedLinks) {
				setFailedLinks(failedLinks.map(l => l.link));
			}

			setIsLoading(false);
		}
	};

	const {
		handleLeavePageConfirmed,
		closeConfirmPopup,
		getData: getConfirmLeavePopupData,
		handleClose
	} = useConfirmLeavePopup({
		onClose,
		open
	});

	const { showConfirmPopup } = getConfirmLeavePopupData();

	return (
		<>
			<ConfirmLeavePopup
				handleLeavePage={handleLeavePageConfirmed}
				open={showConfirmPopup}
				onClose={closeConfirmPopup}
				popup
			/>
			<IntegrationDialogWrapper onClose={() => handleClose(dataChanged)} open={open}>
				<form onSubmit={handleSubmit(onSubmit)}>
					<Box className="dialog-header">
						<Text variant="h4">{content.name} Integration</Text>
						<IconButton onClick={() => handleClose(dataChanged)} className="close-popup">
							<Icon fill="#c5cee0" height={14} width={14} group="filled" name="close" />
						</IconButton>
					</Box>
					<Box className="dialog-content">
						<Box className="integration-status">
							<img src={content.image} alt={content.name} />
							<Box className="status-wrapper">{renderStatus}</Box>
						</Box>
						<Box className="access-info">
							{fields.map((field, i) => (
								<Controller
									key={field.id}
									name={`rss-${i}`}
									control={control}
									defaultValue={field.value}
									rules={{
										required: "RSS feed is required."
									}}
									render={({ onChange, value }) => (
										<Box className="input-wrapper">
											<Input
												onChange={e => {
													setDataChanged(true);
													onChange(e);
												}}
												type="url"
												errorText={
													failedLinks.some(l => l === value)
														? "Couldn't parse data from this URL"
														: apiErrors.includes(value)
														? apiErrors.split(value)[0]
														: errors[`rss-${i}`]
														? errors[`rss-${i}`].message
														: ""
												}
												value={value}
												className="access-field-input"
												label="Insert RSS feed"
											/>
											<IconButton onClick={() => handleRemove(i, value)} size="small">
												<Icon name="close-circle" fill="#c5cbd5" />
											</IconButton>
										</Box>
									)}
								/>
							))}
						</Box>
						<Box className="access-info more-rss">
							<Button onClick={append} size="tiny" buttonTheme="ghost">
								<Icon name="add-item" />
								Add more RSS feed
							</Button>
						</Box>
					</Box>
					<Box className="dialog-footer">
						{!!content.links.length && (
							<Button size="large" buttonTheme="light" palette="danger" onClick={handleDisconnect} disabled={isLoading}>
								Disconnect
							</Button>
						)}
						<Button size="large" type="submit" disabled={isLoading}>
							{isLoading ? <Loader size="20px" color="inherit" variant="indeterminate" show /> : "Save"}
						</Button>
					</Box>
				</form>
			</IntegrationDialogWrapper>
		</>
	);
};

export default IntegrationDialog;
