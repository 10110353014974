import email from "./validations/email";
import max from "./validations/max";
import maxLength from "./validations/maxLength";
import min from "./validations/min";
import minLength from "./validations/minLength";
import password from "./validations/password";
import phone from "./validations/phone";
import required from "./validations/required";
import types from "./validations/types";

import validatorFn from "./validatorFn";

const validators = {
	max,
	min,
	email,
	types,
	phone,
	maxLength,
	minLength,
	required,
	password
};

export { validators };

export default validatorFn;
