import React, { FC, useEffect, useMemo, useRef, useState } from "react";

import { ButtonBase, ClickAwayListener, InputAdornment } from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";

import { ToolbarComponentProps } from "@material-ui/pickers/Picker/Picker";

import { DateTime } from "luxon";

import { ReactComponent as CalendarIcon } from "assets/icons/calendar-filled.svg";

import { useCommunity } from "shared/hooks";
import { Icon, Text } from "shared/ui-kit";

import { CustomToolbarComponent, IconWrapper, InputWrapper, Wrapper } from "./style";

import LabeledInput, { LabelInputProps } from "../LabeledInput";
import TwoOptionsSwitch from "../TwoOptionsSwitch";

interface Props extends LabelInputProps {
	minDate?: Date;
	acceptPast?: boolean;
	datePickerOnClick?: () => void;
	includeTime?: boolean;
	inputRef?: React.MutableRefObject<HTMLInputElement>;
	wrapperWidth?: string;
	labelClassName?: string;
	rightIconColor?: string;
	isBackgroundGray?: boolean;
}

const DateInput: FC<Props> = ({
	acceptPast,
	onChange,
	value,
	label,
	minDate,
	error,
	datePickerOnClick,
	includeTime,
	wrapperWidth,
	inputRef,
	labelClassName,
	rightIconColor,
	isBackgroundGray,
	...props
}) => {
	const [isOpen, setIsOpen] = useState(false);
	const [title, setTitle] = useState("");
	const [titleChanged, setTitleChanged] = useState(false);
	const [time, setTime] = useState({
		hours: 1,
		minutes: 1,
		PM: true
	});

	const { communityColors } = useCommunity();

	const pickerRef = useRef<HTMLDivElement>();

	useEffect(() => {
		if (isOpen) {
			pickerRef.current && pickerRef.current.scrollIntoView({ behavior: "smooth", block: "center" });

			const now = new Date();
			setTime(ctx => ({
				...ctx,
				hours: now.getHours() > 12 ? now.getHours() - 12 : now.getHours(),
				minutes: now.getMinutes()
			}));

			if (!titleChanged) {
				setTimeout(
					() => setTitle(document.querySelector(".MuiPickersCalendarHeader-switchHeader p")?.textContent || ""),
					200
				);

				setTitleChanged(true);
			}
		}
	}, [isOpen, titleChanged]);

	useEffect(() => {
		if (includeTime) {
			const now = new Date();
			setTime({
				hours: now.getHours() > 12 ? now.getHours() - 12 : now.getHours(),
				minutes: now.getMinutes(),
				PM: now.getHours() > 12
			});
		}
	}, [includeTime]);

	const CustomToolbar: FC<ToolbarComponentProps> = ({ openView, setOpenView }) => (
		<CustomToolbarComponent>
			{openView === "date" && <ButtonBase onClick={() => setOpenView("month")}>{title}</ButtonBase>}
		</CustomToolbarComponent>
	);

	const handleClickAway = () => {
		setIsOpen(false);
	};

	const maxDate = useMemo(() => new Date().setFullYear(new Date().getFullYear() + 1), []);
	const formattedValue = useMemo(
		() => (value ? DateTime.fromJSDate(new Date(value)).toFormat(includeTime ? "MMM d, h:mm a" : "MMM d y") : ""),
		[includeTime, value]
	);

	return (
		<Wrapper width={wrapperWidth}>
			<ClickAwayListener onClickAway={handleClickAway}>
				<InputWrapper className={!!error && "error"}>
					<InputWrapper.Label>{label}</InputWrapper.Label>
					<LabeledInput
						{...props}
						value={formattedValue}
						onChange={onChange}
						name="date"
						handleClick={() => setIsOpen(open => !open)}
						activeState={isOpen}
						rightIcon={{
							el: (
								<Icon
									height={20}
									width={20}
									name="calendar-day"
									fill={isOpen ? "#000000" : rightIconColor ? rightIconColor : "#c5cee0"}
									group="linear"
								/>
							),
							active: false
						}}
						displayOnly
						inputRef={inputRef}
						className={labelClassName}
						isBackgroundGray={isBackgroundGray}
					/>
					<Wrapper.Picker color={communityColors.primary} className={isOpen ? "show" : ""} ref={pickerRef}>
						<DatePicker
							open={isOpen}
							autoOk
							views={["month", "date"]}
							disablePast={!acceptPast}
							shouldDisableDate={date =>
								date && minDate && date.getTime() < minDate.getTime() && !acceptPast ? true : false
							}
							allowKeyboardControl
							ToolbarComponent={CustomToolbar}
							variant="static"
							format="MM/dd/yyyy"
							margin="normal"
							id="date-picker-inline"
							label=" "
							value={value}
							onChange={date =>
								onChange(
									includeTime
										? new Date(date?.setHours(time.PM ? time.hours + 12 : time.hours, time.minutes) || "")
										: date
								)
							}
							onMonthChange={() => setTitleChanged(false)}
							PopoverProps={{
								className: "member-calendar-container"
							}}
							onOpen={() => {
								if (datePickerOnClick) datePickerOnClick();
								setTimeout(() => setIsOpen(true), 100);
							}}
							onClose={() => setIsOpen(false)}
							maxDate={maxDate}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<IconWrapper className={isOpen && "active"}>
											<CalendarIcon />
										</IconWrapper>
									</InputAdornment>
								)
							}}
						/>
						{includeTime && (
							<Wrapper.HourPickerWrapper>
								<input
									type="number"
									name="hour"
									placeholder="H"
									title="Hours"
									onChange={e =>
										setTime(time => ({
											...time,
											hours: parseInt(e.target.value)
										}))
									}
									value={time.hours}
								/>
								<Text>:</Text>
								<input
									type="number"
									name="minutes"
									placeholder="M"
									title="Minutes"
									onChange={e =>
										setTime(time => ({
											...time,
											minutes: parseInt(e.target.value)
										}))
									}
									value={time.minutes}
								/>
								<TwoOptionsSwitch
									optionOne={{ value: "PM", label: "PM" }}
									optionTwo={{ value: "AM", label: "AM" }}
									onChange={val => setTime(ctx => ({ ...ctx, PM: val === "PM" }))}
									small
									disableAnimation
									transformXZero
								/>
							</Wrapper.HourPickerWrapper>
						)}
					</Wrapper.Picker>
				</InputWrapper>
			</ClickAwayListener>
			{error && <Wrapper.ErrorText>{error}</Wrapper.ErrorText>}
		</Wrapper>
	);
};

export default DateInput;
