import { ReactNode, useCallback, useMemo } from "react";

import { useNotificationStore } from "../contexts";
import { useNotificationApiService } from "../services";

let interval;

const useNotification = () => {
	const notificationService = useNotificationApiService();

	const store = useNotificationStore();
	const { setState } = useNotificationStore();

	const methods = useMemo(
		() => ({
			showMessage: (message?: string | ReactNode, timeInSeconds = 5, isError = false) => {
				if (interval) {
					clearTimeout(interval);
				}
				setState({ message, isError });
				if (message) {
					interval = setTimeout(() => {
						setState({ message: "" });
					}, timeInSeconds * 1000);
				}
			},
			hide: () => setState({ message: "" }),
			notifyAllUsers: async (data: any) => {
				return await notificationService.notifyAllUsers(data);
			}
		}),
		[notificationService, setState]
	);

	const getData = useCallback(() => {
		return store;
	}, [store]);

	return { ...methods, getData };
};

export default useNotification;
