import { Box } from "@material-ui/core";

import styled from "styled-components";

import { ContentWrapper } from "modules/Community/View/Components";
import { Button, Input, Text } from "shared/ui-kit";
import { customBreakpoints } from "theme/default";

export const ManageBtn = styled(Button)`
	margin-top: 0;
	margin-bottom: 5px;
`;

export const CTAButton = styled(Button)`
	display: block;
	margin-top: 20px;
`;

export const ContentWrapperBlock = styled(ContentWrapper)`
	margin-top: 96px;

	${props => props.theme.breakpoints.down(customBreakpoints.tabletVerticalMax)} {
		margin-top: 32px;
	}
`;

export const EmailInput = styled(Input)`
	margin-top: 13px;

	input {
		font-size: 16px;
	}

	// handles safari autofill color
	input:-webkit-autofill,
	input:-webkit-autofill:hover,
	input:-webkit-autofill:focus,
	input:-webkit-autofill:active {
		-webkit-box-shadow: 0 0 0 60px #fafafa inset !important;
		background-color: #fafafa !important;
		background-clip: content-box !important;
	}
`;

export const OtherLoginOptions = styled(Box)`
	text-align: center;
	margin-top: 32px;
	a {
		color: #6173fe;
		font-weight: 600;
	}
	p {
		margin-top: 8px;
	}
	.caption {
		font-size: 12px;
		color: #8f9bb3;
		margin-bottom: 24px;
		display: block;
		a {
			color: #8f9bb3;
			font-weight: normal;
		}
	}
`;

export const InputWrapper = styled(Box)`
	margin: 16px 0;
	position: relative;

	input:disabled {
		cursor: pointer;
		color: #8f9bb3;
	}

	.show-password {
		position: absolute;
		right: 0;
		top: 0;
	}

	.MuiInputLabel-outlined {
		transform: translate(14px, 17px) scale(1);

		&.MuiInputLabel-shrink {
			transform: translate(14px, -6px) scale(0.75);
		}
	}
`;

export const Subtitle = styled(Text)`
	margin-top: 11px;

	${props => props.theme.breakpoints.down("sm")} {
		margin-top: 16px;
	}
`;

export const AuthPagesContainer = styled(Box)`
	background-color: white;
	max-width: 560px;
	margin: 0 16px;
	border-radius: 4px;
	box-shadow: inset 0 0 0 0.5px #c5cee0;
	padding: 24px 0;
	.header,
	.google-login,
	.inputs {
		padding-left: 16px;
		padding-right: 16px;

		${props => props.theme.breakpoints.up("sm")} {
			padding-left: 80px;
			padding-right: 80px;
		}
	}

	${props => props.theme.breakpoints.up("sm")} {
		margin: auto;
		padding: 56px 0 40px;
	}
`;
