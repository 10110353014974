import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { Grid, Radio, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import clsx from "clsx";
import config from "config/appConfig";
import * as R from "ramda";

import { Controller, useForm } from "react-hook-form";

import { ReactComponent as CrownIcon } from "assets/icons/iconCrownLinear.svg";
import { ReactComponent as GlobeIcon } from "assets/icons/iconGlobe.svg";
import { ReactComponent as LocationIcon } from "assets/icons/iconLocation.svg";
import { ReactComponent as LockIcon } from "assets/icons/iconLock.svg";
import { ReactComponent as PeopleIcon } from "assets/icons/iconPeople.svg";
import { ReactComponent as EyeStrikeIcon } from "assets/icons/stories/icon-eye-strikethrough.svg";

import { Dialog } from "shared/Components/index";
import { useDebounce, useGroup, useMembers, useNotification } from "shared/hooks";
import useScrollToXPosition from "shared/hooks/useScrollToXPosition";
import { GroupModel, Location, UserStatus } from "shared/types";
import { Box, Icon, Loader, Switch, Text, Uploader, orientationConst } from "shared/ui-kit";

import { filterLocationOptions } from "utils/filterLocationOptions";

import {
	ActionBtn,
	CustomDivider,
	CustomLocationIcon,
	FormControl,
	IconWrapper,
	MessageControlWrapper,
	SectionTitle,
	StyledMessageIcon,
	StyledRadioGroup,
	SubText,
	UploaderWrapper
} from "./style";

import { GroupSortBy } from "../../types/GroupModel";

import ConfirmLeavePopup from "../ConfirmLeave";
import useConfirmLeavePopup from "../ConfirmLeave/hooks/useConfirmLeavePopup";
import RadioItem from "../RadioItem";
import SetupPrice from "../SetupPrice";

interface Props {
	onClose: (event: { created?: boolean; group?: GroupModel }, reason: string) => void;
	open: boolean;
	handleCreateGroup;
	groupHidden: boolean;
	setGroupHidden: React.Dispatch<React.SetStateAction<boolean>>;
	onUploaderChange: (files: any[], onChange: (...event: any[]) => void) => {};
	editableModel?: GroupModel;
	defaultParent?: GroupModel;
	isSubgroup?: boolean;
	isUploading: boolean;
}

const CreateGroupManageView: React.FC<Props> = ({
	onClose,
	open,
	editableModel,
	defaultParent,
	isSubgroup = false,
	onUploaderChange,
	handleCreateGroup,
	groupHidden,
	setGroupHidden,
	isUploading
}) => {
	const bodyRef = useRef<HTMLFormElement>();
	const scrollToXPositionOnFocus = useScrollToXPosition(bodyRef?.current);

	const [step, setStep] = useState(1);
	const [suggestedGroups, setSuggestedGroups] = useState<GroupModel[]>([]);
	const [userQuery, setUserQuery] = useState("");
	const { showMessage } = useNotification();

	const debouncedUserQuery = useDebounce(userQuery, 500);

	const { loadSuggestedLocations, getGroups, getData: getGroupData } = useGroup();
	const { suggestedLocations } = getGroupData();

	const { getMembersToInvite, getMembers, getData: getMembersData } = useMembers();
	const { statusFilter, users } = getMembersData();

	type SetupPriceMethodsHandlers = React.ElementRef<typeof SetupPrice>;
	const setupPriceFormRef = React.useRef<SetupPriceMethodsHandlers>(null);

	const {
		register,
		handleSubmit,
		control,
		errors,
		formState: { isDirty, isSubmitting },
		reset,
		watch
	} = useForm({
		mode: "onChange"
	});

	const {
		name,
		parentGroup,
		description,
		location,
		private: privateVal,
		rules,
		chatEnabled
	} = watch(["name", "parentGroup", "description", "location", "private", "rules", "chatEnabled"]);

	const [usersList, setUsersList] = useState<{ key: number; value: string }[]>([]);

	useEffect(() => {
		users && setUsersList(users.map(user => ({ key: user.personaId, value: `${user.firstName} ${user.lastName}` })));
	}, [users]);

	const loadSuggestedGroups = useCallback(
		async (name: string) => {
			const { groups } = await getGroups({
				page: 1,
				limit: 20,
				groupType: "all",
				sortBy: GroupSortBy.PLACEHOLDER,
				name,
				preventStateUpdate: true
			});

			setSuggestedGroups(groups);
		},
		[getGroups]
	);

	useEffect(() => {
		if (editableModel) {
			reset({
				...R.pick(["name", "description", "rules", "chatEnabled", "location", "coverUrl"], editableModel),
				audience: editableModel.isNational ? "national" : "local",
				hidden: editableModel.hidden ? "permitted" : "anyone",
				private: editableModel.private ? "private" : "public",
				parentGroup: editableModel?.parentId
					? { name: editableModel?.parentGroup?.name, _id: editableModel?.parentGroup?._id }
					: undefined
			});
		} else {
			reset({
				parentGroup: defaultParent ? { name: defaultParent?.name, _id: defaultParent?._id } : undefined
			});
			setStep(1);
		}
	}, [editableModel, defaultParent, reset]);

	useEffect(() => {
		if (open) {
			setStep(1);

			if (!defaultParent) {
				loadSuggestedGroups("");
			}
		}
	}, [open, loadSuggestedGroups, defaultParent]);

	useEffect(() => {
		if (Object.keys(errors).length) {
			showMessage(
				<ul>
					{Object.values(errors).map((p, i) => (
						<li key={i}>{p.message}</li>
					))}
				</ul>,
				3
			);
		}
	}, [errors, showMessage]);

	useEffect(() => {
		if (step === 2) {
			bodyRef?.current?.scrollTo(0, 0);
		}
	}, [step]);

	useEffect(() => {
		if (step === 2) {
			getMembers({
				limit: 20,
				offset: 1,
				type: "members",
				keyword: debouncedUserQuery,
				filterBy: [UserStatus.ON_BOARDED]
			});
		}
	}, [getMembers, getMembersToInvite, statusFilter, step, debouncedUserQuery]);

	const correctName = useMemo(() => (isSubgroup ? "Sub-group" : "Group"), [isSubgroup]);

	const Title = useMemo(
		() => (
			<>
				<Text variant="h7">{editableModel ? `Update ${correctName} Details` : `Create ${correctName}`}</Text>{" "}
				<SubText>• Step {step}/2</SubText>
			</>
		),
		[editableModel, step, correctName]
	);

	const renderBody = () => (
		<>
			<Box style={{ display: step === 1 ? "block" : "none" }}>
				<FormControl>
					<UploaderWrapper>
						<Controller
							name="coverUrl"
							control={control}
							render={({ onChange, value }) => (
								<Uploader
									urls={
										!value && editableModel && editableModel.coverUrl
											? [editableModel.coverUrl]
											: value
											? [value]
											: undefined
									}
									onChange={(files: any) => onUploaderChange(files, onChange)}
									label={`Upload ${correctName} Photo`}
									orientation={orientationConst.horizontal}
									width="100%"
									height="auto"
									description={
										<>
											Drag and Drop File Here or <span className="anchor">Browse</span> to Choose a File
										</>
									}
									accept={[
										{
											fileType: "image/png, image/jpeg, image/x-eps",
											name: "png, jpg, eps"
										}
									]}
									icon={
										<Icon
											group={""}
											fill={"#c5cee0"}
											name={"cloud-upload"}
											width={64}
											height={50}
											viewBox={"4 2 18 19"}
										/>
									}
								/>
							)}
						/>
					</UploaderWrapper>
				</FormControl>
				<FormControl>
					<TextField
						id={`${correctName.toLocaleLowerCase()}Name`}
						name="name"
						label={`${correctName} name*`}
						inputProps={{ maxLength: 40 }}
						inputRef={register({
							required: `${correctName} name is required`,
							maxLength: { value: 40, message: `${correctName} name should not exceed 40 characters` },
							minLength: { value: 3, message: "At least 3 characters are required" }
						})}
						variant="outlined"
						error={errors?.name?.message}
						helperText={errors?.name?.message}
						onFocus={scrollToXPositionOnFocus}
					/>
				</FormControl>
				{isSubgroup && !editableModel && (
					<FormControl>
						<Controller
							name="parentGroup"
							control={control}
							rules={{
								required: `${correctName} Parent Group is required!`
							}}
							render={({ onChange, value }) => (
								<Autocomplete
									getOptionLabel={option => (typeof option === "string" ? option : option.name)}
									options={suggestedGroups}
									disabled={!!defaultParent}
									autoComplete
									fullWidth
									includeInputInList
									filterSelectedOptions
									value={value}
									onChange={(event, newValue: GroupModel | null) => onChange(newValue)}
									onInputChange={(group, newInputValue) => !defaultParent && loadSuggestedGroups(newInputValue)}
									renderInput={params => (
										<TextField
											{...params}
											label="Select Parent Group*"
											variant="outlined"
											fullWidth
											error={errors?.parentGroup?.message}
											helperText={errors?.parentGroup?.message}
										/>
									)}
									renderOption={option => (
										<span
											className={clsx(
												option.totalSubGroups >= config.GLOBAL_CONSTANTS.SUBGROUPS_LIMIT_COUNT &&
													"opacity-50 cursor-not-allowed"
											)}
										>
											{option.name}
										</span>
									)}
									getOptionDisabled={option =>
										(option?.totalSubGroups || 0) >= config.GLOBAL_CONSTANTS.SUBGROUPS_LIMIT_COUNT
									}
									onFocus={scrollToXPositionOnFocus}
									id="selectParentGroup"
								/>
							)}
						/>
					</FormControl>
				)}
				<FormControl className={"inner"}>
					<SectionTitle variant="h6">Privacy</SectionTitle>
					<Controller
						name="private"
						control={control}
						defaultValue="public"
						rules={{ required: "Privacy is required!" }}
						as={
							<StyledRadioGroup>
								<RadioItem
									control={<Radio />}
									label="Public"
									value="public"
									icon={
										<IconWrapper>
											<EyeStrikeIcon />
										</IconWrapper>
									}
									helpText={`Anyone can see who’s in the ${correctName} and what they post`}
								/>
								<RadioItem
									control={<Radio />}
									label="Private"
									value="private"
									icon={
										<IconWrapper>
											<LockIcon />
										</IconWrapper>
									}
									helpText={`Only members can see who’s in the ${correctName} and what they post`}
								/>
							</StyledRadioGroup>
						}
					/>
					{privateVal === "private" && (
						<section onClick={() => setGroupHidden(!groupHidden)}>
							<RadioItem
								control={<Switch name="groupHidden" inputRef={register} checked={groupHidden} />}
								label="Hidden"
								value={groupHidden}
								icon={
									<IconWrapper>
										<EyeStrikeIcon />
									</IconWrapper>
								}
								helpText={`Only members can find the ${correctName}`}
							/>
						</section>
					)}
				</FormControl>
				<CustomDivider />
				<MessageControlWrapper>
					<Text>
						<StyledMessageIcon /> Enable {correctName} chat
					</Text>
					<Switch name="chatEnabled" inputRef={register} defaultChecked={chatEnabled} />
				</MessageControlWrapper>
			</Box>
			<Box style={{ display: step === 2 ? "block" : "none" }}>
				<div className="mb-4">
					<Controller
						name="adminIds"
						variant="outlined"
						rules={step === 1 ? { required: "Genre is required!", validate: value => !!value?.length } : undefined}
						control={control}
						render={({ onChange, value }) => (
							<Autocomplete
								multiple
								getOptionLabel={option => (typeof option === "string" ? option : option.value)}
								options={usersList}
								autoComplete
								fullWidth
								includeInputInList
								filterSelectedOptions
								value={value}
								onChange={(_, newValue) => onChange(newValue)}
								onInputChange={(_, newInputValue) => setUserQuery(newInputValue)}
								renderInput={params => (
									<TextField
										{...params}
										label="Invite Admins"
										variant="outlined"
										fullWidth
										error={errors?.parentGroup?.message}
										helperText={errors?.parentGroup?.message}
									/>
								)}
								onFocus={scrollToXPositionOnFocus}
								id="selectParentGroup"
							/>
						)}
					/>
				</div>
				<FormControl className={"inner"}>
					<SectionTitle variant="h6">Audience</SectionTitle>
					<Controller
						name="audience"
						control={control}
						defaultValue="local"
						rules={{ required: "Audience is required!" }}
						as={
							<StyledRadioGroup>
								<RadioItem
									control={<Radio />}
									label="Local"
									value="local"
									icon={<LocationIcon />}
									helpText={`Only members who are local or near the ${correctName} location will see the ${correctName}.`}
								/>
								<RadioItem
									control={<Radio />}
									label="Regional/National"
									value="national"
									icon={<GlobeIcon />}
									helpText={`Anyone in the community will be able to see the ${correctName} regardless of location.`}
								/>
							</StyledRadioGroup>
						}
					/>
				</FormControl>
				<CustomDivider />
				<FormControl className={"inner"}>
					<Controller
						name="priceChanged"
						control={control}
						render={({ onChange }) => (
							<SetupPrice
								ref={setupPriceFormRef}
								hasBorder={false}
								onPriceChanged={() => onChange(true)}
								title={
									<SectionTitle variant="h6" className={"extra-space"}>
										Who can join this Group?
									</SectionTitle>
								}
							/>
						)}
					/>
				</FormControl>
				<CustomDivider />
				<FormControl className={"inner"}>
					<SectionTitle variant="h6" className={"extra-space"}>
						Who can post
					</SectionTitle>
					<Controller
						name="disablePosts"
						control={control}
						defaultValue="anyone"
						rules={{ required: "Who can post is required!" }}
						as={
							<StyledRadioGroup>
								<RadioItem
									control={<Radio />}
									label="Anyone"
									value="anyone"
									icon={
										<IconWrapper>
											<PeopleIcon />
										</IconWrapper>
									}
									helpText={`Any member of the ${correctName} can create a post`}
								/>
								<RadioItem
									control={<Radio />}
									label="Admins & Moderators"
									value="permitted"
									icon={<CrownIcon fill="#8f9bb3" />}
									helpText={`Only ${correctName} admins & moderators can create a post`}
								/>
							</StyledRadioGroup>
						}
					/>
				</FormControl>
				<FormControl>
					<Controller
						name="location"
						control={control}
						rules={{
							required: `${correctName} Location is required!`
						}}
						render={({ onChange, value }) => (
							<Autocomplete
								getOptionLabel={option => (typeof option === "string" ? option : option.name)}
								filterOptions={filterLocationOptions()}
								options={suggestedLocations}
								autoComplete
								freeSolo
								fullWidth
								includeInputInList
								filterSelectedOptions
								value={value}
								onChange={(event, newValue: Location | null) => onChange(newValue)}
								onInputChange={(group, newInputValue) => loadSuggestedLocations(newInputValue)}
								renderInput={params => (
									<TextField
										{...params}
										label="Choose Location*"
										variant="outlined"
										fullWidth
										error={errors?.location?.message}
										helperText={errors?.location?.message}
									/>
								)}
								id={isSubgroup ? "locationSubgroup" : "location"}
								renderOption={option => (
									<Grid container alignItems="center" id={`groupLocation${option.name}`}>
										<Grid item>
											<CustomLocationIcon />
										</Grid>
										<Grid item xs>
											{option.name}
											<Text variant="body2" color="textSecondary">
												{option.vicinity}
											</Text>
										</Grid>
									</Grid>
								)}
							/>
						)}
					/>
				</FormControl>
				<FormControl>
					<TextField
						inputRef={register({
							required: `${correctName} Description is required!`,
							minLength: { value: 10, message: "At least 10 characters are required" }
						})}
						name="description"
						label={`${correctName} Description*`}
						variant="outlined"
						multiline
						rows={3}
						error={errors?.description?.message}
						helperText={errors?.description?.message}
						id="groupDescription"
					/>
				</FormControl>
				<FormControl>
					<TextField
						inputRef={register({
							required: "Rules is required!",
							minLength: { value: 10, message: "At least 10 characters are required" }
						})}
						name="rules"
						label={`Rules of this ${correctName}*`}
						variant="outlined"
						multiline
						rows={3}
						error={errors?.rules?.message}
						helperText={errors?.rules?.message}
						id="groupRules"
					/>
				</FormControl>
			</Box>
		</>
	);

	const ActionButton =
		step === 1 ? (
			<ActionBtn
				id="next"
				onClick={() => setStep(2)}
				disabled={!name || (isSubgroup && !parentGroup) || errors.name || (isSubgroup && errors.parentGroup)}
			>
				Next
			</ActionBtn>
		) : (
			<ActionBtn
				id={editableModel ? `update${correctName}` : `create${correctName}`}
				type="submit"
				onClick={handleSubmit(handleCreateGroup)}
				leftIcon={isUploading ? <Loader size="1rem" show={true} color="primary" variant="indeterminate" /> : undefined}
				disabled={
					!description || !location || !rules || errors.description || errors.rules || isSubmitting || isUploading
				}
			>
				{editableModel ? "Update" : "Create"} {correctName}
			</ActionBtn>
		);

	const {
		handleLeavePageConfirmed,
		closeConfirmPopup,
		handleClose,
		getData: getConfirmLeavePopupData
	} = useConfirmLeavePopup({
		onClose: () => {
			setGroupHidden(false);
			onClose({ created: false }, "");
		},
		open
	});
	const { showConfirmPopup } = getConfirmLeavePopupData();

	return (
		<>
			<ConfirmLeavePopup
				handleLeavePage={handleLeavePageConfirmed}
				open={showConfirmPopup}
				onClose={closeConfirmPopup}
				popup
			/>
			<Dialog
				title={Title}
				open={open}
				onClose={() => handleClose(isDirty)}
				footer={ActionButton}
				hasBackButton={step === 2}
				onBack={() => setStep(1)}
				bodyRef={bodyRef}
			>
				<form noValidate onSubmit={handleSubmit(handleCreateGroup)}>
					{renderBody()}
				</form>
			</Dialog>
		</>
	);
};

export default CreateGroupManageView;
