import { EventModel } from "shared/types";

export enum ConvoUserRoles {
	admin,
	participant
}

export interface FirebaseParticipant {
	liveConversationId: string;
	personaId: number;
	avatarUrl: string;
	firstName: string;
	lastName: string;
	streamId: string;
	userRole: ConvoUserRoles;
	isSpeaker: boolean;
	banned: boolean;
	isFrontCamera: boolean;
	joinedAt: string;
	blocked?: boolean;
}

export interface ConvoParticipant extends FirebaseParticipant {
	stream: MediaStream;
}

export interface ConvoConfig {
	startedAt: string;
	enableHandRaise: boolean;
	enableInvites: boolean;
	enableComments: boolean;
}

export interface ConvoControl {
	personaId: string;
	muted: boolean;
	videoOn: boolean;
}

export type ConvoControlWithKey = Record<string, ConvoControl>;

export interface Stream {
	streamId: string;
	stream: MediaStream;
}

export type RaisedHand = Record<string, boolean>;

export interface ConversationComment {
	id?: string | null;
	_id: string;
	createdAt: string;
	order: number;
	text: string;
	avatarUrl: string;
	firstName: string;
	lastName: string;
	personaId: number;
}

export enum SidebarType {
	participants = "participants",
	comments = "comments",
	hands = "hands"
}

export interface AudioLevel {
	personaId: number;
	volume: number;
}

export interface Conversation {
	config: ConvoConfig;
	participants: Record<string, FirebaseParticipant>;
}

export type ConversationWithId = Conversation & { id: string };
export type ConversationWithEvent = ConversationWithId & { event: EventModel };

export interface LiveConversationContextValues {
	event?: EventModel;
	roomId: string;
	permissionGranted: boolean;
	isVideoOn: boolean;
	isMicOn: boolean;
	localStream?: MediaStream;
	shouldStart: boolean;
	firebaseParticipants: FirebaseParticipant[];
	config: ConvoConfig;
	controls: ConvoControlWithKey;
	sidebarType?: SidebarType;
	raisedHands: RaisedHand;
	totalComments: number;
	pinnedPersonaId?: number;
	isTiles: boolean;
	audioLevels: AudioLevel[];
	convoEndsInSecs: number | null;
	showExtend?: boolean;
	convoExtended?: boolean;
	comments: ConversationComment[];
	ongoingLiveConvos?: ConversationWithEvent[];
	ongoingLiveConvosLoading?: boolean;
}
