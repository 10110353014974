import React from "react";

import { Box, IconButton } from "@material-ui/core";

import config from "config/appConfig";

import { useIntegrations } from "modules/Integrations/Data/hooks";
import { Button, Icon, Loader, Text } from "shared/ui-kit";

import IntegrationDialogWrapper from "./IntegrationDialogWrapper";

const ConfirmDisconnectIntegration = () => {
	const {
		setConfirmDisconnect,
		openIntegrationPopup,
		deleteRssLinks,
		getData: getIntegrationsData
	} = useIntegrations();
	const { confirmDisconnect, linkToDelete, isLoading } = getIntegrationsData();

	const handleBack = () => {
		if (confirmDisconnect) {
			openIntegrationPopup(confirmDisconnect.name);
		}
		setConfirmDisconnect(null);
	};

	const handleDisconnect = async () => {
		if (confirmDisconnect) {
			if (linkToDelete) {
				const link = confirmDisconnect.links.find(({ link }) => link === linkToDelete)?._id;

				if (link) {
					await deleteRssLinks(confirmDisconnect._id, [link]);
					setConfirmDisconnect(null);
				}
			} else {
				const linksIds = confirmDisconnect.links.map(link => link._id);
				await deleteRssLinks(confirmDisconnect._id, linksIds);
			}
		}
	};

	return (
		<IntegrationDialogWrapper zIndex={1400} open={!!confirmDisconnect} onClose={() => setConfirmDisconnect(null)}>
			<Box className="dialog-header">
				<Text variant="h6">Disconnect Confirmation.</Text>
				<IconButton onClick={() => {}} className="close-popup">
					<Icon fill="#c5cee0" height={14} width={14} group="filled" name="close" />
				</IconButton>
			</Box>
			<Box className="dialog-content">
				<Text variant="caption2">
					Disconnecting will permanently delete your {confirmDisconnect?.name} RSS URLs from{" "}
					{config.GLOBAL_CONSTANTS.ENV_APPLICATION_NAME}, are you sure you want to disconnect?
				</Text>
			</Box>
			<br />
			<Box className="dialog-footer with-separator">
				<Button size="medium" buttonTheme="outline" palette="primary" onClick={handleBack}>
					Cancel
				</Button>
				<Button size="medium" type="submit" onClick={handleDisconnect}>
					{isLoading ? <Loader size="20px" color="inherit" variant="indeterminate" show /> : "Disconnect"}
				</Button>
			</Box>
		</IntegrationDialogWrapper>
	);
};

export default ConfirmDisconnectIntegration;
