import styled, { css } from "styled-components";

import { Box } from "shared/ui-kit";

const EditorWrapper = styled(Box)<{ hide?: boolean; hidden?: boolean; communityprimarycolor?: string }>`
	padding-top: 16px;

	.quill {
		background: white;
	}

	.ql-container {
		border: none;
	}

	.ql-toolbar {
		display: none;
	}

	.ql-tooltip {
		display: none;
	}

	.ql-editor {
		max-width: 692px;
		min-height: 320px;
		resize: none;

		margin: 0 auto;

		overflow: visible;

		.hashtag {
			color: inherit;
			text-decoration: none;
			font-weight: bold;
		}

		.author {
			color: #6173fe;
			font-weight: initial;
			text-decoration: none;
		}

		${props => props.theme.breakpoints.down("sm")} {
			padding: 15px;
		}

		${({ communityprimarycolor }) =>
			communityprimarycolor &&
			css`
				a {
					color: ${communityprimarycolor};
				}
			`};

		> p {
			font-size: 18px;
			line-height: 20px;
		}

		> img {
			border-radius: 4px;
		}

		.embed-innerApp {
			display: flex;
			justify-content: center;

			width: 100%;

			> div {
				width: 100%;
			}
		}

		&.ql-blank {
			&:before {
				font-size: 18px;
				font-weight: 600;
				line-height: 1.33;
				color: #8f9bb3;
				font-style: normal;

				position: relative;
				left: 0;

				max-height: 0;

				display: block;

				overflow: visible;
			}
		}

		img {
			width: 100%;
		}
	}

	${({ hide }) =>
		hide &&
		css`
			display: none;
		`};

	${({ hidden }) =>
		hidden &&
		css`
			opacity: 0;
			visibility: hidden;
		`};
`;

export default EditorWrapper;
