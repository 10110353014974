/* eslint-disable react/no-unescaped-entities */
import React, { useCallback, useEffect, useMemo } from "react";

import { useMediaQuery } from "@material-ui/core";

import styled from "styled-components";

import { FileType } from "types/FilesContextValuesType";

import { getFileExtension } from "types/getFileExtension";

import { MemberDialog } from "modules/MemberHome/View/shared";
import { MemberDialogContentWrapper } from "modules/MemberHome/View/shared/style";
import { Dialog } from "shared/Components";
import { useFiles, useNotification, useUser } from "shared/hooks";
import { useS3Uploader } from "shared/services/s3Uploader";
import { ToolbarActions } from "shared/types";
import { Button, Text } from "shared/ui-kit";

import * as appTheme from "theme/default";

import SelectFileBody from "./SelectFileBody";

const CreateButton = styled(Button)`
	height: 48px;
	font-size: 16px;
	font-weight: 600;
	line-height: 1.25;
	color: #6173fe;
`;

interface Props {
	onClose: (file: {}, reason: string) => void;
	onSelect: (file: FileType) => void;
	open: boolean;
}

const SelectFile: React.FC<Props> = ({ onClose, onSelect, open }) => {
	const { getData: getUserData } = useUser();
	const { isMemberView } = getUserData();

	const { setFileUrl, setFileName, setFileExtension, setFileInfoPopup, getData: getFilesData } = useFiles();

	const { fileUploadUrl } = getFilesData();

	const { uploadFile } = useS3Uploader();
	const { showMessage } = useNotification();

	const isMobile = useMediaQuery(appTheme.default.breakpoints.down("sm"));

	useEffect(() => {
		setFileUrl(null);
		setFileName("");
	}, [setFileName, setFileUrl]);

	const handleUpload = () => {
		const el: any = document.getElementById("fileUrl");
		if (el) {
			el.value = null;
			el.click();
		}
	};

	const onSelectFile = useCallback(
		(data: FileType) => {
			setTimeout(() => {
				onSelect(data);
			}, 10);
		},
		[onSelect]
	);

	const onUpload = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			const { target } = e;
			const { files } = target;

			if (files?.length) {
				const extObj = getFileExtension(files[0].name);

				if (!extObj) {
					showMessage("We don't accept this file type, please try with a different file.");
					return;
				}

				uploadFile({
					file: files[0],
					communityName: fileUploadUrl,
					checkProgress: true,
					customCallback: data => setFileUrl(data?.publicUrl)
				});

				setFileName(files && files[0].name.slice(0, files[0].name.lastIndexOf(".")));

				setFileInfoPopup({ open: true, model: undefined });

				setFileExtension(extObj.ext);

				onClose(e, ToolbarActions.ADD_FILE);
			}
		},
		[fileUploadUrl, onClose, setFileExtension, setFileInfoPopup, setFileName, setFileUrl, showMessage, uploadFile]
	);

	const dialogContent = useMemo(
		() => (
			<>
				<input id="fileUrl" type="file" onChange={onUpload} name="fileUrl" hidden accept="*" />
				<SelectFileBody onSelect={onSelectFile} />
			</>
		),
		[onSelectFile, onUpload]
	);

	return isMemberView ? (
		<MemberDialog
			customWidth={512}
			title="Select a File"
			open={open}
			onClose={onClose}
			footerPrimary={{ text: "Upload a new File", onClick: handleUpload }}
		>
			<MemberDialogContentWrapper className="pt-5">{dialogContent}</MemberDialogContentWrapper>
		</MemberDialog>
	) : (
		<Dialog
			title={<Text variant={isMobile ? "h5" : "h3"}>Select File</Text>}
			open={open}
			onClose={onClose}
			footer={
				<CreateButton buttonTheme="light" fullWidth onClick={handleUpload}>
					Upload a new File
				</CreateButton>
			}
		>
			{dialogContent}
		</Dialog>
	);
};

export default SelectFile;
