import * as R from "ramda";

export const convertSerie = data => {
	return {
		...R.pick(
			["title", "categoryId", "groupId", "eventId", "description", "priceTags", "itemIds", "individualAccess"],
			data
		),
		meta: {
			...data.meta,
			canBeDownloaded: !!data?.meta?.canBeDownloaded
		}
	};
};

export const convertUpdateSerie = data => {
	return {
		...R.pick(["title", "categoryId", "groupId", "description", "priceTags", "itemIds", "individualAccess"], data),
		meta: {
			...data.meta,
			canBeDownloaded: !!data?.meta?.canBeDownloaded
		}
	};
};
