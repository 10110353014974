import styled from "styled-components";

import { Button, Text } from "shared/ui-kit";

export const PopupDescription = styled(Text)`
	font-size: 15px;
	font-style: normal;
	line-height: 1.47;
	color: #8f9bb3;
`;

export const DialogButton = styled(Button)`
	width: 120px;
`;
