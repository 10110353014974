import { useMemo } from "react";

import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

// import { setupCache } from "axios-cache-adapter";

import appConfig from "config/appConfig";

import ApiError from "./ApiError";

import useJwt from "./jwt";

import { useUnAuthorizedInterceptor } from "../interceptors";

// const cache = setupCache({
// 	maxAge: 15 * 60 * 1000, // 15 mins
// 	exclude: {
// 		// Only exclude PUT, PATCH and DELETE methods from cache
// 		methods: ["put", "patch", "delete"]
// 	}
// });

interface OptionProps {
	customErrorHandlers?: any[];
}

const useHttpActions = (options?: OptionProps) => {
	const { injectJwt, injectBase } = useJwt();
	const interceptUnAuthorized = useUnAuthorizedInterceptor();

	return useMemo(() => {
		const errorInterceptors: any[] = options?.customErrorHandlers || [interceptUnAuthorized];

		const config: AxiosRequestConfig = {
			baseURL: appConfig.GLOBAL_CONSTANTS.BACKEND_API,
			// timeout: 5000,
			withCredentials: false,
			validateStatus: status => status >= 200 && status < 300
			// adapter: cache.adapter
		};

		const request = axios.create(config);

		request.interceptors.response.use(
			response => response,
			response => {
				if (response.response) {
					const { status, data } = response.response;
					const apiErrorInstance = new ApiError(status, data.errors || [], data.message);
					errorInterceptors.forEach(f => f(apiErrorInstance));
					throw apiErrorInstance;
				} else {
					throw Error(response.message);
				}
			}
		);
		return {
			get: <T>(url: string, params?: any, options?: AxiosRequestConfig): Promise<AxiosResponse<T>> => {
				return request.get(url, injectJwt({ params, ...options }));
			},
			post: <T>(url: string, data: any, options?: AxiosRequestConfig): Promise<AxiosResponse<T>> => {
				return request.post(url, data, injectJwt(options));
			},
			delete: <T>(url: string, data?: any, params?: any, options?: AxiosRequestConfig): Promise<AxiosResponse<T>> => {
				const config = { url, data, params, ...options };
				return request.delete(url, injectJwt(config));
			},
			put: <T>(url: string, data: any, params?: any, options?: AxiosRequestConfig): Promise<AxiosResponse<T>> => {
				return request.put(url, data, { params, ...injectJwt(options) });
			},
			patch: <T>(url: string, data: any, params?: any, options?: AxiosRequestConfig): Promise<AxiosResponse<T>> => {
				return request.patch(url, data, { params, ...injectJwt(options) });
			},
			getOriginal: <T>(url: string, params?: any, options?: AxiosRequestConfig): Promise<AxiosResponse<T>> => {
				return request.get(url, { params, ...injectBase(options) });
			},
			getWithCustomWorkspace: <T>(url: string, workspace: string, params?: any): Promise<AxiosResponse<T>> => {
				return request.get(url, { headers: { workspace, client: "communityUser" }, params });
			},
			postWithCustomWorkspace: <T>(url: string, workspace: string, data?: any): Promise<AxiosResponse<T>> => {
				return request.post(url, data, { headers: { workspace, client: "communityUser" } });
			},
			putWithCustomWorkspace: <T>(url: string, workspace: string, data?: any): Promise<AxiosResponse<T>> => {
				return request.put(url, data, { headers: { workspace, client: "communityUser" } });
			},
			putOriginal: <T>(
				url: string,
				data: any,
				params?: any,
				options?: AxiosRequestConfig
			): Promise<AxiosResponse<T>> => {
				return request.put(url, data, { params, ...options });
			},
			getBlob: (url: string, options?: AxiosRequestConfig): Promise<string> => {
				return axios({
					url,
					method: "GET",
					responseType: "blob",
					...injectJwt(options)
				}).then(response => window.URL.createObjectURL(new Blob([response.data], { type: "text/csv;charset=utf-8;" })));
			}
		};
	}, [injectJwt, injectBase, interceptUnAuthorized, options]);
};

export default useHttpActions;
