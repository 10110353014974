import React, { memo } from "react";

import { SkeletonBase } from "shared/Components/index";

import { FlexContainer } from "./style";

import { TableColumnLoaderType } from "../index";

interface ColumnSkeletonProps {
	type?: TableColumnLoaderType;
}

const Index: React.FC<ColumnSkeletonProps> = memo(({ type = TableColumnLoaderType.oneTextRow }): JSX.Element => {
	if (type === TableColumnLoaderType.imageWthTwoTextRows) {
		return (
			<FlexContainer>
				<SkeletonBase variant={"rect"} width={40} height={40} />
				<FlexContainer.Column className="with-image">
					<SkeletonBase width={"75%"} />
					<SkeletonBase width={"50%"} />
				</FlexContainer.Column>
			</FlexContainer>
		);
	}

	if (type === TableColumnLoaderType.imageWthOneTextRow) {
		return (
			<FlexContainer>
				<SkeletonBase variant={"rect"} width={40} height={40} />
				<FlexContainer.Column className="with-image">
					<SkeletonBase width={"50%"} />
				</FlexContainer.Column>
			</FlexContainer>
		);
	}

	if (type === TableColumnLoaderType.twoTextRows) {
		return (
			<FlexContainer.Column>
				<SkeletonBase width={"75%"} />
				<SkeletonBase width={"45%"} />
			</FlexContainer.Column>
		);
	}

	if (type === TableColumnLoaderType.menuDots) {
		return <SkeletonBase variant={"rect"} width={10} height={28} />;
	}

	if (type === TableColumnLoaderType.checkbox) {
		return <SkeletonBase variant={"rect"} width={24} height={24} />;
	}

	if (type === TableColumnLoaderType.expand) {
		return <SkeletonBase variant={"circle"} width={32} height={32} />;
	}

	// type === TableColumnLoaderType.oneTextRow or others
	return <SkeletonBase width="45%" />;
});

export default Index;
