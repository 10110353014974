import { RegularSizes } from "shared/types";

export const getResizedImage = (url: string, size: RegularSizes) => {
	let updatedUrl = url;
	if (updatedUrl && updatedUrl.includes("/uploads/")) {
		const paths = updatedUrl.split("/uploads/");
		if (paths?.length === 2) {
			updatedUrl = paths[0] + `/${size}/uploads/` + paths[1];
		}
	}

	return updatedUrl;
};
