import React from "react";

import styled, { css } from "styled-components";

import { ForumPostModel } from "types";

import { ReactComponent as LockIcon } from "assets/icons/icon-lock.svg";

import premiumItemBg from "assets/images/premium-item-bg.png";
import { usePosts } from "shared/hooks";

import { UnlockContentType } from "shared/types";
import { Box, Text } from "shared/ui-kit";

import { UnlockPremiumBtn } from "../index";

const Wrapper = styled(Box)<{ backgroundIcon?: string }>`
	height: 356px;
	margin-top: 20px;
	overflow: hidden;
	position: relative;
	width: 100%;

	${({ backgroundIcon }) => {
		return backgroundIcon
			? css`
					&:after {
						content: "";

						position: absolute;
						top: 0;
						left: 0;

						width: 100%;
						height: 100%;

						background: url(${premiumItemBg}) no-repeat center;
						filter: blur(25px);
					}
			  `
			: css`
					background-color: #edf1f7;
			  `;
	}}
`;

const ContentWrapper = styled(Box)`
	position: absolute;
	top: 0;
	left: 0;

	width: 100%;
	height: 100%;

	z-index: 2;

	display: flex;
	justify-content: center;
	align-items: center;

	${({ backgroundIcon }) =>
		backgroundIcon &&
		css`
			background-color: rgba(0, 0, 0, 0.45);
		`}
`;

const Content = styled(Box)`
	display: block;
`;

const LockIconWrapper = styled(Box)`
	display: flex;
	justify-content: center;

	use {
		fill: white;
	}
`;

const Title = styled(Text)`
	font-size: 22px;
	font-weight: bold;
	line-height: 1.45;
	color: #222b45;
	margin-top: 8px;
	text-align: center;
	&.white {
		color: #ffffff;
	}
`;

const Subtitle = styled(Text)`
	font-size: 13px;
	line-height: 1.54;
	color: #222b45;
	text-align: center;
	&.white {
		color: #ffffff;
	}
`;

const BtnWrapper = styled(Box)`
	margin: 20px auto 0 auto;

	display: flex;
	justify-content: center;
	button {
		display: flex;
		align-items: center;
		border-radius: 8px;
		height: 40px;
		font-size: 14px;
		font-weight: bold;
		line-height: 1.14;
		svg {
			width: 20px;
			height: 20px;
			margin-left: 4px;
		}
		&.white {
			color: #6173fe;
		}
	}
`;

interface LockedPostBoxProps {
	backgroundIcon?: string;
	post: ForumPostModel;
}

const LockedPostBox: React.FC<LockedPostBoxProps> = ({ post, backgroundIcon }) => {
	const { getPost } = usePosts();

	return (
		<Wrapper backgroundIcon={backgroundIcon}>
			<ContentWrapper backgroundIcon={backgroundIcon}>
				<Content>
					<LockIconWrapper>
						<LockIcon width={30} height={30} viewBox={"0 0 30 30"} fill={!!backgroundIcon ? "#ffffff" : "#6173fe"} />
					</LockIconWrapper>
					<Title className={!!backgroundIcon && "white"}>Premium Content</Title>
					<Subtitle className={!!backgroundIcon && "white"}>
						Pay to unlock this post or Subscribe <br />
						to unlock all premium content.
					</Subtitle>
					<BtnWrapper>
						<UnlockPremiumBtn
							premiumObject={post}
							teaserContent={post.teaserContent}
							premiumObjectType={UnlockContentType.POST}
							onUnlock={() => {
								getPost(`${post?.pid}`);
							}}
						/>
					</BtnWrapper>
				</Content>
			</ContentWrapper>
		</Wrapper>
	);
};

export default LockedPostBox;
