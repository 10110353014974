import { useCallback, useMemo } from "react";

import { AlbumDetailsType } from "types/AlbumContextValuesType";

import { useNotification } from "shared/hooks";
import { getScheduleDate } from "utils/getScheduleDate";
import { actionMethod, actionTypes, getValidationMessage } from "utils/getValidationMessage";

import { mergeDateAndTime } from "utils/serviceUtils/helpers";

import useAlbumApiService, { AlbumModel } from "./../services/useAlbumApiService";

import useUser from "./useUser";

import { useAlbumStore } from "../contexts";

import { CrudType, GetAlbumResponse } from "../types";

const useAlbums = () => {
	const { showMessage } = useNotification();
	const albumApiService = useAlbumApiService();

	const { getData: getUserData } = useUser();
	const { user } = getUserData();

	const store = useAlbumStore();
	const { setState } = useAlbumStore();

	const methods = useMemo(
		() => ({
			getAlbum: async ({
				id,
				shouldReturn,
				limit = 10,
				offset = 1
			}: {
				id: string;
				shouldReturn?: boolean;
				limit?: number;
				offset?: number;
			}) => {
				setState(ctx => ({ ...ctx, loadingData: true }));

				try {
					const { album } = await albumApiService.getAlbum(id, limit, offset);

					if (!album) {
						showMessage("Could not fetch your album data, please try again", 5);
					}

					if (shouldReturn) {
						setState(ctx => ({ ...ctx, loadingData: false }));
						return album;
					}

					setState(ctx => ({
						...ctx,
						albumDetailsDialog: {
							...ctx.albumDetailsDialog,
							open: true,
							id: album._id,
							data: album
						}
					}));
				} catch (error) {
					showMessage("Could not fetch your album data, please try again", 5);
				}

				setState(ctx => ({ ...ctx, loadingData: false }));
			},
			getAlbumDownloadUrls: async (id: string, data?: { musicIds: string | null; enableNames?: boolean }) => {
				return await albumApiService.getAlbumDownloadUrls(id, data);
			},
			getMoreAlbumTracks: async ({ id, limit, offset }: { id: string; limit: number; offset: number }) => {
				const album = await methods.getAlbum({ id, shouldReturn: true, limit, offset });

				if (album) {
					setState(ctx => ({
						...ctx,
						albumDetailsDialog: {
							...ctx.albumDetailsDialog,
							data: ctx.albumDetailsDialog.data && {
								...ctx.albumDetailsDialog.data,
								musics: [...ctx.albumDetailsDialog.data.musics, ...album.musics]
							}
						}
					}));
				}
			},
			getAlbumDetails: async (id: string, limit = 10, offset = 1) => {
				try {
					const { album } = await albumApiService.getAlbum(id, limit, offset);

					if (!album.hasOwnProperty("_id")) throw Error();

					if (offset > 1) {
						setState(ctx => ({
							...ctx,
							albumDetails: ctx.albumDetails && {
								...ctx.albumDetails,
								musics: [...ctx.albumDetails.musics, ...album.musics]
							}
						}));
					} else {
						setState(ctx => ({
							...ctx,
							albumDetails: album
						}));
					}
				} catch (error) {
					showMessage("Could not fetch your album data, please try again", 5);
				}
			},
			createAlbum: async (
				data,
				creatorPersona?: {
					firstName: string;
					lastName: string;
					_id: string;
				},
				isMemberView?: boolean
			) => {
				setState(ctx => ({ ...ctx, submitting: true }));

				const scheduleDate = isMemberView
					? getScheduleDate(data.time?.value, data.date) || undefined
					: mergeDateAndTime(data.albumScheduleDate, data.albumScheduleTime);

				const body = {
					type: "album",
					title: data.albumTitle,
					artist: data.artist,
					meta: data.albumPhoto
						? {
								mediaId: data.albumPhoto.mediaId,
								url: data.albumPhoto.url
						  }
						: undefined,
					description: data.description,
					private: data.privateAlbum,
					canBeDownloaded: !!data.canBeDownloaded,
					individualAccess: !!data.individualAccess,
					scheduleDate,
					releaseDate: data.releaseDate,
					genre: data.genre.map(g => (typeof g === "string" ? g : g.key)),
					musicIds: [],
					priceTags: data.priceTags
				};

				try {
					const { success, album } = await albumApiService.createAlbum(body);

					if (!success) {
						showMessage("Couldn't create album successfully.", 3);
						return;
					}

					showMessage(
						getValidationMessage({
							name: "Album",
							actionType: actionTypes.CRUD,
							actionMethod: actionMethod.created,
							emoji: "🎉"
						}),
						3
					);

					setState(ctx => ({
						...ctx,
						albums: [{ ...album, creatorPersona }, ...ctx.albums],
						albumCreateOpen: false,
						albumDetailsDialog: {
							open: true,
							id: album._id,
							deletedTracks: []
						},
						submitting: false,
						totalAlbumsCount: ctx.totalAlbumsCount + 1
					}));
				} catch (error) {
					showMessage((error as Error).message, 3);
				}
			},
			updateAlbum: async (id: string, body) => {
				setState(ctx => ({ ...ctx, submitting: true }));
				const updateBody = { ...body };
				body.musics && delete updateBody["musics"];

				const { success, album } = await albumApiService.updateAlbum(id, updateBody);

				if (!success) {
					showMessage("Couldn't update album successfully.", 3);
					return;
				}

				showMessage(
					getValidationMessage({
						name: "Album",
						actionType: actionTypes.CRUD,
						actionMethod: actionMethod.updated,
						emoji: "🎉"
					}),
					3
				);

				setState(ctx => ({
					...ctx,
					submitting: false,
					albums: ctx.albums.map(a => (a._id === id ? album : a)),
					albumDetails: ctx.albumDetails && {
						...ctx.albumDetails,
						musics: body.musics
					},

					editAlbumDetails: null
				}));

				return album;
			},
			updateTracksOrder: async (id: string, body) => {
				setState(ctx => ({ ...ctx, submitting: true }));
				const { success, album } = await albumApiService.updateAlbumOrder(id, body);

				if (!success) {
					showMessage("Couldn't update album successfully.", 3);
					return;
				}

				showMessage(
					getValidationMessage({
						name: "Album",
						actionType: actionTypes.CRUD,
						actionMethod: actionMethod.updated,
						emoji: "🎉"
					}),
					3
				);

				setState(ctx => ({
					...ctx,
					submitting: false,
					albums: ctx.albums.map(a => (a._id === id ? album : a)),
					editAlbumDetails: null
				}));
			},
			deleteAlbum: async (albumId: string) => {
				try {
					const { success } = await albumApiService.deleteAlbum(albumId);
					if (success) {
						setState(ctx => ({
							...ctx,
							albums: ctx.albums.filter(album => album._id !== albumId),
							deleteAlbumDetails: undefined,
							totalAlbumsCount: ctx.totalAlbumsCount - 1
						}));

						showMessage(
							getValidationMessage({
								name: "Album",
								actionType: actionTypes.CRUD,
								actionMethod: actionMethod.deleted,
								emoji: "🎉"
							}),
							3
						);
					}
				} catch (error) {
					showMessage("Could not delete album", 3);
				}
			},
			addTrackToAlbum: async (album: { value: string }, trackId: string) => {
				const track = await methods.getAlbum({ id: album.value, shouldReturn: true });
				if (track && !track.musicIds.some(m => m === trackId)) {
					await methods.updateAlbum(track._id, {
						musicIds: [...track.musicIds, trackId]
					});
				}
			},
			removeTrackFromAlbum: async (album: { value: string }, trackId: string) => {
				const track = await methods.getAlbum({ id: album.value, shouldReturn: true });
				if (track && !!track.musicIds.some(m => m === trackId)) {
					await methods.updateAlbum(track._id, {
						musicIds: track.musicIds.filter(music => music !== trackId)
					});
				}
			},
			getAlbums: async ({
				limit = 10,
				keyword = "",
				offset,
				creatorId,
				sortBy,
				paginate,
				notEmptyBy
			}: {
				limit?: number;
				keyword?: string;
				offset: number;
				creatorId?: string;
				paginate?: boolean;
				sortBy?: string;
				notEmptyBy?: string;
			}) => {
				setState(ctx => ({ ...ctx, isLoading: true }));

				try {
					const { albums } = await albumApiService.getAlbums({ limit, keyword, offset, creatorId, sortBy, notEmptyBy });

					setState(ctx => ({ ...ctx, albums: paginate && offset > 1 ? [...ctx.albums, ...albums] : albums }));
				} catch (error) {
					showMessage("Could not fetch albums data, please try again.", 3);
				}

				setState(ctx => ({ ...ctx, isLoading: false }));
			},
			readSong: async (url: string) => {
				try {
					return await albumApiService.readSong(url, methods.updateTrackProgress);
				} catch (error) {
					showMessage("Could not fetch track data, please try again.", 3);
				}
			},
			getAllAlbumsCount: async ({ notEmptyBy, keyword }: { notEmptyBy?: string; keyword?: string }) => {
				const { totalCount } = await albumApiService.getAllAlbumsCount({ notEmptyBy, keyword });
				setState({ totalAlbumsCount: totalCount });
			},
			setAlbumCreateOpen: (albumCreateOpen: AlbumModel | boolean) => {
				setState(ctx => ({ ...ctx, albumCreateOpen }));
			},
			setPage: (page: number) => {
				setState(ctx => ({ ...ctx, page }));
			},
			setAlbumsShowPerPage: (albumsShowPerPage: number) => {
				setState(ctx => ({ ...ctx, albumsShowPerPage }));
			},
			setDeleteAlbumDetails: (deleteAlbumDetails?: { id: string; name: string }) => {
				setState(ctx => ({ ...ctx, deleteAlbumDetails }));
			},
			setAlbumDetailsDialog: (albumDetailsDialog: AlbumDetailsType) => {
				setState(ctx => ({ ...ctx, albumDetailsDialog }));
			},
			setPlayTrack: (playTrack?: string) => {
				setState(ctx => ({ ...ctx, playTrack }));
			},
			setEditAlbumDetails: (editAlbumDetails: AlbumModel | GetAlbumResponse | null) => {
				setState(ctx => ({ ...ctx, editAlbumDetails }));
			},
			setTrackDownloaded: (trackDownloaded: boolean) => {
				setState(ctx => ({ ...ctx, trackDownloaded }));
			},
			setTracksChanged: (tracksChanged: boolean) => {
				setState(ctx => ({ ...ctx, tracksChanged }));
			},
			setTrackProgressDialog: (
				trackProgressDialog: {
					name: string;
					track: string;
					progress: number;
				}[]
			) => {
				setState(ctx => ({ ...ctx, trackProgressDialog }));
			},
			updateTrackProgress: (url: string, progress: number) => {
				setState(ctx => ({
					...ctx,
					trackProgressDialog: ctx.trackProgressDialog.map(tr => (tr.track === url ? { ...tr, progress } : tr))
				}));
			}
		}),
		[albumApiService, setState, showMessage]
	);

	const getData = useCallback(() => {
		return store;
	}, [store]);

	const isUserCouldManageAlbum = useMemo(
		() =>
			({
				creatorId,
				type = CrudType.CREATE,
				isCreator
			}: {
				creatorId?: string;
				type?: CrudType;
				isCreator?: boolean;
			}) => {
				return !!(
					user?.isGlobalModerator ||
					user?.isGlobalOwner ||
					user?.isGlobalAdmin ||
					(user?.isInfluencer &&
						(type === CrudType.CREATE || isCreator || (creatorId && creatorId === user?.personaDocId)))
				);
			},
		[user]
	);

	return { ...methods, getData, isUserCouldManageAlbum };
};

export default useAlbums;
