import { AxiosResponse } from "axios";

import { InvitationModel, InvitationModelResponse, SingleInvitaionModelResponse } from "../../types";

const convertInvitationsResponse = ({ data }: AxiosResponse<InvitationModelResponse>): InvitationModel[] =>
	data.invitations;

const convertSingleInvitaionResponse = ({ data }: AxiosResponse<SingleInvitaionModelResponse>): InvitationModel =>
	data.invitation;
export default { convertInvitationsResponse, convertSingleInvitaionResponse };
