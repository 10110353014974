import React from "react";

import { Box } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";

import { IntegrationCardContainer } from "../../Containers/Integrations/styles";

const LoadingSkeleton = () => (
	<IntegrationCardContainer>
		<Skeleton variant="rect" width="100%" height={164} />
		<Box padding="12px 0">
			<Skeleton width="25%" height={15} />
			<Skeleton width="75%" height={22} />
		</Box>
	</IntegrationCardContainer>
);

export default LoadingSkeleton;
