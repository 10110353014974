import React, { FC, useState } from "react";

import { Box, ButtonBase, ClickAwayListener, IconButton } from "@material-ui/core";
import { DragIndicator } from "@material-ui/icons";

import PauseIcon from "@material-ui/icons/Pause";

import { ReactComponent as SoundWaveIcon } from "assets/icons/iconSoundWave.svg";
import { ReactComponent as PlayTrackIcon } from "assets/icons/play-track.svg";
import { MoreActionsMenu, TrackInfoWrapper } from "modules/Manage/View/Components/AlbumDetailsDialog/style";
import { useSeries } from "shared/hooks";
import { TrackMeta, TrackModel } from "shared/types";
import { Icon, Text } from "shared/ui-kit";

import { secondsToHumanTime } from "utils/serviceUtils/helpers";

const TrackRow: FC<{
	data: TrackModel;
	order: number;
	handleRemove: (id: string) => void;
	lastItemRef: React.RefObject<HTMLDivElement> | null;
}> = ({ data, order, lastItemRef, handleRemove }) => {
	const [displayActions, setDisplayActions] = useState(false);
	const { setPlayPreview, getData: getSeriesData } = useSeries();
	const { playPreview } = getSeriesData();

	const meta = data.meta as TrackMeta;

	const handleClickAway = () => {
		displayActions && setDisplayActions(false);
	};

	const handlePauseTrack = () => {
		setPlayPreview();
	};

	const handlePlayTrack = () => {
		setPlayPreview({
			type: "TRACK",
			url: meta.track.url
		});
	};

	return (
		<TrackInfoWrapper ref={lastItemRef}>
			<Box className="track-name">
				<Box className="drag-icon-wrapper">
					<DragIndicator htmlColor="#c5cee0" />
				</Box>
				<Text variant="body2">{order}.</Text>

				{meta?.artwork?.url ? (
					<Box position="relative">
						<img src={meta.artwork.url} alt={data.title} />
						<Box className="media-type">
							<SoundWaveIcon />
						</Box>
					</Box>
				) : (
					<Box className="track-no-img">
						<SoundWaveIcon />
					</Box>
				)}
				<Box overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
					<Text variant="subtitle1">{data.title}</Text>
					<Text variant="body2">{meta.artist}</Text>
				</Box>
			</Box>
			<Box className="track-duration-actions">
				<Text variant="body2">{secondsToHumanTime(data.duration)}</Text>
				<Box display="flex" alignItems="center">
					{playPreview && playPreview.url === meta.track.url ? (
						<IconButton size="small" onClick={handlePauseTrack}>
							<PauseIcon htmlColor="#c5cee0" />
						</IconButton>
					) : (
						<IconButton size="small" onClick={handlePlayTrack}>
							<PlayTrackIcon fill="#C5CEE0" />
						</IconButton>
					)}
					<ClickAwayListener onClickAway={handleClickAway}>
						<Box position="relative">
							<IconButton onClick={() => setDisplayActions(!displayActions)}>
								<Icon name="ellipsis" group="filled" fill="#c5cee0" />
							</IconButton>
							<MoreActionsMenu lastItem={false} className={displayActions ? "show" : ""}>
								<ButtonBase className="action" onClick={() => handleRemove(data._id)}>
									Remove from Collection
								</ButtonBase>
							</MoreActionsMenu>
						</Box>
					</ClickAwayListener>
				</Box>
			</Box>
		</TrackInfoWrapper>
	);
};

export default TrackRow;
