import React from "react";

import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

import styled from "styled-components";

import { Icon } from "shared/ui-kit";

import { IColor } from "../interfaces";

const FontBackgroundColor = () => <Icon name="fill-drip" />;
const FontColor = () => <Icon name="font" />;

export type ColorPickerProps = {
	colorList: IColor[];
	columnCount: number;
	icon?: string;
	onSelectColor: (color: string) => void;
	tooltip: string;
};

const CustomColorPickerWrapper = styled.div`
	position: relative;

	.color-picker {
		&__row {
			display: flex;
			align-items: center;
		}

		&__item {
			display: inline-flex;
			width: 20px;
			height: 20px;

			border-radius: 4px;
			margin: 5px;

			cursor: pointer;

			box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.15);
		}
	}

	.ql-custom {
		&__dropdown {
			transition: opacity 0.5s ease-in-out;
			max-width: 220px;

			background: white;

			position: absolute;
			top: 100%;
			left: 0;
			z-index: 1501;
			padding: 5px 5px;

			box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.15);

			border-radius: 4px;

			&.show {
				visibility: visible;
				opacity: 1;
			}

			&.hide {
				visibility: hidden;
				opacity: 0;
			}
		}

		&__btn {
			width: 118px !important;
			height: 48px !important;
			border-radius: 4px;
			background: #f0faff !important;

			font-size: 16px;
			font-weight: bold;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.25;
			letter-spacing: normal;
			text-align: center;
			color: #1998d5 !important;

			margin: 0 0 0 16px;
		}
	}
`;

const ColorPicker = (props: ColorPickerProps) => {
	const { tooltip, colorList, columnCount, icon } = props;

	const [isShowDropdown, toggleDropdown] = React.useState<boolean>(false);
	let contentBlock: HTMLElement | null = null;

	const handleClickOutside = event => {
		if (isShowDropdown && contentBlock && !contentBlock.contains(event.target)) {
			toggleDropdown(false);
		}
	};

	const selectColor = (color: string) => {
		toggleDropdown(false);
		props.onSelectColor(color);
	};

	window.addEventListener("click", handleClickOutside);

	const rowCount = Math.ceil(colorList.length / columnCount);
	return (
		<CustomColorPickerWrapper
			className={"ql-formats color-picker"}
			ref={item => {
				contentBlock = item;
			}}
		>
			<Tooltip title={tooltip}>
				<IconButton
					disableRipple={true}
					className={"ql-formats ql-text-color"}
					onClick={() => toggleDropdown(!isShowDropdown)}
				>
					{icon === "font color" && <FontColor />}
					{icon === "font background color" && <FontBackgroundColor />}
				</IconButton>
			</Tooltip>
			<div className={`ql-custom__dropdown ${isShowDropdown ? "show" : "hide"}`}>
				{[...Array(rowCount)].map((item, key) => (
					<div key={key} className={"color-picker__row"}>
						{colorList.slice(key * columnCount, key * columnCount + columnCount).map(({ color, label }) => (
							<div
								key={label}
								className={"color-picker__item"}
								style={{ background: color }}
								onClick={() => selectColor(color)}
							/>
						))}
					</div>
				))}
			</div>
		</CustomColorPickerWrapper>
	);
};

ColorPicker.defaultProps = {
	colorList: [],
	columnCount: 3,
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	onSelectColor: (color: IColor) => ({}),
	tooltip: "Color"
};

export default ColorPicker;
