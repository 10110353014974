import { Box, ButtonBase } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import styled from "styled-components";

import { Button } from "shared/ui-kit";

export const EventContainer = styled(Box)`
	width: 100%;
	padding: 0 16px;
	max-width: 960px;
	margin: auto;

	${props => props.theme.breakpoints.up("md")} {
		padding: 0;
	}
`;

export const EventHeader = styled(Box)`
	background-color: #fff;
	border-radius: 12px;
	border: 1px solid rgba(197, 206, 224, 0.5);
	box-shadow: 0 2px 4px 0 rgb(0 0 0 / 8%);
`;

EventHeader.IconWrapper = styled(Box)`
	width: 100%;
	height: 180px;

	border-top-left-radius: 12px;
	border-top-right-radius: 12px;

	overflow: hidden;

	img {
		width: 100%;
		height: 180px;

		object-fit: cover;
	}

	${props => props.theme.breakpoints.up("sm")} {
		height: 480px;

		img {
			height: 480px;
		}
	}
`;

EventHeader.Info = styled(Box)`
	padding: 8px 16px 72px;
	position: relative;
	p {
		font-weight: 600;
		line-height: 1.6;
		color: #8f9bb3;
		margin-bottom: 4px;
	}
	h6 {
		color: #ff463d;
		margin-bottom: 4px;
		&.range {
			color: #6173fe;
		}
	}
	h4 {
		color: #222b45;
		margin-bottom: 4px;
	}
	${props => props.theme.breakpoints.up("sm")} {
		padding: 16px 16px 24px;
	}
`;

EventHeader.EventActions = styled(Box)`
	position: absolute;
	bottom: 16px;
	left: 12px;
	display: flex;
	align-items: center;

	${props => props.theme.breakpoints.up("sm")} {
		left: unset;
		bottom: unset;
		top: 12px;
		right: 16px;
	}
`;

EventHeader.ActionButton = styled(Button)`
	width: 120px;
	height: 40px;
	border-radius: 8px;
	margin: 0;
	margin-right: 6px;

	svg {
		fill: ${({ active, bgColor }) => (active ? "#ffffff" : bgColor)};
		width: 18px;
		height: 18px;
		margin-right: 6px;
	}

	${props => props.theme.breakpoints.up("sm")} {
		width: 140px;
		margin-right: 0;
		margin-left: 10px;
	}
`;

export const MoreActionsButton = styled(Button)`
	width: 40px !important;
	margin: 0;
	min-width: 0;
	padding: 0;
	svg {
		transform: rotate(90deg);
		margin-right: 0 !important;
	}
`;

export const EventInfo = styled(Box)`
	margin-top: 24px;
	h4 {
		margin-bottom: 24px;
		font-size: 20px;
	}

	${props => props.theme.breakpoints.up("md")} {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
	}
`;

export const EventDetails = styled(Box)`
	flex: 1;
	border-radius: 12px;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
	box-shadow: inset 0 0 0 0.5px #c5cee0;
	background-color: #fff;
	padding: 16px;
	margin-bottom: 20px;
	${props => props.theme.breakpoints.up("md")} {
		margin-bottom: 0;
		margin-right: 16px;
	}
`;

EventDetails.EventInfoRows = styled(Box)`
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
`;

EventDetails.EventInfoRow = styled(Box)`
	display: flex;
	align-items: center;
	margin-bottom: 24px;
	width: 100%;
	p {
		font-size: 15px;
		font-weight: 600;
		line-height: 1.6;
		color: #222b45;
	}
	.secondary {
		font-size: 13px;
		font-weight: 400;
		line-height: 1.23;
		color: #8f9bb3;
	}
	${props => props.theme.breakpoints.up("sm")} {
		width: calc(50% - 8px);
	}
`;

EventDetails.Description = styled(Box)`
	margin-bottom: 20px;
	font-size: 15px;
	line-height: 1.47;
	color: #222b45;
	margin-top: 14px;
	width: 90%;
	.title {
		font-weight: bold;
		margin-bottom: 10px;
	}
	.text {
		font-size: 0.9375rem;
		line-height: 1.33;
		margin: 0;
	}
`;

export const IconWrapper = styled(Box)`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 40px;
	height: 40px;
	border-radius: 24px;
	background-color: #edf1f7;
	margin-right: 16px;
	flex-shrink: 0;
	img {
		width: 32px;
		height: 32px;
		object-fit: cover;
		border-radius: 99%;
	}
`;

export const EventMembers = styled(Box)`
	width: 100%;
	${props => props.theme.breakpoints.up("md")} {
		width: 320px;
	}
`;

export const MembersList = styled(Box)`
	border-radius: 12px;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
	box-shadow: inset 0 0 0 0.5px #c5cee0;
	background-color: #fff;
	padding: 16px;
	margin-bottom: 24px;
`;

MembersList.MemberRow = styled(ButtonBase)`
	display: flex;
	align-items: center;
	width: 100%;
	text-align: left;
	justify-content: flex-start;
	margin-bottom: 20px;
	img {
		width: 48px;
		height: 48px;
		border-radius: 100px;
		object-fit: cover;
		margin-right: 16px;
	}
	h6 {
		font-size: 15px;
		font-weight: 600;
		line-height: 1.6;
		color: #222b45;
	}
	p {
		font-size: 13px;
		font-weight: 600;
		line-height: 1.85;
		color: #8f9bb3;
	}
`;

MembersList.SeeAll = styled(Button)`
	width: 100%;
`;
export const MoreEvents = styled(Box)`
	margin: 40px 0;
	padding-top: 24px;
	border-top: 1px solid rgb(197 206 224 / 30%);
	border-radius: 3px;
	h4 {
		margin-bottom: 24px;
		margin-left: 8px;
	}
`;

MoreEvents.SliderWrapper = styled(Box)`
	.arrows {
		top: 74px;
		backdrop-filter: blur(20px);
		background-color: rgba(0, 0, 0, 0.24);
		&.arrow-prev {
			left: -16px;
		}
		&.arrow-next {
			right: -16px;
		}
	}
`;

export const SkeletonBase = styled(Skeleton)`
	&.side-offset {
		margin: 0 5px;
	}
`;

export const EventLink = styled.a``;

export const EventText = styled.div`
	max-width: 200px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;
