import React, { ReactNode } from "react";

import styled, { css } from "styled-components";

import { Box } from "shared/ui-kit";

interface WidgetThemeCardProps {
	header: ReactNode;
	children: ReactNode;
	showBody: boolean;
}

interface WidgetThemeCardHeaderProps {
	expanded: boolean;
}

interface WidgetThemeCardBodyProps {
	show: boolean;
}

const WidgetThemeCardWrapper = styled(Box)`
	width: 100%;
	border-radius: 4px;
	border: solid 1px #e4e9f2;
`;

const WidgetThemeCardHeader = styled(Box)<WidgetThemeCardHeaderProps>`
	display: flex;
	justify-content: space-between;
	align-items: center;

	transition: background 0.5s linear;
	background: #f7f9fc;
	padding: 5px 16px;

	border-radius: 3px;
`;

const WidgetThemeCardBody = styled(Box)<WidgetThemeCardBodyProps>`
	padding: 16px 23px;
	transition: padding 0.5s ease-in-out, max-height 0.5s ease-in-out, opacity 0.25s linear 0.25s;

	${({ show }) =>
		show
			? css`
					overflow: visible;
					max-height: 200px;
					opacity: 1;
			  `
			: css`
					max-height: 0;
					opacity: 0;
					overflow: hidden;
					padding-top: 0;
					padding-bottom: 0;
			  `};
`;

const WidgetThemeCard: React.FC<WidgetThemeCardProps> = ({ showBody, header, children }) => (
	<WidgetThemeCardWrapper>
		<WidgetThemeCardHeader expanded={showBody}>{header}</WidgetThemeCardHeader>
		<WidgetThemeCardBody show={showBody}>{children}</WidgetThemeCardBody>
	</WidgetThemeCardWrapper>
);

export default WidgetThemeCard;
