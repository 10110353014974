import React, { Fragment } from "react";

import { ListItemText, Menu, MenuItem, MenuProps } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import { Button, ButtonProps } from "shared/ui-kit";

const StyledMenu = withStyles({
	paper: {
		border: "1px solid #d3d4d5"
	}
})((props: MenuProps) => (
	<Menu
		getContentAnchorEl={null}
		anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
		transformOrigin={{ horizontal: "center", vertical: "top" }}
		{...props}
	/>
));

const StyledMenuItem = withStyles((theme: any) => ({
	root: {
		"& .MuiListItemIcon-root, & .MuiListItemText-primary": {
			fontSize: 13
		},
		"&:focus": {
			"& .MuiListItemIcon-root, & .MuiListItemText-primary": {
				color: theme.palette.quaternary.contrastText
			},
			backgroundColor: theme.palette.quaternary.main
		}
	}
}))(MenuItem);

export type ButtonMenuProps = ButtonProps &
	Omit<MenuProps, "open"> & {
		menuId: string;
		customRender?: boolean;
		onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
		options: any;
	};

export default ({
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	disabled,
	options,
	children,
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	onClick,
	customRender,
	menuId,
	...rest
}: ButtonMenuProps): JSX.Element => {
	const [anchorEl, setAnchorEl] = React.useState(null);

	const handleClick = event => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const renderOptions = (option, index) => (
		<StyledMenuItem key={index}>
			<ListItemText onClick={option.onClick} primary={option.label} />
		</StyledMenuItem>
	);

	const customRenderOptions = (option, index) => <Fragment key={index}>{option}</Fragment>;

	return (
		<>
			<Button aria-controls={menuId} aria-haspopup="true" onClick={handleClick} {...rest}>
				{children}
			</Button>
			<StyledMenu autoFocus={false} id={menuId} anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
				{(options || []).map(customRender ? customRenderOptions : renderOptions)}
			</StyledMenu>
		</>
	);
};
