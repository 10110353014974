import React, { FC, useState } from "react";

import { ReactComponent as VyooLogo } from "assets/icons/vyoo-logo.svg";
import BitcoinLogo from "assets/images/bitcoin-logo.webp";

import MemberNotification from "modules/MemberHome/View/shared/MemberNotification";
import { useCommunity, useNotification, useRoutes, useUser } from "shared/hooks";
import { Button, Checkbox, Text } from "shared/ui-kit";

const Manage: FC = () => {
	const [isDeleteAccount, setIsDeleteAccount] = useState(false);
	const [loading, setLoading] = useState(false);

	const { deleteGlobalAccount, deleteAccount, getData: getUserData } = useUser();
	const { user } = getUserData();

	const { getData: getCommunityData } = useCommunity();
	const { isWhitelabelAppMode, isBitcoinMode } = getCommunityData();

	const { showMessage, getData: getNotificationData } = useNotification();
	const { message } = getNotificationData();

	const { logout } = useRoutes();

	const deleteUserAccount = async () => {
		setLoading(true);
		if (isWhitelabelAppMode) {
			await deleteAccount(user!.userId!);
		} else {
			await deleteGlobalAccount();
		}
		showMessage("All your data will be permanently deleted shortly.");
		setTimeout(async () => {
			await logout();
			setLoading(false);
		}, 3000);
	};

	return (
		<>
			<div className="flex justify-center px-4 py-10 md:py-[120px] md:px-10 mx-auto" style={{ maxWidth: "680px" }}>
				<div className="w-fit p-4 md:p-10 rounded-xl bg-white border border-gray-300 flex flex-col items-center">
					{isBitcoinMode ? (
						<i
							className="block bg-contain bg-no-repeat"
							style={{ width: "100px", height: "100px", backgroundImage: `url(${BitcoinLogo})` }}
						/>
					) : (
						<VyooLogo width={216} height={77} className="w-[216px] h-[77px]" />
					)}
					<Text variant="h1" className="mt-5">
						Manage Account
					</Text>
					<Text variant="body1" className="mt-1 text-center">
						If you want to permanently delete your account, you can do it here.
					</Text>
					<div className="border border-gray-200 p-5 rounded-xl flex items-start mt-5">
						<Checkbox
							checked={isDeleteAccount}
							onChange={e => {
								setIsDeleteAccount(e.target.checked);
							}}
						/>
						<div>
							<Text variant="label" className="font-semibold">
								Delete account
							</Text>
							<Text variant="body1 mt-2">
								When you delete your account, you will not be able to recover the content or info you’ve shared on the
								platform. All your data will be permanently deleted.
							</Text>
						</div>
					</div>
					<Button
						palette="primary"
						fullWidth
						removeSideMargin
						size="large"
						className="mt-5"
						onClick={deleteUserAccount}
						disabled={!isDeleteAccount || loading}
						loading={loading}
					>
						Delete account
					</Button>
				</div>
			</div>
			{!!message && <MemberNotification message={message} open onClose={() => showMessage("")} />}
		</>
	);
};

export default Manage;
