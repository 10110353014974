import React, { FC } from "react";

import { ButtonBase } from "@material-ui/core";

import { useCommunity } from "shared/hooks";
import { InPlaceModal, InPlaceModalProps, Text } from "shared/ui-kit";

import { ConfirmFooter, ConfirmHeader, StyledDivider, StyledParagraphText, StyledPopupOverlay } from "./style";

interface Props extends InPlaceModalProps {
	open: boolean;
}

interface ConfirmLeaveProps {
	open: boolean;
	popup?: boolean;
	onClose: () => void;
	handleLeavePage: () => void;
}

const ConfirmLeavePopupWrapper: FC<Props> = ({ open, children, onClose, modalHeight, ...props }) => (
	<InPlaceModal {...props} onClose={onClose} maxWidth="xs" open={open} modalHeight={modalHeight}>
		{children}
	</InPlaceModal>
);

const ConfirmLeavePopup: FC<ConfirmLeaveProps> = ({ open, onClose, handleLeavePage }) => {
	const { communityColors } = useCommunity();

	return (
		<StyledPopupOverlay open={open}>
			<ConfirmLeavePopupWrapper modalHeight={600} open={open} onClose={onClose}>
				<ConfirmHeader>
					<Text variant="h6">Leave Page?</Text>
				</ConfirmHeader>
				<StyledParagraphText variant="body1">
					Are you sure you want to leave this page? Your changes will be lost if you leave.
				</StyledParagraphText>
				<StyledDivider thickness={0.5} borderColor="#c5cee0" />
				<ConfirmFooter container justify="flex-end" primaryColor={communityColors.btn}>
					<ButtonBase onClick={onClose}>Stay on Page</ButtonBase>
					<ButtonBase onClick={handleLeavePage}>Leave Page</ButtonBase>
				</ConfirmFooter>
			</ConfirmLeavePopupWrapper>
		</StyledPopupOverlay>
	);
};

export default ConfirmLeavePopup;
