import styled from "styled-components";

export const Wrapper = styled.div`
	border-bottom: 1px solid white;
	border-right: 1px solid white;

	position: relative;

	cursor: pointer;

	&:nth-child(3n + 3) {
		border-right: none;
	}

	&:nth-last-child(1),
	&:nth-last-child(2),
	&:nth-last-child(3) {
		border-bottom: none;
	}
`;

export const CheckboxWrapper = styled.div`
	position: absolute;

	top: 10px;
	right: 10px;

	transition: opacity 0.25s ease-in-out;

	opacity: 0;

	&.active {
		opacity: 1;
	}
`;

export const AvatarWrapper = styled.div`
	width: 100%;
	height: 172px;

	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
`;
