import { IStateProps } from "utils/StateOf";

import createStore from "utils/createStore";

export interface Values {
	validating: boolean;
	isValidVideo: boolean;
}

export const initialState: IStateProps<Values> = {
	validating: false,
	isValidVideo: false
};

export default createStore<Values>({
	initialState
});
