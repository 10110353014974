import React, { useCallback } from "react";

import { css } from "styled-components";

import useCompanies from "modules/Marketplace/Data/hooks/useCompanies";
import { Dialog } from "shared/Components";
import { Text } from "shared/ui-kit";

import { CTAButton, ContentWrapper, DeleteText, DialogFooter } from "../style";

const DeleteCategoryDialog = () => {
	const { setDeleteDialog, deleteCompany, getData: getCompaniesData } = useCompanies();
	const { deleteDialog } = getCompaniesData();

	const handleClose = useCallback(() => setDeleteDialog({ data: undefined }), [setDeleteDialog]);

	return (
		<Dialog
			bodyCustomStyles={css`
				padding: 0 !important;
			`}
			title={
				<Text align="left" variant="h6">
					Delete Company?
				</Text>
			}
			open={!!deleteDialog}
			onClose={handleClose}
			hasBackButton={false}
		>
			<ContentWrapper>
				<DeleteText>Are you sure you want to permanently delete {deleteDialog?.name}?</DeleteText>
			</ContentWrapper>
			<DialogFooter mt={20}>
				<CTAButton onClick={handleClose} buttonTheme="outline" wide>
					Keep
				</CTAButton>
				{deleteDialog && (
					<CTAButton wide onClick={() => deleteCompany(deleteDialog)} id="deleteConfirmation">
						Delete
					</CTAButton>
				)}
			</DialogFooter>
		</Dialog>
	);
};

export default DeleteCategoryDialog;
