import { Box } from "@material-ui/core";

import styled from "styled-components";

import { FormContainer, Text } from "shared/ui-kit";

export const Container = styled(Box)`
	.MuiExpansionPanel-root {
		border: 0.5px solid #c5cee0;
		border-radius: 4px;
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
		&:before {
			display: none;
		}
	}

	.MuiExpansionPanelSummary-root {
		.MuiExpansionPanelSummary-expandIcon {
			order: 1;
		}
	}

	.MuiExpansionPanelSummary-content {
		.MuiTypography-root {
			font-weight: normal;
			color: #000000;
			font-size: 18px;
		}
	}
`;

export const AnalyticContentWrapper = styled(FormContainer)`
	margin-top: 0;
	padding: 0;
`;

export const BlockWrapper = styled(Box)`
	background: #ffffff;

	width: 100%;

	border-radius: 4px;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
	box-shadow: inset 0 0 0 0.5px #c5cee0;

	height: 304px;
`;

export const ManagePostBlock = styled(Box)`
	margin-top: 21px;
`;

export const Subtitle = styled(Text)`
	font-size: 15px;

	margin-top: 12px;
`;

export const BlockTitle = styled(Text)`
	font-size: 18px;
	font-weight: bold;
	line-height: 1.33;

	margin-top: 25px;
`;
