import React from "react";

import IconButton from "@material-ui/core/IconButton";
// import { idID } from "@material-ui/core/locale";
import MoreVertIcon from "@material-ui/icons/MoreVert";

// import {SortableContainer, SortableElement} from "react-sortable-hoc";
import { isEqual } from "lodash";
import map from "lodash/map";
import { AutoSizer, InfiniteLoader, MultiGrid } from "react-virtualized";
import styled, { css } from "styled-components";

import { Checkbox, FabButton, InPlaceModal, Loader, Menu } from "shared/ui-kit";

import TableCell from "./Cell";
import EmptyTable from "./EmptyTable";
import TableHeader from "./Header";

const VTLoaderContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`;

const GridWrapper = styled(AutoSizer)`
	height: 100%;
	.ReactVirtualized__Grid.ReactVirtualized__Table__Grid {
		outline: none;
	}
	.FixedGridCell {
		&_empty {
			display: none !important;
		}
		&_inner-row {
			> .MuiTableCell-root {
				padding: 0;
				height: inherit;
			}
		}
		.explorer-table.table-cell {
			width: 100%;
		}
	}
	${props => {
		const customStyle = props.customStyle || "";
		return css`
			.ReactVirtualized__Grid {
				${customStyle}
			}
		`;
	}}
`;

const Actions = ({ itemActions, customItemActions, ...props }) => {
	const options = map(itemActions, action => ({
		...action,
		onClick: e => action.onClick(props, e)
	}));

	if (customItemActions) {
		return customItemActions(props);
	}

	return (
		<Menu
			options={options}
			transformOrigin={{
				horizontal: "right",
				vertical: "top"
			}}
		>
			<IconButton aria-label="more" aria-controls="long-menu" aria-haspopup="true">
				<MoreVertIcon />
			</IconButton>
		</Menu>
	);
};

const defaultProps = {
	headerHeight: 48,
	autoHeight: false,
	height: 700,
	rowHeight: 72,
	width: 800
};

const Component = props => {
	const _multiGrid = React.useRef<any>(null);
	let _autoSizer: any = null;

	const { data, checkedRows, hideHeader, columns, onLastRowScrolled, autoHeight = defaultProps.autoHeight } = props;

	const {
		height: defaultHeight
		// width: defaultWidth
	} = defaultProps;
	// const width = props.width || defaultWidth;
	const height = props.height || defaultHeight;
	const headerHeight = props.headerHeight || defaultProps.headerHeight;
	const rowHeight = props.rowHeight || defaultProps.rowHeight;

	const [columnList, setColumnList] = React.useState<any[]>([]);
	const [autoColumnWidth, setAutoColumnWidth] = React.useState(0);

	const [checkableIndexes, setCheckableIndexes] = React.useState<any[]>([]);
	const [checkedIndexes, setCheckedIndexes] = React.useState<any[]>([]);

	const [expandedIndexes, setExpandedIndexes] = React.useState<any[]>([]);

	const onLastRowScrolledHandler = React.useCallback(
		(...value) => {
			if (onLastRowScrolled) {
				onLastRowScrolled(...value);
			}
		},
		[onLastRowScrolled]
	);

	React.useEffect(() => {
		let indexes: any[] = [];
		if (typeof props.isRowCheckable === "function") {
			data.forEach((currentData, index) => {
				if (props.isRowCheckable(currentData)) {
					indexes.push(index);
				}
			});

			setCheckableIndexes(indexes);
		} else {
			indexes = data
				.map((val, index) => {
					return !val._expandData ? index : null;
				})
				.filter(x => x !== null);

			setCheckableIndexes([...indexes]);
		}

		let expandableIndexes: number[] = [];
		if (typeof props.isRowExpandable === "function") {
			data.forEach((currentData, i) => {
				if (props.isRowExpandable(currentData)) {
					expandableIndexes.push(i);
				}
			});
		} else {
			expandableIndexes = data
				.map((val, index, originalArray) => {
					return index + 1 < originalArray.length && originalArray[index + 1]._expandData ? index : null;
				})
				.filter(x => x !== null);
		}
		const newColumnList = [...columns];

		if (props.checkable) {
			const width = props.checkableColumnWidth || 40;
			newColumnList[props.checkableColumnPosition === "left" ? "unshift" : "push"]({
				width,
				alignment: "left",
				disableSort: true,
				label: "",
				Cell: item => {
					const itemStyle: any = { padding: "16px 0" };
					const correctIndex = item.rowIndex;

					if (props.hideNonCheckables) {
						if (!checkableIndexes.includes(correctIndex)) {
							itemStyle.display = "none";
						}
					}

					return (
						<Checkbox
							checked={isRowChecked(correctIndex)}
							onChange={() => setRowSelectedById(correctIndex)}
							style={itemStyle}
						/>
					);
				},
				Header: () => {
					const itemStyle: any = { padding: "16px 0" };
					if (props.hideNonCheckables) {
						if (checkableIndexes.length === 0) {
							itemStyle.display = "none";
						}
					}
					const visibleCount = data.filter((x, index) => !x._expandData && isRowCheckable(index)).length;
					return (
						<Checkbox
							checked={checkedIndexes.length > 0 && checkedIndexes.length === visibleCount}
							onChange={setAllRowsSelected}
							style={itemStyle}
						/>
					);
				},
				dataKey: "__checkbox__"
			});
		}

		if (props.expandable) {
			newColumnList.push({
				width: 60,
				alignment: "center",
				disableSort: true,
				Cell: item => {
					const itemStyle: any = {};
					if (item.rowData._expandData || !expandableIndexes.includes(item.rowIndex)) {
						itemStyle.display = "none";
					}

					return (
						<FabButton
							size={"small"}
							style={itemStyle}
							palette={"fabArrowDark"}
							expanded={isRowExpanded(item.rowIndex)}
							onClick={e => toggleCollapsible(e, item.rowIndex, item.rowData)}
						/>
					);
				},
				dataKey: "__expand__"
			});
		}

		if ((props.itemActions && props.itemActions.length) || props.customItemActions) {
			newColumnList.push({
				width: 70,
				alignment: "center",
				disableSort: true,
				Cell: item => {
					const itemStyle: any = {};
					if (item.rowData._expandData) {
						itemStyle.display = "none";
					}

					return <Actions customItemActions={props.customItemActions} itemActions={props.itemActions} {...item} />;
				},
				label: "",
				style: { padding: "16 0" },
				dataKey: "__actions__"
			});
		}

		setColumnList([...newColumnList]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [columns, data, props, checkedIndexes]);

	React.useEffect(() => {
		if (!isEqual(checkedIndexes, checkedRows)) {
			setCheckedIndexes([...checkedRows]);
		}
	}, [checkedRows, checkedIndexes]);

	React.useEffect(() => {
		_multiGrid.current.forceUpdate();
	}, [checkedIndexes]);

	React.useEffect(() => {
		_multiGrid.current.recomputeGridSize();
	}, [expandedIndexes]);

	React.useEffect(() => {
		rebuildTable();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [columnList]);

	const isRowCheckable = index => {
		return checkableIndexes.includes(index);
	};

	const isRowChecked = index => {
		return checkedIndexes.includes(index);
	};

	const isRowExpanded = index => {
		return expandedIndexes.includes(index);
	};

	// const isRowSortable = index => {
	//   return index >= 0; // Header row should not be draggable
	// };

	const setRowSelectedById = index => {
		const pos = checkedIndexes.findIndex(x => x === index);
		if (pos > -1) {
			checkedIndexes.splice(pos, 1);
		} else {
			checkedIndexes.push(index);
		}

		if (props.onCheckItem) {
			props.onCheckItem(index, checkedIndexes);
		}

		setCheckedIndexes([...checkedIndexes]);
	};

	const setAllRowsSelected = ({ target: { checked } }) => {
		if (checked) {
			data.forEach((data, index) => {
				if (isRowCheckable(index)) {
					const pos = checkedIndexes.findIndex(x => x === index);
					if (pos === -1) {
						checkedIndexes.push(index);
					}
				}
			});
		} else {
			checkedIndexes.splice(0, checkedIndexes.length);
		}
		setCheckedIndexes([...checkedIndexes]);

		if (props.onCheckAll) {
			props.onCheckAll([...checkedIndexes]);
		}
	};

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const toggleCollapsible = (event, index, rowData) => {
		const pos = expandedIndexes.findIndex(x => x === index);
		if (pos > -1) {
			expandedIndexes.splice(pos, 1);
		} else {
			expandedIndexes.push(index);
		}
		setExpandedIndexes([...expandedIndexes]);

		// if (!rowData._expandData) {
		//   props.onToggleCollapsible(index, rowData);
		//   setCheckableData([...checkableData]);
		// }
	};

	// const SortableTable = SortableContainer(Table, {
	//   withRef: true
	// });
	// const SortableRow = SortableElement(props => (
	//     <Fragment>{defaultTableRowRenderer(props)}</Fragment>
	// ));

	// const rowRenderer = props => {
	//   const { index } = props;
	//   return isRowSortable(index) ? (
	//       <SortableRow {...props} />
	//   ) : (
	//       defaultTableRowRenderer(props)
	//   );
	// };

	// const reorderRow = ({ newIndex, oldIndex, collection, isKeySorting }) => {
	//   if (newIndex === oldIndex) {
	//     return;
	//   }
	//   if (props.onReorder) props.onReorder(newIndex, oldIndex);
	// };

	const _cellRenderer = ({ columnIndex, key, rowIndex, style }) => {
		if (rowIndex === 0) {
			if (!hideHeader) {
				return _cellRendererHeaders({ columnIndex, key, rowIndex, style });
			}

			return null;
		} else {
			if (rowIndex > 1 && data[rowIndex - 1]._expandData && !isRowExpanded(rowIndex - 2)) {
				return null;
			}

			return _cellRendererBody({ columnIndex, key, rowIndex, style });
		}
	};

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const _cellRendererHeaders = ({ columnIndex, key, rowIndex, style }) => {
		const columnProps = columnList[columnIndex];
		const { Header, ...otherColProps } = columnProps;

		return (
			<div className={"FixedGridCell"} key={key} style={style}>
				<TableHeader Header={Header} height={headerHeight} {...otherColProps} />
			</div>
		);
	};

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const _cellRendererBody = ({ columnIndex, key, rowIndex, style, ...rest }) => {
		const dataIndex = rowIndex - 1;

		const columnProps = columnList[columnIndex];
		const { Cell, ...otherColProps } = columnProps;
		const cellProps = {
			rowData: data[dataIndex],
			columnIndex,
			rowIndex: dataIndex
		};

		if (data[dataIndex]._expandData) {
			const expandedColumnIndex = columnList.findIndex(x => x.dataKey && x.dataKey === "__expanded__");
			if (isRowExpanded(dataIndex - 1) && expandedColumnIndex === columnIndex) {
				return (
					<div
						className={"FixedGridCell FixedGridCell_inner-row"}
						key={key}
						style={{ ...style, width: "100%", left: 0 }}
					>
						<TableCell Cell={Cell} {...cellProps} {...otherColProps} alignment={"left"} />
					</div>
				);
			}

			return null;
		}

		return (
			<div className={"FixedGridCell"} key={key} style={style}>
				<TableCell Cell={Cell} height={rowHeight} {...cellProps} {...otherColProps} />
			</div>
		);
	};

	const _columnWidth = ({ index }) => {
		let width = autoColumnWidth;
		if (columnList[index] && !isNaN(columnList[index].width)) {
			width = columnList[index].width;
		}
		const minWidth = _columnMinWidth({ index });
		const maxWidth = _columnMaxWidth({ index });
		if (minWidth && width < minWidth) {
			width = minWidth;
		}
		if (maxWidth && width > maxWidth) {
			width = maxWidth;
		}
		return width;
	};

	const _columnMinWidth = ({ index }) => {
		if (columnList[index] && !isNaN(columnList[index].minWidth)) {
			return columnList[index].minWidth;
		}
		return undefined;
	};

	const _columnMaxWidth = ({ index }) => {
		if (columnList[index] && !isNaN(columnList[index].maxWidth)) {
			return columnList[index].maxWidth;
		}
		return undefined;
	};

	const _rowHeight = React.useMemo(
		() =>
			({ index }) => {
				let correctIndex = index;

				if (correctIndex === 0) {
					return hideHeader ? 0 : headerHeight;
				}

				correctIndex = correctIndex - 1;
				if (correctIndex < data.length) {
					if (data[correctIndex]._expandData && !isRowExpanded(correctIndex - 1)) {
						return 0;
					}

					if (data[correctIndex]._expandData && isRowExpanded(correctIndex - 1)) {
						const {
							oneRowHeight = 72,
							rowHeight: innerRowHeight = 72,
							data: innerTableData = []
						} = data[correctIndex]._expandData;

						if (innerRowHeight) {
							return innerRowHeight;
						}

						if (oneRowHeight && innerTableData.length) {
							return oneRowHeight * innerTableData.length;
						}
					}

					return rowHeight;
				}

				return 0;
			},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[expandedIndexes, data]
	);

	const rebuildTable = (d: any = {}) => {
		let { width } = d;
		if (!width && _autoSizer && _autoSizer?.state) {
			width = _autoSizer.state.width;
		}

		if (columnList.length) {
			let busySpace = 0;
			let columnCount = 0;
			columnList.forEach(column => {
				if (column?.width || column?.maxWidth) {
					busySpace += column?.maxWidth || column?.width;
					columnCount += 1;
				}
			});

			const correctColumnCount = columnList.length - columnCount - (props.expandable ? 1 : 0);
			const columnWidth = correctColumnCount ? (width - busySpace) / correctColumnCount : 0;

			if (columnWidth && columnWidth !== autoColumnWidth) {
				setAutoColumnWidth(columnWidth < 80 ? 80 : columnWidth);
			}
		}

		if (_multiGrid) {
			_multiGrid.current.recomputeGridSize();
			_multiGrid.current.forceUpdate();
		}
	};

	const _onSectionRendered = ({ rowStopIndex }) => {
		if (data && data.length && rowStopIndex >= data.length - 1) {
			onLastRowScrolledHandler(data.length);
		}
	};

	return (
		<>
			<InfiniteLoader
				isRowLoaded={({ index }) => {
					if (data && data.length && index >= data.length) {
						onLastRowScrolledHandler(data.length);
					}
					return !!data[index];
				}}
				loadMoreRows={() => {}}
				rowCount={10000}
				style={{ height: "100%" }}
			>
				{/* eslint-disable-next-line @typescript-eslint/no-unused-vars */}
				{({ onRowsRendered, registerChild }) => (
					<GridWrapper
						disableHeight={!autoHeight}
						onResize={rebuildTable}
						ref={ref => (_autoSizer = ref)}
						customStyle={props.customStyle}
					>
						{({ width, height: autosizeHeight }) => (
							<MultiGrid
								width={width}
								height={autoHeight ? autosizeHeight : height}
								noContentRenderer={() => <EmptyTable />}
								cellRenderer={_cellRenderer}
								columnWidth={_columnWidth}
								columnCount={columnList.length}
								fixedRowCount={hideHeader ? 0 : 1}
								fixedColumnCount={0}
								overscanRowCount={10}
								// ref={ref => {_multiGrid = ref}}
								ref={_multiGrid}
								rowHeight={_rowHeight}
								rowCount={data.length + 1}
								onSectionRendered={_onSectionRendered}
							/>
						)}
					</GridWrapper>
				)}
			</InfiniteLoader>
			<InPlaceModal open={props.showLoader} onClose={() => {}} modalHeight={height > 500 ? 500 : height}>
				<VTLoaderContainer>
					<Loader show={true} color="primary" />
				</VTLoaderContainer>
			</InPlaceModal>
		</>
	);
};

export default Component;
