import React from "react";

import { INVITE } from "modules/Members/constants";

import { DefaultActions, QRCode, ShareableLink, ShareableLinkList, ViaEmail } from "../InviteActions";
import { MemberActions } from "../MemberActionModal";

const switchContent = (modalContent: string) => {
	switch (modalContent) {
		case INVITE.INVITE_VIA_EMAIL:
			return <ViaEmail />;
		case INVITE.INVITE_VIA_SHAREABLE_LINK:
			return <ShareableLink />;
		case INVITE.SHAREABLE_LINK_LIST:
			return <ShareableLinkList />;
		case INVITE.INVITE_VIA_QR_CODE:
			return <ShareableLink />;
		case INVITE.QR_CODE_LIST:
			return <ShareableLinkList />;
		case INVITE.QR_CODE_VIEW:
			return <QRCode />;
		case INVITE.DEFAULT:
			return <DefaultActions />;
		default:
			return <MemberActions />;
	}
};

export const ModalContent = ({ modalContent }) => <>{switchContent(modalContent)}</>;
