import { useCallback, useMemo } from "react";

import { ContactType } from "types";

import { useFundraiserContext } from "shared/contexts";
import { DonationDialogInterface } from "shared/contexts/FundraiserContext/FundraiserContext";
import { useNotification } from "shared/hooks";
import { useFundraisersApiService } from "shared/services";
import { FundraiserModel } from "shared/types";
import { FundraiserDonationSortBy } from "shared/types/FundraiserLeaderboardTypes";
import { actionMethod, actionTypes, getValidationMessage } from "utils/getValidationMessage";

const useFundraisers = () => {
	const { setState, setInitial, ...store } = useFundraiserContext();
	const fundraiserApiService = useFundraisersApiService();

	const { showMessage } = useNotification();

	const methods = useMemo(
		() => ({
			getFundraisers: async ({
				offset,
				limit,
				keyword,
				paginate
			}: {
				offset: number;
				limit: number;
				keyword?: string;
				paginate?: boolean;
			}) => {
				try {
					setState({ loading: true });

					const { fundraisers } = await fundraiserApiService.getFundraisers(offset, limit, keyword);

					setState(ctx => ({
						fundraisers: paginate && offset > 1 ? [...ctx.fundraisers, ...fundraisers] : fundraisers
					}));
				} catch (err: any) {
					if (err.status !== 500) showMessage(err.message, 5, true);
					console.error(err);
				}

				setState({ loading: false });
			},
			getDonationsCount: async ({
				startDate,
				endDate,
				fundraiserId,
				keyword
			}: {
				startDate?: Date;
				endDate?: Date;
				keyword?: string;
				fundraiserId?: string;
			}) => {
				try {
					const { count } = await fundraiserApiService.getDonationsCount({
						keyword,
						startDate,
						endDate,
						fundraiserId
					});

					return count;
				} catch (err: any) {
					if (err.status !== 500) showMessage(err.message, 5, true);
					console.error(err);
				}
			},
			getFundraisersCount: async (keyword?: string) => {
				try {
					const { totalCount } = await fundraiserApiService.getFundraisersCount(keyword);
					setState({ fundraisersCount: totalCount });
				} catch (err: any) {
					if (err.status !== 500) showMessage(err.message, 5, true);
					console.error(err);
				}
			},
			getFundraiserMembers: async ({
				id,
				keyword,
				limit,
				offset,
				addContactCount,
				keepDuplicates,
				workspace
			}: {
				id: string;
				keyword?: string;
				limit?: number;
				offset?: number;
				addContactCount?: boolean;
				keepDuplicates?: boolean;
				workspace?: string;
			}) => {
				try {
					const { members } = await fundraiserApiService.getFundraiserMembers({
						id,
						keyword,
						limit,
						offset,
						addContactCount,
						keepDuplicates,
						workspace
					});
					return members;
				} catch (err: any) {
					if (err.status !== 500) showMessage(err.message, 5, true);
					console.error(err);
				}
			},
			getPaymentIntent: async ({
				name,
				amount,
				groupId,
				fundraiserId,
				personaId,
				uniqueIdentifierType,
				uniqueIdentifier,
				workspace
			}: {
				name: string;
				amount: number;
				groupId: string;
				fundraiserId: string;
				personaId: number;
				uniqueIdentifierType: string;
				uniqueIdentifier: string;
				workspace: string;
			}) => {
				try {
					const paymentIntent = await fundraiserApiService.getPaymentIntent({
						name,
						amount,
						groupId,
						fundraiserId,
						personaId,
						uniqueIdentifierType,
						uniqueIdentifier,
						workspace
					});
					return paymentIntent;
				} catch (err: any) {
					if (err.status !== 500) showMessage(err.message, 5, true);
					console.error(err);
				}
			},
			confirmDonation: async ({
				donationId,
				contactId,
				workspace
			}: {
				donationId: string;
				contactId?: string;
				workspace?: string;
			}) => {
				try {
					const { success } = await fundraiserApiService.confirmDonation({
						donationId,
						contactId,
						workspace
					});
					return success;
				} catch (err: any) {
					if (err.status !== 500) showMessage(err.message, 5, true);
					console.error(err);
				}
			},
			createFundraiser: async (fundraiser: Partial<FundraiserModel>) => {
				try {
					setState({ submitting: true });

					const data = await fundraiserApiService.createFundraiser(fundraiser);

					setState(ctx => ({
						submitting: false,
						fundraisersCount: ctx.fundraisersCount + 1
					}));

					showMessage(
						getValidationMessage({
							name: "Fundraiser",
							actionType: actionTypes.CRUD,
							actionMethod: actionMethod.created,
							emoji: "🎉"
						}),
						3
					);

					return data;
				} catch (err: any) {
					setState({ submitting: false });
					if (err.status !== 500) showMessage(err.message, 5, true);
					console.error(err);
				}
			},
			pauseFundraiser: async (id: string, action: "pause" | "unpause") => {
				try {
					const data = await fundraiserApiService.pauseFundraiser(id, action);

					showMessage(`Fundraiser has been successfully ${action}d`);

					return data;
				} catch (err: any) {
					if (err.status !== 500) showMessage(err.message, 5, true);
					console.error(err);
				}
			},
			updateFundraiser: async (id: string, fundraiser: Partial<FundraiserModel>) => {
				try {
					setState({ submitting: true });
					const data = await fundraiserApiService.updateFundraiser(id, fundraiser);

					setState({ submitting: false });

					return data;
				} catch (err: any) {
					setState({ submitting: false });
					if (err.status !== 500) showMessage(err.message, 5, true);
					console.error(err);
				}
			},
			updateFundraiserContacts: async (id: string, data: Partial<ContactType>) => {
				setState({ submitting: true });
				try {
					const { contact } = await fundraiserApiService.updateFundraiserContacts(id, data);

					setState({ submitting: false });

					return contact;
				} catch (err: any) {
					setState({ submitting: false });
					if (err.status !== 500) showMessage(err.message, 5, true);
					console.error(err);
				}
			},
			deleteFundraiserContacts: async (id: string) => {
				try {
					const { success } = await fundraiserApiService.deleteFundraiserContacts(id);

					return success;
				} catch (err: any) {
					setState({ submitting: false });
					if (err.status !== 500) showMessage(err.message, 5, true);
					console.error(err);
				}
			},
			inviteContact: async (
				data: {
					personaId: number;
					name: string;
					fundraiserId: string;
					contactInfo: {
						email?: string;
						phone?: string;
					};
					messageBody: string;
					relationship: string;
				},
				updateOnAddContact?: boolean
			) => {
				try {
					setState({ submitting: true });
					await fundraiserApiService.inviteContact(data);

					if (updateOnAddContact) {
						setState(ctx => ({
							fundraisers: ctx.fundraisers.map(fundraiser => {
								if (fundraiser._id === data.fundraiserId) {
									return {
										...fundraiser,
										totalContacts: fundraiser.totalContacts + 1
									};
								}
								return fundraiser;
							})
						}));
					}

					showMessage(
						getValidationMessage({
							name: "Contact",
							actionType: actionTypes.CRUD,
							actionMethod: actionMethod.invited,
							emoji: "🎉"
						}),
						3
					);
				} catch (err: any) {
					if (err.status !== 500) showMessage(err.message, 5, true);
					console.error(err);
				}

				setState({ submitting: false });
			},
			getLeaderboardMembers: async (id: string, customWorkspace?: string | null) => {
				try {
					const { members } = await fundraiserApiService.getLeaderboardMembers(id, customWorkspace);

					return members;
				} catch (err: any) {
					if (err.status !== 500) showMessage(err.message, 5, true);
					console.error(err);
				}
			},
			getLeaderboardGroups: async (id: string, customWorkspace?: string | null) => {
				try {
					const { groups } = await fundraiserApiService.getLeaderboardGroups(id, customWorkspace);

					return groups;
				} catch (err: any) {
					if (err.status !== 500) showMessage(err.message, 5, true);
					console.error(err);
				}
			},
			getLeaderboardSponsors: async (id: string, customWorkspace?: string | null) => {
				try {
					const { sponsors } = await fundraiserApiService.getLeaderboardSponsors(id, customWorkspace);

					return sponsors;
				} catch (err: any) {
					if (err.status !== 500) showMessage(err.message, 5, true);
					console.error(err);
				}
			},
			getMyContacts: async (fundraiserId: string, myContactsOnly?: boolean) => {
				try {
					const { contacts } = await fundraiserApiService.getMyContacts(fundraiserId, myContactsOnly);
					setState({ contacts });
				} catch (err: any) {
					if (err.status !== 500) showMessage(err.message, 5, true);
					console.error(err);
				}
			},
			getFundraisersDonations: async ({
				sortBy,
				startDate,
				endDate,
				fundraiserId,
				keyword,
				limit,
				offset,
				order = 1
			}: {
				offset: number;
				limit: number;
				sortBy?: FundraiserDonationSortBy;
				startDate?: Date;
				endDate?: Date;
				keyword?: string;
				fundraiserId?: string;
				order?: number;
			}) => {
				setState({ loading: true });

				try {
					const { donations } = await fundraiserApiService.getFundraisersDonations({
						sortBy,
						startDate,
						endDate,
						fundraiserId,
						keyword,
						limit,
						offset,
						order
					});

					setState({ loading: false });

					return donations;
				} catch (err: any) {
					if (err.status !== 500) showMessage(err.message, 5, true);
					setState({ loading: false });
					console.error(err);
				}
			},
			getFundraisersCSVFile: async ({
				sortBy,
				startDate,
				endDate,
				fundraiserId,
				keyword
			}: {
				sortBy?: FundraiserDonationSortBy;
				startDate?: Date;
				endDate?: Date;
				keyword?: string;
				fundraiserId?: string;
			}) => {
				setState({ downloading: true });

				try {
					const base64 = await fundraiserApiService.getFundraisersCSVFile({
						sortBy,
						startDate,
						endDate,
						fundraiserId,
						keyword
					});

					setState({ downloading: false });

					return base64;
				} catch (err: any) {
					if (err.status !== 500) showMessage(err.message, 5, true);
					setState({ downloading: false });
					console.error(err);
				}
			},
			deleteFundraiser: async (id: string) => {
				try {
					const response = await fundraiserApiService.deleteFundraiser(id);

					setState(ctx => ({ fundraisersCount: ctx.fundraisersCount - 1 }));

					showMessage(
						getValidationMessage({
							name: "Fundraiser",
							actionType: actionTypes.CRUD,
							actionMethod: actionMethod.deleted
						}),
						3
					);

					return response;
				} catch (err: any) {
					if (err.status !== 500) showMessage(err.message, 5, true);
					console.error(err);
				}
			},
			setSelectMemberDialog: (selectMemberDialog?: boolean) => {
				setState({ selectMemberDialog });
			},
			setDialog: (createUpdateDialog?: { open: boolean; model?: FundraiserModel }) => {
				setState({ createUpdateDialog });
			},
			setOffset: (offset: number) => {
				setState({ offset });
			},
			setFundraisersShowPerPage: (fundraisersShowPerPage: number) => {
				setState({ fundraisersShowPerPage });
			},
			donateToFundraiser: async (data, fundraiserId: string, workspace?: string) => {
				try {
					setState({ submitting: true });
					await fundraiserApiService.donateToFundraiser(data, workspace);

					const { fundraisers } = await fundraiserApiService.getFundraiserDetails(fundraiserId, workspace);

					showMessage("You have successfully donated to this fundraiser 🎉");

					setState(ctx => ({
						submitting: false,
						fundraiser: !!fundraisers?.length
							? fundraisers[0]
							: { ...ctx.fundraiser, totalDonated: ctx.fundraiser.totalDonated + data.card.amount / 100 }
					}));
				} catch (err: any) {
					setState({ submitting: false });
					console.error(err);
					if (err.status !== 500) {
						return err.message;
					} else {
						return "Couldn't donate to this fundraiser. Please try again later.";
					}
				}
			},
			openContact: async (id: string, workspace: string) => {
				try {
					await fundraiserApiService.openContact(id, workspace);
				} catch (err: any) {
					if (err.status !== 500) showMessage(err.message, 5, true);
					console.error(err);
				}
			},
			setInviteDialog: (inviteDialog?: {
				open: boolean;
				id: string;
				name: string;
				onBehalfOf?: { label: string; value: number; image?: { mediaId: string; url: string } };
			}) => {
				setState({ inviteDialog });
			},
			setSubmitting: (submitting?: boolean) => {
				setState({ submitting });
			},
			setDonationDialog: (donationDialog?: DonationDialogInterface) => {
				setState({ donationDialog });
			},
			setContacts: (contacts: ContactType[]) => {
				setState({ contacts });
			},
			setDonateSuccessfulDialog: (donateSuccessfulDialog?: DonationDialogInterface) => {
				setState({ donateSuccessfulDialog });
			},
			setMyContactsDialog: (myContactsDialog?: { open: boolean; personaId: number }) => {
				setState({ myContactsDialog });
			},
			getContactInfo: async (id: string, workspace?: string) => {
				try {
					const { contact } = await fundraiserApiService.getContactInfo(id, workspace);
					setState({ onBehalfOf: contact });
				} catch (err: any) {
					if (err.status !== 500) showMessage(err.message, 5, true);
					console.error(err);
				}
			},
			setOnBehalfOf: async (onBehalfOf?: ContactType) => {
				setState({ onBehalfOf });
			},
			getFundraiser: async (id: string, workspace?: string, dontUpdateState?: boolean) => {
				try {
					setState({ loading: true });

					const { fundraisers } = await fundraiserApiService.getFundraiserDetails(id, workspace);

					if (dontUpdateState) {
						return fundraisers[0];
					}

					setState({
						fundraiser: fundraisers[0]
					});
				} catch (err: any) {
					if (err.status !== 500) showMessage(err.message, 5, true);
					console.error(err);
				} finally {
					setState({ loading: false });
				}
			},
			setFullScreenVideoDialog: (fullScreenVideoDialog?: { open: boolean; url: string }) => {
				setState({ fullScreenVideoDialog });
			},
			setInviteDialogModel: (inviteDialogModel?: ContactType) => {
				setState({ inviteDialogModel });
			},
			getFundraiserSponsors: async ({ id, limit }: { id: string; limit?: number }) => {
				try {
					setState({ loadingSponsors: true });

					const { sponsors } = await fundraiserApiService.getFundraiserSponsors({ id, limit });
					return sponsors;
				} catch (err: any) {
					if (err.status !== 500) showMessage(err.message, 5, true);
					console.error(err);
				} finally {
					setState({ loadingSponsors: false });
				}
			},
			subscribe: async (params: { action: "unsub" | "resub"; contactId: string; workspace: string }) => {
				try {
					const data = await fundraiserApiService.subscribe(params);
					return data;
				} catch (err: any) {
					console.error(err);
				}
			},
			resetStore() {
				setInitial();
			}
		}),
		[fundraiserApiService, setState, showMessage, setInitial]
	);

	const getData = useCallback(() => {
		return store;
	}, [store]);

	return { ...methods, getData };
};

export default useFundraisers;
