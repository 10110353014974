import React, { memo } from "react";

import { ReactComponent as PlaceholderImageIcon } from "assets/icons/placeholder-image.svg";

import { PlaceholderImageType } from "shared/types";

import { Wrapper } from "./style";

interface PlaceholderImageProps {
	type: PlaceholderImageType;
	wrapClassName?: string;
	width?: number | string;
	height?: number | string;
	viewBox?: string;
	className?: string;
}

const PlaceholderImage: React.FC<PlaceholderImageProps> = memo(
	({ type, wrapClassName, width, className, height, viewBox }) => (
		<Wrapper className={`${wrapClassName ? wrapClassName : ""} type-${type}`}>
			<PlaceholderImageIcon
				width={width || 1112}
				height={height || 400}
				viewBox={viewBox || "0 0 1112 400"}
				className={className}
			/>
		</Wrapper>
	)
);

export default PlaceholderImage;
