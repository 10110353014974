import React from "react";

import { useLocation, useParams } from "react-router";

import { view } from "modules/MemberHome";

const FundraiserPage = () => {
	const { id } = useParams() as { id: string };

	const { search } = useLocation();
	const query = new URLSearchParams(search);

	const mode = query.get("mode") || undefined;
	const contactId = query.get("contactId");

	return <view.Fundraiser id={id} mode={mode} contactId={contactId} />;
};

export default FundraiserPage;
