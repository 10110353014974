import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";

import { Box, IconButton } from "@material-ui/core";

import { DateTime } from "luxon";

import { ReactComponent as DeleteIcon } from "assets/icons/icon-delete.svg";
import { ReactComponent as SyncIcon } from "assets/icons/icon-flip.svg";
import { ReactComponent as MailIcon } from "assets/icons/icon-mail.svg";
import { ReactComponent as BlockIcon } from "assets/icons/icon-slash.svg";

import { PageTemplate } from "modules/Manage/View/Components";
import { MenuDots, PlaceholderImage, TableWrapper } from "shared/Components";
import { TableColumnLoaderType } from "shared/Components/NewTable/Components";
import { Cell } from "shared/Components/NewTable/style";
import { useDebounce, useMembers, useUser } from "shared/hooks";

import { MemberActions, MemberStatus, MembersModel, PlaceholderImageType, ProfilePhotoSizes } from "shared/types";
import { Icon, Snackbar, Tag, Tooltip } from "shared/ui-kit";

import getCountMessage from "utils/getCountMessage";

import { getResizedImage } from "utils/serviceUtils/cdnImages";

import { AddMembersModal } from "./MembersModal/AddMembersModal";

import { StatusFilter, getStatusByValue } from "./StatusFilter";
import { BanIconWrapper, DropDownAndButtonWrapper, FilterRow, StatusText, UserDetails } from "./style";

import CantCreateDialog from "../../Components/CantCreateDialog";

const Table = React.lazy(() => import("shared/Components/NewTable"));

interface Props {
	showMethod?: boolean;
}

const Members: React.FC<Props> = memo(({ showMethod }) => {
	const { getData: getUserData } = useUser();
	const { userVerified, user } = getUserData();

	const [keyword, setKeyword] = useState<string>("");

	type TableMethodsHandler = React.ElementRef<typeof Table>;
	const tableRef = useRef<TableMethodsHandler | null>(null);

	const {
		getMembers,
		cancelInviteMember,
		resendInviteMember,
		setModalState,
		setIsInvited,
		setPage,
		setRole,
		setMemberShowPerPage,
		setShowToastMessage,
		setSelectMembers,
		getCommunityShareableLinkAndQRCode,
		getMemberTotalCount,
		bulkDeleteMember,
		bulkBanMember,
		bulkUnbanMember,
		markArchive,
		setStatusFilter,
		setShowInviteNotAllowedDialog,
		inviteMember,
		getData: getMembersData
	} = useMembers();

	const {
		toastMessage,
		users,
		showToastMessage,
		selectMembers,
		memberShowPerPage,
		page,
		loadingMembers,
		totalCount,
		enabledActions,
		statusFilter,
		communityName,
		showInviteNotAllowedDialog,
		isOpen
	} = getMembersData();

	const debouncedKeyword = useDebounce(keyword, 400);

	useEffect(() => {
		getMembers({
			offset: page,
			limit: memberShowPerPage,
			type: "members",
			keyword: debouncedKeyword,
			filterBy: statusFilter
		});
	}, [debouncedKeyword, getMembers, memberShowPerPage, page, statusFilter]);

	useEffect(() => {
		getMemberTotalCount({
			type: "members",
			keyword: debouncedKeyword,
			filterBy: statusFilter
		});
	}, [getMemberTotalCount, debouncedKeyword, statusFilter]);

	useEffect(() => {
		toastMessage && setShowToastMessage(true);
		(async () => {
			await getCommunityShareableLinkAndQRCode();
		})();
	}, [
		cancelInviteMember,
		resendInviteMember,
		toastMessage,
		setShowToastMessage,
		setIsInvited,
		getCommunityShareableLinkAndQRCode
	]);

	const handleAddMember = useCallback(() => {
		if (!userVerified) {
			setShowInviteNotAllowedDialog(true);
			return;
		}

		setModalState({ isOpen: true, modalTitle: "Choose method", modalContent: "default" });
		setIsInvited(false);
	}, [userVerified, setModalState, setIsInvited, setShowInviteNotAllowedDialog]);

	useEffect(() => {
		if (showMethod) {
			setModalState({ modalContent: "inviteViaEmail", modalTitle: "Invite Via Email" });
		}
	}, [showMethod, setModalState]);

	const handleSearch = useCallback(
		(keyword: string) => {
			if (keyword === "" || keyword.length >= 3) {
				setKeyword(keyword);
				setPage(1);
			}
		},
		[setPage]
	);

	const clearTableState = useCallback(() => {
		tableRef?.current?.clearCheckboxes && tableRef.current.clearCheckboxes();
	}, []);

	const promptActions = useCallback(
		({
			singleDelete = false,
			actionTitle,
			actionContent,
			actionConfirmMessage,
			actionApiRequest,
			actionApiRequestParams,
			type
		}: {
			singleDelete?: boolean;
			actionTitle?: string;
			actionContent?: string;
			actionConfirmMessage?: string;
			actionApiRequest: any;
			actionApiRequestParams?: any;
			type: MemberActions;
		}) => {
			const rmTxt = `${actionTitle}${!singleDelete ? "s" : ""}`;
			let userParams: string[] = [];
			if (!singleDelete) {
				userParams = users.reduce((total: string[], user) => {
					if (
						selectMembers.includes(user._id) &&
						((user.status === MemberStatus.pending && type === MemberActions.cancel) ||
							type === MemberActions.delete ||
							type === MemberActions.resend ||
							(user.status === MemberStatus.onboarded && type === MemberActions.archive))
					) {
						total.push(user._id);
						return total;
					} else if (selectMembers.includes(user._id) && user.status === MemberStatus.pending) {
						total.push(user.email);
						return total;
					}
					return total;
				}, []);
			}

			setModalState({
				modalTitle: `${rmTxt} From Community`,
				modalContent: `${actionContent}`,
				modalFooterActionsText: { cancelText: "Cancel", submitText: rmTxt },
				apiRequest: actionApiRequest,
				actionConfirmMessage: actionConfirmMessage,
				apiRequestParams: singleDelete
					? actionApiRequestParams
					: type === MemberActions.archive
					? { userIds: userParams, value: true }
					: userParams,
				successCallback: clearTableState
			});
		},
		[selectMembers, setModalState, users, clearTableState]
	);

	const getOptions = useCallback(
		(rowData: { _id; status; email; firstName; personaId }) => {
			const { _id, email, status, firstName, personaId } = rowData;

			const userIds: string[] = [_id];
			return [
				{
					name: status === MemberStatus.deleted ? "Invite Again" : "Resend Invitation",
					showOnPending: true,
					showOnJoined: false,
					showOnOnboarded: false,
					showOnArchive: false,
					showOnModerator: false,
					showOnAdmin: false,
					showOnOwner: false,
					showOnDeleted: true,
					showOnBanned: false,
					onClick: async () => {
						promptActions({
							singleDelete: true,
							actionTitle: status === MemberStatus.deleted ? "Invite" : "Resend Invitation",
							actionContent: "resendInviteContent",
							actionConfirmMessage: `Are you sure you want to ${
								status === MemberStatus.deleted ? "invite again" : "resend the invitation to"
							} ${email}?`,
							actionApiRequest: status === MemberStatus.deleted ? inviteMember : resendInviteMember,
							actionApiRequestParams:
								status === MemberStatus.deleted ? { emails: [email], slug: null, role: null } : userIds,
							type: MemberActions.resend
						});
					}
				},
				{
					name: "Cancel Invitation",
					showOnPending: true,
					showOnJoined: false,
					showOnOnboarded: false,
					showOnModerator: false,
					showOnAdmin: false,
					showOnOwner: false,
					showOnDeleted: false,
					showOnBanned: false,
					onClick: async () => {
						promptActions({
							singleDelete: true,
							actionTitle: "Cancel Invitation",
							actionContent: "cancelInviteContent",
							actionConfirmMessage: `Are you sure you want to revoke the invitation for ${email}?`,
							actionApiRequest: cancelInviteMember,
							actionApiRequestParams: userIds,
							type: MemberActions.cancel
						});
					}
				},
				{
					name: "Delete from community",
					showOnPending: false,
					showOnJoined: true,
					showOnOnboarded: true,
					showOnModerator: true,
					showOnArchive: true,
					showOnAdmin: true,
					showOnOwner: true,
					showOnBanned: false,
					showOnDeleted: false,
					onClick: async () => {
						promptActions({
							singleDelete: true,
							actionTitle: "Remove",
							actionContent: "removeMemberContent",
							actionConfirmMessage: `Are you sure you want to remove ${email} from your community? This will remove the user from the community entirely`,
							actionApiRequest: bulkDeleteMember,
							actionApiRequestParams: userIds,
							type: MemberActions.delete
						});
					}
				},
				{
					name: "Ban user",
					showOnPending: false,
					showOnJoined: true,
					showOnOnboarded: true,
					showOnModerator: true,
					showOnArchive: true,
					showOnAdmin: true,
					showOnOwner: true,
					showOnBanned: false,
					showOnDeleted: true,
					onClick: async () => {
						promptActions({
							singleDelete: true,
							actionTitle: "Ban User",
							actionContent: "banMember",
							actionConfirmMessage: `Are you sure you want to ban ${email} in your community?`,
							actionApiRequest: bulkBanMember,
							actionApiRequestParams: userIds,
							type: MemberActions.ban
						});
					}
				},
				{
					name: "Unban user",
					showOnPending: false,
					showOnJoined: false,
					showOnOnboarded: false,
					showOnModerator: false,
					showOnArchive: false,
					showOnAdmin: false,
					showOnOwner: false,
					showOnBanned: true,
					showOnDeleted: false,
					onClick: async () => {
						promptActions({
							singleDelete: true,
							actionTitle: "Unban User",
							actionContent: "unbanMember",
							actionConfirmMessage: `Are you sure you want to unban ${email} in your community?`,
							actionApiRequest: bulkUnbanMember,
							actionApiRequestParams: userIds,
							type: MemberActions.unban
						});
					}
				},
				{
					name:
						status !== MemberStatus.pending
							? status === MemberStatus.archive
								? "Activate user"
								: "Deactivate user"
							: "",
					showOnPending: false,
					showOnJoined: false,
					showOnOnboarded: true,
					showOnArchive: true,
					showOnModerator: true,
					showOnAdmin: true,
					showOnOwner: true,
					showOnBanned: false,
					showOnDeleted: false,
					onClick: async () => {
						const prefix = status !== MemberStatus.archive ? "de" : "";
						promptActions({
							singleDelete: true,
							actionTitle: status !== MemberStatus.archive ? "Deactivate Member" : "Activate Member",
							actionContent: "markArchive",
							actionConfirmMessage: `Are you sure you want to ${prefix}activate ${firstName}?`,
							actionApiRequest: markArchive,
							actionApiRequestParams: { userIds, value: status !== MemberStatus.archive },
							type: MemberActions.archive
						});
					}
				},
				{
					name: "Make as a Moderator",
					showOnPending: false,
					showOnJoined: false,
					showOnOnboarded: true,
					showOnArchive: false,
					showOnModerator: false,
					showOnAdmin: true,
					showOnOwner: true,
					showOnDeleted: false,
					showOnBanned: false,
					onClick: async () => {
						promptActions({
							singleDelete: true,
							actionTitle: "Make as a Moderator",
							actionContent: "removeMemberContent",
							actionConfirmMessage: `Are you sure you want to make ${firstName} as an Moderator for ${communityName}?`,
							actionApiRequest: setRole,
							actionApiRequestParams: { personaId, selectedRole: "Moderator" },
							type: MemberActions.editRole
						});
					}
				},
				{
					name: "Make as an Admin",
					showOnPending: false,
					showOnJoined: false,
					showOnOnboarded: true,
					showOnArchive: false,
					showOnModerator: false,
					showOnAdmin: false,
					showOnOwner: true,
					showOnDeleted: false,
					showOnBanned: false,
					onClick: async () => {
						promptActions({
							singleDelete: true,
							actionTitle: "Make as an Admin",
							actionContent: "removeMemberContent",
							actionConfirmMessage: `Are you sure you want to make ${firstName} as an Admin for ${communityName}?`,
							actionApiRequest: setRole,
							actionApiRequestParams: { personaId, selectedRole: "Admin" },
							type: MemberActions.editRole
						});
					}
				},
				{
					name: "Make as an Owner",
					showOnPending: false,
					showOnJoined: false,
					showOnOnboarded: false,
					showOnArchive: false,
					showOnModerator: false,
					showOnAdmin: false,
					showOnOwner: true,
					showOnDeleted: false,
					showOnBanned: false,
					onClick: async () => {
						promptActions({
							singleDelete: true,
							actionTitle: "Make as an Owner",
							actionContent: "removeMemberContent",
							actionConfirmMessage: `Are you sure you want to make ${firstName} as an Owner for ${communityName}?`,
							actionApiRequest: setRole,
							actionApiRequestParams: { personaId, selectedRole: "Owner" },
							type: MemberActions.editRole
						});
					}
				},
				{
					name: "Make as an Influencer",
					showOnPending: false,
					showOnJoined: false,
					showOnOnboarded: true,
					showOnArchive: false,
					showOnModerator: true,
					showOnAdmin: true,
					showOnOwner: true,
					showOnDeleted: false,
					showOnBanned: false,
					onClick: async () => {
						promptActions({
							singleDelete: true,
							actionTitle: "Make as an Influencer",
							actionContent: "removeMemberContent",
							actionConfirmMessage: `Are you sure you want to make ${firstName} as an Influencer for ${communityName}?`,
							actionApiRequest: setRole,
							actionApiRequestParams: { personaId, selectedRole: "Influencer" },
							type: MemberActions.editRole
						});
					}
				}
			].filter(item => {
				if (status === MemberStatus.pending) return item.showOnPending;
				if (status === MemberStatus.joined) return item.showOnJoined;
				if (status === MemberStatus.archive) return item.showOnArchive;
				if (status === MemberStatus.banned) return item.showOnBanned;
				if (status === MemberStatus.deleted) return item.showOnDeleted;
				if (user?.role === "Moderator") return item.showOnModerator;
				if (user?.role === "Admin") return item.showOnAdmin;
				if (user?.role === "Owner") return item.showOnOwner;
				return item.showOnOnboarded;
			});
		},
		[
			bulkDeleteMember,
			bulkBanMember,
			bulkUnbanMember,
			cancelInviteMember,
			communityName,
			user,
			markArchive,
			promptActions,
			resendInviteMember,
			inviteMember,
			setRole
		]
	);

	const tableColumns = useMemo(
		() => [
			{
				alignment: "left",
				label: (
					<Cell.HeaderText>
						{!!selectMembers.length
							? `${selectMembers.length} member${selectMembers.length > 1 ? "s are" : " is"} selected`
							: getCountMessage(totalCount, "Member")}
					</Cell.HeaderText>
				),
				minWidth: 260,
				Cell: ({ rowData: { id, firstName, lastName, photos, status, loggedInBy, personaId } }) => {
					const icon = photos?.length ? photos[0]?.profilePicture : undefined;

					const coverImgUrl = !!icon ? getResizedImage(icon, ProfilePhotoSizes.size200x200) : undefined;

					return (
						<Cell.Wrapper key={id}>
							<Cell.ImageWrapper>
								{status === MemberStatus.pending ? (
									<Icon fill="#8f9bb3" name="clock" />
								) : (
									<>
										{coverImgUrl ? (
											<Cell.Image src={coverImgUrl} />
										) : (
											<PlaceholderImage
												type={PlaceholderImageType.PROFILE_IMAGE}
												width={40}
												height={40}
												viewBox={"0 0 400 400"}
											/>
										)}
									</>
								)}
							</Cell.ImageWrapper>
							<Cell.Wrapper className="column with-image">
								<Cell.Text>
									{status === MemberStatus.pending || status === MemberStatus.joined
										? personaId || (!personaId && status === MemberStatus.joined)
											? "Onboarding Pending..."
											: "Invitation pending..."
										: `${firstName} ${lastName}`}
								</Cell.Text>
								<Cell.Text className="light">{loggedInBy}</Cell.Text>
							</Cell.Wrapper>
						</Cell.Wrapper>
					);
				},
				loaderTemplate: TableColumnLoaderType.imageWthTwoTextRows,
				dataKey: "name"
			},
			{
				alignment: "left",
				width: 120,
				label: <Cell.HeaderText>Profile created</Cell.HeaderText>,
				Cell: ({ rowData: { createdAt } }) => (
					<Cell.Wrapper>
						<Cell.Text>{createdAt ? DateTime.fromISO(createdAt).toFormat("LLL dd, yyyy") : "-"}</Cell.Text>
					</Cell.Wrapper>
				),
				dataKey: "createdAt"
			},
			{
				alignment: "left",
				minWidth: 150,
				maxWidth: 190,
				label: <Cell.HeaderText>Last Location</Cell.HeaderText>,
				Cell: ({ rowData: { city, state, lastLocation } }: { rowData: MembersModel }) => {
					let location: string = lastLocation || "";
					if (!lastLocation) {
						location = `${city} ${city && state ? ", " : ""} ${state}`.trim();
						// if (city) location = city;
						// if (state) location = `${location} ${state}`;
					}
					return (
						<Cell.Wrapper>
							<Cell.Text align="left" display="block" paragraph variant="body1">
								{location || "-"}
							</Cell.Text>
						</Cell.Wrapper>
					);
				},
				dataKey: "lastLocation"
			},
			{
				width: 150,
				alignment: "left",
				label: <Cell.HeaderText>Referred By</Cell.HeaderText>,
				Cell: ({ rowData: { referedBy } }: { rowData: MembersModel }) => (
					<Cell.Wrapper>
						<Cell.Text>{referedBy || "-"}</Cell.Text>
					</Cell.Wrapper>
				),
				dataKey: "referedBy"
			},
			{
				alignment: "left",
				label: <Cell.HeaderText>Status</Cell.HeaderText>,
				maxWidth: 115,
				Cell: ({ rowData: { status } }) => (
					<Cell.Wrapper>
						<StatusText align="left" display="block" paragraph variant="body1" status={status}>
							{getStatusByValue(status).label}
						</StatusText>
					</Cell.Wrapper>
				),
				dataKey: "status"
			},
			{
				alignment: "left",
				label: <Cell.HeaderText>Subscriber</Cell.HeaderText>,
				maxWidth: 100,
				Cell: ({ rowData: { subscriptionType } }) => (
					<Cell.Wrapper>
						<UserDetails align="left" display="block" paragraph variant="body1">
							<Tag isFilled label={subscriptionType} palette="basic" size="medium" />
						</UserDetails>
					</Cell.Wrapper>
				),
				dataKey: "subscriber"
			},
			{
				alignment: "right",
				minWidth: 50,
				maxWidth: 50,
				label: "",
				Cell: ({ rowData: { _id, status, email, firstName, personaId } }) => (
					<MenuDots
						options={getOptions({ _id, status, email, firstName, personaId })}
						vertical
						removeBg
						removeshadow
						removeSideMargin
					/>
				),
				loaderTemplate: TableColumnLoaderType.menuDots,
				dataKey: "menu"
			}
		],
		[getOptions, selectMembers, totalCount]
	);

	const handleChangePage = useCallback(
		(newPage: number) => {
			setPage(newPage);
		},
		[setPage]
	);

	const handleChangePageSize = useCallback(
		(newPageSize: number) => {
			setMemberShowPerPage(newPageSize);
			setPage(1);
		},
		[setMemberShowPerPage, setPage]
	);

	const onToggleCheckRow = useCallback(
		({ toggledIndex, checkedRows }: { toggledIndex?: number; checkedRows?: number[] }) => {
			if (toggledIndex !== undefined) {
				const user = users[toggledIndex];
				setSelectMembers(user._id);
			} else if (checkedRows) {
				setSelectMembers(checkedRows.length ? "all" : "none");
			}
		},
		[setSelectMembers, users]
	);

	const TableBlock = useMemo(
		() => (
			<TableWrapper sizes={{ horizontalTablet: 1350 }}>
				<Table
					ref={tableRef}
					columns={tableColumns}
					data={users}
					checkable
					onToggleCheckRow={onToggleCheckRow}
					loading={loadingMembers}
					paginated
					totalDataCount={totalCount}
					page={page}
					pageSize={memberShowPerPage}
					onChangePage={handleChangePage}
					onChangePageSize={handleChangePageSize}
				/>
			</TableWrapper>
		),
		[
			tableColumns,
			users,
			loadingMembers,
			totalCount,
			page,
			memberShowPerPage,
			handleChangePageSize,
			handleChangePage,
			onToggleCheckRow
		]
	);

	return (
		<>
			{!!isOpen && <AddMembersModal />}
			<PageTemplate
				title={"Community Members"}
				isLoading={loadingMembers}
				isNoData={!users || !users.length}
				emptyText={"You don’t have any Members in your community yet."}
				searchPlaceholder={"Search Members"}
				onSearchUpdate={handleSearch}
				actionText={"Add Member"}
				onCreateClick={handleAddMember}
				extraAction={
					<FilterRow>
						{selectMembers.length > 0 && (
							<Box>
								{enabledActions.includes(MemberActions.ban) && (
									<Tooltip text="Ban user">
										<IconButton
											onClick={() =>
												promptActions({
													actionTitle: "Ban User",
													actionContent: "banMember",
													actionConfirmMessage: `Are you sure you want to ban member${
														selectMembers.length > 1 ? "s" : ""
													} from your community?`,
													actionApiRequest: bulkBanMember,
													actionApiRequestParams: selectMembers,
													singleDelete: true,
													type: MemberActions.ban
												})
											}
										>
											<BanIconWrapper />
										</IconButton>
									</Tooltip>
								)}
								{enabledActions.includes(MemberActions.delete) && (
									<Tooltip text="Delete user">
										<IconButton
											onClick={() =>
												promptActions({
													actionTitle: "Remove Member",
													actionContent: "removeMemberContent",
													actionConfirmMessage: `Are you sure you want to remove member${
														selectMembers.length > 1 ? "s" : ""
													} from your community? This will remove the user from the community entirely`,
													actionApiRequest: bulkDeleteMember,
													actionApiRequestParams: selectMembers,
													singleDelete: false,
													type: MemberActions.delete
												})
											}
										>
											<DeleteIcon style={{ color: "#8f9bb3" }} />
										</IconButton>
									</Tooltip>
								)}
								{enabledActions.includes(MemberActions.archive) && (
									<Tooltip text="Archive user">
										<IconButton
											onClick={() => {
												promptActions({
													singleDelete: false,
													actionTitle: "Deactivate Member",
													actionContent: "markArchive",
													actionConfirmMessage: `Are you sure you want to deactivate selected member${
														selectMembers.length > 1 ? "s" : ""
													}?`,
													actionApiRequest: markArchive,
													type: MemberActions.archive
												});
											}}
										>
											<BlockIcon style={{ color: "#8f9bb3" }} />
										</IconButton>
									</Tooltip>
								)}
								{enabledActions.includes(MemberActions.cancel) && (
									<Tooltip text="Cancel invitation">
										<IconButton
											onClick={() =>
												promptActions({
													singleDelete: false,
													actionTitle: "Cancel Invitation",
													actionContent: "cancelInviteContent",
													actionConfirmMessage: `Are you sure you want to revoke the invitation${
														selectMembers.length > 1 ? "s" : ""
													} for selected member${selectMembers.length > 1 ? "s" : ""}?`,
													actionApiRequest: cancelInviteMember,
													type: MemberActions.cancel
												})
											}
										>
											<MailIcon style={{ color: "#8f9bb3" }} />
										</IconButton>
									</Tooltip>
								)}
								{enabledActions.includes(MemberActions.resend) && (
									<Tooltip text="Resend invitation">
										<IconButton
											onClick={() => {
												promptActions({
													singleDelete: false,
													actionTitle: "Resend Invitation",
													actionContent: "resendInviteContent",
													actionConfirmMessage: `Are you sure you want to send another invite to selected member${
														selectMembers.length > 1 ? "s" : ""
													}?`,
													actionApiRequest: resendInviteMember,
													type: MemberActions.resend
												});
											}}
										>
											<SyncIcon style={{ color: "#8f9bb3" }} />
										</IconButton>
									</Tooltip>
								)}
							</Box>
						)}
						<DropDownAndButtonWrapper>
							<StatusFilter onFilterChange={setStatusFilter} />
						</DropDownAndButtonWrapper>
					</FilterRow>
				}
			>
				{TableBlock}
			</PageTemplate>
			{toastMessage && (
				<Snackbar
					message={toastMessage}
					onClose={() => setShowToastMessage(false)}
					open={showToastMessage}
					showCloseButton
					transition="fade"
				/>
			)}
			{showInviteNotAllowedDialog && (
				<CantCreateDialog
					title="Inviting members not allowed"
					description="You can not invite members to your community before you verify your email address."
					handleClose={() => setShowInviteNotAllowedDialog(false)}
				/>
			)}
		</>
	);
});

export default Members;
