import React, { useEffect, useMemo, useRef, useState } from "react";

import { Box, Grid, TextField, withStyles } from "@material-ui/core";
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";

import { Controller, useForm } from "react-hook-form";

import { css } from "styled-components";

import { FooterWrapper } from "modules/Manage/View/Containers/style";
import { useCategories } from "modules/Marketplace/Data";
import useCompanies from "modules/Marketplace/Data/hooks/useCompanies";

import { Dialog } from "shared/Components";
import ConfirmLeavePopup from "shared/Components/ConfirmLeave";
import useConfirmLeavePopup from "shared/Components/ConfirmLeave/hooks/useConfirmLeavePopup";
import useScrollToXPosition from "shared/hooks/useScrollToXPosition";
import { useS3Uploader } from "shared/services/s3Uploader";
import { Icon, Input, Switch, Text, Uploader, orientationConst } from "shared/ui-kit";

import { dataUrlToFile } from "utils/serviceUtils/helpers";

import { validateURL } from "utils/serviceUtils/validators";

import { CTAButton, ContentWrapper, InputWrapper, PinnedBox, UploaderWrapper } from "../style";

const StyledAutoComplete = withStyles({
	tag: {
		backgroundColor: "#f7f9fc"
	}
})(Autocomplete);
interface CategoryProps {
	_id: string;
	name: string;
}

const CreateEditCompanyDialog = () => {
	const bodyRef = useRef<HTMLFormElement>();
	const scrollToXPositionOnFocus = useScrollToXPosition(bodyRef?.current);

	const [imageUploading, setImageUploading] = useState(false);
	const [pickedCategories, setPickedCategories] = useState<CategoryProps[]>([]);

	const {
		handleSubmit,
		control,
		formState: { errors, isDirty, isValid }
	} = useForm({
		mode: "onChange"
	});

	const { uploadFile } = useS3Uploader();
	const { setCreateUpdateCompanyOpen, createCompany, updateCompany, getData: getCompaniesData } = useCompanies();
	const { isLoading, companyImageUploadUrl, createUpdateCompanyOpen, editObject } = getCompaniesData();

	const { getData: getCategoriesData } = useCategories();
	const { categories } = getCategoriesData();

	const options = useMemo(
		() =>
			categories.map((cat, index) => ({
				key: cat._id,
				value: cat.name,
				id: index
			})),
		[categories]
	);

	const isEditMode = !!editObject;

	useEffect(() => {
		if (isEditMode && editObject) {
			const categoriesArray = editObject.categories
				.map(op => {
					const result = options.find(o => o.key === op._id);
					if (result) {
						return {
							_id: op._id,
							name: result.value
						};
					}

					return null;
				})
				.filter(val => val !== null) as CategoryProps[];

			setPickedCategories(categoriesArray);
		}
	}, [editObject, isEditMode, options]);

	const onSubmit = async data => {
		const { companyName, coverUrl, shortDescription, pinned, website } = data;

		if (isEditMode && editObject) {
			await updateCompany({
				_id: editObject._id,
				name: companyName,
				photo: coverUrl,
				categories: pickedCategories,
				pinned,
				url: website,
				description: shortDescription,
				active: editObject.active
			});
		} else {
			await createCompany({
				name: companyName,
				photo: coverUrl,
				categories: pickedCategories,
				pinned,
				url: website,
				description: shortDescription,
				active: true
			});
		}

		setCreateUpdateCompanyOpen({
			status: false,
			editObject: undefined
		});
		setPickedCategories([]);
	};

	const isDisabled = isEditMode
		? (!isDirty && !isValid) || !pickedCategories.length
		: !isValid || !pickedCategories.length;

	const displayErrorMessage = useMemo(
		() => (error?: { type: string; message?: string }) => {
			if (!error) return;
			const { message, type } = error;
			if (message) return message;
			if (type === "required") return "Field is required.";
			if (type === "maxLength") return "Max length exceeded for this field.";
		},
		[]
	);

	const onClose = () => {
		setPickedCategories([]);
		setCreateUpdateCompanyOpen({
			status: false,
			editObject: undefined
		});
	};

	const {
		handleLeavePageConfirmed,
		closeConfirmPopup,
		handleClose,
		getData: getConfirmLeavePopupData
	} = useConfirmLeavePopup({
		onClose,
		open: createUpdateCompanyOpen
	});
	const { showConfirmPopup } = getConfirmLeavePopupData();

	const categoriesValue = pickedCategories.map(cat => ({
		key: cat._id,
		value: cat.name
	}));

	const handleSelectChange = selectionOption => {
		const categoriesArray = selectionOption
			.map(op => {
				const result = options.find(o => o.key === op.key);
				if (result) {
					return {
						_id: op.key,
						name: result.value
					};
				}

				return null;
			})
			.filter(val => val !== null);

		setPickedCategories(categoriesArray);
	};

	const filteredOptions = options.filter(option => {
		const idx = pickedCategories.findIndex(c => c._id === option.key);
		if (idx === -1) return option;
		return null;
	});

	return (
		<>
			<ConfirmLeavePopup
				handleLeavePage={handleLeavePageConfirmed}
				open={showConfirmPopup}
				onClose={closeConfirmPopup}
				popup
			/>
			<Dialog
				bodyCustomStyles={css`
					padding: 0 !important;
				`}
				title={<Text variant="h5">{isEditMode ? "Edit Company Details" : "Create Company"}</Text>}
				open={createUpdateCompanyOpen}
				onClose={() => handleClose(isDirty)}
				hasBackButton={false}
				bodyRef={bodyRef}
				footer={
					<FooterWrapper className={isEditMode ? "cancelBtn" : ""}>
						{isEditMode && (
							<CTAButton onClick={() => handleClose(isDirty)} buttonTheme="outline">
								Cancel
							</CTAButton>
						)}
						<CTAButton
							disabled={imageUploading || isLoading || isDisabled || Object.keys(errors).length}
							type="submit"
							onClick={handleSubmit(onSubmit)}
							id={isEditMode ? "saveChanges" : "createCompany"}
						>
							{imageUploading
								? "Uploading Photo.."
								: isLoading
								? "Loading.."
								: `${isEditMode ? "Save Changes" : "Create Company"}`}
						</CTAButton>
					</FooterWrapper>
				}
			>
				<form onSubmit={handleSubmit(onSubmit)}>
					<ContentWrapper>
						<UploaderWrapper>
							<Controller
								name="coverUrl"
								control={control}
								rules={{
									required: "Cover is required."
								}}
								defaultValue={isEditMode && editObject?.photo}
								render={({ onChange, value }) => {
									const error = displayErrorMessage(errors.coverUrl);
									return (
										<>
											<Uploader
												urls={
													!value && isEditMode && editObject?.photo ? [editObject.photo] : value ? [value] : undefined
												}
												onChange={async (files: any[]) => {
													const img = files && files.length ? files[0] : "";
													if (img) {
														setImageUploading(true);
														const correctFile = typeof img === "string" ? await dataUrlToFile(img, "test") : img;
														const data = await uploadFile({
															file: correctFile as File,
															communityName: companyImageUploadUrl,
															checkProgress: true
														});
														onChange(data?.publicUrl);
														setImageUploading(false);
													} else onChange("");
												}}
												label="Upload Company Logo"
												orientation={orientationConst.horizontal}
												width="100%"
												height="auto"
												description={
													<>
														Drag and Drop File Here or <span className="anchor">Browse</span> to Choose a File
													</>
												}
												accept={[
													{
														fileType: "image/png, image/jpeg, image/x-eps",
														name: "png, jpg, eps"
													}
												]}
												icon={
													<Icon
														group=""
														fill="#c5cee0"
														name="cloud-upload"
														width={64}
														height={50}
														viewBox="4 2 18 19"
													/>
												}
											/>
											{error && <span className="error">{error}</span>}
										</>
									);
								}}
							/>
						</UploaderWrapper>
						<InputWrapper>
							<Controller
								name="companyName"
								control={control}
								defaultValue={editObject?.name ? editObject?.name : ""}
								rules={{
									required: true,
									maxLength: 100
								}}
								render={({ onChange, value }) => {
									const error = displayErrorMessage(errors.companyName);
									return (
										<Input
											errorText={error}
											onChange={onChange}
											value={value}
											label="Company name*"
											onFocus={scrollToXPositionOnFocus}
											id="companyName"
										/>
									);
								}}
							/>
						</InputWrapper>
						<InputWrapper>
							<Controller
								name="shortDescription"
								control={control}
								defaultValue={editObject?.description ? editObject?.description : ""}
								rules={{
									maxLength: 200
								}}
								render={({ onChange, value }) => {
									const error = displayErrorMessage(errors.shortDescription);
									return (
										<Input
											errorText={error}
											onChange={onChange}
											value={value}
											label="Short description"
											onFocus={scrollToXPositionOnFocus}
											id="companyDescription"
										/>
									);
								}}
							/>
						</InputWrapper>
						<InputWrapper>
							<Grid container spacing={2}>
								<Grid item xs={12} sm={6}>
									<Controller
										name="website"
										control={control}
										defaultValue={editObject?.url ? editObject?.url : ""}
										rules={{
											required: "Website is required.",
											validate: value => {
												if (!validateURL(value)) {
													return "Incorrect Url";
												}
											}
										}}
										render={({ onChange, value }) => {
											return (
												<Input
													errorText={errors?.website?.message}
													onChange={onChange}
													value={value}
													label="Website URL*"
													onFocus={scrollToXPositionOnFocus}
													id="companyWebsite"
												/>
											);
										}}
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<StyledAutoComplete
										multiple
										renderInput={params => <TextField {...params} label="Categories*" variant="outlined" />}
										getOptionLabel={(option: any) => (typeof option === "string" ? option : option.value)}
										id="companyCategories"
										filterOptions={createFilterOptions({
											matchFrom: "start",
											stringify: (option: any) => option.value
										})}
										includeInputInList
										filterSelectedOptions
										options={filteredOptions}
										value={categoriesValue}
										onChange={(_, choice) => handleSelectChange(choice)}
										onFocus={scrollToXPositionOnFocus}
										renderOption={(option: any) => <Text id={`selectCategory${option.id + 1}`}>{option.value}</Text>}
									/>
								</Grid>
							</Grid>
						</InputWrapper>
						<InputWrapper>
							<PinnedBox>
								<Box display="flex" alignItems="center">
									<Icon name="star" group="linear" />
									<Text variant="h6">Display company on the top</Text>
								</Box>
								<Controller
									name="pinned"
									control={control}
									render={({ onChange }) => (
										<Switch
											defaultChecked={editObject?.pinned || false}
											onChange={val => onChange(val.target.checked)}
											id="companyOnTop"
										/>
									)}
								/>
							</PinnedBox>
						</InputWrapper>
					</ContentWrapper>
				</form>
			</Dialog>
		</>
	);
};

export default CreateEditCompanyDialog;
