import React, { ReactNode, memo, useCallback, useEffect, useMemo, useState } from "react";

import config from "config/appConfig";
import { DateTime } from "luxon";

import { ReactComponent as GlobalIcon } from "assets/icons/iconGlobe.svg";
import { ReactComponent as LockIcon } from "assets/icons/iconLock.svg";

import { CreateGroup, MenuDots, PlaceholderImage, SkeletonBase, TableWrapper } from "shared/Components";
import { TableColumnLoaderType } from "shared/Components/NewTable/Components";
import { Cell } from "shared/Components/NewTable/style";
import { useDebounce, useGroup } from "shared/hooks";
import { GroupModel, PlaceholderImageType } from "shared/types";
import { GroupSortBy, groupModalType } from "shared/types/GroupModel";
import { formatCount } from "utils/serviceUtils/helpers";

import InvitePeople from "./InvitePeople";

import { StyledButton, Subgroups } from "./style";

import { ConfirmDelete, PageTemplate } from "../../Components";

const Table = React.lazy(() => import("shared/Components/NewTable"));

interface ExpandedGroupModel extends GroupModel {
	_expandDataContent?: ReactNode;
}

const ManageGroups: React.FC = memo(() => {
	const [searchText, setSearchText] = useState("");

	const {
		getPaginatedGroup,
		deleteGroupBySlug,
		getGroups,
		getTotalGroupsCount,
		setPage,
		setGroupsShowPerPage,
		getData: getGroupData
	} = useGroup();
	const { totalFoundGroups, filteredGroups, loading, page, groupsShowPerPage } = getGroupData();

	const [groupToDelete, setGroupToDelete] = useState<GroupModel>();
	const [displayGroups, setDisplayGroups] = useState<ExpandedGroupModel[]>([]);
	const [groupInfoPopUp, setGroupInfoPopUp] = useState<groupModalType>({
		open: false
	});
	const [invitePeoplePopUp, setInvitePeoplePopUp] = useState<groupModalType>({ open: false });

	const handleSearch = useCallback(
		(val: string) => {
			if (val === "" || val.length >= 3) {
				setSearchText(val);
				setPage(1);
			}
		},
		[setSearchText, setPage]
	);

	const debouncedKeyword = useDebounce(searchText, 300);

	const getOptions = useCallback((rowData: GroupModel, rowIndex: number) => {
		const options: { name: string; onClick: () => void; id?: string }[] = [
			{
				name: "Edit",
				onClick: () => {
					setGroupInfoPopUp({
						open: true,
						model: rowData,
						isSubgroup: !!rowData.parentId,
						defaultParent: !!rowData.parentId ? rowData : undefined
					});
				},
				id: `editGroup_${rowIndex + 1}`
			},
			{
				name: "Invite",
				onClick: () => setInvitePeoplePopUp({ open: true, model: rowData }),
				id: `inviteGroup_${rowIndex + 1}`
			},
			{
				name: "Delete",
				onClick: () => {
					setGroupToDelete(rowData);
				},
				id: `deleteGroup_${rowIndex + 1}`
			}
		];

		if (!rowData.parentId && (rowData?.totalSubGroups || 0) < config.GLOBAL_CONSTANTS.SUBGROUPS_LIMIT_COUNT) {
			options.splice(2, 0, {
				name: "Add Sub-group",
				onClick: () => setGroupInfoPopUp({ open: true, isSubgroup: true, defaultParent: rowData }),
				id: `addSubGroup_${rowIndex + 1}`
			});
		}

		return options;
	}, []);

	const tableColumns = useMemo(
		() =>
			(subtable = false) => {
				const baseColumns = [
					{
						alignment: "left",
						width: 290,
						label: <Cell.HeaderText>{formatCount(totalFoundGroups as number, "Group")}</Cell.HeaderText>,
						Cell: ({ rowData: { name, coverUrl, organizedby } }: { rowData: GroupModel }) => (
							<Cell.Wrapper>
								<Cell.ImageWrapper>
									{!!coverUrl ? (
										<Cell.Image src={coverUrl} />
									) : (
										<PlaceholderImage
											type={PlaceholderImageType.EVENT_DETAILS}
											width={40}
											height={40}
											viewBox={"0 0 400 400"}
										/>
									)}
								</Cell.ImageWrapper>
								<Cell.Wrapper className="column with-image">
									<Cell.Text>{name}</Cell.Text>
									{(organizedby?.firstName || organizedby?.lastName) && (
										<Cell.Text className="light">
											by {organizedby?.firstName} {organizedby?.lastName}
										</Cell.Text>
									)}
								</Cell.Wrapper>
							</Cell.Wrapper>
						),
						dataKey: "content",
						loaderTemplate: TableColumnLoaderType.imageWthTwoTextRows
					},
					{
						alignment: "left",
						minWidth: 100,
						label: <Cell.HeaderText>Members</Cell.HeaderText>,
						Cell: ({ rowData: { totalMembers } }: { rowData: GroupModel }) => (
							<Cell.Wrapper>
								<Cell.Text>{totalMembers}</Cell.Text>
							</Cell.Wrapper>
						),
						dataKey: "members",
						loaderTemplate: TableColumnLoaderType.oneTextRow
					},
					{
						alignment: "left",
						minWidth: 135,
						label: <Cell.HeaderText>Date Created</Cell.HeaderText>,
						Cell: ({ rowData: { createdAt } }: { rowData: GroupModel }) => (
							<Cell.Wrapper>
								<Cell.Text>{DateTime.fromISO(createdAt).toFormat("DDD")}</Cell.Text>
							</Cell.Wrapper>
						),
						dataKey: "createdDt",
						loaderTemplate: TableColumnLoaderType.oneTextRow
					},
					{
						alignment: "left",
						minWidth: 135,
						label: <Cell.HeaderText>Last Post Created</Cell.HeaderText>,
						Cell: ({ rowData: { post } }: { rowData: GroupModel }) => (
							<Cell.Wrapper>
								<Cell.Text>{post?.lastPostedAt ? DateTime.fromISO(post.lastPostedAt).toFormat("DDD") : ""}</Cell.Text>
							</Cell.Wrapper>
						),
						dataKey: "lastPostDt",
						loaderTemplate: TableColumnLoaderType.oneTextRow
					},
					{
						alignment: "left",
						minWidth: 165,
						label: <Cell.HeaderText>Group Privacy</Cell.HeaderText>,
						Cell: ({ rowData: { private: isPrivate } }: { rowData: GroupModel }) => (
							<Cell.Wrapper>
								{isPrivate ? <LockIcon /> : <GlobalIcon />}
								<Cell.Text>{isPrivate ? "Private" : "Public"} Group</Cell.Text>
							</Cell.Wrapper>
						),
						dataKey: "privacy",
						loaderTemplate: TableColumnLoaderType.oneTextRow
					},
					{
						alignment: "right",
						label: "",
						width: 100,
						Cell: ({ rowData, rowIndex }: { rowData: GroupModel; rowIndex: number }) => (
							<MenuDots
								options={getOptions(rowData, rowIndex)}
								vertical
								removeBg
								removeshadow
								removeSideMargin
								menuId="groupsMoreActions"
							/>
						),
						dataKey: "menu",
						loaderTemplate: TableColumnLoaderType.menuDots
					}
				];

				return subtable
					? [
							...baseColumns,
							{
								width: 64,
								alignment: "center",
								label: "",
								dataKey: "__expand__",
								loaderTemplate: TableColumnLoaderType.expand,
								customLoaderTemplate: <></>
							}
					  ]
					: baseColumns;
			},
		[getOptions, totalFoundGroups]
	);

	useEffect(() => {
		getPaginatedGroup({
			name: debouncedKeyword,
			page,
			limit: groupsShowPerPage,
			searchAll: !!debouncedKeyword?.length
		});
	}, [debouncedKeyword, page, getPaginatedGroup, groupsShowPerPage]);

	useEffect(() => {
		setDisplayGroups(filteredGroups || []);
	}, [filteredGroups]);

	useEffect(() => {
		getTotalGroupsCount({ name: debouncedKeyword, limit: groupsShowPerPage, searchAll: true });
	}, [getTotalGroupsCount, debouncedKeyword, groupsShowPerPage]);

	const getSubgroupsTable = useCallback(
		(subgroups: GroupModel[], loading = false) => (
			<Subgroups.Wrapper>
				{loading ? (
					<SkeletonBase width={100} height={26} />
				) : (
					!!subgroups.length && (
						<Subgroups.Title>
							{subgroups.length} Sub-group{subgroups.length > 1 && "s"}
						</Subgroups.Title>
					)
				)}
				<TableWrapper sizes={{ horizontalTablet: 900 }}>
					<Table
						rowHeight={72}
						columns={tableColumns(true)}
						data={subgroups || []}
						hideHeader
						loading={loading}
						pageSize={2}
					/>
				</TableWrapper>
			</Subgroups.Wrapper>
		),
		[tableColumns]
	);

	const requestSubGroups = useCallback(
		async (rowIndex: number) => {
			let _expandDataContent = getSubgroupsTable([], true);

			setDisplayGroups(groups =>
				groups.map((group, index) => (index === rowIndex ? { ...group, _expandDataContent } : group))
			);

			const { groups: subgroups } = await getGroups({
				sortBy: GroupSortBy.MEMBERS,
				parentId: displayGroups[rowIndex]._id,
				page: 1,
				limit: displayGroups[rowIndex]?.totalSubGroups || 50,
				preventStateUpdate: true
			});

			_expandDataContent = getSubgroupsTable(subgroups, false);

			setDisplayGroups(groups =>
				groups.map((group, index) => (index === rowIndex ? { ...group, subgroups, _expandDataContent } : group))
			);
		},
		[getGroups, displayGroups, getSubgroupsTable]
	);

	const cancelDelete = () => {
		setGroupToDelete(undefined);
	};

	const confirmDelete = useCallback(() => {
		if (groupToDelete) {
			deleteGroupBySlug(groupToDelete.slug, !!groupToDelete.parentId);
			if (groupToDelete.parentId) {
				setDisplayGroups(groups => {
					return groups.map(group => {
						if (group._id === groupToDelete.parentId) {
							const subgroups = group.subgroups.filter(x => x.slug !== groupToDelete.slug);
							return {
								...group,
								subgroups: subgroups,
								totalSubGroups: subgroups.length,
								_expandDataContent: getSubgroupsTable(subgroups, false)
							};
						} else {
							return group;
						}
					});
				});
			} else {
				setDisplayGroups(groups => groups.filter(x => x.slug !== groupToDelete.slug));
			}

			// getPaginatedGroup({
			// 	...debouncedSearchTerm,
			// 	limit: groupsShowPerPage,
			// 	searchAll: !!debouncedSearchTerm.name.length
			// });
		}

		setGroupToDelete(undefined);
	}, [deleteGroupBySlug, groupToDelete, getSubgroupsTable]);

	const handleCloseCreateGroup = (e: { status?: "updated" | "created"; group?: GroupModel }) => {
		setGroupInfoPopUp({
			open: false
		});

		if (e.group && e.status) {
			if (e.group.parentId) {
				setDisplayGroups(groups =>
					groups.map(group => {
						if (group._id === e.group?.parentId) {
							const sgIdx = e.status === "updated" ? group.subgroups.findIndex(x => x.slug === e.group?.slug) : -1;

							const subgroups =
								e.status === "updated"
									? group.subgroups.map((sg, i) => (sgIdx === i ? (e.group as GroupModel) : sg))
									: !!group?.subgroups?.length
									? [e.group, ...group.subgroups]
									: [e.group];

							return {
								...group,
								subgroups,
								totalSubGroups: subgroups.length,
								_expandDataContent: getSubgroupsTable(subgroups)
							};
						}
						return group;
					})
				);
			} else {
				setDisplayGroups(groups => {
					const sgIdx = e.status === "updated" ? groups.findIndex(x => x.slug === e.group?.slug) : -1;
					return e.status === "updated"
						? groups.map((g, i) => (i === sgIdx ? (e.group as GroupModel) : g))
						: [e.group as ExpandedGroupModel, ...groups];
				});
			}
		}
	};

	const handleChangePage = useCallback(
		(newPage: number) => {
			setPage(newPage);
		},
		[setPage]
	);

	const handleChangeRowsPerPage = useCallback(
		(newPageSize: number) => {
			setGroupsShowPerPage(newPageSize);
			setPage(1);
		},
		[setGroupsShowPerPage, setPage]
	);

	const isRowExpandable = useCallback((data: ExpandedGroupModel) => {
		return !!data?.totalSubGroups;
	}, []);

	const TableBlock = useMemo(
		() => (
			<TableWrapper sizes={{ horizontalTablet: 900 }}>
				<Table
					columns={tableColumns()}
					data={displayGroups || []}
					expandable
					isRowExpandable={isRowExpandable}
					onExpandRow={requestSubGroups}
					loading={loading}
					paginated
					totalDataCount={totalFoundGroups}
					page={page}
					pageSize={groupsShowPerPage}
					onChangePage={handleChangePage}
					onChangePageSize={handleChangeRowsPerPage}
				/>
			</TableWrapper>
		),
		[
			tableColumns,
			loading,
			displayGroups,
			isRowExpandable,
			requestSubGroups,
			page,
			groupsShowPerPage,
			handleChangePage,
			handleChangeRowsPerPage,
			totalFoundGroups
		]
	);

	const extraItem = useMemo(
		() => (
			<StyledButton
				removeSideMargin
				buttonTheme="light"
				palette="primary"
				size="large"
				onClick={() => setGroupInfoPopUp({ open: true, isSubgroup: true })}
				id="create_SubGroup"
			>
				Create Sub-group
			</StyledButton>
		),
		[]
	);

	return (
		<>
			<PageTemplate
				title={"Groups"}
				showReportedLink
				isLoading={loading}
				isNoData={!displayGroups.length}
				emptyText={"You don’t have any Groups in your community yet."}
				searchPlaceholder={"Search Groups"}
				onSearchUpdate={handleSearch}
				actionText={"Create Group"}
				onCreateClick={() => setGroupInfoPopUp({ open: true })}
				extraAction={extraItem}
				searchId="search"
				actionId="create"
			>
				{TableBlock}
			</PageTemplate>
			{!!groupToDelete && (
				<ConfirmDelete
					deleteBtnId="deleteGroupConfirmation"
					headline="Group"
					name={groupToDelete?.name}
					onClose={cancelDelete}
					onDelete={confirmDelete}
				/>
			)}
			<CreateGroup
				open={groupInfoPopUp.open}
				isSubgroup={groupInfoPopUp.isSubgroup}
				defaultParent={groupInfoPopUp.defaultParent}
				editableModel={groupInfoPopUp.model}
				onClose={handleCloseCreateGroup}
			/>
			{invitePeoplePopUp.open && (
				<InvitePeople
					group={invitePeoplePopUp.model}
					open={invitePeoplePopUp.open}
					onClose={() =>
						setInvitePeoplePopUp({
							open: false
						})
					}
				/>
			)}
		</>
	);
});

export default ManageGroups;
