import { ReportContentModel, ReportContentReasonType, ReportContentType } from "shared/types";
import { IStateProps } from "utils/StateOf";

import createStore from "utils/createStore";

export interface Values {
	loading: boolean;
	reports: ReportContentModel[];
	totalCount: number;
	reportContentModal?: {
		reportType: ReportContentType;
		reason: ReportContentReasonType;
		reportContentId: string;
		reportContentName?: string;
		customCallback?: () => void;
	};
}

export const initialState: IStateProps<Values> = {
	loading: false,
	reports: [],
	totalCount: 0
};

export default createStore<Values>({
	initialState
});
