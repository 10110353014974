export const API_URLS = {
	TEAM_LIST: {
		GET_TEAM_MEMBERS: "/globaluser/members",
		DELETE_TEAM_MEMBER: "/user/delete",
		PUT_EDIT_ROLE_TEAM_MEMBER: "/nodebb/group/members",
		POST_VALIDATE_EMAIL_TEAM_MEMBER: "/globaluser/email/validate",
		PUT_CANCEL_INVITE_TEAM_MEMBER: "/globaluser/cancel/invite",
		POST_INVITE_USER: "/globaluser/invite",
		POST_RESEND_INVITE_TEAM_MEMBER: "/globaluser/resend",
		POST_INVITE_TEAM_MEMBER: "/globaluser/invite",
		POST_MEMBER_COUNT: "globaluser/members/count"
	}
};
