import config from "config/appConfig";

import { FundraiserContextValues } from "shared/types";
import { IStateProps } from "utils/StateOf";

import createStore from "utils/createStore";

const localStorageKey = `${config.GLOBAL_CONSTANTS.LOCAL_STORAGE_PREFIX}${config.HOOK_STORAGE_KEYS.FUNDRAISER_DATA}`;

export interface DonationDialogInterface {
	open: boolean;
	behalf: {
		member: { name: string; img: string; personaId: number };
		group: { name: string; img: string; id?: string };
	};
}

export const initialState: IStateProps<FundraiserContextValues> = {
	loading: false,
	submitting: false,
	offset: 1,
	fundraisersShowPerPage: 20,
	fundraisers: [],
	contacts: [],
	fundraiser: undefined,
	downloading: false,
	loadingSponsors: false
	// donateSuccessfulDialog: {
	// 	open: true,
	// 	behalf: {
	// 		member: {
	// 			img: "https://dha939bkqu76d.cloudfront.net/uploads/vyoo/76c6f5e2-4b7a-4cbc-8ed4-7b0e63f6d153_378a8f1969b36882d921dade95f34e58.jpeg",
	// 			name: "Ahmed Maher",
	// 			personaId: 2147123803
	// 		},
	// 		group: {
	// 			img: "https://images.unsplash.com/photo-1506748686214-e9df14d4d9d0?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80",
	// 			name: "Arsenal U12 Boys Gold"
	// 		}
	// 	}
	// }
};

export default createStore<FundraiserContextValues>({
	initialState,
	persistant: true,
	persistantName: localStorageKey
});
