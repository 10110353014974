import React, { FC, useCallback } from "react";

import parsePhoneNumberFromString from "libphonenumber-js";

import { useFundraisers } from "shared/hooks";

import InviteContactsManageView from "./ManageView";

const InviteContactsDialog: FC<{ handleClose: () => void; updateOnAddContact?: boolean }> = ({
	handleClose,
	updateOnAddContact
}) => {
	const { inviteContact, updateFundraiserContacts, getData: getFundraiserData } = useFundraisers();
	const { inviteDialog } = getFundraiserData();

	const onSubmit = useCallback(
		async data => {
			if (inviteDialog) {
				await inviteContact(
					{
						personaId: inviteDialog.onBehalfOf?.value || data.behalf.value,
						contactInfo: {
							phone: data.number
								? data.number[0] === "+"
									? data.number.replace(/\+/g, "")
									: parsePhoneNumberFromString(data.number, "US")?.format("E.164").replace(/\+/g, "")
								: undefined,
							email: data.email
						},
						fundraiserId: inviteDialog.id,
						messageBody: data.message,
						name: data.to,
						relationship: data.relationship?.trim() || undefined
					},
					updateOnAddContact
				);

				handleClose();
			}
		},
		[handleClose, inviteContact, inviteDialog, updateOnAddContact]
	);

	const handleUpdate = useCallback(
		async data => {
			await updateFundraiserContacts(data.contactId, {
				name: data.to,
				relationship: data.relationship,
				contactInfo: { phone: data.number, email: data.email },
				messageBody: data.message
			});

			handleClose();
		},
		[handleClose, updateFundraiserContacts]
	);

	return <InviteContactsManageView onClose={handleClose} handleCreate={onSubmit} handleUpdate={handleUpdate} />;
};

export default InviteContactsDialog;
