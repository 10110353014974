import React, { FC, useRef, useState } from "react";

import { useHistory } from "react-router-dom";

import { useCommunity, usePersona } from "shared/hooks";

import { useS3Uploader } from "shared/services/s3Uploader";
import { Icon, Loader } from "shared/ui-kit";

import { FormBase, SelfieForm } from "./style";

interface FillProfileSelfieProps {
	fillProfileWelcomeUrl: string;
}

const FillProfileSelfie: FC<FillProfileSelfieProps> = ({ fillProfileWelcomeUrl }) => {
	const history = useHistory();

	const { getData: getCommunityData } = useCommunity();
	const { workspace } = getCommunityData();

	const { verifySelfie, getData: getPersonaData } = usePersona();
	const { persona } = getPersonaData();

	const { uploadFile } = useS3Uploader();

	const videoRef = useRef<HTMLVideoElement>(null);
	const canvasRef = useRef<HTMLCanvasElement>(null);
	const streamRef = useRef<MediaStream>(null);

	const [selfiePic, setSelfiePic] = useState("");
	const [showSelfieForm, setShowSelfieForm] = useState(false);
	const [verifyingSelfie, setVerifyingSelfie] = useState(false);
	const [isVerifiedSelfie, setIsVerifiedSelfie] = useState(false);

	const onGoNext = () => {
		if (videoRef.current?.pause) {
			videoRef.current.pause();
			videoRef.current.src = "";
		}

		if (streamRef.current) {
			streamRef.current.getTracks()[0].stop();
		}

		history.push(fillProfileWelcomeUrl);
	};

	const closeSelfieForm = () => {
		setShowSelfieForm(false);
		setSelfiePic("");
		setVerifyingSelfie(false);
		setIsVerifiedSelfie(false);
	};

	const openCamera = async () => {
		if (!streamRef.current) {
			// @ts-expect-error: valid method
			streamRef.current = await navigator.mediaDevices.getUserMedia({ video: true, audio: false });
			if (videoRef.current) {
				videoRef.current.srcObject = streamRef.current;

				const streamWidth = streamRef.current.getVideoTracks()[0].getSettings().width || 640;
				const streamHeight = streamRef.current.getVideoTracks()[0].getSettings().height || 480;

				videoRef.current.width = streamWidth;
				videoRef.current.height = streamHeight;

				if (canvasRef.current) {
					canvasRef.current.width = streamWidth;
					canvasRef.current.height = streamHeight;
				}
			}
		}

		setShowSelfieForm(true);
	};

	const makeShot = async () => {
		if (canvasRef.current && videoRef.current) {
			setVerifyingSelfie(true);

			// @ts-expect-error: valid method
			canvasRef.current
				.getContext("2d")
				.drawImage(videoRef.current, 0, 0, canvasRef.current.width, canvasRef.current.height);

			setSelfiePic(canvasRef.current.toDataURL("image/jpeg", 1));
			canvasRef.current.toBlob(
				async blobData => {
					if (blobData) {
						const res = await uploadFile({
							file: new File([blobData], `photo_${Date.now()}.jpg`),
							communityName: `${workspace?.communityUrl}`,
							noBucket: true
						});

						const data = await verifySelfie(`${persona?.photos[0]}`, res!.publicUrl);
						setVerifyingSelfie(false);

						if (data.detectCustomlabels.verified) {
							setIsVerifiedSelfie(true);
						}
					}
				},
				"image/jpeg",
				1
			);
		}
	};

	return (
		<>
			<SelfieForm className={showSelfieForm && "open"}>
				<SelfieForm.VideoWrapper
					style={{
						backgroundSize: "contain",
						backgroundImage: selfiePic && (verifyingSelfie || isVerifiedSelfie) ? `url(${selfiePic})` : null
					}}
				>
					<video
						ref={videoRef}
						width={640}
						height={480}
						autoPlay
						className={!!selfiePic && (verifyingSelfie || isVerifiedSelfie) ? "hide" : ""}
					/>
					<canvas ref={canvasRef} width={640} height={480} />
				</SelfieForm.VideoWrapper>
				<SelfieForm.InterfaceWrapper>
					<SelfieForm.CloseWrapper onClick={closeSelfieForm}>
						<Icon group={"filled"} fill={"white"} name={"close"} width={24} height={24} />
					</SelfieForm.CloseWrapper>
					{!verifyingSelfie && !isVerifiedSelfie && <SelfieForm.FaceText>Your face here</SelfieForm.FaceText>}
					{!selfiePic && (!verifyingSelfie || !isVerifiedSelfie) && <SelfieForm.FaceWrapper />}
					{!verifyingSelfie && !isVerifiedSelfie && (
						<SelfieForm.ActionFooter>
							<SelfieForm.PeaceText className={"with-icon"}>
								Make a {'"Peace"'} sign with your <b className={"bold"}>right</b> hand and hold it next to your face.
							</SelfieForm.PeaceText>
							<SelfieForm.ShotBtn onClick={makeShot}>
								<Icon group={"filled"} fill={"white"} name={"camera"} width={32} height={32} />
							</SelfieForm.ShotBtn>
						</SelfieForm.ActionFooter>
					)}
					{verifyingSelfie && (
						<SelfieForm.VerifyingFooter>
							<SelfieForm.PeaceText>Verifying…</SelfieForm.PeaceText>
							<Loader size="1rem" show={true} color="primary" variant="indeterminate" />
						</SelfieForm.VerifyingFooter>
					)}
					{isVerifiedSelfie && (
						<SelfieForm.VerifiedFooter>
							<SelfieForm.VerifiedFooterIconWrapper>
								<Icon group={"filled"} fill={"#23B34A"} name={"check-circle"} width={40} height={40} />
							</SelfieForm.VerifiedFooterIconWrapper>
							<SelfieForm.PeaceText>
								Profile Verified!
								<br />
								Thanks
							</SelfieForm.PeaceText>
							<SelfieForm.NextBtn type={"button"} palette={"basic"} buttonTheme={"ghost"} onClick={onGoNext}>
								Next
							</SelfieForm.NextBtn>
						</SelfieForm.VerifiedFooter>
					)}
				</SelfieForm.InterfaceWrapper>
			</SelfieForm>
			<FormBase className="without-logo">
				<FormBase.Title className="verify-account">
					Hi {persona!.firstName}, <br />
					please take a photo to help us verify your account
				</FormBase.Title>
				<FormBase.Subtitle>
					This photo will not show on your profile, so feel free to take a selfie in your PJs.
				</FormBase.Subtitle>
				<FormBase.SelfieExample />
				<FormBase.Footer>
					<FormBase.ActionBtn palette={"basic"} buttonTheme={"light"} onClick={() => history.go(-1)}>
						Back
					</FormBase.ActionBtn>
					<FormBase.ActionBtn type={"button"} onClick={openCamera} className="active">
						Take a Selfie
					</FormBase.ActionBtn>
				</FormBase.Footer>
			</FormBase>
		</>
	);
};

export default FillProfileSelfie;
