import { useCallback, useMemo } from "react";

import { useNotification, useUser } from "shared/hooks";

import { actionMethod, actionTypes, getValidationMessage } from "utils/getValidationMessage";

import { useActivityStore } from "../contexts";

import { useActivitiesApiService } from "../services";
import { ActivitySettingsModel, ActivityType } from "../types/ActivityModel";

const useActivities = () => {
	const store = useActivityStore();
	const { setState } = useActivityStore();

	const { showMessage } = useNotification();

	const { getData: getUserData, updateNotificationPreferences } = useUser();
	const { user } = getUserData();

	const personaId = useMemo(() => user?.profiles[0]?.personaId || "", [user?.profiles]);

	const service = useActivitiesApiService();

	const methods = useMemo(
		() => ({
			loadActivities: async (offset: number, limit: number) => {
				setState({ loading: true });

				const data = await service.getActivities(personaId || 0, offset, limit);
				setState(ctx => ({
					activities: offset === 1 ? data.activites : ctx.activities?.concat(data.activites),
					loading: false,
					allowToLoadMore: !!data.activites?.length
				}));
			},
			deleteActivity: async (activityId: string) => {
				const result = await service.deleteActivity(personaId || 0, activityId);

				if (result.success) {
					setState(ctx => ({
						activities: ctx.activities?.filter(b => b.activityId !== activityId)
					}));
				}
			},
			markRead: async (activityIds: string[]) => {
				service.markRead(activityIds);

				setState(ctx => ({
					activities: ctx.activities?.map(x => (activityIds.includes(x.activityId) ? { ...x, read: true } : x))
				}));
			},
			mutePersona: async (mutePersonaId: number) => {
				service.mutePersona(mutePersonaId);

				showMessage(
					getValidationMessage({
						name: "You will not receive notifications from this user anymore"
					}),
					3
				);
			},
			muteSimilarActivities: async (activityType: ActivityType) => {
				service.muteSimilarActivities(activityType);

				showMessage(
					getValidationMessage({
						name: "You will not receive similar notifications anymore"
					}),
					3
				);
			},
			changeSettings: async (activitySettings: ActivitySettingsModel) => {
				updateNotificationPreferences(activitySettings);

				try {
					service.changeSettings(activitySettings);

					showMessage(
						getValidationMessage({
							name: "Notification Settings",
							actionType: actionTypes.CRUD,
							actionMethod: actionMethod.updated,
							emoji: "🎉"
						}),
						3
					);
				} catch (e) {
					console.log("Can't update notification settings. Error: ", (e as Error)?.message || e);
				}
			}
		}),
		[personaId, service, setState, showMessage, updateNotificationPreferences]
	);

	const getData = useCallback(() => {
		return store;
	}, [store]);

	return { ...methods, getData };
};

export default useActivities;
