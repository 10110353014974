import React, { useEffect, useState } from "react";

import config from "config/appConfig";
import styled from "styled-components";

import { SubmitButton } from "shared/Components";
import useConfirmLeavePage from "shared/Components/ConfirmLeave/hooks/useConfirmLeavePage";
import { useCommunity } from "shared/hooks";
import { Box, Switch, Text } from "shared/ui-kit";

const Wrapper = styled(Box)`
	display: flex;
	flex-direction: row;
	flex: 1;
	margin: 30px 10px 0 0;
	justify-content: space-between;
	${props => props.theme.breakpoints.down("sm")} {
		flex-direction: column;
		width: 100%;
	}
`;

const SwitchWrapper = styled(Box)`
	display: flex;
	flex-direction: row;
	margin: 0 40px 0 0;
	padding: 0 10px 0 20px;
	background-color: #f7f9fc;
	border-radius: 4px;
	border: 0.5px solid #c5cee0;
	height: 48px;
`;

const ContentPlus18 = () => {
	const [content18Plus, setContent18Plus] = useState<boolean>(false);
	const { provideHandleCloseValues } = useConfirmLeavePage({});

	const handleInputContent18Plus = val => {
		setContent18Plus(val);
		provideHandleCloseValues(true);
	};

	const { updateWorkspaceMeta, getData: getCommunityData } = useCommunity();
	const { workspace } = getCommunityData();

	const onSave = async () => {
		await updateWorkspaceMeta({
			meta: {
				content18Plus
			}
		});
	};
	useEffect(() => {
		if (workspace?.meta?.content18Plus !== undefined) {
			setContent18Plus(workspace.meta.content18Plus);
		}
	}, [workspace]);
	return (
		<>
			<Text>
				{`If your community includes any material that is not suitable for audiences under 18 years old, such as nudity, dangerous activities, or vulgar content, then your community needs to be marked as 18+. ${config.GLOBAL_CONSTANTS.ENV_APPLICATION_NAME} is where all types of creators can express themselves freely; however, we need to ensure a safe experience for people of different age groups.`}
			</Text>
			<Wrapper>
				<SwitchWrapper>
					<Switch
						leftLabel={"Community includes 18+ content"}
						checked={content18Plus}
						conditional={true}
						onChange={e => handleInputContent18Plus(e.target.checked)}
						id="18Content"
					/>
				</SwitchWrapper>
				<SubmitButton
					onClick={onSave}
					disabled={(workspace?.meta?.content18Plus ?? false) === content18Plus}
					id="saveChanges18Content"
				>
					Save changes
				</SubmitButton>
			</Wrapper>
		</>
	);
};

export default ContentPlus18;
