import React, { HTMLAttributes } from "react";

import InputBase, { InputBaseProps } from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
import styled from "styled-components";

const SearchWrapper = styled.div<HTMLAttributes<HTMLDivElement> & { filled?: boolean }>`
	position: relative;
	display: flex;
	border-radius: 4px;
	background-color: ${props => (props.filled ? "#edf1f7" : "transparent")};
	width: 500px;
	height: 40px;

	&.outline-dark {
		border: 1px solid rgba(198, 207, 224, 0.5);
		background-color: #f7f9fc;
		border-radius: 8px;

		.search-icon {
			svg {
				fill: #8f9bb3;
			}
		}

		.MuiInputBase-root {
			width: calc(100% - 34px);

			input::placeholder {
				color: #8f9bb3;
				opacity: 1;
			}
		}
	}

	&.full-width {
		width: 100%;
	}

	&:hover {
		background-color: ${props => (props.filled ? "#f7f9fc" : "#f7f9fc")};
	}

	&:focus-within {
		background-color: ${props => (props.filled ? "#edf1f7" : "#f7f9fc")};
		border-radius: 4px;
		border-width: 1px;
		border-style: solid;
		border-color: ${props => (props.filled ? "#4a99f9" : "transparent")};
	}

	.search-icon {
		display: flex;
		pointer-events: none;
		align-items: center;
		justify-content: center;
		width: 50px;
		color: #c5cee0;
		height: 100%;
		margin-left: 10px;
	}

	.MuiInputBase-root {
		color: inherit;
		position: relative;

		.MuiInputBase-input {
			font-size: 13px;
			width: 450px;
			padding: 5px 15px;
			border-color: transparent;
			box-shadow: none;
			opacity: 1;
		}
	}
`;

export type SearchProps = InputBaseProps & {
	filled?: boolean;
	theme?: "outline-dark";
	fullWidth?: boolean;
};

const Search: React.FC<SearchProps> = ({ theme = "", fullWidth, placeholder = "Search...", ...rest }): JSX.Element => (
	<SearchWrapper filled={rest.filled} className={`${theme} ${fullWidth ? "full-width" : ""}`}>
		<div className="search-icon">
			<SearchIcon />
		</div>
		<InputBase placeholder={placeholder} inputProps={{ "aria-label": "search" }} {...rest} />
	</SearchWrapper>
);

export default Search;
