import CloseIcon from "@material-ui/icons/Close";
import styled from "styled-components";

import { Box, Text } from "shared/ui-kit";

export const ChatTextContainer = styled(Box)`
	background-color: transparent;
	margin-bottom: 8px;

	display: flex;
	align-items: center;
`;

export const StyledCloseIcon = styled(CloseIcon)`
	margin-right: 24px;

	cursor: pointer;
`;

export const ReplyChatContainer = styled(Box)<{ isEdit?: boolean }>`
	border-left: 2px solid #6173fe;

	padding: ${({ isEdit }) => (isEdit ? "8px" : "1px")} 14px;

	display: flex;
	flex-direction: column;
	flex-grow: 1;
`;

export const StyledText = styled(Text)`
	color: #8f9bb3;
	font-size: 12px;
	font-weight: bold;
	margin-bottom: 4px;
`;

export const MessageText = styled(Text)`
	font-size: 12px;
	font-weight: normal;
	line-height: 1.33;
	color: #222b45;
	max-height: 48px;
	overflow: hidden;
	display: flex;
	align-items: center;
	svg {
		width: 16px;
		height: 16px;
		margin-right: 4px;
	}
`;
