import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import appConfig from "config/appConfig";
import * as R from "ramda";

import { useHistory } from "react-router-dom";

import { EventType } from "types";

import { useFirebaseLiveConversation } from "modules/LiveConversation/Data/hooks";

import { useFirebase } from "modules/Messaging/Data";

import { useCommunity, useEvent, useUser, useWindowSize } from "shared/hooks";
import { ProfileType } from "shared/types";
import { LabelValueType } from "shared/types/EventModel";
import { Loader } from "shared/ui-kit";

import { WebRTCAdaptor } from "utils/liveconversation/WebRTCAdaptor.js";
import { tilesResize } from "utils/liveconversation/tilesResizer";

import { ParticipantCard, Wrapper } from "./style";

import useLiveConversation from "../../../Data/hooks/useLiveConversation";
import { ConvoParticipant, Stream } from "../../../Data/types";

import { Actions, ConversationReactions, Sidebar, SpeakerView } from "../../Components";
import ConfirmAlertModal from "../../Components/ConfirmAlertModal";
import EndAlert from "../../Components/EndAlert";

const webSocketUrl = appConfig.GLOBAL_CONSTANTS.LIVE_CONVO_WEB_SOCKET_URL;
const mediaConstraints: MediaStreamConstraints = {
	video: {
		height: 480,
		width: 720,
		frameRate: 30
	},
	audio: true
};
const pcConfig = {
	iceServers: [
		{
			urls: appConfig.GLOBAL_CONSTANTS.LIVE_CONVO_ICE_SERVE_URL
		}
	]
};

const sdpConstraints = {
	OfferToReceiveAudio: false,
	OfferToReceiveVideo: false
};

const Conversation: React.FC<{ id: string }> = ({ id }) => {
	const history = useHistory();

	const { setEvent, setRoomId, getData: getLiveConversationData } = useLiveConversation();
	const {
		firebaseParticipants,
		isMicOn,
		isVideoOn,
		event,
		controls,
		isTiles,
		pinnedPersonaId,
		// showExtend,
		// convoEndsInSecs,
		audioLevels,
		sidebarType
	} = getLiveConversationData();

	const { getEvents, updateEvent } = useEvent();

	const { initialiseFirebase } = useFirebase();

	const { getData: getCommunityData } = useCommunity();
	const { workspace } = getCommunityData();

	const {
		joinParticipant,
		leaveConversation,
		watchConversationUpdate,
		getListWithStreams,
		updateAudioLevel,
		updateParticipant
	} = useFirebaseLiveConversation();

	const { getActiveProfile, getData: getUserData } = useUser();
	const { user } = getUserData();

	const { width: screenWidth, height: screenHeight } = useWindowSize(50);

	const [remoteStreams, setRemoteStreams] = useState<Stream[]>([]);
	const [participantList, setParticipantList] = useState<ConvoParticipant[]>([]);
	const [localStream, setLocalStream] = useState<MediaStream>();
	const [warnEndConvo, setWarnEndConvo] = useState(false);
	const [isReady, setIsReady] = useState(false);
	const [tileDim, setTileDim] = useState({ width: 0, height: 0 });

	const stream = useRef({ id: "" }).current;
	const streamsList = useRef<string[]>([]);
	const roomTimerId = useRef<NodeJS.Timeout>();
	const webRTCAdaptor = useRef<WebRTCAdaptor | null>(null);
	const isJoined = useRef<boolean>(false);
	const playOnlyTurned = useRef<boolean>(false);
	const initialized = useRef<boolean>(false);
	const disconnecting = useRef<boolean>(false);

	const { admins } = event || {};

	const participantSectionRef = useRef<HTMLDivElement>(null);

	const activeProfile: ProfileType | undefined = useMemo(() => getActiveProfile(user), [getActiveProfile, user]);
	const isAdmin = useMemo(
		() =>
			admins?.length
				? admins.findIndex(a => a.personaId === activeProfile?.personaId) > -1
				: event?.personaId === activeProfile?.personaId,
		[admins, activeProfile, event]
	);

	const myFirebaseDTO = useMemo(
		() => firebaseParticipants.find(participant => participant.personaId === activeProfile?.personaId),
		[firebaseParticipants, activeProfile]
	);
	const totalParticipants = participantList.length;
	const isSpeaker = !!myFirebaseDTO?.isSpeaker;
	const playOnly = !isAdmin;
	const videoOnCurrent = !!activeProfile?.personaId ? !!controls[activeProfile?.personaId]?.videoOn : false;
	const mutedCurrent = !!activeProfile?.personaId ? !!controls[activeProfile?.personaId]?.muted : true;
	const speakersLength = firebaseParticipants.filter(({ isSpeaker }) => isSpeaker).length;

	const handleDisconnect = ({ navigate = true, force }: { navigate?: boolean; force?: boolean } = {}) => {
		disconnecting.current = true;
		setWarnEndConvo(false);
		if (!webRTCAdaptor.current) return;
		if (roomTimerId.current !== undefined) clearInterval(roomTimerId.current);
		stopListenToAudioLevel();
		leaveConversation({
			canDelete: isAdmin || isSpeaker,
			totalParticipants: firebaseParticipants.length,
			force: !!force
		});
		webRTCAdaptor.current?.stop(stream.id);
		webRTCAdaptor.current?.closePeerConnection(stream.id);
		webRTCAdaptor.current?.leaveFromRoom(id);
		if (isJoined.current && webRTCAdaptor.current?.localStream) webRTCAdaptor.current?.closeStream();
		webRTCAdaptor.current?.closeWebSocket();
		if (!navigate) return;
		const goBack = "goBack";
		history[goBack]();
	};

	useEffect(() => {
		if (stream.id && activeProfile?.personaId && !!myFirebaseDTO && myFirebaseDTO?.streamId !== stream.id) {
			updateParticipant(activeProfile.personaId, {
				streamId: stream.id
			});
		}
	}, [stream.id, activeProfile?.personaId, myFirebaseDTO, updateParticipant]);

	useEffect(() => {
		if (isReady) {
			webRTCAdaptor.current = new WebRTCAdaptor({
				websocket_url: webSocketUrl,
				mediaConstraints: mediaConstraints,
				peerconnection_config: pcConfig,
				sdp_constraints: sdpConstraints,
				localVideoId: null,
				isPlayMode: playOnly,
				debug: false,
				callback: (info, obj) => {
					let token;
					if (obj) token = obj.ATTR_ROOM_NAME;

					if (info === "initialized") {
						webRTCAdaptor.current?.joinRoom(id, undefined, undefined);
					} else if (info === "joinedTheRoom") {
						// console.log("!!!joinedTheRoom!!!");
						// console.log(obj);

						stream.id = obj.streamId;

						if (!playOnly) {
							webRTCAdaptor.current?.publish(
								obj.streamId,
								obj.ATTR_ROOM_NAME,
								undefined,
								undefined,
								undefined,
								undefined
							);
						}

						if (obj.streams !== null) {
							obj.streams.forEach(function (item) {
								console.log("Stream joined with ID: " + item);
								webRTCAdaptor.current?.play(item, token, id, undefined, undefined, undefined);
							});
							streamsList.current = obj.streams;
						}
						if (!isJoined.current) {
							joinConversation();
						}
						roomTimerId.current = setInterval(() => {
							webRTCAdaptor.current?.getRoomInfo(id, obj.streamId);
						}, 5000);
					} else if (info === "newStreamAvailable") {
						// playVideo(obj);
						console.log("newStreamAvailable", obj);
						if (!streamsList.current.includes((obj as Stream).streamId))
							streamsList.current.push((obj as Stream).streamId);
						setRemoteStreams((prevRemoteStreams: Stream[]) => {
							if (!prevRemoteStreams.find(({ streamId }) => streamId === (obj as Stream).streamId)) {
								return [...prevRemoteStreams, obj as Stream];
							}
							return prevRemoteStreams;
						});
					} else if (info === "publish_started") {
						//stream is being published
						console.debug("publish started to room: " + obj.streamId);
					} else if (info === "publish_finished") {
						//stream is being finished
						console.debug("publish finished");
						setTimeout(() => {
							webRTCAdaptor.current?.publish(
								obj.streamId,
								obj.ATTR_ROOM_NAME,
								undefined,
								undefined,
								undefined,
								undefined
							);
						}, 1000);
					} else if (info === "screen_share_stopped") {
						console.log("screen share stopped");
					} else if (info === "browser_screen_share_supported") {
						console.log("browser screen share supported");
					} else if (info === "leavedFromRoom") {
						console.debug("leaved from the room:" + obj.ATTR_ROOM_NAME);
						if (roomTimerId.current !== undefined) {
							clearInterval(roomTimerId.current);
							roomTimerId.current = undefined;
						}
					} else if (info === "closed") {
						if (typeof obj !== "undefined") {
							console.log("Connecton closed: " + JSON.stringify(obj));
						}
					} else if (info === "play_finished") {
						console.log("play_finished", obj.streamId);
						// removeRemoteVideo(obj.streamId);
						setRemoteStreams((prevRemoteStreams: Stream[]) => {
							return prevRemoteStreams.filter(({ streamId }) => streamId !== (obj as Stream).streamId);
						});
					} else if (info === "roomInformation") {
						for (const str of obj.streams) {
							if (!streamsList.current.includes(str)) {
								console.log("replayed", str);
								webRTCAdaptor.current?.play(str, token, id, undefined, undefined, undefined);
							}
						}
						streamsList.current = obj.streams;
					} else if (info === "available_devices") {
						console.log("available devices", obj);
					} else if (info === "myaudio_level") {
						if (activeProfile?.personaId) {
							updateAudioLevel(activeProfile.personaId, obj.audioLevel > 0.01 ? obj.audioLevel : 0);
						}
					}
				},
				callbackError: function (error, message) {
					//some of the possible errors, NotFoundError, SecurityError,PermissionDeniedError

					// if (error.indexOf("publishTimeoutError") !== -1 && roomTimerId.current !== undefined) {
					// 	clearInterval(roomTimerId.current);
					// 	roomTimerId.current = undefined;
					// }

					console.log("error callback: " + JSON.stringify(error));
					let errorMessage = JSON.stringify(error);
					if (typeof message !== "undefined") {
						errorMessage = message;
					}
					errorMessage = JSON.stringify(error);
					if (error.indexOf("NotFoundError") !== -1) {
						errorMessage = "Camera or Mic are not found or not allowed in your device.";
					} else if (error.indexOf("NotReadableError") !== -1 || error.indexOf("TrackStartError") !== -1) {
						errorMessage =
							"Camera or Mic is being used by some other process that does not not allow these devices to be read.";
					} else if (
						error.indexOf("OverconstrainedError") !== -1 ||
						error.indexOf("ConstraintNotSatisfiedError") !== -1
					) {
						errorMessage =
							"There is no device found that fits your video and audio constraints. You may change video and audio constraints.";
					} else if (error.indexOf("NotAllowedError") !== -1 || error.indexOf("PermissionDeniedError") !== -1) {
						errorMessage = "You are not allowed to access camera and mic.";
					} else if (error.indexOf("TypeError") !== -1) {
						errorMessage = "Video/Audio is required.";
					} else if (error.indexOf("UnsecureContext") !== -1) {
						errorMessage =
							"Fatal Error: Browser cannot access camera and mic because of unsecure context. Please install SSL and access via https";
					} else if (error.indexOf("WebSocketNotSupported") !== -1) {
						errorMessage = "Fatal Error: WebSocket not supported in this browser";
					} else if (error.indexOf("no_stream_exist") !== -1) {
						//TODO: removeRemoteVideo(error.streamId);
					} else if (error.indexOf("data_channel_error") !== -1) {
						errorMessage = "There was a error during data channel communication";
					} else if (error.indexOf("ScreenSharePermissionDenied") !== -1) {
						errorMessage = "You are not allowed to access screen share";
					}

					console.log("WEBRTC error: ", errorMessage);
				}
			});

			return () => {
				if (disconnecting.current) return;
				handleDisconnect({ navigate: false });
			};
		}
		// eslint-disable-next-line
	}, [isReady]);

	useEffect(() => {
		if (!isSpeaker) {
			// initialize late so that micCurrent initialize late
			setTimeout(() => (initialized.current = true), 2000);
			return;
		}
		const initializeMicVideoControl = () => {
			if (!webRTCAdaptor.current?.localStream) return setTimeout(initializeMicVideoControl, 1000);
			setLocalStream(webRTCAdaptor.current.localStream);
			if (!isMicOn || initialized.current) webRTCAdaptor.current.muteLocalMic();
			if (!isVideoOn || initialized.current) webRTCAdaptor.current.turnOffLocalCamera(stream.id);
			initialized.current = true;
		};
		initializeMicVideoControl();
		// eslint-disable-next-line
	}, [isSpeaker]);

	const fetchEventInfoByLiveConversation = useCallback(
		async (liveConversationId: string) => {
			const data = await getEvents({
				page: 1,
				limit: 1,
				eventSchedule: EventType.Live_Conversation,
				liveConversationIds: [liveConversationId]
			});
			if (data?.totalEventsFound && data?.events?.length) {
				setEvent(data.events[0]);
			} else {
				history.go(-1);
			}
		},
		[getEvents, setEvent, history]
	);

	useEffect(() => {
		if (!workspace) {
			history.go(-1);
		}

		setRoomId(id);
		initialiseFirebase(workspace!);
		watchConversationUpdate(id);
	}, [watchConversationUpdate, id, workspace, initialiseFirebase, history, setRoomId]);

	useEffect(() => {
		if (!event?.liveConversationId || event?.liveConversationId !== id) {
			fetchEventInfoByLiveConversation(id);
		} else {
			setIsReady(true);
		}
	}, [id, fetchEventInfoByLiveConversation, event?.liveConversationId]);

	useEffect(() => {
		if (isReady) {
			if ((!localStream && isSpeaker) || disconnecting.current) return;
			if (isJoined.current) {
				const me = firebaseParticipants.find(participant => participant.personaId === activeProfile?.personaId);
				if (!me || me.banned || me.blocked) {
					if (me?.blocked) alert("you are blocked");
					return handleDisconnect();
				}
			}
			const streamsList: Stream[] =
				isSpeaker && localStream ? [{ stream: localStream, streamId: stream.id }, ...remoteStreams] : remoteStreams;
			let listWithStreams = getListWithStreams(streamsList, firebaseParticipants);
			if (pinnedPersonaId) {
				const pinnedUser = listWithStreams.find(participant => participant.personaId === pinnedPersonaId);
				if (pinnedUser) {
					listWithStreams = [
						pinnedUser,
						...listWithStreams.filter(participant => participant.personaId !== pinnedPersonaId)
					];
				}
			}
			setParticipantList(listWithStreams);
		}
		// eslint-disable-next-line
	}, [
		isReady,
		remoteStreams,
		firebaseParticipants,
		getListWithStreams,
		localStream,
		stream.id,
		isSpeaker,
		pinnedPersonaId
	]);

	useEffect(() => {
		if (isAdmin || !initialized.current) return;

		if (isSpeaker && !playOnlyTurned.current) {
			webRTCAdaptor.current?.publish(stream.id, id, undefined, undefined, undefined, undefined);
			playOnlyTurned.current = true;
			listenToAudioLevel();
		} else if (!isSpeaker && isJoined.current) {
			webRTCAdaptor.current?.stop(stream.id);
			playOnlyTurned.current = false;
			stopListenToAudioLevel();
		}
		// eslint-disable-next-line
	}, [isSpeaker, isAdmin]);

	useEffect(() => {
		if (!initialized.current || !webRTCAdaptor.current || !webRTCAdaptor.current.localStream) {
			return;
		}
		if (mutedCurrent) {
			webRTCAdaptor.current.muteLocalMic();
		} else {
			webRTCAdaptor.current.unmuteLocalMic();
		}
	}, [mutedCurrent]);

	useEffect(() => {
		if (!initialized.current || !webRTCAdaptor.current || !webRTCAdaptor.current.localStream) {
			return;
		}
		if (videoOnCurrent) {
			webRTCAdaptor.current.turnOnLocalCamera(stream.id);
		} else {
			webRTCAdaptor.current.turnOffLocalCamera(stream.id);
		}
	}, [videoOnCurrent, stream.id]);

	const listenToAudioLevel = () => {
		if (isSpeaker) {
			webRTCAdaptor.current?.enableStats(stream.id, true);
		}
	};

	const stopListenToAudioLevel = () => {
		webRTCAdaptor.current?.disableStats(stream.id);
	};

	const joinConversation = () => {
		joinParticipant({ streamId: stream.id, shouldStart: isAdmin && !firebaseParticipants.length, isAdmin }).then(() => {
			isJoined.current = true;
		});
		listenToAudioLevel();
	};

	const onPressLeave = () => {
		if (speakersLength === 1 && isSpeaker) {
			return setWarnEndConvo(true);
		}
		handleDisconnect();
	};

	const onShowWarn = () => {
		setWarnEndConvo(true);
	};

	useEffect(() => {
		setTimeout(() => {
			const participantViewWidth = participantSectionRef.current?.clientWidth || 0;
			const participantViewHeight = participantSectionRef.current?.clientHeight || 0;

			const dim = tilesResize({ height: participantViewHeight, width: participantViewWidth, total: totalParticipants });
			setTileDim(dim);
		}, 350);
	}, [screenWidth, screenHeight, sidebarType, totalParticipants]);

	const renderParticipant = useCallback(
		(participant, index, arr) => {
			const isPortraitMode = !isTiles || !!pinnedPersonaId;
			const isFirstPortrait = isPortraitMode && index === 0;
			let className = arr?.length === 1 ? "speakerfull" : "speaker-item";

			if (isPortraitMode) {
				if (!isFirstPortrait) className = "speakerhidden";
				else className = "speakerfull";
			}

			return (
				<ParticipantCard
					key={participant.streamId}
					className={className}
					style={{ width: tileDim.width, height: tileDim.height }}
				>
					<SpeakerView participant={participant} isMe={stream.id === participant.streamId} />
				</ParticipantCard>
			);
		},
		[isTiles, pinnedPersonaId, stream.id, tileDim]
	);

	const finalParticipantsList = useMemo(() => {
		if (isTiles) return participantList;
		const index = R.findIndex(R.maxBy(R.prop("volume")))(audioLevels);
		if (index > -1) {
			const maxAudioPersonaId = participantList.find(p => p.personaId === audioLevels[index].personaId)?.personaId;
			if (!maxAudioPersonaId) return participantList;
			return [
				participantList.find(p => p.personaId === maxAudioPersonaId),
				...participantList.filter(p => p.personaId !== maxAudioPersonaId)
			];
		}
		return participantList;
	}, [participantList, isTiles, audioLevels]);

	if (!isReady || !activeProfile || !myFirebaseDTO) {
		return <Loader size="20px" color="inherit" variant="indeterminate" show />;
	}

	return (
		<>
			<Wrapper>
				<Wrapper.ContentBox>
					<Wrapper.ParticipantsSection className={sidebarType ? "with-panel" : ""} ref={participantSectionRef}>
						{finalParticipantsList.map(renderParticipant)}
						<ConversationReactions />
					</Wrapper.ParticipantsSection>
					<Sidebar personaId={activeProfile.personaId} isAdmin={isAdmin} />
				</Wrapper.ContentBox>
				<Wrapper.ActionBox>
					<Actions
						webRTCAdaptorRef={webRTCAdaptor}
						streamId={stream.id}
						activeProfile={activeProfile}
						isSpeaker={isSpeaker}
						isAdmin={isAdmin}
						onLeave={onPressLeave}
						onShowWarn={onShowWarn}
					/>
				</Wrapper.ActionBox>
			</Wrapper>
			<EndAlert isAdmin={isAdmin} endConversation={() => handleDisconnect({ force: true })} />
			<ConfirmAlertModal
				open={warnEndConvo}
				onClose={() => setWarnEndConvo(false)}
				onConfirm={() => {
					handleDisconnect({ force: true });
					if (speakersLength === 1 && isSpeaker && event) {
						updateEvent({
							...event,
							location: undefined,
							admins: event.admins?.map(p => p.personaId),
							startTime: new Date(event.startTime as string),
							endTime: new Date(),
							eventId: event?.eventId,
							timezone: event.timeZone as LabelValueType,
							category: { value: event.categories[0] } as LabelValueType,
							isMemberView: true,
							eventImage: !!event.eventImages.length ? event.eventImages[0] : ""
						});
					}
				}}
				title="End conversation?"
				message={
					speakersLength > 1
						? "Are you sure you want to end this conversation?"
						: "You are the only speaker in the conversation.\nIf you leave, the conversation will end."
				}
				cancelText="Stay"
				okText={speakersLength > 1 ? "End" : "Leave"}
			/>
		</>
	);
};

export default Conversation;
