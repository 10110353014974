import { ReactNode } from "react";

import { IStateProps } from "utils/StateOf";
import createStore from "utils/createStore";

interface Values {
	message?: string | ReactNode;
	isError?: boolean;
}

export const initialState: IStateProps<Values> = {
	message: "",
	isError: false
};

export default createStore<Values>({
	initialState
});
