import React, { useCallback } from "react";

import { DateTime } from "luxon";
import styled from "styled-components";

import { useAnalytics } from "shared/hooks";

import { StatsItemKey } from "shared/types";
import { Box } from "shared/ui-kit";

import BlockHeader from "../BlockHeader";
import { SingleLineChart } from "../index";

const Wrapper = styled(Box)`
	display: flex;
	flex-direction: column;

	width: 100%;
`;

const BodyWrapper = styled(Box)`
	min-height: 200px;

	padding-bottom: 20px;
`;

const RevenueBlock: React.FC = () => {
	const { getRevenue, getData: getAnalyticsData } = useAnalytics();
	const { totalRevenue, revenueData } = getAnalyticsData();

	const changePeriod = useCallback(
		(requestedFrom: DateTime, requestedTo: DateTime) => {
			getRevenue(requestedFrom, requestedTo);
		},
		[getRevenue]
	);

	return (
		<Wrapper>
			<BlockHeader
				title={totalRevenue ? totalRevenue.toString() : "0"}
				subtitle={"Total revenue"}
				showDropdown={true}
				onChangePeriod={changePeriod}
				defaultValue={7}
			/>
			<BodyWrapper>
				<SingleLineChart
					data={revenueData}
					dataKey={StatsItemKey.revenueReceived}
					gradientId={"revenueGradientId"}
					color={"#6173fe"}
					stopColor={"rgba(97, 115, 254, 0)"}
				/>
			</BodyWrapper>
		</Wrapper>
	);
};

export default RevenueBlock;
