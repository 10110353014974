import React, { useEffect, useMemo, useState } from "react";

import { Progress } from "./styles";

type ProgressCircleProps = {
	progress: number;
	size: number;
};

const ProgressCircle: React.FC<ProgressCircleProps> = ({ progress, size }) => {
	const [strokeDashOffset, setStrokeDashOffset] = useState(0);

	const options = useMemo(
		() => ({
			strokeWidth: 2,
			backStrokeFill: "#edf1f7",
			strokeFill: "#6173fe",
			radius: size / 2 - 2
		}),
		[size]
	);

	const [circumference] = useState(options.radius * 2 * Math.PI);

	useEffect(() => {
		setStrokeDashOffset(circumference - (progress / 100) * circumference);
	}, [progress, circumference]);

	return (
		<Progress.Wrapper size={size}>
			<svg className="progress-ring" width={size} height={size}>
				<circle
					className="progress-ring__circle"
					stroke={options.backStrokeFill}
					strokeWidth={options.strokeWidth}
					fill="transparent"
					strokeLinecap="round"
					strokeDashoffset={0}
					strokeDasharray={`${circumference} ${circumference}`}
					r={options.radius}
					cx={size / 2}
					cy={size / 2}
				/>
				<circle
					className="progress-ring__circle"
					stroke={options.strokeFill}
					strokeWidth={options.strokeWidth}
					fill="transparent"
					strokeLinecap="round"
					strokeDashoffset={strokeDashOffset}
					strokeDasharray={`${circumference} ${circumference}`}
					r={options.radius}
					cx={size / 2}
					cy={size / 2}
				/>
			</svg>
			<Progress.Text>{progress}%</Progress.Text>
		</Progress.Wrapper>
	);
};

export default ProgressCircle;
