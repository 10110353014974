import React, { FC, useCallback, useEffect, useState } from "react";

import { Checkbox } from "@material-ui/core";

import clsx from "clsx";

import { SearchbarWrapper } from "modules/Messaging/View/Components/InboxPanel/style";
import { useDebounce, useFundraisers } from "shared/hooks";
import { ImageSizes, MediaModel, ProfileType } from "shared/types";
import { Search, Text } from "shared/ui-kit";

import { getResizedImage } from "utils/serviceUtils/cdnImages";

import { MemberOption } from "./style";

import { MemberDialog } from "../../shared";
import { MemberDialogContentWrapper } from "../../shared/style";

const ChangeMemberDialog: FC<{
	id: string;
	workspace?: string;
	updateOnBehalf?: boolean;
	goToDonation?: boolean;
}> = ({ id, updateOnBehalf, workspace, goToDonation }) => {
	const {
		getFundraiserMembers,
		setDonationDialog,
		setSelectMemberDialog,
		setOnBehalfOf,
		getData: getFundraiserData
	} = useFundraisers();
	const { donationDialog, onBehalfOf } = getFundraiserData();

	const [members, setMembers] = useState<ProfileType[]>([]);
	const [keyword, setKeyword] = useState("");
	const [loading, setLoading] = useState(false);

	const debouncedKeyword = useDebounce(keyword, 500);

	useEffect(() => {
		setLoading(true);
		getFundraiserMembers({
			id,
			keyword: debouncedKeyword,
			workspace
		}).then(members => {
			members && setMembers(members);
			setLoading(false);
		});
	}, [debouncedKeyword, getFundraiserMembers, id, workspace]);

	const handleChangeMember = useCallback(
		(m: ProfileType) => {
			if (updateOnBehalf && onBehalfOf) {
				setOnBehalfOf({
					...onBehalfOf,
					persona: {
						_id: m._id,
						firstName: m.firstName,
						lastName: m.lastName,
						personaId: m.personaId,
						profilePicture: !!m.photos.length ? ((m.photos[0] as MediaModel)?.profilePicture as string) : ""
					}
				});

				setSelectMemberDialog();
			} else if (donationDialog?.behalf) {
				setDonationDialog({
					open: true,
					behalf: {
						...donationDialog.behalf,
						member: {
							personaId: m.personaId,
							name: `${m.firstName} ${m.lastName}`,
							img: !!m.photos.length
								? getResizedImage((m.photos[0] as MediaModel).profilePicture as string, ImageSizes.size100x100)
								: ""
						}
					}
				});

				setSelectMemberDialog();
			} else if (goToDonation) {
				setDonationDialog({
					open: true,
					behalf: {
						group: {
							img: m!.group?.coverUrl || "",
							name: m!.group?.name || "",
							id: m!.group!._id
						},
						member: {
							personaId: m.personaId,
							name: `${m.firstName} ${m.lastName}`,
							img: !!m.photos.length
								? getResizedImage((m.photos[0] as MediaModel).profilePicture as string, ImageSizes.size100x100)
								: ""
						}
					}
				});

				setSelectMemberDialog();
			}
		},
		[
			donationDialog?.behalf,
			onBehalfOf,
			setDonationDialog,
			setOnBehalfOf,
			setSelectMemberDialog,
			updateOnBehalf,
			goToDonation
		]
	);

	return (
		<MemberDialog
			loading={loading}
			open
			onClose={setSelectMemberDialog}
			noFooter
			customWidth={432}
			title="Select a member"
		>
			<MemberDialogContentWrapper className="py-4 overflow-y-auto flex-1 flex flex-col">
				<SearchbarWrapper className="member-side-search block mb-4">
					<Search
						autoComplete="off"
						defaultValue=""
						value={keyword}
						onChange={e => setKeyword(e.target.value)}
						placeholder="Search"
					/>
				</SearchbarWrapper>
				<div className="flex flex-col gap-2">
					{members.map(m => (
						<MemberOption
							onClick={() => handleChangeMember(m)}
							key={m._id}
							className={clsx(
								"flex items-center justify-between px-2",
								m.personaId === donationDialog?.behalf.member.personaId && "active"
							)}
						>
							<div className="flex items-center gap-4">
								{!!m.photos?.length && (
									<img
										src={getResizedImage((m.photos[0] as MediaModel).profilePicture as string, ImageSizes.size200x200)}
										alt={m.firstName}
										className="w-8 h-8 rounded-lg object-cover"
									/>
								)}
								<Text>
									{m.firstName} {m.lastName}
								</Text>
							</div>
							{m.personaId === donationDialog?.behalf.member.personaId && (
								<>
									<Text className="invited">Invited you</Text>
									<Checkbox color="primary" checked />
								</>
							)}
						</MemberOption>
					))}
				</div>
			</MemberDialogContentWrapper>
		</MemberDialog>
	);
};

export default ChangeMemberDialog;
