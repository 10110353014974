import { Box } from "@material-ui/core";
import styled from "styled-components";

import { Button, Text } from "shared/ui-kit";

export const Header = styled(Box)`
	display: flex;
	justify-content: space-between;
	align-items: center;

	margin-top: 12px;
`;

Header.Column = styled(Box)`
	width: 50%;

	display: flex;

	&.right {
		justify-content: flex-end;
	}
`;

Header.Text = styled(Text)`
	font-size: 15px;
	line-height: 1.47;
	color: #222b45;
`;

Header.SettingsBox = styled(Box)`
	width: 48px;
	height: 48px;

	padding: 12px;

	border-radius: 12px;

	box-shadow: inset 0 0 0 0.5px #c5cee0;

	background-color: #fff;

	display: flex;
	justify-content: center;
	align-items: center;

	cursor: pointer;

	svg {
		path {
			&:nth-child(2) {
				fill: #8f9bb3;
			}
		}
	}
`;

export const StyledActionsWrapper = styled(Box)`
	display: flex;
	align-items: center;
`;

export const StyledActionButton = styled(Button)`
	width: 90px;

	margin: 0 4px;

	&.square {
		width: auto;
	}
`;
