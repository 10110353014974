import React from "react";

import { useIntegrations } from "modules/Integrations/Data/hooks";

import ShopifyCard from "../Cards/ShopifyCard";

import IntegrationRow from "../IntegrationRow";

const PodcastRow = () => {
	const { getData: getIntegrationsData } = useIntegrations();
	const { podcasts, isLoading } = getIntegrationsData();

	if (!podcasts.length && isLoading) return null;

	return (
		<IntegrationRow title="E-commerce">
			<ShopifyCard />
		</IntegrationRow>
	);
};

export default PodcastRow;
