import * as R from "ramda";

import { FileFormModel, FileRequest, UpdateFileFormModel } from "types/FilesContextValuesType";

import { MediaModel, MediaObjType } from "shared/types";

export const convertFile = (
	data: FileFormModel,
	fileMedia: MediaModel,
	coverPhotoMedia: MediaModel | null
): FileRequest => {
	return {
		...R.pick(
			["title", "description", "categoryId", "scheduleDate", "private", "priceTags", "groupId", "eventId"],
			data
		),
		canBeDownloaded: !!data.canBeDownloaded,
		file: {
			mediaId: fileMedia._id,
			url: fileMedia.uri,
			extension: data.extension
		},
		coverPhoto: coverPhotoMedia
			? {
					mediaId: coverPhotoMedia._id,
					url: coverPhotoMedia.uri
			  }
			: undefined
	};
};

export const convertUpdateFile = (data: UpdateFileFormModel, coverPhotoMedia?: MediaObjType): FileRequest => {
	return {
		...R.pick(["title", "description", "categoryId", "scheduleDate", "private", "priceTags", "groupId"], data),
		canBeDownloaded: !!data.canBeDownloaded,
		coverPhoto: coverPhotoMedia ? coverPhotoMedia : data.coverPhotoUrl === null ? undefined : data.coverPhoto
	};
};
