import { safelyParseJSON } from "../serviceUtils/helpers";

export enum AdZone {
	chatZone = "webChatZone",
	storyZone = "webStoryZone",
	seeAllZone = "webSeeAllZone",
	cardZone = "webCardZone",
	postZone = "webPostZone",
	matchesZone = "webMatchesZone"
}

export const getRandomAD = (zone: AdZone, bannerAdConfig?: string): string | undefined => {
	const zones: AdZone = safelyParseJSON(bannerAdConfig);

	return (
		zones[zone] && zones[zone]?.code.replaceAll("INSERT_RANDOM_NUMBER_HERE", `${Math.floor(Math.random() * 1000000)}`)
	);
};
