import React, { FC, useCallback, useEffect, useRef, useState } from "react";

import { Switch } from "@material-ui/core";

import { ReactComponent as BlockIcon } from "assets/icons/block.svg";
import { Dialog } from "shared/Components";
import { useCommunity } from "shared/hooks";
import { Icon } from "shared/ui-kit";

import { updateElSize } from "utils/serviceUtils/helpers";

import { ActionBtn, Option, Title } from "./style";

interface ReportedContentRulesModalProps {
	open: boolean;
	onClose: () => void;
}

const ReportedContentRulesModal: FC<ReportedContentRulesModalProps> = ({ open, onClose }) => {
	const { updateWorkspaceMeta, getData: getCommunityData } = useCommunity();
	const { workspace } = getCommunityData();

	const suspendInputRef = useRef<HTMLInputElement>();
	const deleteInputRef = useRef<HTMLInputElement>();

	const [contentRules, setContentRules] = useState({
		isSuspendEnabled: workspace?.reportContentSettings?.isSuspendEnabled || false,
		suspendContentCount: workspace?.reportContentSettings?.suspendContentCount || 5,
		isDeleteEnabled: workspace?.reportContentSettings?.isDeleteEnabled || false,
		deleteContentCount: workspace?.reportContentSettings?.deleteContentCount || 10
	});
	const [isChanged, setIsChanged] = useState(false);

	const save = useCallback(() => {
		updateWorkspaceMeta({
			reportContentSettings: contentRules
		});
		onClose();
	}, [updateWorkspaceMeta, contentRules, onClose]);

	const changeCount = useCallback((val: string, isSuspend?: boolean) => {
		const num = Number(val.replace(/[^\d.]/g, ""));
		let correctNum = isNaN(num) ? 0 : num;

		if (correctNum > 1000) {
			correctNum = 1000;
		}

		setIsChanged(true);

		if (isSuspend) {
			setContentRules(r => ({ ...r, suspendContentCount: correctNum }));
			updateElSize(suspendInputRef.current, num.toString());
		} else {
			setContentRules(r => ({ ...r, deleteContentCount: correctNum }));
			updateElSize(deleteInputRef.current, num.toString());
		}
	}, []);

	useEffect(() => {
		if (open) {
			setTimeout(() => {
				updateElSize(suspendInputRef.current, (workspace?.reportContentSettings?.suspendContentCount || 5).toString());
				updateElSize(deleteInputRef.current, (workspace?.reportContentSettings?.deleteContentCount || 10).toString());
			}, 150);

			setContentRules({
				isSuspendEnabled: workspace?.reportContentSettings?.isSuspendEnabled || false,
				suspendContentCount: workspace?.reportContentSettings?.suspendContentCount || 5,
				isDeleteEnabled: workspace?.reportContentSettings?.isDeleteEnabled || false,
				deleteContentCount: workspace?.reportContentSettings?.deleteContentCount || 10
			});
		}
	}, [workspace?.reportContentSettings, open]);

	const toggleSettings = (isEnabled: boolean, isSuspend: boolean) => {
		if (isEnabled) {
			if (isSuspend && contentRules.isDeleteEnabled) {
				setContentRules(state => ({ ...state, isDeleteEnabled: false }));
			}

			if (!isSuspend && contentRules.isSuspendEnabled) {
				setContentRules(state => ({ ...state, isSuspendEnabled: false }));
			}
		}

		if (isSuspend) {
			setContentRules(state => ({ ...state, isSuspendEnabled: isEnabled }));
		} else {
			setContentRules(state => ({ ...state, isDeleteEnabled: isEnabled }));
		}

		setIsChanged(true);
	};

	return (
		<Dialog
			title={<Title>Reported content rules</Title>}
			open={open}
			onClose={onClose}
			footer={
				<ActionBtn size={"large"} onClick={save} disabled={!isChanged}>
					Save
				</ActionBtn>
			}
		>
			<Option.Wrapper>
				<Option>
					<Option.Content>
						<Option.IconWrapper>
							<BlockIcon />
						</Option.IconWrapper>
						<Option.Body>
							<Option.Title>Suspend content</Option.Title>
							<Option.Text>
								When a content is reported
								<Option.InputWrapper className={!contentRules.isSuspendEnabled && "disabled"}>
									<Option.Input
										disabled={!contentRules.isSuspendEnabled}
										ref={suspendInputRef}
										value={contentRules.suspendContentCount}
										onChange={e => changeCount(e.target.value, true)}
									/>
								</Option.InputWrapper>
								times,
								<br />
								automatically suspend it (hide temporarily).
							</Option.Text>
						</Option.Body>
					</Option.Content>
					<Option.ToggleWrapper>
						<Switch checked={contentRules.isSuspendEnabled} onChange={e => toggleSettings(e.target.checked, true)} />
					</Option.ToggleWrapper>
				</Option>
			</Option.Wrapper>
			<Option.Wrapper>
				<Option>
					<Option.Content>
						<Option.IconWrapper>
							<Icon height={24} width={24} name="trash-linear" fill="#8f9bb3" />
						</Option.IconWrapper>
						<Option.Body>
							<Option.Title>Delete content</Option.Title>
							<Option.Text>
								When a content is reported
								<Option.InputWrapper className={!contentRules.isDeleteEnabled && "disabled"}>
									<Option.Input
										disabled={!contentRules.isDeleteEnabled}
										ref={deleteInputRef}
										value={contentRules.deleteContentCount}
										onChange={e => changeCount(e.target.value)}
									/>
								</Option.InputWrapper>
								times,
								<br />
								automatically delete it permanently.
							</Option.Text>
						</Option.Body>
					</Option.Content>
					<Option.ToggleWrapper>
						<Switch checked={contentRules.isDeleteEnabled} onChange={e => toggleSettings(e.target.checked, false)} />
					</Option.ToggleWrapper>
				</Option>
			</Option.Wrapper>
		</Dialog>
	);
};

export default ReportedContentRulesModal;
