import { useMemo } from "react";

import { API_URLS } from "shared/constants";
import useHttpActions from "shared/services/api/core/useHttpActions";
import { BaseConverter } from "shared/services/converters";

import { PersonStoryModel, ProfileType } from "../types";

const usePaymentApiService = () => {
	const actions = useHttpActions();

	return useMemo(
		() => ({
			getUsersConnections: async ({
				personaId,
				offset = 1,
				limit = 10,
				keyword = ""
			}: {
				personaId: number;
				offset?: number;
				limit?: number;
				keyword?: string;
			}) => {
				const defaultQuery: {
					viewerPersonaId: number;
					responseType: string;
					type: string;
					lat: number;
					lon: number;
					offset: number;
					limit: number;
					keyword?: string;
				} = {
					viewerPersonaId: personaId,
					responseType: "detail",
					type: "userConnections",
					lat: 0,
					lon: 0,
					offset,
					limit
				};

				if (keyword) {
					defaultQuery.keyword = keyword;
				}

				return BaseConverter.convert<{ total: number; connections: ProfileType[] }>(
					await actions.post(API_URLS.CONNECTION.USER_CONNECTIONS, defaultQuery)
				);
			},
			getPendingConnections: async ({
				personaId,
				offset = 1,
				limit = 10
			}: {
				personaId: number;
				offset?: number;
				limit?: number;
			}) => {
				const defaultQuery = {
					personaId: personaId,
					lat: 0,
					lon: 0,
					radius: 0,
					offset,
					limit
				};

				return BaseConverter.convert<{ total: number; connections: ProfileType[] }>(
					await actions.post(API_URLS.CONNECTION.PENDING, defaultQuery)
				);
			},
			getRecommendedConnections: async ({
				personaId,
				offset = 1,
				limit = 10,
				potentialMatchingIds,
				type = "personas"
			}: {
				personaId: number;
				offset?: number;
				limit?: number;
				potentialMatchingIds: string[];
				type?: string;
			}) => {
				const defaultQuery = {
					personaId: personaId,
					lat: 0,
					lon: 0,
					radius: 0,
					offset,
					limit,
					type,
					potentialMatchingIds
				};

				return BaseConverter.convert<{ total: number; stories: PersonStoryModel[] }>(
					await actions.post(API_URLS.CONNECTION.RECOMMENDED, defaultQuery)
				);
			},
			undoMatchPreference: async (id: string) => {
				return BaseConverter.convert(await actions.delete(API_URLS.CONNECTION.UNDO(id)));
			},
			manageConnection: async ({ connectionId, isAccept }: { connectionId: string; isAccept: boolean }) => {
				return BaseConverter.convert<{ success: boolean }>(
					await actions.post(API_URLS.CONNECTION.MANAGE(isAccept), { connectionId })
				);
			},
			requestConnection: async (connecteeId: number, initiatorId: number) => {
				return BaseConverter.convert<{ connectionId: string }>(
					await actions.post(API_URLS.CONNECTION.REQUEST, { connecteeId, initiatorId })
				);
			},
			setMatchIntent: async (data, viewerPersonaId: number) => {
				return BaseConverter.convert<{
					personaPreference: {
						_id: string;
					};
				}>(
					await actions.post(API_URLS.CONNECTION.PREFERENCE, {
						...data,
						viewerPersonaId
					})
				);
			},
			deleteUserConnection: async (connectionId: string) => {
				return BaseConverter.convert(
					await actions.delete(API_URLS.CONNECTION.DELETE, {
						connectionId
					})
				);
			},
			deleteConnection: async (connectionId: string) => {
				return BaseConverter.convert(
					await actions.delete(API_URLS.USER.DELETE_CONNECTION, {
						connectionId
					})
				);
			},
			report: async ({
				initiatorId,
				reporteeId,
				reportType,
				reason
			}: {
				initiatorId: string;
				reporteeId: number;
				reportType: string;
				reason?: string;
			}) => {
				return BaseConverter.convert(
					await actions.post(API_URLS.CONNECTION.REPORT, {
						initiatorId,
						reporteeId,
						reportType,
						reason: reason || ""
					})
				);
			}
		}),
		[actions]
	);
};

export default usePaymentApiService;
