import { useMemo } from "react";

import { API_URLS } from "shared/constants";
import useHttpActions from "shared/services/api/core/useHttpActions";

import { BaseConverter } from "shared/services/converters";

const useNotificationService = () => {
	const actions = useHttpActions();

	return useMemo(
		() => ({
			notifyAllUsers: async (data: any): Promise<[any]> => {
				return BaseConverter.convert<[any]>(await actions.post(API_URLS.EVENTS.NOTIFY_ALL_USERS, { payload: data }));
			}
		}),
		[actions]
	);
};

export default useNotificationService;
