import React from "react";

import { useParams } from "react-router";

import { view } from "modules/MemberHome";
import { useMemberRoutes } from "shared/hooks";

const MemberProfile = () => {
	const { id } = useParams() as { id: string };
	const { getMemberRoutesData } = useMemberRoutes();
	const { routes } = getMemberRoutesData();

	return <view.MemberProfile id={id} connectionsPageUrl={routes?.member.connections.getPath()} />;
};

export default MemberProfile;
