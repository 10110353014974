import styled from "styled-components";

import { Box } from "shared/ui-kit";

export const Wrapper = styled(Box)`
	width: 100%;
	height: 1px;

	margin: 20px 0 24.5px 0;

	box-shadow: inset 0 0.5px 0 0 #c5cee0;
`;
