import React, { FC, useCallback, useEffect, useState } from "react";

import { ReactComponent as VyooLogo } from "assets/icons/vyoo-logo.svg";

import { useFundraisers } from "shared/hooks";
import { Button, Text } from "shared/ui-kit";

export interface UnsubscribeProps {
	contactId: string;
	workspace: string;
	workspaceId: string;
}

const Unsubscribe: FC<UnsubscribeProps> = ({ contactId, workspace }) => {
	const [resubscribed, setResubscribed] = useState(false);

	const { subscribe } = useFundraisers();

	const changeSubscription = useCallback(
		(isResubscribe = false) => {
			subscribe({ action: isResubscribe ? "resub" : "unsub", workspace, contactId });
		},
		[subscribe, workspace, contactId]
	);

	useEffect(() => {
		changeSubscription();
	}, [workspace, contactId, changeSubscription]);

	const resubscribe = () => {
		changeSubscription(true);
		setResubscribed(true);
	};

	return (
		<div className="flex justify-center px-4 py-10 md:py-[120px] md:px-10">
			<div className="w-fit p-4 md:p-10 rounded-xl bg-white border border-gray-300 flex flex-col items-center">
				<VyooLogo width={216} height={77} className="w-[216px] h-[77px]" />
				<Text variant="h1" className="mt-9">
					{resubscribed ? "Resubscribed!" : "Unsubscribed!"}
				</Text>
				<Text variant="body1" className="mt-1 text-center">
					{resubscribed
						? "You have successfully resubscribed to Vyoo emails."
						: "You have successfully unsubscribed from the Vyoo emails."}
				</Text>
				{!resubscribed && (
					<>
						<Text variant="small" className="mt-6 text-hint">
							Didn’t mean to unsubscribe?
						</Text>
						<Button palette="primary" fullWidth removeSideMargin size="large" className="mt-5" onClick={resubscribe}>
							Resubscribe
						</Button>
					</>
				)}
			</div>
		</div>
	);
};

export default Unsubscribe;
