import styled, { css } from "styled-components";

import { Box, Text } from "shared/ui-kit";

export const HeaderWrapper = styled(Box)`
	width: 100%;

	${props => props.theme.breakpoints.up("lg")} {
		margin: 16px auto 0 auto;
		width: 1112px;
	}
`;

export const HeaderBlock = styled(Box)`
	display: block;

	width: 100%;

	background: white;

	${props => props.theme.breakpoints.up("lg")} {
		border-radius: 12px;
	}

	overflow: hidden;

	box-shadow: inset 0 0 0 0.5px #c5cee0, 0 2px 4px 0 rgba(0, 0, 0, 0.08);

	${props => props.theme.breakpoints.down("sm")} {
		box-shadow: none;

		// border-bottom: 8px solid #eff2f5;
	}
`;

export const ContentBlock = styled(Box)`
	width: 632px;
	margin: 0 auto;

	padding-top: 16px;

	${props => props.theme.breakpoints.down("sm")} {
		width: 100%;
		padding-top: 0;
	}
`;

export const PageWrapper = styled.section<{ blurred?: boolean }>`
	width: 100%;
	overflow: hidden;

	${props => props.theme.breakpoints.down("sm")} {
		background: #eff2f5;
	}

	${({ blurred }) => {
		return (
			blurred &&
			css`
				filter: blur(8px);
			`
		);
	}};
`;

export const JoinBtnWrapper = styled(Box)`
	display: flex;
	justify-content: center;

	margin: 20px 0;

	${props => props.theme.breakpoints.down("sm")} {
		margin-bottom: 40px;
	}
`;

export const SkeletonWrapper = styled.div`
	div {
		display: flex;
		flex-direction: row-reverse;

		> span {
			width: 90%;
			margin-left: 10px;
			margin-bottom: 10px;
		}
	}
`;

export const ManageBlock = styled(Box)`
	width: 100%;
	height: 64px;

	margin: 16px 0 0 0;
	padding: 12px 16px;

	border-radius: 12px;

	box-shadow: inset 0 0 0 0.5px #c5cee0, 0 2px 4px 0 rgba(0, 0, 0, 0.08);

	background-color: #fff;

	${props => props.theme.breakpoints.down("sm")} {
		margin: 8px 0;
	}
`;

export const TabList = styled(Box)<{ allOptions?: boolean }>`
	display: flex;
	align-items: center;
`;

export const Tab = styled(Text)`
	font-size: 15px;
	font-weight: 600;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.6;
	letter-spacing: normal;
	color: #222b45;

	//color: ${({ active }) => (active ? "#6173fe" : "#222b45")};

	padding: 8px 16px;
	margin: 0 4px;

	position: relative;

	cursor: pointer;

	border-radius: 8px;

	box-shadow: inset 0 0 0 0.5px #c5cee0;

	background-color: #fff;

	transition: background-color 0.25s linear;

	&:first-child {
		margin-left: 0;
	}

	&:last-child {
		margin-right: 0;
	}

	&.active {
		background-color: #edf1f7;
	}
`;
