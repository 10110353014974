import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";

import { Skeleton } from "@material-ui/lab";

import { Suggestion, SuggestionTypes } from "shared/contexts/UserContext/UserContext";
import { useCommunity, useDebounce, useNotification, useOnScreen, usePersona, useUser } from "shared/hooks";
import { Checkbox } from "shared/ui-kit";

import { MemberDialog } from "../../shared";
import { RefBox } from "../../shared/MemberDialog/style";
import { ProfileListingDialogWrapper } from "../../shared/style";

const AddProfileInfoDialog: FC<{
	categorizedList?: {
		category: string;
		ids: Suggestion[];
	}[];
	list?: Suggestion[];
	updateType: "goals" | "skills" | "interests" | "languages";
	title: string;
	handleClose: () => void;
}> = ({ categorizedList, list, title, handleClose, updateType }) => {
	const { communityColors } = useCommunity();

	const { updatePersona, getData: getPersonaData } = usePersona();
	const { persona: profile } = getPersonaData();

	const { showMessage } = useNotification();

	const { searchSuggestions, listSuggestions, fetchInterests, fetchGoals, getData: getUserData } = useUser();
	const { loading } = getUserData();

	const [data, setData] = useState<Suggestion[]>([]);
	const [isDirty, setIsDirty] = useState(false);
	const [submitting, setSubmitting] = useState(false);
	const [offset, setOffset] = useState(1);
	const [keyword, setKeyword] = useState("");
	const [loadData, setLoadData] = useState(true);
	const debouncedKeyword = useDebounce(keyword, 400);

	const lastItemRef = useRef<HTMLDivElement>(null);
	const onScreen = useOnScreen(lastItemRef);

	useEffect(() => {
		if (debouncedKeyword) {
			searchSuggestions(
				updateType === "languages" ? SuggestionTypes.language : SuggestionTypes.skill,
				debouncedKeyword
			);
		}
	}, [debouncedKeyword, searchSuggestions, updateType]);

	useEffect(() => {
		if (onScreen) {
			setOffset(state => state + 1);
			setLoadData(true);
		}
	}, [onScreen]);

	useEffect(() => {
		if (loadData && !keyword) {
			updateType === "goals"
				? fetchGoals()
				: updateType === "interests"
				? fetchInterests()
				: listSuggestions(updateType === "languages" ? "language" : "skill", 20, offset).then(() => {
						setLoadData(false);
				  });
		}
	}, [fetchGoals, fetchInterests, keyword, listSuggestions, loadData, offset, updateType]);

	useEffect(() => {
		if (updateType === "goals" && profile?.goals?.length) {
			setData(profile.goals);
		} else if (updateType === "skills" && profile?.skills?.length) {
			setData(profile.skills);
		} else if (updateType === "languages" && profile?.languages?.length) {
			setData(profile.languages);
		} else if (updateType === "interests" && profile?.interests?.length) {
			setData(profile.interests);
		}
	}, [profile, updateType]);

	const handleOption = useCallback(
		(goal: Suggestion, unset?: boolean) => {
			unset ? setData(data => data.filter(s => s._id !== goal._id)) : setData(data => [goal, ...data]);

			if (!isDirty) {
				setIsDirty(true);
			}
		},
		[isDirty]
	);

	const skeleton = useMemo(
		() => (
			<ProfileListingDialogWrapper.Option skeleton disabled={true}>
				<Skeleton width={160} height={24} />
				<Skeleton width={24} height={24} />
			</ProfileListingDialogWrapper.Option>
		),
		[]
	);

	const categorizedListRender = useMemo(
		() =>
			categorizedList &&
			categorizedList.map((section, i) => (
				<>
					<ProfileListingDialogWrapper.SubHeader key={i}>{section.category}</ProfileListingDialogWrapper.SubHeader>
					<ProfileListingDialogWrapper.CircularOptions color={communityColors.primary}>
						{section.ids.map(goal => {
							const isChecked = data.some(sk => sk._id === goal._id);
							return (
								<ProfileListingDialogWrapper.CircularOption
									color={communityColors.primary}
									key={goal._id}
									active={isChecked}
									onClick={() => handleOption(goal, isChecked)}
								>
									{goal.label}
								</ProfileListingDialogWrapper.CircularOption>
							);
						})}
					</ProfileListingDialogWrapper.CircularOptions>
				</>
			)),
		[categorizedList, communityColors.primary, data, handleOption]
	);

	const listRender = useMemo(
		() =>
			list && (
				<ProfileListingDialogWrapper>
					<ProfileListingDialogWrapper.InputWrapper>
						<ProfileListingDialogWrapper.SearchInput
							placeholder="Search"
							onChange={(e: React.ChangeEvent<HTMLInputElement>) => setKeyword(e.target.value)}
						/>
					</ProfileListingDialogWrapper.InputWrapper>
					{list.map((item, i) => {
						const isChecked = data.some(sk => sk._id === item._id);
						return (
							<RefBox key={item._id} ref={i + 1 === list.length ? lastItemRef : null}>
								<ProfileListingDialogWrapper.Option
									color={communityColors.primary}
									active={isChecked}
									onClick={() => handleOption(item, isChecked)}
								>
									{item.label}
									<Checkbox checked={isChecked} />
								</ProfileListingDialogWrapper.Option>
							</RefBox>
						);
					})}
					{loading && (
						<>
							{skeleton}
							{skeleton}
							{skeleton}
							{skeleton}
						</>
					)}
				</ProfileListingDialogWrapper>
			),
		[communityColors.primary, data, handleOption, list, loading, skeleton]
	);

	const handleSubmit = useCallback(async () => {
		if (!profile) return;

		setSubmitting(true);

		try {
			if (updateType === "goals" || updateType === "skills" || updateType === "interests") {
				updatePersona(
					{
						...profile,
						[updateType]: data.map(item => ({ label: item.label, _id: item._id, isTop: false }))
					},
					true
				);
			} else if (updateType === "languages") {
				updatePersona({ ...profile, languages: data.map(item => ({ label: item.label, _id: item._id })) }, true);
			}

			handleClose();

			showMessage(`Successfully updated your ${updateType}!`);
		} catch (error) {
			console.log(error);
		}

		setSubmitting(false);
	}, [profile, updatePersona, data, handleClose, showMessage, updateType]);

	return (
		<MemberDialog
			title={title}
			open={true}
			onClose={handleClose}
			confirmLeave={isDirty}
			footerPrimary={{
				text: "Done",
				loading: submitting,
				disabled: submitting || !isDirty,
				onClick: handleSubmit
			}}
			footerSecondary={{
				text: "Clear All",
				onClick: () => setData([]),
				disabled: submitting,
				ghost: true
			}}
		>
			<form onSubmit={handleSubmit}>
				<ProfileListingDialogWrapper verticalPadding>
					{categorizedList ? categorizedListRender : listRender}
				</ProfileListingDialogWrapper>
			</form>
		</MemberDialog>
	);
};

export default AddProfileInfoDialog;
