import React, { FC, memo, useCallback, useEffect, useMemo, useState } from "react";

import { STORIES_OPEN_TYPE } from "shared/contexts/StoryContext/StoryContext";
import { useConnection, usePopulateWithAds, useStory, useUser } from "shared/hooks";
import { AdWrapper } from "shared/styles";
import { PersonStoryModel } from "shared/types";

import { AdZone } from "utils/ads/getRandomAd";

import { ItemWrapper, ListWrapper } from "./style";

import { BoxItem, MatchCard, Slider, Stories } from "..";

const Matches: FC<{ recommended?: boolean }> = memo(({ recommended }) => {
	const [filteredStories, setFilteredStories] = useState<number[]>([]);

	const { getRecommendedConnections } = useConnection();

	const { getPotentialMatchIds, getData: getUserData } = useUser();
	const { user } = getUserData();

	const { openStories, getData: getStoryData } = useStory();
	const { isOpen } = getStoryData();

	const [recommendedConnections, setRecommendedConnections] = useState<PersonStoryModel[]>([]);
	const [total, setTotal] = useState(0);
	const [offset, setOffset] = useState(1);

	useEffect(() => {
		const matchingIds = getPotentialMatchIds(user);
		getRecommendedConnections({
			limit: 20,
			offset,
			potentialMatchingIds: matchingIds,
			type: recommended ? "stories" : "personas"
		}).then(data => {
			const connections = data.stories.filter(st => !!st.stories?.length || !!st.photos.length);

			setRecommendedConnections(ctx => (offset === 1 ? connections : [...ctx, ...connections]));
			setTotal(data.total);
		});
	}, [getPotentialMatchIds, getRecommendedConnections, recommended, user, offset]);

	const listWithAds = usePopulateWithAds({
		list: recommendedConnections,
		zone: AdZone.matchesZone,
		every: 10,
		skip: 0
	});

	const handleEndScroll = useCallback(
		() => listWithAds.length < total && setOffset(offset => offset + 1),
		[listWithAds.length, total]
	);

	const onSliderClose = useCallback(
		(personaIds: number[]) => {
			setRecommendedConnections(recommendedConnections.filter(c => !personaIds.some(p => p === c.personaId)));
		},
		[recommendedConnections]
	);

	const filterMatch = (personaId: number) => {
		setFilteredStories(ctx => [...ctx, personaId]);
	};

	const renderConnections = useMemo(
		() =>
			listWithAds.map((item, index) => {
				if (typeof item === "string") {
					return (
						<ItemWrapper>
							<AdWrapper className="matches_ad--wrapper" dangerouslySetInnerHTML={{ __html: item }} />
						</ItemWrapper>
					);
				}

				if (filteredStories.some(st => item.personaId === st)) return null;
				return (
					<ItemWrapper
						key={index}
						onClick={() =>
							openStories({
								isOpen: recommended ? STORIES_OPEN_TYPE.RECOMMENDED_MATCHES : STORIES_OPEN_TYPE.MATCHES,
								storyId: item?.stories?.length ? item.stories[0]._id : undefined
							})
						}
					>
						<MatchCard match={item} />
					</ItemWrapper>
				);
			}),
		[listWithAds, filteredStories, openStories, recommended]
	);

	if (!listWithAds.length) return null;

	return (
		<>
			<BoxItem title={recommended ? "Recommended matches" : " New matches nearby"} smallTitle>
				<ListWrapper>
					<Slider hideFrom={1} count={listWithAds.length} itemSize={200} onEndScroll={handleEndScroll}>
						{renderConnections}
					</Slider>
				</ListWrapper>
			</BoxItem>
			{recommended
				? isOpen === STORIES_OPEN_TYPE.RECOMMENDED_MATCHES && (
						<Stories
							personsStoryList={listWithAds}
							updateStories={setRecommendedConnections}
							onSliderClose={onSliderClose}
							filterMatch={filterMatch}
							filteredStories={filteredStories}
						/>
				  )
				: isOpen === STORIES_OPEN_TYPE.MATCHES && (
						<Stories
							personsStoryList={listWithAds}
							updateStories={setRecommendedConnections}
							onSliderClose={onSliderClose}
							filterMatch={filterMatch}
							filteredStories={filteredStories}
						/>
				  )}
		</>
	);
});

export default Matches;
