export enum UserRoles {
	Member = "Member",
	Moderator = "Moderator",
	Admin = "Admin",
	Owner = "Owner"
}

export enum TeamRoles {
	Owner = "isOwner",
	Moderator = "isModerator",
	Admin = "isAdmin",
	Influencer = "isInfluencer"
}
