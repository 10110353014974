import TimeAgo from "react-timeago";
import styled from "styled-components";

import { ReactComponent as MuteIcon } from "assets/icons/icon-sound-off.svg";
import { Box, Text } from "shared/ui-kit";

export const NotificationIcon = styled(Box)`
	width: 100%;
	height: 100%;

	border-radius: 50%;

	overflow: hidden;

	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
`;

export const Item = styled(Box)``;

Item.Wrapper = styled(Box)`
	display: flex;
	align-items: center;

	padding: 12px 16px 14px 16px;
	width: 100%;

	position: relative;

	transition: background-color 0.15s ease-in-out;

	&.unread,
	&:hover {
		background: #f7f9fc;
	}

	&:not(:last-child) {
		&:after {
			content: "";

			position: absolute;
			bottom: 0;

			left: 16px;
			width: calc(100% - 32px);
			height: 1px;

			background: transparent;

			box-shadow: inset 0 0 0 0.5px #c5cee0;
		}
	}
`;

Item.IconWrapper = styled(Box)<{ iconColor: string }>`
	width: 32px;
	height: 32px;

	border-radius: 50%;
	background-color: #edf1f7;

	margin-right: 15px;

	display: flex;
	justify-content: center;
	align-items: center;

	position: relative;

	&.unread {
		&:after {
			content: "";

			position: absolute;
			top: -2px;
			right: -2px;

			width: 8px;
			height: 8px;

			border-radius: 50%;

			overflow: hidden;

			background: #ff463d;
		}
	}

	svg {
		width: 20px;
		height: 20px;
		path {
			fill: ${({ iconColor }) => iconColor || "#979BA1"};
		}
	}
`;

Item.InfoBlock = styled(Box)`
	display: block;

	width: calc(100% - 100px);
`;

Item.ManageBlock = styled(Box)``;

Item.Text = styled(Text)`
	font-size: 13px;
	font-weight: normal;
	line-height: 1.38;
	color: #222b45;

	b {
		font-weight: bold;
	}
`;

Item.TimestampWrapper = styled(Text)`
	font-size: 10px;
	font-weight: 600;
	line-height: normal;
	color: #8f9bb3;
`;

Item.Text = styled(Text)`
	font-size: 13px;
	font-weight: normal;
	line-height: 1.38;
	letter-spacing: normal;
	color: #222b45;
`;

Item.PostDate = styled(TimeAgo)`
	font-size: 13px;
	line-height: 1.15;
	letter-spacing: -0.1px;
	color: rgba(0, 0, 0, 0.5);

	${props => props.theme.breakpoints.down("sm")} {
		display: block;

		margin-top: 3px;
	}
`;

export const StyledMuteIcon = styled(MuteIcon)`
	path {
		&:nth-child(2) {
			fill: #8f9bb3;
		}
	}
`;

export const Wrapper = styled(Box)`
	.notifications-preview-body-wrapper {
		margin: 0;
		padding: 0;
	}
`;

export const EmptyCard = styled(Box)`
	display: flex;
	flex-direction: column;
	align-items: center;

	padding: 25px 48px 36px 48px;
`;

EmptyCard.IconWrapper = styled(Box)`
	svg {
		width: 42px;
		height: 42px;

		path {
			&:last-child {
				fill: #e4e9f2;
			}
		}
	}
`;

EmptyCard.Title = styled(Text)`
	margin-top: 9px;

	font-size: 15px;
	font-weight: 600;
	line-height: 1.6;
	text-align: center;
	color: ${({ color }) => color};
`;

EmptyCard.Description = styled(Text)`
	margin-top: 8px;

	font-size: 13px;
	font-weight: normal;
	line-height: 1.38;
	text-align: center;
	color: #8f9bb3;
`;
