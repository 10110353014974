export const API_URLS = {
	INBOX: {
		GET_INBOX: "/user/conversations",
		SEARCH_INBOX: "/user/conversations/search",
		ARCHIVED_INBOX: "/user/conversations/archive"
	},
	MESSAGE: {
		CREATE: "/message/create"
	},
	CONVERSATION: {
		GET: "/user/recentConnections"
	},
	CONNECTION: {
		CREATE: "/persona/connection",
		SEARCH: "/persona/connections/search",
		PIN: "/user/conversation/pin",
		READ: "/user/conversation/read",
		ARCHIVE: "/user/conversation/archive",
		AUTO_CONNECT: "/connection/autoconnect"
	},
	GROUP: {
		GET_GROUP_CHAT_ID: "/groupchat/search",
		CREATE: "/groupchat",
		ADD_MEMBERS: "/groupchat/members",
		GET_GROUP_BY_CHAT_ID: (id: string) => `/groupchat?groupChatId=${id}`,
		DELETE: "/connection/chat/delete"
	}
};
export const INBOX_FILTERS = {
	UNREAD: "unread",
	RECENT: "recent",
	ALL: "all",
	GROUP: "group",
	FAVORITE: "favorite",
	PINNED: "pinned",
	ARCHIVED: "archived"
};
export const CHAT_STATUSES = {
	ACCEPTED: "accepted"
};
