import { Box } from "@material-ui/core";
import styled, { css } from "styled-components";

export const CategoryWrapper = styled(Box)`
	${({ active }) =>
		active
			? css`
					pointer-events: auto;
					transform: translateY(0);
					opacity: 1;
			  `
			: css`
					pointer-events: none;
					transform: translateY(-40px);
					opacity: 0;
			  `}
	position: absolute;
	top: calc(100% + 6px);
	left: 12px;
	width: 360px;
	border-radius: 12px;
	box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12);
	background-color: #fff;
	padding: 16px;
	z-index: 100;
	transition: 0.3s ease-in-out all;
	h4 {
		font-size: 22px;
		font-weight: bold;
		color: #222b45;
		margin-bottom: 4px;
	}
	p {
		font-size: 13px;
		font-weight: 600;
		color: #8f9bb3;
	}
	.icon-wrapper {
		padding: 12px;
		border-radius: 99%;
		background-color: ${({ communityColor }) => `${communityColor}1a` || "#eff2f5"};
		margin-right: 16px;
		flex-shrink: 0;
		svg {
			width: 32px;
			height: 32px;
			fill: ${({ communityColor }) => communityColor};
		}
	}
	button {
		width: 100%;
		border-radius: 8px;
		margin-top: 16px;
	}
`;
