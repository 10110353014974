import { Box, ButtonBase } from "@material-ui/core";
import styled from "styled-components";

import { Text } from "shared/ui-kit";

export const ChooseSkinToneWrapper = styled(Box)`
	margin-top: 4px;
	padding: 7px;

	display: flex;
	justify-content: flex-end;

	position: absolute;
	right: 0;
	top: 100%;

	z-index: 10;

	transform: translateY(-100%);

	opacity: 0;

	transition: 0.2s all linear;

	pointer-events: none;

	border: 1px solid rgba(197, 206, 224, 0.5);
	border-radius: 12px;

	box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12);

	background-color: #fff;

	&.show {
		transform: translateY(0);

		opacity: 1;

		pointer-events: auto;
	}
`;

export const ChooseSkinText = styled(Text)`
	font-size: 10px;
	font-weight: 600;
	color: #8f9bb3;
`;

export const ToneButton = styled(ButtonBase)`
	margin: 0 4px;
	padding: 2px;

	&:hover,
	&.active {
		border-radius: 6px;
		box-shadow: inset 0 0 0 1px #6173fe, 0 0 0 2px rgba(97, 115, 254, 0.24);
	}
`;

export const ToneOptionsWrapper = styled(Box)`
	display: flex;
	align-items: center;
	margin-bottom: 4px;
`;
