import React, { FC, useCallback, useEffect, useMemo } from "react";

import { StoryResponse } from "types/StoriesContextValuesType";

import { useStory } from "shared/hooks";

import { PersonStoryModel, StoryModel } from "shared/types";

import StoryBlock from "./StoryBlock";
import StoryHead from "./StoryHead";
import { StoryBox, StoryContent } from "./style";

export interface IStoryProps {
	activeStory?: boolean;
	data: StoryResponse;
}

interface Props {
	personStory: PersonStoryModel;
	isActive: boolean;
	onStartStory?: (story: StoryModel) => void;
	onEndStory?: () => void;
}

const Story: FC<Props> = ({ personStory, isActive, onStartStory, onEndStory }) => {
	const { getData: getStoryData } = useStory();
	const { currentStorySlide } = getStoryData();

	const userData = useMemo(
		() => ({
			id: personStory._id,
			name: personStory.firstName,
			profilePicture: personStory.photos.length ? personStory.photos[0].profilePicture : undefined,
			connectionId: personStory.connection?._id,
			personaId: personStory.personaId
		}),
		[personStory]
	);

	const handleSlideRight = useCallback(() => {
		onEndStory && onEndStory();
	}, [onEndStory]);

	useEffect(() => {
		onStartStory && onStartStory(personStory.stories[currentStorySlide]);
	}, [currentStorySlide, onStartStory, personStory]);

	return (
		<StoryBox isActive={isActive}>
			<StoryHead
				storiesCount={personStory.stories.length}
				isVideo={personStory.stories[currentStorySlide]?.type === "video"}
				userData={userData}
				isActive={isActive}
				handleMoveNext={handleSlideRight}
			/>
			<StoryContent>
				{personStory.stories.map((story, i) => (
					<StoryBlock
						currentStory={isActive ? currentStorySlide === i : i === 0}
						key={story._id}
						story={story}
						isActive={isActive}
						userData={userData}
					/>
				))}
			</StoryContent>
		</StoryBox>
	);
};

export default Story;
