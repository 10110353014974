import React, { useEffect, useState } from "react";

import Skeleton from "@material-ui/lab/Skeleton";

import { useHistory } from "react-router-dom";

import { TabPanel } from "shared/Components";

import { Post } from "shared/Organisms";

import { useMarketing } from "shared/hooks";
import { Button } from "shared/ui-kit";

import {
	ContentBlock,
	HeaderBlock,
	HeaderWrapper,
	JoinBtnWrapper,
	ManageBlock,
	PageWrapper,
	SkeletonWrapper,
	Tab,
	TabList
} from "./styles";

import { About, AdultPopup, Header, InfoBlock, JoinPopup, Subscription } from "../../Components";

enum TabTitle {
	POSTS = "Posts",
	SUBSCRIPTION = "Subscription",
	ABOUT = "About"
}

export interface ITab {
	title: TabTitle;
	activeIndex: number;
}

export interface MarketingProps {
	communityName: string;
	notFoundUrl: string;
	homeRoute: string;
	memberHomeUrl: string;
	loginUrl: string;
	marketingUrl: string;
	fillProfileUrl: string;
	communityListRoute: string;
	joinCommunitiesUrl: string;
	switchCommunity: boolean;
}

const Marketing: React.FC<MarketingProps> = React.memo(
	({
		communityName,
		notFoundUrl,
		memberHomeUrl,
		loginUrl,
		marketingUrl,
		fillProfileUrl,
		communityListRoute,
		joinCommunitiesUrl,
		switchCommunity
	}) => {
		const history = useHistory();
		const {
			getWorkspaceInfo,
			getWorkspacePosts,
			getWorkspaceStatistic,
			getWorkspaceSubscriptionPlans,
			setIsAdult,
			setShowJoinPopup,
			isFeatureEnabled,
			getData: getMarketingData
		} = useMarketing();
		const { workspace, posts, notFound, isAdult, monthlySubscription, annuallySubscription, loadingPosts } =
			getMarketingData();

		const [tabs, setTabs] = useState<ITab[]>([{ title: TabTitle.ABOUT, activeIndex: 1 }]);
		const [activeTabIndex, setActiveTabIndex] = useState(1);
		const [adultPopupOpen, setAdultPopupOpen] = useState(false);

		const closeAdultPopup = (adult: boolean) => {
			setAdultPopupOpen(false);
			setIsAdult(adult);
		};

		useEffect(() => {
			getWorkspaceInfo(communityName);
		}, [getWorkspaceInfo, communityName]);

		useEffect(() => {
			if (notFound) {
				history.push(notFoundUrl);
			}
		}, [notFound, history, notFoundUrl]);

		useEffect(() => {
			if (workspace) {
				getWorkspaceStatistic(communityName);
				getWorkspaceSubscriptionPlans(communityName);
			}
		}, [workspace, getWorkspaceStatistic, getWorkspaceSubscriptionPlans, communityName]);

		useEffect(() => {
			if ((annuallySubscription || monthlySubscription) && !tabs.some(x => x.title === TabTitle.SUBSCRIPTION)) {
				setTabs([...tabs, { title: TabTitle.SUBSCRIPTION, activeIndex: 2 }]);
			}
		}, [tabs, workspace, monthlySubscription, annuallySubscription]);

		useEffect(() => {
			if (
				workspace &&
				!workspace.isPrivate &&
				!tabs.some(x => x.title === TabTitle.POSTS) &&
				isFeatureEnabled("enableForum")
			) {
				tabs.unshift({ title: TabTitle.POSTS, activeIndex: 0 });
				getWorkspacePosts(communityName);
				setTabs([...tabs]);
				setActiveTabIndex(0);
			}
		}, [tabs, workspace, getWorkspacePosts, communityName, isFeatureEnabled]);

		useEffect(() => {
			if (workspace && !isAdult && workspace?.meta?.content18Plus) {
				setAdultPopupOpen(true);
			}
		}, [workspace, isAdult]);

		return (
			<>
				<PageWrapper blurred={adultPopupOpen}>
					<HeaderWrapper>
						<HeaderBlock>
							<Header
								title={workspace?.meta?.displayName ? workspace?.meta?.displayName : workspace?.name || ""}
								imgUrl={workspace?.meta?.img}
								coverImgUrl={workspace?.meta?.coverImage}
								subtitle={workspace?.meta?.types && workspace?.meta?.types[0]?.type}
								isPrivate={workspace?.isPrivate || false}
								homeUrl={memberHomeUrl}
								loginUrl={loginUrl}
								marketingUrl={marketingUrl}
								fillProfileUrl={fillProfileUrl}
								communityListRoute={communityListRoute}
								joinCommunitiesUrl={joinCommunitiesUrl}
								communityName={communityName}
								switchCommunity={switchCommunity}
							/>
						</HeaderBlock>
						<ManageBlock>
							<TabList allOptions={tabs.length === 3}>
								{tabs.map((tab, index) => (
									<Tab
										key={index}
										className={activeTabIndex === tab.activeIndex && "active"}
										onClick={() => setActiveTabIndex(tab.activeIndex)}
									>
										{tab.title}
									</Tab>
								))}
							</TabList>
						</ManageBlock>
					</HeaderWrapper>
					<ContentBlock>
						{isFeatureEnabled("enableForum") && (
							<TabPanel value={0} index={activeTabIndex}>
								{loadingPosts ? (
									<SkeletonWrapper>
										<div>
											<Skeleton variant="text" />
											<Skeleton variant="circle" width={40} height={40} />
										</div>
										<Skeleton variant="rect" height={118} />
									</SkeletonWrapper>
								) : (
									posts.map((post, index) => (
										<InfoBlock key={index} className="communityPage">
											<Post post={post} isMarketing />
										</InfoBlock>
									))
								)}
								{posts.length > 10 && (
									<JoinBtnWrapper>
										<Button onClick={() => setShowJoinPopup(true)}>Join for More</Button>
									</JoinBtnWrapper>
								)}
							</TabPanel>
						)}
						<TabPanel value={1} index={activeTabIndex}>
							<About />
						</TabPanel>
						<TabPanel value={2} index={activeTabIndex}>
							<Subscription />
						</TabPanel>
					</ContentBlock>
				</PageWrapper>
				<AdultPopup
					title={workspace?.name || ""}
					imgUrl={workspace?.meta?.img}
					open={adultPopupOpen}
					onClose={closeAdultPopup}
				/>
				<JoinPopup />
			</>
		);
	}
);

export default Marketing;
