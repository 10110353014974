export default (count: number, entityName: string): string => {
	switch (count) {
		case 0: {
			return `No ${entityName}s`;
		}
		case 1: {
			return `${count} ${entityName}`;
		}
		default: {
			return `${getCount(count)} ${entityName}s`;
		}
	}
};

const getCount = (count: number): number | string => {
	if (count > 1000 && count < 1000000) return `${count / 1000}k`;
	if (count > 1000000) return `${count / 1000000}M`;
	else return count;
};
