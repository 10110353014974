import styled from "styled-components";

import { Box, Button } from "shared/ui-kit";

export const SeeAllBtnWrapper = styled(Box)`
	padding: 0 8px;
`;

export const Wrapper = styled(Box)`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 12px;
	button {
		border-radius: 8px;
	}
`;

export const StyledButton = styled(Button)`
	margin: 0;

	border-radius: 8px;

	background-color: #edf1f7;

	color: #222b45;

	box-shadow: none;
`;
