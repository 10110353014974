import React from "react";

import { useLocation } from "react-router";

import { routes } from "apps/Auth/constants";
import { routes as regularUserRoutes } from "apps/RegularUser/constants";
import { view } from "modules/App";

const CreatePasswordPage = () => {
	const { search } = useLocation();
	const query = new URLSearchParams(search);
	const next = query.get("next");

	return (
		<view.CreatePassword
			dashboardRoute={next === "dashboard" && regularUserRoutes.dashboard.getPath()}
			communitiesListPage={routes.auth.communities.getPath()}
			signInLink={routes.auth.signIn.getPath()}
		/>
	);
};

export default CreatePasswordPage;
