import * as R from "ramda";
import { AutoPersonaModel, ForumPersonaModel } from "types";

import { TeamMembersModel } from "modules/Team/Data/types";

export const convertUserToAutomated = (users: (ForumPersonaModel | TeamMembersModel)[]): AutoPersonaModel[] => {
	return users
		.map((user, i) => ({
			...R.pick(["personaId", "firstName", "lastName"], user),
			profilePicture: user.photos?.length ? user.photos[0]["profilePicture"] : "",
			id: i + 1
		}))
		.filter(x => x.firstName);
};
