import React from "react";

import styled from "styled-components";

const Li = styled.li`
	display: list-item;
	flex-direction: row;
	list-style-type: circle;
`;
const Ul = styled.ul`
	padding-left: 15px;
`;
const Bullet = ({ text = "", customStyle = () => ({}) }) => (
	<Ul>
		<Li customStyle={customStyle}>{text}</Li>
	</Ul>
);

export default Bullet;
