import { Box } from "@material-ui/core";
import styled, { css } from "styled-components";

export const ModalOverlay = styled(Box)`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	background-color: rgba(25, 36, 53, 0.1);
	z-index: 1400;
	.MuiDialogContent-root {
		padding: 0;
	}
	.MuiDialog-paperWidthSm {
		max-width: 520px;
	}
	.MuiDialog-scrollPaper {
		height: 100vh;
	}
	${props => props.theme.breakpoints.down("sm")} {
		.MuiDialog-paperWidthSm {
			margin: 0;
			width: calc(100% - 32px);
		}
	}
`;

export const SubText = styled.span`
	color: #8f9bb3;
	font-weight: 600;
`;

export const ModalBody = styled(Box)`
	padding: 16px 20px;
	${props => props.theme.breakpoints.up("sm")} {
		padding: 32px 40px;
	}
	${({ smallPadding }) =>
		smallPadding &&
		css`
			padding-top: 12px !important;
		`}
`;

export const UploaderWrapper = styled(Box)`
	position: relative;
	margin-top: 6px;
	margin-bottom: 20px;
	.explorer-uploader .description .anchor {
		color: #6173fe;
	}
	.uploader-wrapper {
		flex: 1;
	}
	.explorer-uploader-label-text {
		font-size: 16px;
	}
`;

export const InputWrapper = styled(Box)`
	margin-top: 6px;
	margin-bottom: 20px;
	${({ noLabel }) =>
		noLabel &&
		css`
			.MuiIconButton-label {
				display: none;
			}
		`}
`;

export const FeatureBlock = styled(Box)`
	.head {
		padding-top: 16px;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.input {
		padding: 8px 0 16px;
		&.multiple > div:first-child {
			margin-bottom: 6px;
		}
		${props => props.theme.breakpoints.up("sm")} {
			&.multiple {
				display: flex;
				> div:first-child {
					margin-bottom: 0;
					width: 65%;
					margin-right: 12px;
				}
				> div:last-child {
					width: 35%;
				}
			}
		}
	}
	${({ noBorder }) =>
		!noBorder &&
		css`
			border-bottom: 1px solid #e4e9f2;
		`}
	.left-side {
		display: flex;
		svg {
			fill: #8f9bb3;
		}
		h6 {
			margin-left: 8px;
		}
		p {
			padding: 0 10px;
			color: #8f9bb3;
		}
	}
`;
