import React, { useCallback, useMemo } from "react";

import CollectionBlock from "shared/Components/Blocks/CollectionBlock";
import EventBlock from "shared/Components/Blocks/EventBlock";
import FileBlock from "shared/Components/Blocks/FileBlock";
import GroupBlock from "shared/Components/Blocks/GroupBlock";
import ImageVideoBlock from "shared/Components/Blocks/ImageVideoBlock/ImageVideoBlock";
import ImgBlock from "shared/Components/Blocks/ImgBlock";
import LinkAnchorBlock from "shared/Components/Blocks/LinkAnchorBlock";
import PollBlock from "shared/Components/Blocks/PollBlock";
import RequestMoneyBlock from "shared/Components/Blocks/RequestMoneyBlock";
import ShowBlock from "shared/Components/Blocks/ShowBlock";
import TextBlock from "shared/Components/Blocks/TextBlock";
import TrackBlock from "shared/Components/Blocks/TrackBlock";
import VideoBlock from "shared/Components/Blocks/VideoBlock";
import YouTubeBlock from "shared/Components/Blocks/YouTubeBLock";

import useMemberRoutes from "./useMemberRoutes";

import { PostBlockEventType } from "../../types";
import useUserService from "../services/useUserApiService";

const useSmartBlock = (isMarketing = false) => {
	const { getMemberRoutesData } = useMemberRoutes();
	const { routes } = getMemberRoutesData();
	const userService = useUserService(isMarketing);

	const trackLink = useMemo(() => routes?.member.track.getPath(), [routes?.member.track]);
	const eventLink = useMemo(() => routes?.member.event.getPath(), [routes?.member.event]);
	const groupLink = useMemo(() => routes?.member.group.getPath(), [routes?.member.group]);
	const fileLink = useMemo(() => routes?.member.file.getPath(), [routes?.member.file]);
	const collectionLink = useMemo(() => routes?.member.collection.getPath(), [routes?.member.collection]);

	const getDataAttributeName = elementName => {
		switch (elementName) {
			case "audio":
				return "data-track";
			case "youtube":
				return "data-video";
			case "collage":
				return "data-images";
			case "text":
				return "data-value";
			default:
				return `data-${elementName}`;
		}
	};

	const getBlock = useCallback(
		(type, data, defaultValue, onEvent?: (eventType: PostBlockEventType, id: string, args?: any) => void) => {
			switch (type) {
				case "photo":
					// {image, imageId}
					return <ImgBlock src={data.image} id={data?.elementId} />;
				case "collage":
					return (
						<ImageVideoBlock
							fileList={data.images}
							editorRef={data?.editorRef}
							id={data?.elementId}
							onEvent={onEvent}
						/>
					);
				case "video":
					return <VideoBlock {...data} onEvent={onEvent} />;
				case "youtube":
					return (
						<YouTubeBlock
							url={`https://www.youtube.com/watch?v=${data.videoId}`}
							playableObjectId={data.playableObjectId}
							id={data?.elementId}
							onEvent={onEvent}
						/>
					);
				case "audio":
				case "track":
					return (
						<TrackBlock
							link={`${trackLink}/${data.id}`}
							track={data}
							trackId={data.id}
							playableObjectId={data.playableObjectId}
							nextTrackId={data.nextTrackId}
							onEvent={onEvent}
							id={data?.elementId}
							postView
							isMarketing={isMarketing}
						/>
					);
				case "poll":
					return <PollBlock poll={data} id={data?.elementId} onEvent={onEvent} isMarketing={isMarketing} />;
				case "event":
					return (
						<EventBlock
							link={`${eventLink}/${data.eventId}`}
							event={data}
							eventId={data.eventId}
							id={data?.elementId}
							onEvent={onEvent}
							isMarketing={isMarketing}
						/>
					);
				case "group":
					return (
						<GroupBlock
							link={`${groupLink}/${data.slug}`}
							group={data}
							groupSlug={data.slug}
							id={data?.elementId}
							onEvent={onEvent}
							postView
							isMarketing={isMarketing}
						/>
					);
				case "payreq":
					return <RequestMoneyBlock payReq={data} id={data?.elementId} onEvent={onEvent} postView />;
				case "text":
					return <TextBlock content={data} />;
				case "link":
				case "linkpreview":
					return (
						<LinkAnchorBlock url={data?.link || ""} id={data?.elementId} onEvent={onEvent} isMarketing={isMarketing} />
					);
				case "show":
					return <ShowBlock data={data} />;
				case "file":
					return (
						<FileBlock
							link={`${fileLink}/${data?.fileId || data?._id}`}
							file={data}
							fileId={data?.fileId || data?._id}
							id={data?.elementId}
							onEvent={onEvent}
							isMarketing={isMarketing}
							postView
						/>
					);
				case "collection":
					return (
						<CollectionBlock
							link={`${collectionLink}/${data?.fileId || data?._id}`}
							collection={data}
							collectionId={data?.fileId || data?._id}
							id={data?.elementId}
							onEvent={onEvent}
							isMarketing={isMarketing}
							postView
						/>
					);

				default:
					return defaultValue;
			}
		},
		[trackLink, groupLink, eventLink, fileLink, collectionLink, isMarketing]
	);

	const resetTextBlockValues = useCallback(() => {
		const textBlockElements: Element[] = [];
		const wrapper = document.createElement("div");
		const dataCell = document.createElement("span");
		const dataCellChildWrapper = document.createElement("div");
		dataCell.setAttribute("data-name", "text");
		dataCell.setAttribute("data-value", "");
		dataCell.appendChild(dataCellChildWrapper);
		wrapper.appendChild(dataCell);

		return {
			textBlockElements,
			wrapper,
			dataCellChildWrapper,
			dataCell
		};
	}, []);

	const improveMentionBlock = useCallback((item: Element): string => {
		if (item.children?.length && item.innerHTML.includes('class="mention"')) {
			Array.from(item.children).forEach(ch => {
				if (ch.tagName === "SPAN" && ch.classList.contains("mention")) {
					item.replaceChild(ch.children[0].children[1], ch);
				}
			});
		}
		return item.innerHTML;
	}, []);

	const getTextBlock = useCallback(
		({
			updatedDOM,
			textBlockElements,
			wrapper,
			dataCellChildWrapper,
			dataCell
		}: {
			updatedDOM: HTMLBodyElement;
			textBlockElements: Element[];
			wrapper: HTMLDivElement;
			dataCellChildWrapper: HTMLDivElement;
			dataCell: HTMLSpanElement;
		}) => {
			let dataValueAttr = "";
			textBlockElements.forEach(item => {
				const correctInnerHTML = improveMentionBlock(item);
				dataValueAttr += `<div>${correctInnerHTML}</div>`;
				const div = document.createElement("div");
				div.innerHTML = correctInnerHTML;
				dataCellChildWrapper.appendChild(div);
			});
			dataCell.setAttribute("data-value", encodeURIComponent(dataValueAttr));
			textBlockElements.forEach((item, i) => {
				if (i) {
					updatedDOM.removeChild(item);
				} else {
					updatedDOM.replaceChild(wrapper, item);
				}
			});
			return {
				...resetTextBlockValues(),
				updatedDOM
			};
		},
		[resetTextBlockValues, improveMentionBlock]
	);

	return useMemo(
		() => ({
			getDataAttributeName,
			grabUrlInfo: async (urL: string) => await userService.getWeblinkPreview(urL),
			encodeEditorContent: (htmlContent: string) => {
				const content = new DOMParser().parseFromString(htmlContent, "text/html");

				const blocks = content.querySelectorAll("div[data-name]");

				blocks.forEach(b => {
					const { attributes, parentNode } = b;
					const elementName = attributes["data-name"].value;
					const dataAttributeName = getDataAttributeName(elementName);

					const wrapper = document.createElement("div");
					const dataCell = document.createElement(elementName === "gif" ? "img" : "span");
					if (elementName === "gif") {
						const val = JSON.parse(attributes[dataAttributeName].value);
						dataCell.setAttribute("src", val.src);
						dataCell.setAttribute("alt", val.alt);
					} else {
						dataCell.setAttribute("data-name", elementName);
						dataCell.setAttribute(dataAttributeName, attributes[dataAttributeName].value);
					}
					wrapper.innerHTML = dataCell.outerHTML;

					if (
						parentNode?.parentNode &&
						(parentNode.parentNode as any)?.classList?.contains("embed-innerApp") &&
						parentNode.parentNode.parentNode
					) {
						parentNode.parentNode.parentNode.replaceChild(wrapper, parentNode.parentNode);
					} else {
						parentNode!.replaceChild(wrapper, b);
					}
				});

				const updatedDOM = content.documentElement.getElementsByTagName("body")[0];

				let blockData = {
					...resetTextBlockValues(),
					updatedDOM
				};

				Array.from(updatedDOM?.children).forEach(ch => {
					if (ch.tagName === "P" || ch.getElementsByTagName("P")?.length) {
						let correctCh = ch;
						if (ch.tagName !== "P") {
							// detect inner content
							const els = ch.getElementsByTagName("P");
							const arrEls = Array.from(els).filter(x => x.innerHTML?.length);
							if (arrEls.length) {
								correctCh = arrEls[0];
								updatedDOM?.replaceChild(correctCh, ch);
							}
						}
						if (correctCh.children.length && blockData.textBlockElements.length) {
							blockData = getTextBlock(blockData);
						}
						blockData.textBlockElements.push(correctCh);
						if (correctCh.children.length) {
							blockData = getTextBlock(blockData);
						}
					} else if (blockData.textBlockElements.length) {
						blockData = getTextBlock(blockData);
					}
				});

				if (blockData.textBlockElements.length) {
					blockData = getTextBlock(blockData);
				}

				return blockData.updatedDOM.innerHTML;
			},
			encodeComment: (htmlContent: string) => {
				const content = new DOMParser().parseFromString(htmlContent, "text/html");

				const blocks = content.querySelectorAll("div[data-name]");
				blocks.forEach(b => {
					const { attributes, parentNode } = b;
					const elementName = attributes["data-name"].value;

					if (elementName === "attachment-wrapper") {
						const imgs = b.getElementsByTagName("img");
						if (imgs?.length) {
							parentNode!.parentNode!.replaceChild(imgs[0], parentNode!);
						}

						const videos = b.getElementsByTagName("video");
						if (videos?.length) {
							parentNode!.parentNode!.replaceChild(videos[0], parentNode!);
						}
					}
				});

				const links = content.getElementsByTagName("a");
				for (let i = 0; i < links.length; i++) {
					const link = links[i];
					const { attributes, parentNode } = link;

					const personaId = attributes["persona-id"]?.value;
					if (personaId) {
						const newLink = document.createElement("a");
						newLink.setAttribute("class", "author");
						newLink.setAttribute("href", `{'personaId':${personaId}}`);
						newLink.innerHTML = link.innerHTML;
						parentNode!.replaceChild(newLink, link);
					}
				}

				const updatedDOM = content.documentElement.getElementsByTagName("body")[0];

				return updatedDOM.outerHTML.replace("</body>", "</div>").replace("<body>", "<div>");
			},
			getBlock
		}),
		[getBlock, resetTextBlockValues, getTextBlock, userService]
	);
};

export default useSmartBlock;
