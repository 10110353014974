import React, { Fragment, memo, useCallback, useEffect, useMemo, useState } from "react";

import { Box } from "@material-ui/core";
import styled from "styled-components";

import { PostBlockEventType } from "types";

import { ReactComponent as PostPlayIcon } from "assets/icons/post-play.svg";
import Close from "shared/Components/Blocks/Close";
import { useCommunity } from "shared/hooks";
import { useS3Uploader } from "shared/services/s3Uploader/hooks";
import { UploadedImageModel } from "shared/types";
import theme from "theme/default";

import { getFileFromVideoUrl } from "utils/getFileFromVideoUrl";
import { getVideoImage } from "utils/getVideoImage";
import { convertToNumberFormat, toBase64 } from "utils/serviceUtils/helpers";

import {
	CircularProgressWrapper,
	ImageCarouselWrapper,
	OverlayBox,
	OverlayText,
	OverlayTextBox,
	UploaderCircularWrapper,
	UploaderWrapper
} from "./style";

import ImageSlider from "../ImageSlider";

export enum ItemTypes {
	image = "image",
	video = "video"
}

export interface localItemType {
	url: string;
	type: ItemTypes;
	refId?: string;
}

export const Image = styled(props => <Box {...props} />)<{ uri: string }>`
	background-image: url("${props => props.uri}");
	background-size: cover;
	background-position: center;
	cursor: pointer;
`;

const Overlay = ({ overlayText, children, styles }) =>
	overlayText ? (
		<OverlayBox className="h-full">
			{children}
			<OverlayTextBox style={styles.overlayContainer} color={theme.palette.colors.basic}>
				<OverlayText style={styles.overlayText}>{overlayText}</OverlayText>
			</OverlayTextBox>
		</OverlayBox>
	) : (
		children
	);

interface UploadImageProps {
	id: string | null;
	localItem: localItemType;
	uploading?: boolean;
	style?: {
		height: number | string;
		width: number | string;
	};
}

const UploadingImage: React.FC<UploadImageProps> = ({ localItem, style, uploading }) => {
	const [coverImage, setCoverImage] = useState("");
	const getVideoCover = useCallback(async (url: string) => {
		let base64 = "";
		try {
			const file = await getFileFromVideoUrl(url);
			const cover = await getVideoImage(file, 1);
			base64 = (await toBase64(cover as File)) as string;
			setCoverImage(base64);
		} catch (err) {}
	}, []);

	useEffect(() => {
		localItem.type === ItemTypes.video && getVideoCover(localItem.url);
	}, [getVideoCover, localItem.url, localItem.type]);

	return (
		<UploaderWrapper style={style} data-refId={localItem.refId}>
			{localItem.type === ItemTypes.video && (
				<div className="relative flex justify-center w-full h-full">
					<video className="object-cover" src={localItem.url} style={style} poster={coverImage} />
					{!uploading && (
						<div className="flex absolute items-center cursor-pointer justify-center w-full h-full">
							<PostPlayIcon width={80} height={80} />
						</div>
					)}
				</div>
			)}
			{localItem.type === ItemTypes.image && <Image uri={localItem.url} style={style} />}
			{uploading && (
				<UploaderCircularWrapper>
					<CircularProgressWrapper size={40} thickness={2} value={50} />
				</UploaderCircularWrapper>
			)}
		</UploaderWrapper>
	);
};

const Wrapper = styled(Box)`
	display: flex;
	flex-direction: column;

	background: #fff;

	overflow: hidden;
`;

const maxComponentWidth = 660;

interface Props {
	fileList: FileList | string[];
	editorRef?: React.Ref<any>;
	id?: string;
	onEvent?: (eventType: PostBlockEventType, id: string, refId?: string) => void;
	memberView?: boolean;
	timeStamp?: string;
}

const ImageVideoBlock: React.FC<Props> = memo(({ fileList, editorRef, id, onEvent, memberView, timeStamp }) => {
	const [uploadedImages, setUploadedImages] = useState<UploadedImageModel[]>([]);
	const [localFileList, setLocalFileList] = useState<localItemType[]>([]);
	const [uploadingFiles, setUploadingFiles] = useState(false);
	const [editorWidth, setEditorWidth] = useState<number>();
	const [sliderOptions, setSliderOptions] = useState<{ open: boolean; activeIndex: number }>({
		open: false,
		activeIndex: 0
	});
	const { uploadFile } = useS3Uploader();
	const { getData: getCommunityData } = useCommunity();
	const { workspace } = getCommunityData();

	const styles = useMemo(() => {
		let collageWidth = editorWidth || maxComponentWidth;
		if (collageWidth > maxComponentWidth) {
			collageWidth = maxComponentWidth;
		}
		const collageHeight = collageWidth * 0.6;
		const topRowHeight = collageHeight * 0.77;

		const myStyles: { [key in string]: React.CSSProperties } = {
			collage: {
				width: "100%"
			},
			separatorOne: {
				width: 1,
				height: 1
			},
			separatorTwo: {
				width: 2,
				height: 2
			},
			// 1 image
			oneImage: {
				width: "100%",
				height: collageHeight
			},
			// 2 images
			twoImagesTopImage: {
				width: "100%",
				height: 186
			},
			twoImagesBottomImage: {
				width: "100%",
				height: 187
			},
			// 3 images
			threeImagesTopImage: {
				width: "100%",
				height: 186
			},
			threeImagesBottomContainer: {
				width: "100%",
				height: 187,
				flexDirection: "row",
				justifyContent: "space-between",
				alignItems: "stretch"
			},
			threeImagesBottomImage: {
				height: 187,
				width: "49.9%"
			},
			// 4 images
			fourImagesTopImage: {
				width: "100%",
				height: topRowHeight
			},
			fourImagesBottomContainer: {
				width: "100%",
				height: collageHeight - topRowHeight,
				flexDirection: "row",
				justifyContent: "space-between",
				alignItems: "stretch"
			},
			fourImagesBottomImage: {
				width: "33%",
				height: collageHeight - topRowHeight
			},
			// 5 images
			fiveImagesTopContainer: {
				width: "100%",
				height: topRowHeight,
				flexDirection: "row",
				justifyContent: "space-between",
				alignItems: "stretch"
			},
			fiveImagesTopImage: {
				width: "49.9%",
				height: topRowHeight
			},
			fiveImagesBottomContainer: {
				width: "100%",
				height: collageHeight - topRowHeight,
				flexDirection: "row",
				justifyContent: "space-between",
				alignItems: "stretch"
			},
			fiveImagesBottomImage: {
				width: "33%",
				height: collageHeight - topRowHeight
			}
		};
		return myStyles;
	}, [editorWidth]);

	useEffect(() => {
		const resize = () => {
			if (editorRef) {
				const editorEl = editorRef as any;
				const fullWidth = editorEl.clientWidth;
				let elStyles;
				try {
					elStyles = getComputedStyle(editorEl);
				} catch {
					elStyles = {
						paddingLift: "0",
						paddingRight: "0"
					};
				}
				setEditorWidth(
					fullWidth -
						Number(convertToNumberFormat(elStyles.paddingLeft || "0")) -
						Number(convertToNumberFormat(elStyles.paddingRight || "0"))
				);
			}
		};
		resize();

		window.addEventListener("resize", resize);

		return () => window.removeEventListener("resize", resize);
	}, [editorRef]);

	useEffect(() => {
		if (uploadedImages.length) {
			setUploadingFiles(false);
		}
	}, [uploadedImages]);

	useEffect(() => {
		if (uploadingFiles) {
			onEvent && onEvent(PostBlockEventType.uploadingProgressStart, `${id}`);
		} else {
			onEvent && onEvent(PostBlockEventType.uploadingProgressFinished, `${id}`);
		}
	}, [uploadingFiles, id, onEvent]);

	useEffect(() => {
		if (fileList.length) {
			if (fileList instanceof FileList) {
				setLocalFileList(
					Array.from(fileList).map(x => {
						return {
							url: window.URL.createObjectURL(x),
							type: x.type.includes("video") ? ItemTypes.video : ItemTypes.image,
							refId: x.name + timeStamp || ""
						};
					})
				);
			} else {
				setLocalFileList(
					fileList.slice(0).map(x => {
						return {
							url: x,
							type: x.endsWith(".mp4") || x.endsWith("_mp4") ? ItemTypes.video : ItemTypes.image
						};
					})
				);
			}
		}
	}, [fileList, timeStamp]);

	useEffect(() => {
		if (fileList instanceof FileList) {
			setUploadingFiles(true);

			(async () => {
				const res = [] as UploadedImageModel[];

				await Promise.all(
					Object.values(fileList).map(async (v, index) => {
						const refId = v.name + timeStamp || "";

						const data = await uploadFile({
							file: v,
							communityName: `${workspace?.name}`,
							refId
						});
						if (!data) return;
						res.push({ ...data, originalIndex: index, refId });
					})
				);
				if (res.length) {
					setUploadedImages(res);
				}
			})();
		}
	}, [fileList, workspace, uploadFile, timeStamp]);

	const renderImage = useCallback(
		(localItem: localItemType, index, style, overlayText?) => {
			const overlayStyle = { width: "100%", height: "100%" };
			return (
				<Box
					style={style}
					onClick={() => {
						setSliderOptions({ open: true, activeIndex: index });
					}}
				>
					<Overlay overlayText={overlayText} styles={overlayStyle}>
						<UploadingImage id={index} localItem={localItem} uploading={uploadingFiles} style={overlayStyle} />
					</Overlay>
					{id && (
						<Close
							onClick={() => onEvent && onEvent(PostBlockEventType.delete, id, localItem.refId)}
							viewMode={memberView}
						/>
					)}
				</Box>
			);
		},
		[uploadingFiles, memberView, id, onEvent]
	);

	const imagesAttr = useMemo(() => {
		let imgSrcList: string[] = [];
		if (fileList instanceof FileList) {
			imgSrcList = uploadedImages.map(x => x?.publicUrl);
		} else {
			imgSrcList = fileList.slice(0);
		}

		return encodeURIComponent(JSON.stringify({ images: imgSrcList }));
	}, [uploadedImages, fileList]);

	return (
		<Fragment>
			<ImageSlider
				open={sliderOptions.open}
				defaultIndex={sliderOptions.activeIndex}
				fileList={localFileList}
				onClose={open => setSliderOptions({ open, activeIndex: 0 })}
				highzIndex={true}
			/>
			<ImageCarouselWrapper
				data-name="collage"
				data-images={imagesAttr}
				collageWidth={styles.collage.width}
				maxCollageWidth={maxComponentWidth}
				style={{ width: `${editorWidth || maxComponentWidth}px` }}
				className="rounded"
			>
				{localFileList.length === 1 && <Wrapper>{renderImage(localFileList[0], 0, styles.oneImage)}</Wrapper>}
				{localFileList.length === 2 && (
					<Wrapper>
						{renderImage(localFileList[0], 0, styles.twoImagesTopImage)}
						<Box style={styles.separatorTwo} />
						{renderImage(localFileList[1], 1, styles.twoImagesBottomImage)}
					</Wrapper>
				)}
				{localFileList.length === 3 && (
					<Wrapper>
						{renderImage(localFileList[0], 0, styles.threeImagesTopImage)}
						<Box style={styles.separatorOne} />
						<Wrapper style={styles.threeImagesBottomContainer}>
							{renderImage(localFileList[1], 1, styles.threeImagesBottomImage)}
							<Box style={styles.separatorOne} />
							{renderImage(localFileList[2], 2, styles.threeImagesBottomImage)}
						</Wrapper>
					</Wrapper>
				)}
				{localFileList.length === 4 && (
					<Wrapper>
						{renderImage(localFileList[0], 0, styles.fourImagesTopImage)}
						<Box style={styles.separatorOne} />
						<Wrapper style={styles.fourImagesBottomContainer}>
							{renderImage(localFileList[1], 1, styles.fourImagesBottomImage)}
							<Box style={styles.separatorOne} />
							{renderImage(localFileList[2], 2, styles.fourImagesBottomImage)}
							<Box style={styles.separatorOne} />
							{renderImage(localFileList[3], 3, styles.fourImagesBottomImage)}
						</Wrapper>
					</Wrapper>
				)}
				{localFileList.length > 4 && (
					<Wrapper>
						<Wrapper style={styles.fiveImagesTopContainer}>
							{renderImage(localFileList[0], 0, styles.fiveImagesTopImage)}
							<Box style={styles.separatorOne} />
							{renderImage(localFileList[1], 1, styles.fiveImagesTopImage)}
						</Wrapper>
						<Box style={styles.separatorOne} />
						<Wrapper style={styles.fiveImagesBottomContainer}>
							{renderImage(localFileList[2], 2, styles.fiveImagesBottomImage)}
							<Box style={styles.separatorOne} />
							{renderImage(localFileList[3], 3, styles.fiveImagesBottomImage)}
							<Box style={styles.separatorOne} />
							{renderImage(
								localFileList[4],
								4,
								styles.fiveImagesBottomImage,
								localFileList?.length > 5 ? `+${localFileList?.length - 5}` : ""
							)}
						</Wrapper>
					</Wrapper>
				)}
			</ImageCarouselWrapper>
		</Fragment>
	);
});

export default ImageVideoBlock;
