import React, { useEffect, useState } from "react";

import styled from "styled-components";

import { ReactComponent as CheckIcon } from "assets/icons/iconTick.svg";
import { Box, Text } from "shared/ui-kit";

const Wrapper = styled(Box)<{ show: boolean }>`
	opacity: ${({ show }) => (show ? 1 : 0)};

	transition: opacity 0.25s ease-in-out;
`;

const ProgressWrapper = styled(Box)<{ progress: number }>`
	width: 100%;
	height: 4px;

	margin: 0;
	padding: 0;

	border-radius: 2px;
	background-color: #f7f9fc;

	position: relative;

	&:after {
		content: "";

		position: absolute;
		left: 0;

		height: 100%;
		width: ${({ progress }) => progress}%;

		border-radius: 2px;

		background: #6173fe;

		transition: width 0.25s ease-in-out;
	}
`;

const SuccessMessageWrapper = styled(Box)`
	width: 100%;
	min-height: 32px;

	display: flex;
	justify-content: center;
	align-items: center;

	background-color: #6173fe;
`;

const MessageText = styled(Text)`
	font-size: 13px;
	font-weight: 600;
	line-height: 1.85;
	color: #ffffff;
`;

const CheckIconWrapper = styled(CheckIcon)`
	margin-left: 10px;

	path {
		fill: white;
	}
`;

interface Props {
	progress: number;
	successText?: string;
	successTimeout?: number;
}

const UploadProgressBar: React.FC<Props> = ({ progress, successText, successTimeout }) => {
	const [show, setShow] = useState(false);

	useEffect(() => {
		if (progress >= 1) {
			setShow(true);
		}

		if (progress === 100) {
			setTimeout(() => {
				setShow(false);
			}, successTimeout || 3000);
		}
	}, [successTimeout, progress]);

	return (
		<Wrapper show={show}>
			{progress < 100 ? (
				<ProgressWrapper progress={progress} />
			) : (
				<SuccessMessageWrapper>
					<MessageText>{successText}</MessageText>
					<CheckIconWrapper />
				</SuccessMessageWrapper>
			)}
		</Wrapper>
	);
};

export default UploadProgressBar;
