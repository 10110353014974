import React, { useCallback, useState } from "react";

import { useMoney, useUser } from "shared/hooks";
import { useS3Uploader } from "shared/services/s3Uploader";
import { getScheduleDate } from "utils/getScheduleDate";
import { dataUrlToFile, mergeDateAndTime } from "utils/serviceUtils/helpers";

import RequestMoneyManageView from "./ManageView";
import RequestMoneyMemberView from "./MemberView";

interface Props {
	onClose: () => void;
	onSelect: (request: any) => void;
	open: boolean;
}

const RequestMoney: React.FC<Props> = ({ onClose, onSelect, open }) => {
	const { createRequest } = useMoney();

	const { getData: getUserData } = useUser();
	const { isMemberView } = getUserData();

	const { uploadFile } = useS3Uploader();
	const { moneyImageUploadUrl } = useMoney();

	const onUploaderChange = useCallback(
		async (files: FileList | any[], onChange: (...event: any[]) => void) => {
			const img = files && files.length ? files[0] : null;
			const correctFile = typeof img === "string" ? await dataUrlToFile(img, "test") : img;
			const data = await uploadFile({
				file: correctFile as File,
				communityName: moneyImageUploadUrl
			});
			onChange(data?.publicUrl);
		},
		[moneyImageUploadUrl, uploadFile]
	);

	const [loading, setLoading] = useState(false);

	const onSubmit = useCallback(
		async data => {
			const dueDate = isMemberView
				? getScheduleDate(data.dueTime?.value, data.dueDate) || undefined
				: mergeDateAndTime(data.dueDate, data.dueTime);

			setLoading(true);
			const res = await createRequest({ ...data, dueDate });
			setLoading(false);
			onSelect(res);
		},
		[createRequest, isMemberView, onSelect]
	);

	return isMemberView ? (
		<RequestMoneyMemberView
			open={open}
			onClose={onClose}
			loading={loading}
			onSubmit={onSubmit}
			onUploaderChange={onUploaderChange}
		/>
	) : (
		<RequestMoneyManageView
			open={open}
			onClose={onClose}
			loading={loading}
			onSubmit={onSubmit}
			onUploaderChange={onUploaderChange}
		/>
	);
};

export default RequestMoney;
