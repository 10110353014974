import { Quill } from "react-quill";

const BlockEmbed = Quill.import("blots/block/embed");
class AppPanelEmbed extends BlockEmbed {
	static create(value) {
		const node = super.create(value);
		node.setAttribute("contenteditable", "false");
		node.setAttribute("width", "100%");
		node.innerHTML = this.transformValue(value);
		return node;
	}

	static transformValue(value) {
		let handleArr = value.split("\n");
		handleArr = handleArr.map(e => e.replace(/^[\s]+/, "").replace(/[\s]+$/, ""));
		return handleArr.join("");
	}

	static value(node) {
		return node.innerHTML;
	}
}
AppPanelEmbed.blotName = "AppPanelEmbed";
AppPanelEmbed.className = "embed-innerApp";
AppPanelEmbed.tagName = "div";

export default AppPanelEmbed;
