import { useCallback, useMemo } from "react";

import config from "config/appConfig";
import { useHistory } from "react-router";

import { routes as authRoutes } from "apps/Auth/constants";

import { routesGenerator } from "apps/Member/constants";
import { useGTM, useNotification, useUser } from "shared/hooks";
import { IWorkspace, PhoneRequest, PhoneVerifyRequest, UserModel, UserStatus } from "shared/types";

import { EnvApplicationNames } from "../../../../types";
import { useAuthStore } from "../contexts";

import { useAuthApiService } from "../services";
import { VerifyType } from "../types";

const useAuth = (isMarketing = false) => {
	const authService = useAuthApiService(isMarketing);

	const { addEvent } = useGTM();
	const { setGlobalUserId, getData: getUserData, setUser } = useUser();
	const { globalUserId: userGlobalUId } = getUserData();
	const { showMessage } = useNotification();

	const history = useHistory();

	const { GLOBAL_CONSTANTS } = config;

	const store = useAuthStore();
	const { setState } = useAuthStore();

	const subscribe = useMemo(() => {
		return store.subscribe;
	}, [store]);

	const methods = useMemo(
		() => ({
			getUser: async () => {
				return await authService.getUser<UserModel>();
			},
			updateUser: async () => {
				try {
					const user = await methods.getUser();
					user && setUser(user);
				} catch (error) {
					console.error("Can’t fetch user data");
				}
			},
			getVerificationLink: async (email: string) => {
				addEvent({ Action: "Create Community", Label: "Verification Sent" });
				await authService.getVerificationLink(email);
			},
			verify: async (
				email: string,
				code: string | null,
				password?: string,
				receiveNews = false
			): Promise<VerifyType | UserModel> => {
				try {
					const data = await authService.verify(email, code, receiveNews, password);
					await authService.logIn(data.token, "");
					setState(ctx => {
						ctx.email = email;
					});
					return data;
				} catch (error) {
					const errorMessage = (error as Error).message;
					console.log(errorMessage);
					return {
						success: false,
						workspaces: [],
						token: "",
						globalUserId: ""
					};
				}
			},
			updateEmail: async (email, userId) => {
				try {
					return await authService.updateEmail(email, userId);
				} catch (err) {
					showMessage((err as Error).message, 3, true);
					return { success: false };
				}
			},
			resetUserPassword: async (password: string, token: string) => {
				methods.setIsLoading(true);
				addEvent({ Category: "User Password", Action: "Reset Password", Label: "Reset Password Success" });
				const status = await authService.resetUserPassword(password, token);
				methods.setIsLoading(false);
				return status;
			},
			resetWhitelabelUserPassword: async (password: string, token: string, email: string, community: string) => {
				methods.setIsLoading(true);
				addEvent({ Category: "User Password", Action: "Reset Password", Label: "Reset Password Success" });
				const status = await authService.resetWhitelabelUserPassword(password, token, email, community);
				methods.setIsLoading(false);
				return status;
			},
			login: async (token: string, workspace: string) => {
				await authService.logIn(token, workspace);
			},
			logOut: async () => await authService.logOut(),
			setEmail(email: string) {
				setState(ctx => {
					ctx.email = email;
				});
			},
			setAuthMode(mode: "signIn" | "signUp") {
				setState(ctx => {
					ctx.mode = mode;
				});
			},
			setCode(code: string) {
				setState(ctx => {
					ctx.code = code;
				});
			},
			setSubscribe(subscribe: boolean) {
				setState(ctx => {
					ctx.subscribe = subscribe;
				});
			},
			setSignedIn(isSignedIn: boolean) {
				setState(ctx => {
					ctx.isSignedIn = isSignedIn;
				});
			},
			setIsLoading(isLoading: boolean) {
				setState(ctx => {
					ctx.isLoading = isLoading;
				});
			},
			setInvitationMode(isInvite: boolean) {
				setState(ctx => {
					ctx.isInvitationMode = isInvite;
				});
			},
			clearState(): void {
				setState({
					email: "",
					code: "",
					subscribe: false,
					isSignedIn: false,
					isInvitationMode: false,
					isLoading: false,
					onboardingToken: {
						valid: true,
						loading: true
					}
				});
			},
			fetchRefreshToken: (refreshToken: string) => {
				return authService.fetchRefreshToken(refreshToken);
			},
			checkInvitationValidation: async ({
				invitationId,
				userId,
				customWorkspace
			}: {
				invitationId: string;
				userId?: string;
				customWorkspace?: string;
			}) => {
				try {
					const { success } = await authService.checkInvitationValidation({ invitationId, userId, customWorkspace });
					if (!success) throw new Error();

					setState(ctx => {
						ctx.onboardingToken = {
							loading: false,
							valid: true
						};
					});
					return true;
				} catch (error) {
					console.log(error);
					setState(ctx => {
						ctx.onboardingToken = {
							loading: false,
							valid: false
						};
					});
					return error;
				}
			},
			verifyOnboardingToken: async ({
				communityUrl,
				invitationId,
				userId
			}: {
				communityUrl: string | null;
				invitationId: string | null;
				userId: string | null;
			}) => {
				if (!communityUrl || !invitationId || !userId) {
					setState(ctx => {
						ctx.onboardingToken = {
							loading: false,
							valid: false
						};
					});

					return;
				}

				try {
					const workspaces = await authService.verifyOnboardingToken({
						communityUrl,
						invitationId,
						userId
					});

					setState(ctx => {
						ctx.onboardingToken = {
							loading: false,
							valid: true
						};
					});

					if (workspaces?.length) {
						return workspaces[0];
					}
				} catch (error) {
					console.log(error);
					setState(ctx => {
						ctx.onboardingToken = {
							loading: false,
							valid: false
						};
					});

					return error;
				}
			},
			confirmInvitation: async ({
				communityUrl,
				invitationCode,
				userId,
				invitationId
			}: {
				communityUrl: string | null;
				invitationCode?: string | null;
				userId: string | null;
				invitationId?: string;
			}) => {
				if (!communityUrl || !userId) {
					setState(ctx => ({
						...ctx,
						onboardingToken: {
							loading: false,
							valid: false
						}
					}));
					return;
				}

				try {
					const workspaces = await authService.confirmInvitation({
						communityUrl,
						invitationCode: invitationCode || undefined,
						userId,
						invitationId
					});

					setState(ctx => {
						ctx.onboardingToken = {
							loading: false,
							valid: true
						};
					});

					if (workspaces?.length) {
						return workspaces[0];
					}
				} catch (error) {
					console.log(error);
					setState(ctx => {
						ctx.onboardingToken = {
							loading: false,
							valid: false
						};
					});

					return error;
				}
			},
			signInUser: async (email: string, password: string) => {
				methods.setIsLoading(true);
				addEvent({ Category: "Login", Action: "Login", Label: "Login Success" });
				const data = await methods.verify(email, null, password);
				methods.setIsLoading(false);
				if ((data as VerifyType)?.globalUserId) {
					setGlobalUserId((data as VerifyType).globalUserId!);
				}

				const token = (data as VerifyType)?.token || (data as UserModel)?.token;
				const { workspaces } = data as VerifyType;
				const { status } = data as UserModel;

				if (token) {
					return {
						token,
						workspaces,
						status,
						userId: (data as UserModel)?.userId || (data as VerifyType)?.globalUserId,
						globalUserId: (data as VerifyType)?.globalUserId
					};
				}

				return {
					token: undefined,
					workspaces: undefined,
					status: undefined,
					userId: undefined,
					globalUserId: undefined
				};
			},
			forgotPassword: async (email: string) => {
				methods.setIsLoading(true);
				addEvent({ Category: "Forgot Password", Action: "Forgot Password", Label: "Forgot Password Success" });
				const { success } = await authService.forgotPassword(email);
				methods.setIsLoading(false);
				return success;
			},
			joinCommunity: async (email: string, communityUrl: string) => {
				try {
					const workspaceInfo = await authService.joinCommunity(email, communityUrl);
					if ((workspaceInfo as IWorkspace[])?.length) {
						return workspaceInfo[0];
					}

					return workspaceInfo;
				} catch (e: any) {
					if (e.status && e.status === 403) {
						return {
							status: UserStatus.BANNED
						};
					}

					return undefined;
				}
			},
			requestPhone: (req: PhoneRequest) => {
				return authService.requestPhone(req);
			},
			verifyPhone: (req: PhoneVerifyRequest) => {
				return authService.verifyPhone(req);
			},
			setInvitationData: (invitationData: { communityUrl: string; invitationId: string }) => {
				setState({ invitationData });
			}
		}),
		[
			//
			addEvent,
			authService,
			setState,
			setUser,
			setGlobalUserId,
			showMessage
		]
	);

	const updateUserPassword = useCallback(
		async ({ password, fromDeepLink, token }: { password: string; fromDeepLink: boolean; token?: string }) => {
			if (token) {
				methods.setIsLoading(true);
				addEvent({ Category: "User Password", Action: "Update Password", Label: "Update Password Success" });
				const status = await authService.updateUserPassword({
					password,
					fromDeepLink,
					token
				});
				methods.setIsLoading(false);
				return status;
			}
		},
		[addEvent, authService, methods]
	);

	const getUserGlobalId = useCallback(
		async (email: string, skipLocalStorage = false) => {
			if (userGlobalUId && !skipLocalStorage) {
				return {
					globalUserId: userGlobalUId
				};
			}

			setState({ isLoading: true });
			const { token, hP, es } = await authService.getUserGlobalId(email);
			setState({ isLoading: false });

			return {
				exists: es,
				havePassword: hP,
				token
			};
		},
		[userGlobalUId, authService, setState]
	);

	const getFreshUserGlobalId = useCallback(
		async (email: string) => {
			setState({ isLoading: true });
			const { token, hP, es } = await authService.getUserGlobalId(email);
			setState({ isLoading: false });

			return {
				exists: es,
				havePassword: hP,
				token
			};
		},
		[authService, setState]
	);

	const checkUserVerification = useCallback(async () => {
		if (userGlobalUId) {
			try {
				const { verified, passedGuidedTour, passedGuidedTourObject } = await authService.checkUserVerification(
					userGlobalUId
				);
				return { verified, passedGuidedTour, passedGuidedTourObject };
			} catch (e) {
				console.log("ERROR: ", (e as Error)?.message);
				return { verified: true, passedGuidedTour: true };
			}
		}

		await methods.logOut();
		history.push(authRoutes.auth.signIn);
	}, [userGlobalUId, authService, history, methods]);

	const resendVerificationEmail = useCallback(
		async (whitelabelUserId?: string) => {
			if (whitelabelUserId) {
				const { success } = await authService.resendWhitelabelVerificationEmail(whitelabelUserId);
				return success;
			}

			if (userGlobalUId) {
				const { success } = await authService.resendVerificationEmail(userGlobalUId);
				return success;
			}

			await methods.logOut();
			history.push(authRoutes.auth.signIn);
		},
		[userGlobalUId, authService, history, methods]
	);

	const resendVerificationEmailWithoutLogout = useCallback(
		async (whitelabelUserId?: string) => {
			try {
				if (whitelabelUserId) {
					await authService.resendWhitelabelVerificationEmail(whitelabelUserId);
				} else if (userGlobalUId) {
					await authService.resendVerificationEmail(userGlobalUId);
				}
				showMessage("Verification email has been resent");
			} catch (error) {
				showMessage("Failed to resend verification email");
				console.error(error);
			}
		},
		[userGlobalUId, authService, showMessage]
	);

	const signupNewUser = useCallback(
		async ({
			email,
			password,
			fromDeepLink,
			token
		}: {
			email: string;
			password: string;
			fromDeepLink: boolean;
			customUserId?: string;
			token?: string;
		}) => {
			methods.setIsLoading(true);
			addEvent({ Category: "Signup", Action: "Signup", Label: "Signup Success" });
			const updatePassData = await updateUserPassword({ password, fromDeepLink, token });

			if (updatePassData) {
				const data = await methods.verify(email, null, password, subscribe);
				const token = (data as VerifyType)?.token || (data as UserModel)?.token;
				const { workspaces } = data as VerifyType;
				const { status } = data as UserModel;

				methods.setIsLoading(false);

				if ((data as VerifyType)?.globalUserId) {
					setGlobalUserId((data as VerifyType).globalUserId!);
				}

				if (token) {
					return {
						token,
						workspaces,
						status,
						userId: (data as UserModel)?.userId || (data as VerifyType)?.globalUserId,
						globalUserId: (data as VerifyType)?.globalUserId
					};
				}
			}

			methods.setIsLoading(false);

			return {
				token: undefined,
				workspaces: undefined,
				status: undefined,
				userId: undefined,
				globalUserId: undefined
			};
		},
		[updateUserPassword, addEvent, subscribe, methods, setGlobalUserId]
	);

	const checkOnboardingFields = useCallback(
		(user: UserModel, workspaceName: string) => {
			// TODO: remove duplicate code
			const isWhiteLabel =
				GLOBAL_CONSTANTS.ENV_APPLICATION_NAME === EnvApplicationNames.Akina ||
				GLOBAL_CONSTANTS.ENV_APPLICATION_NAME === EnvApplicationNames.Relias ||
				GLOBAL_CONSTANTS.ENV_APPLICATION_NAME === EnvApplicationNames.Bitcoin;

			const routes = routesGenerator(workspaceName, isWhiteLabel);

			if (!user.profiles.length) {
				const communityUrl = localStorage.getItem(`${GLOBAL_CONSTANTS.LOCAL_STORAGE_PREFIX}workspace`);

				if (communityUrl) {
					history.push(routes?.member.fillProfile.getPath() + `?communityUrl=${communityUrl}.vyoo.me`);
				} else {
					history.push(authRoutes.auth.signIn);
				}
			} else if (
				!user.profiles[0]?.personalInfo?.birthday &&
				GLOBAL_CONSTANTS.ENV_APPLICATION_NAME !== EnvApplicationNames.Bitcoin
			) {
				history.push(routes?.member.fillProfile.date.getPath());
			}
		},
		[GLOBAL_CONSTANTS.LOCAL_STORAGE_PREFIX, GLOBAL_CONSTANTS.ENV_APPLICATION_NAME, history]
	);

	const verifyUserForEmailUpdate = useCallback(
		async (email: string, password: string) => {
			try {
				return await authService.verify(email, null, false, password);
			} catch (error) {
				showMessage((error as Error).message, 3, true);
				return {
					success: false,
					workspaces: [],
					token: "",
					globalUserId: ""
				};
			}
		},
		[authService, showMessage]
	);

	const getData = useCallback(() => {
		return store;
	}, [store]);

	return {
		...methods,
		updateUserPassword,
		getUserGlobalId,
		checkUserVerification,
		resendVerificationEmail,
		resendVerificationEmailWithoutLogout,
		signupNewUser,
		checkOnboardingFields,
		getData,
		getFreshUserGlobalId,
		verifyUserForEmailUpdate
	};
};

export default useAuth;
