import { useMemo } from "react";

import axios from "axios";

import useHttpActions from "shared/services/api/core/useHttpActions";

import BaseConverter from "./converters/baseConverter";

import { API_URLS } from "../constants";
import { StreamEntityType } from "../types";

const useStreamApiService = () => {
	const actions = useHttpActions();

	const methods = useMemo(
		() => ({
			startStream: async ({
				entityId,
				type = StreamEntityType.MUSIC
			}: {
				entityId: string;
				type: StreamEntityType;
			}) => {
				return BaseConverter.convert<{ success: boolean }>(
					await actions.post(API_URLS.STREAM.START(entityId), { type })
				);
			},
			pauseStream: async ({
				entityId,
				current,
				type = StreamEntityType.MUSIC
			}: {
				entityId: string;
				current: number;
				type: StreamEntityType;
			}) => {
				return BaseConverter.convert<{ success: boolean }>(
					await actions.post(API_URLS.STREAM.PAUSE(entityId), { type, current })
				);
			},
			readFile: (url: string) => {
				return axios({
					method: "get",
					url
				});
			}
		}),
		[actions]
	);

	return useMemo(
		() => ({
			...methods
		}),
		[methods]
	);
};

export default useStreamApiService;
