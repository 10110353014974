import { AxiosResponse } from "axios";
import { DateTime } from "luxon";

import * as R from "ramda";

import BaseConverter from "shared/services/converters/baseConverter";

import { PostFormModel, PostModelRequest, PostType, PostUpdateFormModel } from "shared/types";

import { ProductModel, ProductModelResponse } from "../../types";

export const convertProducts = (data: AxiosResponse<ProductModelResponse>): ProductModel[] => {
	return BaseConverter.convert<ProductModelResponse>(data).products;
};

const convertPublicStartDate = ({
	publicStartDate,
	publicStartTime,
	publicStartTimeZone
}: Pick<PostFormModel, "publicStartDate" | "publicStartTime" | "publicStartTimeZone">): DateTime => {
	const { hour, minute } = DateTime.fromJSDate(publicStartTime as Date);
	const date = DateTime.fromJSDate(publicStartDate as Date)
		.set({ hour, minute })
		.setZone(publicStartTimeZone);

	if (!date.isValid) {
		throw new Error("Invalid date format");
	}

	return date;
};

const convertContentData = (data: PostFormModel, isCreate = true) => {
	const postData =
		data.postType === PostType.PREMIUM
			? {
					...R.pick(
						[
							"content",
							"teaserContent",
							"postType",
							"isEarly",
							"startDate",
							"cid",
							"title",
							"tags",
							"mentions",
							"priceTags"
						],
						data
					)
			  }
			: { ...R.pick(["content", "postType", "startDate", "cid", "title", "tags", "mentions"], data) };

	if (isCreate) {
		postData.sentNotification = data.sentNotification;
	}

	if (data.postType === PostType.PREMIUM && data.isEarly) {
		postData.publicStartDate = data.isEarly ? convertPublicStartDate(data).toJSDate() : null;
	}

	return { createNotifications: true, ...postData };
};

export const convertPostResponse = (data: PostFormModel): PostModelRequest => {
	return {
		payload: convertContentData(data),
		path: "/v2/topics",
		method: "POST",
		token: null,
		boardIds: data.boardIds || [],
		rootPostIds: data.rootPostIds || [],
		type: "bulk"
	};
};

export const convertPostUpdateResponse = (data: PostUpdateFormModel): PostModelRequest => {
	return {
		payload: convertContentData(data, false),
		path: `/v2/topics/${data.pid}`,
		method: "PUT",
		token: null,
		boardIds: data.boardIds
	};
};
