import { Box } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";

import styled from "styled-components";

import { Button, Text } from "shared/ui-kit";

export const Wrapper = styled(Box)`
	position: relative;

	padding-bottom: 75px;
`;

export const Header = styled(Box)``;

Header.Wrapper = styled(Box)`
	width: 100%;

	display: flex;
	justify-content: space-between;
	align-items: center;
`;

Header.Text = styled(Text)`
	color: #222b45;
	font-weight: bold;
	font-size: 32px;

	&.subpage {
		font-size: 18px;
		font-weight: bold;
		line-height: 1.33;
	}
`;

Header.ReportedContentButton = styled(Button)``;

export const Body = styled(Box)``;

Body.Wrapper = styled(Box)`
	margin: 32px auto 0 auto;

	border-radius: 12px;

	border: 1px solid rgba(197, 206, 224, 0.5);

	width: 100%;

	background: white;

	overflow: hidden;

	&.subpage {
		margin-top: 19px;
	}
`;

Body.Header = styled(Box)`
	display: flex;
	justify-content: space-between;
	align-items: center;

	padding: 12px 24px;

	background: #fff;

	${props => props.theme.breakpoints.down("sm")} {
		padding: 12px 16px;
	}
`;

Body.HeaderLeft = styled(Box)`
	display: flex;
	align-items: center;
	justify-content: flex-start;
`;

Body.SeachbarWrapper = styled(Box)`
	> div {
		&:first-child {
			max-width: 260px !important;
			border-radius: 4px;
			width: auto;

			border: 1px solid rgba(197, 206, 224, 0.5);

			background-color: #f7f9fc;

			${props => props.theme.breakpoints.up("sm")} {
				width: 260px;
			}
		}
	}
	.search-icon {
		color: #475b7f;

		width: 30px;
	}

	.MuiInputBase-input {
		max-width: 450px !important;
		width: 100% !important;
		padding: 5px !important;
	}
`;

Body.SearchbarCustomActionsWrapper = styled(Box)`
	margin-left: 8px;
`;

Body.HeaderActionBlock = styled(Box)`
	display: flex;
	align-items: center;
	justify-content: flex-end;
`;

Body.HeaderActionWrapper = styled(Box)`
	&:not(:last-child) {
		margin-right: 10px;
	}
`;

Body.Content = styled(Box)``;

export const ActionButton = styled(Button)`
	font-size: 12px;
	margin: 0;

	&.Mui-disabled {
		background-color: rgba(143, 155, 179, 0.16);
		color: rgba(0, 0, 0, 0.26);
	}
`;

export const EmptyBox = styled(Box)``;

EmptyBox.Body = styled(Box)`
	width: 100%;
	min-height: 600px;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	background: white;
`;

EmptyBox.Note = styled(Text)`
	font-size: 18px;
	font-weight: bold;
	line-height: 1.33;
	letter-spacing: normal;
	text-align: center;
	color: #222b45;

	margin: 22px auto 32px auto;

	max-width: 230px;
`;

EmptyBox.ActionWrapper = styled(Box)`
	margin-top: 10px;
`;

export const Skelet = styled(Box)``;

Skelet.Form = styled(Box)`
	width: 100%;
`;

Skelet.Header = styled(Box)`
	width: 100%;
	min-height: 63px;

	display: flex;
	align-items: center;
	justify-content: space-between;

	border-bottom: 1px solid #edf1f7;

	padding: 12px 25px 11px 25px;
`;

Skelet.TableHead = styled(Box)`
	min-height: 47px;

	display: flex;
	align-items: center;
	justify-content: space-between;

	border-bottom: 1px solid #edf1f7;

	padding: 10px 24px;
`;

Skelet.TableBody = styled(Box)``;

Skelet.TableRow = styled(Box)`
	display: flex;
	align-items: center;
	justify-content: space-between;

	width: 100%;
	min-height: 71px;

	padding: 10px 24px;

	&:not(:last-child) {
		border-bottom: 1px solid #edf1f7;
	}
`;

Skelet.Column = styled(Box)`
	display: flex;
	justify-content: flex-start;
	align-items: center;

	&.last {
		justify-content: flex-end;
	}

	&.left-offset {
		padding-left: 24px;
	}

	&.in-column {
		flex-direction: column;
		justify-content: space-around;
		align-items: flex-start;

		height: 40px;
	}
`;

Skelet.Skeleton = styled(Skeleton)`
	transform: scale(1);
`;
