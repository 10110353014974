import { Box, ButtonBase } from "@material-ui/core";
import styled from "styled-components";

import { Text } from "shared/ui-kit";

export const ChooseBagWrapper = styled(Box)`
	padding: 12px 24px 24px;
	text-align: center;
	img {
		width: 72px;
		height: 72px;
		object-fit: cover;
		border-radius: 8px;
		margin-bottom: 12px;
	}
	h5 {
		margin-bottom: 8px;
	}
	.paragraph {
		font-size: 13px;
		line-height: 1.4;
		color: #8f9bb3;
		margin: auto;
		margin-bottom: 14px;
	}
	.cards-row {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}
	${props => props.theme.breakpoints.up("md")} {
		padding: 24px 85px 42px;
	}

	${props => props.theme.breakpoints.down("xs")} {
		padding: 12px 24px 60px;
	}
`;

export const CCDetailsWrapper = styled(Box)`
	padding: 0px 24px 0px 24px;
	.accepted-cards {
		display: flex;
		align-items: center;
		margin-bottom: 20px;
		svg,
		img {
			margin-right: 8px;
		}
	}
	.bold {
		font-weight: 700;
		margin-bottom: 8px;
	}
	${props => props.theme.breakpoints.up("md")} {
		padding: 40px 24px 0px;
	}
`;

export const CoinsCardWrapper = styled(ButtonBase)`
	width: 100%;
	border-radius: 15px;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
	box-shadow: inset 0 0 0 0.5px #c5cee0;
	margin-top: 16px;
	text-align: left;
	padding: 16px;
	display: flex;
	flex-direction: column;
	position: relative;
	&.active {
		border: ${({ color }) => `solid 2px ${color}`};
	}
	.ribbon {
		position: absolute;
		right: -5px;
		top: -5px;
		z-index: 1;
		overflow: hidden;
		width: 75px;
		height: 75px;
		text-align: right;
	}
	.ribbon span {
		font-size: 10px;
		font-weight: bold;
		color: #fff;
		text-transform: uppercase;
		text-align: center;
		line-height: 20px;
		transform: rotate(45deg);
		-webkit-transform: rotate(45deg);
		width: 100px;
		display: block;
		background: ${({ color }) => color};
		background: ${({ color }) => `linear-gradient(${color} 0%, ${color} 100%)`};
		position: absolute;
		top: 19px;
		right: -21px;
	}
	.ribbon span::before {
		content: "";
		position: absolute;
		left: 0px;
		top: 100%;
		z-index: -1;
		border-left: 3px solid #6173fe;
		border-right: 3px solid transparent;
		border-bottom: 3px solid transparent;
		border-top: 3px solid #6173fe;
	}
	.ribbon span::after {
		content: "";
		position: absolute;
		right: 0px;
		top: 100%;
		z-index: -1;
		border-left: 3px solid transparent;
		border-right: 3px solid #6173fe;
		border-bottom: 3px solid transparent;
		border-top: 3px solid #6173fe;
	}
	.coins-amount {
		width: 100%;
		display: flex;
		align-items: center;
		svg {
			width: 20px;
			height: 20px;
		}
		p {
			font-size: 15px;
			line-height: 1.33;
			color: #8f9bb3;
			margin-left: 4px;
		}
	}
	.content {
		text-align: center;
		img {
			width: 64px;
			height: 64px;
			object-fit: cover;
		}
		h6 {
			font-size: 15px;
			font-weight: 600;
			line-height: 1.6;
			color: #222b45;
		}
		p {
			font-size: 13px;
			line-height: 1.54;
			color: #8f9bb3;
		}
	}

	${props => props.theme.breakpoints.up("sm")} {
		width: 48%;
	}
`;

export const CheckoutSummaryWrapper = styled(Box)`
	padding: 40px 24px;
	p {
		font-size: 13px;
		line-height: 1.38;
		color: #222b45;
	}
	.summary-card {
		border-radius: 12px;
		border: solid 1px #e4e9f2;
		padding: 32px 24px;
		width: 100%;
		.bag-type {
			display: flex;
			justify-content: space-between;
			align-items: center;
			h2 {
				font-size: 22px;
				font-weight: bold;
				line-height: 1.45;
				color: #222b45;
			}
		}
		.coins-amount {
			display: flex;
			align-items: center;
			margin: 8px 0;
			svg {
				width: 20px;
				height: 20px;
			}
			p {
				color: #8f9bb3;
				font-size: 15px;
				margin-left: 4px;
			}
		}
		hr {
			border-radius: 1.5px;
			border: solid 1px #e4e9f2;
			margin: 24px 0 16px;
		}
		.card-info {
			display: flex;
			align-items: center;
			> div {
				display: flex;
				align-items: center;
				svg,
				img {
					margin-right: 12px;
				}
			}
			justify-content: space-between;
			font-size: 15px;
			font-weight: 600;
			line-height: 1.6;
			color: #222b45;
			button {
				color: #6173fe;
			}
		}
	}
	.terms {
		margin-top: 20px;
		font-size: 13px;
		line-height: 1.54;
		color: #8f9bb3;
	}
`;

export const FooterTextWrapper = styled(Box)`
	display: block;
	p {
		color: #8f9bb3;
		font-weight: 600;
		${props => props.theme.breakpoints.down("sm")} {
			font-size: 13px;
		}
	}
	${props => props.theme.breakpoints.up("sm")} {
		display: flex;
	}
`;

export const CoinsText = styled(Text)``;
export const CurrentBalanceText = styled(Text)`
	${props => props.theme.breakpoints.up("sm")} {
		margin-left: 5px;
	}
`;

export const ExpressCheckoutWrapper = styled(Box)`
	h2 {
		font: 18px sans-serif;
		font-weight: 600;
		margin-top: 18px;
		margin-bottom: 18px;
		text-align: center;
	}
	h2.background {
		position: relative;
		z-index: 1;

		&:before {
			border-top: 2px solid #5469d4;
			content: "";
			margin: 0 auto; /* this centers the line to the full width specified */
			position: absolute; /* positioning must be absolute here, and relative positioning must be applied to the parent */
			top: 50%;
			left: 0;
			right: 0;
			bottom: 0;
			width: 95%;
			z-index: -1;
		}

		span {
			/* to hide the lines from behind the text, you have to set the background color the same as the container */
			background: #fff;
			padding: 0 15px;
		}
	}

	h2.double:before {
		/* this is just to undo the :before styling from above */
		border-top: none;
	}

	h2.double:after {
		border-bottom: 1px solid blue;
		-webkit-box-shadow: 0 1px 0 0 red;
		-moz-box-shadow: 0 1px 0 0 red;
		box-shadow: 0 1px 0 0 red;
		content: "";
		margin: 0 auto; /* this centers the line to the full width specified */
		position: absolute;
		top: 45%;
		left: 0;
		right: 0;
		width: 95%;
		z-index: -1;
	}
`;

export const StripeWrapper = styled(Box)`
	#root {
		display: flex;
		align-items: center;
	}

	body {
		font-family: -apple-system, BlinkMacSystemFont, sans-serif;
		font-size: 16px;
		-webkit-font-smoothing: antialiased;
		display: flex;
		justify-content: center;
		align-content: center;
		height: 100vh;
		width: 100vw;
	}

	form {
		min-width: 480px;
		align-self: center;
		padding: 12px;
	}

	#payment-message {
		color: rgb(105, 115, 134);
		font-size: 16px;
		line-height: 20px;
		padding-top: 12px;
		text-align: center;
	}

	#payment-element {
		margin-bottom: 24px;
	}

	/* Buttons and links */
	button {
		background: #5469d4;
		font-family: Arial, sans-serif;
		color: #ffffff;
		border-radius: 4px;
		border: 0;
		padding: 12px 16px;
		font-size: 16px;
		font-weight: 600;
		cursor: pointer;
		display: block;
		transition: all 0.2s ease;
		box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
		width: 100%;
	}

	button:hover {
		filter: contrast(115%);
	}

	button:disabled {
		opacity: 0.5;
		cursor: default;
	}

	/* spinner/processing state, errors */
	.spinner,
	.spinner:before,
	.spinner:after {
		border-radius: 50%;
	}

	.spinner {
		color: #ffffff;
		font-size: 22px;
		text-indent: -99999px;
		margin: 0px auto;
		position: relative;
		width: 20px;
		height: 20px;
		box-shadow: inset 0 0 0 2px;
		-webkit-transform: translateZ(0);
		-ms-transform: translateZ(0);
		transform: translateZ(0);
	}

	.spinner:before,
	.spinner:after {
		position: absolute;
		content: "";
	}

	.spinner:before {
		width: 10.4px;
		height: 20.4px;
		background: #5469d4;
		border-radius: 20.4px 0 0 20.4px;
		top: -0.2px;
		left: -0.2px;
		-webkit-transform-origin: 10.4px 10.2px;
		transform-origin: 10.4px 10.2px;
		-webkit-animation: loading 2s infinite ease 1.5s;
		animation: loading 2s infinite ease 1.5s;
	}

	.spinner:after {
		width: 10.4px;
		height: 10.2px;
		background: #5469d4;
		border-radius: 0 10.2px 10.2px 0;
		top: -0.1px;
		left: 10.2px;
		-webkit-transform-origin: 0px 10.2px;
		transform-origin: 0px 10.2px;
		-webkit-animation: loading 2s infinite ease;
		animation: loading 2s infinite ease;
	}

	@keyframes loading {
		0% {
			-webkit-transform: rotate(0deg);
			transform: rotate(0deg);
		}
		100% {
			-webkit-transform: rotate(360deg);
			transform: rotate(360deg);
		}
	}

	@media only screen and (max-width: 600px) {
		form {
			width: 80vw;
			min-width: initial;
		}
	}
`;
