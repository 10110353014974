import styled from "styled-components";

import { Box, Button, ButtonMenu } from "shared/ui-kit";

export const Wrapper = styled(Box)`
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const StyledLeaveBtn = styled(Button)`
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);

	background-color: #db2c33;

	margin: 0;

	width: 136px;
	height: 32px;

	.MuiButton-label {
		color: white;
		font-size: 14px;
		font-weight: 600;
	}
`;

StyledLeaveBtn.IconWrapper = styled(Box)`
	transform: rotate(135deg);

	margin: 2px 7px 0 0;

	svg {
		path {
			&:nth-child(2) {
				fill: white;
			}
		}
	}

	&.arrow-wrapper {
		transform: translateX(10px);
		margin: 0 0 0 9px;

		position: relative;

		&:before {
			content: "";

			display: block;

			width: 1px;
			height: 24px;

			background-color: rgba(255, 255, 255, 0.5);

			position: absolute;
			top: -5px;
			left: -11px;
		}
	}
`;

export const StyledLeaveDropdownBtn = styled(ButtonMenu)`
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);

	background-color: #db2c33;

	margin: 0;

	width: 136px;
	height: 32px;

	.MuiButton-label {
		color: white;
		font-size: 14px;
		font-weight: 600;
	}
`;
