import { useMemo } from "react";

import useHttpActions from "shared/services/api/core/useHttpActions";
import { BaseConverter } from "shared/services/converters";

import { API_URLS } from "../constants";
import { ActivitiesResponse } from "../types";
import { ActivitySettingsModel, ActivityType } from "../types/ActivityModel";

const useActivitiesApiService = () => {
	const actions = useHttpActions();

	return useMemo(
		() => ({
			getActivities: async (personaId: number, offset: number, limit: number): Promise<ActivitiesResponse> => {
				return BaseConverter.convert<ActivitiesResponse>(
					await actions.get(API_URLS.ACTIVITIES.GET_ALL, {
						personaId,
						offset,
						limit
					})
				);
			},
			deleteActivity: async (personaId: number, activityId: string): Promise<{ success: boolean }> => {
				return BaseConverter.convert(await actions.delete(API_URLS.ACTIVITIES.GET_ALL, { personaId, activityId }));
			},
			markRead: async (activityIds: string[]): Promise<{ success: boolean }> => {
				return BaseConverter.convert(await actions.put(API_URLS.ACTIVITIES.MARK_READ, { activityIds }));
			},
			mutePersona: async (personaIdToMute: number): Promise<{ success: boolean }> => {
				return BaseConverter.convert(await actions.put(API_URLS.ACTIVITIES.MUTE, { personaIdToMute, action: "mute" }));
			},
			muteSimilarActivities: async (activityTypeToMute: ActivityType): Promise<{ success: boolean }> => {
				return BaseConverter.convert(
					await actions.put(API_URLS.ACTIVITIES.MUTE, { activityTypeToMute, action: "mute" })
				);
			},
			changeSettings: async (settings: ActivitySettingsModel): Promise<{ success: boolean }> => {
				return BaseConverter.convert(await actions.put(API_URLS.ACTIVITIES.UPDATE_SETTINGS, settings));
			}
		}),
		[actions]
	);
};

export default useActivitiesApiService;
