import { useMemo } from "react";

import useHttpActions from "shared/services/api/core/useHttpActions";

import BaseConverter from "./converters/baseConverter";

import { API_URLS } from "../constants";
import { ReportContentModel, ReportContentReasonType, ReportContentSortBy, ReportContentType } from "../types";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useReportContentApiService = () => {
	const actions = useHttpActions();

	const methods = useMemo(
		() => ({
			reportUserByPersonaId: async ({
				personaId,
				reporteeId,
				reason
			}: {
				personaId: number;
				reporteeId: number | string;
				reason?: string;
			}) => {
				return BaseConverter.convert<{ success: boolean }>(
					await actions.post(API_URLS.USER.REPORT_USER, {
						reporteeId,
						initiatorId: personaId,
						reportType: "persona",
						reason
					})
				);
			},
			reportContent: async ({
				initiatorId,
				reporteeId,
				reportType,
				reason
			}: {
				initiatorId: string;
				reporteeId: string;
				reportType: ReportContentType;
				reason: ReportContentReasonType;
			}) => {
				return BaseConverter.convert<{ success: boolean }>(
					await actions.post(API_URLS.REPORT_CONTENT.REPORT, {
						initiatorId,
						reporteeId,
						reportType,
						reason
					})
				);
			},
			deleteReport: async (reportIds: string[]) => {
				return BaseConverter.convert(
					await actions.delete<{ success: boolean }>(API_URLS.REPORT_CONTENT.REMOVE_REPORT, {
						reportIds
					})
				);
			},
			deleteReportedContent: async (reportIds: string[]) => {
				return BaseConverter.convert(
					await actions.delete<{ success: boolean }>(API_URLS.REPORT_CONTENT.REMOVE_CONTENT, {
						reportIds
					})
				);
			},
			getReports: async ({
				keyword = "",
				offset = 1,
				limit = 10,
				order,
				sortBy
			}: {
				keyword?: string;
				offset?: number;
				limit?: number;
				order?: number;
				sortBy?: ReportContentSortBy;
			}) => {
				const query: {
					keyword?: string;
					offset?: number;
					limit?: number;
					order?: number;
					sortBy?: ReportContentSortBy;
				} = {
					keyword,
					offset,
					limit
				};

				if (order) {
					query.order = order;
				}

				if (sortBy) {
					query.sortBy = sortBy;
				}

				return BaseConverter.convert<{ success: boolean; reports: ReportContentModel[] }>(
					await actions.get(API_URLS.REPORT_CONTENT.GET_REPORTS, query)
				);
			},
			getReportsCount: async (keyword = "") => {
				return BaseConverter.convert<{ success: boolean; totalCount: number }>(
					await actions.get(API_URLS.REPORT_CONTENT.GET_REPORTS_COUNT, { keyword })
				);
			}
		}),
		[actions]
	);

	return methods;
};

export default useReportContentApiService;
