import React, { Fragment, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from "react";

import { Avatar, Box, FormControl, useMediaQuery } from "@material-ui/core";
import { KeyboardDatePicker, KeyboardTimePicker } from "@material-ui/pickers";

import { DateTime } from "luxon";

import { Controller, useForm } from "react-hook-form";

import { useHistory } from "react-router-dom";

import { ForumPostModel, PostBlockEventType } from "types";

import { EnableFeature, MemberDialog, TwoOptionsSwitch } from "modules/MemberHome/View/shared";
import { SwitchOptions } from "modules/MemberHome/View/shared/TwoOptionsSwitch";
import { MemberDialogContentWrapper } from "modules/MemberHome/View/shared/style";

import CantCreateDialog from "modules/Members/View/Components/CantCreateDialog";
import {
	Dialog,
	PlaceholderImage,
	PostLocationSelector,
	SetupPrice,
	SkeletonBase,
	SmartInput
} from "shared/Components";
import { default as getComponentBlock } from "shared/Components/Blocks/getBlock";
import ConfirmLeavePopup from "shared/Components/ConfirmLeave";
import useConfirmLeavePage from "shared/Components/ConfirmLeave/hooks/useConfirmLeavePage";
import Dialogs from "shared/Components/Dialogs";
import { AllSelectedItemInterface } from "shared/Components/PostLocationSelector/ChooseBoardsDialog";
import {
	useBoards,
	useCommunity,
	useGTM,
	usePost,
	usePosts,
	usePremiumContent,
	useSmartBlock,
	useUser
} from "shared/hooks";
import useNotification from "shared/hooks/useNotification";
import { useS3Uploader } from "shared/services/s3Uploader";
import { MediaModel, PlaceholderImageType, PostType, ToolbarActions } from "shared/types";
import { ContributorLevel } from "shared/types/CategoryModel";
import { Icon, Switch, Tab, Text, Tooltip } from "shared/ui-kit";
import { extractContent, generateLimitedHTML } from "shared/ui-kit/utils/helper";

import * as appTheme from "theme/default";

import { actionMethod, actionTypes, getValidationMessage } from "utils/getValidationMessage";
import { extractContentFromHTML, generateFakeId, hashCode } from "utils/serviceUtils/helpers";

import {
	Button,
	ComponentGroup,
	Content,
	Controls,
	DialogButton,
	EditorForm,
	FormContainer,
	GroupNameText,
	InputWrapper,
	MobilePageSpace,
	MobileSection,
	MobileToolbarWrapper,
	NarrowPicker,
	PickerWrapper,
	PostBasicSettings,
	PostInfo,
	PremiumSetBlock,
	SectionHeader,
	StyledCheckbox,
	StyledRichEditor,
	StyledTabText,
	SwitchWrapper,
	Tabs,
	Title,
	TitleInput,
	TitleInputForm,
	TitleInputWrapper,
	UserFullName,
	WebSection
} from "./styles";

import { EditorWrapper, PostPortal, SkeletonLoader, Toolbar } from "../Components";

export const getName = (action: ToolbarActions | null): string => {
	switch (action) {
		case ToolbarActions.SELECT_TRACK:
		case ToolbarActions.ADD_TRACK: {
			return "Track";
		}
		case ToolbarActions.SELECT_EVENT:
		case ToolbarActions.ADD_EVENT: {
			return "Event";
		}
		case ToolbarActions.SELECT_GROUP:
		case ToolbarActions.ADD_GROUP: {
			return "Group";
		}
		default: {
			return "";
		}
	}
};

export const getId = data => {
	if ("eventId" in data) {
		return data.eventId;
	}

	if ("_id" in data) {
		return data._id;
	}

	if ("cid" in data) {
		return data.cid;
	}

	if ("externalLink" in data) {
		return hashCode(data.link);
	}

	return generateFakeId();
};

enum EditorState {
	Content,
	Teaser
}

enum MentionType {
	Hashtag,
	User
}

export interface CreateUpdatePostProps {
	pid?: string;
	onCreateUpdatePost?: ({ isEdited, postInfo }: { isEdited: boolean; postInfo?: ForumPostModel }) => void;
	groupName?: string;
	groupId?: string;
}

const CreateUpdatePost: React.FC<CreateUpdatePostProps> = ({ pid, onCreateUpdatePost, groupName, groupId }) => {
	const history = useHistory();
	const {
		loadPrerequisite,
		updatePost,
		createPost,
		getPost,
		setEditablePostData,
		setLoading,
		loadHashtags,
		loadTeamMembers,
		saveHashtags,
		commentPost,
		getData: getPostData
	} = usePost();
	const { categories, editablePost, loading, creatingOrUpdating } = getPostData();

	const { communityColors, getData: getCommunityData } = useCommunity();
	const { isReliasMode } = getCommunityData();

	const { aboutRequestsById, setUploadingIds, getData: getS3UploaderData } = useS3Uploader();
	const { uploadingIds } = getS3UploaderData();

	const { setCreateUpdatePostDialog, getData: getPostsData } = usePosts();
	const { createUpdatePostDialog } = getPostsData();

	const { getDefaultBoard, getData: getBoardsData } = useBoards();
	const { defaultBoard } = getBoardsData();

	const { addEvent } = useGTM();

	const { getDataAttributeName, getBlock } = useSmartBlock();

	const { getData: getUserData, isPrivilegedRole } = useUser();
	const { user, isMemberView, userVerified } = getUserData();

	const showNotifyBtn = useMemo(() => user?.isGlobalAdmin || user?.isGlobalOwner || user?.isGlobalModerator, [user]);

	const { showMessage } = useNotification();
	const dialogContentRef = useRef<HTMLDivElement>(null);
	const mainContentRef = useRef<HTMLDivElement>();

	const [portals, setPortals] = useState<{ id: string; html: ReactNode }[]>([]);
	const [notifyCommunity, setNotifyCommunity] = useState(false);
	const [ignoreChanges, setIgnoreChanges] = useState(false);
	const [boardNotifyCommunity, setboardNotifyCommunity] = useState<boolean | undefined>(false);
	const [renderBlocks, setRenderBlocks] = useState<
		{
			id: string;
			comp: ReactNode;
			type?: string;
			extra?: any;
		}[]
	>([]);
	const [teaserRenderBlocks, setTeaserRenderBlocks] = useState<
		{
			id: string;
			comp: ReactNode;
		}[]
	>([]);
	const [initialised, setInitialised] = useState(false);
	const [isStartDateOpen, setIsStartDateOpen] = useState(false);
	const [deleteItemId, setDeleteItemId] = useState<string>();
	const [deleteRefId, setDeleteRefId] = useState<string>();
	const [validContent, setValidContent] = useState(!!pid || !!createUpdatePostDialog.editablePost);
	const [action, setAction] = useState<ToolbarActions | null>(null);
	const [editorState, setEditorState] = useState(EditorState.Content);
	const [contentUploading, setContentUploading] = useState(false);
	const [isPremiumContent, setIsPremiumContent] = useState(false);
	const [showNotAllowedToPost, setShowNotAllowedToPost] = useState(false);

	const isMobile = useMediaQuery(appTheme.default.breakpoints.down("md"));

	const isEditMode = useMemo(
		() => !!pid || !!createUpdatePostDialog.editablePost,
		[pid, createUpdatePostDialog.editablePost]
	);

	const contentRef = useRef<any>();
	const teaserRef = useRef<any>();
	const _contentIndex = useRef<number>(0);
	const _teaserIndex = useRef<number>(0);
	const _insertedLink = useRef<{ link: string; id: string } | null>(null);
	const setupPriceFormRef = useRef<any>();

	const defaultValues: {
		title?: null | string;
		content: null | string;
		contentHidden: string;
		cid: AllSelectedItemInterface;
		teaserContent: null | string;
		teaserContentHidden: string;
		// postType: PostType;
		isEarly: boolean;
		startDate: null | string | Date;
		publicStartDate: null | string | Date;
		publicStartTime: null | string | Date;
		publicStartTimeZone: string;
	} = useMemo(() => {
		return {
			title: "",
			content: null,
			contentHidden: "",
			cid: null,
			teaserContent: null,
			teaserContentHidden: "",
			// postType: PostType.REGULAR,
			isEarly: false,
			startDate: null,
			publicStartDate: null,
			publicStartTime: null,
			publicStartTimeZone: "local"
		};
	}, []);

	const {
		control,
		register,
		handleSubmit,
		watch,
		formState,
		errors,
		reset,
		setValue,
		getValues,
		trigger,
		clearErrors,
		setError
	} = useForm({
		mode: "onChange",
		defaultValues
	});

	const { currentMonetizationPlan } = usePremiumContent();

	const isPayedUser = useMemo(
		() => !!user?.purchase?.productId || !!user?.purchase?.subscriptionId,
		[user?.purchase?.productId, user?.purchase?.subscriptionId]
	);

	const defaultCategory = useMemo(() => {
		if (isEditMode) {
			const editPostCategory = editablePost?.category || categories.find(({ cid }) => cid === editablePost?.cid);

			return (
				editPostCategory || {
					cid: editablePost?.cid,
					name: "",
					titleRequired: editablePost?.title,
					isAdminOnly: true
				}
			);
		}

		return createUpdatePostDialog.editablePost?.cid && groupName
			? {
					cid: createUpdatePostDialog.editablePost.cid,
					name: groupName,
					titleRequired: createUpdatePostDialog.editablePost?.title,
					isAdminOnly: true
			  }
			: categories.find(({ cid }) => cid === defaultBoard?.cid) ||
					categories.find(({ name }) => name === "General Discussion") ||
					categories.find(({ name }) => name === "Announcements");
	}, [categories, defaultBoard, createUpdatePostDialog.editablePost, groupName, isEditMode, editablePost]);

	const defaultCategoryForMemberRole = useMemo(() => {
		if (isEditMode) {
			const editPostCategory = editablePost?.category || categories.find(({ cid }) => cid === editablePost?.cid);

			return (
				editPostCategory || {
					cid: editablePost?.cid,
					name: "",
					titleRequired: editablePost?.title,
					isAdminOnly: isPrivilegedRole
				}
			);
		}

		return (
			(createUpdatePostDialog.editablePost?.cid &&
				groupName && {
					cid: createUpdatePostDialog.editablePost.cid,
					name: groupName,
					titleRequired: createUpdatePostDialog.editablePost?.title,
					isAdminOnly: false
				}) ||
			categories.find(({ cid }) => cid === defaultBoard?.cid) ||
			categories.find(
				({ name, contributorLevel }) =>
					(name === "Announcements" || name === "General Discussion") &&
					(contributorLevel === ContributorLevel.ANYONE || (contributorLevel === ContributorLevel.PAID && isPayedUser))
			)
		);
	}, [
		categories,
		defaultBoard,
		isPayedUser,
		createUpdatePostDialog.editablePost,
		groupName,
		isEditMode,
		editablePost,
		isPrivilegedRole
	]);

	const onEvent = useCallback((eventType: PostBlockEventType, id: string, refId?: string) => {
		if (eventType === PostBlockEventType.delete) {
			setDeleteItemId(id);
			refId && setDeleteRefId(refId);
		}
		if (eventType === PostBlockEventType.uploadingProgressStart) {
			setContentUploading(true);
		}

		if (eventType === PostBlockEventType.uploadingProgressFinished) {
			setContentUploading(false);
		}
	}, []);

	useEffect(() => {
		register("contentHidden");
		register("teaserContentHidden");
		loadPrerequisite();

		if (pid || createUpdatePostDialog.editablePostPid) {
			// @ts-expect-error: ts error. It's valid
			getPost(pid || createUpdatePostDialog.editablePostPid);
		} else {
			getDefaultBoard({});
		}
	}, [register, loadPrerequisite, getPost, pid, getDefaultBoard, createUpdatePostDialog.editablePostPid]);

	useEffect(() => {
		return () => {
			setEditablePostData(undefined);
		};
	}, [setEditablePostData]);

	useEffect(() => {
		if (createUpdatePostDialog.isOpen && createUpdatePostDialog.editablePost) {
			setEditablePostData(createUpdatePostDialog.editablePost);
		}
	}, [createUpdatePostDialog.editablePost, createUpdatePostDialog.isOpen, setEditablePostData]);

	const getCid = useCallback(() => {
		let cidInfo: AllSelectedItemInterface = null;

		if (defaultCategory) {
			cidInfo = {
				[defaultCategory.isGroupBoard ? "rootPostIds" : "boardIds"]: [
					{
						id: defaultCategory.cid!,
						name: defaultCategory.name
					}
				]
			};
		}

		return cidInfo;
	}, [defaultCategory]);

	useEffect(() => {
		setValue("cid", getCid(), {
			shouldDirty: false
		});
	}, [
		defaultCategory,
		setValue,
		isEditMode,
		pid,
		createUpdatePostDialog.isOpen,
		showNotifyBtn,
		defaultCategoryForMemberRole,
		isPrivilegedRole,
		getCid
	]);

	useEffect(() => {
		if (
			editablePost &&
			(editablePost.pid === pid || createUpdatePostDialog.editablePost) &&
			!!categories?.length &&
			!initialised
		) {
			setLoading(true);
			setInitialised(true);
			let currentCategory = categories.find(x => x.cid === editablePost.cid);
			if (!currentCategory) {
				currentCategory = categories.find(({ name }) => name === "General Discussion");
			}

			// const priceTagId: string | null = null;
			// if (!!editablePost.product?.length) {
			// 	const currentProduct = priceTags.find(x => x._id === editablePost.product![0]._id);
			// 	if (currentProduct) {
			// 		priceTagId = currentProduct._id;
			// 	}
			// }

			const content = new DOMParser().parseFromString(editablePost!.content, "text/html");
			const blocks = content.querySelectorAll("span[data-name]");
			const videos = content.querySelectorAll("video");

			const teaserContent = new DOMParser().parseFromString(editablePost!.teaserContent as string, "text/html");
			const teaserBlocks = teaserContent.querySelectorAll("span[data-name]");
			const teaserVideos = teaserContent.querySelectorAll("video");

			const postRenderBlocks: {
				id: string;
				comp: ReactNode;
				type?: string;
				extra?: any;
			}[] = [];

			const postTeaserRenderBlocks: {
				id: string;
				comp: ReactNode;
				type?: string;
			}[] = [];

			const parseBlock = (b, isMainContent) => {
				const { attributes, parentNode } = b;

				if (attributes["poster"]) {
					const src = attributes["src"].value;
					const poster = attributes["poster"].value;
					const id = `custom-embedded-${getId(content)}`;

					const comp = getBlock("video", { src, poster, elementId: id }, b, onEvent);

					if (isMainContent) {
						postRenderBlocks.push({ id, comp, type: "video" });
					} else {
						postTeaserRenderBlocks.push({ id, comp, type: "video" });
					}

					const wrapper = document.createElement("p");
					wrapper.innerHTML = id;

					parentNode!.replaceChild(wrapper, b);
				}

				if (attributes["data-name"]?.value) {
					const elementName = attributes["data-name"].value;
					const dataAttributeName = getDataAttributeName(elementName);
					const decodedData = decodeURIComponent(attributes[dataAttributeName].value);
					const data = elementName === "text" ? decodedData : JSON.parse(decodedData);
					const id = `custom-embedded-${getId(content)}`;
					const correctComponent = getBlock(elementName, { ...data, elementId: id }, b, onEvent);

					if (elementName === "text") {
						const textContent = new DOMParser().parseFromString(data, "text/html");
						const textContentHTML = textContent.documentElement.getElementsByTagName("body")[0];

						const wrapper = document.createElement("p");
						Array.from(textContentHTML.children).forEach(item => {
							const p = document.createElement("p");
							p.innerHTML = item.innerHTML;
							wrapper.appendChild(p);
							if (item.innerHTML.includes("href=\"{'hashtag'") || item.innerHTML.includes("href=\"{'personaId'")) {
								Array.from(item.children).forEach(ch => {
									const href = ch.attributes?.["href"]?.value;
									if (href && href.includes(":")) {
										const val = href.replace(/{|}|'/gi, "");
										const parts = val.split(":");
										postRenderBlocks.push({
											id,
											comp: parts[1],
											type: parts[0] === "hashtag" ? "hashtag" : "mention",
											extra: {
												value: parts[1],
												text: ch.innerHTML
											}
										});
									}
								});
							}
						});
						parentNode!.replaceChild(wrapper, b);
					} else {
						if (isMainContent) {
							postRenderBlocks.push({
								id,
								comp: correctComponent,
								type: elementName
							});
						} else {
							postTeaserRenderBlocks.push({
								id,
								comp: correctComponent,
								type: elementName
							});
						}

						const wrapper = document.createElement("p");
						wrapper.innerHTML = id;

						parentNode!.replaceChild(wrapper, b);
					}
				}
			};

			blocks.forEach(b => {
				parseBlock(b, true);
			});

			teaserBlocks.forEach(b => {
				parseBlock(b, false);
			});

			videos.forEach(v => {
				parseBlock(v, true);
			});

			teaserVideos.forEach(v => {
				parseBlock(v, false);
			});

			const displayContent = content.documentElement.getElementsByTagName("body")[0].innerHTML;
			const correctContent = displayContent.replace(/<br>/g, "<br/>");

			const displayTeaserContent = teaserContent.documentElement.getElementsByTagName("body")[0].innerHTML;
			const correctTeaserContent = displayTeaserContent.replace(/<br>/g, "<br/>");

			reset({
				title: editablePost?.title || "",
				cid: getCid(),
				isEarly: editablePost?.isEarly,
				publicStartDate:
					editablePost?.isEarly && editablePost.publicPostedAt ? new Date(editablePost.publicPostedAt!) : null,
				publicStartTime:
					editablePost?.isEarly && editablePost.publicPostedAt ? new Date(editablePost.publicPostedAt!) : null,
				content: correctContent,
				contentHidden: correctContent,
				teaserContent: correctTeaserContent,
				teaserContentHidden: correctTeaserContent,
				startDate: new Date(editablePost.timestampISO)
			});

			setRenderBlocks(postRenderBlocks);
			setTeaserRenderBlocks(postTeaserRenderBlocks);
			setLoading(false);
		}
	}, [
		editablePost,
		contentRef,
		teaserRef,
		getBlock,
		getDataAttributeName,
		categories,
		initialised,
		pid,
		editorState,
		setLoading,
		onEvent,
		reset,
		createUpdatePostDialog.editablePost,
		defaultCategory,
		getCid
	]);

	useEffect(() => {
		if (Object.keys(errors).length) {
			showMessage(
				<ul>
					{Object.values(errors).map((p, i) => (
						<li key={i}>{p?.message}</li>
					))}
				</ul>,
				3
			);
		}
	}, [errors, showMessage]);

	// Temporary disable
	// const timeZoneOptions = useMemo(() => timezones.map(x => ({ label: x, value: x })), [timezones]);

	const onSetContent = useCallback(
		(content: any, customAction: ToolbarActions, correctEditor: EditorState) => {
			const ref = correctEditor === EditorState.Content ? contentRef : teaserRef;
			const editor = ref.current.getEditor();
			const index = correctEditor === EditorState.Content ? _contentIndex.current : _teaserIndex.current;
			const id = `custom-embedded-${getId(content)}`;

			const correctAction = customAction;

			// check that similar smart obj already inserted
			if (!!document.getElementById(id)) {
				setAction(null);
				showMessage(`The ${getName(correctAction)} was already added to the post`);
				return;
			}

			let editorRef =
				correctEditor === EditorState.Content ? contentRef.current?.editingArea : teaserRef.current?.editingArea;
			editorRef = editorRef?.children?.length ? editorRef?.children[0] : editorRef;

			const html = getComponentBlock(correctAction, content ? { ...content, editorRef } : { editorRef }, id, onEvent);
			let position = index > -1 ? index : editor.getLength();
			if (position <= 0 && correctAction !== ToolbarActions.ADD_LINK) {
				editor.insertText(position, "\n", "user");
				position++;
			}
			const contentInfo = editor.getContents(position - 1, 1);
			if (contentInfo?.ops[0]?.insert?.AppPanelEmbed) {
				editor.insertText(position, "\n", "user");
				position++;
			}
			if (correctAction === ToolbarActions.ADD_LINK && content?.link) {
				while (!editor.getText(position, content.link.length).startsWith("http")) {
					position += 1;
				}
				editor.deleteText(position, content.link.length, "user");
				editor.insertText(position, content.link, "link", content.link, "user");
				position += content.link.length;

				if (!_insertedLink.current) {
					editor.insertText(position, "\n", "user");
					position += 1;
				}
			}

			if (correctAction !== ToolbarActions.ADD_LINK || (content?.link && !_insertedLink.current)) {
				let insertPosition = correctAction === ToolbarActions.ADD_LINK && content?.link ? editor.getLength() : position;

				if (editor.getText(insertPosition - 3, 3) === "\n\n\n" && correctAction !== ToolbarActions.ADD_LINK) {
					// replace with space bar to prevent extra line
					editor.deleteText(insertPosition - 3, 3, "user");
					editor.insertText(insertPosition - 3, " \n", "user");
					insertPosition -= 1;
				}

				editor.insertEmbed(insertPosition, "AppPanelEmbed", `<div id="${id}"></div>`, "user");

				setPortals(portals => [...portals, { id, html }]);

				setAction(null);
				if (correctAction !== ToolbarActions.ADD_LINK) {
					// If we do not have space after inserted element, then we need to add it to allow users to set cursor after element
					const nextText = editor.getText(position + 2, 1);
					if (nextText !== "\n" && nextText !== " ") {
						editor.insertText(position + 2, " ", "user");
					}
				}

				const insertedElement = document.getElementById(id);
				if (insertedElement) {
					setTimeout(() => {
						isMemberView
							? dialogContentRef?.current?.scrollTo({
									top:
										(insertedElement?.offsetTop || 0) +
										((insertedElement?.offsetParent as HTMLElement)?.offsetTop || 0) -
										50,
									behavior: "smooth"
							  })
							: window.scrollTo({
									top:
										(insertedElement?.offsetTop || 0) +
										((insertedElement?.offsetParent as HTMLElement)?.offsetTop || 0) -
										50,
									behavior: "smooth"
							  });
					}, 100);
				}

				if (correctAction === ToolbarActions.ADD_LINK) {
					_insertedLink.current = {
						link: content.link,
						id
					};
				}
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[isMemberView, onEvent, showMessage, getUserData]
	);

	const submit = async data => {
		delete data.priceChanged;

		const editor = contentRef.current.getEditor();
		const mentionModule = editor.getModule("mention");
		if (mentionModule.isOpen) {
			mentionModule.hideMentionList();
		}

		setAction(null);

		data.content = data.contentHidden;
		delete data.contentHidden;
		data.teaserContent = data.teaserContentHidden;
		delete data.teaserContentHidden;

		if (!data.postType) {
			data.postType = isPremiumContent ? PostType.PREMIUM : PostType.REGULAR;
		}

		if (!isEditMode) {
			addEvent({
				Category: "Engagement",
				Action: "Community",
				Label: "Community | Create a Post Success"
			});
		}

		let formData = {
			...data,
			boardIds:
				!groupName && !!data.cid.boardIds?.length ? data.cid.boardIds.map(b => b.id).filter(b => !!b) : undefined,
			rootPostIds:
				!groupName && !!data.cid.rootPostIds?.length ? data.cid.rootPostIds.map(b => b.id).filter(b => !!b) : undefined
		};

		if (data.postType === PostType.PREMIUM) {
			formData = { ...formData, priceTags: setupPriceFormRef.current?.getPriceTags() };
		}

		delete formData.cid;

		const postInfo = isEditMode
			? await updatePost({ ...formData, pid: pid || createUpdatePostDialog.editablePost?.pid })
			: groupId
			? await commentPost({ comment: formData.content, postId: groupId, data: formData })
			: await createPost({ ...formData, ...(boardNotifyCommunity && { sentNotification: notifyCommunity }) });
		// setValue("content", data.content);
		// setValue("teaserContent", data.teaserContent);
		setPortals([]);
		reset();
		setValidContent(false);
		setEditorState(EditorState.Content);
		showMessage(
			getValidationMessage({
				name: "Post",
				actionType: actionTypes.CRUD,
				actionMethod: isEditMode ? actionMethod.updated : actionMethod.created,
				emoji: isEditMode ? "✨" : "🎉"
			}),
			3
		);

		onCreateUpdatePost && onCreateUpdatePost({ isEdited: isEditMode, postInfo });

		setValue(
			"cid",
			isPrivilegedRole
				? defaultCategory?.cid || ""
				: createUpdatePostDialog.editablePost?.cid || defaultCategoryForMemberRole?.cid || ""
		);

		if (isEditMode) {
			setIgnoreChanges(true);

			if (!onCreateUpdatePost) {
				history.go(-1);
			}
		}
	};

	const { title, content, contentHidden, teaserContent, startDate, isEarly, cid } = watch([
		"title",
		"content",
		"contentHidden",
		"teaserContent",
		"startDate",
		"isEarly",
		"cid"
	]);

	const valuesToCheck = !isMemberView
		? !!title?.length || !!content || !!startDate || !!contentHidden.length || !!teaserContent
		: false;

	const {
		handleLeavePageConfirmed,
		closeConfirmPopup,
		getData: getConfirmLeavePopupData
	} = useConfirmLeavePage({
		valuesToCheck,
		ignoreChanges: isMemberView || ignoreChanges
	});
	const { showConfirmPopup } = getConfirmLeavePopupData();

	const ButtonComp = useMemo(() => {
		return loading ? (
			<SkeletonBase height={56} />
		) : (
			<Button
				size="giant"
				buttonTheme="main"
				type="submit"
				disabled={
					creatingOrUpdating || !formState.isDirty || !validContent || !!Object.keys(errors)?.length || contentUploading
				}
			>
				{isEditMode ? "Save Changes" : startDate ? "Schedule" : "Post Now"}
			</Button>
		);
	}, [loading, creatingOrUpdating, formState, validContent, errors, isEditMode, startDate, contentUploading]);

	useEffect(() => {
		if (cid) {
			const selectedCategory = categories.find(({ cid: cId }) => cId === cid);
			setboardNotifyCommunity(selectedCategory?.notifyCommunity);
		}
	}, [cid, categories]);

	useEffect(() => {
		if (!isPremiumContent) {
			setEditorState(EditorState.Content);
			clearErrors(["publicStartDate", "publicStartTime"]);
		}
	}, [isPremiumContent, clearErrors]);

	const isRequireTitle = useMemo(() => {
		return createUpdatePostDialog.editablePost?.cid && groupName
			? !!createUpdatePostDialog.editablePost?.title
			: isEditMode
			? !!editablePost?.title
			: !!categories.find(x => x.cid === cid)?.titleRequired;
	}, [cid, categories, createUpdatePostDialog.editablePost, groupName, editablePost?.title, isEditMode]);

	useEffect(() => {
		if (isEarly) {
			trigger(["publicStartDate", "publicStartTime"]);
		} else {
			clearErrors(["publicStartDate", "publicStartTime"]);
		}
	}, [isEarly, trigger, clearErrors]);

	const PostContentBock = useMemo(() => {
		if (renderBlocks.length) {
			setTimeout(() => {
				const editor = contentRef.current.getEditor();

				if (editor) {
					const text = editor.getText();
					renderBlocks
						.filter(x => !["hashtag", "mention"].includes(`${x.type}`))
						.reverse()
						.forEach((item, index) => {
							const position = text.indexOf(item.id);
							if (position > -1) {
								editor.deleteText(position, item.id.length + 1, "user");
								editor.insertEmbed(position || index, "AppPanelEmbed", `<div id="${item.id}"></div>`, "user");
								setPortals(portals => [...portals, { id: item.id, html: item.comp }]);
							}
						});

					if (renderBlocks.some(x => x.type && x.type === "linkpreview")) {
						const originalArray = renderBlocks.reverse();
						const index = originalArray.findIndex(x => x.type && x.type === "linkpreview");
						const firstLink = document.getElementById(originalArray[index].id);
						const editorContainer = firstLink?.parentElement?.parentElement;
						if (editorContainer) {
							let startRemove = false;
							Array.from(editorContainer.children).forEach(ch => {
								if (ch === firstLink?.parentElement) {
									startRemove = true;
								}
								if (startRemove && ch.tagName === "P") {
									editorContainer.removeChild(ch);
								}
							});
						}
						_insertedLink.current = {
							link: (originalArray[index] as any)?.comp?.props?.url || "",
							id: originalArray[index].id
						};
					}

					if (renderBlocks.some(x => ["hashtag", "mention"].includes(`${x.type}`))) {
						const mentionModule = editor.getModule("mention");

						renderBlocks
							.filter(x => ["hashtag", "mention"].includes(`${x.type}`))
							.reverse()
							.forEach(el => {
								const editorText = editor.getText();

								const searchText = el.type === "hashtag" ? `#${el.extra?.value}` : `${el.extra?.text}`;
								const value = `${el.extra?.value}`;
								const position = editorText.indexOf(searchText);
								if (position > -1) {
									editor.deleteText(position, searchText.length + 1, "user");
									editor.setSelection(position, 0);

									const tag =
										el.type === "hashtag"
											? `<a class="hashtag" href="{'hashtag':'${value}'}">${searchText}</a>`
											: `<a class="author" href="{'personaId':${value}}">${searchText}</a>`;

									mentionModule.insertItem(
										{
											id: value,
											activeChar: el.type === "hashtag" ? "#" : "@",
											value: tag,
											denotationChar: ""
										},
										true
									);
								}
							});
					}
				}
			}, 500);
		}

		return (
			<Controller
				name="content"
				control={control}
				render={({ value, onBlur }) => (
					<div>
						<StyledRichEditor
							placeholder="Type here what’s on your mind… "
							config={{
								toolbar: { items: [] },
								mention: {
									enableAtSearch: true,
									enableHashSearch: true,
									positioningStrategy: "fixed",
									// eslint-disable-next-line react/display-name
									renderMentionItem: (item: {
										id?: string;
										type: MentionType;
										label: string;
										value: string;
										photo?: string;
									}) => {
										switch (item?.type) {
											case MentionType.Hashtag:
												return `<p style="color: #222b45; font-size: 15px; margin: 0; padding: 0 24px;">#${item.label}</p>`;
											case MentionType.User:
												const { label, photo } = item;
												return `
														<div class="mention-user" style="width: 280px; height: 100%; display: flex; align-items: center; padding: 0 24px;">
															<div style="border-radius: 50%; height: 24px; width: 24px; background: #4350f7; margin: 0 8px 0 0; background-size: cover; background-position: center; background-repeat: no-repeat; background-image: url(${photo})"></div>
															<p style="color: #222b45; font-size: 15px; margin: 0;">${label}</p>
														</div>`;
										}
									},
									mentionOnSelect: (item: any, insertItem: any) => {
										const tag =
											item.denotationChar === "#"
												? `<a class="hashtag" href="{'hashtag':'${item.value}'}">#${item.value}</a>`
												: `<a class="author" href="{'personaId':${item.id}}">@${item.value}</a>`;

										insertItem(
											{
												...item,
												id: item.denotationChar === "#" ? item.value : item.id,
												activeChar: item.denotationChar,
												value: tag,
												denotationChar: ""
											},
											true
										);

										if (item.denotationChar === "#" && item?.id === "-1") {
											saveHashtags([item.value]);
										}
									},
									suggestPeople: async (searchTerm: string) => {
										const users = await loadTeamMembers(searchTerm || null);

										return users
											.filter(x => x.firstName || x.lastName)
											.map(u => ({
												id: u.personaId,
												label: `${u.firstName} ${u.lastName}`,
												value: `${u.firstName}`,
												photo: u.photos.length ? u.photos[0]?.profilePicture : null,
												type: MentionType.User
											}));
									},
									suggestTags: async (searchTerm: string) => {
										if (searchTerm.length < 1 || searchTerm === " ") return [];

										const tags = await loadHashtags(searchTerm);
										if (!tags.some(x => x.valueEscaped === searchTerm.toLowerCase())) {
											tags.unshift({
												value: searchTerm,
												valueEscaped: searchTerm.toLowerCase(),
												id: "-1"
											});
										}
										return tags.map(t => ({
											label: t.valueEscaped,
											value: t.value,
											type: MentionType.Hashtag,
											id: t.id || t.value
										}));
									},
									renderMentionLoading: () => {
										return "<p>Loading...</p>";
									},
									onMenuOpen: () => {
										const quillMentionDropdown = document.getElementById("quill-mention-list");
										if (quillMentionDropdown) {
											document.addEventListener("keydown", e => {
												if ((e.key === "ArrowUp" || e.key === "ArrowDown") && quillMentionDropdown.children?.length) {
													const dropdownHeight = quillMentionDropdown.clientHeight;
													const optionHeight = quillMentionDropdown.children[0].clientHeight;
													const activeChild = quillMentionDropdown.getElementsByClassName(
														"ql-mention-list-item selected"
													);
													if (activeChild.length) {
														const index = Array.prototype.indexOf.call(quillMentionDropdown.children, activeChild[0]);
														const elementOffset = optionHeight * (index + 1);
														if (
															elementOffset > quillMentionDropdown.scrollTop + dropdownHeight ||
															elementOffset <= quillMentionDropdown.scrollTop
														) {
															quillMentionDropdown.scrollTop = elementOffset - optionHeight;
														}
													}
												}
											});
										}
									},
									onMenuClose: () => {
										const quillMentionDropdown = document.getElementById("quill-mention-list");
										if (quillMentionDropdown) {
											document.removeEventListener("keydown", () => {});
										}
									}
								}
							}}
							value={value || ""}
							ref={contentRef}
							onChange={(e, c) => {
								if (!loading) {
									const updatedValue = c.getData();
									const text = extractContentFromHTML(updatedValue).trim();
									const content = new DOMParser().parseFromString(updatedValue, "text/html");
									const blocks = content.querySelectorAll(".embed-innerApp");

									_contentIndex.current = c.getSelection()?.index;

									setValidContent(text.length >= 1 || !!blocks.length);
									setValue("contentHidden", updatedValue, { shouldDirty: true });

									text.length >= 1 || !!blocks.length
										? clearErrors("contentHidden")
										: setError("contentHidden", {
												type: "manual",
												message: "Content text can't be empty"
										  });

									if (!!c?.delta?.ops?.length) {
										const inserted = c.delta.ops
											.filter(
												x =>
													x.insert &&
													typeof x.insert === "string" &&
													!x.attributes &&
													(x.insert.startsWith("http://") || x.insert.startsWith("https://"))
											)
											.map(x => x.insert)
											.filter(x => x);

										inserted.forEach(link => {
											onSetContent({ link }, ToolbarActions.ADD_LINK, EditorState.Content);
										});

										const linkedHashtags = c.delta.ops
											.filter(x => x.insert && typeof x.insert === "string" && x.insert.startsWith("#"))
											.map(x => x.insert)
											.filter(x => x && x.length > 1);

										if (linkedHashtags.length) {
											const editor = contentRef.current.getEditor();
											const mentionModule = editor.getModule("mention");
											saveHashtags(linkedHashtags.map(x => x.replace("#", "").toLowerCase()));

											linkedHashtags.forEach(hashtagInfo => {
												const text = editor.getText();
												let position = text.indexOf(hashtagInfo);
												if (position > -1) {
													while (!editor.getText(position, hashtagInfo.length).startsWith("#")) {
														position += 1;
													}
													editor.deleteText(position, hashtagInfo.length, "user");
													editor.setSelection(position, 0);

													const hashtag = hashtagInfo.replace("#", "");
													const tag = `<a class="hashtag" href="{'hashtag':'${hashtag}'}">#${hashtag}</a>`;

													mentionModule.insertItem(
														{
															id: hashtag.toLowerCase(),
															activeChar: "#",
															value: tag,
															denotationChar: ""
														},
														true
													);
												}
												return;
											});
										}
									}
								}
							}}
							onChangeCursorPosition={e => {
								if (!loading && e && "index" in e) {
									_contentIndex.current = e.index || 0;
								}
							}}
							onBlur={onBlur}
						/>
					</div>
				)}
			/>
		);
	}, [
		clearErrors,
		control,
		loadHashtags,
		loadTeamMembers,
		loading,
		setError,
		setValue,
		renderBlocks,
		onSetContent,
		saveHashtags
	]);

	const PostTeaserContentBock = useMemo(() => {
		if (teaserRenderBlocks.length) {
			setTimeout(() => {
				const editor = teaserRef.current.getEditor();

				if (editor) {
					const text = editor.getText();
					teaserRenderBlocks.reverse().forEach((item, index) => {
						const position = text.indexOf(item.id);
						if (position > -1) {
							editor.deleteText(position, item.id.length + 1, "user");
							editor.insertEmbed(position || index, "AppPanelEmbed", `<div id="${item.id}"></div>`, "user");
							setPortals(portals => [...portals, { id: item.id, html: item.comp }]);
						}
					});
				}
			}, 500);
		}

		return (
			<Controller
				name="teaserContent"
				control={control}
				rules={{
					validate: () => {
						if (isPremiumContent) {
							const content = extractContentFromHTML(getValues("teaserContentHidden"));
							return content.length <= 200 || "Teaser content must be less than 200 characters!";
						}
						return true;
					}
				}}
				render={({ value, onBlur }) => (
					<StyledRichEditor
						placeholder="Type a short intro text here… up to 200 characters."
						config={{
							toolbar: { items: [] },
							mention: {
								enableAtSearch: true,
								enableHashSearch: true,
								// eslint-disable-next-line react/display-name
								renderMentionItem: (item: {
									id?: string;
									type: MentionType;
									label: string;
									value: string;
									photo?: string;
								}) => {
									switch (item?.type) {
										case MentionType.Hashtag:
											return `# ${item.label}`;
										case MentionType.User:
											const { label, photo } = item;
											return `
														<div class="mention-user" style="height: 40px; width: 280px; padding: 8px 24.7px 8px 12px; border-radius: 4px;">
															<img alt="${label}" src="${photo}" style="border-radius: 50%; height: 24px; width: 24px; background: #4350f7; margin: 0 8px 0 0;" />
															<span style="color: #222b45; font-size: 15px; position: relative; top: -7px;">${label}</span>															
														</div>`;
									}
								},
								mentionOnSelect: (item: any) => {
									const editor = teaserRef.current.getEditor();
									const position = editor.getSelection().index || 0;
									const tag =
										item.denotationChar === "#"
											? `<a class="hashtag" href="{'hashtag':'${item.value}'}">#${item.value}</a>&nbsp;`
											: `<a class="author" href="{'personaId':${item.id}}">${item.value}</a>&nbsp;`;
									editor.deleteText(position - 1, 1, "user");
									editor.insertEmbed(position - 1, "AppInlineEmbed", tag, "user");
									editor.setSelection(position - 1 + item.value.length + (item.denotationChar === "#" ? 2 : 1), 0);
								},
								suggestPeople: async (searchTerm: string) => {
									const users = await loadTeamMembers(searchTerm || null);

									return users.map(u => ({
										id: u.personaId,
										label: `${u.firstName} ${u.lastName}`,
										value: `${u.firstName} ${u.lastName}`,
										photo: u.photos.length ? u.photos[0]?.profilePicture : null,
										type: MentionType.User
									}));
								},
								suggestTags: async (searchTerm: string) => {
									const tags = await loadHashtags(searchTerm);
									return tags.map(t => ({ label: t.valueEscaped, value: t.value, type: MentionType.Hashtag }));
								},
								renderMentionLoading: () => {
									return "<p>Loading...</p>";
								}
							}
						}}
						value={value || ""}
						ref={teaserRef}
						onChange={(e, c) => {
							if (!loading) {
								const limit = 200;
								_teaserIndex.current = c.getSelection()?.index;

								let customContent = c.getData();
								if (extractContent(customContent).length > limit) {
									const div = document.createElement("div");
									div.innerHTML = customContent.trim();

									customContent = generateLimitedHTML(div, limit, 0).innerHTML;
									// It's a hack to force update this component
									if (value === customContent) {
										setValue("teaserContent", undefined);
									}
									setTimeout(() => {
										setValue("teaserContent", customContent, { shouldDirty: true, shouldValidate: true });
									}, 10);
								}

								setValue("teaserContentHidden", customContent, { shouldDirty: true });
							}
						}}
						onChangeCursorPosition={e => {
							if (!loading && e && "index" in e) {
								_teaserIndex.current = e.index || 0;
							}
						}}
						onBlur={onBlur}
					/>
				)}
			/>
		);
	}, [teaserRenderBlocks, control, isPremiumContent, getValues, loadTeamMembers, loadHashtags, loading, setValue]);

	const fullName = useMemo(() => {
		if (user?.profiles?.length) {
			return `${user?.profiles[0]?.firstName} ${user?.profiles[0]?.lastName}`;
		}
		return "";
	}, [user]);

	const photos = useMemo(() => {
		if (user?.profiles?.length) {
			return user?.profiles[0]?.photos;
		}
		return [];
	}, [user]);

	const addElementFn = useCallback(
		(action: ToolbarActions | null, data?: any) => {
			setAction(action);

			if (action === ToolbarActions.ADD_IMAGE && data && data instanceof FileList) {
				onSetContent(
					{
						fileList: data
					},
					action,
					editorState
				);
			}
		},
		[onSetContent, editorState]
	);

	const showPriceTags = useMemo(
		() => editablePost?.priceTags && !!editablePost?.priceTags.length && !!editablePost?.priceTags[0].priceTagProductId,
		[editablePost]
	);

	const PremiumSettingsForm = useMemo(
		() => (
			<PremiumSetBlock className={isMemberView && "no-shadow"}>
				<Controller
					name="priceChanged"
					control={control}
					render={({ onChange }) => (
						<SetupPrice
							createPostView={isMemberView}
							ref={setupPriceFormRef}
							title={"Who can see this post?"}
							onChangeType={isPrem => {
								setIsPremiumContent(isPrem);
							}}
							defaultValue={showPriceTags ? editablePost?.priceTags : undefined}
							onPriceChanged={() => onChange(true)}
						/>
					)}
				/>
			</PremiumSetBlock>
		),
		[control, editablePost?.priceTags, isMemberView, showPriceTags]
	);

	const toggleStartDate = useCallback(() => {
		setIsStartDateOpen(!isStartDateOpen);
	}, [isStartDateOpen]);

	const PremiumContentBlock = useMemo(
		() =>
			!loading && (
				<>
					{PremiumSettingsForm}
					{isPremiumContent && (
						<>
							<PremiumSetBlock className={`early-access ${isMemberView ? "pt" : ""}`}>
								{isMemberView ? (
									<Controller
										name="isEarly"
										control={control}
										defaulValue={isEarly}
										render={({ onChange, value }) => (
											<EnableFeature
												verticalPadding
												title="Early Access"
												description="Offer Premium members early access to content before the rest of the world."
												value={value}
												onChange={checked => onChange(checked)}
												small
											/>
										)}
									/>
								) : (
									<>
										<SectionHeader>
											<Text variant="h6">Early Access</Text>
											<Controller
												name="isEarly"
												control={control}
												defaulValue={isEarly}
												render={({ onChange, value }) => (
													<Switch value={value} onChange={e => onChange(e.target.checked)} />
												)}
											/>
										</SectionHeader>
										<Text>Offer Premium members early access to content before the rest of the world.</Text>
									</>
								)}
								{isEarly && (
									<>
										<Text varint="subtitle1">This post will become public on:</Text>
										<PickerWrapper component={ComponentGroup} className={`date ${isMemberView && "member-spacing"}`}>
											<Controller
												name="publicStartDate"
												control={control}
												defaultValue={new Date()}
												rules={{
													required: { value: isEarly, message: "Public Date is required for premium!" },
													validate: value => (isEarly ? DateTime.fromJSDate(value).isValid : true)
												}}
												render={({ onChange, value }) => (
													<KeyboardDatePicker
														placeholder={DateTime.now().plus({ days: 1 }).toFormat("dd/LL/yyyy")}
														autoOk
														inputVariant="outlined"
														variant="inline"
														disabled={!isEarly}
														value={value}
														minDate={startDate || new Date()}
														onChange={d => onChange(d)}
													/>
												)}
											/>
										</PickerWrapper>
										<PickerWrapper component={ComponentGroup} className={`time ${isMemberView && "member-spacing"}`}>
											<Controller
												name="publicStartTime"
												control={control}
												defaultValue={new Date()}
												rules={{
													required: { value: isEarly, message: "Public Time is required for premium!" }
												}}
												render={({ onChange, value }) => (
													<InputWrapper>
														<KeyboardTimePicker
															placeholder={DateTime.now().toLocaleString(DateTime.TIME_SIMPLE)}
															autoOk
															inputVariant="outlined"
															variant="inline"
															disabled={!isEarly}
															value={value}
															onChange={d => onChange(d)}
															keyboardIcon={<Icon name="clock" group="filled" />}
														/>
													</InputWrapper>
												)}
											/>
											{/*Temporary disable*/}
											{/*<InputWrapper>*/}
											{/*	<Controller*/}
											{/*		name="publicStartTimeZone"*/}
											{/*		defaultValue={DateTime.local().toFormat("ZZZZ")}*/}
											{/*		disabled={!isEarly}*/}
											{/*		rules={{ required: { value: isEarly, message: "Timezone is required for premium!" } }}*/}
											{/*		as={Select}*/}
											{/*		control={control}*/}
											{/*		variant="outlined"*/}
											{/*	>*/}
											{/*		{timeZoneOptions.map((item, index) => (*/}
											{/*			<MenuItem key={index} className="menuItem" value={item.value}>*/}
											{/*				<Text>{item.label}</Text>*/}
											{/*			</MenuItem>*/}
											{/*		))}*/}
											{/*	</Controller>*/}
											{/*</InputWrapper>*/}
										</PickerWrapper>
									</>
								)}
							</PremiumSetBlock>
						</>
					)}
				</>
			),
		[PremiumSettingsForm, control, isEarly, isMemberView, isPremiumContent, loading, startDate]
	);

	const MainContent = useMemo(() => {
		const isHeightMoreThan700 = (mainContentRef?.current?.clientHeight || 0) > 700;
		return (
			<>
				<Content isNotifyCommunity={isHeightMoreThan700}>
					{loading ? (
						<SkeletonLoader isMemberView={isMemberView} />
					) : (
						<>
							{!isMemberView && <Title variant="h1">{isEditMode ? "Edit the" : "Create a"} post</Title>}
							<PostInfo className={isMemberView && "isMemberView"}>
								<PostInfo.AvatarWrapper>
									{photos.length && (photos[0] as Partial<MediaModel>)?.profilePicture ? (
										<Avatar
											alt={fullName}
											src={
												(typeof photos[0] === "string"
													? photos[0]
													: (photos[0] as Partial<MediaModel>).profilePicture) as string
											}
										/>
									) : (
										<PlaceholderImage
											type={PlaceholderImageType.PROFILE_IMAGE}
											width={48}
											height={48}
											viewBox={"0 0 400 400"}
										/>
									)}
								</PostInfo.AvatarWrapper>
								<PostBasicSettings className={isMemberView && "isMemberView"}>
									<UserFullName>{fullName}</UserFullName>
									<PostBasicSettings.Options className={isMemberView && "no-mr"}>
										<FormControl error={errors?.cid !== undefined} className="w-auto">
											{((createUpdatePostDialog.editablePost?.cid || groupId) && groupName) ||
											(!defaultCategory?.name && !defaultCategoryForMemberRole?.name) ? (
												<GroupNameText className="px-2 py-1 rounded-md font-semibold">
													{groupName || createUpdatePostDialog.editablePost?.category?.name}
												</GroupNameText>
											) : (
												<>
													{((!!defaultCategory?.name && !!defaultCategoryForMemberRole?.name) || !isEditMode) && (
														<Controller
															name="cid"
															control={control}
															rules={{
																required: { value: true, message: "Category is required!" },
																validate: value => !!value
															}}
															render={({ onChange, value }) => (
																<PostLocationSelector value={value} onChange={onChange} />
															)}
														/>
													)}
												</>
											)}
										</FormControl>
										<Controller
											name="startDate"
											control={control}
											rules={{
												validate: value => {
													const minDate = editablePost?.timestamp
														? Math.min(new Date(editablePost.timestamp).getTime(), new Date().getTime())
														: new Date();
													if (value && value.getTime() < minDate) {
														return "Date should not be before minimal date";
													}
													return true;
												}
											}}
											render={({ onChange, value }) => {
												return isMemberView ? (
													<SmartInput
														name="schedule"
														onChange={onChange}
														value={value}
														placeholder="Schedule"
														narrow
														includeTime
														disablePast
														wrapperWidth={isMobile ? "auto" : "75%"}
														labelClassName="font-semibold"
														rightIconColor="#192038"
														isBackgroundGray
														calendar
														readOnly
													/>
												) : (
													<NarrowPicker
														isMemberView={isMemberView}
														inputVariant="outlined"
														emptyLabel="Schedule"
														format={"MMM d, yyyy, h:m a"}
														variant="inline"
														value={value}
														minDate={
															editablePost?.timestamp
																? Math.min(new Date(editablePost.timestamp).getTime(), new Date().getTime())
																: new Date()
														}
														open={isStartDateOpen}
														onChange={d => onChange(d)}
														onClose={toggleStartDate}
														autoOk
														onClick={toggleStartDate}
														clickAction={toggleStartDate}
														error={errors?.startDate?.message}
														helperText={errors?.startDate?.message}
													/>
												);
											}}
										/>
									</PostBasicSettings.Options>
								</PostBasicSettings>
							</PostInfo>
							{isPremiumContent &&
								(isMemberView ? (
									<TwoOptionsSwitch
										disableAnimation
										variants={SwitchOptions.BASIC}
										onChange={choice => setEditorState(choice as number)}
										optionOne={{
											label: "Premium Content",
											value: EditorState.Content
										}}
										optionTwo={{
											label: "Teaser Content",
											value: EditorState.Teaser
										}}
									/>
								) : (
									<Tabs onChange={(_, v) => setEditorState(v)} value={editorState}>
										<Tab
											label={
												<Box component={Tabs.TabWrapper}>
													<StyledTabText>Premium content</StyledTabText>
													<Tabs.TabFooter isActive={editorState === EditorState.Content} />
												</Box>
											}
											value={EditorState.Content}
										/>
										<Tab
											label={
												<Box component={Tabs.TabWrapper}>
													<StyledTabText>
														Teaser content
														<Tooltip text="Create a short public introduction to your premium post that stimulates interest in a community member.">
															<Icon style={{ marginLeft: "8px" }} fill="#c5cee0" name="info-circle" group="filled" />
														</Tooltip>
													</StyledTabText>
													<Tabs.TabFooter isActive={editorState === EditorState.Teaser} />
												</Box>
											}
											value={EditorState.Teaser}
										/>
									</Tabs>
								))}

							<EditorWrapper
								hide={editorState === EditorState.Content}
								hidden={loading}
								communityprimarycolor={communityColors.primary}
							>
								<EditorForm className={!isMemberView && "manage"} boardNotifyCommunity={boardNotifyCommunity}>
									{PostTeaserContentBock}
								</EditorForm>
								{!isMobile && !isMemberView && <Toolbar type="teaser" onClick={addElementFn} />}
							</EditorWrapper>
							<EditorWrapper
								hide={editorState === EditorState.Teaser}
								hidden={loading}
								communityprimarycolor={communityColors.primary}
								ref={mainContentRef}
							>
								<EditorForm className={!isMemberView && "manage"} boardNotifyCommunity={boardNotifyCommunity}>
									{isRequireTitle && (
										<TitleInputWrapper className={!isMemberView && "manage"}>
											<TitleInputForm className={!isMemberView ? "manage" : "member"}>
												<FormControl>
													<Controller
														name="title"
														control={control}
														rules={{
															required: { value: isRequireTitle, message: "Title is required!" }
														}}
														render={({ onChange, value }) => (
															<TitleInput
																className={!isMemberView && "manage"}
																placeholder="Post Title..."
																value={value}
																errorText={errors?.title?.message}
																onChange={e => onChange(e.target.value.slice(0, 100))}
															/>
														)}
													/>
												</FormControl>
											</TitleInputForm>
										</TitleInputWrapper>
									)}
									{PostContentBock}
								</EditorForm>
							</EditorWrapper>
							{isMobile && !isMemberView && (
								<MobileToolbarWrapper boardNotifyCommunity={boardNotifyCommunity}>
									<Toolbar isMobile type={editorState === 0 ? "full" : "teaser"} onClick={addElementFn} />
								</MobileToolbarWrapper>
							)}
							{boardNotifyCommunity && showNotifyBtn && !isMemberView && !isEditMode && (
								<SwitchWrapper className={isMemberView ? "mv-16" : ""}>
									<Switch
										onChange={() => setNotifyCommunity(!notifyCommunity)}
										conditional={true}
										leftLabel={"Send notification to all members"}
										checked={notifyCommunity}
									/>
								</SwitchWrapper>
							)}
							{!isMobile && !isMemberView && editorState === EditorState.Content && (
								<Toolbar type="full" onClick={addElementFn} />
							)}
						</>
					)}
					{isMemberView &&
						!loading &&
						(isPrivilegedRole || user?.isInfluencer) &&
						boardNotifyCommunity &&
						!groupName && (
							<StyledCheckbox
								label="Notify Community"
								onChange={() => setNotifyCommunity(!notifyCommunity)}
								checked={notifyCommunity}
								isNotifyCommunity={isHeightMoreThan700}
							/>
						)}
				</Content>
				{isMobile && <br />}
				{!isMemberView && (
					<>
						<Controls>
							<WebSection>{ButtonComp}</WebSection>
							{PremiumContentBlock}
						</Controls>
						<MobileSection>{ButtonComp}</MobileSection>
						<MobilePageSpace />
					</>
				)}
			</>
		);
	}, [
		loading,
		isMemberView,
		isEditMode,
		photos,
		fullName,
		errors,
		control,
		isPrivilegedRole,
		user?.isInfluencer,
		isPremiumContent,
		editorState,
		communityColors.primary,
		boardNotifyCommunity,
		PostTeaserContentBock,
		isMobile,
		isRequireTitle,
		PostContentBock,
		addElementFn,
		showNotifyBtn,
		notifyCommunity,
		ButtonComp,
		PremiumContentBlock,
		editablePost,
		isStartDateOpen,
		toggleStartDate,
		defaultCategory?.name,
		defaultCategoryForMemberRole?.name,
		createUpdatePostDialog.editablePost?.cid,
		groupName,
		createUpdatePostDialog.editablePost?.category?.name,
		groupId
	]);

	const handleCloseMemberPostDialog = () => {
		setCreateUpdatePostDialog({ isOpen: false });
		setUploadingIds([]);
	};

	const handleDelete = useCallback(() => {
		if (!deleteItemId) return;
		const element = document.getElementById(deleteItemId);
		if (element) {
			const refIds: string[] = [];
			const els = element.querySelectorAll("[data-refId]") as NodeListOf<HTMLElement>;
			els.forEach(el => el.dataset["refid"] && refIds.push(el.dataset["refid"]));

			if (!!refIds.length) {
				aboutRequestsById(refIds);
			}

			element.parentElement?.remove();
			if (_insertedLink.current && _insertedLink.current!.id === deleteItemId) {
				_insertedLink.current = null;
			}
		}
		setDeleteItemId(undefined);
		if (deleteRefId) {
			setUploadingIds(uploadingIds.filter(id => id !== deleteRefId));
		}
	}, [aboutRequestsById, deleteItemId, uploadingIds, setUploadingIds, deleteRefId]);

	const shouldShowPremiumContent = useMemo(
		() => (isPrivilegedRole || user?.isInfluencer) && !loading && !!currentMonetizationPlan,
		[currentMonetizationPlan, isPrivilegedRole, loading, user?.isInfluencer]
	);

	return (
		<>
			{!isMemberView && (
				<ConfirmLeavePopup
					handleLeavePage={handleLeavePageConfirmed}
					open={showConfirmPopup}
					onClose={closeConfirmPopup}
				/>
			)}
			<FormContainer
				className={isMemberView && "member"}
				onSubmit={e => {
					e.preventDefault();
					(isReliasMode ? !user?.verified : !userVerified) ? setShowNotAllowedToPost(true) : handleSubmit(submit)();
				}}
			>
				{isMemberView ? (
					<MemberDialog
						open={createUpdatePostDialog.isOpen}
						onClose={handleCloseMemberPostDialog}
						title={editablePost ? "Update a Post" : "Create a Post"}
						confirmLeave={!!Object.keys(formState.dirtyFields).length}
						customWidth={shouldShowPremiumContent ? 920 : 584}
						modalHeight={480}
						contentRef={dialogContentRef}
						footerLeftContent={
							<Toolbar type={editorState === EditorState.Teaser ? "teaser" : "full"} onClick={addElementFn} />
						}
						footerPrimary={{
							text: editablePost ? "Update" : "Post",
							loading: creatingOrUpdating || contentUploading,
							disabled:
								creatingOrUpdating ||
								!formState.isDirty ||
								!validContent ||
								!!Object.keys(errors)?.length ||
								contentUploading ||
								!!uploadingIds.length
						}}
					>
						<MemberDialogContentWrapper
							className={`full-width-editor relative ${shouldShowPremiumContent ? "post-sides" : ""}`}
							minHeight={480}
							maxHeight={loading ? 480 : isPremiumContent ? 640 : 700}
						>
							{MainContent}
							{shouldShowPremiumContent && (
								<MemberDialogContentWrapper.ContentBlock className={isPremiumContent && "isOpen"}>
									{PremiumContentBlock}
								</MemberDialogContentWrapper.ContentBlock>
							)}
						</MemberDialogContentWrapper>
					</MemberDialog>
				) : (
					MainContent
				)}
				<Dialogs
					action={action}
					setAction={setAction}
					onSetContent={content => onSetContent(content, action as ToolbarActions, editorState)}
				/>
				{isMemberView ? (
					<MemberDialog
						customWidth={420}
						title="Delete Block?"
						open={!!deleteItemId}
						onClose={() => setDeleteItemId(undefined)}
						footerSecondary={{ text: "Keep", onClick: () => setDeleteItemId(undefined) }}
						footerPrimary={{
							text: "Delete",
							onClick: handleDelete
						}}
					>
						<MemberDialogContentWrapper className="pv-20">
							<Text>Are you sure you want to permanently delete this block?</Text>
						</MemberDialogContentWrapper>
					</MemberDialog>
				) : (
					<Dialog
						title="Delete Block?"
						open={!!deleteItemId}
						onClose={() => setDeleteItemId(undefined)}
						footer={
							<Fragment>
								<DialogButton buttonTheme={"outline"} size={"large"} onClick={() => setDeleteItemId(undefined)}>
									Keep
								</DialogButton>
								<DialogButton size={"large"} onClick={handleDelete}>
									Delete
								</DialogButton>
							</Fragment>
						}
						isScrollable={false}
					>
						Are you sure you want to permanently delete this block?
					</Dialog>
				)}
			</FormContainer>
			{portals.map(item => (
				<PostPortal key={item.id} id={item.id}>
					{item.html}
				</PostPortal>
			))}
			{showNotAllowedToPost && (
				<CantCreateDialog
					title="Create post not allowed"
					description="You can not create post before you verify your email address."
					handleClose={() => setShowNotAllowedToPost(false)}
				/>
			)}
		</>
	);
};

export default CreateUpdatePost;
