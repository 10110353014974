import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";

import { ButtonBase } from "@material-ui/core";
import clsx from "clsx";
import { DateTime } from "luxon";

import { Link } from "react-router-dom";

import { routes } from "apps/RegularUser/constants";
import { ReactComponent as ArrowFilledIcon } from "assets/icons/arrow-filled.svg";
import { PageTemplate } from "modules/Manage/View/Components";
import { DateRangeModel, DateRangeSelector, SmartInput, TableWrapper } from "shared/Components";
import { TableColumnLoaderType } from "shared/Components/NewTable/Components";
import { Cell } from "shared/Components/NewTable/style";
import { useCommunity, useDebounce, useFundraisers } from "shared/hooks";
import { FundraiserDonationType } from "shared/types";
import { FundraiserDonationSortBy } from "shared/types/FundraiserLeaderboardTypes";
import { Button } from "shared/ui-kit";
import { formatCount, numberWithCommas } from "utils/serviceUtils/helpers";

import { BreadcrumbText, SortIcon } from "./style";

import { StyledIcon } from "../../../Payments/View/Containers/Transactions/style";

const Table = React.lazy(() => import("shared/Components/NewTable"));

const DonationsTable: FC<{ id?: string; fundraiser?: string }> = ({ id, fundraiser }) => {
	const {
		getData: getFundraisersData,
		getFundraisersDonations,
		getDonationsCount,
		setFundraisersShowPerPage,
		getFundraisersCSVFile
	} = useFundraisers();
	const { fundraisersShowPerPage, loading } = getFundraisersData();

	const { getData: getCommunityData } = useCommunity();
	const { workspace } = getCommunityData();

	const [keyword, setKeyword] = useState<string | undefined>();
	const [dateRange, setDateRange] = useState<{ startDate?: Date; endDate?: Date }>({});
	const [dateFilter, setDateFilter] = useState("allTime");
	const [donationsList, setDonationsList] = useState<FundraiserDonationType[]>([]);
	const [totalCount, setTotalCount] = useState(0);
	const [offset, setOffset] = useState(1);
	const [sortBy, setSortBy] = useState<{
		key: FundraiserDonationSortBy;
		order: number;
	}>({
		key: FundraiserDonationSortBy.DATE,
		order: -1
	});

	const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);

	const selectWrapperRef = useRef<HTMLDivElement | null>(null);

	const openDatepicker = useCallback(() => {
		if (selectWrapperRef.current) {
			setAnchorEl(selectWrapperRef.current);
		}
	}, []);

	const closeDatepicker = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);

	const onChange = useCallback((range?: DateRangeModel) => {
		setDateRange(
			!!range
				? {
						startDate: range.startDate!.toJSDate(),
						endDate: range.endDate!.toJSDate()
				  }
				: {}
		);
		closeDatepicker();
	}, []);

	const handleDateFilter = useCallback(
		(optionVal: string) => {
			let time;
			const today = DateTime.local();

			setDateFilter(optionVal);

			switch (optionVal) {
				case "today":
					time = today;
					break;
				case "thisWeek":
					time = DateTime.local().minus({ week: 1 });
					break;
				case "thisMonth":
					time = DateTime.local().minus({ month: 1 });
					break;
				case "dateRange":
					time = null;
					break;
				default:
					return;
			}

			time && onChange({ startDate: time, endDate: today });
		},
		[onChange]
	);

	const debouncedKeyword = useDebounce(keyword, 500);

	const filterOptions = useMemo(
		() => [
			{ label: "All Time", value: "allTime" },
			{ label: "Today", value: "today" },
			{ label: "This Week", value: "thisWeek" },
			{ label: "This Month", value: "thisMonth" },
			{ label: "Date Range", value: "dateRange", onClick: () => openDatepicker() }
		],
		[openDatepicker]
	);

	useEffect(() => {
		getFundraisersDonations({
			offset,
			limit: fundraisersShowPerPage,
			keyword: debouncedKeyword,
			startDate: dateRange.startDate,
			endDate: dateRange.endDate,
			fundraiserId: id,
			sortBy: sortBy.key,
			order: sortBy.order
		}).then(fundraisers => {
			setDonationsList(fundraisers || []);
		});
	}, [sortBy, dateRange, id, debouncedKeyword, fundraisersShowPerPage, getFundraisersDonations, offset]);

	useEffect(() => {
		getDonationsCount({
			fundraiserId: id,
			keyword: debouncedKeyword,
			startDate: dateRange.startDate,
			endDate: dateRange.endDate
		}).then(count => {
			setTotalCount(count || 0);
		});
	}, [dateRange, id, debouncedKeyword, getDonationsCount]);

	const handleChangeSort = useCallback((sort: FundraiserDonationSortBy) => {
		setSortBy(oldSort =>
			oldSort.key === sort
				? {
						key: sort,
						order: oldSort.order * -1
				  }
				: {
						key: sort,
						order: -1
				  }
		);

		setOffset(1);
	}, []);

	const tableColumns = useMemo(
		() => [
			{
				width: 320,
				alignment: "left",
				label: (
					<ButtonBase
						className="flex items-center rounded"
						onClick={() => handleChangeSort(FundraiserDonationSortBy.SPONSOR)}
					>
						<Cell.HeaderText>{formatCount(totalCount as number, "Donation")}</Cell.HeaderText>
						<SortIcon
							className={clsx(
								`flex flex-col ${
									sortBy.key === FundraiserDonationSortBy.SPONSOR && `active-${sortBy.order === 1 ? "desc" : "asc"}`
								}`
							)}
						>
							<ArrowFilledIcon className="w-2 h-2" />
							<ArrowFilledIcon className="w-2 h-2 transform rotate-180" />
						</SortIcon>
					</ButtonBase>
				),
				Cell: ({ rowData }: { rowData: FundraiserDonationType }) => (
					<Cell.Wrapper className="column with-image">
						<Cell.Text>{rowData.contact.name}</Cell.Text>
						<Cell.Text className="light">
							{rowData.contact.contactInfo.phone || rowData.contact.contactInfo.email}
						</Cell.Text>
					</Cell.Wrapper>
				),
				loaderTemplate: TableColumnLoaderType.twoTextRows,
				dataKey: "cid"
			},
			{
				alignment: "left",
				label: (
					<ButtonBase
						className="flex items-center rounded"
						onClick={() => handleChangeSort(FundraiserDonationSortBy.DATE)}
					>
						<Cell.HeaderText>Date</Cell.HeaderText>
						<SortIcon
							className={clsx(
								`flex flex-col ${
									sortBy.key === FundraiserDonationSortBy.DATE && `active-${sortBy.order === 1 ? "desc" : "asc"}`
								}`
							)}
						>
							<ArrowFilledIcon className="w-2 h-2" />
							<ArrowFilledIcon className="w-2 h-2 transform rotate-180" />
						</SortIcon>
					</ButtonBase>
				),
				width: 120,
				Cell: ({ rowData }: { rowData: FundraiserDonationType }) => {
					const correctDate = DateTime.fromJSDate(new Date(rowData.createdAt));
					return (
						<Cell.Wrapper className="column with-image">
							<Cell.Text>{correctDate.toFormat("MMM d, yyyy")}</Cell.Text>
						</Cell.Wrapper>
					);
				},
				dataKey: "date"
			},
			{
				alignment: "left",
				minWidth: 100,
				label: (
					<ButtonBase
						className="flex items-center rounded"
						onClick={() => handleChangeSort(FundraiserDonationSortBy.FUNDRAISER)}
					>
						<Cell.HeaderText>Fundraiser</Cell.HeaderText>
						<SortIcon
							className={clsx(
								`flex flex-col ${
									sortBy.key === FundraiserDonationSortBy.FUNDRAISER && `active-${sortBy.order === 1 ? "desc" : "asc"}`
								}`
							)}
						>
							<ArrowFilledIcon className="w-2 h-2" />
							<ArrowFilledIcon className="w-2 h-2 transform rotate-180" />
						</SortIcon>
					</ButtonBase>
				),
				Cell: ({ rowData }: { rowData: FundraiserDonationType }) => (
					<Cell.Wrapper>
						<Cell.Text>
							<Cell.Text>{rowData.fundraiser.name}</Cell.Text>
						</Cell.Text>
					</Cell.Wrapper>
				),
				dataKey: "name"
			},
			{
				alignment: "left",
				minWidth: 100,
				label: (
					<ButtonBase
						className="flex items-center rounded"
						onClick={() => handleChangeSort(FundraiserDonationSortBy.GROUP)}
					>
						<Cell.HeaderText>Group</Cell.HeaderText>
						<SortIcon
							className={clsx(
								`flex flex-col ${
									sortBy.key === FundraiserDonationSortBy.GROUP && `active-${sortBy.order === 1 ? "desc" : "asc"}`
								}`
							)}
						>
							<ArrowFilledIcon className="w-2 h-2" />
							<ArrowFilledIcon className="w-2 h-2 transform rotate-180" />
						</SortIcon>
					</ButtonBase>
				),
				Cell: ({ rowData: { group } }: { rowData: FundraiserDonationType }) => (
					<Cell.Wrapper>
						<Cell.Text>
							<Cell.Text>{`${group ? group.name : "-"}`}</Cell.Text>
						</Cell.Text>
					</Cell.Wrapper>
				),
				dataKey: "group"
			},
			{
				alignment: "left",
				minWidth: 140,
				label: (
					<ButtonBase
						className="flex items-center rounded"
						onClick={() => handleChangeSort(FundraiserDonationSortBy.ON_BEHALF)}
					>
						<Cell.HeaderText>On behalf of</Cell.HeaderText>
						<SortIcon
							className={clsx(
								`flex flex-col ${
									sortBy.key === FundraiserDonationSortBy.ON_BEHALF && `active-${sortBy.order === 1 ? "desc" : "asc"}`
								}`
							)}
						>
							<ArrowFilledIcon className="w-2 h-2" />
							<ArrowFilledIcon className="w-2 h-2 transform rotate-180" />
						</SortIcon>
					</ButtonBase>
				),
				Cell: ({ rowData: { onBehalfPersona } }: { rowData: FundraiserDonationType }) => (
					<Cell.Wrapper>
						<Cell.Text>
							<Cell.Text>{`${
								onBehalfPersona ? `${onBehalfPersona.firstName} ${onBehalfPersona.lastName}` : "-"
							}`}</Cell.Text>
						</Cell.Text>
					</Cell.Wrapper>
				),
				dataKey: "behalfOf"
			},
			{
				alignment: "left",
				minWidth: 140,
				label: (
					<ButtonBase
						className="flex items-center rounded"
						onClick={() => handleChangeSort(FundraiserDonationSortBy.AMOUNT)}
					>
						<Cell.HeaderText>Amount</Cell.HeaderText>
						<SortIcon
							className={clsx(
								`flex flex-col ${
									sortBy.key === FundraiserDonationSortBy.AMOUNT && `active-${sortBy.order === 1 ? "desc" : "asc"}`
								}`
							)}
						>
							<ArrowFilledIcon className="w-2 h-2" />
							<ArrowFilledIcon className="w-2 h-2 transform rotate-180" />
						</SortIcon>
					</ButtonBase>
				),
				Cell: ({ rowData }: { rowData: FundraiserDonationType }) => (
					<Cell.Wrapper>
						<Cell.Text>${numberWithCommas(rowData.amountRaised)}</Cell.Text>
					</Cell.Wrapper>
				),
				dataKey: "amount"
			}
		],
		[handleChangeSort, sortBy, totalCount]
	);

	const handleDownloadExcel = useCallback(async () => {
		const base64 = await getFundraisersCSVFile({
			keyword: debouncedKeyword,
			startDate: dateRange.startDate,
			endDate: dateRange.endDate,
			fundraiserId: id,
			sortBy: sortBy.key
		});

		if (base64) {
			const link = document.createElement("a");
			link.href = window.URL.createObjectURL(
				new Blob([base64], {
					type: "type/csv"
				})
			);
			const fileName = `donations-${DateTime.local().toFormat("yyyy-MM-dd")}.csv`;
			link.download = fundraiser ? `${fundraiser}-${fileName}` : fileName;
			document.body.appendChild(link);
			link.click();
		}
	}, [fundraiser, dateRange.endDate, dateRange.startDate, debouncedKeyword, getFundraisersCSVFile, id, sortBy.key]);

	const customSelectedRender = useCallback(
		(option: any) => {
			if (option?.value === "dateRange" && dateRange) {
				const startPeriod = DateTime.fromJSDate(dateRange.startDate!);
				const endPeriod = DateTime.fromJSDate(dateRange.endDate!);
				const format = "LLL d";
				return `${startPeriod.toFormat(format)} - ${endPeriod.toFormat(format)}`;
			}
			return option?.label;
		},
		[dateRange]
	);

	const extraBlock = useMemo(
		() => (
			<div className="flex items-center gap-4 w-max">
				<div ref={selectWrapperRef}>
					<SmartInput
						dropdown
						id={"period-selector-id"}
						placeholder="Select"
						value={dateFilter}
						options={filterOptions}
						onChange={handleDateFilter}
						customSelectedRender={customSelectedRender}
					/>
				</div>
				<DateRangeSelector
					open={open}
					anchorEl={anchorEl}
					onClose={closeDatepicker}
					onChange={onChange}
					minDate={workspace?.createdAt}
					maxDate={DateTime.local().toJSDate()}
				/>
				<Button
					removeSideMargin
					buttonTheme={"light"}
					palette={"success"}
					onClick={handleDownloadExcel}
					leftIcon={<StyledIcon group={"color"} name={"app-excel"} />}
				>
					Download Excel
				</Button>
			</div>
		),
		[
			anchorEl,
			handleDownloadExcel,
			dateFilter,
			filterOptions,
			handleDateFilter,
			onChange,
			open,
			workspace?.createdAt,
			customSelectedRender
		]
	);

	const TableBlock = useMemo(
		() => (
			<TableWrapper sizes={{ horizontalTablet: 900 }}>
				<Table
					columns={tableColumns}
					data={donationsList}
					loading={loading}
					paginated
					totalDataCount={totalCount}
					page={offset}
					pageSize={fundraisersShowPerPage}
					onChangePage={page => setOffset(page)}
					onChangePageSize={pageSize => setFundraisersShowPerPage(pageSize)}
				/>
			</TableWrapper>
		),
		[
			donationsList,
			totalCount,
			fundraisersShowPerPage,
			loading,
			offset,
			setFundraisersShowPerPage,
			setOffset,
			tableColumns
		]
	);

	return (
		<>
			{fundraiser && (
				<BreadcrumbText className="flex items-center gap-1">
					<Link to={routes.fundraising.fundraisers.getPath()}>Fundraisers</Link> <span>/</span>{" "}
					<span>{fundraiser}</span>
				</BreadcrumbText>
			)}
			<PageTemplate
				title="Donations"
				isLoading={loading}
				isNoData={!loading && !totalCount}
				emptyText={`You don’t have any donations in ${id ? "this fundraiser" : "your community"} yet.`}
				searchPlaceholder={"Search Donation"}
				onSearchUpdate={text => setKeyword(text)}
				searchId={"searchDonation"}
				extraAction={extraBlock}
			>
				{TableBlock}
			</PageTemplate>
		</>
	);
};

export default DonationsTable;
