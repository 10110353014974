import React, { FC, useCallback, useEffect, useMemo, useState } from "react";

import { Box } from "@material-ui/core";
import { Controller, useForm } from "react-hook-form";

import { EventType } from "types";

import { LabeledInput, MemberDialog } from "modules/MemberHome/View/shared";

import { InputWrapper, MemberDialogContentWrapper } from "modules/MemberHome/View/shared/style";
import { ButtonGroupButton, ButtonGroupWrapper } from "shared/Components/AddContentToSeries/style";

import { useCarpool, useDebounce, useEvent, useFeature, useUser, useVolunteers } from "shared/hooks";

import { EventModel, MediaModel } from "shared/types";

import SelectInput from "../SelectInput/index";

interface CreateRequestsDialogProps {
	open: boolean;
	onClose: () => void;
	isCarpoolPage?: boolean;
	eventDetail?: EventModel | null;
}

enum TypeEnum {
	RequestRide,
	OfferRide,
	VolunteerSlot
}

const CreateRequestsDialog: FC<CreateRequestsDialogProps> = ({ open, onClose, isCarpoolPage = false, eventDetail }) => {
	const [type, setType] = useState<TypeEnum>(TypeEnum.RequestRide);
	const [events, setEvents] = useState<EventModel[]>([]);
	const [volunteerEvents, setVolunteerEvents] = useState<EventModel[]>([]);
	const [keyword, setKeyword] = useState("");
	const { loadSuggestedLocations, isPrivilegedRole, getData: getUserData } = useUser();
	const { suggestedLocations, user } = getUserData();

	const { isCommunityFeatureEnabled } = useFeature();

	const { createCarpool, getData: getCarpoolData } = useCarpool();
	const { creating: carpoolCreating } = getCarpoolData();
	const { createVolunteerSlots, getData: getVolunteerData } = useVolunteers();
	const { creating: volunteerCreating } = getVolunteerData();

	const debouncedKeyword = useDebounce(keyword, 500);
	const { getEvents } = useEvent();

	useEffect(() => {
		if (open) {
			setType(TypeEnum.RequestRide);
		}
	}, [open]);

	const {
		control,
		handleSubmit,
		setValue,
		watch,
		formState: { isDirty, errors, isValid }
	} = useForm({ mode: "onChange" });
	const { event } = watch();

	const isCarpoolType = useMemo(() => [TypeEnum.RequestRide, TypeEnum.OfferRide].includes(type), [type]);

	const eventList = useMemo(
		() =>
			!isCarpoolType
				? volunteerEvents.map(e => ({
						label: e.title,
						value: JSON.stringify(e)
				  }))
				: events.map(e => ({
						label: e.title,
						value: JSON.stringify(e)
				  })),
		[events, isCarpoolType, volunteerEvents]
	);

	const fetchEvents = useCallback(
		async keyword => {
			const data = await getEvents({
				limit: 10,
				keyword,
				eventSchedule: EventType.All,
				page: 1,
				eventType: "offline",
				isLoading: false
			});

			setEvents(data.events);
		},
		[getEvents]
	);

	const fetchVolunteerEvents = useCallback(async () => {
		const data = await getEvents({
			limit: 10,
			eventSchedule: EventType.All,
			page: 1,
			eventType: isPrivilegedRole ? "all" : "myadminevents",
			isLoading: false
		});

		setVolunteerEvents(data.events);
	}, [getEvents, setVolunteerEvents, isPrivilegedRole]);

	useEffect(() => {
		fetchVolunteerEvents();
	}, [fetchVolunteerEvents]);

	useEffect(() => {
		setValue("event", undefined, { shouldDirty: true, shouldValidate: true });
	}, [isCarpoolType, setValue]);

	useEffect(() => {
		if (!isCarpoolType) {
			fetchVolunteerEvents();
		}
	}, [fetchVolunteerEvents, isCarpoolType]);

	useEffect(() => {
		if (isCarpoolType) {
			fetchEvents(debouncedKeyword);
		}
	}, [fetchEvents, fetchVolunteerEvents, debouncedKeyword, isCarpoolType]);

	const onSubmit = useCallback(
		async data => {
			const event: EventModel = eventDetail || JSON.parse(data.event.value);
			if (type !== 2)
				createCarpool(
					{
						type: type === 0 ? "request" : "offer",
						seats: Number(data.quantity),
						eventId: event.eventId,
						location: {
							name: data.location.label,
							address: data.location.label,
							placeId: data.location.value
						},
						description: data.description
					},
					event,
					user?.profiles ? (user?.profiles[0]?.photos[0] as MediaModel)?.profilePicture || "" : "",
					{
						firstName: user?.profiles[0].firstName || "",
						lastName: user?.profiles[0].lastName || "",
						personaId: user?.activeProfile || 0
					},
					false,
					onClose,
					isCarpoolPage
				);
			else if (type === 2) {
				createVolunteerSlots(
					{
						quantity: Number(data.quantity),
						need: data.need,
						description: data.description
					},
					event.eventId,
					onClose,
					event
				);
			}
		},
		[
			createCarpool,
			type,
			createVolunteerSlots,
			onClose,
			user?.profiles,
			isCarpoolPage,
			eventDetail,
			user?.activeProfile
		]
	);

	const customHandleSubmit = useCallback(
		e => {
			e?.preventDefault && e.preventDefault();
			e?.stopPropagation && e.stopPropagation();
			handleSubmit(onSubmit)();
		},
		[handleSubmit, onSubmit]
	);

	return (
		<>
			<MemberDialog
				customWidth={512}
				title="Create"
				open={open}
				onClose={onClose}
				confirmLeave={isDirty}
				modalHeight={600}
				footerPrimary={{
					text:
						type === TypeEnum.OfferRide
							? "Create Offer"
							: type === TypeEnum.RequestRide
							? "Create Request"
							: "Create Slot",
					disabled: !isDirty || !!Object.keys(errors).length || !isValid || carpoolCreating || volunteerCreating,
					loading: carpoolCreating || volunteerCreating,
					onClick: handleSubmit(onSubmit)
				}}
			>
				<form onSubmit={customHandleSubmit}>
					<MemberDialogContentWrapper className="pv-20">
						<ButtonGroupWrapper
							className="rounded-lg"
							grid={
								!isCarpoolPage &&
								(!!volunteerEvents.length || isPrivilegedRole) &&
								isCommunityFeatureEnabled("enableVolunteers") &&
								isCommunityFeatureEnabled("enableCarpool")
									? 3
									: isCommunityFeatureEnabled("enableCarpool")
									? 2
									: 1
							}
						>
							{isCommunityFeatureEnabled("enableCarpool") && (
								<>
									<ButtonGroupButton
										id="selectVideos"
										onClick={() => setType(TypeEnum.RequestRide)}
										active={type === TypeEnum.RequestRide}
										className="rounded-lg"
									>
										Request a Ride
									</ButtonGroupButton>
									<ButtonGroupButton
										id="selectTracks"
										onClick={() => setType(TypeEnum.OfferRide)}
										active={type === TypeEnum.OfferRide}
										className="rounded-lg"
									>
										Offer a Ride
									</ButtonGroupButton>
								</>
							)}
							{!isCarpoolPage &&
								(!!volunteerEvents.length || isPrivilegedRole) &&
								isCommunityFeatureEnabled("enableVolunteers") && (
									<ButtonGroupButton
										id="selectFiles"
										onClick={() => {
											if (isCommunityFeatureEnabled("enableVolunteers") && isCommunityFeatureEnabled("enableCarpool")) {
												setType(TypeEnum.VolunteerSlot);
											}
										}}
										active={
											(isCommunityFeatureEnabled("enableVolunteers") && !isCommunityFeatureEnabled("enableCarpool")) ||
											type === TypeEnum.VolunteerSlot
										}
										className="rounded-lg"
									>
										Volunteer Slot
									</ButtonGroupButton>
								)}
						</ButtonGroupWrapper>
						<Box className="mb-4">
							{!isCarpoolPage && (
								<Controller
									name="event"
									variant="outlined"
									rules={{
										required: "Event is required!",
										validate: val => {
											if (!val.value || !val.label) {
												return "Please choose a event option";
											}
										}
									}}
									control={control}
									render={({ onChange, value }) => (
										<SelectInput
											label="Select Event *"
											name="event"
											value={event ? value && value.label : ""}
											onChange={onChange}
											loadSuggestions={val => setKeyword(val)}
											placeholder=" "
											options={eventList}
											maxHeight={180}
											error={errors?.event?.message}
										/>
									)}
								/>
							)}
						</Box>
						{type === TypeEnum.VolunteerSlot && (
							<Controller
								name="need"
								control={control}
								rules={{
									required: "Need is required",
									maxLength: {
										value: 40,
										message: "Need can't be more than 40 letters"
									}
								}}
								render={({ onChange, value, ref }) => (
									<InputWrapper>
										<LabeledInput
											label="Need *"
											name="need"
											value={value}
											onChange={onChange}
											placeholder="Ex. Snack bar helper"
											error={errors?.need?.message}
											showCounter={40}
											inputRef={ref}
										/>
									</InputWrapper>
								)}
							/>
						)}
						<Controller
							name="quantity"
							control={control}
							rules={
								[0, 1].includes(type)
									? {
											required: "Quantity is required",
											max: {
												value: 10,
												message: "Quantity can't be more than 10"
											}
									  }
									: { required: "Quantity is required" }
							}
							render={({ onChange, value, ref }) => (
								<InputWrapper>
									<LabeledInput
										label="Quantity *"
										name="quantity"
										value={value}
										onChange={onChange}
										placeholder=" "
										error={errors?.quantity?.message}
										inputRef={ref}
										type="number"
										numbersOnly
									/>
								</InputWrapper>
							)}
						/>
						{(type === TypeEnum.RequestRide || type === TypeEnum.OfferRide) && (
							<Controller
								name="location"
								control={control}
								rules={{
									required: "Location is required",
									validate: val => (!val.value || !val.label ? "Please choose a location option" : true)
								}}
								render={({ onChange, value, ref }) => (
									<InputWrapper>
										<SelectInput
											name="location"
											onChange={onChange}
											placeholder=" "
											label={type === TypeEnum.RequestRide ? "Pick Up Location *" : "Your Address *"}
											value={value ? value.label : null}
											loadSuggestions={val => loadSuggestedLocations(val)}
											maxHeight={260}
											error={errors?.location?.message}
											noOptionsHeadline="Type your location ( ex: New York )"
											options={
												suggestedLocations
													? suggestedLocations.map(suggestion => ({
															value: suggestion.place_id,
															label: suggestion.name
													  }))
													: []
											}
											selectInputRef={ref}
										/>
									</InputWrapper>
								)}
							/>
						)}
						<Controller
							name="description"
							control={control}
							rules={{
								maxLength: {
									value: isCarpoolType ? 400 : 1000,
									message: `${isCarpoolType ? "Notes" : "Description"} can't be more than ${
										isCarpoolType ? "400" : "1000"
									} letters`
								}
							}}
							render={({ onChange, value, ref }) => (
								<InputWrapper>
									<LabeledInput
										label={isCarpoolType ? "Notes" : "Description"}
										name="description"
										value={value}
										onChange={onChange}
										placeholder=" "
										showCounter={isCarpoolType ? 400 : 1000}
										error={errors?.description?.message}
										inputRef={ref}
										textarea
									/>
								</InputWrapper>
							)}
						/>
					</MemberDialogContentWrapper>
				</form>
			</MemberDialog>
		</>
	);
};

export default CreateRequestsDialog;
