import React, { ReactNode, useCallback } from "react";

import { Autocomplete, AutocompleteChangeDetails, AutocompleteInputChangeReason } from "@material-ui/lab";

// import { ReactComponent as LoaderIcon } from "assets/icons/animated/loader.svg";

import { Text } from "shared/ui-kit";

import { DropdownOptionModel } from "./Dropdown";
import { UIKitInput } from "./style";

import { SizesEnum } from "../../types";

import { BaseInputProps } from "./index";

export interface SearchProps extends BaseInputProps {
	options: (DropdownOptionModel | any)[];
	customDropdownItemRender?: (item: any) => ReactNode;
	onInputChange?: (event: any, value: string, reason: AutocompleteInputChangeReason) => void;
	onChange: (
		value: DropdownOptionModel | any | Array<DropdownOptionModel | any>,
		event: any,
		reason: string,
		details?: AutocompleteChangeDetails<any>
	) => void;
	getOptionLabel?: (option: DropdownOptionModel | any) => string;
	preventAutoselect?: boolean;
	filterOptions?: (options: any[], state: any) => any[];
	freeSolo?: boolean;
	includeInputInList?: boolean;
	filterSelectedOptions?: boolean;
}

const Search: React.FC<SearchProps> = ({
	options,
	error,
	// errorText,
	onBlur,
	placeholder,
	customDropdownItemRender,
	LeftSideIcon,
	RightSideIcon,
	loading,
	getOptionLabel,
	preventAutoselect = false,
	size = SizesEnum.MD,
	filterOptions,
	label,
	onChange,
	...rest
}) => {
	const [open, setOpen] = React.useState(false);

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const getDefaultOptionLabel = useCallback((option: DropdownOptionModel | any): string => option?.label || "", []);

	const handleChange = useCallback(
		(
			event: any,
			value: DropdownOptionModel | any | Array<DropdownOptionModel | any>,
			reason: string,
			details?: AutocompleteChangeDetails<any>
		) => {
			onChange(value, event, reason, details);
		},
		[onChange]
	);

	return (
		<Autocomplete
			options={options}
			popupIcon={""}
			disableClearable
			openOnFocus
			open={open}
			onOpen={handleOpen}
			onClose={handleClose}
			filterOptions={filterOptions}
			getOptionLabel={getOptionLabel || getDefaultOptionLabel}
			renderOption={(option, state) => (
				<li
					{...state}
					key={option?.id}
					onClick={params => (preventAutoselect ? undefined : option.onClick && option.onClick(params))}
				>
					{customDropdownItemRender ? customDropdownItemRender(option) : <Text>{option?.label}</Text>}
				</li>
			)}
			renderInput={params => (
				<UIKitInput
					{...params}
					error={error}
					onBlur={onBlur}
					// helperText={errorText}
					inputProps={{
						...params.inputProps,
						placeholder,
						size
					}}
					className={`size_${size}`}
					label={label}
					variant={"outlined"}
					InputProps={{
						...params.InputProps,
						startAdornment: LeftSideIcon && <div className="mr-1">{LeftSideIcon}</div>,
						endAdornment:
							loading || RightSideIcon ? <div className="-mr-6">{loading ? <></> : RightSideIcon}</div> : undefined
					}}
				/>
			)}
			onChange={handleChange}
			{...rest}
		/>
	);
};

export default Search;
