import config from "config/appConfig";

import { VideoModel } from "shared/types";
import { IStateProps } from "utils/StateOf";

import createStore from "utils/createStore";

const localStorageKey = `${config.GLOBAL_CONSTANTS.LOCAL_STORAGE_PREFIX}${config.HOOK_STORAGE_KEYS.VIDEO_DATA}`;

export interface Values {
	loading: boolean;
	filteredVideos: any[];
	videoCount: number;
	allVideoCount: number;
	page: number;
	videosShowPerPage: number;
	videoInfoPopup: {
		open: boolean;
		model?: VideoModel;
	};
	videoUploadUrl: string;
	videoFileUrl: string;
	creating: boolean;
	updating: boolean;
	videoName: string;
	videoDuration?: string;
}

export const initialState: IStateProps<Values> = {
	videoUploadUrl: "",
	filteredVideos: [],
	loading: false,
	videoCount: 0,
	allVideoCount: 0,
	page: 1,
	videosShowPerPage: 10,
	videoFileUrl: "",
	videoInfoPopup: {
		open: false
	},
	creating: false,
	updating: false,
	videoName: "",
	videoDuration: ""
};

export default createStore<Values>({
	initialState,
	persistant: true,
	persistantName: localStorageKey
});
