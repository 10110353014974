import React from "react";

import { view } from "modules/App";

import { routes } from "../../constants";

const ForgotPassword = () => (
	<view.ForgotPassword
		resetPasswordLink={routes.auth.resetPasswordLink.getPath()}
		signInLink={routes.auth.signIn.getPath()}
	/>
);

export default ForgotPassword;
