import { Box, ButtonBase } from "@material-ui/core";
import styled from "styled-components";

export const OperationBox = styled(Box)`
	display: flex;
	justify-content: flex-end;
	align-items: center;
`;

OperationBox.ItemWrapper = styled(Box)`
	&:not(:last-child) {
		margin-right: 8px;
	}
`;

OperationBox.Item = styled(ButtonBase)`
	width: 40px;
	height: 40px;

	border-radius: 8px;

	overflow: hidden;

	position: relative;

	&:hover {
		background-color: #444;
	}

	svg {
		&.fill-second,
		&.active {
			path {
				&:nth-child(2) {
					fill: #6173fe;
				}
			}
		}
	}
`;

OperationBox.ItemBadge = styled(Box)`
	width: fit-content;
	height: 15px;

	border-radius: 8px;

	overflow: hidden;

	background-color: #2f2f2f;

	font-size: 10px;
	font-weight: 600;
	text-align: center;
	color: #fff;

	position: absolute;
	top: 1px;
	right: 1px;

	padding: 0 5px;
`;
