import React, { useCallback, useEffect, useMemo, useState } from "react";

import { DateTime } from "luxon";
import { FileType } from "types/FilesContextValuesType";

import { getFileExtension } from "types/getFileExtension";

import { CreateFileDialog, MenuDots, PlaceholderImage, TableWrapper } from "shared/Components";
import { TableColumnLoaderType } from "shared/Components/NewTable/Components";
import { Cell } from "shared/Components/NewTable/style";
import { useDebounce, useFiles, useNotification, usePremiumContent } from "shared/hooks";
import { useS3Uploader } from "shared/services/s3Uploader";
import { PlaceholderImageType } from "shared/types";

import { formatCount } from "utils/serviceUtils/helpers";

import { ConfirmDelete, PageTemplate } from "../../Components";

const Table = React.lazy(() => import("shared/Components/NewTable"));

const ManageFiles = () => {
	const [searchText, setSearchText] = useState("");
	const { showMessage } = useNotification();
	const { uploadFile, getData: getS3UploaderData } = useS3Uploader();
	const { uploading } = getS3UploaderData();
	const {
		getFiles,
		setPage,
		setFilesShowPerPage,
		getAllFilesCount,
		setFileName,
		setFileUrl,
		setFileExtension,
		setDeleteFileDetails,
		deleteFile,
		setFileInfoPopup,
		getData: getFilesData
	} = useFiles();
	const { filesTotalCount, page, filesShowPerPage, isLoading, files, deleteFileDetails, fileInfoPopup, fileUploadUrl } =
		getFilesData();

	const { getCoinPrice } = usePremiumContent();

	const debouncedSearchText = useDebounce(searchText, 400);

	useEffect(() => {
		getFiles({ limit: filesShowPerPage, offset: page, keyword: debouncedSearchText });
	}, [debouncedSearchText, filesShowPerPage, getFiles, page]);

	useEffect(() => {
		getAllFilesCount({});
	}, [getAllFilesCount]);

	const handleSearch = useCallback((val: string) => {
		if (val === "" || val.length >= 3) {
			setSearchText(val);
		}
	}, []);

	const getOptions = useCallback(
		(rowData: FileType, rowIndex: number) => {
			const options: {
				name: string;
				onClick: () => void;
				id: string;
			}[] = [
				{
					name: "Edit File Details",
					onClick: () => setFileInfoPopup({ open: true, model: rowData }),
					id: `editFile_${rowIndex + 1}`
				},
				{
					name: "Delete",
					onClick: () =>
						setDeleteFileDetails({
							id: rowData._id,
							name: rowData.title
						}),
					id: `deleteFile_${rowIndex + 1}`
				}
			];

			return options;
		},
		[setDeleteFileDetails, setFileInfoPopup]
	);

	const tableColumns = useMemo(
		() => [
			{
				minWidth: 240,
				alignment: "left",
				label: <Cell.HeaderText>{formatCount(filesTotalCount, "File")}</Cell.HeaderText>,
				Cell: ({ rowData: { title, coverPhoto, filecreator } }: { rowData: FileType }) => (
					<Cell.Wrapper>
						<Cell.ImageWrapper>
							{coverPhoto?.url ? (
								<Cell.Image src={coverPhoto.url} alt={title} />
							) : (
								<PlaceholderImage
									type={PlaceholderImageType.FILE_PREVIEW}
									width={40}
									height={40}
									viewBox={"0 0 400 400"}
								/>
							)}
						</Cell.ImageWrapper>
						<Cell.Wrapper className="column with-image">
							<Cell.Text>{title}</Cell.Text>
							{filecreator?.length && <Cell.Text className="light">By {filecreator[0]?.firstName}</Cell.Text>}
						</Cell.Wrapper>
					</Cell.Wrapper>
				),
				loaderTemplate: TableColumnLoaderType.imageWthTwoTextRows,
				dataKey: "fileName"
			},
			{
				minWidth: 150,
				alignment: "left",
				label: <Cell.HeaderText>Category</Cell.HeaderText>,
				Cell: ({ rowData: { category } }: { rowData: FileType }) => (
					<Cell.Wrapper>
						<Cell.Text>{category?.name || "-"}</Cell.Text>
					</Cell.Wrapper>
				),
				dataKey: "category"
			},
			// {
			// 	minWidth: 150,
			// 	alignment: "left",
			// 	label: <Cell.HeaderText>Collection/Series</Cell.HeaderText>,
			//
			// 	Cell: () => <ColumnText>-</ColumnText>,
			// 	dataKey: "series"
			// },
			{
				minWidth: 100,
				alignment: "left",
				label: <Cell.HeaderText>Price</Cell.HeaderText>,
				Cell: ({ rowData: { priceTags } }: { rowData: FileType }) => {
					const price = getCoinPrice(priceTags);
					return (
						<Cell.Wrapper>
							<Cell.Text>{price ? `${price} Coins` : "Free"}</Cell.Text>
						</Cell.Wrapper>
					);
				},
				dataKey: "price"
			},
			{
				minWidth: 150,
				alignment: "left",
				label: <Cell.HeaderText>Created Date</Cell.HeaderText>,
				Cell: ({ rowData: { createdAt } }: { rowData: FileType }) => (
					<Cell.Wrapper className="column">
						<Cell.Text>{DateTime.fromISO(createdAt).toFormat("MMM d, yyyy")}</Cell.Text>
						<Cell.Text>{DateTime.fromISO(createdAt).toFormat("t")}</Cell.Text>
					</Cell.Wrapper>
				),
				loaderTemplate: TableColumnLoaderType.twoTextRows,
				dataKey: "menu"
			},
			{
				width: 50,
				alignment: "right",
				label: "",
				Cell: ({ rowData, rowIndex }) => (
					<MenuDots
						options={getOptions(rowData, rowIndex)}
						vertical
						removeBg
						removeshadow
						removeSideMargin
						menuId={`moreAction_${rowIndex + 1}`}
					/>
				),
				loaderTemplate: TableColumnLoaderType.menuDots,
				dataKey: "menu"
			}
		],
		[filesTotalCount, getOptions, getCoinPrice]
	);

	const handleUpload = useCallback(() => {
		const el: any = document.getElementById("trackFileUrl");
		if (el) {
			el.value = null;
			el.click();
		}
	}, []);

	const onUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { target } = e;
		const { files } = target;

		if (files?.length) {
			const extObj = getFileExtension(files[0].name);

			if (!extObj) {
				showMessage("We don't accept this file type, please try with a different file.");
				return;
			}

			uploadFile({
				file: files[0],
				communityName: fileUploadUrl,
				checkProgress: true,
				customCallback: data => setFileUrl(data?.publicUrl)
			});

			setFileName(files && files[0].name.slice(0, files[0].name.lastIndexOf(".")));

			setFileInfoPopup({ open: true, model: undefined });

			setFileExtension(extObj.ext);
		}
	};

	const handleChangeRowsPerPage = useCallback(
		(newPageSize: number) => {
			setFilesShowPerPage(newPageSize);
			setPage(1);
		},
		[setFilesShowPerPage, setPage]
	);

	const TableBlock = useMemo(
		() => (
			<TableWrapper sizes={{ horizontalTablet: 900 }}>
				<Table
					columns={tableColumns}
					data={files || []}
					loading={isLoading}
					paginated
					totalDataCount={filesTotalCount}
					page={page}
					pageSize={filesShowPerPage}
					onChangePage={setPage}
					onChangePageSize={handleChangeRowsPerPage}
				/>
			</TableWrapper>
		),
		[tableColumns, files, filesTotalCount, isLoading, page, filesShowPerPage, handleChangeRowsPerPage, setPage]
	);

	return (
		<>
			<input id="trackFileUrl" type="file" onChange={onUpload} name="trackFileUrl" hidden accept="*" />
			<PageTemplate
				title={"Files"}
				showReportedLink
				isLoading={isLoading}
				isNoData={!files || !files.length}
				emptyText={"You don’t have any Files in your community yet."}
				searchPlaceholder={"Search Documents"}
				onSearchUpdate={handleSearch}
				actionText={"Create File"}
				onCreateClick={handleUpload}
				isDisabledCreateAction={uploading && fileInfoPopup.open}
				actionId="createFile"
				searchId="searchFile"
			>
				{TableBlock}
			</PageTemplate>
			{fileInfoPopup?.open && (
				<CreateFileDialog
					open
					editableModel={fileInfoPopup.model}
					onClose={e => {
						setFileInfoPopup({ open: false });
						if (e.created) {
							getFiles({ limit: filesShowPerPage, offset: page, keyword: debouncedSearchText });
						}
					}}
				/>
			)}
			{/*{(createFileDialog.open || editFileDialog) && <CreateFileDialog open={true} />}*/}
			{deleteFileDetails && (
				<ConfirmDelete
					name={deleteFileDetails.name}
					headline="File"
					onClose={() => setDeleteFileDetails()}
					onDelete={async () => {
						await deleteFile(deleteFileDetails.id);
						getFiles({ limit: filesShowPerPage, offset: page, keyword: debouncedSearchText });
					}}
					deleteBtnId="deleteConfirmation"
				/>
			)}
		</>
	);
};

export default ManageFiles;
