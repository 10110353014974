import React from "react";

import CircularProgress from "@material-ui/core/CircularProgress";
import { FileDrop } from "react-file-drop";

import { useEvent } from "shared/hooks";
import { useS3Uploader } from "shared/services/s3Uploader";
import { Icon } from "shared/ui-kit";
import { getVideoImage } from "utils/getVideoImage";
import { toBase64, uuidv4 } from "utils/serviceUtils/helpers";

import {
	AddFile,
	AttachmentImage,
	AttachmentList,
	AttachmentRemoveButton,
	AttachmentThumbnail,
	AttachmentThumbnailInner,
	AttachmentThumbnailProgressWrapper,
	AttachmentUploadProgress,
	Wrapper
} from "./styles";

interface UploadedFile {
	fileName?: string;
	publicUrl: string;
}

interface Attachment {
	loadingThumbnail: boolean;
	loading: boolean;
	base64?: string;
	fileName?: string;
	publicUrl?: string;
	file?: File;
}

type AttachmentId = string;

type AttachmentsState = Record<AttachmentId, Attachment>;

interface Props {
	onChange: (files: UploadedFile[]) => void;
	value: UploadedFile[];
}

export function ReportBugAttachments(props: Props) {
	const { onChange, value } = props;
	const [attachments, setAttachments] = React.useState<AttachmentsState | null>(null);

	const { getData: getS3UploaderData, uploadFile } = useS3Uploader();
	const { getData: getEventData } = useEvent();
	const { uploading } = getS3UploaderData();
	const { eventImageUploadUrl } = getEventData();

	const handleChange = async (files: FileList | null) => {
		if (files) {
			const fileList = Array.from(files);
			for (const file of fileList) {
				const fileId = uuidv4();
				const fileReader = new FileReader();
				setAttachments(prevState => ({
					...prevState,
					[fileId]: {
						loadingThumbnail: true,
						loading: true,
						file
					}
				}));
				fileReader.addEventListener("load", async e => {
					let base64 = e.target?.result || "";
					if (file.type === "video/mp4") {
						try {
							const cover = await getVideoImage(file, 1.5);
							base64 = (await toBase64(cover as File)) as string;
						} catch (err) {}
					}

					if (e.target) {
						setAttachments(prevState => {
							const newState = {
								...prevState,
								[fileId]: {
									file: prevState?.[fileId].file || null,
									loadingThumbnail: false,
									loading: true,
									base64
								}
							};
							return newState;
						});
					}
				});
				fileReader.readAsDataURL(file);
			}
		}
	};

	const handleRemoveAttachmentButtonClick = (fileId: string) => {
		setAttachments(prevState => {
			if (prevState) {
				const newState = { ...prevState };
				delete newState[fileId];
				return newState;
			}
			return prevState;
		});
	};

	React.useEffect(() => {
		const uploadAttachments = async () => {
			for (const attachmentId in attachments) {
				const attachment = attachments[attachmentId];
				if (attachment.file && !attachment.publicUrl) {
					const res = await uploadFile({
						file: attachment.file,
						communityName: eventImageUploadUrl
					});
					setAttachments(prevState => {
						const newState = {
							...prevState,
							[attachmentId]: {
								loadingThumbnail: false,
								loading: false,
								base64: prevState ? prevState[attachmentId].base64 : "",
								fileName: res?.fileName || "",
								publicUrl: res?.publicUrl || ""
							}
						};
						return newState;
					});
				}
			}
		};
		uploadAttachments();
	}, [attachments, eventImageUploadUrl, uploadFile]);

	React.useEffect(() => {
		const uploadedAttachments: UploadedFile[] = [];
		if (attachments && !!Object.keys(attachments).length) {
			for (const attachmentId in attachments) {
				const attachment = attachments[attachmentId];
				if (attachment.fileName && attachment.publicUrl) {
					uploadedAttachments.push({
						fileName: attachment.fileName,
						publicUrl: attachment.publicUrl
					});
				}
			}
			if (!!uploadedAttachments.length) onChange(uploadedAttachments);
		} else onChange(uploadedAttachments);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [attachments]);

	React.useEffect(() => {
		if (!value.length) setAttachments(null);
	}, [value.length]);

	const fileClickHandler = (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
		const element = e.target as HTMLInputElement;
		element.value = "";
	};

	return (
		<Wrapper>
			<FileDrop onDrop={handleChange}>
				<AddFile disabled={uploading}>
					<input
						onChange={e => handleChange(e.target.files)}
						type="file"
						multiple
						hidden
						disabled={uploading}
						onClick={fileClickHandler}
					/>
					<Icon group="filled" width="24px" height="24px" fill="#8f9bb3" name="plus" />
				</AddFile>
			</FileDrop>
			{attachments && (
				<AttachmentList>
					{Object.keys(attachments).map(attachmentId => {
						const attachment = attachments[attachmentId];
						return (
							<AttachmentThumbnail key={attachmentId}>
								<AttachmentThumbnailInner>
									{attachment.loadingThumbnail && (
										<AttachmentThumbnailProgressWrapper>
											<CircularProgress size={24} />
										</AttachmentThumbnailProgressWrapper>
									)}
									{!attachment.loadingThumbnail && attachment.base64 && <AttachmentImage src={attachment.base64} />}
								</AttachmentThumbnailInner>
								{attachment.loading ? (
									<AttachmentUploadProgress>
										<CircularProgress size={12} />
									</AttachmentUploadProgress>
								) : (
									<AttachmentRemoveButton type="button" onClick={() => handleRemoveAttachmentButtonClick(attachmentId)}>
										<Icon group="filled" width="20px" height="20px" name="close-circle" />
									</AttachmentRemoveButton>
								)}
							</AttachmentThumbnail>
						);
					})}
				</AttachmentList>
			)}
		</Wrapper>
	);
}
