import React, { FC, useMemo, useRef, useState } from "react";

import { Controller, useForm } from "react-hook-form";

import { FooterWrapper } from "modules/Manage/View/Containers/style";
import { useCategories } from "modules/Marketplace/Data";
import { EditCategoryProps, NewCategoryProps } from "modules/Marketplace/Data/types";

import { Dialog } from "shared/Components";
import ConfirmLeavePopup from "shared/Components/ConfirmLeave";
import useConfirmLeavePopup from "shared/Components/ConfirmLeave/hooks/useConfirmLeavePopup";
import useScrollToXPosition from "shared/hooks/useScrollToXPosition";
import { useS3Uploader } from "shared/services/s3Uploader";
import { Icon, Input, Text, Uploader, orientationConst } from "shared/ui-kit";

import { capitalizeFirstLetter } from "utils/capitaliseFirstLetter";
import { dataUrlToFile } from "utils/serviceUtils/helpers";

import { CTAButton, InputWrapper } from "../style";

const CreateCategoryDialog: FC<{ onCreate: () => void }> = ({ onCreate }) => {
	const bodyRef = useRef<HTMLFormElement>();
	const scrollToXPositionOnFocus = useScrollToXPosition(bodyRef?.current);

	const [imageUploading, setImageUploading] = useState(false);

	const {
		handleSubmit,
		control,
		watch,
		formState: { errors, isDirty }
	} = useForm({
		mode: "onChange"
	});

	const { uploadFile } = useS3Uploader();
	const { setCreateUpdateCategoryOpen, createCategory, updateCategory, getData: getCategoriesData } = useCategories();
	const { categoryImageUploadUrl, createUpdateCategoryOpen, editObject, isLoading } = getCategoriesData();

	const isEditMode = !!editObject;

	const onSubmit = async data => {
		const { _id, categoryTitle, coverUrl, shortDescription } = data;

		if (isEditMode) {
			const category: EditCategoryProps = {
				_id,
				name: categoryTitle,
				photo: coverUrl,
				photoChanged: editObject?.photo !== coverUrl,
				description: shortDescription
			};

			await updateCategory(category);
		} else {
			const category: NewCategoryProps = {
				name: categoryTitle,
				photo: coverUrl,
				description: shortDescription
			};

			await createCategory(category);
			onCreate();
		}

		setCreateUpdateCategoryOpen({
			status: false,
			editObject: undefined
		});
	};

	const { coverUrl, categoryTitle } = watch(["coverUrl", "categoryTitle"]);

	const isDisabled = isEditMode ? !isDirty && (!coverUrl || !categoryTitle) : !coverUrl || !categoryTitle;

	const displayErrorMessage = useMemo(
		() => (error?: { type: string; message?: string }) => {
			if (!error) return;
			const { message, type } = error;
			if (message) return message;
			if (type === "required") return "Field is required.";
			if (type === "maxLength") return "Max length exceeded for this field.";
		},
		[]
	);

	const onClose = () =>
		setCreateUpdateCategoryOpen({
			status: false,
			editObject: undefined
		});

	const {
		handleLeavePageConfirmed,
		closeConfirmPopup,
		handleClose,
		getData: getConfirmLeavePopupData
	} = useConfirmLeavePopup({
		onClose,
		open: createUpdateCategoryOpen
	});
	const { showConfirmPopup } = getConfirmLeavePopupData();

	return (
		<>
			<ConfirmLeavePopup
				handleLeavePage={handleLeavePageConfirmed}
				open={showConfirmPopup}
				onClose={closeConfirmPopup}
				popup
			/>
			<Dialog
				title={<Text variant="h5">{isEditMode ? "Edit" : "Create"} Category</Text>}
				open={createUpdateCategoryOpen}
				onClose={() => handleClose(isDirty)}
				hasBackButton={false}
				bodyRef={bodyRef}
				footer={
					<FooterWrapper className={isEditMode ? "cancelBtn" : ""}>
						{isEditMode && (
							<CTAButton onClick={() => handleClose(isDirty)} buttonTheme="outline">
								Cancel
							</CTAButton>
						)}
						<CTAButton
							id={isEditMode ? "editCategory" : "createCategory"}
							disabled={imageUploading || isLoading || isDisabled || Object.keys(errors).length}
							type="submit"
							onClick={handleSubmit(onSubmit)}
						>
							{imageUploading
								? "Image uploading.."
								: isLoading
								? "Loading.."
								: `${isEditMode ? "Edit" : "Create"} Category`}
						</CTAButton>
					</FooterWrapper>
				}
			>
				<form onSubmit={handleSubmit(onSubmit)}>
					<Controller name="_id" defaultValue={isEditMode && editObject?._id} control={control} render={() => <></>} />
					<Controller
						name="coverUrl"
						control={control}
						rules={{
							required: "Cover is required."
						}}
						defaultValue={isEditMode && editObject?.photo}
						render={({ onChange, value }) => {
							const error = displayErrorMessage(errors.coverUrl);
							return (
								<>
									<Uploader
										urls={!value && isEditMode && editObject?.photo ? [editObject?.photo] : value ? [value] : undefined}
										onChange={async (files: any[]) => {
											const img = files && files.length ? files[0] : "";
											if (img) {
												setImageUploading(true);
												const correctFile = typeof img === "string" ? await dataUrlToFile(img, "test") : img;
												const data = await uploadFile({
													file: correctFile as File,
													communityName: categoryImageUploadUrl
												});
												onChange(data?.publicUrl);
												setImageUploading(false);
											} else onChange("");
										}}
										label="Upload Category Photo"
										orientation={orientationConst.horizontal}
										width={"100%"}
										description={
											<>
												Drag and Drop File Here or <span className="anchor">Browse</span> to Choose a File
											</>
										}
										accept={[
											{
												fileType: "image/png, image/jpeg, image/x-eps",
												name: "png, jpg, eps"
											}
										]}
										icon={
											<Icon group="" fill="#c5cee0" name="cloud-upload" width={64} height={50} viewBox="4 2 18 19" />
										}
									/>
									{error && <span className="error">{error}</span>}
								</>
							);
						}}
					/>
					<InputWrapper>
						<Controller
							name="categoryTitle"
							control={control}
							defaultValue={editObject?.name ? editObject?.name : ""}
							rules={{
								required: true,
								maxLength: 100
							}}
							render={({ onChange, value }) => {
								const error = displayErrorMessage(errors.categoryTitle);
								return (
									<Input
										id="categoryTile"
										errorText={error}
										onChange={e => {
											const val = capitalizeFirstLetter(e.target.value);
											onChange(val);
										}}
										value={value}
										label="Category title*"
										onFocus={scrollToXPositionOnFocus}
									/>
								);
							}}
						/>
					</InputWrapper>
					<InputWrapper>
						<Controller
							name="shortDescription"
							control={control}
							defaultValue={editObject?.description ? editObject?.description : ""}
							rules={{
								maxLength: 200
							}}
							render={({ onChange, value }) => {
								const error = displayErrorMessage(errors.shortDescription);
								return (
									<Input
										id="categoryDescription"
										errorText={error}
										onChange={onChange}
										value={value}
										label="Short description"
										onFocus={scrollToXPositionOnFocus}
									/>
								);
							}}
						/>
					</InputWrapper>
				</form>
			</Dialog>
		</>
	);
};

export default CreateCategoryDialog;
