import styled from "styled-components";

import { Box, Button, Text } from "shared/ui-kit";

export const StreamWrapper = styled(Box)`
	width: 560px;
	height: 312px;

	margin: 72px auto 0 auto;

	border-radius: 12px;

	overflow: hidden;

	position: relative;

	video {
		height: 100%;
		width: 100%;

		transform: scaleX(-1);

		object-fit: cover;
		object-position: center;
	}
`;

export const InitiateConvo = styled(Box)``;

InitiateConvo.Wrapper = styled(Box)`
	display: flex;
	flex-direction: column;
	justify-content: center;

	min-height: 100%;

	padding: 88px 20px;
`;

InitiateConvo.Title = styled(Text)`
	font-size: 26px;
	font-weight: bold;
	line-height: 1.23;
	color: #222b45;
	text-align: center;
`;

InitiateConvo.Viewers = styled(Box)`
	display: flex;
	text-align: center;
	align-items: center;
	justify-content: center;
`;

export const ImageWrapper = styled(Box)`
	position: absolute;
	background: #e4e9f2;
	display: flex;
	justify-content: center;
	align-items: center;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	.MuiAvatar-circle {
		border-radius: 100px;
	}
`;

export const ActionWrapper = styled(Box)`
	display: flex;
	flex-direction: ${({ row }) => (row ? "row" : "column")};
	align-items: center;
`;

ActionWrapper.ActionBtn = styled(Box)`
	position: relative;
`;

ActionWrapper.Badge = styled(Box)`
	position: absolute;
	top: 5px;
	right: 5px;
	background: #2f2f2f;
	border-radius: 20px;
	min-height: 20px;
	min-width: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0 4px;
	font-size: 10px;
`;

export const MediaAction = styled(Box)``;

MediaAction.Wrapper = styled(Box)`
	display: flex;
	justify-content: center;

	margin: 32px 0 36px 0;
`;

MediaAction.ButtonWrapper = styled(Box)`
	margin: 0 8px;
`;

export const StyledBtn = styled(Button)`
	border-radius: 8px;

	margin: 4px 0;

	width: 172px;
`;
