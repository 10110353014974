import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import Skeleton from "@material-ui/lab/Skeleton";
import { DateTime } from "luxon";

import { useHistory } from "react-router-dom";

import { ReactComponent as VolunteerIcon } from "assets/icons/volunteer-slot.svg";
import { useFirebase, useMessaging } from "modules/Messaging/Data";
import { ConfirmActionModal, MenuDots, MenuDotsOption, PlaceholderImage } from "shared/Components";
import {
	useCarpool,
	useCommunity,
	useEvent,
	useFeature,
	useMemberRoutes,
	useOnScreen,
	useUser,
	useVolunteers
} from "shared/hooks";
import { MediaModel, PlaceholderImageType, ProfilePhotoSizes, VolunteerSlot } from "shared/types";
import { EventRequests, OfferRequest, VolunteerSlotType } from "shared/types/EventModel";
import { Box, ExpansionPanel, Icon, Text } from "shared/ui-kit";

import { getResizedImage } from "utils/serviceUtils/cdnImages";

import { formatCount } from "utils/serviceUtils/helpers";

import {
	Container,
	Divider,
	EventDate,
	EventDetail,
	RequestTemplateWrapper,
	SecondaryText,
	SkeletonBox
} from "./style";

import { FilterBlock } from "../../Components";
import SignUpVolunteer from "../../Components/EventDetails/components/SignUpVolunteer";
import { BookRequestDialog, CreateRequestsDialog, RequestCard, SignUpRequestDialog } from "../../shared";

const Requests = () => {
	const [expandedIndex, setExpandedIndex] = useState(-1);
	const [slot, setSlot] = useState<{ slot: VolunteerSlot; event: EventRequests }>();
	const [signUpDialog, setSignUpDialog] = useState<{
		open: boolean;
		eventDetail?: EventRequests;
		request?: OfferRequest;
	}>({
		open: false
	});
	const [bookDialog, setBookDialog] = useState<{
		open: boolean;
		editDetails?: {
			personaDocId: string;
			seatsBooked: number;
		};
		eventDetail?: EventRequests;
		request?: OfferRequest;
	}>({
		open: false
	});
	const [cancelBooking, setCancelBooking] = useState<{
		open: boolean;
		request?: OfferRequest;
		eventId?: string;
		userPersonaDocId?: string;
	}>({ open: false });

	const [cancelRequest, setCancelRequest] = useState<{
		open: boolean;
		request?: OfferRequest;
		eventId?: string;
		userPersonaDocId?: string;
	}>({ open: false });

	const [open, setOpen] = useState(false);
	const [page, setPage] = useState(1);
	const lastItemRef = useRef<HTMLDivElement>(null);
	const onScreen = useOnScreen(lastItemRef);

	const { communityColors } = useCommunity();
	const {
		setSignUpVolunteerSlot,
		setCancelVolunteerSlot,
		joinLeaveForVolunteerSlot,
		getData: getVolunteerData
	} = useVolunteers();
	const { signUpDialog: signUpVolunteer, cancelVolunteerSlot } = getVolunteerData();
	const { getData: getUserData, getProfilePicture, clearCounter } = useUser();
	const { cancelCarpoolRequest, cancelBookingCarpoolRequest } = useCarpool();
	const { user } = getUserData();

	const history = useHistory();
	const { createConnection } = useMessaging();
	const { onSend } = useFirebase();

	const { isCommunityFeatureEnabled } = useFeature();

	const { getMemberRoutesData } = useMemberRoutes();
	const { routes } = getMemberRoutesData();

	const { getEventsWithRequests, setRequestEvents, getData: getEventData } = useEvent();
	const { requestEvents, loading, shouldRequestStop } = getEventData();
	const expandOrCollapse = (id: number) => (id === expandedIndex ? setExpandedIndex(-1) : setExpandedIndex(id));

	const createOption = useMemo(
		() => ({
			label: "Create",
			onClick: () => setOpen(true),
			val: 1
		}),
		[]
	);

	useEffect(() => {
		clearCounter("requestCount");
	}, [clearCounter]);

	useEffect(() => {
		getEventsWithRequests(page, 10);
	}, [getEventsWithRequests, page]);

	useEffect(() => {
		!shouldRequestStop && onScreen && !loading && setPage(pg => pg + 1);
	}, [onScreen, loading, shouldRequestStop]);

	const zone = useMemo(() => Intl.DateTimeFormat().resolvedOptions().timeZone, []);

	const eventRange = useMemo(() => {
		if (signUpVolunteer?.eventDetail) {
			const start = DateTime.fromISO(
				signUpVolunteer.eventDetail.startTime ? signUpVolunteer.eventDetail.startTime : "",
				{ zone }
			).toFormat("MMM d, h:mm a");
			const end = DateTime.fromISO(signUpVolunteer.eventDetail.endTime ? signUpVolunteer.eventDetail.endTime : "", {
				zone
			}).toFormat("MMM d, h:mm a");

			return `${start} - ${end}`;
		}
	}, [signUpVolunteer?.eventDetail, zone]);

	const formatDate = (event, isDayOnly = false) => {
		if (isDayOnly) {
			return DateTime.fromISO(event.eventDateAndTime ? event.eventDateAndTime : "", { zone }).toLocaleString({
				weekday: "short"
			});
		}

		return DateTime.fromISO(event.eventDateAndTime ? event.eventDateAndTime : "", { zone }).toLocaleString({
			month: "short",
			day: "2-digit",
			hour: "2-digit",
			minute: "2-digit"
		});
	};

	const volunteerCancelHandler = useCallback(async () => {
		if (cancelVolunteerSlot?._id) {
			const userDetail = {
				personaId: user?.activeProfile,
				quantity: cancelVolunteerSlot?.members.find(m => m.personaId === user?.activeProfile)?.quantity || 0,
				firstName: user?.profiles[0].firstName,
				lastName: user?.profiles[0].lastName,
				photos: [
					{
						profilePicture: getResizedImage(getProfilePicture(user), ProfilePhotoSizes.size50x50)
					}
				]
			};

			const { success } = await joinLeaveForVolunteerSlot(cancelVolunteerSlot?._id, { action: "leave" }, userDetail);
			if (success && slot?.event && slot.slot) {
				setRequestEvents(
					requestEvents.map(r =>
						r._id === slot.event?._id
							? {
									...r,
									requests: r.requests.map(req =>
										req._id === slot.slot?._id
											? {
													...req,
													filledQuantity: req.filledQuantity - userDetail.quantity,
													members: req.members.filter(m => m.personaId !== userDetail.personaId)
											  }
											: req
									)
							  }
							: r
					)
				);
			}
			setCancelVolunteerSlot(undefined);
		}
	}, [
		setCancelVolunteerSlot,
		joinLeaveForVolunteerSlot,
		getProfilePicture,
		cancelVolunteerSlot,
		user,
		setRequestEvents,
		requestEvents,
		slot?.slot,
		slot?.event
	]);

	const isVolunteerSignedUp = useCallback(
		(volunteer: VolunteerSlotType) => volunteer.members.some(m => m?.personaId === user?.activeProfile),
		[user?.activeProfile]
	);

	const sendCancelMessage = useCallback(
		async (request, isBooking: boolean) => {
			const chatResponse = await createConnection(request?.creator.personaId);

			if (chatResponse) {
				onSend({
					messages: [
						{
							text: `${isBooking ? "Booking" : "Ride"} has been canceled`,
							user: {
								_id: user?.profiles[0].chatUserId,
								name: user?.profiles[0].firstName,
								avatar: (user?.profiles[0].photos[0] as MediaModel).profilePicture
							},
							canceledCarpool: true
						}
					],
					chat: chatResponse
				});
				history.push(`${routes?.member.messaging.getPath()}?messageTo=${request.creator.personaId}`);
			}
		},
		[history, onSend, createConnection, routes?.member, user?.profiles]
	);

	const cancelRequestHandler = useCallback(async () => {
		const { open, request, eventId, userPersonaDocId } = cancelRequest;
		if (open && request?._id && eventId && userPersonaDocId) {
			const { success } = await cancelCarpoolRequest(request._id, eventId, () => setCancelRequest({ open: false }));

			if (success && user?.personaDocId !== request?.creator._id) {
				sendCancelMessage(request, false);
			}
		}
	}, [cancelRequest, cancelCarpoolRequest, sendCancelMessage, user?.personaDocId]);

	const carpoolBookingHandler = useCallback(
		(rideOfferedBy, personaDocId, event, request) => {
			rideOfferedBy !== personaDocId
				? setBookDialog({ open: true, eventDetail: event, request })
				: cancelCarpoolRequest(request._id, event._id);
		},
		[setBookDialog, cancelCarpoolRequest]
	);

	const isRequestAvailable = useCallback(
		(request: OfferRequest) => !!request.rideOfferedBy && request.rideOfferedBy !== user?.personaDocId,
		[user?.personaDocId]
	);

	const isBookedByYourself = useCallback(
		(request: OfferRequest) => request.bookedSeats.some(m => m.personaDocId === user?.personaDocId),
		[user?.personaDocId]
	);

	const getOptions = useCallback(
		({
			request,
			event,
			userPersonaDocId
		}: {
			request: OfferRequest;
			event: EventRequests;
			userPersonaDocId?: string;
		}) => {
			const options: MenuDotsOption[] = [
				{
					icon: <Icon group={"filled"} fill={"#8f9bb3"} name={"pencil"} width={20} height={20} />,
					name: "Edit Booking",
					onClick: () =>
						setBookDialog({
							open: true,
							editDetails: request.bookedSeats.find(m => m.personaDocId === userPersonaDocId),
							eventDetail: event,
							request
						})
				},
				{
					icon: <Icon group={"filled"} fill={"#8f9bb3"} name={"trash"} width={20} height={20} />,
					name: "Cancel Booking",
					onClick: () =>
						setCancelBooking({
							open: true,
							request,
							eventId: event._id,
							userPersonaDocId
						})
				}
			];

			return options;
		},
		[setCancelBooking]
	);

	const cancelBookingHandler = useCallback(async () => {
		const { open, request, eventId, userPersonaDocId } = cancelBooking;
		if (open && request?._id && eventId && userPersonaDocId) {
			const { success } = await cancelBookingCarpoolRequest(request._id, eventId, userPersonaDocId, () =>
				setCancelBooking({ open: false })
			);

			if (success && user?.personaDocId !== request?.creator._id) {
				sendCancelMessage(request, true);
			}
		}
	}, [cancelBooking, cancelBookingCarpoolRequest, sendCancelMessage, user?.personaDocId]);

	const PageSkeleton = useMemo(
		() =>
			Array.from(Array(6).keys()).map((_, i) => (
				<SkeletonBox key={i} className="flex w-full py-3 px-4 mb-4 justify-between">
					<Box className="flex w-full">
						<Skeleton variant="rect" width={64} height={64} />
						<Box className="ml-4 w-full">
							<Skeleton width="15%" height={15} />
							<Skeleton width="20%" height={26} />
							<Skeleton width="15%" height={15} />
						</Box>
					</Box>
					<Box className="flex items-center">
						<Skeleton className="mr-4" width={150} height={20} />
						<Skeleton variant="circle" width={30} height={30} />
					</Box>
				</SkeletonBox>
			)),
		[]
	);

	return (
		<>
			<FilterBlock inline heading={"Requests"} options={[]} createOption={createOption} />
			<Container className="mt-3" color={communityColors.primary}>
				{!!requestEvents.length && (
					<ExpansionPanel
						panels={requestEvents
							.filter(
								event =>
									(isCommunityFeatureEnabled("enableVolunteers") &&
										!!(event.requests as VolunteerSlotType[]).filter(r => r.type === "volunteerslot").length) ||
									(isCommunityFeatureEnabled("enableCarpool") &&
										!!(event.requests as VolunteerSlotType[]).filter(r => r.type === "request").length)
							)
							.map((event, i) => ({
								id: i,
								expandId: `${i}`,
								expansionPanelClassName: "mb-4",
								title: (
									<Box className="flex justify-between px-1">
										<Box className="flex">
											<Box className="w-16 h-full overflow-hidden rounded-md">
												{!!event?.eventImages?.length ? (
													<img src={event.eventImages[0]} alt={event.name} className="w-16 h-full" />
												) : (
													<PlaceholderImage width={120} height={60} type={PlaceholderImageType.PROFILE_IMAGE} />
												)}
											</Box>
											<Box className="ml-4">
												<EventDate color={communityColors.primary}>
													{`${formatDate(event, true)}, ${formatDate(event).replace(",", " at")}`}
												</EventDate>
												<Text variant="h6">{event.name}</Text>
												<EventDetail>{event.location?.name || ""}</EventDetail>
											</Box>
										</Box>
										<Box className="flex items-center">
											<EventDetail>
												{event.carpoolRequestIds.length + event.carpoolOfferIds.length + event.volunteerSlotIds.length}{" "}
												Requests & Offers
											</EventDetail>
										</Box>
									</Box>
								) as unknown as React.Component,
								content: (
									<Box className="px-4 py-2 w-full">
										{isCommunityFeatureEnabled("enableVolunteers") && (
											<>
												{!!(event.requests as OfferRequest[]).filter(r => r.type === "volunteerslot").length && (
													<>
														<SecondaryText className="font-semibold" fontSize={13}>
															Volunteers
														</SecondaryText>
														<RequestTemplateWrapper className="flex flex-wrap">
															{(event.requests as VolunteerSlotType[])
																.filter(r => r.type === "volunteerslot")
																.map((volunteer, i) => (
																	<RequestCard
																		key={i}
																		image={<VolunteerIcon fill={communityColors.primary} />}
																		caption1={"Volunteer"}
																		title={volunteer.need}
																		caption2={
																			volunteer.quantity - volunteer.filledQuantity === 0 ? (
																				<p className="flex items-center">
																					<Icon
																						className="mr-1"
																						fill={"#23b34a"}
																						name={"check-circle"}
																						width={18}
																						height={18}
																					/>
																					{`Slots filled: ${volunteer.filledQuantity} of ${volunteer.quantity}`}
																				</p>
																			) : (
																				`Available slots: ${volunteer.quantity - volunteer.filledQuantity} of ${
																					volunteer.quantity
																				}`
																			)
																		}
																		buttonText={isVolunteerSignedUp(volunteer) ? "Cancel" : "Sign Up"}
																		buttonClassName={isVolunteerSignedUp(volunteer) ? "cancel" : ""}
																		disabledButton={
																			volunteer.quantity - volunteer.filledQuantity === 0 &&
																			!isVolunteerSignedUp(volunteer)
																		}
																		badgeColor="6173fe"
																		onClick={() => {
																			if (isVolunteerSignedUp(volunteer)) {
																				setCancelVolunteerSlot(volunteer);
																				setSlot({ event, slot: volunteer });
																			} else {
																				setSignUpVolunteerSlot({
																					open: true,
																					signUpVolunteerDetail: volunteer,
																					eventDetail: event as any
																				});
																			}
																		}}
																	/>
																))}
														</RequestTemplateWrapper>
													</>
												)}
												{!!(event.requests as OfferRequest[]).filter(r => r.type === "volunteerslot").length &&
													!!(event.requests as OfferRequest[]).filter(r => r.type === "request").length &&
													isCommunityFeatureEnabled("enableCarpool") && <Divider />}
											</>
										)}

										{isCommunityFeatureEnabled("enableCarpool") && (
											<>
												{!!(event.requests as OfferRequest[]).filter(r => r.type === "request").length && (
													<>
														<SecondaryText className="font-semibold" fontSize={13}>
															Carpool Requests
														</SecondaryText>
														<RequestTemplateWrapper className="flex flex-wrap">
															{(event.requests as OfferRequest[])
																.filter(r => r.type === "request")
																.map((request, i) => (
																	<RequestCard
																		key={i}
																		image={
																			<Box className="h-16 w-16 h-full overflow-hidden rounded-md">
																				{request.creator.photos[0] ? (
																					<img
																						src={request.creator.photos[0].profilePicture}
																						alt={event.name}
																						className="h-16 w-16 h-full"
																					/>
																				) : (
																					<PlaceholderImage
																						width={120}
																						height={60}
																						type={PlaceholderImageType.PROFILE_IMAGE}
																					/>
																				)}
																			</Box>
																		}
																		caption1={"Carpool Request"}
																		title={
																			isRequestAvailable(request) ? (
																				<p className="flex items-center">
																					<Icon
																						className="mr-1"
																						fill={"#23b34a"}
																						name={"check-circle"}
																						width={18}
																						height={18}
																					/>
																					Request fulfilled
																				</p>
																			) : (
																				`${formatCount(request.seats, "seat")} needed`
																			)
																		}
																		titleClassName={
																			isRequestAvailable(request) ? "font-normal text-gray-400 text-sm" : ""
																		}
																		caption2={isRequestAvailable(request) ? null : request.location.name}
																		customButton={request.creator.personaId === user?.activeProfile}
																		buttonText={
																			!request.rideOfferedBy && request.rideOfferedBy !== user?.personaDocId
																				? "Sign up"
																				: isRequestAvailable(request)
																				? "Unavailable"
																				: "Cancel"
																		}
																		buttonClassName={request.rideOfferedBy === user?.personaDocId ? "cancel" : ""}
																		disabledButton={isRequestAvailable(request)}
																		isSquareShape={false}
																		badgeColor={"4a99f9"}
																		onClick={() => {
																			!request.rideOfferedBy && request.rideOfferedBy !== user?.personaDocId
																				? event && setSignUpDialog({ open: true, eventDetail: event, request })
																				: setCancelRequest({
																						open: true,
																						request,
																						eventId: event?._id,
																						userPersonaDocId: user?.personaDocId
																				  });
																		}}
																	/>
																))}
														</RequestTemplateWrapper>
													</>
												)}

												{!!(event.requests as OfferRequest[]).filter(r => r.type === "offer").length &&
													!!(event.requests as OfferRequest[]).filter(r => r.type === "request").length && <Divider />}

												{!!(event.requests as OfferRequest[]).filter(r => r.type === "offer").length && (
													<>
														<SecondaryText className="font-semibold" fontSize={13}>
															Carpool Offers
														</SecondaryText>
														<RequestTemplateWrapper className="flex flex-wrap">
															{(event.requests as OfferRequest[])
																.filter(r => r.type === "offer")
																.map((request, i) => (
																	<RequestCard
																		key={i}
																		image={
																			<Box className="h-16 w-16 h-full overflow-hidden rounded-md">
																				{request.creator.photos[0] ? (
																					<img
																						src={request.creator.photos[0].profilePicture}
																						alt={event.name}
																						className="h-16 w-16 h-full"
																					/>
																				) : (
																					<PlaceholderImage
																						width={120}
																						height={60}
																						type={PlaceholderImageType.PROFILE_IMAGE}
																					/>
																				)}
																			</Box>
																		}
																		caption1={"Carpool Offer"}
																		customButton={
																			request.creator.personaId === user?.activeProfile
																				? true
																				: isBookedByYourself(request) && (
																						<MenuDots
																							options={getOptions({
																								request,
																								event,
																								userPersonaDocId: user?.personaDocId
																							})}
																							rounded
																							size={{ small: true }}
																						/>
																				  )
																		}
																		title={
																			isBookedByYourself(request) ||
																			(!isBookedByYourself(request) && request.seatsLeft === 0) ? (
																				<p className="flex items-center">
																					<Icon
																						className="mr-1"
																						fill={"#23b34a"}
																						name={"check-circle"}
																						width={18}
																						height={18}
																					/>
																					Offer fulfilled
																				</p>
																			) : (
																				`${formatCount(request.seatsLeft, "seat")} available`
																			)
																		}
																		titleClassName={
																			isBookedByYourself(request) ||
																			(!isBookedByYourself(request) && request.seatsLeft === 0)
																				? "font-normal text-gray-400 text-sm"
																				: ""
																		}
																		caption2={
																			isBookedByYourself(request)
																				? `You booked ${formatCount(
																						request.bookedSeats.find(m => m.personaDocId === user?.personaDocId)
																							?.seatsBooked || 0,
																						"seat"
																				  )}`
																				: request.location.name
																		}
																		buttonText={
																			!isBookedByYourself(request) && request.seatsLeft === 0 ? "Unavailable" : "Book"
																		}
																		buttonClassName={isBookedByYourself(request) ? "cancel" : ""}
																		disabledButton={!isBookedByYourself(request) && request.seatsLeft === 0}
																		isSquareShape={false}
																		badgeColor={"23b34a"}
																		onClick={() =>
																			user?.personaDocId &&
																			carpoolBookingHandler(request.rideOfferedBy, user?.personaDocId, event, request)
																		}
																	/>
																))}
														</RequestTemplateWrapper>
													</>
												)}
											</>
										)}
									</Box>
								) as unknown as React.Component,
								expanded: expandedIndex === i,
								onChange: () => expandOrCollapse(i)
							}))}
					/>
				)}
				{loading && PageSkeleton}
				<div ref={lastItemRef} />
			</Container>
			<CreateRequestsDialog open={open} onClose={() => setOpen(false)} />
			<SignUpRequestDialog
				open={signUpDialog.open}
				onClose={() => setSignUpDialog({ open: false, eventDetail: undefined, request: undefined })}
				eventDetail={signUpDialog?.eventDetail}
				request={signUpDialog?.request}
			/>
			{bookDialog && bookDialog.open && bookDialog?.request && (
				<BookRequestDialog
					open={bookDialog.open}
					onClose={() => setBookDialog({ open: false, eventDetail: undefined, request: undefined })}
					eventDetail={bookDialog?.eventDetail}
					request={bookDialog?.request}
					editDetails={bookDialog?.editDetails}
				/>
			)}
			{signUpVolunteer && signUpVolunteer.open && (
				<SignUpVolunteer
					open={signUpVolunteer.open}
					volunteerSlotDetail={signUpVolunteer.signUpVolunteerDetail}
					onClose={(userDetail, quantity) => {
						if (signUpVolunteer.eventDetail && userDetail && quantity) {
							setRequestEvents(
								requestEvents.map(r =>
									r._id === (signUpVolunteer.eventDetail as unknown as EventRequests)?._id
										? {
												...r,
												requests: r.requests.map(req =>
													req._id === signUpVolunteer.signUpVolunteerDetail?._id
														? {
																...req,
																filledQuantity: req.filledQuantity + quantity,
																members: [...req.members, userDetail]
														  }
														: req
												)
										  }
										: r
								)
							);
						}
						setSignUpVolunteerSlot({ open: false, signUpVolunteerDetail: undefined, eventDetail: undefined });
					}}
					eventDetail={signUpVolunteer.eventDetail}
					eventRange={eventRange}
				/>
			)}
			<ConfirmActionModal
				open={!!cancelVolunteerSlot?._id}
				onClose={() => {
					setCancelVolunteerSlot(undefined);
				}}
				onConfirm={volunteerCancelHandler}
				title={"Cancel Volunteering"}
				bodyText={"Are you sure you want to cancel your volunteering?"}
				closeText="Keep"
				confirmText="Cancel"
				reverseButtons
				confirmButtonTheme="danger"
			/>
			<ConfirmActionModal
				open={cancelRequest.open || cancelBooking.open}
				onClose={() => (cancelRequest.open ? setCancelRequest({ open: false }) : setCancelBooking({ open: false }))}
				onConfirm={() => (cancelRequest.open ? cancelRequestHandler() : cancelBookingHandler())}
				title={cancelRequest.open ? "Cancel the ride?" : "Don't need a ride?"}
				bodyText={"Are you sure you want to cancel your ride?"}
				closeText="Keep"
				confirmText="Cancel"
				reverseButtons
				confirmButtonTheme="danger"
			/>
		</>
	);
};

export default Requests;
