import { Box, Grid } from "@material-ui/core";
import styled, { css } from "styled-components";

import { Divider, Text } from "shared/ui-kit";

export const StyledPopupOverlay = styled(Box)`
	display: ${props => (props.open ? "block" : "none")};
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.4);
	z-index: 3001;
	.MuiDialogContent-root {
		padding: 0;
		text-align: center;
	}
	.MuiDialog-paper {
		width: 400px !important;
	}
`;

export const StyledDivider = styled(Divider)`
	margin: 0;
	border-top-width: 1px;
`;

export const StyledParagraphText = styled(Text)`
	font-size: 15px;
	line-height: 1.33;
	color: #222b45;
	width: 75%;
	margin: auto;
	margin-bottom: 24px;
`;

export const ConfirmHeader = styled(Box)`
	text-align: center;
	padding: 16px;
	h6 {
		font-size: 18px;
		font-weight: bold;
		line-height: 1.33;
		color: #222b45;
	}
`;

export const ConfirmFooter = styled(Grid)`
	button {
		width: 50%;
		padding: 10px 0;
		font-size: 15px;
		font-weight: 600;
		line-height: 1.6;
		&:first-child {
			border-right: 0.25px solid #c5cee0;
			color: #222b45;
		}
		&:last-child {
			color: ${({ primaryColor }) => primaryColor || "#6173fe"};
		}

		${({ withDanger }) =>
			withDanger &&
			css`
				&:first-child {
					color: #ff463d;
				}
			`}
	}
`;
