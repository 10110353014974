import { Popper } from "@material-ui/core";
import styled from "styled-components";

import { Box } from "shared/ui-kit";

export const StyledPopper = styled(Popper)`
	z-index: 1300;
`;

export const Wrapper = styled(Box)`
	display: flex;
	align-items: center;

	z-index: 10;

	position: relative;

	padding: 7px;

	border: 1px solid rgba(197, 206, 224, 0.5);
	border-radius: 12px;

	box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12);

	background-color: #fff;

	img {
		width: 36px;
		height: 36px;

		object-fit: cover;
	}
`;

export const EmotionWrapper = styled(Box)<{ fill?: string }>`
	transition: transform 0.25s ease-in-out, margin 0.25s ease-in-out;

	transform: scale(1);
	transform-origin: center bottom;

	width: 36px;
	height: 36px;

	${props => props.theme.breakpoints.down("sm")} {
		width: 24px;
		height: 24px;
	}

	& > div {
		&:first-of-type {
			width: 100%;
			height: 100%;
		}
	}

	&:not(:last-child) {
		margin-right: 8px;
	}

	&.active {
		transform: scale(1.75);
	}

	&.inactive {
		transform: scale(0.9);
	}

	&.chatView {
		width: 28px;
		height: 28px;

		svg {
			width: 28px;
			height: 28px;
		}

		img {
			width: 28px;
			height: 28px;
			object-fit: cover;
		}
	}

	svg {
		width: 100%;
		height: 100%;

		fill: ${({ fill }) => fill || "#ffd16d"};
	}

	img {
		width: 100%;
		height: 100%;

		margin-top: 0 !important;
	}
`;
