import React, { useCallback } from "react";

import { useFirebase, useMessaging } from "modules/Messaging/Data";
import { ChatMessage } from "modules/Messaging/Data/types";
import { RelativeWrapper } from "shared/styles";

import Actions from "./Actions";

import Reactions from "./Reactions";

import { RenderActionType } from "..";

import { VideoPreview } from "../styles";

const VideoBubble = props => {
	const { onSend } = useFirebase();
	const { getData: getMessagingData } = useMessaging();
	const { currentChat } = getMessagingData();

	const handleDelete = useCallback(() => {
		if (!currentChat) return;

		const msg = {
			...props.currentMessage,
			edited: props.currentMessage,
			videos: (props.currentMessage.videos || []).filter(vi => vi.id !== props.videoItem.id)
		} as ChatMessage;

		onSend({ messages: [msg], chat: currentChat });
	}, [currentChat, onSend, props.currentMessage, props.videoItem.id]);

	return (
		<RelativeWrapper data-message={props.videoItem.id}>
			<VideoPreview>
				<video controls>
					<source src={props.videoItem.url} type={props.videoItem.type} />
				</video>
			</VideoPreview>
			{props.videoItem.recentLikes && (
				<Reactions list={props.videoItem.recentLikes} isCurrentUser={props.isCurrentUser} />
			)}
			<Actions
				replyItem={{
					id: props.videoItem.id,
					text: "video"
				}}
				isCurrentUser={props.isCurrentUser}
				onDelete={handleDelete}
				onEdit={props.onEdit}
				onForward={props.onForward}
				onReply={props.onReply}
				onLike={props.onLike}
				currentMessage={props.currentMessage}
				type={RenderActionType.VIDEO}
			/>
		</RelativeWrapper>
	);
};

export default VideoBubble;
