import { IStateProps } from "utils/StateOf";

import createStore from "utils/createStore";

export interface S3UploaderContextValues {
	name: string;
	uploading: boolean;
	loadingSignedUrl: boolean;
	uploadingProgress: number;
	uploadingIds: string[];
}

export const initialState: IStateProps<S3UploaderContextValues> = {
	name: "",
	uploading: false,
	loadingSignedUrl: false,
	uploadingProgress: 0,
	uploadingIds: []
};

export default createStore<S3UploaderContextValues>({
	initialState
});
