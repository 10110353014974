import React, { FC, useCallback, useEffect, useMemo, useState } from "react";

import clsx from "clsx";
import { DateTime } from "luxon";

import { useHistory } from "react-router";

import { routes as manageRoutes } from "apps/RegularUser/constants";
import { ConfirmDelete, PageTemplate } from "modules/Manage/View/Components";
import { CreateUpdateFundraiser, InviteContactsDialog, MenuDots, ShareModal, TableWrapper } from "shared/Components";
import { TableColumnLoaderType } from "shared/Components/NewTable/Components";
import { Cell } from "shared/Components/NewTable/style";
import { useCommunity, useDebounce, useFundraisers, useMemberRoutes } from "shared/hooks";
import { useS3Uploader } from "shared/services/s3Uploader";
import { FundraiserModel } from "shared/types";
import { FundraiserStatusEnum } from "shared/types/FundraiserModel";
import { Icon, Tooltip } from "shared/ui-kit";
import { formatMoney } from "utils/formatMoney";
import { formatCount } from "utils/serviceUtils/helpers";

const Table = React.lazy(() => import("shared/Components/NewTable"));

const FundraisersTable: FC<{ edit?: string }> = ({ edit }) => {
	const {
		getData: getFundraisersData,
		setDialog,
		getFundraisers,
		getFundraisersCount,
		setOffset,
		setFundraisersShowPerPage,
		deleteFundraiser,
		setInviteDialog,
		setSubmitting,
		setInviteDialogModel,
		pauseFundraiser,
		getFundraiser
	} = useFundraisers();

	const { fundraisersShowPerPage, fundraisersCount, loading, offset, createUpdateDialog, fundraisers, inviteDialog } =
		getFundraisersData();

	const { getData: getCommnunityData } = useCommunity();
	const { workspace } = getCommnunityData();

	const [keyword, setKeyword] = useState<string | undefined>();
	const [fundraiserToDelete, setFundraiserToDelete] = useState<{ id: string; name: string } | null>(null);
	const [fundraiserToEdit, setFundraiserToEdit] = useState<FundraiserModel | null>(null);
	const [shareModal, setShareModal] = useState<{ url: string }>();

	const { abortUploading } = useS3Uploader();

	const debouncedKeyword = useDebounce(keyword, 500);

	useEffect(() => {
		getFundraisers({ offset, limit: fundraisersShowPerPage, keyword: debouncedKeyword });
	}, [debouncedKeyword, fundraisersShowPerPage, getFundraisers, offset]);

	useEffect(() => {
		if (edit && edit !== fundraiserToEdit?._id) {
			getFundraiser(edit, undefined, true).then(data => {
				data && setFundraiserToEdit(data);
			});
		}
	}, [edit, fundraiserToEdit?._id, getFundraiser]);

	useEffect(() => {
		if (fundraiserToEdit) {
			setDialog({
				open: true,
				model: fundraiserToEdit
			});
		}
	}, [fundraiserToEdit, setDialog]);

	useEffect(() => {
		getFundraisersCount(debouncedKeyword);
	}, [debouncedKeyword, getFundraisersCount]);

	const { getMemberRoutesData } = useMemberRoutes();
	const { routes } = getMemberRoutesData();

	const history = useHistory();

	const getOptions = useCallback(
		(item: FundraiserModel) => {
			const isActive = item.status?.toLowerCase() === FundraiserStatusEnum.ACTIVE.toLowerCase();
			const isPending = item.status?.toLowerCase() === FundraiserStatusEnum.PENDING.toLowerCase();
			const isPaused = item.status?.toLowerCase() === FundraiserStatusEnum.PAUSED.toLowerCase();
			const isEnded = item.status?.toLowerCase() === FundraiserStatusEnum.ENDED.toLowerCase();

			const ar = [
				{
					id: "editFundraiser",
					name: "Edit fundraiser details",
					onClick: () =>
						setDialog({
							open: true,
							model: item
						}),
					value: 1,
					comp: ""
				}
			];

			if (isActive || isPending || isPaused) {
				ar.push({
					id: "viewAsMember",
					name: "View as a Member",
					onClick: () => window.open(`${routes?.member.fundraiser.getPath()}/${item._id}`, "_blank"),
					value: 2,
					comp: ""
				});
				ar.push({
					id: "shareFundraiser",
					name: "Share fundraiser",
					onClick: () => {
						const url = `${window.location.origin}/fundraiser/${item._id}?workspace=${workspace!.name}&workspaceId=${
							workspace!._id
						}`;
						if (navigator.share && item.name) {
							navigator.share({
								title: item.name,
								url
							});
						} else {
							setShareModal({ url });
						}
					},
					comp: "",
					value: 4
				});
			}

			if (isActive) {
				ar.push({
					id: "pauseFundraiser",
					name: "Pause fundraiser",
					onClick: async () => {
						await pauseFundraiser(item._id, "pause");
						getFundraisers({ offset, limit: fundraisersShowPerPage, keyword: debouncedKeyword });
					},
					value: 3,
					comp: ""
				});
			}

			if (isPaused) {
				ar.push({
					id: "unpauseFundraiser",
					name: "Activate fundraiser",
					onClick: async () => {
						await pauseFundraiser(item._id, "unpause");
						getFundraisers({ offset, limit: fundraisersShowPerPage, keyword: debouncedKeyword });
					},
					value: 2,
					comp: ""
				});
			}

			if (!isEnded) {
				ar.push({
					id: "inviteContacts",
					name: "Add Contacts",
					onClick: () =>
						setInviteDialog({
							open: true,
							id: item._id,
							name: item.name
						}),
					value: ar.length + 1,
					comp: ""
				});
			}

			if (!item.totalDonated) {
				ar.push({
					id: "deleteFundraiser",
					name: "Delete fundraiser",
					onClick: () =>
						setFundraiserToDelete({
							id: item._id,
							name: item.name
						}),
					value: ar.length + 1,
					comp: ""
				});
			}

			if (isActive || isEnded || isPaused) {
				ar.push({
					id: "viewDonations",
					name: "View Donations",
					onClick: () =>
						history.push(
							`${manageRoutes.fundraising.fundraisers.getPath()}/${item._id}?fundraiser=${encodeURIComponent(
								item.name
							)}`
						),
					value: 6,
					comp: ""
				});
			}

			return ar;
		},
		[
			setDialog,
			routes?.member.fundraiser,
			pauseFundraiser,
			getFundraisers,
			offset,
			fundraisersShowPerPage,
			debouncedKeyword,
			history,
			setInviteDialog,
			workspace
		]
	);

	const tableColumns = useMemo(
		() => [
			{
				width: 260,
				alignment: "left",
				label: <Cell.HeaderText>{formatCount(fundraisersCount as number, "Fundraiser")}</Cell.HeaderText>,
				Cell: ({ rowData: { thumbnail, name, creator } }: { rowData: FundraiserModel }) => (
					<Cell.Wrapper>
						<Cell.ImageWrapper>
							{thumbnail.url ? <Cell.Image src={thumbnail.url} alt={name} /> : <Icon name="fundraiser" group="color" />}
						</Cell.ImageWrapper>
						<Cell.Wrapper className="column with-image">
							<Cell.Text>{name}</Cell.Text>
							{creator && (
								<Cell.Text className="light">
									By {creator.firstName} {creator.lastName}
								</Cell.Text>
							)}
						</Cell.Wrapper>
					</Cell.Wrapper>
				),
				loaderTemplate: TableColumnLoaderType.imageWthOneTextRow,
				dataKey: "cid"
			},
			{
				alignment: "left",
				minWidth: 30,
				label: <Cell.HeaderText>Groups</Cell.HeaderText>,
				Cell: ({ rowData: { groups } }: { rowData: FundraiserModel }) => (
					<Cell.Wrapper>
						<Cell.Text>
							<Tooltip text={groups.map(g => g.name).join(", ")}>{groups.length}</Tooltip>
						</Cell.Text>
					</Cell.Wrapper>
				),
				dataKey: "groups"
			},
			{
				alignment: "left",
				minWidth: 30,
				label: <Cell.HeaderText>Sponsors</Cell.HeaderText>,
				Cell: ({ rowData: { totalDonations, status } }: { rowData: FundraiserModel }) => (
					<Cell.Wrapper>
						<Cell.Text>
							{status?.toLowerCase() === FundraiserStatusEnum.PENDING.toLowerCase() ? "-" : totalDonations}
						</Cell.Text>
					</Cell.Wrapper>
				),
				dataKey: "sponsors"
			},
			{
				alignment: "left",
				minWidth: 30,
				label: <Cell.HeaderText>Contacts</Cell.HeaderText>,
				Cell: ({ rowData: { totalContacts } }: { rowData: FundraiserModel }) => (
					<Cell.Wrapper>
						<Cell.Text>{totalContacts || 0}</Cell.Text>
					</Cell.Wrapper>
				),
				dataKey: "contacts"
			},
			{
				alignment: "left",
				minWidth: 140,
				label: <Cell.HeaderText>Raised / Goal</Cell.HeaderText>,
				Cell: ({ rowData: { goalAmount, totalDonated } }: { rowData: FundraiserModel }) => (
					<Cell.Wrapper>
						<Cell.Text>
							{formatMoney(totalDonated)} / {formatMoney(goalAmount)}
						</Cell.Text>
					</Cell.Wrapper>
				),
				dataKey: "raised"
			},
			{
				alignment: "left",
				label: <Cell.HeaderText>Start Date</Cell.HeaderText>,
				minWidth: 110,
				Cell: ({ rowData: { startDate } }: { rowData: FundraiserModel }) => {
					if (startDate) {
						const correctDate = DateTime.fromISO(startDate.toString());
						return (
							<Cell.Wrapper className="column with-image">
								<Cell.Text>{correctDate.toFormat("MMM d, yyyy")}</Cell.Text>
								<Cell.Text className="light">{correctDate.toFormat("h:mm a")}</Cell.Text>
							</Cell.Wrapper>
						);
					}
					return "-";
				},
				loaderTemplate: TableColumnLoaderType.oneTextRow,
				dataKey: "startDate"
			},
			{
				alignment: "left",
				label: <Cell.HeaderText>End Date</Cell.HeaderText>,
				minWidth: 110,
				Cell: ({ rowData: { endDate } }: { rowData: FundraiserModel }) => {
					if (endDate) {
						const correctDate = DateTime.fromISO(endDate.toString());
						return (
							<Cell.Wrapper className="column with-image">
								<Cell.Text>{correctDate.toFormat("MMM d, yyyy")}</Cell.Text>
								<Cell.Text className="light">{correctDate.toFormat("h:mm a")}</Cell.Text>
							</Cell.Wrapper>
						);
					}
					return "-";
				},
				loaderTemplate: TableColumnLoaderType.oneTextRow,
				dataKey: "endDate"
			},
			{
				alignment: "left",
				label: <Cell.HeaderText>Status</Cell.HeaderText>,
				minWidth: 90,
				Cell: ({ rowData: { status } }: { rowData: FundraiserModel }) =>
					status && (
						<Cell.Text
							className={clsx(
								(status.toLowerCase() === FundraiserStatusEnum.PENDING.toLowerCase() ||
									status.toLowerCase() === FundraiserStatusEnum.ARCHIVED.toLowerCase() ||
									status.toLowerCase() === FundraiserStatusEnum.ENDED.toLowerCase()) &&
									"blue",
								status.toLowerCase() === FundraiserStatusEnum.ACTIVE.toLowerCase() && "green",
								status.toLowerCase() === FundraiserStatusEnum.ENDED.toLowerCase() && "yellow"
							)}
						>
							{FundraiserStatusEnum[status]}
						</Cell.Text>
					),
				loaderTemplate: TableColumnLoaderType.oneTextRow,
				dataKey: "status"
			},
			{
				alignment: "right",
				label: "",
				width: 40,
				Cell: ({ rowData, rowIndex }) => (
					<MenuDots
						options={getOptions(rowData)}
						vertical
						removeBg
						removeshadow
						removeSideMargin
						menuId={`moreAction${rowIndex + 1}`}
					/>
				),
				loaderTemplate: TableColumnLoaderType.menuDots,
				dataKey: "menu"
			}
		],
		[fundraisersCount, getOptions]
	);

	const TableBlock = useMemo(
		() => (
			<TableWrapper sizes={{ horizontalTablet: 900 }}>
				<Table
					columns={tableColumns}
					data={fundraisers}
					loading={loading}
					paginated
					totalDataCount={fundraisersCount}
					page={offset}
					pageSize={fundraisersShowPerPage}
					onChangePage={page => setOffset(page)}
					onChangePageSize={pageSize => setFundraisersShowPerPage(pageSize)}
				/>
			</TableWrapper>
		),
		[
			fundraisers,
			fundraisersCount,
			fundraisersShowPerPage,
			loading,
			offset,
			setFundraisersShowPerPage,
			setOffset,
			tableColumns
		]
	);

	const handleCreateClick = useCallback(
		() =>
			setDialog({
				open: true
			}),
		[setDialog]
	);

	const handleCloseDialog = useCallback(
		(created?: boolean) => {
			abortUploading();
			setDialog();
			setSubmitting(false);

			if (created) {
				getFundraisers({ offset, limit: fundraisersShowPerPage, keyword: debouncedKeyword });
			}
		},
		[abortUploading, debouncedKeyword, fundraisersShowPerPage, getFundraisers, offset, setDialog, setSubmitting]
	);

	const handleCloseInvite = useCallback(() => {
		setInviteDialog();
		setSubmitting(false);
		setInviteDialogModel();
	}, [setInviteDialog, setInviteDialogModel, setSubmitting]);

	const confirmPopupAction = useCallback(
		async (confirm: boolean) => {
			if (confirm && fundraiserToDelete?.id) {
				await deleteFundraiser(fundraiserToDelete.id);
				getFundraisers({ offset, limit: fundraisersShowPerPage, keyword: debouncedKeyword });
			}
			setFundraiserToDelete(null);
		},
		[debouncedKeyword, deleteFundraiser, fundraiserToDelete?.id, fundraisersShowPerPage, getFundraisers, offset]
	);

	return (
		<>
			<PageTemplate
				title="Fundraisers"
				isLoading={loading}
				isNoData={!loading && !fundraisersCount}
				emptyText={"You don’t have any fundraisers in your community yet."}
				searchPlaceholder={"Search Fundraiser"}
				onSearchUpdate={text => setKeyword(text)}
				actionText={"Create Fundraiser"}
				onCreateClick={handleCreateClick}
				actionId={"createFundraiser"}
				searchId={"searchFundraiser"}
			>
				{TableBlock}
			</PageTemplate>
			{fundraiserToDelete && (
				<ConfirmDelete
					headline="Fundraiser"
					name={fundraiserToDelete.name}
					onClose={() => confirmPopupAction(false)}
					onDelete={() => confirmPopupAction(true)}
				/>
			)}
			{inviteDialog && <InviteContactsDialog handleClose={handleCloseInvite} updateOnAddContact />}
			{createUpdateDialog?.open && <CreateUpdateFundraiser handleClose={handleCloseDialog} />}
			{!!shareModal && (
				<ShareModal title="Share Fundraiser" url={shareModal.url} onClose={() => setShareModal(undefined)} />
			)}
		</>
	);
};

export default FundraisersTable;
