import styled, { css } from "styled-components";

import { Box } from "shared/ui-kit";

export const Container = styled(Box)`
	width: 1200px;
	padding: 0 16px;
	margin: 0 auto;
	display: flex;
	align-items: flex-start;
	justify-content: space-between;

	${props => props.theme.breakpoints.down("sm")} {
		flex-direction: column;
		justify-content: flex-start;

		width: 100%;

		padding: 16px 0;
	}
`;

export const BoxItemWrapper = styled(Box)`
	margin-bottom: 16px;

	&:last-child {
		margin-bottom: 32px;
	}

	&.no-margin {
		margin-bottom: 0;
	}
`;

export const Column = styled(Box)<{ left?: boolean; middle?: boolean; right?: boolean }>`
	width: ${({ left, middle }) => (left ? 248 : middle ? 568 : 320)}px;

	${({ left, right }) =>
		(left || right) &&
		css`
			position: sticky;
			top: 80px;

			&.akina-mode {
				top: 112px;
			}
		`}

	${({ right }) =>
		right &&
		css`
			// Fixes for correct scroll position
			transform: translateY(-16px);
		`}

	${props => props.theme.breakpoints.down("sm")} {
		width: 100%;
		margin-bottom: 24px;
		overflow: hidden;
	}
`;

export const PostSkeletonWrapper = styled(Box)`
	width: 100%;

	background: white;

	border-radius: 12px;

	box-shadow: inset 0 0 0 0.5px #c5cee0, 0 1px 1px 0 rgb(0 0 0 / 8%);

	padding: 16px;
`;

PostSkeletonWrapper.Header = styled(Box)`
	display: flex;
	align-items: center;
`;

PostSkeletonWrapper.HeaderInfo = styled(Box)`
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	margin-left: 10px;
`;

PostSkeletonWrapper.HeaderAuthorRow = styled(Box)`
	display: flex;
	align-items: center;
`;

PostSkeletonWrapper.HeaderCategory = styled(Box)`
	margin-left: 10px;
`;

PostSkeletonWrapper.Body = styled(Box)``;

PostSkeletonWrapper.Footer = styled(Box)`
	display: flex;
	justify-content: space-around;
`;
