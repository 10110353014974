import React, { useCallback } from "react";

import styled from "styled-components";

import { useMarketing } from "shared/hooks";
import { Avatar, Box, Button, InPlaceModal, Text } from "shared/ui-kit";

const PopupWrapper = styled(InPlaceModal)``;

const Content = styled(Box)`
	padding: 20px 112px 40px 112px;
`;

const Title = styled(Text)`
	font-size: 18px;
	font-weight: bold;
	line-height: 1.33;
	text-align: center;
	color: #222b45;
`;

const StyledAvatar = styled(Avatar)`
	width: 120px;
	height: 120px;

	border-radius: 50%;

	background: #f0faff;

	overflow: hidden;

	font-size: 40px;

	margin: 32px auto 0 auto;
`;

const Description = styled(Text)`
	font-size: 18px;
	font-weight: normal;
	line-height: 1.33;
	text-align: center;
	color: #222b45;

	margin-top: 32px;

	span {
		font-weight: bold;
	}
`;

const BtnWrapper = styled(Box)`
	margin: 32px auto 0 auto;

	display: flex;
	justify-content: center;
`;

interface AdultPopupProps {
	open: boolean;
	title: string;
	imgUrl?: string;
	onClose: (adult: boolean) => void;
}

const AdultPopup: React.FC<AdultPopupProps> = ({ open, onClose, title, imgUrl }) => {
	const { getData: getMarketingData } = useMarketing();
	const { workspace } = getMarketingData();

	const handleClose = useCallback(
		(adult: boolean) => {
			onClose && onClose(adult);
		},
		[onClose]
	);

	return (
		<PopupWrapper open={open} fullWidth maxWidth={"sm"} modalHeight={"100vh"} customStyle={"padding: 0;"}>
			<Content>
				<Title>You must be 18+ to view this content</Title>
				<StyledAvatar title={title} image={imgUrl} />
				<Description>
					<span>{workspace?.name}</span> is creating content you must be 18+ to view. Are you 18 years of age or older?
				</Description>
				<BtnWrapper>
					<Button onClick={() => handleClose(true)}>Yes, I’m 18 or older</Button>
				</BtnWrapper>
			</Content>
		</PopupWrapper>
	);
};

export default AdultPopup;
