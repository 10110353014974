import React, { useCallback, useEffect, useMemo, useState } from "react";

import { DateTime } from "luxon";

import { UserRoles } from "types";

import { TeamRoles } from "types/UserRoles";

import { ReactComponent as GearIcon } from "assets/icons/gear.svg";
import { PageTemplate } from "modules/Manage/View/Components";
import { getStatusByValue } from "modules/Members/View/Containers/Members/StatusFilter";
import { StatusText } from "modules/Members/View/Containers/Members/style";
import { useTeam } from "modules/Team/Data";
import { MenuDots, PlaceholderImage, TableWrapper } from "shared/Components";
import { TableColumnLoaderType } from "shared/Components/NewTable/Components";
import { Cell } from "shared/Components/NewTable/style";
import { useDebounce, useMembers, useUser } from "shared/hooks";

import { MemberStatus, PlaceholderImageType, ProfilePhotoSizes } from "shared/types";
import { Icon, Snackbar } from "shared/ui-kit";
import { getResizedImage } from "utils/serviceUtils/cdnImages";
import { formatCount } from "utils/serviceUtils/helpers";

import { AddTeamMemberModal } from "./components/AddTeamMemberModal";
import { UserDetails } from "./style";

const Table = React.lazy(() => import("shared/Components/NewTable"));

const Team = () => {
	const { getData: getUserData } = useUser();
	const { user } = getUserData();

	const [keyword, setKeyword] = useState<string>("");

	const {
		getTeamMembers,
		cancelInviteTeamMember,
		deleteTeamMember,
		editRoleTeamMember,
		resendInviteTeamMember,
		setStateOfModal,
		setIsInvited,
		setShowToastMessage,
		setEditMemberRole,
		setPage,
		setTeamMemberShowPerPage,
		inviteTeamMember,
		getMemberTotalCount,
		getData: getTeamData
	} = useTeam();
	const { toastMessage, users, showToastMessage, page, teamMemberShowPerPage, loadingTeamMembers, totalCount } =
		getTeamData();

	const { bulkBanMember, bulkUnbanMember } = useMembers();

	const debouncedKeyword = useDebounce(keyword, 400);

	useEffect(() => {
		toastMessage && setShowToastMessage(true);
	}, [
		cancelInviteTeamMember,
		deleteTeamMember,
		resendInviteTeamMember,
		toastMessage,
		setShowToastMessage,
		setIsInvited,
		debouncedKeyword
	]);

	useEffect(() => {
		getTeamMembers({
			offset: page,
			limit: teamMemberShowPerPage,
			type: "team",
			keyword: debouncedKeyword
		});
	}, [getTeamMembers, debouncedKeyword, page, teamMemberShowPerPage]);

	useEffect(() => {
		getMemberTotalCount({
			type: "team",
			keyword: debouncedKeyword
		});
	}, [getMemberTotalCount, debouncedKeyword]);

	const getOptions = useCallback(
		(rowData: { _id; status; email; personaId; role }) => {
			const { _id, status, email, personaId, role } = rowData;
			const userIds = [_id];

			return [
				{
					name: status === MemberStatus.deleted ? "Invite Again" : "Resend Invitation",
					showOnPending: true,
					showOnJoined: false,
					showOnOnboarded: false,
					showIfLoggedUser: false,
					showOnDeleted: true,
					onClick: async () => {
						setStateOfModal({
							modalTitle: status === MemberStatus.deleted ? "Invite" : "Resend Invitation",
							modalContent: status === MemberStatus.deleted ? "inviteContent" : "resendInviteContent",
							modalFooterActionsText: {
								cancelText: "Cancel",
								submitText: status === MemberStatus.deleted ? "Invite" : "Resend invitation",
								submitId: status === MemberStatus.deleted ? "InvitationConfirmation" : "resendInvitationConfirmation",
								cancelId: status === MemberStatus.deleted ? "cancelInvitation" : "cancelResendInvitation"
							},
							apiRequest: status === MemberStatus.deleted ? inviteTeamMember : resendInviteTeamMember,
							confirmActionMessage: `Are you sure you want to ${
								status === MemberStatus.deleted ? "invite again" : "resend the invitation to"
							} ${email}?`,
							apiRequestParams:
								status === MemberStatus.deleted
									? { emails: [email], role: TeamRoles[role] || null, slug: "administrators" }
									: userIds
						});
					},
					id: "resendInvitation"
				},
				{
					name: "Cancel Invitation",
					showOnPending: true,
					showOnJoined: false,
					showOnOnboarded: false,
					showIfLoggedUser: false,
					onClick: async () => {
						setStateOfModal({
							modalTitle: "Cancel Invitation",
							modalContent: "cancelInviteContent",
							modalFooterActionsText: {
								cancelText: "Cancel",
								submitText: "Cancel Invite",
								submitId: "cancelInviteConfirmation",
								cancelId: "cancelInvite"
							},
							apiRequest: cancelInviteTeamMember,
							confirmActionMessage: `Are you sure you want to revoke the invitation for ${email}?`,
							apiRequestParams: userIds
						});
					},
					id: "cancelInvitation"
				},
				{
					name: "Change Role",
					showOnPending: false,
					showOnJoined: true,
					showOnOnboarded: true,
					showIfLoggedUser: true,
					onClick: async () => {
						setEditMemberRole(_id);

						setStateOfModal({
							modalTitle: "Change Role",
							modalContent: "changeRole",
							apiRequest: editRoleTeamMember
						});
					},
					id: "changeRole"
				},
				{
					name: "Ban user",
					showOnPending: false,
					showOnJoined: true,
					showOnOnboarded: true,
					showOnModerator: true,
					showOnArchive: true,
					showOnAdmin: user?.role === UserRoles.Admin || user?.role === UserRoles.Owner,
					showOnOwner: user?.role === UserRoles.Owner,
					showOnBanned: false,
					onClick: async () => {
						setStateOfModal({
							modalTitle: "Ban User",
							modalContent: "banMember",
							confirmActionMessage: `Are you sure you want to ban ${email} in your team?`,
							apiRequest: bulkBanMember,
							modalFooterActionsText: {
								cancelText: "Cancel",
								submitText: "Ban from admin team",
								submitId: "banMember",
								cancelId: "cancelBanMember"
							},
							apiRequestParams: userIds
						});
					},
					id: "banUser"
				},
				{
					name: "Unban user",
					showOnPending: false,
					showOnJoined: false,
					showOnOnboarded: false,
					showOnModerator: false,
					showOnArchive: false,
					showOnAdmin: false,
					showOnOwner: false,
					showOnBanned: true,
					onClick: async () => {
						setStateOfModal({
							modalTitle: "Unban User",
							modalContent: "unBanMember",
							confirmActionMessage: `Are you sure you want to Unban ${email} in your team?`,
							apiRequest: bulkUnbanMember,
							modalFooterActionsText: {
								cancelText: "Cancel",
								submitText: "Unban from admin team",
								submitId: "unBanMember",
								cancelId: "cancelUnBanMember"
							},
							apiRequestParams: userIds
						});
					},
					id: "unBanUser"
				},
				{
					name: "Remove from admin team",
					showOnPending: false,
					showOnJoined: true,
					showOnOnboarded: true,
					showIfLoggedUser: false,
					onClick: async () => {
						setStateOfModal({
							modalTitle: "Remove from admin team",
							modalContent: "removeAdminContent",
							modalFooterActionsText: {
								cancelText: "Cancel",
								submitText: "Remove from admin team",
								submitId: "removeFromAdminTeam",
								cancelId: "cancelRemoveFromAdminTeam"
							},
							apiRequest: editRoleTeamMember,
							confirmActionMessage: `Are you sure you want to remove ${email} from your team? This will not remove the user from the community, however, they will no longer have management permissions`,
							apiRequestParams: { slug: "administrators", personaId: personaId }
						});
					},
					id: "removeFromAdminTeam"
				},
				{
					name: "Remove from community",
					showOnPending: false,
					showOnJoined: true,
					showOnOnboarded: true,
					showIfLoggedUser: false,
					onClick: async () => {
						setStateOfModal({
							modalTitle: "Remove from community",
							modalContent: "removeFromCommunity",
							modalFooterActionsText: {
								cancelText: "Cancel",
								submitText: "Remove from community",
								submitId: "removeFromCommunity",
								cancelId: "cancelRemoveFromCommunity"
							},
							apiRequest: deleteTeamMember,
							confirmActionMessage: `Are you sure you want to remove ${email} from your community? This will remove the user from the community entirely`,
							apiRequestParams: userIds
						});
					},
					id: "removeFromCommunity"
				}
			].filter(item => {
				if (status === MemberStatus.pending) return item.showOnPending;
				if (status === MemberStatus.joined) return item.showOnJoined;
				if (status === MemberStatus.banned) return item.showOnBanned;
				if (status === MemberStatus.deleted) return item.showOnDeleted;
				if (user?.userId === userIds.toString()) return item.showIfLoggedUser;
				return item.showOnOnboarded;
			});
		},
		[
			cancelInviteTeamMember,
			deleteTeamMember,
			editRoleTeamMember,
			user,
			resendInviteTeamMember,
			setEditMemberRole,
			setStateOfModal,
			bulkBanMember,
			bulkUnbanMember,
			inviteTeamMember
		]
	);

	const tableColumns = useMemo(
		() => [
			{
				alignment: "left",
				label: <Cell.HeaderText>{formatCount(totalCount, "Team Member")}</Cell.HeaderText>,
				minWidth: 280,
				Cell: function cellRender({ rowData: { id, firstName, lastName, email, status, photos, personaId } }) {
					const icon = photos?.length ? photos[0]?.profilePicture : undefined;

					const coverImgUrl = !!icon ? getResizedImage(icon, ProfilePhotoSizes.size200x200) : undefined;

					return (
						<Cell.Wrapper key={id}>
							<Cell.ImageWrapper>
								{status === MemberStatus.pending ? (
									<Icon fill="#8f9bb3" name="clock" />
								) : (
									<>
										{coverImgUrl ? (
											<Cell.Image src={coverImgUrl} />
										) : (
											<PlaceholderImage
												type={PlaceholderImageType.PROFILE_IMAGE}
												width={40}
												height={40}
												viewBox={"0 0 400 400"}
											/>
										)}
									</>
								)}
							</Cell.ImageWrapper>
							<Cell.Wrapper className="column with-image">
								<Cell.Text>
									{status === MemberStatus.pending || status === MemberStatus.joined
										? personaId || (!personaId && status === MemberStatus.joined)
											? "Onboarding Pending..."
											: "Invitation pending..."
										: `${firstName} ${lastName}`}
								</Cell.Text>
								<Cell.Text className="light">{email}</Cell.Text>
							</Cell.Wrapper>
						</Cell.Wrapper>
					);
				},
				loaderTemplate: TableColumnLoaderType.imageWthTwoTextRows,
				dataKey: "name"
			},
			{
				alignment: "left",
				label: <Cell.HeaderText>Date Added</Cell.HeaderText>,
				Cell: function cellRender({ rowData: { createdAt } }) {
					return (
						<Cell.Wrapper>
							<Cell.Text>{DateTime.fromISO(createdAt).toFormat("LLLL dd, yyyy")}</Cell.Text>
						</Cell.Wrapper>
					);
				},
				dataKey: "createdAt"
			},
			{
				alignment: "left",
				label: <Cell.HeaderText>Role</Cell.HeaderText>,
				Cell: function cellRender({ rowData: { _id, role, status } }) {
					const { Admin, Owner } = UserRoles;

					const allowToChange =
						(user?.role === Owner || (user?.role === Admin && role !== Owner)) &&
						![MemberStatus.pending, MemberStatus.banned, MemberStatus.deleted].includes(status) &&
						user?.userId !== _id;

					const showChangeRoleModal = () => {
						setEditMemberRole(_id);

						setStateOfModal({
							modalTitle: "Change Role",
							modalContent: "changeRole",
							apiRequest: editRoleTeamMember
						});
					};

					return (
						<UserDetails.Wrapper
							className={allowToChange && "pointer"}
							onClick={() => allowToChange && showChangeRoleModal()}
						>
							<UserDetails.Text align="left" display="block" paragraph variant="body1">
								{role}
							</UserDetails.Text>
							{allowToChange && (
								<UserDetails.IconWrapper>
									<GearIcon />
								</UserDetails.IconWrapper>
							)}
						</UserDetails.Wrapper>
					);
				},
				dataKey: "role"
			},
			{
				alignment: "left",
				label: <Cell.HeaderText>Status</Cell.HeaderText>,
				Cell: function cellRender({ rowData: { status } }) {
					return (
						<Cell.Wrapper>
							<StatusText align="left" display="block" paragraph variant="body1" status={status}>
								{getStatusByValue(status).label}
							</StatusText>
						</Cell.Wrapper>
					);
				},
				dataKey: "status"
			},
			{
				alignment: "left",
				label: "",
				width: 70,
				Cell: function cellRender({ rowData: { _id, status, email, personaId, role }, rowIndex }) {
					if (
						(user?.role === UserRoles.Admin && role === UserRoles.Owner) ||
						(user?.role === UserRoles.Moderator && role !== UserRoles.Moderator) ||
						user?.userId === _id ||
						user?.role === UserRoles.Member
					)
						return null;

					return (
						<MenuDots
							options={getOptions({ _id, status, email, personaId, role })}
							vertical
							removeBg
							removeshadow
							removeSideMargin
							menuId={`moreAction${rowIndex + 1}`}
						/>
					);
				},
				loaderTemplate: TableColumnLoaderType.menuDots,
				dataKey: "menu"
			}
		],
		[getOptions, user, setEditMemberRole, totalCount, editRoleTeamMember, setStateOfModal]
	);

	const handleSearch = useCallback(
		(keyword: string) => {
			if (keyword === "" || keyword.length >= 3) {
				setKeyword(keyword);
				setPage(1);
			}
		},
		[setPage]
	);

	const handleChangePage = useCallback(
		(newPage: number) => {
			setPage(newPage);
		},
		[setPage]
	);

	const handleChangePageSize = useCallback(
		(newPageSize: number) => {
			setTeamMemberShowPerPage(newPageSize);
			setPage(1);
		},
		[setTeamMemberShowPerPage, setPage]
	);

	const TableBlock = useMemo(
		() => (
			<TableWrapper sizes={{ horizontalTablet: 800 }}>
				<Table
					columns={tableColumns}
					data={users}
					loading={loadingTeamMembers}
					paginated
					totalDataCount={totalCount}
					page={page}
					pageSize={teamMemberShowPerPage}
					onChangePage={handleChangePage}
					onChangePageSize={handleChangePageSize}
				/>
			</TableWrapper>
		),
		[
			tableColumns,
			users,
			loadingTeamMembers,
			totalCount,
			page,
			teamMemberShowPerPage,
			handleChangePage,
			handleChangePageSize
		]
	);

	return (
		<>
			<AddTeamMemberModal />
			<PageTemplate
				title={"Team & Permissions"}
				isLoading={loadingTeamMembers}
				isNoData={!users.length}
				emptyText={"You don’t have any Team members in your community yet."}
				searchPlaceholder={"Search Team Members"}
				onSearchUpdate={handleSearch}
				actionText={"Add Member"}
				onCreateClick={() => {
					setStateOfModal({
						modalTitle: "Add member",
						modalContent: "inviteViaEmail",
						apiRequest: inviteTeamMember
					});

					setIsInvited(false);
				}}
				searchId="searchTeam"
				actionId="addTeamMember"
			>
				{TableBlock}
			</PageTemplate>
			{toastMessage && (
				<Snackbar
					message={toastMessage}
					onClose={() => setShowToastMessage(false)}
					open={showToastMessage}
					showCloseButton
					transition="fade"
				/>
			)}
		</>
	);
};

export default Team;
