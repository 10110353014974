import React from "react";

import { Box, InputAdornment, TextField } from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";

import styled, { css } from "styled-components";

import { Button, FormColumn, Icon, Text } from "shared/ui-kit";

export const CellWrapper = styled(Box)`
	display: flex;
	align-items: center;
`;

export const LoadingTableData = styled(Box)`
	display: flex;
	justify-content: center;
	padding: 40px 0;
`;

export const StyledQRContainer = styled(Box)`
	margin-top: 10px;
	margin-bottom: 10px;
`;

export const Container = styled(Box)`
	width: 100%;
	position: relative;
	max-height: 650px;
`;

export const StyledOptionsWrapper = styled(Box)`
	display: flex;
	align-items: center;
`;

export const StyledOption = styled(Text)`
	color: #222b45;
	font-size: 15px;
	margin-left: 9px;
`;

export const PopupTitle = styled(Text)`
	font-size: 18px;
	font-weight: bold;
	line-height: 1.33;
	color: #222b45;
	text-align: left;
	padding-left: 16px;
`;

export const PopupDescription = styled(Text)`
	font-size: 15px;
	font-style: normal;
	line-height: 1.47;
	color: #8f9bb3;
	margin-bottom: 12px;
`;

export const CreateLinkButton = styled(Button)`
	width: 95%;
	margin: 20px 12px;
	font-size: 16px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
`;

export const Divider = styled(Box)`
	border-top: 1px solid #edf1f7;
	width: 100%;
	margin-top: 10px;
`;

export const GenarateLinkButton = styled(Button)`
	font-size: 14px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	padding: 16px 32px;
`;

export const ShareableLinkTitle = styled(Text)`
	color: #222b45;
	font-size: 15px;
	font-weight: 600;
`;

export const ShareableLinkColumn = styled(FormColumn)`
	width: 100%;
	position: relative;
	.MuiFormHelperText-root {
		position: absolute;
		top: calc(100% + 2px);
	}
	svg {
		fill: #8f9bb3;
	}
`;

export const IncreaseNumberButton = styled(Button)`
	min-width: 0;
	width: 26px;
	height: 40%;
	margin: 0;
	padding: 4px;
	border-radius: 2px;
	background-color: #edf1f7;
	position: absolute;
	right: 4px;
	top: 4px;
	z-index: 10;

	${({ down }) =>
		down &&
		css`
			transform: rotate(180deg);
			top: unset;
			bottom: 4px;
		`}
`;

export const ShareableLinkColumnLarge = styled(ShareableLinkColumn)``;

export const ShareableLinkItemRow = styled(FormColumn)`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 8px;
	${props => props.theme.breakpoints.up("sm")} {
		justify-content: flex-start;
	}
`;

export const StyledButtonContainer = styled(Box)`
	display: flex;
	align-items: center;
`;

export const RefereeInputWithImage = styled(Box)`
	position: relative;
	img {
		width: 20px;
		height: 20px;
		border-radius: 99%;
		position: absolute;
		left: 12px;
		top: calc(50% - 10px);
	}
	.MuiAutocomplete-inputRoot {
		padding-left: ${({ withImg }) => (withImg ? "32px" : "8px")};
	}
`;

const PickerInput = styled(props => (
	<TextField
		{...props}
		startAdornment={
			<InputAdornment position="start">
				<Icon name="calendar" group="filled" />
			</InputAdornment>
		}
	/>
))``;
export const ExpireDatePicker = styled(props => (
	<KeyboardDatePicker {...props} InputAdornmentProps={{ position: "start" }} TextFieldComponent={PickerInput} />
))`
	position: relative;

	.MuiInputBase-root {
		min-width: 190px;
		height: 48px;
		background: white;
	}

	.MuiInputBase-input,
	.MuiOutlinedInput-input {
		font-size: 13px;
		font-weight: 600;
		line-height: 1.85;
		color: #222b45;
	}

	.MuiOutlinedInput-adornedStart {
		padding-left: 10px;
	}

	.MuiButtonBase-root {
		padding: 0;
	}

	.MuiOutlinedInput-notchedOutline {
		top: -5px !important;
	}

	fieldset {
		border-color: #c5cee0;
	}

	.MuiFormHelperText-root {
		position: absolute;
		top: 100%;
	}
`;

export const StyledBtn = styled(Button)`
	white-space: nowrap;
`;
