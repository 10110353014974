import React, { forwardRef, useCallback, useEffect, useImperativeHandle } from "react";

import { Controller, useFieldArray, useForm, useWatch } from "react-hook-form";

import { PriceTag, WorkspaceProductModel } from "shared/types";
import { Checkbox, Text } from "shared/ui-kit";

import { Subscription } from "./styles";

import { Footer } from "../style";

interface SubscriptionModel {
	label: string;
	monthlyId?: string;
	annuallyId?: string;
	monthlyPrice?: number;
	checked: boolean;
}

interface SubscriptionListProps {
	subscriptionProducts: WorkspaceProductModel[];
	defaultValue?: PriceTag[];
	onPriceChanged?: () => void;
}

const SubscriptionList = forwardRef<any, SubscriptionListProps>(
	({ subscriptionProducts, defaultValue, onPriceChanged }, ref) => {
		const {
			control,
			reset,
			getValues,
			setValue,
			formState: { isDirty }
		} = useForm({
			mode: "onChange"
		});

		const { fields } = useFieldArray({ name: "subscriptions", control });

		const subscriptionFields = useWatch({ control, name: "subscriptions" });

		useEffect(() => {
			isDirty && onPriceChanged && onPriceChanged();
		}, [isDirty, onPriceChanged]);

		useEffect(() => {
			const firstDefaultPriceTag = defaultValue && defaultValue?.length ? defaultValue[0] : undefined;
			reset({
				subscriptions: (subscriptionProducts || [])
					.filter(x => x.monthly?.active || x.annually?.active)
					.map(x => ({
						label: x.name,
						monthlyId: x.monthly?._id,
						annuallyId: x.annually?._id,
						monthlyPrice: x.monthly?.price || 0,
						checked:
							firstDefaultPriceTag && firstDefaultPriceTag?.subscriptionProductIds
								? firstDefaultPriceTag.subscriptionProductIds.some(s => s === x.monthly?._id || s === x.annually?._id)
								: false
					}))
					.sort((a, b) => a.monthlyPrice - b.monthlyPrice)
			});
		}, [subscriptionProducts, reset, defaultValue]);

		useImperativeHandle(ref, () => ({
			getFormValues() {
				return getValues();
			}
		}));

		const togglePlan = useCallback(
			(checked: boolean, index: number) => {
				for (let i = 0; i < (subscriptionFields as SubscriptionModel[])?.length; i++) {
					if (i >= index && checked) {
						setValue(`subscriptions[${i}].checked`, true, { shouldDirty: true });
					}

					if (i <= index && !checked) {
						setValue(`subscriptions[${i}].checked`, false, { shouldDirty: true });
					}
				}
			},
			[subscriptionFields, setValue]
		);

		const RenderRows = (p, i: number) => {
			const { label, checked, monthlyId, annuallyId, monthlyPrice } = p;

			return (
				<Subscription.RowWrapper>
					<Controller
						name={`subscriptions[${i}].monthlyId`}
						control={control}
						defaultValue={monthlyId}
						render={({ value }) => <input type="text" hidden value={value} />}
					/>
					<Controller
						name={`subscriptions[${i}].annuallyId`}
						control={control}
						defaultValue={annuallyId}
						render={({ value }) => <input type="text" hidden value={value} />}
					/>
					<Controller
						name={`subscriptions[${i}].checked`}
						control={control}
						defaultValue={checked}
						render={({ value }) => (
							<Subscription.PlanWrapper
								onClick={e => {
									e.stopPropagation();
									e.preventDefault();
									togglePlan(!value, i);
								}}
							>
								<Subscription.CheckboxWrapper>
									<Checkbox checked={value} label={label} />
								</Subscription.CheckboxWrapper>
								<Subscription.PriceWrapper>${monthlyPrice} /month</Subscription.PriceWrapper>
							</Subscription.PlanWrapper>
						)}
					/>
				</Subscription.RowWrapper>
			);
		};

		return (
			<>
				<Text variant="h6">Who can access this post?</Text>
				<Subscription.Block>{fields.map((field, i) => RenderRows(field as any, i))}</Subscription.Block>
				<Footer.Wrapper>
					<Footer.Text>
						Tiers are hierarchial, selecting lower tiers will automatically grant access to higher tiers.
					</Footer.Text>
				</Footer.Wrapper>
			</>
		);
	}
);

export default SubscriptionList;
