import React, { useEffect, useMemo, useState } from "react";

import { FormControl, MenuItem, Select } from "@material-ui/core";

import { Controller, useForm } from "react-hook-form";

import { UserRoles } from "types";

import useConfirmLeavePopup from "shared/Components/ConfirmLeave/hooks/useConfirmLeavePopup";
import { useNotification, useUser } from "shared/hooks";
import { Text } from "shared/ui-kit";

import { useTeam } from "../../../../Data";
import { EmailsAutoComplete } from "../components/EmailsAutocomplete";
import {
	GuideNote,
	StyledButton,
	StyledFormColumn,
	StyledFormColumns,
	StyledFormContainer,
	StyledFormFooter,
	StyledInvalidEmailAddressText
} from "../style";

const AddTeamMemberContent = () => {
	const { inviteTeamMember, setStateOfModal, setEmailAddresses, getData: getTeamData } = useTeam();
	const { emailAddresses, loadingTeamMembers } = getTeamData();

	const { showMessage } = useNotification();

	const { handleSubmit, watch, control } = useForm();

	const { getData: getUserData } = useUser();
	const { user } = getUserData();

	const RoleOptionsNewMember = [
		{
			label: "Owner",
			value: "isOwner",
			showOnModerator: false,
			showOnAdmin: false,
			showOnOwner: true
		},
		{
			label: "Admin",
			value: "isAdmin",
			showOnModerator: false,
			showOnAdmin: true,
			showOnOwner: true
		},
		{
			label: "Moderator",
			value: "isModerator",
			showOnModerator: true,
			showOnAdmin: true,
			showOnOwner: true
		},
		{
			label: "Influencer",
			value: "isInfluencer",
			showOnModerator: true,
			showOnAdmin: true,
			showOnOwner: true
		}
	].filter(item => {
		if (user?.role === UserRoles.Moderator) return item.showOnModerator;
		if (user?.role === UserRoles.Admin) return item.showOnAdmin;
		if (user?.role === UserRoles.Owner) return item.showOnOwner;
		return item;
	});

	const onSubmit = ({ invitedMemberRole }) => {
		if (!emailAddresses?.length) {
			showMessage("Please add at least one email");
			return;
		}

		if (invitedMemberRole === "placeholder") {
			showMessage("Please select role");
			return;
		}

		setStateOfModal({ isOpen: false });

		const emails = emailAddresses?.map(email => email.email);

		inviteTeamMember({
			emails,
			slug: "administrators",
			role: invitedMemberRole
		});

		setEmailAddresses({ emailAddresses: [] });
	};

	const validEmailsBoolArray = useMemo(() => {
		return emailAddresses?.map(email => email.valid);
	}, [emailAddresses]);

	const [emailErrors, setEmailError] = useState<string[]>([]);

	useEffect(() => {
		const errors: string[] = [];
		emailAddresses
			.filter(email => !email.valid)
			.forEach(email => {
				if (email.message && !errors.includes(email.message)) {
					errors.push(email.message);
				}
			});
		setEmailError(errors);
	}, [emailAddresses]);

	const { provideHandleCloseValues } = useConfirmLeavePopup({});

	const { invitedMemberRole } = watch(["invitedMemberRole"]);

	useEffect(() => {
		const valsToCheck = invitedMemberRole !== "placeholder" || !!emailAddresses.length;

		provideHandleCloseValues(valsToCheck);
	}, [provideHandleCloseValues, invitedMemberRole, emailAddresses.length]);

	return (
		<StyledFormContainer
			alignContent="center"
			container
			direction="column"
			item
			justify="center"
			spacing={2}
			wrap="nowrap"
			onSubmit={handleSubmit(onSubmit)}
		>
			<StyledFormColumns>
				<FormControl component={StyledFormColumn} xs={12} sm={8}>
					<Controller
						name="invitedMembersEmails"
						control={control}
						defaultValue={emailAddresses?.map(email => email)}
						as={<EmailsAutoComplete />}
						id="invitedMembersEmails"
					/>
					{emailErrors.map(emailError => (
						<StyledInvalidEmailAddressText key={emailError}>{emailError}</StyledInvalidEmailAddressText>
					))}
				</FormControl>
				<FormControl component={StyledFormColumn} xs={12} sm={4}>
					<Controller
						name="invitedMemberRole"
						as={Select}
						control={control}
						variant="outlined"
						defaultValue="placeholder"
						id="invitedMemberRole"
						style={{ display: "flex", padding: "4px 0" }}
					>
						<MenuItem className="menuItem" value="placeholder" disabled id="selectRole">
							<Text>Select Role</Text>
						</MenuItem>
						{RoleOptionsNewMember.map((item, index) => (
							<MenuItem key={index} className="menuItem" value={item.value} id={item.label}>
								<Text>{item.label}</Text>
							</MenuItem>
						))}
					</Controller>
				</FormControl>
			</StyledFormColumns>
			<GuideNote className="ml-12 mt-1">Use commas and semicolons or press Enter to separate emails</GuideNote>
			<StyledFormFooter textAlign="right">
				<StyledButton
					buttonTheme="main"
					type="submit"
					palette="primary"
					size="large"
					disabled={!validEmailsBoolArray?.every(Boolean) || emailAddresses.length === 0 || loadingTeamMembers}
					id="addMember"
				>
					Add Member
				</StyledButton>
			</StyledFormFooter>
		</StyledFormContainer>
	);
};

export default AddTeamMemberContent;
