import { IconButton } from "@material-ui/core";
import styled from "styled-components";

import { Box, Text } from "shared/ui-kit";

export const ChatWrapper = styled(Box)`
	height: 100%;
	width: 100%;

	display: flex;
	flex-direction: column;
	justify-content: flex-start;

	&.rounded-corners {
		border-radius: 8px;
	}
`;

export const StyledChat = styled(Box)`
	height: calc(100% - 72px);
	${props => props.theme.breakpoints.up("sm")} {
		padding-top: 16px;
	}
`;

export const ChatAction = styled(Box)`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;

	border-bottom: 1px solid #c5cee057;

	width: 100%;
	height: 72px;

	padding: 10px 24px;

	&.loading {
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;
	}

	${props => props.theme.breakpoints.down("sm")} {
		padding: 10px 16px;
	}
`;

export const ChatInfoBlock = styled(Box)`
	display: flex;
	align-items: center;
`;

export const BackBtnWrapper = styled(Box)`
	display: none;

	margin-right: 5px;

	${props => props.theme.breakpoints.down("md")} {
		display: flex;
	}

	svg {
		width: 20px;
	}
`;

export const ChatInfo = styled(Box)`
	display: flex;
	align-items: center;
	img {
		width: 36px;
		height: 36px;
		margin-right: 8px;
		border-radius: 8px;
		border: solid 1px #fff;
		object-fit: cover;

		${props => props.theme.breakpoints.up("sm")} {
			width: 48px;
			height: 48px;
			margin-right: 16px;
		}
	}
`;

export const ChatIconGroup = styled(Box)`
	display: flex;
	justify-content: flex-end;
	align-items: center;
`;

export const ChatIconWrapper = styled(Box)`
	margin: 0 5px;

	width: 30px;
	height: 30px;

	display: flex;
	align-items: center;
	justify-content: center;

	&:last-child {
		margin-right: 0;
	}
`;

export const ChatTitle = styled(Text)`
	margin: 0 8px 0 0;
	font-size: 15px;
	font-weight: 600;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.6;
	letter-spacing: normal;
	color: #222b45;

	${props => props.theme.breakpoints.down("sm")} {
		max-width: 150px;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}
`;

export const ChatStatus = styled(Text)`
	font-size: 12px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.33;
	letter-spacing: normal;
	color: #23b34a;
`;

export const StyledIconButton = styled(IconButton)`
	padding: 0;

	svg {
		width: 24px;
		height: 24px;

		margin: 0 !important;
	}

	&.active svg {
		fill: #ffc400;
	}

	&.phone {
		svg {
			width: 26px;
		}
	}

	&.camera {
		svg {
			width: 24px;
		}
	}

	&.options {
		width: 30px;
		height: 30px;

		.MuiIconButton-label {
			width: 100%;
			height: 100%;

			& > div {
				width: 100%;
				height: 100%;
			}
		}
	}
	svg {
		fill: #8f9bb3;
	}
`;
