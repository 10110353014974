import React, { FC } from "react";

import { Button, Text } from "shared/ui-kit";

import { NoMutual } from "./style";

const NoProfileDataTemp: FC<{
	icon: JSX.Element;
	title?: string;
	subtitle?: string;
	actions?: { icon?: JSX.Element; title: string; onClick: () => void }[];
}> = ({ icon, title, subtitle, actions }) => (
	<NoMutual>
		{icon}
		<Text variant="h2">{title || "It's empty here yet"}</Text>
		{subtitle && <Text className="secondary">{subtitle}</Text>}
		<NoMutual.Actions>
			{actions?.map((ac, i) => (
				<Button palette="primary" onClick={ac.onClick} key={i}>
					{ac.icon} {ac.title}
				</Button>
			))}
		</NoMutual.Actions>
	</NoMutual>
);

export default NoProfileDataTemp;
