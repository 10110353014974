import styled from "styled-components";

import { Box, Button, Text } from "shared/ui-kit";

export const Header = styled(Box)`
	margin: 0 0 56px;
	padding: 63px 88px 37px 120px;

	width: 100%;

	display: flex;
`;

Header.InfoBlock = styled(Box)`
	width: 400px;

	margin-right: 72px;
	margin-top: 90px;
`;

Header.Title = styled(Text)`
	color: #222b45;
	font-weight: bold;
	font-size: 32px;
`;

Header.Description = styled(Text)`
	color: #222b45;
	font-size: 15px;
	margin-top: 12px;
	margin-bottom: 32px;
`;

Header.Button = styled(Button)`
	margin: 0;
`;

Header.Illustration = styled.img`
	width: 376px;
	height: 342px;

	position: absolute;
	right: 72px;
`;

export const Container = styled(Box)`
	display: block;
	width: 100%;
	position: relative;
`;

export const Content = styled(Box)``;

Content.Wrapper = styled(Box)`
	width: 100%;

	padding: 56px 120px;

	${props => props.theme.breakpoints.down("sm")} {
		padding: 56px 32px;
	}
`;

Content.TitleWrapper = styled(Box)`
	display: flex;
	align-items: center;
`;

Content.Title = styled(Text)`
	font-size: 32px;
	font-weight: bold;
	line-height: 1.25;
	color: #222b45;
`;

Content.Description = styled(Text)`
	font-size: 15px;
	font-weight: 600;
	line-height: 1.6;
	color: #222b45;

	margin-top: 12px;
`;

Content.Child = styled(Box)`
	margin-top: 65px;
`;
