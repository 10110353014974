import React, { ReactNode, useCallback, useEffect, useMemo } from "react";

import * as R from "ramda";

import { ReactComponent as ArchiveIcon } from "assets/icons/icon-archive.svg";

import SmartScroll from "shared/Components/SmartScroll";
import { useAnalytics } from "shared/hooks";

import { SummaryMetricType } from "shared/types";
import { Icon } from "shared/ui-kit";

import ProgressCircle from "./ProgressCircle";
import { BodyWrapper, Metric, Wrapper } from "./styles";

import BlockHeader from "../BlockHeader";

interface MetricType {
	label: string;
	key: string;
	icon?: ReactNode;
	isPercent?: boolean;
}

const SummaryBlock: React.FC = () => {
	const { getMetrics, getMetricsCSVFile, getData: getAnalyticsData } = useAnalytics();
	const { summaryMetrics } = getAnalyticsData();

	const metrics = useMemo(
		(): MetricType[] => [
			{
				label: "Retention rate: Day 1",
				key: "retention.1Day",
				isPercent: true
			},
			{
				label: "Retention rate: Day 7",
				key: "retention.7Day",
				isPercent: true
			},
			{
				label: "Retention rate: Day 21",
				key: "retention.21Day",
				isPercent: true
			},
			{
				label: "Retention rate: Day 30",
				key: "retention.30Day",
				isPercent: true
			},
			{
				label: "Churn Rate",
				key: "churnRate",
				isPercent: true
			},
			{
				label: "Monthly Average Session Length",
				key: "averageSessionLength",
				icon: <Icon fill={"#6173fe"} name={"clock"} />
			},
			{
				label: "Daily sessions",
				key: "dailySessions",
				icon: <Icon group={"filled"} fill={"#6173fe"} name={"id-badge"} />
			},
			{
				label: "Daily sessions per active user",
				key: "dailySessionsPerDailyActiveUser",
				icon: <Icon group={"filled"} fill={"#6173fe"} name={"chart-doughnut"} />
			},
			{
				label: "Daily Active Users",
				key: "dailyActiveUsers",
				icon: <ArchiveIcon />
			},
			{
				label: "Monthly Active User",
				key: "monthlyActiveUsers",
				icon: <ArchiveIcon />
			},
			{
				label: "Stickiness",
				key: "stickiness",
				isPercent: true
			}
		],
		[]
	);

	useEffect(() => {
		getMetrics();
	}, [getMetrics]);

	const getValue = useCallback((summaryMetrics: SummaryMetricType, key: string) => {
		if (key.includes(".")) {
			return R.pathOr("0", key.split("."), summaryMetrics);
		} else {
			if (key === "dailySessionsPerDailyActiveUser") {
				return (summaryMetrics[key] || 0).toFixed(2);
			}
			return summaryMetrics[key] || "0";
		}
	}, []);

	return (
		<Wrapper>
			<BlockHeader
				title="Summary Metrics"
				showDropdown={false}
				onDownloadCsv={getMetricsCSVFile}
				csvFilename="metrics_report.csv"
				displayDownloadText
			/>
			<SmartScroll height="350px">
				<BodyWrapper>
					{metrics.map((metric, index) => (
						<Metric.Item key={index}>
							<Metric.ItemIconWrapper className={!metric?.isPercent && "with-icon"}>
								{metric?.icon ? (
									metric.icon
								) : (
									<ProgressCircle
										progress={Number(
											getValue(summaryMetrics, metric.key)
												.toString()
												.replace(/[^\d.]/g, "")
										)}
										size={58}
									/>
								)}
							</Metric.ItemIconWrapper>
							<Metric.ValueWrapper>
								<Metric.ValueRow>
									<Metric.Value>{getValue(summaryMetrics, metric.key)}</Metric.Value>
								</Metric.ValueRow>
								<Metric.Label>{metric.label}</Metric.Label>
							</Metric.ValueWrapper>
						</Metric.Item>
					))}
				</BodyWrapper>
			</SmartScroll>
		</Wrapper>
	);
};

export default SummaryBlock;
