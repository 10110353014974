import { AuthApp, GlobalUserApp, MemberApp, OnboardingApp, RegularUserApp } from "apps";
import config from "config/appConfig";
import IApp from "types/IApp";

export default (): { apps: IApp[] } => {
	const authApp = new AuthApp();
	const onboardingApp = new OnboardingApp();
	const regularUserApp = new RegularUserApp();
	const memberApp = new MemberApp();
	const globalUserApp = new GlobalUserApp();

	const apps: any[] = [authApp, onboardingApp, globalUserApp]; // todo: remove when any app be implemented

	if (!config.GLOBAL_CONSTANTS.HIDE_MEMBERS_APP) {
		apps.push(memberApp);
	}
	apps.push(regularUserApp);

	return {
		apps
	};
};
