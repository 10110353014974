import { ButtonBase } from "@material-ui/core";
import styled from "styled-components";

export const MemberOption = styled(ButtonBase)`
	border-radius: 8px;
	height: 48px;
	width: 100%;

	.invited {
		color: #8f9bb3;
		font-size: 12px;
		line-height: 16px;
	}

	&.active {
		background-color: #f7f9fc;
	}
`;
