import React, { Fragment, useCallback, useEffect, useMemo, useState } from "react";

import { Dialog } from "shared/Components";
import ConfirmLeavePopup from "shared/Components/ConfirmLeave";
import useConfirmLeavePage from "shared/Components/ConfirmLeave/hooks/useConfirmLeavePage";
import { AutoConnectFeature, AutomatedMessagingFeature } from "shared/constants";

import { useFeature, useNotification } from "shared/hooks";
import { Button } from "shared/ui-kit";

import AutoConnect from "./AutoConnect";
import AutoMessage from "./AutoMessage";

import { Description, FeatureList, FeatureWrapper, PageContainer, PopupTitle, Title } from "./style";

import { FeatureCard } from "../../Components";

const AutoconnectMessaging: React.FC = () => {
	const { updateMeta, getDetails, isFeatureEnabled, getData: getFeatureData } = useFeature();
	const { autoConnectData, autoMessageData } = getFeatureData();

	const { showMessage } = useNotification();

	const [enableAutoconnect, setEnableAutoconnect] = useState(false);
	const [enableAutomatedMessaging, setEnableAutomatedMessaging] = useState(false);

	const [unsavedAutoconnectChanges, setUnsavedAutoconnectChanges] = useState(false);
	const [unsavedAutomatedMessageChanges, setUnsavedAutomatedMessageChanges] = useState(false);

	const [changeableFeature, setChangeableFeature] = useState<{ title: string; value: string }>({
		title: "",
		value: ""
	});

	const isSupportChat = useMemo(() => isFeatureEnabled("enableChat"), [isFeatureEnabled]);
	const isSupportConnecting = useMemo(() => isFeatureEnabled("enableConnecting"), [isFeatureEnabled]);

	const pageTitle = useMemo(
		() =>
			isSupportChat && isSupportConnecting
				? "Autoconnect / Message"
				: isSupportConnecting
				? "Auto Connect"
				: isSupportChat
				? "Automated Message"
				: "",
		[isSupportChat, isSupportConnecting]
	);

	const valuesToCheck = useMemo(
		() =>
			!!changeableFeature.title ||
			!!changeableFeature.value ||
			unsavedAutoconnectChanges ||
			unsavedAutomatedMessageChanges,
		[changeableFeature, unsavedAutoconnectChanges, unsavedAutomatedMessageChanges]
	);

	const {
		handleLeavePageConfirmed,
		closeConfirmPopup,
		getData: getConfirmLeavePopupData
	} = useConfirmLeavePage({
		valuesToCheck
	});
	const { showConfirmPopup } = getConfirmLeavePopupData();

	useEffect(() => {
		getDetails();
	}, [getDetails]);

	useEffect(() => {
		autoConnectData.length && setEnableAutoconnect(true);
		autoMessageData.promotionText && autoMessageData?.conversationPersonas.length && setEnableAutomatedMessaging(true);
	}, [autoConnectData, autoMessageData]);

	const handleUpdate = (featureTitleValue: string, enabled: boolean) => {
		if (enabled) {
			featureTitleValue === AutoConnectFeature.value && setEnableAutoconnect(true);
			featureTitleValue === AutomatedMessagingFeature.value && setEnableAutomatedMessaging(true);
		} else {
			setChangeableFeature({
				title:
					featureTitleValue === AutoConnectFeature.value ? AutoConnectFeature.title : AutomatedMessagingFeature.title,
				value: featureTitleValue
			});
		}
	};

	const updateSettings = useCallback(
		async (confirmed: boolean) => {
			const { title, value } = changeableFeature;

			setChangeableFeature({
				title: "",
				value: ""
			});

			if (confirmed) {
				const payload =
					value === AutoConnectFeature.value
						? { automatedConnectionPersonaIds: [] }
						: { conversationPersonaIds: [], promotionText: "" };

				if (value === AutoConnectFeature.value) {
					setEnableAutoconnect(false);
				} else {
					setEnableAutomatedMessaging(false);
				}

				const response = await updateMeta(payload);
				if (response.success) {
					getDetails();
					showMessage(`${title} was successfully disabled.`);
				}
			}
		},
		[changeableFeature, updateMeta, showMessage, getDetails]
	);

	const ActionFooter = useMemo(
		() => (
			<Fragment>
				<Button buttonTheme={"outline"} size={"large"} onClick={() => updateSettings(false)} id="keep">
					Keep
				</Button>
				<Button size={"large"} onClick={() => updateSettings(true)} id="disable">
					Disable
				</Button>
			</Fragment>
		),
		[updateSettings]
	);

	return (
		<>
			<ConfirmLeavePopup
				handleLeavePage={handleLeavePageConfirmed}
				open={showConfirmPopup}
				onClose={closeConfirmPopup}
			/>
			<PageContainer>
				<Title variant="h1">{pageTitle}</Title>
				<FeatureList>
					{isSupportConnecting && (
						<FeatureWrapper>
							<FeatureCard
								title={AutoConnectFeature.title}
								text={AutoConnectFeature.text}
								icon={AutoConnectFeature.icon}
								enabled={enableAutoconnect}
								customWrapperText
								customStyle={enableAutoconnect ? "" : "align-items: center;"}
								customRadioStyle="justify-content: flex-end; width: 130px;"
								handleChange={enabled => handleUpdate(AutoConnectFeature.value, enabled)}
								switchId="autoconnectToggle"
							/>
							{enableAutoconnect && <AutoConnect onUnsavedChanges={setUnsavedAutoconnectChanges} />}
						</FeatureWrapper>
					)}
					{isSupportChat && (
						<FeatureWrapper>
							<FeatureCard
								title={AutomatedMessagingFeature.title}
								text={AutomatedMessagingFeature.text}
								icon={AutomatedMessagingFeature.icon}
								enabled={enableAutomatedMessaging}
								customWrapperText
								customStyle={enableAutomatedMessaging ? "" : "align-items: center;"}
								customRadioStyle="justify-content: flex-end; width: 130px;"
								handleChange={enabled => handleUpdate(AutomatedMessagingFeature.value, enabled)}
								switchId="automatedMessageToggle"
							/>
							{enableAutomatedMessaging && <AutoMessage onUnsavedChanges={setUnsavedAutomatedMessageChanges} />}
						</FeatureWrapper>
					)}
				</FeatureList>
			</PageContainer>
			<Dialog
				title={
					<PopupTitle>
						{changeableFeature.value === AutoConnectFeature.value ? "Disable Autoconnect" : "Disable AutoMessages"}
					</PopupTitle>
				}
				open={!!changeableFeature.value}
				onClose={() => updateSettings(false)}
				footer={ActionFooter}
				hasBackButton={false}
				onBack={() => updateSettings(false)}
			>
				<Description>Are you sure you want to disable {changeableFeature.title}?</Description>
				<Description>
					The changes will take effect immediately and all your new community members will be impacted.
				</Description>
			</Dialog>
		</>
	);
};

export default AutoconnectMessaging;
