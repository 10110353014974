import React, { FC, memo, useCallback, useEffect, useState } from "react";

import { Box } from "@material-ui/core";

import { useHistory } from "react-router-dom";

import { ManInLotus, MessageText, NoConnectionsBlock } from "modules/MemberHome/View/Containers/Connections/style";
import { RequestTemplateWrapper } from "modules/MemberHome/View/Containers/Requests/style";
import { BookRequestDialog, CreateRequestsDialog, RequestCard } from "modules/MemberHome/View/shared";
import SignUpRequestDialog from "modules/MemberHome/View/shared/Requests/SignUpRequestDialog";
import { useFirebase, useMessaging } from "modules/Messaging/Data";
import { ConfirmActionModal, MenuDots, MenuDotsOption, PlaceholderImage } from "shared/Components";

import { useCarpool, useCommunity, useMemberRoutes, useUser } from "shared/hooks";
import { EventModel, MediaModel, PlaceholderImageType } from "shared/types";

import { EventRequests, OfferRequest } from "shared/types/EventModel";
import { Button, Icon, Text } from "shared/ui-kit";

import { formatCount } from "utils/serviceUtils/helpers";

import { Divider } from "../../../../Containers/Requests/style";
import BoxItem from "../../../BoxItem";
import { GroupSectionHeadline } from "../../../GroupDetails/style";
import { SecondaryText } from "../../styles";

const EventsCarpool: FC<{
	event: EventModel | null;
	canCreate?: boolean;
	isEventEnded: boolean;
	isEventHappening: boolean;
}> = memo(({ event, isEventEnded, isEventHappening }) => {
	const { getData: getUserData } = useUser();
	const { user } = getUserData();
	const [open, setOpen] = useState(false);
	const [signUpDialog, setSignUpDialog] = useState<{
		open: boolean;
		eventDetail?: EventModel;
		request?: OfferRequest;
	}>({
		open: false
	});
	const [bookDialog, setBookDialog] = useState<{
		open: boolean;
		eventDetail?: EventModel;
		request?: OfferRequest;
		editDetails?: {
			personaDocId: string;
			seatsBooked: number;
		};
	}>({
		open: false
	});

	const [cancelBooking, setCancelBooking] = useState<{
		open: boolean;
		request?: OfferRequest;
		event?: EventModel;
		userPersonaDocId?: string;
	}>({ open: false });

	const [cancelRequest, setCancelRequest] = useState<{
		open: boolean;
		request?: OfferRequest;
		eventId?: string;
		userPersonaDocId?: string;
	}>({ open: false });

	const {
		cancelCarpoolRequest,
		cancelBookingCarpoolRequest,
		setCarpoolRequests,
		setCarpoolOffers,
		getData: getCarpoolData
	} = useCarpool();
	const { carpoolRequests, carpoolOffers } = getCarpoolData();

	const { communityColors } = useCommunity();
	const history = useHistory();
	const { createConnection } = useMessaging();
	const { onSend } = useFirebase();

	const { getMemberRoutesData } = useMemberRoutes();
	const { routes } = getMemberRoutesData();

	useEffect(() => {
		setCarpoolRequests(event?.carpoolRequests || []);
		setCarpoolOffers(event?.carpoolOffers || []);
	}, [setCarpoolRequests, setCarpoolOffers, event?.carpoolRequests, event?.carpoolOffers]);

	const isBookedByYourself = useCallback(
		request => request.bookedSeats.some(b => b.personaDocId === user?.personaDocId),
		[user?.personaDocId]
	);

	const isRequestAvailable = useCallback(
		(request: OfferRequest) => !!request.rideOfferedBy && request.rideOfferedBy !== user?.personaDocId,
		[user?.personaDocId]
	);

	const carpoolBookingHandler = useCallback(
		(rideOfferedBy, personaDocId, event, request) => {
			rideOfferedBy !== personaDocId
				? setBookDialog({ open: true, eventDetail: event, request })
				: cancelCarpoolRequest(request._id, event._id);
		},
		[setBookDialog, cancelCarpoolRequest]
	);

	const sendCancelMessage = useCallback(
		async (request, isBooking: boolean) => {
			const chatResponse = await createConnection(request?.creator.personaId);

			if (chatResponse) {
				onSend({
					messages: [
						{
							text: `${isBooking ? "Booking" : "Ride"} has been canceled`,
							user: {
								_id: user?.profiles[0].chatUserId,
								name: user?.profiles[0].firstName,
								avatar: (user?.profiles[0].photos[0] as MediaModel).profilePicture
							},
							canceledCarpool: true
						}
					],
					chat: chatResponse
				});
				history.push(`${routes?.member.messaging.getPath()}?messageTo=${request.creator.personaId}`);
			}
		},
		[history, onSend, createConnection, routes?.member, user?.profiles]
	);

	const cancelBookingHandler = useCallback(async () => {
		const { open, request, event, userPersonaDocId } = cancelBooking;
		if (open && request && event && userPersonaDocId) {
			const { success } = await cancelBookingCarpoolRequest(
				request._id,
				event.eventId,
				userPersonaDocId,
				() => setCancelBooking({ open: false }),
				true
			);

			if (success && user?.personaDocId !== request?.creator._id) {
				sendCancelMessage(request, true);
			}
		}
	}, [cancelBooking, cancelBookingCarpoolRequest, user?.personaDocId, sendCancelMessage]);

	const cancelRequestHandler = useCallback(async () => {
		const { open, request, eventId, userPersonaDocId } = cancelRequest;
		if (open && request && eventId && userPersonaDocId) {
			const { success } = await cancelCarpoolRequest(
				request._id,
				eventId,
				() => setCancelRequest({ open: false }),
				true
			);

			if (success && user?.personaDocId !== request?.creator._id) {
				sendCancelMessage(request, false);
			}
		}
	}, [cancelRequest, cancelCarpoolRequest, user?.personaDocId, sendCancelMessage]);

	const getOptions = useCallback(
		({ request, event, userPersonaDocId }: { request: OfferRequest; event: EventModel; userPersonaDocId?: string }) => {
			const options: MenuDotsOption[] = [
				{
					icon: <Icon group={"filled"} fill={"#8f9bb3"} name={"pencil"} width={20} height={20} />,
					name: "Edit Booking",
					onClick: () =>
						setBookDialog({
							open: true,
							editDetails: request.bookedSeats.find(m => m.personaDocId === userPersonaDocId),
							eventDetail: event,
							request
						})
				},
				{
					icon: <Icon group={"filled"} fill={"#8f9bb3"} name={"trash"} width={20} height={20} />,
					name: "Cancel Booking",
					onClick: () =>
						setCancelBooking({
							open: true,
							request,
							event,
							userPersonaDocId
						})
				}
			];

			return options;
		},
		[setCancelBooking]
	);

	return (
		<>
			{!!carpoolOffers.length || !!carpoolRequests.length ? (
				<>
					<Box className="flex justify-between items-center w-full">
						<Text variant="h5">Carpool</Text>
						<GroupSectionHeadline bgColor={communityColors.btn}>
							<Button buttonTheme="light" onClick={() => setOpen(true)} disabled={isEventEnded || isEventHappening}>
								<Icon name="plus" group="filled" width={16} height={16} className="fill-last-path" />
								Create Carpool
							</Button>
						</GroupSectionHeadline>
					</Box>
					<BoxItem>
						{!!carpoolRequests.length && (
							<>
								<SecondaryText className="font-semibold" fontSize={13}>
									Carpool Requests
								</SecondaryText>
								<RequestTemplateWrapper className="flex flex-wrap">
									{carpoolRequests.map((request, i) => (
										<RequestCard
											key={i}
											image={
												<Box className="h-16 w-16 h-full overflow-hidden rounded-md">
													{request.creator.photos[0] ? (
														<img
															src={request.creator.photos[0].profilePicture}
															alt={"Creator img"}
															className="h-16 w-16 h-full"
														/>
													) : (
														<PlaceholderImage width={120} height={60} type={PlaceholderImageType.PROFILE_IMAGE} />
													)}
												</Box>
											}
											caption1={"Carpool Request"}
											title={
												isRequestAvailable(request) ? (
													<p className="flex items-center">
														<Icon className="mr-1" fill={"#23b34a"} name={"check-circle"} width={18} height={18} />
														Request fulfilled
													</p>
												) : (
													`${formatCount(request.seats, "seat")} needed`
												)
											}
											titleClassName={isRequestAvailable(request) ? "font-normal text-gray-400 text-sm" : ""}
											caption2={isRequestAvailable(request) ? null : request.location.name}
											customButton={request.creator.personaId === user?.activeProfile}
											buttonText={
												!request.rideOfferedBy && request.rideOfferedBy !== user?.personaDocId
													? "Sign up"
													: isRequestAvailable(request)
													? "Unavailable"
													: "Cancel"
											}
											buttonClassName={request.rideOfferedBy === user?.personaDocId ? "cancel" : ""}
											disabledButton={isRequestAvailable(request)}
											isSquareShape={false}
											badgeColor={"4a99f9"}
											onClick={() => {
												!request.rideOfferedBy && request.rideOfferedBy !== user?.personaDocId
													? event && setSignUpDialog({ open: true, eventDetail: event, request })
													: setCancelRequest({
															open: true,
															request,
															eventId: event?.eventId,
															userPersonaDocId: user?.personaDocId
													  });
											}}
										/>
									))}
								</RequestTemplateWrapper>
							</>
						)}
						{!!carpoolRequests.length && !!carpoolOffers.length && <Divider />}

						{!!carpoolOffers?.length && (
							<>
								<SecondaryText className="font-semibold" fontSize={13}>
									Carpool Offers
								</SecondaryText>
								<RequestTemplateWrapper className="flex flex-wrap">
									{carpoolOffers.map((request, i) => (
										<RequestCard
											key={i}
											image={
												<Box className="h-16 w-16 h-full overflow-hidden rounded-md">
													{request.creator.photos[0] ? (
														<img
															src={request.creator.photos[0].profilePicture}
															alt={"Creator img"}
															className="h-16 w-16 h-full"
														/>
													) : (
														<PlaceholderImage width={120} height={60} type={PlaceholderImageType.PROFILE_IMAGE} />
													)}
												</Box>
											}
											caption1={"Carpool Offer"}
											customButton={
												request.creator.personaId === user?.activeProfile
													? true
													: isBookedByYourself(request) &&
													  event && (
															<MenuDots
																options={getOptions({
																	request,
																	event,
																	userPersonaDocId: user?.personaDocId
																})}
																rounded
																size={{ small: true }}
															/>
													  )
											}
											title={
												isBookedByYourself(request) || (!isBookedByYourself(request) && request.seatsLeft === 0) ? (
													<p className="flex items-center">
														<Icon className="mr-1" fill={"#23b34a"} name={"check-circle"} width={18} height={18} />
														Offer fulfilled
													</p>
												) : (
													`${formatCount(request.seatsLeft, "seat")} available`
												)
											}
											titleClassName={
												isBookedByYourself(request) || (!isBookedByYourself(request) && request.seatsLeft === 0)
													? "font-normal text-gray-400 text-sm"
													: ""
											}
											caption2={
												isBookedByYourself(request)
													? `You booked ${formatCount(
															request.bookedSeats.find(m => m.personaDocId === user?.personaDocId)?.seatsBooked || 0,
															"seat"
													  )}`
													: request.location.name
											}
											buttonText={!isBookedByYourself(request) && request.seatsLeft === 0 ? "Unavailable" : "Book"}
											buttonClassName={isBookedByYourself(request) ? "cancel" : ""}
											disabledButton={!isBookedByYourself(request) && request.seatsLeft === 0}
											isSquareShape={false}
											badgeColor={"23b34a"}
											onClick={() =>
												user?.personaDocId &&
												event?.eventId &&
												carpoolBookingHandler(request.rideOfferedBy, user?.personaDocId, event, request)
											}
										/>
									))}
								</RequestTemplateWrapper>
							</>
						)}
					</BoxItem>
				</>
			) : (
				<BoxItem>
					<NoConnectionsBlock>
						<ManInLotus />
						<MessageText>You don’t have any Carpool offers or requests yet.</MessageText>
						<GroupSectionHeadline bgColor={communityColors.btn}>
							<Button buttonTheme="light" onClick={() => setOpen(true)} disabled={isEventEnded || isEventHappening}>
								<Icon name="plus" group="filled" width={16} height={16} className="fill-last-path" />
								Create Carpool
							</Button>
						</GroupSectionHeadline>
					</NoConnectionsBlock>
				</BoxItem>
			)}
			<CreateRequestsDialog open={open} onClose={() => setOpen(false)} isCarpoolPage eventDetail={event} />
			{signUpDialog && signUpDialog.open && signUpDialog.eventDetail && signUpDialog?.request && (
				<SignUpRequestDialog
					open={signUpDialog.open}
					onClose={() => setSignUpDialog({ open: false, eventDetail: undefined, request: undefined })}
					eventDetail={
						{
							_id: signUpDialog?.eventDetail?.eventId,
							name: signUpDialog?.eventDetail?.title,
							...signUpDialog?.eventDetail
						} as unknown as EventRequests
					}
					request={signUpDialog?.request}
					isCarpoolPage
				/>
			)}
			{bookDialog && bookDialog.open && bookDialog?.eventDetail && bookDialog?.request && (
				<BookRequestDialog
					open={bookDialog.open}
					onClose={() => setBookDialog({ open: false, eventDetail: undefined, request: undefined })}
					eventDetail={
						{
							_id: bookDialog?.eventDetail?.eventId,
							name: bookDialog?.eventDetail?.title,
							...bookDialog?.eventDetail
						} as unknown as EventRequests
					}
					request={bookDialog?.request}
					isCarpoolPage
					editDetails={bookDialog?.editDetails}
				/>
			)}
			<ConfirmActionModal
				open={cancelRequest.open || cancelBooking.open}
				onClose={() => (cancelRequest.open ? setCancelRequest({ open: false }) : setCancelBooking({ open: false }))}
				onConfirm={() => (cancelRequest.open ? cancelRequestHandler() : cancelBookingHandler())}
				title={cancelRequest.open ? "Cancel the ride?" : "Don't need a ride?"}
				bodyText={"Are you sure you want to cancel your ride?"}
				closeText="Keep"
				confirmText="Cancel"
				reverseButtons
				confirmButtonTheme="danger"
			/>
		</>
	);
});

export default EventsCarpool;
