import React, { FC, memo, useCallback, useEffect, useMemo, useState } from "react";

import { DateTime } from "luxon";

import { ConfirmActionModal } from "shared/Components";

import { useUser, useVolunteers } from "shared/hooks";
import { EventModel, ProfilePhotoSizes } from "shared/types";

import { getResizedImage } from "utils/serviceUtils/cdnImages";

import { ModelBlockType } from "../../../ModelBlock";
import CreateUpdateVolunteerSlot from "../../components/CreateUpdateVolunteerSlot";
import SignUpVolunteer from "../../components/SignUpVolunteer";
import VolunteersTemplate from "../../components/VolunteersTemplate";

const EventsVolunteers: FC<{
	event: EventModel | null;
	eventId: string;
	canCreate?: boolean;
	isEventEnded: boolean;
}> = memo(({ event, eventId, canCreate, isEventEnded }) => {
	const { getData: getUserData, getProfilePicture } = useUser();
	const { user } = getUserData();
	const [page, setPage] = useState(1);

	const {
		getVolunteerSlots,
		setCreateUpdateDialog,
		setDeleteVolunteerId,
		setSignUpVolunteerSlot,
		deleteVolunteerSlot,
		setCancelVolunteerSlot,
		joinLeaveForVolunteerSlot,
		getData: getVolunteerData
	} = useVolunteers();
	const {
		loading,
		volunteersSlots,
		createUpdateVolunteerDialog,
		volunteerDeleteId,
		signUpDialog,
		cancelVolunteerSlot,
		shouldRequestStop
	} = getVolunteerData();

	useEffect(() => {
		if (eventId) {
			getVolunteerSlots({ eventId, offset: page, limit: 10 });
		}
	}, [eventId, page, getVolunteerSlots]);

	const volunteerDeleteHandler = useCallback(async () => {
		if (volunteerDeleteId) {
			deleteVolunteerSlot(volunteerDeleteId);
			setDeleteVolunteerId(undefined);
		}
	}, [setDeleteVolunteerId, deleteVolunteerSlot, volunteerDeleteId]);

	const volunteerCancelHandler = useCallback(async () => {
		if (cancelVolunteerSlot?._id) {
			const userDetail = {
				personaId: user?.activeProfile,
				quantity: cancelVolunteerSlot?.members.find(m => m.personaId === user?.activeProfile)?.quantity || 0,
				firstName: user?.profiles[0].firstName,
				lastName: user?.profiles[0].lastName,
				photos: [
					{
						profilePicture: getResizedImage(getProfilePicture(user), ProfilePhotoSizes.size50x50)
					}
				]
			};

			joinLeaveForVolunteerSlot(cancelVolunteerSlot?._id, { action: "leave" }, userDetail);
			setCancelVolunteerSlot(undefined);
		}
	}, [setCancelVolunteerSlot, joinLeaveForVolunteerSlot, cancelVolunteerSlot, getProfilePicture, user]);

	const zone = useMemo(() => Intl.DateTimeFormat().resolvedOptions().timeZone, []);
	const eventRange = useMemo(() => {
		if (event) {
			const start = DateTime.fromISO(event.startTime ? event.startTime : "", { zone }).toFormat("MMM d, h:mm a");
			const end = DateTime.fromISO(event.endTime ? event.endTime : "", { zone }).toFormat("MMM d, h:mm a");

			return `${start} - ${end}`;
		}
	}, [event, zone]);

	const onEndScroll = useCallback(() => {
		!loading && !shouldRequestStop && setPage(page => page + 1);
	}, [loading, setPage, shouldRequestStop]);

	return (
		<>
			<VolunteersTemplate
				loading={loading}
				autoFit={volunteersSlots.length === 3}
				items={volunteersSlots}
				type={ModelBlockType.volunteerSlot}
				actionButton={
					canCreate
						? { title: "Create a Slot", onClick: () => setCreateUpdateDialog({ open: true, action: "create" }) }
						: undefined
				}
				noItemsErrorText="You haven’t created any volunteering slots yet."
				event={event}
				isEventEnded={isEventEnded}
				onEndScroll={onEndScroll}
			/>

			{createUpdateVolunteerDialog && createUpdateVolunteerDialog.open && (
				<CreateUpdateVolunteerSlot
					open={createUpdateVolunteerDialog.open}
					volunteerSlotDetail={createUpdateVolunteerDialog?.editDetails}
					onClose={() => {
						setCreateUpdateDialog({ open: false, editDetails: undefined });
					}}
					event={event}
					action={createUpdateVolunteerDialog.action || "create"}
				/>
			)}
			<ConfirmActionModal
				open={!!volunteerDeleteId}
				onClose={() => setDeleteVolunteerId(undefined)}
				onConfirm={volunteerDeleteHandler}
				title={"Delete Volunteering"}
				bodyText={"Are you sure you want to permanently delete your volunteering slot?"}
				closeText="Keep"
				confirmText="Delete"
				reverseButtons
				confirmButtonTheme="danger"
			/>
			{signUpDialog && signUpDialog.open && (
				<SignUpVolunteer
					open={signUpDialog.open}
					volunteerSlotDetail={signUpDialog.signUpVolunteerDetail}
					onClose={() => {
						setSignUpVolunteerSlot({ open: false, signUpVolunteerDetail: undefined, eventDetail: undefined });
					}}
					eventDetail={signUpDialog.eventDetail}
					eventRange={eventRange}
				/>
			)}
			<ConfirmActionModal
				open={!!cancelVolunteerSlot?._id}
				onClose={() => setCancelVolunteerSlot(undefined)}
				onConfirm={volunteerCancelHandler}
				title={"Cancel Volunteering"}
				bodyText={"Are you sure you want to cancel your volunteering?"}
				closeText="Keep"
				confirmText="Cancel"
				reverseButtons
				confirmButtonTheme="danger"
			/>
		</>
	);
});

export default EventsVolunteers;
