import { useMemo } from "react";

import { DefaultResponseType } from "types";

import { API_URLS } from "shared/constants";
import useHttpActions from "shared/services/api/core/useHttpActions";
import BaseConverter from "shared/services/converters/baseConverter";

import { PersonStoryModel, StoryModel } from "../types";

const useStoryApiService = () => {
	const actions = useHttpActions();

	const methods = useMemo(
		() => ({
			getPersonsStories: async (offset, limit, personaId) => {
				return BaseConverter.convert<{ stories: PersonStoryModel[] }>(
					await actions.get(API_URLS.MEDIA.CONNECTIONS, {
						offset,
						limit,
						personaId
					})
				);
			},
			watchStory: async ({
				publisherPersonaId,
				storyId,
				type,
				viewerPersonaId
			}: {
				publisherPersonaId: string;
				storyId: string;
				type: string;
				viewerPersonaId?: number;
			}) => {
				return BaseConverter.convert<DefaultResponseType>(
					await actions.post(API_URLS.STORY.WATCH, {
						publisherPersonaId,
						storyId,
						type,
						viewerPersonaId
					})
				);
			},
			getMyStories: async (personaId: number) => {
				return BaseConverter.convert<{ stories: StoryModel[] }>(await actions.get(API_URLS.MEDIA.BASE, { personaId }));
			}
		}),
		[actions]
	);

	return useMemo(
		() => ({
			...methods
		}),
		[methods]
	);
};

export default useStoryApiService;
