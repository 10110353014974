import React, { memo, useEffect, useState } from "react";

import { usePlayer, useTrack } from "shared/hooks";

import { TrackModel } from "shared/types";

import EpisodeCard from "./EpisodeCard";
import { ItemWrapper, ListWrapper } from "./style";

import { BoxItem, Slider } from "../index";

const Episodes: React.FC<{ recent?: boolean }> = memo(({ recent }) => {
	const { getTracks } = useTrack();

	const [tracks, setTracks] = useState<TrackModel[]>([]);

	const { setPlayTracks } = usePlayer();

	useEffect(() => {
		getTracks({
			keyword: "",
			limit: 10,
			offset: 1,
			type: "podCast",
			sortBy: recent ? "createdAt" : "totalPlays",
			noStateUpdate: true
		}).then(data => setTracks(data));
	}, [getTracks, recent]);

	if (!tracks?.length) return null;

	const handlePlayPodcast = (id: string) => {
		setPlayTracks({
			idx: tracks.findIndex(m => m._id === id),
			tracks: {
				items: tracks
			}
		});
	};

	return (
		<BoxItem title={`${recent ? "Recent" : "Top"} episodes`}>
			<ListWrapper>
				<Slider hideFrom={1} count={(tracks || []).length} itemSize={240}>
					{(tracks || []).map((item, index) => (
						<ItemWrapper key={index}>
							<EpisodeCard episode={item} onPlay={() => handlePlayPodcast(item._id)} />
						</ItemWrapper>
					))}
				</Slider>
			</ListWrapper>
			{/*<SeeAllBtn />*/}
		</BoxItem>
	);
});

export default Episodes;
