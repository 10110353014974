import React, { FC, memo, useEffect, useMemo } from "react";

import { useHistory } from "react-router";

import { useCategories } from "modules/Marketplace/Data";

import { ItemWrapper, SliderWrapper } from "./style";

import { BoxItem, InlineCategoryCard, SeeAllBtn, Slider } from "..";

const SeriesCategories: FC<{ seriesPageUrl: string }> = memo(({ seriesPageUrl }) => {
	const history = useHistory();
	const { getCategories, getCategoriesCount, getData: getCategoriesData } = useCategories();
	const { categories, categoriesCount, isLoading } = getCategoriesData();

	useEffect(() => {
		getCategories({
			limit: 16,
			offset: 1,
			keyword: "",
			notEmptyBy: "series"
		});
	}, [getCategories]);

	useEffect(() => {
		getCategoriesCount("series");
	}, [getCategoriesCount]);

	const catLen = useMemo(() => categories.filter(c => !!c.seriesCount).length, [categories]);

	if (isLoading && !categories.length) {
		return "";
	}

	if (!categories.length && !isLoading) return null;

	return (
		<BoxItem title={"Series Categories"}>
			{categoriesCount > 4 ? (
				<SliderWrapper>
					<Slider hideFrom={9} speed={250} slidesPerRow={4} slidesToShow={2} count={catLen} itemSize={360}>
						{categories.map(col => (
							<ItemWrapper key={col._id}>
								<InlineCategoryCard small grayHover cat={col} />
							</ItemWrapper>
						))}
					</Slider>
				</SliderWrapper>
			) : (
				categories.map(col => (
					<ItemWrapper key={col._id}>
						<InlineCategoryCard small grayHover cat={col} />
					</ItemWrapper>
				))
			)}
			{categoriesCount > 16 && <SeeAllBtn click={() => history.push(seriesPageUrl)} link={seriesPageUrl} />}
		</BoxItem>
	);
});

export default SeriesCategories;
