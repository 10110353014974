import React from "react";

import { useParams } from "react-router";

import { view } from "modules/MemberHome";

const StartCollection = () => {
	const { id } = useParams() as { id: string };
	return <view.StartCollection id={id} />;
};

export default StartCollection;
