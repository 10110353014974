import React, { forwardRef } from "react";

import { Box } from "@material-ui/core";

import { ReactComponent as ReactLikeIcon } from "assets/icons/reactions/reactionLike.svg";
import { usePersona, useUser } from "shared/hooks";

import { ChooseSkinText, ChooseSkinToneWrapper, ToneButton, ToneOptionsWrapper } from "./style";

const skinOptions = [
	{ key: null, fill: "#ffd16d" },
	{ key: "light", fill: "#fee6cd" },
	{ key: "medium_light", fill: "#e2be98" },
	{ key: "medium_dark", fill: "#c69971" },
	{ key: "dark", fill: "#745b4d" }
];

interface ChooseSkinToneProps {
	show: boolean;
	onChooseTone?: () => void;
}

const ChooseSkinTone = forwardRef<HTMLDivElement, ChooseSkinToneProps>(({ show, onChooseTone }, ref) => {
	const { setUserSkinTone, getData: getUserData } = useUser();
	const { user } = getUserData();

	const { updatePersona } = usePersona();
	const ST = user?.profiles[0].skinTone;

	const handleClick = (e: React.MouseEvent<HTMLElement>, opt: { key: null | string; fill: string }) => {
		e.stopPropagation();

		user && delete user.profiles[0].contactInfo;

		updatePersona({
			...user?.profiles[0],
			skinTone: opt.key
		});

		setUserSkinTone(opt.key);

		onChooseTone && onChooseTone();
	};

	return (
		<ChooseSkinToneWrapper ref={ref} className={`choose-skin-tone-wrapper ${show ? "show" : ""}`}>
			<Box textAlign="center">
				<ToneOptionsWrapper>
					{skinOptions.map((opt, i) => (
						<ToneButton key={i} onClick={e => handleClick(e, opt)} className={ST === opt.key && "active"}>
							<ReactLikeIcon fill={opt.fill} height={20} width={20} />
						</ToneButton>
					))}
				</ToneOptionsWrapper>
				<ChooseSkinText>Choose skin tone</ChooseSkinText>
			</Box>
		</ChooseSkinToneWrapper>
	);
});

export default ChooseSkinTone;
