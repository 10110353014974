import React, { FC, useCallback, useState } from "react";

import DateFnsAdapter from "@date-io/date-fns";

import { ReactComponent as CertificationIcon } from "assets/icons/icon-certification.svg";
import { ReactComponent as EducationIcon } from "assets/icons/icon-education.svg";
import { ReactComponent as VolunteerIcon } from "assets/icons/icon-volunteer.svg";
import { InfoBlock } from "shared/Components";
import { CertificationType, ProfileEducationType, ProfileJobType, VolunteerType } from "shared/types/UserModel";
import { Icon } from "shared/ui-kit";
import { getDuration } from "utils/getDuration";

import { EducationRow, ExperienceRow, InfoRow } from "../../shared";

const dateFns = new DateFnsAdapter();

interface Props {
	editable?: () => void;
	data?: (ProfileEducationType | ProfileJobType | VolunteerType | CertificationType)[];
	displayLimit: number;
	title: string;
	eduView?: boolean;
	certificationView?: boolean;
	volunteerView?: boolean;
}

const MyExperience: FC<Props> = ({
	editable,
	data,
	displayLimit,
	title,
	eduView,
	volunteerView,
	certificationView
}) => {
	const [ignoreLimit, setIgnoreLimit] = useState(false);

	const renderEdu = useCallback(
		(item: ProfileEducationType, i: number, duration: string) => (
			<EducationRow
				key={i}
				icon={<EducationIcon className="fill-first-path" />}
				degree={(item as ProfileEducationType).degree.label}
				category={(item as ProfileEducationType).specialization.label}
				school={`${(item as ProfileEducationType).school.label} • ${dateFns.format(
					new Date((item as ProfileEducationType).startAt),
					"LLL Y" as any
				)} - ${
					(item as ProfileEducationType).present
						? `Present ${duration ? `${duration}` : ""}`
						: `${dateFns.format(new Date((item as ProfileEducationType).endAt || ""), "LLL Y" as any)} ${
								duration ? duration : ""
						  }`
				}`}
				location={(item as ProfileEducationType).description}
			/>
		),
		[]
	);

	const renderVolunteer = useCallback(
		(item: VolunteerType, i: number, duration: string) => (
			<ExperienceRow
				key={i}
				icon={<VolunteerIcon className="fill-second-path" />}
				title={item.cause.label}
				company={`${item.organization.label} • ${dateFns.format(new Date(item.startAt), "LLL Y" as any)} - ${
					item.present
						? `Present ${duration ? `${duration}` : ""}`
						: `${dateFns.format(new Date(item.endAt), "LLL Y" as any)} ${duration ? duration : ""}`
				}`}
				location={item.location}
			/>
		),
		[]
	);

	const renderCertifications = useCallback(
		(item: CertificationType, i: number) => (
			<InfoRow
				fw
				key={i}
				icon={<CertificationIcon className="fill-second-path" />}
				title={item.license.label}
				subtitle={item.organization.label}
			/>
		),
		[]
	);

	if (!data) return null;

	return (
		<InfoBlock
			title={title}
			className="mv-16"
			seeAll={displayLimit < data.length && !ignoreLimit ? () => setIgnoreLimit(true) : undefined}
			editable={
				editable
					? {
							onClick: editable,
							icon: !!data.length ? "pencil" : "plus"
					  }
					: undefined
			}
		>
			{data.slice(0, ignoreLimit ? 999 : displayLimit).map((item, i) => {
				const diff = getDuration(
					new Date((item as ProfileJobType).startAt),
					(item as ProfileJobType).present ? new Date() : new Date((item as ProfileJobType).endAt || "")
				);
				const duration = diff ? ` ( ${diff} )` : "";

				if (eduView) {
					return renderEdu(item as ProfileEducationType, i, duration);
				} else if (volunteerView) {
					return renderVolunteer(item as VolunteerType, i, duration);
				} else if (certificationView) {
					return renderCertifications(item as CertificationType, i);
				}
				return (
					<ExperienceRow
						key={i}
						icon={<Icon name="briefcase" group="filled" className="fill-second-path" />}
						title={(item as ProfileJobType).jobTitle.label}
						company={`${(item as ProfileJobType).company.label} • ${dateFns.format(
							new Date((item as ProfileJobType).startAt),
							"LLL Y" as any
						)} - ${
							(item as ProfileJobType).present
								? `Present ${duration}`
								: `${dateFns.format(new Date((item as ProfileJobType).endAt || ""), "LLL Y" as any)} ${duration}`
						}`}
						location={(item as ProfileJobType).location}
						description={(item as ProfileJobType).description}
					/>
				);
			})}
		</InfoBlock>
	);
};

export default MyExperience;
