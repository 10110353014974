import React, { FC } from "react";

import { DialogOverlayWrapper } from "./style";

const DialogOverlay: FC<{ customWidth?: number; minHeight?: number; maxHeight?: number; fullScreen?: boolean }> = ({
	children,
	customWidth,
	minHeight,
	maxHeight,
	fullScreen
}) => {
	return (
		<DialogOverlayWrapper customWidth={customWidth} minHeight={minHeight} maxHeight={maxHeight} fullScreen={fullScreen}>
			{children}
		</DialogOverlayWrapper>
	);
};

export default DialogOverlay;
