import React, { FC, useEffect, useMemo, useRef } from "react";

import { Box } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";

import ShopifyImg from "assets/images/shopify.png";
import { useIntegrations } from "modules/Integrations/Data/hooks";
import EcommerceRow from "modules/Integrations/View/Components/Rows/EcommerceRow";
import PodcastRow from "modules/Integrations/View/Components/Rows/PodcastRow";
import { Input, Text } from "shared/ui-kit";

import { IntegrationsContainer, PageHeader } from "./styles";

import ConfirmDisconnectIntegration from "../../Components/ConfirmDisconnectIntegration";
import MyIntegrationsRow from "../../Components/Rows/MyIntegrations";
import SearchResults from "../../Components/Rows/SearchResults";

const Integrations: FC<{ integrationsRoute: string }> = ({ integrationsRoute }) => {
	const { updateSearchResults, getPodcasts, setIntegrationsUrl, getData: getIntegrationsData } = useIntegrations();
	const { podcasts } = getIntegrationsData();

	const inputRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		getPodcasts();
	}, [getPodcasts]);

	useEffect(() => {
		setIntegrationsUrl(integrationsRoute);
	}, [integrationsRoute, setIntegrationsUrl]);

	const findIntegrations = useMemo(
		() => e => {
			if (!e.target.value.length) {
				updateSearchResults([]);
				return;
			}

			const shopifyResult = {
				_id: `${new Date().getTime()}`,
				image: ShopifyImg,
				infoUrl: process.env.REACT_APP_SHOPIFY_URL as string,
				name: "Shopify",
				links: []
			};

			let results = podcasts.filter(
				int => e.target.value.length && int.name.toLowerCase().includes(e.target.value.toLowerCase())
			);

			if (shopifyResult.name.toLowerCase().startsWith(e.target.value.toLowerCase())) {
				results = [...results, shopifyResult];
			}

			updateSearchResults(results);
		},
		[podcasts, updateSearchResults]
	);

	return (
		<IntegrationsContainer>
			<ConfirmDisconnectIntegration />
			<PageHeader>
				<Box className="typography">
					<Text variant="h1">Integrations</Text>
					<Text variant="subtitle1">Integrate with our partners to import your content.</Text>
				</Box>
				<Box className="search-integration">
					<Input
						ref={inputRef}
						placeholder="Search for integration"
						name="search-integration"
						className="search-integration-input"
						onChange={findIntegrations}
						leftIcon={<SearchIcon />}
					/>
				</Box>
			</PageHeader>
			<SearchResults />
			<MyIntegrationsRow />
			<PodcastRow />
			<EcommerceRow />
		</IntegrationsContainer>
	);
};

export default Integrations;
