import React, { useEffect } from "react";

import { useFirebaseLiveConversation, useLiveConversation } from "modules/LiveConversation/Data/hooks";
import { ConversationComment, SidebarType } from "modules/LiveConversation/Data/types";

import { SideBar } from "./style";

import { Comments, Participants } from "../index";

interface ConversationSidebarProps {
	personaId: number;
	isAdmin: boolean;
}

const ConversationSidebar: React.FC<ConversationSidebarProps> = ({ personaId, isAdmin }) => {
	const { watchComments } = useFirebaseLiveConversation();

	const { toggleSideBar, updateCommentsCount, setComments, getData: getLiveConversationData } = useLiveConversation();
	const { sidebarType, config } = getLiveConversationData();

	useEffect(() => {
		if (sidebarType === SidebarType.comments && !config.enableComments) {
			toggleSideBar(SidebarType.comments);
		}

		if (sidebarType === SidebarType.hands && !config.enableHandRaise) {
			toggleSideBar(SidebarType.hands);
		}
	}, [sidebarType, config.enableComments, config.enableHandRaise, toggleSideBar]);

	useEffect(() => {
		const removeListener = watchComments(snapshot => {
			if (snapshot) {
				const _comments: ConversationComment[] = [];
				snapshot.forEach(child => {
					const data = child.val();
					_comments.push({ ...data, id: child.key });
				});
				updateCommentsCount(_comments.length);
				setComments(_comments);
			}
		});

		return removeListener;
	}, [watchComments, updateCommentsCount, setComments]);

	return (
		<SideBar className={sidebarType ? "show" : ""}>
			{(sidebarType === SidebarType.participants || sidebarType === SidebarType.hands) && (
				<Participants personaId={personaId} isAdmin={isAdmin} sidebarType={sidebarType} />
			)}
			{sidebarType === SidebarType.comments && <Comments isAdmin={isAdmin} personaId={personaId} />}
		</SideBar>
	);
};

export default ConversationSidebar;
