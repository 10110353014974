import React, { memo } from "react";

import { useParams } from "react-router";

import { view } from "modules/MemberHome";

const PostPage = memo(() => {
	const { id } = useParams() as { id: string };

	return <view.Post postId={id} />;
});

export default PostPage;
