import React, { ChangeEvent, memo, useCallback, useEffect, useState } from "react";

import { TextField } from "@material-ui/core";

import { useConnection, useDebounce } from "shared/hooks";
import { MediaModel, ProfileType } from "shared/types";

import { AutoCompleteWrapper } from "./style";

interface EventAdmin {
	personaId: number;
	firstName?: string;
	lastName?: string;
	avatarUrl?: string;
}

const ConnectionAutoComplete: React.FC<{
	onChange: (v: EventAdmin[]) => void;
	value: EventAdmin[];
}> = memo(({ onChange, value = [] }) => {
	const { getUsersConnections } = useConnection();

	const [options, setOptions] = useState<ProfileType[]>([]);

	const [keyword, setKeyword] = useState("");

	const debouncedKeyword = useDebounce(keyword, 200);

	const onSearchConnection = useCallback((e: ChangeEvent<HTMLInputElement>) => {
		setKeyword(e.target.value);
	}, []);

	useEffect(() => {
		if (debouncedKeyword?.length > 1) {
			getUsersConnections({ keyword: debouncedKeyword, offset: 1, limit: 10 }).then(({ connections }) => {
				setOptions(
					connections.filter(({ personaId }) => !value.some(({ personaId: _personaId }) => _personaId === personaId))
				);
			});
		}
	}, [debouncedKeyword, getUsersConnections, value]);

	return (
		<AutoCompleteWrapper
			multiple
			id="tags-standard"
			options={options}
			getOptionLabel={(option: ProfileType) => option.firstName}
			defaultValue={[]}
			onChange={(e, _value: ProfileType[]) => {
				setOptions(
					options.filter(
						({ personaId }) => _value.findIndex(({ personaId: _personaId }) => _personaId === personaId) === -1
					)
				);
				onChange(
					_value.map(profile => ({
						personaId: profile.personaId,
						avatarUrl: (profile.photos[0] as MediaModel)?.profilePicture,
						firstName: profile.firstName,
						lastName: profile.lastName
					}))
				);
			}}
			renderInput={params => (
				<TextField {...params} onChange={onSearchConnection} variant="standard" placeholder="Search Connection" />
			)}
		/>
	);
});

export default ConnectionAutoComplete;
