import { Box } from "@material-ui/core";
import styled, { css } from "styled-components";

import { Text } from "shared/ui-kit";

export const ModalOverlay = styled(Box)`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	background-color: rgba(25, 36, 53, 0.1);
	z-index: 3000;
	.MuiDialogContent-root {
		padding: 0;
		overflow: ${({ allowOverflow }) => !allowOverflow && "hidden"};
	}
	.MuiDialog-root {
		height: 100%;
	}
	.MuiPaper-root {
		border-radius: 12px;
	}
	${({ customMaxWidth }) =>
		customMaxWidth &&
		css`
			.MuiDialog-paperWidthMd {
				max-width: ${customMaxWidth};
			}
		`}
	${props => props.theme.breakpoints.down("xs")} {
		.MuiDialog-paperScrollPaper {
			margin: 0;
			padding: 0;
			width: calc(100% - 32px);
		}
	}
`;

export const ModalContent = styled(Box)`
	padding: 36px;
	border-radius: 12px;
	text-align: center;
	img {
		width: 80px;
		height: 80px;
		object-fit: cover;
	}
`;

ModalContent.Header = styled(Text)`
	font-size: 28px;
	font-weight: bold;
	color: #2f3742;
	margin-top: 16px;
`;
ModalContent.Subtitle = styled(Text)`
	font-size: 16px;
	color: #2f3742;
	margin: 16px 0;
`;

ModalContent.ActionsWrapper = styled(Box)`
	display: flex;
	justify-content: center;
	button {
		width: 140px;
		height: 40px;
		padding: 12px 16px;
		border-radius: 8px;
		font-size: 14px;
		font-weight: bold;
		line-height: 1.14;
		color: #fff;
		background-color: #6173fe;
		&.logout {
			background-color: #edf1f7;
			color: #6173fe;
		}
	}
`;
