import React, { useCallback, useEffect, useMemo, useState } from "react";

import { ReactComponent as LogoutIcon } from "assets/icons/icons-colorful-logout.svg";
import { ReactComponent as SettingsIcon } from "assets/icons/icons-colorful-settings.svg";
import { useCommunity, useFeature, useMemberRoutes, useRoutes, useUser } from "shared/hooks";
import { MediaModel, ProfileType } from "shared/types";

import { memberMenuItems } from "./constants";
import { Item, ListWrapper, MemberPanelBtnWrapper, Title, Wrapper } from "./styles";

import { SettingsDialog, SwitchRoleViewBtn } from "../index";

interface MemberNavPanelProps {
	adminHomeUrl: string;
	profile?: ProfileType;
	profilePageUrl: string;
	onClose?: () => void;
}

const MemberNavPanel: React.FC<MemberNavPanelProps> = ({ adminHomeUrl, profile, profilePageUrl, onClose }) => {
	const { getData: getUserData } = useUser();
	const { user } = getUserData();
	const { communityColors, getData: getCommunityData } = useCommunity();
	const { workspace } = getCommunityData();
	const { logout } = useRoutes();

	const { isFeatureEnabled } = useFeature();

	const { getMemberRoutesData } = useMemberRoutes();
	const { routes } = getMemberRoutesData();

	const [color, setColor] = useState<string>();
	const [touchStart, setTouchStart] = useState(0);
	const [touchEnd, setTouchEnd] = useState(0);
	const [settingsDialogIsOpen, setSettingsDialogIsOpen] = useState(false);

	const userIconSrc = useMemo(
		() => (profile?.photos?.length ? (profile?.photos[0] as MediaModel)?.profilePicture ?? "" : ""),
		[profile]
	);

	useEffect(() => {
		setColor(communityColors.primary);
	}, [communityColors.primary]);

	const menuItems = useMemo(
		() => (workspace?.name ? memberMenuItems(workspace.name, routes) : null),
		[routes, workspace?.name]
	);

	const enabledItems = useMemo(
		() => menuItems?.filter(x => (x.featureValueName ? isFeatureEnabled(x.featureValueName as string) : true)),
		[menuItems, isFeatureEnabled]
	);

	const showAdminPanelBtn = useMemo(
		() => user?.isGlobalAdmin || user?.isGlobalOwner || user?.isGlobalModerator,
		[user]
	);

	const handleClose = useCallback(() => {
		onClose && onClose();
	}, [onClose]);

	const handleTouchStart = (e: MouseEvent | TouchEvent) => {
		setTouchStart((e as MouseEvent).clientX || (e as TouchEvent).targetTouches[0].clientX);
	};

	const handleTouchMove = (e: MouseEvent | TouchEvent) => {
		setTouchEnd((e as MouseEvent).clientX || (e as TouchEvent).targetTouches[0].clientX);
	};

	const handleTouchEnd = useCallback(() => {
		if (touchStart - touchEnd > 50) {
			handleClose();
		}
	}, [handleClose, touchStart, touchEnd]);

	const logoutUser = useCallback(() => {
		logout();
	}, [logout]);

	const handleSettingsClick = () => {
		handleTouchEnd();
		setSettingsDialogIsOpen(true);
	};

	const handleSettingsDialogClose = () => {
		setSettingsDialogIsOpen(false);
	};

	return (
		<>
			<Wrapper
				onTouchStart={handleTouchStart}
				onTouchMove={handleTouchMove}
				onTouchEnd={handleTouchEnd}
				onMouseDown={handleTouchStart}
				onMouseMove={handleTouchMove}
				onMouseUp={handleTouchEnd}
				onMouseLeave={handleTouchEnd}
			>
				{showAdminPanelBtn && (
					<MemberPanelBtnWrapper>
						<SwitchRoleViewBtn label={"Admin Panel"} link={adminHomeUrl} />
					</MemberPanelBtnWrapper>
				)}
				<Title>Menu</Title>
				<ListWrapper>
					<Item.Link to={profilePageUrl}>
						<Item.IconWrapper color={color} imgUrl={userIconSrc} />
						<Item.Title>Profile</Item.Title>
					</Item.Link>
					{enabledItems &&
						enabledItems.map((item, index) => (
							<Item.Link key={index} to={item.link}>
								<Item.IconWrapper color={color}>{item.icon}</Item.IconWrapper>
								<Item.Title>{item.title}</Item.Title>
							</Item.Link>
						))}
					<Item.Menu onClick={handleSettingsClick}>
						<Item.IconWrapper color={color}>
							<SettingsIcon width={32} height={32} />
						</Item.IconWrapper>
						<Item.Title>Settings</Item.Title>
					</Item.Menu>
					<Item.Menu onClick={logoutUser}>
						<Item.IconWrapper color={color}>
							<LogoutIcon width={32} height={32} />
						</Item.IconWrapper>
						<Item.Title>Log Out</Item.Title>
					</Item.Menu>
				</ListWrapper>
			</Wrapper>
			{settingsDialogIsOpen && <SettingsDialog onClose={handleSettingsDialogClose} />}
		</>
	);
};

export default MemberNavPanel;
