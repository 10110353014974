import React, { useCallback, useEffect, useMemo, useState } from "react";

import { useMediaQuery } from "@material-ui/core";

import { UserFileSortBy } from "types";

import { AddTracksToPlaylist, AlbumDetailsDialog, CreateUpdateAlbumDialog } from "modules/Manage/View/Components";
import { CreateTrack } from "shared/Components";
import { useAlbums, useTrack, useUser } from "shared/hooks";
import * as appTheme from "theme/default";

import { AlbumSortBy } from "../../../../../types/AlbumSortBy";

import { FilterBlock, FilterOptionType, ModelBlock, PageWrapper } from "../../Components";
import { ModelBlockType } from "../../Components/ModelBlock";
import { ManInLotus, MessageText, NoConnectionsBlock, StyledButton } from "../Connections/style";
import { FilterType } from "../Files";

const defaultFilters = {
	limit: 20,
	keyword: "",
	sortBy: "updatedAt"
};

export interface AlbumPageProps {}

const AlbumsPage: React.FC<AlbumPageProps> = () => {
	const {
		getAlbums,
		getAllAlbumsCount,
		setAlbumCreateOpen,
		isUserCouldManageAlbum,
		getData: getAlbumsData
	} = useAlbums();
	const { albums, totalAlbumsCount, albumCreateOpen, albumDetailsDialog, isLoading } = getAlbumsData();

	const { getData: getTrackData, setTrackInfoPopup, setRefresh } = useTrack();
	const { trackInfoPopup, refresh } = getTrackData();

	const { getData: getUserData } = useUser();
	const { user } = getUserData();

	const [filters, setFilters] = useState<FilterType>({ notEmptyBy: "music", offset: 1 });
	const [loadData, setLoadData] = useState(true);

	const hasMoreItems = useMemo(
		() => totalAlbumsCount && albums && totalAlbumsCount > albums.length,
		[totalAlbumsCount, albums]
	);

	const isMobile = useMediaQuery(appTheme.default.breakpoints.down("sm"));

	useEffect(() => {
		if (loadData) {
			getAlbums({ ...defaultFilters, ...filters, paginate: filters.offset > 1 ? true : false });
		}
	}, [getAlbums, loadData, filters]);

	useEffect(() => {
		getAllAlbumsCount({ notEmptyBy: "music" });
	}, [getAllAlbumsCount]);

	const filterOptions: FilterOptionType[] = useMemo(
		() => [
			{
				inlineOptions: true,
				onClick: item => {
					switch (item) {
						case AlbumSortBy.MY_ALBUMS:
							return user && setFilters({ creatorId: user.personaDocId, offset: 1 });
						case AlbumSortBy.POPULAR_ALBUMS:
							return user && setFilters({ sortBy: UserFileSortBy.popularity, offset: 1, notEmptyBy: "music" });
						default:
							setFilters({ sortBy: UserFileSortBy.updatedAt, offset: 1, notEmptyBy: "music" });
					}
				},
				listOptions: [
					{
						label: "Most Recent",
						value: AlbumSortBy.MOST_RECENT,
						default: true
					},
					{
						label: "Popular Albums",
						value: AlbumSortBy.POPULAR_ALBUMS
					},
					{
						label: "My Albums",
						value: AlbumSortBy.MY_ALBUMS
					}
				]
			}
		],
		[user]
	);

	const createOption = useMemo(() => {
		return isUserCouldManageAlbum({})
			? {
					label: "Create New Album",
					onClick: () => {
						setAlbumCreateOpen(true);
					},
					val: 1
			  }
			: undefined;
	}, [setAlbumCreateOpen, isUserCouldManageAlbum]);

	const onEndScroll = useCallback(() => {
		if (hasMoreItems) {
			setFilters(ctx => ({ ...ctx, offset: ctx.offset + 1 }));
			setLoadData(true);
		}
	}, [hasMoreItems]);

	return (
		<>
			<PageWrapper>
				<FilterBlock options={filterOptions} createOption={createOption} />
				<ModelBlock
					autoFit={albums.length > 3 ? 258 : undefined}
					title={"Albums"}
					type={ModelBlockType.album}
					items={albums || []}
					loading={isLoading}
					keepCurrentData={isLoading && filters.offset > 1}
					inlineView={!isMobile}
					onEndScroll={onEndScroll}
					noContent={
						<NoConnectionsBlock>
							<ManInLotus />
							<MessageText>You don’t have any albums.</MessageText>
							{isUserCouldManageAlbum({}) && (
								<StyledButton
									buttonTheme="light"
									palette="light"
									size="medium"
									onClick={() => setAlbumCreateOpen(true)}
									removeSideMargin
								>
									+ Create New Album
								</StyledButton>
							)}
						</NoConnectionsBlock>
					}
				/>
			</PageWrapper>
			{trackInfoPopup.open && (
				<CreateTrack
					open={true}
					editableModel={trackInfoPopup.model}
					album={trackInfoPopup.album}
					onClose={e => {
						setTrackInfoPopup({ open: false });
						if (e?.created) {
							setRefresh(refresh + 1);
						}
					}}
				/>
			)}
			{!!albumCreateOpen && <CreateUpdateAlbumDialog open={true} />}
			{albumDetailsDialog.addTracks && !trackInfoPopup.open && <AddTracksToPlaylist />}
			{albumDetailsDialog.open && <AlbumDetailsDialog />}
		</>
	);
};

export default AlbumsPage;
