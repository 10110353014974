import React, { ReactNode, useCallback, useEffect, useMemo } from "react";

import { TextField, useMediaQuery } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import config from "config/appConfig";

import country from "country-list-js";
import { isValidPhoneNumber } from "libphonenumber-js";
import { Controller, useForm } from "react-hook-form";
import { css } from "styled-components";

import { ReactComponent as ComingSoonIcon } from "assets/icons/coming-soon.svg";
import { ReactComponent as PeopleIcon } from "assets/icons/illustration-group.svg";
import AppleStoreIconUrl from "assets/images/apple-store.png";
import GooglePlayIconUrl from "assets/images/google-play.png";

import { Dialog } from "shared/Components";
import { useCommunity } from "shared/hooks";
import { AutoComplete } from "shared/ui-kit";

import * as appTheme from "theme/default";

import { adjustColor } from "utils/serviceUtils/helpers";

import {
	CloseIconButton,
	CodeBlockLabel,
	CodeCompleteWrapper,
	CodeOptionBlock,
	Content,
	ContentSide,
	Description,
	FormWrapper,
	IconSide,
	PaperBox,
	PhoneInput,
	PhoneInputCtrl,
	PhoneRow,
	Pretitle,
	SendLinkBtn,
	ShopBtn,
	ShowSection,
	StyledLink,
	Subtitle,
	Title
} from "./styles";

interface JoinPopupProps {
	isMarketing?: boolean;
	workspaceName?: string;
	open: boolean;
	onClose: () => void;
	onSendAppLink: (code: string, phone: string) => void;
	primaryColor?: string;
	allowToClose?: boolean;
}

const JoinPopupTemplate: React.FC<JoinPopupProps> = ({
	isMarketing,
	workspaceName,
	open,
	onClose,
	onSendAppLink,
	primaryColor,
	allowToClose
}) => {
	const { handleSubmit, control, reset, errors, getValues } = useForm({ mode: "onChange" });
	const isMobile = useMediaQuery(appTheme.default.breakpoints.down("sm"));

	const { getData: getCommunityData } = useCommunity();
	const { workspace } = getCommunityData();

	const countryList = useMemo(() => {
		const getCorrectCode = (code: string): string => {
			const correct = code.includes("and") ? code.split("and")[0].trim() : code;
			return correct.includes("+") ? correct : `+${correct}`;
		};
		return Object.keys(country.all)
			.map(key => {
				const countryCode = country.all[key].dialing_code.trim();
				const code = countryCode ? getCorrectCode(countryCode) : "";
				return {
					label: code,
					code,
					value: (country.all[key] as any).iso2
				};
			})
			.filter(x => x.code && x.value)
			.sort((a, b) => {
				if (a.value < b.value) {
					return -1;
				}
				if (a.value > b.value) {
					return 1;
				}
				return 0;
			});
	}, []);

	useEffect(() => {
		reset();
		if (open && isMobile) {
			onClose();
			// redirect to mobile app or app store
			window.open(config.GLOBAL_CONSTANTS.VYOO_APP_STORE_SMART_LINK, isMarketing ? "_blank" : "_self");
		}
	}, [reset, open, isMobile, onClose, isMarketing]);

	const handleClose = useCallback(() => {
		onClose();
	}, [onClose]);

	const sendLink = data => {
		onClose();
		const country = countryList.find(x => x.value === data.countryISO);
		onSendAppLink(country?.code || "+1", data.phone);
	};

	const androidStoreLink = useMemo(
		() =>
			isMarketing
				? config.GLOBAL_CONSTANTS.GOOGLE_STORE_APP_URL
				: workspace?.androidStoreUrl || config.GLOBAL_CONSTANTS.GOOGLE_STORE_APP_URL,
		[isMarketing, workspace?.androidStoreUrl]
	);

	const appleStoreLink = useMemo(
		() =>
			isMarketing
				? config.GLOBAL_CONSTANTS.APPLE_STORE_APP_URL
				: workspace?.iOSStoreUrl || config.GLOBAL_CONSTANTS.APPLE_STORE_APP_URL,
		[isMarketing, workspace?.iOSStoreUrl]
	);

	return (
		<Dialog
			title={isMarketing ? "Join" : "Coming Soon"}
			showHead={false}
			open={open}
			onClose={handleClose}
			blurBackground={true}
			maxWidth={"md"}
			PaperProps={{
				style: {
					borderRadius: "12px"
				}
			}}
			bodyCustomStyles={css`
				padding: 0 !important;
				overflow: ${isMobile ? "scroll" : "hidden"};
			`}
		>
			<FormWrapper onSubmit={handleSubmit(sendLink)}>
				{(isMarketing || allowToClose) && (
					<CloseIconButton aria-label="close" onClick={handleClose}>
						<CloseIcon />
					</CloseIconButton>
				)}
				<Content>
					<IconSide
						primaryColor={primaryColor ? adjustColor(primaryColor, 70) : undefined}
						className={isMarketing ? "marketing" : allowToClose ? "people-mode" : ""}
					>
						{!isMarketing && <>{allowToClose ? <PeopleIcon /> : <ComingSoonIcon />}</>}
					</IconSide>
					<ContentSide className={isMarketing && "marketing"}>
						{!isMarketing && <Pretitle>{workspaceName} on Web is coming very soon!</Pretitle>}
						<Title>
							{isMarketing ? (
								<>Join {workspaceName}</>
							) : (
								<>
									Download {workspaceName} <br /> app on your mobile.
								</>
							)}
						</Title>
						<Subtitle>Get the download link to your phone</Subtitle>
						<PhoneRow>
							<CodeCompleteWrapper>
								<Controller
									name="countryISO"
									control={control}
									render={({ onChange, value }) => (
										<AutoComplete
											id={"1"}
											label={"test autocomplete"}
											loading={false}
											value={value}
											multiple={false}
											onChange={(val, newVal) => {
												if (newVal?.code) {
													onChange(newVal.value);
												}
											}}
											options={countryList}
											Icon={null}
											PaperComponent={({ children }: { children: ReactNode }) => <PaperBox>{children}</PaperBox>}
											customRenderOptions={option => (
												<CodeOptionBlock>
													{option.value} {option.code}
												</CodeOptionBlock>
											)}
											customRenderInput={({ InputProps, ...params }) => {
												const optionInfo = value ? countryList.find(x => x.value === value) : null;
												return (
													<TextField
														{...params}
														placeholder={"+1"}
														variant="outlined"
														InputProps={{
															...InputProps,
															endAdornment: null,
															readOnly: false,
															startAdornment: <CodeBlockLabel>{value ? optionInfo?.value : "US"}</CodeBlockLabel>
														}}
													/>
												);
											}}
										/>
									)}
								/>
							</CodeCompleteWrapper>
							<PhoneInput>
								<Controller
									name="phone"
									control={control}
									rules={{
										required: true,
										validate: value => {
											const iso = getValues("countryISO") || "US";
											const country = countryList.find(x => x.value === iso);
											return isValidPhoneNumber(`${country?.code}${value}`, iso) || "Incorrect phone number";
										}
									}}
									render={({ onChange, value }) => (
										<PhoneInputCtrl
											size="small"
											type={"text"}
											placeholder="Phone Number"
											value={value}
											errorText={errors["phone"]?.message}
											onChange={e => onChange(e.target.value.replace(/\D/g, "").slice(0, 11))}
										/>
									)}
								/>
								<SendLinkBtn
									type="submit"
									primaryColor={primaryColor}
									hoverColor={primaryColor ? adjustColor(primaryColor, 50) : undefined}
									palette={"control"}
								>
									Send Link
								</SendLinkBtn>
							</PhoneInput>
						</PhoneRow>
						<Description>
							By clicking Send Link, I acknowledge that I have read the{" "}
							<StyledLink href={config.GLOBAL_CONSTANTS.PRIVACY_POLICY_LINK} target={"_blank"}>
								Privacy Policy
							</StyledLink>{" "}
							and agree to the{" "}
							<StyledLink href={config.GLOBAL_CONSTANTS.TERMS_AND_CONDITIONS_LINK} target={"_blank"}>
								Terms of Service
							</StyledLink>
							<span className={"subinfo"}>*Message and data rates may apply.</span>
						</Description>
						<ShowSection>
							<ShopBtn icon={AppleStoreIconUrl} href={appleStoreLink} target={"_blank"} />
							<ShopBtn icon={GooglePlayIconUrl} href={androidStoreLink} target={"_blank"} />
						</ShowSection>
					</ContentSide>
				</Content>
			</FormWrapper>
		</Dialog>
	);
};

export default JoinPopupTemplate;
