import styled from "styled-components";

import { Box } from "shared/ui-kit";

export const Container = styled(Box)`
	max-width: 960px;
	margin: 0 auto;
	padding: 0 16px;
`;

export const MoreVideos = styled(Box)`
	h3 {
		font-size: 22px;
		font-weight: bold;
		color: #222b45;
	}
`;

MoreVideos.Headline = styled(Box)`
	margin: 24px 0 16px;
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

MoreVideos.Row = styled(Box)`
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
	grid-gap: 16px;
`;

MoreVideos.Video = styled(Box)`
	width: 266px;
`;
