import React from "react";

import { view } from "modules/Manage";

import { routes } from "../../constants";

const ManagePostsPage = () => (
	<view.ManagePosts createPostRoute={routes.create.post.getPath()} editPostRoute={routes.edit.post.getPath()} />
);

export default ManagePostsPage;
