import React from "react";

import { view } from "modules/MemberHome";
import { FeatureFlags } from "shared/Components/MemberNavPanel/constants";
import useCheckIfEnabled from "shared/hooks/useCheckIfFlagEnabled";

const ConnectionsPage = () => {
	useCheckIfEnabled(FeatureFlags.connections);
	return <view.Connections />;
};

export default ConnectionsPage;
