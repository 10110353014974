import React, { FC, ReactNode, useCallback, useMemo } from "react";

import { FormControl, InputLabel } from "@material-ui/core";

import clsx from "clsx";

import { useUser } from "shared/hooks";
import { SizesEnum } from "shared/types";
import { Text } from "shared/ui-kit";

// import ErrorMessage from "./ErrorMessage";
import { uuidv4 } from "shared/ui-kit/utils/helper";

import { StyledChevronIcon, StyledMenuItem, UIKitDropdown, UIKitDropdownPaperStyles } from "./style";

import { BaseInputProps } from "./index";

export interface DropdownOptionModel {
	id?: string;
	label: string;
	value: any;
	icon?: ReactNode;
}

export interface DropdownProps extends Omit<BaseInputProps, "value"> {
	minDate?: Date | number;
	maxDate?: Date | number;
	options: DropdownOptionModel[] | any[];
	value: any;
	onChange: (val: any) => void;
	customDropdownItemRender?: (item: any, index?: number) => ReactNode;
	dropdownItemWrapperClassName?: string;
	optionId?: string;
	selectInputRef?: React.MutableRefObject<HTMLInputElement>;
	customSelectedRender?: (item: any, index?: number) => ReactNode | string;
}

const Dropdown: FC<DropdownProps> = ({
	LeftSideIcon,
	loading,
	disabled,
	options,
	onChange,
	placeholder,
	value,
	customDropdownItemRender,
	size,
	className,
	error,
	// errorText,
	dropdownItemWrapperClassName = "",
	label,
	optionId,
	customSelectedRender,
	...props
}) => {
	const MenuProps = {
		PaperProps: {
			style: UIKitDropdownPaperStyles
		}
	};

	const handleChange = useCallback(
		(event: any) => {
			onChange && onChange(event.target.value);
		},
		[onChange]
	);

	const { getData: getUserData } = useUser();
	const { isMemberView } = getUserData();

	const getSelected = (value: any): ReactNode => {
		if (value) {
			const selectedItem = options.find(x => x.value === value);
			if (selectedItem) {
				return customSelectedRender ? (
					customSelectedRender(selectedItem)
				) : customDropdownItemRender ? (
					customDropdownItemRender(selectedItem)
				) : (
					// <Text style={TextStylesEnum.Default}>{selectedItem.label}</Text>
					<Text>{selectedItem.label}</Text>
				);
			}
		}

		// return <Text style={TextStylesEnum.Hint}>{placeholder}</Text>;
		return <Text>{placeholder}</Text>;
	};

	const renderItem = useCallback(
		(item: any, index: number) => <Text id={optionId ? `${optionId}${index + 1}` : index + 1}>{item?.label}</Text>,
		[optionId]
	);

	const labelId = useMemo(() => uuidv4(), []);

	return (
		<FormControl variant="outlined">
			{label && <InputLabel id={labelId}>{label}</InputLabel>}
			<UIKitDropdown
				labelId={labelId}
				variant="outlined"
				startAdornment={LeftSideIcon && <div className="mr-1">{LeftSideIcon}</div>}
				IconComponent={props => (
					<StyledChevronIcon {...props} className={clsx(props.className, isMemberView && "member-styles")} />
				)}
				disabled={disabled || loading}
				onChange={handleChange}
				displayEmpty
				renderValue={getSelected}
				value={value || ""}
				MenuProps={MenuProps}
				className={clsx(`${className}`, `size_${size}`, size === SizesEnum.SM && "h-10")}
				error={error}
				label={label}
				{...props}
			>
				{options.map((item, index) => (
					<StyledMenuItem
						id={item.id}
						key={item?.id || index}
						value={item.value}
						onClick={item?.onClick}
						className={clsx(dropdownItemWrapperClassName, isMemberView && "member-styles")}
					>
						{customDropdownItemRender ? customDropdownItemRender(item, index) : renderItem(item, index)}
					</StyledMenuItem>
				))}
			</UIKitDropdown>
			{/*{!!error && errorText && <ErrorMessage text={errorText} />}*/}
		</FormControl>
	);
};

export default Dropdown;
