import React, { memo } from "react";

import { IconButton, Popper } from "@material-ui/core";
import styled from "styled-components";

import { Box, Icon } from "shared/ui-kit";

const ActionBox = styled(Box)`
	padding-top: 5px;

	.MuiIconButton-root {
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
		background-color: white;
		padding: 12px;
		height: 40px;
		width: 40px;
		margin-left: 4px;
		border-radius: 4px;
		border: 0;

		&:last-child {
			margin-right: 0;
		}
	}
`;

interface CheckButtonsProps {
	anchorEl: null | HTMLElement;
	onClick?: () => void;
}

const CheckButtons: React.FC<CheckButtonsProps> = ({ onClick, anchorEl }) => {
	const open = Boolean(anchorEl);
	return (
		<Popper placement="bottom-end" open={open} anchorEl={anchorEl}>
			<ActionBox>
				<IconButton onClick={onClick}>
					<Icon name="check" />
				</IconButton>
				<IconButton>
					<Icon name="close" group="filled" fill="#000" />
				</IconButton>
			</ActionBox>
		</Popper>
	);
};

export default memo(CheckButtons);
