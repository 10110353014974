import { PostModel } from "./PostModel";

export enum ContributorLevel {
	ADMIN = "ADMIN",
	ANYONE = "ANYONE",
	PAID = "PAID"
}

export interface CategoryModel {
	cid: string;
	slug: string;
	name: string;
	description: string;
	descriptionParsed: string;
	children: CategoryModel[];
	posts: PostModel[];
	class: string;
	iconName: string;
	isAdminOnly: boolean;
	followed: boolean;
	parentCid: number;
	disabled: number;
	contributorLevel?: ContributorLevel;
	totalContributers: number;
	totalTopicCount: number;
	totalPostCount: number;
	createdAt: string;
	topic_count: number;
	isPrimary?: boolean;
	titleRequired?: boolean;
	notifyCommunity?: boolean;
	default?: boolean;
}

export interface CategoryModelResponse {
	categories: CategoryModel[];
}

export interface Coordinates {
	lon: number;
	lat: number;
}

export interface Member {
	personaId: number;
	isMember: boolean;
	isOwner: boolean;
	isModerator: boolean;
	bbUserId: number;
	isPending?: boolean;
	isInvited?: boolean;
	isAdmin?: boolean;
}

export interface GeoLocation {
	type: string;
	coordinates: number[];
}

export interface Coordinates2 {
	lat: number;
	lon: number;
}

export interface GeoLocation2 {
	type: string;
	coordinates: number[];
}

export interface Member2 {
	personaId: number;
	isMember: boolean;
	isOwner: boolean;
	isModerator: boolean;
	isInvited: boolean;
	isPending: boolean;
	bbUserId: number;
	isAdmin: boolean;
}

export interface Post {
	lastPostedAt: Date;
	total: number;
}

export interface Photo {
	id: string;
	name: string;
	profilePicture: string;
	mediaId: string;
	active: boolean;
}

export interface Job {
	active: boolean;
	companyId: string;
	titleId: string;
	industryId: string;
	companyLabel: string;
	titleLabel: string;
	industryLabel: string;
}

export interface Intro {
	education: any[];
	job: Job[];
}

export interface Organizedby {
	firstName: string;
	lastName: string;
	photos: Photo[];
	intro: Intro;
	personaId: number;
	_id: string;
}

export interface Subgroup {
	_id: string;
	organizerId: number;
	coverUrl?: any;
	name: string;
	description: string;
	chatEnabled: boolean;
	groupChatId?: any;
	chatEnabledUpdatedAt?: any;
	hidden: boolean;
	private: number;
	rules: string;
	location: string;
	placeId: string;
	cid?: any;
	isNational: boolean;
	disablePosts: boolean;
	slug: string;
	oldSlug: string;
	coordinates: Coordinates2;
	geoLocation: GeoLocation2;
	totalMembers: number;
	totalPosts: number;
	parentId: string;
	members: Member2[];
	blocks: any[];
	postId: string;
	updatedAt: Date;
	createdAt: Date;
	post: Post;
	organizedby: Organizedby;
}

export interface Photo2 {
	id: string;
	name: string;
	profilePicture: string;
	mediaId: string;
	active: boolean;
}

export interface Job2 {
	active: boolean;
	companyId: string;
	titleId: string;
	industryId: string;
	companyLabel: string;
	titleLabel: string;
	industryLabel: string;
}

export interface Intro2 {
	education: any[];
	job: Job2[];
}

export interface PotentialMatch {
	firstName: string;
	lastName: string;
	photos: Photo2[];
	intro: Intro2;
	personaId: number;
	_id: string;
}

export interface Intro3 {}

export interface Organizedby2 {
	firstName: string;
	lastName: string;
	photos: any[];
	intro: Intro3;
	personaId: number;
}

export interface Invited {
	personaId: number;
	isMember: boolean;
	isOwner: boolean;
	isModerator: boolean;
	isPending: boolean;
	isInvited: boolean;
	bbUserId: number;
	uid: number;
}
