import styled, { css } from "styled-components";

import { FillOptionsEnum } from ".";

export const IconWrapper = styled("div", {
	shouldForwardProp: prop => prop !== "fillOptions" && prop !== "fillColor" && prop !== "strokeColor"
})<{ fillOptions: FillOptionsEnum; fillColor?: string; strokeColor?: string }>(
	({ fillOptions, fillColor, strokeColor }) => {
		if (fillOptions && (fillColor || strokeColor)) {
			switch (fillOptions) {
				case FillOptionsEnum.SVG:
					return css`
						svg {
							${fillColor &&
							css`
								fill: ${fillColor};
							`}
							${strokeColor &&
							css`
								stroke: ${strokeColor};
							`}
						}
					`;

				case FillOptionsEnum.FIRST_PATH:
					return css`
						svg path:first-of-type {
							${fillColor &&
							css`
								fill: ${fillColor};
							`}
							${strokeColor &&
							css`
								stroke: ${strokeColor};
							`}
						}
					`;

				case FillOptionsEnum.LAST_PATH:
					return css`
						svg path:last-of-type {
							${fillColor &&
							css`
								fill: ${fillColor};
							`}
							${strokeColor &&
							css`
								stroke: ${strokeColor};
							`}
						}
					`;

				case FillOptionsEnum.ALL_RECTS:
					return css`
						svg rect {
							${fillColor &&
							css`
								fill: ${fillColor};
							`}
							${strokeColor &&
							css`
								stroke: ${strokeColor};
							`}
						}
					`;

				case FillOptionsEnum.ALL_PATHS:
					return css`
						svg path {
							${fillColor &&
							css`
								fill: ${fillColor};
							`}
							${strokeColor &&
							css`
								stroke: ${strokeColor};
							`}
						}
					`;
			}
		}
	}
);
