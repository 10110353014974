import React from "react";

import { Box, FormControl, InputAdornment, Select, TextField } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { KeyboardDateTimePicker } from "@material-ui/pickers";
import styled, { css } from "styled-components";

// import { ReactComponent as DiscussionIcon } from "assets/icons/iconDiscussion.svg";
import { ReactComponent as ScheduleIcon } from "assets/icons/iconSchedule.svg";
import { Checkbox, Icon, Input, Button as MkButton, Tabs as MkTabs, Radio, RichEditor, Text } from "shared/ui-kit";

import { customBreakpoints } from "theme/default";

export const FormContainer = styled.form`
	position: relative;
	display: grid;
	grid-template-columns: calc(100% - 304px) 304px;

	&.member .ql-editor {
		min-height: 100px !important;
		padding: 0;
		margin: 0;
	}

	${props => props.theme.breakpoints.down("md")} {
		display: block;
	}
`;

export const Title = styled(Text)`
	${props => props.theme.breakpoints.down("sm")} {
		font-size: 22px;
		line-height: 1.45;
	}
`;

export const Content = styled(Box)`
	${({ isNotifyCommunity }) =>
		isNotifyCommunity &&
		css`
			position: relative;
			height: 100%;
		`}

	.MuiTabs-root {
		.MuiTabs-flexContainer {
			align-items: baseline;

			.MuiButtonBase-root {
				margin-right: 10px;

				${props => props.theme.breakpoints.down("md")} {
					margin-left: 0;
					margin-right: 10px;
				}
			}

			button.MuiTab-root {
				padding: 0;
			}

			button.Mui-selected {
				color: #6173fe;
			}
		}
	}

	.mention {
		color: #222b45;
		font-size: 18px;
		font-weight: bold;
	}
`;

export const Controls = styled(Box)`
	position: fixed;

	right: 0;
	top: 64px;

	width: 304px;
	height: calc(100% - 64px);

	box-shadow: inset 0.5px 0 0 0 #c5cee0;

	background: white;

	overflow-y: auto;

	${({ theme }) => theme.breakpoints.down(customBreakpoints.tabletHorizontalMax)} {
		top: 72px;
		height: calc(100% - 72px);
	}

	${props => props.theme.breakpoints.down("md")} {
		position: relative;
		width: 100%;
		height: auto;

		top: 0;
		left: 0;
		right: 0;

		background: transparent;
		box-shadow: none;
	}
`;

export const PremiumSetBlock = styled(Box)`
	&:not(:first-child) {
		border-top: 1px solid #c5cee0;

		${props => props.theme.breakpoints.down("md")} {
			border-radius: 4px;
			box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
			box-shadow: inset 0 0 0 0.5px #c5cee0;
		}
	}

	padding: 24px 15px 24px 15px;

	${props => props.theme.breakpoints.down("md")} {
		margin-bottom: 16px;

		background: white;
	}

	&.early-access {
		margin-bottom: 0;
	}

	&.hidden {
		visibility: hidden;
		max-height: 0;
		padding: 0;
	}

	&.no-shadow {
		border-top: 0;
		box-shadow: 0;
		padding: 0;
		> div:first-child {
			border: 0;
			padding: 0;
			> div:first-child {
				padding: 0;
			}
		}
		${props => props.theme.breakpoints.up("sm")} {
			> div:first-child > div:first-child {
				padding: 16px 0;
			}
		}

		${props => props.theme.breakpoints.up("md")} {
			> div:first-child {
				padding: 0 16px;
			}
		}
	}

	&.pt {
		padding-top: 16px;
		padding-left: 16px;
		> p {
			margin-top: 16px;
		}
		${props => props.theme.breakpoints.down("md")} {
			padding: 0;
			box-shadow: unset;
			border: 0;
		}
	}
`;

export const WebSection = styled(PremiumSetBlock)`
	${props => props.theme.breakpoints.down("md")} {
		display: none;
	}
`;

export const MobileSection = styled(Box)`
	display: none;
	${props => props.theme.breakpoints.down("md")} {
		padding-top: 24px;
		display: flex;
	}
`;

export const Button = styled(MkButton)`
	padding-left: 0;
	text-align: center;
	width: 100%;
	margin: 0;
`;

export const PostInfo = styled(Box)`
	display: inline-flex;
	margin: 20px 0 30px 0;
	align-items: center;

	& > div:not(:first-child) {
		margin-left: 10px;
	}

	${props => props.theme.breakpoints.down("md")} {
		align-items: flex-start;
	}

	&.isMemberView {
		margin-bottom: 12px;
		align-items: center;
	}
`;

PostInfo.AvatarWrapper = styled(Box)`
	width: 48px;
	height: 48px;

	.MuiAvatar-circular {
		width: 48px;
		height: 48px;
	}

	border-radius: 50%;

	overflow: hidden;
`;

export const PostBasicSettings = styled(Box)`
	display: flex;
	align-items: center;

	&.isMemberView {
		flex-direction: column;
		align-items: flex-start;
	}

	${props => props.theme.breakpoints.down("md")} {
		flex-direction: column;
		align-items: flex-start;
	}
`;

PostBasicSettings.Options = styled(Box)`
	display: flex;
	flex-direction: column;
	> div {
		margin-bottom: 6px;
	}
	.MuiInputBase-root {
		margin: 0;
	}

	${props => props.theme.breakpoints.up("md")} {
		flex-direction: row;
		align-items: center;
		> div {
			margin-right: 6px;
		}
		&.no-mr > div {
			margin-right: 10;
			margin-top: 2px;
		}
	}
`;

export const SectionHeader = styled(Box)`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

export const MobileToolbarWrapper = styled(Box)<{ boardNotifyCommunity?: boolean }>`
	padding: 16px;

	background-color: #fff;

	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);

	box-shadow: inset 0 0 0 0.5px #c5cee0;
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
	border-top: 0;

	${({ boardNotifyCommunity }) =>
		boardNotifyCommunity &&
		css`
			border-radius: 0;
		`};
`;

export const ComponentGroup = styled(Box)`
	display: flex;
	flex-direction: row;
	align-content: center;

	& > div {
		${({ isMemberView }) =>
			isMemberView
				? css`
						margin-bottom: 8px;
				  `
				: css`
						margin-top: 16px;
				  `}
	}

	& > div:not(:last-child) {
		margin-right: 16px;
	}
`;

export const StyledTabText = styled(Text)`
	font-weight: 600;
`;

export const Tabs = styled(MkTabs)`
	border-bottom: 1px solid #c5cee0;
	min-height: auto;

	.MuiTab-root {
		min-height: auto;
	}
`;
Tabs.TabWrapper = styled(Box)`
	min-width: 100%;
	text-transform: none;

	svg {
		margin-bottom: -6px; //todo: refactor!!!
	}
`;
Tabs.TabFooter = styled(Box)`
	height: 4px;
	border-top-left-radius: 100px;
	border-top-right-radius: 100px;
	background: #6173fe;
	margin: 10px 10px 0 10px;

	${props => props.theme.breakpoints.down("md")} {
		margin: 10px 0 0 0;
	}

	display: ${({ isActive }) => (isActive ? "block" : "none")};
`;

// const SelectInput = styled(props => (
// 	<TextField
// 		{...props}
// 		startAdornment={
// 			<InputAdornment position="start">
// 				<DiscussionIcon />
// 			</InputAdornment>
// 		}
// 	/>
// ));

export const NarrowSelect = styled(props => <Select {...props} />)`
	background: white;
	height: 28px;
	max-width: 200px;
	margin-right: 15px;
	${props => props.theme.breakpoints.down("md")} {
		margin: 0 0 5px 0;
	}

	.MuiTypography-root {
		font-size: 13px;
		font-weight: 600;
		line-height: 1.85;
		color: #222b45;
		max-width: 122px;
		margin: 0;
		text-overflow: ellipsis;
		overflow: hidden;
	}

	.MuiOutlinedInput-input {
		padding: 2px 8px;
		color: #222b45;

		width: 165px;

		display: flex;
		align-items: center;
	}

	.MuiOutlinedInput-notchedOutline {
		border: solid 0.5px rgb(197, 206, 224, 0.5);
		border-radius: 4px;
	}

	fieldset {
		height: 100%;
		top: 0;

		border-color: #c5cee0 !important;

		span {
			display: none;
		}
	}

	svg {
		width: 24px;
		color: black;
	}

	.MuiBox-root {
		svg {
			width: 100%;
			margin-right: 0;
		}
	}
`;

const StyledExpandMoreIcon = styled(ExpandMoreIcon)`
	color: black;
	position: absolute;
	right: 6px;
	width: 24px;
`;

const StyledBox = styled(Box)`
	position: relative;
	max-width: 200px;
	align-items: center;
	display: flex;

	${({ isMemberView }) =>
		isMemberView &&
		css`
			fieldset {
				border-color: #e4e9f2 !important;
			}
		`}
`;

export const NarrowPicker = styled(props => (
	<StyledBox isMemberView={props.isMemberView}>
		<KeyboardDateTimePicker
			{...props}
			InputAdornmentProps={{ position: "start" }}
			TextFieldComponent={PickerInput}
			keyboardIcon={<Icon width="22" name="clock" group="filled" />}
		/>
		<StyledExpandMoreIcon onClick={props.clickAction} />
	</StyledBox>
))`
	position: relative;

	.MuiInputBase-root {
		min-width: 190px;
		height: 28px;
		background: white;
	}

	.MuiInputBase-input,
	.MuiOutlinedInput-input {
		font-size: 13px;
		font-weight: 600;
		line-height: 1.85;
		color: #222b45;
	}

	.MuiInputAdornment-positionStart {
		margin-right: 3px;
	}

	.MuiOutlinedInput-adornedStart {
		padding-left: 8px;
	}

	.MuiButtonBase-root {
		padding: 0;
	}

	.MuiOutlinedInput-notchedOutline {
		top: -5px !important;
	}

	fieldset {
		border-color: #c5cee0;
	}

	.MuiFormHelperText-root {
		position: absolute;
		top: 100%;
	}
`;

const PickerInput = styled(props => (
	<TextField
		{...props}
		startAdornment={
			<InputAdornment position="start">
				<ScheduleIcon />
			</InputAdornment>
		}
	/>
))``;

export const StyledRichEditor = styled(RichEditor)`
	.ql-blank {
		font-size: 18px;
		font-weight: 600;
		line-height: 1.33;
		color: #8f9bb3;
	}
`;

export const EditorForm = styled(Box)<{ boardNotifyCommunity?: boolean }>`
	${({ boardNotifyCommunity }) =>
		boardNotifyCommunity &&
		css`
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
		`};

	height: 100%;
	display: flex;
	flex-direction: column;

	&.manage {
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
		box-shadow: inset 0 0 0 0.5px #c5cee0;
		border-radius: 4px;
		overflow: hidden;

		${props => props.theme.breakpoints.down("md")} {
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
		}
	}
`;

export const SwitchWrapper = styled(Box)`
	display: flex;
	align-items: center;
	width: 100%;
	height: 50px;
	background-color: #ffffff;
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
	margin-top: 1px;
	padding-left: 27px;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
	box-shadow: inset 0 0 0 0.5px #c5cee0;
	border-top: 0;

	&.mv-16 {
		margin: 16px 0;
	}
`;

export const TitleInputWrapper = styled(Box)`
	width: 100%;
	background: white;
	box-shadow: 0 0.5px 0.5px #c5cee0;
	position: relative;
	margin-bottom: 16px;

	&.manage {
		margin-bottom: 0;
		padding: 16px 0 20px 0;
	}
`;

export const TitleInputForm = styled(Box)`
	max-width: 692px;
	margin: 0 auto;
	&.manage {
		padding: 0 30px;
	}
	&.member {
		margin: 0;
	}
`;

export const TitleInput = styled(Input)`
	fieldset {
		display: none;
	}

	input {
		font-size: 18px;
		font-weight: bold;
		line-height: 1.33;
		color: #222b45;
		padding: 6px 0;

		&.manage {
			padding: 14px 0;
		}

		&::placeholder {
			color: #8f9bb3;
			opacity: 1;
		}
	}
`;

export const UserFullName = styled(Text)`
	white-space: nowrap;
	text-transform: capitalize;
	font-size: 13px;
	font-weight: 600;
	line-height: 1.85;
	color: #222b45;

	margin-right: 13px;

	${props => props.theme.breakpoints.down("sm")} {
		margin: 0 0 5px 0;
	}
`;

export const CategoryOptionWrapper = styled(({ children }) => <span>{children}</span>)`
	.prefix-icon {
		margin: 0 10px 0 0;
		zoom: 0.7;
	}
`;

export const CategoryIconWrapper = styled(Box)`
	display: inline-flex;
	justify-content: center;
	align-items: center;

	width: 20px;
	height: 20px;

	margin-right: 5px;

	.svg {
		width: 100%;
		height: 100%;

		padding: 0;
		margin: 0 !important;
	}
`;

export const InputWrapper = styled(Box)`
	width: 100%;

	.MuiSelect-outlined.MuiSelect-outlined {
		max-width: 70px;

		p {
			overflow: hidden;
		}
	}
`;

export const DialogButton = styled(Button)`
	width: 120px;
	min-width: auto;

	padding: 14px 18px;
`;

export const StyledRadio = styled(Radio)`
	${props => props.theme.breakpoints.down("sm")} {
		flex-direction: column;
		.MuiFormGroup-row {
			flex-direction: column;
		}
	}
`;

export const EditorSection = styled(Box)`
	position: relative;
	min-height: 320px;
`;

export const MobilePageSpace = styled(Box)`
	width: 100%;
	height: 80px;
`;

export const PickerWrapper = styled(FormControl)`
	&.date {
		.MuiIconButton-label {
			svg {
				path {
					&:first-child {
						fill: #c5cee0;
					}
				}
			}
		}
	}

	&.time {
		.MuiIconButton-label {
			svg {
				path {
					&:last-child {
						fill: #c5cee0;
					}
				}
			}
		}
	}

	&.member-spacing > div:first-child {
		margin-top: 8px;
	}
`;

export const StyledCheckbox = styled(Checkbox)`
	margin-top: 16px;

	.MuiTypography-root {
		font-weight: 600;
		font-size: 13px;
	}

	${props => props.theme.breakpoints.up("md")} {
		margin-top: 0;

		${({ isNotifyCommunity }) =>
			isNotifyCommunity
				? css`
						margin-bottom: 0;
				  `
				: css`
						position: absolute;
						bottom: 16px;
						left: 26px;
				  `}
	}
`;

export const GroupNameText = styled(Box)`
	background-color: #f7f9fc;
	border: 1px solid rgba(229, 231, 235);

	&:hover {
		border: 1px solid #000000;
	}
`;
