import config from "config/appConfig";
import { FilesContextValuesType } from "types";

import { IStateProps } from "utils/StateOf";
import createStore from "utils/createStore";

const localStorageKey = `${config.GLOBAL_CONSTANTS.LOCAL_STORAGE_PREFIX}${config.HOOK_STORAGE_KEYS.FILES_DATA}`;

export const initialState: IStateProps<FilesContextValuesType> = {
	files: [],
	isLoading: false,
	page: 1,
	filesShowPerPage: 20,
	filesTotalCount: 10,
	fileInfoPopup: {
		open: false
	},
	fileUrl: "",
	fileName: "",
	extension: "",
	fileUploadUrl: "",
	shouldStopSearching: false
};

export default createStore<FilesContextValuesType>({
	initialState,
	persistant: true,
	persistantName: localStorageKey
});
