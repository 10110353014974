import React, { forwardRef, useImperativeHandle } from "react";

import { Theme } from "@material-ui/core";

import clsx from "clsx";
import _ from "lodash";
import moment from "moment";
import { DropzoneOptions, useDropzone } from "react-dropzone";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { MdCancel } from "react-icons/md";
import styled, { css } from "styled-components";

import { useCommunity, useUser } from "shared/hooks";
import { Icon } from "shared/ui-kit";

export enum orientationConst {
	horizontal = "horizontal",
	vertical = "vertical"
}

const defaultProps = {
	accept: [
		{
			fileType: "image/png",
			name: "png"
		},
		{
			fileType: "image/jpeg",
			name: "jpeg"
		},
		{
			fileType: "image/svg+xml",
			name: "svg"
		}
	],
	multiple: false,
	label: "Upload Logo",
	description: (
		<>
			Drag and drop file here or <span className="anchor">browse</span> to choose a file
		</>
	),
	icon: <Icon group={""} fill={"#c5cee0"} name={"cloud-upload"} width={54} height={54} viewBox={"0 0 25 24"} />,
	orientation: orientationConst.vertical,
	width: {
		vertical: 330,
		horizontal: 400
	},
	height: {
		vertical: 250,
		horizontal: 140
	}
};

type PickedFile = Pick<File, "name" | "size" | "type">;
type CustomFile = PickedFile & {
	file?: string;
	time?: string;
	url: string;
};

const FileUploaderWrapper = styled.div<{
	orientation?: orientationConst.horizontal | orientationConst.vertical;
}>`
	border: dashed 2px #e4e9f2;
	border-radius: 4px;
	width: 100%;
	height: 100%;

	cursor: pointer;
	outline: none;
	${({ orientation }) => {
		if (orientation === orientationConst.horizontal) {
			return css`
				display: flex;
				justify-content: center;
				align-items: center;
				text-align: left;
			`;
		}
		return "";
	}}

	.explorer-uploader {
		font-size: 13px;
		text-align: center;
		border-radius: 2px;
		padding: 20px;

		width: 100%;
		height: 100%;

		outline: none;

		${({ orientation }) => {
			if (orientation === orientationConst.horizontal) {
				return css`
					display: flex;
					flex-direction: row-reverse;
					justify-content: space-between;
					width: 100%;

					padding: 25px 55px 25px 40px;
				`;
			}
			return "";
		}}

		.uploader-content {
			${({ orientation }) => {
				if (orientation === orientationConst.horizontal) {
					return css`
						display: block;
						width: 70%;
						max-width: 200px;
					`;
				}
				return "";
			}}
		}

		.icon {
			margin: ${({ orientation }) => (orientation === orientationConst.horizontal ? "auto 0" : "10px")};
			text-align: center;
		}

		.label {
			font-weight: bold;
			text-align: ${({ orientation }) => (orientation === orientationConst.horizontal ? "left" : "center")};
			font-size: 19px;
		}

		.description {
			color: ${props => props.theme.palette.text.secondary};
			max-width: ${({ orientation }) => (orientation === orientationConst.horizontal ? "none" : "200px")};
			text-align: ${({ orientation }) => (orientation === orientationConst.horizontal ? "left" : "center")};
			white-space: ${({ orientation }) => (orientation === orientationConst.horizontal ? "break-spaces" : undefined)};
			margin: 6px auto auto;

			.anchor {
				color: ${props => props.color || props.theme.palette.warning.contrastText} !important;
				font-weight: bold;
			}
		}

		.accept-extension {
			color: ${props => props.theme.palette.text.secondary};
			max-width: ${({ orientation }) => (orientation === orientationConst.horizontal ? "none" : "200px")};
			text-align: ${({ orientation }) => (orientation === orientationConst.horizontal ? "left" : "center")};
			white-space: ${({ orientation }) => (orientation === orientationConst.horizontal ? "break-spaces" : undefined)};
			margin: 3px auto auto;

			font-size: 10px;
			font-weight: bold;
		}
	}
`;

const FileUploader = ({
	uploaderControl,
	...rest
}: {
	color?: string;
	theme?: Theme;
	uploaderControl: JSX.Element;
	label?: string;
	description?: React.ReactFragment;
	icon?: JSX.Element;
	accept: { fileType: string; name: string }[];
	orientation?: orientationConst.horizontal | orientationConst.vertical;
	uploaderId?: string;
}): JSX.Element => {
	const label = rest.label || defaultProps.label;
	const description = rest.description || defaultProps.description;
	const icon = rest.icon || defaultProps.icon;
	const accept = rest.accept || defaultProps.accept;
	const orientation = rest.orientation || defaultProps.orientation;
	const _uploaderId = rest?.uploaderId;
	return (
		<FileUploaderWrapper orientation={orientation} {...rest} id={_uploaderId}>
			{uploaderControl}
			<div className="explorer-uploader">
				<div className="icon">{icon}</div>
				<div className="uploader-content">
					<div className="label">
						<span className="explorer-uploader-label-text">{label}</span>
					</div>
					<div className="description">
						<span className="text">{description}</span>
					</div>
					<div className="accept-extension">
						<span className="text">
							{accept.map((acc, index, arr) => {
								return index === arr.length - 1 ? `${acc.name.toUpperCase()}` : `${acc.name.toUpperCase()}, `;
							})}
						</span>
					</div>
				</div>
			</div>
		</FileUploaderWrapper>
	);
};

const UploaderFileListWrapper = styled.div`
	padding: 10px;
	border: dashed 2px #e4e9f2;

	.uploader-list-item {
		display: flex;
		align-items: center;
		padding: 5px;
		background: #f5f5f5;
		box-shadow: 0 0 0 2px #eee;
		margin-bottom: 10px;
		min-width: 330px;
		min-height: 250px;

		.item-img-section {
			max-width: 75px;
			max-height: 75px;
			padding: 2.5px;
			overflow: hidden;

			img {
				max-height: 100%;
				max-width: 100%;
			}
		}

		.item-details-section {
			padding: 0 10px;
			.name {
				font-size: 13px;
				color: ${props => props.theme.palette.text.primary};
			}
			.description {
				font-size: 11px;
				color: ${props => props.theme.palette.text.secondary};
				font-weight: bold;
				span {
					margin-right: 10px;
				}
			}
		}

		.item-img-close-btn {
			padding: 0 5px;
			margin-left: auto;
			color: ${props => props.theme.palette.text.secondary};
		}
	}
`;

const Listing = ({
	theme,
	list,
	onRemove
}: {
	list: Partial<CustomFile>[];
	onRemove: (name?: string) => void;
	theme?: Theme;
}): JSX.Element => (
	<UploaderFileListWrapper theme={theme}>
		{list.map(file => (
			<div className="uploader-list-item" key={file.name}>
				<div className="item-img-section">
					<img src={file.url} alt={file.file} />
				</div>
				<div className="item-details-section">
					<div className="name">{file.name}</div>
					<div className="description">
						<div>{file.time}</div>
						<div>{file.type}</div>
					</div>
				</div>
				<div className="item-img-close-btn" onClick={() => onRemove(file.name)}>
					<div>
						<IoIosCloseCircleOutline />
					</div>
				</div>
			</div>
		))}
	</UploaderFileListWrapper>
);

const PreviewWrapper = styled.div<{
	orientation?: orientationConst.horizontal | orientationConst.vertical;
}>`
	border: dashed 2px #e4e9f2;
	padding: 5px;
	display: flex;
	align-items: center;
	justify-content: center;

	width: 100%;
	height: 100%;

	.preview-section {
		position: relative;
		width: 100%;
		height: 100%;

		overflow: hidden;
		display: flex;
		justify-content: center;
		align-items: center;

		${({ orientation }) => {
			if (orientation === orientationConst.horizontal) {
				return css`
					align-items: center;
				`;
			} else {
				return css`
					flex-direction: column;
				`;
			}
		}}

		.close {
			position: absolute;
			top: 2px;
			right: 4px;
			font-size: 24px;
			color: #ddd;

			cursor: pointer;
		}

		.item-img-section {
			display: flex;
			background: #fafafa;
			width: ${({ orientation }) => (orientation === orientationConst.vertical ? "175px" : "120px")};
			height: ${({ orientation }) => (orientation === orientationConst.vertical ? "175px" : "120px")};
			border: 1px solid #e4e9f2;
			border-radius: 4px;
			align-items: center;
			justify-content: center;
			margin: auto;
			padding: 10px;
			position: relative;

			img {
				max-width: 100%;
				max-height: 100%;
			}
		}

		.item-description {
			padding: 10px 30px;
			text-align: center;
			${({ orientation }) => {
				if (orientation === orientationConst.horizontal) {
					return css`
						max-width: calc(100% - 175px);
					`;
				}
			}};
			overflow: hidden;

			.filename {
				font-size: 18px;
				font-weight: bold;
				color: ${props => props.theme.palette.text.primary};
				text-overflow: ellipsis;
				overflow: hidden;
				display: inline;

				margin-bottom: 5px;
			}

			.filetype {
				font-size: 12px;
				color: ${props => props.theme.palette.text.secondary};
				text-overflow: ellipsis;
				overflow: hidden;

				margin-bottom: 10px;
			}

			.close--bin {
				display: inline-flex;
				margin-left: 7px;
				justify-content: center;
				align-items: center;
			}
		}
	}
`;

const Preview = ({
	file: { url, file, name, type },
	onRemove,
	orientation,
	dropFileType = "times",
	showPreview = false,
	icon = defaultProps.icon,
	closeIconId
}: {
	file: Partial<CustomFile>;
	onRemove: (name?: string) => void;
	orientation?: orientationConst.horizontal | orientationConst.vertical;
	dropFileType?: "times" | "bin";
	showPreview?: boolean;
	icon?: React.ReactNode;
	closeIconId?: string;
}): JSX.Element => (
	<PreviewWrapper className="preview-wrapper" orientation={orientation}>
		<div className="preview-section">
			{dropFileType === "times" && (
				<span onClick={() => onRemove(name)} className="close" id={closeIconId}>
					<MdCancel />
				</span>
			)}
			{showPreview ? (
				<div className="item-img-section">
					{type?.startsWith("video") ? (
						<video>
							<source src={url} />
						</video>
					) : (
						<img src={url} alt={file} />
					)}
				</div>
			) : (
				<div className="icon">{icon}</div>
			)}

			<div className="item-description">
				<div className="filename">{name}</div>
				{dropFileType === "bin" && (
					<span onClick={() => onRemove(name)} className="close--bin">
						<Icon fill="#ff463d" group="filled" height={15} name="trash" width={13} />
					</span>
				)}

				<div className="filetype">{type}</div>
			</div>
		</div>
	</PreviewWrapper>
);

const UploaderWrapper = styled.div<{
	orientation?: orientationConst.horizontal | orientationConst.vertical;
	width?: number | string;
	height?: number | string;
}>`
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	outline: none;

	width: ${({ width, orientation }) =>
		width
			? Number.isNaN(width)
				? width
				: `${width}px`
			: orientation === orientationConst.horizontal
			? "400px"
			: "330px"};
	height: ${({ height, orientation }) =>
		height
			? Number.isNaN(height)
				? height
				: `${height}px`
			: orientation === orientationConst.horizontal
			? "140px"
			: "250px"};

	> div {
		outline: none;
		width: 100%;
		height: 100%;
	}

	.file-uploader {
		&_hidden {
			visibility: hidden;
			opacity: 0;
			pointer-events: none;
			max-width: 0;
			max-height: 0;
		}
	}
`;

export type UploaderProps = {
	accept?: { fileType: string; name: string }[];
	customLister?: boolean;
	customTemplate?: boolean;
	multiple?: boolean;
	name?: string;
	onChange: (files: Partial<File>[] | never) => void;
	theme?: Theme;
	label?: string;
	description?: React.ReactFragment;
	icon?: JSX.Element;
	orientation?: orientationConst.horizontal | orientationConst.vertical;
	width?: number | string;
	height?: number | string;
	dropFileType?: "times" | "bin";
	showPreview?: boolean;
	urls?: string[];
	closeIconId?: string;
	uploaderId?: string;
	wrapperClassName?: string;
};

const Uploader = forwardRef(
	(
		{
			name,
			theme,
			customTemplate,
			customLister,
			onChange,
			dropFileType = "times",
			showPreview = true,
			urls = [],
			closeIconId,
			uploaderId,
			wrapperClassName,
			...props
		}: Readonly<UploaderProps>,
		ref
	): JSX.Element => {
		const [files, setFiles] = React.useState<Partial<CustomFile>[]>(urls?.map(url => ({ url })));
		const accept = props.accept || defaultProps.accept;
		const multiple = props.multiple || defaultProps.multiple;
		const orientation = props.orientation || defaultProps.orientation;
		const width =
			props.width ||
			(orientation === orientationConst.vertical ? defaultProps.width.vertical : defaultProps.width.horizontal);
		const height =
			props.height ||
			(orientation === orientationConst.vertical ? defaultProps.height.vertical : defaultProps.height.horizontal);
		// const acceptType = accept.map(acc => acc.fileType);

		const { getData: getUserData } = useUser();
		const { isMemberView } = getUserData();

		const { communityColors } = useCommunity();

		const onDrop = (acceptedFiles: File[]): void => {
			onChange(acceptedFiles);

			setFiles([
				...files,
				...acceptedFiles.map<CustomFile>(file => ({
					...(_.pick(file, ["name", "size", "type"]) as PickedFile),
					time: moment(file.lastModified).format("DD MMMM YYYY, HH:mm:ss"),
					url: URL.createObjectURL(file)
				}))
			]);
		};

		const onFileRemove = (fileName?: string) => {
			const arr = [...files];

			const index = arr.findIndex(({ name: n }) => n === fileName);

			if (index > -1) {
				arr.splice(index, 1);
				onChange(arr);
				setFiles(arr);
			}
		};

		const acceptFileType = accept.reduce((prevVal, accept, idx) => {
			return idx == 0 ? accept.fileType : `${prevVal}, ${accept}`;
		}, "");

		const { getRootProps, getInputProps, open } = useDropzone({
			accept: acceptFileType,
			multiple,
			onDrop
		} as DropzoneOptions);

		useImperativeHandle(ref, () => ({
			clear() {
				setFiles([]);
				onChange([]);
			},
			callReplace() {
				setFiles([]);
				onChange([]);
				open();
			}
		}));

		return (
			<UploaderWrapper
				className={clsx(wrapperClassName, "uploader-wrapper")}
				orientation={orientation}
				width={width}
				height={height}
			>
				<div
					{...getRootProps()}
					className={`file-uploader  ${!(!files.length || (files.length && multiple)) && "file-uploader_hidden"}`}
				>
					{!customTemplate && (
						<FileUploader
							color={isMemberView ? communityColors.primary : undefined}
							theme={theme}
							label={props.label}
							description={props.description}
							icon={props.icon}
							accept={accept}
							uploaderControl={<input name={name} {...getInputProps({ disabled: false })} />}
							orientation={orientation}
							uploaderId={uploaderId}
						/>
					)}
				</div>
				{/*{!files.length || (files.length && multiple) ? (*/}
				{/*  <div {...getRootProps()}>*/}
				{/*    {!customTemplate && (*/}
				{/*      <FileUploader*/}
				{/*        theme={theme}*/}
				{/*        label={props.label}*/}
				{/*        description={props.description}*/}
				{/*        icon={props.icon}*/}
				{/*        accept={accept}*/}
				{/*        uploaderControl={*/}
				{/*          <input name={name} {...getInputProps({ disabled: false })} />*/}
				{/*        }*/}
				{/*        orientation={orientation}*/}
				{/*      />*/}
				{/*    )}*/}
				{/*  </div>*/}
				{/*) : (*/}
				{/*  <div {...getRootProps()}>*/}
				{/*    <input name={name} {...getInputProps({ disabled: false })} />*/}
				{/*  </div>*/}
				{/*)}*/}
				{!!files.length &&
					!customLister &&
					(multiple && !showPreview ? (
						<Listing theme={theme} list={files} onRemove={onFileRemove} />
					) : (
						<Preview
							onRemove={onFileRemove}
							file={files[0]}
							orientation={orientation}
							dropFileType={dropFileType}
							showPreview={showPreview}
							icon={props.icon}
							closeIconId={closeIconId}
						/>
					))}
			</UploaderWrapper>
		);
	}
);

export default Uploader;
