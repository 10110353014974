import { useMemo } from "react";

import { DefaultResponseType } from "types";

import { GetSearchPostTotalType } from "modules/Manage/Data/types";
import { API_URLS } from "shared/constants";
import useHttpActions from "shared/services/api/core/useHttpActions";
import BaseConverter from "shared/services/converters/baseConverter";

import useMediaApiService from "shared/services/useMediaApiService";

import { MediaType, TrackFormModel, UpdateTrackFormModel } from "shared/types";

import { TrackModel } from "./../types/TrackModel";

import { convertTrack, convertTracks, convertUpdateTrack } from "./converters";

const useTrackApiService = (isMarketing = false) => {
	const params = useMemo(() => (isMarketing ? { customErrorHandlers: [] } : undefined), [isMarketing]);

	const actions = useHttpActions(params);
	const mediaService = useMediaApiService();

	const methods = useMemo(
		() => ({
			prepareUpdateQueryParams: async (personaId: string | number, trackFileUrl?: string, trackImageUrl?: string) => {
				const defaultQuery = {
					personaId: personaId as number,
					expireAt: "infinite" as number | "infinite"
				};

				let trackFileMedia, trackImageMedia;
				if (trackFileUrl) {
					trackFileMedia = await mediaService.createMedia({
						...defaultQuery,
						type: MediaType.audioFile,
						uri: trackFileUrl
					});
				}

				if (trackImageUrl) {
					trackImageMedia = await mediaService.createMedia({
						...defaultQuery,
						type: MediaType.trackPhoto,
						uri: trackImageUrl
					});
				}

				return {
					trackFileMedia,
					trackImageMedia
				};
			},
			getAllTracks: async data => {
				return convertTracks(await actions.get(API_URLS.TRACKS.GET_ALL, data));
			},
			getTrack: async ({ trackId, groupId }: { trackId: string; groupId?: string }) => {
				const query: {
					offset: number;
					limit: number;
					order: number;
					sortBy: string;
					type: string;
					trackId: string;
					groupId?: string;
				} = {
					offset: 1,
					limit: 10,
					order: -1,
					sortBy: "createdAt",
					type: "track",
					trackId
				};

				if (groupId) {
					query.groupId = groupId;
				}

				return convertTracks(await actions.get(API_URLS.TRACKS.GET, query));
			},
			getTracks: async ({
				offset = 1,
				limit = 10,
				keyword = "",
				type = "track",
				sortBy = "createdAt",
				groupId = "",
				eventId = "",
				personaId,
				showId
			}: {
				showId?: string;
				offset?: number;
				limit?: number;
				keyword?: string;
				type: string;
				sortBy?: string;
				groupId?: string;
				eventId?: string;
				personaId?: number;
			}) => {
				const query: {
					offset: number;
					limit: number;
					order: number;
					sortBy: string;
					type: string;
					showId?: string;
					keyword?: string;
					groupId?: string;
					eventId?: string;
					personaId?: number;
				} = {
					offset,
					limit,
					order: -1,
					sortBy,
					type,
					showId
				};

				if (keyword) {
					query.keyword = keyword;
				}

				if (groupId) {
					query.groupId = groupId;
				}

				if (eventId) {
					query.eventId = eventId;
				}

				if (personaId) {
					query.personaId = personaId;
				}

				return convertTracks(await actions.get(API_URLS.TRACKS.GET_ALL, query));
			},
			getTrackCount: async ({ keyword = "", groupId }: { keyword?: string; groupId?: string }) => {
				const query: { type: string; keyword?: string; groupId?: string } = {
					type: "track"
				};
				if (keyword) {
					query.keyword = keyword;
				}
				if (groupId) {
					query.groupId = groupId;
				}
				return BaseConverter.convert<GetSearchPostTotalType>(await actions.get(API_URLS.TRACKS.GET_COUNT, query));
			},
			createTrack: async (data: TrackFormModel) => {
				const { trackFileMedia, trackImageMedia } = await methods.prepareUpdateQueryParams(
					data.personaId,
					data.trackFileUrl,
					data.trackImageUrl
				);

				return BaseConverter.convert<TrackModel>(
					await actions.post(
						API_URLS.TRACKS.CREATE,
						convertTrack(data, trackFileMedia[0], trackImageMedia ? trackImageMedia[0] : null)
					)
				);
			},
			updateTrack: async (data: UpdateTrackFormModel) => {
				const { trackFileMedia, trackImageMedia } = await methods.prepareUpdateQueryParams(
					data.personaId,
					data.trackFileUrl,
					data.trackImageUrl
				);

				return BaseConverter.convert<TrackModel>(
					await actions.put(
						API_URLS.TRACKS.UPDATE,
						convertUpdateTrack(
							data,
							trackFileMedia ? trackFileMedia[0] : null,
							trackImageMedia ? trackImageMedia[0] : null
						)
					)
				);
			},
			deleteTrack: async (id: string): Promise<DefaultResponseType> => {
				return BaseConverter.convert(await actions.delete(API_URLS.TRACKS.DELETE(id)));
			}
		}),
		[actions, mediaService]
	);

	return useMemo(
		() => ({
			...methods
		}),
		[methods]
	);
};

export default useTrackApiService;
