import React, { FC, ReactNode } from "react";

import { Box } from "@material-ui/core";

import { saveAs } from "file-saver";
import JSZip from "jszip";
import { DateTime } from "luxon";

import { ReactComponent as IconPause } from "assets/icons/pause-track.svg";
import VinylImg from "assets/images/vinyl.png";
import { MenuDots, MenuDotsOption, PlaceholderImage, SkeletonBase } from "shared/Components";
import { useAlbums, useCommunity, usePlayer, useSeries } from "shared/hooks";
import { GetAlbumResponse, PlaceholderImageType } from "shared/types";
import { Icon, Text } from "shared/ui-kit";

import { AlbumContainer, AlbumDescription, AlbumHeader, AlbumTracks } from "./style";

import AlbumTrack from "../AlbumTrack";
import { BuyTrackButton, PlayTrack } from "../AlbumTrack/style";

interface AlbumDetailsProps {
	details?: GetAlbumResponse;
	loading: boolean;
	unlockBlock: ReactNode;
	lastTrackRef: React.RefObject<HTMLDivElement>;
	options?: MenuDotsOption[];
}

const AlbumDetails: FC<AlbumDetailsProps> = ({ details, unlockBlock, lastTrackRef, loading, options }) => {
	const { setPlayTracks, setAlbumPlaying, setPlay, getData: getPlayerData } = usePlayer();
	const { setSeriesPlayState } = useSeries();
	const { albumPlaying, play } = getPlayerData();

	const {
		setTrackDownloaded,
		readSong,
		getAlbumDownloadUrls,
		setTrackProgressDialog,
		getData: getAlbumsData
	} = useAlbums();
	const { trackProgressDialog } = getAlbumsData();

	const { communityColors } = useCommunity();

	const handlePlayAlbum = () => {
		if (details) {
			if (albumPlaying === details._id) {
				setPlay(!play);
			} else {
				setPlayTracks({
					idx: 0,
					tracks: {
						items: details.musics
					}
				});
				setPlay(true);
				setAlbumPlaying(details._id);
				setSeriesPlayState();
			}
		}
	};

	const handleStartFromThisSong = (id: string) => {
		if (details) {
			const filteredMusics = details.musics.filter(m => !m.locked);
			setPlayTracks({
				idx: filteredMusics.findIndex(m => m._id === id),
				tracks: {
					items: filteredMusics
				}
			});
		}
	};

	const handleDownloadAlbum = async () => {
		if (details) {
			const { musics } = await getAlbumDownloadUrls(details._id, { musicIds: null, enableNames: true });

			setTrackProgressDialog(
				musics.map(track => ({
					name: track.name,
					track: track.url,
					progress: 0
				}))
			);

			const zip = new JSZip();

			const files = await Promise.all(
				musics.map(async track => {
					return {
						title: `${details.title}/${track.name}.mp3`,
						blob: await readSong(track.url)
					};
				})
			);

			files.forEach(file => zip.file(file.title, file.blob));

			zip.generateAsync({ type: "blob" }).then(
				content => {
					saveAs(content, `${details.title}.zip`);
				},
				err => {
					console.log("error is", err);
				}
			);

			setTrackDownloaded(true);
		}
	};

	return (
		<AlbumContainer>
			{!details ? (
				<>
					<AlbumHeader>
						<AlbumHeader.AlbumCover>
							<SkeletonBase variant={"rect"} className={"skeleton-img"} width={"100%"} height={"100%"} />
							<SkeletonBase variant={"circle"} className={"skeletonAlbumCover"} width={"100%"} height={"100%"} />
						</AlbumHeader.AlbumCover>
						<AlbumHeader.AlbumDetails>
							<Box className="mobile-mb">
								<SkeletonBase width={256} height={40} />
								<SkeletonBase width={256} height={67} />
								<SkeletonBase width={146} height={24} />
								<SkeletonBase width={100} height={40} />
							</Box>
							<AlbumHeader.AlbumActions>
								<SkeletonBase variant={"circle"} width={40} height={40} />
								<SkeletonBase width={132} height={50} />
								<SkeletonBase width={38} height={50} />
							</AlbumHeader.AlbumActions>
						</AlbumHeader.AlbumDetails>
					</AlbumHeader>
					<AlbumDescription>
						<SkeletonBase width={100} height={40} />
						<SkeletonBase width={"80%"} height={23} />
						<SkeletonBase width={"80%"} height={23} />
						<SkeletonBase width={"80%"} height={23} />
					</AlbumDescription>
				</>
			) : (
				<>
					<AlbumHeader>
						<AlbumHeader.AlbumCover>
							{details?.meta?.url ? (
								<>
									<img className="albumCover" src={details.meta.url} alt={details.title} />
								</>
							) : (
								<PlaceholderImage
									type={PlaceholderImageType.ALBUM_PREVIEW}
									width={224}
									height={224}
									viewBox={"0 0 400 400"}
								/>
							)}
							<img src={VinylImg} alt="vinyl" />
						</AlbumHeader.AlbumCover>
						<AlbumHeader.AlbumDetails>
							<Box className="mobile-mb">
								<Text variant="subtitle1">{details.artist}</Text>
								<Text variant="h3">{details.title}</Text>
								{details?.releaseDate && (
									<Text variant="caption" className="releaseDate">
										Released: {DateTime.fromISO(details.releaseDate).toFormat("MMM d, yyyy")}
									</Text>
								)}
								<Text variant="subtitle1">
									{details.genre.map((g, i) => (i + 1 === details.genre.length ? g : `${g}, `))}
								</Text>
							</Box>
							<AlbumHeader.AlbumActions>
								{!!details?.musics?.length && !details.locked ? (
									<>
										<PlayTrack btnColor={communityColors.btn} large onClick={handlePlayAlbum}>
											{albumPlaying === details._id && play ? (
												<IconPause fill="white" width={18} height={18} />
											) : (
												<Icon name="play" group="filled" fill="white" width={18} height={18} />
											)}
										</PlayTrack>
										{!!details?.musics?.length && details?.canBeDownloaded && (
											<BuyTrackButton
												palette="light"
												onClick={handleDownloadAlbum}
												disabled={!!trackProgressDialog.length}
											>
												Download
											</BuyTrackButton>
										)}
									</>
								) : (
									<>{unlockBlock}</>
								)}
								{!!options?.length && <MenuDots options={options} memberView />}
							</AlbumHeader.AlbumActions>
						</AlbumHeader.AlbumDetails>
					</AlbumHeader>
					<AlbumDescription>
						<Text variant="subtitle1">Description</Text>
						<Text variant="caption">{details.description}</Text>
					</AlbumDescription>
				</>
			)}
			{loading ? (
				<AlbumTracks>
					<SkeletonBase width={100} height={40} />
					<AlbumTrack loading lastTrackRef={null} />
					<AlbumTrack loading lastTrackRef={null} />
					<AlbumTrack loading lastTrackRef={null} />
				</AlbumTracks>
			) : (
				<AlbumTracks>
					<Text variant="subtitle1">Album Tracks</Text>
					{(details?.musics || []).map((m, i) => (
						<AlbumTrack
							individualAccess={details?.individualAccess}
							albumId={details?._id || ""}
							handleStartFromThisSong={handleStartFromThisSong}
							isFree={!details?.locked || true}
							key={i}
							track={m}
							order={i + 1}
							lastTrackRef={details ? (i + 1 === details.musics.length ? lastTrackRef : null) : null}
							isAllowDownloadAlbum={!!details?.canBeDownloaded}
						/>
					))}
				</AlbumTracks>
			)}
		</AlbumContainer>
	);
};

export default AlbumDetails;
