import React, { FC, useEffect, useMemo } from "react";

import { Box } from "@material-ui/core";
import { Controller, useForm } from "react-hook-form";

import { SuggestionTypes } from "shared/contexts/UserContext/UserContext";
import { months } from "shared/data/months";
import { useNotification, usePersona, useUser } from "shared/hooks";
import { ProfileType } from "shared/types";
import { ProfileJobType, VolunteerType } from "shared/types/UserModel";

import { getYearsList } from "utils/getYearsList";

import { EnableFeature, MemberDialog, SelectInput } from "../../shared";
import LabeledInput from "../../shared/LabeledInput";
import { InputWrapper } from "../../shared/style";
import { EditProfileWrapper } from "../EditProfileDialog/style";

const NewJobDialog: FC<{ item?: ProfileJobType | VolunteerType | undefined; volunteer?: boolean }> = ({
	item,
	volunteer
}) => {
	const {
		control,
		watch,
		formState: { isDirty, errors, isSubmitting },
		handleSubmit,
		setValue,
		getValues,
		setError
	} = useForm({ mode: "onChange" });

	const {
		searchSuggestions,
		loadSuggestedLocations,
		setNewJobDialog,
		setNewVolunteerJobDialog,
		setMyExperienceDialog,
		getData: getUserData
	} = useUser();
	const {
		companiesSuggestions,
		jobTitleSuggestions,
		industrySuggestions,
		suggestedLocations,
		volunteerOrganizationSuggestions,
		causeSuggestions
	} = getUserData();

	const { updatePersona, getData: getPersonaData } = usePersona();
	const { persona: profile } = getPersonaData();

	const { showMessage } = useNotification();
	const { startAt, endAt, currentPosition } = watch(["startAt", "endAt", "currentPosition"]);

	useEffect(() => {
		if (startAt && startAt > endAt) {
			setValue("endAt", startAt);
		}
	}, [endAt, setValue, startAt]);

	useEffect(() => {
		if (item) {
			setValue("currentPosition", true);
		}
	}, [item, setValue]);

	const handleDeleteExperience = () => {
		if (!profile) return;

		if (volunteer) {
			updatePersona(
				{
					...profile,
					volunterExperience: profile.volunterExperience?.filter(
						j =>
							j.role !== (item as VolunteerType).role &&
							j.cause !== (item as VolunteerType).cause &&
							j.organization !== (item as VolunteerType).organization
					)
				},
				true
			);
		} else {
			updatePersona(
				{
					...profile,
					jobs: profile.jobs?.filter(
						j => j.jobTitle !== (item as ProfileJobType).jobTitle && j.company !== (item as ProfileJobType).company
					)
				},
				true
			);
		}

		volunteer ? setNewVolunteerJobDialog() : setNewJobDialog();
		setMyExperienceDialog(true);
	};

	const onSubmit = async data => {
		if (!profile) return;

		const startAt = new Date().setFullYear(parseInt(data.startYear.value), parseInt(data.startMonth.value));
		const endAt = data.endYear
			? new Date().setFullYear(parseInt(data.endYear.value), parseInt(data.endMonth.value))
			: null;

		if (endAt && startAt > endAt) {
			setError("endYear", {
				message: "End date can't be before start date"
			});
			return;
		}

		let newPersona: ProfileType = {
			...profile
		};

		if (volunteer) {
			const exp = {
				active: false,
				cause: {
					_id: data.cause.value,
					label: data.cause.label
				},
				description: data.description || "",
				endAt: currentPosition ? null : new Date(endAt || 0),
				location: data.location.label,
				organization: {
					_id: data.organization.value,
					label: data.organization.label
				},
				present: currentPosition,
				role: {
					_id: data.role.value,
					label: data.role.label
				},
				startAt: new Date(startAt)
			};

			newPersona = {
				...newPersona,
				volunterExperience: profile.volunterExperience
					? item
						? profile.volunterExperience.map(job =>
								job.organization._id === (item as VolunteerType).organization._id &&
								job.role._id === (item as VolunteerType).role._id
									? exp
									: job
						  )
						: [...profile.volunterExperience, exp]
					: [exp]
			};
		} else {
			const exp = {
				company: {
					_id: data.company.value,
					label: data.company.label
				},
				description: data.description,
				endAt: currentPosition ? null : `${new Date(endAt || 0)}`,
				industry: {
					_id: data.industry.value,
					label: data.industry.label
				},
				jobTitle: {
					_id: data.jobTitle.value,
					label: data.jobTitle.label
				},
				location: data.location.label,
				present: currentPosition ? true : false,
				startAt: `${new Date(startAt)}`,
				active: false
			};

			newPersona.jobs = profile.jobs
				? item
					? profile.jobs.map(job =>
							job.company._id === (item as ProfileJobType).company._id &&
							job.jobTitle._id === (item as ProfileJobType).jobTitle._id
								? exp
								: job
					  )
					: [...profile.jobs, exp]
				: [exp];

			if (data.updateIntro) {
				newPersona.intro.job = [
					{
						company: exp.company,
						industry: exp.industry,
						jobTitle: exp.jobTitle
					}
				];
			}
		}

		await updatePersona(newPersona, true);

		showMessage("Successfully updated your experience");
		volunteer ? setNewVolunteerJobDialog() : setNewJobDialog();
		setMyExperienceDialog(true);
	};

	const years = useMemo(() => getYearsList().map(year => ({ label: year.toString(), value: year.toString() })), []);

	if (!profile) return null;

	return (
		<MemberDialog
			title={volunteer ? "Volunteer Experience" : "Experience"}
			open={true}
			onClose={() => (volunteer ? setNewVolunteerJobDialog() : setNewJobDialog())}
			goBack={() => {
				volunteer ? setNewVolunteerJobDialog() : setNewJobDialog();
				setMyExperienceDialog(true);
			}}
			confirmLeave={isDirty}
			customWidth={512}
			footerPrimary={{
				text: "Save changes",
				disabled: !isDirty || isSubmitting || !!Object.keys(errors).length,
				loading: isSubmitting,
				onClick: handleSubmit(onSubmit)
			}}
			footerSecondary={item ? { text: "Delete", onClick: handleDeleteExperience } : undefined}
		>
			<form onSubmit={handleSubmit(onSubmit)}>
				<EditProfileWrapper>
					{volunteer ? (
						<>
							<Controller
								name="role"
								control={control}
								defaultValue={
									item && { value: (item as VolunteerType).role._id, label: (item as VolunteerType).role.label }
								}
								rules={{
									required: "Role is required",
									validate: val => {
										if (!val.value || !val.label) {
											return "Please choose a job option";
										}
									}
								}}
								render={({ onChange, value, ref }) => (
									<InputWrapper>
										<SelectInput
											name="role"
											onChange={onChange}
											value={value ? value.label : null}
											placeholder=" "
											label="Role *"
											loadSuggestions={val => searchSuggestions(SuggestionTypes.jobTitle, val)}
											maxHeight={260}
											error={errors?.role?.message}
											noOptionsHeadline="Type your job title ( ex: UX Designer )"
											allowCreatingSuggestion={SuggestionTypes.jobTitle}
											options={
												jobTitleSuggestions
													? jobTitleSuggestions.map(suggestion => ({
															value: suggestion._id,
															label: suggestion.label
													  }))
													: []
											}
											selectInputRef={ref}
										/>
									</InputWrapper>
								)}
							/>
							<Controller
								name="cause"
								control={control}
								defaultValue={
									item && { value: (item as VolunteerType).cause._id, label: (item as VolunteerType).cause.label }
								}
								rules={{
									required: "Cause is required",
									validate: val => {
										if (!val.value || !val.label) {
											return "Please choose a job option";
										}
									}
								}}
								render={({ onChange, value, ref }) => (
									<InputWrapper>
										<SelectInput
											name="cause"
											onChange={onChange}
											value={value ? value.label : null}
											placeholder=" "
											loadSuggestions={val => searchSuggestions(SuggestionTypes.cause, val)}
											label="Cause *"
											maxHeight={260}
											error={errors?.cause?.message}
											noOptionsHeadline="Type Cause Name ( ex: Animal Welfare )"
											allowCreatingSuggestion={SuggestionTypes.cause}
											options={
												causeSuggestions
													? causeSuggestions.map(suggestion => ({
															value: suggestion._id,
															label: suggestion.label
													  }))
													: []
											}
											selectInputRef={ref}
										/>
									</InputWrapper>
								)}
							/>
							<Controller
								name="organization"
								control={control}
								defaultValue={
									item && {
										value: (item as VolunteerType).organization._id,
										label: (item as VolunteerType).organization.label
									}
								}
								rules={{
									required: "Organization is required",
									validate: val => {
										if (!val.value || !val.label) {
											return "Please choose a company option";
										}
									}
								}}
								render={({ onChange, value, ref }) => (
									<InputWrapper>
										<SelectInput
											name="organization"
											onChange={onChange}
											placeholder=" "
											label="Organization *"
											value={value ? value.label : null}
											loadSuggestions={val => searchSuggestions(SuggestionTypes.volunteerOrganization, val)}
											maxHeight={260}
											error={errors?.organization?.message}
											noOptionsHeadline="Type your company ( ex: Google )"
											allowCreatingSuggestion={SuggestionTypes.volunteerOrganization}
											options={
												volunteerOrganizationSuggestions
													? volunteerOrganizationSuggestions.map(suggestion => ({
															value: suggestion._id,
															label: suggestion.label
													  }))
													: []
											}
											selectInputRef={ref}
										/>
									</InputWrapper>
								)}
							/>
						</>
					) : (
						<>
							<Controller
								name="jobTitle"
								control={control}
								defaultValue={
									item && {
										value: (item as ProfileJobType).jobTitle._id,
										label: (item as ProfileJobType).jobTitle.label
									}
								}
								rules={{
									required: "Job title is required",
									validate: val => {
										if (!val.value || !val.label) {
											return "Please choose a job option";
										}
									}
								}}
								render={({ onChange, value, ref }) => (
									<InputWrapper>
										<SelectInput
											name="jobTitle"
											onChange={onChange}
											value={value ? value.label : null}
											placeholder=" "
											label="Job title *"
											loadSuggestions={val => searchSuggestions(SuggestionTypes.jobTitle, val)}
											maxHeight={260}
											error={errors?.jobTitle?.message}
											noOptionsHeadline="Type your job title ( ex: UX Designer )"
											options={
												jobTitleSuggestions
													? jobTitleSuggestions.map(suggestion => ({
															value: suggestion._id,
															label: suggestion.label
													  }))
													: []
											}
											selectInputRef={ref}
										/>
									</InputWrapper>
								)}
							/>
							<Controller
								name="industry"
								control={control}
								defaultValue={
									item && {
										value: (item as ProfileJobType).industry._id,
										label: (item as ProfileJobType).industry.label
									}
								}
								rules={{
									required: "Industry is required",
									validate: val => {
										if (!val.value || !val.label) {
											return "Please choose a job option";
										}
									}
								}}
								render={({ onChange, value, ref }) => (
									<InputWrapper>
										<SelectInput
											name="industry"
											onChange={onChange}
											value={value ? value.label : null}
											placeholder=" "
											loadSuggestions={val => searchSuggestions(SuggestionTypes.industry, val)}
											label="Industry *"
											maxHeight={260}
											error={errors?.industry?.message}
											noOptionsHeadline="Type your industry ( ex: Media )"
											allowCreatingSuggestion={SuggestionTypes.industry}
											options={
												industrySuggestions
													? industrySuggestions.map(suggestion => ({
															value: suggestion._id,
															label: suggestion.label
													  }))
													: []
											}
											selectInputRef={ref}
										/>
									</InputWrapper>
								)}
							/>
							<Controller
								name="company"
								control={control}
								defaultValue={
									item && {
										value: (item as ProfileJobType).company._id,
										label: (item as ProfileJobType).company.label
									}
								}
								rules={{
									required: "Company is required",
									validate: val => {
										if (!val.value || !val.label) {
											return "Please choose a company option";
										}
									}
								}}
								render={({ onChange, value, ref }) => (
									<InputWrapper>
										<SelectInput
											name="company"
											onChange={onChange}
											placeholder=" "
											label="Company *"
											value={value ? value.label : null}
											loadSuggestions={val => searchSuggestions(SuggestionTypes.company, val)}
											maxHeight={260}
											error={errors?.company?.message}
											noOptionsHeadline="Type your company ( ex: Google )"
											allowCreatingSuggestion={SuggestionTypes.company}
											options={
												companiesSuggestions
													? companiesSuggestions.map(suggestion => ({
															value: suggestion._id,
															label: suggestion.label
													  }))
													: []
											}
											selectInputRef={ref}
										/>
									</InputWrapper>
								)}
							/>
						</>
					)}
					<Controller
						name="location"
						control={control}
						defaultValue={item && { value: item.location, label: item.location }}
						rules={{
							required: "Location is required",
							validate: val => {
								if (!val.value || !val.label) {
									return "Please choose a location option";
								}
							}
						}}
						render={({ onChange, value, ref }) => (
							<InputWrapper>
								<SelectInput
									name="location"
									onChange={onChange}
									placeholder=" "
									label="Location *"
									value={value ? value.label : null}
									loadSuggestions={val => loadSuggestedLocations(val)}
									maxHeight={260}
									error={errors?.location?.message}
									noOptionsHeadline="Type your location ( ex: New York )"
									options={
										suggestedLocations
											? suggestedLocations.map(suggestion => ({
													value: suggestion.place_id,
													label: suggestion.name
											  }))
											: []
									}
									selectInputRef={ref}
								/>
							</InputWrapper>
						)}
					/>
					<Box className="two-inputs-row flex-end">
						<Controller
							name="startYear"
							control={control}
							rules={{
								required: "Start Year is required"
							}}
							defaultValue={years.find(y => y.value === `${new Date().getFullYear()}`)}
							render={({ onChange, value, ref }) => (
								<InputWrapper width="49%">
									<SelectInput
										label="Start date *"
										name="date"
										value={value && value.label}
										onChange={onChange}
										error={errors?.startYear?.message}
										placeholder="Year"
										options={years}
										maxHeight={180}
										selectInputRef={ref}
									/>
								</InputWrapper>
							)}
						/>
						<Controller
							name="startMonth"
							control={control}
							rules={{
								required: "Start month is required"
							}}
							defaultValue={months[0]}
							render={({ onChange, value, ref }) => (
								<InputWrapper width="49%">
									<SelectInput
										label=" "
										name="date"
										value={value && value.label}
										onChange={onChange}
										error={errors?.startAt?.message}
										placeholder="Month"
										options={months}
										maxHeight={180}
										selectInputRef={ref}
									/>
								</InputWrapper>
							)}
						/>
					</Box>
					{!currentPosition && (
						<Box className="two-inputs-row flex-end">
							<Controller
								name="endYear"
								control={control}
								rules={{
									required: "End Year is required",
									validate: ({ value }) => {
										const startVal = parseInt(getValues("startYear").value);
										if (startVal > parseInt(value)) return "End date can't be before start date";
										return undefined;
									}
								}}
								defaultValue={years.find(y => y.value === `${new Date().getFullYear()}`)}
								render={({ onChange, value, ref }) => (
									<InputWrapper width="49%">
										<SelectInput
											label="End date *"
											name="date"
											value={value && value.label}
											onChange={onChange}
											error={errors?.endYear?.message}
											placeholder="Year"
											options={years}
											maxHeight={180}
											selectInputRef={ref}
										/>
									</InputWrapper>
								)}
							/>
							<Controller
								name="endMonth"
								control={control}
								rules={{
									required: "End month is required"
								}}
								defaultValue={months[0]}
								render={({ onChange, value, ref }) => (
									<InputWrapper width="49%">
										<SelectInput
											label=" "
											name="date"
											value={value && value.label}
											onChange={onChange}
											error={errors?.startAt?.message}
											placeholder="Month"
											options={months}
											maxHeight={180}
											selectInputRef={ref}
										/>
									</InputWrapper>
								)}
							/>
						</Box>
					)}
					<Controller
						name="description"
						control={control}
						defaultValue={item?.description}
						render={({ onChange, value, ref }) => (
							<InputWrapper>
								<LabeledInput
									textarea
									name="description"
									onChange={onChange}
									placeholder=" "
									label="Description"
									value={value}
									error={errors?.description?.message}
									inputRef={ref}
								/>
							</InputWrapper>
						)}
					/>
					<Controller
						name="currentPosition"
						control={control}
						render={({ onChange, value }) => (
							<InputWrapper>
								<EnableFeature
									title="Present"
									description="I currently work in this role"
									onChange={onChange}
									value={value}
									verticalPadding
								/>
							</InputWrapper>
						)}
					/>
					<Controller
						name="updateIntro"
						control={control}
						render={({ onChange, value }) => (
							<InputWrapper>
								<EnableFeature title="Update my Intro" onChange={onChange} value={value} />
							</InputWrapper>
						)}
					/>
				</EditProfileWrapper>
			</form>
		</MemberDialog>
	);
};

export default NewJobDialog;
