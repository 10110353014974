/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useContext } from "react";

import ClickAwayListener from "@material-ui/core/ClickAwayListener";

import { useAfterFirstRender, useStateWithHistory } from "../../hooks";

const ToggleStateContext = React.createContext(false);
const ToggleDispatchContext = React.createContext<React.Dispatch<React.SetStateAction<boolean>>>(() => {});

const ToggleButton = ({ children }) => {
	const visible = useContext<boolean>(ToggleStateContext);
	const setVisibility = useContext(ToggleDispatchContext);
	if (typeof children === "function") {
		return children(visible, setVisibility);
	}
	return React.Children.map(children, elem =>
		React.cloneElement(elem, { onClick: () => setVisibility(prev => !prev) })
	);
};

const Content = ({ children }) => {
	const visible = useContext<boolean>(ToggleStateContext);
	const setVisibility = useContext<React.Dispatch<React.SetStateAction<boolean>>>(ToggleDispatchContext);
	if (typeof children === "function") {
		return children(visible, setVisibility);
	}
	return visible ? children : null;
};

export interface ToggleProps {
	children: React.ReactElement;
	onClose?: () => void;
	onOpen?: () => void;
}

const Toggle = ({ children, onOpen, onClose }: ToggleProps) => {
	const [visible, prevVisiblity, setVisibility] = useStateWithHistory(false);
	useAfterFirstRender(() => {
		if (visible !== prevVisiblity) {
			if (visible) {
				onOpen?.();
			} else {
				onClose?.();
			}
		}
	}, [visible, onOpen, onClose]);

	return (
		<ToggleDispatchContext.Provider value={setVisibility}>
			<ToggleStateContext.Provider value={visible}>
				<ClickAwayListener onClickAway={() => setVisibility(false)}>
					{React.Children.count(children) !== 1 ? <span>{children}</span> : children}
				</ClickAwayListener>
			</ToggleStateContext.Provider>
		</ToggleDispatchContext.Provider>
	);
};

Toggle.Button = ToggleButton;
Toggle.Content = Content;
export default Toggle;
