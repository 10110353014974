import React, { memo, useCallback, useEffect, useMemo, useState } from "react";

import { useHistory } from "react-router-dom";

import { ReactComponent as CalendarIcon } from "assets/icons/calendar.svg";
import { ReactComponent as GroupsIcon } from "assets/icons/groups.svg";
import { ReactComponent as BellIcon } from "assets/icons/icon-bell-linear-2.svg";
import { ReactComponent as DeleteIcon } from "assets/icons/icon-delete.svg";

import { ReactComponent as EmptyHeartIcon } from "assets/icons/icon-heart-empty.svg";
import { ReactComponent as HandIcon } from "assets/icons/iconHandWave.svg";
import { ReactComponent as ReplyIcon } from "assets/icons/iconReply.svg";
import { ReactComponent as ProfileIcon } from "assets/icons/profile1.svg";

import { List } from "modules/MemberHome/View/Components";

import { Header } from "modules/Messaging/View/Components/Messages/styles";
import { MenuDots, MenuDotsOption } from "shared/Components";

import { useCommunity, useMemberRoutes, useUser } from "shared/hooks";
import { ProfilePhotoSizes } from "shared/types";

import { getResizedImage } from "utils/serviceUtils/cdnImages";

import EmptyNotifications from "./EmptyNotifications";
import { Item, NotificationIcon, StyledMuteIcon, Wrapper } from "./styles";

import { useActivities } from "../../../Data";
import { ActivityModel } from "../../../Data/types";
import {
	ActivityConnectionRequestAcceptedData,
	ActivityEventStartingData,
	ActivityEventUpdateData,
	ActivityFundraiserParticipantData,
	ActivityGroupEventData,
	ActivityGroupInviteAcceptedData,
	ActivityGroupInviteData,
	ActivityLatestPostingGroupData,
	ActivityLoyaltyCoinsData,
	ActivityMemberReqData,
	ActivityNewGroupPostData,
	ActivityReactedToPostData,
	ActivityReplyMentionData,
	ActivityReplyToPostData,
	ActivityType,
	ActivityVolunteerData,
	EventInviteData
} from "../../../Data/types/ActivityModel";

interface Props {
	open: boolean;
	preview?: boolean;
	notificationPageUrl?: string;
	onClose?: () => void;
}

const NotificationsCard: React.FC<Props> = memo(({ open, preview, notificationPageUrl, onClose }) => {
	const history = useHistory();

	const {
		loadActivities,
		deleteActivity,
		markRead,
		mutePersona,
		muteSimilarActivities,
		getData: getActivitiesData
	} = useActivities();
	const { activities, loading, allowToLoadMore } = getActivitiesData();

	const { clearCounter } = useUser();

	const { getMemberRoutesData } = useMemberRoutes();
	const { routes: memberRoutes } = getMemberRoutesData();

	const { communityColors } = useCommunity();

	const [page, setPage] = useState(1);
	const pageSize = useMemo(() => (preview ? 12 : 20), [preview]);

	const primaryColor = useMemo(() => communityColors.primary, [communityColors.primary]);

	useEffect(() => {
		open && loadActivities(page, pageSize);
	}, [open, loadActivities, page, pageSize]);

	useEffect(() => {
		open && clearCounter();
	}, [open, clearCounter]);

	const getIcon = useCallback((item: ActivityModel) => {
		switch (item.type) {
			case ActivityType.replytopost:
			case ActivityType.replytopicmention:
				return <ReplyIcon />;

			case ActivityType.reactedtopost:
			case ActivityType.reactedtocomment:
			case ActivityType.reactedtoreply:
				return (item.data as ActivityReactedToPostData)?.profilePicture ? (
					<NotificationIcon
						style={{ backgroundImage: `url(${(item.data as ActivityReactedToPostData).profilePicture})` }}
					/>
				) : (
					<EmptyHeartIcon />
				);

			case ActivityType.volunteerjoin:
			case ActivityType.volunteerleft:
			case ActivityType.volunteerslotdeleted:
				return (item as ActivityVolunteerData).data.senderPhoto ? (
					<NotificationIcon style={{ backgroundImage: `url(${(item as ActivityVolunteerData).data.senderPhoto})` }} />
				) : (
					<EmptyHeartIcon />
				);

			case ActivityType.fundraiserparticipant:
				return (item as ActivityFundraiserParticipantData)?.data?.senderPhoto ? (
					<NotificationIcon
						style={{ backgroundImage: `url(${(item as ActivityFundraiserParticipantData).data.senderPhoto})` }}
					/>
				) : (
					<EmptyHeartIcon />
				);

			case ActivityType.eventinvite:
				return (item.data as EventInviteData)?.profilePicture ? (
					<NotificationIcon
						style={{
							backgroundImage: `url(${getResizedImage(
								(item.data as EventInviteData)?.profilePicture,
								ProfilePhotoSizes.size50x50
							)})`
						}}
					/>
				) : (
					<EmptyHeartIcon />
				);

			case ActivityType.reqaccepted:
				return (item.data as ActivityConnectionRequestAcceptedData)?.connection?.profilePhoto ? (
					<NotificationIcon
						style={{
							backgroundImage: `url(${(item.data as ActivityConnectionRequestAcceptedData).connection.profilePhoto})`
						}}
					/>
				) : (
					<EmptyHeartIcon />
				);

			case ActivityType.connectionreq:
				return <EmptyHeartIcon />;

			case ActivityType.eventstarting:
			case ActivityType.eventupdate:
			case ActivityType.groupevent:
			case ActivityType.discover:
			case ActivityType.eventadmin:
				return <CalendarIcon />;

			case ActivityType.memberreq:
			case ActivityType.reqapproved:
			case ActivityType.reqdeclined:
			case ActivityType.inviteaccepted:
			case ActivityType.newgrouppost:
				return <GroupsIcon />;

			case ActivityType.groupinvite:
				return (item.data as ActivityGroupInviteData)?.group?.coverUrl ? (
					<NotificationIcon
						style={{
							backgroundImage: `url(${(item.data as ActivityGroupInviteData).group.coverUrl})`
						}}
					/>
				) : (
					<GroupsIcon />
				);

			case ActivityType.latestpostingroup:
				return (item.data as ActivityLatestPostingGroupData)?.group?.coverUrl ? (
					<NotificationIcon
						style={{
							backgroundImage: `url(${(item.data as ActivityLatestPostingGroupData).group.coverUrl})`
						}}
					/>
				) : (
					<GroupsIcon />
				);

			case ActivityType.loyaltyCoins:
				const url = (item.data as ActivityLoyaltyCoinsData)?.connection?.photos?.length
					? (item.data as ActivityLoyaltyCoinsData)?.connection?.photos[0]?.profilePicture || ""
					: "";
				return url ? (
					<NotificationIcon
						style={{
							backgroundImage: `url(${url})`
						}}
					/>
				) : (
					<ProfileIcon />
				);

			case ActivityType.newepisode:
				// TODO: add
				return ""; // item?.data?.show?.image;

			case ActivityType.taggedStory:
			case ActivityType.postmention:
			case ActivityType.replymention:
				return <ProfileIcon />;

			case ActivityType.payreqrec:
				return "";

			case ActivityType.iwasthere:
				return "";

			case ActivityType.welcome:
				return <HandIcon />;

			default:
				return "";
		}
	}, []);

	const getTitle = useCallback((item: ActivityModel) => {
		switch (item.type) {
			case ActivityType.volunteerjoin:
				return (
					<>
						<b>{(item as ActivityVolunteerData).data.name}</b> has signed up for{" "}
						<b>{(item as ActivityVolunteerData).data.need}</b> at{" "}
						<b>{(item as ActivityVolunteerData).data.eventName}</b>
					</>
				);

			case ActivityType.volunteerleft:
				return (
					<>
						<b>{(item as ActivityVolunteerData).data.name}</b> has opted out from{" "}
						<b>{(item as ActivityVolunteerData).data.need}</b> at{" "}
						<b>{(item as ActivityVolunteerData).data.eventName}</b>
					</>
				);

			case ActivityType.volunteerslotdeleted:
				return (
					<>
						The volunteer slot <b>{(item as ActivityVolunteerData).data.need}</b> you signed up for was deleted
					</>
				);

			case ActivityType.fundraiserparticipant:
				return (
					<>
						<b>{item.senderName}</b> Created <b>{(item as ActivityFundraiserParticipantData).data.fundraiserName}</b>{" "}
						fundraiser. Start adding your fundraiser contacts.
					</>
				);

			case ActivityType.replytopost:
				return (
					<>
						<b>{item.senderName}</b> replied to your post.
					</>
				);

			case ActivityType.replytopicmention:
				return (
					<>
						<b>{item.senderName}</b> mentioned you in a reply.
					</>
				);

			case ActivityType.reactedtopost:
				return (
					<>
						<b>{item.senderName}</b> reacted on your post.
					</>
				);

			case ActivityType.reactedtocomment:
				return (
					<>
						<b>{item.senderName}</b> reacted on your comment.
					</>
				);

			case ActivityType.reactedtoreply:
				return (
					<>
						<b>{item.senderName}</b> reacted on your reply.
					</>
				);

			case ActivityType.reqaccepted:
				return (
					<>
						You are connected to <b>{item.senderName}</b>.
					</>
				);

			case ActivityType.connectionreq:
				return (
					<>
						<b>{item.senderName}</b> invited you to connect.
					</>
				);

			case ActivityType.eventstarting:
				return (
					<>
						<b>{(item.data as ActivityEventStartingData)?.event?.eventName}</b> will start in 1 hour.
					</>
				);

			case ActivityType.eventadmin:
				return (
					<>
						<b>{item.senderName}</b> invited you to <b>{(item.data as ActivityEventStartingData)?.event?.eventName}</b>{" "}
						event as a host.
					</>
				);

			case ActivityType.eventupdate:
				return (
					<>
						<b>{(item.data as ActivityEventUpdateData)?.event?.eventName}</b> event was updated.
					</>
				);

			case ActivityType.groupevent:
				return (
					<>
						{/* // groupevent: item.data.eventName */}
						You have been invited to <b>groupevent</b>.
					</>
				);

			case ActivityType.eventinvite:
				return (
					<>
						You have been invited to <b>{(item.data as EventInviteData).eventName}</b>.
					</>
				);

			case ActivityType.discover:
				return "Check out events happening nearby";

			case ActivityType.memberreq:
				return (
					<>
						<b>{item.senderName}</b> would like to join the group{" "}
						<b>{(item.data as ActivityMemberReqData)?.group?.name || ""}</b>
					</>
				);

			case ActivityType.reqapproved:
				return (
					<>
						Request to join <b>{(item.data as ActivityGroupInviteAcceptedData)?.group?.name}</b> has been approved!
					</>
				);

			case ActivityType.groupinvite:
				return (
					<>
						<b>{item.senderName}</b> invited you to join the group{" "}
						<b>{(item.data as ActivityGroupInviteData)?.group?.name}</b>
					</>
				);

			case ActivityType.inviteaccepted:
				return (
					<>
						You accepted your invite to join <b>{(item.data as ActivityGroupInviteAcceptedData).group?.name}</b>
					</>
				);

			case ActivityType.newgrouppost:
				return (
					<>
						<b>{item.senderName}</b> posted in <b>{(item.data as ActivityNewGroupPostData).group?.name}</b>
					</>
				);

			case ActivityType.latestpostingroup:
				return (
					<>
						You have new <b>{(item.data as ActivityLatestPostingGroupData).group.total || 0}</b> posts in{" "}
						<b>{(item.data as ActivityLatestPostingGroupData).group.name}</b>. Check them out.
					</>
				);

			case ActivityType.loyaltyCoins:
				return (item?.data as ActivityLoyaltyCoinsData)?.message || "";

			case ActivityType.newepisode:
				// TODO: add support
				return "A new episode is available."; // show: item?.data?.show?.title,
			// return "A new episode of {{show}} is available."; // show: item?.data?.show?.title,

			case ActivityType.taggedStory:
				return (
					<>
						<b>{item.senderName}</b> tagged you in a <b>story</b>, tap to see now.
					</>
				);

			case ActivityType.postmention:
				return (
					<>
						<b>{item.senderName}</b> mentioned you in a post.
					</>
				);

			case ActivityType.replymention:
				return (
					<>
						<b>{item.senderName}</b> mentioned you in a reply.
					</>
				);

			case ActivityType.reqdeclined:
				return (
					<>
						Request to join <b>{(item.data as ActivityGroupInviteAcceptedData)?.group?.name}</b> has been declined!
					</>
				);

			case ActivityType.payreqrec:
				return "";

			case ActivityType.iwasthere:
				// caption = strings(
				// 	item.data.totalAttendees === 1
				// 		? 'activity.near_by_single'
				// 		: 'activity.near_by',
				// 	{
				// 		people: item.data.totalAttendees,
				// 		location: strings('activity.nearby'),
				// 	},
				// );
				return "";

			case ActivityType.welcome:
				return (
					<>
						Welcome to <b>Vyoo</b>! Find events nearby to make new connections.
					</>
				);

			default:
				return "";
		}
	}, []);

	const getOptions = useCallback(
		(item: ActivityModel) => {
			const options: MenuDotsOption[] = [
				{
					icon: <StyledMuteIcon />,
					name: `Mute ${item?.senderName}`,
					onClick: () => mutePersona(item.senderPersonaId)
				},
				{
					icon: <BellIcon />,
					name: "Stop receiving similar notification",
					onClick: () => muteSimilarActivities(item.type),
					value: 1
				}
			];

			if (item?.activityId) {
				options.push({
					icon: <DeleteIcon />,
					name: "Remove this notification",
					onClick: () => deleteActivity(`${item!.activityId}`)
				});
			}

			return options;
		},
		[deleteActivity, muteSimilarActivities, mutePersona]
	);

	const handleItemClick = useCallback(
		(item: ActivityModel) => {
			if (!item.read && item._id) {
				markRead([item._id]);
			}

			switch (item.type) {
				case ActivityType.fundraiserparticipant:
					history.push(
						`${memberRoutes?.member.fundraiser.getPath()}/${
							(item as ActivityFundraiserParticipantData).data.fundraiserId
						}?mode=inviteContact`
					);
					break;

				case ActivityType.volunteerjoin:
				case ActivityType.volunteerleft:
				case ActivityType.volunteerslotdeleted:
					history.push(`${memberRoutes?.member.event.getPath()}/${(item as ActivityVolunteerData).data.eventId}`);
					break;

				case ActivityType.replytopost:
					history.push(`${memberRoutes?.member.post.getPath()}/${(item.data as ActivityReplyToPostData).topic.tid}`);
					break;

				case ActivityType.replytopicmention:
					// TODO: we also need to cover it properly for groups as soon as we support it
					if (!(item.data as ActivityReplyMentionData)?.group?.slug) {
						history.push(`${memberRoutes?.member.post.getPath()}/${(item.data as ActivityReplyMentionData).topic.tid}`);
					}
					break;

				case ActivityType.reactedtopost:
					history.push(`${memberRoutes?.member.post.getPath()}/${(item.data as ActivityReactedToPostData).topic.tid}`);
					break;

				case ActivityType.reactedtocomment:
					history.push(`${memberRoutes?.member.post.getPath()}/${(item.data as ActivityReactedToPostData).topic.tid}`);
					break;

				case ActivityType.reactedtoreply:
					history.push(`${memberRoutes?.member.post.getPath()}/${(item.data as ActivityReactedToPostData).topic.tid}`);
					break;

				case ActivityType.eventinvite:
					history.push(`${memberRoutes?.member.event.getPath()}/${(item.data as EventInviteData).eventId}`);
					break;

				case ActivityType.reqaccepted:
					history.push(`${memberRoutes?.member.profile.getPath()}/${item.senderPersonaId}`);
					break;

				case ActivityType.connectionreq:
					history.push(`${memberRoutes?.member.profile.getPath()}/${item.senderPersonaId}`);
					break;

				case ActivityType.eventstarting:
				case ActivityType.eventadmin:
					history.push(
						`${memberRoutes?.member.event.getPath()}/${(item.data as ActivityEventStartingData)?.event?.eventId}`
					);
					break;

				case ActivityType.eventupdate:
					history.push(
						`${memberRoutes?.member.event.getPath()}/${(item.data as ActivityEventUpdateData)?.event?.eventId}`
					);
					break;

				case ActivityType.groupevent:
					history.push(`${memberRoutes?.member.event.getPath()}/${(item.data as ActivityGroupEventData)?.eventId}`);
					break;

				case ActivityType.discover:
					history.push(memberRoutes?.member.events.getPath());
					break;

				case ActivityType.memberreq:
					history.push(`${memberRoutes?.member.group.getPath()}/${(item.data as ActivityMemberReqData)?.group?.slug}`);
					break;

				case ActivityType.reqapproved:
					history.push(
						`${memberRoutes?.member.group.getPath()}/${(item.data as ActivityGroupInviteAcceptedData)?.group?.slug}`
					);
					break;

				case ActivityType.groupinvite:
					history.push(
						`${memberRoutes?.member.group.getPath()}/${(item.data as ActivityGroupInviteData)?.group?.slug}`
					);
					break;

				case ActivityType.inviteaccepted:
				case ActivityType.reqdeclined:
					history.push(
						`${memberRoutes?.member.group.getPath()}/${(item.data as ActivityGroupInviteAcceptedData)?.group?.slug}`
					);
					break;

				case ActivityType.latestpostingroup:
					history.push(
						`${memberRoutes?.member.group.getPath()}/${(item.data as ActivityLatestPostingGroupData)?.group?.slug}`
					);
					break;

				case ActivityType.newgrouppost:
				case ActivityType.loyaltyCoins:
				case ActivityType.newepisode:
				case ActivityType.taggedStory:
				case ActivityType.replymention:
				case ActivityType.postmention:
				case ActivityType.payreqrec:
				case ActivityType.iwasthere:
				case ActivityType.welcome:
					break;
			}

			onClose && onClose();
		},
		[markRead, history, memberRoutes, onClose]
	);

	const RenderItem = useCallback(
		(item: ActivityModel) => (
			<Item.Wrapper className={!item.read && "unread"} onClick={() => handleItemClick(item)}>
				<Item.IconWrapper className={item.read ? "" : "unread"} iconColor={primaryColor}>
					{getIcon(item)}
				</Item.IconWrapper>
				<Item.InfoBlock>
					<Item.Text>{getTitle(item)}</Item.Text>
					<Item.TimestampWrapper>
						<Item.PostDate
							date={item.createdAt}
							live={false}
							formatter={(value, unit, suffix) =>
								value < 60 && unit === "second" ? "Just now" : `${value} ${unit} ${suffix}`
							}
						/>
					</Item.TimestampWrapper>
				</Item.InfoBlock>
				<Item.ManageBlock>
					<MenuDots options={getOptions(item)} rounded size={{ small: true }} />
				</Item.ManageBlock>
			</Item.Wrapper>
		),
		[getIcon, getTitle, getOptions, primaryColor, handleItemClick]
	);

	const loadMore = useCallback(() => {
		if (allowToLoadMore && !loading) {
			setPage(page => page + 1);
		}
	}, [setPage, allowToLoadMore, loading]);

	const handleClose = () => {
		onClose && onClose();
	};

	return (
		<Wrapper>
			<List
				customTitle={<Header title="Notifications" />}
				items={activities}
				preview={preview}
				seeAllLink={notificationPageUrl}
				renderItem={RenderItem}
				loading={loading}
				pageSize={pageSize}
				previewItemCount={7}
				totalCount={preview ? activities?.length : undefined}
				fullBodySize
				onLoadMore={loadMore}
				bodyWrapClassname={`notifications-preview-body-wrapper${preview ? "_preview" : ""}`}
				noContentBlock={<EmptyNotifications />}
				skeletonSizeSmall
				onSeeAllClick={handleClose}
			/>
		</Wrapper>
	);
});

export default NotificationsCard;
