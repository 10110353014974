import React, { useCallback, useMemo } from "react";

import { Controller, useForm } from "react-hook-form";

import { ReactComponent as FacebookIcon } from "assets/icons/facebook-rounded.svg";
import { ReactComponent as InstagramIcon } from "assets/icons/instagram-rounded.svg";
import { ReactComponent as LinkedInIcon } from "assets/icons/linkedin-rounded.svg";
import { ReactComponent as TiktokIcon } from "assets/icons/tiktok-rounded.svg";
import { ReactComponent as TwitterIcon } from "assets/icons/twitter-rounded.svg";

import { useNotification, usePersona, useUser } from "shared/hooks";

import { validateURL } from "utils/serviceUtils/validators";

import { InputWrapper, SocialAccountsWrapper } from "./style";

import { MemberDialog } from "../../shared";
import LabeledInput from "../../shared/LabeledInput";

const AddSocialAccountsDialog = () => {
	const { setAddSocialAccountsDialog, getData: getUserData } = useUser();
	const { addSocialAccountsDialog } = getUserData();

	const { updatePersona, getData: getPersonaData } = usePersona();
	const { persona: profile } = getPersonaData();

	const {
		control,
		handleSubmit,
		formState: { isSubmitting, isDirty, errors }
	} = useForm({ mode: "onChange" });

	const { showMessage } = useNotification();

	const turnToUrl = useCallback((val?: string) => {
		if (!val) return "";
		return val.startsWith("http") ? val : `https://${val}`;
	}, []);

	const onSubmit = useCallback(
		async data => {
			if (!profile) return;

			try {
				await updatePersona(
					{
						...profile,
						linkedAccount: {
							facebook: turnToUrl(data.facebook),
							linkedin: turnToUrl(data.linkedin),
							instagram: turnToUrl(data.instagram),
							twitter: turnToUrl(data.twitter),
							tiktok: turnToUrl(data.tiktok)
						}
					},
					true
				);

				setAddSocialAccountsDialog(false);

				showMessage("Successfully updated your social media accounts!");
			} catch (error) {
				console.log(error);
			}
		},
		[profile, setAddSocialAccountsDialog, showMessage, updatePersona, turnToUrl]
	);

	const INPUT_RULES = useMemo(
		() => ({
			required: false,
			validate: value => {
				if (!value) return;
				if (!validateURL(value)) return "Incorrect URL";
				return undefined;
			}
		}),
		[]
	);

	if (!profile) return null;

	return (
		<MemberDialog
			title="Linked Accounts"
			open={addSocialAccountsDialog}
			onClose={() => setAddSocialAccountsDialog(false)}
			confirmLeave={isDirty}
			footerPrimary={{
				text: "Done",
				loading: isSubmitting,
				disabled: isSubmitting || !isDirty,
				onClick: handleSubmit(onSubmit)
			}}
		>
			<form onSubmit={handleSubmit(onSubmit)}>
				<SocialAccountsWrapper>
					<Controller
						name="facebook"
						control={control}
						defaultValue={profile.linkedAccount?.facebook}
						rules={INPUT_RULES}
						render={({ onChange, value, ref }) => (
							<InputWrapper>
								<LabeledInput
									label="Facebook"
									name="facebook"
									value={value}
									onChange={onChange}
									rightIcon={{
										el: <FacebookIcon />,
										active: false
									}}
									error={errors?.facebook?.message}
									inputRef={ref}
								/>
							</InputWrapper>
						)}
					/>
					<Controller
						name="linkedin"
						control={control}
						defaultValue={profile.linkedAccount?.linkedin}
						rules={INPUT_RULES}
						render={({ onChange, value, ref }) => (
							<InputWrapper>
								<LabeledInput
									label="Linkedin"
									name="linkedin"
									value={value}
									onChange={onChange}
									rightIcon={{
										el: <LinkedInIcon />,
										active: false
									}}
									error={errors?.linkedin?.message}
									inputRef={ref}
								/>
							</InputWrapper>
						)}
					/>
					<Controller
						name="instagram"
						control={control}
						defaultValue={profile.linkedAccount?.instagram}
						rules={INPUT_RULES}
						render={({ onChange, value, ref }) => (
							<InputWrapper>
								<LabeledInput
									label="Instagram"
									name="instagram"
									value={value}
									onChange={onChange}
									rightIcon={{
										el: <InstagramIcon />,
										active: false
									}}
									error={errors?.instagram?.message}
									inputRef={ref}
								/>
							</InputWrapper>
						)}
					/>
					<Controller
						name="twitter"
						control={control}
						defaultValue={profile.linkedAccount?.twitter}
						rules={INPUT_RULES}
						render={({ onChange, value, ref }) => (
							<InputWrapper>
								<LabeledInput
									label="Twitter"
									name="twitter"
									value={value}
									onChange={onChange}
									rightIcon={{
										el: <TwitterIcon />,
										active: false
									}}
									error={errors?.twitter?.message}
									inputRef={ref}
								/>
							</InputWrapper>
						)}
					/>
					<Controller
						name="tiktok"
						control={control}
						defaultValue={profile.linkedAccount?.tiktok}
						rules={INPUT_RULES}
						render={({ onChange, value, ref }) => (
							<InputWrapper>
								<LabeledInput
									label="Tiktok"
									name="tiktok"
									value={value}
									onChange={onChange}
									rightIcon={{
										el: <TiktokIcon />,
										active: false
									}}
									error={errors?.tiktok?.message}
									inputRef={ref}
								/>
							</InputWrapper>
						)}
					/>
				</SocialAccountsWrapper>
			</form>
		</MemberDialog>
	);
};

export default AddSocialAccountsDialog;
