import React, { useEffect, useMemo } from "react";

import { emotionIcons } from "shared/constants";

import { ReactionIconWrapper } from "./style";

export interface ReactionType {
	id: string | null;
	reaction: string;
	right: number;
}

interface ReactionProps extends ReactionType {
	onComplete: (id: string | null) => void;
}

const Reaction: React.FC<ReactionProps> = ({ id, reaction, onComplete }) => {
	useEffect(() => {
		setTimeout(onComplete, 1450, id);
	}, [onComplete, id]);

	const emotion = useMemo(() => emotionIcons.find(({ value }) => value === reaction), [reaction]);

	return emotion ? <ReactionIconWrapper>{emotion.icon}</ReactionIconWrapper> : null;
};

export default Reaction;
