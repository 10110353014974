import { AxiosResponse } from "axios";
import * as R from "ramda";

import BaseConverter from "shared/services/converters/baseConverter";
import { MediaModel, TrackMeta } from "shared/types";
import { generateUid } from "utils/UidGenerator";

import { TrackFormModel, TrackModel, TrackModelResponse, TrackRequest, UpdateTrackFormModel } from "../../types";

export const convertTracks = (data: AxiosResponse<TrackModelResponse>): TrackModel[] => {
	return BaseConverter.convert<TrackModelResponse>(data).musics;
};

export const convertTrack = (
	data: TrackFormModel,
	trackFileMedia: MediaModel,
	trackImageMedia: MediaModel | null
): TrackRequest => {
	return {
		...R.pick(["title", "description", "personaId", "priceTags", "groupId", "eventId"], data),
		guid: generateUid(),
		genre: data.genre.map(g => g.key),
		type: "track",
		duration: data.duration,
		meta: {
			track: {
				mediaId: trackFileMedia._id,
				url: trackFileMedia.uri
			},
			artist: data.artist,
			private: data.private,
			canBeDownloaded: !!data.downloadable,
			previewStart: data.preview.start,
			previewStop: data.preview.end,
			artwork: trackImageMedia
				? {
						mediaId: trackImageMedia._id,
						url: trackImageMedia.uri
				  }
				: undefined
		}
	};
};

export const convertUpdateTrack = (
	data: UpdateTrackFormModel,
	trackFileMedia?: MediaModel,
	trackImageMedia?: MediaModel
): TrackRequest => {
	return {
		...R.pick(["title", "description", "personaId", "_id", "guid", "priceTags", "groupId", "duration"], data),
		genre: data.genre.map(g => g.key),
		type: "track",
		meta: {
			track: trackFileMedia
				? {
						mediaId: trackFileMedia._id,
						url: trackFileMedia.uri
				  }
				: (data.meta as TrackMeta).track,
			artist: data.artist,
			artwork: trackImageMedia
				? {
						mediaId: trackImageMedia._id,
						url: trackImageMedia.uri
				  }
				: data.trackImageUrl === null
				? undefined
				: (data.meta as TrackMeta).artwork,
			private: data.private,
			canBeDownloaded: !!data.downloadable,
			previewStart: data.preview.start,
			previewStop: data.preview.end
		}
	};
};
