import { Box, ButtonBase } from "@material-ui/core";
import { FixedSizeList as List } from "react-window";
import styled from "styled-components";

import { Text } from "shared/ui-kit";

export const SelectOptionsWrapper = styled(Box)`
	display: ${({ show }) => (show ? "block" : "none")};
	border-radius: 12px;
	padding: 8px;
	box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12), inset 0 0 0 0.5px #c5cee0;
	background-color: #fff;
	max-height: max-content;
	position: absolute;
	top: calc(100% + 4px);
	left: 0;
	z-index: 10;
	width: 100%;
	min-width: ${({ minWidth }) => (minWidth ? `${minWidth}px` : "0")};

	::-webkit-scrollbar {
		width: 0;
	}
`;

SelectOptionsWrapper.Headline = styled(Text)`
	font-size: 14px;
	padding: 8px;
	color: #afafaf;
`;

SelectOptionsWrapper.Option = styled(ButtonBase)`
	width: 100%;
	height: 40px;
	padding: 8px;
	border-radius: 8px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	text-align: left;
	svg {
		width: 16px;
		height: 16px;
		margin-right: 6px;
	}
	img {
		width: 24px;
		height: 16px;
		object-fit: cover;
		margin-right: 8px;
	}

	&:hover {
		background-color: #edf1f7;
	}

	&.narrow {
		height: 32px;
		font-size: 13px;
	}
`;

SelectOptionsWrapper.SelectOption = styled.div`
	width: auto;
	height: 40px;
	border-radius: 8px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	text-align: left;
	img {
		width: 24px;
		height: 16px;
		object-fit: cover;
		margin-right: 8px;
	}
`;

SelectOptionsWrapper.WithFlag = styled(Box)`
	width: 16px;
	height: 16px;
	margin-right: 6px;
	svg {
		width: 16px;
		height: 16px;
		margin-right: 6px;
	}
`;

export const OptionList = styled(List)`
	overflow: hidden !important;
`;
