import React, { memo, useEffect, useState } from "react";

import { PostBlockEventType } from "types";

import { useGroup } from "shared/hooks";
import { GroupModel, PlaceholderImageType } from "shared/types";

import { Icon } from "shared/ui-kit";

import ItemTemplate from "./ItemTemplate";

import { PlaceholderImage } from "../index";

interface Props {
	group?: GroupModel;
	groupSlug?: string;
	id?: string;
	onEvent?: (eventType: PostBlockEventType, id: string) => void;
	maxWidth?: boolean;
	postView?: boolean;
	messageBlockCurrentUser?: boolean;
	link?: string;
	isMarketing?: boolean;
}

const GroupBlock: React.FC<Props> = memo(
	({ group, groupSlug, id, onEvent, postView, messageBlockCurrentUser, link, isMarketing }) => {
		const [groupInfo, setGroupInfo] = useState<GroupModel>();
		const [loading, setLoading] = useState<boolean>(false);
		const [isGroupDeleted, setIsGroupDeleted] = useState<boolean>(false);
		const { getGroupBySlug } = useGroup(isMarketing);

		useEffect(() => {
			if (group) {
				setGroupInfo(group);
			}
			if (groupSlug && !groupInfo) {
				(async () => {
					setLoading(true);
					const data = await getGroupBySlug(groupSlug);
					if (data?.group) {
						setGroupInfo(data.group);
					} else {
						setIsGroupDeleted(true);
					}
					setLoading(false);
				})();
			}
		}, [groupSlug, getGroupBySlug, groupInfo, group]);

		return (
			<ItemTemplate
				link={link}
				featureName={"enableGroups"}
				id={id}
				wrapperProps={{
					id,
					onEvent,
					postView,
					messageBlockCurrentUser,
					"data-name": "group",
					"data-group": encodeURIComponent(
						JSON.stringify({ ...groupInfo, slug: groupInfo?.slug, thumbnail: groupInfo?.coverUrl })
					)
				}}
				loading={loading}
				indicatorIcon={<Icon name="user-friend" group="filled" />}
				onClose={() => onEvent && id && onEvent(PostBlockEventType.delete, id)}
				coverUrl={groupInfo?.coverUrl}
				pretitle={groupInfo?.private === 1 ? "Private Group" : "Public Group"}
				title={groupInfo?.name}
				memberList={groupInfo?.members}
				totalMembers={groupInfo?.totalMembers}
				placeholderImg={
					<PlaceholderImage type={PlaceholderImageType.GROUP_DETAILS} width={80} height={80} viewBox={"0 0 400 400"} />
				}
				isDeleted={isGroupDeleted}
			/>
		);
	}
);

export default GroupBlock;
