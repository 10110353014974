import React, { FC, useState } from "react";

import { ClickAwayListener } from "@material-ui/core";

import { MenuDotsOption } from "shared/Components";
import { Icon } from "shared/ui-kit";

import { Menu, Wrapper } from "./style";

import { MoreActionsButton } from "../AlbumDetails/style";

const MoreActionsMenu: FC<{
	options: MenuDotsOption[];
	onClose?: () => void;
}> = ({ options, onClose }) => {
	const [show, setShow] = useState(false);

	return (
		<ClickAwayListener
			onClickAway={() => {
				setShow(false);
				setTimeout(() => {
					onClose && onClose();
				}, 300);
			}}
		>
			<Wrapper>
				<MoreActionsButton buttonTheme="outline" size="medium" onClick={() => setShow(!show)}>
					<Icon name="ellipsis" group="filled" />
				</MoreActionsButton>
				<Menu show={show}>
					{options.map((opt, i) => (
						<Menu.Item key={i} onClick={opt.onClick}>
							{opt.icon} {opt.name}
						</Menu.Item>
					))}
				</Menu>
			</Wrapper>
		</ClickAwayListener>
	);
};

export default MoreActionsMenu;
