import React, { FC } from "react";

import { Box } from "@material-ui/core";

import { ManInLotus, MessageText, NoConnectionsBlock } from "modules/MemberHome/View/Containers/Connections/style";

import { useCommunity } from "shared/hooks";
import { EventModel, VolunteerSlot } from "shared/types";
import { Button, Icon, Text } from "shared/ui-kit";

import ModelBlock, { ModelBlockType } from "../../../ModelBlock";

const VolunteersTemplate: FC<{
	items: VolunteerSlot[];
	type: ModelBlockType;
	actionButton?: {
		title: string;
		onClick: () => void;
	};
	noItemsErrorText: string;
	onEndScroll?: () => void;
	autoFit?: boolean | number;
	loading?: boolean;
	event?: EventModel | null;
	isEventEnded: boolean;
}> = ({ items, type, actionButton, noItemsErrorText, onEndScroll, autoFit, loading, event, isEventEnded }) => {
	const { communityColors } = useCommunity();

	return (
		<Box className="w-full">
			<Box className="flex justify-between items-center">
				<Text variant="h5">Volunteers</Text>
				{actionButton && (
					<Button palette="light" buttonTheme="light" onClick={actionButton.onClick} disabled={isEventEnded}>
						<Icon name="plus" group="filled" width={16} height={16} className="mr-1" fill={communityColors.primary} />
						{actionButton.title}
					</Button>
				)}
			</Box>
			<ModelBlock
				loading={loading}
				autoFit={autoFit}
				type={type}
				items={items}
				isMasonry
				onEndScroll={onEndScroll}
				keepCurrentData
				noContent={
					<NoConnectionsBlock>
						<ManInLotus />
						<MessageText>{noItemsErrorText}</MessageText>
						{actionButton && (
							<Button
								palette="light"
								buttonTheme="light"
								className="mt-2"
								onClick={actionButton.onClick}
								disabled={isEventEnded}
							>
								<Icon
									name="plus"
									group="filled"
									width={16}
									height={16}
									className="mr-1"
									fill={communityColors.primary}
								/>
								{actionButton.title}
							</Button>
						)}
					</NoConnectionsBlock>
				}
				customProp={{ event, isEventEnded }}
			/>
		</Box>
	);
};

export default VolunteersTemplate;
