import React from "react";

import { Box } from "@material-ui/core";

import Album1Cover from "assets/images/a1.jpeg";
import Album2Cover from "assets/images/a2.jpeg";
import Album3Cover from "assets/images/a3.jpeg";
import Album4Cover from "assets/images/a4.jpeg";
import Album5Cover from "assets/images/a5.jpeg";
import Album6Cover from "assets/images/a6.jpeg";

import { useAlbums } from "shared/hooks";
import { Button, InPlaceModal, Text } from "shared/ui-kit";

import { AlbumCovers, DownloadCompletedText, ModalOverlay } from "./style";

const TracksDownloaded = () => {
	const { setTrackDownloaded, setTrackProgressDialog, getData: getAlbumsData } = useAlbums();
	const { trackDownloaded } = getAlbumsData();

	return (
		<ModalOverlay>
			<InPlaceModal open={trackDownloaded} onClose={() => setTrackDownloaded(false)} maxWidth="md" modalHeight={500}>
				<Box display="flex" justifyContent="space-between">
					<DownloadCompletedText>
						<Text variant="h5">Your download has been successful!</Text>
						<Text variant="body1" className="mb">
							<b>Step 1:</b> Unzip the file.{" "}
							<a href="/trackshelp#how-to-unzip" target="_blank" rel="noopener noreferrer">
								Learn more
							</a>
						</Text>
						<Text variant="body1">
							<b>Step 2:</b> Add music to your favorite media players.{" "}
							<a href="/trackshelp#how-to-add-media" target="_blank" rel="noopener noreferrer">
								Learn more
							</a>
						</Text>
						<Button
							buttonTheme="ghost"
							onClick={() => {
								setTrackDownloaded(false);
								setTrackProgressDialog([]);
							}}
						>
							Got it!
						</Button>
					</DownloadCompletedText>
					<AlbumCovers>
						<Box display="flex" marginBottom="16px">
							<img src={Album3Cover} alt="album cover" />
							<img src={Album4Cover} alt="album cover" />
						</Box>
						<Box display="flex" marginBottom="16px">
							<img src={Album1Cover} alt="album cover" />
							<img src={Album2Cover} alt="album cover" />
						</Box>
						<Box display="flex" marginBottom="16px">
							<img src={Album5Cover} alt="album cover" />
							<img src={Album6Cover} alt="album cover" />
						</Box>
					</AlbumCovers>
				</Box>
			</InPlaceModal>
		</ModalOverlay>
	);
};

export default TracksDownloaded;
