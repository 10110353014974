import React from "react";

import { useLocation } from "react-router";

import { routes as authRoutes } from "apps/Auth/constants";
import { routes as adminRoutes } from "apps/RegularUser/constants";
import { view } from "modules/App";
import { useMemberRoutes } from "shared/hooks";

const CommunitiesPage = () => {
	const { getMemberRoutesData } = useMemberRoutes();
	const { routes: memberRoutes } = getMemberRoutesData();

	const { search } = useLocation();
	const query = new URLSearchParams(search);

	const community = query.get("community") || undefined;

	return (
		<view.Communities
			homeUrl={adminRoutes.dashboard.getPath() || memberRoutes?.member.home.getPath()}
			signInUrl={authRoutes.auth.signIn.getPath()}
			joinCommunitiesUrl={adminRoutes.communities.getPath()}
			invitationCodePage={authRoutes.auth.invitationCode.getPath()}
			community={community}
		/>
	);
};

export default CommunitiesPage;
