import { Box, ButtonBase } from "@material-ui/core";
import styled, { css } from "styled-components";

import { Button } from "shared/ui-kit";

export const TrackWrapper = styled(Box)`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 8px 16px;
	margin-top: 8px;
	border-radius: 8px;
	min-width: 700px;
	&:hover {
		background-color: #f7f9fc;
	}

	${({ isPlaying }) =>
		isPlaying &&
		css`
			background-color: rgb(235 236 255 / 50%);
			color: #6173fe;
		`}
`;

TrackWrapper.TrackDetails = styled(Box)`
	display: flex;
	align-items: center;
	text-align: left;
	width: 40%;
	h6 {
		font-weight: 600;
		line-height: 1.5;
	}
	.artistName {
		color: #8f9bb3;
		&.playing {
			color: #6173fe;
		}
	}

	.track-no-img {
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: #edf1f7;

		svg {
			fill: #c5cee0;
		}

		&.skeleton {
			background-color: transparent;
		}
	}

	img,
	.track-no-img {
		flex-shrink: 0;
		margin: 0 16px;
		width: 40px;
		height: 40px;
		border-radius: 8px;
		object-fit: cover;
	}
`;

TrackWrapper.TrackActions = styled(Box)`
	display: flex;
	align-items: center;
`;

export const OpenDocumentButton = styled.a`
	height: 32px;
	padding: 8px 16px;
	border-radius: 8px;
	background-color: ${({ btnColor }) => btnColor};
	text-decoration: none;
	font-size: 14px;
	font-weight: bold;
	line-height: 1.14;
	color: #fff;
`;

export const PlayTrack = styled(ButtonBase)`
	width: ${({ large, medium }) => (large ? "48px" : medium ? "40px" : "28px")};
	height: ${({ large, medium }) => (large ? "48px" : medium ? "40px" : "28px")};
	margin: 0;
	padding: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 99%;
	background-color: ${({ btnColor }) => btnColor};
	.pause {
		width: 18px;
		height: 18px;
		fill: #fff;
	}
	${({ noMr }) =>
		!noMr &&
		css`
			margin-right: 8px;
		`}
	&:disabled {
		background-color: #c5cee0;
	}
`;

export const EmptyBtnSpace = styled(Box)`
	width: 132px;

	margin: 0 8px;
`;

export const BuyTrackButton = styled(Button)`
	padding: 8px 0;
	width: 132px;
	border-radius: 8px;
	margin: 0 8px;
	display: flex;
	align-items: center;
	font-size: 14px;
	line-height: 1.14;
	min-height: 40px;
	font-weight: 600;

	.MuiButton-label {
		display: flex;
		align-items: center;
		svg {
			width: 20px;
			height: 20px;
			margin-left: 4px;
		}
	}
`;
