import { ParsableDate } from "@material-ui/pickers/constants/prop-types";
import { DateTime } from "luxon";

import { PriceTag } from "./PriceTag";

export interface PostModel {
	content: string;
	teaserContent: string;
	category: string;
	postType: "premium" | "regular";
	startDate: Date;
	isEarly?: boolean;
	priceTag?: string;
	publicStartDate?: Date;
}

export interface PostModelRequest {
	payload: Pick<
		PostFormModel,
		"content" | "teaserContent" | "postType" | "isEarly" | "priceTagId" | "startDate" | "cid"
	> & {
		title: string;
		tags: string[];
		publicStartDate: DateTime;
	};
	path: string;
	method: string;
	token: string | null;
	boardIds?: string[];
	rootPostIds?: string[];
	type?: string;
}

export enum PostType {
	REGULAR = "REGULAR",
	PREMIUM = "PREMIUM"
}

export interface PostFormModel {
	content: string;
	cid: string | null;
	title: string;
	teaserContent: string;
	postType: PostType;
	isEarly: boolean;
	priceTagId: string;
	startDate: DateTime;
	publicStartDate: ParsableDate;
	publicStartTime: ParsableDate;
	publicStartTimeZone: string;
	tags?: string[];
	sentNotification: boolean;
	priceTags?: PriceTag[];
	boardIds?: string[];
	rootPostIds?: string[];
}

export interface PostUpdateFormModel extends PostFormModel {
	pid: string;
}
