import { Box } from "@material-ui/core";
import styled from "styled-components";

export const Wrapper = styled(Box)`
	width: 100%;
`;

export const Header = styled(Box)``;

Header.Wrapper = styled(Box)`
	display: flex;
`;

export const Row = styled(Box)``;

Row.Wrapper = styled(Box)`
	width: 100%;

	display: flex;
`;

export const Column = styled(Box)``;

Column.Wrapper = styled(Box)`
	display: flex;
	align-items: center;

	height: 100%;

	padding: 16px;

	border-bottom: 1px solid #f1f7ed;

	&.alignment {
		&_center {
			justify-content: center;
		}

		&_right {
			justify-content: flex-end;
		}
	}

	&:first-child {
		padding-left: 24px;
	}

	&:last-child {
		padding-right: 24px;
	}
`;
