import React, { FC, memo } from "react";

import { Table } from "shared/ui-kit";

import TableProps from "./TableProps";
import { TableWrapper } from "./style";

const TableComponent: FC<TableProps> = memo(
	({
		columns,
		data,
		width,
		height,
		size = "small",
		customStyle = { padding: 0 },
		multigrid = true,
		onCheckAll = () => {},
		onCheckItem = () => {},
		onLastRowScrolled = () => {},
		onReorder = () => {},
		onToggleCollapsible = () => {},
		rowHeight,
		expandable = false,
		...rest
	}) => (
		<TableWrapper>
			<Table
				width={width}
				size={size}
				columns={columns}
				customStyle={customStyle}
				data={data}
				height={height}
				multigrid={multigrid}
				expandable={expandable}
				onCheckAll={onCheckAll}
				onCheckItem={onCheckItem}
				onLastRowScrolled={onLastRowScrolled}
				onReorder={onReorder}
				onToggleCollapsible={onToggleCollapsible}
				rowHeight={rowHeight}
				{...rest}
			/>
		</TableWrapper>
	)
);

export default TableComponent;
