import { useCallback, useMemo } from "react";

import { useNotification } from "shared/hooks";

import { usePodcastsService } from "shared/services";

import { usePodcastsStore } from "../contexts";

const usePodcasts = () => {
	const podcastsApiService = usePodcastsService();
	const { showMessage } = useNotification();

	const { setState, ...store } = usePodcastsStore();

	const methods = useMemo(
		() => ({
			getPodcasts: async ({
				offset,
				limit,
				keyword,
				showId,
				sortBy,
				order,
				noStateUpdate
			}: {
				offset: number;
				limit: number;
				keyword?: string;
				showId?: string;
				sortBy?: "title" | "updatedAt";
				order?: 1 | -1;
				noStateUpdate?: boolean;
			}) => {
				setState({ isLoading: true });
				try {
					const { shows } = await podcastsApiService.getPodcasts({ offset, limit, keyword, showId, sortBy, order });
					if (noStateUpdate) {
						return shows;
					} else {
						setState({ podcasts: shows });
					}
				} catch (err) {
					console.log(err);
					showMessage("Couldn't fetch podcast, please try again.");
				} finally {
					setState({ isLoading: false });
				}
			},
			getEpisodes: async ({ showId, offset, limit }: { showId: string; offset: number; limit?: number }) => {
				return podcastsApiService.getEpisodes({ showId, offset, limit });
			},
			followPodcast: async ({ showId, isFollowing }: { showId: string; isFollowing: boolean }) => {
				return podcastsApiService.followPodcast({ showId, isFollowing });
			}
		}),
		[podcastsApiService, setState, showMessage]
	);

	const getPodcastsData = useCallback(() => {
		return store;
	}, [store]);

	return { ...methods, getPodcastsData };
};

export default usePodcasts;
