import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";

import { DateTime } from "luxon";
import { Controller, useForm, useWatch } from "react-hook-form";

import {
	EnableFeature,
	LabeledInput,
	MemberDialog,
	RectangleUploader,
	SelectInput
} from "modules/MemberHome/View/shared";
import { InputWrapper, MemberDialogContentWrapper } from "modules/MemberHome/View/shared/style";

import { SetupPrice, SmartInput, SmartInputWrapper } from "shared/Components";
import { useConnection, useEvent, useUser } from "shared/hooks";
import { useS3Uploader } from "shared/services/s3Uploader";
import { EventModel, ProfileType } from "shared/types";

import { EventFormData } from "shared/types/EventModel";
import { Icon } from "shared/ui-kit";
import { generateFakeLiveEvent } from "utils/liveconversation/fakeEvent";
import { dataUrlToFile } from "utils/serviceUtils/helpers";

import ConnectionAutoComplete from "./ConnectionAutoComplete";

import { FieldTitle, OptionsHeadline } from "./style";

const defaultDateTime = DateTime.now().plus({ days: 1 }).toJSDate();

const AdvancedSettingsForm: FC<{
	open: boolean;
	onClose: () => void;
	persona: ProfileType;
	onEventCreated: (event: EventModel) => void;
}> = ({ open, onClose, persona, onEventCreated }) => {
	const {
		control,
		handleSubmit,
		formState: { isDirty, errors }
	} = useForm({ mode: "onChange" });

	const { uploadFile, getData: getS3UploaderData } = useS3Uploader();
	const { uploading } = getS3UploaderData();

	const { loadCategories, createEvent, getData: getEventData } = useEvent();
	const { eventImageUploadUrl, categories } = getEventData();

	const { getData: getUserData } = useUser();
	const { user } = getUserData();

	const { getUsersConnections } = useConnection();

	const { scheduled } = useWatch({ control, name: ["scheduled"] });
	const [hasConnections, setHasConnections] = useState(false);

	type SetupPriceMethodsHandlers = React.ElementRef<typeof SetupPrice>;
	const setupPriceFormRef = useRef<SetupPriceMethodsHandlers>(null);

	const showPremiumAccess = useMemo(
		() => user?.isGlobalAdmin || user?.isGlobalOwner || user?.isGlobalModerator,
		[user]
	);

	useEffect(() => {
		loadCategories();
	}, [loadCategories]);

	useEffect(() => {
		getUsersConnections({ limit: 1, offset: 1 }).then(data => {
			if (data.connections.length) {
				setHasConnections(true);
			}
		});
	}, [getUsersConnections]);

	const onUploaderChange = useCallback(
		async (files: FileList | any[], onChange: (...event: any[]) => void) => {
			const img = files?.length ? files[0] : null;
			const correctFile = typeof img === "string" ? await dataUrlToFile(img, "test") : img;
			if (img) {
				const data = await uploadFile({
					file: correctFile as File,
					communityName: eventImageUploadUrl
				});
				onChange(data?.publicUrl);
			} else {
				onChange(null);
			}
		},
		[uploadFile, eventImageUploadUrl]
	);

	const categoryOptions = useMemo(
		() =>
			categories.map(({ sort_name, name }) => ({
				label: name,
				value: sort_name
			})),
		[categories]
	);

	const onSubmit = async data => {
		const priceTags = setupPriceFormRef.current?.getPriceTags();
		const eventData: EventFormData = {
			...generateFakeLiveEvent(persona),
			description: data.description,
			categoryId: data.category?.value,
			title: data.title,
			eventImage: data.eventImage || "",
			priceTags: priceTags || [],
			admins: data.admins?.length ? data.admins.map(x => x.personaId) : []
		};
		if (data.scheduled) {
			let startDateLuxon = DateTime.fromJSDate(data.date);
			const startTimeLuxon = DateTime.fromJSDate(data.time);
			startDateLuxon = startDateLuxon.set({ hour: startTimeLuxon.hour, minute: startTimeLuxon.minute });

			eventData.startTime = startDateLuxon.toJSDate();
			eventData.endTime = startDateLuxon.plus({ hour: 2 }).toJSDate();
		}

		const event = await createEvent(eventData);

		onEventCreated(event!);
	};

	return (
		<MemberDialog
			customWidth={512}
			title="Live Settings"
			open={open}
			onClose={onClose}
			confirmLeave={isDirty}
			modalHeight={680}
			footerPrimary={{
				text: scheduled ? "Schedule Conversation" : "Start Conversation",
				disabled: !isDirty || !!Object.keys(errors).length,
				onClick: handleSubmit(onSubmit)
			}}
		>
			<form onSubmit={handleSubmit(onSubmit)}>
				<Controller
					name="eventImage"
					control={control}
					render={({ value, onChange }) => (
						<RectangleUploader
							defaultPreviewUrl={value}
							uploading={uploading}
							onChange={file => onUploaderChange(file, onChange)}
						/>
					)}
				/>
				<MemberDialogContentWrapper className="pv-20">
					<Controller
						name="title"
						control={control}
						rules={{
							required: "Live title is required",
							minLength: { value: 3, message: "At least 3 characters are required" },
							maxLength: {
								value: 40,
								message: "Live title should not exceed 40 characters."
							}
						}}
						render={({ onChange, value, ref }) => (
							<InputWrapper>
								<LabeledInput
									label="Live title *"
									name="title"
									value={value}
									onChange={onChange}
									placeholder=" "
									error={errors?.name?.message}
									showCounter={40}
									inputRef={ref}
								/>
							</InputWrapper>
						)}
					/>
					{hasConnections && (
						<Controller
							name="admins"
							defaultValue={[]}
							control={control}
							render={({ onChange, value }) => (
								<InputWrapper>
									<FieldTitle variant="label">Co-hosts</FieldTitle>
									<ConnectionAutoComplete onChange={onChange} value={value} />
								</InputWrapper>
							)}
						/>
					)}
					<Controller
						name="category"
						control={control}
						rules={{
							required: "Event category is required"
						}}
						render={({ onChange, value, ref }) => (
							<InputWrapper>
								<SelectInput
									label="Event Category *"
									name="category"
									value={value && value.label}
									onChange={onChange}
									placeholder=" "
									options={categoryOptions}
									maxHeight={180}
									displayOnly
									error={errors?.category?.message}
									selectInputRef={ref}
								/>
							</InputWrapper>
						)}
					/>

					<Controller
						name="description"
						control={control}
						rules={{
							maxLength: {
								value: 1000,
								message: "Description should not exceed 1000 characters."
							}
						}}
						render={({ onChange, value, ref }) => (
							<InputWrapper>
								<LabeledInput
									label="Description"
									name="description"
									value={value}
									onChange={onChange}
									placeholder=" "
									error={errors?.description?.message}
									showCounter={1000}
									textarea
									inputRef={ref}
								/>
							</InputWrapper>
						)}
					/>
					<OptionsHeadline>Options</OptionsHeadline>
					{showPremiumAccess && (
						<InputWrapper>
							<SetupPrice title="Who can access this Live?" ref={setupPriceFormRef} defaultValue={[]} />
						</InputWrapper>
					)}
					<Controller
						name="scheduled"
						control={control}
						defaultValue={false}
						render={({ onChange, value }) => (
							<InputWrapper>
								<EnableFeature
									title="Schedule Live"
									onChange={onChange}
									value={value}
									icon={<Icon group={""} fill={"#8f9bb3"} name={"clock"} width={24} height={24} />}
								/>
							</InputWrapper>
						)}
					/>
					{scheduled && (
						<div className="grid grid-cols-2 gap-4">
							<SmartInputWrapper>
								<Controller
									name="date"
									control={control}
									defaultValue={defaultDateTime}
									render={({ onChange, value, ref }) => (
										<SmartInput
											id="eventStartDate"
											calendar
											headline={"Date *"}
											disablePast
											value={value}
											onChange={onChange}
											error={!!errors?.date?.message}
											errorText={`${errors?.date?.message}`}
											inputRef={ref}
										/>
									)}
								/>
							</SmartInputWrapper>
							<SmartInputWrapper>
								<Controller
									name="time"
									control={control}
									defaultValue={defaultDateTime}
									render={({ onChange, value, ref }) => (
										<SmartInput
											id="eventStartTime"
											time
											headline={"Time *"}
											disablePast
											value={value}
											onChange={onChange}
											error={!!errors?.time?.message}
											errorText={`${errors?.time?.message}`}
											inputRef={ref}
										/>
									)}
								/>
							</SmartInputWrapper>
						</div>
					)}
				</MemberDialogContentWrapper>
			</form>
		</MemberDialog>
	);
};

export default AdvancedSettingsForm;
