import React, { LegacyRef, useCallback, useEffect, useMemo, useRef } from "react";

import { Grid, Radio, TextField } from "@material-ui/core";

import Autocomplete from "@material-ui/lab/Autocomplete";
import * as R from "ramda";

import { Controller, useForm } from "react-hook-form";

import { ReactComponent as GlobeIcon } from "assets/icons/iconGlobe.svg";
import { ReactComponent as LocationIcon } from "assets/icons/iconLocation.svg";
import { ReactComponent as EyeStrikeIcon } from "assets/icons/stories/icon-eye-strikethrough.svg";
import { LabeledInput, MemberDialog, RadioGroupInput, RectangleUploader } from "modules/MemberHome/View/shared";
import { InputWrapper, MemberDialogContentWrapper } from "modules/MemberHome/View/shared/style";
import { useGroup, useNotification } from "shared/hooks";
import useScrollToXPosition from "shared/hooks/useScrollToXPosition";
import { GroupModel, Location } from "shared/types";
import { Switch, Text } from "shared/ui-kit";

import { filterLocationOptions } from "utils/filterLocationOptions";

import {
	BorderWrapper,
	CustomLocationIcon,
	EnableText,
	FormControl,
	GroupChatWrapper,
	HiddenItem,
	IconWrapper,
	LabelHeadline,
	MessageControlWrapper,
	SectionTitle,
	StyledMessageIcon,
	StyledRadioGroup
} from "./style";

import RadioItem from "../RadioItem";
import SetupPrice, { SetupPriceMethods } from "../SetupPrice";

interface Props {
	onClose: (event: { created?: boolean; group?: GroupModel }, reason: string) => void;
	open: boolean;
	setupPriceFormRef: React.RefObject<SetupPriceMethods>;
	handleCreateGroup;
	groupHidden: boolean;
	setGroupHidden: React.Dispatch<React.SetStateAction<boolean>>;
	onUploaderChange: (files: any[], onChange: (...event: any[]) => void) => {};
	editableModel?: GroupModel;
	defaultParent?: GroupModel;
	isSubgroup?: boolean;
	isMember?: boolean;
	isUploading?: boolean;
}

const CreateGroupMemberView: React.FC<Props> = ({
	onClose,
	open,
	editableModel,
	defaultParent,
	isSubgroup = false,
	onUploaderChange,
	handleCreateGroup,
	groupHidden,
	setGroupHidden,
	setupPriceFormRef,
	isMember,
	isUploading
}) => {
	const formRef = useRef<HTMLFormElement>();
	const scrollToXPositionOnFocus = useScrollToXPosition(formRef?.current?.parentElement, 140);
	const { showMessage } = useNotification();

	const { loadSuggestedLocations, getData: getGroupData } = useGroup();
	const { suggestedLocations, creating } = getGroupData();

	const {
		register,
		handleSubmit,
		control,
		errors,
		reset,
		watch,
		formState: { isValid }
	} = useForm({
		mode: "onChange"
	});

	const {
		description,
		location,
		private: privateVal,
		rules,
		chatEnabled,
		disablePosts
	} = watch(["name", "parentGroup", "description", "location", "private", "rules", "chatEnabled", "disablePosts"]);

	useEffect(() => {
		if (editableModel) {
			reset({
				...R.pick(["name", "description", "rules", "chatEnabled", "location", "coverUrl"], editableModel),
				audience: editableModel.isNational ? "national" : "local",
				hidden: editableModel.hidden ? "permitted" : "anyone",
				private: editableModel.private ? "private" : "public",
				parentGroup: editableModel?.parentId
					? { name: editableModel?.parentGroup?.name, _id: editableModel?.parentGroup?._id }
					: undefined
			});
		} else {
			reset({
				parentGroup: defaultParent ? { name: defaultParent?.name, _id: defaultParent?._id } : undefined
			});
		}
	}, [editableModel, defaultParent, reset]);

	useEffect(() => {
		if (Object.keys(errors).length) {
			showMessage(
				<ul>
					{Object.values(errors).map((p, i) => (
						<li key={i}>{p.message}</li>
					))}
				</ul>,
				3
			);
		}
	}, [errors, showMessage]);

	const correctName = useMemo(() => (isSubgroup ? "Sub-group" : "Group"), [isSubgroup]);

	const onCloseHandler = useCallback(() => {
		setGroupHidden(false);
		onClose({ created: false }, "");
	}, [setGroupHidden, onClose]);

	return (
		<MemberDialog
			open={open}
			footerPrimary={{
				text: `${editableModel ? "Update" : "Create"} ${correctName}`,
				disabled:
					!isValid ||
					!description ||
					!location ||
					!rules ||
					errors.description ||
					errors.rules ||
					creating ||
					isUploading,
				loading: isUploading || creating,
				onClick: handleSubmit(handleCreateGroup)
			}}
			onClose={onCloseHandler}
			confirmLeave={true}
			title={editableModel ? `Update ${correctName} Details` : `Create ${correctName}`}
			customWidth={512}
			modalHeight={680}
		>
			<form noValidate onSubmit={handleSubmit(handleCreateGroup)} ref={formRef as LegacyRef<HTMLFormElement>}>
				<Controller
					name="coverUrl"
					control={control}
					render={({ onChange }) => (
						<RectangleUploader
							onChange={(files: any) => onUploaderChange(files, onChange)}
							errorText={errors?.coverUrl?.message}
							uploading={isUploading}
						/>
					)}
				/>
				<MemberDialogContentWrapper className="pv-20">
					<FormControl>
						<Controller
							name="name"
							control={control}
							rules={{
								required: "Group Name is required.",
								maxLength: {
									value: 100,
									message: "Group Name can't be more than 100 letters"
								}
							}}
							render={({ onChange, value, ref }) => (
								<InputWrapper>
									<LabeledInput
										name="name"
										onChange={onChange}
										value={value}
										placeholder=" "
										label="Group Name *"
										showCounter={100}
										error={errors?.name?.message}
										inputRef={ref}
									/>
								</InputWrapper>
							)}
						/>
					</FormControl>
					<FormControl>
						<LabelHeadline>Choose Location *</LabelHeadline>
						<Controller
							name="location"
							control={control}
							rules={{
								required: `${correctName} Location is required!`
							}}
							render={({ onChange, value }) => (
								<Autocomplete
									getOptionLabel={option => (typeof option === "string" ? option : option.name)}
									filterOptions={filterLocationOptions()}
									options={suggestedLocations}
									autoComplete
									freeSolo
									fullWidth
									includeInputInList
									filterSelectedOptions
									value={value}
									onChange={(event, newValue: Location | null) => onChange(newValue)}
									onInputChange={(group, newInputValue) => loadSuggestedLocations(newInputValue)}
									onFocus={scrollToXPositionOnFocus}
									renderInput={params => (
										<TextField
											{...params}
											variant="outlined"
											fullWidth
											error={errors?.location?.message}
											helperText={errors?.location?.message}
										/>
									)}
									renderOption={option => (
										<Grid container alignItems="center">
											<Grid item>
												<CustomLocationIcon />
											</Grid>
											<Grid item xs>
												{option.name}
												<Text variant="body2" color="textSecondary">
													{option.vicinity}
												</Text>
											</Grid>
										</Grid>
									)}
								/>
							)}
						/>
					</FormControl>
					<FormControl>
						<LabelHeadline>{`${correctName} Description*`}</LabelHeadline>
						<TextField
							inputRef={register({
								required: `${correctName} Description is required!`,
								minLength: { value: 10, message: "At least 10 characters are required" }
							})}
							name="description"
							variant="outlined"
							multiline
							rows={3}
							error={errors?.description?.message}
							helperText={errors?.description?.message}
							onFocus={scrollToXPositionOnFocus}
						/>
					</FormControl>
					<FormControl>
						<LabelHeadline>{`Rules of this ${correctName}*`}</LabelHeadline>
						<TextField
							inputRef={register({
								required: "Rules is required!",
								minLength: { value: 10, message: "At least 10 characters are required" }
							})}
							name="rules"
							variant="outlined"
							multiline
							rows={3}
							error={errors?.rules?.message}
							helperText={errors?.rules?.message}
							onFocus={scrollToXPositionOnFocus}
						/>
					</FormControl>
					{!isMember && (
						<FormControl>
							<SectionTitle variant="h6">Options</SectionTitle>
							<Controller
								name="priceChanged"
								control={control}
								render={({ onChange }) => (
									<SetupPrice
										isItemHighlighted
										ref={setupPriceFormRef}
										hasBorder={false}
										onPriceChanged={() => onChange(true)}
										title={
											<SectionTitle variant="h6" className={"extra-space"}>
												Who can join this Group?
											</SectionTitle>
										}
									/>
								)}
							/>
						</FormControl>
					)}
					<Controller
						name="audience"
						control={control}
						defaultValue={{ key: "local", label: "Local" }}
						render={({ onChange, value }) => (
							<InputWrapper>
								<RadioGroupInput
									onChange={onChange}
									value={value}
									title={"Who is the audience of this group?"}
									options={[
										{
											key: "local",
											label: "Local",
											icon: <LocationIcon />,
											description: "Only members who are local or near the group location will see the group."
										},
										{
											key: "national",
											label: "Regional/National",
											icon: <GlobeIcon />,
											description: "Anyone in the community will be able to see the Group regardless of location."
										}
									]}
								/>
							</InputWrapper>
						)}
					/>
					<FormControl>
						<BorderWrapper>
							<SectionTitle variant="h6">Privacy</SectionTitle>
							<Controller
								name="private"
								control={control}
								defaultValue="public"
								rules={{ required: "Privacy is required!" }}
								as={
									<StyledRadioGroup className="private-radios">
										<RadioItem
											isControlLeftSide
											isActive={privateVal !== "private"}
											control={<Radio />}
											label="Public"
											value="public"
											helpText={`Anyone can see who’s in the ${correctName} and what they post`}
										/>
										<RadioItem
											isControlLeftSide
											isActive={privateVal === "private"}
											control={<Radio />}
											label="Private"
											value="private"
											helpText={`Only members can see who’s in the ${correctName} and what they post`}
										/>
									</StyledRadioGroup>
								}
							/>
							{privateVal === "private" && (
								<HiddenItem onClick={() => setGroupHidden(!groupHidden)}>
									<RadioItem
										control={<Switch name="groupHidden" inputRef={register} checked={groupHidden} />}
										label="Hidden"
										value={groupHidden}
										icon={
											<IconWrapper>
												<EyeStrikeIcon />
											</IconWrapper>
										}
										helpText={`Only members can find the ${correctName}`}
									/>
								</HiddenItem>
							)}
						</BorderWrapper>
					</FormControl>
					<FormControl>
						<BorderWrapper>
							<SectionTitle variant="h6" className={"extra-space"}>
								Who can post
							</SectionTitle>
							<Controller
								name="disablePosts"
								control={control}
								defaultValue="anyone"
								rules={{ required: "Who can post is required!" }}
								as={
									<StyledRadioGroup className="private-radios">
										<RadioItem
											isControlLeftSide
											isActive={disablePosts !== "permitted"}
											control={<Radio />}
											label="Anyone"
											value="anyone"
											helpText={`Any member of the ${correctName} can create a post`}
										/>
										<RadioItem
											isControlLeftSide
											isActive={disablePosts === "permitted"}
											control={<Radio />}
											label="Admins & Moderators"
											value="permitted"
											helpText={`Only ${correctName} admins & moderators can create a post`}
										/>
									</StyledRadioGroup>
								}
							/>
						</BorderWrapper>
					</FormControl>

					<GroupChatWrapper>
						<MessageControlWrapper className="enable-chat">
							<EnableText>
								<StyledMessageIcon /> {correctName} chat
							</EnableText>
							<Switch name="chatEnabled" inputRef={register} defaultChecked={chatEnabled} />
						</MessageControlWrapper>
					</GroupChatWrapper>
				</MemberDialogContentWrapper>
			</form>
		</MemberDialog>
	);
};

export default CreateGroupMemberView;
