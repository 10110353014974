export const captureVideoThumbnail = (url: string) => {
	const video = document.createElement("video");
	video.src = url;
	const canvas = document.createElement("canvas");

	const ctx = canvas.getContext("2d");
	ctx!.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);

	return canvas.toDataURL("image/jpeg");
};
