import React, { memo } from "react";

import config from "config/appConfig";

import { view } from "modules/MemberHome";

import { useMemberRoutes } from "shared/hooks";

const HomePage = memo(() => {
	const { getMemberRoutesData } = useMemberRoutes();
	const { routes } = getMemberRoutesData();

	return <view.Home adminPanelUrl={config.CORE_API_URLS.ADMIN} routes={routes} />;
});

export default HomePage;
