import config from "config/appConfig";

import { IStateProps } from "utils/StateOf";

import createStore from "utils/createStore";

import { AuthContextValues } from "../../types";

const localStorageKey = `${config.GLOBAL_CONSTANTS.LOCAL_STORAGE_PREFIX}${config.HOOK_STORAGE_KEYS.AUTH_DATA}`;

export const initialState: IStateProps<AuthContextValues> = {
	email: "",
	code: "",
	subscribe: false,
	isSignedIn: false,
	isInvitationMode: false,
	isLoading: false,
	onboardingToken: {
		valid: true,
		loading: true
	}
};

export default createStore<AuthContextValues>({
	initialState,
	persistant: true,
	persistantName: localStorageKey
});
