import React, { ReactNode, useCallback, useEffect, useMemo } from "react";

import styled from "styled-components";

import { useFirebase } from "modules/Messaging/Data";
import { CommunityBlockSideMenu } from "shared/Components";

import { useCommunity, usePlayer, useRoutes, useSeries, useUser } from "shared/hooks";
import { IWorkspace } from "shared/types";
import { Box, Icon, Menu } from "shared/ui-kit";

const Wrapper = styled(Menu)`
	cursor: pointer;
	margin-top: 33px;
	& .MuiPaper-root {
		max-height: 90%;
		width: 350px;
		max-width: 350px;
		display: block;
	}
	& .Mui-focusVisible {
		background: transparent;
	}
	& ul {
		overflow: hidden;
		width: 350px;
		max-height: 470px;
		max-width: 350px;
		padding-bottom: 0;
	}

	li {
		width: 350px;
		max-width: 350px;
		&:hover {
			background: transparent !important;
		}
		&:first-child {
			padding-right: 0;
			padding-left: 0;
		}
		&:nth-last-child(-n + 3) {
			position: sticky;
			background-color: #fff;
		}
		&:last-child {
			bottom: 0;
			padding-bottom: 14px;
		}
		&:nth-last-child(2) {
			bottom: 56px;
		}
		&:nth-last-child(3) {
			padding-top: 0;
			bottom: 104px;
		}
`;

const Divider = styled(Box)`
	border-top: solid 0.5px #c5cee0;
	flex: 1;
	width: 100%;
	cursor: none;
	//disabled: true;
	margin: 0 -16px 0 -16px;
	margin-bottom: 5px;
`;

const StyledIcon = styled(Icon)`
	margin-right: 8px;
`;

const ButtonWrapper = styled(Box)`
	height: 32px;
	margin: 0 2px 4px 0;
	flex: 1;
	border: solid 1px #c5cee0;
	border-radius: 4px;
	display: flex;
	padding-left: 53px;
	align-items: center;
	&:hover {
		background: #edf1f7;
	}
`;

const CommunityListWrapper = styled(Box)`
	overflow: hidden auto;
	width: 100%;
	max-height: 340px;
`;

interface Props {
	children: ReactNode;
	opts?: { name: string; onClick: () => void; icon: React.ReactNode }[];
	dontFilterCurrentCommunity?: boolean;
}

const SideMenu: React.FC<Props> = ({ children, dontFilterCurrentCommunity, opts }) => {
	const { getUserWorkspaces, getData: getUserData } = useUser();
	const { user, workspaces } = getUserData();

	const { setPlayTracks, setAlbumPlaying } = usePlayer();
	const { getData: getSeriesData, setSeriesPlayState } = useSeries();
	const { seriesPlayState } = getSeriesData();

	const { setCurrentChat } = useFirebase();

	const { loginToCommunity, logout, sendToNewCommunityPage } = useRoutes();
	const { getData: getCommunityData } = useCommunity();

	const { workspace, isWhitelabelAppMode } = getCommunityData();
	const workspaceId = workspace?._id;

	useEffect(() => {
		if (!workspaces && user?.email) {
			getUserWorkspaces(user.email);
		}
	}, [user, getUserWorkspaces, workspaces]);

	const handleSwitchCommunity = useCallback(
		(workspace: IWorkspace) => {
			setPlayTracks({ idx: 0 });
			if (seriesPlayState) {
				setSeriesPlayState();
			}
			setAlbumPlaying();
			setCurrentChat();

			loginToCommunity(workspace, true);
		},
		[loginToCommunity, seriesPlayState, setAlbumPlaying, setCurrentChat, setPlayTracks, setSeriesPlayState]
	);

	const options = useMemo(() => {
		const options = [
			{
				name: "1",
				onClick: (() => {}) as () => void,
				comp: () => (
					<CommunityListWrapper>
						{workspaces
							?.filter(wp => wp._id !== workspaceId || dontFilterCurrentCommunity)
							.map((workspace, i) => (
								<CommunityBlockSideMenu
									key={`${workspace._id}${i}`}
									onClick={() => handleSwitchCommunity(workspace)}
									title={workspace?.displayName || workspace.workspace}
									link={workspace.communityUrl}
									iconUrl={workspace.logo || workspace?.meta?.img}
								/>
							))}
					</CommunityListWrapper>
				)
			}
		];

		options.push({
			name: "2",
			onClick: () => false,

			comp: () => <Divider />
		});

		if (opts?.length) {
			opts.forEach((opt, i) =>
				options.push({
					name: `${i + 3}`,
					onClick: opt.onClick,

					comp: () => (
						<ButtonWrapper>
							{opt.icon}
							{opt.name}
						</ButtonWrapper>
					)
				})
			);
		} else {
			options.push({
				name: "3",
				onClick: logout as () => void,

				comp: () => (
					<ButtonWrapper>
						<StyledIcon fill="#222b45" name="sync" group="filled" height={16} width={16} />
						Try with another email
					</ButtonWrapper>
				)
			});
			options.push({
				name: "4",
				onClick: sendToNewCommunityPage as () => void,

				comp: () => (
					<ButtonWrapper>
						<StyledIcon fill="#222b45" name="plus" group="filled" height={16} width={16} />
						Create a new community
					</ButtonWrapper>
				)
			});
		}
		return options;
	}, [
		opts,
		workspaces,
		dontFilterCurrentCommunity,
		workspaceId,
		handleSwitchCommunity,
		logout,
		sendToNewCommunityPage
	]);

	return isWhitelabelAppMode ? (
		<>{children}</>
	) : (
		<Wrapper closeOnSelect onClose={() => {}} onSelect={function noRefCheck() {}} options={options}>
			{children}
		</Wrapper>
	);
};

export default SideMenu;
