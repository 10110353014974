import { useMemo } from "react";

import { API_URLS } from "shared/constants";
import useHttpActions from "shared/services/api/core/useHttpActions";

import { BaseConverter, convertCategories } from "shared/services/converters";

import { CategoryModel } from "shared/types";

import {
	convertBoardsCount,
	convertCreateBoardResponse,
	convertToCreateBoardRequest,
	convertToDeleteBoardRequest,
	convertToUpdateBoardRequest
} from "./converters/BoardConverter";

const useBoardApiService = () => {
	const actions = useHttpActions();

	return useMemo(
		() => ({
			getBoards: async (offset = 0, limit = 10, keyword = "", supportDefault = false) => {
				const params = {
					limit: limit.toString(),
					offset: offset.toString(),
					sortBy: "POST_COUNT_DESC",
					keyword
				};
				if (supportDefault) {
					params["default"] = "true";
				}

				return convertCategories(
					await actions.get(API_URLS.BOARD.GET_LIST, {
						path: `/v3/boards?${new URLSearchParams(params).toString()}`,
						method: "GET"
					})
				);
			},
			getBoard: async (id: string) => {
				return BaseConverter.convert<CategoryModel>(
					await actions.get(API_URLS.BOARD.GET_LIST, {
						path: `/category/${id}`,
						method: "GET"
					})
				);
			},
			followBoard: async (id: string, unfollow = false) => {
				return BaseConverter.convert<{ success: boolean }>(
					await actions.post(API_URLS.FOLLOW(id), {
						type: "BOARD",
						unfollow
					})
				);
			},
			createBoard: async (data: Partial<CategoryModel>) => {
				return convertCreateBoardResponse(await actions.post(API_URLS.BOARD.CREATE, convertToCreateBoardRequest(data)));
			},
			updateBoard: async (data: CategoryModel) => {
				return BaseConverter.convert<CategoryModel>(
					await actions.post(API_URLS.BOARD.UPDATE, convertToUpdateBoardRequest(data))
				);
			},
			deleteBoard: async (data: CategoryModel) => {
				return BaseConverter.convert<CategoryModel>(
					await actions.post(API_URLS.BOARD.DELETE, convertToDeleteBoardRequest(data))
				);
			},
			getBoardsCount: async (keyword = "") => {
				return convertBoardsCount(
					await actions.get(API_URLS.BOARD.GET_LIST, {
						path: `/v3/boards/count?${new URLSearchParams({
							keyword
						}).toString()}`,
						method: "GET"
					})
				);
			}
		}),
		[actions]
	);
};

export default useBoardApiService;
