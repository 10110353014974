import { Box } from "@material-ui/core";
import styled, { css } from "styled-components";

export const TableIcon = styled(Box)`
	border-radius: 4px;
	margin-right: 16px;
	flex-shrink: 0;
	${({ withBg }) =>
		withBg
			? css`
					width: 40px;
					height: 40px;
					display: flex;
					align-items: center;
					justify-content: center;
					border-radius: 4px;
					background-color: #f7f9fc;
					svg path:nth-child(2) {
						fill: #8f9bb3;
					}
			  `
			: css`
					svg {
						width: 40px;
						height: 40px;
					}
			  `}
`;
