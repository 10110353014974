import React, { FC, useCallback, useMemo } from "react";

import { useMediaQuery } from "@material-ui/core";
import PaginationScroller from "@material-ui/lab/Pagination";

import { Select } from "shared/ui-kit";

import * as appTheme from "theme/default";

import { numberWithCommas } from "utils/serviceUtils/helpers";

import { PaginationWrapper } from "./style";

import { PaginationLoader } from "../index";

interface PaginationProps {
	count: number;
	page: number;
	pageSize: number;
	onChangePage?: (newPage: number) => void;
	onChangePageSize?: (newPageSize: number) => void;
	shape?: "rounded" | "round";
	rowsPerPageOptions?: (number | { value: number; label: string })[];
	loading?: boolean;
}

const Pagination: FC<PaginationProps> = ({
	count,
	page,
	pageSize,
	onChangePage,
	onChangePageSize,
	shape = "rounded",
	rowsPerPageOptions = [5, 10, 15, 20, 50],
	loading = false
}) => {
	const totalPages = Math.ceil(count / pageSize);
	const isMobile = useMediaQuery(appTheme.default.breakpoints.down("sm"));

	const handleChangePageSize = useCallback(
		newPageSize => {
			onChangePageSize && onChangePageSize(newPageSize);
		},
		[onChangePageSize]
	);

	const handleChangePage = useCallback(
		(event: React.ChangeEvent<unknown>, value: number) => {
			onChangePage && onChangePage(value);
		},
		[onChangePage]
	);

	const pageSizeOptions = useMemo(
		() =>
			rowsPerPageOptions.map(x => ({
				value: x,
				label: x
			})),
		[rowsPerPageOptions]
	);

	const toCount = (page - 1) * pageSize + pageSize;

	if (loading) return <PaginationLoader />;

	return (
		<PaginationWrapper>
			<PaginationScroller
				page={page}
				size={isMobile ? "small" : "medium"}
				count={totalPages}
				shape={shape}
				onChange={handleChangePage}
			/>
			<PaginationWrapper.PageInfoWrapper>
				<Select options={pageSizeOptions} value={pageSize} onChange={handleChangePageSize} showIcon />
				<PaginationWrapper.Text>
					Showing {numberWithCommas((page - 1) * pageSize + 1)} -{" "}
					{toCount <= count ? numberWithCommas(toCount) : numberWithCommas(count)} of {numberWithCommas(count)}
				</PaginationWrapper.Text>
			</PaginationWrapper.PageInfoWrapper>
		</PaginationWrapper>
	);
};

export default Pagination;
