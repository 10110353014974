import React, { useMemo } from "react";

import Box from "@material-ui/core/Box";

import { useLocation } from "react-router";
import styled, { css } from "styled-components";

import { PostBlockEventType } from "types";

import { routes } from "apps/RegularUser/constants";

import Close from "shared/Components/Blocks/Close";
import { useMemberRoutes } from "shared/hooks";
import { GifModel } from "shared/types";
import { Text } from "shared/ui-kit";

const Wrapper = styled(({ children, id, onEvent, isMessagingView, ...rest }) => (
	<Box {...rest}>
		{id && !isMessagingView && <Close onClick={() => onEvent(PostBlockEventType.delete, id)} />}
		{children}
	</Box>
))`
	min-width: 300px;
	max-width: 100%;

	margin: 12px 0;

	position: relative;

	border-radius: 4px;

	overflow: hidden;

	img {
		vertical-align: top;
	}

	${({ isMessagingView }) =>
		isMessagingView &&
		css`
			min-width: auto;
			width: 280px;
			border-radius: 8px;
			${props => props.theme.breakpoints.up("sm")} {
				width: 360px;
			}
		`}
`;

Wrapper.Type = styled(Box)`
	position: absolute;
	left: 0;
	bottom: 8px;
	border-top-right-radius: 8px;
	border-bottom-right-radius: 8px;
	background-color: #ffffff;
	width: 40px;
	display: flex;
	justify-content: center;
	font-weight: 700;
`;

type Props = Pick<GifModel, "images" | "title"> & {
	id?: string;
	onEvent?: (eventType: PostBlockEventType, id: string) => void;
};

const GifBlock: React.FC<Props> = ({ images, title, id, onEvent }) => {
	const location = useLocation();
	const { getMemberRoutesData } = useMemberRoutes();
	const { routes: memberRoutes } = getMemberRoutesData();

	const isMessagingView = useMemo(
		() =>
			location.pathname.startsWith(memberRoutes?.member.messaging.getPath()) ||
			location.pathname.startsWith(routes.messaging.getPath()),
		[location.pathname, memberRoutes?.member.messaging]
	);
	return (
		<Wrapper
			id={id}
			isMessagingView={isMessagingView}
			onEvent={onEvent}
			data-name="gif"
			data-gif={JSON.stringify({ src: images.original.url, alt: title })}
		>
			{!isMessagingView && (
				<Wrapper.Type>
					<Text>GIF</Text>
				</Wrapper.Type>
			)}
			<img src={images.original.url} alt={title} style={{ width: "100%" }} />
		</Wrapper>
	);
};

export default GifBlock;
