export enum EventType {
	Upcoming = "Upcoming",
	Happening = "Happening Now",
	Past = "Past",
	All = "All",
	Live_Conversation = "liveconversation"
}

export type UserEventType =
	| "myevents"
	| "myliveconversation"
	| "past"
	| "all"
	| "liveconversation"
	| "offline"
	| "myadminevents";
