import React, { memo, useCallback, useEffect, useMemo, useState } from "react";

import { ButtonBase } from "@material-ui/core";
import styled from "styled-components";

import { PostBlockEventType } from "types";

import { useFeature, usePoll, useUser } from "shared/hooks";
import { PollModelResponse } from "shared/types";
import { Box, Text } from "shared/ui-kit";

import Close from "./Close";

import { Wrapper } from "./ItemTemplate/styles";

const HideBlock = React.lazy(() => import("./HideBlock"));

const AnswerList = styled(Box)`
	margin-top: 16px;
`;

const Description = styled(Text)`
	font-size: 15px;
`;

const Answer = styled(ButtonBase)`
	width: 100%;
	height: 48px;
	padding: 11px 12px;
	border-radius: 4px;
	border: solid 1px #c5cee0;

	display: flex;
	align-items: center;
	justify-content: space-between;

	position: relative;

	overflow: hidden;

	&:not(:last-child) {
		margin-bottom: 8px;
	}
`;

Answer.Progress = styled(Box)`
	position: absolute;
	top: 0;
	left: 0;

	height: 100%;

	background-color: #dfe3ff;

	transition: width 0.25s ease;

	z-index: 1;
`;

Answer.InfoBlock = styled(Box)`
	display: flex;
	align-items: center;
`;

Answer.Counter = styled(Text)`
	position: relative;

	font-size: 15px;
	line-height: 22px;
	color: #939699;

	z-index: 2;
`;

Answer.Marker = styled(Box)`
	position: relative;

	background: #edf1f7;

	width: 24px;
	height: 24px;

	margin: 0 8px 0 0;

	border-radius: 4px;

	font-size: 15px;
	font-weight: 600;
	line-height: 1.6;
	text-align: center;
	color: #8f9bb3;

	z-index: 2;
`;

Answer.Value = styled(Text)`
	position: relative;

	font-size: 15px;
	line-height: 22px;

	z-index: 2;
`;

const genCharArray = (charA, charZ) => {
	const a: any[] = [];
	let i = charA.charCodeAt(0);
	const j = charZ.charCodeAt(0);
	for (; i <= j; ++i) {
		a.push(String?.fromCharCode(i));
	}
	return a;
};

const alphabet = genCharArray("a", "z");

type Props = {
	poll: Omit<PollModelResponse, "totalResponses">;
	id?: string;
	onEvent?: (eventType: PostBlockEventType, id: string) => void;
	isMarketing?: boolean;
};

const PollBlock: React.FC<Props> = memo(({ poll, id, onEvent, isMarketing }) => {
	const { isFeatureEnabled } = useFeature();
	const { getPoll, answerPoll } = usePoll(isMarketing);

	const { getData: getUserData } = useUser();
	const { user, isMemberView } = getUserData();

	const [pollInfo, setPollInfo] = useState(poll);

	const personaId = useMemo(
		() => Number(user?.activeProfile || (user?.profiles?.length ? user.profiles[0]?.personaId || 0 : 0)),
		[user]
	);

	const fetchPoll = useCallback(
		async (id: string) => {
			const pollData = await getPoll(id);
			if (pollData) {
				setPollInfo(pollData);
			}
		},
		[getPoll]
	);

	useEffect(() => {
		poll?._id && fetchPoll(poll._id);
	}, [fetchPoll, poll?._id]);

	const answerOnPoll = useCallback(
		async (id: number, type: "submit" | "unsubmit") => {
			if (pollInfo.mode === "multiple") {
				await answerPoll({ answerIds: [id], pollId: pollInfo._id, type });
			} else {
				const currentUserAnswers = pollInfo.answer
					.filter(x => x.id !== id && x.isSelected.indexOf(personaId) > -1)
					.map(x => x.id);
				if (currentUserAnswers.length) {
					await answerPoll({ answerIds: currentUserAnswers, pollId: pollInfo._id, type: "unsubmit" });
				}
				await answerPoll({ answerIds: [id], pollId: pollInfo._id, type });
			}
			fetchPoll(pollInfo._id);
		},
		[answerPoll, pollInfo, fetchPoll, personaId]
	);

	if (!isFeatureEnabled("enablePolls")) return <HideBlock />;

	return (
		<Wrapper
			autoheight
			isMemberView={isMemberView}
			id={id}
			onEvent={onEvent}
			data-name="poll"
			data-poll={encodeURIComponent(JSON.stringify(pollInfo))}
			isPadding
		>
			{id && !isMemberView && <Close onClick={() => onEvent && onEvent(PostBlockEventType.delete, id)} />}
			<Description>{pollInfo?.description}</Description>
			<AnswerList>
				{(pollInfo?.answer || []).map((a, index) => (
					<Answer
						key={index}
						onClick={() => answerOnPoll(a.id, a.isSelected.indexOf(personaId) > -1 ? "unsubmit" : "submit")}
					>
						<Answer.Progress
							style={{
								width: pollInfo.uniqueUserResponses ? `${(a.count / pollInfo.uniqueUserResponses) * 100}%` : 0
							}}
						/>
						<Answer.InfoBlock>
							<Answer.Marker>{alphabet[index].toUpperCase()}</Answer.Marker>
							<Answer.Value>{a.value}</Answer.Value>
						</Answer.InfoBlock>
						<Answer.Counter>{a.count}</Answer.Counter>
					</Answer>
				))}
			</AnswerList>
		</Wrapper>
	);
});

export default PollBlock;
