import { FundraiserModel, OnBehalfOfPersonaModel } from "./FundraiserModel";
import { GroupModel } from "./GroupModel";
import { MediaModel } from "./MediaModel";

export interface LeaderboardMember {
	_id: string;
	groupId: string;
	persona: {
		_id: string;
		firstName: string;
		lastName: string;
		photos: MediaModel[];
		personaId: number;
	};
	totalRaised?: {
		_id: string;
		totalRaised: number;
		totalSponsors: number;
	};
	group: {
		_id: string;
		coverUrl: string;
		name: string;
		slug: string;
		totalMembers: number;
	}[];
}

export interface LeaderboardGroup {
	_id: string;
	coverUrl: string;
	name: string;
	slug: string;
	totalMembers: number;
	totalRaised: {
		_id: string;
		totalRaised: number;
		totalSponsors: number;
		totalDonations: number;
	}[];
}

export interface GroupRelation {
	_id: string;
	bbUserId: number;
	personaId: number;
	isMember: boolean;
	isAdmin: boolean;
	isOwner: boolean;
	isModerator: boolean;
	isPending: boolean;
	isInvited: boolean;
	isInfluencer: boolean;
	groupId: string;
	userId: string | null;
	updatedAt: string;
	postCount: number;
}
export interface OnBehalfOfLeaderboardPersona {
	_id: string;
	firstName: string;
	lastName: string;
	photos: MediaModel[];
	personaId: number;
	group: GroupRelation[];
}

export interface LeaderboardSponsor {
	_id: string;
	personaId: number;
	creatorId: string;
	messageBody: string;
	relationship: string;
	fundraiserId: string;
	name: string;
	amountRaised: number;
	contactInfo: {
		email?: string | null;
		phone?: string | null;
	};
	image: {
		mediaId: string | null;
		url: string | null;
	};
	updatedAt: string;
	createdAt: string;
	link: string;
	onBehalfOfPersona: OnBehalfOfLeaderboardPersona[];
}

export interface FundraiserDonationType {
	amountRaised: number;
	contact: LeaderboardSponsor;
	contactId: string;
	createdAt: string;
	fundraiser: FundraiserModel;
	fundraiserId: string;
	group: GroupModel;
	groupId: string;
	onBehalfPersona: OnBehalfOfPersonaModel;
	_id: string;
}

export interface FundraiserIntentType {
	donation: any;
	stripePaymentIntentId: string;
	paymentIntentClientSecret: string;
	customerId: string;
}

export enum FundraiserDonationSortBy {
	SPONSOR = "sponsor",
	DATE = "date",
	FUNDRAISER = "fundraiser",
	GROUP = "group",
	ON_BEHALF = "onBehalfOf",
	AMOUNT = "amount"
}
