export default ({ schema: { type, minOcc }, validation: { email: message = "Invalid Email Address" } }) =>
	type === "email" && [
		"validate",
		value => {
			// const re = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
			// eslint-disable-next-line
			const re =
				/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			if (minOcc === 0 && value.length === 0) {
				return true;
			}
			return re.test(String(value).toLowerCase()) ? true : message;
		}
	];
