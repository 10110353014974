import React from "react";

import { useLocation, useParams } from "react-router";

import { view } from "modules/MemberHome";

const TrackPage = () => {
	const { id } = useParams() as { id: string };
	const { search } = useLocation();
	const query = new URLSearchParams(search);
	const groupId = query.get("groupId") || undefined;

	return <view.Track id={id} groupId={groupId} />;
};

export default TrackPage;
