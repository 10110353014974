import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";

import { IconButton } from "@material-ui/core";
import PauseIcon from "@material-ui/icons/Pause";
import PlayIcon from "@material-ui/icons/PlayArrow";
import { Region, WaveForm, WaveSurfer } from "wavesurfer-react";
import RegionsPlugin from "wavesurfer.js/dist/plugin/wavesurfer.regions.min";

import { Video, Wrapper } from "./style";

interface TrackVideoPreviewCutterProps {
	url: string | null;
	preview: {
		start: number;
		end: number;
	};
	setPreview: React.Dispatch<
		React.SetStateAction<{
			start: number;
			end: number;
		}>
	>;
	isVideo?: boolean;
}

const TrackVideoPreviewCutter: FC<TrackVideoPreviewCutterProps> = ({ url, preview, setPreview, isVideo = false }) => {
	const [isPlaying, setIsPlaying] = useState(false);
	const [videoImgList, setVideoImgList] = useState<string[]>([]);
	const [subscribedVideoTimeChange, setSubscribedVideoTimeChange] = useState(false);

	const plugins = useMemo(() => {
		return [{ plugin: RegionsPlugin }].filter(Boolean);
	}, []);

	const regions = useMemo(
		() => [{ id: "region-1", start: preview.start / 1000 || 0, end: preview.end / 1000 || 30, resize: false }],
		[preview]
	);

	const regionsRef = useRef(regions);
	const wavesurferRef = useRef<any>();
	const videoRegionMover = useRef({ active: false, offset: 0, initialX: 0, currentX: 0, offsetPercent: 0 });
	const videoRegionRef = useRef<HTMLDivElement>();
	const videoFormRef = useRef<HTMLDivElement>();
	const videoRef = useRef<any>();

	useEffect(() => {
		regionsRef.current = regions;
	}, [regions]);

	useEffect(() => {
		if (isVideo && url) {
			const newVideo = document.createElement("video");
			newVideo.setAttribute("crossorigin", "use-credentials");
			newVideo.src = url;
			newVideo.style.opacity = "0";
			document.body.appendChild(newVideo);

			newVideo.addEventListener("loadeddata", function () {
				newVideo.width = newVideo.videoWidth;
				newVideo.height = newVideo.videoHeight;
				newVideo.style.width = `${newVideo.videoWidth}px`;
				newVideo.style.height = `${newVideo.videoHeight}px`;

				this.currentTime = 0;
			});

			newVideo.addEventListener("seeked", function () {
				const getScreenshot = (videoEl, scale) => {
					const correctScale = scale || 1;

					const canvas = document.createElement("canvas");
					canvas.width = videoEl.clientWidth * correctScale;
					canvas.height = videoEl.clientHeight * correctScale;
					// @ts-expect-error: valid call
					canvas.getContext("2d").drawImage(videoEl, 0, 0, canvas.width, canvas.height);
					return canvas.toDataURL();
				};

				// now video has seeked and current frames will show
				// at the time as we expect
				const imageSrc = getScreenshot(newVideo, 1);

				const newTime = this.currentTime + this.duration / 9;

				// if we are not past end, seek to next interval
				if (newTime <= this.duration) {
					// this will trigger another seeked event
					this.currentTime = newTime;
					setVideoImgList(list => list.concat([imageSrc]));
				} else {
					setVideoImgList(list => list.concat([imageSrc]));
					document.body.removeChild(newVideo);
					// Done!, next action
				}
			});
		}
	}, [isVideo, url]);

	useEffect(() => {
		if (wavesurferRef.current && url && !isVideo) {
			wavesurferRef.current.load(url);
		}
	}, [url, isVideo]);

	useEffect(() => {
		return () => {
			wavesurferRef?.current?.backend?.pause && wavesurferRef.current.backend.pause();
		};
	}, []);

	const handleWSMount = useCallback(waveSurfer => {
		wavesurferRef.current = waveSurfer;
	}, []);

	const playPause = useCallback(() => {
		if (isVideo) {
			if (videoRef.current.paused) {
				videoRef.current.play();
				setIsPlaying(true);
			} else {
				videoRef.current.pause();
				setIsPlaying(false);
			}
		} else {
			if (wavesurferRef.current.backend.isPaused()) {
				wavesurferRef.current.regions.list["region-1"].play(wavesurferRef.current.getCurrentTime());
				setIsPlaying(true);
			} else {
				wavesurferRef.current.backend.pause();
				setIsPlaying(false);
			}
		}
	}, [isVideo]);

	const handleRegionUpdate = useCallback(
		region => {
			setPreview({
				start: parseInt(region.start) * 1000,
				end: parseInt(region.end) * 1000
			});

			wavesurferRef.current.regions.list["region-1"].play(region.start);
			setIsPlaying(true);
		},
		[setPreview]
	);

	const handleDragVideoRegionStart = useCallback(e => {
		videoRegionMover.current.initialX =
			(e.type === "touchstart" ? e.touches[0].clientX : e.clientX) - videoRegionMover.current.offset;

		if (e.target === videoRegionRef.current) {
			videoRegionMover.current.active = true;
		}
	}, []);

	const handleDragVideoRegionEnd = useCallback(() => {
		videoRegionMover.current.initialX = videoRegionMover.current.currentX;

		videoRegionMover.current.active = false;

		videoRef.current.currentTime = (videoRef.current.duration * videoRegionMover.current.offsetPercent) / 100;
		videoRef.current.play();
		setIsPlaying(true);

		setPreview({
			start: parseInt(videoRef.current.currentTime) * 1000,
			end: parseInt(videoRef.current.currentTime + 30) * 1000
		});
	}, [setPreview]);

	const handleDragVideoRegionMove = useCallback(e => {
		if (videoRegionMover.current.active) {
			e.preventDefault();

			let newCurrentX = (e.type === "touchmove" ? e.touches[0].clientX : e.clientX) - videoRegionMover.current.initialX;

			if (newCurrentX < 0) {
				newCurrentX = 0;
			}

			if (newCurrentX > videoFormRef.current!.clientWidth - videoRegionRef.current!.clientWidth) {
				newCurrentX = videoFormRef.current!.clientWidth - videoRegionRef.current!.clientWidth;
			}

			videoRegionMover.current.currentX = newCurrentX;
			videoRegionMover.current.offset = videoRegionMover.current.currentX;
			videoRegionMover.current.offsetPercent = (newCurrentX * 100) / videoFormRef.current!.clientWidth;

			if (videoRegionRef.current) {
				videoRegionRef.current.style.transform = `translateX(${newCurrentX}px)`;
			}
		}
	}, []);

	useEffect(() => {
		if (isVideo && !subscribedVideoTimeChange) {
			setSubscribedVideoTimeChange(true);
			videoRef.current.addEventListener("timeupdate", (e: any) => {
				const currentTime = e.target.currentTime;

				if (isPlaying && currentTime >= preview.end / 1000) {
					playPause();
				}
			});
		}
	}, [preview, isPlaying, isVideo, playPause, subscribedVideoTimeChange]);

	return (
		<Wrapper>
			<IconButton size="small" onClick={playPause}>
				{isPlaying ? <PauseIcon /> : <PlayIcon />}
			</IconButton>
			{isVideo ? (
				<Video.Form ref={videoFormRef}>
					<Video.Region
						ref={videoRegionRef}
						onTouchStart={handleDragVideoRegionStart}
						onTouchMove={handleDragVideoRegionMove}
						onTouchEnd={handleDragVideoRegionEnd}
						onMouseDown={handleDragVideoRegionStart}
						onMouseMove={handleDragVideoRegionMove}
						onMouseUp={handleDragVideoRegionEnd}
					>
						<Video.VideoWrapperInRegion>
							<video ref={videoRef} src={`${url}`} height={"100%"}></video>
						</Video.VideoWrapperInRegion>
					</Video.Region>
					{videoImgList.map((videoImgSrc, i, arr) => (
						<Video.FormImageFrame
							key={i}
							style={{ backgroundImage: `url(${videoImgSrc})`, left: `${i * arr.length}%`, width: `${arr.length}%` }}
						/>
					))}
				</Video.Form>
			) : (
				/* @ts-expect-error: valid params */
				<WaveSurfer plugins={plugins} onMount={handleWSMount}>
					{/* @ts-expect-error: valid params */}
					<WaveForm id="waveform" hideScrollbar={false} waveColor="#c5cee0" progressColor="#6173fe" height={60}>
						{regions.map(region => (
							/* @ts-expect-error: valid params */
							<Region key={region.id} onUpdateEnd={handleRegionUpdate} color="transparent" {...region} />
						))}
					</WaveForm>
				</WaveSurfer>
			)}
		</Wrapper>
	);
};

export default TrackVideoPreviewCutter;
