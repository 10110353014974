import config from "config/appConfig";

import { ProfileType } from "shared/types";
import { IStateProps } from "utils/StateOf";

import createStore from "utils/createStore";

const localStorageKey = `${config.GLOBAL_CONSTANTS.LOCAL_STORAGE_PREFIX}${config.HOOK_STORAGE_KEYS.PERSONA_DATA}`;

export interface Values {
	persona?: ProfileType;
}

export const initialState: IStateProps<Values> = {
	persona: undefined
};

export default createStore<Values>({
	initialState,
	persistant: true,
	persistantName: localStorageKey
});
