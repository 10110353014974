import React from "react";

import styled, { css } from "styled-components";

import { Box } from "shared/ui-kit";

interface FrameInfoRowProps {
	customStyle?: string;
}

const FrameInfoRowWrapper = styled(Box)<FrameInfoRowProps>`
	display: block;

	border-radius: 4px;

	background-color: #e4e9f2;

	margin: 4px 0;

	${({ customStyle }) => {
		return customStyle
			? css`
					${customStyle}
			  `
			: "";
	}}
`;

const FrameInfoRow: React.FC<FrameInfoRowProps> = (props: FrameInfoRowProps): JSX.Element => {
	return <FrameInfoRowWrapper {...props} />;
};

export default FrameInfoRow;
