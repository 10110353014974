import { HTMLAttributes } from "react";

import styled, { css } from "styled-components";

import { ReactComponent as PlacesIcon } from "assets/icons/places.svg";
import { Box } from "shared/ui-kit";

export const Item = styled(Box)``;

Item.Wrapper = styled(Box)`
	width: 100%;
	height: 80px;

	display: flex;
	flex-direction: row;
	justify-content: space-between;

	margin-bottom: 24px;

	${props => props.theme.breakpoints.down("sm")} {
		justify-content: flex-start;
	}
`;

Item.MainBody = styled(Box)`
	display: flex;
	align-items: center;

	${props => props.theme.breakpoints.down("sm")} {
		width: 100%;
	}
`;

Item.AvatarWrapper = styled(Box)`
	width: 80px;
	min-width: 80px;
	height: 80px;

	border-radius: 8px;

	overflow: hidden;
`;

Item.Avatar = styled<HTMLAttributes<HTMLDivElement> & { img: string }>(Box)`
	width: 100%;
	height: 100%;

	background-color: ${({ img }) => (img ? "white" : "#f7f9fc")};

	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;

	${({ img }) => {
		return img
			? css`
					background-image: url(${img});
			  `
			: css`
					display: flex;
					justify-content: center;
					align-items: center;
			  `;
	}}
`;

Item.InfoBlock = styled(Box)`
	width: ${({ isMemberView }) => (isMemberView ? "auto" : "300px")};
	height: 100%;

	display: flex;
	flex-direction: column;
	justify-content: space-between;

	padding: 5px 16px;
`;

Item.Pretitle = styled(Box)`
	font-size: 13px;
	line-height: 1.23;
	color: #222b45;
`;

Item.Title = styled(Box)`
	text-transform: capitalize;
	font-size: 15px;
	font-weight: 600;
	line-height: 1.2;
	color: #222b45;

	display: block;
	display: -webkit-box;
	-webkit-line-clamp: 2; /* if you change this, make sure to change the fallback line-height and height */
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
`;

Item.Subtitle = styled(Box)`
	font-size: 13px;
	line-height: 1.23;
	color: #8f9bb3;
`;

Item.ButtonWrapper = styled(Box)`
	text-align: right;
	margin: 16px 0;
	${props => props.theme.breakpoints.down("sm")} {
		display: none;
	}
`;

Item.Members = styled(Box)`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;

	.member-avatar {
		width: 16px;
		height: 16px;
	}
`;

Item.MembersCounter = styled.p`
	margin: 0 0 0 8px;
	font-size: 13px;
	line-height: 1.23;
	color: #8f9bb3;
`;

export const Places = styled(PlacesIcon)`
	width: 100%;
	height: 100%;

	path {
		&:nth-child(2) {
			fill: #8f9bb3;
		}
	}
`;
