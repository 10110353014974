import React, { useCallback, useMemo } from "react";

import { DateTime } from "luxon";
import { Scrollbars } from "react-custom-scrollbars";
import { FixedSizeList } from "react-window";

import { PlaceholderImage } from "shared/Components";
import { useAnalytics, useCommunity } from "shared/hooks";

import { PlaceholderImageType } from "shared/types";
import { Icon } from "shared/ui-kit";

import {
	BodyWrapper,
	StyledAvatar,
	UserItem,
	UserItemIconWrapper,
	UserItemInfo,
	UserItemSubTitle,
	UserItemTextsWrapper,
	UserItemTitle,
	UserItemValue,
	UserItemValueWrapper,
	Wrapper
} from "./style";

import BlockHeader from "../BlockHeader";
import NoDataFound from "../NoDataFound";

const virtualizedConfig = {
	itemSize: 70,
	bodyHeight: 212,
	overscanCount: 10
};

interface TopRefereesProps {
	displayCsvDownload: boolean;
}

const TopRefereesBlock: React.FC<TopRefereesProps> = ({ displayCsvDownload }) => {
	const { getTopReferees, getData: getAnalyticsData, getTopRefereesCSVFile } = useAnalytics();
	const { topReferees } = getAnalyticsData();

	const { getData: getCommunityData } = useCommunity();
	const { workspace } = getCommunityData();

	const changePeriod = useCallback(
		(requestedFrom: DateTime, requestedTo: DateTime) => {
			getTopReferees(requestedFrom, requestedTo);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[getTopReferees, workspace]
	);

	const RenderRow = React.useCallback(
		({ index, style }) => {
			const user = topReferees[index];
			const jobTitle = user.creator.intro?.job[0]?.titleLabel;
			const company = user.creator.intro?.job[0]?.companyLabel;
			const img = user.creator.photos?.length ? user.creator.photos[0]?.profilePicture : undefined;

			return (
				<UserItem key={user._id} style={style}>
					<UserItemInfo>
						<UserItemIconWrapper>
							{img ? (
								<StyledAvatar
									title={`${user.creator.firstName} ${user.creator.lastName}`}
									image={user.creator.photos[0]?.profilePicture}
								/>
							) : (
								<PlaceholderImage
									type={PlaceholderImageType.PROFILE_IMAGE}
									width={32}
									height={32}
									viewBox={"0 0 400 400"}
								/>
							)}
						</UserItemIconWrapper>
						<UserItemTextsWrapper>
							<UserItemTitle>{`${user.creator.firstName} ${user.creator.lastName}`}</UserItemTitle>
							<UserItemSubTitle>{jobTitle && `${jobTitle}, ${company}`}</UserItemSubTitle>
						</UserItemTextsWrapper>
					</UserItemInfo>
					<UserItemValueWrapper>
						<UserItemValue>{user.count}</UserItemValue>
						<Icon group={"filled"} fill={"#8f9bb3"} name={"user-plus"} />
					</UserItemValueWrapper>
				</UserItem>
			);
		},
		[topReferees]
	);

	const ListBlock = useMemo(
		() => (
			<FixedSizeList
				height={
					topReferees?.length * virtualizedConfig.itemSize < virtualizedConfig.bodyHeight
						? topReferees?.length * virtualizedConfig.itemSize
						: virtualizedConfig.bodyHeight
				}
				itemCount={topReferees?.length || 0}
				itemSize={virtualizedConfig.itemSize}
				width={"100%"}
				overscanCount={virtualizedConfig.overscanCount}
				outerElementType={Scrollbars}
				style={{ overflow: "hidden" }}
			>
				{RenderRow}
			</FixedSizeList>
		),
		[topReferees, RenderRow]
	);

	return (
		<Wrapper>
			<BlockHeader
				title="Top Referees"
				subtitle={""}
				showDropdown
				onChangePeriod={changePeriod}
				onDownloadCsv={displayCsvDownload ? getTopRefereesCSVFile : undefined}
				csvFilenamePrefix="top_referees_report"
			/>
			<BodyWrapper centeredContent={!topReferees.length}>
				{topReferees.length > 0 ? <>{ListBlock}</> : <NoDataFound />}
			</BodyWrapper>
		</Wrapper>
	);
};

export default TopRefereesBlock;
