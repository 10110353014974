import React, { useEffect, useState } from "react";

import styled from "styled-components";

import { useCommunity } from "shared/hooks";
import { Box, Input, Text } from "shared/ui-kit";

import SubmitButton from "../SubmitButton";

const Wrapper = styled(Box)`
	display: flex;
	flex-direction: row;
	flex: 1;
	justify-content: space-between;
	margin: 30px 10px 0 0;
	${props => props.theme.breakpoints.down("sm")} {
		flex-direction: column;
		width: 100%;
	}
`;

const StyledInput = styled(Input)`
	flex: 1;
	margin-right: 40px;
	max-width: 400px;
`;

const BTCMetricsSponsorNShop = () => {
	const [meta, setMeta] = useState({
		btcMetricsSponsor: {
			name: "",
			url: ""
		},
		purchaseBtcSponsor: {
			url: ""
		}
	});

	const { updateWorkspaceMeta, getData: getCommunityData } = useCommunity();
	const { workspace } = getCommunityData();

	useEffect(() => {
		if (workspace) {
			setMeta({
				btcMetricsSponsor: workspace.meta?.btcMetricsSponsor,
				purchaseBtcSponsor: workspace.meta?.purchaseBtcSponsor
			});
		}
	}, [workspace, setMeta]);

	const onSave = async () => {
		await updateWorkspaceMeta({
			meta
		});
	};
	return (
		<>
			<Text>Change the Bitcoin Metrics Sponsor name, link, or the Bitcoin shop link here.</Text>
			<Wrapper>
				<StyledInput
					placeholder="Type BTC Metrics Sponsor Name"
					value={meta.btcMetricsSponsor.name}
					onChange={e =>
						setMeta({
							btcMetricsSponsor: { name: e.target.value, url: meta.btcMetricsSponsor.url },
							purchaseBtcSponsor: { url: meta.purchaseBtcSponsor.url }
						})
					}
					id="btcMetricsSponsorName"
				/>
				<StyledInput
					placeholder="Type BTC Metrics Sponsor Url"
					value={meta.btcMetricsSponsor.url}
					onChange={e =>
						setMeta({
							btcMetricsSponsor: { name: meta.btcMetricsSponsor.name, url: e.target.value },
							purchaseBtcSponsor: { url: meta.purchaseBtcSponsor.url }
						})
					}
					id="btcMetricsSponsorUrl"
				/>
				<StyledInput
					placeholder="Type BTC Shop Url"
					value={meta.purchaseBtcSponsor.url}
					onChange={e =>
						setMeta({
							btcMetricsSponsor: { name: meta.btcMetricsSponsor.name, url: meta.btcMetricsSponsor.url },
							purchaseBtcSponsor: { url: e.target.value }
						})
					}
					id="purchaseBtcSponsorUrl"
				/>
				<SubmitButton onClick={onSave} id="saveChangesBTCSponsors">
					Save changes
				</SubmitButton>
			</Wrapper>
		</>
	);
};

export default BTCMetricsSponsorNShop;
