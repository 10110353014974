import styled from "styled-components";

import { Box, Button, Icon, InPlaceModal, Text } from "shared/ui-kit";

export const ModalOverlay = styled(Box)`
	.MuiDialog-container {
		height: 100vh;
	}
	.MuiDialog-paperScrollPaper {
		margin: 0;
		padding: 0;
		width: calc(100% - 32px);
	}
	.MuiDialogContent-root {
		padding: 0;
	}
`;

export const AddFaqsModal = styled(InPlaceModal)`
	width: 100%;
	padding: 100px;

	.MuiDialog-paperWidthSm {
		max-width: initial !important;
	}

	.MuiDialogContent-root {
		padding: 0 !important;
	}
`;

export const AddFaqModalHeader = styled(Box)`
	position: relative;
	display: flex;
	justify-content: center;
	padding: 16px 0;
	margin-bottom: 20px;
	border-bottom: 1px solid #edf1f7;
	h6 {
		font-size: 18px;
	}
`;

export const ActionBtn = styled(Button)`
	height: 48px;
	width: 160px;
`;

export const StyledButton = styled(Button)`
	height: 48px;
	flex-shrink: 0;
	width: 104px;
`;

export const FooterWrapper = styled(Box)`
	position: sticky;
	bottom: 0;
	width: 100%;
	background-color: white;
	justify-content: flex-end;
	align-items: center;
	padding: 0 20px;
	margin-top: 20px;
	height: 81px;
	border: solid #edf1f7;
	border-width: 1px 0 0 0;
	display: flex;
	margin-right: -22px;
`;

export const IconContainer = styled(Box)`
	cursor: pointer;
	position: absolute;
	left: 3%;
	bottom: 5%;
	padding: 5px;
	font-size: 24px;
	width: 35px;
	height: 35px;
	:hover {
		border-radius: 4px;
		background-color: rgba(10, 10, 10, 0.07);
	}
`;

export const IconWrapper = styled(Box)`
	position: absolute;
	right: 16px;
	top: 0;
	height: 100%;
	display: flex;
	align-items: center;
`;

export const StyledIcon = styled(Icon)`
	width: 24px;
	height: 24px;
	cursor: pointer;
`;

export const HeadlineText = styled(Text)`
	font-size: 16px;
	margin-bottom: 8px;
	font-weight: bold;
	color: #222b45;
`;

export const InputWrapper = styled(Box)`
	margin-top: 16px;
`;

export const BodyWrapper = styled(Box)`
	padding: 0 24px;
`;
