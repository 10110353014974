import React, { FC, useMemo } from "react";

import clsx from "clsx";
import styled from "styled-components";

import { ReactComponent as AlertIcon } from "assets/icons/icon-alert-fill.svg";
import { useUser } from "shared/hooks";
import { FundraiserStatusEnum } from "shared/types/FundraiserModel";
import { Button, Text } from "shared/ui-kit";

const Wrapper = styled.div`
	border-radius: 12px;
	min-height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;

	svg {
		width: 24px;
		height: 24px;
	}

	&.warning {
		background-color: #ff9500;
	}

	&.error {
		background-color: #db2c33;
	}
`;

const FundraiserErrorHeader: FC<{ status?: FundraiserStatusEnum; handleActive: () => void }> = ({
	status,
	handleActive
}) => {
	const isPending = status === FundraiserStatusEnum.PENDING.toUpperCase();
	const isPaused = status === FundraiserStatusEnum.PAUSED.toUpperCase();
	const isEnded = status === FundraiserStatusEnum.ENDED.toUpperCase();
	const isArchived = status === FundraiserStatusEnum.ARCHIVED.toUpperCase();

	const { getData: getUserData } = useUser();
	const { user } = getUserData();

	const canActivate = useMemo(
		() => user?.isGlobalAdmin || user?.isGlobalModerator || user?.isGlobalOwner || user?.isInfluencer,
		[user]
	);

	if (!isPending && !isPaused && !isEnded && !isArchived) return null;

	const renderMessage = () => {
		if (isPending) {
			return (
				<>
					<AlertIcon />
					<Text variant="subtitle1">This fundraiser is still pending</Text>
				</>
			);
		}

		if (isPaused) {
			return (
				<>
					<AlertIcon />
					<Text variant="subtitle1">This fundraiser was paused</Text>
					{canActivate && (
						<Button
							palette="control"
							buttonTheme="light"
							size="small"
							className="ml-2 text-black"
							onClick={handleActive}
						>
							Activate
						</Button>
					)}
				</>
			);
		}

		if (isEnded) {
			return (
				<>
					<AlertIcon />
					<Text variant="subtitle1">This fundraiser has already ended</Text>
				</>
			);
		}

		if (isArchived) {
			return (
				<>
					<AlertIcon />
					<Text variant="subtitle1">This fundraiser has been archived</Text>
				</>
			);
		}
	};

	return (
		<Wrapper
			className={clsx("mb-4 text-white", (isPaused || isPending) && "warning", (isEnded || isArchived) && "error")}
		>
			{renderMessage()}
		</Wrapper>
	);
};

export default FundraiserErrorHeader;
