import { Box } from "@material-ui/core";
import styled from "styled-components";

import { Text } from "shared/ui-kit";

export const NavbarWrapper = styled(Box)`
	background-color: white;
	padding: 16px;
	border: 1px solid rgba((197, 206, 224, 0.5));
	box-shadow: 0 2px 4px 0 rgb(0 0 0 / 8%);
	${props => props.theme.breakpoints.up("md")} {
		padding: 16px 80px;
	}
`;

export const ContentWrapper = styled(Box)`
	max-width: 900px;
	padding: 32px 16px;
	margin: auto;
`;

ContentWrapper.Block = styled(Box)`
	padding-bottom: 40px;
	margin-bottom: 12px;
	border-bottom: 1px solid #c5cee0;
`;

ContentWrapper.Header = styled(Box)`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 16px 0;
	svg,
	img {
		width: 64px;
		height: 64px;
		object-fit: cover;
		${props => props.theme.breakpoints.up("sm")} {
			width: 80px;
			height: 80px;
		}
	}
`;

ContentWrapper.Headline = styled(Text)`
	font-size: 20px;
	font-weight: bold;
	line-height: 1.33;
	color: #222b45;
	flex: 1;
	margin-right: 12px;
	${props => props.theme.breakpoints.up("sm")} {
		font-size: 30px;
	}
`;

ContentWrapper.OS = styled(Box)`
	display: flex;
	align-items: center;
	margin: 16px 0;
	p {
		font-size: 14px;
		font-weight: bold;
		line-height: 1.33;
		color: #222b45;
		margin-left: 16px;
		${props => props.theme.breakpoints.up("sm")} {
			font-size: 18px;
		}
	}
`;

ContentWrapper.Description = styled(Text)`
	font-size: 15px;
	line-height: 1.47;
	color: #222b45;
`;

ContentWrapper.Step = styled(Box)`
	margin-bottom: 16px;
	.substep {
		margin-top: 12px;
		margin-left: 20px;
	}
`;
