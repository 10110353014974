import React from "react";

import { RelativeWrapper } from "shared/styles";
import { Box, Icon } from "shared/ui-kit";

import { SecondaryText } from "../../../../../MemberHome/View/Containers/Requests/style";

const CancelCarpoolBubble = props => (
	<RelativeWrapper className="w-9/12 m-auto rounded-lg my-4">
		<Box className="text-center mb-4">
			<SecondaryText className="font-medium text-xs flex justify-center items-center">
				<Icon name="close-circle" fill="#C5CEE0" className="mr-1" width={16} height={16} />
				{props.text}
			</SecondaryText>
		</Box>
	</RelativeWrapper>
);

export default CancelCarpoolBubble;
