import React, { useCallback, useEffect, useMemo, useState } from "react";

import { DateTime } from "luxon";

import { useHistory } from "react-router-dom";

import { ReactComponent as GearIcon } from "assets/icons/gear.svg";

import { ConfirmActionModal, MenuDots, PlaceholderImage, TableWrapper } from "shared/Components";
import { TableColumnLoaderType } from "shared/Components/NewTable/Components";
import { Cell } from "shared/Components/NewTable/style";
import { useDebounce, useReportContent, useUser } from "shared/hooks";
import { PlaceholderImageType, ReportContentModel, ReportContentSortBy, ReportContentType } from "shared/types";
import { Icon, Select, Text, Tooltip } from "shared/ui-kit";
import { extractContentFromHTML, formatCount } from "utils/serviceUtils/helpers";

import { Header, StyledActionButton, StyledActionsWrapper } from "./style";

const Table = React.lazy(() => import("shared/Components/NewTable"));

import { PageTemplate, ReportedContentRulesModal } from "../../Components";
import { SelectWrapper } from "../ManageEvents/style";

interface ManageReportedContentProps {
	memberRoutes: any;
	messagePageUrl: string;
}

const ManageReportedContent: React.FC<ManageReportedContentProps> = ({ memberRoutes, messagePageUrl }) => {
	const history = useHistory();
	const {
		getReportsList,
		getReportsCount,
		deleteReport,
		deleteReportedContent,
		getData: getReportContentData
	} = useReportContent();
	const { loading, reports, totalCount } = getReportContentData();

	const { getData: getUserData } = useUser();
	const { user } = getUserData();

	const [selected, setSelected] = useState<number[]>([]);
	const [searchText, setSearchText] = useState("");
	const [confirmAction, setConfirmAction] = useState<{ show: boolean; isDeleteReport?: boolean; reportIds?: string[] }>(
		{
			show: false
		}
	);
	const [filters, setFilters] = useState({
		offset: 1,
		limit: 10,
		sortBy: ReportContentSortBy.TIMESTAMP,
		order: -1
	});
	const [refresh, setRefresh] = useState(0);
	const [showContentRulesModal, setShowContentRulesModal] = useState(false);

	const debouncedSearchText = useDebounce(searchText, 400);

	useEffect(() => {
		getReportsList({
			keyword: debouncedSearchText,
			...filters,
			order: filters.sortBy === ReportContentSortBy.CONTENT_TYPE ? 1 : -1
		});
	}, [getReportsList, debouncedSearchText, filters, refresh]);

	useEffect(() => {
		getReportsCount(debouncedSearchText);
	}, [getReportsCount, debouncedSearchText, refresh]);

	const handleSearch = useCallback((val: string) => {
		if (val === "" || val.length >= 3) {
			setSearchText(val);
		}
	}, []);

	const confirmReportAction = useCallback(
		async (isDeleteReport: boolean, reportIds?: string[]) => {
			setConfirmAction({ show: false });

			if (reportIds?.length) {
				if (isDeleteReport) {
					await deleteReport(reportIds);
				} else {
					await deleteReportedContent(reportIds);
				}
			}

			setRefresh(x => x + 1);
			setSelected([]);
		},
		[deleteReport, deleteReportedContent]
	);

	const getOptions = useCallback(
		(rowData: ReportContentModel) => {
			const options: {
				name: string;
				onClick: () => void;
			}[] = [
				// {
				// 	name: "Send warning", // TODO: let's enable when we have warning system
				// 	onClick: () => {}
				// },
				{
					name: "See Content Details",
					onClick: () => {
						switch (rowData.reportType) {
							case ReportContentType.PERSONA:
								history.push(`${memberRoutes?.member.profile.getPath()}/${rowData.reporteeMeta.creator?.personaId}`);
								break;

							case ReportContentType.EVENT:
								history.push(`${memberRoutes?.member.event.getPath()}/${rowData.reporteeId}`);
								break;

							case ReportContentType.POST:
								history.push(`${memberRoutes?.member.post.getPath()}/${rowData.reporteeId}`);
								break;

							case ReportContentType.GROUP:
								history.push(`${memberRoutes?.member.group.getPath()}/${rowData.reporteeId}`);
								break;

							case ReportContentType.USERFILE:
								history.push(`${memberRoutes?.member.file.getPath()}/${rowData.reporteeId}`);
								break;

							case ReportContentType.VIDEO:
								history.push(`${memberRoutes?.member.video.getPath()}/${rowData.reporteeId}`);
								break;

							case ReportContentType.TRACK:
								history.push(`${memberRoutes?.member.track.getPath()}/${rowData.reporteeId}`);
								break;

							case ReportContentType.ALBUM:
								history.push(`${memberRoutes?.member.album.getPath()}/${rowData.reporteeId}`);
								break;

							case ReportContentType.SERIES:
								history.push(`${memberRoutes?.member.collection.getPath()}/${rowData.reporteeId}`);
								break;
						}
					}
				}
			];

			if (
				rowData.reportType !== ReportContentType.PERSONA &&
				rowData.reporteeMeta?.creator?.personaId &&
				rowData.reporteeMeta.creator.personaId !== user?.activeProfile
			) {
				options.push({
					name: "Write a message to creator",
					onClick: () => {
						history.push(`${messagePageUrl}?messageTo=${rowData.reporteeMeta.creator!.personaId}`);
					}
				});
			}

			return options;
		},
		[history, memberRoutes, messagePageUrl, user?.activeProfile]
	);

	const getPlaceholderType = useCallback((reportType: ReportContentType) => {
		switch (reportType) {
			case ReportContentType.PERSONA:
				return PlaceholderImageType.PROFILE_IMAGE;

			case ReportContentType.EVENT:
				return PlaceholderImageType.EVENT_DETAILS;

			case ReportContentType.POST:
				return PlaceholderImageType.POST_PREVIEW;

			case ReportContentType.GROUP:
				return PlaceholderImageType.GROUP_DETAILS;

			case ReportContentType.USERFILE:
				return PlaceholderImageType.FILE_PREVIEW;

			case ReportContentType.VIDEO:
				return PlaceholderImageType.VIDEO_PREVIEW;

			case ReportContentType.TRACK:
				return PlaceholderImageType.TRACK_PREVIEW;

			case ReportContentType.ALBUM:
				return PlaceholderImageType.ALBUM_PREVIEW;

			default:
				return PlaceholderImageType.SERIES_PREVIEW;
		}
	}, []);

	const tableColumns = useMemo(
		() => [
			{
				minWidth: 240,
				alignment: "left",
				label: <Cell.HeaderText>{formatCount(totalCount, "Content")}</Cell.HeaderText>,
				Cell: ({ rowData: { reporteeMeta, reportType } }: { rowData: ReportContentModel }) => {
					const info =
						reportType === ReportContentType.POST ? extractContentFromHTML(reporteeMeta.name) : reporteeMeta.name;

					let imgUrl = reporteeMeta.artUrl || "";
					if (reportType === ReportContentType.POST) {
						const el = document.createElement("div");
						el.innerHTML = reporteeMeta.name;
						const img = el.querySelector("img");
						if (img?.src) {
							imgUrl = img.src;
						}
					}

					return (
						<Cell.Wrapper>
							<Cell.ImageWrapper>
								{imgUrl ? (
									<Cell.Image src={imgUrl} alt={info} />
								) : (
									<PlaceholderImage
										type={getPlaceholderType(reportType)}
										width={40}
										height={40}
										viewBox={"0 0 400 400"}
									/>
								)}
							</Cell.ImageWrapper>
							<Cell.Wrapper className="column with-image">
								<Cell.Text>{info}</Cell.Text>
								{reporteeMeta.creator?.firstName && (
									<Cell.Text className="light">
										by {`${reporteeMeta.creator?.firstName || ""} ${reporteeMeta.creator?.lastName || ""}`}
									</Cell.Text>
								)}
							</Cell.Wrapper>
						</Cell.Wrapper>
					);
				},
				loaderTemplate: TableColumnLoaderType.imageWthTwoTextRows,
				dataKey: "reporteeMeta"
			},
			{
				minWidth: 120,
				maxWidth: 120,
				alignment: "left",
				label: <Cell.HeaderText>Content type</Cell.HeaderText>,
				Cell: ({ rowData: { reportType } }: { rowData: ReportContentModel }) => (
					<Cell.Wrapper>
						<Cell.Text className={"capitalize"}>{reportType}</Cell.Text>
					</Cell.Wrapper>
				),
				dataKey: "reportType"
			},
			{
				minWidth: 150,
				maxWidth: 150,
				alignment: "left",
				label: <Cell.HeaderText>Reported Date</Cell.HeaderText>,
				Cell: ({ rowData: { createdAt } }: { rowData: ReportContentModel }) => (
					<Cell.Wrapper className="column with-image">
						<Cell.Text>{DateTime.fromISO(createdAt).toFormat("MMM d, yyyy")}</Cell.Text>
						<Cell.Text className="light">{DateTime.fromISO(createdAt).toFormat("t")}</Cell.Text>
					</Cell.Wrapper>
				),
				loaderTemplate: TableColumnLoaderType.twoTextRows,
				dataKey: "createdAt"
			},
			{
				minWidth: 120,
				maxWidth: 120,
				alignment: "left",
				label: <Cell.HeaderText># reported</Cell.HeaderText>,
				Cell: ({ rowData: { reportedCount } }: { rowData: ReportContentModel }) => (
					<Cell.Wrapper>
						<Cell.Text>{reportedCount || 0}</Cell.Text>
					</Cell.Wrapper>
				),
				dataKey: "reportedCount"
			},
			{
				minWidth: 150,
				alignment: "left",
				label: <Cell.HeaderText>Reason</Cell.HeaderText>,
				Cell: ({ rowData: { reason } }: { rowData: ReportContentModel }) => {
					const tooltipText = reason?.length > 1 ? reason.slice(1, reason.length).join(", ") : "";
					return (
						<Cell.Wrapper>
							<Cell.Text>
								{reason?.length ? reason[0] : "-"}
								<Tooltip text={tooltipText} enabled={reason?.length > 1} placement={"top"}>
									<Cell.Text className="pointer inline-flex">
										{reason?.length > 1 ? `, +${reason.length - 1}` : ""}
									</Cell.Text>
								</Tooltip>
							</Cell.Text>
						</Cell.Wrapper>
					);
				},
				dataKey: "reason"
			},
			{
				minWidth: 250,
				alignment: "left",
				label: <Cell.HeaderText>Remove / Keep in Community</Cell.HeaderText>,
				Cell: ({ rowData: { _id } }: { rowData: ReportContentModel }) => {
					return (
						<StyledActionsWrapper>
							<Tooltip text="Remove from community">
								<StyledActionButton
									removeSideMargin
									fullWidth
									size="medium"
									palette="danger"
									buttonTheme="light"
									leftIcon={<Icon fill={"#ff463d"} name={"trash-linear"} />}
									onClick={() =>
										setConfirmAction({
											show: true,
											isDeleteReport: false,
											reportIds: [_id]
										})
									}
								>
									Remove
								</StyledActionButton>
							</Tooltip>
							<Tooltip text="Keep in the community">
								<StyledActionButton
									removeSideMargin
									fullWidth
									size="medium"
									palette="success"
									buttonTheme="light"
									leftIcon={<Icon fill={"#23b34a"} name={"check-square"} />}
									onClick={() =>
										setConfirmAction({
											show: true,
											isDeleteReport: true,
											reportIds: [_id]
										})
									}
								>
									Keep
								</StyledActionButton>
							</Tooltip>
						</StyledActionsWrapper>
					);
				},
				dataKey: "actions"
			},
			{
				width: 50,
				alignment: "right",
				label: "",
				Cell: ({ rowData }) => (
					<MenuDots options={getOptions(rowData)} vertical removeBg removeshadow removeSideMargin />
				),
				dataKey: "menu"
			}
		],
		[getOptions, totalCount, getPlaceholderType]
	);

	const sortOptions = useMemo(
		() => [
			{
				value: ReportContentSortBy.CONTENT_TYPE,
				label: <Text>By Content Type</Text>
			},
			{
				value: ReportContentSortBy.TIMESTAMP,
				label: <Text>By Most Recent</Text>
			},
			{
				value: ReportContentSortBy.POPULAR,
				label: <Text>By Most Reported</Text>
			}
		],
		[]
	);

	const header = useMemo(
		() => (
			<Header>
				<Header.Column>
					<Header.Text>
						You can find here all the content that community members reported.
						<br />
						Set automatic rules when the content should be suspended or removed.
					</Header.Text>
				</Header.Column>
				<Header.Column className={"right"}>
					<Header.SettingsBox onClick={() => setShowContentRulesModal(true)}>
						<GearIcon />
					</Header.SettingsBox>
				</Header.Column>
			</Header>
		),
		[]
	);

	const CustomActions = useMemo(() => {
		if (selected.length) {
			return (
				<>
					<Tooltip text="Remove from community">
						<StyledActionButton
							className="square"
							removeSideMargin
							fullWidth
							size="medium"
							palette="danger"
							buttonTheme="light"
							square
							leftIcon={<Icon fill={"#ff463d"} name={"trash-linear"} />}
							onClick={() =>
								setConfirmAction({
									show: true,
									isDeleteReport: false,
									reportIds: reports.filter((x, i) => selected.indexOf(i) !== -1).map(x => x._id)
								})
							}
						/>
					</Tooltip>
					<Tooltip text="Keep in the community">
						<StyledActionButton
							className="square"
							removeSideMargin
							fullWidth
							size="medium"
							palette="success"
							buttonTheme="light"
							square
							leftIcon={<Icon fill={"#23b34a"} name={"check-square"} />}
							onClick={() =>
								setConfirmAction({
									show: true,
									isDeleteReport: true,
									reportIds: reports.filter((x, i) => selected.indexOf(i) !== -1).map(x => x._id)
								})
							}
						/>
					</Tooltip>
				</>
			);
		}
		return null;
	}, [reports, selected]);

	const extraItem = useMemo(
		() => (
			<SelectWrapper>
				<Select
					showInputLabel={{ show: true }}
					options={sortOptions}
					value={filters.sortBy}
					label={"Sort"}
					id={"report-content-type-select-id"}
					onChange={option => setFilters(f => ({ ...f, sortBy: option }))}
					showIcon
				/>
			</SelectWrapper>
		),
		[sortOptions, filters.sortBy]
	);

	const onToggleCheckRow = useCallback(
		({
			isChecked,
			toggledIndex,
			checkedRows
		}: {
			isChecked: boolean;
			toggledIndex?: number;
			checkedRows?: number[];
		}) => {
			if (toggledIndex !== undefined) {
				setSelected(selected => (isChecked ? [...selected, toggledIndex] : selected.filter(x => x !== toggledIndex)));
			} else if (checkedRows) {
				setSelected(isChecked ? checkedRows : []);
			}
		},
		[]
	);

	const TableBlock = useMemo(() => {
		const handleChangePage = (newPage: number) => {
			setFilters(f => ({ ...f, offset: newPage }));
		};

		const handleChangePageSize = (newPageSize: number) => {
			setFilters(f => ({ ...f, limit: newPageSize, offset: 1 }));
		};

		return (
			<TableWrapper sizes={{ horizontalTablet: 900 }}>
				<Table
					rowHeight={72}
					columns={tableColumns}
					data={reports}
					checkable
					onToggleCheckRow={onToggleCheckRow}
					loading={loading}
					paginated
					totalDataCount={totalCount}
					page={filters.offset}
					pageSize={filters.limit}
					onChangePage={handleChangePage}
					onChangePageSize={handleChangePageSize}
				/>
			</TableWrapper>
		);
	}, [tableColumns, loading, reports, totalCount, filters.offset, filters.limit, onToggleCheckRow]);

	return (
		<>
			<PageTemplate
				title={"Reported Content"}
				customHeader={header}
				isLoading={loading}
				isNoData={!reports.length && !loading}
				emptyText={"You don’t have any Reported Content in your community yet."}
				searchPlaceholder={"Search content"}
				onSearchUpdate={handleSearch}
				searchbarCustomActions={CustomActions}
				extraAction={extraItem}
			>
				{TableBlock}
			</PageTemplate>
			<ConfirmActionModal
				open={confirmAction.show}
				title={`${confirmAction.isDeleteReport ? "Keep Content?" : "Remove Content?"}`}
				bodyText={`Are you sure you want to ${
					confirmAction.isDeleteReport
						? "keep this content in community?"
						: "permanently delete this content from community?"
				}`}
				onClose={() => setConfirmAction({ show: false })}
				onConfirm={() => confirmReportAction(!!confirmAction?.isDeleteReport, confirmAction.reportIds)}
			/>
			<ReportedContentRulesModal open={showContentRulesModal} onClose={() => setShowContentRulesModal(false)} />
		</>
	);
};

export default ManageReportedContent;
