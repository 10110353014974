const primary = {
	100: "#d9ddfe",
	200: "#b4bbfe",
	300: "#8e97fc",
	400: "#717cfa",
	500: "#4350f7",
	600: "#303bd4",
	700: "#2129b1",
	800: "#151b8f",
	900: "#0c1176"
};

export default primary;
