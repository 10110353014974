import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { Box } from "@material-ui/core";

import { ReactComponent as IconPointShield } from "assets/icons/point-shield.svg";
import { useLoyaltyRewards } from "modules/LoyaltyRewards/Data/hooks";

import { Dialog } from "shared/Components";
import { useOnScreen } from "shared/hooks";
import { Receipt } from "shared/types/TransactionModel";
import { Button, Text } from "shared/ui-kit";

import Award from "./HistoryItems/Award";
import Converted from "./HistoryItems/Converted";
import Referred from "./HistoryItems/Referred";
import SkeletonItem from "./HistoryItems/Skeleton";
import Welcome from "./HistoryItems/Welcome";
import { CellWrapper, ModalBody, RewardHistoryWrapper, RewardsHistoryList } from "./style";

const LoadingSkeletons = () => (
	<>
		<SkeletonItem />
		<SkeletonItem />
		<SkeletonItem />
		<SkeletonItem />
		<SkeletonItem />
	</>
);

const RewardHistoryDialog = () => {
	const {
		getMemberRewardHistoryCount,
		setHistoryDialog,
		setAwardDialog,
		getMemberRewardHistory,
		getData: getLoyaltyData
	} = useLoyaltyRewards();
	const { historyCount, historyDialog, loading } = getLoyaltyData();

	const { member, history } = historyDialog;

	const isPremium = useMemo(() => (member?.subscriptionType === "PREMIUM" ? true : false), [member]);

	const [loadData, setLoadData] = useState(true);
	const [offset, setOffset] = useState(1);
	const lastItemRef = useRef<HTMLDivElement>(null);
	const onScreen = useOnScreen(lastItemRef);

	const hasMoreItems = useMemo(() => historyCount > (history ? history.length : 0), [history, historyCount]);

	useEffect(() => {
		if (onScreen && hasMoreItems) {
			setOffset(state => state + 1);
			setLoadData(true);
		}
	}, [hasMoreItems, onScreen]);

	useEffect(() => {
		if (member) {
			getMemberRewardHistoryCount({ personaId: member.personaId });
		}
	}, [getMemberRewardHistoryCount, member]);

	useEffect(() => {
		if (member && loadData) {
			getMemberRewardHistory({ personaId: member.personaId, page: offset }).then(() => {
				setLoadData(false);
			});
		}
	}, [getMemberRewardHistory, loadData, member, offset]);

	const handleClose = () => {
		setHistoryDialog({
			open: false,
			member: null,
			history: null
		});
	};

	const handleAwardPoints = () => {
		if (member) {
			setAwardDialog({
				open: true,
				members: [
					{
						label: member.firstName,
						value: member.personaId,
						profilePicture: member?.photos[0].profilePicture
					}
				]
			});
			handleClose();
		}
	};

	const renderComponent = useCallback((h: Receipt) => {
		switch (h.transactionType) {
			case "award":
				return <Award item={h} />;
			case "convert":
				return <Converted item={h} />;
			case "refer":
				return <Referred item={h} />;
			default:
				return <Welcome item={h} />;
		}
	}, []);

	return (
		<Dialog
			title="Reward History"
			open={true}
			footer={<Button onClick={handleAwardPoints}>Award Points</Button>}
			onClose={handleClose}
		>
			<ModalBody noFlex noMb>
				<RewardHistoryWrapper>
					<CellWrapper>
						<CellWrapper.RewardInfo largeImg noFlexOnMobile>
							<img src={member?.photos[0].profilePicture} alt={member?.firstName} />
							<CellWrapper.InfoWrapper>
								<Text variant="h6" className="member-name">
									{member?.firstName} {member?.lastName}
								</Text>
								<Box className={`chip ${isPremium ? "premium" : "free"}`}>{isPremium ? "Premium" : "Free"}</Box>
								<RewardHistoryWrapper.PointsWrapper>
									<RewardHistoryWrapper.PointBox>
										<Text>All Time Points</Text>
										<CellWrapper.PointsAmount>
											<Text variant="subtitle1">{member?.loyaltyAllTimePoints}</Text>
											<Box className="shield-box">
												<IconPointShield />
											</Box>
										</CellWrapper.PointsAmount>
									</RewardHistoryWrapper.PointBox>
									<RewardHistoryWrapper.PointBox>
										<Text>Current Balance</Text>
										<CellWrapper.PointsAmount>
											<Text variant="subtitle1">{member?.loyaltyCurrentBalance}</Text>
											<Box className="shield-box">
												<IconPointShield />
											</Box>
										</CellWrapper.PointsAmount>
									</RewardHistoryWrapper.PointBox>
								</RewardHistoryWrapper.PointsWrapper>
							</CellWrapper.InfoWrapper>
						</CellWrapper.RewardInfo>
					</CellWrapper>
				</RewardHistoryWrapper>
				<RewardsHistoryList>
					{loading && !history?.length ? (
						<LoadingSkeletons />
					) : (
						history?.map((h, i) => (
							<RewardsHistoryList.ListItem ref={i + 1 === history.length ? lastItemRef : null} key={i}>
								{renderComponent(h)}
							</RewardsHistoryList.ListItem>
						))
					)}
					{loading && offset > 1 && <LoadingSkeletons />}
				</RewardsHistoryList>
			</ModalBody>
		</Dialog>
	);
};

export default RewardHistoryDialog;
