import { Box, ButtonBase } from "@material-ui/core";
import styled from "styled-components";

import { Button } from "shared/ui-kit";

export const Header = styled(Box)`
	border-radius: 12px;
	box-shadow: 0 2px 4px 0 rgb(0 0 0 / 8%), 0 0.5px 0 #c5cee0;
	background-color: #fff;
	margin-bottom: 16px;
	${props => props.theme.breakpoints.up("sm")} {
		margin-bottom: 0;
	}
`;

Header.Cover = styled(Box)`
	position: relative;
	img {
		width: 100%;
		max-height: 160px;
		object-fit: cover;
		border-top-left-radius: 12px;
		border-top-right-radius: 12px;
	}
	.cover-placeholder {
		width: 100%;
		height: 160px;
		background-color: gray;

		border-top-left-radius: 12px;
		border-top-right-radius: 12px;

		overflow: hidden;
	}

	${props => props.theme.breakpoints.up("sm")} {
		img {
			max-height: 400px;
		}
		.cover-placeholder {
			height: 400px;
			overflow: hidden;
		}
	}
`;

Header.EditButton = styled(ButtonBase)`
	position: absolute;
	bottom: 16px;
	right: 16px;
	width: 40px;
	height: 40px;
	border-radius: 8px;
	backdrop-filter: blur(20px);
	border: 1px solid #fff;
	background-color: rgba(0, 0, 0, 0.25);
	> div {
		display: flex;
	}
	.MuiCircularProgress-colorSecondary {
		color: white;
	}
`;

Header.Content = styled(Box)`
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-bottom: 24px;

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	h2 {
		margin: 0;
		margin-top: 20px;
		font-size: 26px;
		font-weight: bold;
		line-height: 1.23;
		color: #222b45;
	}
	h6 {
		margin: 0;
		margin-top: 8px;
		font-size: 15px;
		line-height: 1.33;
		color: #222b45;
	}
	.bio {
		margin: 0;
		margin-top: 8px;
		font-size: 13px;
		line-height: 1.23;
		color: #8f9bb3;
	}
`;

Header.ContentImageWrapper = styled(Box)`
	width: 160px;
	height: 160px;

	border: solid 4px #fff;

	background-color: white;

	border-radius: 99%;

	margin-top: ${({ noCover }) => (noCover ? "24px" : "-80px")};

	overflow: hidden;
`;

Header.Actions = styled(Box)`
	display: none;
	${props => props.theme.breakpoints.up("sm")} {
		position: absolute;
		top: 16px;
		right: 16px;
		display: flex;
		align-items: center;
		button {
			margin: 0;
			margin-left: 8px;
		}
	}
`;

export const MainActionButton = styled(Button)`
	border-radius: 8px;
	&:hover {
		color: #000000;
	}
	svg {
		margin-right: 6px;
	}
`;

Header.ButtonActions = styled(Box)`
	display: flex;
	align-items: center;
	margin-top: 20px;
	button {
		width: 140px;
		height: 40px;
		margin: 0 4px;
		border-radius: 8px;
		svg {
			width: 20px;
			height: 20px;
		}
		p {
			font-size: 14px;
			font-weight: bold;
			margin-left: 4px;
		}
	}
`;

Header.Private = styled(Box)`
	border-radius: 8px;
	background-color: #f7f9fc;
	text-align: center;
	width: calc(100% - 32px);
	padding: 36px 0 44px;
	margin-top: 24px;
	svg {
		fill: ${({ color }) => color};
	}
	h3 {
		font-size: 15px;
		font-weight: 600;
		color: #222b45;
		margin: 8px 0;
	}
	p {
		font-size: 15px;
		color: #222b45;
	}
`;
