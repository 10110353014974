import React from "react";

import { useUser } from "shared/hooks";

import { AddProfileInfoDialog } from "..";

const AddLanguagesDialog = () => {
	const { setAddLanguagesDialog, getData: getUserData } = useUser();
	const { languagesSuggestions } = getUserData();

	return (
		<AddProfileInfoDialog
			title="Languages"
			handleClose={() => setAddLanguagesDialog(false)}
			list={languagesSuggestions}
			updateType="languages"
		/>
	);
};

export default AddLanguagesDialog;
