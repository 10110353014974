import React, { useEffect, useState } from "react";

import { Box, InputAdornment, TextField } from "@material-ui/core";
import GroupIcon from "@material-ui/icons/Group";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { DatePicker } from "@material-ui/pickers";

import { DateTime } from "luxon";

import { Controller, useForm } from "react-hook-form";

import { ReactComponent as CalendarIcon } from "assets/icons/calendar-filled.svg";
import { INVITE } from "modules/Members/constants";

import useConfirmLeavePopup from "shared/Components/ConfirmLeave/hooks/useConfirmLeavePopup";
import { useDebounce, useMembers } from "shared/hooks";
import { Avatar, Icon, Input, Switch } from "shared/ui-kit";

import {
	Divider,
	GenarateLinkButton,
	IncreaseNumberButton,
	RefereeInputWithImage,
	ShareableLinkColumn,
	ShareableLinkItemRow,
	ShareableLinkTitle,
	StyledOption,
	StyledOptionsWrapper
} from "./style";

import { FooterWrapper, InviteInput, InviteInputsRow, InviteMemberActionText, StyledFormColumn } from "../style";

const ShareableLink = () => {
	const {
		setModalState,
		setIsInvited,
		getUserSearch,
		getShareableLink,
		updateShareableLink,
		setPersonasEmpty,
		setLinksEmpty,
		getData: getMembersData
	} = useMembers();
	const { viewType, personas: members, editableModel, noUsersFound } = getMembersData();

	const { control, reset, handleSubmit, watch, register, errors, formState } = useForm({
		mode: "onChange"
	});

	const {
		isExpirable,
		hasLimitedAttendees,
		personaDocId
	}: {
		isExpirable: boolean;
		hasLimitedAttendees: boolean;
		personaDocId: string | undefined;
	} = watch(["isExpirable", "hasLimitedAttendees", "personaDocId"]);

	const isSharableLinkView = viewType === INVITE.SHAREABLE_LINK;
	const modalContent = isSharableLinkView ? INVITE.SHAREABLE_LINK_LIST : INVITE.QR_CODE_LIST;
	const modalTitle = isSharableLinkView ? INVITE.SHAREABLE_LINK_LIST_TITLE : INVITE.QR_CODE_LIST_TITLE;

	const [keyword, setKeyword] = useState<string>("");
	const debouncedKeyword = useDebounce(keyword, 400);
	const handleSearch = e => {
		const keyword = e.target.value;
		setKeyword(keyword);
	};

	const onSearchTextChange = (onChange, newValue) => {
		newValue != null ? onChange(newValue?.id ? newValue.id : null) : setPersonasEmpty();
	};

	const removeEmptyFields = data => {
		Object.keys(data).forEach(key => {
			if (data[key] === "") {
				delete data[key];
			}
		});
	};

	useEffect(() => {
		if (editableModel) {
			reset({
				expireAt: editableModel.expireAt,
				totalRedemption: editableModel.totalRedemption,
				personaDocId: editableModel._id
			});
		}
	}, [editableModel, reset]);

	useEffect(() => {
		if (debouncedKeyword !== "") {
			getUserSearch({ value: debouncedKeyword });
		}
	}, [debouncedKeyword, getUserSearch]);

	const onSubmit = async data => {
		const typeData = {
			...(editableModel && { invitationId: editableModel._id, active: editableModel.active }),
			...(!editableModel && { personaDocId: data.personaDocId, purpose: "shareablelink email", type: viewType }),
			totalRedemption: data.totalRedemption && hasLimitedAttendees ? data.totalRedemption : 0,
			expireAt: data.expireAt && isExpirable ? DateTime.fromJSDate(new Date(data.expireAt)).toISODate() : null
		};
		removeEmptyFields(typeData);
		if (editableModel) {
			await updateShareableLink(typeData);
		} else {
			await getShareableLink(typeData);
		}
		await setLinksEmpty();
		setPersonasEmpty();
		setModalState({ isOpen: true, modalContent, modalTitle, viewType });
		setIsInvited(false);
	};

	const { provideHandleCloseValues } = useConfirmLeavePopup({});

	useEffect(() => {
		provideHandleCloseValues(isExpirable || hasLimitedAttendees || (!!personaDocId && personaDocId.length > 0));
	}, [isExpirable, hasLimitedAttendees, personaDocId, provideHandleCloseValues]);

	const findUserProfilePicture = (id: string) => {
		const user = members.find(m => m._id === id);
		return user?.photos?.length ? user?.photos[0]?.profilePicture : "";
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<InviteInputsRow>
				<InviteInput className="expiration">
					<ShareableLinkItemRow>
						<ShareableLinkTitle>Expiration Date {isExpirable && "*"}</ShareableLinkTitle>
						<Switch name="isExpirable" inputRef={register} defaultChecked={!!editableModel?.expireAt} />
					</ShareableLinkItemRow>
					{isExpirable && (
						<ShareableLinkColumn>
							<Controller
								name="expireAt"
								rules={{ required: "Expiration date is required" }}
								control={control}
								render={({ onChange, value }) => (
									<DatePicker
										inputVariant="outlined"
										emptyLabel="Pick a date"
										format={"MM/dd/yyyy"}
										variant="inline"
										value={value || null}
										minDate={new Date()}
										InputProps={{
											readOnly: true,
											startAdornment: (
												<InputAdornment position="start">
													<CalendarIcon fill="#8f9bb3" />
												</InputAdornment>
											)
										}}
										onChange={d => onChange(d)}
										// open={true}
										autoOk
									/>
								)}
							/>
						</ShareableLinkColumn>
					)}
				</InviteInput>
				<InviteInput className="usenum">
					<ShareableLinkItemRow>
						<ShareableLinkTitle># of use {hasLimitedAttendees && "*"}</ShareableLinkTitle>
						<Switch name="hasLimitedAttendees" inputRef={register} defaultChecked={!!editableModel?.totalRedemption} />
					</ShareableLinkItemRow>
					{hasLimitedAttendees && (
						<ShareableLinkColumn>
							<Controller
								name="totalRedemption"
								control={control}
								rules={{
									required: "# of use is required",
									validate: value => !isNaN(value) && Number(value) > 0
								}}
								defaultValue={1}
								render={({ onChange, value }) => (
									<>
										<IncreaseNumberButton onClick={() => onChange(value + 1)} buttonTheme="light" palette="control">
											<Icon name="chevron-up" group="filled" width={16} height={16} fill="#8f9bb3" />
										</IncreaseNumberButton>
										<Input
											type="number"
											name="totalRedemption"
											errorText={errors["totalRedemption"]?.message}
											placeholder=""
											leftIcon={<GroupIcon />}
											variant="outlined"
											value={value}
											onChange={e => {
												onChange(e.target.value.replace(/^[\D0]/g, "").slice(0, 6));
											}}
										/>
										<IncreaseNumberButton
											onClick={() => value > 1 && onChange(value - 1)}
											down
											buttonTheme="light"
											palette="control"
										>
											<Icon name="chevron-up" group="filled" width={16} height={16} fill="#8f9bb3" />
										</IncreaseNumberButton>
									</>
								)}
							/>
						</ShareableLinkColumn>
					)}
				</InviteInput>
			</InviteInputsRow>
			<InviteInputsRow mt>
				<InviteInput>
					<StyledFormColumn noMt>
						<InviteMemberActionText>Assign Referee *</InviteMemberActionText>
					</StyledFormColumn>
					<Controller
						name="personaDocId"
						variant="outlined"
						rules={{
							required: true
						}}
						control={control}
						render={({ onChange, value }) => (
							<Autocomplete
								id="combo-box-demo"
								popupIcon={""}
								freeSolo
								disabled={!!editableModel}
								options={members.map(m => ({
									name: `${m.firstName} ${m.lastName}`,
									id: m._id,
									profileImage: m.photos[0]?.profilePicture || ""
								}))}
								getOptionLabel={option => option.name}
								onChange={(event, newValue) => onSearchTextChange(onChange, newValue)}
								defaultValue={
									editableModel?.assignee
										? {
												name: `${editableModel?.assignee?.firstName} ${editableModel?.assignee?.lastName}`,
												id: editableModel?._id,
												profileImage: !!editableModel?.assignee.photos.length
													? editableModel?.assignee.photos[0].profilePicture
													: ""
										  }
										: null
								}
								renderOption={props => (
									<StyledOptionsWrapper component="li" {...props}>
										<Avatar
											height={24}
											image={props?.profileImage || ""}
											imageAlt="Image"
											palette="info"
											title={props.name || ""}
											type="Avatar"
											variant="circle"
											avatarStyle={"border-radius:12px"}
											width={24}
										/>
										<StyledOption>{props.name}</StyledOption>
									</StyledOptionsWrapper>
								)}
								renderInput={params => {
									const img = findUserProfilePicture(value);

									return (
										<RefereeInputWithImage withImg={!!img}>
											{img && <img src={img} alt={value} />}
											<TextField
												{...params}
												required
												onChange={handleSearch}
												placeholder="Type to search in members..."
												variant="outlined"
												helperText={noUsersFound && "No members found"}
												error={noUsersFound}
											/>
										</RefereeInputWithImage>
									);
								}}
							/>
						)}
					/>
				</InviteInput>
			</InviteInputsRow>
			<Box marginTop="12px">
				<Divider />
			</Box>
			<FooterWrapper className="full">
				<GenarateLinkButton
					disabled={!formState.isValid || !formState.isDirty || formState.isSubmitting}
					removeSideMargin
					size="medium"
					palette="primary"
					buttonTheme="main"
					type="submit"
				>
					{!!editableModel ? INVITE.SAVE_CHANGES : isSharableLinkView ? INVITE.GENERATE_LINK : INVITE.GENERATE_QR}
				</GenarateLinkButton>
			</FooterWrapper>
		</form>
	);
};

export default ShareableLink;
