import React from "react";

import styled, { css } from "styled-components";

import { Box } from "shared/ui-kit";

interface FrameCircleProps {
	color?: string;
	size?: number;
	customStyle?: string;
}

const FrameCircleWrapper = styled(Box)<FrameCircleProps>`
	width: ${({ size }) => size || 21}px;
	height: ${({ size }) => size || 21}px;

	border-radius: 50%;

	overflow: hidden;

	background: ${({ color }) => color || "#e4e9f2"};

	${({ customStyle }) => {
		return customStyle
			? css`
					${customStyle}
			  `
			: "";
	}}
`;

const FrameCircle: React.FC<FrameCircleProps> = (props: FrameCircleProps): JSX.Element => {
	return <FrameCircleWrapper {...props} />;
};

export default FrameCircle;
