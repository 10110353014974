import React from "react";

import { GroupCard } from "modules/MemberHome/View/Components";
import { RelativeWrapper } from "shared/styles";
import { GroupModel } from "shared/types";

import Actions from "./Actions";

import Reactions from "./Reactions";

import { RenderActionType } from "..";
import { SecondaryText } from "../../../../../MemberHome/View/Components/EventDetails/styles";

const GroupBubble = props => (
	<RelativeWrapper data-message={props.currentMessage.group._id}>
		<SecondaryText className="my-2 text-xs font-semibold">
			{props.isCurrentUser ? "You" : props.currentMessage.user.name} shared a group
		</SecondaryText>
		<GroupCard
			group={props.currentMessage?.group as GroupModel}
			index={0}
			isPotentialMatches
			wrapByHref
			chatThumbnail
			messageBlockCurrentUser={props.messageBlockCurrentUser}
		/>
		{props.currentMessage?.group.recentLikes && (
			<Reactions list={props.currentMessage?.group.recentLikes} isCurrentUser={props.isCurrentUser} />
		)}
		<Actions
			replyItem={{
				id: props.currentMessage.group._id,
				text: "group"
			}}
			isCurrentUser={props.isCurrentUser}
			onDelete={props.onDelete}
			onEdit={props.onEdit}
			onForward={props.onForward}
			onReply={props.onReply}
			onLike={props.onLike}
			currentMessage={props.currentMessage}
			type={RenderActionType.GROUP}
		/>
	</RelativeWrapper>
);

export default GroupBubble;
