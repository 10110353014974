import React from "react";

interface Props {
	className?: string;
	type: React.HTMLInputTypeAttribute;
	placeholder?: string;
	onChange?: (e) => void;
	value?: string | number;
}

export const NumericInput: React.FC<Props> = ({ className, type = "text", placeholder, onChange, value }) => {
	const ref = React.useRef<HTMLInputElement>(null);

	React.useEffect(() => {
		const element = ref.current;

		const handleInput = e => {
			const event = e as React.ChangeEvent<HTMLInputElement>;
			const newValue = event.target.value.replace(/[^0-9]/g, "");
			event.target.value = newValue;
			if (onChange) {
				onChange(event);
			}
		};

		if (element) {
			element.addEventListener("input", handleInput);
		}

		return () => {
			if (element) {
				element.removeEventListener("input", handleInput);
			}
		};
	}, [onChange]);

	return <input className={className} type={type} placeholder={placeholder} ref={ref} value={value} />;
};
