const text = "Invalid Phone Number, Please provide a valid phone number";

export default ({ schema: { type, minOcc } }) =>
	type === "phone" && [
		"validate",
		value => {
			const re = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im;
			if (minOcc === 0 && value.length === 0) {
				return true;
			}
			return re.test(String(value).toLowerCase()) ? true : text;
		}
	];
