import React, { useCallback, useEffect, useMemo, useState } from "react";

import { Scrollbars } from "react-custom-scrollbars";

import { useFirebaseLiveConversation, useLiveConversation } from "modules/LiveConversation/Data/hooks";
import { ConversationWithEvent } from "modules/LiveConversation/Data/types";
import { useFirebase } from "modules/Messaging/Data";
import { useCommunity, useUser } from "shared/hooks";

import ConversationCard from "./ConversationCard";
import { JoinBtn, StartBox, Wrapper } from "./style";

const LiveConvoWidgetPartial = () => {
	const [firebaseInitialized, setFirebaseInitialized] = useState<boolean>(false);
	const { watchLiveConvos, onJoinConversation, startNewLiveConvo } = useFirebaseLiveConversation();
	const { getData: getCommunityData } = useCommunity();
	const { workspace } = getCommunityData();
	const { getActiveProfile, getData: getUserData } = useUser();
	const { user } = getUserData();
	const { authenticateUser } = useFirebase();

	const { getData: getLiveConversationData } = useLiveConversation();
	const { ongoingLiveConvos } = getLiveConversationData();

	const activeProfile = useMemo(() => getActiveProfile(user), [getActiveProfile, user]);
	const personaId = activeProfile?.personaId;

	const refreshList = useCallback(() => {
		setFirebaseInitialized(false);
		setFirebaseInitialized(true);
	}, []);

	const renderConversation = useCallback(
		(conversation: ConversationWithEvent, index) => (
			<ConversationCard
				key={`convo-${index}`}
				conversation={conversation}
				joinConversation={onJoinConversation}
				doListRefresh={refreshList}
			/>
		),
		[onJoinConversation, refreshList]
	);

	useEffect(() => {
		if (!firebaseInitialized && personaId && workspace) {
			authenticateUser(personaId, workspace).then(() => {
				setFirebaseInitialized(true);
			});
		}
	}, [personaId, workspace, authenticateUser, firebaseInitialized]);

	useEffect(() => {
		if (firebaseInitialized) {
			return watchLiveConvos();
		}
	}, [watchLiveConvos, firebaseInitialized]);

	if (!ongoingLiveConvos?.length) return null;

	return (
		<Scrollbars style={{ height: 170 }}>
			<Wrapper>
				<StartBox height="100vh">
					Live Streaming
					<JoinBtn onClick={startNewLiveConvo}>Start</JoinBtn>
				</StartBox>
				{ongoingLiveConvos.map(renderConversation)}
			</Wrapper>
		</Scrollbars>
	);
};

export default LiveConvoWidgetPartial;
