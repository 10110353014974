import React, { useCallback, useEffect, useMemo, useState } from "react";

import { useForm } from "react-hook-form";

import { useAlbums, useCommunity, useNotification, useSeries, useTrack, useUser } from "shared/hooks";
import { useS3Uploader } from "shared/services/s3Uploader";
import { ToolbarActions, TrackModel } from "shared/types";
import { dataUrlToFile } from "utils/serviceUtils/helpers";

import CreateTrackManageView from "./ManageView";
import CreateTrackMemberView from "./MemberView";

interface Props {
	onClose: (event?: { created?: boolean }, reason?: string) => void;
	editableModel?: Partial<TrackModel>;
	open: boolean;
	album?: {
		value: string;
		label: string;
	};
	seriesView?: boolean;
	onSelect?: (content: any) => void;
	groupId?: string;
	eventId?: string;
}

const CreateTrack: React.FC<Props> = ({
	open,
	onClose,
	album,
	seriesView,
	editableModel,
	onSelect,
	groupId,
	eventId
}) => {
	const { uploadFile } = useS3Uploader();
	const { showMessage } = useNotification();

	const { updateTrack, createTrack, getData: getTrackData, setFilteredTracks } = useTrack();
	const { trackFileUrl, trackUploadUrl, trackDuration, filteredTracks } = getTrackData();

	const {
		addTrackToAlbum,
		removeTrackFromAlbum,
		setAlbumDetailsDialog,
		setTracksChanged,
		getData: getAlbumsData
	} = useAlbums();
	const { albumDetailsDialog } = getAlbumsData();

	const { setSeriesDetailsDialog, getData: getSeriesData } = useSeries();
	const { seriesDetailsDialog } = getSeriesData();

	const { getData: getCommunityData } = useCommunity();
	const { isFree } = getCommunityData();

	const { getData: getUserData } = useUser();
	const { isMemberView } = getUserData();

	const { errors } = useForm({
		mode: "onChange"
	});
	const [uploading, setUploading] = useState(false);

	const editModel = useMemo(() => {
		const modelInfo = editableModel ? { ...editableModel } : undefined;
		if (modelInfo?.publishDate && new Date(modelInfo.publishDate) < new Date()) {
			modelInfo.publishDate = undefined;
		}
		return modelInfo;
	}, [editableModel]);

	useEffect(() => {
		if (Object.keys(errors).length) {
			showMessage(
				<ul>
					{Object.values(errors).map((p, i) => (
						<li key={i}>{p.message}</li>
					))}
				</ul>,
				3
			);
		}
	}, [errors, showMessage]);

	const tracks = useMemo(() => (!!filteredTracks?.length ? filteredTracks : []), [filteredTracks]);

	const addTrackToAlbumHandler = useCallback(
		(albums: { label: string; value: string }[], trackId: string) => {
			albums.map(obj => {
				addTrackToAlbum(obj, trackId);
			});
		},
		[addTrackToAlbum]
	);

	const removeTrackFromAlbumHandler = useCallback(
		(albums: { value: string }[], trackId: string) => {
			albums.map(obj => {
				removeTrackFromAlbum(obj, trackId);
			});
		},
		[removeTrackFromAlbum]
	);

	const handleUpdateTrack = async data => {
		const track = await updateTrack({ ...editModel, ...data, trackFileUrl });
		const trackId = track._id;
		const { album } = data;
		const albumLength = album?.length || 0;

		const editAlbum = !!editModel?.parentAlbums?.length
			? editModel.parentAlbums.map(obj => ({ value: obj._id }))
			: undefined;

		if (!!albumLength && trackId) {
			if (editAlbum) {
				const tracksToBeRemoved = editAlbum.filter(item => !album.some(o => o.value === item.value));
				!!tracksToBeRemoved.length && removeTrackFromAlbumHandler(tracksToBeRemoved, trackId);

				addTrackToAlbumHandler(album, trackId);
			} else addTrackToAlbumHandler(album, trackId);
		} else if (!albumLength && trackId && editAlbum) removeTrackFromAlbumHandler(editAlbum, trackId);

		setFilteredTracks(
			tracks.map(item =>
				item._id === trackId
					? {
							...track,
							parentAlbums: !!albumLength ? album.map(obj => ({ _id: obj.value, title: obj.label })) : []
					  }
					: item
			)
		);
	};

	const handleCreateTrack = async data => {
		const { album } = data;
		const albumLength = album?.length || 0;

		const track = await createTrack({
			preview: data.preview || { start: 0, end: 30000 },
			...data,
			trackFileUrl,
			duration: trackDuration,
			groupId,
			eventId
		});

		if (!isMemberView) {
			setFilteredTracks([
				{
					...track,
					parentAlbums: !!albumLength ? album.map(obj => ({ _id: obj.value, title: obj.label })) : []
				},
				...tracks
			]);
		}

		if (album && track._id) {
			if (!!albumLength) addTrackToAlbumHandler(album, track._id);

			if (albumDetailsDialog.data) {
				setAlbumDetailsDialog({
					...albumDetailsDialog,
					data: {
						...albumDetailsDialog.data,
						musics: [...albumDetailsDialog.data.musics, track]
					}
				});
				setTracksChanged(true);
			}
		}

		if (seriesView && seriesDetailsDialog && seriesDetailsDialog.data) {
			setSeriesDetailsDialog({
				addContent: true,
				shouldUpdate: true,
				data: {
					...seriesDetailsDialog.data,
					itemIds: [{ _id: track._id, type: "TRACK", music: track, new: true }, ...seriesDetailsDialog.data.itemIds]
				}
			});
		}

		return track;
	};

	const onSubmit = async data => {
		const track = editModel ? await handleUpdateTrack(data) : await handleCreateTrack(data);

		onClose &&
			onClose.apply(this, isMemberView ? [{ created: true }, ToolbarActions.SELECT_TRACK] : [undefined, undefined]);

		onSelect && onSelect(track);
	};

	const onUploaderChange = useCallback(
		async (files: FileList, onChange: (...event: any[]) => void) => {
			const img = files && files.length ? files[0] : null;

			const correctFile = typeof img === "string" ? await dataUrlToFile(img, "test") : img;
			if (img) {
				setUploading(true);
				const data = await uploadFile({
					file: correctFile as File,
					communityName: trackUploadUrl
				});
				onChange(data?.publicUrl);
				setUploading(false);
			} else {
				onChange(null);
			}
		},
		[trackUploadUrl, uploadFile]
	);

	if (isMemberView) {
		return (
			<CreateTrackMemberView
				open={open}
				submitTrack={onSubmit}
				onClose={onClose}
				onUploaderChange={onUploaderChange}
				uploading={uploading}
				editableModel={editModel}
				album={album}
				trackFileUrl={trackFileUrl}
			/>
		);
	}

	return (
		<CreateTrackManageView
			open={open}
			submitTrack={onSubmit}
			onClose={onClose}
			editableModel={editModel}
			onUploaderChange={onUploaderChange}
			isFreeCommunity={isFree}
		/>
	);
};

export default CreateTrack;
