import React, { FC } from "react";

import { Text } from "shared/ui-kit";

import { LoadingTracksWrapper } from "./style";

const NoMoreTracks: FC<{ series?: boolean }> = ({ series }) => {
	return (
		<LoadingTracksWrapper>
			<Text variant="body2">
				No more {series ? "series" : "tracks"} left, {"you're all caught up."}{" "}
				<span role="img" aria-label="Party popper">
					🎉
				</span>
			</Text>
		</LoadingTracksWrapper>
	);
};

export default NoMoreTracks;
