import React, { FC, useCallback, useMemo } from "react";

import { Box, IconButton, useMediaQuery } from "@material-ui/core";

import clsx from "clsx";

import { ConfirmMemberLeave } from "shared/Components";
import useConfirmLeavePopup from "shared/Components/ConfirmLeave/hooks/useConfirmLeavePopup";

import { useCommunity } from "shared/hooks";
import { Button, Icon, InPlaceModal, Loader, Text } from "shared/ui-kit";
import * as appTheme from "theme/default";

import {
	DialogContent,
	DialogContentWithoutScroll,
	DialogFooter,
	DialogHeader,
	LoadingWrapper,
	RefBox,
	ScrollBarWrapper,
	SecondaryButton
} from "./style";

import { DialogOverlay } from "..";

interface Props {
	open: boolean;
	title: string;
	onClose: (...any) => void;
	goBack?: () => void;
	footerPrimary?: {
		text: string;
		disabled?: boolean;
		loading?: boolean;
		small?: boolean;
		onClick?: () => void;
		id?: string;
		footerTheme?: string;
		footerPlatte?: string;
	};
	footerSecondary?: {
		text: string;
		onClick: () => void;
		disabled?: boolean;
		loading?: boolean;
		ghost?: boolean;
		small?: boolean;
		grayBackground?: boolean;
	};
	customWidth?: number;
	confirmLeave?: boolean;
	modalHeight?: number;
	noHeader?: boolean;
	noFooter?: boolean;
	footerNote?: string;
	loading?: boolean;
	modalRef?: React.RefObject<HTMLDivElement>;
	contentRef?: React.RefObject<HTMLDivElement>;
	uploadingProgress?: number;
	footerLeftContent?: JSX.Element;
	isXsFooterFlex?: boolean;
	isScrollable?: boolean;
}

const MemberDialog: FC<Props> = ({
	children,
	open,
	customWidth = 400,
	confirmLeave,
	modalHeight = 600,
	title,
	onClose,
	goBack,
	footerPrimary,
	footerSecondary,
	noHeader,
	noFooter,
	footerNote,
	loading,
	modalRef,
	contentRef,
	uploadingProgress,
	footerLeftContent,
	isXsFooterFlex = false,
	isScrollable = true
}) => {
	const {
		handleLeavePageConfirmed,
		closeConfirmPopup,
		getData: getConfirmLeavePopupData,
		handleClose
	} = useConfirmLeavePopup({
		open,
		onClose
	});

	const { showConfirmPopup } = getConfirmLeavePopupData();

	const { communityColors } = useCommunity();

	const color = useMemo(() => communityColors.primary, [communityColors.primary]);

	const handleSubmit = useCallback(
		e => {
			if (footerPrimary?.onClick) {
				e?.preventDefault && e.preventDefault();
				e?.stopPropagation && e.stopPropagation();
				e?.stopImmediatePropagation && e.stopImmediatePropagation();

				footerPrimary.onClick();
			}
		},
		[footerPrimary]
	);

	const onCloseHandler = useCallback(() => {
		handleClose(confirmLeave);
	}, [handleClose, confirmLeave]);

	const isMobile = useMediaQuery(appTheme.default.breakpoints.down("xs"));

	if (!open) return null;

	return (
		<DialogOverlay fullScreen={isMobile} customWidth={customWidth}>
			<ConfirmMemberLeave
				handleLeavePage={handleLeavePageConfirmed}
				open={showConfirmPopup}
				onClose={closeConfirmPopup}
				popup
			/>
			<InPlaceModal
				open
				fullScreen={isMobile}
				modalHeight={modalHeight}
				onClose={onCloseHandler}
				customStyle={"overflow: hidden;"}
			>
				<RefBox ref={modalRef} className="w-full h-full flex-1 flex flex-col">
					{!noHeader && (
						<DialogHeader id="dialogHeader">
							{goBack && (
								<Box className="action-button back">
									<IconButton size="small" onClick={goBack}>
										<Icon name="arrow-left" group="filled" fill="#8f9bb3" />
									</IconButton>
								</Box>
							)}
							<Text>{title}</Text>
							<Box className="action-button close">
								<IconButton size="small" onClick={onCloseHandler}>
									<Icon name="close" group="filled" fill="#8f9bb3" />
								</IconButton>
							</Box>
							{!!uploadingProgress && uploadingProgress > 0 && (
								<DialogHeader.Progress color={communityColors.primary} width={uploadingProgress} />
							)}
						</DialogHeader>
					)}
					{!loading ? (
						isScrollable ? (
							<ScrollBarWrapper
								autoHide
								autoHideDuration={200}
								thumbMinSize={10}
								className="scrollContent flex flex-col flex-1"
								noHeader={noHeader}
								noFooter={noFooter}
							>
								<DialogContent
									ref={contentRef}
									className={`${noHeader ? "no-header-padding" : ""} ${noFooter ? "no-footer-padding" : ""}`}
									id="dialogContent"
								>
									{children}
								</DialogContent>
							</ScrollBarWrapper>
						) : (
							<DialogContentWithoutScroll
								style={{ maxHeight: "70vh" }}
								className={`${noHeader ? "no-header-padding" : ""} ${noFooter ? "no-footer-padding" : ""}`}
								id="dialogContent"
							>
								{children}
							</DialogContentWithoutScroll>
						)
					) : (
						<LoadingWrapper>
							<Loader show variant="indeterminate" />
						</LoadingWrapper>
					)}
					{!noFooter && (
						<DialogFooter
							id="dialogFooter"
							color={color}
							className={isXsFooterFlex ? "xs-footer-flex" : ""}
							onSubmit={handleSubmit}
						>
							{footerNote && (
								<DialogFooter.TextWrapper>
									<Text className="break-words">{footerNote}</Text>
								</DialogFooter.TextWrapper>
							)}
							{footerLeftContent && <DialogFooter.TextWrapper>{footerLeftContent}</DialogFooter.TextWrapper>}
							{footerSecondary && (
								<SecondaryButton
									className={clsx(footerSecondary.small && "small", footerSecondary.grayBackground && "grayBg")}
									onClick={footerSecondary.onClick}
									disabled={loading || footerSecondary.disabled}
									buttonTheme={footerSecondary.ghost ? "ghost" : "light"}
									palette={footerSecondary.ghost ? "basic" : "primary"}
								>
									{footerSecondary.loading && <Loader show size={20} color="secondary" variant="indeterminate" />}
									{footerSecondary.text}
								</SecondaryButton>
							)}
							{footerPrimary && (
								<Button
									className={`primary ${footerPrimary.small && "small"}`}
									type="submit"
									disabled={loading || footerPrimary.disabled}
									id={footerPrimary?.id || ""}
									buttonTheme={footerPrimary?.footerTheme}
									palette={footerPrimary?.footerPlatte}
								>
									{footerPrimary.loading && <Loader show size={20} color="secondary" variant="indeterminate" />}
									{footerPrimary.text}
								</Button>
							)}
						</DialogFooter>
					)}
				</RefBox>
			</InPlaceModal>
		</DialogOverlay>
	);
};

export default MemberDialog;
