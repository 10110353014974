import styled from "styled-components";

import { Box, Text } from "shared/ui-kit";

export const InputWrapper = styled(Box)`
	.MuiTextField-root {
		font-size: 15px;
		line-height: 1.33;
		border-radius: 8px;
		border: solid 1px #e4e9f2;
		margin-top: 0;
		margin-bottom: 0;

		.MuiInputAdornment-positionEnd {
			margin: 0;
			padding: 0 8px;
		}
	}

	fieldset {
		display: none;
	}
`;

InputWrapper.Label = styled(Text)`
	font-size: 13px;
	font-weight: 600;
	line-height: 1.85;
	color: #222b45;
	margin-bottom: 2px;
`;
