import React from "react";

import { useUser } from "shared/hooks";

import { AddProfileInfoDialog } from "..";

const AddInterestsDialog = () => {
	const { setAddInterestsDialog, getData: getUserData } = useUser();
	const { interests } = getUserData();

	return (
		<AddProfileInfoDialog
			title="Interests"
			handleClose={() => setAddInterestsDialog(false)}
			categorizedList={interests}
			updateType="interests"
		/>
	);
};

export default AddInterestsDialog;
