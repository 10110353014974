import React, { useMemo } from "react";

import { ProfileType } from "shared/types";
import { WebRTCAdaptor } from "utils/liveconversation/WebRTCAdaptor.js";

import { ActionBlock } from "./style";

import { useFirebaseLiveConversation } from "../../../Data/hooks";

import useLiveConversation from "../../../Data/hooks/useLiveConversation";

import { Controls, ConversationDuration, ConversationOperation } from "../index";

interface ActionsProps {
	webRTCAdaptorRef: React.MutableRefObject<WebRTCAdaptor | null>;
	streamId: string;
	activeProfile?: ProfileType;
	isSpeaker?: boolean;
	isAdmin?: boolean;
	onLeave: () => void;
	onShowWarn: () => void;
}

const Actions: React.FC<ActionsProps> = ({
	webRTCAdaptorRef,
	streamId,
	activeProfile,
	isSpeaker = false,
	isAdmin = false,
	onLeave,
	onShowWarn
}) => {
	const { getData: getLiveConversationData } = useLiveConversation();
	const { config, firebaseParticipants } = getLiveConversationData();

	const { raiseHand } = useFirebaseLiveConversation();

	const turnOnCamera = () => {
		webRTCAdaptorRef?.current?.turnOnLocalCamera(streamId);
	};

	const turnOffCamera = () => {
		webRTCAdaptorRef?.current?.turnOffLocalCamera(streamId);
	};

	const mute = () => {
		webRTCAdaptorRef?.current?.muteLocalMic();
	};

	const unmute = () => {
		webRTCAdaptorRef?.current?.unmuteLocalMic();
	};

	const speakersCount = useMemo(
		() => firebaseParticipants.filter(({ isSpeaker }) => isSpeaker)?.length,
		[firebaseParticipants]
	);

	return (
		<ActionBlock>
			<ActionBlock.SectionWrapper>
				<ConversationDuration startedAt={config.startedAt} />
			</ActionBlock.SectionWrapper>
			<ActionBlock.SectionWrapper className="center">
				<Controls
					onLeave={onLeave}
					turnOnVideoCamera={turnOnCamera}
					turnOffVideoCamera={turnOffCamera}
					muteMicrophone={mute}
					unmuteMicrophone={unmute}
					personaId={activeProfile!.personaId}
					isSpeaker={isSpeaker}
					speakersCount={speakersCount}
					warnConvo={onShowWarn}
				/>
			</ActionBlock.SectionWrapper>
			<ActionBlock.SectionWrapper className="end">
				<ConversationOperation
					canSeeRaisedHands={isAdmin || isSpeaker}
					personaId={activeProfile?.personaId}
					raiseHand={raiseHand}
					warnConvo={onShowWarn}
					isAdmin={isAdmin}
				/>
			</ActionBlock.SectionWrapper>
		</ActionBlock>
	);
};

export default Actions;
