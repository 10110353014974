import React, { useEffect, useMemo, useRef, useState } from "react";

import { Box } from "@material-ui/core";

import { useAlbums, useDebounce, useOnScreen, useTrack } from "shared/hooks";

import { useS3Uploader } from "shared/services/s3Uploader";
import { TrackModel } from "shared/types";
import { Button, Icon, InPlaceModal, Loader, Search, Text } from "shared/ui-kit";
import { dataUrlToFile, filterFiles } from "utils/serviceUtils/helpers";

import LoadingTracks from "./LoadingTracks";
import NoMoreTracks from "./NoMoreTracks";
import Track from "./Track";
import TrackSkeleton from "./TrackSkeleton";
import {
	ClosePopupButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	SearchTracksWrapper,
	TracksScrollableList
} from "./style";

const correctFileTypes = ["audio/*"];

const AddTracksToPlaylist = () => {
	const [loadData, setLoadData] = useState(true);
	const [searchText, setSearchText] = useState("");
	const [allTracks, setAllTracks] = useState<{
		loading: boolean;
		data: TrackModel[];
	}>({
		loading: true,
		data: []
	});

	const { uploadFile, getData: getS3UploaderData } = useS3Uploader();
	const { uploading } = getS3UploaderData();

	const {
		getTracks,
		getTrackCount,
		setTrackFileUrl,
		setTrackName,
		setTrackInfoPopup,
		setTrackDuration,
		getData: getTrackData
	} = useTrack();
	const { filteredTracks, loading, trackCount, trackUploadUrl } = getTrackData();

	const { setAlbumDetailsDialog, setTracksChanged, getData: getAlbumsData } = useAlbums();
	const { albumDetailsDialog, tracksChanged } = getAlbumsData();

	const lastTrackRef = useRef<HTMLDivElement>(null);
	const debouncedSearchTerm = useDebounce(searchText, 300);

	const [filters, setFilters] = useState<{ limit: number; offset: number; keyword?: string }>({
		limit: 10,
		offset: 1,
		keyword: undefined
	});

	useEffect(() => {
		setFilters(f => ({ ...f, keyword: debouncedSearchTerm, offset: 1 }));
	}, [debouncedSearchTerm]);

	const onScreen = useOnScreen(lastTrackRef);

	const hasMoreItems = useMemo(() => trackCount > allTracks.data.length, [allTracks.data.length, trackCount]);

	useEffect(() => {
		if (onScreen && hasMoreItems) {
			setFilters(f => ({ ...f, offset: f.offset + 1 }));
			setLoadData(true);
		}
	}, [hasMoreItems, onScreen]);

	useEffect(() => {
		if (albumDetailsDialog.addTracks) {
			getTracks({
				...filters,
				keyword: filters?.keyword && filters?.keyword?.length > 2 ? filters.keyword : undefined,
				paginate: true
			});
			setLoadData(false);
		}
	}, [albumDetailsDialog.addTracks, filters, getTracks]);

	useEffect(() => {
		if (!!albumDetailsDialog.addTracks && (!debouncedSearchTerm || debouncedSearchTerm?.length > 2)) {
			getTrackCount({ keyword: debouncedSearchTerm });
		}
	}, [albumDetailsDialog.addTracks, debouncedSearchTerm, getTrackCount]);

	useEffect(() => {
		if (filteredTracks) {
			setAllTracks({ loading: false, data: filteredTracks });
		}
	}, [filteredTracks]);

	const onClose = () => {
		setSearchText("");
		setFilters(f => ({ ...f, offset: 1 }));
		setTrackInfoPopup({ open: false });
		setAlbumDetailsDialog({
			...albumDetailsDialog,
			addTracks: false
		});
		setAllTracks({
			loading: true,
			data: []
		});
		setTracksChanged(false);
	};

	const handleUpdateAlbum = () => {
		setAlbumDetailsDialog({
			...albumDetailsDialog,
			open: true,
			addTracks: false
		});
	};

	const sendToTracks = () => {
		const el: any = document.getElementById("trackFileUrl");
		if (el) {
			el.value = null;
			el.click();
		}
	};

	const onUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
		const { target } = e;
		const { files } = target;

		if (files) {
			const filteredFiles = filterFiles(files, correctFileTypes);

			const trackFile = !!filteredFiles?.length ? files[0] : null;

			if (trackFile) {
				const correctFile = typeof trackFile === "string" ? await dataUrlToFile(trackFile, "test") : trackFile;

				const audio = document.createElement("audio");
				const reader = new FileReader();

				reader.onload = e => {
					audio.src = (e.target?.result as string) || "";
					audio.addEventListener("loadedmetadata", () => {
						const duration = audio.duration;
						setTrackDuration(`${duration}`);
					});
				};

				reader.readAsDataURL(correctFile);

				uploadFile({
					file: correctFile as File,
					communityName: trackUploadUrl,
					checkProgress: true,
					customCallback: data => setTrackFileUrl(data?.publicUrl)
				});

				setTrackName(files && files[0].name.slice(0, files[0].name.lastIndexOf(".")));

				if (albumDetailsDialog.data) {
					setTrackInfoPopup({
						open: true,
						model: undefined,
						album: {
							label: albumDetailsDialog.data.title,
							value: albumDetailsDialog.data._id
						}
					});
				}
			}
		}
	};

	return (
		<ModalOverlay>
			<InPlaceModal open={!!albumDetailsDialog.addTracks} onClose={onClose} maxWidth="sm" modalHeight={700}>
				<ModalHeader>
					<Text variant="h6">Add Tracks to {albumDetailsDialog.data?.title}</Text>
					<ClosePopupButton onClick={onClose} size="small">
						<Icon name="close" group="filled" fill="#c5cee0" />
					</ClosePopupButton>
				</ModalHeader>
				<ModalContent>
					<SearchTracksWrapper>
						<Search
							placeholder="Search"
							onChange={e => {
								setSearchText(e.target.value);
								if (!loadData) {
									setLoadData(true);
								}
							}}
						/>
					</SearchTracksWrapper>
					<TracksScrollableList>
						<Box>
							{!allTracks.data.length && !allTracks.loading && <Text variant="h5">No Search Results</Text>}
							{allTracks.loading && (
								<>
									<TrackSkeleton />
									<TrackSkeleton />
									<TrackSkeleton />
									<TrackSkeleton />
								</>
							)}
							{!!allTracks.data.length &&
								allTracks.data.map((track, i) => {
									if (i + 1 === allTracks.data.length) {
										return (
											<Track
												lastTrackRef={lastTrackRef}
												track={track}
												key={track._id}
												trackCheckboxId={`albumCheckbox${i + 1}`}
											/>
										);
									}
									return <Track track={track} key={track._id} trackCheckboxId={`albumCheckbox${i + 1}`} />;
								})}
						</Box>
						{loading && <LoadingTracks page={1} />}
						{trackCount === allTracks.data.length && trackCount > 0 && !debouncedSearchTerm && <NoMoreTracks />}
					</TracksScrollableList>
				</ModalContent>
				<ModalFooter>
					<Button palette="light" buttonTheme="light" disabled={uploading} onClick={sendToTracks}>
						{uploading ? <Loader color="inherit" size="16px" show variant="indeterminate" /> : "Create New Track"}
					</Button>
					<Button onClick={handleUpdateAlbum} disabled={!tracksChanged} id="addToAlbum">
						Add to Album
					</Button>
				</ModalFooter>
				<input
					id="trackFileUrl"
					type="file"
					onChange={onUpload}
					name="trackFileUrl"
					hidden
					accept={correctFileTypes.join(",")}
				/>
			</InPlaceModal>
		</ModalOverlay>
	);
};

export default AddTracksToPlaylist;
