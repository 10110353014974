import React from "react";

import { PlaceholderImage } from "shared/Components";

import { PlaceholderImageType } from "shared/types";
import { Avatar } from "shared/ui-kit";

import { IconWrapper, Info, InfoWrapper, Link, StyledArrowRight, Title, Wrapper } from "./style";

interface CommunityBlockProps {
	title: string;
	link: string;
	iconUrl?: string;
	onClick: () => void;
}

const CommunityBlockSideMenu: React.FC<CommunityBlockProps> = ({ title, link, iconUrl, onClick }) => (
	<Wrapper onClick={onClick}>
		<InfoWrapper>
			<IconWrapper>
				{iconUrl ? (
					<Avatar title={title || ""} width={32} height={32} image={iconUrl || undefined} />
				) : (
					<PlaceholderImage
						type={PlaceholderImageType.WORKSPACE_SEARCH}
						width={32}
						height={32}
						viewBox={"0 0 400 400"}
					/>
				)}
			</IconWrapper>
			<Info>
				<Title>{title}</Title>
				<Link>{link}</Link>
			</Info>
		</InfoWrapper>
		<StyledArrowRight width={16} height={16} />
	</Wrapper>
);

export default CommunityBlockSideMenu;
