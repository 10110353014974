import React, { ReactNode } from "react";

import styled from "styled-components";

import { FrameSize, IExtendedTheme } from "shared/ui-kit/themes/default";

export enum orientationType {
	landscape = "landscape",
	portrait = "portrait"
}

export enum deviceType {
	tablet = "tablet",
	smartphone = "smartphone"
}

export interface Device {
	variant: deviceType.tablet | deviceType.smartphone;
}

export type FrameProps = Device & {
	children?: ReactNode;
	className?: string;
	glow?: boolean;
	highlight?: boolean;
	orientation?: orientationType.portrait | orientationType.landscape;
	theme: IExtendedTheme;
	width?: number;
	height?: number;
	isEmpty?: boolean;
	deviceType?: deviceType;
};

const getInnerBorderColor = ({ glow, highlight, theme }: FrameProps): string => {
	if (glow) {
		return theme.palette.frame.glowBorderColor;
	}
	if (highlight) {
		return theme.palette.frame.highlightBorderColor;
	}

	return theme.palette.frame.innerBorderColor;
};

const getScreenBackgroundColor = ({ glow, theme, isEmpty }: FrameProps): string => {
	if (glow && !isEmpty) {
		return theme.palette.frame.glowBackgroundColor;
	}

	return theme.palette.frame.contentBackgroundColor;
};

const getProperWidth = ({ width, variant }: FrameProps) => {
	return width || (variant === deviceType.tablet ? 605 : 338);
};

const isRotateMode = ({ orientation, variant }: FrameProps): boolean =>
	orientation === orientationType.landscape && variant === deviceType.tablet;

const DeviceButtons = ({ variant }: Device): JSX.Element => (
	<div className={`${variant}-buttons`}>
		<div className={`${variant}-buttons__button ${variant}-buttons__button_sound`} />
		<div className={`${variant}-buttons__button ${variant}-buttons__button_volume-up`} />
		<div className={`${variant}-buttons__button ${variant}-buttons__button_volume-down`} />
		<div className={`${variant}-buttons__button ${variant}-buttons__button_power`} />
	</div>
);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default styled(({ children, variant, width, orientation, ...rest }: Readonly<FrameProps>): JSX.Element => {
	return (
		<div {...rest}>
			<DeviceButtons variant={variant} />
			{variant === deviceType.smartphone && <div className="notch" />}
			<div className="input-devices">
				{variant === deviceType.smartphone && <span className="micro" />}
				<span className="camera" />
			</div>
			<div className="screen">{children}</div>
		</div>
	);
})`
	position: relative;
	background-color: black;

	${(props: FrameProps) => {
		const frame: FrameSize = props.theme.sizes.frame[props.variant];
		const width = getProperWidth(props);
		const currentRatio =
			props.orientation === orientationType.landscape
				? props.variant === deviceType.tablet
					? 725 / 605
					: 690 / 338
				: props.variant === deviceType.tablet
				? 605 / 725
				: 338 / 690;
		return `
    width: ${props.orientation === orientationType.portrait ? `${width}px` : `${width * currentRatio}px`};
    height: ${props.orientation === orientationType.portrait ? `${width / currentRatio}px` : `${width}px`};
    border: solid 4px ${props.theme.palette.frame.outerBorderColor};
    border-radius: ${frame.borderRadius};
    padding: ${frame.padding};
    background-color: ${props.theme.palette.frame.deviceColor};
  `;
	}}

	${(props: FrameProps) => {
		const { variant, theme, orientation } = props;
		if (variant === deviceType.tablet) {
			return;
		}
		const frame: FrameSize = props.theme.sizes.frame[props.variant];
		return `
  .notch {
    content: '';
    position: absolute;
    left: ${orientation === orientationType.portrait ? "22%" : frame.padding};
    right: auto;
    top: ${orientation === orientationType.portrait ? frame.padding : "22%"};
    z-index: 2;
    width: ${orientation === orientationType.portrait ? "56%" : "23px"};
    height: ${orientation === orientationType.portrait ? "23px" : "56%"};
    background-color: ${theme.palette.frame.deviceColor};
    border: 2px solid ${getInnerBorderColor(props)};
    ${orientation === orientationType.portrait ? "border-top: none;" : "border-left: none;"};
    border-radius: ${orientation === orientationType.portrait ? "0px 0px 40px 40px" : "0px 40px 40px 0"};
  }`;
	}}

  .tablet-buttons {
		position: absolute;
		left: 0;
		top: 0;
		right: 0;

		&__button {
			position: absolute;

			&_power {
				top: ${(props: FrameProps) => (isRotateMode(props) ? "45px" : "-6px")};
				right: 45px;
				left: ${(props: FrameProps) => (isRotateMode(props) ? "-18px" : "initial")};
				background-color: ${({ theme }: FrameProps) => theme.palette.frame.outerBorderColor};
				width: 27px;
				height: 3px;
				transform: ${(props: FrameProps) => (isRotateMode(props) ? "rotate(90deg)" : "none")};
			}
			&_volume-up {
				right: -18px;
				top: ${(props: FrameProps) => (isRotateMode(props) ? "-6px" : "55px")};
				left: ${(props: FrameProps) => (isRotateMode(props) ? "55px" : "initial")};
				background-color: ${({ theme }: FrameProps) => theme.palette.frame.outerBorderColor};
				width: 27px;
				height: 3px;
				transform: ${(props: FrameProps) => (isRotateMode(props) ? "none" : "rotate(90deg)")};
			}
			&_volume-down {
				right: -18px;
				top: ${(props: FrameProps) => (isRotateMode(props) ? "-6px" : "90px")};
				left: ${(props: FrameProps) => (isRotateMode(props) ? "90px" : "initial")};
				background-color: ${({ theme }: FrameProps) => theme.palette.frame.outerBorderColor};
				width: 27px;
				height: 3px;
				transform: ${(props: FrameProps) => (isRotateMode(props) ? "none" : "rotate(90deg)")};
			}
		}
	}

	.smartphone-buttons {
		position: absolute;
		left: 0;
		top: 0;
		right: 0;

		&__button {
			position: absolute;

			&_sound {
				width: 26px;
				height: 3px;
				left: ${(props: FrameProps) =>
					props.orientation === orientationType.landscape && props.variant === deviceType.smartphone
						? "42px"
						: "-18px"};
				top: ${(props: FrameProps) =>
					props.orientation === orientationType.landscape && props.variant === deviceType.smartphone
						? `calc(${getProperWidth(props)}px - 5px)`
						: "55px"};
				background-color: ${({ theme }: FrameProps) => theme.palette.frame.outerBorderColor};
				transform: ${(props: FrameProps) =>
					props.orientation === orientationType.landscape && props.variant === deviceType.smartphone
						? undefined
						: "rotate(90deg)"};
			}

			&_power {
				width: 80px;
				height: 3px;
				right: ${(props: FrameProps) =>
					props.orientation === orientationType.landscape && props.variant === deviceType.smartphone
						? undefined
						: "-45px"};
				left: ${(props: FrameProps) =>
					props.orientation === orientationType.landscape && props.variant === deviceType.smartphone
						? "100px"
						: undefined};
				top: ${(props: FrameProps) =>
					props.orientation === orientationType.landscape && props.variant === deviceType.smartphone
						? "-6px"
						: "150px"};
				background-color: ${({ theme }: FrameProps) => theme.palette.frame.outerBorderColor};
				transform: ${(props: FrameProps) =>
					props.orientation === orientationType.landscape && props.variant === deviceType.smartphone
						? undefined
						: "rotate(90deg)"};
			}

			&_volume-up {
				width: 50px;
				height: 3px;
				left: ${(props: FrameProps) =>
					props.orientation === orientationType.landscape && props.variant === deviceType.smartphone
						? "96px"
						: "-30px"};
				top: ${(props: FrameProps) =>
					props.orientation === orientationType.landscape && props.variant === deviceType.smartphone
						? `calc(${getProperWidth(props)}px - 5px)`
						: "114px"};
				background-color: ${({ theme }: FrameProps) => theme.palette.frame.outerBorderColor};
				transform: ${(props: FrameProps) =>
					props.orientation === orientationType.landscape && props.variant === deviceType.smartphone
						? undefined
						: "rotate(90deg)"};
			}

			&_volume-down {
				width: 50px;
				height: 3px;
				left: ${(props: FrameProps) =>
					props.orientation === orientationType.landscape && props.variant === deviceType.smartphone
						? "176px"
						: "-30px"};
				top: ${(props: FrameProps) =>
					props.orientation === orientationType.landscape && props.variant === deviceType.smartphone
						? `calc(${getProperWidth(props)}px - 5px)`
						: "184px"};
				background-color: ${({ theme }: FrameProps) => theme.palette.frame.outerBorderColor};
				transform: ${(props: FrameProps) =>
					props.orientation === orientationType.landscape && props.variant === deviceType.smartphone
						? undefined
						: "rotate(90deg)"};
			}
		}
	}

	.input-devices {
		position: absolute;
		display: inline-flex;
		align-items: center;
		top: ${({ orientation, variant, theme }: FrameProps) =>
			variant === deviceType.smartphone && orientation === orientationType.landscape
				? "50%"
				: theme.sizes.frame[variant].topBangsPadding};
		left: ${({ variant, orientation }: FrameProps) =>
			variant === deviceType.smartphone && orientation === orientationType.portrait
				? "calc(50% - 20px)"
				: variant === deviceType.smartphone && orientation === orientationType.landscape
				? "-13px"
				: "50%"};
		z-index: 2;

		transform: ${({ variant, orientation }: FrameProps) =>
			variant === deviceType.smartphone && orientation === orientationType.landscape
				? "translateY(-50%) rotate(-90deg)"
				: ""};

		${(props: FrameProps) => {
			if (isRotateMode(props)) {
				return `
            top: 50%;
            left: ${props.theme.sizes.frame[props.variant].topBangsPadding};
          `;
			}
		}};
	}

	.micro {
		width: 42px;
		height: 6px;
		border-radius: 3px;
		background-color: ${(props: FrameProps) => props.theme.palette.frame.innerBorderColor};
		margin-right: 8px;
	}

	.camera {
		background-color: ${(props: FrameProps) => props.theme.palette.frame.innerBorderColor};
		border-radius: 50%;
		width: ${(props: FrameProps) => props.theme.sizes.frame[props.variant].cameraSize};
		height: ${(props: FrameProps) => props.theme.sizes.frame[props.variant].cameraSize};
	}

	.screen {
		height: 100%;
		background-color: ${(props: FrameProps) => getScreenBackgroundColor(props)};
		border-radius: ${(props: FrameProps) => props.theme.sizes.frame[props.variant].screenBorderRadius};
		border: 2px solid ${(props: FrameProps) => getInnerBorderColor(props)};
		overflow-y: auto;
		padding: ${(props: FrameProps) => props.theme.sizes.frame[props.variant].screenPadding};
	}
`;
