import { withStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";

import styled from "styled-components";

import { Box, Button, Text } from "shared/ui-kit";

export const Search = styled(Box)`
	width: 100%;
	height: 40px;

	fieldset {
		//display: none;
	}

	.MuiAutocomplete-root {
		border-radius: 8px;
		box-shadow: inset 0 0 0 0.5px #c5cee0;
		background-color: #f7f9fc;

		width: 100%;
		height: 100%;
	}

	.MuiFormControl-root {
		width: 100%;
		height: 100%;
	}

	.MuiAutocomplete-endAdornment {
		right: 12px;
	}

	.MuiInputBase-root {
		width: 100%;
		height: 100%;

		padding: 0 16px;

		&:before,
		&:after {
			display: none;
		}
	}
`;

export const StyledAutocomplete = withStyles({
	option: {
		background: "white !important"
	},
	paper: {
		marginTop: "10px",
		borderRadius: "12px"
	}
})(Autocomplete);

export const WorkspaceOption = styled(Box)`
	width: 100%;
	height: 56px;

	margin: 0;

	padding: 8px;

	border-radius: 8px;

	background-color: white;

	display: flex;
	align-items: center;
	justify-content: space-between;

	&:hover {
		background-color: #f7f9fc;
	}
`;

WorkspaceOption.Content = styled(Box)`
	display: flex;
	align-items: center;
`;

WorkspaceOption.Action = styled(Box)`
	display: flex;
	align-items: center;
	justify-content: flex-end; ;
`;

WorkspaceOption.Avatar = styled(Box)`
	width: 40px;
	height: 40px;

	border-radius: 8px;

	margin-right: 16px;

	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;

	overflow: hidden;
`;

WorkspaceOption.InfoBox = styled(Box)`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
`;

WorkspaceOption.Title = styled(Text)`
	font-size: 15px;
	font-weight: 600;
	line-height: 1.6;
	letter-spacing: normal;
	color: #222b45;
`;

WorkspaceOption.Domain = styled(Text)`
	font-size: 12px;
	font-weight: normal;
	line-height: 1.33;
	letter-spacing: normal;
	color: #8f9bb3;
`;

WorkspaceOption.ActionBtn = styled(Button)`
	margin: 0;

	border-radius: 8px;
`;
