import React, { FC, useMemo } from "react";

import { Box } from "@material-ui/core";

import { ReactComponent as SoundWaveIcon } from "assets/icons/iconSoundWave.svg";

import { useAlbums } from "shared/hooks";
import { TrackMeta, TrackModel } from "shared/types";
import { Checkbox, Text } from "shared/ui-kit";

import { TrackRow } from "./style";

interface Props {
	track: TrackModel;
	lastTrackRef?: React.RefObject<HTMLDivElement>;
	trackCheckboxId?: string;
}

const Track: FC<Props> = ({ track, lastTrackRef, trackCheckboxId }) => {
	const { setAlbumDetailsDialog, setTracksChanged, getData: getAlbumsData } = useAlbums();
	const { albumDetailsDialog } = getAlbumsData();

	const { title, meta } = track;

	const artworkUrl = (meta as TrackMeta)?.artwork?.url;

	const checked = useMemo(
		() =>
			albumDetailsDialog.data?.musics.some(t => t._id === track._id) &&
			!albumDetailsDialog.deletedTracks.some(d => d === track._id),
		[albumDetailsDialog.data, albumDetailsDialog.deletedTracks, track._id]
	);

	const handleToggleTrack = () => {
		if (albumDetailsDialog.data) {
			if (checked) {
				setAlbumDetailsDialog({
					...albumDetailsDialog,
					deletedTracks: [...albumDetailsDialog.deletedTracks, track._id]
				});
			} else {
				const tIdx = albumDetailsDialog.deletedTracks.findIndex(t => t === track._id);

				if (tIdx > -1) {
					setAlbumDetailsDialog({
						...albumDetailsDialog,
						deletedTracks: albumDetailsDialog.deletedTracks.filter((_, i) => i !== tIdx)
					});
				} else {
					setAlbumDetailsDialog({
						...albumDetailsDialog,
						data: {
							...albumDetailsDialog.data,
							musics: [track, ...albumDetailsDialog.data.musics]
						}
					});
				}
			}
			setTracksChanged(true);
		}
	};

	return (
		<TrackRow ref={lastTrackRef} checked={checked} onClick={handleToggleTrack}>
			<Box className="track-info">
				{artworkUrl ? (
					<img src={artworkUrl} alt={track.title} />
				) : (
					<Box className="track-no-img">
						<SoundWaveIcon />
					</Box>
				)}
				<Box className="track-name">
					<Text variant="subtitle1">{title}</Text>
					<Text variant="body2">{meta.artist}</Text>
				</Box>
			</Box>
			<Box className="checkbox">
				<Checkbox checked={checked} id={trackCheckboxId || ""} />
			</Box>
		</TrackRow>
	);
};

export default Track;
