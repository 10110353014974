import styled, { css } from "styled-components";

import { Box, Text } from "shared/ui-kit";

export const Wrapper = styled(Box)`
	position: relative;
	${({ width }) =>
		width &&
		css`
			width: ${width};
		`}
`;

Wrapper.Picker = styled(Box)`
	position: absolute;
	top: calc(100% + 4px);
	left: 0;
	z-index: 100;
	border: 1px solid #e4e9f2;
	background-color: #ffffff;
	border-radius: 8px;
	overflow: hidden;
	transform: translateY(-20px);
	opacity: 0;
	pointer-events: none;
	transition: ease-in-out 0.2s all;
	box-shadow: 0 0 8px 0 rgb(0 0 0 / 12%), inset 0 0 0 0.5px #c5cee0;
	.MuiPickersDay-day {
		border-radius: 8px;
	}
	.MuiPickersDay-daySelected {
		background-color: ${({ color }) => (color ? `${color}33` : "#6173fe33")};
		color: ${({ color }) => (color ? color : "#ffffff")};
	}
	.MuiPickersDay-current {
		color: ${({ color }) => (color ? color : "#6173fe")};
		&.MuiPickersDay-daySelected {
			color: ${({ color }) => (color ? color : "#ffffff")};
		}
	}
	.MuiPickersMonth-monthSelected {
		color: ${({ color }) => (color ? color : "#6173fe")};
		font-size: 15px;
	}
	&.show {
		transform: translateY(0);
		opacity: 1;
		pointer-events: auto;
	}
`;

Wrapper.HourPickerWrapper = styled(Box)`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 4px;
	margin-bottom: 16px;
	input {
		padding: 4px;
		width: 40px;
		height: 40px;
		border-radius: 4px;
		border: 1px solid #e4e9f2;
		font-size: 16px;
		text-align: center;
	}
	.variant__member--wrapper,
	.variant__member--child {
		border-radius: 4px !important;
	}
	.variant__member--wrapper {
		height: 40px;
	}
	.variant__member--child {
		padding: 4px !important;
		width: 36px;
	}
`;

Wrapper.ErrorText = styled(Box)`
	position: absolute;
	top: calc(100% + 4px);
	left: 0;
	font-size: 12px;
	color: red;
`;

export const InputWrapper = styled(Box)`
	.MuiTextField-root {
		font-size: 15px;
		line-height: 1.33;
		border-radius: 8px;
		border: solid 1px #e4e9f2;
		margin-top: 0;
		margin-bottom: 0;
		.MuiInput-underline {
			&:after,
			&:before {
				border-bottom: 0;
			}
		}
		label + .MuiInput-formControl {
			margin-top: 0;
			margin: 0;
			padding: 8px;
			padding-left: 12px;
		}
	}
	&.error .MuiTextField-root {
		border-color: red;
	}
`;

InputWrapper.Label = styled(Text)`
	font-size: 13px;
	font-weight: 600;
	line-height: 1.85;
	color: #222b45;
	margin-bottom: 2px;
`;

export const IconWrapper = styled(Box)`
	path {
		fill: #c5cee0;
	}
	&.active {
		path {
			fill: #192038;
		}
	}
`;

export const CustomToolbarComponent = styled(Box)`
	position: absolute;
	top: 4px;
	left: 50%;
	height: 48px;
	transform: translateX(-50%);
	z-index: 100;
	display: flex;
	align-items: center;
	justify-content: center;
	min-width: 80px;
	button {
		height: 32px;
		font-size: 15px;
		font-weight: bold;
		line-height: 1.6;
		color: #222b45;
	}
`;
