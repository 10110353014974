import React, { memo, useCallback, useEffect, useState } from "react";

import { useHistory } from "react-router";

import { ForumPostModel } from "types";

import { Post } from "shared/Organisms";
import { useCommunity, usePosts, useUser } from "shared/hooks";

import { Column, Container } from "./style";

interface PostPageProps {
	postId?: string;
}

const PostPage: React.FC<PostPageProps> = memo(({ postId }) => {
	const history = useHistory();
	const { getData: getUserData } = useUser();
	const { user } = getUserData();

	const { getData: getCommunityData } = useCommunity();
	const { workspace } = getCommunityData();

	const { getPost } = usePosts();

	const [post, setPost] = useState<ForumPostModel>();

	const fetchPostInfo = useCallback(
		(id: string) => {
			getPost(id).then(postInfo => {
				setPost(postInfo);
			});
		},
		[getPost]
	);

	useEffect(() => {
		if (!postId) {
			history.go(-1);
		} else {
			fetchPostInfo(postId);
		}
	}, [history, fetchPostInfo, postId]);

	if (!workspace || !user || !post) {
		return null;
	}

	return (
		<Container>
			<Column middle>
				<Post fullMode post={post} />
			</Column>
		</Container>
	);
});

export default PostPage;
