import { useMemo } from "react";

import { useUser } from "shared/hooks";

import useJwt from "shared/services/api/core/jwt";

import { usePaymentApiService } from "../services";
import { PaymentModel } from "../types";

const useMoney = () => {
	const service = usePaymentApiService();

	const { getData: getUserData } = useUser();
	const { user } = getUserData();
	const personaId = user?.profiles[0]?.personaId || "";

	const { getWorkspace } = useJwt();
	const workSpace = getWorkspace();

	return useMemo(
		() => ({
			createRequest: async (payment: PaymentModel) => {
				payment.personaId = personaId as number;
				return await service.createPayment(payment);
			},
			moneyImageUploadUrl: workSpace
		}),
		[personaId, service, workSpace]
	);
};

export default useMoney;
