import React, { useCallback, useEffect, useMemo, useState } from "react";

import { useLocation } from "react-router-dom";
import styled from "styled-components";

import { ReactComponent as CoinIcon } from "assets/icons/iconCoin.svg";

import {
	BTCMetricsSponsorNShop,
	CommunityName,
	CommunityPrivacy,
	CommunityTheme,
	CommunityTypes
} from "shared/Components";
import ConfirmLeavePopup from "shared/Components/ConfirmLeave";
import useConfirmLeavePage from "shared/Components/ConfirmLeave/hooks/useConfirmLeavePage";
import { useCommunity } from "shared/hooks";
import { Box, ExpansionPanel, Icon, Text } from "shared/ui-kit";
import theme from "theme/default";

import { CoinsAndBundles, CommunityLogo, CommunityPage, ContentPlus18, Splitter } from "./Components";

import { AdditionalBtcGSItems, GeneralSettingsItems } from "./constants";

export interface HomePageProps {}

const Container = styled(Box)`
	display: flex;
	flex-direction: column;

	${props => props.theme.breakpoints.down("sm")} {
		width: 100%;
		margin: 0;
	}

	.MuiExpansionPanel-root {
		border: 0.5px solid #c5cee0;
		border-radius: 4px;

		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);

		margin-bottom: 16px;

		&:before {
			display: none;
		}
	}

	.MuiExpansionPanelSummary-root {
		height: 70px;

		.MuiExpansionPanelSummary-expandIcon {
			order: 1;

			margin-right: 1px;
		}
		.MuiIconButton-label {
			background-color: white;
		}
	}

	.MuiExpansionPanelSummary-content {
		.MuiTypography-root {
			font-weight: normal;
			color: #000000;
			font-size: 18px;
		}
	}
`;

const TitleContainer = styled(Box)`
	display: flex;
	margin-left: 13px;

	.title {
		margin-left: 24px;
	}
`;

const EmptyCircle = styled(Box)`
	width: 22px;
	height: 22px;
	background-color: #fff;
	border: 2px solid #e4e9f2;
	border-radius: 50%;
`;

const ContentContainer = styled(Box)`
	width: 94%;
	margin-left: 4%;
	margin-right: 4%;
	padding-bottom: 24px;
`;

const GeneralSettings: React.FC<HomePageProps> = () => {
	const { search } = useLocation();
	const query = new URLSearchParams(search);
	const activeBlock = query.get("activeBlock");

	const [expandedIndex, setExpandedIndex] = useState(-1);

	useEffect(() => {
		if (activeBlock && activeBlock === "coin") {
			setExpandedIndex(7);
		}
	}, [activeBlock]);

	const getIcon = useMemo(
		() => index => {
			switch (index) {
				case 1:
					return <Icon fill={theme.palette.primary.main} name={"user"} group="filled" />;
				case 2:
					return <Icon fill={theme.palette.primary.main} name={"heart"} group="filled" />;
				case 3:
					return <Icon fill={theme.palette.primary.main} name={"page-break"} group="color" />;
				case 4:
					return <Icon fill={theme.palette.primary.main} name={"tiles-colourful"} group="color" />;
				case 5:
					return <Icon fill={theme.palette.primary.main} name={"globe"} group="linear" />;
				case 6:
					return <Icon fill={theme.palette.primary.main} name={"eye-slash"} group="filled" />;
				case 7:
					return <Icon fill={theme.palette.primary.main} name={"id-badge"} group="filled" />;
				case 8:
					return <CoinIcon />;
				case 9:
					return <Icon fill={theme.palette.primary.main} viewBox={"0 0 512 512"} name={"bitcoin"} group="filled" />;
				default:
					return <EmptyCircle />;
			}
		},
		[]
	);

	const expandOrCollapse = useCallback(
		(id: number) => (id === expandedIndex ? setExpandedIndex(-1) : setExpandedIndex(id)),
		[expandedIndex]
	);

	const renderContent = useMemo(
		() => (id: number) => {
			switch (id) {
				case 1:
					return <CommunityName />;
				case 2:
					return <CommunityLogo />;
				case 3:
					return <CommunityTheme />;
				case 4:
					return <CommunityTypes />;
				case 5:
					return <CommunityPrivacy />;
				case 6:
					return <ContentPlus18 />;
				case 7:
					return <CommunityPage />;
				case 8:
					return <CoinsAndBundles />;
				case 9:
					return <BTCMetricsSponsorNShop />;
				default:
					return <>In Progress</>;
			}
		},
		[]
	);

	const { handleLeavePageConfirmed, closeConfirmPopup, getData: getConfirmLeavePopupData } = useConfirmLeavePage({});
	const { showConfirmPopup } = getConfirmLeavePopupData();

	const { getData: getCommunityData } = useCommunity();
	const { isBitcoinMode } = getCommunityData();
	const items = isBitcoinMode ? [...GeneralSettingsItems, ...AdditionalBtcGSItems] : GeneralSettingsItems;

	return (
		<>
			<ConfirmLeavePopup
				handleLeavePage={handleLeavePageConfirmed}
				open={showConfirmPopup}
				onClose={closeConfirmPopup}
			/>
			<Container>
				<Text variant="h3" customStyle={() => "margin-bottom: 40px;"}>
					General Settings
				</Text>
				<ExpansionPanel
					panels={items.map((el, i) => ({
						...el,
						title: (
							<TitleContainer>
								{getIcon(el.id)}
								<div className="title">{el.title}</div>
							</TitleContainer>
						) as any,
						content: (
							<ContentContainer>
								<Splitter />
								{renderContent(el.id)}
							</ContentContainer>
						) as any,
						expanded: expandedIndex === i,
						onChange: () => expandOrCollapse(i)
					}))}
				/>
			</Container>
		</>
	);
};

export default GeneralSettings;
