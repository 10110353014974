import { Menu, TextField } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import styled from "styled-components";

import { Box, Button, Icon, Text } from "shared/ui-kit";

export const Container = styled(Box)`
	display: flex;
	flex-direction: column;
	max-width: 900px;
	margin: auto;

	.MuiAccordionDetails-root {
		padding-top: 0px;
	}

	${props => props.theme.breakpoints.down("sm")} {
		width: 100%;
		margin: 0;
	}

	.MuiAccordion-root {
		border: 0.5px solid #c5cee0;
		border-radius: 4px;

		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);

		&:before {
			display: none;
		}
	}

	.MuiBackdrop-root {
		position: fixed !important;
		background: rgba(25, 36, 53, 0.1) !important;
	}

	.MuiAccordionSummary-root {
		display: flex;
		min-height: 70px;
		padding: 0 10px;
		color: #222b45;
		font-size: 18px;

		.MuiExpansionPanelSummary-expandIcon {
			order: 1;
		}

		.MuiIconButton-label {
			background-color: white;
		}
	}

	.MuiAccordionSummary-content {
		.MuiTypography-root {
			font-weight: normal;
			color: #000000;
			font-size: 18px;
		}
	}
`;

export const FaqItemContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	margin-bottom: 16px;

	.MuiAccordion-root.Mui-expanded {
		margin: 0;
	}

	.reorder-button {
		display: none;

		.MuiBox-root {
			margin: 0;
			&:hover {
				background-color: transparent;
			}
		}

		&-sm {
			transition: transform 0.3s ease-in-out;
			display: flex;
			cursor: grab;
			font-size: 24px;
			align-items: center;
			transform: rotate(90deg);
		}
	}

	.MuiAccordion-root {
		flex: 1;
	}

	.edit-buttons {
		justify-self: end;
		opacity: 0;
		transition: transform 0.3s ease-in-out;
		font-size: 24px;
		display: none;
		align-items: center;
		margin-left: 16px;
	}

	&:hover .edit-buttons {
		opacity: 1;
		//background: rgba(10, 10, 10, 0.07);
		transition: all 0.2s ease-in-out;
	}

	&:hover .reorder-button {
		opacity: 1;
		transition: all 0.2s ease-in-out;
	}

	${props => props.theme.breakpoints.up("sm")} {
		grid-template-columns: 54px auto 185px;

		.reorder-button {
			transition: transform 0.3s ease-in-out;
			display: flex;
			cursor: grab;
			font-size: 24px;
			align-items: center;
			transform: rotate(90deg);
			margin: 0;
			height: 100%;
			justify-content: center;
			margin-right: 16px;
			opacity: 0;

			&-sm {
				display: none;
			}
		}

		.edit-buttons {
			display: flex;
		}
	}
`;

export const TitleContainer = styled(Box)`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;

	.left-side {
		display: flex;
		align-items: center;
	}

	.icon {
		flex: 1;
		margin-left: 6px;
		display: none;
	}

	.title {
		margin-left: 10px;
		font-size: 18px;
		color: #000;
		letter-spacing: 0.1px;
	}

	.dropdown {
		position: relative;
		button {
			padding: 12px 0;
			margin: 0;
			background-color: transparent;
		}
	}

	${props => props.theme.breakpoints.up("sm")} {
		.title {
			font-size: 18px;
		}

		.dropdown {
			display: none;
		}

		.icon {
			margin-left: 12px;
			display: flex;
		}
	}
`;

export const ContentContainer = styled(Box)`
	width: 100%;
	white-space: pre-line;
	padding: 0 0 16px;
	font-size: 15px;
	line-height: 1.47;
`;

export const Row = styled(Box)`
	width: inherit;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	margin-bottom: 24px;

	.right-side {
		width: inherit;
		display: flex;
		flex-direction: row;
		align-items: center;
		margin-bottom: 16px;

		button {
			margin-top: 5px;
		}
	}

	&.no-margin {
		margin: 0;
	}

	${props => props.theme.breakpoints.up("md")} {
		flex-direction: row;
		margin: 0 165px 16px 46px;

		.right-side {
			align-items: flex-start;
			button {
				margin-top: 0;
			}
		}
	}
`;

export const SearchField = styled(TextField)`
	padding: 0;
	max-width: 200px;
	flex: 1;
	background-color: white;
	border-radius: 4px;
	border: 0;
	.MuiOutlinedInput-notchedOutline {
		top: -5px;
	}
`;

export const Title = styled(Text)`
	flex: 1;
	font-size: 20px;

	${props => props.theme.breakpoints.up("sm")} {
		font-size: 1.875rem;
	}
`;

export const NoResultsWrapper = styled(Box)`
	flex-direction: column;
	display: flex;
	justify-content: center;
	align-items: center;
	flex: 1;
`;

export const ButtonWrapper = styled(Box)`
	display: flex;
	height: 46px;
	justify-content: center;
	align-items: center;
	margin-left: 12px;

	form {
		width: 100%;
		margin-right: 16px;
	}
	.MuiInputBase-root {
		padding: 2px 0 !important;
	}
`;

export const StyledIcon = styled(Icon)`
	width: 24px;
	height: 24px;
`;

export const IconContainer = styled(Box)`
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 5px;
	margin-right: 16px;
	font-size: 24px;
	> div {
		display: flex;
	}
	:hover {
		.fill-first-path path:first-child {
			fill: #a3aab9;
		}

		.fill-second-path path:nth-child(2) {
			fill: #a3aab9;
		}
	}

	${props => props.theme.breakpoints.down("sm")} {
		margin-right: 0;
	}
`;

export const CaretContainer = styled(Box)`
	margin-right: 16px;
`;

export const StyledButton = styled(Button)<{ marginTop?: number }>`
	width: 96px;
	height: 46px;

	border-radius: 4px;
	background-color: #6173fe;
	color: ${({ transparent }) => (transparent ? "#8f9bb3" : "white")};
	@media (min-width: 991px) {
		padding: 13px 32px;
		font-size: 14px;
		white-space: nowrap;
		margin: 0;
		margin-top: ${({ marginTop }) => marginTop}px;
	}
`;

export const PopupTitle = styled(Text)`
	font-size: 18px;
	font-weight: bold;
	line-height: 1.33;
	color: #222b45;
	text-align: left;
	padding-left: 16px;
`;

export const PopupDescription = styled(Text)`
	font-size: 15px;
	font-style: normal;
	line-height: 1.47;
	color: #8f9bb3;
`;

export const Splitter = styled(Box)`
	border-top: 1px solid #c5cee0;
	width: 100%;
	margin-bottom: 20px;
	margin-top: 1px;
`;

export const DropdownMenu = styled(Menu)`
	svg {
		margin-right: 10px;
	}
`;

export const SkeletonContainer = styled(Box)`
	margin: 0 185px 0 54px;
`;

export const SkeletonQuestionWrapper = styled(Box)`
	margin-bottom: 16px;
`;

export const SkeletonRow = styled(Skeleton)`
	transform: scale(1);
`;
