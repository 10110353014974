import React from "react";

import { ContentContainer, TitleContainer, TitleText, Wrapper } from "./style";

import { Splitter } from "../index";

const PayoutCard = ({ children, title }) => {
	return (
		<>
			<TitleContainer>
				<TitleText>{title}</TitleText>
			</TitleContainer>
			<ContentContainer>
				<Splitter />
				<Wrapper>{children}</Wrapper>
			</ContentContainer>
		</>
	);
};

export default PayoutCard;
