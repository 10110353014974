import React, { useCallback, useEffect, useMemo, useState } from "react";

import { useHistory, useLocation } from "react-router-dom";

import { VARS, routes as authRoutes } from "apps/Auth/constants";
import { routes as adminRoutes } from "apps/RegularUser/constants";
import { view } from "modules/App";
import { useAuth } from "modules/App/Data";

import { useCommunity, useMemberRoutes, useRoutes, useUser } from "shared/hooks";

import { Loader } from "shared/ui-kit";

const CreateCommunity = () => {
	const history = useHistory();
	const { search } = useLocation();
	const { clearStorage } = useRoutes();
	const { resetState } = useUser();
	const { clearState, checkInvitationValidation } = useAuth();
	const { setSportsMode } = useCommunity();

	const { getMemberRoutesData } = useMemberRoutes();
	const { routes: memberRoutes } = getMemberRoutesData();

	const query = new URLSearchParams(search.replace(/\+/g, "%2B"));
	const workspace = query.get("workspace") || undefined;
	const email = query.get("email");
	const userId = query.get("userId");
	const globalUserId = query.get("globalUserId");
	const invitationId = query.get("invitationId");
	const isSportsMode = query.get("sportsmode");

	const [initialised, setInitialised] = useState(false);
	const [loading, setLoading] = useState(false);
	const [invitationValid, setInvitationValid] = useState(!invitationId);

	useEffect(() => {
		if (!initialised && invitationValid) {
			clearStorage();
			clearState();
			resetState();
			setInitialised(true);
			setSportsMode(isSportsMode !== null);
		}
	}, [clearStorage, clearState, resetState, initialised, invitationValid, setSportsMode, isSportsMode]);

	const invitationData = useMemo(() => {
		return workspace
			? {
					workspace: decodeURIComponent(workspace),
					email: email ? decodeURIComponent(email) : undefined,
					userId: userId ? decodeURIComponent(userId) : undefined,
					globalUserId: globalUserId ? decodeURIComponent(globalUserId) : undefined,
					invitationId: invitationId ? decodeURIComponent(invitationId) : undefined
			  }
			: undefined;
	}, [workspace, email, userId, globalUserId, invitationId]);

	const validateInvitation = useCallback(
		async (invitationId: string, workspace?: string) => {
			setLoading(true);
			const isValid = await checkInvitationValidation({ invitationId, customWorkspace: workspace });
			setLoading(false);

			if (typeof isValid === "boolean" && isValid) {
				setInvitationValid(true);
			} else {
				history.push(authRoutes.auth.brokenLink.getPath());
			}
		},
		[checkInvitationValidation, history]
	);

	useEffect(() => {
		if (invitationId) {
			validateInvitation(invitationId, workspace);
		}
	}, [invitationId, workspace, validateInvitation]);

	return loading || !initialised ? (
		<Loader size="15px" color="secondary" show variant="intermediate" />
	) : (
		<view.SignIn
			TERMS_AND_CONDITION={VARS.TERMS_AND_CONDITIONS_LINK}
			PRIVACY_POLICY_LINK={VARS.PRIVACY_POLICY_LINK}
			COOKIE_USE_LINK={VARS.COOKIE_USE_LINK}
			communityListRoute={authRoutes.auth.communities.getPath()}
			signInRoute={
				!invitationData ? `${authRoutes.auth.signIn.getPath()}${isSportsMode !== null ? "?sportsmode" : ""}` : undefined
			}
			sendToCreateCommunity={!invitationData}
			memberHomePage={memberRoutes?.member.home.getPath()}
			memberSignInPage={adminRoutes.user.signIn.getPath()}
			memberFillProfilePage={memberRoutes?.member.fillProfile.getPath()}
			invitationCodePage={authRoutes.auth.invitationCode.getPath()}
			invitationData={invitationData}
			forgotPassword={!!invitationData ? authRoutes.auth.forgotPassword.getPath() : undefined}
			joinCommunitiesUrl={adminRoutes.communities.getPath()}
		/>
	);
};

export default CreateCommunity;
