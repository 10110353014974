import { Box, ButtonBase } from "@material-ui/core";
import styled from "styled-components";

export const Wrapper = styled(ButtonBase)`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	position: relative;
	border-radius: 12px;
	width: 100%;
	margin-bottom: 16px;
	text-align: left;
	box-shadow: inset 0 0 0 0.5px #c5cee0;
	&:after {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		box-shadow: 1px 1px 6px #c5cee0;
		opacity: 0;
		transition: opacity 0.2s ease-in-out;
		border-radius: 12px;
	}
	&:hover {
		&:after {
			opacity: 1;
		}
	}
	h6 {
		font-size: 11px;
		line-height: 1.33;
		color: #222b45;
		font-weight: normal;
		margin: 0;
	}
	h3 {
		font-size: 13px;
		font-weight: 600;
		line-height: 1.6;
		color: #222b45;
		margin: 4px 0;
	}
	p {
		font-size: 11px;
		line-height: 1.33;
		color: #8f9bb3;
		margin: 0;
	}
	${props => props.theme.breakpoints.up("sm")} {
		h3 {
			font-size: 15px;
		}
		h6,
		p {
			font-size: 12px;
		}
	}
`;

Wrapper.IconWrapper = styled(Box)`
	height: 96px;
	width: 96px;

	margin-right: 16px;

	border-top-left-radius: 12px;
	border-bottom-left-radius: 12px;

	overflow: hidden;

	img {
		width: 100%;
		height: 100%;

		object-fit: cover;
	}
`;
