import React, { FC, useCallback } from "react";

import DateFnsAdapter from "@date-io/date-fns";
import { Box } from "@material-ui/core";

import { ReactComponent as NoProfileIcon } from "assets/icons/no-profile.svg";

import { InfoItem, List } from "modules/MemberHome/View/Components";
import { Headline, StyledAbout } from "modules/MemberHome/View/shared/style";
import { PlaceholderImage } from "shared/Components";
import { useCommunity, useFundraisers, useUser } from "shared/hooks";
import { PlaceholderImageType } from "shared/types";
import { TopContrib } from "shared/types/FundraiserModel";
import { Icon, ReadMore, Text } from "shared/ui-kit";
import { formatMoney } from "utils/formatMoney";
import { formatCount } from "utils/serviceUtils/helpers";

const dateFns = new DateFnsAdapter();

const AboutSection: FC<{}> = ({}) => {
	const { getData: getFundraiserData } = useFundraisers();
	const { fundraiser } = getFundraiserData();
	const { communityColors } = useCommunity();
	const { getData: getUserData } = useUser();
	const { user } = getUserData();

	const RenderItem = useCallback(
		(item: TopContrib) => (
			<InfoItem
				iconWrapperClassName="mr-2"
				placeholderImg={<NoProfileIcon className="w-12 h-12" />}
				avatarUrl={item.image || undefined}
				title={item.name}
				subtitle={formatMoney(item.amount)}
			/>
		),
		[]
	);

	return !fundraiser ? null : (
		<Box className="w-full">
			<Box className="contain">
				<Box className="content-block">
					<StyledAbout fillColor={communityColors.primary}>
						<Headline variant="h3">Fundraiser Details</Headline>
						<Box className="details-row">
							<Box className="details-item">
								<Box className="icon">
									<Icon name="fundraiser" group="color" />
								</Box>
								<Box>
									<Text variant="h6">
										{`${formatMoney(fundraiser?.totalDonated)} `}
										<Text>{`/ ${formatMoney(fundraiser?.goalAmount)}`}</Text>
									</Text>
									<Text>Raised</Text>
								</Box>
							</Box>
							<Box className="details-item">
								<Box className="icon">
									<Icon name="clock" group="filled" fill={communityColors.primary} />
								</Box>
								<Box>
									<Text variant="h5">Started on {dateFns.format(new Date(fundraiser.startDate), "LL/d/y" as any)}</Text>
									<Text variant="h5">Active until {dateFns.format(new Date(fundraiser.endDate), "LL/d/y" as any)}</Text>
								</Box>
							</Box>
							<Box className="details-item">
								<Box className="icon">
									<Icon name="user-friend" group="filled" fill={communityColors.primary} />
								</Box>
								<Box>
									<Text variant="h5">{formatCount(fundraiser.totalDonations, "", " ")}</Text>
									<Text>Donations</Text>
								</Box>
							</Box>
							<Box className="details-item">
								{!!fundraiser.creator[0]?.photos?.length ? (
									<Box className="icon">
										<img
											src={fundraiser?.creator[0]?.photos[0]?.profilePicture}
											alt={fundraiser?.creator[0]?.firstName}
										/>
									</Box>
								) : (
									<PlaceholderImage
										wrapClassName="rounded-svg"
										type={PlaceholderImageType.PROFILE_IMAGE}
										width={40}
										height={40}
										viewBox={"0 0 400 400"}
									/>
								)}
								<Text variant="h5">
									{`Created by ${fundraiser?.creator[0]?.firstName} ${fundraiser?.creator[0]?.lastName} ${
										fundraiser?.creator[0]?.personaId === user?.activeProfile ? "(You)" : ""
									}`}
								</Text>
							</Box>
						</Box>
						<Headline variant="h3" mt>
							About Fundraiser
						</Headline>
						<ReadMore text={fundraiser?.description} showCharCount={300} wrapClassName="" wrapTextClassName="" />
					</StyledAbout>
				</Box>
				<Box className="admins-list">
					<List
						title={`Top ${fundraiser?.top?.length === 1 ? "Sponsor" : "Sponsors"}`}
						items={!!fundraiser?.top?.length ? fundraiser.top.slice(0, 3) : []}
						onSeeAllClick={fundraiser?.top?.length > 3 ? () => {} : undefined}
						totalCount={10}
						renderItem={RenderItem}
						noContentBlock={<Text className="p-2">No top sponsors for this fundraiser yet.</Text>}
						preview
					/>
				</Box>
			</Box>
		</Box>
	);
};

export default AboutSection;
