export const getFileFromVideoUrl = async (url: string) => {
	const data = await fetch(url)
		.then(res => res.blob())
		.catch(e => console.error({ e }));
	const file = new File([data as Blob], "videoFile", {
		type: (data as Blob).type
	});

	return file;
};
