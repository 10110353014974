import { buildRouteTree } from "utils/routeUtils";

export const routes = buildRouteTree({
	onboarding: {
		community: {
			profile: null,
			create: null,
			about: null,
			feature: null,
			theme: null
		},
		profile: null,
		congratulation: {
			confirmed: null
		}
	}
});
