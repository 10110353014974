import { useMemo } from "react";

import useHttpActions from "shared/services/api/core/useHttpActions";

import { CreateUpdateSlot } from "shared/types/VolunteersTypes";

import BaseConverter from "./converters/baseConverter";

import { API_URLS } from "../constants";
import { VolunteerSlot } from "../types";

const useVolunteersApiService = () => {
	const actions = useHttpActions();

	const methods = useMemo(
		() => ({
			getVolunteerSlots: async ({
				eventId,
				offset,
				limit,
				sortOrder = -1
			}: {
				eventId: string;
				offset: number;
				limit: number;
				sortOrder?: 1 | -1;
			}) => {
				return BaseConverter.convert<{ success: boolean; volunteerslots: VolunteerSlot[] }>(
					await actions.get(API_URLS.VOLUNTEERS.GET(eventId), { offset, limit, sortOrder })
				);
			},
			createVolunteerSlot: async (data: CreateUpdateSlot, eventId: string) => {
				return BaseConverter.convert<{ success: boolean; volunteerslot: VolunteerSlot }>(
					await actions.post(API_URLS.VOLUNTEERS.CREATE, { ...data, eventId })
				);
			},
			updateVolunteerSlot: async (data: CreateUpdateSlot, volunteerId: string) => {
				return BaseConverter.convert<{ success: boolean; volunteerslots: VolunteerSlot[] }>(
					await actions.put(API_URLS.VOLUNTEERS.UPDATE(volunteerId), data)
				);
			},
			deleteVolunteerSlot: async (id: string) => {
				return BaseConverter.convert<{ success: boolean }>(await actions.delete(API_URLS.VOLUNTEERS.DELETE(id)));
			},
			JoinLeaveVolunteerSlot: async (id: string, payload: { action: "join" | "leave"; quantity?: number }) => {
				return BaseConverter.convert<{ success: boolean }>(await actions.put(API_URLS.VOLUNTEERS.JOIN(id), payload));
			}
		}),
		[actions]
	);

	return useMemo(
		() => ({
			...methods
		}),
		[methods]
	);
};

export default useVolunteersApiService;
