import { Box } from "@material-ui/core";
import styled from "styled-components";

export const Wrapper = styled(Box)`
	width: 100%;

	overflow-x: auto;
	overflow-y: hidden;
`;

export const ScrollableWrapper = styled(Box)<{ width?: number }>`
	width: 100%;
	min-width: ${({ width }) => (width ? `${width}px` : "100%")};
`;
