import * as R from "ramda";

import { GroupFormModel, GroupModelRequest, LocationDetail, MediaModel } from "shared/types";

export const convertGroupRequest = ({
	data,
	groupImageMedia,
	groupPlaceDetails,
	isEdit = false
}: {
	data: GroupFormModel;
	groupImageMedia: MediaModel[];
	groupPlaceDetails: LocationDetail;
	isEdit?: boolean;
}): GroupModelRequest => {
	const convertPrivate = v => {
		switch (v) {
			case "public":
				return 0;
			case "private":
				return 1;
		}
	};

	const convertLocation = () => {
		const {
			geometry: {
				location: { lat, lng }
			},
			place_id
		} = groupPlaceDetails;

		const { location } = data;

		return {
			location: typeof location === "string" ? location : location?.description || location?.name,
			placeId: place_id,
			latitude: lat,
			longitude: lng
		};
	};

	return {
		payload: {
			...R.pick(["name", "rules", "chatEnabled", "cid", "parentId", "priceTags"], data),
			coverUrl: groupImageMedia?.length ? groupImageMedia[0]?.uri : data?.coverUrl || undefined,
			description: data.description ? data.description : undefined,
			rules: data.rules ? data.rules : undefined,
			hidden: data.hidden,
			disablePosts: data.disablePosts,
			private: convertPrivate(data.private),
			isNational: data.audience === "national",
			...convertLocation()
		},
		path: `/v${isEdit ? 2 : 3}/groups${isEdit ? `/${data.slug}` : ""}`,
		method: isEdit ? "PUT" : "POST",
		token: null
	};
};

export const extractGroupPayload = data => {
	return data.payload;
};
