/* eslint-disable react/no-unescaped-entities */
import React, { useCallback, useMemo } from "react";

import { useMediaQuery } from "@material-ui/core";
import styled from "styled-components";

import { MemberDialog } from "modules/MemberHome/View/shared";
import { MemberDialogContentWrapper } from "modules/MemberHome/View/shared/style";
import { Dialog } from "shared/Components";

import { useUser } from "shared/hooks";
import { GroupModel, ToolbarActions } from "shared/types";
import { Button, Text } from "shared/ui-kit";
import * as appTheme from "theme/default";

import SelectGroupBody from "./SelectGroupBody";

const CreateButton = styled(Button)`
	height: 48px;
	font-size: 16px;
	font-weight: 600;
	line-height: 1.25;
	color: #6173fe;
`;

interface Props {
	onClose: (event: {}, reason: string) => void;
	onSelect: (group: GroupModel) => void;
	open: boolean;
}

const SelectGroup: React.FC<Props> = ({ onClose, onSelect, open }) => {
	const isMobile = useMediaQuery(appTheme.default.breakpoints.down("sm"));

	const { getData: getUserData } = useUser();
	const { isMemberView } = getUserData();

	const onSelectGroup = useCallback(
		(data: GroupModel) => {
			setTimeout(() => {
				onSelect(data);
			}, 10);
		},
		[onSelect]
	);

	const dialogContent = useMemo(() => <SelectGroupBody onSelect={onSelectGroup} />, [onSelectGroup]);

	return isMemberView ? (
		<MemberDialog
			customWidth={512}
			title="Select Group"
			open={open}
			onClose={onClose}
			footerPrimary={{
				text: "Close",
				onClick: () => onClose({}, "")
			}}
		>
			<MemberDialogContentWrapper className="pt-5">{dialogContent}</MemberDialogContentWrapper>
		</MemberDialog>
	) : (
		<Dialog
			title={<Text variant={isMobile ? "h5" : "h3"}>Select Group</Text>}
			open={open}
			onClose={onClose}
			footer={
				<CreateButton buttonTheme="light" fullWidth onClick={e => onClose(e, ToolbarActions.ADD_GROUP)}>
					Create a new Group
				</CreateButton>
			}
		>
			{dialogContent}
		</Dialog>
	);
};

export default SelectGroup;
