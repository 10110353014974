import React, { useCallback, useEffect, useMemo, useState } from "react";

import { Box, useMediaQuery } from "@material-ui/core";

import { useLocation } from "react-router";
import styled from "styled-components";

import { useCategories } from "modules/Marketplace/Data";
import { CategoryProps } from "modules/Marketplace/Data/types";
import { CreateUpdateSeriesWrapper } from "shared/Components";
import { useSeries } from "shared/hooks";
import * as appTheme from "theme/default";

import { EntityTemplate, ModelBlock, PageWrapper, SectionHeadline } from "../../Components";
import { ModelBlockType } from "../../Components/ModelBlock";
import { ManInLotus, MessageText, NoConnectionsBlock, StyledButton } from "../Connections/style";

const Space = styled(Box)`
	margin: 16px 0;
`;

const defaultFilters = {
	limit: 20,
	keyword: ""
};

export interface CollectionsPageProps {
	groupId?: string;
}

const AllCollectionsPage: React.FC<CollectionsPageProps> = ({ groupId }) => {
	const {
		getCollections,
		getAllCollectionsCount,
		setSeriesDialog,
		isUserCouldManageSeries,
		getData: getSeriesData
	} = useSeries();
	const { series, seriesCount, isLoading } = getSeriesData();

	const { getCategory, getData: getCategoriesData } = useCategories();
	const { isLoading: loadingCategory } = getCategoriesData();

	const [offset, setOffset] = useState(1);
	const [loadData, setLoadData] = useState(true);
	const [category, setCategory] = useState<CategoryProps | null>(null);

	const hasMoreItems = useMemo(() => seriesCount > series.length, [seriesCount, series.length]);
	const isMobile = useMediaQuery(appTheme.default.breakpoints.down("sm"));

	const { search } = useLocation();
	const query = new URLSearchParams(search);
	const cId = query.get("cId");

	useEffect(() => {
		if (loadData) {
			getCollections({ ...defaultFilters, offset, categoryId: cId || undefined, paginate: true, groupId });
		}
	}, [getCollections, cId, loadData, offset, groupId]);

	useEffect(() => {
		if (cId) {
			getCategory(cId).then(cat => setCategory(cat));
		}
	}, [cId, getCategory]);

	useEffect(() => {
		getAllCollectionsCount({ categoryId: cId || undefined, groupId });
	}, [cId, getAllCollectionsCount, groupId]);

	const onEndScroll = useCallback(() => {
		if (hasMoreItems) {
			setOffset(offset => offset + 1);
			setLoadData(true);
		}
	}, [hasMoreItems]);

	return (
		<>
			<PageWrapper>
				{category && (
					<EntityTemplate
						loading={loadingCategory}
						coverPhoto={category.photo}
						pretitle={`${seriesCount} series`}
						title={category.name}
						summary={category.description}
					/>
				)}
				<Space />
				<SectionHeadline
					title={cId ? "Series in this category" : "Series and Collections"}
					actionButton={
						isUserCouldManageSeries({})
							? {
									text: "+ Create New Series",
									onClick: () => setSeriesDialog({ open: true })
							  }
							: undefined
					}
				/>
				<ModelBlock
					autoFit={seriesCount >= 3 ? 260 : undefined}
					gridGap={16}
					type={ModelBlockType.series}
					items={series || []}
					loading={isLoading}
					keepCurrentData={isLoading && offset > 1}
					onEndScroll={onEndScroll}
					inlineView={!isMobile}
					noContent={
						<NoConnectionsBlock>
							<ManInLotus />
							<MessageText>You don’t have any Series & Collections yet.</MessageText>
							{isUserCouldManageSeries({}) && (
								<StyledButton
									buttonTheme="light"
									palette="light"
									size="medium"
									removeSideMargin
									onClick={() => setSeriesDialog({ open: true })}
								>
									+ Create New Series
								</StyledButton>
							)}
						</NoConnectionsBlock>
					}
				/>
			</PageWrapper>
			<CreateUpdateSeriesWrapper />
		</>
	);
};

export default AllCollectionsPage;
