import React, { FC, useState } from "react";

import { Box, ButtonBase, ClickAwayListener, IconButton } from "@material-ui/core";
import { DragIndicator } from "@material-ui/icons";
import PauseIcon from "@material-ui/icons/Pause";

import { ReactComponent as SoundWaveIcon } from "assets/icons/iconSoundWave.svg";
import { ReactComponent as PlayTrackIcon } from "assets/icons/play-track.svg";
import { useAlbums, useTrack, useUser } from "shared/hooks";

import { TrackMeta, TrackModel } from "shared/types";
import { Icon, Text } from "shared/ui-kit";

import { secondsToHumanTime } from "utils/serviceUtils/helpers";

import { MoreActionsMenu, TrackInfoWrapper } from "./style";

const TrackInfo: FC<{
	track: TrackModel;
	lastItem: boolean;
	order: number;
	lastTrackRef: React.RefObject<HTMLDivElement> | null;
	hideDND?: boolean;
}> = ({ track, lastItem, order, hideDND, lastTrackRef }) => {
	const { setPlayTrack, setAlbumDetailsDialog, setTracksChanged, getData: getAlbumsData } = useAlbums();
	const { albumDetailsDialog, playTrack } = getAlbumsData();

	const { setTrackInfoPopup } = useTrack();
	const { getData: getUserData } = useUser();
	const { user } = getUserData();
	const [displayActions, setDisplayActions] = useState(false);

	const meta = track.meta as TrackMeta;

	const handleClickAway = () => {
		if (displayActions) {
			setDisplayActions(false);
		}
	};

	const removeFromAlbum = (id: string) => {
		if (playTrack === meta.track.url) {
			setPlayTrack();
		}

		setAlbumDetailsDialog({
			...albumDetailsDialog,
			deletedTracks: [...albumDetailsDialog.deletedTracks, id]
		});

		setTracksChanged(true);
	};

	return (
		<TrackInfoWrapper ref={lastTrackRef}>
			<Box className="track-name">
				{!hideDND && (
					<Box className="drag-icon-wrapper">
						<DragIndicator htmlColor="#c5cee0" />
					</Box>
				)}
				<Text variant="body2">{order}.</Text>

				{meta?.artwork?.url ? (
					<img src={meta.artwork.url} alt={track.title} />
				) : (
					<Box className="track-no-img">
						<SoundWaveIcon />
					</Box>
				)}
				<Box overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
					<Text variant="subtitle1">{track.title}</Text>
					<Text variant="body2">{meta.artist}</Text>
				</Box>
			</Box>
			<Box className="track-duration-actions">
				<Text variant="body2">{secondsToHumanTime(track.duration)}</Text>
				<Box display="flex" alignItems="center">
					{playTrack && playTrack === meta.track.url ? (
						<IconButton size="small" onClick={() => setPlayTrack()}>
							<PauseIcon htmlColor="#c5cee0" />
						</IconButton>
					) : (
						<IconButton size="small" onClick={() => setPlayTrack(meta.track.url)}>
							<PlayTrackIcon fill="#C5CEE0" />
						</IconButton>
					)}
					<ClickAwayListener onClickAway={handleClickAway}>
						<Box position="relative">
							<IconButton onClick={() => setDisplayActions(!displayActions)}>
								<Icon name="ellipsis" group="filled" fill="#c5cee0" />
							</IconButton>
							<MoreActionsMenu lastItem={lastItem} className={displayActions ? "show" : ""}>
								{user?.activeProfile === track.personaId && (
									<ButtonBase
										className="action"
										onClick={() => {
											setTrackInfoPopup({ open: true, model: track });
											setPlayTrack();
											setAlbumDetailsDialog({ open: false, deletedTracks: [] });
										}}
									>
										Edit Track Details
									</ButtonBase>
								)}
								<ButtonBase className="action" onClick={() => removeFromAlbum(track._id)}>
									Remove from Album
								</ButtonBase>
							</MoreActionsMenu>
						</Box>
					</ClickAwayListener>
				</Box>
			</Box>
		</TrackInfoWrapper>
	);
};

export default TrackInfo;
