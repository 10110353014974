import React, { FC, useCallback } from "react";

import * as R from "ramda";

import { useFundraisers, useMedia, useUser } from "shared/hooks";
import { useS3Uploader } from "shared/services/s3Uploader";
import { dataUrlToFile } from "utils/serviceUtils/helpers";

import CreateFundraiserManageView from "./ManageView";

const CreateUpdateFundraiser: FC<{
	handleClose: (created?: boolean) => void;
}> = ({ handleClose }) => {
	const { createFundraiser, updateFundraiser } = useFundraisers();
	const { uploadFile } = useS3Uploader();

	const { uploadFiles } = useMedia();

	const { getData: getUserData } = useUser();
	const { isMemberView } = getUserData();

	const onUploaderChange = useCallback(
		async (files: any[], onChange: (...event: any[]) => void, withId?: boolean) => {
			const img = files && files.length ? files[0] : null;

			const correctFile = typeof img === "string" ? await dataUrlToFile(img, "test") : img;
			if (img) {
				if (withId) {
					const files = await uploadFiles({ files: [correctFile], checkProgress: true });

					onChange({
						mediaId: files[0]._id,
						url: files[0].uri
					});
				} else {
					const data = await uploadFile({
						file: correctFile as File,
						communityName: "",
						checkProgress: true
					});

					onChange(data?.publicUrl);
				}
			} else {
				onChange(null);
			}
		},
		[uploadFile, uploadFiles]
	);

	const handleSubmit = useCallback(
		async data => {
			!!data._id ? await updateFundraiser(data._id, R.omit(["_id"], data)) : await createFundraiser(data);
			handleClose(true);
		},
		[createFundraiser, handleClose, updateFundraiser]
	);

	return isMemberView ? (
		<h1>Member</h1>
	) : (
		<CreateFundraiserManageView onClose={handleClose} onSubmit={handleSubmit} onUploaderChange={onUploaderChange} />
	);
};

export default CreateUpdateFundraiser;
