import config from "config/appConfig";
import { CompaniesContextValuesType } from "types";

import { IStateProps } from "utils/StateOf";

import createStore from "utils/createStore";

const localStorageKey = `${config.GLOBAL_CONSTANTS.LOCAL_STORAGE_PREFIX}${config.HOOK_STORAGE_KEYS.COMPANIES_DATA}`;

export const initialState: IStateProps<CompaniesContextValuesType> = {
	companiesPerPage: 20,
	page: 1,
	isLoading: false,
	companyImageUploadUrl: "",
	createUpdateCompanyOpen: false,
	companiesCount: 0,
	companies: []
};

export default createStore<CompaniesContextValuesType>({
	initialState,
	persistant: true,
	persistantName: localStorageKey
});
