import styled from "styled-components";

const Wrapper = styled.div`
	width: 100%;
	border-radius: 8px;
	border: 1px solid #e4e9f2;
	padding: 16px;
`;

const AttachmentList = styled.div`
	margin-top: 8px;
	display: flex;
	flex-wrap: wrap;
`;

const AttachmentThumbnailInner = styled.div`
	overflow: hidden;
	height: 100%;
	width: 100%;
	border-radius: 8px;
`;

const AttachmentThumbnailProgressWrapper = styled.div`
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const AttachmentImage = styled.img`
	object-fit: cover;
	max-width: 100%;
	height: 100%;
`;

const AttachmentUploadProgress = styled.div`
	position: absolute;
	background: #ffffff;
	width: 18px;
	height: 18px;
	border-radius: 50%;
	top: -5px;
	right: -5px;
`;

const AttachmentRemoveButton = styled.button`
	position: absolute;
	background: #ffffff;
	border-radius: 50%;
	top: -5px;
	right: -5px;
	cursor: pointer;
	border: 0;
	padding: 0;
	width: 20px;
	height: 20px;
`;

const AttachmentThumbnail = styled.div`
	border-radius: 8px;
	border: 1px solid #ccc;
	width: 60px;
	height: 60px;
	text-align: center;
	position: relative;
	margin-right: 10px;
	margin-bottom: 10px;

	&:hover {
		${AttachmentRemoveButton} {
			opacity: 1;
		}
	}
`;

const AddFile = styled.label<{ disabled: boolean }>`
	width: 48px;
	height: 48px;
	border-radius: 8px;
	background-color: #f7f9fc;
	display: block;
	cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
	display: flex;
	align-items: center;
	justify-content: center;
`;

export {
	Wrapper,
	AttachmentList,
	AttachmentThumbnail,
	AttachmentThumbnailInner,
	AttachmentThumbnailProgressWrapper,
	AttachmentImage,
	AttachmentUploadProgress,
	AttachmentRemoveButton,
	AddFile
};
