import React, { FC } from "react";

import { Elements } from "@stripe/react-stripe-js";
import { Appearance, StripeElementsOptions, loadStripe } from "@stripe/stripe-js";

import clsx from "clsx";
import config from "config/appConfig";
import { Control, DeepMap, FieldArrayDefaultValues, FieldError, FieldValues } from "react-hook-form";

import { Text } from "shared/ui-kit";

import CheckoutForm from "./CheckoutForm";
import { CCDetailsWrapper, StripeWrapper } from "./style";

const stripePromise = loadStripe(config.GLOBAL_CONSTANTS.STRIPE_PUB_KEY);

const StripeCheckout: FC<{
	control: Control<FieldValues>;
	errors: DeepMap<FieldArrayDefaultValues, FieldError>;
	clientSecret: string;
	donationId: string;
	contactId: string;
}> = ({ clientSecret, donationId, contactId }) => {
	const appearance: Appearance = {
		theme: "stripe"
	};

	const options: StripeElementsOptions = {
		clientSecret,
		appearance
	};

	return (
		<CCDetailsWrapper className={clsx(false && "pt-6")}>
			{clientSecret && (
				<StripeWrapper>
					<Elements options={options} stripe={stripePromise}>
						<CheckoutForm donationId={donationId} contactId={contactId} />
					</Elements>
				</StripeWrapper>
			)}
			<Text variant="body2" className="text-gray-500 bold text-center">
				Charge will appear as Vyoo.me on your bill
			</Text>
		</CCDetailsWrapper>
	);
};

export default StripeCheckout;
