import styled from "styled-components";

import { Box, Text } from "shared/ui-kit";

export const ItemWrapper = styled(Box)`
	display: flex;
	align-items: center;
`;

ItemWrapper.IconContainer = styled(Box)`
	border-radius: 12px;
	border: solid 0.5px #c5cee0;
	width: 80px;
	height: 80px;
	margin: 0 16px 0 0;
	padding: 14px;
	background-color: #c5cee0;
`;
ItemWrapper.TextContainer = styled(Box)`
	display: flex;
	flex-direction: column;
`;
ItemWrapper.Heading = styled(Text)`
	font-size: 15px;
	font-weight: 600;
	color: #222b45;
	margin-bottom: 4px;
`;
ItemWrapper.Text = styled(Text)`
	font-size: 13px;
	color: #8f9bb3;
`;
