import React, { FC, useEffect, useMemo, useRef, useState } from "react";

import { Box, TextField } from "@material-ui/core";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { DatePicker, TimePicker } from "@material-ui/pickers";
import { DateTime } from "luxon";
import { Controller, useForm, useWatch } from "react-hook-form";
import { css } from "styled-components";
import { FileType } from "types/FilesContextValuesType";

import { ReactComponent as DownloadIcon } from "assets/icons/icon-download.svg";

import { CategoryProps } from "modules/Marketplace/Data/types";
import { Dialog, SetupPrice, UploadProgressBar } from "shared/Components";
import ConfirmLeavePopup from "shared/Components/ConfirmLeave";
import useConfirmLeavePopup from "shared/Components/ConfirmLeave/hooks/useConfirmLeavePopup";
import RadioItem from "shared/Components/RadioItem";
import { SetupPriceMethods } from "shared/Components/SetupPrice";
import { IconWrapper, StyledActionButton, UploadProgressBarWrapper } from "shared/Components/Video/style";
import { useCommunity, useFiles } from "shared/hooks";
import useScrollToXPosition from "shared/hooks/useScrollToXPosition";
import { useS3Uploader } from "shared/services/s3Uploader";
import { GroupModel } from "shared/types";
import { Icon, Input, Loader, Switch, Text, Uploader, orientationConst } from "shared/ui-kit";
// import { orientationConst } from "shared/ui-kit";

import { FeatureBlock, InputWrapper, SubText, UploaderWrapper } from "./style";

interface Props {
	open: boolean;
	editableModel?: FileType;
	onClose: (
		event: {
			created?: boolean;
		},
		reason?: string | undefined
	) => void;
	uploadingCover: boolean;
	onSubmit: (data: any) => Promise<void>;
	onUploaderChange: (files: FileList, onChange: (...event: any[]) => void) => Promise<void>;
	setupPriceFormRef: React.RefObject<SetupPriceMethods>;
	suggestedCategories: CategoryProps[];
	loadSuggestedCategories: (name: string) => Promise<void>;
}

const CreateFileManageView: FC<Props> = ({
	onClose,
	open,
	editableModel,
	uploadingCover,
	onSubmit,
	onUploaderChange,
	setupPriceFormRef,
	suggestedCategories,
	loadSuggestedCategories
}) => {
	const bodyRef = useRef<HTMLFormElement>();
	const scrollToXPositionOnFocus = useScrollToXPosition(bodyRef?.current);
	const {
		control,
		reset,
		formState: { errors, isSubmitting, isDirty, isValid },
		handleSubmit,
		watch,
		setError,
		clearErrors
	} = useForm({
		mode: "onChange"
	});

	const { getData: getCommunityData } = useCommunity();
	const { isFree } = getCommunityData();

	const {
		handleClose,
		handleLeavePageConfirmed,
		closeConfirmPopup,
		getData: getConfirmLeavePopupData
	} = useConfirmLeavePopup({
		onClose: () => {
			onClose({ created: false }, "");
		},
		open
	});

	const { showConfirmPopup } = getConfirmLeavePopupData();

	const [step, setStep] = useState(1);

	const { setFileUrl, getData: getFilesData } = useFiles();
	const { fileName } = getFilesData();

	const { getData: getS3UploaderData } = useS3Uploader();
	const { uploading, uploadingProgress } = getS3UploaderData();

	const { scheduleDate, scheduleTime, price, schedule } = watch(["scheduleTime", "scheduleDate", "price", "schedule"]);

	const options = useMemo(
		() => suggestedCategories.map((cat, index) => ({ ...cat, optionId: index })),
		[suggestedCategories]
	);

	useEffect(() => {
		if (open) {
			setStep(1);
			loadSuggestedCategories("");
		}
	}, [open, loadSuggestedCategories]);

	useEffect(() => {
		if (editableModel) {
			reset({
				...editableModel,
				coverPhotoUrl: editableModel?.coverPhoto?.url,
				category: editableModel?.categoryId
					? { name: editableModel?.category?.name, _id: editableModel?.category?._id }
					: undefined
			});
			setFileUrl(editableModel?.file?.url);
		} else {
			reset(
				fileName
					? {
							title: fileName
					  }
					: {}
			);
		}
	}, [editableModel, reset, fileName, setFileUrl]);

	useEffect(() => {
		if (scheduleDate && scheduleTime) {
			const date = new Date(scheduleDate).setHours(
				new Date(scheduleTime).getHours(),
				new Date(scheduleTime).getMinutes()
			);
			if (date < new Date().getTime()) {
				setError("scheduleDate", {
					message: "Time can't be in the past"
				});
				setError("scheduleTime", {
					message: " "
				});
			} else {
				clearErrors(["scheduleDate", "scheduleTime"]);
			}
		}
	}, [scheduleDate, scheduleTime, setError, clearErrors]);

	const category = useWatch({ control, name: "category" });
	const description = useWatch({ control, name: "description" });

	const ActionButton = (
		<StyledActionButton
			type="submit"
			onClick={() => (step === 1 ? setStep(2) : handleSubmit(onSubmit)())}
			leftIcon={
				isSubmitting || uploading || uploadingCover ? (
					<Loader size="1rem" show={true} color="primary" variant="indeterminate" />
				) : undefined
			}
			disabled={
				(step === 1 ? errors?.title || errors?.category || !description || !category : !isValid || !isDirty) ||
				uploading ||
				isSubmitting ||
				uploadingCover
			}
			id={step === 1 ? "next" : editableModel ? "updateFile" : "createFile"}
		>
			{step === 1 ? "Next" : editableModel ? "Update File" : "Create File"}
		</StyledActionButton>
	);

	const Title = useMemo(
		() => (
			<>
				<Text variant="h7">{editableModel ? "Update" : "Create"} File</Text> <SubText>• Step {step}/2</SubText>
			</>
		),
		[editableModel, step]
	);

	return (
		<>
			{open && (
				<ConfirmLeavePopup
					handleLeavePage={handleLeavePageConfirmed}
					open={showConfirmPopup}
					onClose={closeConfirmPopup}
					popup
				/>
			)}
			<Dialog
				title={Title}
				hasBackButton={step === 2}
				onBack={() => setStep(1)}
				open={open}
				onClose={() => handleClose(true)}
				footer={ActionButton}
				bodyCustomStyles={css`
					position: relative;
				`}
				maxWidth={"sm"}
				bodyRef={bodyRef}
			>
				<UploadProgressBarWrapper>
					<UploadProgressBar progress={uploadingProgress} successText={"Your File has been successfully uploaded!"} />
				</UploadProgressBarWrapper>
				<form onSubmit={handleSubmit(onSubmit)}>
					<Box display={step === 1 ? "block" : "none"}>
						<UploaderWrapper>
							<Controller
								name="coverPhotoUrl"
								control={control}
								render={({ onChange, value }) => (
									<Uploader
										urls={
											!value && editableModel && editableModel?.coverPhoto?.url
												? [`${editableModel.coverPhoto.url}`]
												: value
												? [value]
												: undefined
										}
										onChange={(files: any) => onUploaderChange(files, onChange)}
										label="Upload Cover Photo"
										orientation={orientationConst.horizontal}
										width="100%"
										height="auto"
										description={
											<>
												Drag and Drop File Here or <span className="anchor">Browse</span> to Choose a File
											</>
										}
										accept={[
											{
												fileType: "image/png, image/jpeg, image/x-eps",
												name: "png, jpg, eps"
											}
										]}
										icon={<Icon fill="#c5cee0" name="cloud-upload" width={64} height={50} viewBox="4 2 18 19" />}
									/>
								)}
							/>
						</UploaderWrapper>
						<InputWrapper>
							<Controller
								name="title"
								rules={{
									required: "Title is required!",
									maxLength: {
										value: 100,
										message: "Title can't be more than 100 letters"
									}
								}}
								control={control}
								render={({ onChange, value }) => (
									<Input
										errorText={errors.title?.message}
										label="File title*"
										onChange={onChange}
										value={value}
										onFocus={scrollToXPositionOnFocus}
										id="fileName"
									/>
								)}
							/>
						</InputWrapper>
						<InputWrapper>
							<Controller
								name="category"
								defaultValue={null}
								control={control}
								rules={{ required: "Category is required!", validate: value => !!value }}
								render={({ onChange, value }) => (
									<Autocomplete
										getOptionLabel={option => (typeof option === "string" ? option : option.name)}
										options={options}
										autoComplete
										fullWidth
										includeInputInList
										filterSelectedOptions
										value={value}
										onChange={(_, newValue: GroupModel | null) => onChange(newValue)}
										onInputChange={(_, newInputValue) => loadSuggestedCategories(newInputValue)}
										id="selectCategory"
										renderInput={params => (
											<TextField
												{...params}
												label="Select Category*"
												variant="outlined"
												fullWidth
												error={errors?.category}
												helperText={errors?.category?.message}
												onFocus={scrollToXPositionOnFocus}
											/>
										)}
										renderOption={option => <Text id={`category${option.optionId}`}>{option.name}</Text>}
									/>
								)}
							/>
						</InputWrapper>
						<InputWrapper>
							<Controller
								name="description"
								defaultValue={""}
								control={control}
								rules={{
									required: "Description is required.",
									maxLength: {
										value: 1000,
										message: "Description can't be more than 1000 letters"
									}
								}}
								render={({ onChange, value }) => (
									<TextField
										error={errors.description}
										helperText={errors.description?.message}
										name="description"
										onChange={onChange}
										value={value}
										label="Description *"
										variant="outlined"
										multiline
										rows={3}
										onFocus={scrollToXPositionOnFocus}
										id="fileDescription"
									/>
								)}
							/>
						</InputWrapper>
					</Box>
					<Box display={step === 2 ? "block" : "none"}>
						{!isFree && (
							<Controller
								name="price"
								control={control}
								defaultValue={!!editableModel?.priceTags?.length}
								render={({ onChange, value }) => (
									<FeatureBlock>
										<RadioItem
											control={
												<Switch defaultChecked={value} onChange={e => onChange(e.target.checked)} id="setPrice" />
											}
											value={value}
											label="Set a Price"
											icon={<MonetizationOnIcon />}
										/>
										{price && (
											<Controller
												name="priceChanged"
												control={control}
												render={({ onChange }) => (
													<SetupPrice
														ref={setupPriceFormRef}
														title={"Who can access this file?"}
														defaultValue={editableModel?.priceTags}
														onPriceChanged={() => onChange(true)}
													/>
												)}
											/>
										)}
									</FeatureBlock>
								)}
							/>
						)}
						<Controller
							name="schedule"
							control={control}
							defaultValue={!!editableModel?.scheduleDate}
							render={({ onChange, value }) => (
								<FeatureBlock>
									<RadioItem
										control={
											<Switch defaultChecked={value} onChange={e => onChange(e.target.checked)} id="scheduleFile" />
										}
										value={value}
										label="Schedule File"
										icon={<Icon group={""} fill={"#8f9bb3"} name={"clock"} width={24} height={24} />}
									/>
									{schedule && (
										<Box className="input multiple">
											<Controller
												name="scheduleDate"
												rules={{ required: schedule ? "Schedule Date is required" : false }}
												control={control}
												defaultValue={editableModel?.scheduleDate}
												render={({ onChange, value = null }) => (
													<DatePicker
														placeholder="Schedule Date"
														autoOk
														minDate={DateTime.now()}
														inputVariant="outlined"
														variant="inline"
														value={value}
														onChange={d => onChange(d)}
														error={!!errors.scheduleDate}
														helperText={errors.scheduleDate?.message}
													/>
												)}
											/>
											<Controller
												name="scheduleTime"
												rules={{ required: schedule ? "Schedule Time is required" : false }}
												control={control}
												defaultValue={editableModel?.scheduleDate}
												render={({ onChange, value = null }) => (
													<TimePicker
														placeholder="Select Time"
														autoOk
														inputVariant="outlined"
														variant="inline"
														value={value}
														onChange={d => onChange(d)}
														error={!!errors.scheduleTime}
														helperText={errors.scheduleTime?.message}
													/>
												)}
											/>
										</Box>
									)}
								</FeatureBlock>
							)}
						/>
						<Controller
							name="canBeDownloaded"
							control={control}
							defaultValue={editableModel?.canBeDownloaded}
							render={({ onChange, value }) => (
								<FeatureBlock>
									<RadioItem
										control={
											<Switch defaultChecked={value} onChange={e => onChange(e.target.checked)} id="downloadFile" />
										}
										value={value}
										label="Allow Download"
										icon={
											<IconWrapper className={"fill-second"}>
												<DownloadIcon />
											</IconWrapper>
										}
									/>
								</FeatureBlock>
							)}
						/>
						<Controller
							name="private"
							control={control}
							defaultValue={editableModel?.private}
							render={({ onChange, value }) => (
								<FeatureBlock noBorder>
									<RadioItem
										control={
											<Switch defaultChecked={value} onChange={e => onChange(e.target.checked)} id="privateFile" />
										}
										value={value}
										label="Set as Private"
										icon={
											<IconWrapper>
												<VisibilityIcon />
											</IconWrapper>
										}
										helpText={"Choose this option to hide from others in the community"}
									/>
								</FeatureBlock>
							)}
						/>
					</Box>
				</form>
			</Dialog>
		</>
	);
};

export default CreateFileManageView;
