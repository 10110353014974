import React, { useCallback } from "react";

import { ForumPostModel } from "types";

import { Comment } from "./style";

import { CommentEditor } from "../index";

export interface NewCommentProps {
	avatarUrl?: string;
	onPostNewComment: ({
		content,
		toPid,
		clbk
	}: {
		content: string;
		toPid?: string;
		clbk?: (post: ForumPostModel) => void;
	}) => void;
	replyTo?: { toPid: string; toAuthorId: number; toAuthorName: string };
	onCancel?: () => void;
	subreply?: boolean;
}

const NewComment: React.FC<NewCommentProps> = ({ avatarUrl, onPostNewComment, replyTo, onCancel, subreply }) => {
	const handleSubmit = useCallback(
		(content: string) => {
			onPostNewComment({ content });
		},
		[onPostNewComment]
	);

	return (
		<Comment.Wrapper>
			<Comment.AvatarWrapper style={{ backgroundImage: `url(${avatarUrl})` }} className={subreply && "subreply"} />
			<Comment.InputWrapper className={subreply && "subreply"}>
				<CommentEditor onSubmit={handleSubmit} replyTo={replyTo} onCancel={onCancel} />
			</Comment.InputWrapper>
		</Comment.Wrapper>
	);
};

export default NewComment;
