import { useCallback, useMemo } from "react";

import { useHistory } from "react-router";

import { routes as useRoutes } from "apps/RegularUser/constants";

import { StepType } from "shared/contexts/GuidedTour/GuideContext";
import { useMemberRoutes, useUser } from "shared/hooks";
import { useUserApiService } from "shared/services";

import useCommunity from "./useCommunity";

import { useGuidedStore } from "../contexts";
import useLocalStorage from "../services/localStorage/localStorage";

const useGuidedTour = () => {
	const history = useHistory();
	const { setPassedGuidedTour, getData: getUserData } = useUser();
	const { globalUserId } = getUserData();
	const { getData: getCommnunityData } = useCommunity();
	const { isWhitelabelAppMode } = getCommnunityData();

	const { getMemberRoutesData } = useMemberRoutes();
	const { routes } = getMemberRoutesData();

	const { setGlobal } = useLocalStorage();

	const { updateTourStatus } = useUserApiService();

	const store = useGuidedStore();
	const { setState } = useGuidedStore();

	const methods = useMemo(
		() => ({
			provideStepsRefs: (steps: StepType[]) => {
				setState(ctx => ({ ...ctx, steps }));
			},
			// provideStepDetails: (stepDetails: StepType) => {
			// 	const step = store.steps.find(({ step }) => step === stepDetails.step);
			//
			// 	if (!step) {
			// 		setState(ctx => ({ ...ctx, steps: [stepDetails, ...ctx.steps] }));
			// 	}
			// },
			// findStepRect: (step?: string) => {
			// 	if (!step) return null;
			//
			// 	const rect = store.steps.find(st => st.step === step);
			//
			// 	if (rect && rect.bottom === 0 && rect.top === 0 && rect.left === 0 && rect.right === 0 && rect.width === 0) {
			// 		const { top, left, bottom, right, width, height } = rect.stepEl.getBoundingClientRect();
			// 		return { top, left, bottom, right, width, height, step: rect.step, fullRing: rect.fullRing };
			// 	}
			//
			// 	return rect;
			// },
			setCurrentStep: (currentStep: string) => {
				setState(ctx => ({ ...ctx, currentStep }));
			},
			setTourStarted: (tourStarted: boolean) => {
				setState(ctx => ({ ...ctx, tourStarted }));
			},
			setShowWelcomeDialog: (showWelcomeDialog?: { admin: boolean; member: boolean }) => {
				setState(ctx => ({ ...ctx, showWelcomeDialog }));
			},
			setPopupHeight: (popupHeight: number) => {
				setState(ctx => ({ ...ctx, popupHeight }));
			},
			setDisplayPopup: (displayPopup: boolean) => {
				setState(ctx => ({ ...ctx, displayPopup }));
			},
			setPassedGuidedTour: (passedGuidedTour: { admin: boolean; member: boolean }) => {
				setState({ passedGuidedTour });
				if (isWhitelabelAppMode) {
					setGlobal("akina_passed_guided_tour_object", JSON.stringify(passedGuidedTour));
				}
			},
			setChecklistOpen: (checklistOpen: boolean) => {
				setState({ checklistOpen });
			},
			setChecklistAutoOpen: (checklistAutoOpen: boolean) => {
				setState({ checklistAutoOpen });
			}
		}),
		[setState, isWhitelabelAppMode, setGlobal]
	);

	const startTour = useCallback(
		(
			isMember: boolean,
			stepsContent: {
				step: string;
				headline: string;
				content: string;
			}[]
		) => {
			setState(ctx => ({
				...ctx,
				showWelcomeDialog: undefined,
				tourStarted: true,
				currentStep: "switcher",
				stepsContent
			}));
			setTimeout(() => {
				history.push(isMember ? routes?.member.home.getPath() : useRoutes.dashboard.getPath());
			}, 250);
		},
		[routes?.member.home, history, setState]
	);

	const tourFinished = useCallback(
		async (member: boolean) => {
			try {
				if (globalUserId) {
					const { success } = await updateTourStatus(member ? "member" : "admin", true, globalUserId);
					if (success) {
						setPassedGuidedTour(true);
						setState(ctx => ({
							...ctx,
							tourStarted: false,
							passedGuidedTour: {
								member,
								admin: !member
							},
							checklistOpen: true
						}));
					}
				} else {
					history.push(useRoutes.user.signIn.getPath());
				}
			} catch (error) {
				console.error(error);
			}
		},
		[globalUserId, setPassedGuidedTour, history, setState, updateTourStatus]
	);

	const findStepRect = useCallback(
		(step?: string) => {
			if (!step) return null;

			const rect = store.steps.find(st => st.step === step);

			if (rect && rect.bottom === 0 && rect.top === 0 && rect.left === 0 && rect.right === 0 && rect.width === 0) {
				const { top, left, bottom, right, width, height } = rect.stepEl.getBoundingClientRect();
				return { top, left, bottom, right, width, height, step: rect.step, fullRing: rect.fullRing };
			}

			return rect;
		},
		[store.steps]
	);

	const getData = useCallback(() => {
		return store;
	}, [store]);

	return { ...methods, getData, findStepRect, tourFinished, startTour };
};

export default useGuidedTour;
