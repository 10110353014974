import React, { FC, useEffect, useMemo, useState } from "react";

import clsx from "clsx";

import { ReactComponent as IconCrown } from "assets/icons/icon-crown.svg";
import { PlaceholderImage } from "shared/Components";
import { TableColumnLoaderType } from "shared/Components/NewTable/Components";
import { Cell } from "shared/Components/NewTable/style";
import { useFundraisers } from "shared/hooks";
import { LeaderboardGroup, PlaceholderImageType } from "shared/types";
import { formatMoney } from "utils/formatMoney";
import { formatCount } from "utils/serviceUtils/helpers";

const Table = React.lazy(() => import("shared/Components/NewTable"));

const GroupsTable: FC<{ show: boolean; workspace?: string | null }> = ({ show, workspace }) => {
	const [tableData, setTableData] = useState<LeaderboardGroup[]>([]);
	const [isLoading, setIsLoading] = useState(true);

	const { getLeaderboardGroups, getData: getFundraiserData } = useFundraisers();
	const { fundraiser } = getFundraiserData();

	useEffect(() => {
		if (show && !tableData.length && fundraiser) {
			setIsLoading(true);
			getLeaderboardGroups(fundraiser._id, workspace).then(data => {
				data && setTableData(data.filter(g => !!g.totalRaised.length && !!g.totalRaised[0].totalRaised));
				setIsLoading(false);
			});
		}
	}, [fundraiser, getLeaderboardGroups, show, tableData.length, workspace]);

	const tableColumns = useMemo(
		() => [
			{
				alignment: "left",
				width: 338,
				label: <Cell.HeaderText>{formatCount(tableData.length, "Group")}</Cell.HeaderText>,
				Cell: ({ rowData, rowIndex }: { rowData: LeaderboardGroup; rowIndex: number }) => (
					<Cell.Wrapper>
						<Cell.Order className="mr-3">{rowIndex + 1}.</Cell.Order>
						<div className="relative">
							<Cell.ImageWrapper className="w-12 h-12">
								{!!rowData.coverUrl?.length ? (
									<Cell.Image src={rowData.coverUrl} className="rounded-xl" />
								) : (
									<PlaceholderImage
										type={PlaceholderImageType.PROFILE_IMAGE}
										width={40}
										height={40}
										viewBox={"0 0 400 400"}
									/>
								)}
							</Cell.ImageWrapper>
							{rowIndex < 3 && (
								<div className="absolute -top-2 -left-2 bg-white rounded-full p-1">
									<IconCrown
										className={clsx(`leaderboard-crown-${rowIndex + 1}`, rowIndex === 0 ? "h-5 w-5" : "h-4 w-4")}
									/>
								</div>
							)}
						</div>
						<Cell.Wrapper className="column with-image">
							<Cell.Text className="bold">{rowData.name}</Cell.Text>
							<Cell.Text className="light">{rowData.totalMembers} Members</Cell.Text>
						</Cell.Wrapper>
					</Cell.Wrapper>
				),
				loaderTemplate: TableColumnLoaderType.imageWthOneTextRow,
				dataKey: "content"
			},
			{
				alignment: "left",
				minWidth: 120,
				label: <Cell.HeaderText>Raised amount</Cell.HeaderText>,
				Cell: ({ rowData }: { rowData: LeaderboardGroup }) => (
					<Cell.Wrapper>
						<Cell.Text>
							{!!rowData.totalRaised?.length ? formatMoney(rowData.totalRaised[0].totalRaised) : "-"}
						</Cell.Text>
					</Cell.Wrapper>
				),
				dataKey: "raisedAmount"
			},
			{
				alignment: "left",
				minWidth: 120,
				label: <Cell.HeaderText>Sponsors</Cell.HeaderText>,
				Cell: ({ rowData }: { rowData: LeaderboardGroup }) => (
					<Cell.Wrapper>
						<Cell.Text>{!!rowData.totalRaised?.length ? rowData.totalRaised[0].totalSponsors : "-"}</Cell.Text>
					</Cell.Wrapper>
				),
				dataKey: "sponsors"
			},
			{
				alignment: "left",
				minWidth: 120,
				label: <Cell.HeaderText># of Donations</Cell.HeaderText>,
				Cell: ({ rowData }: { rowData: LeaderboardGroup }) => (
					<Cell.Wrapper>
						<Cell.Text>{!!rowData.totalRaised?.length ? rowData.totalRaised[0].totalDonations : "-"}</Cell.Text>
					</Cell.Wrapper>
				),
				dataKey: "Group"
			}
		],
		[tableData.length]
	);

	return (
		<Table
			columns={tableColumns}
			data={tableData}
			loading={isLoading}
			totalDataCount={20}
			page={1}
			pageSize={20}
			onChangePage={() => {}}
			onChangePageSize={() => {}}
		/>
	);
};

export default GroupsTable;
