import React, { useState } from "react";

import { IconButton } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import clsx from "clsx";
import { Controller, useForm } from "react-hook-form";

import { useAuth } from "modules/App/Data";

import { PasswordInstructions } from "modules/App/View/Components";
import { useCommunity, useUser } from "shared/hooks";
import { Icon, Input } from "shared/ui-kit";

import { validators } from "shared/ui-kit/utils/dynamicForm/validators";
import { validateEmail } from "utils/serviceUtils/validators";

import { FormActions, FormButton, FormWrapper } from "./commonStyles";
import { DialogCloseButton, DialogSubtitle, DialogTitle, DialogWrapper, InputWrapper } from "./styles";

enum Step {
	CONFIRM_VERIFICATION,
	CHANGE_EMAIL
}

interface Props {
	setConfirmChangeEmailDialogShow: React.Dispatch<React.SetStateAction<boolean>>;
}

const EmailChangeProcess = (props: Props) => {
	const [step, setStep] = useState<Step>(Step.CONFIRM_VERIFICATION);
	const [showPassword, setShowPassword] = useState(false);
	const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
	const { setConfirmChangeEmailDialogShow } = props;

	const { getData: getUserData, setUser, setUserVerified } = useUser();
	const { user, globalUserId } = getUserData();

	const { verifyUserForEmailUpdate, updateEmail, getUser, setEmail } = useAuth();
	const { getData: getCommunityData } = useCommunity();
	const { isWhitelabelAppMode } = getCommunityData();

	const {
		control,
		handleSubmit,
		watch,
		formState: { isValid, isSubmitting, errors }
	} = useForm({
		mode: "onChange"
	});
	const { password } = watch(["password"]);

	const onVerifyPasswordSubmit = handleSubmit(async data => {
		if (user?.email) {
			try {
				const { success, token } = await verifyUserForEmailUpdate(user.email, data.password);
				if (success || token) {
					setStep(Step.CHANGE_EMAIL);
				}
			} catch (error) {}
		}
	});

	const onEmailChangeSubmit = handleSubmit(async data => {
		try {
			const { success } = await updateEmail(data.email, isWhitelabelAppMode ? user?.userId : globalUserId);
			if (success) {
				const _user = await getUser();
				setUserVerified(_user.verified || false);
				setUser(_user);
				setEmail(_user.email);
				setConfirmChangeEmailDialogShow(false);
			}
		} catch (error) {}
	});

	const togglePasswordShow = () => {
		setShowPassword(!showPassword);
	};

	const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	return (
		<>
			<DialogWrapper open>
				{step === Step.CONFIRM_VERIFICATION && (
					<>
						<DialogTitle>Confirm Password</DialogTitle>
						<DialogSubtitle>Confirm your password to be able to change email.</DialogSubtitle>
						<FormWrapper>
							<form onSubmit={onVerifyPasswordSubmit}>
								<PasswordInstructions password={password} anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
								<Controller
									name="password"
									control={control}
									rules={{
										required: "Field is required.",
										minLength: {
											value: 8,
											message: "Password length should be between 8 to 20"
										},
										maxLength: {
											value: 20,
											message: "Password length should be between 8 to 20"
										},
										validate: validators.password
									}}
									render={({ onChange, value }) => (
										<InputWrapper>
											<Input
												label="Password"
												errorText={errors.password?.message}
												type="password"
												value={value}
												onChange={onChange}
												onFocus={handlePopoverOpen}
											/>
											<IconButton className={clsx("show-password mt-0", errors.password?.message && "mb-4")}>
												<Icon
													name={showPassword ? "eye-slash" : "eye"}
													group="filled"
													fill="#c5cee0"
													onClick={togglePasswordShow}
												/>
											</IconButton>
										</InputWrapper>
									)}
								/>
								<FormActions>
									<FormButton type="submit" fullWidth buttonTheme="light" disabled={!isValid || isSubmitting}>
										{isSubmitting ? <CircularProgress size={20} /> : "Continue"}
									</FormButton>
								</FormActions>
							</form>
						</FormWrapper>
					</>
				)}
				{step === Step.CHANGE_EMAIL && (
					<>
						<DialogTitle>Change Email Address</DialogTitle>
						<DialogSubtitle>Use your email address to log in.</DialogSubtitle>
						<FormWrapper>
							<form onSubmit={onEmailChangeSubmit}>
								<Controller
									name="email"
									control={control}
									rules={{ required: "Field is required.", validate: value => validateEmail(value) }}
									render={({ onChange, value }) => (
										<InputWrapper>
											<Input
												name="email"
												id="email"
												label="New email address"
												value={value}
												onChange={onChange}
												error={errors?.email?.message}
												placeholder=""
											/>
										</InputWrapper>
									)}
								/>
								<FormActions>
									<FormButton type="submit" fullWidth buttonTheme="light" disabled={!isValid || isSubmitting}>
										{isSubmitting ? <CircularProgress size={20} /> : "Save"}
									</FormButton>
								</FormActions>
							</form>
						</FormWrapper>
					</>
				)}
				<DialogCloseButton onClick={() => setConfirmChangeEmailDialogShow(false)}>
					<Icon name="close" group="filled" fill="#8f9bb3" />
				</DialogCloseButton>
			</DialogWrapper>
		</>
	);
};

export default EmailChangeProcess;
