import config from "config/appConfig";
import { CommunityModel, PriceTagType } from "types";

import { IStateProps } from "utils/StateOf";

import createStore from "utils/createStore";

import {
	CheckListModel,
	CommunityThemeType,
	CommunityType,
	MonetizationPlansType,
	WorkspaceProductModel
} from "../../types";

const localStorageKey = `${config.GLOBAL_CONSTANTS.LOCAL_STORAGE_PREFIX}${config.HOOK_STORAGE_KEYS.NEW_COMMUNITY_DATA}`;

export interface Values {
	name: string;
	url: string;
	img: File | string | null;
	theme: CommunityThemeType;
	takenUrl: boolean;
	loadingSuggestionList: boolean;
	loadingCheckList: boolean;
	urlSuggestionList: string[];
	selectedTypes: CommunityType[];
	typeList: CommunityType[] | null;
	created: boolean;
	creating: boolean;
	isPrivate: boolean;
	monetizationPlans?: MonetizationPlansType[];
	isFree?: boolean;
	workspace?: CommunityModel;
	defaultTags: PriceTagType[];
	coins: WorkspaceProductModel[];
	bags: WorkspaceProductModel[];
	subscriptionProducts: WorkspaceProductModel[];
	subscriptionCanMakeFree?: boolean;
	canMakeFree?: boolean;
	haveActiveMembers?: boolean;
	setupProgress?: number;
	isLoading: boolean;
	isConfirmedCaptcha: boolean;
	isSubmittedCreatedCommunity: boolean;
	checklist: CheckListModel[];
	showJoinPopup: boolean;
	searching: boolean;
	searchedCommunities: CommunityModel[];
	isSportsMode?: boolean;
	mainAdminColor?: string;
}

export const initialState: IStateProps<Values> = {
	name: "",
	url: "",
	img: null,
	theme: {
		primaryColor: "",
		primaryBtnColor: ""
	},
	urlSuggestionList: [],
	selectedTypes: [],
	typeList: null,
	takenUrl: false,
	created: false,
	creating: false,
	loadingSuggestionList: false,
	loadingCheckList: false,
	isPrivate: false,
	defaultTags: [],
	coins: [],
	bags: [],
	subscriptionProducts: [],
	setupProgress: undefined,
	isLoading: false,
	isConfirmedCaptcha: false,
	isSubmittedCreatedCommunity: false,
	checklist: [],
	showJoinPopup: false,
	searching: false,
	searchedCommunities: [],
	isFree: true,
	isSportsMode: false,
	mainAdminColor: undefined
};

export default createStore<Values>({
	initialState,
	persistant: true,
	persistantName: localStorageKey
});
