import config from "config/appConfig";
import { FeatureContextValues } from "types";

import { CommunityCategoryType } from "shared/types/CommunityType";
import { IStateProps } from "utils/StateOf";

import createStore from "utils/createStore";

const localStorageKey = `${config.GLOBAL_CONSTANTS.LOCAL_STORAGE_PREFIX}${config.HOOK_STORAGE_KEYS.FEATURE_DATA}`;

export const initialState: IStateProps<FeatureContextValues> = {
	features: [],
	allFeatures: [],
	selectedFeatures: [],
	autoConnectData: [],
	loadingDetails: false,
	autoMessageData: {
		promotionText: "",
		conversationPersonas: []
	},
	defaultSelectedFeatures: {
		[CommunityCategoryType.Artist]: [
			"enableChat",
			"enableAudioTracks",
			"enableForum",
			"enableStories",
			"communityChatEnabled",
			"enableEvents",
			"enableCollections",
			"isLoyaltyEnabled"
		],
		[CommunityCategoryType.GameCreator]: [
			"enableChat",
			"enableForum",
			"enableStories",
			"communityChatEnabled",
			"enableEvents",
			"enableCollections",
			"enablePolls",
			"enableConnecting",
			"isLoyaltyEnabled"
		],
		[CommunityCategoryType.Podcaster]: [
			"enableChat",
			"enableAudioTracks",
			"enableForum",
			"enableStories",
			"communityChatEnabled",
			"enableEvents",
			"enableCollections",
			"enablePolls",
			"enableConnecting"
		],
		[CommunityCategoryType.NonprofitOrg]: [
			"enableChat",
			"enableGroups",
			"enableForum",
			"communityChatEnabled",
			"enableEvents",
			"enableCvGenerator",
			"enableCollections",
			"enablePolls",
			"enableConnecting"
		],
		[CommunityCategoryType.Musician]: [
			"enableChat",
			"enableAudioTracks",
			"enableForum",
			"enableStories",
			"communityChatEnabled",
			"enableEvents",
			"enableCollections",
			"enablePolls",
			"enableConnecting",
			"isLoyaltyEnabled"
		],
		[CommunityCategoryType.Educator]: [
			"enableChat",
			"enableGroups",
			"enableAudioTracks",
			"enableForum",
			"communityChatEnabled",
			"enableEvents",
			"enableCollections",
			"enablePolls"
		],
		[CommunityCategoryType.VideoCreator]: [
			"enableChat",
			"enableGroups",
			"enableForum",
			"enableStories",
			"communityChatEnabled",
			"enableEvents",
			"enableConnecting",
			"enableCollections",
			"isLoyaltyEnabled"
		],
		[CommunityCategoryType.Business]: [
			"enableChat",
			"enableGroups",
			"enableForum",
			"communityChatEnabled",
			"enableCvGenerator",
			"enableConnecting",
			"enablePolls",
			"enableMarketplace",
			"enableEvents"
		],
		[CommunityCategoryType.Writer]: [
			"enableChat",
			"enableForum",
			"communityChatEnabled",
			"enablePolls",
			"enableAudioTracks",
			"enableEvents"
		],
		[CommunityCategoryType.Other]: ["enableChat", "enableForum", "enableStories", "enableConnecting"]
	}
};

export default createStore<FeatureContextValues>({
	initialState,
	persistant: true,
	persistantName: localStorageKey
});
