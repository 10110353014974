import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";

import { Premium, SubscriptionList, SubscriptionPremiumList } from "shared/Components";
import { useCommunity, useUser } from "shared/hooks";

import { PriceTag } from "shared/types";

import { PremiumSetBlock, StyledRadio, Title, Wrapper } from "./style";

import { MonetizationPlanSubscriptionId } from "../../types/MonetizationPlansType";

export type SetupPriceMethods = {
	getPriceTags: () => PriceTag[];
};

export type SetupPriceMethodsHandlers = React.ElementRef<typeof SetupPrice>;
interface SetupPriceProps {
	title?: string | React.ReactNode;
	onChangeType?: (isPremium: boolean) => void;
	defaultValue?: PriceTag[];
	hasBorder?: boolean;
	createPostView?: boolean;
	isItemHighlighted?: boolean;
	onPriceChanged?: () => void;
}

const SetupPrice = forwardRef<SetupPriceMethods, SetupPriceProps>(
	(
		{ title, onChangeType, defaultValue, createPostView, hasBorder = true, onPriceChanged, isItemHighlighted = false },
		ref
	) => {
		const { getData: getCommunityData } = useCommunity();
		const { monetizationPlans, subscriptionProducts, coins: priceTags, isLoading } = getCommunityData();

		const { getData: getUserData } = useUser();
		const { isMemberView } = getUserData();

		const [isPremiumContent, setIsPremiumContent] = useState(false);

		const subscriptionFormRef = useRef<any>();
		const premiumFormRef = useRef<any>();
		const subscriptionPremiumFormRef = useRef<any>();

		const currentSubscriptionType = useMemo(() => {
			return (monetizationPlans || []).find(x => x.selected)?.subscriptionId;
		}, [monetizationPlans]);

		useEffect(() => {
			if (
				(currentSubscriptionType === MonetizationPlanSubscriptionId.SUBSCRIPTION_ONLY && !isLoading) ||
				!!defaultValue?.length
			) {
				setIsPremiumContent(true);
			}
		}, [currentSubscriptionType, isLoading, onChangeType, defaultValue]);

		useEffect(() => {
			onChangeType && onChangeType(isPremiumContent);
		}, [onChangeType, isPremiumContent]);

		const priceTagOptions = useMemo(() => {
			return priceTags.sort((a, b) => a.price - b.price).map(({ _id, name }) => ({ label: name, value: _id }));
		}, [priceTags]);

		const tierPriceOptions = useMemo(() => {
			const tags = priceTags
				.map(({ _id, coins }) => ({ label: coins || 0, value: _id }))
				.sort((a, b) => a.label - b.label);

			return tags;
		}, [priceTags]);

		useImperativeHandle(ref, () => ({
			getPriceTags() {
				let priceTags: PriceTag[] = [];

				if (isPremiumContent) {
					const subscriptionFormValues = subscriptionFormRef.current?.getFormValues();
					const subscriptionPremiumFormValues = subscriptionPremiumFormRef.current?.getFormValues();
					const premiumFormValues = premiumFormRef.current?.getFormValues();

					if (currentSubscriptionType === MonetizationPlanSubscriptionId.PREMIUM_ONLY) {
						priceTags = [{ subscriptionProductIds: [], priceTagProductId: premiumFormValues.priceTagId }];
					}

					if (currentSubscriptionType === MonetizationPlanSubscriptionId.SUBSCRIPTION_ONLY) {
						const ids: string[] = [];
						subscriptionFormValues.subscriptions
							.filter(x => x.checked)
							.forEach(x => {
								ids.push(x.monthlyId);
								ids.push(x.annuallyId);
							});

						priceTags = [
							{
								subscriptionProductIds: ids,
								priceTagProductId: null
							}
						];
					}

					if (currentSubscriptionType === MonetizationPlanSubscriptionId.SUBSCRIPTION_PREMIUM_ONLY) {
						subscriptionPremiumFormValues.tiers.forEach(x => {
							priceTags.push({
								subscriptionProductIds: x.monthlyId && x.annuallyId ? [x.monthlyId, x.annuallyId] : [],
								priceTagProductId: x.value ? x.value : null
							});
						});
					}
				}

				return priceTags;
			}
		}));

		return currentSubscriptionType ? (
			<Wrapper isMemberView={isMemberView}>
				<PremiumSetBlock
					isMemberView={isMemberView}
					hasBorder={hasBorder}
					className={`${!isMemberView && "setup"} ${
						currentSubscriptionType === MonetizationPlanSubscriptionId.SUBSCRIPTION_ONLY && "hidden"
					}`}
				>
					<Title>{title || "Who can access it?"}</Title>
					<StyledRadio
						className={createPostView ? "createPostView" : isMemberView && "memberViewChoice"}
						value={isPremiumContent}
						onChange={e => {
							setIsPremiumContent(JSON.parse(e.target.value));
						}}
						options={[
							{
								label: "Everyone",
								value: false,
								option: 1,
								className: isItemHighlighted && !isPremiumContent ? "active" : ""
							},
							{
								label: "Premium Access",
								value: true,
								option: 2,
								className: isItemHighlighted && isPremiumContent ? "active" : "",
								disabled:
									(currentSubscriptionType === MonetizationPlanSubscriptionId.PREMIUM_ONLY && !priceTags.length) ||
									(currentSubscriptionType === MonetizationPlanSubscriptionId.SUBSCRIPTION_PREMIUM_ONLY &&
										(!priceTags.length || !subscriptionProducts.length))
							}
						]}
					/>
				</PremiumSetBlock>
				{isPremiumContent && (
					<>
						{currentSubscriptionType === MonetizationPlanSubscriptionId.PREMIUM_ONLY && (
							<PremiumSetBlock className={isMemberView && "isMemberView"}>
								<Premium
									ref={premiumFormRef}
									priceTagOptions={priceTagOptions}
									defaultValue={defaultValue}
									onPriceChanged={onPriceChanged}
								/>
							</PremiumSetBlock>
						)}
						{currentSubscriptionType === MonetizationPlanSubscriptionId.SUBSCRIPTION_ONLY && (
							<PremiumSetBlock className={isMemberView && "isMemberView"}>
								<SubscriptionList
									ref={subscriptionFormRef}
									subscriptionProducts={subscriptionProducts}
									defaultValue={defaultValue}
									onPriceChanged={onPriceChanged}
								/>
							</PremiumSetBlock>
						)}
						{currentSubscriptionType === MonetizationPlanSubscriptionId.SUBSCRIPTION_PREMIUM_ONLY && (
							<PremiumSetBlock className={isMemberView && "isMemberView"}>
								<SubscriptionPremiumList
									ref={subscriptionPremiumFormRef}
									subscriptionProducts={subscriptionProducts}
									tierPriceOptions={tierPriceOptions}
									defaultValue={defaultValue}
									onPriceChanged={onPriceChanged}
								/>
							</PremiumSetBlock>
						)}
					</>
				)}
			</Wrapper>
		) : null;
	}
);

export default SetupPrice;
