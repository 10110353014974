import React from "react";

import CircularProgress from "@material-ui/core/CircularProgress";
import { Controller, useFieldArray, useForm } from "react-hook-form";

import { useAuth } from "modules/App/Data";

import { useNotification } from "shared/hooks";
import { PhoneRequestResponse } from "shared/types";

import { Fields, Input } from "./styles";

import { Form, FormActions, FormButton, FormContent, FormWrapper } from "../commonStyles";
interface Props {
	onSubmit: (userId: string) => void;
	verificationData: {
		codeValidationId: PhoneRequestResponse["codeValidationId"] | null;
		userId: string | null;
	} | null;
}

// TODO: check if there is a similar component and move to shared
export function PhoneVerificationCodeForm(props: Props) {
	const { verificationData } = props;

	const {
		control,
		handleSubmit,
		formState: { isValid, isSubmitting }
	} = useForm({
		mode: "onChange",
		defaultValues: {
			digits: [{ value: "" }, { value: "" }, { value: "" }, { value: "" }]
		}
	});

	const { verifyPhone } = useAuth();

	const { showMessage } = useNotification();

	const inputRefs = React.useRef<HTMLInputElement[]>([]);

	const { fields } = useFieldArray({ name: "digits", control });

	const onSubmit = handleSubmit(async data => {
		const code = data.digits.reduce((acc, item) => {
			return acc + item.value;
		}, "");
		try {
			if (verificationData?.codeValidationId) {
				const res = await verifyPhone({
					codeValidationId: verificationData.codeValidationId,
					code,
					userId: verificationData.userId
				});
				props.onSubmit(res.userId);
			}
		} catch (error) {
			showMessage("Error verifying phone");
			console.error(error);
		}
	});

	const renderInputs = (_, i: number) => {
		return (
			<Controller
				key={i}
				name={`digits[${i}].value`}
				control={control}
				defaultValue=""
				rules={{ required: true }}
				render={({ value, onChange }) => (
					<Input
						value={value}
						placeholder={i + 1}
						onChange={e => {
							const value = e.target.value.replace(/\D/g, "").slice(0, 1);
							onChange(value);
							if (value && i < 3 && inputRefs.current[i + 1] && inputRefs.current[i + 1].focus) {
								inputRefs.current[i + 1].focus();
							}
						}}
						ref={ref => {
							if (ref) {
								inputRefs.current[i] = ref;
							}
						}}
					/>
				)}
			/>
		);
	};

	React.useEffect(() => {
		const onKeyUp = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
			if (e?.code) {
				if (
					e.code === "ArrowRight" &&
					index < 3 &&
					inputRefs.current[index + 1] &&
					inputRefs.current[index + 1].focus
				) {
					inputRefs.current[index + 1].focus();
				}

				if (e.code === "ArrowLeft" && index > 0 && inputRefs.current[index - 1] && inputRefs.current[index - 1].focus) {
					inputRefs.current[index - 1].focus();
				}
			}
		};

		inputRefs.current.forEach((currentRef, index) => {
			if (currentRef) {
				currentRef.addEventListener("keyup", e => onKeyUp(e as any, index));
			}
		});
	}, []);

	return (
		<FormWrapper>
			<Form>
				<FormContent>
					<Fields>{fields.map((_, i) => renderInputs(null, i))}</Fields>
				</FormContent>
				<FormActions>
					<FormButton
						type="submit"
						fullWidth
						buttonTheme="light"
						disabled={!isValid || isSubmitting}
						onClick={onSubmit}
					>
						{isSubmitting ? <CircularProgress size={20} /> : "Continue"}
					</FormButton>
				</FormActions>
			</Form>
		</FormWrapper>
	);
}
