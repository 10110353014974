import { useMemo } from "react";

import { PriceTag } from "shared/types";

import { MonetizationPlanSubscriptionId } from "../types/MonetizationPlansType";
import { useCommunity, useUser } from "./index";

const usePremiumContent = () => {
	const { getData: getCommunityData } = useCommunity();
	const { subscriptionProducts, monetizationPlans, coins } = getCommunityData();

	const { getData: getUserData } = useUser();
	const { user } = getUserData();

	const userPurchase = useMemo(() => {
		return user?.purchase;
	}, [user]);

	const currentMonetizationPlan = useMemo(() => {
		return (monetizationPlans || []).find(x => x.selected)?.subscriptionId;
	}, [monetizationPlans]);

	const methods = useMemo(
		() => ({
			getPriceInfo(priceTags?: PriceTag[]) {
				if (priceTags && !!priceTags?.length && currentMonetizationPlan) {
					if (currentMonetizationPlan === MonetizationPlanSubscriptionId.PREMIUM_ONLY) {
						const premiumPriceTag = priceTags.find(x => !x.subscriptionProductIds || !x.subscriptionProductIds?.length);
						if (premiumPriceTag) {
							const priceTagId = premiumPriceTag.priceTagProductId;
							return coins.find(x => x._id === priceTagId);
						}
					}

					if (currentMonetizationPlan === MonetizationPlanSubscriptionId.SUBSCRIPTION_ONLY && subscriptionProducts) {
						const subscriptionPriceTag = priceTags.find(x => !x.priceTagProductId && x.subscriptionProductIds);
						if (subscriptionPriceTag && subscriptionPriceTag.subscriptionProductIds.length) {
							const supportedProducts = subscriptionProducts
								.filter(
									x =>
										subscriptionPriceTag.subscriptionProductIds.includes(x.monthly!._id) ||
										subscriptionPriceTag.subscriptionProductIds.includes(x.annually!._id)
								)
								.sort((a, b) => a.monthly!.price - b.monthly!.price);

							return supportedProducts?.length ? supportedProducts[0] : undefined;
						}
					}

					if (currentMonetizationPlan === MonetizationPlanSubscriptionId.SUBSCRIPTION_PREMIUM_ONLY && coins) {
						const priceTagIds = priceTags.map(x => x.priceTagProductId);
						const supportedProducts = coins
							.filter(x => priceTagIds.includes(x._id))
							.sort((a, b) => (a.coins || 0) - (b.coins || 0));

						return supportedProducts?.length ? supportedProducts[0] : undefined;
					}
				}

				return undefined;
			},
			getPriceInfoForUser(priceTags?: PriceTag[]) {
				if (priceTags && !!priceTags?.length && currentMonetizationPlan) {
					if (currentMonetizationPlan === MonetizationPlanSubscriptionId.PREMIUM_ONLY) {
						const premiumPriceTag = priceTags.find(x => !x.subscriptionProductIds || !x.subscriptionProductIds?.length);
						if (premiumPriceTag) {
							const priceTagId = premiumPriceTag.priceTagProductId;
							return coins.find(x => x._id === priceTagId);
						}
					}

					if (currentMonetizationPlan === MonetizationPlanSubscriptionId.SUBSCRIPTION_ONLY && subscriptionProducts) {
						const subscriptionPriceTag = priceTags.find(x => !x.priceTagProductId && x.subscriptionProductIds);
						if (subscriptionPriceTag && subscriptionPriceTag.subscriptionProductIds.length) {
							const supportedProducts = subscriptionProducts
								.filter(
									x =>
										subscriptionPriceTag.subscriptionProductIds.includes(x.monthly!._id) ||
										subscriptionPriceTag.subscriptionProductIds.includes(x.annually!._id)
								)
								.sort((a, b) => a.monthly!.price - b.monthly!.price);

							return supportedProducts?.length ? supportedProducts[0] : undefined;
						}
					}
					if (currentMonetizationPlan === MonetizationPlanSubscriptionId.SUBSCRIPTION_PREMIUM_ONLY && coins) {
						const userProductId = userPurchase?.productId;

						const usersPriceTags = priceTags.find(x =>
							userProductId
								? x.subscriptionProductIds && x.subscriptionProductIds.includes(userProductId)
								: !x.subscriptionProductIds || !x.subscriptionProductIds.length
						);

						if (usersPriceTags?.priceTagProductId) {
							return coins.find(x => x._id === usersPriceTags?.priceTagProductId);
						}

						return coins.sort((a, b) => (a?.coins || 0) - (b?.coins || 0))[0];
					}
				}

				return undefined;
			},
			isHigherPlanCheaperForUser(priceTags?: PriceTag[]) {
				if (priceTags && !!priceTags?.length && currentMonetizationPlan) {
					if (currentMonetizationPlan === MonetizationPlanSubscriptionId.SUBSCRIPTION_PREMIUM_ONLY && coins) {
						const userProductId = userPurchase?.productId;

						const fullInfoPriceTags = priceTags.map(x => ({
							...x,
							priceInfo: coins.find(c => c._id === x?.priceTagProductId),
							isCurrentUserSubscription:
								userProductId && x.subscriptionProductIds && x.subscriptionProductIds.includes(userProductId)
						}));

						const userPrice = fullInfoPriceTags.find(x => x.isCurrentUserSubscription)?.priceInfo?.coins || 0;

						return (
							!userProductId ||
							fullInfoPriceTags.some(
								x =>
									(!!x?.subscriptionProductIds?.length && !x.priceInfo) ||
									(x.priceInfo?.coins && x.priceInfo.coins < userPrice)
							)
						);
					}
				}

				return false;
			},
			getCoinPrice(priceTags?: PriceTag[]) {
				const priceInfo = methods.getPriceInfo(priceTags);
				return priceInfo?.coins || priceInfo?.monthly?.price || 0;
			}
		}),
		[currentMonetizationPlan, coins, subscriptionProducts, userPurchase]
	);

	return useMemo(
		() => ({
			...methods,
			currentMonetizationPlan
		}),
		[methods, currentMonetizationPlan]
	);
};

export default usePremiumContent;
