import React from "react";

import { Box } from "@material-ui/core";

import { ReactComponent as AppleLogo } from "assets/icons/apple-logo.svg";
import { ReactComponent as SpotifyLogo } from "assets/icons/spotify.svg";
import { ReactComponent as VyooLogo } from "assets/icons/vyoo-logo.svg";
import { ReactComponent as WindowsLogo } from "assets/icons/windows-logo.svg";
import ItunesImg from "assets/images/itunes.png";
import { Text } from "shared/ui-kit";

import { ContentWrapper, NavbarWrapper } from "./style";

const TracksHelpPage = () => {
	return (
		<>
			<NavbarWrapper>
				<VyooLogo />
			</NavbarWrapper>
			<ContentWrapper>
				<ContentWrapper.Block id="how-to-unzip">
					<ContentWrapper.Headline>How to unzip the file</ContentWrapper.Headline>
					<ContentWrapper.OS>
						<AppleLogo /> <Text>Mac</Text>
					</ContentWrapper.OS>
					<ContentWrapper.Description>
						To unzip zipped files on a Mac, simply double-click on the zipped file and the Archive Utility tool will
						unzip the files, creating a new folder of its contents beside the zipped folder on your desktop.
					</ContentWrapper.Description>
					<ContentWrapper.OS>
						<WindowsLogo /> <Text>Windows</Text>
					</ContentWrapper.OS>
					<ContentWrapper.Description>
						<b>1.</b> Open File Explorer and find the zipped folder. <br />
						<br />
						<b>2.</b> To unzip the entire folder, right-click to select Extract All, and then follow the instructions.
						<br />
						<br />
						<b>3.</b> To unzip a single file or folder, double-click the zipped folder to open it. Then, drag or copy
						the item from the zipped folder to a new location.
					</ContentWrapper.Description>
				</ContentWrapper.Block>
				<ContentWrapper.Block id="how-to-add-media">
					<ContentWrapper.Header>
						<ContentWrapper.Headline>How to add media into iTunes</ContentWrapper.Headline>
						<img src={ItunesImg} alt="itunes" />
					</ContentWrapper.Header>
					<ContentWrapper.Description>
						<ContentWrapper.Step>
							<b>1.</b> In the iTunes app on your PC, choose File &gt; Add File to Library or Add Folder to Library.
						</ContentWrapper.Step>
						<ContentWrapper.Step>
							<b>2.</b> Locate a file or folder, then click Open.
							<Box className="substep">If you add a folder, all the files it contains are added to your library.</Box>
						</ContentWrapper.Step>
						<ContentWrapper.Step>
							<b>Tip:</b> You can also drag a file or folder from the desktop to the iTunes window to import it.
						</ContentWrapper.Step>
						<ContentWrapper.Step>
							When you import an item by dragging it to the iTunes window or choosing File &gt; Add File to Library, a
							reference (or “pointer”) to the item—not the item itself—is placed in the iTunes folder. You might not be
							able to play songs if you move the references to them.
						</ContentWrapper.Step>
					</ContentWrapper.Description>
				</ContentWrapper.Block>
				<ContentWrapper.Block>
					<ContentWrapper.Header>
						<ContentWrapper.Headline>How to add media into Spotify</ContentWrapper.Headline>
						<SpotifyLogo />
					</ContentWrapper.Header>
					<ContentWrapper.Description>
						<ContentWrapper.Step>
							<b>1.</b> Click on your profile.
						</ContentWrapper.Step>
						<ContentWrapper.Step>
							<b>2.</b> Go to settings.
						</ContentWrapper.Step>
						<ContentWrapper.Step>
							<b>3.</b> {'Enable "Show Local Files"'}
						</ContentWrapper.Step>
						<ContentWrapper.Step>
							<b>4.</b> Click {'"ADD A SOURCE" and add your music folder.'}
						</ContentWrapper.Step>
						<ContentWrapper.Step>
							<b>5.</b> {"Go to your library and you'll be able to access your music."}
						</ContentWrapper.Step>
					</ContentWrapper.Description>
				</ContentWrapper.Block>
			</ContentWrapper>
		</>
	);
};

export default TracksHelpPage;
