import { Link } from "react-router-dom";
import styled from "styled-components";

import { Box, Text } from "shared/ui-kit";

export const Card = styled(Box)`
	border-radius: 12px;
	border: 1px solid rgba(197, 206, 224, 0.5);
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
	background-color: #ffffff;
	margin-bottom: 16px;
`;

export const Group = styled(Box)`
	display: flex;
	align-items: center;
	box-shadow: 0 0.5px 0 0 #c5cee0;
	padding: 16px;
`;

Group.Title = styled(Text)`
	color: rgba(34, 43, 69, 1);
	font-size: 18px;
	font-weight: 700;
	letter-spacing: 0px;
	text-align: left;
	line-height: 24px;
`;

Group.Subtitle = styled(Text)`
	color: rgba(143, 155, 179, 1);
	font-size: 15px;
	font-weight: 400;
	letter-spacing: 0px;
	text-align: left;
	line-height: 20px;
`;

export const Item = styled(Box)``;

interface IconWrapperProps {
	width?: string;
	height?: string;
	borderRadius?: string;
}

Item.IconWrapper = styled(Box)<IconWrapperProps>`
	width: ${({ width }) => (width ? width : "64px")};
	height: ${({ height }) => (height ? height : "64px")};
	border-radius: ${({ borderRadius }) => (borderRadius ? borderRadius : "64px")};
	overflow: hidden;
	margin-right: 16px;
`;

Item.Icon = styled(Box)<{ imgUrl: string | undefined }>`
	width: 100%;
	height: 100%;
	background-color: #edf1f7;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	background-image: url(${({ imgUrl }) => imgUrl});
`;

Item.InfoBlock = styled(Box)`
	display: flex;
	flex-direction: column;
`;

export const GroupMembers = styled(Box)<{ showMenu: boolean }>`
	padding: 16px 16px 0px 16px;

	.member-item {
		padding: 8px 12px 8px 8px;
		margin-bottom: 16px;

		.member-item-menu {
			visibility: hidden;
			border-radius: 8px;
			position: absolute;
			right: 0;
			background-color: #edf1f7;

			.MuiButton-label {
				width: 24px;
				height: 24px;
			}

			.menu-icon {
				display: block;
				margin: 4px;
				width: 24px;
				height: 24px;
			}
		}

		&:hover {
			border-radius: 8px;
			background-color: #f7f9fc;

			.member-item-menu {
				visibility: ${({ showMenu }) => (showMenu ? "visible" : "hidden")};
			}
		}
	}
`;

GroupMembers.Heading = styled(Text)`
	color: rgba(34, 43, 69, 1);
	font-size: 15px;
	font-weight: 600;
	letter-spacing: 0px;
	text-align: left;
	line-height: 24px;
	margin-bottom: 8px;
`;

export const Member = styled(Box)`
	display: flex;
	align-items: center;
	cursor: pointer;
	position: relative;
`;

Member.Title = styled(Text)`
	color: rgba(34, 43, 69, 1);
	font-size: 15px;
	font-weight: 400;
	letter-spacing: 0px;
	text-align: left;
	line-height: 20px;
`;

Member.Subtitle = styled(Text)`
	color: rgba(143, 155, 179, 1);
	font-size: 13px;
	font-weight: 400;
	letter-spacing: 0px;
	text-align: left;
	line-height: 16px;
`;

export const GroupSponsors = styled(Box)`
	padding: 16px 16px 16px 16px;
`;

export const SponsorItem = styled(Box)`
	width: 176px;
	height: 200px;
	border-radius: 12px;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08), inset 0 0 0 0.5px #c5cee0;
	background-color: #fff;
	transition: box-shadow 0.25s linear;
	overflow: hidden;
`;

SponsorItem.CoverPhotoWrapper = styled(Box)`
	width: 100%;
	height: 64px;
	position: relative;
	border-top-left-radius: 12px;
	border-top-right-radius: 12px;
	margin-bottom: 52px;
`;

SponsorItem.CoverPhoto = styled(Box)<{ url?: string }>`
	width: 100%;
	height: 100%;
	background: url(${({ url }) => url}) center no-repeat;
	background-size: cover;
`;

SponsorItem.CoverPhotoPlaceholderWrapper = styled(Box)`
	width: 100%;
	height: 64px;
	position: relative;
	border-top-left-radius: 12px;
	border-top-right-radius: 12px;
	overflow: hidden;
`;

SponsorItem.PhotoWrapper = styled(Box)`
	position: absolute;
	border: solid 1px #fff;
	padding: 2px;
	z-index: 5;
`;

SponsorItem.PhotoLink = styled(Link)``;

SponsorItem.Photo = styled(Box)<{ url?: string }>`
	width: 100%;
	height: 100%;

	background: url(${({ url }) => url}) center no-repeat;
	background-size: cover;
`;

SponsorItem.Title = styled(Text)`
	color: rgba(34, 43, 69, 1);
	font-size: 18px;
	font-weight: 700;
	font-style: normal;
	letter-spacing: 0px;
	text-align: center;
	line-height: 24px;
`;

SponsorItem.Subtitle = styled(Text)`
	color: rgba(34, 43, 69, 1);
	font-size: 15px;
	font-weight: 600;
	font-style: normal;
	letter-spacing: 0px;
	text-align: center;
	line-height: 24px;
	margin: 3px 0px 33px 0px;
`;
