import { Box } from "@material-ui/core";
import styled from "styled-components";

export const Wrapper = styled(Box)`
	border-radius: 4px;
	background-color: #f7f9fc;
	display: flex;
	align-items: center;
	padding: 8px 16px;
	margin-top: 8px;
	button {
		width: 32px;
		height: 32px;
		margin-right: 8px;
		background-color: #6173fe;
		display: flex;
		align-items: center;
		&:hover {
			background-color: #6173fe;
		}
		svg {
			fill: #ffffff;
			width: 24px;
			height: 24px;
		}
	}
	region {
		border-width: 2px 6px;
		border-style: solid;
		border-color: #ffd044;
		border-radius: 8px;
		z-index: 100 !important;
		position: relative;
	}
	#waveform {
		flex: 1;
		wave > wave {
			border-right: 1px solid #ea5e00 !important;
		}
	}
`;

export const Video = styled(Box)``;

Video.Form = styled(Box)`
	position: relative;
	width: 100%;
	height: 60px;

	overflow-x: hidden;

	border-radius: 8px;
`;

Video.Region = styled(Box)`
	position: absolute;
	top: 0;
	left: 0;

	width: 100px;
	height: 100%;

	background-color: transparent;
	cursor: move;

	border-width: 2px 6px;
	border-style: solid;
	border-color: rgb(255, 208, 68);
	border-radius: 8px;

	z-index: 100 !important;

	overflow: hidden;
`;

Video.VideoWrapperInRegion = styled(Box)`
	position: absolute;
	width: 100%;
	height: 100%;

	top: 0;
	left: 0;

	overflow: hidden;

	pointer-events: none;

	z-index: -1;

	video {
		object-fit: cover;
	}
`;

Video.FormImageFrame = styled(Box)`
	position: absolute;
	top: 0;
	left: 0;

	height: 100%;

	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
`;
