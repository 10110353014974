import styled from "styled-components";

import { Box, Button, Text } from "shared/ui-kit";

export const Wrapper = styled(Box)`
	display: flex;
	align-items: flex-start;
	justify-content: space-between;

	width: 100%;

	padding: 15px 24px;
`;

export const LabelsWrapper = styled(Box)`
	display: flex;
	flex-direction: column;
`;

export const Title = styled(Text)`
	font-size: 18px;
	font-weight: bold;
	line-height: 1.33;
	color: #222b45;
`;

export const Subtitle = styled(Text)`
	font-size: 12px;
	line-height: 1.23;
	color: #8f9bb3;

	margin-top: 4px;
`;

export const SelectWrapper = styled(Box)`
	.MuiInputBase-root {
		min-width: 120px;
	}
`;

export const DownloadBtn = styled(Button)`
	margin-top: 6px;

	&.MuiButton-root {
		padding: 11px 18px;
	}

	&.square {
		padding: 11px;
		min-width: auto;
	}
`;
