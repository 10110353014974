import config from "config/appConfig";

import { IStateProps } from "utils/StateOf";

import createStore from "utils/createStore";

import { PodcastIntegration } from "./../../types/PodcastsResponse";

const localStorageKey = `${config.GLOBAL_CONSTANTS.LOCAL_STORAGE_PREFIX}${config.HOOK_STORAGE_KEYS.INTEGRATION_DATA}`;

export interface IntegrationContextValues {
	openDialog: string | null;
	confirmDisconnect: PodcastIntegration | null;
	linkToDelete?: string;
	podcasts: PodcastIntegration[];
	searchResults: PodcastIntegration[];
	integrationsUrl: string;
	isLoading: boolean;
	shouldUpdateFields: boolean;
	openShopifyDialog: boolean;
	dataChanged: boolean;
}

export const initialState: IStateProps<IntegrationContextValues> = {
	openDialog: null,
	confirmDisconnect: null,
	podcasts: [],
	searchResults: [],
	integrationsUrl: "/",
	isLoading: false,
	shouldUpdateFields: false,
	openShopifyDialog: false,
	dataChanged: false
};

export default createStore<IntegrationContextValues>({
	initialState,
	persistant: true,
	persistantName: localStorageKey
});
