import styled, { css } from "styled-components";

const dialogBodyStyles = css`
	padding: 0 !important;
	color: inherit;
	display: flex;
	flex-direction: column;
	overflow: hidden;
`;

const Wrapper = styled.div`
	display: flex;
	flex: 1;
	height: 100%;
`;

const Sidebar = styled.div`
	width: 280px;
	background-color: #192038;
`;

const Menu = styled.ul`
	padding: 0;
	margin: 0;
`;

const MenuItem = styled.li<{ selected: boolean }>`
	font-size: 13px;
	font-weight: 600 !important;
	color: #fff;
	padding: 12px 0 12px 16px;
	cursor: pointer;
	border: solid 1px #101426;
	list-style: none;
	background: ${({ selected }) => (selected ? "#101426" : "none")};
`;

const SettingsSection = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
`;

const SettingsSectionHeader = styled.div`
	padding: 24px 24px 9px 24px;
`;

const SettingsSectionTitle = styled.div`
	font-size: 18px;
	font-weight: bold;
`;

const SettingsSectionSubtitle = styled.div`
	font-size: 13px;
	margin-top: 8px;
	color: #8f9bb3;
`;

export {
	Wrapper,
	Sidebar,
	Menu,
	MenuItem,
	SettingsSection,
	SettingsSectionHeader,
	SettingsSectionTitle,
	SettingsSectionSubtitle,
	dialogBodyStyles
};
