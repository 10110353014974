import React, { FC, useMemo } from "react";

import { Grid } from "@material-ui/core";

import { useHistory } from "react-router-dom";

import { MenuDots, MenuDotsOption, PlaceholderImage } from "shared/Components";
import { useFundraisers, useMemberRoutes, useUser } from "shared/hooks";
import { GroupAndMembersModel, ImageSizes, MediaModel, PlaceholderImageType } from "shared/types";
import { Icon } from "shared/ui-kit";

import { getResizedImage } from "utils/serviceUtils/cdnImages";

import { Card, Group, GroupMembers, Item, Member } from "../../styles";

const GroupsAndMembersSection: FC<{
	id: string;
	groupAndMembers: GroupAndMembersModel[];
}> = ({ id, groupAndMembers }) => {
	const history = useHistory();
	const { getData: getFundraisersData, setInviteDialog } = useFundraisers();
	const { fundraiser } = getFundraisersData();
	const { getData: getUserData } = useUser();
	const { user } = getUserData();

	const { getMemberRoutesData } = useMemberRoutes();
	const { routes } = getMemberRoutesData();

	const isFundraiserCreatorOrPriviliged = useMemo(
		() =>
			user?.isGlobalAdmin ||
			user?.isGlobalOwner ||
			user?.isGlobalModerator ||
			user?.isInfluencer ||
			fundraiser?.creator[0]?.personaId === user?.activeProfile,
		[user, fundraiser]
	);

	const renderMenuButton = (personaId: number, name: string, photos: MediaModel[]) => {
		const optionList: MenuDotsOption[] = [
			{
				name: `Add Contact for ${name}`,
				icon: <Icon group="filled" name="user-plus" width="24px" height="24px" fill="#8F9BB3" />,
				onClick: () =>
					fundraiser &&
					setInviteDialog({
						open: true,
						id,
						name: fundraiser.name,
						onBehalfOf: {
							label: name,
							value: personaId,
							image: {
								mediaId: photos[0]?.mediaId,
								url: photos[0]?.profilePicture || ""
							}
						}
					})
			}
		];

		return (
			<div className="member-item-menu">
				<MenuDots
					key={personaId?.toString() || ""}
					options={optionList}
					memberView
					removeSideMargin
					vertical
					dotColor="#8F9BB3"
					removeBg
					removeshadow
					size={{ small: true }}
					iconClass="menu-icon"
				/>
			</div>
		);
	};

	return (
		<div className="flex flex-col w-full">
			{groupAndMembers?.map(g => (
				<Card key={g._id}>
					<Group>
						<Item.IconWrapper borderRadius={"12px"}>
							{!g.coverUrl ? (
								<PlaceholderImage
									type={PlaceholderImageType.PROFILE_IMAGE}
									width={80}
									height={80}
									viewBox={"0 0 400 400"}
								/>
							) : (
								<Item.Icon imgUrl={g.coverUrl} />
							)}
						</Item.IconWrapper>
						<Item.InfoBlock>
							<Group.Title>{g.name}</Group.Title>
							<Group.Subtitle>{`${g.totalMembers} members`}</Group.Subtitle>
						</Item.InfoBlock>
					</Group>
					{!!g.members?.length && (
						<GroupMembers showMenu={isFundraiserCreatorOrPriviliged}>
							<GroupMembers.Heading>Members</GroupMembers.Heading>
							<Grid container>
								{g.members.map(m => (
									<Grid
										key={m.personaId?.toString() || ""}
										className="member-item"
										item
										xs={12}
										sm={6}
										md={4}
										onClick={() => history.push(`${routes?.member.profile.getPath()}/${m.personaId}`)}
									>
										<Member>
											<Item.IconWrapper width="40px" height="40px" borderRadius="8px">
												{m.photos?.length ? (
													<Item.Icon
														imgUrl={getResizedImage(
															(m.photos[0] as MediaModel).profilePicture as string,
															ImageSizes.size100x100
														)}
													/>
												) : (
													<Icon group="filled" name="user" width="40px" height="40px" />
												)}
											</Item.IconWrapper>
											<Item.InfoBlock>
												<Member.Title>{`${m.firstName} ${m.lastName}`}</Member.Title>
												<Member.Subtitle>{m.contactCount || 0} contacts</Member.Subtitle>
											</Item.InfoBlock>
											{renderMenuButton(m.personaId, `${m.firstName} ${m.lastName}`, m.photos as MediaModel[])}
										</Member>
									</Grid>
								))}
							</Grid>
						</GroupMembers>
					)}
				</Card>
			))}
		</div>
	);
};

export default GroupsAndMembersSection;
