import React, { FC, memo, useCallback, useState } from "react";

import { Button } from "@material-ui/core";
import Countdown from "react-countdown";

import { useAuth } from "modules/App/Data/hooks";

import { useUser } from "shared/hooks";
import { Box, Icon, Text } from "shared/ui-kit";

import { NotVerifiedNotification } from "./styles";

import useCommunity from "../../hooks/useCommunity";

const NotVerified: FC<{ isMember?: boolean }> = memo(({ isMember }) => {
	const { getData: getUserData } = useUser();
	const { user, userVerified } = getUserData();

	const { getData: getCommnunityData, communityColors } = useCommunity();
	const { isWhitelabelAppMode, isAkinaMode, mainAdminColor } = getCommnunityData();

	const { resendVerificationEmail } = useAuth();

	const [canResend, setCanResend] = useState(false);
	const [verificationEmailSent, setVerificationEmailSent] = useState(false);

	const handleResendEmail = useCallback(async () => {
		if (user) {
			const status = await resendVerificationEmail(isWhitelabelAppMode ? user.userId : undefined);
			if (status) {
				setVerificationEmailSent(true);
				setCanResend(false);
			}
		}
	}, [user, resendVerificationEmail, isWhitelabelAppMode]);

	const handleCountdownDone = () => {
		setCanResend(true);
	};

	if (userVerified) return null;

	return (
		<NotVerifiedNotification
			bgColor={!isMember ? mainAdminColor || communityColors.btn : communityColors.btn}
			isMember={isMember}
			className={isAkinaMode && "kijiji"}
		>
			<Box display="flex" alignItems="center">
				<Icon name="exclamation-triangle" group="filled" height={18} width={18} fill="#ffffff" />
				<Text variant="body1">Please verify your email address to activate your community.</Text>
			</Box>
			{!canResend && verificationEmailSent ? (
				<Countdown
					renderer={() => (
						<Button variant="outlined" color="secondary" disabled>
							Email Sent
						</Button>
					)}
					date={Date.now() + 30000}
					onComplete={handleCountdownDone}
				/>
			) : (
				<Button
					onClick={handleResendEmail}
					variant={isMember ? "contained" : "outlined"}
					color={isMember ? "primary" : "secondary"}
					style={{
						background: isMember ? "#fff" : "transparent",
						color: isMember ? communityColors.btn : "#fff"
					}}
				>
					Re-send email
				</Button>
			)}
		</NotVerifiedNotification>
	);
});

export default NotVerified;
