import { useCallback, useMemo } from "react";

import { useAuth } from "modules/App/Data";
import { useGroupApiService, useLocationApiService } from "shared/services";
import { GroupFormModel, GroupModel, GroupSearchParams, Location } from "shared/types";

import { GroupSortBy, MemberSortBy, MemberStatus } from "shared/types/GroupModel";

import { actionMethod, actionTypes, getValidationMessage } from "utils/getValidationMessage";

import useNotification from "./useNotification";

import { useGroupStore } from "../contexts";

import { useUser } from "./index";

const useGroup = (isMarketing = false) => {
	const service = useGroupApiService(isMarketing);

	const locationService = useLocationApiService();
	const { showMessage } = useNotification();

	const store = useGroupStore();
	const { setState } = useGroupStore();

	const { getData: getUserData } = useUser();
	const { user, userIPDetails } = getUserData();
	const personaId = user?.profiles[0]?.personaId || "";

	const { updateUser } = useAuth();

	const methods = useMemo(
		() => ({
			getGroupBySlug: async (slug: string) => {
				try {
					return await service.getGroupBySlug(slug);
				} catch {
					return null;
				}
			},
			getGroupFromListing: async (slug: string) => {
				setState({ loadingGroup: true });
				const { groups } = await service.getGroupFromListing(slug);
				if (groups[0]) {
					setState({ group: groups[0] });
				}
				setState({ loadingGroup: false });
			},
			getGroupAdmins: async ({
				slug,
				status = MemberStatus.admins,
				offset = 1,
				limit = 10,
				sortBy
			}: {
				slug: string;
				status: MemberStatus;
				offset?: number;
				limit?: number;
				sortBy?: MemberSortBy;
			}) => {
				return await service.getGroupAdmins(slug, status, offset, limit, sortBy);
			},
			getGroupTopContrib: async (slug: string, limit = 5, offset?: number) => {
				const { users } = await service.getGroupTopContrib(slug, limit, offset);
				return users;
			},
			getTotalGroupsCount: async ({
				groupType = "all",
				sortBy = GroupSortBy.PLACEHOLDER,
				isMyGroups,
				name,
				groupRelation,
				potentialMatchingIds,
				searchAll,
				filterBy,
				parentId,
				preventStateUpdate = false
			}: Partial<GroupSearchParams>) => {
				if (!preventStateUpdate) {
					setState({ loadingGroupsCount: true });
				}
				const query = {
					lat: userIPDetails.lat,
					lon: userIPDetails.lon,
					sortBy: sortBy === GroupSortBy.PLACEHOLDER ? GroupSortBy.MEMBERS : sortBy,
					filterBy
				};

				if (name && name.length >= 3) {
					query["value"] = name;
				}

				if (groupType !== "all") {
					query["groupType"] = groupType;
				}

				if (isMyGroups) {
					query["filterBy"] = ["member", "owner", "moderator"];
					query["personaId"] = personaId;
				}

				if (groupRelation && groupRelation === "popular") {
					query["startingRadius"] = 0;
					query["radius"] = 0;
					query["personaId"] = null;
				}

				if (potentialMatchingIds) {
					query["potentialMatchingIds"] = potentialMatchingIds;
				}

				if (parentId) {
					query["parentId"] = parentId;
				}

				const { totalFoundGroups } = await service.getGroupsCount(query, searchAll);

				if (!preventStateUpdate) {
					setState({ totalFoundGroups, loadingGroupsCount: false });
				}

				return totalFoundGroups;
			},
			getGroups: async ({
				page = 1,
				limit = 10,
				groupType = "all",
				sortBy = GroupSortBy.PLACEHOLDER,
				isMyGroups,
				name,
				groupRelation,
				potentialMatchingIds,
				searchAll,
				filterBy,
				parentId,
				preventStateUpdate = false,
				noSubgroups
			}: Partial<GroupSearchParams>) => {
				if (!preventStateUpdate) {
					setState({ loading: true });
				}

				const query = {
					offset: page,
					limit: limit,
					lat: userIPDetails.lat,
					lon: userIPDetails.lon,
					sortBy: sortBy === GroupSortBy.PLACEHOLDER ? GroupSortBy.MEMBERS : sortBy,
					filterBy
				};

				if (name && name.length >= 3) {
					query["value"] = name;
				}

				if (groupType !== "all") {
					query["groupType"] = groupType;
				}

				if (isMyGroups) {
					query["filterBy"] = ["member", "owner", "moderator"];
					query["personaId"] = personaId;
				}

				if (groupRelation && groupRelation === "popular") {
					query["startingRadius"] = 0;
					query["radius"] = 0;
					query["personaId"] = null;
				}

				if (potentialMatchingIds) {
					query["potentialMatchingIds"] = potentialMatchingIds;
				}

				if (noSubgroups) {
					query["groupId"] = null;
				}

				if (parentId) {
					query["parentId"] = parentId;
				}
				try {
					const { groups } = await service.getGroupList(query, searchAll);

					if (!preventStateUpdate) {
						setState({ loading: false });
					}

					return {
						groups
					};
				} catch (error) {
					showMessage("Failed to fetch groups");
					return {
						groups: []
					};
				}
			},
			getPaginatedGroup: async ({
				page = 1,
				limit = 10,
				groupType = "all",
				sortBy = GroupSortBy.PLACEHOLDER,
				isMyGroups,
				name,
				groupRelation,
				potentialMatchingIds,
				searchAll,
				filterBy,
				concatGroups = false,
				preventStateUpdate
			}: Partial<GroupSearchParams> & { concatGroups?: boolean }) => {
				setState({ loading: true });

				const { groups } = await methods.getGroups({
					page,
					limit,
					groupType,
					sortBy,
					isMyGroups,
					name,
					groupRelation,
					potentialMatchingIds,
					searchAll,
					filterBy
				});

				if (preventStateUpdate) {
					setState({
						offset: page,
						loading: false
					});

					return groups;
				}

				setState(ctx => ({
					offset: page,
					groups,
					filteredGroups: concatGroups ? (page === 1 ? groups : (ctx.filteredGroups || []).concat(groups)) : groups,
					shouldStopSearching: groups.length < limit,
					loading: false
				}));
			},
			loadSuggestedLocations: async keyWord => {
				if (keyWord.length < 3) return;
				const suggestedLocations: Location[] = await locationService.getLocationSuggestions(keyWord, {
					lat: userIPDetails.lat,
					lon: userIPDetails.lon
				});

				setState({ suggestedLocations });
			},
			createGroup: async data => {
				setState({ creating: true });
				try {
					const createdGroup = await service.createGroup(data);

					if (!createdGroup._id) {
						throw Error();
					}

					showMessage(
						getValidationMessage({
							name: "Group",
							actionType: actionTypes.CRUD,
							actionMethod: actionMethod.created,
							emoji: "🎉"
						}),
						3
					);
					setState({ creating: false });

					return createdGroup;
				} catch (error) {
					showMessage((error as Error).message, 300);
				}
			},
			updateGroup: async (data: GroupFormModel) => {
				setState({ creating: true });

				const res = await service.updateGroup(data);

				setState({ creating: false });

				showMessage(
					getValidationMessage({
						name: "Group",
						actionType: actionTypes.CRUD,
						actionMethod: actionMethod.updated,
						emoji: "✨"
					}),
					3
				);

				return res;
			},
			async deleteGroupBySlug(slug: string, isSubGroupDelete: boolean) {
				const { code } = await service.deleteGroup(slug);
				if (code === "ok") {
					if (!isSubGroupDelete)
						setState(ctx => ({
							filteredGroups: [...(ctx.filteredGroups as GroupModel[]).filter(g => g.slug !== slug)],
							filteredGroupsCount: (ctx.filteredGroupsCount || 1) - 1,
							totalFoundGroups: (ctx.totalFoundGroups || 1) - 1
						}));

					showMessage(
						getValidationMessage({
							name: "Group",
							actionType: actionTypes.CRUD,
							actionMethod: actionMethod.deleted,
							emoji: "🗑"
						}),
						3
					);
				}

				return code === "ok";
			},
			joinGroup: async ({
				isPrivate,
				personaId,
				slug,
				isInvited = false,
				updateStorage = false
			}: {
				isPrivate: boolean;
				personaId: number;
				slug: string;
				isInvited?: boolean;
				updateStorage?: boolean;
			}) => {
				const { success } = await service.joinGroup({ isPrivate, personaId, slug, isInvited });
				if (!success) return;
				updateUser(); // We need it to get fresh data of user fundraiserParticipantOfIds
				if (updateStorage) {
					setState(ctx =>
						ctx?.group?.slug === slug
							? {
									group: {
										...ctx.group,
										roles: {
											...ctx.group.roles,
											isInvited: false,
											isMember: isInvited ? true : !isPrivate,
											isModerator: false,
											isOwner: false,
											isPending: isPrivate ? !isInvited : false
										}
									}
							  }
							: ctx
					);
				}
				showMessage(`Successfully ${isPrivate ? "requested to join group" : "joined group"}`);
			},
			declineGroupInvitation: async ({
				slug,
				personaId,
				updateStorage = false
			}: {
				slug: string;
				personaId: number;
				updateStorage?: boolean;
			}) => {
				const { success } = await service.declineGroupInvitation({ slug, personaId });
				if (!success) return;
				if (updateStorage) {
					setState(ctx =>
						ctx?.group?.slug === slug
							? { group: { ...ctx.group, roles: { ...ctx.group.roles, isInvited: false } } }
							: ctx
					);
				}
				showMessage("Successfully declined group invitation");
			},
			actionOnPendingGroupUser: async (data: { personaId: number; slug: string; isMember: boolean }) => {
				const { success } = await service.actionOnPendingGroupUser(data);
				if (!success) return false;
				showMessage(`Successfully ${data.isMember ? "approved" : "declined"} member requests`);
				return success;
			},
			approveAllPendingGroupUsers: async (groupId: string, onClose?: () => void) => {
				setState({ approveAllLoading: true });
				const { success } = await service.approveAllPendingGroupUsers(groupId);
				setState({ approveAllLoading: false });
				if (!success) return false;
				onClose && onClose();
				showMessage("Successfully approved all member requests");
				return success;
			},
			declineAllPendingGroupUsers: async (groupId: string, onClose?: () => void) => {
				setState({ declineAllLoading: true });
				const { success } = await service.declineAllPendingGroupUsers(groupId);
				setState({ declineAllLoading: false });
				if (!success) return false;
				onClose && onClose();
				showMessage("Successfully declined all member requests");
				return success;
			},
			inviteMembers: async (members, slug = "") => {
				try {
					await service.inviteMemebers(members, slug, personaId);
				} catch (error) {
					showMessage((error as Error).message, 300);
				}
			},
			setSearchText: (searchText: string) => {
				setState({ searchText });
			},
			setPage: (page: number) => {
				setState({ page });
			},
			setGroupsShowPerPage: (groupsShowPerPage: number) => {
				setState({ groupsShowPerPage });
			},
			setGroups: (groups: GroupModel[]) => {
				setState({ groups });
			},
			setGroup: (group: GroupModel) => {
				setState({ group });
			},
			setGroupDetails: (group: GroupModel | null) => {
				setState({ group });
			}
		}),
		[service, setState, locationService, personaId, showMessage, updateUser, userIPDetails.lat, userIPDetails.lon]
	);

	const getData = useCallback(() => {
		return store;
	}, [store]);

	return { ...methods, getData };
};

export default useGroup;
