import React, { FC, useCallback } from "react";

import { StoryResponse } from "types/StoriesContextValuesType";

import { AdWrapper } from "shared/styles";

import AdStoryHead from "./AdStoryHead";
import { StoryBox, StoryContent } from "./style";

export interface IStoryProps {
	activeStory?: boolean;
	data: StoryResponse;
}

interface Props {
	personStory: string;
	isActive: boolean;
	onEndStory?: () => void;
}

const AdStory: FC<Props> = ({ personStory, isActive, onEndStory }) => {
	const handleSlideRight = useCallback(() => {
		onEndStory && onEndStory();
	}, [onEndStory]);

	return (
		<StoryBox isActive={isActive}>
			<AdStoryHead isActive={isActive} handleMoveNext={handleSlideRight} />
			<StoryContent>
				<AdWrapper
					isActive={isActive}
					className="story_ad--wrapper"
					dangerouslySetInnerHTML={{ __html: personStory as string }}
				/>
			</StoryContent>
		</StoryBox>
	);
};

export default AdStory;
