import React, { useCallback, useState } from "react";

import { InputAdornment, TextField } from "@material-ui/core";

import { useHistory } from "react-router-dom";

import { CommunityModel } from "types";

import { ReactComponent as SearchIcon } from "assets/icons/liveconvo/search.svg";
import { PlaceholderImage } from "shared/Components";
import { useCommunity } from "shared/hooks";

import { PlaceholderImageType } from "shared/types";

import { Search, StyledAutocomplete, WorkspaceOption } from "./styles";

interface WorkspaceSearchProps {
	marketingUrl: string;
}

const WorkspaceSearch: React.FC<WorkspaceSearchProps> = ({ marketingUrl }) => {
	const history = useHistory();
	const { getCommunities } = useCommunity();

	const [communityList, setCommunityList] = useState<CommunityModel[]>([]);

	const handleSearch = useCallback(
		async (val: string) => {
			if (val?.length) {
				const communities = await getCommunities({ keyword: val, offset: 1, limit: 50 });
				setCommunityList(communities);
			}
		},
		[getCommunities]
	);

	return (
		<Search>
			<StyledAutocomplete
				open
				getOptionLabel={(option: any) => (typeof option === "string" ? option : option?.meta?.displayName || "")}
				options={communityList}
				autoComplete
				freeSolo
				fullWidth
				onInputChange={(group, newInputValue) => {
					handleSearch(newInputValue);
				}}
				id="findWorkspace"
				renderInput={params => (
					<TextField
						{...params}
						placeholder="Find workspace"
						fullWidth
						InputProps={{
							...params.InputProps,
							startAdornment: (
								<InputAdornment position="start">
									<SearchIcon fill="#8f9bb3" />
								</InputAdornment>
							)
						}}
					/>
				)}
				renderOption={(option: any) => (
					<WorkspaceOption onClick={() => history.push(`${marketingUrl}/${option.name}`)}>
						<WorkspaceOption.Content>
							<WorkspaceOption.Avatar style={{ backgroundImage: `url(${option?.meta?.img || ""})` }}>
								{!option?.meta?.img && (
									<PlaceholderImage
										type={PlaceholderImageType.WORKSPACE_SEARCH}
										width={40}
										height={40}
										viewBox={"0 0 400 400"}
									/>
								)}
							</WorkspaceOption.Avatar>
							<WorkspaceOption.InfoBox>
								<WorkspaceOption.Title>{option?.meta?.displayName}</WorkspaceOption.Title>
								<WorkspaceOption.Domain>{option?.communityUrl}</WorkspaceOption.Domain>
							</WorkspaceOption.InfoBox>
						</WorkspaceOption.Content>
						<WorkspaceOption.Action>
							<WorkspaceOption.ActionBtn id={`enter${option?.communityUrl}`} palette={"basic"} buttonTheme={"light"}>
								Enter
							</WorkspaceOption.ActionBtn>
						</WorkspaceOption.Action>
					</WorkspaceOption>
				)}
			/>
		</Search>
	);
};

export default WorkspaceSearch;
