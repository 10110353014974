import { Box } from "@material-ui/core";
import styled from "styled-components";

import { Text } from "shared/ui-kit";

export const Subtitle = styled(Text)`
	margin-top: 11px;
	margin-bottom: 30px;

	${props => props.theme.breakpoints.down("sm")} {
		margin-top: 16px;
	}
`;

export const OtherLoginOptions = styled(Box)`
	text-align: center;
	margin-top: 32px;
	a {
		color: #6173fe;
		font-weight: 600;
	}
	p {
		margin-top: 8px;
	}
	.caption {
		font-size: 12px;
		color: #8f9bb3;
		margin-bottom: 24px;
		display: block;
		a {
			color: #8f9bb3;
			font-weight: normal;
		}
	}
`;

export const ContactUsText = styled.a<{ color?: string }>`
	width: 100%;
	text-align: center;
	font-size: 12px;
	margin-top: 20px;
	text-decoration: none;

	display: block;

	color: ${({ color }) => color || "#8f9bb3"};
`;
