import { useCallback, useMemo } from "react";

import { useNotification } from "shared/hooks";

import { CategorySort } from "shared/types";
import { actionMethod, actionTypes, getValidationMessage } from "utils/getValidationMessage";

import { CategoryProps, EditCategoryProps, NewCategoryProps } from "./../types/CategoryTypes";

import { useCategoriesStore } from "../contexts";

import { useCategoriesApiService } from "../services";

const useCategories = () => {
	const categoryApiService = useCategoriesApiService();
	const { showMessage } = useNotification();

	const store = useCategoriesStore();
	const { setState } = useCategoriesStore();

	const methods = useMemo(
		() => ({
			getCategories: async ({
				limit = 20,
				keyword = "",
				offset = 1,
				sortBy,
				order,
				paginate,
				notEmptyBy
			}: {
				limit?: number;
				keyword?: string;
				offset: number;
				sortBy?: CategorySort;
				order?: number;
				paginate?: boolean;
				notEmptyBy?: "series" | "company";
			}) => {
				setState(ctx => ({ ...ctx, isLoading: true }));
				const { categories } = await categoryApiService.getCategories(
					offset,
					limit,
					keyword,
					sortBy,
					order,
					notEmptyBy
				);

				setState(ctx => ({
					...ctx,
					offset,
					categories: offset > 1 && paginate ? [...ctx.categories, ...categories] : categories,
					isLoading: false
				}));

				return { categories };
			},
			getCategory: async (id: string) => {
				setState(ctx => ({ ...ctx, isLoading: true }));
				const { categories } = await categoryApiService.getCategory(id);
				setState(ctx => ({ ...ctx, isLoading: false }));
				return categories[0];
			},
			getCategoriesCount: async (notEmptyBy?: "series" | "company") => {
				const { totalCount } = await categoryApiService.getCategoriesCount(notEmptyBy);
				setState(ctx => ({ ...ctx, categoriesCount: totalCount }));
			},
			createCategory: async (cat: NewCategoryProps) => {
				setState(ctx => ({ ...ctx, isLoading: true }));
				await categoryApiService.createCategory(cat);

				// if (category) {
				// 	setState(ctx => ({
				// 		...ctx,
				// 		isLoading: false,
				// 		categories: [category, ...(ctx.categories ?? [])],
				// 		categoriesCount: (ctx.categoriesCount || 0) + 1
				// 	}));
				// }
				showMessage(
					getValidationMessage({
						name: "The Marketplace Category",
						actionType: actionTypes.CRUD,
						actionMethod: actionMethod.created,
						emoji: "🎉"
					}),
					3
				);
			},
			async updateCategory(category: EditCategoryProps) {
				setState(ctx => ({ ...ctx, isLoading: true }));
				const data = await categoryApiService.updateCategory(category);
				if (data) {
					setState(ctx => ({
						...ctx,
						isLoading: false,
						categories: ctx.categories?.map(categoryItem => {
							if (categoryItem._id === category._id) {
								return {
									...categoryItem,
									...category
								};
							}
							return categoryItem;
						})
					}));
					showMessage(
						getValidationMessage({
							name: "The Marketplace Category",
							actionType: actionTypes.CRUD,
							actionMethod: actionMethod.updated,
							emoji: "✨"
						}),
						3
					);
				}
			},
			async deleteCategory(category: CategoryProps) {
				const data = await categoryApiService.deleteCategory(category._id);

				if (data) {
					setState(ctx => ({
						...ctx,
						deleteDialog: undefined
					}));

					showMessage(
						getValidationMessage({
							name: "The Marketplace Category",
							actionType: actionTypes.CRUD,
							actionMethod: actionMethod.deleted,
							emoji: "🗑"
						}),
						3
					);
				}
			},
			setPage: (page: number) => {
				setState(ctx => ({ ...ctx, page }));
			},
			setCreateUpdateCategoryOpen: ({ status, editObject }: { status: boolean; editObject?: CategoryProps }) => {
				setState(ctx => ({ ...ctx, createUpdateCategoryOpen: status, editObject }));
			},
			setDeleteDialog: ({ data }: { data?: CategoryProps }) => {
				setState(ctx => ({ ...ctx, deleteDialog: data }));
			},
			setCategoriesPerPage: (categoriesPerPage: number) => {
				setState(ctx => ({ ...ctx, categoriesPerPage }));
			}
		}),
		[categoryApiService, setState, showMessage]
	);

	const getData = useCallback(() => {
		return store;
	}, [store]);

	return { ...methods, getData };
};

export default useCategories;
