import React, { useCallback, useEffect, useMemo } from "react";

import { ReactComponent as VyooCoin } from "assets/icons/coin-24.svg";

import { useCommunity, usePremiumContent, useStripe, useUser } from "shared/hooks";

import { PremiumObjectType, UnlockContentType } from "shared/types";

import { MonetizationPlanSubscriptionId } from "shared/types/MonetizationPlansType";

import { BuyButton, Wrapper } from "./style";

interface UnlockPremiumBtnProps {
	premiumObject: PremiumObjectType<any>;
	premiumObjectType: UnlockContentType;
	teaserContent?: string;
	onUnlock?: () => void;
	text?: string;
	vertical?: boolean;
	horizontalAlignment?: "left" | "center" | "right";
	ignoreWidth?: boolean;
}

const UnlockPremiumBtn: React.FC<UnlockPremiumBtnProps> = ({
	premiumObject,
	premiumObjectType,
	onUnlock,
	text,
	teaserContent,
	vertical,
	horizontalAlignment
	// ignoreWidth
}) => {
	const { getData: getCommunityData, communityColors } = useCommunity();
	const { monetizationPlans } = getCommunityData();

	const { getPriceInfoForUser, isHigherPlanCheaperForUser } = usePremiumContent();

	const { getData: getUserData } = useUser();
	const { user } = getUserData();

	const { setBuyCoinsDialog, setSubscribeDialog, setUnlockContentDialog, unlockPremiumObject } = useStripe();

	// const buttonRef = useRef<HTMLButtonElement>(null);

	const currentMonetizationPlan = useMemo(() => {
		return (monetizationPlans || []).find(x => x.selected)?.subscriptionId;
	}, [monetizationPlans]);

	const userBalance = useMemo(() => user?.purchase.coinBalance || 0, [user]);
	const color = useMemo(() => communityColors.primary, [communityColors.primary]);

	const priceInfo = useMemo(() => {
		return getPriceInfoForUser(premiumObject?.priceTags);
	}, [getPriceInfoForUser, premiumObject]);

	const isHigherPlanCheaper = useMemo(() => {
		return isHigherPlanCheaperForUser(premiumObject?.priceTags);
	}, [premiumObject, isHigherPlanCheaperForUser]);

	const price = useMemo(() => {
		return priceInfo?.coins || priceInfo?.monthly?.price || 0;
	}, [priceInfo]);

	const id = useMemo(() => `buy-premium-content-${premiumObject._id}`, [premiumObject._id]);

	const handleUnlockPremiumContent = () => {
		if (currentMonetizationPlan === MonetizationPlanSubscriptionId.SUBSCRIPTION_ONLY) {
			setSubscribeDialog({ premiumObject, priceInfo, unlockContentType: premiumObjectType, onUnlock });
		} else if (userBalance >= price) {
			setUnlockContentDialog({
				premiumObject,
				priceInfo,
				unlockContentType: premiumObjectType,
				teaserContent,
				onUnlock
			});
		} else {
			setBuyCoinsDialog({ open: true, id }, onUnlock);
		}
	};

	const handleUnlockFreeObject = useCallback(async () => {
		await unlockPremiumObject({
			premiumObject: premiumObject,
			unlockContentType: premiumObjectType,
			coins: priceInfo?.coins || 0,
			productId: priceInfo!.productId,
			subscriptionId: priceInfo!.subscriptionId
		});

		onUnlock && onUnlock();
	}, [onUnlock, premiumObject, priceInfo, premiumObjectType, unlockPremiumObject]);

	useEffect(() => {
		if (price === 0 && priceInfo) {
			handleUnlockFreeObject();
		}
	}, [price, priceInfo, handleUnlockFreeObject]);

	const handleUpgradePlan = () => {
		setSubscribeDialog({ premiumObject, priceInfo, unlockContentType: premiumObjectType, onUnlock });
	};

	return user?.personaDocId ? (
		<Wrapper className={vertical && "vertical"} horizontalAlignment={horizontalAlignment}>
			{isHigherPlanCheaper && (
				<BuyButton.Wrapper className={vertical && "vertical"}>
					<BuyButton
						onClick={e => {
							e.stopPropagation();
							e.preventDefault();
							handleUpgradePlan();
						}}
					>
						{user?.purchase?.productId ? "Upgrade Now" : "Subscribe Now"}
					</BuyButton>
				</BuyButton.Wrapper>
			)}
			{!!price && (
				<BuyButton.Wrapper className={`${!isHigherPlanCheaper && "single"} ${vertical && "vertical"}`}>
					<BuyButton
						id={id}
						isLight={
							isHigherPlanCheaper || currentMonetizationPlan === MonetizationPlanSubscriptionId.SUBSCRIPTION_ONLY
						}
						color={color}
						onClick={e => {
							e.stopPropagation();
							e.preventDefault();
							handleUnlockPremiumContent();
						}}
					>
						{currentMonetizationPlan === MonetizationPlanSubscriptionId.SUBSCRIPTION_ONLY ? (
							user?.purchase.subscriptionId ? (
								"Upgrade Now"
							) : (
								"Subscribe Now"
							)
						) : (
							<>
								{text || "Unlock for"} {price} <VyooCoin />
							</>
						)}
					</BuyButton>
				</BuyButton.Wrapper>
			)}
		</Wrapper>
	) : null;
};

export default UnlockPremiumBtn;
