import React from "react";

import { useLocation } from "react-router-dom";

import { view } from "modules/App";

import { useMemberRoutes } from "shared/hooks";

import { routes as adminRoutes } from "../../../RegularUser/constants";

const InvitationCodePage = () => {
	const { search } = useLocation();
	const { getMemberRoutesData } = useMemberRoutes();
	const { routes: memberRoutes } = getMemberRoutesData();

	const query = new URLSearchParams(search);
	const communityUrl = query.get("communityUrl") || undefined;
	const workspace = query.get("workspace") || undefined;
	const userId = query.get("userId") || undefined;

	return (
		<view.InvitationCode
			workspace={workspace}
			communityUrl={communityUrl}
			userId={userId}
			memberSignInPage={adminRoutes.user.signIn.getPath()}
			memberFillProfilePage={memberRoutes?.member.fillProfile.getPath()}
		/>
	);
};

export default InvitationCodePage;
