import React, { FC } from "react";

import { Box } from "@material-ui/core";

import { Text } from "shared/ui-kit";

import { EnableFeatureWrapper } from "./style";

import { VyooSwitch } from "..";

interface Props {
	onChange;
	value: boolean;
	icon?: JSX.Element;
	title: string;
	description?: string;
	verticalPadding?: boolean;
	fill?: string;
	small?: boolean;
	switchId?: string;
}

const EnableFeature: FC<Props> = ({
	value,
	onChange,
	icon,
	title,
	description,
	verticalPadding,
	small,
	fill,
	switchId
}) => (
	<EnableFeatureWrapper verticalPadding={verticalPadding} fill={fill}>
		<Box className="side">
			{icon}
			<Box className={!description ? "title-center" : ""}>
				<Text className="feature-name">{title}</Text>
				{description && <Text className={`description ${small ? "mt" : ""}`}>{description}</Text>}
			</Box>
		</Box>
		<Box className={`side center ${small ? "col" : ""}`}>
			<Text className="on-off-indicator">{value ? "On" : "Off"}</Text>
			<VyooSwitch onChange={e => onChange(e.target.checked)} checked={value} id={switchId} />
		</Box>
	</EnableFeatureWrapper>
);

export default EnableFeature;
