import React, { FC } from "react";

import { Box } from "@material-ui/core";

import { useCommunity } from "shared/hooks";
import { ReadMore, Text } from "shared/ui-kit";

import { IconWrapper, InfoRow } from "../style";

interface Props {
	icon: JSX.Element;
	degree: string;
	category: string;
	school: string;
	location: string;
	className?: string;
	distanceLevel?: 1 | 2;
	boxClassName?: string;
}

const EducationRow: FC<Props> = ({
	icon,
	degree,
	category,
	school,
	location,
	className,
	distanceLevel = 2,
	boxClassName
}) => {
	const { communityColors } = useCommunity();
	return (
		<InfoRow className={className} fw edu>
			<IconWrapper fillColor={communityColors.primary} distanceLevel={distanceLevel}>
				{icon}
			</IconWrapper>
			<Box className={boxClassName}>
				<Text className="wrap">{degree}</Text>
				<Text className="wrap">{category}</Text>
				<Text className="school wrap">{school}</Text>
				{location && <ReadMore text={location} showCharCount={300} wrapClassName="" wrapTextClassName="wrap" />}
			</Box>
		</InfoRow>
	);
};

export default EducationRow;
