import React from "react";

import styled from "styled-components";

export type FormFooterProps = {
	children?: React.ReactNode;
	textAlign?: "left" | "right" | "center";
};

export default styled(({ children, ...rest }): JSX.Element => <div {...rest}>{children}</div>)`
	color: ${props => props.theme.palette.formFooterTextColor};
	line-height: 1.5;
	margin-top: ${props => props.theme.sizes.formFooter.marginTop};
	font-size: ${props => props.theme.sizes.formFooter.fontSize};
	text-align: ${({ textAlign = "left" }) => textAlign};

	a {
		text-decoration: underline;
		color: ${props => props.theme.palette.formFooterTextColor};
	}
`;
