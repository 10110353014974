import { Box, ButtonGroup } from "@material-ui/core";

import styled, { css } from "styled-components";

import PlacesIcon from "assets/icons/places.svg";
import { Button, Menu, Text } from "shared/ui-kit";

export const PanelWrapper = styled(Box)`
	width: 100%;
	height: 100%;

	background: white;
`;
export const InboxAction = styled(Box)`
	display: flex;
	flex-direction: column;

	padding: 16px;

	${props => props.theme.breakpoints.down("sm")} {
		padding-bottom: 18px;
	}
`;

InboxAction.Heading = styled.h2`
	font-size: 22px;
	font-weight: bold;
	line-height: 1.45;
	color: #222b45;
	margin-bottom: 24px;
`;

export const ActionRow = styled(Box)`
	padding-bottom: 16px;

	display: flex;
	align-items: center;
`;

export const ActionSeparatedRow = styled(Box)`
	${({ memberView }) =>
		memberView
			? css`
					display: grid;
					align-items: center;
					grid-template-columns: repeat(auto-fit, minmax(48px, 1fr));
					grid-gap: 8px;
			  `
			: css`
					display: flex;
					align-items: center;
					flex-direction: row;
					justify-content: space-between;
			  `}

	width: 100%;
	height: 40px;
`;

export const Container = styled(Box)`
	position: relative;
`;

export const Header = styled(Box)`
	margin-bottom: 32px;
	width: 100%;
	display: flex;
`;

export const HeaderText = styled(Text)`
	color: #222b45;
	font-weight: bold;
	font-size: 32px;
`;

export const BodyWrapper = styled(Box)`
	margin: 0 0 56px;
	border-radius: 4px;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
`;

export const SearchbarRow = styled(Box)`
	display: flex;

	justify-content: space-between;
	align-items: center;

	width: 100%;
	height: 40px;
`;

export const SearchbarWrapper = styled(Box)`
	&.admin-search {
		width: calc(100% - 56px);
		> div {
			width: 100%;
			border-radius: 4px;
			background-color: #edf1f7;
			border: none !important;
			&:hover,
			&:focus-within {
				background-color: #edf1f7;
			}
		}
	}

	&.member-side-search > div {
		border-radius: 8px;
		border: 0.5px solid rgba(198, 207, 224, 1);
		opacity: 1;
		background-color: rgba(247, 249, 252, 1);
		width: 100%;
	}

	&.member-search {
		width: calc(100% - 48px);
		> div {
			border-radius: 8px;
			background-color: transparent;
			border: solid 1px #e4e9f2;
			width: 100%;
		}
	}

	.search-icon {
		width: 20px;

		margin-left: 14px;

		color: #8f9bb3;

		svg {
			width: 21px;
		}
	}

	.MuiInputBase-root {
		width: calc(100% - 34px);
		max-width: 100%;
		text-overflow: ellipsis;

		.MuiInputBase-input {
			font-size: 13px;
			padding-left: 6px;
			&::placeholder {
				color: #8f9bb3;
				opacity: 1;
			}
		}
	}
`;

export const CreateChatBtn = styled(Button)<{ transparent?: boolean }>`
	background-color: ${({ transparent, color }) => (transparent ? "transparent" : color)};

	font-size: 11px;

	color: ${({ transparent }) => (transparent ? "#8f9bb3" : "white")};

	min-width: 40px;
	width: 40px;
	height: 40px;

	margin: 0 0 0 16px;

	.MuiButton-label {
		min-width: 25px;
	}

	&.rounded-btn {
		border-radius: 8px;
		margin: 0;
	}
`;

export const SelectWrapper = styled(Box)`
	float: left;
	height: 100%;

	.MuiFormControl-root {
		width: 160px;
		height: 100%;

		${props => props.theme.breakpoints.down("sm")} {
			width: 130px;
		}

		.MuiSelect-root {
			display: flex;
			align-items: center;
			padding: 12px;
		}

		.MuiInputBase-root {
			height: calc(100% + 3px);
			position: relative;
			top: -3px;
		}

		input {
			display: none;
		}

		.MuiSelect-icon {
			width: 15px;
		}
	}
`;

export const SearchBarFooter = styled(Box)`
	display: flex;
	justify-content: flex-end;
	align-items: center;
`;

export const RightButtonGroup = styled(ButtonGroup)`
	display: flex;
	flex-direction: row;
	justify-content: flex-end;

	border: solid 1px #edf1f7;
	border-radius: 4px;

	height: 100%;
`;

export const FilterButton = styled(Button)<{ active?: boolean }>`
	background-color: white;

	color: #222b45;
	font-size: 13px;
	font-weight: bold;

	padding: 12px 20px;
	margin: 0;

	border: none;

	&:hover {
		background-color: inherit;
		color: inherit;
	}

	&.active {
		background-color: #ebecff;
		color: #6173fe;

		&:hover {
			background-color: #ebecff;
			color: #6173fe;
		}
	}

	&:first-child {
		border-right: solid 1px #edf1f7;
	}
`;

export const CellWrapper = styled(Box)`
	display: flex;
	align-items: center;
`;

export const TableWrapper = styled(Box)`
	border-top: 1px solid #edf1f7;
	border-top-right-radius: 0;
	border-top-left-radius: 0;
	box-shadow: none;
	.MuiInputBase-root.Mui-disabled {
		color: #222b45;
	}
	.MuiSelect-outlined.MuiSelect-outlined {
		display: flex;
		align-items: center;
	}
	.ReactVirtualized__Grid {
		&:focus {
			outline: 0;
		}
	}
`;

export const ColumnHeader = styled(Text)`
	color: #8f9bb3;
`;

export const StyledMenu = styled(Menu)`
	.MuiMenu-paper {
		width: auto !important;
	}
`;

export const PopupTitle = styled(Text)`
	font-size: 18px;
	font-weight: bold;
	line-height: 1.33;
	color: #222b45;
	text-align: left;
	padding-left: 16px;
`;

export const PopupDescription = styled(Text)`
	font-size: 15px;
	font-style: normal;
	line-height: 1.47;
	color: #8f9bb3;
`;

export const ContentColumn = styled(Box)`
	display: flex;
	align-items: center;
`;

export const ContentColumnTitle = styled(Text)`
	font-size: 13px;
	font-weight: normal;
	line-height: 1.23;
	color: #222b45;
	max-width: 100px;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
`;

export const ContentColumnAuthor = styled(Text)`
	font-size: 13px;
	font-weight: normal;
	line-height: 1.23;
	color: #8f9bb3;
	max-width: 100px;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	margin-top: 4px;
`;

export const DateColumn = styled(Box)`
	display: flex;
	flex-direction: column;
`;

export const DateColumnDate = styled(Text)`
	font-size: 13px;
	font-weight: normal;
	line-height: 1.23;
	color: #222b45;
	max-width: 210px;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
`;

export const DateColumnTime = styled(Text)`
	font-size: 13px;
	font-weight: normal;
	line-height: 1.23;
	color: #8f9bb3;
	margin-top: 4px;
`;

export const AccessColumn = styled(Box)`
	display: flex;
	align-items: center;
`;

export const EmptyBody = styled(Box)`
	width: 100%;
	min-height: 600px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background: white;
`;

export const EmptyNote = styled(Text)`
	font-size: 18px;
	font-weight: bold;
	line-height: 1.33;
	letter-spacing: normal;
	text-align: center;
	color: #222b45;
	margin: 22px auto 32px auto;
	max-width: 220px;
`;

export const EventIcon = styled(Box)<{ icon?: string }>`
	width: 40px;
	height: 40px;
	min-width: 40px;
	background-image: url(${({ icon }) => icon || PlacesIcon});
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	border-radius: 4px;
	background-color: #edf1f7;
	margin-right: 24px;
`;

export const SkeletonWrapper = styled(Box)`
	padding: 13px 16px 19px 15px;

	border-bottom: 1px solid #edf1f7;
`;

export const SkeletonRowWrapper = styled(Box)`
	width: 100%;
	height: 40px;

	display: flex;
	align-items: center;
	justify-content: space-between;

	&:not(:last-child) {
		margin-bottom: 16px;
	}
`;
