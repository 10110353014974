import React, { FC, useCallback, useEffect } from "react";

import { Controller, useForm } from "react-hook-form";

import { useCommunity } from "shared/hooks";

import { StyledButton, StyledInput, StyledText, Wrapper } from "./style";

import useConfirmLeavePage from "../ConfirmLeave/hooks/useConfirmLeavePage";
import SubmitButton from "../SubmitButton";

interface Props {
	expandOrCollapse: (id: number) => void;
	index: number;
	currentTemplate: string;
	emailSubjectId: string;
	emailBodyId: string;
	emailCancelId: string;
	emailSaveChangeId: string;
}

const EmailTemplate: FC<Props> = ({
	expandOrCollapse,
	index,
	currentTemplate,
	emailSubjectId,
	emailBodyId,
	emailCancelId,
	emailSaveChangeId
}) => {
	const { updateWorkspaceMeta, getData: getCommunityData } = useCommunity();
	const { workspace } = getCommunityData();
	const { control, handleSubmit, formState, errors, reset } = useForm({
		mode: "onChange"
	});

	const { provideHandleCloseValues } = useConfirmLeavePage({});

	const setValues = useCallback(() => {
		if (workspace) {
			reset({
				subject: workspace.meta?.[currentTemplate]?.subject,
				body: workspace.meta?.[currentTemplate]?.body
			});
		}
	}, [reset, workspace, currentTemplate]);

	useEffect(() => {
		setValues();
	}, [workspace, setValues]);

	const onSave = useCallback(
		async data => {
			await updateWorkspaceMeta({
				meta: {
					[currentTemplate]: data
				}
			});
			expandOrCollapse(index);
		},
		[updateWorkspaceMeta, currentTemplate, expandOrCollapse, index]
	);

	const handleCancel = useCallback(() => {
		setValues();
		expandOrCollapse(index);
	}, [setValues, expandOrCollapse, index]);

	const handleInputChange = (e: any, onChange: (event: any[]) => void) => {
		onChange(e);
		provideHandleCloseValues(true);
	};

	return (
		<form onSubmit={handleSubmit(onSave)}>
			<StyledText>Email Subject</StyledText>
			<Controller
				name={"subject"}
				control={control}
				rules={{
					required: "Subject is required",
					minLength: {
						value: 2,
						message: "Subject should not be less than 2 characters."
					}
				}}
				render={({ onChange, value, ref }) => (
					<StyledInput
						placeholder="Email subject"
						errorText={errors["subject"]?.message}
						value={value}
						onChange={e => handleInputChange(e, onChange)}
						ref={ref}
						id={emailSubjectId}
					/>
				)}
			/>
			<StyledText>Email Body</StyledText>
			<Controller
				name={"body"}
				control={control}
				rules={{
					required: "Body is required",
					minLength: {
						value: 2,
						message: "Body should not be less than 2 characters."
					}
				}}
				render={({ onChange, value, ref }) => (
					<StyledInput
						placeholder="Email body"
						errorText={errors["body"]?.message}
						value={value}
						rows={7}
						multiline
						onChange={e => handleInputChange(e, onChange)}
						ref={ref}
						id={emailBodyId}
					/>
				)}
			/>
			<Wrapper>
				<StyledButton onClick={handleCancel} buttonTheme="outline" size="small" id={emailCancelId}>
					Cancel
				</StyledButton>
				<SubmitButton
					type="submit"
					WithLeftMargin
					disabled={!formState.isValid || !formState.isDirty}
					id={emailSaveChangeId}
				>
					Save changes
				</SubmitButton>
			</Wrapper>
		</form>
	);
};

export default EmailTemplate;
