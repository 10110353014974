import React from "react";

import { ReactComponent as BellIcon } from "assets/icons/iconBellFilled.svg";

import { useCommunity } from "shared/hooks";

import { EmptyCard } from "./styles";

const EmptyNotifications: React.FC = () => {
	const { communityColors } = useCommunity();
	return (
		<EmptyCard>
			<EmptyCard.IconWrapper>
				<BellIcon />
			</EmptyCard.IconWrapper>
			<EmptyCard.Title color={communityColors.primary}>{"It's empty here yet"}</EmptyCard.Title>
			<EmptyCard.Description>
				{"We'll update you once you have important activities in your community."}
			</EmptyCard.Description>
		</EmptyCard>
	);
};

export default EmptyNotifications;
