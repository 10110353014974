import React from "react";

import { useLocation } from "react-router";

import { view } from "modules/MemberHome";

const MessagingPage = () => {
	const { search } = useLocation();
	const query = new URLSearchParams(search);
	const messageTo = query.get("messageTo") || undefined;
	const chatId = query.get("chatId") || undefined;

	return <view.Messaging messageTo={messageTo} chatId={chatId} />;
};

export default MessagingPage;
