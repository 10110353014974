import { Box } from "@material-ui/core";
import styled from "styled-components";

import { Button, Text } from "shared/ui-kit";

export const CTAButton = styled(Button)`
	margin: 0;
	padding: ${({ wide }) => (wide ? "18px 36px" : "18px")};
`;

export const UploaderWrapper = styled(Box)`
	width: 100%;
	margin-top: 24px;

	.error {
		font-size: 12px;
		margin-top: 4px;
		padding-left: 4px;
		color: #f44336;
		display: block;
	}

	.item-description {
		display: none;
	}

	> div {
		> div {
			width: 100%;
		}
	}

	.preview-section {
		.item-img-section {
			max-width: 120px;
			max-height: 120px;
			img {
				max-width: 100px;
			}
		}
	}
	.explorer-uploader-label-text {
		color: #222b45;
	}
	.explorer-uploader .description .anchor {
		color: #6173fe;
	}

	.explorer-uploader {
		${props => props.theme.breakpoints.down("sm")} {
			padding: 28px 24px;
		}
		.label {
			${props => props.theme.breakpoints.down("sm")} {
				font-size: 15px;
			}
		}
	}

	${props => props.theme.breakpoints.down("sm")} {
		margin-top: 15px;
	}
`;

export const InputWrapper = styled(Box)`
	margin: 24px 0;
	/* .MuiFormHelperText-root {
		position: absolute;
		top: 100%;
		left: 0;
	} */
`;

export const PinnedBox = styled(Box)`
	display: flex;
	align-items: center;
	justify-content: space-between;
	h6 {
		margin-left: 12px;
	}
`;

export const ContentWrapper = styled(Box)`
	padding: 0 32px;
`;

export const DialogFooter = styled(Box)`
	border-top: 1px solid #edf1f7;
	text-align: right;
	padding: 16px 32px;
	margin-top: ${({ mt }) => (mt ? mt + "px" : "40px")};
	button {
		margin-left: 12px;
	}
`;

export const DeleteText = styled(Text)`
	font-size: 15px;
	color: #8f9bb3;
	margin-top: 20px;
`;
