import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";

import clsx from "clsx";

import { DateTime } from "luxon";

import { DateRangeModel, DateRangeSelector, SmartInput } from "shared/Components";
import { useCommunity } from "shared/hooks";

import { SizesEnum } from "shared/types";

import { DownloadBtn, LabelsWrapper, SelectWrapper, Subtitle, Title, Wrapper } from "./styles";

import { StyledIcon } from "../../../../Payments/View/Containers/Transactions/style";

interface BlockHeaderProps {
	title: string;
	subtitle?: string;
	showDropdown?: boolean;
	defaultValue?: number;
	onChangePeriod?: (requestedFrom: DateTime, requestedTo: DateTime) => void;
	onDownloadCsv?: (requestedFrom: DateTime, requestedTo: DateTime) => Promise<string | undefined>;
	displayDownloadText?: boolean;
	csvFilenamePrefix?: string;
	csvFilename?: string;
}

const BlockHeader: React.FC<BlockHeaderProps> = memo(
	({
		title,
		subtitle,
		showDropdown,
		onChangePeriod,
		defaultValue = 7,
		onDownloadCsv,
		csvFilename,
		csvFilenamePrefix,
		displayDownloadText = false
	}) => {
		const { getData: getCommunityData } = useCommunity();
		const { workspace } = getCommunityData();

		const [period, setPeriod] = useState<number>(defaultValue);
		const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
		const [customDateRange, setCustomTempDateRange] = useState<{ startDate?: Date; endDate?: Date }>();
		const [requestedDateRange, setRequestedDateRange] = useState<{ requestedFrom: Date; requestedTo: Date }>();

		const selectWrapperRef = useRef<HTMLDivElement | null>(null);

		const openDatepicker = useCallback(() => {
			if (selectWrapperRef.current) {
				setAnchorEl(selectWrapperRef.current);
			}
		}, []);

		const handleDownload = useCallback(async () => {
			const requestedFrom = DateTime.fromJSDate(requestedDateRange!.requestedFrom);
			const requestedTo = DateTime.fromJSDate(requestedDateRange!.requestedTo);
			const base64 = onDownloadCsv && (await onDownloadCsv(requestedFrom, requestedTo));
			if (base64) {
				const link = document.createElement("a");
				link.href = window.URL.createObjectURL(
					new Blob([base64], {
						type: "type/csv"
					})
				);
				link.download =
					csvFilename ||
					`${csvFilenamePrefix ? csvFilenamePrefix : "report"}_${requestedFrom.toFormat(
						"yyyy-MM-dd"
					)}_${requestedTo.toFormat("yyyy-MM-dd")}.csv`;
				document.body.appendChild(link);
				link.click();
			}
		}, [onDownloadCsv, csvFilename, csvFilenamePrefix, requestedDateRange]);

		const closeDatepicker = () => {
			setAnchorEl(null);
		};

		const open = Boolean(anchorEl);

		const fetchData = useCallback(
			(requestedFrom: Date, requestedTo: Date) => {
				onChangePeriod && onChangePeriod(DateTime.fromJSDate(requestedFrom), DateTime.fromJSDate(requestedTo));
			},
			[onChangePeriod]
		);

		const onChange = useCallback(
			(range?: DateRangeModel) => {
				if (range) {
					const properRange = {
						startDate: range.startDate!.toJSDate(),
						endDate: range.endDate!.toJSDate()
					};
					setCustomTempDateRange(properRange);
					setRequestedDateRange({ requestedFrom: properRange.startDate, requestedTo: properRange.endDate });
					fetchData(properRange.startDate, properRange.endDate);
				} else {
					setCustomTempDateRange(undefined);
				}
				closeDatepicker();
			},
			[fetchData]
		);

		const changePeriod = useCallback(
			(selectedOption: number) => {
				setPeriod(selectedOption);

				if (selectedOption === -1) {
					openDatepicker();
				} else {
					const requestedTo = DateTime.local();
					const requestedFrom = requestedTo.minus({ days: selectedOption });
					setRequestedDateRange({ requestedFrom: requestedFrom.toJSDate(), requestedTo: requestedTo.toJSDate() });

					fetchData(requestedFrom.toJSDate(), requestedTo.toJSDate());
				}
			},
			[fetchData, openDatepicker]
		);

		useEffect(() => {
			changePeriod(defaultValue);
		}, [changePeriod, defaultValue]);

		const customSelectedRender = useCallback(
			(option: any) => {
				if (option?.value === -1 && customDateRange) {
					const startPeriod = DateTime.fromJSDate(customDateRange.startDate!);
					const endPeriod = DateTime.fromJSDate(customDateRange.endDate!);
					const format = "LLL d";
					return `${startPeriod.toFormat(format)} - ${endPeriod.toFormat(format)}`;
				}
				return option?.label;
			},
			[customDateRange]
		);

		const periodOptionList = useMemo(
			() => [
				{
					label: "Week",
					value: 7
				},
				{
					label: "2 Weeks",
					value: 14
				},
				{
					label: "Month",
					value: 30
				},
				{
					label: "3 Months",
					value: 90
				},
				{
					label: "6 Months",
					value: 182
				},
				{
					label: "Date Range",
					value: -1,
					onClick: () => openDatepicker()
				},
				{
					label: "All Time",
					value: 18000
				}
			],
			[openDatepicker]
		);

		return (
			<Wrapper>
				<LabelsWrapper>
					<Title>{title}</Title>
					{subtitle && <Subtitle>{subtitle}</Subtitle>}
				</LabelsWrapper>
				<div className="flex items-center justify-end gap-4">
					{showDropdown && (
						<>
							<SelectWrapper ref={selectWrapperRef}>
								<SmartInput
									dropdown
									id={`period-selector-${title}-id`}
									placeholder="Select"
									size={SizesEnum.MD}
									value={period}
									options={periodOptionList}
									onChange={changePeriod}
									customSelectedRender={customSelectedRender}
								/>
							</SelectWrapper>
							<DateRangeSelector
								open={open}
								anchorEl={anchorEl}
								onClose={closeDatepicker}
								onChange={onChange}
								minDate={workspace?.createdAt}
								maxDate={DateTime.local().toJSDate()}
							/>
						</>
					)}
					{onDownloadCsv && (
						<DownloadBtn
							removeSideMargin
							buttonTheme="light"
							palette="success"
							onClick={handleDownload}
							size={"medium"}
							className={clsx(!displayDownloadText && "square")}
						>
							<StyledIcon group="color" name="app-excel" width="24px" height="24px" />
							{displayDownloadText ? "Download Excel" : ""}
						</DownloadBtn>
					)}
				</div>
			</Wrapper>
		);
	}
);

export default BlockHeader;
