import { useMemo } from "react";

import useHttpActions from "shared/services/api/core/useHttpActions";

import BaseConverter from "shared/services/converters/baseConverter";

import CommunityModel from "../../types/CommunityModel";
import DefaultResponseType from "../../types/DefaultResponseType";
import { API_URLS } from "../constants";

const useFeatureApiService = () => {
	const actions = useHttpActions();

	return useMemo(
		() => ({
			getDetails: async (): Promise<CommunityModel> => {
				return BaseConverter.convert<CommunityModel>(await actions.get(API_URLS.WORKSPACE_SETTINGS.DETAILS));
			},
			manageFeatures: async (payload): Promise<DefaultResponseType> => {
				return BaseConverter.convert<DefaultResponseType>(
					await actions.put(API_URLS.WORKSPACE_SETTINGS.UPDATE_FEATURES, payload)
				);
			}
		}),
		[actions]
	);
};

export default useFeatureApiService;
