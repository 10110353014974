import React, { ChangeEvent, useRef, useState } from "react";

import { ReactComponent as CloseIcon } from "assets/icons/close.svg";
import { ReactComponent as AddIcon } from "assets/icons/liveconvo/add.svg";
import { ReactComponent as SearchIcon } from "assets/icons/liveconvo/search.svg";

import { SideBar } from "./style";

interface SidebarHeaderProps {
	title: string;
	isSearch?: boolean;
	onChangeSearch?: (val: string) => void;
	showAdd?: boolean;
	onAdd?: () => void;
}

const SidebarHeader: React.FC<SidebarHeaderProps> = ({ title, isSearch, onChangeSearch, showAdd, onAdd }) => {
	const [showSearch, setShowSearch] = useState(false);
	const [keyword, setKeyword] = useState("");

	const inputRef = useRef<HTMLDivElement>(null);

	const toggleShow = () => {
		if (showSearch) {
			setKeyword("");
			onChangeSearch && onChangeSearch("");
		} else if (inputRef.current) {
			const inputs = inputRef.current.getElementsByTagName("input");
			if (inputs.length && inputs[0].focus) {
				inputs[0].focus();
			}
		}

		setShowSearch(!showSearch);
	};

	const handleChangeKeyword = (e: ChangeEvent<HTMLInputElement>) => {
		const val = e.target.value;
		setKeyword(val);
		onChangeSearch && onChangeSearch(val);
	};

	return (
		<SideBar.Header>
			<SideBar.HeaderTitle>{title}</SideBar.HeaderTitle>

			{(isSearch || showAdd) && (
				<SideBar.HeaderActions>
					{isSearch && (
						<SideBar.HeaderSearch className={`search ${showSearch ? "active" : ""}`} ref={inputRef}>
							<SideBar.SearchInput placeholder="Search" value={keyword} onChange={handleChangeKeyword} />
							<SideBar.HeaderSearchIconWrapper onClick={toggleShow} className={showSearch ? "hide" : "show"}>
								<SearchIcon width={22} height={22} fill="#fff" />
							</SideBar.HeaderSearchIconWrapper>
							<SideBar.HeaderSearchIconWrapper onClick={toggleShow} className={showSearch ? "show" : "hide"}>
								<CloseIcon width={16} height={16} fill="#fff" viewBox="-2 -3 24 24" />
							</SideBar.HeaderSearchIconWrapper>
						</SideBar.HeaderSearch>
					)}
					{showAdd && onAdd && (
						<SideBar.HeaderItemWrapper>
							<SideBar.HeaderItem onClick={onAdd}>
								<AddIcon width={18} height={18} fill="#fff" />
							</SideBar.HeaderItem>
						</SideBar.HeaderItemWrapper>
					)}
				</SideBar.HeaderActions>
			)}
		</SideBar.Header>
	);
};

export default SidebarHeader;
