import React, { memo, useMemo } from "react";

import styled from "styled-components";

import { ReactComponent as IcGroups } from "assets/icons/icon-team-linear.svg";

import { ContributorLevelType } from "modules/Manage/Data/types";
import { Box, Icon, Radio, Text } from "shared/ui-kit";

const Container = styled(Box)`
	display: flex;
	flex-direction: column;
	margin: 16px 6px;
	flex: 1;
	max-width: 440px;
`;

const ItemWrapper = styled(Box)`
	display: flex;
	flex: 1;
	justify-content: center;
	align-items: flex-start;
	margin: 6px 0;
`;
const Column = styled(Box)`
	display: flex;
	flex-direction: column;
	flex: 1;
	padding: 0 30px 0 10px;
`;

const StyledRadio = styled(Radio)`
	margin-right: 10px;
	padding: 0;
	width: 40px;
	color: ${props => props.theme.palette.primary.main};
`;

const Title = styled(Text)`
	font-weight: 600;
	font-size: 15px;
	color: #222b45;
`;

const StyledText = styled(Text)`
	font-size: 13px;
	color: #8f9bb3;
`;

interface Props {
	value: string;
	onChange: (value: string) => void;
}

export const whoCanPost: ContributorLevelType[] = [
	{
		value: "INDIVIDUAL",
		icon: <Icon group="filled" fill="#8f9bb3" name="user" />,
		title: "An Individual",
		description: ""
	},
	{
		value: "BUSINESS",
		icon: <IcGroups fill="#8f9bb3" />,
		title: "A Registered Business",
		description: ""
	}
];

const IAmRadioField: React.FC<Props> = memo(({ value = "", onChange }: Props) => {
	const items = useMemo(
		() =>
			whoCanPost.map((item, index) => (
				<ItemWrapper key={index}>
					{item.icon}
					<Column>
						<Title>{item.title}</Title>
						<StyledText variant="p">{item.description}</StyledText>
					</Column>
					<StyledRadio value={value} onChange={() => onChange(item.value)} options={[{ value: item.value }]} />
				</ItemWrapper>
			)),
		[value, onChange]
	);
	return <Container>{items}</Container>;
});

export default IAmRadioField;
