import { Quill } from "react-quill";

const BlockEmbed = Quill.import("blots/block");
const ATTRIBUTES = ["style", "align"];
class ParagraphBlot extends BlockEmbed {
	static create(value) {
		const node = super.create(value);
		node.setAttribute("style", "margin: 0;");
		return node;
	}
	static formats(domNode) {
		return ATTRIBUTES.reduce((formats, attribute) => {
			if (domNode.hasAttribute(attribute)) {
				formats[attribute] = domNode.getAttribute(attribute);
			}
			return formats;
		}, {});
	}
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	static value(domNode) {
		return true;
	}
	format(name, value) {
		if (ATTRIBUTES.indexOf(name) > -1) {
			if (value) {
				this.domNode.setAttribute(name, value);
			} else {
				this.domNode.removeAttribute(name);
			}
		} else {
			super.format(name, value);
		}
	}
}
ParagraphBlot.tagName = "P";
export default ParagraphBlot;
