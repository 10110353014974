import { Box, ButtonBase } from "@material-ui/core";
import styled from "styled-components";

export const PreviewDialogWrapper = styled(Box)``;

PreviewDialogWrapper.Header = styled(Box)`
	padding: 12px 16px;
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

PreviewDialogWrapper.Footer = styled(Box)`
	padding: 12px 16px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	.download {
		padding: 4px 8px;
		border-radius: 8px;
		svg {
			margin-right: 6px;
		}
	}
`;

PreviewDialogWrapper.PDFPreviewContent = styled(Box)`
	position: relative;
	display: flex;
	justify-content: space-between;
	padding: 8px;
	.react-pdf__Document {
		.react-pdf__Page__textContent {
			display: none;
		}
		&:last-child {
			/* flex: 1; */
			.react-pdf__Page__canvas {
				/* width: 100% !important; */
				/* object-fit: contain;
				object-position: top; */
			}
			${props => props.theme.breakpoints.up("sm")} {
				.react-pdf__Page__canvas {
					/* width: 100% !important; */
					/* object-fit: cover; */
					/* object-position: top; */
				}
			}
		}
	}
`;

PreviewDialogWrapper.ScrollableMainContent = styled(Box)`
	width: 100%;
	max-height: 500px;
	overflow-y: scroll;
	display: flex;
	justify-content: center;
	scroll-snap-type: y mandatory;
	.react-pdf__Page {
		scroll-snap-align: start;
	}

	${props => props.theme.breakpoints.up("sm")} {
		width: calc(100% - 200px);
	}

	${props => props.theme.breakpoints.down("sm")} {
		.react-pdf__Page__canvas,
		.react-pdf__Page__textContent {
			width: 100% !important;
			height: auto !important;
		}
		.react-pdf__Page__canvas {
			object-fit: contain;
			object-position: top;
		}
	}
`;

PreviewDialogWrapper.ScrollableSide = styled(Box)`
	width: 200px;
	max-height: 500px;
	position: sticky;
	top: 0;
	overflow-y: auto;
	flex-shrink: 0;
	.react-pdf__Document {
		display: flex;
		flex-direction: column;
	}
	.react-pdf__Page {
		height: 160px;
		margin-bottom: 12px;
	}
	.react-pdf__Page__canvas {
		height: 160px !important;
		object-fit: cover;
		object-position: top;
	}
	.react-pdf__Page__textContent {
		display: none;
	}
	button.MuiButtonBase-root {
		border-radius: 12px;
		overflow: hidden;
	}
`;

PreviewDialogWrapper.ImagePreviewContent = styled(Box)`
	position: relative;
	display: flex;
	justify-content: center;
	overflow: hidden;
	background-size: cover;
	background-repeat: no-repeat;
	img {
		position: relative;
		max-height: 540px;
		max-width: 100%;
		object-fit: cover;
		transform-origin: center center;
	}
`;

PreviewDialogWrapper.BG = styled(Box)`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	backdrop-filter: blur(10px);
	background-color: rgb(0 0 0 / 40%);
`;

PreviewDialogWrapper.Profile = styled(Box)`
	display: flex;
	align-items: center;
	img {
		width: 40px;
		height: 40px;
		border-radius: 8px;
		object-fit: cover;
	}
	.profile-info {
		margin-left: 12px;
		display: flex;
		flex-direction: column;
	}
`;

PreviewDialogWrapper.ImageControls = styled(Box)`
	display: flex;
	align-items: center;
	.zoom-controls {
		margin-left: 8px;
		display: flex;
		align-items: center;
		.input-range__label-container {
			display: none;
		}
		button:first-child {
			margin-right: 6px;
		}
		button:last-child {
			margin-left: 6px;
		}
		.input-range {
			display: none;
		}
		${props => props.theme.breakpoints.up("sm")} {
			.input-range {
				display: block;
				width: 100px;
			}
			button:first-child {
				margin-right: 12px;
			}
			button:last-child {
				margin-left: 12px;
			}
		}
	}
`;

export const PageButton = styled(ButtonBase)`
	&.pdf-page-visible {
		border: ${props => `2px solid ${props.communityColor}`};
	}
`;
