import React, { ReactNode, useCallback, useEffect, useMemo, useState } from "react";

import { InputAdornment } from "@material-ui/core";

import { ReactComponent as SearchIcon } from "assets/icons/iconSearch.svg";

import { data, view } from "modules/Support";
import { ExpansionPanel, Icon, Text } from "shared/ui-kit";

import theme from "theme/default";

import {
	Container,
	ContentContainer,
	NoResultsWrapper,
	Row,
	SearchField,
	SkeletonQuestionWrapper,
	SkeletonRow,
	Title,
	TitleContainer
} from "./styles";

import Splitter from "../Components/Splitter";

interface SupportPageProps {}

const Support: React.FC<SupportPageProps> = () => {
	const [expandedIndex, setExpandedIndex] = useState(-1);
	const [search, setSearch] = useState("");

	const { getSupportItems, getData: getSupportData } = data.useSupport();
	const { supportItems, isLoading } = getSupportData();

	const items = useMemo(() => {
		return (supportItems ?? []).filter(item => item.title.includes(search) || item.description.includes(search));
	}, [supportItems, search]);

	useEffect(() => {
		getSupportItems();
	}, [getSupportItems]);

	const expandOrCollapse = useCallback(
		(id: number) => (id === expandedIndex ? setExpandedIndex(-1) : setExpandedIndex(id)),
		[expandedIndex]
	);

	const getIcon = () => <Icon fill={theme.palette.primary.main} name={"question"} group="filled" />;

	const renderItem = item => <view.SupportItem item={item} />;

	const content = useMemo(() => {
		if (search && !items.length) {
			return (
				<NoResultsWrapper>
					<Text variant="h6">{"We didn't find any results"}</Text>
					<Text>Make sure everything is spelled correctly or try different keywords.</Text>
				</NoResultsWrapper>
			);
		}

		return isLoading ? (
			<>
				{[...Array.from(Array(5).keys())].map(i => (
					<SkeletonQuestionWrapper key={i}>
						<SkeletonRow animation="wave" width={"100%"} height={72} />
					</SkeletonQuestionWrapper>
				))}
			</>
		) : (
			<ExpansionPanel
				panels={
					items.map((el, i) => {
						const icon = getIcon();
						return {
							...el,
							title: (
								<TitleContainer>
									{icon}
									<div className="title">{el.title}</div>
								</TitleContainer>
							) as ReactNode,
							content: (
								<ContentContainer>
									<Splitter />
									{renderItem(el)}
								</ContentContainer>
							) as ReactNode,
							expanded: expandedIndex === i,
							onChange: () => expandOrCollapse(i)
						};
					}) as []
				}
			/>
		);
	}, [items, expandedIndex, search, expandOrCollapse, isLoading]);

	return (
		<Container>
			<Row>
				{isLoading ? (
					<>
						<SkeletonRow animation="wave" width={260} height={46} />
						<SkeletonRow animation="wave" width={"50%"} height={46} />
					</>
				) : (
					<>
						<Title variant="h3">{"Help & Support"}</Title>
						<SearchField
							value={search}
							onChange={e => setSearch(e.target.value)}
							name="name"
							placeholder="Search"
							variant="outlined"
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<SearchIcon />
									</InputAdornment>
								)
							}}
						/>
					</>
				)}
			</Row>
			{content}
		</Container>
	);
};

export default Support;
