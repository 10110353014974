import React from "react";

import styled from "styled-components";

import { Avatar, Box, Icon, Text } from "shared/ui-kit";

const Wrapper = styled(Box)`
	width: 100%;

	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const InfoWrapper = styled(Box)`
	display: flex;
	align-items: center;
`;

const IconWrapper = styled(Box)`
	width: 32px;
	height: 32px;

	margin-right: 16px;
`;

const Info = styled(Box)`
	display: block;
`;

const Title = styled(Text)`
	font-size: 15px;
	font-weight: 600;
	line-height: 1.6;
	color: #222b45;

	margin: 0;
`;

interface CommunityBlockProps {
	title: string;
	iconUrl?: string;
}

const CommunityBlock: React.FC<CommunityBlockProps> = ({ title, iconUrl }) => (
	<Wrapper>
		<InfoWrapper>
			<IconWrapper>
				<Avatar width={32} height={32} title={title || ""} image={iconUrl || undefined} />
			</IconWrapper>
			<Info>
				<Title>{title}</Title>
			</Info>
		</InfoWrapper>
		<Icon group={"filled"} fill={"black"} name={"arrow-right"} />
	</Wrapper>
);

export default CommunityBlock;
