import React, { FC } from "react";

import { Option } from "shared/Components/MenuDots/style";

import { Wrapper } from "./style";

interface Option {
	key: string;
	label: string;
	icon?: JSX.Element;
	description?: string;
}

const RadioGroupInput: FC<{
	options: Option[];
	title?: string;
	value;
	onChange: (...event: any[]) => void;
}> = ({ options, onChange, value, title }) => (
	<Wrapper noTitle={!title}>
		{title && <Wrapper.Headline>{title}</Wrapper.Headline>}
		<Wrapper.Options noMT={!title}>
			{options.map(op => (
				<Wrapper.Option
					checked={value.key === op.key}
					key={op.key}
					onClick={() => {
						onChange(op);
					}}
				>
					<Wrapper.Info>
						<Wrapper.Label checked={value.key === op.key}>
							{op.icon} {op.label}
						</Wrapper.Label>
						{op.description && <Wrapper.Description>{op.description}</Wrapper.Description>}
					</Wrapper.Info>
				</Wrapper.Option>
			))}
		</Wrapper.Options>
	</Wrapper>
);

export default RadioGroupInput;
