import React, { FC, useCallback } from "react";

import DateFnsAdapter from "@date-io/date-fns";

import { Box } from "@material-ui/core";

import { useHistory } from "react-router";
import { ForumPersonaModel } from "types";

import { ReactComponent as DiscussionIcon } from "assets/icons/iconDiscussion.svg";
import { ReactComponent as LocationIcon } from "assets/icons/location_filled.svg";
import { Headline, StyledAbout } from "modules/MemberHome/View/shared/style";
import { useCommunity, useGroup, useMemberRoutes } from "shared/hooks";
import { ContributorModel, MediaModel } from "shared/types";

import { GroupMembersType } from "shared/types/GroupModel";
import { Icon, ReadMore, Text } from "shared/ui-kit";
import { formatCount } from "utils/serviceUtils/helpers";

import { ItemLink } from "../../../Contributors/style";
import { InfoItem, List } from "../../../index";

const dateFns = new DateFnsAdapter();

interface Props {
	admins: GroupMembersType;
	members: GroupMembersType;
	isPrivateClosedGroup: boolean;
	setMembersDialog: React.Dispatch<
		React.SetStateAction<{
			open: boolean;
			admins: boolean;
			list: ForumPersonaModel[];
		}>
	>;
}

const AboutSection: FC<Props> = ({ admins, members, isPrivateClosedGroup, setMembersDialog }) => {
	const { getData: getGroupData } = useGroup();
	const { group } = getGroupData();

	const { communityColors } = useCommunity();

	const { getMemberRoutesData } = useMemberRoutes();
	const { routes } = getMemberRoutesData();

	const history = useHistory();

	const getPhoto = useCallback((persona: ForumPersonaModel): string | undefined => {
		return persona?.photos?.length ? persona.photos[0].profilePicture : undefined;
	}, []);

	const RenderItem = useCallback(
		(item: ContributorModel) => (
			<ItemLink onClick={() => history.push(`${routes?.member.profile.getPath()}/${item.persona.personaId}`)}>
				<InfoItem avatarUrl={getPhoto(item.persona)} title={`${item.persona.firstName} ${item.persona.lastName}`} />
			</ItemLink>
		),
		[getPhoto, history, routes?.member.profile]
	);

	return !group ? null : (
		<>
			<Box className="content-block">
				<StyledAbout fillColor={communityColors.primary}>
					<Headline variant="h3">About Group</Headline>
					<ReadMore text={group.description} showCharCount={300} wrapClassName="" wrapTextClassName="" />
					<Headline variant="h3">Rules</Headline>
					<ReadMore text={group.rules} showCharCount={300} wrapClassName="" wrapTextClassName="" />
					<Headline variant="h3">Group Details</Headline>
					<Box className="details-row">
						{group.private === 0 ? (
							<Box className="details-item">
								<Box className="icon">
									<Icon name="globe" group="linear" fill={communityColors.primary} />
								</Box>
								<Box>
									<Text variant="h5">Public group</Text>
									<Text>Anyone can join</Text>
								</Box>
							</Box>
						) : (
							<Box className="details-item">
								<Box className="icon">
									<Icon name="eye-slash" group="filled" fill={communityColors.primary} />
								</Box>
								<Box>
									<Text variant="h5">Private group</Text>
									<Text>Only invited can join</Text>
								</Box>
							</Box>
						)}
						<Box className="details-item">
							<Box className="icon">
								<Icon name="clock" group="filled" fill={communityColors.primary} />
							</Box>
							<Text variant="h5">Created on {dateFns.format(new Date(group.createdAt), "d/LL/y" as any)}</Text>
						</Box>
						<Box className="details-item">
							<Box className="icon">
								<Icon name="user-friend" group="filled" fill={communityColors.primary} />
							</Box>
							<Box>
								<Text variant="h5">{formatCount(group.totalMembers, "", " ")}</Text>
								<Text>Group members</Text>
							</Box>
						</Box>
						<Box className="details-item">
							<Box className="icon fill-second-path">
								<LocationIcon />
							</Box>
							<Text variant="h5">{group.location}</Text>
						</Box>
						<Box className="details-item">
							<Box className="icon fill-second-path">
								<DiscussionIcon />
							</Box>
							<Box>
								<Text variant="h5">Posts</Text>
								<Text>
									{/* <b>{formatCount(group.totalPosts, "post")}</b> in total | <b>18</b> post daily */}
									<b>{formatCount(group.totalPosts, "post")}</b> in total
								</Text>
							</Box>
						</Box>
						<Box className="details-item">
							{!!group.organizedby?.photos?.length && (
								<Box className="icon">
									<img
										src={(group.organizedby.photos[0] as MediaModel)?.profilePicture}
										alt={group.organizedby.firstName}
									/>
								</Box>
							)}
							<Text variant="h5">
								by {group.organizedby?.firstName} {group.organizedby?.lastName}
							</Text>
						</Box>
					</Box>
				</StyledAbout>
			</Box>
			{!isPrivateClosedGroup && (
				<Box className="admins-list">
					<Box className="mb-4">
						{!!admins.data.length && (
							<List
								title={"Admins"}
								items={admins.data.slice(0, 3) || []}
								onSeeAllClick={
									admins.data.length > 3
										? () => setMembersDialog({ open: true, admins: true, list: admins.data })
										: undefined
								}
								totalCount={10}
								renderItem={RenderItem}
								loading={admins.loading}
								preview={!admins.loading}
							/>
						)}
					</Box>
					{!!members.data.length && (
						<List
							title={"Members"}
							items={members.data.slice(0, 5) || []}
							onSeeAllClick={
								members.data.length > 5
									? () => setMembersDialog({ open: true, admins: false, list: members.data })
									: undefined
							}
							totalCount={10}
							renderItem={RenderItem}
							loading={members.loading}
							preview={!members.loading}
						/>
					)}
				</Box>
			)}
		</>
	);
};

export default AboutSection;
