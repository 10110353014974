import { Box, ButtonBase } from "@material-ui/core";
import styled from "styled-components";

import { Button, Text } from "shared/ui-kit";

export const Container = styled(Box)`
	width: 100%;
	padding: 0 16px;
	max-width: 1120px;
	margin: auto;
`;

export const StyledButton = styled(Button)`
	border-radius: 8px;
	margin: 0;
`;

export const OtherBlock = styled(Box)`
	margin-top: 20px;
`;

OtherBlock.Header = styled(Box)`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

OtherBlock.Body = styled(Box)`
	margin-top: 20px;
`;

OtherBlock.Title = styled(Text)`
	font-size: 22px;
	font-weight: bold;
	line-height: 1.45;
	color: #222b45;
`;

OtherBlock.ManageBtn = styled(Button)`
	border-radius: 8px;
`;

export const ItemWrapper = styled(ButtonBase)`
	width: 248px;
	min-width: 248px;
	text-align: left;

	margin-right: 16px;

	vertical-align: top;
`;
