import React from "react";

import { Box } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";

import { RewardsHistoryList } from "../style";

const SkeletonItem = () => (
	<RewardsHistoryList.ListItem>
		<Box className="left-side">
			<Skeleton variant="rect" width={40} height={40} />
			<Box className="skeleton-text">
				<Skeleton variant="text" width={120} height={24} />
			</Box>
		</Box>
		<Box className="right-side">
			<Skeleton variant="text" width={90} height={24} />
			<Skeleton variant="text" width={60} height={24} />
		</Box>
	</RewardsHistoryList.ListItem>
);

export default SkeletonItem;
