import React, { FC, useMemo } from "react";

import { Box } from "@material-ui/core";

import config from "config/appConfig";

import { ReactComponent as MessageBubbleTail } from "assets/icons/message-bubble-tail.svg";
import { ReactComponent as WelcomeIllustration } from "assets/icons/welcome-illustration.svg";
import JeromeImg from "assets/images/ppJerome.png";
import { useUser } from "shared/hooks";
import useGuidedTour from "shared/hooks/useGuidedTour";
import { Button, Icon, InPlaceModal, Text } from "shared/ui-kit";

import { DialogContentWrapper, DialogFooter, DialogWrapper, MessageBubble, WelcomePopupCloseButton } from "./style";

const manageStepsContent = [
	{
		step: "switcher",
		headline: "Community Switcher",
		content:
			"All of your communities are listed here. Join with another email, switch between workspaces, or create a new community."
	},
	{
		step: "home",
		headline: "Home",
		content:
			"This is where you can return to the main page. Here you will find analytics such as post engagements, payments, and active users."
	},
	{
		step: "create post",
		headline: "Create Post",
		content:
			"Ask a question, write a blog, or check in with your community. Adjust subscription type so that anyone can view, or only those with premium access."
	},
	{
		step: "manage",
		headline: "Manage Content",
		content: "Here you can create content, edit, update, delete, and invite people to view."
	},
	{
		step: "marketplace companies",
		headline: "Marketplace",
		content: "Add links to your online retail partners to provide exclusive deals for your community."
	},
	{
		step: "engage",
		headline: "Engage",
		content: "Send a message to your entire community to keep them engaged."
	},
	{
		step: "community members",
		headline: "Community Members",
		content: " View and connect with members of your community that have similar interests and demographics as you."
	},
	{
		step: "payments",
		headline: "Payments",
		content: "Add your credit card information and deposit money into your bank account."
	},
	{
		step: "analytics",
		headline: "Analytics",
		content: "See how community members are engaging with your posts and keep track of new subscribers."
	},
	{
		step: "messages",
		headline: "Messages",
		content:
			"Check your messages from other community members. Notifications- See who has interacted with your posts with likes and comments."
	},
	{
		step: "team",
		headline: "Admin Team",
		content: "Invite your team to help you manage your community and keep it up-to-date."
	},
	{
		step: "settings",
		headline: "Settings",
		content: "Adjust your profile settings and personal information. "
	},
	{
		step: "integrations",
		headline: "Integrations",
		content: "Connect your existing podcast service, Shopify store and other services, to your community."
	},
	{
		step: "help & support",
		headline: "Help & Support",
		content: "Get help for questions you have about your account."
	},
	{
		step: "notifications",
		headline: "Notifications",
		content: "See who has interacted with your posts with likes and comments."
	},
	{
		step: "profile",
		headline: "Profile",
		content: "Add information about yourself."
	},
	{
		step: "checklist",
		headline: "Checklist",
		content: "Make sure that you have completed everything that you need to have your community up and running."
	}
];

const memberStepsContent = [
	{
		step: "switcher",
		headline: "Community Switcher",
		content:
			"All of your communities are listed here. Join with another email, switch between workspaces, or create a new community."
	},
	{
		step: "liveconvo",
		headline: "Live",
		content: "Create or join live conversations to connect with people across different topics."
	},
	{
		step: "discovery",
		headline: "Discovery",
		content: "This is where you go to see all the recent updates to the community."
	},
	{
		step: "messages",
		headline: "Messages",
		content: "Stay in contact with your connections, groups and events."
	},
	{
		step: "notifications",
		headline: "Notifications",
		content: "Stop by to see everything that you might have missed."
	},
	{
		step: "profile",
		headline: "Your Profile",
		content: "You can find your profile details and shortcut to log out here."
	},
	{
		step: "menu",
		headline: "Menu",
		content: "You can find the awesome sections of your community here."
	},
	{
		step: "createPost",
		headline: "Create a Post",
		content: "Ask a question, write a blog, or check in with your community."
	}
];

const WelcomePopup: FC<{ isMember?: boolean }> = ({ isMember }) => {
	const { setPassedGuidedTour, getData: getUserData } = useUser();
	const { user, isMemberView } = getUserData();

	const { startTour, setShowWelcomeDialog, getData: getGuidedTourData, setChecklistAutoOpen } = useGuidedTour();
	const { showWelcomeDialog } = getGuidedTourData();

	const handleClose = () => {
		setShowWelcomeDialog();
		setPassedGuidedTour(true);
		if (!isMemberView) setChecklistAutoOpen(true);
	};

	const handleStartTour = () => {
		(document.getElementById("root") as HTMLElement).style.overflowY = "hidden";
		if (isMember && showWelcomeDialog?.member) {
			startTour(true, memberStepsContent);
		}

		if (!isMember && showWelcomeDialog?.admin) {
			startTour(false, manageStepsContent);
		}
	};

	const open = useMemo(
		() => !!showWelcomeDialog && (isMember ? showWelcomeDialog.member : showWelcomeDialog.admin),
		[isMember, showWelcomeDialog]
	);

	return (
		<DialogWrapper isMember={isMember}>
			<InPlaceModal maxWidth="sm" modalHeight={900} open={open} onClose={handleClose}>
				<DialogContentWrapper>
					<WelcomePopupCloseButton onClick={handleClose} size="small" id="closeTour">
						<Icon name="close" group="filled" fill="#c5cee0" />
					</WelcomePopupCloseButton>
					<WelcomeIllustration />
					<MessageBubble>
						<Box className="user-profile-wrapper">
							<img src={JeromeImg} alt="Jerome C." />
						</Box>
						<Text variant="body1" className="break-words">
							<span role="img" aria-label="Party popper">
								🎉
							</span>{" "}
							Hi {user?.profiles[0].firstName}, Jerome here from {config.GLOBAL_CONSTANTS.ENV_APPLICATION_NAME} team.
							Welcome to {config.GLOBAL_CONSTANTS.ENV_APPLICATION_NAME}, and I’ll be your tour guide on{" "}
							{config.GLOBAL_CONSTANTS.ENV_APPLICATION_NAME} Platform. Can’t wait to start our tour.
						</Text>
						<MessageBubbleTail />
					</MessageBubble>
				</DialogContentWrapper>
				<DialogFooter className={isMember && "member"}>
					<Button size={"large"} onClick={handleStartTour}>
						Start the Tour
					</Button>
				</DialogFooter>
			</InPlaceModal>
		</DialogWrapper>
	);
};

export default WelcomePopup;
