import styled from "styled-components";

import { Box, Button, Text } from "shared/ui-kit";

export const MonetizeCardContainer = styled(Box)<{ fullMode?: boolean; disabled?: boolean }>`
	position: relative;

	text-align: center;

	box-shadow: inset 0 0 0 0.5px #c5cee0, 0 2px 4px 0 rgba(0, 0, 0, 0.12);

	border-radius: 4px;

	display: flex;
	flex-direction: column;
	justify-content: space-between;

	vertical-align: top;

	width: 296px;
	height: ${({ fullMode }) => (fullMode ? "auto" : "410px")};

	opacity: ${({ disabled }) => (disabled ? 0.4 : 1)};

	cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};

	&:last-child {
		margin-right: 0;
	}

	.chooseMethodBtn {
		display: ${({ fullMode }) => (fullMode ? "initial" : "none")};
	}

	.current-method {
		position: absolute;
		background: #ffd044;
		width: 100%;
		height: 40px;

		.MuiTypography-root {
			font-size: 14px;
			font-weight: bold;
			line-height: 1.14;
			color: #222b45;
			margin-top: 11px;
		}
	}

	&:hover {
		height: auto;

		.chooseMethodBtn {
			display: initial;
		}
	}

	${props => props.theme.breakpoints.down("sm")} {
		width: 100%;
		margin-bottom: 32px;
	}

	${props => props.theme.breakpoints.up("sm")} {
		width: auto;
	}
`;

export const IllustrationCard = styled.img`
	width: 100%;
	object-fit: cover;

	width: 100%;
	height: 190px;
`;

export const Title = styled(Text)`
	margin: 24px 20px 16px 20px;
	font-size: 18px;
	font-weight: bold;
	text-align: left;
`;

export const StyledBody = styled(Text)`
	text-align: left;
	margin: 0 20px 16px 20px;
	font-size: 15px;
	min-height: 115px;
`;

export const StyledBtn = styled(Button)`
	margin: 24px auto 16px auto;
	width: 90%;
`;
