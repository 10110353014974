import React from "react";

import styled, { css } from "styled-components";

import { Icon } from "shared/ui-kit";

export interface ThemeCardProps {
	primaryColor: string;
	primaryBtnColor: string;
	isShort?: boolean;
	customStyle?: () => string;
}

const ThemeCardWrapper = styled.div<{ isShort?: boolean }>`
	max-width: 128px;

	${({ isShort }) => {
		return !isShort
			? css`
					width: 128px;
					height: 105px;

					border-radius: 4px;
					border: solid 1px #e4e9f2;

					overflow: hidden;
			  `
			: "";
	}}
`;

const ThemeCardHeader = styled.div<{ isShort?: boolean; color: string }>`
	width: 100%;
	height: 36px;
	max-height: ${({ isShort }) => (isShort ? 0 : "36px")};
	overflow: ${({ isShort }) => (isShort ? "hidden" : "visible")};

	background: ${({ color }) => (color ? color : "black")};

	position: relative;

	.theme-card__header-icon-wrapper {
		display: flex;
		justify-content: center;
		align-items: center;

		position: absolute;

		left: calc(50% - 21px);
		bottom: -21px;

		width: 42px;
		height: 42px;

		border: solid 1px ${({ color }) => (color ? color : "black")};
		border-radius: 50%;

		overflow: hidden;

		background-color: #e4e9f2;
	}

	transition: max-height 0.5s ease-in-out;
`;

const ThemeCardBodyWrapper = styled.div<{ isShort?: boolean }>`
	transition: padding 0.5s ease-in-out;
	padding: ${({ isShort }) => (isShort ? 0 : "30px 15px 15px 15px")};
`;

const ThemeCardBody = styled.div<{ color: string }>`
	width: 100%;
	max-width: 115px;
	height: 21px;
	border-radius: 11px;
	background-color: ${({ color }) => (color ? color : "black")};
	margin: 0 auto;
`;

const ThemeCardComponent = (props: ThemeCardProps): JSX.Element => {
	const { isShort, primaryColor, primaryBtnColor } = props;

	return (
		<ThemeCardWrapper isShort={isShort} className={`theme-card__wrapper ${isShort ? "theme-card__wrapper_short" : ""}`}>
			<ThemeCardHeader color={primaryColor} isShort={isShort} className="theme-card__header">
				<div className="theme-card__header-icon-wrapper">
					<Icon group={"filled"} fill={"white"} name={"image-landscape"} width={19} viewBox={"0 0 19 19"} />
				</div>
			</ThemeCardHeader>
			<ThemeCardBodyWrapper isShort={isShort} className="theme-card__body-wrapper">
				<ThemeCardBody color={primaryBtnColor} className="theme-card__body" />
			</ThemeCardBodyWrapper>
		</ThemeCardWrapper>
	);
};

export default styled((props: ThemeCardProps): JSX.Element => {
	return <ThemeCardComponent {...props} />;
})`
	${({ customStyle }: ThemeCardProps) => (customStyle ? customStyle() : "")}
`;
