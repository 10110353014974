import React from "react";

import Box from "@material-ui/core/Box";
import styled from "styled-components";

import NotFoundImg from "assets/images/error_bg.png";

const StyledNotFoundWrapper = styled(Box)`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	height: 100vh;

	span {
		color: #222b45;
		font-weight: 700;
		font-size: 18px;
		text-align: center;
	}
`;

const NotFound = () => {
	return (
		<StyledNotFoundWrapper>
			<img src={NotFoundImg} alt="404 not found" />
			<span>
				Oops,
				<br />
				looks like you got lost.
			</span>
		</StyledNotFoundWrapper>
	);
};

export default NotFound;
