import styled, { keyframes } from "styled-components";

import { Box } from "shared/ui-kit";

import { randomInteger } from "utils/serviceUtils/helpers";

export const ReactionWrapper = styled(Box)`
	position: absolute;

	bottom: 0;
	right: 0;

	color: white;

	width: 200px;

	pointer-events: none;

	z-index: 9;
`;

const floatAnimation = keyframes`
	0%    { opacity: 1.0; right: ${randomInteger(0, 10)}px; bottom: 0; transform: rotate(0deg); }
	20%   { opacity: 0.8; right: ${randomInteger(0, 10)}px; bottom: 40px; transform: rotate(-2deg); }
	40%   { opacity: 0.6; right: ${randomInteger(0, 20)}px; bottom: 80px; transform: rotate(0deg); }
	60%   { opacity: 0.4; right: ${randomInteger(0, 30)}px; bottom: 120px; transform: rotate(2deg); }
	80%   { opacity: 0.2; right: ${randomInteger(0, 40)}px; bottom: 160px; transform: rotate(0deg); }
	100%  { opacity: 0.0; right: ${randomInteger(0, 50)}px; bottom: 200px; transform: rotate(-2deg); }
`;

export const ReactionIconWrapper = styled(Box)`
	animation: ${floatAnimation} 1.5s linear;

	position: absolute;
	right: 0;
	bottom: 0;

	svg {
		width: 20px;
		height: 20px;

		fill: #ffd16d;

		g {
			width: 100%;
			height: 100%;
		}
	}
`;
