import { Box, ButtonBase } from "@material-ui/core";
import styled from "styled-components";

export const Wrapper = styled(Box)`
	position: relative;
	z-index: 100;
`;

export const Menu = styled(Box)`
	position: absolute;
	right: 0;
	top: calc(100% + 4px);
	width: 256px;
	border-radius: 12px;
	box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12), inset 0 0 0 0.5px #c5cee0;
	background-color: #fff;
	padding: 8px;
	transform: ${({ show }) => (show ? "translateY(0)" : "translateY(45px)")};
	opacity: ${({ show }) => (show ? 1 : 0)};
	pointer-events: ${({ show }) => (show ? "auto" : "none")};
	transition: 0.3s ease-in-out all;
`;

Menu.Item = styled(ButtonBase)`
	padding: 8px;
	margin: 0;
	border-radius: 8px;
	width: 100%;
	align-items: center;
	justify-content: flex-start;
	margin-left: 0 !important;
	font-size: 13px;
	font-weight: 600;
	line-height: 1.85;
	color: #222b45;
	svg {
		margin-right: 8px;
		fill: #8f9bb3;
		&.fill-first-path path:first-child {
			fill: #8f9bb3;
		}
		&.fill-second-path path:nth-child(2) {
			fill: #8f9bb3;
		}
		use {
			fill: #8f9bb3;
		}
	}
	&:hover {
		background-color: #f7f9fc;
	}
`;
