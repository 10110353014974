import styled from "styled-components";

import { Box, Text } from "shared/ui-kit";

export const Wrapper = styled(Box)`
	width: 100%;
	padding: ${({ customPadding }) => customPadding || "12px 16px"};
	border-radius: 12px;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08), inset 0 0 0 0.5px #c5cee0;
	background-color: #fff;

	&.pageBlock {
		padding: 0;
	}

	&.pageItem {
		padding: 0;
		background: transparent;
		box-shadow: none;
	}
`;

export const TitleWrapper = styled(Box)`
	&.pageBlock {
		padding: 18px 25px 15px 25px;
		border-bottom: 1px solid #c5cee0;
	}
`;

export const Title = styled(Text)`
	font-size: ${({ smallTitle }) => (smallTitle ? "18px" : "22px")};
	font-weight: bold;
	line-height: 1.45;
	color: #222b45;
	margin-left: ${({ ml }) => ml || 0};
`;

export const BodyWrapper = styled(Box)``;
