import React, { memo, useMemo } from "react";

import { ButtonBase } from "@material-ui/core";
import styled from "styled-components";

import { ReactComponent as DollarIcon } from "assets/icons/icon-dollar-linear.svg";
import { ReactComponent as CrownIcon } from "assets/icons/iconCrownLinear.svg";

import { ReactComponent as PeopleIcon } from "assets/icons/iconPeople.svg";

import { ContributorLevelType } from "modules/Manage/Data/types";
import { Box, Radio, Text } from "shared/ui-kit";

const Container = styled(Box)`
	display: flex;
	flex-direction: column;
	margin: 16px 0;
	flex: 1;
`;

const ItemWrapper = styled(ButtonBase)`
	display: flex;
	justify-content: space-between;
	flex: 1;
	align-items: center;
	text-align: left;
	margin: 12px 0;
`;
const Column = styled(Box)`
	display: flex;
	flex-direction: column;
	flex: 1;
	padding: 0 30px 0 10px;
	${props => props.theme.breakpoints.down("sm")} {
		p {
			font-size: 13px;
		}
		span {
			font-size: 12px;
		}
	}
`;

const StyledRadio = styled(Radio)`
	padding: 0;
	width: 40px;
	display: flex;
	justify-content: flex-end;
	color: ${props => props.theme.palette.primary.main};
	.MuiFormControlLabel-root {
		margin: 0;
	}
`;
const Title = styled(Text)`
	font-weight: 600;
`;

interface Props {
	value: string;
	onChange: (value: string) => void;
}

export const whoCanPost: ContributorLevelType[] = [
	{
		value: "ANYONE",
		icon: <PeopleIcon fill="#8f9bb3" />,
		title: "Anyone",
		description: "Any member of the community can create a post on this board"
	},
	{
		value: "ADMIN",
		icon: <CrownIcon fill="#8f9bb3" />,
		title: "Admins & Moderators",
		description: "Only community admins & moderators can create a post on this board"
	},
	{
		value: "PAID",
		icon: <DollarIcon fill="#8f9bb3" />,
		title: "Paid",
		description: "Subscribed community member, admins & moderators can create a post on this board"
	}
];

const WhoCanPostField: React.FC<Props> = memo(({ value = "", onChange }: Props) => {
	const items = useMemo(
		() =>
			whoCanPost.map((item, i) => (
				<ItemWrapper onClick={() => onChange(item.value)} key={i}>
					{item.icon}
					<Column>
						<Title>{item.title}</Title>
						<Text variant="p">{item.description}</Text>
					</Column>
					<StyledRadio
						id={item.value}
						value={value}
						onChange={() => onChange(item.value)}
						options={[{ value: item.value }]}
					/>
				</ItemWrapper>
			)),
		[value, onChange]
	);
	return (
		<Container>
			<Text variant="h6">Who Can Post</Text>
			{items}
		</Container>
	);
});

export default WhoCanPostField;
