import React from "react";

import { Box } from "@material-ui/core";
import { Controller, useForm } from "react-hook-form";
import { Link, useHistory } from "react-router-dom";

import { Button, Icon, Input, Loader, Text } from "shared/ui-kit";

import { validateEmail } from "utils/serviceUtils/validators";

import { useAuth } from "../../../Data";
import { AuthPagesContainer } from "../SignIn/styles";
import { ContentWrapperBlock, InputWrapper, OtherLoginOptions, Subtitle } from "../style";

export interface ForgotPasswordProps {
	signInLink: string;
	resetPasswordLink: string;
}

const ForgotPassword: React.FC<ForgotPasswordProps> = ({ signInLink, resetPasswordLink }) => {
	const history = useHistory();
	const { forgotPassword, getData: getAuthData } = useAuth();
	const { isLoading } = getAuthData();

	const { handleSubmit, control, errors, formState } = useForm({ mode: "onChange" });

	const onSubmit = async data => {
		const status = await forgotPassword(data.email);
		if (status) {
			history.push(`${resetPasswordLink}?email=${data.email}`);
		}
	};

	return (
		<ContentWrapperBlock>
			<AuthPagesContainer>
				<form onSubmit={handleSubmit(onSubmit)}>
					<Box className="header">
						<Text variant="h2">Forgot Password?</Text>
						<Subtitle variant="body1">Enter your email and we’ll send a link to reset your password.</Subtitle>
						<Controller
							name="email"
							control={control}
							rules={{ required: "", validate: value => validateEmail(value) }}
							render={({ onChange, value }) => (
								<InputWrapper>
									<Input
										label="Email Address"
										value={value ? value.toLowerCase() : ""}
										success={!!value && !errors.email}
										errorText={value && errors.email ? "This email isn't valid" : ""}
										onChange={onChange}
										rightIcon={value && !errors.email ? <Icon fill={"#23b34a"} name={"check-circle"} /> : undefined}
									/>
								</InputWrapper>
							)}
						/>
						<Button removeSideMargin fullWidth size="large" disabled={!formState.isValid} type="submit">
							{isLoading ? <Loader size="15px" color="secondary" show variant="intermediate" /> : "Send Reset Link"}
						</Button>
						<OtherLoginOptions>
							<Link to={signInLink}>Return to Login </Link>
						</OtherLoginOptions>
					</Box>
				</form>
			</AuthPagesContainer>
		</ContentWrapperBlock>
	);
};

export default ForgotPassword;
