export const fileExtensions = [
	{
		ext: "aac",
		mime: "audio/aac"
	},
	{
		ext: "wmv",
		mime: "video/x-ms-asf"
	},
	{
		ext: "mov",
		mime: "video/quicktime"
	},
	{
		ext: "abw",
		mime: "application/x-abiword"
	},
	{
		ext: "arc",
		mime: "application/x-freearc"
	},
	{
		ext: "avif",
		mime: "image/avif"
	},
	{
		ext: "avi",
		mime: "video/x-msvideo"
	},
	{
		ext: "azw",
		mime: "application/vnd.amazon.ebook"
	},
	{
		ext: "psd",
		mime: "application/octet-stream"
	},
	{
		ext: "bmp",
		mime: "image/bmp"
	},
	{
		ext: "bz",
		mime: "application/x-bzip"
	},
	{
		ext: "bz2",
		mime: "application/x-bzip2"
	},
	{
		ext: "cda",
		mime: "application/x-cdf"
	},
	{
		ext: "csh",
		mime: "application/x-csh"
	},
	{
		ext: "css",
		mime: "text/css"
	},
	{
		ext: "csv",
		mime: "text/csv"
	},
	{
		ext: "doc",
		mime: "application/msword"
	},
	{
		ext: "docx",
		mime: "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
	},
	{
		ext: "eot",
		mime: "application/vnd.ms-fontobject"
	},
	{
		ext: "epub",
		mime: "application/epub+zip"
	},
	{
		ext: "gz",
		mime: "application/gzip"
	},
	{
		ext: "ai",
		mime: "application/postscript"
	},
	{
		ext: "eps",
		mime: "application/postscript"
	},
	{
		ext: "gif",
		mime: "image/gif"
	},
	{
		ext: "html",
		mime: "text/html"
	},
	{
		ext: "ico",
		mime: "image/vnd.microsoft.icon"
	},
	{
		ext: "ics",
		mime: "text/calendar"
	},
	{
		ext: "jar",
		mime: "application/java-archive"
	},
	{
		ext: "jpg",
		mime: "image/jpeg"
	},
	{
		ext: "jpeg",
		mime: "image/jpeg"
	},
	{
		ext: "js",
		mime: "text/javascript"
	},
	{
		ext: "json",
		mime: "application/json"
	},
	{
		ext: "jsonld",
		mime: "application/ld+json"
	},
	{
		ext: "mid",
		mime: "audio/midi"
	},
	{
		ext: "midi",
		mime: "audio/x-midi"
	},
	{
		ext: "mjs",
		mime: "text/javascript"
	},
	{
		ext: "mp3",
		mime: "audio/mpeg"
	},
	{
		ext: "mp4",
		mime: "video/mp4"
	},
	{
		ext: "mpeg",
		mime: "video/mpeg"
	},
	{
		ext: "mpkg",
		mime: "application/vnd.apple.installer+xml"
	},
	{
		ext: "odp",
		mime: "application/vnd.oasis.opendocument.presentation"
	},
	{
		ext: "ods",
		mime: "application/vnd.oasis.opendocument.spreadsheet"
	},
	{
		ext: "odt",
		mime: "application/vnd.oasis.opendocument.text"
	},
	{
		ext: "oga",
		mime: "audio/ogg"
	},
	{
		ext: "ogv",
		mime: "video/ogg"
	},
	{
		ext: "ogx",
		mime: "application/ogg"
	},
	{
		ext: "opus",
		mime: "audio/opus"
	},
	{
		ext: "otf",
		mime: "font/otf"
	},
	{
		ext: "png",
		mime: "image/png"
	},
	{
		ext: "pdf",
		mime: "application/pdf"
	},
	{
		ext: "php",
		mime: "application/x-httpd-php"
	},
	{
		ext: "ppt",
		mime: "application/vnd.ms-powerpoint"
	},
	{
		ext: "pptm",
		mime: "application/vnd.ms-powerpoint.presentation.macroEnabled.12"
	},
	{
		ext: "pptx",
		mime: "application/vnd.openxmlformats-officedocument.presentationml.presentation"
	},
	{
		ext: "rtf",
		mime: "application/rtf"
	},
	{
		ext: "sh",
		mime: "application/x-sh"
	},
	{
		ext: "svg",
		mime: "image/svg+xml"
	},
	{
		ext: "swf",
		mime: "application/x-shockwave-flash"
	},
	{
		ext: "tar",
		mime: "application/x-tar"
	},
	{
		ext: "tiff",
		mime: "image/tiff"
	},
	{
		ext: "ts",
		mime: "video/mp2t"
	},
	{
		ext: "ttf",
		mime: "font/ttf"
	},
	{
		ext: "txt",
		mime: "text/plain"
	},
	{
		ext: "vsd",
		mime: "application/vnd.visio"
	},
	{
		ext: "wav",
		mime: "audio/wav"
	},
	{
		ext: "weba",
		mime: "audio/webm"
	},
	{
		ext: "webm",
		mime: "video/webm"
	},
	{
		ext: "webp",
		mime: "image/webp"
	},
	{
		ext: "woff",
		mime: "font/woff"
	},
	{
		ext: "woff2",
		mime: "font/woff2"
	},
	{
		ext: "xhtml",
		mime: "application/xhtml+xml"
	},
	{
		ext: "xls",
		mime: "application/vnd.ms-excel"
	},
	{
		ext: "xlsx",
		mime: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
	},
	{
		ext: "xml",
		mime: "application/xml"
	},
	{
		ext: "xul",
		mime: "application/vnd.mozilla.xul+xml"
	},
	{
		ext: "3gp",
		mime: "video/3gpp"
	},
	{
		ext: "3g2",
		mime: "video/3gpp2"
	}
];
