import React from "react";

import { useIntegrations } from "modules/Integrations/Data/hooks";

import ShopifyCard from "../Cards/ShopifyCard";
import IntegrationCard from "../IntegrationCard";
import IntegrationRow from "../IntegrationRow";

const SearchResults = () => {
	const { getData: getIntegrationsData } = useIntegrations();
	const { searchResults } = getIntegrationsData();

	if (!searchResults.length) return null;

	return (
		<IntegrationRow title="Search Results">
			{searchResults.map(podcast =>
				podcast.name === "Shopify" ? <ShopifyCard /> : <IntegrationCard content={podcast} key={podcast._id} />
			)}
		</IntegrationRow>
	);
};

export default SearchResults;
