import { useCallback, useMemo } from "react";

import { useUserApiService } from "shared/services";

import { useGifStore } from "../contexts";

const useGif = () => {
	const service = useUserApiService();

	const store = useGifStore();
	const { setState } = useGifStore();

	const methods = useMemo(
		() => ({
			getListOfGifs: async ({
				keyword = "",
				offset = 1,
				limit = 24
			}: {
				keyword?: string;
				offset?: number;
				limit?: number;
			}) => {
				setState(ctx => ({ ...ctx, loading: true }));
				const list = await service.getGifs({ keyword, offset, limit });
				setState(ctx => ({ ...ctx, list: !offset ? list : (ctx.list || []).concat(list), loading: false }));
			}
		}),
		[service, setState]
	);

	const getData = useCallback(() => {
		return store;
	}, [store]);

	return { ...methods, getData };
};

export default useGif;
