import React, { FC } from "react";

import { Box, Button, Link } from "@material-ui/core";

import ShopifyImg from "assets/images/shopify.png";
import { useIntegrations } from "modules/Integrations/Data/hooks";
import { Icon, Text } from "shared/ui-kit";

import { IntegrationCardContainer } from "../../Containers/Integrations/styles";
import ShopifyDialog from "../ShopifyDialog";

interface Props {
	connected?: boolean;
}

const ShopifyCard: FC<Props> = ({ connected }) => {
	const { setOpenShopifyDialog } = useIntegrations();
	return (
		<>
			<ShopifyDialog />
			<IntegrationCardContainer
				showStatus={connected}
				integrationStatus="connected"
				onClick={() => setOpenShopifyDialog(true)}
				className="card-r"
			>
				<Button className="logo-container">
					<img src={ShopifyImg} alt="Shopify" />
					{connected && (
						<Box className="status">
							<Icon height={20} width={20} name="check-circle" group="filled" fill="#23b34a" />
						</Box>
					)}
				</Button>
				<Box className="info">
					<Link href={process.env.REACT_APP_SHOPIFY_URL} target="_blank" rel="noopener noreferrer">
						shopify.com
					</Link>
					<Box className="title-status">
						<Text variant="h6">Shopify</Text>
						{connected && <Box className="status green">connected</Box>}
					</Box>
				</Box>
			</IntegrationCardContainer>
		</>
	);
};

export default ShopifyCard;
