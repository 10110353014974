import { FormControl as MuiFormControl, RadioGroup } from "@material-ui/core";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import styled from "styled-components";

import { ReactComponent as MessageIcon } from "assets/icons/iconMessage.svg";
import { Box, Button, Divider, Radio, Text } from "shared/ui-kit";

export const FormControl = styled(MuiFormControl)`
	margin-top: 12px;
	margin-bottom: 12px;

	&.inner {
		padding: 0 16px;
	}
`;

export const CustomDivider = styled(Divider)`
	border-top: 1px solid #e4e9f2;
	margin: 0;
`;

export const UploaderWrapper = styled(Box)`
	width: 100%;
	margin-top: 24px;

	.item-description {
		display: none;
	}

	> div {
		> div {
			width: 100%;
		}
	}

	.preview-section {
		.item-img-section {
			max-width: 120px;
			max-height: 120px;
			img {
				max-width: 100px;
			}
		}
	}

	.explorer-uploader-label-text {
		color: #222b45;
		white-space: nowrap;
	}

	.explorer-uploader .description .anchor {
		color: #6173fe;
	}

	.explorer-uploader {
		${props => props.theme.breakpoints.down("sm")} {
			padding: 28px 24px;
		}
		.label {
			${props => props.theme.breakpoints.down("sm")} {
				font-size: 15px;
			}
		}
	}

	${props => props.theme.breakpoints.down("sm")} {
		margin-top: 15px;
	}
`;

export const MessageControlWrapper = styled(FormControl)`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	p {
		display: flex;
		align-items: center;
	}

	svg {
		margin-right: 5px;
	}
`;

export const GroupChatWrapper = styled(Box)`
	padding: 3px 16px;
	border-radius: 8px;
	border: solid 1px #e4e9f2;
	background-color: #f7f9fc;

	.enable-chat {
		margin-top: 0;
		margin-bottom: 0;
	}
`;

export const StyledMessageIcon = styled(MessageIcon)`
	color: #8f9bb3;
`;

export const SubText = styled.span`
	color: #8f9bb3;
	font-weight: 600;
`;

export const ActionBtn = styled(Button)`
	height: 48px;
`;

export const SectionTitle = styled(Text)`
	color: #222b45;

	&.extra-space {
		margin-top: 12px;
	}
`;

export const BorderWrapper = styled(Box)`
	padding: 16px;
	border-radius: 8px;
	border: solid 1px #e4e9f2;
`;

export const HiddenItem = styled(Box)`
	padding: 10px 10px 16px 15px;
`;

export const IconWrapper = styled(Box)`
	svg {
		path {
			fill: #8f9bb3;
		}
	}
`;

export const StyledRadioGroup = styled(RadioGroup)`
	display: block;

	&.private-radios {
		label {
			justify-content: start;
			min-height: 68px;
			margin: 8px 0;
			padding: 10px 10px 16px 15px;
			border-radius: 8px;
			border: solid 1px #e4e9f2;
			width: 100%;

			div {
				justify-content: flex-start;
				width: unset;
				span {
					padding: 0;
				}
			}
		}
	}
`;

export const LabelHeadline = styled(Text)`
	font-size: 13px;
	font-weight: 600;
	line-height: 1.85;
	color: #222b45;
	margin-bottom: 2px;
`;

export const StyledRadio = styled(Radio)`
	${props => props.theme.breakpoints.down("sm")} {
		flex-direction: column;
		.MuiFormGroup-row {
			flex-direction: column;
		}
	}
`;

export const EnableText = styled(Text)`
	display: flex;
	align-items: center;
	font-weight: 600;

	svg {
		margin-right: 5px;
	}
`;

export const CustomLocationIcon = styled(LocationOnIcon)`
	color: ${props => props.theme.palette.text.secondary};
	margin-right: 15px;
`;
