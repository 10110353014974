import config from "config/appConfig";

import { IStateProps } from "utils/StateOf";
import createStore from "utils/createStore";

import { PersonStoryModel } from "../../types";

const localStorageKey = `${config.GLOBAL_CONSTANTS.LOCAL_STORAGE_PREFIX}${config.HOOK_STORAGE_KEYS.STORY_DATA}`;

export enum STORIES_OPEN_TYPE {
	STORIES = "STORIES",
	MATCHES = "MATCHES",
	RECOMMENDED_MATCHES = "RECOMMENDED_MATCHES"
}
export interface Values {
	personsStoryList: PersonStoryModel[];
	isOpen?: STORIES_OPEN_TYPE;
	muteStory: boolean;
	defaultStoryId?: string;
	slideId?: string;
	pauseStory: boolean;
	currentStorySlide: number;
	removePersonConnection?: {
		connectionId: string;
		name: string;
	};
	reportDialog?: {
		reporteeId: number;
		reportType: string;
		reason?: string;
	};
	dontRequestdata?: boolean;
}

export const initialState: IStateProps<Values> = {
	personsStoryList: [],
	muteStory: false,
	pauseStory: false,
	currentStorySlide: 0
};

export default createStore<Values>({
	initialState,
	persistant: true,
	persistantName: localStorageKey
});
