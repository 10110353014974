import React, { useCallback, useEffect, useMemo, useState } from "react";

import { useMediaQuery } from "@material-ui/core";
import { UserFileSortBy } from "types";

import { CreateTrack } from "shared/Components";
import { useTrack, useUser } from "shared/hooks";
import { useS3Uploader } from "shared/services/s3Uploader";
import * as appTheme from "theme/default";

import { dataUrlToFile, filterFiles } from "utils/serviceUtils/helpers";

import { FilterBlock, FilterOptionType, ModelBlock, PageWrapper } from "../../Components";
import { ModelBlockType } from "../../Components/ModelBlock";
import { ManInLotus, MessageText, NoConnectionsBlock, StyledButton } from "../Connections/style";
import { FilterType } from "../Files";

const correctFileTypes = ["audio/*"];

const defaultFilters = {
	limit: 20,
	keyword: "",
	sortBy: "publishDate"
};

export interface TracksPageProps {
	groupId?: string;
}

export enum TracksFilters {
	MOST_RECENT = "MOST_RECENT",
	MOST_POPULAR = "MOST_POPULAR",
	MY_TRACKS = "MY_TRACKS"
}

const TracksPage: React.FC<TracksPageProps> = ({ groupId }) => {
	const {
		getTracks,
		getAllTrackCount,
		setTrackInfoPopup,
		setTrackDuration,
		setTrackName,
		setTrackFileUrl,
		setRefresh,
		getData: getTrackData
	} = useTrack();
	const { trackInfoPopup, filteredTracks, allTrackCount, loading, refresh, trackUploadUrl } = getTrackData();

	const { getData: getUserData } = useUser();
	const { user } = getUserData();

	const { uploadFile } = useS3Uploader();

	const [loadData, setLoadData] = useState(true);
	const [filters, setFilters] = useState<FilterType>({ notEmptyBy: "music", offset: 1 });
	const hasMoreItems = useMemo(
		() => allTrackCount && filteredTracks && allTrackCount > filteredTracks.length,
		[allTrackCount, filteredTracks]
	);

	const isMobile = useMediaQuery(appTheme.default.breakpoints.down("sm"));

	useEffect(() => {
		if (loadData) {
			getTracks({ ...defaultFilters, ...filters, groupId, paginate: true });
		}
	}, [getTracks, loadData, filters, refresh, groupId]);

	useEffect(() => {
		getAllTrackCount(groupId);
	}, [getAllTrackCount, groupId]);

	const onEndScroll = useCallback(() => {
		if (hasMoreItems) {
			setFilters(ctx => ({ ...ctx, offset: ctx.offset + 1 }));
			setLoadData(true);
		}
	}, [hasMoreItems]);

	const handleUpload = useCallback(() => {
		const el: any = document.getElementById("trackFileUrl");
		if (el) {
			el.value = null;
			el.click();
		}
	}, []);

	const onUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
		const { target } = e;
		const { files } = target;

		if (files) {
			setTrackFileUrl(null);
			const filteredFiles = filterFiles(files, correctFileTypes);

			const trackFile = !!filteredFiles?.length ? files[0] : null;

			if (trackFile) {
				const correctFile = typeof trackFile === "string" ? await dataUrlToFile(trackFile, "test") : trackFile;

				const audio = document.createElement("audio");
				const reader = new FileReader();

				reader.onload = e => {
					audio.src = (e.target?.result as string) || "";
					audio.addEventListener("loadedmetadata", () => {
						const duration = audio.duration;
						setTrackDuration(`${duration}`);
					});
				};

				reader.readAsDataURL(correctFile);

				uploadFile({
					file: correctFile as File,
					communityName: trackUploadUrl,
					checkProgress: true,
					customCallback: data => setTrackFileUrl(data?.publicUrl)
				});

				setTrackName(files && files[0].name.slice(0, files[0].name.lastIndexOf(".")));

				setTrackInfoPopup({ open: true, model: undefined });
			}
		}
	};

	const filterOptions: FilterOptionType[] = useMemo(
		() => [
			{
				inlineOptions: true,
				onClick: item => {
					switch (item) {
						case TracksFilters.MOST_POPULAR:
							return setFilters({ sortBy: UserFileSortBy.totalPlays, offset: 1 });
						case TracksFilters.MY_TRACKS:
							return user && setFilters({ personaId: user.activeProfile, offset: 1 });
						default:
							setFilters({ sortBy: UserFileSortBy.publishDate, offset: 1 });
					}
				},
				listOptions: [
					{
						label: "Most Recent",
						value: TracksFilters.MOST_RECENT,
						default: true
					},
					{
						label: "Most Popular",
						value: TracksFilters.MOST_POPULAR
					},
					{
						label: "My Tracks",
						value: TracksFilters.MY_TRACKS
					}
				]
			}
		],
		[user]
	);

	const createOption = useMemo(
		() => ({
			label: "Create New Track",
			onClick: () => {
				handleUpload();
			}
		}),
		[handleUpload]
	);

	return (
		<PageWrapper>
			<FilterBlock options={filterOptions} createOption={createOption} />
			<ModelBlock
				autoFit={filteredTracks && filteredTracks.length > 3 ? 258 : undefined}
				title={"Audio Tracks"}
				type={ModelBlockType.track}
				items={filteredTracks || []}
				loading={loading}
				keepCurrentData={loading && filters.offset > 1}
				inlineView={!isMobile}
				onEndScroll={onEndScroll}
				noContent={
					<NoConnectionsBlock>
						<ManInLotus />
						<MessageText>You don’t have any audio tracks.</MessageText>
						<StyledButton palette="light" buttonTheme="light" size="medium" onClick={handleUpload} removeSideMargin>
							+ Create New Track
						</StyledButton>
					</NoConnectionsBlock>
				}
			/>
			<input
				id="trackFileUrl"
				type="file"
				onChange={onUpload}
				name="trackFileUrl"
				hidden
				accept={correctFileTypes.join(",")}
			/>
			{trackInfoPopup.open && (
				<CreateTrack
					open={true}
					editableModel={trackInfoPopup.model}
					album={trackInfoPopup.album}
					onClose={e => {
						setTrackInfoPopup({ open: false });
						if (e?.created) {
							setRefresh(refresh + 1);
						}
					}}
				/>
			)}
		</PageWrapper>
	);
};

export default TracksPage;
