import React, { useCallback, useMemo, useState } from "react";

import { ButtonBase, TextField, withStyles } from "@material-ui/core";
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";

import { useLoyaltyRewards } from "modules/LoyaltyRewards/Data/hooks";
import { SelectedMemberType } from "modules/LoyaltyRewards/Data/types/RewardedMemberType";
import { IncreaseNumberButton } from "modules/Members/View/Containers/Members/InviteActions/style";
import { Dialog } from "shared/Components";
import ConfirmLeavePopup from "shared/Components/ConfirmLeave";
import useConfirmLeavePopup from "shared/Components/ConfirmLeave/hooks/useConfirmLeavePopup";
import { useMembers } from "shared/hooks";
import { Button, Icon, Input, Text } from "shared/ui-kit";

import { ModalBody, OptionWrapper, TagWrapper } from "./style";

const AwardPointsDialog = () => {
	const { setAwardDialog, awardUser, getData: getLoyaltyData } = useLoyaltyRewards();
	const { awardDialog } = getLoyaltyData();

	const { getData: getMembersData } = useMembers();
	const { users } = getMembersData();

	const [points, setPoints] = useState<null | number>(null);
	const [loading, setLoading] = useState(false);

	const StyledAutoComplete = withStyles({
		root: {
			"& .MuiOutlinedInput-root": {
				"&.Mui-focused fieldset": {
					borderColor: "#6173fe",
					borderWidth: 1
				}
			}
		},
		tag: {
			backgroundColor: "#f7f9fc"
		}
	})(Autocomplete);

	const handleDeleteOption = value => {
		setAwardDialog({
			open: true,
			members: awardDialog.members.filter(m => m.value !== value)
		});
	};

	const onSubmit = useCallback(() => {
		setLoading(true);

		awardUser(
			awardDialog.members.map(m => m.value),
			parseInt(`${points}`)
		);
	}, [awardUser, setLoading, awardDialog.members, points]);

	const onClose = () => {
		setLoading(false);
		setAwardDialog({ open: false, members: [] });
	};

	const {
		handleLeavePageConfirmed,
		closeConfirmPopup,
		handleClose,
		getData: getConfirmLeavePopupData
	} = useConfirmLeavePopup({
		onClose,
		open: true
	});

	const { showConfirmPopup } = getConfirmLeavePopupData();

	const Footer = useMemo(
		() => (
			<Button
				onClick={onSubmit}
				disabled={!awardDialog.members.length || !points || points <= 0 || points > 10000 || loading}
			>
				{loading ? "Sending..." : "Send Points"}
			</Button>
		),
		[awardDialog.members.length, points, loading, onSubmit]
	);

	return (
		<>
			<ConfirmLeavePopup
				handleLeavePage={handleLeavePageConfirmed}
				open={showConfirmPopup}
				onClose={closeConfirmPopup}
				popup
			/>
			<Dialog title="Award Points" open={true} footer={Footer} onClose={handleClose}>
				<ModalBody className="award-points">
					<ModalBody.SelectUser>
						<ModalBody.Heading>Select User</ModalBody.Heading>
						<StyledAutoComplete
							multiple
							ListboxProps={{ style: { maxHeight: "180px" }, position: "bottom-start" }}
							renderInput={params => <TextField {...params} label="Select User" variant="outlined" />}
							renderOption={(option: any) => (
								<OptionWrapper>
									{option.profilePicture && <img src={option.profilePicture} alt={option.label} />}
									<Text>{option.label}</Text>
								</OptionWrapper>
							)}
							renderTags={(options, getTagProps) =>
								options.map((option: any, i) => (
									<TagWrapper {...getTagProps({ index: i })} key={i}>
										{option.profilePicture && <img src={option.profilePicture} alt={option.label} />}
										<Text>{option.label}</Text>
										<ButtonBase onClick={() => handleDeleteOption(option.value)}>
											<Icon name="close-circle" group="filled" fill="#8f9bb3" width={14} height={14} />
										</ButtonBase>
									</TagWrapper>
								))
							}
							getOptionLabel={(option: any) => (option ? option?.label || option : "")}
							id="users-selector"
							filterOptions={createFilterOptions({
								matchFrom: "start",
								stringify: (option: any) => option.label
							})}
							includeInputInList
							filterSelectedOptions
							options={users
								.filter(m => !awardDialog.members.some(mr => mr.value === m.personaId))
								.map(m => ({
									label: m.firstName,
									value: m.personaId,
									profilePicture: m.photos[0] ? m.photos[0].profilePicture : null
								}))}
							value={awardDialog.members}
							onChange={(_, newValue) => {
								setAwardDialog({
									open: true,
									members: newValue as SelectedMemberType[]
								});
							}}
						/>
					</ModalBody.SelectUser>
					<ModalBody.ChoosePoints>
						<ModalBody.Heading># of Points</ModalBody.Heading>
						<ModalBody.ChoosePointsInputWrapper>
							<IncreaseNumberButton
								onClick={() => setPoints(points ? points + 1 : 1)}
								buttonTheme="light"
								palette="control"
							>
								<Icon name="chevron-up" group="filled" width={16} height={16} fill="#8f9bb3" />
							</IncreaseNumberButton>
							<Input
								type="number"
								name="totalPoints"
								placeholder="1-10000"
								variant="outlined"
								value={points}
								onChange={e => setPoints(parseInt(e.target.value))}
							/>
							<IncreaseNumberButton
								onClick={() => points && points > 1 && setPoints(points - 1)}
								down
								buttonTheme="light"
								palette="control"
							>
								<Icon name="chevron-up" group="filled" width={16} height={16} fill="#8f9bb3" />
							</IncreaseNumberButton>
						</ModalBody.ChoosePointsInputWrapper>
					</ModalBody.ChoosePoints>
				</ModalBody>
			</Dialog>
		</>
	);
};

export default AwardPointsDialog;
