export const extractContent = s => {
	let span = document.createElement("span");
	span.innerHTML = s;
	return span.textContent || span.innerText;
};

/* eslint-disable */
export const uuidv4 = (s) => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    let r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
};

export const generateLimitedHTML = (baseElement, limit, currentCount) => {
  let newHTMLElement = document.createElement(baseElement.tagName);

  if (!baseElement.children.length) {
    newHTMLElement = baseElement.cloneNode(true);
  } else {
    for (let i = 0; i < baseElement.children.length; i++) {
      let newElement = document.createElement(baseElement.children[i].tagName);
      let newChild = generateLimitedHTML(
        baseElement.children[i],
        limit,
        currentCount
      );
      if (newChild) {
        if (!newChild.children.length) {
          if (currentCount + newChild.innerText.length > limit) {
            newChild.innerText = newChild.innerText.slice(
              0,
              limit - currentCount
            );
          }
          currentCount += newChild.innerText.length;
          newElement = newChild;
        } else {
          for (let i = 0; i < newChild.children.length; i++) {
            newElement.appendChild(newChild.children[i]);
          }
        }
      }

      newHTMLElement.appendChild(newElement);

      if (currentCount >= limit) {
        break;
      }
    }
  }

  return newHTMLElement;
};
