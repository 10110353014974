import React, { FC, useCallback, useMemo, useRef, useState } from "react";

import LocationOnIcon from "@material-ui/icons/LocationOn";
import { Controller, useForm } from "react-hook-form";

import { MemberDialog, SelectInput } from "modules/MemberHome/View/shared";

import { MemberDialogContentWrapper } from "modules/MemberHome/View/shared/style";

import { PlaceholderImage } from "shared/Components";
import { useCommunity, useUser, useVolunteers } from "shared/hooks";
import { PlaceholderImageType, ProfilePhotoSizes, VolunteerSlot } from "shared/types";
import { EventModel } from "shared/types/EventModel";
import { VolunteerMember } from "shared/types/VolunteersTypes";
import { Box, Icon, Text } from "shared/ui-kit";

import { getResizedImage } from "utils/serviceUtils/cdnImages";

import { EventDetails, IconWrapper, InputWrapper, SecondaryText } from "../../styles";

interface SignUpVolunteerProps {
	open;
	volunteerSlotDetail?: VolunteerSlot;
	eventDetail?: EventModel;
	onClose: (userDetail?: VolunteerMember, quantity?: number) => void;
	eventRange?: string;
}

const SignUpVolunteer: FC<SignUpVolunteerProps> = ({ open, onClose, volunteerSlotDetail, eventDetail, eventRange }) => {
	const {
		control,
		handleSubmit,
		formState: { isDirty, errors, isValid },
		watch
	} = useForm({ mode: "onChange" });

	const { slot } = watch(["slot"]);

	const memberDialogContentRef = useRef<null | HTMLDivElement>(null);

	const { communityColors } = useCommunity();
	const { getData: getUserData, getProfilePicture } = useUser();
	const { user } = getUserData();
	const { joinLeaveForVolunteerSlot, getData: getVolunteerData } = useVolunteers();
	const { loading } = getVolunteerData();

	const availableSlots = useMemo(
		() => (volunteerSlotDetail ? volunteerSlotDetail.quantity - volunteerSlotDetail.filledQuantity : 0),
		[volunteerSlotDetail]
	);

	const allSlots = useMemo(
		() =>
			[...Array.from(Array(availableSlots).keys())].map((_, i) => ({
				value: `${i + 1}`,
				label: `${i + 1}`
			})),
		[availableSlots]
	);

	const [slots, setSlots] = useState<{ value: string; label: string }[]>(allSlots);

	const searchFromSlots = useCallback(
		(value: string) => {
			const search: { value: string; label: string }[] = allSlots;
			const filteredSlots = search.filter(slot => Number(slot.value) >= Number(value));
			setSlots(filteredSlots);
		},
		[setSlots, allSlots]
	);

	const onSubmit = useCallback(
		async (data: { slot: { value: number; label: number } }) => {
			if (volunteerSlotDetail) {
				const userDetail = {
					personaId: user?.activeProfile,
					quantity: Number(data.slot.value),
					firstName: user?.profiles[0].firstName,
					lastName: user?.profiles[0].lastName,
					photos: [
						{
							profilePicture: getResizedImage(getProfilePicture(user), ProfilePhotoSizes.size50x50)
						}
					]
				};
				const success = await joinLeaveForVolunteerSlot(
					volunteerSlotDetail._id,
					{
						action: "join",
						quantity: Number(data.slot.value)
					},
					userDetail
				);

				success && onClose(userDetail, Number(data.slot.value));
			}
		},
		[joinLeaveForVolunteerSlot, volunteerSlotDetail, onClose, user, getProfilePicture]
	);

	const customHandleSubmit = useCallback(
		e => {
			e?.preventDefault && e.preventDefault();
			e?.stopPropagation && e.stopPropagation();
			handleSubmit(onSubmit)();
		},
		[handleSubmit, onSubmit]
	);

	return (
		<MemberDialog
			customWidth={512}
			title="Sign up for volunteering"
			open={open}
			onClose={onClose}
			confirmLeave={isDirty}
			modalHeight={700}
			footerPrimary={{
				text: "Sign up",
				disabled: loading || !isDirty || !isValid || !slot || typeof slot === "string",
				loading: loading,
				id: "signUpVolunteer",
				onClick: handleSubmit(onSubmit)
			}}
		>
			<form onSubmit={customHandleSubmit}>
				<MemberDialogContentWrapper className="pv-20" ref={memberDialogContentRef}>
					<Box className="flex">
						<Box className="flex w-4/6">
							<Box className="w-10 h-10 overflow-hidden rounded-md">
								{!!eventDetail?.eventImages?.length ? (
									<img src={eventDetail!.eventImages[0]} alt={eventDetail!.title} className="w-10 h-10" />
								) : (
									<PlaceholderImage width={120} height={60} type={PlaceholderImageType.EVENT_DETAILS} />
								)}
							</Box>
							<Box className="ml-4">
								<Text>
									<span className="font-semibold">{volunteerSlotDetail?.need}</span> on
								</Text>
								<p className="grid">
									<Text className="font-semibold truncate">{eventDetail?.title}</Text>
								</p>
							</Box>
						</Box>
						<Box className="flex flex-col items-end w-2/6">
							<Controller
								name="slot"
								control={control}
								rules={{
									required: "Slot is required",
									max: {
										value: availableSlots,
										message: `Need can't be more than ${availableSlots} letters`
									}
								}}
								render={({ onChange, value }) => (
									<InputWrapper className="w-28" mb={6}>
										<SelectInput
											name="slot"
											onChange={onChange}
											loadSuggestions={searchFromSlots}
											value={value?.label || value || null}
											maxHeight={150}
											options={slots}
											type="number"
											autoCloseWhenNoOption
											numbersOnly
											className="removeArrows"
										/>
									</InputWrapper>
								)}
							/>
							<SecondaryText danger={errors?.slot?.message}>{availableSlots} slots available</SecondaryText>
						</Box>
					</Box>
					{eventRange && (
						<EventDetails.EventInfoRow className="w-full">
							<IconWrapper>
								<Icon name="clock" fill={communityColors.primary} />
							</IconWrapper>
							<Text>{eventRange}</Text>
						</EventDetails.EventInfoRow>
					)}
					<EventDetails.EventInfoRow className="w-full">
						<IconWrapper>
							<LocationOnIcon htmlColor="#6173fe" />
						</IconWrapper>
						{eventDetail?.location?.address ? (
							<Box>
								<Text>{eventDetail!.location!.address}</Text>
								<Text className="secondary">{eventDetail!.location!.address}</Text>
							</Box>
						) : (
							<Text>Online</Text>
						)}
					</EventDetails.EventInfoRow>
					<Box>
						<Text className="font-semibold">Description</Text>
						<Text variant="caption1">{volunteerSlotDetail?.description}</Text>
					</Box>
				</MemberDialogContentWrapper>
			</form>
		</MemberDialog>
	);
};

export default SignUpVolunteer;
