import React from "react";

import config from "config/appConfig";
import { Redirect, Route, Switch } from "react-router";

import { EnvApplicationNames } from "types";
import IApp from "types/IApp";

import TrackPlayer from "modules/MemberHome/View/Components/TrackPlayer";

import { NotVerified } from "shared/Components";
import SmartScroll from "shared/Components/SmartScroll";
import { NotFound } from "shared/Errorpages";
import SecureRoute from "shared/services/AuthDecorator/SecureRoute";
import BaseLayout from "shared/templates/BaseLayout";

import OnboardingLayout from "shared/templates/OnboardingLayout";

import wrapByLayout from "utils/wrapByLayout";

import {
	AlbumPage,
	AlbumsPage,
	AllCollectionsPage,
	AllGroupsPage,
	BoardPage,
	CategoriesPage,
	CollectionPage,
	CollectionsPage,
	ConnectionsPage,
	ContributorsPage,
	EventPage,
	EventsPage,
	FilePage,
	FilesPage,
	FillProfileDatePage,
	FillProfileMaritalStatusPage,
	FillProfileMotherhoodPage,
	FillProfilePage,
	FillProfileSelfiePage,
	FillProfileWelcomePage,
	FundraiserPage,
	FundraisersPage,
	FuturePost,
	FuturePosts,
	GroupPage,
	GroupsPage,
	HashtagPage,
	HashtagsPage,
	HelpPage,
	HomePage,
	LiveConvoInitiatePage,
	LiveConvoListPage,
	LiveConvoMainPage,
	MemberProfile,
	MessagingPage,
	MyProfilePage,
	NotificationsPage,
	PlacesPage,
	PodcastPage,
	PodcastsPage,
	PostPage,
	RequestsPage,
	RewardsPage,
	SettingsPage,
	StartCollectionPage,
	TrackPage,
	TracksPage,
	VideoPage,
	VideosPage
} from "./Pages";

import { routesGenerator } from "./constants";

const isWhitelabelAppMode =
	config.GLOBAL_CONSTANTS.ENV_APPLICATION_NAME === EnvApplicationNames.Akina ||
	config.GLOBAL_CONSTANTS.ENV_APPLICATION_NAME === EnvApplicationNames.Relias ||
	config.GLOBAL_CONSTANTS.ENV_APPLICATION_NAME === EnvApplicationNames.Bitcoin;

const routes = routesGenerator(":communityName", isWhitelabelAppMode);
class MemberApp implements IApp {
	getRoutes = () => [
		<Route path={routes?.member.getPath()} key="member-user-route">
			<SmartScroll>
				<Switch>
					<Redirect exact from="/" to="/member/home" />
					{this.getMemberOnboardingRoutes()}
					{this.getLiveConversationRoutes()}
					<BaseLayout isMember>
						<NotVerified isMember />
						{this.getSecuredRoutes()}
						<TrackPlayer />
					</BaseLayout>
				</Switch>
			</SmartScroll>
		</Route>
	];

	getMemberOnboardingRoutes = () => {
		const routeList = [
			<Route
				exact
				key={routes?.member.fillProfile.getElementKey()}
				path={routes?.member.fillProfile.getPath()}
				component={wrapByLayout(OnboardingLayout)(FillProfilePage)}
			/>,
			<Route
				exact
				key={routes?.member.fillProfile.date.getElementKey()}
				path={routes?.member.fillProfile.date.getPath()}
				component={wrapByLayout(OnboardingLayout)(FillProfileDatePage)}
			/>,
			<Route
				exact
				key={routes?.member.fillProfile.marital.getElementKey()}
				path={routes?.member.fillProfile.marital.getPath()}
				component={wrapByLayout(OnboardingLayout)(FillProfileMaritalStatusPage)}
			/>,
			<Route
				exact
				key={routes?.member.fillProfile.motherhood.getElementKey()}
				path={routes?.member.fillProfile.motherhood.getPath()}
				component={wrapByLayout(OnboardingLayout)(FillProfileMotherhoodPage)}
			/>,
			<Route
				exact
				key={routes?.member.fillProfile.selfie.getElementKey()}
				path={routes?.member.fillProfile.selfie.getPath()}
				component={wrapByLayout(OnboardingLayout)(FillProfileSelfiePage)}
			/>,
			<Route
				exact
				key={routes?.member.fillProfile.welcome.getElementKey()}
				path={routes?.member.fillProfile.welcome.getPath()}
				component={wrapByLayout(OnboardingLayout)(FillProfileWelcomePage)}
			/>
		];
		return routeList;
	};

	getLiveConversationRoutes = () => {
		const routeList = [
			<Route
				exact
				key={`${routes?.member.liveconversation.initiate.getElementKey()}/id`}
				path={`${routes?.member.liveconversation.initiate.getPath()}/:id`}
				component={LiveConvoInitiatePage}
			/>,
			<Route
				exact
				key={`${routes?.member.liveconversation.main.getElementKey()}/id`}
				path={`${routes?.member.liveconversation.main.getPath()}/:id`}
				component={LiveConvoMainPage}
			/>
		];
		return routeList;
	};

	getSecuredRoutes = () => {
		const routeList = [
			<Route
				exact
				key={routes?.member.categories.getElementKey()}
				path={routes?.member.categories.getPath()}
				component={CategoriesPage}
			/>,
			<Route
				exact
				key={routes?.member.connections.getElementKey()}
				path={routes?.member.connections.getPath()}
				component={ConnectionsPage}
			/>,
			<Route
				exact
				key={routes?.member.contributors.getElementKey()}
				path={routes?.member.contributors.getPath()}
				component={ContributorsPage}
			/>,
			<Route
				exact
				key={routes?.member.events.getElementKey()}
				path={routes?.member.events.getPath()}
				component={EventsPage}
			/>,
			<Route
				exact
				key={`${routes?.member.event.getElementKey()}/id`}
				path={`${routes?.member.event.getPath()}/:id`}
				component={EventPage}
			/>,
			<Route
				exact
				key={routes?.member.files.getElementKey()}
				path={routes?.member.files.getPath()}
				component={FilesPage}
			/>,
			<Route
				exact
				key={`${routes?.member.file.getElementKey()}/id`}
				path={`${routes?.member.file.getPath()}/:id`}
				component={FilePage}
			/>,
			<Route
				exact
				key={routes?.member.futureposts.getElementKey()}
				path={routes?.member.futureposts.getPath()}
				component={FuturePosts}
			/>,
			<Route
				exact
				key={`${routes?.member.futurepost.getElementKey()}/id`}
				path={`${routes?.member.futurepost.getPath()}/:id`}
				component={FuturePost}
			/>,
			<Route
				exact
				key={routes?.member.groups.getElementKey()}
				path={routes?.member.groups.getPath()}
				component={GroupsPage}
			/>,
			<Route
				exact
				key={routes?.member.messaging.getElementKey()}
				path={routes?.member.messaging.getPath()}
				component={MessagingPage}
			/>,
			<Route
				exact
				key={routes?.member.hashtags.getElementKey()}
				path={routes?.member.hashtags.getPath()}
				component={HashtagsPage}
			/>,
			<Route
				exact
				key={routes?.member.help.getElementKey()}
				path={routes?.member.help.getPath()}
				component={HelpPage}
			/>,
			<Route
				exact
				key={routes?.member.home.getElementKey()}
				path={routes?.member.home.getPath()}
				component={HomePage}
			/>,
			<Route
				exact
				key={`${routes?.member.album.getElementKey()}/id`}
				path={`${routes?.member.album.getPath()}/:id`}
				component={AlbumPage}
			/>,
			<Route
				exact
				key={`${routes?.member.group.getElementKey()}/id`}
				path={`${routes?.member.group.getPath()}/:id`}
				component={GroupPage}
			/>,
			<Route
				exact
				key={`${routes?.member.hashtag.getElementKey()}/id`}
				path={`${routes?.member.hashtag.getPath()}/:id`}
				component={HashtagPage}
			/>,
			<Route
				exact
				key={`${routes?.member.board.getElementKey()}/id`}
				path={`${routes?.member.board.getPath()}/:id`}
				component={BoardPage}
			/>,
			<Route
				exact
				key={routes?.member.places.getElementKey()}
				path={routes?.member.places.getPath()}
				component={PlacesPage}
			/>,
			<Route
				exact
				key={routes?.member.rewards.getElementKey()}
				path={routes?.member.rewards.getPath()}
				component={RewardsPage}
			/>,
			<Route
				exact
				key={routes?.member.settings.getElementKey()}
				path={routes?.member.settings.getPath()}
				component={SettingsPage}
			/>,
			<Route
				exact
				key={routes?.member.tracks.getElementKey()}
				path={routes?.member.tracks.getPath()}
				component={TracksPage}
			/>,
			<Route
				exact
				key={`${routes?.member.track.getElementKey()}/id`}
				path={`${routes?.member.track.getPath()}/:id`}
				component={TrackPage}
			/>,
			<Route
				exact
				key={routes?.member.albums.getElementKey()}
				path={routes?.member.albums.getPath()}
				component={AlbumsPage}
			/>,
			<Route
				exact
				key={routes?.member.fundraisers.getElementKey()}
				path={routes?.member.fundraisers.getPath()}
				component={FundraisersPage}
			/>,
			<Route
				exact
				key={routes?.member.videos.getElementKey()}
				path={routes?.member.videos.getPath()}
				component={VideosPage}
			/>,
			<Route
				exact
				key={routes?.member.podcasts.getElementKey()}
				path={routes?.member.podcasts.getPath()}
				component={PodcastsPage}
			/>,
			<Route
				exact
				key={`${routes?.member.podcasts.getElementKey()}/id`}
				path={`${routes?.member.podcasts.getPath()}/:id`}
				component={PodcastPage}
			/>,
			<Route
				exact
				key={`${routes?.member.video.getElementKey()}/id`}
				path={`${routes?.member.video.getPath()}/:id`}
				component={VideoPage}
			/>,
			<Route
				exact
				key={routes?.member.collections.getElementKey()}
				path={routes?.member.collections.getPath()}
				component={CollectionsPage}
			/>,
			<Route
				exact
				key={`${routes?.member.collections.getElementKey()}/all`}
				path={`${routes?.member.collections.getPath()}/all`}
				component={AllCollectionsPage}
			/>,
			<Route
				exact
				key={`${routes?.member.collection.getElementKey()}/id`}
				path={`${routes?.member.collection.getPath()}/:id`}
				component={CollectionPage}
			/>,
			<Route
				exact
				key={`${routes?.member.groups.getElementKey()}/all`}
				path={`${routes?.member.groups.getPath()}/all`}
				component={AllGroupsPage}
			/>,
			<Route
				exact
				key={`${routes?.member.collection.getElementKey()}/id/start`}
				path={`${routes?.member.collection.getPath()}/:id/start`}
				component={StartCollectionPage}
			/>,

			<Route
				exact
				key={`${routes?.member.groups.getElementKey()}/all`}
				path={`${routes?.member.groups.getPath()}/all`}
				component={AllGroupsPage}
			/>,
			<Route
				exact
				key={routes?.member.profile.getElementKey()}
				path={routes?.member.profile.getPath()}
				component={MyProfilePage}
			/>,
			<Route
				exact
				key={`${routes?.member.profile.getElementKey()}/:id`}
				path={`${routes?.member.profile.getPath()}/:id`}
				component={MemberProfile}
			/>,
			<Route
				exact
				key={routes?.member.notifications.getElementKey()}
				path={routes?.member.notifications.getPath()}
				component={NotificationsPage}
			/>,
			<Route
				exact
				key={routes?.member.liveconversation.list.getElementKey()}
				path={routes?.member.liveconversation.list.getPath()}
				component={LiveConvoListPage}
			/>,
			<Route
				exact
				key={`${routes?.member.post.getElementKey()}/:id`}
				path={`${routes?.member.post.getPath()}/:id`}
				component={PostPage}
			/>,
			<Route
				exact
				key={`${routes?.member.fundraiser.getElementKey()}/id`}
				path={`${routes?.member.fundraiser.getPath()}/:id`}
				component={FundraiserPage}
			/>,
			<Route
				exact
				key={routes.member.requests.getElementKey()}
				path={routes.member.requests.getPath()}
				component={RequestsPage}
			/>,
			<Route path="*" key={"NotFound"} component={NotFound} />
		];

		return <SecureRoute routes={routeList} />;
	};
}

export default MemberApp;
