import styled, { css } from "styled-components";

import { Box, Tab as DefaultTab, Tabs } from "shared/ui-kit";

export const ManageDialogFormWrapper = styled.div`
	touch-action: none;
	${({ customStyle }) =>
		customStyle &&
		css`
			${customStyle}
		`}

	${({ maxHeight, minHeight }) =>
		css`
			max-height: ${maxHeight ? maxHeight : 480}px;
			min-height: ${minHeight ? minHeight : 0}px;
		`}
`;

// DialogFormWrapper.defaultProps = { component: "form" };

export const RelativeWrapper = styled(Box)`
	position: relative;
	&.row {
		display: flex;
	}
	&.center {
		align-items: center;
	}
`;

export const GeneralWrapper = styled(Box)`
	width: 100%;
	&.relative {
		position: relative;
	}
	&.row {
		display: flex;
	}
	&.center {
		align-items: center;
	}
	&.justify-end {
		justify-content: flex-end;
	}
	&.children-space-4 {
		column-gap: 4px;
	}
	&.reactions-positioning {
		> div {
			border: 2px solid #fff;
			border-radius: 99%;
			z-index: 1;
		}
		${({ isCurrentUser }) =>
			isCurrentUser
				? css`
						div:not(:first-child) {
							margin-left: -10px;
						}
				  `
				: css`
						justify-content: flex-end;
						div:not(:last-child) {
							margin-right: -10px;
						}
				  `}
	}
`;

export const ReactionRow = styled(Box)`
	display: flex;
	align-items: center;
	margin-bottom: 8px;
	flex-direction: row-reverse;
	justify-content: flex-end;
	p {
		margin-left: 4px;
		font-size: 13px;
		font-weight: 600;
		color: #8f9bb3;
	}
	&.other-user {
		flex-direction: row;
		p {
			margin-left: 0;
			margin-right: 4px;
		}
	}
`;

export const ReactionWrapper = styled(Box)`
	margin-left: -4px;
	background: white;
	border-radius: 99%;
	height: 18px;
	padding: 1px;

	&.other-user {
		margin-left: 0;
		margin-right: -6px;
	}
	svg {
		width: 16px;
		height: 16px;
		fill: ${({ reactionColor }) => reactionColor || "#ffd16d"};
		g {
			width: 100%;
			height: 100%;
		}
	}
`;

export const TabContainer = styled(Tabs)`
	font-size: 15px;
	font-weight: 600;
	line-height: 1.6;
	letter-spacing: normal;
	color: #6173fe;
	border-bottom: 1px solid #e4e9f2;
	position: relative;
	margin-bottom: 20px;
	width: 100%;

	button {
		position: relative;
		text-align: center;

		${props => props.theme.breakpoints.down("sm")} {
			width: 50%;
		}

		&:before {
			content: "";
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 4px;
			background-color: #6173fe;
			border-top-left-radius: 4px;
			border-top-right-radius: 4px;
		}

		&:first-child {
			&:before {
				display: ${props => (props.activeTab === 0 ? "block" : "none")};
			}
		}

		&:nth-child(2) {
			&:before {
				display: ${props => (props.activeTab === 1 ? "block" : "none")};
			}
		}

		&:nth-child(3) {
			&:before {
				display: ${props => (props.activeTab === 2 ? "block" : "none")};
			}
		}
	}

	@media (min-width: 600px) {
		button {
			min-width: 0;
		}
	}

	.hidden {
		visibility: hidden;
		padding: 0;
		max-width: 0;
	}
`;

export const Tab = styled(DefaultTab)`
	.MuiTab-wrapper {
		text-transform: initial !important;
		font-weight: 600;
	}
`;

export const AdWrapper = styled(Box)`
	iframe {
		width: 100%;
	}
	&.see_all--wrapper iframe {
		height: 320px;
	}
	&.block_ad--wrapper {
		box-shadow: 0 4px 12px 0 rgb(0 0 0 / 10%);
		border-radius: 12px;
	}
	&.post_ad--wrapper iframe {
		height: auto;
		min-height: 375px;

		${props => props.theme.breakpoints.up("sm")} {
			min-height: 500px;
		}
	}
	&.story_ad--wrapper iframe {
		opacity: ${({ isActive }) => (isActive ? 1 : 0.5)};
		cursor: pointer;
	}

	&.matches_ad--wrapper {
		border-radius: 12px;
		overflow: hidden;
	}
`;

export const BodyWrapper = styled.div`
	padding: 16px 40px 23px;
	height: 100%;
	overflow: hidden;

	${({ isDelete }) =>
		isDelete &&
		css`
			padding: 0;
		`}

	${props => props.theme.breakpoints.down("sm")} {
		padding: 16px;

		${({ isDelete }) =>
			isDelete &&
			css`
				padding: 0;
			`}
	}

	${({ bodyCustomStyles }) => bodyCustomStyles}
`;

export const ScrollbarBody = styled.div<{ bodyCustomStyles: string }>`
	padding: 16px 40px 23px;
	touch-action: none;

	${({ bodyCustomStyles }) => bodyCustomStyles}

	${({ isDelete }) =>
		isDelete &&
		css`
			padding: 0;
		`}

	${props => props.theme.breakpoints.down("sm")} {
		padding: 16px;

		${({ isDelete }) =>
			isDelete &&
			css`
				padding: 0;
			`}
	}
`;
