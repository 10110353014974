import React, { FC, useCallback } from "react";

import { useHistory } from "react-router";

import { ForumPersonaModel } from "types";

import { useMemberRoutes } from "shared/hooks";

import { MemberDialog } from "../shared";
import { ProfileListingDialogWrapper } from "../shared/style";

interface Props {
	title: string;
	handleClose: () => void;
	list: ForumPersonaModel[];
}

const UsersListDialog: FC<Props> = ({ title, handleClose, list }) => {
	const history = useHistory();
	const { getMemberRoutesData } = useMemberRoutes();
	const { routes } = getMemberRoutesData();

	const handleGoToUser = useCallback(
		(pId: number) => {
			handleClose();
			history.push(`${routes?.member.profile.getPath()}/${pId}`);
		},
		[handleClose, history, routes?.member.profile]
	);

	return (
		<MemberDialog
			title={title}
			open={true}
			onClose={handleClose}
			footerPrimary={{
				text: "Close",
				onClick: handleClose
			}}
		>
			<ProfileListingDialogWrapper>
				{list.map(con => (
					<ProfileListingDialogWrapper.UserOption
						key={con.persona.personaId}
						onClick={() => handleGoToUser(con.persona.personaId)}
					>
						{!!con.persona.photos.length && (
							<img src={con.persona.photos[0].profilePicture} alt={con.persona.firstName} />
						)}
						{con.persona.firstName} {con.persona.lastName}
					</ProfileListingDialogWrapper.UserOption>
				))}
			</ProfileListingDialogWrapper>
		</MemberDialog>
	);
};

export default UsersListDialog;
