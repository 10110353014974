import React, { useMemo } from "react";

import styled from "styled-components";

import { useCommunity } from "shared/hooks";
import { PlaceholderImageType } from "shared/types";
import { Avatar, Box, Text } from "shared/ui-kit";
import { customBreakpoints } from "theme/default";

import LogoSkeleton from "./LogoSkeleton";

import { PlaceholderImage } from "../index";

const Wrapper = styled(Box)`
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	overflow: hidden;
`;

const InfoWrapper = styled(Box)`
	display: flex;
	align-items: center;
`;

const IconWrapper = styled(Box)`
	width: 30px;
	height: 30px;

	margin-right: 12px;

	border-radius: 4px;

	overflow: hidden;

	${({ theme }) => theme.breakpoints.down(customBreakpoints.tabletVerticalMax)} {
		margin-right: 0;
		width: 30px;
		height: 30px;
	}
`;

const Info = styled(Box)`
	display: block;
`;

const Title = styled(Text)`
	font-size: 13px;
	font-weight: 600;
	line-height: 1.85;
	color: #222b45;
	margin: 0;

	${({ theme }) => theme.breakpoints.down("sm")} {
		display: none;
	}
`;

const Logo = styled(Avatar)`
	width: 30px;
	height: 30px;

	${({ theme }) => theme.breakpoints.down(customBreakpoints.tabletVerticalMax)} {
		width: 30px;
		height: 30px;
	}
`;

const CommunityLogo: React.FC = () => {
	const { getData: getCommunityData } = useCommunity();
	const { workspace, img } = getCommunityData();

	const name = useMemo(() => workspace?.meta?.displayName || "", [workspace]);

	return workspace ? (
		<Wrapper>
			<InfoWrapper>
				<IconWrapper>
					{workspace?.meta?.img || img ? (
						<Logo title={name} image={workspace?.meta?.img || img} />
					) : (
						<PlaceholderImage
							type={PlaceholderImageType.WORKSPACE_SEARCH}
							width={32}
							height={32}
							viewBox={"0 0 400 400"}
						/>
					)}
				</IconWrapper>
				<Info>
					<Title>{name}</Title>
				</Info>
			</InfoWrapper>
		</Wrapper>
	) : (
		<LogoSkeleton />
	);
};

export default CommunityLogo;
