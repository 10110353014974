import React, { useMemo } from "react";

import { Icon } from "shared/ui-kit";

import { ChatTextContainer, MessageText, ReplyChatContainer, StyledCloseIcon, StyledText } from "./styles";

import { RenderActionType } from "../ChatBubble";

const ChatReply = ({ replyMsg, currentUserId, onClose, isEdit }) => {
	const renderIcon = useMemo(() => {
		switch (replyMsg.type) {
			case RenderActionType.VIDEO:
				return <Icon name="video" group="filled" />;
			case RenderActionType.FILE:
				return <Icon name="file" group="filled" />;
			case RenderActionType.AUDIO:
			case RenderActionType.TRACK:
				return <Icon name="play" group="filled" />;
			case RenderActionType.IMAGE:
				return <Icon name="image" group="filled" />;
			case RenderActionType.GROUP:
				return <Icon name="user-friend" group="filled" />;
			case RenderActionType.EVENT:
				return <Icon name="calendar" group="filled" />;
			default:
				return null;
		}
	}, [replyMsg.type]);

	return (
		<ChatTextContainer>
			<StyledCloseIcon htmlColor="#8f9bb3" onClick={onClose} />
			<ReplyChatContainer isEdit={!!isEdit}>
				<StyledText>
					{!!isEdit ? "Edit Message" : replyMsg.user.id === currentUserId ? "You" : replyMsg.user.name}
				</StyledText>
				{isEdit ? (
					<MessageText>{replyMsg.text}</MessageText>
				) : (
					<MessageText>
						{renderIcon} {replyMsg.info.text}
					</MessageText>
				)}
			</ReplyChatContainer>
		</ChatTextContainer>
	);
};

export default ChatReply;
