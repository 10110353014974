import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";

import { Box } from "@material-ui/core";

import { FileDrop } from "react-file-drop";

import { ReactComponent as IconLandscape } from "assets/icons/icon-landscape.svg";
import { MenuDots, MenuDotsOption } from "shared/Components";
import { Icon, Loader, Text } from "shared/ui-kit";

import { Container, ImageUploaderWrapper, UploaderError } from "./style";

interface CircularUploaderMethods {
	clearValue: () => void;
	initiateSelect: () => void;
}

interface CircularUploaderProps {
	errorText?: string;
	onChange: (files: FileList) => void;
	preview?: string;
	uploading?: boolean;
	options?: MenuDotsOption[];
	size?: number;
	simpleMode?: boolean;
}

const CircularUploader = forwardRef<CircularUploaderMethods, CircularUploaderProps>(
	({ errorText, onChange, preview, uploading, options, size, simpleMode }, ref) => {
		const [previewURL, setPreviewURL] = useState<string>();
		const [dragOver, setDragOver] = useState(false);
		const [frameEntered, setFrameEntered] = useState(false);

		const inputRef = useRef<HTMLInputElement>(null);

		useImperativeHandle(ref, () => ({
			clearValue: () => {
				setPreviewURL(undefined);
			},
			initiateSelect: () => {
				handleOpenFileChoice();
			}
		}));

		useEffect(() => {
			setPreviewURL(preview);
		}, [preview]);

		const handleOpenFileChoice = useCallback(() => {
			inputRef.current?.click();
		}, []);

		const handleImagePicked = useCallback(
			(files: FileList | null) => {
				if (!files) return;

				if (!files[0].type.startsWith("image/")) return;

				const previewURL = URL.createObjectURL(files[0]);
				setPreviewURL(previewURL);

				onChange(files);
			},
			[onChange]
		);

		// const handleClosePreview = useCallback(() => {
		// 	setPreviewURL(null);
		// 	if (inputRef.current) {
		// 		inputRef.current.value = "";
		// 		setShowOptions(false);
		// 	}
		// }, []);

		const handleOnDrop = useCallback(
			files => {
				setDragOver(false);
				handleImagePicked(files);
			},
			[handleImagePicked]
		);

		const EditBlock = useMemo(
			() => (
				<ImageUploaderWrapper.EditButton circular size={size}>
					<Icon width={16} height={16} name="pencil" group="filled" fill="#ffffff" />
				</ImageUploaderWrapper.EditButton>
			),
			[size]
		);

		const ImageUploaderBlock = useMemo(
			() => (
				<ImageUploaderWrapper
					circular
					size={size}
					dragOver={dragOver}
					onClick={() => (!options?.length ? handleOpenFileChoice() : undefined)}
					simple={simpleMode}
				>
					{previewURL ? (
						<ImageUploaderWrapper.Image className="cover" src={previewURL} />
					) : (
						<MenuDots options={options || []} memberView invisibleFullWidth />
					)}
					{!previewURL && (
						<Box>
							{simpleMode ? (
								<Icon width={40} height={40} name="camera" group="filled" fill="#8f9cb3" />
							) : (
								<IconLandscape />
							)}
							{frameEntered && <Text>Drop photo here!</Text>}
						</Box>
					)}
					<input
						type="file"
						hidden
						ref={inputRef}
						accept="image/*"
						onChange={e => {
							if (!!e.target.files?.length) handleImagePicked(e.target.files);
						}}
					/>
				</ImageUploaderWrapper>
			),
			[size, dragOver, simpleMode, options, previewURL, frameEntered, handleOpenFileChoice, handleImagePicked]
		);

		return (
			<FileDrop
				onDragOver={() => setDragOver(true)}
				onDragLeave={() => setDragOver(false)}
				onFrameDragEnter={() => setFrameEntered(true)}
				onDrop={handleOnDrop}
			>
				<Container circular>
					{ImageUploaderBlock}
					{previewURL && (
						<ImageUploaderWrapper.EditButtonWrapper circular>
							{uploading ? (
								<Loader show size="24px" variant="indeterminate" color="secondary" />
							) : (
								<MenuDots options={options || []} memberView customAnchor={EditBlock} />
							)}
							{/* DO NOT DELETE, WE'LL NEED LATER AFTER PROFILE PICTURE IS NOT REQUIRED ANYMORE. */}
							{/* {showOptions && (
							<ClickAwayListener onClickAway={() => setShowOptions(false)}>
								<ImageUploaderWrapper.ButtonOptions circular>
									<ImageUploaderWrapper.Option onClick={() => inputRef.current?.click()}>
										<IconCamera width={20} height={20} /> Change Photo
									</ImageUploaderWrapper.Option>
									<ImageUploaderWrapper.Option onClick={handleClosePreview}>
										<IconBin width={20} height={20} /> Delete Photo
									</ImageUploaderWrapper.Option>
								</ImageUploaderWrapper.ButtonOptions>
							</ClickAwayListener>
						)} */}
						</ImageUploaderWrapper.EditButtonWrapper>
					)}
					{errorText && <UploaderError>{errorText}</UploaderError>}
				</Container>
			</FileDrop>
		);
	}
);

export default CircularUploader;
