import React, { useCallback, useEffect, useMemo, useState } from "react";

import { DateTime } from "luxon";

import { PageTemplate } from "modules/Manage/View/Components";
import { useCategories } from "modules/Marketplace/Data";
import { MenuDots, TableWrapper } from "shared/Components";
import { TableColumnLoaderType } from "shared/Components/NewTable/Components";
import { Cell } from "shared/Components/NewTable/style";
import { useDebounce, useFeature } from "shared/hooks";

import { formatCount } from "utils/serviceUtils/helpers";

import CreateCategoryDialog from "../../Components/ManageCategories/CreateCategoryDialog";
import DeleteCategoryDialog from "../../Components/ManageCategories/DeleteCategoryDialog";

const Table = React.lazy(() => import("shared/Components/NewTable"));

const ManageCategories = () => {
	const [searchText, setSearchText] = useState("");
	const { isFeatureEnabled } = useFeature();

	const {
		getCategories,
		setCategoriesPerPage,
		setPage,
		setCreateUpdateCategoryOpen,
		setDeleteDialog,
		getCategoriesCount,
		getData: getCategoriesData
	} = useCategories();
	const { categories, categoriesPerPage, page, categoriesCount, isLoading } = getCategoriesData();

	const debouncedSearchText = useDebounce(searchText, 400);

	useEffect(() => {
		getCategories({ limit: categoriesPerPage, offset: page, keyword: debouncedSearchText });
	}, [categoriesPerPage, debouncedSearchText, getCategories, page]);

	useEffect(() => {
		getCategoriesCount();
	}, [getCategoriesCount]);

	const handleSearch = useCallback((keyword: string) => {
		if (keyword === "" || keyword.length > 2) {
			setSearchText(keyword);
		}
	}, []);

	const getOptions = useCallback(
		rowData => [
			{
				name: "Edit",
				onClick: () =>
					setCreateUpdateCategoryOpen({
						status: true,
						editObject: rowData
					}),
				value: 1,
				comp: "",
				id: "categoryEdit"
			},
			{
				name: "Delete",
				onClick: () => {
					setDeleteDialog({
						data: rowData
					});
				},
				value: 2,
				comp: "",
				id: "categoryDelete"
			}
		],
		[setCreateUpdateCategoryOpen, setDeleteDialog]
	);

	const tableColumns = useMemo(() => {
		let columns: any[] = [
			{
				minWidth: 240,
				alignment: "left",
				label: <Cell.HeaderText>{formatCount(categoriesCount, "Category", "Categories")}</Cell.HeaderText>,
				Cell: ({ rowData: { name, photo } }: { rowData: any }) => (
					<Cell.Wrapper>
						<Cell.ImageWrapper>
							<Cell.Image>{photo ? <img src={photo} alt={name} /> : ""}</Cell.Image>
						</Cell.ImageWrapper>
						<Cell.Wrapper className="column with-image">
							<Cell.Text>{name}</Cell.Text>
						</Cell.Wrapper>
					</Cell.Wrapper>
				),
				loaderTemplate: TableColumnLoaderType.imageWthOneTextRow,
				dataKey: "_id"
			}
		];

		if (isFeatureEnabled("enableCollections")) {
			columns.push({
				width: 140,
				alignment: "left",
				label: <Cell.HeaderText>Collections</Cell.HeaderText>,
				Cell: ({ rowData: { seriesCount } }: { rowData: any }) => (
					<Cell.Wrapper>
						<Cell.Text>{seriesCount || 0}</Cell.Text>
					</Cell.Wrapper>
				),
				dataKey: "collections"
			});
		}

		columns = [
			...columns,
			{
				width: 140,
				alignment: "left",
				label: <Cell.HeaderText>Companies</Cell.HeaderText>,
				Cell: ({ rowData: { totalCompanies } }: { rowData: any }) => (
					<Cell.Wrapper>
						<Cell.Text>{totalCompanies || 0}</Cell.Text>
					</Cell.Wrapper>
				),
				dataKey: "companies"
			},
			{
				alignment: "left",
				width: 140,
				label: <Cell.HeaderText>Created Date</Cell.HeaderText>,
				Cell: ({ rowData: { createdAt } }: { rowData: any }) => (
					<Cell.Wrapper className="column">
						<Cell.Text>{DateTime.fromISO(createdAt).toFormat("MMM d, yyyy")}</Cell.Text>
						<Cell.Text className="light">{DateTime.fromISO(createdAt).toFormat("t")}</Cell.Text>
					</Cell.Wrapper>
				),
				loaderTemplate: TableColumnLoaderType.twoTextRows,
				dataKey: "createdAt"
			},
			{
				alignment: "right",
				label: "",
				width: 100,
				Cell: ({ rowData, rowIndex }) => (
					<MenuDots
						options={getOptions(rowData)}
						vertical
						removeBg
						removeshadow
						removeSideMargin
						menuId={`moreAction${rowIndex + 1}`}
					/>
				),
				loaderTemplate: TableColumnLoaderType.menuDots,
				dataKey: "menu"
			}
		];

		return columns;
	}, [categoriesCount, getOptions, isFeatureEnabled]);

	const handleChangePage = useCallback(
		(newPage: number) => {
			setPage(newPage);
		},
		[setPage]
	);

	const handleChangeRowsPerPage = useCallback(
		(newPageSize: number) => {
			setCategoriesPerPage(newPageSize);
			setPage(1);
		},
		[setCategoriesPerPage, setPage]
	);

	const handleOpenDialog = () =>
		setCreateUpdateCategoryOpen({
			status: true
		});

	const TableBlock = useMemo(
		() => (
			<TableWrapper sizes={{ horizontalTablet: 900 }}>
				<Table
					columns={tableColumns}
					data={categories || []}
					loading={isLoading}
					paginated
					totalDataCount={categoriesCount || 20}
					page={page}
					pageSize={categoriesPerPage}
					onChangePage={handleChangePage}
					onChangePageSize={handleChangeRowsPerPage}
				/>
			</TableWrapper>
		),
		[
			tableColumns,
			categories,
			isLoading,
			categoriesCount,
			categoriesPerPage,
			page,
			handleChangePage,
			handleChangeRowsPerPage
		]
	);

	return (
		<>
			<PageTemplate
				title={"Categories"}
				isLoading={isLoading}
				isNoData={!categories.length}
				emptyText={"You don’t have any Categories in your community yet."}
				searchPlaceholder={"Search Categories"}
				onSearchUpdate={handleSearch}
				actionText={"Create Category"}
				onCreateClick={handleOpenDialog}
				actionId={"createCategory"}
				searchId={"searchCategory"}
			>
				{TableBlock}
			</PageTemplate>
			<DeleteCategoryDialog
				debouncedSearchText={debouncedSearchText}
				onDelete={() => {
					getCategories({ limit: categoriesPerPage, offset: page, keyword: debouncedSearchText });
				}}
			/>
			<CreateCategoryDialog
				onCreate={() => {
					getCategories({ limit: categoriesPerPage, offset: page, keyword: debouncedSearchText });
				}}
			/>
		</>
	);
};

export default ManageCategories;
