import React, { useEffect, useState } from "react";

import { useLocation, useParams } from "react-router";

import { view } from "modules/Fundraiser";
import { useFundraisers, useMemberRoutes } from "shared/hooks";

const FundraiserPage = () => {
	const { id } = useParams() as { id: string };
	const { search } = useLocation();
	// const history = useHistory();
	const { generateRoutes, getMemberRoutesData } = useMemberRoutes();
	const { routes, workspace: generatedRoutesWorkspace } = getMemberRoutesData();

	const { openContact, resetStore } = useFundraisers();

	const query = new URLSearchParams(search);
	const contactId = query.get("contactId") || undefined;
	const workspaceQuery = query.get("workspace");

	const [cleared, setCleared] = useState(false);
	// const workspaceId = query.get("workspaceId");

	// const { getData: getCommunityData } = useCommunity();
	// const { workspace } = getCommunityData();

	useEffect(() => {
		resetStore();
		setCleared(true);
	}, [resetStore]);

	useEffect(() => {
		if (workspaceQuery && (!routes || generatedRoutesWorkspace !== workspaceQuery)) {
			generateRoutes(workspaceQuery);
		}
	}, [generateRoutes, generatedRoutesWorkspace, routes, workspaceQuery]);

	useEffect(() => {
		if (cleared && workspaceQuery && contactId) {
			openContact(contactId, workspaceQuery);
		}
	}, [contactId, openContact, workspaceQuery, cleared]);

	// Commented out it because we decided to use the same flow for signed in and non signed in users
	// useEffect(() => {
	// 	if (workspaceId && workspace && workspaceId === workspace._id && !!routes) {
	// 		history.push(`${routes?.member.fundraiser.getPath()}/${id}${contactId ? `?contactId=${contactId}` : ""}`);
	// 	}
	// }, [contactId, history, id, routes?.member.fundraiser, workspace, workspaceId, routes]);

	return cleared ? <view.Fundraiser workspace={workspaceQuery} contactId={contactId} id={id} /> : null;
};

export default FundraiserPage;
