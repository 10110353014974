import React from "react";

import { useParams } from "react-router-dom";

import { view } from "modules/MemberHome";
import { FeatureFlags } from "shared/Components/MemberNavPanel/constants";
import useCheckIfEnabled from "shared/hooks/useCheckIfFlagEnabled";

const FuturePostPage = () => {
	useCheckIfEnabled(FeatureFlags.forum);
	const { id } = useParams() as { id: string };

	return <view.FuturePost id={id} />;
};

export default FuturePostPage;
