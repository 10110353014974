import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { useOnScreen, useSeries, useUser, useVideo } from "shared/hooks";

import { VideoModel } from "shared/types";
import { Text } from "shared/ui-kit";

import LoadingItems from "../LoadingItems";
import VideoBlock from "../block/VideoBlock";

interface Props {
	searchTerm: string;
	active?: boolean;
	showOnlySelfContent?: boolean;
}

const VideosTab: React.FC<Props> = ({ searchTerm, active, showOnlySelfContent }) => {
	const { getVideos, getVideoCount, getData: getVideoData } = useVideo();
	const { videoCount, filteredVideos, loading } = getVideoData();

	const { getData: getUserData } = useUser();
	const { user } = getUserData();

	const lastVideoRef = useRef<HTMLDivElement>(null);
	const [page, setPage] = useState(1);
	const [loadData, setLoadData] = useState(true);

	const onScreen = useOnScreen(lastVideoRef);
	const { setSeriesDetailsDialog, getData: getSeriesData } = useSeries();
	const { seriesDetailsDialog } = getSeriesData();

	const hasMoreData = useMemo(() => videoCount > filteredVideos.length, [filteredVideos, videoCount]);

	const handleToggleVideo = useCallback(
		(video: VideoModel, checked?: boolean) => {
			if (seriesDetailsDialog?.data) {
				if (!checked) {
					setSeriesDetailsDialog({
						addContent: true,
						shouldUpdate: true,
						data: {
							...seriesDetailsDialog.data,
							itemIds: [{ _id: video._id, type: "VIDEO", video }, ...seriesDetailsDialog.data.itemIds]
						}
					});
				} else {
					setSeriesDetailsDialog({
						addContent: true,
						shouldUpdate: true,
						data: {
							...seriesDetailsDialog.data,
							itemIds: seriesDetailsDialog.data.itemIds.filter(item => item._id !== video._id)
						}
					});
				}
			}
		},
		[seriesDetailsDialog, setSeriesDetailsDialog]
	);

	useEffect(() => {
		if (onScreen && hasMoreData) {
			setPage(state => state + 1);
			setLoadData(true);
		}
	}, [hasMoreData, onScreen]);

	useEffect(() => {
		if (active && loadData) {
			setLoadData(false);
			const restProps: Record<string, unknown> = {};
			if (showOnlySelfContent && user?.personaDocId) {
				restProps.personaDocId = user.personaDocId;
			}
			getVideos({ ...restProps, keyword: searchTerm, limit: 10, offset: searchTerm ? 1 : page, paginate: true });
		}
	}, [active, getVideos, page, searchTerm, loadData, showOnlySelfContent, user?.personaDocId]);

	useEffect(() => {
		if (active) {
			getVideoCount({ keyword: searchTerm });
		}
	}, [active, getVideoCount, searchTerm]);

	return (
		<>
			{!filteredVideos.length && !loading && <Text variant="h5">No Results</Text>}
			{loading && !filteredVideos.length ? (
				<LoadingItems />
			) : (
				!!filteredVideos.length &&
				filteredVideos.map((video, i) => {
					if (i + 1 === filteredVideos.length) {
						return (
							<VideoBlock
								handleToggleVideo={handleToggleVideo}
								lastVideoRef={lastVideoRef}
								video={video}
								key={video._id}
								id={`video${i + 1}`}
							/>
						);
					}
					return (
						<VideoBlock handleToggleVideo={handleToggleVideo} key={video._id} video={video} id={`video${i + 1}`} />
					);
				})
			)}
		</>
	);
};

export default VideosTab;
