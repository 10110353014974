import React, { Fragment, memo, useCallback, useEffect, useMemo, useRef, useState } from "react";

import { Box } from "@material-ui/core";

import { Link } from "react-router-dom";

import { ForumPostModel, LikeType, PostBlockEventType } from "types";

import { ReactComponent as DeleteIcon } from "assets/icons/icon-delete.svg";
import { ReactComponent as LikeIcon } from "assets/icons/icon-heart-empty.svg";
import { ReactComponent as MessageIcon } from "assets/icons/icon-message-linear.svg";
// import { ReactComponent as SharePostIcon } from "assets/icons/icon-share.svg";
import { ReactComponent as ReportIcon } from "assets/icons/liveconvo/report.svg";
import { ReactComponent as PencilIcon } from "assets/icons/pencil.svg";
import { boardIcons } from "modules/Manage/View/Components/SelectIconField";
import { ConfirmActionModal, LockedPostBox, MenuDots, MenuDotsOption, ReactionListDialog } from "shared/Components";
import { emotionIcons } from "shared/constants";
import {
	useCommunity,
	useLongPress,
	useMemberRoutes,
	usePost,
	usePosts,
	useReportContent,
	useSmartBlock,
	useUser
} from "shared/hooks";

import usePlayableMedia from "shared/hooks/usePlayableMedia";

import { ReportContentType } from "shared/types";
import { determineSkinTone } from "utils/determineSkinTone";
import { getReaction } from "utils/getReaction";
import { safelyParseJSON } from "utils/serviceUtils/helpers";

import { CategoryInfoCard, CommentsBlock, Like } from "./components";

import {
	AuthorName,
	Body,
	Category,
	CategoryIconWrapper,
	CategoryName,
	CategoryWrapper,
	ContentWrapper,
	Footer,
	Header,
	InfoContent,
	InfoContentWrapper,
	PostDate,
	PostTitle,
	StyledAvatar,
	Wrapper
} from "./style";

export interface PostProps {
	post?: ForumPostModel;
	postContent?: string;
	fullMode?: boolean;
	allowManage?: boolean;
	hideBoard?: boolean;
	boardPathPrefix?: string;
	groupPathPrefix?: string;
	isMarketing?: boolean;
	confirmDeleteCb?: () => void;
}

const Post: React.FC<PostProps> = memo(
	({
		post,
		postContent,
		hideBoard,
		fullMode = false,
		allowManage = true,
		boardPathPrefix = "",
		groupPathPrefix = "",
		isMarketing = false,
		confirmDeleteCb
	}) => {
		const { getDataAttributeName, getBlock } = useSmartBlock(isMarketing);

		const { setPlayableObjectId, setNextTrackId, getData: getPlayableMediaData } = usePlayableMedia();
		const { playableObjectId, nextTrackId } = getPlayableMediaData();

		const { reactOnPost } = usePost();

		const { removePostFromList, deletePost, setCreateUpdatePostDialog } = usePosts();

		const { isPrivilegedRole, getData: getUserData } = useUser();
		const { user } = getUserData();

		const { getReportTypeOptions } = useReportContent();

		const { getMemberRoutesData } = useMemberRoutes();
		const { routes } = getMemberRoutesData();

		const anchorEl = useRef<HTMLElement | null>(null);

		const [showLikeDropdown, setShowLikeDropdown] = useState(false);
		const [showComments, setShowComments] = useState(true);
		const [commentsCount, setCommentsCount] = useState(0);
		const [postReactionList, setPostReactionList] = useState<LikeType[]>([]);
		const [initialisedWrapper, setInitialisedWrapper] = useState(false);
		const [showBoardSummary, setShowBoardSummary] = useState(false);
		const [postTotalLikes, setPostTotalLikes] = useState<number | null>(null);
		const [color, setColor] = useState<string>();
		const [showConfirmDelete, setShowConfirmDelete] = useState(false);
		const [preventShowDropdown, setPreventShowDropdown] = useState(false);
		const [showReactionsList, setShowReactionsList] = useState(false);
		// const [showHashtagSummary, setShowHashtagSummary] = useState(false);

		const wrapperRef = useRef<HTMLDivElement>();

		const { communityColors } = useCommunity();

		const ST = useMemo(() => user?.profiles[0]?.skinTone, [user]);
		const reactionColor = useMemo(() => determineSkinTone(ST || ""), [ST]);

		useEffect(() => {
			setColor(communityColors.primary);
		}, [communityColors.primary]);

		useEffect(() => {
			if (post?.recentLikes?.length) {
				setPostReactionList(post.recentLikes);
			}
			if (post?.postcount) {
				setCommentsCount(post.postcount);
			}

			if (post?.totalLikes) {
				setPostTotalLikes(post.totalLikes);
			}
		}, [post]);

		const onEvent = useCallback(
			(eventType: PostBlockEventType, id: string, args?: any) => {
				if (eventType === PostBlockEventType.trackEnd && args?.trackId) {
					const content = new DOMParser().parseFromString(postContent ? postContent : post!.content, "text/html");

					const blocks = content.querySelectorAll("span[data-name]");
					const audioBlocks = Array.from(blocks)
						.filter(x => x.attributes["data-track"])
						.map(x => JSON.parse(decodeURIComponent(x.attributes["data-track"].value)));

					const index = audioBlocks.findIndex(x => x?.id === args.trackId);
					if (index < audioBlocks.length - 1) {
						setNextTrackId(audioBlocks[index + 1].id);
					}
				}
				if ([PostBlockEventType.trackStart, PostBlockEventType.youtubeClick].includes(eventType) && id) {
					setPlayableObjectId(id);
				}
			},
			[post, postContent, setPlayableObjectId, setNextTrackId]
		);

		const getContent = useMemo(
			() => () => {
				if (postContent || post?.content || (post?.locked && post?.teaserContent)) {
					const content = new DOMParser().parseFromString(
						post?.locked ? `${post!.teaserContent}` : postContent ? postContent : post!.content,
						"text/html"
					);

					const div = document.createElement("div");
					div.innerHTML = content.documentElement.getElementsByTagName("body")[0].innerHTML;

					const renderNodes = (nodes: NodeListOf<ChildNode>) => (
						<>
							{Array.from(nodes).map(n => {
								if (n.nodeName === "A") {
									const tag = n as HTMLLinkElement;
									return (
										<a className={tag.className} href={tag.attributes["href"].value}>
											{tag.innerText}
										</a>
									);
								}

								if (n?.parentElement?.tagName === "A") {
									const tag = n.parentElement as HTMLLinkElement;
									return (
										<a
											className={tag.className}
											href={tag.attributes["href"].value}
											target={tag.attributes["target"]?.value || "_blank"}
										>
											{tag.innerText}
										</a>
									);
								}

								if (n.childNodes?.length) {
									return renderNodes(n.childNodes);
								}

								if (n.nodeType === 3) {
									return n.nodeValue;
								}

								if (n.nodeName === "BR") return <br />;
								if (n.nodeName === "P") return <p>{(n as HTMLParagraphElement).innerHTML}</p>;

								return "";
							})}
						</>
					);

					const correctDomRender = (children: HTMLCollection): JSX.Element => (
						<Fragment>
							{Array.from(children).map((ch, index) => {
								const { attributes } = ch;

								if (attributes["poster"]) {
									const src = attributes["src"].value;
									const poster = attributes["poster"].value;

									return getBlock("video", { src, poster }, ch, onEvent);
								}

								if (attributes["data-name"]?.value) {
									const editorRef = wrapperRef.current;

									const elementName = attributes["data-name"].value;
									const dataAttributeName = getDataAttributeName(elementName);
									const decodedVal = decodeURIComponent(attributes[dataAttributeName].value).replace(/&quot;/g, "'");
									const data = safelyParseJSON<any>(decodedVal) || decodedVal;

									let correctComponent = getBlock(elementName, data, ch, onEvent);

									if (dataAttributeName === "data-track" || dataAttributeName === "data-video") {
										correctComponent = getBlock(
											elementName,
											{
												...data,
												nextTrackId,
												playableObjectId,
												editorRef,
												elementId: data.elementId || post?.pid || post?.tid
											},
											ch,
											onEvent
										);
									}

									if (dataAttributeName === "data-images") {
										correctComponent = getBlock(
											elementName,
											{
												...data,
												editorRef
											},
											ch,
											onEvent
										);
									}

									if (dataAttributeName === "data-show") {
										correctComponent = getBlock(elementName, data, ch, onEvent);
									}

									return <Fragment>{correctComponent}</Fragment>;
								}

								if (ch.children?.length !== ch.childNodes?.length) {
									return <div>{renderNodes(ch.childNodes)}</div>;
								}

								if (ch.children?.length) {
									return correctDomRender(ch.children);
								}

								if (ch.tagName === "IMG") {
									return (
										<img
											key={index}
											src={(ch as HTMLImageElement).src}
											alt={(ch as HTMLImageElement).alt}
											style={{ width: (ch as HTMLImageElement).style?.width || "100%" }}
										/>
									);
								}

								if (ch.tagName === "BR") return <br />;

								return <p key={index}>{ch.innerHTML}</p>;
							})}
						</Fragment>
					);

					return correctDomRender(div.children);
				}
				return "";
			},
			[post, postContent, getBlock, getDataAttributeName, onEvent, nextTrackId, playableObjectId, wrapperRef]
		);

		const lockedContentIcon = useMemo((): string => {
			let iconUrl = "";

			if (postContent || post?.content) {
				const content = new DOMParser().parseFromString(postContent || post!.content, "text/html");

				const blocks = content.querySelectorAll("span[data-name]");
				if (blocks.length) {
					const sliderBlock = Array.from(blocks).find(
						x =>
							x.attributes["data-name"]?.value === "collage" &&
							x.attributes["data-images"]?.value &&
							!!JSON.parse(decodeURIComponent(x.attributes["data-images"]?.value))?.images?.length
					);
					if (sliderBlock) {
						iconUrl = JSON.parse(decodeURIComponent(sliderBlock.attributes["data-images"]?.value)).images[0];
					}
				}
			}

			return iconUrl;
		}, [post, postContent]);

		const userReaction = useMemo(() => {
			if (user) {
				const previousReaction = postReactionList.find(x => x.personaId === user.activeProfile);
				return previousReaction?.reaction;
			}
		}, [postReactionList, user]);

		const addReactToPost = useCallback(
			(reaction?: string) => {
				setShowLikeDropdown(false);
				setPreventShowDropdown(true);
				setTimeout(() => {
					setPreventShowDropdown(false);
				}, 50);

				reactOnPost(reaction ? (ST ? `${reaction}_${ST}` : reaction) : undefined, post?.pid);
				if (reaction) {
					if (userReaction) {
						const index = postReactionList.findIndex(x => x.personaId === Number(user?.activeProfile));
						if (index > -1) {
							postReactionList.splice(index, 1);
							setPostTotalLikes(likes => (likes || 0) - 1);
						}
					}

					postReactionList.unshift({
						personaId: Number(user?.activeProfile),
						reaction: ST ? `${reaction}_${ST}` : reaction
					});
					setPostTotalLikes(likes => (likes || 0) + 1);
				} else {
					const index = postReactionList.findIndex(x => x.personaId === Number(user?.activeProfile));
					if (index > -1) {
						postReactionList.splice(index, 1);
						setPostTotalLikes(likes => (likes || 0) - 1);
					}
				}
				setPostReactionList([...postReactionList]);
			},
			[reactOnPost, ST, post, postReactionList, user, userReaction]
		);

		const toggleComments = useCallback(() => {
			setShowComments(!showComments);
		}, [showComments]);

		const reactLongPress = useLongPress({
			callback: () => {
				setShowLikeDropdown(true);
			},
			onLeave: () => {
				setShowLikeDropdown(false);
			},
			ms: 250,
			refresh: preventShowDropdown ? 1 : 0
		});

		const onCommentEvent = useCallback(
			({ eventType, extra }: { eventType: PostBlockEventType; id?: string; extra?: any }) => {
				if (eventType == PostBlockEventType.addComment) {
					setCommentsCount(count => count + 1);
				}

				if (eventType === PostBlockEventType.delete) {
					setCommentsCount(count => count - 1 - (extra?.totalReplies || 0));
				}
			},
			[]
		);

		const optionList = useMemo(() => {
			let options: MenuDotsOption[] = [];

			const isCreator = post?.uid === user?.personaDocId;

			if (isPrivilegedRole || isCreator) {
				options = [
					{
						icon: <PencilIcon />,
						name: "Edit",
						onClick: () => {
							setCreateUpdatePostDialog({ isOpen: true, editablePost: post });
						}
					},
					{
						icon: <DeleteIcon />,
						name: "Delete",
						onClick: () => {
							setShowConfirmDelete(true);
						}
					}
				];
			}

			if (!isCreator && !isPrivilegedRole) {
				options = [
					...options,
					{
						name: "Report",
						icon: <ReportIcon viewBox="0 -2 22 22" />,
						onClick: () => {},
						submenuOptions: getReportTypeOptions({
							reportType: ReportContentType.POST,
							reportContentId: `${post?.pid}`,
							reportContentName: post?.title,
							customCallback: () => {
								removePostFromList(`${post!.pid}`);
							}
						})
					}
				];
			}

			return options;
		}, [
			getReportTypeOptions,
			post,
			removePostFromList,
			isPrivilegedRole,
			user?.personaDocId,
			setCreateUpdatePostDialog
		]);

		const cancelDelete = () => {
			setShowConfirmDelete(false);
		};

		const confirmDelete = async () => {
			setShowConfirmDelete(false);
			const code = await deletePost({ id: `${post!.pid}`, showNotification: true, contentType: "Post" });
			code === "ok" && confirmDeleteCb && confirmDeleteCb();
		};

		const content = useMemo(() => getContent(), [getContent]);

		const PostHeader = useMemo(
			() => (
				<Header className={`between ${fullMode && "fullMode"}`}>
					<Header.BaseInfo>
						<Link to={`${routes?.member.profile.getPath()}/${post?.user?.persona?.personaId}`}>
							<StyledAvatar
								title={post?.user?.persona?.firstName}
								image={post?.user?.persona?.photos[0]?.profilePicture ?? ""}
							/>
						</Link>
						<InfoContentWrapper>
							<InfoContent>
								<AuthorName to={`${routes?.member.profile.getPath()}/${post?.user?.persona?.personaId}`}>
									{post?.user?.persona?.firstName} {post?.user?.persona?.lastName}
								</AuthorName>
								{!hideBoard && post?.category && (
									<>
										<Box className="posted-in">posted in</Box>
										<CategoryWrapper
											onMouseEnter={() => setShowBoardSummary(true)}
											onMouseLeave={() => setShowBoardSummary(false)}
										>
											<CategoryInfoCard catId={post!.category!.cid} active={showBoardSummary} />
											<Category
												to={
													post.category.isGroupBoard
														? `${groupPathPrefix}${post.category.slug.split("/")[1]}`
														: `${boardPathPrefix}${post!.category!.cid}`
												}
												component={Link}
											>
												{!post.category.isGroupBoard && (
													<CategoryIconWrapper fill={color}>
														{boardIcons.find(x => x.value === post?.category?.iconName)?.icon}
													</CategoryIconWrapper>
												)}
												<Box className="grid">
													<CategoryName className="truncate">{post?.category?.name}</CategoryName>
												</Box>
											</Category>
										</CategoryWrapper>
									</>
								)}
							</InfoContent>

							{post?.timestamp && (
								<PostDate
									date={post!.timestamp}
									live={false}
									formatter={(value, unit, suffix) =>
										value < 60 && unit === "second" ? "Just now" : `${value} ${unit} ${suffix}`
									}
								/>
							)}
						</InfoContentWrapper>
					</Header.BaseInfo>
					<MenuDots options={optionList} size={{ small: true }} memberView />
				</Header>
			),
			[
				fullMode,
				routes?.member.profile,
				post,
				hideBoard,
				showBoardSummary,
				boardPathPrefix,
				groupPathPrefix,
				color,
				optionList
			]
		);

		const PostBody = useMemo(
			() => (
				<Body>
					<ContentWrapper communityprimarycolor={communityColors.primary}>
						<Box className="content">
							{post?.title && <PostTitle variant="h6">{post.title}</PostTitle>}
							{content}
						</Box>
						{post?.locked && <LockedPostBox post={post} backgroundIcon={lockedContentIcon} />}
					</ContentWrapper>
				</Body>
			),
			[communityColors.primary, post, content, lockedContentIcon]
		);

		const LikeColumn = useMemo(
			() => (
				<Footer.ManageColumn
					onMouseEnter={() => {
						if (!preventShowDropdown) {
							setShowLikeDropdown(true);
						}
					}}
					onClick={() => {
						addReactToPost(!userReaction ? emotionIcons[0].value : undefined);
						setShowLikeDropdown(false);
					}}
					{...reactLongPress}
				>
					<Footer.LikeList ref={anchorEl} className="button">
						<Footer.IconWrapper reactionColor={userReaction ? reactionColor : "#222B45"}>
							{userReaction ? getReaction(userReaction)?.icon : <LikeIcon />}
						</Footer.IconWrapper>
						<Footer.Text className={!userReaction && "unselectable"}>
							{userReaction ? getReaction(userReaction)?.label : "Like"}
						</Footer.Text>
					</Footer.LikeList>
					<Like
						reactionColor={reactionColor}
						show={showLikeDropdown && Boolean(anchorEl)}
						onSelect={addReactToPost}
						showChooseSkinTone
						anchorEl={anchorEl.current}
					/>
				</Footer.ManageColumn>
			),
			[preventShowDropdown, reactLongPress, reactionColor, showLikeDropdown, addReactToPost, userReaction]
		);

		const handleRemoveReaction = useCallback(() => {
			addReactToPost();
			setShowReactionsList(false);
		}, [addReactToPost]);

		return (
			<>
				<Wrapper
					className={fullMode && "fullMode"}
					ref={el => {
						wrapperRef.current = el;
						setInitialisedWrapper(true);
					}}
				>
					{post && initialisedWrapper && (
						<Fragment>
							{/* <MentionedHashtag
						onMouseLeave={() => setShowHashtagSummary(false)}
						onMouseEnter={() => setShowHashtagSummary(true)}
					>
						{showHashtagSummary && (
							<Box className="hashtag-summary-popup">
								<Text variant="h4">#ux</Text>
								<Text>
									<b>12k</b> posts with this hashtag.
								</Text>
								<Button>Following</Button>
							</Box>
						)}
						<Text>#ux mentioned</Text>
					</MentionedHashtag> */}
							{PostHeader}
							{PostBody}
						</Fragment>
					)}
					{!post && postContent && (
						<ContentWrapper>
							<Box className="content">{content}</Box>
						</ContentWrapper>
					)}
					{fullMode && user && allowManage && (
						<Footer>
							<Footer.InfoRow>
								<Footer.LikeBlock onClick={() => setShowReactionsList(c => !c)}>
									{!!postReactionList.length && (
										<>
											<Footer.LikeList>
												{postReactionList.slice(0, 3).map((reaction, index) => (
													<Footer.Reaction
														zIndex={postReactionList.slice(0, 3).length - index}
														reactionColor={determineSkinTone(reaction.reaction)}
														key={index}
													>
														{getReaction(reaction.reaction)?.icon}
													</Footer.Reaction>
												))}
											</Footer.LikeList>
											<Footer.Text>{postTotalLikes || postReactionList.length}</Footer.Text>
										</>
									)}
								</Footer.LikeBlock>
								<Footer.Text clickable onClick={toggleComments}>
									{commentsCount ? `${commentsCount} Comment${commentsCount > 1 ? "s" : ""}` : ""}
								</Footer.Text>
							</Footer.InfoRow>
							<Footer.ManageRow>
								{LikeColumn}
								<Footer.ManageColumn onClick={toggleComments}>
									<Footer.IconWrapper>
										<MessageIcon />
									</Footer.IconWrapper>
									<Footer.Text>Comment</Footer.Text>
								</Footer.ManageColumn>
								{/*<Footer.ManageColumn>*/}
								{/*	<Footer.IconWrapper>*/}
								{/*		<SharePostIcon />*/}
								{/*	</Footer.IconWrapper>*/}
								{/*	<Footer.Text>Share</Footer.Text>*/}
								{/*</Footer.ManageColumn>*/}
							</Footer.ManageRow>
							{showComments && post?.pid && (
								<Footer.CommentsBlock>
									<CommentsBlock
										previewComments={post.replies}
										nextComments={post.nextReplies}
										postId={post.pid}
										onEvent={onCommentEvent}
										currentReplies={post?.currentRepliesCount || 0}
									/>
								</Footer.CommentsBlock>
							)}
						</Footer>
					)}
				</Wrapper>
				<ReactionListDialog
					handleRemoveReaction={handleRemoveReaction}
					open={showReactionsList}
					onClose={() => setShowReactionsList(false)}
					pid={post?.pid}
				/>
				{showConfirmDelete && (
					<ConfirmActionModal
						open
						onClose={cancelDelete}
						title="Delete post?"
						bodyText={"Are you sure you want to delete this post?"}
						onConfirm={confirmDelete}
						closeText="Keep"
						confirmText="Delete"
						confirmButtonTheme="danger"
						cancelButtonTheme="primary"
					/>
				)}
			</>
		);
	}
);

export default Post;
