import Switch from "@material-ui/core/Switch";
import styled from "styled-components";

const StyledSwitch = styled(Switch)`
	&.MuiSwitch-root {
		width: 40px;
		height: 24px;
		padding: 0;
	}

	.MuiSwitch-switchBase {
		padding: 1px;

		&.Mui-checked {
			transform: translateX(16px);
			color: #ffffff;

			.MuiSwitch-thumb {
				color: ${({ color }) => color};
				border: 6px solid #fff;
				background-color: #ffffff !important;
			}
		}

		&.Mui-checked + .MuiSwitch-track {
			background-color: ${({ color }) => color};
			opacity: 1;
			border: none;
		}
	}

	.MuiSwitch-thumb {
		width: 20px;
		height: 20px;
		background-color: #8f9bb3;
		transform: translateX(1px) translateY(1px);
		box-shadow: none;
	}

	.MuiSwitch-track {
		border-radius: 13px;
		border: 1px solid #8f9bb3;
		background-color: #ffffff;
		opacity: 1;
		transition: ${props => props.theme.transitions.create(["background-color", "border"])};
	}
`;

const Label = styled.label`
	display: flex;
	cursor: pointer;
`;

const LabelText = styled.div<{ checked: boolean }>`
	margin-right: 16px;
	margin-top: 4px;
	font-size: 13px;
	color: ${({ checked, color }) => (checked ? color : "#8f9bb3")};
`;

export { StyledSwitch, Label, LabelText };
