export const padZero = value => {
	if (!value || !value?.trim?.()) {
		return value;
	}
	const str = `${value}`;
	if (str.length === 1) {
		return `0${str}`;
	}
	return str;
};
