import React, { useCallback } from "react";

import { IconButton, useMediaQuery } from "@material-ui/core";

import { ReactComponent as SuccessIcon } from "assets/icons/illustration-success.svg";
import { useFundraisers } from "shared/hooks";
import { Button, Icon, Text } from "shared/ui-kit";
import * as appTheme from "theme/default";

import { MemberDialog } from "../../shared";

const FundraiserSuccessfulDialog = () => {
	const { getData: getFundraiserData, setDonateSuccessfulDialog, setDonationDialog } = useFundraisers();
	const { donateSuccessfulDialog } = getFundraiserData();

	const handleDonateAgain = useCallback(() => {
		setDonationDialog(donateSuccessfulDialog);
		setDonateSuccessfulDialog();
	}, [donateSuccessfulDialog, setDonateSuccessfulDialog, setDonationDialog]);

	const isMobile = useMediaQuery(appTheme.default.breakpoints.down("xs"));

	return (
		<MemberDialog
			open
			onClose={setDonateSuccessfulDialog}
			customWidth={512}
			title=""
			noHeader
			noFooter={!isMobile}
			footerPrimary={{
				text: "Donate Again",
				onClick: handleDonateAgain
			}}
		>
			{donateSuccessfulDialog?.behalf.member && (
				<div className="mt-3 w-full flex justify-center sm:fixed sm:-mt-7 sm:left-0">
					<div className="relative">
						<img
							className="w-16 h-16 rounded-full border-2 object-cover border-white"
							src={donateSuccessfulDialog?.behalf.member.img}
							alt={donateSuccessfulDialog?.behalf.member.name}
						/>
						{donateSuccessfulDialog?.behalf.group?.img && (
							<img
								className="w-6 h-6 rounded-lg object-cover absolute -bottom-1 -right-1"
								src={donateSuccessfulDialog?.behalf.group.img}
								alt={donateSuccessfulDialog?.behalf.group.name}
							/>
						)}
					</div>
				</div>
			)}
			<div className="absolute top-2 right-2">
				<IconButton size="small" onClick={() => setDonateSuccessfulDialog()}>
					<Icon name="close" group="filled" fill="#8f9bb3" />
				</IconButton>
			</div>
			<div className="pt-4 flex flex-col items-center text-center sm:pt-14 sm:pb-10">
				<div className="flex flex-col gap-2">
					<Text variant="h4">Thank you!</Text>
					<Text variant="subtitle2" className="px-2">
						You have successfully supported {donateSuccessfulDialog?.behalf.member.name} and their team.
					</Text>
				</div>
				<SuccessIcon width={isMobile ? 260 : 280} />
				{!isMobile && (
					<Button className="mt-8" onClick={handleDonateAgain}>
						Donate Again
					</Button>
				)}
			</div>
		</MemberDialog>
	);
};

export default FundraiserSuccessfulDialog;
