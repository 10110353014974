import React, { memo, useCallback, useEffect, useMemo, useState } from "react";

import { EventType } from "types";

import { useFirebaseLiveConversation, useLiveConversation } from "modules/LiveConversation/Data/hooks";
import { useCategories } from "modules/Marketplace/Data";
import {
	Delimiter,
	FilterBlock,
	FilterOptionType,
	ModelBlock,
	ModelBlockType,
	PageWrapper
} from "modules/MemberHome/View/Components";
import { useFirebase } from "modules/Messaging/Data";
import { useCommunity, useEvent, useUser } from "shared/hooks";
import { EventModel } from "shared/types";

const pageSize = 12;
enum ConvoFilterType {
	liveNow = "Live Now",
	upcoming = "Upcoming",
	categories = "Categories"
}

const ConvoList = memo(() => {
	const [firebaseInitialized, setFirebaseInitialized] = useState<boolean>(false);
	const { watchLiveConvos, startNewLiveConvo } = useFirebaseLiveConversation();

	const { getData: getLiveConversationData } = useLiveConversation();
	const { ongoingLiveConvos, ongoingLiveConvosLoading } = getLiveConversationData();

	const { getEvents } = useEvent();

	const { getData: getCommunityData } = useCommunity();
	const { workspace } = getCommunityData();

	const { authenticateUser } = useFirebase();

	const { getActiveProfile, getData: getUserData } = useUser();
	const { user } = getUserData();

	const { getCategories, getData: getCategoriesData } = useCategories();
	const { categories, isLoading: loadingCategories } = getCategoriesData();

	const [categoriesLoaded, setCategoriesLoaded] = useState(false);
	const [upcomingEvents, setUpcomingEvents] = useState<EventModel[]>([]);
	const [upcomingEventsLoading, setUpcomingEventsLoading] = useState<boolean>(true);
	const [selectedFilter, setSelectedFilter] = useState<ConvoFilterType>(ConvoFilterType.liveNow);
	const [upcomingEventsCurrentPage, setUpcomingEventsCurrentPage] = useState<number>(1);
	const [selectedCategory, setSelectedCategory] = useState<string>();
	const activeProfile = useMemo(() => getActiveProfile(user), [getActiveProfile, user]);
	const personaId = activeProfile?.personaId;

	const onPressFilter = useCallback((filter: ConvoFilterType) => {
		setSelectedFilter(filter);
		setUpcomingEventsCurrentPage(1);
		setUpcomingEvents([]);
	}, []);

	const filterOptions: FilterOptionType[] = useMemo(
		() => [
			{
				inlineOptions: true,
				onClick: item => {
					item !== selectedFilter && onPressFilter(item as ConvoFilterType);
				},
				listOptions: [
					{
						label: "Live Now",
						value: ConvoFilterType.liveNow,
						default: selectedFilter === ConvoFilterType.liveNow
					},
					{
						label: "Upcoming",
						value: ConvoFilterType.upcoming,
						default: selectedFilter === ConvoFilterType.upcoming
					}
				]
			},
			{
				label: "Categories",
				loading: loadingCategories,
				onClick: item => {
					if (!item || !item.length || item !== selectedCategory) {
						setSelectedCategory(item as string);
						onPressFilter(ConvoFilterType.categories);
					}
				},
				listOptions: categories.map(({ name, _id }) => ({
					label: name,
					value: _id
				}))
			}
		],
		[categories, loadingCategories, onPressFilter, selectedCategory, selectedFilter]
	);

	const handleFilterWillAppear = useCallback(
		async (item: string) => {
			if (item === "Categories" && !categoriesLoaded) {
				await getCategories({ limit: 20, offset: 1 });
				setCategoriesLoaded(true);
			}
		},
		[categoriesLoaded, getCategories]
	);

	const createOption = useMemo(
		() => ({
			label: "Create Live",
			onClick: startNewLiveConvo,
			val: 1
		}),
		[startNewLiveConvo]
	);

	useEffect(() => {
		if (!firebaseInitialized && personaId && workspace) {
			authenticateUser(personaId, workspace).then(() => {
				setFirebaseInitialized(true);
			});
		}
	}, [personaId, workspace, authenticateUser, firebaseInitialized]);

	useEffect(() => {
		if (firebaseInitialized && selectedFilter === ConvoFilterType.liveNow) {
			watchLiveConvos();
		}
	}, [watchLiveConvos, firebaseInitialized, selectedFilter, selectedCategory]);

	useEffect(() => {
		if (selectedFilter === ConvoFilterType.upcoming || selectedFilter === ConvoFilterType.categories) {
			setUpcomingEventsLoading(true);
			getEvents({
				page: upcomingEventsCurrentPage,
				limit: pageSize,
				eventSchedule: EventType.Upcoming,
				eventType: "liveconversation",
				sortBy: "startTime",
				categories: selectedCategory ? [selectedCategory] : []
			})
				.then(({ events }) => {
					setUpcomingEventsLoading(false);
					if (events?.length) setUpcomingEvents(prevEvents => [...prevEvents, ...events]);
				})
				.catch(() => {
					setUpcomingEventsLoading(false);
				});
		}
	}, [getEvents, upcomingEventsCurrentPage, selectedFilter, selectedCategory]);

	return (
		<PageWrapper>
			<FilterBlock options={filterOptions} createOption={createOption} onShowOptions={handleFilterWillAppear} />
			{[ConvoFilterType.liveNow, ConvoFilterType.categories].includes(selectedFilter) && (
				<ModelBlock
					loading={ongoingLiveConvosLoading}
					title={"Live Now"}
					type={ModelBlockType.liveconvo}
					items={ongoingLiveConvos || []}
					onEndScroll={() => {}}
					noContent={"You don’t have any live conversation."}
				/>
			)}
			{selectedFilter === ConvoFilterType.categories && <Delimiter />}
			{[ConvoFilterType.upcoming, ConvoFilterType.categories].includes(selectedFilter) && (
				<ModelBlock
					loading={upcomingEventsLoading}
					title={"Upcoming Lives"}
					type={ModelBlockType.liveUpcomingEvent}
					items={upcomingEvents}
					onEndScroll={() => {
						if (upcomingEvents.length >= upcomingEventsCurrentPage * pageSize) {
							setUpcomingEventsCurrentPage(prevPage => prevPage + 1);
						}
					}}
					noContent={"You don’t have any upcoming live conversations."}
				/>
			)}
		</PageWrapper>
	);
});

export default ConvoList;
