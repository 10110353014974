import { Box } from "@material-ui/core";
import styled from "styled-components";

import VyooLogoUrl from "assets/icons/vyoo-logo-rounded.svg";
import { Button, Text } from "shared/ui-kit";

export const StyledPopupOverlay = styled(Box)<{ open: boolean }>`
	display: ${({ open }) => (open ? "block" : "none")};
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.4);
	z-index: 3001;

	.MuiDialogContent-root {
		padding: 40px 30px;
		text-align: center;
	}

	.MuiDialog-paper {
		width: 400px !important;
		border-radius: 12px;
	}

	.MuiDialog-root {
		height: 100%;
	}
`;

export const CommunityImg = styled(Box)`
	width: 100%;
	height: 100%;

	display: block;

	background: url(${VyooLogoUrl}) no-repeat center;
	background-size: cover;

	position: relative;
`;

CommunityImg.Wrapper = styled(Box)`
	position: relative;

	width: 80px;
	height: 80px;

	margin: 0 auto;
`;

CommunityImg.ExclamationMarkWrapper = styled(Box)`
	width: 32px;
	height: 32px;

	position: absolute;
	right: -4px;
	bottom: -4px;

	border-radius: 50%;

	overflow: hidden;

	display: flex;
	justify-content: center;
	align-items: center;

	background: white;

	svg {
		width: 20px;
		height: 18px;

		path {
			&:nth-child(2) {
				fill: #db2c33;
			}
		}
	}
`;

export const ConfirmHeader = styled(Box)`
	margin-top: 24px;

	font-size: 26px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.23;
	letter-spacing: normal;
	text-align: center;
	color: #222b45;
`;

export const StyledParagraphText = styled(Text)`
	margin-top: 16px;

	font-size: 15px;
	line-height: 1.47;
	text-align: center;
	color: #222b45;
`;

export const ButtonWrapper = styled(Box)`
	max-width: 224px;

	margin: 24px auto 0 auto;
`;

export const StyledButton = styled(Button)`
	border-radius: 8px;

	.MuiButton-label {
		font-weight: bold;
	}
`;
