import React, { ReactNode, memo, useCallback, useEffect, useMemo, useRef, useState } from "react";

import clsx from "clsx";

import { Icon, Tooltip } from "shared/ui-kit";

import { MoreActionsButton, Option, StyledMenu, StyledMenuItem, Wrapper } from "./style";

export interface MenuDotsOption {
	name: string;
	onClick: () => void;
	value?: number;
	icon?: ReactNode;
	closeOnSelect?: boolean;
	submenuOptions?: MenuDotsOption[];
	id?: string;
}

export enum TransformOriginOpts {
	TOP_LEFT = "top-left",
	TOP_CENTER = "top-center",
	TOP_RIGHT = "top-right",
	CENTER_LEFT = "center-left",
	CENTER_CENTER = "center-center",
	CENTER_RIGHT = "center-right",
	BOTTOM_LEFT = "bottom-left",
	BOTTOM_CENTER = "bottom-center",
	BOTTOM_RIGHT = "bottom-right"
}
interface MenuDotsProps {
	options: MenuDotsOption[];
	size?: {
		extraSmall?: boolean;
		small?: boolean;
		middle?: boolean;
	};
	rounded?: boolean;
	removeSideMargin?: boolean;
	onClose?: () => void;
	inverted?: boolean;
	vertical?: boolean;
	removeBg?: boolean;
	circular?: boolean;
	removeshadow?: boolean;
	dotColor?: string;
	customImage?: ReactNode;
	memberView?: boolean;
	alignLeft?: boolean;
	transformOrigin?: TransformOriginOpts;
	customWidth?: number;
	customOptionHeight?: number;
	menuId?: string;
	iconClass?: string;
	onClick?: () => void;
	customAnchor?: ReactNode;
	className?: string;
	invisibleFullWidth?: boolean;
	dontFillSvg?: boolean;
}

const MenuDots: React.FC<MenuDotsProps> = memo(
	({
		options,
		size,
		rounded,
		removeSideMargin,
		onClose,
		inverted,
		vertical,
		removeBg,
		circular,
		removeshadow,
		dotColor,
		customImage,
		memberView = false,
		transformOrigin,
		alignLeft,
		customWidth,
		customOptionHeight,
		menuId,
		iconClass,
		onClick,
		customAnchor,
		className,
		invisibleFullWidth,
		dontFillSvg
	}) => {
		const [currentMenuOptions, setCurrentMenuOptions] = useState<MenuDotsOption[]>(options);
		const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

		useEffect(() => {
			setCurrentMenuOptions(options);
		}, [options]);

		const wrapperRef = useRef<HTMLElement | HTMLDivElement | null>(null);
		const open = Boolean(anchorEl);

		const handleClick = useCallback(
			(e?: React.MouseEvent<HTMLElement>) => {
				e?.stopPropagation();

				onClick && onClick();

				if (wrapperRef.current) {
					setAnchorEl(wrapperRef.current);
				}
			},
			[onClick]
		);

		const handleClose = useCallback(() => {
			setAnchorEl(null);
			onClose && onClose();

			setTimeout(() => {
				setCurrentMenuOptions(options);
			}, 250);
		}, [onClose, options]);

		const switchOptions = useCallback(
			(newOptionList: MenuDotsOption[]) => {
				setAnchorEl(null);
				onClose && onClose();

				setTimeout(() => {
					setCurrentMenuOptions(newOptionList);
					handleClick();
				}, 200);
			},
			[onClose, handleClick]
		);

		const menuOrigins = useMemo(
			() =>
				transformOrigin
					? {
							vertical: transformOrigin.split("-")[0],
							horizontal: transformOrigin.split("-")[1]
					  }
					: {
							vertical: "top",
							horizontal: "right"
					  },
			[transformOrigin]
		);

		const dotsContent = useMemo(
			() => (
				<MoreActionsButton
					id={menuId}
					buttonTheme="outline"
					inverted={inverted}
					vertical={vertical}
					removebg={removeBg}
					removeshadow={removeshadow}
					btnSize={size}
					onClick={handleClick}
					circular={circular}
					className={clsx(invisibleFullWidth && "w-full h-full opacity-0")}
				>
					{customImage ? (
						<div>{customImage}</div>
					) : (
						<Icon
							fill={dotColor || (inverted ? "transparent" : "#c5cee0")}
							group="filled"
							height={size?.small || size?.extraSmall ? 16 : 24}
							name="ellipsis"
							width={size?.small || size?.extraSmall ? 16 : 24}
							aria-controls="long-menu"
							aria-haspopup="true"
							aria-label="more"
							aria-expanded={open ? "true" : undefined}
							className={iconClass}
						/>
					)}
				</MoreActionsButton>
			),
			[
				circular,
				customImage,
				dotColor,
				handleClick,
				iconClass,
				inverted,
				invisibleFullWidth,
				menuId,
				open,
				removeBg,
				removeshadow,
				size,
				vertical
			]
		);

		if (!options?.length) return null;

		if (customAnchor) {
			return (
				<>
					<div onClick={handleClick} ref={wrapperRef}>
						{customAnchor}
					</div>
					<StyledMenu
						customWidth={customWidth}
						anchorEl={anchorEl}
						open={open}
						onClose={handleClose}
						getContentAnchorEl={null} // it fixes anchorOrigin vertical ignored prop
						anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
						transformOrigin={{ ...menuOrigins }}
						PaperProps={{
							style:
								rounded || memberView
									? {
											borderRadius: "12px",
											boxShadow: "inset 0 0 0 0.5px #c5cee0, 0 4px 40px 0 rgba(0, 0, 0, 0.16)",
											paddingLeft: 8,
											paddingRight: 8
									  }
									: {}
						}}
					>
						{currentMenuOptions.map((item, index) => (
							<StyledMenuItem
								dontFillSvg={dontFillSvg}
								id={item?.id || ""}
								customOptionHeight={customOptionHeight}
								className={alignLeft ? "left-aligned" : ""}
								onClick={e => {
									e.stopPropagation();
									if (item.submenuOptions) {
										switchOptions(item.submenuOptions);
									} else {
										item.onClick();
										if (item.closeOnSelect === undefined || item.closeOnSelect) {
											handleClose();
										}
									}
								}}
								key={index}
							>
								{item?.icon && <StyledMenuItem.IconWrapper>{item.icon}</StyledMenuItem.IconWrapper>}
								<Option.Text withIcon={item?.icon}>{item.name}</Option.Text>
							</StyledMenuItem>
						))}
					</StyledMenu>
				</>
			);
		}

		return (
			<Wrapper
				size={size}
				removeSideMargin={removeSideMargin}
				ref={wrapperRef}
				className={clsx(className, invisibleFullWidth && "absolute top-0 left-0 w-full h-full ml-0")}
			>
				{invisibleFullWidth ? dotsContent : <Tooltip text="More actions">{dotsContent}</Tooltip>}
				<StyledMenu
					customWidth={customWidth}
					anchorEl={anchorEl}
					open={open}
					onClose={handleClose}
					getContentAnchorEl={null} // it fixes anchorOrigin vertical ingored prop
					anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
					transformOrigin={{ ...menuOrigins }}
					PaperProps={{
						style:
							rounded || memberView
								? {
										borderRadius: "12px",
										boxShadow: "inset 0 0 0 0.5px #c5cee0, 0 4px 40px 0 rgba(0, 0, 0, 0.16)",
										paddingLeft: 8,
										paddingRight: 8
								  }
								: {}
					}}
				>
					{currentMenuOptions.map((item, index) => (
						<StyledMenuItem
							dontFillSvg={dontFillSvg}
							id={item?.id || ""}
							customOptionHeight={customOptionHeight}
							className={alignLeft ? "left-aligned" : ""}
							onClick={e => {
								e.stopPropagation();
								if (item.submenuOptions) {
									switchOptions(item.submenuOptions);
								} else {
									item.onClick();
									if (item.closeOnSelect === undefined || item.closeOnSelect) {
										handleClose();
									}
								}
							}}
							key={index}
						>
							{item?.icon && <StyledMenuItem.IconWrapper>{item.icon}</StyledMenuItem.IconWrapper>}
							<Option.Text withIcon={item?.icon}>{item.name}</Option.Text>
						</StyledMenuItem>
					))}
				</StyledMenu>
			</Wrapper>
		);
	}
);

export default MenuDots;
