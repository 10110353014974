import React, { FC, useCallback, useRef, useState } from "react";

import { Box, ButtonBase, IconButton } from "@material-ui/core";

import AddIcon from "@material-ui/icons/Add";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import RemoveIcon from "@material-ui/icons/Remove";
import RotateRightIcon from "@material-ui/icons/RotateRight";
import InputRange from "react-input-range";

import QuickPinchZoom, { make3dTransformValue } from "react-quick-pinch-zoom";

import { Text } from "shared/ui-kit";

import { PreviewDialogWrapper } from "./style";

export interface MessagePreviewType {
	handleDownload: () => void;
	URL?: string;
}
const ImagePreview: FC<MessagePreviewType> = ({ handleDownload, URL }) => {
	const [axis, setAxis] = useState({
		x: 0,
		y: 0,
		scale: 100
	});
	const [rotate, setRotate] = useState(0);

	const imgRef = useRef<HTMLImageElement>(null);
	const zoomAreaRef = useRef<QuickPinchZoom>(null);
	const onUpdate = useCallback(({ x, y, scale }) => {
		const { current: img } = imgRef;

		if (img) {
			const value = make3dTransformValue({ x, y, scale });

			img.style.setProperty("transform", value);
		}
		setAxis({
			x,
			y,
			scale
		});
	}, []);

	return (
		<>
			<PreviewDialogWrapper.ImagePreviewContent
				style={{
					backgroundImage: `url(${URL})`
				}}
			>
				<PreviewDialogWrapper.BG />
				<QuickPinchZoom onUpdate={onUpdate} ref={zoomAreaRef}>
					<img src={URL} alt="" ref={imgRef} />
				</QuickPinchZoom>
			</PreviewDialogWrapper.ImagePreviewContent>
			<PreviewDialogWrapper.Footer>
				<PreviewDialogWrapper.ImageControls>
					<IconButton
						size="small"
						onClick={() => {
							imgRef.current?.setAttribute("style", `transform: rotate(${rotate + 90}deg)`);
							setRotate(prev => prev + 90);
						}}
					>
						<RotateRightIcon />
					</IconButton>
					<Box className="zoom-controls">
						<IconButton
							size="small"
							onClick={() =>
								zoomAreaRef.current?.scaleTo({
									scale: axis.scale - 1,
									x: axis.x,
									y: axis.y
								})
							}
						>
							<RemoveIcon />
						</IconButton>
						<InputRange value={axis.scale * 100} minValue={50} maxValue={500} onChange={() => {}} disabled />
						<IconButton
							size="small"
							onClick={() =>
								zoomAreaRef.current?.scaleTo({
									scale: axis.scale + 1,
									x: axis.x,
									y: axis.y
								})
							}
						>
							<AddIcon />
						</IconButton>
					</Box>
				</PreviewDialogWrapper.ImageControls>
				<ButtonBase className="download" onClick={handleDownload}>
					<CloudDownloadIcon />
					<Text>Download</Text>
				</ButtonBase>
			</PreviewDialogWrapper.Footer>
		</>
	);
};

export default ImagePreview;
