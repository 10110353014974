import React, { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";

import { Dialog } from "shared/Components";

import { AccountSettings } from "./AccountSettings";
import { BlockedUsers } from "./BlockedUsers";
import { DeleteOrDeactivateAccount } from "./DeleteOrDeactivateAccount";
import { Notifications } from "./Notifications";
import { PaymentMethods } from "./PaymentMethods";
import { PrivacyPolicy } from "./PrivacyPolicy";
import { ReportBug } from "./ReportBug";
import { TermsConditions } from "./TermsConditions";

import {
	Menu,
	MenuItem,
	SettingsSection,
	SettingsSectionHeader,
	SettingsSectionSubtitle,
	SettingsSectionTitle,
	Sidebar,
	Wrapper,
	dialogBodyStyles
} from "./styles";

enum ActiveSection {
	accountSettings = "ACCOUNT_SETTINGS",
	paymentMethods = "PAYMENT_METHODS",
	termsConditions = "TERMS_CONDITIONS",
	privacyPolicy = "PRIVACY_POLICY",
	notifications = "NOTIFICATIONS",
	blockedUsers = "BLOCKED_USERS",
	reportBug = "REPORT_BUG",
	deactivation = "DEACTIVATION"
}

const sections: { title: string; key: ActiveSection; caption?: string; component: () => JSX.Element; id?: string }[] = [
	{
		title: "Account Settings",
		key: ActiveSection.accountSettings,
		caption: "Below you can see your log in methods and edit them.",
		component: AccountSettings
	},
	{
		title: "Payment Methods",
		key: ActiveSection.paymentMethods,
		caption: "Below you can see your payment methods and edit them.",
		component: PaymentMethods
	},
	{
		title: "Terms & Conditions",
		key: ActiveSection.termsConditions,
		component: TermsConditions
	},
	{
		title: "Privacy Policy",
		key: ActiveSection.privacyPolicy,
		component: PrivacyPolicy
	},
	{
		title: "Notifications",
		caption: "Below you can see your notification settings and disable/enable them.",
		key: ActiveSection.notifications,
		component: Notifications
	},
	{ title: "Report a Bug", key: ActiveSection.reportBug, component: ReportBug },
	{ title: "Blocked Users", key: ActiveSection.blockedUsers, component: BlockedUsers },
	{
		title: "Deactivation & Deletion",
		key: ActiveSection.deactivation,
		id: "deactivation",
		caption:
			"If you want to take a break from Vyoo, you can deactivate your account. If you want to permanently delete your Vyoo account, let us know.",
		component: DeleteOrDeactivateAccount
	}
];

const useStyles = makeStyles({
	root: {
		maxWidth: "900px",
		height: "680px",
		borderRadius: "12px"
	}
});
interface Props {
	onClose: () => void;
}

export function SettingsDialog(props: Props) {
	const { onClose } = props;
	const [activeSection, setActiveSection] = useState<ActiveSection>(ActiveSection.accountSettings);
	const classes = useStyles();

	const handleMenuClick = (newActiveSection: ActiveSection) => {
		setActiveSection(newActiveSection);
	};

	const sectionData = sections.find(section => section.key === activeSection);

	if (!sectionData) {
		console.error(`No section data found for ${activeSection}`);
		return null;
	}

	const { component: CurrentSection, title: currentSectionTitle, caption: currentSectionCaption } = sectionData;

	return (
		<Dialog
			showHead={false}
			open={true}
			bodyCustomStyles={dialogBodyStyles}
			PaperProps={{ classes }}
			onClose={onClose}
			className="maxHeight85"
			isDelete
			isScrollable={false}
		>
			<Wrapper>
				<Sidebar>
					<Menu>
						{sections.map(section => (
							<MenuItem
								id={section?.id || ""}
								key={section.key}
								onClick={() => handleMenuClick(section.key)}
								selected={section.key === activeSection}
							>
								{section.title}
							</MenuItem>
						))}
					</Menu>
				</Sidebar>
				<SettingsSection>
					<SettingsSectionHeader>
						<SettingsSectionTitle>{currentSectionTitle}</SettingsSectionTitle>
						{currentSectionCaption && <SettingsSectionSubtitle>{currentSectionCaption}</SettingsSectionSubtitle>}
					</SettingsSectionHeader>
					<CurrentSection />
				</SettingsSection>
			</Wrapper>
		</Dialog>
	);
}
