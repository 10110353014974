import { Badge } from "@material-ui/core";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

import { Box, Text } from "shared/ui-kit";

export const ListWrapper = styled(Box)`
	margin-top: 8px;
	padding-bottom: 10px;
`;

export const Item = styled(Box)``;

Item.Link = styled(Link)`
	display: flex;
	align-items: center;
	padding: 8px;
	cursor: pointer;
	text-decoration: none;

	&:hover {
		background-color: #f7f9fc;
		border-radius: 8px;
	}
`;

Item.IconWrapper = styled(Box)<{ color?: string }>`
	width: 30px;
	height: 30px;

	margin-right: 15px;

	svg {
		width: 30px;
		height: 30px;
		${({ isRequestIcon }) =>
			!isRequestIcon &&
			css`
				g {
					g {
						fill: ${({ color }) => color};
					}
				}
			`}

		&.request-fill {
			#Path-2,
			#Path {
				fill: ${({ color }) => color};
			}
		}

		&.fundraiser-fill {
			margin-left: 4px;
			width: 32px;
			height: 32px;
			path {
				fill: ${({ color }) => color};
			}
		}
	}
`;

Item.Title = styled(Text)`
	font-size: 15px;
	font-weight: 600;
	line-height: 1.6;
	color: #222b45;
`;

Item.Badge = styled(Badge)`
	.MuiBadge-anchorOriginTopRightRectangle {
		left: 0;
		right: unset;
	}

	.MuiBadge-badge {
		height: 18px;
		min-width: 18px;
	}
`;
