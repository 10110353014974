import { Box } from "@material-ui/core";

import styled from "styled-components";

import { Button } from "shared/ui-kit";

export const IconWrapper = styled(Box)`
	display: flex;
	justify-content: center;
`;

export const TitleWrapper = styled(Box)`
	margin-top: 41px;

	text-align: center;
`;

export const DescriptionWrapper = styled(Box)`
	margin-top: 17px;

	text-align: center;
`;

export const ButtonWrapper = styled(Box)`
	max-width: 170px;

	margin: 19px auto 0 auto;
`;

export const StyledButton = styled(Button)`
	border-radius: 8px;

	padding-left: 5px;
	padding-right: 5px;

	.MuiButton-label {
		font-weight: bold;
	}
`;
