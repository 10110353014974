/* eslint-disable no-useless-escape */
const removeHTMLTags = str => {
	let correctStr = str;
	if (str === null || str === "") {
		return false;
	} else {
		correctStr = str.toString();
	}
	return correctStr.replace(/(<([^>]+)>)/gi, "");
};

const isEmailRx =
	/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const isEmail = title => {
	let _isEmail = false;
	if (isEmailRx.test(title)) {
		_isEmail = true;
	}
	return _isEmail;
};

const getImageUrl = initImage => {
	let image: string | null = null;
	if (initImage && initImage.variations) {
		image = [initImage.url, ...initImage.variations].join("/");
		if (initImage.ver) {
			image = `${image}?${initImage.ver}`;
		}
	} else if (initImage && initImage.url) {
		image = initImage.ver ? `${initImage.url}?${initImage.ver}` : initImage.url;
	}
	return image;
};

// Traversal recursion
const traverseArray = (arr, executor) => {
	arr.forEach(x => traverse(x, executor));
};

const traverseObject = (obj, executor) => {
	for (const key in obj) {
		if (obj.hasOwnProperty(key)) {
			traverse(obj[key], executor);
			if (typeof executor === "function") {
				executor(obj, key);
			}
		}
	}
};

const isArray = o => Object.prototype.toString.call(o) === "[object Array]";

const traverse = (x, executor) => {
	if (isArray(x)) {
		traverseArray(x, executor);
	} else if (typeof x === "object" && x !== null) {
		traverseObject(x, executor);
	} else {
		// no-op
	}
};
// Traversal recursion end

/* const testObj = {a: 1, b:2, c:[{x:1, __typename: 5, y:2}, {x:3, y:4}], d: {__typename: 1, m: 1, n: 2}, __typename: 5}
traverse(testObj, (obj, key) => {
  if(key === "__typename") {
    delete obj[key]
  }
}) */

const toTitleCase = str => {
	const sentence = str.toLowerCase().split(" ");
	for (let i = 0; i < sentence.length; i++) {
		if (sentence[i].length > 0) {
			sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
		}
	}
	return sentence.join(" ");
};

export { removeHTMLTags, isEmail, getImageUrl, traverse, toTitleCase };
