import React, { FC, useEffect, useMemo, useRef, useState } from "react";

import { Box, FormControl as MuiFormControl, Switch, TextField, makeStyles } from "@material-ui/core";

import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";
import { Controller, useForm } from "react-hook-form";
import styled, { css } from "styled-components";

import { ReactComponent as DownloadIcon } from "assets/icons/icon-download.svg";
import { TrackVideoPreviewCutter } from "modules/Manage/View/Components";
import { SubText } from "modules/Manage/View/Components/CreateUpdateAlbumDialog";
import { FeatureBlock } from "modules/Manage/View/Containers/style";

import { Dialog, SetupPrice, UploadProgressBar } from "shared/Components";
import { useAlbums, useDebounce, useTrack } from "shared/hooks";

import useScrollToXPosition from "shared/hooks/useScrollToXPosition";
import { useS3Uploader } from "shared/services/s3Uploader";
import { TrackMeta, TrackModel, genres } from "shared/types";
import { Button, Icon, Loader, Text, Uploader, orientationConst } from "shared/ui-kit";

import ConfirmLeavePopup from "../ConfirmLeave";
import useConfirmLeavePopup from "../ConfirmLeave/hooks/useConfirmLeavePopup";

const FormControl = styled(MuiFormControl)`
	margin-top: 12px;
	margin-bottom: 12px;
`;

export const UploadProgressBarWrapper = styled(Box)`
	position: absolute;
	top: 0;
	left: 0;

	width: 100%;

	z-index: 2;
`;

const UploaderWrapper = styled(Box)`
	width: 100%;
	margin-top: 24px;

	.item-description {
		display: none;
	}

	> div {
		> div {
			width: 100%;
		}
	}

	.preview-section {
		.item-img-section {
			max-width: 120px;
			max-height: 120px;
			img {
				max-width: 100px;
			}
		}
	}

	.explorer-uploader .description .anchor {
		color: #6173fe;
	}

	.explorer-uploader {
		${props => props.theme.breakpoints.down("sm")} {
			padding: 28px 24px;
		}
		.label {
			${props => props.theme.breakpoints.down("sm")} {
				font-size: 15px;
			}
		}
	}

	${props => props.theme.breakpoints.down("sm")} {
		margin-top: 15px;
	}
`;

export const StepBox = styled(Box)`
	display: none;

	&.active {
		display: block;
	}

	&.row {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
	}

	&.hidden {
		display: none;
	}
`;

const StyledActionButton = styled(Button)`
	height: 48px;

	${props => props.theme.breakpoints.down("sm")} {
		width: 100%;
	}
`;

const GenreAutocompleteWrapper = styled(Box)`
	.MuiAutocomplete-inputRoot {
		width: 100%;
	}
`;

const useStyles = makeStyles(() => ({
	formControl: {
		width: "100%"
	}
}));

interface Props {
	editableModel?: Partial<TrackModel>;
	open: boolean;
	onClose: () => void;
	onUploaderChange: (files: FileList, onChange: (...event: any[]) => void) => Promise<void>;
	submitTrack: (data: any) => Promise<void>;
	isFreeCommunity?: boolean;
}

const CreateTrackManageView: FC<Props> = ({
	open,
	onClose,
	editableModel,
	onUploaderChange,
	submitTrack,
	isFreeCommunity = false
}) => {
	const bodyRef = useRef<HTMLFormElement>();
	const scrollToXPositionOnFocus = useScrollToXPosition(bodyRef?.current);
	const { getData: getS3UploaderData } = useS3Uploader();
	const { uploadingProgress, loadingSignedUrl, uploading } = getS3UploaderData();

	const [searchText, setSearchText] = useState("");

	const { getAlbums, getData: getAlbumsData } = useAlbums();
	const { albums } = getAlbumsData();

	const [step, setStep] = useState(1);

	const [preview, setPreview] = useState({
		start: 0,
		end: 30000
	});
	const {
		handleLeavePageConfirmed,
		closeConfirmPopup,
		handleClose,
		getData: getConfirmLeavePopupData
	} = useConfirmLeavePopup({
		onClose,
		open
	});
	const { showConfirmPopup } = getConfirmLeavePopupData();

	const { getData: getTrackData, setTrackFileUrl } = useTrack();
	const { trackName, creating, trackFileUrl } = getTrackData();

	const classes = useStyles();

	const { reset, control, handleSubmit, formState, watch } = useForm();

	const { isValid, isDirty, errors } = formState;
	const { title, artist, genre } = watch(["title", "artist", "genre"]);

	const debouncedSearchText = useDebounce(searchText, 400);

	useEffect(() => {
		if (open) {
			setStep(1);
		}
	}, [open]);

	useEffect(() => {
		if (open && step === 2) {
			getAlbums({ limit: 10, offset: 1, keyword: debouncedSearchText });
		}
	}, [debouncedSearchText, getAlbums, open, step]);

	useEffect(() => {
		if (editableModel) {
			reset({
				...editableModel,
				artist: (editableModel.meta as TrackMeta).artist,
				genre: editableModel.genre
					? editableModel.genre?.map(x => ({ key: x, value: genres.find(g => g.key === x || g.value === x)?.value }))
					: undefined,
				album: !!editableModel.parentAlbums?.length
					? editableModel.parentAlbums.map(obj => ({
							label: obj.title,
							value: obj._id
					  }))
					: undefined
			});
			(editableModel?.meta as TrackMeta) && setTrackFileUrl((editableModel?.meta as TrackMeta)?.track.url);

			setPreview({
				start: (editableModel.meta as TrackMeta).previewStart,
				end: (editableModel.meta as TrackMeta).previewStop
			});
		} else {
			reset(
				trackName
					? {
							title: trackName
					  }
					: {}
			);
		}
	}, [editableModel, reset, trackName, setTrackFileUrl]);

	const albumsList = useMemo(
		() =>
			(albums || []).map(album => ({
				value: album._id,
				label: album.title
			})),
		[albums]
	);

	const Title = (
		<>
			<Text variant="h7">{editableModel ? "Update" : "Create"} Track</Text> <SubText>• Step {step}/2</SubText>
		</>
	);

	type SetupPriceMethodsHandlers = React.ElementRef<typeof SetupPrice>;
	const setupPriceFormRef = useRef<SetupPriceMethodsHandlers>(null);

	const onSubmit = data => {
		delete data.priceChanged;

		if (step === 1) {
			setStep(2);
		} else {
			const priceTags = setupPriceFormRef.current?.getPriceTags();
			submitTrack({ ...data, priceTags, preview });
		}
	};

	const ActionButton = (
		<StyledActionButton
			type="submit"
			onClick={handleSubmit(onSubmit)}
			leftIcon={
				uploading || loadingSignedUrl || !trackFileUrl ? (
					<Loader size="1rem" show={true} color="primary" variant="indeterminate" />
				) : undefined
			}
			disabled={
				(!editableModel ? !isDirty : step === 1 ? false : !isDirty) ||
				uploading ||
				loadingSignedUrl ||
				creating ||
				!trackFileUrl ||
				(step === 1 ? (!editableModel ? !title || !artist || !genre || !genre.length : false) : !isValid)
			}
		>
			{step === 1 ? "Next" : editableModel ? "Update Track" : "Create Track"}
		</StyledActionButton>
	);

	return (
		<>
			{open && (
				<ConfirmLeavePopup
					handleLeavePage={handleLeavePageConfirmed}
					open={showConfirmPopup}
					onClose={closeConfirmPopup}
				/>
			)}
			<Dialog
				title={Title}
				hasBackButton={step === 2}
				onBack={() => setStep(1)}
				open={open}
				onClose={() => handleClose(true)}
				footer={ActionButton}
				bodyCustomStyles={css`
					position: relative;
				`}
				bodyRef={bodyRef}
			>
				<UploadProgressBarWrapper>
					{!editableModel && (
						<UploadProgressBar
							progress={uploadingProgress}
							successText={"Your Track has been successfully uploaded!"}
						/>
					)}
				</UploadProgressBarWrapper>
				<form onSubmit={handleSubmit(onSubmit)}>
					<StepBox className={step === 1 ? "active" : "hidden"}>
						<UploaderWrapper>
							<Controller
								name="trackImageUrl"
								control={control}
								render={({ value, onChange }) => (
									<Uploader
										urls={
											!value && editableModel && (editableModel?.meta as TrackMeta).artwork.url
												? [`${(editableModel.meta as TrackMeta).artwork.url}`]
												: value
												? [value]
												: undefined
										}
										onChange={(files: any) => onUploaderChange(files, onChange)}
										label="Upload Track Photo"
										orientation={orientationConst.horizontal}
										width={"100%"}
										description={
											<>
												Drag and Drop File Here or <span className="anchor">Browse</span> to Choose a File
											</>
										}
										accept={[
											{
												fileType: "image/png, image/jpeg, image/x-eps",
												name: "png, jpg, eps"
											}
										]}
										icon={
											<Icon
												group={""}
												fill={"#c5cee0"}
												name={"cloud-upload"}
												width={64}
												height={50}
												viewBox={"4 2 18 19"}
											/>
										}
									/>
								)}
							/>
						</UploaderWrapper>
						<FormControl>
							<Controller
								name="title"
								control={control}
								rules={{ required: "Track name is required!" }}
								render={({ onChange, value }) => (
									<TextField
										value={value}
										onChange={onChange}
										label="Track name*"
										variant="outlined"
										error={errors?.title?.message}
										helperText={errors?.title?.message}
										onFocus={scrollToXPositionOnFocus}
									/>
								)}
							/>
						</FormControl>
						<FormControl>
							<Controller
								name="artist"
								control={control}
								rules={{ required: "Artist is required!" }}
								render={({ onChange, value }) => (
									<TextField
										value={value}
										onChange={onChange}
										label="Artist"
										variant="outlined"
										required
										onFocus={scrollToXPositionOnFocus}
									/>
								)}
							/>
						</FormControl>
						<FormControl>
							<Controller
								name="genre"
								variant="outlined"
								rules={{ required: "Genre is required!", validate: value => !!value?.length }}
								control={control}
								render={({ onChange, value }) => (
									<GenreAutocompleteWrapper>
										<Autocomplete
											multiple
											ListboxProps={{ style: { maxHeight: "180px" }, position: "bottom-start" }}
											renderInput={params => <TextField {...params} label="Genres*" variant="outlined" />}
											getOptionLabel={option => (typeof option === "string" ? option : option.value)}
											id="genre-selector"
											filterOptions={createFilterOptions({
												matchFrom: "start",
												stringify: option => option.value
											})}
											includeInputInList
											filterSelectedOptions
											options={genres}
											value={value}
											onChange={(event, newValue) => onChange(newValue)}
											onFocus={scrollToXPositionOnFocus}
										/>
									</GenreAutocompleteWrapper>
								)}
							/>
						</FormControl>
						<FormControl>
							<Controller
								name="description"
								control={control}
								rules={{
									maxLength: {
										value: 50,
										message: "Description should not exceed 50 characters"
									}
								}}
								render={({ onChange, value }) => (
									<TextField
										value={value}
										onChange={onChange}
										label="Description"
										variant="outlined"
										multiline
										rows={3}
										error={errors?.description?.message}
										helperText={errors?.description?.message}
										onFocus={scrollToXPositionOnFocus}
									/>
								)}
							/>
						</FormControl>
					</StepBox>
					<StepBox className={step === 2 ? "active" : "hidden"}>
						<Controller
							name="album"
							control={control}
							render={({ onChange, value }) => (
								<FormControl variant="outlined" className={classes.formControl}>
									<Autocomplete
										multiple
										id="selectAlbum"
										options={albumsList}
										getOptionLabel={(option: { label: string }) => option.label}
										onChange={(_, newValue) => onChange(newValue)}
										filterSelectedOptions
										value={value}
										renderInput={params => (
											<TextField
												{...params}
												label="Select album"
												variant="outlined"
												onChange={e => setSearchText(e.target.value)}
											/>
										)}
										onFocus={scrollToXPositionOnFocus}
									/>
								</FormControl>
							)}
						/>
						{!isFreeCommunity && (
							<Controller
								name="price"
								control={control}
								defaultValue={!!editableModel?.priceTags?.length}
								render={({ onChange, value }) => (
									<>
										<FeatureBlock>
											<Box className="head">
												<Box className="left-side">
													<MonetizationOnIcon />
													<Text variant="subtitle1">Set a Price</Text>
												</Box>
												<Switch checked={value} onChange={e => onChange(e.target.checked)} />
											</Box>
											{value && (
												<Controller
													name="priceChanged"
													control={control}
													render={({ onChange }) => (
														<SetupPrice
															ref={setupPriceFormRef}
															title={"Who can access this track?"}
															onPriceChanged={() => onChange(true)}
															defaultValue={editableModel?.priceTags}
														/>
													)}
												/>
											)}
										</FeatureBlock>
										<Box
											style={{
												opacity: value ? 1 : 0,
												height: value ? "auto" : 0
											}}
										>
											<FeatureBlock noBorder>
												<Box className="head">
													<Box className="left-side">
														<Text variant="subtitle1">Preview</Text>
													</Box>
												</Box>
											</FeatureBlock>
											<TrackVideoPreviewCutter
												preview={preview}
												setPreview={setPreview}
												url={editableModel ? (editableModel.meta as TrackMeta).track.url : trackFileUrl}
											/>
										</Box>
									</>
								)}
							/>
						)}
						<Controller
							name="downloadable"
							control={control}
							defaultValue={(editableModel?.meta as TrackMeta)?.canBeDownloaded}
							render={({ onChange, value }) => (
								<FeatureBlock>
									<Box className="head">
										<Box className="left-side">
											<DownloadIcon />
											<Text variant="subtitle1">Allow Download</Text>
										</Box>
										<Switch checked={value} onChange={e => onChange(e.target.checked)} />
									</Box>
								</FeatureBlock>
							)}
						/>
						<Controller
							name="private"
							control={control}
							defaultValue={(editableModel?.meta as TrackMeta)?.private}
							render={({ onChange, value }) => (
								<FeatureBlock noBorder>
									<Box className="head">
										<Box className="left-side">
											<VisibilityIcon />
											<Box>
												<Text variant="subtitle1">Set as Private</Text>
												<Text variant="body2">Choose this option to hide from others in the community</Text>
											</Box>
										</Box>
										<Switch checked={value} onChange={e => onChange(e.target.checked)} />
									</Box>
								</FeatureBlock>
							)}
						/>
					</StepBox>
				</form>
			</Dialog>
		</>
	);
};

export default CreateTrackManageView;
