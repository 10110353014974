import { useMemo } from "react";

import { API_URLS } from "shared/constants";
import useHttpActions from "shared/services/api/core/useHttpActions";
import { BaseConverter } from "shared/services/converters";

import { convertPoolFormRequest } from "./converters/PollConverter";

import { PollModelResponse } from "../types";

const usePollApiService = (isMarketing = false) => {
	const params = useMemo(() => (isMarketing ? { customErrorHandlers: [] } : undefined), [isMarketing]);

	const actions = useHttpActions(params);

	return useMemo(
		() => ({
			getAll: async () => {
				return BaseConverter.convert<PollModelResponse[]>(await actions.get(API_URLS.POOL.GET_ALL));
			},
			getById: async (id: string) => {
				return BaseConverter.convert<PollModelResponse[]>(await actions.get(API_URLS.POOL.GET(id)));
			},
			create: async data => {
				return BaseConverter.convert<Omit<PollModelResponse, "totalResponses">>(
					await actions.post(API_URLS.POOL.CREATE, convertPoolFormRequest(data))
				);
			},
			answer: async ({
				answerIds,
				pollId,
				type = "submit"
			}: {
				answerIds: number[];
				pollId: string;
				type?: "submit" | "unsubmit";
			}) => {
				return BaseConverter.convert<{ success: boolean }>(
					await actions.put(API_URLS.POOL.ANSWER, {
						type,
						answerIds,
						pollId
					})
				);
			}
		}),
		[actions]
	);
};

export default usePollApiService;
