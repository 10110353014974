import React from "react";

import Skeleton from "@material-ui/lab/Skeleton";

import { ReactComponent as ManIllustration } from "assets/icons/man-icon.svg";
import ConfirmActionModal from "shared/Components/ConfirmActionModal";
import { useUser } from "shared/hooks";

import { BlockedUser } from "shared/types/UserModel";

import {
	BlockedUserAvatar,
	BlockedUserAvatarWrapper,
	BlockedUserInfo,
	BlockedUserJobTitle,
	BlockedUserName,
	BlockedUserRow,
	BlockedUserUnblockButton,
	BlockedUserWrapper,
	BlockedUsersEmpty,
	BlockedUsersEmptyText,
	Container
} from "./styles";

const initialConfirmDialogState = {
	isOpen: false,
	data: null
};

export function BlockedUsers() {
	const { getBlockedUsers, unblockUser, getData } = useUser();
	const { blockedUsers, loading, globalUserId } = getData();

	const [confirmDialog, setConfirmDialog] = React.useState<{ isOpen: boolean; data: BlockedUser | null }>(
		initialConfirmDialogState
	);

	const handleUnblockButtonClick = (blockedUser: BlockedUser) => {
		setConfirmDialog({ isOpen: true, data: blockedUser });
	};

	const handleConfirmAction = () => {
		setConfirmDialog(prevState => ({ ...prevState, isOpen: false }));
		const data = confirmDialog.data;
		if (data?.personaId) {
			unblockUser(data.personaId);
		}
	};

	React.useEffect(() => {
		if (globalUserId) {
			getBlockedUsers(globalUserId);
		}
	}, [getBlockedUsers, globalUserId]);

	return (
		<>
			<Container>
				<BlockedUserWrapper>
					{loading ? (
						<BlockedUserSkeleton />
					) : (
						<>
							{blockedUsers?.length > 0 ? (
								blockedUsers.map(blockedUser => (
									<BlockedUserRow key={blockedUser.personaId}>
										<BlockedUserAvatarWrapper>
											<BlockedUserAvatar src={blockedUser.profilePhoto} />
										</BlockedUserAvatarWrapper>
										<BlockedUserInfo>
											<BlockedUserName>{blockedUser.name}</BlockedUserName>
											<BlockedUserJobTitle>{blockedUser.jobTitle}</BlockedUserJobTitle>
										</BlockedUserInfo>
										<BlockedUserUnblockButton
											variant="contained"
											color="primary"
											onClick={() => handleUnblockButtonClick(blockedUser)}
										>
											Unblock
										</BlockedUserUnblockButton>
									</BlockedUserRow>
								))
							) : (
								<BlockedUsersEmpty>
									<ManIllustration />
									<BlockedUsersEmptyText>You don&apos;t have any blocked users yet.</BlockedUsersEmptyText>
								</BlockedUsersEmpty>
							)}
						</>
					)}
				</BlockedUserWrapper>
			</Container>
			<ConfirmActionModal
				open={confirmDialog.isOpen}
				onClose={() => setConfirmDialog(initialConfirmDialogState)}
				title="Unblock member"
				bodyText={`Are you sure you want to unblock ${confirmDialog.data?.name}? They will be able to see your profile, send you connection request and more.`}
				onConfirm={handleConfirmAction}
				confirmText="Unblock"
				reverseButtons
				confirmButtonTheme="danger"
				cancelButtonTheme="primary"
				closeText="Keep"
			/>
		</>
	);
}

function BlockedUserSkeleton() {
	return (
		<>
			{new Array(5).fill(0).map((_, index) => {
				return (
					<BlockedUserRow key={index}>
						<Skeleton variant="circle" width={48} height={48} />
						<Skeleton variant="text" width={118} style={{ marginLeft: "16px" }} />
						<Skeleton variant="rect" width={84} height={32} style={{ marginLeft: " auto" }} />
					</BlockedUserRow>
				);
			})}
		</>
	);
}
