import { useMemo } from "react";

import useHttpActions from "shared/services/api/core/useHttpActions";
import { BaseConverter, convertAnalytics } from "shared/services/converters";

import { API_URLS } from "../constants";
import {
	GetAnalyticsActiveUsers,
	GetAnalyticsDataType,
	GetNewUsersType,
	GetSignUpsType,
	GetSummaryType,
	SummaryMetricType,
	TopRefereeModel
} from "../types";

const useMarketingService = () => {
	const actions = useHttpActions();

	return useMemo(
		() => ({
			getSummary: async ({
				requestedFrom,
				requestedTo
			}: {
				requestedFrom: string;
				requestedTo: string;
			}): Promise<GetSummaryType> => {
				return BaseConverter.convert<GetSummaryType>(
					await actions.get(API_URLS.WORKSPACE_ANALYTICS.SUMMARY, {
						requestedFrom,
						requestedTo
					})
				);
			},
			getSummaryCSVFile: async ({
				requestedFrom,
				requestedTo
			}: {
				requestedFrom: string;
				requestedTo: string;
			}): Promise<string | undefined> => {
				return BaseConverter.convert<string | undefined>(
					await actions.get(API_URLS.WORKSPACE_ANALYTICS.SUMMARY_CSV, {
						requestedFrom,
						requestedTo
					})
				);
			},
			getActiveUsers: async ({
				requestedFrom,
				requestedTo
			}: {
				requestedFrom: string;
				requestedTo: string;
			}): Promise<GetAnalyticsActiveUsers> => {
				return BaseConverter.convert<GetAnalyticsActiveUsers>(
					await actions.get(API_URLS.WORKSPACE_ANALYTICS.ACTIVE_USERS, {
						requestedFrom,
						requestedTo
					})
				);
			},
			getActiveUsersCSVFile: async ({
				requestedFrom,
				requestedTo
			}: {
				requestedFrom: string;
				requestedTo: string;
			}): Promise<string | undefined> => {
				return BaseConverter.convert<string | undefined>(
					await actions.get(API_URLS.WORKSPACE_ANALYTICS.ACTIVE_USERS_CSV, {
						requestedFrom,
						requestedTo
					})
				);
			},
			getSignUps: async ({
				requestedFrom,
				requestedTo
			}: {
				requestedFrom: string;
				requestedTo: string;
			}): Promise<GetSignUpsType> => {
				return BaseConverter.convert<GetSignUpsType>(
					await actions.get(API_URLS.WORKSPACE_ANALYTICS.SIGN_UPS, {
						requestedFrom,
						requestedTo
					})
				);
			},
			getSignUpsCSVFile: async ({
				requestedFrom,
				requestedTo
			}: {
				requestedFrom: string;
				requestedTo: string;
			}): Promise<string | undefined> => {
				return BaseConverter.convert<string | undefined>(
					await actions.get(API_URLS.WORKSPACE_ANALYTICS.SIGN_UPS_CSV, {
						requestedFrom,
						requestedTo
					})
				);
			},
			getRevenue: async ({
				requestedFrom,
				requestedTo
			}: {
				requestedFrom: string;
				requestedTo: string;
			}): Promise<GetAnalyticsDataType<{ revenue: number }>> => {
				return BaseConverter.convert<GetAnalyticsDataType<{ revenue: number }>>(
					await actions.get(API_URLS.WORKSPACE_ANALYTICS.REVENUE, {
						requestedFrom,
						requestedTo
					})
				);
			},
			getSales: async ({
				requestedFrom,
				requestedTo
			}: {
				requestedFrom: string;
				requestedTo: string;
			}): Promise<GetAnalyticsDataType<{ sales: number }>> => {
				return BaseConverter.convert<GetAnalyticsDataType<{ sales: number }>>(
					await actions.get(API_URLS.WORKSPACE_ANALYTICS.SALES, {
						requestedFrom,
						requestedTo
					})
				);
			},
			getNewUsers: async ({
				requestedFrom,
				requestedTo
			}: {
				requestedFrom: string;
				requestedTo: string;
			}): Promise<GetNewUsersType> => {
				return BaseConverter.convert<GetNewUsersType>(
					await actions.get(API_URLS.WORKSPACE_ANALYTICS.NEW_USERS, {
						requestedFrom,
						requestedTo
					})
				);
			},
			getNewUsersCSVFile: async ({
				requestedFrom,
				requestedTo
			}: {
				requestedFrom: string;
				requestedTo: string;
			}): Promise<string | undefined> => {
				return BaseConverter.convert<string | undefined>(
					await actions.get(API_URLS.WORKSPACE_ANALYTICS.NEW_USERS_CSV, {
						requestedFrom,
						requestedTo
					})
				);
			},
			getTopReferees: async ({
				requestedFrom,
				requestedTo
			}: {
				requestedFrom: string;
				requestedTo: string;
			}): Promise<TopRefereeModel[]> => {
				return convertAnalytics(
					await actions.get(API_URLS.WORKSPACE_ANALYTICS.TOP_REFEREES, {
						startDate: requestedFrom,
						endDate: requestedTo
					})
				);
			},
			getTopRefereesCSVFile: async ({
				requestedFrom,
				requestedTo
			}: {
				requestedFrom: string;
				requestedTo: string;
			}): Promise<string | undefined> => {
				return BaseConverter.convert<string | undefined>(
					await actions.get(API_URLS.WORKSPACE_ANALYTICS.TOP_REFEREES_CSV, {
						startDate: requestedFrom,
						endDate: requestedTo
					})
				);
			},
			getMetrics: async (): Promise<SummaryMetricType> => {
				return BaseConverter.convert<SummaryMetricType>(await actions.get(API_URLS.WORKSPACE_ANALYTICS.METRICS));
			},
			getMetricsCSVFile: async (): Promise<string | undefined> => {
				return BaseConverter.convert<string | undefined>(await actions.get(API_URLS.WORKSPACE_ANALYTICS.METRICS_CSV));
			}
		}),
		[actions]
	);
};

export default useMarketingService;
