import styled from "styled-components";

import { Text } from "shared/ui-kit";

export const ReceivedOpen = styled(Text)`
	font-size: 13px;
	line-height: 1.23;
	color: #222b45;
	align-items: center;
	margin: 0;
`;

export const StyledNumbers = styled.span`
	color: #6173fe;
`;
