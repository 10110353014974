export const determineSkinTone = (ST: string): string => {
	if (ST.includes("medium_light")) {
		return "#e2be98";
	} else if (ST.includes("light")) {
		return "#fee6cd";
	} else if (ST.includes("medium_dark")) {
		return "#c69971";
	} else if (ST.includes("dark")) {
		return "#745b4d";
	} else {
		return "#ffd16d";
	}
};
