import { useCallback, useMemo } from "react";

import { DateTime } from "luxon";

import { useAnalyticsStore } from "shared/contexts";

import { useNotification } from "shared/hooks";

import { useAnalyticsApiService } from "../services";

const useAnalytics = () => {
	const store = useAnalyticsStore();
	const { setState } = useAnalyticsStore();

	const analyticsService = useAnalyticsApiService();
	const { showMessage } = useNotification();

	const convertDates = useCallback((requestedFrom: DateTime, requestedTo: DateTime) => {
		const dateFormat = "yyyy-MM-dd";
		const currentDate = DateTime.now();

		return {
			requestedFrom: requestedFrom.toFormat(dateFormat),
			requestedTo:
				currentDate.toFormat(dateFormat) === requestedTo.toFormat(dateFormat)
					? currentDate.plus({ day: 1 }).toFormat(dateFormat)
					: requestedTo.toFormat(dateFormat)
		};
	}, []);

	const methods = useMemo(
		() => ({
			getSummary: async (requestedFrom: DateTime, requestedTo: DateTime) => {
				const { summary } = await analyticsService.getSummary(convertDates(requestedFrom, requestedTo));
				setState({ summary });
			},
			getSummaryCSVFile: async (requestedFrom: DateTime, requestedTo: DateTime) => {
				setState({ downloading: true });

				try {
					return analyticsService.getSummaryCSVFile(convertDates(requestedFrom, requestedTo));
				} catch (err: any) {
					if (err.status !== 500) showMessage(err.message, 5, true);
					console.error(err);
				} finally {
					setState({ downloading: false });
				}
			},
			getActiveUsers: async (requestedFrom: DateTime, requestedTo: DateTime) => {
				const { summary, total } = await analyticsService.getActiveUsers(convertDates(requestedFrom, requestedTo));
				setState(ctx => ({ ...ctx, totalActiveUsers: total, activeUsersData: summary }));
			},
			getActiveUsersCSVFile: async (requestedFrom: DateTime, requestedTo: DateTime) => {
				setState({ downloading: true });

				try {
					return analyticsService.getActiveUsersCSVFile(convertDates(requestedFrom, requestedTo));
				} catch (err: any) {
					if (err.status !== 500) showMessage(err.message, 5, true);
					console.error(err);
				} finally {
					setState({ downloading: false });
				}
			},
			getSignUps: async (requestedFrom: DateTime, requestedTo: DateTime) => {
				const { byDay, total } = await analyticsService.getSignUps(convertDates(requestedFrom, requestedTo));
				setState(ctx => ({ ...ctx, totalSignUps: Number(total), signUpsData: byDay }));
			},
			getSignUpsCSVFile: async (requestedFrom: DateTime, requestedTo: DateTime) => {
				setState({ downloading: true });

				try {
					return analyticsService.getSignUpsCSVFile(convertDates(requestedFrom, requestedTo));
				} catch (err: any) {
					if (err.status !== 500) showMessage(err.message, 5, true);
					console.error(err);
				} finally {
					setState({ downloading: false });
				}
			},
			getRevenue: async (requestedFrom: DateTime, requestedTo: DateTime) => {
				const { total, byDay } = await analyticsService.getRevenue(convertDates(requestedFrom, requestedTo));
				setState(ctx => ({ ...ctx, totalRevenue: total.toString(), revenueData: byDay }));
			},
			getSales: async (requestedFrom: DateTime, requestedTo: DateTime) => {
				const { total, byDay } = await analyticsService.getSales(convertDates(requestedFrom, requestedTo));
				setState(ctx => ({ ...ctx, totalSales: Number(total), salesData: byDay }));
			},
			getNewUsers: async (requestedFrom: DateTime, requestedTo: DateTime) => {
				const { users } = await analyticsService.getNewUsers(convertDates(requestedFrom, requestedTo));
				setState(ctx => ({ ...ctx, newUsersData: users.monthly }));
			},
			getNewUsersCSVFile: async (requestedFrom: DateTime, requestedTo: DateTime) => {
				setState({ downloading: true });

				try {
					return analyticsService.getNewUsersCSVFile(convertDates(requestedFrom, requestedTo));
				} catch (err: any) {
					if (err.status !== 500) showMessage(err.message, 5, true);
					console.error(err);
				} finally {
					setState({ downloading: false });
				}
			},
			getTopReferees: async (requestedFrom: DateTime, requestedTo: DateTime) => {
				const topReferees = await analyticsService.getTopReferees(convertDates(requestedFrom, requestedTo));
				setState(ctx => ({ ...ctx, topReferees }));
			},
			getTopRefereesCSVFile: async (requestedFrom: DateTime, requestedTo: DateTime) => {
				setState({ downloading: true });

				try {
					return analyticsService.getTopRefereesCSVFile(convertDates(requestedFrom, requestedTo));
				} catch (err: any) {
					if (err.status !== 500) showMessage(err.message, 5, true);
					console.error(err);
				} finally {
					setState({ downloading: false });
				}
			},
			getMetrics: async () => {
				const summaryMetrics = await analyticsService.getMetrics();
				setState(ctx => ({ ...ctx, summaryMetrics }));
			},
			getMetricsCSVFile: async () => {
				setState({ downloading: true });

				try {
					return analyticsService.getMetricsCSVFile();
				} catch (err: any) {
					if (err.status !== 500) showMessage(err.message, 5, true);
					console.error(err);
				} finally {
					setState({ downloading: false });
				}
			}
		}),
		[setState, analyticsService, convertDates, showMessage]
	);

	const getData = useCallback(() => {
		return store;
	}, [store]);

	return { ...methods, getData };
};

export default useAnalytics;
