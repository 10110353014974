import React, { useMemo } from "react";

import { useIntegrations } from "modules/Integrations/Data/hooks";

import { useCommunity } from "shared/hooks";

import ShopifyCard from "../Cards/ShopifyCard";
import IntegrationCard from "../IntegrationCard";
import IntegrationRow from "../IntegrationRow";

const MyIntegrationsRow = () => {
	const { getData: getIntegrationsData } = useIntegrations();
	const { podcasts } = getIntegrationsData();

	const { getData: getCommunityData } = useCommunity();
	const { workspace } = getCommunityData();

	const myIntegrations = useMemo(
		() =>
			podcasts
				.filter(podcast => !!podcast.links.length)
				.map(podcast => <IntegrationCard noDialog showStatus content={podcast} key={podcast._id} />),
		[podcasts]
	);

	const shopifyIntegration = useMemo(() => {
		if (workspace?.shopifyDomain && workspace.storefrontAccessToken) {
			return <ShopifyCard connected />;
		}
	}, [workspace]);

	if (!podcasts.length || (!myIntegrations.length && !shopifyIntegration)) {
		return null;
	}

	return (
		<IntegrationRow title="My Integrations">
			{shopifyIntegration}
			{myIntegrations}
		</IntegrationRow>
	);
};

export default MyIntegrationsRow;
