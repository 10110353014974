import React from "react";

import { GoogleMap, Marker, withGoogleMap, withScriptjs } from "react-google-maps";
import { compose, withProps } from "recompose";

const EventMap = compose(
	withProps({
		googleMapURL: `https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${process.env.REACT_APP_GOOGLE_MAPS_KEY}`,
		loadingElement: <div style={{ height: "100%" }} />,
		containerElement: <div style={{ height: "300px" }} />,
		mapElement: <div style={{ height: "100%" }} />
	}),
	withScriptjs,
	withGoogleMap
)(({ lat, lng }) => (
	<GoogleMap defaultZoom={18} defaultCenter={{ lat, lng }}>
		<Marker position={{ lat, lng }} />
	</GoogleMap>
));

export default EventMap;
