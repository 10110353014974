import React from "react";

import { useUser } from "shared/hooks";

import { AddProfileInfoDialog } from "..";

const AddSkillsDialog = () => {
	const { setAddSkillsDialog, getData: getUserData } = useUser();
	const { skillsSuggestions } = getUserData();

	return (
		<AddProfileInfoDialog
			title="Skills"
			handleClose={() => setAddSkillsDialog(false)}
			list={skillsSuggestions}
			updateType="skills"
		/>
	);
};

export default AddSkillsDialog;
