import React from "react";

import CircularProgress from "@material-ui/core/CircularProgress";
import Autocomplete from "@material-ui/lab/Autocomplete";
import country from "country-list-js";
import { Controller, useForm } from "react-hook-form";

import { useAuth } from "modules/App/Data";

import { useNotification } from "shared/hooks";
import { PhoneRequestResponse } from "shared/types";

import { FormField, Label, PhoneCodeField, PhoneCodeInput, PhoneInput } from "./styles";

import { Form, FormActions, FormButton, FormContent, FormWrapper } from "../commonStyles";

interface CountryCodeOption {
	label: string;
	value: string;
}

const getCountryCodes = (): CountryCodeOption[] => {
	const countryCodes: CountryCodeOption[] = [];
	for (const countryKey in country.all) {
		const dialingCode = country.all[countryKey].dialing_code;
		const codeLabel = `${countryKey} ${dialingCode}`;
		if (dialingCode) {
			countryCodes.push({ label: codeLabel, value: String(country.all[countryKey].dialing_code) });
		}
	}
	return countryCodes.sort((a, b) => (a.label > b.label ? 1 : -1));
};

const countryCodes = getCountryCodes();

interface FormValues {
	code: string;
	number: string;
}

interface Props {
	onSubmit: (params: {
		codeValidationId: PhoneRequestResponse["codeValidationId"];
		codeExpiration: PhoneRequestResponse["codeExpiration"];
		phoneCode: string;
		phoneNumber: string;
	}) => void;
}

export const ChangePhoneForm: React.FC<Props> = ({ onSubmit }) => {
	const {
		control,
		handleSubmit,
		formState: { isValid, isSubmitting }
	} = useForm<FormValues>({ mode: "onChange" });
	const { requestPhone } = useAuth();
	const { showMessage } = useNotification();

	const onSubmitHandler = handleSubmit(async data => {
		try {
			const res = await requestPhone({
				phoneNumber: data.number,
				phoneCode: data.code,
				exists: true,
				deviceId: navigator.userAgent
			});
			onSubmit({
				codeValidationId: res.codeValidationId,
				codeExpiration: res.codeExpiration,
				phoneCode: data.code,
				phoneNumber: data.number
			});
		} catch (error) {
			showMessage("Phone number change failed");
			console.error(error);
		}
	});

	return (
		<FormWrapper>
			<Form onSubmit={onSubmitHandler}>
				<FormContent>
					<FormField>
						<Label>Code</Label>
						<Controller
							name="code"
							control={control}
							rules={{ required: "Field is required" }}
							render={({ onChange, value }) => (
								<Autocomplete
									onChange={(_, value) => onChange(value.value)}
									value={value}
									options={countryCodes}
									getOptionLabel={option => option.label}
									renderInput={params => (
										<PhoneCodeField ref={params.InputProps.ref}>
											<PhoneCodeInput type="text" placeholder="US +1" {...params.inputProps} />
										</PhoneCodeField>
									)}
								/>
							)}
						/>
					</FormField>
					<FormField style={{ flex: 1 }}>
						<Label>Phone</Label>
						<Controller
							name="number"
							control={control}
							rules={{ required: "Field is required" }}
							render={({ onChange, value }) => <PhoneInput onChange={onChange} value={value} type="tel" />}
						/>
					</FormField>
				</FormContent>
				<FormActions>
					<FormButton buttonTheme="light" disabled={!isValid || isSubmitting} fullWidth type="submit">
						{isSubmitting ? <CircularProgress size={20} /> : "Continue"}
					</FormButton>
				</FormActions>
			</Form>
		</FormWrapper>
	);
};
