import React, { memo, useEffect } from "react";

import { useGroup, useUser } from "shared/hooks";

import { ItemWrapper, ListWrapper } from "./style";

import { BoxItem, GroupCard, SeeAllBtn, Slider } from "../index";

interface GroupsProps {
	title?: string;
	fetchMyGroups?: boolean;
	groupsPageUrl: string;
}

const Groups: React.FC<GroupsProps> = memo(({ title, fetchMyGroups, groupsPageUrl }) => {
	const { getPaginatedGroup, getTotalGroupsCount, getData: getGroupData } = useGroup();
	const { totalFoundGroups, groups } = getGroupData();

	const { getPotentialMatchIds, getData: getUserData } = useUser();
	const { user } = getUserData();

	useEffect(() => {
		getPaginatedGroup({
			name: "",
			limit: 5,
			page: 1,
			groupRelation: "popular",
			potentialMatchingIds: getPotentialMatchIds(user),
			filterBy: fetchMyGroups ? ["member"] : undefined
		});
	}, [getPaginatedGroup, getPotentialMatchIds, user, fetchMyGroups]);

	useEffect(() => {
		getTotalGroupsCount({});
	}, [getTotalGroupsCount]);

	if (!totalFoundGroups) return null;

	return (
		<BoxItem title={title || "Popular groups"}>
			<ListWrapper customPadding="0 0 12px 16px">
				<Slider slickPadding count={(groups || []).length} itemSize={360}>
					{(groups || []).map((group, index) => (
						<ItemWrapper key={index}>
							<GroupCard group={group} index={index} idPrefix={title || "Popular groups"} />
						</ItemWrapper>
					))}
				</Slider>
			</ListWrapper>
			{totalFoundGroups > 10 && <SeeAllBtn link={groupsPageUrl} />}
		</BoxItem>
	);
});

export default Groups;
