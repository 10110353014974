import styled from "styled-components";

import { Button } from "shared/ui-kit";

const SubmitButton = styled(Button)`
	height: 48px;
	flex-shrink: 0;
	${props =>
		`margin: ${props.WithTopMargin ? 16 : 0}px ${props.WithRightMargin ? 9 : 0}px 0px ${
			props.WithLeftMargin ? 20 : 0
		}px;`};
`;

export default SubmitButton;
