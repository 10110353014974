import React, { FC, useEffect, useState } from "react";

import { InputAdornment, TextField } from "@material-ui/core";

import { DateTime } from "luxon";

import { Controller, useForm } from "react-hook-form";

import { css } from "styled-components";

import { ReactComponent as DollarIcon } from "assets/icons/iconDollarFilled.svg";

import { Dialog } from "shared/Components";
import { useS3Uploader } from "shared/services/s3Uploader";
import { Icon, Loader, Text, Uploader, orientationConst } from "shared/ui-kit";

import { convertToNumberFormat } from "utils/serviceUtils/helpers";

import {
	ComponentGroup,
	CreateButton,
	DateWrapper,
	FormControl,
	NarrowPicker,
	NarrowTimePicker,
	TimeWrapper,
	UploaderWrapper
} from "./styles";

export interface RequestMoneyViewProps {
	open: boolean;
	onClose: () => void;
	onSubmit: (data: any) => Promise<void>;
	loading: boolean;
	onUploaderChange: (files: FileList | any[], onChange: (...event: any[]) => void) => Promise<void>;
}
const RequestMoneyManageView: FC<RequestMoneyViewProps> = ({ open, onClose, onSubmit, loading, onUploaderChange }) => {
	const { getData: getS3UploaderData } = useS3Uploader();
	const { loadingSignedUrl, uploading } = getS3UploaderData();

	const { handleSubmit, formState, control, errors, trigger, watch } = useForm({ mode: "onChange" });

	const { dueDate } = watch(["dueDate"]);

	useEffect(() => {
		trigger(["dueTime"]);
	}, [dueDate, trigger]);

	const [isStartDateOpen, setIsStartDateOpen] = useState(false);
	const [isStartTimeOpen, setIsStartTimeOpen] = useState(false);

	return (
		<Dialog
			title={<Text variant="h7">Request Money</Text>}
			open={open}
			onClose={onClose}
			footer={
				<CreateButton
					buttonTheme="main"
					onClick={handleSubmit(onSubmit)}
					leftIcon={
						loading || uploading || loadingSignedUrl ? (
							<Loader size="1rem" show={true} color="primary" variant="indeterminate" />
						) : undefined
					}
					disabled={!formState.isDirty || !formState.isValid || uploading || loadingSignedUrl || loading}
				>
					Done
				</CreateButton>
			}
			bodyCustomStyles={css`
				position: relative;
			`}
		>
			<form onSubmit={handleSubmit(onSubmit)}>
				<FormControl>
					<UploaderWrapper>
						<Controller
							name="imageUrl"
							control={control}
							render={({ onChange }) => (
								<Uploader
									onChange={file => onUploaderChange(file, onChange)}
									label="Upload Payment Photo"
									orientation={orientationConst.horizontal}
									width={"100%"}
									description={
										<>
											Drag and Drop File Here or <span className="anchor">Browse</span> to Choose a File
										</>
									}
									accept={[
										{
											fileType: "image/png, image/jpeg, image/x-eps",
											name: "png, jpg, eps"
										}
									]}
									icon={
										<Icon
											group={""}
											fill={"#c5cee0"}
											name={"cloud-upload"}
											width={64}
											height={50}
											viewBox={"4 2 18 19"}
										/>
									}
								/>
							)}
						/>
					</UploaderWrapper>
				</FormControl>
				<FormControl>
					<Controller
						name="amountPaid"
						control={control}
						rules={{
							required: "Amount is required",
							min: { value: 0, message: "Amount cannot be negative" },
							max: { value: 99999, message: "Amount cannot be higher than $99,999" }
						}}
						render={({ onChange, value }) => (
							<TextField
								value={value}
								onChange={e => onChange(convertToNumberFormat(e.target.value).slice(0, 5))}
								placeholder="Amount"
								variant="outlined"
								required
								InputProps={{
									type: "text",
									startAdornment: (
										<InputAdornment position="start">
											<DollarIcon />
										</InputAdornment>
									)
								}}
								error={errors?.amountPaid?.message}
								helperText={errors?.amountPaid?.message}
							/>
						)}
					/>
				</FormControl>
				<FormControl>
					<Controller
						name="purpose"
						control={control}
						rules={{
							required: "Reason field is required",
							minLength: { value: 3, message: "At least 3 characters are required" }
						}}
						render={({ onChange, value }) => (
							<TextField
								label="What is this for?"
								value={value}
								onChange={onChange}
								error={errors.purpose?.message}
								helperText={errors.purpose?.message}
								variant="outlined"
								required
							/>
						)}
					/>
				</FormControl>
				<FormControl component={ComponentGroup}>
					<DateWrapper>
						<Controller
							name="dueDate"
							control={control}
							rules={{ required: "Date is required." }}
							render={({ onChange, value }) => (
								<NarrowPicker
									disableToolbar={true}
									autoOk
									inputVariant="outlined"
									variant="inline"
									value={value || null}
									isSelectedDate={value}
									emptyLabel="Due date"
									label="Due date"
									onChange={onChange}
									disablePast={true}
									open={isStartDateOpen}
									onClose={() => setIsStartDateOpen(!isStartDateOpen)}
									onClick={() => setIsStartDateOpen(!isStartDateOpen)}
									InputAdornmentProps={{ position: "start" }}
									keyboardIcon={<Icon name="calendar" group="filled" fill={"#8f9bb3"} />}
								/>
							)}
						/>
					</DateWrapper>
					<TimeWrapper>
						<Controller
							name="dueTime"
							control={control}
							rules={{
								required: "Time is required.",
								validate: val => {
									if (dueDate) {
										const luxonDueDate = DateTime.fromJSDate(dueDate);
										if (luxonDueDate.hasSame(DateTime.local(), "day") && val < DateTime.now()) {
											return "Due time cannot be in the past";
										}
									}
									return true;
								}
							}}
							render={({ onChange, value }) => (
								<NarrowTimePicker
									disableToolbar={true}
									autoOk
									inputVariant="outlined"
									variant="inline"
									value={value || null}
									isSelectedDate={value}
									emptyLabel="Select Time"
									label="Select Time"
									allowKeyboardControl={false}
									onChange={onChange}
									disablePast={true}
									open={isStartTimeOpen}
									onClose={() => setIsStartTimeOpen(!isStartTimeOpen)}
									onClick={() => setIsStartTimeOpen(!isStartTimeOpen)}
									InputAdornmentProps={{ position: "start" }}
									error={!!errors?.dueTime?.message}
									helperText={errors?.dueTime?.message}
									keyboardIcon={<Icon name="clock" group="filled" fill={"#8f9bb3"} />}
								/>
							)}
						/>
					</TimeWrapper>
				</FormControl>
			</form>
		</Dialog>
	);
};

export default RequestMoneyManageView;
