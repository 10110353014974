import styled from "styled-components";

import { Box, FormContainer, Text } from "shared/ui-kit";

export const PageWrapper = styled.section`
	width: 100%;

	padding: 0 25px;
`;

export const ContentWrapper = styled(FormContainer)`
	width: 100%;

	padding: 0;

	${props => props.theme.breakpoints.down("sm")} {
		margin: 10px 0;
	}

	.MuiGrid-root {
		${props => props.theme.breakpoints.down("sm")} {
			padding: 0;
			margin-left: 0;
			margin-right: 0;
			width: 100%;
		}
	}
`;

export const Title = styled(Text)`
	width: 100%;
	position: relative;

	margin-bottom: 20px;

	${props => props.theme.breakpoints.down("sm")} {
		margin-bottom: 10px;
	}
`;

export const BlockWrapper = styled(Box)`
	background: #ffffff;
	min-height: 290px;
	width: 100%;

	border-radius: 4px;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
	box-shadow: inset 0 0 0 0.5px #c5cee0;
`;
