import React, { FC } from "react";

import { SkeletonBase } from "shared/Components/index";

import { FlexContainer } from "./style";

const PaginationLoader: FC = () => (
	<FlexContainer className="between full">
		<FlexContainer>
			<SkeletonBase width={32} height={32} fullScale className="page-card" />
			<SkeletonBase width={32} height={32} fullScale className="page-card" />
			<SkeletonBase width={32} height={32} fullScale className="page-card" />
			<SkeletonBase width={32} height={32} fullScale className="page-card" />
			<SkeletonBase width={32} height={32} fullScale className="page-card" />
			<SkeletonBase width={32} height={32} fullScale className="page-card" />
		</FlexContainer>
		<FlexContainer className={"flex-end"}>
			<SkeletonBase width={70} height={32} fullScale className="page-size-card" />
			<SkeletonBase width={130} height={16} fullScale />
		</FlexContainer>
	</FlexContainer>
);

export default PaginationLoader;
