import React, { FC, useCallback, useMemo, useRef } from "react";

import { Grid } from "@material-ui/core";
import LocationOnIcon from "@material-ui/icons/LocationOn";

import { DateTime } from "luxon";

import { Controller, useForm } from "react-hook-form";

import timezones from "timezones-list";

import { ReactComponent as LocationIcon } from "assets/icons/location_filled.svg";
import { EnableFeature, MemberDialog, MultichoiceBoxesInput, RectangleUploader } from "modules/MemberHome/View/shared";
import { InputWrapper, MemberDialogContentWrapper } from "modules/MemberHome/View/shared/style";

import { SmartInput, SmartInputWrapper } from "shared/Components";
import { useCommunity, useEvent, useFeature, useUser } from "shared/hooks";
import { useS3Uploader } from "shared/services/s3Uploader";
import { Icon, Text } from "shared/ui-kit";

import { filterLocationOptions } from "utils/filterLocationOptions";
import { getDefaultDate, getDefaultTime } from "utils/getDefaultTime";
import { getUserTimezone } from "utils/serviceUtils/helpers";
import { validateURL } from "utils/serviceUtils/validators";

import { CreateEventProps } from "./ManageView";

import { ErrorText, InputsRowWrapper, OptionsHeadline } from "./style";

import { Location } from "../../types";

const CreateEventMemberView: FC<CreateEventProps> = ({
	open,
	onClose,
	onUploaderChange,
	handleCreateEvent,
	checkValidDate,
	editableModel
}) => {
	const time = DateTime.now();
	const startDateTime = time.plus({ hours: 1 });
	const endDateTime = time.plus({ hours: 2 });

	const { communityColors } = useCommunity();

	const color = useMemo(() => communityColors.primary || "6173fe", [communityColors.primary]);

	const eventTypes = useMemo(
		() => [
			{
				icon: <Icon name="globe" group="linear" fill={color} />,
				title: "Online",
				value: "online"
			},
			{
				icon: <Icon name="user-friend" group="filled" fill={color} />,
				title: "In person",
				value: "inperson"
			}
		],
		[color]
	);

	const { loadSuggestedLocations, getData: getEventData } = useEvent();
	const { categories, suggestedLocations, creating } = getEventData();

	const { isFeatureEnabled } = useFeature();

	const categoryOptions = useMemo(
		() =>
			categories.map(({ sort_name, name }) => ({
				label: name,
				value: sort_name
			})),
		[categories]
	);

	const {
		control,
		handleSubmit,
		watch,
		formState: { isDirty, errors, isValid },
		setError,
		clearErrors,
		setValue
	} = useForm<any>({
		mode: "onChange",
		defaultValues: {
			eventImage: !!editableModel?.eventImages.length ? editableModel.eventImages[0] : undefined,
			title: editableModel?.title,
			type: editableModel ? (editableModel?.isOnline ? "online" : "inperson") : undefined,
			liveconversation: !!editableModel?.liveConversationId,
			link: editableModel?.onlineUrl,
			startDate: editableModel ? editableModel.startTime : startDateTime,
			startTime: editableModel ? editableModel.startTime : startDateTime.toJSDate(),
			endDate: editableModel ? editableModel.endTime : endDateTime,
			endTime: editableModel ? editableModel.endTime : endDateTime.toJSDate(),
			description: editableModel?.description,
			chat: editableModel?.chatEnabled,
			category: editableModel ? editableModel?.categories[0] : undefined,
			location: editableModel?.location,
			attendees: !!editableModel?.attendLimit,
			attendeesNumber: `${editableModel?.attendLimit}`,
			worldwide: editableModel?.isNational
		}
	});

	const memberDialogContentRef = useRef<null | HTMLDivElement>(null);

	const { getData: getS3UploaderData } = useS3Uploader();
	const { uploading } = getS3UploaderData();

	const { type, liveconversation, attendees, startTime, startDate, endTime, endDate } = watch([
		"type",
		"liveconversation",
		"attendees",
		"startTime",
		"startDate",
		"endTime",
		"endDate"
	]);

	const { getData: getUserData } = useUser();
	const { isMemberView } = getUserData();

	const isOnlineEvent = useMemo(() => (type ? type === "online" : true), [type]);

	const filteredTimezones = useMemo(() => timezones.map(tz => ({ label: tz.label, value: tz.tzCode })), []);

	const attendeesLimit = [...Array.from(Array(100).keys())].map((_, i) => ({
		value: `${(i + 1) * 5}`,
		label: `${(i + 1) * 5}`
	}));

	const onSubmit = useCallback(
		data => {
			if (!isOnlineEvent && !data.location) {
				setError("location", {
					message: "Please choose a location option"
				});
				return;
			} else if (isOnlineEvent && !!data.location && !!errors?.location) {
				clearErrors("location");
			}

			handleCreateEvent({
				data: {
					...editableModel,
					...data,
					isMemberView,
					eventId: editableModel?.eventId,
					admins: editableModel?.admins?.map(admin => admin.personaId)
				},
				startTime,
				endTime,
				isOnlineEvent,
				liveconversation,
				isUpdate: !!editableModel
			});
		},
		[
			isOnlineEvent,
			errors?.location,
			editableModel,
			setError,
			clearErrors,
			isMemberView,
			startTime,
			endTime,
			liveconversation,
			handleCreateEvent
		]
	);

	const customHandleSubmit = useCallback(
		e => {
			e?.preventDefault && e.preventDefault();
			e?.stopPropagation && e.stopPropagation();
			handleSubmit(onSubmit)();
		},
		[handleSubmit, onSubmit]
	);

	const isValidDate = useMemo(() => checkValidDate(startTime, endTime), [startTime, endTime, checkValidDate]);

	const handleEditTime = (field: string, d: Date | DateTime | string | any, onChange: (val: Date) => void) => {
		onChange(d);

		if (field === "startDate") {
			if (d instanceof Date && !isNaN(d?.getTime())) {
				const luxonStartTime = startTime?.isLuxonDateTime ? startTime : DateTime.fromJSDate(new Date(startTime));
				if (luxonStartTime.isValid) {
					const newVal = DateTime.fromJSDate(d).set({
						hour: luxonStartTime.hour,
						minute: luxonStartTime.minute
					});
					setValue("startTime", newVal.toJSDate());

					if (endDate < d) {
						setValue("endDate", d);
						setValue(
							"endTime",
							DateTime.fromJSDate(d)
								.set({
									hour: luxonStartTime.hour + 1,
									minute: luxonStartTime.minute
								})
								.toJSDate()
						);
					}
				}
			}
		}

		if (field === "startTime") {
			let newEndVal = d;
			let newDateTime = startDate?.isLuxonDateTime ? startDate : DateTime.fromJSDate(new Date(startDate));

			if (newEndVal instanceof Date) {
				newDateTime = newDateTime.set({ hour: newEndVal.getHours(), minute: newEndVal.getMinutes() });
				newEndVal = newDateTime.plus({ hour: 1 });

				onChange(newDateTime.toJSDate());
			}
			if (newEndVal?.toJSDate) {
				setValue("endDate", newEndVal.toJSDate());
				setValue("endTime", newEndVal.toJSDate());
			}
		}

		if (field === "endDate" && d instanceof Date && !isNaN(d?.getTime())) {
			const endDateLuxon = DateTime.fromJSDate(new Date(d));
			const endTimeLuxon = DateTime.fromJSDate(new Date(endTime));
			if (endTimeLuxon.isValid) {
				endDateLuxon.set({ hour: endTimeLuxon.hour, minute: endTimeLuxon.minute });
				setValue("endTime", endDateLuxon.toJSDate());
			}
		}

		if (field === "endTime") {
			const newEndVal = d;
			let newDateTime = endDate?.isLuxonDateTime ? endDate : DateTime.fromJSDate(new Date(endDate));
			if (newEndVal instanceof Date) {
				newDateTime = newDateTime.set({ hour: newEndVal.getHours(), minute: newEndVal.getMinutes() });
				onChange(newDateTime.toJSDate());
			}
		}
	};

	return (
		<MemberDialog
			customWidth={512}
			title={`${!!editableModel ? "Update" : "Create"} an event`}
			open={open}
			onClose={() => onClose({ created: false }, "")}
			confirmLeave={isDirty}
			modalHeight={800}
			footerPrimary={{
				text: `${!!editableModel ? "Update" : "Create"}`,
				disabled: creating || !isDirty || !isValidDate || !isValid || uploading,
				loading: creating || uploading,
				id: "createEvent",
				onClick: handleSubmit(onSubmit)
			}}
		>
			<form onSubmit={customHandleSubmit}>
				<Controller
					name="eventImage"
					control={control}
					render={({ value, onChange }) => (
						<RectangleUploader
							defaultPreviewUrl={value}
							uploading={uploading}
							onChange={file => onUploaderChange(file, onChange)}
						/>
					)}
				/>
				<MemberDialogContentWrapper className="pv-20" ref={memberDialogContentRef}>
					<SmartInputWrapper>
						<Controller
							name="title"
							control={control}
							rules={{
								required: "Event name is required",
								minLength: { value: 3, message: "At least 3 characters are required" },
								maxLength: {
									value: 40,
									message: "Event name should not exceed 40 characters."
								}
							}}
							render={({ onChange, value, ref }) => (
								<SmartInput
									id="eventTitle"
									headline={"Event name *"}
									value={value}
									onChange={onChange}
									placeholder=" "
									error={!!errors?.title?.message}
									errorText={`${errors?.title?.message}`}
									showCounter={40}
									inputRef={ref}
								/>
							)}
						/>
					</SmartInputWrapper>
					<Controller
						name="type"
						control={control}
						rules={{
							required: "Event type is required"
						}}
						defaultValue="online"
						render={({ onChange, value }) => (
							<InputWrapper>
								<MultichoiceBoxesInput
									options={eventTypes}
									label="Event type *"
									value={value}
									onChange={onChange}
									error={errors?.type?.message}
									primaryColor={color}
								/>
							</InputWrapper>
						)}
					/>
					{isOnlineEvent ? (
						<>
							<Controller
								name="liveconversation"
								control={control}
								render={({ onChange, value }) => (
									<InputWrapper>
										<EnableFeature
											switchId="eventLiveConversation"
											icon={<Icon name="video" group="filled" width={20} fill="#c5cee0" />}
											title="Live conversation"
											onChange={onChange}
											value={value}
										/>
									</InputWrapper>
								)}
							/>
							{!liveconversation && (
								<SmartInputWrapper>
									<Controller
										name="link"
										control={control}
										rules={{
											required: "Event link is required.",
											validate: value => {
												if (!validateURL(value)) {
													return "Incorrect Url";
												}
											}
										}}
										render={({ onChange, value, ref }) => (
											<SmartInput
												id="eventLink"
												headline={"Event link *"}
												value={value}
												onChange={onChange}
												placeholder="https://"
												error={!!errors?.link?.message}
												errorText={`${errors?.link?.message}`}
												inputRef={ref}
											/>
										)}
									/>
								</SmartInputWrapper>
							)}
						</>
					) : (
						<SmartInputWrapper>
							<Controller
								name="location"
								control={control}
								rules={{ required: "Location is required" }}
								render={({ onChange, value, ref }) => (
									<SmartInput
										search
										id="eventLocation"
										headline={"Location*"}
										value={value}
										onChange={(newValue: Location | null) => onChange(newValue)}
										error={!!errors?.location?.message}
										errorText={`${errors?.location?.message}`}
										getOptionLabel={option => (typeof option === "string" ? option : option.name)}
										filterOptions={filterLocationOptions()}
										freeSolo
										options={suggestedLocations}
										includeInputInList
										filterSelectedOptions
										onInputChange={(_, newInputValue) => loadSuggestedLocations(newInputValue)}
										customDropdownItemRender={option => (
											<Grid container alignItems="center" id={`eventLocation${option.name}`}>
												<Grid item>
													<LocationOnIcon />
												</Grid>
												<Grid item xs>
													{option.name}
													<Text variant="body2" color="textSecondary">
														{option.vicinity}
													</Text>
												</Grid>
											</Grid>
										)}
										selectInputRef={ref}
									/>
								)}
							/>
						</SmartInputWrapper>
					)}
					<SmartInputWrapper>
						<Controller
							name="timezone"
							control={control}
							defaultValue={getUserTimezone()}
							rules={{ required: "Timezone is required" }}
							render={({ onChange, value, ref }) => (
								<SmartInput
									search
									id="timezone"
									headline={"Timezone *"}
									placeholder=" "
									value={value}
									options={filteredTimezones}
									onChange={onChange}
									error={!!errors?.timezone?.message}
									errorText={`${errors?.timezone?.message}`}
									// maxHeight={220}
									selectInputRef={ref}
								/>
							)}
						/>
					</SmartInputWrapper>
					<InputsRowWrapper>
						<SmartInputWrapper>
							<Controller
								name="startDate"
								control={control}
								defaultValue={getDefaultDate()}
								render={({ onChange, value, ref }) => (
									<SmartInput
										id="eventStartDate"
										calendar
										headline={"Start date *"}
										disablePast
										value={value}
										onChange={d => handleEditTime("startDate", d, onChange)}
										error={!!errors?.startDate?.message}
										errorText={`${errors?.startDate?.message}`}
										inputRef={ref}
									/>
								)}
							/>
						</SmartInputWrapper>
						<SmartInputWrapper>
							<Controller
								name="startTime"
								control={control}
								defaultValue={getDefaultTime()}
								render={({ value, onChange, ref }) => (
									<SmartInput
										id="eventStartTime"
										time
										headline={"Start time *"}
										placeholder="12:00 pm"
										value={value}
										onChange={d => handleEditTime("startTime", d, onChange)}
										error={!!errors?.startTime?.message}
										errorText={`${errors?.startTime?.message}`}
										selectInputRef={ref}
									/>
								)}
							/>
						</SmartInputWrapper>
					</InputsRowWrapper>
					<InputsRowWrapper>
						<SmartInputWrapper>
							<Controller
								name="endDate"
								control={control}
								defaultValue={getDefaultDate(true)}
								render={({ onChange, value, ref }) => (
									<SmartInput
										id="eventEndDate"
										calendar
										headline="End date *"
										disablePast
										minDate={startDate}
										value={value}
										onChange={d => handleEditTime("endDate", d, onChange)}
										error={!!errors?.endDate?.message}
										errorText={`${errors?.endDate?.message}`}
										inputRef={ref}
									/>
								)}
							/>
						</SmartInputWrapper>
						<SmartInputWrapper>
							<Controller
								name="endTime"
								control={control}
								defaultValue={getDefaultTime(true)}
								render={({ value, onChange, ref }) => (
									<SmartInput
										id="eventEndTime"
										time
										headline="End time *"
										placeholder="12:00 pm"
										value={value}
										onChange={d => handleEditTime("endTime", d, onChange)}
										error={!!errors?.endTime?.message}
										errorText={`${errors?.endTime?.message}`}
										selectInputRef={ref}
									/>
								)}
							/>
						</SmartInputWrapper>
					</InputsRowWrapper>
					{!isValidDate && (
						<ErrorText className="mb-5">
							Date should not be before current time and End time should be after start time
						</ErrorText>
					)}
					<SmartInputWrapper>
						<Controller
							name="description"
							control={control}
							rules={{
								required: "Description is required",
								maxLength: {
									value: 1000,
									message: "Event description should not exceed 1000 characters."
								}
							}}
							render={({ onChange, value, ref }) => (
								<SmartInput
									id="eventDescription"
									multipleRows={3}
									minRows={3}
									headline={"Event Description *"}
									value={value}
									onChange={onChange}
									error={!!errors?.description?.message}
									errorText={`${errors?.description?.message}`}
									inputRef={ref}
									showCounter={1000}
								/>
							)}
						/>
					</SmartInputWrapper>
					<SmartInputWrapper>
						<Controller
							name="category"
							control={control}
							rules={{
								required: "Event category is required"
							}}
							render={({ onChange, value, ref }) => (
								<SmartInput
									dropdown
									id="category"
									optionId={"category_"}
									headline={"Event Category *"}
									value={value}
									options={categoryOptions}
									onChange={onChange}
									error={!!errors?.category?.message}
									errorText={`${errors?.category?.message}`}
									selectInputRef={ref}
								/>
							)}
						/>
					</SmartInputWrapper>
					<OptionsHeadline>Options</OptionsHeadline>
					<Controller
						name="chat"
						control={control}
						render={({ onChange, value }) => (
							<InputWrapper>
								<EnableFeature
									switchId="comment"
									icon={<Icon name="comment" width={24} height={24} fill="#c5cee0" />}
									title="Event chat"
									onChange={onChange}
									value={value}
								/>
							</InputWrapper>
						)}
					/>
					{!isOnlineEvent && (
						<>
							<Controller
								name="attendees"
								control={control}
								render={({ onChange, value }) => (
									<InputWrapper mb={attendees && "10px"}>
										<EnableFeature
											switchId="attendees"
											fill="#c5cee0"
											icon={<Icon name="user-friend" group="filled" fill="#c5cee0" />}
											title="Attendees limit"
											onChange={onChange}
											value={value}
										/>
									</InputWrapper>
								)}
							/>
							{attendees && (
								<SmartInputWrapper>
									<Controller
										name="attendeesNumber"
										control={control}
										rules={{
											required: "Attendees Limit is required!"
										}}
										render={({ onChange, value }) => (
											<SmartInput
												dropdown
												id="attendeesNumber"
												headline={"Attendee limit"}
												value={value}
												options={attendeesLimit}
												onChange={onChange}
												error={!!errors?.attendeesNumber?.message}
												errorText={`${errors?.attendeesNumber?.message}`}
											/>
										)}
									/>
								</SmartInputWrapper>
							)}
							{isFeatureEnabled("enableDistanceLimitation") && (
								<Controller
									name="worldwide"
									control={control}
									render={({ onChange, value }) => (
										<InputWrapper>
											<EnableFeature
												switchId="Worldwide"
												fill="#c5cee0"
												icon={<LocationIcon />}
												title="Worldwide"
												description="Enable to make this event visible to members everywhere."
												onChange={onChange}
												value={value}
												verticalPadding
											/>
										</InputWrapper>
									)}
								/>
							)}
						</>
					)}
					{/* TODO: We don't have support for private events yet */}
					{/* <Controller
						name="private"
						control={control}
						render={({ onChange, value }) => (
							<InputWrapper>
								<EnableFeature
									icon={<Icon name="eye" group="filled" width={24} height={24} fill="#c5cee0" />}
									title="Private"
									description="Only invited members will be able 
								to find this event"
									onChange={onChange}
									value={value}
									verticalPadding
								/>
							</InputWrapper>
						)}
					/> */}
				</MemberDialogContentWrapper>
			</form>
		</MemberDialog>
	);
};

export default CreateEventMemberView;
