/* eslint-disable react/no-unescaped-entities */
import React, { useCallback, useEffect, useState } from "react";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { DateTime } from "luxon";
import * as R from "ramda";

import { MenuDots } from "shared/Components";
import { useCommunity } from "shared/hooks";

import { WorkspaceProductModel } from "shared/types";
import { Icon, Switch } from "shared/ui-kit";

import { Card, CardList, EmptyCard, ToggleWrapper } from "./style";

import { NewSubscriptionPlanModal } from "../index";

export interface SubscriptionPlansProps {
	subscriptionAndPremium?: boolean;
}

export const SubscriptionPlans: React.FC<SubscriptionPlansProps> = ({ subscriptionAndPremium }) => {
	const { getSubscriptionProducts, updateSubscriptionProduct, getData: getCommunityData } = useCommunity();
	const { subscriptionProducts } = getCommunityData();

	const [isAnnualPrices, setIsAnnualPrices] = useState(false);
	const [changableProduct, setChangableProduct] = useState<{
		type: "view" | "edit" | "create";
		product?: WorkspaceProductModel;
	}>();

	useEffect(() => {
		getSubscriptionProducts();
	}, [getSubscriptionProducts]);

	const optionList = useCallback((product: WorkspaceProductModel, index: number) => {
		const options = [
			{
				name: "Edit",
				onClick: () => setChangableProduct({ type: "edit", product }),
				id: `editPlan${index + 1}`
			}
		];
		return options;
	}, []);

	const toggleActive = useCallback(
		(active: boolean, product: WorkspaceProductModel) => {
			updateSubscriptionProduct({
				...R.pick(["name", "description", "type", "subscriptionId", "freeTrailDays", "freeTrailEnabled"], product),
				annually: {
					_id: product?.annually?._id,
					price: product.annually?.price,
					active
				},
				monthly: {
					_id: product?.monthly?._id,
					price: product.monthly?.price,
					active
				}
			});
		},
		[updateSubscriptionProduct]
	);

	return (
		<>
			{!!subscriptionProducts.length && (
				<ToggleWrapper>
					<Switch
						conditional
						leftLabel={"Monthly"}
						rightLabel={"Annually"}
						checked={isAnnualPrices}
						onChange={el => {
							setIsAnnualPrices(el.target.checked);
						}}
						id="monthlyAnnually"
					/>
				</ToggleWrapper>
			)}
			<CardList>
				{subscriptionProducts.map((product, i) => (
					<Card.Wrapper key={i}>
						<Card.Content>
							<Card.Body>
								<Card.Header>
									<Card.Name>{product.name}</Card.Name>
									<Card.DropdownWrapper>
										<Card.Select
											IconComponent={ExpandMoreIcon}
											onChange={e => toggleActive(e.target.value, product)}
											value={isAnnualPrices ? product.annually?.active : product.monthly?.active}
											id={`planStatus_${i + 1}`}
										>
											<Card.SelectItem className="menuItem" value={true} id={`planStatus_active_${i + 1}`}>
												<Card.SelectItemIcon className={"success"} />
												<Card.SelectItemText>Active</Card.SelectItemText>
											</Card.SelectItem>
											<Card.SelectItem className="menuItem" value={false} id={`planStatus_inActive_${i + 1}`}>
												<Card.SelectItemIcon />
												<Card.SelectItemText>Inactive</Card.SelectItemText>
											</Card.SelectItem>
										</Card.Select>
									</Card.DropdownWrapper>
								</Card.Header>
								<Card.Price>${isAnnualPrices ? product.annually?.price : product.monthly?.price}</Card.Price>
								<Card.Description>{product.description}</Card.Description>
								{product?.freeTrailEnabled && (
									<>
										<Card.DesciprionNote>Free Trial for {product.freeTrailDays} days</Card.DesciprionNote>
									</>
								)}
								{product.activeAt && (
									<Card.CreatedDate>
										Created {DateTime.fromISO(product.activeAt).toFormat("MMM d, yyyy")}
									</Card.CreatedDate>
								)}
								<Card.Subscribers>
									Subscribers: {isAnnualPrices ? product.annually?.totalSubscribers : product.monthly?.totalSubscribers}
								</Card.Subscribers>
							</Card.Body>
							<Card.Footer>
								<Card.BtnWrapper>
									<Card.Btn
										palette={"basic"}
										buttonTheme={"light"}
										onClick={() => setChangableProduct({ type: "view", product })}
										id={`viewPlan${i + 1}`}
									>
										View Plan
									</Card.Btn>
								</Card.BtnWrapper>
								<Card.MenuDotsWrapper>
									<MenuDots options={optionList(product, i)} removeSideMargin />
								</Card.MenuDotsWrapper>
							</Card.Footer>
						</Card.Content>
					</Card.Wrapper>
				))}

				<EmptyCard
					className={!subscriptionProducts.length && "full-mode"}
					onClick={() => setChangableProduct({ type: "create" })}
					id="createSubscriptionPlan"
				>
					<EmptyCard.PlusIconWrapper>
						<Icon fill={"#475b7f"} name={"plus-oval"} group="color" />
					</EmptyCard.PlusIconWrapper>
					<EmptyCard.Title>
						Create New <br /> Subscription Plan
					</EmptyCard.Title>
				</EmptyCard>
			</CardList>

			<NewSubscriptionPlanModal
				open={!!changableProduct}
				type={changableProduct?.type}
				product={changableProduct?.product}
				subscriptionAndPremium={subscriptionAndPremium}
				onClose={() => setChangableProduct(undefined)}
			/>
		</>
	);
};

export default SubscriptionPlans;
