import React, { useMemo } from "react";

import { IconButton } from "@material-ui/core";

import { INVITE } from "modules/Members/constants";
import ConfirmLeavePopup from "shared/Components/ConfirmLeave";
import useConfirmLeavePopup from "shared/Components/ConfirmLeave/hooks/useConfirmLeavePopup";

import { useMembers } from "shared/hooks";
import { Icon, InPlaceModal, Text } from "shared/ui-kit";

import { ModalContent } from "./ModalContent";

import { AddMemberModalHeader, AddMemberModalWrapper, ModalOverlay, StyledFormContainer } from "../style";

export const AddMembersModal = () => {
	const { setModalState, getData: getMembersData } = useMembers();
	const { isOpen, modalContent, modalTitle } = getMembersData();

	const actionsContentTypes = "cancelInviteContent";

	const showIcon =
		modalContent !== "default" &&
		modalContent !== "markArchive" &&
		modalContent !== "cancelInviteContent" &&
		modalContent !== "resendInviteContent" &&
		modalContent !== "removeMemberContent";

	const {
		handleLeavePageConfirmed,
		closeConfirmPopup,
		handleClose,
		getData: getConfirmLeavePopupData
	} = useConfirmLeavePopup({
		onClose: () => setModalState({ isOpen: false }),
		open: isOpen
	});
	const { showConfirmPopup } = getConfirmLeavePopupData();

	const dialogWidth = useMemo(
		() => (modalContent === "default" ? "648px" : modalContent === "shareableLinkList" ? "850px" : "760px"),
		[modalContent]
	);

	const handleMoveBack = () => {
		switch (modalContent) {
			case INVITE.INVITE_VIA_SHAREABLE_LINK:
				setModalState({ modalContent: INVITE.SHAREABLE_LINK_LIST, modalTitle: INVITE.SHAREABLE_LINK_LIST_TITLE });
				break;
			case INVITE.INVITE_VIA_QR_CODE || INVITE.QR_CODE_VIEW:
				setModalState({ modalContent: INVITE.QR_CODE_LIST, modalTitle: INVITE.QR_CODE_LIST_TITLE });
				break;
			default:
				setModalState({ modalContent: "default", modalTitle: "Choose method" });
		}
	};

	return (
		<ModalOverlay>
			<ConfirmLeavePopup
				handleLeavePage={handleLeavePageConfirmed}
				open={showConfirmPopup}
				onClose={closeConfirmPopup}
				popup
			/>
			<AddMemberModalWrapper dialogWidth={dialogWidth}>
				<InPlaceModal
					customStyle="padding: 0"
					modalHeight={600}
					open={!!isOpen}
					onClose={() => handleClose()}
					fullWidth={true}
					maxWidth={"sm"}
				>
					<AddMemberModalHeader>
						{modalContent !== actionsContentTypes && showIcon && (
							<IconButton size="small">
								<Icon fill="#c5cee0" group="filled" height={24} name="arrow-left" width={24} onClick={handleMoveBack} />
							</IconButton>
						)}
						<Text align="center" display="initial" variant="h5">
							{modalTitle}
						</Text>
						<IconButton size="small">
							<Icon
								fill="#c5cee0"
								group="filled"
								height={24}
								name="close"
								width={24}
								onClick={() => setModalState({ isOpen: false })}
							/>
						</IconButton>
					</AddMemberModalHeader>
					<StyledFormContainer container item spacing={2} page={modalContent}>
						<ModalContent modalContent={modalContent} />
					</StyledFormContainer>
				</InPlaceModal>
			</AddMemberModalWrapper>
		</ModalOverlay>
	);
};
