import styled from "styled-components";

import { Box } from "shared/ui-kit";

export const ListWrapper = styled(Box)`
	margin: 32px 0 0 -16px;

	width: calc(100% + 32px);

	/* padding: 0 0 16px 16px; */

	display: flex;
	align-items: flex-start;
`;

export const ItemWrapper = styled(Box)`
	width: 248px;
	min-width: 248px;
	text-align: left;
	margin-left: 16px;
`;

export const BuyWrapper = styled(Box)`
	margin-top: 16px;
`;
