import React, { useCallback, useEffect, useMemo, useState } from "react";

import { ForumPostModel, PostBlockEventType } from "types";

import { usePost, useSmartBlock, useUser } from "shared/hooks";

import { MediaModel, ProfilePhotoSizes } from "shared/types";

import { getResizedImage } from "utils/serviceUtils/cdnImages";

import { CommentWrapper, NewCommentSkeletonWrapper, NewCommentWrapper, Wrapper } from "./style";

import { Comment, CommentSkeleton, LoadMore, NewComment } from "../index";

export interface CommentsBlockProps {
	postId: string;
	previewComments?: ForumPostModel[];
	nextComments?: ForumPostModel[];
	currentReplies: number;
	onEvent: ({ eventType, id, extra }: { eventType: PostBlockEventType; id?: string; extra?: any }) => void;
}

const CommentsBlock: React.FC<CommentsBlockProps> = ({
	postId,
	previewComments,
	nextComments,
	currentReplies,
	onEvent
}) => {
	const { getData: getUserData } = useUser();
	const { user } = getUserData();

	const { loadPostComments, commentPost, updateComment } = usePost();

	const [commentList, setCommentList] = useState<ForumPostModel[]>(previewComments || []);
	const [nextCommentList, setNextCommentList] = useState(nextComments || []);
	const [currentRepliesCount, setCurrentRepliesCount] = useState(currentReplies || 0);
	const [displayCommentCount, setDisplayCommentCount] = useState(2);
	const [offset, setOffset] = useState<number>(1);
	const [loadingReplies, setLoadingReplies] = useState(false);
	const [loadingNewComment, setLoadingNewComment] = useState(false);

	const { encodeComment } = useSmartBlock();

	const fetchComments = useCallback(
		async ({
			reloadCurrent,
			customOffset,
			customLimit
		}: {
			reloadCurrent?: boolean;
			customOffset?: number;
			customLimit?: number;
		}) => {
			if (offset) {
				setLoadingReplies(true);

				const limit = customLimit || 5;

				const { comments, nextComments } = await loadPostComments({
					postId,
					offset: customOffset || offset,
					limit,
					extraLimit: 3
				});

				setCommentList(ctx => (offset > 1 && !reloadCurrent ? [...ctx, ...comments] : comments));

				if (!reloadCurrent) {
					setDisplayCommentCount(x => x + limit);
				}

				if (reloadCurrent && customLimit) {
					setDisplayCommentCount(customLimit);
				}

				setNextCommentList(nextComments);

				setLoadingReplies(false);
			}
		},
		[offset, loadPostComments, postId]
	);

	const loadMore = useCallback(() => setOffset(off => (off || 0) + 1), []);

	useEffect(() => {
		if (offset > 1) {
			fetchComments(offset === 2 ? { reloadCurrent: true, customOffset: 1, customLimit: 7 } : {});
		}
	}, [fetchComments, offset]);

	useEffect(() => {
		if (!loadingReplies && currentRepliesCount > displayCommentCount && commentList?.length < displayCommentCount) {
			fetchComments({ reloadCurrent: true });
		}
	}, [currentRepliesCount, displayCommentCount, commentList?.length, fetchComments, loadingReplies]);

	const postNewComment = useCallback(
		async ({ content, toPid, clbk }: { content: string; toPid?: string; clbk?: (post: ForumPostModel) => void }) => {
			if (!toPid) {
				setLoadingNewComment(true);
			}

			const newComment = await commentPost({ comment: content, postId: toPid || postId });
			if (!toPid || toPid === postId) {
				// comment only
				setCurrentRepliesCount(count => count + 1);
				setCommentList(comments => [{ ...newComment!, postcount: 0 }].concat(comments));
			}

			// increase total because added reply/comment
			onEvent && onEvent({ eventType: PostBlockEventType.addComment });

			setLoadingNewComment(false);

			clbk && newComment && clbk(newComment);
		},
		[onEvent, commentPost, postId]
	);

	const currentUserImgUrl = useMemo(
		() =>
			getResizedImage(
				user?.profiles?.length && user.profiles[0]?.photos?.length
					? (user.profiles[0].photos[0] as MediaModel)?.profilePicture || ""
					: "",
				ProfilePhotoSizes.size200x200
			),
		[user]
	);

	const onCommentEvent = useCallback(
		({
			eventType,
			id,
			content,
			extra,
			clbk
		}: {
			eventType: PostBlockEventType;
			id?: string;
			content?: string;
			extra?: any;
			clbk?: (post: ForumPostModel) => void;
		}) => {
			if (eventType === PostBlockEventType.delete || eventType === PostBlockEventType.report) {
				setCommentList(cmts => cmts.filter(x => x.pid !== id));
				if (!extra?.subreply) {
					setCurrentRepliesCount(count => count - 1);
				}
				onEvent({ eventType: PostBlockEventType.delete, extra });
			}

			if (eventType === PostBlockEventType.updateComment) {
				updateComment(content, id);
				setCommentList(cmts =>
					cmts.map(x =>
						x.pid === id ? { ...x, content: content ? encodeComment(content) : "", timestamp: Date.now() } : x
					)
				);
			}

			if (eventType === PostBlockEventType.addComment && extra?.toPid && content) {
				postNewComment({ content, toPid: extra.toPid, clbk });
			}
		},
		[onEvent, updateComment, postNewComment, encodeComment]
	);

	const ExistingComments = useMemo(
		() => (
			<>
				{commentList.slice(0, displayCommentCount).map(comment => (
					<CommentWrapper key={comment.pid}>
						<Comment comment={comment} onEvent={onCommentEvent} />
					</CommentWrapper>
				))}
			</>
		),
		[commentList, displayCommentCount, onCommentEvent]
	);

	return (
		<>
			<Wrapper>
				<NewCommentWrapper>
					<NewComment avatarUrl={currentUserImgUrl} onPostNewComment={postNewComment} />
				</NewCommentWrapper>
				{loadingNewComment && (
					<NewCommentSkeletonWrapper>
						<CommentSkeleton />
					</NewCommentSkeletonWrapper>
				)}
				{ExistingComments}
				{loadingReplies && <CommentSkeleton />}
				{!loadingReplies && currentRepliesCount > displayCommentCount && (
					<LoadMore onLoadMore={loadMore} commentsList={nextCommentList} />
				)}
			</Wrapper>
		</>
	);
};

export default CommentsBlock;
