import React, { ReactNode } from "react";

import Grid from "@material-ui/core/Grid";
import styled, { css } from "styled-components";

import { customBreakpoints } from "theme/default";

const ContentWrapperBlock = styled(Grid)<ContentWrapperProps>`
	display: flex;
	justify-content: center;
	align-items: flex-start;

	margin: 96px auto;

	max-width: 1388px;

	${props => props.theme.breakpoints.down(customBreakpoints.tabletHorizontalMax)} {
		margin-top: 31px;
	}

	${props => props.theme.breakpoints.down(customBreakpoints.tabletVerticalMax)} {
		flex-direction: column;
		align-items: center;
		justify-content: flex-start;
		margin-top: 55px;
	}

	${props => props.theme.breakpoints.down("sm")} {
		margin: 8px auto 25px auto;
		width: 100%;
	}

	${({ customStyle }) => {
		return customStyle
			? css`
					${customStyle}
			  `
			: "";
	}}
`;

interface ContentWrapperProps {
	children: ReactNode;
	customStyle?: string;
}

const ContentWrapper: React.FC<ContentWrapperProps> = ({ customStyle, children }) => {
	return (
		<ContentWrapperBlock container xs={12} customStyle={customStyle}>
			{children}
		</ContentWrapperBlock>
	);
};

export default ContentWrapper;
