import { useMemo } from "react";

import { API_URLS } from "modules/LoyaltyRewards/constants";
import useHttpActions from "shared/services/api/core/useHttpActions";
import BaseConverter from "shared/services/converters/baseConverter";

import { BadgeModel, CreateBadgeResponse } from "shared/types";

interface GetBadges {
	badges: BadgeModel[];
}

const useBadgeService = () => {
	const actions = useHttpActions();

	return useMemo(
		() => ({
			badges: async ({ limit, offset }: { offset: number; limit: number }) =>
				BaseConverter.convert<GetBadges>(await actions.get(API_URLS.BADGES, { limit, offset })),
			createBadge: async (data: BadgeModel) =>
				BaseConverter.convert<{ success: boolean; badge: CreateBadgeResponse }>(
					await actions.post(API_URLS.BADGES, data)
				),
			updateBadge: async (data: Partial<BadgeModel>, badgeId: number) =>
				BaseConverter.convert<{ success: boolean }>(await actions.put(`${API_URLS.BADGES}/${badgeId}`, data)),
			deleteBadge: async (badgeId: number) =>
				BaseConverter.convert<{ success: boolean }>(await actions.delete(`${API_URLS.BADGES}/${badgeId}`))
		}),
		[actions]
	);
};

export default useBadgeService;
