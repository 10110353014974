import { Box } from "@material-ui/core";
import styled from "styled-components";

import AkinaLogo from "assets/images/akinaLogo.png";
import ReliasLogo from "assets/images/relias.webp";
import theme, { customBreakpoints } from "theme/default";

export const TopBar = styled.header`
	width: 100%;
	height: 64px;
	position: sticky;
	top: 0;
	z-index: 1200;

	display: flex;
	align-items: center;

	backdrop-filter: blur(20px);
	box-shadow: 0 0.5px 0 0 ${theme.palette.colors.basic[500]};
	background-color: white;

	${props => props.theme.breakpoints.down(customBreakpoints.tabletHorizontalMax)} {
		height: 72px;
	}

	&.workspace-kijijimoms {
		height: 96px;

		background: #a0a0f2;

		box-shadow: none;

		backdrop-filter: none;

		.header {
			&__container {
				width: 1190px;
				height: 100%;

				margin: 0 auto;
				padding: 0;
			}

			&__action-icon-wrapper {
				width: 40px;
				height: 40px;

				padding: 2px;

				margin: 0 9px;
			}

			&__left-side {
				margin: 0 0 0 -8px;
				padding: 0;
			}

			&__right-side {
				margin: 0 -16px 0 0;
				padding: 0;
			}
		}

		.profile__name {
			color: white;
		}

		.brand-image {
			display: block;

			width: 190px;
			height: 80px;

			background-image: url(${AkinaLogo});
			background-position: center;
			background-repeat: no-repeat;
			background-size: contain;
		}
	}

	&.workspace-holliblu {
		.brand-image {
			display: block;

			width: 240px;
			height: 55px;

			background-image: url(${ReliasLogo});
			background-position: center;
			background-repeat: no-repeat;
			background-size: contain;
		}
	}
`;

export const TopBarContainer = styled(Box)`
	width: 100%;

	margin: 0 auto;

	padding: 12px 8px;
	display: flex;
	align-items: center;

	.left-side {
		display: flex;
		flex: 2 1 auto;
		padding: 0 16px;

		${props => props.theme.breakpoints.down("sm")} {
			padding: 0 32px;
		}
	}

	.brand {
		display: flex;

		&-meta {
			margin-left: 12px;
		}
	}
`;

export const ContentWrapper = styled.div`
	width: 100%;
	min-height: 100%;

	display: flex;
	flex-direction: column;
`;

export const Main = styled.main`
	background-color: ${theme.palette.colors.basic[200]};
	display: flex;
	justify-content: space-between;
	flex-grow: 1;
`;

export const FooterWrapper = styled.footer`
	flex-shrink: 0;

	padding-bottom: 25px;

	${props => props.theme.breakpoints.down("sm")} {
		padding-bottom: 37px;
	}
`;

export const StepperBlock = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;

	${props => props.theme.breakpoints.down(customBreakpoints.tabletHorizontalMax)} {
		margin-top: 31px;
	}

	${props => props.theme.breakpoints.down(customBreakpoints.tabletVerticalMax)} {
		margin-top: 46px;
	}

	${props => props.theme.breakpoints.down("sm")} {
		margin-top: 7px;
	}

	.StepItemCircleBox {
		padding: 0;

		svg {
			path[fill="#FFF"] {
				fill: transparent;
			}
		}
	}
`;

export const ContactBlock = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;

	margin-top: 20px;
`;

export const ContactLink = styled.a`
	color: #8f9bb3;
	text-decoration: none;
`;
