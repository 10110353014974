import React, { FC } from "react";

import { Loader } from "shared/ui-kit";

import { StyledMemberButton } from "./style";

const PrimaryMemberButton: FC<{ onClick?: () => void; loading?: boolean; disabled?: boolean; small?: boolean }> = ({
	children,
	disabled,
	loading,
	small,
	onClick
}) => {
	return (
		<StyledMemberButton
			className={`primary ${small && "small"}`}
			buttonTheme="light"
			palette="primary"
			disabled={loading || disabled}
			onClick={onClick}
		>
			{loading && <Loader show size={20} color="secondary" variant="indeterminate" />}
			{children}
		</StyledMemberButton>
	);
};

export default PrimaryMemberButton;
