import React, { ReactNode, useEffect, useMemo, useRef } from "react";

import Skeleton from "@material-ui/lab/Skeleton";

import clsx from "clsx";

import SmartScroll from "shared/Components/SmartScroll";
import { useOnScreen } from "shared/hooks";

import { BtnWrapper, ItemWrapper, ListWrapper, StyledSkeleton } from "./style";

import { BoxItem, SeeAllBtn } from "../index";

interface ListProps {
	title?: string;
	smallTitle?: boolean;
	customTitle?: ReactNode;
	preview?: boolean;
	items: any[];
	totalCount?: number;
	renderItem: (item?: any) => ReactNode;
	seeAllLink?: string;
	onSeeAllClick?: () => void;
	previewItemCount?: number;
	onLoadMore?: () => void;
	loading?: boolean;
	pageSize?: number;
	skeletonRenderItem?: ReactNode;
	fullBodySize?: boolean;
	bodyWrapClassname?: string;
	noContentBlock?: ReactNode;
	skeletonSizeSmall?: boolean;
	wrapperClassName?: string;
	itemWrapClassName?: string;
}

const List: React.FC<ListProps> = ({
	title,
	smallTitle,
	customTitle,
	preview,
	items,
	renderItem,
	totalCount = 0,
	seeAllLink,
	previewItemCount = 5,
	onLoadMore,
	loading = false,
	// pageSize = 5,
	fullBodySize = false,
	skeletonRenderItem,
	onSeeAllClick,
	bodyWrapClassname = "",
	noContentBlock,
	skeletonSizeSmall,
	wrapperClassName,
	itemWrapClassName
}) => {
	const lastItemRef = useRef<any>(null);
	const onScreen = useOnScreen(lastItemRef);

	useEffect(() => {
		!!items.length && onScreen && onLoadMore && !loading && (!totalCount || items.length < totalCount) && onLoadMore();
	}, [onLoadMore, items, totalCount, loading, onScreen]);

	const loaderTemplate = useMemo(
		() => (
			<>
				{[...Array.from(Array(5).keys())].map(
					(item, index) =>
						skeletonRenderItem || (
							<StyledSkeleton.Wrapper key={index} className={clsx(skeletonSizeSmall && "small")}>
								<Skeleton variant="circle" width={skeletonSizeSmall ? 32 : 48} height={skeletonSizeSmall ? 32 : 48} />
								<StyledSkeleton.Body>
									<Skeleton width="75%" height={24} />
									<Skeleton width="25%" height={24} />
								</StyledSkeleton.Body>
							</StyledSkeleton.Wrapper>
						)
				)}
			</>
		),
		[skeletonRenderItem, skeletonSizeSmall]
	);

	const ListContainer = () => (
		<ListWrapper className={`${!preview ? "pageBlock" : ""} ${bodyWrapClassname}`}>
			{(items || []).slice(0, preview ? previewItemCount : items.length).map((item, index) => (
				<ItemWrapper className={clsx(preview ? "" : itemWrapClassName ? itemWrapClassName : "bordered")} key={index}>
					{renderItem(item)}
				</ItemWrapper>
			))}
			{!preview && <div ref={lastItemRef} />}
			{!preview && loading && loaderTemplate}
		</ListWrapper>
	);

	return (
		<BoxItem
			customPadding="12px 8px"
			ml="8px"
			title={title}
			customTitle={customTitle}
			smallTitle={smallTitle}
			pageBlock={!preview || fullBodySize}
			className={clsx(wrapperClassName)}
		>
			{!loading && !items?.length && noContentBlock}
			{preview && !!items?.length && bodyWrapClassname.includes("notifications-preview-body-wrapper") ? (
				<SmartScroll height={"370px"} shouldScrollToTop={false}>
					<ListContainer />
				</SmartScroll>
			) : (
				<ListContainer />
			)}
			{preview && loading && !items?.length && loaderTemplate}
			{!!items?.length && preview && (seeAllLink || onSeeAllClick) && (!totalCount || totalCount > previewItemCount) && (
				<BtnWrapper>
					<SeeAllBtn link={seeAllLink} click={onSeeAllClick ? onSeeAllClick : () => {}} />
				</BtnWrapper>
			)}
		</BoxItem>
	);
};

export default List;
