import { Link } from "react-router-dom";
import styled from "styled-components";

import { Box, Button, Text } from "shared/ui-kit";

export const Item = styled(Box)`
	width: 208px;
	height: 296px;
	padding: 0 0 16px;
	border-radius: 12px;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08), inset 0 0 0 0.5px #c5cee0;
	background-color: #fff;

	transition: box-shadow 0.25s linear;

	overflow: hidden;

	&:hover {
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08), inset 0 0 0 0.5px ${({ color }) => color};
	}

	&.long {
		height: 344px;
	}
`;

Item.CoverPhotoWrapper = styled(Box)`
	width: 100%;
	height: 64px;

	position: relative;

	border-top-left-radius: 12px;
	border-top-right-radius: 12px;

	margin-bottom: 56px;

	//overflow: hidden;
`;

Item.CoverPhotoPlaceholderWrapper = styled(Box)`
	width: 100%;
	height: 64px;

	position: relative;

	border-top-left-radius: 12px;
	border-top-right-radius: 12px;

	overflow: hidden;
`;

Item.CoverPhoto = styled(Box)<{ url?: string }>`
	width: 100%;
	height: 100%;

	background: url(${({ url }) => url}) center no-repeat;
	background-size: cover;
`;

Item.PhotoWrapper = styled(Box)`
	position: absolute;

	bottom: -40px;
	left: calc(50% - 40px);

	width: 80px;
	height: 80px;

	border: solid 1px #fff;

	border-radius: 50%;

	overflow: hidden;

	z-index: 5;

	background-color: white;
`;

Item.TooltipWrapper = styled(Box)`
	width: 100%;
	> div {
		width: 100%;
	}
`;

Item.Photo = styled(Box)<{ url?: string }>`
	width: 100%;
	height: 100%;

	background: url(${({ url }) => url}) center no-repeat;
	background-size: cover;
`;

Item.PhotoLink = styled(Link)``;

Item.Title = styled(Text)`
	font-size: 15px;
	font-weight: 600;
	line-height: 1.6;
	letter-spacing: normal;
	text-align: center;
	color: #222b45;
`;

Item.Body = styled(Box)`
	padding: 0 16px;
`;

Item.ConnectionsBlock = styled(Box)`
	display: flex;
	align-items: center;

	margin-top: 28px;
`;

Item.ConnectionText = styled(Text)`
	font-size: 10px;
	font-weight: 600;
	line-height: normal;
	letter-spacing: normal;
	color: #222b45;
`;

Item.ManageBlock = styled(Box)`
	margin-top: 16px;

	&.long {
		min-height: 100px;

		display: flex;
		flex-direction: column;
		justify-content: center;
	}
`;

Item.MessageRow = styled(Box)`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

Item.MenuWrapper = styled(Box)`
	margin-left: 8px;
`;

export const StyledButton = styled(Button)`
	border-radius: 8px;
	&.hover-to-black:hover {
		color: #000000;
	}
`;
