import React from "react";

import appConfig from "config/appConfig";

import { view } from "modules/App";
import { useMemberRoutes } from "shared/hooks";
import useJwt from "shared/services/api/core/jwt";

const BrokenLinkPage = () => {
	const jwtService = useJwt();
	const { getMemberRoutesData } = useMemberRoutes();
	const { routes: memberRoutes } = getMemberRoutesData();

	const memberHomePage = memberRoutes?.member.home.getPath();

	const backToCommunityLink = !jwtService.getJwt()
		? appConfig.GLOBAL_CONSTANTS.VYOO_MARKETING_SITE_URL || memberHomePage
		: memberHomePage;

	return <view.BrokenLink backToCommunityLink={backToCommunityLink} />;
};

export default BrokenLinkPage;
