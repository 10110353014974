import React, { useCallback, useMemo } from "react";

import { ReactComponent as BlockIcon } from "assets/icons/liveconvo/block.svg";
import { ReactComponent as IconEyeStrikethrough } from "assets/icons/stories/icon-eye-strikethrough.svg";
import { ReactComponent as IconPictureLinear } from "assets/icons/stories/icon-picture-linear.svg";
import { MenuDotsOption } from "shared/Components";
import { useReportContentStore } from "shared/contexts";
import { useReportContentApiService } from "shared/services";
import { actionMethod, actionTypes, getValidationMessage } from "utils/getValidationMessage";

import { ReportContentReasonType, ReportContentSortBy, ReportContentType } from "../types";
import { useNotification, useUser } from "./index";

const iconProps = {
	fill: "#8F9BB3",
	width: 20,
	height: 20
};

const useReportContent = () => {
	const store = useReportContentStore();
	const { setState } = useReportContentStore();

	const service = useReportContentApiService();

	const { getData: getUserData } = useUser();
	const { user } = getUserData();

	const { showMessage } = useNotification();

	const initiatorId = useMemo(() => {
		return user?.personaDocId || "";
	}, [user]);

	const personaId = useMemo(() => {
		return user?.activeProfile || 0;
	}, [user]);

	const methods = useMemo(
		() => ({
			getReportsList: async ({
				keyword = "",
				offset = 1,
				limit = 10,
				order,
				sortBy
			}: {
				keyword?: string;
				offset?: number;
				limit?: number;
				order?: number;
				sortBy?: ReportContentSortBy;
			}) => {
				setState(ctx => ({ ...ctx, loading: true }));
				const { reports } = await service.getReports({ keyword, offset, limit, order, sortBy });
				setState(ctx => ({ ...ctx, loading: false, reports }));
			},
			getReportsCount: async (keyword = "") => {
				setState(ctx => ({ ...ctx, loading: true }));
				const { totalCount } = await service.getReportsCount(keyword);
				setState(ctx => ({ ...ctx, loading: false, totalCount }));
			},
			reportContent: async ({
				reporteeId,
				reportType,
				reason
			}: {
				reporteeId: string;
				reportType: ReportContentType;
				reason: ReportContentReasonType;
			}) => {
				try {
					setState(ctx => ({ ...ctx, loading: true }));

					if (reportType === ReportContentType.LIVE_PARTICIPANT) {
						await service.reportUserByPersonaId({ personaId, reporteeId, reason });
					} else {
						await service.reportContent({ initiatorId, reporteeId, reportType, reason });
					}

					showMessage(
						getValidationMessage({
							name: "Content",
							actionType: actionTypes.CRUD,
							actionMethod: actionMethod.banned,
							emoji: "🚫"
						}),
						3
					);
				} catch (e) {
					console.log("Can't report content: ", e);
				} finally {
					setState(ctx => ({ ...ctx, loading: false }));
				}
			},
			deleteReport: async (reportIds: string[]) => {
				setState(ctx => ({ ...ctx, loading: true }));
				await service.deleteReport(reportIds);
				setState(ctx => ({ ...ctx, loading: false }));
			},
			deleteReportedContent: async (reportIds: string[]) => {
				setState(ctx => ({ ...ctx, loading: true }));
				await service.deleteReportedContent(reportIds);
				setState(ctx => ({ ...ctx, loading: false }));
			},
			getReportTypeOptions: ({
				reportType,
				reportContentId,
				reportContentName,
				customCallback
			}: {
				reportType: ReportContentType;
				reportContentId: string;
				reportContentName?: string;
				customCallback?: () => void;
			}): MenuDotsOption[] => {
				return [
					{
						name: ReportContentReasonType.STOLEN,
						icon: <IconPictureLinear {...iconProps} viewBox="2 1 22 22" />,
						onClick: () =>
							methods.setReportContentModal({
								reportType,
								reason: ReportContentReasonType.STOLEN,
								reportContentId,
								reportContentName,
								customCallback
							})
					},
					{
						name: ReportContentReasonType.INAPROPRIATE_CONTENT,
						icon: <IconEyeStrikethrough {...iconProps} viewBox="2 1 23 20" />,
						onClick: () =>
							methods.setReportContentModal({
								reportType,
								reason: ReportContentReasonType.INAPROPRIATE_CONTENT,
								reportContentId,
								reportContentName,
								customCallback
							})
					},
					{
						name: ReportContentReasonType.SENDING_SPAM,
						icon: <BlockIcon {...iconProps} viewBox={"0 0 20 20"} />,
						onClick: () =>
							methods.setReportContentModal({
								reportType,
								reason: ReportContentReasonType.SENDING_SPAM,
								reportContentId,
								reportContentName,
								customCallback
							})
					}
				];
			},
			setReportContentModal: (
				params:
					| {
							reportType: ReportContentType;
							reason: ReportContentReasonType;
							reportContentId: string;
							reportContentName?: string;
							customCallback?: () => void;
					  }
					| undefined
			) => {
				if (params) {
					setState(ctx => ({
						...ctx,
						reportContentModal: {
							reportType: params?.reportType,
							reason: params?.reason,
							reportContentId: params?.reportContentId,
							reportContentName: params?.reportContentName,
							customCallback: params?.customCallback
						}
					}));
				} else {
					setState(ctx => ({
						...ctx,
						reportContentModal: undefined
					}));
				}
			}
		}),
		[service, setState, initiatorId, personaId, showMessage]
	);

	const getData = useCallback(() => {
		return store;
	}, [store]);

	return { ...methods, getData };
};

export default useReportContent;
