import config from "config/appConfig";

import { ManagePostsContextValues } from "shared/types";
import { IStateProps } from "utils/StateOf";

import createStore from "utils/createStore";

const localStorageKey = `${config.GLOBAL_CONSTANTS.LOCAL_STORAGE_PREFIX}${config.HOOK_STORAGE_KEYS.POSTS_DATA}`;

export const initialState: IStateProps<ManagePostsContextValues> = {
	posts: [],
	postCount: 0,
	offset: 0,
	loading: false,
	postsPerPage: 20,
	page: 1,
	createUpdatePostDialog: {
		isOpen: false
	}
};

export default createStore<ManagePostsContextValues>({
	initialState,
	persistant: true,
	persistantName: localStorageKey
});
