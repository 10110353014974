import React from "react";

import { SkeletonBase } from "shared/Components";

import { AvatarWrapper, TextWrapper, Wrapper } from "./style";

export interface CommentSkeletonProps {
	reply?: boolean;
}

const CommentSkeleton: React.FC<CommentSkeletonProps> = ({ reply }) => (
	<Wrapper className={reply && "reply"}>
		<AvatarWrapper className="avatar-wrapper">
			<SkeletonBase variant="circle" />
		</AvatarWrapper>
		<TextWrapper className="text-wrapper">
			<SkeletonBase variant="rect" height={60} />
		</TextWrapper>
	</Wrapper>
);

export default CommentSkeleton;
