import React, { FC, useCallback, useEffect, useMemo, useState } from "react";

import DateFnsAdapter from "@date-io/date-fns";

import { ContactType } from "types";

import { ReactComponent as BinIcon } from "assets/icons/icon-bin.svg";
import { ReactComponent as NoProfileIcon } from "assets/icons/no-profile.svg";
import { ReactComponent as PencilIcon } from "assets/icons/pencil.svg";
import { ReactComponent as EyeIcon } from "assets/icons/stories/icon-eye-strikethrough.svg";

import { ManInLotus } from "modules/MemberHome/View/Containers/Connections/style";
import { MemberDialog } from "modules/MemberHome/View/shared";
import { useFundraisers, useNotification, useUser } from "shared/hooks";

import { FundraiserStatusEnum } from "shared/types/FundraiserModel";
import { Button, Icon, Text } from "shared/ui-kit";

import { ContactsWrapper, StatusChip } from "./style";

import ConfirmActionModal from "../ConfirmActionModal";
import MenuDots from "../MenuDots";

const dateFns = new DateFnsAdapter();

const MyContactsDialog: FC<{ id: string }> = ({ id }) => {
	const {
		setMyContactsDialog,
		getMyContacts,
		getData: getFundraiserData,
		setInviteDialog,
		updateFundraiserContacts,
		setContacts,
		setInviteDialogModel,
		deleteFundraiserContacts
	} = useFundraisers();

	const { contacts, fundraiser } = getFundraiserData();

	const [loading, setLoading] = useState(false);
	const [confirmInfo, setConfirmInfo] = useState<{
		title: string;
		text: string;
		onClose: () => void;
		onConfirm: () => void;
	} | null>(null);

	const { getData: getUserData, getActiveProfile } = useUser();
	const { user } = getUserData();

	const { showMessage } = useNotification();

	const activeProfile = useMemo(() => getActiveProfile(user), [user, getActiveProfile]);

	const handleAddContact = useCallback(() => {
		setMyContactsDialog();
		activeProfile &&
			setInviteDialog({
				open: true,
				id,
				name: "Name placeholder..",
				onBehalfOf: {
					label: activeProfile.firstName,
					value: activeProfile.personaId
				}
			});
	}, [activeProfile, id, setInviteDialog, setMyContactsDialog]);

	useEffect(() => {
		setLoading(true);
		getMyContacts(id, true).then(() => {
			setLoading(false);
		});
	}, [getMyContacts, id]);

	const handleHideFromList = useCallback(
		async (contactId: string) => {
			const response = await updateFundraiserContacts(contactId, { hiddenByInvitor: true });

			if (response) {
				setContacts(contacts.filter(c => c._id !== contactId));
				showMessage("Contact has been hidden from your list.");
			}
		},
		[contacts, setContacts, showMessage, updateFundraiserContacts]
	);

	const handleRemoveContact = useCallback(
		async (contactId: string) => {
			const success = await deleteFundraiserContacts(contactId);

			if (success) {
				setContacts(contacts.filter(c => c._id !== contactId));
				showMessage("Contact has been removed.");
			}
		},
		[contacts, deleteFundraiserContacts, setContacts, showMessage]
	);

	const handleEditContact = useCallback(
		(contact: ContactType) => {
			if (fundraiser && activeProfile) {
				setInviteDialog({
					open: true,
					id,
					name: fundraiser.name,
					onBehalfOf: {
						label: `${activeProfile.firstName} ${activeProfile.lastName}`,
						value: activeProfile.personaId
					}
				});
				setInviteDialogModel(contact);
				setMyContactsDialog();
			}
		},
		[activeProfile, fundraiser, id, setInviteDialog, setInviteDialogModel, setMyContactsDialog]
	);

	const menuOpts = useCallback(
		(contact: ContactType) => {
			let opts = [
				{
					icon: <EyeIcon />,
					name: "Hide from List",
					onClick: () =>
						setConfirmInfo({
							title: "Hide from List",
							text: "Are you sure you want to hide this contact from your list?",
							onClose: () => setConfirmInfo(null),
							onConfirm: () => handleHideFromList(contact._id)
						})
				}
			];

			if (contact.status === "pending") {
				opts = [
					{
						icon: <PencilIcon width={18} height={18} />,
						name: "Edit Contact",
						onClick: () => handleEditContact(contact)
					},
					{
						icon: <BinIcon />,
						name: "Remove Contact",
						onClick: () =>
							setConfirmInfo({
								title: "Remove Contact",
								text: "Are you sure you want to remove this contact?",
								onClose: () => setConfirmInfo(null),
								onConfirm: () => handleRemoveContact(contact._id)
							})
					},
					...opts
				];
			}

			return opts;
		},
		[handleEditContact, handleHideFromList, handleRemoveContact]
	);

	const getDifferenceInDays = useCallback((date1, date2) => {
		const diffInMs = Math.abs(date2 - date1);
		return Number(Math.floor(diffInMs / (1000 * 60 * 60 * 24)).toFixed(0));
	}, []);

	const daysAmount = useMemo(() => {
		if (fundraiser) {
			const startDate = new Date(fundraiser.startDate);
			const endDate = new Date(fundraiser.endDate);
			const now = new Date();

			const startsIn = getDifferenceInDays(startDate, now);
			const endsIn = getDifferenceInDays(endDate, now);

			if (startsIn > 0) {
				return `${startsIn} days until send out.`;
			}

			return `${endsIn} days until fundraiser ends.`;
		}
	}, [fundraiser, getDifferenceInDays]);

	const dateInfo = useMemo(() => {
		if (fundraiser) {
			const startDate = new Date(fundraiser.startDate).getTime();
			const now = new Date().getTime();

			if (startDate > now)
				return `This fundraiser will start on [${dateFns.format(
					new Date(fundraiser.startDate),
					"M.d.yy"
				)}]. Add your contacts and prepare your messages to be sent out once
			the fundraiser is live.`;

			return `This fundraiser will end on [${dateFns.format(
				new Date(fundraiser.endDate),
				"M.d.yy"
			)}]. You still can add more contacts to support your group.`;
		}
	}, [fundraiser]);

	const isEnded = useMemo(
		() => fundraiser?.status?.toLowerCase() === FundraiserStatusEnum.ENDED.toLowerCase(),
		[fundraiser]
	);

	return (
		<>
			<MemberDialog
				open
				onClose={setMyContactsDialog}
				title="Fundraiser Contacts"
				customWidth={460}
				loading={loading}
				noFooter
				footerPrimary={{ text: "Add Contact", onClick: handleAddContact }}
			>
				<ContactsWrapper className="px-6 pt-3 pb-1 flex flex-col">
					<p className="info-gray info-text">{daysAmount}</p>

					<div className="mt-4 flex-1 flex flex-col">
						{!contacts.length && fundraiser ? (
							<div className="flex-1 flex flex-col justify-center items-center text-center gap-4">
								<ManInLotus />
								<Text variant="body1">
									The fundraiser is scheduled to go live on [{dateFns.format(new Date(fundraiser.startDate), "M.d.yy")}
									]. Add your contacts and prepare your messages to be sent out once the fundraiser is live.
								</Text>
								<Button
									size="medium"
									className="mt-2"
									buttonTheme="light"
									disabled={isEnded}
									onClick={handleAddContact}
								>
									<Icon name="plus" fill="white" width={20} height={20} className="mr-2" />
									Add Contacts
								</Button>
							</div>
						) : (
							<div className="flex flex-col gap-6">
								{contacts.map(contact => (
									<div key={contact._id} className="flex items-center justify-between">
										<div className="flex items-center gap-4">
											{contact.image.url ? (
												<img src={contact.image.url} alt={contact.name} className="w-10 h-10 rounded-full" />
											) : (
												<NoProfileIcon className="w-10 h-10" />
											)}
											<div>
												<Text variant="subtitle1">{contact.name}</Text>
												<Text variant="body2" className="info-gray">
													Added {dateFns.format(new Date(contact.createdAt), "hh:mm aa")}
												</Text>
											</div>
										</div>
										<div className="flex items-center -mr-2">
											<StatusChip className={`px-2 border rounded-full font-semibold ${contact.status.toLowerCase()}`}>
												{contact.status}
											</StatusChip>
											<MenuDots options={menuOpts(contact)} alignLeft memberView vertical removeshadow />
										</div>
									</div>
								))}
							</div>
						)}
					</div>

					<p className="info-gray mt-4 info-text mb-8">{dateInfo}</p>
				</ContactsWrapper>
			</MemberDialog>
			{confirmInfo && (
				<ConfirmActionModal
					open
					onClose={confirmInfo.onClose}
					title={confirmInfo.title}
					bodyText={confirmInfo.text}
					onConfirm={() => {
						confirmInfo.onConfirm();
						confirmInfo.onClose();
					}}
					closeText="Keep"
					confirmText="Delete"
					confirmButtonTheme="danger"
					cancelButtonTheme="primary"
					reverseButtons
				/>
			)}
		</>
	);
};

export default MyContactsDialog;
