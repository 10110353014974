import React, { FC, useMemo } from "react";

import { Box } from "@material-ui/core";

import { ReactComponent as IconVideo } from "assets/icons/icon-video.svg";
import { TrackRow } from "modules/Manage/View/Components/AddTracksToPlaylist/style";
import { useSeries } from "shared/hooks";
import { VideoModel } from "shared/types";
import { Checkbox, Text } from "shared/ui-kit";

interface Props {
	video: VideoModel;
	lastVideoRef?: React.RefObject<HTMLDivElement>;
	handleToggleVideo: (video: VideoModel, checked?: boolean | undefined) => void;
	id?: string;
}

const VideoBlock: FC<Props> = ({ video, lastVideoRef, handleToggleVideo, id }) => {
	const { getData: getSeriesData } = useSeries();
	const { seriesDetailsDialog } = getSeriesData();
	const artworkUrl = video.meta?.artwork?.url || "";

	const checked = useMemo(
		() => seriesDetailsDialog?.data?.itemIds.some(item => item._id === video._id),
		[video._id, seriesDetailsDialog]
	);

	return (
		<TrackRow ref={lastVideoRef} checked={checked} onClick={() => handleToggleVideo(video, checked)} id={id}>
			<Box className="track-info">
				{artworkUrl ? (
					<img src={artworkUrl} alt={video.title} />
				) : (
					<Box className="track-no-img">
						<IconVideo />
					</Box>
				)}
				<Box className="track-name">
					<Text variant="subtitle1">{video.title}</Text>
					<Text variant="body2">{video.category?.name || "-"}</Text>
				</Box>
			</Box>
			<Box className="checkbox">
				<Checkbox checked={checked} />
			</Box>
		</TrackRow>
	);
};

export default VideoBlock;
