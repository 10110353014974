import config from "config/appConfig";
import { SeriesContextValuesType } from "types";

import { IStateProps } from "utils/StateOf";

import createStore from "utils/createStore";

const localStorageKey = `${config.GLOBAL_CONSTANTS.LOCAL_STORAGE_PREFIX}${config.HOOK_STORAGE_KEYS.SERIES_DATA}`;

export const initialState: IStateProps<SeriesContextValuesType> = {
	series: [],
	seriesCount: 0,
	searchText: "",
	seriesPerPage: 20,
	page: 1,
	isLoading: false,
	createEditSeriesDialog: {
		open: false,
		isEditingDetail: false
	}
};

export default createStore<SeriesContextValuesType>({
	initialState,
	persistant: true,
	persistantName: localStorageKey
});
