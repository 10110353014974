import React, { FC, useEffect, useMemo } from "react";

import { ClickAwayListener, IconButton } from "@material-ui/core";
import YouTube from "react-youtube";

import { Icon } from "shared/ui-kit";
import { validateYoutube } from "utils/serviceUtils/validators";

import { Wrapper } from "./style";

import { getVideoId } from "../Youtube/AddYoutube";

const FullScreenVideoDialog: FC<{ url: string; handleClose: () => void; autoplay?: boolean }> = ({
	url,
	handleClose,
	autoplay = false
}) => {
	const isYTVid = useMemo(() => validateYoutube(url), [url]);

	useEffect(() => {
		return () => {
			handleClose();
		};
	}, [handleClose]);

	return (
		<Wrapper className="flex items-center justify-center">
			<ClickAwayListener onClickAway={handleClose}>
				<Wrapper.VideoWrapper className="md:relative">
					<IconButton className="absolute top-2 right-2 z-20" onClick={handleClose}>
						<Icon name="close" group="filled" fill="#ffffff" />
					</IconButton>
					{isYTVid ? (
						<YouTube
							videoId={`${getVideoId(url)}`}
							opts={{
								width: "100%",
								height: "100%",
								playerVars: {
									autoplay: autoplay ? 1 : 0
								}
							}}
						/>
					) : (
						<video className="w-full h-full rounded-lg object-cover" controls autoPlay={autoplay}>
							<source src={url} />
						</video>
					)}
				</Wrapper.VideoWrapper>
			</ClickAwayListener>
		</Wrapper>
	);
};
export default FullScreenVideoDialog;
