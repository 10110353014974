import { Box } from "@material-ui/core";
import styled from "styled-components";

export const About = styled(Box)`
	padding: 12px 16px 20px;
	border-radius: 12px;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08), inset 0 0 0 0.5px #c5cee0;
	background-color: #fff;
	margin-bottom: 16px;
	position: relative;
	.read-more {
		color: ${({ color }) => color};
	}
`;

About.InfoRows = styled(Box)`
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
`;
