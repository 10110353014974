import React, { FC, useMemo, useRef } from "react";

import { IconButton } from "@material-ui/core";

import { Controller, useForm } from "react-hook-form";

import { ReactComponent as LikeIcon } from "assets/icons/icon-heart-empty.svg";
import { ReactComponent as SendIcon } from "assets/icons/icon-send.svg";
import { useFirebase, useMessaging } from "modules/Messaging/Data";
import { ChatMessage } from "modules/Messaging/Data/types";
import { useNotification, useStory, useUser } from "shared/hooks";
import { PersonStoryModel } from "shared/types";
import { Loader } from "shared/ui-kit";
import { updateTextareaEl } from "utils/updateTextareaEl";

import { CommentInput, InteractWithStoryWrapper } from "./style";

const StoryCommentInput: FC<{
	showControls: boolean;
	currentStory: PersonStoryModel;
}> = ({ showControls, currentStory }) => {
	const commentInputRef = useRef<HTMLTextAreaElement>(null);

	const { getData: getStoryData } = useStory();
	const { currentStorySlide } = getStoryData();

	const activeStoryId = useMemo(
		() =>
			!!currentStory &&
			!!currentStory.stories.length &&
			currentStory.stories[currentStorySlide] &&
			currentStory.stories[currentStorySlide]._id,
		[currentStory, currentStorySlide]
	);

	const { createConnection } = useMessaging();

	const { showMessage } = useNotification();
	const { onSend } = useFirebase();

	const { getActiveProfile, getData: getUserData, getProfilePicture } = useUser();
	const { user } = getUserData();

	const {
		handleSubmit,
		control,
		formState: { isSubmitting },
		watch,
		setValue
	} = useForm({ mode: "onChange" });

	const { comment = "" } = watch(["comment"]);

	const activeProfile = useMemo(() => getActiveProfile(user), [getActiveProfile, user]);

	const onSubmit = async data => {
		if (!data.comment?.length) return;

		const chatResponse = await createConnection(currentStory.personaId);

		if (chatResponse && activeStoryId) {
			const sendingMessage: Partial<ChatMessage> = {
				text: data.comment,
				user: {
					_id: activeProfile?.chatUserId,
					name: activeProfile?.firstName,
					avatar: getProfilePicture(user)
				},
				storyReply: {
					...currentStory,
					activeStoryId
				}
			};

			onSend({ messages: [sendingMessage], chat: chatResponse });
			showMessage(`Reply was sent to ${currentStory.firstName}`);
			setValue("comment", "");
			updateTextareaEl(commentInputRef, 3);
		} else {
			// Ideally we should not ever get here.
			showMessage("Error sending message");
		}
	};

	return (
		<InteractWithStoryWrapper className={showControls && "visible"}>
			<Controller
				name="comment"
				control={control}
				render={({ onChange, value }) => (
					<CommentInput
						rows="1"
						placeholder="Write a comment..."
						variant="outline"
						className="write-a-comment"
						onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
							onChange(e.target.value);
							updateTextareaEl(commentInputRef, 3);
						}}
						onKeyDown={(e: React.KeyboardEvent<HTMLTextAreaElement>) => {
							if (e.key === "Enter" && !e.shiftKey) {
								e.preventDefault();
								handleSubmit(onSubmit)();
							}
						}}
						value={value}
						ref={commentInputRef}
					/>
				)}
			/>

			{isSubmitting ? (
				<Loader size="20px" className="sending-message" show variant="indeterminate" color="inherit" />
			) : (
				<IconButton size="small" onClick={handleSubmit(onSubmit)}>
					<SendIcon className={!!comment.length ? "visible" : ""} />
					<LikeIcon className={!comment.length ? "visible" : ""} />
				</IconButton>
			)}
		</InteractWithStoryWrapper>
	);
};

export default StoryCommentInput;
