import styled from "styled-components";

export const FundraiserSummary = styled.div`
	color: #8f9bb3;
`;

export const StatusChip = styled.div`
	font-size: 10px;
	padding: 2px 0;

	&.pending,
	&.paused {
		border-color: #6173fe;
		color: #6173fe;
	}

	&.ended,
	&.archived {
		border-color: #222b45;
		color: #222b45;
	}

	&.active {
		border-color: #23b34a;
		color: #23b34a;
	}
`;

export const FundraiserMoney = styled.p`
	color: rgba(47, 55, 66, 1);
	font-size: 18px;
	font-weight: 700;
	span {
		color: #8f9bb3;
	}
`;
