import { Box } from "@material-ui/core";

import styled from "styled-components";

import { Icon } from "shared/ui-kit";

export const StyledIcon = styled(Icon)`
	padding-right: 5px;
`;

export const PaginationWrapper = styled(Box)`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	padding: 10px 24px;

	.MuiPagination-root {
		.MuiPagination-ul {
			li {
				&:first-child,
				&:last-child {
					background-color: #f0f3fe;

					button {
						color: #6173fe;
					}
				}

				.MuiButtonBase-root {
					color: #222b45;
				}

				.MuiPaginationItem-page.Mui-disabled {
					svg {
						color: #8f9bb3;
					}
				}

				.MuiPaginationItem-page.Mui-selected {
					color: #fff;
					background: #6173fe;
				}

				.MuiPaginationItem-icon {
					font-size: 1.8rem;
				}
			}
		}
	}

	.MuiToolbar-root {
		.MuiTablePagination-selectRoot {
			padding: 0 6px;
			background: #f7f9fc;
			display: flex;
			align-items: center;
		}

		.MuiSelect-select {
			color: #6173fe;
			text-align-last: center;
		}

		.MuiSelect-icon {
			right: 7px;
		}

		.MuiTablePagination-actions {
			display: none;
		}
	}
`;

export const CellWrapper = styled(Box)`
	display: flex;
	align-items: center;
`;

export const TableWrapper = styled(Box)`
	border-top: 1px solid #edf1f7;
	border-radius-right-top: 0;
	border-top-right-radius: 0;
	border-top-left-radius: 0;
	box-shadow: none;

	.MuiInputBase-root.Mui-disabled {
		color: #222b45;
	}

	.MuiSelect-outlined.MuiSelect-outlined {
		display: flex;
		align-items: center;
	}

	.ReactVirtualized__Grid {
		&:focus {
			outline: 0;
		}
	}
`;
