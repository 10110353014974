import React, { FC, LegacyRef, useMemo, useRef, useState } from "react";

import { Box } from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import WatchLaterIcon from "@material-ui/icons/WatchLater";

import { Controller, useForm } from "react-hook-form";

import { ReactComponent as ClockIcon } from "assets/icons/clock-filled.svg";
import { ReactComponent as DownloadIcon } from "assets/icons/icon-download.svg";
import {
	DateInput,
	EnableFeature,
	LabeledInput,
	MemberDialog,
	MultiselectInput,
	RectangleUploader,
	SelectInput
} from "modules/MemberHome/View/shared";
import { InputWrapper, MemberDialogContentWrapper, SimpleIconWrapper } from "modules/MemberHome/View/shared/style";

import { SetupPrice } from "shared/Components";
import { useUser } from "shared/hooks";
import useScrollToXPosition from "shared/hooks/useScrollToXPosition";
import { genres } from "shared/types";
import { times } from "shared/types/times";
import { Icon } from "shared/ui-kit";
import { getDefaultTime } from "utils/getDefaultTime";

import { CreateAlbumProps } from "./ManageView";

const CreateAlbumMemberView: FC<CreateAlbumProps> = ({
	open,
	onClose,
	uploadingCover,
	setupPriceFormRef,
	onUploaderChange,
	handleCreateAlbum,
	editAlbumDetails
}) => {
	const formRef = useRef<HTMLFormElement>();
	const scrollToXPositionOnFocus = useScrollToXPosition(formRef?.current?.parentElement);
	const {
		control,
		handleSubmit,
		watch,
		formState: { isDirty, isSubmitting, errors }
	} = useForm({ mode: "onChange" });

	const { schedule } = watch(["schedule"]);

	const { getData: getUserData } = useUser();
	const { user } = getUserData();

	const [submitting, setSubmitting] = useState(false);

	const showPremiumAccess = useMemo(
		() => user?.isGlobalAdmin || user?.isGlobalOwner || user?.isGlobalModerator,
		[user]
	);

	const tomorrow = useMemo(() => new Date().setDate(new Date().getDate() + 1), []);

	const onSubmit = async data => {
		setSubmitting(true);
		await handleCreateAlbum(data);
		setSubmitting(false);
	};

	return (
		<MemberDialog
			customWidth={512}
			open={open}
			title="Create Album"
			onClose={onClose}
			confirmLeave={isDirty}
			footerPrimary={{
				loading: isSubmitting || uploadingCover,
				text: "Create Album",
				disabled: isSubmitting || uploadingCover || !isDirty || submitting,
				onClick: handleSubmit(onSubmit)
			}}
		>
			<form onSubmit={handleSubmit(onSubmit)} ref={formRef as LegacyRef<HTMLFormElement>}>
				<Controller
					name="albumPhoto"
					control={control}
					render={({ onChange }) => (
						<RectangleUploader
							defaultPreviewUrl={editAlbumDetails?.meta.url}
							onChange={(files: any) => onUploaderChange(files, onChange)}
							errorText={errors?.albumPhoto?.message}
						/>
					)}
				/>
				<MemberDialogContentWrapper className="pv-20">
					<Controller
						name="albumTitle"
						control={control}
						rules={{
							required: "Album Title is required.",
							maxLength: {
								value: 100,
								message: "Title can't be more than 100 letters"
							}
						}}
						render={({ onChange, value, ref }) => (
							<InputWrapper>
								<LabeledInput
									name="albumTitle"
									onChange={onChange}
									value={value}
									placeholder=" "
									label="Album title *"
									showCounter={100}
									error={errors?.albumTitle?.message}
									inputRef={ref}
								/>
							</InputWrapper>
						)}
					/>
					<Controller
						name="artist"
						control={control}
						defaultValue={editAlbumDetails && editAlbumDetails.artist}
						rules={{
							required: "Artist name is required.",
							maxLength: {
								value: 100,
								message: "Artist name can't be more than 100 letters"
							}
						}}
						render={({ onChange, value, ref }) => (
							<InputWrapper>
								<LabeledInput
									placeholder=" "
									name="artist"
									error={errors.artist?.message}
									label="Artist *"
									onChange={onChange}
									value={value}
									inputRef={ref}
								/>
							</InputWrapper>
						)}
					/>
					<Controller
						name="genre"
						control={control}
						rules={{
							required: "Genre is required."
						}}
						render={({ onChange, value }) => (
							<InputWrapper onFocus={scrollToXPositionOnFocus}>
								<MultiselectInput value={value} onChange={onChange} options={genres} error={errors?.genre?.message} />
							</InputWrapper>
						)}
					/>
					<Controller
						name="description"
						control={control}
						defaultValue={editAlbumDetails && editAlbumDetails.description}
						rules={{
							required: "Description is required.",
							maxLength: {
								value: 1000,
								message: "Description can't be more than 1000 letters"
							}
						}}
						render={({ onChange, value, ref }) => (
							<InputWrapper>
								<LabeledInput
									textarea
									error={errors.description?.message}
									name="description"
									onChange={onChange}
									value={value}
									label="Description *"
									placeholder=" "
									showCounter={1000}
									inputRef={ref}
								/>
							</InputWrapper>
						)}
					/>
					<Controller
						name="releaseDate"
						control={control}
						defaultValue={editAlbumDetails && editAlbumDetails.releaseDate}
						render={({ onChange, value = null, ref }) => (
							<InputWrapper>
								<DateInput
									name="releaseDate"
									label="Release Date"
									placeholder=" "
									error={errors.releaseDate?.message}
									value={value}
									onChange={onChange}
									displayOnly
									inputRef={ref}
								/>
							</InputWrapper>
						)}
					/>
					{showPremiumAccess && (
						<InputWrapper>
							<Controller
								name="priceChanged"
								control={control}
								render={({ onChange }) => (
									<SetupPrice
										ref={setupPriceFormRef}
										defaultValue={editAlbumDetails?.priceTags}
										onPriceChanged={() => onChange(true)}
									/>
								)}
							/>
						</InputWrapper>
					)}
					<Controller
						name="individualAccess"
						control={control}
						defaultValue={editAlbumDetails?.individualAccess}
						render={({ onChange, value }) => (
							<InputWrapper>
								<EnableFeature
									fill="#8f9bb3"
									icon={<Icon fill="#c5cee0" name="folder-open-gray" group="color" />}
									onChange={onChange}
									title="Allow access to content in Album"
									description="Allow users to buy or access the individual content in the album without buying entire album."
									value={value}
									verticalPadding
								/>
							</InputWrapper>
						)}
					/>
					<Controller
						name="schedule"
						control={control}
						defaultValue={!!editAlbumDetails?.scheduleDate}
						render={({ onChange, value }) => (
							<InputWrapper>
								<EnableFeature
									fill="#c5cee0"
									icon={<WatchLaterIcon />}
									onChange={onChange}
									value={value}
									title="Schedule Album"
								/>
							</InputWrapper>
						)}
					/>
					{schedule && (
						<Box className="two-inputs-row">
							<Controller
								name="date"
								control={control}
								defaultValue={tomorrow}
								render={({ onChange, value, ref }) => (
									<InputWrapper width="60%">
										<DateInput
											label="Date *"
											name="date"
											value={value}
											onChange={onChange}
											error={errors?.date?.message}
											placeholder=" "
											displayOnly
											inputRef={ref}
										/>
									</InputWrapper>
								)}
							/>
							<Controller
								name="time"
								control={control}
								defaultValue={getDefaultTime()}
								render={({ onChange, value, ref }) => (
									<InputWrapper width="38%">
										<SelectInput
											label="Time *"
											name="time"
											value={value && value.label}
											onChange={onChange}
											placeholder=" "
											options={times}
											maxHeight={180}
											rightIcon={{
												el: <ClockIcon />,
												active: false
											}}
											displayOnly
											noOptionsHeadline="Select Time Option"
											selectInputRef={ref}
										/>
									</InputWrapper>
								)}
							/>
						</Box>
					)}
					<Controller
						name="downloadable"
						control={control}
						defaultValue={editAlbumDetails?.canBeDownloaded}
						render={({ onChange, value }) => (
							<InputWrapper>
								<EnableFeature
									icon={
										<SimpleIconWrapper className="member fill-second-path">
											<DownloadIcon />
										</SimpleIconWrapper>
									}
									onChange={onChange}
									value={value}
									title="Allow Download"
								/>
							</InputWrapper>
						)}
					/>
					<Controller
						name="private"
						control={control}
						defaultValue={editAlbumDetails?.private}
						render={({ onChange, value }) => (
							<InputWrapper>
								<EnableFeature
									fill="#c5cee0"
									icon={<VisibilityIcon />}
									onChange={onChange}
									value={value}
									title="Set as Private"
								/>
							</InputWrapper>
						)}
					/>
				</MemberDialogContentWrapper>
			</form>
		</MemberDialog>
	);
};

export default CreateAlbumMemberView;
