import React, { useCallback, useEffect, useState } from "react";

import { useHistory, useParams } from "react-router";

import { EventType } from "types";

import { view } from "modules/LiveConversation";
import useLiveConversation from "modules/LiveConversation/Data/hooks/useLiveConversation";

import { useEvent, useMemberRoutes } from "shared/hooks";
import { Loader } from "shared/ui-kit";

const LiveConvoMainPage = () => {
	const history = useHistory();

	const { id } = useParams() as { id: string };

	const { getData: getLiveConversationData, resetConvo, notifyOnStart } = useLiveConversation();
	const { event, roomId } = getLiveConversationData();

	const { getEvents } = useEvent();
	const { getMemberRoutesData } = useMemberRoutes();
	const { routes } = getMemberRoutesData();

	const [loading, setLoading] = useState(true);

	const fetchEventInfo = useCallback(
		async (liveConvoId: string) => {
			const data = await getEvents({
				page: 1,
				limit: 1,
				eventSchedule: EventType.Live_Conversation,
				liveConversationIds: [liveConvoId]
			});
			if (data?.totalEventsFound && data?.events?.length) {
				history.replace(`${routes?.member.event.getPath()}/${data.events[0].eventId}`);
			} else {
				history.go(-1);
			}
		},
		[getEvents, history, routes?.member.event]
	);

	useEffect(() => {
		if (id && roomId === id && id === event?.liveConversationId && event?.eventId) {
			setLoading(false);
			notifyOnStart(id);
		} else {
			setLoading(true);
			fetchEventInfo(id);
		}
	}, [event, roomId, id, fetchEventInfo, notifyOnStart]);

	useEffect(() => {
		return () => {
			resetConvo(true);
		};
	}, [resetConvo]);

	return loading ? (
		<Loader size="15px" color="secondary" show variant="intermediate" />
	) : (
		<view.MainConversation id={id} />
	);
};

export default LiveConvoMainPage;
