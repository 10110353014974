import React, { memo, useCallback, useEffect, useMemo, useState } from "react";

import * as R from "ramda";

import styled from "styled-components";

import { PostBlockEventType } from "types";

import { ReactComponent as SoundWaveIcon } from "assets/icons/iconSoundWave.svg";
import { useStream, useTrack, useUser } from "shared/hooks";
import { PlaceholderImageType, TrackMeta, TrackModel, genres } from "shared/types";

import ItemTemplate from "./ItemTemplate";
import PlayBtn, { PlayStatus } from "./PlayBtn";

import { TrackMessageModel } from "../../types/TrackModel";
import { PlaceholderImage } from "../index";

const SoundWave = styled(SoundWaveIcon)`
	width: 38px;
	height: 43px;

	path {
		&:nth-child(2) {
			fill: #8f9bb3;
		}
	}
`;

const getGenreName = key => {
	return genres.find(g => g.key === key)?.value;
};

type Props = {
	track?: TrackModel | TrackMessageModel;
	trackId?: string;
	nextTrackId?: string;
	playableObjectId?: string;
	id?: string;
	onEvent?: (eventType: PostBlockEventType, id: string, args?: any) => void;
	maxWidth?: boolean;
	postView?: boolean;
	messageBlockCurrentUser?: boolean;
	link?: string;
	isMarketing?: boolean;
};

const TrackBlock: React.FC<Props> = memo(
	({
		track,
		trackId,
		id,
		onEvent,
		maxWidth,
		nextTrackId,
		playableObjectId,
		postView,
		messageBlockCurrentUser,
		link,
		isMarketing
	}) => {
		const [trackInfo, setTrackInfo] = useState<TrackModel | TrackMessageModel>();
		const [audio, setAudio] = useState<HTMLAudioElement>();
		const [loading, setLoading] = useState<boolean>(false);
		const [progress, setProgress] = useState<number>(0);
		const [isTrackDeleted, setIsTrackDeleted] = useState<boolean>(false);

		const { getTrack } = useTrack(isMarketing);
		const { startStream, pauseStream } = useStream();

		const { getData: getUserData } = useUser();
		const { isMemberView } = getUserData();

		useEffect(() => {
			if (trackId) {
				(async () => {
					setLoading(true);
					const data = await getTrack({ trackId });
					if (data) {
						setTrackInfo(data);
					} else {
						setIsTrackDeleted(true);
					}

					setLoading(false);
				})();
			}
		}, [getTrack, trackId]);

		useEffect(() => {
			return () => {
				audio && audio.pause();
			};
		}, [audio]);

		useEffect(() => {
			if (track && !trackInfo) {
				setTrackInfo(track);
			}
		}, [track, trackInfo]);

		const toggleAudio = useCallback(() => {
			if (!audio) return;

			const correctTrackId = trackId || (trackInfo as TrackModel)._id;

			if (audio.paused) {
				audio.play();
				onEvent && onEvent(PostBlockEventType.trackStart, `${id}_${correctTrackId}`);
				startStream({ entityId: correctTrackId });
			} else {
				audio.pause();
				pauseStream({ entityId: correctTrackId, current: audio.currentTime });
			}
		}, [audio, trackId, trackInfo, onEvent, id, startStream, pauseStream]);

		useEffect(() => {
			if (
				nextTrackId &&
				trackId &&
				((nextTrackId === trackId && audio?.paused) || (nextTrackId !== trackId && !audio?.paused))
			) {
				toggleAudio();
			}
		}, [nextTrackId, trackId, toggleAudio, audio]);

		useEffect(() => {
			if (playableObjectId && !audio?.paused && playableObjectId !== `${id}_${trackId}`) {
				toggleAudio();
			}
		}, [playableObjectId, trackId, toggleAudio, audio, id]);

		useEffect(() => {
			const updateTrackProgress = data => {
				const newProgress = (data.currentTarget.currentTime * 100) / data.currentTarget.duration;
				setProgress(newProgress);

				if (onEvent && newProgress === 100) {
					onEvent(PostBlockEventType.trackEnd, `${id}`, { trackId });
				}
			};

			if ((trackInfo as TrackModel)?.meta && ((trackInfo as TrackModel)?.meta as TrackMeta)?.track?.url) {
				const trackAudio = new Audio(((trackInfo as TrackModel).meta as TrackMeta).track.url);
				trackAudio.addEventListener("timeupdate", updateTrackProgress);
				setAudio(trackAudio);
			}

			if ((trackInfo as TrackMessageModel)?.url) {
				const trackAudio = new Audio((trackInfo as TrackMessageModel)?.url);
				trackAudio.addEventListener("timeupdate", updateTrackProgress);
				setAudio(trackAudio);
			}
		}, [trackInfo, onEvent, id, trackId]);

		const convertTrackToPostTrack = useMemo(() => {
			if (trackInfo && (trackInfo as TrackModel)?._id) {
				const correctTrackInfo = trackInfo as TrackModel;
				return {
					...R.pick(["guid", "type", "personaId", "duration", "title", "description", "genre"], correctTrackInfo),
					id: correctTrackInfo._id,
					url: correctTrackInfo?.trackObj?.url || (correctTrackInfo?.meta as TrackMeta)?.track?.url || "",
					date: correctTrackInfo.date,
					artwork: correctTrackInfo?.artObj?.url || (correctTrackInfo?.meta as TrackMeta)?.artwork?.url || "",
					artist: correctTrackInfo.artist || (correctTrackInfo?.meta as TrackMeta)?.artist || "",
					artObj: correctTrackInfo.artObj || (correctTrackInfo?.meta as TrackMeta)?.artwork,
					trackObj: correctTrackInfo.trackObj || (correctTrackInfo?.meta as TrackMeta)?.track,
					stream: {}
				};
			}

			return "";
		}, [trackInfo]);

		const img = useMemo(() => {
			return (
				((trackInfo as TrackModel)?.meta as TrackMeta)?.artwork?.url || (trackInfo as TrackMessageModel)?.artwork || ""
			);
		}, [trackInfo]);

		return (
			<ItemTemplate
				link={link}
				featureName={"enableAudioTracks"}
				id={id}
				wrapperProps={{
					maxWidth,
					id,
					onEvent,
					"data-name": "audio",
					"data-track": encodeURIComponent(JSON.stringify(convertTrackToPostTrack)),
					hideCloseButton: isMemberView,
					postView,
					messageBlockCurrentUser
				}}
				loading={loading}
				onClose={() => onEvent && id && onEvent(PostBlockEventType.delete, id)}
				coverUrl={img}
				previewIcon={<SoundWave />}
				placeholderImg={
					<PlaceholderImage type={PlaceholderImageType.TRACK_PREVIEW} width={80} height={80} viewBox={"0 0 400 400"} />
				}
				pretitle={`${trackInfo?.genre?.map(getGenreName).slice(0, 2).join(", ")}${
					trackInfo?.genre && trackInfo?.genre?.length > 2 ? "..." : ""
				}`}
				indicatorIcon={<SoundWave />}
				title={trackInfo?.title}
				subtitle={((trackInfo as TrackModel)?.meta as TrackMeta)?.artist || (trackInfo as TrackMessageModel)?.artist}
				extraBlock={
					<PlayBtn
						progress={progress}
						onTogglePlay={toggleAudio}
						state={audio?.paused ? PlayStatus.paused : PlayStatus.playing}
						messageBlockCurrentUser={messageBlockCurrentUser}
					/>
				}
				isDeleted={isTrackDeleted}
			/>
		);
	}
);

export default TrackBlock;
