import { DateTime } from "luxon";
import { ForumPostModel } from "types";
import { zones } from "tzdata";

import { CategoryModel } from "shared/types";
import { IStateProps } from "utils/StateOf";

import createStore from "utils/createStore";

export interface Values {
	categories: CategoryModel[];
	timezones: any[];
	loading: boolean;
	creatingOrUpdating?: boolean;
	editablePost?: ForumPostModel;
}

export const initialState: IStateProps<Values> = {
	categories: [],
	loading: false,
	creatingOrUpdating: false,
	timezones: Object.entries(zones)
		.filter(([, v]) => Array.isArray(v))
		.map(([zoneName]) => zoneName)
		.filter(tz => DateTime.local().setZone(tz).isValid)
};

export default createStore<Values>({
	initialState
});
