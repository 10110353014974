import styled from "styled-components";

import { ReactComponent as ArrowRight } from "assets/icons/arrow-right.svg";
import { Box, Text } from "shared/ui-kit";

export const Wrapper = styled(Box)`
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 50px;
	padding-left: 15px;
	&:hover {
		background: #edf1f7;
	}
`;

export const InfoWrapper = styled(Box)`
	display: flex;
	align-items: center;
`;

export const IconWrapper = styled(Box)`
	width: 32px;
	height: 32px;

	margin-right: 16px;

	border-radius: 4px;

	overflow: hidden;
`;

export const Info = styled(Box)`
	display: block;
`;

export const Title = styled(Text)`
	font-size: 13px;
	font-weight: 600;
	line-height: 1.85;
	color: #222b45;
	margin: 0;
	max-width: 200px;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
`;

export const Link = styled(Text)`
	font-size: 13px;
	line-height: 1.23;
	color: #8f9bb3;
	margin: 0;
	max-width: 200px;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
`;

export const StyledArrowRight = styled(ArrowRight)`
	margin-right: 2px;
`;
