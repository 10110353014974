import React from "react";

import { useLocation, useParams } from "react-router";

import { view } from "modules/MemberHome";
import { FeatureFlags } from "shared/Components/MemberNavPanel/constants";
import useCheckIfEnabled from "shared/hooks/useCheckIfFlagEnabled";

const CollectionPage = () => {
	useCheckIfEnabled(FeatureFlags.collections);

	const { id } = useParams() as { id: string };
	const { search } = useLocation();
	const query = new URLSearchParams(search);
	const groupId = query.get("groupId") || undefined;

	return <view.Collection id={id} groupId={groupId} />;
};

export default CollectionPage;
