import React, { useCallback, useEffect, useMemo, useState } from "react";

import { Chip, InputAdornment, Select, TextField } from "@material-ui/core";

import styled, { css } from "styled-components";

import { ReactComponent as SearchIcon } from "assets/icons/iconSearch.svg";

import { FilterBlock, FilterOptionType } from "modules/MemberHome/View/Components";
import { useGroup, useUser } from "shared/hooks";
import { GroupModel, GroupSearchParams } from "shared/types";
import { Box, MenuItem, Text } from "shared/ui-kit";

import GroupItem from "./GroupItem";

import { GroupSortBy } from "../../types/GroupModel";

import SelectItemTemplate from "../Blocks/SelectItemTemplate";
import { EmptyBody, ScrollableBlock } from "../index";

const FilterWrapper = styled(Box)`
	margin: 16px 0;
	display: flex;
	flex-direction: row;
	align-content: center;
	justify-content: flex-start;

	${({ inline }) =>
		!inline &&
		css`
			div,
			button :not(:first-child) {
				margin-left: 8px;
			}

			${props => props.theme.breakpoints.down("sm")} {
				display: none;
			}
		`}
`;

const CustomChip = styled(Chip)`
	height: 36px;
	margin-top: 4px;
`;

const CustomSelect = styled(Select)`
	height: 40px;
	border-radius: 20px;

	.Mui-focused {
		background: transparent !important;
	}
`;

const ContentWrapper = styled(Box)`
	height: ${({ isMemberView }) => (isMemberView ? "340px" : "50vh")};
`;

const GroupItemsWrapper = styled(Box)`
	${props => props.theme.breakpoints.down("sm")} {
		display: flex;
		flex-direction: column;
		margin-top: 15px;
	}
`;

const sortOptions = [
	{ label: "Newest to Oldest", value: "newest" },
	{ label: "Oldest to Newest", value: "oldest" },
	{ label: "Most members first", value: "members" },
	{ label: "Name", value: "name" }
];

const groupTypeOptions = [
	{ label: "All", value: "all" },
	{ label: "Public", value: "public" },
	{ label: "Private", value: "private" }
];

const LoaderBlock = () => (
	<>
		<SelectItemTemplate loading />
		<SelectItemTemplate loading />
		<SelectItemTemplate loading />
	</>
);

interface Props {
	onSelect: (g: GroupModel) => void;
}

const SelectGroupBody: React.FC<Props> = ({ onSelect }) => {
	const { getPaginatedGroup, getData: getGroupData } = useGroup();
	const { shouldStopSearching, filteredGroups, loading } = getGroupData();

	const { getData: getUserData } = useUser();
	const { isMemberView } = getUserData();

	const [filter, setFilter] = useState<GroupSearchParams & { concatGroups: boolean }>({
		page: 1,
		limit: 10,
		name: "",
		groupType: "all",
		sortBy: GroupSortBy.PLACEHOLDER,
		concatGroups: true
	});

	useEffect(() => {
		getPaginatedGroup(filter);
	}, [filter, getPaginatedGroup]);

	const { name, sortBy, groupType, isMyGroups } = filter;

	const handleSearchChange = useCallback(
		e => {
			const {
				target: { name: inputName, value }
			} = e;

			let v = value;

			if (inputName === "isMyGroups") {
				v = !isMyGroups;
			}

			setFilter({ ...filter, page: 1, [inputName]: v });
		},
		[filter, isMyGroups]
	);

	const filterOptions: FilterOptionType[] = useMemo(
		() => [
			{
				label: "Sort By",
				onClick: value =>
					handleSearchChange({
						target: { name: "sortBy", value }
					}),
				listOptions: sortOptions
			},
			{
				label: "All",
				onClick: value =>
					handleSearchChange({
						target: { name: "groupType", value }
					}),
				listOptions: groupTypeOptions
			},
			{
				inlineOptions: true,
				onClick: () =>
					handleSearchChange({
						target: { name: "isMyGroups", value: false }
					}),
				listOptions: [
					{
						label: "My Groups",
						value: "MY_GROUPS"
					}
				]
			}
		],
		[handleSearchChange]
	);

	const onEndScroll = useCallback(() => {
		!loading && !shouldStopSearching && setFilter(x => ({ ...x, page: x.page + 1 }));
	}, [shouldStopSearching, loading, setFilter]);

	return (
		<>
			<TextField
				value={name}
				onChange={handleSearchChange}
				name="name"
				placeholder="Search"
				variant="outlined"
				InputProps={{
					startAdornment: (
						<InputAdornment position="start">
							<SearchIcon />
						</InputAdornment>
					)
				}}
			/>
			{isMemberView ? (
				<FilterWrapper inline>
					<FilterBlock inline options={filterOptions} />
				</FilterWrapper>
			) : (
				<FilterWrapper>
					<CustomSelect name="sortBy" value={sortBy} onChange={handleSearchChange} variant="outlined">
						<MenuItem className="menuItem" value="placeholder" disabled>
							<Text>Sort by</Text>
						</MenuItem>
						{sortOptions.map((i, index) => (
							<MenuItem key={index} className="menuItem" value={i.value}>
								<Text>{i.label}</Text>
							</MenuItem>
						))}
					</CustomSelect>
					<CustomSelect name="groupType" value={groupType} onChange={handleSearchChange} variant="outlined">
						{groupTypeOptions.map((i, index) => (
							<MenuItem key={index} className="menuItem" value={i.value}>
								<Text>{i.label}</Text>
							</MenuItem>
						))}
					</CustomSelect>
					<CustomChip
						name="isMyGroups"
						variant={isMyGroups ? "default" : "outlined"}
						label="My Groups"
						onClick={() => handleSearchChange({ target: { name: "isMyGroups", value: filter.isMyGroups } })}
					/>
				</FilterWrapper>
			)}

			<ContentWrapper isMemberView={isMemberView}>
				<ScrollableBlock onEndScroll={onEndScroll}>
					{filteredGroups?.length ? (
						<GroupItemsWrapper>
							{filteredGroups.map(g => (
								<GroupItem key={g._id} {...g} onSelect={() => onSelect(g)} />
							))}
							{loading && <LoaderBlock />}
						</GroupItemsWrapper>
					) : (
						<>{loading ? <LoaderBlock /> : <EmptyBody>Groups</EmptyBody>}</>
					)}
				</ScrollableBlock>
			</ContentWrapper>
		</>
	);
};

export default SelectGroupBody;
