import { useMemo } from "react";

import useHttpActions from "shared/services/api/core/useHttpActions";

import useMediaApiService from "shared/services/useMediaApiService";

import { convertUpdateVideo, convertVideo } from "./converters";
import BaseConverter from "./converters/baseConverter";

import { API_URLS } from "../constants";
import { MediaType, UpdateVideoFormModel, VideoFormModel, VideoModel, VideoSortBy } from "../types";

const useVideoApiService = () => {
	const actions = useHttpActions();
	const mediaService = useMediaApiService();

	const methods = useMemo(
		() => ({
			prepareUpdateQueryParams: async (personaId: string | number, videoFileUrl?: string, videoImageUrl?: string) => {
				const defaultQuery = {
					personaId: personaId as number,
					expireAt: "infinite" as number | "infinite"
				};

				let videoFileMedia, videoImageMedia;
				if (videoFileUrl) {
					videoFileMedia = await mediaService.createMedia({
						...defaultQuery,
						type: MediaType.manageVideo,
						uri: videoFileUrl
					});
				}

				if (videoImageUrl) {
					videoImageMedia = await mediaService.createMedia({
						...defaultQuery,
						type: MediaType.videoPhoto,
						uri: videoImageUrl
					});
				}

				return {
					videoFileMedia,
					videoImageMedia
				};
			},
			getVideo: async (id: string) => {
				return BaseConverter.convert(
					await actions.get<{ success: boolean; video: VideoModel }>(API_URLS.VIDEOS.GET_BY_ID(id))
				);
			},
			getVideos: async ({
				offset = 1,
				limit = 10,
				keyword = "",
				groupId,
				eventId,
				order,
				sortBy,
				personaDocId
			}: {
				offset?: number;
				limit?: number;
				keyword?: string;
				groupId?: string;
				eventId?: string;
				order?: number;
				sortBy?: VideoSortBy;
				personaDocId?: string;
			}) => {
				const query: {
					order?: number;
					offset?: number;
					limit?: number;
					keyword?: string;
					groupId?: string;
					eventId?: string;
					sortBy?: VideoSortBy;
					personaDocId?: string;
				} = {
					keyword,
					offset,
					limit,
					sortBy,
					personaDocId
				};

				if (groupId) {
					query.groupId = groupId;
				}

				if (eventId) {
					query.eventId = eventId;
				}

				if (order) {
					query.order = order;
				}

				return BaseConverter.convert<{ success: boolean; videos: VideoModel[] }>(
					await actions.get(API_URLS.VIDEOS.GET_ALL, query)
				);
			},
			getVideoCount: async ({
				keyword,
				eventId,
				groupId,
				personaDocId
			}: {
				keyword?: string;
				eventId?: string;
				groupId?: string;
				personaDocId?: string;
			}) => {
				return BaseConverter.convert<{ success: boolean; totalCount: number }>(
					await actions.get(API_URLS.VIDEOS.GET_COUNT, { keyword, eventId, groupId, personaDocId })
				);
			},
			createVideo: async (data: VideoFormModel, personaId: number | "") => {
				const { videoFileMedia, videoImageMedia } = await methods.prepareUpdateQueryParams(
					personaId,
					data.videoFileUrl,
					data.videoImageUrl
				);

				return BaseConverter.convert<{ success: boolean; video: VideoModel }>(
					await actions.post(
						API_URLS.VIDEOS.CREATE,
						convertVideo(data, videoFileMedia[0], videoImageMedia ? videoImageMedia[0] : null)
					)
				);
			},
			updateVideo: async (data: UpdateVideoFormModel, personaId: number | "") => {
				const { videoImageMedia } = await methods.prepareUpdateQueryParams(personaId, "", data.videoImageUrl);

				return BaseConverter.convert<{ success: boolean; video: VideoModel }>(
					await actions.put(
						API_URLS.VIDEOS.UPDATE(data._id),
						convertUpdateVideo(data, videoImageMedia ? videoImageMedia[0] : null)
					)
				);
			},
			deleteVideo: async (id: string) => {
				return BaseConverter.convert<{ success: boolean }>(await actions.delete(API_URLS.VIDEOS.DELETE(id)));
			}
		}),
		[actions, mediaService]
	);

	return useMemo(
		() => ({
			...methods
		}),
		[methods]
	);
};

export default useVideoApiService;
