import React, { FC, useEffect, useRef, useState } from "react";

import { useStory } from "shared/hooks";
import { StoryModel } from "shared/types";
import { getVideoDimensions } from "utils/getVideoDimensions";

import { StoryWrapper } from "./style";

interface StoryBlockProps {
	story: StoryModel;
	isActive: boolean;
	currentStory: boolean;
	userData: {
		id: string;
		name: string;
		profilePicture?: string;
	} | null;
}

const StoryBlock: FC<StoryBlockProps> = ({ story, isActive, currentStory, userData }) => {
	const { getData: getStoryData } = useStory();
	const { muteStory, pauseStory } = getStoryData();

	const [isVertical, setIsVertical] = useState(false);
	const imageRef = useRef<HTMLImageElement>(null);
	const videoRef = useRef<HTMLVideoElement>(null);

	useEffect(() => {
		if (imageRef.current) {
			const width = imageRef.current.clientWidth;
			const height = imageRef.current.clientHeight;

			setIsVertical(height > width);
		}

		if (videoRef.current) {
			getVideoDimensions(story.uri).then(({ width, height }) => {
				setIsVertical(height > width);
			});
		}
	}, [story.uri]);

	useEffect(() => {
		if (isActive && videoRef.current && currentStory) {
			pauseStory ? videoRef.current.pause() : videoRef.current.play();
			videoRef.current.muted = muteStory;
		} else {
			videoRef.current?.pause();
		}
	}, [currentStory, isActive, muteStory, pauseStory]);

	if (!currentStory) return null;

	return (
		<StoryWrapper isActive={isActive} isVertical={isVertical}>
			{story.type === "video" ? (
				<video ref={videoRef} loop>
					<source src={story.uri} />
					Your browser does not support the video tag.
				</video>
			) : (
				<img ref={imageRef} src={story.uri} alt={userData?.name} />
			)}
		</StoryWrapper>
	);
};

export default StoryBlock;
