import React, { FC } from "react";

import styled from "styled-components";

const StyledImg = styled.img`
	background-color: #fff;
`;

const ImgBlock: FC<{ src: string; id: string }> = ({ src, id }) => {
	return <StyledImg src={src} alt="" id={id} />;
};

export default ImgBlock;
