import React, { FC, useCallback, useMemo, useRef, useState } from "react";

import { Box } from "@material-ui/core";

import { useHistory } from "react-router-dom";

// import { ReactComponent as StoryIcon } from "assets/icons/icon-camera.svg";
import { ReactComponent as IconBubble } from "assets/icons/icon-chat-bubble.svg";
import { ReactComponent as LockIcon } from "assets/icons/icon-lock.svg";
import { ReactComponent as SlashIcon } from "assets/icons/icon-slash.svg";
// import { ReactComponent as MessageIcon } from "assets/icons/message.svg";
// import { ReactComponent as MuteIcon } from "assets/icons/mute.svg";
// import { ReactComponent as ShareIcon } from "assets/icons/share.svg";

import { ReactComponent as ReportIcon } from "assets/icons/liveconvo/report.svg";
import { ConfirmActionModal, MenuDots, MenuDotsOption, PlaceholderImage } from "shared/Components";
import {
	useCommunity,
	useConnection,
	useMedia,
	useMemberRoutes,
	useNotification,
	usePersona,
	useReportContent,
	useUser
} from "shared/hooks";
import { MediaModel, MediaType, PlaceholderImageType, ProfileType, ReportContentType } from "shared/types";
import { Button, Icon, Loader, Text } from "shared/ui-kit";

import { Header, MainActionButton } from "./style";

import { ConnectionAction } from "../Connections/ConnectionCard";
import { StyledButton } from "../Connections/style";

const ProfileHeader: FC<{
	profile: ProfileType;
	isLiteProfile: boolean;
	me?: boolean;
	handleConnect?: () => Promise<void>;
	handleManage?: ({ connection, action }: { connection: ProfileType; action: ConnectionAction }) => void;
}> = ({ profile, me, handleConnect, isLiteProfile, handleManage }) => {
	const history = useHistory();

	const inputRef = useRef<HTMLInputElement>(null);
	const { uploadFiles } = useMedia();

	const { communityColors } = useCommunity();

	const { updatePersona } = usePersona();

	const { setUpdateUserDialog, blockUser, getData: getUserData, isPrivilegedRole } = useUser();
	const { user } = getUserData();

	const { deleteUserConnection } = useConnection();

	const { showMessage } = useNotification();
	const { getReportTypeOptions } = useReportContent();

	const { getMemberRoutesData } = useMemberRoutes();
	const { routes } = getMemberRoutesData();

	const [uploading, setUploading] = useState(false);

	const [showRemoveConnectionModal, setShowRemoveConnectionModal] = useState(false);
	const [showBlockConnectionModal, setShowBlockConnectionModal] = useState(false);

	const triggerFilePicker = useCallback(() => {
		inputRef.current?.click();
	}, []);

	const handleUpdateCover = useCallback(
		async (files: FileList | null) => {
			if (files) {
				setUploading(true);
				const data = await uploadFiles({ files, type: MediaType.coverPhoto });

				if ((data[0], user)) {
					await updatePersona(
						{
							...profile,
							coverPhotos: [{ mediaId: data[0].mediaId || data[0]._id, uri: data[0].uri, active: true }]
						},
						true
					);

					showMessage("Successfully updated profile cover");
				}
			}
			setUploading(false);
		},
		[uploadFiles, user, updatePersona, profile, showMessage]
	);

	const isPendingConnection = useMemo(() => !me && profile.connection?.status === "waiting", [me, profile]);
	const isPendingRequestForYou = useMemo(() => {
		return isPendingConnection && profile?.connection?.connecteeId === user?.activeProfile;
	}, [user?.activeProfile, profile?.connection, isPendingConnection]);

	const isAConnection = useMemo(() => !me && profile.connection?.status === "accepted", [me, profile]);

	const moreActions = useMemo(() => {
		const options: MenuDotsOption[] = [
			// {
			// 	icon: <StoryIcon />,
			// 	name: `View ${profile.firstName}'s Story`,
			// 	onClick: () => {}
			// },
			// {
			// 	icon: <ShareIcon className="fill-first-path" />,
			// 	name: `Share ${profile.firstName}'s Profile`,
			// 	onClick: () => {}
			// },
			// {
			// 	icon: <MessageIcon />,
			// 	name: `Message ${profile.firstName}`,
			// 	onClick: () => {}
			// },
			// {
			// 	icon: <MuteIcon />,
			// 	name: "Mute activities",
			// 	onClick: () => {}
			// },
			{
				icon: <Icon name="user-minus" group="filled" fill="#8f9bb3" />,
				name: "Remove connection",
				onClick: () => {
					setShowRemoveConnectionModal(true);
				}
			},
			{
				icon: <SlashIcon />,
				name: "Block",
				onClick: () => {
					setShowBlockConnectionModal(true);
				}
			}
		];

		if (!isPrivilegedRole) {
			options.push({
				icon: <ReportIcon viewBox="0 -2 22 22" fill="#8f9bb3" />,
				name: "Report",
				onClick: () => {},
				submenuOptions: getReportTypeOptions({
					reportType: ReportContentType.PERSONA,
					reportContentId: `${profile?.personaDocId}`,
					reportContentName: `${profile?.firstName || ""} ${profile?.lastName || ""}`,
					customCallback: () => {
						history.go(-1);
					}
				})
			});
		}

		return options;
	}, [profile, history, getReportTypeOptions, isPrivilegedRole]);

	const closeRemoveConnectionModal = () => {
		setShowRemoveConnectionModal(false);
	};

	const closeBlockConnectionModal = () => {
		setShowBlockConnectionModal(false);
	};

	const confirmRemoveConnection = () => {
		setShowRemoveConnectionModal(false);
		if (profile?.connection?._id) {
			deleteUserConnection(profile?.connection?._id);
		}
		history.go(-1);
	};

	const confirmBlockConnection = () => {
		setShowBlockConnectionModal(false);
		if (profile?.personaId) {
			blockUser(profile?.personaId);
		}
		history.go(-1);
	};

	const handleGoToMessages = () => {
		history.push(`${routes?.member.messaging.getPath()}?messageTo=${profile.personaId}`);
	};

	const handleConnection = useCallback(
		(action: ConnectionAction.accept | ConnectionAction.decline) => {
			profile?.connection &&
				handleManage &&
				handleManage({ connection: profile.connection as unknown as ProfileType, action });
		},
		[handleManage, profile?.connection]
	);

	return (
		<>
			<Header>
				<Header.Cover>
					{!!profile.coverPhotos?.length && <img src={(profile.coverPhotos[0] as MediaModel).uri} alt="cover" />}
					{(!profile.coverPhotos || !profile.coverPhotos.length) && (
						<Box className="cover-placeholder">
							<PlaceholderImage type={PlaceholderImageType.PROFILE_COVER} />
						</Box>
					)}
					{me && (
						<>
							<Header.EditButton disabled={uploading} onClick={triggerFilePicker}>
								{uploading ? (
									<Loader variant="indeterminate" size="16px" color="secondary" show />
								) : (
									<Icon name="pencil" group="filled" fill="white" width={16} height={16} />
								)}
							</Header.EditButton>
							<input
								type="file"
								hidden
								ref={inputRef}
								accept="image/*"
								onChange={e => handleUpdateCover(e.target.files)}
							/>
						</>
					)}
				</Header.Cover>
				<Header.Content>
					<Header.ContentImageWrapper>
						{profile.photos.length && (profile.photos[0] as MediaModel)?.profilePicture ? (
							<img src={(profile.photos[0] as MediaModel).profilePicture} alt={profile.firstName} />
						) : (
							<PlaceholderImage
								type={PlaceholderImageType.PROFILE_IMAGE}
								width={160}
								height={160}
								viewBox={"0 0 400 400"}
							/>
						)}
					</Header.ContentImageWrapper>
					<Text variant="h2">
						{profile.firstName} {profile.lastName} {me && "(You)"}
					</Text>
					{!isLiteProfile && (
						<>
							{profile.intro && !!profile.intro.job?.length && (
								<Text variant="h6">
									{profile.intro.job[0].jobTitle.label} @ {profile.intro.job[0].company.label}
								</Text>
							)}
							{profile.intro && !!profile.intro.job?.length && (
								<Text className="bio">{profile.intro.job[0].industry.label}</Text>
							)}
						</>
					)}
					{me ? (
						<Header.Actions>
							<MainActionButton buttonTheme="light" palette="basic" onClick={() => setUpdateUserDialog(true)}>
								<Icon name="pencil" group="filled" width={16} height={16} />
								Edit Profile
							</MainActionButton>
						</Header.Actions>
					) : (
						<>
							{isAConnection && (
								<Header.Actions>
									<MenuDots memberView options={moreActions} />
								</Header.Actions>
							)}
							<Box className="flex">
								{isPendingRequestForYou ? (
									<Header.ButtonActions>
										<StyledButton
											buttonTheme={"light"}
											palette="light"
											onClick={handleGoToMessages}
											leftIcon={<IconBubble fill={communityColors.btn} />}
											square
											className="w-auto px-4"
										/>
										<StyledButton
											buttonTheme={"light"}
											palette="light"
											fullWidth
											onClick={() => handleConnection(ConnectionAction.accept)}
											leftIcon={<Icon name="user-plus" group="filled" fill={communityColors.btn} />}
										>
											Accept
										</StyledButton>
										<StyledButton
											palette="basic"
											buttonTheme="light"
											fullWidth
											onClick={() => handleConnection(ConnectionAction.decline)}
											className="hover-to-black"
											leftIcon={<Icon name="user-minus" group="filled" />}
										>
											Reject
										</StyledButton>
									</Header.ButtonActions>
								) : (
									<Header.ButtonActions>
										{(profile.vyooable || isAConnection) && !me && (
											<StyledButton
												buttonTheme={"light"}
												palette="light"
												fullWidth
												onClick={handleGoToMessages}
												leftIcon={<IconBubble fill={communityColors.btn} />}
											>
												Message
											</StyledButton>
										)}
										{/* <Button buttonTheme="light" onClick={handleGoToMessages}>
											<IconBubble fill="#ffffff" />
											<Text>Message</Text>
										</Button> */}

										{/* <Button buttonTheme="light" onClick={handleConnect}>
												<Icon name="user-plus" group="filled" fill="#ffffff" />
												<Text>Connect</Text>
											</Button> */}
										{isPendingConnection && !isPendingRequestForYou && (
											<Button buttonTheme="light" disabled>
												<Icon name="message-check" group="filled" />
												<Text>Pending</Text>
											</Button>
										)}
										{!isPendingConnection && !isAConnection && (
											<StyledButton
												buttonTheme={"light"}
												palette="light"
												fullWidth
												onClick={handleConnect}
												leftIcon={<Icon name="user-plus" group="filled" fill={communityColors.btn} />}
											>
												Connect
											</StyledButton>
										)}
									</Header.ButtonActions>
								)}
							</Box>
						</>
					)}
					{!profile.vyooable && !isAConnection && !me && (
						<Header.Private color={communityColors.primary} className="flex flex-col items-center">
							<LockIcon />
							<Text variant="h3">This account is private</Text>
							<Text>
								Only connections can see their <br /> profile and activities
							</Text>
						</Header.Private>
					)}
				</Header.Content>
			</Header>
			<ConfirmActionModal
				open={showRemoveConnectionModal}
				onClose={closeRemoveConnectionModal}
				title="Remove Connection?"
				bodyText={"Are you sure you want to remove connection with this person?"}
				onConfirm={confirmRemoveConnection}
			/>
			<ConfirmActionModal
				open={showBlockConnectionModal}
				onClose={closeBlockConnectionModal}
				title="Block Connection?"
				bodyText={"Are you sure you want to block connection?"}
				onConfirm={confirmBlockConnection}
			/>
		</>
	);
};

export default ProfileHeader;
