import React, { useCallback, useEffect, useMemo, useState } from "react";

import Box from "@material-ui/core/Box";

import styled from "styled-components";

import { ReactComponent as PauseIcon } from "assets/icons/pause.svg";
import { ReactComponent as PlayIcon } from "assets/icons/play.svg";

const ProgressWrapper = styled(Box)`
	display: flex;
	justify-content: center;
	align-items: center;

	width: 80px;
	height: 80px;

	position: relative;

	cursor: pointer;

	.progress-ring__circle {
		transition: 0.2s stroke-dashoffset;
		// axis compensation
		transform: rotate(-90deg);
		transform-origin: 50% 50%;
	}
`;

const IconWrapper = styled(Box)`
	position: absolute;

	top: 0;
	left: 0;

	width: 100%;
	height: 100%;

	display: flex;
	justify-content: center;
	align-items: center;

	&.white-svg svg path:last-child {
		fill: #ffffff;
	}
`;

export enum PlayStatus {
	playing = "playing",
	paused = "paused"
}

type PlayBtnProps = {
	progress: number;
	onTogglePlay: () => void;
	state: PlayStatus;
	messageBlockCurrentUser?: boolean;
};

const PlayBtn: React.FC<PlayBtnProps> = ({ progress, state, onTogglePlay, messageBlockCurrentUser }) => {
	const [strokeDashOffset, setStrokeDashOffset] = useState(0);

	const options = useMemo(
		() => ({
			size: 80,
			strokeWidth: 4,
			backStrokeFill: messageBlockCurrentUser ? "#fff" : "#E4E9F2",
			fillOpacity: messageBlockCurrentUser ? "0.4" : "1",
			strokeFill: messageBlockCurrentUser ? "#fff" : "#8F9BB3",
			radius: 80 / 2 - 4 * 2
		}),
		[messageBlockCurrentUser]
	);

	const [circumference] = useState(options.radius * 2 * Math.PI);

	useEffect(() => {
		setStrokeDashOffset(circumference - (progress / 100) * circumference);
	}, [progress, circumference]);

	const togglePlay = useCallback(() => {
		onTogglePlay();
	}, [onTogglePlay]);

	return (
		<ProgressWrapper onClick={togglePlay}>
			<svg className="progress-ring" width="80" height="80">
				<circle
					className="progress-ring__circle"
					stroke={options.backStrokeFill}
					strokeWidth={options.strokeWidth}
					strokeOpacity={options.fillOpacity}
					fill="transparent"
					strokeLinecap="round"
					strokeDashoffset={0}
					strokeDasharray={`${circumference} ${circumference}`}
					r={options.radius}
					cx={options.size / 2}
					cy={options.size / 2}
				/>
				<circle
					className="progress-ring__circle"
					stroke={options.strokeFill}
					strokeWidth={options.strokeWidth}
					fill="transparent"
					strokeLinecap="round"
					strokeDashoffset={strokeDashOffset}
					strokeDasharray={`${circumference} ${circumference}`}
					r={options.radius}
					cx={options.size / 2}
					cy={options.size / 2}
				/>
			</svg>
			<IconWrapper className={messageBlockCurrentUser && "white-svg"}>
				{state === PlayStatus.playing && <PauseIcon />}
				{state === PlayStatus.paused && <PlayIcon />}
			</IconWrapper>
		</ProgressWrapper>
	);
};

export default PlayBtn;
