import { Button, Menu, MenuItem } from "@material-ui/core";
import styled, { css } from "styled-components";

import { Box, Text } from "shared/ui-kit";

export const Wrapper = styled(Box)<{
	size?: {
		extraSmall?: boolean;
		small?: boolean;
		middle?: boolean;
	};
	removeSideMargin?: boolean;
}>`
	width: ${({ size }) => (size?.extraSmall ? 24 : size?.small ? 32 : 40)}px;
	height: ${({ size }) => (size?.extraSmall ? 24 : size?.small ? 32 : 40)}px;

	margin-left: ${({ removeSideMargin }) => (removeSideMargin ? 0 : "8px")};
`;

export const Option = styled(Box)``;

Option.Text = styled(Text)<{ withIcon?: boolean }>`
	font-size: 13px;
	font-weight: 600;
	line-height: 1.85;
	letter-spacing: normal;
	color: #222b45;

	margin-left: ${({ withIcon }) => (withIcon ? "8px" : "0")};
`;

export const MoreActionsButton = styled(Button)<{
	inverted?: boolean;
	vertical?: boolean;
	removebg?: boolean;
	removeshadow?: boolean;
	btnSize?: {
		extraSmall?: boolean;
		small?: boolean;
		middle?: boolean;
	};
	circular?: boolean;
}>`
	border-radius: 8px;

	padding: ${({ btnSize }) => (btnSize?.extraSmall ? 4 : 8)}px;
	margin: 0;

	width: ${({ btnSize }) => (btnSize?.extraSmall ? 24 : btnSize?.small ? 32 : 40)}px;
	height: ${({ btnSize }) => (btnSize?.extraSmall ? 24 : btnSize?.small ? 32 : 40)}px;
	min-width: auto;

	display: flex;
	align-items: center;
	justify-content: center;

	border: 0;

	${({ removeshadow }) =>
		removeshadow
			? css`
					box-shadow: unset;
			  `
			: css`
					border: 1px solid
						${({ inverted, removebg }) => (inverted || removebg ? "transparent" : "rgba(197, 206, 224, 0.5)")};
					box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
			  `}

	background-color: ${({ inverted, removebg }) => (inverted || removebg ? "transparent" : "#fff")};

	${({ circular }) =>
		circular &&
		css`
			border-radius: 99%;
		`}

	cursor: pointer;

	svg {
		transform: rotate(${({ vertical }) => (vertical ? "0" : "90deg")});

		${({ inverted }) => {
			return (
				inverted &&
				css`
					path {
						&:nth-child(2) {
							fill: white;
						}
					}
				`
			);
		}}
	}

	&:hover {
		background-color: ${({ inverted, removebg }) => (inverted || removebg ? "transparent" : "#f7f9fc")};
	}
`;

export const StyledMenu = styled(Menu)`
	padding: 8px;
	${({ customWidth }) =>
		customWidth &&
		css`
			> .MuiPaper-root {
				width: 240px;
			}
		`}
`;

export const StyledMenuItem = styled(MenuItem)`
	border-radius: 8px;

	height: ${({ customOptionHeight }) => (customOptionHeight ? `${customOptionHeight}px` : "auto")};

	&.left-aligned {
		padding-left: 8px;
	}

	${({ dontFillSvg }) =>
		!dontFillSvg &&
		css`
			svg {
				fill: #8f9bb3;
				&.fill-both-paths path {
					fill: #8f9bb3;
				}
				&.fill-first-path path:nth-child(1) {
					fill: #8f9bb3;
				}
				&.fill-second-path path:nth-child(2) {
					fill: #8f9bb3;
				}
				&.fill-use use {
					fill: #8f9bb3;
				}
			}
		`}
`;

StyledMenuItem.IconWrapper = styled(Box)`
	width: 25px;
	height: 100%;

	display: flex;
	justify-content: center;
	align-items: center;
`;
