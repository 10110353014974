import React, { memo, useCallback, useMemo } from "react";

import { DateTime } from "luxon";
import styled, { css } from "styled-components";

import { useAnalytics, useCommunity } from "shared/hooks";

import { StatsItemKey } from "shared/types";
import { Box } from "shared/ui-kit";

import BlockHeader from "../BlockHeader";
import NoDataFound from "../NoDataFound";
import { SingleLineChart } from "../index";

const Wrapper = styled(Box)`
	display: flex;
	flex-direction: column;

	width: 100%;
`;

const BodyWrapper = styled(Box)<{ centeredContent: boolean }>`
	min-height: 214px;

	${({ centeredContent }) => {
		if (centeredContent) {
			return css`
				align-items: center;
				justify-content: center;
				display: flex;
			`;
		}
	}}
`;

interface ActiveUsersProps {
	displayCsvDownload: boolean;
}

const ActiveUsers: React.FC<ActiveUsersProps> = memo(({ displayCsvDownload }) => {
	const { getActiveUsers, getActiveUsersCSVFile, getData: getAnalyticsData } = useAnalytics();
	const { totalActiveUsers, activeUsersData } = getAnalyticsData();

	const { getData: getCommunityData } = useCommunity();
	const { workspace } = getCommunityData();

	const changePeriod = useCallback(
		(requestedFrom: DateTime, requestedTo: DateTime) => {
			getActiveUsers(requestedFrom, requestedTo);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[getActiveUsers, workspace]
	);

	const ChartBlock = useMemo(
		() => (
			<SingleLineChart
				data={activeUsersData}
				dataKey={StatsItemKey.activeUsers}
				gradientId={"activeUsersGradientId"}
				color={"#23b34a"}
				stopColor={"rgba(35, 179, 74, 0)"}
			/>
		),
		[activeUsersData]
	);

	return (
		<Wrapper>
			<BlockHeader
				title={totalActiveUsers.toString()}
				subtitle={"Total active users"}
				showDropdown
				onChangePeriod={changePeriod}
				onDownloadCsv={displayCsvDownload ? getActiveUsersCSVFile : undefined}
				csvFilenamePrefix="active_users_report"
			/>
			<BodyWrapper centeredContent={!activeUsersData.length}>
				{activeUsersData.length > 0 ? <>{ChartBlock}</> : <NoDataFound />}
			</BodyWrapper>
		</Wrapper>
	);
});

export default ActiveUsers;
