import styled from "styled-components";

import { Box, Text } from "shared/ui-kit";

export const Wrapper = styled(Box)`
	display: flex;
	justify-content: flex-start;
	align-items: center;
`;

Wrapper.Text = styled(Text)`
	font-size: 13px;
	line-height: 1.54;
	letter-spacing: normal;
	color: #fff;
	text-transform: uppercase;

	margin-left: 8px;
`;
