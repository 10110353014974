import { DateTime } from "luxon";

import { ForumPersonaModel } from "types";

import { PriceTag, ProfileType } from "shared/types";

export interface GroupMembersType {
	loading: boolean;
	data: ForumPersonaModel[];
}

export enum MemberStatus {
	all = "all",
	pending = "pending",
	admins = "admins",
	connections = "connections",
	members = "members"
}

export enum MemberSortBy {
	newest = "newest",
	oldest = "oldest",
	postCount = "postCount"
}

interface CoordinatesType {
	lat: number;
	lon: number;
}

interface Member {
	bbUserId: number;
	isAdmin: boolean;
	isInvited: boolean;
	isMember: boolean;
	isModerator: boolean;
	isOwner: boolean;
	isPending: boolean;
	personaId: number;
	userId: string | null;
}

export interface BaseGroupEntity {
	name: string;
	description: string;
	hidden: boolean;
	private: number;
	rules: string;
	location: string;
	placeId: string;
	coverUrl?: string;
	isNational: boolean;
	parentId: null | string;
	subgroups: GroupModel[];
}

export interface GroupFormModel extends Pick<BaseGroupEntity, "name" | "description" | "coverUrl" | "rules"> {
	hidden: boolean;
	private: "public" | "private";
	location;
	chatEnabled: boolean;
	audience: "local" | "national";
	cid?: string;
	slug?: string;
	placeId?: string;
	disablePosts: boolean;
	photoChanged?: boolean;
	placeChanged?: boolean;
	priceTags?: PriceTag[] | [];
	parentId?: string;
}

export interface GroupRolesType {
	isPending: boolean;
	isMember: boolean;
	isOwner: boolean;
	isAdmin: boolean;
	isModerator: boolean;
	isInvited?: boolean;
}

export interface GroupModel extends BaseGroupEntity {
	_id: string;
	chatEnabled: boolean;
	chatEnabledUpdatedAt: DateTime | null;
	cid: string;
	displayName: string;
	coordinates: CoordinatesType;
	disablePosts: boolean;
	groupChatId: string | null;
	members: Member[];
	oldSlug: string | null;
	organizerId: number;
	organizedby?: Pick<ProfileType, "firstName" | "lastName" | "photos">;
	postId: string;
	post?: {
		lastPostedAt?: string | null;
	};
	slug: string;
	totalMembers: number;
	totalPosts: number;
	boardId: string;
	createdAt: string;
	updatedAt: string;
	parentGroup?: GroupModel;
	createtimeISO: Date;
	roles?: GroupRolesType;
	// TODO: remove after upgrade API
	isPending?: boolean;
	isMember?: boolean;
	isOwner?: boolean;
	isAdmin?: boolean;
	isModerator?: boolean;
	totalSubGroups?: number;
	locked?: boolean;
	priceTags?: PriceTag[];
	purchase?: [];
	inviter?: {
		firstName: string;
		lastName: string;
		photos: { profilePicture: string }[];
		personaId: number;
		_id: string;
	};
	potentialMatches: {
		_id: string;
		firstName: string;
		lastName: string;
		photos?: {
			mediaId: string;
			id: string;
			name: string;
			active: boolean;
			profilePicture: string;
		}[];
		intro: {
			education: any[];
			job: {
				active: boolean;
				companyId: string;
				titleId: string;
				industryId: string;
				companyLabel: string;
				titleLabel: string;
				industryLabel: string;
			}[];
		};
		personaId: number;
	}[];
	totalPotentialMatches?: string;
	hasRequests: boolean;
}

export interface GroupModelResponse {
	groups: GroupModel[];
}

export interface GroupModelRequest {
	payload: Pick<
		BaseGroupEntity,
		"name" | "description" | "hidden" | "private" | "rules" | "location" | "placeId" | "coverUrl" | "isNational"
	> & {
		latitude: number;
		longitude: number;
	};
	path: string;
	method: string;
	token: string | null;
}

export enum GroupSortBy {
	NEWEST = "newest",
	OLDEST = "oldest",
	MEMBERS = "members",
	PLACEHOLDER = "placeholder",
	POPULAR = "popular",
	NATIONAL = "national"
}

export type groupType = "all" | "public" | "private";

export interface GroupSearchParams {
	page: number;
	limit: number;
	name: string | null;
	sortBy: GroupSortBy;
	groupType: groupType;
	isMyGroups?: boolean;
	groupRelation?: "popular";
	potentialMatchingIds?: string[];
	searchAll?: boolean;
	filterBy?: string[];
	parentId?: string;
	preventStateUpdate?: boolean;
	noSubgroups?: boolean;
}

export interface groupModalType {
	open: boolean;
	model?: GroupModel;
	isSubgroup?: boolean;
	defaultParent?: GroupModel;
}
