import _ from "lodash";

export default ({ schema: { type, max }, validation }) =>
	type === "types" && [
		"validate",
		value => {
			const exceedsMaxLength = _.some(value, ({ text }) => text.length > max);
			if (exceedsMaxLength) {
				return _.template(validation.max)({ model: { max } });
			}
			return true;
		}
	];
