import { Box } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import styled from "styled-components";

const DialogWrapper = styled(Dialog)`
	.MuiDialog-paper {
		overflow: visible;
		width: 480px;
		height: 440px;
		border-radius: 12px;
		padding: 40px;
	}
`;

const DialogCloseButton = styled.button`
	border: 0;
	padding: 0;
	background: none;
	position: absolute;
	top: 25px;
	right: 25px;
	cursor: pointer;
`;

const DialogTitle = styled.div`
	font-size: 22px;
	font-weight: bold;
	text-align: center;
`;

const DialogSubtitle = styled.div`
	margin-top: 8px;
	color: #8f9bb3;
	text-align: center;
`;

export { DialogWrapper, DialogCloseButton, DialogTitle, DialogSubtitle };

export const Fields = styled.div``;

export const InputWrapper = styled(Box)`
	margin: 16px 0;
	position: relative;
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: center;

	input:disabled {
		cursor: pointer;
		color: #8f9bb3;
	}

	.show-password {
		position: absolute;
		right: 0;
		margin-top: 25px;
	}

	.MuiInputLabel-outlined {
		transform: translate(14px, 17px) scale(1);

		&.MuiInputLabel-shrink {
			transform: translate(14px, -6px) scale(0.75);
		}
	}
`;
