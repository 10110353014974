import React from "react";

import { Box, IconButton } from "@material-ui/core";

import { useAlbums, usePlayer } from "shared/hooks";
import { CircularProgress, Icon, Text } from "shared/ui-kit";

import { TrackProgressWrapper } from "./style";

const TracksProgress = () => {
	const { setTrackProgressDialog, getData: getAlbumsData } = useAlbums();
	const { trackProgressDialog } = getAlbumsData();

	const { getData: getPlayerData } = usePlayer();
	const { playTracks } = getPlayerData();

	if (!trackProgressDialog.length) return null;

	return (
		<TrackProgressWrapper abovePlayer={!!(playTracks.tracks?.items || playTracks.series?.items || []).length}>
			<Box className="header">
				<Text>
					Zipping files •{" "}
					{`${trackProgressDialog.filter(track => track.progress === 100).length}/${trackProgressDialog.length}`}
				</Text>
				<Box className="close">
					<IconButton size="small" onClick={() => setTrackProgressDialog([])}>
						<Icon name="close" fill="#201515" />
					</IconButton>
				</Box>
			</Box>
			<Box className="list">
				{trackProgressDialog.map((track, i) => (
					<Box key={i} className="item">
						{track.progress === 100 ? (
							<Icon name="check-circle" group="filled" fill="#189646" />
						) : (
							<CircularProgress size={24} value={track.progress} palette="secondary" />
						)}
						<Text className="track-name">{track.name}</Text>
						<Text>{track.progress}%</Text>
					</Box>
				))}
			</Box>
		</TrackProgressWrapper>
	);
};

export default TracksProgress;
