import React from "react";

import config from "config/appConfig";

import MetaTags from "react-meta-tags";
import { useLocation, useParams } from "react-router-dom";

import { routes as authRoutes } from "apps/Auth/constants";
import { routesGenerator } from "apps/Member/constants";
import { routes as adminRoutes } from "apps/RegularUser/constants";

import { view } from "modules/Marketing";

const MarketingPage = () => {
	const { name } = useParams<{ name: string }>();
	const { search } = useLocation();
	const query = new URLSearchParams(search);

	const switchCommunity = query.get("switchCommunity") || undefined;

	const memberRoutes = routesGenerator(name);

	return (
		<>
			<MetaTags>
				<meta name="apple-itunes-app" content={`app-id=${config.GLOBAL_CONSTANTS.APPLE_APP_ID}`} />
			</MetaTags>
			<view.Marketing
				homeRoute={adminRoutes.dashboard.getPath()}
				communityName={name}
				notFoundUrl={config.CORE_API_URLS.NOT_FOUND}
				memberHomeUrl={memberRoutes?.member.home.getPath()}
				loginUrl={authRoutes.auth.signIn.getPath()}
				marketingUrl={adminRoutes.community.getPath()}
				fillProfileUrl={memberRoutes?.member.fillProfile.getPath()}
				communityListRoute={authRoutes.auth.communities.getPath()}
				joinCommunitiesUrl={adminRoutes.communities.getPath()}
				switchCommunity={!!switchCommunity}
			/>
		</>
	);
};

export default MarketingPage;
