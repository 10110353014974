import React from "react";

import styled from "styled-components";

import { SupportItemType } from "modules/Support/Data/types";
import { Box } from "shared/ui-kit";

const DescriptionBox = styled(Box)`
	white-space: pre-line;
`;

const SupportItem = ({ item }: { item: SupportItemType }) => <DescriptionBox>{item.description}</DescriptionBox>;

export default SupportItem;
