import { Box } from "@material-ui/core";

import { Scrollbars } from "react-custom-scrollbars";
import styled, { css } from "styled-components";

import { Button, Text } from "shared/ui-kit";

const headerHight = "64px";
const footerHight = "64px";

export const ScrollBarWrapper = styled(Scrollbars)`
	max-height: ${({ noFooter }) => `${noFooter ? "100vh" : "70vh"}`};

	@media (max-height: 500px) {
		max-height: ${({ noHeader, noFooter }) =>
			`${`calc(100vh - ${noHeader ? "0px" : headerHight} - ${noFooter ? "0px" : footerHight})`}`};
	}

	&.scrollContent > div:first-child {
		max-height: 100vh;
		position: relative !important;
	}
`;

export const RefBox = styled(Box)`
	min-height: 1px;
`;

const sharedDialogContentStyles = css`
	&.no-header-padding {
		padding-top: 0;
	}

	&.no-footer-padding {
		padding-bottom: 0;
	}
`;

export const DialogHeader = styled(Box)`
	position: relative;
	top: 0;
	left: 0;
	z-index: 10000;
	background-color: #fff;
	display: flex;
	justify-content: center;
	align-items: center;

	border-bottom: 1px solid #edf1f7;

	width: 100%;
	height: ${headerHight};
	p {
		font-size: 18px;
		font-weight: bold;
		line-height: 0;
		color: #222b45;
		text-align: center;
	}
	.action-button {
		position: absolute;
		height: 100%;
		display: flex;
		align-items: center;
	}
	.close {
		right: 20px;
		top: 0;
	}
	.back {
		left: 20px;
		top: 0;
	}
`;

DialogHeader.Progress = styled(Box)`
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 4px;
	border-radius: 2px;
	background-color: ${({ color }) => color || "#6173fe"};
	transition: 0.3s ease-in-out all;
	${({ width }) => css`
		transform: translateX(${-100 + width}%);
	`}
`;

export const DialogContent = styled(Box)`
	${sharedDialogContentStyles}
`;

export const DialogContentWithoutScroll = styled(Box)`
	${sharedDialogContentStyles}
`;

export const DialogFooter = styled.form`
	position: relative;

	background-color: #fff;

	padding: 12px 16px;

	box-shadow: 0 -0.5px 0px #c5cee0;

	width: 100%;

	z-index: 1;

	button {
		border-radius: 10px;
		width: 100%;
		margin: 0;
		&.circular {
			border-radius: 50px;
		}
		&.small {
			font-size: 12px;
		}
		/* &.primary {
			background-color: #6173fe;
		} */

		&.black-text {
			color: #000000;
		}

		&.Mui-disabled {
			color: rgba(0, 0, 0, 0.26);
			background-color: rgba(143, 155, 179, 0.16);
		}
		&:nth-child(even) {
			margin-top: 8px;
		}
		.MuiButton-label {
			display: flex;
			align-items: center;
			justify-content: center;
			div {
				margin-right: 4px;
			}
		}
	}

	${props => props.theme.breakpoints.up("sm")} {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		padding: 0 24px;
		height: ${footerHight};
		button {
			width: auto;
			margin-left: 8px;
			&:nth-child(even) {
				margin-top: 0;
			}
		}
	}

	&.xs-footer-flex {
		${props => props.theme.breakpoints.down("xs")} {
			display: flex;
			align-items: center;
			justify-content: space-between;
			button {
				width: auto;
				margin-left: 8px;
				&:nth-child(even) {
					margin-top: 0;
				}
			}
		}
	}
`;

DialogFooter.TextWrapper = styled(Box)`
	${props => props.theme.breakpoints.up("sm")} {
		position: absolute;
		top: 0;
		left: 16px;
		height: 100%;
		display: flex;
		align-items: center;
	}
`;

export const LoadingWrapper = styled(Box)`
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 40px 0;
`;

export const SectionHeader = styled(Text)`
	font-size: 18px;
	font-weight: bold;
	line-height: 1.33;
	color: #222b45;
	margin-bottom: 16px;
`;

export const SecondaryButton = styled(Button)`
	&.grayBg {
		background-color: #edf1f7;
	}
`;
