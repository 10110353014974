import { Box, ButtonBase } from "@material-ui/core";
import styled, { css } from "styled-components";

import { Button, Text } from "shared/ui-kit";

export const Entity = styled(Box)``;

Entity.Wrapper = styled(Box)`
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08), inset 0 0 0 0.5px #c5cee0;
	background: white;
	border-radius: 12px;
	padding: 16px;

	${props => props.theme.breakpoints.up("sm")} {
		padding: 40px;
	}
`;

Entity.InfoRow = styled(Box)`
	${props => props.theme.breakpoints.up("sm")} {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;

		width: 100%;
		height: 224px;
	}
`;

Entity.PreviewWrapper = styled(Box)<{ coverPhoto?: string }>`
	width: 224px;
	height: 224px;

	border-radius: 12px;
	box-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.1);
	background-color: #c5cee0;

	display: flex;
	justify-content: center;
	align-items: center;

	overflow: hidden;

	${({ coverPhoto }) =>
		coverPhoto &&
		css`
			background: url(${coverPhoto}) no-repeat center;
			background-size: cover;
		`}
`;

Entity.FileTypeIconWrapper = styled(Box)`
	width: 64px;
	height: 64px;

	display: flex;
	justify-content: center;
	align-items: center;
`;

Entity.Info = styled(Box)`
	width: 100%;
`;

Entity.InfoColumn = styled(Box)`
	margin-top: 16px;
	${props => props.theme.breakpoints.up("sm")} {
		width: calc(100% - 224px);
		height: 100%;
		display: flex;
		margin-top: 0;
		flex-direction: column;
		justify-content: space-between;
		align-items: flex-start;
		padding-left: 40px;
	}
`;

Entity.Pretitle = styled(Text)`
	font-size: 15px;
	font-weight: 600;
	line-height: 1.6;
	color: #222b45;
`;

Entity.Title = styled(Text)`
	font-size: 30px;
	font-weight: bold;
	line-height: 1.33;
	color: #222b45;

	margin-top: 4px;

	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2; /* number of lines to show */
	line-clamp: 2;
	-webkit-box-orient: vertical;

	width: 100%;
`;

Entity.Subtitle = styled(Text)`
	font-size: 15px;
	font-weight: 600;
	line-height: 1.6;
	color: #222b45;

	margin-top: 8px;
`;

Entity.Extra = styled(Text)`
	font-size: 13px;
	font-weight: normal;
	line-height: 1.23;
	color: #8f9bb3;

	margin-top: 6px;
`;

Entity.Summary = styled(Text)`
	font-size: 13px;
	line-height: 1.54;
	color: #222b45;
`;

Entity.Manage = styled(Box)`
	display: flex;
	align-items: center;

	width: 100%;
`;

Entity.ManageOptionWrapper = styled(Box)`
	button {
		margin-right: 8px;
	}
`;

Entity.DescriptionTitle = styled(Text)`
	margin-top: 24px;

	font-size: 15px;
	font-weight: 600;
	line-height: 1.6;
	color: #222b45;
`;

Entity.Description = styled(Text)`
	font-size: 13px;
	font-weight: normal;
	color: #222b45;
	white-space: pre-line;

	width: 50%;
`;

export const EventHeader = styled(Box)`
	border-radius: 12px;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
	box-shadow: inset 0 0 0 0.5px #c5cee0;
	background-color: #fff;
	img {
		width: 100%;
		max-height: 480px;
		object-fit: cover;
		border-top-left-radius: 12px;
		border-top-right-radius: 12px;
	}
`;

EventHeader.Info = styled(Box)`
	padding: 8px 16px 72px;
	position: relative;
	p {
		font-weight: 600;
		line-height: 1.6;
		color: #8f9bb3;
		margin-bottom: 4px;
	}
	h6 {
		color: #ff463d;
		margin-bottom: 4px;
	}
	h4 {
		color: #222b45;
		margin-bottom: 4px;
	}
	${props => props.theme.breakpoints.up("sm")} {
		padding: 24px 16px;
	}
`;

EventHeader.EventActions = styled(Box)`
	position: absolute;
	bottom: 16px;
	left: 12px;
	display: flex;
	align-items: center;

	button {
		width: 120px;
		height: 40px;
		border-radius: 8px;
		margin: 0;
		margin-right: 6px;
		svg {
			fill: ${({ active }) => (active ? "#ffffff" : "#6173fe")};
			width: 18px;
			height: 18px;
			margin-right: 6px;
		}
	}

	${props => props.theme.breakpoints.up("sm")} {
		left: unset;
		bottom: unset;
		top: 12px;
		right: 16px;
		button {
			width: 140px;
			margin-right: 0;
			margin-left: 10px;
		}
	}
`;

export const MoreActionsButton = styled(Button)`
	width: 40px !important;
	margin: 0;
	min-width: 0;
	padding: 0;
	svg {
		transform: rotate(90deg);
		margin-right: 0 !important;
	}
`;

export const EventInfo = styled(Box)`
	margin-top: 24px;
	h4 {
		margin-bottom: 24px;
		font-size: 20px;
	}

	${props => props.theme.breakpoints.up("md")} {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
	}
`;

export const EventDetails = styled(Box)`
	flex: 1;
	border-radius: 12px;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
	box-shadow: inset 0 0 0 0.5px #c5cee0;
	background-color: #fff;
	padding: 16px;
	margin-bottom: 20px;
	${props => props.theme.breakpoints.up("md")} {
		margin-bottom: 0;
		margin-right: 16px;
	}
`;

EventDetails.EventInfoRows = styled(Box)`
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
`;

EventDetails.EventInfoRow = styled(Box)`
	display: flex;
	align-items: center;
	margin-bottom: 24px;
	width: 100%;
	p {
		font-size: 15px;
		font-weight: 600;
		line-height: 1.6;
		color: #222b45;
	}
	.secondary {
		font-size: 13px;
		font-weight: 400;
		line-height: 1.23;
		color: #8f9bb3;
	}
	${props => props.theme.breakpoints.up("sm")} {
		width: calc(50% - 8px);
	}
`;

EventDetails.Description = styled(Box)`
	margin-bottom: 20px;
	font-size: 15px;
	line-height: 1.47;
	color: #222b45;
	margin-top: 14px;
	width: 90%;
	.title {
		font-weight: bold;
		margin-bottom: 10px;
	}
`;

export const IconWrapper = styled(Box)`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 40px;
	height: 40px;
	border-radius: 24px;
	background-color: #edf1f7;
	margin-right: 16px;
	flex-shrink: 0;
	img {
		width: 32px;
		height: 32px;
		object-fit: cover;
		border-radius: 99%;
	}
`;

export const EventMembers = styled(Box)`
	width: 100%;
	${props => props.theme.breakpoints.up("md")} {
		width: 320px;
	}
`;

export const MembersList = styled(Box)`
	border-radius: 12px;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
	box-shadow: inset 0 0 0 0.5px #c5cee0;
	background-color: #fff;
	padding: 16px;
	margin-bottom: 24px;
`;

MembersList.MemberRow = styled(ButtonBase)`
	display: flex;
	align-items: center;
	width: 100%;
	text-align: left;
	justify-content: flex-start;
	margin-bottom: 20px;
	img {
		width: 48px;
		height: 48px;
		border-radius: 100px;
		object-fit: cover;
		margin-right: 16px;
	}
	h6 {
		font-size: 15px;
		font-weight: 600;
		line-height: 1.6;
		color: #222b45;
	}
	p {
		font-size: 13px;
		font-weight: 600;
		line-height: 1.85;
		color: #8f9bb3;
	}
`;

MembersList.SeeAll = styled(Button)`
	width: 100%;
`;
export const MoreEvents = styled(Box)`
	margin: 40px 0;
	padding-top: 24px;
	border-top: 1px solid #c5cee0;
	border-radius: 0.3px;
	h4 {
		margin-bottom: 24px;
		margin-left: 8px;
	}
`;

MoreEvents.SliderWrapper = styled(Box)`
	.arrows {
		top: 74px;
		backdrop-filter: blur(20px);
		background-color: rgba(0, 0, 0, 0.24);
		&.arrow-prev {
			left: -16px;
		}
		&.arrow-next {
			right: -16px;
		}
	}
`;
