import { useCallback, useMemo } from "react";

import * as R from "ramda";

import {
	ChatMessage,
	ConnectionSearchModel,
	InboxItem,
	RecentConnections,
	getCreateGroupDatatype,
	getInboxDataType,
	getRecentConnectionDataType
} from "modules/Messaging/Data/types";
import useNotification from "shared/hooks/useNotification";

import { getLastMessage } from "utils/getLastMessage";

import { actionMethod, actionTypes, getValidationMessage } from "utils/getValidationMessage";

import { LastMessageActions } from "../../View/Components/Inbox";
import { useMessagingStore } from "../contexts/MessagingContext";
import { InboxOptions, PreviewDialogType } from "../contexts/MessagingContext/MessagingContext";
import { useMessagingApiService } from "../services";
import { convertConnectionSearch, convertConnections, convertCreateGroup, convertInbox } from "../services/convertors";

const useMessaging = () => {
	const { showMessage } = useNotification();

	const MessagingService = useMessagingApiService();

	const store = useMessagingStore();
	const { setState } = useMessagingStore();

	const methods = useMemo(() => {
		const createGroupChat = async (initiatorId: number) => {
			try {
				return convertCreateGroup(await MessagingService.createGroupChat(initiatorId));
			} catch (error) {
				showMessage((error as Error).message);
			}
		};
		const addMembersGroupChat = async (groupChatId: string, connections: getCreateGroupDatatype[]) => {
			try {
				return convertCreateGroup(await MessagingService.addMembersGroupChat(groupChatId, connections));
			} catch (error) {
				showMessage((error as Error).message);
			}
		};
		const createGroupChatWithMembers = (connections: getCreateGroupDatatype[], initiatorId: number) => {
			if (connections?.length < 2) {
				return;
			}

			return createGroupChat(initiatorId)
				.then(createGroupChatResponse =>
					addMembersGroupChat(createGroupChatResponse!.groupChat._id!, connections).then(
						addMembersGroupChatResponse => addMembersGroupChatResponse
					)
				)
				.then(groupChat => groupChat);
		};

		const createConnection = async (personaId: number) => {
			try {
				const newConnection = convertInbox(await MessagingService.createConnection(personaId));
				setState(ctx => ({
					...ctx,
					inbox: [...newConnection, ...ctx.inbox.filter(i => i.chatUserId !== newConnection[0].chatUserId)]
				}));
				return newConnection[0];
			} catch (error) {
				showMessage((error as Error).message);
			}
		};

		return {
			getInbox: async (data: getInboxDataType) => {
				try {
					data?.keyword?.length
						? setState(ctx => ({ ...ctx, searchingInbox: true }))
						: setState(ctx => ({ ...ctx, loadingInbox: true }));

					const params = R.pick(["offset", "limit", data?.keyword?.length ? "keyword" : "personaId"], data);
					const inbox = convertInbox(await MessagingService.getInbox(params));
					setState(ctx => ({
						...ctx,
						inbox: data.offset === 1 ? inbox : [...ctx.inbox, ...inbox],
						allowLoadMoreInboxes: !!inbox.length && inbox.length === data.limit
					}));

					data?.keyword?.length
						? setState(ctx => ({ ...ctx, searchingInbox: false }))
						: setState(ctx => ({ ...ctx, loadingInbox: false }));
				} catch (error) {
					showMessage((error as Error).message);
				}
			},
			updateInbox: async (data: getInboxDataType, noStateUpdate?: boolean) => {
				try {
					const params = R.pick(
						["offset", "pinned", "limit", data?.keyword?.length ? "keyword" : "personaId", "_id"],
						data
					);
					const inbox = convertInbox(await MessagingService.getInbox(params));
					if (!noStateUpdate) {
						setState(ctx => ({
							...ctx,
							inbox: data.offset === 1 ? inbox : [...ctx.inbox, ...inbox]
						}));
					}

					return inbox;
				} catch (error) {
					showMessage((error as Error).message);
				}
			},
			getArchivedInbox: async (offset: number, limit: number) => {
				const { connections: inbox } = await MessagingService.getArchivedInbox(offset, limit);
				setState({ inbox });
			},
			findGroupChat: (chatId: string) => {
				return MessagingService.findGroupChat(chatId);
			},
			updateInboxLastMessage: (chat: InboxItem, message: ChatMessage, action: LastMessageActions) => {
				setState(ctx => {
					const inboxList = [...ctx.inbox];
					const index = inboxList.findIndex(
						x => (x._id && x._id === chat._id) || (x.chatUserId && x.chatUserId === chat.chatUserId)
					);

					const lastMessage = getLastMessage(message);

					if (index > -1) {
						if (action === LastMessageActions.remove && inboxList[index].lastMessage === message.text) {
							inboxList[index].lastMessage = "";
						}

						if (action === LastMessageActions.update && inboxList[index].lastMessage === message.text) {
							inboxList[index].lastMessage = lastMessage;
						}

						if (action === LastMessageActions.create) {
							inboxList[index].lastMessage = lastMessage;
						}
					}
					return {
						...ctx,
						inbox: inboxList
					};
				});
			},
			createAutoConnection: async (connecteeId: string, initiatorId: string) => {
				try {
					const { success } = await MessagingService.createAutoConnection(connecteeId, initiatorId);
					return success;
				} catch (error) {
					return false;
					showMessage((error as Error).message);
				}
			},
			getRecentConnections: async (data: getRecentConnectionDataType) => {
				try {
					data?.keyword?.length
						? setState(ctx => ({ ...ctx, searchingConnections: true }))
						: setState(ctx => ({ ...ctx, loadingConnections: true }));

					const params = R.pick(
						data?.keyword?.length ? ["keyword", "personaId"] : ["offset", "limit", "personaId"],
						data
					);

					const res = await MessagingService.getRecentConnections(params);
					const connections = (res as RecentConnections)?.recentConnections
						? convertConnections(res as RecentConnections)
						: convertConnectionSearch(res as ConnectionSearchModel);
					setState(ctx => ({
						...ctx,
						connectionList: data.offset === 1 ? connections : ctx.connectionList.concat(connections),
						allowLoadMoreConnections: data.offset > 1 ? !!connections.length && connections.length === data.limit : true
					}));

					data?.keyword?.length
						? setState(ctx => ({ ...ctx, searchingConnections: false }))
						: setState(ctx => ({ ...ctx, loadingConnections: false }));
				} catch (error) {
					showMessage((error as Error).message);
				}
			},
			archiveChat: async (connectionId: string, archived: boolean) => {
				try {
					await MessagingService.archiveChat(connectionId, archived);

					showMessage(
						getValidationMessage({
							name: "Chat",
							actionType: actionTypes.CRUD,
							actionMethod: archived ? actionMethod.archived : actionMethod.unarchived
						}),
						3
					);
				} catch (error) {
					showMessage((error as Error).message);
				}
			},
			readConversation: async (connectionId: string, unread: boolean) => {
				return MessagingService.readConversation(connectionId, unread);
			},
			getGroupChatId: async (selectedMembers: number[]) => {
				try {
					return await MessagingService.getGroupChatId(selectedMembers);
				} catch (error) {
					showMessage((error as Error).message);
				}
			},
			pinChat: async (connectionId: string, pinned: boolean, personaId?: number) => {
				try {
					await MessagingService.pinChat(connectionId, pinned);

					if (personaId) {
						setState(ctx => ({
							...ctx,
							currentChat: {
								...ctx.currentChat,
								pinned
							}
						}));
					}

					showMessage(
						getValidationMessage({
							name: "Chat",
							actionType: actionTypes.CRUD,
							actionMethod: pinned ? actionMethod.pinned : actionMethod.unpinned
						}),
						3
					);
				} catch (error) {
					showMessage((error as Error).message);
				}
			},
			createGroupChatWithMembers,
			createConnection,
			setInboxType: inboxType => setState(ctx => ({ ...ctx, inboxType })),
			setGroupInbox: () => setState(ctx => ({ ...ctx, inbox: ctx.inbox.filter(r => !!r._id) })),
			setActivePanel: (panel: "inbox" | "chat") => setState(ctx => ({ ...ctx, activePanel: panel })),
			setPreviewDialog: (previewDialog?: PreviewDialogType) => setState(ctx => ({ ...ctx, previewDialog })),
			clearInbox: () => setState(ctx => ({ ...ctx, inbox: [] })),
			deleteChat: async (id: string) => {
				await MessagingService.deleteChat(id);
				setState(ctx => ({
					...ctx,
					inbox: ctx.inbox.filter(x => x._id !== id)
				}));
			},
			deleteChatByConnectionId: async (id: string) => {
				await MessagingService.deleteChatByConnectionId(id);
				setState(ctx => ({
					...ctx,
					inbox: ctx.inbox.filter(x => x.connectionId !== id)
				}));
			},
			setCurrentInboxTab: (currentInboxTab: InboxOptions) => setState({ currentInboxTab }),
			setInbox: (inbox: InboxItem[]) => setState({ inbox })
		};
	}, [MessagingService, setState, showMessage]);

	const getData = useCallback(() => {
		return store;
	}, [store]);

	return { ...methods, getData };
};

export default useMessaging;
