import { Box, ButtonBase, Grid } from "@material-ui/core";
import styled, { css } from "styled-components";

import { Divider, Text } from "shared/ui-kit";

export const StyledPopupOverlay = styled(Box)<{ open: boolean }>`
	display: ${({ open }) => (open ? "block" : "none")};
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.4);
	z-index: 99999;

	.MuiDialogContent-root {
		padding: 0;
		text-align: center;
	}

	.MuiDialog-paper {
		width: 400px !important;
		border-radius: 12px;
	}

	.MuiDialog-root {
		height: 100%;
	}
`;

export const StyledDivider = styled(Divider)`
	margin: 0;
	border-top-width: 1px;
`;

export const StyledParagraphText = styled(Text)`
	font-size: 15px;
	line-height: 1.33;
	color: #222b45;
	width: 75%;
	margin: auto;
	margin-bottom: 24px;
`;

export const ConfirmHeader = styled(Box)`
	text-align: center;
	padding: 16px;
	h6 {
		font-size: 18px;
		font-weight: bold;
		line-height: 1.33;
		color: #222b45;
	}
`;

export const ConfirmFooter = styled<{ direction: "row" | "row-reverse" }>(Grid)`
	button {
		width: 50%;
		padding: 10px 0;
		font-size: 15px;
		font-weight: 600;
		line-height: 1.6;
		display: flex;
		align-items: center;

		> div {
			display: flex;
			align-items: center;
		}

		&:first-child {
			border-right: ${({ direction }) => (direction === "row" ? "0.25px solid #c5cee0" : "none")};
		}

		&:last-child {
			border-right: ${({ direction }) => (direction === "row-reverse" ? "0.25px solid #c5cee0" : "none")};
		}

		.Mui-disabled {
			background: #f4f4f4;
		}

		${({ withDanger }) =>
			withDanger &&
			css`
				&:first-child {
					color: #ff463d;
				}
			`}
	}
`;

export const ConfirmButton = styled(ButtonBase)<{ buttonTheme: "primary" | "danger" }>`
	${({ buttonTheme }) => {
		switch (buttonTheme) {
			case "danger":
				return css`
					color: red;
				`;
			case "primary":
			default:
				return css`
					color: ${({ color }) => color || "#6173fe"};
				`;
		}
	}}
`;

export const CancelButton = styled(ButtonBase)<{ buttonTheme: "primary" | "secondary" }>`
	${({ buttonTheme }) => {
		switch (buttonTheme) {
			case "primary":
				return css`
					color: #6173fe;
				`;
			case "secondary":
			default:
				return css`
					color: ${({ color }) => color || "#6173fe"};
				`;
		}
	}}
`;
