const info = {
	100: "#daf1fe",
	200: "#b6e0fe",
	300: "#92ccfd",
	400: "#76b8fb",
	500: "#4a99f9",
	600: "#3677d6",
	700: "#2558b3",
	800: "#173d90",
	900: "#0e2a77"
};

export default info;
