import React, { FC, useMemo } from "react";

import { Box, IconButton } from "@material-ui/core";

import { ReactComponent as IconPause } from "assets/icons/icon-pause.svg";
import { ReactComponent as IconPlay } from "assets/icons/icon-play.svg";
import { useStory } from "shared/hooks";

import ProgressIndicator from "./ProgressIndicator";
import { StoryHeadWrapper, UserDataActions } from "./style";

interface StoryHeadProps {
	isActive: boolean;
	handleMoveNext: () => void;
}

const AdStoryHead: FC<StoryHeadProps> = ({ isActive, handleMoveNext }) => {
	const { setPauseStory, getData: getStoryData } = useStory();
	const { pauseStory, currentStorySlide, removePersonConnection } = getStoryData();

	const handlePauseStory = useMemo(
		() => () => {
			setPauseStory(isActive ? !pauseStory : false);
		},
		[pauseStory, setPauseStory, isActive]
	);

	const isPaused = useMemo(() => pauseStory || !!removePersonConnection, [pauseStory, removePersonConnection]);

	return (
		<StoryHeadWrapper>
			{isActive && (
				<ProgressIndicator
					storiesCount={1}
					isActive={isActive}
					pauseStory={isPaused}
					currentStoryNum={currentStorySlide}
					handleMoveNext={handleMoveNext}
				/>
			)}
			<UserDataActions>
				<Box />
				{isActive && (
					<Box display="flex" alignItems="center">
						<IconButton size="small" onClick={handlePauseStory}>
							{isPaused ? <IconPlay /> : <IconPause />}
						</IconButton>
					</Box>
				)}
			</UserDataActions>
		</StoryHeadWrapper>
	);
};

export default AdStoryHead;
