import { useMemo } from "react";

import config from "config/appConfig";

import useLocalStorage from "../../localStorage/localStorage";

let cashedToken = "";
let cachedWorkspace = "";

const cashedClient = "communityUser";

const WORKSPACE_KEY = "workspace";
const CLIENT = "client";

const useJwt = () => {
	const localStorage = useLocalStorage();

	return useMemo(() => {
		const getJwt = (): string => cashedToken || (localStorage.get(config.TOKEN_KEY, null) as string);
		const getWorkspace = (): string => cachedWorkspace || (localStorage.get(WORKSPACE_KEY, "") as string);
		const getClient = () => cashedClient || localStorage.get(CLIENT, null);

		return {
			getJwt,
			setJwt: (token: string, workspace: string): void => {
				cashedToken = token;
				cachedWorkspace = workspace;
				localStorage.set(config.TOKEN_KEY, token);
				localStorage.set(WORKSPACE_KEY, workspace);
			},
			injectJwt: config => {
				const token = getJwt();
				const workspace = getWorkspace();
				const client = getClient();
				const header = token ? { Authorization: token } : {};
				const workspaceHeader = !!workspace ? { workspace } : {};
				return {
					...config,
					headers: {
						"Content-Type": "application/json",
						...header,
						...workspaceHeader,
						client: client,
						...(config ? config.headers || {} : {})
					}
				};
			},
			injectBase: config => {
				const client = getClient();
				return {
					...config,
					headers: {
						"Content-Type": "application/json",
						client,
						...(config ? config.headers || {} : {})
					}
				};
			},
			clearJwt: () => {
				cashedToken = "";
				localStorage.remove(config.TOKEN_KEY);
				localStorage.remove(config.USER_KEY);
				localStorage.remove(WORKSPACE_KEY);
			},
			getWorkspace
		};
	}, [localStorage]);
};

export default useJwt;
