import React from "react";

import { routes as onboardingRoutes } from "apps/Onboarding/constants";
import { routes } from "apps/RegularUser/constants";
import { view } from "modules/Community";

const CongratulationPage = () => (
	<view.Congratulation
		homeUrl={routes.dashboard.getPath()}
		submitUrl={onboardingRoutes.onboarding.congratulation.confirmed.getPath()}
	/>
);

export default CongratulationPage;
