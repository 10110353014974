import { useMemo } from "react";

import useHttpActions from "shared/services/api/core/useHttpActions";

import BaseConverter from "shared/services/converters/baseConverter";

import { WithdrawRequestModel } from "shared/types";

import { API_URLS } from "../constants";

const useReportApiService = () => {
	const actions = useHttpActions();

	return useMemo(
		() => ({
			getReports: async (offset = 1, limit = 10) => {
				return BaseConverter.convert<{ funds: WithdrawRequestModel[] }>(
					await actions.get(API_URLS.REPORTS.GET_LIST, {
						offset,
						limit
					})
				);
			},
			getReportsCount: async () => {
				return BaseConverter.convert<{ totalCount: number }>(await actions.get(API_URLS.REPORTS.GET_COUNT));
			}
		}),
		[actions]
	);
};

export default useReportApiService;
