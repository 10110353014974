import React, { KeyboardEvent, useCallback, useEffect, useRef, useState } from "react";

import config from "config/appConfig";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";

import { GLOBAL_VARS } from "shared/constants";
import { useCommunity, useUser } from "shared/hooks";
import { IWorkspace, UserStatus } from "shared/types";
import { Loader } from "shared/ui-kit";

import { ContentWrapperBlock, ErrorText, FormBlock, FormWrapper, ManageBlock, StyledButton, TextBlock } from "./styles";

import { useAuth } from "../../../Data";

export interface InvitationCodePageProps {
	memberSignInPage: string;
	memberFillProfilePage: string;
	workspace?: string;
	communityUrl?: string;
	userId?: string;
}

const InvitationCodePage: React.FC<InvitationCodePageProps> = ({
	memberSignInPage,
	memberFillProfilePage,
	workspace,
	communityUrl,
	userId
}) => {
	const { confirmInvitation } = useAuth();
	const history = useHistory();

	const [error, setError] = useState(false);

	const inputRefs = useRef<HTMLInputElement[]>([]);

	const { getData: getUserData } = useUser();
	const { globalUserId } = getUserData();

	const { getData: getCommunityData } = useCommunity();
	const { isWhitelabelAppMode } = getCommunityData();

	const {
		control,
		reset,
		handleSubmit,
		formState: { isValid, isSubmitting }
	} = useForm({
		mode: "onChange"
	});

	const { fields } = useFieldArray({ name: "digits", control });

	useEffect(() => {
		reset({
			digits: ["", "", "", ""]
		});
	}, [reset]);

	useEffect(() => {
		const onKeyUp = (e: KeyboardEvent<HTMLInputElement>, index: number) => {
			if (e && e.code) {
				if (
					e.code === "ArrowRight" &&
					index < 3 &&
					inputRefs.current[index + 1] &&
					inputRefs.current[index + 1].focus
				) {
					inputRefs.current[index + 1].focus();
				}

				if (e.code === "ArrowLeft" && index > 0 && inputRefs.current[index - 1] && inputRefs.current[index - 1].focus) {
					inputRefs.current[index - 1].focus();
				}
			}
		};

		inputRefs.current.forEach((currentRef, index) => {
			if (currentRef) {
				currentRef.addEventListener("keyup", e => onKeyUp(e as any, index));
			}
		});
	}, []);

	const onSubmit = useCallback(
		async (data: any) => {
			setError(false);

			const workspaceUrl = communityUrl
				? communityUrl
				: isWhitelabelAppMode && config.GLOBAL_CONSTANTS.AKINA_WORKSPACE
				? `${config.GLOBAL_CONSTANTS.AKINA_WORKSPACE}${GLOBAL_VARS.COMMUNITY.EXTENSION_LINK}`
				: "";

			const digitCode = data?.digits.map(x => x.value).join("");
			const verificationInfo = await confirmInvitation({
				communityUrl: workspaceUrl,
				invitationCode: digitCode,
				userId: `${userId}`
			});

			const info = verificationInfo as IWorkspace;

			if (info?.token && info?.status) {
				if (info.status === UserStatus.ON_BOARDED) {
					history.push(`${memberSignInPage}?token=${info.token}&workspace=${workspace}`);
				} else if (info.status === UserStatus.JOINED) {
					history.push(
						`${memberFillProfilePage}?token=${info.token}&workspace=${workspace}${
							globalUserId ? `&globalUserId=${globalUserId}` : ""
						}`
					);
				}
			} else {
				setError(true);
			}
		},
		[
			userId,
			confirmInvitation,
			communityUrl,
			workspace,
			globalUserId,
			history,
			memberFillProfilePage,
			memberSignInPage,
			isWhitelabelAppMode
		]
	);

	const RenderRows = (p, i: number) => {
		return (
			<FormBlock.InputWrapper>
				<Controller
					name={`digits[${i}].value`}
					control={control}
					rules={{
						required: "Required"
					}}
					defaultValue={""}
					render={({ value, onChange }) => (
						<FormBlock.Input
							value={value}
							onChange={e => {
								const value = e.target.value.replace(/\D/g, "").slice(0, 1);
								onChange(value);

								if (value && i < 3 && inputRefs.current[i + 1] && inputRefs.current[i + 1].focus) {
									inputRefs.current[i + 1].focus();
								}
							}}
							ref={ref => {
								if (ref) {
									inputRefs.current[i] = ref;
								}
							}}
						/>
					)}
				/>
			</FormBlock.InputWrapper>
		);
	};

	return (
		<ContentWrapperBlock onSubmit={handleSubmit(onSubmit)}>
			<FormWrapper>
				<TextBlock>
					Please enter <span>4-digit</span> invitation code here.
				</TextBlock>
				<FormBlock>{fields.map((field, i) => RenderRows(field as any, i))}</FormBlock>
				{error && <ErrorText>Incorrect code</ErrorText>}
				<ManageBlock>
					<StyledButton
						type={"button"}
						removeSideMargin
						palette={"basic"}
						buttonTheme={"outline"}
						onClick={() => history.go(-1)}
					>
						Back
					</StyledButton>
					<StyledButton
						type={"submit"}
						removeSideMargin
						disabled={isSubmitting || !isValid}
						leftIcon={
							isSubmitting ? <Loader size="1rem" show={true} color="primary" variant="indeterminate" /> : undefined
						}
					>
						Next
					</StyledButton>
				</ManageBlock>
			</FormWrapper>
		</ContentWrapperBlock>
	);
};

export default InvitationCodePage;
