import React, { FC } from "react";

import styled from "styled-components";

import { Button } from "shared/ui-kit";

const StyledButton = styled(Button)`
	margin: 0;
	border-radius: 8px;
`;

const OpenCard: FC<{ text: string }> = ({ text }) => <StyledButton>{text}</StyledButton>;

export default OpenCard;
