import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo } from "react";

import { useWindowSize } from "shared/hooks";
import { Checkbox, FabButton } from "shared/ui-kit";

import { Column, Header, Row, Wrapper } from "./style";

import { TableMethods } from "../../index";

import { SkeletonLoader, TableColumnLoaderType } from "../index";

export enum TableSpecialColumnType {
	checkbox = "checkbox",
	expand = "expand"
}

interface SimpleTableProps {
	columns: any[];
	data: any[];

	width?: number;
	height?: number;

	headerHeight: number;
	rowHeight: number;

	hideHeader?: boolean;

	checkable?: boolean;
	checkableColumnWidth?: number;
	checkableColumnPosition?: "left" | "right";
	isRowCheckable?: (data: any) => boolean;
	hideNonCheckables?: boolean;
	onToggleCheckRow?: ({
		isChecked,
		toggledIndex,
		checkedRows
	}: {
		isChecked: boolean;
		toggledIndex?: number;
		checkedRows?: number[];
	}) => void;

	expandable?: boolean;
	isRowExpandable?: (data: any) => boolean;
	onExpandRow?: (number) => void;

	loading?: boolean;

	paginated?: boolean;
	totalDataCount?: number;
	page?: number;
	pageSize?: number;
	onChangePage?: (newPage: number) => void;
	onChangePageSize?: (newPageSize: number) => void;
}

const SimpleTable = forwardRef<TableMethods, SimpleTableProps>(
	(
		{
			data,
			columns,
			rowHeight,
			headerHeight,
			checkable,
			expandable,
			isRowCheckable,
			isRowExpandable,
			checkableColumnWidth,
			checkableColumnPosition = "left",
			hideNonCheckables = false,
			onToggleCheckRow,
			hideHeader = false,
			onExpandRow,
			loading = false,
			pageSize
		},
		ref
	) => {
		const wrapperRef = React.useRef<HTMLDivElement>(null);

		const { width: screenWidth } = useWindowSize(200);

		const [columnList, setColumnList] = React.useState(columns || []);
		const [checkableIndexes, setCheckableIndexes] = React.useState<number[]>([]);
		const [checkedRowIndexes, setCheckedRowIndexes] = React.useState<number[]>([]);

		const [expandableIndexes, setExpandableIndexes] = React.useState<number[]>([]);
		const [expandedRowIndexes, setExpandedRowIndexes] = React.useState<number[]>([]);

		// eslint-disable-next-line react-hooks/exhaustive-deps
		const wrapperWidth = useMemo(() => wrapperRef.current?.clientWidth || 0, [screenWidth, wrapperRef.current]);

		useImperativeHandle(ref, () => ({
			clearCheckboxes: () => {
				setCheckedRowIndexes([]);
			}
		}));

		const autoColumnWidth = useMemo(() => {
			if (columnList.length) {
				let busySpace = 0;
				let columnCount = 0;
				columnList.forEach(column => {
					if (column?.width || column?.maxWidth) {
						busySpace += column?.maxWidth || column?.width;
						columnCount += 1;
					}
				});

				const correctColumnCount = columnList.length - columnCount;
				const columnWidth = correctColumnCount ? (wrapperWidth - busySpace) / correctColumnCount : 0;

				if (columnWidth) {
					return columnWidth < 80 ? 80 : columnWidth;
				}
			}
			return undefined;
		}, [columnList, wrapperWidth]);

		const _columnWidth = useMemo(
			() => (index: number) => {
				const widths = columnList[index]
					? {
							minWidth: !isNaN(columnList[index].minWidth) ? columnList[index].minWidth : undefined,
							maxWidth: !isNaN(columnList[index].maxWidth) ? columnList[index].maxWidth : undefined,
							width: !isNaN(columnList[index].width) ? columnList[index].width : undefined
					  }
					: {
							minWidth: undefined,
							maxWidth: undefined,
							width: undefined
					  };

				let width = widths?.width || autoColumnWidth;

				if (widths?.minWidth && width < widths.minWidth) {
					width = widths.minWidth;
				}

				if (widths?.maxWidth && width > widths.maxWidth) {
					width = widths.maxWidth;
				}

				return width;
			},
			[columnList, autoColumnWidth]
		);

		const toggleSelectAllRow = useCallback(() => {
			const isCheckAll = checkedRowIndexes.length !== checkableIndexes.length;
			setCheckedRowIndexes(isCheckAll ? checkableIndexes : []);

			onToggleCheckRow && onToggleCheckRow({ isChecked: isCheckAll, checkedRows: checkableIndexes });
		}, [checkableIndexes, checkedRowIndexes, onToggleCheckRow]);

		const isRowChecked = useCallback((index: number) => checkedRowIndexes.some(x => x === index), [checkedRowIndexes]);

		const isRowExpanded = useCallback(
			(index: number) => expandedRowIndexes.some(x => x === index),
			[expandedRowIndexes]
		);

		const toggleCheckboxRow = useCallback(
			(index: number) => {
				const checkedIndex = checkedRowIndexes.findIndex(x => x === index);
				if (checkedIndex === -1) {
					if (checkableIndexes.includes(index)) {
						setCheckedRowIndexes(x => [...x, index]);
						onToggleCheckRow && onToggleCheckRow({ isChecked: true, toggledIndex: index });
					}
				} else {
					setCheckedRowIndexes(x => x.filter(i => i !== index));
					onToggleCheckRow && onToggleCheckRow({ isChecked: false, toggledIndex: index });
				}
			},
			[checkedRowIndexes, checkableIndexes, onToggleCheckRow]
		);

		const toggleExpand = useCallback(
			(index: number, expanded: boolean) => {
				setExpandedRowIndexes(x => (expanded ? x.filter(i => i !== index) : [...x, index]));
				if (!expanded) {
					onExpandRow && onExpandRow(index);
				}
			},
			[onExpandRow]
		);

		useEffect(() => {
			let indexes: any[] = [];
			if (typeof isRowCheckable === "function") {
				data.forEach((currentData, index) => {
					if (isRowCheckable(currentData)) {
						indexes.push(index);
					}
				});

				setCheckableIndexes(indexes);
			} else {
				indexes = data.map((val, index) => (!val._expandData ? index : null)).filter(x => x !== null);
				setCheckableIndexes([...indexes]);
			}
		}, [isRowCheckable, data]);

		useEffect(() => {
			let indexes: any[] = [];
			if (typeof isRowExpandable === "function") {
				data.forEach((currentData, index) => {
					if (isRowExpandable(currentData)) {
						indexes.push(index);
					}
				});

				setExpandableIndexes(indexes);
			} else {
				indexes = data.map((val, index) => (!val._expandDataContent ? index : null)).filter(x => x !== null);
				setExpandableIndexes([...indexes]);
			}
		}, [isRowExpandable, data]);

		useEffect(() => {
			const newColumnList = [...columns];

			if (checkable) {
				newColumnList[checkableColumnPosition === "left" ? "unshift" : "push"]({
					width: checkableColumnWidth || 62,
					alignment: "left",
					disableSort: true,
					label: "",
					type: TableSpecialColumnType.checkbox,
					dataKey: "__checkbox__",
					loaderTemplate: TableColumnLoaderType.checkbox
				});
			}

			if (expandable) {
				newColumnList.push({
					width: 64,
					alignment: "center",
					disableSort: true,
					label: "",
					type: TableSpecialColumnType.expand,
					dataKey: "__expand__",
					loaderTemplate: TableColumnLoaderType.expand
				});
			}

			setColumnList([...newColumnList]);
		}, [columns, checkable, checkableColumnWidth, checkableColumnPosition, hideNonCheckables, expandable]);

		const ExpandeButton = useCallback(
			(rowIndex: number) => {
				const expanded = isRowExpanded(rowIndex);

				return (
					<FabButton
						size={"small"}
						palette={"fabArrowDark"}
						expanded={expanded}
						onClick={() => toggleExpand(rowIndex, expanded)}
					/>
				);
			},
			[isRowExpanded, toggleExpand]
		);

		const columnStyles = useCallback(
			(width: number) => ({
				width,
				minWidth: width,
				maxWidth: width
			}),
			[]
		);

		return (
			<Wrapper ref={wrapperRef}>
				{loading && (
					<SkeletonLoader
						pageSize={pageSize || 5}
						columnWidth={_columnWidth}
						columnList={columnList}
						headerHeight={headerHeight}
						rowHeight={rowHeight}
						hideHeader={hideHeader}
					/>
				)}
				{!loading && (
					<>
						{!hideHeader && (
							<Header.Wrapper>
								<Row.Wrapper style={{ height: headerHeight }}>
									{columnList.map((column, columnIndex) => (
										<Column.Wrapper
											key={columnIndex}
											style={columnStyles(_columnWidth(columnIndex))}
											className={`${column?.alignment ? `alignment_${column?.alignment}` : ""}`}
										>
											{column?.label}
											{column?.type === TableSpecialColumnType.checkbox && (
												<Checkbox
													checked={checkedRowIndexes.length === checkableIndexes.length}
													onChange={toggleSelectAllRow}
												/>
											)}
										</Column.Wrapper>
									))}
								</Row.Wrapper>
							</Header.Wrapper>
						)}
						{data.map((row, rowIndex) => (
							<>
								<Row.Wrapper key={rowIndex} style={{ height: rowHeight }}>
									{columnList.map((column, columnIndex) => (
										<Column.Wrapper
											key={columnIndex}
											style={columnStyles(_columnWidth(columnIndex))}
											className={`${column?.alignment ? `alignment_${column.alignment}` : ""}`}
										>
											{column?.Cell && column.Cell({ rowData: data[rowIndex], rowIndex })}
											{column?.type === TableSpecialColumnType.checkbox && (
												<Checkbox checked={isRowChecked(rowIndex)} onChange={() => toggleCheckboxRow(rowIndex)} />
											)}
											{column?.type === TableSpecialColumnType.expand &&
												expandableIndexes.includes(rowIndex) &&
												ExpandeButton(rowIndex)}
										</Column.Wrapper>
									))}
								</Row.Wrapper>
								{isRowExpanded(rowIndex) && data[rowIndex]?._expandDataContent}
							</>
						))}
					</>
				)}
			</Wrapper>
		);
	}
);

export default SimpleTable;
