import { useCallback, useMemo } from "react";

import { actionMethod, actionTypes, getValidationMessage } from "utils/getValidationMessage";

import { useVideoStore } from "../contexts";
import { useVideoApiService } from "../services";
import { UpdateVideoFormModel, VideoFormModel, VideoModel, VideoSortBy } from "../types";
import { useNotification, useUser } from "./index";

const useVideo = () => {
	const store = useVideoStore();
	const { setState } = useVideoStore();

	const service = useVideoApiService();

	const { getData: getUserData } = useUser();
	const { user } = getUserData();

	const { showMessage } = useNotification();
	const personaId = user?.profiles[0]?.personaId || "";

	const methods = useMemo(
		() => ({
			async getVideos({
				limit = 10,
				keyword = "",
				offset,
				groupId,
				eventId,
				paginate,
				order,
				sortBy,
				personaDocId
			}: {
				limit?: number;
				keyword?: string;
				offset?: number;
				groupId?: string;
				eventId?: string;
				paginate?: boolean;
				order?: number;
				sortBy?: VideoSortBy;
				personaDocId?: string;
			}) {
				setState(ctx => ({ ...ctx, loading: true }));

				const { videos } = await service.getVideos({
					offset,
					limit,
					keyword,
					groupId,
					order,
					sortBy,
					eventId,
					personaDocId
				});

				setState(ctx => ({
					...ctx,
					offset,
					filteredVideos: paginate && (offset || 1) > 1 ? [...ctx.filteredVideos, ...videos] : videos,
					loading: false
				}));
			},
			async getVideoCount({
				keyword = "",
				groupId
			}: {
				keyword?: string;
				groupId?: string;
				sortBy?: VideoSortBy;
				personaDocId?: string;
			}) {
				setState(ctx => ({ ...ctx, loading: true }));

				const { totalCount } = await service.getVideoCount({ keyword, groupId });

				setState(ctx => ({
					...ctx,
					videoCount: totalCount,
					loading: false
				}));
			},
			async getAllVideoCount({
				groupId,
				eventId,
				personaDocId,
				keyword
			}: {
				eventId?: string;
				groupId?: string;
				personaDocId?: string;
				keyword?: string;
			}) {
				setState(ctx => ({ ...ctx, loading: true }));

				const { totalCount } = await service.getVideoCount({ keyword, groupId, eventId, personaDocId });

				setState(ctx => ({
					...ctx,
					allVideoCount: totalCount,
					loading: false
				}));
			},
			getVideo(id: string) {
				try {
					return service.getVideo(id);
				} catch {
					showMessage("Couldn't retrieve video info, please try again.", 3);
				}
			},
			getVideoInContext: async (id: string) => {
				try {
					const { video } = await service.getVideo(id);
					setState(ctx => ({
						...ctx,
						filteredVideos: ctx.filteredVideos?.some(x => x._id === id)
							? ctx.filteredVideos.map(x => (x._id === id ? video : x))
							: ctx.filteredVideos
					}));

					return video;
				} catch {
					showMessage("Couldn't retrieve video info, please try again.", 3);
				}
			},
			createVideo: async (data: VideoFormModel) => {
				setState(ctx => ({ ...ctx, creating: true }));
				const { video } = await service.createVideo(data, personaId);

				const newVideo = {
					...video,
					category: data.category
				};

				setState(ctx => ({
					...ctx,
					filteredVideos: [newVideo].concat(ctx.filteredVideos),
					videoCount: ctx.videoCount + 1,
					allVideoCount: ctx.allVideoCount + 1,
					creating: false
				}));

				showMessage(
					getValidationMessage({
						name: "Video",
						actionType: actionTypes.CRUD,
						actionMethod: actionMethod.created,
						emoji: "🎉"
					}),
					3
				);

				return video;
			},
			updateVideo: async (data: UpdateVideoFormModel) => {
				setState(ctx => ({ ...ctx, updating: true }));
				const { video } = await service.updateVideo(data, personaId);

				setState(ctx => {
					const videos = ctx.filteredVideos;
					const index = videos.findIndex(x => x._id === data._id);
					if (index > -1) {
						videos[index] = video;
					}
					return {
						...ctx,
						updating: false,
						filteredVideos: videos
					};
				});

				showMessage(
					getValidationMessage({
						name: "Video",
						actionType: actionTypes.CRUD,
						actionMethod: actionMethod.updated,
						emoji: "✨"
					}),
					3
				);
			},
			async deleteVideo(id: string) {
				setState(ctx => ({ ...ctx, loading: true }));

				const { success } = await service.deleteVideo(id);

				if (success) {
					setState(ctx => ({
						...ctx,
						filteredVideos: ctx.filteredVideos.filter(x => x._id !== id),
						videoCount: ctx.videoCount - 1,
						allVideoCount: ctx.allVideoCount - 1,
						loading: false
					}));

					showMessage(
						getValidationMessage({
							name: "Video",
							actionType: actionTypes.CRUD,
							actionMethod: actionMethod.deleted,
							emoji: "🗑"
						}),
						3
					);
				}
			},
			setPage: (page: number) => {
				setState(ctx => ({ ...ctx, page }));
			},
			setVideosShowPerPage: (videosShowPerPage: number) => {
				setState(ctx => ({ ...ctx, videosShowPerPage }));
			},
			setVideoName: (videoName: string) => setState(ctx => ({ ...ctx, videoName })),
			setVideoInfoPopup: (videoInfoPopup: { open: boolean; model?: VideoModel }) => {
				setState(ctx => ({ ...ctx, videoInfoPopup, creating: false }));
			},
			setVideoFileUrl: videoFileUrl => setState(ctx => ({ ...ctx, videoFileUrl })),
			setVideoDuration: (duration: string) => setState(ctx => ({ ...ctx, videoDuration: duration }))
		}),
		[setState, showMessage, service, personaId]
	);

	const getData = useCallback(() => {
		return store;
	}, [store]);

	return { ...methods, getData };
};

export default useVideo;
