import { useMemo } from "react";

import { Podcast } from "types/PodcastsContextValuesType";

import { API_URLS } from "shared/constants";

import useHttpActions from "shared/services/api/core/useHttpActions";

import { BaseConverter } from "./converters";
import useTrackApiService from "./useTrackApiService";

const usePodcastsApiService = () => {
	const actions = useHttpActions();

	const trackApiService = useTrackApiService();

	const methods = useMemo(
		() => ({
			getPodcasts: async ({
				offset,
				limit,
				keyword,
				showId,
				sortBy,
				order
			}: {
				offset: number;
				limit: number;
				keyword?: string;
				showId?: string;
				sortBy?: "title" | "updatedAt";
				order?: 1 | -1;
			}) => {
				return BaseConverter.convert<{ shows: Podcast[]; success: boolean }>(
					await actions.get(API_URLS.PODCASTS.GET_PODCASTS, { offset, limit, keyword, showId, sortBy, order })
				);
			},
			getEpisodes: async ({ offset = 1, limit = 20, showId }: { showId: string; offset: number; limit?: number }) => {
				return trackApiService.getTracks({ offset, limit, showId, type: "podCast" });
			},
			followPodcast: async ({ showId, isFollowing }: { showId: string; isFollowing: boolean }) => {
				return BaseConverter.convert<{ success: boolean }>(
					isFollowing
						? await actions.put(`${API_URLS.PODCASTS.UNFOLLOW(showId)}`, {})
						: await actions.put(`${API_URLS.PODCASTS.FOLLOW(showId)}`, {})
				);
			}
		}),
		[actions, trackApiService]
	);

	return useMemo(
		() => ({
			...methods
		}),
		[methods]
	);
};

export default usePodcastsApiService;
