import React, { useCallback, useEffect, useMemo, useState } from "react";

import { useHistory } from "react-router";

import { useLocation } from "react-router-dom";

import { CreateGroup } from "shared/Components";
import { useCommunity, useGroup, useMemberRoutes, useUser } from "shared/hooks";

import { GroupModel } from "shared/types";
import { GroupSortBy, groupModalType, groupType } from "shared/types/GroupModel";

import { capitalizeFirstLetter } from "utils/capitaliseFirstLetter";

import { updateUrlSearchParams } from "utils/updateUrlSearchParams";

import { Container } from "./style";

import { Delimiter, FilterBlock, FilterOptionType, ModelBlock, SectionHeadline } from "../../Components";
import { ModelBlockType } from "../../Components/ModelBlock";

const defaultFilters = {
	limit: 10,
	name: "",
	searchAll: true
};

export interface GroupsPageProps {
	sortBy?: GroupSortBy;
	groupType: groupType;
}

const GroupsPage: React.FC<GroupsPageProps> = ({ sortBy, groupType }) => {
	const { search } = useLocation();

	const [filters, setFilters] = useState<{
		sortBy?: GroupSortBy;
		groupType: groupType;
		isMyGroup: boolean;
	}>({
		sortBy: sortBy,
		groupType: groupType,
		isMyGroup: false
	});
	const { getMemberRoutesData } = useMemberRoutes();
	const { routes } = getMemberRoutesData();

	const handleSeeAll = useMemo(
		() => `${routes?.member.groups.getPath()}/all${search}`,
		[routes?.member.groups, search]
	);

	const { getGroups, getTotalGroupsCount } = useGroup();

	const { getPotentialMatchIds, getData: getUserData } = useUser();
	const { user } = getUserData();

	const { getData: getCommunityData } = useCommunity();
	const { workspace } = getCommunityData();

	const [myGroups, setMyGroups] = useState<GroupModel[]>([]);
	const [myGroupsCount, setMyGroupsCount] = useState<number>();
	const [loadingMyGroups, setLoadingMyGroups] = useState(true);
	const [popularGroups, setPopularGroups] = useState<GroupModel[]>([]);
	const [popularGroupsCount, setPopularGroupsCount] = useState<number>();
	const [loadingPopularGroups, setLoadingPopularGroups] = useState(true);

	const [potentialMatchingIds, setPotentialMatchingIds] = useState<string[]>([]);

	const history = useHistory();

	const [groupInfoPopUp, setGroupInfoPopUp] = useState<groupModalType>({
		open: false
	});

	const isWhiteLabelApp = useMemo(() => workspace?.whiteLabelApp || false, [workspace?.whiteLabelApp]);

	const createOption = useMemo(
		() => ({
			label: "Create New Group",
			onClick: () => setGroupInfoPopUp({ open: true, isSubgroup: true }),
			val: 1
		}),
		[setGroupInfoPopUp]
	);

	const fetchGroups = useCallback(
		async ({
			isMyGroups = false,
			isPopularGroups = false,
			page = 1,
			matchingIds = [],
			sortBy = GroupSortBy.MEMBERS,
			groupType = "all"
		}: {
			isMyGroups?: boolean;
			isPopularGroups?: boolean;
			page?: number;
			matchingIds?: string[];
			sortBy?: GroupSortBy;
			groupType?: groupType;
		}) => {
			const data = await getGroups({
				...defaultFilters,
				sortBy,
				page,
				isMyGroups,
				// TODO: uncomment me when BE is ready
				// noSubgroups: true,
				groupRelation: isPopularGroups ? "popular" : undefined,
				potentialMatchingIds: matchingIds,
				groupType
			});
			const totalFoundGroups = await getTotalGroupsCount({
				name: "",
				isMyGroups,
				groupRelation: isPopularGroups ? "popular" : undefined,
				potentialMatchingIds: matchingIds
			});
			if (isMyGroups) {
				setLoadingMyGroups(true);
				setMyGroups(groups => (page === 1 ? data.groups : groups.concat(data.groups)));
				setMyGroupsCount(totalFoundGroups);
				setLoadingMyGroups(false);
			}
			if (isPopularGroups) {
				setLoadingPopularGroups(true);
				setPopularGroups(groups => (page === 1 ? data.groups : groups.concat(data.groups)));
				setPopularGroupsCount(totalFoundGroups);
				setLoadingPopularGroups(false);
			}
		},
		[getGroups, getTotalGroupsCount]
	);

	useEffect(() => {
		const matchingIds = getPotentialMatchIds(user);
		setPotentialMatchingIds(matchingIds);

		fetchGroups({
			isMyGroups: true,
			sortBy: filters.sortBy || GroupSortBy.NEWEST,
			groupType: filters.groupType
		});
		if (!filters.isMyGroup) {
			fetchGroups({
				isPopularGroups: true,
				matchingIds,
				sortBy: filters.sortBy || GroupSortBy.MEMBERS,
				groupType: filters.groupType
			});
		}
	}, [fetchGroups, user, getPotentialMatchIds, filters]);

	const applyFilters = useCallback(
		(name: string, item?: string) => {
			const query = updateUrlSearchParams(name, item, search);
			history.replace(`?${query.toString()}`);
		},
		[history, search]
	);

	const groupTypeClickHandler = useCallback(
		item => {
			setFilters(ctx => ({
				...ctx,
				groupType: typeof item === "string" && item ? (((item || "All") as string)?.toLowerCase() as groupType) : "all"
			}));

			if (typeof item === "string" && item !== "all") applyFilters("groupType", item);
			else applyFilters("groupType", undefined);
		},
		[setFilters, applyFilters]
	);

	const filterOptions: FilterOptionType[] = useMemo(
		() => [
			{
				label: "Sort By",
				inlineOptions: false,
				onClick: item => {
					const _item = typeof item === "string" && item ? (item as GroupSortBy)?.toUpperCase() : undefined;
					setFilters(ctx => ({ ...ctx, sortBy: GroupSortBy[_item as GroupSortBy] }));

					if (typeof item === "string" && item) applyFilters("sortBy", item);
					else applyFilters("sortBy", undefined);
				},
				listOptions: Object.keys(GroupSortBy)
					.filter(a => GroupSortBy[a as GroupSortBy] !== GroupSortBy.PLACEHOLDER)
					.map(key => ({
						label: capitalizeFirstLetter(GroupSortBy[key]),
						value: capitalizeFirstLetter(GroupSortBy[key]),
						default: key === (sortBy ? sortBy.toUpperCase() : "")
					}))
			},
			{
				label: "Group Type",
				inlineOptions: false,
				onClick: groupTypeClickHandler,
				listOptions: [
					{ label: "Public", value: "Public", default: groupType === "public" },
					{ label: "Private", value: "Private", default: groupType === "private" }
				]
			},
			{
				label: "My Group",
				inlineOptions: true,
				onClick: () => {
					setFilters(ctx => ({ ...ctx, isMyGroup: !ctx.isMyGroup }));
				},
				listOptions: [{ label: "My Group", value: "myGroup", id: "myGroup" }]
			}
		],
		[setFilters, groupTypeClickHandler, sortBy, groupType, applyFilters]
	);

	const onMyGroupEndScroll = useCallback(() => {
		if (myGroupsCount && myGroups.length < myGroupsCount) {
			fetchGroups({ isMyGroups: true, page: Math.ceil(myGroups.length / defaultFilters.limit) + 1 });
		}
	}, [fetchGroups, myGroups.length, myGroupsCount]);

	return (
		<>
			<Container>
				<FilterBlock options={filterOptions} createOption={!isWhiteLabelApp ? createOption : undefined} />
				<ModelBlock
					idPrefix={"MyGroups"}
					title={"My Groups"}
					type={ModelBlockType.group}
					items={myGroups}
					loading={loadingMyGroups}
					onEndScroll={onMyGroupEndScroll}
					noContent={"You don’t have any groups."}
					inlineView={filters.isMyGroup}
				/>
				{!filters.isMyGroup && (
					<>
						<Delimiter />
						<SectionHeadline
							title={filters.sortBy ? `${capitalizeFirstLetter(filters.sortBy)} Groups` : "Popular Groups"}
							actionButton={{ text: "See All", onClick: () => history.push(handleSeeAll), link: handleSeeAll }}
						/>
						<ModelBlock
							idPrefix={"PopularGroups"}
							type={ModelBlockType.group}
							items={popularGroups}
							loading={loadingPopularGroups}
							onEndScroll={() => {
								if (popularGroupsCount && popularGroups.length < popularGroupsCount) {
									fetchGroups({
										isPopularGroups: true,
										page: Math.ceil(popularGroups.length / defaultFilters.limit) + 1,
										matchingIds: potentialMatchingIds
									});
								}
							}}
							noContent={"You don’t have any popular groups."}
						/>
					</>
				)}
				{/*<Delimiter />*/}
				{/*<ModelBlock*/}
				{/*	title={"Suggested Groups"}*/}
				{/*	type={ModelBlockType.group}*/}
				{/*	items={[]}*/}
				{/*	inlineView*/}
				{/*	noContent={"You don’t have suggested groups."}*/}
				{/*/>*/}
			</Container>
			{groupInfoPopUp.open && (
				<CreateGroup
					open
					isSubgroup={groupInfoPopUp.isSubgroup}
					defaultParent={groupInfoPopUp.defaultParent}
					editableModel={groupInfoPopUp.model}
					onClose={() => {
						setGroupInfoPopUp({ open: false });
						fetchGroups({ isMyGroups: true });
					}}
				/>
			)}
		</>
	);
};

export default GroupsPage;
