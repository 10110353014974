import { Box, ButtonBase } from "@material-ui/core";
import styled from "styled-components";

export const Wrapper = styled(ButtonBase)`
	width: 200px;
	height: 320px;
	border-radius: 12px;
	display: block;
	.story,
	svg {
		height: 100%;
		width: 100%;
		border-radius: 12px;
		object-fit: cover;
	}
`;

Wrapper.Header = styled(Box)`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	padding: 12px 16px;
	display: flex;
	align-items: center;
	img,
	svg {
		width: 24px;
		height: 24px;
		border-radius: 99%;
		border: 1px solid #fff;
		object-fit: cover;
	}
	p {
		font-size: 13px;
		font-weight: 600;
		line-height: 1.85;
		color: #fff;
		margin-left: 8px;
	}
	.type-profile_image {
		width: 24px;
		height: 24px;
	}
`;
