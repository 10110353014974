import React, { useEffect, useState } from "react";

import { ForumPostModel } from "types";

import { ProfilePhotoSizes } from "shared/types";
import { getResizedImage } from "utils/serviceUtils/cdnImages";

import { AuthorPreview, LoadMoreRow, LoadMoreText } from "./style";

export interface LoadMoreProps {
	authorImgUrlList?: string[];
	commentsList?: ForumPostModel[];
	onLoadMore: () => void;
	viewReplyCount?: number;
}

const LoadMore: React.FC<LoadMoreProps> = ({ authorImgUrlList, commentsList, onLoadMore, viewReplyCount }) => {
	const [imgUrlList, setImgUrlList] = useState(authorImgUrlList || []);

	useEffect(() => {
		if (commentsList?.length) {
			// remove duplicates
			const filteredPersonas = commentsList
				.filter(
					(item, index, arr) =>
						arr.findIndex(x => x.user?.persona?.personaId === item.user?.persona?.personaId) === index
				)
				.map(x => x.user?.persona)
				.filter(x => !!x);

			setImgUrlList(
				filteredPersonas.map(x =>
					getResizedImage(x.photos?.length ? x.photos[0]?.profilePicture || "" : "", ProfilePhotoSizes.size50x50)
				)
			);
		}
	}, [commentsList]);

	return (
		<LoadMoreRow>
			{imgUrlList.map((authorImgUrl, index) => (
				<AuthorPreview key={index} style={{ backgroundImage: `url(${authorImgUrl})` }} />
			))}
			<LoadMoreText onClick={onLoadMore}>
				{viewReplyCount ? `View ${viewReplyCount} replies...` : "Load more comments..."}
			</LoadMoreText>
		</LoadMoreRow>
	);
};

export default LoadMore;
