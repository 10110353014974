import React, { FC } from "react";

import { ButtonBase } from "@material-ui/core";

import { SkeletonBase } from "shared/Components";

import { StyledWrapper } from "../style";

const Sections: FC<{
	sections: string[];
	current: string;
	loading?: boolean;
	handleSwitchSection: (section: string) => void;
}> = ({ sections, current, loading, handleSwitchSection }) => {
	return loading ? (
		<StyledWrapper.Sections>
			{[...Array.from(Array(8).keys())].map((section, i) => (
				<SkeletonBase variant="rect" animation="wave" width={80} height={40} key={i} className={"side-offset"} />
			))}
		</StyledWrapper.Sections>
	) : (
		<StyledWrapper.Sections>
			{sections.map((section, i) => (
				<ButtonBase
					onClick={() => handleSwitchSection(section)}
					className={`section ${current === section && "active"}`}
					key={i}
				>
					{section}
				</ButtonBase>
			))}
		</StyledWrapper.Sections>
	);
};

export default Sections;
