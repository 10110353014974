import React, { memo, useCallback, useMemo } from "react";

import { DateTime } from "luxon";
import { Scrollbars } from "react-custom-scrollbars";
import { FixedSizeList } from "react-window";
import styled, { css } from "styled-components";

import { PlaceholderImage } from "shared/Components";
import { useAnalytics, useCommunity } from "shared/hooks";

import { PlaceholderImageType } from "shared/types";
import { Avatar, Box, Text } from "shared/ui-kit";

import BlockHeader from "../BlockHeader";
import NoDataFound from "../NoDataFound";

const Wrapper = styled(Box)`
	display: flex;
	flex-direction: column;
	width: 100%;
`;

const BodyWrapper = styled(Box)<{ centeredContent: boolean }>`
	min-height: 200px;
	max-height: 350px;

	${({ centeredContent }) => {
		if (centeredContent) {
			return css`
				align-items: center;
				justify-content: center;
				display: flex;
			`;
		}
	}}
`;

const UserItem = styled(Box)`
	display: flex;
	justify-content: space-between;
	padding: 18px 24px 19px 24px;

	@media (max-width: 500px) {
		padding: 18px 12px 19px 12px;
	}
	&:not(:last-child) {
		border-bottom: solid 0.5px #c5cee0;
	}
`;

const UserItemInfo = styled(Box)`
	display: flex;
	align-items: center;
`;

const UserItemIconWrapper = styled(Box)`
	width: 32px;
	height: 32px;

	margin-right: 16px;

	border-radius: 4px;

	overflow: hidden;
`;

const UserItemTextsWrapper = styled(Box)`
	display: flex;
	flex-direction: column;
`;

const StyledAvatar = styled(Avatar)`
	width: 100%;
	height: 100%;

	background-color: rgb(240, 250, 255);
	color: rgb(25, 152, 213);
`;

const UserItemTitle = styled(Text)`
	font-size: 13px;
	font-weight: 600;
	line-height: 1.85;
	color: #222b45;
`;

const UserItemSubTitle = styled(Text)`
	font-size: 12px;
	font-weight: normal;
	line-height: 1.33;
	color: #8f9bb3;
`;

const UserItemValueWrapper = styled(Box)`
	display: flex;
	justify-content: flex-end;
	flex-direction: column;
	font-weight: 400;
	line-height: 1.85;
	text-align: right;
`;

const UserItemValue = styled(Text)`
	font-size: 12px;
	font-weight: 400;
	line-height: 1.85;
	text-align: right;
	color: #222b45;
`;

const virtualizedConfig = {
	itemSize: 70,
	bodyHeight: 222,
	overscanCount: 10
};

interface NewUsersProps {
	displayCsvDownload: boolean;
}
const NewUsersBlock: React.FC<NewUsersProps> = memo(({ displayCsvDownload }) => {
	const { getNewUsers, getNewUsersCSVFile, getData: getAnalyticsData } = useAnalytics();
	const { newUsersData } = getAnalyticsData();

	const { getData: getCommunityData } = useCommunity();
	const { workspace } = getCommunityData();

	const changePeriod = useCallback(
		(requestedFrom: DateTime, requestedTo: DateTime) => {
			getNewUsers(requestedFrom, requestedTo);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[getNewUsers, workspace]
	);

	const RenderRow = useCallback(
		({ index, style }) => {
			const { name, picture, jobTitle, createdAt } = newUsersData[index];
			return (
				<UserItem style={style}>
					<UserItemInfo className="w-9/12">
						<UserItemIconWrapper>
							{picture ? (
								<StyledAvatar title={name} image={picture} />
							) : (
								<PlaceholderImage
									type={PlaceholderImageType.PROFILE_IMAGE}
									width={32}
									height={32}
									viewBox={"0 0 400 400"}
								/>
							)}
						</UserItemIconWrapper>
						<UserItemTextsWrapper className="w-9/12">
							<UserItemTitle className="truncate">{name}</UserItemTitle>
							<UserItemSubTitle>{jobTitle}</UserItemSubTitle>
						</UserItemTextsWrapper>
					</UserItemInfo>
					{createdAt && (
						<UserItemValueWrapper className="w-3/12">
							<UserItemValue>{DateTime.fromISO(createdAt).toFormat("MMM d, yyyy")}</UserItemValue>
							<UserItemSubTitle>{DateTime.fromISO(createdAt).toFormat("h:mm a")}</UserItemSubTitle>
						</UserItemValueWrapper>
					)}
				</UserItem>
			);
		},
		[newUsersData]
	);

	const UserList = useMemo(
		() => (
			<FixedSizeList
				height={
					newUsersData.length * virtualizedConfig.itemSize < virtualizedConfig.bodyHeight
						? newUsersData.length * virtualizedConfig.itemSize
						: virtualizedConfig.bodyHeight
				}
				itemCount={newUsersData.length}
				itemSize={virtualizedConfig.itemSize}
				width={"100%"}
				overscanCount={virtualizedConfig.overscanCount}
				outerElementType={Scrollbars}
				style={{ overflow: "hidden" }}
			>
				{RenderRow}
			</FixedSizeList>
		),
		[newUsersData, RenderRow]
	);

	return (
		<Wrapper>
			<BlockHeader
				title={newUsersData.length.toString()}
				subtitle="New Users"
				showDropdown
				onChangePeriod={changePeriod}
				onDownloadCsv={displayCsvDownload ? getNewUsersCSVFile : undefined}
				csvFilenamePrefix="new_users_report"
			/>
			<BodyWrapper centeredContent={!newUsersData.length}>
				{newUsersData.length > 0 ? <>{UserList}</> : <NoDataFound />}
			</BodyWrapper>
		</Wrapper>
	);
});

export default NewUsersBlock;
