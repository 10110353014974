import React from "react";

import { ReactComponent as AlbumIcon } from "assets/icons/icons-colorful-albums.svg";
import { ReactComponent as ConnectionIcon } from "assets/icons/icons-colorful-connection.svg";
import { ReactComponent as EventsIcon } from "assets/icons/icons-colorful-event.svg";
import { ReactComponent as FileIcon } from "assets/icons/icons-colorful-file.svg";
import { ReactComponent as GroupsIcon } from "assets/icons/icons-colorful-group.svg";
// import { ReactComponent as HelpIcon } from "assets/icons/icons-colorful-help.svg";
import { ReactComponent as CollectionIcon } from "assets/icons/icons-colorful-menu-series.svg";
// import { ReactComponent as PlaceIcon } from "assets/icons/icons-colorful-place.svg";
// import { ReactComponent as RewardsIcon } from "assets/icons/icons-colorful-reward.svg";
import { ReactComponent as PodcastIcon } from "assets/icons/icons-colorful-podcasts.svg";
import { ReactComponent as ScheduledPostIcon } from "assets/icons/icons-colorful-scheduled-post.svg";
// import { ReactComponent as SettingsIcon } from "assets/icons/icons-colorful-settings.svg";
import { ReactComponent as TrackIcon } from "assets/icons/icons-colorful-tracks.svg";
import { ReactComponent as VideoIcon } from "assets/icons/icons-colorful-video.svg";
import { ReactComponent as RequestIcon } from "assets/icons/requests.svg";
import { Icon } from "shared/ui-kit";

export interface MenuItemModel {
	title: string;
	icon?: JSX.Element;
	link?: string;
	featureValueName?: string | string[];
}

export enum FeatureFlags {
	connections = "enableConnecting",
	events = "enableEvents",
	groups = "enableGroups",
	tracks = "enableAudioTracks",
	collections = "enableCollections",
	forum = "enableForum",
	chat = "enableChat",
	files = "enableFiles",
	videos = "enableVideos",
	carpool = "enableCarpool",
	volunteer = "enableVolunteers"
}

export const memberMenuItems = (workspace: string, memberRoutes: any | null): MenuItemModel[] => {
	return [
		// {
		// 	title: "Discussion Boards",
		// 	icon: <ConnectionIcon />
		// 	// link: memberRoutes?.member.connections.getPath()
		// },
		// {
		// 	title: "Marketing Page",
		// 	icon: <ConnectionIcon />
		// 	// link: memberRoutes?.member.connections.getPath()
		// },
		{
			title: "Connections",
			icon: <ConnectionIcon />,
			link: memberRoutes?.member.connections.getPath(),
			featureValueName: FeatureFlags.connections
		},
		{
			title: "Events",
			icon: <EventsIcon />,
			link: memberRoutes?.member.events.getPath(),
			featureValueName: FeatureFlags.events
		},
		{
			title: "Groups",
			icon: <GroupsIcon />,
			link: memberRoutes?.member.groups.getPath(),
			featureValueName: FeatureFlags.groups
		},
		{
			title: "Tracks",
			icon: <TrackIcon />,
			link: memberRoutes?.member.tracks.getPath(),
			featureValueName: FeatureFlags.tracks
		},
		{
			title: "Shows & Podcasts",
			icon: <PodcastIcon />,
			link: memberRoutes?.member.podcasts.getPath(),
			featureValueName: FeatureFlags.tracks
		},
		{
			title: "Albums",
			icon: <AlbumIcon />,
			link: memberRoutes?.member.albums.getPath(),
			featureValueName: FeatureFlags.tracks
		},
		{
			title: "Files",
			icon: <FileIcon />,
			link: memberRoutes?.member.files.getPath(),
			featureValueName: FeatureFlags.files
		},
		{
			title: "Videos",
			icon: <VideoIcon />,
			link: memberRoutes?.member.videos.getPath(),
			featureValueName: FeatureFlags.videos
		},
		{
			title: "Series & Collection",
			icon: <CollectionIcon />,
			link: memberRoutes?.member.collections.getPath(),
			featureValueName: FeatureFlags.collections
		},
		{
			title: "Scheduled post",
			icon: <ScheduledPostIcon />,
			link: memberRoutes?.member.futureposts.getPath(),
			featureValueName: FeatureFlags.forum
		},
		{
			title: "Requests",
			icon: <RequestIcon className="request-fill" />,
			link: memberRoutes?.member.requests.getPath(),
			featureValueName: [FeatureFlags.carpool, FeatureFlags.volunteer]
		},
		{
			title: "Fundraisers",
			icon: <Icon name="fundraiser" group="color" className="fundraiser-fill" />,
			link: memberRoutes?.member.fundraisers.getPath(),
			featureValueName: FeatureFlags.forum
		}
		// {
		// 	title: "Rewards",
		// 	icon: <RewardsIcon />
		// 	// link: memberRoutes?.member.rewards.getPath()
		// },
		// {
		// 	title: "Places",
		// 	icon: <PlaceIcon />,
		// 	// link: memberRoutes?.member.places.getPath(),
		// 	featureValueName: "enablePlaces"
		// },
		// {
		// 	title: "Settings",
		// 	icon: <SettingsIcon />
		// 	// link: memberRoutes?.member.settings.getPath()
		// },
		// {
		// 	title: "Help & Support",
		// 	icon: <HelpIcon />
		// 	// link: memberRoutes?.member.help.getPath()
		// }
	];
};
