import React from "react";

import { useLocation, useParams } from "react-router";

import { view } from "modules/MemberHome";
import { FeatureFlags } from "shared/Components/MemberNavPanel/constants";
import useCheckIfEnabled from "shared/hooks/useCheckIfFlagEnabled";

const AlbumPage = () => {
	useCheckIfEnabled(FeatureFlags.tracks);

	const { id } = useParams() as { id: string };

	const { search } = useLocation();

	const query = new URLSearchParams(search);

	const download = query.get("download");
	const musicIds = query.get("musicIds");

	return <view.Album id={id} download={download} musicIds={musicIds} />;
};

export default AlbumPage;
