import React, { useCallback } from "react";

import { FormControl, TextField } from "@material-ui/core";

import { Controller, useForm } from "react-hook-form";

import { useFaq } from "modules/Settings/Data";

import ConfirmLeavePopup from "shared/Components/ConfirmLeave";
import useConfirmLeavePopup from "shared/Components/ConfirmLeave/hooks/useConfirmLeavePopup";
import { Text } from "shared/ui-kit";

import {
	ActionBtn,
	AddFaqModalHeader,
	AddFaqsModal,
	BodyWrapper,
	FooterWrapper,
	HeadlineText,
	IconWrapper,
	InputWrapper,
	ModalOverlay,
	StyledIcon
} from "./style";

interface AddFaqModalProps {
	handleDelete?: (id: any) => void;
}

const AddFaqModal: React.FC<AddFaqModalProps> = ({}) => {
	const { setStateOfModal, createFaqItem, updateFaqItem, getData: getFaqData } = useFaq();
	const { modalFooterActionsText, isModalOpen, modalTitle, order, faq } = getFaqData();

	const { formState, handleSubmit, control } = useForm({ mode: "onChange" });
	const hasValues = obj => obj._id !== "";

	const onSubmit = values => {
		hasValues(faq) ? updateFaqItem({ ...values, _id: faq._id }) : createFaqItem({ ...values, order });
	};

	const renderForm = () => (
		<>
			<BodyWrapper>
				<InputWrapper>
					<FormControl>
						<HeadlineText>Question title</HeadlineText>
						<Controller
							name={"title"}
							control={control}
							rules={{
								required: "Title is required"
							}}
							defaultValue={faq && faq.title}
							render={({ onChange, value, ref }) => (
								<TextField
									placeholder="Type the question title here..."
									variant="outlined"
									multiline
									rows={2}
									onChange={onChange}
									value={value}
									ref={ref}
									id="question"
								/>
							)}
						/>
					</FormControl>
				</InputWrapper>
				<InputWrapper>
					<HeadlineText>Answer content</HeadlineText>
					<FormControl>
						<Controller
							name={"description"}
							control={control}
							rules={{
								required: "Description is required"
							}}
							defaultValue={faq && faq.description}
							render={({ onChange, value, ref }) => (
								<TextField
									placeholder="Type the answer here..."
									variant="outlined"
									multiline
									rows={7}
									onChange={onChange}
									value={value}
									ref={ref}
									id="answer"
								/>
							)}
						/>
					</FormControl>
				</InputWrapper>
			</BodyWrapper>
			<FooterWrapper>
				<ActionBtn
					type="submit"
					disabled={!formState.isValid || !formState.isDirty || formState.isSubmitting}
					id="createFAQ"
				>
					{modalFooterActionsText?.submitText || "Create Question"}
				</ActionBtn>
			</FooterWrapper>
		</>
	);
	const onClose = useCallback(() => {
		setStateOfModal({ isOpen: false });
	}, [setStateOfModal]);

	const {
		handleLeavePageConfirmed,
		closeConfirmPopup,
		handleClose,
		getData: getConfirmLeavePopupData
	} = useConfirmLeavePopup({
		onClose,
		open: isModalOpen
	});
	const { showConfirmPopup } = getConfirmLeavePopupData();

	const { isDirty } = formState;

	return (
		<>
			<ConfirmLeavePopup
				handleLeavePage={handleLeavePageConfirmed}
				open={showConfirmPopup}
				onClose={closeConfirmPopup}
				popup
			/>
			<ModalOverlay>
				<AddFaqsModal
					onClick={() => setStateOfModal({ isOpen: true })}
					open={isModalOpen}
					onClose={() => handleClose(isDirty)}
					maxWidth="sm"
					modalHeight={700}
				>
					<AddFaqModalHeader>
						<Text className="heading" align="center" display="initial" variant="h6">
							{modalTitle}
						</Text>
						<IconWrapper>
							<StyledIcon
								fill="#c5cee0"
								group="filled"
								height={24}
								name="close"
								width={24}
								onClick={() => setStateOfModal({ isOpen: false })}
							/>
						</IconWrapper>
					</AddFaqModalHeader>
					<form onSubmit={handleSubmit(onSubmit)}>{renderForm()}</form>
				</AddFaqsModal>
			</ModalOverlay>
		</>
	);
};

export default AddFaqModal;
