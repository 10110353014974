import { useCallback, useMemo } from "react";

import { useTransactionApiService } from "modules/Payments/Data";
import { useMembers, useNotification } from "shared/hooks";

import { BadgeModel, GetBadgeResponseModel } from "shared/types";

import { SelectedMemberType } from "./../types/RewardedMemberType";

import { useBadgeRulesStore, useLoyaltyStore } from "../contexts/LoyaltyContext";

import { HistoryDialogType } from "../contexts/LoyaltyContext/LoyaltyContext";

import { useLoyaltyRewardsService } from "../services";
import useBadgeService from "../services/useBadgeService";
import useRulesIncentivesService from "../services/useRulesIncentivesService";

import { GetRule, IEventType, RulePayload } from "../types/RulesType";

const useIntegrations = () => {
	const { setUsers, getData: getMembersData } = useMembers();
	const { users } = getMembersData();

	const { showMessage } = useNotification();
	const loyaltyRewardsApiService = useLoyaltyRewardsService();
	const rulesIncentivesService = useRulesIncentivesService();
	const badgesService = useBadgeService();
	const transactionApiService = useTransactionApiService();

	const store = useLoyaltyStore();
	const { setState } = useLoyaltyStore();
	const badgeRulesStore = useBadgeRulesStore();
	const { setState: setStateBadgesRules } = useBadgeRulesStore();

	const awardUser = useCallback(
		async (personaIds: number[], amount: number) => {
			const { success } = await loyaltyRewardsApiService.awardMembers(personaIds, amount);
			if (success) {
				setUsers(
					users.map(user =>
						personaIds.some(id => id === user.personaId)
							? {
									...user,
									loyaltyAllTimePoints: user.loyaltyAllTimePoints + amount,
									loyaltyCurrentBalance: user.loyaltyCurrentBalance + amount
							  }
							: user
					)
				);

				setState({ awardDialog: { open: false, members: [] }, selectedMembers: [] });
				showMessage("The Points were successfully granted! 🎉");
			}
		},
		[loyaltyRewardsApiService, setState, showMessage, setUsers, users]
	);

	const methods = useMemo(
		() => ({
			getBadges: async ({ offset, limit = 25 }: { offset: number; limit?: number }) => {
				setStateBadgesRules({ loading: true });
				try {
					const { badges } = await badgesService.badges({ offset, limit });
					badges.length < limit && setStateBadgesRules({ hasMoreBadges: false });

					setStateBadgesRules(ctx => ({ badges: offset === 1 ? badges : [...ctx.badges, ...badges], loading: false }));
				} catch (error) {
					showMessage("Failed to fetch badges");
					setStateBadgesRules({ loading: false });
				}
			},
			createBadge: async (data: BadgeModel) => {
				setStateBadgesRules({ createUpdateLoading: true });
				try {
					const { success, badge } = await badgesService.createBadge(data);
					if (!success) throw new Error();

					setStateBadgesRules(ctx => ({
						badges: [badge, ...ctx.badges],
						createUpdateBadgeDialog: false,
						createUpdateLoading: false
					}));
					showMessage("Created the badge successfully");
				} catch (error) {
					showMessage("Failed to create the badge");
					setStateBadgesRules({ createUpdateLoading: false });
				}
			},
			updateBadge: async (data: BadgeModel, id: number) => {
				setStateBadgesRules({ createUpdateLoading: true });
				try {
					const { success } = await badgesService.updateBadge(data, id);
					if (!success) throw new Error();

					setStateBadgesRules(ctx => ({
						badges: ctx.badges.map(badge => (badge.id === id ? { ...badge, ...data } : badge)),
						createUpdateBadgeDialog: false,
						createUpdateLoading: false
					}));
					showMessage("Updated the badge successfully");
				} catch (error) {
					showMessage("Failed to update the badge");
					setStateBadgesRules({ createUpdateLoading: false });
				}
			},
			deleteBadge: async (id: number) => {
				try {
					const { success } = await badgesService.deleteBadge(id);
					if (!success) throw new Error();

					setStateBadgesRules(ctx => ({
						badges: ctx.badges.filter(badge => badge.id !== id),
						createUpdateBadgeDialog: false
					}));
					showMessage("Deleted the badge successfully");
				} catch (error) {
					setStateBadgesRules({ createUpdateBadgeDialog: false });
					showMessage("Failed to delete the badge");
				}
			},
			getRules: async (keyword = "", offset, limit = 25) => {
				!!keyword.length ? setStateBadgesRules({ loading: true, rules: [] }) : setStateBadgesRules({ loading: true });

				try {
					const { rules } = await rulesIncentivesService.getRules(offset, limit, keyword);
					rules.length < limit
						? setStateBadgesRules({ hasMoreRules: false })
						: setStateBadgesRules({ hasMoreRules: true });

					!keyword.length && offset !== 1
						? setStateBadgesRules(ctx => ({ rules: [...ctx.rules, ...rules], loading: false }))
						: setStateBadgesRules({ loading: false, rules });
				} catch (error) {
					showMessage("Failed to fetch rules");
					setStateBadgesRules({ loading: false });
				}
			},
			createRule: async (data: RulePayload & IEventType) => {
				setStateBadgesRules({ createUpdateLoading: true });
				try {
					const { success, rule } = await rulesIncentivesService.createRule(data);

					if (!success) throw new Error();

					setStateBadgesRules(ctx => ({
						rules: [...ctx.rules, { ...rule, ...data }],
						createUpdateRuleDialog: false,
						createUpdateLoading: false
					}));
					showMessage("Created the rule successfully");
				} catch (error) {
					setStateBadgesRules({ createUpdateRuleDialog: false, createUpdateLoading: false });
					showMessage("Failed to create the rule");
				}
			},
			updateRule: async (data: Partial<RulePayload>, id: number) => {
				setStateBadgesRules({ createUpdateLoading: true });
				try {
					const { success } = await rulesIncentivesService.updateRule(data, id);
					if (!success) throw new Error();

					setStateBadgesRules(ctx => ({
						rules: ctx.rules.map(rule => (rule.id === id ? { ...rule, ...data } : rule)),
						createUpdateRuleDialog: false,
						createUpdateLoading: false
					}));
					showMessage("Updated the rule successfully");
				} catch (error) {
					setStateBadgesRules({ createUpdateRuleDialog: false, createUpdateLoading: false });
					showMessage("Failed to update the rule");
				}
			},
			updateRuleActivity: async (active: boolean, id: number) => {
				setStateBadgesRules(ctx => ({
					rules: ctx.rules.map(rule => (rule.id === id ? { ...rule, active } : rule))
				}));
				try {
					const { success } = await rulesIncentivesService.updateRuleActivity(active, id);
					if (!success) throw new Error();

					setStateBadgesRules(ctx => ({
						rules: ctx.rules.map(rule => (rule.id === id ? { ...rule, active } : rule)),
						createUpdateRuleDialog: false
					}));
					showMessage("Updated the rule successfully");
				} catch (error) {
					showMessage("Failed to update the rule");
				}
			},
			deleteRule: async (id: number) => {
				try {
					const { success } = await rulesIncentivesService.deleteRule(id);
					if (!success) throw new Error();

					setStateBadgesRules(ctx => ({
						rules: ctx.rules.filter(rule => rule.id !== id),
						createUpdateRuleDialog: false
					}));
					showMessage("Deleted the rule successfully");
				} catch (error) {
					setStateBadgesRules({ createUpdateRuleDialog: false });
					showMessage("Failed to delete the rule");
				}
			},
			setBadge: (badges: GetBadgeResponseModel[]) => {
				setStateBadgesRules({ badges });
			},
			setRules: (rules: GetRule[]) => {
				setStateBadgesRules({ rules });
			},
			setSelectedMembers: (selectedMembers: { label: string; value: number; profilePicture?: string | null }[]) => {
				setState({ selectedMembers });
			},
			setAwardDialog: (awardDialog: { open: boolean; members: SelectedMemberType[] }) => {
				setState({ awardDialog });
			},
			setHistoryDialog: (historyDialog: HistoryDialogType) => {
				setState({ historyDialog });
			},
			setCreateUpdateBadgeDialog: (createUpdateBadgeDialog: boolean) => {
				setStateBadgesRules({ createUpdateBadgeDialog });
			},
			setCreateUpdateRuleDialog: (createUpdateRuleDialog: boolean) => {
				setStateBadgesRules({ createUpdateRuleDialog });
			},
			getMemberRewardHistoryCount: async ({ personaId }: { personaId: number }) => {
				const historyCount = await transactionApiService.getTransactionsCount("", null, null, ["points"], personaId);
				setState({ historyCount });
			},
			getMemberRewardHistory: async ({ personaId, page }: { personaId: number; page: number }) => {
				setState({ loading: true });
				const response = await transactionApiService.getTransactions(page, 5, "", null, null, ["points"], personaId);
				const history = response.map(r => ({ ...r.receipt, createdAt: r.createdAt }));

				if (history.length) {
					setState(ctx => ({
						historyDialog: {
							...ctx.historyDialog,
							history: page > 1 ? ctx.historyDialog.history && [...ctx.historyDialog.history, ...history] : history
						}
					}));
				}

				setState({ loading: false });
			},
			getWithdrawRequests: async (offset: number, limit: number, keyword?: string) => {
				setState({ loading: true });
				try {
					const { withdrawRequests } = await loyaltyRewardsApiService.getWithdrawRequests(offset, limit, keyword);
					setState({
						withdrawRequests,
						loading: false
					});
				} catch (error) {
					showMessage("Failed to fetch withdraw requests");
					setState({ loading: false });
				}
			},
			approveWithdrawRequest: async (withdrawRequestId: string) => {
				try {
					const { success } = await loyaltyRewardsApiService.approveWithdrawRequest(withdrawRequestId);
					if (!success) throw new Error();

					setState(ctx => ({
						withdrawRequests: ctx.withdrawRequests.map(request =>
							request.id === withdrawRequestId
								? { ...request, approvedAt: new Date().toISOString(), claimedAt: new Date().toISOString() }
								: request
						),
						loading: false
					}));
					showMessage("Withdraw request approved successfully");
				} catch (error) {
					showMessage("Failed to approve withdraw request");
					setState({ loading: false });
				}
			},
			deleteWithdrawRequest: async (withdrawRequestId: string) => {
				try {
					const { success } = await loyaltyRewardsApiService.deleteWithdrawRequest(withdrawRequestId);
					if (!success) throw new Error();

					setState(ctx => ({
						withdrawRequests: ctx.withdrawRequests.filter(request => request.id !== withdrawRequestId),
						loading: false
					}));
					showMessage("Withdraw request deleted successfully");
				} catch (error) {
					showMessage("Failed to delete withdraw request");
					setState({ loading: false });
				}
			},
			getTotalOutstandingPoints: async () => {
				try {
					const { totalOutstandingPoints } = await loyaltyRewardsApiService.getAdminMetrics();
					setState({ totalOutstandingPoints });
				} catch (error) {}
			}
		}),
		[
			setState,
			showMessage,
			transactionApiService,
			badgesService,
			rulesIncentivesService,
			setStateBadgesRules,
			loyaltyRewardsApiService
		]
	);

	const getData = useCallback(() => {
		return store;
	}, [store]);

	const getBadgeRulesData = useCallback(() => {
		return badgeRulesStore;
	}, [badgeRulesStore]);

	return { ...methods, getData, awardUser, getBadgeRulesData };
};

export default useIntegrations;
