import React, { FC } from "react";

import { Box, Text } from "shared/ui-kit";

import { IconWrapper, RequestList, SignUpButton, TextBadge } from "./styles";

interface DetailTemplateProps {
	key: string | number;
	image: React.ReactNode;
	caption1: string;
	title: string | JSX.Element;
	caption2: string | JSX.Element | null;
	buttonText: string;
	onClick?: () => void;
	isSquareShape?: boolean;
	badgeColor?: string;
	buttonClassName?: string;
	disabledButton?: boolean;
	titleClassName?: string;
	customButton?: React.ReactNode;
}

const RequestCard: FC<DetailTemplateProps> = ({
	key,
	image,
	caption1,
	title,
	caption2,
	buttonText,
	onClick,
	isSquareShape = true,
	badgeColor,
	buttonClassName,
	disabledButton,
	titleClassName,
	customButton
}) => (
	<RequestList key={key} className={"mb-2 mt-1 py-2 flex justify-between requestItem"}>
		<Box className="flex">
			<IconWrapper isSquareShape={isSquareShape}>{image}</IconWrapper>
			<Box className="flex flex-col justify-center">
				<TextBadge className="rounded-full font-semibold" color={badgeColor}>
					{caption1}
				</TextBadge>

				<Text className={titleClassName} variant="h6">
					{title}
				</Text>
				{caption2 && <Text>{caption2}</Text>}
			</Box>
		</Box>
		<Box className="flex items-center">
			{customButton ? (
				<>{customButton}</>
			) : (
				<SignUpButton size="small" onClick={onClick} className={buttonClassName} disabled={disabledButton}>
					{buttonText}
				</SignUpButton>
			)}
		</Box>
	</RequestList>
);

export default RequestCard;
