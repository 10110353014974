import styled from "styled-components";

import { Button } from "shared/ui-kit";

export const StyledMemberButton = styled(Button)`
	border-radius: 10px;
	width: 100%;
	margin: 0;
	font-size: 14px;
	font-weight: bold;
	line-height: 1.14;
	color: #fff;

	&.circular {
		border-radius: 50px;
	}
	&.small {
		font-size: 12px;
	}
	&.primary {
		background-color: #6173fe;
	}
	&.Mui-disabled {
		color: rgba(0, 0, 0, 0.26);
		background-color: rgba(143, 155, 179, 0.16);
	}
	&:nth-child(even) {
		margin-top: 8px;
	}
	.MuiButton-label {
		display: flex;
		align-items: center;
		justify-content: center;
		div {
			margin-right: 4px;
		}
	}
`;
