import { useCallback, useMemo } from "react";

import { useNotification } from "shared/hooks";
import { actionMethod, actionTypes, getValidationMessage } from "utils/getValidationMessage";

import { useFaqStore } from "../contexts";

import { useFaqApiService } from "../services";
import { FaqItemType, ModalStateModel } from "../types";

const useFaq = () => {
	const { showMessage } = useNotification();

	const faqService = useFaqApiService();

	const store = useFaqStore();
	const { setState } = useFaqStore();

	const methods = useMemo(
		() => ({
			getFaqItems: async (keyword: string) => {
				const data = await faqService.getFaqItems(keyword);
				if (data) {
					setState(ctx => ({
						...ctx,
						faqs: data.faqs,
						isLoading: false
					}));
				}
			},
			clearState() {
				setState(ctx => ({
					...ctx,
					faqs: [],
					isModalOpen: false,
					isOpen: false,
					faq: { _id: "", title: "", description: "", order: 0 },
					isLoading: false
				}));
			},
			createFaqItem: async (data: { title: string; description: string; order: number }) => {
				try {
					await faqService.createFaq({ ...data, order: data.order || 1 });
					setState(ctx => ({
						...ctx,
						isModalOpen: false,
						faqs: ctx.faqs
					}));
					await methods.getFaqItems("");
					showMessage(
						getValidationMessage({
							name: "FAQ",
							actionType: actionTypes.CRUD,
							actionMethod: actionMethod.created,
							emoji: "✨"
						}),
						3
					);
				} catch (error) {
					showMessage(
						getValidationMessage({
							name: "Required"
						}),
						3
					);
				}
			},
			updateFaqItem: async (data: {
				title: string;
				description: string;
				_id: string;
				order: number | null;
				targetOrder: number | null;
			}) => {
				try {
					const { _id: id } = data;
					// @ts-expect-error: valid logic
					delete data?._id;
					await faqService.updateFaq({ ...data, id, order: null, targetOrder: null });
					setState(ctx => ({
						...ctx,
						isModalOpen: false,
						faqs: ctx.faqs,
						isLoading: false
					}));
					await methods.getFaqItems("");
					showMessage(
						getValidationMessage({
							name: "FAQ",
							actionType: actionTypes.CRUD,
							actionMethod: actionMethod.updated,
							emoji: "✨"
						}),
						3
					);
				} catch (error) {
					showMessage(
						getValidationMessage({
							name: "Required"
						}),
						3
					);
				}
			},
			reOrderFaqItem: async (data: {
				title: string;
				description: string;
				_id?: string;
				order: number;
				targetOrder: number;
			}) => {
				try {
					const { _id: id } = data;
					delete data?._id;
					await faqService.reorderFaq({ ...data, id });
				} catch (error) {
					showMessage(
						getValidationMessage({
							name: "Required"
						}),
						3
					);
				}
			},
			async deleteFaq(id: string) {
				const data = await faqService.deleteFaq(id);
				if (data) {
					setState(ctx => ({
						...ctx,
						faqs: [...(ctx.faqs as FaqItemType[]).filter(faq => faq._id !== id)]
					}));
				}
			},
			setStateOfModal: ({ isOpen = true, ...rest }: ModalStateModel) => {
				setState(ctx => ({
					...ctx,
					isModalOpen: isOpen,
					...rest
				}));
			},
			setNewFaq: (data: any) => {
				setState(ctx => ({
					...ctx,
					faqs: data.newFaqOrder,
					isModalOpen: false,
					isOpen: false,
					faq: data.newFaqOrder[data.result.destination.index],
					isLoading: false
				}));
			}
		}),
		[faqService, setState, showMessage]
	);

	const getData = useCallback(() => {
		return store;
	}, [store]);

	return { ...methods, getData };
};

export default useFaq;
