import styled from "styled-components";

import { Box, FormContainer, Text } from "shared/ui-kit";

export const ContentWrapper = styled(FormContainer)`
	width: 100%;
	height: calc(90vh - 72px);
	min-height: 500px;
	max-height: 850px;

	overflow: hidden;

	border-radius: 4px;

	box-shadow: 2px 2px 4px 0 rgb(0 0 0 / 8%), -2px 0 4px 0 rgb(0 0 0 / 8%);

	background: #fff;

	margin: 24px 0 8px;
	padding: 0;

	display: flex;
	flex-wrap: nowrap;

	&.rounded-corners {
		border-radius: 8px;
	}

	${props => props.theme.breakpoints.down("sm")} {
		margin: 0;
		height: calc(100vh - 72px);
	}
`;

export const Title = styled(Text)`
	padding-left: 18px;
	margin-top: 8px;

	font-size: 26px;
	font-weight: bold;
	line-height: 1.23;
	letter-spacing: normal;
	color: #222b45;

	${props => props.theme.breakpoints.down("sm")} {
		font-size: 22px;
		padding: 0;
		margin-top: 2px;
	}
`;

export const InboxPanelWrapper = styled(Box)`
	width: 385px;
	border-right: 1px solid #edf1f7;

	@keyframes right-slide {
		0% {
			display: none;
			opacity: 0;
			background-color: #fff;
			z-index: 100;
		}
		1% {
			display: block;
			opacity: 1;
			transform: translateX(300px);
		}
		100% {
			transform: translateX(0px);
		}
	}

	@media (max-width: 1230px) {
		display: none;
	}

	&.active_inbox {
		@media (max-width: 1230px) {
			display: block !important;
			animation: right-slide cubic-bezier(0.39, 0.58, 0, 1.13) 0.5s forwards;
			width: 100% !important;
		}
	}
`;
export const ChatWrapper = styled(Box)`
	width: calc(100% - 385px);
	height: 100%;

	overflow: hidden;

	@keyframes left-slide {
		0% {
			display: none;
			opacity: 0;
			background-color: #fff;
			z-index: 100;
		}
		1% {
			display: block;
			opacity: 1;
			transform: translateX(-100px);
		}
		100% {
			transform: translateX(0px);
		}
	}

	@media (max-width: 1230px) {
		animation: left-slide cubic-bezier(0.39, 0.58, 0, 1.13) 0.5s forwards;
		width: 100%;
	}

	&.active_inbox {
		@media (max-width: 1230px) {
			display: none !important;
		}
	}
`;
