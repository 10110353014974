import React from "react";

import { useLocation, useParams } from "react-router";

import { view } from "modules/MemberHome";

import { useMemberRoutes } from "shared/hooks";

const FilePage = () => {
	const { id } = useParams() as { id: string };
	const { getMemberRoutesData } = useMemberRoutes();
	const { routes } = getMemberRoutesData();
	const { search } = useLocation();
	const query = new URLSearchParams(search);
	const groupId = query.get("groupId") || undefined;

	return <view.File id={id} groupId={groupId} routes={routes} />;
};

export default FilePage;
