import { Badge } from "@material-ui/core";

import { FixedSizeList as List } from "react-window";

import styled from "styled-components";

import { Avatar, Box, Text } from "shared/ui-kit";

export const InboxList = styled(List)`
	width: 100%;
	overflow: hidden !important;
`;

export const InboxItemWrapper = styled(Box)`
	&.active-chat::after {
		content: "";
		position: absolute;
		left: 0;
		top: 0;
		height: calc(100% - 8px);
		width: 2px;
		border-top-right-radius: 4px;
		border-bottom-right-radius: 4px;
		background-color: ${({ color }) => color};
	}

	&.memberChat {
		padding: 0 8px;
	}
`;

InboxItemWrapper.Content = styled(Box)`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	height: 100%;

	background: ${({ unreadcount, active }) => (active ? "#ebecff" : unreadcount ? "#f9f9ff" : "#fff")};

	border-bottom: 1px solid #edf1f7;

	cursor: pointer;

	&.active {
		box-shadow: inset 8px 0 0 -4px #6173fe;

		background: #ebecff;
	}

	&.unread {
		background: rgba(235, 236, 255, 0.3);
	}

	&:hover {
		background: #ebecff;

		.more {
			opacity: 1;
		}
	}

	&:first-child {
		border-top: 1px solid #edf1f7;
	}

	&.memberChat {
		height: calc(100% - 8px);
		border-radius: 8px;
		border: 0;
		&.active,
		&.unread {
			box-shadow: none;
			background-color: ${({ color }) => `${color}0d`};
		}
	}
`;

export const StyledAvatar = styled(Avatar)`
	width: 32px;
	height: 32px;
	border-radius: 4px;
	margin: 19px 16px 21px;
	&.memberView {
		width: 48px;
		height: 48px;
		border-radius: 8px;
		border: solid 1px #fff;
		margin-top: 0;
		margin-bottom: 0;
	}
`;

export const GroupdAvatarContainer = styled(Badge)`
	margin: 19px 16px 21px;
	&.member-margins {
		margin: 12px 30px 21px 16px;
		.MuiAvatar-root {
			border: 1px solid #fff;
		}
	}
	&.no-ml {
		margin-left: 0;
	}
`;

export const StyledGroupAvatar = styled(Avatar)`
	width: 24px;
	height: 24px;
	border-radius: 4px;
	&.large-avatar {
		width: 34px;
		height: 34px;
	}
`;

export const InboxItemInfo = styled(Box)`
	display: flex;
	padding-top: 16px;
	padding-bottom: 16px;
	padding-right: 16px;
	flex-direction: column;
	height: 100%;
	width: 100%;
	overflow: hidden;
`;

export const styledText = styled(Text)`
	font-size: 12px;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.33;
	letter-spacing: normal;

	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;

	${props => props.theme.breakpoints.down("sm")} {
		font-size: 22px;
		font-weight: bold;
		line-height: 1.47;

		margin: 2px 0 7px 0;
	}
`;

export const InboxItemTitle = styled(styledText)`
	float: left;
	font-weight: 600;
	color: #222b45;
	max-width: 200px;
	margin-bottom: 5px;

	${props => props.theme.breakpoints.down("sm")} {
		font-size: 12px;

		margin-top: 0;
		margin-bottom: 0;
	}
`;

export const InboxItemDescription = styled(styledText)`
	color: #8f9bb3;

	max-width: 90%;

	margin-top: -2px;

	${props => props.theme.breakpoints.down("sm")} {
		font-size: 12px;

		margin-top: -4px;
	}
`;

export const InboxItemDate = styled(styledText)`
	float: right;
	color: #8f9bb3;
	text-align: right;

	display: flex;
	align-items: flex-end;

	${props => props.theme.breakpoints.down("sm")} {
		font-size: 12px;

		margin-top: 0;
	}
`;

InboxItemDate.ReadAmount = styled(Box)`
	font-size: 10px;
	font-weight: 600;
	color: #fff;
	background-color: #ff463d;
	border-radius: 99%;
	height: 16px;
	width: 16px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: 8px;
	&.three-digits {
		width: auto;
		padding: 0 6px;
		border-radius: 6px;
	}
`;

export const Notification = styled(Box)`
	float: right;
	text-align: center;

	width: 6px;
	height: 6px;

	margin: 0 0 2px 7px;

	background-color: #6173fe;

	border-radius: 50%;

	overflow: hidden;
`;

export const FloatingIconButton = styled(Box)`
	position: absolute;

	height: 100%;

	top: 0;
	right: 16px;

	display: flex;
	align-items: center;

	opacity: 0;

	transition: opacity 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

	&:hover {
		opacity: 1;
	}
`;

export const FavoriteIconWrapper = styled(Box)`
	position: absolute;

	bottom: 6px;
	right: 20px;

	z-index: 2;

	width: 20px;

	&.memberView {
		bottom: 12px;
	}

	svg {
		width: 20px;
		fill: #c5cee0;
	}
`;

export const SkeletonRowWrapper = styled(Box)`
	width: 100%;
	height: 72px;

	border-bottom: 1px solid #edf1f7;

	display: flex;
	align-items: center;

	padding: 10px 15px;
`;

export const SkeletonInfoWrapper = styled(Box)`
	width: calc(100% - 32px);
	height: 100%;

	padding-left: 16px;

	display: flex;
	flex-direction: column;
	justify-content: center;
`;
