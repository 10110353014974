import React, { ReactNode, memo, useEffect, useRef, useState } from "react";

import { IconButton } from "@material-ui/core";

import { MenuDots } from "shared/Components";
import { MediaType } from "shared/types";
import { Icon, Uploader, orientationConst } from "shared/ui-kit";

import { ActionBox, ButtonsWrapper, UploaderWrapper, Wrapper } from "./style";

interface InputFieldProps {
	customBackColor?: string;
	preventCustomActions?: boolean;
	mediaType?: MediaType;
	updateImage?: (type: MediaType, photo?: Partial<File>) => void;
	value?: string;
	height?: number;
	placeholderImg?: ReactNode;
	type?: string;
	onChange?: (file: Partial<File> | null) => void;
	customIcon?: ReactNode;
}

const UploaderWithCheck: React.FC<InputFieldProps> = ({
	customBackColor,
	preventCustomActions = false,
	mediaType,
	height,
	value,
	placeholderImg,
	type,
	onChange,
	updateImage,
	customIcon
}) => {
	const [file, setFile] = useState<Partial<File> | string | undefined>(value);
	const [isChanged, setIsChanged] = useState(false);

	const uploaderRef = useRef(null);

	useEffect(() => {
		setFile(value);
	}, [value]);

	return (
		<Wrapper>
			<UploaderWrapper
				className={!file && placeholderImg && "custom-placeholder-img"}
				customBackColor={customBackColor}
			>
				<Uploader
					ref={uploaderRef}
					showPreview
					onChange={([file]) => {
						setIsChanged(true);
						onChange ? onChange(file) : setFile(file);
					}}
					orientation={orientationConst.horizontal}
					width="100%"
					label={""}
					height={height || 154}
					urls={value ? [value] : undefined}
					description={<></>}
					accept={[
						{
							fileType: "image/png, image/jpeg, image/jpg, image/x-eps",
							name: ""
						}
					]}
					icon={
						customIcon ? (
							<>{customIcon}</>
						) : (
							<Icon
								group={"filled"}
								fill={"#fff"}
								name={"image-landscape"}
								width={73}
								height={53}
								viewBox={"0 7 20 5"}
							/>
						)
					}
				/>
				{!file && placeholderImg && <UploaderWrapper.PlaceholderImg>{placeholderImg}</UploaderWrapper.PlaceholderImg>}
				<UploaderWrapper.ReplaceWrapper
					className={`${type === "square" && "center"} replace-wrapper ${!!file && "active"}`}
				>
					<MenuDots
						options={[
							{
								name: "Change Photo",
								onClick: () => {
									// @ts-expect-error: ref link object
									uploaderRef.current.callReplace();
								}
							},
							{
								name: "Remove Photo",
								onClick: () => {
									// @ts-expect-error: ref link object
									uploaderRef.current.clear();
								}
							}
						]}
						removeSideMargin
						removeBg
						vertical
						customImage={
							<Icon group={"filled"} fill={"white"} name={"camera"} width={22} height={19} viewBox={"0 0 22 21"} />
						}
					/>
				</UploaderWrapper.ReplaceWrapper>
			</UploaderWrapper>
			{!preventCustomActions && isChanged && (
				<ButtonsWrapper>
					<ActionBox>
						<IconButton
							onClick={() => {
								if (updateImage && mediaType) {
									updateImage && updateImage(mediaType, file ? (file as Partial<File>) : undefined);
								}
								setIsChanged(false);
							}}
						>
							<Icon name="check" />
						</IconButton>
						<IconButton
							onClick={() => {
								// @ts-expect-error: ref link object
								uploaderRef.current.clear();
								setFile(undefined);
								setIsChanged(false);
							}}
						>
							<Icon name="close" group="filled" fill="#000" />
						</IconButton>
					</ActionBox>
				</ButtonsWrapper>
			)}
		</Wrapper>
	);
};

export default memo(UploaderWithCheck);
