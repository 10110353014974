import styled from "styled-components";

import { Box } from "shared/ui-kit";

export const Wrapper = styled(Box)`
	width: 100%;

	display: flex;
	align-items: flex-start;
	justify-content: space-between;

	&.reply {
		max-width: 280px;

		.avatar-wrapper {
			width: 24px;
			height: 24px;
		}

		.text-wrapper {
			width: calc(100% - 32px);
		}
	}
`;

export const AvatarWrapper = styled(Box)`
	width: 40px;
	height: 40px;

	margin-right: 8px;
`;

export const TextWrapper = styled(Box)`
	width: calc(100% - 50px);

	border-radius: 12px;

	overflow: hidden;
`;
