import styled, { css } from "styled-components";

import { Box, Radio, Text } from "shared/ui-kit";

export const Wrapper = styled(Box)`
	${({ isMemberView }) =>
		isMemberView &&
		css`
			padding: 0 16px;
			border-radius: 8px;
			border: solid 1px #e4e9f2;
		`}
	.memberViewChoice label {
		border-radius: 8px;
		border: solid 1px #e4e9f2;
		padding: 16px;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		flex: 1;
		margin: 0;
		margin-top: 8px;
		.MuiIconButton-root {
			padding: 0;
			margin-right: 8px;
		}
		label {
			font-size: 14px;
			font-weight: 600;
			line-height: 1.85;
			color: #222b45;
		}
		&.active {
			border: solid 1px #6173fe;
			background-color: #f5f5ff;
		}

		${props => props.theme.breakpoints.up("md")} {
			padding: 0 16px;
			height: 48px;

			&:nth-child(odd) {
				margin-right: 4px;
			}
			&:nth-child(even) {
				margin-left: 4px;
			}
		}
	}
	.createPostView .MuiFormGroup-root {
		display: flex;
		align-items: center;
		flex-direction: row;
	}
`;

export const PremiumSetBlock = styled(Box)<{ hasBorder: boolean }>`
	padding: 16px 0;

	${props => props.theme.breakpoints.down("md")} {
		margin-bottom: 16px;

		background: white;
	}

	&.setup {
		border-bottom: ${({ hasBorder }) => (hasBorder ? "1px solid #c5cee0" : "none")};
	}

	&.hidden {
		visibility: hidden;
		max-height: 0;
		padding: 0;
	}

	&.isMemberView {
		padding-top: 0;
	}
`;

export const StyledRadio = styled(Radio)`
	${props => props.theme.breakpoints.down("sm")} {
		flex-direction: column;
		.MuiFormGroup-row {
			flex-direction: column;
		}
	}
`;

export const Title = styled(Text)`
	font-size: 16px;
	font-weight: 500;
	line-height: 1.33;
	color: #222b45;
`;

export const Footer = styled(Box)``;

Footer.Wrapper = styled(Box)`
	margin-top: 12px;
`;

Footer.Text = styled(Text)`
	font-size: 12px;
	font-weight: normal;
	line-height: 1.33;
	color: #8f9bb3;
`;
