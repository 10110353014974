import config from "config/appConfig";

import { GroupModel, Location } from "shared/types";
import { IStateProps } from "utils/StateOf";

import createStore from "utils/createStore";

const localStorageKey = `${config.GLOBAL_CONSTANTS.LOCAL_STORAGE_PREFIX}${config.HOOK_STORAGE_KEYS.GROUP_DATA}`;

export interface Values {
	groups: GroupModel[] | null;
	totalFoundGroups?: number;
	filteredGroups: GroupModel[] | null;
	filteredGroupsCount: number;
	suggestedLocations: Location[];
	groupImageUploadUrl: string;
	loading: boolean;
	loadingGroupsCount: boolean;
	creating: boolean;
	page: number;
	groupsShowPerPage: number;
	group: GroupModel | null;
	loadingGroup: boolean;
	shouldStopSearching?: boolean;
	approveAllLoading?: boolean;
	declineAllLoading?: boolean;
}

export const initialState: IStateProps<Values> = {
	groups: null,
	filteredGroups: null,
	filteredGroupsCount: 0,
	suggestedLocations: [],
	groupImageUploadUrl: "",
	loading: false,
	loadingGroupsCount: false,
	creating: false,
	page: 1,
	groupsShowPerPage: 20,
	group: null,
	loadingGroup: false,
	shouldStopSearching: false,
	approveAllLoading: false,
	declineAllLoading: false
};

export default createStore<Values>({
	initialState,
	persistant: true,
	persistantName: localStorageKey
});
