import React, { useCallback, useEffect, useMemo, useState } from "react";

import { DateTime } from "luxon";

import { CreateTrack, MenuDots, PlaceholderImage, TableWrapper } from "shared/Components";
import { TableColumnLoaderType } from "shared/Components/NewTable/Components";
import { Cell } from "shared/Components/NewTable/style";
import { useAlbums, useDebounce, usePremiumContent, useTrack } from "shared/hooks";

import { AlbumModel } from "shared/services/useAlbumApiService";
import { PlaceholderImageType } from "shared/types";

import { AddTracksToPlaylist, AlbumDetailsDialog, ConfirmDelete, PageTemplate } from "../../Components";
import CreateUpdateAlbumDialog from "../../Components/CreateUpdateAlbumDialog";

const Table = React.lazy(() => import("shared/Components/NewTable"));

const ManageAlbums = () => {
	const {
		getAlbums,
		setAlbumCreateOpen,
		setPage,
		setAlbumsShowPerPage,
		setDeleteAlbumDetails,
		setAlbumDetailsDialog,
		setEditAlbumDetails,
		getAllAlbumsCount,
		deleteAlbum,
		getData: getAlbumsData
	} = useAlbums();
	const {
		isLoading,
		albums,
		page,
		albumsShowPerPage,
		albumCreateOpen,
		deleteAlbumDetails,
		albumDetailsDialog,
		editAlbumDetails,
		totalAlbumsCount
	} = getAlbumsData();

	const { getCoinPrice } = usePremiumContent();
	const { setTrackInfoPopup, setRefresh, setTrackDuration, getData: getTrackData } = useTrack();
	const { trackInfoPopup, refresh } = getTrackData();

	const [searchText, setSearchText] = useState("");

	const debouncedSearchText = useDebounce(searchText, 400);

	useEffect(() => {
		getAlbums({ limit: albumsShowPerPage, offset: page, keyword: debouncedSearchText });
	}, [getAlbums, albumsShowPerPage, debouncedSearchText, page]);

	useEffect(() => {
		getAllAlbumsCount({ keyword: debouncedSearchText });
	}, [getAllAlbumsCount, debouncedSearchText]);

	const handleSearch = useCallback(
		(val: string) => {
			if (val === "" || val.length >= 3) {
				setSearchText(val);
				setPage(1);
			}
		},
		[setPage]
	);

	const getOptions = useCallback(
		(rowData: AlbumModel) => {
			const options: {
				name: string;
				onClick: () => void;
				id?: string;
			}[] = [
				{
					name: "Edit Album Details",
					onClick: () => setEditAlbumDetails(rowData),
					id: "editAlbumDetails"
				},
				{
					name: "Edit Playlist",
					onClick: () => setAlbumDetailsDialog({ open: true, deletedTracks: [], id: rowData._id }),
					id: "editPlaylist"
				},
				{
					name: "Delete",
					onClick: () =>
						setDeleteAlbumDetails({
							id: rowData._id,
							name: rowData.title
						}),
					id: "deleteAlbum"
				}
			];

			return options;
		},
		[setAlbumDetailsDialog, setDeleteAlbumDetails, setEditAlbumDetails]
	);

	const tableColumns = useMemo(
		() => [
			{
				alignment: "left",
				minWidth: 280,
				width: 300,
				label: <Cell.HeaderText>{totalAlbumsCount} Albums</Cell.HeaderText>,
				Cell: ({ rowData: { title, creatorPersona, artist, meta } }: { rowData: AlbumModel }) => (
					<Cell.Wrapper>
						<Cell.ImageWrapper>
							{meta?.url ? (
								<Cell.Image src={meta.url} alt={title} />
							) : (
								<PlaceholderImage
									type={PlaceholderImageType.ALBUM_PREVIEW}
									width={40}
									height={40}
									viewBox={"0 0 400 200"}
								/>
							)}
						</Cell.ImageWrapper>
						<Cell.Wrapper className="column with-image">
							<Cell.Text>{title}</Cell.Text>
							<Cell.Text className="light">
								By {creatorPersona ? `${creatorPersona.firstName} ${creatorPersona.lastName}` : artist}
							</Cell.Text>
						</Cell.Wrapper>
					</Cell.Wrapper>
				),
				loaderTemplate: TableColumnLoaderType.imageWthTwoTextRows,
				dataKey: "album"
			},
			{
				alignment: "left",
				label: <Cell.HeaderText>Artist</Cell.HeaderText>,
				minWidth: 160,
				Cell: ({ rowData: { artist } }: { rowData: AlbumModel }) => (
					<Cell.Wrapper>
						<Cell.Text>{artist}</Cell.Text>
					</Cell.Wrapper>
				),
				dataKey: "artist"
			},
			{
				alignment: "left",
				minWidth: 120,
				label: <Cell.HeaderText>Genre</Cell.HeaderText>,
				Cell: ({ rowData: { genre } }: { rowData: AlbumModel }) => {
					const genres = genre.map((cat, i) => (i === genre.length - 1 ? cat : `${cat}, `));
					return (
						<Cell.Wrapper>
							<Cell.Text>{genres}</Cell.Text>
						</Cell.Wrapper>
					);
				},
				dataKey: "genre"
			},
			{
				alignment: "left",
				minWidth: 130,
				label: <Cell.HeaderText>Price</Cell.HeaderText>,
				Cell: ({ rowData: { priceTags } }: { rowData: AlbumModel }) => {
					const price = getCoinPrice(priceTags);
					return (
						<Cell.Wrapper>
							<Cell.Text>{price ? `${price} Coins` : "Free"}</Cell.Text>
						</Cell.Wrapper>
					);
				},
				dataKey: "price"
			},
			{
				alignment: "left",
				label: <Cell.HeaderText>Created Date</Cell.HeaderText>,
				minWidth: 130,
				Cell: ({ rowData: { createdAt } }: { rowData: AlbumModel }) => (
					<Cell.Wrapper className="column">
						<Cell.Text>{DateTime.fromISO(createdAt).toFormat("MMM d, yyyy")}</Cell.Text>
						<Cell.Text className="light">{DateTime.fromISO(createdAt).toFormat("t")}</Cell.Text>
					</Cell.Wrapper>
				),
				loaderTemplate: TableColumnLoaderType.twoTextRows,
				dataKey: "createdAt"
			},
			{
				alignment: "right",
				label: "",
				width: 50,
				Cell: ({ rowData, rowIndex }: { rowData: AlbumModel; rowIndex: number }) => (
					<MenuDots
						menuId={`moreAction${rowIndex + 1}`}
						options={getOptions(rowData)}
						vertical
						removeBg
						removeshadow
						removeSideMargin
					/>
				),
				loaderTemplate: TableColumnLoaderType.menuDots,
				dataKey: "menu"
			}
		],
		[getCoinPrice, getOptions, totalAlbumsCount]
	);

	const handleOpenCreateAlbum = () => {
		setAlbumCreateOpen(true);
	};

	const filteredAlbums = useMemo(() => albums.filter((_, i) => i < albumsShowPerPage), [albums, albumsShowPerPage]);

	const handleChangeRowsPerPage = useCallback(
		(newPageSize: number) => {
			setAlbumsShowPerPage(newPageSize);
			setPage(1);
		},
		[setAlbumsShowPerPage, setPage]
	);

	const TableBlock = useMemo(
		() => (
			<TableWrapper sizes={{ horizontalTablet: 900 }}>
				<Table
					columns={tableColumns}
					data={filteredAlbums || []}
					loading={isLoading}
					paginated
					totalDataCount={totalAlbumsCount}
					page={page}
					pageSize={albumsShowPerPage}
					onChangePage={setPage}
					onChangePageSize={handleChangeRowsPerPage}
				/>
			</TableWrapper>
		),
		[
			tableColumns,
			isLoading,
			filteredAlbums,
			totalAlbumsCount,
			page,
			albumsShowPerPage,
			setPage,
			handleChangeRowsPerPage
		]
	);

	return (
		<>
			<PageTemplate
				title={"Albums"}
				showReportedLink
				isLoading={isLoading}
				isNoData={!filteredAlbums || !filteredAlbums.length}
				emptyText={"You don’t have any Albums in your community yet."}
				searchPlaceholder={"Search Albums"}
				onSearchUpdate={handleSearch}
				actionText={"Create Album"}
				onCreateClick={handleOpenCreateAlbum}
				actionId={"createAlbum"}
				searchId={"searchAlbum"}
			>
				{TableBlock}
			</PageTemplate>

			{deleteAlbumDetails && (
				<ConfirmDelete
					headline="Album"
					name={deleteAlbumDetails.name}
					onClose={() => setDeleteAlbumDetails()}
					onDelete={async () => {
						await deleteAlbum(deleteAlbumDetails.id);
						getAlbums({ limit: albumsShowPerPage, offset: page, keyword: debouncedSearchText });
					}}
					deleteBtnId="deleteModal"
				/>
			)}

			{trackInfoPopup.open && (
				<CreateTrack
					open
					editableModel={trackInfoPopup.model}
					album={trackInfoPopup.album}
					onClose={e => {
						setTrackInfoPopup({ open: false });
						if (e?.created) {
							setRefresh(refresh + 1);
						}
						setTrackDuration();
					}}
				/>
			)}
			{albumDetailsDialog.open && <AlbumDetailsDialog />}
			{albumDetailsDialog.addTracks && !trackInfoPopup.open && <AddTracksToPlaylist />}
			{(!!albumCreateOpen || !!editAlbumDetails) && <CreateUpdateAlbumDialog open={true} />}
		</>
	);
};

export default ManageAlbums;
