export const formatMoney = (amount: number, currency?: string) =>
	// We use Belgian locale to get the correct decimal separator and to get the currency symbol before the number not after
	// Feel fre to change if needed
	new Intl.NumberFormat("sfb", {
		maximumFractionDigits: 0,
		minimumFractionDigits: 0,
		style: "currency",
		currency: currency || "USD",
		compactDisplay: "short"
	}).format(amount);
