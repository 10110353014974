import React from "react";

import ConfirmActionModal from "shared/Components/ConfirmActionModal";

import { useRoutes, useUser } from "shared/hooks";

import {
	Option,
	OptionDescription,
	OptionInfo,
	OptionRadio,
	OptionRadioWrapper,
	OptionTitle,
	SubmitButton
} from "./styles";

import { SettingsSectionContainer } from "../commonStyles";

enum ActionKind {
	DeleteAccount = "DELETE_ACCOUNT",
	DeactivateAccount = "DEACTIVATE_ACCOUNT"
}

const deactivateConfirmData = {
	title: "Deactivate account",
	text: "Your account will be disabled, and your data will be temporarily hidden from the community. You can reactivate your profile at any time by logging back into Vyoo.",
	confirmButtonText: "Deactivate",
	id: "deactivateConfirmation"
};

const deleteConfirmData = {
	title: "Delete account",
	text: "When you delete your account, you will not be able to recover the content or info you’ve shared on Vyoo. All your data will be permanently deleted.",
	confirmButtonText: "Delete"
};

const initialState = {
	isOpen: false,
	data: {
		title: "",
		text: "",
		confirmButtonText: ""
	}
};

export function DeleteOrDeactivateAccount() {
	const { logout } = useRoutes();

	const { getData: getUserData, deleteAccount, activateAccount } = useUser();
	const { user } = getUserData();

	const [selectedOption, setSelectedValue] = React.useState<ActionKind>(ActionKind.DeactivateAccount);
	const [confirmDialog, setConfirmDialog] = React.useState<{
		isOpen: boolean;
		data: { title: string; text: string; confirmButtonText: string; id?: string };
	}>(initialState);

	const handleConfirmAction = async () => {
		if (selectedOption === ActionKind.DeactivateAccount) {
			await activateAccount("deactivate");
		}

		if (selectedOption === ActionKind.DeleteAccount && user?.userId) {
			await deleteAccount(user!.userId);
		}

		setConfirmDialog(prevState => ({ ...prevState, isOpen: false }));

		logout();
	};

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSelectedValue(event.target.value as ActionKind);
	};

	const handleClickConfirmButton = () => {
		const data = selectedOption === ActionKind.DeactivateAccount ? deactivateConfirmData : deleteConfirmData;
		setConfirmDialog({ isOpen: true, data });
	};

	return (
		<>
			<SettingsSectionContainer>
				<Option>
					<OptionRadioWrapper>
						<OptionRadio
							checked={selectedOption === ActionKind.DeactivateAccount}
							value={ActionKind.DeactivateAccount}
							onChange={handleChange}
						/>
					</OptionRadioWrapper>
					<OptionInfo>
						<OptionTitle>Deactivate account</OptionTitle>
						<OptionDescription>
							Your account will be disabled, and your data will be temporarily hidden from the community. You can
							reactivate your profile at any time by logging back into Vyoo.
						</OptionDescription>
					</OptionInfo>
				</Option>
				<Option>
					<OptionRadioWrapper>
						<OptionRadio
							checked={selectedOption === ActionKind.DeleteAccount}
							value={ActionKind.DeleteAccount}
							onChange={handleChange}
						/>
					</OptionRadioWrapper>
					<OptionInfo>
						<OptionTitle>Delete account</OptionTitle>
						<OptionDescription>
							When you delete your account, you will not be able to recover the content or info you’ve shared on Vyoo.
							All your data will be permanently deleted.
						</OptionDescription>
					</OptionInfo>
				</Option>
				<SubmitButton
					variant="contained"
					onClick={handleClickConfirmButton}
					id={selectedOption === ActionKind.DeactivateAccount ? "deactivate" : ""}
				>
					{selectedOption === ActionKind.DeactivateAccount ? "Deactivate" : "Delete"} account
				</SubmitButton>
			</SettingsSectionContainer>
			<ConfirmActionModal
				open={confirmDialog.isOpen}
				onClose={() => setConfirmDialog(initialState)}
				title={confirmDialog.data.title}
				bodyText={confirmDialog.data.text}
				onConfirm={handleConfirmAction}
				confirmText={confirmDialog.data.confirmButtonText}
				confirmButtonId={confirmDialog.data?.id || ""}
			/>
		</>
	);
}
