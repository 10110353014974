import { Box } from "@material-ui/core";
import styled from "styled-components";

export const ErrorText = styled(Box)`
	font-size: 12px;
	line-height: 1.33;
	margin-top: 4px;
	margin-left: 16px;
	color: #f44336;
`;
