import { Box } from "@material-ui/core";
import styled, { css } from "styled-components";

export const EnableFeatureWrapper = styled(Box)`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: ${({ verticalPadding }) => (verticalPadding ? "10px 16px" : "0 16px")};
	border-radius: 8px;
	border: solid 1px #e4e9f2;
	background-color: #f7f9fc;
	.title-center {
		display: flex;
		align-items: center;
	}
	.side {
		display: flex;
		&.col {
			flex-direction: column;
		}
		&:first-child {
			flex: 1;
		}
		&.center {
			align-items: center;
		}
		flex-shrink: 0;
		${props => props.theme.breakpoints.up("sm")} {
			max-width: 75%;
		}
		svg {
			flex-shrink: 0;
			margin-right: 10px;
			width: 24px;
			height: 24px;
			${({ fill }) =>
				fill &&
				css`
					fill: ${fill};
					&.fill-first-path path:nth-child(1) {
						fill: ${fill};
					}
					&.fill-second-path path:nth-child(2) {
						fill: ${fill};
					}
					use {
						fill: ${fill};
					}
				`}
		}
		.feature-name {
			font-size: 15px;
			font-weight: 600;
			color: #222b45;
		}
		.description {
			font-size: 13px;
			color: #8f9bb3;
			&.mt {
				margin-top: 4px;
			}
		}
		.on-off-indicator {
			margin-right: 4px;
			font-size: 13px;
			color: #8f9bb3;
		}
	}
`;
