import React, { FC, useEffect, useMemo } from "react";

import { useMediaQuery } from "@material-ui/core";

import { Scrollbars } from "react-custom-scrollbars";

import { useLocation } from "react-router-dom";
import styled, { css } from "styled-components";

import { useScrollbar } from "shared/hooks";
import * as appTheme from "theme/default";
import { isRealMobile } from "utils/serviceUtils/helpers";

const BoxWrapper = styled.div`
	overflow-y: auto;

	${({ height }) => css`
		height: ${height ? height : "100vh"};
	`}

	${({ customStyles }) => customStyles}
`;

interface Props {
	children;
	height?: string;
	customStyles?: string;
	shouldScrollToTop?: boolean;
	ref?: HTMLElement;
	scrollbarId?: string;
}

const SmartScroll: FC<Props> = ({ children, height, customStyles, shouldScrollToTop = true, ref, scrollbarId }) => {
	const isMobile = useMediaQuery(appTheme.default.breakpoints.down("sm"));
	const isRealMobileDevice = useMemo(() => isRealMobile.any(), []);

	const location = useLocation();
	const { scrollBarToTop, highIndex } = useScrollbar();

	useEffect(() => {
		shouldScrollToTop && scrollBarToTop();
	}, [location.pathname, shouldScrollToTop, scrollBarToTop]);

	useEffect(() => {
		highIndex();
	}, [highIndex]);

	if (isMobile && isRealMobileDevice)
		return (
			<BoxWrapper height={height} id="boxWrapper" customStyles={customStyles} ref={ref}>
				{children}
			</BoxWrapper>
		);

	return (
		<Scrollbars
			id={scrollbarId || "scrollWrapper"}
			autoHide
			autoHideDuration={200}
			thumbMinSize={10}
			universal
			style={{ height: height ? height : "100%" }}
		>
			{children}
		</Scrollbars>
	);
};

export default SmartScroll;
