import React, { useCallback } from "react";

import { Box } from "@material-ui/core";

import { useConnection, useStory } from "shared/hooks";
import { Button, Text } from "shared/ui-kit";

import { RemoveConnectionDialogWrapper } from "./style";

const RemoveConnectionDialog = () => {
	const { setRemovePersonConnection, getData: getStoryData } = useStory();
	const { removePersonConnection } = getStoryData();

	const { deleteUserConnection } = useConnection();

	const handleRemoveConnection = useCallback(() => {
		if (removePersonConnection) {
			deleteUserConnection(removePersonConnection?.connectionId);
			setRemovePersonConnection();
		}
	}, [deleteUserConnection, removePersonConnection, setRemovePersonConnection]);

	if (!removePersonConnection) {
		return null;
	}

	return (
		<RemoveConnectionDialogWrapper>
			<Box className="dialog">
				<Text variant="h6">Remove from connections</Text>
				<Text variant="body1">
					Are you sure you want to remove {removePersonConnection?.name} from your connections?
				</Text>
				<Box className="footer">
					<Button buttonTheme="ghost" palette="danger" onClick={handleRemoveConnection}>
						Remove
					</Button>
					<Button buttonTheme="ghost" palette="info" onClick={() => setRemovePersonConnection()}>
						Keep
					</Button>
				</Box>
			</Box>
		</RemoveConnectionDialogWrapper>
	);
};

export default RemoveConnectionDialog;
