import { useCallback, useMemo } from "react";

import { useYoutubeStore } from "../contexts";

import { useYoutubeApiService } from "../services";

const useYoutube = () => {
	const service = useYoutubeApiService();

	const store = useYoutubeStore();
	const { setState } = useYoutubeStore();

	const methods = useMemo(
		() => ({
			validateVideoById: async (videoId: string) => {
				setState(ctx => ({ ...ctx, validating: true }));
				const videoInfo = await service.searchVideoById(videoId);
				const item = videoInfo?.items?.find(x => x.id === videoId);
				setState(ctx => ({ ...ctx, validating: false, isValidVideo: !!item?.status?.embeddable }));
			},
			setIsValidVideo: (state: boolean) => setState(ctx => ({ ...ctx, isValidVideo: state }))
		}),
		[service, setState]
	);

	const getData = useCallback(() => {
		return store;
	}, [store]);

	return { ...methods, getData };
};

export default useYoutube;
