import React, { forwardRef } from "react";

import { Pagination, SimpleTable } from "./Components";

import { PaginationWrapper, TableWrapper } from "./style";

export interface TableMethods {
	clearCheckboxes: () => void;
}

export interface TableProps {
	columns: any[];
	data: any[];

	width?: number;
	height?: number;

	headerHeight?: number;
	rowHeight?: number;

	hideHeader?: boolean;

	checkable?: boolean;
	checkableColumnWidth?: number;
	checkableColumnPosition?: "left" | "right";
	isRowCheckable?: (data: any) => boolean;
	hideNonCheckables?: boolean;
	onToggleCheckRow?: ({
		isChecked,
		toggledIndex,
		checkedRows
	}: {
		isChecked: boolean;
		toggledIndex?: number;
		checkedRows?: number[];
	}) => void;

	expandable?: boolean;
	isRowExpandable?: (data: any) => boolean;
	onExpandRow?: (number) => void;

	loading?: boolean;

	paginated?: boolean;
	totalDataCount?: number;
	page?: number;
	pageSize?: number;
	onChangePage?: (newPage: number) => void;
	onChangePageSize?: (newPageSize: number) => void;
}

const TableComponent = forwardRef<TableMethods, TableProps>(
	(
		{
			width,
			height,

			paginated,
			totalDataCount,
			page,
			pageSize,
			onChangePage,
			onChangePageSize,
			loading,

			headerHeight = 48,
			rowHeight = 72,

			...rest
		},
		ref
	) => {
		const customWrapperStyle = width && height ? { width, height } : width ? { width } : height ? { height } : {};

		return (
			<>
				<TableWrapper style={customWrapperStyle}>
					<SimpleTable
						width={width}
						height={height}
						headerHeight={headerHeight}
						rowHeight={rowHeight}
						pageSize={pageSize}
						loading={loading}
						ref={ref}
						{...rest}
					/>
				</TableWrapper>
				{paginated && !!totalDataCount && !!page && !!pageSize && (
					<PaginationWrapper>
						<Pagination
							loading={loading}
							count={totalDataCount}
							page={page}
							pageSize={pageSize}
							onChangePage={onChangePage}
							onChangePageSize={onChangePageSize}
						/>
					</PaginationWrapper>
				)}
			</>
		);
	}
);

export default TableComponent;
