import { useMemo } from "react";

import { useUser } from "shared/hooks";

import { useConnectionApiService } from "../services";

const useConnection = () => {
	const service = useConnectionApiService();

	const { getData: getUserData } = useUser();
	const { user } = getUserData();
	const personaId = user?.profiles[0]?.personaId || -1;

	return useMemo(
		() => ({
			getUsersConnections: async ({
				offset = 1,
				limit = 10,
				keyword
			}: {
				offset?: number;
				limit?: number;
				keyword?: string;
			}) => {
				return await service.getUsersConnections({ personaId, offset, limit, keyword });
			},
			getPendingConnections: async ({ offset = 1, limit = 10 }: { offset?: number; limit?: number }) => {
				return await service.getPendingConnections({ personaId, offset, limit });
			},
			undoMatchPreference: async (id: string) => {
				return service.undoMatchPreference(id);
			},
			getRecommendedConnections: async ({
				offset = 1,
				limit = 10,
				potentialMatchingIds,
				type = "personas"
			}: {
				offset?: number;
				limit?: number;
				potentialMatchingIds: string[];
				type?: string;
			}) => {
				return await service.getRecommendedConnections({ personaId, offset, limit, potentialMatchingIds, type });
			},
			manageConnection: async ({ connectionId, isAccept }: { connectionId: string; isAccept: boolean }) => {
				return await service.manageConnection({ connectionId, isAccept });
			},
			requestConnection: async (connectionId: number) => {
				return await service.requestConnection(connectionId, personaId);
			},
			setMatchIntent: async data => {
				return service.setMatchIntent(data, personaId);
			},
			deleteUserConnection: (connectionId: string) => {
				service.deleteUserConnection(connectionId);
				return service.deleteConnection(connectionId);
			},
			report: async ({
				reporteeId,
				reportType,
				reason
			}: {
				reporteeId: number;
				reportType: string;
				reason?: string;
			}) => {
				return await service.report({
					initiatorId: personaId.toString(),
					reporteeId,
					reportType,
					reason
				});
			}
		}),
		[personaId, service]
	);
};

export default useConnection;
