import { useMemo } from "react";

import { DefaultForumResponseType, ForumPostModel, ForumUserModel } from "types";

import { API_URLS } from "shared/constants";
import useHttpActions from "shared/services/api/core/useHttpActions";
import { BaseConverter, convertCategories } from "shared/services/converters";

import { GetPostType, PostFormModel } from "shared/types";

import { convertPostResponse, convertPostUpdateResponse, convertProducts } from "./converters/PostConverter";

const usePostApiService = () => {
	const actions = useHttpActions();

	return useMemo(
		() => ({
			getPriceTags: async () => {
				return convertProducts(await actions.get(API_URLS.PRODUCT.GET_PRODUCT, { type: "premium", coinBags: false }));
			},
			getCategories: async () => {
				return convertCategories(await actions.get(API_URLS.CATEGORIES.GET_ALL));
			},
			createPost: async data => {
				return BaseConverter.convert<
					{
						code: string;
						payload: {
							basePost: ForumPostModel;
							postData: ForumPostModel;
							topicData: ForumPostModel;
						};
					}[]
				>(await actions.post(API_URLS.TOPIC.CREATE_TOPIC, convertPostResponse(data)));
			},
			updatePost: async data => {
				return BaseConverter.convert<{
					code: string;
					payload: {
						basePost: ForumPostModel;
						post: ForumPostModel[];
						topic: ForumPostModel[];
						editor?: ForumUserModel;
					};
				}>(await actions.post(API_URLS.TOPIC.UPDATE_TOPIC, convertPostUpdateResponse(data)));
			},
			getPost: async (pid: string) => {
				return BaseConverter.convert<GetPostType>(
					await actions.post(API_URLS.TOPIC.GET_TOPIC, {
						payload: {
							offset: 1,
							limit: 1,
							postId: pid
						},
						path: "/v3/posts",
						method: "GET",
						token: null
					})
				);
			},
			findHashtagData: async (title: string) => {
				return BaseConverter.convert<{ tags: { used: number; followed: boolean }[] }>(
					await actions.get(API_URLS.TAGS.TAG_DETAILS(title))
				);
			},
			getHashTags: async (key: string, limit: number, offset: number) => {
				return BaseConverter.convert<{ tags: any[] }>(await actions.get(API_URLS.TAGS.GET_TAGS(key, limit, offset)));
			},
			createHashTags: async (hashtags: string[]) => {
				return BaseConverter.convert<{ success: boolean }>(
					await actions.post(API_URLS.TAGS.CREATE, {
						payload: {
							tags: hashtags,
							createNotifications: true
						},
						path: "/tag",
						method: "POST"
					})
				);
			},
			getPostComments: async ({
				postId,
				offset,
				limit,
				extraLimit
			}: {
				postId: string;
				offset: number;
				limit: number;
				extraLimit?: number;
			}) => {
				return BaseConverter.convert<{
					basePost: ForumPostModel;
					posts: ForumPostModel[];
					nextPosts?: ForumPostModel[];
				}>(
					await actions.post(API_URLS.TOPIC.GET_TOPIC, {
						path: "/v3/posts",
						method: "GET",
						token: null,
						payload: {
							offset: offset,
							limit: limit,
							postId,
							extraLimit
						}
					})
				);
			},
			reactOnPost: async (reaction?: string, postId?: string) => {
				return BaseConverter.convert<DefaultForumResponseType>(
					await actions.post(API_URLS.TOPIC.UPDATE_TOPIC, {
						path: "v2/react",
						method: "POST",
						payload: reaction
							? {
									postId,
									tapped: true,
									reaction,
									createNotifications: true
							  }
							: {
									postId,
									tapped: false,
									createNotifications: true
							  }
					})
				);
			},
			commentPost: async ({ comment, postId, data }: { comment?: string; postId?: string; data?: PostFormModel }) => {
				const payload = data
					? {
							...convertPostResponse(data).payload,
							content: comment,
							toPid: postId,
							createNotifications: true
					  }
					: {
							content: comment,
							toPid: postId,
							createNotifications: true
					  };

				return BaseConverter.convert<{ code: string; payload: ForumPostModel }>(
					await actions.post(API_URLS.TOPIC.UPDATE_TOPIC, {
						path: `v2/topics/${postId}`,
						method: "POST",
						token: null,
						payload
					})
				);
			},
			updateComment: async (comment?: string, postId?: string) => {
				return BaseConverter.convert<{ code: string; payload: ForumPostModel }>(
					await actions.post(API_URLS.TOPIC.UPDATE_TOPIC, {
						path: `v2/topics/${postId}`,
						method: "PUT",
						token: null,
						payload: {
							content: comment,
							createNotifications: true
						}
					})
				);
			}
		}),
		[actions]
	);
};

export default usePostApiService;
