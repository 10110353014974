import { useMemo } from "react";

import axios from "axios";
import config from "config/appConfig";

import { UserIPDetailsType } from "shared/types";

import { BaseConverter } from "./converters";

const useUserIPApiService = () => {
	return useMemo(
		() => ({
			getUserIPDetails: async () => {
				return BaseConverter.convert<UserIPDetailsType>(
					await axios.get<UserIPDetailsType>(
						`${config.GLOBAL_CONSTANTS.IP_API_BASE_URL}/json?key=${config.GLOBAL_CONSTANTS.IP_API_KEY}`
					)
				);
			}
		}),
		[]
	);
};

export default useUserIPApiService;
