import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";

import { Box } from "@material-ui/core";
import { ForumPersonaModel, ForumPostModel } from "types";

import { ManInLotus, NoConnectionsBlock } from "modules/MemberHome/View/Containers/Connections/style";
import { CreateUpdatePost } from "modules/Post/View";
import { Post } from "shared/Organisms";
import { StyledAvatar } from "shared/Organisms/Post/style";
import { useGroup, useOnScreen, usePosts, useUser } from "shared/hooks";

import { MediaModel, ProfileType } from "shared/types";

import { Button } from "shared/ui-kit";

import { ItemLink } from "../../../Contributors/style";
import { MessageText } from "../../../ModelBlock/style";
import PostSkeleton from "../../../PostSkeleton";
import { Hashtags, InfoItem, List } from "../../../index";
import { CreatePostInput, CreatePostWrapper, DiscussionsWrapper } from "../../style";

const Discussions: FC<{ routes: any; slug?: string; totalPosts?: number; eventId?: string }> = ({
	routes,
	slug,
	totalPosts,
	eventId
}) => {
	const { updatePostsList, getGroupPosts, getData: getPostsData, setCreateUpdatePostDialog } = usePosts();
	const { posts, loading, createUpdatePostDialog } = getPostsData();

	const { getData: getUserData, isPrivilegedRole } = useUser();
	const { user } = getUserData();

	const { getGroupTopContrib, getData: getGroupData } = useGroup();
	const { group } = getGroupData();

	const [members, setMembers] = useState<{ users: ForumPersonaModel[]; loading: boolean }>({
		users: [],
		loading: true
	});
	const [offset, setOffset] = useState(1);

	const lastItemRef = useRef<HTMLDivElement>(null);
	const onScreen = useOnScreen(lastItemRef);
	const hasMoreItems = useMemo(() => posts.length < (totalPosts || 0), [posts.length, totalPosts]);

	useEffect(() => {
		if ((group && group.postId) || eventId) {
			getGroupPosts({ limit: 10, offset, postId: eventId || (group?.postId as string), paginate: true });
		}
	}, [eventId, getGroupPosts, group, offset]);

	useEffect(() => {
		if (hasMoreItems && onScreen) {
			setOffset(ctx => ctx + 1);
		}
	}, [hasMoreItems, onScreen]);

	useEffect(() => {
		if (slug) {
			getGroupTopContrib(slug).then(members =>
				setMembers({
					users: members,
					loading: false
				})
			);
		}
	}, [getGroupTopContrib, slug]);

	const getPhoto = useCallback((persona: ForumPersonaModel): string | undefined => {
		return persona?.photos?.length ? persona.photos[0].profilePicture : undefined;
	}, []);

	const handlePostChanged = useCallback(
		async ({ isEdited, postInfo }: { isEdited: boolean; postInfo?: ForumPostModel }) => {
			setCreateUpdatePostDialog({ isOpen: false });
			if (!isEdited && group?.postId) {
				await getGroupPosts({ limit: 10, offset, postId: group.postId, paginate: true });
			}
			if (isEdited && postInfo) {
				updatePostsList({ postInfo, isNew: !isEdited });
			}
		},
		[updatePostsList, setCreateUpdatePostDialog, getGroupPosts, group?.postId, offset]
	);

	const RenderItem = useCallback(
		(item: ForumPersonaModel) => (
			<ItemLink>
				<InfoItem
					avatarUrl={getPhoto(item.persona)}
					title={`${item.persona.firstName} ${item.persona.lastName}`}
					subtitle={`${item.postCount > 500 ? "+500" : item.postCount} Posts`}
				/>
			</ItemLink>
		),
		[getPhoto]
	);

	return (
		<DiscussionsWrapper>
			<DiscussionsWrapper.Discussions>
				{(isPrivilegedRole || group?.roles?.isMember) && !eventId && (
					<CreatePostWrapper className="flex p-4 mb-4">
						<StyledAvatar image={((user?.profiles[0] as ProfileType)?.photos[0] as MediaModel)?.profilePicture || ""} />
						<CreatePostInput className="w-full ml-2 pl-4" onClick={() => setCreateUpdatePostDialog({ isOpen: true })}>
							Write Something
						</CreatePostInput>
					</CreatePostWrapper>
				)}
				{(!loading || offset > 1) && !!posts.length ? (
					posts.map((post, i) => (
						<DiscussionsWrapper.PostWrapper key={post.pid} ref={i + 1 === posts.length ? lastItemRef : null}>
							<Post hideBoard fullMode={true} post={post} />
						</DiscussionsWrapper.PostWrapper>
					))
				) : (
					<NoConnectionsBlock className="with-bg">
						<ManInLotus />
						<MessageText>There are not posts here yet.</MessageText>
						{!eventId && (
							<Button
								buttonTheme="light"
								palette="light"
								size="medium"
								removeSideMargin
								onClick={() => setCreateUpdatePostDialog({ isOpen: true })}
							>
								+ Create New Post
							</Button>
						)}
					</NoConnectionsBlock>
				)}
				{loading && (
					<>
						<DiscussionsWrapper.PostWrapper>
							<PostSkeleton />
						</DiscussionsWrapper.PostWrapper>
						<DiscussionsWrapper.PostWrapper>
							<PostSkeleton />
						</DiscussionsWrapper.PostWrapper>
					</>
				)}
			</DiscussionsWrapper.Discussions>
			<DiscussionsWrapper.RightPanel>
				{slug && (
					<Box className="mb-4">
						<List
							title={"Top Contributors"}
							items={members.users || []}
							seeAllLink={`${routes?.member.contributors.getPath()}?slug=${slug}`}
							renderItem={RenderItem}
							loading={members.loading}
							preview={!members.loading}
						/>
					</Box>
				)}
				<Hashtags hashtagsUrl={routes?.member.hashtags.getPath()} />
			</DiscussionsWrapper.RightPanel>
			{createUpdatePostDialog?.isOpen && !eventId && (
				<CreateUpdatePost
					onCreateUpdatePost={handlePostChanged}
					pid={createUpdatePostDialog?.editablePostPid}
					groupName={group?.name}
					groupId={group?.postId}
				/>
			)}
		</DiscussionsWrapper>
	);
};

export default Discussions;
