import styled from "styled-components";

import { Box, Button, Input, Text } from "shared/ui-kit";

export const Wrapper = styled(Box)`
	display: flex;
	flex-direction: row;
	flex: 1;
	justify-content: flex-end;
	margin-top: 30px;
	${props => props.theme.breakpoints.down("sm")} {
		flex-direction: column;
		width: 100%;
	}
`;

export const StyledText = styled(Text)`
	font-size: 15px;
	font-weight: 600;
	margin-bottom: 12px;
`;

export const StyledButton = styled(Button)`
	height: 48px;
	flex-shrink: 0;
	width: 104px;
	${({ WithTopMargin, WithRightMargin, WithLeftMargin }) =>
		`margin: ${WithTopMargin ? 16 : 0}px ${WithRightMargin ? 9 : 0}px 0px ${WithLeftMargin ? 20 : 0}px;`};
`;

export const StyledInput = styled(Input)`
	flex: 1;
	margin-bottom: 12px;
`;
