import React from "react";

import { JoinPopup as CommunityJoinPopup } from "shared/Components";

import { useMarketing } from "shared/hooks";
interface JoinPopupProps {}

const JoinPopup: React.FC<JoinPopupProps> = () => {
	const { setShowJoinPopup, getData: getMarketingData } = useMarketing();
	const { workspace, showJoinPopup } = getMarketingData();

	return (
		<CommunityJoinPopup
			isMarketing
			workspaceName={workspace?.name}
			showJoinPopup={showJoinPopup}
			setShowJoinPopup={setShowJoinPopup}
		/>
	);
};

export default JoinPopup;
