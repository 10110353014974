import styled from "styled-components";

import { Box } from "shared/ui-kit";

export const SocialAccountsWrapper = styled(Box)`
	padding: 12px 20px;
`;

export const InputWrapper = styled(Box)`
	margin-bottom: 12px;
`;
