import { ButtonBase } from "@material-ui/core";
import styled from "styled-components";

import { Box, Input, Text } from "shared/ui-kit";

export const SideBar = styled(Box)`
	display: flex;
	flex-direction: column;

	width: 320px;
	height: 100%;

	color: white;

	background: #1f1f1f;

	border-radius: 12px;

	transition: right 0.25s ease-in-out;

	position: absolute;
	top: 0;
	right: -110%;

	&.show {
		right: 0;
	}
`;

SideBar.Header = styled(Box)`
	flex: 0 1 64px;

	display: flex;
	justify-content: space-between;
	align-items: center;

	position: relative;

	padding: 16px;

	border-bottom: 1px solid #000000;
`;

SideBar.HeaderTitle = styled(Text)`
	font-size: 22px;
	font-weight: bold;
	line-height: 1.45;
	color: #fff;
`;

SideBar.HeaderActions = styled(Box)`
	display: flex;
	align-items: center;
	justify-content: flex-end;
`;

SideBar.HeaderItemWrapper = styled(Box)`
	&:not(:last-child) {
		margin-right: 8px;
	}
`;

SideBar.HeaderItem = styled(ButtonBase)`
	width: 32px;
	height: 32px;

	border-radius: 8px;

	overflow: hidden;

	position: relative;

	background-color: rgba(255, 255, 255, 0.04);

	&:hover {
		background-color: #444;
	}
`;

SideBar.HeaderSearch = styled(Box)`
	position: absolute;
	top: 16px;
	right: 56px;

	width: 32px;
	height: 32px;

	border-radius: 8px;

	overflow: hidden;

	cursor: pointer;

	background-color: rgba(255, 255, 255, 0.04);

	transition: background-color 0.15s linear, width 0.15s linear;

	&:hover {
		background-color: #444;
	}

	&.active {
		background-color: #303030;
		cursor: auto;

		width: calc(100% - 72px);
		z-index: 5;
	}
`;

SideBar.HeaderSearchIconWrapper = styled(Box)`
	width: 32px;
	height: 100%;

	position: absolute;
	top: 0;
	right: 0;

	display: flex;
	justify-content: center;
	align-items: center;

	transition: opacity 0.15s linear;

	&.show {
		opacity: 1;
		pointer-events: auto;
		cursor: pointer;

		z-index: 2;
	}

	&.hide {
		opacity: 0;
		pointer-events: none;

		z-index: 1;
	}
`;

SideBar.SearchInput = styled(Input)`
	width: calc(100% - 32px);
	height: 100%;

	.MuiTextField-root {
		height: 100%;

		.MuiInputBase-root {
			height: 100%;

			input {
				font-size: 12px;
				line-height: 1.33;
				color: #fff;

				padding: 0 16px;
				height: 100%;

				&::placeholder {
					color: #fff;
				}
			}

			fieldset {
				display: none;
			}
		}
	}
`;

SideBar.Content = styled(Box)`
	flex: 1 1 calc(100% - 136px);

	padding: 16px;

	overflow-y: hidden;
`;

SideBar.ContentTitle = styled(Text)`
	font-size: 12px;
	line-height: 1.33;
	color: #fff;
`;

SideBar.ListWrapper = styled(Box)`
	padding: 13px 0 23px 0;
`;

SideBar.Bottom = styled(Box)`
	flex: 0 1 72px;

	padding: 16px;

	border-top: 1px solid #000000;

	display: flex;
	flex-direction: row;

	position: relative;

	z-index: 2;
`;
