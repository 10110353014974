import React, { useCallback, useEffect, useMemo, useRef } from "react";

import MoreVertIcon from "@material-ui/icons/MoreVert";
import Skeleton from "@material-ui/lab/Skeleton";

import { Scrollbars } from "react-custom-scrollbars";
import { useHistory } from "react-router-dom";

import { ReactComponent as IconPinFilled } from "assets/icons/pin-angle-fill.svg";
import { useFirebase, useMessaging } from "modules/Messaging/Data";
import { InboxOptions } from "modules/Messaging/Data/contexts/MessagingContext/MessagingContext";

import { ChatMessage, InboxItem } from "modules/Messaging/Data/types";
import { INBOX_FILTERS } from "modules/Messaging/constants";

import MenuDots, { TransformOriginOpts } from "shared/Components/MenuDots";
import { useCommunity, useMemberRoutes, usePopulateWithAds, useUser } from "shared/hooks";
import { AdWrapper } from "shared/styles";
import { Box, Tooltip } from "shared/ui-kit";
import { AdZone } from "utils/ads/getRandomAd";
import { getGroupMemberAvatar } from "utils/getGroupMemberAvatar";

import {
	FavoriteIconWrapper,
	FloatingIconButton,
	GroupdAvatarContainer,
	InboxItemDate,
	InboxItemDescription,
	InboxItemInfo,
	InboxItemTitle,
	InboxItemWrapper,
	InboxList,
	Notification,
	SkeletonInfoWrapper,
	SkeletonRowWrapper,
	StyledAvatar,
	StyledGroupAvatar
} from "./style";

export enum LastMessageActions {
	create = "create",
	update = "update",
	remove = "remove"
}

interface InboxProps {
	keyword: string;
	widget: boolean;
	messagingPageUrl?: string;
	availableHeight?: number;
	offset: number;
	setOffset: React.Dispatch<React.SetStateAction<number>>;
}

const Inbox: React.FC<InboxProps> = ({ keyword, widget, messagingPageUrl, availableHeight, offset, setOffset }) => {
	const history = useHistory();
	const {
		getInbox,
		setInbox,
		deleteChat,
		deleteChatByConnectionId,
		updateInboxLastMessage,
		pinChat,
		archiveChat,
		readConversation,
		getData: getMessagingData,
		setCurrentInboxTab
	} = useMessaging();

	const { inbox, inboxType, currentChat, loadingInbox, searchingInbox, allowLoadMoreInboxes, currentInboxTab } =
		getMessagingData();

	const { getMemberRoutesData } = useMemberRoutes();
	const { routes } = getMemberRoutesData();

	const { communityColors } = useCommunity();

	const { setCurrentChat, clearChat, listenForIboxMessages } = useFirebase();

	const { getActiveProfile, getData: getUserData } = useUser();
	const { user, isMemberView } = getUserData();

	const _subscribedIds = useRef<string[]>([]);

	const activeProfile = useMemo(() => getActiveProfile(user), [getActiveProfile, user]);

	const handleLoad = useCallback(
		async ({ offset, keyword }) => {
			await getInbox({
				offset,
				personaId: activeProfile?.personaId,
				limit: 20,
				keyword
			});
			setCurrentInboxTab(InboxOptions.ALL_CHATS);
		},
		[getInbox, activeProfile?.personaId, setCurrentInboxTab]
	);

	useEffect(() => {
		!currentChat && inbox.length && setCurrentChat(inbox[0]);
	}, [inbox, currentChat, setCurrentChat]);

	useEffect(() => {
		handleLoad({ offset: keyword ? 1 : offset, keyword });
	}, [handleLoad, keyword, offset]);

	const inboxList: (InboxItem | undefined)[] = useMemo(() => {
		let msgs: (InboxItem | undefined)[] = [];
		if (inboxType === INBOX_FILTERS.RECENT) {
			msgs = inbox;
		}
		if (inboxType === INBOX_FILTERS.UNREAD) {
			clearChat();
			msgs = inbox.filter((item: InboxItem) => item.unReadCount);
		}
		if ((loadingInbox || searchingInbox) && offset > 1) {
			msgs = msgs.concat([...Array.from(Array(10).keys()).map(() => undefined)]);
		}
		return msgs;
	}, [clearChat, inbox, inboxType, offset, loadingInbox, searchingInbox]);

	const inboxListWithAds = usePopulateWithAds({
		list: inboxList,
		zone: AdZone.chatZone,
		every: 10,
		skip: 5
	});

	useEffect(() => {
		const notSubscribed = inboxList.filter(
			x =>
				x &&
				typeof x !== "string" &&
				(x._id || (x.chatUserId && !_subscribedIds.current.includes(x._id || x.chatUserId)))
		) as InboxItem[];
		if (notSubscribed.length) {
			notSubscribed.forEach(chat => {
				const startAt = -new Date().getTime();
				listenForIboxMessages(startAt, chat, (message: ChatMessage, action: LastMessageActions) => {
					updateInboxLastMessage(chat, message, action);
				});
			});
			_subscribedIds.current = notSubscribed.map(x => (x._id ? x._id : `${x.chatUserId}`));
		}
	}, [activeProfile?.chatUserId, inboxList, listenForIboxMessages, updateInboxLastMessage]);

	const formattedTime = useCallback((timeStr?: string): string => {
		if (!timeStr) return "";

		const creationDate = new Date(timeStr);
		const now = new Date();

		return now.getFullYear() !== creationDate.getFullYear()
			? creationDate.toLocaleString("en-US", { year: "numeric", month: "long" })
			: now.getDay() === creationDate.getDay()
			? creationDate.toLocaleTimeString("en-US", { hour: "2-digit", minute: "2-digit" })
			: creationDate.toLocaleString("en-US", { month: "long", day: "numeric" });
	}, []);

	const isActiveItem = useCallback(
		(item: InboxItem) => {
			return (
				!widget &&
				((currentChat?.chatUserId && item?.chatUserId === currentChat?.chatUserId) ||
					(currentChat?._id && item?._id === currentChat?._id))
			);
		},
		[currentChat, widget]
	);

	const getChatName = useCallback((item: InboxItem) => {
		return item.firstName || item.name
			? `${item.firstName || item.name}${item?.lastName ? " " + item.lastName : ""}`
			: item.members
					?.filter(item => !item.isOwner)
					.map(u => u.firstName || u.lastName)
					.join();
	}, []);

	const skeletonRow = useCallback(
		(index: number, style?: any) => (
			<SkeletonRowWrapper key={index} style={style || {}}>
				<Skeleton variant="rect" width={32} height={32} />
				<SkeletonInfoWrapper>
					<Skeleton width="31%" height={20} />
					<Skeleton width="65%" height={20} />
				</SkeletonInfoWrapper>
			</SkeletonRowWrapper>
		),
		[]
	);

	const markAsRead = useCallback(
		(id: string) => {
			setInbox(inbox.map(chat => (chat._id === id ? { ...chat, unReadCount: 0 } : chat)));
		},
		[inbox, setInbox]
	);

	const InboxListItem = React.useCallback(
		({ index, style }) => {
			const currentItem = isMemberView ? inboxListWithAds[index] : inboxList[index];

			if (currentItem) {
				if (typeof currentItem === "string") {
					return (
						<InboxItemWrapper style={style}>
							<AdWrapper dangerouslySetInnerHTML={{ __html: currentItem }} />
						</InboxItemWrapper>
					);
				}

				const chatName = getChatName(currentItem as InboxItem);

				const opts = [
					// { name: "Mute notifications", value: 3, comp: "", onClick: () => {} },
					{
						name: "Delete chat",
						value: 4,
						onClick: () => {
							currentItem._id
								? deleteChat(`${currentItem._id}`)
								: deleteChatByConnectionId(`${currentItem.connectionId}`);
						}
					}
				];

				if (currentItem.connectionId) {
					if (!currentItem.unReadCount) {
						opts.unshift({
							name: "Mark as unread",
							value: 1,
							onClick: async () => {
								currentItem.connectionId && (await readConversation(currentItem.connectionId, true));
								handleLoad({ offset: keyword ? 1 : offset, keyword });
							}
						});
					}

					const isArchivedChats = currentInboxTab === InboxOptions.ARCHIVED_CHATS;

					opts.unshift({
						name: `${isArchivedChats ? "Unarchive" : "Archive"} chat`,
						value: 2,
						onClick: async () => {
							if (currentItem.connectionId) {
								await archiveChat(currentItem.connectionId || "", !isArchivedChats);
								handleLoad({ offset: keyword ? 1 : offset, keyword });
							}
						}
					});

					opts.unshift({
						name: currentItem.pinned ? "Unpin Chat" : "Pin Chat",
						value: 3,
						onClick: async () => {
							if (currentItem.connectionId) {
								await pinChat(currentItem.connectionId, !currentItem.pinned, activeProfile?.personaId);
								handleLoad({ offset: keyword ? 1 : offset, keyword });
							}
						}
					});
				}

				return (
					<InboxItemWrapper
						color={communityColors.primary}
						className={`${isMemberView && "memberChat"} ${isActiveItem(currentItem) && isMemberView && "active-chat"}`}
						key={currentItem.chatUserId || currentItem._id}
						style={style}
					>
						<InboxItemWrapper.Content
							color={communityColors.btn}
							onClick={() => {
								history.push(isMemberView || !messagingPageUrl ? routes?.member.messaging.getPath() : messagingPageUrl);
								setCurrentChat(currentItem, () => {
									markAsRead(currentItem._id || "");
									(currentItem as InboxItem).unReadCount = 0;
								});
							}}
							className={`relative ${isActiveItem(currentItem) && "active"} ${currentItem.unReadCount && "unread"} ${
								isMemberView && "memberChat"
							}`}
						>
							{!widget && (
								<FloatingIconButton
									className="more"
									onClick={e => {
										e.stopPropagation();
									}}
								>
									<MenuDots
										vertical
										memberView={isMemberView}
										customImage={
											<MoreVertIcon
												htmlColor="#8f9bb3"
												height={24}
												width={24}
												aria-controls="long-menu"
												aria-haspopup="true"
												aria-label="more"
											/>
										}
										transformOrigin={TransformOriginOpts.TOP_RIGHT}
										options={opts}
									/>
								</FloatingIconButton>
							)}
							{currentItem.pinned && (
								<FavoriteIconWrapper className={isMemberView && "memberView"}>
									<Tooltip text="Pin Chat">
										<IconPinFilled />
									</Tooltip>
								</FavoriteIconWrapper>
							)}
							{currentItem.type === "group" ? (
								<GroupdAvatarContainer
									className={isMemberView && "member-margins"}
									overlap="circle"
									anchorOrigin={{
										vertical: "bottom",
										horizontal: "right"
									}}
									badgeContent={
										<StyledGroupAvatar
											title={chatName}
											image={getGroupMemberAvatar(1, currentItem)}
											className={isMemberView && "large-avatar"}
										/>
									}
								>
									<StyledGroupAvatar
										title={chatName}
										image={getGroupMemberAvatar(0, currentItem)}
										className={isMemberView && "large-avatar"}
									/>
								</GroupdAvatarContainer>
							) : (
								<StyledAvatar
									title={chatName}
									image={currentItem.photo?.url || currentItem.profilePhoto}
									className={isMemberView && "memberView"}
								/>
							)}
							<InboxItemInfo>
								<Box>
									<InboxItemTitle>{chatName}</InboxItemTitle>
									<InboxItemDate>
										{formattedTime(currentItem.updatedAt)}
										{/* 
											TODO: Implement when we have seen functionality
											https://vyoo-me.atlassian.net/browse/VM-5531
										*/}
										{/* {!!currentItem.unReadCount ? (
											isMemberView ? (
												<InboxItemDate.ReadAmount className={currentItem.unReadCount > 99 && "three-digits"}>
													{currentItem.unReadCount > 99 ? "99+" : currentItem.unReadCount}
												</InboxItemDate.ReadAmount>
											) : (
												<Notification />
											)
										) : undefined} */}

										{!!currentItem.unReadCount && <Notification />}
									</InboxItemDate>
								</Box>
								<Box>
									<InboxItemDescription>
										{currentItem.lastMessage.length > 40
											? currentItem.lastMessage.slice(0, 40) + "..."
											: currentItem.lastMessage}
									</InboxItemDescription>
								</Box>
							</InboxItemInfo>
						</InboxItemWrapper.Content>
					</InboxItemWrapper>
				);
			}

			return <>{skeletonRow(index, style)}</>;
		},
		[
			activeProfile?.personaId,
			archiveChat,
			currentInboxTab,
			deleteChat,
			deleteChatByConnectionId,
			formattedTime,
			getChatName,
			handleLoad,
			history,
			inboxListWithAds,
			inboxList,
			isActiveItem,
			isMemberView,
			keyword,
			markAsRead,
			messagingPageUrl,
			offset,
			pinChat,
			readConversation,
			setCurrentChat,
			skeletonRow,
			communityColors,
			widget,
			routes?.member.messaging
		]
	);

	return (loadingInbox || searchingInbox) && offset === 1 ? (
		<>
			{[...Array.from(Array(10).keys())].map((i, index) => (
				<>{skeletonRow(index)}</>
			))}
		</>
	) : (
		<Box
			onScroll={(e: any) => {
				if (
					e.target?.scrollHeight - e.target?.scrollTop === e.target?.clientHeight &&
					!loadingInbox &&
					!searchingInbox &&
					allowLoadMoreInboxes
				) {
					setOffset(offset + 1);
				}
			}}
		>
			<InboxList
				outerElementType={Scrollbars}
				height={widget ? 500 : availableHeight || 720}
				itemCount={inboxList.length}
				itemSize={72}
			>
				{InboxListItem}
			</InboxList>
		</Box>
	);
};
export default Inbox;
