import React, { useCallback, useEffect, useMemo, useState } from "react";

import { Chip, InputAdornment, TextField } from "@material-ui/core";
import { debounce } from "@material-ui/core/utils";

import { DateTime } from "luxon";

import styled, { css } from "styled-components";

import { EventType } from "types";

import { ReactComponent as SearchIcon } from "assets/icons/iconSearch.svg";
import { FilterBlock, FilterOptionType } from "modules/MemberHome/View/Components";
import { useEvent, useUser } from "shared/hooks";
import { EventModel } from "shared/types";
import { Box } from "shared/ui-kit";

import EventItem from "./EventItem";

import SelectItemTemplate from "../Blocks/SelectItemTemplate";
import { EmptyBody, ScrollableBlock } from "../index";

const FilterWrapper = styled(Box)`
	margin: 16px 0;
	display: flex;
	flex-direction: row;
	align-content: center;
	justify-content: flex-start;

	${({ inline }) =>
		!inline &&
		css`
			div,
			button :not(:first-child) {
				margin-left: 8px;
			}

			${props => props.theme.breakpoints.down("sm")} {
				display: none;
			}
		`}
`;

const CustomChip = styled(Chip)`
	height: 36px;
	margin-top: 4px;
`;

const ContentWrapper = styled(Box)`
	height: ${({ isMemberView }) => (isMemberView ? "340px" : "50vh")};
`;

const EventItemsWrapper = styled(Box)`
	${props => props.theme.breakpoints.down("sm")} {
		display: flex;
		flex-direction: column;
		margin-top: 15px;
	}
`;

const LoaderBlock = () => (
	<>
		<SelectItemTemplate loading />
		<SelectItemTemplate loading />
		<SelectItemTemplate loading />
	</>
);

interface Props {
	onSelect: (g: EventModel) => void;
}

const SelectEventBody: React.FC<Props> = ({ onSelect }) => {
	const { getPaginatedEvents, getData: getEventData } = useEvent();
	const { filteredEvents, loading, eventCount } = getEventData();

	const { getData: getUserData } = useUser();
	const { isMemberView } = getUserData();

	const [filter, setFilter] = useState({
		keyword: "",
		eventSchedule: EventType.All,
		isMyEvents: false,
		page: 1,
		limit: 10,
		concatEvents: true,
		startDate: DateTime.now().toISODate(),
		endDate: DateTime.now().plus({ years: 10 }).toFormat("yyyy-LL-dd")
	});

	useEffect(() => {
		getPaginatedEvents(filter);
	}, [getPaginatedEvents, filter]);

	const handleSearchChange = useCallback(
		e => {
			const {
				target: { name: inputName, value }
			} = e;

			let v = value;

			if (inputName === "isMyEvents") {
				v = !value;
			}

			debounce(() => setFilter({ ...filter, [inputName]: v }), 500)();
		},
		[filter]
	);

	// const sortOptions = [
	// 	{ label: "Relevance", value: "relevance" },
	// 	{ label: "Distance", value: "distance" }
	// ];

	const filterOptions: FilterOptionType[] = useMemo(
		() => [
			{
				inlineOptions: true,
				onClick: () =>
					handleSearchChange({
						target: { name: "isMyEvents", value: false }
					}),
				listOptions: [
					{
						label: "My Events",
						value: "MY_EVENTS"
					}
				]
			}
		],
		[handleSearchChange]
	);

	return (
		<>
			<TextField
				defaultValue={filter.keyword}
				onChange={handleSearchChange}
				name="keyword"
				placeholder="Search"
				variant="outlined"
				className="search-wrapper"
				InputProps={{
					startAdornment: (
						<InputAdornment position="start">
							<SearchIcon />
						</InputAdornment>
					)
				}}
			/>
			{isMemberView ? (
				<FilterWrapper inline>
					<FilterBlock inline options={filterOptions} />
				</FilterWrapper>
			) : (
				<FilterWrapper className="filters-wrapper">
					{/*<CustomSelect name="sortBy" value={sortBy} onChange={handleSearchChange} variant="outlined">*/}
					{/*	<MenuItem className="menuItem" value="placeholder" disabled>*/}
					{/*		<Text>Sort by</Text>*/}
					{/*	</MenuItem>*/}
					{/*	{sortOptions.map((i, index) => (*/}
					{/*		<MenuItem key={index} className="menuItem" value={i.value}>*/}
					{/*			<Text>{i.label}</Text>*/}
					{/*		</MenuItem>*/}
					{/*	))}*/}
					{/*</CustomSelect>*/}
					<CustomChip
						variant={filter.isMyEvents ? "default" : "outlined"}
						label="My Events"
						onClick={() => handleSearchChange({ target: { name: "isMyEvents", value: filter.isMyEvents } })}
					/>
				</FilterWrapper>
			)}
			<ContentWrapper isMemberView={isMemberView}>
				{filteredEvents?.length ? (
					<ScrollableBlock
						onEndScroll={() =>
							!loading && (filteredEvents?.length || 0) < eventCount && setFilter(x => ({ ...x, page: x.page + 1 }))
						}
					>
						<EventItemsWrapper>
							{filteredEvents.map(e => (
								<EventItem key={e.eventId} {...e} onSelect={() => onSelect(e)} />
							))}
							{loading && <LoaderBlock />}
						</EventItemsWrapper>
					</ScrollableBlock>
				) : (
					<>{loading ? <LoaderBlock /> : <EmptyBody>Events</EmptyBody>}</>
				)}
			</ContentWrapper>
		</>
	);
};

export default SelectEventBody;
