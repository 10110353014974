import React, { FC, useCallback, useEffect, useMemo, useState } from "react";

import { Box } from "@material-ui/core";
import { DateTime } from "luxon";
import { Controller, useForm } from "react-hook-form";

import { useHistory } from "react-router-dom";

import { ReactComponent as OfficeChair } from "assets/icons/chair-office.svg";
import { InputWrapper } from "modules/Manage/View/Containers/style";
import { MemberDialog, SelectInput } from "modules/MemberHome/View/shared";

import { MemberDialogContentWrapper } from "modules/MemberHome/View/shared/style";

import { useFirebase, useMessaging } from "modules/Messaging/Data";
import { PlaceholderImage } from "shared/Components";
import { useCarpool, useCommunity, useMemberRoutes, useUser } from "shared/hooks";

import { MediaModel, PlaceholderImageType } from "shared/types";
import { CarpoolMessage } from "shared/types/CarpoolTypes";
import { EventRequests, OfferRequest } from "shared/types/EventModel";
import { Icon, Text } from "shared/ui-kit";

import { formatCount } from "utils/serviceUtils/helpers";

import { EventDetails, IconWrapper, SecondaryText } from "../../Components/EventDetails/styles";
import CarpoolMap from "../../Components/GoogleMap/CarpoolMap";

interface BookRequestDialogProps {
	open: boolean;
	onClose: () => void;
	eventDetail?: EventRequests;
	request?: OfferRequest;
	isCarpoolPage?: boolean;
	editDetails?: {
		personaDocId: string;
		seatsBooked: number;
	};
}

const BookRequestDialog: FC<BookRequestDialogProps> = ({
	open,
	onClose,
	eventDetail,
	request,
	isCarpoolPage,
	editDetails
}) => {
	const { communityColors } = useCommunity();
	const { bookASeatCarpoolRequest, updateBookingCarpoolRequest } = useCarpool();
	const { getData: getUserData } = useUser();
	const { user } = getUserData();
	const history = useHistory();

	const { getMemberRoutesData } = useMemberRoutes();
	const { routes } = getMemberRoutesData();

	const { createConnection } = useMessaging();

	const { onSend } = useFirebase();

	const {
		control,
		handleSubmit,
		watch,
		reset,
		formState: { isDirty, errors, isValid }
	} = useForm({ mode: "onChange" });
	const { seat } = watch();

	useEffect(() => {
		editDetails?.seatsBooked && reset({ seat: { label: editDetails.seatsBooked, value: editDetails.seatsBooked } });
	}, [editDetails, reset]);

	const zone = useMemo(() => Intl.DateTimeFormat().resolvedOptions().timeZone, []);

	const formatDate = eventDateAndTime => {
		return DateTime.fromISO(eventDateAndTime || "", { zone }).toLocaleString({
			month: "short",
			day: "2-digit",
			hour: "2-digit",
			minute: "2-digit"
		});
	};

	const sendCarpoolMessage = useCallback(
		async (request, carpool) => {
			const chatResponse = await createConnection(request?.creator.personaId);

			if (chatResponse) {
				onSend({
					messages: [
						{
							text: "",
							user: {
								_id: user?.profiles[0].chatUserId,
								name: carpool.name,
								avatar: (user?.profiles[0].photos[0] as MediaModel).profilePicture
							},
							carpool
						}
					],
					chat: chatResponse
				});
				history.push(`${routes?.member.messaging.getPath()}?messageTo=${request.creator.personaId}`);
			}
		},
		[history, user?.profiles, onSend, createConnection, routes?.member]
	);

	const onSubmit = useCallback(
		async data => {
			if (eventDetail?._id && request?._id && user?.personaDocId && request?.creator) {
				const carpool: CarpoolMessage = {
					type: "request",
					name: user?.profiles[0].firstName,
					title: eventDetail?.name,
					quantity: Number(data.seat.value),
					seats: request?.seats,
					seatsLeft: request?.seatsLeft,
					eventDate: eventDetail?.eventDateAndTime,
					notes: request?.description,
					address: request?.location?.address,
					latitude: request?.location?.location?.lat,
					longitude: request?.location?.location?.lon,
					notifyToken: request?.creator?.notifyToken,
					isUpdated: !!editDetails
				};

				if (editDetails?.seatsBooked) {
					const { success } = await updateBookingCarpoolRequest(
						request._id,
						eventDetail._id,
						Number(data.seat.value),
						user?.personaDocId,
						onClose,
						isCarpoolPage
					);

					if (success && user?.personaDocId !== request?.creator._id) {
						sendCarpoolMessage(request, carpool);
					}
				} else if (!editDetails) {
					const { success } = await bookASeatCarpoolRequest(
						request._id,
						eventDetail._id,
						Number(data.seat.value),
						user?.personaDocId,
						onClose,
						isCarpoolPage
					);

					if (success && user?.personaDocId !== request?.creator._id) {
						sendCarpoolMessage(request, carpool);
					}
				}
			}
		},
		[
			bookASeatCarpoolRequest,
			eventDetail,
			user?.personaDocId,
			user?.profiles,
			onClose,
			isCarpoolPage,
			request,
			updateBookingCarpoolRequest,
			sendCarpoolMessage,
			editDetails
		]
	);

	const availableSeats = useMemo(
		() => (request?.seatsLeft || 0) + (editDetails?.seatsBooked || 0),
		[request?.seatsLeft, editDetails?.seatsBooked]
	);

	const allSeats = useMemo(
		() => [...Array.from(Array(availableSeats).keys())].map((_, i) => ({ value: `${i + 1}`, label: `${i + 1}` })),
		[availableSeats]
	);

	const [seats, setSeats] = useState<{ value: string; label: string }[]>(allSeats);

	const searchFromSeats = useCallback(
		(value: string) => {
			const search: { value: string; label: string }[] = allSeats;
			const filteredSeats = search.filter(s => Number(s.value) >= Number(value));
			setSeats(filteredSeats);
		},
		[setSeats, allSeats]
	);

	const customHandleSubmit = useCallback(
		e => {
			e?.preventDefault && e.preventDefault();
			e?.stopPropagation && e.stopPropagation();
			handleSubmit(onSubmit)();
		},
		[handleSubmit, onSubmit]
	);

	return (
		<>
			<MemberDialog
				customWidth={512}
				title="Book a Ride"
				open={open}
				onClose={onClose}
				confirmLeave={isDirty}
				modalHeight={600}
				footerPrimary={{
					text: editDetails?.seatsBooked
						? "Update Booking"
						: `Book ${typeof seat === "object" ? formatCount(Number(seat.value), "Seat") : "Seat"}`,
					disabled: !isDirty || !isValid || typeof seat !== "object",
					loading: false,
					onClick: handleSubmit(onSubmit)
				}}
				isXsFooterFlex
				footerLeftContent={
					<SecondaryText className="pl-2 w-3/4">
						you&apos;ll be connected with the requester to discuss carpool details.
					</SecondaryText>
				}
			>
				<form onSubmit={customHandleSubmit}>
					<MemberDialogContentWrapper className="pv-20">
						<Box className="flex mb-6">
							<Box className="w-10 h-10 overflow-hidden rounded-md">
								{!!eventDetail?.eventImages?.length ? (
									<img src={eventDetail!.eventImages[0]} alt={eventDetail!.name} className="w-10 h-10" />
								) : (
									<PlaceholderImage width={120} height={60} type={PlaceholderImageType.EVENT_DETAILS} />
								)}
							</Box>
							<Box className="ml-4">
								<p className="grid">
									<Text className="font-semibold truncate">{eventDetail?.name}</Text>
									<SecondaryText className="">{eventDetail?.location?.name}</SecondaryText>
								</p>
							</Box>
						</Box>
						<EventDetails.EventInfoRow className="w-full">
							<IconWrapper>
								<Icon name="clock" fill={communityColors.primary} />
							</IconWrapper>
							<Text>{formatDate(eventDetail?.eventDateAndTime)}</Text>
						</EventDetails.EventInfoRow>
						<Box className="flex mb-6">
							<Box className="w-10 h-10 overflow-hidden rounded-md">
								{!!request?.creator.photos.length ? (
									<img
										src={request?.creator.photos[0].profilePicture}
										alt={request?.creator.firstName}
										className="w-10 h-10"
									/>
								) : (
									<PlaceholderImage width={120} height={60} type={PlaceholderImageType.PROFILE_IMAGE} />
								)}
							</Box>
							<Box className="ml-4">
								<p className="grid">
									<Text className="font-semibold truncate">{`${request?.creator.firstName} ${request?.creator.lastName}`}</Text>
									<SecondaryText className="">{request?.location.name}</SecondaryText>
								</p>
							</Box>
						</Box>
						<Box className="flex">
							<EventDetails.EventInfoRow className="w-full">
								<IconWrapper>
									<OfficeChair fill={communityColors.primary} />
								</IconWrapper>
								<Text>{formatCount(availableSeats, "Seat")} Available</Text>
							</EventDetails.EventInfoRow>
							<Box className="flex flex-col items-end w-5/12">
								<Controller
									name="seat"
									control={control}
									rules={{
										required: "Seat is required",
										max: {
											value: availableSeats,
											message: `Seat can't be more than ${availableSeats}`
										}
									}}
									render={({ onChange, value }) => (
										<InputWrapper className="w-28" mb={6}>
											<SelectInput
												name="seat"
												onChange={onChange}
												loadSuggestions={searchFromSeats}
												value={value?.label || value || null}
												maxHeight={150}
												options={seats}
												type="number"
												autoCloseWhenNoOption
												numbersOnly
												className="removeArrows"
											/>
										</InputWrapper>
									)}
								/>
								<SecondaryText danger={errors?.seat?.message}>
									{formatCount(availableSeats, "seat")} available
								</SecondaryText>
							</Box>
						</Box>
						{eventDetail && eventDetail?.location?.location && (
							<>
								<Text className="font-semibold mb-2">View on Map</Text>
								<CarpoolMap
									lat={eventDetail.location.location.lat}
									lng={eventDetail.location.location.lon}
									defaultMapOptions={{
										fullscreenControl: false,
										draggable: false,
										zoomControl: false,
										streetViewControl: false,
										mapTypeControl: false
									}}
								/>
							</>
						)}

						{request?.description && (
							<>
								<Text className="font-semibold mb-2">Notes</Text>
								<Text className="font-normal">{request?.description}</Text>
							</>
						)}
					</MemberDialogContentWrapper>
				</form>
			</MemberDialog>
		</>
	);
};

export default BookRequestDialog;
