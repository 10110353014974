import { Box } from "@material-ui/core";

import styled from "styled-components";

import { Text } from "shared/ui-kit";

export const Container = styled(Box)`
	display: block;

	height: 80px;
	width: 100%;

	cursor: pointer;
`;

export const GetStartedTitle = styled(Text)`
	margin-bottom: 3px;

	font-size: 18px;
	font-weight: bold;
	color: #6173fe;
	letter-spacing: 0.7px;
`;

export const GetStartedText = styled(Text)`
	margin-bottom: 8px;

	font-size: 12px;
	color: #222b45;
`;

export const PercentageContainer = styled(Box)`
	position: relative;
`;

export const PercentageBar = styled(Box)<{ percentage: number }>`
	height: 4px;
	width: 214px;

	background: #b4bbfe;

	border-radius: 4px;

	overflow: hidden;

	position: relative;

	&:after {
		content: "";

		position: absolute;

		top: 0;
		left: 0;

		width: ${({ percentage }) => percentage}%;
		height: 100%;

		background: #6173fe;
		border-radius: 4px;
	}
`;
