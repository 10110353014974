import React, { FC, useCallback, useEffect, useMemo, useState } from "react";

import { DateTime } from "luxon";

import { useHistory } from "react-router";

import { ReactComponent as ReportIcon } from "assets/icons/liveconvo/report.svg";
import { PlaceholderImage, UnlockPremiumBtn } from "shared/Components";
import { useAlbums, useMemberRoutes, usePlayer, useReportContent, useTrack, useUser } from "shared/hooks";
import { PlaceholderImageType, ReportContentType, TrackMeta, TrackModel, UnlockContentType } from "shared/types";
import { Button } from "shared/ui-kit";

import { EntityTemplate, ModelBlockType, TrackCard } from "../../Components";

import { Container, ItemWrapper, MoreItemsWrapper, UnlockedBlockWrapper } from "../Collection/style";
import { OtherBlock } from "../File/style";

interface TrackDetailsProps {
	id: string;
	groupId?: string;
}

const TrackDetails: FC<TrackDetailsProps> = ({ id, groupId }) => {
	const { getTrack, getTracks, getData: getTrackData } = useTrack();
	const { filteredTracks, loading } = getTrackData();

	const { getMemberRoutesData } = useMemberRoutes();
	const { routes } = getMemberRoutesData();

	const { readSong, setTrackProgressDialog, getData: getAlbumsData } = useAlbums();
	const { trackProgressDialog } = getAlbumsData();

	const { setPlayTracks } = usePlayer();
	const { getReportTypeOptions } = useReportContent();

	const { isPrivilegedRole } = useUser();

	const [track, setTrack] = useState<TrackModel | null>(null);

	const history = useHistory();

	useEffect(() => {
		getTrack({ trackId: id, groupId }).then(track => setTrack(track));
	}, [getTrack, id, groupId]);

	useEffect(() => {
		getTracks({ limit: 5, keyword: "", offset: 1, groupId });
	}, [getTracks, groupId]);

	const handleDownloadAlbum = async () => {
		if (!track || !(track.meta as TrackMeta).track.url) return;

		setTrackProgressDialog([
			{
				name: track.title,
				track: (track.meta as TrackMeta).track.url,
				progress: 0
			}
		]);

		const data = await readSong((track.meta as TrackMeta).track.url);
		const link = document.createElement("a");
		link.href = window.URL.createObjectURL(
			new Blob([data], {
				type: "application/octet-stream"
			})
		);
		link.download = `${track.title}.mp3`;
		document.body.appendChild(link);
		link.click();

		setTrackProgressDialog([]);
	};

	const itemDownload = useMemo(
		() => trackProgressDialog.find(t => t.track === (track?.meta as TrackMeta).track.url),
		[track, trackProgressDialog]
	);

	const handleTrackClicked = (id: string) => {
		history.push(`${routes?.member.track.getPath()}/${id}`);
	};

	const handlePlaySong = () => {
		if (!track) return;
		setPlayTracks({
			idx: 0,
			tracks: {
				items: [track]
			}
		});
	};

	const optionList = useMemo(() => {
		return !track?.creator && !isPrivilegedRole
			? [
					{
						name: "Report",
						icon: <ReportIcon viewBox="0 -2 22 22" />,
						onClick: () => {},
						submenuOptions: getReportTypeOptions({
							reportType: ReportContentType.TRACK,
							reportContentId: `${track?._id}`,
							reportContentName: track?.title,
							customCallback: () => {
								history.go(-1);
							}
						})
					}
			  ]
			: [];
	}, [getReportTypeOptions, history, track, isPrivilegedRole]);

	const goToOtherTracks = useCallback(() => {
		history.push(`${routes?.member.tracks.getPath()}${groupId ? `?groupId=${groupId}` : ""}`);
	}, [groupId, history, routes?.member.tracks]);

	return (
		<Container>
			<EntityTemplate
				loading={loading}
				coverPhoto={(track?.meta as TrackMeta)?.artwork.url || undefined}
				placeholderImg={
					<PlaceholderImage
						type={PlaceholderImageType.TRACK_PREVIEW}
						width={224}
						height={224}
						viewBox={"0 0 400 400"}
					/>
				}
				pretitle={track?.title}
				title={track?.title || ""}
				subtitle={`${track?.genre}`.replaceAll(",", " | ")}
				extra={track?.createdAt ? `Released: ${DateTime.fromJSDate(new Date(track?.createdAt)).toRelative()}` : ""}
				locked={track?.locked}
				description={track?.description}
				options={!track?.locked ? optionList : []}
				unlockedBlock={
					<UnlockedBlockWrapper>
						<Button onClick={handlePlaySong}>Play</Button>
						{(track?.meta as TrackMeta)?.canBeDownloaded && (
							<Button palette="light" buttonTheme="light" onClick={handleDownloadAlbum}>
								{itemDownload ? `${itemDownload.progress}% Preparing File..` : "Download"}
							</Button>
						)}
					</UnlockedBlockWrapper>
				}
				unlockAction={
					<UnlockPremiumBtn
						premiumObject={track}
						teaserContent={track?.title}
						premiumObjectType={UnlockContentType.TRACK}
						onUnlock={() => getTrack({ trackId: id }).then(track => setTrack(track))}
					/>
				}
			/>

			{!!filteredTracks?.length && (
				<OtherBlock>
					<OtherBlock.Header>
						<OtherBlock.Title>More Tracks</OtherBlock.Title>
						<OtherBlock.ManageBtn removeSideMargin buttonTheme={"light"} palette={"basic"} onClick={goToOtherTracks}>
							See All
						</OtherBlock.ManageBtn>
					</OtherBlock.Header>
					<MoreItemsWrapper fourItems>
						{(filteredTracks || [])
							.filter(x => x._id !== track?._id)
							.slice(0, 4)
							.map((trackInfo, index) => (
								<ItemWrapper onClick={() => handleTrackClicked(trackInfo._id)} key={index}>
									<TrackCard type={ModelBlockType.track} item={trackInfo} />
								</ItemWrapper>
							))}
					</MoreItemsWrapper>
				</OtherBlock>
			)}
		</Container>
	);
};

export default TrackDetails;
