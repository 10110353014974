import React, { FC, useMemo } from "react";

import { Box } from "@material-ui/core";

import { FileType } from "types/FilesContextValuesType";

import { ReactComponent as IconFile } from "assets/icons/icon-file.svg";
import { TrackRow } from "modules/Manage/View/Components/AddTracksToPlaylist/style";
import { TableIcon } from "modules/Manage/View/Containers/ManageFiles/style";
import { useSeries } from "shared/hooks";
import { Checkbox, Text } from "shared/ui-kit";

interface Props {
	file: FileType;
	handleToggleFile: (file: FileType, checked: boolean | undefined) => void;
	lastFileRef?: React.RefObject<HTMLDivElement>;
	id?: string;
}

const FileBlock: FC<Props> = ({ file, lastFileRef, handleToggleFile, id }) => {
	const { getData: getSeriesData } = useSeries();
	const { seriesDetailsDialog } = getSeriesData();

	const checked = useMemo(
		() => seriesDetailsDialog?.data?.itemIds.some(item => item._id === file._id),
		[file._id, seriesDetailsDialog]
	);

	return (
		<TrackRow ref={lastFileRef} checked={checked} onClick={() => handleToggleFile(file, checked)} id={id}>
			<Box className="track-info">
				<TableIcon withBg>
					<IconFile />
				</TableIcon>
				<Box className="track-name">
					<Text variant="subtitle1">{file.title}</Text>
					<Text variant="body2">{file.category?.name || "-"}</Text>
				</Box>
			</Box>
			<Box className="checkbox">
				<Checkbox checked={checked} />
			</Box>
		</TrackRow>
	);
};

export default FileBlock;
