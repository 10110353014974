import React from "react";

import { useParams } from "react-router";

import { view } from "modules/MemberHome";
import { FeatureFlags } from "shared/Components/MemberNavPanel/constants";
import useCheckIfEnabled from "shared/hooks/useCheckIfFlagEnabled";

const PodcastPage = () => {
	useCheckIfEnabled(FeatureFlags.tracks);

	const { id } = useParams() as { id: string };

	return <view.Podcast id={id} />;
};

export default PodcastPage;
