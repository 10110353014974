import React from "react";

import CircularProgress from "@material-ui/core/CircularProgress";

import config from "config/appConfig";

import { useCommunity } from "shared/hooks";

import { Iframe, SpinnerWrapper, Wrapper } from "./styles";

export function TermsConditions() {
	const { getData } = useCommunity();
	const { workspace } = getData();
	const [loading, setLoading] = React.useState(true);

	const handleIframeOnload = async () => {
		setLoading(false);
	};

	return (
		<Wrapper>
			{loading && (
				<SpinnerWrapper>
					<CircularProgress size={42} />
				</SpinnerWrapper>
			)}
			<Iframe
				src={workspace?.termsAndConditionUrl || config.GLOBAL_CONSTANTS.TERMS_AND_CONDITIONS_LINK}
				frameBorder="0"
				onLoad={handleIframeOnload}
			/>
		</Wrapper>
	);
}
