import { Box, ButtonBase } from "@material-ui/core";
import styled from "styled-components";

export const EmailsSuggestionsWrapper = styled(Box)`
	position: relative;
`;

EmailsSuggestionsWrapper.EmailSuggestion = styled(ButtonBase)`
	display: none;
	${props => props.theme.breakpoints.up("sm")} {
		display: block;
		position: absolute;
		top: 110%;
		left: 0;
		min-width: 240px;
		background-color: white;
		border-radius: 4px;
		border: 1px solid #c5cee0;
		padding: 12px;
	}
`;
