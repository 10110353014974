import React from "react";

import { ReactComponent as FlipIcon } from "assets/icons/icon-flip.svg";
import { ReactComponent as LockIcon } from "assets/icons/lock.svg";
import { ReactComponent as MailIcon } from "assets/icons/mail.svg";
// import { ReactComponent as PhoneIcon } from "assets/icons/phone.svg";

import { useAuth } from "modules/App/Data";
import ConfirmActionModal from "shared/Components/ConfirmActionModal";
import { useCommunity, useNotification, useUser } from "shared/hooks";

import { ChangePasswordDialog } from "./ChangePasswordDialog";

import { ChangePhoneProcess } from "./ChangePhoneProcess";

// import { concatPhone } from "./lib";

import EmailChangeProcess from "./ChangePhoneProcess/EmailChangeProcess";
import { LockIconGray } from "./styles";

import { DropdownOption, SettingsOptionDropdown } from "../SettingsOptionDropdown";
import { SettingsOption, SettingsSectionContainer } from "../commonStyles";

export function AccountSettings() {
	const [showChangePasswordDialog, setShowChangePasswordDialog] = React.useState(false);
	const [showChangePhoneProcess, setShowChangePhoneProcess] = React.useState(false);
	const [confirmForgotPasswordDialogShow, setConfirmForgotPasswordDialogShow] = React.useState(false);
	const [confirmChangeEmailDialogShow, setConfirmChangeEmailDialogShow] = React.useState(false);

	const { communityColors } = useCommunity();

	const { updateUserPhoneState, getData: getUserData } = useUser();
	const { user, userVerified } = getUserData();
	const { forgotPassword, resendVerificationEmailWithoutLogout } = useAuth();
	const { showMessage } = useNotification();

	const handleChangePasswordDialogClose = () => setShowChangePasswordDialog(false);

	const handleChangePasswordDialogOpen = () => setShowChangePasswordDialog(true);

	const handleConfirmForgotPassword = async () => {
		setConfirmForgotPasswordDialogShow(false);
		if (user?.email) {
			try {
				await forgotPassword(user.email);
				showMessage("Instructions to reset the password were sent to your email");
			} catch (error) {
				showMessage("Failed to send instructions to reset the password");
				console.error("Unhandled async error: ", error);
			}
		}
	};

	const handleConfirmForgotPasswordShow = () => {
		setConfirmForgotPasswordDialogShow(true);
	};

	// const handleShowChangePhoneProcess = () => {
	// 	setShowChangePhoneProcess(true);
	// };

	const handleHideChangePhoneProcess = () => {
		setShowChangePhoneProcess(false);
	};

	const handleFinishChangePhoneProcess = (params: { phoneCode: string; phoneNumber: string }) => {
		updateUserPhoneState({
			phoneCode: params.phoneCode,
			phoneNumber: params.phoneNumber
		});
		setShowChangePhoneProcess(false);
	};

	return (
		<>
			<SettingsSectionContainer>
				<SettingsOption>
					<SettingsOption.Icon>
						<MailIcon fill={communityColors.primary} />
					</SettingsOption.Icon>
					<SettingsOption.Header>
						<SettingsOption.Header.Title>{user?.email}</SettingsOption.Header.Title>
						<SettingsOption.Header.Subtitle color={userVerified ? "default" : "danger"}>
							{userVerified ? "Verified Email Address" : "Email Address Not Verified"}
						</SettingsOption.Header.Subtitle>
					</SettingsOption.Header>
					<SettingsOption.More>
						<SettingsOptionDropdown
							options={
								[
									userVerified && {
										icon: <FlipIcon />,
										handler: () => setConfirmChangeEmailDialogShow(true),
										name: "Change Email"
									},
									!userVerified && {
										icon: <MailIcon fill="#8f9bb3" />,
										handler: resendVerificationEmailWithoutLogout,
										name: "Resend Verification Email"
									}
								].filter(Boolean) as DropdownOption[]
							}
						/>
					</SettingsOption.More>
				</SettingsOption>
				{/*TODO: https://vyoo-me.atlassian.net/browse/VM-7158*/}
				{/*<SettingsOption*/}
				{/*	onClick={!user?.phoneNumber ? handleShowChangePhoneProcess : () => {}}*/}
				{/*	cursor={!user?.phoneNumber ? "pointer" : "default"}*/}
				{/*>*/}
				{/*	<SettingsOption.Icon>*/}
				{/*		<PhoneIcon />*/}
				{/*	</SettingsOption.Icon>*/}
				{/*	<SettingsOption.Header>*/}
				{/*		<SettingsOption.Header.Title>*/}
				{/*			{user?.phoneNumber ? concatPhone(user.phoneCode, user.phoneNumber) : "Add phone"}*/}
				{/*		</SettingsOption.Header.Title>*/}
				{/*		{user?.phoneNumber && (*/}
				{/*			<SettingsOption.Header.Subtitle>Verified Phone Number</SettingsOption.Header.Subtitle>*/}
				{/*		)}*/}
				{/*	</SettingsOption.Header>*/}
				{/*	{user?.phoneNumber && (*/}
				{/*		<SettingsOption.More>*/}
				{/*			<SettingsOptionDropdown*/}
				{/*				options={[*/}
				{/*					{ icon: <FlipIcon />, handler: handleShowChangePhoneProcess, name: "Change Phone Number" },*/}
				{/*					{ icon: <DeleteIcon />, handler: () => console.log("delete phone?"), name: "Delete Phone Number" }*/}
				{/*				]}*/}
				{/*			/>*/}
				{/*		</SettingsOption.More>*/}
				{/*	)}*/}
				{/*</SettingsOption>*/}
				<SettingsOption>
					<SettingsOption.Icon fill={communityColors.primary}>
						<LockIcon />
					</SettingsOption.Icon>
					<SettingsOption.Header>
						<SettingsOption.Header.Title>Password</SettingsOption.Header.Title>
					</SettingsOption.Header>
					<SettingsOption.More>
						<SettingsOptionDropdown
							options={[
								{ icon: <FlipIcon />, handler: handleChangePasswordDialogOpen, name: "Change Password" },
								{ icon: <LockIconGray />, handler: handleConfirmForgotPasswordShow, name: "Forgot Password" }
							]}
						/>
					</SettingsOption.More>
				</SettingsOption>
			</SettingsSectionContainer>
			{showChangePasswordDialog && <ChangePasswordDialog onClose={handleChangePasswordDialogClose} />}
			<ConfirmActionModal
				open={confirmForgotPasswordDialogShow}
				onClose={() => setConfirmForgotPasswordDialogShow(false)}
				title="Forgot password"
				bodyText="You will receive instructions on how to recover your password to your email address."
				onConfirm={handleConfirmForgotPassword}
				confirmText="Send"
			/>
			{showChangePhoneProcess && (
				<ChangePhoneProcess
					onFinishProcess={handleFinishChangePhoneProcess}
					onClose={handleHideChangePhoneProcess}
					currentPhoneCode={user?.phoneCode}
					currentPhoneNumber={user?.phoneNumber}
				/>
			)}
			{confirmChangeEmailDialogShow && (
				<EmailChangeProcess setConfirmChangeEmailDialogShow={setConfirmChangeEmailDialogShow} />
			)}
		</>
	);
}
