import styled, { css } from "styled-components";

import { NumericInput } from "shared/Components/NumericInput";

const FormField = styled.div`
	margin-right: 12px;

	&:last-child {
		margin-right: 0;
	}
`;

const PhoneCodeField = styled.div`
	width: 89px;
`;

const inputCss = css`
	border-radius: 8px;
	height: 44px;
	padding: 12px 16px;
	border: 1px solid #e4e9f2;
	font-size: 15px;
	width: 100%;

	&:focus {
		border-color: #192038;
	}
`;

const PhoneInput = styled(NumericInput)`
	${inputCss}
`;

const PhoneCodeInput = styled.input`
	${inputCss}
`;

const Label = styled.label`
	font-size: 13px;
	color: #222b45;
	margin-bottom: 2px;
	font-weight: 600;
	display: block;
`;

export { FormField, PhoneCodeField, PhoneInput, PhoneCodeInput, Label };
