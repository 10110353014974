import React, { memo, useCallback, useEffect } from "react";

import {
	DialogActions,
	DialogTitle,
	IconButton,
	Dialog as MuiDialog,
	DialogProps as MuiDialogProps,
	useMediaQuery
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import clsx from "clsx";
import styled, { css } from "styled-components";

import { ScrollBarWrapper } from "modules/MemberHome/View/shared/MemberDialog/style";
import { useScrollbar } from "shared/hooks";
import { BodyWrapper, ScrollbarBody } from "shared/styles";
import { Icon } from "shared/ui-kit";
import * as appTheme from "theme/default";

const DialogWrapper = styled(MuiDialog)<{ hideBackground: boolean; fullScreenMode: boolean }>`
	.MuiDialog-paperScrollPaper {
		max-height: 100%;
		border-radius: ${({ isMemberView }) => (isMemberView ? 16 : 12)}px;
	}

	&.maxHeight85 {
		.MuiDialog-paperScrollPaper {
			max-height: 85vh;
		}
	}

	.MuiDialog-container {
		overflow: hidden;
	}

	.MuiBackdrop-root {
		${({ hideBackground }) =>
			hideBackground &&
			css`
				opacity: 0 !important;
			`}
	}

	${({ highzIndex }) =>
		highzIndex &&
		css`
			z-index: 100000 !important;
		`}

	${({ customWidth }) =>
		customWidth &&
		css`
			.MuiDialog-paper {
				width: ${customWidth + "px"};
			}
		`}
			
	${({ fullScreenMode }) =>
		fullScreenMode &&
		css`
			.MuiPaper-root {
				min-width: 100vw;
				min-height: 100vh;

				margin: 0;

				border-radius: 0;
			}
		`}

	${props => props.theme.breakpoints.down("sm")} {
		.MuiDialog-paperScrollPaper {
			padding: 0;
			width: 90%;
			margin: 0;
		}
	}
`;

const Title = styled(DialogTitle)`
	width: 100%;
	min-height: 56px;
	text-align: center;
	border-bottom: 1px solid #edf1f7;
	font-size: 18px;

	.ButtonBack {
		position: absolute;
		left: 15px;
		top: 15px;
		color: #c5cee0;
		cursor: pointer;
	}

	.MuiButtonBase-root {
		position: absolute;
		right: 5px;
		top: 5px;
		color: #c5cee0;
	}

	h2 {
		font-size: 18px;
	}
`;

const Footer = styled(DialogActions)`
	border-top: 1px solid #edf1f7;
	padding: 12px 16px;

	${({ ignorePadding }) =>
		ignorePadding &&
		css`
			${props => props.theme.breakpoints.up("sm")} {
				padding: 12px 40px;
			}
		`}
`;

interface OwnProps {
	title?: string | React.ReactNode;
	children: React.ReactNode;
	footer?: React.ReactNode;
	hasBackButton?: boolean;
	onBack?: () => void;
	showHead?: boolean;
	blurBackground?: boolean;
	maxWidth?: string;
	bodyCustomStyles?: string;
	fullScreenMode?: boolean;
	ignorePadding?: boolean;
	isMemberView?: boolean;
	highzIndex?: boolean;
	className?: string;
	bodyRef?;
	isDelete?: boolean;
	isScrollable?: boolean;
	customWidth?: number;
}

export type DialogProps = OwnProps & Pick<MuiDialogProps, "open" | "onClose" | "PaperProps" | "classes">;

const Dialog: React.FC<DialogProps> = memo(
	({
		open,
		onClose,
		title,
		children,
		footer,
		hasBackButton = false,
		onBack,
		showHead = true,
		blurBackground = true,
		maxWidth = "sm",
		customWidth,
		bodyCustomStyles = "",
		fullScreenMode = false,
		// ignorePadding = false,
		isMemberView = false,
		highzIndex,
		className,
		bodyRef,
		isDelete,
		isScrollable = true,
		...rest
	}) => {
		const { showScroll, hideScroll } = useScrollbar();

		useEffect(() => {
			open ? hideScroll() : showScroll();

			return () => {
				showScroll();
			};
		}, [showScroll, hideScroll, open]);

		const isMobile = useMediaQuery(appTheme.default.breakpoints.down("xs"));

		const onCloseHandler = useCallback(
			(e, r) => {
				onClose && onClose(e, r);
			},
			[onClose]
		);

		if (!open) return null;

		return (
			<DialogWrapper
				open={open}
				onClose={onCloseHandler}
				maxWidth={maxWidth}
				fullWidth
				hideBackground={!blurBackground}
				fullScreenMode={fullScreenMode || isMobile}
				isMemberView={isMemberView}
				highzIndex={highzIndex}
				className={clsx("flex flex-col", className)}
				customWidth={customWidth}
				{...rest}
			>
				{showHead && (
					<Title>
						{hasBackButton && onBack && (
							<Icon group={"filled"} fill={"#c5cee0"} name={"arrow-left"} className="ButtonBack" onClick={onBack} />
						)}
						{title}
						{onClose ? (
							<IconButton aria-label="close" onClick={onCloseHandler as any}>
								<CloseIcon />
							</IconButton>
						) : null}
					</Title>
				)}

				{isScrollable ? (
					<ScrollBarWrapper
						autoHide
						autoHideDuration={200}
						thumbMinSize={10}
						className="scrollContent flex flex-col flex-1"
					>
						<ScrollbarBody bodyCustomStyles={bodyCustomStyles} ref={bodyRef} isDelete={isDelete}>
							{children}
						</ScrollbarBody>
					</ScrollBarWrapper>
				) : (
					<BodyWrapper bodyCustomStyles={bodyCustomStyles} isDelete={isDelete} ref={bodyRef}>
						{children}
					</BodyWrapper>
				)}

				{footer && <Footer>{footer}</Footer>}
			</DialogWrapper>
		);
	}
);

export default Dialog;
