import { ButtonBase } from "@material-ui/core";
import styled from "styled-components";

import { Box } from "shared/ui-kit";

export const ListWrapper = styled(Box)`
	margin: 32px 0 0 -16px;

	width: calc(100% + 32px);

	padding: ${({ customPadding }) => (customPadding ? customPadding : "0 0 32px 16px")};

	display: flex;
	align-items: flex-start;
`;

export const ItemWrapper = styled(Box)`
	width: 360px;
	min-width: 360px;
	text-align: left;
	margin-right: 16px;
`;

export const ActionIconWrapper = styled(ButtonBase)`
	display: inline-flex;
	align-items: center;
	justify-content: center;

	cursor: pointer;
	width: 40px;
	height: 40px;

	position: relative;

	svg {
		width: 24px;
		height: 24px;
	}
`;

export const RowBox = styled(Box)`
	display: flex;
	flex-direction: row;
`;
