import { useCallback, useMemo } from "react";

import { usePlayableMediaStore } from "../contexts";

const usePlayableMedia = () => {
	const store = usePlayableMediaStore();
	const { setState } = usePlayableMediaStore();

	const methods = useMemo(
		() => ({
			setPlayableObjectId(id: string) {
				setState(ctx => ({ ...ctx, playableObjectId: id }));
			},
			setNextTrackId(id: string) {
				setState(ctx => ({ ...ctx, nextTrackId: id }));
			}
		}),
		[setState]
	);

	const getData = useCallback(() => {
		return store;
	}, [store]);

	return { ...methods, getData };
};

export default usePlayableMedia;
