import { red } from "@material-ui/core/colors";
import { PaletteColorOptions, Theme, createMuiTheme } from "@material-ui/core/styles";
import { CommonColors, Palette, PaletteColor } from "@material-ui/core/styles/createPalette";
import { TypographyOptions, TypographyStyleOptions } from "@material-ui/core/styles/createTypography";

import * as colors from "./colors";

type ICustomPaletteColorOptions =
	| {
			border: string;
	  }
	| PaletteColorOptions;

type ColorsType = {
	100: string;
	200: string;
	300: string;
	400: string;
	500: string;
	600: string;
	700: string;
	800: string;
	900: string;
	1000?: string;
	1100?: string;
};

type TagColorType = {
	backgroundColor: string;
	backgroundColorOnHover: string;
	backgroundColorOnPressed: string;
	color: string;
};

type BannerColor = {
	backgroundColor: string;
	borderColor?: string;
	textColor: string;
};

type BannerColors = {
	basic: BannerColor;
	primary: BannerColor;
	success: BannerColor;
	info: BannerColor;
	warning: BannerColor;
	danger: BannerColor;
};

export type ButtonColorType = {
	backgroundColor: string;
	backgroundColorOnHover: string;
	backgroundColorOnPressed: string;
	color: string;
	iconColor?: string;
	boxShadowColor?: string;
	labelOpacity?: number;
};

export type ButtonSizeType = {
	fontSize: string;
	iconFontSize: string;
	iconHeight: string;
	iconWidth: string;
	iconLineHeight: string;
	lineHeight: string;
	padding: string;
	paddingFromIcon: string;
	paddingWithLeftIcon: string;
	paddingWithRightIcon: string;
	squarePadding: string;
	squareWidth: string;
};

export type InfoCard = {
	backgroundColor: string;
	borderColor: string;
	iconColor: string;
	textColor: string;
};

export type FrameSize = {
	borderRadius: string;
	width: string;
	height: string;
	padding: string;
	screenPadding: string;
	screenBorderRadius: string;
	cameraSize: string;
	topBangsPadding: string;
};

export interface ISizes {
	button: {
		giant: ButtonSizeType;
		large: ButtonSizeType;
		medium: ButtonSizeType;
		small: ButtonSizeType;
		tiny: ButtonSizeType;
	};
	frame: {
		smartphone: FrameSize;
		tablet: FrameSize;
	};
	imagePickerDefaultSize: {
		width: string;
		height: string;
		borderRadius: string;
		closeIconSize: string;
	};
	snackbar: {
		fontSize: string;
	};
	contentBox: {
		defaultPaddings: string;
		defaultWidth: string;
	};
	formFooter: {
		marginTop: string;
		fontSize: string;
	};
	questionCard: {
		actionItem: {
			fontSize: string;
			lineHeight: number | string;
			iconSize: number;
			marginLeft: string;
			marginRight: string;
		};
		actions: {
			height: string;
			left: {
				padding: string;
			};
			padding: string;
			right: {
				padding: string;
			};
		};
		content: {
			padding: string;
		};
		iconButton: {
			marginLeft: string;
			marginRight: string;
			marginTop: string;
			marginBottom: string;
		};
	};
}

type StatusColors = {
	basic: string;
	primary: string;
	success: string;
	info: string;
	warning: string;
	danger: string;
};

type ExtendedPaletteColor = PaletteColor & {
	tabs: PaletteColorOptions & { border?: string };
};

export interface IExtendedPaletteOptions extends Palette {
	common: CommonColors & { whiteShade1: string; grey: string };
	info: ExtendedPaletteColor;
	secondary: ExtendedPaletteColor;
	primary: ExtendedPaletteColor;
	success: ExtendedPaletteColor;
	warning: ExtendedPaletteColor;
	error: ExtendedPaletteColor;
	disabled: PaletteColorOptions;
	simple: PaletteColorOptions;
	simpleLabel: PaletteColorOptions;
	tertiary: ICustomPaletteColorOptions;
	quaternary: PaletteColorOptions;
	secondaryLight: PaletteColor;
	greenLight: PaletteColorOptions;
	infoInvert: PaletteColorOptions;
	infoLight: PaletteColorOptions;
	infoWarning: PaletteColorOptions;
	deprecated: PaletteColorOptions;
	tangerine: PaletteColorOptions;
	errorInvert: { contrastText: string };
	simpleQuaternary: PaletteColorOptions;
	solidSuccess: PaletteColorOptions;
	solidWarning: PaletteColorOptions;
	solidPrimary: PaletteColorOptions;
	solidTangerine: PaletteColorOptions;
	solidInfoLight: PaletteColorOptions;
	fabArrowDark: PaletteColorOptions;
	drawer: {
		foreground: string;
		background: string;
		titleColor: string;
		titleFontSize: string;
	};
	BannerColors: BannerColors;
	StepItem: {
		activeText: string;
		activeBackground: string;
		disabledBackground: string;
		disabledText: string;
		disabledBox: string;
		activeSuccess: string;
		disabledBasic: string;
	};
	RawColorPicker: {
		borderColor: string;
		titleColor: string;
	};
	Select: {
		defaultText: string;
		filledText: string;
		defaultBorder: string;
		activeBorder: string;
		successBorder: string;
		errorBorder: string;
		ghostBorder: string;
		menuItemColor: string;
	};
	InfoCard: InfoCard;
	TagColors: {
		basic: TagColorType;
		success: TagColorType;
		info: TagColorType;
		danger: TagColorType;
		control: TagColorType;
		primary: TagColorType;
	};
	sidebar: {
		main: string;
		active: string;
		background: string;
		contentBackground: string;
	};
	logo: PaletteColorOptions;
	colors: {
		basic: ColorsType;
		primary: ColorsType;
		success: ColorsType;
		info: ColorsType;
		warning: ColorsType;
		danger: ColorsType;
	};
	mainButtonColors: {
		info: ButtonColorType;
		primary: ButtonColorType;
	};
	lightButtonColors: {
		basic: ButtonColorType;
		success: ButtonColorType;
		info: ButtonColorType;
		danger: ButtonColorType;
		control: ButtonColorType;
		primary: ButtonColorType;
	};
	ghostButtonColors: {
		basic: ButtonColorType;
		success: ButtonColorType;
		info: ButtonColorType;
		danger: ButtonColorType;
		control: ButtonColorType;
		primary: ButtonColorType;
	};
	outlineButtonColors: ButtonColorType;
	disabledButtonColors: ButtonColorType;
	inputColors: {
		disabledColor: string;
		disabledBackgroundColor: string;
		focusedColor: string;
		focusedOutlinedBorderColor: string;
		outlinedBorderColor: string;
	};
	controlPrimaryBackgroundColor: string;
	controlPrimaryColor: string;
	controlSecondaryColor: string;
	controlSecondaryBackgroundColor: string;
	controlFillColor: string;
	controlLabelColor: string;
	labelColorPrimary: string;
	labelColorSecondary: string;
	status: StatusColors;
	frame: {
		blankContentColor: string;
		outerBorderColor: string;
		innerBorderColor: string;
		highlightBorderColor: string;
		glowBorderColor: string;
		deviceColor: string;
		contentBackgroundColor: string;
		glowBackgroundColor: string;
	};
	imagePickerBackgroundColor: string;
	imagePickerCloseIconColor: string;
	snackbar: {
		backgroundColor: string;
		textColor: string;
	};
	vyooStartTemplate: {
		headerBackgroundColor: string;
		contentBackgroundColor: string;
	};
	contentBox: {
		backgroundColor: string;
		borderColor: string;
	};
	formFooterTextColor: string;
}

const { palette: defaultPalette } = createMuiTheme();

export const colorPalette: IExtendedPaletteOptions = {
	...defaultPalette,
	colors,
	common: {
		black: "#000",
		white: colors.basic[100],
		whiteShade1: "#f8f8f8",
		grey: colors.basic[600]
	},
	disabled: {
		contrastText: colors.basic[600],
		main: "rgba(143, 155, 179, 0.16)"
	},
	type: "light",
	simple: {
		contrastText: colors.basic[800],
		main: "transparent"
	},
	simpleQuaternary: {
		contrastText: "#1998d5",
		main: "transparent"
	},
	simpleLabel: {
		contrastText: colors.basic[600],
		main: "transparent"
	},
	primary: {
		contrastText: colors.basic[100],
		dark: defaultPalette.primary.dark,
		light: defaultPalette.primary.light,
		main: colors.primary[500],
		tabs: {
			border: colors.basic[500],
			contrastText: colors.basic[800],
			main: colors.basic[300]
		}
	},
	secondary: {
		contrastText: defaultPalette.secondary.contrastText,
		dark: defaultPalette.secondary.dark,
		light: defaultPalette.secondary.light,
		main: "#232f3e",
		tabs: {
			contrastText: defaultPalette.secondary.contrastText,
			dark: colors.info[500],
			main: "#232f3e",
			border: colors.info[600]
		}
	},
	tertiary: {
		border: colors.basic[500],
		contrastText: colors.basic[600],
		main: colors.basic[100]
	},
	quaternary: {
		contrastText: colors.basic[100],
		main: "#1998d5"
	},
	info: {
		contrastText: colors.basic[100],
		dark: defaultPalette.info.dark,
		light: defaultPalette.info.light,
		main: colors.primary[500],
		tabs: {
			contrastText: colors.info[500],
			dark: colors.info[500],
			main: colors.info[100],
			border: colors.basic[300]
		}
	},
	error: {
		...defaultPalette.error,
		tabs: defaultPalette.error
	},
	secondaryLight: {
		dark: "",
		light: "",
		contrastText: colors.basic[800],
		main: colors.basic[300]
	},
	greenLight: {
		contrastText: "#71a411",
		main: "#f2f3f8"
	},
	infoInvert: {
		contrastText: colors.basic[600],
		main: colors.basic[100]
	},
	// blue
	infoLight: {
		contrastText: "#1998d5",
		main: "#f0faff"
	},
	infoWarning: {
		main: "#fff4e5",
		contrastText: "#ff9900"
	},
	// green
	success: {
		contrastText: "#71a411",
		dark: defaultPalette.success.dark,
		light: defaultPalette.success.light,
		main: "#f3f9e7",
		tabs: {
			contrastText: "#71a411",
			dark: defaultPalette.success.dark,
			main: "#f3f9e7",
			border: defaultPalette.success.dark
		}
	},
	// red
	warning: {
		contrastText: "#ff6138",
		dark: defaultPalette.warning.dark,
		light: defaultPalette.warning.light,
		main: "#fff8f2",
		tabs: {
			contrastText: "#ff6138",
			dark: defaultPalette.warning.dark,
			main: "#fff8f2",
			border: defaultPalette.warning.dark
		}
	},
	deprecated: {
		contrastText: "#ffc400",
		main: colors.basic[100]
	},
	tangerine: {
		contrastText: "#ff9900",
		main: "#fff8f2"
	},
	errorInvert: {
		contrastText: red[500]
	},
	solidSuccess: {
		contrastText: colors.basic[100],
		main: "#8ec418"
	},
	solidWarning: {
		contrastText: "#ff9900",
		main: "#ff6138"
	},
	solidPrimary: {
		contrastText: "#ff9900",
		main: colors.basic[100]
	},
	solidTangerine: {
		contrastText: "#ff9900",
		main: "#fff8f2"
	},
	solidInfoLight: {
		contrastText: "#ff9900",
		main: "#fff8f2"
	},
	contrastThreshold: 3,
	tonalOffset: 0.2,
	text: {
		disabled: defaultPalette.text.disabled,
		hint: defaultPalette.text.hint,
		primary: colors.basic[800],
		secondary: colors.basic[600]
	},
	fabArrowDark: {
		contrastText: colors.basic[600],
		dark: colors.basic[300],
		main: colors.basic[300]
	},
	drawer: {
		background: "#232f3e",
		foreground: "#192435",
		titleColor: colors.basic[100],
		titleFontSize: "24px"
	},
	sidebar: {
		main: "#7d807e",
		active: colors.basic[100],
		background: "#0e1928",
		contentBackground: "#101c2c"
	},
	logo: {
		dark: "#417dc8",
		main: colors.basic[100]
	},
	BannerColors: {
		basic: {
			backgroundColor: colors.basic[500],
			textColor: colors.basic[100]
		},
		primary: {
			backgroundColor: colors.primary[500],
			textColor: colors.basic[100]
		},
		success: {
			backgroundColor: colors.success[500],
			textColor: colors.basic[100]
		},
		info: {
			backgroundColor: colors.info[500],
			textColor: colors.basic[100]
		},
		warning: {
			backgroundColor: colors.warning[500],
			textColor: colors.basic[100]
		},
		danger: {
			backgroundColor: colors.danger[500],
			textColor: colors.basic[100]
		}
	},
	InfoCard: {
		backgroundColor: colors.basic[200],
		borderColor: colors.basic[400],
		iconColor: colors.basic[600],
		textColor: colors.basic[600]
	},
	StepItem: {
		activeText: colors.info[500],
		activeBackground: colors.basic[100],
		disabledBackground: colors.basic[200],
		disabledText: colors.basic[600],
		disabledBox: colors.basic[300],
		activeSuccess: colors.success[600],
		disabledBasic: colors.basic[400]
	},
	RawColorPicker: {
		borderColor: colors.basic[500],
		titleColor: colors.basic[800]
	},
	Select: {
		defaultBorder: colors.basic[400],
		activeBorder: colors.info[500],
		successBorder: colors.success[500],
		errorBorder: colors.danger[500],
		defaultText: colors.basic[600],
		ghostBorder: colors.basic[100],
		filledText: colors.info[800],
		menuItemColor: colors.basic[300]
	},
	TagColors: {
		basic: {
			backgroundColor: colors.basic[300],
			backgroundColorOnHover: colors.basic[400],
			backgroundColorOnPressed: colors.basic[500],
			color: colors.basic[800]
		},
		success: {
			backgroundColor: colors.success[100],
			backgroundColorOnHover: colors.success[200],
			backgroundColorOnPressed: colors.success[300],
			color: colors.success[600]
		},
		info: {
			backgroundColor: colors.info[100],
			backgroundColorOnHover: colors.info[200],
			backgroundColorOnPressed: colors.info[300],
			color: colors.info[500]
		},
		danger: {
			backgroundColor: colors.danger[100],
			backgroundColorOnHover: colors.danger[200],
			backgroundColorOnPressed: colors.danger[300],
			color: colors.danger[500]
		},
		control: {
			backgroundColor: colors.basic[100],
			backgroundColorOnHover: colors.basic[200],
			backgroundColorOnPressed: colors.basic[300],
			color: colors.basic[600]
		},
		primary: {
			backgroundColor: colors.primary[100],
			backgroundColorOnHover: colors.primary[200],
			backgroundColorOnPressed: colors.primary[300],
			color: colors.primary[500]
		}
	},
	lightButtonColors: {
		basic: {
			backgroundColor: colors.basic[300],
			backgroundColorOnHover: colors.basic[400],
			backgroundColorOnPressed: colors.basic[500],
			color: colors.basic[800]
		},
		success: {
			backgroundColor: colors.success[100],
			backgroundColorOnHover: colors.success[200],
			backgroundColorOnPressed: colors.success[300],
			color: colors.success[600]
		},
		info: {
			backgroundColor: colors.info[100],
			backgroundColorOnHover: colors.info[200],
			backgroundColorOnPressed: colors.info[300],
			color: colors.info[500]
		},
		danger: {
			backgroundColor: colors.danger[100],
			backgroundColorOnHover: colors.danger[200],
			backgroundColorOnPressed: colors.danger[300],
			color: colors.danger[500]
		},
		control: {
			backgroundColor: colors.basic[100],
			backgroundColorOnHover: colors.basic[200],
			backgroundColorOnPressed: colors.basic[300],
			color: colors.basic[600]
		},
		primary: {
			backgroundColor: colors.primary[100],
			backgroundColorOnHover: colors.primary[200],
			backgroundColorOnPressed: colors.primary[300],
			color: colors.primary[500]
		}
	},
	ghostButtonColors: {
		basic: {
			backgroundColor: colors.basic[100],
			backgroundColorOnHover: colors.basic[200],
			backgroundColorOnPressed: colors.basic[300],
			color: colors.basic[800]
		},
		success: {
			backgroundColor: colors.basic[100],
			backgroundColorOnHover: colors.basic[200],
			backgroundColorOnPressed: colors.basic[300],
			color: colors.success[600]
		},
		info: {
			backgroundColor: colors.basic[100],
			backgroundColorOnHover: colors.basic[200],
			backgroundColorOnPressed: colors.basic[300],
			color: colors.info[500]
		},
		danger: {
			backgroundColor: colors.basic[100],
			backgroundColorOnHover: colors.basic[200],
			backgroundColorOnPressed: colors.basic[300],
			color: colors.danger[500]
		},
		control: {
			backgroundColor: colors.basic[100],
			backgroundColorOnHover: colors.basic[200],
			backgroundColorOnPressed: colors.basic[300],
			color: colors.basic[600]
		},
		primary: {
			backgroundColor: colors.basic[100],
			backgroundColorOnHover: colors.basic[200],
			backgroundColorOnPressed: colors.basic[300],
			color: colors.primary[500]
		}
	},
	mainButtonColors: {
		info: {
			backgroundColor: colors.info[500],
			backgroundColorOnHover: colors.info[400],
			backgroundColorOnPressed: colors.info[600],
			color: colors.basic[100]
		},
		primary: {
			backgroundColor: "rgb(97, 115, 254)",
			backgroundColorOnHover: colors.primary[400],
			backgroundColorOnPressed: colors.primary[600],
			color: colors.basic[100]
		}
	},
	outlineButtonColors: {
		color: colors.basic[600],
		backgroundColor: colors.basic[100],
		backgroundColorOnHover: colors.basic[200],
		backgroundColorOnPressed: colors.basic[300],
		boxShadowColor: colors.basic[500],
		iconColor: colors.basic[500]
	},
	disabledButtonColors: {
		color: colors.basic[600],
		backgroundColor: "rgba(143, 155, 179, .16)",
		backgroundColorOnHover: "rgba(143, 155, 179, .16)",
		backgroundColorOnPressed: "rgba(143, 155, 179, .16)",
		labelOpacity: 0.48
	},
	inputColors: {
		disabledColor: colors.basic[600],
		disabledBackgroundColor: colors.basic[200],
		focusedColor: colors.info[500],
		focusedOutlinedBorderColor: colors.info[500],
		outlinedBorderColor: colors.success[500]
	},
	controlPrimaryBackgroundColor: colors.primary[200],
	controlPrimaryColor: colors.primary[500],
	controlSecondaryColor: colors.basic[400],
	controlSecondaryBackgroundColor: colors.basic[600],
	controlFillColor: colors.basic[600],
	controlLabelColor: colors.basic[800],
	labelColorPrimary: colors.primary[500],
	status: {
		basic: colors.basic[500],
		primary: colors.primary[500],
		success: colors.success[500],
		info: colors.info[500],
		warning: colors.warning[500],
		danger: colors.danger[500]
	},
	labelColorSecondary: colors.basic[500],
	frame: {
		blankContentColor: colors.basic[600],
		outerBorderColor: colors.basic[500],
		innerBorderColor: colors.basic[500],
		highlightBorderColor: colors.basic[800],
		glowBorderColor: colors.primary[500],
		deviceColor: colors.basic[100],
		contentBackgroundColor: colors.basic[200],
		glowBackgroundColor: colors.primary[100]
	},
	imagePickerBackgroundColor: colors.basic[400],
	imagePickerCloseIconColor: colors.basic[600],
	snackbar: {
		backgroundColor: colors.basic[1000],
		textColor: colors.basic[100]
	},
	vyooStartTemplate: {
		headerBackgroundColor: "rgba(255, 255, 255, 0.94)",
		contentBackgroundColor: colors.basic[200]
	},
	contentBox: {
		backgroundColor: colors.basic[100],
		borderColor: colors.basic[500]
	},
	formFooterTextColor: colors.basic[600]
};

const giantButtonSize: ButtonSizeType = {
	fontSize: "18px",
	iconFontSize: "17px",
	iconHeight: "21px",
	iconWidth: "20px",
	iconLineHeight: "24px",
	lineHeight: "1.33",
	padding: "16px 22px",
	paddingFromIcon: "10px",
	paddingWithLeftIcon: "16px 22px 16px 16px",
	paddingWithRightIcon: "16px 18px 16px 22px",
	squarePadding: "14px",
	squareWidth: "56px"
};

export const sizes: ISizes = {
	button: {
		giant: giantButtonSize,
		large: {
			...giantButtonSize,
			fontSize: "16px",
			iconLineHeight: "24px",
			lineHeight: "1.25",
			padding: "14px 18px",
			paddingFromIcon: "12px",
			paddingWithLeftIcon: "12px 18px 12px 14px",
			paddingWithRightIcon: "12px 15px 12px 22px",
			squarePadding: "12px",
			squareWidth: "48px"
		},
		medium: {
			fontSize: "14px",
			iconFontSize: "14px",
			iconHeight: "17px",
			iconWidth: "17px",
			iconLineHeight: "20px",
			lineHeight: "1.14",
			padding: "12px 18px 12px 19px",
			paddingFromIcon: "4.5px",
			paddingWithLeftIcon: "10px 18.5px 10px 16px",
			paddingWithRightIcon: "10px 16.5px 10px 13px",
			squarePadding: "10px",
			squareWidth: "40px"
		},
		small: {
			fontSize: "12px",
			iconFontSize: "11px",
			iconHeight: "14px",
			iconWidth: "14px",
			iconLineHeight: "16px",
			lineHeight: "1.33",
			padding: "8px 14px 8px 15px",
			paddingFromIcon: "2.5px",
			paddingWithLeftIcon: "8px 14.5px 8px 11px",
			paddingWithRightIcon: "8px 8px 8px 12.5px",
			squarePadding: "8px",
			squareWidth: "32px"
		},
		tiny: {
			fontSize: "10px",
			iconFontSize: "9px",
			iconHeight: "11px",
			iconWidth: "11px",
			iconLineHeight: "12px",
			lineHeight: "1.2",
			padding: "6px 11px",
			paddingFromIcon: "7px",
			paddingWithLeftIcon: "6px 11px 6px 8px",
			paddingWithRightIcon: "6px 9px 6px 13px",
			squarePadding: "6px",
			squareWidth: "24px"
		}
	},
	frame: {
		smartphone: {
			width: "338px",
			height: "690px",
			padding: "13px",
			screenPadding: "40px 16px 16px",
			borderRadius: "40px",
			screenBorderRadius: "30px",
			cameraSize: "10px",
			topBangsPadding: "15px"
		},
		tablet: {
			width: "605px",
			height: "725px",
			padding: "23px",
			screenPadding: "24px 16px",
			borderRadius: "36px",
			screenBorderRadius: "20px",
			cameraSize: "11px",
			topBangsPadding: "6px"
		}
	},
	imagePickerDefaultSize: {
		width: "96px",
		height: "96px",
		borderRadius: "4px",
		closeIconSize: "22px"
	},
	snackbar: {
		fontSize: "15px"
	},
	contentBox: {
		defaultPaddings: "62px 80px 80px",
		defaultWidth: "560px"
	},
	formFooter: {
		marginTop: "16px",
		fontSize: "12px"
	},
	questionCard: {
		actionItem: {
			fontSize: "12px",
			lineHeight: "normal",
			iconSize: 24,
			marginLeft: "10px",
			marginRight: "10px"
		},
		actions: {
			height: "50px",
			left: {
				padding: "0"
			},
			padding: "5px 0 0",
			right: {
				padding: "0 25px"
			}
		},
		content: {
			padding: "20px"
		},
		iconButton: {
			marginLeft: "10px",
			marginRight: "10px",
			marginTop: "5px",
			marginBottom: "auto"
		}
	}
};

export interface IExtendedTheme extends Theme {
	palette: IExtendedPaletteOptions;
	sizes: ISizes;
}

interface CustomTypography extends TypographyOptions {
	caption1: TypographyStyleOptions;
}

export default createMuiTheme(
	{
		overrides: {
			MuiButton: {
				root: {
					borderRadius: "4px",
					cursor: "pointer",
					fontSize: "12px",
					margin: "5px"
				}
			},
			MuiDialog: {
				container: {
					overflowY: "scroll"
				}
			},
			MuiFormControl: {
				root: {
					width: "100%"
				}
			},
			MuiSlider: {
				root: {
					height: 4
				},
				rail: {
					height: 4
				},
				track: {
					height: 4
				}
			},
			MuiInputLabel: {
				outlined: {
					fontSize: 15,
					transform: "translate(14px, 16px) scale(1)"
				}
			},
			MuiOutlinedInput: {
				adornedEnd: {
					paddingRight: 0
				},
				input: {
					fontSize: 15,
					lineHeight: "20px",
					padding: "14px 16px"
				},
				notchedOutline: {
					borderColor: "#e4e9f2",
					top: -2
				}
			},
			MuiTabs: {
				indicator: {
					backgroundColor: "transparent"
				}
			}
		},
		props: {
			MuiButton: {
				disableElevation: true
			},
			MuiCheckbox: {
				color: "default",
				size: "small"
			},
			MuiRadio: {
				color: "primary"
			},
			MuiSwitch: {
				color: "primary"
			},
			MuiTab: {
				disableRipple: true
			}
		},
		palette: colorPalette,
		typography: {
			fontFamily: '"Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif',
			h1: {
				fontSize: "2.25rem", // 36px
				fontWeight: 700,
				lineHeight: 1.33
			},
			h2: {
				fontSize: "2rem", // 32px
				fontWeight: 700,
				lineHeight: 1.25
			},
			h3: {
				fontSize: "1.875rem", // 30px
				fontWeight: 700,
				lineHeight: 1.33
			},
			h4: {
				fontSize: "1.625rem", // 26px
				fontWeight: 700,
				lineHeight: 1.23
			},
			h5: {
				fontSize: "1.375rem", // 22px
				fontWeight: 700,
				lineHeight: 1.45
			},
			h6: {
				fontSize: "1.125rem", // 18px
				fontWeight: 700,
				lineHeight: 1.33
			},
			subtitle1: {
				fontSize: "0.9375rem", // 15px
				fontWeight: 600,
				lineHeight: 1.6
			},
			subtitle2: {
				fontSize: "0.8125rem", // 13px
				fontWeight: 600,
				lineHeight: 1.85
			},
			body1: {
				fontSize: "0.9375rem", // 15px
				lineHeight: 1.33
			},
			body2: {
				fontSize: "0.8125rem", // 13px
				lineHeight: 1.23
			},
			caption1: {
				fontSize: "0.75rem", // 12px
				lineHeight: 1.33
			},
			caption2: {
				fontSize: "0.75rem", // 12px
				fontWeight: 600,
				lineHeight: 1.33
			},
			label: {
				fontSize: "0.75rem", // 12px
				lineHeight: 1.33,
				textTransform: "uppercase"
			},
			small1: {
				fontSize: "0.625rem", // 10px
				fontWeight: 600,
				lineHeight: "normal"
			},
			small2: {
				fontSize: "0.625rem", // 10px
				fontWeight: 600,
				lineHeight: "normal"
			}
		} as CustomTypography
	},
	{
		sizes
	}
) as IExtendedTheme;
