import { Box, ButtonBase } from "@material-ui/core";
import styled from "styled-components";

import { Button, Text } from "shared/ui-kit";

export const Wrapper = styled(Box)`
	border-radius: 12px;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08), inset 0 0 0 0.5px #c5cee0;
	background-color: #fff;
	padding: 16px 8px 8px;
	width: 100%;
	flex-shrink: 0;
	position: relative;

	margin-bottom: ${({ marginBottom }) => `${marginBottom}px` || 0};
	margin-top: ${({ marginTop }) => `${marginTop}px` || 0};

	${props => props.theme.breakpoints.up("sm")} {
		width: 320px;
	}
`;

Wrapper.EditButton = styled(ButtonBase)`
	position: absolute;
	top: 16px;
	right: 16px;
	width: 32px;
	height: 32px;
	border-radius: 8px;
	box-shadow: 0 0 0 0.5px #c5cee0;
	display: flex;
	align-items: center;
	justify-content: center;
	svg {
		width: 16px;
		height: 16px;
	}
`;

Wrapper.Tags = styled(Box)`
	display: flex;
	flex-wrap: wrap;
	padding: 0 4px;
`;

Wrapper.Tag = styled(Box)`
	padding: 2px 8px;
	border-radius: 8px;
	box-shadow: inset 0 0 0 0.5px #c5cee0;
	background-color: #f7f9fc;
	margin: 0 4px 8px;
	font-size: 13px;
	font-weight: 600;
	line-height: 1.85;
	color: #222b45;
`;

Wrapper.MemberRow = styled(Box)`
	display: flex;
	align-items: center;
	width: 100%;
	text-align: left;
	justify-content: flex-start;
	margin-bottom: 8px;
	padding: 8px;
	cursor: pointer;
	&:hover {
		background-color: #f7f9fc;
		border-radius: 12px;
	}
	img {
		width: 48px;
		height: 48px;
		border-radius: 100px;
		object-fit: cover;
		margin-right: 16px;
	}
	h6 {
		font-size: 15px;
		font-weight: 600;
		line-height: 1.6;
		color: #222b45;
	}
	p {
		font-size: 13px;
		font-weight: 600;
		line-height: 1.85;
		color: #8f9bb3;
	}
`;

Wrapper.PrimaryButton = styled(Button)`
	width: 100%;
	margin: 0;
	border-radius: 8px;
	background-color: #edf1f7;
	margin-top: 8px;
	font-size: 14px;
	font-weight: bold;
	line-height: 1.14;
	color: #222b45;
	box-shadow: unset;
`;

Wrapper.SocialItem = styled.a`
	display: flex;
	align-items: center;
	padding: 0 8px;
	text-decoration: none;
	margin-bottom: 16px;
	width: 100%;
	border-radius: 8px;
	padding: 8px;
	margin-bottom: 8px;
	&:hover {
		background-color: #f7f9fc;
		border-radius: 12px;
	}
	svg {
		width: 32px;
		height: 32px;
		fill: ${({ bg }) => bg};
	}
	p {
		margin-left: 8px;
		font-size: 15px;
		font-weight: 600;
		line-height: 1.6;
		color: #222b45;
	}
`;

Wrapper.LanguageRow = styled(Box)`
	padding: 0 8px;
`;

Wrapper.Language = styled(Text)`
	font-size: 15px;
	font-weight: 600;
	line-height: 1.6;
	color: #222b45;
	margin-bottom: 8px;
	padding-bottom: 8px;
	box-shadow: ${({ last }) => (last ? "0" : "0 0.5px 0 #c5cee0")};
`;
