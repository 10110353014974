import { Box, Button } from "@material-ui/core";
import styled, { css } from "styled-components";

export const IntegrationsContainer = styled(Box)`
	padding: 8px 4px;
	max-width: 1200px;
	margin: auto;

	${props => props.theme.breakpoints.up("sm")} {
		padding: 16px;
	}
`;

export const PageHeader = styled(Box)`
	margin-bottom: 40px;
	.typography {
		margin-bottom: 20px;
		h1 {
			margin-top: 0;
		}
	}
	.search-integration {
		position: relative;
		width: 100%;
		max-width: 320px;
		.search-integration-input {
			background-color: #fff;
			border-radius: 4px;
			&:focus {
				outline-color: #a4a6a9;
			}
		}
	}

	${props => props.theme.breakpoints.up("sm")} {
		display: flex;
		justify-content: space-between;
		align-items: center;
		.typography {
			max-width: 45%;
		}
	}
	${props => props.theme.breakpoints.up("lg")} {
		align-items: flex-end;
	}
`;

export const IntegrationRowContainer = styled(Box)`
	position: relative;
	margin-bottom: 20px;

	.header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		h5 {
			/* margin-left: 8px; */
		}
		button {
			color: #222b45;
			padding: 12px 0;
			margin: 0;
			background-color: transparent;
		}
	}
`;

export const ScrollableRow = styled(Box)`
	display: flex;
	padding: 10px 0;

	${({ noScroll }) =>
		noScroll
			? css`
					flex-wrap: wrap;
			  `
			: css`
					overflow-x: auto;
					scroll-behavior: smooth;
					-ms-overflow-style: none;
					scrollbar-width: none;
					&::-webkit-scrollbar {
						display: none;
					}
			  `}
`;

export const SliderArrows = styled(Button)`
	display: none;
	${props => props.theme.breakpoints.up("sm")} {
		height: 32px;
		width: 32px;
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		top: 40%;
		z-index: 10;
		border-radius: 99%;
		backdrop-filter: blur(20px);
		border: solid 0.5px #fff;
		background-color: rgb(195 189 189 / 24%);
		padding: 0;
		margin: 0;
		min-width: 0;
		&:hover {
			background-color: rgb(195 189 189 / 24%);
		}
		${props =>
			props.left &&
			css`
				left: -10px;
				transform: rotate(180deg);
			`}

		${props =>
			props.right &&
			css`
				right: -10px;
			`}
	}
`;

export const IntegrationCardContainer = styled(Box)`
	width: 80%;
	flex: 0 0 auto;
	padding: 0 8px;

	&:first-child {
		padding-left: 0;
	}

	.logo-container {
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: white;
		position: relative;
		border-radius: 12px;
		box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.1);
		width: 100%;
		height: 164px;

		.status {
			position: absolute;
			top: 4px;
			right: 4px;
			border-radius: 99%;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		${props =>
			props.showStatus &&
			props.integrationStatus &&
			props.integrationStatus === "connected" &&
			css`
				border: 1px solid green;
			`}

		${props =>
			props.showStatus &&
			props.integrationStatus &&
			props.integrationStatus === "disconnected" &&
			css`
				border: 1px solid red;
			`}

		img {
			width: 75%;
			margin: auto;
			object-fit: cover;
		}
	}

	.info {
		padding: 12px;
		a {
			font-size: 13px;
			color: #8f9bb3;
			text-decoration: none;
			margin-bottom: 4px;
			display: block;
			&:hover {
				text-decoration: underline;
			}
		}
		.title-status {
			display: flex;
			align-items: center;
			.status {
				margin-left: 8px;
				font-size: 12px;
				border-radius: 12px;
				padding: 4px 8px;
				&.green {
					color: #23b34a;
					background-color: #ddfcd5;
				}
				&.red {
					color: #ff463d;
					background-color: #ffe7d8;
				}
			}
		}
		h6 {
			font-size: 15px;
			font-weight: 600;
			color: #222b45;
		}
	}

	${props => props.theme.breakpoints.up("sm")} {
		width: 33.33%;
	}

	${props => props.theme.breakpoints.up("lg")} {
		width: 25%;
	}
`;

export const StyledPopupOverlay = styled.div`
	display: ${props => (props.open ? "block" : "none")};
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	background-color: rgba(25, 36, 53, 0.2);
	z-index: ${({ zIndex }) => (zIndex ? zIndex : 1301)};
`;

export const IntegrationDialogContainer = styled(Box)`
	.MuiDialogContent-root {
		padding: 0;
	}

	.MuiDialog-paperWidthSm {
		width: 520px;
	}
	.dialog-header {
		text-align: center;
		padding: 16px 0;
		border-bottom: 1px solid #edf1f7;
		position: relative;
		h4 {
			font-size: 18px;
			margin: 0;
		}
		.close-popup {
			position: absolute;
			right: 10px;
			top: 10px;
		}
	}
	.dialog-content {
		padding: 16px 40px 0;
		.integration-status {
			text-align: center;
			img {
				width: 160px;
			}
			.status-wrapper {
				width: auto;
				text-align: center;
				.status {
					font-size: 12px;
					border-radius: 12px;
					padding: 4px 8px;
					&.green {
						color: #23b34a;
						background-color: #ddfcd5;
					}
					&.red {
						color: #ff463d;
						background-color: #ffe7d8;
					}
				}
			}
		}
		.access-info {
			margin: 24px 0 16px;
			.input-wrapper {
				margin-bottom: 12px;
				display: flex;
				align-items: center;
				.access-field-input {
					flex: 1;
					padding-right: 8px;
				}
			}
			.access-field {
				display: flex;
				align-items: center;
				margin-bottom: 20px;
				font-size: 12px;
				.access-field-input {
					flex: 1;
					padding-right: 16px;
				}
			}
			&.more-rss {
				margin-top: 0;
				display: flex;
				align-items: center;
				button {
					padding: 0;
					margin: 0;
				}
				span {
					font-size: 13px;
					font-weight: 600;
					color: #8f9bb3;
				}
				svg {
					margin-right: 12px;
				}
			}
		}
	}
	.dialog-footer {
		padding: 0 12px 16px;
		display: flex;
		justify-content: flex-end;
		&.with-separator {
			border-top: 1px solid #edf1f7;
			margin-top: 4px;
			padding-top: 12px;
		}
	}

	${props => props.theme.breakpoints.up("sm")} {
		.dialog-content .integration-status {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
	}
`;
