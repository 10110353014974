import React, { FC, useCallback, useEffect, useState } from "react";

import { FileType } from "types/FilesContextValuesType";

import { getFileExtension } from "types/getFileExtension";

import { CreateFileDialog } from "shared/Components";
import { useFiles, useNotification } from "shared/hooks";
import { useS3Uploader } from "shared/services/s3Uploader";

import { ModelBlockType } from "../../../ModelBlock";
import { GroupSectionTemplate } from "../../components";

const defaultFilters = {
	limit: 10,
	keyword: ""
};

const FilesSection: FC<{ id: string; canCreate?: boolean; event?: boolean }> = ({ id, canCreate, event }) => {
	const {
		getFiles,
		getAllFilesCount,
		setFileInfoPopup,
		setFileUrl,
		setFileName,
		setFileExtension,
		getData: getFilesData
	} = useFiles();
	const { files, filesTotalCount, fileInfoPopup, fileUploadUrl, isLoading, shouldStopSearching } = getFilesData();

	const { showMessage } = useNotification();
	const { uploadFile } = useS3Uploader();

	const [fileList, setFileList] = useState<FileType[]>([]);
	const [offset, setOffset] = useState(1);

	useEffect(() => {
		getFiles({ ...defaultFilters, offset, groupId: !event ? id : undefined, eventId: event ? id : undefined });
	}, [event, getFiles, id, offset]);

	useEffect(() => {
		getAllFilesCount({ groupId: !event ? id : undefined, eventId: event ? id : undefined });
	}, [event, getAllFilesCount, id]);

	useEffect(() => {
		setFileList(fls => (offset === 1 ? files : fls.concat(files)));
	}, [files, offset]);

	const onUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { target } = e;
		const { files } = target;

		if (files?.length) {
			const extObj = getFileExtension(files[0].name);

			if (!extObj) {
				showMessage("We don't accept this file type, please try with a different file.");
				return;
			}

			uploadFile({
				file: files[0],
				communityName: fileUploadUrl,
				checkProgress: true,
				customCallback: data => setFileUrl(data?.publicUrl)
			});

			setFileName(files && files[0].name.slice(0, files[0].name.lastIndexOf(".")));

			setFileInfoPopup({ open: true, model: undefined });

			setFileExtension(extObj.ext);
		}
	};

	const handleUpload = useCallback(() => {
		const el: any = document.getElementById("trackFileUrl");
		if (el) {
			el.value = null;
			el.click();
		}
	}, []);

	const onEndScroll = useCallback(() => {
		if (!shouldStopSearching && !isLoading) {
			setOffset(x => x + 1);
		}
	}, [setOffset, isLoading, shouldStopSearching]);

	return (
		<>
			<GroupSectionTemplate
				loading={isLoading}
				autoFit={filesTotalCount > 3 && fileList.length > 3}
				items={fileList || []}
				type={ModelBlockType.file}
				actionButton={canCreate ? { title: "Create New File", onClick: handleUpload } : undefined}
				title="Files"
				noItemsErrorText="This group don’t have any files yet."
				onEndScroll={onEndScroll}
			/>
			<input id="trackFileUrl" type="file" onChange={onUpload} name="trackFileUrl" hidden accept="*" />
			<CreateFileDialog
				open={!!fileInfoPopup.open}
				groupId={!event ? id : undefined}
				eventId={event ? id : undefined}
				onClose={() => {
					setFileInfoPopup({ open: false });
				}}
			/>
		</>
	);
};

export default FilesSection;
