import React, { memo, useCallback, useEffect, useMemo, useState } from "react";

import { boardIcons } from "modules/Manage/View/Components/SelectIconField";

import { useBoards, useCommunity, useMemberRoutes } from "shared/hooks";
import { CategoryModel } from "shared/types";
import getCountMessage from "utils/getCountMessage";

import { Item } from "./style";

import { ItemButton } from "../List/style";
import { List } from "../index";

interface Props {
	categoriesUrl?: string;
	preview?: boolean;
	clickable?: boolean;
}

const Categories: React.FC<Props> = memo(({ categoriesUrl, preview, clickable }) => {
	const { getBoards, getBoardsCount, getData: getBoardsData } = useBoards();
	const { boards, boardsCount, isLoading } = getBoardsData();

	const { communityColors, getData: getCommunityData } = useCommunity();
	const { workspace } = getCommunityData();

	const { getMemberRoutesData } = useMemberRoutes();
	const { routes } = getMemberRoutesData();

	const [page, setPage] = useState(1);

	const color = useMemo(() => communityColors.primary, [communityColors.primary]);

	const pageSize = useMemo(() => (preview ? 5 : 30), [preview]);

	useEffect(() => {
		getBoards({ limit: preview ? 5 : pageSize, offset: preview ? 1 : page, paginated: true });
	}, [getBoards, workspace, preview, page, pageSize]);

	useEffect(() => {
		getBoardsCount({});
	}, [getBoardsCount, workspace]);

	const getIcon = useCallback(iconName => boardIcons.find(x => x.value === iconName), []);

	// const handleFollowBoard = useCallback(
	// 	async (board: CategoryModel) => {
	// 		setLoading(true);

	// 		try {
	// 			const { success } = await followBoard(board.cid, board.followed);

	// 			if (!success) {
	// 				throw new Error();
	// 			}

	// 			showMessage(`Successfully ${board.followed ? "unfollowed" : "followed"} board.`);

	// 			if (boards) {
	// 				setCategories(
	// 					boards.map(b => {
	// 						if (b.cid === board.cid) {
	// 							return { ...b, followed: board.followed ? false : true };
	// 						}

	// 						return b;
	// 					})
	// 				);
	// 			}
	// 		} catch {
	// 			showMessage(`Failed to ${board.followed ? "unfollow" : "follow"} board.`);
	// 		}

	// 		setLoading(false);
	// 	},
	// 	[boards, followBoard, showMessage]
	// );

	const ItemContents = useCallback(
		(board: CategoryModel) => (
			<Item.Wrapper to={`${routes?.member.board.getPath()}/${board.cid}`}>
				<Item.IconWrapper>
					<Item.Icon
						color={color || "#222b45"}
						className={
							getIcon(board.iconName)?.colorFirstPath
								? "color-first-path"
								: getIcon(board.iconName)?.colorAllPaths
								? "color-all-paths"
								: ""
						}
					>
						{getIcon(board.iconName)?.icon}
					</Item.Icon>
				</Item.IconWrapper>
				<Item.InfoBlock>
					<Item.Title>{board.name}</Item.Title>
					<Item.TopicCount>{getCountMessage(board.totalTopicCount, "post")}</Item.TopicCount>
				</Item.InfoBlock>
			</Item.Wrapper>
		),
		[color, getIcon, routes?.member.board]
	);

	const RenderItem = useCallback(
		(board: CategoryModel) =>
			clickable ? (
				ItemContents(board)
			) : (
				<ItemButton>
					{ItemContents(board)}
					{/* <Button
						className="follow"
						onClick={() => handleFollowBoard(board)}
						size="small"
						buttonTheme={board.followed ? "main" : "light"}
						disabled={loading}
					>
						{board.followed ? "Following" : "Follow"}
					</Button> */}
				</ItemButton>
			),
		[ItemContents, clickable]
	);

	const loadMore = () => {
		if (!isLoading) {
			setPage(page + 1);
		}
	};

	return (
		<List
			title={"Boards"}
			items={boards || []}
			preview={preview}
			seeAllLink={categoriesUrl}
			totalCount={boardsCount}
			renderItem={RenderItem}
			loading={isLoading}
			pageSize={pageSize}
			onLoadMore={loadMore}
		/>
	);
});

export default Categories;
