export enum ImageSizes {
	size50x50 = "50x50",
	size100x100 = "100x100",
	size200x200 = "200x200",
	size500x500 = "500x500"
}

export enum ProfilePhotoSizes {
	size50x50 = "50x50",
	size200x200 = "200x200",
	size500x500 = "500x500"
}

export enum StorySizes {
	size350x620 = "350x620",
	size490x660 = "490x660",
	size750x1000 = "750x1000",
	size900x1200 = "900x1200",
	size1200x1600 = "1200x1600"
}

export enum CoverPhotoSizes {
	size565x240 = "565x240"
}

export enum GroupCoverPhotoSizes {
	size100x100 = "100x100",
	size565x240 = "565x240"
}

export type RegularSizes = ImageSizes | ProfilePhotoSizes | StorySizes | CoverPhotoSizes | GroupCoverPhotoSizes;
