import TimeAgo from "react-timeago";
import styled from "styled-components";

import { Box, Text } from "shared/ui-kit";

export const Wrapper = styled(Box)`
	display: flex;
	align-items: flex-start;
`;

export const ContentBlock = styled(Box)`
	margin-left: 8px;

	width: calc(100% - 48px);

	&.subreply {
		width: fit-content;
		min-width: 280px;
	}
`;

export const Cmt = styled(Box);

Cmt.Body = styled(Box)`
	padding: 6px 16px 15px 16px;
	border-radius: 12px;
	background-color: #edf1f7;
`;

Cmt.InfoRow = styled(Box)`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

Cmt.InfoRowManageBlock = styled(Box)`
	display: flex;
	justify-content: flex-end;
	align-items: center;
`;

Cmt.AuthorImg = styled(Box)`
	width: 40px;
	height: 40px;

	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;

	border-radius: 50%;

	overflow: hidden;

	&.subreply {
		width: 24px;
		height: 24px;
	}
`;

Cmt.Autor = styled(Text)`
	font-size: 13px;
	font-weight: 600;
	line-height: 1.85;
	color: #222b45;
`;

Cmt.PostDate = styled(TimeAgo)`
	font-size: 12px;
	line-height: 1.33;
	letter-spacing: -0.1px;
	color: #8f9bb3;
`;

Cmt.Options = styled(Box)`
	margin-left: 8px;
`;

Cmt.Content = styled(Box)`
	margin-top: -1px;

	font-size: 15px;
	font-weight: normal;
	line-height: 1.33;
	color: #222b45;

	img {
		max-width: 240px;
		max-height: 240px;

		border-radius: 8px;

		overflow: hidden;

		margin-top: 16px;
	}

	video {
		max-width: 240px;
		max-height: 240px;

		border-radius: 8px;

		overflow: hidden;

		margin-top: 16px;
	}

	.author {
		font-weight: bold;
		font-style: inherit;
		font-size: inherit;
		color: inherit;
		text-decoration: none;
	}
`;

Cmt.ManageRow = styled(Box)`
	display: flex;
	align-items: center;

	padding-left: 16px;
`;

Cmt.ManageBlockWrapper = styled(Box)`
	margin: 0 16px 0 0;

	position: relative;

	cursor: pointer;

	user-select: none;
`;

Cmt.ManageText = styled(Text)<{ color: string }>`
	font-size: 13px;
	font-weight: 600;
	line-height: 1.85;
	color: ${({ color }) => color || "#8f9bb3"};
`;

Cmt.LikeList = styled(Box)`
	display: flex;
	align-items: center;
`;

Cmt.Reaction = styled(Box)`
	width: 16px;
	height: 16px;

	border-radius: 50%;

	overflow: hidden;

	border: 2px solid white;

	display: flex;
	justify-content: center;
	align-items: center;
	svg {
		fill: ${({ reactionColor }) => reactionColor || "#ffd16d"};
	}
`;

Cmt.RepliesBlock = styled(Box)`
	margin-top: 16px;
`;

Cmt.NewCommentWrapper = styled(Box)`
	margin: 16px auto 8px 0;

	width: fit-content;
	max-width: 100%;
`;

Cmt.CommentSkeletonWrapper = styled(Box)`
	margin-top: 8px;
`;

export const ReplyWrapper = styled(Box)`
	margin-bottom: 16px;
`;
