import React, { ReactNode, Suspense, lazy, useCallback, useEffect, useMemo, useRef, useState } from "react";

import DateFnsUtils from "@date-io/date-fns";
import { ClickAwayListener, IconButton, useMediaQuery } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";

import clsx from "clsx";
import { useHistory, useLocation, useParams } from "react-router-dom";

import { CommunityModel, UserRoles } from "types";

import { routes as onboardingRoutes } from "apps/Onboarding/constants";
import { routes as adminRoutes } from "apps/RegularUser/constants";

import { ReactComponent as CommentFilledIcon } from "assets/icons/comment-filled.svg";
import { ReactComponent as CommentIcon } from "assets/icons/comment.svg";

import { ReactComponent as CompassFilledIcon } from "assets/icons/compass-filled.svg";
import { ReactComponent as CompassIcon } from "assets/icons/compass.svg";

import { ReactComponent as ArrowDownIcon } from "assets/icons/icon-chevron-down.svg";
import { ReactComponent as GlobeIcon } from "assets/icons/icon-globe.svg";
import { ReactComponent as RadarIcon } from "assets/icons/liveconvo/radar-live.svg";
import { NotificationsCard } from "modules/Activity/View/Components";
import { useAuth } from "modules/App/Data";
import { useFirebase } from "modules/Messaging/Data";
import MessageCard from "modules/Messaging/View/Components/Messages/MessageCard";

import { JoinPopup, MemberNavPanel, NavPanel, SideMenu } from "shared/Components";
import CommunityLogo from "shared/Components/CommunityLogo";
import ProfileMenu from "shared/Components/ProfileMenu";
import {
	useCommunity,
	useFeature,
	useMarketing,
	useMemberRoutes,
	useNotification,
	usePersona,
	usePlayer,
	useReportContent,
	useRoutes,
	useSeries,
	useUser
} from "shared/hooks";
import useGuidedTour from "shared/hooks/useGuidedTour";
import { Box, Icon, Loader, Tooltip } from "shared/ui-kit";
import { useOffline } from "shared/ui-kit/hooks";
import * as appTheme from "theme/default";

import { NoInternet } from "./NoInternet";

import {
	ArrowIconWrapper,
	// BackButton,
	// BackButtonWrapper,
	ContentWrapper,
	IconWrapper,
	Main,
	MenuWrapper,
	ModalCardWrapper,
	PageContentWrapper,
	PageWrapper,
	SideBarOverlay,
	TopBar,
	Wrapper
} from "./style";

import { usePrevious } from "../../../utils/usePrevious";
import WelcomePopup from "../../Components/GuidedTour/WelcomePopup";
import { StepType } from "../../contexts/GuidedTour/GuideContext";
import useLocalStorage from "../../services/localStorage/localStorage";
import { navInfo } from "../constants";

const Checklist = lazy(() => import("../Checklist"));
const MemberNotification = lazy(() => import("modules/MemberHome/View/shared/MemberNotification"));
const BuyCoinsDialog = lazy(() => import("modules/MemberHome/View/shared/BuyCoinsDialog"));

const SubscribeDialog = lazy(() => import("modules/MemberHome/View/shared/SubscribeDialog"));
const UnlockConfirmation = lazy(() => import("modules/MemberHome/View/shared/UnlockConfirmation"));
const ConfirmActionModal = lazy(() => import("shared/Components/ConfirmActionModal"));
const ConfirmEmailBlocker = lazy(() => import("shared/Components/ConfirmEmailBlocker"));
const Snackbar = lazy(() => import("shared/ui-kit/atoms/Snackbar"));
const GuideTooltipBlock = lazy(() => import("shared/Components/GuidedTour/GuideTooltipBlock"));
const NotVerified = lazy(() => import("shared/Components/NotVerified"));
const TechnicalDifficulties = lazy(() => import("./TechnicalDifficulties"));

enum openWindows {
	notifications = "notification",
	messages = "messages"
}

interface BaseLayoutProps {
	children: ReactNode;
	isMember?: boolean;
}

const BaseLayout: React.FC<BaseLayoutProps> = React.memo(({ children, isMember = false }) => {
	const location = useLocation();
	const history = useHistory();

	const { getGlobal, get } = useLocalStorage();

	const { showMessage, getData: getNotificationData } = useNotification();
	const { message, isError } = getNotificationData();

	const isOffline = useOffline();

	const {
		setUser,
		setUserVerified,
		updateUserChatId,
		getData: getUserData,
		setIsMemberView,
		isPrivilegedRole,
		getUserIPDetails
	} = useUser();
	const { user, userVerified, ignoreGuidedTour } = getUserData();

	const { getDetails, isFeatureEnabled, isCommunityFeatureEnabled } = useFeature();
	const { getUser, checkOnboardingFields, checkUserVerification } = useAuth();
	const { refreshTokenIfExpired } = useRoutes();
	const { authenticateUser } = useFirebase();

	const { reportContent, setReportContentModal, getData: getReportContentData } = useReportContent();
	const { reportContentModal } = getReportContentData();

	const {
		getWorkspaceDetails,
		getAllMonetization,
		getCoinsList,
		getSubscriptionProducts,
		updateWorkspaceMetaState,
		setShowJoinPopup,
		getData: getCommunityData
	} = useCommunity();
	const { workspace, setupProgress, isWhitelabelAppMode, isAkinaMode, isReliasMode, isBitcoinMode } =
		getCommunityData();

	const { setPersona, getData: getPersonaData } = usePersona();
	const { persona } = getPersonaData();

	const localHostWorkspaceValue = get("workspace", "") as string;

	const { generateRoutes, getMemberRoutesData } = useMemberRoutes();
	const { workspace: generatedRoutesWorkspace, routes: memberRoutes } = getMemberRoutesData();

	const isMobile = useMediaQuery(appTheme.default.breakpoints.down("sm"));

	const { provideStepsRefs, setPassedGuidedTour, setShowWelcomeDialog, getData: getGuidedTourData } = useGuidedTour();
	const { tourStarted, currentStep, passedGuidedTour } = getGuidedTourData();

	const { workspaces } = getUserData();

	const { setPlayTracks, setAlbumPlaying } = usePlayer();
	const { getData: getSeriesData, setSeriesPlayState } = useSeries();
	const { seriesPlayState } = getSeriesData();

	// const { loginToCommunity } = useRoutes();

	const { setCurrentChat } = useFirebase();

	const { getWorkspaceByName } = useMarketing();

	const [sidebarOpened, setSidebarOpened] = useState(false);
	const [windowToShow, setWindowToShow] = useState<openWindows>();
	const [guideSteps, setGuideSteps] = useState<StepType[]>([]);
	const [unreadMessageCount, setUnreadMessageCount] = useState(0);
	const [isRefetchCommunityData, setIsRefetchCommunityData] = useState(true);

	const topBarRef = useRef<HTMLDivElement>(null);

	const prevWorkspaceName = usePrevious(workspace?.name);

	const isChatFeatureEnabled = useMemo(() => isFeatureEnabled("enableChat"), [isFeatureEnabled]);
	const isAppDownEnabled = useMemo(() => isFeatureEnabled("isAppDown"), [isFeatureEnabled]);

	const isLiveFeatureEnabled = useMemo(() => isCommunityFeatureEnabled("enableLiveTab"), [isCommunityFeatureEnabled]);

	const { communityName } = useParams() as { communityName?: string };

	const unreadNotificationCount = useMemo(
		() => (user?.profiles?.length ? user?.profiles[0]?.counters?.totalCount || 0 : 0),
		[user]
	);

	const showSideTopBar = useMemo(
		() =>
			!!workspace && isPrivilegedRole && !location.pathname.includes(onboardingRoutes.onboarding.community.getPath()),
		[workspace, isPrivilegedRole, location]
	);

	const handleSwitchCommunity = useCallback(() => {
		setPlayTracks({ idx: 0 });
		if (seriesPlayState) {
			setSeriesPlayState();
		}
		setAlbumPlaying();
		setCurrentChat();

		// loginToCommunity(workspace, true);
	}, [seriesPlayState, setAlbumPlaying, setPlayTracks, setSeriesPlayState, setCurrentChat]);

	useEffect(() => {
		getUserIPDetails();
	}, [getUserIPDetails]);

	useEffect(() => {
		if (localHostWorkspaceValue && localHostWorkspaceValue !== generatedRoutesWorkspace) {
			generateRoutes(localHostWorkspaceValue);
		}
	}, [generateRoutes, generatedRoutesWorkspace, localHostWorkspaceValue]);

	useEffect(() => {
		if (isMember && workspace?.name && communityName && workspace.name !== communityName && !!workspaces?.length) {
			const workspaceToJoin = workspaces.find(w => w.workspace === communityName);
			if (workspaceToJoin) {
				handleSwitchCommunity();
				setIsRefetchCommunityData(true);
			} else {
				getWorkspaceByName(communityName).then(workspace => {
					workspace && history.push(`${adminRoutes.community.getPath()}/${communityName}?switchCommunity=true`);
				});
			}
		}
	}, [isMember, communityName, workspace?.name, history, handleSwitchCommunity, workspaces, getWorkspaceByName]);

	useEffect(() => {
		showSideTopBar && setWindowToShow(undefined);
	}, [showSideTopBar]);

	useEffect(() => {
		setIsMemberView(isMember);
	}, [isMember, setIsMemberView]);

	useEffect(() => {
		const checkUser = () => {
			if (!isWhitelabelAppMode) {
				checkUserVerification().then(res => {
					if (res) {
						setUserVerified(res.verified);
						res.passedGuidedTourObject?.web && setPassedGuidedTour(res.passedGuidedTourObject.web);
					}
				});
			} else {
				getUser().then(async data => {
					if (data.profiles.length) {
						setUserVerified(!!data?.verified);

						const passedGuidedTourObject = getGlobal("akina_passed_guided_tour_object", {
							admin: false,
							member: false
						});
						setPassedGuidedTour(passedGuidedTourObject as { admin: boolean; member: boolean });
					}
				});
			}
		};

		if (!isWhitelabelAppMode) {
			checkUser();
		}

		if (!userVerified) {
			window.addEventListener("focus", checkUser);
		}

		return () => {
			window.removeEventListener("focus", checkUser);
		};
	}, [
		checkUserVerification,
		setPassedGuidedTour,
		setUserVerified,
		userVerified,
		isWhitelabelAppMode,
		getGlobal,
		getUser
	]);

	useEffect(() => {
		isBitcoinMode && setShowJoinPopup(true);
	}, [isBitcoinMode, setShowJoinPopup]);

	useEffect(() => {
		if (prevWorkspaceName && workspace?.name !== prevWorkspaceName) {
			setIsRefetchCommunityData(true);
		}
	}, [workspace?.name, prevWorkspaceName]);

	useEffect(() => {
		const authFBUser = async ({
			personaId,
			workspace,
			existing,
			updateUserChatIdData = false
		}: {
			personaId: number;
			workspace: Partial<CommunityModel>;
			existing: boolean;
			updateUserChatIdData?: boolean;
		}) => {
			try {
				return await authenticateUser(personaId, workspace, existing).then(res => {
					if (res && updateUserChatIdData) {
						updateUserChatId(personaId.toString(), res.user.uid);
					}
					return res?.user?.uid || "";
				});
			} catch (error) {
				const errorMessage = (error as Error).message;
				console.error(errorMessage);
				return "";
			}
		};

		const fetchData = async () => {
			const newWorkspaceInfo = await getWorkspaceDetails();
			getAllMonetization();
			getCoinsList();
			getSubscriptionProducts();

			getUser()
				.then(async data => {
					checkOnboardingFields(data, newWorkspaceInfo?.name || "");
					refreshTokenIfExpired(data, newWorkspaceInfo?.name || "");
					const userUid = await authFBUser({
						personaId: data.profiles[0].personaId,
						workspace: newWorkspaceInfo as CommunityModel,
						existing: !!data.profiles[0].chatUserId,
						updateUserChatIdData: !data?.profiles?.length || !data?.profiles[0]?.chatUserId
					});
					setPersona(data.profiles[0]);
					setUser({ ...data, profiles: [{ ...data.profiles[0], chatUserId: userUid }] });

					if (isWhitelabelAppMode) {
						setUserVerified(!!data?.verified);

						const passedGuidedTourObject = getGlobal("akina_passed_guided_tour_object", {
							admin: false,
							member: false
						});
						setPassedGuidedTour(passedGuidedTourObject as { admin: boolean; member: boolean });
					}
				})
				.catch(err => {
					console.log("err!", err);
				});

			getDetails().then(({ meta }) => {
				updateWorkspaceMetaState(meta);
			});
		};

		if (isRefetchCommunityData) {
			fetchData();
			setIsRefetchCommunityData(false);
		}
	}, [
		isRefetchCommunityData,
		getWorkspaceDetails,
		workspace?.name,
		getAllMonetization,
		getCoinsList,
		getSubscriptionProducts,
		authenticateUser,
		checkOnboardingFields,
		getGlobal,
		getUser,
		isWhitelabelAppMode,
		refreshTokenIfExpired,
		setPassedGuidedTour,
		setPersona,
		setUser,
		setUserVerified,
		updateUserChatId,
		getDetails,
		updateWorkspaceMetaState
	]);

	useEffect(() => {
		if (user && (!passedGuidedTour.admin || !passedGuidedTour.member) && !tourStarted && !ignoreGuidedTour) {
			setShowWelcomeDialog({
				admin: !passedGuidedTour.admin,
				member: !passedGuidedTour.member
			});
		}
	}, [ignoreGuidedTour, passedGuidedTour, setShowWelcomeDialog, tourStarted, user]);

	useEffect(() => {
		if (!tourStarted || ignoreGuidedTour) {
			(document.getElementById("root") as HTMLElement).style.removeProperty("overflow-y");
			document?.getElementById("scrollWrapper")?.classList.add("ps");
		}
	}, [tourStarted, ignoreGuidedTour]);

	useEffect(() => {
		const calculateStepsRects = () => {
			const dataPages = document.querySelectorAll("[data-step]") as NodeListOf<HTMLElement> | undefined;
			const topNavItems = document.querySelectorAll("[data-topnav]") as NodeListOf<HTMLElement> | undefined;
			const rects: StepType[] = [];

			dataPages?.forEach(p => {
				const { bottom, height, left, right, top, width } = p.getBoundingClientRect();
				rects.push({
					bottom,
					height,
					left,
					right,
					top,
					width,
					step: p.dataset["step"],
					stepEl: p,
					fullRing: isMember
				});
			});

			topNavItems?.forEach(p => {
				const { bottom, height, left, right, top, width } = p.getBoundingClientRect();
				rects.push({ bottom, height, left, right, top, width, step: p.dataset["topnav"], stepEl: p, fullRing: true });
			});

			if (rects.length) {
				setGuideSteps(rects);
			}
		};

		if (tourStarted) {
			setTimeout(() => {
				calculateStepsRects();
				window.addEventListener("resize", calculateStepsRects);

				const nav = document.querySelector("#navpanel-wrapper");
				if (nav) nav.addEventListener("scroll", calculateStepsRects);

				return () => {
					window.removeEventListener("resize", calculateStepsRects);
					nav?.removeEventListener("scroll", calculateStepsRects);
				};
			}, 300);
		}
	}, [isMember, tourStarted]);

	useEffect(() => {
		if (tourStarted && isMobile) {
			if (isMember) {
				setSidebarOpened(currentStep === "profile");
			} else {
				setSidebarOpened(
					currentStep !== "switcher" &&
						currentStep !== "checklist" &&
						currentStep !== "notifications" &&
						currentStep !== "profile"
				);
			}
		}
	}, [currentStep, isMobile, tourStarted, isMember]);

	useEffect(() => {
		provideStepsRefs(guideSteps);
	}, [guideSteps, provideStepsRefs]);

	// useEffect(() => {
	// 	setSidebarOpened(false);
	// }, [location]);

	useEffect(() => {
		!isMobile && setSidebarOpened(false);
	}, [isMobile, sidebarOpened, location]);

	const showBack = useMemo(
		() =>
			isMember &&
			(location.pathname === memberRoutes?.member.messaging.getPath()
				? !isMobile
				: location.pathname !== memberRoutes?.member.home.getPath()),
		[isMember, location.pathname, memberRoutes?.member.messaging, memberRoutes?.member.home, isMobile]
	);

	const isNotificationsActive = useMemo(
		() =>
			windowToShow === openWindows.notifications || location.pathname === memberRoutes?.member.notifications.getPath(),
		[location.pathname, memberRoutes?.member.notifications, windowToShow]
	);

	const isMessagingActive = useMemo(
		() => windowToShow === openWindows.messages || location.pathname === memberRoutes?.member.messaging.getPath(),
		[location.pathname, memberRoutes?.member.messaging, windowToShow]
	);

	const isLiveConvoActive = useMemo(
		() => location.pathname.startsWith(memberRoutes?.member.liveconversation.getPath()),
		[location.pathname, memberRoutes?.member.liveconversation]
	);

	const openInNewTab = useCallback(() => {
		if (workspace?.communityPageUrl) {
			const newWindow = window.open(workspace.communityPageUrl, "_blank", "noopener,noreferrer");
			if (newWindow) newWindow.opener = null;
		}
	}, [workspace]);

	const toggleSidebarCollapsed = useCallback(
		e => {
			e.stopPropagation();
			setSidebarOpened(!sidebarOpened);
		},
		[sidebarOpened]
	);

	const isMessagingPage = useMemo(() => location.pathname === adminRoutes.messaging.getPath(), [location.pathname]);

	const defaultIconColor = useMemo(() => {
		return isAkinaMode && isMember ? "#fff" : "#8f9bb3";
	}, [isAkinaMode, isMember]);

	const SideMenuBlock = useMemo(
		() => (
			<SideMenu>
				{isWhitelabelAppMode ? (
					<IconWrapper className="brand-image">
						{isMobile && !isMember && (
							<IconButton
								onClick={toggleSidebarCollapsed}
								color="primary"
								aria-label="collapse side navigation menu"
								component="span"
							>
								<Icon group={"filled"} fill={"#747474"} name={sidebarOpened ? "close" : "menu"} />
							</IconButton>
						)}
						{isAkinaMode && <i className={"akina-logo"} />}
						{isReliasMode && <i className={"relias-logo"} />}
						{isBitcoinMode && <i className={"bitcoin-logo"} />}
					</IconWrapper>
				) : (
					<IconWrapper className="brand-image">
						{isMobile && !isMember && (
							<IconButton
								onClick={toggleSidebarCollapsed}
								color="primary"
								aria-label="collapse side navigation menu"
								component="span"
							>
								<Icon group={"filled"} fill={"#747474"} name={sidebarOpened ? "close" : "menu"} />
							</IconButton>
						)}
						<CommunityLogo />
						{!isMember && (
							<Tooltip text="Expand">
								<ArrowIconWrapper>
									<ArrowDownIcon />
								</ArrowIconWrapper>
							</Tooltip>
						)}
					</IconWrapper>
				)}
			</SideMenu>
		),
		[
			isMobile,
			isMember,
			sidebarOpened,
			toggleSidebarCollapsed,
			isWhitelabelAppMode,
			isAkinaMode,
			isReliasMode,
			isBitcoinMode
		]
	);

	const workspaceBtnColor = useMemo(
		() => workspace?.meta?.theme?.primaryBtnColor,
		[workspace?.meta?.theme?.primaryBtnColor]
	);

	const QuickActionBar = useMemo(() => {
		const convoListPath = memberRoutes?.member.liveconversation.list.getPath();
		return (
			workspace && (
				<>
					<TopBar.CommunitiesList data-step={isMobile ? "switcher" : null}>{SideMenuBlock}</TopBar.CommunitiesList>
					<TopBar.ActionList className={isMember ? "member-view" : ""}>
						{isMember && isLiveFeatureEnabled && (
							<TopBar.ActionIconWrapper color={workspaceBtnColor} className={clsx(isLiveConvoActive && "active")}>
								<TopBar.ActionLink to={convoListPath} className={"header__action-icon-wrapper"} data-topnav="liveconvo">
									<Tooltip text="Live Conversations">
										<RadarIcon
											className="navSvg"
											fill={location.pathname === convoListPath ? workspaceBtnColor : defaultIconColor}
										/>
									</Tooltip>
								</TopBar.ActionLink>
							</TopBar.ActionIconWrapper>
						)}
						{isMember && (
							<TopBar.ActionIconWrapper
								className={clsx("header__action-icon-wrapper", !showBack && "active")}
								data-topnav="discovery"
								color={workspaceBtnColor}
							>
								<TopBar.ActionLink to={memberRoutes?.member.home.getPath()}>
									<Tooltip text="Visit Discover page">
										{!showBack ? (
											<CompassFilledIcon className="navSvg" />
										) : (
											<CompassIcon className="navSvg" fill={defaultIconColor} />
										)}
									</Tooltip>
								</TopBar.ActionLink>
							</TopBar.ActionIconWrapper>
						)}

						{isChatFeatureEnabled && (
							<ClickAwayListener
								onClickAway={() => windowToShow === openWindows.messages && setWindowToShow(undefined)}
							>
								<TopBar.ActionIconWrapper
									className={clsx("header__action-icon-wrapper position-initial", isMessagingActive && "active")}
									data-topnav="messages"
									color={workspaceBtnColor}
								>
									<TopBar.ButtonAction onClick={() => setWindowToShow(windowToShow ? undefined : openWindows.messages)}>
										<TopBar.NotificationCountWrapper className={unreadMessageCount ? "show" : "hide"}>
											{unreadMessageCount}
										</TopBar.NotificationCountWrapper>
										<Tooltip text="Messages">
											{isMessagingActive ? (
												<CommentFilledIcon className="navSvg" />
											) : (
												<CommentIcon className="navSvg" />
											)}
										</Tooltip>
									</TopBar.ButtonAction>
									<ModalCardWrapper show={windowToShow === openWindows.messages}>
										<MessageCard
											show={windowToShow === openWindows.messages}
											messagingPageUrl={adminRoutes.messaging.getPath()}
											onChangeUnreadCount={count => {
												setUnreadMessageCount(count);
											}}
											onClose={() => setWindowToShow(undefined)}
										/>
									</ModalCardWrapper>
								</TopBar.ActionIconWrapper>
							</ClickAwayListener>
						)}
						<ClickAwayListener
							onClickAway={() => windowToShow === openWindows.notifications && setWindowToShow(undefined)}
						>
							<TopBar.ActionIconWrapper
								className={clsx("header__action-icon-wrapper", isNotificationsActive && "active")}
								data-topnav="notifications"
								color={workspaceBtnColor}
							>
								<TopBar.ButtonAction
									onClick={() => setWindowToShow(windowToShow ? undefined : openWindows.notifications)}
								>
									<TopBar.NotificationCountWrapper className={unreadNotificationCount ? "show" : "hide"}>
										{unreadNotificationCount <= 99 ? unreadNotificationCount : "99+"}
									</TopBar.NotificationCountWrapper>
									<Tooltip text={"Notifications"}>
										<Icon
											group={isNotificationsActive ? "filled" : "linear"}
											fill={isNotificationsActive ? workspaceBtnColor : defaultIconColor}
											width={24}
											height={24}
											name={"bell"}
											className="navSvg"
										/>
									</Tooltip>
								</TopBar.ButtonAction>
								<ModalCardWrapper show={windowToShow === openWindows.notifications}>
									<NotificationsCard
										preview
										open={windowToShow === openWindows.notifications}
										notificationPageUrl={memberRoutes?.member.notifications.getPath()}
										onClose={() => setWindowToShow(undefined)}
									/>
								</ModalCardWrapper>
							</TopBar.ActionIconWrapper>
						</ClickAwayListener>
						{!isMember && !isBitcoinMode && (
							<TopBar.ActionIconWrapper className={"header__action-icon-wrapper"} color={workspaceBtnColor}>
								<TopBar.ButtonAction onClick={openInNewTab}>
									<Tooltip text="Visit community page">
										<GlobeIcon className="navSvg" />
									</Tooltip>
								</TopBar.ButtonAction>
							</TopBar.ActionIconWrapper>
						)}
						{isMember && (
							<TopBar.MenuWrapper>
								<IconButton
									onClick={toggleSidebarCollapsed}
									color="primary"
									aria-label="collapse side navigation menu"
									component="span"
								>
									<Icon
										group={""}
										fill={sidebarOpened ? workspaceBtnColor : defaultIconColor}
										name={"menu"}
										width={18}
									/>
								</IconButton>
							</TopBar.MenuWrapper>
						)}
						<TopBar.ProfileWrapper className={isMember ? "" : "admin-view"}>
							<ProfileMenu
								isMember={isMember}
								profile={persona ? persona : !!user?.profiles?.length ? user.profiles[0] : undefined}
							/>
						</TopBar.ProfileWrapper>
					</TopBar.ActionList>
				</>
			)
		);
	}, [
		memberRoutes,
		workspace,
		isMobile,
		SideMenuBlock,
		isMember,
		isLiveFeatureEnabled,
		location.pathname,
		workspaceBtnColor,
		defaultIconColor,
		showBack,
		isChatFeatureEnabled,
		windowToShow,
		unreadMessageCount,
		unreadNotificationCount,
		openInNewTab,
		toggleSidebarCollapsed,
		sidebarOpened,
		persona,
		user?.profiles,
		isNotificationsActive,
		isMessagingActive,
		isLiveConvoActive,
		isBitcoinMode
	]);

	const isMessagingView = useMemo(() => location.pathname === adminRoutes.messaging.getPath(), [location.pathname]);

	const confirmReport = useCallback(async () => {
		if (reportContentModal) {
			const { reportContentId, reportType, reason, customCallback } = reportContentModal;

			setReportContentModal(undefined);

			await reportContent({
				reporteeId: reportContentId || "",
				reportType: reportType || "",
				reason: reason || ""
			});

			customCallback && customCallback();
		}
	}, [reportContentModal, reportContent, setReportContentModal]);

	return !user?.profiles.length ? (
		<Box display="flex" alignItems="center" justifyContent="center" height="100vh">
			<Loader color="primary" show size="4rem" thickness={3} value={0} variant="indeterminate" />
		</Box>
	) : (
		<>
			{!userVerified && user.gracePeriodExpired && (
				<Suspense fallback={null}>
					<ConfirmEmailBlocker />
				</Suspense>
			)}
			<WelcomePopup isMember={isMember} />
			<PageWrapper
				className={`${
					isAkinaMode
						? "workspace-kijijimoms"
						: isReliasMode
						? "workspace-holliblu"
						: isBitcoinMode
						? "workspace-bitcoin"
						: ""
				}`}
			>
				{tourStarted && (
					<Suspense fallback={null}>
						<GuideTooltipBlock isMember={isMember} />
					</Suspense>
				)}
				<TopBar
					ref={topBarRef}
					className={`${isMember ? "header_member" : ""} ${
						isAkinaMode
							? "workspace-kijijimoms"
							: isReliasMode
							? "workspace-holliblu"
							: isBitcoinMode
							? "workspace-bitcoin"
							: ""
					}`}
				>
					<TopBar.Container className={`header__container ${isMember ? "" : "admin-view"}`}>
						<TopBar.LeftSide className={"header__left-side"} data-step={!isMobile && "switcher"}>
							{SideMenuBlock}
						</TopBar.LeftSide>
						<TopBar.SearchBarWrapper>{/*<Search filled placeholder="Search" />*/}</TopBar.SearchBarWrapper>
						<TopBar.RightSide className={"header__right-side"}>{QuickActionBar}</TopBar.RightSide>
					</TopBar.Container>
					{!sidebarOpened && !isMember && (
						<TopBar.NotVerifiedWrapper>
							<Suspense fallback={null}>
								<NotVerified />
							</Suspense>
						</TopBar.NotVerifiedWrapper>
					)}
				</TopBar>
				<Main stickyTop={topBarRef.current?.clientHeight}>
					{isOffline ? (
						<NoInternet />
					) : isAppDownEnabled ? (
						<Suspense fallback={null}>
							<TechnicalDifficulties />
						</Suspense>
					) : (
						<>
							{isMember ? (
								<MenuWrapper className={`menu-wrapper ${sidebarOpened ? "open" : ""}`}>
									<MemberNavPanel
										adminHomeUrl={adminRoutes.dashboard.getPath()}
										profile={!!user?.profiles?.length ? user.profiles[0] : undefined}
										profilePageUrl={
											user.role === UserRoles.Member
												? memberRoutes?.member.profile.getPath()
												: adminRoutes.settings.profile.getPath()
										}
										onClose={() => setSidebarOpened(false)}
									/>
								</MenuWrapper>
							) : (
								<>
									{isMobile && (
										<SideBarOverlay
											onClick={() => setSidebarOpened(false)}
											open={true}
											className={sidebarOpened ? "" : "active"}
										>
											{showSideTopBar && (
												<NavPanel
													stickyTop={topBarRef.current?.clientHeight}
													sidebarCollapsed={!sidebarOpened}
													items={navInfo}
													setupProgress={setupProgress || 0}
													memberHomeUrl={memberRoutes?.member.home.getPath()}
												/>
											)}
										</SideBarOverlay>
									)}
								</>
							)}
							<ContentWrapper>
								{/* {showBack && (
									<BackButtonWrapper>
										<BackButton
											leftIcon={<Icon group={"filled"} fill={"black"} name={"arrow-left"} />}
											palette={"basic"}
											buttonTheme="light"
											onClick={() => {
												if (history.location.key) return history.go(-1);
												history.push(memberRoutes?.member.home.getPath());
											}}
											size={"small"}
										>
											Back
										</BackButton>
									</BackButtonWrapper>
								)} */}
								{!isMobile && showSideTopBar && !isMember && (
									<NavPanel
										stickyTop={topBarRef.current?.clientHeight}
										sidebarCollapsed={false}
										items={navInfo}
										setupProgress={setupProgress || 0}
										memberHomeUrl={memberRoutes?.member.home.getPath()}
									/>
								)}
								<MuiPickersUtilsProvider utils={DateFnsUtils}>
									<Wrapper className={!isMember ? "admin-view" : ""}>
										<PageContentWrapper
											className={`${isMember ? "member-view" : ""} ${isMessagingView ? "messagingView" : ""}`}
										>
											{children}
										</PageContentWrapper>
									</Wrapper>
								</MuiPickersUtilsProvider>
							</ContentWrapper>
						</>
					)}
				</Main>
			</PageWrapper>
			{isMember && <JoinPopup allowToClose={!isBitcoinMode} setShowJoinPopup={setShowJoinPopup} />}
			{!isMember && !isMessagingPage && !isBitcoinMode && (
				<Suspense fallback={null}>
					<Checklist />
				</Suspense>
			)}
			{isMember ? (
				<>
					{!!message && (
						<Suspense fallback={null}>
							<MemberNotification message={message} open onClose={() => showMessage("")} isError={isError} />
						</Suspense>
					)}
					<Suspense fallback={null}>
						<>
							<BuyCoinsDialog />
							<SubscribeDialog />
							<UnlockConfirmation />
						</>
					</Suspense>
					{!!reportContentModal && (
						<Suspense fallback={null}>
							<ConfirmActionModal
								closeText="Cancel"
								open
								title="Report Content?"
								bodyText={`Are you sure you want to mark this ${reportContentModal?.reportType} ${
									reportContentModal?.reportContentName && `"${reportContentModal?.reportContentName}"`
								} as ${reportContentModal?.reason}`}
								onClose={() => setReportContentModal(undefined)}
								onConfirm={confirmReport}
							/>
						</Suspense>
					)}
				</>
			) : (
				<>
					{!!message && (
						<Suspense fallback={null}>
							<Snackbar open message={message} onClose={() => showMessage("")} showCloseButton transition="fade" />
						</Suspense>
					)}
				</>
			)}
		</>
	);
});

export default BaseLayout;
