import React, { memo, useCallback, useEffect, useMemo } from "react";

import { useMediaQuery } from "@material-ui/core";

import { ForumPostModel, PostScheduleType } from "types";

import { CreateUpdatePost } from "modules/Post/View";
import { SwitchRoleViewBtn } from "shared/Components";
import SmartScroll from "shared/Components/SmartScroll";
import { useBoards, useCommunity, usePersona, usePosts, useScrollbar, useUser } from "shared/hooks";
import { Box } from "shared/ui-kit";
import * as appTheme from "theme/default";

import MainSection from "./MainSection";
import { BoxItemWrapper, Column, Container } from "./style";

import { AddPostBtn, CategoriesCard, ContributorsCard, Hashtags, Menu } from "../../Components";

export interface DashboardPageProps {
	adminPanelUrl: string;
	routes: any;
}

const SetupPage: React.FC<DashboardPageProps> = memo(({ adminPanelUrl, routes }) => {
	const { getData: getUserData } = useUser();
	const { user, userVerified } = getUserData();

	const { getData: getBoardsData } = useBoards();
	const { boardsCount } = getBoardsData();

	const { getPosts, updatePostsList, setCreateUpdatePostDialog, getData: getPostsData } = usePosts();
	const { createUpdatePostDialog } = getPostsData();

	const { getWorkspaceDetails, getAllMonetization, getData: getCommunityData } = useCommunity();
	const { workspace, monetizationPlans, isAkinaMode, isReliasMode, isBitcoinMode } = getCommunityData();

	const { setPersona, getData: getPersonaData } = usePersona();
	const { persona } = getPersonaData();

	const isMobile = useMediaQuery(appTheme.default.breakpoints.down("sm"));
	const { marginTopOnScroll } = useScrollbar();

	const scrollbarId = "rightScrollbar";

	useEffect(() => {
		marginTopOnScroll("1rem", scrollbarId);
	}, [marginTopOnScroll]);

	useEffect(() => {
		if (!workspace) {
			getWorkspaceDetails();
		}
	}, [getWorkspaceDetails, workspace]);

	useEffect(() => {
		if (!monetizationPlans) {
			getAllMonetization();
		}
	}, [getAllMonetization, monetizationPlans]);

	const adminBtn = useMemo(() => {
		return user?.isGlobalAdmin || user?.isGlobalOwner || user?.isGlobalModerator ? (
			<BoxItemWrapper>
				<SwitchRoleViewBtn label={"Admin Panel"} link={adminPanelUrl} buttonId="adminPanel" />
			</BoxItemWrapper>
		) : null;
	}, [user, adminPanelUrl]);

	const handlePostChanged = useCallback(
		async ({ isEdited, postInfo }: { isEdited: boolean; postInfo?: ForumPostModel }) => {
			setCreateUpdatePostDialog({ isOpen: false });
			if (!isEdited && !postInfo) {
				await getPosts({ postType: PostScheduleType.Posted, limit: 10, offset: 1 });
			} else if (postInfo) {
				updatePostsList({ postInfo, isNew: !isEdited });
			}

			if (!isEdited && persona && !persona.counters.postCount) {
				setPersona({
					...persona,
					counters: {
						...persona.counters,
						postCount: 1
					}
				});
			}
		},
		[getPosts, updatePostsList, persona, setCreateUpdatePostDialog, setPersona]
	);

	if (!workspace || !user) {
		return null;
	}

	return (
		<>
			<Container>
				{!isMobile && (
					<Column data-step="menu" left className={isAkinaMode && "akina-mode"}>
						{adminBtn}
						<BoxItemWrapper>
							<Menu />
						</BoxItemWrapper>
					</Column>
				)}
				<Column middle>{!isBitcoinMode && <MainSection routes={routes} />}</Column>
				{!isMobile && (
					<Column right className={isAkinaMode && "akina-mode"}>
						<SmartScroll height={"calc(100vh - 50px)"} scrollbarId={scrollbarId}>
							<Box>
								<BoxItemWrapper className="mt-4">
									<CategoriesCard clickable preview categoriesUrl={routes?.member.categories.getPath()} />
								</BoxItemWrapper>
								<BoxItemWrapper>
									<ContributorsCard preview contributorsUrl={routes?.member.contributors.getPath()} />
								</BoxItemWrapper>
								<BoxItemWrapper>
									<Hashtags hashtagsUrl={routes?.member.hashtags.getPath()} />
								</BoxItemWrapper>
							</Box>
						</SmartScroll>
					</Column>
				)}
			</Container>
			{createUpdatePostDialog?.isOpen && (
				<CreateUpdatePost onCreateUpdatePost={handlePostChanged} pid={createUpdatePostDialog?.editablePostPid} />
			)}
			{!!boardsCount && isReliasMode ? user.verified : userVerified && <AddPostBtn />}
		</>
	);
});

export default SetupPage;
