import { IStateProps } from "utils/StateOf";

import createStore from "utils/createStore";

export interface StepType {
	step: string | undefined;
	top: number;
	left: number;
	right: number;
	bottom: number;
	width: number;
	height: number;
	stepEl: HTMLElement;
	fullRing?: boolean;
}

export interface Values {
	tourStarted: boolean;
	// more pages later.
	tourPage: "dashboard" | "settings";
	currentStep: string;
	steps: StepType[];
	showWelcomeDialog?: { admin: boolean; member: boolean };
	stepsContent: {
		step: string;
		headline: string;
		content: string;
		ignoreOnMobile?: boolean;
	}[];
	popupHeight: number;
	displayPopup: boolean;
	passedGuidedTour: {
		admin: boolean;
		member: boolean;
	};
	checklistOpen: boolean;
	checklistAutoOpen: boolean;
}

export const initialState: IStateProps<Values> = {
	tourStarted: false,
	tourPage: "dashboard",
	currentStep: "switcher",
	steps: [],
	stepsContent: [],
	popupHeight: 0,
	displayPopup: true,
	passedGuidedTour: {
		admin: true,
		member: true
	},
	checklistOpen: false,
	checklistAutoOpen: false
};

export default createStore<Values>({
	initialState
});
