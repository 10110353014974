import React from "react";

import styled, { css } from "styled-components";

import { Box } from "shared/ui-kit";

import { FrameCircle, FrameInfoRow } from "./index";

interface FrameBlockProps {
	color: string;
	customStyle?: string;
}

const FrameBlockBody = styled(Box)`
	display: flex;
	flex-direction: column;
	flex-grow: 1;

	padding-left: 10px;
`;

const FrameBlockWrapper = styled(Box)<FrameBlockProps>`
	width: 100%;
	display: flex;

	${({ customStyle }) => {
		return customStyle
			? css`
					${customStyle}
			  `
			: "";
	}}
`;

const FrameBlock: React.FC<FrameBlockProps> = (props: FrameBlockProps): JSX.Element => {
	return (
		<FrameBlockWrapper {...props}>
			<FrameCircle color={props.color} />
			<FrameBlockBody>
				<FrameInfoRow customStyle={"width: 30%; height: 12px;"} />
				<FrameInfoRow customStyle={"width: 100%; height: 30px;"} />
			</FrameBlockBody>
		</FrameBlockWrapper>
	);
};

export default FrameBlock;
