import React, { useCallback, useEffect, useMemo, useState } from "react";

import { useMediaQuery } from "@material-ui/core";

import { CreateVideo } from "shared/Components";
import { useNotification, useUser, useVideo } from "shared/hooks";
import { useS3Uploader } from "shared/services/s3Uploader";
import { VideoFilterType, VideoSortBy } from "shared/types";
import * as appTheme from "theme/default";

import { getValidationMessage } from "utils/getValidationMessage";
import { dataUrlToFile, filterFiles } from "utils/serviceUtils/helpers";

import { FilterBlock, FilterOptionType, ModelBlock, PageWrapper } from "../../Components";
import { ModelBlockType } from "../../Components/ModelBlock";
import { ManInLotus, MessageText, NoConnectionsBlock, StyledButton } from "../Connections/style";
import { TracksFilters } from "../Tracks";

const defaultFilters = {
	limit: 20,
	keyword: "",
	sortBy: VideoSortBy.timestamp
};

export interface VideosPageProps {
	groupId?: string;
}

const VideosPage: React.FC<VideosPageProps> = ({ groupId }) => {
	const {
		getVideos,
		getAllVideoCount,
		getData: getVideoData,
		setVideoDuration,
		setVideoFileUrl,
		setVideoName,
		setVideoInfoPopup
	} = useVideo();
	const { filteredVideos, allVideoCount, loading, videoUploadUrl, videoInfoPopup } = getVideoData();
	const { uploadFile } = useS3Uploader();

	const [filters, setFilters] = useState<VideoFilterType>({ offset: 1, sortBy: VideoSortBy.timestamp });

	const { showMessage } = useNotification();
	const hasMoreItems = useMemo(() => allVideoCount > filteredVideos.length, [allVideoCount, filteredVideos.length]);
	const isMobile = useMediaQuery(appTheme.default.breakpoints.down("sm"));

	useEffect(() => {
		getVideos({ ...defaultFilters, ...filters, paginate: true, groupId });
	}, [getVideos, filters, groupId]);

	useEffect(() => {
		getAllVideoCount({ groupId, personaDocId: filters.personaDocId });
	}, [getAllVideoCount, groupId, filters]);

	const onEndScroll = useCallback(() => {
		if (hasMoreItems && !loading) {
			setFilters(ctx => ({ ...ctx, offset: ctx.offset + 1 }));
		}
	}, [hasMoreItems, loading]);

	const correctFileTypes = ["video/*"];

	const handleUpload = useCallback(() => {
		const el: any = document.getElementById("videoFileUrl");
		if (el) {
			el.value = null;
			el.click();
		}
	}, []);

	const onUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
		const { target } = e;
		const { files } = target;

		if (files) {
			const filteredFiles = filterFiles(files, correctFileTypes);

			const videoFile = !!filteredFiles?.length ? files[0] : null;

			if (videoFile) {
				if (videoFile.size / 1024 / 1024 > 2048) {
					showMessage(
						getValidationMessage({
							name: "File size is too large. Use file that less than 2GB.",
							emoji: "⚠️"
						})
					);
					return;
				}
				const correctFile = typeof videoFile === "string" ? await dataUrlToFile(videoFile, "test") : videoFile;

				const video = document.createElement("video");
				video.preload = "metadata";

				video.onloadedmetadata = function () {
					window.URL.revokeObjectURL(video.src);

					if (video.duration < 1) return;

					setVideoDuration(`${parseInt(`${video.duration}`)}`);
				};

				video.src = URL.createObjectURL(correctFile);

				setVideoFileUrl("");

				uploadFile({
					file: correctFile as File,
					communityName: videoUploadUrl,
					checkProgress: true,
					customCallback: data => setVideoFileUrl(data?.publicUrl)
				});

				setVideoName(files && files[0].name.slice(0, files[0].name.lastIndexOf(".")));

				setVideoInfoPopup({ open: true, model: undefined });
			}
		}
	};

	const { getData: getUserData } = useUser();

	const { user } = getUserData();

	const filterOptions: FilterOptionType[] = useMemo(
		() => [
			{
				inlineOptions: true,
				onClick: item => {
					switch (item) {
						case TracksFilters.MOST_POPULAR:
							return setFilters({ sortBy: VideoSortBy.popular, offset: 1 });
						case TracksFilters.MY_TRACKS:
							return setFilters({ sortBy: VideoSortBy.timestamp, personaDocId: user?.personaDocId, offset: 1 });
						default:
							setFilters({ sortBy: VideoSortBy.timestamp, offset: 1 });
					}
				},
				listOptions: [
					{
						label: "Most Recent",
						value: TracksFilters.MOST_RECENT,
						default: true
					},
					{
						label: "Most Popular",
						value: TracksFilters.MOST_POPULAR
					},
					{
						label: "My Videos",
						value: TracksFilters.MY_TRACKS
					}
				]
			}
		],
		[user]
	);

	const createOption = useMemo(
		() => ({
			label: "Create New Video",
			onClick: () => {
				handleUpload();
			}
		}),
		[handleUpload]
	);

	return (
		<>
			<PageWrapper>
				<FilterBlock options={filterOptions} createOption={createOption} />
				<ModelBlock
					autoFit={filteredVideos.length > 3 ? 258 : undefined}
					title={"Videos"}
					type={ModelBlockType.video}
					items={filteredVideos || []}
					loading={loading}
					keepCurrentData={loading && filters.offset > 1}
					inlineView={!isMobile}
					onEndScroll={onEndScroll}
					noContent={
						<NoConnectionsBlock>
							<ManInLotus />
							<MessageText>You don’t have any videos yet.</MessageText>
							<StyledButton buttonTheme="light" palette="light" size="medium" onClick={handleUpload} removeSideMargin>
								+ Create New Video
							</StyledButton>
						</NoConnectionsBlock>
					}
				/>
			</PageWrapper>
			<input
				id="videoFileUrl"
				type="file"
				onChange={onUpload}
				name="videoFileUrl"
				hidden
				accept={correctFileTypes.join(",")}
			/>
			<CreateVideo
				open={!!videoInfoPopup.open}
				editableModel={videoInfoPopup.model}
				onClose={() => {
					setVideoInfoPopup({ open: false });
				}}
			/>
		</>
	);
};

export default VideosPage;
