import React, { memo } from "react";

import { CreateEvent, CreateFileDialog, CreateTrack } from "shared/Components";
import CreateGroup from "shared/Components/Group/CreateGroup";
import { ToolbarActions } from "shared/types";

import RequestMoney from "./Donation/RequestMoney";
import SelectEvent from "./Event/SelectEvent";
import SelectGif from "./Gif/SelectGif";
import SelectGroup from "./Group/SelectGroup";
import CreatePoll from "./Poll/CreatePoll";
import SelectFile from "./SelectFile";
import SelectTrack from "./Track/SelectTrack";
import CreateVideo from "./Video/CreateVideo";
import SelectVideo from "./Video/SelectVideo";
import AddYoutube from "./Youtube/AddYoutube";

interface Props {
	action: ToolbarActions | null;
	setAction: React.Dispatch<React.SetStateAction<ToolbarActions | null>>;
	onSetContent: (content: any) => void;
}

const Dialogs: React.FC<Props> = memo(({ action, setAction, onSetContent }) => {
	switch (action) {
		case ToolbarActions.ADD_YOUTUBE: {
			return <AddYoutube open onClose={(_, r) => setAction((r as ToolbarActions) || null)} onSelect={onSetContent} />;
		}
		case ToolbarActions.ADD_GIF: {
			return <SelectGif open onClose={(_, r) => setAction((r as ToolbarActions) || null)} onSelect={onSetContent} />;
		}
		case ToolbarActions.SELECT_GROUP: {
			return <SelectGroup open onClose={(_, r) => setAction((r as ToolbarActions) || null)} onSelect={onSetContent} />;
		}
		case ToolbarActions.ADD_GROUP: {
			return (
				<CreateGroup open={true} onClose={(_, r) => setAction((r as ToolbarActions) || null)} onSelect={onSetContent} />
			);
		}
		case ToolbarActions.SELECT_EVENT: {
			return <SelectEvent open onClose={(_, r) => setAction((r as ToolbarActions) || null)} onSelect={onSetContent} />;
		}
		case ToolbarActions.SEND_VIDEO: {
			return <SelectVideo open onClose={(_, r) => setAction((r as ToolbarActions) || null)} onSelect={onSetContent} />;
		}
		case ToolbarActions.ADD_EVENT: {
			return (
				<CreateEvent open={true} onClose={(_, r) => setAction((r as ToolbarActions) || null)} onSelect={onSetContent} />
			);
		}
		case ToolbarActions.SELECT_TRACK: {
			return <SelectTrack open onClose={(_, r) => setAction((r as ToolbarActions) || null)} onSelect={onSetContent} />;
		}
		case ToolbarActions.ADD_TRACK: {
			return <CreateTrack open onClose={(_, r) => setAction((r as ToolbarActions) || null)} onSelect={onSetContent} />;
		}
		case ToolbarActions.ADD_REQUEST_MONEY: {
			return <RequestMoney open={true} onSelect={onSetContent} onClose={() => setAction(null)} />;
		}
		case ToolbarActions.ADD_POLL: {
			return <CreatePoll open={true} onSelect={onSetContent} onClose={() => setAction(null)} />;
		}
		case ToolbarActions.ADD_VIDEO: {
			return <CreateVideo open={true} onSelect={onSetContent} onClose={() => setAction(null)} />;
		}
		case ToolbarActions.SELECT_FILE: {
			return <SelectFile open onClose={(_, r) => setAction((r as ToolbarActions) || null)} onSelect={onSetContent} />;
		}
		case ToolbarActions.ADD_FILE: {
			return (
				<CreateFileDialog open onClose={(_, r) => setAction((r as ToolbarActions) || null)} onSelect={onSetContent} />
			);
		}
		default:
			return null;
	}
});

export default Dialogs;
