import React from "react";

import styled from "styled-components";

import { ReactComponent as CatIcon } from "assets/images/cat-illustration.svg";
import { Box, Text } from "shared/ui-kit";

const EmptyBodyWrapper = styled(Box)`
	text-align: center;
	margin: 140px 35px 150px;
	display: flex;
	align-items: center;
	flex-direction: column;
`;

const EmptyBody: React.FC<{ children: React.ReactNode }> = ({ children }) => {
	return (
		<EmptyBodyWrapper>
			<CatIcon />
			<Text variant="h5">{`Oops, you don't have any ${children} in your community`}</Text>
		</EmptyBodyWrapper>
	);
};

export default EmptyBody;
