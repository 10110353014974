import React, { FC } from "react";

import { Box } from "@material-ui/core";
import styled from "styled-components";

import { CategoriesCard, ContributorsCard } from "../../Components";

const CategoriesWrapper = styled(Box)`
	${props => props.theme.breakpoints.up("md")} {
		display: flex;
		align-items: flex-start;
		justify-content: center;
	}
`;

CategoriesWrapper.Content = styled(Box)`
	width: 95%;
	margin: auto;
	${props => props.theme.breakpoints.up("md")} {
		width: 640px;
		margin: unset;
		margin-right: 16px;
	}
`;

CategoriesWrapper.Sidepanel = styled(Box)`
	width: 95%;
	margin: 16px auto;
	${props => props.theme.breakpoints.up("md")} {
		margin: 0;
		width: 320px;
	}
`;

const CategoriesPage: FC<{ routes: any }> = ({ routes }) => (
	<CategoriesWrapper>
		<CategoriesWrapper.Content>
			<CategoriesCard />
		</CategoriesWrapper.Content>
		<CategoriesWrapper.Sidepanel>
			<ContributorsCard preview contributorsUrl={routes?.member.contributors.getPath()} />
		</CategoriesWrapper.Sidepanel>
	</CategoriesWrapper>
);

export default CategoriesPage;
