import React, { FC } from "react";

import { TextField } from "@material-ui/core";
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";

import { useCommunity } from "shared/hooks";
import { Text } from "shared/ui-kit";

import { LabelHeadline, MultiselectWrapper } from "./style";

interface Option {
	value: string;
	key: string;
}

interface Props {
	options: Option[];
	onChange;
	value: Option[];
	error?: string;
}

const MultiselectInput: FC<Props> = ({ onChange, value, options, error }) => {
	const { communityColors } = useCommunity();
	return (
		<MultiselectWrapper color={communityColors.primary}>
			<Autocomplete
				multiple
				ListboxProps={{ style: { maxHeight: "180px" }, position: "bottom-start" }}
				renderInput={params => (
					<>
						<LabelHeadline>Genres *</LabelHeadline>
						<TextField {...params} variant="outlined" helperText={error} error={!!error} />
					</>
				)}
				renderTags={options =>
					options.map(op => (
						<MultiselectWrapper.Item color={communityColors.btn} key={op.key}>
							<Text>{op.value}</Text>
						</MultiselectWrapper.Item>
					))
				}
				getOptionLabel={option => (typeof option === "string" ? option : option.value)}
				id="genre-selector"
				filterOptions={createFilterOptions({
					matchFrom: "start",
					stringify: option => option.value
				})}
				includeInputInList
				filterSelectedOptions
				options={options}
				value={value}
				onChange={(_, newValue) => onChange(!!newValue.length ? newValue : undefined)}
			/>
		</MultiselectWrapper>
	);
};

export default MultiselectInput;
