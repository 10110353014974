import { Box } from "@material-ui/core";
import styled, { css } from "styled-components";

export const InputWrapper = styled(Box)`
	position: relative;
	${({ customHeight }) =>
		customHeight &&
		css`
			height: ${customHeight};
			> div {
				height: 100%;
				> input {
					height: 100%;
				}
			}
		`}
	p {
		font-size: 13px;
		font-weight: 600;
		line-height: 1.85;
		color: #222b45;
		margin-bottom: 2px;
	}
	.input-with-icon {
		position: relative;
		border: solid 1px #e4e9f2;
		border-radius: 8px;
		padding: 0 8px;

		&.grayBg {
			background-color: #f7f9fc;
		}

		.input-blocker {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			cursor: pointer;
			border-radius: 8px;
			display: flex;
			align-items: center;
			padding: 0 16px;
			&.autoResize {
				width: calc(100% - 10px);
			}
			&.active {
				border: solid 1px #192038;
			}
		}
		.icon-wrapper {
			pointer-events: none;
			height: 100%;
			display: flex;
			align-items: center;
			svg {
				width: 24px;
				fill: ${({ active, rightIconColor }) => (active ? "#192038" : rightIconColor ? rightIconColor : "#c5cee0")};
			}
		}
		.left-icon {
			svg {
				width: 16px;
				height: 16px;
			}
		}
		&.w-error {
			input,
			textarea {
				border-color: red;
			}
		}
		&.narrow input {
			padding: ${({ autoResize }) => (autoResize ? "4px" : "4px 8px")};
			font-size: 13px;
		}
	}
	.input-wrapper {
		width: 100%;
		input {
			&.grayBg {
				background-color: #f7f9fc;
				::placeholder {
					color: #000000;
					opacity: 1;
				}
			}
		}
		&.focused {
			border-color: #192038;
		}
	}

	input,
	textarea {
		padding: 12px 0;
		font-size: 15px;
		line-height: 1.33;
		border-radius: 8px;
		width: 100%;
		resize: none;

		&:focus {
			outline: 0;
			border: 0;
			box-shadow: none;
		}
		::placeholder {
			color: #8f9bb3;
			opacity: 1;
		}
		:-ms-input-placeholder {
			color: #8f9bb3;
		}
		::-ms-input-placeholder {
			color: #8f9bb3;
		}
	}
	.input-error {
		position: absolute;
		top: calc(100% + 4px);
		left: 0;
		font-size: 12px;
		color: red;
	}

	.letter-counter {
		position: absolute;
		top: calc(100% + 4px);
		right: 0;
		font-size: 12px;
		color: #8f9bb3;
	}

	/* Chrome, Safari, Edge, Opera */
	input.removeArrows::-webkit-outer-spin-button,
	input.removeArrows::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	/* Firefox */
	input.removeArrows[type="number"] {
		-moz-appearance: textfield;
	}
`;

export const RemoveButton = styled.button`
	background-color: #c5cee0;
	width: 20px;
	height: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
`;
