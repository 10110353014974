import { Box } from "@material-ui/core";
import styled from "styled-components";

export const FlexContainer = styled(Box)`
	width: 100%;

	display: flex;
`;

FlexContainer.Column = styled(Box)`
	width: 100%;

	display: flex;
	flex-direction: column;

	&.with-image {
		width: calc(100% - 56px);
		margin-left: 16px;
	}
`;
