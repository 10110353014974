import { Box, ButtonBase } from "@material-ui/core";
import styled, { css } from "styled-components";

import { Text } from "shared/ui-kit";

export const Container = styled(Box)`
	position: relative;
	${({ circular }) =>
		circular &&
		css`
			display: flex;
			justify-content: center;
		`}
`;

export const ImageUploaderWrapper = styled(ButtonBase)<{ circular?: boolean; size?: number; simple?: boolean }>`
	background-color: ${({ simple }) => (simple ? "#fff" : "#92ccfd")};
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	overflow: hidden;

	${({ circular, size, simple }) =>
		circular
			? css`
					width: ${size || 160}px;
					height: ${size || 160}px;
					border-radius: 99%;
					outline-offset: 2px;
					${simple ? "border: 2px dashed rgb(228, 233, 242)" : "outline: 1px solid #e4e9f2"};
			  `
			: css`
					height: 168px;
					width: 100%;
			  `}

	p {
		color: #222b45;
		font-weight: 600;
	}
	${({ uploading }) =>
		uploading &&
		css`
			&:before {
				content: "";
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				background: rgb(0 0 0 / 38%);
			}
		`}
	${({ dragOver }) =>
		dragOver &&
		css`
			&:before {
				content: "";
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				background: rgb(255 255 255 / 15%);
			}
		`}

	${props => props.theme.breakpoints.up("sm") && props.circular} {
		height: 224px;
	}
`;

ImageUploaderWrapper.EditButtonWrapper = styled(Box)`
	position: absolute;
	.MuiCircularProgress-root {
		color: #ffffff;
	}
	${({ circular }) =>
		circular
			? css`
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					display: flex;
					justify-content: center;
					align-items: center;
					z-index: 10;
			  `
			: css`
					bottom: 16px;
					right: 16px;
			  `}
`;

ImageUploaderWrapper.EditButton = styled(ButtonBase)`
	${({ circular, size }) =>
		circular
			? css`
					display: flex;
					align-items: center;
					justify-content: center;
					width: ${size || 160}px;
					height: ${size || 160}px;
					background-color: rgba(0, 0, 0, 0.16);
					border-radius: 99%;
			  `
			: css`
					border-radius: 8px;
					backdrop-filter: blur(20px);
					box-shadow: inset 0 0 0 0.5px #fff;
					background-color: rgba(0, 0, 0, 0.24);
					width: 32px;
					height: 32px;
			  `}
`;

ImageUploaderWrapper.ButtonOptions = styled(Box)`
	position: absolute;
	top: ${({ circular }) => (circular ? "108%" : "40px")};
	background: #fff;
	box-shadow: 0 0 8px 0 rgb(0 0 0 / 12%), -0.5px 0 0 #c5cee0;
	width: ${({ circular }) => (circular ? "260px" : "160px")};
	z-index: 10;
	border-radius: 8px;
	${({ circular }) =>
		!circular &&
		css`
			right: 0;
		`}
`;

ImageUploaderWrapper.Option = styled(ButtonBase)`
	width: 100%;
	padding: 8px 12px;
	border-radius: 8px;
	text-align: left;
	justify-content: flex-start;
	font-size: 13px;
	font-weight: 600;
	line-height: 1.85;
	color: #222b45;
	&:first-child {
		box-shadow: 0 0.5px 0 #e4e4e4;
	}
	svg {
		margin-right: 4px;
	}
`;

ImageUploaderWrapper.Image = styled.img`
	width: 100%;
	&.cover {
		object-fit: cover;
		height: 100%;
	}
`;

export const UploaderError = styled(Text)`
	position: absolute;
	top: calc(100% + 6px);
	left: 8px;
	font-size: 12px;
	color: red;
	z-index: 10;
	text-align: left;
	width: 100%;
	padding: 0 16px;
`;
