import { Box } from "@material-ui/core";

import styled from "styled-components";

import { Text } from "shared/ui-kit";
import { customBreakpoints } from "theme/default";

export const SelectWrapper = styled(Box)`
	${({ theme }) => theme.breakpoints.down(customBreakpoints.tabletVerticalMax)} {
		display: none;
	}

	.MuiFormControl-root {
		width: 160px;

		.MuiSelect-root {
			display: flex;
			align-items: center;
		}

		input {
			display: none;
		}
	}
`;

export const AccessIconWrapper = styled(Box)`
	margin-right: 9px;

	svg > g > path {
		&:first-child {
			fill: #c5cee0;
		}
	}
`;

export const StyledOptionText = styled(Text)`
	margin: 0 0 0 10px;
`;
