import React, { FC } from "react";

import { PlaceholderImage } from "shared/Components";

import { useMemberRoutes } from "shared/hooks";
import { FundraiserModel, PlaceholderImageType } from "shared/types";

import { FundraiserStatusEnum } from "shared/types/FundraiserModel";
import { formatMoney } from "utils/formatMoney";

import { FundraiserMoney, FundraiserSummary, StatusChip } from "./style";

import ModelItem from "../ModelItem";

const FundraiserCard: FC<{ fundraiser: FundraiserModel }> = ({ fundraiser }) => {
	const { getMemberRoutesData } = useMemberRoutes();
	const { routes } = getMemberRoutesData();

	return (
		<ModelItem
			autoHeight
			item={{
				wrapByHref: true,
				dontStyleBeforeTitle: true,
				_id: fundraiser._id,
				href: `${routes?.member.fundraiser.getPath()}/${fundraiser._id}`,
				img: fundraiser.thumbnail.url || undefined,
				placeholderImg: (
					<PlaceholderImage
						type={PlaceholderImageType.FUNDRAISER_DETAILS}
						width={360}
						height={160}
						viewBox={"0 0 720 320"}
					/>
				),
				beforeTitle: (
					<div className="flex items-center justify-between">
						<FundraiserSummary>
							{fundraiser.groupIds.length} groups • {fundraiser.memberCount} members
						</FundraiserSummary>
						{fundraiser.status && (
							<StatusChip className={`px-2 border rounded-full font-semibold ${fundraiser.status.toLowerCase()}`}>
								{FundraiserStatusEnum[fundraiser.status]}
							</StatusChip>
						)}
					</div>
				),
				title: fundraiser.name,
				subtitle: (
					<FundraiserMoney>
						{formatMoney(fundraiser.totalDonated)}
						<span> / {formatMoney(fundraiser.goalAmount)}</span>
					</FundraiserMoney>
				),
				dontStyleSubtitle: true
			}}
		/>
	);
};

export default FundraiserCard;
