import React, { ReactNode, useMemo } from "react";

import { CssBaseline, StylesProvider } from "@material-ui/core";
import { MuiThemeProvider } from "@material-ui/core/styles";
import MetaTags from "react-meta-tags";
import { ThemeProvider, createGlobalStyle } from "styled-components/macro";

import { useCommunity, useWindowSize } from "shared/hooks";
import { uuidv4 } from "shared/ui-kit/utils/helper";

import appTheme from "theme/default";
import "../index.scss";

interface IAppWrapperProps {
	children: undefined | ReactNode;
}

const GlobalStyle = createGlobalStyle`
  body {
		overscroll-behavior-y: none;
	}

	input:-webkit-autofill,
	input:-webkit-autofill:hover,
	input:-webkit-autofill:focus,
	input:-webkit-autofill:active
	{
		-webkit-box-shadow: 0 0 0 30px white inset !important;
	}

	.MuiSelect-select:focus {
		background-color: transparent !important;
	}
	/* ::-webkit-scrollbar {
		width: 0; // TODO: do not use standart option as it cause a lot of bugs in UI
		height: 0;
	}
	::-webkit-scrollbar-button {
		display: none;
	}
	::-webkit-scrollbar-track-piece {
		background: #f7f9fc
	}
	::-webkit-scrollbar-thumb {
		background: #c5cee0;
		border-radius: 8px;
	} */
	.MuiAutocomplete-popper, .MuiPopover-root {
		z-index: 999999 !important;
	}
`;

const AppWrapper: React.FC<IAppWrapperProps> = ({ children }) => {
	const { getData: getCommunityData } = useCommunity();
	const { workspace, isBitcoinMode } = getCommunityData();

	useWindowSize();

	const meta = useMemo(() => {
		if (isBitcoinMode) {
			return (
				<>
					<title>The Bitcoin App</title>
					<link rel="icon" href={`/tba/favicon.ico?v=${uuidv4()}`} />
					<link rel="apple-touch-icon" sizes="180x180" href="/tba/apple-touch-icon.png" />
					<link rel="icon" type="image/png" sizes="32x32" href="/tba/favicon-32x32.png" />
					<link rel="icon" type="image/png" sizes="16x16" href="/tba/favicon-16x16.png" />
					<meta name="theme-color" content="#ff9400" />

					<meta name="apple-mobile-web-app-title" content="The Bitcoin App" />
					<meta name="application-name" content="The Bitcoin App" />

					<meta name="title" content="Home of the global Bitcoin community" />
					<meta property="twitter:title" content="Home of the global Bitcoin community" />
					<meta prefix="og:http://ogp.me/ns#" property="og:title" content="Home of the global Bitcoin community" />

					<meta property="og:image" content="/tba/favicon-384x384.png" />

					<meta
						name="description"
						content="The Bitcoin App is the global Bitcoin community at your fingertips. Connect, learn from, and meetup with other Bitcoiners. Enjoy the latest news, podcasts, livestreams, and market updates. Shop for Bitcoin merchandise and earn Bitcoin just by using the app."
					/>
					<meta
						property="twitter:description"
						content="The Bitcoin App is the global Bitcoin community at your fingertips. Connect, learn from, and meetup with other Bitcoiners. Enjoy the latest news, podcasts, livestreams, and market updates. Shop for Bitcoin merchandise and earn Bitcoin just by using the app."
					/>
					<meta
						prefix="og:http://ogp.me/ns#"
						property="og:description"
						content="The Bitcoin App is the global Bitcoin community at your fingertips. Connect, learn from, and meetup with other Bitcoiners. Enjoy the latest news, podcasts, livestreams, and market updates. Shop for Bitcoin merchandise and earn Bitcoin just by using the app."
					/>
				</>
			);
		}

		if (workspace?.isSportType) {
			return (
				<>
					<title>Create a Sports Community to Connect, Fundraise and Broadcast in minutes.</title>
					<link rel="icon" href={`/vs-favicon.ico?v=${uuidv4()}`} />
					<link rel="apple-touch-icon" sizes="180x180" href="/vs-apple-touch-icon.png" />
					<link rel="icon" type="image/png" sizes="32x32" href="/vs-favicon-32x32.png" />
					<link rel="icon" type="image/png" sizes="16x16" href="/vs-favicon-16x16.png" />
				</>
			);
		}

		return (
			<>
				<title>
					For artists and creators who want to make money and build community, while doing what they love | Vyoo
					Community
				</title>
				<link rel="icon" href={`/favicon.ico?v=${uuidv4()}`} />
				<link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
				<link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
				<link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
			</>
		);
	}, [workspace?.isSportType, isBitcoinMode]);

	return (
		<MuiThemeProvider theme={appTheme}>
			<StylesProvider injectFirst>
				<ThemeProvider theme={appTheme}>
					<GlobalStyle />
					<CssBaseline />
					<MetaTags>{meta}</MetaTags>
					{children}
				</ThemeProvider>
			</StylesProvider>
		</MuiThemeProvider>
	);
};

export default AppWrapper;
