import React, { useCallback, useEffect, useMemo, useState } from "react";

import { useMediaQuery } from "@material-ui/core";
import { DateTime } from "luxon";

import { pick } from "ramda";

import { useHistory } from "react-router-dom";
import { ForumPostModel, PostScheduleType } from "types";

import { boardIcons } from "modules/Manage/View/Components/SelectIconField";

import { ConfirmActionModal, MenuDots } from "shared/Components";
import { Post } from "shared/Organisms";
import { useCommunity, useFeature, usePost, usePosts, useUser } from "shared/hooks";

import * as appTheme from "theme/default";

import { BoxItemWrapper, Column, Container, PostMenu, PostPreview } from "./style";

import { CreateUpdatePost } from "../../../../Post/View";

import { ModelBlock, ModelBlockType } from "../../Components";
import { MessageText } from "../../Components/ModelBlock/style";
import { EmptyStateWoman, ManInLotus, NoConnectionsBlock, StyledButton } from "../Connections/style";

export interface FuturePostsPageProps {}

const FuturePostsPage: React.FC<FuturePostsPageProps> = () => {
	const history = useHistory();
	const { getData: getUserData } = useUser();
	const { user } = getUserData();

	const isMobile = useMediaQuery(appTheme.default.breakpoints.down("sm"));

	const { getData: getCommunityData, communityColors } = useCommunity();
	const { workspace, isWhitelabelAppMode } = getCommunityData();

	const { getPosts, updatePostsList, setCreateUpdatePostDialog, getData: getPostsData, deletePost } = usePosts();
	const { posts, createUpdatePostDialog } = getPostsData();

	const { updatePost } = usePost();

	const { isFeatureEnabled } = useFeature();

	const [confirmation, setConfirmation] = useState<{ open: boolean; postInfo?: ForumPostModel }>({
		open: false
	});
	const [showConfirmDelete, setShowConfirmDelete] = useState(false);
	const [postConfirmDeleteId, setPostConfirmDeleteId] = useState<string>();
	const [highlightedPost, setHighlightedPost] = useState<string>();

	useEffect(() => {
		getPosts({ postType: PostScheduleType.Scheduled, limit: 10, offset: 1 });
	}, [getPosts]);

	useEffect(() => {
		return () => setCreateUpdatePostDialog({ isOpen: false });
	}, [setCreateUpdatePostDialog]);

	const handlePostChanged = ({ isEdited, postInfo }: { isEdited: boolean; postInfo?: ForumPostModel }) => {
		setCreateUpdatePostDialog({ isOpen: false });
		if (!isEdited && !postInfo) {
			getPosts({ postType: PostScheduleType.Scheduled, limit: 10, offset: 1 });
		} else if (postInfo && postInfo.timestamp && Date.now() - postInfo.timestamp < 0) {
			// check timestamp to validate that it's an upcoming post
			updatePostsList({ postInfo, isNew: !isEdited });
		}
	};

	const handleCreateNewPost = () => {
		setCreateUpdatePostDialog({ isOpen: true });
	};

	const forumEnabled = useMemo(() => {
		return isFeatureEnabled("enableForum");
	}, [isFeatureEnabled]);

	const options = useMemo(
		() => (postInfo?: ForumPostModel) => {
			return [
				{
					name: "Post now",
					onClick: () => setConfirmation({ open: true, postInfo }),
					value: 1
				},
				{
					name: "Edit post",
					onClick: () =>
						setCreateUpdatePostDialog({ isOpen: true, editablePost: postInfo, editablePostPid: postInfo?.pid }),
					value: 2
				},
				{
					name: "Delete post",
					onClick: () => {
						setPostConfirmDeleteId(postInfo?.pid);
						setShowConfirmDelete(true);
					},
					value: 3
				}
			];
		},
		[setCreateUpdatePostDialog]
	);

	const cancelDelete = () => {
		setShowConfirmDelete(false);
	};

	const confirmDelete = () => {
		setShowConfirmDelete(false);
		deletePost({ id: `${postConfirmDeleteId}`, showNotification: true, contentType: "Post" });
	};

	const getTimestamp = useCallback((timestamp?: number) => {
		return timestamp ? DateTime.fromMillis(timestamp).toFormat("MMM d, yyyy, h:mm a") : "";
	}, []);

	const postNow = useCallback(async () => {
		setConfirmation({ open: false });
		if (confirmation.postInfo) {
			await updatePost({
				...pick(
					[
						"pid",
						"content",
						"cid",
						"title",
						"teaserContent",
						"postType",
						"priceTagId",
						"tags",
						"sentNotification",
						"priceTags"
					],
					confirmation.postInfo
				),
				isEarly: false,
				startDate: new Date(),
				publicStartDate: new Date(),
				publicStartTime: new Date(),
				publicStartTimeZone: "local"
			});
		}
		getPosts({ postType: PostScheduleType.Scheduled, limit: 10, offset: 1 });
	}, [confirmation, getPosts, updatePost]);

	const postClickHandlerForMobile = useCallback(
		(post: ForumPostModel) => {
			if (isMobile) history.push(`futurepost/${post.pid}`);
			else if (post?.pid) {
				setHighlightedPost(post.pid);
				document.getElementById(post.pid)?.scrollIntoView({ block: "center" });

				setTimeout(() => {
					setHighlightedPost(undefined);
				}, 300);
			}
		},
		[isMobile, history]
	);

	if (!workspace || !user) {
		return null;
	}

	return (
		<>
			{forumEnabled && (
				<>
					<Container className={!posts?.length && "column"}>
						{posts?.length ? (
							<>
								<Column left withMargin>
									<BoxItemWrapper>
										<PostMenu>
											<PostMenu.Title>Scheduled Posts</PostMenu.Title>
											<PostMenu.Body>
												{posts.map((post, index) => (
													<PostPreview.Wrapper key={index} onClick={() => postClickHandlerForMobile(post)}>
														<PostPreview.MainBlock>
															<PostPreview.IconWrapper imgUrl={post?.user?.persona?.photos[0]?.profilePicture ?? ""} />
															<PostPreview.Body>
																<PostPreview.CategoryWrapper>
																	<PostPreview.CategoryIconWrapper color={communityColors.primary}>
																		{boardIcons.find(x => x.value === post.category.iconName)?.icon}
																	</PostPreview.CategoryIconWrapper>
																	<PostPreview.CategoryName>{post.category.name}</PostPreview.CategoryName>
																</PostPreview.CategoryWrapper>
																<PostPreview.DateTextRow>
																	<PostPreview.DateText>For</PostPreview.DateText>
																	<PostPreview.Date>{getTimestamp(post.timestamp)}</PostPreview.Date>
																</PostPreview.DateTextRow>
															</PostPreview.Body>
														</PostPreview.MainBlock>
														<PostPreview.ManageBlock>
															<MenuDots options={options(post)} size={{ small: true }} />
														</PostPreview.ManageBlock>
													</PostPreview.Wrapper>
												))}
											</PostMenu.Body>
										</PostMenu>
									</BoxItemWrapper>
								</Column>
								<Column middle>
									{posts.map(post => (
										<BoxItemWrapper
											key={post.pid}
											id={post.pid}
											className={highlightedPost === post.pid ? "highlight" : ""}
										>
											<Post fullMode post={post} allowManage={false} />
										</BoxItemWrapper>
									))}
								</Column>
								<ConfirmActionModal
									open={confirmation.open}
									title={"Post now?"}
									bodyText={"Are you sure you  want to post it now?"}
									onConfirm={postNow}
									onClose={() => setConfirmation({ open: false })}
								/>
							</>
						) : (
							<>
								<PostMenu.Title>Scheduled Posts</PostMenu.Title>
								<ModelBlock
									type={ModelBlockType.liveUpcomingEvent}
									items={[]}
									noContent={
										<NoConnectionsBlock>
											{isWhitelabelAppMode ? <EmptyStateWoman /> : <ManInLotus />}
											<MessageText>{"You haven't scheduled any posts yet."}</MessageText>
											<StyledButton
												buttonTheme="light"
												palette="primary"
												size="medium"
												removeSideMargin
												onClick={handleCreateNewPost}
											>
												+ Create New Post
											</StyledButton>
										</NoConnectionsBlock>
									}
								/>
							</>
						)}
					</Container>
					{createUpdatePostDialog.isOpen && (
						<CreateUpdatePost onCreateUpdatePost={handlePostChanged} pid={createUpdatePostDialog?.editablePostPid} />
					)}

					{showConfirmDelete && (
						<ConfirmActionModal
							open
							onClose={cancelDelete}
							title="Delete post?"
							bodyText={"Are you sure you want to delete this post?"}
							onConfirm={confirmDelete}
							closeText="Keep"
							confirmText="Delete"
							confirmButtonTheme="danger"
							cancelButtonTheme="primary"
						/>
					)}
				</>
			)}
		</>
	);
};

export default FuturePostsPage;
