import React from "react";

import styled from "styled-components";

import { ReactComponent as FacebookIcon } from "assets/icons/facebook.svg";

import { ReactComponent as InstagramIcon } from "assets/icons/instagram.svg";

import { ReactComponent as TiktokIcon } from "assets/icons/tiktok.svg";

import { ReactComponent as TwitterIcon } from "assets/icons/twitter.svg";

import { SocialTypes } from "shared/types";
import { Box, Input, Text } from "shared/ui-kit";

const SocialIcons = {
	[SocialTypes.facebook]: <FacebookIcon />,
	[SocialTypes.instagram]: <InstagramIcon />,
	[SocialTypes.tiktok]: <TiktokIcon />,
	[SocialTypes.twitter]: <TwitterIcon />
};

const SocialLabels = {
	[SocialTypes.facebook]: "Facebook",
	[SocialTypes.instagram]: "Instagram",
	[SocialTypes.tiktok]: "Tiktok",
	[SocialTypes.twitter]: "Twitter"
};

const Row = styled(Box)`
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-bottom: 10px;
	${props => props.theme.breakpoints.down("sm")} {
		flex-direction: column;
		width: 100%;
	}
`;

const Label = styled(Text)`
	padding: 0 0 0 10px;
	flex: 1;
	display: flex;
`;

const TextField = styled(Input)`
	flex: 4;
`;

const SocialAccount = ({
	type,
	value = "",
	onChange,
	error = "",
	ref,
	id
}: {
	type: SocialTypes;
	value: string;
	onChange: (text: string) => void;
	error?: string;
	ref: any;
	id?: string;
}) => (
	<Row>
		{SocialIcons[type]}
		<Label>{SocialLabels[type]}</Label>
		<TextField
			placeholder="Enter your account public page url"
			onChange={e => onChange(e.target.value)}
			value={value}
			errorText={error}
			ref={ref}
			id={id}
		/>
	</Row>
);

export default SocialAccount;
