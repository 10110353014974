import React, { FC, useEffect, useState } from "react";

import { ExpressCheckoutElement, PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { FieldsOption, StripeExpressCheckoutElementOptions, StripePaymentElementOptions } from "@stripe/stripe-js";

import { ExpressCheckoutWrapper } from "./style";

const CheckoutForm: FC<{
	donationId: string;
	contactId: string;
}> = ({ donationId, contactId }) => {
	const stripe = useStripe();
	const elements = useElements();

	const [message, setMessage] = useState<string>("");
	const [isLoading, setIsLoading] = useState(false);
	const [loaded, setLoaded] = useState(false);

	useEffect(() => {
		if (!stripe) {
			return;
		}

		const clientSecret = new URLSearchParams(window.location.search).get("payment_intent_client_secret");

		if (!clientSecret) {
			return;
		}
	}, [stripe]);

	const return_url = `${window.location.href}&donationId=${donationId}&contactId=${contactId}`;

	const handleSubmit = async e => {
		e.preventDefault();

		if (!stripe || !elements) {
			// Stripe.js hasn't yet loaded.
			// Make sure to disable form submission until Stripe.js has loaded.
			return;
		}

		setIsLoading(true);

		const { error } = await stripe.confirmPayment({
			elements,
			//redirect: "if_required",
			confirmParams: {
				// Make sure to change this to your payment completion page
				return_url
			}
		});

		// This point will only be reached if there is an immediate error when
		// confirming the payment. Otherwise, your customer will be redirected to
		// your `return_url`. For some payment methods like iDEAL, your customer will
		// be redirected to an intermediate site first to authorize the payment, then
		// redirected to the `return_url`.
		if (error.type === "card_error" || error.type === "validation_error") {
			setMessage(error.message || "");
		} else {
			setMessage("An unexpected error occurred.");
		}

		setIsLoading(false);
	};

	const onConfirm = async () => {
		if (!stripe || !elements) {
			// Stripe.js hasn't yet loaded.
			// Make sure to disable form submission until Stripe.js has loaded.
			return;
		}

		setIsLoading(true);

		const { error } = await stripe.confirmPayment({
			elements,
			//redirect: "if_required",
			confirmParams: {
				// Make sure to change this to your payment completion page
				return_url
			}
		});

		// This point will only be reached if there is an immediate error when
		// confirming the payment. Otherwise, your customer will be redirected to
		// your `return_url`. For some payment methods like iDEAL, your customer will
		// be redirected to an intermediate site first to authorize the payment, then
		// redirected to the `return_url`.
		if (error.type === "card_error" || error.type === "validation_error") {
			setMessage(error.message || "");
		} else {
			setMessage("An unexpected error occurred.");
		}

		setIsLoading(false);
	};

	const fields: FieldsOption = {};

	const paymentElementOptions: StripePaymentElementOptions = {
		layout: "tabs",
		fields
	};

	const expressElementOptions: StripeExpressCheckoutElementOptions = {};

	return (
		<ExpressCheckoutWrapper>
			<form id="payment-form" onSubmit={handleSubmit}>
				<ExpressCheckoutElement options={expressElementOptions} onConfirm={onConfirm} onReady={() => setLoaded(true)} />
				{loaded && (
					<h2 className="background double">
						<span>Or Pay With</span>
					</h2>
				)}
				<PaymentElement id="payment-element" options={paymentElementOptions} />
				<button disabled={isLoading || !stripe || !elements} id="submit">
					<span id="button-text">{isLoading ? <div className="spinner" id="spinner"></div> : "Pay now"}</span>
				</button>
				{/* Show any error or success messages */}
				{message && <div id="payment-message">{message}</div>}
			</form>
		</ExpressCheckoutWrapper>
	);
};

export default CheckoutForm;
