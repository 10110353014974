import React, { memo } from "react";

import Box from "@material-ui/core/Box";
import styled from "styled-components";

import Close from "./Close";

import { PostBlockEventType } from "../../../types";

const Wrapper = styled(({ children, id, onEvent, ...rest }) => (
	<Box {...rest}>
		{id && <Close viewMode={rest?.memberView} onClick={() => onEvent(PostBlockEventType.delete, id)} />}
		{children}
	</Box>
))`
	width: 100%;
	height: 315px;

	border-radius: 4px;
	border: solid 1px #c5cee0;

	overflow: hidden;

	margin: 12px 0;

	position: relative;

	${props => props.theme.breakpoints.down("sm")} {
		width: 100%;
	}
`;

interface Props {
	elementId: string;
	src: string;
	poster?: string;
	width?: string;
	onEvent?: (eventType: PostBlockEventType, id: string) => void;
}

const VideoBlock: React.FC<Props> = memo(({ src, width, poster, elementId, onEvent }) => (
	<Wrapper id={elementId} onEvent={onEvent}>
		<video width={width || "600"} height="300" src={src} poster={poster} controls={true}>
			<source src={src} type="video/mp4" />
		</video>
	</Wrapper>
));

export default VideoBlock;
