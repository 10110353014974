import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";

import { FileFormModel, FileType, UpdateFileFormModel } from "types/FilesContextValuesType";

import { useCategories } from "modules/Marketplace/Data/hooks";
import { CategoryProps } from "modules/Marketplace/Data/types";
import { SetupPrice } from "shared/Components";
import { useFiles, useSeries, useUser } from "shared/hooks";
import { useS3Uploader } from "shared/services/s3Uploader";

import { CategorySort } from "shared/types";
import { getScheduleDate } from "utils/getScheduleDate";
import { dataUrlToFile, mergeDateAndTime } from "utils/serviceUtils/helpers";

import CreateFileManageView from "./ManageView";
import CreateFileMemberView from "./MemberView";

interface CreateFileDialogProps {
	onClose: (event: { created?: boolean }, reason?: string) => void;
	editableModel?: FileType;
	open: boolean;
	seriesView?: boolean;
	onSelect?: (content: any) => void;
	groupId?: string;
	eventId?: string;
}

const CreateFileDialog: FC<CreateFileDialogProps> = ({
	open,
	editableModel,
	onClose,
	seriesView,
	onSelect,
	groupId,
	eventId
}) => {
	const { createFile, updateFile, getData: getFilesData } = useFiles();
	const { fileName, fileUrl, extension, fileUploadUrl } = getFilesData();

	const { setSeriesDetailsDialog, getData: getSeriesData } = useSeries();
	const { seriesDetailsDialog } = getSeriesData();

	const { uploadFile } = useS3Uploader();
	const { getCategories } = useCategories();

	const { getData: getUserData } = useUser();
	const { isMemberView } = getUserData();

	const [suggestedCategories, setSuggestedCategories] = useState<CategoryProps[]>([]);

	const [uploadingCover, setUploadingCover] = useState(false);

	const editModel = useMemo(() => {
		const modelInfo = editableModel ? { ...editableModel } : undefined;
		if (modelInfo?.scheduleDate && new Date(modelInfo.scheduleDate) < new Date()) {
			modelInfo.scheduleDate = "";
		}
		return modelInfo;
	}, [editableModel]);

	type SetupPriceMethodsHandlers = React.ElementRef<typeof SetupPrice>;
	const setupPriceFormRef = useRef<SetupPriceMethodsHandlers>(null);

	const onUploaderChange = useCallback(
		async (files: FileList, onChange: (...event: any[]) => void) => {
			setUploadingCover(true);

			const img = files && files.length ? files[0] : null;

			const correctFile = typeof img === "string" ? await dataUrlToFile(img, "test") : img;
			if (img) {
				const data = await uploadFile({
					file: correctFile as File,
					communityName: fileUploadUrl
				});
				onChange(data?.publicUrl);
			} else {
				onChange(null);
			}

			setUploadingCover(false);
		},
		[uploadFile, fileUploadUrl]
	);

	const handleCreateUpdateFile = useCallback(
		async (data: any, isEdit: boolean) => {
			const priceTags = setupPriceFormRef.current?.getPriceTags();

			let scheduleDate: Date | string | undefined;

			if (isMemberView) {
				scheduleDate = getScheduleDate(data.time?.value, data.date) || undefined;
			} else if (data.scheduleDate && data.scheduleTime) {
				scheduleDate = mergeDateAndTime(data.scheduleDate, data.scheduleTime);
			}

			const body = {
				...data,
				fileUrl,
				fileName,
				extension,
				scheduleDate,
				groupId,
				eventId,
				category: isMemberView ? { name: data.category.label } : { name: data.category.name },
				categoryId: isMemberView ? data?.category.value : data?.category._id,
				priceTags,
				canBeDownloaded: !!data.canBeDownloaded,
				private: !!data.private
			};

			if (isEdit) {
				await updateFile({ ...editModel, ...(body as UpdateFileFormModel), _id: editModel!._id });
			} else {
				const file = await createFile(body as FileFormModel);

				if (seriesView && seriesDetailsDialog && seriesDetailsDialog.data && file) {
					setSeriesDetailsDialog({
						addContent: true,
						shouldUpdate: true,
						data: {
							...seriesDetailsDialog.data,
							itemIds: [{ _id: file._id, type: "FILE", file, new: true }, ...seriesDetailsDialog.data.itemIds]
						}
					});
				}

				return file;
			}
		},
		[
			createFile,
			editModel,
			fileName,
			fileUrl,
			groupId,
			eventId,
			isMemberView,
			seriesDetailsDialog,
			seriesView,
			setSeriesDetailsDialog,
			updateFile,
			extension
		]
	);

	const loadSuggestedCategories = useCallback(
		async (name: string) => {
			const { categories } = await getCategories({
				limit: 20,
				offset: 1,
				keyword: name,
				sortBy: CategorySort.name,
				order: 1
			});

			setSuggestedCategories(categories);
		},
		[getCategories]
	);

	const onSubmit = async data => {
		delete data.priceChanged;

		const file = await handleCreateUpdateFile(data, !!editModel);
		onClose && onClose({ created: true });
		onSelect && onSelect(file);
	};

	useEffect(() => {
		if (open) {
			loadSuggestedCategories("");
		}
	}, [loadSuggestedCategories, open]);

	if (isMemberView)
		return (
			<CreateFileMemberView
				suggestedCategories={suggestedCategories}
				loadSuggestedCategories={loadSuggestedCategories}
				onSubmit={onSubmit}
				onUploaderChange={onUploaderChange}
				onClose={onClose}
				open={open}
				setupPriceFormRef={setupPriceFormRef}
				editableModel={editModel}
			/>
		);

	return (
		<CreateFileManageView
			suggestedCategories={suggestedCategories}
			loadSuggestedCategories={loadSuggestedCategories}
			onClose={onClose}
			open={open}
			editableModel={editModel}
			uploadingCover={uploadingCover}
			onSubmit={onSubmit}
			onUploaderChange={onUploaderChange}
			setupPriceFormRef={setupPriceFormRef}
		/>
	);
};

export default CreateFileDialog;
