import React, { useCallback, useMemo } from "react";

import { DateTime } from "luxon";
import { MessageText } from "react-gifted-chat";

import { useUser } from "shared/hooks";
import { GeneralWrapper } from "shared/styles";
import { Icon, Text } from "shared/ui-kit";

import Actions from "./Actions";

import Reactions from "./Reactions";

import { RenderActionType } from "..";
import { ChatTextContainer, MessageTimestamp, ReplyChatContainer } from "../styles";

const TextBubble = props => {
	const { getData: getUserData } = useUser();
	const { isMemberView } = getUserData();

	const time = useMemo(
		() =>
			props.currentMessage.createdAt
				? DateTime.fromJSDate(new Date(props.currentMessage.createdAt)).toFormat("h:mm a")
				: "",
		[props.currentMessage]
	);

	const isOldReply = useMemo(
		() =>
			!!props.currentMessage?.reply &&
			Object.keys(props.currentMessage?.reply).length === 3 &&
			typeof props.currentMessage?.reply.text === "string",
		[props.currentMessage]
	);

	const isNewReply = useMemo(
		() => !!props.currentMessage?.reply && !isOldReply,
		[isOldReply, props.currentMessage?.reply]
	);

	const fillIcons = useMemo(() => (isMemberView ? "#fff" : undefined), [isMemberView]);

	const renderIcon = useMemo(() => {
		switch (props.currentMessage?.reply?.type) {
			case RenderActionType.VIDEO:
				return <Icon name="video" group="filled" fill={fillIcons} />;
			case RenderActionType.FILE:
				return <Icon name="file" group="filled" fill={fillIcons} />;
			case RenderActionType.AUDIO:
			case RenderActionType.TRACK:
				return <Icon name="play" group="filled" fill={fillIcons} />;
			case RenderActionType.IMAGE:
				return <Icon name="image" group="filled" fill={fillIcons} />;
			case RenderActionType.GROUP:
				return <Icon name="user-friend" group="filled" fill={fillIcons} />;
			case RenderActionType.EVENT:
				return <Icon name="calendar" group="filled" fill={fillIcons} />;
			default:
				return null;
		}
	}, [fillIcons, props.currentMessage]);

	const handleClickReply = useCallback(() => {
		if (!props.currentMessage?.reply) return;
		const msg = document.querySelector(`[data-message="${props.currentMessage?.reply.text.id}"]`);
		if (msg) msg.scrollIntoView({ behavior: "smooth", block: "center" });
	}, [props.currentMessage?.reply]);

	return (
		<>
			<ChatTextContainer
				className={`${props.isCurrentUser && isMemberView ? "current-user-bubble" : ""} ${
					isMemberView ? "member" : ""
				}`}
				data-message={props.currentMessage._id}
			>
				{isOldReply && (
					<ReplyChatContainer memberSide={isMemberView}>
						<Text style={{ color: "#8f9bb3", fontSize: 12, fontWeight: "bold", marginBottom: 4 }}>
							{props.currentMessage.reply.id === props.currentUserId ? "You" : props.currentMessage.reply.name}
						</Text>
						<Text>{props.currentMessage.reply.text}</Text>
					</ReplyChatContainer>
				)}
				{isNewReply && (
					<ReplyChatContainer onClick={handleClickReply} memberSide={isMemberView}>
						<Text
							style={{ color: isMemberView ? "#ffffff" : "#8f9bb3", fontSize: 12, fontWeight: "bold", marginBottom: 4 }}
						>
							{props.currentMessage.reply.uid === props.currentUserId ? "You" : props.currentMessage.reply.name}
						</Text>
						<Text className="reply-text">
							{renderIcon} {props.currentMessage.reply.text.text}
						</Text>
					</ReplyChatContainer>
				)}
				<MessageText {...props} />
				<MessageTimestamp className={props.isCurrentUser && isMemberView ? "current-user-ts" : ""}>
					{props.currentMessage?.edited && "Edited "}
					{time}
				</MessageTimestamp>
				<Actions
					replyItem={{
						id: props.currentMessage._id,
						text: props.currentMessage.text
					}}
					isCurrentUser={props.isCurrentUser}
					onDelete={props.onDelete}
					onEdit={props.onEdit}
					onForward={props.onForward}
					onReply={props.onReply}
					onLike={props.onLike}
					currentMessage={props.currentMessage}
					type={RenderActionType.TEXT}
				/>
			</ChatTextContainer>
			<GeneralWrapper
				className={"row center children-space-4 reactions-positioning"}
				isCurrentUser={props.isCurrentUser}
			>
				{props.currentMessage.recentLikes && (
					<Reactions list={props.currentMessage.recentLikes} isCurrentUser={props.isCurrentUser} />
				)}
			</GeneralWrapper>
		</>
	);
};

export default TextBubble;
