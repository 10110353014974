import { IStateProps } from "utils/StateOf";

import createStore from "utils/createStore";

export interface Values {
	playableObjectId: string;
	nextTrackId: string;
}

export const initialState: IStateProps<Values> = {
	playableObjectId: "",
	nextTrackId: ""
};

export default createStore<Values>({
	initialState
});
