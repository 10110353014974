import React from "react";

import { Box } from "@material-ui/core";
import styled from "styled-components";

import { ReactComponent as CrownIcon } from "assets/icons/icon-crown.svg";

const Wrapper = styled(Box)`
	position: absolute;
	top: 16px;
	right: 16px;
	width: 32px;
	height: 32px;
	padding: 4px;
	border-radius: 8px;
	background-color: #fff;
	z-index: 10;
`;

const PremiumContentIndicator = () => (
	<Wrapper>
		<CrownIcon />
	</Wrapper>
);

export default PremiumContentIndicator;
