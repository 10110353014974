import { ButtonBase } from "@material-ui/core";
import { FixedSizeList as List } from "react-window";
import styled from "styled-components";

import { Box, Button, Text } from "shared/ui-kit";

export const Wrapper = styled(Box)`
	display: flex;

	max-height: 400px;
`;

Wrapper.SearchBox = styled(Box)`
	width: 60%;

	border-right: 1px solid rgba(197, 206, 224, 0.5);

	padding: 15px 0;

	.MuiOutlinedInput-notchedOutline {
		top: -2px;
	}

	.MuiOutlinedInput-root {
		margin-right: 16px;
		margin-bottom: 10px;
		background: #f7f9fc;
		input::placeholder {
			color: #8f9bb3;
		}
	}
`;

Wrapper.TableBox = styled(Box)`
	width: 40%;
`;

Wrapper.SelectedHeaderRow = styled(Box)`
	padding: 7px 16px;

	border-bottom: 1px solid rgba(197, 206, 224, 0.5);

	display: flex;
	align-items: center;
	justify-content: space-between;
`;

Wrapper.SelectedCounter = styled(Text)`
	font-size: 12px;
	font-weight: 600;
	line-height: 1.33;
	color: #8f9bb3;
`;

Wrapper.DeselectAll = styled.button`
	font-size: 12px;
	font-weight: 600;
	line-height: 1.33;
	text-align: right;
	color: #8f9bb3;

	background-color: transparent;
	border: none;
	padding: 0;
	cursor: pointer;
`;

Wrapper.ActionBtn = styled(Button)``;

Wrapper.Footer = styled(Box)`
	text-align: right;
`;

export const SearchWrapper = styled(Box)`
	padding: 0 24px;
`;

export const ConnectionList = styled(List)`
	width: 100%;
	overflow: hidden !important;
`;

ConnectionList.Wrapper = styled(Box)`
	width: 100%;

	margin-top: 16px;

	padding: 0 0 0 24px;

	&.selected {
		padding: 0 16px;
	}
`;

export const ConnectionItemWrapper = styled(Box)`
	width: 100%;

	padding-right: 16px;

	&.checked {
		padding-right: 0;
	}

	&.skeleton-loader {
		padding-right: 28px;
	}
`;

export const Connection = styled(Box)`
	width: 100%;

	display: flex;
	align-items: center;
	justify-content: space-between;

	&.checkable {
		cursor: pointer;
	}

	&.disabled {
		opacity: 0.5;
		cursor: not-allowed;
	}
`;

Connection.Base = styled(Box)`
	display: flex;
	align-items: center;
`;

Connection.Action = styled(Box)`
	display: flex;
	align-items: center;

	.MuiFormControlLabel-root {
		margin: 0;
	}
`;

Connection.ActionBtn = styled(ButtonBase)`
	width: 24px;
	height: 24px;

	border-radius: 4px;

	cursor: pointer;

	display: flex;
	justify-content: center;
	align-items: center;

	&:hover {
		background-color: #c6cfe0;
	}

	svg {
		path {
			fill: #151a30;
		}
	}
`;

Connection.AvatarWrapper = styled(Box)`
	margin-right: 16px;
`;

Connection.Avatar = styled(Box)`
	width: 32px;
	height: 32px;

	border-radius: 8px;

	overflow: hidden;

	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;

	&.small {
		width: 24px;
		height: 24px;
	}
`;

Connection.Name = styled(Text)`
	font-size: 15px;
	font-weight: normal;
	line-height: 1.33;
	color: #222b45;
	white-space: nowrap;
	text-overflow: ellipsis;

	max-width: 190px;

	overflow: hidden;

	&.bold {
		font-weight: 600;
	}

	&.small {
		font-size: 13px;
		line-height: 1.23;

		max-width: 120px;
	}
`;

Connection.Status = styled(Text)`
	font-size: 12px;
	line-height: 1.33;
	letter-spacing: normal;
	color: #8f9bb3;

	margin-top: 2px;
`;
