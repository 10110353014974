import React, { FC } from "react";

import { Box } from "@material-ui/core";

import { ReactComponent as IconConverted } from "assets/icons/converted-icon.svg";
import { Receipt } from "shared/types/TransactionModel";
import { Text } from "shared/ui-kit";

import RightSide from "./RightSide";

const Converted: FC<{ item: Receipt }> = ({ item }) => (
	<>
		<Box className="left-side">
			<Box className="icon-wrapper">
				<IconConverted />
			</Box>
			<Text className="event-description">Converted to Coins</Text>
		</Box>
		<RightSide item={item} />
	</>
);

export default Converted;
