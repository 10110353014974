import React, { FC, useCallback } from "react";

import LabeledInput, { LabelInputProps } from "../LabeledInput";

const CVVInput: FC<LabelInputProps> = ({ label, onChange, name, placeholder, value, rightIcon, error, disabled }) => {
	const handleChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			const { value } = e.target as HTMLInputElement;

			if (value.length > 4) return;

			onChange(value.replace(/[^0-9]/g, "").trim());
		},
		[onChange]
	);

	return (
		<LabeledInput
			label={label}
			onChange={handleChange}
			name={name}
			placeholder={placeholder}
			value={value}
			rightIcon={rightIcon}
			maxLength={4}
			error={error}
			disabled={disabled}
		/>
	);
};

export default CVVInput;
