import { Box, ButtonBase } from "@material-ui/core";
import styled from "styled-components";

export const Wrapper = styled(Box)`
	padding: 20px 12px;
`;

export const ReactionsListTop = styled(Box)`
	display: flex;
	align-items: center;
	overflow-x: auto;
	flex-wrap: nowrap;
	flex: 1;
	gap: 28px;
	padding: 0 8px;
`;

ReactionsListTop.Button = styled(ButtonBase)`
	display: flex;
	align-items: center;
	gap: 4px;
	border-radius: 8px;
	font-size: 15px;
	font-weight: 600;
	line-height: 1.6;
	.reaction svg {
		width: 16px;
		height: 16px;
		fill: #ffd16c;
	}

	&.active {
		color: #6173fe;
	}
`;

export const ReactionsList = styled(Box)`
	margin-top: 24px;
`;

export const ReactionRow = styled.button`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 8px;
	height: 48px;
	width: 100%;
	text-align: left;
	border-radius: 8px;
	padding: 0 8px;
	&:hover {
		background-color: #f7f9fc;
	}
	.row {
		display: flex;
		align-items: center;
		gap: 16px;
		img {
			width: 32px;
			height: 32px;
			border-radius: 8px;
			object-fit: cover;
		}
		p {
			font-size: 15px;
			line-height: 1.33;
			color: #222b45;
		}
	}

	&.active-reaction {
		background-color: #f7f9fc;
		p.remove {
			font-size: 12px;
			color: #8f9bb3;
		}
	}

	.reaction svg {
		fill: ${({ reactionColor }) => reactionColor};
		width: 24px;
		height: 24px;
	}
`;
