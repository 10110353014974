import { Box } from "@material-ui/core";
import styled from "styled-components";

import { Text } from "shared/ui-kit";

export const TableWrapper = styled(Box)`
	border-radius: 0;
	box-shadow: none;
	overflow-x: auto;

	width: 100%;
`;

export const Cell = styled(Box)``;

Cell.Wrapper = styled(Box)`
	display: flex;
	flex-direction: row;
	align-items: center;

	width: 100%;

	&.column {
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
	}

	.with-image {
		max-width: calc(100% - 56px);
	}

	&.end {
		justify-content: flex-end;
	}

	&.center {
		justify-content: center;
	}

	.leaderboard-crown-2 {
		use {
			fill: #c6cfe1;
		}
		g path:last-child {
			fill: #c6cfe1;
		}
	}

	.leaderboard-crown-3 {
		use {
			fill: #ffe073;
		}
		g path:last-child {
			fill: #ffe073;
		}
	}
`;

Cell.Text = styled(Text)`
	word-break: break-all;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	font-size: 13px;

	width: 100%;

	&.light {
		color: #8f9bb3;
	}

	&.bold {
		font-weight: 700;
	}

	&.capitalize {
		text-transform: capitalize;
	}

	&.pointer {
		cursor: pointer;
	}

	&.inline-flex {
		display: inline-flex;
	}

	&.green {
		color: #23b34a;
	}

	&.blue {
		color: #4a99f9;
	}

	&.yellow {
		color: #ffd044;
	}
`;

Cell.HeaderText = styled(Text)`
	font-size: 12px;
	font-weight: 500;
	color: #8f9bb3;
`;

Cell.Date = styled(Box)`
	word-break: break-all;
	align-self: center;
	p {
		font-size: 13px;
	}
	.secondary-date {
		color: #8f9bb3;
	}
`;

Cell.Order = styled.p`
	color: #222b45;
	font-size: 13px;
	line-height: 16px;
`;

Cell.ImageWrapper = styled(Box)`
	width: 40px;
	min-width: 40px;
	height: 40px;

	border-radius: 4px;

	overflow: hidden;

	margin-right: 16px;

	display: flex;
	justify-content: center;
	align-items: center;

	background-color: #f7f9fc;
`;

Cell.Icon = styled(Box)`
	width: fit-content;
`;

Cell.Image = styled(Box)<{ src?: string }>`
	width: 100%;
	height: 100%;

	background-color: #f7f9fc;
	flex: 0 0 auto;

	display: flex;
	justify-content: center;
	align-items: center;

	background-image: url(${({ src }) => src});
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
`;

export const PaginationWrapper = styled(Box)`
	padding: 20px 21px;

	width: 100%;

	${props => props.theme.breakpoints.down("sm")} {
		padding: 10px 15px;
	}
`;
