import React from "react";

import { Box } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";

import { TrackRow } from "./style";

const TrackSkeleton = () => {
	return (
		<TrackRow>
			<Box className="track-info">
				<Skeleton variant="rect" width={40} height={40} />
				<Box className="track-name">
					<Skeleton width="100px" height="16px" />
					<Skeleton width="65px" height="16px" />
				</Box>
			</Box>
		</TrackRow>
	);
};

export default TrackSkeleton;
