import React, { useState } from "react";

import { EmailTemplate } from "shared/Components";
import ConfirmLeavePopup from "shared/Components/ConfirmLeave";
import useConfirmLeavePage from "shared/Components/ConfirmLeave/hooks/useConfirmLeavePage";
import { Box, ExpansionPanel, Icon } from "shared/ui-kit";

import theme from "theme/default";

import { EmailTemplateItems, Texts } from "./constants";
import {
	Container,
	ContentContainer,
	DescriptionContainer,
	HeadingContainer,
	IconContainer,
	Splitter,
	StyledDescriptionText,
	StyledHeadingText,
	TitleContainer
} from "./style";

const EmailTemplates = () => {
	const [expandedIndex, setExpandedIndex] = useState(-1);
	const expandOrCollapse = (id: number) => (id === expandedIndex ? setExpandedIndex(-1) : setExpandedIndex(id));

	const { handleLeavePageConfirmed, closeConfirmPopup, getData: getConfirmLeavePopupData } = useConfirmLeavePage({});
	const { showConfirmPopup } = getConfirmLeavePopupData();

	return (
		<>
			<ConfirmLeavePopup
				handleLeavePage={handleLeavePageConfirmed}
				open={showConfirmPopup}
				onClose={closeConfirmPopup}
			/>
			<Container>
				<StyledHeadingText variant="h3">{Texts.mainHeading}</StyledHeadingText>
				<StyledDescriptionText variant="subtitle1">{Texts.description}</StyledDescriptionText>
				<ExpansionPanel
					panels={EmailTemplateItems.map((el, i) => ({
						...el,
						expandId: el.expandId,
						title: (
							<TitleContainer>
								<IconContainer>
									<Icon fill={theme.palette.primary.main} name={"envelope"} group="filled" />
								</IconContainer>
								<HeadingContainer>
									<Box>{el.title}</Box>
									<DescriptionContainer>{el.description}</DescriptionContainer>
								</HeadingContainer>
							</TitleContainer>
						) as unknown as React.Component,
						content: (
							<ContentContainer>
								<Splitter />
								<EmailTemplate
									index={i}
									expandOrCollapse={expandOrCollapse}
									currentTemplate={el.templateType}
									emailSubjectId={el.emailSubjectId}
									emailBodyId={el.emailBodyId}
									emailCancelId={el.emailCancelId}
									emailSaveChangeId={el.emailSaveChangeId}
								/>
							</ContentContainer>
						) as unknown as React.Component,
						expanded: expandedIndex === i,
						onChange: () => expandOrCollapse(i)
					}))}
				/>
			</Container>
		</>
	);
};

export default EmailTemplates;
