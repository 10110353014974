import styled from "styled-components";

const CardList = styled.div`
	display: flex;
	& > * {
		margin-right: 8px;
	}
`;

const CardNumber = styled.div`
	margin-left: 16px;
	font-size: 15px;
	font-weight: 600;
`;

const AddPaymentMethod = styled.div`
	margin-left: auto;
`;

const AddNewCardButton = styled.button`
	display: flex;
	align-items: center;
	cursor: pointer;
	border: 0;
	background: none;

	span {
		margin-left: 4px;
		font-size: 15px;
		font-weight: 600;
	}
`;

export { CardList, CardNumber, AddPaymentMethod, AddNewCardButton };
