import React, { FC, useCallback } from "react";

import clsx from "clsx";

import { useUser } from "shared/hooks";

import { Text } from "shared/ui-kit";

import { DropdownProps } from "./Dropdown";

import { StyledMenuItem } from "./style";

export interface DropdownOptionsProps
	extends Pick<DropdownProps, "options" | "dropdownItemWrapperClassName" | "customDropdownItemRender"> {
	onSelect?: (item: any) => void;
}

const DropdownOptions: FC<DropdownOptionsProps> = ({
	options,
	customDropdownItemRender,
	dropdownItemWrapperClassName = "",
	onSelect
}) => {
	// const renderItem = useCallback((item: any) => <Text variants={TextVariantsEnum.Caption3}>{item?.label}</Text>, []);
	const renderItem = useCallback((item: any) => <Text>{item?.label}</Text>, []);

	const handleSelect = useCallback(
		(item?: any) => {
			onSelect && onSelect(item);
		},
		[onSelect]
	);

	const { getData: getUserData } = useUser();
	const { isMemberView } = getUserData();

	return (
		<>
			{options.map((item, index) => (
				<StyledMenuItem
					onClick={() => handleSelect(item)}
					id={item.id}
					key={item?.id || index}
					value={item.value}
					className={clsx(dropdownItemWrapperClassName, isMemberView && "member-styles")}
				>
					{customDropdownItemRender ? customDropdownItemRender(item) : renderItem(item)}
				</StyledMenuItem>
			))}
		</>
	);
};

export default DropdownOptions;
