import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from "react";

import { FormControl } from "@material-ui/core";

import { Controller, useForm } from "react-hook-form";

import { usePremiumContent } from "shared/hooks";
import { PriceTag } from "shared/types";
import { Select } from "shared/ui-kit";

import { Title } from "../style";

interface SubscriptionListProps {
	priceTagOptions: { label: string; value: string }[];
	defaultValue?: PriceTag[];
	onPriceChanged?: () => void;
}

const SubscriptionList = forwardRef<any, SubscriptionListProps>(
	({ priceTagOptions, defaultValue, onPriceChanged }, ref) => {
		const { getPriceInfo } = usePremiumContent();

		const [optionList, setOptionList] = useState<{ label: string; value: string }[]>([]);

		const {
			control,
			getValues,
			formState: { isDirty }
		} = useForm({
			mode: "onChange"
		});

		useEffect(() => {
			isDirty && onPriceChanged && onPriceChanged();
		}, [isDirty, onPriceChanged]);

		useImperativeHandle(ref, () => ({
			getFormValues() {
				return getValues();
			}
		}));

		useEffect(() => {
			setOptionList(priceTagOptions);
		}, [priceTagOptions]);

		const correctDefaultValue = useMemo(() => {
			if (!!defaultValue?.length) {
				const firstProduct = defaultValue[0];
				const existingOption = optionList.find(x => x.value === firstProduct.priceTagProductId);
				if (existingOption) {
					return existingOption.value;
				} else {
					const priceInfo = getPriceInfo(defaultValue);
					if (priceInfo?._id) {
						setOptionList(ol => ol.concat([{ label: `Unlock for ${priceInfo!.coins}`, value: priceInfo!._id }]));
						return priceInfo!._id;
					}
				}
			}
			return undefined;
		}, [defaultValue, optionList, getPriceInfo]);

		return (
			<>
				<Title variant="h6">Select Price tag</Title>
				<FormControl>
					<Controller
						name="priceTagId"
						rules={{
							required: "Price Tag is required for premium!"
						}}
						style={{ width: "100%" }}
						defaultValue={correctDefaultValue}
						control={control}
						render={({ onChange, value }) => (
							<Select
								showInputLabel={{ show: true }}
								options={optionList}
								value={value}
								label={"Select"}
								id={"price-tag-id"}
								onChange={onChange}
								showIcon
							/>
						)}
					/>
				</FormControl>
			</>
		);
	}
);

export default SubscriptionList;
