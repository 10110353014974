import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";

import { MenuItem, Select } from "@material-ui/core";

import { Controller, useFieldArray, useForm, useWatch } from "react-hook-form";

import { ReactComponent as VyooCoin } from "assets/icons/coin-24.svg";
import { PriceTag, WorkspaceProductModel } from "shared/types";

import { Tier } from "./styles";

import { Footer, Title } from "../style";

interface SubscriptionModel {
	label: string;
	monthlyId: string;
	annuallyId: string;
	monthlyPrice?: number;
	value?: number | string;
}

interface TierPriceOptionModel {
	label: number;
	value: string;
}

interface SubscriptionPremiumListProps {
	subscriptionProducts: WorkspaceProductModel[];
	tierPriceOptions: TierPriceOptionModel[];
	defaultValue?: PriceTag[];
	onPriceChanged?: () => void;
}

const SubscriptionPremiumList = forwardRef<any, SubscriptionPremiumListProps>(
	({ subscriptionProducts, tierPriceOptions, defaultValue, onPriceChanged }, ref) => {
		const {
			control,
			reset,
			getValues,
			setValue,
			formState: { isDirty }
		} = useForm({
			mode: "onChange"
		});

		const { fields } = useFieldArray({ name: "tiers", control });

		const tierPrices = useWatch({ control, name: "tiers" });

		const [subscriptionList, setSubscriptionList] = useState<SubscriptionModel[]>([]);
		const [highestPlanPriceOption, setHighestPlanPriceOption] = useState<TierPriceOptionModel>({
			label: 0,
			value: ""
		});

		useImperativeHandle(ref, () => ({
			getFormValues() {
				return getValues();
			}
		}));

		useEffect(() => {
			isDirty && onPriceChanged && onPriceChanged();
		}, [isDirty, onPriceChanged]);

		useEffect(() => {
			if (subscriptionProducts.length) {
				const freeSubscription = [
					{
						label: "Free members",
						monthlyId: "",
						annuallyId: ""
					}
				];

				const validTiers = subscriptionProducts
					.filter(x => x.monthly?.active || x.annually?.active)
					.sort((a, b) => (a.monthly?.price || 0) - (b.monthly?.price || 0))
					.map(x => ({
						label: x.name,
						monthlyId: x.monthly!._id,
						annuallyId: x.annually!._id,
						monthlyPrice: x.monthly?.price || 0
					}));

				setSubscriptionList(freeSubscription.concat(validTiers));
			}
		}, [subscriptionProducts]);

		useEffect(() => {
			reset({
				tiers: (subscriptionList || []).map(x => {
					let value: string | 0 = 0;

					if (defaultValue) {
						const selectedVal = defaultValue.find(
							v =>
								(v.subscriptionProductIds &&
									v.subscriptionProductIds.some(p => p === x.monthlyId) &&
									v.subscriptionProductIds.some(p => p === x.annuallyId)) ||
								(x.annuallyId === "" &&
									x.monthlyId === "" &&
									(!v.subscriptionProductIds || !v.subscriptionProductIds.length))
						);
						value = selectedVal?.priceTagProductId || 0;
					}

					return {
						label: x.label,
						monthlyId: x.monthlyId,
						annuallyId: x.annuallyId,
						monthlyPrice: x?.monthlyPrice,
						value
					};
				})
			});
		}, [subscriptionList, reset, defaultValue]);

		useEffect(() => {
			let priceyOption: TierPriceOptionModel = {
				label: 0,
				value: ""
			};

			if ((tierPrices as SubscriptionModel[])?.length) {
				for (let i = (tierPrices as SubscriptionModel[]).length - 1; i >= 0; i--) {
					const tier = (tierPrices as SubscriptionModel[])[i];

					const priceOption = tierPriceOptions.find(x => x.value === tier?.value);
					if (priceOption && priceOption.label > priceyOption.label) {
						priceyOption = priceOption;
					}

					if (priceyOption?.label && (!priceOption || priceOption.label < priceyOption.label)) {
						setValue(`tiers[${i}].value`, priceyOption.value);
					}

					if (i === (tierPrices as SubscriptionModel[]).length - 1) {
						setHighestPlanPriceOption(x =>
							priceOption
								? priceOption
								: x.label !== 0
								? {
										label: 0,
										value: ""
								  }
								: x
						);
					}
				}
			}
		}, [tierPrices, tierPriceOptions, setValue]);

		const RenderRows = (p, i: number) => {
			const { label, monthlyId, annuallyId, value, monthlyPrice } = p;

			return (
				<>
					<Tier.RowWrapper>
						<Controller
							name={`tiers[${i}].monthlyId`}
							control={control}
							defaultValue={monthlyId}
							render={({ value }) => <input type="text" hidden value={value} />}
						/>
						<Controller
							name={`tiers[${i}].annuallyId`}
							control={control}
							defaultValue={annuallyId}
							render={({ value }) => <input type="text" hidden value={value} />}
						/>

						<Tier.PlanNameWrapper className={!!monthlyId && "active"}>
							<Tier.PlanName>{label}</Tier.PlanName>
							<Tier.PlanPrice>{monthlyPrice ? `$${monthlyPrice} /month` : "Non subscribers"}</Tier.PlanPrice>
						</Tier.PlanNameWrapper>
						<Tier.PlanValueWrapper className={!!monthlyId && "active"}>
							<Controller
								name={`tiers[${i}].value`}
								control={control}
								placeholder="FREE"
								defaultValue={value}
								rules={{ required: "Required" }}
								render={({ onChange, value }) => (
									<Select onChange={onChange} value={value} variant="outlined">
										<MenuItem value={0} disabled={i !== fields.length - 1 && 0 < highestPlanPriceOption.label}>
											Free
										</MenuItem>
										{tierPriceOptions.map(tierPrice => (
											<MenuItem
												value={tierPrice.value}
												key={tierPrice.value}
												disabled={i !== fields.length - 1 && tierPrice.label < highestPlanPriceOption.label}
											>
												{tierPrice.label}
												<Tier.PriceOptionIcon>
													<VyooCoin />
												</Tier.PriceOptionIcon>
											</MenuItem>
										))}
									</Select>
								)}
							/>
						</Tier.PlanValueWrapper>
					</Tier.RowWrapper>
				</>
			);
		};

		return (
			<>
				<Title variant="h6">Define Price</Title>
				<Tier.Block>
					<Tier.RowWrapper className={"header"}>
						<Tier.PlanNameWrapper className={"header"}>
							<Tier.Title>Tiers</Tier.Title>
						</Tier.PlanNameWrapper>
						<Tier.PlanValueWrapper>
							<Tier.Title>Price</Tier.Title>
						</Tier.PlanValueWrapper>
					</Tier.RowWrapper>
					{fields.map((field, i) => RenderRows(field as any, i))}
				</Tier.Block>
				<Footer.Wrapper>
					<Footer.Text>Consider giving better prices and benefits to higher paying subscribers.</Footer.Text>
				</Footer.Wrapper>
			</>
		);
	}
);

export default SubscriptionPremiumList;
