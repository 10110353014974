import React, { FC, useEffect, useRef, useState } from "react";

import { Box, Switch, TextField } from "@material-ui/core";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import VisibilityIcon from "@material-ui/icons/Visibility";
import WatchLaterIcon from "@material-ui/icons/WatchLater";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { DatePicker, TimePicker } from "@material-ui/pickers";

import { DateTime } from "luxon";

import { Controller, useForm } from "react-hook-form";

import styled from "styled-components";

import { ReactComponent as DownloadIcon } from "assets/icons/icon-download.svg";

import { SubText } from "modules/Manage/View/Components/CreateUpdateAlbumDialog";
import { ErrorText } from "modules/Manage/View/Containers/ManageAlbums/style";
import { FeatureBlock, InputWrapper, UploaderWrapper } from "modules/Manage/View/Containers/style";
import { Dialog, SetupPrice } from "shared/Components";
import ConfirmLeavePopup from "shared/Components/ConfirmLeave";
import useConfirmLeavePopup from "shared/Components/ConfirmLeave/hooks/useConfirmLeavePopup";
import { useSeries } from "shared/hooks";
import useScrollToXPosition from "shared/hooks/useScrollToXPosition";
import { GroupModel } from "shared/types";
import { Button, Icon, Input, Text, Uploader, orientationConst } from "shared/ui-kit";

import { mergeDateAndTime } from "utils/serviceUtils/helpers";

import { SeriesDialogProps } from "./MemberView";

const StyledActionButton = styled(Button)`
	height: 48px;
`;

const CreateSeriesManageView: FC<SeriesDialogProps> = ({
	createSeries,
	loadSuggestedCategories,
	suggestedCategories,
	uploadPhoto,
	uploadingCover,
	editModel,
	setupPriceFormRef,
	isFreeCommunity = false
}) => {
	const {
		reset,
		control,
		watch,
		handleSubmit,
		setError,
		clearErrors,
		formState: { isDirty, errors, isSubmitting, isValid }
	} = useForm({ mode: "onChange" });

	const [step, setStep] = useState(1);
	const { setSeriesDialog } = useSeries();
	const {
		handleClose,
		handleLeavePageConfirmed,
		closeConfirmPopup,
		getData: getConfirmLeavePopupData
	} = useConfirmLeavePopup({
		onClose: () => setSeriesDialog({ open: false }),
		open: true
	});

	const { showConfirmPopup } = getConfirmLeavePopupData();

	const { scheduleDate, scheduleTime, price, schedule } = watch([
		"scheduleTime",
		"scheduleDate",
		"private",
		"price",
		"schedule"
	]);

	const bodyRef = useRef<HTMLFormElement>();
	const scrollToXPositionOnFocus = useScrollToXPosition(bodyRef?.current);

	useEffect(() => {
		if (scheduleDate && scheduleTime) {
			const date = new Date(scheduleDate).setHours(
				new Date(scheduleTime).getHours(),
				new Date(scheduleTime).getMinutes()
			);

			if (date < new Date().getTime()) {
				setError("scheduleDate", {
					message: "Time can't be in the past"
				});
				setError("scheduleTime", {
					message: " "
				});
			} else {
				clearErrors(["scheduleDate", "scheduleTime"]);
			}
		}
	}, [scheduleDate, scheduleTime, setError, clearErrors]);

	useEffect(() => {
		if (editModel) {
			reset({
				...editModel,
				category: editModel?.categoryId ? { name: editModel?.category?.name, _id: editModel?.categoryId } : undefined
			});
		}
	}, [editModel, reset]);

	const onSubmit = data => {
		if (step === 1) {
			setStep(2);
		} else {
			delete data.priceChanged;
			let scheduleDate: Date | undefined;

			if (data.scheduleTime && data.scheduleDate) {
				scheduleDate = mergeDateAndTime(data.scheduleDate, data.scheduleTime);
			}

			createSeries({
				...data,
				categoryId: data.category?._id,
				scheduleDate
			});
		}
	};

	const ActionButton = (
		<StyledActionButton
			id={step === 1 ? "next" : editModel ? "saveSeriesChanges" : "createSeries"}
			onClick={handleSubmit(onSubmit)}
			disabled={isSubmitting || uploadingCover || (step === 1 && (!isDirty || !isValid))}
		>
			{uploadingCover ? "Uploading Image.." : step === 1 ? "Next" : editModel ? "Save Changes" : "Create Series"}
		</StyledActionButton>
	);

	return (
		<>
			<ConfirmLeavePopup
				handleLeavePage={handleLeavePageConfirmed}
				open={showConfirmPopup}
				onClose={closeConfirmPopup}
				popup
			/>
			<Dialog
				bodyCustomStyles="overflow: hidden;"
				hasBackButton={step === 2}
				onBack={() => setStep(1)}
				open={true}
				onClose={() => handleClose(isDirty)}
				title={
					<>
						<Text variant="h7">{editModel ? "Update" : "Create"} Series</Text> <SubText>• Step {step}/2</SubText>
					</>
				}
				bodyRef={bodyRef}
				footer={ActionButton}
			>
				<form onSubmit={handleSubmit(onSubmit)}>
					<Box style={{ display: step === 1 ? "block" : "none" }}>
						<UploaderWrapper>
							<Controller
								name="artwork"
								control={control}
								defaultValue={editModel && editModel.meta?.artwork?.url}
								rules={{ required: "Image is required" }}
								render={({ onChange, value }) => (
									<Uploader
										urls={value ? [value] : undefined}
										onChange={(files: any) => uploadPhoto(files, onChange)}
										label="Upload Series Photo"
										orientation={orientationConst.horizontal}
										width="100%"
										height="auto"
										description={
											<>
												Drag and Drop File Here or <span className="anchor">Browse</span> to Choose a File
											</>
										}
										accept={[
											{
												fileType: "image/png, image/jpeg, image/x-eps",
												name: "png, jpg, eps"
											}
										]}
										icon={<Icon fill="#c5cee0" name="cloud-upload" width={64} height={50} viewBox="4 2 18 19" />}
									/>
								)}
							/>
							{errors.artwork && <ErrorText>{errors.artwork.message}</ErrorText>}
						</UploaderWrapper>
						<InputWrapper>
							<Controller
								name="title"
								control={control}
								defaultValue={editModel && editModel.title}
								rules={{
									required: "Series Title is required.",
									maxLength: {
										value: 100,
										message: "Title can't be more than 100 letters"
									}
								}}
								render={({ onChange, value }) => (
									<Input
										errorText={errors.title?.message}
										label="Series title"
										onChange={onChange}
										value={value}
										onFocus={scrollToXPositionOnFocus}
										id="SeriesName"
									/>
								)}
							/>
						</InputWrapper>
						<InputWrapper>
							<Controller
								name="category"
								control={control}
								rules={{ required: "Category is required!", validate: value => !!value }}
								render={({ onChange, value }) => (
									<Autocomplete
										getOptionLabel={option => (typeof option === "string" ? option : option.name)}
										options={suggestedCategories}
										autoComplete
										fullWidth
										includeInputInList
										filterSelectedOptions
										value={value}
										onChange={(event, newValue: GroupModel | null) => onChange(newValue)}
										onInputChange={(group, newInputValue) => loadSuggestedCategories(newInputValue)}
										renderInput={params => (
											<TextField
												{...params}
												label="Select Category*"
												variant="outlined"
												fullWidth
												error={errors?.category}
												helperText={errors?.category?.message}
												onFocus={scrollToXPositionOnFocus}
											/>
										)}
										id="seriesCategories"
										renderOption={option => <Text id={`category${option.name}`}>{option.name}</Text>}
									/>
								)}
							/>
						</InputWrapper>
						<InputWrapper>
							<Controller
								name="description"
								control={control}
								defaultValue={editModel && editModel.description}
								rules={{
									required: "Description is required.",
									maxLength: {
										value: 1000,
										message: "Description can't be more than 1000 letters"
									}
								}}
								render={({ onChange, value }) => (
									<TextField
										error={errors.description}
										helperText={errors.description?.message}
										name="description"
										onChange={onChange}
										value={value}
										label="Description *"
										variant="outlined"
										multiline
										rows={3}
										onFocus={scrollToXPositionOnFocus}
										id="categoryDescription"
									/>
								)}
							/>
						</InputWrapper>
					</Box>
					<Box style={{ display: step === 2 ? "block" : "none" }}>
						{!isFreeCommunity && (
							<Controller
								name="price"
								control={control}
								defaultValue={!!editModel?.priceTags?.length}
								render={({ onChange, value }) => (
									<FeatureBlock>
										<Box className="head">
											<Box className="left-side">
												<MonetizationOnIcon />
												<Text variant="subtitle1">Set a Price</Text>
											</Box>
											<Switch checked={value} onChange={e => onChange(e.target.checked)} id="setPrice" />
										</Box>

										{price && (
											<Controller
												name="priceChanged"
												control={control}
												render={({ onChange }) => (
													<SetupPrice
														ref={setupPriceFormRef}
														defaultValue={editModel?.priceTags}
														onPriceChanged={() => onChange(true)}
													/>
												)}
											/>
										)}
									</FeatureBlock>
								)}
							/>
						)}
						<Controller
							name="individualAccess"
							control={control}
							defaultValue={editModel?.meta.individualAccess}
							render={({ onChange, value }) => (
								<FeatureBlock className="pb-8">
									<Box className="head">
										<Box className="left-side">
											<Icon name="folder-open-gray" group="color" />
											<Box>
												<Text variant="subtitle1">Allow access to content in Series</Text>
												<Text variant="body2">
													Allow users to buy or access the individual content in the series without buying entire
													series.
												</Text>
											</Box>
										</Box>
										<Switch checked={value} onChange={e => onChange(e.target.checked)} id="allowAccessToContent" />
									</Box>
								</FeatureBlock>
							)}
						/>
						<Controller
							name="schedule"
							control={control}
							defaultValue={!!editModel?.meta.scheduleDate}
							render={({ onChange, value }) => (
								<FeatureBlock>
									<Box className="head">
										<Box className="left-side">
											<WatchLaterIcon />
											<Text variant="subtitle1">Schedule Series</Text>
										</Box>
										<Switch checked={value} onChange={e => onChange(e.target.checked)} id="scheduleSeries" />
									</Box>
									{schedule && (
										<Box className="input multiple">
											<Controller
												name="scheduleDate"
												rules={{ required: schedule ? "Schedule Date is required" : false }}
												control={control}
												defaultValue={editModel && editModel.meta.scheduleDate}
												render={({ onChange, value = null }) => (
													<DatePicker
														placeholder="Schedule Date"
														autoOk
														minDate={DateTime.now()}
														inputVariant="outlined"
														variant="inline"
														value={value}
														onChange={d => onChange(d)}
														error={!!errors.scheduleDate}
														helperText={errors.scheduleDate?.message}
														id="scheduleDateSeries"
													/>
												)}
											/>
											<Controller
												name="scheduleTime"
												rules={{ required: schedule ? "Schedule Time is required" : false }}
												control={control}
												defaultValue={editModel && editModel.meta.scheduleDate}
												render={({ onChange, value = null }) => (
													<TimePicker
														placeholder="Select Time"
														autoOk
														inputVariant="outlined"
														variant="inline"
														value={value}
														onChange={d => onChange(d)}
														error={!!errors.scheduleTime}
														helperText={errors.scheduleTime?.message}
														id="scheduleTimeSeries"
													/>
												)}
											/>
										</Box>
									)}
								</FeatureBlock>
							)}
						/>
						<Controller
							name="downloadable"
							control={control}
							defaultValue={editModel?.meta.canBeDownloaded}
							render={({ onChange, value }) => (
								<FeatureBlock>
									<Box className="head">
										<Box className="left-side">
											<DownloadIcon />
											<Text variant="subtitle1">Allow Download</Text>
										</Box>
										<Switch checked={value} onChange={e => onChange(e.target.checked)} id="downloadSeries" />
									</Box>
								</FeatureBlock>
							)}
						/>
						<Controller
							name="private"
							control={control}
							defaultValue={editModel?.meta.private}
							render={({ onChange, value }) => (
								<FeatureBlock noBorder>
									<Box className="head">
										<Box className="left-side">
											<VisibilityIcon />
											<Box>
												<Text variant="subtitle1">Set as Private</Text>
												<Text variant="body2">Choose this option to hide from others in the community</Text>
											</Box>
										</Box>
										<Switch checked={value} onChange={e => onChange(e.target.checked)} id="privateSeries" />
									</Box>
								</FeatureBlock>
							)}
						/>
					</Box>
				</form>
			</Dialog>
		</>
	);
};

export default CreateSeriesManageView;
