import { Link } from "react-router-dom";
import styled from "styled-components";

import { Box, Text } from "shared/ui-kit";

export const Item = styled(Box)``;

Item.Wrapper = styled(Link)`
	display: flex;
	align-items: center;
	padding: 8px;
	cursor: pointer;
	width: 100%;
	text-decoration: none;

	&:hover {
		background: #f7f9fc;
		border-radius: 12px;
	}
`;

Item.IconWrapper = styled(Box)`
	width: 40px;
	height: 40px;

	border-radius: 50%;
	background-color: #edf1f7;

	margin-right: 15px;

	overflow: hidden;

	display: flex;
	justify-content: center;
	align-items: center;
`;

Item.Icon = styled(Box)<{ color?: string }>`
	display: inline-flex;
	justify-content: center;
	align-items: center;

	width: 20px;
	height: 20px;

	svg {
		width: 100%;
		height: 100%;

		padding: 0;
		margin: 0 !important;
		fill: ${({ color }) => color};

		use {
			fill: ${({ color }) => color};
		}
	}

	&.color-first-path {
		svg {
			path {
				&:first-child {
					fill: ${({ color }) => color};
				}
			}
		}
	}

	&.color-all-paths {
		svg {
			path {
				fill: ${({ color }) => color};
			}
		}
	}
`;

Item.InfoBlock = styled(Box)`
	display: block;
`;

Item.Title = styled(Text)`
	font-size: 15px;
	font-weight: 600;
	line-height: 1.6;
	color: #222b45;
`;

Item.TopicCount = styled(Text)`
	font-size: 13px;
	font-weight: 600;
	line-height: 1.45;
	color: #8f9bb3;
`;
