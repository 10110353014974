import React, { FC } from "react";

import { useMediaQuery } from "@material-ui/core";

import { FileType } from "types/FilesContextValuesType";

import { SeriesCollection } from "modules/Manage/Data/types";
import { ManInLotus, NoConnectionsBlock } from "modules/MemberHome/View/Containers/Connections/style";
import { useCommunity } from "shared/hooks";
import { EventModel, GroupModel, TrackModel } from "shared/types";
import { Button, Icon, Text } from "shared/ui-kit";
import * as appTheme from "theme/default";

import { ModelBlockType } from "../../../ModelBlock";
import { MessageText } from "../../../ModelBlock/style";
import { ModelBlock } from "../../../index";
import { GroupSectionHeadline, SectionWrapper } from "../../style";

const GroupSectionTemplate: FC<{
	items: (EventModel | TrackModel | FileType | GroupModel | SeriesCollection)[];
	type: ModelBlockType;
	title: string;
	actionButton?: {
		title: string;
		onClick: () => void;
	};
	noItemsErrorText: string;
	onEndScroll?: () => void;
	autoFit?: boolean | number;
	loading?: boolean;
	keepCurrentData?: boolean;
}> = ({ items, type, title, actionButton, noItemsErrorText, onEndScroll, autoFit, loading, keepCurrentData }) => {
	const isMobile = useMediaQuery(appTheme.default.breakpoints.down("sm"));
	const { communityColors } = useCommunity();
	return (
		<SectionWrapper>
			<GroupSectionHeadline bgColor={communityColors.btn}>
				<Text variant="h3">{title}</Text>
				{actionButton && (
					<Button buttonTheme="light" onClick={actionButton.onClick}>
						<Icon name="plus" group="filled" width={16} height={16} className="fill-last-path" />
						{actionButton.title}
					</Button>
				)}
			</GroupSectionHeadline>
			<ModelBlock
				loading={loading}
				autoFit={autoFit}
				type={type}
				items={items}
				inlineView={!isMobile}
				onEndScroll={onEndScroll}
				keepCurrentData={keepCurrentData}
				noContent={
					<NoConnectionsBlock>
						<ManInLotus />
						<MessageText>{noItemsErrorText}</MessageText>
					</NoConnectionsBlock>
				}
			/>
		</SectionWrapper>
	);
};

export default GroupSectionTemplate;
