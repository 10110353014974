import config from "config/appConfig";

import { EventCategory, EventModel, Location } from "shared/types";
import { EventRequests } from "shared/types/EventModel";
import { IStateProps } from "utils/StateOf";

import createStore from "utils/createStore";

const localStorageKey = `${config.GLOBAL_CONSTANTS.LOCAL_STORAGE_PREFIX}${config.HOOK_STORAGE_KEYS.EVENT_DATA}`;

export interface Values {
	searchText?: string;
	suggestedLocations: Location[];
	categories: EventCategory[];
	filteredEvents: EventModel[] | null;
	eventImageUploadUrl: string;
	eventCount: number;
	allEventCount?: number;
	loading: boolean;
	offset: number;
	creating: boolean;
	page: number;
	eventsShowPerPage: number;
	requestEvents: EventRequests[];
	shouldRequestStop: boolean;
	inviteEventDialog?: boolean;
}

export const initialState: IStateProps<Values> = {
	categories: [],
	filteredEvents: null,
	suggestedLocations: [],
	eventImageUploadUrl: "",
	eventCount: 0,
	offset: 0,
	loading: false,
	creating: false,
	page: 1,
	eventsShowPerPage: 20,
	requestEvents: [],
	shouldRequestStop: false
};

export default createStore<Values>({
	initialState,
	persistant: true,
	persistantName: localStorageKey
});
