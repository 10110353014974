import React, { FC, useCallback, useEffect, useMemo, useState } from "react";

import { SearchTracksWrapper, TracksScrollableList } from "modules/Manage/View/Components/AddTracksToPlaylist/style";
import { MemberDialog } from "modules/MemberHome/View/shared";
import { useDebounce, usePost } from "shared/hooks";
import { CategoryModel, EventModel, GroupModel } from "shared/types";

import { Box, Search } from "shared/ui-kit";

import { ButtonGroupButton, ButtonGroupWrapper } from "./style";
import BoardsTab from "./tabs/BoardsTab";
import EventsTab from "./tabs/EventsTab";
import GroupsTab from "./tabs/GroupsTab";

enum PostTabs {
	BOARDS,
	GROUPS,
	EVENTS
}

interface ChosenItem {
	id: string;
	name: string;
	image?: string;
}

export type AllSelectedItemInterface = {
	boardIds?: ChosenItem[];
	rootPostIds?: ChosenItem[];
} | null;

const ChooseBoardsDialog: FC<{
	handleClose: () => void;
	value: AllSelectedItemInterface;
	onChange: (val: AllSelectedItemInterface) => void;
}> = ({ handleClose, value, onChange }) => {
	const [loadData, setLoadData] = useState(true);
	const [searchText, setSearchText] = useState("");
	const [activeTab, setActiveTab] = useState<PostTabs>(PostTabs.BOARDS);
	const [selectedBoard, setSelectedBoard] = useState<CategoryModel>();
	const [selectedGroup, setSelectedGroup] = useState<GroupModel[]>();
	const [selectedEvent, setSelectedEvent] = useState<EventModel>();
	const [allSelectedItems, setAllSelectedItems] = useState<AllSelectedItemInterface>(null);

	const { getData: getPostData } = usePost();
	const { categories } = getPostData();

	const debouncedSearchTerm = useDebounce(searchText, 300);

	const handleSwitchTab = (type: PostTabs) => {
		setActiveTab(type);
		setSearchText("");
	};

	const onClose = useCallback(() => {
		allSelectedItems && onChange(allSelectedItems);
		handleClose();
	}, [allSelectedItems, handleClose, onChange]);

	const category = useMemo(() => {
		if (value?.boardIds && value.boardIds[0].id) {
			return categories.find(v => v.cid === (value.boardIds || [])[0].id);
		}
	}, [categories, value]);

	useEffect(() => {
		if (category) setSelectedBoard(category);
	}, [category, setSelectedBoard]);

	useEffect(() => {
		if (selectedBoard?.cid) {
			setAllSelectedItems(ctx => ({
				...ctx,
				boardIds: [
					{
						id: selectedBoard.cid,
						name: selectedBoard.name
					}
				]
			}));
		}

		if (selectedGroup) {
			setAllSelectedItems(ctx => ({
				...ctx,
				rootPostIds: selectedGroup.map(g => ({
					id: g.postId,
					name: g.name,
					image: g.coverUrl
				}))
			}));
		}
	}, [selectedBoard, selectedGroup]);

	return (
		<MemberDialog
			open
			title="Where to Post"
			onClose={onClose}
			customWidth={512}
			footerNote="Select up to 5 places"
			footerPrimary={{
				text: "Done",
				onClick: onClose
			}}
		>
			<div className="py-6 px-4">
				<ButtonGroupWrapper>
					<ButtonGroupButton onClick={() => handleSwitchTab(PostTabs.BOARDS)} active={activeTab === PostTabs.BOARDS}>
						Boards
					</ButtonGroupButton>
					<ButtonGroupButton onClick={() => handleSwitchTab(PostTabs.GROUPS)} active={activeTab === PostTabs.GROUPS}>
						Groups
					</ButtonGroupButton>
					<ButtonGroupButton onClick={() => handleSwitchTab(PostTabs.EVENTS)} active={activeTab === PostTabs.EVENTS}>
						Events
					</ButtonGroupButton>
				</ButtonGroupWrapper>
				<SearchTracksWrapper whiteBG className="mb-2">
					<Search
						fullWidth
						value={searchText}
						placeholder="Search"
						onChange={e => {
							setSearchText(e.target.value);
							if (!loadData) {
								setLoadData(true);
							}
						}}
					/>
				</SearchTracksWrapper>
				<TracksScrollableList>
					<Box display={activeTab === PostTabs.BOARDS ? "block" : "none"}>
						<BoardsTab
							active
							searchTerm={debouncedSearchTerm || ""}
							selectedBoard={selectedBoard}
							setSelectedBoard={setSelectedBoard}
						/>
					</Box>
					<Box display={activeTab === PostTabs.GROUPS ? "block" : "none"}>
						<GroupsTab
							active
							searchTerm={debouncedSearchTerm || ""}
							selectedGroup={selectedGroup}
							setSelectedGroup={setSelectedGroup}
							itemsCount={selectedGroup?.length || 0}
						/>
					</Box>
					<Box display={activeTab === PostTabs.EVENTS ? "block" : "none"}>
						<EventsTab
							active
							searchTerm={debouncedSearchTerm || ""}
							selectedEvent={selectedEvent}
							setSelectedEvent={setSelectedEvent}
						/>
					</Box>
				</TracksScrollableList>
			</div>
		</MemberDialog>
	);
};

export default ChooseBoardsDialog;
