import React, { memo, useMemo } from "react";

import { useFirebaseLiveConversation } from "modules/LiveConversation/Data/hooks";
import { ConversationWithEvent } from "modules/LiveConversation/Data/types";

import { PlaceholderImage, UnlockPremiumBtn } from "shared/Components";
import { useMemberRoutes } from "shared/hooks";
import { PlaceholderImageType, UnlockContentType } from "shared/types";
import { Button } from "shared/ui-kit";

import { ModelItem } from "../index";

interface ILiveConvoCardProps {
	conversation: ConversationWithEvent;
}

const LiveConvoCard: React.FC<ILiveConvoCardProps> = memo(({ conversation }) => {
	const { event, participants: participantsObj } = conversation;
	const eventImage = event?.eventImages.length ? event.eventImages[0] : "";
	const participants = !!participantsObj ? Object.values(participantsObj) : [];
	const { onJoinConversation } = useFirebaseLiveConversation();

	const { getMemberRoutesData } = useMemberRoutes();
	const { routes } = getMemberRoutesData();

	const onTapJoin = () => {
		onJoinConversation(conversation);
	};

	const href = useMemo(
		() => `${routes?.member.event.getPath()}/${conversation.event.eventId}`,
		[conversation.event.eventId, routes?.member.event]
	);

	return (
		<ModelItem
			item={{
				_id: event.eventId,
				wrapByHref: true,
				href,
				img: eventImage,
				placeholderImg: (
					<PlaceholderImage
						type={PlaceholderImageType.EVENT_DETAILS}
						width={360}
						height={160}
						viewBox={"0 0 720 320"}
					/>
				),
				beforeTitle: "Live Now",
				title: event.title,
				subtitle: event?.categories.join(", "),
				underTitle: event.onlineUrl,
				peopleList: participants?.map(participant => participant.avatarUrl),
				peopleListText: `${participants.length} viewers`,
				action: event.locked ? (
					<UnlockPremiumBtn
						premiumObject={event}
						premiumObjectType={UnlockContentType.EVENT}
						onUnlock={onTapJoin}
						text="Buy"
						ignoreWidth
					/>
				) : (
					<Button onClick={onTapJoin}>Join now</Button>
				),
				showLiveBadge: true
			}}
		/>
	);
});

export default LiveConvoCard;
