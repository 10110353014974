import React from "react";

interface TabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;
}

function TabPanel(props: TabPanelProps) {
	const { children, value, index } = props;

	return (
		<div role="tabpanel" hidden={value !== index}>
			{value === index && <div>{children}</div>}
		</div>
	);
}

export default TabPanel;
