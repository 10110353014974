import { Box } from "@material-ui/core";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

import { Text } from "shared/ui-kit";

const sharedStyle = css`
	display: flex;
	align-items: center;
	padding: 10px 3px;
	cursor: pointer;
	text-decoration: none;
`;

export const Wrapper = styled(Box)`
	width: 100%;
	height: 100%;

	background-color: #ffffff;

	overflow-y: auto;

	padding: 16px 24px;
`;

export const Title = styled(Text)`
	font-size: 22px;
	font-weight: bold;
	line-height: 1.45;
	letter-spacing: normal;
	color: #222b45;
`;

export const ListWrapper = styled(Box)`
	width: 100%;

	margin-top: 24px;
`;

export const MemberPanelBtnWrapper = styled(Box)`
	width: 100%;

	margin: 0 0 16px 0;
	padding: 0;
`;

export const Item = styled(Box)``;

Item.Link = styled(Link)`
	${sharedStyle}
`;

Item.Menu = styled.div`
	${sharedStyle}
`;

Item.IconWrapper = styled(Box)<{ color?: string; imgUrl?: string }>`
	width: 30px;
	height: 30px;

	margin-right: 15px;

	display: flex;
	justify-content: center;
	align-items: center;

	svg {
		g {
			g {
				fill: ${({ color }) => color};
			}
		}

		&.fundraiser-fill {
			path {
				fill: ${({ color }) => color || undefined};
			}
		}
	}

	.colorful-logout-main {
		fill: ${({ color }) => color};
	}

	${({ imgUrl }) =>
		imgUrl &&
		css`
			background-image: url(${imgUrl});
			background-position: center;
			background-size: cover;
			background-repeat: no-repeat;
		`}
`;

Item.Title = styled(Text)`
	font-size: 15px;
	font-weight: 600;
	line-height: 1.6;
	color: #222b45;
`;
