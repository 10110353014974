import { Box } from "@material-ui/core";
import styled from "styled-components";

import { Text } from "shared/ui-kit";

export const Chip = styled.span`
	display: inline-block;

	font-size: 10px;
	font-weight: 600;
	text-align: center;

	margin-top: 6px;
	padding: 4px 8px;

	border-radius: 16px;

	&.free {
		color: #222b45;
		background-color: #f7f9fc;
	}

	&.premium {
		background-color: #ebecff;
		color: #6173fe;
	}
`;

export const PointsAmountBox = styled(Box)`
	position: relative;

	width: 120px;
	height: 40px;

	padding: 8px 16px;

	border-radius: 4px;

	background-color: #f7f9fc;
`;

PointsAmountBox.Value = styled(Text)`
	font-size: 15px;
	font-weight: 600;
	line-height: 1.6;
	color: #222b45;
`;

PointsAmountBox.IconWrapper = styled(Text)`
	height: 100%;

	position: absolute;
	top: 0;
	right: 16px;

	display: flex;
	align-items: center;
`;
