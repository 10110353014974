import React, { useCallback, useEffect } from "react";

import { Box, InputAdornment, FormControl as MuiFormControl, TextField } from "@material-ui/core";
import config from "config/appConfig";
import * as R from "ramda";
import { Controller, SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import styled from "styled-components";

import { ReactComponent as CoinYellowIcon } from "assets/icons/iconCoinYellow.svg";

import useConfirmLeavePage from "shared/Components/ConfirmLeave/hooks/useConfirmLeavePage";
import { useCommunity } from "shared/hooks";
import { useS3Uploader } from "shared/services/s3Uploader";
import { WorkspaceProductModel, WorkspaceProductPayloadType } from "shared/types";
import { Button, Icon, Loader, Text, Uploader, orientationConst } from "shared/ui-kit";

import { dataUrlToFile } from "utils/serviceUtils/helpers";

const FormWrapper = styled.form`
	font-size: 15px;
	line-height: 1.47;
	color: #222b45;
`;

const GeneralWrapper = styled(Box)`
	.bag-wrapper:not(:last-child) {
		border-bottom: 1px solid #e4e9f2;
	}
`;

const Wrapper = styled(Box)`
	padding: 24px 0;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;

	label {
		font-size: 15px;
		font-weight: 600;
		line-height: 1.6;
		color: #222b45;
	}
`;

const BodyWrapper = styled(Box)`
	margin: 20px 0;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	max-width: 232px;
`;

const LineWrapper = styled(Box)`
	margin-top: 21px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
`;

const FormControl = styled(MuiFormControl)`
	&:last-child {
		margin-left: 8px;
	}
`;

const CancelButton = styled(Button)`
	float: right;
	min-width: 152px;
`;

const CreateTagsBtn = styled(Button)`
	margin-left: 10px;
	min-width: 152px;
	float: right;
`;

const UploaderWrapper = styled(Box)`
	width: 20%;
	margin-top: 20px;
	margin-right: 40px;
	display: block;
	align-items: center;
	background-color: white;
	justify-content: center;

	.item-description {
		display: none;
	}
	.explorer-uploader {
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.explorer-uploader .icon {
		margin: 0;
	}
	.accept-extension {
		display: none;
	}
	.preview-section {
		.item-img-section {
			width: 100%;
			height: 100%;
			img {
				object-fit: contain;
				width: 100%;
			}
		}
	}
	.explorer-uploader-label-text {
		color: #222b45;
	}
	.explorer-uploader .description .anchor {
		color: #6173fe;
	}
	.uploader-wrapper {
		z-index: 1000;
		background: white;
		margin-top: 4px;
	}
	.close {
		z-index: 1000;
	}
`;

type FormValues = {
	bags: WorkspaceProductModel[];
};

const CoinsAndBundles = () => {
	const { getBagsList, updatePremiumProduct, getData: getCommunityData } = useCommunity();
	const { bags, isLoading, workspace } = getCommunityData();

	const { uploadFile } = useS3Uploader();

	useEffect(() => {
		getBagsList();
	}, [getBagsList]);

	const { formState, control, handleSubmit, register, reset, errors } = useForm<FormValues>();
	const { fields } = useFieldArray({ name: "bags", control });

	const { provideHandleCloseValues } = useConfirmLeavePage({});

	const { isDirty } = formState;

	useEffect(() => {
		provideHandleCloseValues(isDirty);
	}, [isDirty, provideHandleCloseValues]);

	useEffect(() => {
		reset({ bags: bags });
	}, [bags, reset]);

	const onUploaderChange = useCallback(
		async (files: any[], onChange: (...event: any[]) => void) => {
			const img = files && files.length ? files[0] : null;
			const correctFile = typeof img === "string" ? await dataUrlToFile(img, "test") : img;
			if (img) {
				const data = await uploadFile({
					file: correctFile as File,
					communityName: `${workspace?.communityUrl}`
				});
				onChange(data?.publicUrl);
			} else {
				onChange(null);
			}
		},
		[uploadFile, workspace]
	);

	const onSubmit: SubmitHandler<FormValues> = async ({ bags: data }) => {
		const payload: WorkspaceProductPayloadType[] = bags.reduce((p, c) => {
			const i = data.find(d => d._id === c._id);

			if (i && (c.name !== i.name || c.image !== i.image)) {
				p.push({
					...R.pick(["name", "image"], i),
					...R.pick(["coins", "sku", "price"], c),
					id: c._id,
					removed: false,
					update: true
				});
			}

			return p;
		}, [] as WorkspaceProductPayloadType[]);

		await updatePremiumProduct({ data: [...payload] }, true);
	};

	return (
		<FormWrapper onSubmit={handleSubmit(onSubmit)}>
			<Text variant="caption2">
				Coins are used across the app in order to allow you to monetize your content. By default we have{" "}
				{config.GLOBAL_CONSTANTS.ENV_APPLICATION_NAME} Coins, however, we have made it accessible for you to change the
				name and icon of the coins and bundles, you can change to names in alignment with you brand and you can upload
				icons that best reflect you.
			</Text>
			<Text variant="subtitle1" style={{ margin: "12px 0" }}>
				Some tips on choosing a good icon:
			</Text>
			<ul>
				<li>Use a solid background color.</li>
				<li>Use a graphical logo or image rather than text.</li>
				<li>Leave some space around your icon.</li>
				<li>Upload an image that is 200px square or larger.</li>
			</ul>
			<GeneralWrapper>
				{fields.map((f, i) => (
					<Wrapper key={f.id} className="bag-wrapper">
						<input type="hidden" defaultValue={f._id} name={`bags[${i}]._id`} ref={register()} />
						<UploaderWrapper>
							<label>Coin Icon</label>
							<Controller
								name={`bags[${i}].image`}
								control={control}
								rules={{
									required: "Field is required."
								}}
								render={({ onChange, value }) => (
									<Uploader
										onChange={(files: any) => onUploaderChange(files, onChange)}
										orientation={orientationConst.vertical}
										urls={!value && f.image ? [f.image] : value ? [value] : undefined}
										width={"100%"}
										height={80}
										label={" "}
										description={<></>}
										accept={[
											{
												fileType: "image/png, image/jpeg, image/x-eps",
												name: "png, jpg, eps"
											}
										]}
										icon={
											<Icon
												group={""}
												fill={"#c5cee0"}
												name={"cloud-upload"}
												width={30}
												height={20}
												viewBox={"4 2 18 19"}
											/>
										}
										closeIconId={`coinIcon${f.name.replaceAll(" ", "")}`}
										uploaderId={`coinIcon${f.name.replaceAll(" ", "")}`}
									/>
								)}
							/>
						</UploaderWrapper>
						<BodyWrapper>
							<FormControl>
								<label>Coin Name</label>
								<TextField
									name={`bags[${i}].name`}
									variant="outlined"
									defaultValue={f.name}
									inputRef={register({ required: "Name is required!" })}
									placeholder="Name"
									error={errors[`bags[${i}].name`]}
									helperText={errors[`bags[${i}].name`] ? errors[`bags[${i}].name`].message || "Invalid Name" : ""}
									id={`coinName${f.name.replaceAll(" ", "")}`}
								/>
							</FormControl>
							<LineWrapper>
								<FormControl>
									<label>Coin Qty</label>
									<TextField
										disabled
										variant="outlined"
										value={f.coins}
										InputProps={{
											startAdornment: (
												<InputAdornment position="start">
													<CoinYellowIcon />
												</InputAdornment>
											)
										}}
									/>
								</FormControl>
								<FormControl>
									<label>Value</label>
									<TextField
										disabled
										variant="outlined"
										value={f.price}
										InputProps={{
											startAdornment: <InputAdornment position="start">$</InputAdornment>
										}}
									/>
								</FormControl>
							</LineWrapper>
						</BodyWrapper>
					</Wrapper>
				))}
			</GeneralWrapper>
			<CreateTagsBtn
				disabled={!formState.isDirty || isLoading}
				type="submit"
				size="large"
				leftIcon={isLoading ? <Loader size="1rem" show={true} color="inherit" variant="indeterminate" /> : undefined}
				id="saveChangesCommunityCoins"
			>
				Save Changes
			</CreateTagsBtn>
			<CancelButton
				onClick={() => reset({ bags })}
				disabled={!formState.isDirty || isLoading}
				buttonTheme="outline"
				type="reset"
				size="large"
				palette="control"
				id="resetCommunityCoins"
			>
				Reset
			</CancelButton>
		</FormWrapper>
	);
};

export default CoinsAndBundles;
