import React, { memo } from "react";

import { Box } from "@material-ui/core";

import { ReactComponent as EyeIcon } from "assets/icons/eye.svg";
import { ReactComponent as MicroPhoneFilledIcon } from "assets/icons/liveconvo/microphone-filled.svg";
import { ConversationWithEvent } from "modules/LiveConversation/Data/types";

import { PlaceholderImage, UnlockPremiumBtn } from "shared/Components";
import { PlaceholderImageType, UnlockContentType } from "shared/types";

import { ConvoBox, JoinBtn } from "./style";

const iconProps = {
	height: 14,
	width: 14,
	fill: "#fff"
};

interface IConversationCardProps {
	conversation: ConversationWithEvent;
	joinConversation: (conversation: ConversationWithEvent) => void;
	doListRefresh?: () => void;
}

const ConversationCard: React.FC<IConversationCardProps> = memo(({ conversation, joinConversation, doListRefresh }) => {
	const { event, participants: participantsObj } = conversation;

	const eventImage = event?.eventImages.length ? event.eventImages[0] : "";
	const participants = participantsObj && Object.values(participantsObj);

	return (
		<ConvoBox imgUrl={eventImage}>
			{!eventImage && (
				<ConvoBox.Placeholder>
					<PlaceholderImage
						type={PlaceholderImageType.EVENT_DETAILS}
						width={240}
						height={128}
						viewBox={"0 0 720 320"}
					/>
				</ConvoBox.Placeholder>
			)}
			<ConvoBox.Content>
				<ConvoBox.Members>
					{participants?.map(participant => {
						return <ConvoBox.MemberIcon key={participant.personaId} bgUrl={participant.avatarUrl} />;
					})}
					{participants && (
						<>
							<ConvoBox.Title>{participants?.length > 1 ? "Speakers" : participants[0].firstName}</ConvoBox.Title>
							<MicroPhoneFilledIcon {...iconProps} />
						</>
					)}
				</ConvoBox.Members>
				<Box>{event?.title}</Box>
				<ConvoBox.ActionBar>
					{participants ? (
						<ConvoBox.ParticipantsCounter>
							{participants?.length} <EyeIcon {...iconProps} />
						</ConvoBox.ParticipantsCounter>
					) : (
						<Box />
					)}
					{conversation.event.locked && conversation.event?.priceTags?.length ? (
						<UnlockPremiumBtn
							premiumObject={conversation.event}
							premiumObjectType={UnlockContentType.EVENT}
							onUnlock={doListRefresh}
							text="Buy"
						/>
					) : (
						<JoinBtn onClick={() => joinConversation(conversation)}>Join</JoinBtn>
					)}
				</ConvoBox.ActionBar>
			</ConvoBox.Content>
		</ConvoBox>
	);
});

export default ConversationCard;
