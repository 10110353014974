import config from "config/appConfig";

import { MarketingContextValues } from "shared/types";
import { IStateProps } from "utils/StateOf";
import createStore from "utils/createStore";

const localStorageKey = `${config.GLOBAL_CONSTANTS.LOCAL_STORAGE_PREFIX}${config.HOOK_STORAGE_KEYS.MARKETING_DATA}`;

export const initialState: IStateProps<MarketingContextValues> = {
	posts: [],
	showJoinPopup: false,
	notFound: false,
	isAdult: false,
	features: [],
	viewMode: true,
	loadingPosts: false,
	monetizationPlans: []
};

export default createStore<MarketingContextValues>({
	initialState,
	persistant: true,
	persistantName: localStorageKey
});
