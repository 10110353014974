import React, { ReactNode } from "react";

import { ReactComponent as DocFileIcon } from "assets/icons/files/doc.svg";
import { ReactComponent as ExcelFileIcon } from "assets/icons/files/excel.svg";
import { ReactComponent as PowerPointFileIcon } from "assets/icons/files/powerpoint.svg";
import { ReactComponent as TextFileIcon } from "assets/icons/files/text.svg";
import { ReactComponent as WordFileIcon } from "assets/icons/files/word.svg";
import { ReactComponent as PdfFileIcon } from "assets/icons/icon-file-pdf.svg";

export const getFileIconAndType = (fileName?: string) => {
	const ext = fileName ? fileName.split(".").pop() : "";
	let icon: ReactNode;
	let type = "";

	switch (ext) {
		case "pdf":
			icon = <PdfFileIcon />;
			type = "PDF doc";
			break;
		case "doc":
		case "docx":
			icon = <WordFileIcon />;
			type = "Word doc";
			break;
		case "ppt":
		case "pptx":
			icon = <PowerPointFileIcon />;
			type = "PowerPoint doc";
			break;
		case "xls":
		case "xlsx":
			icon = <ExcelFileIcon />;
			type = "Excel doc";
			break;
		case "zip":
			icon = <DocFileIcon />;
			type = "Zip File";
			break;
		case "txt":
			icon = <TextFileIcon />;
			type = "Text doc";
			break;
		default:
			icon = <DocFileIcon />;
			type = "Document";
	}

	return { icon, type };
};
