import styled from "styled-components";

import { Box } from "shared/ui-kit";

export const Container = styled(Box)`
	width: 960px;
	padding: 0 8px;
	margin: 0 auto 15px auto;

	display: flex;
	flex-direction: column;

	${props => props.theme.breakpoints.down("sm")} {
		width: 100%;
	}
`;

export const BoxItemWrapper = styled(Box)`
	:not(:last-child) {
		margin-bottom: 24px;
	}

	&.no-margin {
		margin-bottom: 0;
	}
`;

export const ListWrapper = styled(Box)`
	padding: 12px 0;

	.slick-list {
		padding-bottom: 10px;
	}
`;

export const ItemWrapper = styled(Box)`
	width: 360px;
	min-width: 360px;

	margin-right: 16px;

	&.inline {
		display: inline-flex;
		padding-bottom: 16px;
	}
`;
