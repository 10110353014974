import { Box } from "@material-ui/core";
import styled from "styled-components";

export const ActionBlock = styled(Box)`
	width: 100%;

	position: relative;

	padding: 8px 9px 8px 16px;

	display: flex;
	justify-content: space-between;
	align-items: center;
`;

ActionBlock.SectionWrapper = styled(Box)`
	display: flex;
	flex: 1 1 25%;

	&.center {
		justify-content: center;
	}

	&.end {
		justify-content: flex-end;
	}
`;
