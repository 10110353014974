import React, { useCallback, useEffect, useState } from "react";

import { useMediaQuery } from "@material-ui/core";

import { useFundraisers } from "shared/hooks";
import * as appTheme from "theme/default";

import { ModelBlock, ModelBlockType, PageWrapper } from "../../Components";
import { ManInLotus, MessageText, NoConnectionsBlock } from "../Connections/style";

const FundraisersPage = () => {
	const { getFundraisers, getFundraisersCount, getData: getFundraiserData } = useFundraisers();
	const { fundraisers, fundraisersCount = 0, loading } = getFundraiserData();
	const [offset, setOffset] = useState(1);

	const isMobile = useMediaQuery(appTheme.default.breakpoints.down("sm"));

	const onEndScroll = useCallback(
		() => fundraisersCount > fundraisers.length && setOffset(offset => offset + 1),
		[fundraisers.length, fundraisersCount]
	);

	useEffect(() => {
		getFundraisers({ offset, limit: 20, paginate: true });
	}, [getFundraisers, offset]);

	useEffect(() => {
		getFundraisersCount();
	}, [getFundraisersCount]);

	return (
		<PageWrapper>
			<ModelBlock
				autoFit={fundraisers.length > 3 ? 300 : undefined}
				title="Fundraisers"
				type={ModelBlockType.fundraisers}
				items={fundraisers || []}
				loading={loading}
				keepCurrentData={loading && offset > 1}
				inlineView={!isMobile}
				onEndScroll={onEndScroll}
				noContent={
					<NoConnectionsBlock>
						<ManInLotus />
						<MessageText>
							You are currently not participating in any fundraiser. <br /> Once added to fundraisers, you will see them
							here.
						</MessageText>
					</NoConnectionsBlock>
				}
			/>
		</PageWrapper>
	);
};

export default FundraisersPage;
