import React from "react";

import { DateTime } from "luxon";

import { PlaceholderImageType, VideoModel } from "shared/types";

import SelectItemTemplate from "../Blocks/SelectItemTemplate";
import { PlaceholderImage } from "../index";

const getDateText = start => {
	return DateTime.fromISO(start).toFormat("MMM d");
};

const VideoItem: React.FC<VideoModel & { onSelect: () => void }> = ({ onSelect, ...video }) => (
	<SelectItemTemplate
		coverUrl={video.meta.artwork.url}
		onSelect={onSelect}
		pretitle={getDateText(video.createdAt)}
		title={video.title}
		subtitle={video.category?.name || undefined}
		placeholderImg={
			<PlaceholderImage type={PlaceholderImageType.VIDEO_PREVIEW} width={80} height={80} viewBox={"0 0 400 400"} />
		}
	/>
);

export default VideoItem;
