/* eslint-disable react/no-unescaped-entities */
import React, { useCallback, useEffect, useMemo, useState } from "react";

import { DateTime } from "luxon";
import * as R from "ramda";

import { Controller, useForm, useWatch } from "react-hook-form";
import { css } from "styled-components";

import { Dialog } from "shared/Components";
import ConfirmLeavePopup from "shared/Components/ConfirmLeave";
import useConfirmLeavePage from "shared/Components/ConfirmLeave/hooks/useConfirmLeavePage";
import { StyledActionButton } from "shared/Components/Video/style";
import { useCommunity } from "shared/hooks";
import { WorkspaceProductModel } from "shared/types";
import { MonetizationPlanSubscriptionId } from "shared/types/MonetizationPlansType";
import { Loader, Select, Switch } from "shared/ui-kit";
import { convertToDecimal } from "utils/serviceUtils/helpers";

import {
	Block,
	BlockContainer,
	BlockNoBorder,
	Calculation,
	FilterButton,
	RightButtonGroup,
	StyledInfoCard,
	StyledMonetizationContainer,
	StyledSubFormContainer,
	SubField,
	Title,
	TotalBlock,
	Value
} from "./style";

interface FormDataType {
	name: string;
	description: string;
	monthlyPrice: number;
	annuallyPrice: number;
	freeTrial?: boolean;
	duration?: number;
}

enum CalculationType {
	monthly,
	annually
}

export interface NewSubscriptionPlanModalProps {
	open: boolean;
	type?: "view" | "edit" | "create";
	product?: WorkspaceProductModel;
	subscriptionAndPremium?: boolean;
	onClose: () => void;
}

export const NewSubscriptionPlanModal: React.FC<NewSubscriptionPlanModalProps> = ({
	open,
	subscriptionAndPremium,
	type = "create",
	product,
	onClose
}) => {
	const { createSubscriptionProduct, updateSubscriptionProduct, getData: getCommunityData } = useCommunity();
	const { monetizationPlans, subscriptionProducts, workspace, haveActiveMembers } = getCommunityData();

	const [calculationType, setCalculationType] = useState(CalculationType.monthly);
	const [saving, setSaving] = useState(false);

	const {
		control,
		reset,
		formState: { errors, isDirty, isValid },
		handleSubmit,
		trigger,
		setValue,
		getValues
	} = useForm<FormDataType>({
		mode: "onChange"
	});

	const { monthlyPrice, annuallyPrice, freeTrial } = useWatch({
		control,
		name: ["monthlyPrice", "annuallyPrice", "freeTrial"]
	});

	const isReadonly = useMemo(() => type === "view", [type]);

	useEffect(() => {
		trigger("duration");

		if (!freeTrial) {
			setValue("duration", null);
		}
	}, [freeTrial, trigger, setValue]);

	useEffect(() => {
		if ((type === "view" || type === "edit") && !!product) {
			reset({
				...R.pick(["name", "description"], product),
				monthlyPrice: product.monthly?.price,
				annuallyPrice: product.annually?.price,
				freeTrial: product?.freeTrailEnabled,
				duration: product?.freeTrailDays
			});
		} else if (open) {
			reset({});
		}
		setSaving(false);
	}, [type, product, reset, open]);

	const savePlan = useCallback(
		async (data: FormDataType, isDraft = false) => {
			if (isDraft) {
				setSaving(true);
			}

			if (type === "create") {
				const subscriptionPlan = monetizationPlans?.find(monetizationPlan =>
					subscriptionAndPremium
						? monetizationPlan.subscriptionId === MonetizationPlanSubscriptionId.SUBSCRIPTION_PREMIUM_ONLY
						: monetizationPlan.subscriptionId === MonetizationPlanSubscriptionId.SUBSCRIPTION_ONLY
				);
				if (subscriptionPlan) {
					const payload = {
						...R.pick(["name", "monthlyPrice", "annuallyPrice", "description"], data),
						freeTrailDays: data.freeTrial ? data.duration : undefined,
						freeTrailEnabled: data.freeTrial,
						active: !isDraft,
						sku: "",
						subscriptionId: subscriptionPlan.subscriptionId,
						type: "subscription"
					};

					await createSubscriptionProduct(payload);
				}
			}

			if (type === "edit") {
				const payload = {
					...R.pick(["name", "description"], data),
					...R.pick(["type", "subscriptionId"], product),
					freeTrailDays: data.freeTrial ? data.duration : undefined,
					freeTrailEnabled: data.freeTrial,
					annually: {
						_id: product?.annually?._id,
						price: data.annuallyPrice,
						active: !isDraft
					},
					monthly: {
						_id: product?.monthly?._id,
						price: data.monthlyPrice,
						active: !isDraft
					}
				};

				await updateSubscriptionProduct(payload);
			}

			setSaving(false);

			onClose();
		},
		[
			createSubscriptionProduct,
			updateSubscriptionProduct,
			type,
			product,
			monetizationPlans,
			subscriptionAndPremium,
			onClose
		]
	);

	const onSubmit = useCallback(
		async (data: FormDataType) => {
			savePlan(data);
		},
		[savePlan]
	);

	const renderNotice = () => {
		const activeAt = subscriptionProducts?.[0]?.activeAt;
		if (haveActiveMembers && subscriptionProducts && subscriptionProducts.length && new Date(activeAt) > new Date()) {
			return (
				<div style={{ marginBottom: "24px" }}>
					<StyledInfoCard
						description={`Your subscription plan will be active on ${DateTime.fromISO(activeAt).toFormat("LLLL")}`}
						layout="icon-right"
						title="Subscription Plan Pending…"
					/>
				</div>
			);
		}
	};

	const calculationData = useMemo(() => {
		const SubscriptionRate = calculationType === CalculationType.monthly ? monthlyPrice || 0 : annuallyPrice || 0;

		const _subscriptionRate =
			calculationType === CalculationType.monthly
				? Number(monthlyPrice || 0).toFixed(2)
				: Number(annuallyPrice || 0).toFixed(2);

		const VyooServiceFee =
			SubscriptionRate && !isNaN(SubscriptionRate)
				? Number(((SubscriptionRate * (workspace?.vyooServiceFees || 0)) / 100).toFixed(2))
				: 0;

		const StripeServiceFee =
			SubscriptionRate && !isNaN(SubscriptionRate)
				? Number(((SubscriptionRate * (workspace?.stripeServiceFees || 0)) / 100 + 0.3).toFixed(2))
				: 0;

		const userReceives =
			SubscriptionRate && !isNaN(SubscriptionRate)
				? Number((SubscriptionRate - (Number(VyooServiceFee) + Number(StripeServiceFee))).toFixed(2))
				: 0;

		return {
			rate: _subscriptionRate,
			vyooFee: convertToDecimal(VyooServiceFee),
			stripeFee: convertToDecimal(StripeServiceFee),
			userReceives: convertToDecimal(userReceives)
		};
	}, [monthlyPrice, annuallyPrice, calculationType, workspace]);

	const {
		handleLeavePageConfirmed,
		closeConfirmPopup,
		getData: getConfirmLeavePopupData
	} = useConfirmLeavePage({
		valuesToCheck: isDirty && !!Object.keys(errors).length
	});
	const { showConfirmPopup } = getConfirmLeavePopupData();

	const ActionButton = useMemo(() => {
		if (type === "view") {
			return (
				<StyledActionButton palette={"basic"} buttonTheme={"outline"} onClick={onClose}>
					Close
				</StyledActionButton>
			);
		}

		return (
			<>
				{type === "edit" ? (
					<StyledActionButton palette={"basic"} buttonTheme={"outline"} onClick={onClose} disabled={saving}>
						Close
					</StyledActionButton>
				) : (
					<StyledActionButton
						type={"button"}
						palette={"basic"}
						buttonTheme={"outline"}
						disabled={!isValid || saving}
						leftIcon={saving ? <Loader size="1rem" show={true} color="primary" variant="indeterminate" /> : undefined}
						onClick={() => savePlan(getValues(), true)}
						id="saveAsDraft"
					>
						Save as Draft
					</StyledActionButton>
				)}
				<StyledActionButton
					type="submit"
					disabled={!isValid || saving}
					leftIcon={saving ? <Loader size="1rem" show={true} color="primary" variant="indeterminate" /> : undefined}
					onClick={handleSubmit(onSubmit)}
					id={type === "edit" ? "updatePlan" : "createPlan"}
				>
					{type === "edit" ? "Update" : "Create"} Plan
				</StyledActionButton>
			</>
		);
	}, [saving, isValid, handleSubmit, onSubmit, type, onClose, getValues, savePlan]);

	return (
		<>
			<ConfirmLeavePopup
				handleLeavePage={handleLeavePageConfirmed}
				open={showConfirmPopup}
				onClose={closeConfirmPopup}
			/>
			<Dialog
				title={`${type === "view" ? "View" : type === "edit" ? "Edit" : "Create"} Subscription Plan`}
				open={open}
				onClose={onClose}
				footer={ActionButton}
				bodyCustomStyles={css`
					position: relative;
				`}
				maxWidth={"md"}
			>
				<StyledMonetizationContainer onSubmit={handleSubmit(onSubmit)}>
					<StyledSubFormContainer>
						{renderNotice()}

						<SubField.Container>
							<SubField.ItemWrapper className={"right w-20"}>
								<SubField.Label>Pricing</SubField.Label>
							</SubField.ItemWrapper>
							<SubField.ItemWrapper className={"w-35"}>
								<Controller
									name="monthlyPrice"
									control={control}
									rules={{
										required: "Invalid Price",
										min: { value: 0.99, message: "Amount cannot be less $0.99" },
										validate: value => (!isNaN(value) && Number(value) > 0 ? true : "Invalid Price")
									}}
									render={({ onChange, value }) => (
										<SubField.Input
											inputProps={{ readOnly: isReadonly }}
											onChange={onChange}
											value={value}
											errorText={errors.monthlyPrice?.message}
											placeholder="Monthly Price"
											id="monthlyPrice"
										/>
									)}
								/>
							</SubField.ItemWrapper>
							<SubField.ItemWrapper className={"w-35"}>
								<Controller
									name="annuallyPrice"
									control={control}
									rules={{
										required: "Invalid Price",
										min: { value: 0.99, message: "Amount cannot be less $0.99" },
										validate: value => (!isNaN(value) && Number(value) > 0 ? true : "Invalid Price")
									}}
									render={({ onChange, value }) => (
										<SubField.Input
											inputProps={{ readOnly: isReadonly }}
											onChange={onChange}
											value={value}
											errorText={errors.annuallyPrice?.message}
											placeholder="Annually Price"
											id="AnnuallyPrice"
										/>
									)}
								/>
							</SubField.ItemWrapper>
						</SubField.Container>

						<SubField.Container>
							<SubField.ItemWrapper className={"w-40 between"}>
								<SubField.ItemWrapper className={"right w-50"}>
									<SubField.Label>Free trial</SubField.Label>
								</SubField.ItemWrapper>
								<SubField.ItemWrapper className={"w-43"}>
									<Controller
										name="freeTrial"
										control={control}
										defaultValue={false}
										render={({ onChange, value }) => (
											<Switch
												checked={value}
												onChange={e => onChange(e.target.checked)}
												disabled={isReadonly}
												id="FreeTrial"
											/>
										)}
									/>
								</SubField.ItemWrapper>
							</SubField.ItemWrapper>
							<SubField.ItemWrapper className={"w-60 between"}>
								<SubField.ItemWrapper className={"w-33 right"}>
									<SubField.Label>Duration</SubField.Label>
								</SubField.ItemWrapper>
								<SubField.ItemWrapper className={"w-58"}>
									<Controller
										name="duration"
										control={control}
										rules={{
											validate: value => (!freeTrial || (freeTrial && value) ? true : "Invalid Duration")
										}}
										render={({ onChange, value }) => (
											<Select
												inputProps={{ readOnly: !freeTrial || isReadonly }}
												onChange={onChange}
												value={value}
												label={"Select Duration"}
												showInputLabel={{ show: true }}
												showIcon
												id="durationSubscriptionPlan"
												options={[
													{
														label: "3 Days",
														value: 3
													},
													{
														label: "10 Days",
														value: 10
													},
													{
														label: "1 month",
														value: 30
													}
												]}
											/>
										)}
									/>
								</SubField.ItemWrapper>
							</SubField.ItemWrapper>
						</SubField.Container>

						<SubField.Container>
							<SubField.ItemWrapper className={"right w-20"}>
								<SubField.Label>Name</SubField.Label>
							</SubField.ItemWrapper>
							<SubField.ItemWrapper className={"w-75"}>
								<Controller
									name="name"
									defaultValue={""}
									control={control}
									rules={{
										required: "Name is required",
										maxLength: {
											value: 20,
											message: "Password shouldn't be more than 20 characters"
										}
									}}
									render={({ onChange, value }) => (
										<SubField.Input
											inputProps={{ readOnly: isReadonly }}
											onChange={onChange}
											value={value}
											placeholder="Pricing Plan Name"
											errorText={errors.name?.message}
											id="nameSubscriptionPlan"
										/>
									)}
								/>
							</SubField.ItemWrapper>
						</SubField.Container>

						<SubField.Container>
							<SubField.ItemWrapper className={"right w-20"}>
								<SubField.Label>Description</SubField.Label>
							</SubField.ItemWrapper>
							<SubField.ItemWrapper className={"w-75"}>
								<Controller
									name="description"
									defaultValue={""}
									control={control}
									rules={{
										required: "Description is required",
										maxLength: {
											value: 255,
											message: "Description shouldn't be more than 255 characters"
										}
									}}
									render={({ onChange, value }) => (
										<SubField.Input
											inputProps={{ readOnly: isReadonly }}
											onChange={onChange}
											value={value}
											placeholder="Add Description"
											errorText={errors.description?.message}
											id="descriptionSubscriptionPlan"
										/>
									)}
								/>
							</SubField.ItemWrapper>
						</SubField.Container>
					</StyledSubFormContainer>

					<Calculation.Container>
						<Calculation.Header>
							<SubField.Label>Calculation</SubField.Label>
							<RightButtonGroup>
								<FilterButton
									className={calculationType === CalculationType.monthly && "active"}
									onClick={() => setCalculationType(CalculationType.monthly)}
								>
									Monthly
								</FilterButton>
								<FilterButton
									className={calculationType === CalculationType.annually && "active"}
									onClick={() => setCalculationType(CalculationType.annually)}
								>
									Annually
								</FilterButton>
							</RightButtonGroup>
						</Calculation.Header>
						<Calculation.Body>
							<BlockContainer>
								<Block>
									<Title>Subscription Rate</Title> <Value>${calculationData.rate}</Value>
								</Block>
								<Block>
									<Title>Vyoo Service Fee </Title> <Value>-${calculationData.vyooFee}</Value>
								</Block>
								<BlockNoBorder>
									<Title>Stripe Pay Fee </Title> <Value>-${calculationData.stripeFee}</Value>
								</BlockNoBorder>
								<TotalBlock>
									<Title>You'll Receive </Title> <Value>${calculationData.userReceives}</Value>
								</TotalBlock>
							</BlockContainer>
						</Calculation.Body>
					</Calculation.Container>
				</StyledMonetizationContainer>
			</Dialog>
		</>
	);
};

export default NewSubscriptionPlanModal;
