import { ChatMessage, ConnectionItem, InboxItem } from "modules/Messaging/Data/types";
import { INBOX_FILTERS } from "modules/Messaging/constants";
import { IStateProps } from "utils/StateOf";

import createStore from "utils/createStore";

type inboxType = "unread" | "recent";

export interface PreviewDialogType {
	user: {
		name: string;
		avatar: string;
	};
	file: {
		name: string;
		url: string;
		type: "Image" | "PDF";
		createdAt: Date;
	};
}

export enum InboxOptions {
	ALL_CHATS,
	GROUP_CHATS,
	PINNED_CHATS,
	ARCHIVED_CHATS
}
export interface Values {
	inbox: InboxItem[];
	inboxType: inboxType;
	currentChat?: InboxItem;
	groupMembers: Record<string, { _id?: string; name?: string; avatar?: string }>;
	messages: ChatMessage[];
	loadingInbox: boolean;
	searchingInbox: boolean;
	loadingMessages: boolean;
	activePanel: "inbox" | "chat";
	loadingConnections: boolean;
	searchingConnections: boolean;
	connectionList: ConnectionItem[];
	typingChat?: ChatMessage;
	allowLoadMoreInboxes: boolean;
	allowLoadMoreConnections: boolean;
	previewDialog?: PreviewDialogType;
	currentInboxTab: InboxOptions;
}

export const initialState: IStateProps<Values> = {
	inbox: [],
	inboxType: INBOX_FILTERS.RECENT as inboxType,
	messages: [],
	groupMembers: {},
	loadingInbox: false,
	searchingInbox: false,
	loadingMessages: true,
	activePanel: "inbox",
	connectionList: [],
	allowLoadMoreInboxes: true,
	allowLoadMoreConnections: true,
	loadingConnections: false,
	searchingConnections: false,
	currentInboxTab: InboxOptions.ALL_CHATS
};

export default createStore<Values>({
	initialState
});
