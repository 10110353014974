import React, { ReactNode, memo, useMemo } from "react";

import styled from "styled-components";

import { ReactComponent as Paypal } from "assets/icons/paypal.svg";
import { ReactComponent as Stripe } from "assets/icons/stripe.svg";
import { Box, Radio } from "shared/ui-kit";

const Container = styled(Box)`
	display: flex;
	flex-direction: column;
	margin: 16px 0;
	flex: 1;
`;

const Column = styled(Box)`
	display: flex;
	flex-direction: column;
	flex: 1;
	border: 1px solid #e4e9f2;
	border-radius: 4px;
	max-width: 440px;
	margin: 6px;
	padding: 10px 0;
`;

const ItemWrapper = styled(Box)`
	display: flex;
	flex: 1;
	justify-content: space-between;
	align-items: center;
	min-height: 64px;
`;

const StyledRadio = styled(Radio)`
	margin-right: 10px;
	padding: 0;
	width: 40px;
	color: ${props => props.theme.palette.primary.main};
`;

interface Props {
	value: string;
	onChange: (value: string) => void;
	stripeSupport?: boolean;
	children: ReactNode;
}

const MethodRadioField: React.FC<Props> = memo(({ value = "", onChange, children, stripeSupport }: Props) => {
	const methods = useMemo(() => {
		const data = [
			{
				value: "paypal",
				icon: <Paypal height={30} width={150} />
			}
		];
		if (stripeSupport) {
			data.push({
				value: "stripe",
				icon: <Stripe height={30} width={150} />
			});
		}
		return data;
	}, [stripeSupport]);

	const items = useMemo(
		() =>
			methods.map((item, index) => (
				<Column key={index}>
					<ItemWrapper>
						{item.icon}
						<StyledRadio value={value} onChange={() => onChange(item.value)} options={[{ value: item.value }]} />
					</ItemWrapper>
					{value === item.value && children}
				</Column>
			)),
		[value, onChange, children, methods]
	);

	return <Container>{items}</Container>;
});

export default MethodRadioField;
