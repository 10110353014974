import React from "react";

import { useUser } from "shared/hooks";

import { AddProfileInfoDialog } from "..";

const AddGoalsDialog = () => {
	const { setAddGoalsDialog, getData: getUserData } = useUser();
	const { goals } = getUserData();

	return (
		<AddProfileInfoDialog
			title="Goals"
			handleClose={() => setAddGoalsDialog(false)}
			categorizedList={goals}
			updateType="goals"
		/>
	);
};

export default AddGoalsDialog;
