/* eslint-disable react/no-unescaped-entities */
import React, { ReactNode, useEffect, useState } from "react";

import { Link } from "@material-ui/core";

import styled from "styled-components";

import { ReactComponent as FacebookIcon } from "assets/icons/facebook.svg";
import { ReactComponent as TrackIcon } from "assets/icons/iconAudioTrack.svg";
import { ReactComponent as CalendarIcon } from "assets/icons/iconCalendar.svg";
import { ReactComponent as GroupIcon } from "assets/icons/iconGroups.svg";
import { ReactComponent as MessageIcon } from "assets/icons/iconMessageSquare.svg";
import { ReactComponent as PeopleIcon } from "assets/icons/iconPeople.svg";
import { ReactComponent as YoutubeIcon } from "assets/icons/iconYoutube.svg";

import { ReactComponent as InstagramIcon } from "assets/icons/instagram.svg";
import { ReactComponent as TikTokIcon } from "assets/icons/tiktok.svg";
import { ReactComponent as TwitterIcon } from "assets/icons/twitter.svg";

import { Post } from "shared/Organisms";
import { useFeature, useMarketing } from "shared/hooks";
import { SocialTypes } from "shared/types";
import { Box, Text } from "shared/ui-kit";

import { InfoBlock } from "../index";

const Title = styled(Text)`
	font-size: 32px;
	font-weight: bold;
	line-height: 1.25;
	color: #222b45;

	margin-bottom: 24px;

	${props => props.theme.breakpoints.down("sm")} {
		font-size: 22px;
	}
`;

const StatisticBlock = styled(Box)`
	display: inline-flex;
	align-items: center;
	width: 50%;

	margin: 0 0 32px 0;
	padding-right: 10px;

	&:last-child,
	&:nth-last-child(2) {
		margin-bottom: 0;
	}
`;

const StatisticIcon = styled(Box)`
	path {
		fill: #6173fe;
	}
`;

const StatisticIconSecondColor = styled(Box)`
	path {
		&:nth-child(2) {
			fill: #6173fe;
		}
	}
`;

const StatisticIconWrapper = styled(Box)`
	width: 48px;
	min-width: 48px;
	height: 48px;
	border-radius: 50%;
	background-color: #ebecff;

	display: flex;
	justify-content: center;
	align-items: center;

	margin-right: 12px;
`;

const StatisticValue = styled(Text)`
	font-size: 15px;
	font-weight: 600;
	line-height: 1.6;
	color: #222b45;
`;

const SocialIconWrapper = styled(Link)`
	width: 48px;
	min-width: 48px;
	height: 48px;

	margin-right: 16px;

	display: inline-flex;

	cursor: pointer;
`;

interface IStatistic {
	title: string;
	icon: ReactNode;
	value: number;
	postfix: string;
	featureValueName?: string;
}

interface AboutProps {}

const About: React.FC<AboutProps> = () => {
	const { getData: getMarketingData } = useMarketing();
	const { workspace, statistic } = getMarketingData();

	const { isFeatureEnabled } = useFeature();

	const [originalStatisticList] = useState<IStatistic[]>(
		[
			{
				title: "members",
				postfix: "Members",
				value: 0,
				icon: (
					<StatisticIcon>
						<PeopleIcon />
					</StatisticIcon>
				)
			},
			{
				title: "tracks",
				postfix: "Tracks",
				featureValueName: "enableAudioTracks",
				value: 0,
				icon: (
					<StatisticIconSecondColor>
						<TrackIcon />
					</StatisticIconSecondColor>
				)
			},
			{
				title: "events",
				postfix: "Events",
				featureValueName: "enableEvents",
				value: 0,
				icon: (
					<StatisticIcon>
						<CalendarIcon />
					</StatisticIcon>
				)
			},
			{
				title: "boards",
				postfix: "Message boards",
				featureValueName: "enableForum",
				value: 0,
				icon: <MessageIcon />
			},
			{
				title: "groups",
				postfix: "Groups",
				featureValueName: "enableGroups",
				value: 0,
				icon: (
					<StatisticIcon>
						<GroupIcon />
					</StatisticIcon>
				)
			},
			{
				title: "videos",
				postfix: "Videos",
				value: 0,
				icon: (
					<StatisticIconSecondColor>
						<YoutubeIcon />
					</StatisticIconSecondColor>
				)
			}
		].filter(x => isFeatureEnabled(x.featureValueName))
	);

	const [statisticList, setStatisticList] = useState<IStatistic[]>(originalStatisticList);

	useEffect(() => {
		const newList: IStatistic[] = [];
		originalStatisticList.forEach(stat => {
			const value = statistic?.count[stat.title] || 0;
			if (value >= 10) {
				newList.push({
					...stat,
					value
				});
			}
		});

		setStatisticList(newList);
	}, [statistic, originalStatisticList]);

	const displayStatisticCount = (count: number): string => {
		let displayValue = count;
		if (count < 100) {
			displayValue = Math.floor(count / 10) * 10;
			return displayValue.toString();
		}

		if (count < 1000) {
			displayValue = Math.floor(count / 100) * 100;
			return displayValue.toString();
		}

		if (count < 100000) {
			displayValue = Math.floor(count / 1000) * 1000;
			return `${displayValue / 1000}K`;
		}

		return displayValue.toString();
	};

	return (
		<>
			{!!statisticList.length && (
				<InfoBlock>
					<Title>What's in {workspace?.meta?.displayName || workspace?.name || ""}</Title>
					{statisticList.map((item, index) => (
						<StatisticBlock key={index}>
							<StatisticIconWrapper>{item.icon}</StatisticIconWrapper>
							<StatisticValue>
								+{displayStatisticCount(item.value)} {item.postfix}
							</StatisticValue>
						</StatisticBlock>
					))}
				</InfoBlock>
			)}
			<InfoBlock>
				<Title>About {workspace?.meta?.displayName || workspace?.name || ""}</Title>
				{workspace?.meta?.about ? (
					<Post postContent={workspace.meta.about} isMarketing />
				) : (
					"There is no info about this community."
				)}
			</InfoBlock>
			{workspace?.meta?.social && Object.values(workspace.meta.social).some(x => x) && (
				<InfoBlock>
					<Title>Socials</Title>
					{workspace.meta.social[SocialTypes.facebook] && (
						<SocialIconWrapper href={workspace.meta.social[SocialTypes.facebook]} target={"_blank"}>
							<FacebookIcon />
						</SocialIconWrapper>
					)}
					{workspace.meta.social[SocialTypes.instagram] && (
						<SocialIconWrapper href={workspace.meta.social[SocialTypes.instagram]} target={"_blank"}>
							<InstagramIcon />
						</SocialIconWrapper>
					)}
					{workspace.meta.social[SocialTypes.twitter] && (
						<SocialIconWrapper href={workspace.meta.social[SocialTypes.twitter]} target={"_blank"}>
							<TwitterIcon />
						</SocialIconWrapper>
					)}
					{workspace.meta.social[SocialTypes.tiktok] && (
						<SocialIconWrapper href={workspace.meta.social[SocialTypes.tiktok]} target={"_blank"}>
							<TikTokIcon />
						</SocialIconWrapper>
					)}
				</InfoBlock>
			)}
		</>
	);
};

export default About;
