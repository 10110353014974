import { useCallback, useMemo } from "react";

import { INVITE } from "modules/Members/constants";
import { EditRoleTeamMember, getTeamMembersDataType } from "modules/Team/Data/types";
import useNotification from "shared/hooks/useNotification";
import { useMembersListApiService } from "shared/services";
import {
	ContributorModel,
	MemberActions,
	MemberStatus,
	MembersModel,
	ModalStateModel,
	UserStatus,
	ValidateEmailMember
} from "shared/types";
import getCountMessage from "utils/getCountMessage";
import { actionMethod, actionTypes, getValidationMessage } from "utils/getValidationMessage";
import { validateEmail } from "utils/serviceUtils/validators";

import { useMembersStore } from "../contexts";

const useMembers = () => {
	const { showMessage } = useNotification();

	const memberService = useMembersListApiService();

	const store = useMembersStore();
	const { setState } = useMembersStore();

	const contextMemberShowPerPage = useMemo(() => {
		return store.memberShowPerPage;
	}, [store]);

	const methods = useMemo(
		() => ({
			getMembers: async (data: getTeamMembersDataType, addLoyaltyDetails?: boolean, isPaginated = false) => {
				try {
					setState({ loadingMembers: true });
					const requestData = data;
					if (!requestData.keyword && (!requestData?.filterBy || !requestData?.filterBy?.length)) {
						// fetch all except deleted
						requestData.filterBy = [
							UserStatus.PENDING,
							UserStatus.JOINED,
							UserStatus.ON_BOARDED,
							UserStatus.ARCHIVE,
							UserStatus.PAID_SUBSCRIPTION,
							UserStatus.BANNED
						];
					}
					const users = await memberService.getMembers(requestData, addLoyaltyDetails);
					if (!isPaginated) {
						const keyword = data.keyword;
						setState(ctx => ({
							...ctx,
							users,
							keyword,
							loadingMembers: false
						}));
					} else {
						setState(ctx => ({
							loadingMembers: false,
							users:
								data.offset === 1
									? users.map(m => ({ ...m, isShared: false }))
									: [...ctx.users, ...users.map(m => ({ ...m, isShared: false }))]
						}));
					}
				} catch (error) {
					showMessage((error as Error).message);
				}
			},
			getMembersToInvite: async (data: any, withPagination?: boolean) => {
				try {
					setState(ctx => ({ ...ctx, loadingMembers: true }));
					const personas = await memberService.getMembersToInvite(data);
					const { keyword } = data;
					setState(ctx => ({
						...ctx,
						personas: withPagination && data.offset > 1 ? [...ctx.personas, ...personas] : personas,
						keyword,
						loadingMembers: false
					}));
				} catch (error) {
					showMessage((error as Error).message);
				}
			},
			getMembersToInviteCount: async data => {
				const { totalCount } = await memberService.getMembersToInviteCount(data);
				setState(ctx => ({ ...ctx, totalCount }));
			},
			getMembersEmails: async (data: getTeamMembersDataType) => {
				try {
					const users = await memberService.getMembers(data);

					const usersEmails = users.map(user => user.email);

					setState(ctx => ({
						...ctx,
						usersEmails
					}));
				} catch (error) {
					showMessage((error as Error).message);
				}
			},
			bulkDeleteMember: async (userIds: string[]) => {
				try {
					if (userIds.length) {
						await memberService.deleteMember(userIds);
					}

					const deletedUsersLength = getCountMessage(userIds.length, "member");

					showMessage(
						getValidationMessage({
							name: deletedUsersLength,
							actionType: actionTypes.CRUDUser,
							actionMethod: actionMethod.removed,
							emoji: "🗑",
							multiple: userIds.length > 1
						}),
						3
					);
					setState(ctx => ({
						...ctx,
						selectMembers: [],
						totalCount: ctx.totalCount - userIds.length,
						isOpen: false
					}));
				} catch (error) {
					showMessage((error as Error).message);
				}
			},
			bulkBanMember: async (userIds: string[]) => {
				try {
					if (userIds.length) {
						await memberService.banMember(userIds);
					}

					const bannedUsersLength = getCountMessage(userIds.length, "member");

					showMessage(
						getValidationMessage({
							name: bannedUsersLength,
							actionType: actionTypes.CRUDUser,
							actionMethod: actionMethod.banned,
							emoji: "🚫",
							multiple: userIds.length > 1
						}),
						3
					);
					setState(ctx => ({
						...ctx,
						selectMembers: [],
						totalCount: ctx.totalCount - userIds.length,
						isOpen: false
					}));
				} catch (error) {
					showMessage((error as Error).message);
				}
			},
			bulkUnbanMember: async (userIds: string[]) => {
				try {
					if (userIds.length) {
						await memberService.unbanMember(userIds);
					}

					const unbannedUsersLength = getCountMessage(userIds.length, "member");

					showMessage(
						getValidationMessage({
							name: unbannedUsersLength,
							actionType: actionTypes.CRUDUser,
							actionMethod: actionMethod.unbanned,
							emoji: "🎉",
							multiple: userIds.length > 1
						}),
						3
					);
					setState(ctx => ({
						...ctx,
						selectMembers: [],
						isOpen: false
					}));
				} catch (error) {
					showMessage((error as Error).message);
				}
			},
			validateTypedEmailMember: (email: string, toast = false) => {
				const isValid = email && validateEmail(email);
				if (!isValid) {
					if (toast) {
						showMessage(
							getValidationMessage({
								name: "Not a valid email"
							}),
							3
						);
						methods.setIsInvited(true);
					}

					return false;
				}

				return true;
			},
			validatedInvitedMember: async (emails: string[]) => {
				try {
					return emails.length ? await memberService.validateEmailMember(emails) : [];
				} catch (error) {
					showMessage("User is invited already");
				}
			},
			cancelInviteMember: async (userIds: string[]) => {
				try {
					if (userIds.length) {
						await memberService.cancelInviteMember(userIds);
					}

					const invitedUsersLength =
						userIds.length === 1 ? "Invitation" : getCountMessage(userIds.length, "invitation");

					showMessage(
						getValidationMessage({
							name: invitedUsersLength,
							actionType: actionTypes.CRUD,
							actionMethod: actionMethod.cancelled,
							emoji: "❌",
							multiple: userIds.length > 1
						}),
						3
					);

					setState(ctx => ({
						...ctx,
						isOpen: false,
						totalCount: ctx.totalCount - userIds.length,
						selectMembers: []
					}));
				} catch (error) {
					showMessage((error as Error).message);
				}
			},

			resendInviteMember: async (userIds: string[]) => {
				try {
					if (userIds.length) {
						await memberService.resendInviteMember(userIds);

						const resendInvitationsUsersLength =
							userIds.length === 1 ? "Invitation" : getCountMessage(userIds.length, "invitation");

						showMessage(
							getValidationMessage({
								name: resendInvitationsUsersLength,
								actionType: actionTypes.CRUD,
								actionMethod: actionMethod.resent,
								emoji: "✉️",
								multiple: userIds.length > 1
							}),
							3
						);
					}
					setState(ctx => ({ ...ctx, isOpen: false, selectMembers: [] }));
				} catch (error) {
					showMessage((error as Error).message);
				}
			},
			inviteMember: async (data: { emails: string[]; slug: string | null; role: string | null }) => {
				try {
					setState(ctx => ({ ...ctx, loadingMembers: true }));
					await memberService.inviteMember(data);

					const users = await memberService.getMembers({
						offset: 1,
						limit: contextMemberShowPerPage,
						type: "members",
						keyword: ""
					});

					setState(ctx => ({
						...ctx,
						users,
						loadingMembers: false,
						modalIsOpen: false,
						selectMembers: []
					}));
				} catch (error) {
					showMessage((error as Error).message);
				}
			},
			markArchive: async (data: { userIds: string[]; value: boolean }) => {
				try {
					if (data.userIds.length) {
						await memberService.markArchive(data);

						const archivedUsersLength = getCountMessage(data.userIds.length, "member");

						showMessage(
							getValidationMessage({
								name: archivedUsersLength,
								actionType: actionTypes.CRUDUser,
								actionMethod: data.value ? actionMethod.deactivated : actionMethod.activated,
								emoji: data.value ? "🗄" : "🗃",
								multiple: data.userIds.length > 1
							}),
							3
						);
						setState(ctx => ({ ...ctx, isOpen: false, selectMembers: [] }));
					}
				} catch (error) {
					showMessage((error as Error).message);
				}
			},
			setModalState: ({
				isOpen = true,
				modalTitle,
				modalContent,
				apiRequest,
				actionConfirmMessage,
				apiRequestParams,
				modalFooterActionsText,
				viewType = INVITE.SHAREABLE_LINK,
				editableModel = undefined,
				successCallback
			}: ModalStateModel) => {
				setState(ctx => ({
					...ctx,
					isOpen,
					modalTitle,
					viewType,
					editableModel,
					modalContent,
					modalFooterActionsText,
					apiRequest,
					actionConfirmMessage,
					apiRequestParams,
					successCallback
				}));
			},
			setEmailAddresses: ({ emailAddresses }: ValidateEmailMember) => {
				setState(ctx => ({ ...ctx, emailAddresses }));
			},
			setRole: async (data: EditRoleTeamMember) => {
				try {
					const apiData = {
						slug: "administrators",
						isMember: !!data.personaId,
						isOwner: false,
						isModerator: false,
						isPending: false,
						isInvited: false,
						isAdmin: false,
						isInfluencer: false
					};

					if (!!data.personaId) {
						switch (data.selectedRole) {
							case "Admin":
								apiData.isAdmin = true;
								break;
							case "Owner":
								apiData.isOwner = true;
								break;
							case "Moderator":
								apiData.isModerator = true;
								break;
							case "Influencer":
								apiData.isInfluencer = true;
								break;
							case "Member":
								apiData.isMember = true;
								apiData.isInvited = true;
								break;
							default:
								apiData.isMember = false;
								apiData.isInvited = false;
						}

						await methods.getMembers({
							offset: 1,
							limit: contextMemberShowPerPage,
							type: "members",
							keyword: null
						});

						methods.setPage(1);

						// eslint-disable-next-line
						const filteredApiData = (({ selectedRole, ...o }) => o)({ ...apiData, ...data });

						await memberService.editRoleMember(filteredApiData);

						showMessage(
							getValidationMessage({
								name: "Role",
								actionType: actionTypes.CRUDUser,
								actionMethod: actionMethod.updated,
								emoji: "✨"
							}),
							3
						);
					}
				} catch (error) {
					showMessage((error as Error).message);
				}
			},
			setShowInviteNotAllowedDialog: (showInviteNotAllowedDialog: boolean) => {
				setState(ctx => ({ ...ctx, showInviteNotAllowedDialog }));
			},
			setIsInvited: (isInvited: boolean) => {
				setState(ctx => ({ ...ctx, isInvited }));
			},
			setShowToastMessage: (showToastMessage: boolean) => {
				setState(ctx => ({ ...ctx, showToastMessage }));
			},
			setEditMemberRole: (editMemberRole: boolean) => {
				setState(ctx => ({ ...ctx, editMemberRole }));
			},
			setSelectMembers: (id: string) => {
				setState(ctx => {
					const { users, selectMembers } = ctx;
					let updatedMembers: string[] = [];
					if (id === "all") updatedMembers = selectMembers.length === users.length ? [] : users.map(user => user._id);
					else if (id === "none") updatedMembers = [];
					else if (selectMembers.includes(id)) updatedMembers = selectMembers.filter(_id => _id !== id);
					else updatedMembers = [...selectMembers, id];
					let enabledActions = [""];
					users.forEach((user: MembersModel) => {
						if (updatedMembers.includes(user._id)) {
							if (user.status === MemberStatus.pending && !enabledActions.includes(MemberActions.resend))
								enabledActions = [...enabledActions, MemberActions.resend, MemberActions.cancel];
							if (user.status === MemberStatus.joined && !enabledActions.includes(MemberActions.archive))
								enabledActions = [...enabledActions, MemberActions.delete];
							if (user.status === MemberStatus.onboarded && !enabledActions.includes(MemberActions.archive))
								enabledActions = [...enabledActions, MemberActions.archive, MemberActions.delete];
							if (user.status === MemberStatus.onboarded && !enabledActions.includes(MemberActions.ban))
								enabledActions = [...enabledActions, MemberActions.ban];
						}
					});

					return {
						...ctx,
						selectMembers: updatedMembers,
						selectedMembers: updatedMembers.length,
						enabledActions
					};
				});
			},
			getCommunityShareableLinkAndQRCode: async () => {
				try {
					const communityDetails = await memberService.getCommunityDetails();
					const communityShareableLink =
						communityDetails.communityPageUrl === "" ? "No Community Link" : communityDetails.communityPageUrl;

					const communityQRCode = communityDetails.qrCodeUrl === "" ? "No Community Link" : communityDetails.qrCodeUrl;

					const communityName = communityDetails.name;

					const firebaseUrl = communityDetails.firebaseUrl;
					const firebaseDbUrl = communityDetails.firebaseDbUrl;
					const firebaseWebApiKey = communityDetails.firebaseWebApiKey;

					setState(ctx => ({
						...ctx,
						communityShareableLink,
						communityQRCode,
						communityName,
						firebaseUrl,
						firebaseDbUrl,
						firebaseWebApiKey
					}));
				} catch (error) {
					showMessage((error as Error).message);
				}
			},
			getUserSearch: async data => {
				try {
					const personas = await memberService.getUserSearch(data);

					setState(ctx => ({ ...ctx, personas, noUsersFound: !personas.length }));
				} catch (error) {
					showMessage((error as Error).message);
				}
			},
			getShareableLink: async data => {
				try {
					const shareableLink = await memberService.getShareableLink(data);
					if (shareableLink) {
						showMessage(
							getValidationMessage({
								name: data.type === "shareablelink" ? "The Shareable link" : "The QR code",
								actionType: actionTypes.CRUD,
								actionMethod: actionMethod.created,
								emoji: "🎉"
							}),
							2
						);
						setState(ctx => ({ ...ctx, shareableLink }));
					}
				} catch (error) {
					showMessage((error as Error).message);
				}
			},
			updateShareableLink: async data => {
				try {
					await memberService.updateShareableLink(data);
					showMessage(
						getValidationMessage({
							name: "The Shareable link",
							actionType: actionTypes.CRUD,
							actionMethod: actionMethod.updated,
							emoji: "🎉"
						}),
						2
					);
				} catch (error) {
					showMessage((error as Error).message);
				}
			},
			activeDeactiveShareableLink: async (data, type) => {
				try {
					await memberService.updateShareableLink(data);
					showMessage(
						getValidationMessage({
							name: type === INVITE.SHAREABLE_LINK ? "The Shareable link" : "The QR Code",
							actionType: actionTypes.CRUD,
							actionMethod: data.active ? actionMethod.active : actionMethod.deactive,
							emoji: "🎉"
						}),
						2
					);
				} catch (error) {
					showMessage((error as Error).message);
				}
			},
			getAllShareableLinks: async data => {
				try {
					setState(ctx => ({ ...ctx, isLoading: true }));

					const allShareableLinks = await memberService.getAllShareableLinks(data);
					data.type === INVITE.QR_CODE
						? setState(ctx => ({
								...ctx,
								allQRCodes: data.offset === 1 ? allShareableLinks : [...ctx.allQRCodes, ...allShareableLinks],
								stopLoadingQR: !allShareableLinks.length,
								isLoading: false
						  }))
						: setState(ctx => ({
								...ctx,
								allShareableLinks:
									data.offset === 1 ? allShareableLinks : [...ctx.allShareableLinks, ...allShareableLinks],
								stopLoadingLinks: !allShareableLinks.length,
								isLoading: false
						  }));
				} catch (error) {
					showMessage((error as Error).message);
				}
			},
			deleteShareableLink: async (id, type) => {
				try {
					if (id) {
						await memberService.deleteShareableLink(id);

						showMessage(
							getValidationMessage({
								name: type === INVITE.SHAREABLE_LINK ? "Shareable link" : "QR Code",
								actionType: actionTypes.CRUD,
								actionMethod: actionMethod.deleted,
								emoji: "🗑"
							}),
							3
						);
						setState(ctx => ({ ...ctx, selectMembers: [] }));
					}
				} catch (error) {
					showMessage((error as Error).message);
				}
			},
			getMemberTotalCount: async (
				data: Partial<getTeamMembersDataType>,
				filterBy?: UserStatus[],
				returnData?: boolean
			) => {
				try {
					const requestData = data;
					if (!requestData.keyword && (!requestData?.filterBy || !requestData?.filterBy?.length)) {
						// fetch all except deleted
						requestData.filterBy = filterBy || [
							UserStatus.PENDING,
							UserStatus.JOINED,
							UserStatus.ON_BOARDED,
							UserStatus.ARCHIVE,
							UserStatus.PAID_SUBSCRIPTION,
							UserStatus.BANNED
						];
					}

					const { totalCount } = await memberService.getTotalMemberCount(requestData);

					if (!returnData) {
						setState(ctx => ({ ...ctx, totalCount }));
					} else {
						return totalCount;
					}
				} catch (error) {
					showMessage((error as Error).message);
				}
			},
			setShareableLinkPage: async (sharaeableLinkPage: number) => {
				setState(ctx => ({ ...ctx, sharaeableLinkPage }));
			},
			setSelectedQRCode: async (link: string) => {
				setState(ctx => ({ ...ctx, selectedQRCode: link }));
			},
			setPersonasEmpty: async () => {
				setState(ctx => ({ ...ctx, personas: [] }));
			},
			setLinksEmpty: async () => {
				setState(ctx => ({ ...ctx, allShareableLinks: [], stopLoadingLinks: false }));
			},
			setPage: async (page: number) => {
				setState(ctx => ({ ...ctx, page, selectMembers: [] }));
			},
			setMemberShowPerPage: async (memberShowPerPage: number) => {
				setState(ctx => ({ ...ctx, memberShowPerPage }));
			},
			setStatusFilter: statusFilter => {
				setState(ctx => ({ ...ctx, statusFilter }));
			},
			setUsers: (users: MembersModel[]) => {
				setState(ctx => ({ ...ctx, users }));
			},
			setIsLoading: (isLoading: boolean) => setState(ctx => ({ ...ctx, isLoading })),
			setLoadingMembers: (loadingMembers: boolean) => setState(ctx => ({ ...ctx, loadingMembers })),
			fetchContributors: async ({
				offset = 1,
				limit = 10
			}): Promise<{ contributors: ContributorModel[]; totalContributors: number }> => {
				methods.setIsLoading(true);
				const contributors = await memberService.getContributors({ offset, limit, sortBy: "postCount" });
				methods.setIsLoading(false);
				return {
					contributors: contributors.users,
					totalContributors: contributors.totalUsersFound
				};
			},
			getContributors: async ({ offset = 1, limit = 10 }): Promise<void> => {
				const contributors = await methods.fetchContributors({ offset, limit });
				setState(ctx => ({
					...ctx,
					contributors: contributors.contributors,
					totalContributors: contributors.totalContributors
				}));
			}
		}),
		[memberService, setState, showMessage, contextMemberShowPerPage]
	);

	const getData = useCallback(() => {
		return store;
	}, [store]);

	return { ...methods, getData };
};

export default useMembers;
