import { IStateProps } from "utils/StateOf";

import createStore from "utils/createStore";

import { SupportContextValues } from "../types";

export const initialState: IStateProps<SupportContextValues> = {
	supportItems: [],
	isLoading: true
};

export default createStore<SupportContextValues>({
	initialState
});
