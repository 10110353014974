import React, { Suspense, memo, useCallback, useEffect, useMemo, useState } from "react";

import { DateTime } from "luxon";

import { ReactComponent as PushNotificationIcon } from "assets/icons/push-notification-icon.svg";
import { useEngageNotifications } from "modules/Engage/Data";
import { PageTemplate } from "modules/Manage/View/Components";
import { MenuDots, TableWrapper } from "shared/Components";
import { TableColumnLoaderType } from "shared/Components/NewTable/Components";
import { Cell } from "shared/Components/NewTable/style";
import { useDebounce } from "shared/hooks";
import { formatCount } from "utils/serviceUtils/helpers";

import { ReceivedOpen, StyledNumbers } from "./style";

import { EngageNotificationsModel } from "../../../Data/types";
import { EngageNotificationStatuses } from "../../../Data/types/EngageNotificationModel";

import DeleteNotificationDialog from "../../Components/DeleteNotificationDialog";

const Table = React.lazy(() => import("shared/Components/NewTable"));
const NotificationModal = React.lazy(() => import("./NotificationModal/NotificationModal"));

const Engages: React.FC = memo(() => {
	const [addNotificationModalDialog, setAddNotificationModalDialog] = useState<{
		open: boolean;
		editableNtf?: EngageNotificationsModel;
	}>();

	const [keyword, setKeyword] = useState<string>("");

	const {
		getNotifications,
		setPage,
		setNotificationShowPerPage,
		getNotificationsTotalCount,
		setDeleteDialog,
		getData: getEngageNotificationsData
	} = useEngageNotifications();

	const { notifications, notificationShowPerPage, page, loadingNotifications, totalCount } =
		getEngageNotificationsData();

	const debouncedKeyword = useDebounce(keyword, 400);

	const fetchData = useCallback(
		(offset: number, limit: number, keyword?: string) => {
			getNotifications({
				offset,
				limit,
				...(keyword && { keyword })
			});
		},
		[getNotifications]
	);

	useEffect(() => {
		fetchData(page, notificationShowPerPage, debouncedKeyword);
	}, [fetchData, debouncedKeyword, notificationShowPerPage, page]);

	useEffect(() => {
		getNotificationsTotalCount({
			...(debouncedKeyword && { keyword: debouncedKeyword })
		});
	}, [getNotificationsTotalCount, debouncedKeyword]);

	const handleAddNotification = useCallback(() => {
		setAddNotificationModalDialog({ open: true });
	}, []);

	const handleSearch = useCallback(
		(keyword: string) => {
			if (keyword === "" || keyword.length >= 3) {
				setKeyword(keyword);
				setPage(1);
			}
		},
		[setPage]
	);

	const getOptions = useCallback(
		(rowData: EngageNotificationsModel) => {
			const options = [
				{
					id: "delete",
					name: "Delete",
					onClick: () => {
						setDeleteDialog({
							data: rowData
						});
					},
					value: 2
				}
			];

			if (rowData.status === EngageNotificationStatuses.scheduled) {
				options.unshift({
					id: "edit",
					name: "Edit",
					onClick: () => {
						setAddNotificationModalDialog({
							open: true,
							editableNtf: rowData
						});
					},
					value: 1
				});
			}

			return options;
		},
		[setDeleteDialog]
	);

	const tableColumns = useMemo(
		() => [
			{
				alignment: "left",
				label: <Cell.HeaderText>{formatCount(totalCount, "Notification")}</Cell.HeaderText>,
				minWidth: 260,
				Cell: ({ rowData: { _id, title, creator } }: { rowData: EngageNotificationsModel }) => (
					<Cell.Wrapper key={_id}>
						<Cell.ImageWrapper>
							<PushNotificationIcon />
						</Cell.ImageWrapper>
						<Cell.Wrapper className="column with-image">
							<Cell.Text>{title}</Cell.Text>
							{!!creator?.length && (
								<Cell.Text className="light">By {`${creator[0]?.firstName} ${creator[0]?.lastName}`}</Cell.Text>
							)}
						</Cell.Wrapper>
					</Cell.Wrapper>
				),
				loaderTemplate: TableColumnLoaderType.imageWthTwoTextRows,
				dataKey: "title"
			},
			{
				alignment: "left",
				width: 250,
				label: <Cell.HeaderText>Creation Date</Cell.HeaderText>,
				Cell: ({ rowData: { createdAt } }: { rowData: EngageNotificationsModel }) => (
					<Cell.Wrapper className="column">
						<Cell.Text>{DateTime.fromISO(createdAt).toFormat("LLL dd, yyyy")}</Cell.Text>
						<Cell.Text className="light">{DateTime.fromISO(createdAt).toFormat("h:mm a")}</Cell.Text>
					</Cell.Wrapper>
				),
				loaderTemplate: TableColumnLoaderType.twoTextRows,
				dataKey: "createdAt"
			},
			{
				alignment: "left",
				label: <Cell.HeaderText>Status</Cell.HeaderText>,
				maxWidth: 250,
				Cell: ({ rowData: { status } }: { rowData: EngageNotificationsModel }) => (
					<Cell.Wrapper>
						<Cell.Text>{status}</Cell.Text>
					</Cell.Wrapper>
				),
				dataKey: "status"
			},
			{
				alignment: "center",
				label: <Cell.HeaderText>Sent / Open</Cell.HeaderText>,
				maxWidth: 230,
				Cell: ({ rowData: { sent, opened } }: { rowData: EngageNotificationsModel }) => (
					<Cell.Wrapper className="center">
						<ReceivedOpen align="center" display="block" paragraph variant="body1">
							<span>{sent}</span> / <StyledNumbers>{opened}</StyledNumbers>
						</ReceivedOpen>
					</Cell.Wrapper>
				),
				dataKey: "received"
			},
			{
				alignment: "right",
				minWidth: 50,
				maxWidth: 50,
				label: "",
				Cell: ({ rowData, rowIndex }: { rowData: EngageNotificationsModel; rowIndex: number }) => (
					<MenuDots
						menuId={`moreAction${rowIndex}`}
						options={getOptions(rowData)}
						vertical
						removeBg
						removeshadow
						removeSideMargin
					/>
				),
				loaderTemplate: TableColumnLoaderType.menuDots,
				dataKey: "menu"
			}
		],
		[getOptions, totalCount]
	);

	const handleChangePage = useCallback(
		(newPage: number) => {
			setPage(newPage);
		},
		[setPage]
	);

	const handleChangeRowsPerPage = useCallback(
		(newPageSize: number) => {
			setNotificationShowPerPage(newPageSize);
			setPage(1);
		},
		[setNotificationShowPerPage, setPage]
	);

	const TableBlock = useMemo(
		() => (
			<TableWrapper sizes={{ horizontalTablet: 900 }}>
				<Table
					columns={tableColumns}
					data={notifications}
					loading={loadingNotifications}
					paginated
					totalDataCount={totalCount}
					page={page}
					pageSize={notificationShowPerPage}
					onChangePage={handleChangePage}
					onChangePageSize={handleChangeRowsPerPage}
				/>
			</TableWrapper>
		),
		[
			tableColumns,
			notifications,
			loadingNotifications,
			totalCount,
			page,
			notificationShowPerPage,
			handleChangePage,
			handleChangeRowsPerPage
		]
	);

	return (
		<>
			<PageTemplate
				searchId="searchNotification"
				actionId="createNotification"
				title="Push Notifications"
				isLoading={loadingNotifications}
				isNoData={!notifications.length}
				emptyText="You don’t have any Notifications in your community yet."
				searchPlaceholder="Search Notification"
				onSearchUpdate={handleSearch}
				actionText="Create New Notification"
				onCreateClick={handleAddNotification}
			>
				{TableBlock}
			</PageTemplate>
			{addNotificationModalDialog?.open && (
				<Suspense fallback={null}>
					<NotificationModal
						editableNtf={addNotificationModalDialog?.editableNtf}
						open
						onClose={(updated?: boolean) => {
							setAddNotificationModalDialog(undefined);
							if (updated) {
								setPage(1);
								setKeyword("");
								fetchData(1, notificationShowPerPage, "");
							}
						}}
					/>
				</Suspense>
			)}
			<DeleteNotificationDialog />
		</>
	);
});

export default Engages;
