import React from "react";

import { useLocation } from "react-router";

import { routes } from "apps/Auth/constants";
import { view } from "modules/App";

const CreatePasswordPage = () => {
	const { search } = useLocation();
	const query = new URLSearchParams(search);
	const token = query.get("token") || undefined;
	const workspace = query.get("workspace") || undefined;
	const communityUrl = query.get("communityUrl") || undefined;
	const email = query.get("email")?.toString() || "";
	const tenantLevel = JSON.parse(query.get("tenantLevel")?.toString() || "") || false;

	return (
		<view.ResetPassword
			forgotPasswordLink={routes.auth.forgotPassword.getPath()}
			signInLink={routes.auth.signIn.getPath()}
			token={token}
			workspace={workspace}
			communityUrl={communityUrl}
			email={email}
			tenantLevel={tenantLevel}
		/>
	);
};

export default CreatePasswordPage;
