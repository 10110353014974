import React, { useCallback } from "react";

import { useFirebase, useMessaging } from "modules/Messaging/Data";
import { ChatMessage } from "modules/Messaging/Data/types";
import { RelativeWrapper } from "shared/styles";

import Actions from "./Actions";

import ImageWithOverlay from "./ImageWithOverlay";

import Reactions from "./Reactions";

import { RenderActionType } from "..";
import { MessageImage } from "../styles";

const ImageBubble = props => {
	const { onSend } = useFirebase();
	const { getData: getMessagingData, setPreviewDialog } = useMessaging();
	const { currentChat } = getMessagingData();

	const handleDelete = useCallback(() => {
		if (!currentChat) return;

		const msg = {
			...props.currentMessage,
			edited: props.currentMessage,
			images: (props.currentMessage.images || []).filter(im => im.id !== props.imageItem.id)
		} as ChatMessage;

		onSend({ messages: [msg], chat: currentChat });
	}, [currentChat, onSend, props.currentMessage, props.imageItem.id]);

	const handleOpenPreview = useCallback(() => {
		setPreviewDialog({
			user: {
				avatar: props.user.avatar,
				name: props.user.name
			},
			file: {
				name: props.imageItem.name,
				url: props.imageItem.image,
				type: "Image",
				createdAt: props.currentMessage.createdAt
			}
		});
	}, [
		props.currentMessage.createdAt,
		props.imageItem.image,
		props.imageItem.name,
		props.user.avatar,
		props.user.name,
		setPreviewDialog
	]);

	return (
		<RelativeWrapper data-message={props.imageItem.id}>
			<ImageWithOverlay
				handleOpenPreview={handleOpenPreview}
				bg={<MessageImage style={{ backgroundImage: `url(${props.imageItem.image})` }} />}
			/>
			{props.imageItem.recentLikes && (
				<Reactions list={props.imageItem.recentLikes} isCurrentUser={props.isCurrentUser} />
			)}
			<Actions
				replyItem={{
					id: props.imageItem.id,
					text: "photo"
				}}
				isCurrentUser={props.isCurrentUser}
				onDelete={handleDelete}
				onEdit={props.onEdit}
				onForward={props.onForward}
				onReply={props.onReply}
				onLike={props.onLike}
				currentMessage={props.currentMessage}
				type={RenderActionType.IMAGE}
			/>
		</RelativeWrapper>
	);
};

export default ImageBubble;
