import { useCallback, useEffect, useMemo, useState } from "react";

import { useConfirmPopupStore } from "shared/contexts";

interface Props {
	onClose?: () => void;
	open?: boolean;
}

const useConfirmLeavePopup = ({ onClose, open }: Props) => {
	const [confirmedLeaving, setConfirmedLeaving] = useState(false);

	const store = useConfirmPopupStore();
	const { setState } = useConfirmPopupStore();

	const providedValuesToCheck = useMemo(() => {
		return store.providedValuesToCheck;
	}, [store.providedValuesToCheck]);

	useEffect(() => {
		if (confirmedLeaving && open && onClose) {
			if (onClose) onClose();
			setConfirmedLeaving(false);
		}
	}, [confirmedLeaving, onClose, open]);

	const methods = useMemo(
		() => ({
			closeConfirmPopup: () => {
				setState(ctx => {
					ctx.showConfirmPopup = false;
					ctx.providedValuesToCheck = false;
				});
			},
			handleLeavePageConfirmed: () => {
				setConfirmedLeaving(true);
				methods.closeConfirmPopup();
			},
			provideHandleCloseValues: (values: boolean) => {
				setState(ctx => {
					ctx.providedValuesToCheck = values;
				});
			},
			handleClose: (valuesToCheck?: boolean) => {
				if (valuesToCheck || providedValuesToCheck) {
					setState(ctx => {
						ctx.showConfirmPopup = true;
					});
					return false;
				} else {
					if (onClose) onClose();
					setState(ctx => {
						ctx.showConfirmPopup = false;
					});
				}
			}
		}),
		[setState, onClose, providedValuesToCheck]
	);

	const getData = useCallback(() => {
		return store;
	}, [store]);

	return { ...methods, getData };
};

export default useConfirmLeavePopup;
