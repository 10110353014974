import React, { FC, useState } from "react";

import { ButtonBase } from "@material-ui/core";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";

import { ReactComponent as BackArrowIcon } from "assets/icons/back-arrow.svg";

import { OpenDocumentButton, Wrapper } from "./style";

const PDFReader: FC<{ file: string }> = ({ file }) => {
	const [numPages, setNumPages] = useState(null);
	const [pageNumber, setPageNumber] = useState(1);
	const [showSidePages, setShowSidePages] = useState(false);

	const onDocumentLoadSuccess = ({ numPages }) => {
		setNumPages(numPages);
		setPageNumber(1);
		if (numPages > 1) setShowSidePages(true);
	};

	const changePage = (offset: number) => {
		setPageNumber(prevPageNumber => prevPageNumber + offset);
	};

	const previousPage = () => {
		changePage(-1);
	};

	const nextPage = () => {
		changePage(1);
	};

	return (
		<Wrapper>
			<Wrapper.PDF>
				<Document
					file={file}
					onLoadSuccess={onDocumentLoadSuccess}
					onLoadError={error => console.log("error: ", error)}
				>
					<Page width={280} pageNumber={pageNumber} />
				</Document>
				{showSidePages && (
					<>
						<Wrapper.ExtraPaper heightOffset={6} zIndex={2} />
						<Wrapper.ExtraPaper heightOffset={12} zIndex={1} />
					</>
				)}
			</Wrapper.PDF>
			<Wrapper.Actions>
				<ButtonBase disabled={pageNumber <= 1} onClick={previousPage} className="circle">
					<BackArrowIcon />
				</ButtonBase>
				<OpenDocumentButton href={file} target="_blank" rel="noopener noreferrer">
					Open
				</OpenDocumentButton>
				<ButtonBase disabled={pageNumber >= (numPages || 0)} onClick={nextPage} className="circle">
					<BackArrowIcon className="next" />
				</ButtonBase>
			</Wrapper.Actions>
			{/* <div>
				<p>
					Page {pageNumber || (numPages ? 1 : "--")} of {numPages || "--"}
				</p>
				<button type="button" disabled={pageNumber <= 1} onClick={previousPage}>
					Previous
				</button>
				<button type="button" disabled={pageNumber >= (numPages || 0)} onClick={nextPage}>
					Next
				</button>
			</div> */}
		</Wrapper>
	);
};

export default PDFReader;
