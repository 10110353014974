import { ChatMessage } from "modules/Messaging/Data/types";

export const getLastMessage = (message: Partial<ChatMessage>): string =>
	message?.text
		? message.text
		: message?.place
		? "event was shared"
		: message.audios || message.track
		? "audio was shared"
		: message.group
		? "group was shared"
		: message.image || message.images
		? "image was shared"
		: message.location
		? "location was shared"
		: message.payreq
		? "payment request was shared"
		: message.videos
		? "video was shared"
		: message.gif
		? "a gif was shared"
		: message.files || message.file
		? "a file was shared"
		: message.carpool?.isUpdated
		? "Carpool booking has been updated"
		: !message.carpool?.isUpdated
		? "🚘 Carpool chat was created"
		: "";
