import React, { FC, useEffect, useMemo, useState } from "react";

import clsx from "clsx";

import { ReactComponent as IconCrown } from "assets/icons/icon-crown.svg";
import { PlaceholderImage } from "shared/Components";
import { TableColumnLoaderType } from "shared/Components/NewTable/Components";
import { Cell } from "shared/Components/NewTable/style";
import { useFundraisers } from "shared/hooks";
import { LeaderboardMember, PlaceholderImageType } from "shared/types";
import { formatMoney } from "utils/formatMoney";
import { formatCount } from "utils/serviceUtils/helpers";

const Table = React.lazy(() => import("shared/Components/NewTable"));

const MembersTable: FC<{ show: boolean; workspace?: string | null }> = ({ show, workspace }) => {
	const [tableData, setTableData] = useState<LeaderboardMember[]>([]);
	const [isLoading, setIsLoading] = useState(true);

	const { getLeaderboardMembers, getData: getFundraiserData } = useFundraisers();
	const { fundraiser } = getFundraiserData();

	useEffect(() => {
		if (show && !tableData.length && fundraiser) {
			setIsLoading(true);
			getLeaderboardMembers(fundraiser._id, workspace).then(data => {
				data && setTableData(data.filter(g => !!g.totalRaised));
				setIsLoading(false);
			});
		}
	}, [fundraiser, getLeaderboardMembers, show, tableData.length, workspace]);

	const tableColumns = useMemo(
		() => [
			{
				alignment: "left",
				width: 338,
				label: <Cell.HeaderText>{formatCount(tableData.length, "Member")}</Cell.HeaderText>,
				Cell: ({ rowData, rowIndex }: { rowData: LeaderboardMember; rowIndex: number }) => (
					<Cell.Wrapper>
						<Cell.Order className="mr-3">{rowIndex + 1}.</Cell.Order>
						<div className="relative">
							<Cell.ImageWrapper>
								{!!rowData.persona.photos.length ? (
									<Cell.Image src={rowData.persona.photos[0].profilePicture} />
								) : (
									<PlaceholderImage
										type={PlaceholderImageType.PROFILE_IMAGE}
										width={40}
										height={40}
										viewBox={"0 0 400 400"}
									/>
								)}
							</Cell.ImageWrapper>
							{rowIndex < 3 && (
								<div className="absolute -top-2 -left-2 bg-white rounded-full p-1">
									<IconCrown
										className={clsx(`leaderboard-crown-${rowIndex + 1}`, rowIndex === 0 ? "h-5 w-5" : "h-4 w-4")}
									/>
								</div>
							)}
						</div>
						<Cell.Wrapper className="column with-image">
							<Cell.Text>
								{rowData.persona.firstName} {rowData.persona.lastName}
							</Cell.Text>
						</Cell.Wrapper>
					</Cell.Wrapper>
				),
				loaderTemplate: TableColumnLoaderType.imageWthOneTextRow,
				dataKey: "content"
			},
			{
				alignment: "left",
				minWidth: 120,
				label: <Cell.HeaderText>Raised amount</Cell.HeaderText>,
				Cell: ({ rowData }: { rowData: LeaderboardMember }) => (
					<Cell.Wrapper>
						<Cell.Text>{rowData.totalRaised ? formatMoney(rowData.totalRaised.totalRaised) : "-"}</Cell.Text>
					</Cell.Wrapper>
				),
				dataKey: "raisedAmount"
			},
			{
				alignment: "left",
				minWidth: 120,
				label: <Cell.HeaderText>Sponsors</Cell.HeaderText>,
				Cell: ({ rowData }: { rowData: LeaderboardMember }) => (
					<Cell.Wrapper>
						<Cell.Text>{rowData.totalRaised ? rowData.totalRaised.totalSponsors : "-"}</Cell.Text>
					</Cell.Wrapper>
				),
				dataKey: "sponsors"
			},
			{
				alignment: "left",
				minWidth: 120,
				label: <Cell.HeaderText>Group</Cell.HeaderText>,
				Cell: ({ rowData }: { rowData: LeaderboardMember }) => (
					<Cell.Wrapper>
						<Cell.Text>{rowData.group[0].name}</Cell.Text>
					</Cell.Wrapper>
				),
				dataKey: "Group"
			}
		],
		[tableData.length]
	);

	return (
		<Table
			columns={tableColumns}
			data={tableData}
			loading={isLoading}
			totalDataCount={20}
			page={1}
			pageSize={20}
			onChangePage={() => {}}
			onChangePageSize={() => {}}
		/>
	);
};

export default MembersTable;
