import { Box } from "@material-ui/core";
import styled, { css } from "styled-components";

export const TrackInfoWrapper = styled(Box)`
	display: flex;
	justify-content: space-between;
	padding: 12px 0;
	border-bottom: 1px solid #edf1f7;
	width: 600px;
	margin: auto;
	.media-type {
		width: 24px;
		height: 24px;
		border-radius: 99%;
		background-color: #ffffff;
		display: flex;
		align-items: center;
		justify-content: center;
		svg {
			width: 14px;
			height: 14px;
			path:nth-child(2) {
				fill: #c5cee0;
			}
		}
		position: absolute;
		top: -6px;
		left: 6px;
	}
	.track-name {
		display: flex;
		align-items: center;
		width: 62%;
		.drag-icon-wrapper {
			transform: rotate(90deg);
			cursor: grab;
			margin-right: 20px;
		}
		.track-no-img {
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: #edf1f7;
			svg {
				fill: #c5cee0;
			}
		}
		.track-no-img,
		img {
			width: 40px;
			height: 40px;
			object-fit: cover;
			border-radius: 8px;
			margin: 0 16px;
			flex: 0 0 auto;
		}
		p {
			color: #8f9bb3;
		}
	}
	.track-duration-actions {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 35%;
	}

	${props => props.theme.breakpoints.up("md")} {
		width: auto;
	}
`;

export const AlbumDetailsModalContent = styled(Box)`
	padding: 32px 16px 0;
	img {
		width: 160px;
		height: 160px;
		object-fit: cover;
		border-radius: 14px;
	}
	.album-details {
		margin-left: 0;
		margin-top: 12px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		span.MuiTypography-caption {
			color: #8f9bb3;
			display: block;
		}
		button {
			align-self: flex-start;
			margin: 0;
			margin-top: 12px;
		}
	}
	.description {
		margin: 24px 0;
		h6 {
			margin-bottom: 4px;
		}
	}
	.tracks {
		button {
			svg {
				margin-right: 4px;
			}
		}
	}
	.tracks-list {
		max-height: 320px;
		overflow-y: auto;
		-moz-user-select: none;
		-khtml-user-select: none;
		-webkit-user-select: none;
		-ms-user-select: none;
		user-select: none;
		::-webkit-scrollbar {
			width: 3px;
		}
		::-webkit-scrollbar-button {
			display: none;
		}
		::-webkit-scrollbar-track-piece {
			background: #f7f9fc;
		}
		::-webkit-scrollbar-thumb {
			background: #c6cfe0;
		}
	}

	${props => props.theme.breakpoints.up("sm")} {
		padding: 32px 40px 0;
		.album-details-image {
			display: flex;
		}
		.album-details {
			margin-left: 24px;
			margin-top: 0;
			span.MuiTypography-caption {
				margin: 8px 0 4px;
			}
			button {
				margin-top: 0;
			}
		}
	}
`;

export const LoadingAlbum = styled(Box)`
	display: flex;
	justify-content: center;
	align-items: center;
	padding-bottom: 40px;
	> span {
		margin-left: 12px;
	}
`;

export const MoreActionsMenu = styled(Box)`
	padding: 8px 0;
	border-radius: 4px;
	background-color: #fff;
	box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.16);
	position: absolute;
	${({ lastItem }) =>
		lastItem
			? css`
					right: calc(100% + 4px);
					bottom: 0;
			  `
			: css`
					right: 0;
					top: calc(100% + 4px);
			  `}
	z-index: 10;
	min-width: 220px;
	opacity: 0;
	transform: translateY(-40px);
	transition: all 0.3s ease-in-out;
	pointer-events: none;

	&.show {
		opacity: 1;
		pointer-events: auto;
		transform: translateY(0);
	}
	.action {
		font-size: 15px;
		line-height: 1.33;
		color: #222b45;
		width: 100%;
		padding: 10px 0;
		padding-left: 16px;
		display: flex;
		justify-content: flex-start;
		&:hover {
			background-color: #edf1f7;
			border-radius: 4px;
		}
	}
`;

export const DeleteAlbumDialogContent = styled(Box)`
	padding: 16px 0;
	${props => props.theme.breakpoints.up("md")} {
		padding: 16px 32px;
	}
`;

export const DeleteAlbumFooter = styled(Box)`
	display: flex;
	justify-content: flex-end;
	margin-bottom: 12px;
	button {
		width: 50%;
		&:first-child {
			margin-left: 0;
		}
		&:last-child {
			margin-right: 0;
		}
	}
	${props => props.theme.breakpoints.up("sm")} {
		margin-bottom: 0;
		button {
			width: 120px;
		}
	}
	${props => props.theme.breakpoints.up("md")} {
		border-top: 1px solid #edf1f7;
		padding: 16px;
		margin-top: 8px;
	}
`;

export const PreviewAudio = styled.audio`
	display: none;
`;
