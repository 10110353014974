export enum MemberStatus {
	pending = "PENDING",
	archive = "ARCHIVE",
	joined = "JOINED",
	onboarded = "ON_BOARDED",
	banned = "BANNED",
	deleted = "DELETED"
}

export enum MemberActions {
	delete = "delete",
	archive = "archive",
	cancel = "cancel",
	resend = "resend",
	editRole = "editRole",
	ban = "ban",
	unban = "unban"
}

export enum InvitedToGroupStatus {
	pending = "PENDING",
	invited = "INVITED",
	owner = "OWNER",
	moderator = "MODERATOR",
	member = "MEMBER",
	NOT_MEMBER = ""
}
