import React from "react";

import { view } from "modules/MemberHome";
import { FeatureFlags } from "shared/Components/MemberNavPanel/constants";
import useCheckIfEnabled from "shared/hooks/useCheckIfFlagEnabled";

const PodcastsPage = () => {
	useCheckIfEnabled(FeatureFlags.tracks);
	return <view.Podcasts />;
};

export default PodcastsPage;
