import React, { FC } from "react";

import { SkeletonBase } from "shared/Components/index";

import { Column, Header, Row } from "../SimpleTable/style";
import { ColumnSkeleton, TableSpecialColumnType } from "../index";

export enum TableColumnLoaderType {
	imageWthOneTextRow = "imageWthOneTextRow",
	imageWthTwoTextRows = "imageWthTwoTextRows",
	oneTextRow = "oneTextRow",
	twoTextRows = "twoTextRows",
	menuDots = "menuDots",
	checkbox = "checkbox",
	expand = "expand"
}

interface SkeletonLoaderProps {
	hideHeader: boolean;
	headerHeight: number;
	rowHeight: number;
	columnList: any[];
	pageSize: number;
	columnWidth: (index: number) => number;
}

const SkeletonLoader: FC<SkeletonLoaderProps> = ({
	hideHeader,
	headerHeight,
	rowHeight,
	columnList,
	pageSize,
	columnWidth
}) => (
	<>
		{!hideHeader && (
			<Header.Wrapper>
				<Row.Wrapper style={{ height: headerHeight }}>
					{columnList.map((column, columnIndex) => (
						<Column.Wrapper
							key={columnIndex}
							style={{ width: columnWidth(columnIndex) }}
							className={`${column?.alignment ? `alignment_${column?.alignment}` : ""}`}
						>
							{column?.customLoaderTemplate ? (
								<>{column.customLoaderTemplate}</>
							) : column?.type === TableSpecialColumnType.checkbox ? (
								<ColumnSkeleton type={TableColumnLoaderType.checkbox} />
							) : column?.type !== TableSpecialColumnType.expand ? (
								<SkeletonBase width="50%" height={26} />
							) : null}
						</Column.Wrapper>
					))}
				</Row.Wrapper>
			</Header.Wrapper>
		)}
		{Array.from(Array(pageSize).keys()).map((row, rowIndex) => (
			<>
				<Row.Wrapper key={rowIndex} style={{ height: rowHeight }}>
					{columnList.map((column, columnIndex) => (
						<Column.Wrapper
							key={columnIndex}
							style={{ width: columnWidth(columnIndex) }}
							className={`${column?.alignment ? `alignment_${column.alignment}` : ""}`}
						>
							{column?.customLoaderTemplate ? (
								<>{column.customLoaderTemplate}</>
							) : (
								<ColumnSkeleton type={column?.loaderTemplate} />
							)}
						</Column.Wrapper>
					))}
				</Row.Wrapper>
			</>
		))}
	</>
);

export default SkeletonLoader;
