import styled, { css } from "styled-components";

import { Box, Text } from "shared/ui-kit";

export const Container = styled(Box)`
	border-radius: 12px;
	overflow: hidden;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08), inset 0 0 0 0.5px #c5cee0;
	background-color: #fff;
	position: relative;
`;

export const ImgViewer = styled.img`
	padding: 16px 0;
	max-height: 208px;
	width: 100%;
	background: #f1f3f4;
	object-fit: contain;
	${props => props.theme.breakpoints.up("sm")} {
		max-height: 418px;
	}
`;

export const PlayerWrapper = styled(Box)`
	position: relative;
`;

PlayerWrapper.Locked = styled(Box)`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	backdrop-filter: blur(20px);
	background-color: rgba(0, 0, 0, 0.45);
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	z-index: 9999;
	svg {
		fill: white;
	}
	color: white;
	.content {
		color: #fff;
		h3 {
			font-size: 22px;
			font-weight: bold;
			margin: 8px 0;
		}
		p {
			font-size: 13px;
		}
	}
	.btn-wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 24px;
		button {
			border-radius: 8px;
			padding: 0 12px;
			height: 40px;
			svg {
				margin-left: 6px;
			}
			&.white {
				background-color: #fff;
				color: #6173fe;
			}
		}
	}
`;

export const Content = styled(Box)`
	padding: 16px;
	.actions {
		margin-top: 16px;
		display: flex;
		align-items: center;
		.download {
			border-radius: 8px;
			margin: 0;
			padding: 0 16px;
			height: 40px;
			svg {
				margin-left: 6px;
			}
		}
		${props => props.theme.breakpoints.up("lg")} {
			display: none;
		}
	}
`;

Content.Header = styled(Box)`
	display: flex;
	justify-content: space-between;
	p {
		font-size: 13px;
		font-weight: 600;
		color: #222b45;
	}
	.actions {
		margin-top: 0;
		display: none;
		${props => props.theme.breakpoints.up("lg")} {
			display: flex;
			align-items: center;
			button:first-child {
				border-radius: 8px;
			}
		}
	}
`;

Content.Title = styled(Text)`
	font-size: 22px;
	font-weight: bold;
	line-height: 1.45;
	color: #222b45;
	${props => props.theme.breakpoints.up("sm")} {
		width: 65%;
	}
`;

Content.SubTitle = styled(Text)`
	font-size: 13px;
	line-height: 1.23;
	color: #8f9bb3;
`;

Content.Description = styled(Box)`
	margin-top: 24px;
	h4 {
		font-size: 15px;
		font-weight: 600;
		color: #222b45;
	}
	p {
		margin-top: 6px;
		font-size: 13px;
		color: #222b45;
		.read-more {
			color: ${({ color }) => color || "#6173fe"};
		}
	}

	${({ fullWidthDescription }) =>
		!fullWidthDescription &&
		css`
			${props => props.theme.breakpoints.up("sm")} {
				width: 65%;
			}
		`}
`;
