import { Box } from "@material-ui/core";

import styled, { css } from "styled-components";

import { Button, Text } from "shared/ui-kit";

const sharedListStyles = css`
	display: flex;
	align-items: center;
	width: 100%;
	text-align: left;
	justify-content: flex-start;
	margin-bottom: 20px;
	img {
		width: 48px;
		height: 48px;
		border-radius: 100px;
		object-fit: cover;
		margin-right: 16px;
	}
	h6 {
		font-size: 15px;
		font-weight: 600;
		line-height: 1.6;
		color: #222b45;
	}
	p {
		font-size: 13px;
		font-weight: 600;
		line-height: 1.85;
		color: #8f9bb3;
	}
`;

export const VolunteerSlotsList = styled(Box)`
	${sharedListStyles}
	justify-content: space-between;
`;

export const IconWrapper = styled(Box)`
	display: flex;
	align-items: center;
	justify-content: center;
	width: ${({ isSquareShape }) => (isSquareShape ? "48px" : "40px")};
	height: ${({ isSquareShape }) => (isSquareShape ? "48px" : "40px")};
	border-radius: ${({ isSquareShape }) => (isSquareShape ? "10px" : "24px")};
	background-color: #edf1f7;
	margin-right: 16px;
	flex-shrink: 0;
	img {
		width: 32px;
		height: 32px;
		object-fit: cover;
		border-radius: 99%;
	}
`;

export const SecondaryColorText = styled(Text)`
	color: #8f9bb3;
`;

export const ButtonWrapper = styled(Box)`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 5px 0 10px;
	h3 {
		font-size: 22px;
		font-weight: bold;
		line-height: 1.45;
		color: #222b45;
	}
	button {
		display: flex;
		align-items: center;
		svg {
			margin-right: 6px;
			fill: ${({ bgColor }) => bgColor};
			&.fill-first-path path:first-child {
				fill: ${({ bgColor }) => bgColor};
			}
			&.fill-last-path path:last-child {
				fill: ${({ bgColor }) => bgColor};
			}
		}
		border-radius: 8px;
		font-size: 14px;
		font-weight: bold;
		line-height: 1.14;
		background-color: ${({ bgColor }) => `${bgColor}33`};
		color: ${({ bgColor }) => bgColor};

		&:hover {
			svg {
				fill: #ffffff;
				&.fill-first-path path:first-child {
					fill: #ffffff;
				}
				&.fill-last-path path:last-child {
					fill: #ffffff;
				}
			}
		}
	}
`;

export const CancelButton = styled(Button)`
	color: #000000;
	background-color: #8f9bb329;

	&:hover {
		background-color: #8f9bb329;
	}
`;

export const VolunteerCardWrapper = styled(Box)`
	border: 1px solid rgba(197, 206, 224, 0.5);
`;
