import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { useBoards, useOnScreen } from "shared/hooks";
import { CategoryModel } from "shared/types/CategoryModel";
import { Text } from "shared/ui-kit";

import LoadingItems from "../LoadingItems";
import BoardBlock from "../blocks/BoardBlock";

interface Props {
	searchTerm: string;
	active?: boolean;
	selectedBoard: CategoryModel | undefined;
	setSelectedBoard: React.Dispatch<React.SetStateAction<CategoryModel | undefined>>;
}

const BoardsTab: React.FC<Props> = ({ searchTerm, active, selectedBoard, setSelectedBoard }) => {
	const { getBoards, getBoardsCount, getData: getBoardsData } = useBoards();
	const { boards, boardsCount, isLoading } = getBoardsData();

	const lastBoardRef = useRef<HTMLDivElement>(null);
	const [page, setPage] = useState(1);

	const onScreen = useOnScreen(lastBoardRef);

	const hasMoreBoards = useMemo(() => (boardsCount || 0) > (boards?.length || 0), [boards?.length, boardsCount]);

	useEffect(() => {
		if (active) getBoards({ keyword: searchTerm, paginated: true, offset: page });
	}, [getBoards, searchTerm, page, active]);

	useEffect(() => {
		if (active) getBoardsCount({ keyword: searchTerm });
	}, [getBoardsCount, searchTerm, active]);

	const handleToggleBoard = useCallback(
		(board: CategoryModel) => {
			if (board) setSelectedBoard(board);
		},
		[setSelectedBoard]
	);

	useEffect(() => {
		if (onScreen && hasMoreBoards) {
			setPage(state => state + 1);
		}
	}, [hasMoreBoards, onScreen]);

	return (
		<>
			{!boards?.length && !isLoading && <Text variant="h5">No Results</Text>}
			{isLoading && !boards?.length ? (
				<LoadingItems />
			) : (
				!!boards?.length &&
				boards?.map(board => (
					<BoardBlock
						handleToggleBoard={handleToggleBoard}
						board={board}
						key={board.cid}
						selectedBoard={selectedBoard}
						lastBoardRef={lastBoardRef}
					/>
				))
			)}
		</>
	);
};

export default BoardsTab;
