import config from "config/appConfig";

import { IStateProps } from "utils/StateOf";

import createStore from "utils/createStore";

const localStorageKey = `${config.GLOBAL_CONSTANTS.LOCAL_STORAGE_PREFIX}${config.HOOK_STORAGE_KEYS.LIVE_CONVERSATION_HELPER_DATA}`;

interface LiveConversationContextHelperValues {
	isRefresh: boolean;
}

export const initialState: IStateProps<LiveConversationContextHelperValues> = {
	isRefresh: false
};

export default createStore<LiveConversationContextHelperValues>({
	initialState,
	persistant: true,
	persistantName: localStorageKey
});
