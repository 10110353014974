import React from "react";

import { view } from "modules/MemberHome";

import { useMemberRoutes } from "shared/hooks";

const FillProfileMotherhoodPage = () => {
	const { getMemberRoutesData } = useMemberRoutes();
	const { routes } = getMemberRoutesData();
	return <view.FillProfileMotherhoodJourney selfiePageUrl={routes?.member.fillProfile.selfie.getPath()} />;
};

export default FillProfileMotherhoodPage;
