import React, { FC, useEffect, useState } from "react";

import { EventType } from "types";

import { eventModalType } from "modules/Manage/View/Containers/ManageEvents";
import { CreateEvent } from "shared/Components";
import { useEvent } from "shared/hooks";

import { EventModel } from "shared/types";

import { ModelBlockType } from "../../../ModelBlock";
import { GroupSectionTemplate } from "../../components";

const GroupEvents: FC<{ id: string; canCreate?: boolean }> = ({ id, canCreate }) => {
	const { getEvents, getData: getEventData } = useEvent();
	const { loading } = getEventData();

	const [events, setEvents] = useState<{
		total: number;
		data: EventModel[];
	}>({
		total: -1,
		data: []
	});
	const [eventInfoPopup, setEventInfoPopup] = useState<eventModalType>({
		open: false
	});

	useEffect(() => {
		getEvents({ page: 1, eventSchedule: EventType.All, groupId: id }).then(({ events, totalEventsFound }) => {
			setEvents({
				data: events,
				total: totalEventsFound
			});
		});
	}, [getEvents, id]);

	return (
		<>
			<GroupSectionTemplate
				loading={loading}
				autoFit={events.total > 3}
				items={events.data}
				type={ModelBlockType.event}
				actionButton={
					canCreate
						? { title: "Create Group Event", onClick: () => setEventInfoPopup({ open: true, model: undefined }) }
						: undefined
				}
				title="Group Events"
				noItemsErrorText="This group don’t have any events yet."
			/>

			{eventInfoPopup.open && (
				<CreateEvent
					groupId={id}
					open={eventInfoPopup.open}
					editableModel={eventInfoPopup.model}
					onClose={e => {
						setEventInfoPopup({ open: false });
						if (e.created) {
							getEvents({ page: 1, eventSchedule: EventType.All, groupId: id }).then(({ events, totalEventsFound }) => {
								setEvents({
									data: events,
									total: totalEventsFound
								});
							});
						}
					}}
				/>
			)}
		</>
	);
};

export default GroupEvents;
