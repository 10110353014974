import { useCallback, useMemo } from "react";

import { ItemId } from "modules/Manage/Data/types/SeriesTypes";
import { TrackModel } from "shared/types";

import { usePlayerStore } from "../contexts";

const usePlayer = () => {
	const store = usePlayerStore();
	const { setState } = usePlayerStore();

	const methods = useMemo(
		() => ({
			setPlayTracks: (playTracks: {
				idx: number;
				tracks?: {
					albumId?: string;
					items: TrackModel[];
				};
				series?: {
					id: string;
					items: ItemId[];
				};
			}) => {
				setState(ctx => ({ ...ctx, playTracks, play: true }));
			},
			setPlay: (play: boolean) => {
				setState(ctx => ({ ...ctx, play }));
			},
			setShuffleTracks: (shuffleTracks: boolean) => {
				setState(ctx => ({ ...ctx, shuffleTracks }));
			},
			setRepeatTrack: (repeatTrack: boolean) => {
				setState(ctx => ({ ...ctx, repeatTrack }));
			},
			setShowVoiceControl: (showVoiceControl: boolean) => {
				setState(ctx => ({ ...ctx, showVoiceControl }));
			},
			setVolume: (volume: number) => {
				setState(ctx => ({ ...ctx, volume }));
			},
			setAlbumPlaying: (albumPlaying?: string) => {
				setState(ctx => ({ ...ctx, albumPlaying }));
			}
		}),
		[setState]
	);

	const getData = useCallback(() => {
		return store;
	}, [store]);

	return { ...methods, getData };
};
export default usePlayer;
