import React, { ReactNode, createRef, useCallback, useEffect, useState } from "react";

import { useMediaQuery } from "@material-ui/core";

import * as R from "ramda";

import { useHistory } from "react-router";

import styled from "styled-components";

import { ReactComponent as GearIcon } from "assets/icons/gear.svg";
import { useAuth } from "modules/App/Data";

import { WidgetCustomThemeCard } from "shared/Components";
import { groupThemeList } from "shared/constants";
import { useCommunity, useFeature, useGTM, useMedia, usePersona, useUser } from "shared/hooks";

import { useS3Uploader } from "shared/services/s3Uploader";
import { MediaType, ProfileType, ThemePageProps } from "shared/types";
import {
	Box,
	Button,
	ColorPicker,
	FormContainer,
	FormFooter,
	Frame,
	Loader,
	Radio,
	Snackbar,
	Text,
	ThemeCard,
	deviceType,
	orientationType,
	positions
} from "shared/ui-kit";

import * as appTheme from "theme/default";

import { scrollTo } from "utils/scrollTo";

import { dataUrlToFile } from "utils/serviceUtils/helpers";

import {
	ContentWrapper,
	FormBlock,
	FrameBar,
	FrameBlock,
	FrameButton,
	FrameHeader,
	FrameInfoRow,
	FrameNavigationBlock,
	FrameRow,
	ThemeHeader as FrameThemeHeader
} from "../../Components";

interface NotificationProps {
	show: boolean;
	msg: string;
}
const ContentBlock = styled(Box)`
	display: flex;
	justify-content: center;
	align-items: center;

	width: 100%;

	${props => props.theme.breakpoints.down("sm")} {
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
	}
`;

const FormBlockWrapper = styled(Box)`
	width: 60%;
	display: flex;
	justify-content: center;

	${props => props.theme.breakpoints.down(appTheme.customBreakpoints.tabletHorizontalMax)} {
		width: 60%;
	}

	${props => props.theme.breakpoints.down(appTheme.customBreakpoints.tabletVerticalMax)} {
		width: 65%;
	}

	${props => props.theme.breakpoints.down("sm")} {
		width: 100%;
		padding-left: 0;
	}
`;

const FormFooterWrapper = styled(FormFooter)`
	margin-top: 16px;

	${props => props.theme.breakpoints.down("sm")} {
		margin-top: 4px;
	}
`;

const AssetWrapper = styled(Box)`
	width: 40%;

	display: flex;
	justify-content: flex-start;

	padding-left: 74px;

	.screen {
		background: white;
	}

	${props => props.theme.breakpoints.down(appTheme.customBreakpoints.tabletHorizontalMax)} {
		width: 35%;

		padding-left: 40px;
	}

	${props => props.theme.breakpoints.down(appTheme.customBreakpoints.tabletVerticalMax)} {
		width: 35%;

		padding-left: 30px;
	}

	${props => props.theme.breakpoints.down("sm")} {
		width: 100%;

		justify-content: center;

		padding-left: 0;

		margin-top: 26px;
	}
`;

const FrameContent = styled(Box)`
	display: block;

	width: 100%;

	${props => props.theme.breakpoints.down(appTheme.customBreakpoints.tabletVerticalMax)} {
		max-height: 60%;
		overflow: hidden;
	}

	${props => props.theme.breakpoints.down("sm")} {
		max-height: none;
	}
`;

const ThemeSectionWrapper = styled.section`
	margin-top: 15px;
	margin-bottom: 4px;

	${props => props.theme.breakpoints.down("sm")} {
		margin-top: 31px;
	}
`;

const ThemeCardWrapper = styled(Box)<{
	isShort: boolean;
}>`
	display: inline-block;
	margin: ${({ isShort }) => (isShort ? "14px" : 0)} 7px 12px 0;

	cursor: pointer;

	width: 32%;

	${props => props.theme.breakpoints.up("xs")} {
		&:nth-child(3n) {
			margin-right: 0;
		}
	}

	${props => props.theme.breakpoints.down("xs")} {
		width: 49%;
		margin-right: 6px;

		&:nth-child(3n) {
			margin-right: 6px;
		}

		&:nth-child(2n) {
			margin-right: 0;
		}

		& > div {
			width: 100%;
			max-width: 100%;
		}
	}

	${props => props.theme.breakpoints.down("sm")} {
		margin-bottom: 7px;
	}

	.theme-card__body {
		margin-left: ${({ isShort }) => (isShort ? 0 : "auto")};
	}
`;

const CustomThemeColorRow = styled(Box)`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const FrameContentWrapper = styled(FormContainer)`
	height: 100%;

	padding: 0;

	position: relative;
`;

const Theme: React.FC<ThemePageProps> = ({ congratulationUrl }) => {
	const history = useHistory();

	const { setTheme, createCommunity, updateWorkspaceMeta, getData: getCommunityData } = useCommunity();
	const { name, isPrivate, url, selectedTypes, theme, img, creating, created, isSportsMode } = getCommunityData();

	const { updatePersona, getData: getPersonaData } = usePersona();
	const { persona } = getPersonaData();

	const { getData: getFeatureData } = useFeature();
	const { selectedFeatures } = getFeatureData();

	const { uploadFile } = useS3Uploader();

	const { uploadFiles, createMediaForUploaded } = useMedia();

	const { getData: getAuthData } = useAuth();
	const { email } = getAuthData();

	const { getData: getUserData } = useUser();
	const { user } = getUserData();

	const { addEvent } = useGTM();

	const [notification, setNotification] = useState<NotificationProps>({ show: false, msg: "" });
	const [showLoader, setLoader] = useState<boolean>(false);
	const [showCustomTheme, setShowCustomTheme] = useState<string>("");
	const [initialized, setInitialized] = useState<boolean>(false);
	const [customPrimaryColor, setCustomPrimaryColor] = React.useState<string>("#f03aaa");
	const [customPrimaryBtnColor, setCustomPrimaryBtnColor] = React.useState<string>("#f03aaa");

	const [selectThemeState, setSelectThemeState] = useState<string[]>(
		groupThemeList.map(() => {
			return "";
		})
	);
	const [activeTheme, setActiveTheme] = useState<number | null>(null);

	const lessTabletHorizontalMax = useMediaQuery(
		appTheme.default.breakpoints.down(appTheme.customBreakpoints.tabletHorizontalMax)
	);
	const lessTabletVerticalMax = useMediaQuery(
		appTheme.default.breakpoints.down(appTheme.customBreakpoints.tabletVerticalMax)
	);
	const lessSm = useMediaQuery(appTheme.default.breakpoints.down("sm"));
	const lessXs = useMediaQuery(appTheme.default.breakpoints.down("xs"));

	let smartphoneWidth = 310;
	let maxFrameHeight = 610;
	if (lessTabletHorizontalMax) {
		smartphoneWidth = 280;
	}
	if (lessTabletVerticalMax) {
		smartphoneWidth = 240;
		maxFrameHeight = 578;
	}
	if (lessSm) {
		smartphoneWidth = 300;
		maxFrameHeight = 568;
	}

	if (lessXs) {
		maxFrameHeight = 682;
	}

	const selectActiveTheme = (id: number): void => {
		setActiveTheme(id);

		const currentTheme = groupThemeList.find(x => x.id === id);
		if (currentTheme) {
			const index = groupThemeList.indexOf(currentTheme);

			const newThemeState = selectThemeState.map((theme, i) => {
				return index === i ? currentTheme.options[0].value : "";
			});
			setSelectThemeState([...newThemeState]);
		}

		if (showCustomTheme) {
			setShowCustomTheme("");
		}
	};

	const updatePersonaInfo = useCallback(
		async (communityData, persona) => {
			const uploads: (Promise<any> | null)[] = [];
			if (persona?.coverImage) {
				uploads.push(
					new Promise(async resolve => {
						if (typeof persona.coverImage === "string" && !persona.coverImage.includes("base64")) {
							const data = createMediaForUploaded({
								fileUrl: persona.coverImage,
								type: MediaType.coverPhoto,
								personaId: Number(communityData?.persona.personaId)
							});

							resolve(data);
						} else {
							const correctFile =
								typeof persona.coverImage === "string"
									? await dataUrlToFile(persona.coverImage, "test")
									: persona.coverImage;

							const data = uploadFiles({
								files: [correctFile],
								communityName: communityData.url,
								personaId: Number(communityData?.persona.personaId),
								type: MediaType.coverPhoto
							});

							resolve(data);
						}
					})
				);
			} else {
				uploads.push(null);
			}

			if (persona?.profileImage) {
				uploads.push(
					new Promise(async resolve => {
						if (typeof persona.profileImage === "string" && !persona.profileImage.includes("base64")) {
							const data = createMediaForUploaded({
								fileUrl: persona.profileImage,
								type: MediaType.profilePhoto,
								personaId: Number(communityData?.persona.personaId)
							});

							resolve(data);
						} else {
							const correctFile =
								typeof persona.profileImage === "string"
									? await dataUrlToFile(persona.profileImage, "test")
									: persona.profileImage;

							const data = uploadFiles({
								files: [correctFile],
								communityName: communityData.url,
								personaId: Number(communityData?.persona.personaId),
								type: MediaType.profilePhoto
							});

							resolve(data);
						}
					})
				);
			} else {
				uploads.push(null);
			}

			Promise.all(uploads).then(values => {
				if (values[0]) {
					communityData.persona.coverPhotos = Array?.isArray(values[0]) ? values[0] : [values[0]];
				}

				if (values[1]) {
					communityData.persona.photos = Array?.isArray(values[1]) ? values[1] : [values[1]];
				}

				if (persona?.personalInfo) {
					communityData.persona.personalInfo = persona.personalInfo;
				}

				updatePersona(communityData?.persona);
			});
		},
		[uploadFiles, updatePersona, createMediaForUploaded]
	);

	const updateWorkspaceIcon = useCallback(
		async (url, img) => {
			const correctFile = typeof img === "string" ? await dataUrlToFile(img, "test") : img;
			const data = await uploadFile({ file: correctFile as File, communityName: url });
			await updateWorkspaceMeta(
				{
					meta: {
						img: data?.publicUrl || ""
					}
				},
				false
			);
		},
		[uploadFile, updateWorkspaceMeta]
	);

	const createNewCommunity = async () => {
		setLoader(true);

		try {
			const newCommunityData = await createCommunity({
				newCommunity: {
					name,
					url,
					typeList: selectedTypes,
					theme,
					isPrivate,
					owner: {
						email: email || user!.email,
						firstname: persona!.firstName,
						lastname: persona!.lastName
					},
					persona: {
						...R.pick(["firstName", "lastName", "personalInfo"], persona)
					},
					isSportType: isSportsMode
				},
				selectedFeatures,
				personaInfo: persona as ProfileType,
				workspaceImg: img
			});

			if (img) {
				updateWorkspaceIcon(newCommunityData.url, img);
			}

			updatePersonaInfo(newCommunityData, persona);
		} catch (error) {
			setNotification({ show: true, msg: (error as Error).message });
		}
		setLoader(false);
	};

	useEffect(() => {
		if (showCustomTheme) {
			setTheme({
				primaryColor: customPrimaryColor,
				primaryBtnColor: customPrimaryBtnColor
			});
		} else if (activeTheme) {
			const selectedTheme = groupThemeList.find(x => x.id === activeTheme);
			if (selectedTheme) {
				setTheme({
					primaryColor: selectedTheme.primaryColor,
					primaryBtnColor: selectedTheme.primaryBtnColor
				});
			}
		}
	}, [setTheme, showCustomTheme, customPrimaryColor, customPrimaryBtnColor, activeTheme]);

	useEffect(() => {
		if (!initialized) {
			setInitialized(true);

			if (theme.primaryColor && selectThemeState.findIndex(x => x) === -1) {
				const index = groupThemeList.findIndex(
					x => x.primaryColor === theme.primaryColor && x.primaryBtnColor === theme.primaryBtnColor
				);

				if (index > -1) {
					selectActiveTheme(groupThemeList[index].id);
				} else if (!showCustomTheme) {
					setShowCustomTheme("custom");
					setCustomPrimaryColor(theme.primaryColor);
					setCustomPrimaryBtnColor(theme.primaryBtnColor);
				}
			} else {
				selectActiveTheme(groupThemeList[0].id);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [initialized, theme, selectThemeState, showCustomTheme]);

	const customThemeHeader: ReactNode = (
		<React.Fragment>
			<Radio
				options={[{ label: "Create a custom theme", value: "custom" }]}
				value={showCustomTheme}
				onChange={() => {
					const newThemeState = selectThemeState.map(() => {
						return "";
					});
					setSelectThemeState([...newThemeState]);
					setShowCustomTheme("custom");
				}}
				register={createRef()}
			/>
			<GearIcon />
		</React.Fragment>
	);

	useEffect(() => {
		addEvent({ Action: "Create Community", Label: "Step-7" });
		scrollTo(0, 0);
	}, [addEvent]);

	useEffect(() => {
		if ((creating || created) && congratulationUrl) {
			history.push(congratulationUrl);
		}
	}, [creating, created, congratulationUrl, history]);

	return (
		<ContentWrapper>
			<ContentBlock>
				<FormBlockWrapper>
					<FormBlock
						customStyle={`margin: 0 0 0 0; padding-bottom: 77px; overflow: hidden; max-height: ${maxFrameHeight}px;`}
					>
						<Text variant="h2">Choose Your Theme</Text>
						<ThemeSectionWrapper>
							{groupThemeList.map((propTheme, index) => (
								<ThemeCardWrapper
									key={index}
									onClick={() => selectActiveTheme(propTheme.id)}
									isShort={!!showCustomTheme}
								>
									<ThemeCard {...propTheme} isShort={!!showCustomTheme} />
									<Radio
										options={propTheme.options}
										value={selectThemeState[index]}
										onChange={val => {
											selectActiveTheme(propTheme.id);
											const newThemeState = selectThemeState.map((theme, i) => {
												return index === i ? (val.target as HTMLInputElement).value : "";
											});
											setSelectThemeState([...newThemeState]);
										}}
										register={propTheme.ref}
									/>
								</ThemeCardWrapper>
							))}
						</ThemeSectionWrapper>
						<WidgetCustomThemeCard header={customThemeHeader} showBody={!!showCustomTheme}>
							<CustomThemeColorRow>
								<Text variant="body2">Your app primary color</Text>
								<ColorPicker
									color={customPrimaryColor}
									onChange={c => setCustomPrimaryColor(c.hex)}
									position={positions.top}
									disableAlpha
									expanded
								/>
							</CustomThemeColorRow>
							<CustomThemeColorRow>
								<Text variant="body2">Your primary button color</Text>
								<ColorPicker
									color={customPrimaryBtnColor}
									onChange={c => setCustomPrimaryBtnColor(c.hex)}
									position={positions.top}
									disableAlpha
									expanded
								/>
							</CustomThemeColorRow>
						</WidgetCustomThemeCard>
						<FormFooterWrapper>
							<Button
								removeSideMargin
								fullWidth
								disabled={!(activeTheme || showCustomTheme) || showLoader}
								size="large"
								customStyle={() => "margin-top: 8px;"}
								onClick={createNewCommunity}
							>
								{!showLoader ? (
									<>Continue</>
								) : (
									<Loader size="1rem" show={true} color="inherit" variant="indeterminate" />
								)}
							</Button>
						</FormFooterWrapper>
					</FormBlock>
				</FormBlockWrapper>
				<AssetWrapper>
					<Frame variant={deviceType.smartphone} orientation={orientationType.portrait} width={smartphoneWidth}>
						<FrameContentWrapper direction="column" style={{ height: "100%" }}>
							<FrameBar customStyle={"margin-top: -35px;"} />
							<FrameHeader color={theme.primaryColor} customStyle={"margin-top: 29px;"} />
							<FrameThemeHeader
								color={theme.primaryColor}
								customStyle={"width: calc(100% + 32px); height: 105px; margin: 8px -16px 0 -16px;"}
							/>
							<FrameContent>
								<FrameRow customStyle={"margin-top: 76px; justify-content: space-between; width: 100%;"}>
									<FrameButton color={theme.primaryBtnColor} />
									<FrameButton color={theme.primaryBtnColor} />
								</FrameRow>
								<FrameInfoRow customStyle={"width: 52%; height: 19px; margin-top: 24px;"} />
								<FrameInfoRow customStyle={"width: 100%; height: 24px;"} />
								<FrameInfoRow customStyle={"width: 50%; height: 12px;"} />
								<FrameBlock color={theme.primaryColor} customStyle={"margin-top: 20px;"} />
								<FrameBlock color={theme.primaryColor} customStyle={"margin-top: 8px;"} />
							</FrameContent>
							<FrameNavigationBlock
								color={theme.primaryColor}
								customStyle={"position: absolute; width: calc(100% + 32px); left: -16px; bottom: -16px;"}
							/>
						</FrameContentWrapper>
					</Frame>
				</AssetWrapper>
			</ContentBlock>
			<Snackbar
				showCloseButton
				onClose={() => setNotification({ ...notification, show: false })}
				open={notification.show}
				message={notification.msg}
			/>
		</ContentWrapper>
	);
};

export default Theme;
