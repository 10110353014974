import { Box } from "@material-ui/core";

import styled from "styled-components";

import { Button, Text } from "shared/ui-kit";

export const StyledButton = styled(Button)`
	font-size: 12px;
	margin: 0;

	@media (min-width: 991px) {
		white-space: nowrap;
	}
`;

export const Subgroups = styled(Box)``;

Subgroups.Wrapper = styled(Box)`
	background: #f7f9fc;

	& > .MuiBox-root {
		border-top-color: transparent;
	}

	.ReactVirtualized__Grid {
		background: #f7f9fc;

		.ReactVirtualized__Grid__innerScrollContainer {
			&:first-child {
				.FixedGridCell {
					border-top-color: transparent;
				}
			}

			&:last-child {
				.FixedGridCell {
					border-bottom-color: transparent;
				}
			}
		}
	}
`;

Subgroups.Title = styled(Text)`
	font-size: 12px;
	font-weight: 500;
	line-height: 1.33;
	color: #8f9bb3;

	padding: 8px 0 0 16px;
`;
