export enum EventSectionsEnum {
	About = "About",
	Discussions = "Discussions",
	Volunteers = "Volunteers",
	Carpool = "Carpool",
	Series = "Series",
	Videos = "Videos",
	Tracks = "Tracks",
	Files = "Files"
}
