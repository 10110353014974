import styled, { css } from "styled-components";

import { Box, Button } from "shared/ui-kit";

export const Wrapper = styled(Box)<{ horizontalAlignment?: "left" | "center" | "right" }>`
	display: flex;
	align-items: center;

	${({ horizontalAlignment }) => {
		if (horizontalAlignment) {
			switch (horizontalAlignment) {
				case "left":
					return css`
						justify-content: flex-start;
					`;

				case "center":
					return css`
						justify-content: center;
					`;

				case "right":
					return css`
						justify-content: flex-end;
					`;
			}
		}
	}}

	&.vertical {
		flex-direction: column;

		${({ horizontalAlignment }) => {
			if (horizontalAlignment) {
				switch (horizontalAlignment) {
					case "left":
						return css`
							align-items: flex-start;
						`;

					case "center":
						return css`
							align-items: center;
						`;

					case "right":
						return css`
							align-items: flex-end;
						`;
				}
			}
		}}
	}
`;

export const BuyButton = styled(Button)<{ ignoreWidth: boolean; isLight?: boolean; color: string }>`
	height: 40px;

	border-radius: 8px;

	margin: 0;

	display: flex;
	align-items: center;

	font-size: 14px;
	line-height: 1.14;
	font-weight: 600;

	&:hover {
		color: white;
	}

	.MuiButton-label {
		display: flex;
		align-items: center;

		svg {
			width: 20px;
			height: 20px;
			margin-left: 4px;
		}
	}

	${props => props.theme.breakpoints.up("sm") && !props.ignoreWidth} {
		width: 160px;
	}

	${({ isLight, color }) =>
		isLight &&
		color &&
		css`
			color: ${color};
			background: white;
		`}
`;

BuyButton.Wrapper = styled(Box)`
	margin-right: 8px;

	&.vertical {
		margin: 5px 0;
	}
`;
