import React from "react";

import TrackBlock from "shared/Components/Blocks/TrackBlock";
import { RelativeWrapper } from "shared/styles";
import { TrackMessageModel } from "shared/types/TrackModel";

import Actions from "./Actions";

import Reactions from "./Reactions";

import { RenderActionType } from "..";

const TrackBubble = props => (
	<RelativeWrapper data-message={props.currentMessage.track.id}>
		<TrackBlock
			maxWidth
			track={props.currentMessage?.track as TrackMessageModel}
			messageBlockCurrentUser={props.messageBlockCurrentUser}
		/>
		{props.currentMessage?.track.recentLikes && (
			<Reactions list={props.currentMessage?.track.recentLikes} isCurrentUser={props.isCurrentUser} />
		)}
		<Actions
			replyItem={{
				id: props.currentMessage.track.id,
				text: "track"
			}}
			isCurrentUser={props.isCurrentUser}
			onDelete={props.onDelete}
			onEdit={props.onEdit}
			onForward={props.onForward}
			onReply={props.onReply}
			onLike={props.onLike}
			currentMessage={props.currentMessage}
			type={RenderActionType.TRACK}
		/>
	</RelativeWrapper>
);

export default TrackBubble;
