import { Box } from "@material-ui/core";
import styled from "styled-components";

import { Button, Text } from "shared/ui-kit";

export const ActionBtn = styled(Button)`
	margin: 0;
`;

export const DialogBody = styled(Box)`
	padding: 40px;
`;

export const Option = styled(Box)`
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
`;

Option.Wrapper = styled(Box)`
	&:not(:last-child) {
		margin-bottom: 24px;
	}
`;

Option.Content = styled(Box)`
	display: flex;
	align-items: flex-start;
`;

Option.ToggleWrapper = styled(Box)`
	width: 100px;
	display: flex;
	justify-content: flex-end;
`;

Option.IconWrapper = styled(Box)`
	width: 24px;
	height: 24px;

	display: flex;
	justify-content: center;
	align-items: center;

	margin-right: 8px;

	svg {
		use {
			fill: #8f9bb3;
		}
	}
`;

Option.Body = styled(Box)`
	display: block;
`;

Option.Title = styled(Text)`
	font-size: 15px;
	font-weight: 600;
	line-height: 1.6;
	color: #222b45;
`;

Option.Text = styled(Text)`
	font-size: 13px;
	line-height: 1.54;
	color: #8f9bb3;
`;

Option.InputWrapper = styled(Box)`
	min-width: 32px;
	height: 24px;

	display: inline-flex;
	justify-content: center;
	align-items: center;

	border: solid 1px #e4e9f2;
	border-radius: 4px;

	background-color: transparent;

	overflow: hidden;

	padding: 5px 12px;

	margin: 0 5px;

	transition: background-color 0.25s ease;

	&.disabled {
		background-color: #edf1f7;
	}
`;

Option.Input = styled.input`
	font-size: 13px;
	line-height: 1.23;
	color: #8f9bb3;
	text-align: center;

	border: none;
	background: transparent;
`;

export const Title = styled(Text)`
	font-size: 18px;
	font-weight: bold;
	line-height: 1.33;
	color: #222b45;
	text-align: center;

	padding-left: 16px;
`;

export const Footer = styled(Box)`
	display: flex;
	justify-content: flex-end;
	margin-bottom: 12px;
	button {
		width: 50%;
		&:first-child {
			margin-left: 0;
		}
		&:last-child {
			margin-right: 0;
		}
	}
	${props => props.theme.breakpoints.up("sm")} {
		margin-bottom: 0;
		button {
			width: 120px;
		}
	}
	${props => props.theme.breakpoints.up("md")} {
		border-top: 1px solid #edf1f7;
		padding: 16px;
		margin-top: 8px;
	}
`;
