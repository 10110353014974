import { useCallback, useMemo } from "react";

import { IManageFeature } from "types";

import { useAuth } from "modules/App/Data/hooks";

import { originalFeatures } from "shared/constants";

import { useMarketingApiService } from "shared/services";
import useJwt from "shared/services/api/core/jwt";

import { useMarketingStore } from "../contexts";

const useMarketing = () => {
	const store = useMarketingStore();
	const { setState } = useMarketingStore();

	const marketingService = useMarketingApiService();

	const { getUser } = useAuth(true);
	const { getJwt, getWorkspace } = useJwt();

	const contextFeatures = useMemo(() => {
		return store.features;
	}, [store]);

	const methods = useMemo(
		() => ({
			getWorkspaceMonetization: async (name: string) => {
				const { subscriptions } = await marketingService.getAllMonetization(name);
				if (subscriptions?.length) {
					setState(ctx => ({ ...ctx, monetizationPlans: subscriptions }));
				}
			},
			getWorkspaceByName: async (name: string) => {
				try {
					const workspace = await marketingService.getWorkspaceInfo(name);
					return workspace;
				} catch (error) {
					console.error(error);
				}
			},
			getWorkspaceInfo: async (name: string) => {
				try {
					setState({ notFound: false });
					const workspace = await marketingService.getWorkspaceInfo(name);
					if (workspace.isPrivate && getJwt() && getWorkspace()) {
						try {
							const user = await getUser();
							workspace.isPrivate = !(user?.isGlobalAdmin || user?.isGlobalOwner || user?.isGlobalModerator);
						} catch {
							console.log("Can't fetch user");
						}
					}
					const features: IManageFeature[] = originalFeatures.map(feature => {
						const communityFeature = workspace.features.find(x => x.name === feature.value);
						return {
							...feature,
							enabled: communityFeature ? communityFeature.isEnabled : false
						};
					});

					setState(ctx => ({ ...ctx, workspace, features }));
				} catch (error) {
					setState(ctx => ({ ...ctx, notFound: true }));
				}
			},
			getWorkspacePosts: async (name: string) => {
				setState(ctx => ({ ...ctx, loadingPosts: true }));
				const { posts } = await marketingService.getWorkspacePosts(name);
				setState(ctx => ({ ...ctx, posts, loadingPosts: false }));
			},
			getWorkspaceStatistic: async (name: string) => {
				const statistic = await marketingService.getWorkspaceStatistic(name);
				setState(ctx => ({ ...ctx, statistic }));
			},
			getWorkspaceSubscriptionPlans: async (name: string) => {
				const { products } = await marketingService.getWorkspaceSubscriptionPlans(name);
				setState(ctx => ({
					...ctx,
					monthlySubscription: products.find(x => x.interval === "monthly"),
					annuallySubscription: products.find(x => x.interval === "annually")
				}));
			},
			setShowJoinPopup(open: boolean) {
				setState(ctx => ({ ...ctx, showJoinPopup: open }));
			},
			setIsAdult(isAdult: boolean) {
				setState(ctx => ({ ...ctx, isAdult }));
			},
			sendAppLink(phoneCode: string, phoneNumber: string, workspace: string) {
				marketingService.sendSMSLink(phoneCode, phoneNumber, workspace);
			}
		}),
		[marketingService, setState, getUser, getJwt, getWorkspace]
	);

	const getData = useCallback(() => {
		return store;
	}, [store]);

	const isFeatureEnabled = useCallback(
		(featureName?: string): boolean => {
			return featureName && contextFeatures
				? contextFeatures.find(x => x.value === featureName)?.enabled || false
				: true;
		},
		[contextFeatures]
	);

	return { ...methods, isFeatureEnabled, getData };

	// return useMemo(
	// 	() => ({
	// 		...context,
	// 		isFeatureEnabled: (featureName?: string): boolean => {
	// 			return featureName && context.features
	// 				? context.features.find(x => x.value === featureName)?.enabled || false
	// 				: true;
	// 		},
	// 		...methods
	// 	}),
	// 	[context, methods]
	// );
};

export default useMarketing;
