import React from "react";

import styled from "styled-components";

import { Box, Text } from "shared/ui-kit";

const Conatiner = styled(Box)`
	margin: 24px;
`;

const StyledText = styled(Text)`
	font-size: 12px;
	color: #8f9bb3;
	margin-top: 4px;
`;

const TypingContainer = styled(Box)`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 88px;
	height: 40px;
	position: relative;
	background: #edf1f7;
	border-radius: 4px;
`;

const TypingDot = styled(Box)`
	float: left;
	width: 8px;
	height: 8px;
	margin: 0 4px;
	background: #ffffff;
	border-radius: 50%;
	opacity: 0;
	animation: loadingFade 1s infinite;
	&:nth-child(1) {
		animation-delay: 0s;
	}

	&:nth-child(2) {
		animation-delay: 0.2s;
	}

	&:nth-child(3) {
		animation-delay: 0.4s;
	}

	@keyframes loadingFade {
		0% {
			opacity: 0;
		}
		50% {
			opacity: 1;
		}
		100% {
			opacity: 0;
		}
	}
`;

const TypingIndicator = ({ name }) => {
	return (
		<Conatiner>
			<TypingContainer>
				<TypingDot />
				<TypingDot />
				<TypingDot />
			</TypingContainer>
			<StyledText>{`${name} is typing...`}</StyledText>
		</Conatiner>
	);
};

export default TypingIndicator;
