export enum MonetizationPlanSubscriptionId {
	PREMIUM_ONLY = "premium_only",
	SUBSCRIPTION_ONLY = "subscription_only",
	SUBSCRIPTION_PREMIUM_ONLY = "subscription_premium_only"
}

export default interface monetizationPlansType {
	_id: string;
	description: string;
	image: string;
	name: string;
	subscriptionId: MonetizationPlanSubscriptionId;
	selected: boolean;
	disabled: boolean;
}
