import { useCallback, useMemo } from "react";

import useNotification from "shared/hooks/useNotification";
import { UserStatus, ValidateEmailMember } from "shared/types";
import getCountMessage from "utils/getCountMessage";
import { actionMethod, actionTypes, getValidationMessage } from "utils/getValidationMessage";
import { validateEmail } from "utils/serviceUtils/validators";

import { useTeamStore } from "../contexts/TeamContext";

import { useTeamListApiService } from "../services";
import { EditRoleTeamMember, ModalStateModel, getTeamMembersDataType } from "../types/";

const useTeam = () => {
	const teamService = useTeamListApiService();
	const { showMessage } = useNotification();

	const store = useTeamStore();
	const { setState } = useTeamStore();

	const methods = useMemo(
		() => ({
			getTeamMembers: async (data: getTeamMembersDataType) => {
				try {
					setState(ctx => ({ ...ctx, loadingTeamMembers: true }));
					const requestData = data;
					if (!requestData.keyword && (!requestData?.filterBy || !requestData?.filterBy?.length)) {
						requestData.filterBy = [
							UserStatus.PENDING,
							UserStatus.JOINED,
							UserStatus.ON_BOARDED,
							UserStatus.ARCHIVE,
							UserStatus.PAID_SUBSCRIPTION,
							UserStatus.BANNED,
							UserStatus.DELETED
						];
					}
					const users = await teamService.getTeamMembers(requestData);
					setState(ctx => ({ ...ctx, users, keyword: data.keyword, loadingTeamMembers: false }));
				} catch (error) {
					showMessage((error as Error).message);
				}
			},
			getTeamMembersEmails: async (data: getTeamMembersDataType) => {
				try {
					const users = await teamService.getTeamMembers(data);

					const usersEmails = users.map(user => user.email);

					setState(ctx => ({
						...ctx,
						usersEmails
					}));
				} catch (error) {
					showMessage((error as Error).message);
				}
			},
			deleteTeamMember: async (userIds: string[]) => {
				try {
					await teamService.deleteTeamMember(userIds);

					showMessage(
						getValidationMessage({
							name: "Member",
							actionType: actionTypes.CRUDUser,
							actionMethod: actionMethod.deleted,
							emoji: "🗑"
						}),
						3
					);

					setState(ctx => ({
						...ctx,
						totalCount: ctx.totalCount - userIds.length
					}));
				} catch (error) {
					showMessage((error as Error).message);
				}
			},
			editRoleTeamMember: async (data: EditRoleTeamMember, selectedRole?: string) => {
				try {
					const apiData = {
						isMember: !!data.personaId,
						isOwner: false,
						isModerator: false,
						isPending: false,
						isInvited: false,
						isAdmin: false,
						isInfluencer: false
					};

					if (data.personaId !== 0) {
						switch (selectedRole) {
							case "Admin":
								apiData.isAdmin = true;
								break;
							case "Owner":
								apiData.isOwner = true;
								break;
							case "Moderator":
								apiData.isModerator = true;
								break;
							case "Influencer":
								apiData.isInfluencer = true;
								break;
							case "Member":
								apiData.isMember = true;
								apiData.isInvited = true;
								break;
							default:
								apiData.isMember = false;
								apiData.isInvited = false;
						}

						await teamService.editRoleTeamMember({ ...apiData, ...data });

						setState(ctx => ({
							...ctx,
							users: ctx.users.map(x => ({
								...x,
								role: x.personaId === data.personaId && selectedRole ? selectedRole : x.role
							}))
						}));

						showMessage(
							getValidationMessage({
								name: "Role",
								actionType: actionTypes.CRUD,
								actionMethod: actionMethod.updated,
								emoji: "🎉"
							}),
							3
						);
					}
				} catch (error) {
					showMessage((error as Error).message);
				}
			},
			validateTypedEmailTeamMember: (email: string, toast = false) => {
				const isValid = email && validateEmail(email);
				if (!isValid) {
					if (toast) {
						showMessage(
							getValidationMessage({
								name: "Not a valid email"
							}),
							3
						);
						methods.setIsInvited(true);
					}

					return false;
				}

				return true;
			},
			validatedInvitedTeamMember: async (emails: string[]) => {
				try {
					return emails.length ? await teamService.validateEmailTeamMember(emails) : [];
				} catch (error) {
					showMessage(
						getValidationMessage({
							name: "User is invited already"
						}),
						3
					);
				}
			},
			cancelInviteTeamMember: async (userIds: string[]) => {
				try {
					await teamService.cancelInviteTeamMember(userIds);

					const invitedMembersLength =
						userIds.length === 1 ? "Invitation" : getCountMessage(userIds.length, "invitation");

					showMessage(
						getValidationMessage({
							name: invitedMembersLength,
							actionType: actionTypes.CRUD,
							actionMethod: actionMethod.cancelled,
							emoji: "❌"
						}),
						3
					);

					setState(ctx => ({
						...ctx,
						totalCount: ctx.totalCount - userIds.length
					}));
				} catch (error) {
					showMessage((error as Error).message);
				}
			},
			resendInviteTeamMember: async (userIds: string[]) => {
				try {
					await teamService.resendInviteTeamMember(userIds);

					const invitedMembersLength =
						userIds.length === 1 ? "Invitation" : getCountMessage(userIds.length, "invitation");

					showMessage(
						getValidationMessage({
							name: invitedMembersLength,
							actionType: actionTypes.CRUD,
							actionMethod: actionMethod.resent,
							emoji: "✉️"
						}),
						3
					);
				} catch (error) {
					showMessage((error as Error).message);
				}
			},
			inviteTeamMember: async (data: { emails: (string | undefined)[] | undefined; role: any; slug: string }) => {
				try {
					setState(ctx => ({ ...ctx, loadingTeamMembers: true }));
					await teamService.inviteTeamMember(data);

					const invitedMembersLength =
						data.emails &&
						getCountMessage(
							data.emails.length,
							data.role === "isAdmin" ? "admin team member" : "moderator team member"
						);

					const users = await teamService.getTeamMembers({
						offset: 1,
						limit: store.teamMemberShowPerPage,
						type: "team",
						keyword: null
					});

					const emailsLength = data.emails?.length || 0;

					emailsLength &&
						setState(ctx => ({
							...ctx,
							users,
							loadingTeamMembers: false,
							totalCount: ctx.totalCount + emailsLength
						}));
					showMessage(
						getValidationMessage({
							name: invitedMembersLength,
							actionType: actionTypes.CRUDUser,
							actionMethod: actionMethod.invited,
							emoji: "✉️",
							multiple: emailsLength > 1
						}),
						3
					);
				} catch (error) {
					showMessage((error as Error).message);
				}
			},
			searchTeamMembers: async keyword => {
				setState(ctx => ({ ...ctx, loadingTeamMembers: true }));
				const page = 1;
				const limit = keyword === "" ? 5 : 10;
				const users = await teamService.getTeamMembers({
					offset: page,
					limit,
					type: "team",
					keyword: keyword
				});

				await methods.getMemberTotalCount({ type: "members", keyword: keyword });

				setState(ctx => ({
					...ctx,
					users,
					loadingTeamMembers: false,
					page,
					teamMemberShowPerPage: limit,
					totalCount: users.length
				}));
			},
			setStateOfModal: ({
				isOpen = true,
				modalTitle,
				modalContent,
				apiRequest,
				confirmActionMessage,
				apiRequestParams,
				modalFooterActionsText
			}: ModalStateModel) => {
				setState(ctx => ({
					...ctx,
					modalIsOpen: isOpen,
					modalTitle,
					modalContent,
					modalFooterActionsText,
					apiRequest,
					confirmActionMessage,
					apiRequestParams
				}));
			},
			getMemberTotalCount: async data => {
				try {
					const requestData = data;
					if (!requestData.keyword && (!requestData?.filterBy || !requestData?.filterBy?.length)) {
						// fetch all except deleted
						requestData.filterBy = [
							UserStatus.PENDING,
							UserStatus.JOINED,
							UserStatus.ON_BOARDED,
							UserStatus.ARCHIVE,
							UserStatus.PAID_SUBSCRIPTION,
							UserStatus.BANNED
						];
					}

					const { totalCount } = await teamService.getTotalTeamMemberCount(requestData);
					setState(ctx => ({ ...ctx, totalCount }));
				} catch (error) {
					showMessage((error as Error).message);
				}
			},
			setEmailAddresses: ({ emailAddresses }: ValidateEmailMember) => {
				setState(ctx => ({ ...ctx, emailAddresses }));
			},
			setRole: (role: string) => {
				setState(ctx => ({ ...ctx, role }));
			},
			setIsInvited: (isInvited: boolean) => {
				setState(ctx => ({ ...ctx, isInvited }));
			},
			setShowToastMessage: (showToastMessage: boolean) => {
				setState(ctx => ({ ...ctx, showToastMessage }));
			},
			setEditMemberRole: (editMemberRole: string) => {
				setState(ctx => ({ ...ctx, editMemberRole }));
			},
			setToastMessage: (message: string) => {
				setState(ctx => ({ ...ctx, toastMessage: message }));
			},
			setPage: async (page: number) => {
				setState(ctx => ({ ...ctx, page }));
			},
			setTeamMemberShowPerPage: async (teamMemberShowPerPage: number) => {
				setState(ctx => ({ ...ctx, teamMemberShowPerPage }));
			},
			setIsLoading: (isLoading: boolean) => setState(ctx => ({ ...ctx, isLoading }))
		}),
		[teamService, setState, showMessage, store.teamMemberShowPerPage]
	);

	const getData = useCallback(() => {
		return store;
	}, [store]);

	return { ...methods, getData };
};

export default useTeam;
