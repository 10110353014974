import React from "react";

import * as R from "ramda";

import { PostBlockEventType } from "types";

import { ToolbarActions } from "shared/types";

import EventBlock from "./EventBlock";
import GifBlock from "./GifBlock";
import GroupBlock from "./GroupBlock";
import ImageVideoBlock from "./ImageVideoBlock/ImageVideoBlock";
import LinkAnchorBlock from "./LinkAnchorBlock";
import PollBlock from "./PollBlock";
import RequestMoneyBlock from "./RequestMoneyBlock";
import TrackBlock from "./TrackBlock";
import YouTubeBlock from "./YouTubeBLock";

const getTemplate = (action: ToolbarActions | null, content) => {
	const correctContent = action === ToolbarActions.ADD_IMAGE ? content : R.omit(["editorRef"], content);

	switch (action) {
		case ToolbarActions.ADD_IMAGE: {
			return [ImageVideoBlock];
		}
		case ToolbarActions.ADD_YOUTUBE: {
			return [YouTubeBlock];
		}
		case ToolbarActions.SELECT_TRACK:
		case ToolbarActions.ADD_TRACK: {
			return [TrackBlock, { track: correctContent }];
		}
		case ToolbarActions.ADD_GIF: {
			return [GifBlock];
		}
		case ToolbarActions.ADD_POLL: {
			return [PollBlock, { poll: correctContent }];
		}
		case ToolbarActions.SELECT_EVENT:
		case ToolbarActions.ADD_EVENT: {
			return [EventBlock, { event: correctContent }];
		}
		case ToolbarActions.SELECT_GROUP:
		case ToolbarActions.ADD_GROUP: {
			return [GroupBlock, { group: correctContent }];
		}
		case ToolbarActions.ADD_REQUEST_MONEY: {
			return [RequestMoneyBlock, { payReq: correctContent }];
		}
		case ToolbarActions.ADD_LINK: {
			return [LinkAnchorBlock, { url: correctContent?.link }];
		}
	}
};

export default (
	action: ToolbarActions | null,
	content: any,
	id: string,
	onEvent?: (eventType: PostBlockEventType, id: string, args?: any) => void
) => {
	if (action === null) return null;

	const [Template, data]: any = getTemplate(action, content);

	return (
		<Template
			{...(data ? data : content)}
			id={id}
			onEvent={onEvent}
			timeStamp={new Date().toISOString().replace(/[^\d]/g, "")}
		/>
	);
};
