import config from "config/appConfig";

import { EnvApplicationNames } from "types";

import { appTheme } from "shared/ui-kit";

export const customBreakpoints = {
	tabletHorizontalMax: 1367,
	tabletVerticalMax: 1025,
	laptopLgScreen: 1440,
	surfaceDuo: 540,
	iPadPro: 1024
};

const theme = appTheme;

theme.typography.fontFamily = ["OpenSans"].join(",");

theme.typography.h2 = {
	...theme.typography.h2,
	[theme.breakpoints.down("xs")]: {
		fontSize: "26px"
	}
};

theme.typography.h6 = {
	...theme.typography.h6,
	[theme.breakpoints.down(customBreakpoints.tabletHorizontalMax)]: {
		fontSize: "14px"
	}
};

theme.typography.body1 = {
	...theme.typography.body1,
	[theme.breakpoints.down("sm")]: {
		lineHeight: 1.6
	}
};

theme.typography.body2 = {
	...theme.typography.body2,
	[theme.breakpoints.down("xs")]: {
		fontSize: "13px"
	}
};

theme.overrides = {
	...theme.overrides,
	MuiInputLabel: {
		outlined: {
			[theme.breakpoints.down("xs")]: {
				transform: "translate(14px, 11px) scale(1)"
			}
		}
	}
};

theme.palette = {
	...theme.palette,
	mainButtonColors: {
		...theme.palette.mainButtonColors,
		primary:
			config.GLOBAL_CONSTANTS.ENV_APPLICATION_NAME === EnvApplicationNames.Akina
				? {
						...theme.palette.mainButtonColors.primary,
						backgroundColor: "#a2007f",
						backgroundColorOnHover: "#c4029a",
						backgroundColorOnPressed: "#8f006f"
				  }
				: config.GLOBAL_CONSTANTS.ENV_APPLICATION_NAME === EnvApplicationNames.Bitcoin
				? {
						...theme.palette.mainButtonColors.primary,
						backgroundColor: "#ff9400",
						backgroundColorOnHover: "#ffc023",
						backgroundColorOnPressed: "#ff9400"
				  }
				: theme.palette.mainButtonColors.primary
	}
};

export default theme;
