import React from "react";

import styled, { css } from "styled-components";

import { Box } from "shared/ui-kit";

interface FrameButtonProps {
	color?: string;
	customStyle?: string;
}

const FrameButtonWrapper = styled(Box)<FrameButtonProps>`
	display: block;

	width: 115px;
	max-width: 48%;
	height: 30px;

	border-radius: 15px;
	background-color: ${({ color }) => color || "#e4e9f2"};

	${({ customStyle }) => {
		return customStyle
			? css`
					${customStyle}
			  `
			: "";
	}}
`;

const FrameButton: React.FC<FrameButtonProps> = (props: FrameButtonProps): JSX.Element => {
	return <FrameButtonWrapper {...props} />;
};

export default FrameButton;
