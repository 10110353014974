import config from "config/appConfig";

import { IStateProps } from "utils/StateOf";

import createStore from "utils/createStore";

import { LiveConversationContextValues } from "../../types";

const localStorageKey = `${config.GLOBAL_CONSTANTS.LOCAL_STORAGE_PREFIX}${config.HOOK_STORAGE_KEYS.LIVE_CONVERSATION_DATA}`;

export const initialState: IStateProps<LiveConversationContextValues> = {
	roomId: "",
	permissionGranted: false,
	isVideoOn: true,
	isMicOn: true,
	shouldStart: false,
	firebaseParticipants: [],
	config: {
		enableComments: true,
		enableHandRaise: true,
		enableInvites: true,
		startedAt: ""
	},
	controls: {},
	raisedHands: {},
	totalComments: 0,
	isTiles: true,
	audioLevels: [],
	convoEndsInSecs: null,
	showExtend: false,
	convoExtended: false,
	comments: [],
	ongoingLiveConvos: [],
	ongoingLiveConvosLoading: true
};

export default createStore<LiveConversationContextValues>({
	initialState,
	persistant: true,
	persistantName: localStorageKey
});
