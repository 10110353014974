import { useMemo } from "react";

import { Connection } from "types/StoriesContextValuesType";

import { API_URLS } from "shared/constants";
import { Suggestion, SuggestionTypes } from "shared/contexts/UserContext/UserContext";
import useHttpActions from "shared/services/api/core/useHttpActions";

import { BaseConverter, convertGifs, convertWeblinkPreview } from "shared/services/converters";

import { IWorkspace, WeblinkPreview } from "shared/types";

import {
	BlockedUser,
	BlockedUserResponse,
	ChangePasswordRequest,
	MutualConnection,
	ProfileType,
	ReportBugRequest,
	UserReaction
} from "./../types/UserModel";

function mapBlockedUsers(blockedUsers: BlockedUserResponse[]): BlockedUser[] {
	return blockedUsers.map(blockedUser => {
		const jobTitle = blockedUser.intro.job[0] ? blockedUser.intro.job[0].titleLabel : "";
		return {
			name: blockedUser.name,
			personaId: blockedUser.personaId,
			profilePhoto: blockedUser.profilePhoto,
			jobTitle
		};
	});
}

const useUserService = (isMarketing = false) => {
	const params = useMemo(() => (isMarketing ? { customErrorHandlers: [] } : undefined), [isMarketing]);

	const actions = useHttpActions(params);

	return useMemo(
		() => ({
			getGifs: async ({ keyword, offset, limit }: { keyword: string; offset: number; limit: number }) => {
				return convertGifs(
					await actions.post(API_URLS.USER.GIFS, {
						path: `${keyword ? "search" : "trending"}?q=${keyword}&limit=${limit}&offset=${offset}&rating=g&lang=en`
					})
				);
			},
			updateTourStatus: async (type: "admin" | "member", value: boolean, globalUserId: string) => {
				return BaseConverter.convert<{ success: boolean }>(
					await actions.put(API_URLS.USER.PASSED_GUIDED_TOUR(globalUserId), {
						type,
						value
					})
				);
			},
			getUserWorkspaces: async (email: string): Promise<IWorkspace[]> => {
				return BaseConverter.convert<IWorkspace[]>(await actions.post(API_URLS.USER.WORKSPACES, { email }));
			},
			getTopConnections: async () => {
				return BaseConverter.convert<{ totalCount: number; connections: Connection[] }>(
					await actions.get(API_URLS.CONNECTION.TOP, { limit: 3 })
				);
			},
			getUsersFromPersonaIds: async (viewerPersonaId: number, personaIds: number[]) => {
				return BaseConverter.convert<{ personas: ProfileType[] }>(
					await actions.post(API_URLS.COMMUNITY.GET_USERS_FROM_PERSONAS, {
						viewerPersonaId,
						personaIds,
						purpose: "business"
					})
				);
			},
			searchSuggestions: async (type: SuggestionTypes, keyword: string) => {
				return BaseConverter.convert<{ success: boolean; suggestions: Suggestion[] }>(
					await actions.get(API_URLS.COMMUNITY.GET_JOB_SUGGESTIONS(type, keyword))
				);
			},
			createNewSuggestion: async (type: SuggestionTypes, label: string) => {
				return BaseConverter.convert<{ label: string; type: SuggestionTypes; _id: string }>(
					await actions.post(API_URLS.COMMUNITY.NEW_SUGGESTION, { label, type })
				);
			},
			listSuggestions: async (type: "skill" | "language", limit: number, offset: number) => {
				return BaseConverter.convert<{ suggestions: Suggestion[] }>(
					await actions.get(API_URLS.COMMUNITY.GET_SKILLS_SUGGESTIONS(type, limit, offset))
				);
			},
			fetchGoals: async () => {
				return BaseConverter.convert<{ success: true; goals: { category: string; ids: Suggestion[] }[] }>(
					await actions.get(API_URLS.USER.GOALS)
				);
			},
			getMutualConnections: async (
				viewerPersonaId: number,
				displayedPersonaId: number,
				limit: number,
				offset: number
			) => {
				return BaseConverter.convert<{ connections: MutualConnection[]; total: number }>(
					await actions.post(API_URLS.USER.CONNECTIONS, {
						responseType: "detail",
						type: "mutualConnections",
						lat: 0,
						lon: 0,
						viewerPersonaId,
						displayedPersonaId,
						limit,
						offset
					})
				);
			},
			fetchInterests: async () => {
				return BaseConverter.convert<{ success: true; interests: { category: string; ids: Suggestion[] }[] }>(
					await actions.get(API_URLS.USER.INTERESTS)
				);
			},
			updateUserChatId: async (personaId: string, chatUserId: string): Promise<IWorkspace[]> => {
				return BaseConverter.convert<IWorkspace[]>(
					await actions.put(API_URLS.USER.UPDATE_CHAT_ID, { personaId, chatUserId })
				);
			},
			getUserFirebaseToken: async (personaId: number): Promise<{ signature: string }> => {
				return BaseConverter.convert<{ signature: string }>(
					await actions.get(API_URLS.USER.FIREBASE_TOKEN, { personaId })
				);
			},
			getCommunityAppLink: async (): Promise<string> => {
				return BaseConverter.convert<string>(await actions.get(API_URLS.USER.COMMUNITY_APP_LINK, {}));
			},
			getWeblinkPreview: async (url: string): Promise<WeblinkPreview> => {
				try {
					return convertWeblinkPreview(await actions.post(API_URLS.USER.PREVIEW_LINK, { url }));
				} catch (e) {
					console.log(`ERROR: ${(e as Error).message}`);
					return {
						url,
						title: "",
						description: "",
						favicons: []
					};
				}
			},
			blockUserByEntity: async ({
				personaId,
				entity,
				block = true,
				id
			}: {
				personaId: number;
				entity: "event";
				block?: boolean;
				id: string;
			}) => {
				return BaseConverter.convert<{ success: boolean }>(
					await actions.put(API_URLS.USER.BLOCK_USER, {
						personaId,
						entity,
						block,
						id
					})
				);
			},
			getBlockedUsers: async (userId: string) => {
				const res = BaseConverter.convert<BlockedUserResponse[]>(
					await actions.get(API_URLS.USER.BLOCKED_USERS, { userId })
				);
				return mapBlockedUsers(res);
			},
			blockUser: async (personaId: number) => {
				return BaseConverter.convert<{ blockedUsers: BlockedUser[] }>(
					await actions.post(API_URLS.USER.BLOCK_PERSONA, { blockeeId: personaId })
				);
			},
			unblockUser: async (personaId: number) => {
				return await actions.post(API_URLS.USER.UNBLOCK_USER, { personaId });
			},
			bulkNofifyUsers: async ({ personaIds, notification }: { personaIds: number[]; notification: any }) => {
				return BaseConverter.convert<{ success: boolean }>(
					await actions.post(API_URLS.USER.BULK_NOTIFY_USERS, {
						personaIds,
						notification
					})
				);
			},
			getUserInfo: async (displayedPersonaId: number, viewerPersonaId: number) => {
				return BaseConverter.convert<{ persona: ProfileType }>(
					await actions.post(API_URLS.USER.PROFILE_DETAIL, {
						displayedPersonaId,
						viewerPersonaId
					})
				);
			},
			reportBug: async (reqParams: ReportBugRequest) => {
				return BaseConverter.convert<{ success: boolean }>(await actions.post(API_URLS.USER.REPORT_BUG, reqParams));
			},
			changePassword: (req: ChangePasswordRequest) => {
				const { globalUserId, ...request } = req;
				return actions.put(`${API_URLS.USER.CHANGE_PASSWORD}/${globalUserId}`, request);
			},
			clearCounter: (counter: string) => {
				return actions.put(API_URLS.USER.CLEAR_COUNTER, {
					counter
				});
			},
			activateAccount: async (action: "activate" | "deactivate") => {
				return BaseConverter.convert<{ success: boolean }>(
					await actions.put(API_URLS.USER.ACTIVATE, {
						action
					})
				);
			},
			deleteAccount: async (userId: string) => {
				return BaseConverter.convert<{ success: boolean }>(
					await actions.delete(API_URLS.USER.DELETE, {
						userId,
						action: "delete"
					})
				);
			},
			deleteGlobalAccount: async () => {
				return BaseConverter.convert<{ success: boolean }>(await actions.delete(API_URLS.USER.DELETE_GLOBAL));
			},
			getUserReaction: async (pid: string, limit: number, offset: number) => {
				return BaseConverter.convert<{ code: string; data: { totalReactions: number; reactions: UserReaction[] } }>(
					await actions.post(API_URLS.TOPIC.GET_TOPIC, {
						payload: {
							offset,
							limit,
							postId: pid,
							reaction: "all"
						},
						path: "/v2/react",
						method: "GET",
						token: null
					})
				);
			}
		}),
		[actions]
	);
};

export default useUserService;
