import styled from "styled-components";

import { Box } from "shared/ui-kit";

export const AddPostBtnWrapper = styled(Box)<{ bgColor?: string }>`
	width: 64px;
	height: 64px;

	border-radius: 50%;

	overflow: hidden;
	padding: 12px;

	box-shadow: 0 2px 4px 0 rgba(4, 16, 108, 0.16);

	background-color: ${({ bgColor }) => bgColor || "#6173fe"};

	position: fixed;
	bottom: 48px;
	right: 40px;

	display: flex;
	justify-content: center;
	align-items: center;

	cursor: pointer;

	z-index: 5;

	${props => props.theme.breakpoints.down("sm")} {
		width: 56px;
		height: 56px;

		bottom: 12px;
		right: 12px;
	}

	&.track-player-open {
		bottom: 90px;

		${props => props.theme.breakpoints.down("sm")} {
			bottom: 107px;
		}
	}

	svg {
		path {
			fill: white;
		}
	}
`;
