import React, { FC, useCallback } from "react";

import { OutlinedTextFieldProps } from "@material-ui/core";

import clsx from "clsx";

import { SizesEnum } from "shared/types";
import { Loader } from "shared/ui-kit";
import { numberWithCommas } from "utils/serviceUtils/helpers";

import { Counter, InfoText, UIKitInput } from "./style";

import { BaseInputProps } from "./index";

export interface InputProps extends BaseInputProps {
	onChange: OutlinedTextFieldProps["onChange"];
	info?: string;
	tabIndex?: number;
	hideBorders?: boolean;
	minRows?: number;
	// Number of rows accepted
	multipleRows?: number;
	hintError?: boolean;

	isBackgroundGray?: boolean;
	narrow?: boolean;

	showCounter?: number;
	readOnly?: boolean;

	setIsOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

const InputBase: FC<InputProps> = ({
	RightSideIcon,
	LeftSideIcon,
	loading,
	multipleRows,
	disabled,
	onFocus,
	onBlur,
	info,
	tabIndex,
	error,
	errorText,
	className,
	size = SizesEnum.MD,
	hideBorders,
	minRows,
	showCounter,
	value,
	readOnly,
	isBackgroundGray,
	narrow,
	...props
}) => {
	const handleBlur = useCallback(() => {
		onBlur && onBlur();
	}, [onBlur]);

	return (
		<div className="relative">
			<UIKitInput
				minRows={minRows}
				multiline={!!multipleRows}
				maxRows={multipleRows}
				variant="outlined"
				InputProps={{
					startAdornment: LeftSideIcon && <div className="mr-1">{LeftSideIcon}</div>,
					endAdornment: loading ? (
						<Loader size="16px" show variant="indeterminate" />
					) : (
						RightSideIcon && <div className="ml-1">{RightSideIcon}</div>
					)
				}}
				disabled={disabled}
				onBlur={handleBlur}
				onFocus={onFocus}
				inputProps={{ tabIndex: tabIndex || 0, readOnly }}
				error={error}
				helperText={!!error && errorText ? errorText : undefined}
				className={clsx(
					`${className}`,
					!multipleRows && `size_${size}`,
					hideBorders && "hideBorders",
					isBackgroundGray && "gray-bg",
					narrow && "narrow",
					readOnly && "cursor-pointer"
				)}
				value={value}
				{...props}
			/>
			{info && <InfoText className="absolute bottom-0 right-4 h-full flex items-center">{info}</InfoText>}
			{showCounter && (
				<Counter>
					{value ? value.length : 0}/{numberWithCommas(showCounter)}
				</Counter>
			)}
		</div>
	);
};

export default InputBase;
