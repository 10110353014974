import styled from "styled-components";

import { Box } from "shared/ui-kit";

export const SliderWrapper = styled(Box)`
	.slick-slide {
		> div {
			display: flex;
			flex-direction: column;
			> div {
				margin: 8px 0;
				width: 100% !important;
			}
		}
	}
`;

export const ItemWrapper = styled(Box)`
	margin: 12px 6px 12px 0;
`;
