import React, { Fragment, memo, useCallback, useEffect, useMemo, useState } from "react";

import { useMediaQuery } from "@material-ui/core";

import { DateTime } from "luxon";

import { useHistory } from "react-router";

import { ForumPostModel, PostScheduleType } from "types";

import { ReactComponent as EveryoneAccessIcon } from "assets/icons/iconGlobe.svg";
import { ReactComponent as LockIcon } from "assets/icons/lock.svg";
import { MenuDots, PlaceholderImage, TableWrapper } from "shared/Components";
import { TableColumnLoaderType } from "shared/Components/NewTable/Components";
import { Cell } from "shared/Components/NewTable/style";
import { useCommunity, useDebounce, usePosts } from "shared/hooks";

import useNotification from "shared/hooks/useNotification";
import { PlaceholderImageType, PostType } from "shared/types";
import { Icon, Select } from "shared/ui-kit";
import * as appTheme from "theme/default";

import { actionMethod, actionTypes, getValidationMessage } from "utils/getValidationMessage";
import { extractContentFromHTML, formatCount } from "utils/serviceUtils/helpers";

import { AccessIconWrapper, SelectWrapper, StyledOptionText } from "./style";

import { ConfirmDelete, PageTemplate } from "../../Components";

const Table = React.lazy(() => import("shared/Components/NewTable"));

export interface ManagePostsProps {
	createPostRoute: string;
	editPostRoute: string;
	isSubpage?: boolean;
}

const ManagePosts: React.FC<ManagePostsProps> = memo(({ createPostRoute, editPostRoute, isSubpage }) => {
	const history = useHistory();

	const { showMessage } = useNotification();

	const { getData: getCommunityData } = useCommunity();
	const { workspace } = getCommunityData();

	const { getPosts, getPostCount, deletePost, getData: getPostsData } = usePosts();
	const { posts, postCount, loading } = getPostsData();

	const [filter, setFilter] = useState({
		keyword: "",
		postType: PostScheduleType.Posted,
		limit: 10,
		offset: 1
	});

	const [searchText, setSearchText] = useState<string>("");
	const [open, setOpen] = useState(false);
	const [postIdToDelete, setPostIdToDelete] = useState<null | string>(null);

	const debouncedSearchText = useDebounce(searchText, 400);

	const isMobile = useMediaQuery(appTheme.default.breakpoints.down("sm"));

	useEffect(() => {
		setFilter(f => ({ ...f, keyword: debouncedSearchText, offset: 1 }));
	}, [debouncedSearchText]);

	useEffect(() => {
		getPosts(filter);
	}, [getPosts, workspace, filter]);

	useEffect(() => {
		getPostCount({ keyword: filter.keyword, postType: filter.postType });
	}, [getPostCount, filter.postType, filter.keyword, workspace]);

	const handleSearch = useCallback((val: string) => {
		if (val === "" || val.length >= 3) {
			setSearchText(val);
		}
	}, []);

	const getOptions = useCallback(
		(rowData: ForumPostModel) => [
			{
				name: "Edit",
				onClick: () => {
					history.push(`${editPostRoute}/${rowData.pid}`);
				},
				value: 1,
				comp: ""
			},
			{
				name: "Delete",
				onClick: () => {
					setPostIdToDelete(rowData.pid || null);
					setOpen(true);
				},
				value: 2,
				comp: ""
			}
		],
		[history, editPostRoute]
	);

	const tableColumns = useMemo(
		() => [
			{
				alignment: "left",
				width: 250,
				label: <Cell.HeaderText>{formatCount(postCount, "Post")}</Cell.HeaderText>,
				Cell: ({ rowData: { content, user, uid } }: { rowData: ForumPostModel }) => {
					const flatContent = extractContentFromHTML(content);
					const el = document.createElement("div");
					el.innerHTML = content;
					const img = el.querySelector("img");
					return (
						<Cell.Wrapper>
							<Cell.ImageWrapper>
								{img?.src ? (
									<Cell.Image src={img.src} />
								) : (
									<PlaceholderImage
										type={PlaceholderImageType.POST_PREVIEW}
										width={40}
										height={40}
										viewBox={"0 0 400 400"}
									/>
								)}
							</Cell.ImageWrapper>
							<Cell.Wrapper className="column with-image">
								<Cell.Text>{flatContent.length > 1 ? flatContent.slice(0, 15) : uid}</Cell.Text>
								<Cell.Text className="light">by {`${user.persona.firstName} ${user.persona.lastName}`}</Cell.Text>
							</Cell.Wrapper>
						</Cell.Wrapper>
					);
				},
				loaderTemplate: TableColumnLoaderType.imageWthTwoTextRows,
				dataKey: "content"
			},
			{
				alignment: "left",
				minWidth: isMobile ? 120 : undefined,
				label: <Cell.HeaderText>Posted Date</Cell.HeaderText>,
				Cell: ({ rowData: { timestamp } }: { rowData: ForumPostModel }) => {
					if (timestamp) {
						const createdAt = DateTime.fromMillis(Number(timestamp));
						return (
							<Cell.Wrapper className="column">
								<Cell.Text>{createdAt.toFormat("MMM d, yyyy")}</Cell.Text>
								<Cell.Text className="light">{createdAt.toFormat("h:m a")}</Cell.Text>
							</Cell.Wrapper>
						);
					}
					return "-";
				},
				loaderTemplate: TableColumnLoaderType.twoTextRows,
				dataKey: "date"
			},
			{
				minWidth: isMobile ? 110 : undefined,
				alignment: "left",
				label: <Cell.HeaderText>Reactions</Cell.HeaderText>,
				Cell: ({ rowData: { totalLikes } }: { rowData: ForumPostModel }) => (
					<Cell.Wrapper>
						<Cell.Text>{totalLikes}</Cell.Text>
					</Cell.Wrapper>
				),
				dataKey: "reactions"
			},
			{
				minWidth: isMobile ? 100 : undefined,
				alignment: "left",
				label: <Cell.HeaderText>Comments</Cell.HeaderText>,
				Cell: ({ rowData: { totalReplies } }: { rowData: ForumPostModel }) => (
					<Cell.Wrapper>
						<Cell.Text>{totalReplies}</Cell.Text>
					</Cell.Wrapper>
				),
				dataKey: "comments"
			},
			{
				minWidth: isMobile ? 160 : undefined,
				alignment: "left",
				label: <Cell.HeaderText>Category</Cell.HeaderText>,
				Cell: ({ rowData: { category } }: { rowData: ForumPostModel }) => (
					<Cell.Wrapper>
						<Cell.Text>{category.name}</Cell.Text>
					</Cell.Wrapper>
				),
				dataKey: "category"
			},
			{
				minWidth: isMobile ? 100 : undefined,
				alignment: "left",
				label: <Cell.HeaderText>Access</Cell.HeaderText>,
				Cell: ({ rowData: { postType } }: { rowData: ForumPostModel }) => (
					<Cell.Wrapper>
						<AccessIconWrapper>
							{postType === PostType.PREMIUM ? <LockIcon /> : <EveryoneAccessIcon />}
						</AccessIconWrapper>
						<Cell.Text>{postType === PostType.PREMIUM ? "Premium" : "Everyone"}</Cell.Text>
					</Cell.Wrapper>
				),
				dataKey: "access"
			},
			{
				alignment: "right",
				label: "",
				width: 100,
				Cell: ({ rowData }) => (
					<MenuDots options={getOptions(rowData)} vertical removeBg removeshadow removeSideMargin />
				),
				loaderTemplate: TableColumnLoaderType.menuDots,
				dataKey: "menu"
			}
		],
		[postCount, getOptions, isMobile]
	);

	const confirmPopupAction = useCallback(
		async (confirm: boolean) => {
			setOpen(false);
			if (confirm && postIdToDelete) {
				await deletePost({ id: postIdToDelete });
				showMessage(
					getValidationMessage({
						name: "Post",
						actionType: actionTypes.CRUD,
						actionMethod: actionMethod.deleted,
						emoji: "🗑"
					}),
					3
				);
				getPosts(filter);
			}
		},
		[deletePost, getPosts, postIdToDelete, showMessage, filter]
	);

	const postedOptions = useMemo(() => {
		return [
			{
				value: PostScheduleType.Posted,
				label: (
					<Fragment>
						<Icon fill="#c5cee0" group="" name="check-square" width={20} height={20} />{" "}
						<StyledOptionText>{PostScheduleType.Posted}</StyledOptionText>
					</Fragment>
				)
			},
			{
				value: PostScheduleType.Scheduled,
				label: (
					<Fragment>
						<Icon fill="#c5cee0" group="" name="calendar-day" width={19} height={22} />{" "}
						<StyledOptionText>{PostScheduleType.Scheduled}</StyledOptionText>
					</Fragment>
				)
			}
		];
	}, []);

	const handleChangePage = (newPage: number) => {
		setFilter(f => ({ ...f, offset: newPage }));
	};

	const handleChangeRowsPerPage = (newPageSize: number) => {
		setFilter(f => ({ ...f, offset: 1, limit: newPageSize }));
	};

	const handleChangePostType = option => {
		setFilter(f => ({ ...f, offset: 1, postType: option }));
	};

	const TableBlock = useMemo(
		() => (
			<TableWrapper sizes={{ horizontalTablet: 900 }}>
				<Table
					rowHeight={72}
					columns={tableColumns}
					data={posts || []}
					loading={loading}
					paginated
					totalDataCount={postCount || 10}
					page={filter.offset}
					pageSize={filter.limit}
					onChangePage={handleChangePage}
					onChangePageSize={handleChangeRowsPerPage}
				/>
			</TableWrapper>
		),
		[tableColumns, posts, postCount, loading, filter.offset, filter.limit]
	);

	return (
		<>
			<PageTemplate
				title={"Posts"}
				showReportedLink
				isLoading={loading}
				isNoData={!posts.length}
				emptyText={"You don’t have any Posts in your community yet."}
				searchPlaceholder={"Search Posts"}
				onSearchUpdate={handleSearch}
				actionText={"Create Post"}
				onCreateClick={() => history.push(createPostRoute)}
				extraAction={
					<SelectWrapper>
						<Select options={postedOptions} value={filter.postType} onChange={handleChangePostType} />
					</SelectWrapper>
				}
				isSubpage={isSubpage}
			>
				{TableBlock}
			</PageTemplate>
			{open && (
				<ConfirmDelete
					headline="Post"
					onClose={() => confirmPopupAction(false)}
					onDelete={() => confirmPopupAction(true)}
				/>
			)}
		</>
	);
});

export default ManagePosts;
