import React, { useMemo } from "react";

import { EmailsSuggestionsWrapper } from "./style";

import { useTeam } from "../../../../Data";
import { StyledAutoComplete, StyledChip, StyledLoader, StyledTextField } from "../style";

export const EmailsAutoComplete = () => {
	const { setEmailAddresses, validatedInvitedTeamMember, setIsLoading, getData: getTeamData } = useTeam();
	const { isLoading, emailAddresses } = getTeamData();

	const splitEmails = value => {
		const emails = value.split(/[ ,;]+/).filter(email => email);

		setEmailAddresses({
			emailAddresses: [...emailAddresses, ...emails.map(email => ({ email: email, valid: true }))]
		});
		validatedInvitedTeamMember(emails).then(res => {
			if (!res) {
				return;
			}
			const newEmailAddress = [...emailAddresses, ...res].map(item => {
				return item?.message === "Invalid Email" ? { ...item, message: "Invalid email address" } : item;
			});
			setEmailAddresses({ emailAddresses: newEmailAddress });
		});
	};

	const onPasteHandler = async e => {
		e.stopPropagation();
		e.preventDefault();

		const clipboardData = e.clipboardData.getData("Text");

		splitEmails(clipboardData);
	};

	const onKeyDownHandler = async e => {
		switch (e.key) {
			case ",":
			case ";":
			case " ":
			case "Enter":
				if (e.target.value) {
					splitEmails(e.target.value);
				}
				break;
			default:
				return;
		}
		setIsLoading(false);
	};

	const onBlurHandler = async e => {
		if (e.target.value) {
			splitEmails(e.target.value);
		}
	};

	const validEmailsBoolArray = useMemo(() => {
		return emailAddresses?.map(email => email.valid);
	}, [emailAddresses]);

	if (isLoading) return <StyledLoader size="3rem" show={true} color="inherit" variant="indeterminate" />;

	return (
		<StyledAutoComplete
			multiple
			id="multiple-limit-tags"
			options={[]}
			onInputChange={() => {}}
			getOptionLabel={(option: any) => option}
			value={emailAddresses?.map(email => email.email)}
			isNotInvited={validEmailsBoolArray === undefined ? true : validEmailsBoolArray?.every(Boolean)}
			onChange={(_, value) => {
				if (value.length < emailAddresses.length) {
					const newEmailAddress = emailAddresses.filter(emailAddress => value.includes(emailAddress.email));
					setEmailAddresses({ emailAddresses: newEmailAddress });
				}
			}}
			renderTags={(_, getTagProps) =>
				emailAddresses?.map((option, index) => (
					<StyledChip key={index} {...getTagProps({ index })} label={option.email} isInvited={option.valid} />
				))
			}
			freeSolo
			renderInput={(params: any) => {
				params.inputProps.onKeyDown = onKeyDownHandler;
				params.inputProps.onPaste = onPasteHandler;
				params.inputProps.onBlur = onBlurHandler;

				return (
					<EmailsSuggestionsWrapper>
						<StyledTextField
							{...params}
							variant="outlined"
							label="Email address(es)"
							placeholder="Email address(es)"
							InputProps={{
								...params.InputProps,
								type: "email"
							}}
						/>
					</EmailsSuggestionsWrapper>
				);
			}}
		/>
	);
};
