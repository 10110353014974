import React, { FC, useCallback, useMemo, useRef, useState } from "react";

import { Box } from "@material-ui/core";
import { Controller, useForm } from "react-hook-form";

import { ReactComponent as DogIcon } from "assets/icons/animal-dog.svg";
import { ReactComponent as CameraFlipIcon } from "assets/icons/camera-flip.svg";
import { MenuDotsOption, SelectAnimalAvatarModal } from "shared/Components";
import { SuggestionTypes } from "shared/contexts/UserContext/UserContext";
import { useMedia, useNotification, usePersona, useUser } from "shared/hooks";
import { useS3Uploader } from "shared/services/s3Uploader";
import { MediaModel, MediaType, ProfilePhotoSizes } from "shared/types";
import { ProfileJobType } from "shared/types/UserModel";

import { Icon } from "shared/ui-kit";
import { getResizedImage } from "utils/serviceUtils/cdnImages";

import { EditProfileWrapper } from "./style";

import { CircularUploader, MemberDialog, SelectInput } from "../../shared";
import LabeledInput from "../../shared/LabeledInput";
import { InputWrapper } from "../../shared/style";

const EditProfileDialog: FC<{ isLiteProfile?: boolean }> = ({ isLiteProfile }) => {
	const { control, setValue, formState, handleSubmit } = useForm({ mode: "onChange" });

	const { isDirty, errors, isSubmitting } = formState;

	const { getData: getS3UploaderData } = useS3Uploader();
	const { uploading } = getS3UploaderData();

	const { uploadFiles } = useMedia();

	const { searchSuggestions, setUpdateUserDialog, activeProfileId, getData: getUserData } = useUser();
	const { companiesSuggestions, jobTitleSuggestions, industrySuggestions, updateUserDialog } = getUserData();

	const { updatePersona, getData: getPersonaData } = usePersona();
	const { persona: profile } = getPersonaData();

	const { showMessage } = useNotification();

	const { createMediaForUploaded } = useMedia();

	const [showAnimalAvatarModal, setShowAnimalAvatarModal] = useState(false);

	type CircularUploaderMethodsHandlers = React.ElementRef<typeof CircularUploader>;
	const circularUploaderRef = useRef<CircularUploaderMethodsHandlers>(null);

	const [creatingMedia, setCreatingMedia] = useState(false);

	const onSubmit = async data => {
		if (profile) {
			const newPersona = {
				...profile,
				photos: Array.isArray(data.photo) ? data.photo : [data.photo],
				firstName: data.firstName,
				lastName: data.lastName,
				intro: isLiteProfile
					? profile.intro
					: {
							...profile.intro,
							job: [
								{
									industry: {
										_id: data.industry.value,
										label: data.industry.label
									},
									company: {
										_id: data.company.value,
										label: data.company.label
									},
									jobTitle: {
										_id: data.job.value,
										label: data.job.label
									}
								}
							] as ProfileJobType[]
					  }
			};

			await updatePersona(newPersona, true);

			showMessage("Successfully updated your profile");
			setUpdateUserDialog(false);
		}
	};

	const uploadPhoto = useCallback(
		async (files: FileList, onChange?: (...event: any[]) => void) => {
			if (profile) {
				const photos = await uploadFiles({ files, type: MediaType.profilePhoto });
				onChange && onChange(photos);
				return photos;
			}
		},
		[profile, uploadFiles]
	);

	const defaultJob = useMemo(
		() =>
			!!profile?.intro?.job?.length
				? {
						value: profile.intro.job[0].jobTitle._id,
						label: profile.intro.job[0].jobTitle.label
				  }
				: undefined,
		[profile]
	);

	const defaultIndustry = useMemo(
		() =>
			!!profile?.intro?.job?.length
				? {
						value: profile.intro.job[0].industry._id,
						label: profile.intro.job[0].industry.label
				  }
				: undefined,
		[profile]
	);

	const getAvatarOptions = useCallback(
		(currentAvatar?: MediaModel) => {
			let optionList: MenuDotsOption[] = [];

			if (currentAvatar) {
				optionList = [
					{
						icon: <CameraFlipIcon />,
						name: "Change Photo",
						onClick: () => {
							circularUploaderRef?.current!.clearValue();
							circularUploaderRef?.current!.initiateSelect();
						}
					},
					{
						icon: <DogIcon />,
						name: "Select Avatar",
						onClick: () => setShowAnimalAvatarModal(true)
					},
					{
						icon: <Icon fill="#8f9bb3" name="trash-linear" />,
						name: "Delete Photo",
						onClick: () => setValue("photo", null)
					}
				];
			} else {
				optionList = [
					{
						icon: <CameraFlipIcon />,
						name: "Select Photo",
						onClick: () => circularUploaderRef?.current!.initiateSelect()
					},
					{
						icon: <DogIcon />,
						name: "Select Avatar",
						onClick: () => setShowAnimalAvatarModal(true)
					}
				];
			}
			return optionList;
		},
		[setValue]
	);

	const handleCloseAnimalAvatarModal = useCallback(
		async (imgUrl?: string) => {
			setShowAnimalAvatarModal(false);
			if (imgUrl) {
				setCreatingMedia(true);
				const profilePhotoMedia = await createMediaForUploaded({
					personaId: activeProfileId,
					type: MediaType.profilePhoto,
					fileUrl: imgUrl
				});
				setValue("photo", profilePhotoMedia, { shouldDirty: true });
				setCreatingMedia(false);
			}
		},
		[setValue, activeProfileId, createMediaForUploaded]
	);

	if (!profile) return null;

	return (
		<>
			<MemberDialog
				title="Edit intro"
				open={updateUserDialog}
				onClose={() => setUpdateUserDialog(false)}
				confirmLeave={isDirty}
				customWidth={512}
				footerPrimary={{
					text: "Save changes",
					disabled: !isDirty || uploading || creatingMedia || isSubmitting,
					loading: isSubmitting,
					onClick: handleSubmit(onSubmit)
				}}
			>
				<form onSubmit={handleSubmit(onSubmit)}>
					<EditProfileWrapper>
						{profile.photos.length > 0 && (
							<Controller
								name="photo"
								control={control}
								defaultValue={profile.photos[0]}
								rules={{
									required: "Photo is required"
								}}
								render={({ value, onChange }) => {
									const image = getResizedImage(
										((Array.isArray(value) ? value[0] : value) as MediaModel)?.profilePicture || "",
										ProfilePhotoSizes.size200x200
									);
									return (
										<InputWrapper mb={32}>
											<CircularUploader
												ref={circularUploaderRef}
												preview={image}
												onChange={files => uploadPhoto(files, onChange)}
												uploading={uploading}
												errorText={errors?.photo?.message}
												options={getAvatarOptions(value)}
											/>
										</InputWrapper>
									);
								}}
							/>
						)}
						<Box className="two-inputs-row">
							<Controller
								name="firstName"
								control={control}
								defaultValue={profile.firstName}
								rules={{
									required: "First Name is required"
								}}
								render={({ onChange, value, ref }) => (
									<InputWrapper width="48%">
										<LabeledInput
											name="firstName"
											onChange={onChange}
											value={value}
											placeholder=" "
											label="First Name *"
											error={errors?.firstName?.message}
											inputRef={ref}
										/>
									</InputWrapper>
								)}
							/>
							<Controller
								name="lastName"
								control={control}
								defaultValue={profile.lastName}
								rules={{
									required: "Last Name is required"
								}}
								render={({ onChange, value, ref }) => (
									<InputWrapper width="48%">
										<LabeledInput
											name="lastName"
											onChange={onChange}
											value={value}
											placeholder=" "
											label="Last Name *"
											error={errors?.lastName?.message}
											inputRef={ref}
										/>
									</InputWrapper>
								)}
							/>
						</Box>
						{!isLiteProfile && (
							<>
								<Controller
									name="job"
									control={control}
									defaultValue={defaultJob}
									rules={{
										required: "Job is required",
										validate: val => (!val.value || !val.label ? "Please choose a job option" : true)
									}}
									render={({ onChange, value, ref }) => (
										<InputWrapper>
											<SelectInput
												name="job"
												onChange={onChange}
												value={value && value.label}
												placeholder=" "
												label="Current job title *"
												loadSuggestions={val => searchSuggestions(SuggestionTypes.jobTitle, val)}
												maxHeight={260}
												error={errors?.job?.message}
												noOptionsHeadline="Type your job title ( ex: UX Designer )"
												allowCreatingSuggestion={SuggestionTypes.jobTitle}
												options={
													jobTitleSuggestions
														? jobTitleSuggestions.map(suggestion => ({
																value: suggestion._id,
																label: suggestion.label
														  }))
														: []
												}
												selectInputRef={ref}
											/>
										</InputWrapper>
									)}
								/>
								<Controller
									name="industry"
									control={control}
									defaultValue={defaultIndustry}
									rules={{
										required: "Industry is required",
										validate: val => (!val.value || !val.label ? "Please choose an industry option" : true)
									}}
									render={({ onChange, value, ref }) => (
										<InputWrapper>
											<SelectInput
												name="industry"
												onChange={onChange}
												value={value && value.label}
												placeholder=" "
												loadSuggestions={val => searchSuggestions(SuggestionTypes.industry, val)}
												label="Industry *"
												maxHeight={260}
												error={errors?.industry?.message}
												noOptionsHeadline="Type your industry ( ex: Media )"
												allowCreatingSuggestion={SuggestionTypes.industry}
												options={
													industrySuggestions
														? industrySuggestions.map(suggestion => ({
																value: suggestion._id,
																label: suggestion.label
														  }))
														: []
												}
												selectInputRef={ref}
											/>
										</InputWrapper>
									)}
								/>
								<Controller
									name="company"
									control={control}
									defaultValue={
										profile.intro?.job &&
										profile.intro?.job[0] && {
											value: profile.intro.job[0].company._id,
											label: profile.intro.job[0].company.label
										}
									}
									rules={{
										required: "Company is required",
										validate: val => (!val.value || !val.label ? "Please choose a company option" : true)
									}}
									render={({ onChange, value, ref }) => (
										<InputWrapper>
											<SelectInput
												name="company"
												onChange={onChange}
												placeholder=" "
												label="Company *"
												value={value && value.label}
												loadSuggestions={val => searchSuggestions(SuggestionTypes.company, val)}
												maxHeight={260}
												error={errors?.company?.message}
												noOptionsHeadline="Type your company ( ex: Google )"
												allowCreatingSuggestion={SuggestionTypes.company}
												options={
													companiesSuggestions
														? companiesSuggestions.map(suggestion => ({
																value: suggestion._id,
																label: suggestion.label
														  }))
														: []
												}
												selectInputRef={ref}
											/>
										</InputWrapper>
									)}
								/>
							</>
						)}
					</EditProfileWrapper>
				</form>
			</MemberDialog>
			{showAnimalAvatarModal && <SelectAnimalAvatarModal onClose={handleCloseAnimalAvatarModal} />}
		</>
	);
};

export default EditProfileDialog;
