import React, { useEffect, useState } from "react";

import { useHistory } from "react-router-dom";
import styled from "styled-components";

import { ReactComponent as HighGiveIcon } from "assets/icons/high-five-amico.svg";
import CommunityAbout from "assets/icons/illustration-group.svg";
import { sportsFeatureList } from "shared/constants";
import { useCommunity, useFeature, useGTM } from "shared/hooks";
import { CommunityType } from "shared/types";
import { Box, Button, FormFooter, Text } from "shared/ui-kit";
import { customBreakpoints } from "theme/default";

import { scrollTo } from "utils/scrollTo";

import { ContentWrapper, FormBlock, TypeCard } from "../../Components";

export interface GroupType {
	id: number;
	iconUrl: string;
	title: string;
	active?: boolean;
	description?: string;
	imageUrl?: string;
	isSportType?: boolean;
}

const ContentForm = styled.section`
	display: flex;
	justify-content: center;
	align-items: center;

	${props => props.theme.breakpoints.down("sm")} {
		flex-direction: column;
		width: 100%;
		justify-content: flex-start;
		align-items: center;
	}
`;

const AssetWrapper = styled(Box)`
	margin-left: 90px;
	width: 450px;

	display: flex;
	flex-direction: column;
	align-items: flex-start;

	${props => props.theme.breakpoints.down(customBreakpoints.tabletHorizontalMax)} {
		width: 400px;
		margin-left: 55px;
	}

	${props => props.theme.breakpoints.down(customBreakpoints.tabletVerticalMax)} {
		width: 280px;

		margin-left: 50px;
	}

	${props => props.theme.breakpoints.down("sm")} {
		width: 100%;
		max-width: 350px;

		margin-left: 0;
	}
`;

const TypeListWrapper = styled.section`
	margin-top: 28px;
`;

const TypeCardWrapper = styled(Box)`
	display: inline-block;
	margin: 0 7px 6px 0;

	width: 48%;

	cursor: pointer;

	${props => props.theme.breakpoints.down("sm")} {
		width: 47%;
	}
`;

const GroupIconWrapper = styled.img`
	width: 100%;

	${props => props.theme.breakpoints.down("sm")} {
		order: 2;

		margin-top: 36px;
	}
`;

const Title = styled(Text)`
	margin-top: 53px;

	${props => props.theme.breakpoints.down(customBreakpoints.tabletHorizontalMax)} {
		font-size: 18px;
		margin-top: 20px;
	}

	${props => props.theme.breakpoints.down(customBreakpoints.tabletVerticalMax)} {
		margin-top: 42px;
	}

	${props => props.theme.breakpoints.down("sm")} {
		order: 0;
		margin-top: 21px;
	}
`;

const Description = styled(Text)`
	margin-top: 9px;
	line-height: 1.33;

	${props => props.theme.breakpoints.down("sm")} {
		order: 1;
	}
`;

export interface AboutProps {
	featurePageUrl: string;
	themePageUrl: string;
}

const About: React.FC<AboutProps> = ({ featurePageUrl, themePageUrl }) => {
	const history = useHistory();

	const { setTypeList, getTypeList, getData: getCommunityData } = useCommunity();
	const { typeList, selectedTypes, isSportsMode } = getCommunityData();

	const { setSelectedFeatures } = useFeature();

	const { addEvent } = useGTM();

	const [groupTypeList, setGroupTypeList] = useState<GroupType[]>([]);

	const toggleSelectedType = (id: number) => {
		const updated = groupTypeList.map(type => {
			type.active = type.id === id;
			return type;
		});
		setGroupTypeList([...updated]);
		setTypeList(
			groupTypeList
				.filter(x => x.active)
				.map(x => ({
					type: x.title,
					image: x.iconUrl,
					icon: x.imageUrl,
					description: x.description
				}))
		);
	};

	useEffect(() => {
		if (!typeList) {
			getTypeList("all");
		} else {
			const newGroupTypeList = typeList?.map((x, i) => ({
				id: i,
				title: x.type,
				iconUrl: x.image,
				imageUrl: x.icon,
				description: x.description,
				active: selectedTypes.findIndex(st => st.type === x.type) > -1,
				isSportType: x.isSportType
			}));
			setGroupTypeList(newGroupTypeList);
		}
	}, [typeList, getTypeList, selectedTypes]);

	useEffect(() => {
		if (isSportsMode) {
			setSelectedFeatures(sportsFeatureList.filter(x => x.isEnabled).map(x => x.name));
		}
	}, [isSportsMode, setSelectedFeatures]);

	const getSelectedType = React.useMemo(
		() => (): CommunityType => {
			return selectedTypes.length
				? selectedTypes[0]
				: {
						type: "",
						description: "",
						image: "",
						icon: ""
				  };
		},
		[selectedTypes]
	);

	useEffect(() => {
		addEvent({ Action: "Create Community", Label: "Step-5" });
		scrollTo(0, 0);
	}, [addEvent]);

	return (
		<ContentWrapper customStyle={"align-items: center;"}>
			<ContentForm>
				<FormBlock customStyle={"margin: 0; padding: 40px 70px 64px 80px; max-width: 100%;"}>
					<Text variant="h2">{isSportsMode ? "Select Sport" : "What best describes you?"}</Text>
					<Text variant="body1" className={"mt-4"}>
						Select one of the option below that describes you best, this will allow us to recommend features which are
						tailor fit for your community.
					</Text>
					<TypeListWrapper>
						{groupTypeList
							.filter(x => x?.isSportType === isSportsMode)
							.map(({ iconUrl, title, active, id }) => (
								<TypeCardWrapper key={id} onClick={() => toggleSelectedType(id)}>
									<TypeCard iconUrl={iconUrl} title={title} active={active} />
								</TypeCardWrapper>
							))}
					</TypeListWrapper>
					<FormFooter>
						<Button
							removeSideMargin
							fullWidth
							customStyle={() => "margin-top: 16px;"}
							disabled={groupTypeList.findIndex(x => x.active) === -1}
							size="large"
							onClick={() => history.push(isSportsMode ? themePageUrl : featurePageUrl)}
						>
							Continue
						</Button>
					</FormFooter>
				</FormBlock>
				<AssetWrapper>
					{isSportsMode ? (
						<HighGiveIcon />
					) : (
						<>
							<GroupIconWrapper src={getSelectedType()?.icon || CommunityAbout} />
							<Title variant="h6">{getSelectedType()?.type}</Title>
							<Description variant="body1">{getSelectedType()?.description}</Description>
						</>
					)}
				</AssetWrapper>
			</ContentForm>
		</ContentWrapper>
	);
};

export default About;
