import React, { FC } from "react";

import { SectionHeader } from "modules/MemberHome/View/shared/style";
import { Button, Icon } from "shared/ui-kit";

import { Wrapper } from "./style";

interface Props {
	title: string;
	editable?: {
		onClick: () => void;
		icon: string;
	};
	className: string;
	seeAll?: () => void;
}

const InfoBlock: FC<Props> = ({ editable, title, className, seeAll, children }) => (
	<Wrapper className={className}>
		{editable && (
			<Wrapper.EditButton onClick={editable.onClick}>
				<Icon name={editable.icon} group="filled" fill="#8f9bb3" />
			</Wrapper.EditButton>
		)}
		<SectionHeader variant="h4" className="mb-4 ml-2">
			{title}
		</SectionHeader>
		{children}
		{seeAll && (
			<Button className="see-all" palette="basic" buttonTheme="light" onClick={seeAll}>
				See All
			</Button>
		)}
	</Wrapper>
);

export default InfoBlock;
