import React, { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from "react";

import { ReactComponent as LiveTextIcon } from "assets/icons/liveconvo/live-text.svg";
import { ReactComponent as IconPause } from "assets/icons/pause-track.svg";
import { ReactComponent as PlayCircleIcon } from "assets/icons/play-circle-icon.svg";

import { SkeletonBase } from "shared/Components";
import { useCommunity, usePlayer } from "shared/hooks";
import { Loader } from "shared/ui-kit";
import { validateYoutube } from "utils/serviceUtils/validators";
import { shorten } from "utils/shortenText";
import { removeTags } from "utils/stripHTMLTags";

import { Item, ModalLink } from "./style";

import { PremiumContentIndicator } from "../../shared";

interface itemModel {
	_id: string;
	wrapByHref?: boolean;
	href?: string;
	img?: string;
	placeholderImg?: ReactNode;
	logo?: ReactNode;
	beforeTitle?: string | ReactNode;
	dontStyleBeforeTitle?: boolean;
	title: string;
	subtitle?: string | ReactNode;
	dontStyleSubtitle?: boolean;
	noSubtitleDots?: boolean;
	underTitle?: string;
	peopleList?: string[];
	distance?: number;
	action?: ReactNode;
	largeThumbnail?: boolean;
	extraAction?: ReactNode;
	minimalistic?: boolean;
	highlightOnHover?: boolean;
	videoPreview?: string;
	duration?: string;
	onPlay?: () => void;
	summary?: string;
	locked?: boolean;
	peopleListText?: string;
	showLiveBadge?: boolean;
	isWrapBorder?: boolean;
	chatThumbnail?: boolean;
	chatCurrentUser?: boolean;
	moreAttendees?: number;
}

interface Props {
	item: itemModel;
	autoHeight?: boolean;
	maxHeight?: number;
	noSidePadding?: boolean;
	noPB?: boolean;
	loading?: boolean;
}

const ModelItem: React.FC<Props> = ({ item, noSidePadding, maxHeight, autoHeight, noPB, loading }) => {
	const [playVideo, setPlayVideo] = useState(false);
	const [itemHovered, setItemHovered] = useState(false);
	const [isVideoReady, setIsVideoReady] = useState<boolean>();

	const { setPlay, getData: getPlayerData } = usePlayer();
	const { playTracks, play } = getPlayerData();

	const vidRef = useRef<HTMLVideoElement | null>(null);
	const isYTVid = useMemo(() => validateYoutube(item.videoPreview), [item.videoPreview]);

	const { communityColors } = useCommunity();

	useEffect(() => {
		if (playVideo) {
			vidRef.current?.play();
		} else {
			vidRef.current?.pause();
		}
	}, [playVideo]);

	const isPlaying = useMemo(
		() =>
			!!playTracks.tracks?.items?.length
				? playTracks.tracks.items[playTracks.idx] && playTracks.tracks.items[playTracks.idx]._id === item._id
				: !!playTracks.series?.items?.length
				? playTracks.series.items[playTracks.idx] && playTracks.series.items[playTracks.idx]._id === item._id
				: false,
		[playTracks.tracks, playTracks.idx, playTracks.series, item._id]
	);

	const handlePauseTracks = useCallback(() => setPlay(!play), [play, setPlay]);
	const handleLoadedData = useCallback(() => setIsVideoReady(true), []);
	const handleLoadStart = useCallback(() => setIsVideoReady(false), []);

	const content = useMemo(() => {
		return loading ? (
			<Item.Wrapper
				autoHeight={autoHeight}
				maxHeight={maxHeight}
				className={`${item?.minimalistic ? "minimalistic" : ""} ${item.highlightOnHover ? "highlightOnHover" : ""}`}
			>
				<Item.IconWrapper>
					<SkeletonBase variant="rect" animation="wave" width={"100%"} height={"100%"} />
				</Item.IconWrapper>
				<Item.InfoBox noSidePadding={noSidePadding}>
					<SkeletonBase variant="text" animation="wave" width={50} height={30} />
					<SkeletonBase variant="text" animation="wave" width={120} height={50} />
					<Item.InfoRow>
						<Item.MemberListBox>
							<SkeletonBase variant="circle" animation="wave" width={30} height={30} />
							<SkeletonBase variant="circle" animation="wave" width={30} height={30} />
							<SkeletonBase variant="circle" animation="wave" width={30} height={30} />
						</Item.MemberListBox>
						<Item.DistanceBox>
							<SkeletonBase variant="text" animation="wave" width={50} height={30} />
							<SkeletonBase variant="circle" animation="wave" width={30} height={30} />
						</Item.DistanceBox>
					</Item.InfoRow>
				</Item.InfoBox>
			</Item.Wrapper>
		) : (
			<Item.Wrapper
				isBorder={item?.isWrapBorder}
				noPB={noPB}
				autoHeight={autoHeight}
				maxHeight={maxHeight}
				className={`${item?.minimalistic ? "minimalistic" : ""} ${item.highlightOnHover ? "highlightOnHover" : ""}`}
				chatCurrentUser={item.chatCurrentUser}
			>
				{isVideoReady === false && (
					<Item.LoaderWrapper>
						<Item.Loader>
							<Loader size="25px" show color="primary" variant="intermediate" />
						</Item.Loader>
					</Item.LoaderWrapper>
				)}
				{item.locked && <PremiumContentIndicator />}
				{item.duration && <Item.Duration>{item.duration}</Item.Duration>}
				{item.showLiveBadge && (
					<Item.LiveBadge>
						<LiveTextIcon />
					</Item.LiveBadge>
				)}

				{(item.logo || item.img) && !playVideo ? (
					<Item.IconWrapper
						className={`icon-wrapper ${item.largeThumbnail && "large-thumbnail"} ${
							item.chatThumbnail && "chat-thumbnail"
						}`}
						style={item?.img ? { backgroundImage: `url(${item.img})` } : {}}
						onMouseEnter={() =>
							item.videoPreview && !isYTVid ? setPlayVideo(true) : item.onPlay && setItemHovered(true)
						}
						onMouseLeave={() => (item.videoPreview ? setPlayVideo(false) : item.onPlay && setItemHovered(false))}
					>
						{item.logo && !item.img && <Item.ItemLogo>{item.logo}</Item.ItemLogo>}
						{item.onPlay && (
							<Item.PlayTrack
								bgColor={communityColors.btn}
								show={itemHovered || isPlaying}
								onClick={() => (isPlaying ? handlePauseTracks() : item.onPlay && item.onPlay())}
							>
								{isPlaying && play ? <IconPause fill="white" className="pause-fill" /> : <PlayCircleIcon />}
							</Item.PlayTrack>
						)}
					</Item.IconWrapper>
				) : item?.placeholderImg && !playVideo ? (
					<Item.IconWrapper
						className={`icon-wrapper ${item.largeThumbnail && "largeThumbnail"}`}
						onMouseEnter={() => item.videoPreview && setPlayVideo(true)}
						onMouseLeave={() => item.videoPreview && setPlayVideo(false)}
					>
						{item.placeholderImg}
					</Item.IconWrapper>
				) : (
					<Item.VideoWrapper onMouseEnter={() => setPlayVideo(true)} onMouseLeave={() => setPlayVideo(false)}>
						<video ref={vidRef} muted onLoadStart={handleLoadStart} onLoadedData={handleLoadedData}>
							<source src={item.videoPreview} />
						</video>
					</Item.VideoWrapper>
				)}
				<Item.InfoBox noSidePadding={noSidePadding}>
					{item?.beforeTitle && item.dontStyleBeforeTitle ? (
						item.beforeTitle
					) : (
						<Item.BeforeTitle className="before-title">{item.beforeTitle}</Item.BeforeTitle>
					)}
					{!item.wrapByHref && item.href ? (
						<ModalLink to={item.href} id={item.title}>
							<Item.Title>{item.title}</Item.Title>
						</ModalLink>
					) : (
						<Item.Title className="title" id={item.title}>
							{item.title}
						</Item.Title>
					)}
					{item?.subtitle && item.dontStyleSubtitle ? (
						item.subtitle
					) : (
						<Item.Subtitle className="sub-title">
							{item.subtitle && shorten(removeTags(item.subtitle), 90, " ")}
							{!item.noSubtitleDots && ".."}
						</Item.Subtitle>
					)}
					{item?.subtitle && <Item.UnderTitle>{item.underTitle}</Item.UnderTitle>}
					<Item.InfoRow>
						{item.summary && <Item.Summary>{item.summary}</Item.Summary>}
						{!!item?.peopleList?.length && (
							<Item.MemberListBox>
								{item!.peopleList!.slice(0, 3).map((member, i) => (
									<Item.MemberIcon key={i} bgUrl={member} />
								))}
								{!item.peopleListText && item!.peopleList!.length > 3 && (
									<Item.MemberText>+ {item!.peopleList!.length - 3} people going</Item.MemberText>
								)}
								{!!item.peopleListText && (
									<Item.MemberText className="peopleListText">{item.peopleListText}</Item.MemberText>
								)}
								{item.moreAttendees && item.moreAttendees > 0 ? (
									<Item.PeopleGoing>+{item.moreAttendees} people going</Item.PeopleGoing>
								) : null}
							</Item.MemberListBox>
						)}
						{item?.action}
						{!!item?.distance && (
							<Item.DistanceBox>
								<Item.DistanceText>{item.distance} mi</Item.DistanceText>
								<Item.DistanceIcon />
							</Item.DistanceBox>
						)}
					</Item.InfoRow>
					{item?.extraAction && (
						<Item.ExtraActionWrapper className={"extra-action-block"}>{item.extraAction}</Item.ExtraActionWrapper>
					)}
				</Item.InfoBox>
			</Item.Wrapper>
		);
	}, [
		autoHeight,
		handlePauseTracks,
		isPlaying,
		isYTVid,
		item,
		itemHovered,
		maxHeight,
		noPB,
		noSidePadding,
		play,
		playVideo,
		loading,
		isVideoReady,
		handleLoadedData,
		handleLoadStart,
		communityColors.btn
	]);

	if (item.wrapByHref)
		return (
			<ModalLink noHover to={item.href}>
				{content}
			</ModalLink>
		);

	return content;
};

export default ModelItem;
