import React, { FC, useCallback, useEffect, useState } from "react";

import { ForumPersonaModel } from "types";

import { ReactComponent as CatIcon } from "assets/images/cat-illustration.svg";
import { List } from "modules/MemberHome/View/Components";
import { MemberDialog } from "modules/MemberHome/View/shared";
import { useGroup } from "shared/hooks";
import { MemberStatus } from "shared/types/GroupModel";
import { Box, Button, Text } from "shared/ui-kit";

import { SecondaryButton } from "../../../../shared/MemberDialog/style";

interface Props {
	open: boolean;
	setMembersRequestsDialog: React.Dispatch<React.SetStateAction<boolean>>;
	slug: string;
	groupId?: string;
}

const MembersRequestsDialog: FC<Props> = ({ open, setMembersRequestsDialog, slug, groupId }) => {
	const [page, setPage] = useState(1);
	const [pendingUsers, setPendingUsers] = useState<ForumPersonaModel[]>([]);
	const [shouldStopSearching, setShouldStopSearching] = useState(false);
	const [loading, setLoading] = useState(false);
	const {
		getGroupAdmins,
		approveAllPendingGroupUsers,
		declineAllPendingGroupUsers,
		actionOnPendingGroupUser,
		setGroup,
		getData: getGroupData
	} = useGroup();
	const { approveAllLoading, declineAllLoading, group } = getGroupData();

	const fetchPendingUsers = useCallback(async () => {
		if (open) {
			const limit = 10;
			setLoading(true);
			const { users } = await getGroupAdmins({ offset: page, limit, slug, status: MemberStatus.pending });
			setPendingUsers(ctx =>
				page === 1
					? users.map(u => ({ ...u, isApproved: false, isDeclined: false }))
					: [...ctx, ...users.map(u => ({ ...u, isApproved: false, isDeclined: false }))]
			);
			setShouldStopSearching(users.length < limit);
			setLoading(false);
		}
	}, [open, page, slug, getGroupAdmins]);

	useEffect(() => {
		fetchPendingUsers();
	}, [fetchPendingUsers]);

	const onClose = useCallback(() => {
		setMembersRequestsDialog(false);
	}, [setMembersRequestsDialog]);

	const pendingUserClickHandler = useCallback(
		async (type: "approveAll" | "declineAll" | "approve" | "decline", personaId?: number) => {
			if (groupId) {
				if (type === "approveAll") {
					const success = await approveAllPendingGroupUsers(groupId, onClose);
					success && group && setGroup({ ...group, hasRequests: false });
				}
				if (type === "declineAll") {
					const success = await declineAllPendingGroupUsers(groupId, onClose);
					success && group && setGroup({ ...group, hasRequests: false });
				}
				if (type === "approve" && personaId) {
					const success = await actionOnPendingGroupUser({ personaId, slug, isMember: true });
					if (success) {
						setPendingUsers(user =>
							user.map(u => (u.persona.personaId === personaId ? { ...u, isApproved: true } : u))
						);
						group && setGroup({ ...group, totalMembers: group.totalMembers + 1 });
					}
				}
				if (type === "decline" && personaId) {
					const success = await actionOnPendingGroupUser({ personaId, slug, isMember: false });
					success &&
						setPendingUsers(user =>
							user.map(u => (u.persona.personaId === personaId ? { ...u, isDeclined: true } : u))
						);
				}
			}
		},
		[
			approveAllPendingGroupUsers,
			declineAllPendingGroupUsers,
			groupId,
			actionOnPendingGroupUser,
			slug,
			onClose,
			setGroup,
			group
		]
	);

	const RenderItem = useCallback(
		(item: ForumPersonaModel & { isApproved: boolean; isDeclined: boolean }) => (
			<Box className="flex justify-between items-centers w-full py-2">
				<Box className="flex items-center w-3/4">
					<img className="w-10 h-10 rounded-md" src={item?.persona?.photos?.[0]?.profilePicture} alt="avatar" />
					<Box className="grid ml-4">
						<Text className="truncate">{`${item?.persona.firstName} ${item?.persona.lastName}`}</Text>
					</Box>
				</Box>
				{!item.isApproved && (
					<SecondaryButton
						className="grayBg"
						buttonTheme={"ghost"}
						palette="basic"
						onClick={() => pendingUserClickHandler("decline", item?.persona.personaId)}
						disabled={item.isDeclined || item.isApproved}
					>
						{item.isDeclined ? "Declined" : "Decline"}
					</SecondaryButton>
				)}
				{!item.isDeclined && (
					<Button
						buttonTheme={"light"}
						palette="light"
						onClick={() => pendingUserClickHandler("approve", item?.persona.personaId)}
						disabled={item.isDeclined || item.isApproved}
					>
						{item.isApproved ? "Approved" : "Approve"}
					</Button>
				)}
			</Box>
		),
		[pendingUserClickHandler]
	);

	const loadMore = useCallback(() => {
		if (!shouldStopSearching && !loading) {
			setPage(page => page + 1);
		}
	}, [setPage, loading, shouldStopSearching]);

	return (
		<MemberDialog
			title={"Member Requests"}
			open={open}
			onClose={() => {
				setMembersRequestsDialog(false);
				setPendingUsers([]);
				setPage(1);
			}}
			customWidth={512}
			noFooter={!pendingUsers.length}
			footerPrimary={{
				text: "Approve All",
				loading: approveAllLoading,
				disabled: approveAllLoading || declineAllLoading,
				onClick: () => pendingUserClickHandler("approveAll"),
				footerPlatte: "light",
				footerTheme: "light"
			}}
			footerSecondary={{
				text: "Decline All",
				loading: declineAllLoading,
				disabled: approveAllLoading || declineAllLoading,
				ghost: true,
				grayBackground: true,
				onClick: () => pendingUserClickHandler("declineAll")
			}}
		>
			<List
				title={""}
				items={pendingUsers}
				renderItem={RenderItem}
				loading={loading}
				fullBodySize
				onLoadMore={loadMore}
				noContentBlock={
					<Box className="w-full flex flex-col items-center my-20">
						<CatIcon />
						<Text className="mt-6">No Pending Request Found</Text>
					</Box>
				}
				skeletonSizeSmall
				wrapperClassName="shadow-none"
				itemWrapClassName=" "
			/>
		</MemberDialog>
	);
};

export default MembersRequestsDialog;
