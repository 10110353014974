import styled from "styled-components";

const SetupPriceTitle = styled.div`
	font-size: 15px;
	font-weight: 600;
	color: #222b45;
	margin-bottom: 8px;
`;

export { SetupPriceTitle };
