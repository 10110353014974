import styled from "styled-components";

export const SortIcon = styled.div`
	padding: 2px 4px;
	border-radius: 4px;
	margin-left: 4px;

	svg {
		fill: #c8cbcf;
	}

	&.active-asc {
		svg:first-child {
			fill: #000000;
		}
	}

	&.active-desc {
		svg:last-child {
			fill: #000000;
		}
	}
`;

export const BreadcrumbText = styled.p`
	color: #8f9bb3;
	font-size: 12px;
	font-weight: 500;
	line-height: 16px;

	a:hover {
		text-decoration: underline;
	}
`;
