import React, { useCallback, useEffect, useMemo, useState } from "react";

import { InputAdornment, TextField } from "@material-ui/core";

import Skeleton from "@material-ui/lab/Skeleton";

import { Scrollbars } from "react-custom-scrollbars";

import { ReactComponent as SearchIcon } from "assets/icons/iconSearch.svg";
import { SkeletonInfoWrapper, SkeletonRowWrapper } from "modules/Messaging/View/Containers/Messaging/CreateChat/style";
import { useDebounce, useGroup, useMembers, useNotification } from "shared/hooks";
import { GroupModel, InvitedToGroupStatus, SearchUsersModel, UserStatus } from "shared/types";
import { Button, Checkbox, Text } from "shared/ui-kit";

import getCountMessage from "utils/getCountMessage";

import { CustomDialog, FilterWrapper, FooterWrapper, Persona, PersonaList } from "./invitePeopleStyles";

interface Props {
	group?: GroupModel;
	open: boolean;
	onClose: (e: Event, r: string) => void;
}

const InvitePeople: React.FC<Props> = ({ group, open = true, onClose }) => {
	const { showMessage } = useNotification();

	const { getMembersToInvite, getMembersToInviteCount, getData: getMembersData } = useMembers();
	const { statusFilter, totalCount, loadingMembers, personas } = getMembersData();

	const { inviteMembers } = useGroup();

	const [isAllSelected, setIsAllSelected] = useState(false);
	const [selected, setSelected] = useState<number[]>([]);
	const [keyword, setKeyword] = useState("");
	const [visiblePersonas, setVisiblePersonas] = useState<SearchUsersModel[]>([]);
	const [filter, setFilter] = useState<{
		offset: number;
		limit: number;
		groupId?: string;
		keyword?: string;
		filterBy?: UserStatus[];
	}>({
		offset: 1,
		limit: 20,
		groupId: group?._id,
		keyword: undefined,
		filterBy: statusFilter
	});

	const debouncedKeyword = useDebounce(keyword, 250);

	useEffect(() => {
		setFilter(f => ({ ...f, groupId: group?._id, filterBy: statusFilter }));
	}, [group, statusFilter]);

	useEffect(() => {
		getMembersToInvite(filter);
	}, [getMembersToInvite, filter]);

	useEffect(() => {
		if (open) {
			setVisiblePersonas([]);
			getMembersToInviteCount({
				groupId: filter.groupId,
				keyword: filter.keyword || undefined
			});
		}
	}, [getMembersToInviteCount, filter.groupId, filter.keyword, open]);

	useEffect(() => {
		const filtered = personas.filter(
			persona =>
				persona.groupMembershipStatus !== InvitedToGroupStatus.member &&
				persona.groupMembershipStatus !== InvitedToGroupStatus.moderator &&
				persona.groupMembershipStatus !== InvitedToGroupStatus.owner
		);
		setVisiblePersonas(p => p.concat(filtered));
	}, [personas]);

	useEffect(() => {
		if (isAllSelected) {
			setSelected(visiblePersonas.map(x => x.personaId));
		}
	}, [isAllSelected, visiblePersonas]);

	useEffect(() => {
		if (debouncedKeyword && debouncedKeyword?.length >= 2) {
			setFilter(f => ({ ...f, keyword: debouncedKeyword, offset: 1 }));
		} else {
			setFilter(f => ({ ...f, keyword: undefined, offset: 1 }));
		}
	}, [debouncedKeyword]);

	const onCloseHandler = useCallback(
		(e, r) => {
			onClose && onClose(e, r);
		},
		[onClose]
	);

	const handleInvite = useCallback(async () => {
		await inviteMembers(selected, group?.slug);
		onCloseHandler({} as Event, "");
		showMessage(`${selected.length} user${selected.length > 1 ? "s have" : " has"} been invited! ✨`);
	}, [selected, inviteMembers, showMessage, onCloseHandler, group]);

	const footer = useMemo(
		() => (
			<FooterWrapper>
				<Text variant="subtitle2">You can invite max 500 members.</Text>
				<Button disabled={!selected.length || selected.length > 500} onClick={handleInvite} id="invite">
					Invite {getCountMessage(selected.length || 500, "Member")}
				</Button>
			</FooterWrapper>
		),
		[selected, handleInvite]
	);

	const handleSearchChange = useCallback(e => {
		setKeyword(e.target.value);
	}, []);

	const toggleAll = useCallback(() => {
		if (isAllSelected) {
			setSelected([]);
		}
		setIsAllSelected(!isAllSelected);
	}, [isAllSelected]);

	const loadMore = useCallback(() => {
		setFilter(f => ({ ...f, offset: f.offset + 1 }));
	}, []);

	const selectMember = useCallback(
		(checked: boolean, id: number) => {
			if (checked) {
				setSelected(ctx => ctx.concat(id));
			} else {
				setSelected(ctx => ctx.filter(x => x !== id));
				if (isAllSelected) {
					setIsAllSelected(false);
				}
			}
		},
		[isAllSelected]
	);

	const skeletonRow = useCallback(
		(index: number, style?: any) => (
			<SkeletonRowWrapper key={index} style={style || {}}>
				<Skeleton variant="rect" width={40} height={40} />
				<SkeletonInfoWrapper>
					<Skeleton width="35%" height={30} />
				</SkeletonInfoWrapper>
			</SkeletonRowWrapper>
		),
		[]
	);

	const PersonaListItem = React.useCallback(
		({ index, style }) => {
			if (index < visiblePersonas.length) {
				const currentItem = visiblePersonas[index];
				const { firstName, lastName, photos, groupMembershipStatus, personaId } = currentItem;

				return (
					<Persona.Wrapper key={index} style={style}>
						<Persona.InfoColumn>
							<Persona.Avatar title={firstName} image={!!photos.length ? photos[0].profilePicture : ""} />
							<Persona.Name>
								{`${firstName} ${lastName}`}{" "}
								{groupMembershipStatus === InvitedToGroupStatus.invited && "(Already invited)"}
							</Persona.Name>
						</Persona.InfoColumn>
						<Persona.CheckboxWrapper>
							<Checkbox
								checked={selected.some(x => x === personaId)}
								onChange={e => selectMember(e.target.checked, personaId)}
								id={`select_member_${index + 1}`}
							/>
						</Persona.CheckboxWrapper>
					</Persona.Wrapper>
				);
			}

			return skeletonRow(index, style);
		},
		[visiblePersonas, skeletonRow, selected, selectMember]
	);

	return (
		<CustomDialog title={`Invite to ${group?.name}`} open={open} footer={footer} onClose={onCloseHandler}>
			<TextField
				defaultValue={filter.keyword}
				onChange={handleSearchChange}
				placeholder="Search"
				variant="outlined"
				InputProps={{
					startAdornment: (
						<InputAdornment position="start">
							<SearchIcon />
						</InputAdornment>
					)
				}}
				id="searchMember"
			/>
			<FilterWrapper>
				<Button size="medium" palette="control" buttonTheme="outline" onClick={toggleAll} id="selectAll">
					{isAllSelected ? "Clear" : "Select"} All
				</Button>
			</FilterWrapper>
			<PersonaList
				height={400}
				itemCount={loadingMembers ? visiblePersonas.length + 7 : visiblePersonas.length}
				itemSize={72}
				onScroll={scroll => {
					if (
						scroll.scrollOffset >= visiblePersonas.length * 72 * 0.65 &&
						!loadingMembers &&
						visiblePersonas.length !== totalCount
					) {
						loadMore();
					}
				}}
				outerElementType={Scrollbars}
				style={{ overflow: "hidden" }}
			>
				{PersonaListItem}
			</PersonaList>
		</CustomDialog>
	);
};

export default InvitePeople;
