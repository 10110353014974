import React, { useCallback } from "react";

import { useHistory } from "react-router-dom";

import { useCommunity, useGTM } from "shared/hooks";

import { MonetizationPlansType } from "shared/types";

import { MonetizationPlanSubscriptionId } from "shared/types/MonetizationPlansType";

import { MonetizeCards } from "./style";

import { MonetizationRoutes } from "../../Containers/Monetization";
import { MonetizationPlanCard } from "../index";

interface MonetizationPlansProps {
	fullMode?: boolean;
	routes: MonetizationRoutes;
}

const MonetizationPlans: React.FC<MonetizationPlansProps> = ({ fullMode, routes }) => {
	const history = useHistory();
	const { addEvent } = useGTM();
	const { updateSubscriptionType, getData: getCommunityData } = useCommunity();
	const { monetizationPlans } = getCommunityData();

	const selectCard = useCallback(
		(monetizationPlan: MonetizationPlansType) => {
			addEvent({
				Category: "Engagement",
				Action: "Community",
				Label: "Community Monetization | Choose Method | Premium Content Only"
			});

			updateSubscriptionType({ subscriptionId: monetizationPlan._id });

			if (monetizationPlan.subscriptionId === MonetizationPlanSubscriptionId.SUBSCRIPTION_ONLY) {
				history.push(routes.subscription);
			}

			if (monetizationPlan.subscriptionId === MonetizationPlanSubscriptionId.PREMIUM_ONLY) {
				history.push(routes.premium);
			}

			if (monetizationPlan.subscriptionId === MonetizationPlanSubscriptionId.SUBSCRIPTION_PREMIUM_ONLY) {
				history.push(routes.subscription_and_premium);
			}
		},
		[addEvent, history, routes, updateSubscriptionType]
	);

	return (
		<MonetizeCards>
			{(monetizationPlans || []).map(plan => (
				<MonetizationPlanCard
					key={plan._id}
					plan={plan}
					fullMode={fullMode}
					onSelect={() => selectCard(plan)}
					buttonId={plan.subscriptionId}
				/>
			))}
		</MonetizeCards>
	);
};

export default MonetizationPlans;
