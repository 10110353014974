import styled, { css } from "styled-components";

import { Avatar, Box, Text } from "shared/ui-kit";

export const Wrapper = styled(Box)`
	display: flex;
	flex-direction: column;

	width: 100%;
`;

export const BodyWrapper = styled(Box)<{ centeredContent: boolean }>`
	max-height: 350px;
	min-height: 200px;
	${({ centeredContent }) => {
		if (centeredContent) {
			return css`
				align-items: center;
				justify-content: center;
				display: flex;
			`;
		}
	}}
`;

export const UserItem = styled(Box)`
	display: flex;
	justify-content: space-between;

	padding: 18px 24px 19px 24px;

	&:not(:last-child) {
		border-bottom: solid 0.5px #c5cee0;
	}
`;

export const UserItemInfo = styled(Box)`
	display: flex;
	align-items: center;
`;

export const UserItemIconWrapper = styled(Box)`
	margin-right: 16px;

	width: 32px;
	height: 32px;

	border-radius: 4px;

	overflow: hidden;
`;

export const UserItemTextsWrapper = styled(Box)`
	display: flex;
	flex-direction: column;
`;

export const StyledAvatar = styled(Avatar)`
	width: 100%;
	height: 100%;
`;

export const UserItemTitle = styled(Text)`
	font-size: 13px;
	font-weight: 600;
	line-height: 1.85;
	color: #222b45;
`;

export const UserItemSubTitle = styled(Text)`
	font-size: 12px;
	font-weight: normal;
	line-height: 1.33;
	color: #8f9bb3;
`;

export const UserItemValueWrapper = styled(Box)`
	display: flex;
	justify-content: flex-end;
`;

export const UserItemValue = styled(Text)`
	font-size: 13px;
	font-weight: 500;
	line-height: 1.85;
	text-align: right;
	margin-right: 10px;
	color: #222b45;
`;
