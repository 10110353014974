import { useCallback, useMemo } from "react";

import { PlayPreviewType, SeriesDialogType, SeriesPlayState } from "types/SeriesContextValuesType";

import { SeriesCollection } from "modules/Manage/Data/types";
import { FileTypesEnum } from "modules/Manage/Data/types/SeriesTypes";
import { useSeriesStore } from "shared/contexts";
import { useNotification } from "shared/hooks";
import useUser from "shared/hooks/useUser";

import { CrudType } from "shared/types";

import { uniqBy } from "utils/serviceUtils/helpers";

import { useSeriesApiService } from "../services";

const useSeries = (isMarketing = false) => {
	const seriesApiService = useSeriesApiService(isMarketing);
	const { showMessage } = useNotification();

	const { getData: getUserData } = useUser();
	const { user } = getUserData();

	const store = useSeriesStore();
	const { setState } = useSeriesStore();

	const methods = useMemo(
		() => ({
			createCollection: async data => {
				try {
					const { success, collection } = await seriesApiService.createCollection(data);

					if (!success) {
						throw new Error();
					}

					setState(ctx => ({
						...ctx,
						series: [
							{
								...data,
								...collection,
								filecreator: { ...collection.creator, firstName: user ? user.profiles[0].firstName : "" }
							},
							...ctx.series
						],
						seriesCount: ctx.seriesCount + 1,
						createEditSeriesDialog: {
							open: false
						}
					}));

					showMessage("Your series was successfully created. 🎉.", 3);

					return collection;
				} catch (error) {
					console.error(error);
					showMessage("Failed to create Series please try again.", 3);
				}
			},
			updateCollection: async (id: string, data, ignoreUpdate = false) => {
				try {
					const { success, collection } = await seriesApiService.updateCollection(id, data);

					if (!success) {
						throw new Error();
					}

					if (!ignoreUpdate) {
						setState(ctx => ({
							series: ctx.series.map(series =>
								series._id === collection._id ? { ...data, ...collection, filecreator: series.creator } : series
							),
							createEditSeriesDialog: {
								open: false
							},
							seriesDetailsDialog: undefined
						}));
					} else {
						setState({
							createEditSeriesDialog: {
								open: false
							},
							seriesDetailsDialog: undefined
						});
					}

					showMessage("Your series was successfully updated. 🎉.", 3);
					return collection;
				} catch (error) {
					console.error(error);
					showMessage("Failed to update Series please try again.", 3);
				}
			},
			deleteCollection: async (id: string) => {
				setState(ctx => ({ ...ctx, isLoading: true }));

				try {
					const { success } = await seriesApiService.deleteCollection(id);

					if (!success) {
						throw new Error();
					}

					setState(ctx => ({
						...ctx,
						series: ctx.series.filter(s => s._id !== id),
						seriesCount: ctx.seriesCount - 1,
						deleteSeriesDialog: undefined
					}));

					showMessage("Your collection was successfully deleted. 🗑.", 3);
				} catch (error) {
					console.error(error);
					showMessage("Failed to delete Collection please try again.", 3);
				}

				setState(ctx => ({ ...ctx, isLoading: false }));
			},
			getCollection: async ({
				id,
				offset,
				limit = 10,
				paginate
			}: {
				id: string;
				offset: number;
				limit?: number;
				paginate?: boolean;
			}) => {
				try {
					const { success, collection } = await seriesApiService.getCollection(id, offset, limit);

					if (!success) {
						throw new Error();
					}

					if (!!collection.itemIds.length || paginate) {
						setState(ctx => ({
							seriesDetailsDialog: ctx.seriesDetailsDialog && {
								...ctx.seriesDetailsDialog,
								data: {
									...collection,
									itemIds: !!ctx.seriesDetailsDialog.data?.itemIds.length
										? uniqBy(
												[
													...ctx.seriesDetailsDialog.data.itemIds.filter(i => i.hasOwnProperty("video")),
													...collection.itemIds
												],
												"_id"
										  )
										: collection.itemIds
								}
							}
						}));
					} else {
						setState(ctx => ({
							...ctx,
							seriesDetailsDialog: ctx.seriesDetailsDialog && {
								...ctx.seriesDetailsDialog,
								addContent: true,
								data: collection
							}
						}));
					}

					return collection;
				} catch (error) {
					showMessage("Failed to load Collection please try again.", 3);
				}
			},
			getCollectionInfo: async ({
				id,
				offset,
				limit = 10
			}: // paginate
			{
				id: string;
				offset: number;
				limit?: number;
				paginate?: boolean;
			}) => {
				try {
					const { success, collection } = await seriesApiService.getCollection(id, offset, limit);

					if (!success) {
						throw new Error();
					}

					return collection;
				} catch (error) {
					showMessage("Failed to load Collection please try again.", 3);
				}
			},
			getCollections: async ({
				limit = 20,
				keyword = "",
				offset,
				groupId,
				paginate,
				sortBy,
				ignoreState,
				categoryId,
				eventId
			}: {
				limit?: number;
				keyword?: string;
				offset: number;
				groupId?: string;
				paginate?: boolean;
				sortBy?: string;
				ignoreState?: boolean;
				categoryId?: string;
				eventId?: string;
			}) => {
				setState(ctx => ({ ...ctx, isLoading: true }));

				try {
					const { success, collections } = await seriesApiService.getCollections(
						offset,
						limit,
						keyword,
						groupId,
						sortBy,
						categoryId,
						eventId
					);
					if (!success) {
						throw new Error("");
					}

					if (!ignoreState) {
						setState(ctx => ({
							...ctx,
							series: offset > 1 && paginate ? [...ctx.series, ...collections] : collections,
							seriesSuggestions: offset > 1 && paginate ? [...ctx.series, ...collections] : collections
						}));
					}

					return collections;
				} catch (err) {
					console.error(err);
					showMessage("Failed to load Series please try again.", 3);
				} finally {
					setState(ctx => ({ ...ctx, isLoading: false }));
				}
			},
			reorderCollection: async (id: string, itemIds: { _id: string; type: FileTypesEnum }[]) => {
				const { success } = await seriesApiService.reorderCollection(id, itemIds);
				if (!success) {
					throw new Error();
				}

				setState(ctx => ({
					...ctx,
					seriesDetailsDialog: undefined
				}));

				showMessage("Your series was successfully updated. 🎉.", 3);
			},
			getSeriesUrls: async (id: string) => {
				try {
					const { urls } = await seriesApiService.getSeriesUrls(id);
					return urls;
				} catch {
					showMessage("Couldn't get series info, please try again.", 3);
				}
			},
			getAllCollectionsCount: async ({
				keyword,
				groupId,
				eventId,
				categoryId
			}: {
				keyword?: string;
				groupId?: string;
				eventId?: string;
				categoryId?: string;
			}) => {
				const { totalCount } = await seriesApiService.getSeriesCount({ keyword, groupId, eventId, categoryId });
				setState(ctx => ({ ...ctx, seriesCount: totalCount }));
			},
			setSeriesPerPage: (seriesPerPage: number) => {
				setState(ctx => ({ ...ctx, seriesPerPage }));
			},
			setPage: (page: number) => {
				setState(ctx => ({ ...ctx, page }));
			},
			setSeriesDialog: (createEditSeriesDialog: {
				open: boolean;
				data?: SeriesCollection;
				isEditingDetail?: boolean;
			}) => {
				setState(ctx => ({ ...ctx, createEditSeriesDialog }));
			},
			setDeleteSeriesDialog: (deleteSeriesDialog?: string) => {
				setState(ctx => ({ ...ctx, deleteSeriesDialog }));
			},
			setSeriesDetailsDialog: (seriesDetailsDialog?: SeriesDialogType) => {
				setState(ctx => ({ ...ctx, seriesDetailsDialog }));
			},
			setPlayPreview: (playPreview?: PlayPreviewType) => {
				setState(ctx => ({ ...ctx, playPreview }));
			},
			setSeriesPlayState: (seriesPlayState?: SeriesPlayState) => {
				setState(ctx => ({ ...ctx, seriesPlayState }));
			},
			setSearchText: (searchText: string) => {
				setState(ctx => ({ ...ctx, searchText }));
			},
			setSeries: (series: SeriesCollection[]) => {
				setState({ series });
			}
		}),
		[seriesApiService, setState, showMessage, user]
	);

	const getData = useCallback(() => {
		return store;
	}, [store]);

	const isUserCouldManageSeries = useMemo(
		() =>
			({
				creatorId,
				type = CrudType.CREATE,
				isCreator
			}: {
				creatorId?: string;
				type?: CrudType;
				isCreator?: boolean;
			}) => {
				return !!(
					user?.isGlobalModerator ||
					user?.isGlobalOwner ||
					user?.isGlobalAdmin ||
					(user?.isInfluencer &&
						(type === CrudType.CREATE || isCreator || (creatorId && creatorId === user?.personaDocId)))
				);
			},
		[user]
	);

	return { ...methods, getData, isUserCouldManageSeries };
};

export default useSeries;
