import styled from "styled-components";

import { Box } from "shared/ui-kit";

export const Wrapper = styled(Box)`
	width: 100%;
	height: 100%;

	pointer-events: none;

	&.rounded {
		svg {
			border-radius: 10px;
		}
	}

	svg {
		g {
			path {
				&:first-child {
					// background-fill
				}

				&:nth-child(2) {
					// first color - 4 elements
				}

				&:nth-child(3),
				&:nth-child(6) {
					//  second color - 3 big elements
				}

				&:nth-child(4),
				&:nth-child(7),
				&:nth-child(9),
				&:nth-child(11) {
					// third color - n small elements
				}

				&:nth-child(5),
				&:nth-child(8) {
					// 4-th color -2 small elements inside of big elements
				}

				&:nth-child(10) {
					// 5-th color - small ovals inside of 3 big elements
				}
			}
		}
	}

	&.type-workspace_search {
		svg {
			g {
				path {
					&:first-child {
						fill: #0e2a77;
					}

					&:nth-child(2) {
						fill: #2558b3;
					}

					&:nth-child(3),
					&:nth-child(6) {
						fill: #3677d6;
					}

					&:nth-child(4),
					&:nth-child(7),
					&:nth-child(9),
					&:nth-child(11) {
						fill: #4a99f9;
					}

					&:nth-child(5),
					&:nth-child(8) {
						fill: #76b8fb;
					}

					&:nth-child(10) {
						fill: #fcf5f1;
					}
				}
			}
		}
	}

	&.type-event_details {
		svg {
			g {
				path {
					&:first-child {
						fill: #30d14d;
					}

					&:nth-child(2) {
						fill: #189646;
					}

					&:nth-child(3),
					&:nth-child(6) {
						fill: #0f7940;
					}

					&:nth-child(4),
					&:nth-child(7),
					&:nth-child(9),
					&:nth-child(11) {
						fill: #60e36b;
					}

					&:nth-child(5),
					&:nth-child(8) {
						fill: #23b34a;
					}

					&:nth-child(10) {
						fill: #fcf5f1;
					}
				}
			}
		}
	}

	&.type-group_details {
		svg {
			g {
				path {
					&:first-child {
						fill: #c5cee0;
					}

					&:nth-child(2) {
						fill: #8f9bb3;
					}

					&:nth-child(3),
					&:nth-child(6) {
						fill: #475b7f;
					}

					&:nth-child(4),
					&:nth-child(7),
					&:nth-child(9),
					&:nth-child(11) {
						fill: #e4e9f2;
					}

					&:nth-child(5),
					&:nth-child(8) {
						fill: #8f9bb3;
					}

					&:nth-child(10) {
						fill: #fcf5f1;
					}
				}
			}
		}
	}

	&.type-profile_image {
		svg {
			g {
				path {
					&:first-child {
						fill: #30d14d;
					}

					&:nth-child(2) {
						fill: #189646;
					}

					&:nth-child(3),
					&:nth-child(6) {
						fill: #0f7940;
					}

					&:nth-child(4),
					&:nth-child(7),
					&:nth-child(9),
					&:nth-child(11) {
						fill: #60e36b;
					}

					&:nth-child(5),
					&:nth-child(8) {
						fill: #23b34a;
					}

					&:nth-child(10) {
						fill: #82f181;
					}
				}
			}
		}
	}

	&.type-profile_cover {
		svg {
			g {
				path {
					&:first-child {
						fill: #4a99f9;
					}

					&:nth-child(2) {
						fill: #3677d6;
					}

					&:nth-child(3),
					&:nth-child(6) {
						fill: #ffd044;
					}

					&:nth-child(4),
					&:nth-child(7),
					&:nth-child(9),
					&:nth-child(11) {
						fill: #6173fe;
					}

					&:nth-child(5),
					&:nth-child(8) {
						fill: #dbac31;
					}

					&:nth-child(10) {
						fill: #fcf5f1;
					}
				}
			}
		}
	}

	&.type-post_preview {
		svg {
			g {
				path {
					&:first-child {
						fill: #fff2b4;
					}

					&:nth-child(2) {
						fill: #ffd044;
					}

					&:nth-child(3),
					&:nth-child(6) {
						fill: #189646;
					}

					&:nth-child(4),
					&:nth-child(7),
					&:nth-child(9),
					&:nth-child(11) {
						fill: #ffdf72;
					}

					&:nth-child(5),
					&:nth-child(8) {
						fill: #0f7940;
					}

					&:nth-child(10) {
						fill: #fcf5f1;
					}
				}
			}
		}
	}

	&.type-board_preview {
		svg {
			g {
				path {
					&:first-child {
						fill: #ddfcd5;
					}

					&:nth-child(2) {
						fill: #23b34a;
					}

					&:nth-child(3),
					&:nth-child(6) {
						fill: #475b7f;
					}

					&:nth-child(4),
					&:nth-child(7),
					&:nth-child(9),
					&:nth-child(11) {
						fill: #82f181;
					}

					&:nth-child(5),
					&:nth-child(8) {
						fill: #222b45;
					}

					&:nth-child(10) {
						fill: #fcf5f1;
					}
				}
			}
		}
	}

	&.type-series_preview {
		svg {
			g {
				path {
					&:first-child {
						fill: #ebecff;
					}

					&:nth-child(2) {
						fill: #4157fb;
					}

					&:nth-child(3),
					&:nth-child(6) {
						fill: #4a99f9;
					}

					&:nth-child(4),
					&:nth-child(7),
					&:nth-child(9),
					&:nth-child(11) {
						fill: #a7afff;
					}

					&:nth-child(5),
					&:nth-child(8) {
						fill: #3677d6;
					}

					&:nth-child(10) {
						fill: #fcf5f1;
					}
				}
			}
		}
	}

	&.type-file_preview {
		svg {
			g {
				path {
					&:first-child {
						fill: #daf1fe;
					}

					&:nth-child(2) {
						fill: #3677d6;
					}

					&:nth-child(3),
					&:nth-child(6) {
						fill: #ffd044;
					}

					&:nth-child(4),
					&:nth-child(7),
					&:nth-child(9),
					&:nth-child(11) {
						fill: #92ccfd;
					}

					&:nth-child(5),
					&:nth-child(8) {
						fill: #f2bb2e;
					}

					&:nth-child(10) {
						fill: #fcf5f1;
					}
				}
			}
		}
	}

	&.type-track_preview {
		svg {
			g {
				path {
					&:first-child {
						fill: #edf1f7;
					}

					&:nth-child(2) {
						fill: #c5cee0;
					}

					&:nth-child(3),
					&:nth-child(6) {
						fill: #db2c33;
					}

					&:nth-child(4),
					&:nth-child(7),
					&:nth-child(9),
					&:nth-child(11) {
						fill: #c5cee0;
					}

					&:nth-child(5),
					&:nth-child(8) {
						fill: #b71e31;
					}

					&:nth-child(10) {
						fill: #fcf5f1;
					}
				}
			}
		}
	}

	&.type-video_preview {
		svg {
			g {
				path {
					&:first-child {
						fill: #ffc8b1;
					}

					&:nth-child(2) {
						fill: #ffa38a;
					}

					&:nth-child(3),
					&:nth-child(6) {
						fill: #189646;
					}

					&:nth-child(4),
					&:nth-child(7),
					&:nth-child(9),
					&:nth-child(11) {
						fill: #ff806d;
					}

					&:nth-child(5),
					&:nth-child(8) {
						fill: #0f7940;
					}

					&:nth-child(10) {
						fill: #fcf5f1;
					}
				}
			}
		}
	}

	&.type-request_money {
		svg {
			g {
				path {
					&:first-child {
						fill: #c5cee0;
					}

					&:nth-child(2) {
						fill: #8f9bb3;
					}

					&:nth-child(3),
					&:nth-child(6) {
						fill: #475b7f;
					}

					&:nth-child(4),
					&:nth-child(7),
					&:nth-child(9),
					&:nth-child(11) {
						fill: #e4e9f2;
					}

					&:nth-child(5),
					&:nth-child(8) {
						fill: #8f9bb3;
					}

					&:nth-child(10) {
						fill: #fcf5f1;
					}
				}
			}
		}
	}

	&.type-album_preview {
		svg {
			g {
				path {
					&:first-child {
						fill: #ffe7d8;
					}

					&:nth-child(2) {
						fill: #ff806d;
					}

					&:nth-child(3),
					&:nth-child(6) {
						fill: #6173fe;
					}

					&:nth-child(4),
					&:nth-child(7),
					&:nth-child(9),
					&:nth-child(11) {
						fill: #ffa38a;
					}

					&:nth-child(5),
					&:nth-child(8) {
						fill: #808fff;
					}

					&:nth-child(10) {
						fill: #fcf5f1;
					}
				}
			}
		}
	}
`;
