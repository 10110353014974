import { Box } from "@material-ui/core";
import styled from "styled-components";

import AkinaLogo from "assets/images/akinaLogoNew.png";
import BitcoinLogo from "assets/images/bitcoin-logo.webp";
import ReliasLogo from "assets/images/relias.webp";
import { Text } from "shared/ui-kit";

export const NavbarWrapper = styled(Box)`
	background-color: white;
	padding: 16px;
	box-shadow: 0 0.5px 0 0 #c5cee0;
	${props => props.theme.breakpoints.up("md")} {
		padding: 16px 80px;
	}

	&.kijiji {
		background-color: #a0a0f2;

		.akina-logo {
			display: block;

			width: 190px;
			height: 80px;

			background-image: url(${AkinaLogo});
			background-position: center;
			background-repeat: no-repeat;
			background-size: contain;

			${props => props.theme.breakpoints.down("sm")} {
				width: 120px;
				height: 50px;

				margin-left: 10px;
			}
		}
	}

	&.holliblu {
		.relias-logo {
			display: block;

			width: 170px;
			height: 46px;

			background-image: url(${ReliasLogo});
			background-position: center;
			background-repeat: no-repeat;
			background-size: contain;

			${props => props.theme.breakpoints.down("sm")} {
				width: 120px;
				height: 34px;

				margin-left: 10px;
			}
		}
	}

	&.bitcoin {
		.bitcoin-logo {
			display: block;

			width: 55px;
			height: 50px;

			background-image: url(${BitcoinLogo});
			background-position: center;
			background-repeat: no-repeat;
			background-size: contain;

			${props => props.theme.breakpoints.down("sm")} {
				width: 55px;
				height: 50px;

				margin-left: 10px;
			}
		}
	}
`;

export const ContentWrapper = styled(Box)`
	max-width: 900px;
	padding: 32px 16px;
	margin: auto;
`;

ContentWrapper.Block = styled(Box)`
	padding-bottom: 40px;
	margin-bottom: 12px;
	border-bottom: 1px solid #c5cee0;
`;

ContentWrapper.Header = styled(Box)`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 16px 0;
	svg,
	img {
		width: 64px;
		height: 64px;
		object-fit: cover;
		${props => props.theme.breakpoints.up("sm")} {
			width: 80px;
			height: 80px;
		}
	}
`;

ContentWrapper.Headline = styled(Text)`
	font-size: 20px;
	font-weight: bold;
	line-height: 1.33;
	color: #222b45;
	flex: 1;
	margin-right: 12px;
	${props => props.theme.breakpoints.up("sm")} {
		font-size: 30px;
	}
`;

ContentWrapper.OS = styled(Box)`
	display: flex;
	align-items: center;
	margin: 16px 0;
	p {
		font-size: 14px;
		font-weight: bold;
		line-height: 1.33;
		color: #222b45;
		margin-left: 16px;
		${props => props.theme.breakpoints.up("sm")} {
			font-size: 18px;
		}
	}
`;

ContentWrapper.Description = styled(Text)`
	font-size: 15px;
	line-height: 1.47;
	color: #222b45;
`;

ContentWrapper.Step = styled(Box)`
	margin-bottom: 16px;
	.substep {
		margin-top: 12px;
		margin-left: 20px;
	}
`;

export const EmailVerifiedWrapper = styled(Box)`
	margin: auto;
	max-width: 560px;
	margin-top: 24px;
	padding: 0 16px;

	${props => props.theme.breakpoints.up("sm")} {
		margin-top: 80px;
		padding: 0;
	}
`;

export const EmailVerified = styled(Box)`
	padding: 60px 16px 46px;
	border-radius: 12px;
	box-shadow: inset 0 0 0 0.5px #c5cee0;
	background-color: #fff;
	text-align: center;
	h1 {
		font-size: 32px;
		font-weight: bold;
		line-height: 1.25;
		text-align: center;
		color: #222b45;
		margin: 40px 0 10px;
	}
	p {
		font-size: 15px;
		line-height: 1.33;
		color: #222b45;
	}
	button {
		margin-top: 24px;
		border-radius: 4px;
		font-size: 16px;
		font-weight: bold;
		line-height: 1.25;
		color: #fff;
	}
`;
