import React, { useCallback, useEffect, useMemo, useState } from "react";

import Skeleton from "@material-ui/lab/Skeleton";

import { useLocation } from "react-router-dom";

import { ReactComponent as ManInLotusIcon } from "assets/icons/man-icon.svg";
import { useAuth } from "modules/App/Data/hooks";

import { SelectAnimalAvatarModal } from "shared/Components";
import { useCommunity, useGTM } from "shared/hooks";
import { Text } from "shared/ui-kit";
import { scrollTo } from "utils/scrollTo";

import ProfileFields from "./ProfileFields";
import { BrokenLinkContainer, ContentBlock, FormBlockWrapper } from "./style";

import { ContentWrapper, FormBlock } from "../../Components";

export interface ProfileProps {
	adminProfileView?: boolean;
	createCommunityRoute: string;
	homeRoute: string;
	createPasswordRoute?: string;
}

const Profile: React.FC<ProfileProps> = ({
	adminProfileView,
	createCommunityRoute,
	homeRoute,
	createPasswordRoute
}) => {
	const [showAnimalAvatarModal, setShowAnimalAvatarModal] = useState(false);
	const [avatarImage, setAvatarImage] = useState<string>();

	const { setInvitationMode, getData: getAuthData } = useAuth();
	const { onboardingToken } = getAuthData();

	const { getWorkspaceDetails } = useCommunity();

	const { checkInvitationValidation } = useAuth();

	const params = new URLSearchParams(useLocation().search);

	const communityUrl = params.get("communityUrl");
	const invitationId = params.get("invitationId") || "";
	const userId = params.get("userId") || "";

	const isNewCommunity = !invitationId;

	const { addEvent } = useGTM();

	useEffect(() => {
		if (invitationId) {
			checkInvitationValidation({ invitationId, userId });
		}
	}, [checkInvitationValidation, invitationId, userId]);

	useEffect(() => {
		if (adminProfileView) {
			setInvitationMode(true);
			getWorkspaceDetails();
		}
	}, [adminProfileView, getWorkspaceDetails, setInvitationMode]);

	useEffect(() => {
		scrollTo(0, 0);
		addEvent({ Action: "Create Community", Label: "Step-3" });
	}, [addEvent]);

	const PageSkeleton = useMemo(
		() => (
			<>
				<Skeleton variant="circle" width={64} height={64} />
				<Skeleton width="40%" height={26} />
				<Skeleton width="85%" height={45} />
				<Skeleton width="90%" height={30} />
				<Skeleton width="60%" height={30} />
				<Skeleton width="95%" height={120} />
				<Skeleton width="100%" height={60} />
			</>
		),
		[]
	);

	const BrokenLink = useMemo(
		() => (
			<BrokenLinkContainer>
				<ManInLotusIcon />
				<Text variant="h2">Oops!</Text>
				<Text variant="body1">
					The invite is no longer active. Please check with the person who shared it with you to see if there’s a new
					link available.
				</Text>
			</BrokenLinkContainer>
		),
		[]
	);

	const handleCloseAnimalAvatarModal = useCallback(
		async (imgUrl?: string) => {
			setShowAnimalAvatarModal(false);
			if (imgUrl) setAvatarImage(imgUrl);
		},
		[setAvatarImage]
	);

	const ProfileForm = useMemo(
		() => (
			<ProfileFields
				adminProfileView={adminProfileView}
				createCommunityRoute={createCommunityRoute}
				homeRoute={homeRoute}
				createPasswordRoute={createPasswordRoute}
				setShowAnimalAvatarModal={setShowAnimalAvatarModal}
				communityUrl={communityUrl}
				userId={userId}
				invitationId={invitationId}
				imgUrl={avatarImage}
			/>
		),
		[
			adminProfileView,
			createCommunityRoute,
			homeRoute,
			createPasswordRoute,
			setShowAnimalAvatarModal,
			communityUrl,
			userId,
			invitationId,
			avatarImage
		]
	);

	const body = useMemo(
		() =>
			isNewCommunity
				? ProfileForm
				: onboardingToken.loading
				? PageSkeleton
				: !onboardingToken.valid
				? BrokenLink
				: ProfileForm,
		[BrokenLink, PageSkeleton, isNewCommunity, onboardingToken.loading, onboardingToken.valid, ProfileForm]
	);

	return (
		<ContentWrapper>
			<ContentBlock>
				<FormBlockWrapper>
					<FormBlock customStyle={!onboardingToken.valid ? "margin: 0; border-radius:12px" : "margin: 0;"}>
						{body}
					</FormBlock>
				</FormBlockWrapper>
			</ContentBlock>
			{showAnimalAvatarModal && <SelectAnimalAvatarModal onClose={handleCloseAnimalAvatarModal} />}
		</ContentWrapper>
	);
};

export default Profile;
