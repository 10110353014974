import styled from "styled-components";

import { Box } from "shared/ui-kit";

export const Wrapper = styled(Box)`
	background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 68%, rgba(0, 0, 0, 0.16));
	background-color: #c5cee0;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 16px 0 72px;
	position: relative;
	${props => props.theme.breakpoints.up("sm")} {
		padding: 16px 0 32px;
		height: 450px;
	}
`;

Wrapper.PDF = styled(Box)`
	width: 280px;
	position: relative;
	canvas {
		position: relative;
		z-index: 100;
		box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.24);
	}
	.react-pdf__Page__annotations {
		display: none;
	}
`;

Wrapper.ExtraPaper = styled(Box)`
	position: absolute;
	width: 10px;
	background-color: #fff;
	border-radius: 2px;
	box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.24);
	height: ${({ heightOffset }) => heightOffset && `calc(100% - ${heightOffset}px)`};
	top: ${({ heightOffset }) => heightOffset && `${heightOffset}px`};
	right: ${({ heightOffset }) => heightOffset && `-${heightOffset}px`};
	z-index: ${({ zIndex }) => zIndex && zIndex};
`;

Wrapper.Actions = styled(Box)`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	bottom: 16px;
	.circle {
		border-radius: 99%;
		&:disabled {
			svg path:nth-child(2) {
				fill: #c5cee0;
			}
		}
		svg {
			width: 32px;
		}
	}
	.next {
		transform: rotate(180deg);
	}
	${props => props.theme.breakpoints.up("sm")} {
		justify-content: flex-start;
	}
`;

export const OpenDocumentButton = styled.a`
	height: 32px;
	border-radius: 8px;
	background-color: #fff;
	padding: 8px 20px;
	color: #adb4c4;
	font-weight: bold;
	margin: 0 6px;
	text-decoration: none;
	display: flex;
	align-items: center;
`;
