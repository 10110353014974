import styled from "styled-components";

import { Box, Text } from "shared/ui-kit";

export const Container = styled(Box)`
	display: flex;
	flex-direction: column;

	${props => props.theme.breakpoints.down("sm")} {
		width: 100%;
		margin: 0;
	}

	.MuiExpansionPanel-root {
		border: 0.5px solid #c5cee0;
		border-radius: 4px;
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
		&:before {
			display: none;
		}
	}

	.MuiExpansionPanelSummary-root {
		.MuiExpansionPanelSummary-expandIcon {
			order: 1;
		}
		.MuiIconButton-label {
			background-color: white;
		}
	}

	.MuiExpansionPanelSummary-content {
		.MuiTypography-root {
			font-weight: normal;
			color: #000000;
			font-size: 18px;
		}
	}
`;

export const IconContainer = styled(Box)`
	align-items: center;
	display: flex;
	flex: 0.5 auto;
	margin: 0 24px 0 14px;
`;

export const HeadingContainer = styled(Box)`
	flex: 2 1 auto;
`;

export const TitleContainer = styled(Box)`
	display: flex;
	flex-direction: row;
`;

export const DescriptionContainer = styled(Box)`
	padding-top: 5px;
	font-size: 13px;
	color: #8f9bb3;
	line-height: 18px;
`;

export const ContentContainer = styled(Box)`
	width: 94%;
	margin-left: 4%;
	margin-right: 4%;
	padding-bottom: 24px;
`;

export const Splitter = styled(Box)`
	border-top: 1px solid #c5cee0;
	width: 100%;
	margin-bottom: 20px;
	margin-top: 10px;
`;

export const StyledHeadingText = styled(Text)`
	margin-bottom: 12px;
`;

export const StyledDescriptionText = styled(Text)`
	margin-bottom: 35px;
`;
