import React from "react";

import { DateTime } from "luxon";
import { Area, CartesianGrid, ComposedChart, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

import styled from "styled-components";

import { StatItem, StatsItemKey } from "shared/types";
import { Box } from "shared/ui-kit";

const TooltipWrapper = styled(Box)<{ color?: string }>`
	display: flex;
	align-items: center;
	justify-content: center;

	background: rgba(255, 255, 255, 0.8);
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);

	border-radius: 4px;

	padding: 5px 15px;

	.label {
		font-size: 14px;
		font-weight: normal;
		letter-spacing: normal;
		color: #222b45;
	}

	.value {
		font-size: 16px;
		font-weight: bold;
		letter-spacing: normal;
		color: ${({ color }) => color || "#222b45"};
	}
`;

interface IProps<T extends string> {
	data: Array<StatItem<T>> | null;
	dataKey: T;
	gradientId: string;
	color: string;
	stopColor: string;
}

type Props = IProps<StatsItemKey>;

const SingleLineChart = React.memo(({ data, dataKey, color, stopColor, gradientId }: Props) => {
	const CustomTooltip = ({ active, payload, label, color }) => {
		if (active && payload && payload.length) {
			return (
				<TooltipWrapper color={color}>
					<p className="label">
						{`${DateTime.fromISO(label).toFormat("dd LLL, yyyy")}:`}{" "}
						<span className="value">{`${payload[0].value}`}</span>
					</p>
				</TooltipWrapper>
			);
		}

		return null;
	};

	return (
		data && (
			<ResponsiveContainer minWidth={230} minHeight={214}>
				<ComposedChart data={data} margin={{ top: 10, left: -10, right: 30, bottom: 5 }}>
					<defs>
						<linearGradient id={gradientId} x1="0" y1="0" x2="0" y2="1">
							<stop offset="0%" stopColor={color} stopOpacity={0.3} />
							<stop offset="95%" stopColor={stopColor} stopOpacity={0.05} />
						</linearGradient>
					</defs>
					<CartesianGrid stroke={"#e4e9f2"} />
					<XAxis
						dataKey="date"
						tickLine={false}
						axisLine={{ stroke: "transparent" }}
						tickFormatter={label => {
							const date = DateTime.fromISO(label);
							return date.isValid ? date.toFormat("dd LLL") : "";
						}}
					/>
					<YAxis allowDecimals={false} tickLine={false} axisLine={{ stroke: "transparent" }} />
					<Line type="monotone" dataKey={dataKey} dot={false} stroke={color} strokeWidth={2} />
					<Area
						name={"hiddenArea"}
						type="monotone"
						dataKey={dataKey}
						stroke={"none"}
						strokeWidth={2}
						fillOpacity={1}
						fill={`url(#${gradientId})`}
					/>
					<Tooltip
						content={info => (
							<CustomTooltip active={info.active} payload={info.payload} label={info.label} color={color} />
						)}
					/>
				</ComposedChart>
			</ResponsiveContainer>
		)
	);
});

export default SingleLineChart;
