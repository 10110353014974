import React from "react";

import { Card } from "./styles";

import { NotificationsCard } from "../../Components";

const NotificationPage = () => (
	<Card>
		<NotificationsCard open />
	</Card>
);

export default NotificationPage;
