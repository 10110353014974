import React, { memo, useCallback, useRef, useState } from "react";

import { Fade } from "@material-ui/core";
import { PopperPlacementType } from "@material-ui/core/Popper/Popper";

import SkinTonesImg from "assets/images/skin-tones.png";
import { emotionIcons } from "shared/constants";

import { Tooltip } from "shared/ui-kit";

import { EmotionWrapper, StyledPopper, Wrapper } from "./style";

import ChooseSkinTone from "../ChooseSkinTone";

export interface LikeProps {
	show: boolean;
	reactionColor: string;
	onSelect: (reaction: string) => void;
	reactionFill?: string;
	showChooseSkinTone?: boolean;
	chatView?: boolean;
	anchorEl: null | HTMLElement;
	placement?: PopperPlacementType;
}

const Like: React.FC<LikeProps> = memo(
	({
		show = false,
		reactionColor,
		onSelect,
		reactionFill,
		showChooseSkinTone,
		chatView,
		anchorEl,
		placement = "top-start"
	}) => {
		const [activeIndex, setActiveIndex] = useState(-1);

		const [openChangeEmojiSkintone, setOpenChangeEmojiSkintone] = useState(false);

		const tonesRef = useRef<HTMLDivElement>(null);

		const handleChooseTone = useCallback((e?: React.MouseEvent<HTMLElement>) => {
			e?.stopPropagation && e.stopPropagation();
			e?.preventDefault && e.preventDefault();

			setOpenChangeEmojiSkintone(tone => !tone);
		}, []);

		const handleSelect = useCallback(
			(e: React.MouseEvent<HTMLElement>, reaction: string) => {
				e.stopPropagation();
				onSelect(reaction);
			},
			[onSelect]
		);

		return (
			<StyledPopper open={show} anchorEl={anchorEl} placement={placement} transition style={{ zIndex: 1300 }}>
				{({ TransitionProps }) => (
					<Fade {...TransitionProps} timeout={250}>
						<Wrapper className={`${chatView ? "chatView" : ""}`}>
							{emotionIcons.map((emotion, index) => (
								<EmotionWrapper
									className={`${index === activeIndex ? "active" : ""} ${
										activeIndex > -1 && index !== activeIndex ? "inactive" : ""
									} ${chatView ? "chatView" : ""}`}
									onMouseEnter={() => setActiveIndex(index)}
									onMouseLeave={() => (index === activeIndex ? setActiveIndex(-1) : undefined)}
									key={index}
									onClick={e => handleSelect(e, emotion.value)}
									fill={reactionFill || reactionColor}
								>
									<Tooltip text={emotion?.label} placement={"bottom"} debounce={250}>
										{emotion.icon}
									</Tooltip>
								</EmotionWrapper>
							))}
							{showChooseSkinTone && (
								<EmotionWrapper onClick={handleChooseTone} className={`${chatView ? "chatView" : ""}`}>
									<Tooltip text="Choose skin tone" placement="top">
										<img src={SkinTonesImg} alt="choose skin tone" />
									</Tooltip>
								</EmotionWrapper>
							)}
							<ChooseSkinTone
								show={openChangeEmojiSkintone && !!showChooseSkinTone}
								onChooseTone={handleChooseTone}
								ref={tonesRef}
							/>
						</Wrapper>
					</Fade>
				)}
			</StyledPopper>
		);
	}
);

export default Like;
