import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { useGroup, useOnScreen } from "shared/hooks";

import { GroupModel } from "shared/types";
import { Text } from "shared/ui-kit";

import LoadingItems from "../LoadingItems";
import GroupBlock from "../blocks/GroupBlock";

interface Props {
	searchTerm: string;
	itemsCount: number;
	active?: boolean;
	setSelectedGroup: React.Dispatch<React.SetStateAction<GroupModel[] | undefined>>;
	selectedGroup: GroupModel[] | undefined;
}

const GroupsTab: React.FC<Props> = ({ searchTerm, active, selectedGroup, setSelectedGroup, itemsCount }) => {
	const [groupsCount, setGroupsCount] = useState(0);
	const [groups, setGroups] = useState<GroupModel[]>();

	const { getData: getGroupData, getGroups, getTotalGroupsCount } = useGroup();
	const { loading } = getGroupData();

	const lastGroupRef = useRef<HTMLDivElement>(null);
	const [page, setPage] = useState(1);

	const onScreen = useOnScreen(lastGroupRef);

	const hasMoreGroups = useMemo(() => groupsCount > (groups?.length || 0), [groups, groupsCount]);

	useEffect(() => {
		if (onScreen && hasMoreGroups) {
			setPage(state => state + 1);
		}
	}, [hasMoreGroups, onScreen]);

	useEffect(() => {
		(async () => {
			if (active) {
				const { groups } = await getGroups({ name: searchTerm, isMyGroups: true, limit: 10, page });
				setGroups(prevGroups => (page === 1 || !prevGroups ? groups : [...prevGroups, ...groups]));
			}
		})();
	}, [getGroups, searchTerm, active, page]);

	useEffect(() => {
		(async () => {
			const totalFoundGroups = await getTotalGroupsCount({ name: searchTerm, isMyGroups: true });
			setGroupsCount(totalFoundGroups);
		})();
	}, [getTotalGroupsCount, searchTerm]);

	const handleToggleGroup = useCallback(
		(group: GroupModel, checked: boolean) => {
			if (itemsCount === 5 && checked) return;
			else if (checked) setSelectedGroup(prevGroup => (prevGroup ? [...prevGroup, group] : [group]));
			else setSelectedGroup(prevGroup => prevGroup?.filter(g => g._id !== group._id));
		},
		[setSelectedGroup, itemsCount]
	);

	useEffect(() => {
		if (onScreen && hasMoreGroups && searchTerm && !searchTerm?.length) {
			setPage(state => state + 1);
		}
	}, [hasMoreGroups, onScreen, searchTerm]);

	return (
		<>
			{!groups?.length && !loading && <Text variant="h5">No Results</Text>}
			{loading && !groups ? (
				<LoadingItems />
			) : (
				groups &&
				groups.map(group => (
					<>
						<GroupBlock
							handleToggleGroup={handleToggleGroup}
							group={group}
							key={group._id}
							selectedGroup={selectedGroup}
						/>
					</>
				))
			)}
		</>
	);
};

export default GroupsTab;
