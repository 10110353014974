import { useMemo } from "react";

import { API_URLS } from "shared/constants";
import { useUser } from "shared/hooks";
import useHttpActions from "shared/services/api/core/useHttpActions";

import { BaseConverter } from "shared/services/converters";

import useMediaApiService from "shared/services/useMediaApiService";
import { CategorySort, MediaModel, MediaType } from "shared/types";

import { CategoryProps, EditCategoryProps, NewCategoryProps } from "../types";

interface IGetCategories {
	success: boolean;
	categories: CategoryProps[];
}

interface NewCategoryPropsResponse {
	success: boolean;
	category: CategoryProps;
}

const useCategoriesApiService = () => {
	const actions = useHttpActions();
	const mediaService = useMediaApiService();

	const { getData: getUserData } = useUser();
	const { user } = getUserData();
	const personaId = user?.profiles[0]?.personaId || -1;

	const methods = useMemo(
		() => ({
			prepareParams: async (photo: string) => {
				const defaultQuery = {
					personaId: personaId as number,
					expireAt: "infinite" as number | "infinite"
				};

				const categoryImageMedia = await mediaService.createMedia({
					...defaultQuery,
					type: MediaType.categoryPhoto,
					uri: photo
				});

				return {
					categoryImageMedia
				};
			},
			getCategories: async (
				offset = 1,
				limit = 10,
				keyword = "",
				sortBy?: CategorySort,
				order?: number,
				notEmptyBy?: string
			) => {
				return BaseConverter.convert<IGetCategories>(
					await actions.get(API_URLS.MARKETPLACE.CATEGORIES.BASE, {
						offset,
						limit,
						keyword,
						sortBy,
						order,
						notEmptyBy
					})
				);
			},
			getCategory: async (categoryId: string) => {
				return BaseConverter.convert<IGetCategories>(
					await actions.get(API_URLS.MARKETPLACE.CATEGORIES.BASE, {
						categoryId,
						limit: 1
					})
				);
			},
			getCategoriesCount: async (notEmptyBy?: string) => {
				return BaseConverter.convert<{ totalCount: number }>(
					await actions.get(API_URLS.MARKETPLACE.CATEGORIES.COUNT, {
						notEmptyBy
					})
				);
			},
			createCategory: async (category: NewCategoryProps) => {
				const { categoryImageMedia } = await methods.prepareParams(category.photo);

				return BaseConverter.convert<NewCategoryPropsResponse>(
					await actions.post(API_URLS.MARKETPLACE.CATEGORIES.BASE, {
						...category,
						photo: categoryImageMedia?.length ? categoryImageMedia[0].uri : undefined
					})
				);
			},
			updateCategory: async (category: EditCategoryProps) => {
				let categoryImageMedia: MediaModel[] = [];
				if (category?.photoChanged) {
					const { categoryImageMedia: imageMedia } = await methods.prepareParams(category.photo);
					categoryImageMedia = imageMedia;
				}

				return BaseConverter.convert(
					await actions.put(`${API_URLS.MARKETPLACE.CATEGORIES.BASE}/${category._id}`, {
						name: category.name,
						description: category.description,
						photo: categoryImageMedia?.length ? categoryImageMedia[0].uri : category?.photo
					})
				);
			},
			deleteCategory: async (categoryId: string) => {
				return BaseConverter.convert(await actions.delete(`${API_URLS.MARKETPLACE.CATEGORIES.BASE}/${categoryId}`));
			}
		}),
		[actions, personaId, mediaService]
	);

	return useMemo(
		() => ({
			...methods
		}),
		[methods]
	);
};

export default useCategoriesApiService;
