import React from "react";

import { Skeleton } from "@material-ui/lab";
import { CommunityModel } from "types";

import { ReactComponent as GlobeIcon } from "assets/icons/iconGlobe.svg";
import { ReactComponent as LockIcon } from "assets/icons/iconLock.svg";

import { Card } from "./styles";

interface WorkspaceCardProps {
	item?: CommunityModel;
	skeleton?: boolean;
}

const WorkspaceCard: React.FC<WorkspaceCardProps> = ({ item, skeleton }) => {
	return item && !skeleton ? (
		<Card>
			<Card.Icon style={{ backgroundImage: `url(${item.meta.coverImage || ""})` }}>
				<Card.ProfileIcon style={{ backgroundImage: `url(${item.meta.img || ""})` }} />
			</Card.Icon>
			<Card.Body>
				<Card.Title>{item.meta?.displayName}</Card.Title>
				<Card.Count>+1.1M Members</Card.Count>
				<Card.Description>{item.meta?.description}</Card.Description>
				<Card.Footer>
					<Card.Type>Educator</Card.Type>
					<Card.Private>
						<GlobeIcon />
						<LockIcon />
					</Card.Private>
				</Card.Footer>
			</Card.Body>
		</Card>
	) : (
		<Card>
			<Card.Icon>
				<Skeleton variant="rect" width="100%" height={"100%"} />
				<Card.ProfileIcon>
					<Skeleton variant="circle" width="100%" height="100%" />
				</Card.ProfileIcon>
			</Card.Icon>
			<Card.Body>
				<Skeleton variant="text" width="80%" height={30} />
				<Skeleton variant="text" width="50%" height={30} />
				<Skeleton variant="text" width="100%" height={60} />
				<Card.Footer>
					<Skeleton variant="text" width={65} height={30} />
					<Skeleton variant="text" width={30} height={30} />
				</Card.Footer>
			</Card.Body>
		</Card>
	);
};

export default WorkspaceCard;
