import React from "react";

import { view } from "modules/Community";

import { routes } from "../../constants";

const AboutCommunityPage = () => (
	<view.About
		featurePageUrl={routes.onboarding.community.feature.getPath()}
		themePageUrl={routes.onboarding.community.theme.getPath()}
	/>
);

export default AboutCommunityPage;
