import React, { memo, useCallback, useEffect, useMemo, useState } from "react";

import { MenuItem, Select } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { DatePicker } from "@material-ui/pickers";
import clsx from "clsx";
import country from "country-list-js";
import { DateTime } from "luxon";
import { Controller, useForm, useWatch } from "react-hook-form";
import InputMask from "react-input-mask";
import { useHistory } from "react-router";

import { ReactComponent as Paypal } from "assets/icons/paypal.svg";
import { ReactComponent as Stripe } from "assets/icons/stripe.svg";
import ConfirmLeavePopup from "shared/Components/ConfirmLeave";
import { useCommunity } from "shared/hooks";
import { Text, Tooltip } from "shared/ui-kit";
import { isAlphabetic } from "utils/isAlphabetic";
import { convertToDecimal, numberWithCommas } from "utils/serviceUtils/helpers";
import { validateEmail } from "utils/serviceUtils/validators";

import {
	BalanceAccordion,
	BalanceBlock,
	BalanceText,
	BoldText,
	ButtonWrapper,
	CardWrapper,
	ColumnWrapper,
	Container,
	ErrorText,
	FeesTable,
	FieldWrapper,
	FormWrapper,
	PendingTextWrapper,
	RowWrapper,
	StyledInput,
	StyledSubTitle,
	StyledText,
	StyledTitle,
	TextWrapper
} from "./style";

import { stripeSupportCountries } from "../../../Data/constants";
import { IAmRadioField, MethodRadioField, PayoutCard, Splitter, SubmitButton } from "../../Components";

const Payouts: React.FC = memo(() => {
	const [expanded, setExpanded] = useState(false);
	const [loading, setLoading] = useState(false);
	const [triggerLeaveConfirm, setTriggerLeaveConfirm] = useState(false);
	const [confirmedLeaving, setConfirmedLeaving] = useState(false);
	const [leaveUrl, setLeaveUrl] = useState("");
	const [expandedFees, setExpandedFees] = React.useState(false);

	const { handleSubmit, control, reset, getValues, setValue, formState } = useForm({ mode: "onSubmit" });

	const { updateWorkspaceMeta, withdrawFunds, getWorkspaceDetails, getData: getCommunityData } = useCommunity();
	const { workspace } = getCommunityData();

	const countryList = useMemo(() => country.names().sort(), []);

	const residence = useWatch({ control, name: "residence" });
	const owner = useWatch({ control, name: "owner" });

	const stripeSupport = useMemo(() => {
		return residence && residence !== "placeholder" ? stripeSupportCountries.includes(residence as string) : true;
	}, [residence]);

	useEffect(() => {
		getWorkspaceDetails();
	}, [getWorkspaceDetails]);

	useEffect(() => {
		if (!stripeSupport) {
			setValue("method", "paypal");
		}
	}, [stripeSupport, setValue]);

	const paymentInfo = useMemo(() => {
		const paymentsAdded: { logo: JSX.Element; email?: string }[] = [];

		if (workspace?.meta?.paymentDetails?.stripe) {
			paymentsAdded.push({
				logo: <Stripe className="h-16 w-16" />,
				email: `${workspace?.meta?.paymentDetails.stripe.firstname} ${workspace?.meta?.paymentDetails.stripe.lastname}`
			});
		}

		if (workspace?.meta?.paymentDetails?.paypal) {
			paymentsAdded.push({
				logo: <Paypal className="h-6" />,
				email: workspace?.meta?.paymentDetails.paypal.email
			});
		}

		return paymentsAdded;
	}, [workspace]);

	useEffect(() => {
		if (workspace?.meta?.paymentDetails) {
			reset({
				owner: workspace?.meta?.paymentDetails.isIndividual ? "INDIVIDUAL" : "BUSINESS",
				residence: workspace?.meta?.paymentDetails.countryResidence,
				paypal: workspace?.meta?.paymentDetails.paypal
					? {
							...workspace?.meta?.paymentDetails.paypal,
							confirmEmail: workspace?.meta?.paymentDetails.paypal.email
					  }
					: {},
				stripe: workspace?.meta?.paymentDetails.stripe
					? {
							...workspace?.meta?.paymentDetails.stripe,
							dob: workspace?.meta?.paymentDetails.stripe?.dob
								? new Date(workspace?.meta?.paymentDetails.stripe?.dob)
								: null
					  }
					: {},
				method: workspace?.meta?.paymentDetails.choosenMethod
			});
		}
	}, [workspace, reset]);

	const history = useHistory();

	const { isDirty, errors } = formState;

	useEffect(() => {
		const unblock = history.block((ts: any) => {
			setLeaveUrl(ts.pathname as string);

			if (expanded && isDirty) {
				setTriggerLeaveConfirm(true);
				return false;
			}
		});

		if (confirmedLeaving && leaveUrl) {
			unblock();
			history.push(leaveUrl);
		}

		return () => {
			unblock();
		};
	}, [history, expanded, confirmedLeaving, leaveUrl, formState, isDirty]);

	const onSubmit = useCallback(
		async data => {
			await updateWorkspaceMeta({
				meta: {
					paymentDetails: {
						isIndividual: data.owner === "INDIVIDUAL",
						countryResidence: data.residence,
						paypal: data?.paypal || workspace?.meta?.paymentDetails?.paypal,
						stripe: data?.stripe || workspace?.meta?.paymentDetails?.stripe,
						choosenMethod: data.method
					}
				}
			});

			setExpanded(false);
		},
		[workspace, updateWorkspaceMeta]
	);

	const onWithdraw = useCallback(async () => {
		setLoading(true);
		await withdrawFunds();
		await getWorkspaceDetails();
		setLoading(false);
	}, [withdrawFunds, getWorkspaceDetails]);

	const body = !expanded ? (
		!!paymentInfo.length ? (
			<div className="flex items-center gap-2 justify-between w-full">
				<div className="flex items-center gap-2 flex-wrap">
					{paymentInfo.map((payment, index) => (
						<div key={index} className="flex items-center gap-4">
							{payment.logo}
							{payment.email && (
								<TextWrapper>
									<StyledText>{payment.email}</StyledText>
								</TextWrapper>
							)}
						</div>
					))}
				</div>
				<SubmitButton onClick={() => setExpanded(true)} disabled={false}>
					Change
				</SubmitButton>
			</div>
		) : (
			<>
				<TextWrapper>
					<StyledText>Add Payout Method to withdraw your balance.</StyledText>
				</TextWrapper>
				<SubmitButton onClick={() => setExpanded(true)} disabled={false}>
					Add Method
				</SubmitButton>
			</>
		)
	) : (
		<FormWrapper onSubmit={handleSubmit(onSubmit)}>
			<BoldText>I am</BoldText>
			<Controller
				name="owner"
				control={control}
				rules={{ required: "Field is required." }}
				render={({ onChange, value }) => <IAmRadioField value={value} onChange={onChange} />}
			/>
			{errors?.owner?.message && <ErrorText>{errors?.owner?.message}</ErrorText>}
			<ErrorText />
			<Splitter />
			<BoldText>Country of residence</BoldText>
			<Controller
				name="residence"
				control={control}
				style={{ minWidth: 440 }}
				as={Select}
				defaultValue={"placeholder"}
				variant="outlined"
				rules={{
					required: { value: true, message: "Country of residence is required." },
					validate: value => value !== "placeholder"
				}}
				IconComponent={() => <></>}
			>
				<MenuItem className="menuItem" value="placeholder" disabled>
					<Text>Select your country of residence</Text>
				</MenuItem>
				{countryList.map((item, index) => (
					<MenuItem key={index} className="menuItem" value={item}>
						{item}
					</MenuItem>
				))}
			</Controller>
			{errors?.residence && <ErrorText>{"Country of residence is required"}</ErrorText>}

			<Splitter />
			<BoldText>Choose method</BoldText>
			<Controller
				name="method"
				control={control}
				rules={{ required: "Field is required." }}
				render={({ onChange, value }) => (
					<MethodRadioField value={value} onChange={onChange} stripeSupport={stripeSupport}>
						<Splitter />
						{/* method form */}
						{value === "stripe" ? (
							<FieldWrapper>
								<RowWrapper>
									<ColumnWrapper half>
										<StyledSubTitle>First Name</StyledSubTitle>
										<Controller
											name="stripe.firstname"
											control={control}
											rules={{
												required: "Field is required.",
												minLength: { value: 1, message: "First name is too short" }
											}}
											render={({ onChange, value }) => (
												<StyledInput
													placeholder="Input First Name"
													value={value}
													onChange={e => onChange(e.target.value.replace(/([^a-zA-Z '])/g, ""))}
													errorText={errors.stripe?.firstname?.message}
												/>
											)}
										/>
									</ColumnWrapper>
									<ColumnWrapper half>
										<StyledSubTitle>Last Name</StyledSubTitle>
										<Controller
											name="stripe.lastname"
											control={control}
											rules={{
												required: "Field is required.",
												minLength: { value: 1, message: "Last name is too short" }
											}}
											render={({ onChange, value }) => (
												<StyledInput
													placeholder="Input Last Name"
													value={value}
													onChange={e => onChange(e.target.value.replace(/([^a-zA-Z '])/g, ""))}
													errorText={errors.stripe?.lastname?.message}
												/>
											)}
										/>
									</ColumnWrapper>
								</RowWrapper>
								<StyledSubTitle>Street Address 1</StyledSubTitle>
								<Controller
									name="stripe.address"
									control={control}
									rules={{ required: "Field is required.", minLength: { value: 2, message: "Address is too short" } }}
									render={({ onChange, value }) => (
										<StyledInput
											placeholder="Input Street Address 1"
											value={value}
											onChange={e => onChange(e.target.value.slice(0, 25))}
											errorText={errors.stripe?.address?.message}
										/>
									)}
								/>
								<StyledSubTitle>Street Address 2 ( Optional )</StyledSubTitle>
								<Controller
									name="stripe.address2"
									control={control}
									render={({ onChange, value }) => (
										<StyledInput placeholder="Input Street Address 2" value={value} onChange={onChange} />
									)}
								/>
								<StyledSubTitle>City</StyledSubTitle>
								<Controller
									name="stripe.city"
									control={control}
									rules={{ required: "Field is required.", minLength: { value: 2, message: "City name is too short" } }}
									render={({ onChange, value }) => (
										<StyledInput
											placeholder="Input City"
											value={value}
											onChange={e =>
												isAlphabetic(e.target.value) &&
												onChange(e.target.value.replace(/([^a-zA-Z '])/g, "").slice(0, 25))
											}
											errorText={errors.stripe?.city?.message}
										/>
									)}
								/>
								<RowWrapper>
									<ColumnWrapper half>
										<StyledSubTitle>State</StyledSubTitle>
										<Controller
											name="stripe.state"
											control={control}
											rules={{ required: "Field is required.", minLength: { value: 2, message: "State is too short" } }}
											render={({ onChange, value }) => (
												<StyledInput
													placeholder="Select State"
													value={value}
													onChange={e =>
														isAlphabetic(e.target.value) &&
														onChange(e.target.value.replace(/([^a-zA-Z '])/g, "").slice(0, 25))
													}
													errorText={errors.stripe?.state?.message}
												/>
											)}
										/>
									</ColumnWrapper>
									<ColumnWrapper half>
										<StyledSubTitle>Zip</StyledSubTitle>
										<Controller
											name="stripe.zip"
											control={control}
											pattern={/^[0-9]{5}(?:-[0-9]{4})?$/i}
											rules={{ required: "Field is required.", minLength: { value: 5, message: "Invalid ZIP code" } }}
											render={({ onChange, value }) => (
												<StyledInput
													placeholder="Input Zip Code"
													value={value}
													onChange={e => onChange(e.target.value.replace(/([^0-9])/g, "").slice(0, 9))}
													errorText={errors.stripe?.zip?.message}
												/>
											)}
										/>
									</ColumnWrapper>
								</RowWrapper>
								<StyledSubTitle>Date of Birth</StyledSubTitle>
								<Controller
									name="stripe.dob"
									control={control}
									rules={{
										required: "Field is required.",
										validate: value => DateTime.fromJSDate(value).isValid || "Incorrect date"
									}}
									render={({ onChange, value = null }) => (
										<DatePicker
											format="dd / MM / yyyy"
											placeholder="DD / MM / YYYY"
											disableFuture={true}
											autoOk
											inputVariant="outlined"
											variant="inline"
											value={value}
											onChange={d => onChange(d)}
											error={errors.stripe?.dob?.message}
											helperText={errors.stripe?.dob?.message}
										/>
									)}
								/>
								{owner === "INDIVIDUAL" && (
									<>
										<StyledSubTitle>Social Security Number (SSN)</StyledSubTitle>
										<Controller
											name="stripe.ssn"
											control={control}
											rules={{
												required: "Field is required.",
												validate: val => {
													return val.replace(/\D/g, "").length === 9 ? true : "At least 9 characters are required";
												}
											}}
											render={({ onChange, value }) => (
												<InputMask mask="999-99-9999" value={value} onChange={e => onChange(e.target.value)}>
													{() => <StyledInput placeholder="Input SSN" errorText={errors.stripe?.ssn?.message} />}
												</InputMask>
											)}
										/>
									</>
								)}
								<StyledSubTitle>Bank Routing Number</StyledSubTitle>
								<Controller
									name="stripe.bankRoutingNumber"
									control={control}
									rules={{
										required: "Field is required.",
										minLength: { value: 9, message: "Routing number is too short" }
									}}
									render={({ onChange, value }) => (
										<StyledInput
											placeholder="Input Bank Routing Number"
											value={value}
											onChange={e => onChange(e.target.value.replace(/\D/g, "").slice(0, 9))}
											errorText={errors.stripe?.bankRoutingNumber?.message}
										/>
									)}
								/>
								<StyledSubTitle>Bank Account Number</StyledSubTitle>
								<Controller
									name="stripe.bankAccountNumber"
									control={control}
									rules={{
										required: "Field is required.",
										minLength: { value: 17, message: "Bank number is too short" }
									}}
									render={({ onChange, value }) => (
										<StyledInput
											placeholder="Input Bank Account Number"
											value={value}
											onChange={e => onChange(e.target.value.replace(/\D/g, "").slice(0, 17))}
											errorText={errors.stripe?.bankAccountNumber?.message}
										/>
									)}
								/>
								{owner === "BUSINESS" && (
									<>
										<StyledSubTitle>Corporate EIN ( Optional )</StyledSubTitle>
										<Controller
											name="stripe.corporateEin"
											control={control}
											render={({ onChange, value }) => (
												<StyledInput
													placeholder="Input Corporate EIN"
													value={value}
													onChange={onChange}
													errorText={errors.stripe?.corporateEin?.message}
												/>
											)}
										/>
										<StyledSubTitle>Corporation Name</StyledSubTitle>
										<Controller
											name="stripe.corporationName"
											control={control}
											rules={{
												required: "Field is required.",
												minLength: { value: 2, message: "Corporation name is too short" }
											}}
											render={({ onChange, value }) => (
												<StyledInput
													placeholder="Input Corporation Name"
													value={value}
													onChange={e => onChange(e.target.value.slice(0, 50))}
													errorText={errors.stripe?.corporationName?.message}
												/>
											)}
										/>
									</>
								)}
							</FieldWrapper>
						) : (
							<FieldWrapper>
								<StyledSubTitle>PayPal Email</StyledSubTitle>
								<Controller
									name="paypal.email"
									control={control}
									rules={{
										required: "Field is required.",
										validate: value => validateEmail(value) || "Invalid email"
									}}
									render={({ onChange, value }) => (
										<StyledInput
											placeholder="Input PayPal Email"
											value={value}
											onChange={onChange}
											errorText={errors.paypal?.email?.message}
										/>
									)}
								/>
								<StyledSubTitle>Confirm PayPal Email</StyledSubTitle>
								<Controller
									name="paypal.confirmEmail"
									control={control}
									rules={{
										required: "Field is required.",
										validate: value => {
											if (value !== getValues("paypal.email")) {
												return "Emails are not equal";
											}
											return validateEmail(value) || "Invalid email";
										}
									}}
									render={({ onChange, value }) => (
										<StyledInput
											placeholder="Input PayPal Email"
											value={value}
											onChange={onChange}
											errorText={errors.paypal?.confirmEmail?.message}
										/>
									)}
								/>
							</FieldWrapper>
						)}
					</MethodRadioField>
				)}
			/>
			<ButtonWrapper>
				<SubmitButton type="submit" disabled={false}>
					Add Method
				</SubmitButton>
			</ButtonWrapper>
		</FormWrapper>
	);

	const handleClose = () => {
		setTriggerLeaveConfirm(false);
	};

	const handleLeavePage = () => {
		setConfirmedLeaving(true);
		handleClose();
	};

	return (
		<Container>
			<ConfirmLeavePopup handleLeavePage={handleLeavePage} open={triggerLeaveConfirm} onClose={handleClose} />
			<StyledTitle variant="h3">Payouts</StyledTitle>
			<CardWrapper>
				<PayoutCard title="Balance">
					<BalanceAccordion square expanded={expandedFees}>
						<BalanceAccordion.Summary>
							<div className="w-full flex justify-between items-center">
								<BalanceBlock onClick={() => setExpandedFees(!expandedFees)}>
									<TextWrapper>
										<StyledText>Your current balance is</StyledText>
										<BalanceText>${numberWithCommas(convertToDecimal(workspace?.availableBalance))}</BalanceText>
										<BalanceAccordion.ArrowIconWrapper
											className={clsx(expandedFees && "transform rotate-180", "-translate-y-1")}
										>
											<ExpandMoreIcon />
										</BalanceAccordion.ArrowIconWrapper>
									</TextWrapper>
									{workspace?.havePendingWithdraw?.status === "pending" && (
										<PendingTextWrapper>
											You have one pending withdraw of amount $
											{numberWithCommas(convertToDecimal(workspace?.havePendingWithdraw?.amount))}
										</PendingTextWrapper>
									)}
								</BalanceBlock>
								<Tooltip text={!workspace?.meta?.paymentDetails ? "Add your payment method first" : ""}>
									<SubmitButton
										onClick={onWithdraw}
										disabled={
											loading ||
											!Number(workspace?.availableBalance) ||
											!!workspace?.havePendingWithdraw?.amount ||
											!workspace?.meta?.paymentDetails
										}
									>
										Withdraw
									</SubmitButton>
								</Tooltip>
							</div>
						</BalanceAccordion.Summary>
						<BalanceAccordion.Details>
							<FeesTable className="mt-6 w-full">
								<div className="px-4">
									<FeesTable.Row>
										<FeesTable.Text>From Donation</FeesTable.Text>
										<FeesTable.Text>
											$
											{numberWithCommas(
												convertToDecimal(
													(workspace?.availableBalance || 0) +
														(workspace?.availableVyooCommission || 0) +
														(workspace?.availableProcessorCommission || 0)
												)
											)}
										</FeesTable.Text>
									</FeesTable.Row>
									<FeesTable.Row>
										<FeesTable.Text>Vyoo Service Fee</FeesTable.Text>
										<FeesTable.Text>
											- ${numberWithCommas(convertToDecimal(workspace?.availableVyooCommission || 0))}
										</FeesTable.Text>
									</FeesTable.Row>
									<FeesTable.Row>
										<FeesTable.Text>Stripe Fee</FeesTable.Text>
										<FeesTable.Text>
											- ${numberWithCommas(convertToDecimal(workspace?.availableProcessorCommission || 0))}
										</FeesTable.Text>
									</FeesTable.Row>
								</div>
								<FeesTable.Footer>
									<FeesTable.Text>{"You'll Receive"}</FeesTable.Text>
									<FeesTable.Text>${numberWithCommas(convertToDecimal(workspace?.availableBalance))}</FeesTable.Text>
								</FeesTable.Footer>
							</FeesTable>
						</BalanceAccordion.Details>
					</BalanceAccordion>
				</PayoutCard>
			</CardWrapper>
			<CardWrapper>
				<PayoutCard title="Payment Details">{body}</PayoutCard>
			</CardWrapper>
		</Container>
	);
});

export default Payouts;
