import React from "react";

import AboutBlock from "./About";
import CoverImageBlock from "./CoverImage";
import SocialBlock from "./Social";

import Splitter from "../Splitter";

const CommunityPage = () => {
	return (
		<>
			<CoverImageBlock />
			<Splitter />
			<AboutBlock />
			<Splitter />
			<SocialBlock />
		</>
	);
};

export default CommunityPage;
