import styled from "styled-components";

import { Box } from "shared/ui-kit";

import * as appTheme from "theme/default";

export const MonetizeCards = styled(Box)`
	max-width: 1050px;

	${props => props.theme.breakpoints.up(appTheme.customBreakpoints.surfaceDuo)} {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-gap: 12px;
	}

	${props => props.theme.breakpoints.up("lg")} {
		grid-template-columns: repeat(3, 1fr);
		grid-gap: 24px;
	}
`;
