interface StatItemCommon {
	_id: string;
	date: string;
}

export type StatItem<K extends string> = StatItemCommon &
	Partial<{
		[key in K]: number;
	}>;

export enum StatsItemKey {
	users = "users",
	signups = "signups",
	activeUsers = "active",
	abandonedSignups = "abandonedSignups",
	sales = "sales",
	revenueReceived = "revenueReceived"
}
