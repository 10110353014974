import React, { ReactNode } from "react";

import styled, { css } from "styled-components";

import { ContentBox } from "shared/ui-kit";

import { customBreakpoints } from "theme/default";

const InfoForm = styled(ContentBox)<FormBlockProps>`
	height: fit-content;
	width: 560px;
	margin: 0;
	padding: 40px 80px;

	${props => props.theme.breakpoints.down(customBreakpoints.tabletVerticalMax)} {
		width: 512px;
		padding: 40px 55px;
	}

	${props => props.theme.breakpoints.down("xs")} {
		width: 382px;
		max-width: 93%;

		padding: 24px 16px;
	}

	${({ customStyle }) => {
		return customStyle
			? css`
					${customStyle}
			  `
			: "";
	}}
`;

interface FormBlockProps {
	children: ReactNode;
}

const FormBlock: React.FC<FormBlockProps> = ({ children, ...props }) => <InfoForm {...props}>{children}</InfoForm>;

export default FormBlock;
