import { Box } from "@material-ui/core";
import styled, { css } from "styled-components";

export const NotificationWrapper = styled(Box)`
	width: 320px;
	position: fixed;
	bottom: 32px;
	left: 24px;
	border-radius: 8px;
	box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.08);
	box-shadow: inset 0 0 0 0.5px #c5cee0;
	background-color: #fff;
	z-index: 9999999;
	padding: 16px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	transform: translateY(-40px);
	opacity: 0;
	transition: 0.3s all ease-in-out;
	pointer-events: none;
	p {
		margin: 0 8px;
		line-height: auto;
	}
	button {
		padding: 0;
	}

	${({ show }) =>
		show &&
		css`
			transform: translateY(0);
			opacity: 1;
			pointer-events: auto;
		`}
`;
