import React from "react";

import CircularProgress from "@material-ui/core/CircularProgress";
import { Controller, SubmitHandler, useForm } from "react-hook-form";

import { useNotification, useUser } from "shared/hooks";

import { ReportBugAttachments } from "./ReportBugAttachments";
import { Actions, BugDescription, FormField, FormLabel, SubmitButton, Wrapper } from "./styles";

interface FormValues {
	bugDescription: string;
	attachments: { fileName?: string; publicUrl: string }[];
}

const defaultFormValues: FormValues = {
	bugDescription: "",
	attachments: []
};

export function ReportBug() {
	const {
		control,
		handleSubmit,
		reset,
		formState: { isDirty, isSubmitting }
	} = useForm<FormValues>({
		defaultValues: defaultFormValues
	});

	const { reportBug, getData } = useUser();
	const { user } = getData();
	const { showMessage } = useNotification();

	const onSubmit: SubmitHandler<FormValues> = async data => {
		const photos = data.attachments.map(({ publicUrl }) => ({ uri: publicUrl }));
		if (user?.activeProfile) {
			await reportBug({
				personaId: user.activeProfile,
				message: data.bugDescription,
				photos
			});
		}
		showMessage("The bug report was successfully sent");
		reset({ ...defaultFormValues });
	};

	return (
		<Wrapper>
			<form onSubmit={handleSubmit(onSubmit)}>
				<Controller
					name="bugDescription"
					control={control}
					render={({ value, onChange, name }) => (
						<FormField>
							<FormLabel>Bug Description</FormLabel>
							<BugDescription name={name} value={value} onChange={onChange} multiline disableUnderline rows={10} />
						</FormField>
					)}
				/>
				<Controller
					name="attachments"
					control={control}
					render={({ onChange, value }) => (
						<FormField>
							<FormLabel>Include Attachments</FormLabel>
							<ReportBugAttachments onChange={onChange} value={value} />
						</FormField>
					)}
				/>
				<Actions>
					<SubmitButton variant="contained" type="submit" disabled={!isDirty}>
						{isSubmitting ? <CircularProgress size={16} /> : "Send"}
					</SubmitButton>
				</Actions>
			</form>
		</Wrapper>
	);
}
