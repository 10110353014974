import React, { useCallback } from "react";

import { Box } from "@material-ui/core";

import styled, { css } from "styled-components";

import { useEngageNotifications } from "modules/Engage/Data";
import { Dialog } from "shared/Components";
import { Button, Text } from "shared/ui-kit";

export const CTAButton = styled(Button)<{ wide?: boolean }>`
	margin: 0;
	padding: ${({ wide }) => (wide ? "18px 36px" : "18px")};
`;

export const ContentWrapper = styled(Box)`
	padding: 0 32px;
`;

export const DialogFooter = styled(Box)`
	border-top: 1px solid #edf1f7;
	text-align: right;
	padding: 16px 32px;
	margin-top: ${({ mt }) => (mt ? mt + "px" : "40px")};
	button {
		margin-left: 12px;
	}
`;

export const DeleteText = styled(Text)`
	font-size: 15px;
	color: #8f9bb3;
	margin-top: 20px;
`;

const DeleteNotificationDialog = () => {
	const { setDeleteDialog, deleteNotification, getData: getEngageNotificationData } = useEngageNotifications();
	const { deleteNotificationDialog } = getEngageNotificationData();

	const handleClose = useCallback(() => setDeleteDialog({ data: undefined }), [setDeleteDialog]);

	return (
		<Dialog
			bodyCustomStyles={css`
				padding: 0 !important;
			`}
			title={
				<Text align="left" variant="h6">
					Delete Notification?
				</Text>
			}
			open={!!deleteNotificationDialog}
			onClose={handleClose}
			hasBackButton={false}
		>
			<ContentWrapper>
				<DeleteText>Are you sure you want to permanently delete {deleteNotificationDialog?.title}?</DeleteText>
			</ContentWrapper>
			<DialogFooter mt={20}>
				<CTAButton onClick={handleClose} buttonTheme="outline" wide>
					Keep
				</CTAButton>
				{deleteNotificationDialog && (
					<CTAButton
						id="deleteConfirmation"
						wide
						onClick={() => deleteNotification(deleteNotificationDialog._id as string)}
					>
						Delete
					</CTAButton>
				)}
			</DialogFooter>
		</Dialog>
	);
};

export default DeleteNotificationDialog;
