export enum PostBlockEventType {
	addComment = "addComment",
	updateComment = "updateComment",
	delete = "Delete",
	report = "Report",
	edit = "Edit",
	trackStart = "trackStart",
	trackEnd = "TrackEnd",
	youtubeClick = "YoutubeClick",
	uploadingProgressStart = "UploadingProgressStart",
	uploadingProgressFinished = "UploadingProgressFinished"
}
