export const getDuration = (startAt, endAt): string | null => {
	const diffTime = Math.abs(endAt - startAt);
	const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
	const diffYears = Math.floor(diffDays / 365);
	const diffMonths = Math.floor(diffDays / 30) - diffYears * 12;

	if (diffYears === 0 && diffMonths === 0) return null;

	return `${diffYears > 0 ? `${diffYears} years` : ""}${diffMonths > 0 ? `, ${diffMonths} months` : ""}`;
};
