import { Box } from "@material-ui/core";
import styled from "styled-components";

export const BoardWrapper = styled(Box)`
	padding: 0 16px;
	${props => props.theme.breakpoints.up("sm")} {
		display: flex;
		align-items: flex-start;
		justify-content: center;
	}
	${props => props.theme.breakpoints.up("md")} {
		padding: 0;
	}
`;

BoardWrapper.SidePanel = styled(Box)`
	width: 100%;
	max-width: 320px;
	> div {
		margin-bottom: 16px;
	}
`;

BoardWrapper.PageContent = styled(Box)`
	margin-right: 16px;
	width: 100%;
	max-width: 568px;
`;

BoardWrapper.PageHeader = styled(Box)`
	padding: 12px 16px 16px;
	border-radius: 12px;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
	box-shadow: inset 0 0 0 0.5px #c5cee0;
	background-color: #fff;
	margin-bottom: 16px;
	.icon-wrapper {
		flex-shrink: 0;
		margin-right: 12px;
		width: 56px;
		height: 56px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 28px;
		background-color: #eff2f5;
		svg {
			width: 32px;
			height: 32px;
			fill: #6173fe;
		}
	}
	h4 {
		font-size: 22px;
		font-weight: bold;
		line-height: 1.45;
		color: #222b45;
	}
	p {
		font-size: 13px;
		font-weight: 600;
		line-height: 1.85;
		color: #8f9bb3;
		margin: 0;
	}
	button {
		width: 100%;
		height: 40px;
		border-radius: 8px;
		border: solid 0.5px rgba(97, 115, 254, 0.24);
		margin-top: 16px;
	}
	${props => props.theme.breakpoints.up("sm")} {
		display: flex;
		align-items: center;
		justify-content: space-between;
		button {
			width: 104px;
			margin-top: 0;
		}
	}
`;

BoardWrapper.PostWrapper = styled(Box)`
	margin-bottom: 20px;
`;
