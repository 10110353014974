import React, { Suspense } from "react";

import "./wdyr";
import ReactDOM from "react-dom";

import configureApp from "./core/configureApp";
import generateRoutes from "./core/routes";
import * as serviceWorker from "./serviceWorker";

import "./i18n";

const { apps } = configureApp();

const Loading: React.FC = () => <span>Loading...</span>;

ReactDOM.render(
	// <React.StrictMode>
	<Suspense fallback={<Loading />}>{generateRoutes(apps)}</Suspense>,
	// </React.StrictMode>,
	document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
