import React, { FC, useState } from "react";

import { Box } from "@material-ui/core";

import clsx from "clsx";
import { Control, Controller, DeepMap, FieldArrayDefaultValues, FieldError, FieldValues } from "react-hook-form";

import { ReactComponent as LockIcon } from "assets/icons/icon-lock.svg";
import { ReactComponent as MastercardIcon } from "assets/icons/mastercard.svg";
import { ReactComponent as VisaIcon } from "assets/icons/visa.svg";
import AmericanExpress from "assets/images/american-express.png";

import { useStripe } from "shared/hooks";
import { Text } from "shared/ui-kit";

import { isEmail } from "shared/ui-kit/utils";

import { validatePhoneNumber } from "utils/serviceUtils/validators";

import { CCDetailsWrapper } from "./style";

import CVVInput from "../CVVInput";
import CreditCardInput from "../CreditCardInput";
import ExpirationDateInput from "../ExpirationDateInput";
import LabeledInput from "../LabeledInput";
import { CardOption, SelectACardWrapper } from "../SubscribeDialog/style";
import { InputWrapper } from "../style";

const CCDetails: FC<{
	control: Control<FieldValues>;
	errors: DeepMap<FieldArrayDefaultValues, FieldError>;
	currentStep?: boolean;
	includePersonalInfo?: boolean;
	askForCard?: boolean;
}> = ({ control, errors, currentStep, includePersonalInfo, askForCard }) => {
	const { setChosenCard, getData: getStripeData } = useStripe();
	const { cards, chosenCard } = getStripeData();

	const [newCardView, setNewCardView] = useState(false);

	return (
		<>
			{!askForCard && cards.length && !newCardView ? (
				<SelectACardWrapper>
					{cards.map(card => (
						<CardOption active={chosenCard?.id === card.id} onClick={() => setChosenCard(card)} key={card.id}>
							{card.brand === "Visa" && <VisaIcon />}
							{card.brand === "MasterCard" && <MastercardIcon />}
							{card.brand === "American Express" && <img src={AmericanExpress} alt="American Express" />}

							<Text>•••• {card.last4}</Text>
						</CardOption>
					))}
					<CardOption
						onClick={() => {
							setNewCardView(true);
							setChosenCard();
						}}
					>
						Add a payment method
					</CardOption>
				</SelectACardWrapper>
			) : (
				<CCDetailsWrapper className={clsx(includePersonalInfo && "pt-6")}>
					{includePersonalInfo && (
						<div className="flex flex-col sm:flex-row sm:items-center gap-4 mb-8">
							<Controller
								name="fullName"
								control={control}
								rules={
									currentStep
										? {
												required: "Name is required",
												minLength: { value: 2, message: "At least 2 characters long." },
												maxLength: { value: 40, message: "Must be 40 characters or less." }
										  }
										: undefined
								}
								render={({ onChange, value }) => (
									<LabeledInput
										name="name"
										label="Name visible for public *"
										placeholder="Full name"
										onChange={onChange}
										value={value}
										error={errors?.fullName?.message}
										outerWrapperClassName="flex-1"
									/>
								)}
							/>
							<Controller
								name="contactDetails"
								control={control}
								rules={
									currentStep
										? {
												required: "Email or number is required",
												validate: val => {
													if (!isNaN(val) && (val.length < 10 || val.length > 15)) {
														return "Phone number should be between 10 - 15 numbers";
													}
													return validatePhoneNumber(val) || isEmail(val) ? true : "Please enter valid email or number";
												}
										  }
										: undefined
								}
								render={({ onChange, value }) => (
									<LabeledInput
										name="name"
										label="Email or phone number *"
										placeholder="Email or phone number"
										onChange={onChange}
										value={value}
										error={errors?.contactDetails?.message}
										outerWrapperClassName="flex-1"
									/>
								)}
							/>
						</div>
					)}
					<Box className="accepted-cards">
						<VisaIcon />
						<MastercardIcon />
						<img src={AmericanExpress} alt="american express" />
					</Box>
					<InputWrapper>
						<Controller
							name="cardNumber"
							rules={
								currentStep && (askForCard || newCardView)
									? {
											required: "Card number is required",
											minLength: { value: 16, message: "Invalid Card Number." },
											maxLength: { value: 19, message: "Invalid Card Number." }
									  }
									: undefined
							}
							control={control}
							render={({ onChange, value }) => (
								<CreditCardInput
									label="Card Number"
									onChange={onChange}
									value={value}
									placeholder="1234 5678 9012 3456"
									name="cardNumber"
									rightIcon={{
										el: <LockIcon />,
										active: true
									}}
									error={errors?.cardNumber?.message}
								/>
							)}
						/>
					</InputWrapper>
					<Box className="two-inputs-row">
						<InputWrapper width="48%">
							<Controller
								name="expiration"
								rules={
									currentStep && (askForCard || newCardView)
										? {
												required: "Expiration is required",
												validate: val => {
													const valArray = val.split("/");
													const month = valArray[0];
													const year = valArray[1];

													const currentYear = `${new Date().getFullYear()}`.slice(2);

													const isOldDate =
														year < currentYear || (year === currentYear && month < new Date().getMonth() + 1);

													if (!month || !year || parseInt(month) > 12 || isOldDate) {
														return "Invalid Expiration Date.";
													}

													return true;
												}
										  }
										: undefined
								}
								control={control}
								render={({ onChange, value }) => (
									<ExpirationDateInput
										label="Expiration"
										onChange={onChange}
										value={value}
										placeholder="01/25"
										name="expiration"
										error={errors?.expiration?.message}
									/>
								)}
							/>
						</InputWrapper>
						<InputWrapper width="48%">
							<Controller
								name="cvv"
								rules={
									currentStep && (askForCard || newCardView)
										? {
												required: "CVV is required",
												maxLength: { value: 4, message: "Invalid CVV Number." }
										  }
										: undefined
								}
								control={control}
								render={({ onChange, value }) => (
									<CVVInput
										label="CVV"
										onChange={onChange}
										value={value}
										placeholder="123"
										name="cvv"
										error={errors?.cvv?.message}
									/>
								)}
							/>
						</InputWrapper>
					</Box>
					<Text variant="body2" className="text-gray-500 text-center">
						Charge will appear as Vyoo.me on your bill
					</Text>
				</CCDetailsWrapper>
			)}
		</>
	);
};

export default CCDetails;
