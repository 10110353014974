import React, { FC, useMemo } from "react";

import { ReactComponent as FacebookIcon } from "assets/icons/facebook.svg";
import { ReactComponent as LinkedInIcon } from "assets/icons/linkedin-rounded.svg";
import { ReactComponent as TwitterIcon } from "assets/icons/twitter.svg";
import { MemberDialog } from "modules/MemberHome/View/shared";
import { useNotification } from "shared/hooks";
import { Button, Icon, Text } from "shared/ui-kit";

interface ShareModalProps {
	title?: string;
	onClose: () => void;
	url?: string;
}

const ShareModal: FC<ShareModalProps> = ({ title, onClose, url = document.location.href }) => {
	const { showMessage } = useNotification();

	const shareOpts = useMemo(
		() => [
			{
				title: "Facebook",
				icon: <FacebookIcon className="w-5 h-5 mr-2" />,
				href: "https://www.facebook.com/sharer/sharer.php?u="
			},
			{
				title: "Twitter",
				icon: <TwitterIcon className="w-5 h-5 mr-2" />,
				href: "https://twitter.com/intent/tweet?url="
			},
			{
				title: "LinkedIn",
				icon: <LinkedInIcon className="w-5 h-5 mr-2" />,
				href: "https://www.linkedin.com/shareArticle?url="
			},
			{
				title: "Email",
				icon: <Icon name="email" width={20} height={20} fill="#222b45" className="mr-2" />,
				href: "mailto:?body="
			}
		],
		[]
	);

	const handleCopyLink = () => {
		navigator.clipboard.writeText(url);
		showMessage("Link copied to clipboard");
	};

	return (
		<MemberDialog open onClose={onClose} title={title || "Share"} noFooter customWidth={420}>
			<div className="p-4">
				<div className="grid grid-cols-2 gap-3">
					{shareOpts.map((opt, i) => (
						<a
							href={opt.href + url}
							target="_blank"
							rel="noreferrer"
							key={i}
							className="p-3 flex items-center justify-center text-sm border rounded-lg hover:bg-gray-100"
						>
							{opt.icon}
							{opt.title}
						</a>
					))}
				</div>
				<div className="w-full flex justify-between gap-2 items-center mt-4 p-3 bg-gray-100 rounded-lg">
					<Text className="flex-1 truncate">{url}</Text>
					<Button size="small" className="m-0 p-3" onClick={handleCopyLink}>
						Copy link
					</Button>
				</div>
			</div>
		</MemberDialog>
	);
};

export default ShareModal;
