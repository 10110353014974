import { Box, ButtonBase, Radio } from "@material-ui/core";
import styled, { css } from "styled-components";

import { Checkbox } from "shared/ui-kit";

export const Wrapper = styled.button`
	background-color: red;
	border-radius: 8px;
	display: flex;
	align-items: center;
	background-color: #f7f9fc;
	border: 1px solid #e4e9f2;
	padding: 6px;
	padding-right: 4px;
	cursor: pointer;
	p {
		margin-right: 12px;
		font-size: 13px;
		font-weight: 600;
		color: #222b45;
	}
`;

export const ButtonGroupWrapper = styled(Box)`
	padding: 2px;
	border-radius: 4px;
	border: solid 0.5px #c6cfe0;
	background-color: #f7f9fc;
	width: 100%;
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-gap: 4px;
	margin-bottom: 16px;
`;

export const ButtonGroupButton = styled(ButtonBase)`
	font-size: 13px;
	line-height: 1.23;
	letter-spacing: normal;
	color: #222b45;
	padding: 8px 0;
	${({ active }) =>
		active &&
		css`
			border-radius: 3px;
			border: solid 0.5px rgba(198, 207, 224, 0.4);
			background-color: #fff;
			box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
		`}
`;

export const CustomRow = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 10px 0;
	border-radius: 8px;
	padding: 10px;
	${({ isSubGroup }) =>
		isSubGroup &&
		css`
			height: 64px;
		`}
	&:hover,
	&.active {
		background-color: #f7f9fc;
		.image-container {
			border: 4px solid #f7f9fc;
		}
	}
	.sub-item {
		padding-left: 20px;
		&:before {
			content: "";
			width: 30px;
			height: 84px;
			border-radius: 0 0 0 12px;
			top: -60px;
			position: relative;
			border-left: 3px solid #e4e9f2;
			border-bottom: 3px solid #e4e9f2;
		}
	}
	.info {
		height: 100%;
		display: flex;
	}
	.description {
		font-size: 13px;
		color: #8f9bb3;
		font-weight: 600;
	}
`;

export const CustomRadio = styled(Radio)`
	color: #e4e9f2;
`;

export const CustomCheckbox = styled(Checkbox)`
	color: #e4e9f2;
`;

export const Image = styled.img`
	width: 40px;
	height: 40px;
`;

export const ImageContainer = styled(Box)`
	width: 40px;
	height: 40px;
	overflow: hidden;
	border-radius: 8px;
	margin-right: 20px;
	svg {
		position: relative;
		top: -10px;
	}
`;

export const GroupImageContainer = styled(Box)`
	width: 44px;
	height: 44px;
	overflow: hidden;
	border-radius: 8px;
	margin-right: 20px;
	z-index: 1;
	border: 4px solid #f7f9fc00;
	svg {
		position: relative;
		top: -10px;
	}
`;

export const FooterContainer = styled(Box)`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	.side-text {
		color: #8f9bb3;
	}
`;

export const CategoryWrapper = styled(Box)`
	display: flex;
	align-items: center;
	background: white;
	border: 1px solid #c5cee0;
	border-radius: 5px;
	height: 28px;
	cursor: pointer;
	svg {
		margin-left: 5px;
	}
	span {
		margin-left: 10px;
		font-weight: 600;
	}
`;
