import React, { memo, useCallback, useEffect, useState } from "react";

import { PostBlockEventType } from "types";

import { ReactComponent as CloseCircle } from "assets/icons/close-icon-bordered.svg";
import ConfirmActionModal from "shared/Components/ConfirmActionModal";
import { useS3Uploader } from "shared/services/s3Uploader";
import { ToolbarActions } from "shared/types";

import { Loader } from "shared/ui-kit";

import { CloseIconWrapper, ContentTypeWrapper, LoaderWrapper, StyledImg, StyledVideo, Wrapper } from "./style";

export interface CommentWidgetProps {
	content: any;
	action: ToolbarActions;
	onEvent: (eventType: PostBlockEventType) => void;
	editMode?: boolean;
}

const CommentWidget: React.FC<CommentWidgetProps> = memo(({ content, action, onEvent, editMode = false }) => {
	const { uploadFile } = useS3Uploader();

	const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

	const [imgSrc, setImgSrc] = useState<string>();
	const [contentType, setContentType] = useState<string>();

	const [uploading, setUploading] = useState(false);

	const cancelDelete = () => {
		setShowDeleteConfirmation(false);
	};

	const onDelete = () => {
		setShowDeleteConfirmation(true);
	};

	const confirmDelete = () => {
		setShowDeleteConfirmation(false);
		setUploading(false);
		onEvent(PostBlockEventType.delete);
	};

	useEffect(() => {
		onEvent(uploading ? PostBlockEventType.uploadingProgressStart : PostBlockEventType.uploadingProgressFinished);
	}, [onEvent, uploading]);

	const prepareData = useCallback(
		async (content: any, action: ToolbarActions, editMode: boolean) => {
			if (editMode && content?.url && typeof content.url === "string") {
				setImgSrc(content.url);
				setContentType(content.url.includes(".gif") ? "GIF" : content.url.includes(".mp4") ? "VIDEO" : "IMG");
			} else {
				if (action === ToolbarActions.ADD_IMAGE) {
					const file = content?.fileList?.length ? content?.fileList[0] : undefined;
					if (file) {
						setImgSrc(window.URL.createObjectURL(file));

						setContentType(file.type?.includes("video/") ? "VIDEO" : "IMG");

						setUploading(true);

						const data = await uploadFile({
							file,
							communityName: `${file!.name}`,
							refId: file.name
						});

						if (data?.publicUrl) {
							setImgSrc(data.publicUrl);
						}

						setUploading(false);
					}
				}

				if (action === ToolbarActions.ADD_GIF && content?.images?.original?.url) {
					setImgSrc(content.images.original.url);
					setContentType("GIF");
				}
			}
		},
		[uploadFile]
	);

	useEffect(() => {
		prepareData(content, action, editMode);
	}, [prepareData, action, content, editMode]);

	if (!imgSrc) return null;

	return (
		<>
			<Wrapper
				contentEditable={false}
				data-name="attachment-wrapper"
				className={contentType === "VIDEO" ? "video-wrapper" : ""}
			>
				{uploading && (
					<LoaderWrapper>
						<Loader show variant="indeterminate" size="20px" />
					</LoaderWrapper>
				)}
				<CloseIconWrapper onClick={onDelete}>
					<CloseCircle width={22} height={22} />
				</CloseIconWrapper>
				{contentType === "VIDEO" ? <StyledVideo controls src={imgSrc} /> : <StyledImg src={imgSrc} />}
				{contentType && contentType !== "VIDEO" && <ContentTypeWrapper>{contentType}</ContentTypeWrapper>}
			</Wrapper>
			<ConfirmActionModal
				open={showDeleteConfirmation}
				onClose={cancelDelete}
				title="Delete content?"
				bodyText={"Are you sure you want to delete this content?"}
				onConfirm={confirmDelete}
				closeText="Keep"
				confirmText="Delete"
				confirmButtonTheme="danger"
				cancelButtonTheme="primary"
			/>
		</>
	);
});

export default CommentWidget;
