import { Box, CircularProgress } from "@material-ui/core";

import styled from "styled-components";

import { Text } from "shared/ui-kit";

export const UploaderWrapper = styled(Box)`
	display: flex;
	position: relative;

	pointer-events: none;

	video {
		height: 100vh;
		min-height: 100%;
		object-fit: none;
	}
`;

export const UploaderCircularWrapper = styled(Box)`
	position: absolute;
	top: 0;
	left: 0;

	width: 100%;
	height: 100%;

	display: flex;
	justify-content: center;
	align-items: center;

	background: rgba(0, 0, 0, 0.4);

	border-radius: 4px;

	z-index: 2;
`;

export const CircularProgressWrapper = styled(CircularProgress)`
	circle {
		stroke: white;
	}
`;

export const OverlayBox = styled(Box)`
	position: relative;
`;

export const OverlayTextBox = styled(Box)`
	position: absolute;
	top: 0;
	left: 0;

	width: 100%;
	height: 100%;

	display: flex;
	justify-content: center;
	align-items: center;
`;

export const OverlayText = styled(Text)`
	background: rgba(0, 0, 0, 05);

	width: 30px;
	height: 30px;

	display: flex;
	justify-content: center;
	align-items: center;

	color: white;

	border-radius: 50%;
`;

export const ImageCarouselWrapper = styled(Box)<{ collageWidth: number; maxCollageWidth: number }>`
	position: relative;

	width: ${({ collageWidth }) => `${collageWidth}px`};
	max-width: 100%;

	overflow: hidden;

	margin: 16px auto 16px 0;
`;
