import React, { useCallback, useEffect, useMemo, useState } from "react";

import { Box, InputAdornment, TextField, useMediaQuery } from "@material-ui/core";

import styled, { css } from "styled-components";

import { ReactComponent as SearchIcon } from "assets/icons/iconSearch.svg";

import { MemberDialog } from "modules/MemberHome/View/shared";
import { ScrollBarWrapper } from "modules/MemberHome/View/shared/MemberDialog/style";
import { Dialog, SkeletonBase } from "shared/Components";

import { useDebounce, useGif, useUser } from "shared/hooks";
import { GifModel } from "shared/types";
import { Text } from "shared/ui-kit";
import * as appTheme from "theme/default";

const CustomWrapper = styled(Box)`
	min-height: 400px;
	max-height: 70vh;
`;

const SearchWrapper = styled(Box)`
	padding: 16px 40px;

	${props => props.theme.breakpoints.down("sm")} {
		padding: 16px;
	}
`;

const StyledTextField = styled(TextField)`
	width: 100%;
`;

const StyledText = styled(Text)`
	font-size: 16px;
	text-align: center;
`;

const StyledGridListTile = styled(Box)`
	width: 33.3%;
	height: 219px;

	display: inline-flex;

	padding: 0 1px;

	${props => props.theme.breakpoints.down("sm")} {
		width: 100%;
		max-width: 400px;
	}

	img {
		width: 100%;
		height: 100%;

		margin: 0 !important;

		object-fit: cover;
		object-position: center;
	}
`;

interface Props {
	onClose: (gif: {}, reason: string) => void;
	onSelect: (gif: GifModel) => void;
	open: boolean;
}

const limit = 24;

const SelectGif: React.FC<Props> = ({ onClose, onSelect, open }) => {
	const isMobile = useMediaQuery(appTheme.default.breakpoints.down("sm"));
	const { getListOfGifs, getData: getGifData } = useGif();
	const { list, loading } = getGifData();

	const { getData: getUserData } = useUser();
	const { isMemberView } = getUserData();

	const [keyWord, setKeyWord] = useState();
	const [offset, setOffset] = useState(0);

	const debouncedKeyWord = useDebounce(keyWord, 300);

	useEffect(() => {
		getListOfGifs({ keyword: debouncedKeyWord, offset, limit });
	}, [getListOfGifs, debouncedKeyWord, offset]);

	const handleSearchChange = e => {
		const { value } = e.target;
		setOffset(0);
		setKeyWord(value);
	};

	const select = useCallback(
		item => {
			setTimeout(() => {
				onSelect(item);
			}, 10);
		},
		[onSelect]
	);

	const dialogContent = useMemo(
		() => (
			<>
				<SearchWrapper>
					<StyledTextField
						value={keyWord}
						onChange={handleSearchChange}
						name="name"
						placeholder="Search"
						variant="outlined"
						isMemberView={isMemberView}
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<SearchIcon />
								</InputAdornment>
							)
						}}
					/>
				</SearchWrapper>
				<CustomWrapper>
					{list?.length || loading ? (
						<ScrollBarWrapper onScrollStop={() => !loading && setOffset(x => x + limit)} className="scrollContent">
							{(list || []).map(i => (
								<StyledGridListTile key={i.slug} cols={1} onClick={() => select(i)}>
									<img src={i.images.original.url} alt={i.title} />
								</StyledGridListTile>
							))}
							{loading && (
								<>
									{Array.from(Array(3).keys()).map((i, index) => (
										<StyledGridListTile cols={1} key={index}>
											<SkeletonBase variant="rect" />
										</StyledGridListTile>
									))}
								</>
							)}
						</ScrollBarWrapper>
					) : (
						<StyledText>No results</StyledText>
					)}
				</CustomWrapper>
			</>
		),
		[isMemberView, keyWord, loading, list, select]
	);

	return isMemberView ? (
		<MemberDialog customWidth={512} title="Choose a Gif" open={open} onClose={onClose} noFooter isScrollable={false}>
			{dialogContent}
		</MemberDialog>
	) : (
		<Dialog
			bodyCustomStyles={css`
				padding: 0 !important;
				overflow: hidden;
			`}
			title={<Text variant={isMobile ? "h5" : "h3"}>Choose a Gif</Text>}
			open={open}
			onClose={onClose}
			isScrollable={false}
		>
			{dialogContent}
		</Dialog>
	);
};

export default SelectGif;
