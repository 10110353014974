import React, { FC, ReactNode, useCallback, useMemo } from "react";

import { Box } from "@material-ui/core";

import { ReactComponent as LockIcon } from "assets/icons/icon-lock.svg";
import { MenuDots, MenuDotsOption, PDFReader, SkeletonBase, TrackPlayer, VideoPlayer } from "shared/Components";
import { useAlbums, useCommunity } from "shared/hooks";
import { RelativeWrapper } from "shared/styles";
import { TrackMeta, TrackModel, VideoModel } from "shared/types";
import { Button, ReadMore, Text } from "shared/ui-kit";
import { validateYoutube } from "utils/serviceUtils/validators";

import { Container, Content, ImgViewer, PlayerWrapper } from "./style";

interface Props {
	image?: string;
	pdf?: string;
	video?: VideoModel;
	track?: TrackModel;
	fileType?: string;
	summary?: string;
	title: string;
	subtitle?: string;
	description?: string;
	locked?: boolean;
	unlockPremiumBlock?: ReactNode;
	fullWidthDescription?: boolean;
	mini?: boolean;
	nextItem?: {
		title: string;
		subtitle: string;
	};
	loading?: boolean;
	options?: MenuDotsOption[];
	allowDownload?: boolean;
}

const ItemDetailsTemplate: FC<Props> = ({
	image,
	pdf,
	video,
	track,
	fileType,
	summary,
	title,
	subtitle,
	description,
	locked,
	unlockPremiumBlock,
	fullWidthDescription,
	mini,
	nextItem,
	loading,
	options,
	allowDownload = false
}) => {
	const { readSong, setTrackProgressDialog, getData: getAlbumsData } = useAlbums();
	const { trackProgressDialog } = getAlbumsData();

	const { communityColors } = useCommunity();

	const item = useMemo(() => video || track || pdf || image || "", [pdf, image, track, video]);

	const itemDownload = useMemo(() => trackProgressDialog.find(t => t.track === item), [item, trackProgressDialog]);

	const handleDownloadItem = useCallback(async () => {
		if (!video && !track && !pdf && !image) return null;

		const itemUrl =
			typeof item === "string"
				? item
				: video
				? (video as VideoModel).meta.video.url
				: track
				? (track.meta as TrackMeta).track.url
				: "";

		setTrackProgressDialog([
			{
				name: title,
				track: itemUrl,
				progress: 0
			}
		]);

		const data = await readSong(itemUrl);
		const link = document.createElement("a");
		link.href = window.URL.createObjectURL(
			new Blob([data], {
				type: "application/octet-stream"
			})
		);
		link.download = `${title}.${fileType ? fileType : track ? "mp3" : "mp4"}`;
		document.body.appendChild(link);
		link.click();

		setTrackProgressDialog([]);
	}, [video, track, pdf, image, setTrackProgressDialog, title, item, readSong, fileType]);

	const isYTVideo = useMemo(() => validateYoutube(video?.meta.video.url), [video]);

	const headerActions = useCallback(
		(header?: boolean) => (
			<Box className="actions">
				{locked ? (
					<>{unlockPremiumBlock}</>
				) : !isYTVideo ? (
					<>
						{allowDownload && (
							<Button className="download" buttonTheme="light" onClick={handleDownloadItem} disabled={!!itemDownload}>
								{itemDownload ? `${itemDownload.progress}% Preparing File..` : "Download"}
							</Button>
						)}
					</>
				) : null}
				{!header && !!options?.length && <MenuDots options={options} memberView />}
			</Box>
		),
		[handleDownloadItem, isYTVideo, itemDownload, locked, unlockPremiumBlock, options, allowDownload]
	);

	return loading ? (
		<Container>
			<SkeletonBase variant="rect" height={450} />
			<Content>
				<Content.Header>
					<SkeletonBase width={"18%"} height={26} />
				</Content.Header>
				<SkeletonBase width={"20%"} height={50} />
				<SkeletonBase width={"18%"} height={24} />
				<SkeletonBase width={100} height={28} />
				<SkeletonBase width={"80%"} height={24} />
				<SkeletonBase width={"80%"} height={24} />
				<SkeletonBase width={"80%"} height={24} />
			</Content>
		</Container>
	) : (
		<Container>
			<RelativeWrapper>
				{(image || track || pdf || video) && locked && (
					<PlayerWrapper.Locked>
						<Box className="content flex flex-col items-center">
							<LockIcon width={40} height={40} />
							<Text variant="h3">Premium Content</Text>
							<Box className="btn-wrapper">{headerActions(true)}</Box>
						</Box>
					</PlayerWrapper.Locked>
				)}
				{image && <ImgViewer src={image} alt={title} />}
				{track && <TrackPlayer autoPlay={!locked} nextItem={nextItem} track={track} />}
				{pdf && <PDFReader file={pdf} />}
				{video && (
					<VideoPlayer autoPlay={!locked} nextItem={nextItem} videoPlayer={video} isYTVideo={isYTVideo} mini={mini} />
				)}
			</RelativeWrapper>

			<Content>
				<Content.Header>
					{summary && <Text>{summary}</Text>} {headerActions()}
				</Content.Header>
				<Content.Title variant="h2">{title}</Content.Title>
				{subtitle && <Content.SubTitle>{subtitle}</Content.SubTitle>}
				{headerActions}
				{description && (
					<Content.Description color={communityColors.primary} fullWidthDescription={fullWidthDescription}>
						<Text variant="h4">Description</Text>
						<ReadMore text={description} showCharCount={300} wrapClassName="" wrapTextClassName="" />
					</Content.Description>
				)}
			</Content>
		</Container>
	);
};

export default ItemDetailsTemplate;
