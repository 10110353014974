import { useMemo } from "react";

import { API_URLS } from "shared/constants";
import useHttpActions from "shared/services/api/core/useHttpActions";
import { BaseConverter } from "shared/services/converters";

import { CompanyProps, CreateCompanyProps, UpdateCompanyProps } from "../types/CompanyTypes";

interface IGetCompanies {
	success: boolean;
	companies: CompanyProps[];
}

interface NewCompanyPropsResponse {
	success: boolean;
	company: CompanyProps;
}

const useCompaniesApiService = () => {
	const actions = useHttpActions();

	return useMemo(
		() => ({
			getCompanies: async (offset = 1, limit = 10, keyword = "") => {
				return BaseConverter.convert<IGetCompanies>(
					await actions.get(API_URLS.MARKETPLACE.COMPANIES.BASE, {
						offset,
						limit,
						keyword
					})
				);
			},
			getCompaniesCount: async () => {
				return BaseConverter.convert<{ totalCount: number }>(await actions.get(API_URLS.MARKETPLACE.COMPANIES.COUNT));
			},
			newCompany: async (company: CreateCompanyProps) => {
				return BaseConverter.convert<NewCompanyPropsResponse>(
					await actions.post(API_URLS.MARKETPLACE.COMPANIES.BASE, {
						...company
					})
				);
			},
			updateCompanyStatus: async (id: string, pinned: boolean) => {
				return BaseConverter.convert<{ company: CompanyProps }>(
					await actions.put(`${API_URLS.MARKETPLACE.COMPANIES.BASE}/${id}`, {
						pinned
					})
				);
			},
			updateCompanyActive: async (id: string, active: boolean) => {
				return BaseConverter.convert<{ company: CompanyProps }>(
					await actions.put(`${API_URLS.MARKETPLACE.COMPANIES.BASE}/${id}`, {
						active
					})
				);
			},
			updateCompany: async (company: UpdateCompanyProps) => {
				return BaseConverter.convert<{ company: CompanyProps }>(
					await actions.put(`${API_URLS.MARKETPLACE.COMPANIES.BASE}/${company._id}`, {
						name: company.name,
						photo: company.photo,
						description: company.description,
						url: company.url,
						pinned: company.pinned,
						categories: company.categories
					})
				);
			},
			deleteCompany: async (categoryId: string) => {
				return BaseConverter.convert(await actions.delete(`${API_URLS.MARKETPLACE.COMPANIES.BASE}/${categoryId}`));
			}
		}),
		[actions]
	);
};

export default useCompaniesApiService;
