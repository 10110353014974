import React, { useCallback, useEffect, useRef } from "react";

import { ButtonBase } from "@material-ui/core";
import styled, { keyframes } from "styled-components";

import { useSeries } from "shared/hooks";
import { Icon } from "shared/ui-kit";

const progress = keyframes`
	0% {
		stroke-dasharray: 0 100;
	}
`;

const Wrapper = styled(ButtonBase)`
	width: 64px;
	height: 64px;
	border-radius: 99%;
	position: relative;

	.play-next {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
	.circular-chart {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.circle-bg {
		fill: none;
		stroke-width: 1.6;
	}

	.circle {
		fill: none;
		stroke-width: 1.6;
		stroke-linecap: round;
		animation: ${progress} 5s ease-out forwards;
		stroke: white;
	}
`;

const NextVideoCircularProgress = () => {
	const { setSeriesPlayState, getData: getSeriesData } = useSeries();
	const { seriesPlayState } = getSeriesData();
	const svgRef = useRef<SVGSVGElement>(null);

	const handleClick = useCallback(() => {
		setSeriesPlayState({
			sId: seriesPlayState?.sId || "",
			currentIdx: (seriesPlayState?.currentIdx || 0) + 1
		});
	}, [seriesPlayState, setSeriesPlayState]);

	useEffect(() => {
		svgRef.current?.addEventListener("animationend", handleClick);
	}, [handleClick]);

	return (
		<Wrapper onClick={handleClick}>
			<svg ref={svgRef} viewBox="0 0 36 36" className="circular-chart blue">
				<path
					className="circle-bg"
					d="M18 2.0845
						a 15.9155 15.9155 0 0 1 0 31.831
						a 15.9155 15.9155 0 0 1 0 -31.831"
				/>
				<path
					className="circle"
					strokeDasharray="100, 100"
					d="M18 2.0845
						a 15.9155 15.9155 0 0 1 0 31.831
						a 15.9155 15.9155 0 0 1 0 -31.831"
				/>
			</svg>
			<Icon className="play-next" name="play" group="filled" fill="white" width={24} height={24} />
		</Wrapper>
	);
};

export default NextVideoCircularProgress;
