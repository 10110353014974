import { Box, IconButton } from "@material-ui/core";
import Slider from "react-slick";
import styled, { css, keyframes } from "styled-components";

export const StoriesOverlay = styled(Box)`
	z-index: 1201;
	width: 100%;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.92);
	position: fixed;
	left: 0;
	top: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	opacity: 0;
	transition: ease-in-out 0.3s opacity;
	transition-delay: 200ms;

	&.showOverlay {
		opacity: 1;
	}
`;

export const CloseIcon = styled(Box)`
	position: absolute;
	top: 16px;
	right: 16px;
	width: 60px;
	height: 60px;
	cursor: pointer;
	display: flex;
	background-color: transparent;
	align-items: center;
	justify-content: center;
	z-index: 100;
`;

export const StoriesWrapper = styled(Box)`
	position: relative;
	width: 100%;
	opacity: 0;
	transform: translateY(-40px);
	transition: ease-in-out 0.3s all;

	&.showSlider {
		opacity: 1;
		transform: translateY(0);
	}
`;

export const SliderWrapper = styled(Slider)`
	max-width: 1920px;
	margin: auto;
	width: 100%;

	.slick-track {
		display: flex;
		align-items: flex-end;
		${({ centerStory }) =>
			centerStory &&
			css`
				justify-content: center;
			`}
	}

	.slick-slide {
		margin: auto 0;
	}
`;

export const StoryBox = styled(Box)`
	border-radius: 12px;
	overflow: hidden;
	background-color: rgba(0, 0, 0, 0.56);
	transition: all ease-in-out 0.2s;
	margin: 0 24px;
	width: 85vw;
	height: 85vh;
	max-height: 566.2px;
	position: relative;
	color: white;
	cursor: pointer;
	${props => props.theme.breakpoints.up("sm")} {
		width: 355px;
		height: 596px;
		max-height: unset;
		transform: scale(0.9);
		${({ isActive }) =>
			isActive &&
			css`
				transform: scale(1);
			`}
	}
`;

export const StoryHeadWrapper = styled(Box)`
	padding: 16px;
	position: relative;
	z-index: 10;
	background-image: linear-gradient(to top, rgba(17, 23, 30, 0), rgba(0, 0, 0, 0.5) 78%);
`;

const loadingIndicatorKF = keyframes`
	from {
		transform: translateX(-100%)
	}

	to {
		transform: translateX(0%)
	}
`;

export const ProgressIndicatorWrapper = styled(Box)<{ pauseStory: boolean; widthVal: number }>`
	width: ${({ widthVal }) => widthVal}%;
	height: 2px;
	border-radius: 2px;
	background-color: rgba(255, 255, 255, 0.5);
	position: relative;
	overflow-x: hidden;

	.progress-bar {
		content: "";
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background-color: white;
		transform: translateX(-100%);

		&.start {
			animation: ${loadingIndicatorKF} 15s linear;
			animation-play-state: ${({ pauseStory }) => (pauseStory ? "paused" : "running")};
		}

		&.seen {
			transform: translateX(0);
		}
	}
`;

export const UserDataActions = styled(Box)`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 12px;
	img {
		height: 40px;
		width: 40px;
		border: 1px solid white;
		border-radius: 99%;
		object-fit: cover;
		margin-right: 8px;
	}
	button {
		margin-left: 12px;
		&:first-child {
			margin-left: 0;
		}
	}
`;

export const StoryContent = styled(Box)`
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
`;

export const StoryWrapper = styled(Box)`
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	video,
	img {
		width: 100%;
		height: ${({ isVertical }) => (isVertical ? "100%" : "auto")};
		max-height: 100%;
		object-fit: cover;
		border-radius: 12px;
		opacity: ${({ isActive }) => (isActive ? 1 : 0.5)};
		transition: 0.3s opacity ease-in-out;
		&:hover {
			opacity: 1;
		}
	}
`;

export const SlideControlButtons = styled(Box)`
	position: fixed;
	top: 45%;
	left: calc(50% - 47.5vw);
	width: 95vw;
	margin: auto;
	z-index: 10;
	display: flex;
	align-items: center;
	justify-content: space-between;

	button {
		height: 32px;
		width: 32px;
		border: 0.5px solid white;
		&:hover {
			background-color: rgba(255, 255, 255, 0.16);
		}
	}

	${props => props.theme.breakpoints.up("sm")} {
		width: 440px;
		left: calc(50% - 220px);
		opacity: 0;
		transition: 0.3s opacity ease-in-out;

		&.visible {
			opacity: 1;
		}
	}
`;

export const InteractWithStoryWrapper = styled(Box)`
	display: flex;
	align-items: center;
	justify-content: space-between;
	color: white;
	width: 85vw;
	margin: auto;
	margin-top: 16px;
	position: relative;
	border: 2px solid white;
	border-radius: 16px;
	padding: 8px 0;

	.write-a-comment {
		padding-left: 12px;
		width: calc(100% - 40px);
		line-height: 22px;
		outline: none;
		.MuiOutlinedInput-root {
			color: white;
		}
	}

	button,
	.sending-message {
		position: absolute;
		top: 0;
		right: 12px;
		height: 100%;
	}

	.sending-message {
		display: flex;
		align-items: center;
	}

	button {
		width: 24px;
		display: flex;
		align-items: center;
		svg {
			fill: white;
			position: absolute;
			top: 0;
			right: 0;
			height: 100%;
			pointer-events: none;
			opacity: 0;
			transform: scaleX(0.7);
			transition: ease-in-out 0.3s all;
			&.visible {
				opacity: 1;
				transform: scaleX(1);
				pointer-events: all;
			}
		}
	}

	${props => props.theme.breakpoints.up("sm")} {
		width: 355px;
		opacity: 0;
		transition: 0.2s opacity ease-in-out;

		&.visible {
			opacity: 1;
		}
	}
`;

export const MoreActionsMenu = styled(Box)`
	position: absolute;
	top: 110%;
	right: 0;
	width: 240px;
	background-color: white;
	border-radius: 16px;
	display: none;
	padding: 8px;
	overflow: hidden;
	h6 {
		text-align: center;
		padding: 8px 0;
		border-bottom: 0.5px solid #c5cee0;
		color: #222b45;
	}
	&.show {
		display: block;
	}
	button {
		display: flex;
		justify-content: flex-start;
		width: 100%;
		margin-left: 0;
		margin: 0;
		color: #222b45;
		background-color: transparent;
		padding-left: 8px;
		padding-right: 8px;
		svg {
			fill: #8f9bb3;
		}
		p {
			margin-left: 8px;
		}
	}
`;

export const RemoveConnectionDialogWrapper = styled(Box)`
	text-align: center;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.4);
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 1900;
	.dialog {
		max-width: 90%;
		width: 400px;
		margin: auto;
		z-index: 2000;
		position: relative;
		background-color: white;
		border-radius: 12px;
		padding-top: 16px;
		h6 {
			text-align: center;
			color: #222b45;
			padding: 0 16px;
			font-size: 18px;
		}
		p {
			width: 75%;
			margin: 12px auto 24px;
		}
		.footer {
			display: flex;
			button {
				width: 50%;
				margin-left: 0;
				margin-right: 0;
				margin-bottom: 0;
				border: 1px solid #c5cee0;
				border-radius: 0;
				&:first-child {
					border-bottom-left-radius: 12px;
					border-right: none;
				}
				&:last-child {
					border-bottom-right-radius: 12px;
				}
			}
		}
	}
`;

export const StyledMultipleStoriesProgress = styled(Box)`
	width: 100%;
	display: flex;
	justify-content: space-between;
`;

export const ArrowIconButton = styled(IconButton)<{ show?: boolean }>`
	transition: opacity 0.25s linear;
	opacity: ${({ show }) => (show ? 1 : 0)};
	cursor: ${({ show }) => (show ? "pointer" : "none")};
	pointer-events: ${({ show }) => (show ? "all" : "none")};
`;

export const CommentInput = styled.textarea`
	resize: none;
	border: 1px solid #ffffff;
	background-color: transparent;
	border: 0;
	color: #ffffff;
	font-size: 16px;
`;

export const InteractWithMatch = styled(Box)`
	display: flex;
	align-items: center;
	justify-content: space-between;
	color: white;
	width: 85vw;
	margin: auto;
	margin-top: 16px;

	.Mui-disabled p {
		color: #fff !important;
	}

	.connect {
		flex: 1;
		margin: 0 12px;
		height: 40px;
		border-radius: 8px;
		p {
			margin-left: 8px;
			font-size: 14px;
			font-weight: bold;
			line-height: 1.14;
			color: #222b45;
		}
	}

	.outline {
		height: 40px;
		width: 40px;
		padding: 8px;
		border: 1px solid #fff;
	}

	${props => props.theme.breakpoints.up("sm")} {
		width: 355px;
		opacity: 0;
		transition: 0.2s opacity ease-in-out;

		&.visible {
			opacity: 1;
		}
	}
`;
