import React, { useEffect, useRef } from "react";

import { Speaker } from "./style";

interface VideoStreamProps {
	stream: MediaStream;
	isMe: boolean;
}

const VideoStream: React.FC<VideoStreamProps> = ({ stream, isMe }) => {
	const myVideo = useRef<HTMLVideoElement>(null);

	useEffect(() => {
		if (!myVideo.current) return;
		myVideo.current.srcObject = stream;
	}, [stream]);

	return <Speaker.Video ref={myVideo} autoPlay className={isMe ? "myVideo" : undefined} muted={isMe} />;
};

export default VideoStream;
