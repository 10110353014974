import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";

import { ButtonBase, useMediaQuery } from "@material-ui/core";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";

import { Document, Page } from "react-pdf/dist/esm/entry.webpack";

import { useCommunity } from "shared/hooks";
import { Text } from "shared/ui-kit";

import * as appTheme from "theme/default";

import { MessagePreviewType } from "./ImagePreview";
import { PageButton, PreviewDialogWrapper } from "./style";

const PDF: FC<{
	url?: string;
	small?: boolean;
	onLoadingDone?: () => void;
	visiblePage?: number;
	onClickPage?: (page: number) => void;
}> = ({ url, small, onLoadingDone, visiblePage, onClickPage }) => {
	const [numPages, setNumPages] = useState(null);
	const { communityColors } = useCommunity();

	const onDocumentLoadSuccess = ({ numPages: nextNumPages }) => {
		setNumPages(nextNumPages);
		onLoadingDone && onLoadingDone();
	};

	const communityColor = useMemo(() => communityColors.primary, [communityColors.primary]);

	return (
		<Document file={url} onLoadSuccess={onDocumentLoadSuccess}>
			{Array.from({ length: numPages || 0 }, (_, index) =>
				small ? (
					<PageButton
						onClick={() => onClickPage && onClickPage(index + 1)}
						communityColor={communityColor}
						className={visiblePage && visiblePage === index + 1 ? "pdf-page-visible" : ""}
					>
						<Page width={200} key={`page_${index + 1}`} pageNumber={index + 1} />
					</PageButton>
				) : (
					<Page key={`page_${index + 1}`} pageNumber={index + 1} />
				)
			)}
		</Document>
	);
};

const PDFPreview: FC<MessagePreviewType> = ({ handleDownload, URL }) => {
	const [visiblePage, setVisiblePage] = useState(1);
	const [loadingCompleted, setLoadingCompleted] = useState(false);
	const [offsets, setOffsets] = useState<{ step: number; offset: number }[]>([]);
	const pdfRef = useRef<HTMLDivElement>(null);
	const pdfListRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		setTimeout(() => {
			if (!pdfRef.current || !loadingCompleted) return;
			const pages = pdfRef.current.querySelectorAll("[data-page-number]") as NodeListOf<HTMLElement> | undefined;
			const offsets = Array.from(pages || []).map(p => {
				return {
					step: Number(p.dataset["pageNumber"]),
					offset: p.offsetTop
				};
			});

			setOffsets(offsets);
		}, 500);
	}, [loadingCompleted]);

	const handleScroll = useCallback(
		e => {
			const closestPage = offsets.reduce(
				(prev, curr) => {
					return curr.offset > e.target.scrollTop ? prev : curr;
				},
				{ step: 1, offset: 0 }
			);

			setVisiblePage(closestPage.step);
		},
		[offsets]
	);

	useEffect(() => {
		if (!pdfRef.current) return;
		pdfRef.current.addEventListener("scroll", handleScroll);
	}, [handleScroll, offsets]);

	useEffect(() => {
		if (pdfListRef.current) {
			const el = pdfListRef.current.querySelector(`[data-page-number="${visiblePage}"]`);
			el?.scrollIntoView({ behavior: "smooth", block: "start" });
		}
	}, [visiblePage]);

	const isMobile = useMediaQuery(appTheme.default.breakpoints.down("sm"));

	const handleSlidePDF = useCallback((page: number) => {
		if (pdfRef.current) {
			const el = pdfRef.current.querySelector(`[data-page-number="${page}"]`) as HTMLDivElement | null;
			if (el) pdfRef.current.scrollTo({ top: el.offsetTop, behavior: "smooth" });
		}
	}, []);

	return (
		<>
			<PreviewDialogWrapper.PDFPreviewContent>
				{!isMobile && (
					<PreviewDialogWrapper.ScrollableSide ref={pdfListRef}>
						<PDF url={URL} small visiblePage={visiblePage} onClickPage={handleSlidePDF} />
					</PreviewDialogWrapper.ScrollableSide>
				)}
				<PreviewDialogWrapper.ScrollableMainContent ref={pdfRef}>
					<PDF url={URL} onLoadingDone={() => setLoadingCompleted(true)} />
				</PreviewDialogWrapper.ScrollableMainContent>
			</PreviewDialogWrapper.PDFPreviewContent>
			<PreviewDialogWrapper.Footer>
				<PreviewDialogWrapper.ImageControls></PreviewDialogWrapper.ImageControls>
				<ButtonBase className="download" onClick={handleDownload}>
					<CloudDownloadIcon />
					<Text>Download</Text>
				</ButtonBase>
			</PreviewDialogWrapper.Footer>
		</>
	);
};

export default PDFPreview;
