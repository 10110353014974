import styled from "styled-components";

import { Box, Text } from "shared/ui-kit";

export const Wrapper = styled.label`
	margin: 12px 0;

	display: inline-flex;
	justify-content: space-between;
	align-items: flex-start;

	cursor: pointer;

	width: 100%;

	&.vertical-center {
		align-items: center;
	}

	&.active {
		border: solid 1px #6173fe !important;
		background-color: #f5f5ff;
	}
`;

export const Item = styled(Box);

Item.InfoColumn = styled(Box)`
	display: flex;
	align-items: flex-start;

	width: calc(100% - 100px);
`;

Item.IconWrapper = styled(Box)`
	margin-right: 10px;

	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
`;

Item.TextBlock = styled(Box)`
	width: calc(100% - 50px);
`;

Item.Title = styled(Text)`
	font-size: 15px;
	font-weight: 600;
	line-height: 1.6;
	color: #222b45;
`;

Item.Description = styled(Text)`
	font-size: 13px;
	font-weight: normal;
	line-height: 1.23;
	color: #8f9bb3;
`;

Item.ActionColumn = styled(Box)`
	display: flex;
	align-items: flex-start;
	justify-content: flex-end;

	width: 100px;
`;
