import React, { FC, ReactNode } from "react";

import clsx from "clsx";

export interface SmartInputWrapperProps {
	children: ReactNode;
	className?: string;
}

const SmartInputWrapper: FC<SmartInputWrapperProps> = ({ children, className = "" }) => (
	<div className={clsx("mb-6", className)}>{children}</div>
);

export default SmartInputWrapper;
