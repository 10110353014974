import React, { memo, useEffect, useState } from "react";

import { useHistory } from "react-router-dom";

import { SeriesCollection } from "modules/Manage/Data/types";
import { usePopulateWithAds, useSeries } from "shared/hooks";

import { AdWrapper } from "shared/styles";
import { AdZone } from "utils/ads/getRandomAd";

import { ItemWrapper, ListWrapper } from "../Groups/style";
import { BoxItem, SeeAllBtn, SeriesCard, Slider } from "../index";

interface SeriesProps {
	seriesPageUrl: string;
	popular?: boolean;
}

const Series: React.FC<SeriesProps> = memo(({ seriesPageUrl, popular }) => {
	const history = useHistory();
	const { getCollections } = useSeries();
	const [collections, setCollections] = useState<SeriesCollection[]>([]);

	useEffect(() => {
		getCollections({
			keyword: "",
			limit: 10,
			offset: 1,
			sortBy: popular ? "popular" : undefined,
			ignoreState: !!popular || undefined
		}).then(collections => {
			if (!collections) return;
			setCollections(collections);
		});
	}, [getCollections, popular]);

	const listWithAds = usePopulateWithAds({
		list: collections,
		zone: AdZone.cardZone,
		every: 10,
		skip: 0
	});

	if (!listWithAds.length) return null;

	return (
		<BoxItem title={`${popular ? "Top Series" : "Series and Collection"}`}>
			<ListWrapper noPB>
				<Slider count={(listWithAds || []).length} itemSize={360}>
					{listWithAds.map((item, index) =>
						typeof item === "string" ? (
							<ItemWrapper key={index}>
								<AdWrapper className="block_ad--wrapper" dangerouslySetInnerHTML={{ __html: item }} />
							</ItemWrapper>
						) : (
							<ItemWrapper key={index}>
								<SeriesCard series={item} />
							</ItemWrapper>
						)
					)}
				</Slider>
			</ListWrapper>
			<SeeAllBtn click={() => history.push(seriesPageUrl)} link={seriesPageUrl} />
		</BoxItem>
	);
});

export default Series;
