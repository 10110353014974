import React from "react";

import MuiButton, { ButtonProps as MuiButtonProps } from "@material-ui/core/Button";
import clsx from "clsx";
import styled, { css } from "styled-components";

import { useCommunity, useUser } from "shared/hooks";

import { ButtonColorType, ButtonSizeType, IExtendedTheme } from "../../themes/default";

type ButtonSize = "giant" | "large" | "medium" | "small" | "tiny";
export type ButtonPalette = "primary" | "light" | "basic" | "success" | "info" | "danger" | "control";
type ButtonTheme = "main" | "outline" | "light" | "ghost";

type DefaultButtonProps = {
	buttonTheme?: ButtonTheme;
	customStyle?: () => string;
	transparent?: boolean;
	leftIcon?: any;
	oval?: boolean;
	palette?: ButtonPalette;
	rightIcon?: any;
	size?: ButtonSize;
	square?: boolean;
	theme: IExtendedTheme;
	removeSideMargin?: boolean;
	fullWidth?: boolean;
	preventIconSize?: boolean;
};

export type ButtonProps = MuiButtonProps & DefaultButtonProps;

const getPadding = (props: Readonly<ButtonProps>, selectedSize: Readonly<ButtonSizeType>): string => {
	let { padding } = selectedSize;

	if (props.rightIcon) {
		padding = selectedSize.paddingWithRightIcon;
	}

	if (props.leftIcon) {
		padding = selectedSize.paddingWithLeftIcon;
	}

	if (props.square || props.oval) {
		padding = selectedSize.squarePadding;
	}

	return padding;
};

export default styled(
	({
		children,
		// size,
		// palette,
		// buttonTheme,
		// customStyle,
		oval,
		square,
		// theme,
		// transparent,
		leftIcon,
		rightIcon,
		removeSideMargin,
		className,
		// fullWidth,
		...rest
	}: Readonly<ButtonProps>): JSX.Element => {
		return (
			<MuiButton {...rest} className={clsx(className, removeSideMargin && "mx-0")}>
				{leftIcon && <span className="icon left-icon">{leftIcon}</span>}
				{!square && !oval && children}
				{rightIcon && <span className="icon right-icon">{rightIcon}</span>}
			</MuiButton>
		);
	}
)`
	&.MuiButton-root {
		font-weight: bold;
		letter-spacing: normal;
		text-align: center;
		text-transform: none;

		${({ fullWidth }: Readonly<ButtonProps>) => {
			return fullWidth
				? css`
						width: 100%;
				  `
				: "";
		}}

		.icon {
			display: inherit;
		}

		${(props: Readonly<ButtonProps>) => {
			const { communityColors, getData: getCommunityData } = useCommunity();
			const { mainAdminColor } = getCommunityData();

			const { getData: getUserData } = useUser();
			const { isMemberView } = getUserData();

			let customStyle = (props.customStyle && props.customStyle()) || "";

			const selectedSizeName = props.size || "medium";
			const selectedSize: ButtonSizeType = props.theme.sizes.button[selectedSizeName];

			const padding = getPadding(props, selectedSize);

			const { palette } = props.theme;
			const buttonTheme = props.buttonTheme || "main";
			const paletteName = props.palette || "primary";

			let buttonColors: ButtonColorType =
				// @ts-expect-error: should be correct
				palette[`${buttonTheme}ButtonColors`][paletteName] || palette[`${buttonTheme}ButtonColors`].primary;

			if (props.buttonTheme === "outline") {
				buttonColors = palette.outlineButtonColors;

				customStyle += `
        box-shadow: 0 0 0 1px ${buttonColors.boxShadowColor};

        .icon {
          color: ${buttonColors.iconColor};
        }
      `;
			}

			if (!isMemberView && paletteName === "primary" && mainAdminColor) {
				buttonColors = {
					...buttonColors,
					color: "#ffffff",
					backgroundColor: mainAdminColor,
					backgroundColorOnHover: `${mainAdminColor}cc`,
					backgroundColorOnPressed: mainAdminColor
				};
			}

			if (isMemberView && paletteName === "primary") {
				buttonColors = {
					...buttonColors,
					color: "#ffffff",
					backgroundColor: communityColors.btn,
					backgroundColorOnHover: `${communityColors.btn}cc`,
					backgroundColorOnPressed: communityColors.btn
				};
			}

			if (isMemberView && paletteName === "light") {
				buttonColors = {
					...buttonColors,
					color: communityColors.btn,
					backgroundColor: `${communityColors.btn}4d`,
					backgroundColorOnHover: `${communityColors.btn}33`,
					backgroundColorOnPressed: `${communityColors.btn}4d`
				};
			}

			if (props.disabled) {
				buttonColors = palette.disabledButtonColors;

				customStyle += `
       .MuiButton-label {
        opacity: ${buttonColors.labelOpacity};
       }
      `;
			}

			if (props.square || props.oval) {
				customStyle += `
        min-width: ${selectedSize.squareWidth};

        .icon {
          padding-right: 0;
          padding-left: 0;
        }
      `;
			}

			return css`
				padding: ${padding};
				font-size: ${selectedSize.fontSize};
				line-height: ${selectedSize.lineHeight};
				color: ${buttonColors.color};
				background-color: ${props.transparent ? "transparent" : buttonColors.backgroundColor};
				border-radius: ${props.oval ? "50%" : "8px"};

				@media (hover: hover) {
					&:hover {
						color: "#ffffff";
						background-color: ${props.transparent ? "transparent" : buttonColors.backgroundColorOnHover};
					}
				}

				@media (hover: none) {
					&:hover {
						color: #ffffff;
						background-color: ${props.transparent ? "transparent" : buttonColors.backgroundColor};
					}
				}

				&:active {
					background-color: ${props.transparent ? "transparent" : buttonColors.backgroundColorOnPressed};
				}

				.icon {
					height: ${selectedSize.iconLineHeight};
					display: flex;
					justify-content: center;
					align-items: center;
					padding-right: ${props.leftIcon ? selectedSize.paddingFromIcon : 0};
					padding-left: ${props.rightIcon ? selectedSize.paddingFromIcon : 0};
				}

				${({ preventIconSize }) =>
					!preventIconSize &&
					css`
						.icon > *:first-child {
							font-size: ${selectedSize.iconFontSize};
							height: ${selectedSize.iconHeight};
							width: ${selectedSize.iconWidth};
						}
					`}

				${customStyle};
			`;
		}};
	}
`;
