import React, { useCallback, useMemo } from "react";

import { Box, useMediaQuery } from "@material-ui/core";

import { DragIndicator } from "@material-ui/icons";
import { DateTime } from "luxon";

import { ReactComponent as PauseIcon } from "assets/icons/pause-track.svg";
import { MenuDots } from "shared/Components";
import { useAlbums, useCommunity, usePlayer } from "shared/hooks";
import { StreamType } from "shared/types/VideoModel";
import { Icon, Text } from "shared/ui-kit";
import * as appTheme from "theme/default";
import { validateYoutube } from "utils/serviceUtils/validators";
import { shorten } from "utils/shortenText";

import { DragIconWrapper, EyeButton, Wrapper } from "./style";

import { BuyTrackButton, PlayTrack } from "../AlbumTrack/style";

interface Props {
	order: number;
	image: string;
	icon: JSX.Element;
	name: string;
	category: string;
	duration?: string;
	createdAt: string;
	type: "VIDEO" | "FILE" | "TRACK";
	locked: boolean;
	mini?: boolean;
	stream?: StreamType;
	handleClick?: () => void;
	current?: boolean;
	canEdit?: boolean;
	unlockButton?: JSX.Element;
	downloadUrl?: string;
	updatePlayButton?: boolean;
	id?: string;
}

const RowTemplate: React.FC<Props> = ({
	order,
	image,
	icon,
	name,
	category,
	duration,
	createdAt,
	type,
	mini,
	handleClick,
	stream,
	current,
	canEdit,
	unlockButton,
	downloadUrl,
	updatePlayButton,
	id
}) => {
	const isFormatted = duration?.includes(":");
	const d = duration ? (isFormatted ? duration : `${parseFloat(duration) / 60}`) : null;
	const minutesSeconds = d && d !== "NaN" ? (isFormatted ? d.split(":") : d.split(".")) : null;

	const { communityColors } = useCommunity();

	const { readSong, setTrackProgressDialog, getData: getAlbumsData } = useAlbums();
	const { trackProgressDialog } = getAlbumsData();

	const { getData: getPlayerData } = usePlayer();
	const { playTracks, play } = getPlayerData();

	const isCurrentlyPlaying = useMemo(
		() => play && playTracks.tracks?.items[playTracks.idx]?._id && playTracks.tracks?.items[playTracks.idx]?._id === id,
		[id, play, playTracks.idx, playTracks.tracks?.items]
	);

	const itemDownload = useMemo(
		() => trackProgressDialog.find(t => t.track === downloadUrl),
		[downloadUrl, trackProgressDialog]
	);

	const isMobile = useMediaQuery(appTheme.default.breakpoints.down("xs"));

	const isYTVid = useMemo(() => validateYoutube(downloadUrl), [downloadUrl]);

	const progress = useMemo(
		() => stream && duration && (100 * stream.current) / parseFloat(duration),
		[duration, stream]
	);

	const displayDuration = useMemo(() => {
		if (minutesSeconds) {
			let text = "";

			if (minutesSeconds.length > 2) {
				if (minutesSeconds[0] && minutesSeconds[0] !== "0") {
					text += `${minutesSeconds[0]}h`;
				}

				if (minutesSeconds[1] && minutesSeconds[1][0] !== "0") {
					text += ` ${minutesSeconds[1]}m`;
				}

				if (minutesSeconds[2] && minutesSeconds[2][0] !== "0") {
					text += ` ${minutesSeconds[2]}s`;
				}
			} else {
				if (minutesSeconds[0] && minutesSeconds[0] !== "0") {
					text += `${minutesSeconds[0]}m`;
				}

				if (minutesSeconds[1] && minutesSeconds[1][0] !== "0") {
					text += ` ${minutesSeconds[1][0]}s`;
				}
			}

			return text;
		}
	}, [minutesSeconds]);

	const shortenedTitle = useMemo(() => shorten(name, mini ? 50 : 100), [mini, name]);

	const handleDownload = useCallback(
		async (e?: Event) => {
			e && e.stopPropagation();

			if (!downloadUrl) return;

			const fileUrlArr = downloadUrl.split(".");
			const fileType = fileUrlArr && fileUrlArr[fileUrlArr.length - 1].toLowerCase();

			setTrackProgressDialog([
				{
					name: name,
					track: downloadUrl,
					progress: 0
				}
			]);

			const data = await readSong(downloadUrl);
			const link = document.createElement("a");
			link.href = window.URL.createObjectURL(
				new Blob([data], {
					type: "application/octet-stream"
				})
			);
			link.download = `${name}.${fileType ? fileType : type === "TRACK" ? "mp3" : "mp4"}`;
			document.body.appendChild(link);
			link.click();

			setTrackProgressDialog([]);
		},
		[downloadUrl, name, readSong, setTrackProgressDialog, type]
	);

	const menuOpts = useMemo(
		() => [
			{
				name: "Download",
				onClick: async () => handleDownload()
			}
		],
		[handleDownload]
	);

	return (
		<Wrapper current={current} mini={mini} onClick={handleClick}>
			{canEdit && (
				<DragIconWrapper>
					<DragIndicator htmlColor="#c5cee0" />
				</DragIconWrapper>
			)}
			<Wrapper.LeftContent progress={progress} fw={mini}>
				{current && !updatePlayButton && (type === "TRACK" || type === "VIDEO") ? (
					<Wrapper.CurrentlyPlaying btnColor={communityColors.btn}>
						<PauseIcon />
					</Wrapper.CurrentlyPlaying>
				) : (
					<Box className="order">{order}.</Box>
				)}
				<Box className="title">
					{image ? (
						<>
							<Box className="icon">{icon}</Box>
							<Box className="img-wrapper">
								<img src={image} alt={name} />
							</Box>
						</>
					) : (
						<Box className="icon-placeholder">{icon}</Box>
					)}

					<Box className="space-between">
						<Text variant="h3">{!!shortenedTitle.length ? shortenedTitle : name.substring(0, mini ? 45 : 90)}</Text>
						<Text>{category}</Text>
					</Box>
				</Box>
			</Wrapper.LeftContent>
			{!mini && (
				<Wrapper.RightContent>
					<Box className="duration">{displayDuration}</Box>
					<Box className="createdAt">{DateTime.fromISO(createdAt).toFormat("MMM d, yyyy")}</Box>
					<Box className="premium-actions">{unlockButton}</Box>
					<Box className="actions">
						{!isMobile && downloadUrl && !isYTVid && (
							<BuyTrackButton palette="light" disabled={!!itemDownload} free onClick={handleDownload}>
								{itemDownload ? "Downloading.." : "Download"}
							</BuyTrackButton>
						)}
						{handleClick && (
							<>
								{(type === "TRACK" || type === "VIDEO") && (
									<PlayTrack btnColor={communityColors.btn}>
										{isCurrentlyPlaying ? (
											<PauseIcon className="pause" />
										) : (
											<Icon name="play" group="filled" fill="white" width={14} height={14} />
										)}
									</PlayTrack>
								)}
								{type === "FILE" && (
									<EyeButton size="small">
										<Icon name="eye" group="filled" fill={communityColors.btn} width={28} height={28} />
									</EyeButton>
								)}
							</>
						)}
						<MenuDots memberView removeBg removeshadow vertical options={menuOpts} />
					</Box>
				</Wrapper.RightContent>
			)}
		</Wrapper>
	);
};

export default RowTemplate;
