import React from "react";

import { BrowserRouter, Route, Switch } from "react-router-dom";

import { ScrollToTop } from "shared/Components";
import { AccessDenied } from "shared/Errorpages";

import AppWrapper from "./AppWrapper";

import IApp from "../types/IApp";

const PREFIX = "/";

const routes = (modules: IApp[]) => {
	return (
		<AppWrapper>
			<BrowserRouter>
				<ScrollToTop />
				<Route key="root" path={PREFIX}>
					<Switch>
						<Route key={"AccessDenied"} path="/AccessDenied" exact component={AccessDenied} />
						{modules.map(({ getRoutes }) => (getRoutes ? getRoutes() : null))}
					</Switch>
				</Route>
			</BrowserRouter>
		</AppWrapper>
	);
};

export default routes;
