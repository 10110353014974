import { ButtonBase } from "@material-ui/core";
import styled from "styled-components";

import { Box, Text } from "shared/ui-kit";

export const EmptyBox = styled(Box)`
	padding: 38px 20px 0 20px;
`;

EmptyBox.IconWrapper = styled(Box)`
	margin-bottom: 8px;

	display: flex;
	justify-content: center;

	&.hand {
		svg {
			path {
				&:nth-child(2) {
					fill: rgba(255, 255, 255, 0.32);
				}
			}
		}
	}
`;

EmptyBox.Title = styled(Text)`
	font-size: 15px;
	font-weight: 600;
	line-height: 1.6;
	text-align: center;
	color: #fff;

	margin-bottom: 9px;
`;

EmptyBox.Text = styled(Text)`
	font-size: 13px;
	line-height: 1.38;
	text-align: center;
	color: rgba(255, 255, 255, 0.64);
`;

export const NewComment = styled(Box)``;

NewComment.Form = styled.form`
	width: 100%;
	height: fit-content;
`;

NewComment.InputWrapper = styled(Box)`
	position: relative;

	width: 100%;

	border-radius: 8px;

	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.4);

	background-color: rgba(255, 255, 255, 0.08);
`;

NewComment.Input = styled.textarea`
	width: 100%;
	height: 40px;

	resize: none;

	padding: 11px 50px 8px 16px;

	background-color: transparent;
	border: none;

	font-size: 15px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.33;
	letter-spacing: normal;
	color: #fff;

	transition: height 0.25s ease-in-out;

	position: relative;

	outline: none;

	&:before {
		content: "Write a comment...";

		display: block;

		font-weight: inherit;
		font-style: inherit;
		color: #8f9bb3;

		position: absolute;
		top: 8px;
		left: 16px;

		opacity: 0;

		transition: opacity 0.15s linear;
	}

	&:empty {
		&:before {
			opacity: 0.64;
		}
	}
`;

NewComment.ReactionWrapper = styled(Box)`
	width: 24px;
	height: 100%;

	display: flex;
	align-items: center;

	position: absolute;
	top: 0;
	right: 16px;

	z-index: 2;
`;

NewComment.LikeList = styled(Box)`
	display: flex;
	align-items: center;
`;

NewComment.LikeDropdownWrapper = styled(Box)`
	position: absolute;
	bottom: calc(100% + 4px);
	right: -7px;

	pointer-events: none;

	&.show {
		pointer-events: all;
	}
`;

export const Comment = styled(Box)`
	display: flex;
	align-items: flex-start;

	width: 100%;
`;

Comment.Wrapper = styled(Box)`
	width: 100%;

	&:not(:last-child) {
		margin-bottom: 8px;
	}
`;

Comment.Avatar = styled(Box)`
	width: 32px;
	height: 32px;

	border-radius: 50%;

	overflow: hidden;

	margin-right: 8px;

	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
`;

Comment.Body = styled(Box)`
	padding: 4px 8px 8px 8px;

	border-radius: 12px;

	width: fit-content;
	min-width: 180px;
	max-width: calc(100% - 40px);

	background-color: #2e2e2e;

	position: relative;

	&.deletable {
		padding-right: 42px;
	}
`;

Comment.Name = styled(Text)`
	font-size: 13px;
	font-weight: 600;
	line-height: 1.85;
	color: #fff;

	word-break: break-all;
`;

Comment.Text = styled(Text)`
	font-size: 15px;
	line-height: 1.33;
	color: #fff;

	white-space: break-spaces;
	word-break: break-all;
`;

Comment.DeleteIconWrapper = styled(Box)`
	width: 28px;
	height: 100%;

	position: absolute;

	top: 0;
	right: 8px;

	display: flex;
	align-items: center;
`;

Comment.DeleteIcon = styled(ButtonBase)`
	width: 28px;
	height: 28px;

	border-radius: 4px;

	overflow: hidden;

	& > div {
		&:first-child {
			width: 100%;
			height: 100%;

			display: flex;
			justify-content: center;
			align-items: center;
		}
	}

	&:hover {
		background-color: #444;
	}
`;
