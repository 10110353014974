import React from "react";

import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";

import { ColorResult, SketchPicker, SketchPickerProps } from "react-color";
import styled from "styled-components";

import { Button } from "shared/ui-kit";

const PickerWrapper = styled.div<{ expanded?: boolean }>`
	position: relative;
	display: ${({ expanded }) => (expanded ? "inline-block" : "none")};
	background: white;
	border-radius: 5px;
	top: 100%;
	left: 0;
	z-index: 1000;
`;

const PaletteWrapper = styled.div`
	padding: 10px 10px 16px 10px;
	border-bottom: 1px solid ${({ theme }) => theme.palette.RawColorPicker.borderColor};

	span.title {
		display: inline-block;
		margin-bottom: 5px;
		color: ${({ theme }) => theme.palette.RawColorPicker.titleColor};
	}

	.colors {
		display: grid;
		grid-template-columns: repeat(auto-fill, 18px);
		grid-auto-rows: 18px;
		gap: 8px;
	}

	.swatch {
		border-radius: 4px;
		border: 1px solid rgba(0, 0, 0, 0.3);
	}
`;

const SaveColorButtonWrapper = styled.div`
	margin: 10px;
`;

export interface PaletteProps {
	colors?: string[];
	onSelect: (color: string) => void;
	title?: string;
	onExpand?: () => void;
}

const Palette = ({ colors = [], onExpand, onSelect, title }: PaletteProps) => {
	return (
		<PaletteWrapper>
			{title ? <span className="title">{title}</span> : null}
			<div className="colors">
				{colors.map((background, i) => (
					<div className="swatch" key={background + i} style={{ background }} onClick={() => onSelect(background)} />
				))}
				{onExpand && (
					<IconButton onClick={onExpand} size="small">
						<AddIcon fontSize="inherit" />
					</IconButton>
				)}
			</div>
		</PaletteWrapper>
	);
};

export interface RawColorPickerProps extends Omit<SketchPickerProps, "presetColors" | "color"> {
	children?: React.ReactChild;
	onChange?: (color?: ColorResult) => void;
	defaultColor?: string;
	color?: string;
	onAdd?: () => void;
	expanded?: boolean;
}

const RawColorPicker = ({
	children,
	color,
	defaultColor = "azure",
	onAdd,
	expanded,
	...props
}: RawColorPickerProps) => {
	return (
		<PickerWrapper expanded={expanded}>
			<SketchPicker
				{...props}
				color={color ?? defaultColor}
				styles={{
					default: {
						picker: {
							background: "none",
							borderBottomLeftRadius: 0,
							borderBottomRightRadius: 0,
							boxShadow: "none"
						}
					}
				}}
				presetColors={[]}
			/>
			{children}
			{onAdd && (
				<SaveColorButtonWrapper>
					<Button buttonTheme="main" fullWidth onClick={onAdd} palette="primary" removeSideMargin size="small">
						Save color
					</Button>
				</SaveColorButtonWrapper>
			)}
		</PickerWrapper>
	);
};

RawColorPicker.Palette = Palette;
export default RawColorPicker;
