import React from "react";

import styled from "styled-components";

import { Avatar, Box, Button, Text } from "shared/ui-kit";
import { colorPalette } from "shared/ui-kit/themes/default";

const Wrapper = styled(Box)`
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px 16px;

	${props => props.theme.breakpoints.up("sm")} {
		padding: 10px 55px;
	}

	&:first-child {
		padding-top: 0;
	}
`;

const InfoWrapper = styled(Box)`
	display: flex;
	align-items: center;
`;

const IconWrapper = styled(Box)`
	width: 40px;
	height: 40px;

	margin-right: 15px;
`;

const Info = styled(Box)`
	display: block;
`;

const Title = styled(Text)`
	font-size: 13px;
	font-weight: 600;
	line-height: 1.85;
	color: #222b45;
	margin: 0;
	max-width: 250px;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
`;

const Link = styled(Text)`
	font-size: 13px;
	line-height: 1.23;
	color: #8f9bb3;
	margin: 0;
	max-width: 250px;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
`;

interface CommunityBlockProps {
	title: string;
	link: string;
	onLogin: () => void;
	iconUrl?: string;
	id?: string;
}

const CommunityBlock: React.FC<CommunityBlockProps> = ({ title, link, iconUrl, onLogin, id }) => (
	<Wrapper>
		<InfoWrapper>
			<IconWrapper>
				<Avatar title={title || ""} image={iconUrl || undefined} />
			</IconWrapper>
			<Info className="pr-2 sm:pr-0">
				<div className="grid">
					<Title className="truncate">{title}</Title>
				</div>
				<div className="grid">
					<Link className="truncate">{link}</Link>
				</div>
			</Info>
		</InfoWrapper>
		<Button
			className="px-0 sm:px-4"
			removeSideMargin
			size="small"
			buttonTheme={"outline"}
			palette={colorPalette.colors.basic}
			onClick={onLogin}
			id={id}
		>
			Log in
		</Button>
	</Wrapper>
);

export default CommunityBlock;
