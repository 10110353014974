import React, { useCallback, useEffect, useMemo, useState } from "react";

import { useMediaQuery } from "@material-ui/core";
import { UserFileSortBy } from "types";

import { FileType } from "types/FilesContextValuesType";

import { getFileExtension } from "types/getFileExtension";

import { CreateFileDialog } from "shared/Components";
import { useFiles, useNotification } from "shared/hooks";
import { useS3Uploader } from "shared/services/s3Uploader";
import { VideoSortBy } from "shared/types";
import * as appTheme from "theme/default";

import { usePrevious } from "utils/usePrevious";

import { useCategories } from "../../../../Marketplace/Data";
import { FilterBlock, FilterOptionType, ModelBlock, ModelBlockType, PageWrapper } from "../../Components";
import { ManInLotus, MessageText, NoConnectionsBlock, StyledButton } from "../Connections/style";

const defaultFilters = {
	limit: 10,
	keyword: "",
	offset: 1
};

export interface FilterType {
	sortBy?: UserFileSortBy | VideoSortBy;
	categoryId?: string;
	offset: number;
	limit?: number;
	creatorId?: string;
	notEmptyBy?: string;
	personaId?: number;
	personaDocId?: string;
	keyword?: string;
}

export interface EventsPageProps {
	groupId?: string;
}

const FilesPage: React.FC<EventsPageProps> = ({ groupId }) => {
	const { getAllFilesCount, setFileUrl, setFileName, getFiles, getData: getFilesData, setFileExtension } = useFiles();
	const { files, filesTotalCount, fileUploadUrl, isLoading } = getFilesData();

	const { getCategories, getData: getCategoriesData } = useCategories();
	const { categories, isLoading: loadingCategories } = getCategoriesData();

	const { showMessage } = useNotification();
	const { uploadFile } = useS3Uploader();

	const isMobile = useMediaQuery(appTheme.default.breakpoints.down("sm"));

	const [fileList, setFileList] = useState<FileType[]>(files.slice(0, defaultFilters.limit));
	const [filters, setFilters] = useState<FilterType>({ offset: 1, sortBy: UserFileSortBy.timestamp });
	const [categoriesLoaded, setCategoriesLoaded] = useState(false);
	const [showFileDialog, setShowFileDialog] = useState(false);

	const hasMoreItems = useMemo(() => filesTotalCount > fileList.length, [filesTotalCount, fileList.length]);

	const prevFiles = usePrevious(files);

	enum FilesFilters {
		MOST_POPULAR = "MOST_POPULAR",
		MOST_RECENT = "MOST_RECENT"
	}

	const filterOptions: FilterOptionType[] = useMemo(
		() => [
			{
				inlineOptions: true,
				onClick: item => {
					const sortBy = item === FilesFilters.MOST_POPULAR ? UserFileSortBy.popular : UserFileSortBy.timestamp;
					sortBy !== filters.sortBy &&
						setFilters({
							sortBy,
							offset: 1
						});
				},
				listOptions: [
					{
						label: "Most Recent",
						value: FilesFilters.MOST_RECENT,
						default: filters.sortBy === UserFileSortBy.timestamp
					},
					{
						label: "Most Popular",
						value: FilesFilters.MOST_POPULAR,
						default: filters.sortBy === UserFileSortBy.popular
					}
				]
			},
			{
				label: "Categories",
				listOptions: categories.map(({ name }) => ({
					label: name,
					value: name
				})),
				loading: loadingCategories,
				onClick: item => {
					if (!item || !item.length) {
						setFilters(ctx => ({ ...ctx, categoryId: undefined, offset: 1 }));
					} else {
						const cId = categories.find(cat => cat.name === item)?._id;
						cId && cId !== filters.categoryId && setFilters(ctx => ({ ...ctx, categoryId: cId, offset: 1 }));
					}
				}
			}
		],
		[
			FilesFilters.MOST_POPULAR,
			FilesFilters.MOST_RECENT,
			categories,
			filters.categoryId,
			filters.sortBy,
			loadingCategories
		]
	);

	const handleUpload = useCallback(() => {
		const el: any = document.getElementById("fileUrl");
		if (el) {
			el.value = null;
			el.click();
		}
	}, []);

	const createOption = useMemo(
		() => ({
			label: "Create New File",
			onClick: () => {
				handleUpload();
			},
			val: 1
		}),
		[handleUpload]
	);

	useEffect(() => {
		getFiles({ ...defaultFilters, ...filters, sortBy: filters.sortBy as UserFileSortBy, groupId });
	}, [getFiles, filters, groupId]);

	useEffect(() => {
		getAllFilesCount({ groupId, categoryId: filters.categoryId });
	}, [filters.categoryId, getAllFilesCount, groupId]);

	useEffect(() => {
		if (prevFiles !== files) {
			setFileList(fls => (filters.offset === 1 ? files : fls.concat(files)));
		}
	}, [files, filters, prevFiles]);

	const onEndScroll = useCallback(() => {
		if (hasMoreItems && !isLoading) {
			setFilters(ctx => ({ ...ctx, offset: ctx.offset + 1 }));
		}
	}, [hasMoreItems, isLoading]);

	const onUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { target } = e;
		const { files } = target;

		if (files?.length) {
			const extObj = getFileExtension(files[0].name);

			if (!extObj) {
				showMessage("We don't accept this file type, please try with a different file.");
				return;
			}

			uploadFile({
				file: files[0],
				communityName: fileUploadUrl,
				checkProgress: true,
				customCallback: data => setFileUrl(data?.publicUrl)
			});

			setFileName(files && files[0].name.slice(0, files[0].name.lastIndexOf(".")));

			setShowFileDialog(true);

			setFileExtension(extObj.ext);
		}
	};

	const handleFilterWillAppear = useCallback(
		async (item: string) => {
			if (item === "Categories" && !categoriesLoaded) {
				await getCategories({ limit: 20, offset: 1 });
				setCategoriesLoaded(true);
			}
		},
		[categoriesLoaded, getCategories]
	);

	return (
		<PageWrapper>
			<FilterBlock options={filterOptions} createOption={createOption} onShowOptions={handleFilterWillAppear} />
			<ModelBlock
				autoFit={fileList.length > 3 ? 258 : undefined}
				title={"Files & Documents"}
				type={ModelBlockType.file}
				loading={isLoading}
				keepCurrentData={isLoading && filters.offset >= 1}
				items={fileList}
				inlineView={!isMobile}
				onEndScroll={onEndScroll}
				noContent={
					<NoConnectionsBlock>
						<ManInLotus />
						<MessageText>You don’t have any files yet.</MessageText>
						<StyledButton buttonTheme="light" palette="light" size="medium" removeSideMargin onClick={handleUpload}>
							+ Create New File
						</StyledButton>
					</NoConnectionsBlock>
				}
			/>
			<CreateFileDialog
				open={showFileDialog}
				onClose={e => {
					setShowFileDialog(false);
					if (e?.created) {
						getFiles({ ...defaultFilters, ...filters, sortBy: filters.sortBy as UserFileSortBy, groupId });
					}
				}}
			/>
			<input id="fileUrl" type="file" onChange={onUpload} name="trackFileUrl" hidden accept="*" />
		</PageWrapper>
	);
};

export default FilesPage;
