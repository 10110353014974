import React from "react";

import { useLocation } from "react-router-dom";

import { view } from "modules/Members";
import { safelyParseJSON } from "utils/serviceUtils/helpers";

const MembersPage = () => {
	const { search } = useLocation();
	const query = new URLSearchParams(search);
	const showParam = query.get("showMethod");

	return <view.Members showMethod={showParam ? safelyParseJSON(showParam) : false} />;
};

export default MembersPage;
