import { ButtonBase } from "@material-ui/core";

import styled from "styled-components";

export const TermsConditions = styled.a`
	color: #8f9bb3;
	font-size: 13px;
	font-weight: 600;
	line-height: 24px;
`;

export const DonationCard = styled.div`
	border-radius: 12px;
	border: 2px solid #6173fe;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);

	.total {
		color: #8f9bb3;
	}

	&.noBorder {
		border: 0;
	}

	&.noShadow {
		box-shadow: unset;
	}

	${props => props.theme.breakpoints.up("sm")} {
		margin-bottom: 160px;
	}

	.pers-info {
		margin-top: 16px;
	}

	.amount-sel p {
		font-size: 13px;
		font-weight: 600;
		line-height: 1.85;
		color: #222b45;
		margin-bottom: 2px;
	}

	.on-behalf-of {
		border-radius: 8px;
		background-color: #f7f9fc;
		.profile-wrapper {
			width: 60px;
		}
		.support-title {
			opacity: 1;
			color: #222b45;
			font-size: 15px;
			line-height: 20px;
			margin-bottom: 4px;
		}
		.team-group {
			opacity: 1;
			color: #222b45;
			font-size: 13px;
			font-weight: 600;
		}
		img {
			object-fit: cover;
			&.profile {
				width: 100%;
				height: 60px;
				border-radius: 99%;
			}

			&.profile-rounded {
				width: 40px;
				height: 40px;
				border-radius: 8px;
			}

			&.group {
				width: 24px;
				height: 24px;
				border-radius: 8px;
				border: 1px solid #ffffff;
			}
		}
	}
`;

export const AmountRadioWrapper = styled(ButtonBase)`
	border-radius: 8px;
	border: 1px solid #e4e9f2;
	background-color: #ffffff;
	.val {
		color: #222b45;
		font-size: 13px;
		font-weight: 600;
		line-height: 24px;
		&.gray-text {
			color: #8f9bb3;
		}
	}
	&.active-donation {
		border-color: #6173fe;
		background-color: #f6f6ff;
	}
	.MuiRadio-root {
		padding: 0;
		width: 18px;
		height: 18px;
		svg {
			width: 18px;
			height: 18px;
		}
	}
`;

export const LoaderWrapper = styled.div`
	align-items: center;
	justify-content: center;
	display: flex;
	margin-top: 90px;
	margin-bottom: 90px;
`;
