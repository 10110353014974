import React, { useCallback, useEffect } from "react";

import { useHistory, useLocation } from "react-router-dom";

import { routes as authRoutes } from "apps/Auth/constants";
import { routes as adminRoutes } from "apps/RegularUser/constants";

import { useAuth } from "modules/App/Data/hooks";

import { useRoutes, useUser } from "shared/hooks";

const SignInPage = () => {
	const history = useHistory();
	const { search } = useLocation();
	const { login, getUser, getFreshUserGlobalId, setEmail } = useAuth();
	const { getUserWorkspaces, setGlobalUserId } = useUser();
	const { clearStorage } = useRoutes();
	const query = new URLSearchParams(search);
	const token = query.get("token");
	const workspace = query.get("workspace");

	const verifyData = useCallback(async () => {
		try {
			const user = await getUser();
			const { globalUserId } = await getFreshUserGlobalId(user.email);
			setGlobalUserId(globalUserId);
			setEmail(user.email);
			getUserWorkspaces(user.email);
			history.push(adminRoutes.dashboard.getPath());
		} catch (error) {
			console.log(error);
		}
	}, [history, getUser, getUserWorkspaces, setGlobalUserId, setEmail, getFreshUserGlobalId]);

	useEffect(() => {
		if (token && workspace) {
			clearStorage();
			login(token, workspace);
			verifyData();
		} else {
			history.push(authRoutes.auth.signIn.getPath());
		}
	}, [token, workspace, history, login, clearStorage, verifyData]);

	return <p>LOADING...</p>;
};

export default SignInPage;
