import React from "react";

import { useParams } from "react-router-dom";

import { view } from "modules/Post";

const EditPostPage = () => {
	const { id } = useParams<{ id: string }>();

	return <view.CreateUpdatePost pid={id} />;
};

export default EditPostPage;
