import { buildRouteTree } from "utils/routeUtils";

export const memberRoutes = {
	home: null,
	member: {
		home: null,
		connections: null,
		events: null,
		event: null,
		groups: null,
		group: null,
		tracks: null,
		track: null,
		futureposts: null,
		futurepost: null,
		rewards: null,
		places: null,
		settings: null,
		collections: null,
		collection: null,
		help: null,
		notifications: null,
		categories: null,
		contributors: null,
		hashtags: null,
		messaging: null,
		fundraisers: null,
		liveconversation: {
			initiate: null,
			main: null,
			list: null
		},
		profile: null,
		hashtag: null,
		board: null,
		files: null,
		file: null,
		videos: null,
		video: null,
		albums: null,
		album: null,
		fillProfile: {
			date: null,
			marital: null,
			motherhood: null,
			selfie: null,
			welcome: null
		},
		post: null,
		podcasts: null,
		fundraiser: null,
		requests: null
	}
};

export const routesGenerator = (workspace: string, isWhiteLabel?: boolean) =>
	buildRouteTree(
		{
			home: null,
			member: {
				home: null,
				connections: null,
				events: null,
				event: null,
				groups: null,
				group: null,
				tracks: null,
				track: null,
				futureposts: null,
				futurepost: null,
				rewards: null,
				places: null,
				settings: null,
				collections: null,
				collection: null,
				help: null,
				notifications: null,
				categories: null,
				contributors: null,
				hashtags: null,
				messaging: null,
				fundraisers: null,
				liveconversation: {
					initiate: null,
					main: null,
					list: null
				},
				profile: null,
				hashtag: null,
				board: null,
				files: null,
				file: null,
				videos: null,
				video: null,
				albums: null,
				album: null,
				fillProfile: {
					date: null,
					marital: null,
					motherhood: null,
					selfie: null,
					welcome: null
				},
				post: null,
				podcasts: null,
				fundraiser: null,
				requests: null
			}
		},
		!isWhiteLabel ? workspace : undefined
	);
