import React, { ReactNode } from "react";

import { Wrapper } from "./style";

interface CardProps {
	children: ReactNode;
}

const PageWrapper: React.FC<CardProps> = ({ children }) => <Wrapper>{children}</Wrapper>;

export default PageWrapper;
