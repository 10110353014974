import React from "react";

import { useAuth } from "modules/App/Data";
import ConfirmActionModal from "shared/Components/ConfirmActionModal";
import { PhoneRequestResponse } from "shared/types";
import { Icon } from "shared/ui-kit";

import { ChangePhoneForm } from "./ChangePhoneForm/ChangePhoneForm";
import { PhoneVerificationCodeForm } from "./PhoneVerificationCodeForm";
import { DialogCloseButton, DialogSubtitle, DialogTitle, DialogWrapper } from "./styles";

import { concatPhone } from "../lib";

enum Step {
	CONFIRM = "CONFIRM",
	FIRST_VERIFICATION = "FIRST_VERIFICATION",
	CHANGE_PHONE_FORM = "CHANGE_PHONE_FORM",
	SECOND_VERIFICATION = "SECOND_VERIFICATION"
}

interface VerificationPhoneDataState {
	codeValidationId: PhoneRequestResponse["codeValidationId"] | null;
	userId: string | null;
}

interface Props {
	onClose: () => void;
	onFinishProcess: (params: { phoneCode: string; phoneNumber: string }) => void;
	currentPhoneCode: string | undefined;
	currentPhoneNumber: string | undefined;
}

export const ChangePhoneProcess: React.FC<Props> = ({
	onClose,
	currentPhoneCode,
	currentPhoneNumber,
	onFinishProcess
}) => {
	const isChangePhoneProcess = currentPhoneCode && currentPhoneNumber;
	const [step, setStep] = React.useState<Step>(() => (isChangePhoneProcess ? Step.CONFIRM : Step.CHANGE_PHONE_FORM));
	const [phoneData, setPhoneData] = React.useState<{ phoneCode: string; phoneNumber: string } | null>(
		isChangePhoneProcess
			? {
					phoneCode: currentPhoneCode,
					phoneNumber: currentPhoneNumber
			  }
			: null
	);
	const [verificationPhoneData, setVerificationPhoneData] = React.useState<VerificationPhoneDataState>({
		userId: null,
		codeValidationId: null
	});
	const { requestPhone } = useAuth();

	const handleConfirmAction = () => {
		setStep(Step.FIRST_VERIFICATION);
	};

	const handleSubmitVerificationCode = (userId: string) => {
		if (step === Step.FIRST_VERIFICATION) {
			setVerificationPhoneData({
				codeValidationId: null,
				userId
			});
			setStep(Step.CHANGE_PHONE_FORM);
		}

		if (step === Step.SECOND_VERIFICATION) {
			if (phoneData) {
				onFinishProcess(phoneData);
			}
			onClose();
		}
	};

	const handleSubmitChangePhoneForm = (params: {
		codeValidationId: PhoneRequestResponse["codeValidationId"];
		codeExpiration: PhoneRequestResponse["codeExpiration"];
		phoneCode: string;
		phoneNumber: string;
	}) => {
		if (params.phoneCode && params.phoneNumber) {
			setPhoneData({
				phoneCode: params.phoneCode,
				phoneNumber: params.phoneNumber
			});
			setVerificationPhoneData(prevState => ({
				...prevState,
				codeValidationId: params.codeValidationId
			}));
			setStep(Step.SECOND_VERIFICATION);
		}
	};

	React.useEffect(() => {
		if (step === Step.FIRST_VERIFICATION && currentPhoneCode && currentPhoneNumber) {
			requestPhone({
				phoneNumber: currentPhoneNumber,
				phoneCode: currentPhoneCode,
				deviceId: navigator.userAgent,
				exists: false
			}).then(res => setVerificationPhoneData(prevState => ({ ...prevState, codeValidationId: res.codeValidationId })));
		}
	}, [currentPhoneCode, currentPhoneNumber, requestPhone, step]);

	return (
		<>
			{step === Step.CONFIRM ? (
				<ConfirmActionModal
					open={true}
					onClose={onClose}
					title="Change your phone?"
					bodyText={`You’ll first get a verification code to your current phone number: ${concatPhone(
						phoneData?.phoneCode,
						phoneData?.phoneNumber
					)}`}
					onConfirm={handleConfirmAction}
				/>
			) : (
				<DialogWrapper open={true}>
					{step === Step.FIRST_VERIFICATION && (
						<>
							<DialogTitle>
								We sent a code to <br /> {concatPhone(phoneData?.phoneCode, phoneData?.phoneNumber)}. Enter it here.
							</DialogTitle>
							<PhoneVerificationCodeForm
								onSubmit={handleSubmitVerificationCode}
								verificationData={verificationPhoneData}
							/>
						</>
					)}
					{step === Step.CHANGE_PHONE_FORM && (
						<>
							<DialogTitle>{isChangePhoneProcess ? "Change Phone Number" : "Add Phone Number"}</DialogTitle>
							<DialogSubtitle>Use your new phone number to log in.</DialogSubtitle>
							<ChangePhoneForm onSubmit={handleSubmitChangePhoneForm} />
						</>
					)}
					{step === Step.SECOND_VERIFICATION && (
						<>
							<DialogTitle>
								We sent a code to <br /> {concatPhone(phoneData?.phoneCode, phoneData?.phoneNumber)}. Enter it here.
							</DialogTitle>
							<PhoneVerificationCodeForm
								onSubmit={handleSubmitVerificationCode}
								verificationData={verificationPhoneData}
							/>
						</>
					)}
					<DialogCloseButton onClick={onClose}>
						<Icon name="close" group="filled" fill="#8f9bb3" />
					</DialogCloseButton>
				</DialogWrapper>
			)}
		</>
	);
};
