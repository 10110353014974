import React, { memo, useCallback, useEffect, useMemo, useState } from "react";

import { useHistory } from "react-router";

import { UserFileSortBy } from "types";

import { useCategories } from "modules/Marketplace/Data";
import { CreateUpdateSeriesWrapper } from "shared/Components";
import { useMemberRoutes, useSeries } from "shared/hooks";

import { CategoriesWrapper } from "./style";

import {
	Delimiter,
	FilterBlock,
	FilterOptionType,
	InlineCategoryCard,
	ModelBlock,
	PageWrapper,
	SectionHeadline
} from "../../Components";
import { ModelBlockType } from "../../Components/ModelBlock";
import { ManInLotus, MessageText, NoConnectionsBlock, StyledButton } from "../Connections/style";
import { FilterType } from "../Files";

export interface CollectionsPageProps {
	groupId?: string;
}

const CollectionsPage: React.FC<CollectionsPageProps> = memo(({ groupId }) => {
	const { getCollections, setSeriesDialog, isUserCouldManageSeries, getData: getSeriesData } = useSeries();
	const { series, isLoading } = getSeriesData();

	const { getMemberRoutesData } = useMemberRoutes();
	const { routes } = getMemberRoutesData();

	const { getCategories, getCategoriesCount, getData: getCategoriesData } = useCategories();
	const { categories, categoriesCount, isLoading: loadingCategories } = getCategoriesData();

	const [filters, setFilters] = useState<FilterType>({ offset: 1, limit: 6, sortBy: UserFileSortBy.timestamp });
	const [offset, setOffset] = useState(1);
	const [categoriesLoaded, setCategoriesLoaded] = useState(false);

	useEffect(() => {
		getCollections({ ...filters, paginate: true, groupId });
	}, [filters, getCollections, groupId]);

	useEffect(() => {
		getCategories({
			limit: 12,
			offset,
			keyword: "",
			paginate: true,
			notEmptyBy: "series"
		});
	}, [getCategories, offset]);

	useEffect(() => {
		getCategoriesCount("series");
	}, [getCategoriesCount]);

	const history = useHistory();

	const skeleton = useMemo(() => {
		const count = categoriesCount - (categories?.length || 0);

		return count < 6 && count >= 0 ? (
			Array.from(Array(count).keys()).map((_, i) => <InlineCategoryCard skeleton key={i} />)
		) : (
			<>
				<InlineCategoryCard skeleton />
				<InlineCategoryCard skeleton />
				<InlineCategoryCard skeleton />
				<InlineCategoryCard skeleton />
				<InlineCategoryCard skeleton />
				<InlineCategoryCard skeleton />
			</>
		);
	}, [categories.length, categoriesCount]);

	const handleSeeAll = useMemo(
		() => `${routes?.member.collections.getPath()}/all${groupId ? `?groupId=${groupId}` : ""}`,
		[groupId, routes?.member.collections]
	);

	enum CollectionsFilters {
		MOST_RECENT = "MOST_RECENT",
		MOST_POPULAR = "MOST_POPULAR"
	}

	const filterOptions: FilterOptionType[] = useMemo(
		() => [
			{
				inlineOptions: true,
				onClick: item => {
					const sortBy = item === CollectionsFilters.MOST_POPULAR ? UserFileSortBy.popular : UserFileSortBy.timestamp;
					sortBy !== filters.sortBy &&
						setFilters({
							offset: 1,
							limit: 6,
							sortBy
						});
				},
				listOptions: [
					{
						label: "Most Recent",
						value: CollectionsFilters.MOST_RECENT,
						default: filters.sortBy === UserFileSortBy.timestamp
					},
					{
						label: "Most Popular",
						value: CollectionsFilters.MOST_POPULAR,
						default: filters.sortBy === UserFileSortBy.popular
					}
				]
			},
			{
				label: "Categories",
				listOptions: categories.map(({ name }) => ({
					label: name,
					value: name
				})),
				loading: loadingCategories,
				onClick: item => {
					const cId = categories.find(cat => cat.name === item)?._id;
					if (!cId) return;
					history.push(`${routes?.member.collections.getPath()}/all?cId=${cId}`);
				}
			}
		],
		[
			CollectionsFilters.MOST_POPULAR,
			CollectionsFilters.MOST_RECENT,
			categories,
			filters.sortBy,
			history,
			loadingCategories,
			routes?.member.collections
		]
	);

	const createOption = useMemo(() => {
		return isUserCouldManageSeries({})
			? {
					label: "Create Series",
					onClick: () => {
						setSeriesDialog({ open: true });
					},
					val: 1
			  }
			: undefined;
	}, [setSeriesDialog, isUserCouldManageSeries]);

	const handleFilterWillAppear = useCallback(
		async (item: string) => {
			if (item === "Categories" && !categoriesLoaded) {
				await getCategories({ limit: 20, offset: 1 });
				setCategoriesLoaded(true);
			}
		},
		[categoriesLoaded, getCategories]
	);

	return (
		<>
			<PageWrapper>
				<FilterBlock options={filterOptions} createOption={createOption} onShowOptions={handleFilterWillAppear} />
				<SectionHeadline
					title="Series and Collection"
					actionButton={{ text: "See All", onClick: () => history.push(handleSeeAll), link: handleSeeAll }}
				/>
				<ModelBlock
					skeletonNum={3}
					loading={isLoading}
					type={ModelBlockType.series}
					items={series || []}
					inlineView={false}
					keepCurrentData={isLoading && filters.offset > 1}
					onEndScroll={() => setFilters(ctx => ({ ...ctx, offset: ctx.offset + 1 }))}
					noContent={
						<NoConnectionsBlock>
							<ManInLotus />
							<MessageText>You don’t have any Series & Collections yet.</MessageText>
							{isUserCouldManageSeries({}) && (
								<StyledButton
									buttonTheme="light"
									palette="light"
									size="medium"
									removeSideMargin
									onClick={() => setSeriesDialog({ open: true })}
								>
									+ Create New Series
								</StyledButton>
							)}
						</NoConnectionsBlock>
					}
				/>
				{!!categories.length && !!series.length && (
					<>
						<Delimiter />
						<SectionHeadline
							title="Series Categories"
							actionButton={
								categoriesCount > categories.length && !loadingCategories
									? { text: "Load More", onClick: () => setOffset(offset + 1) }
									: undefined
							}
						/>
						<CategoriesWrapper>
							{categories.map(col => (
								<InlineCategoryCard key={col._id} cat={col} />
							))}
							{loadingCategories && (offset > 1 || !categories.length) && skeleton}
						</CategoriesWrapper>
					</>
				)}
			</PageWrapper>
			<CreateUpdateSeriesWrapper shouldUpdateIgnore />
		</>
	);
});

export default CollectionsPage;
