import { Box } from "@material-ui/core";

import { FixedSizeList as List } from "react-window";
import styled from "styled-components";

import { Dialog } from "shared/Components";
import { Avatar, Text } from "shared/ui-kit";

export const FilterWrapper = styled(Box)`
	margin: 16px 0;
	display: flex;
	align-content: center;
	justify-content: space-between;
	flex-direction: row-reverse;

	div,
	button :not(:first-child) {
		margin-left: 8px;
	}
`;

export const CustomDialog = styled(Dialog)`
	.MuiDialogContent-root {
		overflow: hidden;
	}
`;

export const TableWrapper = styled(Box)`
	height: 48vh;
	.MuiCheckbox-root:hover {
		background: none;
	}
`;

export const FooterWrapper = styled(Box)`
	display: flex;
	justify-content: space-between;
	width: 100%;
	align-items: baseline;
`;

export const PersonaList = styled(List)`
	width: 100%;
`;

export const Persona = styled(Box)``;

Persona.Wrapper = styled(Box)`
	display: flex;
	justify-content: space-between;
	align-items: center;

	padding: 5px 16px;

	border-bottom: 1px solid #e0e0e0;
`;

Persona.InfoColumn = styled(Box)`
	display: flex;
	align-items: center;
`;

Persona.Avatar = styled(Avatar)`
	width: 40px;
	height: 40px;

	border-radius: 4px;

	margin: 0 16px 0 0;
`;

Persona.Name = styled(Text)`
	font-size: 15px;
	font-weight: 600;
	line-height: 1.6;
	color: #222b45;
`;

Persona.CheckboxWrapper = styled(Box)`
	padding: 5px;
`;
