import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";

import { useHistory } from "react-router";

import { MemberDialog } from "modules/MemberHome/View/shared";

import { SkeletonBase } from "shared/Components";
import { emotionIcons } from "shared/constants";

import { useMemberRoutes, useOnScreen, useUser } from "shared/hooks";

import { MediaModel } from "shared/types";

import { Box, Text } from "shared/ui-kit";

import { determineSkinTone } from "utils/determineSkinTone";
import { getReaction } from "utils/getReaction";

import { ReactionRow, ReactionsList, ReactionsListTop, Wrapper } from "./style";

interface ReactType {
	reaction?: string;
	name: string;
	personaId: number;
	photos: MediaModel[];
}

const ReactionListDialog: FC<{
	open: boolean;
	onClose: () => void;
	handleRemoveReaction: () => void;
	pid?: string;
}> = ({ open, onClose, handleRemoveReaction, pid }) => {
	const [tabs, setTabs] = useState([
		{
			icon: "All",
			amount: 0,
			value: "all"
		},
		...emotionIcons.map(e => ({
			icon: e.icon,
			amount: 0,
			value: e.value
		}))
	]);
	const [reactions, setReactions] = useState<ReactType[]>([]);

	const [activeTab, setActiveTab] = useState("all");
	const [totalReactions, setTotalReactions] = useState(0);
	const [page, setPage] = useState(1);
	const [loading, setLoading] = useState(false);
	const lastDivRef = useRef(null);
	const onScreen = useOnScreen(lastDivRef);

	const { getUsersReaction, getActiveProfile, getData: getUserData } = useUser();
	const { user } = getUserData();

	const { getMemberRoutesData } = useMemberRoutes();
	const { routes } = getMemberRoutesData();

	const activeProfile = useMemo(() => getActiveProfile(user), [getActiveProfile, user]);

	const userReactions = useCallback(
		async pid => {
			setLoading(true);
			try {
				await getUsersReaction(pid, 10, page).then(({ data }) => {
					const newReactions = data.reactions.map(p => ({
						reaction: p.reaction.reaction,
						name: `${p.firstName} ${p.lastName}`,
						personaId: p.personaId,
						photos: p.photos as any[]
					}));

					setReactions(ctx => {
						const reactions = page === 1 ? newReactions : [...ctx, ...newReactions];

						setTabs(tabs =>
							tabs.map(tab => ({
								...tab,
								amount:
									tab.value === "all"
										? reactions.length
										: reactions.filter(r => r.reaction?.startsWith(tab.value)).length
							}))
						);

						return reactions;
					});

					setTotalReactions(data.totalReactions);
				});
			} catch (error) {
				console.error(error);
			} finally {
				setLoading(false);
			}
		},
		[getUsersReaction, setLoading, page]
	);

	useEffect(() => {
		if (open && pid) userReactions(pid);
	}, [open, pid, userReactions]);

	useEffect(() => {
		if (!loading && onScreen && reactions.length < totalReactions) {
			setPage(state => state + 1);
		}
	}, [loading, onScreen, totalReactions, reactions.length, setPage]);

	const history = useHistory();
	const goToProfile = useCallback(
		(personaId: number) => history.push(`${routes?.member.profile.getPath()}/${personaId}`),
		[history, routes?.member.profile]
	);

	const renderReactions = useMemo(() => {
		const tab = tabs.find(t => t.value === activeTab)?.value;
		if (tab) {
			const data = tab === "all" ? reactions : reactions.filter(r => r.reaction?.startsWith(tab));
			const myReactionIndex = data.findIndex(t => t.personaId === activeProfile?.personaId);
			const myReaction = data.find(t => t.personaId === activeProfile?.personaId);

			const reactionsData =
				myReaction && myReactionIndex >= 0
					? [myReaction, ...data.filter(t => t.personaId !== activeProfile?.personaId)]
					: data;

			return reactionsData.map(t => {
				const isMyReaction = t.personaId === activeProfile?.personaId;
				return (
					<ReactionRow
						reactionColor={determineSkinTone(t.reaction || "")}
						key={t.personaId}
						className={isMyReaction && "active-reaction"}
						onClick={
							isMyReaction
								? () => {
										setPage(1);
										handleRemoveReaction();
								  }
								: () => goToProfile(t.personaId)
						}
					>
						<div className="row">
							<img src={t?.photos[0]?.profilePicture} alt={t.name} />
							<div className="paragraphs">
								<Text>
									{t.name} {isMyReaction && "(You)"}
								</Text>
								{isMyReaction && <Text className="remove">Click to remove</Text>}
							</div>
						</div>
						<div className="reaction">{t.reaction && getReaction(t.reaction)?.icon}</div>
					</ReactionRow>
				);
			});
		}
	}, [activeTab, activeProfile?.personaId, goToProfile, handleRemoveReaction, reactions, tabs]);

	return (
		<MemberDialog open={open} onClose={onClose} customWidth={432} noFooter title="Post Reactions">
			<Wrapper>
				<ReactionsListTop>
					{tabs
						.filter(t => !!t.amount)
						.map((tab, index) => (
							<ReactionsListTop.Button
								onClick={() => setActiveTab(tab.value)}
								className={`${tab.value === activeTab && "active"}`}
								key={index}
							>
								<span className="reaction">{tab.icon}</span> {tab.amount}
							</ReactionsListTop.Button>
						))}
				</ReactionsListTop>
				<ReactionsList>{renderReactions}</ReactionsList>
				<div ref={lastDivRef} />
				{loading && (
					<Box className="pt-0 p-2">
						<Box className="flex mb-4 items-center">
							<SkeletonBase className="mr-6" variant="text" width={40} height={60} />
							<SkeletonBase className="mr-6" variant="circle" width={30} height={30} />
							<SkeletonBase className="mr-6" variant="circle" width={30} height={30} />
							<SkeletonBase className="mr-6" variant="circle" width={30} height={30} />
						</Box>
						{Array.from(Array(4).keys()).map((_, i) => (
							<React.Fragment key={i}>
								<Box className="flex justify-between items-center">
									<Box className="flex items-center w-2/4">
										<SkeletonBase className="mr-4" variant="text" width={40} height={50} />
										<SkeletonBase variant="text" width="80%" height={10} />
									</Box>
									<SkeletonBase variant="circle" width={30} height={30} />
								</Box>
							</React.Fragment>
						))}
					</Box>
				)}
			</Wrapper>
		</MemberDialog>
	);
};

export default ReactionListDialog;
