import React from "react";

import { OutlinedTextFieldProps } from "@material-ui/core";

import clsx from "clsx";
import InputMask from "react-input-mask";

import InputBase, { InputProps } from "./InputBase";

export interface MaskInputProps extends InputProps {
	hideBorders?: boolean;
	maskChar?: string;
	onChange: OutlinedTextFieldProps["onChange"];
	inputRef?: React.MutableRefObject<HTMLInputElement> | React.RefObject<HTMLInputElement>;
}

const MaskInput: React.FC<MaskInputProps> = ({ hideBorders, ...props }) => {
	const { mask, value, maskChar = "_", disabled, size, ...rest } = props;

	return (
		<InputMask mask={mask!} maskChar={maskChar} value={value || ""} disabled={disabled} {...rest}>
			{inputProps => (
				<InputBase className={clsx(hideBorders && "hideBorders")} {...inputProps} size={size} disabled={disabled} />
			)}
		</InputMask>
	);
};

export default MaskInput;
