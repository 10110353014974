import React from "react";

import { useCommunity, useMarketing } from "shared/hooks";

import useNotification from "shared/hooks/useNotification";

import JoinPopupTemplate from "./template";

interface JoinPopupProps {
	isMarketing?: boolean;
	workspaceName?: string;
	showJoinPopup?: boolean;
	setShowJoinPopup?: (show: boolean) => void;
	allowToClose?: boolean;
}

const JoinPopup: React.FC<JoinPopupProps> = ({
	isMarketing,
	workspaceName,
	showJoinPopup,
	setShowJoinPopup,
	allowToClose
}) => {
	const { getData: getCommunityData, communityColors } = useCommunity();
	const { workspace, showJoinPopup: communityShowJoinPopup } = getCommunityData();

	const { showMessage } = useNotification();

	const { sendAppLink } = useMarketing();
	const displayName = isMarketing
		? workspaceName
		: workspace?.whiteLabelApp
		? workspace?.meta?.displayName || workspace?.name
		: "vyoo";

	return (
		<JoinPopupTemplate
			primaryColor={!isMarketing && communityColors.primary}
			isMarketing={isMarketing}
			open={isMarketing ? !!showJoinPopup : communityShowJoinPopup}
			onClose={() => (isMarketing || allowToClose ? setShowJoinPopup && setShowJoinPopup(false) : null)}
			workspaceName={displayName}
			onSendAppLink={(code, phone) => {
				sendAppLink(code, phone, `${displayName}`);
				showMessage("Link was sent.", 3);
			}}
			allowToClose={allowToClose}
		/>
	);
};

export default JoinPopup;
