import React, { useCallback, useMemo } from "react";

import { DateTime } from "luxon";

import { ReactComponent as MessageFilledIcon } from "assets/icons/icon-chat-bubble.svg";
import { ReactComponent as CommentOffIcon } from "assets/icons/liveconvo/comment-off.svg";
import { ReactComponent as CopyIcon } from "assets/icons/liveconvo/copy.svg";
import { ReactComponent as FlagIcon } from "assets/icons/liveconvo/flag.svg";
import { ReactComponent as HandOffIcon } from "assets/icons/liveconvo/hand-off.svg";
import { ReactComponent as HandIcon } from "assets/icons/liveconvo/hand.svg";
import { ReactComponent as HandFilledIcon } from "assets/icons/liveconvo/icon-hand-fill.svg";
import { ReactComponent as LayoutIcon } from "assets/icons/liveconvo/layout.svg";
import { ReactComponent as MemberIcon } from "assets/icons/liveconvo/member.svg";
import { ReactComponent as MessageIcon } from "assets/icons/liveconvo/message.svg";
import { ReactComponent as PinIcon } from "assets/icons/liveconvo/pin.svg";
import { ReactComponent as PortraitIcon } from "assets/icons/liveconvo/portrait.svg";

import { useLiveConversation } from "modules/LiveConversation/Data/hooks";
import { SidebarType } from "modules/LiveConversation/Data/types";
import { MenuDots, MenuDotsOption } from "shared/Components";

import { useMemberRoutes } from "shared/hooks";
import useNotification from "shared/hooks/useNotification";
import { Tooltip } from "shared/ui-kit";
import { ReactComponent as MemberFilledIcon } from "shared/ui-kit/assets/icons/filled/user.svg";

import { OperationBox } from "./style";

import { useFirebaseLiveConversation } from "../../../Data/hooks";

interface ConversationOperationProps {
	canSeeRaisedHands: boolean;
	personaId?: number;
	raiseHand: (personaId: number, flag: boolean | string) => void;
	warnConvo: () => void;
	isAdmin: boolean;
}

const iconProps = {
	fill: "#8F9BB3",
	width: 20,
	height: 20
};

const ConversationOperation: React.FC<ConversationOperationProps> = ({
	canSeeRaisedHands,
	personaId,
	raiseHand,
	warnConvo,
	isAdmin
}) => {
	const { toggleSideBar, setPinnedUser, toggleTileMode, getData: getLiveConversationData } = useLiveConversation();
	const { event, raisedHands, sidebarType, config, firebaseParticipants, totalComments, isTiles, pinnedPersonaId } =
		getLiveConversationData();

	const { updateConversationConfig } = useFirebaseLiveConversation();
	const { showMessage } = useNotification();

	const { getMemberRoutesData } = useMemberRoutes();
	const { routes } = getMemberRoutesData();

	const { enableHandRaise, enableComments } = config;

	const sidebarClick = (type: SidebarType) => {
		if (type === "hands" && !canSeeRaisedHands && personaId) {
			return raiseHand(personaId, raisedHands[personaId] ? false : DateTime.now().toUTC().toISO());
		}
		toggleSideBar(type);
	};

	const totalParticipants = firebaseParticipants.length;
	const totalRaisedHands = firebaseParticipants.filter(participant => !!raisedHands[participant.personaId]).length;

	const getTotalCounts = (count: number) => {
		return count < 100 ? count : "99+";
	};

	const onLayoutChange = () => {
		if (pinnedPersonaId) return setPinnedUser();
		toggleTileMode();
	};

	const onCopyLink = useCallback(() => {
		if (!event) return;
		const link = `${document.location.origin}${routes?.member.event.getPath()}/${event.eventId}`;
		navigator.clipboard.writeText(link);
		showMessage("Link copied to clipboard");
	}, [event, routes?.member.event, showMessage]);

	const menuOptions = useMemo(
		(): MenuDotsOption[] => [
			{
				name: `Turn ${enableComments ? "off" : "on"}  commenting`,
				onClick: () => updateConversationConfig({ enableComments: !enableComments }),
				icon: <CommentOffIcon {...iconProps} />
			},
			{
				name: `${enableHandRaise ? "Disable" : "Enable"} raise hands`,
				onClick: () => updateConversationConfig({ enableHandRaise: !enableHandRaise }),
				icon: <HandOffIcon {...iconProps} />
			},
			{
				name: "Copy event link",
				onClick: onCopyLink,
				icon: <CopyIcon {...iconProps} />
			},
			{
				name: "End entire conversation",
				onClick: warnConvo,
				icon: <FlagIcon {...iconProps} />
			}
		],
		[enableHandRaise, enableComments, updateConversationConfig, warnConvo, onCopyLink]
	);

	const toggleParticipants = () => {
		sidebarClick(SidebarType.participants);
	};

	const toggleComments = () => {
		sidebarClick(SidebarType.comments);
	};

	const toggleHands = () => {
		sidebarClick(SidebarType.hands);
	};

	return (
		<OperationBox>
			<OperationBox.ItemWrapper>
				<Tooltip text={"Participants"}>
					<OperationBox.Item onClick={toggleParticipants}>
						{sidebarType === SidebarType.participants ? (
							<MemberFilledIcon className={"active fill-second"} />
						) : (
							<MemberIcon fill={"#ffffff"} />
						)}
						{totalParticipants > 0 && (
							<OperationBox.ItemBadge>{getTotalCounts(totalParticipants)}</OperationBox.ItemBadge>
						)}
					</OperationBox.Item>
				</Tooltip>
			</OperationBox.ItemWrapper>
			{enableComments && (
				<OperationBox.ItemWrapper>
					<Tooltip text={"Comments"}>
						<OperationBox.Item onClick={toggleComments}>
							{sidebarType === SidebarType.comments ? (
								<MessageFilledIcon width={24} height={24} fill="#6173fe" />
							) : (
								<MessageIcon fill="#ffffff" />
							)}
							{totalComments > 0 && <OperationBox.ItemBadge>{getTotalCounts(totalComments)}</OperationBox.ItemBadge>}
						</OperationBox.Item>
					</Tooltip>
				</OperationBox.ItemWrapper>
			)}
			{enableHandRaise && (
				<OperationBox.ItemWrapper>
					<Tooltip text={"Raise Hand"}>
						<OperationBox.Item onClick={toggleHands}>
							{sidebarType === SidebarType.hands ? (
								<HandFilledIcon fill="#6173fe" viewBox="0 2 24 25" />
							) : (
								<HandIcon viewBox="0 2 24 25" fill="#ffffff" />
							)}
							{totalRaisedHands > 0 && (
								<OperationBox.ItemBadge>{getTotalCounts(totalRaisedHands)}</OperationBox.ItemBadge>
							)}
						</OperationBox.Item>
					</Tooltip>
				</OperationBox.ItemWrapper>
			)}
			{totalParticipants > 1 && (
				<OperationBox.ItemWrapper>
					<Tooltip text={"Change View"}>
						<OperationBox.Item onClick={onLayoutChange}>
							{pinnedPersonaId ? (
								<PinIcon fill="#ffffff" />
							) : isTiles ? (
								<LayoutIcon fill="#ffffff" />
							) : (
								<PortraitIcon fill="#ffffff" />
							)}
						</OperationBox.Item>
					</Tooltip>
				</OperationBox.ItemWrapper>
			)}
			{isAdmin && <MenuDots options={menuOptions} inverted />}
		</OperationBox>
	);
};

export default ConversationOperation;
