import { HTMLAttributes } from "react";

import styled from "styled-components";

const LoaderContainer = styled.div<HTMLAttributes<HTMLDivElement> & { height?: number }>`
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: ${props => (props.height ? `${props.height}px` : "100vh")};
`;

export default LoaderContainer;
