import React from "react";

import Grid, { GridProps } from "@material-ui/core/Grid";
import styled from "styled-components";

export type FormRowProps = GridProps & { customStyle?: string };

export default styled(
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	({ customStyle, ...props }: FormRowProps): JSX.Element => (
		<Grid classes={{ root: "form-row" }} container spacing={3} {...props} />
	)
)`
	margin-top: 12px;
	margin-bottom: 12px;
	${props => props.customStyle};
`;
