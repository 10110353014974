import React, { useCallback, useEffect, useMemo, useState } from "react";

import { ParsableDate } from "@material-ui/pickers/constants/prop-types";

import { DateTime } from "luxon";

import { ReactComponent as BellIcon } from "assets/icons/iconBellFilled.svg";
import { ReactComponent as MuteBellIcon } from "assets/icons/mute-notify.svg";

import { PlaceholderImage, UnlockPremiumBtn } from "shared/Components";
import { useEvent, useMemberRoutes } from "shared/hooks";

import { EventModel, PlaceholderImageType, UnlockContentType } from "shared/types";

import { ActionIconWrapper, RowBox } from "./style";

import { ModelItem } from "../index";

interface EventCardProps {
	event: EventModel;
	loading?: boolean;
	isLiveUpcoming?: boolean;
}

const EventCard: React.FC<EventCardProps> = ({ event, loading, isLiveUpcoming }) => {
	const [isInterested, setIsInterested] = useState<boolean>(event?.interested);
	const [isAttending, setIsAttending] = useState<boolean>(event?.attending);

	const [eventInfo, setEventInfo] = useState<EventModel>();
	const { setEventIntent } = useEvent();

	const { getMemberRoutesData } = useMemberRoutes();
	const { routes } = getMemberRoutesData();

	useEffect(() => {
		setEventInfo(event);
	}, [event]);

	const zone = useMemo(() => Intl.DateTimeFormat().resolvedOptions().timeZone, []);

	const eventRange = useMemo(() => {
		if (event) {
			const start = DateTime.fromISO(event.startTime ? event.startTime : "", { zone }).toFormat("MMM d, h:mm a");
			const end = DateTime.fromISO(event.endTime ? event.endTime : "", { zone }).toFormat("MMM d, h:mm a");

			return `${start} - ${end}`;
		}
	}, [event, zone]);

	const getTimeTitle = useCallback(
		(startTime: ParsableDate, endTime: ParsableDate): string => {
			const now = new Date();
			const start = new Date(startTime ? startTime.toString() : "");
			const end = new Date(endTime ? endTime.toString() : "");
			if (now > end) {
				return "Ended";
			}
			if (now < start) {
				return eventRange || "Upcoming";
			}
			return "Happening now";
		},
		[eventRange]
	);

	const getMiles = useCallback((meter: number): number => {
		return Math.round(meter * 0.000621371192);
	}, []);

	const setIntent = e => {
		e.stopPropagation();
		const interested = !isInterested;
		const going = interested ? false : isAttending;

		if (eventInfo?.eventId) setEventIntent(going, eventInfo?.eventId, interested);

		setIsInterested(interested);
		setIsAttending(going);
	};

	const Icon = isInterested ? MuteBellIcon : BellIcon;

	const href = useMemo(
		() =>
			eventInfo &&
			`${routes?.member.event.getPath()}/${eventInfo.eventId}${
				eventInfo?.groupId ? `?groupId=${eventInfo.groupId}` : ""
			}`,
		[eventInfo, routes?.member.event]
	);

	if (!eventInfo) return null;

	return (
		<ModelItem
			loading={loading}
			item={{
				_id: eventInfo.eventId,
				wrapByHref: true,
				href,
				img: !!eventInfo.eventImages.length ? eventInfo.eventImages[0] : undefined,
				placeholderImg: (
					<PlaceholderImage
						type={PlaceholderImageType.EVENT_DETAILS}
						width={320}
						height={160}
						viewBox={"0 0 720 320"}
					/>
				),
				beforeTitle: getTimeTitle(eventInfo.startTime, eventInfo.endTime),
				title: eventInfo.title,
				subtitle: eventInfo.isOnline ? "Online event" : eventInfo?.location?.address,
				noSubtitleDots: true,
				underTitle: eventInfo.onlineUrl,
				peopleList: eventInfo.potentialMatches.map(x => (x.photos.length ? x.photos[0].profilePicture : "")),
				moreAttendees: (eventInfo.totalAttendess || 0) - eventInfo.potentialMatches.length,
				distance: eventInfo.location && !isLiveUpcoming ? getMiles(eventInfo.distance) : undefined,
				showLiveBadge: isLiveUpcoming,
				action: isLiveUpcoming && (
					<RowBox>
						<ActionIconWrapper onClick={setIntent}>
							<Icon fill={"#6173fe"} />
						</ActionIconWrapper>
						{eventInfo.locked && (
							<UnlockPremiumBtn
								premiumObject={eventInfo}
								premiumObjectType={UnlockContentType.EVENT}
								onUnlock={() => {
									setEventInfo(e => (e ? { ...e, locked: false } : undefined));
								}}
								text="Buy"
								ignoreWidth
							/>
						)}
					</RowBox>
				)
			}}
		/>
	);
};

export default EventCard;
