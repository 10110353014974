import React from "react";

import { useHistory } from "react-router";

import { view } from "modules/LoyaltyRewards";
import { useFeature, useMemberRoutes } from "shared/hooks";

const LoyaltyRewardsPage = () => {
	const history = useHistory();

	const { getMemberRoutesData } = useMemberRoutes();
	const { routes: memberRoutes } = getMemberRoutesData();

	const { isFeatureEnabled } = useFeature();

	if (!isFeatureEnabled("isLoyaltyEnabled")) {
		history.push(memberRoutes?.member.home.getPath());
	}

	return <view.Rewards />;
};

export default LoyaltyRewardsPage;
