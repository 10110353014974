import React, { memo, useCallback, useEffect, useMemo, useState } from "react";

import { ForumPersonaModel } from "types";

import { PlaceholderImage } from "shared/Components";
import { useGroup, useMemberRoutes, useMembers } from "shared/hooks";
import { ContributorModel, PlaceholderImageType } from "shared/types";

import { ItemLink } from "./style";

import { InfoItem, List } from "../index";

interface Props {
	contributorsUrl?: string;
	preview?: boolean;
	slug?: string;
}

const Contributors: React.FC<Props> = memo(({ contributorsUrl, preview, slug }) => {
	const { fetchContributors, getContributors, getData: getMembersData } = useMembers();
	const { contributors, totalContributors } = getMembersData();

	const { getGroupTopContrib } = useGroup();

	const { getMemberRoutesData } = useMemberRoutes();
	const { routes } = getMemberRoutesData();

	const [page, setPage] = useState(1);
	const [stopFetching, setStopFetching] = useState(false);
	const [contributorList, setContributorList] = useState<ContributorModel[]>(contributors || []);
	const [isLoading, setIsLoading] = useState(false);

	const pageSize = useMemo(() => (preview ? 5 : 10), [preview]);

	const fetchData = useCallback(
		async (offset, limit) => {
			setIsLoading(true);
			if (slug) {
				const users = await getGroupTopContrib(slug, limit, offset);
				setContributorList(ctb => (offset === 1 ? users : ctb.concat(users)));
				users.length < limit && setStopFetching(true);
			} else {
				const contributorsData = await fetchContributors({ offset, limit });
				setContributorList(ctb =>
					offset === 1 ? contributorsData.contributors : ctb.concat(contributorsData.contributors)
				);
				contributorsData.contributors.length < limit && setStopFetching(true);
			}
			setIsLoading(false);
		},
		[fetchContributors, setContributorList, setIsLoading, setStopFetching, getGroupTopContrib, slug]
	);

	useEffect(() => {
		if (preview) {
			setContributorList(contributors);
		}
	}, [preview, contributors, totalContributors]);

	useEffect(() => {
		if (preview) {
			getContributors({ limit: 5, offset: 1 });
		} else {
			fetchData(page, pageSize);
		}
	}, [fetchData, page, pageSize, preview, getContributors]);

	const getPhoto = useCallback((persona: ForumPersonaModel): string | undefined => {
		return persona?.photos?.length ? persona.photos[0].profilePicture : undefined;
	}, []);

	const onLoadMore = useCallback(() => {
		!stopFetching && setPage(page => page + 1);
	}, [setPage, stopFetching]);

	const RenderItem = useCallback(
		(item: ContributorModel) => (
			<ItemLink to={`${routes?.member.profile.getPath()}/${item.persona.personaId}`}>
				<InfoItem
					avatarUrl={getPhoto(item.persona)}
					title={`${item.persona.firstName} ${item.persona.lastName}`}
					subtitle={`${
						item.persona.counters.postCount > 500 ? "+500" : slug ? item.postCount : item.persona.counters.postCount
					} Posts`}
					placeholderImg={
						<PlaceholderImage
							type={PlaceholderImageType.PROFILE_IMAGE}
							width={48}
							height={48}
							viewBox={"0 0 400 400"}
						/>
					}
				/>
			</ItemLink>
		),
		[getPhoto, routes?.member.profile, slug]
	);

	return (
		<List
			title={"Top contributors"}
			items={contributorList || []}
			preview={preview}
			seeAllLink={contributorsUrl}
			renderItem={RenderItem}
			loading={isLoading}
			onLoadMore={onLoadMore}
		/>
	);
});

export default Contributors;
