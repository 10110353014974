export interface BaseEngageNotificationModel {
	_id?: string;
	title?: string;
	body?: string;
	creatorId: string;
	status: EngageNotificationStatuses;
	received: number;
	sent: number;
	opened: number;
	scheduleDate?: string;
	createdAt: string;
	updatedAt: string;
}

export interface EngageNotificationsModel extends BaseEngageNotificationModel {
	creator: {
		firstName: string;
		lastName: string;
	}[];
}

export interface EngageNotificationsModelResponse {
	notifications: EngageNotificationsModel[];
}

export interface EngageNotificationModel
	extends Pick<BaseEngageNotificationModel, "body" | "title" | "scheduleDate" | "_id"> {
	date?: Date;
	time?: string;
	isScheduleLater?: boolean;
}

export interface EngageNotificationAPiResponse {
	success: boolean;
	notification: BaseEngageNotificationModel;
}

export enum EngageNotificationStatuses {
	scheduled = "scheduled",
	sent = "sent"
}

export interface getEngageNotificationsDataType {
	offset: number;
	limit: number;
	keyword?: any;
}
