import { ProfileType } from "./UserModel";

export enum FundraiserStatusEnum {
	PENDING = "Pending",
	ACTIVE = "Active",
	ENDED = "Ended",
	PAUSED = "Paused",
	ARCHIVED = "Archived"
}

export interface TopContrib {
	name: string;
	amount: number;
	image: string | null;
}

export interface GroupModel {
	_id: string;
	name: string;
	slug: string;
	totalMembers: number;
	coverUrl?: string;
}

export interface FundraiserModel {
	_id: string;
	name: string;
	description?: string;
	startDate: string;
	endDate: string;
	goalAmount: number;
	groupIds: string[];
	groups: GroupModel[];
	videoUrl: string | null;
	thumbnail: {
		mediaId: string | null;
		url: string | null;
	};
	status?: FundraiserStatusEnum;
	totalViews: number;
	totalDonated: number;
	totalDonations: number;
	creatorPersonaDocId: string;
	memberCount: number;
	totalContacts: number;
	createdAt: string;
	updatedAt: string;
	creator: ProfileType;
	top: TopContrib[];
}

export interface GroupAndMembersModel extends GroupModel {
	members: ProfileType[];
}

export enum SponsorStatusEnum {
	PENDING = "pending",
	SENT = "sent",
	OPENED = "opened",
	DONATED = "donated"
}

export interface OnBehalfOfPersonaModel {
	_id: string;
	firstName: string;
	lastName: string;
	photos: {
		mediaID: string;
		id: string;
		name: string;
		active: boolean;
		profilePicture: string;
	}[];
	personaId: number;
	group: {
		_id: string;
		bbUserId: number;
		personaId: number;
		isMember: boolean;
		isAdmin: boolean;
		isOwner: boolean;
		isModerator: boolean;
		isPending: boolean;
		isInvited: boolean;
		isInfluencer: boolean;
		groupId: string;
		userId: string | null;
		updatedAt: string;
		postCount: number;
	}[];
}

export interface SponsorModel {
	_id: string;
	personaId: number;
	creatorId: string;
	messageBody: string;
	relationship: string;
	fundraiserId: string;
	name: string;
	amountRaised: number;
	contactInfo: {
		email: string;
		phone?: string;
	};
	image?: {
		mediaId: string | null;
		url: string | null;
	};
	status: SponsorStatusEnum;
	hiddenByInvitor: boolean;
	emailLastSentAt: string | null;
	smsLastSentAt: string | null;
	smsResponseReceivedAt: string | null;
	smsCommunicationStatus: string | null;
	declinedCommunication: boolean;
	updatedAt: string;
	createdAt: string;
	link: string;
	onBehalfOfPersona: OnBehalfOfPersonaModel[];
}

export interface GroupAndSponsorsModel extends GroupModel {
	sponsors: Array<SponsorModel>;
}
