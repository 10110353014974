import { useMemo } from "react";

import config from "config/appConfig";

import useJwt from "shared/services/api/core/jwt";
import useHttpActions from "shared/services/api/core/useHttpActions";
import BaseConverter from "shared/services/converters/baseConverter";

import { PhoneRequest, PhoneRequestResponse, PhoneVerifyRequest, PhoneVerifyResponse } from "shared/types";
import IWorkspace from "shared/types/IWorkspace";

import { CheckForPasswordResponse } from "./../types/VerifyType";

import { EnvApplicationNames } from "../../../../types";

import { API_URLS } from "../../constants";
import { GetVerificationType, VerifyType } from "../types";

const useAuthApiService = (isMarketing = false) => {
	const params = useMemo(() => (isMarketing ? { customErrorHandlers: [] } : undefined), [isMarketing]);

	const actions = useHttpActions(params);
	const { clearJwt, setJwt, getJwt } = useJwt();

	const whiteLabelWorkspace = useMemo(() => {
		return config.GLOBAL_CONSTANTS.ENV_APPLICATION_NAME === EnvApplicationNames.Akina
			? config.GLOBAL_CONSTANTS.AKINA_WORKSPACE
			: config.GLOBAL_CONSTANTS.ENV_APPLICATION_NAME === EnvApplicationNames.Relias
			? config.GLOBAL_CONSTANTS.HOLLIBLU_WORKSPACE
			: config.GLOBAL_CONSTANTS.ENV_APPLICATION_NAME === EnvApplicationNames.Bitcoin
			? config.GLOBAL_CONSTANTS.BITCOIN_WORKSPACE
			: "";
	}, []);

	return useMemo(
		() => ({
			logIn: async (token: string, workspace: string): Promise<void> => {
				setJwt(token, workspace);
			},
			getUser: async <T>(): Promise<T> => {
				return BaseConverter.convert(await actions.get(`${API_URLS.LOGIN.GET_USER}?token=${getJwt()}`));
			},
			getVerificationLink: async (email: string): Promise<GetVerificationType> => {
				return BaseConverter.convert<GetVerificationType>(
					await actions.post(API_URLS.LOGIN.GET_VERIFICATION_LINK, {
						email,
						type: "link"
					})
				);
			},
			fetchRefreshToken: async (refreshToken: string) => {
				return BaseConverter.convert<{ token: string; refreshToken: string }>(
					await actions.post(API_URLS.EMAIL.REFRESH_TOKEN, { refreshToken })
				);
			},
			verify: async (
				email: string,
				code: string | null,
				receiveNews: boolean,
				password?: string
			): Promise<VerifyType> => {
				return BaseConverter.convert<VerifyType>(
					await actions.post(
						whiteLabelWorkspace ? API_URLS.WHITELABEL_USER.EMAIL_VERIFY : API_URLS.LOGIN.VERIFY_LINK,
						whiteLabelWorkspace
							? {
									email,
									password,
									code: null
							  }
							: {
									email,
									password,
									code,
									receiveNews,
									deviceId: "123", // TODO: MediaDeviceInfo.deviceId ?
									detectCommunities: true
							  },
						whiteLabelWorkspace
							? {
									headers: {
										workspace: whiteLabelWorkspace
									}
							  }
							: undefined
					)
				);
			},
			updateEmail: async (email: string, userId: string): Promise<{ success: boolean }> => {
				return BaseConverter.convert<any>(
					await actions.put(
						whiteLabelWorkspace
							? API_URLS.WHITELABEL_USER.GET_VERIFICATION_LINK
							: API_URLS.WHITELABEL_USER.UPDATE_EMAIL(userId),
						whiteLabelWorkspace ? { email, userId } : { email },
						undefined,
						whiteLabelWorkspace ? { headers: { workspace: whiteLabelWorkspace } } : undefined
					)
				);
			},
			updateUserPassword: async ({
				password,
				fromDeepLink,
				token
			}: {
				password: string;
				fromDeepLink: boolean;
				token?: string;
			}) => {
				const params: { password: string; fromDeepLink?: boolean } = {
					password
				};
				if (!whiteLabelWorkspace) {
					params.fromDeepLink = fromDeepLink;
				}

				return BaseConverter.convert(
					await actions.put(
						whiteLabelWorkspace ? API_URLS.WHITELABEL_USER.UPDATE_PASSWORD_V2 : API_URLS.PASSWORD.UPDATE_PASSWORD_V2,
						params,
						null,
						whiteLabelWorkspace
							? {
									headers: {
										workspace: whiteLabelWorkspace,
										Authorization: token || ""
									}
							  }
							: {
									headers: {
										Authorization: token || ""
									}
							  }
					)
				);
			},
			resetUserPassword: async (password: string, token) => {
				return BaseConverter.convert<{ status: boolean }>(
					await actions.put(API_URLS.PASSWORD.RESET_PASSWORD, {
						token,
						password
					})
				);
			},
			resetWhitelabelUserPassword: async (password: string, token: string, email: string, workspace: string) => {
				return BaseConverter.convert<{ status: boolean }>(
					await actions.post(
						API_URLS.WHITELABEL_USER.RESET_PASSWORD,
						{
							token,
							password,
							email
						},
						{
							headers: {
								workspace
							}
						}
					)
				);
			},
			logOut: async (): Promise<void> => {
				await actions.post(API_URLS.LOGIN.POST_LOGOUT, {});
				clearJwt();
			},
			verifyOnboardingToken: async ({
				communityUrl,
				invitationId,
				invitationCode,
				userId
			}: {
				communityUrl: string;
				invitationId: string;
				userId: string;
				invitationCode?: string;
			}) => {
				return BaseConverter.convert<IWorkspace[]>(
					await actions.post(API_URLS.TOKEN.VERIFY_ONBOARDING_TOKEN, {
						communityUrl,
						invitationId,
						userId,
						invitationCode: invitationCode || null
					})
				);
			},
			confirmInvitation: async ({
				communityUrl,
				invitationCode,
				userId,
				invitationId
			}: {
				communityUrl: string;
				invitationCode?: string;
				userId: string;
				invitationId?: string;
			}) => {
				return BaseConverter.convert<IWorkspace[]>(
					await actions.post(API_URLS.TOKEN.VERIFY_ONBOARDING_TOKEN, {
						communityUrl,
						invitationCode,
						invitationId,
						userId
					})
				);
			},
			checkInvitationValidation: async ({
				invitationId,
				userId,
				customWorkspace
			}: {
				invitationId: string;
				userId?: string;
				customWorkspace?: string;
			}) => {
				return BaseConverter.convert<{ success: boolean }>(
					await actions.get(
						API_URLS.TOKEN.VERIFY_INVITATION_TOKEN,
						{ invitationId, userId },
						whiteLabelWorkspace || customWorkspace
							? {
									headers: {
										workspace: customWorkspace || whiteLabelWorkspace
									}
							  }
							: undefined
					)
				);
			},
			getUserGlobalId: async (email: string) => {
				return BaseConverter.convert<CheckForPasswordResponse>(
					await actions.post(
						whiteLabelWorkspace ? API_URLS.WHITELABEL_USER.GET_VERIFICATION_LINK : API_URLS.LOGIN.GET_VERIFICATION_LINK,
						whiteLabelWorkspace
							? {
									email
							  }
							: {
									email,
									type: "link"
							  },
						whiteLabelWorkspace
							? {
									headers: {
										workspace: whiteLabelWorkspace
									}
							  }
							: undefined
					)
				);
			},
			forgotPassword: async (email: string) => {
				return BaseConverter.convert<{ success: true }>(
					await actions.post(
						whiteLabelWorkspace ? API_URLS.WHITELABEL_USER.FORGOT_PASSWORD : API_URLS.PASSWORD.FORGOT_PASSWORD,
						{ email },
						whiteLabelWorkspace
							? {
									headers: {
										workspace: whiteLabelWorkspace
									}
							  }
							: undefined
					)
				);
			},
			checkUserVerification: async (globalUserId: string) => {
				return BaseConverter.convert<{
					gracePeriodExpired: boolean;
					verified: boolean;
					passedGuidedTour: boolean;
					passedGuidedTourObject: {
						web: {
							member: boolean;
							admin: boolean;
						};
					};
				}>(await actions.get(API_URLS.EMAIL.EMAIL_VERIFIED(globalUserId)));
			},
			resendVerificationEmail: async (globalUserId: string) => {
				return BaseConverter.convert<{ success: true }>(
					await actions.get(API_URLS.EMAIL.RESEND_VERIFICATION(globalUserId))
				);
			},
			resendWhitelabelVerificationEmail: async (whitelabelUserId: string) => {
				return BaseConverter.convert<{ success: true }>(
					await actions.get(API_URLS.EMAIL.RESEND_WHITELABEL_VERIFICATION(whitelabelUserId))
				);
			},
			joinCommunity: async (email: string, communityUrl: string) => {
				return BaseConverter.convert<IWorkspace[] | { success: boolean; userOd: string }>(
					await actions.post(API_URLS.LOGIN.JOIN, {
						email,
						communityUrl
					})
				);
			},
			getWorkspaces: async (email: string) => {
				return BaseConverter.convert(await actions.post(API_URLS.WORKSPACES, { email }));
			},
			requestPhone: async (req: PhoneRequest) => {
				return BaseConverter.convert<PhoneRequestResponse>(await actions.post(API_URLS.PHONE.REQUEST, req));
			},
			verifyPhone: async (req: PhoneVerifyRequest) => {
				return BaseConverter.convert<PhoneVerifyResponse>(await actions.post(API_URLS.PHONE.VERIFY, req));
			}
		}),
		[actions, clearJwt, getJwt, setJwt, whiteLabelWorkspace]
	);
};

export default useAuthApiService;
