export function creditCardType(cc: string) {
	const amex = new RegExp("^3[47][0-9]{13}$");
	const visa = new RegExp("^4[0-9]{12}(?:[0-9]{3})?$");

	const mastercard = new RegExp("^5[1-5][0-9]{14}$");
	const mastercard2 = new RegExp("^2[2-7][0-9]{14}$");

	if (visa.test(cc)) {
		return "Visa";
	}
	if (amex.test(cc)) {
		return "American Express";
	}
	if (mastercard.test(cc) || mastercard2.test(cc)) {
		return "MasterCard";
	}

	return undefined;
}
