import { IMessage } from "react-gifted-chat";

import { RenderActionType } from "modules/Messaging/View/Components/ChatBubble";

import { GifModel, GroupModel, MediaObjType, MembersModel, PersonStoryModel } from "shared/types";
import { CarpoolMessage } from "shared/types/CarpoolTypes";
import { EventMessageModel } from "shared/types/EventModel";

export interface getInboxDataType {
	offset: number;
	limit: number;
	personaId?: number;
	keyword?: string;
	id?: string;
	pinned?: boolean;
}

export interface getRecentConnectionDataType {
	offset: number;
	limit: number;
	personaId?: number;
	keyword?: string;
}

export interface getCreateGroupDatatype {
	personaId?: number;
	isOwner: boolean;
	isMember: boolean;
	notificationEnabed?: boolean; // typo on backend
}

export interface getGroupChatIdDatatype {
	groupChatId?: string;
}

export interface createMessageDataType {
	senderId?: number;
	recipientId: number | null;
	timestamp: number | Date;
	lastMessage: string;
	groupChatId?: string;
}

export type ChatStatus = "accepted" | string;

export enum ChatType {
	CONNECTION = "connection",
	GROUP = "group",
	FORUM_GROUP_CHAT = "forumGroupChat",
	EVENT_CHAT = "eventChat",
	COMMUNITY_CHAT = "communityChat"
}

export interface InboxItem {
	_id?: string;
	chatUserId?: string;
	allowMembersInvite: boolean;
	createdAt?: string;
	deletedAt?: string | null;
	removedAt?: string | null;
	initiatorId: string;
	connectionId?: string;
	lastMessage: string;
	members: MembersModel[];
	name: string;
	firstName?: string;
	lastName?: string;
	open?: boolean;
	photo?: MediaObjType;
	photos?: { profilePicture: string }[];
	profilePhoto?: string;
	status?: ChatStatus;
	type: ChatType;
	unReadCount?: number;
	updatedAt?: string;
	personaId?: number;
	notifyToken?: any;
	purpose?: any;
	pinned?: boolean;
}

export interface ConnectionItem extends InboxItem {
	personaId?: number;
}

export interface RecentConnections {
	recentConnections: {
		recentConnections: InboxItem[];
	};
}

export interface InboxModel {
	connections: InboxItem[];
}

export interface RecentConnectionModel {
	recentConnections: RecentConnections;
}

export interface CreateGroupModel {
	groupChat: InboxItem;
}

export interface ConnectionSearchModel {
	personas: InboxItem[];
}

export interface ReplyMessage {
	id: string;
	uid: string;
	name: string;
	text: string;
}

export interface StoryReply extends PersonStoryModel {
	activeStoryId: string;
}

export type ChatMessage = Omit<IMessage, "createdAt"> & {
	order: number;
	uid?: string;
	images?: string | any;
	videos?: string | any;
	audios?: string | any;
	files?: string | any;
	location?: string;
	position?: string;
	place?: EventMessageModel;
	disconnect?: string;
	topic?: string;
	group?: GroupModel;
	profile?: string;
	payreq?: string;
	track?: string;
	system?: boolean;
	file?: string;
	fileType?: string;
	fileName?: string;
	reply?: ReplyMessage;
	storyReply?: StoryReply;
	edited?: ChatMessage;
	reacted?: boolean;
	recentLikes: { chatUserId: string; reaction: string }[];
	createdAt?: Date | number;
	lastMessage?: string;
	gif?: GifModel;
	type?: RenderActionType;
	carpool?: CarpoolMessage;
	canceledCarpool?: boolean;
};
