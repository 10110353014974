import React, { FC, useCallback, useMemo, useState } from "react";

import { useCommunity } from "shared/hooks";

import { SwitchWrapper } from "./style";

export enum SwitchOptions {
	BASIC = "variant__basic",
	MEMBER = "variant__member"
}
interface Option {
	label: string;
	value: string | number;
}
const TwoOptionsSwitch: FC<{
	optionOne: Option;
	optionTwo: Option;
	onChange: (data: string | number) => void;
	variants?: SwitchOptions;
	disableAnimation?: boolean;
	small?: boolean;
	transformXZero?: boolean;
}> = ({ optionOne, optionTwo, onChange, variants = SwitchOptions.MEMBER, disableAnimation, small, transformXZero }) => {
	const [activeOpt, setActiveOpt] = useState(optionOne.value);

	const { communityColors } = useCommunity();

	const handleChange = useCallback(
		(val: string | number) => {
			setActiveOpt(val);
			onChange(val);
		},
		[onChange]
	);

	const BASE_CLASSES = useMemo(() => `${variants} ${variants}--child ${small ? "small" : ""}`, [small, variants]);

	return (
		<SwitchWrapper className={`${variants} ${variants}--wrapper`}>
			<SwitchWrapper.ActiveBG
				color={communityColors.primary}
				className={`${activeOpt === optionTwo.value ? "opt-two" : ""} ${variants} ${variants}--child ${
					!disableAnimation ? "animate-change" : ""
				} ${transformXZero ? "transformXZero" : ""}`}
			/>
			<SwitchWrapper.Option
				color={communityColors.primary}
				onClick={() => handleChange(optionOne.value)}
				className={`${activeOpt === optionOne.value ? "active" : ""} ${BASE_CLASSES}`}
			>
				{optionOne.label}
			</SwitchWrapper.Option>
			<SwitchWrapper.Option
				color={communityColors.primary}
				onClick={() => handleChange(optionTwo.value)}
				className={`${activeOpt === optionTwo.value ? "active" : ""} ${BASE_CLASSES} opt-two`}
			>
				{optionTwo.label}
			</SwitchWrapper.Option>
		</SwitchWrapper>
	);
};

export default TwoOptionsSwitch;
