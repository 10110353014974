import React, { useMemo, useState } from "react";

import { Link, useLocation } from "react-router-dom";

import { NavMenuItemModel, UserRoles } from "types";

import { useCommunity, useFeature, useGuidedTour, useScrollbar, useUser } from "shared/hooks";
import { Icon } from "shared/ui-kit";

import ExpandItem from "./ExpandItem";

import { ListWrapper, MemberPanelBtnWrapper, MenuLink, NavLabel, Wrapper } from "./styles";

import SmartScroll from "../SmartScroll";
import { SwitchRoleViewBtn } from "../index";
interface NavPanelProps {
	items: NavMenuItemModel[];
	setupProgress: number;
	sidebarCollapsed: boolean;
	memberHomeUrl: string;
	stickyTop: number | undefined;
}

const NavPanel: React.FC<NavPanelProps> = ({ items, setupProgress, sidebarCollapsed, memberHomeUrl, stickyTop }) => {
	const location = useLocation();
	const [expandedItem, setExpandedItem] = useState<string | null>(null);

	const { getData: getUserData } = useUser();
	const { user } = getUserData();
	const { isFeatureEnabled, isAnyFeatureEnabled } = useFeature();

	const { getData: getCommnunityData } = useCommunity();
	const { mainAdminColor, isBitcoinMode } = getCommnunityData();

	const { getData: getGuidedTourData, setChecklistAutoOpen } = useGuidedTour();
	const { checklistAutoOpen } = getGuidedTourData();

	const { scrollBarToTop } = useScrollbar();

	const pathname = useMemo(() => location.pathname, [location.pathname]);

	return (
		<Wrapper stickyTop={stickyTop} id="navpanel-wrapper" className={sidebarCollapsed ? "collapsed" : ""}>
			<SmartScroll>
				{!isBitcoinMode && (
					<MemberPanelBtnWrapper
						onClick={() => {
							checklistAutoOpen && setChecklistAutoOpen(false);
						}}
					>
						<SwitchRoleViewBtn label="Visit Community" link={memberHomeUrl} />
					</MemberPanelBtnWrapper>
				)}
				<ListWrapper>
					{items
						.filter(x => (x.featureValueName ? isFeatureEnabled(x.featureValueName as string) : true))
						.map((info, i) => {
							const parentHighlight =
								pathname.includes(`${info.route}`) || !!info.children?.some(x => pathname.includes(`${x.route}`));

							const availableChildren = info.children
								?.filter(x =>
									typeof x.featureValueName === "object"
										? isAnyFeatureEnabled(x.featureValueName)
										: isFeatureEnabled(x.featureValueName)
								)
								.map(child => {
									let isShow = true;
									if (child.updateLabel) {
										let isAutoConnect = false;
										let isAutoMessage = false;
										child?.featureValueName &&
											(child?.featureValueName as string[]).forEach(element => {
												if (element === "enableConnecting") isAutoConnect = isFeatureEnabled(element);
												if (element === "enableChat") isAutoMessage = isFeatureEnabled(element);
											});
										const label =
											isAutoConnect && isAutoMessage
												? "Autoconnect / Message"
												: isAutoConnect
												? "Auto Connect"
												: isAutoMessage
												? "Automated Message"
												: "";
										child.label = label;
									}

									if (child.restrictTo) {
										for (let i = 0; i < child.restrictTo.length; i++) {
											const restrictionRole = child.restrictTo[i];
											switch (restrictionRole) {
												case UserRoles.Owner:
													isShow = user?.role === UserRoles.Owner;
													break;

												case UserRoles.Admin:
													isShow = user?.role === UserRoles.Admin;
													break;

												case UserRoles.Moderator:
													isShow = user?.role === UserRoles.Moderator;
													break;
											}
											if (!isShow) {
												break;
											}
										}
									}
									return isShow ? child : null;
								})
								?.filter(x => x);

							if (!!availableChildren?.length) {
								return (
									<ExpandItem
										key={i}
										item={info}
										subItems={availableChildren as NavMenuItemModel[]}
										highlighted={parentHighlight}
										expandedItem={expandedItem}
										setExpandedItem={setExpandedItem}
										scrollToTop={scrollBarToTop}
										color={mainAdminColor}
									/>
								);
							}

							if (info.route !== null) {
								return (
									<MenuLink to={info.route} component={Link} key={i} id={info.id || ""}>
										<NavLabel.Container
											data-step={info.label.toLowerCase()}
											highlighted={+parentHighlight}
											color={mainAdminColor}
											first={+(setupProgress === 100 && !i)}
										>
											<NavLabel.IconWrapper>
												<Icon
													group={info.iconGroup || ""}
													fill={parentHighlight ? mainAdminColor || "#6173fe" : "#c5cee0"}
													name={info.icon}
												/>
											</NavLabel.IconWrapper>
											<NavLabel.Title active={+parentHighlight} color={mainAdminColor}>
												{info.label}
											</NavLabel.Title>
										</NavLabel.Container>
									</MenuLink>
								);
							}
							return null;
						})}
				</ListWrapper>
			</SmartScroll>
		</Wrapper>
	);
};

export default NavPanel;
