import React, { useEffect, useState } from "react";

import { SeeAllBtn } from "modules/MemberHome/View/Components";
import { useMemberRoutes, useUser } from "shared/hooks";

import { Card, CardContent, Header } from "./styles";

import { useMessaging } from "../../../Data/hooks";
import { InboxPanel } from "../index";

interface MessageCardProps {
	onChangeUnreadCount?: (count: number) => void;
	onClose: () => void;
	messagingPageUrl: string;
	show?: boolean;
}

const MessageCard: React.FC<MessageCardProps> = ({ show, onChangeUnreadCount, onClose, messagingPageUrl }) => {
	const { getData: getMessagingData } = useMessaging();
	const { inbox } = getMessagingData();

	const { getData: getUserData } = useUser();
	const { isMemberView } = getUserData();

	const { getMemberRoutesData } = useMemberRoutes();
	const { routes } = getMemberRoutesData();

	const [widgetOffset, setWidgetOffset] = useState(1);

	useEffect(() => {
		onChangeUnreadCount &&
			onChangeUnreadCount(
				inbox.reduce((total, item) => {
					return total + (item?.unReadCount || 0);
				}, 0)
			);
	}, [inbox, onChangeUnreadCount]);

	if (!show) return null;

	return (
		<Card>
			<Header title="Recent Messages" />
			<CardContent>
				<CardContent.Body>
					<InboxPanel
						widget
						messagingPageUrl={messagingPageUrl}
						widgetOffset={widgetOffset}
						setWidgetOffset={setWidgetOffset}
					/>
				</CardContent.Body>
				<CardContent.Footer>
					<SeeAllBtn
						text="See All Messages"
						link={isMemberView ? routes?.member.messaging.getPath() : "/messaging"}
						click={onClose}
					/>
				</CardContent.Footer>
			</CardContent>
		</Card>
	);
};

export default MessageCard;
