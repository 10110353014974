import React, { useCallback, useMemo } from "react";

import { Box, IconButton } from "@material-ui/core";
import TimeAgo from "react-timeago";

import { MemberDialog } from "modules/MemberHome/View/shared";

import { useMessaging } from "modules/Messaging/Data";
import { useAlbums } from "shared/hooks";
import { Icon, Text } from "shared/ui-kit";

import ImagePreview from "./ImagePreview";
import PDFPreview from "./PDFPreview";
import { PreviewDialogWrapper } from "./style";

const PreviewDialog = () => {
	const { getData: getMessagingDate, setPreviewDialog } = useMessaging();
	const { previewDialog } = getMessagingDate();
	const { readSong } = useAlbums();

	const URL = useMemo(() => previewDialog?.file.url, [previewDialog?.file.url]);

	const handleDownload = useCallback(async () => {
		if (!URL) return;
		const data = await readSong(URL);
		const link = document.createElement("a");
		link.href = window.URL.createObjectURL(
			new Blob([data], {
				type: "application/octet-stream"
			})
		);

		link.download = previewDialog?.file.name
			? previewDialog?.file.name
			: previewDialog?.file.type === "PDF"
			? "file.pdf"
			: "image.jpg";
		document.body.appendChild(link);
		link.click();
	}, [URL, previewDialog?.file, readSong]);

	const isPDF = useMemo(() => previewDialog?.file.type === "PDF", [previewDialog?.file.type]);

	return (
		<MemberDialog title="" open={true} onClose={setPreviewDialog} customWidth={960} noHeader noFooter>
			<PreviewDialogWrapper.Header>
				<PreviewDialogWrapper.Profile>
					<img src={previewDialog?.user.avatar} alt={previewDialog?.user.name} />
					<Box className="profile-info">
						<Text variant="subtitle1">{previewDialog?.user.name}</Text>
						<Text variant="body2">
							<TimeAgo
								date={previewDialog?.file.createdAt}
								live={false}
								formatter={(value, unit, suffix) =>
									value < 60 && unit === "second" ? "Just now" : `${value} ${unit} ${suffix}`
								}
							/>{" "}
							- {previewDialog?.file.name}
						</Text>
					</Box>
				</PreviewDialogWrapper.Profile>
				<IconButton size="small" onClick={() => setPreviewDialog()}>
					<Icon name="close" group="filled" />
				</IconButton>
			</PreviewDialogWrapper.Header>
			{isPDF ? (
				<PDFPreview URL={URL} handleDownload={handleDownload} />
			) : (
				<ImagePreview URL={URL} handleDownload={handleDownload} />
			)}
		</MemberDialog>
	);
};

export default PreviewDialog;
