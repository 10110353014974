import { useMemo } from "react";

import axios from "axios";
import config from "config/appConfig";

import BaseConverter from "shared/services/converters/baseConverter";

import { YoutubeVideoModel } from "../types";

const useEventApiService = () => {
	const methods = useMemo(
		() => ({
			searchVideoById: async (videoId: string): Promise<YoutubeVideoModel> => {
				return BaseConverter.convert(
					await axios({
						url: `${config.GLOBAL_CONSTANTS.GOOGLE_YOUTUBE_API_LINK}/videos`,
						method: "GET",
						params: {
							id: videoId,
							part: "snippet,status",
							key: config.GLOBAL_CONSTANTS.GOOGLE_YOUTUBE_API_KEY
						}
					})
				);
			}
		}),
		[]
	);

	return useMemo(
		() => ({
			...methods
		}),
		[methods]
	);
};

export default useEventApiService;
