import { Box } from "@material-ui/core";

import styled from "styled-components";

export const SelectWrapper = styled(Box)`
	width: 75%;
	.MuiFormControl-root {
		.MuiSelect-root {
			display: flex;
			align-items: center;
		}

		input {
			display: none;
		}
	}
	${props => props.theme.breakpoints.up("sm")} {
		width: auto;
		.MuiFormControl-root {
			width: 160px;
		}
	}
`;
