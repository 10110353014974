import { Box } from "@material-ui/core";
import styled from "styled-components";

import { Text } from "shared/ui-kit";

export const CategoriesWrapper = styled(Box)`
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
	grid-gap: 16px;
`;

export const ContentTitle = styled(Text)`
	margin: 16px 0 8px;
`;
