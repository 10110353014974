import { Box, Chip, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import styled, { css } from "styled-components";

import { ReactComponent as BanIcon } from "assets/icons/componentsWarning.svg";
import { Button, Checkbox, FormColumn, FormContainer, FormFooter, Icon, Loader, Text } from "shared/ui-kit";

export const ModalOverlay = styled(Box)`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	background-color: rgba(25, 36, 53, 0.4);
	z-index: 1300;
	.MuiDialog-container {
		height: 100vh;
	}
	.MuiDialogContent-root {
		&:first-child {
			padding: 0;
		}
	}
	.MuiDialog-paperWidthMd {
		border-radius: 16px;
		background-image: linear-gradient(to left bottom, #4a99f9, #368bfd, #2b7cff, #306bfe, #4157fb);
		background-color: #4157fb;
		max-width: 740px;
		overflow: hidden;
		padding: 26px 40px;
		color: white;
		a {
			color: white;
		}
		${props => props.theme.breakpoints.up("sm")} {
			max-height: 280px;
		}
	}
`;

export const FilterRow = styled(Box)`
	display: flex;
	align-items: center;

	${props => props.theme.breakpoints.down("sm")} {
		width: 100%;
	}
`;

export const DropDownAndButtonWrapper = styled(Box)`
	display: flex;
	justify-content: center;
	align-items: center;

	form {
		width: 100%;
		margin-right: 16px;
	}

	.MuiInputBase-root {
		padding: 2px 0 !important;
	}
`;

export const StyledButton = styled(Button)<{ marginTop?: number }>`
	background-color: ${({ transparent }) => (transparent ? "transparent" : "#6173fe")};
	padding: 8px 18px;
	font-size: 11px;
	color: ${({ transparent }) => (transparent ? "#8f9bb3" : "white")};

	&.Mui-disabled {
		color: ${({ disabled }) => disabled && "#8f9bb3"};
		background-color: ${({ disabled }) => disabled && "#edf1f7"};
	}

	@media (min-width: 991px) {
		padding: 13px 33px;
		font-size: 12px;
		white-space: nowrap;
		margin-left: 18px;
		margin-top: ${({ marginTop }) => marginTop}px;
	}
`;

export const UserDetailsWrapper = styled(Box)<{ margin: number }>`
	margin: ${props => props.margin};
`;

export const UserDetails = styled(Text)`
	color: #222b45;
	font-size: 13px;
	line-height: 16px;
	margin-bottom: 0;
	padding-top: 4px;

	.logged-info {
		color: #8f9bb3;
		margin: 4px 0;
		font-size: 13px;
		line-height: 1.23;
	}

	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	width: 100%;
`;

export const AddMemberModalWrapper = styled(Box)`
	.MuiDialogContent-root:first-child {
		padding-top: 8px;
	}
	.MuiPaper-elevation24 {
		box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%);
	}
	${props => props.theme.breakpoints.down("sm")} {
		.paper-full-width {
			width: 100%;
			margin: 0;
		}
	}
	${props => props.theme.breakpoints.up("sm")} {
		.MuiDialog-paperWidthSm {
			max-width: ${props => (props.dialogWidth ? props.dialogWidth : "648px")};
		}
		.MuiDialog-scrollPaper {
			display: flex;
		}
	}
`;

export const AddMemberModalHeader = styled(Box)`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 12px 16px;
	width: 100%;
	border-bottom: 1px solid #edf1f7;
	margin-bottom: 8px;
	h5 {
		font-size: 18px;
	}
`;

export const StyledFormContainer = styled(FormContainer)`
	padding: ${props =>
		props.page === "inviteViaEmail" ? "20px 0" : props.page === "shareableLinkList" ? "0 8px" : "14px 8px"};
	${props =>
		props.page !== "inviteViaEmail" &&
		css`
			padding-right: 0;
		`}
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 0;
	form {
		width: 100%;
	}

	${props => props.theme.breakpoints.up("sm")} {
		flex-direction: row;
		justify-content: center;
		margin-bottom: 20px;
	}
`;

export const StyledForm = styled.form`
	width: 100%;
`;

export const ControllerWrapper = styled(Box)`
	width: 100%;
	padding: 0 24px;
	height: 400px;
	overflow-y: auto;

	.invite-field {
		margin-bottom: 20px;
	}

	${props => props.theme.breakpoints.up("sm")} {
		display: flex;
		padding: 0 32px;
		height: auto;
		.invite-field {
			padding: 0 8px;
			margin-bottom: 0;
		}
	}
`;

export const StyledFormColumn = styled(FormColumn)`
	margin-top: ${props => !props.noMt && "20px"};
	display: flex;
`;

export const StyledFormFooter = styled(FormFooter)`
	width: 100%;
	padding: 16px;
	border-top: 1px solid #edf1f7;
	button {
		font-size: 12px;
		padding: 12px 24px;
	}
`;

export const StyledAutoComplete = styled(Autocomplete)<{ isNotInvited: boolean }>`
	.MuiOutlinedInput-notchedOutline {
		border-color: ${({ isNotInvited }) => (isNotInvited ? "#c5cee0" : "#ff463d")} !important;
	}

	.MuiInputLabel-outlined.MuiInputLabel-shrink {
		color: ${({ isNotInvited }) => (isNotInvited ? "#c5cee0" : "#ff463d")} !important;
	}

	.MuiInputBase-root {
		align-items: flex-start;
		justify-content: flex-start;
	}

	.MuiInputBase-input {
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
		padding: 0 0 211px !important;
		min-width: 90px;

		height: 32px;
		margin-top: 2px;
		margin-left: 4px;
	}

	.MuiAutocomplete-endAdornment {
		top: 10px;
	}
`;

export const StyledTextField = styled(TextField)<{ isInvited: boolean }>`
	.MuiChip-root {
		background: none;
	}
	.MuiInputBase-input {
		display: flex;
		align-items: center;
	}
`;

export const StyledChip = styled(Chip)<{ isInvited: boolean }>`
	span {
		color: ${({ isInvited }) => (isInvited ? "#222b45" : "#ff463d")};
		border: 1px solid ${({ isInvited }) => (isInvited ? "#c5cee0" : "#ff463d")};
		border-radius: 30px;
		padding-right: 20px;
		padding-top: 3px;
		padding-bottom: 3px;
	}
	.MuiChip-deleteIcon {
		width: 12px;
		height: 12px;
		margin: 0 5px 0 -17px;
		color: ${({ isInvited }) => (isInvited ? "#8f9bb3" : "#ff463d")};
	}
`;

export const IconWrapper = styled(Box)`
	display: flex;
	padding: 7px 8px;
	align-items: center;
	justify-content: center;
	background-color: #f7f9fc;
`;

export const StatusText = styled(Text)<{ status: string }>`
	color: ${({ status }) => {
		switch (status) {
			case "JOINED":
				return "#23b34a";
			case "ON_BOARDED":
				return "#4a99f9";
			case "PENDING":
				return "#ffd044";
			default:
				return "#ff463d";
		}
	}};
	margin: 0;
`;

export const StyledCheckbox = styled(Checkbox)`
	.PrivateSwitchBase-input-40 {
		width: 18px;
		height: 18px;
		opacity: initial;
		margin: 5px 12px;
	}
`;

export const InviteMemberWrapper = styled(Box)`
	border-radius: 4px;
	text-align: center;
	background-color: #ffffff;
	box-shadow: inset 0 0 0 0.5px #c5cee0;
	box-sizing: border-box;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	height: 120px;
	width: 160px;
	margin-bottom: 20px;

	&:hover {
		cursor: pointer;
	}

	${props => props.theme.breakpoints.up("sm")} {
		margin-bottom: 0;
		margin: 0 10px;
	}
`;

export const InviteMemberText = styled(Text)`
	line-height: 1.6;
	color: #8f9bb3;
	font-size: 15px;
	font-weight: 600;
	padding-bottom: 12px;
`;

export const IconTextWrapper = styled(Box)`
	display: flex;
	justify-content: flex-start;
	align-items: center;

	.MuiTypography-root {
		font-size: 13px;
	}

	svg {
		width: 20px;
		height: 20px;
		margin-right: 8px;
	}
`;

export const InviteMembersModalIcon = styled(Icon)`
	width: 24px;
	height: 24px;
`;

export const InviteMemberActionText = styled(Text)`
	color: #222b45;
	font-size: 15px;
	font-weight: 600;
	margin-bottom: 6px;
	${props => props.theme.breakpoints.up("sm")} {
		margin-bottom: 12px;
	}
`;

export const FilterText = styled(Text)`
	flex: 1;
`;

export const PaginationWrapper = styled(Box)`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	padding: 10px 24px;

	.MuiPagination-root {
		.MuiPagination-ul {
			li {
				&:first-child,
				&:last-child {
					background-color: #f0f3fe;

					button {
						color: #6173fe;
					}
				}

				.MuiButtonBase-root {
					color: #222b45;
				}

				.MuiPaginationItem-page.Mui-disabled {
					svg {
						color: #8f9bb3;
					}
				}

				.MuiPaginationItem-page.Mui-selected {
					color: #fff;
					background: #6173fe;
				}

				.MuiPaginationItem-icon {
					font-size: 1.8rem;
				}
			}
		}
	}

	.MuiToolbar-root {
		.MuiTablePagination-selectRoot {
			padding: 0 6px;
			background: #f7f9fc;
			display: flex;
			align-items: center;
		}

		.MuiSelect-select {
			color: #6173fe;
			text-align-last: center;
		}

		.MuiSelect-icon {
			right: 7px;
		}

		.MuiTablePagination-actions {
			display: none;
		}
	}
`;

export const StyledLoader = styled(Loader)`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 100px;
`;

export const StyledInvalidEmailAddressText = styled(Text)`
	font-size: 12px;
	color: #ff463d;
	margin-top: 4px;
	margin-left: 15px;
	margin-bottom: 0;
`;

export const EmptyBody = styled(Box)`
	width: 100%;
	min-height: 600px;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	background: white;
`;

export const EmptyNote = styled(Text)`
	font-size: 18px;
	font-weight: bold;
	line-height: 1.33;
	letter-spacing: normal;
	text-align: center;
	color: #222b45;

	margin: 22px auto 0 auto;

	max-width: 220px;
`;

export const BanIconWrapper = styled(BanIcon)`
	use {
		fill: #8f9bb3;
	}
`;

export const ButtonWrapper = styled(Box)`
	display: flex;
	justify-content: center;
	${props => props.theme.breakpoints.up("sm")} {
		${({ removeBottomMargin }) =>
			removeBottomMargin &&
			css`
				margin-bottom: -16px;
			`}
	}
`;

export const InviteInputsRow = styled(Box)`
	padding: 0 8px;
	.MuiInputBase-root.Mui-disabled {
		color: rgb(143, 155, 179);
		background-color: rgb(247, 249, 252);
	}

	${({ mt }) =>
		mt &&
		css`
			margin-top: 26px;
		`}

	${props => props.theme.breakpoints.up("sm")} {
		display: flex;
		padding: 0 16px;
		margin-bottom: 16px;
	}
`;

export const InviteInput = styled(Box)`
	display: flex;
	flex-direction: column;
	padding: 0 8px;
	width: 100%;
	margin-bottom: 12px;

	${props => props.theme.breakpoints.up("sm")} {
		margin-bottom: 0;

		&.expiration {
			width: 65%;
		}
		&.usenum {
			width: 35%;
		}
	}
`;

export const FooterWrapper = styled(Box)`
	display: flex;
	justify-content: flex-end;
	padding: 12px 16px 0;
	&.center {
		justify-content: center;
	}
	&.full {
		button {
			width: 100%;
		}
	}
	${props => props.theme.breakpoints.up("sm")} {
		margin-bottom: -16px;
		padding: 12px 24px 0;
		&.full button {
			width: auto;
		}
	}
`;

export const QrCodeContainer = styled(Box)`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
`;
