import { Box, CardHeader, Card as MuiCard, CardContent as MuiCardContent } from "@material-ui/core";
import styled from "styled-components";

import { Button } from "shared/ui-kit";

export const Card = styled(MuiCard)`
	&.MuiPaper-rounded {
		width: 85%;
		border-radius: 12px;
		z-index: 2;
		box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.16);

		${props => props.theme.breakpoints.up("sm")} {
			&.MuiPaper-rounded {
				width: 400px;
			}
		}
	}
`;

export const CardContent = styled(MuiCardContent)`
	padding: 0;
	&.MuiCardContent-root:last-child {
		padding-bottom: 0;
	}
`;

CardContent.Body = styled(Box)`
	height: 350px;
	max-height: 60vh;
	${props => props.theme.breakpoints.up("md")} {
		height: 500px;
	}
`;

CardContent.Footer = styled(Box)`
	z-index: 100;
	background: white;
	position: relative;
	border-top: 1px solid #edf1f7;
	padding: 16px;
`;

export const Header = styled(CardHeader)`
	color: #222b45;
	border-bottom: 1px solid #edf1f7;
	padding: 16px 26px;
	.MuiTypography-root {
		font-size: 15px;
		font-weight: 600;
		line-height: 1.6;
		color: #222b45;
	}
`;

export const SeeAllButton = styled(Button)`
	font-size: 14px;
	font-weight: bold;
	line-height: 1.14;
	text-align: center;
	color: #222b45;
	height: 40px;
	padding: 12px 0;
	border-radius: 8px;
	background-color: #edf1f7;
	border: 0;
`;
