import React, { FC, useCallback, useEffect, useMemo, useState } from "react";

import clsx from "clsx";
import { Controller, useForm } from "react-hook-form";

import { LabeledInput, SelectInput } from "modules/MemberHome/View/shared";

import { SelectInputOption } from "modules/MemberHome/View/shared/SelectInput";
import { SelectOptionsWrapper } from "modules/MemberHome/View/shared/SelectInput/style";
import { useDebounce, useFundraisers, useUser } from "shared/hooks";
import { MediaModel } from "shared/types";
import { Loader } from "shared/ui-kit";

import { validateEmail, validatePhoneNumber } from "utils/serviceUtils/validators";

import ConfirmLeavePopup from "../ConfirmLeave";
import useConfirmLeavePopup from "../ConfirmLeave/hooks/useConfirmLeavePopup";

import Dialog from "../Dialog";
import { StyledActionButton } from "../Video/style";

const InviteContactsManageView: FC<{
	onClose: () => void;
	handleCreate: (data: any) => void;
	handleUpdate: (data: any) => void;
}> = ({ onClose, handleCreate, handleUpdate }) => {
	const { getData: getFundraisersData, getFundraiserMembers } = useFundraisers();
	const { submitting, inviteDialog, inviteDialogModel } = getFundraisersData();

	const [keyword, setKeyword] = useState("");
	const [behalfOfOptions, setBehalfOfOptions] = useState<SelectInputOption[]>([]);

	const debouncedKeyword = useDebounce(keyword, 500);

	const {
		handleLeavePageConfirmed,
		closeConfirmPopup,
		handleClose,
		getData: getConfirmLeavePopupData
	} = useConfirmLeavePopup({
		onClose,
		open: true
	});

	const { showConfirmPopup } = getConfirmLeavePopupData();

	const {
		control,
		formState: { isDirty, isValid, errors },
		watch,
		setValue,
		handleSubmit
	} = useForm({ mode: "onChange" });

	const [numberEmailError, setNumberEmailError] = useState("");

	const { getData: getUserData } = useUser();
	const { user } = getUserData();
	const isPriviliged = useMemo(
		() => user?.isGlobalAdmin || user?.isGlobalOwner || user?.isGlobalModerator || user?.isInfluencer,
		[user]
	);

	const behalf = !isPriviliged ? inviteDialog?.onBehalfOf : watch("behalf");
	const contactTo = watch("to");
	const email = watch("email");
	const number = watch("number");
	const relationship = watch("relationship");

	const onSubmit = useCallback(
		data => {
			if (!data.email && !data.number) {
				setNumberEmailError("Please enter a valid email or phone number");
			} else {
				!!inviteDialogModel
					? handleUpdate({
							...data,
							contactId: inviteDialogModel._id
					  })
					: handleCreate(data);
			}
		},
		[handleCreate, handleUpdate, inviteDialogModel]
	);

	const ActionButton = useMemo(
		() => (
			<StyledActionButton
				className="full-screen"
				type="submit"
				leftIcon={submitting ? <Loader size="1rem" show color="primary" variant="indeterminate" /> : undefined}
				onClick={() => handleSubmit(onSubmit)()}
				disabled={!isValid || !isDirty || !!numberEmailError.length || submitting}
			>
				{`${!!inviteDialogModel ? "Update" : "Create"} Contact`}
			</StyledActionButton>
		),
		[inviteDialogModel, handleSubmit, isDirty, isValid, numberEmailError.length, onSubmit, submitting]
	);

	useEffect(() => {
		if ((email || number) && !!numberEmailError.length) {
			setNumberEmailError("");
		}
	}, [email, number, numberEmailError.length]);

	useEffect(() => {
		if (behalf?.label && (contactTo || relationship) && inviteDialog?.name) {
			setValue(
				"message",
				`Hi ${relationship || contactTo}, it's ${behalf.label}. I'd like to invite you to be a part of my ${
					inviteDialog.name
				} experience. Will you support me and my team? Your donation will help contribute to our success during this season.`
			);
		}
	}, [behalf, contactTo, inviteDialog?.name, relationship, setValue]);

	useEffect(() => {
		if (inviteDialog?.id) {
			getFundraiserMembers({
				id: inviteDialog.id,
				keyword: debouncedKeyword,
				limit: 20
			}).then(members => {
				members &&
					setBehalfOfOptions(
						members.map(m => ({
							image: !!m.photos?.length
								? {
										mediaId: (m.photos[0] as MediaModel).mediaId,
										url: (m.photos[0] as MediaModel).profilePicture || ""
								  }
								: undefined,
							label: `${m.firstName} ${m.lastName}`,
							value: `${m.personaId}`
						})) || []
					);
			});
		}
	}, [debouncedKeyword, getFundraiserMembers, inviteDialog?.id]);

	return (
		<>
			<ConfirmLeavePopup
				handleLeavePage={handleLeavePageConfirmed}
				open={showConfirmPopup}
				onClose={closeConfirmPopup}
				popup
			/>
			<Dialog
				title="Add Contacts"
				open
				onClose={() => handleClose(isDirty)}
				footer={ActionButton}
				bodyCustomStyles={"padding: 0;"}
				maxWidth="md"
				customWidth={520}
			>
				<div className="p-2 md:py-6 md:px-8">
					{(!inviteDialog?.onBehalfOf || isPriviliged) && (
						<Controller
							name="behalf"
							control={control}
							rules={{ validate: val => (!val || !val["value"] ? "Please choose a person from the list" : true) }}
							defaultValue={
								inviteDialog?.onBehalfOf
									? {
											label: inviteDialog?.onBehalfOf.label,
											value: inviteDialog?.onBehalfOf.value,
											image: inviteDialog.onBehalfOf.image
									  }
									: undefined
							}
							render={({ onChange, value }) => (
								<SelectInput
									id="onBehalf"
									label="On behalf of"
									name="behalf"
									value={value?.label}
									onChange={onChange}
									placeholder="Select member"
									error={errors?.behalf?.message}
									options={behalfOfOptions}
									loadSuggestions={key => setKeyword(key)}
									maxHeight={260}
									renderOption={
										value && typeof value !== "string"
											? () => (
													<SelectOptionsWrapper.SelectOption>
														{value.image && (
															<img
																className="w-6 h-6 rounded-full object-cover"
																src={value.image.url}
																alt={value.label}
															/>
														)}
														{value.label}
													</SelectOptionsWrapper.SelectOption>
											  )
											: undefined
									}
								/>
							)}
						/>
					)}
					<div className={clsx("flex flex-col gap-3 md:flex-row md:items-center", !inviteDialog?.onBehalfOf && "mt-5")}>
						<div className="flex-1">
							<Controller
								name="to"
								control={control}
								rules={{ required: { value: true, message: "Name or Nickname is required" } }}
								defaultValue={inviteDialogModel?.name}
								render={({ onChange, value }) => (
									<LabeledInput
										id="toContact"
										label="To *"
										name="to"
										value={value}
										onChange={onChange}
										placeholder="Name or Nickname"
										error={errors?.to?.message}
									/>
								)}
							/>
						</div>
						<div className="flex-1">
							<Controller
								name="relationship"
								control={control}
								defaultValue={inviteDialogModel?.relationship}
								render={({ onChange, value }) => (
									<LabeledInput
										id="contactRelationship"
										label="What do you call this person?"
										name="relationship"
										value={value}
										onChange={onChange}
										placeholder="Relationship"
										error={errors?.relationship?.message}
									/>
								)}
							/>
						</div>
					</div>
					<div className="mt-5 flex flex-col gap-3">
						<Controller
							name="email"
							control={control}
							rules={{ validate: value => !value || validateEmail(value) }}
							defaultValue={inviteDialogModel?.contactInfo.email}
							render={({ onChange, value }) => (
								<LabeledInput
									id="contactEmail"
									label="Contact details"
									name="email"
									value={value}
									onChange={onChange}
									placeholder="Emailaddress@mail.com"
									error={errors?.email?.message}
								/>
							)}
						/>
						<Controller
							name="number"
							control={control}
							rules={{
								minLength: { value: 10, message: "Phone number must be at least 10 numbers" },
								validate: value => {
									if (value && !!value.length && !validatePhoneNumber(value)) return "Phone number is not correct";
									return undefined;
								}
							}}
							defaultValue={inviteDialogModel?.contactInfo.phone}
							render={({ onChange, value }) => (
								<LabeledInput
									id="contactNumber"
									name="number"
									value={value}
									onChange={onChange}
									placeholder="Phone number ( +15555551234 )"
									error={errors?.number?.message}
								/>
							)}
						/>
						<div className="text-red-600 -mt-1">{numberEmailError}</div>
						<Controller
							name="message"
							control={control}
							defaultValue={inviteDialogModel?.messageBody}
							render={({ onChange, value }) => (
								<LabeledInput
									id="contactMessage"
									name="message"
									value={value}
									onChange={onChange}
									placeholder="Message"
									label="Message *"
									error={errors?.message?.message}
									textarea
									textareaRows={4}
								/>
							)}
						/>
					</div>
				</div>
			</Dialog>
		</>
	);
};

export default InviteContactsManageView;
