import { useMemo } from "react";

import { useLiveConversationHelperStore } from "../contexts/LiveConversationContext";

const useLiveConversationHelper = () => {
	const store = useLiveConversationHelperStore();
	const { setState } = useLiveConversationHelperStore();

	const methods = useMemo(
		() => ({
			setupVariable: (isRefresh: boolean) => {
				setState(ctx => ({ ...ctx, isRefresh }));
			},
			getVariable: () => {
				return store.isRefresh;
			}
		}),
		[setState, store]
	);

	return { ...methods };
};

export default useLiveConversationHelper;
