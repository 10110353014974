import { Box, ButtonBase } from "@material-ui/core";
import { Scrollbars } from "react-custom-scrollbars";
import styled, { css } from "styled-components";

import { Button, Text } from "shared/ui-kit";

export const InfoRow = styled(Box)`
	display: flex;
	align-items: ${({ center }) => (center ? "center" : "flex-start")};
	margin-bottom: 16px;
	width: 100%;
	.info {
		overflow: hidden;
	}
	p {
		font-size: 15px;
		font-weight: 600;
		color: #222b45;
		overflow: hidden;
		text-overflow: ellipsis;
		&.wrap {
			white-space: pre-wrap;
		}
		white-space: nowrap;
	}
	.company {
		font-size: 13px;
		color: #222b45;
		margin: 1px 0;
		font-weight: ${({ regularCompany }) => (regularCompany ? 400 : 600)};
	}
	.secondary {
		font-size: 13px;
		font-weight: 400;
		color: #8f9bb3;
	}

	${props => props.theme.breakpoints.up("md")} {
		width: ${({ fw, width }) => (fw ? (width ? width : "100%") : "50%")};
	}
	${({ edu }) =>
		edu &&
		css`
			.school,
			.location {
				font-size: 13px;
				line-height: 1.23;
			}
			.school {
				color: #222b45;
			}
			.location {
				color: #8f9bb3;
			}
		`}
`;

export const IconWrapper = styled(Box)`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 40px;
	height: 40px;
	border-radius: 24px;
	background-color: #edf1f7;
	margin-right: ${({ distanceLevel }) => (distanceLevel === 1 ? "8px" : "16px")};
	flex-shrink: 0;
	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		border-radius: 99%;
		padding: 4px;
	}

	svg {
		fill: ${({ fillColor }) => fillColor};
		&.fill-first-path {
			path:first-child {
				fill: ${({ fillColor }) => fillColor};
			}
		}

		&.fill-second-path {
			path:nth-child(2) {
				fill: ${({ fillColor }) => fillColor};
			}
		}
	}

	&.member &.fill-second-path {
		svg {
			path:nth-child(2) {
				fill: #222b45;
			}
		}
	}
`;

export const SimpleIconWrapper = styled(Box)`
	&.member.fill-second-path {
		svg {
			path:nth-child(2) {
				fill: #c5cee0;
			}
		}
	}
`;

export const SectionHeader = styled(Text)`
	font-size: 22px;
	font-weight: bold;
	line-height: 1.45;
	color: #222b45;
	&.mt-8 {
		margin-top: 8px;
	}
	&.ml-8 {
		margin-left: 8px;
	}
	&.mb-16 {
		margin-bottom: 16px;
	}
`;

export const BioPlaceHolder = styled(Box)`
	height: 112px;
	margin: 16px 0 24px;
	padding: 8px 16px;
	border-radius: 8px;
	background-color: #f7f9fc;
	font-size: 15px;
	line-height: 1.33;
	color: #8f9bb3;
`;

export const ProfileListingDialogWrapper = styled(Box)`
	${({ verticalPadding }) =>
		verticalPadding &&
		css`
			padding: 12px 0;
		`}
`;

ProfileListingDialogWrapper.SubHeader = styled(Text)`
	font-size: 15px;
	font-weight: 700;
	margin: 8px 16px 8px;
`;

ProfileListingDialogWrapper.InputWrapper = styled(Box)`
	position: relative;
	display: flex;
	justify-content: center;
`;

ProfileListingDialogWrapper.SearchInput = styled.input`
	border: 1px solid #e4e9f2;
	border-radius: 8px;
	height: 40px;
	margin: 8px 16px 16px;
	padding: 0 12px;
	width: 100%;
	&:focus {
		outline-offset: 0px;
		outline: none;
		border: 1px solid #192038;
	}
`;

ProfileListingDialogWrapper.Option = styled(ButtonBase)`
	width: 100%;

	display: flex;
	justify-content: space-between;

	font-size: 13px;
	font-weight: 600;
	text-align: left;
	padding: 16px;
	box-shadow: -0.5px -0.5px 0 #e3e2e2;

	img {
		width: 32px;
		height: 32px;
		border-radius: 99%;
		object-fit: cover;
	}

	${({ active }) =>
		active &&
		css`
			background-color: #f7f6f6;
		`}

	label {
		margin: 0;
		span {
			padding: 0;
			&.Mui-checked {
				svg {
					color: ${({ color }) => color} !important;
				}
			}
		}
	}

	&.disabled {
		opacity: 0.5;

		cursor: not-allowed;
	}
`;

ProfileListingDialogWrapper.UserOption = styled(ProfileListingDialogWrapper.Option)`
	justify-content: flex-start;
	align-items: center;
	img {
		width: 36px;
		height: 36px;
		object-fit: cover;
		border-radius: 99%;
		margin-right: 12px;
	}

	${({ skeleton }) =>
		skeleton &&
		css`
			.MuiSkeleton-root {
				margin-right: 12px;
			}
		`}
`;

ProfileListingDialogWrapper.CircularOptions = styled(Box)`
	display: flex;
	flex-wrap: wrap;
	padding: 0 16px;
`;

ProfileListingDialogWrapper.CircularOption = styled(ButtonBase)`
	border: ${({ color }) => `1px solid ${color}`};
	font-size: 12px;
	font-weight: 500;
	padding: 4px 8px;
	border-radius: 8px;
	margin-right: 8px;
	margin-bottom: 8px;
	color: #222b45;
	${({ active }) =>
		active &&
		css`
			background-color: ${({ color }) => color};
			color: white;
		`}
`;

ProfileListingDialogWrapper.WithImage = styled(Box)`
	display: flex;
	align-items: center;
	img {
		margin-right: 8px;
		width: 40px;
		height: 40px;
		object-fit: cover;
		border-radius: 99%;
	}
`;

export const MemberDialogContentWrapper = styled(Box)`
	padding: 0 24px;

	&.no-padding {
		padding: 0;
	}
	&.relative {
		position: relative;
	}
	&.pv-20 {
		padding-top: 20px;
		padding-bottom: 20px;
	}
	&.pv-24 {
		padding-top: 24px;
		padding-bottom: 24px;
	}
	&.ph-20 {
		padding-left: 20px;
		padding-right: 20px;
	}
	.MuiOutlinedInput-root {
		border-radius: 8px;
	}

	&.full-width-editor .ql-editor {
		height: auto;
	}

	${props => props.theme.breakpoints.up("sm")} {
		&.no-padding {
			padding: 0;
		}
		&.ph-40 {
			padding-left: 40px;
			padding-right: 40px;
		}
		&.post-sides {
			display: flex;
			justify-content: space-between;
			min-height: 512px;

			> div:first-child {
				box-shadow: 0.5px 0 0px #c5cee0;
				padding-right: 16px;
				${props => props.theme.breakpoints.up("sm")} {
					width: calc(100% - 320px);
				}
			}
		}
	}

	${({ minHeight }) =>
		minHeight &&
		css`
			min-height: ${minHeight}px;
		`}

	${({ maxHeight }) =>
		maxHeight &&
		css`
			max-height: ${maxHeight}px;
		`}
`;

MemberDialogContentWrapper.ContentBlock = styled(Box)`
	&.isOpen {
		padding-bottom: 60px;
	}
	${props => props.theme.breakpoints.up("sm")} {
		&.isOpen {
			padding-bottom: 0;
		}
		width: 320px;
		flex-shrink: 0;
		> div {
			padding-top: 0;
			padding-right: 0;
			padding-left: 8px;
		}
	}
`;

export const InputWrapper = styled(Box)`
	margin-bottom: ${({ mb }) => (mb ? `${mb}px` : "20px")} !important;
	position: relative;
	${({ width }) =>
		width &&
		css`
			width: ${width};
		`}

	.input-error {
		white-space: nowrap;
	}
`;

InputWrapper.HideItem = styled(Box)`
	display: flex;
	align-items: center;
	justify-content: flex-end;
`;

export const StyledWrapper = styled(Box)`
	padding: 0 16px;

	${props => props.theme.breakpoints.up("md")} {
		max-width: 960px;

		margin: auto;
		padding: 0;
	}
`;

StyledWrapper.PlayIcon = styled.div`
	background-color: rgba(0, 0, 0, 0.2);

	svg path:first-child {
		fill: transparent;
	}

	svg path:last-child {
		fill: #ffffff;
	}
`;

StyledWrapper.Header = styled(Box)`
	background-color: #ffffff;

	border-radius: 12px;
	border: 1px solid rgba(197, 206, 224, 0.5);

	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
`;

StyledWrapper.HeaderIconWrapper = styled(Box)`
	width: 100%;
	height: 480px;
	border-top-left-radius: 12px;
	border-top-right-radius: 12px;
	overflow: hidden;
	position: relative;

	> div {
		width: 100%;
		height: 100%;
	}

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	${props => props.theme.breakpoints.down("sm")} {
		height: 280px;
	}
`;

StyledWrapper.HeaderBody = styled(Box)`
	padding: 12px;
	display: flex;
	flex-direction: column;

	${props => props.theme.breakpoints.up("sm")} {
		padding: 24px 16px;
		display: block;
	}
`;

StyledWrapper.HeaderInfoRow = styled(Box)`
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
`;

StyledWrapper.TypeWrapper = styled(Box)`
	display: flex;
	align-items: center;
	.lock-path {
		fill: #8f9bb3;
	}
`;

StyledWrapper.TypeText = styled(Text)`
	font-weight: 600;
	line-height: 1.6;
	color: #8f9bb3;

	margin-left: 4px;
`;

StyledWrapper.ActionsWrapper = styled(Box)`
	display: flex;
	align-items: center;

	${props => props.theme.breakpoints.down("sm")} {
		margin-top: 12px;
	}
`;

StyledWrapper.Title = styled(Text)`
	text-transform: capitalize;
	font-size: 24px;

	${props => props.theme.breakpoints.up("sm")} {
		font-size: 36px;
	}
`;

StyledWrapper.MembersBlock = styled(Box)`
	display: flex;
	align-items: center;

	margin-top: 12px;
`;

StyledWrapper.Sections = styled(Box)`
	border-radius: 12px;
	border: 1px solid rgba(197, 206, 224, 0.5);
	box-shadow: 0 2px 4px 0 rgb(0 0 0 / 8%);
	background-color: #fff;

	padding: 12px 16px;
	margin: 16px 0;

	display: flex;
	align-items: center;
	overflow-x: auto;

	.section {
		flex-shrink: 0;

		height: 40px;

		margin-right: 8px;
		padding: 0 16px;

		display: flex;
		justify-content: center;
		align-items: center;

		border-radius: 8px;
		border: 1px solid rgba(197, 206, 224, 0.5);

		font-size: 15px;
		font-weight: 600;
		line-height: 1.6;
		color: #222b45;

		&.active {
			background-color: #edf1f7;
		}
	}
`;

StyledWrapper.Content = styled(Box)`
	margin-top: ${({ mt = false }) => (mt ? "16px" : "0px")};

	.contain {
		display: flex;
		flex-direction: column;
	}

	.content-block {
		margin-bottom: 16px;
		flex: 1;
		p {
			margin: 0;
			font-size: 15px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.47;
			letter-spacing: normal;
			color: #222b45;
			margin-top: 4px;
			margin-bottom: 16px;
		}
	}
	${props => props.theme.breakpoints.up("sm")} {
		.admins-list {
			display: flex;
			align-items: flex-start;
		}
	}
	${props => props.theme.breakpoints.up("md")} {
		display: flex;
		align-items: flex-start;

		.contain {
			flex-direction: row;
		}

		.content-block {
			margin-bottom: 0;
		}

		.admins-list {
			display: block;
			width: 320px;
			margin-left: 16px;
		}
	}
`;

export const ActionItemWrapper = styled(Box)`
	&:not(:last-of-type) {
		margin-right: 8px;
	}
`;

export const ActionButton = styled(Button)`
	margin: 0;

	&.fixed-size {
		width: 140px;
	}
`;

export const Headline = styled(Text)`
	margin-top: ${({ mt = false }) => (mt ? "24px" : "0px")};
	margin-bottom: ${({ mb = false }) => (mb ? "16px" : "0px")};
	font-size: 22px;
	font-weight: bold;
	line-height: 1.45;
	color: #222b45;
`;

export const StyledAbout = styled(Box)`
	padding: 12px 16px 24px;

	border-radius: 12px;
	border: 1px solid rgba(197, 206, 224, 0.5);

	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);

	background-color: #fff;

	.read-more {
		color: ${({ fillColor }) => fillColor};
	}

	.details-row {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		.details-item {
			width: 100%;
			display: flex;
			align-items: center;
			margin-top: 16px;
			.icon {
				height: 40px;
				width: 40px;
				flex-shrink: 0;
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 24px;
				background-color: ${({ fillColor }) => `${fillColor}1a` || "#edf1f7"};
				margin-right: 12px;
				&.fill-second-path {
					svg path:nth-child(2) {
						fill: ${({ fillColor }) => fillColor};
					}
				}
				img {
					width: 80%;
					height: 80%;
					object-fit: cover;
					border-radius: 99%;
				}
				svg {
					fill: ${({ fillColor }) => fillColor};
				}
			}
			.rounded-svg {
				height: 40px;
				width: 40px;
				margin-right: 12px;

				svg {
					border-radius: 24px;
				}
			}
			h5,
			p {
				margin: 0;
			}
			h5 {
				font-size: 15px;
				font-weight: 600;
				line-height: 1.6;
				color: #222b45;
			}
			p {
				font-size: 13px;
				line-height: 1.23;
				color: #8f9bb3;
			}
			h6 {
				display: flex;
				white-space: pre;
				font-size: 15px;
				font-weight: 600;
				line-height: 1.6;
				color: #222b45;

				p {
					font-size: 15px;
					font-weight: 600;
					line-height: 1.6;
					color: #8f9bb3;
				}
			}
		}
	}
	${props => props.theme.breakpoints.up("sm")} {
		.details-row .details-item {
			width: 50%;
		}
	}
`;

export const Btn = styled(Button)<{ disabled?: boolean }>`
	width: ${({ fixedWidth = true }) => (fixedWidth ? "104px" : "none")};
	margin: 0;
	border-radius: 8px;
	color: white;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
`;

export const ScrollBarWrapper = styled(Scrollbars)`
	max-height: 100%;

	&.scrollWrapper > div:first-child {
		max-height: 100%;
		position: relative !important;
		overflow-x: hidden !important;
	}
`;
