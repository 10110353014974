import React, { FC, ReactNode } from "react";

import { IconButton } from "@material-ui/core";

import { Icon, Text } from "shared/ui-kit";

import { NotificationWrapper } from "./style";

const MemberNotification: FC<{
	message: string | ReactNode;
	open: boolean;
	onClose: () => void;
	isError?: boolean;
}> = ({ message, onClose, open, isError }) => (
	<NotificationWrapper show={open}>
		{message && (
			<Icon
				name={isError ? "close-circle" : "check-circle"}
				group="filled"
				fill={isError ? "#DB2C33" : "#189646"}
				className="flex-shrink-0"
			/>
		)}
		<Text>{message}</Text>
		<IconButton size="small" onClick={onClose}>
			<Icon name="close" group="filled" fill="#8f9bb3" width={16} height={16} />
		</IconButton>
	</NotificationWrapper>
);

export default MemberNotification;
