import React, { FC, useEffect } from "react";

import { Controller, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";

import { usePersona } from "shared/hooks";

import { FormBase } from "./style";

import { SelectInput } from "../../shared";
import { InputWrapper } from "../../shared/style";

const maritalOptions = [
	{ label: "Single", value: "single" },
	{ label: "Married", value: "married" },
	{ label: "Divorced", value: "divorced" },
	{ label: "Widowed", value: "widowed" },
	{ label: "Complicated", value: "complicated" },
	{ label: "Other", value: "other" }
];

interface FillProfileMaritalStatusProps {
	motherhoodPageUrl: string;
	selfiePageUrl: string;
}

const FillProfileMaritalStatus: FC<FillProfileMaritalStatusProps> = ({ motherhoodPageUrl, selfiePageUrl }) => {
	const history = useHistory();
	const { setPersona, getData: getPersonaData } = usePersona();
	const { persona } = getPersonaData();

	const {
		control,
		handleSubmit,
		formState: { isValid, isDirty, isSubmitting },
		errors,
		reset
	} = useForm({
		mode: "onChange"
	});

	useEffect(() => {
		if (persona?.maritalStatus) {
			reset({
				maritalStatus: maritalOptions.find(x => x.value === persona.maritalStatus)
			});
		}
	}, [persona, reset]);

	const onSubmit = async data => {
		setPersona({ maritalStatus: data.maritalStatus.value }, true);
		if (persona?.personalInfo?.gender === "man") {
			history.push(selfiePageUrl);
		} else {
			history.push(motherhoodPageUrl);
		}
	};

	return (
		<FormBase onSubmit={handleSubmit(onSubmit)} className="without-logo">
			<FormBase.Title>
				Great
				<br />
				What’s your marital status?
			</FormBase.Title>
			<FormBase.InfoForm style={{ marginTop: "40px" }}>
				<FormBase.Row>
					<FormBase.Column className={"full"}>
						<Controller
							name="maritalStatus"
							control={control}
							rules={{
								required: "Marital Status is required",
								validate: val => {
									if (!val.value || !val.label) {
										return "Please choose a status";
									}
								}
							}}
							render={({ onChange, value }) => (
								<InputWrapper>
									<SelectInput
										name="maritalStatus"
										onChange={onChange}
										value={value ? value.label : null}
										placeholder=" "
										label={
											<>
												Marital Status <FormBase.Asterisk>*</FormBase.Asterisk>
											</>
										}
										maxHeight={260}
										error={errors?.maritalStatus?.message}
										options={maritalOptions}
										displayOnly
									/>
								</InputWrapper>
							)}
						/>
					</FormBase.Column>
				</FormBase.Row>
			</FormBase.InfoForm>
			<FormBase.Footer>
				<FormBase.ActionBtn palette={"basic"} buttonTheme={"light"} onClick={() => history.go(-1)}>
					Back
				</FormBase.ActionBtn>
				<FormBase.ActionBtn
					className="active"
					type={"submit"}
					disabled={!isValid || (persona?.maritalStatus ? false : !isDirty) || isSubmitting}
				>
					Next
				</FormBase.ActionBtn>
			</FormBase.Footer>
		</FormBase>
	);
};

export default FillProfileMaritalStatus;
