export enum MediaType {
	photo = "photo",
	video = "video",
	licensePhoto = "licensePhoto",
	profilePhoto = "profilePhoto",
	coverPhoto = "coverPhoto",
	groupCoverPhoto = "groupCoverPhoto",
	bugPhoto = "bugPhoto",
	groupChatCoverPhoto = "groupChatCoverPhoto",
	audioFile = "audioFile",
	coverArt = "coverArt",
	seriesPhoto = "seriesPhoto",
	eventPhoto = "eventPhoto",
	albumPhoto = "albumPhoto",
	filePhoto = "filePhoto",
	trackPhoto = "trackPhoto",
	videoPhoto = "videoPhoto",
	categoryPhoto = "categoryPhoto",
	userFile = "userFile",
	manageVideo = "manageVideo"
}

export enum MediaStatus {
	verified = "VERIFIED",
	not_verified = "NOT_VERIFIED"
}

export interface MediaModel {
	personaId: number;
	uri?: string;
	type: MediaType;
	expireAt: number | "infinite";
	status: MediaStatus;
	errors?: Error[];
	updatedAt?: Date;
	createdAt: Date;
	_id: string;
	mediaId: string;
	profilePicture?: string;
}

export interface CoverPhotosType {
	active: boolean;
	mediaId: string;
	uri: string;
}

export interface MediaRequest extends Pick<MediaModel, "personaId" | "uri" | "type" | "expireAt"> {}

export interface MediaResponse {
	media: MediaModel[];
}
