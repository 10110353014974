import config from "config/appConfig";

import { AnalyticsContextValues } from "shared/types";

import { IStateProps } from "utils/StateOf";

import createStore from "utils/createStore";

const localStorageKey = `${config.GLOBAL_CONSTANTS.LOCAL_STORAGE_PREFIX}${config.HOOK_STORAGE_KEYS.ANALYTICS_DATA}`;

export const initialState: IStateProps<AnalyticsContextValues> = {
	summary: {
		monthly: [],
		today: null,
		monthlySummaryAverage: null
	},

	totalActiveUsers: 0,
	activeUsersData: [],

	totalSignUps: 0,
	signUpsData: [],

	totalRevenue: "0",
	revenueData: [],

	totalSales: 0,
	salesData: [],

	newUsersData: [],
	topReferees: [],

	summaryMetrics: {
		dailyActiveUsers: 0,
		dailySessions: 0,
		stickiness: "",
		retention: {
			"1Day": "",
			"7Day": "",
			"21Day": "",
			"30Day": ""
		},
		dailySessionsPerDailyActiveUser: 0,
		monthlyActiveUsers: 0,
		churnRate: 0,
		averageSessionLength: 0
	}
};

export default createStore<AnalyticsContextValues>({
	initialState,
	persistant: true,
	persistantName: localStorageKey
});
