import React from "react";

import styled from "styled-components";

import { Box, Text } from "shared/ui-kit";

interface TypeCardWrapperProps {
	active?: boolean;
}

const TypeCardWrapper = styled(Box)<TypeCardWrapperProps>`
	padding: 12px 16px;
	border-radius: 4px;
	background-color: ${({ active }) => (active ? "#ebecff" : "#f7f9fc")};
	transition: border-color 0.5s ease-in-out;
	height: 48px;
	display: flex;
	align-items: center;

	border: 0.5px solid ${({ active }) => (active ? "#6173fe" : "#c5cee0")};
`;

const IconWrapper = styled.i<{ url: string }>`
	display: block;

	background-image: url(${({ url }) => url});
	background-position: left center;
	background-repeat: no-repeat;
	background-size: contain;

	width: 24px;
	height: 24px;

	margin-right: 16px;

	${props => props.theme.breakpoints.down("xs")} {
		margin-right: 12px;
	}
`;

const Title = styled(Text)`
	${props => props.theme.breakpoints.down("xs")} {
		font-size: 13px;
	}
`;

interface TypeCardProps {
	iconUrl: string;
	title: string;
	active?: boolean;
}

const TypeCard: React.FC<TypeCardProps> = ({ iconUrl, title, active }) => {
	return (
		<TypeCardWrapper active={active ? "true" : undefined}>
			<IconWrapper url={iconUrl} />
			<Title variant="small1">{title}</Title>
		</TypeCardWrapper>
	);
};

export default TypeCard;
