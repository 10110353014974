import React, { FC, useEffect, useState } from "react";

import { Box, Button, Link } from "@material-ui/core";

import { useIntegrations } from "modules/Integrations/Data/hooks";

import { PodcastIntegration } from "modules/Integrations/Data/types/PodcastsResponse";
import { Icon, Text } from "shared/ui-kit";

import IntegrationDialog from "./IntegrationDialog";

import { IntegrationCardContainer } from "../Containers/Integrations/styles";

interface Props {
	content: PodcastIntegration;
	showStatus?: boolean;
	noDialog?: boolean;
}

const IntegrationCard: FC<Props> = ({ content, noDialog, showStatus }) => {
	const { openIntegrationPopup, getData: getIntegrationsData } = useIntegrations();
	const { openDialog } = getIntegrationsData();

	const [displayUrl, setDisplayUrl] = useState("");

	useEffect(() => {
		const urlArray = new URL(content.infoUrl).hostname.split(".");
		if (urlArray.length > 2) {
			const url = `${urlArray[urlArray.length - 2]}.${urlArray[urlArray.length - 1]}`;
			setDisplayUrl(url);
		} else {
			const url = new URL(content.infoUrl).hostname;
			setDisplayUrl(url);
		}
	}, [content.infoUrl]);

	const handleOpenDialog = () => {
		openIntegrationPopup(content.name);
	};

	const integrationStatus = !!content.links.length ? "connected" : "disconnected";

	return (
		<>
			{!noDialog && openDialog === content.name && <IntegrationDialog content={content} />}
			<IntegrationCardContainer
				showStatus={showStatus}
				integrationStatus={integrationStatus}
				onClick={handleOpenDialog}
				className="card-r"
			>
				<Button className="logo-container">
					<img src={content.image} alt={content.name} />
					{showStatus && integrationStatus && (
						<Box className="status">
							{integrationStatus === "connected" ? (
								<Icon height={20} width={20} name="check-circle" group="filled" fill="#23b34a" />
							) : (
								<Icon height={20} width={20} name="exclamation-circle" group="filled" fill="#db2c33" />
							)}
						</Box>
					)}
				</Button>
				<Box className="info">
					<Link href={content.infoUrl} target="_blank" rel="noopener noreferrer">
						{displayUrl}
					</Link>
					<Box className="title-status">
						<Text variant="h6">{content.name}</Text>
						{showStatus && integrationStatus && (
							<Box className={integrationStatus === "connected" ? "status green" : "status red"}>
								{integrationStatus}
							</Box>
						)}
					</Box>
				</Box>
			</IntegrationCardContainer>
		</>
	);
};

export default IntegrationCard;
