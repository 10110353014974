import React from "react";

import { useParams } from "react-router";

import { view } from "modules/Settings";

import { routes } from "../../constants";

const Monetization = () => {
	const { option } = useParams() as { option: string };

	return (
		<view.Monetization
			option={option}
			routes={{
				dashboard: routes.dashboard.getPath(),
				landing: routes.settings.monetization.getPath(),
				plans: routes.settings.monetization.plans.getPath(),
				subscription: routes.settings.monetization.subscription.getPath(),
				premium: routes.settings.monetization.premium.getPath(),
				subscription_and_premium: routes.settings.monetization.subscription_and_premium.getPath(),
				change: routes.settings.monetization.change.getPath()
			}}
			generalSettingsCoinBlockRoute={`${routes.settings.general.getPath()}?activeBlock=coin`}
		/>
	);
};

export default Monetization;
