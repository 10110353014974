import React, { useCallback, useMemo } from "react";

import { ReactComponent as CancelIcon } from "assets/icons/cancel.svg";

import { InvitedToGroupStatus, ProfilePhotoSizes, SearchUsersModel } from "shared/types";
import { Checkbox } from "shared/ui-kit";
import { getResizedImage } from "utils/serviceUtils/cdnImages";

import { Connection } from "./style";

interface MemberProps {
	member: SearchUsersModel;
	isSelected?: boolean;
	isChecked?: boolean;
	onToggleChecked?: (personaId: number, isChecked: boolean) => void;
	onRemove?: (personaId: number) => void;
	entityText?: string;
}

const Member: React.FC<MemberProps> = ({
	member,
	isSelected,
	isChecked,
	onToggleChecked,
	onRemove,
	entityText = ""
}) => {
	const avatarUrl = getResizedImage(
		member?.photos?.length && member?.photos[0]?.profilePicture ? member.photos[0].profilePicture : "",
		ProfilePhotoSizes.size50x50
	);

	const personaId = useMemo(() => member?.personaId || -1, [member]);

	const handleToggleChecked = useCallback(
		(e: React.ChangeEvent<HTMLInputElement> | boolean) => {
			!member?.groupMembershipStatus &&
				onToggleChecked &&
				onToggleChecked(personaId, typeof e === "boolean" ? e : e.target.checked);
		},
		[onToggleChecked, personaId, member?.groupMembershipStatus]
	);

	const handleRemove = useCallback(() => {
		onRemove && onRemove(personaId);
	}, [onRemove, personaId]);

	const isMember = useMemo(
		() =>
			member?.groupMembershipStatus === InvitedToGroupStatus.member ||
			member?.groupMembershipStatus === InvitedToGroupStatus.moderator ||
			member?.groupMembershipStatus === InvitedToGroupStatus.owner,
		[member?.groupMembershipStatus]
	);

	return (
		<Connection
			className={`${!isChecked ? "checkable" : ""} ${member?.groupMembershipStatus ? "disabled" : ""}`}
			onClick={() => !isChecked && !member?.groupMembershipStatus && handleToggleChecked(!isSelected)}
		>
			<Connection.Base>
				<Connection.AvatarWrapper>
					<Connection.Avatar style={{ backgroundImage: `url(${avatarUrl})` }} />
				</Connection.AvatarWrapper>
				<div>
					<Connection.Name className={`${isSelected ? "bold" : ""} ${isChecked ? "small" : ""}`}>
						{member?.firstName} {member?.lastName}
					</Connection.Name>
					{(isMember || member?.groupMembershipStatus === InvitedToGroupStatus.invited) && (
						<Connection.Status>{isMember ? `Already in ${entityText}` : "Already invited"}</Connection.Status>
					)}
				</div>
			</Connection.Base>
			<Connection.Action>
				{!isChecked ? (
					<Checkbox checked={isSelected} />
				) : (
					<Connection.ActionBtn onClick={handleRemove}>
						<CancelIcon width={16} height={16} />
					</Connection.ActionBtn>
				)}
			</Connection.Action>
		</Connection>
	);
};

export default Member;
