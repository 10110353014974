import React from "react";

import { view } from "modules/MemberHome";
import { useMemberRoutes } from "shared/hooks";

const FillProfileWelcomePage = () => {
	const { getMemberRoutesData } = useMemberRoutes();
	const { routes } = getMemberRoutesData();
	return <view.FillProfileWelcome memberHomeUrl={routes?.member.home.getPath()} />;
};

export default FillProfileWelcomePage;
