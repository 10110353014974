import React, { useCallback, useEffect, useMemo, useState } from "react";

import { Box, InputAdornment, TextField } from "@material-ui/core";

import styled from "styled-components";

import { ReactComponent as SearchIcon } from "assets/icons/iconSearch.svg";
import { useFirebase, useMessaging } from "modules/Messaging/Data";
import { ChatMessage, InboxItem } from "modules/Messaging/Data/types";
import { Dialog } from "shared/Components";
import { useDebounce, useUser } from "shared/hooks";
import { GroupModel } from "shared/types";
import { Button, Table, Text } from "shared/ui-kit";

import {
	ColumnHeader,
	ContentColumn,
	DateColumn,
	DateColumnDate,
	GroupdAvatarContainer,
	StyledAvatar,
	StyledGroupAvatar
} from "./style";

const CustomDialog = styled(Dialog)`
	.MuiDialogContent-root {
		overflow: hidden;
	}
`;

const TableWrapper = styled(Box)`
	height: 48vh;
	margin-top: 24px;
	.MuiCheckbox-root:hover {
		background: none;
	}
`;

const FooterWrapper = styled(Box)`
	display: flex;
	justify-content: space-between;
	width: 100%;
	align-items: baseline;
`;
interface Props {
	group?: GroupModel;
	open: boolean;
	currentMessage?: ChatMessage;
	onClose: (e: Event, r: string) => void;
}

const ForwardChat: React.FC<Props> = ({ group, open = true, currentMessage, onClose }) => {
	const [keyword, setKeyword] = useState<string | null>(null);
	const [inbox, setInbox] = useState<InboxItem[]>([]);
	const { onSend } = useFirebase();

	const { updateInbox } = useMessaging();

	const { getActiveProfile, getData: getUserData } = useUser();
	const { user } = getUserData();

	const personaId = getActiveProfile(user)?.personaId;

	const debouncedKeyword = useDebounce(keyword, 400);

	useEffect(() => {
		updateInbox({ offset: 1, limit: 20, personaId, keyword: debouncedKeyword || undefined }, true).then(data => {
			setInbox(data || []);
		});
	}, [debouncedKeyword, personaId, updateInbox]);

	const [selected, setSelected] = useState<number[]>([]);

	const handleSendMsg = useCallback(async () => {
		const members = selected.map(item => inbox[item]);
		delete currentMessage?.edited;
		members.map(chat => onSend({ messages: [currentMessage] as any, chat }));
		await onClose({} as Event, "");
	}, [currentMessage, inbox, onClose, onSend, selected]);

	const footer = useMemo(
		() => (
			<FooterWrapper>
				<Text variant="subtitle2">You can forward message to max 50 chats.</Text>
				<Button disabled={!selected.length || selected.length > 500} onClick={handleSendMsg}>
					Done
				</Button>
			</FooterWrapper>
		),
		[selected, handleSendMsg]
	);

	const handleSearchChange = e => {
		const {
			target: { value }
		} = e;

		setKeyword(value);
	};

	const loadMore = () => {};

	const tableColumns = useMemo(
		() => [
			{
				alignment: "left",
				width: 450,
				label: <ColumnHeader>User</ColumnHeader>,

				Cell: ({
					rowData: { members, firstName, lastName, name, photo, profilePhoto, type }
				}: {
					rowData: InboxItem;
				}) => {
					const chatName = () =>
						firstName || name
							? `${firstName || name}${lastName ? " " + lastName : ""}`
							: members
									?.filter(item => !item.isOwner)
									.map(u => u.firstName || u.lastName)
									.join();
					return (
						<ContentColumn>
							{type === "group" ? (
								<GroupdAvatarContainer
									overlap="circle"
									anchorOrigin={{
										vertical: "bottom",
										horizontal: "right"
									}}
									badgeContent={
										<StyledGroupAvatar
											title={chatName()}
											image={!!members[1] && !!members[1].photos.length ? members[1].photos[0].profilePicture : ""}
										/>
									}
								>
									<StyledGroupAvatar
										title={chatName()}
										image={!!members[0] && !!members[0].photos.length ? members[0].photos[0].profilePicture : ""}
									/>
								</GroupdAvatarContainer>
							) : (
								<StyledAvatar title={chatName()} image={photo?.url || profilePhoto} />
							)}
							<DateColumn>
								<DateColumnDate>{chatName()}</DateColumnDate>
							</DateColumn>
						</ContentColumn>
					);
				},
				dataKey: "userName"
			}
		],
		[]
	);

	return (
		<CustomDialog title="Send to" open={open} footer={footer} onClose={onClose}>
			<TextField
				defaultValue={keyword}
				onChange={handleSearchChange}
				placeholder="Search for connections and conversation"
				variant="outlined"
				InputProps={{
					startAdornment: (
						<InputAdornment position="start">
							<SearchIcon />
						</InputAdornment>
					)
				}}
			/>
			<TableWrapper>
				<Table
					hideHeader
					checkable
					checkableColumnWidth={50}
					checkableColumnPosition="right"
					checkedRows={selected}
					onCheckItem={(i, a) => {
						setSelected(a);
					}}
					isRowCheckable={({ personaId }) => !group?.members.some(m => m.personaId === personaId)}
					size="small"
					columns={tableColumns}
					data={inbox || []}
					autoHeight
					multigrid
					onLastRowScrolled={loadMore}
				/>
			</TableWrapper>
		</CustomDialog>
	);
};

export default ForwardChat;
