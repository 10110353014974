import styled from "styled-components";

import { Box, Text } from "shared/ui-kit";

export const Wrapper = styled(Box)`
	display: flex;
	align-items: center;
`;

export const Item = styled(Box)`
	&:not(:first-of-type) {
		margin-left: -6px;
	}

	width: 24px;
	height: 24px;

	border-radius: 50%;

	border: 2px solid white;

	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
`;

export const StyledText = styled(Text)`
	font-size: 13px;
	font-weight: 600;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.85;
	letter-spacing: normal;
	color: #222b45;

	margin-left: 8px;

	&.no-imgs {
		margin: 0;
	}
`;
