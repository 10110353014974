import React from "react";

import { useLocation } from "react-router";

import { view } from "modules/MemberHome";

const AllCollectionsPage = () => {
	const { search } = useLocation();
	const query = new URLSearchParams(search);
	const groupId = query.get("groupId") || undefined;

	return <view.AllCollections groupId={groupId} />;
};

export default AllCollectionsPage;
