import React, { useEffect, useState } from "react";

import { DateTime } from "luxon";

import { useLiveConversation } from "modules/LiveConversation/Data/hooks";
import { Button } from "shared/ui-kit";

import { padZero } from "utils/padZero";

import { EndAlertWrapper } from "../Containers/Main/style";

const maxConverasationDurationInMinutes = 120;
const extendMinutes = 15;
const graceMinutes = 5;

const EndAlert: React.FC<{ isAdmin: boolean; endConversation: () => void }> = props => {
	const { updateEndAlertConfig, getData: getLiveConversationData } = useLiveConversation();
	const {
		config: { startedAt },
		event,
		showExtend,
		convoEndsInSecs,
		convoExtended
	} = getLiveConversationData();

	const { startTime: eventStartTime, endTime: eventEndTime } = event || {};
	const { isAdmin, endConversation } = props;

	const [endTime, setEndTime] = useState<string>(eventEndTime as string);
	const [graced, setGraced] = useState<boolean>(false);
	const [secs, setSecs] = useState<number>(0);
	const [intervalStart, setIntervalStart] = useState<boolean>(false);

	useEffect(() => {
		if (!showExtend) {
			return;
		}

		if (!secs) {
			setSecs(DateTime.fromISO(endTime).diff(DateTime.now(), "seconds").seconds);
			setIntervalStart(true);
		}
	}, [showExtend, secs, endTime, eventStartTime]);

	useEffect(() => {
		if (!intervalStart) {
			return;
		}
		const interval = setInterval(() => {
			setSecs(prev => prev - 1);
		}, 1000);
		return () => clearInterval(interval);
	}, [intervalStart]);

	useEffect(() => {
		if (!startedAt || !isAdmin) {
			return;
		}

		const interval = setInterval(() => {
			const diffInSecs = DateTime.fromISO(endTime).diff(DateTime.now(), "seconds").seconds;
			if (diffInSecs <= 0) {
				endConversation();
			} else if (!showExtend && diffInSecs <= 120) {
				// max maxConverasationDurationInMinutes minutes allowed.
				// if you want to extend meeting for more extendMinutes minutes
				// it should not exceed the 2 hours (maxConverasationDurationInMinutes mins) time
				// hence maxConverasationDurationInMinutes - extendMinutes minutes
				if (
					!convoExtended &&
					DateTime.fromISO(endTime).diff(DateTime.fromISO(eventStartTime as string), "minutes").minutes <
						maxConverasationDurationInMinutes - extendMinutes
				) {
					updateEndAlertConfig({ showExtend: true });
				} else if (!graced && endTime) {
					setEndTime(DateTime.fromISO(endTime).plus({ minutes: graceMinutes }).toISO());
					setGraced(true);
				} else if (!convoEndsInSecs) {
					updateEndAlertConfig({ convoEndsInSecs: diffInSecs });
				}
			}
		}, 5000);

		return () => clearInterval(interval);
	}, [
		startedAt,
		showExtend,
		endTime,
		isAdmin,
		convoEndsInSecs,
		convoExtended,
		graced,
		eventStartTime,
		updateEndAlertConfig,
		endConversation
	]);

	const onExtend = () => {
		setEndTime(DateTime.fromISO(endTime).plus({ minutes: extendMinutes }).toISO());
		updateEndAlertConfig({ showExtend: false, convoExtended: true });
		setGraced(true);
	};

	if (!showExtend && !convoEndsInSecs) {
		return null;
	}
	const minutes = Math.floor(secs / 60);
	const seconds = padZero(Math.floor(secs % 60));

	return (
		<EndAlertWrapper>
			The live event ends in {minutes}:{seconds}{" "}
			{showExtend && <Button onClick={onExtend}>Extend for {extendMinutes}m</Button>}
		</EndAlertWrapper>
	);
};

export default EndAlert;
