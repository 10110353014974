import { Box, ButtonBase } from "@material-ui/core";
import styled, { css } from "styled-components";

import { Text } from "shared/ui-kit";

export const ChoosePlanBlock = styled(Box)`
	padding: 24px;
	padding-top: 40px;
	text-align: center;
	img {
		width: 72px;
		height: 72px;
		object-fit: cover;
		border-radius: 8px;
		margin-bottom: 12px;
	}
	h5 {
		margin-bottom: 8px;
	}
	.paragraph {
		font-size: 13px;
		line-height: 1.4;
		color: #8f9bb3;
		margin: auto;
		margin-bottom: 14px;
		padding: 0 24px;
		${props => props.theme.breakpoints.up("sm")} {
			padding: 0 85px;
		}
	}
	.plans {
		margin-top: 40px;
	}
`;

export const CardOption = styled(ButtonBase)`
	border-radius: 12px;
	border: solid 1px #e4e9f2;
	padding: 16px;
	margin-bottom: 16px;
	display: flex;
	justify-content: flex-start;
	svg,
	img {
		width: 32px;
		margin-right: 16px;
	}
	${({ active }) =>
		active &&
		css`
			border: 2px solid #6173fe;
		`}
`;

export const SelectACardWrapper = styled(Box)`
	display: flex;
	flex-direction: column;
	padding: 40px 24px;
	max-height: 450px;
	overflow-y: scroll;
`;

export const ToggleWrapper = styled(Box)`
	width: 100%;
	height: 40px;

	display: flex;
	align-items: center;
	justify-content: center;

	margin-bottom: 16px;
`;

export const SubscriptionBlock = styled(Box)`
	min-height: 132px;

	padding: 16px 24px;

	border-radius: 12px;

	background-color: #fff;

	box-shadow: inset 0 0 0 0.5px #c5cee0, 0 2px 4px 0 rgba(0, 0, 0, 0.08);

	cursor: pointer;

	&:not(:last-child) {
		margin-bottom: 12px;
	}

	&.active {
		border: solid 2px #6173fe;
	}

	&.current,
	&.disabled {
		background-color: #f7f9fc;

		pointer-events: none;
	}
`;

SubscriptionBlock.Header = styled(Box)`
	display: flex;
	align-items: center;
`;

SubscriptionBlock.Title = styled(Text)`
	font-size: 18px;
	font-weight: bold;
	line-height: 1.33;
	color: #222b45;

	display: flex;
`;

SubscriptionBlock.TitleSuffix = styled(Text)`
	font-size: 15px;
	font-weight: 600;
	line-height: 1.6;
	letter-spacing: normal;
	color: #222b45;
`;

SubscriptionBlock.Note = styled(Box)`
	font-size: 15px;
	font-weight: normal;
	line-height: 1.33;
	letter-spacing: normal;
	color: #8f9bb3;

	margin-top: 4px;

	display: flex;
`;

SubscriptionBlock.Description = styled(Box)`
	font-size: 13px;
	font-weight: normal;
	line-height: 1.54;
	letter-spacing: normal;
	color: #222b45;

	margin-top: 8px;

	.subscription-read {
		display: flex;
	}

	.text {
		margin: 0;
	}
`;
