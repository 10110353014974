import React, { FC, ReactNode, useCallback, useMemo } from "react";

import { Box } from "@material-ui/core";

import { ReactComponent as VolunteerIcon } from "assets/icons/volunteer-slot.svg";
import { Members, MenuDots, PlaceholderImage } from "shared/Components";
import { useCommunity, useUser, useVolunteers } from "shared/hooks";
import { EventModel, PlaceholderImageType, ProfilePhotoSizes, VolunteerSlot } from "shared/types";

import { Button, Icon, Text } from "shared/ui-kit";

import { getResizedImage } from "utils/serviceUtils/cdnImages";

import {
	ButtonWrapper,
	CancelButton,
	IconWrapper,
	SecondaryColorText,
	VolunteerCardWrapper,
	VolunteerSlotsList
} from "./styles";

const VolunteerCard: FC<{
	customProp: { event: EventModel | null; isEventEnded: boolean };
	volunteerSlot: VolunteerSlot;
}> = ({ customProp, volunteerSlot }) => {
	const { event, isEventEnded } = customProp;

	const { communityColors } = useCommunity();
	const { isPrivilegedRole, getData: getUserData } = useUser();
	const { user } = getUserData();
	const { setCreateUpdateDialog, setDeleteVolunteerId, setSignUpVolunteerSlot, setCancelVolunteerSlot } =
		useVolunteers();
	const { quantity, filledQuantity, members, need, _id } = volunteerSlot;

	const isEventOwner = useMemo(() => event?.personaId === user?.activeProfile, [event, user]);
	const isAlreadySignedUp = useMemo(
		() => members.find(m => m.personaId === user?.activeProfile),
		[user?.activeProfile, members]
	);

	const slotsRemainingQuantity = useMemo(() => quantity - filledQuantity, [filledQuantity, quantity]);
	const isQuantityFilled = useMemo(() => slotsRemainingQuantity === 0, [slotsRemainingQuantity]);

	const showMembers = useMemo(
		() =>
			members
				.filter(x => x?.photos?.length && x?.photos[0]?.profilePicture)
				.slice(0, 3)
				.map(x => getResizedImage(x.photos[0].profilePicture, ProfilePhotoSizes.size50x50)),
		[members]
	);

	const signUpHandler = useCallback(() => {
		setSignUpVolunteerSlot({
			open: true,
			signUpVolunteerDetail: volunteerSlot,
			eventDetail: event || undefined
		});
	}, [setSignUpVolunteerSlot, volunteerSlot, event]);

	const getOptions = useCallback(
		(rowData: VolunteerSlot) => {
			const options: {
				name: string;
				icon: ReactNode;
				onClick: () => void;
			}[] = [
				{
					name: "Edit Details",
					icon: <Icon group={"filled"} fill={"#8f9bb3"} name={"pencil"} width={20} height={20} />,
					onClick: () => setCreateUpdateDialog({ open: true, editDetails: volunteerSlot, action: "update" })
				},
				{
					name: "Delete",
					icon: <Icon group={"filled"} fill={"#8f9bb3"} name={"trash"} width={20} height={20} />,
					onClick: () => setDeleteVolunteerId(rowData._id)
				}
			];

			if (isEventOwner || isPrivilegedRole) {
				if (!isAlreadySignedUp && !isQuantityFilled) {
					options.unshift({
						name: "Sign Up",
						icon: <Icon name="user-plus" group="filled" width={18} height={18} fill={"#8f9bb3"} />,
						onClick: () => signUpHandler()
					});
				} else if (isAlreadySignedUp) {
					options.unshift({
						name: "Cancel",
						icon: <Icon name="user-minus" group="filled" width={18} height={18} fill={"#8f9bb3"} />,
						onClick: () => setCancelVolunteerSlot(rowData)
					});
				}
			}

			return options;
		},
		[
			setDeleteVolunteerId,
			setCreateUpdateDialog,
			setCancelVolunteerSlot,
			signUpHandler,
			volunteerSlot,
			isEventOwner,
			isPrivilegedRole,
			isAlreadySignedUp,
			isQuantityFilled
		]
	);

	return (
		<VolunteerCardWrapper className="bg-white w-full rounded-md p-2">
			<VolunteerSlotsList key={_id} className={"mb-0 p-2 rounded-md"}>
				<Box className="flex w-full">
					<IconWrapper isSquareShape>
						<VolunteerIcon fill={communityColors.primary} />
					</IconWrapper>
					<Box className="w-full">
						<Box className="flex justify-between">
							<Text variant="h6">{need}</Text>
							<Members memberImgs={isEventOwner || isPrivilegedRole ? [] : showMembers} onlyImages />
						</Box>
						<Text className="flex items-center">
							{isQuantityFilled && (
								<Icon className="mr-1" fill={"#23b34a"} name={"check-circle"} width={18} height={18} />
							)}
							{isQuantityFilled
								? `All slots are filled: ${filledQuantity} of ${quantity}`
								: `Available slots: ${slotsRemainingQuantity} of ${quantity}`}
						</Text>
						<Text>
							{!(isEventOwner || isPrivilegedRole) && isAlreadySignedUp && `Your slots: ${isAlreadySignedUp.quantity}`}
						</Text>
					</Box>
				</Box>
			</VolunteerSlotsList>
			{(isEventOwner || isPrivilegedRole) && (
				<>
					<Box className="h-px my-2 mx-3 bg-gray-100" />
					<Box>
						{!!members?.length && (
							<Box className="p-2">
								<Box className="flex justify-between">
									<SecondaryColorText className="text-xs font-semibold">Volunteers</SecondaryColorText>
									<SecondaryColorText className="text-xs font-semibold">Quantity</SecondaryColorText>
								</Box>
								{members.map((member, i) => (
									<Box className="flex items-center mt-3" key={i}>
										<Box className="w-1/5">
											<Box className="w-10 h-10 overflow-hidden rounded-md">
												{!!member.photos.length ? (
													<img src={member.photos[0].profilePicture} alt={member.firstName} className="w-10 h-10" />
												) : (
													<PlaceholderImage width={120} height={60} type={PlaceholderImageType.EVENT_DETAILS} />
												)}
											</Box>
										</Box>
										<Box className="w-4/5">
											<Text className="font-semibold">
												{member.firstName} {member.lastName}
											</Text>
										</Box>
										<Box className="w-1/5 text-center">
											<Text className="font-semibold">{member.quantity}</Text>
										</Box>
									</Box>
								))}
							</Box>
						)}
					</Box>
				</>
			)}
			{isEventOwner || isPrivilegedRole ? (
				<Box className="flex items-center">
					<ButtonWrapper bgColor={communityColors.btn} className="w-full">
						<Button
							buttonTheme="light"
							onClick={() => {
								setCreateUpdateDialog({ open: true, editDetails: volunteerSlot, action: "view" });
							}}
							className="w-full mb-0"
						>
							View Detail
						</Button>
					</ButtonWrapper>
					<Box className="flex justify-end">
						<MenuDots options={getOptions(volunteerSlot)} rounded />
					</Box>
				</Box>
			) : !isAlreadySignedUp && !isQuantityFilled ? (
				<ButtonWrapper bgColor={communityColors.btn} className="w-full">
					<Button buttonTheme="light" onClick={signUpHandler} className="w-full mb-0" disabled={isEventEnded}>
						<Icon
							name="user-plus"
							group="filled"
							width={18}
							height={18}
							fill={communityColors.primary}
							className="mr-1"
						/>
						Sign Up
					</Button>
				</ButtonWrapper>
			) : isAlreadySignedUp ? (
				<Box className="px-2">
					<CancelButton
						className="w-full mx-0 my-2"
						onClick={() => setCancelVolunteerSlot(volunteerSlot)}
						disabled={isEventEnded}
					>
						<Icon name="user-minus" group="filled" width={18} height={18} fill={"#000000"} className="mr-1" />
						Cancel
					</CancelButton>
				</Box>
			) : (
				<Box className="px-2">
					<Button buttonTheme="light" className="w-full mx-0 my-2" disabled>
						No Slots Available
					</Button>
				</Box>
			)}
		</VolunteerCardWrapper>
	);
};

export default VolunteerCard;
