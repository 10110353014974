import React, { ReactNode } from "react";

import styled from "styled-components";

import { Box } from "shared/ui-kit";

const Wrapper = styled(Box)`
	margin-bottom: 24px;
	padding: 30px 48px 48px 48px;

	&.communityPage {
		padding: 0;

		video {
			width: 100%;
		}
	}

	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);

	border-radius: 4px;
	box-shadow: inset 0 0 0 0.5px #c5cee0;

	background-color: #ffffff;

	${props => props.theme.breakpoints.down("sm")} {
		box-shadow: none;

		border-radius: 0;
		border: none;
		border-bottom: 8px solid #eff2f5;

		padding: 16px;
		margin-bottom: 0;
	}
`;

export interface InfoBlockProps {
	children: ReactNode;
	className?: string;
}

const InfoBlock: React.FC<InfoBlockProps> = ({ children, className }) => (
	<Wrapper className={className}>{children}</Wrapper>
);

export default InfoBlock;
