import React, { FC, memo, useCallback, useEffect, useMemo, useRef, useState } from "react";

import { Box } from "@material-ui/core";

import { PostScheduleType } from "types";

import { boardIcons } from "modules/Manage/View/Components/SelectIconField";
import { Post } from "shared/Organisms";
import { useBoards, useOnScreen, usePosts } from "shared/hooks";
import { CategoryModel } from "shared/types";
import { Text } from "shared/ui-kit";

import { formatCount } from "utils/serviceUtils/helpers";

import { BoardWrapper } from "./style";

import { ContributorsCard, Hashtags, PostSkeleton } from "../../Components";

const BoardPage: FC<{ routes: any; id: string }> = memo(({ routes, id }) => {
	const { getPosts, getData: getPostsData } = usePosts();
	const { posts, loading } = getPostsData();

	const { getBoard } = useBoards();
	const [board, setBoard] = useState<CategoryModel | null>(null);
	const [offset, setOffset] = useState(1);
	// const [loading, setLoading] = useState(false);
	// const { showMessage } = useNotification();

	const totalLength = useMemo(
		() => (board ? (board.totalPostCount ? board.totalTopicCount + board.totalPostCount : board.totalTopicCount) : 0),
		[board]
	);
	const lastItemRef = useRef<HTMLDivElement>(null);
	const onScreen = useOnScreen(lastItemRef);
	const hasMoreItems = useMemo(() => posts.length < totalLength, [posts.length, totalLength]);

	useEffect(() => {
		getBoard(id).then(board => setBoard(board));
	}, [getBoard, id]);

	useEffect(() => {
		if (hasMoreItems && onScreen) {
			setOffset(ctx => ctx + 1);
		}
	}, [hasMoreItems, onScreen]);

	useEffect(() => {
		getPosts({ postType: PostScheduleType.Posted, limit: 6, offset, boardId: [id], paginate: true });
	}, [getPosts, id, offset]);

	const getIcon = useCallback(iconName => boardIcons.find(x => x.value === iconName), []);

	// const handleFollowBoard = useCallback(async () => {
	// 	if (board) {
	// 		setLoading(true);

	// 		try {
	// 			const { success } = await followBoard(id, board.followed);

	// 			if (!success) {
	// 				throw new Error();
	// 			}

	// 			showMessage(`Successfully ${board.followed ? "unfollowed" : "followed"} board.`);
	// 			setBoard({ ...board, followed: !board.followed });
	// 		} catch {
	// 			showMessage(`Failed to ${board.followed ? "unfollow" : "follow"} board.`);
	// 		}

	// 		setLoading(false);
	// 	}
	// }, [board, followBoard, id, showMessage]);

	return (
		<BoardWrapper>
			<BoardWrapper.PageContent>
				{board && (
					<BoardWrapper.PageHeader>
						<Box display="flex">
							<Box className="icon-wrapper">{getIcon(board.iconName)?.icon}</Box>
							<Box>
								<Text variant="h4">{board.name}</Text>
								<Text>{formatCount(totalLength, "post")} with this hashtag</Text>
							</Box>
						</Box>
						{/* <Button
							size="medium"
							buttonTheme={board.followed ? "main" : "light"}
							onClick={handleFollowBoard}
							disabled={loading}
						>
							{board.followed ? "Following" : "Follow"}
						</Button> */}
					</BoardWrapper.PageHeader>
				)}
				{(!loading || offset > 1) &&
					posts.map((post, i) => (
						<BoardWrapper.PostWrapper ref={i + 1 === posts.length ? lastItemRef : null} key={post.pid}>
							<Post hideBoard fullMode post={post} />
						</BoardWrapper.PostWrapper>
					))}
				{loading && (
					<>
						<BoardWrapper.PostWrapper>
							<PostSkeleton />
						</BoardWrapper.PostWrapper>
						<BoardWrapper.PostWrapper>
							<PostSkeleton />
						</BoardWrapper.PostWrapper>
					</>
				)}
			</BoardWrapper.PageContent>
			<BoardWrapper.SidePanel>
				<Hashtags hashtagsUrl={routes?.member.hashtags.getPath()} />
				<ContributorsCard preview contributorsUrl={routes?.member.contributors.getPath()} />
			</BoardWrapper.SidePanel>
		</BoardWrapper>
	);
});

export default BoardPage;
