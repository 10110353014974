import { Box } from "@material-ui/core";
import styled from "styled-components";

import { Text } from "shared/ui-kit";

import { customBreakpoints } from "theme/default";

export const TableWrapper = styled(Box)`
	border-radius: 0;
	box-shadow: none;
	overflow-x: auto;

	.MuiTableCell-root {
		border-bottom: 1px solid #edf1f7;
	}

	.MuiPaper-root {
		border-radius: 0;
		box-shadow: none;
	}

	.MuiInputBase-root.Mui-disabled {
		color: #222b45;
	}

	.MuiSelect-outlined.MuiSelect-outlined {
		display: flex;
		align-items: center;
	}

	.ReactVirtualized__Grid {
		${({ theme }) => theme.breakpoints.down(customBreakpoints.tabletVerticalMax)} {
			//overflow-x: initial !important;
		}

		//overflow-x: hidden !important;

		&:focus {
			outline: 0;
		}
	}

	svg {
		margin-left: initial !important;
	}
`;

export const Cell = styled(Box)``;

Cell.Wrapper = styled(Box)`
	display: flex;
	flex-direction: row;
	align-items: center;

	width: 100%;

	&.column {
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
	}

	.with-image {
		max-width: calc(100% - 56px);
	}
`;

Cell.Text = styled(Text)`
	word-break: break-all;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	font-size: 13px;

	width: 100%;

	&.light {
		color: #8f9bb3;
	}

	&.bold {
		font-weight: 700;
	}

	&.capitalize {
		text-transform: capitalize;
	}

	&.pointer {
		cursor: pointer;
	}

	&.inline-flex {
		display: inline-flex;
	}
`;

Cell.HeaderText = styled(Text)`
	font-size: 12px;
	font-weight: 500;
	color: #8f9bb3;
`;

Cell.Date = styled(Box)`
	word-break: break-all;
	align-self: center;
	p {
		font-size: 13px;
	}
	.secondary-date {
		color: #8f9bb3;
	}
`;

Cell.ImageWrapper = styled(Box)`
	width: 40px;
	min-width: 40px;
	height: 40px;

	border-radius: 4px;

	overflow: hidden;

	margin-right: 16px;

	display: flex;
	justify-content: center;
	align-items: center;

	background-color: #f7f9fc;
`;

Cell.Icon = styled(Box)`
	width: fit-content;
`;

Cell.Image = styled(Box)<{ src?: string }>`
	width: 100%;
	height: 100%;

	background-color: #f7f9fc;
	flex: 0 0 auto;

	display: flex;
	justify-content: center;
	align-items: center;

	background-image: url(${({ src }) => src});
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
`;
