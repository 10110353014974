import { Box } from "@material-ui/core";
import styled from "styled-components";

import { Text } from "shared/ui-kit";

export const Subscription = styled(Box)``;

Subscription.Block = styled(Box)`
	margin-top: 20px;
`;

Subscription.RowWrapper = styled(Box)`
	width: 100%;
	height: 60px;

	&:not(:last-child) {
		margin-bottom: 8px;
	}
`;

Subscription.PlanWrapper = styled(Box)`
	width: 100%;
	height: 100%;

	border-radius: 8px;

	padding: 11px;

	border: solid 1px #e4e9f2;

	cursor: pointer;
`;

Subscription.CheckboxWrapper = styled(Box)`
	width: 100%;

	.MuiButtonBase-root {
		padding: 0;
	}

	.MuiFormControlLabel-root {
		width: 100%;
		height: 100%;

		margin: 0;
	}

	.MuiFormControlLabel-label {
		font-size: 13px;
		font-weight: 600;
		line-height: 1.85;
		color: #222b45;

		padding-left: 8px;
	}
`;

Subscription.PriceWrapper = styled(Text)`
	font-size: 12px;
	line-height: 1.33;
	color: #8f9bb3;

	padding-left: 32px;
`;
