import React, { Fragment, useEffect, useMemo, useState } from "react";

import styled from "styled-components";

import IManageFeature from "types/IManageFeature";

import { Dialog } from "shared/Components";
import { originalFeatures } from "shared/constants";
import { useCommunity, useFeature, useNotification } from "shared/hooks";
import { Box, Button, Text } from "shared/ui-kit";

import { lowerFirstLetter } from "utils/lowerFirstLetter";

import { FeatureCard } from "../../Components";

const PageContainer = styled.form`
	position: relative;
`;

const Title = styled(Text)`
	width: 100%;
	position: relative;

	margin-bottom: 20px;
`;

const FeatureList = styled(Box)`
	width: 100%;
	padding: 40px;
	border-radius: 4px;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
	background-color: #ffffff;

	${props => props.theme.breakpoints.down("sm")} {
		padding: 20px;
	}
`;

const FeatureWrapper = styled(Box)`
	width: 100%;
	margin-bottom: 16px;

	border-radius: 4px;
	box-shadow: inset 0 0 0 0.5px #c5cee0, 0 2px 4px 0 rgba(0, 0, 0, 0.16);
	background-color: #ffffff;
`;

const PopupTitle = styled(Text)`
	font-size: 18px;
	font-weight: bold;
	line-height: 1.33;
	color: #222b45;
	text-align: left;

	padding-left: 16px;
`;

const Description = styled(Text)`
	font-size: 15px;
	font-style: normal;
	line-height: 1.47;
	color: #8f9bb3;
`;

const Features: React.FC = () => {
	const [featureList, setFeatureList] = useState<IManageFeature[]>([]);
	const [showPopup, setShowPopup] = useState(false);
	const [changeableFeature, setChangeableFeature] = useState<Partial<IManageFeature>>({
		title: "",
		value: "",
		enabled: false
	});

	const { getDetails, manageFeature, getData: getFeatureData } = useFeature();
	const { features } = getFeatureData();

	const { getData: getCommnunityData } = useCommunity();
	const { isWhitelabelAppMode, workspace } = getCommnunityData();

	const workspaceName = useMemo(() => workspace?.meta?.displayName || workspace?.name || "", [workspace]);

	const { showMessage } = useNotification();

	useEffect(() => {
		getDetails();
	}, [getDetails]);

	useEffect(() => {
		setFeatureList(
			originalFeatures
				.filter(x => (x?.hideForNonWhitelabel ? isWhitelabelAppMode || workspaceName.toLowerCase() === "vyoo" : true))
				.map(x => {
					const selected = features.find(f => f.value === x.value);
					return { ...x, enabled: selected ? selected.enabled : false };
				})
		);
	}, [features, isWhitelabelAppMode, workspaceName]);

	const handleRequestForFeatureUpdate = (featureTitleValue: string, enabled: boolean) => {
		const feature = originalFeatures.find(x => x.value === featureTitleValue);
		setChangeableFeature({
			title: feature?.title,
			value: featureTitleValue,
			enabled
		});
		setShowPopup(true);
	};

	const updateSettingsForFeature = (confirmed: boolean) => {
		setShowPopup(false);
		if (confirmed) {
			manageFeature(`${changeableFeature.value}`, !!changeableFeature.enabled);

			// TODO: temp solution. Will be fixed later on backend + mobile
			if (changeableFeature.value === "enableAudioTracks") {
				manageFeature("isPodcastEnabled", !!changeableFeature.enabled);
			}

			// const index = featureList.findIndex(x => x.value === changeableFeature.value);
			// if (index > -1) {
			// 	featureList[index].enabled = !!changeableFeature.enabled;
			// }
			// setFeatureList([...untrackDeep(featureList)]);

			showMessage(
				`${changeableFeature.title} has been successfully ${changeableFeature.enabled ? "enabled 🎉" : "disabled ✌️"}`,
				3
			);
		}
	};

	const ActionFooter = (
		<Fragment>
			<Button buttonTheme={"outline"} size={"large"} onClick={() => updateSettingsForFeature(false)}>
				No, cancel
			</Button>
			<Button size={"large"} onClick={() => updateSettingsForFeature(true)}>
				Yes, confirm
			</Button>
		</Fragment>
	);

	return (
		<>
			<PageContainer>
				<Title variant="h1">Features</Title>
				<FeatureList>
					{featureList.map((feature, index) => (
						<FeatureWrapper key={index}>
							<FeatureCard
								title={feature.title}
								text={feature.text}
								icon={feature.icon}
								enabled={feature.enabled}
								handleChange={enabled => handleRequestForFeatureUpdate(feature.value, enabled)}
								switchId={`${lowerFirstLetter(feature.value.replace("enable", "").replace("Enabled", ""))}Feature`}
							/>
						</FeatureWrapper>
					))}
				</FeatureList>
			</PageContainer>
			<Dialog
				title={<PopupTitle>Update Feature?</PopupTitle>}
				open={showPopup}
				onClose={() => updateSettingsForFeature(false)}
				footer={ActionFooter}
				hasBackButton={false}
				onBack={() => updateSettingsForFeature(false)}
			>
				<Description>
					Are you sure you want to {changeableFeature.enabled ? "enable" : "disable"} {changeableFeature.title}?
				</Description>
				<Description>
					The changes will take effect immediately and all your community members will be impacted.
				</Description>
			</Dialog>
		</>
	);
};

export default Features;
