import styled from "styled-components";

import { Box, Text } from "shared/ui-kit";

export const Card = styled(Box)`
	width: 100%;
	height: 100%;

	border-radius: 8px;

	box-shadow: inset 0 0 0 0.5px #c5cee0, 0 2px 4px 0 rgba(0, 0, 0, 0.12);

	background-color: #fff;

	overflow: hidden;
`;

Card.Icon = styled(Box)`
	width: 100%;
	height: 88px;

	position: relative;

	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
`;

Card.ProfileIcon = styled(Box)`
	position: absolute;

	width: 48px;
	height: 48px;

	border-radius: 50%;
	border: solid 1px #fff;

	overflow: hidden;

	top: calc(100% - 24px);
	left: 16px;

	background-color: #f2f2f2;

	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
`;

Card.Body = styled(Box)`
	padding: 39px 16px 16px 16px;

	height: calc(100% - 88px);

	position: relative;
`;

Card.Title = styled(Text)`
	font-size: 18px;
	font-weight: bold;
	line-height: 1.33;
	letter-spacing: normal;
	color: #222b45;
	text-align: left;
`;

Card.Count = styled(Text)`
	font-size: 13px;
	font-weight: normal;
	line-height: 1.23;
	letter-spacing: normal;
	color: #8f9bb3;
	text-align: left;

	margin-top: 6px;
`;

Card.Description = styled(Text)`
	font-size: 13px;
	font-weight: normal;
	line-height: 1.54;
	letter-spacing: normal;
	color: #222b45;
	text-align: left;

	display: block;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;

	margin-top: 18px;
`;

Card.Footer = styled(Box)`
	display: flex;
	justify-content: space-between;
	align-items: center;

	position: absolute;
	bottom: 16px;
	left: 0;

	width: 100%;

	padding: 0 16px;
`;

Card.Type = styled(Box)`
	padding: 4px 8px;

	border-radius: 12px;

	background-color: #f7f9fc;

	display: flex;
	align-items: center;
	justify-content: center;

	font-size: 12px;
	font-weight: normal;
	line-height: 1.33;
	letter-spacing: normal;
	color: #222b45;
`;

Card.Private = styled(Box)`
	svg {
		fill: #6173fe;
	}
`;
