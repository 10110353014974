import { useCallback, useMemo } from "react";

import { ForumPostModel, PostScheduleType } from "types";

import { usePostsApiService } from "shared/services";

import { actionMethod, actionTypes, getValidationMessage } from "utils/getValidationMessage";

import { usePostsStore } from "../contexts";
import { useNotification } from "./index";

const usePosts = () => {
	const postApiService = usePostsApiService();

	const store = usePostsStore();
	const { setState } = usePostsStore();

	const { showMessage } = useNotification();

	const getPostScheduleType = (originalType: PostScheduleType): string => {
		switch (originalType) {
			case PostScheduleType.Posted:
				return "active";

			case PostScheduleType.Scheduled:
				return "upcoming";

			default:
				return "all";
		}
	};

	const methods = useMemo(
		() => ({
			getPosts: async ({
				limit = 20,
				keyword = "",
				postType = PostScheduleType.Posted,
				offset,
				tags,
				boardId,
				paginate,
				extraRepliesCount
			}: {
				limit: number;
				keyword?: string;
				postType: PostScheduleType;
				offset: number;
				tags?: string[];
				boardId?: string[];
				paginate?: boolean;
				extraRepliesCount?: number;
			}) => {
				setState({ loading: true });
				const { posts } = await postApiService.getPosts({
					offset,
					limit,
					keyword,
					type: getPostScheduleType(postType),
					tags,
					boardId,
					extraRepliesCount
				});
				setState(ctx => ({
					offset,
					posts: paginate && offset > 1 ? [...ctx.posts, ...posts] : posts,
					loading: false
				}));
			},
			getPost: async (pid: string) => {
				setState({ loading: true });
				const { basePost } = await postApiService.getPost(pid);
				setState(ctx => ({
					posts: ctx.posts.map(post =>
						post.pid === pid
							? {
									...post,
									...basePost
							  }
							: post
					),
					loading: false
				}));

				return basePost;
			},
			getGroupPosts: async ({
				limit,
				offset,
				postId,
				paginate
			}: {
				limit: number;
				offset: number;
				postId: string;
				paginate?: boolean;
			}) => {
				setState({ loading: true });
				const { posts } = await postApiService.getGroupPosts(offset, limit, postId);
				setState(ctx => ({
					offset,
					posts: paginate && offset > 1 ? [...ctx.posts, ...posts] : posts,
					loading: false
				}));
			},
			getUserPosts: (creatorId: string, limit = 10, offset = 1) => {
				return postApiService.getPosts({ offset, limit, keyword: "", type: "all", tags: [], boardId: [], creatorId });
			},
			async deletePost({
				id,
				showNotification = false,
				contentType
			}: {
				id: string;
				showNotification?: boolean;
				contentType?: string;
			}) {
				const { code } = await postApiService.deletePost(id);
				if (code === "ok") {
					if (showNotification) {
						showMessage(
							getValidationMessage({
								name: contentType || "Post",
								actionType: actionTypes.CRUD,
								actionMethod: actionMethod.deleted,
								emoji: "🗑"
							}),
							3
						);
					}

					setState(ctx => ({
						posts: [...ctx.posts?.filter(post => post.pid !== id)],
						postCount: (ctx.postCount || 1) - 1,
						allPostCount: (ctx.allPostCount || 1) - 1
					}));
				}

				return code;
			},
			async getPostCount({
				keyword = "",
				postType = PostScheduleType.Posted,
				tags,
				boardId
			}: {
				keyword?: string;
				postType: PostScheduleType;
				tags?: string[];
				boardId?: string[];
			}) {
				const { totalCount } = await postApiService.getPostCount(keyword, getPostScheduleType(postType), tags, boardId);
				setState({ postCount: totalCount });
			},
			async getHashtagInfo(slug: string) {
				return postApiService.getHashtagInfo(slug);
			},
			async getAllPostCount() {
				setState({ loading: true });
				const { totalCount } = await postApiService.getPostCount("", PostScheduleType.All);
				setState({ allPostCount: totalCount, loading: false });
			},
			setPage: (page: number) => {
				setState({ page });
			},
			setPostsPerPage: (postsPerPage: number) => {
				setState({ postsPerPage });
			},
			setPosts: posts => {
				setState({ posts });
			},
			updatePostsList: ({ postInfo, isNew }: { postInfo: ForumPostModel; isNew: boolean }) => {
				if (isNew) {
					setState(ctx => ({
						posts: [postInfo, ...ctx.posts]
					}));
				} else {
					setState(ctx => ({
						posts: ctx.posts.map(x => (x.pid === postInfo.pid ? postInfo : x))
					}));
				}
			},
			removePostFromList: (pid: string) => {
				setState(ctx => ({ posts: ctx.posts.filter(x => x?.pid !== pid) }));
			},
			setCreateUpdatePostDialog: (createUpdatePostDialog: {
				isOpen: boolean;
				editablePost?: ForumPostModel;
				editablePostPid?: string;
			}) => {
				setState({ createUpdatePostDialog });
			},
			clear() {
				setState({ postCount: 0, posts: [], offset: 0, page: 1 });
			}
		}),
		[postApiService, setState, showMessage]
	);

	const getData = useCallback(() => {
		return store;
	}, [store]);

	return { ...methods, getData };
};

export default usePosts;
