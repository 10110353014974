import React from "react";

import { routes as adminRoutes } from "apps/RegularUser/constants";
import { view } from "modules/Manage";
import { useMemberRoutes } from "shared/hooks";

const ManageReportedContentPage = () => {
	const { getMemberRoutesData } = useMemberRoutes();
	const { routes: memberRoutes } = getMemberRoutesData();

	return <view.ManageReportedContent memberRoutes={memberRoutes} messagePageUrl={adminRoutes.messaging.getPath()} />;
};

export default ManageReportedContentPage;
