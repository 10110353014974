import { ButtonBase } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

import { ReactComponent as LocationIcon } from "assets/icons/icon-location-linear.svg";
import { Box, Text } from "shared/ui-kit";

export const ModalLink = styled(Link)`
	width: 100%;
	text-decoration: none;
`;

export const Item = styled(Box)``;

Item.Summary = styled(Text)`
	font-size: 13px;
	line-height: 1.23;
	color: #8f9bb3;
`;

Item.Duration = styled(Box)`
	position: absolute;
	top: 125px;
	right: 12px;
	border-radius: 6px;
	backdrop-filter: blur(20px);
	border: solid 1px rgba(255, 255, 255, 0.31);
	background-color: rgba(0, 0, 0, 0.8);
	font-size: 11px;
	color: #fff;
	padding: 4px;
	z-index: 10;
`;

Item.LoaderWrapper = styled(Box)`
	position: absolute;
	width: 100%;
	background-color: #edf1f7;
`;

Item.Loader = styled(Box)`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 160px;
`;

Item.PlayTrack = styled(ButtonBase)`
	position: absolute;
	top: 0;
	left: 0;
	opacity: 0;
	transition: ease-in-out 0.2s all;
	background-color: rgb(0 0 0 / 50%);
	width: 100%;
	height: 100%;
	svg path:last-child {
		fill: ${({ bgColor }) => bgColor};
	}
	.pause-fill path:last-child {
		fill: #ffffff;
	}

	${({ show }) =>
		show &&
		css`
			opacity: 1;
		`}
`;

Item.Wrapper = styled(Box)`
	width: 100%;
	height: ${({ autoHeight }) => (autoHeight ? "auto" : "310px")};
	max-height: ${({ maxHeight }) => (maxHeight ? `${maxHeight}px` : "auto")};
	text-align: left;
	padding: ${({ noPB }) => (noPB ? "0" : "0 0 16px 0")};
	border-radius: 12px;
	${({ isBorder }) =>
		isBorder
			? css`
					border: 1px solid #edf1f7;
			  `
			: css`
					box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.1);
			  `}
	background-color: ${({ chatCurrentUser }) => (chatCurrentUser ? "#4a99f9" : "#fff")};
	overflow: hidden;
	position: relative;
	p {
		text-align: left;
	}
	&.minimalistic {
		box-shadow: none;
		background-color: transparent;

		.icon-wrapper {
			border-radius: 12px;
			overflow: hidden;
		}
	}
	&.highlightOnHover {
		.extra-action-block {
			max-height: 0;
			overflow: hidden;
		}

		.icon-wrapper {
			transition: transform 0.2s;
		}

		&:hover {
			background-color: #f7f9fc;
			.icon-wrapper {
				transform: scale(0.95);
			}
			.extra-action-block {
				max-height: 200px;
			}
		}
	}

	${({ chatCurrentUser }) =>
		chatCurrentUser &&
		css`
			.before-title,
			.sub-title,
			.title,
			.peopleListText {
				color: #fff;
			}
		`}
`;

Item.VideoWrapper = styled(Box)`
	padding: 4px;
	position: relative;
	height: 160px;
	overflow: hidden;

	video {
		width: 100%;
		height: 100%;
		object-fit: cover;
		border-radius: 12px;
	}
`;

Item.IconWrapper = styled(Box)`
	width: 100%;
	height: 160px;

	svg {
		width: 100%;
	}

	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	background-color: #edf1f7;

	position: relative;

	display: flex;
	justify-content: center;
	align-items: center;

	overflow: hidden;

	&.large-thumbnail {
		height: 185px;
	}

	&.chat-thumbnail {
		width: 360px;
	}
`;

Item.ItemLogo = styled(Box)`
	width: 64px;
	height: 64px;

	display: flex;
	justify-content: center;
	align-items: center;
`;

Item.InfoBox = styled(Box)`
	width: 100%;
	padding: ${({ noSidePadding }) => (noSidePadding ? "15px 0" : "15px 16px")};
`;

Item.ExtraActionWrapper = styled(Box)`
	transition: max-height 0.2s ease-in-out;
`;

Item.BeforeTitle = styled(Text)`
	font-size: 13px;
	line-height: 1.23;
	color: #8f9bb3;
`;

Item.Title = styled(Text)`
	font-size: 16px;
	font-weight: bold;
	line-height: 1.33;
	color: #222b45;
	text-decoration: none;
	margin-top: 6px;
	max-height: 48px;
	overflow: hidden;
	text-overflow: ellipsis;

	display: -webkit-box;

	-webkit-line-clamp: 2;
	line-clamp: 2;

	-webkit-box-orient: vertical;
`;

Item.Subtitle = styled(Text)`
	font-size: 13px;
	font-weight: normal;
	line-height: 1.23;
	color: #8f9bb3;

	margin-top: 8px;
`;

Item.UnderTitle = styled(Text)`
	font-size: 13px;
	line-height: 1.23;
	color: #8f9bb3;
`;

Item.InfoRow = styled(Box)`
	display: flex;
	justify-content: space-between;
	align-items: center;

	position: absolute;
	bottom: 14px;
	left: 0;

	width: 100%;

	padding: 0 16px;
`;

Item.MemberListBox = styled(Box)`
	display: flex;
	align-items: center;
`;

Item.PeopleGoing = styled.p`
	font-size: 13px;
	font-weight: 600;
	line-height: 1.85;
	color: #222b45;
	margin-left: 4px;
`;

Item.MemberIcon = styled(Box)<{ bgUrl?: string }>`
	width: 28px;
	height: 28px;

	border: 2px solid white;
	border-radius: 50%;

	overflow: hidden;

	background-color: grey;

	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	background-image: ${({ bgUrl }) => (bgUrl ? `url(${bgUrl})` : "none")};

	&:not(:first-child) {
		margin-left: -6px;
	}
`;

Item.MemberText = styled(Text)`
	font-size: 13px;
	font-weight: 600;
	line-height: 1.85;
	color: #222b45;

	margin-left: 4px;
`;

Item.DistanceBox = styled(Box)`
	display: flex;
	align-items: center;
	justify-content: flex-end;
`;

Item.DistanceText = styled(Text)`
	font-size: 13px;
	font-weight: 600;
	line-height: 1.85;
	color: #222b45;

	margin-right: 4px;
`;

Item.DistanceIcon = styled(LocationIcon)`
	width: 24px;
	height: 24px;
`;

Item.LiveBadge = styled(Box)`
	position: absolute;
	z-index: 1;
	top: 8px;
	left: 8px;
`;

export const SkeletonBase = styled(Skeleton)`
	&.side-offset {
		margin: 0 5px;
	}
`;
