import React, { FC, useCallback, useEffect, useMemo, useState } from "react";

import { Controller, useForm } from "react-hook-form";

import { ReactComponent as MessageIcon } from "assets/icons/icon-chat-bubble.svg";
import { ReactComponent as CrownIcon } from "assets/icons/iconCrownLinear.svg";
import { ReactComponent as GlobeIcon } from "assets/icons/iconGlobe.svg";
import { ReactComponent as LocationIcon } from "assets/icons/iconLocation.svg";
import { ReactComponent as LockIcon } from "assets/icons/iconLock.svg";
import { ReactComponent as PeopleIcon } from "assets/icons/iconPeople.svg";
import { ReactComponent as EyeStrikeIcon } from "assets/icons/stories/icon-eye-strikethrough.svg";
import {
	EnableFeature,
	MemberDialog,
	RadioGroupInput,
	RectangleUploader,
	SelectInput
} from "modules/MemberHome/View/shared";
import LabeledInput from "modules/MemberHome/View/shared/LabeledInput";

import { InputWrapper, MemberDialogContentWrapper } from "modules/MemberHome/View/shared/style";
import { SetupPrice } from "shared/Components";
import { useGroup, useUser } from "shared/hooks";
import { useS3Uploader } from "shared/services/s3Uploader";
import { GroupModel } from "shared/types";
import { dataUrlToFile } from "utils/serviceUtils/helpers";

import { SetupPriceTitle } from "./styles";

const CreateSubGroup: FC<{
	group: GroupModel | null;
	handleClose: () => void;
	groupDetails: GroupModel | undefined;
	subGroups: GroupModel[];
	setSubGroups: React.Dispatch<React.SetStateAction<GroupModel[]>>;
}> = ({ group, handleClose, groupDetails, setSubGroups, subGroups }) => {
	const { uploadFile, getData: getS3UploaderData } = useS3Uploader();
	const { uploading } = getS3UploaderData();

	type SetupPriceMethodsHandlers = React.ElementRef<typeof SetupPrice>;
	const setupPriceFormRef = React.useRef<SetupPriceMethodsHandlers>(null);

	const { createGroup, updateGroup, getGroupFromListing, getData: getGroupData } = useGroup();
	const { group: parentGroup, groupImageUploadUrl } = getGroupData();

	const { loadSuggestedLocations, getData: getUserData } = useUser();
	const { suggestedLocations } = getUserData();

	const isSG = useMemo(() => !!group && !groupDetails, [group, groupDetails]);
	const [privacyMode, setPrivacyMode] = useState({
		key: "public",
		label: "Public"
	});

	const {
		control,
		formState: { isDirty, errors, isSubmitting, isValid },
		handleSubmit
	} = useForm({ mode: "onChange" });

	useEffect(() => {
		if (groupDetails?.private === 1) {
			setPrivacyMode({
				key: "private",
				label: "Private"
			});
		}
	}, [groupDetails]);

	const handleUploadImage = useCallback(
		async (files: FileList, onChange: (...event: any[]) => void) => {
			const img = files && files.length ? files[0] : null;
			const correctFile = typeof img === "string" ? await dataUrlToFile(img, "test") : img;
			if (img) {
				const data = await uploadFile({
					file: correctFile as File,
					communityName: groupImageUploadUrl
				});
				onChange(data?.publicUrl);
			} else {
				onChange(null);
			}
		},
		[groupImageUploadUrl, uploadFile]
	);

	const onSubmit = useCallback(
		async data => {
			delete data.priceChanged;

			const groupData = {
				chatEnabled: data.enableChat || false,
				coverUrl: data.photo,
				description: data.description,
				disablePosts: data.post?.key === "admins",
				audience: data.audience?.key,
				location: {
					name: data.location.label,
					place_id: data.location.value,
					description: data.location.description
				},
				name: data.name,
				private: data.privacy?.key,
				rules: data.rules
			};

			if (groupDetails) {
				await updateGroup({
					...groupData,
					cid: groupDetails.cid,
					slug: groupDetails.slug,
					hidden: data.hidden
				});
				group && (await getGroupFromListing(group.slug));
			} else {
				const g = await createGroup({ ...groupData, parentId: group?._id, hidden: data.hidden });
				if (parentGroup) {
					setSubGroups(!!subGroups.length ? [g, ...subGroups] : [g]);
				}
			}

			handleClose();
		},
		[
			groupDetails,
			handleClose,
			updateGroup,
			group,
			getGroupFromListing,
			createGroup,
			parentGroup,
			setSubGroups,
			subGroups
		]
	);

	return (
		<MemberDialog
			customWidth={512}
			title={isSG ? "Create Subgroup" : "Edit Group"}
			open={true}
			onClose={handleClose}
			confirmLeave={isDirty}
			footerPrimary={{
				text: isSG ? "Create" : "Update",
				disabled: !isDirty || !isValid || !!Object.keys(errors).length || uploading || isSubmitting,
				loading: isSubmitting || uploading,
				onClick: handleSubmit(onSubmit)
			}}
		>
			<form onSubmit={handleSubmit(onSubmit)}>
				<Controller
					name="photo"
					control={control}
					defaultValue={!isSG && groupDetails?.coverUrl}
					render={({ onChange, value }) => (
						<RectangleUploader
							defaultPreviewUrl={value}
							uploading={uploading}
							onChange={file => handleUploadImage(file, onChange)}
						/>
					)}
				/>
				<MemberDialogContentWrapper className="pv-20">
					<Controller
						name="name"
						control={control}
						rules={{
							required: `${isSG ? "Subgroup" : "Group"} name is required`,
							maxLength: { value: 40, message: "Name can't be longer than 40 characters" },
							minLength: { value: 3, message: "Name must be at least 3 characters" }
						}}
						defaultValue={!isSG ? groupDetails?.name : ""}
						render={({ onChange, value, ref }) => (
							<InputWrapper>
								<LabeledInput
									name="name"
									onChange={onChange}
									value={value}
									placeholder=" "
									label={isSG ? "Sub-group name *" : "Group name *"}
									showCounter={40}
									error={errors?.name?.message}
									inputRef={ref}
								/>
							</InputWrapper>
						)}
					/>
					{isSG && (
						<Controller
							name="parent"
							control={control}
							defaultValue={group?.name}
							render={({ onChange, value }) => (
								<InputWrapper>
									<LabeledInput
										name="parent"
										onChange={onChange}
										value={value}
										placeholder=" "
										label="Parent Group *"
										error={errors?.parent?.message}
										displayOnly
									/>
								</InputWrapper>
							)}
						/>
					)}
					<InputWrapper>
						<Controller
							name="priceChanged"
							control={control}
							render={({ onChange }) => (
								<SetupPrice
									ref={setupPriceFormRef}
									hasBorder={false}
									title={<SetupPriceTitle>Who can join this Group?</SetupPriceTitle>}
									onPriceChanged={() => onChange(true)}
								/>
							)}
						/>
					</InputWrapper>
					<Controller
						name="privacy"
						control={control}
						defaultValue={groupDetails?.private ? { key: "private", label: "Private" } : privacyMode}
						render={({ onChange, value }) => (
							<InputWrapper>
								<RadioGroupInput
									onChange={val => {
										setPrivacyMode(val);
										onChange(val);
									}}
									value={value}
									title={`Who can access this ${isSG ? "subgroup" : "group"}?`}
									options={[
										{
											key: "public",
											label: "Public",
											icon: <EyeStrikeIcon />,
											description: `Anyone can see who’s in the ${isSG ? "Sub-group" : "Group"} and what they post`
										},
										{
											key: "private",
											label: "Private",
											icon: <LockIcon className="fill-first-path" />,
											description: `Only members can see who’s in the ${
												isSG ? "Sub-group" : "Group"
											} and what they post`
										}
									]}
								/>
							</InputWrapper>
						)}
					/>
					{privacyMode.key === "private" && (
						<Controller
							name="hidden"
							control={control}
							defaultValue={groupDetails?.hidden}
							render={({ onChange, value }) => (
								<InputWrapper>
									<EnableFeature
										title="Hidden"
										description="Only members can find the Group"
										icon={<EyeStrikeIcon />}
										onChange={onChange}
										value={value}
										verticalPadding
									/>
								</InputWrapper>
							)}
						/>
					)}
					<Controller
						name="audience"
						control={control}
						defaultValue={
							groupDetails?.isNational
								? {
										key: "national",
										label: "Regional/National"
								  }
								: {
										key: "local",
										label: "Local"
								  }
						}
						render={({ onChange, value }) => (
							<InputWrapper>
								<RadioGroupInput
									onChange={onChange}
									value={value}
									title={`Who is the audience of this ${isSG ? "subgroup" : "group"}?`}
									options={[
										{
											key: "local",
											label: "Local",
											icon: <LocationIcon />,
											description: `Only members who are local or near the ${
												isSG ? "Sub-group" : "Group"
											} location will see the ${isSG ? "Sub-group" : "Group"}.`
										},
										{
											key: "national",
											label: "Regional/National",
											icon: <GlobeIcon />,
											description: `Anyone in the community will be able to see the ${
												isSG ? "Sub-group" : "Group"
											} regardless of location.`
										}
									]}
								/>
							</InputWrapper>
						)}
					/>
					<Controller
						name="post"
						control={control}
						defaultValue={
							groupDetails?.disablePosts
								? {
										key: "admins",
										label: "Admins & Moderators"
								  }
								: {
										key: "anyone",
										label: "Anyone"
								  }
						}
						render={({ onChange, value }) => (
							<InputWrapper>
								<RadioGroupInput
									onChange={onChange}
									value={value}
									title={`Who can post in this ${isSG ? "subgroup" : "group"}?`}
									options={[
										{
											key: "anyone",
											label: "Anyone",
											icon: <PeopleIcon />,
											description: `Any member of the ${isSG ? "Sub-group" : "Group"} can create a post`
										},
										{
											key: "admins",
											label: "Admins & Moderators",
											icon: <CrownIcon />,
											description: `Only ${isSG ? "Sub-group" : "Group"} admins & moderators can create a post`
										}
									]}
								/>
							</InputWrapper>
						)}
					/>
					<Controller
						name="location"
						control={control}
						defaultValue={
							groupDetails?.location &&
							groupDetails?.placeId && { value: groupDetails.placeId, label: groupDetails.location }
						}
						rules={{
							required: "Location is required",
							validate: val => {
								if (!val.value || !val.label) {
									return "Please choose a location option";
								}
							}
						}}
						render={({ onChange, value, ref }) => (
							<InputWrapper>
								<SelectInput
									name="location"
									onChange={onChange}
									placeholder=" "
									label="Location *"
									value={value ? value.label : null}
									loadSuggestions={val => loadSuggestedLocations(val)}
									maxHeight={260}
									error={errors?.location?.message}
									noOptionsHeadline="Type your location ( ex: New York )"
									options={
										suggestedLocations
											? suggestedLocations.map(suggestion => ({
													value: suggestion.place_id,
													label: suggestion.name,
													description: suggestion.description
											  }))
											: []
									}
									selectInputRef={ref}
								/>
							</InputWrapper>
						)}
					/>
					<Controller
						name="description"
						control={control}
						rules={{
							required: "Description is required",
							maxLength: { value: 1000, message: "Description can't be longer than 1000 characters" },
							minLength: { value: 5, message: "Description must be at least 5 characters" }
						}}
						defaultValue={groupDetails?.description}
						render={({ onChange, value, ref }) => (
							<InputWrapper>
								<LabeledInput
									textarea
									name="description"
									onChange={onChange}
									placeholder=" "
									label="Description *"
									value={value}
									error={errors?.description?.message}
									showCounter={1000}
									inputRef={ref}
								/>
							</InputWrapper>
						)}
					/>
					<Controller
						name="rules"
						control={control}
						rules={{
							required: "Rules are required",
							maxLength: { value: 1000, message: "Rules can't be longer than 1000 characters" },
							minLength: { value: 5, message: "Rules must be at least 5 characters" }
						}}
						defaultValue={groupDetails?.rules}
						render={({ onChange, value, ref }) => (
							<InputWrapper>
								<LabeledInput
									textarea
									name="rules"
									onChange={onChange}
									placeholder=" "
									label="Rules *"
									value={value}
									error={errors?.rules?.message}
									showCounter={1000}
									inputRef={ref}
								/>
							</InputWrapper>
						)}
					/>
					<Controller
						name="enableChat"
						control={control}
						defaultValue={groupDetails?.chatEnabled}
						render={({ onChange, value }) => (
							<InputWrapper>
								<EnableFeature
									icon={<MessageIcon className="fill-first-path" />}
									title="Chat"
									description={`Enable ${isSG ? "Sub-group" : "Group"} chat`}
									onChange={onChange}
									value={value}
									fill="#c5cee0"
									verticalPadding
								/>
							</InputWrapper>
						)}
					/>
				</MemberDialogContentWrapper>
			</form>
		</MemberDialog>
	);
};

export default CreateSubGroup;
