import styled, { keyframes } from "styled-components";

import { Box } from "shared/ui-kit";

export const Wrapper = styled(Box)`
	position: relative;

	background-color: black;

	padding: 8px;

	width: 100%;
	height: 100%;

	overflow: hidden;
`;

Wrapper.ContentBox = styled(Box)`
	display: flex;
	flex-direction: row;

	width: 100%;
	height: calc(100% - 56px);

	position: relative;

	margin-bottom: 8px;
`;

Wrapper.ActionBox = styled(Box)`
	display: flex;
	align-items: center;
	justify-content: space-between;

	width: 100%;
	height: 48px;

	overflow: hidden;
	border-radius: 12px;

	background: #1f1f1f;
`;

Wrapper.ParticipantsSection = styled(Box)`
	position: relative;

	width: 100%;
	height: 100%;

	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;

	transition: width 0.25s ease-in-out 0.1s;

	&.with-panel {
		width: calc(100% - 328px);

		transition: width 0.15s ease-in-out 0s;
	}
`;

const show = keyframes`
0% {
	opacity: 0;
	transform: scale(0.4) translateY(20px);
}

100% {
	opacity: 1;
	transform: scale(1) translateY(0);
}
`;

export const ParticipantCard = styled(Box)`
	margin: 8px;

	&.speaker-item {
		animation: ${show} 0.4s ease;
	}

	&.speakerhidden {
		display: none;
	}

	&.speakerfull {
		height: 100% !important;
		width: 100% !important;

		margin: 0;
	}
`;

export const EndAlertWrapper = styled(Box)`
	background: #db2c33;
	color: white;
	text-align: center;
	height: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 12px;
	.MuiButtonBase-root {
		padding: 8px 12px;
		background: white;
		color: #222b45;
		font-weight: 500;
		margin-left: 16px;
	}
`;
