import { Backdrop, Box } from "@material-ui/core";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

import AkinaLogoAdmin from "assets/images/akinaLogoAdminNew.png";
import AkinaLogo from "assets/images/akinaLogoNew.png";

import BitcoinLogo from "assets/images/bitcoin-logo.webp";
import ReliasLogo from "assets/images/relias.webp";

import { Button } from "shared/ui-kit";
import theme, { customBreakpoints } from "theme/default";

export const PageWrapper = styled(Box)`
	min-height: 100vh;

	&.workspace-kijijimoms {
		.menu-wrapper {
			${props => props.theme.breakpoints.down("sm")} {
				top: 96px;
				height: calc(100vh - 96px);
			}
		}

		header {
			&.workspace-kijijimoms {
				.akina-logo {
					display: block;

					width: 130px;
					height: 25px;

					background-image: url(${AkinaLogoAdmin});
					background-position: center;
					background-repeat: no-repeat;
					background-size: contain;

					${props => props.theme.breakpoints.down("sm")} {
						width: 105px;
						height: 20px;

						margin-left: 10px;
					}
				}

				&.header_member {
					.akina-logo {
						width: 190px;
						height: 80px;

						background-image: url(${AkinaLogo});

						${props => props.theme.breakpoints.down("sm")} {
							width: 120px;
							height: 50px;

							margin-left: 10px;
						}
					}
				}
			}
		}
	}

	&.workspace-holliblu {
		header {
			&.workspace-holliblu {
				.relias-logo {
					display: block;

					width: 170px;
					height: 46px;

					background-image: url(${ReliasLogo});
					background-position: center;
					background-repeat: no-repeat;
					background-size: contain;

					${props => props.theme.breakpoints.down("sm")} {
						width: 120px;
						height: 34px;

						margin-left: 10px;
					}
				}

				&.header_member {
					.relias-logo {
						width: 170px;
						height: 46px;

						background-image: url(${ReliasLogo});

						${props => props.theme.breakpoints.down("sm")} {
							width: 120px;
							height: 34px;

							margin-left: 10px;
						}
					}
				}
			}
		}
	}

	&.workspace-bitcoin {
		header {
			&.workspace-bitcoin {
				.bitcoin-logo {
					display: block;

					width: 55px;
					height: 50px;

					background-image: url(${BitcoinLogo});
					background-position: center;
					background-repeat: no-repeat;
					background-size: contain;

					${props => props.theme.breakpoints.down("sm")} {
						width: 120px;
						height: 34px;

						margin-left: 10px;
					}
				}

				&.header_member {
					.bitcoin-logo {
						width: 55px;
						height: 50px;

						background-image: url(${BitcoinLogo});

						${props => props.theme.breakpoints.down("sm")} {
							width: 55px;
							height: 50px;

							margin-left: 10px;
						}
					}
				}
			}
		}
	}
`;

export const TopBar = styled.header`
	width: 100%;
	height: auto;
	position: fixed;
	top: 0;
	z-index: 1200;
	display: block;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08), inset 0 0 0 0.5px ${theme.palette.colors.basic[500]};
	background-color: white;

	&.workspace-kijijimoms&.header_member {
		height: 96px;

		background: #a0a0f2;

		box-shadow: none;

		backdrop-filter: none;

		.header {
			&__container {
				width: 1190px;
				height: 100%;

				margin: 0 auto;
				padding: 0;

				${props => props.theme.breakpoints.down("sm")} {
					width: 100%;
				}
			}

			&__action-icon-wrapper {
				width: 50px;
				height: 50px;

				padding: 2px;

				margin: 0 9px;

				${props => props.theme.breakpoints.down("sm")} {
					width: 34px;
					height: 34px;

					margin: 0 5px;
				}

				svg {
					width: 36px;
					height: 36px;

					${props => props.theme.breakpoints.down("sm")} {
						width: 26px;
						height: 26px;
					}
				}
			}

			&__left-side {
				margin: 0 0 0 -8px;
				padding: 0;
			}

			&__right-side {
				margin: 0 -16px 0 0;
				padding: 0;
			}
		}

		.profile__name {
			color: white;
		}
	}
`;

TopBar.Container = styled(Box)`
	width: 100%;
	height: 64px;

	margin: 0 auto;

	padding: 0 24px;
	display: flex;
	align-items: center;
	justify-content: space-between;

	position: relative;

	&.admin-view {
		${props => props.theme.breakpoints.down("sm")} {
			padding: 14px 8px;
		}
	}

	${props => props.theme.breakpoints.down(customBreakpoints.tabletHorizontalMax)} {
		height: 72px;
	}
`;

TopBar.LeftSide = styled(Box)`
	display: flex;
	align-items: center;

	${props => props.theme.breakpoints.down("sm")} {
		display: none;
	}
	${props => props.theme.breakpoints.up("sm")} {
		min-width: 290px;
		padding: 8px 16px;
		margin-left: -16px;
	}
`;

TopBar.RightSide = styled(Box)`
	display: flex;
	align-items: center;
	justify-content: flex-end;

	${props => props.theme.breakpoints.down("sm")} {
		width: 100%;
		justify-content: space-between;
	}
`;

TopBar.SearchBarWrapper = styled(Box)`
	position: absolute;

	width: 480px;
	height: 100%;

	left: calc(50% - 240px);
	top: 0;

	display: flex;
	align-items: center;
	justify-content: center;

	& > div {
		background-color: #f7f9fc;
		border-radius: 8px;
		box-shadow: inset 0 0 0 0.5px #c6cfe0;
	}

	${props => props.theme.breakpoints.down("sm")} {
		display: none;
	}
`;

TopBar.ActionIconWrapper = styled(Box)`
	display: inline-flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	width: 40px;
	height: 40px;
	border-radius: 8px;

	&.active {
		background-color: ${props => `${props.color}33`};
		.navSvg {
			fill: ${props => props.color};
			path[fill] {
				fill: ${props => props.color};
			}
		}
	}

	&.position-initial {
		position: initial;
	}

	.navSvg {
		width: 20px;
		height: 20px;
		fill: #8f9bb3;
	}

	&:hover {
		.navSvg {
			fill: ${props => props.color};
			path[fill] {
				fill: ${props => props.color};
			}
		}
	}
`;

TopBar.ActionLink = styled(Link)`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
`;

TopBar.ButtonAction = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	text-align: left;
	position: relative;
`;

TopBar.CommunitiesList = styled(Box)`
	display: none;
	align-items: center;
	justify-content: center;

	${props => props.theme.breakpoints.down("sm")} {
		display: flex;
	}
`;

TopBar.ActionList = styled(Box)`
	display: flex;
	align-items: center;
	flex: 1;
	gap: 4px;

	${props => props.theme.breakpoints.down("sm")} {
		justify-content: flex-end;
	}

	&.member-view {
		${props => props.theme.breakpoints.down("sm")} {
			justify-content: space-between;
			padding-left: 20px;
		}
	}
`;

TopBar.MenuWrapper = styled(Box)`
	display: none;
	align-items: center;
	justify-content: center;

	${props => props.theme.breakpoints.down("sm")} {
		display: flex;
	}
`;

TopBar.ProfileWrapper = styled(Box)`
	display: flex;
	align-items: center;
	justify-content: center;

	${props => props.theme.breakpoints.down("sm")} {
		display: none;
	}

	&.admin-view {
		${props => props.theme.breakpoints.down("sm")} {
			display: block;

			button {
				min-width: auto;
				padding: 0;
			}
		}
	}
`;

TopBar.NotificationCountWrapper = styled.span`
	position: absolute;
	top: 0;
	right: 0;

	min-width: 16px;
	height: 16px;

	padding: 0 4px;

	border-radius: 8px;
	overflow: hidden;

	background: #ff463d;

	font-size: 10px;
	font-weight: 600;
	text-align: center;
	color: #fff;

	display: flex;
	justify-content: center;
	align-items: center;

	transition: opacity 0.25s ease-in-out;

	&.show {
		opacity: 1;
	}

	&.hide {
		opacity: 0;
	}
`;

TopBar.NotVerifiedWrapper = styled(Box)`
	position: relative;
	width: 100%;
`;

export const Main = styled.main<{ stickyTop?: number }>`
	background-color: ${theme.palette.colors.basic[200]};

	display: flex;

	min-height: calc(100% - ${({ stickyTop }) => stickyTop || 64});
	top: ${({ stickyTop }) => stickyTop || 64}px;
	position: relative;
`;

export const ContentWrapper = styled.section`
	width: 100%;
	position: relative;
	left: 0;
	display: flex;

	${props => props.theme.breakpoints.down("sm")} {
		display: block;
	}

	${props => props.theme.breakpoints.down(customBreakpoints.tabletHorizontalMax)} {
		left: 0;
		width: 100%;
	}
`;

export const PageContentWrapper = styled(Box)`
	padding: 24px;
	width: 100%;
	max-width: 1400px;

	${props => props.theme.breakpoints.down("sm")} {
		padding: 16px;
	}

	&.member-view {
		width: auto;
		margin: 0 auto;
		padding-top: 16px;

		${props => props.theme.breakpoints.down("sm")} {
			width: 100%;
			padding: 16px 0;
		}
	}

	&.messagingView {
		padding-bottom: 0;

		${props => props.theme.breakpoints.down("sm")} {
			padding: 0;
		}
	}
`;

export const IconWrapper = styled(Box)`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	cursor: pointer;
`;

export const MenuWrapper = styled(Box)`
	display: flex;
	justify-content: center;
	align-content: center;

	width: 100vw;
	height: calc(var(--app-height) - 72px);

	position: fixed;
	top: 72px;
	left: 0;

	transform: translateX(-100vw);

	transition: transform 0.25s ease-in-out;

	z-index: 1200;

	background-color: white;

	&.open {
		transform: translateX(0);
	}
`;

export const BackButtonWrapper = styled(Box)`
	position: absolute;
	z-index: 100;
	top: 20px;
	left: 19px;

	${props => props.theme.breakpoints.down("sm")} {
		top: 0;
		left: 0;

		padding: 6px;

		position: relative;
	}
`;

export const BackButton = styled(Button)`
	border-radius: 8px;

	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08), inset 0 0 0 0.5px #c5cee0;

	background-color: #fff;

	&:hover {
		color: #000;
	}

	width: 90px;
`;

export const ModalCardWrapper = styled(Box)<{ show: boolean }>`
	position: absolute;
	top: 100%;

	z-index: 5;

	width: 400px;
	transition: opacity 0.25s ease-in-out;

	${({ show }) =>
		show
			? css`
					opacity: 1;
					pointer-events: auto;
			  `
			: css`
					opacity: 0;
					pointer-events: none;
			  `}

	${props => props.theme.breakpoints.up("sm")} {
		right: 24px;
	}
`;

export const ArrowIconWrapper = styled(Box)`
	display: flex;
	justify-content: center;
	align-content: center;

	padding-left: 8px;

	svg {
		width: 16px;
	}
`;

export const Wrapper = styled(Box)`
	width: 100%;

	&.admin-view {
		${props => props.theme.breakpoints.up("md")} {
			width: calc(100% - 302px);
			margin-left: 302px;
			display: flex;
			justify-content: center;
		}
	}
`;

export const SideBarOverlay = styled(Backdrop)`
	z-index: 1001;
	&.active {
		display: none;
	}
`;
