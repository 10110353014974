import React, { FC, useCallback, useEffect, useMemo, useState } from "react";

import { InviteMembersModal } from "shared/Components";
import { useGroup, useMembers, useNotification, useUser } from "shared/hooks";
import { GroupModel } from "shared/types";
import { formatCount } from "utils/serviceUtils/helpers";

const InviteToGroup: FC<{ group: GroupModel; handleClose: () => void }> = ({ group, handleClose }) => {
	const { getMembersToInvite, getMembersToInviteCount, getData: getMembersData } = useMembers();
	const { statusFilter, loadingMembers, totalCount, personas } = getMembersData();
	const { getData: getUserData } = useUser();
	const { user } = getUserData();

	const { inviteMembers } = useGroup();

	const { showMessage } = useNotification();

	const [offset, setOffset] = useState(1);
	const [keyword, setKeyword] = useState("");

	const hasMoreData = useMemo(() => totalCount > personas.length, [personas.length, totalCount]);

	useEffect(() => {
		getMembersToInvite(
			{
				offset: offset,
				limit: 10,
				groupId: group?._id,
				keyword: keyword || undefined,
				filterBy: statusFilter
			},
			true
		);
	}, [keyword, getMembersToInvite, group, offset, statusFilter]);

	useEffect(() => {
		getMembersToInviteCount({
			groupId: group?._id,
			keyword: keyword || undefined
		});
	}, [getMembersToInviteCount, group, keyword]);

	const loadMore = () => {
		if (hasMoreData && !loadingMembers) {
			setOffset(offset => offset + 1);
		}
	};

	const handleInvite = useCallback(
		async (personaIds: number[]) => {
			const list = personaIds;

			await inviteMembers(list, group?.slug);

			handleClose();

			showMessage(`${formatCount(list.length, "user")} ${list.length > 1 ? "have" : "has"} been invited! ✨`);
		},
		[group?.slug, inviteMembers, showMessage, handleClose]
	);

	return (
		<InviteMembersModal
			title={"Invite to group"}
			show
			onHide={handleClose}
			members={personas.filter(p => p._id !== user?.personaDocId)}
			onChangeKeyword={keyword => {
				setKeyword(keyword);
				setOffset(1);
			}}
			loading={loadingMembers}
			onLoadMore={loadMore}
			onInvite={handleInvite}
			entityText={"group"}
		/>
	);
};

export default InviteToGroup;
