import React, { FC, useCallback, useEffect, useRef, useState } from "react";

import { Box, useMediaQuery } from "@material-ui/core";
import YouTube from "react-youtube";

import styled from "styled-components";

import { useStream } from "shared/hooks";
import { StreamEntityType, VideoModel } from "shared/types";
import * as appTheme from "theme/default";

import { NextPlaylistItem } from "..";

const PlayerWrapper = styled(Box)`
	position: relative;
	iframe {
		height: 450px;
	}
`;

const Player = styled.video`
	width: 100%;
	height: auto;
	height: 220px;
	object-fit: cover;
	${props => props.theme.breakpoints.up("sm")} {
		height: 450px;
	}
`;

const VideoPlayer: FC<{
	isYTVideo: boolean;
	videoPlayer: VideoModel;
	mini?: boolean;
	nextItem?: {
		title: string;
		subtitle: string;
	};
	autoPlay?: boolean;
}> = ({ isYTVideo, videoPlayer, mini, nextItem, autoPlay }) => {
	const videoRef = useRef<HTMLVideoElement>(null);
	const { startStream, pauseStream } = useStream();

	const ytId = isYTVideo ? new URL(videoPlayer.meta.video.url || "").pathname.split("/")[1] : "";
	const isMobile = useMediaQuery(appTheme.default.breakpoints.down("sm"));

	const [videoNextClosed, setVideoNextClosed] = useState(false);
	const [videoEnded, setVideoEnded] = useState(false);

	const handleVideoEnded = useCallback(() => {
		setVideoEnded(true);
	}, []);

	const handleVideoPlay = useCallback(() => {
		startStream({ entityId: videoPlayer._id, type: StreamEntityType.VIDEO });
	}, [startStream, videoPlayer._id]);

	const handleVideoPaused = useCallback(
		e => {
			pauseStream({ entityId: videoPlayer._id, current: e.target.currentTime, type: StreamEntityType.VIDEO });
		},
		[pauseStream, videoPlayer._id]
	);

	useEffect(() => {
		const video = videoRef.current;
		if (video) {
			video.addEventListener("ended", handleVideoEnded, false);
		}

		return () => {
			video?.removeEventListener("ended", handleVideoEnded, false);
		};
	}, [handleVideoEnded]);

	useEffect(() => {
		document?.querySelector("video")?.setAttribute("oncontextmenu", "return false;");
		const video = videoRef.current;
		return () => {
			if (video) {
				pauseStream({ entityId: videoPlayer._id, current: video.currentTime, type: StreamEntityType.VIDEO });
			}
		};
	}, [pauseStream, videoPlayer._id]);

	const handleClose = useCallback(() => {
		setVideoNextClosed(true);
	}, []);

	return (
		<PlayerWrapper>
			{nextItem && !videoNextClosed && videoEnded && (
				<NextPlaylistItem title={nextItem.title} subtitle={nextItem.subtitle} handleClose={handleClose} />
			)}
			{isYTVideo ? (
				<YouTube
					videoId={ytId}
					onEnd={handleVideoEnded}
					opts={{
						width: "100%",
						height: isMobile ? "220px" : mini ? "400px" : "550px",
						playerVars: {
							autoplay: 0
						}
					}}
				/>
			) : (
				<>
					<Player
						key={videoPlayer._id}
						ref={videoRef}
						controls={autoPlay}
						autoPlay={autoPlay}
						onPlay={handleVideoPlay}
						onPause={handleVideoPaused}
						controlsList="nodownload"
					>
						{videoPlayer.meta.video.url && <source src={videoPlayer.meta.video.url} />}
					</Player>
				</>
			)}
		</PlayerWrapper>
	);
};

export default VideoPlayer;
