import { Box } from "@material-ui/core";
import styled from "styled-components";

export const TrackProgressWrapper = styled(Box)`
	border-radius: 8px;
	box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12);
	box-shadow: inset 0 0 0 0.5px #c5cee0;
	background-color: #fff;
	position: fixed;
	bottom: ${({ abovePlayer }) => (abovePlayer ? "90px" : "32px")};
	left: 16px;
	width: calc(100% - 32px);
	z-index: 1000;

	.header {
		border-top-left-radius: 8px;
		border-top-right-radius: 8px;
		position: relative;
		padding: 12px 24px;
		background-color: #151a30;
		font-size: 15px;
		font-weight: 600;
		line-height: 1.6;
		color: #fff;
		display: flex;
		align-items: center;
		.close {
			position: absolute;
			height: 100%;
			right: 16px;
			top: 0;
			display: flex;
			align-items: center;
			svg {
				fill: white;
				width: 16px;
				height: 16px;
			}
		}
	}
	.list {
		position: relative;
		max-height: 144px;
		overflow: auto;
		.item {
			display: flex;
			align-items: center;
			height: 48px;
			padding: 0 18px;
			border-bottom: 1px solid #c5cee0;
			.klQFsn {
				display: none;
			}
			.MuiCircularProgress-colorPrimary {
				color: #edf1f7;
				&:last-child {
					color: #6173fe;
				}
			}
			.track-name {
				flex: 1;
				margin-right: 5px;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
			p {
				margin-left: 8px;
				font-size: 15px;
				line-height: 1.33;
				color: #222b45;
				display: flex;
			}
		}
	}

	${props => props.theme.breakpoints.up("sm")} {
		left: 24px;
		width: 320px;
	}
`;
