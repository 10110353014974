import { useMemo } from "react";

import { DefaultForumResponseType } from "types";

import { API_URLS } from "shared/constants";
import useHttpActions from "shared/services/api/core/useHttpActions";

import { BaseConverter } from "shared/services/converters";

import { GetPostType } from "shared/types";

import { GetSearchPostTotalType, GetSearchPostType } from "../types";

const usePostsApiService = () => {
	const actions = useHttpActions();

	return useMemo(
		() => ({
			getPosts: async ({
				offset = 1,
				limit = 10,
				keyword = "",
				type,
				tags,
				boardId,
				creatorId,
				extraRepliesCount
			}: {
				offset: number;
				limit: number;
				keyword?: string;
				type: string;
				tags?: string[];
				boardId?: string[];
				creatorId?: string;
				extraRepliesCount?: number;
			}) => {
				return BaseConverter.convert<GetSearchPostType>(
					await actions.post(API_URLS.POST.GET_LIST, {
						payload: {
							offset,
							limit,
							keyword,
							boardId: boardId ? boardId : [],
							type,
							tags,
							creatorId,
							extraRepliesCount
						},
						path: "/v3/recent",
						method: "GET",
						token: null
					})
				);
			},
			getPost: async (pid: string) => {
				return BaseConverter.convert<GetPostType>(
					await actions.post(API_URLS.TOPIC.GET_TOPIC, {
						payload: {
							offset: 1,
							limit: 1,
							postId: pid
						},
						path: "/v3/posts",
						method: "GET",
						token: null
					})
				);
			},
			getGroupPosts: async (offset = 1, limit = 10, postId: string) => {
				return BaseConverter.convert<GetSearchPostType>(
					await actions.post(API_URLS.POST.GET_LIST, {
						payload: {
							offset,
							limit,
							postId
						},
						path: "/v3/posts",
						method: "GET",
						token: null
					})
				);
			},
			getHashtagInfo: async (slug: string) => {
				return BaseConverter.convert(
					await actions.get(API_URLS.BOARD.GET_LIST, {
						path: `/tag/${slug}`,
						method: "GET"
					})
				);
			},
			getPostCount: async (keyword = "", type: string, tags?: string[], boardId: string[] = []) => {
				return BaseConverter.convert<GetSearchPostTotalType>(
					await actions.post(API_URLS.POST.GET_LIST, {
						payload: {
							keyword,
							tags,
							boardId,
							type
						},
						path: "/v3/recent/count",
						method: "GET",
						token: null
					})
				);
			},
			deletePost: async (id: string) => {
				return BaseConverter.convert<DefaultForumResponseType>(
					await actions.post(API_URLS.POST.DELETE, {
						path: `/v2/topics/${id}`,
						method: "DELETE"
					})
				);
			}
		}),
		[actions]
	);
};

export default usePostsApiService;
