import { Box } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import styled from "styled-components";

export const SkeletonRow = styled(Skeleton)`
	transform: scale(1);
`;

export const SkeletonPostHeader = styled(Box)``;

SkeletonPostHeader.Row = styled(Box)`
	display: flex;
	align-items: center;

	margin: 20px 0 30px 0;
`;

SkeletonPostHeader.Item = styled(Box)`
	margin-right: 13px;
`;

export const SkeletonPostBody = styled(Box)``;

SkeletonPostBody.Editor = styled(Box)`
	margin: 10px 0 60px 0;
`;

SkeletonPostBody.ExtraSection = styled(Box)`
	width: 70%;
	margin: 0 auto;
`;
