import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";

import { Box } from "@material-ui/core";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

import { ItemId, SeriesCollection } from "modules/Manage/Data/types/SeriesTypes";
import LoadingTracks from "modules/Manage/View/Components/AddTracksToPlaylist/LoadingTracks";
import {
	ClosePopupButton,
	ModalFooter,
	ModalHeader,
	ModalOverlay
} from "modules/Manage/View/Components/AddTracksToPlaylist/style";
import { reorder } from "modules/Manage/View/Components/AlbumDetailsDialog";
import { AlbumDetailsModalContent, PreviewAudio } from "modules/Manage/View/Components/AlbumDetailsDialog/style";
import { LoadingAlbums } from "modules/Manage/View/Components/CreateUpdateAlbumDialog/style";
import ConfirmLeavePopup from "shared/Components/ConfirmLeave";
import useConfirmLeavePopup from "shared/Components/ConfirmLeave/hooks/useConfirmLeavePopup";
import { useCommunity, useOnScreen, useSeries } from "shared/hooks";
import { Button, Icon, InPlaceModal, Loader, Text } from "shared/ui-kit";
import { formatCount } from "utils/serviceUtils/helpers";

import PreviewVideo from "./PreviewVideo";
import FileRow from "./rows/FileRow";
import TrackRow from "./rows/TrackRow";
import VideoRow from "./rows/VideoRow";

interface Props {
	onUpdateSeriesContent?: () => void;
	onUpdate?: (updatedCollection?: SeriesCollection, isCreateUpdate?: boolean) => void;
	shouldUpdateIgnore?: boolean;
}

const SeriesDetailsDialog: FC<Props> = ({ onUpdate, onUpdateSeriesContent, shouldUpdateIgnore = false }) => {
	const [filesReordered, setFilesReordered] = useState(false);
	const [offset, setOffset] = useState(1);
	const [loadData, setLoadData] = useState(true);

	const {
		setSeriesDetailsDialog,
		getCollection,
		setSeriesDialog,
		updateCollection,
		reorderCollection,
		getData: getSeriesData
	} = useSeries();

	const { seriesDetailsDialog, isLoading, playPreview } = getSeriesData();

	const lastItemRef = useRef<HTMLDivElement>(null);
	const onScreen = useOnScreen(lastItemRef);

	const itemsCount = (seriesDetailsDialog?.data && seriesDetailsDialog.data.originalItemIds.length) || 0;

	const hasMoreItems = useMemo(
		() => itemsCount > (seriesDetailsDialog?.data ? seriesDetailsDialog.data.itemIds.length : 0),
		[seriesDetailsDialog, itemsCount]
	);

	useEffect(() => {
		if (onScreen && hasMoreItems) {
			setOffset(state => state + 1);
			setLoadData(true);
		}
	}, [hasMoreItems, onScreen]);

	useEffect(() => {
		if (loadData && seriesDetailsDialog?.seriesDetails && seriesDetailsDialog.addContent) {
			getCollection({
				id: seriesDetailsDialog.seriesDetails,
				offset,
				paginate: !!seriesDetailsDialog.data?.itemIds.length
			});
			setLoadData(false);
		}
	}, [getCollection, loadData, offset, seriesDetailsDialog]);

	const getItemStyle = useCallback(
		(_, draggableStyle) => ({
			userSelect: "none",
			...draggableStyle
		}),
		[]
	);

	const onClose = useCallback(() => {
		setSeriesDetailsDialog();
	}, [setSeriesDetailsDialog]);

	const handleAddContent = useCallback(() => {
		setSeriesDetailsDialog({
			...seriesDetailsDialog,
			addContent: true
		});
	}, [seriesDetailsDialog, setSeriesDetailsDialog]);

	const filesSummary = () => {
		if (seriesDetailsDialog?.data) {
			let files = 0,
				audio = 0,
				videos = 0;

			let summary = "";

			seriesDetailsDialog.data.itemIds.forEach(file => {
				if (file.type === "VIDEO") {
					videos++;
				} else if (file.type === "TRACK") {
					audio++;
				} else {
					files++;
				}
			});

			if (videos > 0) summary += `${formatCount(videos, "Video")}, `;
			if (audio > 0) summary += `${formatCount(audio, "Track")}, `;
			if (files > 0) summary += formatCount(files, "File");

			return summary;
		}
	};

	const onDragEnd = useCallback(
		result => {
			if (result.destination && result.destination.index !== result.source.index && seriesDetailsDialog?.data) {
				const items = reorder(
					seriesDetailsDialog?.data?.itemIds,
					result.source.index,
					result.destination.index
				) as ItemId[];

				setSeriesDetailsDialog({
					...seriesDetailsDialog,
					data: {
						...seriesDetailsDialog.data,
						itemIds: items
					}
				});

				setFilesReordered(true);
			}
		},
		[seriesDetailsDialog, setSeriesDetailsDialog]
	);

	const handleEditDetails = useCallback(() => {
		setSeriesDialog({ open: true, data: seriesDetailsDialog?.data });
	}, [seriesDetailsDialog, setSeriesDialog]);

	const handleSaveChanges = useCallback(() => {
		if (seriesDetailsDialog?.data) {
			if (seriesDetailsDialog.shouldUpdate) {
				const reordered = seriesDetailsDialog.data.itemIds.map(item => ({ _id: item._id, type: item.type }));

				const itemIds = [
					...reordered,
					...seriesDetailsDialog.data.originalItemIds.filter(oI => reordered.some(item => item._id === oI._id))
				];

				let filteredIds: { _id: string; type: string }[] = [];

				if (!!reordered.length) {
					filteredIds = itemIds.filter((value, index, self) => index === self.findIndex(t => t._id === value._id));
				}

				if (
					seriesDetailsDialog.data.meta.scheduleDate &&
					new Date(seriesDetailsDialog.data.meta.scheduleDate) < new Date()
				) {
					seriesDetailsDialog.data.meta.scheduleDate = "";
				}

				const data = {
					title: seriesDetailsDialog.data.title,
					categoryId: seriesDetailsDialog.data.category._id,
					description: seriesDetailsDialog.data.description,
					meta: seriesDetailsDialog.data.meta,
					itemIds: filteredIds,
					priceTags: seriesDetailsDialog.data?.priceTags,
					category: { name: seriesDetailsDialog.data.category.name, _id: seriesDetailsDialog.data.category._id }
				};

				updateCollection(seriesDetailsDialog.data._id, data, shouldUpdateIgnore).then(collection => {
					onUpdate &&
						onUpdate(
							{
								...collection,
								category: seriesDetailsDialog.data.category
							},
							false
						);
				});
			} else {
				const reordered = seriesDetailsDialog.data.itemIds.map(item => ({ _id: item._id, type: item.type }));
				const allData = [
					...reordered,
					...seriesDetailsDialog.data.originalItemIds.filter(oI => !reordered.find(item => item._id === oI._id))
				];

				reorderCollection(seriesDetailsDialog.data._id, allData);
			}
			onUpdateSeriesContent && onUpdateSeriesContent();
		}
	}, [seriesDetailsDialog, reorderCollection, updateCollection, onUpdate, onUpdateSeriesContent, shouldUpdateIgnore]);

	const { communityColors } = useCommunity();

	const {
		handleClose,
		handleLeavePageConfirmed,
		closeConfirmPopup,
		getData: getConfirmLeavePopupData
	} = useConfirmLeavePopup({
		onClose,
		open: true
	});

	const { showConfirmPopup } = getConfirmLeavePopupData();

	const handleRemove = (id: string) => {
		if (seriesDetailsDialog?.data) {
			setSeriesDetailsDialog({
				...seriesDetailsDialog,
				data: {
					...seriesDetailsDialog?.data,
					itemIds: seriesDetailsDialog?.data?.itemIds.filter(item => item._id !== id) || [],
					originalItemIds: seriesDetailsDialog?.data?.originalItemIds.filter(item => item._id !== id) || []
				},
				shouldUpdate: true
			});
		}
	};

	return (
		<>
			<ConfirmLeavePopup
				handleLeavePage={handleLeavePageConfirmed}
				open={showConfirmPopup}
				onClose={closeConfirmPopup}
				popup
			/>
			<ModalOverlay allowOverflow customMaxWidth="720px">
				<InPlaceModal
					open={true}
					onClose={() => handleClose(filesReordered || (seriesDetailsDialog && seriesDetailsDialog.shouldUpdate))}
					maxWidth="md"
					modalHeight={700}
				>
					<ModalHeader>
						<Text variant="h6">Series Details</Text>
						<ClosePopupButton
							onClick={() => handleClose(filesReordered || (seriesDetailsDialog && seriesDetailsDialog.shouldUpdate))}
							size="small"
						>
							<Icon name="close" group="filled" fill="#050505" />
						</ClosePopupButton>
					</ModalHeader>
					{seriesDetailsDialog?.data ? (
						<AlbumDetailsModalContent>
							<Box className="album-details-image">
								<img src={seriesDetailsDialog.data?.meta?.artwork?.url} alt={seriesDetailsDialog.data.title} />
								<Box className="album-details">
									<Box>
										<Text variant="h5">{seriesDetailsDialog.data.title}</Text>
										<Text variant="caption">{filesSummary()}</Text>

										<Text variant="body1">{seriesDetailsDialog.data.category?.name}</Text>
									</Box>

									<Button size="small" palette="light" buttonTheme="light" onClick={handleEditDetails}>
										Edit Details
									</Button>
								</Box>
							</Box>
							<Box className="description">
								<Text variant="subtitle1">Description</Text>
								<Text variant="caption">{seriesDetailsDialog.data.description}</Text>
							</Box>
							<Box className="tracks">
								<Box display="flex" justifyContent="space-between" alignItems="center">
									<Text variant="subtitle1">Series content</Text>
									<Button size="small" palette="light" buttonTheme="light" onClick={handleAddContent}>
										<Box display="flex" alignItems="flex-end">
											<Icon name="plus" width={16} height={16} fill={communityColors.primary} /> Add Content
										</Box>
									</Button>
								</Box>
								{playPreview && playPreview.type === "TRACK" && <PreviewAudio loop autoPlay src={playPreview.url} />}
								<DragDropContext onDragEnd={onDragEnd}>
									<Droppable droppableId="playlist-droppable">
										{provided => (
											<Box {...provided.droppableProps} ref={provided.innerRef} className="tracks-list">
												{seriesDetailsDialog.data &&
													seriesDetailsDialog.data.itemIds.map((file, i) => (
														<Draggable key={file._id} draggableId={file._id} index={i}>
															{(provided, snapshot) => (
																<Box
																	ref={provided.innerRef}
																	{...provided.draggableProps}
																	{...provided.dragHandleProps}
																	style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
																>
																	{file.type === "VIDEO" && (
																		<VideoRow
																			lastItemRef={
																				i + 1 === seriesDetailsDialog.data?.itemIds.length ? lastItemRef : null
																			}
																			order={i + 1}
																			data={file.video}
																			handleRemove={handleRemove}
																		/>
																	)}
																	{file.type === "FILE" && file.file && (
																		<FileRow
																			lastItemRef={
																				i + 1 === seriesDetailsDialog.data?.itemIds.length ? lastItemRef : null
																			}
																			order={i + 1}
																			data={file.file}
																			handleRemove={handleRemove}
																		/>
																	)}
																	{file.type === "TRACK" && file.music && (
																		<TrackRow
																			lastItemRef={
																				i + 1 === seriesDetailsDialog.data?.itemIds.length ? lastItemRef : null
																			}
																			order={i + 1}
																			data={file.music}
																			handleRemove={handleRemove}
																		/>
																	)}
																</Box>
															)}
														</Draggable>
													))}
												{provided.placeholder}
												{isLoading && <LoadingTracks page={2} />}
											</Box>
										)}
									</Droppable>
								</DragDropContext>
							</Box>
							<ModalFooter noPadding>
								<Button
									disabled={!filesReordered && !seriesDetailsDialog.shouldUpdate}
									onClick={handleSaveChanges}
									id="saveChanges"
								>
									Save Changes
								</Button>
							</ModalFooter>
						</AlbumDetailsModalContent>
					) : (
						<LoadingAlbums>
							<Loader show variant="indeterminate" size="16px" />
							<Text variant="h6">Loading Collection Details</Text>
						</LoadingAlbums>
					)}
				</InPlaceModal>
			</ModalOverlay>
			{playPreview && playPreview.type === "VIDEO" && <PreviewVideo url={playPreview.url} />}
		</>
	);
};

export default SeriesDetailsDialog;
