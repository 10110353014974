import React, { useMemo } from "react";

import { ReactComponent as MicroPhoneMutedIcon } from "assets/icons/liveconvo/mic_muted.svg";
import { ReactComponent as MicroPhoneFilledIcon } from "assets/icons/liveconvo/microphone-filled.svg";
import { ReactComponent as VideoOffIcon } from "assets/icons/liveconvo/video-off.svg";
import { ReactComponent as VideoOnIcon } from "assets/icons/liveconvo/video-on.svg";

import { useFirebaseLiveConversation, useLiveConversation } from "modules/LiveConversation/Data/hooks";
import { Tooltip } from "shared/ui-kit";
import { ReactComponent as ArrowIcon } from "shared/ui-kit/assets/icons/dropdown-arrow-down.svg";
import { ReactComponent as PhoneIcon } from "shared/ui-kit/assets/icons/filled/phone.svg";

import { StyledLeaveBtn, StyledLeaveDropdownBtn, Wrapper } from "./style";

import { OperationBox } from "../ConversationOperation/style";

interface ControlsProps {
	onLeave: () => void;
	turnOnVideoCamera: () => void;
	turnOffVideoCamera: () => void;
	muteMicrophone: () => void;
	unmuteMicrophone: () => void;
	personaId: number;
	isSpeaker: boolean;
	speakersCount: number;
	warnConvo: () => void;
}

const Controls: React.FC<ControlsProps> = ({
	onLeave,
	turnOnVideoCamera,
	turnOffVideoCamera,
	muteMicrophone,
	unmuteMicrophone,
	personaId,
	isSpeaker,
	speakersCount,
	warnConvo
}) => {
	const { getData: getLiveConversationData } = useLiveConversation();
	const { controls } = getLiveConversationData();

	const { updateMyControls } = useFirebaseLiveConversation();

	const isVideoOn = controls[personaId]?.videoOn;
	const isMicOn = !controls[personaId]?.muted;

	const toggleVideo = () => {
		updateMyControls({ ...controls[personaId], videoOn: !isVideoOn });
		isVideoOn ? turnOffVideoCamera() : turnOnVideoCamera();
	};

	const toggleMic = () => {
		updateMyControls({ ...controls[personaId], muted: isMicOn });
		isMicOn ? muteMicrophone() : unmuteMicrophone();
	};

	const getLeaveOptions = useMemo(
		() => [
			{
				label: "Leave",
				onClick: () => onLeave()
			},
			{
				label: "End conversation",
				onClick: () => warnConvo()
			}
		],
		[onLeave, warnConvo]
	);

	return (
		<Wrapper>
			{isSpeaker && (
				<>
					<OperationBox.ItemWrapper>
						<Tooltip text={isVideoOn ? "Turn off camera" : "Turn on camera"}>
							<OperationBox.Item onClick={toggleVideo}>
								{isVideoOn ? (
									<VideoOnIcon width={28} height={28} fill="#ffffff" />
								) : (
									<VideoOffIcon width={28} height={28} fill="#ffffff" />
								)}
							</OperationBox.Item>
						</Tooltip>
					</OperationBox.ItemWrapper>
					<OperationBox.ItemWrapper>
						<Tooltip text={isMicOn ? "Mute" : "Unmute"}>
							<OperationBox.Item onClick={toggleMic}>
								{isMicOn ? (
									<MicroPhoneFilledIcon width={25} height={25} fill="#ffffff" />
								) : (
									<MicroPhoneMutedIcon width={25} height={25} fill="#ffffff" />
								)}
							</OperationBox.Item>
						</Tooltip>
					</OperationBox.ItemWrapper>
				</>
			)}
			<OperationBox.ItemWrapper>
				{!isSpeaker || speakersCount === 1 ? (
					<StyledLeaveBtn
						removeSideMargin
						size="small"
						onClick={onLeave}
						palette="danger"
						buttonTheme="light"
						leftIcon={
							<StyledLeaveBtn.IconWrapper>
								<PhoneIcon width={20} height={20} fill="white" />
							</StyledLeaveBtn.IconWrapper>
						}
					>
						Leave
					</StyledLeaveBtn>
				) : (
					<StyledLeaveDropdownBtn
						size="small"
						palette="danger"
						buttonTheme="light"
						options={getLeaveOptions}
						leftIcon={
							<StyledLeaveBtn.IconWrapper>
								<PhoneIcon width={20} height={20} fill="white" />
							</StyledLeaveBtn.IconWrapper>
						}
						rightIcon={
							<StyledLeaveBtn.IconWrapper className="arrow-wrapper">
								<ArrowIcon width={16} height={16} fill="white" />
							</StyledLeaveBtn.IconWrapper>
						}
					>
						Leave
					</StyledLeaveDropdownBtn>
				)}
			</OperationBox.ItemWrapper>
		</Wrapper>
	);
};

export default Controls;
