export const getVideoDimensions = (url: string): Promise<{ height: number; width: number }> => {
	return new Promise(resolve => {
		const video = document.createElement("video");

		video.addEventListener(
			"loadedmetadata",
			function () {
				const height = this.videoHeight;
				const width = this.videoWidth;
				resolve({ height, width });
			},
			false
		);

		video.src = url;
	});
};
