import React from "react";

import TrackSkeleton from "modules/Manage/View/Components/AddTracksToPlaylist/TrackSkeleton";

const LoadingItems = () => {
	return (
		<>
			<TrackSkeleton />
			<TrackSkeleton />
			<TrackSkeleton />
			<TrackSkeleton />
		</>
	);
};

export default LoadingItems;
