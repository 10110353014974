import React, { FC, useCallback, useMemo, useState } from "react";

import { Box, ClickAwayListener, IconButton } from "@material-ui/core";

import { ReactComponent as IconPause } from "assets/icons/icon-pause.svg";
import { ReactComponent as IconPlay } from "assets/icons/icon-play.svg";
import { ReactComponent as IconSlash } from "assets/icons/icon-slash.svg";
import { ReactComponent as IconMoreHorizontal } from "assets/icons/iconMoreHorizontal.svg";
import { ReactComponent as IconSoundOff } from "assets/icons/iconSoundOff.svg";
import { ReactComponent as IconSoundOn } from "assets/icons/iconSoundOn.svg";
import { ReactComponent as IconAlert } from "assets/icons/stories/icon-alert.svg";
import { ReactComponent as IconDeleteConnection } from "assets/icons/stories/icon-delete-connection.svg";
import { ReactComponent as IconEyeStrikethrough } from "assets/icons/stories/icon-eye-strikethrough.svg";
import { ReactComponent as IconPictureLinear } from "assets/icons/stories/icon-picture-linear.svg";
import { useStory } from "shared/hooks";
import { Button, Text } from "shared/ui-kit";

import ProgressIndicator from "./ProgressIndicator";
import { MoreActionsMenu, StoryHeadWrapper, UserDataActions } from "./style";

interface StoryHeadProps {
	isActive: boolean;
	isVideo: boolean;
	userData: {
		id: string;
		name: string;
		profilePicture?: string;
		connectionId?: string;
		personaId: number;
	} | null;
	storiesCount?: number;
	handleMoveNext: () => void;
	isAnAD?: boolean;
}

const StoryHead: FC<StoryHeadProps> = ({ isActive, isVideo, userData, isAnAD, storiesCount = 1, handleMoveNext }) => {
	const { setMuteStory, setPauseStory, setRemovePersonConnection, setReportDialog, getData: getStoryData } = useStory();
	const { muteStory, pauseStory, currentStorySlide, removePersonConnection } = getStoryData();

	const [showMenu, setShowMenu] = useState(false);
	const [showReportMenu, setShowReportMenu] = useState(false);

	// const canBlock = useMemo(() => isOpen === STORIES_OPEN_TYPE.STORIES, [isOpen]);

	const handleMuteStory = useMemo(
		() => () => {
			setMuteStory(!muteStory);
		},
		[muteStory, setMuteStory]
	);

	const handlePauseStory = useMemo(
		() => () => {
			setPauseStory(isActive ? !pauseStory : false);
		},
		[pauseStory, setPauseStory, isActive]
	);

	const handleCloseMenu = () => {
		setShowMenu(false);
		setShowReportMenu(false);
	};

	const handleReportStory = useCallback(
		async (reportType: string, reason: string) => {
			if (!userData) return;

			setReportDialog({
				reportType,
				reporteeId: userData.personaId,
				reason
			});
		},
		[setReportDialog, userData]
	);

	const handleConfirmRemoveConnection = useCallback(() => {
		if (userData && userData.connectionId) {
			setRemovePersonConnection({
				name: userData.name,
				connectionId: userData.connectionId
			});
		}
	}, [userData, setRemovePersonConnection]);

	const isPaused = useMemo(
		() => pauseStory || showMenu || !!removePersonConnection,
		[pauseStory, showMenu, removePersonConnection]
	);

	return (
		<StoryHeadWrapper>
			{isActive && (
				<ProgressIndicator
					storiesCount={storiesCount}
					isActive={isActive}
					pauseStory={isPaused}
					currentStoryNum={currentStorySlide}
					handleMoveNext={handleMoveNext}
				/>
			)}
			<UserDataActions>
				{userData ? (
					<Box display="flex" alignItems="center">
						<img src={userData.profilePicture} alt={userData.name} />
						<Text variant="subtitle2">{userData.name}</Text>
					</Box>
				) : (
					<Box />
				)}
				{isActive && (
					<Box display="flex" alignItems="center">
						<IconButton size="small" onClick={handlePauseStory}>
							{isPaused ? <IconPlay /> : <IconPause />}
						</IconButton>
						{isVideo && (
							<IconButton size="small" onClick={handleMuteStory}>
								{muteStory ? <IconSoundOff /> : <IconSoundOn />}
							</IconButton>
						)}
						{!isAnAD && userData && (
							<Box position="relative">
								<ClickAwayListener onClickAway={handleCloseMenu}>
									<Box marginLeft="12px">
										<IconButton
											size="small"
											onClick={() => {
												setShowMenu(ctx => !ctx);
												setShowReportMenu(false);
											}}
										>
											<IconMoreHorizontal />
										</IconButton>
										<MoreActionsMenu className={(showMenu || showReportMenu) && isActive ? "show" : ""}>
											{showMenu && (
												<>
													{userData.connectionId && (
														<Button
															palette="control"
															size="small"
															buttonTheme="light"
															onClick={() => {
																handleConfirmRemoveConnection();
															}}
														>
															<IconDeleteConnection />
															<Text variant="body2">Remove from connections</Text>
														</Button>
													)}
													<Button
														palette="control"
														size="small"
														buttonTheme="light"
														onClick={() => {
															setShowMenu(false);
															setShowReportMenu(true);
														}}
													>
														<IconAlert />
														<Text variant="body2">Report</Text>
													</Button>
													{/* TODO: We need to implement block functioanlity */}
													{/* {canBlock && (
													<Button palette="control" size="small" buttonTheme="light">
														<IconSlash />
														<Text variant="body2">Block</Text>
													</Button>
												)} */}
												</>
											)}
											{showReportMenu && (
												<>
													<Text variant="h6">Report</Text>
													<Button
														palette="control"
														size="small"
														buttonTheme="light"
														onClick={() => handleReportStory("personaStolenPhoto", "Stolen photo/video")}
													>
														<IconPictureLinear />
														<Text variant="body2">Stolen photo/video</Text>
													</Button>
													<Button
														palette="control"
														size="small"
														buttonTheme="light"
														onClick={() => handleReportStory("personaInappropriateContent", "Inappropriate content")}
													>
														<IconEyeStrikethrough />
														<Text variant="body2">Inappropriate content</Text>
													</Button>
													<Button
														palette="control"
														size="small"
														buttonTheme="light"
														onClick={() => handleReportStory("personaSendingSpam", "Sending spam")}
													>
														<IconSlash />
														<Text variant="body2">Sending spam</Text>
													</Button>
												</>
											)}
										</MoreActionsMenu>
									</Box>
								</ClickAwayListener>
							</Box>
						)}
					</Box>
				)}
			</UserDataActions>
		</StoryHeadWrapper>
	);
};

export default StoryHead;
