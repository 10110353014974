import { ButtonBase } from "@material-ui/core";
import styled from "styled-components";

import { Box } from "shared/ui-kit";

export const Wrapper = styled(Box)`
	border-radius: 12px;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08), inset 0 0 0 0.5px #c5cee0;
	background-color: #fff;
	width: 100%;
	flex-shrink: 0;
	position: relative;
	margin: ${({ margin }) => margin || 0};
	padding: ${({ padding }) => padding || "16px"};
	&.p-16 {
		padding: 16px;
	}
	&.mv-16 {
		margin-top: 16px;
		margin-bottom: 16px;
	}
	h4 {
		margin-left: 0;
	}
	.see-all {
		width: 100%;
		border-radius: 8px;
		margin: 0;
	}
`;

Wrapper.EditButton = styled(ButtonBase)`
	position: absolute;
	top: 16px;
	right: 16px;
	width: 32px;
	height: 32px;
	border-radius: 8px;
	box-shadow: 0 0 0 0.5px #c5cee0;
	display: flex;
	align-items: center;
	justify-content: center;
	svg {
		width: 16px;
		height: 16px;
	}
`;
