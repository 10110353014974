import React, { FC, useCallback, useMemo, useRef, useState } from "react";

import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";

import { ReactComponent as ReplyIcon } from "assets/icons/iconReply.svg";
import { MenuDots } from "shared/Components";
import { TransformOriginOpts } from "shared/Components/MenuDots";

import { Like } from "shared/Organisms/Post/components";
import { useLongPress, useUser } from "shared/hooks";
import { Icon, Tooltip } from "shared/ui-kit";

import { determineSkinTone } from "utils/determineSkinTone";

import { RenderActionType } from "..";

import { ActionContainer, ActionIconWrapper, ForwardIcon, MenuWrapper } from "../styles";

const Actions: FC<{
	type: RenderActionType;
	isCurrentUser: boolean;
	onForward: (data) => void;
	onDelete: (item) => void;
	onEdit: (item) => void;
	currentMessage: any;
	onReply: (currentItem, type) => void;
	onLike?: (reaction: string, type: RenderActionType) => void;
	replyItem: {
		id: string;
		text: string;
	};
}> = ({ type, onDelete, onForward, onEdit, onReply, currentMessage, isCurrentUser, replyItem, onLike }) => {
	const { getData: getUserData } = useUser();
	const { user, isMemberView } = getUserData();

	const anchorEl = useRef<HTMLElement | null>(null);

	const ST = useMemo(() => user?.profiles[0].skinTone, [user]);
	const reactionColor = useMemo(() => determineSkinTone(ST || ""), [ST]);

	const [showLikeDropdown, setShowLikeDropdown] = useState(false);

	const reactLongPress = useLongPress({
		callback: () => {
			setShowLikeDropdown(true);
		},
		ms: 250
	});

	const handleLike = useCallback(
		(reaction: string) => {
			setShowLikeDropdown(false);
			onLike && onLike(reaction, type);
		},
		[onLike, type]
	);

	const options = useMemo(() => {
		const BASE_OPTIONS = [
			{
				icon: <ForwardIcon width={20} height={20} viewBox={"0 0 24 24"} />,
				name: "Forward",
				value: 2,
				onClick: () => onForward(currentMessage)
			},
			{
				icon: <Icon group={"filled"} fill={"#8f9bb3"} name={"trash"} width={20} height={20} />,
				name: "Delete",
				value: 1,
				onClick: () => onDelete(currentMessage)
			}
		];

		if (type === RenderActionType.TEXT)
			BASE_OPTIONS.unshift({
				icon: <Icon group={"filled"} fill={"#8f9bb3"} name={"pencil"} width={20} height={20} />,
				name: "Edit",
				value: 3,
				onClick: () => {
					onEdit(currentMessage);
				}
			});

		return BASE_OPTIONS;
	}, [currentMessage, onDelete, onEdit, onForward, type]);

	const onShowLikeOptions = useCallback(() => {
		setShowLikeDropdown(true);
	}, []);

	return (
		<ActionContainer
			className={`action-box ${showLikeDropdown ? "show-like-list" : ""} ${isCurrentUser ? "current-user" : ""}`}
			onMouseLeave={() => setShowLikeDropdown(false)}
		>
			{isCurrentUser && (
				<ActionIconWrapper>
					<MenuWrapper>
						<MenuDots
							vertical
							removeBg
							removeshadow
							memberView={isMemberView}
							alignLeft
							circular
							options={options}
							transformOrigin={TransformOriginOpts.BOTTOM_RIGHT}
							customImage={
								<Icon
									fill="#c5cee0"
									group="filled"
									height={24}
									name="ellipsis"
									width={24}
									aria-controls="long-menu"
									aria-haspopup="true"
									aria-label="more"
								/>
							}
						/>
					</MenuWrapper>
				</ActionIconWrapper>
			)}
			<ActionIconWrapper className={"color-icon"}>
				<Tooltip text={"Reply"}>
					<ReplyIcon
						fill="#8f9bb3"
						onClick={() => onReply(replyItem, type)}
						width={24}
						height={24}
						viewBox={"2 1 21 21"}
					/>
				</Tooltip>
			</ActionIconWrapper>
			<ActionIconWrapper
				className={"color-icon like-btn"}
				ref={anchorEl}
				onMouseEnter={onShowLikeOptions}
				{...reactLongPress}
			>
				<Tooltip text={"Like"}>
					<FavoriteBorderIcon className="option" htmlColor="#8f9bb3" viewBox={"0 0 24 22"} />
				</Tooltip>
			</ActionIconWrapper>
			<Like
				reactionColor={reactionColor}
				show={showLikeDropdown && Boolean(anchorEl)}
				onSelect={handleLike}
				chatView
				showChooseSkinTone
				anchorEl={anchorEl.current}
				placement={isCurrentUser ? "top-end" : undefined}
			/>
		</ActionContainer>
	);
};

export default Actions;
