import styled from "styled-components";

import { Button } from "shared/ui-kit";

const SettingsSectionContainer = styled.div`
	margin-top: 40px;
	padding: 0 24px 12px 24px;
	flex: 1;
	display: flex;
	flex-direction: column;
`;

const SettingsOption = styled.div<{ cursor: "pointer" | "default" }>`
	display: flex;
	padding: 24px 16px 24px 24px;
	height: 80px;
	border-radius: 12px;
	border: 1px solid #e4e9f2;
	margin-bottom: 16px;
	align-items: center;
	cursor: ${({ cursor }) => cursor};

	&:last-child {
		margin-bottom: 0;
	}
`;

const SettingsOptionIcon = styled.div`
	margin-right: 24px;
	display: flex;
	align-items: center;
	justify-content: center;

	svg > g > path:first-of-type {
		fill: ${({ fill }) => fill};
	}
`;

const SettingsOptionMore = styled.div`
	margin-left: auto;
`;

const SettingsOptionHeader = styled.div``;

const SettingsOptionHeaderTitle = styled.div`
	font-size: 15px;
`;

const SettingsOptionHeaderSubtitle = styled.div<{ color?: "default" | "danger" }>`
	font-size: 13px;
	color: ${({ color }) => (color === "danger" ? "#ff463d" : "#8f9bb3")};
	margin-top: 2px;
`;

SettingsOption.Icon = SettingsOptionIcon;

SettingsOption.More = SettingsOptionMore;

SettingsOption.Header = SettingsOptionHeader;

SettingsOption.Header.Title = SettingsOptionHeaderTitle;

SettingsOption.Header.Subtitle = SettingsOptionHeaderSubtitle;

const SettingsActionButton = styled(Button)<{ disabled: boolean }>`
	padding: 12px 16px;
	text-transform: none;
	border-radius: 8px;
	background: ${({ disabled }) => (disabled ? "rgba(143, 155, 179, 0.16)" : "#6173fe")};
	cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};

	&:hover {
		background: ${({ disabled }) => (disabled ? "rgba(143, 155, 179, 0.16)" : "#6173fe")};
	}

	span {
		font-size: 14px;
		font-weight: bold;
		color: ${({ disabled }) => (disabled ? "#8f9bb3" : "#ffffff")};
	}
`;

const SettingsCancelButton = styled(Button)`
	padding: 12px 16px;
	text-transform: none;
	border-radius: 8px;
	background: rgba(143, 155, 179, 0.16);

	&:hover {
		background: rgba(143, 155, 179, 0.16);
	}

	span {
		font-size: 14px;
		font-weight: bold;
		color: #8f9bb3;
	}
`;

export { SettingsSectionContainer, SettingsOption, SettingsActionButton, SettingsCancelButton };
