import React, { useCallback } from "react";

import { useFirebase, useMessaging } from "modules/Messaging/Data";
import { ChatMessage } from "modules/Messaging/Data/types";
import { RelativeWrapper } from "shared/styles";

import Actions from "./Actions";

import Reactions from "./Reactions";

import { RenderActionType } from "..";

import { AudioPreview } from "../styles";

const AudioBubble = props => {
	const { onSend } = useFirebase();
	const { getData: getMessagingData } = useMessaging();
	const { currentChat } = getMessagingData();

	const handleDelete = useCallback(() => {
		if (!currentChat) return;

		const msg = {
			...props.currentMessage,
			edited: props.currentMessage,
			audios: (props.currentMessage.audios || []).filter(au => au.id !== props.audio.id)
		} as ChatMessage;

		onSend({ messages: [msg], chat: currentChat });
	}, [currentChat, onSend, props.audio.id, props.currentMessage]);

	return (
		<RelativeWrapper data-message={props.audio.id}>
			<AudioPreview>
				<audio controls={true}>
					<source src={props.audio.url} type={props.audio.type} />
				</audio>
			</AudioPreview>
			{props.audio.recentLikes && <Reactions list={props.audio.recentLikes} isCurrentUser={props.isCurrentUser} />}

			<Actions
				replyItem={{
					id: props.audio.id,
					text: "audio"
				}}
				isCurrentUser={props.isCurrentUser}
				onDelete={handleDelete}
				onEdit={props.onEdit}
				onForward={props.onForward}
				onReply={props.onReply}
				onLike={props.onLike}
				currentMessage={props.currentMessage}
				type={RenderActionType.AUDIO}
			/>
		</RelativeWrapper>
	);
};

export default AudioBubble;
