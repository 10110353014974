import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { SeriesCollection } from "modules/Manage/Data/types";
import { useCategories } from "modules/Marketplace/Data/hooks";
import { CategoryProps } from "modules/Marketplace/Data/types";
import { SetupPrice } from "shared/Components";
import { useSeries, useUser, useVideo } from "shared/hooks";
import { useS3Uploader } from "shared/services/s3Uploader";
import { CategorySort, UpdateVideoFormModel, VideoFormModel, VideoModel } from "shared/types";
import { dataUrlToFile } from "utils/serviceUtils/helpers";

import CreateVideoManageView from "./ManageView";
import CreateVideoMemberView from "./MemberView";

interface Props {
	onClose: (event: { created?: boolean }, reason?: string) => void;
	editableModel?: VideoModel;
	open: boolean;
	seriesView?: boolean;
	onSelect?: (content: any) => void;
	groupId?: string;
	eventId?: string;
}

const CreateVideo: React.FC<Props> = ({ open, onClose, editableModel, seriesView, onSelect, groupId, eventId }) => {
	const { uploadFile, abortUploading } = useS3Uploader();

	const { createVideo, updateVideo, getData: getVideoData } = useVideo();
	const { videoFileUrl, videoUploadUrl, videoDuration } = getVideoData();

	const { getCategories } = useCategories();

	const { getData: getUserData } = useUser();
	const { isMemberView } = getUserData();

	const { getCollections, setSeriesDetailsDialog, getData: getSeriesData } = useSeries();
	const { seriesDetailsDialog } = getSeriesData();

	const [preview, setPreview] = useState({
		start: 0,
		end: 30000
	});
	const [suggestedCategories, setSuggestedCategories] = useState<CategoryProps[]>([]);
	const [suggestedCollections, setSuggestedCollections] = useState<SeriesCollection[]>([]);

	const editModel = useMemo(() => {
		const modelInfo = editableModel ? { ...editableModel } : undefined;
		if (modelInfo?.meta?.scheduleDate && new Date(modelInfo.meta.scheduleDate) < new Date()) {
			modelInfo.meta.scheduleDate = "";
		}
		return modelInfo;
	}, [editableModel]);

	type SetupPriceMethodsHandlers = React.ElementRef<typeof SetupPrice>;
	const setupPriceFormRef = useRef<SetupPriceMethodsHandlers>(null);

	const loadSuggestedCollections = useCallback(
		async (name: string) => {
			const collections = await getCollections({
				limit: 50,
				offset: 1,
				keyword: name,
				sortBy: "timestamp"
			});

			setSuggestedCollections(collections || []);
		},
		[getCollections]
	);

	const loadSuggestedCategories = useCallback(
		async (name: string) => {
			const { categories } = await getCategories({
				limit: 20,
				offset: 1,
				keyword: name,
				sortBy: CategorySort.name,
				order: 1
			});

			setSuggestedCategories(categories);
		},
		[getCategories]
	);

	useEffect(() => {
		if (open) {
			loadSuggestedCategories("");
			loadSuggestedCollections("");
			setPreview({
				start: 0,
				end: 30000
			});
		}
	}, [loadSuggestedCategories, loadSuggestedCollections, open]);

	const handleCreateUpdateVideo = useCallback(
		async (data: any, isEdit: boolean) => {
			const body = {
				...data,
				videoFileUrl,
				duration: videoDuration || editModel?.duration,
				preview,
				groupId,
				eventId
			};

			if (isEdit) {
				await updateVideo({ ...(body as UpdateVideoFormModel), _id: editModel!._id });
			} else {
				const video = await createVideo(body as VideoFormModel);

				if (seriesView && seriesDetailsDialog && seriesDetailsDialog.data) {
					setSeriesDetailsDialog({
						addContent: true,
						shouldUpdate: true,
						data: {
							...seriesDetailsDialog.data,
							itemIds: [{ _id: video._id, type: "VIDEO", video, new: true }, ...seriesDetailsDialog.data.itemIds]
						}
					});
				}

				return video;
			}
		},
		[
			videoFileUrl,
			videoDuration,
			editModel,
			preview,
			updateVideo,
			createVideo,
			seriesView,
			seriesDetailsDialog,
			setSeriesDetailsDialog,
			groupId,
			eventId
		]
	);

	const onSubmit = useCallback(
		async data => {
			const priceTags = setupPriceFormRef.current?.getPriceTags();

			let formData = { ...data, category: isMemberView ? { name: data.category.label } : { name: data.category.name } };
			if (priceTags) {
				formData = { ...formData, priceTags };
			}
			const video = await handleCreateUpdateVideo(formData, !!editModel);

			onSelect && onSelect(video);
			onClose && onClose({ created: true });
		},
		[editModel, handleCreateUpdateVideo, isMemberView, onClose, onSelect]
	);

	const onUploaderChange = useCallback(
		async (files: any[], onChange: (...event: any[]) => void) => {
			const img = files && files.length ? files[0] : null;

			const correctFile = typeof img === "string" ? await dataUrlToFile(img, "test") : img;
			if (img) {
				const data = await uploadFile({
					file: correctFile as File,
					communityName: videoUploadUrl
				});
				onChange(data?.publicUrl);
			} else {
				onChange(null);
			}
		},
		[uploadFile, videoUploadUrl]
	);

	if (isMemberView) {
		return (
			<CreateVideoMemberView
				open={open}
				onClose={() => {
					abortUploading();
					onClose({ created: false }, "");
				}}
				loadSuggestedCategories={loadSuggestedCategories}
				suggestedCategories={suggestedCategories}
				onUploaderChange={onUploaderChange}
				createVideo={onSubmit}
				setupPriceFormRef={setupPriceFormRef}
				editableModel={editModel}
			/>
		);
	}

	return (
		<CreateVideoManageView
			open={open}
			onClose={() => {
				abortUploading();
				onClose({ created: false }, "");
			}}
			setPreview={setPreview}
			preview={preview}
			loadSuggestedCategories={loadSuggestedCategories}
			suggestedCategories={suggestedCategories}
			loadSuggestedCollections={loadSuggestedCollections}
			suggestedCollections={suggestedCollections}
			onUploaderChange={onUploaderChange}
			setupPriceFormRef={setupPriceFormRef}
			createVideo={onSubmit}
			editableModel={editModel}
		/>
	);
};

export default CreateVideo;
