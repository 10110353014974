import React, { memo, useEffect } from "react";

import { useCommunity, useFiles } from "shared/hooks";

import { ItemWrapper, ListWrapper } from "./style";

import { BoxItem, FileCard, SeeAllBtn, Slider } from "../index";

const defaultFilters = {
	offset: 1,
	limit: 10,
	keyword: ""
};

interface FilesProps {
	noContainer?: boolean;
	routes: any;
}

const Files: React.FC<FilesProps> = memo(({ noContainer, routes }) => {
	const { getData: getCommunityData } = useCommunity();
	const { workspace } = getCommunityData();

	const { getFiles, getData: getFilesData } = useFiles();
	const { files } = getFilesData();

	useEffect(() => {
		getFiles({ ...defaultFilters });
	}, [getFiles, workspace]);

	if (!files.length) return null;

	if (noContainer) {
		return (
			<Slider hideFrom={1} count={(files || []).length} itemSize={248}>
				{(files || []).map((file, index) => (
					<ItemWrapper key={index}>
						<FileCard file={file} />
					</ItemWrapper>
				))}
			</Slider>
		);
	}

	return (
		<BoxItem title={"Popular Files"}>
			<ListWrapper>
				<Slider hideFrom={1} count={(files || []).length} itemSize={248}>
					{(files || []).map((file, index) => (
						<ItemWrapper br={12} key={index}>
							<FileCard highlightOnHover noPB file={file} />
						</ItemWrapper>
					))}
				</Slider>
			</ListWrapper>
			<SeeAllBtn link={routes?.member.files.getPath()} />
		</BoxItem>
	);
});

export default Files;
