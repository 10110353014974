import React, { useCallback, useEffect, useState } from "react";

export default function useLongPress({
	callback = () => {},
	onLeave,
	ms = 300,
	refresh = 0
}: {
	callback?: () => void;
	onLeave?: () => void;
	ms: number;
	refresh?: number;
}) {
	const [startLongPress, setStartLongPress] = useState(false);

	useEffect(() => {
		let timerId;
		if (startLongPress) {
			timerId = setTimeout(callback, ms);
		} else {
			clearTimeout(timerId);
		}

		return () => {
			clearTimeout(timerId);
		};
	}, [callback, ms, startLongPress]);

	const start = useCallback(() => {
		setStartLongPress(true);
	}, []);

	const stop = useCallback(
		(e?: React.MouseEvent<HTMLElement>) => {
			if (e?.relatedTarget) {
				setStartLongPress(false);
				onLeave && onLeave();
			}
		},
		[onLeave]
	);

	useEffect(() => {
		setStartLongPress(false);
	}, [refresh]);

	return {
		onMouseDown: start,
		onMouseUp: stop,
		onMouseLeave: stop,
		onTouchStart: start,
		onTouchEnd: stop
	};
}
