import { useCallback, useMemo } from "react";

import { originalFeatures } from "shared/constants";

import IManageFeature from "../../types/IManageFeature";
import { useFeatureStore } from "../contexts";
import useFeatureApiService from "../services/useFeatureApiService";

const useFeature = () => {
	const featureService = useFeatureApiService();

	const store = useFeatureStore();
	const { setState } = useFeatureStore();

	const stateFeatures = useMemo(() => {
		return store?.features;
	}, [store]);

	const stateAllFeatures = useMemo(() => {
		return store?.allFeatures;
	}, [store]);

	const methods = useMemo(
		() => ({
			getDetails: async () => {
				try {
					setState({
						loadingDetails: true
					});
					const communityModel = await featureService.getDetails();

					const { features, meta, automatedConnectionPersonas, promotionText, conversationPersonas } = communityModel;

					const _features: IManageFeature[] = originalFeatures.map(feature => {
						const communityFeature = features.find(x => x.name === feature.value);
						return {
							...feature,
							enabled: communityFeature ? communityFeature.isEnabled : false
						};
					});

					const podCastFeature = features.find(x => x.name === "isPodcastEnabled");
					const appDownFeature = features.find(x => x.name === "isAppDown");
					const appDownFeatureObj: IManageFeature = {
						value: appDownFeature?.name as string,
						enabled: appDownFeature?.isEnabled as boolean,
						title: "",
						text: "",
						shortDesc: ""
					};
					const featureObj: IManageFeature = {
						value: podCastFeature?.name as string,
						enabled: podCastFeature?.isEnabled as boolean,
						title: "",
						text: "",
						shortDesc: ""
					};
					_features.push(featureObj);
					_features.push(appDownFeatureObj);
					setState({
						autoConnectData: automatedConnectionPersonas,
						autoMessageData: { conversationPersonas, promotionText },
						features: _features,
						allFeatures: features,
						loadingDetails: false
					});

					return {
						meta
					};
				} catch (err) {
					console.error(err);
					return {};
				} finally {
					setState({
						loadingDetails: false
					});
				}
			},
			manageFeature: async (featureValueName: string, enable: boolean) => {
				await featureService.manageFeatures({
					features: [
						{
							name: featureValueName,
							isEnabled: enable
						}
					]
				});

				setState(ctx => ({
					...ctx,
					features: ctx.features.map(x => (x.value === featureValueName ? { ...x, enabled: enable } : x))
				}));
			},
			async updateMeta(payload) {
				return await featureService.manageFeatures(payload);
			},
			setSelectedFeatures: (features: string[]) => {
				setState({ selectedFeatures: features });
			},
			manageMultipleFeatures: async (features: IManageFeature[]) => {
				await featureService.manageFeatures({
					features: features.map(x => ({
						name: x.value,
						isEnabled: x.enabled
					}))
				});

				// setState(ctx => ({ ...ctx, features }));
			}
		}),
		[setState, featureService]
	);

	const getData = useCallback(() => {
		return store;
	}, [store]);

	const isAnyFeatureEnabled = useCallback(
		(featureNames: string[]): boolean => {
			let enable = false;
			for (const featureName of featureNames) {
				if (!enable) {
					enable =
						featureName && stateFeatures ? stateFeatures.find(x => x.value === featureName)?.enabled || false : true;
				}
			}
			return enable;
		},
		[stateFeatures]
	);

	const isFeatureEnabled = useCallback(
		(featureName?: string): boolean => {
			return featureName && stateFeatures ? stateFeatures.find(x => x.value === featureName)?.enabled || false : true;
		},
		[stateFeatures]
	);

	const isCommunityFeatureEnabled = useCallback(
		(featureName: string) => {
			return featureName && stateAllFeatures
				? stateAllFeatures.find(x => x.name === featureName)?.isEnabled || false
				: true;
		},
		[stateAllFeatures]
	);

	return { ...methods, getData, isAnyFeatureEnabled, isFeatureEnabled, isCommunityFeatureEnabled };
};

export default useFeature;
