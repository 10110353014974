import React from "react";

import styled, { css } from "styled-components";

import { ReactComponent as BatteryIcon } from "assets/icons/battery.svg";
import { ReactComponent as ConnectionIcon } from "assets/icons/cellular-connection.svg";
import { ReactComponent as TimeIcon } from "assets/icons/time.svg";
import { ReactComponent as WifiIcon } from "assets/icons/wifi.svg";
import { Box } from "shared/ui-kit";

interface FrameBarProps {
	customStyle?: string;
}

const FrameBarWrapper = styled(Box)<FrameBarProps>`
	width: 100%;
	display: flex;

	position: relative;

	${({ customStyle }) => {
		return customStyle
			? css`
					${customStyle}
			  `
			: "";
	}}
`;

const TimeIconWrapper = styled(Box)`
	position: absolute;
	top: 0;
	left: 0;
`;

const ConnectionIconWrapper = styled(Box)`
	position: absolute;
	top: 1px;
	right: 26px;
`;

const WifiIconWrapper = styled(Box)`
	position: absolute;
	top: 0;
	right: 13px;
`;

const BatteryIconWrapper = styled(Box)`
	position: absolute;
	top: 1px;
	right: -8px;
`;

const FrameBlock: React.FC<FrameBarProps> = (props: FrameBarProps): JSX.Element => {
	return (
		<FrameBarWrapper {...props}>
			<TimeIconWrapper>
				<TimeIcon />
			</TimeIconWrapper>
			<ConnectionIconWrapper>
				<ConnectionIcon />
			</ConnectionIconWrapper>
			<WifiIconWrapper>
				<WifiIcon />
			</WifiIconWrapper>
			<BatteryIconWrapper>
				<BatteryIcon />
			</BatteryIconWrapper>
		</FrameBarWrapper>
	);
};

export default FrameBlock;
