import React, { Fragment, useCallback, useEffect } from "react";

import { Link } from "@material-ui/core";

import { useHistory, useLocation } from "react-router-dom";

import styled from "styled-components";

import { ReactComponent as CatIllustrationIcon } from "assets/icons/cat-illustration.svg";
import { useAuth } from "modules/App/Data";
import { CommunityBlock } from "modules/App/View/Components";

import { useCommunity, useGTM, useMarketing, useMemberRoutes, useUser } from "shared/hooks";
import useJwt from "shared/services/api/core/jwt";
import { IWorkspace, UserStatus } from "shared/types";
import { Box, Button, Icon, Text } from "shared/ui-kit";
import { colorPalette } from "shared/ui-kit/themes/default";

import colors from "theme/colors";

import { ContentWrapperBlock } from "../style";

const Header = styled(Text)<{ hasWorkspace: boolean }>`
	text-align: ${({ hasWorkspace }) => (hasWorkspace ? "left" : "center")};
`;

const Subtitle = styled(Text)`
	margin-top: 14px;

	span {
		font-weight: bold;
	}
`;

const ImageWrapper = styled(Box)`
	margin-top: 20px;
	display: flex;
	justify-content: center;
`;

const Note = styled(Text)`
	margin-top: 11px;
	text-align: center;
	font-size: 18px;
	font-weight: bold;
	color: #222b45;
`;

const StyledLink = styled(Link)`
	font-size: 15px;
	color: ${colors.palette.colors.purple};
	cursor: pointer;
`;

const EmailButton = styled(Button)`
	display: block;

	width: 280px;

	margin: 40px auto 0 auto;
`;

const JoinButton = styled(Button)`
	width: 100%;

	margin: 0;

	font-size: 14px;
	font-weight: bold;
	line-height: 1.14;
	color: #222b45;
`;

JoinButton.Wrapper = styled(Box)`
	padding: 0 40px;

	margin: 24px 0 0 0;
`;

const Delimiter = styled.hr`
	position: relative;

	background: #c5cee0;

	width: 100%;
	height: 1px;

	border: none;
`;

const CommunityList = styled(Box)`
	display: flex;
	flex-direction: column;
	margin-top: 48px;
`;

const CommunitiesListContainer = styled(Box)`
	background-color: white;
	border: 0.5px solid rgb(197, 206, 224);
	border-radius: 4px;
	max-width: 93%;
	margin: auto;
	padding: 24px 0;
	.padding-sides {
		padding: 0 16px;
	}

	${props => props.theme.breakpoints.up("sm")} {
		max-width: 512px;
		padding: 40px 0;
		.padding-sides {
			padding: 0 55px;
		}
	}
`;

export interface CommunitiesPageProps {
	homeUrl: string;
	signInUrl: string;
	joinCommunitiesUrl: string;
	invitationCodePage: string;
	community?: string;
}

const CommunitiesPage: React.FC<CommunitiesPageProps> = ({
	homeUrl,
	signInUrl,
	joinCommunitiesUrl,
	invitationCodePage,
	community
}) => {
	const history = useHistory();
	const { search } = useLocation();
	const { activateAccount, joinWorkspace, getData: getUserData } = useUser();
	const { workspaces } = getUserData();

	const jwtService = useJwt();
	const { login, joinCommunity, getData: getAuthData } = useAuth();
	const { email } = getAuthData();
	const { addEvent } = useGTM();

	const { resetStore } = useCommunity();

	const { getWorkspaceByName } = useMarketing();

	const { generateRoutes, getMemberRoutesData } = useMemberRoutes();
	const { workspace: routesWs } = getMemberRoutesData();

	const query = new URLSearchParams(search);

	const workspaceId = query.get("workspaceId");
	const next = query.get("next");

	const url = next ? search.split("?next=")[1] : null;

	const handleLogin = useCallback(
		async (workspace: IWorkspace) => {
			if (!routesWs || routesWs !== workspace.workspace) {
				generateRoutes(workspace.workspace);
			}

			if (workspace.isPrivate && workspace.status === UserStatus.PENDING) {
				history.push(
					`${invitationCodePage}?workspace=${workspace.workspace}&userId=${workspace.userId}&communityUrl=${workspace?.communityUrl}`
				);
			} else {
				login(workspace.token, workspace.workspace);

				try {
					if (jwtService.getJwt()) {
						addEvent({ Category: "Login", Action: "Admin Login", Label: "Login Success" });

						if (workspace.status === UserStatus.ARCHIVE) {
							await activateAccount("activate");
						}

						history.push(url ? url : homeUrl);
					}
				} catch (error) {
					const errorMessage = (error as Error).message;
					console.log(errorMessage);
				}
			}
		},
		[routesWs, generateRoutes, history, invitationCodePage, login, jwtService, addEvent, url, homeUrl, activateAccount]
	);

	useEffect(() => {
		if (workspaceId) {
			const workspace = workspaces?.find(ws => ws._id === workspaceId);
			if (workspace) {
				handleLogin(workspace);
			}
		}
	}, [workspaceId, workspaces, handleLogin]);

	useEffect(() => {
		if (!workspaces?.length) {
			history.push(joinCommunitiesUrl);
		}
	}, [workspaces, history, joinCommunitiesUrl]);

	useEffect(() => {
		if (community) {
			const workspace = workspaces?.find(ws => ws.workspace === community);
			if (workspace) {
				handleLogin(workspace);
			} else {
				getWorkspaceByName(community).then(async workspace => {
					if (email && workspace?.communityUrl) {
						const commInfo = await joinCommunity(email, workspace.communityUrl);
						resetStore();
						joinWorkspace(commInfo);
						login(commInfo.token, commInfo.workspace);
					}
				});
			}
		}
	}, [community, getWorkspaceByName, handleLogin, joinCommunity, joinWorkspace, login, resetStore, email, workspaces]);

	return (
		<ContentWrapperBlock>
			<CommunitiesListContainer>
				<Header className="padding-sides" variant="h2" hasWorkspace={!!workspaces?.length}>
					Your communities
				</Header>
				{!!workspaces?.length && (
					<Fragment>
						<Subtitle className="padding-sides" variant="body1">
							These are all the communities associated with <span>{email}</span>. <br />
							Can’t find your community?{" "}
							<StyledLink
								onClick={() => {
									history.push(signInUrl);
								}}
							>
								Try another email
							</StyledLink>
						</Subtitle>
						<CommunityList>
							{workspaces.map((workspace, index) => (
								<Fragment key={index}>
									<CommunityBlock
										title={workspace?.displayName || workspace.workspace}
										link={workspace.communityUrl}
										iconUrl={workspace.logo}
										onLogin={() => handleLogin(workspace)}
										id={workspace?.displayName || workspace.workspace}
									/>
									{index < workspaces?.length - 1 && <Delimiter />}
								</Fragment>
							))}
						</CommunityList>
					</Fragment>
				)}
				{!workspaces?.length && (
					<Fragment>
						<ImageWrapper>
							<CatIllustrationIcon />
						</ImageWrapper>
						<Note>Oops, you don’t have any communities associated with {email}.</Note>
						<EmailButton
							buttonTheme="outline"
							palette={colorPalette.colors.basic}
							onClick={() => {
								history.push(signInUrl);
							}}
						>
							Try with another email
						</EmailButton>
					</Fragment>
				)}
				<JoinButton.Wrapper>
					<JoinButton
						buttonTheme="outline"
						palette={colorPalette.colors.basic}
						onClick={() => {
							history.push(joinCommunitiesUrl);
						}}
						leftIcon={<Icon group={"thin"} fill={"#222b45"} name={"web"} />}
					>
						Join New Community
					</JoinButton>
				</JoinButton.Wrapper>
			</CommunitiesListContainer>
		</ContentWrapperBlock>
	);
};

export default CommunitiesPage;
