import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { DateTime } from "luxon";

import { useHistory } from "react-router-dom";

// import { ReactComponent as CameraFlipIcon } from "assets/icons/liveconvo/camera-flip.svg";
import { ReactComponent as MicroPhoneMutedIcon } from "assets/icons/liveconvo/mic_muted.svg";
import { ReactComponent as MicroPhoneFilledIcon } from "assets/icons/liveconvo/microphone-filled.svg";
import { ReactComponent as VideoOffIcon } from "assets/icons/liveconvo/video-off.svg";
import { ReactComponent as VideoOnIcon } from "assets/icons/liveconvo/video-on.svg";

import { useFirebase } from "modules/Messaging/Data";
import { useCommunity, useEvent, useMemberRoutes, usePersona, useUser } from "shared/hooks";
import { EventModel, MediaModel, ProfileType } from "shared/types";
import { Avatar, Tooltip } from "shared/ui-kit";

import { generateFakeLiveEvent } from "utils/liveconversation/fakeEvent";

import { ActionWrapper, ImageWrapper, InitiateConvo, MediaAction, StreamWrapper, StyledBtn } from "./style";

import useLiveConversation from "../../../Data/hooks/useLiveConversation";
import AdvancedSettingsForm from "../../Components/AdvancedSettingsForm";
import ConversationDuration from "../../Components/ConversationDuration";
import JoinButton, { ScreenTypes } from "../../Components/JoinButton";
import MediaActionButton from "../../Components/MediaActionButton";

const LiveConvoInitiatePage: React.FC<{ id: string }> = ({ id }) => {
	const {
		setRoomId,
		setIsVideoOn,
		setIsMicOn,
		getLocalStream,
		setEvent,
		getData: getLiveConversationData
	} = useLiveConversation();
	const { event, permissionGranted, isMicOn, isVideoOn, localStream } = getLiveConversationData();

	const { getActiveProfile, getData: getUserData } = useUser();
	const { user } = getUserData();

	const { createEvent, getData: getEventData } = useEvent();
	const { creating } = getEventData();

	const history = useHistory();
	const { authenticateUser } = useFirebase();
	const { getData: getCommunityData } = useCommunity();
	const { workspace } = getCommunityData();
	const { getMemberRoutesData } = useMemberRoutes();
	const { routes: memberRoutes } = getMemberRoutesData();
	const { getData: getPersonaData } = usePersona();
	const { persona } = getPersonaData();

	const profile = !!user?.profiles?.length ? (user.profiles[0] as ProfileType) : undefined;
	const userIconSrc = useMemo(() => (profile?.photos[0] as MediaModel)?.profilePicture ?? "", [profile]);
	const [startedAt, setStartedAt] = useState<string>("");
	const [totalParticipants, setTotalParticipants] = useState<number>(0);
	const [showAdvancedSettings, setShowAdvancedSettings] = useState<boolean>(false);
	const isNewEvent = id === "newevent";
	const activeProfile = useMemo(() => getActiveProfile(user), [getActiveProfile, user]);
	const personaId = activeProfile?.personaId;

	const myVideo = useRef<HTMLVideoElement>(null);

	useEffect(() => {
		if (isNewEvent) return;
		setRoomId(id);
	}, [id, setRoomId, isNewEvent]);

	useEffect(() => {
		getLocalStream();
	}, [getLocalStream]);

	useEffect(() => {
		if (!permissionGranted || !myVideo.current || !localStream || !localStream?.id) return;

		myVideo.current.srcObject = localStream;
		myVideo.current.muted = true;
		myVideo.current.onloadedmetadata = () => {
			myVideo.current?.play();
		};
		return () => {
			localStream?.getVideoTracks().forEach(track => {
				track.stop();
			});
			localStream?.getAudioTracks().forEach(track => {
				track.stop();
			});
		};
	}, [permissionGranted, localStream]);

	const onFirebaseUpdate = useCallback(updates => {
		setStartedAt(updates.startedAt);
		setTotalParticipants(updates.totalParticipants);
	}, []);

	const startConversation = useCallback(
		async (advancedEvent?: EventModel) => {
			setShowAdvancedSettings(false);
			if (advancedEvent && advancedEvent.startTime) {
				const startTime = DateTime.fromISO(advancedEvent.startTime).diffNow().toObject();
				if (startTime.milliseconds && startTime.milliseconds > 0) {
					history.replace(`${memberRoutes?.member.event.getPath()}/${advancedEvent.eventId}`);
					return;
				}
			}
			const _event = advancedEvent || (await createEvent(generateFakeLiveEvent(profile)));
			if (personaId && workspace && _event) {
				authenticateUser(personaId, workspace).then(() => {
					if (_event.liveConversationId) setRoomId(_event.liveConversationId);
					setEvent(_event);

					setTimeout(() => {
						history.replace(`${memberRoutes?.member.liveconversation.main.getPath()}/${_event.liveConversationId}`);
					}, 50);
				});
			}
		},
		[createEvent, profile, personaId, workspace, history, memberRoutes, authenticateUser, setRoomId, setEvent]
	);

	return (
		<InitiateConvo.Wrapper>
			<InitiateConvo.Title>{isNewEvent ? `${profile?.firstName}'s Live` : event?.title}</InitiateConvo.Title>
			{startedAt && !!totalParticipants && (
				<InitiateConvo.Viewers>
					<ConversationDuration startedAt={startedAt} /> . {totalParticipants}
				</InitiateConvo.Viewers>
			)}
			<StreamWrapper>
				<video ref={myVideo} />
				{!isVideoOn && (
					<ImageWrapper>
						<Avatar
							height={100}
							width={100}
							image={persona?.profileImage || userIconSrc}
							title="name"
							variant="circle"
						/>
					</ImageWrapper>
				)}
			</StreamWrapper>
			<ActionWrapper>
				<MediaAction.Wrapper>
					<MediaAction.ButtonWrapper>
						<Tooltip text={isVideoOn ? "Turn off camera" : "Turn on camera"}>
							<MediaActionButton
								onClick={() => setIsVideoOn(!isVideoOn)}
								icon={!isVideoOn ? <VideoOffIcon fill="#000000" /> : <VideoOnIcon fill="#000000" />}
								disabled={!permissionGranted}
								borderWidth={1}
							/>
						</Tooltip>
					</MediaAction.ButtonWrapper>
					<MediaAction.ButtonWrapper>
						<Tooltip text={isMicOn ? "Mute" : "Unmute"}>
							<MediaActionButton
								onClick={() => setIsMicOn(!isMicOn)}
								icon={!isMicOn ? <MicroPhoneMutedIcon fill="#000000" /> : <MicroPhoneFilledIcon fill="#000000" />}
								disabled={!permissionGranted}
								borderWidth={1}
							/>
						</Tooltip>
					</MediaAction.ButtonWrapper>
				</MediaAction.Wrapper>
				{isNewEvent ? (
					<>
						<StyledBtn onClick={() => startConversation()} disabled={creating}>
							Start Conversation
						</StyledBtn>
						<StyledBtn buttonTheme="light" palette="basic" onClick={() => setShowAdvancedSettings(true)}>
							Advanced Settings
						</StyledBtn>
					</>
				) : (
					<JoinButton event={event} screen={ScreenTypes.initiateconvo} onFirebaseUpdate={onFirebaseUpdate} />
				)}
			</ActionWrapper>
			{showAdvancedSettings && profile && (
				<AdvancedSettingsForm
					open
					onClose={() => setShowAdvancedSettings(false)}
					persona={profile}
					onEventCreated={startConversation}
				/>
			)}
		</InitiateConvo.Wrapper>
	);
};

export default LiveConvoInitiatePage;
