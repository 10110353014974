import { useCallback } from "react";

import { useMediaQuery } from "@material-ui/core";

import * as appTheme from "theme/default";

const useScrollToXPosition = (inputRef?: HTMLElement | HTMLFormElement | HTMLDivElement | null, offset = 125) => {
	const isMobile = useMediaQuery(appTheme.default.breakpoints.down("sm"));

	const scrollToXPosition = useCallback(
		event => {
			if (isMobile && event.target && inputRef)
				inputRef.scrollTo({ top: event.target.getBoundingClientRect().top + inputRef.scrollTop - offset });
		},
		[isMobile, inputRef, offset]
	);

	return scrollToXPosition;
};

export default useScrollToXPosition;
