import React, { FC, useCallback, useEffect, useMemo, useState } from "react";

import { useHistory } from "react-router";

import { FundraiserHeader } from "modules/MemberHome/View/Components/FundraiserDetails";
import {
	About,
	GroupsAndMembers,
	Leaderboard,
	Sponsors
} from "modules/MemberHome/View/Components/FundraiserDetails/sections";
import { HeaderLoader, Sections } from "modules/MemberHome/View/shared";
import { StyledWrapper } from "modules/MemberHome/View/shared/style";

import {
	FullScreenVideoDialog,
	FundraiserErrorHeader,
	InviteContactsDialog,
	MyContactsDialog,
	ShareModal
} from "shared/Components";
import { useFundraisers, useUser } from "shared/hooks";

import { GroupAndMembersModel, GroupAndSponsorsModel, ProfileType, SponsorModel } from "shared/types";

import { FundraiserDonationDialog, FundraiserSuccessfulDialog } from "../../Components";

enum FundraiserSectionsEnum {
	About = "About",
	GroupsAndMembers = "Groups & Members",
	Sponsors = "Sponsors",
	Leaderboard = "Leaderboard"
}

const sections = ["About", "Groups & Members", "Sponsors", "Leaderboard"];

const FundraiserDetails: FC<{ id: string; mode?: string; contactId: string | null }> = ({ id, mode, contactId }) => {
	const [groupAndMembers, setGroupAndMembers] = useState<GroupAndMembersModel[]>([]);
	const [groupAndSponsors, setGroupAndSponsors] = useState<GroupAndSponsorsModel[]>([]);
	const [current, setCurrent] = useState<string>(FundraiserSectionsEnum.About);
	const [shareModal, setShareModal] = useState<{ url: string }>();
	const [autoOpenAddContacts, setAutoOpenAddContacts] = useState(false);

	const history = useHistory();

	const { getActiveProfile, getData: getUserData } = useUser();
	const { user } = getUserData();
	const activeProfile = useMemo(() => getActiveProfile(user), [getActiveProfile, user]);

	const {
		getData: getFundraisersData,
		setInviteDialog,
		setSubmitting,
		getFundraiser,
		setFullScreenVideoDialog,
		getContactInfo,
		getFundraiserMembers,
		setInviteDialogModel,
		pauseFundraiser,
		getFundraiserSponsors,
		setOnBehalfOf
	} = useFundraisers();
	const {
		donationDialog,
		donateSuccessfulDialog,
		inviteDialog,
		myContactsDialog,
		fundraiser,
		loading,
		fullScreenVideoDialog
	} = getFundraisersData();
	const isFundraiserCreatorOrPriviliged = useMemo(
		() =>
			user?.isGlobalAdmin ||
			user?.isGlobalOwner ||
			user?.isGlobalModerator ||
			user?.isInfluencer ||
			fundraiser?.creator[0]?.personaId === user?.activeProfile,
		[user, fundraiser]
	);

	useEffect(() => {
		if (id) getFundraiser(id);
	}, [id, getFundraiser]);

	useEffect(() => {
		if (contactId) getContactInfo(contactId);
		return () => {
			setOnBehalfOf();
		};
	}, [contactId, getContactInfo, setOnBehalfOf]);

	useEffect(() => {
		if (fundraiser && fundraiser._id === id) {
			getFundraiserMembers({
				id: fundraiser._id,
				limit: 200,
				addContactCount: true,
				keepDuplicates: true
			}).then(members => {
				if (members?.length) {
					const groupAndMembers = fundraiser.groups.map(g => {
						return {
							...g,
							members: [] as ProfileType[]
						};
					});

					groupAndMembers.forEach(({ _id }, idx) => {
						const filtered = members.filter(m => m?.groupId === _id);
						groupAndMembers[idx].members = [...filtered];
					});

					setGroupAndMembers(groupAndMembers);
				}
			});
		}
	}, [fundraiser, getFundraiserMembers, id]);

	useEffect(() => {
		if (fundraiser && fundraiser._id === id) {
			getFundraiserSponsors({ id: fundraiser._id, limit: 100 }).then(sponsors => {
				if (sponsors?.length) {
					const groupAndSponsors = fundraiser.groups.map(g => {
						return {
							...g,
							sponsors: [] as SponsorModel[]
						};
					});

					groupAndSponsors.forEach(({ _id }, idx) => {
						const arr: SponsorModel[] = [];

						sponsors.forEach(s => {
							if (s.onBehalfOfPersona.length) {
								s.onBehalfOfPersona.forEach(b => {
									if (b.group.length) {
										b.group.forEach(g => {
											if (g.groupId === _id) arr.push(s);
										});
									}
								});
							}
						});

						groupAndSponsors[idx].sponsors = [...arr];
					});

					setGroupAndSponsors(
						groupAndSponsors.sort((a, b) =>
							a.sponsors.reduce((acc, m) => acc + m.amountRaised, 0) >
							b.sponsors.reduce((acc, m) => acc + m.amountRaised, 0)
								? -1
								: 1
						)
					);
				}
			});
		}
	}, [fundraiser, getFundraiserSponsors, id]);

	useEffect(() => {
		if (mode && mode === "inviteContact" && fundraiser && !autoOpenAddContacts) {
			setInviteDialog({
				open: true,
				id,
				name: fundraiser.name,
				onBehalfOf:
					!isFundraiserCreatorOrPriviliged && activeProfile
						? {
								label: activeProfile?.firstName,
								value: activeProfile?.personaId
						  }
						: undefined
			});

			setAutoOpenAddContacts(true);
		}
	}, [
		activeProfile,
		autoOpenAddContacts,
		fundraiser,
		history,
		id,
		isFundraiserCreatorOrPriviliged,
		mode,
		setInviteDialog
	]);

	const handleSwitchSection = useCallback((section: string) => {
		setCurrent(section);
	}, []);

	const handleCloseInvite = useCallback(() => {
		setInviteDialog();
		setSubmitting(false);
		setInviteDialogModel();
	}, [setInviteDialog, setSubmitting, setInviteDialogModel]);

	const currentSection = useMemo(() => {
		switch (current) {
			case FundraiserSectionsEnum.About:
				return <About />;
			case FundraiserSectionsEnum.GroupsAndMembers:
				return <GroupsAndMembers id={id} groupAndMembers={groupAndMembers} />;
			case FundraiserSectionsEnum.Sponsors:
				return <Sponsors groupAndSponsors={groupAndSponsors} />;
			case FundraiserSectionsEnum.Leaderboard:
				return <Leaderboard />;
			default:
				return null;
		}
	}, [current, id, groupAndMembers, groupAndSponsors]);

	const handleActive = useCallback(async () => {
		await pauseFundraiser(id, "unpause");
		getFundraiser(id);
	}, [getFundraiser, id, pauseFundraiser]);

	const onCloseVideoDialog = useCallback(() => {
		setFullScreenVideoDialog({
			open: false,
			url: ""
		});
	}, [setFullScreenVideoDialog]);

	return !fundraiser || loading ? (
		<>
			<StyledWrapper>
				<HeaderLoader />
				<Sections sections={[]} loading current={current} handleSwitchSection={handleSwitchSection} />
			</StyledWrapper>
		</>
	) : (
		<>
			<StyledWrapper>
				<FundraiserErrorHeader status={fundraiser.status} handleActive={handleActive} />
				<FundraiserHeader id={id} contactId={contactId} setShareModal={url => setShareModal({ url })} />
				<Sections sections={sections} current={current} handleSwitchSection={handleSwitchSection} />
				<StyledWrapper.Content>{currentSection}</StyledWrapper.Content>
			</StyledWrapper>
			{donationDialog?.open && <FundraiserDonationDialog id={id} contactId={contactId || ""} />}
			{donateSuccessfulDialog?.open && <FundraiserSuccessfulDialog />}
			{inviteDialog?.open && <InviteContactsDialog handleClose={handleCloseInvite} />}
			{myContactsDialog?.open && <MyContactsDialog id={id} />}
			{fullScreenVideoDialog?.open && (
				<FullScreenVideoDialog url={fullScreenVideoDialog.url} handleClose={onCloseVideoDialog} autoplay />
			)}
			{!!shareModal && (
				<ShareModal title="Share Fundraiser" url={shareModal.url} onClose={() => setShareModal(undefined)} />
			)}
		</>
	);
};

export default FundraiserDetails;
