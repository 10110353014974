import React from "react";

import styled from "styled-components";

import { Box, Text } from "shared/ui-kit";

import { customBreakpoints } from "theme/default";

interface FeatureCardProps {
	icon?: JSX.Element;
	title: string;
	description: string;
	enabled: boolean;
}

const FeatureCardWrapper = styled(Box)<{ active: boolean }>`
	padding: 12px 16px 24px 16px;
	border-radius: 4px;

	display: flex;
	align-items: flex-start;
	text-align: left;

	transition: border-color 0.25s linear, transform 0.25s linear;

	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
	border: solid ${({ active }) => (active ? "1px #6173fe" : "0.5px #c5cee0")};
	background-color: ${({ active }) => (active ? "#ebecff" : "#ffffff")};

	min-height: 153px;

	&:hover {
		transform: scale(1.05);
	}

	${props => props.theme.breakpoints.down(customBreakpoints.tabletVerticalMax)} {
		flex-direction: column;

		padding: 16px;
	}
`;

const IconWrapper = styled(Box)`
	width: 38%;
	height: 90px;

	display: flex;
	justify-content: center;

	${props => props.theme.breakpoints.down(customBreakpoints.tabletVerticalMax)} {
		width: 100%;
	}
`;

const InfoWrapper = styled(Box)`
	width: 62%;

	padding-left: 7px;

	${props => props.theme.breakpoints.down(customBreakpoints.tabletVerticalMax)} {
		width: 100%;
	}
`;

const Title = styled(Text)`
	${props => props.theme.breakpoints.down("xs")} {
		font-size: 13px;
	}
`;

const Description = styled(Text)`
	margin-top: 10px;

	${props => props.theme.breakpoints.down("xs")} {
		font-size: 13px;
	}
`;

const FeatureCard: React.FC<FeatureCardProps> = ({ icon, title, description, enabled }: FeatureCardProps) => {
	return (
		<FeatureCardWrapper active={enabled}>
			<IconWrapper>{icon}</IconWrapper>
			<InfoWrapper>
				<Title variant="h6">{title}</Title>
				<Description variant="body2">{description}</Description>
			</InfoWrapper>
		</FeatureCardWrapper>
	);
};

export default FeatureCard;
