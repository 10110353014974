import React, { useCallback, useEffect, useMemo, useState } from "react";

import { Omit, useMediaQuery } from "@material-ui/core";
import { DateTime } from "luxon";

import { EventType, UserEventType } from "types";

import { eventModalType } from "modules/Manage/View/Containers/ManageEvents";
import { CreateEvent } from "shared/Components";
import { useEvent, useUser } from "shared/hooks";
import { EventModel, EventSearchParams } from "shared/types";
import * as appTheme from "theme/default";

import { Delimiter, FilterBlock, FilterOptionType, ModelBlock, PageWrapper } from "../../Components";
import { ModelBlockType } from "../../Components/ModelBlock";

const defaultFilters = {
	limit: 10,
	keyword: ""
};

type PaginatedEventSearchParams = Omit<EventSearchParams, "count"> & { page: number };

export interface EventsPageProps {}

enum EventsFilters {
	RELEVANCE = "Relevance",
	MOST_RECENT = "Most Recent",
	MOST_POPULAR = "Most Popular",
	MY_EVENTS = "My Events",
	ONLINE = "Online",
	HAPPENING_NOW = "Happening Now",
	TODAY = "Today",
	TOMORROW = "Tomorrow",
	THIS_WEEK = "This Week",
	THIS_WEEKEND = "This Weekend",
	NEXT_WEEK = "Next Week",
	NEXT_MONTH = "Next Month",
	PAST_EVENTS = "Past Events"
}

export interface EventsType {
	myEvents: boolean;
	online: boolean;
	startDate?: string;
	endDate?: string;
	categories?: string[];
	isToday?: boolean;
	pastEvents?: boolean;
}

const EventsPage: React.FC<EventsPageProps> = () => {
	const { getEvents, loadCategories, getUserEvents, getData: getEventsData } = useEvent();
	const { categories } = getEventsData();

	const { getActiveProfile, getData: getUserData } = useUser();
	const { user } = getUserData();

	const isMobile = useMediaQuery(appTheme.default.breakpoints.down("sm"));

	const [myEvents, setMyEvents] = useState<{ type: UserEventType; data: EventModel[] }>({
		type: "myevents",
		data: []
	});
	const [allowLoadMoreUserEvents, setAllowLoadMoreUserEvents] = useState(false);
	const [loadingUserEvents, setLoadingUserEvents] = useState(false);
	const [eventInfoPopup, setEventInfoPopup] = useState<eventModalType>({
		open: false
	});

	const [showFilteredData, setShowFilteredData] = useState("");

	const [happeningEvents, setHappeningEvents] = useState<EventModel[]>([]);
	const [happeningEventsCount, setHappeningEventsCount] = useState<number>();
	const [loadingHappeningEvents, setLoadingHappeningEvents] = useState(false);

	const [allEvents, setAllEvents] = useState<EventModel[]>([]);
	const [upcomingEvents, setUpcomingEvents] = useState<EventModel[]>([]);
	const [upcomingEventsCount, setUpcomingEventsCount] = useState<number>();
	const [allEventsCount, setAllEventsCount] = useState<number>();

	const [loadingUpcomingEvents, setLoadingUpcomingEvents] = useState(false);
	const [loadingAllEvents, setLoadingAllEvents] = useState(false);

	const [filters, setFilters] = useState<EventsType>({ myEvents: false, online: false });

	const activeProfile = useMemo(() => getActiveProfile(user), [getActiveProfile, user]);

	const fetchEvents = useCallback(
		async ({
			eventType = EventType.All,
			isMyEvents = false,
			page = 1,
			liveConversation,
			startDate,
			endDate
		}: {
			eventType?: EventType;
			isMyEvents?: boolean;
			page?: number;
			liveConversation?: boolean;
			startDate?: string;
			endDate?: string;
		}) => {
			if (isMyEvents || filters.myEvents) {
				setLoadingUserEvents(true);
				// const eType: UserEventType = liveConversation ? "myliveconversation" : "myevents";
				// const data = await getEvents({
				// 	...defaultFilters,
				// 	eventSchedule: eventType,
				// 	page,
				// 	eventType: eType,
				// 	categories: filters.categories,
				// 	startDate,
				// 	endDate,
				// 	sortBy: "endTime",
				// 	sortOrder: -1
				// });

				const data = await getUserEvents({
					...defaultFilters,
					page,
					personaId: activeProfile?.personaId || 0,
					potentialMatchingIds: []
				});

				setMyEvents(state => {
					let newEventList = filters?.pastEvents ? data.past : data.hosted.concat(data.going).concat(data.interested);
					if (page > 1) {
						newEventList = state.data.concat(newEventList);
					}

					newEventList = (
						filters?.pastEvents
							? newEventList
							: newEventList.filter(x => new Date(x.startTime!) > new Date() || new Date(x.endTime!) > new Date())
					).filter((value, index, arr) => index === arr.findIndex(e => e.eventId === value.eventId));

					let allowLoadMore;
					if (filters?.pastEvents) {
						allowLoadMore = data.past?.length === defaultFilters.limit;
					} else {
						const fetchedEventList = data.hosted
							.concat(data.going)
							.concat(data.interested)
							.filter(x => new Date(x.startTime!) > new Date() || new Date(x.endTime!) > new Date())
							.filter((value, index, arr) => index === arr.findIndex(e => e.eventId === value.eventId));
						allowLoadMore = fetchedEventList.length === defaultFilters.limit;
					}

					setAllowLoadMoreUserEvents(allowLoadMore);

					return {
						type: "myevents",
						data: newEventList.sort((a, b) => new Date(a.startTime!).getTime() - new Date(b.startTime!).getTime())
					};
				});

				setLoadingUserEvents(false);
			} else {
				if (eventType === EventType.Happening) {
					setLoadingHappeningEvents(true);
				} else if (eventType === EventType.Upcoming) {
					setLoadingUpcomingEvents(true);
				} else if (eventType === EventType.All) {
					setLoadingAllEvents(true);
				}

				const params: PaginatedEventSearchParams = {
					...defaultFilters,
					eventSchedule: eventType,
					page,
					eventType: liveConversation ? "liveconversation" : undefined,
					categories: filters.categories,
					startDate,
					endDate,
					sortBy: "startTime",
					sortOrder: 1
				};

				const data = await getEvents(params);

				if (eventType === EventType.Happening) {
					setHappeningEvents(events => (page === 1 ? data.events : events.concat(data.events)));
					setHappeningEventsCount(data.totalEventsFound);
					setLoadingHappeningEvents(false);
				} else if (eventType === EventType.Upcoming) {
					setUpcomingEvents(events => (page === 1 ? data.events : events.concat(data.events)));
					setUpcomingEventsCount(data.totalEventsFound);
					setLoadingUpcomingEvents(false);
				} else if (eventType === EventType.All) {
					setAllEvents(events => (page === 1 ? data.events : events.concat(data.events)));
					setAllEventsCount(data.totalEventsFound);
					setLoadingAllEvents(false);
				}
			}
		},
		[getEvents, filters, activeProfile?.personaId, getUserEvents]
	);

	useEffect(() => {
		if (filters.myEvents) {
			fetchEvents({
				eventType: filters.isToday ? EventType.Happening : EventType.All,
				isMyEvents: true,
				liveConversation: filters.online,
				startDate: filters.startDate,
				endDate: filters.endDate
			});
		} else {
			fetchEvents({
				eventType: filters.isToday ? EventType.Happening : EventType.All,
				isMyEvents: true,
				liveConversation: filters.online,
				startDate: filters.startDate,
				endDate: filters.endDate
			});

			if (filters.startDate || filters.endDate) {
				fetchEvents({
					eventType: filters.isToday ? EventType.Happening : EventType.All,
					liveConversation: filters.online,
					startDate: filters.startDate,
					endDate: filters.endDate
				});
			} else {
				fetchEvents({
					eventType: EventType.Happening,
					liveConversation: filters.online,
					startDate: filters.startDate,
					endDate: filters.endDate
				});
				fetchEvents({
					eventType: EventType.Upcoming,
					liveConversation: filters.online,
					startDate: filters.startDate,
					endDate: filters.endDate
				});
			}
		}
	}, [fetchEvents, filters]);

	useEffect(() => {
		loadCategories();
	}, [loadCategories]);

	const filterOptions: FilterOptionType[] = useMemo(() => {
		const optionsList: FilterOptionType[] = [
			// TODO: Apply sorting by recent/popular when backend support is ready
			// {
			// 	label: "Sort by",
			// 	onClick: () => {},
			// 	listOptions: [
			// 		{
			// 			label: "Relevance",
			// 			value: EventsFilters.RELEVANCE
			// 		},
			// 		{
			// 			label: "Most Recent",
			// 			value: EventsFilters.MOST_RECENT
			// 		},
			// 		{
			// 			label: "Most Popular",
			// 			value: EventsFilters.MOST_POPULAR
			// 		}
			// 	]
			// },
			{
				label: "Event Category",
				onClick: categories => {
					setFilters(ctx => ({ ...ctx, categories: typeof categories === "string" ? [categories] : categories }));
				},
				multipleChoice: true,
				listOptions: categories.map(({ sort_name, name }) => ({
					label: name,
					value: sort_name
				}))
			},
			{
				label: "Event Date",
				onClick: item => {
					const now = new Date();
					const startDateStringJS = new Date(now.getFullYear(), now.getMonth(), now.getDate() - now.getDay() + 1);

					!!item && item !== EventsFilters.TODAY ? setShowFilteredData(item as string) : setShowFilteredData("");

					switch (item) {
						case EventsFilters.TODAY:
							return setFilters(ctx => ({ ...ctx, isToday: true, startDate: undefined, endDate: undefined }));
						case EventsFilters.TOMORROW:
							return setFilters(ctx => ({
								...ctx,
								startDate: DateTime.now().plus({ days: 1 }).toISODate(),
								endDate: undefined,
								isToday: undefined
							}));
						case EventsFilters.THIS_WEEK:
							return setFilters(ctx => ({
								...ctx,
								startDate: DateTime.fromJSDate(startDateStringJS).toISODate(),
								endDate: DateTime.fromJSDate(
									new Date(now.getFullYear(), now.getMonth(), startDateStringJS.getDate() + 6)
								).toISODate(),
								isToday: undefined
							}));
						case EventsFilters.THIS_WEEKEND:
							return setFilters(ctx => ({
								...ctx,
								startDate: DateTime.fromJSDate(
									new Date(now.getFullYear(), now.getMonth(), startDateStringJS.getDate() + 4)
								).toISODate(),
								endDate: DateTime.fromJSDate(
									new Date(now.getFullYear(), now.getMonth(), startDateStringJS.getDate() + 5)
								).toISODate(),
								isToday: undefined
							}));

						case EventsFilters.NEXT_WEEK:
							const nextMonday = DateTime.fromJSDate(startDateStringJS).plus({ days: 6 }).toISODate();
							const nextSunday = DateTime.fromISO(nextMonday).plus({ days: 6 }).toISODate();

							return setFilters(ctx => ({ ...ctx, startDate: nextMonday, endDate: nextSunday, isToday: undefined }));

						case EventsFilters.NEXT_MONTH:
							return setFilters(ctx => ({
								...ctx,
								startDate: DateTime.fromJSDate(new Date(now.getFullYear(), now.getMonth() + 1, 1)).toISODate(),
								endDate: DateTime.fromJSDate(new Date(now.getFullYear(), now.getMonth() + 2, 0)).toISODate(),
								isToday: undefined
							}));

						default:
							return setFilters(ctx => ({ ...ctx, startDate: undefined, endDate: undefined, isToday: undefined }));
					}
				},
				listOptions: [
					{
						label: "Today",
						value: EventsFilters.TODAY
					},
					{
						label: "Tomorrow",
						value: EventsFilters.TOMORROW
					},
					{
						label: "This Week",
						value: EventsFilters.THIS_WEEK
					},
					{
						label: "This Weekend",
						value: EventsFilters.THIS_WEEKEND
					},
					{
						label: "Next Week",
						value: EventsFilters.NEXT_WEEK
					},
					{
						label: "Next Month",
						value: EventsFilters.NEXT_MONTH
					}
				]
			},
			{
				inlineOptions: true,
				onClick: () => {
					setFilters(ctx => ({ ...ctx, myEvents: !ctx.myEvents, pastEvents: false }));
				},
				listOptions: [
					{
						label: "My Events",
						value: EventsFilters.MY_EVENTS,
						id: "eventName"
					}
				]
			}
		];

		if (filters.myEvents) {
			optionsList.push({
				inlineOptions: true,
				onClick: () => {
					setFilters(ctx => ({ ...ctx, pastEvents: !ctx.pastEvents }));
				},
				listOptions: [
					{
						label: "Past Events",
						value: EventsFilters.PAST_EVENTS,
						id: "pastEvents"
					}
				]
			});
		}

		optionsList.push({
			inlineOptions: true,
			onClick: () => {
				setFilters(ctx => ({ ...ctx, online: !ctx.online }));
			},
			listOptions: [
				{
					label: "Online",
					value: EventsFilters.ONLINE,
					id: "eventOnline"
				}
			]
		});

		return optionsList;
	}, [categories, filters.myEvents]);

	const createOption = useMemo(
		() => ({
			label: "Create New Event",
			onClick: () => setEventInfoPopup({ open: true, model: undefined }),
			id: "createNewEvent"
		}),
		[]
	);

	return (
		<>
			<PageWrapper>
				<FilterBlock options={filterOptions} createOption={createOption} />
				{filters.myEvents ? (
					<ModelBlock
						autoFit={myEvents.data.length > 3 ? 300 : undefined}
						loading={loadingUserEvents}
						title={"My Events"}
						type={ModelBlockType.event}
						inlineView={true}
						items={myEvents.data}
						keepCurrentData
						onEndScroll={() => {
							if (allowLoadMoreUserEvents && !loadingUserEvents) {
								fetchEvents({
									isMyEvents: true,
									page: Math.ceil(myEvents.data.length / defaultFilters.limit) + 1,
									liveConversation: filters.online,
									startDate: filters.startDate,
									endDate: filters.endDate
								});
							}
						}}
						noContent={"You don’t have any events."}
					/>
				) : (
					<>
						<ModelBlock
							loading={loadingUserEvents}
							title={"My Events"}
							type={ModelBlockType.event}
							items={myEvents.data}
							keepCurrentData={loadingUserEvents && Math.ceil(myEvents.data.length / defaultFilters.limit) + 1 > 1}
							onEndScroll={() => {
								if (allowLoadMoreUserEvents && !loadingUserEvents) {
									fetchEvents({
										isMyEvents: true,
										page: Math.ceil(myEvents.data.length / defaultFilters.limit) + 1,
										liveConversation: filters.online,
										startDate: filters.startDate,
										endDate: filters.endDate
									});
								}
							}}
							noContent={"You don’t have any events."}
						/>

						<Delimiter />
						{/*<ModelBlock*/}
						{/*	title={"Popular Events"}*/}
						{/*	type={ModelBlockType.event}*/}
						{/*	items={[]}*/}
						{/*	noContent={"You don’t have popular events."}*/}
						{/*/>*/}
						{/*<Delimiter />*/}
						{showFilteredData ? (
							<ModelBlock
								title={`${showFilteredData} Events`}
								loading={loadingAllEvents}
								type={ModelBlockType.event}
								items={allEvents}
								onEndScroll={() => {
									if (allEventsCount && allEvents.length < allEventsCount) {
										fetchEvents({
											eventType: EventType.All,
											page: Math.ceil(happeningEvents.length / defaultFilters.limit) + 1,
											liveConversation: filters.online,
											startDate: filters.startDate,
											endDate: filters.endDate
										});
									}
								}}
								noContent={"You don’t have any current events."}
							/>
						) : (
							<>
								<ModelBlock
									title={`${filters.isToday ? "Today Events" : "Events Happening Now"}`}
									loading={loadingHappeningEvents}
									type={ModelBlockType.event}
									items={happeningEvents}
									onEndScroll={() => {
										if (happeningEventsCount && happeningEvents.length < happeningEventsCount) {
											fetchEvents({
												eventType: EventType.Happening,
												page: Math.ceil(happeningEvents.length / defaultFilters.limit) + 1,
												liveConversation: filters.online,
												startDate: filters.startDate,
												endDate: filters.endDate
											});
										}
									}}
									noContent={"You don’t have any current events."}
								/>
								{!filters.isToday && (
									<>
										<Delimiter />
										<ModelBlock
											title={"Upcoming Events"}
											loading={loadingUpcomingEvents}
											type={ModelBlockType.event}
											items={upcomingEvents}
											inlineView={!isMobile}
											onEndScroll={() => {
												if (upcomingEventsCount && upcomingEvents.length < upcomingEventsCount) {
													fetchEvents({
														eventType: EventType.Upcoming,
														page: Math.ceil(upcomingEvents.length / defaultFilters.limit) + 1,
														liveConversation: filters.online,
														startDate: filters.startDate,
														endDate: filters.endDate
													});
												}
											}}
											noContent={"You don’t have any upcoming events."}
										/>
									</>
								)}
							</>
						)}
					</>
				)}
			</PageWrapper>
			{eventInfoPopup.open && (
				<CreateEvent
					open={eventInfoPopup.open}
					editableModel={eventInfoPopup.model}
					onClose={e => {
						setEventInfoPopup({ open: false });
						if (e.created) {
							fetchEvents({
								isMyEvents: true,
								liveConversation: filters.online,
								startDate: filters.startDate,
								endDate: filters.endDate
							});
						}
					}}
				/>
			)}
		</>
	);
};

export default EventsPage;
