import React, { useMemo } from "react";

import CoreCheckbox, { CheckboxProps as MuiCheckboxProps } from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import styled from "styled-components";

import { useCommunity, useUser } from "shared/hooks";

export type CheckboxProps = MuiCheckboxProps & {
	label?: React.ReactNode;
	onChange: (e: any) => void;
};

const StyledFormControlLabel = styled(FormControlLabel)`
	.Mui-checked svg {
		fill: ${({ color }) => color || "#6173fe"} !important;
	}
`;

export default styled(({ label, className, ...rest }: Readonly<CheckboxProps>) => {
	const { communityColors } = useCommunity();
	const { getData: getUserData } = useUser();
	const { isMemberView } = getUserData();

	const color = useMemo(() => isMemberView && communityColors.primary, [communityColors.primary, isMemberView]);

	return (
		<StyledFormControlLabel
			control={<CoreCheckbox size="medium" color="primary" {...rest} />}
			label={label}
			className={className}
			color={color}
		/>
	);
})`
	.MuiCheckbox-colorPrimary.Mui-checked:not(.Mui-disabled) {
		color: ${props => props.theme.palette.controlPrimaryColor};
	}
	.MuiCheckbox-root {
		color: ${props => props.theme.palette.controlSecondaryColor};
	}
	.MuiSvgIcon-root {
		font-size: 24px;
	}
`;
