const danger = {
	100: "#ffe7d8",
	200: "#ffc8b1",
	300: "#ffa38a",
	400: "#ff806d",
	500: "#ff463d",
	600: "#db2c33",
	700: "#b71e31",
	800: "#93132d",
	900: "#7a0b2b"
};

export default danger;
