import React from "react";

import { useLocation } from "react-router-dom";

import { Card, ContributorsCard } from "../../Components";

const ContributorsPage = () => {
	const params = new URLSearchParams(useLocation().search);
	const slug = params.get("slug") || undefined;

	return (
		<Card>
			<ContributorsCard slug={slug} />
		</Card>
	);
};

export default ContributorsPage;
