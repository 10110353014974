import React from "react";

import { view } from "modules/MemberHome";

import { useMemberRoutes } from "shared/hooks";

const FillProfileMaritalStatusPage = () => {
	const { getMemberRoutesData } = useMemberRoutes();
	const { routes } = getMemberRoutesData();

	return (
		<view.FillProfileMaritalStatus
			motherhoodPageUrl={routes?.member.fillProfile.motherhood.getPath()}
			selfiePageUrl={routes?.member.fillProfile.selfie.getPath()}
		/>
	);
};

export default FillProfileMaritalStatusPage;
