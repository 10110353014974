import React from "react";

import styled from "styled-components";

import { ReactComponent as SoundWaveIcon } from "assets/icons/iconSoundWave.svg";

import { PlaceholderImage } from "shared/Components";
import { PlaceholderImageType, TrackMeta, TrackModel, genres } from "shared/types";

import SelectItemTemplate from "../Blocks/SelectItemTemplate";

const SoundWave = styled(SoundWaveIcon)`
	width: 38px;
	height: 43px;

	path {
		&:nth-child(2) {
			fill: #8f9bb3;
		}
	}
`;

const getGenreName = key => {
	return genres.find(g => g.key === key)?.value;
};

const TrackItem: React.FC<TrackModel & { onSelect: () => void }> = ({ title, genre, onSelect, meta }) => (
	<SelectItemTemplate
		coverUrl={(meta as TrackMeta)?.artwork?.url || ""}
		previewIcon={<SoundWave />}
		onSelect={onSelect}
		pretitle={`${genre?.map(getGenreName).slice(0, 2).join(", ")}${genre?.length > 2 ? "..." : ""}`}
		title={title}
		subtitle={(meta as TrackMeta)?.artist || ""}
		placeholderImg={
			<PlaceholderImage type={PlaceholderImageType.TRACK_PREVIEW} width={80} height={80} viewBox={"0 0 400 400"} />
		}
	/>
);

export default TrackItem;
