export enum UnlockContentType {
	POST = "post",
	FILE = "userfile",
	TRACK = "music",
	VIDEO = "video",
	ALBUM = "album",
	COLLECTION = "collection",
	EVENT = "event",
	GROUP = "group",
	FUNDRAISER = "fundraiser"
}
