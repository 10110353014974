import React, { useEffect, useRef } from "react";

import config from "config/appConfig";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import { useRoutes } from "shared/hooks";
import { Box, Button } from "shared/ui-kit";
import colors from "theme/colors";

import { routes as authRoutes } from "../../Auth/constants";

const Nav = styled(Box)`
	height: 100vh;
`;

Nav.Wrapper = styled(Box)<{ show: boolean }>`
	position: fixed;
	top: 0;
	right: 0;
	z-index: 1200;

	width: 300px;
	height: 100vh;

	transition: transform cubic-bezier(0.7, 0, 0.3, 1) 400ms 17ms;

	background-color: rgba(0, 0, 0, 0.85) !important;

	transform: ${({ show }) => (show ? "translate3d(0,0,0)" : "translate3d(300px,0,0)")};

	display: flex;
	align-items: center;

	${props => props.theme.breakpoints.down("sm")} {
		width: 250px;
		transform: ${({ show }) => (show ? "translate3d(0,0,0)" : "translate3d(250px,0,0)")};
	}
`;

Nav.MenuWrapper = styled.nav`
	flex: 1 100%;

	background: 0 0;

	border: none;

	margin: 0;
	padding: 0;
`;

Nav.Menu = styled.ul`
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	list-style-type: none;

	padding: 0 53px 0 30px;

	line-height: inherit;

	${props => props.theme.breakpoints.down("sm")} {
		padding: 0 15px;
	}
`;

Nav.MenuOption = styled.li`
	padding: 7px 0;

	display: flex;
	justify-content: flex-end;
	align-items: center;

	border-bottom: 1px dotted ${colors.palette.colors.purple};

	cursor: pointer;

	position: relative;

	${props => props.theme.breakpoints.down("sm")} {
		padding: 6px 0;

		border-bottom-color: #5a5a5a;
	}

	&:hover {
		a {
			color: ${colors.palette.colors.purple};
		}
	}
`;

Nav.MenuComing = styled(Box)`
	position: absolute;

	top: 6px;
	left: 0;

	${props => props.theme.breakpoints.down("sm")} {
		left: auto;
		right: 0;
	}
`;

Nav.MenuLink = styled.a`
	color: white;
	text-transform: uppercase;
	text-decoration: none;

	font-size: 14px;
	line-height: 25px;
	font-weight: 400;

	width: 100%;
	text-align: right;

	${props => props.theme.breakpoints.down("sm")} {
		text-align: left;
		font-weight: 700;
	}
`;

const ComingSoonBtn = styled(Button)`
	background: ${colors.palette.colors.purple};

	margin: 0;
	padding: 8px 10px;

	width: 108px;
	height: 28px;

	border: none;
	border-radius: 14px;

	font-size: 13px;

	${props => props.theme.breakpoints.down("sm")} {
		width: 88px;
		height: 23px;
		border-radius: 14px;
		font-size: 10px;
	}
`;

const CreateCmtButton = styled(Button)`
	display: none;

	background-color: ${colors.palette.colors.purple};

	width: auto;
	height: 37px;

	padding: 10px 20px;

	border-radius: 12px;

	font-size: 14px;
	font-weight: 700;

	margin: 20px 10px 0 0;

	text-transform: uppercase;

	${props => props.theme.breakpoints.down("sm")} {
		display: block;
	}
`;

const AdminLoginBtn = styled(Button)`
	display: none;

	background: transparent;
	color: white;
	border: none;
	box-shadow: none;

	font-size: 14px;
	font-weight: 700;

	margin: 19px 20px 0 0;

	text-transform: uppercase;

	${props => props.theme.breakpoints.down("sm")} {
		display: block;
	}
`;

interface NavMenuProps {
	show: boolean;
	onClose: () => void;
}

const NavMenu: React.FC<NavMenuProps> = ({ show, onClose }) => {
	const wrapperRef = useRef(null);
	const history = useHistory();
	const { createNewCommunity } = useRoutes();

	useEffect(() => {
		const handleClickOutside = event => {
			// @ts-expect-error: ref link object
			if (wrapperRef.current && !wrapperRef.current!.contains(event.target)) {
				onClose && onClose();
			}
		};

		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [wrapperRef, onClose]);

	return (
		<Nav.Wrapper show={show} ref={wrapperRef}>
			<Nav.MenuWrapper>
				<Nav.Menu>
					<Nav.MenuOption>
						<Nav.MenuLink href={config.GLOBAL_CONSTANTS.VYOO_MARKETING_SITE_URL}>Home</Nav.MenuLink>
					</Nav.MenuOption>
					<Nav.MenuOption>
						<Nav.MenuLink href={`${config.GLOBAL_CONSTANTS.VYOO_MARKETING_SITE_URL}features/`}>Features</Nav.MenuLink>
					</Nav.MenuOption>
					<Nav.MenuOption>
						<Nav.MenuComing>
							<ComingSoonBtn removeSideMargin size="small">
								Coming Soon
							</ComingSoonBtn>
						</Nav.MenuComing>
						<Nav.MenuLink>Blog</Nav.MenuLink>
					</Nav.MenuOption>
					<Nav.MenuOption>
						<Nav.MenuLink href={`${config.GLOBAL_CONSTANTS.VYOO_MARKETING_SITE_URL}team/`}>Team</Nav.MenuLink>
					</Nav.MenuOption>
					<Nav.MenuOption>
						<Nav.MenuComing>
							<ComingSoonBtn removeSideMargin size="small">
								Coming Soon
							</ComingSoonBtn>
						</Nav.MenuComing>
						<Nav.MenuLink>Communities</Nav.MenuLink>
					</Nav.MenuOption>
					<Nav.MenuOption>
						<Nav.MenuLink href={`${config.GLOBAL_CONSTANTS.VYOO_MARKETING_SITE_URL}faq/`}>FAQ</Nav.MenuLink>
					</Nav.MenuOption>
					<CreateCmtButton onClick={createNewCommunity}>Create Community</CreateCmtButton>
					<AdminLoginBtn buttonTheme={"outline"} onClick={() => history.push(authRoutes.auth.signIn.getPath())}>
						Admin Login
					</AdminLoginBtn>
				</Nav.Menu>
			</Nav.MenuWrapper>
		</Nav.Wrapper>
	);
};

export default NavMenu;
