import React, { FC, memo, useCallback, useMemo, useState } from "react";

import { OutlinedTextFieldProps } from "@material-ui/core";

import clsx from "clsx";
import { DateTime } from "luxon";

import { SizesEnum } from "shared/types";

import { Icon } from "shared/ui-kit";

import Datepicker, { DatepickerProps } from "./Datepicker";
import Dropdown, { DropdownProps } from "./Dropdown";
import InputBase, { InputProps } from "./InputBase";
import MaskInput, { MaskInputProps } from "./MaskInput";
import Search, { SearchProps } from "./Search";
import Timepicker, { TimepickerProps } from "./Timepicker";

import { Headline, InputWrapper } from "./style";

import useScrollToXPosition from "../../hooks/useScrollToXPosition";

interface InputWrapperProps {
	headline?: string;
	boldHeadline?: boolean;
	wrapClassName?: string;

	dropdown?: boolean;
	multiselect?: boolean;
	calendar?: boolean;
	search?: boolean;
	autocomplete?: boolean; // duplicate of search?
	time?: boolean;

	inputChips?: (string | React.ReactNode)[];
}

export interface BaseInputProps {
	value?: string | null;
	defaultValue?: string;

	label?: string;
	placeholder?: string;

	className?: string;
	RightSideIcon?: JSX.Element;
	LeftSideIcon?: JSX.Element;
	loading?: boolean;
	disabled?: boolean;
	error?: boolean;
	errorText?: string;
	size?: SizesEnum;

	type?: OutlinedTextFieldProps["type"];

	info?: string;

	onFocus?: (e) => void;
	onClick?: (e) => void;
	onBlur?: (e) => void;

	mask?: string | (string | RegExp)[];

	id?: string;

	warning?: boolean;
	warningText?: string;
	minRows?: number;

	inputRef?: React.MutableRefObject<HTMLInputElement | HTMLTextAreaElement> | React.RefObject<HTMLInputElement>;
	name?: string;
	readOnly?: boolean;
	rightIconColor?: string;
}

const Input: FC<
	(InputProps | MaskInputProps | DropdownProps | DatepickerProps | TimepickerProps | SearchProps) & InputWrapperProps
> = memo(
	({
		headline,
		wrapClassName,
		dropdown,
		multiselect,
		autocomplete,
		search,
		calendar,
		time,
		value,
		inputChips,
		size = SizesEnum.MD,
		inputRef,
		onFocus,
		rightIconColor,
		...props
	}) => {
		const [isOpen, setIsOpen] = useState(false);
		const scrollToXPositionOnFocus = useScrollToXPosition(inputRef?.current?.form?.parentElement);

		const isCalenderWithTime = useMemo(
			() => (calendar || false) && ((props as DatepickerProps).includeTime || false),
			[calendar, props]
		);

		const isRegularInput =
			!dropdown && !multiselect && !autocomplete && (!calendar || isCalenderWithTime) && !time && !search;

		const handleFocus = useCallback(
			(e: React.FocusEvent<HTMLElement>) => {
				scrollToXPositionOnFocus(e);
				onFocus && onFocus(e);
			},
			[onFocus, scrollToXPositionOnFocus]
		);

		const formattedValue = useMemo(
			() =>
				value
					? DateTime.fromJSDate(new Date(value)).toFormat(
							(props as DatepickerProps).includeTime ? "MMM d, h:mm a" : "MMM d y"
					  )
					: "",
			[props, value]
		);

		return (
			<InputWrapper className={clsx("flex flex-col", wrapClassName)}>
				{headline && <Headline>{headline}</Headline>}
				{isRegularInput && (
					<>
						{props?.mask ? (
							<MaskInput
								{...(props as MaskInputProps)}
								onFocus={handleFocus}
								value={value || ""}
								size={size}
								hideBorders={!!inputChips?.length}
							/>
						) : (
							<InputBase
								{...(props as InputProps)}
								value={isCalenderWithTime ? formattedValue : value || ""}
								size={size}
								onFocus={handleFocus}
								hideBorders={!!inputChips?.length}
								onClick={() => isCalenderWithTime && setIsOpen(true)}
								RightSideIcon={
									isCalenderWithTime ? (
										<Icon
											height={20}
											width={20}
											name="calendar-day"
											fill={isOpen ? "#000000" : rightIconColor ? rightIconColor : "#c5cee0"}
											group="linear"
											className="mr-2"
										/>
									) : undefined
								}
								setIsOpen={setIsOpen}
							/>
						)}
					</>
				)}
				{dropdown && <Dropdown {...(props as DropdownProps)} value={value || ""} size={size} />}
				{calendar && (
					<>
						{((isOpen && isCalenderWithTime) || !isCalenderWithTime) && (
							<Datepicker
								{...(props as DatepickerProps)}
								size={size}
								value={value || ""}
								isOpen={isOpen}
								setIsOpen={setIsOpen}
							/>
						)}
					</>
				)}
				{time && <Timepicker {...(props as TimepickerProps)} size={size} value={value || ""} />}
				{search && <Search {...(props as SearchProps)} size={size} value={value} />}
			</InputWrapper>
		);
	}
);

export default Input;
