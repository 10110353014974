import { Grid, TextField } from "@material-ui/core";
import styled from "styled-components";

import { ReactComponent as CoinYellowIcon } from "assets/icons/iconCoinYellow.svg";
import { Box, Button, Text } from "shared/ui-kit";
import * as appTheme from "theme/default";

export const StyledMonetizationActionsContainer = styled(Box)`
	margin-top: 4px;
	max-width: 800px;
	.actions {
		display: flex;
		margin-top: 8px;

		${props => props.theme.breakpoints.down("sm")} {
			flex-direction: column;
			padding: 0 8px;
		}

		button {
			margin-left: 0;
			min-width: 0;
			&:last-child {
				flex: 1;
			}
		}
	}
	.more-tag button {
		padding: 0;
	}

	@media (min-width: 768px) {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	@media (min-width: 1280px) {
		.actions {
			margin-right: 65px;
		}
	}
`;

export const VideoInfo = styled(Text)`
	color: #8f9bb3;
	font-size: 13px;
	margin-bottom: 16px;
	width: 100%;
	padding-right: 10px;

	${props => props.theme.breakpoints.down("sm")} {
		width: 100%;
	}
`;

export const CoinsPrices = styled(Box)`
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin-top: 16px;
	.price-block {
		width: 48%;
		text-align: center;
		border: solid 1px #e4e9f2;
		padding: 12px 0;
		border-radius: 6px;
		margin-bottom: 12px;
		h2 {
			color: #2f3742;
			font-size: 13px;
		}
		h6 {
			color: #979ba1;
			font-size: 10px;
		}

		&:hover {
			box-shadow: 0px 2px 3px rgb(202 202 202 / 50%);
		}
	}

	${props => props.theme.breakpoints.up(appTheme.customBreakpoints.iPadPro)} {
		margin-top: 0;
		width: 50%;
		padding-left: 10px;
	}

	@media (min-width: 1280px) {
		width: 40%;
	}
`;

export const CancelButton = styled(Button)`
	margin-left: 380px;
	min-width: 152px;

	${props => props.theme.breakpoints.down("sm")} {
		margin-left: 0;
	}
`;

export const CreateTagsBtn = styled(Button)`
	margin-left: 10px;
	min-width: 152px;
`;

export const StyledCoinYellowIcon = styled(CoinYellowIcon)`
	width: 18px;
	height: 18px;
	${props => props.theme.breakpoints.down("sm")} {
		width: 16px;
		height: 16px;
	}
`;

export const InfoContainer = styled(Box)`
	background-color: #f7f9fc;
	max-width: 800px;
	margin-bottom: 40px;
	padding: 20px;
	border-radius: 4px;
	border: solid 1px #e4e9f2;

	${props => props.theme.breakpoints.down("sm")} {
		width: 100%;
		padding: 16px;
	}
`;

export const MainVidContainer = styled(Box)`
	width: 100%;
	display: flex;
	justify-content: space-between;

	${props => props.theme.breakpoints.down("sm")} {
		flex-direction: column;
	}
`;

export const CreatePriceTagHead = styled(Text)`
	color: #222b45;
	font-size: 18px;
	font-weight: bold;
	margin-bottom: 8px;
	${props => props.theme.breakpoints.down("sm")} {
		padding: 0 8px;
	}
`;

export const CreatePriceTagInfo = styled(Text)`
	color: #8f9bb3;
	font-size: 13px;
	font-weight: 600;
	margin-bottom: 16px;
	${props => props.theme.breakpoints.down("sm")} {
		padding: 0 8px;
	}
`;

export const PriceTagHeader = styled(Text)`
	font-size: 15px;
	font-weight: 600;
	color: #222b45;
	width: 66.66%;
	flex: 0 0 auto;
	margin-left: 12px;
	margin-bottom: 12px;

	&:first-child {
		margin-left: 0;
	}

	@media (min-width: 768px) {
		width: ${props => (props.primaryInput ? "260px" : "140px")};
		margin-left: 16px;
	}
`;

export const AddMoreTags = styled(Text)`
	color: #8f9bb3;
	font-size: 13px;
	font-weight: 600;
	margin-left: 12px;
	margin-top: 5px;
`;

export const PriceTagInput = styled(TextField)`
	margin-right: 12px;
	margin-bottom: 6px;
	width: 66.66%;
	flex: 0 0 auto;

	@media (min-width: 768px) {
		width: ${props => (props.primaryInput ? "260px" : "140px")};
		margin-right: 16px;
	}
`;

export const TableContainer = styled(Grid)`
	max-width: 800px;
	width: 100%;
	overflow-x: auto;
	padding: 4px 0;

	${props => props.theme.breakpoints.down("sm")} {
		padding: 4px 8px;
	}
`;

export const FlexContainer = styled(Box)`
	margin-bottom: 24px;
	display: flex;
	align-items: center;
`;

export const IconRow = styled(Box)`
	width: 130px;
	padding: 0 10px;

	cursor: pointer;

	${props => props.theme.breakpoints.down("sm")} {
		width: 110px;
		padding: 0 4px;
	}
`;

export const AddMore = styled(Button)`
	background: transparent;
	cursor: pointer;
	display: flex;

	&:hover {
		background: transparent;
	}
`;

export const HeaderInfo = styled(Text)`
	color: #8f9bb3;
	font-weight: 600;
	font-size: 16px;
	margin-right: auto;
`;

export const InfoBox = styled(Box)`
	width: 100%;

	${props => props.theme.breakpoints.up(appTheme.customBreakpoints.iPadPro)} {
		width: 50%;
	}

	${props => props.theme.breakpoints.up("lg")} {
		width: 60%;
	}
`;

export const TableGridContainer = styled(Grid)`
	flex-wrap: nowrap;
	-webkit-overflow-scrolling: touch;
	-ms-overflow-style: -ms-autohiding-scrollbar;
`;

export const UpdateCoinButton = styled(Button)`
	margin-left: 0;

	${props => props.theme.breakpoints.down("sm")} {
		width: 100%;
		margin-top: 10px;
	}
`;

export const BagImage = styled.img`
	height: 50px;
`;

export const BagCard = styled.div``;
