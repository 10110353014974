import React, { FC, MutableRefObject, RefObject, useCallback, useMemo, useRef } from "react";

import { ClickAwayListener, Popper } from "@material-ui/core";
import clsx from "clsx";
import { DateTime } from "luxon";

import { ReactComponent as ClockIcon } from "assets/icons/clock-filled.svg";

import { IconRenderer } from "shared/Components";

import colors from "theme/colors";

import { DropdownOptionModel } from "./Dropdown";

import DropdownOptions from "./DropdownOptions";
import MaskInput from "./MaskInput";

import { TimepickerIconBase, TimepickerWrapper, UiKitDropdownOptionPaper } from "./style";

import { times } from "../../types/times";

import { BaseInputProps } from "./index";

export interface TimepickerProps extends Omit<BaseInputProps, "value"> {
	value: string | Date;
	onChange: (params: string | Date) => void;
	minTime?: any;
}

const Timepicker: FC<TimepickerProps> = ({ onChange, value, disabled, inputRef, ...props }) => {
	const [anchorEl, setAnchorEl] = React.useState(null);

	const open = Boolean(anchorEl);

	const handleClick = event => {
		!disabled && setAnchorEl(anchorEl ? null : event.currentTarget);
	};

	const handleClickAway = useCallback(() => {
		open && setAnchorEl(null);
	}, [open]);

	const handleInputChange = useCallback(
		(params: React.ChangeEvent<HTMLInputElement>) => {
			if (params?.target?.value !== undefined) {
				const value = params.target.value;

				let newDateTime: DateTime | undefined;

				if (
					value &&
					value.replace(/[^0-9pPmMaA]/g, "")?.length === 6 &&
					(value.toLowerCase().includes("am") || value.toLowerCase().includes("pm"))
				) {
					const timeValue = value.toLowerCase();
					const isAm = timeValue.includes("am");
					const timeOnly = timeValue.replace(/[^\d:]/g, "");
					const timeSplit = timeOnly.split(":");
					const enteredHours = !isNaN(Number(timeSplit[0])) ? Number(timeSplit[0]) : undefined;
					if (enteredHours) {
						const hours =
							enteredHours === 12 && isAm ? 0 : Number(timeSplit[0]) + (isAm ? 0 : enteredHours === 12 ? 0 : 12);
						const minutes = !isNaN(Number(timeSplit[1])) ? Number(timeSplit[1]) : undefined;
						if (hours !== undefined && minutes !== undefined) {
							const currentDateTime = DateTime.now();
							newDateTime = currentDateTime.set({ hour: hours, minute: minutes });
						}
					}
				}

				handleClickAway();
				onChange && onChange(newDateTime?.toJSDate ? newDateTime?.toJSDate() : value?.toUpperCase());
			}
		},
		[onChange, handleClickAway]
	);

	const handleOptionChange = useCallback(
		(option: DropdownOptionModel) => {
			let newDateTime: DateTime | undefined;
			if (option?.value && option.value.includes(":")) {
				const timeSplit = option.value.split(":");
				const currentDateTime = DateTime.now();
				newDateTime = currentDateTime.set({ hour: timeSplit[0], minute: timeSplit[1] });
			}

			handleClickAway();
			onChange && onChange(newDateTime?.toJSDate ? newDateTime?.toJSDate() : option?.label?.toUpperCase());
		},
		[onChange, handleClickAway]
	);

	const correctValue = useMemo(() => {
		let luxonVal = DateTime.fromJSDate(value as Date);
		if (luxonVal?.invalidReason) {
			luxonVal = DateTime.fromISO(value as string);
		}
		if (!luxonVal?.invalidReason) {
			return luxonVal.toFormat("hh:mm a");
		}
		return typeof value === "string" ? value : value.toString();
	}, [value]);

	const wrapperRef = useRef<HTMLDivElement>(null);

	return (
		<ClickAwayListener onClickAway={handleClickAway}>
			<TimepickerWrapper onClick={handleClick} className={clsx(disabled && "disabled")} ref={wrapperRef}>
				<MaskInput
					{...props}
					disabled={disabled}
					value={correctValue}
					onChange={handleInputChange}
					inputRef={inputRef as RefObject<HTMLInputElement> | MutableRefObject<HTMLInputElement> | undefined}
					mask={"99:99 aa"}
					RightSideIcon={
						<TimepickerIconBase>
							<IconRenderer fillColor={colors.palette.colors.gray[open ? "800" : "500"]}>
								<ClockIcon />
							</IconRenderer>
						</TimepickerIconBase>
					}
				/>
				<Popper
					open={open}
					placement="bottom"
					anchorEl={anchorEl}
					disablePortal={false}
					style={{
						zIndex: 100000,
						marginTop: 4,
						width: wrapperRef.current?.clientWidth ? wrapperRef.current?.clientWidth : undefined
					}}
					modifiers={{
						flip: {
							enabled: true
						},
						preventOverflow: {
							enabled: true,
							boundariesElement: "scrollParent"
						}
					}}
				>
					<UiKitDropdownOptionPaper>
						<DropdownOptions options={times} onSelect={handleOptionChange} />
					</UiKitDropdownOptionPaper>
				</Popper>
			</TimepickerWrapper>
		</ClickAwayListener>
	);
};

export default Timepicker;
