import React from "react";

import Skeleton from "@material-ui/lab/Skeleton";

import { PostSkeletonWrapper } from "../../Containers/Home/style";

const PostSkeleton = () => {
	return (
		<PostSkeletonWrapper>
			<PostSkeletonWrapper.Header>
				<Skeleton variant="circle" animation="wave" width={48} height={48} />
				<PostSkeletonWrapper.HeaderInfo>
					<PostSkeletonWrapper.HeaderAuthorRow>
						<Skeleton variant="text" animation="wave" width={100} height={25} />
						<PostSkeletonWrapper.HeaderCategory>
							<Skeleton variant="text" animation="wave" width={75} height={30} />
						</PostSkeletonWrapper.HeaderCategory>
					</PostSkeletonWrapper.HeaderAuthorRow>
					<Skeleton variant="text" animation="wave" width={75} height={25} />
				</PostSkeletonWrapper.HeaderInfo>
			</PostSkeletonWrapper.Header>
			<PostSkeletonWrapper.Body>
				<Skeleton variant="text" animation="wave" width="100%" height={30} />
				<Skeleton variant="text" animation="wave" width="100%" height={30} />
				<Skeleton variant="text" animation="wave" width="100%" height={30} />
				<Skeleton variant="text" animation="wave" width="100%" height={30} />
			</PostSkeletonWrapper.Body>
			<PostSkeletonWrapper.Footer>
				<Skeleton variant="text" animation="wave" width="40%" height={50} />
				<Skeleton variant="text" animation="wave" width="40%" height={50} />
			</PostSkeletonWrapper.Footer>
		</PostSkeletonWrapper>
	);
};

export default PostSkeleton;
