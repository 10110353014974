import styled from "styled-components";

import { Box } from "shared/ui-kit";

export const Wrapper = styled(Box)`
	width: 960px;
	margin: 0 auto 15px auto;

	display: flex;
	flex-direction: column;

	${props => props.theme.breakpoints.down("sm")} {
		padding: 0 8px;
		width: 100%;
	}
`;
