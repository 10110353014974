import React, { FC } from "react";

import { Link } from "react-router-dom";

import { Text } from "shared/ui-kit";

import { StyledButton, Wrapper } from "./style";

const SectionHeadline: FC<{ title: string; actionButton?: { text: string; onClick: () => void; link?: string } }> = ({
	title,
	actionButton
}) => {
	const optionalProps: Record<string, unknown> = {};
	if (actionButton?.link) {
		optionalProps.to = actionButton.link;
		optionalProps.component = Link;
	}

	return (
		<Wrapper>
			<Text variant="h5">{title}</Text>
			{actionButton && (
				<StyledButton
					{...optionalProps}
					size="medium"
					palette="basic"
					buttonTheme="light"
					onClick={actionButton.onClick}
				>
					{actionButton.text}
				</StyledButton>
			)}
		</Wrapper>
	);
};

export default SectionHeadline;
