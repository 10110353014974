import { Quill } from "react-quill";

const Inline = Quill.import("blots/inline");

class AppInlineEmbed extends Inline {
	static create(value) {
		const node = super.create(value);
		node.setAttribute("contenteditable", "false");

		node.innerHTML = this.transformValue(value);
		return node;
	}

	static transformValue(value) {
		let handleArr = value.split("\n");
		handleArr = handleArr.map(e => e.replace(/^[\s]+/, "").replace(/[\s]+$/, ""));
		return handleArr.join("");
	}
}

AppInlineEmbed.blotName = "AppInlineEmbed";

export default AppInlineEmbed;
