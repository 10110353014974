import React, { memo, useCallback, useState } from "react";

import { Box, Button, FormControl, Menu, MenuItem } from "@material-ui/core";

import { Checkbox, Icon, Text } from "shared/ui-kit";

import { FilterText, IconTextWrapper, StyledButton, StyledFormFooter } from "./style";

interface StatusFilterProps {
	onFilterChange: (options: string[]) => void;
}

interface FilterOption {
	label: string;
	value: string;
	checked: boolean;
	tempChecked: boolean;
}

export const userStatuses: FilterOption[] = [
	{
		label: "Pending",
		value: "PENDING",
		checked: false,
		tempChecked: false
	},
	{
		label: "Joined",
		value: "JOINED",
		checked: false,
		tempChecked: false
	},
	{
		label: "Onboarded",
		value: "ON_BOARDED",
		checked: false,
		tempChecked: false
	},
	{
		label: "Deactivated",
		value: "ARCHIVE",
		checked: false,
		tempChecked: false
	},
	{
		label: "Paid",
		value: "PAID_SUBSCRIPTION",
		checked: false,
		tempChecked: false
	},
	{
		label: "Banned",
		value: "BANNED",
		checked: false,
		tempChecked: false
	},
	{
		label: "Deleted",
		value: "DELETED",
		checked: false,
		tempChecked: false
	}
];

export const getStatusByValue = (value: string): FilterOption => {
	return userStatuses.find(status => status.value === value) as FilterOption;
};

const _StatusFilter: React.FC<StatusFilterProps> = ({ onFilterChange }) => {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [filterMemberOptions, setFilterMemberOptions] = useState<FilterOption[]>(userStatuses);
	const isFiltered: boolean = filterMemberOptions.findIndex(option => option.checked) > -1;

	const updateFilterValues = useCallback(
		(tempToCheck = true): FilterOption[] => {
			if (tempToCheck) {
				const options = filterMemberOptions.map(option => ({ ...option, checked: option.tempChecked }));
				setFilterMemberOptions(options);
				return options;
			} else {
				setFilterMemberOptions(filterMemberOptions.map(option => ({ ...option, tempChecked: option.checked })));
				return [];
			}
		},
		[filterMemberOptions]
	);

	const onDoneFilter = useCallback(() => {
		setAnchorEl(null);
		const options: FilterOption[] = updateFilterValues(true);
		onFilterChange(options.map(option => (option.checked ? option.value : "")).filter(option => !!option));
	}, [onFilterChange, updateFilterValues]);

	const onCancelFilter = useCallback(() => {
		setAnchorEl(null);
		updateFilterValues(false);
	}, [updateFilterValues]);

	const openFilter = useCallback(
		e => {
			setAnchorEl(e.currentTarget);
			updateFilterValues(false);
		},
		[updateFilterValues]
	);

	const handleFilterCheckboxChange = (value: string) => {
		setFilterMemberOptions(
			filterMemberOptions.map(option => ({
				...option,
				tempChecked: option.value === value ? !option.tempChecked : option.tempChecked
			}))
		);
	};

	return (
		<FormControl component={Box}>
			<Button
				aria-controls="customized-menu"
				aria-haspopup="true"
				variant="outlined"
				color="secondary"
				onClick={openFilter}
				style={{ padding: "11px", borderColor: "#e4e9f2" }}
			>
				<IconTextWrapper>
					<Icon fill="#c5cee0" name="list-bullet" />
					<Text>{isFiltered ? "Filtered" : "All Members"}</Text>
				</IconTextWrapper>
			</Button>
			<Menu id="customized-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)}>
				{filterMemberOptions.map((item, index) => (
					<MenuItem
						key={index}
						className="menuItem"
						value={item.value}
						onClick={handleFilterCheckboxChange.bind(null, item.value)}
					>
						<FilterText>{item.label}</FilterText>
						<Checkbox checked={item.tempChecked} />
					</MenuItem>
				))}
				<StyledFormFooter textAlign="center">
					<StyledButton onClick={onCancelFilter} transparent size="small">
						Cancel
					</StyledButton>
					<StyledButton buttonTheme="main" onClick={onDoneFilter} palette="primary" size="small">
						Done
					</StyledButton>
				</StyledFormFooter>
			</Menu>
		</FormControl>
	);
};

export const StatusFilter = memo(_StatusFilter);
