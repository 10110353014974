import React, { FC, useCallback, useEffect, useState } from "react";

import { useGroup } from "shared/hooks";

import { GroupModel } from "shared/types";

import { ModelBlockType } from "../../../ModelBlock";
import { GroupSectionTemplate } from "../../components";

interface SubGroupsProps {
	groupId?: string;
	handleActionButton: () => void;
	canCreate?: boolean;
	subGroups: GroupModel[];
	setSubGroups: React.Dispatch<React.SetStateAction<GroupModel[]>>;
	totalSubGroups?: number;
}

const SubGroups: FC<SubGroupsProps> = ({
	groupId,
	handleActionButton,
	canCreate,
	subGroups,
	setSubGroups,
	totalSubGroups = 20
}) => {
	const { getGroups, getData: getGroupData } = useGroup();
	const { loading } = getGroupData();

	const [offset, setOffset] = useState(1);

	useEffect(() => {
		if (!groupId) return;
		getGroups({ page: offset, limit: 20, parentId: groupId }).then(({ groups }) => {
			setSubGroups(subgroups => (offset === 1 ? groups : subgroups.concat(groups)));
		});
	}, [getGroups, groupId, setSubGroups, offset]);

	const handleScrollMore = useCallback(() => {
		if (!loading && subGroups?.length < totalSubGroups) {
			setOffset(of => of + 1);
		}
	}, [totalSubGroups, subGroups?.length, loading]);

	if (!groupId) return null;

	return (
		<GroupSectionTemplate
			autoFit={subGroups.length > 2 ? 300 : undefined}
			items={subGroups || []}
			type={ModelBlockType.group}
			actionButton={canCreate ? { title: "Create Sub-group", onClick: handleActionButton } : undefined}
			loading={loading}
			title="Sub-groups"
			noItemsErrorText="This group don’t have any sub-groups yet."
			onEndScroll={handleScrollMore}
			keepCurrentData
		/>
	);
};

export default SubGroups;
