import React, { useCallback, useEffect, useState } from "react";

import { Scrollbars } from "react-custom-scrollbars";

import { Dialog, SkeletonBase } from "shared/Components";
import { useDebounce } from "shared/hooks";
import { SearchUsersModel } from "shared/types";
import { Search } from "shared/ui-kit";

import Member from "./Member";
import { Connection, ConnectionItemWrapper, ConnectionList, SearchWrapper, Wrapper } from "./style";

interface InviteMembersModalProps {
	title?: string;
	show: boolean;
	onHide: () => void;
	members: SearchUsersModel[];
	onChangeKeyword: (keyword: string) => void;
	loading: boolean;
	onLoadMore: () => void;
	onInvite: (personaIds: number[]) => void;
	entityText?: string;
}

const InviteMembersModal: React.FC<InviteMembersModalProps> = ({
	title,
	show,
	onHide,
	members,
	onChangeKeyword,
	loading,
	onLoadMore,
	onInvite,
	entityText
}) => {
	const [keyword, setKeyword] = useState("");

	const [selected, setSelected] = useState<number[]>([]);

	const debouncedKeyword = useDebounce(keyword, 400);

	useEffect(() => {
		onChangeKeyword(debouncedKeyword || "");
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [debouncedKeyword]);

	const deselectAll = () => {
		setSelected([]);
	};

	const onChangeSelected = useCallback((personaId: number, isChecked = false) => {
		if (isChecked) {
			setSelected(s => [...s, personaId]);
		} else {
			setSelected(s => s.filter(_id => _id !== personaId));
		}
	}, []);

	const MemberListItem = useCallback(
		({ index, style, data }) => {
			const isSkeleton = !data?.length || index >= data?.length;
			const member = !isSkeleton ? data[index] : undefined;
			const isSelected = member?.personaId ? selected.includes(member?.personaId) : false;

			return isSkeleton ? (
				<ConnectionItemWrapper style={style} key={index} className={"skeleton-loader"}>
					<Connection>
						<Connection.Base>
							<Connection.AvatarWrapper>
								<SkeletonBase width={32} height={32} fullScale />
							</Connection.AvatarWrapper>
							<SkeletonBase width={100} height={24} />
						</Connection.Base>
						<Connection.Action>
							<SkeletonBase width={18} height={18} fullScale />
						</Connection.Action>
					</Connection>
				</ConnectionItemWrapper>
			) : (
				<ConnectionItemWrapper style={style} key={member?.personaId}>
					<Member member={member} isSelected={isSelected} onToggleChecked={onChangeSelected} entityText={entityText} />
				</ConnectionItemWrapper>
			);
		},
		[selected, onChangeSelected, entityText]
	);

	const SelectedListItem = useCallback(
		({ index, style, data }) => {
			const member = members.find(({ personaId }) => personaId === data[index]);

			return member ? (
				<ConnectionItemWrapper style={style} key={index} className="checked">
					<Member member={member} isChecked onRemove={onChangeSelected} />
				</ConnectionItemWrapper>
			) : null;
		},
		[onChangeSelected, members]
	);

	const sendInvitation = useCallback(() => {
		if (!selected.length) return;
		onInvite(selected);
		setSelected([]);
		onHide();
	}, [selected, onHide, onInvite]);

	const updateKeyword = useCallback(e => {
		setKeyword(e.target.value);
	}, []);

	const scrollCheck = useCallback(
		event => {
			const bottom = event.target.scrollHeight - event.target.scrollTop === event.target.clientHeight;
			if (bottom && !loading) {
				onLoadMore();
			}
		},
		[onLoadMore, loading]
	);

	const selectedCount = selected.length;

	return (
		<Dialog
			title={title || "Invite"}
			open={show}
			onClose={onHide}
			bodyCustomStyles="padding: 0; overflow-x: hidden;"
			footer={
				<Wrapper.Footer>
					<Wrapper.ActionBtn
						onClick={sendInvitation}
						disabled={!selectedCount}
						buttonTheme="main"
						palette="primary"
						wide
						removeSideMargin
					>
						Invite {selectedCount ? selectedCount : ""}
					</Wrapper.ActionBtn>
				</Wrapper.Footer>
			}
		>
			<Wrapper>
				<Wrapper.SearchBox>
					<SearchWrapper>
						<Search
							autoComplete="off"
							defaultValue={keyword}
							onChange={updateKeyword}
							theme={"outline-dark"}
							fullWidth
						/>
					</SearchWrapper>
					<ConnectionList.Wrapper onScroll={scrollCheck}>
						<ConnectionList
							height={328}
							itemCount={loading ? members.length + 5 : members.length}
							itemSize={48}
							itemData={members}
							outerElementType={Scrollbars}
						>
							{MemberListItem}
						</ConnectionList>
					</ConnectionList.Wrapper>
				</Wrapper.SearchBox>
				<Wrapper.TableBox>
					<Wrapper.SelectedHeaderRow>
						<Wrapper.SelectedCounter>{selected.length} selected</Wrapper.SelectedCounter>
						<Wrapper.DeselectAll onClick={deselectAll} size="small" buttonTheme="ghost" palette="basic">
							Unselect all
						</Wrapper.DeselectAll>
					</Wrapper.SelectedHeaderRow>
					<ConnectionList.Wrapper className={"selected"}>
						<ConnectionList height={354} itemCount={selectedCount} itemSize={48} itemData={selected}>
							{SelectedListItem}
						</ConnectionList>
					</ConnectionList.Wrapper>
				</Wrapper.TableBox>
			</Wrapper>
		</Dialog>
	);
};

export default InviteMembersModal;
