import { ButtonBase } from "@material-ui/core";
import styled, { css } from "styled-components";

import { Box } from "shared/ui-kit";

export const Wrapper = styled(Box)`
	padding: 0 16px;
	max-width: 1200px;
	${props => props.theme.breakpoints.up("md")} {
		padding: 0;
		margin: auto;
		display: flex;
		align-items: flex-start;
	}
`;

Wrapper.CollectionItems = styled(Box)`
	overflow-y: auto;
	flex: 1;
	padding: 0 8px;
`;

Wrapper.SeriesContent = styled(Box)`
	width: 100%;
	max-width: 400px;
	margin: 16px 0;
	max-height: 580px;
	border-radius: 12px;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12), inset 0 0 0 0.5px #c5cee0;
	background-color: #fff;
	padding-top: 12px;
	display: flex;
	flex-direction: column;
	h3 {
		font-size: 15px;
		font-weight: 600;
		color: #222b45;
		&.header {
			margin: 0 16px;
		}
	}
	.series-summary {
		font-size: 13px;
		color: #8f9bb3;
		margin: 2px 16px 0;
	}

	${props => props.theme.breakpoints.up("md")} {
		margin: 0;
	}
`;

Wrapper.CurrentPlaying = styled(Box)`
	flex: 1;

	${props => props.theme.breakpoints.up("sm")} {
		margin-right: 16px;
	}
`;

Wrapper.HR = styled(Box)`
	height: 1px;
	box-shadow: inset 0 0 0 0.5px #c5cee0;
	margin-top: 8px;
	margin-bottom: 8px;
`;

Wrapper.Item = styled(ButtonBase)`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	text-align: left;
	border-radius: 8px;
	margin-bottom: 8px;
	> div {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		border-radius: 8px;
		margin-bottom: 0;
	}

	h3 {
		width: 90%;
	}

	${({ current }) =>
		current &&
		css`
			background-color: #f7f9fc;
		`}
`;
