import Radio from "@material-ui/core/Radio";
import styled from "styled-components";

import { SettingsActionButton } from "../commonStyles";

const Option = styled.div`
	margin-bottom: 16px;
	border-radius: 12px;
	border: 1px solid #e4e9f2;
	display: flex;
	padding: 24px 64px 24px 20px;
`;

const OptionRadioWrapper = styled.div``;

const OptionRadio = styled(Radio)<{ disabled?: boolean }>`
	&.MuiRadio-root {
		color: ${({ disabled }) => (disabled ? "#a9b0b9" : "#222b45")};

		&:hover {
			background: none;
		}
	}
`;

const OptionInfo = styled.div`
	margin-left: 10px;
`;

const OptionTitle = styled.div`
	font-size: 15px;
`;

const OptionDescription = styled.div`
	font-size: 13px;
	color: #8f9bb3;
	margin-top: 8px;
`;

const SubmitButton = styled(SettingsActionButton)`
	margin-left: auto;
	margin-top: auto;
`;

export { Option, OptionTitle, OptionRadioWrapper, OptionRadio, OptionDescription, OptionInfo, SubmitButton };
