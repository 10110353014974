import { ButtonBase } from "@material-ui/core";
import styled from "styled-components";

export const UploaderWrapper = styled.div`
	height: 246px;
	width: 100%;

	${props => props.theme.breakpoints.up("sm")} {
		width: 246px;
	}

	&.full-w {
		width: 100%;
	}
	.preview-section {
		display: flex;
		flex-direction: column;
		.item-description {
			width: 90%;
			max-width: none;
			padding: 0;
		}
	}
	> .uploader-wrapper {
		height: 100%;
		.explorer-uploader {
			padding: 0;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			.description .anchor {
				color: #6173fe !important;
			}
			.icon {
				margin: 0;
				margin-bottom: 16px;
			}
			.uploader-content {
				padding: 0 24px;
				width: 100%;
				max-width: none;
				div {
					text-align: center;
				}
			}
			.accept-extension {
				display: none;
			}
		}
	}
`;

export const CloseButton = styled(ButtonBase)`
	background-color: #919db4;
	svg {
		fill: #ffffff;
		width: 14px;
		height: 14px;
	}
`;
