import { useMemo } from "react";

import BaseConverter from "shared/services/converters/baseConverter";

import useHttpActions from "../../api/core/useHttpActions";

import { API_URLS } from "../constants";
import { SignedUrlResponse, UploadedFileResponse } from "../types";

const abortControllerList: { refId?: string; controller: AbortController }[] = [];

const useS3UploaderApiService = () => {
	const actions = useHttpActions();

	return useMemo(
		() => ({
			getSignedUrl: async (
				name: string,
				type: string,
				communityName: string,
				noBucket: boolean
			): Promise<SignedUrlResponse> => {
				return BaseConverter.convert<SignedUrlResponse>(
					await actions.get(API_URLS.UPLOADER.GET_SIGNED_URL(name, type, communityName, noBucket))
				);
			},
			uploadFile: async (
				url: string,
				file: File,
				type: string,
				onUploadProgress?: (percentage: number) => void,
				refId?: string
			): Promise<UploadedFileResponse> => {
				const controller = new AbortController();
				abortControllerList.push({
					refId,
					controller
				});
				return BaseConverter.convert<UploadedFileResponse>(
					await actions.putOriginal(url, file, null, {
						headers: {
							"content-type": type
						},
						onUploadProgress: progressEvent => {
							onUploadProgress && onUploadProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total));
						},
						signal: controller.signal
					})
				);
			},
			abortRequests() {
				abortControllerList.forEach(({ controller }) => {
					controller.abort();
				});
			},
			aboutRequestsById(ids: string[]) {
				abortControllerList.forEach(c => {
					if (ids.some(id => id === c.refId)) {
						c.controller.abort();
					}
				});
			}
		}),
		[actions]
	);
};

export default useS3UploaderApiService;
