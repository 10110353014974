import React, { FC } from "react";

import { useMessaging } from "modules/Messaging/Data";
import { ChatPanel, InboxPanel } from "modules/Messaging/View/Components";
import { ChatWrapper, ContentWrapper, InboxPanelWrapper } from "modules/Messaging/View/Containers/Messaging/styles";
import { MessagingLayout } from "shared/Components";
import { NotFound } from "shared/Errorpages";
import { useFeature, useUser } from "shared/hooks";

const MessagingPage: FC<{ messageTo?: string; chatId?: string }> = ({ messageTo, chatId }) => {
	const { isFeatureEnabled } = useFeature();
	const { getData: getMessagingData } = useMessaging();
	const { activePanel } = getMessagingData();

	const { getData: getUserData } = useUser();
	const { isMemberView } = getUserData();

	if (!isFeatureEnabled("enableChat")) return <NotFound />;

	return (
		<MessagingLayout>
			<ContentWrapper container spacing={0} className={isMemberView && "rounded-corners"}>
				<InboxPanelWrapper className={activePanel === "inbox" && "active_inbox"}>
					<InboxPanel messageTo={messageTo} chatId={chatId} />
				</InboxPanelWrapper>
				<ChatWrapper className={activePanel === "inbox" && "active_inbox"}>
					<ChatPanel />
				</ChatWrapper>
			</ContentWrapper>
		</MessagingLayout>
	);
};

export default MessagingPage;
