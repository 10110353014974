import Input from "@material-ui/core/Input";
import styled from "styled-components";

import { SettingsActionButton } from "../commonStyles";

const Wrapper = styled.div`
	margin-top: 16px;
	padding: 0 24px;
	padding-bottom: 12px;
	overflow: auto;
	flex: 1;
	display: flex;
	flex-direction: column;

	& > form {
		flex-direction: column;
		display: flex;
		flex: 1;
	}
`;

const FormField = styled.div`
	margin-bottom: 20px;

	&:last-child {
		margin-bottom: 0;
	}
`;

const FormLabel = styled.label`
	display: block;
	margin-bottom: 4px;
	font-size: 13px;
	font-weight: 600;
	color: #222b45;
`;

const BugDescription = styled(Input)`
	border-radius: 8px;
	border: 1px solid #e4e9f2;
	width: 100%;
	padding: 10px 16px;
`;

const Actions = styled.div`
	display: flex;
	margin-top: auto;
`;

const SubmitButton = styled(SettingsActionButton)`
	width: 72px;
	margin-left: auto;
`;

export { Wrapper, FormField, FormLabel, BugDescription, Actions, SubmitButton };
