import { useMemo } from "react";

import useHttpActions from "shared/services/api/core/useHttpActions";

import BaseConverter from "shared/services/converters/baseConverter";

import { EmailStateModel } from "shared/types";

import TeamListConvertor from "./convertors/TeamListConvertor";

import { API_URLS } from "../../constants";
import {
	CancelInviteTeamMember,
	DeleteTeamMemberType,
	EditRoleTeamMember,
	InviteTeamMember,
	ResendInviteTeamMember,
	TeamMembersModel,
	getTeamMembersDataType
} from "../types";

const useTeamListApiService = () => {
	const actions = useHttpActions();

	return useMemo(
		() => ({
			getTeamMembers: async (data: getTeamMembersDataType): Promise<TeamMembersModel[]> => {
				return TeamListConvertor.convertUsersResponse(await actions.post(API_URLS.TEAM_LIST.GET_TEAM_MEMBERS, data));
			},
			deleteTeamMember: async (ids: string[]): Promise<DeleteTeamMemberType> => {
				return BaseConverter.convert(
					await actions.delete(API_URLS.TEAM_LIST.DELETE_TEAM_MEMBER, { userId: null, ids })
				);
			},
			editRoleTeamMember: async (data: EditRoleTeamMember): Promise<EditRoleTeamMember> => {
				return BaseConverter.convert(await actions.put(API_URLS.TEAM_LIST.PUT_EDIT_ROLE_TEAM_MEMBER, data));
			},
			validateEmailTeamMember: async (emails: string[]): Promise<EmailStateModel[]> => {
				return BaseConverter.convert(
					await actions.post(API_URLS.TEAM_LIST.POST_VALIDATE_EMAIL_TEAM_MEMBER, { emails })
				);
			},
			cancelInviteTeamMember: async (userIds: string[]): Promise<CancelInviteTeamMember> => {
				return BaseConverter.convert(await actions.put(API_URLS.TEAM_LIST.PUT_CANCEL_INVITE_TEAM_MEMBER, { userIds }));
			},
			resendInviteTeamMember: async (userIds: string[]): Promise<ResendInviteTeamMember> => {
				return BaseConverter.convert(
					await actions.post(API_URLS.TEAM_LIST.POST_RESEND_INVITE_TEAM_MEMBER, { userIds })
				);
			},
			inviteTeamMember: async (data: {
				emails: (string | undefined)[] | undefined;
				role: any;
				slug: string;
			}): Promise<InviteTeamMember> => {
				return BaseConverter.convert(await actions.post(API_URLS.TEAM_LIST.POST_INVITE_TEAM_MEMBER, data));
			},
			getTotalTeamMemberCount: async (data): Promise<{ totalCount: number }> => {
				return BaseConverter.convert(await actions.post(API_URLS.TEAM_LIST.POST_MEMBER_COUNT, data));
			}
		}),
		[actions]
	);
};

export default useTeamListApiService;
