import { useCallback, useMemo } from "react";

import config from "config/appConfig";
import { useHistory } from "react-router";

import { useLocation } from "react-router-dom";

import { routes as authRoutes } from "apps/Auth/constants";
import { routesGenerator } from "apps/Member/constants";
import { routes as onboardingRoutes } from "apps/Onboarding/constants";
import { routes as adminRoutes } from "apps/RegularUser/constants";
import { useAuth } from "modules/App/Data";
import { useCommunity, useGTM } from "shared/hooks";
import useJwt from "shared/services/api/core/jwt";
import { IWorkspace, UserModel } from "shared/types";

import useUser from "./useUser";

import appConfig from "../../config/appConfig";
import { EnvApplicationNames } from "../../types";

const { GLOBAL_CONSTANTS } = config;

const useRoutes = () => {
	const history = useHistory();
	const location = useLocation();

	const { getWorkspaceDetails, getCheckList, resetStore: resetCommunityStore } = useCommunity();
	const { resetStore: resetUserStore, setUser, getData: getUserData } = useUser();
	const { globalUserId } = getUserData();

	const { getUser, login: authLogin, logOut, fetchRefreshToken } = useAuth();
	const jwtService = useJwt();
	const { addEvent } = useGTM();

	const isAdminMode = useMemo(() => !location.pathname.startsWith("/member"), [location]);

	const methods = useMemo(
		() => ({
			createNewCommunity(isSportsMode?: boolean) {
				addEvent({ Action: "Create Community", Label: "Create Community Initiate" });
				const workspace = localStorage.getItem(`${GLOBAL_CONSTANTS.LOCAL_STORAGE_PREFIX}workspace`);
				if (workspace) {
					for (const key in localStorage) {
						if (key.includes(GLOBAL_CONSTANTS.LOCAL_STORAGE_PREFIX ?? "")) {
							localStorage.removeItem(key);
						}
					}
					history.push(authRoutes.auth.signUp.getPath());
				} else {
					for (const key in localStorage) {
						const shouldRemove =
							key.includes(GLOBAL_CONSTANTS.LOCAL_STORAGE_PREFIX ?? "") &&
							!key.includes("token") &&
							!key.includes("UserData") &&
							!key.includes("AuthData") &&
							!key.includes("MarketingData");
						if (shouldRemove) {
							localStorage.removeItem(key);
						}
					}

					const token = localStorage.getItem(`${GLOBAL_CONSTANTS.LOCAL_STORAGE_PREFIX}token`);

					history.push(
						token
							? `${onboardingRoutes.onboarding.profile.getPath()}${isSportsMode ? "?sportsmode" : ""}`
							: authRoutes.auth.signUp.getPath()
					);
				}
			},
			sendToNewCommunityPage() {
				localStorage.removeItem(`${GLOBAL_CONSTANTS.LOCAL_STORAGE_PREFIX ?? ""}NewCommunityData`);
				const token = localStorage.getItem(`${GLOBAL_CONSTANTS.LOCAL_STORAGE_PREFIX}token`);
				history.push(token ? onboardingRoutes.onboarding.profile.getPath() : authRoutes.auth.signUp.getPath());
			}
		}),
		[history, addEvent]
	);

	const loginToCommunity = useCallback(
		async (workspace: IWorkspace, isSwitching = false) => {
			const isWhiteLabel =
				appConfig.GLOBAL_CONSTANTS.ENV_APPLICATION_NAME === EnvApplicationNames.Akina ||
				appConfig.GLOBAL_CONSTANTS.ENV_APPLICATION_NAME === EnvApplicationNames.Relias ||
				appConfig.GLOBAL_CONSTANTS.ENV_APPLICATION_NAME === EnvApplicationNames.Bitcoin;

			const memberRoutes = routesGenerator(workspace.workspace, isWhiteLabel);

			const currentToken = jwtService.getJwt();
			const currentCommunity = jwtService.getWorkspace();

			const isAdminView = isSwitching && isAdminMode;

			await authLogin(workspace.token, workspace.workspace);
			try {
				if (jwtService.getJwt()) {
					const user = await getUser();
					for (const key in localStorage) {
						const shouldRemove =
							key.includes(GLOBAL_CONSTANTS.LOCAL_STORAGE_PREFIX ?? "") &&
							!key.includes("token") &&
							!key.includes("workspace") &&
							!key.includes("UserData");
						if (shouldRemove) {
							localStorage.removeItem(key);
						}
					}

					setUser(user);
					getWorkspaceDetails();
					getCheckList(globalUserId as string);

					history.push(
						isAdminView && (user.isGlobalAdmin || user.isGlobalModerator || user.isGlobalOwner)
							? adminRoutes.dashboard.getPath()
							: memberRoutes?.member.home.getPath()
					);
				}
			} catch (error) {
				const errorMessage = (error as Error).message;
				console.log(errorMessage);
				await authLogin(`${currentToken}`, `${currentCommunity}`);
			}
		},
		[jwtService, isAdminMode, authLogin, getUser, setUser, history, getWorkspaceDetails, getCheckList, globalUserId]
	);

	const clearStorage = useCallback(() => {
		resetCommunityStore();
		resetUserStore();

		for (const key in localStorage) {
			if (key.includes(GLOBAL_CONSTANTS.LOCAL_STORAGE_PREFIX ?? "")) {
				localStorage.removeItem(key);
			}
		}
	}, [resetUserStore, resetCommunityStore]);

	const removeStorageByKey = useCallback((key: string) => {
		localStorage.removeItem(key);
	}, []);

	const logout = useCallback(async () => {
		try {
			await logOut();
		} catch {
		} finally {
			clearStorage();
			history.push(authRoutes.auth.signIn.getPath());
			if (config.GLOBAL_CONSTANTS.THIRD_PARTY_SOURCE_PAGE_URL) {
				window.location.href = config.GLOBAL_CONSTANTS.THIRD_PARTY_SOURCE_PAGE_URL;
			}
		}
	}, [clearStorage, history, logOut]);

	const refreshTokenIfExpired = useCallback(
		async (data: UserModel, workspace: string) => {
			if (!data.refreshToken) {
				logout();
			} else if ((data.tokenExpiresInDays || 0) <= 2) {
				const { token } = await fetchRefreshToken(data.refreshToken);
				jwtService.setJwt(token, workspace);
			}
		},
		[fetchRefreshToken, jwtService, logout]
	);

	return { ...methods, clearStorage, logout, loginToCommunity, refreshTokenIfExpired, removeStorageByKey };
};

export default useRoutes;
