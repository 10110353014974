export const GeneralSettingsItems = [
	{
		title: "Community name",
		content: "Community name",
		id: 1,
		expandId: "expandCommunityName"
	},
	{
		title: "Community logo",
		content: "Community logo",
		id: 2,
		expandId: "expandCommunityLogo"
	},
	{
		title: "Theme selection",
		content: "Theme selection",
		id: 3,
		expandId: "expandThemeSelection"
	},
	{
		title: "Community type",
		content: "Community type",
		id: 4,
		expandId: "expandCommunityType"
	},
	{
		title: "Community privacy",
		content: "Community privacy",
		id: 5,
		expandId: "expandCommunityPrivacy"
	},
	{
		title: "18+ content",
		content: "18+ content",
		id: 6,
		expandId: "expand18Content"
	},
	{
		title: "Community page",
		content: "Community page",
		id: 7,
		expandId: "expandCommunityPage"
	},
	{
		title: "Community Coins & bundles",
		content: "Community Coins & bundles",
		id: 8,
		expandId: "expandCommunityCoins"
	}
];

export const AdditionalBtcGSItems = [
	{
		title: "BTC Metrics Sponsor & BTC Shop",
		content: "BTC Metrics Sponsor & BTC Shop",
		id: 9,
		expandId: "expandBTCMSponsor"
	}
];
