import React, { memo, useCallback, useEffect, useMemo, useState } from "react";

import { DateTime } from "luxon";

import { MenuDots, PlaceholderImage, TableWrapper } from "shared/Components";
import { TableColumnLoaderType } from "shared/Components/NewTable/Components";
import { Cell } from "shared/Components/NewTable/style";
import { useBoards, useDebounce } from "shared/hooks";
import { CategoryModel, PlaceholderImageType } from "shared/types";
import { formatCount } from "utils/serviceUtils/helpers";

import { ConfirmDelete, PageTemplate } from "../../Components";
import { boardIcons } from "../../Components/SelectIconField";
import { whoCanPost } from "../../Components/WhoCanPostField";
import CreateUpdateBoardModal from "../CreateUpdateBoardModal";

const Table = React.lazy(() => import("shared/Components/NewTable"));

const ManageBoards: React.FC = memo(() => {
	const {
		getBoards,
		getDefaultBoard,
		getBoardsCount,
		setPage,
		setBoardsPerPage,
		deleteBoard,
		getData: getBoardsData
	} = useBoards();
	const { boards, boardsCount, page, boardsPerPage, isLoading } = getBoardsData();

	const [searchText, setSearchText] = useState<string>("");
	const [open, setOpen] = useState(false);
	const [boardToDelete, setBoardToDelete] = useState<CategoryModel | null>(null);
	const [boardToUpdate, setBoardToUpdate] = useState<CategoryModel>();

	const debouncedSearchText = useDebounce(searchText, 400);

	useEffect(() => {
		getBoards({ keyword: debouncedSearchText, limit: boardsPerPage, offset: page });
	}, [debouncedSearchText, getBoards, boardsPerPage, page]);

	useEffect(() => {
		getBoardsCount({ keyword: debouncedSearchText });
		getDefaultBoard({});
	}, [debouncedSearchText, getBoardsCount, getDefaultBoard]);

	useEffect(() => {
		setPage(1);
	}, [setPage]);

	const handleSearch = useCallback((val: string) => {
		if (val === "" || val.length > 2) {
			setSearchText(val);
		}
	}, []);

	const getOptions = useCallback(
		(rowData: CategoryModel) => {
			const ar = [
				{
					id: "editBoard",
					name: "Edit",
					onClick: () => {
						setBoardToUpdate(rowData);
					},
					value: 1,
					comp: ""
				}
			];
			if ((boards?.length ?? 0) > 1) {
				ar.push({
					id: "deleteBoard",
					name: "Delete",
					onClick: async () => {
						setBoardToDelete(rowData);
					},
					value: 2,
					comp: ""
				});
			}
			return ar;
		},
		[boards]
	);

	const tableColumns = useMemo(
		() => [
			{
				width: 400,
				alignment: "left",
				label: <Cell.HeaderText>{formatCount(boardsCount as number, "Board")}</Cell.HeaderText>,
				Cell: ({ rowData: { name, iconName } }: { rowData: CategoryModel }) => {
					const icon = boardIcons.find(boardIcon => boardIcon.value === iconName)?.icon;
					return (
						<Cell.Wrapper>
							<Cell.ImageWrapper>
								{icon ? (
									<Cell.Icon>{icon}</Cell.Icon>
								) : (
									<PlaceholderImage
										type={PlaceholderImageType.BOARD_PREVIEW}
										width={40}
										height={40}
										viewBox={"0 0 400 400"}
									/>
								)}
							</Cell.ImageWrapper>
							<Cell.Wrapper className="column with-image">
								<Cell.Text>{name}</Cell.Text>
							</Cell.Wrapper>
						</Cell.Wrapper>
					);
				},
				loaderTemplate: TableColumnLoaderType.imageWthOneTextRow,
				dataKey: "cid"
			},
			{
				alignment: "left",
				minWidth: 160,
				label: <Cell.HeaderText>Who can post</Cell.HeaderText>,
				Cell: ({ rowData: { contributorLevel } }: { rowData: CategoryModel }) => (
					<Cell.Wrapper>{whoCanPost.find(icon => icon.value === contributorLevel)?.icon}</Cell.Wrapper>
				),
				dataKey: "cid"
			},
			{
				alignment: "left",
				minWidth: 100,
				label: <Cell.HeaderText>Posts</Cell.HeaderText>,
				Cell: ({ rowData: { totalTopicCount } }: { rowData: CategoryModel }) => (
					<Cell.Wrapper>
						<Cell.Text>{totalTopicCount ?? 0}</Cell.Text>
					</Cell.Wrapper>
				),
				dataKey: "cid"
			},
			{
				alignment: "left",
				minWidth: 120,
				label: <Cell.HeaderText>Contributors</Cell.HeaderText>,
				Cell: ({ rowData: { totalContributers } }: { rowData: CategoryModel }) => (
					<Cell.Wrapper>
						<Cell.Text>{totalContributers}</Cell.Text>
					</Cell.Wrapper>
				),
				dataKey: "cid"
			},
			{
				alignment: "left",
				minWidth: 135,
				label: <Cell.HeaderText>Created Date</Cell.HeaderText>,
				Cell: ({ rowData: { createdAt } }: { rowData: CategoryModel }) => (
					<Cell.Wrapper>
						<Cell.Text>{DateTime.fromISO(createdAt).toFormat("MMM d, yyyy")}</Cell.Text>
					</Cell.Wrapper>
				),
				dataKey: "cid"
			},
			{
				alignment: "right",
				label: "",
				width: 100,
				Cell: ({ rowData, rowIndex }) => (
					<MenuDots
						options={getOptions(rowData)}
						vertical
						removeBg
						removeshadow
						removeSideMargin
						menuId={`moreAction${rowIndex + 1}`}
					/>
				),
				loaderTemplate: TableColumnLoaderType.menuDots,
				dataKey: "menu"
			}
		],
		[boardsCount, getOptions]
	);

	const handleChangePage = useCallback(
		(newPage: number) => {
			setPage(newPage);
		},
		[setPage]
	);

	const handleChangeRowsPerPage = useCallback(
		(newPageSize: number) => {
			setBoardsPerPage(newPageSize);
			setPage(1);
		},
		[setBoardsPerPage, setPage]
	);

	const handleCreateNew = () => {
		setOpen(true);
	};

	const TableBlock = useMemo(
		() => (
			<TableWrapper sizes={{ horizontalTablet: 900 }}>
				<Table
					columns={tableColumns}
					data={boards || []}
					loading={isLoading}
					paginated
					totalDataCount={boardsCount || 20}
					page={page}
					pageSize={boardsPerPage}
					onChangePage={handleChangePage}
					onChangePageSize={handleChangeRowsPerPage}
				/>
			</TableWrapper>
		),
		[tableColumns, boards, isLoading, page, boardsCount, boardsPerPage, handleChangePage, handleChangeRowsPerPage]
	);

	return (
		<>
			<PageTemplate
				title={"Forum"}
				isLoading={isLoading}
				isNoData={!boards || !boards.length}
				emptyText={"You don’t have any Boards in your community yet."}
				searchPlaceholder={"Search Board"}
				onSearchUpdate={handleSearch}
				actionText={"Create Board"}
				onCreateClick={handleCreateNew}
				actionId={"createBoard"}
				searchId={"searchBoard"}
			>
				{TableBlock}
			</PageTemplate>
			{boardToDelete && (
				<ConfirmDelete
					headline="Board"
					name={boardToDelete.name}
					onClose={() => setBoardToDelete(null)}
					onDelete={async () => {
						await deleteBoard(boardToDelete);
						setBoardToDelete(null);
						getBoards({ keyword: debouncedSearchText, limit: boardsPerPage, offset: page });
					}}
					deleteBtnId="deleteConfirmation"
				/>
			)}
			{(open || !!boardToUpdate) && (
				<CreateUpdateBoardModal
					open={open || !!boardToUpdate}
					onClose={e => {
						setOpen(false);
						setBoardToUpdate(undefined);

						if (e?.created) {
							getBoards({ keyword: debouncedSearchText, limit: boardsPerPage, offset: page });
						}
					}}
					board={boardToUpdate}
				/>
			)}
		</>
	);
});

export default ManageBoards;
