import React, { useEffect, useMemo, useState } from "react";

import { useHistory } from "react-router-dom";

import Credit from "assets/images/credit-machine.svg";
import { MenuDots, TabPanel } from "shared/Components";
import { useCommunity, useMembers, useNotification } from "shared/hooks";

import { Tab, TabContainer } from "shared/styles";
import { UserStatus } from "shared/types";
import { MonetizationPlanSubscriptionId } from "shared/types/MonetizationPlansType";

import { Container, Content, Header } from "./style";

import { MonetizationPlans, PremiumContent, SubscriptionPlans } from "../../Components";

export interface MonetizationRoutes {
	dashboard: string;
	landing: string;
	plans: string;
	subscription: string;
	premium: string;
	subscription_and_premium: string;
	change: string;
}

interface MonetizationProps {
	option?: string;
	routes: MonetizationRoutes;
	generalSettingsCoinBlockRoute: string;
}

const Monetization: React.FC<MonetizationProps> = ({ option, routes, generalSettingsCoinBlockRoute }) => {
	const history = useHistory();
	const {
		getWorkspaceDetails,
		getAllMonetization,
		subscriptionSwitchToFree,
		getSubscriptionProducts,
		getData: getCommunityData,
		setMonetizationPlans
	} = useCommunity();
	const { canMakeFree, subscriptionProducts, monetizationPlans } = getCommunityData();
	const { getMemberTotalCount } = useMembers();
	const { showMessage } = useNotification();

	const [activeTab, setActiveTab] = useState(0);

	const currentSubscription = useMemo(() => {
		return (monetizationPlans || []).find(x => x.selected);
	}, [monetizationPlans]);

	useEffect(() => {
		getWorkspaceDetails();
		getSubscriptionProducts();
		getAllMonetization();
	}, [getWorkspaceDetails, getAllMonetization, getSubscriptionProducts]);

	useEffect(() => {
		if (option) {
			if (["subscription", "subscription_and_premium"].includes(option)) {
				setActiveTab(0);
			}

			if (["premium", "subscription_and_premium"].includes(option)) {
				setActiveTab(1);
			}
		}
	}, [option]);

	useEffect(() => {
		if (currentSubscription && !option) {
			if (currentSubscription.subscriptionId === MonetizationPlanSubscriptionId.SUBSCRIPTION_ONLY) {
				history.push(routes.subscription);
			}

			if (currentSubscription.subscriptionId === MonetizationPlanSubscriptionId.PREMIUM_ONLY) {
				history.push(routes.premium);
			}

			if (currentSubscription.subscriptionId === MonetizationPlanSubscriptionId.SUBSCRIPTION_PREMIUM_ONLY) {
				history.push(routes.subscription_and_premium);
			}
		}
	}, [currentSubscription, option, routes, history]);

	const monetizationMenuOptions = useMemo(() => {
		const options: { name: string; onClick: () => void; id?: string }[] = [
			// Learn More Option is Commented as per discussion in the ticket.
			// Ticket Reference: https://vyoo-me.atlassian.net/browse/VM-6995

			// {
			// 	name: "Learn More",
			// 	onClick: function noRefCheck() {}
			// },
			{
				name: "Change Method",
				onClick: () => history.push(routes.change),
				id: "changeMethod"
			}
		];

		if (canMakeFree && subscriptionProducts.length && currentSubscription?.subscriptionId) {
			options.push({
				name: "Switch to FREE",
				onClick: async () => {
					const paidMembers = await getMemberTotalCount({ type: "members" }, [UserStatus.PAID_SUBSCRIPTION], true);

					if (paidMembers) {
						showMessage("Can't set community with paid members to free");
					} else {
						await subscriptionSwitchToFree(currentSubscription!.subscriptionId);
						setMonetizationPlans([]);
						history.push(routes.dashboard);
					}
				},
				id: "switchToFree"
			});
		}

		return options;
	}, [
		currentSubscription,
		canMakeFree,
		subscriptionProducts.length,
		history,
		routes.change,
		routes.dashboard,
		getMemberTotalCount,
		showMessage,
		subscriptionSwitchToFree,
		setMonetizationPlans
	]);

	return (
		<Container>
			{!option && (
				<Header>
					<Header.InfoBlock>
						<Header.Title>Monetization</Header.Title>
						<Header.Description>
							By default your community is FREE. Click on Get started to earn with you community
						</Header.Description>
						<Header.Button
							size="large"
							buttonTheme="main"
							onClick={() => {
								history.push(routes.plans);
							}}
							id="getStartedMonetization"
						>
							Get Started
						</Header.Button>
					</Header.InfoBlock>
					<Header.Illustration src={Credit} />
				</Header>
			)}
			<Content.Wrapper>
				{!option && (
					<>
						<Content.Title>Ways you can monetize your community</Content.Title>
						<Content.Child>
							<MonetizationPlans routes={routes} />
						</Content.Child>
					</>
				)}
				{option === "plans" && (
					<>
						<Content.Title>Monetization</Content.Title>
						<Content.Description>How do you want to earn money?</Content.Description>
						<Content.Child>
							<MonetizationPlans fullMode routes={routes} />
						</Content.Child>
					</>
				)}
				{option === "change" && (
					<>
						<Content.Title>Change Monetization Method</Content.Title>
						<Content.Description>Change how you earn money.</Content.Description>
						<Content.Child>
							<MonetizationPlans fullMode routes={routes} />
						</Content.Child>
					</>
				)}
				{option && ["premium", "subscription", "subscription_and_premium"].includes(option) && (
					<>
						<Content.TitleWrapper>
							<Content.Title>Monetization</Content.Title>
							<MenuDots
								options={monetizationMenuOptions}
								removeSideMargin
								vertical
								removeBg
								dotColor={"#8f9bb3"}
								menuId="monetizationMoreActions"
							/>
						</Content.TitleWrapper>
						<TabContainer
							activeTab={activeTab}
							onChange={(event: React.ChangeEvent, value: number) => setActiveTab(value)}
							value={activeTab}
						>
							<Tab
								value={0}
								className={!["subscription", "subscription_and_premium"].includes(option) && "hidden"}
								label={["subscription", "subscription_and_premium"].includes(option) ? "Subscription Plan" : null}
								id="subscriptionPlan"
							/>
							<Tab
								value={1}
								className={!["premium", "subscription_and_premium"].includes(option) && "hidden"}
								label={["premium", "subscription_and_premium"].includes(option) ? "Premium Content" : null}
								id="premiumContent"
							/>
						</TabContainer>

						<Content.Child>
							<TabPanel value={activeTab} index={0}>
								<SubscriptionPlans subscriptionAndPremium={option === "subscription_and_premium"} />
							</TabPanel>

							<TabPanel value={activeTab} index={1}>
								<PremiumContent
									subscriptionAndPremium={option === "subscription_and_premium"}
									generalSettingsCoinBlockRoute={generalSettingsCoinBlockRoute}
								/>
							</TabPanel>
						</Content.Child>
					</>
				)}
			</Content.Wrapper>
		</Container>
	);
};

export default Monetization;
