import { Link } from "react-router-dom";
import styled from "styled-components";

export const Wrapper = styled(Link)`
	display: flex;
	align-items: center;
	text-align: left;
	max-width: 360px;
	width: 100%;
	text-decoration: none;
	border-radius: 12px;
	button {
		width: 100%;
		justify-content: flex-start;
		border-radius: 12px;
		text-align: left;
	}
	.mr-16 {
		margin-right: 16px;
	}
	.mb-8 {
		margin-bottom: 8px;
	}
	.MuiSkeleton-root {
		transform: scale(1);
	}
	img {
		width: ${({ small }) => (small ? "68px" : "80px")};
		height: ${({ small }) => (small ? "68px" : "80px")};
		object-fit: cover;
		border-radius: 12px;
		flex-shrink: 0;
		transition: transform 0.2s;
	}
	&:hover {
		background-color: ${({ grayHover }) => (grayHover ? "#edf1f7" : "#fff")};
		img {
			transform: scale(0.9);
		}
	}
	h4 {
		font-size: ${({ small }) => (small ? "15px" : "18px")};
		font-weight: bold;
		line-height: 1.33;
		color: #222b45;
	}
	p {
		font-size: 13px;
		line-height: 1.23;
		color: #8f9bb3;
	}
`;
