import React from "react";

import config from "config/appConfig";
import { Redirect, Route, Switch } from "react-router";

import { UserRoles } from "types";
import IApp from "types/IApp";

import SmartScroll from "shared/Components/SmartScroll";
import { NotFound } from "shared/Errorpages";
import SecureRoute from "shared/services/AuthDecorator/SecureRoute";
import BaseLayout from "shared/templates/BaseLayout";

import wrapByLayout from "utils/wrapByLayout";

import MarketingLayout from "./Layouts/MarketingLayout";
import {
	AnalyticsPage,
	AutoconnectMessaging,
	CategoriesPage,
	CommunitiesPage,
	CompaniesPage,
	CreateGroupPage,
	CreatePostPage,
	CreateTrackPage,
	DashboardPage,
	DonationsPage,
	EditPostPage,
	EmailTemplatesPage,
	EmailVerifiedPage,
	EngagePage,
	FaqsPage,
	FeaturesPage,
	FundraiserDonationsPage,
	FundraiserPage,
	FundraisersPage,
	GeneralSettingsPage,
	IntegrationsPage,
	LoyaltyRewardsPage,
	ManageAlbumsPage,
	ManageBoardsPage,
	ManageEventsPage,
	ManageFilesPage,
	ManageGroupsPage,
	ManagePostsPage,
	ManageReportedContentPage,
	ManageSeriesPage,
	ManageTracksPage,
	ManageVideosPage,
	MarketingPage,
	MembersPage,
	MessagingPage,
	MonetizationPage,
	PayoutsPage,
	ProfilePage,
	ReportsPage,
	SupportPage,
	TeamPage,
	TracksHelpPage,
	TransactionsPage,
	UnsubscribePage,
	UserSignInPage
} from "./Pages";
import { routes } from "./constants";

class RegularUserApp implements IApp {
	getRoutes = () => [
		<Route path="/" key="regular-user-route">
			<SmartScroll>
				<Switch>
					<Redirect exact from="/" to="/dashboard" />
					{this.getPublicRoutes()}
					<BaseLayout>
						{config.GLOBAL_CONSTANTS.HIDE_MEMBERS_APP && (
							<Route path="/member/" key={"NotFound"} component={NotFound} />
						)}
						{this.getSecuredRoutes()}
					</BaseLayout>
				</Switch>
			</SmartScroll>
		</Route>
	];

	getSettingsRoutes = () => [
		<Route
			exact
			key={routes.settings.monetization.getElementKey()}
			path={routes.settings.monetization.getPath()}
			component={MonetizationPage}
		/>,
		<Route
			exact
			key={`${routes.settings.monetization.getElementKey()}/option`}
			path={`${routes.settings.monetization.getPath()}/:option`}
			component={MonetizationPage}
		/>,
		// <Route
		// 	exact
		// 	key={routes.settings.monetization.subscription_and_premium.getElementKey()}
		// 	path={routes.settings.monetization.subscription_and_premium.getPath()}
		// 	component={SubscriptionAndPremiumPage}
		// />,
		// <Route
		// 	exact
		// 	key={routes.settings.monetization.plans.getElementKey()}
		// 	path={routes.settings.monetization.plans.getPath()}
		// 	component={MonetizationPlansPage}
		// />,
		// <Route
		// 	exact
		// 	key={routes.settings.monetization.getElementKey()}
		// 	path={routes.settings.monetization.getPath()}
		// 	component={MonetizationPage}
		// />,
		// <Route
		// 	exact
		// 	key={routes.settings.monetization.subscription.getElementKey()}
		// 	path={routes.settings.monetization.subscription.getPath()}
		// 	component={SubscriptionOnlyPage}
		// />,
		// <Route
		// 	exact
		// 	key={routes.settings.monetization.premium.getElementKey()}
		// 	path={routes.settings.monetization.premium.getPath()}
		// 	component={PremiumOnlyPage}
		// />,
		<Route
			exact
			key={routes.settings.general.getElementKey()}
			path={routes.settings.general.getPath()}
			component={GeneralSettingsPage}
		/>,
		<Route
			exact
			key={routes.settings.email_templates.getElementKey()}
			path={routes.settings.email_templates.getPath()}
			component={EmailTemplatesPage}
		/>,
		<Route
			exact
			key={routes.settings.autoconnect_messaging.getElementKey()}
			path={routes.settings.autoconnect_messaging.getPath()}
			component={AutoconnectMessaging}
		/>,
		<Route
			exact
			key={routes.settings.faqs.getElementKey()}
			path={routes.settings.faqs.getPath()}
			component={FaqsPage}
		/>,
		<Route
			exact
			key={routes.settings.features.getElementKey()}
			path={routes.settings.features.getPath()}
			component={FeaturesPage}
		/>,
		<Route
			exact
			key={routes.settings.profile.getElementKey()}
			path={routes.settings.profile.getPath()}
			component={ProfilePage}
		/>
	];

	getCreateRoutes = () => [
		<Route
			exact
			key={routes.create.post.getElementKey()}
			path={routes.create.post.getPath()}
			component={CreatePostPage}
		/>,
		<Route
			exact
			key={routes.create.group.getElementKey()}
			path={routes.create.group.getPath()}
			component={CreateGroupPage}
		/>,
		<Route
			exact
			key={routes.create.track.getElementKey()}
			path={routes.create.track.getPath()}
			component={CreateTrackPage}
		/>,
		<Route
			exact
			key={`${routes.edit.post.getElementKey()}/id`}
			path={`${routes.edit.post.getPath()}/:id`}
			component={EditPostPage}
		/>
	];

	getSecuredRoutes = () => {
		const routeList = [
			<Route
				exact
				key={routes.dashboard.getElementKey()}
				path={routes.dashboard.getPath()}
				component={DashboardPage}
			/>,
			<Route exact key={routes.support.getElementKey()} path={routes.support.getPath()} component={SupportPage} />,
			<Route
				exact
				key={routes.analytics.getElementKey()}
				path={routes.analytics.getPath()}
				component={AnalyticsPage}
			/>,
			<Route
				exact
				key={routes.messaging.getElementKey()}
				path={routes.messaging.getPath()}
				component={MessagingPage}
			/>,
			<Route exact key={routes.engage.getElementKey()} path={routes.engage.getPath()} component={EngagePage} />,
			<Route
				exact
				key={routes.loyalty_rewards.getElementKey()}
				path={routes.loyalty_rewards.getPath()}
				component={LoyaltyRewardsPage}
			/>,
			<Route
				exact
				key={routes.integrations.getElementKey()}
				path={routes.integrations.getPath()}
				component={IntegrationsPage}
			/>,
			...this.getSettingsRoutes(),
			...this.getCreateRoutes(),
			...this.getManageRoutes(),
			...this.getFundraisersRoutes(),
			...this.getMarketplaceRoutes(),
			...this.getMembersRoutes(),
			...this.getTeamRoutes(),
			...this.getPaymentsRoutes(),
			<Route path="*" key={"NotFound"} component={NotFound} />
		];

		return <SecureRoute roles={[UserRoles.Admin, UserRoles.Owner, UserRoles.Moderator]} routes={routeList} />;
	};

	getMembersRoutes = () => [
		<Route exact key={routes.members.getElementKey()} path={routes.members.getPath()} component={MembersPage} />
	];

	getPublicRoutes = () => [
		<Route
			exact
			key={`${routes.community.getElementKey()}/name`}
			path={`${routes.community.getPath()}/:name`}
			component={wrapByLayout(MarketingLayout)(MarketingPage)}
		/>,
		<Route
			exact
			key={routes.communities.getElementKey()}
			path={routes.communities.getPath()}
			component={wrapByLayout(MarketingLayout)(CommunitiesPage)}
		/>,
		<Route
			exact
			key={routes.user.signIn.getElementKey()}
			path={routes.user.signIn.getPath()}
			component={UserSignInPage}
		/>,
		<Route
			exact
			key={`${routes.trackshelp.getElementKey()}`}
			path={`${routes.trackshelp.getPath()}`}
			component={TracksHelpPage}
		/>,
		<Route
			exact
			key={`${routes.fundraiser.getElementKey()}/:id`}
			path={`${routes.fundraiser.getPath()}/:id`}
			component={FundraiserPage}
		/>,
		<Route
			exact
			key={`${routes.email.verified.getElementKey()}`}
			path={`${routes.email.verified.getPath()}`}
			component={EmailVerifiedPage}
		/>,
		<Route
			exact
			key={routes.unsubscribe.getElementKey()}
			path={routes.unsubscribe.getPath()}
			component={UnsubscribePage}
		/>
	];

	getTeamRoutes = () => [
		<Route exact key={routes.team.getElementKey()} path={routes.team.getPath()} component={TeamPage} />
	];

	getManageRoutes = () => [
		<Route
			exact
			key={routes.manage.boards.getElementKey()}
			path={routes.manage.boards.getPath()}
			component={ManageBoardsPage}
		/>,
		<Route
			exact
			key={routes.manage.posts.getElementKey()}
			path={routes.manage.posts.getPath()}
			component={ManagePostsPage}
		/>,
		<Route
			exact
			key={routes.manage.groups.getElementKey()}
			path={routes.manage.groups.getPath()}
			component={ManageGroupsPage}
		/>,
		<Route
			exact
			key={routes.manage.events.getElementKey()}
			path={routes.manage.events.getPath()}
			component={ManageEventsPage}
		/>,
		<Route
			exact
			key={routes.manage.files.getElementKey()}
			path={routes.manage.files.getPath()}
			component={ManageFilesPage}
		/>,
		<Route
			exact
			key={routes.manage.series.getElementKey()}
			path={routes.manage.series.getPath()}
			component={ManageSeriesPage}
		/>,
		<Route
			exact
			key={routes.manage.albums.getElementKey()}
			path={routes.manage.albums.getPath()}
			component={ManageAlbumsPage}
		/>,
		<Route
			exact
			key={routes.manage.tracks.getElementKey()}
			path={routes.manage.tracks.getPath()}
			component={ManageTracksPage}
		/>,
		<Route
			exact
			key={routes.manage.videos.getElementKey()}
			path={routes.manage.videos.getPath()}
			component={ManageVideosPage}
		/>,
		<Route
			exact
			key={routes.manage.categories.getElementKey()}
			path={routes.manage.categories.getPath()}
			component={CategoriesPage}
		/>,
		<Route
			exact
			key={routes.manage.reportedContent.getElementKey()}
			path={routes.manage.reportedContent.getPath()}
			component={ManageReportedContentPage}
		/>
	];

	getFundraisersRoutes = () => [
		<Route
			exact
			key={routes.fundraising.fundraisers.getElementKey()}
			path={routes.fundraising.fundraisers.getPath()}
			component={FundraisersPage}
		/>,
		<Route
			exact
			key={`${routes.fundraising.fundraisers.getElementKey()}/:id`}
			path={`${routes.fundraising.fundraisers.getPath()}/:id`}
			component={FundraiserDonationsPage}
		/>,
		<Route
			exact
			key={routes.fundraising.donations.getElementKey()}
			path={routes.fundraising.donations.getPath()}
			component={DonationsPage}
		/>
	];

	getMarketplaceRoutes = () => [
		<Route
			exact
			key={routes.marketplace.companies.getElementKey()}
			path={routes.marketplace.companies.getPath()}
			component={CompaniesPage}
		/>
	];

	getPaymentsRoutes = () => [
		<Route
			exact
			key={routes.payments.payouts.getElementKey()}
			path={routes.payments.payouts.getPath()}
			component={PayoutsPage}
		/>,
		<Route
			exact
			key={routes.payments.transactions.getElementKey()}
			path={routes.payments.transactions.getPath()}
			component={TransactionsPage}
		/>,
		<Route
			exact
			key={routes.payments.reports.getElementKey()}
			path={routes.payments.reports.getPath()}
			component={ReportsPage}
		/>
	];
}

export default RegularUserApp;
