import React, { FC, useEffect } from "react";

import * as R from "ramda";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";

import { usePersona } from "shared/hooks";

import { FormBase, MotherhoodCard } from "./style";

const motherhoodJourneyOptions = [
	{
		label: "Future mom",
		desc: "I don't have kids yet, but I'm trying",
		title: "future", // for mobile
		icon: "ic_balloons", // for mobile
		caption: "future_desc", // for mobile
		selectedColor: "#ff5b57" // for mobile
	},
	{
		label: "Expecting",
		desc: "It's in the oven",
		title: "expecting",
		icon: "ic_drum",
		caption: "expecting_desc",
		selectedColor: "#eb41c8"
	},
	{
		label: "Current mom",
		desc: "They're running around right now",
		title: "current",
		icon: "ic_babybib",
		caption: "current_desc",
		selectedColor: "#ffa500"
	},
	{
		label: "Bonus",
		desc: "Bonus mom to my spouse/partners' kids",
		title: "bonus",
		icon: "ic_schoolbag",
		caption: "bonus_desc",
		selectedColor: "#db41e3"
	},
	{
		label: "Foster mom",
		desc: "Fostering kids currently",
		title: "foster",
		icon: "ic_kindergarten",
		caption: "foster_desc",
		selectedColor: "#ffa3a0"
	},
	{
		label: "Auntie",
		desc: "Not really sure kids are my jam, but I am supporting kids of loved ones in various ways.",
		title: "auntie",
		icon: "ic_auntie",
		caption: "auntie_desc",
		selectedColor: "#eb41c8"
	},
	{
		label: "Empty Nester",
		desc: "My kids are grown",
		title: "emptynest",
		icon: "ic_emptynest",
		caption: "emptynest_desc",
		selectedColor: "#ff5b57"
	},
	{
		label: "Grandma",
		desc: "My kids have kids",
		title: "grandmom",
		icon: "ic_grandma",
		caption: "grandmom_desc",
		selectedColor: "#db41e3"
	}
];

interface FillProfileMotherhoodJourneyProps {
	selfiePageUrl: string;
}

const FillProfileMotherhoodJourney: FC<FillProfileMotherhoodJourneyProps> = ({ selfiePageUrl }) => {
	const history = useHistory();

	const { setPersona, getData: getPersonaData } = usePersona();
	const { persona } = getPersonaData();

	const {
		control,
		handleSubmit,
		formState: { isValid, isDirty, isSubmitting },
		reset
	} = useForm({
		mode: "onChange"
	});

	const { fields } = useFieldArray({ name: "options", control });

	useEffect(() => {
		reset({
			options: motherhoodJourneyOptions.map(x => ({
				...x,
				checked: persona?.journey?.find(j => j.title === x.title) ? true : false
			}))
		});
	}, [reset, persona]);

	const onSubmit = async data => {
		setPersona(
			{
				journey: data.options
					.filter(x => x.checked)
					.map(x => ({ ...R.pick(["icon", "title", "caption", "selectedColor"], x) }))
			},
			true
		);
		history.push(selfiePageUrl);
	};

	const RenderRows = (p, i: number) => {
		const { label, desc, checked } = p;

		return (
			<FormBase.Row>
				<FormBase.Column className={"full"}>
					<Controller name={`options[${i}].icon`} control={control} render={() => <input type="text" hidden />} />
					<Controller name={`options[${i}].title`} control={control} render={() => <input type="text" hidden />} />
					<Controller name={`options[${i}].caption`} control={control} render={() => <input type="text" hidden />} />
					<Controller
						name={`options[${i}].selectedColor`}
						control={control}
						render={() => <input type="text" hidden />}
					/>
					<Controller
						name={`options[${i}].checked`}
						control={control}
						defaultValue={checked}
						render={({ value, onChange }) => (
							<MotherhoodCard className={value && "active"} onClick={() => onChange(!value)}>
								<MotherhoodCard.Title>{label}</MotherhoodCard.Title>
								<MotherhoodCard.Desc>{desc}</MotherhoodCard.Desc>
							</MotherhoodCard>
						)}
					/>
				</FormBase.Column>
			</FormBase.Row>
		);
	};

	return (
		<FormBase onSubmit={handleSubmit(onSubmit)}>
			<FormBase.Title>Where you are in your motherhood journey?</FormBase.Title>
			<FormBase.Subtitle>You can select multiple :)</FormBase.Subtitle>
			<FormBase.InfoForm>{fields.map((field, i) => RenderRows(field as any, i))}</FormBase.InfoForm>
			<FormBase.Footer>
				<FormBase.ActionBtn palette={"basic"} buttonTheme={"light"} onClick={() => history.go(-1)}>
					Back
				</FormBase.ActionBtn>
				<FormBase.ActionBtn
					className="active"
					type={"submit"}
					disabled={!isValid || (persona?.journey?.length ? false : !isDirty) || isSubmitting}
				>
					Next
				</FormBase.ActionBtn>
			</FormBase.Footer>
		</FormBase>
	);
};

export default FillProfileMotherhoodJourney;
