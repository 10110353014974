import React from "react";

import { useParams } from "react-router";

import { view } from "modules/MemberHome";

import { useMemberRoutes } from "shared/hooks";

const HashtagPage = () => {
	const { id } = useParams() as { id: string };
	const { getMemberRoutesData } = useMemberRoutes();
	const { routes } = getMemberRoutesData();

	return <view.Hashtag routes={routes} id={id} />;
};

export default HashtagPage;
