import { IconButton, Link } from "@material-ui/core";
import styled, { css } from "styled-components";

import MarketingIconUrl from "assets/images/marketing.png";
import { Box, Button, Input, Text } from "shared/ui-kit";

export const Content = styled(Box)`
	padding: 0;
	height: 436px;

	display: flex;

	${props => props.theme.breakpoints.down("sm")} {
		flex-direction: column;
		height: auto;
	}
`;

export const FormWrapper = styled.form`
	position: relative;
`;

export const CloseIconButton = styled(IconButton)`
	position: absolute;
	top: 10px;
	right: 10px;
`;

export const IconSide = styled(Box)<{ primaryColor?: string }>`
	width: 40%;
	height: 100%;

	background-color: #f7f9fc;

	display: flex;
	justify-content: center;
	align-items: center;

	&.marketing {
		background-image: url(${MarketingIconUrl});
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;

		width: 30%;
	}

	&.people-mode {
		transform: rotateY(180deg);
		padding: 25px;
	}

	${({ primaryColor }) =>
		primaryColor &&
		css`
			svg {
				> g {
					path {
						&:nth-child(3) {
							fill: ${primaryColor};
						}
					}
				}
			}
		`}

	${props => props.theme.breakpoints.down("sm")} {
		width: 100%;
		height: 200px;

		padding: 20px;

		svg {
			height: 100%;
		}
	}
`;

export const ContentSide = styled(Box)`
	width: 60%;

	padding: 40px 40px;

	display: flex;
	flex-direction: column;
	justify-content: center;

	&.marketing {
		width: 70%;
	}

	${props => props.theme.breakpoints.down("sm")} {
		width: 100%;
		padding: 20px 10px;
		justify-content: flex-start;
	}
`;

export const Title = styled(Text)`
	font-size: 32px;
	font-weight: bold;
	line-height: 1.25;
	letter-spacing: normal;
	color: #222b45;
	text-align: left;

	${props => props.theme.breakpoints.down("sm")} {
		font-size: 20px;
	}
`;

export const Pretitle = styled(Text)`
	text-transform: capitalize;
	font-size: 13px;
	font-weight: 600;
	line-height: 1.85;
	color: #8f9bb3;

	margin-bottom: 6px;
`;

export const Subtitle = styled(Text)`
	font-size: 15px;
	line-height: 1.6;
	letter-spacing: normal;
	color: #222b45;

	margin-top: 8px;
`;

export const PhoneRow = styled(Box)`
	display: flex;

	margin-top: 24px;

	${props => props.theme.breakpoints.down("sm")} {
		flex-direction: column;
	}
`;

export const CodeCompleteWrapper = styled(Box)`
	margin: 0 12px 0 0;

	.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
		top: -3px;
		height: 44px;
		padding: 14px 16px 9px 16px !important;

		width: 130px;

		border-radius: 8px;

		input {
			padding: 0 0 0 30px !important;
			margin-left: -30px;
			font-size: 15px;
			font-weight: normal;
			line-height: 1.33;
			color: #8f9bb3;
		}
	}

	${props => props.theme.breakpoints.down("sm")} {
		width: 100%;
	}
`;

export const CodeOptionBlock = styled(Box)`
	display: block;
	white-space: nowrap;

	overflow: hidden;
	text-overflow: ellipsis;
`;

export const CodeBlockLabel = styled(Text)`
	font-size: 15px;
	font-weight: normal;
	line-height: 1.33;
	color: #8f9bb3;

	padding-right: 5px;
`;

export const PhoneInput = styled(Box)`
	display: flex;

	position: relative;

	width: 360px;

	.MuiInputBase-root {
		border-top-left-radius: 8px;
		border-bottom-left-radius: 8px;
	}

	${props => props.theme.breakpoints.down("sm")} {
		width: 100%;

		margin-top: 10px;
	}
`;

export const PhoneInputCtrl = styled(Input)`
	margin: 0;
	width: 100%;

	padding-right: 120px;

	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	/* Firefox */

	input[type="number"] {
		-moz-appearance: textfield;
	}

	${props => props.theme.breakpoints.down("sm")} {
		padding-right: 0;
	}
`;

export const SendLinkBtn = styled(Button)<{ primaryColor?: string; hoverColor?: string }>`
	margin: 0;

	width: 125px;
	height: 41px;

	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	border-top-right-radius: 8px;
	border-bottom-right-radius: 8px;

	position: absolute;
	top: 0;
	right: 0;

	${({ primaryColor }) =>
		primaryColor &&
		css`
			background-color: ${primaryColor};
		`}

	${({ hoverColor }) =>
		hoverColor &&
		css`
			&:hover {
				background-color: ${hoverColor};
			}
		`}

	${props => props.theme.breakpoints.down("sm")} {
		position: relative;
		width: 155px;
	}
`;

export const Description = styled(Text)`
	font-size: 12px;
	line-height: 1.33;
	letter-spacing: normal;
	color: #8f9bb3;

	margin-top: 12px;

	.subinfo {
		font-style: italic;
		display: block;

		margin-top: 15px;
	}
`;

export const StyledLink = styled(Link)`
	font-size: 12px;
	font-weight: 600;
	line-height: 1.33;
	letter-spacing: normal;
	color: #222b45;
	cursor: pointer;
`;

export const ShowSection = styled(Box)`
	margin-top: 36px;

	${props => props.theme.breakpoints.down("sm")} {
		display: flex;

		margin-top: 15px;
	}
`;

export const ShopBtn = styled(Link)<{ icon: string }>`
	width: 160px;
	height: 48px;

	background-image: url(${({ icon }) => icon});
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;

	display: inline-block;

	cursor: pointer;

	border-radius: 8px;

	overflow: hidden;

	&:not(:last-child) {
		margin-right: 16px;
	}
`;

export const PaperBox = styled(Box)`
	background: #fff;

	width: 130px;
	overflow-x: hidden;

	box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%), 0 1px 1px 0px rgb(0 0 0 / 14%), 0 1px 3px 0 rgb(0 0 0 / 12%);

	li {
		background: #fff;
	}

	.MuiAutocomplete-option[aria-selected="true"] {
		background: white;
	}

	.MuiAutocomplete-option[data-focus="true"] {
		background-color: rgba(0, 0, 0, 0.04);
	}
`;
