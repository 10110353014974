import React from "react";

import styled, { css } from "styled-components";

import { Box, Icon } from "shared/ui-kit";

interface ThemeHeaderProps {
	color?: string;
	customStyle?: string;
}

const ThemeCardHeader = styled(Box)<ThemeHeaderProps>`
	width: 100%;
	height: 36px;

	background: ${({ color }) => (color ? color : "#e4e9f2")};

	position: relative;

	.theme-card__header-icon-wrapper {
		display: flex;
		justify-content: center;
		align-items: center;

		position: absolute;

		left: calc(50% - 52px);
		bottom: -52px;

		width: 104px;
		height: 104px;

		border: solid 2px ${({ color }) => (color ? color : "#e4e9f2")};
		border-radius: 50%;

		overflow: hidden;

		background-color: #e4e9f2;
	}

	${({ customStyle }) => {
		return customStyle
			? css`
					${customStyle}
			  `
			: "";
	}}
`;

const ThemeHeader: React.FC<ThemeHeaderProps> = (props: ThemeHeaderProps): JSX.Element => {
	return (
		<ThemeCardHeader {...props}>
			<div className="theme-card__header-icon-wrapper">
				<Icon group={"filled"} fill={"white"} name={"image-landscape"} width={44} height={32} viewBox={"0 0 19 16"} />
			</div>
		</ThemeCardHeader>
	);
};

export default ThemeHeader;
