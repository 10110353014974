import { useMemo } from "react";

import useHttpActions from "shared/services/api/core/useHttpActions";

import BaseConverter from "shared/services/converters/baseConverter";

import { API_URLS } from "../../constants";
import { SupportItemType } from "../types";

const useSupportService = () => {
	const actions = useHttpActions();
	return useMemo(
		() => ({
			getSupportItems: async (): Promise<{ help: Array<SupportItemType> }> => {
				return BaseConverter.convert(await actions.get(API_URLS.GET_SUPPORT_ITEMS));
			}
		}),
		[actions]
	);
};

export default useSupportService;
