import { useCallback, useMemo } from "react";

import { Parser } from "json2csv";

import { DateTime } from "luxon";

import { useCommunity } from "shared/hooks";

import { useTransactionStore } from "../contexts";

import { useTransactionApiService } from "../services";

const useTransactions = () => {
	const transactionApiService = useTransactionApiService();

	const { getData: getCommunityData } = useCommunity();
	const { workspace } = getCommunityData();

	const store = useTransactionStore();
	const { setState } = useTransactionStore();

	const methods = useMemo(
		() => ({
			async getTransactions({ keyword = "", startDate, endDate, limit, offset }) {
				setState(ctx => ({ ...ctx, loading: true }));
				const transactions = await transactionApiService.getTransactions(offset, limit, keyword, startDate, endDate);
				if (transactions) setState(ctx => ({ ...ctx, transactions }));
				setState(ctx => ({ ...ctx, loading: false }));
				return transactions;
			},
			async getTransactionsCount({ keyword = "", startDate, endDate }) {
				const totalCount = await transactionApiService.getTransactionsCount(keyword, startDate, endDate);
				setState(ctx => ({ ...ctx, totalCount }));
			},
			setPage: async (page: number) => {
				setState(ctx => ({ ...ctx, page }));
			},
			saveCSV: async (searchText, startDate, endDate) => {
				const transactions = await methods.getTransactions({
					keyword: searchText,
					offset: 1,
					limit: 500,
					startDate,
					endDate
				});

				if (transactions.length) {
					const fields = [
						{ label: "full name", value: row => `${row.firstName || ""} ${row.lastName || ""}` },
						{ label: "amount paid", value: row => row.amountPaid },
						{ label: "purchase date", value: row => DateTime.fromISO(row.purchaseDate).toFormat("yyyy-MM-dd") },
						"status",
						{ label: "purpose heading", value: "purpose.heading" }
					];
					const opts = { fields };
					const fileName =
						startDate && endDate
							? `${workspace?.name}-from-date-${DateTime.fromJSDate(startDate).toFormat(
									"yyyy-MM-dd"
							  )}-to-${DateTime.fromJSDate(endDate).toFormat("yyyy-MM-dd")}-transactions.csv`
							: `${workspace?.name}-transactions.csv`;
					try {
						const parser = new Parser(opts);
						const csvData = parser.parse(transactions);
						const link = document.createElement("a");
						link.setAttribute("href", `data:text/html;charset=utf-8,${encodeURIComponent(csvData)}`);
						link.setAttribute("download", fileName);
						link.style.visibility = "hidden";
						document.body.appendChild(link);
						link.click();
						document.body.removeChild(link);
					} catch (error) {}
				}
			},
			setTransactionShowPerPage: async (transactionShowPerPage: number) => {
				setState(ctx => ({ ...ctx, transactionShowPerPage }));
			}
		}),
		[workspace, setState, transactionApiService]
	);

	const getData = useCallback(() => {
		return store;
	}, [store]);

	return { ...methods, getData };
};

export default useTransactions;
