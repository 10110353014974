import React, { useMemo, useRef, useState } from "react";

import { Box, ClickAwayListener, MenuItem, MenuList, Popper } from "@material-ui/core";
import { useHistory } from "react-router-dom";

import { routes as regularUserRoutes } from "apps/RegularUser/constants";
import { ReactComponent as LogoutIcon } from "assets/icons/icons-colorful-logout.svg";
import { ReactComponent as SettingsIcon } from "assets/icons/icons-colorful-settings.svg";
import { ReactComponent as UserIcon } from "assets/icons/icons-colorful-user.svg";

import { useAuth } from "modules/App/Data";
import { useCommunity, useMemberRoutes, usePersona, useRoutes } from "shared/hooks";

import { MediaModel, PlaceholderImageType, ProfileType } from "shared/types";

import {
	Container,
	StyledGrow,
	UserAvatarWrapper,
	UserDropdownPaper,
	UserName,
	UserProfileButton,
	UserProfileMenuItemIcon
} from "./style";

import { PlaceholderImage, SettingsDialog } from "../index";

interface ProfileMenuProps {
	profile?: ProfileType;
	isMember?: boolean;
}

const ProfileMenu: React.FC<ProfileMenuProps> = props => {
	const { profile, isMember } = props;
	const [open, setOpen] = useState(false);
	const anchorRef = useRef<HTMLButtonElement>(null);
	const history = useHistory();
	const { logout } = useRoutes();

	const { getMemberRoutesData } = useMemberRoutes();
	const { routes } = getMemberRoutesData();

	const { communityColors } = useCommunity();

	const { getData: getPersonaData } = usePersona();
	const { persona } = getPersonaData();

	const [settingsDialogIsOpen, setSettingsDialogIsOpen] = useState(false);

	const { clearState } = useAuth();

	const openProfilePage = () => {
		setOpen(false);
		history.push(isMember ? routes?.member.profile.getPath() : regularUserRoutes.settings.profile.getPath());
	};

	const logoutUser = () => {
		setOpen(false);
		logout();
		clearState();
	};

	const handleToggle = () => {
		setOpen(prevOpen => !prevOpen);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleListKeyDown = (event: React.KeyboardEvent) => {
		if (event.key === "Tab") {
			event.preventDefault();
			setOpen(false);
		}
	};

	const handleSettingsClick = () => {
		setSettingsDialogIsOpen(true);
	};

	const handleSettingsDialogClose = () => {
		setSettingsDialogIsOpen(false);
	};

	// return focus to the button when we transitioned from !open -> open
	const prevOpen = useRef(open);
	React.useEffect(() => {
		if (prevOpen.current && !open) {
			anchorRef.current!.focus();
		}

		prevOpen.current = open;
	}, [open]);

	const userIconSrc = useMemo(
		() => (profile?.photos && (profile?.photos[0] as MediaModel))?.profilePicture ?? "",
		[profile]
	);

	return (
		<>
			<Container data-topnav="profile">
				<UserProfileButton
					ref={anchorRef}
					aria-controls={open ? "menu-list-grow" : undefined}
					aria-haspopup="true"
					onClick={handleToggle}
				>
					{(profile?.firstName?.length || 0) > 20 ? (
						<Box className="w-32">
							<UserName className={"profile__name truncate"}>{profile?.firstName || ""}</UserName>
						</Box>
					) : (
						<UserName className={"profile__name"}>{profile?.firstName || ""}</UserName>
					)}
					<UserAvatarWrapper>
						{userIconSrc || persona?.profileImage ? (
							<img alt="user-profile-pic" src={userIconSrc || String(persona?.profileImage)} id="profile" />
						) : (
							<PlaceholderImage
								type={PlaceholderImageType.PROFILE_IMAGE}
								width={32}
								height={32}
								viewBox={"0 0 400 400"}
							/>
						)}
					</UserAvatarWrapper>
				</UserProfileButton>
				<Popper
					open={open}
					anchorEl={anchorRef.current}
					role={undefined}
					placement="bottom-end"
					transition
					disablePortal
				>
					{({ TransitionProps, placement }) => (
						<StyledGrow {...TransitionProps} placement={placement}>
							<UserDropdownPaper>
								<ClickAwayListener onClickAway={handleClose}>
									<MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
										<MenuItem onClick={openProfilePage}>
											<UserProfileMenuItemIcon name="user" themeColor={communityColors.primary}>
												<UserIcon />
											</UserProfileMenuItemIcon>
											<span>My Profile</span>
										</MenuItem>
										<MenuItem onClick={handleSettingsClick}>
											<UserProfileMenuItemIcon name="settings" themeColor={communityColors.primary}>
												<SettingsIcon />
											</UserProfileMenuItemIcon>
											<span id="settings">Settings</span>
										</MenuItem>
										<MenuItem onClick={logoutUser}>
											<UserProfileMenuItemIcon name="logout" themeColor={communityColors.primary}>
												<LogoutIcon />
											</UserProfileMenuItemIcon>
											<span id="logout">Log Out</span>
										</MenuItem>
									</MenuList>
								</ClickAwayListener>
							</UserDropdownPaper>
						</StyledGrow>
					)}
				</Popper>
			</Container>
			{settingsDialogIsOpen && <SettingsDialog onClose={handleSettingsDialogClose} />}
		</>
	);
};

export default ProfileMenu;
