import React, { FC, ReactNode, memo, useMemo } from "react";

import { useMediaQuery } from "@material-ui/core";

import * as appTheme from "theme/default";

import { customBreakpoints } from "theme/default";

import { ScrollableWrapper, Wrapper } from "./style";

interface TableWrapperSize {
	mobile?: number;
	tablet?: number;
	horizontalTablet?: number;
}

interface TableWrapperProps {
	children: ReactNode;
	sizes?: TableWrapperSize;
}

const TableWrapper: FC<TableWrapperProps> = memo(({ sizes, children }) => {
	const isMobile = useMediaQuery(appTheme.default.breakpoints.down("sm"));
	const isTablet = useMediaQuery(appTheme.default.breakpoints.down(customBreakpoints.tabletVerticalMax));
	const isHorizontalTablet = useMediaQuery(appTheme.default.breakpoints.down(customBreakpoints.tabletHorizontalMax));

	const correctWidth = useMemo(() => {
		if (isMobile && sizes?.mobile) {
			return sizes.mobile;
		}
		if (isTablet && sizes?.tablet) {
			return sizes.tablet;
		}
		if (isHorizontalTablet && sizes?.horizontalTablet) {
			return sizes.horizontalTablet;
		}
		return undefined;
	}, [sizes, isMobile, isTablet, isHorizontalTablet]);

	return (
		<Wrapper>
			<ScrollableWrapper width={correctWidth}>{children}</ScrollableWrapper>
		</Wrapper>
	);
});

export default TableWrapper;
