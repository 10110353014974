import React from "react";

import { DateTime } from "luxon";
import { FileType } from "types/FilesContextValuesType";

import { PlaceholderImageType } from "shared/types";

import SelectItemTemplate from "../Blocks/SelectItemTemplate";
import { PlaceholderImage } from "../index";

const getDateText = start => {
	return DateTime.fromISO(start).toFormat("MMM d");
};

const FileItem: React.FC<FileType & { onSelect: () => void }> = ({ onSelect, ...file }) => (
	<SelectItemTemplate
		coverUrl={file.coverPhoto?.url || undefined}
		onSelect={onSelect}
		pretitle={getDateText(file.createdAt)}
		title={file.title}
		subtitle={file.category?.name || undefined}
		placeholderImg={
			<PlaceholderImage type={PlaceholderImageType.FILE_PREVIEW} width={80} height={80} viewBox={"0 0 400 400"} />
		}
	/>
);

export default FileItem;
