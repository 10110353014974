import { PremiumObjectType, UnlockContentType, WorkspaceProductModel } from "shared/types";
import { Card } from "shared/types/CardsType";

import createStore from "utils/createStore";

export interface Values {
	loadingCards: boolean;
	submitting: boolean;
	cards: Card[];
	buyCoinsDialog: {
		open: boolean;
		id?: string;
	};
	subscribeDialog?: {
		premiumObject: PremiumObjectType<any>;
		priceInfo?: WorkspaceProductModel;
		unlockContentType?: UnlockContentType;
	};
	unlockContentDialog?: {
		premiumObject: PremiumObjectType<any>;
		priceInfo?: WorkspaceProductModel;
		unlockContentType?: UnlockContentType;
		teaserContent?: string;
	};
	chosenCard?: Card;
	onUnlock?: () => void;
}

export const initialState: Values = {
	loadingCards: false,
	submitting: false,
	cards: [],
	buyCoinsDialog: {
		open: false
	}
};

export default createStore<Values>({
	initialState
});
