import { useCallback, useMemo } from "react";

import { useSupportStore } from "../contexts";

import { useSupportApiService } from "../services";

const useSupport = () => {
	const supportService = useSupportApiService();

	const store = useSupportStore();
	const { setState } = useSupportStore();

	const methods = useMemo(
		() => ({
			getSupportItems: async () => {
				const data = await supportService.getSupportItems();
				if (data) {
					const supportItems = data.help.map((item, id) => ({ ...item, id: `${id}` }));
					setState(ctx => ({ ...ctx, supportItems, isLoading: false }));
				}
			},
			clearState(): void {
				setState(ctx => ({ ...ctx, supportItems: [], isLoading: true }));
			}
		}),
		[supportService, setState]
	);

	const getData = useCallback(() => {
		return store;
	}, [store]);

	return { ...methods, getData };
};

export default useSupport;
