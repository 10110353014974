import React, { FC } from "react";

import { useHistory } from "react-router-dom";

import { useUser } from "shared/hooks";
import { InPlaceModal, InPlaceModalProps } from "shared/ui-kit";

import { ReactComponent as ExclamationMarkIcon } from "shared/ui-kit/assets/icons/filled/exclamation-triangle.svg";

import {
	ButtonWrapper,
	CommunityImg,
	ConfirmHeader,
	StyledButton,
	StyledParagraphText,
	StyledPopupOverlay
} from "./style";

interface Props extends InPlaceModalProps {
	open: boolean;
}

interface BannedUserModalProps {
	open: boolean;
	communityName?: string;
	workspaceUrl?: string;
	communitiesPageLink: string;
	joinCommunitiesUrl: string;
}

const ConfirmLeavePopupWrapper: FC<Props> = ({ open, children, onClose, modalHeight, ...props }) => (
	<InPlaceModal {...props} onClose={onClose} maxWidth="xs" open={open} modalHeight={modalHeight}>
		{children}
	</InPlaceModal>
);

const BannedUserModal: FC<BannedUserModalProps> = ({
	open,
	communityName,
	workspaceUrl,
	communitiesPageLink,
	joinCommunitiesUrl
}) => {
	const history = useHistory();

	const { getData: getUserData } = useUser();
	const { workspaces } = getUserData();

	const backToCommunities = () => {
		history.push(
			workspaces?.filter(x => x.communityUrl !== workspaceUrl && x.workspace !== communityName)?.length
				? communitiesPageLink
				: joinCommunitiesUrl
		);
	};

	return (
		<StyledPopupOverlay open={open}>
			<ConfirmLeavePopupWrapper modalHeight={600} open={open} onClose={() => {}}>
				<CommunityImg.Wrapper>
					<CommunityImg />
					<CommunityImg.ExclamationMarkWrapper>
						<ExclamationMarkIcon />
					</CommunityImg.ExclamationMarkWrapper>
				</CommunityImg.Wrapper>
				<ConfirmHeader>Banned from Community</ConfirmHeader>
				<StyledParagraphText variant="body1">
					You appear to be banned from the {communityName || "community"}. You can still be a member of other
					communities on Vyoo as long as you don’t violate community guidelines.
				</StyledParagraphText>
				<ButtonWrapper>
					<StyledButton removeSideMargin fullWidth size="medium" type="button" onClick={backToCommunities}>
						Back to Communities
					</StyledButton>
				</ButtonWrapper>
			</ConfirmLeavePopupWrapper>
		</StyledPopupOverlay>
	);
};

export default BannedUserModal;
