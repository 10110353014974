import React, { FC, useCallback, useEffect, useMemo, useState } from "react";

import clsx from "clsx";

import config from "config/appConfig";
import parsePhoneNumberFromString from "libphonenumber-js";
import { Controller, useForm } from "react-hook-form";

import { ReactComponent as DollarIcon } from "assets/icons/iconDollarFilled.svg";
import { MenuDots } from "shared/Components";
import { useFundraisers, useNotification } from "shared/hooks";
import { Icon, Loader, Text } from "shared/ui-kit";

import { isEmail } from "shared/ui-kit/utils";
import { creditCardType } from "utils/creditCardType";
import { validatePhoneNumber } from "utils/serviceUtils/validators";

import { AmountRadioWrapper, DonationCard, LoaderWrapper, TermsConditions } from "./style";

import { LabeledInput, MemberDialog, VyooRadio } from "../../shared";
import CCDetails from "../../shared/BuyCoinsDialog/CCDetails";
import CheckoutSummary from "../../shared/BuyCoinsDialog/CheckoutSummary";
import StripeCheckout from "../../shared/BuyCoinsDialog/StripeCheckout";
import ChangeMemberDialog from "../ChangeMemberDialog";

interface FundraiserInformationType {
	amount: string;
	customAmount: string;
	fullName?: string;
	contactDetails: string;
	cardNumber: string;
	expiration: string;
	cvv: string;
}

const FundraiserDonationDialog: FC<{
	id: string;
	contactId: string;
	workspace?: string;
	useStripeCheckout: boolean;
}> = ({ id, useStripeCheckout, contactId, workspace }) => {
	const {
		control,
		handleSubmit,
		watch,
		formState: { isDirty, isValid, isSubmitting, errors }
	} = useForm<FundraiserInformationType>({
		mode: "onChange"
	});

	const {
		getData: getFundraiserData,
		setDonationDialog,
		setDonateSuccessfulDialog,
		setSelectMemberDialog,
		donateToFundraiser,
		getPaymentIntent,
		confirmDonation
	} = useFundraisers();
	const { donationDialog, selectMemberDialog, onBehalfOf } = getFundraiserData();

	const { showMessage } = useNotification();

	const amount = watch("amount");
	const customAmount = watch("customAmount");
	const cardNumber = watch("cardNumber");

	const last4 = useMemo(() => cardNumber?.replace(/\s/g, "").slice(-4), [cardNumber]);

	const moneyTotal = useMemo(() => (amount === "custom" ? customAmount : amount), [amount, customAmount]);

	const [step, setStep] = useState(1);
	const [clientSecret, setClientSecret] = useState("");
	const [donation, setDonation] = useState();

	const donationOpts = useMemo(() => [25, 100, 250], []);
	const title = useMemo(() => (step === 1 ? "Donate" : step === 2 ? "Payment details" : "Checkout"), [step]);

	const summaryItem = useMemo(
		() => (
			<div className="bag-type">
				<Text variant="h2">{donationDialog?.behalf.group.name}</Text>
				{moneyTotal && <Text variant="h2">${moneyTotal}</Text>}
			</div>
		),
		[moneyTotal, donationDialog?.behalf.group.name]
	);

	const handleSwitchCards = useCallback(() => setStep(2), []);

	const onSubmit = useCallback(
		async (data: FundraiserInformationType) => {
			if (useStripeCheckout && step === 1) {
				setDonation(undefined);
				setClientSecret("");

				const isEmail = data.contactDetails.includes("@");
				const formData: any = {
					name: data.fullName,
					amount: parseInt(moneyTotal) * 100,
					groupId: onBehalfOf ? onBehalfOf.group._id : donationDialog!.behalf.group.id,
					fundraiserId: id,
					personaId: donationDialog?.behalf.member.personaId || onBehalfOf?.persona?.personaId,
					uniqueIdentifierType: isEmail ? "email" : "phone",
					uniqueIdentifier: isEmail
						? data.contactDetails
						: data.contactDetails[0] === "+"
						? data.contactDetails.replace(/\+/g, "")
						: parsePhoneNumberFromString(data.contactDetails, "US")?.format("E.164").replace(/\+/g, ""),
					workspace
				};

				getPaymentIntent(formData)
					.then(paymentIntent => {
						setDonation(paymentIntent?.donation);
						setClientSecret(paymentIntent!.paymentIntentClientSecret);
					})
					.catch(e => {
						console.log(e);
					});

				setStep(2);
			} else if (!useStripeCheckout) {
				if (step === 3 && (onBehalfOf || donationDialog?.behalf)) {
					const formData: any = {
						name: data.fullName,
						card: {
							number: data.cardNumber.replace(/\s/g, ""),
							exp_month: data.expiration.split("/")[0],
							exp_year: data.expiration.split("/")[1],
							cvc: data.cvv,
							amount: parseInt(moneyTotal) * 100
						},
						groupId: onBehalfOf ? onBehalfOf.group._id : donationDialog!.behalf.group.id,
						contactId: contactId || null,
						personaId: donationDialog?.behalf.member.personaId || onBehalfOf?.persona?.personaId,
						fundraiserId: id
					};

					if (data.contactDetails) {
						const isEmail = data.contactDetails.includes("@");
						formData.uniqueIdentifierType = isEmail ? "email" : "phone";
						formData.uniqueIdentifier = isEmail
							? data.contactDetails
							: data.contactDetails[0] === "+"
							? data.contactDetails.replace(/\+/g, "")
							: parsePhoneNumberFromString(data.contactDetails, "US")?.format("E.164").replace(/\+/g, "");
					}

					const error = await donateToFundraiser(formData, id, workspace);

					if (error) {
						return showMessage(error, 5, true);
					}

					setDonateSuccessfulDialog(donationDialog);
					setDonationDialog();
				} else {
					setStep(step => step + 1);
				}
			}
		},
		[
			id,
			step,
			onBehalfOf,
			moneyTotal,
			contactId,
			donateToFundraiser,
			getPaymentIntent,
			workspace,
			setDonateSuccessfulDialog,
			donationDialog,
			setDonationDialog,
			showMessage,
			useStripeCheckout
		]
	);

	useEffect(() => {
		const url = new URL(window.location.href);
		const query = new URLSearchParams(url.search);

		if (query.get("redirect_status") === "succeeded") {
			setDonateSuccessfulDialog(donationDialog);
			setDonationDialog();

			const newLocal = query.get("donationId") || "";
			const newLocal_1 = query.get("contactId") || undefined;
			confirmDonation({
				donationId: newLocal,
				contactId: newLocal_1,
				workspace
			});

			query.delete("redirect_status");
			query.delete("payment_intent_client_secret");
			query.delete("donationId");
			query.delete("contactId");
			query.delete("payment_intent");

			const newUrl = `${window.location.origin}${window.location.pathname}?${query.toString()}`;

			window.history.replaceState({}, "Fundraiser", newUrl);
		}
	}, [confirmDonation, donationDialog, setDonationDialog, setDonateSuccessfulDialog, donation, contactId, workspace]);

	return (
		<>
			<form onSubmit={handleSubmit(onSubmit)}>
				<MemberDialog
					open
					title={title}
					onClose={() => setDonationDialog()}
					confirmLeave={isDirty}
					modalHeight={useStripeCheckout ? 640 : 600}
					customWidth={512}
					goBack={step > 1 ? () => setStep(step => step - 1) : undefined}
					noFooter={useStripeCheckout && step > 1}
					footerLeftContent={
						<TermsConditions
							href={config.GLOBAL_CONSTANTS.TERMS_AND_CONDITIONS_LINK}
							target="_blank"
							className="text-center block sm:text-left"
						>
							Terms & Conditions
						</TermsConditions>
					}
					footerPrimary={{
						text: step === 3 ? "Confirm Payment" : "Next",
						disabled: !isDirty || !isValid || isSubmitting,
						loading: isSubmitting
					}}
				>
					<div className={clsx("py-4 px-4 md:py-6 md:px-6", step === 1 ? "block" : "hidden")}>
						<DonationCard className="sm:p-4">
							<div className="on-behalf-of relative p-3 flex items-center justify-between gap-4">
								<div className="flex flex-col sm:items-center sm:flex-row gap-4">
									<div className="profile-wrapper relative">
										{donationDialog?.behalf.member.img && (
											<img
												className="profile"
												src={donationDialog.behalf.member.img}
												alt={donationDialog.behalf.member.name}
											/>
										)}
										{donationDialog?.behalf?.group?.img && (
											<img
												className="group absolute -bottom-1 -right-1"
												src={donationDialog.behalf.group.img}
												alt={donationDialog.behalf.group.name}
											/>
										)}
									</div>
									<div className="flex flex-col">
										<Text className="support-title">
											Support <b>{`${donationDialog?.behalf.member.name}'s`}</b> team
										</Text>
										<Text className="team-group">{donationDialog?.behalf?.group?.name}</Text>
									</div>
								</div>
								<div className="absolute top-4 right-3 sm:relative sm:top-0 sm:right-0">
									<MenuDots
										vertical
										memberView
										options={[
											{
												icon: <Icon name="user-edit" group="filled" fill="#919db4" />,
												name: "Change member",
												onClick: () => setSelectMemberDialog(true)
											}
										]}
									/>
								</div>
							</div>
							<div className="amount-sel my-2 px-3 flex flex-col gap-2 sm:px-0">
								{useStripeCheckout && (
									<>
										<Controller
											name="fullName"
											control={control}
											rules={{
												required: "Name is required",
												minLength: { value: 2, message: "At least 2 characters long." },
												maxLength: { value: 40, message: "Must be 40 characters or less." }
											}}
											render={({ onChange, value }) => (
												<LabeledInput
													name="name"
													label="Name visible for public *"
													placeholder="Full name"
													onChange={onChange}
													value={value}
													error={errors?.fullName?.message}
													outerWrapperClassName="flex-1"
												/>
											)}
										/>
										<div className="gap-2" />
										<Controller
											name="contactDetails"
											control={control}
											rules={{
												required: "Email or number is required",
												validate: val => {
													if (!isNaN(val) && (val.length < 10 || val.length > 15)) {
														return "Phone number should be between 10 - 15 numbers";
													}
													return validatePhoneNumber(val) || isEmail(val) ? true : "Please enter valid email or number";
												}
											}}
											render={({ onChange, value }) => (
												<LabeledInput
													name="name"
													label="Email or phone number *"
													placeholder="Email or phone number"
													onChange={onChange}
													value={value}
													error={errors?.contactDetails?.message}
													outerWrapperClassName="flex-1"
												/>
											)}
										/>
										<div className="gap-2" />
									</>
								)}
								<p>Select amount *</p>
								<Controller
									name="amount"
									control={control}
									render={({ onChange, value }) => (
										<>
											<div className="flex items-center flex-wrap gap-3">
												{donationOpts.map(d => (
													<AmountRadioWrapper
														key={d}
														onClick={() => onChange(d)}
														className={clsx(
															"flex items-center justify-start flex-1 gap-2 py-3 px-4",
															value === d && "active-donation"
														)}
													>
														<VyooRadio checked={value === d} />
														<Text className="val">${d}</Text>
													</AmountRadioWrapper>
												))}
											</div>
											<AmountRadioWrapper
												onClick={() => onChange("custom")}
												className={clsx(
													"flex items-center justify-start flex-1 gap-2 py-3 px-4",
													value === "custom" && "active-donation"
												)}
											>
												<VyooRadio checked={value === "custom"} />
												<Text className="gray-text">Custom</Text>
											</AmountRadioWrapper>
										</>
									)}
								/>
								{amount === "custom" && (
									<Controller
										name="customAmount"
										control={control}
										rules={{
											required: "Donation amount is required",
											validate: val => {
												if (0 >= parseInt(val)) return "Amount can't be less than 1$";
											}
										}}
										render={({ onChange, value }) => (
											<LabeledInput
												name="customAmount"
												id="customAmount"
												onChange={onChange}
												type="number"
												value={value}
												placeholder="Donation Amount ( In Dollars )"
												error={errors?.customAmount?.message}
												rightIcon={{
													el: <DollarIcon />,
													active: false
												}}
											/>
										)}
									/>
								)}
							</div>
						</DonationCard>
					</div>
					<div className={clsx(step === 2 ? "block" : "hidden")}>
						{!useStripeCheckout && (
							<CCDetails control={control} errors={errors} currentStep={step === 2} askForCard includePersonalInfo />
						)}
						{!clientSecret && useStripeCheckout && (
							<LoaderWrapper>
								<Loader size="40px" color="inherit" variant="indeterminate" show />
							</LoaderWrapper>
						)}
						{clientSecret && useStripeCheckout && (
							<StripeCheckout
								clientSecret={clientSecret}
								control={control}
								errors={errors}
								donationId={donation?._id}
								contactId={contactId}
							/>
						)}
					</div>
					<div className={clsx(step === 3 ? "block" : "hidden")}>
						<CheckoutSummary
							summaryItem={summaryItem}
							card={cardNumber ? { brand: creditCardType(cardNumber.replace(/\s/g, "")), last4 } : undefined}
							itemPrice={moneyTotal}
							onSwitch={handleSwitchCards}
							isDonation
						/>
					</div>
				</MemberDialog>
			</form>
			{selectMemberDialog && <ChangeMemberDialog id={id} workspace={workspace || undefined} />}
		</>
	);
};

export default FundraiserDonationDialog;
