import config from "config/appConfig";

import { buildRouteTree } from "utils/routeUtils";

export const routes = buildRouteTree({
	dashboard: null,
	team: null,
	analytics: null,
	engage: null,
	messaging: null,
	manage: {
		boards: null,
		posts: null,
		events: null,
		files: null,
		series: null,
		albums: null,
		tracks: null,
		groups: null,
		videos: null,
		categories: null,
		reportedContent: null
	},
	fundraising: {
		fundraisers: null,
		donations: null
	},
	loyalty_rewards: null,
	marketplace: {
		companies: null
	},
	community: {
		":name": null
	},
	communities: null,
	payments: {
		payouts: null,
		transactions: null,
		reports: null
	},
	settings: {
		features: null,
		monetization: {
			plans: null,
			subscription: null,
			premium: null,
			subscription_and_premium: null,
			change: null
		},
		general: null,
		email_templates: null,
		autoconnect_messaging: null,
		faqs: null,
		community_privacy: null,
		other: null,
		profile: null
	},
	members: null,
	create: {
		post: null,
		group: null,
		track: null
	},
	edit: {
		post: {
			":id": null
		}
	},
	integrations: null,
	support: null,
	user: {
		signIn: null
	},
	trackshelp: null,
	fundraiser: null,
	email: { verified: null },
	unsubscribe: null
});

export const VARS = {
	CONTACT_US_EMAIL: `${config.GLOBAL_CONSTANTS.CONTACT_US_EMAIL}`,
	TERMS_AND_CONDITIONS_LINK: `${config.GLOBAL_CONSTANTS.TERMS_AND_CONDITIONS_LINK}`,
	PRIVACY_POLICY_LINK: `${config.GLOBAL_CONSTANTS.PRIVACY_POLICY_LINK}`,
	COOKIE_USE_LINK: `${config.GLOBAL_CONSTANTS.COOKIE_USE_LINK}`
};
