import React, { FC } from "react";

import { useHistory } from "react-router";

import { PlaceholderImage } from "shared/Components";
import { useMemberRoutes } from "shared/hooks";
import { AlbumModel } from "shared/services/useAlbumApiService";

import { PlaceholderImageType } from "shared/types";
import { Text } from "shared/ui-kit";

import { RecommendedAlbumWrapper } from "./style";

const Album: FC<{ album: AlbumModel }> = ({ album }) => {
	const history = useHistory();
	const { getMemberRoutesData } = useMemberRoutes();
	const { routes } = getMemberRoutesData();

	const handleSendToAlbum = () => {
		history.push(`${routes?.member.album.getPath()}/${album._id}`);
	};

	return (
		<RecommendedAlbumWrapper onClick={handleSendToAlbum}>
			{album.meta.url ? (
				<img src={album.meta.url} alt={album.title} />
			) : (
				<PlaceholderImage type={PlaceholderImageType.ALBUM_PREVIEW} width={266} height={164} viewBox={"0 0 720 320"} />
			)}
			<Text variant="caption" className="genre">
				{album.genre.map((g, i) => (i + 1 === album.genre.length ? g : `${g}, `))}
			</Text>
			<Text caption="h6">{album.title}</Text>
			<Text variant="caption" className="genre">
				{album.artist}
			</Text>
		</RecommendedAlbumWrapper>
	);
};

export default Album;
