import React from "react";

import { SkeletonPostBody, SkeletonPostHeader, SkeletonRow } from "./styles";

interface SkeletonLoaderProps {
	isMemberView?: boolean;
}

const SkeletonLoader: React.FC<SkeletonLoaderProps> = React.memo(({ isMemberView = false }) => (
	<>
		{isMemberView ? (
			<>
				<SkeletonPostHeader.Row>
					<SkeletonPostHeader.Item>
						<SkeletonRow variant="circle" width={40} height={40} />
					</SkeletonPostHeader.Item>
					<SkeletonPostHeader.Item>
						<SkeletonRow animation="wave" width={100} height={28} />
					</SkeletonPostHeader.Item>
					<SkeletonPostHeader.Item>
						<SkeletonRow animation="wave" width={200} height={28} />
					</SkeletonPostHeader.Item>
				</SkeletonPostHeader.Row>
				<SkeletonRow animation="wave" width={"100%"} height={40} />
				<SkeletonPostBody.Editor>
					<SkeletonRow animation="wave" width={"100%"} height={200} />
				</SkeletonPostBody.Editor>
			</>
		) : (
			<>
				<SkeletonRow animation="wave" width={260} height={48} />
				<SkeletonPostHeader.Row>
					<SkeletonPostHeader.Item>
						<SkeletonRow variant="circle" width={40} height={40} />
					</SkeletonPostHeader.Item>
					<SkeletonPostHeader.Item>
						<SkeletonRow animation="wave" width={100} height={28} />
					</SkeletonPostHeader.Item>
					<SkeletonPostHeader.Item>
						<SkeletonRow animation="wave" width={200} height={28} />
					</SkeletonPostHeader.Item>
					<SkeletonPostHeader.Item>
						<SkeletonRow animation="wave" width={200} height={28} />
					</SkeletonPostHeader.Item>
				</SkeletonPostHeader.Row>
				<SkeletonPostBody.Editor>
					<SkeletonRow animation="wave" width={"100%"} height={320} />
				</SkeletonPostBody.Editor>
				<SkeletonPostBody.ExtraSection>
					<SkeletonRow animation="wave" width={"100%"} height={56} />
				</SkeletonPostBody.ExtraSection>
			</>
		)}
	</>
));

export default SkeletonLoader;
