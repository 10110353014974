import { NavMenuItemModel, UserRoles } from "types";

import { routes } from "apps/RegularUser/constants";

export const navInfo: NavMenuItemModel[] = [
	{
		icon: "home",
		iconGroup: "filled",
		label: "Home",
		route: routes.dashboard.getPath(),
		id: "home"
	},
	{
		icon: "folder-plus",
		iconGroup: "filled",
		label: "Create Post",
		route: routes.create.post.getPath(),
		id: "createPost"
	},
	{
		icon: "box-gray",
		iconGroup: "color",
		label: "Manage",
		id: "Manage",
		route: null,
		children: [
			{
				label: "Manage Boards",
				route: routes.manage.boards.getPath(),
				id: "manageBoards"
			},
			{
				label: "Manage Posts",
				route: routes.manage.posts.getPath(),
				id: "ManagePosts"
			},
			{
				label: "Manage Events",
				route: routes.manage.events.getPath(),
				id: "manageEvents"
			},
			{
				label: "Manage Series",
				route: routes.manage.series.getPath(),
				id: "ManageSeries"
			},
			{
				label: "Manage Files",
				route: routes.manage.files.getPath(),
				id: "ManageFiles"
			},
			{
				label: "Manage Albums",
				route: routes.manage.albums.getPath(),
				id: "manageAlbums"
			},
			{
				label: "Manage Tracks",
				route: routes.manage.tracks.getPath(),
				id: "ManageTracks"
			},
			{
				label: "Manage Groups",
				route: routes.manage.groups.getPath(),
				id: "manageGroups"
			},
			{
				label: "Manage Videos",
				route: routes.manage.videos.getPath(),
				id: "ManageVideos"
			},
			{
				label: "Manage Categories",
				route: routes.manage.categories.getPath(),
				id: "manageCategories"
			},
			{
				label: "Reported Content",
				route: routes.manage.reportedContent.getPath(),
				id: "ManageContent"
			}
		]
	},
	{
		icon: "fundraiser",
		iconGroup: "color",
		label: "Fundraising",
		route: null,
		id: "fundraising",
		children: [
			{
				label: "Fundraisers",
				route: routes.fundraising.fundraisers.getPath(),
				id: "fundraisers"
			},
			{
				label: "Donations",
				route: routes.fundraising.donations.getPath(),
				id: "donations"
			}
		]
	},
	{
		icon: "gift",
		iconGroup: "filled",
		label: "Loyalty & Rewards",
		route: routes.loyalty_rewards.getPath(),
		hideForNonWhitelabel: true,
		id: "loyaltyRewards",
		featureValueName: "isLoyaltyEnabled"
	},
	{
		icon: "marketplace",
		iconGroup: "filled",
		label: "Marketplace Companies",
		route: routes.marketplace.companies.getPath(),
		id: "marketplaceCompanies"
	},
	{
		icon: "megaphone-gray",
		iconGroup: "color",
		label: "Engage",
		route: routes.engage.getPath(),
		id: "engage"
	},
	{
		icon: "user-friend",
		iconGroup: "filled",
		label: "Community Members",
		route: routes?.members.getPath(),
		id: "communityMembers"
	},
	{
		icon: "usd-circle-colourful",
		iconGroup: "color",
		label: "Payments",
		route: routes.payments.getPath(),
		id: "payment",
		children: [
			{
				label: "Payouts",
				route: routes.payments.payouts.getPath(),
				restrictTo: [UserRoles.Owner],
				id: "payouts"
			},
			{
				label: "Transactions",
				route: routes.payments.transactions.getPath(),
				id: "transactions"
			},
			{
				label: "Reports",
				route: routes.payments.reports.getPath(),
				restrictTo: [UserRoles.Owner],
				id: "reports"
			}
		]
	},
	{
		icon: "chart-pie",
		iconGroup: "filled",
		label: "Analytics",
		route: routes.analytics.getPath(),
		id: "analytics"
	},
	{
		icon: "envelope",
		iconGroup: "filled",
		label: "Messages",
		route: routes.messaging.getPath(),
		id: "messages"
	},
	{
		icon: "users-crown-gray",
		iconGroup: "color",
		label: "Team",
		route: routes.team.getPath(),
		id: "team"
	},
	{
		icon: "setting",
		label: "Settings",
		route: routes.settings.getPath(),
		id: "settings",
		children: [
			{
				label: "Features",
				route: routes.settings.features.getPath(),
				id: "featuresSettings"
			},
			{
				label: "Monetization",
				route: routes.settings.monetization.getPath(),
				id: "monetizationSettings"
			},
			{
				label: "Community FAQ",
				route: routes.settings.faqs.getPath(),
				id: "communityFAQSettings"
			},
			{
				label: "Email Templates",
				route: routes.settings.email_templates.getPath(),
				id: "emailTemplatesSettings"
			},
			{
				label: "Autoconnect / Message",
				route: routes.settings.autoconnect_messaging.getPath(),
				featureValueName: ["enableConnecting", "enableChat"],
				updateLabel: true,
				id: "autoConnectMessageSettings"
			},
			{
				label: "General settings",
				route: routes.settings.general.getPath(),
				id: "generalSettings"
			}
		]
	},
	{
		icon: "puzzle-piece",
		iconGroup: "filled",
		label: "Integrations",
		route: routes.integrations.getPath(),
		id: "integrations"
	},
	{
		icon: "handshake-gray",
		iconGroup: "color",
		label: "Help & Support",
		route: routes.support.getPath(),
		id: "helpSupport"
	}
];
