import React, { useEffect, useMemo, useState } from "react";

import { useMediaQuery } from "@material-ui/core";

import { usePodcasts } from "shared/hooks";
import * as appTheme from "theme/default";

import { FilterBlock, FilterOptionType, ModelBlock, ModelBlockType, PageWrapper } from "../../Components";
import { ManInLotus, MessageText, NoConnectionsBlock } from "../Connections/style";
import { TracksFilters } from "../Tracks";

const PodcastsPage = () => {
	const [filters, setFilters] = useState<{ offset: number; order: 1 | -1 }>({ offset: 1, order: 1 });

	const { getPodcasts, getPodcastsData } = usePodcasts();
	const { isLoading, podcasts } = getPodcastsData();

	const isMobile = useMediaQuery(appTheme.default.breakpoints.down("xs"));

	useEffect(() => {
		getPodcasts({ limit: 20, ...filters });
	}, [filters, getPodcasts]);

	const onEndScroll = () => {
		if (!isLoading && podcasts.length > 20) {
			setFilters(ctx => ({ ...ctx, offset: ctx.offset + 1 }));
		}
	};

	const filterOptions: FilterOptionType[] = useMemo(
		() => [
			{
				inlineOptions: true,
				onClick: item => {
					switch (item) {
						case TracksFilters.MOST_POPULAR:
							return setFilters({ order: -1, offset: 1 });
						case TracksFilters.MOST_RECENT:
							return setFilters({ order: 1, offset: 1 });
					}
				},
				listOptions: [
					{
						label: "Most Recent",
						value: TracksFilters.MOST_RECENT,
						default: true
					},
					{
						label: "Most Popular",
						value: TracksFilters.MOST_POPULAR
					}
				]
			}
		],
		[]
	);

	return (
		<PageWrapper>
			<FilterBlock options={filterOptions} />
			<ModelBlock
				autoFit={podcasts.length > 3 ? 258 : undefined}
				title={"Shows & Podcasts"}
				type={ModelBlockType.podcasts}
				items={podcasts || []}
				loading={isLoading}
				keepCurrentData={isLoading && filters.offset > 1}
				inlineView={!isMobile}
				onEndScroll={onEndScroll}
				noContent={
					// TODO: Move these elements inside modelblock if they're the same everywhere
					<NoConnectionsBlock>
						<ManInLotus />
						<MessageText>Your community don’t have any podcasts yet.</MessageText>
					</NoConnectionsBlock>
				}
			/>
		</PageWrapper>
	);
};

export default PodcastsPage;
