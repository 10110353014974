import React, { useCallback, useEffect, useMemo, useState } from "react";

import { InputAdornment, TextField } from "@material-ui/core";

import styled, { css } from "styled-components";

import { ReactComponent as SearchIcon } from "assets/icons/iconSearch.svg";
import { FilterBlock, FilterOptionType } from "modules/MemberHome/View/Components";
import { FilterType } from "modules/MemberHome/View/Containers/Files";
import { TracksFilters } from "modules/MemberHome/View/Containers/Tracks";
import { useDebounce, useUser, useVideo } from "shared/hooks";
import { VideoModel, VideoSortBy } from "shared/types";
import { Box } from "shared/ui-kit";

import VideoItem from "./VideoItem";

import SelectItemTemplate from "../Blocks/SelectItemTemplate";
import { EmptyBody, ScrollableBlock } from "../index";

const FilterWrapper = styled(Box)`
	margin: 16px 0;
	display: flex;
	flex-direction: row;
	align-content: center;
	justify-content: flex-start;

	${({ inline }) =>
		!inline &&
		css`
			div,
			button :not(:first-child) {
				margin-left: 8px;
			}

			${props => props.theme.breakpoints.down("sm")} {
				display: none;
			}
		`}
`;

const ContentWrapper = styled(Box)`
	height: ${({ isMemberView }) => (isMemberView ? "340px" : "50vh")};
	padding-top: ${({ isMemberView }) => (!isMemberView ? "20px" : "0")};
`;

const VideoItemsWrapper = styled(Box)`
	${props => props.theme.breakpoints.down("sm")} {
		display: flex;
		flex-direction: column;
		margin-top: 15px;
	}
`;

const LoaderBlock = () => (
	<>
		<SelectItemTemplate loading />
		<SelectItemTemplate loading />
		<SelectItemTemplate loading />
	</>
);

interface Props {
	onSelect: (g: VideoModel) => void;
}

const defaultFilters = {
	limit: 20,
	keyword: ""
};

const SelectVideoBody: React.FC<Props> = ({ onSelect }) => {
	const { getVideos, getAllVideoCount, getData: getVideoData } = useVideo();
	const { filteredVideos, loading, videoCount } = getVideoData();
	const [filters, setFilters] = useState<FilterType>({ offset: 1, sortBy: VideoSortBy.timestamp });

	const debouncedKeyword = useDebounce(filters.keyword, 400);

	const { getData: getUserData } = useUser();
	const { isMemberView, user } = getUserData();

	useEffect(() => {
		getVideos({
			...defaultFilters,
			...filters,
			sortBy: filters.sortBy as VideoSortBy,
			paginate: true,
			keyword: debouncedKeyword
		});
	}, [getVideos, filters, debouncedKeyword]);

	useEffect(() => {
		getAllVideoCount({ personaDocId: filters.personaDocId, keyword: debouncedKeyword });
	}, [getAllVideoCount, filters, debouncedKeyword]);

	const handleSearchChange = useCallback(
		e => {
			setFilters({ ...filters, keyword: e.target.value });
		},
		[filters]
	);

	const filterOptions: FilterOptionType[] = useMemo(
		() => [
			{
				inlineOptions: true,
				onClick: item => {
					switch (item) {
						case TracksFilters.MOST_POPULAR:
							return setFilters({ sortBy: VideoSortBy.popular, offset: 1 });
						case TracksFilters.MY_TRACKS:
							return setFilters({ sortBy: VideoSortBy.timestamp, personaDocId: user?.personaDocId, offset: 1 });
						default:
							setFilters({ sortBy: VideoSortBy.timestamp, offset: 1 });
					}
				},
				listOptions: [
					{
						label: "Most Recent",
						value: TracksFilters.MOST_RECENT,
						default: true
					},
					{
						label: "Most Popular",
						value: TracksFilters.MOST_POPULAR
					},
					{
						label: "My Videos",
						value: TracksFilters.MY_TRACKS
					}
				]
			}
		],
		[user]
	);

	return (
		<>
			<TextField
				// defaultValue={filter.keyword}
				onChange={handleSearchChange}
				name="keyword"
				placeholder="Search"
				variant="outlined"
				className="search-wrapper"
				InputProps={{
					startAdornment: (
						<InputAdornment position="start">
							<SearchIcon />
						</InputAdornment>
					)
				}}
			/>
			{isMemberView && (
				<FilterWrapper inline>
					<FilterBlock inline options={filterOptions} />
				</FilterWrapper>
			)}
			<ContentWrapper isMemberView={isMemberView}>
				<ScrollableBlock
					onEndScroll={() =>
						!loading && (filteredVideos?.length || 0) < videoCount && setFilters(x => ({ ...x, offset: x.offset + 1 }))
					}
				>
					{filteredVideos?.length ? (
						<VideoItemsWrapper>
							{filteredVideos.map((e, i) => (
								<VideoItem key={i} {...e} onSelect={() => onSelect(e)} />
							))}
							{loading && <LoaderBlock />}
						</VideoItemsWrapper>
					) : (
						<>{loading ? <LoaderBlock /> : <EmptyBody>Videos</EmptyBody>}</>
					)}
				</ScrollableBlock>
			</ContentWrapper>
		</>
	);
};

export default SelectVideoBody;
