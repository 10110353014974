import React, { ReactNode, useEffect, useState } from "react";

import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import clsx from "clsx";
import appConfig from "config/appConfig";
import MetaTags from "react-meta-tags";

import { useHistory, useLocation, useParams } from "react-router-dom";

import { useAuth } from "modules/App/Data";

import { useCommunity, useMemberRoutes, useRoutes, useUser } from "shared/hooks";
import useJwt from "shared/services/api/core/jwt";
import { IWorkspace } from "shared/types";
import { Button, ButtonMenu, Icon, Search } from "shared/ui-kit";

import {
	CommunityBlockWrapper,
	CreateCmtButton,
	LaunchBtn,
	Main,
	PageWrapper,
	StyledVyooLogo,
	TopBar,
	TopBarContainer
} from "./style";

import { routes as authRoutes } from "../../Auth/constants";
import CommunityBlock from "../Components/CommunityBlock";

interface LandingLayoutProps {
	children: ReactNode;
}

const LandingLayout: React.FC<LandingLayoutProps> = ({ children }) => {
	const { setUser, getData: getUserData } = useUser();
	const { workspaces } = getUserData();

	const { getData: getCommnunityData } = useCommunity();
	const { isWhitelabelAppMode, isAkinaMode, isReliasMode, isSportsMode, isBitcoinMode } = getCommnunityData();

	const { createNewCommunity, loginToCommunity } = useRoutes();
	const { getUser } = useAuth();
	const jwtService = useJwt();
	const history = useHistory();
	const { name } = useParams<{ name: string }>();
	const [isLanding, setIsLanding] = useState(false);
	const [isMarketing, setIsMarketing] = useState(false);
	const [signedIn, setSignedIn] = useState(false);

	const location = useLocation();

	const { generateRoutes, getMemberRoutesData } = useMemberRoutes();
	const { routes, workspace } = getMemberRoutesData();

	useEffect(() => {
		setSignedIn(!!jwtService.getJwt());
	}, [jwtService]);

	useEffect(() => {
		if (
			isWhitelabelAppMode &&
			(!routes || workspace !== appConfig.GLOBAL_CONSTANTS.ENV_APPLICATION_NAME.toLowerCase())
		) {
			generateRoutes(appConfig.GLOBAL_CONSTANTS.ENV_APPLICATION_NAME.toLowerCase());
		}
	}, [isWhitelabelAppMode, routes, workspace, generateRoutes]);

	useEffect(() => {
		setIsLanding(location.pathname === "/" || location.pathname === "/landing");
		setIsMarketing(name && location.pathname.includes("/community/"));
	}, [location, name]);

	useEffect(() => {
		(async () => {
			try {
				if (jwtService.getJwt() && jwtService.getWorkspace()) {
					const user = await getUser();
					setUser(user);
				}
			} catch (error) {
				const errorMessage = (error as Error).message;
				console.log(errorMessage);
			}
		})();
	}, [getUser, setUser, jwtService]);

	const isLandingOrMarketing = React.useMemo(() => {
		return isLanding || isMarketing;
	}, [isLanding, isMarketing]);

	const getLaunchOptions = React.useMemo(() => {
		const handleLogin = (workspace: IWorkspace) => {
			loginToCommunity(workspace);
		};

		const options =
			workspaces?.map((workspace, index) => (
				<CommunityBlockWrapper key={index} onClick={() => handleLogin(workspace)}>
					<CommunityBlock title={workspace.workspace} iconUrl={workspace.logo} />
				</CommunityBlockWrapper>
			)) || [];
		options.push(<hr />);
		options.push(
			<LaunchBtn
				size={"small"}
				buttonTheme={"outline"}
				fullWidth
				leftIcon={<Icon group={"filled"} fill={"black"} name={"sync"} />}
				onClick={() => history.push(authRoutes.auth.signIn.getPath())}
			>
				Try with another email
			</LaunchBtn>
		);
		options.push(
			<LaunchBtn
				size={"small"}
				buttonTheme={"outline"}
				fullWidth
				leftIcon={<Icon group={"filled"} fill={"black"} name={"plus"} />}
				onClick={createNewCommunity}
			>
				Create a new community
			</LaunchBtn>
		);
		return options;
	}, [workspaces, history, createNewCommunity, loginToCommunity]);

	return (
		<PageWrapper>
			{isSportsMode && (
				<MetaTags>
					<title>Create a Sports Community to Connect, Fundraise and Broadcast in minutes.</title>
					<link rel="icon" href="/vs-favicon.ico" />
					<link rel="apple-touch-icon" sizes="180x180" href="/vs-apple-touch-icon.png" />
					<link rel="icon" type="image/png" sizes="32x32" href="/vs-favicon-32x32.png" />
					<link rel="icon" type="image/png" sizes="16x16" href="/vs-favicon-16x16.png" />
				</MetaTags>
			)}
			<TopBar
				className={
					isAkinaMode
						? "workspace-kijijimoms"
						: isReliasMode
						? "workspace-holliblu"
						: isBitcoinMode
						? "workspace-bitcoin"
						: ""
				}
			>
				<TopBarContainer className={"header__container"}>
					<div className="left-side header__left-side">
						<div className="brand">
							<div className="brand-image">
								{isAkinaMode ? (
									<i className={"akina-logo"} />
								) : isReliasMode ? (
									<i className={"relias-logo"} />
								) : isBitcoinMode ? (
									<i className={"bitcoin-logo"} />
								) : (
									<StyledVyooLogo className={clsx(isSportsMode && "sports-mode")} />
								)}
							</div>
						</div>
					</div>
					{isLandingOrMarketing && (
						<div className="middle">
							<Search filled placeholder="Search for communities" />
						</div>
					)}
					<div className="right-side header__right-side">
						{isLanding && !signedIn && (
							<Button buttonTheme={"outline"} onClick={() => history.push(authRoutes.auth.signIn.getPath())}>
								Login
							</Button>
						)}
						{isMarketing && !signedIn && (
							<Button buttonTheme={"outline"} onClick={() => history.push(authRoutes.auth.signIn.getPath())}>
								Admin Login
							</Button>
						)}
						{!isWhitelabelAppMode && (
							<CreateCmtButton
								className={`${isWhitelabelAppMode ? "header__create-cmt-btn" : ""}`}
								onClick={createNewCommunity}
							>
								Create Community
							</CreateCmtButton>
						)}
						{signedIn && (isLanding || isMarketing) && !!workspaces?.length && (
							/* @ts-expect-error: valid params */
							<ButtonMenu customRender options={getLaunchOptions}>
								Launch ${appConfig.GLOBAL_CONSTANTS.ENV_APPLICATION_NAME}
							</ButtonMenu>
						)}
					</div>
				</TopBarContainer>
			</TopBar>
			<Main>
				<MuiPickersUtilsProvider utils={DateFnsUtils}>{children}</MuiPickersUtilsProvider>
			</Main>
		</PageWrapper>
	);
};

export default LandingLayout;
