import React, { useCallback } from "react";

import styled, { css } from "styled-components";

import { Box, ReadMore, Switch, Text } from "shared/ui-kit";

import { customBreakpoints } from "theme/default";

interface FeatureCardProps {
	title: string;
	text: string;
	enabled: boolean;
	icon?: JSX.Element;
	customStyle?: string;
	customWrapperText?: boolean;
	customRadioStyle?: string;
	handleChange?: (enabled: boolean) => void;
	switchId?: string;
}

const FeatureCardWrapper = styled(Box)`
	padding: 20px;

	display: flex;
	align-items: flex-start;
	text-align: left;

	background-color: #ffffff;

	${({ customStyle }) => {
		return customStyle
			? css`
					${customStyle}
			  `
			: "";
	}}

	${props => props.theme.breakpoints.down("sm")} {
		flex-direction: column;
		align-items: center;
	}
`;

const IconWrapper = styled(Box)`
	width: 90px;
	height: 90px;

	display: flex;
	align-items: center;
	justify-content: center;

	${props => props.theme.breakpoints.down(customBreakpoints.tabletVerticalMax)} {
		width: 100%;
	}
`;

const CustomWrapper = styled(Box)`
	width: 48px;
	height: 48px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #edf1f7;
	border-radius: 50%;
`;

const InfoWrapper = styled(Box)`
	width: calc(100% - 180px);

	padding-left: 20px;

	${props => props.theme.breakpoints.down(customBreakpoints.tabletVerticalMax)} {
		width: 100%;
	}

	${props => props.theme.breakpoints.down("sm")} {
		padding-left: 0;
	}
`;

const Title = styled(Text)`
	font-size: 18px;

	${props => props.theme.breakpoints.down("xs")} {
		font-size: 13px;
	}
`;

const Description = styled(Box)`
	margin-top: 10px;

	.text,
	.read-more {
		margin: 0;
		font-size: 15px;
		line-height: 20px;

		${props => props.theme.breakpoints.down("xs")} {
			font-size: 13px;
		}
	}
`;

const SwitchWrapper = styled(Box)`
	display: flex;
	justify-content: center;
	align-items: center;

	width: 90px;
	height: 90px;

	${props => props.theme.breakpoints.down("sm")} {
		height: 40px;
	}

	${({ customRadioStyle }) => {
		return customRadioStyle
			? css`
					${customRadioStyle}
					${props => props.theme.breakpoints.down("sm")} {
						justify-content: center;
					}
			  `
			: "";
	}}
`;

const FeatureCard: React.FC<FeatureCardProps> = ({
	title,
	text,
	icon,
	enabled,
	handleChange,
	customStyle,
	customWrapperText,
	customRadioStyle,
	switchId
}: FeatureCardProps) => {
	const handleSwitch = useCallback(
		e => {
			handleChange && handleChange(e.target.checked);
		},
		[handleChange]
	);
	const IconStyledWrapper = customWrapperText ? CustomWrapper : IconWrapper;

	return (
		<FeatureCardWrapper customStyle={customStyle}>
			<IconStyledWrapper>{icon && React.isValidElement(icon) ? icon : ""}</IconStyledWrapper>
			<InfoWrapper>
				<Title variant="h6">{title}</Title>
				<Description variant="body1">
					<ReadMore wrapClassName={""} wrapTextClassName={""} showCharCount={200} text={text} />
				</Description>
			</InfoWrapper>
			<SwitchWrapper customRadioStyle={customRadioStyle}>
				<Switch checked={enabled} onChange={handleSwitch} id={switchId} />
			</SwitchWrapper>
		</FeatureCardWrapper>
	);
};

export default FeatureCard;
