import React, { memo, useCallback, useEffect, useMemo, useState } from "react";

import { IconButton, Typography } from "@material-ui/core";
import clsx from "clsx";
import config from "config/appConfig";

import { Controller, useForm } from "react-hook-form";

import { useHistory, useLocation } from "react-router-dom";

import { CommunityModel } from "types";

import { VARS } from "apps/Auth/constants";
// import { GoogleLogin } from "react-google-login";

import { routes as adminRoutes } from "apps/RegularUser/constants";
import { BannedUserModal } from "shared/Components";
import { useCommunity, useMemberRoutes, useNotification, useRoutes, useUser } from "shared/hooks";
import useLocalStorage from "shared/services/localStorage/localStorage";
import { IWorkspace, UserStatus } from "shared/types";
import { Box, Button, Checkbox, Icon, Input, Loader, Text } from "shared/ui-kit";

// import { ReactComponent as GoogleIcon } from "assets/icons/google.svg";

import { validators } from "shared/ui-kit/utils/dynamicForm/validators";
import { validateEmail } from "utils/serviceUtils/validators";

import { AgreementText, AuthPagesContainer, ContentWrapperBlock, OtherLoginOptions, StyledLink } from "./styles";

import { useAuth } from "../../../Data";
import { PasswordInstructions } from "../../Components";
import AuthError from "../../Components/AuthError";
import { ContactUsText, Subtitle } from "../CreatePassword/style";
import { InputWrapper } from "../style";

interface InvitationModel {
	workspace: string;
	email?: string;
	userId?: string;
	globalUserId?: string;
	invitationId?: string;
}

export interface SignInProps {
	communityListRoute: string;
	forgotPassword?: string;
	signInRoute?: string;
	TERMS_AND_CONDITION: string;
	PRIVACY_POLICY_LINK: string;
	COOKIE_USE_LINK: string;
	sendToCreateCommunity?: boolean;
	memberHomePage: string;
	memberSignInPage: string;
	memberFillProfilePage: string;
	invitationCodePage: string;
	invitationData?: InvitationModel;
	joinCommunitiesUrl: string;
	community?: string;
	redirectUrl?: string | null;
}

const SignIn: React.FC<SignInProps> = memo(
	({
		forgotPassword,
		signInRoute,
		communityListRoute,
		memberHomePage,
		TERMS_AND_CONDITION,
		PRIVACY_POLICY_LINK,
		COOKIE_USE_LINK,
		sendToCreateCommunity,
		memberSignInPage,
		memberFillProfilePage,
		invitationCodePage,
		invitationData,
		joinCommunitiesUrl,
		redirectUrl
	}) => {
		const history = useHistory();
		const { getCustomWorkspaceDetails, getData: getCommunityData } = useCommunity();
		const { isAkinaMode, isReliasMode, isBitcoinMode, mainAdminColor, isSportsMode } = getCommunityData();

		const {
			setEmail,
			getUserGlobalId,
			signupNewUser,
			signInUser,
			setSubscribe,
			login,
			verifyOnboardingToken,
			joinCommunity,
			setInvitationData,
			getData: getAuthData
		} = useAuth();
		const { email, isLoading, subscribe } = getAuthData();

		const { setUserWorkspaces, setGlobalUserId } = useUser();

		const { generateRoutes, getMemberRoutesData } = useMemberRoutes();
		const { routes, workspace } = getMemberRoutesData();

		const localStorage = useLocalStorage();

		const { search } = useLocation();
		const { createNewCommunity } = useRoutes();
		const query = new URLSearchParams(search);
		const passwordReset = query.get("passwordReset");

		const { showMessage } = useNotification();
		// const [supportGoogleSignIn, setSupportGoogleSignIn] = useState<boolean>(true);
		const [showLoginPasswordFlow, setShowLoginPasswordFlow] = useState(false);
		const [showSignupPasswordFlow, setShowSignupPasswordFlow] = useState(false);
		const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
		const [showPassword, setShowPassword] = useState(false);
		const [authError, setAuthError] = useState("");
		const [onboardingCommunityInfo, setOnboardingCommunityInfo] = useState<CommunityModel>();
		const [isVerifying, setIsVerifying] = useState(false);
		const [whitelabelEmailToken, setWhitelabelEmailToken] = useState<string>();
		const [inputType, setInputType] = useState<"password" | "conformPassword">("password");
		const [showBanned, setShowBanned] = useState<{ open: boolean; workspaceName?: string; workspaceUrl?: string }>();

		const { handleSubmit, control, errors, formState, getValues, setValue, watch } = useForm({ mode: "onChange" });

		const { password, passwordConfirm } = watch(["password", "passwordConfirm"]);

		useEffect(() => {
			if (invitationData?.workspace && (!routes || invitationData.workspace !== workspace)) {
				generateRoutes(invitationData.workspace);
			}
		}, [generateRoutes, invitationData?.workspace, routes, workspace]);

		useEffect(() => {
			if (passwordReset) {
				showMessage("Your password was successfully changed, you can login.");
			}
		}, [passwordReset, showMessage]);

		useEffect(() => {
			const fetchWorkspaceInfo = async (url: string) => {
				const workspaceInfo = await getCustomWorkspaceDetails(url);
				if (workspaceInfo) {
					setOnboardingCommunityInfo(workspaceInfo);
				}
			};

			if (invitationData && invitationData.workspace) {
				fetchWorkspaceInfo(invitationData.workspace);
			} else if (isAkinaMode) {
				fetchWorkspaceInfo(config.GLOBAL_CONSTANTS.AKINA_WORKSPACE);
			} else if (isReliasMode) {
				fetchWorkspaceInfo(config.GLOBAL_CONSTANTS.HOLLIBLU_WORKSPACE);
			} else if (isBitcoinMode) {
				fetchWorkspaceInfo(config.GLOBAL_CONSTANTS.BITCOIN_WORKSPACE);
			}
		}, [getCustomWorkspaceDetails, invitationData, isAkinaMode, isReliasMode, isBitcoinMode]);

		const progressUser = useMemo(
			() => (token: string) => {
				localStorage.set(config.TOKEN_KEY, token);
				if (sendToCreateCommunity) {
					createNewCommunity(isSportsMode);
				} else {
					if (isAkinaMode && config.GLOBAL_CONSTANTS.AKINA_WORKSPACE) {
						login(token, config.GLOBAL_CONSTANTS.AKINA_WORKSPACE);
						history.push(redirectUrl || `${memberHomePage}${search}`);
					} else if (isReliasMode && config.GLOBAL_CONSTANTS.HOLLIBLU_WORKSPACE) {
						login(token, config.GLOBAL_CONSTANTS.HOLLIBLU_WORKSPACE);
						history.push(redirectUrl || `${memberHomePage}${search}`);
					} else if (isBitcoinMode && config.GLOBAL_CONSTANTS.BITCOIN_WORKSPACE) {
						login(token, config.GLOBAL_CONSTANTS.BITCOIN_WORKSPACE);
						history.push(redirectUrl || adminRoutes.dashboard.getPath());
					} else {
						history.push(redirectUrl || `${communityListRoute}${search}`);
					}
				}
			},
			[
				isSportsMode,
				communityListRoute,
				createNewCommunity,
				history,
				localStorage,
				search,
				sendToCreateCommunity,
				memberHomePage,
				login,
				isAkinaMode,
				isReliasMode,
				isBitcoinMode,
				redirectUrl
			]
		);

		const checkStatus = useCallback(
			async ({
				token,
				workspaces,
				status,
				userId,
				isPrivateCommunityStatus,
				globalUserId
			}: {
				token: string;
				workspaces?: IWorkspace[];
				status?: UserStatus;
				userId?: string;
				isPrivateCommunityStatus?: boolean;
				globalUserId?: string;
			}) => {
				if (workspaces) {
					setUserWorkspaces(workspaces);
				}

				let isPrivate = onboardingCommunityInfo?.isPrivate;
				if (isPrivateCommunityStatus !== undefined && onboardingCommunityInfo) {
					setOnboardingCommunityInfo({
						...onboardingCommunityInfo,
						isPrivate:
							isPrivate !== onboardingCommunityInfo?.isPrivate
								? isPrivateCommunityStatus
								: onboardingCommunityInfo?.isPrivate
					});
					isPrivate = isPrivateCommunityStatus;
				}

				if (status) {
					const workspace =
						isAkinaMode && config.GLOBAL_CONSTANTS.AKINA_WORKSPACE
							? config.GLOBAL_CONSTANTS.AKINA_WORKSPACE
							: isReliasMode && config.GLOBAL_CONSTANTS.HOLLIBLU_WORKSPACE
							? config.GLOBAL_CONSTANTS.HOLLIBLU_WORKSPACE
							: isBitcoinMode && config.GLOBAL_CONSTANTS.BITCOIN_WORKSPACE
							? config.GLOBAL_CONSTANTS.BITCOIN_WORKSPACE
							: invitationData && invitationData.workspace
							? invitationData.workspace
							: "";

					if (status === UserStatus.ON_BOARDED) {
						progressUser(token);
					} else if (status === UserStatus.JOINED || (!isPrivate && status === UserStatus.PENDING)) {
						history.push(
							`${memberFillProfilePage}?token=${token}&workspace=${workspace}${
								globalUserId ? `&globalUserId=${globalUserId}` : ""
							}${onboardingCommunityInfo?.communityUrl ? `&communityUrl=${onboardingCommunityInfo.communityUrl}` : ""}${
								invitationData?.invitationId ? `&invitationId=${invitationData.invitationId}` : ""
							}`
						);
					} else {
						if (isPrivate) {
							// PENDING, DELETED statuses
							if (invitationData && invitationData?.invitationId) {
								setIsVerifying(true);

								const verificationInfo = await verifyOnboardingToken({
									communityUrl: `${onboardingCommunityInfo?.communityUrl}`,
									invitationId: invitationData.invitationId,
									userId: userId || invitationData?.userId || ""
								});

								const info = verificationInfo as IWorkspace;

								if (info?.token && info?.status) {
									const workspaceName = invitationData.workspace;
									if (info.status === UserStatus.ON_BOARDED) {
										history.push(`${memberSignInPage}?token=${info.token}&workspace=${workspaceName}`);
									} else if (info.status === UserStatus.JOINED) {
										if (onboardingCommunityInfo?.communityUrl && invitationData?.invitationId) {
											setInvitationData({
												communityUrl: onboardingCommunityInfo?.communityUrl,
												invitationId: invitationData?.invitationId
											});
										}

										history.push(
											`${memberFillProfilePage}?token=${info.token}&workspace=${workspaceName}&globalUserId=${
												globalUserId || userId || invitationData.userId
											}${
												onboardingCommunityInfo?.communityUrl
													? `&communityUrl=${onboardingCommunityInfo.communityUrl}`
													: ""
											}${invitationData?.invitationId ? `&invitationId=${invitationData.invitationId}` : ""}`
										);
									}
								} else {
									setAuthError("Something went wrong, please try again");
								}

								setIsVerifying(false);
							} else {
								history.push(
									`${invitationCodePage}?workspace=${workspace}&userId=${userId}&communityUrl=${onboardingCommunityInfo?.communityUrl}`
								);
							}
						}
					}
				} else {
					if (invitationData) {
						let isNeedToJoin = false;

						if (workspaces?.length) {
							const workspaceName = invitationData.workspace;

							const invitedWorkspaceInfo = workspaces.find(x => x.workspace === workspaceName);
							if (invitedWorkspaceInfo) {
								const { status, userId, token: invitationWorkspaceToken } = invitedWorkspaceInfo;
								checkStatus({ token: invitationWorkspaceToken || token, workspaces, status, userId, globalUserId });
							} else if (onboardingCommunityInfo?.communityUrl) {
								isNeedToJoin = true;
							}
						} else if (invitationData?.workspace && onboardingCommunityInfo?.communityUrl) {
							isNeedToJoin = true;
						}

						if (isNeedToJoin && onboardingCommunityInfo?.communityUrl) {
							const workspaceInfo = await joinCommunity(email, onboardingCommunityInfo.communityUrl);
							// confirmInvitation({ communityUrl: onboardingCommunityInfo.communityUrl, userId, invitationCode: invitationData.invitationId });

							if (workspaceInfo) {
								if (workspaceInfo?.status === UserStatus.BANNED) {
									setShowBanned({
										open: true,
										workspaceName: invitationData?.workspace,
										workspaceUrl: onboardingCommunityInfo.communityUrl
									});
								}

								checkStatus({
									token: workspaceInfo?.token,
									workspaces: [
										{
											...workspaceInfo,
											workspace: workspaceInfo?.workspace || invitationData.workspace
										}
									],
									status: workspaceInfo.status || UserStatus.PENDING,
									isPrivateCommunityStatus: !workspaceInfo?.token,
									userId: workspaceInfo?.userId,
									globalUserId
								});
							}
						}
					} else {
						progressUser(token);
					}
				}
			},
			[
				progressUser,
				history,
				invitationCodePage,
				onboardingCommunityInfo,
				memberSignInPage,
				memberFillProfilePage,
				setUserWorkspaces,
				invitationData,
				verifyOnboardingToken,
				email,
				joinCommunity,
				isAkinaMode,
				isReliasMode,
				isBitcoinMode,
				setInvitationData
			]
		);

		const loginUser = useCallback(
			async data => {
				const { token, workspaces, status, userId, globalUserId } = await signInUser(
					data.email.toLowerCase(),
					data.password
				);
				if (token) {
					checkStatus({ token, workspaces, status, userId, globalUserId });
				} else {
					setAuthError("Password is incorrect.");
				}
			},
			[setAuthError, signInUser, checkStatus]
		);

		const validateUserEmail = useCallback(
			async (email: string) => {
				const { havePassword, token } = await getUserGlobalId(email, true);

				setEmail(email);

				if (token) {
					setWhitelabelEmailToken(token);
				}

				if (!havePassword) {
					setShowSignupPasswordFlow(true);
				} else {
					setShowLoginPasswordFlow(true);
				}
			},
			[getUserGlobalId, setEmail]
		);

		const signupUser = useCallback(
			async data => {
				const { token, workspaces, status, userId, globalUserId } = await signupNewUser({
					email: data.email.toLowerCase(),
					password: data.password,
					fromDeepLink: !!invitationData?.email && !!invitationData.invitationId,
					token: whitelabelEmailToken
				});
				if (token) {
					checkStatus({ token, workspaces, status: status as UserStatus, userId, globalUserId });
				} else {
					setAuthError("Something went wrong, please try again");
				}
			},
			[checkStatus, invitationData, signupNewUser, whitelabelEmailToken]
		);

		useEffect(() => {
			if (invitationData?.email) {
				setValue("email", invitationData.email, { shouldDirty: true, shouldValidate: true });

				if (invitationData?.globalUserId) {
					setGlobalUserId(invitationData.globalUserId);
				}
			}
		}, [invitationData, setValue, setGlobalUserId]);

		useEffect(() => {
			if (invitationData?.email) {
				validateUserEmail(invitationData.email);
			}
		}, [invitationData, validateUserEmail]);

		// const onSuccessResponseGoogle = async (data: any) => {
		// 	if (data.profileObj && data.profileObj.email) {
		// 		const { exists } = await getUserGlobalId(data.profileObj.email);
		// 		setEmail(data.profileObj.email);

		// 		if (!exists) {
		// 			setShowSignupPasswordFlow(true);
		// 		} else {
		// 			setShowLoginPasswordFlow(true);
		// 		}
		// 	}
		// };

		// const onErrorResponseGoogle = (err: any) => {
		// 	if (err && err.error === "idpiframe_initialization_failed") {
		// 		setSupportGoogleSignIn(false);
		// 	}
		// };

		const onSubmit = useCallback(
			async data => {
				if (showLoginPasswordFlow) {
					await loginUser(data);
				} else if (showSignupPasswordFlow) {
					await signupUser(data);
				} else {
					await validateUserEmail(data.email);
				}
			},
			[showLoginPasswordFlow, showSignupPasswordFlow, loginUser, signupUser, validateUserEmail]
		);

		const handleEmailChange = (onChange: (...event: any[]) => void, e: InputEvent) => {
			if (showLoginPasswordFlow) {
				setShowLoginPasswordFlow(false);
			}

			if (showSignupPasswordFlow) {
				setShowSignupPasswordFlow(false);
			}

			onChange(e);
		};

		const handleSubscribeChange = useCallback(
			e => {
				setSubscribe(e.target.checked);
			},
			[setSubscribe]
		);

		const hidePasswordInputs = useCallback(() => {
			setShowLoginPasswordFlow(false);
			setShowSignupPasswordFlow(false);
		}, []);

		const togglePasswordShow = () => {
			setShowPassword(!showPassword);
		};

		const handleFocusPassword = (event: React.MouseEvent<HTMLElement>, confirm = false) => {
			setAnchorEl(event.currentTarget);
			setInputType(confirm ? "conformPassword" : "password");
		};

		return (
			<>
				<ContentWrapperBlock>
					<PasswordInstructions
						password={inputType === "password" ? password : passwordConfirm}
						anchorEl={anchorEl}
						setAnchorEl={setAnchorEl}
					/>
					<AuthPagesContainer>
						<Box className="header">
							<Text variant="h3">
								{sendToCreateCommunity
									? "Create Your Community"
									: `Join ${config.GLOBAL_CONSTANTS.ENV_APPLICATION_NAME}`}
							</Text>
							<Subtitle variant="body1">
								{sendToCreateCommunity
									? "Continue with your email address & password to create your community."
									: `Use your email address to login or signup to ${config.GLOBAL_CONSTANTS.ENV_APPLICATION_NAME}.`}
							</Subtitle>
							{/* <Subtitle variant="body1">Continue with the Google account or email address you use to join.</Subtitle> */}
						</Box>
						{/* {supportGoogleSignIn && (
					<>
						<Box className="google-login">
							<GoogleLogin
								clientId={`${config.GLOBAL_CONSTANTS.GOOGLE_CLIENT_ID}`}
								render={renderProps => (
									<ManageBtn
										removeSideMargin
										fullWidth
										buttonTheme={"outline"}
										size="large"
										onClick={renderProps.onClick}
										leftIcon={<GoogleIcon />}
									>
										Continue with Google
									</ManageBtn>
								)}
								onSuccess={onSuccessResponseGoogle}
								onFailure={onErrorResponseGoogle}
								cookiePolicy={"single_host_origin"}
							/>
						</Box>
						<DelimiterWrapper>
							<Delimiter />
							<DelimiterText>OR</DelimiterText>
						</DelimiterWrapper>
					</>
				)} */}
						<Box className="inputs">
							<AuthError message={authError} />
							<form onSubmit={handleSubmit(onSubmit)}>
								<Controller
									name="email"
									control={control}
									defaultValue={email}
									rules={{ required: "", validate: value => validateEmail(value) }}
									render={({ onChange, value }) => (
										<InputWrapper>
											<Input
												id="email"
												label="Email Address"
												value={value.toLowerCase()}
												errorText={value && errors.email ? "This email isn't valid" : ""}
												onChange={e => handleEmailChange(onChange, e)}
												rightIcon={value && !errors.email ? <Icon fill={"#23b34a"} name={"check-circle"} /> : undefined}
												fakeDisabled={showLoginPasswordFlow || showSignupPasswordFlow}
												onClick={hidePasswordInputs}
											/>
										</InputWrapper>
									)}
								/>
								{showLoginPasswordFlow && (
									<Controller
										name="password"
										defaultValue=""
										control={control}
										rules={{
											required: "Field is required."
										}}
										render={({ onChange, value }) => (
											<InputWrapper>
												<Input
													id="password"
													label="Password"
													type={showPassword ? "text" : "password"}
													errorText={errors.password?.message}
													value={value}
													onChange={onChange}
												/>
												<IconButton className="show-password" onClick={togglePasswordShow}>
													<Icon name={showPassword ? "eye-slash" : "eye"} group="filled" fill="#c5cee0" />
												</IconButton>
											</InputWrapper>
										)}
									/>
								)}
								{showSignupPasswordFlow && (
									<>
										<Controller
											name="password"
											defaultValue=""
											control={control}
											rules={{
												required: "Field is required.",
												minLength: {
													value: 8,
													message: "Password length should be between 8 to 20"
												},
												maxLength: {
													value: 20,
													message: "Password length should be between 8 to 20"
												},
												validate: validators.password
											}}
											render={({ onChange, value }) => (
												<InputWrapper>
													<Input
														id="password"
														label="Password"
														errorText={errors.password?.message}
														type={showPassword ? "text" : "password"}
														value={value}
														onChange={onChange}
														onFocus={handleFocusPassword}
													/>
													<IconButton className="show-password" onClick={togglePasswordShow}>
														<Icon name={showPassword ? "eye-slash" : "eye"} group="filled" fill="#c5cee0" />
													</IconButton>
												</InputWrapper>
											)}
										/>
										<Controller
											name="passwordConfirm"
											defaultValue=""
											control={control}
											rules={{
												required: "Field is required.",
												validate: value => {
													if (value !== getValues("password")) {
														return "Passwords are not equal";
													}
												}
											}}
											render={({ onChange, value }) => (
												<InputWrapper>
													<Input
														id="confirmPassword"
														label="Confirm Password"
														errorText={errors.passwordConfirm?.message}
														type={showPassword ? "text" : "password"}
														value={value}
														onChange={onChange}
														onFocus={e => handleFocusPassword(e, true)}
													/>
													<IconButton className="show-password" onClick={togglePasswordShow}>
														<Icon name={showPassword ? "eye-slash" : "eye"} group="filled" fill="#c5cee0" />
													</IconButton>
												</InputWrapper>
											)}
										/>
										<Checkbox
											checked={subscribe}
											label={
												<Typography variant="body2">
													Receive {config.GLOBAL_CONSTANTS.ENV_APPLICATION_NAME} Platform updates, news, tips and
													offers?
												</Typography>
											}
											onChange={handleSubscribeChange}
										/>
									</>
								)}
								<Button
									className={clsx(showSignupPasswordFlow ? "mt-3" : "mt-0", "mb-1.5")}
									id={showLoginPasswordFlow || showSignupPasswordFlow ? "join" : "continue"}
									type="submit"
									removeSideMargin
									fullWidth
									size="large"
									disabled={!formState.isValid || isLoading || isVerifying}
								>
									{isLoading || isVerifying ? (
										<Loader size="15px" color="secondary" show variant="indeterminate" />
									) : showLoginPasswordFlow || showSignupPasswordFlow ? (
										"Join"
									) : (
										"Continue"
									)}
								</Button>
							</form>

							<OtherLoginOptions>
								{showSignupPasswordFlow && (
									<AgreementText variant="caption" className="caption">
										By continuing you’re agree to the{" "}
										{TERMS_AND_CONDITION && (
											<>
												<a href={TERMS_AND_CONDITION} target="_blank" rel="noopener noreferrer">
													Terms & Conditions
												</a>
												,{" "}
											</>
										)}
										{PRIVACY_POLICY_LINK && (
											<a href={PRIVACY_POLICY_LINK} target="_blank" rel="noopener noreferrer">
												Privacy Policy
											</a>
										)}
										{COOKIE_USE_LINK && (
											<>
												and
												<a href={COOKIE_USE_LINK} target="_blank" rel="noopener noreferrer">
													Cookie use
												</a>
											</>
										)}{" "}
										of {config.GLOBAL_CONSTANTS.ENV_APPLICATION_NAME} Platform.
									</AgreementText>
								)}
								{forgotPassword && (
									<StyledLink to={forgotPassword} color={mainAdminColor}>
										Forgot Password?
									</StyledLink>
								)}
								{signInRoute && (
									<StyledLink color={mainAdminColor} to={signInRoute}>
										Join as a member?
									</StyledLink>
								)}
							</OtherLoginOptions>
						</Box>
					</AuthPagesContainer>
					<ContactUsText href={`mailto:${VARS.CONTACT_US_EMAIL}?subject=Community help`} color={mainAdminColor}>
						Contact Us
					</ContactUsText>
				</ContentWrapperBlock>
				{showBanned?.open && (
					<BannedUserModal
						open
						communityName={showBanned?.workspaceName}
						workspaceUrl={showBanned?.workspaceUrl}
						communitiesPageLink={communityListRoute}
						joinCommunitiesUrl={joinCommunitiesUrl}
					/>
				)}
			</>
		);
	}
);

export default SignIn;
