import React, { useMemo } from "react";

import SkeletonRow from "./SkeletonRow";

const MonetizationSkeleton = () =>
	useMemo(
		() => (
			<>
				<SkeletonRow />
				<SkeletonRow />
				<SkeletonRow />
				<SkeletonRow />
			</>
		),
		[]
	);
export default MonetizationSkeleton;
