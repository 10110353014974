import React, { useEffect, useMemo, useState } from "react";

import { useMediaQuery } from "@material-ui/core";
import config from "config/appConfig";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import { routes } from "apps/Onboarding/constants";

import { PrivacyField } from "shared/Components";
import { useCommunity, useGTM } from "shared/hooks";
import {
	Avatar,
	Box,
	Button,
	FormContainer,
	FormFooter,
	Icon,
	Input,
	Loader,
	Text,
	Uploader,
	deviceType,
	orientationType
} from "shared/ui-kit";
import { ReactComponent as ExclamationTriangle } from "shared/ui-kit/assets/icons/filled/exclamation-triangle.svg";
import Frame from "shared/ui-kit/atoms/Frame";
import { orientationConst } from "shared/ui-kit/molecules/Uploader";
import theme, { customBreakpoints } from "theme/default";

import { scrollTo } from "utils/scrollTo";
import { correctName, downscaleImage, toBase64 } from "utils/serviceUtils/helpers";

import { ContentWrapper, FormBlock, Preview } from "../../Components";

export interface CreateProps {}

const ContentBlock = styled.section`
	display: flex;
	align-items: center;
	width: 100%;

	${props => props.theme.breakpoints.down("sm")} {
		flex-direction: column;
	}
`;

const FormBlockWrapper = styled(Box)`
	width: 60%;
	display: flex;
	justify-content: center;

	${props => props.theme.breakpoints.down(customBreakpoints.tabletHorizontalMax)} {
		width: 65%;
	}

	${props => props.theme.breakpoints.down(customBreakpoints.tabletVerticalMax)} {
		width: 65%;
	}

	${props => props.theme.breakpoints.down("sm")} {
		width: 100%;
	}
`;

const FrameWrapper = styled(Box)`
	width: 40%;
	display: flex;
	justify-content: flex-start;
	padding-left: 6%;

	.screen {
		background: white;
	}

	${props => props.theme.breakpoints.down(customBreakpoints.tabletHorizontalMax)} {
		width: 35%;
		padding-left: 0;
	}

	${props => props.theme.breakpoints.down(customBreakpoints.tabletVerticalMax)} {
		width: 35%;

		padding-left: 3%;
	}

	${props => props.theme.breakpoints.down("sm")} {
		width: 100%;
		padding-left: 0;
		justify-content: center;

		margin-top: 26px;
	}
`;

const InputNameWrapper = styled(Box)`
	margin: 27px 0 0 0;

	${props => props.theme.breakpoints.down("sm")} {
		margin-top: 30px;
	}
`;

const InputURLWrapper = styled(Box)`
	position: relative;
	margin-top: 23px;

	.MuiSvgIcon-root {
		color: #db2c33;
		margin-right: 120px;
	}

	.MuiInputBase-input {
		max-width: 77%;
		padding: 14px 12px;
	}

	.MuiInputAdornment-positionEnd {
		margin-left: -30px;
	}

	${props => props.theme.breakpoints.down("sm")} {
		margin-top: 16px;
	}
`;

const ExclamationTriangleIcon = styled(ExclamationTriangle)`
	path {
		&:last-child {
			fill: red;
		}
	}
`;

const InputURLVyoo = styled(Box)`
	position: absolute;

	top: 1px;
	right: 1px;

	height: 46px;

	background-color: #f7f9fc;

	color: #222b45;
	font-weight: 600;

	display: flex;
	align-items: center;
	justify-content: center;

	padding: 0 8px;

	border-top-right-radius: 3px;
	border-bottom-right-radius: 3px;
`;

const AvailableUrls = styled(Box)`
	margin-top: 12px;
`;

AvailableUrls.Title = styled(Text)`
	margin-right: 8px;

	font-weight: 600;
	font-size: 13px;
	line-height: 24px;
	color: #222b45;
`;

AvailableUrls.Link = styled(Text)`
	display: inline-block;
	font-weight: 600;
	font-size: 13px;
	line-height: 24px;
	color: #6173fe;
	text-decoration: none;

	padding-right: 16px;

	cursor: pointer;
`;

const LoaderWrapper = styled(Box)`
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	right: 75px;
`;

const UploaderWrapper = styled(Box)`
	width: 100%;
	margin-top: 24px;

	.item-description {
		display: none;
	}

	> div {
		> div {
			width: 100%;
		}
	}

	.preview-section {
		.item-img-section {
			max-width: 120px;
			max-height: 120px;
			img {
				max-width: 100px;
			}
		}
	}

	.explorer-uploader-label-text {
		color: #222b45;
	}
	.explorer-uploader .description .anchor {
		color: #6173fe;
	}

	${props => props.theme.breakpoints.down("sm")} {
		margin-top: 15px;
	}
`;

const FormFooterWrapper = styled(FormFooter)`
	margin-top: 37px;

	${props => props.theme.breakpoints.down("sm")} {
		margin-top: 10px;
	}
`;

const FrameContentWrapper = styled(FormContainer)`
	height: 100%;
	padding: 24px 0;
`;

const FrameTextName = styled(Text)`
	width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;

	margin-top: 60px;

	${props => props.theme.breakpoints.down(customBreakpoints.tabletHorizontalMax)} {
		margin-top: 35px;
	}

	${props => props.theme.breakpoints.down("sm")} {
		font-size: 18px;
		margin-top: 60px;
	}
`;

const FrameTextUrl = styled(Text)`
	width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;

	margin-top: 16px;

	${props => props.theme.breakpoints.down(customBreakpoints.tabletHorizontalMax)} {
		font-size: 12px;
		margin-top: 14px;
	}

	${props => props.theme.breakpoints.down("sm")} {
		font-size: 15px;
	}
`;

const StyledPrivacyField = styled(PrivacyField)`
	flex: 1;
	margin: 24px 0;

	& > div {
		background-color: ${theme.palette.common.white};
		border-color: #e4e9f2;
	}
`;

const Create: React.FC<CreateProps> = () => {
	const history = useHistory();
	const { addEvent } = useGTM();
	const [showError, setShowError] = useState<boolean>(false);
	const lessTabletHorizontalMax = useMediaQuery(theme.breakpoints.down(customBreakpoints.tabletHorizontalMax));
	const lessSm = useMediaQuery(theme.breakpoints.down("sm"));

	const {
		getSuggestionUrlList,
		setName,
		setIsPrivate,
		setUrl,
		setImage,
		getUrlExtension,
		getTypeList,
		getData: getCommunityData
	} = useCommunity();

	const { urlSuggestionList, loadingSuggestionList, name, isPrivate, url, img, takenUrl, typeList, mainAdminColor } =
		getCommunityData();

	const extensionUrl = getUrlExtension();
	let smartphoneWidth = 285;
	if (lessTabletHorizontalMax) {
		smartphoneWidth = 220;
	}
	if (lessSm) {
		smartphoneWidth = 300;
	}

	useEffect(() => {
		if (url) {
			const checkingValue = url.replace(extensionUrl, "");
			if (checkingValue) {
				getSuggestionUrlList(checkingValue);
			}
		}
	}, [getSuggestionUrlList, url, extensionUrl]);

	// Fetch type list here to immediately show it on the next step
	useEffect(() => {
		if (!typeList) {
			getTypeList("all");
		}
	}, [typeList, getTypeList]);

	const getCommunityUrlByName = (url: string): string => {
		const maxUrlLength = config.GLOBAL_CONSTANTS.IS_STAGE ? 24 : 30;

		return url
			.toLowerCase()
			.split(" ")
			.join("")
			.replace(/[^a-zA-Z0-9 ]/g, "")
			.slice(0, maxUrlLength);
	};

	const handleInputCommunityUrl = React.useMemo(
		() =>
			(url: string): void => {
				setUrl(getCommunityUrlByName(url));
			},
		[setUrl]
	);

	const handleInputCommunityName = React.useMemo(
		() =>
			(name: string): void => {
				const correctedName = correctName(name, 40);
				setName(correctedName);
				setShowError(name.length > 40 || name.length < 2);
				handleInputCommunityUrl(correctedName);
			},
		[setName, handleInputCommunityUrl]
	);

	const handleImage = async (files): Promise<void> => {
		if (files && files.length) {
			const base64Img =
				files[0].size / 1000 > 450 // if size more than ~450Kb
					? await downscaleImage(URL.createObjectURL(files[0]), 500, files[0].type, 0.5)
					: await toBase64(files[0]);
			setImage(base64Img);
		}
	};

	useEffect(() => {
		addEvent({ Action: "Create Community", Label: "Step-4" });
		scrollTo(0, 0);
	}, [addEvent]);

	const providedUrl = useMemo(() => {
		return url.replace(extensionUrl, "");
	}, [url, extensionUrl]);

	const urlInputIcon = useMemo(() => {
		if (providedUrl && loadingSuggestionList) {
			return (
				<LoaderWrapper>
					<Loader
						color="secondary"
						show={loadingSuggestionList}
						size="1.5rem"
						thickness={3}
						value={0}
						variant="indeterminate"
					/>
				</LoaderWrapper>
			);
		}

		if (providedUrl && takenUrl) {
			return <ExclamationTriangleIcon />;
		}
	}, [providedUrl, loadingSuggestionList, takenUrl]);

	return (
		<ContentWrapper>
			<ContentBlock>
				<FormBlockWrapper>
					<FormBlock customStyle={"margin: 0 0 0 0"}>
						<Text variant="h2">Build your community</Text>
						<Text customStyle={() => "margin-top: 16px; max-width: 385px;"} variant="body1">
							Your community members will recognize your community by it’s name and logo.
						</Text>
						<InputNameWrapper>
							<Input
								placeholder="Type community name here*"
								errorText={showError ? "Community name should be between 2 and 40 characters" : ""}
								value={name}
								onChange={e => handleInputCommunityName(e.target.value)}
							/>
						</InputNameWrapper>
						<InputURLWrapper>
							<Input
								errorText={providedUrl && takenUrl ? "This community URL is taken. Try another" : undefined}
								rightIcon={urlInputIcon}
								customStyle={() => "position: relative;"}
								placeholder="Community URL"
								value={providedUrl}
								onChange={e => handleInputCommunityUrl(e.target.value)}
							/>
							<InputURLVyoo>{extensionUrl}</InputURLVyoo>
						</InputURLWrapper>
						{providedUrl && takenUrl && !!urlSuggestionList.length && (
							<AvailableUrls>
								<AvailableUrls.Title align="left" display="inline" noWrap paragraph variant="body1">
									Available URLs:
								</AvailableUrls.Title>
								{urlSuggestionList.map((item, index) => (
									<AvailableUrls.Link key={index} onClick={() => handleInputCommunityUrl(item)}>
										{item}
									</AvailableUrls.Link>
								))}
							</AvailableUrls>
						)}
						<StyledPrivacyField isPrivate={isPrivate} setIsPrivate={setIsPrivate} color={mainAdminColor} />
						<UploaderWrapper>
							<Uploader
								onChange={handleImage}
								orientation={orientationConst.horizontal}
								width={"100%"}
								description={
									<>
										Drag and Drop File Here or <span className="anchor">Browse</span> to Choose a File
									</>
								}
								accept={[
									{
										fileType: "image/png, image/jpeg, image/x-eps",
										name: "png, jpg, eps"
									}
								]}
								icon={
									<Icon
										group={""}
										fill={"#c5cee0"}
										name={"cloud-upload"}
										width={64}
										height={50}
										viewBox={"4 2 18 19"}
									/>
								}
							/>
						</UploaderWrapper>
						<FormFooterWrapper>
							<Button
								removeSideMargin
								fullWidth
								disabled={!name || !url.replace(extensionUrl, "") || takenUrl || showError}
								size="large"
								onClick={() => history.push(routes.onboarding.community.about.getPath())}
							>
								Continue
							</Button>
						</FormFooterWrapper>
					</FormBlock>
				</FormBlockWrapper>
				<FrameWrapper>
					<Frame variant={deviceType.smartphone} orientation={orientationType.portrait} width={smartphoneWidth}>
						<FrameContentWrapper direction="column" alignItems="center" justify="center" style={{ height: "100%" }}>
							{img && <Preview file={img} />}
							{!img && (
								<Avatar
									title={name || "A"}
									width={80}
									height={80}
									palette={"infoLight"}
									avatarStyle={"background: #e4e9f2; color: white; font-weight: bold;"}
								/>
							)}
							<FrameTextName align="center" variant="h6">
								{name || "Community Name"}
							</FrameTextName>
							<FrameTextUrl align="center" variant="body1">
								{url ? url : "Community URL"}
							</FrameTextUrl>
						</FrameContentWrapper>
					</Frame>
				</FrameWrapper>
			</ContentBlock>
		</ContentWrapper>
	);
};

export default Create;
