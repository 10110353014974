import React, { memo } from "react";

import config from "config/appConfig";
import { Switch } from "react-router";
import { Redirect, useLocation } from "react-router-dom";

import { UserRoles } from "types";

import { useMemberRoutes, useUser } from "shared/hooks";
import useJwt from "shared/services/api/core/jwt";

interface SecureRouteProps {
	routes: JSX.Element[];
	roles?: UserRoles[];
}

const SecureRoute = memo(({ routes, roles = [] }: SecureRouteProps): JSX.Element => {
	const jwtService = useJwt();

	const { getData: getUserData } = useUser();
	const { user } = getUserData();

	const { pathname, search } = useLocation();

	const { getMemberRoutesData } = useMemberRoutes();
	const { routes: memberRoutes } = getMemberRoutesData();

	let isAllow = !!jwtService.getJwt();

	if (isAllow && roles?.length) {
		isAllow = !!(
			(roles.includes(UserRoles.Owner) && user?.isGlobalOwner) ||
			(roles.includes(UserRoles.Admin) && user?.isGlobalAdmin) ||
			(roles.includes(UserRoles.Moderator) && user?.isGlobalModerator) ||
			(roles.includes(UserRoles.Member) && user?.isGlobalMember)
		);
	}

	if (isAllow) {
		return <Switch>{routes}</Switch>;
	}

	const query = new URLSearchParams("");
	query.set("redirectUrl", encodeURIComponent(`${pathname}${search}`));

	return (
		<Redirect
			to={{
				pathname: user?.activeProfile ? memberRoutes?.member.home.getPath() : config.CORE_API_URLS.LOGIN,
				search: query.toString()
			}}
		/>
	);
});

export default SecureRoute;
