import React, { FC } from "react";

import { css } from "styled-components";

import { CTAButton, ContentWrapper, DeleteText, DialogFooter } from "modules/Marketplace/View/Components/style";
import { Dialog } from "shared/Components";
import { useMembers } from "shared/hooks";
import { Text } from "shared/ui-kit";

const CantCreateDialog: FC<{ title: string; description: string; handleClose: () => void }> = ({
	title,
	description,
	handleClose
}) => {
	const { setShowInviteNotAllowedDialog } = useMembers();
	return (
		<Dialog
			bodyCustomStyles={css`
				padding: 0 !important;
			`}
			title={
				<Text align="left" variant="h6">
					{title}
				</Text>
			}
			open={true}
			onClose={() => setShowInviteNotAllowedDialog(false)}
			hasBackButton={false}
		>
			<ContentWrapper>
				<DeleteText>{description}</DeleteText>
			</ContentWrapper>
			<DialogFooter mt={20}>
				<CTAButton wide onClick={handleClose}>
					Close
				</CTAButton>
			</DialogFooter>
		</Dialog>
	);
};

export default CantCreateDialog;
