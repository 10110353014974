import React from "react";

import { Link } from "react-router-dom";

import { StyledButton } from "./style";

interface SeeAllBtnProps {
	text?: string;
	click?: () => void;
	link?: string;
}

const SeeAllBtn: React.FC<SeeAllBtnProps> = ({ text, click, link }) => {
	const optionalProps: Record<string, unknown> = {};
	if (link) {
		optionalProps.to = link;
		optionalProps.component = Link;
	}

	return (
		<StyledButton
			{...optionalProps}
			removeSideMargin
			fullWidth
			size="medium"
			palette="outlined"
			buttonTheme="outline"
			onClick={() => click && click()}
		>
			{text || "See All"}
		</StyledButton>
	);
};

export default SeeAllBtn;
