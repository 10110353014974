import React, { useEffect, useState } from "react";

import IconButton from "@material-ui/core/IconButton";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import MoreVertIcon from "@material-ui/icons/MoreVert";

import _ from "lodash";
import includes from "lodash/includes";
import map from "lodash/map";
import ReactDOM from "react-dom";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { AutoSizer, Column, InfiniteLoader, Table, defaultTableRowRenderer } from "react-virtualized";
import styled, { css } from "styled-components";

import { Checkbox, FabButton, Menu } from "shared/ui-kit";

import TableCell from "./Cell";

import EmptyTable from "./EmptyTable";

import TableHeader from "./Header";

import { Loader } from "../../../atoms";
import InPlaceModal from "../../InPlaceModal";

const VTLoaderContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`;

const defaultProps = {
	headerHeight: 48,
	height: 700,
	rowHeight: 72,
	width: 800
};

const Reorder = styled.div`
	padding: 16px 0;
	display: flex;
	justify-content: center;
	align-items: center;
	color: ${props => props.theme.palette.tertiary.border};
	cursor: move;
	& .MuiSvgIcon-root {
		width: 30px;
		height: 30px;
	}
`;

const Actions = ({ itemActions, customItemActions, ...props }) => {
	const options = map(itemActions, action => ({
		...action,
		onClick: e => action.onClick(props, e)
	}));

	if (customItemActions) {
		return customItemActions(props);
	}

	return (
		<Menu options={options} transformOrigin={{ horizontal: "right", vertical: "top" }}>
			<IconButton aria-label="more" aria-controls="long-menu" aria-haspopup="true">
				<MoreVertIcon />
			</IconButton>
		</Menu>
	);
};

const Component = ({ data, className, checkedRows, hideHeader, ...props }) => {
	// const { data, className, checkedRows, hideHeader } = props;
	const { height: defaultHeight, width: defaultWidth } = defaultProps;
	const width = props.width || defaultWidth;
	const height = props.height || defaultHeight;
	const rowHeight = props.rowHeight || defaultProps.rowHeight;
	const headerHeight = props.headerHeight || defaultProps.headerHeight;
	const columns = [...props.columns];
	let columnCount = props.columns.length;
	if (props.columns.find(x => x.dataKey === "__expanded__")) {
		columnCount -= 1;
	}

	let columnWidth = width / columnCount;
	const [checkableData, setCheckableData] = useState<any[]>([]);
	const [expandableData, setExpandableData] = useState<any>([]);
	const [sortState, setSortState] = useState<any>({
		by: props.sortBy,
		direction: props.sortDirection
	});

	const SortableTable = SortableContainer<any>(Table, {
		withRef: true
	});
	const SortableRow = SortableElement(p => <>{defaultTableRowRenderer(p)}</>);

	useEffect(() => {
		if (typeof props.isRowCheckable === "function") {
			const checkableDataArr: any[] = [];
			data.forEach((currentData, index) => {
				if (props.isRowCheckable(currentData)) {
					checkableDataArr.push(index);
				}
			});
			setCheckableData(checkableDataArr);
		} else {
			if (props.expandable) {
				setCheckableData(data.map((val, index) => index));
			} else {
				setCheckableData(data.map((val, index) => index));
			}
		}

		if (typeof props.isRowExpandable === "function") {
			const expandableDataArr: any[] = [];
			data.forEach((currentData, index) => {
				if (props.isRowExpandable(currentData)) {
					expandableDataArr.push(index);
				}
			});
			setExpandableData(expandableDataArr);
		} else {
			if (props.expandable) {
				setExpandableData(data.map((val, index) => index));
			} else {
				setExpandableData(data.map((val, index) => index));
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);

	const isRowCheckable = rowIndex => {
		return checkableData.includes(rowIndex);
	};

	const isRowExpandable = rowIndex => {
		return expandableData.includes(rowIndex);
	};

	const setAllRowsSelected = ({ target: { checked } }) => {
		const result: any[] = [];
		if (checked) {
			data.forEach((d, index) => {
				if (isRowCheckable(index)) {
					result.push(index);
				}
			});
		}
		if (props.onCheckAll) {
			props.onCheckAll(result);
		}
	};

	const loadMoreRows = () => {
		/**/
	};

	const setRowSelectedById = ({ target: { checked } }, index) => {
		const result = [...checkedRows];

		if (isRowCheckable(index)) {
			if (checked) {
				result.push(index);
			} else {
				result.splice(result.indexOf(index), 1);
			}

			if (props.onCheckItem) {
				props.onCheckItem(index, result);
			}
		}
	};

	if (props.reorderable) {
		const w = 80;
		const header = typeof props.reorderHeader === "string" ? "label" : "Header";
		columns.unshift({
			Cell: () => (
				<Reorder>
					<DragHandleIcon />
				</Reorder>
			),
			alignment: "center",
			dataKey: "__reorder__",
			disableSort: true,
			[header]: props.reorderHeader,
			width: w
		});
	}

	const toggleCollapsible = (event, index, rowData) => {
		if (!rowData._expandData) {
			props.onToggleCollapsible(index, rowData);
		}
	};

	const isRowChecked = index => {
		return isRowCheckable(index) && includes(checkedRows, index);
	};

	_.forEach(columns, ({ width: w }) => {
		if (_.isNumber(width)) {
			const adjustmentWidth = columnWidth - w;

			if (adjustmentWidth) {
				columnCount -= 1;
			}

			columnWidth = columnWidth + adjustmentWidth / columnCount;
		}
	});

	if (props.checkable) {
		const w = props.checkableColumnWidth || 52;
		columns.unshift({
			Cell: item => {
				const itemStyle: any = { padding: "16px 0" };
				let correctIndex = item.rowIndex;

				if (props.hideNonCheckables) {
					if (props.expandable) {
						correctIndex = data.indexOf(item.rowData);
					}

					if (!isRowCheckable(correctIndex)) {
						itemStyle.display = "none";
					}
				}
				return (
					<Checkbox
						checked={isRowChecked(correctIndex)}
						onChange={e => setRowSelectedById(e, correctIndex)}
						style={itemStyle}
					/>
				);
			},
			Header: () => {
				const itemStyle: any = { padding: "16px 0" };
				if (props.hideNonCheckables) {
					if (checkableData.length === 0) {
						itemStyle.display = "none";
					}
				}
				return (
					<Checkbox
						checked={checkedRows.length === checkableData.length && checkedRows.length > 0}
						onChange={setAllRowsSelected}
						style={itemStyle}
					/>
				);
			},
			alignment: "left",
			className: "virtualized-table__column_action",
			dataKey: "__checkbox__",
			disableSort: true,
			width: w
		});

		columnWidth = columnWidth - w / columnCount;
	}

	if (props.expandable) {
		const w = props.expandableColumnWidth || 90;
		columns.push({
			Cell: item => {
				const itemStyle: any = {};
				if (item.rowData._expandData) {
					itemStyle.display = "none";
				}

				let correctIndex = item.rowIndex;
				if (props.hideNonExpandable) {
					if (props.expandable) {
						correctIndex = data.indexOf(item.rowData);
					}

					if (!isRowExpandable(correctIndex)) {
						itemStyle.display = "none";
					}
				}
				return (
					<FabButton
						size={"small"}
						style={itemStyle}
						palette={"fabArrowDark"}
						expanded={item.rowData._expanded}
						handleClick={e => toggleCollapsible(e, item.rowIndex, item.rowData)}
					/>
				);
			},
			Header: () => null,
			alignment: "left",
			className: "virtualized-table__column_action",
			dataKey: "__expand__",
			width: w
		});

		columnWidth = columnWidth - w / columnCount;
	}

	if ((props.itemActions && props.itemActions.length) || props.customItemActions) {
		const w = props.customizableColumnWidth || 80;
		columns.push({
			Cell: cell => <Actions customItemActions={props.customItemActions} itemActions={props.itemActions} {...cell} />,
			alignment: "left",
			className: "virtualized-table__column_action",
			dataKey: "__actions__",
			disableSort: true,
			label: "",
			style: { padding: "16 0" },
			width: w
		});

		columnWidth = columnWidth - w / columnCount;
	}

	const _visibleItems = () => {
		return data.filter(item => {
			if (!item._expandData) {
				return true;
			}

			const index = data.findIndex(x => x === item);
			if (index > 0) {
				return data[index - 1]._expanded;
			}

			return false;
		});
	};

	const _customClassForRow = index => {
		let cName = "virtualized-table__row ";
		if ((data[index] || {}).disabled) {
			cName += "disabled ";
		}

		if (props.expandable) {
			const visibleData = _visibleItems();

			if (visibleData[index] && visibleData[index]._expandData) {
				cName += "virtualized-table__row_inner ";
			}
		}

		return cName;
	};

	const isRowSortable = index => {
		return index >= 0; // Header row should not be draggable
	};

	const rowRenderer = p => {
		const { index } = p;
		return isRowSortable(index) ? <SortableRow {...p} /> : defaultTableRowRenderer(p);
	};

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const reorderRow = ({ newIndex, oldIndex, collection, isKeySorting }) => {
		if (newIndex === oldIndex) {
			return;
		}
		if (props.onReorder) {
			props.onReorder(newIndex, oldIndex);
		}
	};

	const getRowHeight = index => {
		if (_visibleItems()[index] && _visibleItems()[index]._expandData) {
			return _visibleItems()[index]._expandData.rowHeight ? _visibleItems()[index]._expandData.rowHeight : 300;
		}

		return rowHeight;
	};

	return (
		<div>
			<InfiniteLoader isRowLoaded={({ index }) => !!data[index]} loadMoreRows={loadMoreRows} rowCount={10000}>
				{({ onRowsRendered, registerChild }) => (
					<AutoSizer disableHeight={true} disableWidth={true}>
						{() => (
							<SortableTable
								// eslint-disable-next-line react/no-find-dom-node
								getContainer={(wrappedInstance: any) => ReactDOM.findDOMNode(wrappedInstance.Grid)}
								rowHeight={({ index }) => {
									return getRowHeight(index);
								}}
								headerHeight={hideHeader ? 0 : headerHeight}
								width={width}
								noRowsRenderer={() => <EmptyTable />}
								height={height}
								className={className}
								rowCount={props.expandable ? _visibleItems().length : data.length}
								ref={registerChild}
								rowGetter={({ index }) => (props.expandable ? _visibleItems()[index] : data[index])}
								rowRenderer={rowRenderer}
								onRowsRendered={onRowsRendered}
								onSortEnd={reorderRow}
								useDragHandle={true}
								helperClass="table-reorder-helper"
								rowClassName={({ index }) => _customClassForRow(index)}
								onRowClick={() => {
									/* */
								}}
								overscanRowCount={2}
								sort={async ({ defaultSortDirection, event, sortBy, sortDirection }) => {
									if (props.sort) {
										await props.sort({
											defaultSortDirection,
											event,
											sortBy,
											sortDirection
										});
										setSortState({ by: sortBy, direction: sortDirection });
									}
								}}
								sortBy={sortState.by}
								sortDirection={sortState.direction}
							>
								{/* eslint-disable-next-line @typescript-eslint/no-unused-vars*/}
								{columns.map((columnProps, index) => (
									<Column
										{...columnProps}
										width={!isNaN(columnProps.width) ? columnProps.width : columnWidth}
										key={columnProps.dataKey}
										dataKey={columnProps.dataKey}
										className={`virtualized-table__column ${columnProps.className ? columnProps.className : ""}`}
										headerRenderer={headerProps => {
											const { Header, ...otherColProps } = columnProps;
											let customStyle = "";

											if (otherColProps.dataKey && otherColProps.dataKey === "__expanded__") {
												customStyle = `
                          display: none;
                          .MuiTableCell-head {
                            padding: 0 !important;
                          }
                        `;
											}

											return (
												<TableHeader
													Header={Header}
													height={headerHeight}
													customStyle={customStyle}
													sortable={props.sort && !otherColProps.disableSort}
													{...headerProps}
													{...otherColProps}
												/>
											);
										}}
										cellRenderer={cellProps => {
											const { Cell, ...otherColProps } = columnProps;
											return <TableCell Cell={Cell} height={rowHeight} {...cellProps} {...otherColProps} />;
										}}
									/>
								))}
							</SortableTable>
						)}
					</AutoSizer>
				)}
			</InfiniteLoader>
			<InPlaceModal
				open={props.showLoader}
				onClose={() => {
					/* */
				}}
				modalHeight={height > 500 ? 500 : height}
			>
				<VTLoaderContainer>
					<Loader show={true} color="primary" />
				</VTLoaderContainer>
			</InPlaceModal>
		</div>
	);
};

const VirtualizedTable = styled(Component)`
	height: 100%;
	.ReactVirtualized__Grid.ReactVirtualized__Table__Grid {
		outline: none;
	}

	.virtualized-table__row {
		display: flex;
		align-items: center;
		box-sizing: border-box;
		outline: none;
		padding-right: 0 !important;

		.virtualized-table__inner-column_expanded {
			display: none;

			& > .MuiTableCell-root.MuiTableCell-body {
				padding: 0 !important;
			}
		}

		&_inner {
			background: #f7f9fc;

			.ReactVirtualized__Table__row {
				height: 100%;
				border-bottom: 1px solid #f2f3f8;
			}

			.MuiTableCell-root {
				border: none;
			}

			.virtualized-table__column {
				display: none;
				height: 100%;
			}

			.virtualized-table__inner-column_expanded {
				display: block;
				flex: 0 0 100% !important;
				height: 100%;

				.MuiTableCell-root {
					width: 100%;
					height: 100% !important;

					.explorer-table {
						max-height: 100%;
					}
				}
			}
		}

		& .ReactVirtualized__Table__headerColumn {
			outline: none;
		}

		&.disabled {
			.virtualized-table__column {
				opacity: 0.25;

				&_action {
					opacity: 1;
				}
			}
		}
	}

	.virtualized-table__inner-column_expanded {
		.virtualized-table__column {
			display: block;
		}
	}

	${props => {
		const customStyle = props.customStyle || "";

		return css`
			${customStyle}
		`;
	}}
`;

export default VirtualizedTable;
