import { ReportContentRulesModel } from "types/CommunityModel";

import { CommunityType } from ".";

export default interface WorkspaceMetaPayloadType {
	meta?: MetaObjType;
	isPrivate?: boolean | null;
	reportContentSettings?: ReportContentRulesModel;
}

export interface MetaObjType {
	monetizationMethod?: MonetizationMethodType;
	displayName?: string;
	invitationEmailTemplate?: EmailTemplateType;
	invitationInAppTemplate?: EmailTemplateType;
	types?: Array<CommunityType>;
	theme?: ThemeType;
	content18Plus?: boolean;
	img?: string;
	about?: string;
	social?: SocialAccounts;
	coverImage?: string;
	paymentDetails?: PaymentDetailsType;
	isFreeClicked?: boolean;
	btcMetricsSponsor?: BTCSponsorType;
	purchaseBtcSponsor?: BTCSponsorType;
}

interface BTCSponsorType {
	name?: string;
	url?: string;
	image?: string;
}

interface MonetizationMethodType {
	name: string;
	subscriptionId: string;
}

interface EmailTemplateType {
	subject: string;
	body: string;
}

export interface PaypalPaymentDetailModel {
	email: string;
	confirmEmail?: string;
}

export interface StripePaymentDetailModel {
	firstname: string;
	lastname: string;
	address: string;
	address2?: string;
	city: string;
	state: string;
	zip: string;
	dob: string;
	ssn: string;
	bankRoutingNumber: string;
	bankAccountNumber: string;
	corporateEin?: string;
	corporationName?: string;
}

export interface PaymentDetailsType {
	isIndividual?: boolean;
	countryResidence?: string;
	paypal?: PaypalPaymentDetailModel;
	stripe?: StripePaymentDetailModel;
	choosenMethod?: string;
}

interface ThemeType {
	primaryColor: string;
	primaryBtnColor: string;
}

export enum SocialTypes {
	facebook = "facebook",
	instagram = "instagram",
	twitter = "twitter",
	tiktok = "tiktok"
}

export interface SocialAccounts {
	[SocialTypes.facebook]?: string;
	[SocialTypes.instagram]?: string;
	[SocialTypes.twitter]?: string;
	[SocialTypes.tiktok]?: string;
}
