import { IconButton } from "@material-ui/core";

import Dialog from "@material-ui/core/Dialog";
import styled from "styled-components";

import { Button } from "shared/ui-kit";

const Wrapper = styled(Dialog)`
	.MuiDialog-paper {
		width: 480px;
		border-radius: 12px;
		position: relative;
		padding: 40px;
	}
`;

const Title = styled.div`
	font-size: 22px;
	font-weight: bold;
	text-align: center;
`;

const Subtitle = styled.div`
	color: #8f9bb3;
	margin-top: 8px;
	text-align: center;
`;

const Form = styled.form`
	margin-top: 40px;
`;

const FormRow = styled.div`
	margin-bottom: 20px;

	&:last-child {
		margin-bottom: 0;
	}
`;

const InputWrapper = styled.div`
	position: relative;
`;

const ToggleShowButton = styled(IconButton)`
	position: absolute;
	right: 0;
	top: 26px;
`;

const FormActions = styled.div`
	margin-top: 40px;
`;

const SubmitButton = styled(Button)`
	font-size: 16px;
	border-radius: 8px;
	max-height: 40px;

	span {
		font-weight: bold;
	}
`;

const CloseButton = styled.button`
	border: 0;
	padding: 0;
	background: none;
	position: absolute;
	top: 25px;
	right: 25px;
	cursor: pointer;
`;

export {
	Wrapper,
	Title,
	Subtitle,
	Form,
	FormRow,
	InputWrapper,
	ToggleShowButton,
	FormActions,
	SubmitButton,
	CloseButton
};
