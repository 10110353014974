import { Box, ButtonBase } from "@material-ui/core";
import styled, { css } from "styled-components";

export const MultichoiceInputWrapper = styled(Box)`
	position: relative;
	p {
		font-size: 13px;
		font-weight: 600;
		line-height: 1.85;
		color: #222b45;
		margin-bottom: 2px;
	}
	.choices {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.input-error {
		position: absolute;
		top: calc(100% + 2px);
		left: 0;
		font-size: 12px;
		color: red;
	}
`;

export const Choice = styled(ButtonBase)`
	height: 72px;
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 8px;
	border: solid 1px #e4e9f2;
	margin: 0 8px;
	p {
		font-size: 13px;
		font-weight: 600;
		line-height: 1.85;
		color: #222b45;
		margin-left: 8px;
	}
	&:first-child {
		margin-left: 0;
	}
	&:last-child {
		margin-right: 0;
	}
	${props =>
		css`
			&.active {
				border-color: ${props.primaryColor};
			}
			.Mui-checked span span {
				background-color: ${props.primaryColor};
			}
		`}
`;
