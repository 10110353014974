import { useCallback } from "react";

const useScrollbar = () => {
	const getScrollWrapper = document?.getElementById("scrollWrapper");
	const getBoxWrapper = document?.getElementById("boxWrapper");

	const marginTopOnScroll = useCallback((marginTop: string, id: string) => {
		const scrollbar = document?.getElementById(id);
		scrollbar && ((scrollbar.lastElementChild?.firstElementChild as HTMLElement).style.marginTop = marginTop);
	}, []);

	const highIndex = useCallback(() => {
		getScrollWrapper && ((getScrollWrapper.lastElementChild as HTMLElement).style.zIndex = "1300");
	}, [getScrollWrapper]);

	const showScroll = useCallback(() => {
		getScrollWrapper && ((getScrollWrapper.firstElementChild as HTMLElement).style.overflow = "scroll");
	}, [getScrollWrapper]);

	const hideScroll = useCallback(() => {
		getScrollWrapper && ((getScrollWrapper.firstElementChild as HTMLElement).style.overflow = "hidden");
	}, [getScrollWrapper]);

	const scrollBarToTop = useCallback(() => {
		(getBoxWrapper || getScrollWrapper?.firstElementChild)?.scrollTo(0, 0);
	}, [getBoxWrapper, getScrollWrapper]);

	return { showScroll, hideScroll, scrollBarToTop, highIndex, marginTopOnScroll };
};

export default useScrollbar;
