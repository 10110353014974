import { Box } from "@material-ui/core";
import styled from "styled-components";

import { Text } from "shared/ui-kit";

export const Container = styled(Box)`
	position: relative;
	margin: auto;
	margin-bottom: 40px;
`;

export const HeaderText = styled(Text)`
	color: #222b45;
	font-weight: bold;
	font-size: 22px;
	margin-top: 16px;
	${props => props.theme.breakpoints.up("sm")} {
		font-size: 32px;
	}
`;

export const BodyWrapper = styled(Box)`
	margin: 0 0 56px;
	border-radius: 4px;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
`;

export const SearchbarWrapper = styled(Box)`
	padding: 12px 16px;
	background: #fff;
	> div {
		&:first-child {
			width: 100%;
			border-radius: 4px;
			border: solid 0.5px #c6cfe0;
			background-color: #f7f9fc;
		}
	}

	.search-icon {
		color: #475b7f;
	}

	.MuiInputBase-input {
		max-width: 450px !important;
		width: 100% !important;
		padding: 11px 16px;
	}

	${props => props.theme.breakpoints.up("sm")} {
		padding: 12px 24px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		> div {
			&:first-child {
				max-width: 260px !important;
			}
		}
	}
`;

export const ButtonWrapper = styled(Box)`
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 8px;
	button {
		width: 100%;
		margin: 0;
	}
	form {
		width: 100%;
		margin-right: 16px;
	}
	.MuiInputBase-root {
		padding: 2px 0 !important;
	}
	${props => props.theme.breakpoints.up("sm")} {
		margin-top: 0%;
		button {
			width: auto;
		}
	}
`;

export const Content = styled(Box)``;

Content.Child = styled(Box)``;

export const Header = styled(Text)`
	color: #222b45;
	font-weight: bold;
	font-size: 26px;
	margin-bottom: 16px;
`;
