import { Box, ButtonBase } from "@material-ui/core";
import styled from "styled-components";

import { Text } from "shared/ui-kit";

export const Toolbar = styled(Box)`
	max-width: 632px;
	border-radius: 4px;

	display: flex;
	align-content: baseline;

	&.manage {
		height: 56px;

		background: white;

		border: 1px solid rgba(197, 206, 224, 0.5);

		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);

		margin: 55px auto 88px;
		padding: 8px 24px 3px 32px;
	}

	&.mobile {
		height: 390px;

		display: flex;
		flex-direction: column;
		align-content: normal;

		background: white;
		box-shadow: none;

		border-radius: 0;

		max-width: none;

		margin: 0;

		padding: 0;

		border: none;
	}
`;

Toolbar.Title = styled(Text)`
	margin: 8px 32px 8px 0;
	font-size: 15px;
	font-weight: 600;
	line-height: 1.6;
	white-space: nowrap;
`;

Toolbar.Controls = styled(Box)`
	width: 100%;
	display: flex;
	justify-content: space-around;

	&.mobile {
		flex-direction: column;
		justify-content: flex-start;
	}
`;

Toolbar.ControlWrapper = styled(ButtonBase)`
	width: 40px;
	height: 40px;

	padding: 8px 7px 8px 9px;

	border-radius: 8px;

	cursor: pointer;

	position: relative;

	> * {
		cursor: pointer;
	}

	&:hover {
		background-color: rgb(10, 10, 10, 0.07);
	}

	&.mobile {
		width: 100%;
		height: fit-content;

		margin: 0;
		padding: 12px 16px;

		display: flex;
		align-items: center;
		justify-content: flex-start;

		border-radius: 8px;
	}
`;

Toolbar.MobileControlWrapper = styled(Box)`
	cursor: pointer;
	padding: 12px 16px;
	display: flex;
	align-items: center;

	position: relative;

	&:hover {
		background-color: rgb(10, 10, 10, 0.07);
	}
`;

Toolbar.MobileIconWrapper = styled(Box)`
	width: 24px;
	height: 24px;

	margin-right: 16px;
`;

Toolbar.MobileControlTitle = styled.span`
	font-size: 15px;
	font-weight: 600;
	line-height: 1.6;
	letter-spacing: normal;
	color: #222b45;
`;

export const ToolbarMobileRow = styled(Box)`
	display: flex;
	align-items: center;

	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
	border-top: 0;

	cursor: pointer;
`;

ToolbarMobileRow.IconWrapper = styled(Box)`
	width: 24px;
	height: 24px;

	margin-right: 8px;
`;

export const MobileAddElements = styled(Box)`
	font-size: 15px;
	font-weight: 600;
	line-height: 1.6;
	color: #8f9bb3;
`;
