import React, { useCallback, useEffect, useState } from "react";

import { useMediaQuery } from "@material-ui/core";
import { debounce } from "@material-ui/core/utils";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import IManageFeature from "types/IManageFeature";

import { routes } from "apps/Onboarding/constants";

import { originalFeatures } from "shared/constants";
import { useCommunity, useFeature, useGTM } from "shared/hooks";
import { Box, Button, FormFooter, Text } from "shared/ui-kit";
import * as appTheme from "theme/default";

import { customBreakpoints } from "theme/default";

import { scrollTo } from "utils/scrollTo";

import { ContentWrapper, FeatureCard, FormBlock } from "../../Components";
export interface FeaturePageProps {}

const ContentForm = styled.section`
	display: flex;
	justify-content: center;
	align-items: center;

	width: 100%;

	${props => props.theme.breakpoints.down("sm")} {
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
	}
`;

const FeatureListWrapper = styled.section`
	margin-top: 23px;

	text-align: center;
`;

const FeatureCardWrapper = styled(Box)`
	display: inline-block;
	margin: 0 24px 16px 0;

	vertical-align: top;

	width: 31.3%;

	cursor: pointer;

	&:nth-child(3n) {
		margin-right: 0;
	}

	${props => props.theme.breakpoints.down(customBreakpoints.tabletVerticalMax)} {
		width: 48%;
		margin-right: 16px;
		margin-bottom: 14px;

		&:nth-child(3n) {
			margin-right: 16px;
		}

		&:nth-child(2n) {
			margin-right: 0;
		}
	}

	${props => props.theme.breakpoints.down("sm")} {
		width: 100%;
		margin: 0 0 14px 0;
	}
`;

const FormFooterWrapper = styled(FormFooter)`
	margin: 23px auto 0 auto;
	max-width: 400px;
`;

const Feature: React.FC<FeaturePageProps> = () => {
	const history = useHistory();
	const { getData: getCommunityData } = useCommunity();
	const { selectedTypes } = getCommunityData();

	const { setSelectedFeatures, getData: getFeatureData } = useFeature();
	const { defaultSelectedFeatures } = getFeatureData();

	const { addEvent } = useGTM();
	const [featureList, setFeatureList] = useState<IManageFeature[]>([]);

	useEffect(() => {
		setFeatureList(
			originalFeatures
				.filter(x => !x.hideForNonWhitelabel)
				.map(feature => {
					const selected = defaultSelectedFeatures[selectedTypes[0].type].some(
						defaultFeature => defaultFeature === feature.value
					);
					return { ...feature, enabled: selected || false };
				})
		);
	}, [defaultSelectedFeatures, selectedTypes]);

	const lessTabletHorizontalMax = useMediaQuery(
		appTheme.default.breakpoints.down(appTheme.customBreakpoints.tabletHorizontalMax)
	);
	const lessTabletVerticalMax = useMediaQuery(
		appTheme.default.breakpoints.down(appTheme.customBreakpoints.tabletVerticalMax)
	);
	const lessSm = useMediaQuery(appTheme.default.breakpoints.down("sm"));

	let formWidth = "1120px";
	if (lessTabletHorizontalMax) {
		formWidth = "1000px";
	}
	if (lessTabletVerticalMax) {
		formWidth = "700px";
	}
	if (lessSm) {
		formWidth = "90%";
	}

	const toggleSettingsForFeature = (featureTitleValue: string) => {
		const index = featureList.findIndex(x => x.value === featureTitleValue);
		if (index > -1) {
			featureList[index].enabled = !featureList[index].enabled;
		}
		setFeatureList([...featureList]);
		setSelectedFeatures(featureList.filter(feature => feature.enabled).map(feature => feature.value));
	};

	const submit = useCallback(() => {
		setSelectedFeatures(featureList.filter(feature => feature.enabled).map(feature => feature.value));
		const debounced = debounce(() => {
			history.push(routes.onboarding.community.theme.getPath());
		}, 100);
		debounced();
	}, [featureList, history, setSelectedFeatures]);

	useEffect(() => {
		addEvent({ Action: "Create Community", Label: "Step-6" });
		scrollTo(0, 0);
	}, [addEvent]);

	return (
		<ContentWrapper customStyle={"align-items: center;"}>
			<ContentForm>
				<FormBlock customStyle={`margin: 0; max-width: 100%; width: ${formWidth}`}>
					<Text variant="h2">What features you need?</Text>
					<FeatureListWrapper>
						{featureList.map((item, index) => (
							<FeatureCardWrapper key={index} onClick={() => toggleSettingsForFeature(item.value)}>
								<FeatureCard icon={item.icon} title={item.title} enabled={item.enabled} description={item.shortDesc} />
							</FeatureCardWrapper>
						))}
					</FeatureListWrapper>
					<FormFooterWrapper>
						<Button
							removeSideMargin
							fullWidth
							disabled={!featureList.some(feature => feature.enabled)}
							size="large"
							onClick={submit}
						>
							Continue
						</Button>
					</FormFooterWrapper>
				</FormBlock>
			</ContentForm>
		</ContentWrapper>
	);
};

export default Feature;
