import React, { FC, ReactNode, useCallback, useEffect, useMemo, useState } from "react";

import { useHistory } from "react-router-dom";

import TimeAgo from "react-timeago";

import { FileType } from "types/FilesContextValuesType";
import { fileExtensions } from "types/fileExtensions";

import { ReactComponent as DeleteIcon } from "assets/icons/icon-delete.svg";
// import { ReactComponent as ShareIcon } from "assets/icons/icon-share.svg"; // Temporary hide
// import { ReactComponent as IconSlash } from "assets/icons/icon-slash.svg";
// import { ReactComponent as IconEyeStrikethrough } from "assets/icons/stories/icon-eye-strikethrough.svg";

import { ReactComponent as ReportIcon } from "assets/icons/liveconvo/report.svg";
import { ConfirmActionModal, MenuDotsOption, UnlockPremiumBtn } from "shared/Components";
import { useAlbums, useFiles, useReportContent, useStream, useUser } from "shared/hooks";
import { ReportContentType, StreamEntityType, UnlockContentType } from "shared/types";
import { Icon } from "shared/ui-kit";

import { getFileIconAndType } from "utils/serviceUtils/fileExtension";
import { formatSizeUnits, openFileByLink } from "utils/serviceUtils/helpers";

import { Container, ItemWrapper, OtherBlock, StyledButton } from "./style";

import { CreateFileDialog, EntityTemplate, FileCard } from "../../Components";

interface FileDetailsPageProps {
	id: string;
	groupId?: string;
	routes: any;
}

const FileDetailsPage: FC<FileDetailsPageProps> = ({ id, routes, groupId }) => {
	const history = useHistory();
	const { getFileById, getFiles, deleteFile, getData: getFilesData } = useFiles();
	const { files } = getFilesData();

	const { isPrivilegedRole } = useUser();

	const { startStream, readFile } = useStream();
	const { getReportTypeOptions } = useReportContent();

	const [file, setFile] = useState<FileType>();
	const [fileExtension, setFileExtension] = useState<string>();
	const [loading, setLoading] = useState(false);
	const [showFileDialog, setShowFileDialog] = useState(false);
	const [deletableFile, setDeletableFile] = useState<{ id: string; name: string }>();

	useEffect(() => {
		setLoading(true);
		getFileById(id)
			.then(data => {
				setFile(data);
			})
			.finally(() => {
				setLoading(false);
			});
		getFiles({ limit: 5, offset: 1, groupId });
	}, [getFileById, id, getFiles, groupId]);

	const getFileExtension = useCallback(async () => {
		if (!file) return;

		if (!file.file.extension) {
			const fileData = await (await readFile(file.file.url)).headers["content-type"];
			if (fileData) {
				const extFound = fileExtensions.find(ext => ext.mime === fileData)?.ext;
				if (extFound) {
					setFileExtension(extFound);
				} else {
					let splitName: string[] = [];

					if (fileData.includes(".")) {
						splitName = fileData.split(".");
					} else {
						splitName = fileData.split("_");
					}

					setFileExtension(splitName[splitName.length - 1]);
				}
			}
		} else {
			setFileExtension(file.file.extension);
		}
	}, [file, readFile]);

	useEffect(() => {
		getFileExtension();
	}, [file, getFileExtension, readFile]);

	const handleFileClicked = (id: string) => {
		history.push(`${routes?.member.file.getPath()}/${id}`);
	};

	const deleteUserfile = useCallback(
		(id: string) => {
			deleteFile(id);
			history.push(routes?.member.files.getPath());
		},
		[deleteFile, history, routes]
	);

	const options = useMemo(() => {
		const optionList: MenuDotsOption[] = [
			// Temporary hide until we get a design
			// {
			// 	name: "Share",
			// 	icon: (
			// 		<OptionIconWrapper>
			// 			<ShareIcon />
			// 		</OptionIconWrapper>
			// 	),
			// 	onClick: () => {},
			// 	value: 1
			// },
		];

		if (!file?.creator && !isPrivilegedRole) {
			optionList.push({
				name: "Report",
				icon: <ReportIcon viewBox="0 -2 22 22" />,
				onClick: () => {},
				submenuOptions: getReportTypeOptions({
					reportType: ReportContentType.USERFILE,
					reportContentId: `${file?._id}`,
					reportContentName: file?.title,
					customCallback: () => {
						history.go(-1);
					}
				})
			});
		}

		if (isPrivilegedRole) {
			// Temporary hide until we get a design
			optionList.push({
				name: "Edit",
				icon: <Icon name="pencil" group="filled" viewBox={"-3 -3 30 30"} fill={"#8f9bb3"} />,
				onClick: () => {
					setShowFileDialog(true);
				},
				value: 3,
				closeOnSelect: true
			});

			optionList.push({
				name: "Delete",
				icon: <DeleteIcon />,
				onClick: () => {
					file && setDeletableFile({ id: file?._id, name: file?.title });
				},
				value: 4,
				closeOnSelect: true
			});
		}
		return optionList;
	}, [file, getReportTypeOptions, history, isPrivilegedRole]);

	const getSubtitle = useMemo(
		(): ReactNode => (
			<>
				{`${file?.totalViewed} views • `}
				<TimeAgo
					date={file?.createdAt}
					live={false}
					formatter={(value, unit, suffix) =>
						value < 60 && unit === "second" ? "Just now" : `${value} ${unit} ${suffix}`
					}
				/>
			</>
		),
		[file]
	);

	const getExtra = useMemo((): ReactNode => {
		const fileInfo = getFileIconAndType(file?.file?.url);
		const size = formatSizeUnits(file?.file?.size);

		return <>{`${fileInfo.type}${size ? ` - ${size}` : ""}`}</>;
	}, [file]);

	const isOpenable = useMemo(() => {
		const extType = fileExtensions.find(ex => ex.ext === fileExtension);
		return (
			(extType && extType.mime === "application/pdf") ||
			extType?.mime.startsWith("audio/") ||
			extType?.mime.startsWith("image/") ||
			extType?.mime.startsWith("video/")
		);
	}, [fileExtension]);

	const { readSong } = useAlbums();

	const handleDownload = useCallback(async () => {
		if (!file?.file.url) return;

		const data = await readSong(file.file.url);
		const link = document.createElement("a");
		link.href = window.URL.createObjectURL(
			new Blob([data], {
				type: "application/octet-stream"
			})
		);

		link.download = `${file.title}.${fileExtension}`;
		document.body.appendChild(link);
		link.click();
	}, [file, fileExtension, readSong]);

	const unlockedBlock = useMemo(
		() => (
			<>
				{isOpenable && (
					<StyledButton
						removeSideMargin
						buttonTheme={"main"}
						palette={"primary"}
						onClick={() => {
							file?.file?.url && openFileByLink(file.file.url);
							startStream({ entityId: file!._id, type: StreamEntityType.FILE });
							setFile(f => (f ? { ...f, totalViewed: (f?.totalViewed || 0) + 1, viewed: true } : undefined));
						}}
					>
						Open
					</StyledButton>
				)}
				{file?.canBeDownloaded && (
					<StyledButton
						className={"download"}
						removeSideMargin
						buttonTheme={"light"}
						palette={"light"}
						onClick={handleDownload}
					>
						Download
					</StyledButton>
				)}
			</>
		),
		[file, handleDownload, isOpenable, startStream]
	);

	const noMoreFiles = useMemo(() => !files.filter(x => x._id !== file?._id).length, [file, files]);

	const goToOtherFiles = useCallback(() => {
		history.push(`${routes?.member.files.getPath()}${groupId ? `?groupId=${groupId}` : ""}`);
	}, [routes, groupId, history]);

	return (
		<>
			<Container>
				<EntityTemplate
					loading={loading}
					coverPhoto={file?.coverPhoto?.url}
					fileTypeIcon={getFileIconAndType(file?.file?.url)?.icon}
					pretitle={file?.category?.name}
					title={file?.title || ""}
					subtitle={getSubtitle}
					extra={getExtra}
					locked={file?.locked}
					unlockedBlock={unlockedBlock}
					description={file?.description}
					options={!file?.locked ? options : []}
					unlockAction={
						<UnlockPremiumBtn
							premiumObject={file}
							teaserContent={file?.title}
							premiumObjectType={UnlockContentType.FILE}
							onUnlock={() => {
								getFileById(id).then(data => {
									setFile(data);
								});
							}}
						/>
					}
				/>
				{!noMoreFiles && (
					<OtherBlock>
						<OtherBlock.Header>
							<OtherBlock.Title>Other Files</OtherBlock.Title>
							<OtherBlock.ManageBtn removeSideMargin buttonTheme={"light"} palette={"basic"} onClick={goToOtherFiles}>
								See All
							</OtherBlock.ManageBtn>
						</OtherBlock.Header>
						<OtherBlock.Body>
							{(files || [])
								.filter(x => x._id !== file?._id)
								.slice(0, 4)
								.map((fileInfo, index) => (
									<ItemWrapper onClick={() => handleFileClicked(fileInfo._id)} key={index}>
										<FileCard file={fileInfo} />
									</ItemWrapper>
								))}
						</OtherBlock.Body>
					</OtherBlock>
				)}
			</Container>
			<ConfirmActionModal
				open={!!deletableFile}
				title={"Delete File?"}
				bodyText={`Are you sure you want to permanently delete "${deletableFile?.name}"?`}
				onClose={() => setDeletableFile(undefined)}
				onConfirm={() => deleteUserfile(`${deletableFile?.id}`)}
			/>
			<CreateFileDialog open={showFileDialog} onClose={() => setShowFileDialog(false)} editableFile={file} />
		</>
	);
};

export default FileDetailsPage;
