export const getYearsList = () => {
	const currentYear = new Date().getFullYear();
	const years: number[] = [];

	for (let i = currentYear; i >= 1940; i--) {
		years.push(i);
	}

	return years;
};
