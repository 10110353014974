import React from "react";

import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";

import { useParams } from "react-router";

import { view } from "modules/LiveConversation";

const LiveConvoInitiatePage = () => {
	const { id } = useParams() as { id: string };

	return (
		<MuiPickersUtilsProvider utils={DateFnsUtils}>
			<view.InitiateConvo id={id} />
		</MuiPickersUtilsProvider>
	);
};

export default LiveConvoInitiatePage;
