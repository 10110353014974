import React, { FC, ReactNode } from "react";

import { MenuDots, MenuDotsOption, SkeletonBase } from "shared/Components";

import { Entity } from "./style";

interface EntityDetailsTemplateProps {
	coverPhoto?: string;
	fileTypeIcon?: ReactNode;
	pretitle?: string;
	title: string;
	subtitle?: string | ReactNode;
	extra?: string | ReactNode;
	summary?: string;
	options?: MenuDotsOption[];
	description?: string;
	locked?: boolean;
	unlockedBlock?: ReactNode;
	onCloseOptionMenu?: () => void;
	unlockAction?: ReactNode;
	loading?: boolean;
	placeholderImg?: ReactNode;
}

const EntityDetailsTemplate: FC<EntityDetailsTemplateProps> = ({
	coverPhoto,
	fileTypeIcon,
	pretitle,
	title,
	subtitle,
	extra,
	summary,
	options,
	description,
	locked,
	unlockedBlock,
	// onCloseOptionMenu,
	unlockAction,
	children,
	loading,
	placeholderImg
}) => {
	return loading ? (
		<Entity.Wrapper>
			<Entity.InfoRow>
				<SkeletonBase variant="rect" animation="wave" width={224} height={224} />
				<Entity.InfoColumn>
					<Entity.Info>
						<SkeletonBase variant="text" animation="wave" width={"20%"} height={26} />
						<SkeletonBase variant="text" animation="wave" width={"30%"} height={50} />
						<SkeletonBase variant="text" animation="wave" width={"20%"} height={25} />
						<SkeletonBase variant="text" animation="wave" width={"30%"} height={23} />
					</Entity.Info>
					<Entity.Manage>
						<Entity.ManageOptionWrapper>
							<SkeletonBase variant="text" animation="wave" width={100} height={80} />
						</Entity.ManageOptionWrapper>
						<Entity.ManageOptionWrapper>
							<SkeletonBase variant="text" animation="wave" width={100} height={80} />
						</Entity.ManageOptionWrapper>
					</Entity.Manage>
				</Entity.InfoColumn>
			</Entity.InfoRow>
			<SkeletonBase variant="text" animation="wave" width={"10%"} height={30} />
			<SkeletonBase variant="text" animation="wave" width={"80%"} height={28} />
			<SkeletonBase variant="text" animation="wave" width={"80%"} height={28} />
			<SkeletonBase variant="text" animation="wave" width={"80%"} height={28} />
		</Entity.Wrapper>
	) : (
		<Entity.Wrapper>
			<Entity.InfoRow>
				<Entity.PreviewWrapper coverPhoto={coverPhoto}>
					{!coverPhoto && placeholderImg
						? placeholderImg
						: fileTypeIcon && !coverPhoto && <Entity.FileTypeIconWrapper>{fileTypeIcon}</Entity.FileTypeIconWrapper>}
				</Entity.PreviewWrapper>
				<Entity.InfoColumn>
					<Entity.Info>
						{pretitle && <Entity.Pretitle>{pretitle}</Entity.Pretitle>}
						<Entity.Title>{title}</Entity.Title>
						{subtitle && <Entity.Subtitle>{subtitle}</Entity.Subtitle>}
						{extra && <Entity.Extra>{extra}</Entity.Extra>}
						{summary && <Entity.Summary>{summary}</Entity.Summary>}
					</Entity.Info>
					<Entity.Manage>
						{locked && unlockAction}
						{!locked && unlockedBlock && <Entity.ManageOptionWrapper>{unlockedBlock}</Entity.ManageOptionWrapper>}
						{!!options?.length && (
							<Entity.ManageOptionWrapper>
								<MenuDots options={options} memberView />
							</Entity.ManageOptionWrapper>
						)}
					</Entity.Manage>
				</Entity.InfoColumn>
			</Entity.InfoRow>
			{description && (
				<>
					<Entity.DescriptionTitle>Description</Entity.DescriptionTitle>
					<Entity.Description dangerouslySetInnerHTML={{ __html: description }} />
				</>
			)}
			{children}
		</Entity.Wrapper>
	);
};

export default EntityDetailsTemplate;
