import styled from "styled-components";

import { Box, Button, Text } from "shared/ui-kit";

export const PageWrapper = styled.section`
	width: 1112px;
	margin: 0 auto;

	${props => props.theme.breakpoints.down("sm")} {
		background: white;
	}
`;

export const Banner = styled(Box)`
	width: 100%;
	height: 384px;
	padding: 16px;

	${props => props.theme.breakpoints.up("lg")} {
		margin: 16px 0 0 0;
		border-radius: 12px;
	}

	box-shadow: inset 0 0 0 0.5px #c5cee0, 0 2px 4px 0 rgba(0, 0, 0, 0.08);

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	position: relative;

	overflow: hidden;

	.banner-image {
		position: absolute;

		top: 0;
		left: 0;

		width: 100%;
		height: 100%;
	}
`;

Banner.Title = styled(Text)`
	font-size: 30px;
	font-weight: bold;
	line-height: 1.33;
	letter-spacing: normal;
	color: #fff;
	text-align: center;

	position: relative;
	z-index: 2;
`;

Banner.Subtitle = styled(Text)`
	font-size: 15px;
	font-weight: 600;
	line-height: 1.6;
	letter-spacing: normal;
	text-align: center;
	color: #fff;

	margin-top: 4px;

	position: relative;
	z-index: 2;
`;

Banner.InputWrapper = styled(Box)`
	margin: 24px auto 0 auto;

	width: 480px;

	${props => props.theme.breakpoints.down("xs")} {
		width: 100%;
	}

	position: relative;
	z-index: 2;
`;

export const Content = styled(Box)`
	margin-top: 24px;
`;

Content.HeadRow = styled(Box)`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

Content.Title = styled(Text)`
	font-size: 22px;
	font-weight: bold;
	line-height: 1.45;
	letter-spacing: normal;
	color: #222b45;
`;

Content.Body = styled(Box)`
	margin-top: 24px;
`;

export const SeeAllBtn = styled(Button)`
	margin: 0;
`;

export const WorkspaceCardWrapper = styled(Box)`
	display: inline-block;
	vertical-align: top;

	width: 266px;
	height: 320px;

	margin: 0 16px 16px 0;

	&:nth-child(4n) {
		margin-right: 0;
	}

	.MuiButtonBase-root {
		width: 100%;
		height: 100%;
	}
`;
