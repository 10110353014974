import React, { FC, useMemo } from "react";

import { Box } from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";

import { Controller, useForm } from "react-hook-form";

import { FileType } from "types/FilesContextValuesType";

import { ReactComponent as ClockIcon } from "assets/icons/clock-filled.svg";
import { ReactComponent as DownloadIcon } from "assets/icons/icon-download.svg";
import { CategoryProps } from "modules/Marketplace/Data/types";
import {
	DateInput,
	EnableFeature,
	LabeledInput,
	MemberDialog,
	RectangleUploader,
	SelectInput
} from "modules/MemberHome/View/shared";
import { MemberDialogContentWrapper } from "modules/MemberHome/View/shared/style";

import { SetupPrice } from "shared/Components";
import { SetupPriceMethods } from "shared/Components/SetupPrice";
import { useFiles, useUser } from "shared/hooks";
import { useS3Uploader } from "shared/services/s3Uploader";
import { times } from "shared/types/times";
import { Icon } from "shared/ui-kit";

import { getDefaultTime } from "utils/getDefaultTime";

import { InputWrapper } from "./style";

interface Props {
	onSubmit: (data: any) => Promise<void>;
	onUploaderChange: (files: FileList, onChange: (...event: any[]) => void) => Promise<void>;
	suggestedCategories: CategoryProps[];
	loadSuggestedCategories: (name: string) => Promise<void>;
	onClose: (
		event: {
			created?: boolean;
		},
		reason?: string | undefined
	) => void;
	open: boolean;
	setupPriceFormRef: React.RefObject<SetupPriceMethods>;
	editableModel?: FileType;
}

const CreateFileMemberView: FC<Props> = ({
	onSubmit,
	onUploaderChange,
	suggestedCategories,
	loadSuggestedCategories,
	onClose,
	open,
	setupPriceFormRef,
	editableModel
}) => {
	const {
		control,
		handleSubmit,
		formState: { isDirty, isSubmitting, errors },
		watch
	} = useForm();
	const { getData: getS3UploaderData } = useS3Uploader();
	const { uploading, uploadingProgress } = getS3UploaderData();

	const { getData: getFilesData } = useFiles();
	const { fileName } = getFilesData();

	const { enableSchedule } = watch(["enableSchedule"]);
	const tomorrow = useMemo(() => new Date().setDate(new Date().getDate() + 1), []);

	const { getData: getUserData } = useUser();
	const { user } = getUserData();

	const showPremiumAccess = useMemo(
		() => user?.isGlobalAdmin || user?.isGlobalOwner || user?.isGlobalModerator,
		[user]
	);

	return (
		<MemberDialog
			open={open}
			footerPrimary={{
				text: "Create File",
				disabled: uploading || !isDirty || isSubmitting,
				loading: isSubmitting,
				onClick: handleSubmit(onSubmit)
			}}
			onClose={onClose}
			confirmLeave={true}
			title="Create File"
			uploadingProgress={uploadingProgress}
			customWidth={512}
			modalHeight={680}
		>
			<form onSubmit={handleSubmit(onSubmit)}>
				<Controller
					name="photo"
					control={control}
					render={({ onChange, value }) => (
						<RectangleUploader
							defaultPreviewUrl={value}
							uploading={uploading}
							onChange={file => onUploaderChange(file, onChange)}
						/>
					)}
				/>
				<MemberDialogContentWrapper className="pv-20">
					<Controller
						name="title"
						control={control}
						rules={{
							required: "File title is required",
							maxLength: { value: 100, message: "Name can't be longer than 100 characters" },
							minLength: { value: 3, message: "Name must be at least 3 characters" }
						}}
						defaultValue={fileName}
						render={({ onChange, value, ref }) => (
							<InputWrapper>
								<LabeledInput
									name="title"
									onChange={onChange}
									value={value}
									placeholder=" "
									label="File title *"
									showCounter={100}
									error={errors?.title?.message}
									inputRef={ref}
								/>
							</InputWrapper>
						)}
					/>
					<Controller
						name="category"
						control={control}
						rules={{
							required: "Category is required",
							validate: val => {
								if (!val.value || !val.label) {
									return "Please choose a category";
								}
							}
						}}
						render={({ onChange, value, ref }) => (
							<InputWrapper>
								<SelectInput
									name="category"
									onChange={onChange}
									value={value ? value.label : null}
									placeholder=" "
									loadSuggestions={val => loadSuggestedCategories(val)}
									label="Category *"
									maxHeight={260}
									error={errors?.category?.message}
									noOptionsHeadline="Type Category Name ( ex: Artist )"
									options={
										suggestedCategories
											? suggestedCategories.map(suggestion => ({
													value: suggestion._id,
													label: suggestion.name
											  }))
											: []
									}
									selectInputRef={ref}
								/>
							</InputWrapper>
						)}
					/>
					<Controller
						name="description"
						control={control}
						rules={{
							required: "Description is required",
							maxLength: { value: 1000, message: "Description can't be longer than 1000 characters" },
							minLength: { value: 5, message: "Description must be at least 5 characters" }
						}}
						render={({ onChange, value, ref }) => (
							<InputWrapper>
								<LabeledInput
									textarea
									name="description"
									onChange={onChange}
									placeholder=" "
									label="Description *"
									value={value}
									error={errors?.description?.message}
									inputRef={ref}
								/>
							</InputWrapper>
						)}
					/>
					{showPremiumAccess && (
						<InputWrapper>
							<Controller
								name="priceChanged"
								control={control}
								render={({ onChange }) => (
									<SetupPrice
										ref={setupPriceFormRef}
										defaultValue={editableModel?.priceTags}
										onPriceChanged={() => onChange(true)}
									/>
								)}
							/>
						</InputWrapper>
					)}
					<Controller
						name="enableSchedule"
						control={control}
						render={({ onChange, value }) => (
							<InputWrapper>
								<EnableFeature
									icon={<Icon fill="#8f9bb3" name="clock" width={24} height={24} />}
									onChange={onChange}
									title="Schedule File"
									value={value}
								/>
							</InputWrapper>
						)}
					/>
					{enableSchedule && (
						<Box className="two-inputs-row">
							<Controller
								name="date"
								control={control}
								defaultValue={tomorrow}
								render={({ onChange, value }) => (
									<InputWrapper width="60%">
										<DateInput
											label="Date *"
											name="date"
											value={value}
											onChange={onChange}
											error={errors?.date?.message}
											placeholder=" "
											displayOnly
										/>
									</InputWrapper>
								)}
							/>
							<Controller
								name="time"
								control={control}
								defaultValue={getDefaultTime()}
								render={({ onChange, value }) => (
									<InputWrapper width="38%">
										<SelectInput
											label="Time *"
											name="time"
											value={value && value.label}
											onChange={onChange}
											placeholder=" "
											options={times}
											maxHeight={180}
											rightIcon={{
												el: <ClockIcon />,
												active: false
											}}
											displayOnly
											noOptionsHeadline="Select Time Option"
										/>
									</InputWrapper>
								)}
							/>
						</Box>
					)}
					<Controller
						name="canBeDownloaded"
						control={control}
						render={({ onChange, value }) => (
							<InputWrapper>
								<EnableFeature icon={<DownloadIcon />} onChange={onChange} title="Allow Download" value={value} />
							</InputWrapper>
						)}
					/>
					<Controller
						name="private"
						control={control}
						render={({ onChange, value }) => (
							<InputWrapper>
								<EnableFeature
									fill="#8f9bb3"
									icon={<VisibilityIcon />}
									onChange={onChange}
									title="Set as Private"
									description="Choose this option to hide from others in the community"
									value={value}
									verticalPadding
								/>
							</InputWrapper>
						)}
					/>
				</MemberDialogContentWrapper>
			</form>
		</MemberDialog>
	);
};

export default CreateFileMemberView;
