import { IStateProps } from "utils/StateOf";

import createStore from "utils/createStore";

import { EngageNotificationsModel } from "../../types";

export interface Values {
	notifications: EngageNotificationsModel[];
	deleteNotificationDialog?: EngageNotificationsModel;
	loadingNotifications: boolean;
	totalCount: number;
	page: number;
	keyword?: string;
	notificationShowPerPage: number;
}

export const initialState: IStateProps<Values> = {
	notifications: [],
	loadingNotifications: false,
	totalCount: 0,
	page: 1,
	notificationShowPerPage: 10
};

export default createStore<Values>({
	initialState
});
