import React, { useCallback, useMemo, useState } from "react";

import config from "config/appConfig";

import { ReactComponent as VyooIcon } from "assets/icons/vyoo.svg";
import { useAuth } from "modules/App/Data";

import { useCommunity, useRoutes, useUser } from "shared/hooks";
import { Button, InPlaceModal } from "shared/ui-kit";

import { ModalContent, ModalOverlay } from "./style";

const ConfirmEmailBlocker = () => {
	const { resendVerificationEmail } = useAuth();
	const { getData: getUserData } = useUser();
	const { user } = getUserData();
	const { logout } = useRoutes();
	const { getData: getCommunityData } = useCommunity();
	const { workspace, isWhitelabelAppMode } = getCommunityData();

	const [verificationEmailSent, setVerificationEmailSent] = useState(false);
	const [canResend, setCanResend] = useState(false);

	const handleResendEmail = useCallback(async () => {
		if (user) {
			const status = await resendVerificationEmail(isWhitelabelAppMode ? user.userId : undefined);
			if (status) {
				setVerificationEmailSent(true);
				setCanResend(false);
			}
		}
	}, [user, resendVerificationEmail, isWhitelabelAppMode]);

	const workspaceIcon = useMemo(() => workspace?.meta.appicon, [workspace]);

	return (
		<ModalOverlay>
			<InPlaceModal maxWidth="xs" open={true} onClose={() => {}} modalHeight={600}>
				<ModalContent className="flex items-center flex-col">
					{workspaceIcon ? (
						<img src={workspaceIcon} alt={workspace?.meta.displayName} />
					) : (
						<VyooIcon width={80} height={80} />
					)}
					<ModalContent.Header>Verify Your Email</ModalContent.Header>
					<ModalContent.Subtitle>
						To continue using {config.GLOBAL_CONSTANTS.ENV_APPLICATION_NAME}, please, click on the link in the email we
						sent you.
					</ModalContent.Subtitle>
					<ModalContent.ActionsWrapper>
						<Button palette="primary" onClick={handleResendEmail} disabled={!canResend && verificationEmailSent}>
							{!canResend && verificationEmailSent ? "Email sent" : "Re-send email"}
						</Button>
						<Button className="logout" onClick={logout}>
							Log out
						</Button>
					</ModalContent.ActionsWrapper>
				</ModalContent>
			</InPlaceModal>
		</ModalOverlay>
	);
};

export default ConfirmEmailBlocker;
