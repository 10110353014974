import React, { FC, useCallback, useEffect, useRef, useState } from "react";

import { Controller, useForm, useWatch } from "react-hook-form";
import { useHistory } from "react-router-dom";

import { ReactComponent as DogIcon } from "assets/icons/animal-dog.svg";
import { ReactComponent as CameraFlipIcon } from "assets/icons/camera-flip.svg";
import { SelectAnimalAvatarModal } from "shared/Components";
import { useCommunity, useMarketing, useMedia, usePersona } from "shared/hooks";
import { useS3Uploader } from "shared/services/s3Uploader";
import { MediaModel, MediaType } from "shared/types";
import { Loader } from "shared/ui-kit";

import { FormBase } from "./style";

import { CircularUploader, LabeledInput } from "../../shared";
import { InputWrapper } from "../../shared/style";

interface FileDetailsPageProps {
	fillProfileBirthdayUrl: string;
	switchCommunity?: boolean;
	adminDashboardUrl: string;
}

const FillProfileBase: FC<FileDetailsPageProps> = ({ fillProfileBirthdayUrl, switchCommunity, adminDashboardUrl }) => {
	const history = useHistory();
	const { getWorkspaceDetails, getData: getCommunityData } = useCommunity();
	const { workspace, isAkinaMode, isBitcoinMode } = getCommunityData();

	const { setPersona, createPersona, updatePersona, getData: getPersonaData } = usePersona();
	const { persona } = getPersonaData();

	const { getData: getMarketingData } = useMarketing();
	const { workspace: marketingWorkspace } = getMarketingData();

	const { uploadFile } = useS3Uploader();

	const { createMediaForUploaded } = useMedia();

	const {
		control,
		handleSubmit,
		formState: { isDirty, isSubmitting },
		setValue,
		errors
	} = useForm({
		mode: "onChange"
	});

	type CircularUploaderMethodsHandlers = React.ElementRef<typeof CircularUploader>;
	const circularUploaderRef = useRef<CircularUploaderMethodsHandlers>(null);

	const [showAnimalAvatarModal, setShowAnimalAvatarModal] = useState(false);

	const coverImage = useWatch({ control, name: "coverImage" });

	useEffect(() => {
		if (!workspace) {
			getWorkspaceDetails();
		}
	}, [workspace, getWorkspaceDetails]);

	const handleCloseAnimalAvatarModal = useCallback(
		async (imgUrl?: string) => {
			setShowAnimalAvatarModal(false);
			if (imgUrl) {
				setValue("coverImage", imgUrl);
			}
		},
		[setValue]
	);

	const onSubmit = async data => {
		let res = "";

		if (typeof data.coverImage === "string") {
			res = data.coverImage;
		} else {
			// We will create mediaModel on the welcome step, because we don't have personaId yet
			res = await uploadFile({
				file: data.coverImage,
				communityName: `${workspace?.communityUrl}`,
				noBucket: true
			}).then(file => file?.publicUrl || "");
		}

		if (isBitcoinMode) {
			const personaData = await createPersona(data!.firstname, data!.lastname);
			const profilePhotoMedia = await createMediaForUploaded({
				personaId: Number(personaData?.personaId),
				type: MediaType.profilePhoto,
				fileUrl: res
			});

			await updatePersona(
				{
					firstName: data?.firstname.trim(),
					lastName: data?.lastname.trim(),
					personaId: Number(personaData?.personaId),
					photos: [profilePhotoMedia as MediaModel]
				},
				true
			);

			return history.push(adminDashboardUrl);
		}

		setPersona(
			{
				...persona,
				photos: [res],
				firstName: data.firstname.trim(),
				lastName: data.lastname.trim()
			},
			true
		);

		history.push(fillProfileBirthdayUrl);
	};

	const getAvatarOptions = useCallback(
		(currentAvatar?: MediaModel) => [
			{
				icon: <CameraFlipIcon />,
				name: currentAvatar ? "Change Photo" : "Select Photo",
				onClick: () => {
					if (currentAvatar) {
						circularUploaderRef?.current!.clearValue();
						circularUploaderRef?.current!.initiateSelect();
					} else {
						circularUploaderRef?.current!.initiateSelect();
					}
				}
			},
			{
				icon: <DogIcon />,
				name: "Select Avatar",
				onClick: () => setShowAnimalAvatarModal(true)
			}
		],
		[]
	);

	return (
		<FormBase onSubmit={handleSubmit(onSubmit)}>
			<FormBase.WorkspaceLogoWrapper
				style={{
					backgroundImage: `url(${
						marketingWorkspace?.meta?.img || workspace?.meta?.img || workspace?.meta?.coverImage || ""
					})`
				}}
			/>
			<FormBase.WelcomeText>
				Welcome to {marketingWorkspace?.meta?.displayName || workspace?.meta?.displayName}
			</FormBase.WelcomeText>
			<FormBase.Title>Let’s create your profile</FormBase.Title>
			<FormBase.AvatarUploaderWrapper>
				<FormBase.AvatarUploaderTitle>
					{coverImage ? (
						"Looking Good 😎"
					) : (
						<>
							Upload Profile Picture <FormBase.Asterisk>*</FormBase.Asterisk>
						</>
					)}
				</FormBase.AvatarUploaderTitle>
				<FormBase.UploaderWrapper>
					<Controller
						name={"coverImage"}
						control={control}
						rules={{
							required: "Profile picture is required."
						}}
						defaultValue={
							!switchCommunity && !!persona?.photos?.length
								? (persona?.photos[0] as MediaModel).profilePicture
								: undefined
						}
						render={({ value, onChange }) => (
							<CircularUploader
								simpleMode
								size={116}
								ref={circularUploaderRef}
								preview={value ? (typeof value === "string" ? value : window.URL.createObjectURL(value)) : undefined}
								onChange={files => onChange(files?.length ? files[0] : files)}
								options={getAvatarOptions(value)}
							/>
							// <Uploader
							// 	urls={value ? [value] : undefined}
							// 	onChange={([file]) => onChange(file)}
							// 	orientation={orientationConst.horizontal}
							// 	width={"100%"}
							// 	height={104}
							// 	description={<></>}
							// 	accept={[
							// 		{
							// 			fileType: "image/png, image/jpeg, image/x-eps",
							// 			name: ""
							// 		}
							// 	]}
							// 	icon={<Icon group={"filled"} fill={"#8f9bb3"} name={"camera"} width={40} height={40} />}
							// />
						)}
					/>
					{errors?.coverImage && <FormBase.ErrorText>{errors?.coverImage?.message}</FormBase.ErrorText>}
				</FormBase.UploaderWrapper>
			</FormBase.AvatarUploaderWrapper>
			<FormBase.InfoForm>
				<FormBase.Row className="sm-block">
					<FormBase.Column>
						<Controller
							name="firstname"
							defaultValue={!switchCommunity ? persona?.firstName : undefined}
							control={control}
							rules={{
								required: "First name is required",
								validate: value => (value.trim().length >= 2 ? true : "Incorrect"),
								minLength: {
									value: 2,
									message: "First name should be at least 2 characters"
								},
								maxLength: {
									value: 50,
									message: "First name should be less 50 characters"
								}
							}}
							render={({ onChange, value }) => (
								<InputWrapper>
									<LabeledInput
										id="firstName"
										label={
											<>
												First Name <FormBase.Asterisk>*</FormBase.Asterisk>
											</>
										}
										placeholder=" "
										onChange={onChange}
										value={value}
										name="firstname"
										error={errors?.firstname?.message}
									/>
								</InputWrapper>
							)}
						/>
					</FormBase.Column>
					<FormBase.Column>
						<Controller
							name="lastname"
							defaultValue={!switchCommunity ? persona?.lastName : undefined}
							control={control}
							rules={{
								required: "Last name is required",
								validate: value => (value.trim().length >= 2 ? true : "Incorrect"),
								minLength: {
									value: 2,
									message: "Last name should be at least 2 characters"
								},
								maxLength: {
									value: 50,
									message: "Last name should be less 50 characters"
								}
							}}
							render={({ onChange, value }) => (
								<InputWrapper>
									<LabeledInput
										id="lastName"
										label={
											<>
												Last Name <FormBase.Asterisk>*</FormBase.Asterisk>
											</>
										}
										placeholder=" "
										onChange={onChange}
										value={value}
										name="lastname"
										error={errors?.lastname?.message}
									/>
								</InputWrapper>
							)}
						/>
					</FormBase.Column>
				</FormBase.Row>
			</FormBase.InfoForm>
			<FormBase.Footer>
				<FormBase.ActionBtn palette={"basic"} buttonTheme={"light"} onClick={() => history.go(-1)}>
					Back
				</FormBase.ActionBtn>
				<FormBase.ActionBtn
					id="Next"
					className={(isAkinaMode || isBitcoinMode) && "active"}
					type={"submit"}
					disabled={(persona?.firstName ? false : !isDirty) || isSubmitting}
					leftIcon={
						isSubmitting ? <Loader size="1rem" show={true} color="primary" variant="indeterminate" /> : undefined
					}
				>
					Next
				</FormBase.ActionBtn>
			</FormBase.Footer>
			{showAnimalAvatarModal && <SelectAnimalAvatarModal onClose={handleCloseAnimalAvatarModal} />}
		</FormBase>
	);
};

export default FillProfileBase;
