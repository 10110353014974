import React, { FC, useMemo } from "react";

import { Box } from "@material-ui/core";

import classNames from "classnames";

import { PlaceholderImage } from "shared/Components";
import { GroupModel, PlaceholderImageType } from "shared/types";
import { Text } from "shared/ui-kit";

import getCountMessage from "utils/getCountMessage";

import { CustomCheckbox, CustomRow, GroupImageContainer, Image } from "../style";

interface Props {
	group: GroupModel;
	handleToggleGroup: (group: GroupModel, isChecked: boolean) => void;
	lastTrackRef?: React.RefObject<HTMLDivElement>;
	selectedGroup?: GroupModel[];
	isSubGroup?: boolean;
}

const GroupBlock: FC<Props> = ({ group, lastTrackRef, handleToggleGroup, selectedGroup, isSubGroup = false }) => {
	const isGroupSelected = useMemo(() => selectedGroup?.some(g => g._id === group._id) || false, [selectedGroup, group]);
	return (
		<CustomRow
			ref={lastTrackRef}
			className={isGroupSelected ? "active" : ""}
			onClick={() => handleToggleGroup(group, !isGroupSelected)}
			isSubGroup={isSubGroup}
		>
			<Box className={classNames(`${isSubGroup ? "sub-item" : ""}`, "info")}>
				<GroupImageContainer className="image-container">
					{group.coverUrl ? (
						<Image src={group.coverUrl} />
					) : (
						<PlaceholderImage
							type={PlaceholderImageType.GROUP_DETAILS}
							width={60}
							height={60}
							viewBox={"0 0 720 320"}
						/>
					)}
				</GroupImageContainer>
				<Box>
					<Text variant="subtitle1">{group.name}</Text>
					<Text className="description">{getCountMessage(group.totalMembers, "member")}</Text>
				</Box>
			</Box>
			<Box>
				<CustomCheckbox checked={isGroupSelected} />
			</Box>
		</CustomRow>
	);
};

export default GroupBlock;
