import styled, { css } from "styled-components";

import { ActivitySettingsType } from "modules/Activity/Data/types/ActivityModel";
import { Box } from "shared/ui-kit";

const Wrapper = styled.div`
	margin: 24px;
	flex: 1;
	overflow: hidden;
	border-radius: 12px;
	border: 1px solid #e4e9f2;
`;

const Inner = styled(Box)`
	padding: 0 16px;
	height: 100%;
`;

const Notification = styled.div`
	padding: 16px 0;
	border-bottom: 1px solid #e4e9f2;
	display: flex;

	&:last-child {
		border-bottom: 0;
	}
`;

const NotificationIcon = styled.div<{ type: ActivitySettingsType }>`
	${({ type }) => {
		switch (type) {
			case ActivitySettingsType.connectionRequests:
			case ActivitySettingsType.events:
				return css`
					svg use {
						fill: #c5cee0;
					}
				`;
			case ActivitySettingsType.groups:
			case ActivitySettingsType.loyaltyAndRewards:
			case ActivitySettingsType.lives:
				return css`
					svg path {
						fill: #c5cee0;
					}
				`;
			case ActivitySettingsType.groupsYouManage:
				return css`
					svg:nth-child(1)
						> g:nth-child(1)
						> g:nth-child(1)
						> g:nth-child(1)
						> g:nth-child(2)
						> g:nth-child(1)
						> path:nth-child(1) {
						fill: #c5cee0;
					}
				`;
			default:
				return css``;
		}
	}}
`;

const NotificationInfo = styled.div`
	margin-left: 12px;
	max-width: 280px;
`;

const NotificationTitle = styled.div`
	font-size: 15px;
	font-weight: 600;
	color: #222b45;
`;

const NotificationDescription = styled.div`
	margin-top: 4px;
	color: #8f9bb3;
	font-size: 12px;
`;

const NotificationSwitchWrapper = styled.div`
	margin-left: auto;
`;

export {
	Wrapper,
	Inner,
	Notification,
	NotificationIcon,
	NotificationInfo,
	NotificationTitle,
	NotificationDescription,
	NotificationSwitchWrapper
};
