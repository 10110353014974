import React, { ReactNode } from "react";

import clsx from "clsx";

import { Item } from "./style";

interface InfoItemProps {
	avatarUrl?: string;
	title?: string;
	subtitle?: string;
	placeholderImg?: ReactNode;
	iconWrapperClassName?: string;
}

const InfoItem: React.FC<InfoItemProps> = ({ avatarUrl, title, subtitle, placeholderImg, iconWrapperClassName }) => (
	<Item.Wrapper>
		<Item.IconWrapper className={clsx(iconWrapperClassName, "flex-shrink-0")}>
			{avatarUrl ? <Item.Icon imgUrl={avatarUrl} /> : placeholderImg}
		</Item.IconWrapper>
		<Item.InfoBlock>
			<Item.Title>{title}</Item.Title>
			<Item.Subtitle>{subtitle}</Item.Subtitle>
		</Item.InfoBlock>
	</Item.Wrapper>
);

export default InfoItem;
