import React from "react";

import { Route, Switch } from "react-router";

import IApp from "types/IApp";

import SmartScroll from "shared/Components/SmartScroll";
import { NotFound } from "shared/Errorpages";

import SecureRoute from "shared/services/AuthDecorator/SecureRoute";
import OnboardingLayout from "shared/templates/OnboardingLayout";

import {
	AboutCommunity,
	CommunityTheme,
	Congratulation,
	CreateCommunity,
	FeatureCommunity,
	ProfileCommunity
} from "./Pages";
import { routes } from "./constants";

class OnboardingApp implements IApp {
	getRoutes = () => {
		return [
			<Route path={routes.onboarding.getPath()} key="community-onboarding-route">
				<SmartScroll>
					<OnboardingLayout>
						<Switch>
							<Route
								exact
								key={routes.onboarding.profile.getElementKey()}
								path={[routes.onboarding.profile.getPath(), routes.onboarding.community.profile.getPath()]}
								component={ProfileCommunity}
							/>
							{this.getSecuredRoutes()}
						</Switch>
					</OnboardingLayout>
				</SmartScroll>
			</Route>
		];
	};

	getCommunityRoutes = () => [
		<Route
			exact
			key={routes.onboarding.community.create.getElementKey()}
			path={routes.onboarding.community.create.getPath()}
			component={CreateCommunity}
		/>,
		<Route
			exact
			key={routes.onboarding.community.about.getElementKey()}
			path={routes.onboarding.community.about.getPath()}
			component={AboutCommunity}
		/>,
		<Route
			exact
			key={routes.onboarding.community.feature.getElementKey()}
			path={routes.onboarding.community.feature.getPath()}
			component={FeatureCommunity}
		/>,
		<Route
			exact
			key={routes.onboarding.community.theme.getElementKey()}
			path={routes.onboarding.community.theme.getPath()}
			component={CommunityTheme}
		/>,
		<Route
			exact
			key={routes.onboarding.congratulation.getElementKey()}
			path={[routes.onboarding.congratulation.getPath(), routes.onboarding.congratulation.confirmed.getPath()]}
			component={Congratulation}
		/>,
		<Route path="*" key={"NotFound"} component={NotFound} />
	];

	getSecuredRoutes = () => {
		const routeList = [...this.getCommunityRoutes()];

		return <SecureRoute routes={routeList} />;
	};
}

export default OnboardingApp;
