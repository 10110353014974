import styled from "styled-components";

import { ContentWrapper } from "shared/Organisms/Post/style";
import { Box, Button, Text } from "shared/ui-kit";
import { customBreakpoints } from "theme/default";

export const ContentWrapperBlock = styled(ContentWrapper)`
	margin: 96px auto 0 auto;

	${props => props.theme.breakpoints.down(customBreakpoints.tabletVerticalMax)} {
		margin-top: 32px;
	}
`;

export const FormWrapper = styled.form`
	width: 560px;
	max-width: 560px;

	margin: 0 auto;
	padding: 56px 0 40px;

	background-color: white;

	border-radius: 4px;
	box-shadow: inset 0 0 0 0.5px #c5cee0;
`;

export const FormBlock = styled(Box)`
	display: flex;
	align-items: center;
	justify-content: center;

	margin-top: 32px;
`;

FormBlock.InputWrapper = styled(Box)`
	width: 48px;
	height: 62px;

	margin: 0 12px;
	padding: 0;

	border-radius: 8px;

	border: solid 1px #e4e9f2;

	overflow: hidden;

	&:focus-within {
		border-color: #151a30;
	}
`;

FormBlock.Input = styled.input`
	width: 100%;
	height: 100%;

	display: flex;
	justify-content: center;
	align-items: center;

	border: none;
	box-shadow: none;
	outline: none;

	font-size: 15px;
	font-weight: 600;
	line-height: 1.6;
	letter-spacing: normal;
	color: #8f9bb3;
	text-align: center;

	&::placeholder {
		color: #8f9bb3;
	}
`;

export const TextBlock = styled(Text)`
	font-size: 18px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.33;
	letter-spacing: normal;
	text-align: center;
	color: #222b45;

	span {
		color: #6173fe;
	}
`;

export const ErrorText = styled(Text)`
	font-size: 18px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.33;
	letter-spacing: normal;
	text-align: center;
	color: #ff6138;

	margin-top: 20px;
`;

export const ManageBlock = styled(Box)`
	display: flex;
	justify-content: space-between;
	align-items: center;

	padding: 0 78px;
	margin-top: 50px;
`;

export const StyledButton = styled(Button)`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;
