import { useMemo } from "react";

import useHttpActions from "shared/services/api/core/useHttpActions";

import { convertMedia } from "shared/services/converters";
import { MediaRequest } from "shared/types";

import { API_URLS } from "../../modules/Post/constants";

const useMediaApiService = () => {
	const actions = useHttpActions();

	return useMemo(
		() => ({
			createMedia: async (data: MediaRequest) => {
				return convertMedia(await actions.post(API_URLS.MEDIA.CREATE, data));
			}
		}),
		[actions]
	);
};

export default useMediaApiService;
