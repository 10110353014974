import React, { memo, useEffect } from "react";

import { useAlbums, useMemberRoutes, usePlayer } from "shared/hooks";

import { ItemWrapper, ListWrapper } from "../Episodes/style";
import SeeAllBtn from "../SeeAllBtn";
import { BoxItem, ModelBlockType, Slider, TrackCard } from "../index";

const Episodes: React.FC = memo(() => {
	const { getAlbums, getAlbum, getAllAlbumsCount, getData: getAlbumsData } = useAlbums();
	const { albums, totalAlbumsCount } = getAlbumsData();

	const { getMemberRoutesData } = useMemberRoutes();
	const { routes } = getMemberRoutesData();

	const { setPlayTracks } = usePlayer();

	useEffect(() => {
		getAlbums({ keyword: "", limit: 10, offset: 1, notEmptyBy: "music" });
	}, [getAlbums]);

	useEffect(() => {
		getAllAlbumsCount({ notEmptyBy: "music" });
	}, [getAllAlbumsCount]);

	if (!albums?.length) return null;

	const handlePlayPodcast = async (id: string) => {
		const album = await getAlbum({ id, shouldReturn: true });
		if (!album) return;
		const filteredMusics = album.musics.filter(m => !m.locked);
		setPlayTracks({
			idx: 0,
			tracks: {
				items: filteredMusics
			}
		});
	};

	return (
		<BoxItem title={"Recent Albums"}>
			<ListWrapper>
				<Slider hideFrom={1} count={(albums || []).length} itemSize={240}>
					{(albums || []).map((item, index) => (
						<ItemWrapper key={index}>
							<TrackCard
								hidePlay={!item.musicIds.length || item.locked}
								grayHover
								type={ModelBlockType.album}
								item={item}
								customHandlePlayTracks={() => handlePlayPodcast(item._id)}
							/>
						</ItemWrapper>
					))}
				</Slider>
			</ListWrapper>
			{totalAlbumsCount > 10 && <SeeAllBtn link={routes?.member.albums.getPath()} />}
		</BoxItem>
	);
});

export default Episodes;
