import React, { FC, useCallback, useMemo } from "react";

import { Box, IconButton } from "@material-ui/core";

import { DateTime } from "luxon";

import { ReactComponent as SoundWaveIcon } from "assets/icons/iconSoundWave.svg";
import { ReactComponent as IconPause } from "assets/icons/pause-track.svg";
import { SkeletonBase, UnlockPremiumBtn } from "shared/Components";
import { useAlbums, useCommunity, usePlayer } from "shared/hooks";
import { TrackMeta, TrackModel, UnlockContentType } from "shared/types";
import { Icon, Text, Tooltip } from "shared/ui-kit";

import { secondsToHumanTime } from "utils/serviceUtils/helpers";

import { BuyTrackButton, EmptyBtnSpace, PlayTrack, TrackWrapper } from "./style";

interface AlbumTrackProps {
	albumId?: string;
	track?: TrackModel;
	order?: number;
	isFree?: boolean;
	loading?: boolean;
	individualAccess?: boolean;
	lastTrackRef: React.RefObject<HTMLDivElement> | null;
	handleStartFromThisSong?: (id: string) => void;
	isAllowDownloadAlbum?: boolean;
}

const AlbumTrack: FC<AlbumTrackProps> = ({
	albumId,
	track,
	order,
	handleStartFromThisSong,
	// isFree,
	lastTrackRef,
	individualAccess,
	loading,
	isAllowDownloadAlbum = false
}) => {
	const { setAlbumPlaying, setPlay, getData: getPlayerData } = usePlayer();
	const { albumPlaying, playTracks, play } = getPlayerData();

	const { readSong, getAlbumDetails } = useAlbums();

	const { communityColors } = useCommunity();

	const currentSong = useMemo(
		() =>
			playTracks.tracks?.items
				? playTracks.tracks.items[playTracks.idx]
				: playTracks.series?.items
				? playTracks.series.items[playTracks.idx]
				: undefined,
		[playTracks]
	);

	const url = useMemo(() => (track?.meta as TrackMeta)?.artwork?.url, [track]);

	const handlePlayTrack = useMemo(
		() => () => {
			if (track) {
				if (track._id === currentSong?._id) {
					setPlay(!play);
				} else {
					handleStartFromThisSong && handleStartFromThisSong(track._id);
					setPlay(true);
					setAlbumPlaying(albumId);
				}
			}
		},
		[currentSong, handleStartFromThisSong, play, setAlbumPlaying, setPlay, track, albumId]
	);

	const handleDownloadTrack = useCallback(async () => {
		if (track) {
			const data = await readSong((track.meta as TrackMeta).track.url);

			const link = document.createElement("a");

			link.href = window.URL.createObjectURL(
				new Blob([data], {
					type: "application/octet-stream"
				})
			);

			link.download = `${track.title}.mp3`;

			document.body.appendChild(link);

			link.click();
		}
	}, [readSong, track]);

	const isPlaying = useMemo(() => track?._id === currentSong?._id && play, [currentSong, play, track]);
	const playingNow = albumPlaying === albumId && isPlaying;

	return loading ? (
		<TrackWrapper isPlaying={playingNow} ref={lastTrackRef}>
			<TrackWrapper.TrackDetails>
				<SkeletonBase width={26} height={40} />
				<Box className="track-no-img skeleton">
					<SkeletonBase width={40} height={65} />
				</Box>
				<Box>
					<SkeletonBase width={120} height={20} />
					<SkeletonBase width={50} height={20} className={"artistName"} />
				</Box>
			</TrackWrapper.TrackDetails>
			<SkeletonBase width={120} height={40} />
			<SkeletonBase width={120} height={40} />
		</TrackWrapper>
	) : (
		<TrackWrapper isPlaying={playingNow} ref={lastTrackRef}>
			<TrackWrapper.TrackDetails>
				<Text variant="caption">{order}.</Text>
				{url ? (
					<img src={url} alt={track?.title} />
				) : (
					<Box className="track-no-img">
						<SoundWaveIcon />
					</Box>
				)}
				<Box>
					<Text variant="subtitle2">{track?.title}</Text>
					<Text variant="caption" className={`artistName ${playingNow && "playing"}`}>
						{(track?.meta as TrackMeta)?.artist}
					</Text>
				</Box>
			</TrackWrapper.TrackDetails>
			<Text variant="caption">{secondsToHumanTime(track?.duration || "")}</Text>
			<Text variant="caption">{track ? DateTime.fromISO(track.createdAt).toFormat("MMM d, yyyy") : ""}</Text>
			<TrackWrapper.TrackActions>
				<TrackWrapper.TrackActions>
					{!track?.locked || !track?.priceTags?.length ? (
						<>
							<PlayTrack btnColor={communityColors.btn} onClick={handlePlayTrack}>
								{playingNow ? (
									<IconPause className="fw" fill="white" width={16} height={16} />
								) : (
									<Icon name="play" group="filled" fill="white" width={14} height={14} />
								)}
							</PlayTrack>
							{/* https://vyoo-me.atlassian.net/browse/VM-6892 */}
							{individualAccess &&
							(isAllowDownloadAlbum || (!isAllowDownloadAlbum && (track?.meta as TrackMeta)?.canBeDownloaded)) ? (
								<BuyTrackButton free onClick={handleDownloadTrack}>
									Download
								</BuyTrackButton>
							) : (
								<EmptyBtnSpace />
							)}
						</>
					) : (
						<>
							<PlayTrack disabled>
								<Icon name="play" group="filled" fill="white" width={14} height={14} />
							</PlayTrack>
							{!!track.priceTags?.length && (
								<UnlockPremiumBtn
									premiumObject={track}
									premiumObjectType={UnlockContentType.TRACK}
									onUnlock={() => {
										if (albumId) {
											getAlbumDetails(albumId, 1, 10);
										}
									}}
								/>
							)}
						</>
					)}
				</TrackWrapper.TrackActions>
				<Tooltip text="Coming soon">
					<IconButton size="small">
						<Icon name="ellipsis" group="filled" fill="#c5cee0" />
					</IconButton>
				</Tooltip>
			</TrackWrapper.TrackActions>
		</TrackWrapper>
	);
};

export default AlbumTrack;
