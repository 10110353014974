import { useCallback, useMemo } from "react";

import { useCommunity, useNotification } from "shared/hooks";

import { useFeatureApiService } from "shared/services";

import { useIntegrationStore } from "../contexts/IntegrationContext";

import { useIntegrationsService } from "../services";
import { PodcastIntegration } from "../types/PodcastsResponse";

const useIntegrations = () => {
	const { getWorkspaceDetails } = useCommunity();
	const integrationsService = useIntegrationsService();
	const { showMessage } = useNotification();
	const featureService = useFeatureApiService();

	const store = useIntegrationStore();
	const { setState } = useIntegrationStore();

	const methods = useMemo(
		() => ({
			getPodcasts: async () => {
				methods.setIsLoading(true);
				const { podcastIntegrations } = await integrationsService.getPodcasts();
				setState(ctx => ({ ...ctx, podcasts: podcastIntegrations, isLoading: false }));
			},
			updatePodcastRss: async (integrationId: string, links: string[]) => {
				try {
					const { success, podcastIntegration, failedLinks } = await integrationsService.updatePodcastRss(
						integrationId,
						links
					);

					setState(ctx => ({
						...ctx,
						isLoading: false,
						podcasts: ctx.podcasts?.map(podcastItem => {
							if (podcastItem._id === integrationId) {
								return podcastIntegration;
							}

							return podcastItem;
						})
					}));

					return {
						success,
						apiError: null,
						failedLinks
					};
				} catch (error) {
					return {
						success: false,
						apiError: (error as Error).message
					};
				}
			},
			deleteRssLinks: async (integrationId: string, linkIds: string[]) => {
				methods.setIsLoading(true);

				try {
					const { podcastIntegration } = await integrationsService.deleteRssLinks(integrationId, linkIds);

					setState(ctx => ({
						...ctx,
						isLoading: false,
						shouldUpdateFields: true,
						podcasts: ctx.podcasts?.map(podcastItem => {
							if (podcastItem._id === integrationId) {
								return podcastIntegration;
							}

							return podcastItem;
						})
					}));

					showMessage("Integration successfully disconnected.");
				} catch (error) {
					showMessage((error as Error).message);
				}

				methods.setIsLoading(false);
				methods.setConfirmDisconnect(null);
			},
			updateShopifyInfo: async (shopifyDomain = "", storefrontAccessToken = "") => {
				setState(ctx => ({ ...ctx, isLoading: true }));
				try {
					await featureService.manageFeatures({
						shopifyDomain,
						storefrontAccessToken
					});

					await getWorkspaceDetails();

					!shopifyDomain && !storefrontAccessToken
						? showMessage("Shopify successfully disconnected.", 3)
						: showMessage("Shopify successfully updated. ✨", 3);
				} catch (error) {
					showMessage("Couldn't update Shopify, please try again.", 3);
				} finally {
					setState(ctx => ({ ...ctx, isLoading: false }));
				}
			},
			openIntegrationPopup: (company: string) => {
				setState(ctx => ({ ...ctx, openDialog: company }));
			},
			closeIntegrationPopup: () => {
				setState(ctx => ({ ...ctx, openDialog: null }));
			},
			updateSearchResults: (searchResults: PodcastIntegration[]) => {
				setState(ctx => ({ ...ctx, searchResults }));
			},
			setConfirmDisconnectURL: (
				confirmDisconnectURL: { integration: string; url: string; confirmed: boolean } | null
			) => {
				setState(ctx => ({ ...ctx, confirmDisconnectURL }));
			},
			setIntegrationsUrl: (integrationsUrl: string) => {
				setState(ctx => ({ ...ctx, integrationsUrl }));
			},
			setShouldUpdateFields: (shouldUpdateFields: boolean) => {
				setState(ctx => ({ ...ctx, shouldUpdateFields }));
			},
			setIsLoading: (isLoading: boolean) => {
				setState(ctx => ({ ...ctx, isLoading }));
			},
			setConfirmDisconnect: (confirmDisconnect: PodcastIntegration | null, linkToDelete?: string) => {
				setState(ctx => ({ ...ctx, confirmDisconnect, linkToDelete }));
			},
			setOpenShopifyDialog: (openShopifyDialog: boolean) => {
				setState(ctx => ({ ...ctx, openShopifyDialog }));
			},
			setDataChanged: (dataChanged: boolean) => {
				setState(ctx => ({ ...ctx, dataChanged }));
			}
		}),
		[featureService, getWorkspaceDetails, integrationsService, setState, showMessage]
	);

	const getData = useCallback(() => {
		return store;
	}, [store]);

	return { ...methods, getData };
};

export default useIntegrations;
