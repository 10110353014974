import { Box, ButtonBase } from "@material-ui/core";
import styled from "styled-components";

export const SwitchWrapper = styled(Box)`
	position: relative;
	display: flex;
	align-items: center;
	padding: 2px;
	&.variant__member {
		border: solid 1px #e4e9f2;
		border-radius: 8px;
	}
	&.variant__basic {
		border-radius: 10px;
		border: solid 0.5px #c6cfe0;
		background-color: #f7f9fc;
	}
`;

SwitchWrapper.ActiveBG = styled(Box)`
	position: absolute;
	top: 2px;
	left: 2px;
	width: 50%;
	height: calc(100% - 4px);
	transform: translateX(0);
	&.animate-change {
		transition: ease-in-out 0.3s transform;
	}
	&.variant__member {
		background-color: ${({ color }) => (color ? `${color}33` : "#edf1f733")};
		border-radius: 6px;
	}
	&.variant__basic {
		background-color: #fff;
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
		border: solid 1px rgba(198, 207, 224, 0.4);
		border-radius: 8px;
	}
	&.opt-two {
		transform: translateX(100%) translateX(-4px);
		&.transformXZero {
			transform: translateX(100%);
		}
	}
`;

SwitchWrapper.Option = styled(ButtonBase)`
	border-radius: 6px;
	width: 50%;
	min-height: 36px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 12px;
	line-height: 1.33;
	color: #222b45;
	padding: 0;
	&.small {
		min-height: auto;
	}
	&.variant__member {
		font-weight: bold;
		&.active {
			color: #ffffff;
			color: ${({ color }) => color || "#ffffff"};
		}
	}
`;
