import React, { FC, memo, useCallback, useEffect, useState } from "react";

import { Box } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";

import { boardIcons } from "modules/Manage/View/Components/SelectIconField";
import { useBoards, useCommunity } from "shared/hooks";

import { CategoryModel } from "shared/types";
import { Text } from "shared/ui-kit";
import { formatCount } from "utils/serviceUtils/helpers";

import { CategoryWrapper } from "./style";

const CategoryInfoCard: FC<{ active: boolean; catId: string }> = memo(({ active, catId }) => {
	const { communityColors } = useCommunity();

	const getIcon = useCallback(iconName => boardIcons.find(x => x.value === iconName), []);
	const [board, setBoard] = useState<CategoryModel | null>(null);

	const { getBoard, getData: getBoardsData } = useBoards();
	const { boards } = getBoardsData();

	useEffect(() => {
		if (active && !board) {
			const board = boards?.find(b => b.cid === catId);
			if (board) {
				setBoard(board);
			} else {
				getBoard(catId).then(cat => setBoard(cat));
			}
		}
	}, [active, board, boards, catId, getBoard]);

	return (
		<CategoryWrapper active={active} communityColor={communityColors.btn}>
			<Box display="flex" alignItems="center">
				{!board ? (
					<>
						<Skeleton variant="circle" width={56} height={56} />
						<Box marginLeft="8px">
							<Skeleton variant="text" width={210} height={28} />
							<Skeleton variant="text" width={160} height={18} />
						</Box>
					</>
				) : (
					<>
						<Box className="icon-wrapper">{getIcon(board.iconName)?.icon}</Box>
						<Box>
							<Text variant="h4">{board.name}</Text>
							<Text>{formatCount(board.topic_count || board.totalTopicCount, "post")} in this board</Text>
						</Box>
					</>
				)}
			</Box>
			{/* <Button size="medium" buttonTheme="light">
				Follow
			</Button> */}
		</CategoryWrapper>
	);
});

export default CategoryInfoCard;
