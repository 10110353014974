import { useMemo } from "react";

import axios from "axios";

import { API_URLS } from "shared/constants";

import useHttpActions from "shared/services/api/core/useHttpActions";
import { BaseConverter } from "shared/services/converters";

import { MediaObjType, PriceTag } from "shared/types";

import { GetAlbumResponse } from "../types/AlbumTypes";

export interface AlbumModel {
	type: "album" | "playlist";
	title: string;
	artist: string;
	meta: MediaObjType;
	genre: string[];
	creatorId: string;
	description: string;
	private: boolean;
	scheduleDate: string;
	releaseDate: string;
	canBeDownloaded: boolean;
	musicIds: string[];
	musics: string[];
	createdAt: string;
	priceTags?: PriceTag[];
	locked: boolean;
	individualAccess: boolean;
	_id: string;
	creatorPersona?: {
		firstName: string;
		lastName: string;
		_id: string;
	};
}

const useAlbumApiService = () => {
	const actions = useHttpActions();

	return useMemo(
		() => ({
			createAlbum: async body => {
				return BaseConverter.convert<{ success: boolean; album: AlbumModel }>(
					await actions.post(API_URLS.ALBUMS.GET_CREATE_ALBUMS, body)
				);
			},
			updateAlbum: async (albumId: string, body) => {
				return BaseConverter.convert<{ success: boolean; album: AlbumModel }>(
					await actions.put(API_URLS.ALBUMS.GET_UPDATE_DELETE_ALBUM(albumId), body)
				);
			},
			updateAlbumOrder: async (albumId: string, body) => {
				return BaseConverter.convert<{ success: boolean; album: AlbumModel }>(
					await actions.put(API_URLS.ALBUMS.UPDATE_ALBUM_ORDER(albumId), body)
				);
			},
			deleteAlbum: async (albumId: string) => {
				return BaseConverter.convert<{ success: boolean }>(
					await actions.delete(API_URLS.ALBUMS.GET_UPDATE_DELETE_ALBUM(albumId))
				);
			},
			getAlbums: async ({
				limit,
				keyword,
				offset,
				creatorId,
				sortBy,
				notEmptyBy
			}: {
				limit: number;
				keyword: string;
				offset: number;
				creatorId?: string;
				sortBy?: string;
				notEmptyBy?: string;
			}) => {
				return BaseConverter.convert<{ success: boolean; albums: AlbumModel[] }>(
					await actions.get(API_URLS.ALBUMS.GET_CREATE_ALBUMS, {
						limit,
						keyword: keyword || null,
						offset,
						creatorId,
						sortBy,
						notEmptyBy
					})
				);
			},
			getAllAlbumsCount: async ({ notEmptyBy, keyword }: { notEmptyBy?: string; keyword?: string }) => {
				const params: { notEmptyBy?: string; keyword?: string } = {};

				if (notEmptyBy) {
					params["notEmptyBy"] = notEmptyBy;
				}

				if (keyword) {
					params["keyword"] = keyword;
				}

				return BaseConverter.convert<{ totalCount: number }>(
					await actions.get(API_URLS.ALBUMS.GET_ALBUMS_COUNT, params)
				);
			},
			getAlbum: async (id: string, limit: number, offset: number) => {
				return BaseConverter.convert<{ album: GetAlbumResponse }>(
					await actions.get(API_URLS.ALBUMS.GET_UPDATE_DELETE_ALBUM(id), {
						limit,
						offset
					})
				);
			},
			readSong: async (url: string, updateTrack: (url: string, progress: number) => void) => {
				return BaseConverter.convert(
					await axios({
						method: "get",
						url,
						responseType: "arraybuffer",
						onDownloadProgress: progressEvent => {
							const totalLength = progressEvent.lengthComputable
								? progressEvent.total
								: progressEvent.target.getResponseHeader("content-length") ||
								  progressEvent.target.getResponseHeader("x-decompressed-content-length");

							updateTrack(url, Math.round((progressEvent.loaded * 100) / totalLength));
						}
					})
				);
			},
			getAlbumDownloadUrls: async (id: string, data?: { musicIds: string | null }) => {
				return BaseConverter.convert<{ musics: { url: string; name: string }[] }>(
					await actions.get(API_URLS.ALBUMS.MUSIC_URLS(id), data)
				);
			}
		}),
		[actions]
	);
};

export default useAlbumApiService;
