import styled from "styled-components";

import { Button } from "shared/ui-kit";

const FormWrapper = styled.div`
	margin-top: 20px;
	height: 100%;

	form {
		display: flex;
		flex-direction: column;
		height: 100%;
		justify-content: space-between;
	}
`;

const Form = styled.form`
	display: flex;
	flex-direction: column;
	height: 100%;
`;

const FormContent = styled.div`
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
`;

const FormActions = styled.div`
	display: flex;
	justify-content: space-between;
`;

const FormButton = styled(Button)<{ size?: "small" }>`
	margin: 0;
	font-size: ${({ size }) => (size === "small" ? "14px" : "16px")};
	border-radius: 8px;
	height: ${({ size }) => (size === "small" ? "40px" : "48px")};

	span {
		font-weight: bold;
	}
`;

export { FormWrapper, Form, FormContent, FormActions, FormButton };
