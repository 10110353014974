export const genres = [
	{ key: "acoustic", value: "Acoustic" },
	{ key: "afrikaans", value: "Afrikaans" },
	{ key: "african", value: "African Music" },
	{ key: "alternative", value: "Alternative" },
	{ key: "americana", value: "Americana" },
	{ key: "anime", value: "Anime" },
	{ key: "arabic", value: "Arabic" },
	{ key: "baile", value: "Baile Funk" },
	{ key: "blues", value: "Blues" },
	{ key: "bollywood", value: "Bollywood" },
	{ key: "brazilian", value: "Brazilian Pop" },
	{ key: "cpop", value: "C-Pop" },
	{ key: "canadian", value: "Canadian Music" },
	{ key: "cantopop", value: "Cantopop" },
	{ key: "chill", value: "Chill" },
	{ key: "christian", value: "Christian" },
	{ key: "classic_rock", value: "Classic Rock" },
	{ key: "classical", value: "Classical" },
	{ key: "classics", value: "Classics" },
	{ key: "commuting", value: "Commuting" },
	{ key: "country", value: "Country" },
	{ key: "dance", value: "Dance" },
	{ key: "deutschpop", value: "Deutschpop" },
	{ key: "deutschrap", value: "Deutschrap" },
	{ key: "dj_mix", value: "DJ Mixes" },
	{ key: "electronic", value: "Electronic" },
	{ key: "feel_good", value: "Feel Good" },
	{ key: "feel_blue", value: "Feeling Blue" },
	{ key: "film", value: "Film, TV & Stage" },
	{ key: "fitness", value: "Fitness" },
	{ key: "flamenco", value: "Flamenco" },
	{ key: "focus", value: "Focus" },
	{ key: "frechpop", value: "French Pop" },
	{ key: "frechrap", value: "French Rap" },
	{ key: "gaming", value: "Gaming Music" },
	{ key: "gospel", value: "Gospel" },
	{ key: "turkish", value: "Turkish Folk Music" },
	{ key: "hardrock", value: "Hard Rock" },
	{ key: "hiphop", value: "Hip-Hop/Rap" },
	{ key: "hits", value: "Hits" },
	{ key: "holiday", value: "Holiday" },
	{ key: "horspiele", value: "Hörspiele und Hörbücher" },
	{ key: "indian", value: "Indian Independent" },
	{ key: "indie", value: "Indie" },
	{ key: "indonesian", value: "Indonesian Music" },
	{ key: "islamic", value: "Islamic Music" },
	{ key: "jhiphop", value: "J-Hip-Hop" },
	{ key: "jpop", value: "J-Pop" },
	{ key: "jrock", value: "J-Rock" },
	{ key: "japan", value: "Japan" },
	{ key: "jazz", value: "Jazz" },
	{ key: "kpop", value: "K-Pop" },
	{ key: "Kannada", value: "Kannada" },
	{ key: "kayokyoku", value: "Kayokyoku" },
	{ key: "kids", value: "Kids & Family" },
	{ key: "latin", value: "Latin" },
	{ key: "malayalam", value: "Malayalam" },
	{ key: "malaysian", value: "Malaysian Music" },
	{ key: "mandopop", value: "Mandopop" },
	{ key: "metal", value: "Metal" },
	{ key: "mizrahi", value: "Mizrahi Music" },
	{ key: "motivation", value: "Motivation" },
	{ key: "mpb", value: "MPB" },
	{ key: "mus_mex", value: "Musica Mexicana" },
	{ key: "mus_trop", value: "Musica Tropical" },
	{ key: "musiq", value: "Musique francophone" },
	{ key: "neosoul", value: "Neo Soul" },
	{ key: "oldies", value: "Oldies" },
	{ key: "orig_pil", value: "Original Pilipino Music" },
	{ key: "party", value: "Party" },
	{ key: "pop", value: "Pop" },
	{ key: "pop_span", value: "Pop in Spanish" },
	{ key: "pop_ital", value: "Pop Italiano" },
	{ key: "pop_lat", value: "Pop Latino" },
	{ key: "punjabi", value: "Punjabi" },
	{ key: "rnb", value: "R&B" },
	{ key: "reggae", value: "Reggae" },
	{ key: "religi", value: "Religi/Ketuhanan" },
	{ key: "rock", value: "Rock" },
	{ key: "rockspan", value: "Rock in Spanish" },
	{ key: "rockyalt", value: "Rock y Alternativo" },
	{ key: "romance", value: "Romance" },
	{ key: "russian", value: "Russian" },
	{ key: "russian_hiphop", value: "Russian Hip-Hop" },
	{ key: "russian_pop", value: "Russian Pop" },
	{ key: "russian_rock", value: "Russian Rock" },
	{ key: "samba", value: "Samba" },
	{ key: "sanat", value: "Sanat" },
	{ key: "schlager", value: "Schlager" },
	{ key: "sertanejo", value: "Sertanejo" },
	{ key: "sleep", value: "Sleep" },
	{ key: "soul", value: "Soul/Funk" },
	{ key: "tamil", value: "Tamil" },
	{ key: "telugu", value: "Telugu" },
	{ key: "thai", value: "Thai Music" },
	{ key: "up_ext", value: "Up Next" },
	{ key: "urbano", value: "Urbano Latino" },
	{ key: "vallenato", value: "Vallenato" },
	{ key: "wellness", value: "Wellness" },
	{ key: "worldwide", value: "Worldwide" }
];
