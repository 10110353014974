import styled from "styled-components";

import { Box } from "shared/ui-kit";

export const Editor = styled(Box);

Editor.InputWrapper = styled.form`
	display: block;
	width: 100%;

	position: relative;

	overflow: hidden;

	padding-bottom: 0;

	transition: padding-bottom 0.25s ease-in-out;

	&.large {
		padding-bottom: 36px;
	}
`;

Editor.Input = styled(Box)`
	width: 100%;
	height: 40px;

	resize: none;

	padding: 8px 16px;

	background-color: transparent;
	border: none;

	font-size: 15px;
	font-weight: normal;
	line-height: 1.33;
	letter-spacing: normal;
	color: #222b45;

	transition: height 0.25s ease-in-out;

	position: relative;

	outline: none;

	&.no-side-padding {
		padding-left: 0;
		padding-right: 0;
	}

	&:before {
		content: "Write a comment...";

		display: block;

		font-weight: inherit;
		font-style: inherit;
		color: #8f9bb3;

		position: absolute;
		top: 8px;
		left: 16px;

		opacity: 0;

		transition: opacity 0.15s linear;
	}

	&:empty {
		&:before {
			opacity: 1;
		}
	}

	.attachment-block {
		display: inline-block;
	}

	.author {
		font-weight: bold;
		font-size: inherit;
		font-style: inherit;
		line-height: inherit;
		text-decoration: none;
		color: inherit;
		cursor: pointer;
	}

	> * {
		position: relative;
	}
`;

Editor.ExtraActions = styled(Box)`
	display: flex;
	justify-content: flex-end;
	align-items: center;

	position: absolute;
	right: 16px;
	bottom: 8px;

	z-index: 2;
`;

Editor.ExtraItem = styled(Box)`
	width: 24px;
	height: 24px;

	display: flex;
	justify-content: center;
	align-items: center;

	cursor: pointer;

	position: relative;

	&:not(:last-child) {
		margin-right: 16px;
	}

	&.fill-first-path {
		svg {
			path {
				&:first-child {
					fill: #8f9bb3;
				}
			}
		}
	}

	&.fill-second-path {
		svg {
			path {
				&:nth-child(2) {
					fill: #8f9bb3;
				}
			}
		}
	}

	&[allow="true"] {
		cursor: not-allowed;
		pointer-events: visible;

		&.fill-first-path {
			svg {
				path {
					&:first-child {
						fill: #222b45;
					}
				}
			}
		}

		&.fill-second-path {
			svg {
				path {
					&:nth-child(2) {
						fill: #222b45;
					}
				}
			}
		}
	}
`;
