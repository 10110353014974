import React, { useCallback, useEffect, useMemo, useState } from "react";

import { InputAdornment, TextField } from "@material-ui/core";

import { ReactComponent as SearchIcon } from "assets/icons/iconSearch.svg";
import { FilterBlock, FilterOptionType } from "modules/MemberHome/View/Components";
import { TracksFilters } from "modules/MemberHome/View/Containers/Tracks";
import { EmptyBody, ScrollableBlock } from "shared/Components";
import { useTrack, useUser } from "shared/hooks";
import { TrackModel, genres } from "shared/types";
import { MenuItem, Text } from "shared/ui-kit";

import TrackItem from "./TrackItem";
import { ContentWrapper, CustomChip, CustomSelect, FilterWrapper, TrackItemsWrapper } from "./style";

import SelectItemTemplate from "../Blocks/SelectItemTemplate";

const LoaderBlock = () => (
	<>
		<SelectItemTemplate loading />
		<SelectItemTemplate loading />
		<SelectItemTemplate loading />
	</>
);

interface Props {
	onSelect: (t: TrackModel) => void;
}

const SelectTrackBody: React.FC<Props> = ({ onSelect }) => {
	const { searchTrack, getData: getTrackData } = useTrack();
	const { filteredTracks, loading, shouldStopSearching } = getTrackData();

	const { getData: getUserData } = useUser();
	const { isMemberView } = getUserData();

	const gnrs = useMemo(() => [{ key: "all", value: "All" }, ...genres], []);

	const [filters, setFilters] = useState({
		keyword: null,
		sortBy: "placeholder",
		isMyTracks: false,
		order: -1,
		genre: "placeholder",
		type: "track",
		limit: 10,
		offset: 1
	});

	const { keyword, sortBy, genre, isMyTracks } = filters;

	useEffect(() => {
		searchTrack({ ...filters });
	}, [searchTrack, filters]);

	const handleSearchChange = useCallback(
		e => {
			const {
				target: { name: inputName, value }
			} = e;

			let v = value;

			if (inputName === "isMyTracks") {
				v = !filters.isMyTracks;
			}

			setFilters({ ...filters, offset: 1, [inputName]: v });
		},
		[filters]
	);

	const sortOptions = useMemo(
		() => [
			{ label: "Most popular", value: "totalPlays" },
			{ label: "Publish Date", value: "publishDate" }
		],
		[]
	);

	const filterOptions: FilterOptionType[] = useMemo(
		() => [
			{
				label: "Sort By",
				onClick: value =>
					handleSearchChange({
						target: { name: "sortBy", value: value }
					}),
				listOptions: sortOptions
			},
			{
				label: "All",
				multipleChoice: true,
				onClick: value => {
					handleSearchChange({
						target: { name: "genre", value: genres.find(g => g.value === value)?.key || value }
					});
				},
				listOptions: genres.map(g => ({ label: g.key, value: g.value }))
			},
			{
				inlineOptions: true,
				onClick: () =>
					handleSearchChange({
						target: { name: "isMyTracks", value: false }
					}),
				listOptions: [
					{
						label: "My Tracks",
						value: TracksFilters.MY_TRACKS
					}
				]
			}
		],
		[handleSearchChange, sortOptions]
	);

	const handleEndScroll = useCallback(
		() => !loading && !shouldStopSearching && setFilters(x => ({ ...x, offset: x.offset + 1 })),
		[loading, setFilters, shouldStopSearching]
	);

	return (
		<>
			<TextField
				value={keyword}
				onChange={handleSearchChange}
				name="keyword"
				placeholder="Search"
				variant="outlined"
				InputProps={{
					startAdornment: (
						<InputAdornment position="start">
							<SearchIcon />
						</InputAdornment>
					)
				}}
			/>
			{isMemberView ? (
				<FilterWrapper inline>
					<FilterBlock inline options={filterOptions} />
				</FilterWrapper>
			) : (
				<FilterWrapper>
					<CustomSelect name="sortBy" value={sortBy} onChange={handleSearchChange} variant="outlined">
						<MenuItem className="menuItem" value="placeholder" disabled>
							<Text>Sort by</Text>
						</MenuItem>
						{sortOptions.map((i, index) => (
							<MenuItem key={index} className="menuItem" value={i.value}>
								<Text>{i.label}</Text>
							</MenuItem>
						))}
					</CustomSelect>
					<CustomSelect name="genre" value={genre} onChange={handleSearchChange} variant="outlined" displayEmpty>
						{gnrs.map(i => (
							<MenuItem key={i.key} className="menuItem" value={i.key}>
								<Text>{i.value}</Text>
							</MenuItem>
						))}
					</CustomSelect>
					<CustomChip
						variant={isMyTracks ? "default" : "outlined"}
						label="My Tracks"
						onClick={() => handleSearchChange({ target: { name: "isMyTracks", value: isMyTracks } })}
					/>
				</FilterWrapper>
			)}
			<ContentWrapper isMemberView={isMemberView}>
				{filteredTracks?.length ? (
					<ScrollableBlock loading={loading} onEndScroll={handleEndScroll}>
						<TrackItemsWrapper>
							{filteredTracks.map(t => (
								<TrackItem key={t._id} {...t} onSelect={() => onSelect(t)} />
							))}
							{loading && <LoaderBlock />}
						</TrackItemsWrapper>
					</ScrollableBlock>
				) : (
					<>{loading ? <LoaderBlock /> : <EmptyBody>Tracks</EmptyBody>}</>
				)}
			</ContentWrapper>
		</>
	);
};

export default SelectTrackBody;
