import { useMemo } from "react";

import { AxiosResponse } from "axios";

import useHttpActions from "shared/services/api/core/useHttpActions";
import BaseConverter from "shared/services/converters/baseConverter";

import { API_URLS } from "../../constants";
import {
	ConnectionSearchModel,
	CreateGroupModel,
	InboxItem,
	InboxModel,
	RecentConnections,
	createMessageDataType,
	getCreateGroupDatatype,
	getGroupChatIdDatatype,
	getInboxDataType,
	getRecentConnectionDataType
} from "../types";

const useMessagingApiService = () => {
	const actions = useHttpActions();

	return useMemo(
		() => ({
			getInbox: async (data: getInboxDataType): Promise<AxiosResponse<InboxModel>> => {
				return data?.keyword
					? await actions.post(API_URLS.INBOX.SEARCH_INBOX, { ...data })
					: await actions.get(API_URLS.INBOX.GET_INBOX, { ...data });
			},
			createMessage: async (data: createMessageDataType): Promise<AxiosResponse<InboxModel>> => {
				return await actions.post(API_URLS.MESSAGE.CREATE, { ...data });
			},
			getRecentConnections: async (
				data: getRecentConnectionDataType
			): Promise<RecentConnections | ConnectionSearchModel> => {
				return BaseConverter.convert(
					await actions.get(data?.keyword?.length ? API_URLS.CONNECTION.SEARCH : API_URLS.CONVERSATION.GET, {
						...data
					})
				);
			},
			createGroupChat: async (initiatorId: number): Promise<AxiosResponse<CreateGroupModel>> => {
				return await actions.post(API_URLS.GROUP.CREATE, { initiatorId, allowMembersInvite: true });
			},
			getGroupChatId: async (members: number[]): Promise<getGroupChatIdDatatype> => {
				return BaseConverter.convert<getGroupChatIdDatatype>(
					await actions.post(API_URLS.GROUP.GET_GROUP_CHAT_ID, { members })
				);
			},
			addMembersGroupChat: async (
				groupChatId: string,
				members: getCreateGroupDatatype[]
			): Promise<AxiosResponse<CreateGroupModel>> => {
				return await actions.put(API_URLS.GROUP.ADD_MEMBERS, { groupChatId, data: members });
			},
			findGroupChat: async (chatId: string) => {
				return BaseConverter.convert<{ groupChat: InboxItem }>(
					await actions.get(API_URLS.GROUP.GET_GROUP_BY_CHAT_ID(chatId))
				);
			},
			getArchivedInbox: async (offset: number, limit: number) => {
				return BaseConverter.convert<InboxModel>(await actions.get(API_URLS.INBOX.ARCHIVED_INBOX, { offset, limit }));
			},
			pinChat: async (connectionId: string, pinned: boolean) => {
				return BaseConverter.convert(await actions.put(API_URLS.CONNECTION.PIN, { connectionId, pinned }));
			},
			readConversation: async (connectionId: string, unread: boolean) => {
				return BaseConverter.convert(await actions.put(API_URLS.CONNECTION.READ, { connectionId, unread }));
			},
			archiveChat: async (connectionId: string, archived: boolean) => {
				return BaseConverter.convert(await actions.put(API_URLS.CONNECTION.ARCHIVE, { connectionId, archived }));
			},
			createConnection: async (personaId: number): Promise<AxiosResponse<InboxModel>> => {
				return await actions.get(API_URLS.CONNECTION.CREATE, { personaId });
			},
			createAutoConnection: async (connecteeId: string, initiatorId: string): Promise<{ success: boolean }> => {
				return BaseConverter.convert<{ success: boolean }>(
					await actions.post(API_URLS.CONNECTION.AUTO_CONNECT, { connecteeId, initiatorId })
				);
			},
			deleteChat: async (id: string): Promise<{ success: boolean }> => {
				return BaseConverter.convert<{ success: boolean }>(
					await actions.delete(API_URLS.GROUP.DELETE, { connectionId: null, groupchatId: id })
				);
			},
			deleteChatByConnectionId: async (id: string): Promise<{ success: boolean }> => {
				return BaseConverter.convert<{ success: boolean }>(
					await actions.delete(API_URLS.GROUP.DELETE, { connectionId: id, groupchatId: null })
				);
			}
		}),
		[actions]
	);
};

export default useMessagingApiService;
