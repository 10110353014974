import React, { FC, useCallback } from "react";

import { DateTime } from "luxon";

import { useHistory } from "react-router";
import { ForumPostModel } from "types";

import { ReactComponent as IconCompass } from "assets/icons/compass-filled.svg";
import { ReactComponent as IconDiscussion } from "assets/icons/iconDiscussion.svg";

import { CreateUpdatePost } from "modules/Post/View";
import { InfoBlock } from "shared/Components";
import { useMemberRoutes, usePosts } from "shared/hooks";
import { Icon } from "shared/ui-kit";

import { ActivityCard, NoProfileDataTemp } from "..";

const MyActivities: FC<{
	posts: ForumPostModel[];
	title?: string;
	noData?: boolean;
	refresh: number;
	setRefresh: React.Dispatch<React.SetStateAction<number>>;
}> = ({ posts, title, noData, refresh, setRefresh }) => {
	const history = useHistory();
	const { setCreateUpdatePostDialog, getData: getPostsData } = usePosts();
	const { createUpdatePostDialog } = getPostsData();

	const { getMemberRoutesData } = useMemberRoutes();
	const { routes } = getMemberRoutesData();

	const handlePostCreated = useCallback(async () => {
		setCreateUpdatePostDialog({ isOpen: false });
		setRefresh(refresh + 1);
	}, [setCreateUpdatePostDialog, setRefresh, refresh]);

	return (
		<InfoBlock title={title || "My Activities"} className="p-4 mv-16">
			{noData ? (
				<>
					<NoProfileDataTemp
						icon={<IconDiscussion className="fill-second-path" width={80} height={80} fill="#c5cee0" />}
						subtitle="Once you have activities you will see them here"
						actions={[
							{
								onClick: () => history.push(routes?.member.categories.getPath()),
								icon: <IconCompass fill="#ffffff" width={16} height={16} />,
								title: "Explore Boards"
							},
							{
								onClick: () => {
									setCreateUpdatePostDialog({ isOpen: true });
								},
								icon: <Icon name="plus" fill="#ffffff" width={16} height={16} />,
								title: "Create a Post"
							}
						]}
					/>
					{createUpdatePostDialog.isOpen && <CreateUpdatePost onCreateUpdatePost={handlePostCreated} />}
				</>
			) : (
				posts
					.slice(0, 3)
					.map(post => (
						<ActivityCard
							photo={post.user.persona.photos?.length ? post.user.persona.photos[0]?.profilePicture : undefined}
							key={post.pid}
							title={`${post.user.persona.firstName} posted in ${post.category.name}`}
							subtitle={
								(post.totalReplies && post.totalReplies > 0) || (post.totalLikes && post.totalLikes > 0)
									? `${post.totalReplies} comments • ${post.totalLikes} reactions`
									: undefined
							}
							date={DateTime.fromISO(post.timestampISO).toRelative() || ""}
						/>
					))
			)}
		</InfoBlock>
	);
};

export default MyActivities;
