import React from "react";

import { Link } from "react-router-dom";

import { useUser } from "shared/hooks";

import { Icon } from "shared/ui-kit";

import { StyledBtn } from "./styles";

interface SwitchRoleViewBtnProps {
	label: string;
	link: string;
	buttonId?: string;
}

const SwitchRoleViewBtn: React.FC<SwitchRoleViewBtnProps> = ({ label, link, buttonId }) => {
	const { getData: getUserData } = useUser();
	const { isMemberView } = getUserData();

	return (
		<StyledBtn
			removeSideMargin
			fullWidth
			isMemberView={isMemberView}
			size="medium"
			palette={"basic"}
			buttonTheme="light"
			leftIcon={<Icon group={"filled"} fill={"#222b45"} name={"external-link"} />}
			to={link}
			component={Link}
			id={buttonId}
		>
			{label}
		</StyledBtn>
	);
};

export default SwitchRoleViewBtn;
