import React from "react";

import { useHistory } from "react-router-dom";

import { ReactComponent as BrokenLinkIcon } from "assets/icons/broken-link.svg";
import { Text } from "shared/ui-kit";

import { ButtonWrapper, DescriptionWrapper, IconWrapper, StyledButton, TitleWrapper } from "./style";

import { AuthPagesContainer } from "../SignIn/styles";
import { ContentWrapperBlock } from "../style";

export interface BrokenLinkProps {
	backToCommunityLink: string;
}

const BrokenLink: React.FC<BrokenLinkProps> = ({ backToCommunityLink }) => {
	const history = useHistory();

	const backToCommunity = () => {
		history.push(backToCommunityLink);
	};

	return (
		<ContentWrapperBlock>
			<AuthPagesContainer className="broken-link">
				<IconWrapper>
					<BrokenLinkIcon />
				</IconWrapper>
				<TitleWrapper>
					<Text variant="h4">Invite Invalid</Text>
				</TitleWrapper>
				<DescriptionWrapper>
					<Text variant="body1">
						The invitation has expired or not valid, please contact the admin for a new invitation.
					</Text>
				</DescriptionWrapper>
				<ButtonWrapper>
					<StyledButton removeSideMargin fullWidth size="medium" type="button" onClick={backToCommunity}>
						Back to Community
					</StyledButton>
				</ButtonWrapper>
			</AuthPagesContainer>
		</ContentWrapperBlock>
	);
};

export default BrokenLink;
