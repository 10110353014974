import React, { FC, useState } from "react";

import { Box, ButtonBase, ClickAwayListener, IconButton } from "@material-ui/core";
import { DragIndicator } from "@material-ui/icons";

import { FileType } from "types/FilesContextValuesType";

import { ReactComponent as IconFile } from "assets/icons/icon-file.svg";
import { MoreActionsMenu, TrackInfoWrapper } from "modules/Manage/View/Components/AlbumDetailsDialog/style";
import { Icon, Text } from "shared/ui-kit";

const FileRow: FC<{
	data: FileType;
	order: number;
	handleRemove: (id: string) => void;
	lastItemRef: React.RefObject<HTMLDivElement> | null;
}> = ({ data, order, lastItemRef, handleRemove }) => {
	const [displayActions, setDisplayActions] = useState(false);

	const handleClickAway = () => {
		if (displayActions) {
			setDisplayActions(false);
		}
	};

	const urlArray = data.file.url.split(".");
	const fileType = urlArray[urlArray.length - 1].toLowerCase();
	const isPDF = fileType === "pdf";

	const handleOpenPDF = () => {
		window.open(data.file.url, "_blank")?.focus();
	};

	return (
		<TrackInfoWrapper ref={lastItemRef}>
			<Box className="track-name">
				<Box className="drag-icon-wrapper">
					<DragIndicator htmlColor="#c5cee0" />
				</Box>
				<Text variant="body2">{order}.</Text>

				{data.coverPhoto.url ? (
					<Box position="relative">
						<img src={data.coverPhoto.url} alt={data.title} />
						<Box className="media-type">
							<IconFile />
						</Box>
					</Box>
				) : (
					<Box className="track-no-img">
						<IconFile />
					</Box>
				)}
				<Box overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
					<Text variant="subtitle1">{data.title}</Text>
					<Text variant="body2">{data.category?.name || "-"}</Text>
				</Box>
			</Box>
			<Box className="track-duration-actions">
				<Text variant="body2"></Text>
				<Box display="flex" alignItems="center">
					{isPDF && (
						<IconButton size="small" onClick={handleOpenPDF}>
							<Icon name="eye" group="filled" fill="#C5CEE0" />
						</IconButton>
					)}
					<ClickAwayListener onClickAway={handleClickAway}>
						<Box position="relative">
							<IconButton onClick={() => setDisplayActions(!displayActions)}>
								<Icon name="ellipsis" group="filled" fill="#c5cee0" />
							</IconButton>
							<MoreActionsMenu lastItem={false} className={displayActions ? "show" : ""}>
								<ButtonBase className="action" onClick={() => handleRemove(data._id)}>
									Remove from Collection
								</ButtonBase>
							</MoreActionsMenu>
						</Box>
					</ClickAwayListener>
				</Box>
			</Box>
		</TrackInfoWrapper>
	);
};

export default FileRow;
