/* eslint-disable */
import React from "react";

export default Layout => Component =>
	class extends React.Component {
		render() {
			return (
				<Layout>
					<Component {...this.props} />
				</Layout>
			);
		}
	};
