import React, { useEffect, useRef, useState } from "react";

import { Box } from "@material-ui/core";
import { Controller, useForm } from "react-hook-form";

import { GuideNote } from "modules/Team/View/Containers/Team/style";
import useConfirmLeavePopup from "shared/Components/ConfirmLeave/hooks/useConfirmLeavePopup";
import { useGTM, useMembers } from "shared/hooks";
import useNotification from "shared/hooks/useNotification";
import { ValidateEmailMember } from "shared/types";
import { Icon, Uploader } from "shared/ui-kit";
import getCountMessage from "utils/getCountMessage";
import { actionMethod, actionTypes, getValidationMessage } from "utils/getValidationMessage";

import { EmailsAutoComplete } from "../EmailsAutocomplete";
import {
	ControllerWrapper,
	FooterWrapper,
	InviteMemberActionText,
	StyledButton,
	StyledForm,
	StyledInvalidEmailAddressText
} from "../style";

const ViaEmail = () => {
	const [keyword] = useState<string>();
	const { control } = useForm();
	const { addEvent } = useGTM();

	const {
		setModalState,
		inviteMember,
		setEmailAddresses,
		getMemberTotalCount,
		validatedInvitedMember,
		getData: getMembersData
	} = useMembers();
	const { emailAddresses, loadingMembers } = getMembersData();

	const { showMessage } = useNotification();

	const fileReader: FileReader = useRef(new FileReader()).current;

	const handleFileRead = async () => {
		const content: string = fileReader.result as string;

		const emails: string[] = content?.split("\n") || [];

		const tempEmailsHolder: string[] = [];

		emails
			.map(x => {
				if (x.endsWith("\r")) {
					return x.replace("\r", "");
				}
				return x;
			})
			.filter((email, i, a) => email && a.indexOf(email) === i)
			.forEach((email: string) => {
				const sanitizedEmail = email.trim();

				tempEmailsHolder.push(sanitizedEmail);
			});

		validatedInvitedMember(tempEmailsHolder).then(res => {
			const updatedEmailAddresses = [...emailAddresses, ...(res as { email?: string; valid?: boolean }[])].filter(
				(item, i, a) => a.findIndex(x => x.email === item.email) === i
			);
			setEmailAddresses({
				emailAddresses: updatedEmailAddresses
			} as ValidateEmailMember);
		});
	};

	const handleFileChosen = file => {
		if (file) {
			fileReader.onloadend = handleFileRead;
			fileReader.readAsText(file);
		}
	};

	const checkIfEmailAddressesAreEmpty = async (emailAddresses, message) => {
		if (!emailAddresses.length) {
			showMessage(message, 5000);
			return;
		}

		setModalState({ isOpen: false });
		const emails = emailAddresses.map(email => email.email);

		await inviteMember({
			emails,
			slug: null,
			role: null
		});

		setEmailAddresses({ emailAddresses: [] });

		getMemberTotalCount({
			type: "members",
			keyword
		});

		const invitedUsersLength = getCountMessage(emails.length, "member");

		showMessage(
			getValidationMessage({
				name: invitedUsersLength,
				actionType: actionTypes.CRUDUser,
				actionMethod: actionMethod.invited,
				emoji: "✉️"
			}),
			3
		);

		addEvent({
			Category: "Engagement",
			Action: "Community",
			Label: "Community | Add Member"
		});
	};

	const validEmailsBoolArray = emailAddresses?.map(email => email.valid);
	const [emailErrors, setEmailError] = useState<string[]>([]);

	const { provideHandleCloseValues } = useConfirmLeavePopup({});

	useEffect(() => {
		setEmailAddresses({ emailAddresses: [] });

		return () => {
			setEmailAddresses({ emailAddresses: [] });
		};
	}, [setEmailAddresses]);

	useEffect(() => {
		provideHandleCloseValues(emailAddresses.length > 0);
	}, [emailAddresses, provideHandleCloseValues]);

	useEffect(() => {
		const errors: string[] = [];
		emailAddresses
			.filter(email => !email.valid)
			.forEach(email => {
				if (email.message && !errors.includes(email.message)) {
					errors.push(email.message);
				}
			});
		setEmailError(errors);
	}, [emailAddresses]);

	return (
		<StyledForm onSubmit={e => e.preventDefault()}>
			<ControllerWrapper>
				<Box className="invite-field" flex={1}>
					<InviteMemberActionText>Enter email</InviteMemberActionText>
					<Controller
						name="invitedMembersEmails"
						control={control}
						value={emailAddresses?.map(email => email.email)}
						as={<EmailsAutoComplete />}
					/>
					{emailErrors.map(emailError => (
						<StyledInvalidEmailAddressText key={emailError}>{emailError}</StyledInvalidEmailAddressText>
					))}
				</Box>
				<Box className="invite-field flex flex-col" maxWidth={265}>
					<InviteMemberActionText>Bulk Upload</InviteMemberActionText>
					<Uploader
						width="246"
						wrapperClassName="h-full"
						label="Upload Email list"
						dropFileType={"bin"}
						showPreview={false}
						icon={<Icon group={"filled"} name={"file-csv"} width={54} height={54} viewBox={"0 0 25 24"} />}
						accept={[
							{
								fileType: ".csv",
								name: "Csv format supported"
							}
						]}
						onChange={file => handleFileChosen(file[0])}
					/>
				</Box>
			</ControllerWrapper>
			<GuideNote className="ml-10 mt-1">Use commas and semicolons or press Enter to separate emails</GuideNote>
			<FooterWrapper>
				<StyledButton
					buttonTheme="main"
					type="submit"
					palette="primary"
					size="large"
					disabled={!validEmailsBoolArray?.every(Boolean) || emailAddresses.length === 0 || loadingMembers}
					onClick={() => {
						checkIfEmailAddressesAreEmpty(emailAddresses, "Please add at least one email");
					}}
				>
					Add Member
				</StyledButton>
			</FooterWrapper>
		</StyledForm>
	);
};

export default ViaEmail;
