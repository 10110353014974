import React, { ReactNode, useCallback, useEffect, useRef, useState } from "react";

import { useMediaQuery } from "@material-ui/core";

import { PostBlockEventType } from "types";

import { ReactComponent as GifIcon } from "assets/icons/iconGif.svg";
import { ReactComponent as ImageIcon } from "assets/icons/iconImage.svg";
import { PostPortal } from "modules/Post/View/Components";
import { getId } from "modules/Post/View/Post/CreateUpdatePost";
import { HiddenFileSelector } from "shared/Components";
import ConfirmLeavePopup from "shared/Components/ConfirmLeave";
import useConfirmLeavePage from "shared/Components/ConfirmLeave/hooks/useConfirmLeavePage";
import Dialogs from "shared/Components/Dialogs";

import { useMemberRoutes } from "shared/hooks";
import { ToolbarActions } from "shared/types";
import { Tooltip } from "shared/ui-kit";
import * as appTheme from "theme/default";

import { Editor } from "./style";

import { CommentWidget } from "../index";

export interface CommentEditorProps {
	editableContent?: string;
	removeSideMargin?: boolean;
	onCancel?: () => void;
	onSubmit: (content: string) => void;
	replyTo?: { toPid: string; toAuthorId: number; toAuthorName: string };
}

const CommentEditor: React.FC<CommentEditorProps> = ({
	editableContent,
	onSubmit,
	removeSideMargin,
	onCancel,
	replyTo
}) => {
	const newCommentAreaRef = useRef<HTMLDivElement>(null);

	const isMobile = useMediaQuery(appTheme.default.breakpoints.down("sm"));

	const { getMemberRoutesData } = useMemberRoutes();
	const { routes } = getMemberRoutesData();

	const [localMessageText, setLocalMessageText] = useState("");

	const [largeComment, setLargeComment] = useState(false);
	const [action, setAction] = useState<ToolbarActions | null>(null);

	const [isUploading, setIsUploading] = useState(false);

	const [portalItem, setPortalItem] = useState<{ id: string; html: ReactNode }>();

	const resizeNewComment = useCallback(
		(val: string, calculateManually = false) => {
			if (calculateManually) {
				const newDiv = document.createElement("div");
				newDiv.innerHTML = newCommentAreaRef.current!.innerHTML;
				const styles = window.getComputedStyle(newCommentAreaRef.current!);
				let cssText = styles.cssText;
				if (!cssText) {
					cssText = Array.from(styles).reduce((str, property) => {
						return `${str}${property}:${styles.getPropertyValue(property)};`;
					}, "");
				}
				newDiv.style.cssText = cssText;
				newDiv.style.position = "absolute";
				newDiv.style.opacity = "0";
				newDiv.style.width = `${newCommentAreaRef.current!.clientWidth}px`;
				newDiv.style.height = "fit-content";
				document.body.appendChild(newDiv);
				let height = newDiv.scrollHeight || newCommentAreaRef.current!.scrollHeight;
				document.body.removeChild(newDiv);

				if (height < 40) {
					height = 40;
				}

				newCommentAreaRef.current!.style.height = `${val?.length ? height : 40}px`;
			} else {
				let height = newCommentAreaRef.current!.scrollHeight;

				if (height < 40) {
					height = 40;
				}

				newCommentAreaRef.current!.style.height = `${val?.length ? height : 40}px`;
			}

			setLargeComment(val.length >= 20 || (isMobile && !!portalItem));
		},
		[isMobile, portalItem]
	);

	const onCommentEvent = useCallback(({ eventType, id }: { eventType: PostBlockEventType; id: string }) => {
		if (eventType === PostBlockEventType.uploadingProgressStart) {
			setIsUploading(true);
		}

		if (eventType === PostBlockEventType.uploadingProgressFinished) {
			setIsUploading(false);
		}

		if (eventType === PostBlockEventType.delete) {
			setPortalItem(undefined);

			setIsUploading(false);

			const addedEl = document.getElementById(id);
			newCommentAreaRef.current!.removeChild(addedEl!);

			setTimeout(() => {
				newCommentAreaRef.current && resizeNewComment(newCommentAreaRef.current.innerHTML, true);
			}, 50);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onAddContentToComment = useCallback(
		({ content, action, editMode = false }: { content: any; action: ToolbarActions; editMode?: boolean }) => {
			const id = `post-comment-embedded-${getId(content)}`;

			const attachDiv = document.createElement("div");
			attachDiv.setAttribute("id", id);
			attachDiv.setAttribute("class", "attachment-block");
			const contentChildren = newCommentAreaRef.current!.children;
			if (!contentChildren?.length || contentChildren[contentChildren.length - 1]?.tagName !== "BR") {
				const newBr = document.createElement("br");
				newCommentAreaRef.current!.appendChild(newBr);
			}
			newCommentAreaRef.current!.appendChild(attachDiv);

			const html = (
				<CommentWidget
					content={content}
					action={action}
					onEvent={(eventType: PostBlockEventType) => onCommentEvent({ id, eventType })}
					editMode={editMode}
				/>
			);
			setPortalItem({ id, html });

			setAction(null);

			setTimeout(() => {
				resizeNewComment(newCommentAreaRef.current!.innerHTML);
			}, 500);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[onCommentEvent]
	);

	const placeCaretAtEnd = useCallback((el: HTMLDivElement) => {
		el.focus();

		if (typeof window.getSelection != "undefined" && typeof document.createRange != "undefined") {
			const range = document.createRange();
			range.selectNodeContents(el);
			range.collapse(false);
			const sel = window.getSelection();
			if (sel) {
				sel.removeAllRanges();
				sel.addRange(range);
			}
		}
	}, []);

	useEffect(() => {
		if (editableContent) {
			const content = new DOMParser().parseFromString(editableContent, "text/html");

			const imgs = content.getElementsByTagName("img");
			const videos = content.getElementsByTagName("video");

			if (imgs?.length || videos?.length) {
				const item = imgs?.length ? imgs[0] : videos[0];
				item.parentNode!.removeChild(item);

				const updatedDOM = content.documentElement.getElementsByTagName("body")[0];

				newCommentAreaRef.current!.innerHTML = updatedDOM.innerHTML;

				onAddContentToComment({ content: { url: item.src }, action: ToolbarActions.ADD_IMAGE, editMode: true });
			} else {
				newCommentAreaRef.current!.innerHTML = editableContent;
			}
		} else {
			if (replyTo?.toAuthorId && replyTo?.toAuthorName) {
				const memberLink = `${routes?.member.profile.getPath()}/${replyTo.toAuthorId}`;
				newCommentAreaRef.current!.innerHTML = `<a class="author" href=${memberLink} contentEditable="false" persona-id=${replyTo.toAuthorId} target="_blank">${replyTo?.toAuthorName}</a>&nbsp;`;
				newCommentAreaRef.current && placeCaretAtEnd(newCommentAreaRef.current);
			} else {
				newCommentAreaRef.current!.innerHTML = "";
			}
		}

		setTimeout(() => {
			newCommentAreaRef.current?.innerText && resizeNewComment(newCommentAreaRef.current!.innerText);
		}, 250);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [editableContent, onAddContentToComment, replyTo, placeCaretAtEnd]);

	const postNewComment = async (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
		e.preventDefault();

		const innerText = newCommentAreaRef.current!.innerText;
		if (innerText?.length) {
			let isEmpty = false;
			if (replyTo?.toAuthorName && innerText.includes(replyTo.toAuthorName)) {
				isEmpty = !innerText.replace(replyTo.toAuthorName, "").trim()?.length;
			}

			if (!isEmpty && !isUploading) {
				onSubmit(newCommentAreaRef.current!.innerHTML);
				handleChangeNewComment("");
				setPortalItem(undefined);

				setTimeout(() => {
					newCommentAreaRef.current && resizeNewComment(newCommentAreaRef.current.innerHTML);
				}, 50);
			}
		}
	};

	const handleChangeNewComment = (e: React.KeyboardEvent<HTMLTextAreaElement> | string) => {
		if (typeof e !== "string" && e?.key === "Enter" && !e?.shiftKey) {
			e.preventDefault();
			postNewComment(e);
		} else if (typeof e !== "string" && e?.key === "Escape" && onCancel) {
			onCancel();
		} else {
			const val = typeof e === "string" ? e : newCommentAreaRef.current!.innerText || "";
			if (typeof e === "string") {
				newCommentAreaRef.current!.innerHTML = e;
			}
			resizeNewComment(val, (e as React.KeyboardEvent<HTMLTextAreaElement>)?.key === "Backspace" && !portalItem);
			setLocalMessageText(newCommentAreaRef.current!.innerText || "");
		}
	};

	const handleAddAttachment = (action: ToolbarActions) => {
		if (!portalItem) {
			setAction(action);
		}
	};

	const {
		handleLeavePageConfirmed,
		closeConfirmPopup,
		getData: getConfirmLeavePopupData
	} = useConfirmLeavePage({
		valuesToCheck: !!localMessageText?.length || !!portalItem
	});
	const { showConfirmPopup } = getConfirmLeavePopupData();

	return (
		<>
			<Editor.InputWrapper className={largeComment && "large"}>
				<Editor.Input
					className={removeSideMargin && "no-side-padding"}
					contentEditable
					ref={newCommentAreaRef}
					onKeyDown={handleChangeNewComment}
				/>
				<Editor.ExtraActions>
					<Editor.ExtraItem
						className="fill-first-path"
						allow={!!portalItem ? "true" : "false"}
						onClick={() => handleAddAttachment(ToolbarActions.ADD_IMAGE)}
					>
						<Tooltip text="Add Photo">
							<ImageIcon />
						</Tooltip>
						<HiddenFileSelector
							onSelect={files =>
								onAddContentToComment({ content: { fileList: files }, action: ToolbarActions.ADD_IMAGE })
							}
						/>
					</Editor.ExtraItem>
					<Editor.ExtraItem
						className="fill-second-path"
						allow={!!portalItem ? "true" : "false"}
						onClick={() => handleAddAttachment(ToolbarActions.ADD_GIF)}
					>
						<Tooltip text="Add Gif">
							<GifIcon />
						</Tooltip>
					</Editor.ExtraItem>
				</Editor.ExtraActions>
			</Editor.InputWrapper>
			<Dialogs
				action={action}
				setAction={setAction}
				onSetContent={content => onAddContentToComment({ content, action: action as ToolbarActions })}
			/>
			{(!!localMessageText?.length || !!portalItem) && (
				<ConfirmLeavePopup
					handleLeavePage={handleLeavePageConfirmed}
					open={showConfirmPopup}
					onClose={closeConfirmPopup}
				/>
			)}
			{portalItem && (
				<PostPortal key={portalItem.id} id={portalItem.id}>
					{portalItem.html}
				</PostPortal>
			)}
		</>
	);
};

export default CommentEditor;
