import React, { FC, useCallback, useMemo, useRef, useState } from "react";

import styled from "styled-components";

import { SetupPrice } from "shared/Components";

import { useAlbums, useCommunity, useMedia, useUser } from "shared/hooks";
import { GetAlbumResponse, MediaType } from "shared/types";

import { getScheduleDate } from "utils/getScheduleDate";
import { mergeDateAndTime } from "utils/serviceUtils/helpers";

import CreateAlbumManageView from "./ManageView";
import CreateAlbumMemberView from "./MemberView";

export const SubText = styled.span`
	color: #8f9bb3;
	font-weight: 600;
`;

const CreateUpdateAlbumDialog: FC<{ open: boolean }> = ({ open }) => {
	const {
		setAlbumCreateOpen,
		createAlbum,
		updateAlbum,
		setEditAlbumDetails,
		getData: getAlbumsData,
		setAlbumDetailsDialog
	} = useAlbums();
	const { editAlbumDetails, albumDetailsDialog } = getAlbumsData();

	const { getData: getUserData } = useUser();
	const { user, isMemberView } = getUserData();

	const { uploadFiles } = useMedia();

	const { getData: getCommunityData } = useCommunity();
	const { isFree } = getCommunityData();

	const [uploadingCover, setUploadingCover] = useState(false);

	type SetupPriceMethodsHandlers = React.ElementRef<typeof SetupPrice>;
	const setupPriceFormRef = useRef<SetupPriceMethodsHandlers>(null);

	const onClose = () => {
		setAlbumCreateOpen(false);
		setEditAlbumDetails(null);
	};

	const editableModel = useMemo(() => {
		const modelInfo = editAlbumDetails ? { ...editAlbumDetails } : null;
		if (modelInfo?.scheduleDate && new Date(modelInfo.scheduleDate) < new Date()) {
			modelInfo.scheduleDate = "";
		}
		return modelInfo;
	}, [editAlbumDetails]);

	const onUploaderChange = useCallback(
		async (files: FileList, onChange: (...event: any[]) => void) => {
			setUploadingCover(true);

			const data = await uploadFiles({ files, type: MediaType.albumPhoto });

			if (data[0]) {
				onChange({
					mediaId: data[0]._id,
					url: data[0].uri
				});
			} else {
				onChange(null);
			}

			setUploadingCover(false);
		},
		[uploadFiles]
	);

	const onSubmit = async data => {
		delete data.priceChanged;

		const priceTags = setupPriceFormRef.current?.getPriceTags();

		if (editableModel) {
			const scheduleDate = isMemberView
				? getScheduleDate(data.time?.value, data.date) || undefined
				: mergeDateAndTime(data.albumScheduleDate, data.albumScheduleTime);

			const body = {
				title: data.albumTitle,
				artist: data.artist,
				description: data.description,
				private: data.private,
				meta: data.meta,
				canBeDownloaded: data.downloadable,
				individualAccess: data.individualAccess,
				scheduleDate,
				releaseDate: data.releaseDate ? data.releaseDate : new Date(),
				genre: data.genre.map(g => (typeof g === "string" ? g : g.key)),
				priceTags
			};

			const album = await updateAlbum(editableModel._id, body);
			setAlbumDetailsDialog({
				...albumDetailsDialog,
				data: { ...albumDetailsDialog.data, ...album } as GetAlbumResponse
			});
		} else {
			await createAlbum(
				{
					...data,
					privateAlbum: data.private,
					canBeDownloaded: data.downloadable,
					individualAccess: data.individualAccess,
					priceTags
				},
				user && !!user?.profiles.length
					? {
							firstName: user.profiles[0].firstName,
							lastName: user.profiles[0].lastName,
							_id: user.profiles[0]._id || ""
					  }
					: undefined,
				isMemberView
			);
		}
	};

	if (isMemberView) {
		return (
			<CreateAlbumMemberView
				onUploaderChange={onUploaderChange}
				open={open}
				onClose={onClose}
				uploadingCover={uploadingCover}
				editAlbumDetails={editableModel}
				handleCreateAlbum={onSubmit}
				setupPriceFormRef={setupPriceFormRef}
				isFreeCommunity={isFree}
			/>
		);
	}

	return (
		<CreateAlbumManageView
			onUploaderChange={onUploaderChange}
			open={open}
			onClose={onClose}
			uploadingCover={uploadingCover}
			editAlbumDetails={editableModel}
			handleCreateAlbum={onSubmit}
			setupPriceFormRef={setupPriceFormRef}
			isFreeCommunity={isFree}
		/>
	);
};

export default CreateUpdateAlbumDialog;
