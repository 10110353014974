import React from "react";

import { INVITE } from "modules/Members/constants";

import { useMembers } from "shared/hooks";

import { InviteMemberText, InviteMemberWrapper, InviteMembersModalIcon } from "../style";

const DefaultActions = () => {
	const { setModalState } = useMembers();
	const sharaebleLinkModalContent = INVITE.SHAREABLE_LINK_LIST;
	const shareableLinkModalTitle = INVITE.SHAREABLE_LINK_LIST_TITLE;

	const qrModalContent = INVITE.QR_CODE_LIST;
	const qrModalTitle = INVITE.QR_CODE_LIST_TITLE;

	return (
		<>
			<InviteMemberWrapper
				onClick={() => setModalState({ modalContent: "inviteViaEmail", modalTitle: "Invite Via Email" })}
			>
				<InviteMemberText align="center" display="initial" variant="body1">
					Invite
					<br />
					Via Email
				</InviteMemberText>
				<InviteMembersModalIcon fill="#8f9bb3" group="filled" width={18} height={15} name="envelope" />
			</InviteMemberWrapper>

			<InviteMemberWrapper
				onClick={() =>
					setModalState({
						modalContent: sharaebleLinkModalContent,
						modalTitle: shareableLinkModalTitle,
						viewType: INVITE.SHAREABLE_LINK
					})
				}
			>
				<InviteMemberText align="center" display="initial" variant="body1">
					Shareable
					<br />
					Link
				</InviteMemberText>
				<InviteMembersModalIcon fill="#8f9bb3" group="filled" width={18} height={15} name="link" />
			</InviteMemberWrapper>

			<InviteMemberWrapper
				onClick={() =>
					setModalState({ modalContent: qrModalContent, modalTitle: qrModalTitle, viewType: INVITE.QR_CODE })
				}
			>
				<InviteMemberText align="center" display="initial" variant="body1">
					QR code
				</InviteMemberText>
				<InviteMembersModalIcon fill="#8f9bb3" group="filled" width={18} height={15} name="barcode" />
			</InviteMemberWrapper>
		</>
	);
};

export default DefaultActions;
