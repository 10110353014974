import { useMemo } from "react";

import { useUser } from "shared/hooks";

import { usePollApiService } from "../services";

const usePoll = (isMarketing = false) => {
	const service = usePollApiService(isMarketing);

	const { getData: getUserData } = useUser();
	const { user } = getUserData();
	const personaId = user?.profiles[0]?.personaId || "";

	return useMemo(
		() => ({
			createPool: async pool => {
				return await service.create({ ...pool, personaId });
			},
			getPoll: async (id: string) => {
				try {
					const pollInfo = await service.getById(id);
					if (pollInfo?.length) {
						return pollInfo[0];
					}
				} catch (e) {
					console.log(`Error: ${(e as Error)?.message}`);
					return null;
				}
			},
			answerPoll: async ({
				answerIds,
				pollId,
				type
			}: {
				answerIds: number[];
				pollId: string;
				type?: "submit" | "unsubmit";
			}) => {
				return await service.answer({ answerIds, pollId, type });
			}
		}),
		[personaId, service]
	);
};

export default usePoll;
