import { useCallback, useMemo } from "react";

import { actionMethod, actionTypes, getValidationMessage } from "utils/getValidationMessage";

import { trackModalType } from "./../contexts/TrackContext/TrackContext";

import { useTrackStore } from "../contexts";

import { useTrackApiService } from "../services";
import { TrackFormModel, TrackModel, TrackSearchParams, UpdateTrackFormModel } from "../types";
import { useNotification, useUser } from "./index";

const useTrack = (isMarketing = false) => {
	const service = useTrackApiService(isMarketing);

	const store = useTrackStore();
	const { setState } = useTrackStore();

	const { getData: getUserData } = useUser();
	const { user } = getUserData();

	const { showMessage } = useNotification();

	const personaId = user?.profiles[0]?.personaId || "";

	const methods = useMemo(
		() => ({
			searchTrack: async ({ keyword, sortBy, order, offset, genre, isMyTracks, type }: TrackSearchParams) => {
				const query = {
					offset: offset || 1,
					limit: 20,
					order
				};

				const filteredGenres =
					typeof genre === "string"
						? genre === "placeholder" || genre === "all"
							? undefined
							: [genre]
						: genre.filter(g => g !== "placeholder" && g !== "all");

				if (filteredGenres && !!filteredGenres.length) {
					query["genre"] = filteredGenres;
				}

				if (keyword && keyword.length >= 3) {
					query["keyword"] = keyword;
				}

				if (isMyTracks) {
					query["pid"] = personaId;
				}

				if (type) {
					query["type"] = type;
				}

				setState({ loading: true });

				const filteredTracks = await service.getAllTracks({
					...query,
					sortBy: sortBy === "placeholder" ? "createdAt" : sortBy
				});

				setState(ctx => ({
					filteredTracks: offset === 1 ? filteredTracks : [...ctx.filteredTracks, ...filteredTracks],
					shouldStopSearching: filteredTracks.length < query["limit"],
					loading: false
				}));
			},
			loadTracks: async (data?: any) => {
				const tracks = await service.getAllTracks(data);
				setState({ tracks, filteredTracks: tracks });
			},
			async getTrack({ trackId, groupId }: { trackId: string; groupId?: string }) {
				setState({ loading: true });
				try {
					const data = await service.getTrack({ trackId, groupId });
					setState({ loading: false });
					if (data?.length) {
						return data[0];
					}
				} catch (e) {
					return null;
				}
				return null;
			},
			async getTrackInContext({ trackId, groupId }: { trackId: string; groupId?: string }) {
				const data = await service.getTrack({ trackId, groupId });

				if (data?.length) {
					setState(ctx => ({
						...ctx,
						filteredTracks: ctx.filteredTracks?.some(x => x._id === trackId)
							? ctx.filteredTracks.map(x => (x._id === trackId ? data[0] : x))
							: ctx.filteredTracks
					}));

					return data[0];
				}
				return null;
			},
			async getTracks({
				// count = 0,
				limit = 10,
				keyword = "",
				offset,
				type = "track",
				sortBy = "createdAt",
				groupId = "",
				eventId = "",
				personaId = 0,
				paginate = false,
				noStateUpdate = false
			}) {
				setState({ loading: true });

				const tracks = await service.getTracks({ offset, limit, keyword, type, sortBy, groupId, eventId, personaId });

				if (!noStateUpdate) {
					setState(ctx => ({
						offset,
						filteredTracks: !paginate || offset === 1 ? tracks : (ctx.filteredTracks || []).concat(tracks),
						loading: false
					}));
				}

				return tracks;
			},
			async getTrackCount({ keyword = "", groupId }: { keyword?: string; groupId?: string }) {
				try {
					const { totalCount } = await service.getTrackCount({ keyword, groupId });
					setState({ trackCount: totalCount });
				} catch (error) {
					showMessage("Failed to get tracks count");
				}
			},
			async getAllTrackCount(groupId?: string) {
				setState({ loading: true });
				const { totalCount } = await service.getTrackCount({ keyword: "", groupId });
				setState({ allTrackCount: totalCount, loading: false });
			},
			createTrack: async (data: TrackFormModel) => {
				data.personaId = personaId;

				setState({ creating: true });
				const trackInfo = await service.createTrack(data);
				showMessage(
					getValidationMessage({
						name: "Track",
						actionType: actionTypes.CRUD,
						actionMethod: actionMethod.created,
						emoji: "🎉"
					}),
					3
				);

				setState({ creating: false });

				return trackInfo;
			},
			updateTrack: async (data: UpdateTrackFormModel) => {
				data.personaId = personaId;

				setState({ creating: true });

				const trackInfo = await service.updateTrack(data);

				showMessage(
					getValidationMessage({
						name: "Track",
						actionType: actionTypes.CRUD,
						actionMethod: actionMethod.updated,
						emoji: "✨"
					}),
					3
				);

				setState({ creating: false });

				return trackInfo;
			},
			async deleteTrack(id: string) {
				const data = await service.deleteTrack(id);
				if (data) {
					setState(ctx => ({
						filteredTracks: [...(ctx.filteredTracks as TrackModel[]).filter(track => track._id !== id)],
						trackCount: (ctx.trackCount || 1) - 1,
						allTrackCount: (ctx.allTrackCount || 1) - 1
					}));
					showMessage(
						getValidationMessage({
							name: "Track",
							actionType: actionTypes.CRUD,
							actionMethod: actionMethod.deleted,
							emoji: "🗑"
						}),
						3
					);
				}
			},
			setTrackFileUrl: trackFileUrl => setState({ trackFileUrl }),
			setPage: (page: number) => {
				setState({ page });
			},
			setTracksShowPerPage: (tracksShowPerPage: number) => {
				setState({ tracksShowPerPage });
			},
			setTrackInfoPopup: (trackInfoPopup: trackModalType) => {
				setState({ trackInfoPopup });
			},
			setRefresh: (refresh: number) => {
				setState({ refresh });
			},
			setTrackName: trackName => setState({ trackName }),
			setTrackDuration: (trackDuration?: string) => {
				setState({ trackDuration });
			},
			setFilteredTracks: (filteredTracks: TrackModel[] | null) => setState({ filteredTracks })
		}),
		[personaId, service, setState, showMessage]
	);

	const getData = useCallback(() => {
		return store;
	}, [store]);

	return { ...methods, getData };
};

export default useTrack;
