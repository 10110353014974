import React, { FC } from "react";

import { InPlaceModal } from "shared/ui-kit";

import { IntegrationDialogContainer, StyledPopupOverlay } from "../Containers/Integrations/styles";

const IntegrationDialogWrapper: FC<{ onClose: () => void; open: boolean; zIndex?: number }> = ({
	children,
	onClose,
	zIndex,
	open
}) => (
	<StyledPopupOverlay open={open} zIndex={zIndex}>
		<IntegrationDialogContainer>
			<InPlaceModal fullWidth={false} maxWidth="sm" onClose={onClose} open={open} modalHeight={600}>
				{children}
			</InPlaceModal>
		</IntegrationDialogContainer>
	</StyledPopupOverlay>
);

export default IntegrationDialogWrapper;
