import { useCallback, useEffect, useMemo, useState } from "react";

import { useHistory } from "react-router";

import { useConfirmPopupStore } from "shared/contexts";

interface Props {
	valuesToCheck?: boolean;
	ignoreChanges?: boolean;
}

const useConfirmLeavePage = ({ valuesToCheck, ignoreChanges }: Props) => {
	const [confirmedLeaving, setConfirmedLeaving] = useState(false);
	const [leaveUrl, setLeaveUrl] = useState("");

	const store = useConfirmPopupStore();
	const { setState } = useConfirmPopupStore();

	const history = useHistory();

	useEffect(() => {
		const unblock = history.block((ts: any) => {
			setLeaveUrl(ts.pathname as string);

			if (!ignoreChanges && (valuesToCheck || store.providedValuesToCheck)) {
				setState(ctx => {
					ctx.showConfirmPopup = true;
				});
				return false;
			}
		});

		if (confirmedLeaving && leaveUrl) {
			unblock();
			setState(ctx => {
				ctx.showConfirmPopup = false;
				ctx.providedValuesToCheck = false;
			});
			history.push(leaveUrl);
		}

		return () => {
			unblock();
		};
	}, [history, confirmedLeaving, leaveUrl, valuesToCheck, setState, store, ignoreChanges]);

	const methods = useMemo(
		() => ({
			closeConfirmPopup: () => {
				setState(ctx => ({ ...ctx, showConfirmPopup: false }));
			},
			handleLeavePageConfirmed: () => {
				setConfirmedLeaving(true);
				methods.closeConfirmPopup();
			},
			provideHandleCloseValues: (values: boolean) => {
				setState(ctx => ({ ...ctx, providedValuesToCheck: values }));
			}
		}),
		[setState]
	);

	const getData = useCallback(() => {
		return store;
	}, [store]);

	return { ...methods, getData };
};

export default useConfirmLeavePage;
