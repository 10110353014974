import React from "react";

import { Controller, useForm } from "react-hook-form";
import styled from "styled-components";

import useConfirmLeavePage from "shared/Components/ConfirmLeave/hooks/useConfirmLeavePage";
import { useCommunity } from "shared/hooks";

import { SocialTypes } from "shared/types";
import { Box, Button, Text } from "shared/ui-kit";

import { validateUrlRegex } from "utils/serviceUtils/validators";

import SocialAccount from "../../SocialAccount";

const Wrapper = styled(Box)`
	display: flex;
	flex-direction: row;
	flex: 1;
	margin: 30px 0 0 0;
	align-items: flex-end;
	padding-bottom: 70px;

	${props => props.theme.breakpoints.down("sm")} {
		flex-direction: column;
		width: 100%;
	}
`;

const SocialAccountsWrapper = styled(Box)`
	display: flex;
	flex-direction: column;
	padding: 20px 0 20px 0;
`;

const ButtonWrapper = styled(Wrapper)`
	align-items: flex-end;
	justify-content: flex-end;
	margin: 0;
	padding-bottom: 0;
`;

const Subtitle = styled(Text)`
	margin-top: 15px;
	margin-bottom: 15px;
`;
const SubmitButton = styled(Button)`
	height: 48px;
	margin-right: 10px;
`;

const Social = () => {
	const { updateWorkspaceMeta, getData: getCommunityData } = useCommunity();
	const { workspace } = getCommunityData();
	const { control, handleSubmit, formState, errors } = useForm({
		defaultValues: workspace?.meta?.social,
		mode: "onChange"
	});

	const { provideHandleCloseValues } = useConfirmLeavePage({});

	const onSaveSocialAccounts = async data => {
		if (data) {
			await updateWorkspaceMeta({
				meta: {
					social: data
				}
			});
		}
	};

	return (
		<>
			<Subtitle variant={"subtitle1"}>Social accounts</Subtitle>
			<Text>
				Allow your community members to follow you on other social media platforms by adding your profile URLs below.
			</Text>
			<form onSubmit={handleSubmit(onSaveSocialAccounts)}>
				<SocialAccountsWrapper>
					{[SocialTypes.facebook, SocialTypes.instagram, SocialTypes.twitter, SocialTypes.tiktok].map(
						(account, index) => (
							<Controller
								key={`${index}_${account}`}
								name={account}
								defaultValue={""}
								control={control}
								rules={{
									pattern: {
										message: "Invalid url",
										value: validateUrlRegex
									},
									validate: data => {
										if (data && data.toLowerCase().match(validateUrlRegex)[0] !== data.toLowerCase()) {
											return "Invalid url";
										}
										if (data && !data.toLowerCase().includes(account.toLowerCase())) {
											return `Invalid ${account.toLowerCase()} url`;
										}
										return true;
									},
									required: ""
								}}
								render={({ onChange, value, ref }) => (
									<SocialAccount
										type={account}
										value={value}
										onChange={e => {
											onChange(e);
											provideHandleCloseValues(true);
										}}
										error={errors[account]?.message}
										ref={ref}
										id={`${account}Link`}
									/>
								)}
							/>
						)
					)}
				</SocialAccountsWrapper>
				<ButtonWrapper>
					<SubmitButton
						type="submit"
						disabled={!formState.isValid || !formState.isDirty}
						id="saveChangesSocialCommunityPage"
					>
						Save changes
					</SubmitButton>
				</ButtonWrapper>
			</form>
		</>
	);
};

export default Social;
