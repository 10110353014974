import styled from "styled-components";

import { Button } from "shared/ui-kit";

export const StyledButton = styled(Button)`
	margin: 0;

	border-radius: 8px;

	background-color: #edf1f7;

	color: #222b45;

	box-shadow: none;
`;
