import React, { memo, useMemo } from "react";

import { Box } from "@material-ui/core";
import ReactHtmlParser from "react-html-parser";
import styled from "styled-components";

import { useMemberRoutes } from "shared/hooks";
import { safelyParseJSON } from "utils/serviceUtils/helpers";

type Props = {
	content?: string;
};

const TextWrapper = styled(Box)`
	font-size: 15px;
	line-height: 20px;
	padding: 0 16px;
`;

const TextBlock: React.FC<Props> = memo(({ content }) => {
	const { getMemberRoutesData } = useMemberRoutes();
	const { routes } = getMemberRoutesData();

	const dom = useMemo(() => {
		const domData = new DOMParser().parseFromString(content || "", "text/html");
		domData.querySelectorAll("a").forEach(tag => {
			if (tag.innerText.startsWith("#")) {
				tag.setAttribute("href", `${routes?.member.hashtag.getPath()}/${tag.innerText.split("#")[1]}`);
			}

			if (tag.innerText.startsWith("@")) {
				const splitTag = decodeURIComponent(tag.href).split("/");
				const hrefVal = splitTag[splitTag.length - 1];
				const hrefObj = safelyParseJSON(hrefVal.replace(/'/gi, '"')) as { personaId: number };

				if (hrefObj.personaId) {
					tag.setAttribute("href", `${routes?.member.profile.getPath()}/${hrefObj.personaId}`);
				}
			}
		});
		return domData;
	}, [content, routes]);

	return dom ? <TextWrapper>{ReactHtmlParser(dom.body.innerHTML)}</TextWrapper> : <p></p>;
});

export default TextBlock;
