import React, { useEffect, useMemo, useRef } from "react";

import { ButtonBase } from "@material-ui/core";
import { ChatAttachmentMessage, ChatImageMessage } from "types/MessagingTypes";

import { fileExtensions } from "types/fileExtensions";

import GifBlock from "shared/Components/Blocks/GifBlock";
import RequestMoneyBlock from "shared/Components/Blocks/RequestMoneyBlock";
import YouTubeBlock from "shared/Components/Blocks/YouTubeBLock";
import { STORIES_OPEN_TYPE } from "shared/contexts/StoryContext/StoryContext";
import { useMemberRoutes, useStory, useUser } from "shared/hooks";
import { RelativeWrapper } from "shared/styles";
import { PaymentRequestModel } from "shared/types";

import { Text } from "shared/ui-kit";
import { captureVideoThumbnail } from "utils/captureVideoThumbnail";
import { validateYoutube } from "utils/serviceUtils/validators";

import {
	AudioBubble,
	CancelCarpoolBubble,
	CarpoolBubble,
	FileBubble,
	GroupBubble,
	ImageBubble,
	PlaceBubble,
	TextBubble,
	TrackBubble,
	VideoBubble
} from "./Bubbles";
import { ChatWrapper, StoryMessagePreview } from "./styles";

export enum RenderActionType {
	TEXT = "TEXT",
	IMAGE = "IMAGE",
	VIDEO = "VIDEO",
	AUDIO = "AUDIO",
	FILE = "FILE",
	GROUP = "GROUP",
	PLACE = "PLACE",
	TRACK = "TRACK",
	PAYERQ = "PAYREQ",
	EVENT = "EVENT"
}

const ChatBubble = props => {
	const isCurrentUser = useMemo(() => props?.currentMessage?.user._id === props.currentUserId, [props]);

	const { getMemberRoutesData } = useMemberRoutes();
	const { routes: memberRoutes } = getMemberRoutesData();

	const messageBlockCurrentUser = useMemo(
		() => isCurrentUser && location.pathname === memberRoutes?.member.messaging.getPath(),
		[isCurrentUser, memberRoutes?.member.messaging]
	);

	const { getData: getUserData, getActiveProfile } = useUser();
	const { user } = getUserData();

	const activeProfile = getActiveProfile(user);

	const { openStories, getMyStories, populateStories, getData: getStoryData } = useStory();

	const { personsStoryList } = getStoryData();

	const renderMessageImage = useMemo(() => {
		if (props.currentMessage?.images?.length) {
			return props.currentMessage.images.map((imageItem: ChatImageMessage, index) => (
				<ImageBubble imageItem={imageItem} key={index} isCurrentUser={isCurrentUser} {...props} />
			));
		}
	}, [isCurrentUser, props]);

	const renderMessageAudio = useMemo(() => {
		if (props.currentMessage?.audios?.length) {
			return props.currentMessage.audios.map((audio: ChatAttachmentMessage, index) => (
				<AudioBubble audio={audio} key={index} isCurrentUser={isCurrentUser} {...props} />
			));
		}
	}, [isCurrentUser, props]);

	const renderMessageVideo = useMemo(() => {
		if (props.currentMessage?.videos?.length) {
			return props.currentMessage.videos.map((videoItem: ChatAttachmentMessage, index) => {
				const isYTVid = validateYoutube(videoItem.url);

				if (isYTVid) {
					return <YouTubeBlock url={videoItem.url} />;
				}

				return <VideoBubble videoItem={videoItem} key={index} isCurrentUser={isCurrentUser} {...props} />;
			});
		}
	}, [isCurrentUser, props]);

	const renderFiles = useMemo(() => {
		if (props.currentMessage?.files?.length) {
			return props.currentMessage.files.map((fileItem: ChatAttachmentMessage, index) =>
				!!fileExtensions.find(ext => ext.mime === fileItem.type) ? (
					<FileBubble
						fileItem={fileItem}
						key={index}
						isCurrentUser={isCurrentUser}
						messageBlockCurrentUser={messageBlockCurrentUser}
						{...props}
					/>
				) : null
			);
		}
	}, [isCurrentUser, messageBlockCurrentUser, props]);

	const renderGroup = useMemo(() => {
		if (props.currentMessage?.group) {
			return <GroupBubble isCurrentUser={isCurrentUser} messageBlockCurrentUser={messageBlockCurrentUser} {...props} />;
		}
	}, [isCurrentUser, messageBlockCurrentUser, props]);

	const renderTrack = useMemo(() => {
		if (props.currentMessage?.track) {
			return (
				<RelativeWrapper>
					<TrackBubble isCurrentUser={isCurrentUser} messageBlockCurrentUser={messageBlockCurrentUser} {...props} />
				</RelativeWrapper>
			);
		}
	}, [isCurrentUser, messageBlockCurrentUser, props]);

	const renderPlace = useMemo(() => {
		if (props.currentMessage?.place) {
			return <PlaceBubble isCurrentUser={isCurrentUser} messageBlockCurrentUser={messageBlockCurrentUser} {...props} />;
		}
	}, [isCurrentUser, messageBlockCurrentUser, props]);

	const renderRequestMoney = useMemo(() => {
		if (props.currentMessage?.payreq) {
			return (
				<RelativeWrapper>
					<RequestMoneyBlock
						maxWidth
						payReq={props.currentMessage?.payreq as PaymentRequestModel}
						messageBlockCurrentUser={messageBlockCurrentUser}
					></RequestMoneyBlock>
				</RelativeWrapper>
			);
		}
	}, [messageBlockCurrentUser, props.currentMessage?.payreq]);

	const renderCarpool = useMemo(() => {
		if (props.currentMessage?.carpool) {
			return <CarpoolBubble {...props.currentMessage} isCurrentUser={isCurrentUser} />;
		}
	}, [props.currentMessage, isCurrentUser]);

	const renderCancelCarpool = useMemo(() => {
		if (props.currentMessage?.canceledCarpool) {
			return <CancelCarpoolBubble {...props.currentMessage} />;
		}
	}, [props.currentMessage]);

	const renderGif = useMemo(() => {
		if (props.currentMessage?.gif) {
			return (
				<RelativeWrapper>
					<GifBlock {...props.currentMessage.gif} />
				</RelativeWrapper>
			);
		}
	}, [props.currentMessage]);

	const renderStory = useMemo(() => {
		if (props.currentMessage?.storyReply) {
			const story = props.currentMessage?.storyReply.stories.find(
				story => story._id === props.currentMessage?.storyReply.activeStoryId
			);

			if (!story) return null;

			const handleClickStory = async () => {
				if (isMyStory) {
					const pIdx = personsStoryList.findIndex(st => st.chatUserId === activeProfile?.chatUserId);
					if (pIdx > -1) {
						const storyMain = personsStoryList[pIdx];
						openStories({
							isOpen: STORIES_OPEN_TYPE.STORIES,
							dontRequestdata: true,
							storyId: storyMain._id,
							slideId: story._id
						});
					} else {
						const stories = await getMyStories();
						if (stories && activeProfile) {
							const userStories = {
								chatUserId: activeProfile.chatUserId,
								currentCoordinates: { lat: 0, lon: 0 },
								education: activeProfile.education,
								intro: activeProfile.intro,
								isWatched: true,
								jobs: activeProfile.jobs,
								personaId: activeProfile.personaId,
								photos: activeProfile.photos,
								vyooable: activeProfile.vyooable,
								stories,
								firstName: activeProfile.firstName,
								liveStory: false,
								_id: activeProfile.chatUserId
							};

							populateStories([userStories]);

							setTimeout(() => {
								openStories({
									isOpen: STORIES_OPEN_TYPE.STORIES,
									dontRequestdata: true,
									storyId: userStories._id,
									slideId: story._id
								});
							}, 300);
						}
					}
				} else {
					openStories({
						isOpen: STORIES_OPEN_TYPE.STORIES,
						slideId: story._id
					});
				}
			};

			const isMyStory = props.currentMessage.storyReply.personaId === activeProfile?.personaId;
			const sender = isCurrentUser ? "You" : props.currentMessage.user.name;
			const receiver = isMyStory ? "your" : `${props.currentMessage.storyReply.firstName}'s`;

			const storyPreview = story.type === "video" ? captureVideoThumbnail(story.uri) : story.uri;

			const isExpired = !!story.expireAt && new Date(story?.expireAt).getTime() < new Date().getTime();

			return (
				<StoryMessagePreview className={isCurrentUser && "isCurrentUser"}>
					<Text>{`${sender} replied to ${receiver} story`}</Text>
					{!isExpired && (
						<ButtonBase onClick={handleClickStory}>
							<img src={storyPreview} alt={story._id} />
						</ButtonBase>
					)}
				</StoryMessagePreview>
			);
		}
	}, [
		props.currentMessage.storyReply,
		props.currentMessage.user.name,
		activeProfile,
		isCurrentUser,
		personsStoryList,
		openStories,
		getMyStories,
		populateStories
	]);

	const chatRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (props.currentMessage.observeMessage && chatRef.current) {
			chatRef.current.setAttribute("data-observe", "true");
		}
	}, [props.currentMessage.observeMessage]);

	return (
		<ChatWrapper
			ref={chatRef}
			className={`${isCurrentUser && "current-user"} ${props.isDifferentUser && "member-message"} ${
				(props.isCarpool || props.isCanceledCarpool) && "w-full"
			}`}
		>
			{renderStory}
			{!props.isCanceledCarpool && !!props.currentMessage?.text && !!props.currentMessage?.text.length && (
				<TextBubble {...props} isCurrentUser={isCurrentUser} />
			)}
			{renderMessageImage}
			{renderMessageVideo}
			{renderMessageAudio}
			{renderFiles}
			{renderGroup}
			{renderPlace}
			{renderTrack}
			{renderRequestMoney}
			{renderGif}
			{renderCarpool}
			{renderCancelCarpool}
		</ChatWrapper>
	);
};

export default ChatBubble;
