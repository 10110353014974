import { useCallback, useMemo } from "react";

import { useCarpoolStore } from "shared/contexts/CarpoolContext";
import useCarpoolApiService from "shared/services/useCarpoolApiService";
import { EventModel } from "shared/types";
import { CreateCarpool } from "shared/types/CarpoolTypes";

import { actionMethod, actionTypes, getValidationMessage } from "utils/getValidationMessage";

import { OfferRequest } from "../types/EventModel";
import { useEvent, useNotification } from "./index";

const useCarpool = () => {
	const store = useCarpoolStore();
	const { setState: setCarpoolState } = useCarpoolStore();
	const { setRequestEvents, getData: getEventData } = useEvent();
	const { requestEvents } = getEventData();

	const service = useCarpoolApiService();

	const { showMessage } = useNotification();

	const methods = useMemo(
		() => ({
			createCarpool: async (
				data: CreateCarpool,
				event: EventModel,
				profilePicture: string,
				user: { firstName: string; lastName: string; personaId: number },
				isUpdate = false,
				onClose?: () => void,
				isCarpoolPage = false
			) => {
				setCarpoolState({ creating: true });
				try {
					const { carpool, location: _location } = await service.createCarpool(data);
					const _requestEvents = requestEvents.find(r => r._id === data.eventId);
					const location = { ..._location, location: { lat: _location.lat, lon: _location.lon } };
					const creator = {
						_id: user.personaId,
						personaId: user.personaId,
						firstName: user.firstName,
						lastName: user.lastName,
						photos: [{ profilePicture }]
					};

					if (!isCarpoolPage) {
						if (_requestEvents) {
							setRequestEvents(
								requestEvents.map(r =>
									r._id === data.eventId
										? {
												...r,
												requests: [...r.requests, { ...carpool, location, creator }],
												carpoolOfferIds:
													data.type === "offer" ? [carpool._id, ...r.carpoolOfferIds] : r.carpoolOfferIds,
												carpoolRequestIds:
													data.type === "request" ? [carpool._id, ...r.carpoolRequestIds] : r.carpoolRequestIds
										  }
										: r
								)
							);
						} else {
							setRequestEvents([
								{
									...event,
									_id: event.eventId,
									name: event.title,
									eventDateAndTime: event.startTime,
									requests: [{ ...carpool, location, creator }],
									carpoolOfferIds: data.type === "offer" ? [carpool._id] : [],
									carpoolRequestIds: data.type === "request" ? [carpool._id] : [],
									volunteerSlotIds: []
								},
								...requestEvents
							]);
						}
					} else {
						if (data.type === "request") {
							setCarpoolState(ctx => ({
								carpoolRequests: [...ctx.carpoolRequests, { ...carpool, location, creator }]
							}));
						} else {
							setCarpoolState(ctx => ({
								carpoolOffers: ctx.carpoolOffers.find(o => o._id === carpool._id)
									? ctx.carpoolOffers.map(req => (req._id === "" ? { ...carpool, location, creator } : req))
									: [...ctx.carpoolOffers, { ...carpool, location, creator }]
							}));
						}
					}
					onClose && onClose();
					showMessage(
						getValidationMessage({
							name: data.type === "request" ? "Carpool Request" : "Carpool Offer",
							actionType: actionTypes.CRUD,
							actionMethod: isUpdate ? actionMethod.updated : actionMethod.created,
							emoji: isUpdate ? "✨" : "🎉"
						}),
						3
					);

					return carpool;
				} catch (error) {
				} finally {
					setCarpoolState({ creating: false });
				}
			},
			signUpCarpoolRequest: async (
				id: string,
				eventId: string,
				userId: string,
				onClose?: () => void,
				isCarpoolPage = false
			) => {
				setCarpoolState({ creating: true });
				try {
					const { success, connection } = await service.signUpCarpoolRequest(id);

					if (!success) {
						throw Error();
					}

					if (!isCarpoolPage) {
						setRequestEvents(
							requestEvents.map(r =>
								r._id === eventId
									? {
											...r,
											requests: r.requests.map(req =>
												req._id === id ? { ...req, rideOfferedBy: userId, offeredRide: true } : req
											)
									  }
									: r
							)
						);
					} else {
						setCarpoolState(ctx => ({
							carpoolRequests: ctx.carpoolRequests.map(r =>
								r._id === id
									? {
											...r,
											rideOfferedBy: userId,
											offeredRide: true
									  }
									: r
							)
						}));
					}

					onClose && onClose();

					showMessage("You’ve successfully signed up for carpool.", 3);

					return { success, connection };
				} catch (error) {
					return { success: null, connection: null };
				} finally {
					setCarpoolState({ creating: false });
				}
			},
			cancelCarpoolRequest: async (id: string, eventId: string, onClose?: () => void, isCarpoolPage = false) => {
				setCarpoolState({ creating: true });
				try {
					const { success, connection } = await service.cancelCarpoolRequest(id);

					if (!success) {
						throw Error();
					}

					if (!isCarpoolPage) {
						setRequestEvents(
							requestEvents.map(r =>
								r._id === eventId
									? {
											...r,
											requests: r.requests.map(req =>
												req._id === id ? { ...req, rideOfferedBy: null, offeredRide: false } : req
											)
									  }
									: r
							)
						);
					} else {
						setCarpoolState(ctx => ({
							carpoolRequests: ctx.carpoolRequests.map(r =>
								r._id === id
									? {
											...r,
											rideOfferedBy: null,
											offeredRide: false
									  }
									: r
							)
						}));
					}

					onClose && onClose();

					showMessage("You’ve successfully signed up for carpool.", 3);

					return { success, connection };
				} catch (error) {
					return { success: null, connection: null };
				} finally {
					setCarpoolState({ creating: false });
				}
			},
			bookASeatCarpoolRequest: async (
				id: string,
				eventId,
				seatsCount: number,
				userId: string,
				onClose?: () => void,
				isCarpoolPage = false
			) => {
				setCarpoolState({ creating: true });
				try {
					const { success, connection } = await service.bookASeatCarpoolRequest(id, seatsCount);

					if (!success) {
						throw Error();
					}

					if (!isCarpoolPage) {
						setRequestEvents(
							requestEvents.map(r =>
								r._id === eventId
									? {
											...r,
											requests: r.requests.map(req =>
												req._id === id
													? {
															...req,
															seatsLeft: req.seatsLeft - seatsCount,
															bookedSeats: [...req.bookedSeats, { personaDocId: userId, seatsBooked: seatsCount }],
															bookedASeat: true
													  }
													: req
											)
									  }
									: r
							)
						);
					} else {
						setCarpoolState(ctx => ({
							carpoolOffers: ctx.carpoolOffers.map(req =>
								req._id === id
									? {
											...req,
											seatsLeft: req.seatsLeft - seatsCount,
											bookedSeats: [...req.bookedSeats, { personaDocId: userId, seatsBooked: seatsCount }],
											bookedASeat: true
									  }
									: req
							)
						}));
					}

					onClose && onClose();
					showMessage(`You’ve successfully booked ${seatsCount} seats.`, 3);

					return { success, connection };
				} catch (error) {
					return { success: false, connection: null };
				} finally {
					setCarpoolState({ creating: false });
				}
			},
			updateBookingCarpoolRequest: async (
				id: string,
				eventId,
				seatsCount: number,
				userId: string,
				onClose?: () => void,
				isCarpoolPage = false
			) => {
				setCarpoolState({ creating: true });
				try {
					const { success, connection } = await service.updateBookingCarpoolRequest(id, seatsCount);

					if (!success) {
						throw Error();
					}

					if (!isCarpoolPage) {
						setRequestEvents(
							requestEvents.map(r =>
								r._id === eventId
									? {
											...r,
											requests: r.requests.map(req =>
												req._id === id
													? {
															...req,
															bookedSeats: req.bookedSeats.map(b =>
																b.personaDocId === userId ? { ...b, seatsBooked: seatsCount } : b
															)
													  }
													: req
											)
									  }
									: r
							)
						);
					} else {
						setCarpoolState(ctx => ({
							carpoolOffers: ctx.carpoolOffers.map(req =>
								req._id === id
									? {
											...req,
											bookedSeats: req.bookedSeats.map(b =>
												b.personaDocId === userId ? { ...b, seatsBooked: seatsCount } : b
											)
									  }
									: req
							)
						}));
					}

					onClose && onClose();
					showMessage("Your carpool booking details have been updated", 3);

					return { success, connection };
				} catch (error) {
					return { success: false, connection: null };
				} finally {
					setCarpoolState({ creating: false });
				}
			},
			cancelBookingCarpoolRequest: async (
				id: string,
				eventId,
				userId: string,
				onClose?: () => void,
				isCarpoolPage = false
			) => {
				setCarpoolState({ creating: true });
				try {
					const { success, connection } = await service.cancelBookingCarpoolRequest(id);

					if (!success) {
						throw Error();
					}

					if (!isCarpoolPage) {
						setRequestEvents(
							requestEvents.map(r =>
								r._id === eventId
									? {
											...r,
											requests: r.requests.map(req =>
												req._id === id
													? {
															...req,
															seatsLeft:
																req.seatsLeft + req.bookedSeats.find(b => b.personaDocId === userId).seatsBooked || 0,
															bookedSeats: req.bookedSeats.filter(b => b.personaDocId !== userId),
															bookedASeat: !!req.bookedSeats.filter(b => b.personaDocId !== userId).length
																? true
																: false
													  }
													: req
											)
									  }
									: r
							)
						);
					} else {
						setCarpoolState(ctx => ({
							carpoolOffers: ctx.carpoolOffers.map(req =>
								req._id === id
									? {
											...req,
											seatsLeft: req.seatsLeft + req.bookedSeats.find(b => b.personaDocId === userId).seatsBooked || 0,
											bookedSeats: req.bookedSeats.filter(b => b.personaDocId !== userId),
											bookedASeat: !!req.bookedSeats.filter(b => b.personaDocId !== userId).length ? true : false
									  }
									: req
							)
						}));
					}

					onClose && onClose();

					showMessage("You’ve successfully canceled your carpool booking", 3);

					return { success, connection };
				} catch (error) {
					return { success: null, connection: null };
				} finally {
					setCarpoolState({ creating: false });
				}
			},
			setCarpoolRequests: (carpoolRequests: OfferRequest[]) => {
				setCarpoolState({ carpoolRequests });
			},
			setCarpoolOffers: (carpoolOffers: OfferRequest[]) => {
				setCarpoolState({ carpoolOffers });
			}
		}),
		[service, setCarpoolState, showMessage, requestEvents, setRequestEvents]
	);

	const getData = useCallback(() => {
		return store;
	}, [store]);

	return { ...methods, getData };
};

export default useCarpool;
