import React from "react";

import Paper from "@material-ui/core/Paper";
import styled from "styled-components";

import Spreadsheet from "./Components/Spreadsheet";
import VirtualizedTable from "./Components/VirtualizedTable";

const PaperWrapper = styled(Paper)<{ autoHeight: boolean }>`
	height: ${({ autoHeight }) => (autoHeight ? "100%" : "auto")};
`;

type DefaultTableProps = {
	checkable?: boolean;
	checkableColumnWidth?: number;
	checkableColumnPosition?: "left" | "right";
	checkedRows?: any[];
	columns: any[];
	customItemActions?: any;
	customStyle?: string;
	data: any[];
	expandable?: boolean;
	height?: number;
	autoHeight?: boolean;
	hideHeader?: boolean;
	hideNonCheckables?: boolean;
	hideNonExpandable?: boolean;
	isRowCheckable?: () => void;
	isRowExpandable?: () => void;
	itemActions?: any;
	// sort,
	// sortBy,
	// sortDirection,
	multigrid?: boolean;
	onCheckAll?: () => void;
	onCheckItem?: () => void;
	onLastRowScrolled?: () => void;
	onReorder?: () => void;
	onToggleCollapsible?: () => void;
	reorderable?: boolean;
	reorderHeader?: string;
	rowHeight?: number;
	showLoader?: boolean;
};

export type TableProps = DefaultTableProps;

const Component = ({
	data,
	height,
	autoHeight = false,
	columns,
	rowHeight,
	checkable,
	expandable,
	itemActions,
	onCheckAll,
	checkedRows = [],
	customItemActions,
	onCheckItem,
	onToggleCollapsible,
	isRowCheckable,
	isRowExpandable,
	checkableColumnWidth,
	checkableColumnPosition = "left",
	hideNonCheckables = false,
	hideNonExpandable = false,
	showLoader = false,
	reorderable = false,
	hideHeader = false,
	onReorder,
	reorderHeader = "Arrange",
	sort,
	customStyle = "",
	sortBy,
	sortDirection,
	multigrid = false,
	onLastRowScrolled
}) => {
	const ref = React.useRef<any>(null);
	const [width, setWidth] = React.useState(500);

	React.useEffect(() => {
		if (ref) {
			setWidth(ref.current.clientWidth);
		}
	}, [ref]);

	if (multigrid) {
		return (
			<PaperWrapper ref={ref} autoHeight={autoHeight}>
				<Spreadsheet
					data={data}
					width={width}
					rowHeight={rowHeight}
					height={height}
					autoHeight={autoHeight}
					hideHeader={hideHeader}
					checkedRows={checkedRows}
					rowCount={data.length}
					columns={columns}
					rowGetter={({ index }) => data[index]}
					itemActions={itemActions}
					customItemActions={customItemActions}
					checkableColumnWidth={checkableColumnWidth}
					checkableColumnPosition={checkableColumnPosition}
					checkable={checkable}
					expandable={expandable}
					isRowExpandable={isRowExpandable}
					onCheckAll={onCheckAll}
					onCheckItem={onCheckItem}
					onToggleCollapsible={onToggleCollapsible}
					isRowCheckable={isRowCheckable}
					hideNonCheckables={hideNonCheckables}
					showLoader={showLoader}
					reorderable={reorderable}
					onReorder={onReorder}
					reorderHeader={reorderHeader}
					sort={sort}
					sortBy={sortBy}
					sortDirection={sortDirection}
					customStyle={customStyle}
					onLastRowScrolled={onLastRowScrolled}
				/>
			</PaperWrapper>
		);
	}

	return (
		<div>
			<Paper ref={ref}>
				<VirtualizedTable
					data={data}
					width={width}
					rowHeight={rowHeight}
					height={height}
					hideHeader={hideHeader}
					checkedRows={checkedRows}
					rowCount={data.length}
					columns={columns}
					rowGetter={({ index }) => data[index]}
					itemActions={itemActions}
					customItemActions={customItemActions}
					checkableColumnWidth={checkableColumnWidth}
					checkable={checkable}
					expandable={expandable}
					onCheckAll={onCheckAll}
					onCheckItem={onCheckItem}
					onToggleCollapsible={onToggleCollapsible}
					isRowCheckable={isRowCheckable}
					isRowExpandable={isRowExpandable}
					hideNonCheckables={hideNonCheckables}
					hideNonExpandable={hideNonExpandable}
					showLoader={showLoader}
					reorderable={reorderable}
					onReorder={onReorder}
					reorderHeader={reorderHeader}
					sort={sort}
					sortBy={sortBy}
					sortDirection={sortDirection}
					customStyle={customStyle}
					onLastRowScrolled={onLastRowScrolled}
				/>
			</Paper>
		</div>
	);
};

const ExplorerTable = styled(Component)`
	outline: none;
`;

export default ExplorerTable;
