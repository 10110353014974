import React, { FC, useEffect, useState } from "react";

import { CreateUpdateSeriesWrapper } from "shared/Components";
import { useSeries } from "shared/hooks";

import { ModelBlockType } from "../../../ModelBlock";
import { GroupSectionTemplate } from "../../components";

const defaultFilters = {
	limit: 20,
	keyword: ""
};

const SeriesSection: FC<{ id: string; event?: boolean; canCreate?: boolean }> = ({ id, event, canCreate }) => {
	const { getCollections, getAllCollectionsCount, setSeriesDialog, getData: getSeriesData } = useSeries();
	const { series, seriesCount, isLoading } = getSeriesData();
	const [offset, setOffset] = useState(1);

	useEffect(() => {
		const data: any = { ...defaultFilters, offset };

		event ? (data.eventId = id) : (data.groupId = id);

		getCollections(data);
	}, [event, getCollections, id, offset]);

	useEffect(() => {
		const data: { eventId?: string; groupId?: string } = {};

		event ? (data.eventId = id) : (data.groupId = id);

		getAllCollectionsCount(data);
	}, [event, getAllCollectionsCount, id]);

	return (
		<>
			<GroupSectionTemplate
				loading={isLoading}
				autoFit={seriesCount > 3 ? 300 : undefined}
				items={series || []}
				type={ModelBlockType.series}
				actionButton={
					canCreate ? { title: "Create New Series", onClick: () => setSeriesDialog({ open: true }) } : undefined
				}
				title="Series & Collections"
				noItemsErrorText={`This ${event ? "event" : "group"} don’t have any collections yet.`}
				onEndScroll={() => {
					if (seriesCount && series.length < seriesCount) {
						setOffset(offset + 1);
					}
				}}
			/>
			<CreateUpdateSeriesWrapper groupId={!event ? id : undefined} eventId={event ? id : undefined} />
		</>
	);
};

export default SeriesSection;
