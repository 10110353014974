import { GifModel } from "shared/types";
import { IStateProps } from "utils/StateOf";

import createStore from "utils/createStore";

export interface Values {
	list: GifModel[] | null;
	loading: boolean;
}

export const initialState: IStateProps<Values> = {
	list: [],
	loading: false
};

export default createStore<Values>({
	initialState
});
