import React, { useEffect } from "react";

import { useLocation } from "react-router-dom";

import { view as appView } from "modules/App";
import { useAuth } from "modules/App/Data";
import { view } from "modules/Community";

import { useCommunity, useMemberRoutes, useRoutes, useUser } from "shared/hooks";
import useJwt from "shared/services/api/core/jwt";

import { VARS, routes as authRoutes, routes } from "../../../Auth/constants";
import { routes as onboardingRoutes } from "../../../Onboarding/constants";
import { routes as adminRoutes, routes as regularRoutes } from "../../../RegularUser/constants";

const ProfileCommunityPage = () => {
	const { search } = useLocation();
	const jwtService = useJwt();
	const { setGlobalUserId, resetState, getData: getUserData } = useUser();
	const { globalUserId } = getUserData();

	const { getMemberRoutesData } = useMemberRoutes();
	const { routes: memberRoutes } = getMemberRoutesData();

	const { clearStorage } = useRoutes();
	const { clearState } = useAuth();

	const { setSportsMode } = useCommunity();

	const query = new URLSearchParams(search);
	const token = query.get("token");
	const workspace = query.get("workspace");
	const havePassword = query.get("havePassword");
	const guId = query.get("globalUserId");
	const isSportsMode = query.get("sportsmode");

	useEffect(() => {
		if (token && workspace) {
			clearStorage();
			clearState();
			resetState();
			jwtService.setJwt(token, workspace);
		}
	}, [clearStorage, clearState, resetState, jwtService, token, workspace]);

	useEffect(() => {
		setSportsMode(isSportsMode !== null);
	}, [isSportsMode, setSportsMode]);

	useEffect(() => {
		if (guId && !globalUserId) {
			setGlobalUserId(guId);
		}
	}, [globalUserId, guId, setGlobalUserId]);

	if (!jwtService.getJwt()) {
		return (
			<appView.SignIn
				TERMS_AND_CONDITION={VARS.TERMS_AND_CONDITIONS_LINK}
				PRIVACY_POLICY_LINK={VARS.PRIVACY_POLICY_LINK}
				COOKIE_USE_LINK={VARS.COOKIE_USE_LINK}
				forgotPassword={authRoutes.auth.forgotPassword.getPath()}
				communityListRoute={authRoutes.auth.communities.getPath()}
				memberHomePage={memberRoutes?.member.home.getPath()}
				memberSignInPage={adminRoutes.user.signIn.getPath()}
				memberFillProfilePage={memberRoutes?.member.fillProfile.getPath()}
				invitationCodePage={routes.auth.invitationCode.getPath()}
				joinCommunitiesUrl={adminRoutes.communities.getPath()}
			/>
		);
	}

	return (
		<view.Profile
			adminProfileView={!!search && search !== "?sportsmode"}
			createCommunityRoute={onboardingRoutes.onboarding.community.create.getPath()}
			homeRoute={regularRoutes.dashboard.getPath()}
			createPasswordRoute={havePassword === "false" ? authRoutes.auth.createPassword.getPath() : undefined}
		/>
	);
};

export default ProfileCommunityPage;
