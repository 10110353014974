import React from "react";

import { useLocation } from "react-router";

import { view } from "modules/Fundraisers";

const FundraisersPage = () => {
	const { search } = useLocation();
	const query = new URLSearchParams(search);
	const edit = query.get("edit") || undefined;

	return <view.FundraisersView edit={edit} />;
};

export default FundraisersPage;
