import { ForumUserModel } from "types";
import { Connection } from "types/StoriesContextValuesType";

import { GroupModel } from "shared/types";

export interface ActivityContextValues {
	loading: boolean;
	activities: ActivityModel[];
	allowToLoadMore: boolean;
}

export enum ActivityType {
	replytopost = "replytopost",
	reactedtopost = "reactedtopost",
	reactedtocomment = "reactedtocomment",
	reactedtoreply = "reactedtoreply",
	reqaccepted = "reqaccepted",
	connectionreq = "connectionreq",
	eventstarting = "eventstarting",
	eventupdate = "eventupdate",
	groupevent = "groupevent",
	memberreq = "memberreq",
	reqapproved = "reqapproved",
	groupinvite = "groupinvite",
	inviteaccepted = "inviteaccepted",
	newgrouppost = "newgrouppost",
	latestpostingroup = "latestpostingroup",
	loyaltyCoins = "loyaltyCoins",
	newepisode = "newepisode",
	taggedStory = "taggedStory",
	postmention = "postmention",
	replymention = "replymention",
	replytopicmention = "replytopicmention",
	payreqrec = "payreqrec",
	discover = "discover",
	iwasthere = "iwasthere",
	welcome = "welcome",
	eventadmin = "eventadmin",
	fundraiserparticipant = "fundraiserparticipant",
	volunteerslotdeleted = "volunteerslotdeleted",
	volunteerleft = "volunteerleft",
	volunteerjoin = "volunteerjoin",
	reqdeclined = "reqdeclined",
	eventinvite = "eventinvite"
	// interested = "interested", // deprecated
	// nearbyevent = "nearbyevent", // deprecated
	// nearbylocation = "nearbylocation", // deprecated
	// badphoto = "badphoto", // deprecated
	// badvideo = "badvideo", // deprecated
	// rejectupload = "rejectupload", // deprecated
	// rejectuploadvideo = "rejectuploadvideo", // deprecated
	// rejectuploadphoto = "rejectuploadphoto", // deprecated
	// whowasnearby = "whowasnearby", // deprecated
}

export interface ActivityConnection {
	chatUserId: string;
	connection: Connection;
	connectionId: string;
	dateCreated: string;
	firstName: string;
	lastName: string;
	location: {
		locationId: string;
		name: string;
	};
	personaId: number;
	profilePhoto: string;
	purpose: string;
	remainingHours: number;
}

export interface ActivityMemberReqData {
	group?: {
		name: string;
		slug: string;
	};
}

// reactedtopost, reactedtoreply
export interface ActivityReactedToPostData {
	// check if it could be filled
	// group: {
	// 	slug: null;
	// 	name: null;
	// 	groupId: null;
	// };
	// message: null;
	post: {
		pid: string;
	};
	profilePicture: string;
	reactions: number[];
	topic: {
		tid: string;
	};
}

export interface ActivityFundraiserParticipantData {
	_id: string;
	senderPersonaId: number;
	viewerPersonaId: number;
	type: string;
	date: string;
	senderName: string;
	read: boolean;
	data: {
		fundraiserId: string;
		fundraiserName: string;
		senderPhoto: string;
	};
	activityId: string;
	updatedAt: string;
	createdAt: string;
}

export interface EventInviteData {
	eventName: string;
	eventId: string;
	profilePicture: string;
}

// replytopost
export interface ActivityReplyToPostData {
	// group: {};
	isNew: boolean;
	post: {
		content: string;
		pid: string;
		replyPostId: string;
		tid: string;
		timestampISO: string;
		user: ForumUserModel;
	};
	topic: {
		cid: string;
		locked: boolean;
		postcount: number;
		privileges: {
			// check if needed
			// deletable: false
			// editable: false
			// isAdminOrMod: false
			// posts:delete: false
			// posts:edit: false
			// posts:history: false
			// posts:view_deleted: false
			// purge: false
			// read: false
			// topics:delete: false
			// topics:read: false
			// topics:reply: false
			// topics:tag: false
			// view_deleted: false
			// view_thread_tools: false
		};
		tags: any[];
		tid: string;
		title: string;
		uid: string;
		votes: number;
	};
}

// replytopicmention
export interface ActivityReplyToPicMentionData {
	// group: {};
	post: {
		content: string;
		pid: string;
		replyPostId: string;
		timestampISO: string;
		user: {
			persona: {
				firstName: string;
				lastName: string;
				intro: {
					education: any[];
					job: any[];
				};
				personaId: number;
				photos: {
					active: boolean;
					id: string;
					mediaId: string;
					name: string;
					profilePicture: string;
				}[];
			};
		};
	};
	topic: {
		cid: string;
		locked: boolean;
		postcount: number;
		privileges: {
			// check if needed
			// deletable: false
			// editable: false
			// isAdminOrMod: false
			// posts:delete: false
			// posts:edit: false
			// posts:history: false
			// posts:view_deleted: false
			// purge: false
			// read: false
			// topics:delete: false
			// topics:read: false
			// topics:reply: false
			// topics:tag: false
			// view_deleted: false
			// view_thread_tools: false
		};
		tags: any[];
		tid: string;
		title: string;
		uid: string;
		votes: number;
	};
}

// connectionreq
export interface ActivityConnectionRequestData {
	connection: {
		connectionId: string;
	};
	message: string;
	profilePicture?: string;
}

// reqaccepted
export interface ActivityConnectionRequestAcceptedData {
	connection: {
		chatUserId: string;
		connection: Connection;
		connectionId: string;
		dateCreated: string;
		firstName: string;
		lastName: string;
		location: {
			locationId: string;
			name: string;
		};
		notifyToken: string;
		personaId: number;
		profilePhoto?: string;
		purpose: string;
		remainingHours: number;
	};
}

// eventstarting, eventadmin
export interface ActivityEventStartingData {
	event: {
		eventId: string;
		eventName: string;
		startTime: string;
		endTime: string | null;
	};
}

// eventupdate
export interface ActivityEventUpdateData {
	event: {
		eventId: string;
		eventName: string;
		startTime: string;
		endTime: string | null;
	};
}

// groupevent
export interface ActivityGroupEventData {
	eventId: string;
	eventName: string;
	groupId: string;
	groupName: string;
}

// groupinvite
export interface ActivityGroupInviteData {
	group: GroupModel;
}

// inviteaccepted, reqapproved
export interface ActivityGroupInviteAcceptedData {
	group: {
		name: string;
		slug: string;
	};
}

// newgrouppost
export interface ActivityNewGroupPostData {
	group: {
		name: string;
		slug: string;
	};
}

// latestpostingroup
export interface ActivityLatestPostingGroupData {
	group: {
		name: string;
		slug: string;
		coverUrl: string;
		groupId: string;
		total: number;
	};
}

// loyaltyCoins
export interface ActivityLoyaltyCoinsData {
	message?: string;
	connection: {
		photos: {
			profilePicture?: string;
		}[];
	};
}

// replymention, replytopicmention
export interface ActivityReplyMentionData {
	topic: {
		tid: string;
		title: string;
		cid: string;
		locked: boolean;
		postcount: number;
		uid: string;
		votes: number;
	};
	group?: {
		slug: string;
		name: string;
		cid: string | null;
		_id: string;
	};
	post: {
		content: string;
		pid: string;
		replyPostId: string;
		tid: string;
		timestampISO: string;
		user: ForumUserModel;
	};
	isNew: boolean;
}

export interface ActivityModel {
	_id: string;
	viewerPersonaId: number;
	senderPersonaId: number;
	senderName: string;
	date: string;
	activityId?: string;
	type: ActivityType;
	data:
		| ActivityMemberReqData
		| ActivityReactedToPostData
		| ActivityReplyToPostData
		| ActivityReplyToPicMentionData
		| ActivityConnectionRequestData
		| ActivityConnectionRequestAcceptedData
		| ActivityEventStartingData
		| ActivityEventUpdateData
		| ActivityGroupEventData
		| ActivityGroupInviteData
		| ActivityGroupInviteAcceptedData
		| ActivityLatestPostingGroupData
		| ActivityLoyaltyCoinsData
		| ActivityReplyMentionData;
	// data: {
	// 	event?: {
	// 		eventId: string;
	// 		eventName: string;
	// 		startTime: DateTime;
	// 		endTime: DateTime;
	// 	};
	// 	group?: {
	// 		// memberreq
	// 		name: string;
	// 		slug: string;
	// 	};
	// 	location?: any;
	// 	connection?: ActivityConnection;
	// };
	updatedAt: string;
	createdAt: string;
	read: boolean;
}

export enum ActivitySettingsType {
	commentsAndReactions = "commentsAndReactions",
	tagsAndMentions = "tagsAndMentions",
	reminders = "reminders",
	loyaltyAndRewards = "loyaltyAndRewards",
	connectionsActivities = "connectionsActivities",
	connectionRequests = "connectionRequests",
	groups = "groups",
	lives = "lives",
	events = "events",
	groupsYouManage = "groupsYouManage",
	showsAndPodcasts = "showsAndPodcasts",
	transactions = "transactions",
	messages = "messages"
}

export type ActivitySettingsModel = {
	commentsAndReactions: boolean;
	tagsAndMentions: boolean;
	reminders: boolean;
	loyaltyAndRewards: boolean;
	connectionsActivities: boolean;
	connectionRequests: boolean;
	groups: boolean;
	lives: boolean;
	events: boolean;
	groupsYouManage: boolean;
	showsAndPodcasts: boolean;
	transactions: boolean;
	messages: boolean;
};

export interface ActivitiesResponse {
	activites: ActivityModel[];
}

export interface ActivityVolunteerData {
	activityId: string;
	createdAt: string;
	data: {
		senderPhoto: string;
		eventId: string;
		name: string;
		eventName?: string;
		need?: string;
	};
	date: string;
	read: boolean;
	senderName: string;
	senderPersonaId: number;
	type: string;
	updatedAt: string;
	viewerPersonaId: number;
	_id: string;
}
