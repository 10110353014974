import React, { FC } from "react";

import { Dialog } from "shared/Components";

import { DialogButton, PopupDescription } from "../../Containers/ManageTracks/style";
import { PopupTitle } from "../../Containers/style";
import { DeleteAlbumDialogContent, DeleteAlbumFooter } from "../AlbumDetailsDialog/style";

const ConfirmDelete: FC<{
	headline: string;
	name?: string;
	onDelete: () => void;
	onClose: () => void;
	deleteBtnId?: string;
}> = ({ headline, name, onDelete, onClose, deleteBtnId }) => {
	return (
		<Dialog
			title={<PopupTitle>Delete {headline}?</PopupTitle>}
			open={true}
			onClose={onClose}
			bodyCustomStyles="padding: 0;"
			hasBackButton={false}
			isDelete
		>
			<DeleteAlbumDialogContent>
				{/* eslint-disable-next-line react/no-unescaped-entities */}
				<PopupDescription>
					Are you sure you want to permanently delete {!!name?.length ? `"${name}"` : `this ${headline.toLowerCase()}`}?
				</PopupDescription>
			</DeleteAlbumDialogContent>
			<DeleteAlbumFooter>
				<DialogButton buttonTheme={"outline"} size={"large"} onClick={onClose}>
					Keep
				</DialogButton>
				<DialogButton size={"large"} onClick={onDelete} id={deleteBtnId}>
					Delete
				</DialogButton>
			</DeleteAlbumFooter>
		</Dialog>
	);
};

export default ConfirmDelete;
