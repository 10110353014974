import React, { useCallback, useEffect, useMemo, useState } from "react";

import firebase from "firebase";
import { DateTime } from "luxon";

import { useHistory } from "react-router-dom";

import { useFirebaseLiveConversation, useLiveConversation } from "modules/LiveConversation/Data/hooks";
import { useMemberRoutes, useUser } from "shared/hooks";
import { EventModel } from "shared/types";
import { Box, Button } from "shared/ui-kit";

export enum ScreenTypes {
	eventdetail,
	initiateconvo
}

interface JoinButtonProps {
	event?: EventModel;
	screen: ScreenTypes;
	onFirebaseUpdate?: ({ startedAt, totalParticipants }: { startedAt?: string; totalParticipants?: number }) => void;
}

const JoinButton: React.FC<JoinButtonProps> = ({ event, screen, onFirebaseUpdate }) => {
	const { getActiveProfile, getData: getUserData } = useUser();
	const { user } = getUserData();

	const { watchConversation } = useFirebaseLiveConversation();
	const { setRoomId, setEvent, resetConvo, checkResetHelper, getData: getLiveConversationData } = useLiveConversation();
	const { roomId } = getLiveConversationData();

	const history = useHistory();
	const { liveConversationId, attending, interested, startTime, endTime, admins, personaId } = event || {};
	const [visible, setVisible] = useState<boolean>(false);
	const [started, setStarted] = useState<boolean>(false);

	const activeProfile = getActiveProfile(user);
	const { getMemberRoutesData } = useMemberRoutes();
	const { routes: memberRoutes } = getMemberRoutesData();

	const isAdmin = useMemo(
		() => (admins?.length ? admins.findIndex(a => a.personaId === activeProfile?.personaId) > -1 : false),
		[admins, activeProfile]
	);
	const isOwner = useMemo(() => personaId === activeProfile?.personaId, [personaId, activeProfile]);

	const mayVisible: boolean = useMemo(() => {
		const now = new Date();
		const start = new Date(startTime ? startTime.toString() : "");
		const end = new Date(endTime ? endTime.toString() : "");
		const isHappening = start < now && end > now;
		return !!(isHappening || interested || attending);
	}, [startTime, endTime, attending, interested]);

	const canStart = useMemo(() => isAdmin || isOwner, [isAdmin, isOwner]);
	const shouldStart = canStart && !started;

	const onConversationUpdated = useCallback(
		(snapshot: firebase.database.DataSnapshot) => {
			const data = snapshot.val();
			const isStarted = !!data;
			setStarted(isStarted);
			setVisible((isStarted && mayVisible) || canStart);
			onFirebaseUpdate?.({
				startedAt: data?.config?.startedAt,
				totalParticipants: data?.participants ? Object.keys(data.participants).length : 0
			});
		},
		[canStart, mayVisible, onFirebaseUpdate]
	);

	const removeListener = useMemo(
		() => watchConversation(`${liveConversationId}`, onConversationUpdated),
		[watchConversation, liveConversationId, onConversationUpdated]
	);

	useEffect(() => {
		if (!liveConversationId || (!mayVisible && !canStart)) return;
		// TODO: check for was speaker
		return () => {
			removeListener();
		};
	}, [liveConversationId, mayVisible, canStart, removeListener]);

	useEffect(() => {
		checkResetHelper();

		// Coment for now, because it breaks the logic when user tries to join conversation from event screen
		// return () => {
		// 	if (screen === ScreenTypes.initiateconvo) {
		// 		resetConvo(true);
		// 	}
		// };
	}, [screen, resetConvo, checkResetHelper]);

	const eventStarted =
		DateTime.fromISO(startTime?.toString() ?? "")
			.diff(DateTime.now())
			.as("minutes") <= (canStart ? 5 : 0);
	const eventEnded =
		DateTime.now()
			.diff(DateTime.fromISO(endTime?.toString() ?? ""))
			.as("minutes") > 0;

	const initiateConversation = useCallback(() => {
		setRoomId(liveConversationId || "");

		if (screen === ScreenTypes.initiateconvo)
			return history.replace(`${memberRoutes?.member.liveconversation.main.getPath()}/${event?.liveConversationId}`);
		if (event) {
			setEvent(event);
		}

		if (canStart) {
			setTimeout(() => {
				history.push(`${memberRoutes?.member.liveconversation.initiate.getPath()}/${liveConversationId}`);
			}, 50);
			return;
		}
		setTimeout(() => {
			history.push(`${memberRoutes?.member.liveconversation.main.getPath()}/${liveConversationId}`);
		}, 50);
	}, [canStart, event, history, liveConversationId, memberRoutes, screen, setEvent, setRoomId]);

	if (!visible || !eventStarted || eventEnded) return null;

	const alreadyJoined = !!roomId && screen !== ScreenTypes.initiateconvo;
	const isJoinedCurrent = liveConversationId === roomId;

	return alreadyJoined ? (
		<Box>{`${isJoinedCurrent ? "Joined" : "Joined other conversation"}`}</Box>
	) : (
		<Button onClick={initiateConversation}>{`${shouldStart ? "Start" : "Join"} Conversation`}</Button>
	);

	// return (
	// 	<Button onClick={initiateConversation}>
	// 		{isJoinedCurrent ? "Rejoin Conversation" : `${shouldStart ? "Start" : "Join"} Conversation`}
	// 	</Button>
	// );
};

export default JoinButton;
