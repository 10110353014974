import React, { memo, useCallback, useEffect, useMemo, useState } from "react";

import { PostBlockEventType } from "types";

import { useSmartBlock } from "shared/hooks";

import { WeblinkPreview } from "shared/types";

import ItemTemplate from "../ItemTemplate";

interface Props {
	url: string;
	id?: string;
	onEvent?: (eventType: PostBlockEventType, id: string) => void;
	isMarketing?: boolean;
}

const LinkAnchorBlock: React.FC<Props> = memo(({ url, id, onEvent, isMarketing }) => {
	const [linkData, setLinkData] = useState<WeblinkPreview>();
	const { grabUrlInfo } = useSmartBlock(isMarketing);

	const domain = useMemo(() => url.replace("http://", "").replace("https://", "").split(/[/?#]/)[0], [url]);

	useEffect(() => {
		grabUrlInfo(url).then(r => {
			setLinkData(r);
		});
	}, [grabUrlInfo, url]);

	const previewImg = useMemo(() => {
		return linkData?.cover && !linkData.cover.includes("data:image/svg+xml")
			? linkData?.cover
			: linkData?.favicons.length
			? linkData.favicons[0]
			: "";
	}, [linkData]);

	const openLink = useCallback(() => {
		window.open(url, "_blank");
	}, [url]);

	return (
		<ItemTemplate
			id={id}
			loading={!linkData}
			wrapperProps={{
				id,
				onEvent,
				onClick: openLink,
				"data-name": "linkpreview",
				"data-linkpreview": encodeURIComponent(JSON.stringify({ link: linkData?.url, thumbnail: linkData?.cover }))
			}}
			onClose={() => onEvent && id && onEvent(PostBlockEventType.delete, id)}
			coverUrl={previewImg}
			title={linkData?.title.toUpperCase() || domain.toUpperCase()}
			subtitle={linkData?.description || domain}
		/>
	);
});

export default LinkAnchorBlock;
