import React, { ReactNode } from "react";

import { ReactComponent as AddConnectionIcon } from "assets/icons/addConnection.svg";
import { ReactComponent as CalendarIcon } from "assets/icons/calendar.svg";
import { ReactComponent as GroupsIcon } from "assets/icons/groups.svg";
import { ReactComponent as DollarIcon } from "assets/icons/icon-dollar-linear.svg";
import { ReactComponent as GiftIcon } from "assets/icons/icon-gift.svg";
import { ReactComponent as AtIcon } from "assets/icons/icon-linear-at.svg";
import { ReactComponent as MicIcon } from "assets/icons/icon-microphone.svg";
import { ReactComponent as PeopleIcon } from "assets/icons/icon-people.svg";
import { ReactComponent as CrownIcon } from "assets/icons/iconCrownLinear.svg";
import { ReactComponent as RadarIcon } from "assets/icons/liveconvo/radar-live.svg";
import { ActivitySettingsType } from "modules/Activity/Data/types/ActivityModel";
import { Icon } from "shared/ui-kit";

export interface NotificationModel {
	title: string;
	description: string;
	type: ActivitySettingsType;
	icon: ReactNode;
	hideForNonWhitelabel?: boolean;
}

export const NOTIFICATION_SETTING: NotificationModel[] = [
	{
		title: "Comments & Reactions",
		description: "These are notifications for comments on your posts and replies to your comments.",
		icon: <Icon fill="#C5CEE0" name="message-linear" />,
		type: ActivitySettingsType.commentsAndReactions
	},
	{
		title: "Tags & Mentions",
		description: "These are notifications for when someone tags or mentions you in a comment, post or story.",
		icon: <AtIcon />,
		type: ActivitySettingsType.tagsAndMentions
	},
	{
		title: "Reminders",
		description: "These are notifications to remind you of updates you may have missed.",
		icon: <Icon fill="#C5CEE0" name="bell-linear" />,
		type: ActivitySettingsType.reminders
	},
	{
		title: "Loyalty & Rewards",
		description: "These are notifications for receiving loyalty points, rewards,badges, etc.",
		icon: <GiftIcon />,
		type: ActivitySettingsType.loyaltyAndRewards,
		hideForNonWhitelabel: true
	},
	{
		title: "Connection’s Activities",
		description: "These are notifications about your connections’ activities: Posting, sharing a story and more.",
		icon: <PeopleIcon />,
		type: ActivitySettingsType.connectionsActivities
	},
	{
		title: "Connection Requests",
		description: "Notifications when someone sends you a connection request or accepts your request.",
		icon: <AddConnectionIcon />,
		type: ActivitySettingsType.connectionRequests
	},
	{
		title: "Group",
		description: "These are notifications about activity in Groups you've joined.",
		icon: <GroupsIcon />,
		type: ActivitySettingsType.groups
	},
	{
		title: "Lives",
		description: "These are notifications about Live Conversations.",
		icon: <RadarIcon />,
		type: ActivitySettingsType.lives
	},
	{
		title: "Events",
		description: "These are notifications about Events.",
		icon: <CalendarIcon />,
		type: ActivitySettingsType.events
	},
	{
		title: "Groups you manage",
		description: "These are notifications for groups you are the admin of.",
		icon: <CrownIcon />,
		type: ActivitySettingsType.groupsYouManage
	},
	{
		title: "Shows & Podcasts.",
		description: "These are notifications about activity in shows and podcasts you follow.",
		icon: <MicIcon />,
		type: ActivitySettingsType.showsAndPodcasts
	},
	{
		title: "Transactions",
		description: "These are notifications about Payments.",
		icon: <DollarIcon />,
		type: ActivitySettingsType.transactions
	},
	{
		title: "Messages",
		description: "These are notifications about all direct and group Messages & Conversation.",
		icon: <Icon fill="#C5CEE0" name="message-linear" />,
		type: ActivitySettingsType.messages
	}
];
