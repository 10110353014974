import React, { useCallback, useEffect, useMemo, useState } from "react";

import { IconButton, Switch } from "@material-ui/core";
import { DateTime } from "luxon";

import { PageTemplate } from "modules/Manage/View/Components";
import { useCategories } from "modules/Marketplace/Data";
import useCompanies from "modules/Marketplace/Data/hooks/useCompanies";
import { MenuDots, PlaceholderImage, TableWrapper } from "shared/Components";
import { TableColumnLoaderType } from "shared/Components/NewTable/Components";
import { Cell } from "shared/Components/NewTable/style";
import { useDebounce } from "shared/hooks";
import { PlaceholderImageType } from "shared/types";
import { Icon } from "shared/ui-kit";
import { formatCount } from "utils/serviceUtils/helpers";

import CreateEditCompanyDialog from "../../Components/ManageCompanies/CreateEditCompanyDialog";
import DeleteCompanyDialog from "../../Components/ManageCompanies/DeleteCompanyDialog";

const Table = React.lazy(() => import("shared/Components/NewTable"));

const ManageCompanies = () => {
	const [searchText, setSearchText] = useState("");

	const {
		getCompanies,
		setCategoriesPerPage,
		setPage,
		setCreateUpdateCompanyOpen,
		setDeleteDialog,
		togglePinned,
		toggleActive,
		getCompaniesCount,
		getData: getCompaniesData
	} = useCompanies();
	const { isLoading, companies, companiesCount, companiesPerPage, page } = getCompaniesData();

	const { getCategories } = useCategories();

	const debouncedSearchText = useDebounce(searchText, 400);

	useEffect(() => {
		getCategories({ offset: 1 });
	}, [getCategories]);

	useEffect(() => {
		getCompanies({ limit: companiesPerPage, offset: page, keyword: debouncedSearchText });
	}, [companiesPerPage, debouncedSearchText, getCompanies, page]);

	useEffect(() => {
		getCompaniesCount();
	}, [getCompaniesCount]);

	const handleSearch = useCallback((keyword: string) => {
		if (keyword === "" || keyword.length > 2) {
			setSearchText(keyword);
		}
	}, []);

	const getOptions = useCallback(
		(rowData, rowIndex) => {
			const ar = [
				{
					name: "Edit",
					onClick: () =>
						setCreateUpdateCompanyOpen({
							status: true,
							editObject: rowData
						}),
					value: 1,
					comp: "",
					id: `edit${rowIndex + 1}`
				},
				{
					name: "Delete",
					onClick: () => {
						setDeleteDialog({
							data: rowData
						});
					},
					value: 2,
					comp: "",
					id: `delete${rowIndex + 1}`
				}
			];

			return ar;
		},
		[setCreateUpdateCompanyOpen, setDeleteDialog]
	);

	const tableColumns = useMemo(
		() => [
			{
				minWidth: 220,
				alignment: "left",
				label: <Cell.HeaderText>{formatCount(companiesCount, "Company", "Companies")}</Cell.HeaderText>,
				Cell: ({ rowData: { name, photo } }) => (
					<Cell.Wrapper>
						<Cell.ImageWrapper>
							{photo ? (
								<Cell.Image src={photo} />
							) : (
								<PlaceholderImage
									type={PlaceholderImageType.WORKSPACE_SEARCH}
									width={40}
									height={40}
									viewBox={"0 0 400 400"}
								/>
							)}
						</Cell.ImageWrapper>
						<Cell.Wrapper className="column with-image">
							<Cell.Text>{name}</Cell.Text>
						</Cell.Wrapper>
					</Cell.Wrapper>
				),
				loaderTemplate: TableColumnLoaderType.imageWthOneTextRow,
				dataKey: "_id"
			},
			{
				width: 90,
				alignment: "left",
				label: <Cell.HeaderText>Featured</Cell.HeaderText>,
				Cell: ({ rowData: { _id, pinned } }) => (
					<Cell.Wrapper justifyContent="center">
						<IconButton
							onClick={() => togglePinned(_id, pinned)}
							size="small"
							style={{ backgroundColor: "transparent" }}
						>
							<Icon fill={pinned ? "#ffd044" : "#e4e9f2"} group="filled" height={24} name="star" width={24} />
						</IconButton>
					</Cell.Wrapper>
				),
				dataKey: "pinned"
			},
			{
				width: 170,
				alignment: "left",
				label: <Cell.HeaderText>Category</Cell.HeaderText>,
				Cell: ({ rowData: { categories } }) => {
					const cats = categories.map((cat, i) => (i === categories.length - 1 ? cat.name : `${cat.name}, `));
					return (
						<Cell.Wrapper>
							<Cell.Text>{cats}</Cell.Text>
						</Cell.Wrapper>
					);
				},
				dataKey: "categories"
			},
			{
				width: 100,
				alignment: "left",
				label: <Cell.HeaderText>Enabled</Cell.HeaderText>,
				Cell: ({ rowData: { _id, active } }) => (
					<Cell.Wrapper>
						<Switch onChange={e => toggleActive(_id, e.target.checked)} checked={active} />
					</Cell.Wrapper>
				),
				dataKey: "active"
			},
			{
				alignment: "left",
				width: 140,
				label: <Cell.HeaderText>Created Date</Cell.HeaderText>,
				Cell: ({ rowData: { createdAt } }) => (
					<Cell.Wrapper className="column">
						<Cell.Text>{DateTime.fromISO(createdAt).toFormat("MMM d, yyyy")}</Cell.Text>
						<Cell.Text className="light" variant="body2">
							{DateTime.fromISO(createdAt).toFormat("t")}
						</Cell.Text>
					</Cell.Wrapper>
				),
				loaderTemplate: TableColumnLoaderType.twoTextRows,
				dataKey: "createdAt"
			},
			{
				alignment: "right",
				label: "",
				width: 100,
				Cell: ({ rowData, rowIndex }) => (
					<MenuDots
						options={getOptions(rowData, rowIndex)}
						vertical
						removeBg
						removeshadow
						removeSideMargin
						menuId={`moreActions${rowIndex + 1}`}
					/>
				),
				loaderTemplate: TableColumnLoaderType.menuDots,
				dataKey: "menu"
			}
		],
		[companiesCount, getOptions, toggleActive, togglePinned]
	);

	const handleChangePage = useCallback(
		(newPage: number) => {
			setPage(newPage);
		},
		[setPage]
	);

	const handleChangeRowsPerPage = useCallback(
		(newPageSize: number) => {
			setCategoriesPerPage(newPageSize);
			setPage(1);
		},
		[setCategoriesPerPage, setPage]
	);

	const handleOpenDialog = () =>
		setCreateUpdateCompanyOpen({
			status: true
		});

	const filteredCompanies = useMemo(
		() => companies.filter((_, i) => i < companiesPerPage),
		[companies, companiesPerPage]
	);

	const TableBlock = useMemo(
		() => (
			<TableWrapper sizes={{ horizontalTablet: 900 }}>
				<Table
					columns={tableColumns}
					data={filteredCompanies || []}
					loading={isLoading}
					paginated
					totalDataCount={companiesCount || 10}
					page={page}
					pageSize={companiesPerPage}
					onChangePage={handleChangePage}
					onChangePageSize={handleChangeRowsPerPage}
				/>
			</TableWrapper>
		),
		[
			tableColumns,
			filteredCompanies,
			isLoading,
			companiesCount,
			page,
			companiesPerPage,
			handleChangePage,
			handleChangeRowsPerPage
		]
	);

	return (
		<>
			<PageTemplate
				title={"Companies"}
				isLoading={isLoading}
				isNoData={!filteredCompanies.length}
				emptyText={"You don’t have any Companies in your community yet."}
				searchPlaceholder={"Search Companies"}
				onSearchUpdate={handleSearch}
				actionText={"Create Company"}
				onCreateClick={handleOpenDialog}
				actionId="createCompany"
				searchId="searchCompanies"
			>
				{TableBlock}
			</PageTemplate>
			<DeleteCompanyDialog />
			<CreateEditCompanyDialog />
		</>
	);
};

export default ManageCompanies;
