import styled from "styled-components";

import { Button } from "shared/ui-kit";

const Wrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	padding-top: 108px;
	align-items: center;
`;

const TextWrapper = styled.div`
	margin-top: 40px;
	text-align: center;
`;

const Title = styled.div`
	font-size: 18px;
	font-weight: bold;
`;

const Text = styled.div`
	font-size: 15px;
	margin-top: 5px;
`;

const ReloadButton = styled(Button)`
	margin-top: 16px;
	width: 120px;
	height: 40px;
	border-radius: 8px;

	.MuiButton-label {
		font-weight: bold;
	}
`;

export { Wrapper, TextWrapper, Title, Text, ReloadButton };
