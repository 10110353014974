import React from "react";

import { Scrollbars } from "react-custom-scrollbars";
import { Route, Switch } from "react-router";

import IApp from "types/IApp";

import SecureRoute from "shared/services/AuthDecorator/SecureRoute";
import OnboardingLayout from "shared/templates/OnboardingLayout";
import wrapByLayout from "utils/wrapByLayout";

import LandingLayout from "./Layouts/LandingLayout";
import {
	BrokenLinkPage,
	CommunitiesPage,
	CreatePasswordPage,
	ForgotPassword,
	InvitationCode,
	Landing,
	ResetPasswordLink,
	ResetPasswordPage,
	SignIn,
	SignUp
} from "./Pages";
import { routes } from "./constants";

class AuthApp implements IApp {
	getRoutes = () => {
		return [
			<Route path={routes.auth.getPath()} key="auth-routes">
				<Scrollbars>
					<Switch>
						{this.getPublicRoutes()}
						{this.getSecuredRoutes()}
					</Switch>
				</Scrollbars>
			</Route>,
			<Route path={"/landing"} key="landing">
				<Route exact key={"landing"} path={""} component={wrapByLayout(LandingLayout)(Landing)} />,
			</Route>
		];
	};

	getPublicRoutes = () => [
		<Route
			exact
			key={routes.auth.signIn.getElementKey()}
			path={routes.auth.signIn.getPath()}
			component={wrapByLayout(LandingLayout)(SignIn)}
		/>,
		<Route
			exact
			key={routes.auth.signUp.getElementKey()}
			path={routes.auth.signUp.getPath()}
			component={wrapByLayout(OnboardingLayout)(SignUp)}
		/>,
		<Route
			exact
			key={routes.auth.invitationCode.getElementKey()}
			path={routes.auth.invitationCode.getPath()}
			component={wrapByLayout(LandingLayout)(InvitationCode)}
		/>,
		<Route
			exact
			key={routes.auth.forgotPassword.getElementKey()}
			path={routes.auth.forgotPassword.getPath()}
			component={wrapByLayout(OnboardingLayout)(ForgotPassword)}
		/>,
		<Route
			exact
			key={routes.auth.resetPassword.getElementKey()}
			path={routes.auth.resetPassword.getPath()}
			component={wrapByLayout(OnboardingLayout)(ResetPasswordPage)}
		/>,
		<Route
			exact
			key={routes.auth.resetPasswordLink.getElementKey()}
			path={routes.auth.resetPasswordLink.getPath()}
			component={wrapByLayout(OnboardingLayout)(ResetPasswordLink)}
		/>,
		<Route
			exact
			key={routes.auth.createPassword.getElementKey()}
			path={routes.auth.createPassword.getPath()}
			component={wrapByLayout(LandingLayout)(CreatePasswordPage)}
		/>,
		<Route
			exact
			key={routes.auth.brokenLink.getElementKey()}
			path={routes.auth.brokenLink.getPath()}
			component={wrapByLayout(LandingLayout)(BrokenLinkPage)}
		/>
	];

	getSecuredRoutes = () => {
		const routeList = [
			<Route
				exact
				key={routes.auth.communities.getElementKey()}
				path={routes.auth.communities.getPath()}
				component={wrapByLayout(LandingLayout)(CommunitiesPage)}
			/>
		];

		return <SecureRoute routes={routeList} />;
	};
}

export default AuthApp;
