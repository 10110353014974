import { DateTime } from "luxon";

import * as R from "ramda";

import { MediaModel } from "shared/types";

import { PaymentModel, PaymentRequestModel } from "../../types";

export const convertPaymentForRequest = (payment: PaymentModel, media: MediaModel | null): PaymentRequestModel => {
	const { hour, minute } = DateTime.fromJSDate(payment.dueTime as Date);

	return {
		...R.pick(["amountPaid", "purpose"], payment),
		artObj: media
			? {
					url: media.uri,
					mediaId: media._id
			  }
			: undefined,
		dueDate: payment.dueDate ? payment.dueDate : DateTime.fromJSDate(payment.dueTime).set({ hour, minute })
	};
};
