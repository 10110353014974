import React, { ReactNode, useMemo } from "react";

import { Avatar } from "@material-ui/core";

import { useLocation } from "react-router";
import { Link } from "react-router-dom";

import { routes } from "apps/RegularUser/constants";
import { DeletedItemTemplate } from "shared/Components";
import Close from "shared/Components/Blocks/Close";

import { useFeature, useMemberRoutes } from "shared/hooks";
import getCountMessage from "utils/getCountMessage";

import { Item, Places, Skelet, Wrapper } from "./styles";

const HideBlock = React.lazy(() => import("../HideBlock"));

interface Props {
	id?: string;
	isDeleted?: boolean;
	featureName?: string;
	loading?: boolean;
	wrapperProps: any;
	onClose: () => void;
	coverUrl?: string;
	previewIcon?: ReactNode;
	pretitle?: string;
	title?: string;
	subtitle?: string;
	memberList?: any[];
	totalMembers?: number;
	extraBlock?: ReactNode;
	placeholderImg?: ReactNode;
	link?: string;
	indicatorIcon?: JSX.Element;
}

const ItemTemplate: React.FC<Props> = ({
	id,
	featureName,
	wrapperProps,
	loading,
	onClose,
	coverUrl,
	previewIcon,
	pretitle,
	title,
	subtitle,
	memberList,
	totalMembers,
	extraBlock,
	placeholderImg,
	link,
	indicatorIcon,
	isDeleted = false
}) => {
	const location = useLocation();
	const { isFeatureEnabled } = useFeature();

	const { getMemberRoutesData } = useMemberRoutes();
	const { routes: memberRoutes } = getMemberRoutesData();

	const isMessagingView = useMemo(
		() =>
			location.pathname.startsWith(routes.messaging.getPath()) ||
			location.pathname.startsWith(memberRoutes?.member.messaging.getPath()),
		[location.pathname, memberRoutes?.member.messaging]
	);

	const optionalProps: Record<string, unknown> = {};
	if (link) {
		optionalProps.to = link;
		optionalProps.component = Link;
	}

	return featureName && !isFeatureEnabled(featureName) ? (
		<HideBlock />
	) : (
		<Wrapper
			messagingView={isMessagingView}
			{...wrapperProps}
			className={wrapperProps.messageBlockCurrentUser && "messageBlockCurrentUser"}
		>
			{id && !isMessagingView && !wrapperProps.postView && <Close onClick={onClose} />}
			<Item.Body>
				{loading ? (
					<Item.MainBlock>
						<Skelet.Avatar variant="rect" width={80} height={80} />
						<Item.EntityInfo>
							<Skelet.Text variant="text" width={200} height={15} />
							<Skelet.Text variant="text" width={200} height={15} />
							<Skelet.Text variant="text" width={200} height={15} />
						</Item.EntityInfo>
					</Item.MainBlock>
				) : isDeleted ? (
					<DeletedItemTemplate contentType={wrapperProps["data-name"] ? wrapperProps["data-name"] : "item"} />
				) : (
					<>
						<Item.MainBlock
							className={`${extraBlock && "part-size"} ${isMessagingView && "align-center"}`}
							{...optionalProps}
						>
							<Item.AvatarWrapper className={`${isMessagingView ? "messagingView" : ""}`}>
								{!isMessagingView && indicatorIcon && <Item.Type>{indicatorIcon}</Item.Type>}
								{!coverUrl && placeholderImg ? (
									placeholderImg
								) : (
									<Item.Avatar img={coverUrl}>{!coverUrl && (previewIcon || <Places />)}</Item.Avatar>
								)}
							</Item.AvatarWrapper>
							<Item.EntityInfo
								className={`${title && !pretitle && !subtitle && "center"} ${isMessagingView && "chatPadding"}`}
							>
								{pretitle && (
									<Item.Pretitle
										className={`${isMessagingView && "messaging-pretitle"} ${
											wrapperProps.messageBlockCurrentUser && "white-text"
										}`}
									>
										{pretitle}
									</Item.Pretitle>
								)}
								{title && (
									<Item.Title
										className={`${isMessagingView && "messaging-title"} ${
											wrapperProps.messageBlockCurrentUser && "white-text"
										}`}
									>
										{title}
									</Item.Title>
								)}
								{subtitle && (
									<Item.Subtitle
										className={`${isMessagingView && "messaging-subtitle"} ${
											wrapperProps.messageBlockCurrentUser && "gray-text"
										}`}
									>
										{subtitle}
									</Item.Subtitle>
								)}
								{!!memberList?.length && (
									<Item.Members>
										{memberList.slice(0, 5).map(m => (
											<>
												<Avatar className="member-avatar" key={m.bbUserId} /> &nbsp;
											</>
										))}
										<Item.MembersCounter>{getCountMessage(totalMembers || 0, "member")} here</Item.MembersCounter>
									</Item.Members>
								)}
							</Item.EntityInfo>
						</Item.MainBlock>
						{extraBlock}
					</>
				)}
			</Item.Body>
		</Wrapper>
	);
};

export default ItemTemplate;
