import React, { FC, useRef } from "react";

import LabeledInput, { LabelInputProps } from "../LabeledInput";

const ExpirationDateInput: FC<LabelInputProps> = ({ label, onChange, name, placeholder, value, rightIcon, error }) => {
	const inputRef = useRef<HTMLInputElement>(null);

	const handleChange = (e: KeyboardEvent) => {
		const { value } = e.target as HTMLInputElement;

		if (value.length === 6) return;

		let formattedValue = value.replace(/[^0-9]/g, "").replace("/", "");

		if (formattedValue.length >= 2) {
			formattedValue = `${formattedValue.substring(0, 2)}/${formattedValue.substring(2, formattedValue.length)}`;
		}

		if (inputRef.current) {
			onChange(formattedValue);
		}
	};

	return (
		<LabeledInput
			label={label}
			onChange={handleChange}
			name={name}
			placeholder={placeholder}
			value={value}
			rightIcon={rightIcon}
			inputRef={inputRef}
			maxLength={5}
			error={error}
		/>
	);
};

export default ExpirationDateInput;
