/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useMemo } from "react";

import { InputAdornment } from "@material-ui/core";

import config from "config/appConfig";
import * as R from "ramda";

import { Controller, SubmitHandler, useFieldArray, useForm } from "react-hook-form";

import { useHistory } from "react-router-dom";

import { ReactComponent as CoinYellowIcon } from "assets/icons/iconCoinYellow.svg";

import ConfirmLeavePopup from "shared/Components/ConfirmLeave";
import useConfirmLeavePage from "shared/Components/ConfirmLeave/hooks/useConfirmLeavePage";
import MonetizationSkeleton from "shared/Components/TableSkeletons/MonetizationSkeleton";
import { useCommunity } from "shared/hooks";
import { WorkspaceProductModel, WorkspaceProductPayloadType } from "shared/types";

import { MonetizationPlanSubscriptionId } from "shared/types/MonetizationPlansType";
import { Icon, Loader, Text } from "shared/ui-kit";

import {
	AddMore,
	AddMoreTags,
	BagCard,
	BagImage,
	CancelButton,
	CoinsPrices,
	CreatePriceTagHead,
	CreatePriceTagInfo,
	CreateTagsBtn,
	FlexContainer,
	HeaderInfo,
	IconRow,
	InfoBox,
	InfoContainer,
	MainVidContainer,
	PriceTagHeader,
	PriceTagInput,
	StyledCoinYellowIcon,
	StyledMonetizationActionsContainer,
	TableContainer,
	TableGridContainer,
	UpdateCoinButton,
	VideoInfo
} from "./style";

export interface FormDataType {
	name: string;
	price?: number;
	type: string;
	subscriptionId: string;
	sku: string;
}

type FormValues = {
	coins: WorkspaceProductModel[];
};

const FIXED_TAGS = ["vyoo_coin_large_bag", "vyoo_coin_big_bag", "vyoo_coin_small_bag", "vyoo_coin_tiny_bag"];

export interface PremiumOnlyPanelProps {
	subscriptionAndPremium?: boolean;
	generalSettingsCoinBlockRoute: string;
}

export const PremiumOnlyPanel: React.FC<PremiumOnlyPanelProps> = ({
	subscriptionAndPremium,
	generalSettingsCoinBlockRoute
}) => {
	const { updatePremiumProduct, getDefaultPremiumProducts, getCoinsList, getData: getCommunityData } = useCommunity();
	const { monetizationPlans, isLoading, workspace, defaultTags, coins, bags } = getCommunityData();

	const history = useHistory();

	const { formState, watch, trigger, control, handleSubmit, register, reset, setValue, errors } = useForm<FormValues>({
		mode: "onChange"
	});

	const { fields, append, remove } = useFieldArray({ name: "coins", control });

	const coinValue = useMemo(() => workspace?.coinValue || 0.99, [workspace]);

	const subscriptionPlan = useMemo(
		() =>
			monetizationPlans?.find(x =>
				subscriptionAndPremium
					? x.subscriptionId === MonetizationPlanSubscriptionId.SUBSCRIPTION_PREMIUM_ONLY
					: x.subscriptionId === MonetizationPlanSubscriptionId.PREMIUM_ONLY
			),
		[monetizationPlans, subscriptionAndPremium]
	);

	const priceTagTemplate: FormDataType = useMemo(
		() => ({
			name: "",
			type: "premium",
			subscriptionId: subscriptionPlan ? subscriptionPlan.subscriptionId : "",
			sku: ""
		}),
		[subscriptionPlan]
	);

	const formProducts = watch("coins");

	const productsLength = useMemo(() => {
		return formProducts?.length;
	}, [formProducts]);

	useEffect(() => {
		getCoinsList();
	}, [getDefaultPremiumProducts, getCoinsList]);

	useEffect(() => {
		if (productsLength) {
			trigger([`coins[${productsLength - 1}].name`, `coins[${productsLength - 1}].coins`]);
		}
	}, [productsLength, trigger]);

	useEffect(() => {
		reset({ coins: [...coins].sort((a, b) => ((a?.price || 0) < (b?.price || 0) ? 1 : -1)) || [] });
	}, [coins, reset]);

	const renderBags = () => {
		if (!bags.length) return null;

		return bags
			.sort((a, b) => a.price - b.price)
			.map((bag, i) => (
				<BagCard key={i} className="price-block">
					<BagImage src={bag.image} />
					<Text variant="h2">{bag.name}</Text>
					<Text variant="subtitle1">${bag.price}</Text>
				</BagCard>
			));
	};

	const RenderRows = (p, i: number) => {
		const { sku, name, id, coins: tagCoins, _id } = p;
		const isPriceAndCoinsReadOnly = FIXED_TAGS.includes(sku);
		const rest = R.omit(["id", "name", "coins", "price"], p);

		const productPrice = parseFloat(watch(`coins[${i}].price`));

		const userReceives =
			!!productPrice && !isNaN(productPrice)
				? (
						productPrice -
						((productPrice * (workspace?.appleServiceFees || 0)) / 100 +
							(productPrice * (workspace?.vyooServiceFees || 0)) / 100)
				  ).toFixed(2)
				: 0;

		return (
			<TableGridContainer container alignItems="center" key={id}>
				{[...Object.keys(rest)].map(k => (
					<input key={`${i}_${k}`} type="hidden" defaultValue={rest[k]} name={`coins[${i}].${k}`} ref={register()} />
				))}
				<PriceTagInput
					name={`coins[${i}].name`}
					inputRef={register({ required: "Name is required!" })}
					defaultValue={name}
					placeholder="Name"
					variant="outlined"
					error={errors[`coins[${i}].name`]}
					helperText={errors[`coins[${i}].name`] ? errors[`coins[${i}].name`].message || "Invalid Name" : ""}
					primaryInput
					id={`priceTagName${i + 1}`}
				/>
				<Controller
					name={`coins[${i}].coins`}
					control={control}
					disabled={isPriceAndCoinsReadOnly}
					defaultValue={tagCoins}
					placeholder="coins"
					rules={{ required: "Coins is required!", min: 1, validate: v => !isNaN(parseInt(v)) }}
					render={({ onChange, value }) => (
						<PriceTagInput
							id={`price${i + 1}`}
							disabled={isPriceAndCoinsReadOnly}
							variant="outlined"
							InputProps={{
								shrink: true,
								startAdornment: (
									<InputAdornment position="start">
										<CoinYellowIcon />
									</InputAdornment>
								)
							}}
							type="number"
							onChange={({ target }) => {
								const v = parseInt(target.value);

								setValue(`coins[${i}].price`, (v || 0) * coinValue);

								if (!_id) {
									setValue(`coins[${i}].sku`, `vc_post_unlock_${v}`);
								}

								onChange(v);
							}}
							value={value}
						/>
					)}
				/>
				<input type="hidden" name={`coins[${i}].price`} ref={register()} value={productPrice.toFixed(2)} />
				<PriceTagInput
					disabled
					value={productPrice.toFixed(2)}
					variant="outlined"
					InputProps={{
						startAdornment: <InputAdornment position="start">$</InputAdornment>
					}}
				/>
				<PriceTagInput
					disabled
					value={userReceives}
					InputProps={{
						startAdornment: <InputAdornment position="start">$</InputAdornment>
					}}
					variant="outlined"
				/>
				{coins?.length > 1 && (
					<IconRow id={`delete${i + 1}`}>
						<Icon name="trash" group={"filled"} fill="#c5cee0" onClick={() => remove(i)} />
					</IconRow>
				)}
			</TableGridContainer>
		);
	};

	const onSubmit: SubmitHandler<FormValues> = async ({ coins: data }) => {
		const newItems: WorkspaceProductPayloadType[] = data
			.filter(x => !x._id)
			.map(y => ({ ...R.pick(["coins", "sku", "price", "name"], y), removed: false, update: false }));

		const payload: WorkspaceProductPayloadType[] = coins.reduce((p, c) => {
			const i = data.find(d => d._id === c._id);

			if (!i) {
				p.push({ ...R.pick(["coins", "sku", "price", "name"], c), id: c._id, removed: !!c._id, update: false });
				return p;
			}

			if (c.name !== i.name || c.coins !== i.coins) {
				p.push({
					...R.pick(["coins", "name", "price"], i),
					...R.pick(["sku"], c),
					id: c._id,
					removed: false,
					update: true
				});
			}

			return p;
		}, [] as WorkspaceProductPayloadType[]);

		await updatePremiumProduct({ data: [...payload, ...newItems] }, false);
	};

	const {
		handleLeavePageConfirmed,
		closeConfirmPopup,
		getData: getConfirmLeavePopupData
	} = useConfirmLeavePage({
		valuesToCheck: formState.isDirty
	});
	const { showConfirmPopup } = getConfirmLeavePopupData();

	return (
		<>
			<ConfirmLeavePopup
				handleLeavePage={handleLeavePageConfirmed}
				open={showConfirmPopup}
				onClose={closeConfirmPopup}
			/>
			<form onSubmit={handleSubmit(onSubmit)}>
				<InfoContainer>
					<FlexContainer>
						<HeaderInfo>How it works</HeaderInfo>
						<Icon name="info-circle" group={"filled"} fill="#8f9bb3" />
					</FlexContainer>
					<MainVidContainer>
						<InfoBox>
							<VideoInfo>
								Monetising by using premium content will allow you to lock the content posted and will require users to
								purchase them in order to unlock and view your content. In order for community members to unlock premium
								content, they will need to purchase coin bundles and use some of the coins to unlock the posts based on
								the price tags you’ve selected . By default your community Coins will be{" "}
								{config.GLOBAL_CONSTANTS.ENV_APPLICATION_NAME} Coins and you can change the name and the look of the
								coins to best reflect your brand and community.
							</VideoInfo>

							<UpdateCoinButton
								palette="basic"
								buttonTheme="light"
								onClick={() => history.push(generalSettingsCoinBlockRoute)}
								id="updateCoins"
							>
								Update Coins & Bundles
							</UpdateCoinButton>
						</InfoBox>
						<CoinsPrices>{renderBags()}</CoinsPrices>
					</MainVidContainer>
				</InfoContainer>
				<CreatePriceTagHead>Create Price Tags</CreatePriceTagHead>
				<CreatePriceTagInfo>
					You can create price tags to tag your content with different pricing. 1{" "}
					{config.GLOBAL_CONSTANTS.ENV_APPLICATION_NAME}Coin <StyledCoinYellowIcon /> is equivalent to $0.99!
				</CreatePriceTagInfo>
				<TableContainer>
					<TableGridContainer container>
						<PriceTagHeader primaryInput>Price Tag Name</PriceTagHeader>
						<PriceTagHeader>Set Price</PriceTagHeader>
						<PriceTagHeader>Equivalent to</PriceTagHeader>
						<PriceTagHeader>You'll Receive</PriceTagHeader>
					</TableGridContainer>

					{!fields.length && isLoading ? (
						<MonetizationSkeleton />
					) : (
						fields.map((field, i) => RenderRows(field as any, i))
					)}
				</TableContainer>
				<StyledMonetizationActionsContainer>
					<div className="more-tag">
						{productsLength < 10 && (
							<AddMore
								onClick={() => {
									const prices = formProducts.map(p => p.price);

									const uniquePrice: number = defaultTags?.find(tag => !prices.includes(tag.price))?.price || 0;
									append({
										...priceTagTemplate,
										price: uniquePrice,
										coins: Math.round(uniquePrice / coinValue),
										sku: `vc_post_unlock_${uniquePrice}`,
										new: true
									});
								}}
								id="addMoreTags"
							>
								<Icon name="plus-oval" group={"color"} />
								<AddMoreTags>Add More Tags</AddMoreTags>
							</AddMore>
						)}
					</div>
					<div className="actions">
						<CancelButton
							onClick={() => reset({ coins })}
							disabled={!formState.isDirty || isLoading}
							buttonTheme="outline"
							type="reset"
							size="large"
							palette="control"
							id="reset"
						>
							Reset
						</CancelButton>
						<CreateTagsBtn
							disabled={!formState.isDirty || isLoading || !!Object.keys(errors).length}
							type="submit"
							size="large"
							leftIcon={
								isLoading ? <Loader size="1rem" show={true} color="inherit" variant="indeterminate" /> : undefined
							}
							id="saveChanges"
						>
							Save Changes
						</CreateTagsBtn>
					</div>
				</StyledMonetizationActionsContainer>
			</form>
		</>
	);
};

export default PremiumOnlyPanel;
