import { GetBadgeResponseModel } from "shared/types";
import { IStateProps } from "utils/StateOf";

import createStore from "utils/createStore";

import { GetRule } from "../../types/RulesType";

export interface BadgeRulesContextValues {
	loading: boolean;
	badges: GetBadgeResponseModel[];
	rules: GetRule[];
	createUpdateBadgeDialog: boolean;
	createUpdateRuleDialog: boolean;
	hasMoreBadges: boolean;
	hasMoreRules: boolean;
	createUpdateLoading: boolean;
}

export const initialState: IStateProps<BadgeRulesContextValues> = {
	loading: false,
	badges: [],
	rules: [],
	createUpdateBadgeDialog: false,
	createUpdateRuleDialog: false,
	hasMoreBadges: true,
	hasMoreRules: true,
	createUpdateLoading: false
};

export default createStore<BadgeRulesContextValues>({
	initialState
});
