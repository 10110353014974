import React from "react";

import { StyledWrapper } from "modules/MemberHome/View/shared/style";
import { SkeletonBase } from "shared/Components";

const FundraiserAboutLoader = () => {
	return (
		<>
			<StyledWrapper.HeaderInfoRow>
				<StyledWrapper.TypeWrapper>
					<SkeletonBase variant="circle" animation="wave" width={"18px"} height={"18px"} />
					<SkeletonBase className="ml-1 rounded-lg" variant="rect" animation="wave" width={"79px"} height={"18px"} />
				</StyledWrapper.TypeWrapper>
			</StyledWrapper.HeaderInfoRow>
			<SkeletonBase className="mt-2 rounded-lg" variant="text" animation="wave" width={"100%"} height={"32px"} />
			<div className="details-row">
				<div className="details-item mt-1">
					<SkeletonBase variant="circle" animation="wave" width={"40px"} height={"40px"} />
					<div>
						<SkeletonBase className="ml-4 rounded-lg" variant="text" animation="wave" width={"215px"} height={"24px"} />
					</div>
				</div>
			</div>
			<StyledWrapper.MembersBlock className="mb-2">
				<SkeletonBase className="rounded-lg" variant="rect" animation="wave" width={"154px"} height={"24px"} />
			</StyledWrapper.MembersBlock>
			<SkeletonBase className="mt-3 rounded-lg" variant="text" animation="wave" width={"192px"} height={"32px"} />
			<SkeletonBase className="mt-2 rounded-lg" variant="text" animation="wave" width={"100%"} height={"88px"} />
		</>
	);
};

export default FundraiserAboutLoader;
