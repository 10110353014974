import { Box } from "@material-ui/core";
import config from "config/appConfig";
import styled, { css } from "styled-components";

import PeaceIcon from "assets/icons/peaceIcon.svg";
import SelfieExample from "assets/images/selfieExample.jpg";
import { Button, Text } from "shared/ui-kit";

export const FormBase = styled.form`
	border-radius: 12px;
	height: fit-content;
	padding: 47px 40px;
	background-color: #fff;
	width: 480px;
	position: relative;
	box-shadow: inset 0 0 0 0.5px #c5cee0, 0 2px 4px 0 rgba(0, 0, 0, 0.08);
	margin: 88px auto 80px auto;

	${props => props.theme.breakpoints.down("xs")} {
		margin: 40px 8px 8px;
		width: 100%;
	}

	&.without-logo {
		${props => props.theme.breakpoints.down("xs")} {
			margin: 8px;
			width: 100%;
		}
	}
`;

FormBase.ErrorText = styled.p`
	position: absolute;
	top: calc(100% + 4px);
	left: 0;
	width: 100%;
	text-align: center;
	font-size: 12px;
	color: red;
`;

FormBase.WorkspaceLogoWrapper = styled(Box)`
	top: -35px;
	left: calc(50% - 35px);
	z-index: 1;
	position: absolute;
	width: 70px;
	height: 70px;
	overflow: hidden;
	background-repeat: no-repeat;
	background-color: #fff;
	background-position: center;
	background-size: cover;
	border-radius: 50%;
	box-shadow: inset 0 0 0 1px #fff, 0 0 0 0.5px #c5cee0;
`;

FormBase.WelcomeText = styled(Text)`
	font-weight: 600;
	margin-bottom: 2px;
	line-height: 1.6;
	color: #6173fe;
	font-size: 15px;
	letter-spacing: normal;
	text-align: center;
`;

FormBase.Title = styled(Text)`
	margin: 0 auto;
	color: #222b45;
	font-weight: bold;
	font-size: 22px;
	line-height: 1.45;
	letter-spacing: normal;
	text-align: center;

	&.verify-account {
		padding: 0px 50px;
	}

	.alert {
		color: #ff5b57;
		font-weight: inherit;
		font-size: inherit;
	}
`;

FormBase.Label = styled(Text)`
	font-size: 13px;
	font-weight: 600;
	line-height: 1.85;
	color: #222b45;
	margin-bottom: 2px;
`;

FormBase.Subtitle = styled(Text)`
	max-width: 70%;
	margin: 8px auto 39px auto;
	color: #8f9bb3;
	font-size: 13px;
	font-style: normal;
	line-height: 1.38;
	letter-spacing: normal;
	text-align: center;

	${props => props.theme.breakpoints.down("xs")} {
		margin: 8px auto;
	}
`;

FormBase.AvatarUploaderWrapper = styled(Box)`
	margin: 25px auto 40px auto;
`;

FormBase.AvatarUploaderTitle = styled(Text)`
	color: #222b45;
	font-weight: 600;
	font-size: 13px;
	line-height: 1.85;
	letter-spacing: normal;
	text-align: center;
`;

FormBase.UploaderWrapper = styled(Box)`
	position: relative;
	> div {
		width: 120px !important;
		height: 120px !important;
		margin: 8px auto 22px auto;
	}

	.uploader-wrapper {
		width: 100%;
		height: 100%;

		& > div {
			width: 100%;
			height: 100%;
			border-radius: 50%;

			&.preview-wrapper {
				padding: 0;
				border: none;

				.item-description {
					display: none;
				}

				.item-img-section {
					width: 100%;
					height: 100%;
					padding: 0;

					img {
						width: 100%;
						height: 100%;

						object-fit: cover;
					}
				}
			}

			& > div {
				width: 100%;
				height: 100%;
				border-radius: 50%;
			}
		}

		.preview-section {
			border-radius: 0;

			.close {
				z-index: 5;
			}

			.item-img-section {
				border-radius: 50%;
				overflow: hidden;
			}
		}
	}

	.explorer-uploader {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0;

		.uploader-content {
			display: none;
		}
	}

	.label,
	.description {
		display: none;
	}
`;

FormBase.InfoForm = styled(Box)``;

FormBase.Row = styled(Box)`
	display: flex;
	align-items: flex-end;
	justify-content: space-between;

	&.sm-block {
		${props => props.theme.breakpoints.down("xs")} {
			display: block;
		}
	}
`;

FormBase.Column = styled(Box)`
	width: 47%;

	${props => props.theme.breakpoints.down("xs")} {
		width: 100%;
	}

	&.full {
		width: 100%;
	}

	&.three-col {
		width: 31%;
	}
`;

FormBase.Footer = styled(Box)`
	display: flex;
	align-items: center;
	justify-content: space-between;

	margin-top: 12px;

	&.welcome {
		margin-top: 54px;
	}
`;

FormBase.ActionBtn = styled(Button)`
	margin: 0;
	border-radius: 8px;
	min-height: 40px;
	min-width: 80px;

	&.active {
		${() => {
			if (config.GLOBAL_CONSTANTS.ENV_APPLICATION_NAME === "Akina") {
				return css`
					color: white;
					background-color: #a20080;

					&:disabled {
						color: #8f9bb3;
						background-color: #edf1f7;
					}
				`;
			}
		}}
	}

	& > span {
		font-weight: bold;
	}
`;

FormBase.SelfieExample = styled(Box)`
	width: 216px;
	height: 248px;
	margin: 0 auto;

	padding: 8px;
	border-radius: 8px;

	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);

	&:before {
		display: block;

		width: 100%;
		height: 100%;
		background: url(${SelfieExample}) top no-repeat;
		background-size: cover;
		content: "";
	}
`;

FormBase.Asterisk = styled.span`
	color: #a20080;
	font-weight: inherit;
	font-size: inherit;
`;

export const MotherhoodCard = styled(Box)`
	width: 100%;
	min-height: 64px;
	margin-bottom: 8px;

	padding: 8px 16px;
	background-color: #fff;

	border-radius: 12px;

	box-shadow: inset 0 0 0 0.5px #c5cee0, 0 2px 4px 0 rgba(0, 0, 0, 0.08);

	cursor: pointer;
	transition: background-color 0.25s linear, box-shadow 0.25s linear;

	&.active {
		background-color: rgba(255, 91, 87, 0.1);

		box-shadow: inset 0 0 0 1px rgb(255, 91, 87), 0 2px 4px 0 rgba(0, 0, 0, 0.08);
	}
`;

MotherhoodCard.Title = styled(Text)`
	color: #222b45;
	font-weight: 700;
	font-size: 15px;
	line-height: 24px;
`;

MotherhoodCard.Desc = styled(Text)`
	color: #222b45;
	font-size: 13px;
	line-height: 16px;
`;

export const SelfieForm = styled(Box)`
	position: fixed;

	top: 0;
	left: 0;

	z-index: 10000;
	width: 100%;

	min-width: 100vw;
	height: 100%;
	min-height: 100vh;
	background-color: black;

	opacity: 0;
	transition: opacity 0.25s linear;
	pointer-events: none;

	&.open {
		opacity: 1;
		pointer-events: all;
	}
`;

SelfieForm.VideoWrapper = styled(Box)`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-repeat: no-repeat;

	background-position: center;
	background-size: cover;

	video {
		width: 100%;
		height: 100%;

		${props => props.theme.breakpoints.down("xs")} {
			width: unset;
			height: 100vh;
		}

		&.hide {
			opacity: 0;
		}
	}

	canvas {
		width: 100%;
		visibility: hidden;
		height: 100%;
	}
`;

SelfieForm.InterfaceWrapper = styled(Box)`
	position: absolute;
	top: 0;
	left: 0;

	z-index: 2;
	width: 100%;
	height: 100%;
`;

SelfieForm.CloseWrapper = styled(Box)`
	position: absolute;
	top: 40px;
	right: 40px;
	display: flex;
	align-items: center;
	justify-content: center;

	width: 24px;
	height: 24px;
	cursor: pointer;
`;

SelfieForm.FaceText = styled(Text)`
	margin-top: 54px;
	color: white;
	font-size: 16px;
	line-height: 20px;
	text-align: center;
`;

SelfieForm.FaceWrapper = styled(Box)`
	width: 295px;
	height: 405px;

	${props => props.theme.breakpoints.down("xs")} {
		width: 120px;
		height: 200px;
	}

	margin: 16px auto 0 auto;
	background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='148' ry='203' stroke='white' stroke-width='15' stroke-dasharray='2 5' stroke-dashoffset='49' stroke-linecap='butt'/%3e%3c/svg%3e");
	border-radius: 50%;
`;

SelfieForm.ActionFooter = styled(Box)`
	position: absolute;
	right: 0;

	bottom: 40px;
	left: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-end;
`;

SelfieForm.VerifyingFooter = styled(Box)`
	position: absolute;
	right: 0;

	bottom: 170px;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
`;

SelfieForm.VerifiedFooter = styled(Box)`
	position: absolute;
	right: 0;

	bottom: 90px;
	left: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-end;
`;

SelfieForm.ShotBtn = styled(Box)`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 80px;
	height: 80px;

	margin-top: 34px;
	border: 2px solid white;
	border-radius: 50%;

	cursor: pointer;

	backdrop-filter: blur(8px);
`;

SelfieForm.PeaceText = styled(Text)`
	position: relative;
	max-width: 295px;
	color: white;
	font-size: 16px;
	line-height: 20px;
	text-align: center;

	${props => props.theme.breakpoints.down("xs")} {
		font-size: 13px;
	}

	.bold {
		font-weight: bold;
	}

	&.with-icon {
		&:before {
			position: absolute;
			top: -50px;
			left: -100px;
			width: 80px;
			height: 142px;
			background: url(${PeaceIcon}) top no-repeat;
			background-size: contain;
			content: "";

			${props => props.theme.breakpoints.down("xs")} {
				top: -142px;
				left: -8px;
			}
		}
	}
`;

SelfieForm.VerifiedFooterIconWrapper = styled(Box)`
	margin-bottom: 8px;
`;

SelfieForm.NextBtn = styled(Button)`
	margin: 24px 0 0 0;
	border-radius: 8px;
`;

export const AkinaRules = styled(Box)`
	margin-top: 32px;
`;

AkinaRules.Title = styled(Text)`
	color: #ff4a4e;
	font-weight: 700;
	font-size: 18px;
	line-height: 24px;
`;

AkinaRules.Subtitle = styled(Text)`
	margin-top: 8px;
	color: #222b45;
	font-weight: 700;
	font-size: 13px;
	line-height: 20px;
`;

AkinaRules.Text = styled(Text)`
	margin-top: 2px;
	color: #222b45;
	font-size: 13px;
	line-height: 20px;
`;
