import { Box, Link as MUILink } from "@material-ui/core";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

import { Text } from "shared/ui-kit";

const highlightedStyle = (color?: string) => {
	return css`
		background: ${color ? `${color}14` : "rgba(235, 236, 255, 0.5)"};

		&:before {
			content: "";

			position: absolute;
			top: 0;
			left: 0;

			height: 100%;
			width: 3px;

			background: ${color ? color : "#6173fe"};

			border-top-right-radius: 2px;
			border-bottom-right-radius: 2px;
		}
	`;
};

const hoverStyle = (color?: string) => {
	return css`
		&:hover {
			background: ${color ? `${color}0D` : "rgba(235, 236, 255, 0.3)"};
		}
	`;
};

export const Wrapper = styled(Box)`
	width: 302px;
	${({ stickyTop }) =>
		stickyTop
			? css`
					height: calc(100vh - ${stickyTop + "px"});
			  `
			: css`
					height: 92vh;
			  `};

	background-color: #ffffff;
	box-shadow: 0.5px 0 0 0 #c5cee0;
	overflow-y: auto;
	position: fixed;
	top: ${({ stickyTop }) => (stickyTop ? stickyTop + "px" : "64px")};
	left: 0;

	&.collapsed {
		width: 0;
	}

	${props => props.theme.breakpoints.down("sm")} {
		z-index: 2;
		position: fixed;
		top: 72px;
		height: calc(100vh - 72px);
		width: 264px;
	}
`;

export const GetStartedWrapper = styled(Box)<{ filled: boolean; highlighted: boolean }>`
	padding: ${({ filled }) => (filled ? 0 : "10px 24px 0 24px")};
	margin-bottom: ${({ filled }) => (filled ? 0 : "5px")};

	position: relative;

	${({ highlighted }) => highlighted && highlightedStyle()};
`;

export const ListWrapper = styled(Box)`
	width: 100%;
`;

export const NavLabel = styled(Box)``;
NavLabel.Container = styled(Box)<{ highlighted: boolean; color?: string }>`
	padding: 12px 24px;

	display: flex;
	align-items: center;

	position: relative;

	cursor: pointer;

	${({ highlighted, color }) => highlighted && highlightedStyle(color)};

	${({ color }) => hoverStyle(color)};

	${({ first }) =>
		first &&
		css`
			padding-top: 24px;
		`}
`;

NavLabel.IconWrapper = styled(Box)`
	width: 24px;

	display: flex;
	justify-content: center;
	align-items: center;

	margin-right: 16px;
`;

NavLabel.Title = styled(Text)<{ active: boolean; color?: boolean }>`
	font-size: 13px;
	font-weight: 600;
	color: ${({ active, color }) => (active ? color || "#6173fe" : "#222b45")};
`;

NavLabel.ArrowWrapper = styled(Box)<{ show?: boolean }>`
	width: 24px;
	height: 24px;

	display: flex;
	align-content: center;
	justify-content: center;

	position: absolute;

	top: 13px;
	right: 19px;

	transform-origin: center center;

	transform: rotate(-90deg);

	transition: transform 0.25s linear;

	svg {
		width: 16px;
	}

	${({ show }) =>
		show &&
		css`
			transform: rotate(0);
		`}
`;

export const SubMenu = styled(Box)<{ show?: boolean }>`
	width: 100%;
	max-height: 0;
	overflow: hidden;

	opacity: 0;

	transition: max-height 0.25s linear, opacity 0.25s linear;

	${({ show }) =>
		show &&
		css`
			opacity: 1;
			max-height: 550px;
		`}
`;

export const ChildNavLabel = styled(Box)``;

ChildNavLabel.Container = styled(Link)<{ highlighted: boolean; color?: string }>`
	padding: 16px 24px 15px 65px;
	display: flex;
	align-items: center;
	position: relative;
	cursor: pointer;
	text-decoration: none;

	${({ highlighted, color }) => highlighted && highlightedStyle(color)};

	${({ color }) => hoverStyle(color)};
`;

ChildNavLabel.Delimiter = styled(Box)`
	width: 9px;
	height: 2px;
	border-radius: 0.8px;

	background: #8f9bb3;

	margin-right: 16px;
`;

ChildNavLabel.Title = styled(Text)<{ highlighted: boolean; color?: string }>`
	font-size: 13px;
	font-weight: 600;
	color: ${({ highlighted, color }) => (highlighted ? color || "#6173fe" : "#222b45")};
`;

export const ExpansionLink = styled(Box)``;

export const MemberPanelBtnWrapper = styled(Box)`
	width: 100%;
	margin: 6px 0 12px 0;

	padding: 0 8px;
`;

export const MenuLink = styled(MUILink)`
	text-decoration: none;

	&:hover {
		text-decoration: none;
	}
`;
