import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";

import ReactHtmlParser from "react-html-parser";
import { ForumPostModel, LikeType, PostBlockEventType } from "types";

// import { ReactComponent as CopyIcon } from "assets/icons/copy.svg";
import { ReactComponent as DeleteIcon } from "assets/icons/icon-delete.svg";
import { ReactComponent as PencilIcon } from "assets/icons/pencil.svg";
import { ReactComponent as IconAlert } from "assets/icons/stories/icon-alert.svg";
import { MenuDots, MenuDotsOption, ReactionListDialog } from "shared/Components";
import ConfirmActionModal from "shared/Components/ConfirmActionModal";
import { emotionIcons } from "shared/constants";
import { useLongPress, useMemberRoutes, usePost, usePosts, useReportContent, useUser } from "shared/hooks";
import { EmotionIconType, MediaModel, ProfilePhotoSizes, ReportContentType } from "shared/types";

import { determineSkinTone } from "utils/determineSkinTone";

import { getResizedImage } from "utils/serviceUtils/cdnImages";

import { safelyParseJSON } from "utils/serviceUtils/helpers";

import { Cmt, ContentBlock, ReplyWrapper, Wrapper } from "./style";

import { CommentEditor, CommentSkeleton, Like, LoadMore, NewComment } from "../index";

const pageSize = 10;

export interface CommentProps {
	comment: ForumPostModel;
	subreply?: boolean;
	onEvent: ({
		eventType,
		id,
		content,
		extra,
		clbk
	}: {
		eventType: PostBlockEventType;
		id?: string;
		content?: string;
		extra?: any;
		clbk?: (post: ForumPostModel) => void;
	}) => void;
	onCommentOnReply?: ({
		toPid,
		toAuthorId,
		toAuthorName
	}: {
		toPid: string;
		toAuthorId: number;
		toAuthorName: string;
	}) => void;
}

const Comment: React.FC<CommentProps> = memo(({ comment, subreply = false, onEvent, onCommentOnReply }) => {
	const { reactOnPost, updateComment, loadPostComments } = usePost();

	const { deletePost } = usePosts();

	const { isPrivilegedRole, getData: getUserData } = useUser();
	const { user } = getUserData();

	const { getReportTypeOptions } = useReportContent();

	// const { showMessage } = useNotification();

	const ST = user?.profiles[0].skinTone;

	const reactionColor = determineSkinTone(ST || "");

	const anchorEl = useRef<HTMLElement | null>(null);

	const { getMemberRoutesData } = useMemberRoutes();
	const { routes } = getMemberRoutesData();

	const [commentReactionList, setCommentReactionList] = useState<LikeType[]>([]);
	const [showLikeDropdown, setShowLikeDropdown] = useState(false);
	const [displayReplyCount, setDisplayReplyCount] = useState(0);
	const [totalReplyCount, setTotalReplyCount] = useState(0);
	const [replyList, setReplyList] = useState<ForumPostModel[]>([]);
	const [nextCommentList, setNextCommentList] = useState(comment?.replies || []);
	const [totalLikeCount, setTotalLikeCount] = useState(0);
	const [isEditMode, setIsEditMode] = useState(false);
	const [loadingReplies, setLoadingReplies] = useState(false);
	const [loadingNewReply, setLoadingNewReply] = useState(false);
	const [removingCurrent, setRemovingCurrent] = useState(false);
	const [preventShowDropdown, setPreventShowDropdown] = useState(false);
	const [showReactionsList, setShowReactionsList] = useState(false);

	const [newReply, setNewReply] = useState<{ toPid: string; toAuthorId: number; toAuthorName: string }>();

	const [showConfirmDelete, setShowConfirmDelete] = useState(false);

	const reactLongPress = useLongPress({
		callback: () => {
			setShowLikeDropdown(true);
		},
		onLeave: () => {
			setShowLikeDropdown(false);
		},
		ms: 250,
		refresh: preventShowDropdown ? 1 : 0
	});

	const authorIcon = useMemo(() => {
		return comment.user.persona?.photos?.length
			? getResizedImage(comment.user.persona?.photos[0].profilePicture || "", ProfilePhotoSizes.size200x200)
			: undefined;
	}, [comment]);

	const currentUserImgUrl = useMemo(
		() =>
			getResizedImage(
				user?.profiles?.length && user.profiles[0]?.photos?.length
					? (user.profiles[0].photos[0] as MediaModel)?.profilePicture || ""
					: "",
				ProfilePhotoSizes.size50x50
			),
		[user]
	);

	const getReaction = useCallback((reactionValue: string): EmotionIconType | undefined => {
		return emotionIcons.find(x => reactionValue.startsWith(x.value));
	}, []);

	useEffect(() => {
		if (comment.replies) {
			setReplyList([...comment.replies]);
		}

		if (comment?.recentLikes?.length) {
			setCommentReactionList(comment.recentLikes);
		}

		if (comment?.totalLikes) {
			setTotalLikeCount(comment.totalLikes);
		}

		if (comment?.postcount) {
			setTotalReplyCount(comment.postcount);
		}
	}, [comment]);

	const userReaction = useMemo(() => {
		if (user) {
			const previousReaction = commentReactionList.find(x => x.personaId === user.activeProfile);
			return previousReaction?.reaction;
		}
	}, [commentReactionList, user]);

	const addReactToPost = useCallback(
		(reaction?: string) => {
			setShowLikeDropdown(false);
			setPreventShowDropdown(true);
			setTimeout(() => {
				setPreventShowDropdown(false);
			}, 50);

			reactOnPost(reaction ? (ST ? `${reaction}_${ST}` : reaction) : undefined, comment?.pid);

			if (reaction) {
				if (userReaction) {
					const index = commentReactionList.findIndex(x => x.personaId === Number(user?.activeProfile));
					if (index > -1) {
						commentReactionList.splice(index, 1);
					}
				} else {
					setTotalLikeCount(count => count + 1);
				}

				commentReactionList.unshift({
					personaId: Number(user?.activeProfile),
					reaction: ST ? `${reaction}_${ST}` : reaction
				});
			} else {
				const index = commentReactionList.findIndex(x => x.personaId === Number(user?.activeProfile));
				if (index > -1) {
					commentReactionList.splice(index, 1);
					setTotalLikeCount(count => count - 1);
				}
			}
			setCommentReactionList([...commentReactionList]);
		},
		[reactOnPost, ST, comment, commentReactionList, user, userReaction]
	);

	const loadMore = useCallback(async () => {
		setLoadingReplies(true);

		const page = Math.ceil((displayReplyCount + pageSize) / pageSize) || 1;
		const { comments, nextComments } = await loadPostComments({
			postId: `${comment.pid}`,
			offset: page,
			limit: pageSize,
			extraLimit: 3
		});
		if (page === 1) {
			setReplyList(comments);
		} else {
			setReplyList(replyList.concat(comments));
		}
		setDisplayReplyCount(displayReplyCount + pageSize);

		setNextCommentList(nextComments);

		setLoadingReplies(false);
	}, [displayReplyCount, replyList, loadPostComments, comment]);

	const menuOptions = useMemo(() => {
		let options: MenuDotsOption[] = [
			// TODO: implement https://vyoo-me.atlassian.net/browse/VM-7320
			// {
			// 	icon: <CopyIcon />,
			// 	name: "Copy Link to Comment",
			// 	onClick: () => {
			// 		showMessage("Link copied to clipboard.");
			// 	}
			// }
		];

		const isCreator = comment?.uid === user?.personaDocId;

		if (!isCreator && !isPrivilegedRole) {
			options.push({
				icon: <IconAlert />,
				name: "Report Comment",
				onClick: () => {},
				submenuOptions: getReportTypeOptions({
					reportType: ReportContentType.POST,
					reportContentId: `${comment?.pid}`,
					reportContentName: comment?.title,
					customCallback: () => {
						onEvent({ eventType: PostBlockEventType.report, id: comment!.pid });
					}
				})
			});
		}

		if (isCreator || isPrivilegedRole) {
			options = [
				...options,
				{
					icon: <PencilIcon />,
					name: "Edit Comment",
					onClick: () => {
						setIsEditMode(true);
					}
				},
				{
					icon: <DeleteIcon />,
					name: "Delete Comment",
					onClick: () => {
						setShowConfirmDelete(true);
					}
				}
			];
		}

		return options;
	}, [comment, user?.personaDocId, isPrivilegedRole, getReportTypeOptions, onEvent]);

	const cancelDelete = () => {
		setShowConfirmDelete(false);
	};

	const confirmDelete = async () => {
		setShowConfirmDelete(false);
		setRemovingCurrent(true);

		await deletePost({ id: `${comment!.pid}`, showNotification: true, contentType: "Comment" });

		setRemovingCurrent(false);

		onEvent({
			eventType: PostBlockEventType.delete,
			id: comment!.pid,
			extra: { totalReplies: comment?.totalReplies || 0 }
		});
	};

	const onUpdateComment = (content: string) => {
		setIsEditMode(false);
		onEvent({ eventType: PostBlockEventType.updateComment, id: comment.pid, content });
	};

	const onCreateReply = ({ content }: { content: string }) => {
		setLoadingNewReply(true);

		onEvent({
			eventType: PostBlockEventType.addComment,
			content,
			extra: newReply,
			clbk: (post: ForumPostModel) => {
				setLoadingNewReply(false);

				setReplyList(rpls => [post, ...rpls]);
				setTotalReplyCount(count => count + 1);
				setDisplayReplyCount(dc => dc + 1);
			}
		});

		setNewReply(undefined);
	};

	const cancelUpdate = () => {
		setIsEditMode(false);
	};

	const cancelNewComment = () => {
		setNewReply(undefined);
	};

	const handleReply = useCallback(
		({ toAuthorId, toAuthorName }: { toAuthorId: number; toAuthorName: string }) => {
			if (subreply) {
				onCommentOnReply &&
					onCommentOnReply({
						toPid: `${comment!.pid}`,
						toAuthorId: comment.user.persona.personaId,
						toAuthorName: `${comment.user.persona?.firstName} ${comment.user.persona?.lastName}`
					});
			} else {
				setNewReply({
					toPid: `${comment!.pid}`,
					toAuthorId: toAuthorId || comment.user.persona.personaId,
					toAuthorName: toAuthorName || `${comment.user.persona?.firstName} ${comment.user.persona?.lastName}`
				});
			}
		},
		[subreply, onCommentOnReply, comment]
	);

	const correctContent = useMemo(() => {
		let originalContent = comment.content;

		if (originalContent.includes("href=\"{'personaId':")) {
			const htmlObject = document.createElement("div");
			htmlObject.innerHTML = originalContent;
			const personLinks = htmlObject.getElementsByTagName("a");
			if (personLinks?.length) {
				const linkTag = personLinks[0];
				const linkData = linkTag.attributes["href"].value;
				const data: any = safelyParseJSON(linkData.replace(/'/gi, '"')) || {};
				if (data?.personaId) {
					personLinks[0].setAttribute("href", `${routes?.member.profile.getPath()}/${data.personaId}`);
					personLinks[0].setAttribute("target", "_blank");
				}
			}

			originalContent = htmlObject.innerHTML;
		}

		if (originalContent.includes("<video")) {
			const htmlObject = document.createElement("div");
			htmlObject.innerHTML = originalContent;
			const videos = htmlObject.getElementsByTagName("video");
			if (videos?.length) {
				const videoTag = videos[0];
				videoTag.setAttribute("controls", "");
			}

			originalContent = htmlObject.innerHTML;
		}

		return originalContent;
	}, [comment.content, routes?.member.profile]);

	const onShowLikeOptions = useCallback(() => {
		if (!preventShowDropdown) {
			setShowLikeDropdown(true);
		}
	}, [preventShowDropdown]);

	const onAddDefaultReaction = useCallback(() => {
		addReactToPost(!userReaction ? emotionIcons[0].value : undefined);
		setShowLikeDropdown(false);
	}, [userReaction, addReactToPost]);

	const handleRemoveReaction = useCallback(() => {
		addReactToPost();
		setShowReactionsList(false);
	}, [addReactToPost]);

	const onReplyEvent = useCallback(
		({
			eventType,
			id,
			content,
			extra
		}: {
			eventType: PostBlockEventType;
			id?: string;
			content?: string;
			extra?: any;
		}) => {
			if (eventType === PostBlockEventType.delete || eventType === PostBlockEventType.report) {
				setReplyList(rpls => rpls.filter(x => x.pid !== id));
				setTotalReplyCount(count => count - 1);

				onEvent({ eventType, id, extra: extra ? { ...extra, subreply: true } : undefined });
			}

			if (eventType === PostBlockEventType.updateComment) {
				updateComment(content, id);
				setReplyList(rpls =>
					rpls.map(x => (x.pid === id ? { ...x, content: content || "", timestamp: Date.now() } : x))
				);
			}
		},
		[updateComment, onEvent]
	);

	const RepliesBlock = useMemo(
		() => (
			<Cmt.RepliesBlock>
				{replyList.slice(0, displayReplyCount).map(reply => (
					<ReplyWrapper key={reply.pid}>
						<Comment comment={reply} subreply onEvent={onReplyEvent} onCommentOnReply={handleReply} />
					</ReplyWrapper>
				))}
			</Cmt.RepliesBlock>
		),
		[replyList, displayReplyCount, handleReply, onReplyEvent]
	);

	const LikeBlock = useMemo(
		() => (
			<>
				<Cmt.ManageBlockWrapper
					onMouseEnter={onShowLikeOptions}
					onClick={onAddDefaultReaction}
					{...reactLongPress}
					ref={anchorEl}
				>
					<Cmt.LikeList>
						<Cmt.ManageText>{userReaction ? getReaction(userReaction)?.label : "Like"}</Cmt.ManageText>
					</Cmt.LikeList>
					<Like
						reactionColor={reactionColor}
						show={showLikeDropdown && Boolean(anchorEl)}
						onSelect={addReactToPost}
						showChooseSkinTone
						anchorEl={anchorEl.current}
					/>
				</Cmt.ManageBlockWrapper>
				{!!commentReactionList.length && (
					<div className="flex" onClick={() => setShowReactionsList(true)}>
						<Cmt.LikeList>
							{commentReactionList.slice(0, 3).map((reaction, index) => (
								<Cmt.Reaction reactionColor={determineSkinTone(reaction.reaction)} key={index}>
									{getReaction(reaction.reaction)?.icon}
								</Cmt.Reaction>
							))}
						</Cmt.LikeList>
						<Cmt.ManageText>{totalLikeCount}</Cmt.ManageText>
					</div>
				)}
			</>
		),
		[
			onShowLikeOptions,
			addReactToPost,
			commentReactionList,
			getReaction,
			onAddDefaultReaction,
			reactLongPress,
			reactionColor,
			showLikeDropdown,
			userReaction,
			totalLikeCount,
			anchorEl
		]
	);

	if (removingCurrent) {
		return (
			<Cmt.CommentSkeletonWrapper>
				<CommentSkeleton reply={subreply} />
			</Cmt.CommentSkeletonWrapper>
		);
	}

	return (
		<>
			<Wrapper>
				<Cmt.AuthorImg className={subreply && "subreply"} style={{ backgroundImage: `url(${authorIcon})` }} />
				<ContentBlock className={subreply ? "subreply" : ""}>
					<Cmt.Body>
						<Cmt.InfoRow>
							<Cmt.Autor>
								{comment.user.persona?.firstName} {comment.user.persona?.lastName}
							</Cmt.Autor>
							{!isEditMode && (
								<Cmt.InfoRowManageBlock>
									<Cmt.PostDate
										date={comment.timestamp}
										live={false}
										formatter={(value, unit, suffix) =>
											value < 60 && unit === "second" ? "Just now" : `${value} ${unit} ${suffix}`
										}
									/>
									<Cmt.Options>
										<MenuDots
											options={menuOptions}
											size={{ extraSmall: true }}
											removeSideMargin
											removeBg
											removeshadow
											memberView
										/>
									</Cmt.Options>
								</Cmt.InfoRowManageBlock>
							)}
						</Cmt.InfoRow>
						<Cmt.Content>
							{!isEditMode ? (
								ReactHtmlParser(correctContent)
							) : (
								<CommentEditor
									editableContent={comment.content}
									onSubmit={onUpdateComment}
									removeSideMargin
									onCancel={cancelUpdate}
								/>
							)}
						</Cmt.Content>
					</Cmt.Body>
					{!isEditMode && (
						<Cmt.ManageRow>
							<Cmt.ManageBlockWrapper onClick={handleReply}>
								<Cmt.ManageText>Reply</Cmt.ManageText>
							</Cmt.ManageBlockWrapper>
							{LikeBlock}
						</Cmt.ManageRow>
					)}
					{newReply && (
						<Cmt.NewCommentWrapper>
							<NewComment
								avatarUrl={currentUserImgUrl}
								onPostNewComment={onCreateReply}
								replyTo={newReply}
								onCancel={cancelNewComment}
								subreply
							/>
						</Cmt.NewCommentWrapper>
					)}
					{loadingNewReply && (
						<Cmt.CommentSkeletonWrapper>
							<CommentSkeleton reply />
						</Cmt.CommentSkeletonWrapper>
					)}
					{!!replyList?.length && RepliesBlock}
					{loadingReplies && (
						<Cmt.CommentSkeletonWrapper>
							<CommentSkeleton reply />
						</Cmt.CommentSkeletonWrapper>
					)}
					{!subreply && !loadingReplies && totalReplyCount > displayReplyCount && (
						<LoadMore
							onLoadMore={loadMore}
							commentsList={nextCommentList}
							viewReplyCount={totalReplyCount - displayReplyCount > 10 ? 10 : totalReplyCount - displayReplyCount}
						/>
					)}
				</ContentBlock>
			</Wrapper>
			{showReactionsList && (
				<ReactionListDialog
					handleRemoveReaction={handleRemoveReaction}
					open={showReactionsList}
					onClose={() => setShowReactionsList(false)}
					pid={comment.pid}
				/>
			)}
			{showConfirmDelete && (
				<ConfirmActionModal
					open
					onClose={cancelDelete}
					title="Delete content?"
					bodyText={"Are you sure you want to delete this content?"}
					onConfirm={confirmDelete}
					closeText="Keep"
					confirmText="Delete"
					confirmButtonTheme="danger"
					cancelButtonTheme="primary"
				/>
			)}
		</>
	);
});

export default Comment;
