export const API_URLS = {
	GLOBAL: {
		GET_MEMBERS: "/globaluser/members",
		POST_VALIDATE_EMAIL_MEMBER: "/globaluser/email/validate",
		PUT_CANCEL_INVITE_MEMBER: "/globaluser/cancel/invite",
		POST_INVITE_USER: "/globaluser/invite",
		POST_RESEND_INVITE_MEMBER: "/globaluser/resend",
		POST_INVITE_MEMBER: "/globaluser/invite",
		POST_MEMBER_COUNT: "globaluser/members/count",
		GET_MEMBERS_TO_INVITE: "/nodebb/group/inviteSearch",
		COUNT_INVIETABLE_MEMBERS: "/nodebb/group/inviteSearch/count"
	},
	ADMIN: {
		GET_COMMUNITY_DETAILS: "/admin/workspace/detail",
		GET_USER_SEARCH: "/admin/dashboard/users/list/search",
		POST_MARK_ARCHIVE: "admin/dashboard/users/archive",
		PUT_EDIT_ROLE_MEMBER: "/nodebb/group/members"
	},
	USER: { DELETE_MEMBER: "/user/delete" },
	GROUP_CHAT: { GET_MEMBERS: "groupchat/v2/members/detail" },
	INVITATION: {
		CREATE_AND_GET: "/invitation/code",
		UPDATE: "/invitation/update",
		GET_ALL: "/invitation",
		DELETE: "invitation/delete"
	},
	CONTRIBUTORS: {
		GET_ALL: "/nodebb/boards/groups"
	}
};

export const INVITE = {
	DEFAULT: "default",
	SHAREABLE_LINK: "shareablelink",
	SHAREABLE_LINK_TITLE: "Create a Link",
	SHAREABLE_UPDATE_LINK_TITLE: "Update a Link",
	SHAREABLE_LINK_LIST: "shareableLinkList",
	SHAREABLE_LINK_LIST_TITLE: "Shareable Links",
	QR_CODE: "qrcode",
	QR_CODE_TITLE: "Create a QR code",
	QR_CODE_LIST: "qrcodeList",
	QR_CODE_VIEW: "viewQRCode",
	QR_CODE_LIST_TITLE: "QR codes",
	INVITE_VIA_EMAIL: "inviteViaEmail",
	INVITE_VIA_SHAREABLE_LINK: "inviteShareableLink",
	INVITE_VIA_QR_CODE: "inviteQRCode",
	CREATE_LINK: "Create a new link",
	CREATE_QR: "Create a new QR code",
	GENERATE_LINK: "Generate Link",
	GENERATE_QR: "Generate QR",
	COPY_LINK: "Copy link",
	DOWNLOAD: "Download",
	VIEW: "View",
	ACTIVATE: "Activate",
	SAVE_CHANGES: "Save Changes",
	DELETE_SHAREABLE_LINK: "Shareable Link",
	DELETE_QR_CODE: "QR code"
};
