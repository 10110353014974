import { useCallback, useMemo } from "react";

import { useReportStore } from "../contexts";

import { useReportApiService } from "../services";

const useReports = () => {
	const reportApiService = useReportApiService();

	const store = useReportStore();
	const { setState } = useReportStore();

	const methods = useMemo(
		() => ({
			async getReports({ limit, offset }) {
				setState({ loading: true });

				const { funds } = await reportApiService.getReports(offset, limit);
				if (funds) {
					setState({ reports: funds, loading: false });
				}

				return funds;
			},
			async getReportsCount() {
				const { totalCount } = await reportApiService.getReportsCount();
				setState({ totalCount });
			},
			setPage: async (page: number) => {
				setState({ page });
			},
			setReportsShowPerPage: async (reportShowPerPage: number) => {
				setState({ reportShowPerPage });
			}
		}),
		[setState, reportApiService]
	);

	const getData = useCallback(() => {
		return store;
	}, [store]);

	return { ...methods, getData };
};

export default useReports;
