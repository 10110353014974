import { TrackModel } from "shared/types";
import { IStateProps } from "utils/StateOf";

import createStore from "utils/createStore";

export interface trackModalType {
	open: boolean;
	model?: TrackModel;
	album?: { value: string; label: string };
	collection?: { value: string; label: string };
}

export interface Values {
	searchText?: string;
	tracks: TrackModel[] | null;
	filteredTracks: TrackModel[] | null;
	trackFileUrl: string | null;
	trackUploadUrl: string;
	trackCount: number;
	allTrackCount?: number;
	loading: boolean;
	offset: number;
	page: number;
	tracksShowPerPage: number;
	trackName?: string;
	creating: boolean;
	trackInfoPopup: trackModalType;
	refresh: number;
	trackDuration?: string;
	shouldStopSearching?: boolean;
}

export const initialState: IStateProps<Values> = {
	tracks: null,
	filteredTracks: null,
	trackUploadUrl: "",
	trackFileUrl: null,
	trackCount: 0,
	offset: 0,
	loading: false,
	page: 1,
	tracksShowPerPage: 20,
	creating: false,
	trackInfoPopup: {
		open: false
	},
	refresh: 0,
	shouldStopSearching: false
};

export default createStore<Values>({
	initialState
});
