import React, { memo, useCallback, useEffect, useMemo, useState } from "react";

import { DateTime } from "luxon";

import { CreateTrack, MenuDots, PlaceholderImage, TableWrapper } from "shared/Components";
import { TableColumnLoaderType } from "shared/Components/NewTable/Components";
import { Cell } from "shared/Components/NewTable/style";
import { useDebounce, useTrack } from "shared/hooks";
import { useS3Uploader } from "shared/services/s3Uploader/hooks";
import { PlaceholderImageType, TrackMeta, TrackModel, genres } from "shared/types";
import { dataUrlToFile, filterFiles, formatCount } from "utils/serviceUtils/helpers";

import { ConfirmDelete, PageTemplate } from "../../Components";

const correctFileTypes = ["audio/*"];

const Table = React.lazy(() => import("shared/Components/NewTable"));

const ManageTracks: React.FC = memo(() => {
	const {
		getTracks,
		deleteTrack,
		getTrackCount,
		setTrackFileUrl,
		setPage,
		setTracksShowPerPage,
		setTrackName,
		setTrackInfoPopup,
		setTrackDuration,
		getData: getTrackData
	} = useTrack();
	const { filteredTracks, loading, trackCount, trackUploadUrl, page, tracksShowPerPage, trackInfoPopup, refresh } =
		getTrackData();

	const { uploadFile, getData: getS3UploaderData } = useS3Uploader();
	const { uploading } = getS3UploaderData();

	const [searchText, setSearchText] = useState<string>("");
	const [open, setOpen] = useState(false);
	const [trackInfoToDelete, setTrackInfoToDelete] = useState<{ id: string | null; name: string }>({
		id: null,
		name: ""
	});

	const debouncedSearchTerm = useDebounce(searchText, 300);

	useEffect(() => {
		if (refresh) {
			setSearchText("");
		}
	}, [refresh]);

	const handleSearch = useCallback(
		(val: string) => {
			if (val === "" || val.length >= 3) {
				setSearchText(val);
				setPage(1);
			}
		},
		[setPage]
	);

	useEffect(() => {
		getTracks({ keyword: debouncedSearchTerm, limit: tracksShowPerPage, offset: page });
	}, [debouncedSearchTerm, getTracks, page, tracksShowPerPage, refresh]);

	useEffect(() => {
		getTrackCount({ keyword: debouncedSearchTerm });
	}, [debouncedSearchTerm, getTrackCount, refresh]);

	const getOptions = useCallback(
		(rowData: TrackModel) => [
			{
				name: "Edit",
				onClick: () => {
					setTrackInfoPopup({ open: true, model: rowData });
				},
				value: 1,
				comp: ""
			},
			{
				name: "Delete",
				onClick: () => {
					setTrackInfoToDelete({ id: rowData._id || null, name: rowData.title });
					setOpen(true);
				},
				value: 2,
				comp: ""
			}
		],
		[setTrackInfoPopup]
	);

	const tableColumns = useMemo(
		() => [
			{
				alignment: "left",
				width: 338,
				label: <Cell.HeaderText>{formatCount(trackCount, "Track")}</Cell.HeaderText>,
				Cell: ({ rowData: { title, meta } }: { rowData: TrackModel }) => {
					const icon = (meta as TrackMeta)?.artwork?.url;

					return (
						<Cell.Wrapper>
							<Cell.ImageWrapper>
								{icon ? (
									<Cell.Image src={icon} />
								) : (
									<PlaceholderImage
										type={PlaceholderImageType.TRACK_PREVIEW}
										width={40}
										height={40}
										viewBox={"0 0 400 400"}
									/>
								)}
							</Cell.ImageWrapper>
							<Cell.Wrapper className="column with-image">
								<Cell.Text>{title}</Cell.Text>
							</Cell.Wrapper>
						</Cell.Wrapper>
					);
				},
				loaderTemplate: TableColumnLoaderType.imageWthOneTextRow,
				dataKey: "content"
			},
			{
				alignment: "left",
				minWidth: 120,
				label: <Cell.HeaderText>Artist</Cell.HeaderText>,
				Cell: ({ rowData: { meta } }: { rowData: TrackModel }) => (
					<Cell.Wrapper>
						<Cell.Text>{(meta as TrackMeta).artist}</Cell.Text>
					</Cell.Wrapper>
				),
				dataKey: "date"
			},
			{
				alignment: "left",
				minWidth: 100,
				label: <Cell.HeaderText>Genre</Cell.HeaderText>,
				Cell: ({ rowData: { genre } }: { rowData: TrackModel }) => (
					<Cell.Wrapper>
						<Cell.Text>
							{genre?.length
								? genre
										.slice(0, 2)
										.map(x => genres.find(g => g.key === x)?.value || x)
										.join(", ")
								: "-"}
						</Cell.Text>
					</Cell.Wrapper>
				),
				dataKey: "reactions"
			},
			{
				alignment: "left",
				minWidth: 100,
				label: <Cell.HeaderText>Total Plays</Cell.HeaderText>,
				Cell: ({ rowData: { totalPlays } }: { rowData: TrackModel }) => (
					<Cell.Wrapper>
						<Cell.Text>{totalPlays}</Cell.Text>
					</Cell.Wrapper>
				),
				dataKey: "plays"
			},
			{
				alignment: "left",
				minWidth: 135,
				label: <Cell.HeaderText>Created Date</Cell.HeaderText>,
				Cell: ({ rowData: { createdAt } }: { rowData: TrackModel }) => {
					const correctDate = DateTime.fromISO(createdAt.toString());
					return (
						<Cell.Wrapper className="column">
							<Cell.Text>{correctDate.toFormat("MMM d, yyyy")}</Cell.Text>
							<Cell.Text className="light">{correctDate.toFormat("h:mm a")}</Cell.Text>
						</Cell.Wrapper>
					);
				},
				loaderTemplate: TableColumnLoaderType.twoTextRows,
				dataKey: "comments"
			},
			{
				alignment: "right",
				label: "",
				width: 100,
				Cell: ({ rowData }: { rowData: TrackModel }) => (
					<MenuDots options={getOptions(rowData)} vertical removeBg removeshadow removeSideMargin />
				),
				loaderTemplate: TableColumnLoaderType.menuDots,
				dataKey: "menu"
			}
		],
		[trackCount, getOptions]
	);

	const handleUpload = useCallback(() => {
		const el: any = document.getElementById("trackFileUrl");
		if (el) {
			el.value = null;
			el.click();
		}
	}, []);

	const onUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
		const { target } = e;
		const { files } = target;

		if (files) {
			setTrackFileUrl(null);
			const filteredFiles = filterFiles(files, correctFileTypes);

			const trackFile = !!filteredFiles?.length ? files[0] : null;

			if (trackFile) {
				const correctFile = typeof trackFile === "string" ? await dataUrlToFile(trackFile, "test") : trackFile;

				const audio = document.createElement("audio");
				const reader = new FileReader();

				reader.onload = e => {
					audio.src = (e.target?.result as string) || "";
					audio.addEventListener("loadedmetadata", () => {
						const duration = audio.duration;
						setTrackDuration(`${duration}`);
					});
				};

				reader.readAsDataURL(correctFile);

				uploadFile({
					file: correctFile as File,
					communityName: trackUploadUrl,
					checkProgress: true,
					customCallback: data => setTrackFileUrl(data?.publicUrl)
				});

				setTrackName(files && files[0].name.slice(0, files[0].name.lastIndexOf(".")));

				setTrackInfoPopup({ open: true, model: undefined });
			}
		}
	};

	const confirmPopupAction = useCallback(
		async (confirm: boolean) => {
			setOpen(false);
			if (confirm && trackInfoToDelete.id) {
				await deleteTrack(trackInfoToDelete.id);
				getTracks({ keyword: debouncedSearchTerm, limit: tracksShowPerPage, offset: page });
			}
		},
		[debouncedSearchTerm, deleteTrack, getTracks, page, trackInfoToDelete.id, tracksShowPerPage]
	);

	const handleChangeRowsPerPage = useCallback(
		(newPageSize: number) => {
			setTracksShowPerPage(newPageSize);
			setPage(1);
		},
		[setPage, setTracksShowPerPage]
	);

	const TableBlock = useMemo(
		() => (
			<TableWrapper sizes={{ horizontalTablet: 900 }}>
				<Table
					columns={tableColumns}
					data={filteredTracks || []}
					loading={loading}
					paginated
					totalDataCount={trackCount || 10}
					page={page}
					pageSize={tracksShowPerPage}
					onChangePage={setPage}
					onChangePageSize={handleChangeRowsPerPage}
				/>
			</TableWrapper>
		),
		[tableColumns, filteredTracks, loading, trackCount, page, tracksShowPerPage, setPage, handleChangeRowsPerPage]
	);

	return (
		<>
			<PageTemplate
				title={"Tracks"}
				showReportedLink
				isLoading={loading}
				isNoData={!filteredTracks || !filteredTracks.length}
				emptyText={"You don’t have any Tracks in your community yet."}
				searchPlaceholder={"Search Tracks"}
				onSearchUpdate={handleSearch}
				actionText={"Create Track"}
				onCreateClick={handleUpload}
				isDisabledCreateAction={uploading}
				isLoadingAction={uploading}
			>
				{TableBlock}
			</PageTemplate>

			<input
				id="trackFileUrl"
				type="file"
				onChange={onUpload}
				name="trackFileUrl"
				hidden
				accept={correctFileTypes.join(",")}
			/>
			{open && (
				<ConfirmDelete
					headline="Video"
					name={trackInfoToDelete?.name}
					onClose={() => confirmPopupAction(false)}
					onDelete={() => confirmPopupAction(true)}
				/>
			)}
			{trackInfoPopup.open && (
				<CreateTrack
					open
					editableModel={trackInfoPopup.model}
					onClose={() => {
						setTrackInfoPopup({ open: false });
						setTrackDuration();
					}}
				/>
			)}
		</>
	);
});

export default ManageTracks;
