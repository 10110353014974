import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";

import { SeriesCollection } from "modules/Manage/Data/types";
import { useCategories } from "modules/Marketplace/Data/hooks";

import { CategoryProps } from "modules/Marketplace/Data/types";
import { SetupPrice } from "shared/Components";
import { useCommunity, useMedia, useSeries, useUser } from "shared/hooks";
import { CategorySort, MediaType } from "shared/types";

import CreateSeriesManageView from "./ManageView";
import CreateSeriesMemberView from "./MemberView";

const CreateUpdateSeriesDialog: FC<{
	groupId?: string;
	eventId?: string;
	onUpdate?: (updatedCollection?: SeriesCollection, isCreateUpdate?: boolean) => void;
}> = ({ groupId, eventId, onUpdate }) => {
	const [uploadingCover, setUploadingCover] = useState(false);

	const { getData: getUserData } = useUser();
	const { isMemberView } = getUserData();

	const { getCategories } = useCategories();

	const { createCollection, updateCollection, setSeriesDetailsDialog, getData: getSeriesData } = useSeries();
	const { createEditSeriesDialog } = getSeriesData();

	const { uploadFiles } = useMedia();

	const { getData: getCommunityData } = useCommunity();
	const { isFree } = getCommunityData();

	const [suggestedCategories, setSuggestedCategories] = useState<CategoryProps[]>([]);

	type SetupPriceMethodsHandlers = React.ElementRef<typeof SetupPrice>;
	const setupPriceFormRef = useRef<SetupPriceMethodsHandlers>(null);

	const loadSuggestedCategories = useCallback(
		async (name: string) => {
			const { categories } = await getCategories({
				limit: 20,
				offset: 1,
				keyword: name,
				sortBy: CategorySort.name,
				order: 1
			});

			setSuggestedCategories(categories);
		},
		[getCategories]
	);

	const editModel = useMemo(() => {
		const modelInfo = createEditSeriesDialog.data;
		if (modelInfo && modelInfo.meta.scheduleDate && new Date(modelInfo.meta.scheduleDate) < new Date()) {
			modelInfo.meta.scheduleDate = "";
		}
		return modelInfo;
	}, [createEditSeriesDialog.data]);

	useEffect(() => {
		if (!suggestedCategories.length) {
			loadSuggestedCategories("");
		}
	}, [loadSuggestedCategories, suggestedCategories.length]);

	const onUploaderChange = useCallback(
		async (files: FileList, onChange: (...event: any[]) => void) => {
			setUploadingCover(true);

			const data = await uploadFiles({ files, type: MediaType.seriesPhoto });

			data[0]
				? onChange({
						mediaId: data[0]._id,
						url: data[0].uri
				  })
				: onChange(null);

			setUploadingCover(false);
		},
		[uploadFiles]
	);

	const onSubmit = async data => {
		const meta = {
			artwork: data.artwork
				? data.artwork
				: {
						mediaId: null,
						url: null
				  },
			scheduleDate: data.scheduleDate,
			canBeDownloaded: data.downloadable,
			private: data.private,
			individualAccess: data.individualAccess
		};

		delete data.schedule;
		delete data.individualAccess;

		const priceTags = setupPriceFormRef.current?.getPriceTags();

		const newCollectionData = {
			...data,
			title: data.title,
			description: data.description,
			meta,
			priceTags,
			groupId,
			eventId,
			category: isMemberView ? { name: data.category.label } : { name: data.category.name }
		};

		if (editModel) {
			const updateCollectionData = {
				...newCollectionData,
				meta: {
					...newCollectionData.meta,
					artwork:
						typeof newCollectionData.meta.artwork === "string" ? editModel.meta.artwork : newCollectionData.meta.artwork
				},
				priceTags,
				category: !data.category ? createEditSeriesDialog.data?.category : data.category,
				itemIds: editModel.itemIds.map(item => ({
					_id: item._id,
					type: item.type
				})),
				categoryId: !data.categoryId ? createEditSeriesDialog.data?.categoryId : data.categoryId
			};

			updateCollection(editModel._id, updateCollectionData, createEditSeriesDialog.isEditingDetail).then(
				(collection?: SeriesCollection) => {
					createEditSeriesDialog.isEditingDetail &&
						collection &&
						onUpdate &&
						onUpdate(
							{
								...collection,
								category: data.category || createEditSeriesDialog.data?.category
							},
							true
						);
				}
			);
		} else {
			const collection = await createCollection(newCollectionData);

			if (collection) {
				setSeriesDetailsDialog({
					seriesDetails: collection._id,
					addContent: true,
					data: {
						...collection,
						originalItemIds: [],
						category: { _id: collection.categoryId, name: data.category.name, photo: data.category.photo },
						categoryId: data.categoryId
					}
				});
			}
		}
	};

	if (isMemberView) {
		return (
			<CreateSeriesMemberView
				createSeries={onSubmit}
				uploadingCover={uploadingCover}
				uploadPhoto={onUploaderChange}
				loadSuggestedCategories={loadSuggestedCategories}
				suggestedCategories={suggestedCategories}
				setupPriceFormRef={setupPriceFormRef}
				editModel={editModel}
				onUpdate={onUpdate}
			/>
		);
	}

	return (
		<CreateSeriesManageView
			createSeries={onSubmit}
			uploadingCover={uploadingCover}
			uploadPhoto={onUploaderChange}
			loadSuggestedCategories={loadSuggestedCategories}
			suggestedCategories={suggestedCategories}
			setupPriceFormRef={setupPriceFormRef}
			editModel={editModel}
			isFreeCommunity={isFree}
		/>
	);
};

export default CreateUpdateSeriesDialog;
