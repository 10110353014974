import React, { memo, useEffect, useState } from "react";

import styled from "styled-components";

import { Box, Icon, Input, Text } from "shared/ui-kit";

import CheckButtons from "../CheckButtons";

const Wrapper = styled(Box)`
	margin-bottom: 24px;
	${props => props.theme.breakpoints.up("sm")} {
		display: flex;
		justify-content: space-between;
	}
`;

const WrappedInput = styled(Input)`
	background-color: #f7f9fc;
	border-radius: 4px;

	border: 1px solid transparent;

	flex: 1;

	&:hover {
		border-color: #e7e9ec;
	}

	&.Mui-focused {
		border-color: #6173fe;
		background-color: #ffffff;
	}

	fieldset {
		display: none;
	}
`;

const Title = styled(Text)`
	flex: 1;
	display: flex;
	align-items: center;
	font-weight: bold;
	padding-left: 8px;
`;

const WrappedIcon = styled(Box)`
	display: flex;
	align-items: center;
`;

const IconTitleWrapper = styled(Box)`
	display: flex;
	align-items: center;
	margin-bottom: 12px;
	${props => props.theme.breakpoints.up("sm")} {
		margin-bottom: 0;
		width: 200px;
	}
`;

interface InputFieldProps {
	label: string;
	defaultValue?: string;
	icon: string;
	iconProps?: {
		group: "filled" | "linear" | "thin" | "color";
	};
	disabled?: boolean;
	onSubmit?: (persona) => void;
	name?: string;
}

const InputField: React.FC<InputFieldProps> = ({
	label,
	onSubmit,
	name = "",
	defaultValue = "",
	icon,
	iconProps = {},
	disabled = false
}) => {
	const [value, setValue] = useState(defaultValue);
	const [error, setError] = useState("");
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const handleFocus = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(anchorEl ? null : event.currentTarget);
	};

	useEffect(() => {
		setValue(defaultValue);
	}, [defaultValue]);

	useEffect(() => {
		if (value && (value.length < 3 || value.length > 50)) {
			setError(`${label} should be between 3 and 50 characters`);
		} else {
			setError("");
		}
	}, [label, value]);

	const handleSubmit = () => {
		if (onSubmit && !error) {
			onSubmit({ [name]: value });
		}
	};

	return (
		<Wrapper>
			<IconTitleWrapper>
				<WrappedIcon>
					<Icon name={icon} fill={"#c5cee0"} {...iconProps} />
				</WrappedIcon>
				<Title>{label}</Title>
			</IconTitleWrapper>
			<WrappedInput
				value={value}
				onFocus={handleFocus}
				disabled={disabled}
				errorText={error}
				onBlur={() => setTimeout(setAnchorEl, 100, null)}
				onChange={e => setValue(e.target.value as string)}
			/>
			{!error && <CheckButtons anchorEl={anchorEl} onClick={handleSubmit} />}
		</Wrapper>
	);
};

export default memo(InputField);
