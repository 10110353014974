import { useMemo } from "react";

import { ForumPersonaModel, ForumPostModel } from "types";

import { useLocationApiService } from "shared/services";
import useHttpActions from "shared/services/api/core/useHttpActions";
import { convertGroupRequest, extractGroupPayload } from "shared/services/converters/GroupConverter";
import BaseConverter from "shared/services/converters/baseConverter";

import { MemberSortBy, MemberStatus } from "shared/types/GroupModel";

import useMediaApiService from "./useMediaApiService";

import { API_URLS } from "../constants";
import { useUser } from "../hooks";
import { GroupFormModel, GroupModel, LocationDetail, MediaModel, MediaType } from "../types";

const useGroupApiService = (isMarketing = false) => {
	const params = useMemo(() => (isMarketing ? { customErrorHandlers: [] } : undefined), [isMarketing]);

	const actions = useHttpActions(params);

	const locationService = useLocationApiService();
	const mediaService = useMediaApiService();

	const { getData: getUserData } = useUser();
	const { user } = getUserData();
	const personaId = user?.profiles[0]?.personaId || -1;

	const methods = useMemo(
		() => ({
			prepareParams: async ({ photo, placeId }: { photo?: string; placeId: string }) => {
				const defaultQuery = {
					personaId: personaId as number,
					expireAt: "infinite" as number | "infinite"
				};

				const uploads: (Promise<any> | null)[] = [];
				if (photo) {
					uploads.push(
						new Promise(async resolve => {
							const groupImageMedia = await mediaService.createMedia({
								...defaultQuery,
								type: MediaType.groupCoverPhoto,
								uri: photo
							});
							resolve(groupImageMedia);
						})
					);
				} else {
					uploads.push(null);
				}

				if (placeId) {
					uploads.push(
						new Promise(async resolve => {
							const placeDetail = await locationService.getLocationDetails(placeId);
							resolve(placeDetail);
						})
					);
				} else {
					uploads.push(null);
				}

				let groupImageMedia: MediaModel[] = [];
				let placeDetail: LocationDetail;

				return await Promise.all(uploads).then(values => {
					if (values[0]) {
						groupImageMedia = values[0];
					}
					if (values[1]) {
						placeDetail = values[1];
					}

					return {
						groupImageMedia,
						groupPlaceDetails: placeDetail
					};
				});
			},
			getGroupBySlug: async (slug: string) => {
				return BaseConverter.convert<{
					group: GroupModel;
					isAdmin: boolean;
					isGlobalMod: boolean;
					posts: ForumPostModel[];
				}>(
					await actions.post(API_URLS.GROUPS.CREATE, {
						payload: null,
						path: `/groups/${slug}`,
						method: "GET",
						token: "79552dc2-afc6-4459-a7a2-f9ea4e07da69"
					})
				);
			},
			getGroupFromListing: async (slug: string) => {
				return BaseConverter.convert<{ groups: GroupModel[]; totalFoundGroups: number }>(
					await actions.post(API_URLS.GROUPS.GET_ALL, {
						slug,
						offset: 1,
						limit: 1,
						lat: 0,
						lon: 0,
						sortBy: "members",
						addInviterInfo: true
					})
				);
			},
			getGroupAdmins: async (
				slug: string,
				status: MemberStatus,
				offset: number,
				limit: number,
				sortBy?: MemberSortBy
			) => {
				return BaseConverter.convert<{ totalUserFound: number; users: ForumPersonaModel[] }>(
					await actions.get(API_URLS.GROUPS.GET_ADMINS, { slug, status, offset, limit, sortBy })
				);
			},
			getGroupTopContrib: async (slug: string, limit, offset) => {
				return BaseConverter.convert<{ users: ForumPersonaModel[] }>(
					await actions.get(API_URLS.GROUPS.TOP_CONTRIB, { slug, limit, offset })
				);
			},
			approveAllPendingGroupUsers: async groupId => {
				return BaseConverter.convert<{ success: boolean }>(
					await actions.get(`${API_URLS.GROUPS.ACCEPT_ALL}?groupId=${groupId}`)
				);
			},
			declineAllPendingGroupUsers: async groupId => {
				return BaseConverter.convert<{ success: boolean }>(
					await actions.get(`${API_URLS.GROUPS.DECLINED_ALL}?groupId=${groupId}`)
				);
			},
			actionOnPendingGroupUser: async (data: { personaId: number; slug: string; isMember: boolean }) => {
				return BaseConverter.convert<{ success: boolean }>(await actions.put(API_URLS.GROUPS.JOIN, data));
			},
			getGroupList: async (data, searchAll?: boolean) => {
				return BaseConverter.convert<{ groups: GroupModel[]; totalFoundGroups: number }>(
					await actions.post(API_URLS.GROUPS.GET_ALL, { ...data, searchAll })
				);
			},
			getGroupsCount: async (data, searchAll?: boolean) => {
				return BaseConverter.convert<{ groups: GroupModel[]; totalFoundGroups: number }>(
					await actions.post(API_URLS.GROUPS.GET_ALL_COUNT, { ...data, searchAll })
				);
			},
			createGroup: async (data: GroupFormModel) => {
				const {
					location: { place_id },
					coverUrl
				} = data;

				const { groupImageMedia, groupPlaceDetails } = await methods.prepareParams({
					photo: coverUrl,
					placeId: place_id
				});

				const dataAPI = BaseConverter.convert(
					await actions.post(API_URLS.GROUPS.CREATE, convertGroupRequest({ data, groupImageMedia, groupPlaceDetails }))
				);

				return extractGroupPayload(dataAPI);
			},
			updateGroup: async (data: GroupFormModel) => {
				const placeId = typeof data.location === "string" ? data.placeId : data.location.place_id;
				const coverUrl = data?.photoChanged ? data?.coverUrl : undefined;

				const { groupImageMedia, groupPlaceDetails } = await methods.prepareParams({
					photo: coverUrl,
					placeId
				});

				return BaseConverter.convert<GroupModel>(
					await actions.post(
						API_URLS.GROUPS.CREATE,
						convertGroupRequest({ data, groupImageMedia, groupPlaceDetails, isEdit: true })
					)
				);
			},
			deleteGroup: async (slug: string) => {
				return BaseConverter.convert<{ code: string; payload: {} }>(
					await actions.post(API_URLS.GROUPS.CREATE, {
						payload: null,
						path: `/v2/groups/${slug}`,
						method: "DELETE",
						token: null
					})
				);
			},
			inviteMemebers: async (invited, slug, personaId) => {
				await actions.post(API_URLS.GROUPS.INVITE, {
					slug,
					personaId,
					invited
				});
			},
			joinGroup: async ({
				isPrivate,
				personaId,
				slug,
				isInvited
			}: {
				isPrivate: boolean;
				personaId: number;
				slug: string;
				isInvited: boolean;
			}) => {
				return BaseConverter.convert<{ success: boolean }>(
					await actions.put(API_URLS.GROUPS.JOIN, {
						isInvited: false,
						isMember: isInvited ? true : isPrivate ? false : true,
						isModerator: false,
						isOwner: false,
						isPending: isPrivate ? (isInvited ? false : true) : false,
						personaId,
						slug
					})
				);
			},
			declineGroupInvitation: async ({ slug, personaId }: { slug: string; personaId: number }) => {
				return BaseConverter.convert<{ success: boolean }>(
					await actions.put(API_URLS.GROUPS.JOIN, {
						slug,
						personaId,
						isMember: false,
						isOwner: false,
						isModerator: false,
						isPending: false,
						isInvited: false
					})
				);
			}
		}),
		[actions, locationService, mediaService, personaId]
	);

	return methods;
};

export default useGroupApiService;
