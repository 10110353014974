import { Badge, Box, Button } from "@material-ui/core";

import styled from "styled-components";

import PlacesIcon from "assets/icons/places.svg";
import { Avatar, Text } from "shared/ui-kit";

export const ColumnHeader = styled(Text)`
	color: #8f9bb3;
`;

export const ContentColumn = styled(Box)`
	display: flex;
	align-items: center;
`;

export const DateColumn = styled(Box)`
	display: flex;
	flex-direction: column;
`;

export const DateColumnDate = styled(Text)`
	font-size: 13px;
	font-weight: normal;
	line-height: 1.23;
	color: #222b45;
	max-width: 210px;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
`;
export const EventIcon = styled(Box)<{ icon?: string }>`
	width: 40px;
	height: 40px;
	min-width: 40px;
	background-image: url(${({ icon }) => icon || PlacesIcon});
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	border-radius: 4px;
	background-color: #6173fe;
	margin-right: 24px;
`;

export const StyledAvatar = styled(Avatar)`
	width: 32px;
	height: 32px;
	border-radius: 4px;
	margin: 19px 16px 21px;
`;

export const GroupdAvatarContainer = styled(Badge)`
	margin: 19px 16px 21px;
`;

export const StyledGroupAvatar = styled(Avatar)`
	width: 24px;
	height: 24px;
	border-radius: 4px;
`;

export const StyledSendButton = styled(Button)`
	width: 35px;
	height: 16px;
	background: #6173fe;
	color: #fff;
	padding: 15px;
	margin: 10px;
`;
