import { ButtonBase } from "@material-ui/core";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

import { Box, Text } from "shared/ui-kit";

export const Item = styled(Box)``;

Item.Wrapper = styled(Box)`
	width: 100%;
	position: relative;
	padding: 4px 4px 12px 4px;
	border-radius: 12px;
	background-color: transparent;
	overflow: hidden;
	transition: background-color 0.25s linear, box-shadow 0.25s linear;

	&:hover {
		background-color: ${({ grayHover }) => (grayHover ? "#f7f9fc" : "white")};
		box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.1);
	}
`;

Item.PlayTrack = styled(ButtonBase)`
	position: absolute;
	top: 4px;
	left: 4px;
	opacity: 0;
	transition: ease-in-out 0.2s all;
	background-color: rgb(0 0 0 / 50%);
	width: calc(100% - 8px);
	height: 164px;
	border-radius: 12px;
	svg path:last-child {
		fill: ${({ bgColor }) => bgColor || "#6173FE"};
	}

	${({ show }) =>
		show &&
		css`
			opacity: 1;
		`}
`;

Item.IconWrapper = styled(Box)<{ imgUrl: string }>`
	width: 100%;
	height: 164px;

	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	background-color: #edf1f7;

	background-image: ${({ imgUrl }) => (imgUrl ? `url(${imgUrl})` : "none")};

	border-radius: 12px;

	display: flex;
	justify-content: center;
	align-items: center;

	overflow: hidden;
`;

Item.InfoBox = styled(Box)`
	width: 100%;
	padding: 16px;
	a {
		text-decoration: none;
	}
`;

Item.BeforeTitle = styled(Text)`
	font-size: 13px;
	line-height: 1.23;
	color: #8f9bb3;
`;

Item.Title = styled(Text)`
	font-size: 18px;
	font-weight: bold;
	line-height: 1.33;
	color: #222b45;
	margin-top: 6px;

	max-height: 48px;
	overflow: hidden;
`;

Item.Subtitle = styled(Text)`
	font-size: 13px;
	font-weight: normal;
	line-height: 1.23;
	color: #8f9bb3;

	margin-top: 8px;
`;

Item.UnlockBtnWrapper = styled(Box)`
	margin-top: 8px;

	padding: 0 16px;
`;

export const HrefLink = styled(Link)`
	text-decoration: none;
	display: block;
`;
