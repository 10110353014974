import { TextField } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import styled from "styled-components";

import { Box, Text } from "shared/ui-kit";

import theme from "theme/default";

export const Container = styled(Box)`
	display: flex;
	flex-direction: column;

	${props => props.theme.breakpoints.down("sm")} {
		width: 100%;
		margin: 0;
	}

	.MuiExpansionPanel-root {
		border: 0.5px solid #c5cee0;
		border-radius: 4px;

		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);

		margin-bottom: 16px;

		&:before {
			display: none;
		}
	}

	.MuiExpansionPanelSummary-root {
		height: 70px;

		.MuiExpansionPanelSummary-expandIcon {
			order: 1;

			margin-right: 1px;
		}
		.MuiIconButton-label {
			background-color: white;
		}
	}

	.MuiExpansionPanelSummary-content {
		.MuiTypography-root {
			font-weight: normal;
			color: #000000;
			font-size: 18px;
		}
	}
`;

export const TitleContainer = styled(Box)`
	display: flex;
	margin-left: 13px;

	.title {
		margin-left: 24px;
	}
`;

export const ContentContainer = styled(Box)`
	width: 94%;
	margin-left: 4%;
	margin-right: 4%;
	padding-bottom: 24px;
`;

export const Row = styled(Box)`
	display: flex;
	flex-direction: row;
	justify-content: space-between;

	margin-bottom: 40px;
`;

export const SearchField = styled(TextField)`
	padding: 0;
	flex: 1;

	> div {
		background-color: ${theme.palette.common.white};
		top: 0;
	}

	fieldset {
		margin-top: -5px;
	}
`;

export const Title = styled(Text)`
	flex: 1;
`;

export const NoResultsWrapper = styled(Box)`
	flex-direction: column;
	display: flex;
	justify-content: center;
	align-items: center;
	flex: 1;
`;

export const SkeletonQuestionWrapper = styled(Box)`
	margin-bottom: 16px;
`;

export const SkeletonRow = styled(Skeleton)`
	transform: scale(1);
`;
