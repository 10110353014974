import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import * as R from "ramda";
import { Controller, useForm } from "react-hook-form";

import { ReactComponent as DeleteIcon } from "assets/icons/icon-delete.svg";
import { ReactComponent as LikeIcon } from "assets/icons/icon-heart-empty.svg";
import { useFirebaseLiveConversation, useLiveConversation } from "modules/LiveConversation/Data/hooks";
import { ConversationComment } from "modules/LiveConversation/Data/types";

import SmartScroll from "shared/Components/SmartScroll";
import { Like } from "shared/Organisms/Post/components";

import { useLongPress, useUser } from "shared/hooks";
import { ManageDialogFormWrapper } from "shared/styles";
import { ProfilePhotoSizes } from "shared/types";
import { Tooltip } from "shared/ui-kit";
import { determineSkinTone } from "utils/determineSkinTone";

import { getResizedImage } from "utils/serviceUtils/cdnImages";

import EmptyBox from "./Empty";
import { Comment, NewComment } from "./style";

import ConfirmAlertModal from "../ConfirmAlertModal";
import { SideBar } from "../Sidebar/style";

import { SidebarHeader } from "../index";

interface CommentsProps {
	isAdmin: boolean;
	personaId: number;
}

const Comments: React.FC<CommentsProps> = ({ isAdmin, personaId }) => {
	const { getData: getUserData } = useUser();
	const { user } = getUserData();

	const { setComments, getData: getLiveConversationData } = useLiveConversation();
	const { comments } = getLiveConversationData();

	const { addComment, reactOnConversastion, removeComment } = useFirebaseLiveConversation();

	const [showLikeDropdown, setShowLikeDropdown] = useState(false);
	const [selectedComment, setSelectedComment] = useState<ConversationComment>();

	const anchorEl = useRef<HTMLElement | null>(null);

	const { handleSubmit, control, reset } = useForm({ mode: "onChange" });

	const ST = useMemo(() => user?.profiles[0].skinTone, [user]);
	const reactionColor = useMemo(() => determineSkinTone(ST || ""), [ST]);

	const scrollbarRef = useRef<any>(null);

	const reactLongPress = useLongPress({
		callback: () => {
			setShowLikeDropdown(true);
		},
		ms: 250
	});

	useEffect(() => {
		scrollbarRef.current?.scrollToBottom && scrollbarRef.current.scrollToBottom();
	}, [comments]);

	const renderComment = useCallback(
		(comment: ConversationComment): JSX.Element => {
			const { avatarUrl, firstName, lastName, text } = comment;
			const imgUrl = avatarUrl ? getResizedImage(avatarUrl, ProfilePhotoSizes.size50x50) : "";

			const couldBeDeleted = isAdmin || personaId === comment.personaId;

			const handleDelete = () => {
				setSelectedComment(comment);
			};

			return (
				<Comment.Wrapper>
					<Comment>
						<Comment.Avatar style={{ backgroundImage: `url(${imgUrl})` }} />
						<Comment.Body className={couldBeDeleted ? "deletable" : ""}>
							<Comment.Name>
								{firstName} {lastName}
							</Comment.Name>
							<Comment.Text>{text}</Comment.Text>
							{couldBeDeleted && (
								<Comment.DeleteIconWrapper>
									<Comment.DeleteIcon onClick={handleDelete}>
										<Tooltip text="Delete">
											<DeleteIcon fill="#fff" />
										</Tooltip>
									</Comment.DeleteIcon>
								</Comment.DeleteIconWrapper>
							)}
						</Comment.Body>
					</Comment>
				</Comment.Wrapper>
			);
		},
		[isAdmin, personaId]
	);

	const onSubmit = data => {
		addComment(data.text);
		reset({ text: "" });
	};

	const deleteComment = () => {
		if (selectedComment) {
			if (selectedComment.id) removeComment(selectedComment.id);
			setComments(comments.filter(_comment => _comment.id !== selectedComment.id));
			setSelectedComment(undefined);
		}
	};

	const addReaction = useCallback(
		(reaction?: string) => {
			setShowLikeDropdown(false);
			reaction && reactOnConversastion(reaction);
		},
		[reactOnConversastion]
	);

	const handleChangeNewComment = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
		if (e?.key === "Enter" && !e?.shiftKey) {
			e.preventDefault();
			handleSubmit(onSubmit)();
		}
	};

	return (
		<>
			<SidebarHeader title="Comments" />
			<SideBar.Content>
				{!comments?.length && <EmptyBox />}
				<ManageDialogFormWrapper maxHeight="100%" style={{ height: comments?.length ? "100%" : 0 }} ref={scrollbarRef}>
					<SmartScroll height="100%">{R.reverse(comments).map(renderComment)}</SmartScroll>
				</ManageDialogFormWrapper>
			</SideBar.Content>
			<SideBar.Bottom>
				<NewComment.Form onSubmit={handleSubmit(onSubmit)}>
					<Controller
						name="text"
						control={control}
						defaultValue=""
						rules={{
							required: "Required.",
							minLength: {
								value: 1,
								message: "Min 1 symbol"
							}
						}}
						render={({ onChange, value }) => (
							<NewComment.InputWrapper>
								<NewComment.Input
									placeholder="Write a comment..."
									onChange={onChange}
									value={value}
									onKeyDown={handleChangeNewComment}
								/>
								<NewComment.ReactionWrapper onMouseLeave={() => setShowLikeDropdown(false)}>
									<NewComment.LikeList
										ref={anchorEl}
										className="button"
										onMouseEnter={() => setShowLikeDropdown(true)}
										onClick={() => {
											setShowLikeDropdown(show => !show);
										}}
										{...reactLongPress}
									>
										<LikeIcon fill="#ffffff" />
									</NewComment.LikeList>
									<Like
										reactionColor={reactionColor}
										show={showLikeDropdown && Boolean(anchorEl)}
										onSelect={addReaction}
										showChooseSkinTone
										anchorEl={anchorEl.current}
									/>
								</NewComment.ReactionWrapper>
							</NewComment.InputWrapper>
						)}
					/>
				</NewComment.Form>
			</SideBar.Bottom>
			<ConfirmAlertModal
				open={!!selectedComment}
				onClose={() => setSelectedComment(undefined)}
				onConfirm={deleteComment}
				title="Delete Comment"
				message="Are you sure you want to delete this comment?"
				okText="Delete"
			/>
		</>
	);
};

export default Comments;
