import React from "react";

import { Box } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

import { BoxWrapper, ProfileContainerSkeleton, ProfileSkeleton as ProfileSkeletonWrapper } from "./style";

import { SkeletonBase } from "../Event/style";

const Profile = ({ isDescription = false, isFlex = true }) => {
	return (
		<Box className={isFlex ? "flex" : ""}>
			<Box className={`flex mt-4 items-center ${isFlex ? "w-6/12" : "w-full"}`}>
				<Skeleton variant="circle" animation="wave" width={70} height={60} />
				<Box className="w-full">
					<SkeletonBase className="ml-2" variant="text" animation="wave" width={"70%"} height={30} />
					{isDescription && <SkeletonBase className="ml-2" variant="text" animation="wave" width={"50%"} height={30} />}
				</Box>
			</Box>
			<Box className={`flex mt-4 items-center ${isFlex ? "w-6/12" : "w-full"}`}>
				<Skeleton variant="circle" animation="wave" width={70} height={60} />
				<Box className="w-full">
					<SkeletonBase className="ml-2" variant="text" animation="wave" width={"70%"} height={30} />
					{isDescription && <SkeletonBase className="ml-2" variant="text" animation="wave" width={"50%"} height={30} />}
				</Box>
			</Box>
		</Box>
	);
};

const ProfileSkeleton = () => {
	return (
		<ProfileContainerSkeleton>
			<ProfileSkeletonWrapper>
				<ProfileSkeletonWrapper.IconWrapper>
					<SkeletonBase variant="rect" animation="wave" width={"100%"} height={"100%"} />
				</ProfileSkeletonWrapper.IconWrapper>
				<BoxWrapper className="relative">
					<BoxWrapper className="flex justify-center relative bottom-20">
						<Box className="circle">
							<Skeleton variant="circle" animation="wave" width={150} height={150} />
						</Box>
					</BoxWrapper>
					<BoxWrapper className="flex justify-center relative bottom-10">
						<SkeletonBase variant="text" animation="wave" width={"50%"} height={60} />
					</BoxWrapper>
					<BoxWrapper className="mr-10 absolute top-0 right-1 sm:flex hidden">
						<SkeletonBase variant="text" animation="wave" width={120} height={60} />
					</BoxWrapper>
				</BoxWrapper>
			</ProfileSkeletonWrapper>
			<Box className="md:flex mt-4">
				<ProfileSkeletonWrapper className="md:w-4/6 px-4 pb-4 h-full md:mr-2">
					<SkeletonBase variant="text" animation="wave" width={150} height={60} />
					<SkeletonBase variant="text" animation="wave" width={"100%"} height={80} />
					<SkeletonBase className="mt-4" variant="text" animation="wave" width={150} height={60} />
					<Profile isDescription />
					<SkeletonBase className="mt-4" variant="text" animation="wave" width={150} height={60} />
					<Profile isDescription />
				</ProfileSkeletonWrapper>
				<Box className="md:w-2/6 md:ml-2 h-full md:mt-0 mt-4">
					<ProfileSkeletonWrapper className="h-full pb-4">
						<Box className="flex justify-center">
							<SkeletonBase variant="text" animation="wave" width={"80%"} height={60} />
						</Box>
						<Box className="px-4">
							<Profile isFlex={false} />
						</Box>
					</ProfileSkeletonWrapper>
					<ProfileSkeletonWrapper className="h-full pb-3 mt-4">
						<Box className="flex justify-center">
							<SkeletonBase variant="text" animation="wave" width={"80%"} height={60} />
						</Box>
						<Box className="flex flex-wrap px-5 mt-4">
							<SkeletonBase className="mr-2" variant="text" animation="wave" width={60} height={40} />
							<SkeletonBase className="mr-2" variant="text" animation="wave" width={100} height={40} />
							<SkeletonBase className="mr-2" variant="text" animation="wave" width={60} height={40} />
							<SkeletonBase className="mr-2" variant="text" animation="wave" width={100} height={40} />
							<SkeletonBase className="mr-2" variant="text" animation="wave" width={60} height={40} />
							<SkeletonBase className="mr-2" variant="text" animation="wave" width={120} height={40} />
						</Box>
						<Box className="flex justify-center">
							<SkeletonBase variant="text" animation="wave" width={"90%"} height={60} />
						</Box>
					</ProfileSkeletonWrapper>
				</Box>
			</Box>
		</ProfileContainerSkeleton>
	);
};

export default ProfileSkeleton;
