import styled from "styled-components";

import { Box, Text } from "shared/ui-kit";

export const AuthorPreview = styled(Box)`
	width: 24px;
	height: 24px;

	border-radius: 50%;
	border: 1px solid white;

	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;

	&:not(:first-child) {
		margin-left: -10px;
	}
`;

export const LoadMoreRow = styled(Box)`
	display: flex;
	align-items: center;
`;

export const LoadMoreText = styled(Text)`
	font-size: 15px;
	font-weight: 600;
	line-height: 1.6;
	color: #222b45;

	margin-left: 10px;

	cursor: pointer;
`;
