import { useCallback, useMemo } from "react";

import { useNotification } from "shared/hooks";

import { actionMethod, actionTypes, getValidationMessage } from "utils/getValidationMessage";

import { useCompaniesStore } from "../contexts";

import useCompaniesApiService from "../services/useCompaniesApiService";
import { CompanyProps, NewCompanyProps } from "../types/CompanyTypes";

const useCompanies = () => {
	const companyApiService = useCompaniesApiService();
	const { showMessage } = useNotification();

	const store = useCompaniesStore();
	const { setState } = useCompaniesStore();

	const methods = useMemo(
		() => ({
			getCompanies: async ({ limit = 20, keyword = "", offset }) => {
				setState(ctx => ({ ...ctx, isLoading: true }));

				const { companies } = await companyApiService.getCompanies(offset, limit, keyword);

				setState(ctx => ({
					...ctx,
					offset,
					companies,
					isLoading: false
				}));
			},
			getCompaniesCount: async () => {
				const { totalCount } = await companyApiService.getCompaniesCount();
				setState(ctx => ({ ...ctx, companiesCount: totalCount }));
			},
			createCompany: async (com: NewCompanyProps) => {
				setState(ctx => ({ ...ctx, isLoading: true }));

				const { company } = await companyApiService.newCompany({
					...com,
					categories: com.categories.map(cat => cat._id)
				});

				if (company) {
					setState(ctx => ({
						...ctx,
						isLoading: false,
						companies: [{ ...company, categories: com.categories }, ...(ctx.companies ?? [])],
						companiesCount: ctx.companiesCount + 1
					}));
				}
				showMessage(
					getValidationMessage({
						name: "The Marketplace Company",
						actionType: actionTypes.CRUD,
						actionMethod: actionMethod.created,
						emoji: "🎉"
					}),
					3
				);
			},
			async updateCompany(company: CompanyProps) {
				setState(ctx => ({ ...ctx, isLoading: true }));
				const data = await companyApiService.updateCompany({
					...company,
					categories: company.categories.map(cat => cat._id)
				});
				if (data) {
					setState(ctx => ({
						...ctx,
						isLoading: false,
						companies: ctx.companies?.map(compItem => {
							if (compItem._id === company._id) {
								return {
									...compItem,
									...data.company,
									categories: company.categories
								};
							}
							return compItem;
						})
					}));
					showMessage(
						getValidationMessage({
							name: "The Marketplace Category",
							actionType: actionTypes.CRUD,
							actionMethod: actionMethod.updated,
							emoji: "✨"
						}),
						3
					);
				}
			},
			async deleteCompany(company: CompanyProps) {
				const data = await companyApiService.deleteCompany(company._id);

				if (data) {
					setState(ctx => ({
						...ctx,
						deleteDialog: undefined,
						companies: ctx.companies?.filter(boardItem => boardItem._id !== company._id),
						companiesCount: ctx.companiesCount - 1
					}));

					showMessage(
						getValidationMessage({
							name: "The Marketplace Company",
							actionType: actionTypes.CRUD,
							actionMethod: actionMethod.deleted,
							emoji: "🗑"
						}),
						3
					);
				}
			},
			setPage: (page: number) => {
				setState(ctx => ({ ...ctx, page }));
			},
			setCreateUpdateCompanyOpen: ({ status, editObject }: { status: boolean; editObject?: CompanyProps }) => {
				setState(ctx => ({ ...ctx, createUpdateCompanyOpen: status, editObject: editObject }));
			},
			setDeleteDialog: ({ data }: { data?: CompanyProps }) => {
				setState(ctx => ({ ...ctx, deleteDialog: data }));
			},
			setCategoriesPerPage: (companiesPerPage: number) => {
				setState(ctx => ({ ...ctx, companiesPerPage }));
			},
			togglePinned: async (id: string, pinned: boolean) => {
				const { company } = await companyApiService.updateCompanyStatus(id, !pinned);

				if (company) {
					setState(ctx => ({
						...ctx,
						isLoading: false,
						companies: ctx.companies.map(catItem => {
							if (catItem._id === id) {
								return {
									...catItem,
									pinned: company.pinned
								};
							}
							return catItem;
						})
					}));

					showMessage(
						getValidationMessage({
							name: "Company status",
							actionType: actionTypes.CRUD,
							actionMethod: actionMethod.updated,
							emoji: "✨"
						}),
						3
					);
				}
			},
			toggleActive: async (id: string, active: boolean) => {
				await companyApiService.updateCompanyActive(id, active);

				setState(ctx => ({
					...ctx,
					isLoading: false,
					companies: ctx.companies.map(catItem => {
						if (catItem._id === id) {
							return {
								...catItem,
								active
							};
						}
						return catItem;
					})
				}));

				showMessage(
					getValidationMessage({
						name: "Company active",
						actionType: actionTypes.CRUD,
						actionMethod: actionMethod.updated,
						emoji: "✨"
					}),
					3
				);
			}
		}),
		[companyApiService, setState, showMessage]
	);

	const getData = useCallback(() => {
		return store;
	}, [store]);

	return { ...methods, getData };
};

export default useCompanies;
