import { Box, Grid } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import styled from "styled-components";

import { Divider, Text } from "shared/ui-kit";

export const StyledPopupOverlay = styled.div`
	display: ${props => (props.open ? "block" : "none")};
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	background-color: rgba(25, 36, 53, 0.2);
	z-index: 3001;
	.MuiDialogContent-root {
		padding: 0;
	}
`;

export const StyledDivider = styled(Divider)`
	margin: 0;
	border-top-width: 1px;
`;

export const StyledParagraphText = styled(Text)`
	color: #8f9bb3;
	padding: 16px;
`;

export const ConfirmHeader = styled(Box)`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 8px 16px;
	padding-bottom: 0;
	button {
		padding: 12px;
		margin-right: -12px;
	}
`;

export const ConfirmFooter = styled(Grid)`
	padding: 8px 16px;
`;

export const AutoCompleteWrapper = styled(Autocomplete)`
	padding: 7px 0;
	border: 1px solid #e4e9f2;
	border-radius: 6px;
	.MuiFormControl-fullWidth {
		padding-left: 6px;
	}
	.MuiInput-underline:after,
	.MuiInput-underline:before,
	.MuiInput-underline:hover:not(.Mui-disabled):before {
		border-bottom: none;
	}
	&:focus-within {
		border-color: #000;
	}
`;

export const OptionsHeadline = styled(Text)`
	font-size: 18px;
	font-weight: bold;
	line-height: 1.33;
	color: #222b45;
	margin-bottom: 16px;
	margin-top: 8px;
`;

export const FieldTitle = styled(Text)`
	font-size: 13px;
	font-weight: bold;
	line-height: 1.85;
`;
