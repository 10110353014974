import React, { FC } from "react";

import { LikeType } from "types";

import { ReactionRow, ReactionWrapper } from "shared/styles";
import { Text } from "shared/ui-kit";
import { determineSkinTone } from "utils/determineSkinTone";
import { getReaction } from "utils/getReaction";

const Reactions: FC<{ list: LikeType[]; isCurrentUser?: boolean }> = ({ list, isCurrentUser }) => (
	<ReactionRow className={!isCurrentUser && "other-user"}>
		<Text>{list.length}</Text>
		{list.slice(0, 3).map((like, i) => (
			<ReactionWrapper
				className={!isCurrentUser && "other-user"}
				key={i}
				reactionColor={determineSkinTone(like.reaction)}
			>
				{getReaction(like.reaction)?.icon}
			</ReactionWrapper>
		))}
	</ReactionRow>
);

export default Reactions;
