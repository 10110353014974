import React, { FC, useState } from "react";

import { useHistory } from "react-router-dom";

import { useAuth } from "modules/App/Data";
import { useMedia, usePersona } from "shared/hooks";

import { MediaModel, MediaType } from "shared/types";
import { Loader } from "shared/ui-kit";

import { AkinaRules, FormBase } from "./style";

interface FillProfileWelcomeProps {
	memberHomeUrl: string;
}

const FillProfileWelcome: FC<FillProfileWelcomeProps> = ({ memberHomeUrl }) => {
	const history = useHistory();

	const { createPersona, updatePersona, getData: getPersonaData } = usePersona();
	const { persona } = getPersonaData();

	const { getUser, confirmInvitation, getData: getAuthData } = useAuth();
	const { invitationData } = getAuthData();

	const { createMediaForUploaded } = useMedia();

	const [isLoading, setIsLoading] = useState(false);

	const onSubmit = async () => {
		setIsLoading(true);

		const personaData = await createPersona(persona!.firstName, persona!.lastName);
		const profilePhotoMedia = await createMediaForUploaded({
			personaId: Number(personaData?.personaId),
			type: MediaType.profilePhoto,
			fileUrl: `${persona!.photos[0]}`
		});

		await updatePersona(
			{
				firstName: persona!.firstName,
				lastName: persona!.lastName,
				personaId: Number(personaData?.personaId),
				photos: [profilePhotoMedia as MediaModel],
				personalInfo: persona!.personalInfo,
				maritalStatus: persona!.maritalStatus,
				journey: persona!.journey
			},
			true
		);

		setIsLoading(false);

		if (invitationData?.communityUrl && invitationData?.invitationId) {
			const user = await getUser();
			confirmInvitation({
				userId: user.userId,
				invitationId: invitationData.invitationId,
				communityUrl: invitationData.communityUrl
			});
		}

		history.push(memberHomeUrl);
	};

	return (
		<FormBase className="without-logo">
			<FormBase.Title>
				Welcome to <span className={"alert"}>Our Village</span>!
			</FormBase.Title>
			<AkinaRules>
				<AkinaRules.Title>Akina Rules</AkinaRules.Title>
				<AkinaRules.Subtitle>Be respectful</AkinaRules.Subtitle>
				<AkinaRules.Text>
					{/* eslint-disable-next-line react/no-unescaped-entities */}
					Differing opinions are welcome and expected as long as those are shared respectfully, and others' views are
					respected.
				</AkinaRules.Text>
				<br />
				<br />
				<AkinaRules.Subtitle>Be positive</AkinaRules.Subtitle>
				<AkinaRules.Text>
					We are a community experiencing motherhood in vastly different ways we could all use a bit of positivity and
					support.
				</AkinaRules.Text>
			</AkinaRules>
			<FormBase.Footer className={"welcome"}>
				<FormBase.ActionBtn palette={"basic"} buttonTheme={"light"} onClick={() => history.go(-1)}>
					Back
				</FormBase.ActionBtn>
				<FormBase.ActionBtn
					className="active"
					type={"button"}
					onClick={onSubmit}
					disabled={isLoading}
					leftIcon={isLoading ? <Loader size="1rem" show={true} color="primary" variant="indeterminate" /> : undefined}
				>
					I Accept the Rules
				</FormBase.ActionBtn>
			</FormBase.Footer>
		</FormBase>
	);
};

export default FillProfileWelcome;
