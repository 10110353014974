import React from "react";

import { Typography } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";

import { Autocomplete, createFilterOptions } from "@material-ui/lab";

import styled from "styled-components";

import SearchIcon from "shared/ui-kit/assets/icons/search.svg";

const filter = createFilterOptions();

const RenderNoOptionComponentWrapper = styled.div`
	font-size: 1rem;
	color: ${props => props.theme.palette.text.secondary};
`;

const RenderNoOptionsComponent = () => <RenderNoOptionComponentWrapper>No more options</RenderNoOptionComponentWrapper>;

const AutocompleteWrapper = styled.div`
	.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input {
		padding: 5px;
	}

	.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon
		.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
		padding-right: 5px;
	}
`;

const SearchIconWrapper = styled.div`
	padding-right: 10px;
	font-size: 18px;
	path[fill] {
		fill: #c5cee0;
	}
`;

export type AutoCompleteProps = {
	Icon?: null | React.ElementType;
	id: string;
	label: string;
	loading: boolean;
	multiple?: boolean;
	NoOptionsComponent?: React.Component;
	options: ({ label: any; noOption?: any | null; value: any } | any)[];
	readOnly?: boolean;
	value: { label: any; noOption: any; value: any } | null | undefined | any;
	onChange(value: any, valueObj: any): void;
	customRenderInput?: (any) => JSX.Element;
	customRenderOptions?: (any) => JSX.Element;
	ListboxComponent?: any;
	PaperComponent?: any;
};

export default ({
	onChange,
	NoOptionsComponent,
	label,
	readOnly,
	id,
	value,
	options,
	loading,
	multiple,
	Icon,
	customRenderInput,
	customRenderOptions,
	ListboxComponent,
	PaperComponent
}: AutoCompleteProps): JSX.Element => {
	const onChangeHandler = React.useCallback((newVal, newValObj?) => onChange(value, newValObj), [onChange, value]);
	const NoOptions = NoOptionsComponent || <RenderNoOptionsComponent />;

	const handleChange = (event, originalValue) => {
		if (event && originalValue) {
			if (originalValue.value) {
				onChangeHandler(originalValue.value, originalValue);
			}
		} else {
			onChangeHandler(null);
		}
	};

	const filterOptions: any = (optns, params) => {
		const filtered = filter(optns, params);
		if (filtered.length === 0) {
			filtered.unshift({ noOption: true });
		}
		return filtered;
	};

	const renderOption = ({ label: lbl, noOption }) =>
		noOption ? NoOptions : <Typography noWrap={true}>{lbl}</Typography>;

	const renderInput = ({ InputProps, ...params }) => (
		<TextField
			{...params}
			placeholder={label}
			variant="outlined"
			InputProps={{
				...InputProps,
				endAdornment: null,
				readOnly: !!readOnly,
				startAdornment: Icon === null ? null : <SearchIconWrapper>{Icon ? Icon : <SearchIcon />}</SearchIconWrapper>
			}}
		/>
	);

	return (
		<AutocompleteWrapper>
			<Autocomplete
				multiple={multiple}
				id={id}
				value={value}
				onChange={handleChange}
				options={
					customRenderOptions
						? options
						: options.map(x => {
								return { label: x.label, noOption: x.noOption || null };
						  })
				}
				ListboxComponent={ListboxComponent}
				PaperComponent={PaperComponent}
				renderInput={customRenderInput || renderInput}
				getOptionSelected={(option: any) => option.value}
				getOptionLabel={option => option.label || ""}
				renderOption={customRenderOptions || renderOption}
				filterOptions={filterOptions}
				loading={loading}
			/>
		</AutocompleteWrapper>
	);
};
