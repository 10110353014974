import React, { FC, ReactNode, useCallback, useState } from "react";

import { Box } from "@material-ui/core";

import clsx from "clsx";

import useScrollToXPosition from "shared/hooks/useScrollToXPosition";
import { Icon, Text } from "shared/ui-kit";
import { numberWithCommas } from "utils/serviceUtils/helpers";

import { InputWrapper, RemoveButton } from "./style";

export interface LabelInputProps {
	label?: string | ReactNode;
	onChange;
	name: string;
	value: string;
	disabled?: boolean;
	leftIcon?: JSX.Element;
	rightIcon?: {
		el: JSX.Element;
		active: boolean;
	};
	placeholder?: string;
	inputRef?: React.MutableRefObject<HTMLInputElement | HTMLTextAreaElement> | React.RefObject<HTMLInputElement>;
	maxLength?: number;
	type?: "text" | "number" | "submit" | "password" | "url";
	error?: string;
	rest?: any;
	id?: string;
	showCounter?: number;
	displayOnly?: boolean;
	activeState?: boolean; // only works if displayOnly=true
	handleClick?: (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => void;
	textarea?: boolean;
	textareaRows?: number;
	customHeight?: string;
	narrow?: boolean;
	autoResize?: boolean;
	className?: string;
	onFocus?: (e: React.FocusEvent<HTMLElement>) => void;
	onBlur?: (e: React.FocusEvent<HTMLElement>) => void;
	wrapperClassName?: string;
	outerWrapperClassName?: string;
	renderOption?: () => JSX.Element;
	handleKeyPress?: React.KeyboardEventHandler<HTMLElement>;
	rightIconColor?: string;
	isBackgroundGray?: boolean;
	numbersOnly?: boolean;
}

const LabeledInput: FC<LabelInputProps> = ({
	label,
	onChange,
	name,
	placeholder,
	value,
	rightIcon,
	leftIcon,
	inputRef,
	maxLength,
	type = "text",
	error,
	rest,
	id,
	showCounter,
	displayOnly,
	activeState,
	handleClick,
	textarea,
	textareaRows,
	customHeight,
	narrow,
	disabled,
	autoResize,
	className,
	onFocus,
	onBlur,
	wrapperClassName,
	outerWrapperClassName,
	renderOption,
	handleKeyPress,
	rightIconColor,
	isBackgroundGray,
	numbersOnly
}) => {
	const [isFocused, setIsFocused] = useState(false);
	const scrollToXPositionOnFocus = useScrollToXPosition(inputRef?.current?.form?.parentElement);

	const handleFocus = useCallback(
		(e: React.FocusEvent<HTMLElement>) => {
			scrollToXPositionOnFocus(e);
			setIsFocused(true);
			onFocus && onFocus(e);
		},
		[onFocus, scrollToXPositionOnFocus]
	);

	const handleBlur = useCallback(
		(e: React.FocusEvent<HTMLElement>) => {
			setIsFocused(false);
			onBlur && onBlur(e);
		},
		[onBlur]
	);

	const handleRemoveOption = useCallback(
		e => {
			e.stopPropagation();
			onChange("");
		},
		[onChange]
	);

	return (
		<InputWrapper
			customHeight={customHeight}
			active={rightIcon?.active}
			rightIconColor={rightIconColor}
			autoResize={autoResize}
			className={clsx(outerWrapperClassName)}
		>
			{label && <Text variant="label">{label}</Text>}
			<Box
				className={clsx(
					"input-with-icon flex items-center",
					`${error && "w-error"}`,
					`${narrow && "narrow"}`,
					`${isBackgroundGray && "grayBg"}`
				)}
			>
				{displayOnly && (
					<Box
						onClick={handleClick}
						className={`input-blocker ${activeState && "active"} ${autoResize && "autoResize"}`}
					/>
				)}
				{leftIcon && <Box className="left-icon">{leftIcon}</Box>}
				<div className={clsx("input-wrapper", isFocused && "focused", wrapperClassName)}>
					{renderOption ? (
						<div className="flex items-center justify-between cursor-pointer py-1 pr-5" onClick={handleClick}>
							{renderOption()}
							<RemoveButton onClick={handleRemoveOption}>
								<Icon name="close" group="filled" className="w-3 h-3" fill="#ffffff" />
							</RemoveButton>
						</div>
					) : textarea ? (
						<textarea
							ref={inputRef as React.MutableRefObject<HTMLTextAreaElement>}
							name={name}
							placeholder={placeholder || (typeof label === "string" ? label : "")}
							onChange={onChange}
							value={value}
							maxLength={maxLength}
							rows={textareaRows || 3}
							id={id}
							autoComplete="off"
							readOnly={displayOnly}
							disabled={disabled}
							onFocus={handleFocus}
							onBlur={handleBlur}
							className={clsx(className, customHeight && "p-0")}
							onKeyPress={handleKeyPress}
						/>
					) : (
						<input
							ref={inputRef}
							name={name}
							placeholder={placeholder || label}
							onChange={onChange}
							value={value}
							maxLength={maxLength}
							type={type}
							onClick={handleClick}
							id={id}
							autoComplete="off"
							readOnly={displayOnly}
							disabled={disabled}
							onFocus={handleFocus}
							onBlur={handleBlur}
							className={clsx(className, `${isBackgroundGray && "grayBg"}`, customHeight && "p-0")}
							onKeyDown={e => {
								if (numbersOnly) {
									return ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault();
								}
								handleKeyPress && handleKeyPress(e);
							}}
							{...rest}
						/>
					)}
				</div>

				<Box className="icon-wrapper">{rightIcon?.el}</Box>
			</Box>
			{showCounter && (
				<Box className="letter-counter">
					{value ? value.length : 0}/{numberWithCommas(showCounter)}
				</Box>
			)}
			{error && <Box className="input-error">{error}</Box>}
		</InputWrapper>
	);
};

export default LabeledInput;
