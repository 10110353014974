import React, { FC, memo } from "react";

import { Box } from "@material-ui/core";
import styled from "styled-components";

import { Text } from "shared/ui-kit";

import { shorten } from "utils/shortenText";

const ShowWrapper = styled(Box)`
	box-shadow: inset 0 0 0 0.5px #c5cee0;
	border-radius: 4px;
	padding: 16px;
	display: flex;
	align-items: center;
	width: calc(100% - 32px);
	margin: 0 16px;
	img {
		width: 80px;
		height: 80px;
		border-radius: 12px;
		flex-shrink: 0;
		margin-right: 12px;
	}
	h3 {
		font-size: 14px;
	}
	.author {
		font-size: 12px;
		margin: 4px 0;
		color: #8f9bb3;
	}
	.description {
		font-size: 12px;
	}
`;

const ShowBlock: FC<{ data }> = memo(({ data }) => (
	<ShowWrapper>
		<img src={data.image} alt={data.title} />
		<Box>
			<Text variant="h3">{data.title}</Text>
			<Text className="author">{data.author}</Text>
			<Text className="description">{shorten(data.description, 130)}</Text>
		</Box>
	</ShowWrapper>
));

export default ShowBlock;
