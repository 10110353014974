import React from "react";

import styled from "styled-components";

import { useMarketing } from "shared/hooks";
import { Box, Button, Text } from "shared/ui-kit";

const Wrapper = styled(Box)`
	display: flex;

	${props => props.theme.breakpoints.down("sm")} {
		flex-direction: column;

		padding: 30px;
	}
`;

const SubscriptionWrapper = styled(Box)`
	width: 48%;

	display: inline-block;

	&:nth-child(2n + 1) {
		margin-right: 24px;
	}

	${props => props.theme.breakpoints.down("sm")} {
		width: 100%;

		margin: 0;

		&:not(:last-child) {
			margin-bottom: 30px;
		}
	}
`;

const SubscriptionBlock = styled(Box)`
	width: 100%;

	border-radius: 4px;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
	box-shadow: inset 0 0 0 0.5px #c5cee0;
	background-color: #ffffff;

	padding: 40px;
`;

const Title = styled(Text)`
	font-size: 22px;
	font-weight: bold;
	line-height: 1.45;
	color: #222b45;
`;

const Price = styled(Text)`
	font-size: 36px;
	font-weight: 600;
	line-height: 1.33;
	color: #8f9bb3;

	margin-top: 26px;
`;

const Desc = styled(Text)`
	font-size: 15px;
	font-style: normal;
	line-height: 1.33;
	color: #222b45;

	margin-top: 30px;
`;

const StyledBtn = styled(Button)`
	font-size: 15px;
	font-style: normal;
	line-height: 1.33;
	margin-top: 40px;
`;

interface SubscriptionProps {}

const Subscription: React.FC<SubscriptionProps> = () => {
	const { setShowJoinPopup, getData: getMarketingData } = useMarketing();
	const { annuallySubscription, monthlySubscription } = getMarketingData();

	return (
		<Wrapper>
			{monthlySubscription?.price && (
				<SubscriptionWrapper>
					<SubscriptionBlock>
						<Title>Monthly</Title>
						<Price>${monthlySubscription.price}</Price>
						<Desc>{monthlySubscription.description}</Desc>
						<StyledBtn
							buttonTheme={"main"}
							palette="primary"
							removeSideMargin
							fullWidth
							onClick={() => setShowJoinPopup(true)}
						>
							Join
						</StyledBtn>
					</SubscriptionBlock>
				</SubscriptionWrapper>
			)}
			{annuallySubscription?.price && (
				<SubscriptionWrapper>
					<SubscriptionBlock>
						<Title>Annually</Title>
						<Price>${annuallySubscription.price}</Price>
						<Desc>{annuallySubscription.description}</Desc>
						<StyledBtn
							buttonTheme={"main"}
							palette="primary"
							removeSideMargin
							fullWidth
							onClick={() => setShowJoinPopup(true)}
						>
							Join
						</StyledBtn>
					</SubscriptionBlock>
				</SubscriptionWrapper>
			)}
		</Wrapper>
	);
};

export default Subscription;
