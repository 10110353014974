import styled, { css } from "styled-components";

import { Box, Text } from "shared/ui-kit";

export const Container = styled(Box)`
	display: flex;
	flex-direction: column;

	${props => props.theme.breakpoints.down("sm")} {
		width: 100%;
		margin: 0;
	}

	.MuiExpansionPanel-root {
		border-radius: 12px;
		border: 1px solid #c5cee050;
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
		&:before {
			display: none;
		}
	}

	.MuiExpansionPanelSummary-root {
		.MuiExpansionPanelSummary-expandIcon {
			order: 1;
		}
		.MuiIconButton-label {
			background-color: #f4f4f4;
			padding: 5px;

      svg {
        fill ${({ color }) => color || "#6173FE"};
      }
		}
	}

	.MuiExpansionPanelSummary-content {
		.MuiTypography-root {
			width: 100%;
		}
	}

	.MuiCollapse-wrapper {
		border-top: 1px solid #c5cee080;
	}
`;

export const EventDate = styled(Text)`
	font-size: 12px;
	color: ${({ color }) => color || "#6173FE"};
`;

export const EventDetail = styled(Text)`
	color: #8f9bb3;
	font-size: 15px;
`;

export const ImageContainer = styled(Box)`
	img {
		width: 64px;
		height: 64px;
		border-radius: 12px;
	}

	svg {
		width: 64px;
		height: 64px;
		border-radius: 12px;
	}
`;

export const SecondaryText = styled(Text)`
	color: #8f9bb3;
	${({ danger }) =>
		danger &&
		css`
			color: red;
		`}

	${({ fontSize }) =>
		fontSize &&
		css`
			font-size: ${fontSize}px;
		`}
`;

export const Divider = styled(Box)`
	border-top: 1px solid #c5cee080;
	width: 100%;
	margin: 5px 0 10px 0;
`;

export const SkeletonBox = styled(Box)`
	background: #fff;
	border-radius: 12px;
	border: 1px solid #c5cee050;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
`;

export const RequestTemplateWrapper = styled(Box)`
	.requestItem {
		:nth-child(1n) {
			border-right: 1px solid #c5cee080;
			border-top-right-radius: 0px;
			border-bottom-right-radius: 0px;
			padding-right: 0.78rem;
		}

		:nth-of-type(3n + 2) {
			padding-left: 0.78rem;
		}

		:nth-child(3n) {
			border-right: none;
			padding-right: 0;
			padding-left: 0.78rem;
		}

		:last-child {
			border-right: none;
		}

		@media (max-width: 1096px) {
			:nth-child(1n) {
				border-right: 1px solid #c5cee080;
				padding-right: 0.78rem;
				padding-left: 0;
			}

			:nth-of-type(2n) {
				border-right: none;
				padding-left: 0.78rem;
			}

			:last-child {
				border-right: none;
			}
		}

		@media (max-width: 768px) {
			:nth-child(1n) {
				border-right: none;
				padding-left: 0;
			}
		}
	}
`;
