import { useMemo } from "react";

import useHttpActions from "shared/services/api/core/useHttpActions";

import { convertTransactions, convertTransactionsCount } from "./converters/TransactionConverter";

import { API_URLS } from "../constants";

const useTransactionApiService = () => {
	const actions = useHttpActions();

	return useMemo(
		() => ({
			getTransactions: async (
				offset = 0,
				limit = 10,
				keyword = "",
				startDate: string | null = null,
				endDate: string | null = null,
				orderType: string[] = [],
				personaId?: number
			) => {
				return convertTransactions(
					await actions.post(API_URLS.TRANSACTIONS.GET_LIST, {
						offset,
						limit,
						startDate,
						endDate,
						keyword,
						orderType,
						personaId
					})
				);
			},
			getTransactionsCount: async (
				keyword = "",
				startDate = null,
				endDate = null,
				orderType: string[] = [],
				personaId?: number
			) => {
				return convertTransactionsCount(
					await actions.post(API_URLS.TRANSACTIONS.GET_COUNT, {
						startDate,
						endDate,
						keyword,
						orderType,
						personaId
					})
				);
			}
		}),
		[actions]
	);
};

export default useTransactionApiService;
