import React from "react";

import { useLocation } from "react-router";

import { view } from "modules/MemberHome";

const FilesPage = () => {
	const { search } = useLocation();
	const query = new URLSearchParams(search);
	const groupId = query.get("groupId") || undefined;

	return <view.Files groupId={groupId} />;
};

export default FilesPage;
