import React, { FC, useMemo } from "react";

import { Box } from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";

import { Controller, useForm } from "react-hook-form";

import { ReactComponent as ClockIcon } from "assets/icons/clock-filled.svg";
import { ReactComponent as DownloadIcon } from "assets/icons/icon-download.svg";
import { SeriesCollection } from "modules/Manage/Data/types";
import { CategoryProps } from "modules/Marketplace/Data/types";
import {
	DateInput,
	EnableFeature,
	LabeledInput,
	MemberDialog,
	RectangleUploader,
	SelectInput
} from "modules/MemberHome/View/shared";
import { InputWrapper, MemberDialogContentWrapper } from "modules/MemberHome/View/shared/style";

import { SetupPrice } from "shared/Components";
import { SetupPriceMethods } from "shared/Components/SetupPrice";
import { useSeries, useUser } from "shared/hooks";
import { times } from "shared/types/times";
import { Icon } from "shared/ui-kit";
import { getDefaultTime } from "utils/getDefaultTime";
import { getScheduleDate } from "utils/getScheduleDate";

export interface SeriesDialogProps {
	uploadingCover: boolean;
	createSeries: (data: any) => Promise<void>;
	uploadPhoto: (files: FileList, onChange: (...event: any[]) => void) => Promise<void>;
	loadSuggestedCategories: (name: string) => Promise<void>;
	suggestedCategories: CategoryProps[];
	editModel?: SeriesCollection;
	isFreeCommunity?: boolean;
	setupPriceFormRef: React.RefObject<SetupPriceMethods>;
	onUpdate?: () => void;
}

const CreateSeriesMemberView: FC<SeriesDialogProps> = ({
	createSeries,
	uploadingCover,
	uploadPhoto,
	loadSuggestedCategories,
	suggestedCategories,
	setupPriceFormRef,
	editModel,
	onUpdate
}) => {
	const { setSeriesDialog } = useSeries();

	const {
		control,
		handleSubmit,
		formState: { isDirty, errors, isSubmitting, isValid },
		watch
	} = useForm({ mode: "onChange" });

	const { schedule } = watch(["schedule"]);

	const { getData: getUserData } = useUser();
	const { user } = getUserData();

	const showPremiumAccess = useMemo(
		() => user?.isGlobalAdmin || user?.isGlobalOwner || user?.isGlobalModerator,
		[user]
	);

	const tomorrow = useMemo(() => new Date().setDate(new Date().getDate() + 1), []);

	const onSubmit = async data => {
		delete data.priceChanged;

		const scheduleDate = getScheduleDate(data.time?.value, data.date);

		await createSeries({
			...data,
			categoryId: data.category.value,
			scheduleDate
		});

		onUpdate && onUpdate();
	};

	return (
		<MemberDialog
			customWidth={512}
			open
			onClose={() => setSeriesDialog({ open: false })}
			title={`${editModel ? "Update" : "Create a"} Series`}
			confirmLeave={isDirty}
			footerPrimary={{
				text: editModel ? "Update" : "Create",
				disabled: !isValid || !isDirty || !!Object.keys(errors).length || isSubmitting || uploadingCover,
				loading: isSubmitting,
				onClick: handleSubmit(onSubmit)
			}}
		>
			<form onSubmit={handleSubmit(onSubmit)}>
				<Controller
					name="artwork"
					control={control}
					defaultValue={editModel?.meta?.artwork?.url}
					rules={{ required: "Image is required" }}
					render={({ onChange, value }) => (
						<InputWrapper>
							<RectangleUploader
								defaultPreviewUrl={value ? (typeof value === "object" ? value.url : value) : undefined}
								onChange={files => uploadPhoto(files, onChange)}
								onDelete={onChange}
								uploading={uploadingCover}
								errorText={errors?.artwork?.message}
							/>
						</InputWrapper>
					)}
				/>
				<MemberDialogContentWrapper>
					<Controller
						name="title"
						control={control}
						defaultValue={editModel?.title}
						rules={{
							required: "Track title is required",
							maxLength: {
								value: 100,
								message: "Title should not be more than 100 letters"
							}
						}}
						render={({ onChange, value, ref }) => (
							<InputWrapper>
								<LabeledInput
									label="Series title *"
									placeholder=" "
									onChange={onChange}
									value={value}
									name="title"
									maxLength={100}
									showCounter={100}
									error={errors?.title?.message}
									inputRef={ref}
								/>
							</InputWrapper>
						)}
					/>
					<Controller
						name="category"
						control={control}
						defaultValue={editModel ? { value: editModel.category._id, label: editModel.category.name } : undefined}
						rules={{ required: "Category is required!", validate: value => !!value }}
						render={({ onChange, value, ref }) => (
							<InputWrapper>
								<SelectInput
									name="category"
									onChange={onChange}
									value={value && value.label}
									placeholder=" "
									label="Category *"
									loadSuggestions={val => loadSuggestedCategories(val)}
									maxHeight={260}
									error={errors?.job?.message}
									noOptionsHeadline="Series Category ( ex: HipHop )"
									options={
										suggestedCategories
											? suggestedCategories.map(suggestion => ({
													value: suggestion._id,
													label: suggestion.name
											  }))
											: []
									}
									selectInputRef={ref}
								/>
							</InputWrapper>
						)}
					/>
					<Controller
						name="description"
						control={control}
						defaultValue={editModel?.description}
						rules={{
							required: "Description is required"
						}}
						render={({ onChange, value, ref }) => (
							<InputWrapper>
								<LabeledInput
									label="Description *"
									placeholder=" "
									onChange={onChange}
									value={value}
									name="description"
									maxLength={1000}
									showCounter={1000}
									textarea
									inputRef={ref}
								/>
							</InputWrapper>
						)}
					/>
					{showPremiumAccess && (
						<InputWrapper>
							<Controller
								name="priceChanged"
								control={control}
								render={({ onChange }) => (
									<SetupPrice
										ref={setupPriceFormRef}
										defaultValue={editModel?.priceTags}
										onPriceChanged={() => onChange(true)}
									/>
								)}
							/>
						</InputWrapper>
					)}
					<Controller
						name="individualAccess"
						control={control}
						defaultValue={editModel?.meta.individualAccess}
						render={({ onChange, value }) => (
							<InputWrapper>
								<EnableFeature
									fill="#8f9bb3"
									icon={<Icon name="folder-open-gray" group="color" />}
									onChange={onChange}
									title="Allow access to content in Series"
									description="Allow users to buy or access the individual content in the series without buying entire series."
									value={value}
									verticalPadding
								/>
							</InputWrapper>
						)}
					/>
					<Controller
						name="schedule"
						control={control}
						defaultValue={!!editModel?.meta?.scheduleDate}
						render={({ onChange, value }) => (
							<InputWrapper>
								<EnableFeature
									icon={<Icon fill="#8f9bb3" name="clock" width={24} height={24} />}
									onChange={onChange}
									title="Schedule Series"
									value={value}
								/>
							</InputWrapper>
						)}
					/>
					{schedule && (
						<Box className="two-inputs-row">
							<Controller
								name="date"
								control={control}
								defaultValue={editModel ? editModel.meta.scheduleDate : tomorrow}
								render={({ onChange, value, ref }) => (
									<InputWrapper width="60%">
										<DateInput
											label="Date *"
											name="date"
											value={value}
											onChange={onChange}
											error={errors?.date?.message}
											placeholder=" "
											displayOnly
											inputRef={ref}
										/>
									</InputWrapper>
								)}
							/>
							<Controller
								name="time"
								control={control}
								defaultValue={getDefaultTime(false, editModel?.meta?.scheduleDate)}
								render={({ onChange, value, ref }) => (
									<InputWrapper width="38%">
										<SelectInput
											label="Time *"
											name="time"
											value={value && value.label}
											onChange={onChange}
											placeholder=" "
											options={times}
											maxHeight={180}
											rightIcon={{
												el: <ClockIcon />,
												active: false
											}}
											displayOnly
											noOptionsHeadline="Select Time Option"
											selectInputRef={ref}
										/>
									</InputWrapper>
								)}
							/>
						</Box>
					)}
					<Controller
						name="downloadable"
						control={control}
						defaultValue={editModel?.meta?.canBeDownloaded}
						render={({ onChange, value }) => (
							<InputWrapper>
								<EnableFeature icon={<DownloadIcon />} onChange={onChange} title="Allow Download" value={value} />
							</InputWrapper>
						)}
					/>
					<Controller
						name="private"
						control={control}
						defaultValue={editModel?.meta?.private}
						render={({ onChange, value }) => (
							<InputWrapper>
								<EnableFeature
									fill="#8f9bb3"
									icon={<VisibilityIcon />}
									onChange={onChange}
									title="Set as Private"
									description="Choose this option to hide from others in the community"
									value={value}
									verticalPadding
								/>
							</InputWrapper>
						)}
					/>
				</MemberDialogContentWrapper>
			</form>
		</MemberDialog>
	);
};

export default CreateSeriesMemberView;
