import styled from "styled-components";

import { Box } from "shared/ui-kit";

export const Wrapper = styled(Box)`
	padding: 22px 16px;
`;

export const NewCommentWrapper = styled(Box)`
	margin-bottom: 24px;
`;

export const NewCommentSkeletonWrapper = styled(Box)`
	margin-bottom: 24px;
`;

export const CommentWrapper = styled(Box)`
	margin-bottom: 16px;
`;
