import React, { Fragment } from "react";

export type CustomToolbarProps = {
	toolbarItemList: any[];
	token: string;
	disabled?: boolean;
};

const CustomToolbar = ({ toolbarItemList, token, disabled }) => (
	<div
		id={`toolbar_${token}`}
		className={`ql-toolbar ql-snow ql__custom-toolbar ${disabled ? "ql__custom-toolbar_disabled" : ""} ${
			!toolbarItemList.length ? "ql__custom-toolbar_empty" : ""
		}`}
	>
		{toolbarItemList.map((item, key) => (
			<Fragment key={key}>{item}</Fragment>
		))}
	</div>
);

CustomToolbar.defaultProps = {
	token: "",
	disabled: false,
	toolbarItemList: []
};

export default CustomToolbar;
