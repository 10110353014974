import React, { FC, useCallback, useMemo } from "react";

import { Box } from "@material-ui/core";

import { ParsableDate } from "@material-ui/pickers/constants/prop-types";

import { DateTime } from "luxon";

import { PlaceholderImage } from "shared/Components";
import { EventModel, PlaceholderImageType } from "shared/types";
import { Text } from "shared/ui-kit";

import { CustomRadio, CustomRow, Image, ImageContainer } from "../style";

interface Props {
	event: EventModel;
	lastEventRef?: React.RefObject<HTMLDivElement>;
	handleToggleEvent: (event: EventModel) => void;
	selectedEvent?: EventModel;
}

const EventBlock: FC<Props> = ({ event, lastEventRef, handleToggleEvent, selectedEvent }) => {
	const zone = useMemo(() => Intl.DateTimeFormat().resolvedOptions().timeZone, []);

	const eventRange = useMemo(() => {
		if (event) {
			const start = DateTime.fromISO(event.startTime ? event.startTime : "", { zone }).toFormat("MMM d, h:mm a");
			const end = DateTime.fromISO(event.endTime ? event.endTime : "", { zone }).toFormat("MMM d, h:mm a");

			return `${start} - ${end}`;
		}
	}, [event, zone]);

	const getTimeTitle = useCallback(
		(startTime: ParsableDate, endTime: ParsableDate): string => {
			const now = new Date();
			const start = new Date(startTime ? startTime.toString() : "");
			const end = new Date(endTime ? endTime.toString() : "");
			if (now > end) {
				return "Ended";
			}
			if (now < start) {
				return eventRange || "Upcoming";
			}
			return "Happening now";
		},
		[eventRange]
	);

	return (
		<CustomRow
			ref={lastEventRef}
			className={`${selectedEvent?.eventId === event.eventId ? "active" : ""}`}
			onClick={() => handleToggleEvent(event)}
		>
			<Box className="info">
				<ImageContainer>
					{!!event.eventImages.length ? (
						<Image src={event.eventImages[0]} />
					) : (
						<PlaceholderImage
							type={PlaceholderImageType.EVENT_DETAILS}
							width={60}
							height={60}
							viewBox={"0 0 720 320"}
						/>
					)}
				</ImageContainer>
				<Box>
					<Text variant="subtitle1">{event.title}</Text>
					<Text className="description">{getTimeTitle(event.startTime, event.endTime)}</Text>
				</Box>
			</Box>
			<Box>
				<CustomRadio checked={selectedEvent?.eventId === event.eventId} />
			</Box>
		</CustomRow>
	);
};

export default EventBlock;
