import React from "react";

import { ReactComponent as NoProfileIcon } from "assets/icons/no-profile-small.svg";
import { SkeletonBase } from "shared/Components";

import { formatCount } from "utils/serviceUtils/helpers";

import { Item, StyledText, Wrapper } from "./style";

interface MemberProps {
	memberImgs: (string | null)[];
	total?: number;
	loading?: boolean;
	isFundraiser?: boolean;
	onlyImages?: boolean;
}

const Members: React.FC<MemberProps> = ({
	memberImgs,
	total = 0,
	loading,
	isFundraiser = false,
	onlyImages = false
}) => {
	return loading ? (
		<Wrapper>
			<Item>
				<SkeletonBase variant="circle" />
			</Item>
			<Item>
				<SkeletonBase variant="circle" />
			</Item>
			<Item>
				<SkeletonBase variant="circle" />
			</Item>
			<SkeletonBase width={70} height={16} fullScale className="side-offset" />
		</Wrapper>
	) : (
		<Wrapper>
			{memberImgs.map((img, index) =>
				img ? (
					<Item key={index} style={{ backgroundImage: `url(${img})` }} />
				) : (
					<Item key={index}>
						<NoProfileIcon />
					</Item>
				)
			)}
			{!onlyImages && (
				<StyledText className={!memberImgs?.length ? "no-imgs" : ""}>
					{formatCount(
						total,
						isFundraiser ? "Donation" : "Member",
						isFundraiser ? "Donations" : "Members",
						isFundraiser
					)}
				</StyledText>
			)}
		</Wrapper>
	);
};

export default Members;
