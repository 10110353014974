import { useMemo } from "react";

import { ReportSpamType, UserFileSortBy } from "types";
import { FileFormModel, FileType, UpdateFileFormModel } from "types/FilesContextValuesType";

import { API_URLS } from "shared/constants";

import useHttpActions from "shared/services/api/core/useHttpActions";
import { BaseConverter, convertFile, convertUpdateFile } from "shared/services/converters";
import useMediaApiService from "shared/services/useMediaApiService";

import { MediaObjType, MediaType } from "shared/types";

const useFilesApiService = (isMarketing = false) => {
	const params = useMemo(() => (isMarketing ? { customErrorHandlers: [] } : undefined), [isMarketing]);

	const actions = useHttpActions(params);
	const mediaService = useMediaApiService();

	const methods = useMemo(
		() => ({
			prepareUpdateQueryParams: async (personaId: string | number, fileUrl?: string, imageUrl?: string) => {
				const defaultQuery = {
					personaId: personaId as number,
					expireAt: "infinite" as number | "infinite"
				};

				let file, coverPhoto;
				if (fileUrl) {
					file = await mediaService.createMedia({
						...defaultQuery,
						type: MediaType.userFile,
						uri: fileUrl
					});
				}

				if (imageUrl) {
					coverPhoto = await mediaService.createMedia({
						...defaultQuery,
						type: MediaType.filePhoto,
						uri: imageUrl
					});
				}

				return {
					file,
					coverPhoto
				};
			},
			createFile: async (data: FileFormModel, personaId: number | "") => {
				const { file, coverPhoto } = await methods.prepareUpdateQueryParams(
					personaId,
					data.fileUrl,
					data.coverPhotoUrl
				);

				return BaseConverter.convert<{ success: boolean; userFile: FileType }>(
					await actions.post(API_URLS.FILES.GET_FILES, convertFile(data, file[0], coverPhoto ? coverPhoto[0] : null))
				);
			},
			updateFile: async (data: UpdateFileFormModel, personaId: number | "") => {
				let coverPhoto: MediaObjType | undefined;
				if (data.coverPhotoUrl && data.coverPhoto?.url && data.coverPhotoUrl === data.coverPhoto?.url) {
					coverPhoto = data.coverPhoto;
				} else {
					const { coverPhoto: newPhoto } = await methods.prepareUpdateQueryParams(personaId, "", data.coverPhotoUrl);
					coverPhoto = newPhoto
						? {
								mediaId: newPhoto[0]._id,
								url: newPhoto[0].uri
						  }
						: undefined;
				}

				return BaseConverter.convert<{ success: boolean; userFile: FileType }>(
					await actions.put(API_URLS.FILES.UPDATE_DELETE_FILE(data._id), convertUpdateFile(data, coverPhoto))
				);
			},
			getFileById: async (id: string) => {
				return BaseConverter.convert<{ userFile: FileType }>(await actions.get(API_URLS.FILES.GET_FILE(id)));
			},
			getFiles: async ({
				limit,
				keyword,
				offset,
				sortBy,
				categoryId,
				eventId,
				groupId,
				personaId
			}: {
				limit: number;
				keyword?: string;
				offset: number;
				sortBy?: UserFileSortBy;
				categoryId?: string;
				eventId?: string;
				groupId?: string;
				personaId?: string | number;
			}) => {
				return BaseConverter.convert<{ userFiles: FileType[] }>(
					await actions.get(API_URLS.FILES.GET_FILES, {
						limit,
						keyword: keyword || null,
						offset,
						sortBy,
						categoryId,
						eventId,
						groupId,
						creatorId: personaId
					})
				);
			},
			getAllFilesCount: async ({
				categoryId,
				groupId,
				eventId,
				searchTerm
			}: {
				categoryId?: string;
				groupId?: string;
				eventId?: string;
				searchTerm?: string;
			}) => {
				const payload = { categoryId, groupId, eventId };
				if (!!searchTerm?.length) {
					payload["keyword"] = searchTerm;
				}

				return BaseConverter.convert<{ totalCount: number }>(
					await actions.get(API_URLS.FILES.GET_FILES_COUNT, payload)
				);
			},
			deleteFile: async (id: string) => {
				return BaseConverter.convert<{ success: boolean }>(await actions.delete(API_URLS.FILES.UPDATE_DELETE_FILE(id)));
			},
			reportFile: async ({ id, reportType, reason }: { id: string; reportType: ReportSpamType; reason?: string }) => {
				return BaseConverter.convert<{ success: boolean }>(
					await actions.post(API_URLS.FILES.REPORT(id), {
						reportType,
						reason: reason || ""
					})
				);
			}
		}),
		[actions, mediaService]
	);

	return useMemo(
		() => ({
			...methods
		}),
		[methods]
	);
};

export default useFilesApiService;
