import { IStateProps } from "utils/StateOf";
import createStore from "utils/createStore";

import { ActivityContextValues } from "../../types";

export const initialState: IStateProps<ActivityContextValues> = {
	loading: false,
	activities: [],
	allowToLoadMore: true
};

export default createStore<ActivityContextValues>({
	initialState
});
