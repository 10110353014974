import React, { useCallback, useEffect, useState } from "react";

import { useHistory } from "react-router-dom";
import { ForumPostModel } from "types";

import { Post } from "shared/Organisms";
import { useCommunity, useMemberRoutes, usePosts, useUser } from "shared/hooks";

import { BoxItemWrapper, Column, Container } from "./style";

import { CreateUpdatePost } from "../../../../Post/View";

import { ModelBlock, ModelBlockType, PostSkeleton } from "../../Components";
import { MessageText } from "../../Components/ModelBlock/style";
import { EmptyStateWoman, ManInLotus, NoConnectionsBlock } from "../Connections/style";

export interface FuturePostPageProps {
	id: string;
}

const FuturePostPage: React.FC<FuturePostPageProps> = ({ id }) => {
	const history = useHistory();
	const [post, setPost] = useState<ForumPostModel>();
	const [loading, setLoading] = useState(false);
	const { getData: getUserData } = useUser();
	const { user } = getUserData();

	const { getData: getCommunityData } = useCommunity();
	const { workspace, isWhitelabelAppMode } = getCommunityData();

	const { getPost, setCreateUpdatePostDialog, getData: getPostsData } = usePosts();
	const { createUpdatePostDialog } = getPostsData();

	const { getMemberRoutesData } = useMemberRoutes();
	const { routes } = getMemberRoutesData();

	const fetchPostById = useCallback(async () => {
		setLoading(true);
		try {
			const _post = await getPost(id);
			setPost(_post);
		} catch (error) {
			setPost(undefined);
		} finally {
			setLoading(false);
		}
	}, [getPost, id, setPost]);

	useEffect(() => {
		fetchPostById();
	}, [fetchPostById]);

	useEffect(() => {
		return () => setCreateUpdatePostDialog({ isOpen: false });
	}, [setCreateUpdatePostDialog]);

	const handlePostChanged = async () => {
		setCreateUpdatePostDialog({ isOpen: false });
		redirectToScheduledPostPage();
	};

	const redirectToScheduledPostPage = useCallback(() => {
		history.push(routes?.member.futureposts.getPath());
	}, [history, routes?.member.futureposts]);

	if (!workspace || !user) {
		return null;
	}

	return (
		<>
			<Container className={!post && "column"}>
				{loading ? (
					<PostSkeleton />
				) : post ? (
					<>
						<Column middle>
							<BoxItemWrapper key={post.pid}>
								<Post fullMode post={post} allowManage={false} confirmDeleteCb={redirectToScheduledPostPage} />
							</BoxItemWrapper>
						</Column>
					</>
				) : (
					<>
						<ModelBlock
							type={ModelBlockType.liveUpcomingEvent}
							items={[]}
							noContent={
								<NoConnectionsBlock>
									{isWhitelabelAppMode ? <EmptyStateWoman /> : <ManInLotus />}
									<MessageText>{"No Schedule Post Found"}</MessageText>
								</NoConnectionsBlock>
							}
						/>
					</>
				)}
			</Container>
			{createUpdatePostDialog.isOpen && (
				<CreateUpdatePost onCreateUpdatePost={handlePostChanged} pid={createUpdatePostDialog?.editablePostPid} />
			)}
		</>
	);
};

export default FuturePostPage;
