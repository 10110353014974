import { AxiosResponse } from "axios";

import {
	ConnectionSearchModel,
	CreateGroupModel,
	InboxItem,
	InboxModel,
	RecentConnections
} from "modules/Messaging/Data/types";

export const convertInbox = ({ data }: AxiosResponse<InboxModel>): InboxItem[] => data.connections;

export const convertConnections = (data: RecentConnections): InboxItem[] => data.recentConnections.recentConnections;

export const convertCreateGroup = ({ data }: AxiosResponse<CreateGroupModel>): CreateGroupModel => data;

export const convertConnectionSearch = (data: ConnectionSearchModel): InboxItem[] => data.personas;
