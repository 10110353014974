import { Box } from "@material-ui/core";
import styled, { css } from "styled-components";

export const DialogOverlayWrapper = styled(Box)`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	max-height: 100vh;
	background-color: rgba(0, 0, 0, 0.4);
	z-index: 99999;
	.MuiDialog-container {
		height: var(--app-height);
	}
	.MuiDialog-paper {
		margin: 0;
		box-shadow: 0 4px 40px 0 rgba(0, 0, 0, 0.16);
		width: ${props => (props.fullScreen ? "100%" : "calc(100% - 32px)")};
		border-radius: 12px;
	}
	.MuiDialogContent-root {
		padding: 0;
	}

	${props => props.theme.breakpoints.up("sm")} {
		.container {
			max-width: 100%;
		}

		${({ customWidth }) =>
			customWidth &&
			css`
				.MuiDialog-paper {
					width: ${customWidth + "px"};
				}
			`}

		${({ maxHeight }) =>
			maxHeight &&
			css`
				.MuiDialog-paper {
					max-height: ${maxHeight + "px"};
				}
			`}

			${({ minHeight }) =>
			minHeight &&
			css`
				.MuiDialog-paper {
					min-height: ${minHeight + "px"};
				}
			`}
	}
`;
