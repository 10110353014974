import React, { FC, useEffect, useMemo } from "react";

import { useMediaQuery } from "@material-ui/core";
import { DateTime } from "luxon";
import { Controller, useForm, useWatch } from "react-hook-form";
import { useHistory } from "react-router-dom";

import { useAuth } from "modules/App/Data";
import { useCommunity, useMedia, usePersona } from "shared/hooks";
import { MediaModel, MediaType } from "shared/types";
import { Loader } from "shared/ui-kit";

import * as appTheme from "theme/default";

import { FormBase } from "./style";

import { SelectInput } from "../../shared";
import { InputWrapper } from "../../shared/style";

const genderOptions = [
	{ label: "Male", value: "man" },
	{ label: "Female", value: "woman" },
	{ label: "Other", value: "other" }
];

const monthOptions = [
	{ label: "January", value: "1" },
	{ label: "February", value: "2" },
	{ label: "March", value: "3" },
	{ label: "April", value: "4" },
	{ label: "May", value: "5" },
	{ label: "June", value: "6" },
	{ label: "July", value: "7" },
	{ label: "August", value: "8" },
	{ label: "September", value: "9" },
	{ label: "October", value: "10" },
	{ label: "November", value: "11" },
	{ label: "December", value: "12" }
];

interface FillProfileBirthdayProps {
	fillProfileBaseUrl: string;
	memberHomeUrl: string;
	maritalStatusPageUrl: string;
}

const FillProfileBirthday: FC<FillProfileBirthdayProps> = ({
	fillProfileBaseUrl,
	memberHomeUrl,
	maritalStatusPageUrl
}) => {
	const history = useHistory();
	const { getData: getCommunityData } = useCommunity();
	const { workspace, isAkinaMode } = getCommunityData();

	const { updatePersona, setPersona, createPersona, getData: getPersonaData } = usePersona();
	const { persona } = getPersonaData();

	const { getUser, confirmInvitation, getData: getAuthData } = useAuth();
	const { invitationData } = getAuthData();

	const { createMediaForUploaded } = useMedia();

	const {
		control,
		handleSubmit,
		formState: { isValid, isDirty, isSubmitting },
		errors,
		setValue,
		setError,
		clearErrors,
		reset
	} = useForm({
		mode: "onChange"
	});

	const { month, day, year } = useWatch({ control, name: ["month", "day", "year"] });

	const isMobile = useMediaQuery(appTheme.default.breakpoints.down("xs"));

	const dayOptions = useMemo(() => {
		const days: { label: string; value: string }[] = [];
		for (let i = 1; i <= 31; i++) {
			days.push({ label: i.toString(), value: i.toString() });
		}
		return days;
	}, []);

	const yearOptions = useMemo(() => {
		const years: { label: string; value: string }[] = [];
		const currentYear = DateTime.local().year;
		for (let i = currentYear; i >= currentYear - 100; i--) {
			years.push({ label: i.toString(), value: i.toString() });
		}
		return years;
	}, []);

	useEffect(() => {
		if (persona?.personalInfo?.birthday) {
			const correctDate = DateTime.fromISO(persona?.personalInfo?.birthday.toString());
			if (correctDate.isValid) {
				reset({
					day: dayOptions.find(x => x.value === correctDate.day.toString()),
					month: monthOptions.find(x => x.value === correctDate.month.toString()),
					year: yearOptions.find(x => x.value === correctDate.year.toString())
				});
			}
		}
	}, [persona, reset, dayOptions, yearOptions]);

	useEffect(() => {
		if (month && day && year) {
			const correctDate = DateTime.fromObject({ day: day.value, month: month.value, year: year.value });
			if (correctDate.isValid) {
				const currentTime = DateTime.local();
				const diff = currentTime.diff(correctDate, ["years"]);

				if (correctDate > currentTime) {
					setValue("date", null);
					setError("date", {
						type: "manual",
						message: "Select past Date"
					});
				} else if (diff.years < 16) {
					setValue("date", null);
					setError("date", {
						type: "manual",
						message: "You must be 16 years old or over"
					});
				} else {
					setValue("date", correctDate);
					clearErrors("date");
				}
			} else {
				setValue("date", null);
				setError("date", {
					type: "manual",
					message: "Invalid Date"
				});
			}
		}
	}, [month, day, year, setValue, setError, clearErrors]);

	const onSubmit = async data => {
		const personalInfo = {
			birthday: data.date.toString(),
			birthdayHidden: true,
			gender: data.gender?.value,
			genderHidden: true
		};

		// just update birthday if user still do not have it, just because it's a required field now
		if (persona?.personaId) {
			const updatedPersonaInfo = {
				...persona,
				personalInfo
			};

			if (persona?.photos?.length && typeof persona.photos[0] === "string") {
				const profilePhotoMedia = await createMediaForUploaded({
					personaId: Number(persona.personaId),
					type: MediaType.profilePhoto,
					fileUrl: `${persona!.photos[0]}`
				});
				updatedPersonaInfo.photos = [profilePhotoMedia as MediaModel];
			}

			await updatePersona(updatedPersonaInfo, true);

			history.push(memberHomeUrl);
		} else {
			if (isAkinaMode) {
				setPersona({ personalInfo }, true);
				history.push(maritalStatusPageUrl);
			} else {
				const personaData = await createPersona(persona!.firstName, persona!.lastName);
				const profilePhotoMedia = await createMediaForUploaded({
					personaId: Number(personaData?.personaId),
					type: MediaType.profilePhoto,
					fileUrl: `${persona!.photos[0]}`
				});

				await updatePersona(
					{
						firstName: persona?.firstName.trim(),
						lastName: persona?.lastName.trim(),
						personaId: Number(personaData?.personaId),
						photos: [profilePhotoMedia as MediaModel],
						personalInfo
					},
					true
				);

				if (invitationData?.communityUrl && invitationData?.invitationId) {
					const user = await getUser();
					confirmInvitation({
						userId: user.userId,
						invitationId: invitationData.invitationId,
						communityUrl: invitationData.communityUrl
					});
				}

				history.push(memberHomeUrl);
			}
		}
	};

	return (
		<FormBase onSubmit={handleSubmit(onSubmit)}>
			<FormBase.WorkspaceLogoWrapper
				style={{ backgroundImage: `url(${workspace?.meta?.img || workspace?.meta?.coverImage || ""})` }}
			/>
			<FormBase.Title>What’s your date of birth and gender?</FormBase.Title>
			<FormBase.Subtitle>You can always hide this information from your profile details page.</FormBase.Subtitle>
			<FormBase.InfoForm>
				<FormBase.Label>
					Date of Birth <FormBase.Asterisk>*</FormBase.Asterisk>
				</FormBase.Label>
				<FormBase.Row>
					<Controller name="date" control={control} render={() => <input type="text" hidden />} />
					<FormBase.Column className={"three-col"}>
						<Controller
							name="month"
							control={control}
							rules={{
								required: "Required",
								validate: val => {
									if (!val.value || !val.label) {
										return "Required";
									}
								}
							}}
							render={({ onChange, value }) => (
								<InputWrapper>
									<SelectInput
										id="month"
										name="month"
										onChange={onChange}
										value={value ? (isMobile ? value.label.slice(0, 3) : value.label) : null}
										placeholder="Month"
										maxHeight={260}
										error={errors?.month?.message || errors?.date?.message}
										options={monthOptions}
										displayOnly
									/>
								</InputWrapper>
							)}
						/>
					</FormBase.Column>
					<FormBase.Column className={"three-col"}>
						<Controller
							name="day"
							control={control}
							rules={{
								required: "Required",
								validate: val => {
									if (!val.value || !val.label) {
										return "Required";
									}
								}
							}}
							render={({ onChange, value }) => (
								<InputWrapper>
									<SelectInput
										id="day"
										name="day"
										onChange={onChange}
										value={value ? value.label : null}
										placeholder="Day"
										label=" "
										maxHeight={260}
										error={errors?.day?.message || errors?.date?.message ? " " : ""}
										options={dayOptions}
										displayOnly
									/>
								</InputWrapper>
							)}
						/>
					</FormBase.Column>
					<FormBase.Column className={"three-col"}>
						<Controller
							name="year"
							control={control}
							rules={{
								required: "Required",
								validate: val => {
									if (!val.value || !val.label) {
										return "Required";
									}
								}
							}}
							render={({ onChange, value }) => (
								<InputWrapper>
									<SelectInput
										id="year"
										name="year"
										onChange={onChange}
										value={value ? value.label : null}
										placeholder="Year"
										label=" "
										maxHeight={260}
										error={errors?.year?.message || errors?.date?.message ? " " : ""}
										options={yearOptions}
										displayOnly
									/>
								</InputWrapper>
							)}
						/>
					</FormBase.Column>
				</FormBase.Row>
				<FormBase.Row>
					<FormBase.Column className={"full"}>
						<Controller
							name="gender"
							control={control}
							defaultValue={genderOptions.find(x => x.value === persona?.personalInfo?.gender)}
							render={({ onChange, value }) => (
								<InputWrapper>
									<SelectInput
										id="gender"
										name="gender"
										onChange={onChange}
										value={value ? value.label : null}
										placeholder="Select Gender"
										label="Gender"
										maxHeight={260}
										error={errors?.gender?.message}
										options={genderOptions}
										displayOnly
									/>
								</InputWrapper>
							)}
						/>
					</FormBase.Column>
				</FormBase.Row>
			</FormBase.InfoForm>
			<FormBase.Footer>
				<FormBase.ActionBtn
					palette={"basic"}
					buttonTheme={"light"}
					type={"button"}
					onClick={() => history.push(fillProfileBaseUrl)}
				>
					Back
				</FormBase.ActionBtn>
				<FormBase.ActionBtn
					id="next"
					className={isAkinaMode && "active"}
					type={"submit"}
					disabled={!isValid || (persona?.personalInfo?.gender ? false : !isDirty) || isSubmitting}
					leftIcon={
						isSubmitting ? (
							<Loader size="1rem" show={true} color={isAkinaMode ? "white" : "primary"} variant="indeterminate" />
						) : undefined
					}
				>
					Next
				</FormBase.ActionBtn>
			</FormBase.Footer>
		</FormBase>
	);
};

export default FillProfileBirthday;
