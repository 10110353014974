import styled from "styled-components";

import { Button } from "shared/ui-kit";

export const BuyButton = styled(Button)`
	width: 132px;
	min-height: 40px;

	border-radius: 8px;

	margin: 0;

	display: flex;
	align-items: center;

	font-size: 14px;
	line-height: 1.14;
	font-weight: 600;

	&:hover {
		color: white;
	}

	.MuiButton-label {
		display: flex;
		align-items: center;

		svg {
			width: 20px;
			height: 20px;
			margin-left: 4px;
		}
	}
`;
