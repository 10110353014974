import React from "react";

import { FormControl as MuiFormControl } from "@material-ui/core";
import { KeyboardDatePicker, KeyboardTimePicker } from "@material-ui/pickers";
import styled from "styled-components";

import { Box, Button } from "shared/ui-kit";

export const CreateButton = styled(Button)`
	height: 48px;
	font-size: 16px;
	font-weight: 600;
	line-height: 1.25;
	color: white;
`;

export const FormControl = styled(MuiFormControl)`
	margin: 0 0 28px 0;
`;

export const UploaderWrapper = styled(Box)`
	width: 100%;
	margin-top: 24px;

	.item-description {
		display: none;
	}

	> div {
		> div {
			width: 100%;
		}
	}

	.preview-section {
		.item-img-section {
			max-width: 120px;
			max-height: 120px;
			img {
				max-width: 100px;
			}
		}
	}
	.explorer-uploader-label-text {
		color: #222b45;
	}
	.explorer-uploader .description .anchor {
		color: #6173fe;
	}

	.explorer-uploader {
		${props => props.theme.breakpoints.down("sm")} {
			padding: 28px 24px;
		}
		.label {
			${props => props.theme.breakpoints.down("sm")} {
				font-size: 14px;
			}
		}
	}

	${props => props.theme.breakpoints.down("sm")} {
		margin-top: 15px;
	}
`;

export const ComponentGroup = styled(Box)`
	display: flex;
	flex-direction: row;
	justify-content: space-between;

	${props => props.theme.breakpoints.down("sm")} {
		flex-direction: column;
		gap: 20px;
	}
`;

export const NarrowPicker = styled(props => <KeyboardDatePicker {...props} />)`
	.MuiInputBase-root {
		min-width: 190px;
		height: 48px;
	}

	.MuiInputBase-input,
	.MuiOutlinedInput-input {
		font-size: 15px;
		line-height: 1.33;
		color: ${({ isSelectedDate }) => (isSelectedDate ? "black" : "#8f9bb3")};
	}

	.MuiOutlinedInput-adornedStart {
		padding: 0 16px;
	}

	.MuiButtonBase-root {
		padding: 0;
	}

	.MuiOutlinedInput-notchedOutline {
		top: -5px !important;
	}

	fieldset {
		border-color: #e4e9f2;
	}
`;

export const NarrowTimePicker = styled(props => <KeyboardTimePicker {...props} />)`
	.MuiInputBase-root {
		min-width: 190px;
		height: 48px;
	}

	.MuiInputBase-input,
	.MuiOutlinedInput-input {
		font-size: 15px;
		line-height: 1.33;
		color: ${({ isSelectedDate }) => (isSelectedDate ? "black" : "#8f9bb3")};
	}

	.MuiOutlinedInput-adornedStart {
		padding: 0 16px;
	}

	.MuiButtonBase-root {
		padding: 0;
	}

	.MuiOutlinedInput-notchedOutline {
		top: -5px !important;
	}

	fieldset {
		border-color: #e4e9f2;
	}
`;

export const DateWrapper = styled(Box)`
	width: 59%;

	${props => props.theme.breakpoints.down("sm")} {
		width: 100%;
	}
`;

export const TimeWrapper = styled(Box)`
	width: 39%;

	${props => props.theme.breakpoints.down("sm")} {
		width: 100%;
	}
`;
