import styled from "styled-components";

import { Box, Button, Text } from "shared/ui-kit";

export const Container = styled(Box)`
	max-width: 960px;
	margin: 0 auto;
	padding: 0 16px;
`;

export const MoreVideos = styled(Box)`
	h3 {
		font-size: 22px;
		font-weight: bold;
		color: #222b45;
	}
`;

MoreVideos.Headline = styled(Box)`
	margin: 24px 0 16px;
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

MoreVideos.Row = styled(Box)`
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
	grid-gap: 16px;
`;

MoreVideos.Video = styled(Box)``;

export const UnlockedBlockWrapper = styled(Box)`
	position: relative;
	button {
		border-radius: 8px;
		margin-left: 0;
	}
`;

export const MoreItemsWrapper = styled(Box)`
	display: grid;
	grid-template-columns: ${({ fourItems }) => `repeat(auto-fit, minmax(${fourItems ? "260px" : "320px"}, 1fr))`};
	grid-gap: 16px;
	margin-top: 24px;
`;

export const ItemWrapper = styled(Box)``;

export const SkeletonWrapper = styled(Box)`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 16px;
	.row {
		display: flex;
		align-items: center;
	}
	.number {
		margin-right: 6px;
	}
	.thumbnail {
		margin-right: 16px;
	}
	.headline {
		margin-bottom: 4px;
	}
	.MuiSkeleton-text {
		transform: scale(1, 1);
	}
	.podcast-row {
		width: 45%;
	}
	.podcast-right-row {
		width: 50%;
		justify-content: space-between;
	}
	&.podcast-skeleton {
		padding-right: 12px;
		.play {
			margin-right: 20px;
		}
	}
`;

export const AddContentButton = styled(Button)`
	border-radius: 8px;
	margin-left: auto;

	& > span > span {
		margin-left: 6px;
		font-weight: bold;
	}
`;

export const ContentHeaderWrapper = styled(Box)`
	display: flex;
	justify-content: space-between;
`;

export const ContentHeader = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

export const ContentText = styled(Text)`
	margin: 32px 0 16px 0;
`;

export const EmptyContentWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
`;
