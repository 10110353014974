import React, { useCallback, useEffect, useMemo, useState } from "react";

import { DateTime } from "luxon";

import { useHistory } from "react-router";

import { useBoards, useCommunity, useMemberRoutes, usePersona, usePosts, useUser } from "shared/hooks";
import { Button, Icon, Text } from "shared/ui-kit";

import { shorten } from "utils/shortenText";

import { StyledMailIcon, Wrapper } from "./style";

import { Slider } from "..";

const GetStartedBlock = () => {
	const { getData: getUserData } = useUser();
	const { user } = getUserData();

	const { setCreateUpdatePostDialog } = usePosts();

	const { getData: getBoardsData } = useBoards();
	const { boardsCount } = getBoardsData();

	const { communityColors } = useCommunity();

	const { getData: getPersonaData } = usePersona();
	const { persona } = getPersonaData();

	const { getMemberRoutesData } = useMemberRoutes();
	const { routes } = getMemberRoutesData();

	const [color, setColor] = useState<string>();

	useEffect(() => {
		setColor(communityColors.primary);
	}, [communityColors.primary]);

	const [dismissedContent, setDismissedContent] = useState<string[]>([]);

	const hasNoPosts = useMemo(
		() => user?.verified && !persona?.counters?.postCount && !!boardsCount,
		[user?.verified, persona?.counters?.postCount, boardsCount]
	);

	const handleCreatePost = useCallback(() => {
		setCreateUpdatePostDialog({ isOpen: true });
	}, [setCreateUpdatePostDialog]);

	const history = useHistory();

	const handleInvite = useCallback(
		(id: string) => history.push(`${routes?.member.fundraiser.getPath()}/${id}?mode=inviteContact`),
		[history, routes?.member.fundraiser]
	);

	const tasks = useMemo(() => {
		const tasksList: {
			id: string;
			icon: JSX.Element;
			headline: string;
			message: string;
			primary?: {
				text: string;
				onClick: () => void;
			};
			showDismiss?: boolean;
		}[] = [];

		if (hasNoPosts) {
			tasksList.push({
				id: "create-first-post",
				icon: <Icon name="comment" fill={color} />,
				headline: "Create first post",
				message: "Now that you are part of the community, make your voice heard by creating your first post.",
				primary: {
					text: "Create",
					onClick: handleCreatePost
				},
				showDismiss: true
			});
		}

		if (!!user?.fundraiserParticipantOfIds?.length) {
			user.fundraiserParticipantOfIds.forEach((fund, i) => {
				tasksList.push({
					id: `${fund.name.toLowerCase()}-${i}`,
					icon: <StyledMailIcon className="mail-icon-fill" />,
					headline: "Add Fundraiser Contacts",
					message: `You have a ${shorten(fund.name, 40)} fundraiser coming on ${DateTime.fromJSDate(
						new Date(fund.startDate)
					).toFormat("LLL dd")} at ${DateTime.fromJSDate(new Date(fund.startDate)).toFormat(
						"ha"
					)}. Start adding your fundraiser contacts.`,
					primary: {
						text: "Add Contacts",
						onClick: () => handleInvite(fund._id)
					},
					showDismiss: true
				});
			});
		}

		return tasksList.filter(t => !dismissedContent.includes(t.id));
	}, [hasNoPosts, user?.fundraiserParticipantOfIds, color, handleCreatePost, handleInvite, dismissedContent]);

	if (!tasks.length) return null;

	return (
		<Wrapper>
			<Text variant="h2">Tasks</Text>
			<Wrapper.TaskWrapper>
				<Slider slickPadding count={tasks.length} itemSize={320}>
					{tasks.map(t => (
						<Wrapper.Task key={t.id}>
							<Wrapper.TaskHeader color={color}>
								{t.icon}
								<Text variant="h4">{t.headline}</Text>
							</Wrapper.TaskHeader>
							<Text>{t.message}</Text>
							<Wrapper.TaskActions color={color}>
								{t.showDismiss && (
									<Button size="small" className="secondary" onClick={() => setDismissedContent(c => [...c, t.id])}>
										Dismiss
									</Button>
								)}
								{t.primary && (
									<Button size="small" onClick={t.primary.onClick}>
										{t.primary.text}
									</Button>
								)}
							</Wrapper.TaskActions>
						</Wrapper.Task>
					))}
				</Slider>
			</Wrapper.TaskWrapper>
		</Wrapper>
	);
};

export default GetStartedBlock;
