import { useMemo } from "react";

import { AppConfig } from "config";

const { USE_DATA_LAYER_EVENTS } = AppConfig.GLOBAL_CONSTANTS;

const useGTM = () => {
	const methods = useMemo(
		() => ({
			addEvent({
				event = "gaEvent",
				Category = "Form",
				Action,
				Label
			}: {
				event?: string;
				Category?: string;
				Action: string;
				Label: string;
			}): void {
				if (USE_DATA_LAYER_EVENTS) {
					// @ts-expect-error: custom valid function
					window?.dataLayer.push({
						event,
						Category,
						Action,
						Label
					});
				}
			}
		}),
		[]
	);

	return useMemo(
		() => ({
			...methods
		}),
		[methods]
	);
};

export default useGTM;
