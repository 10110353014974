import React, { FC, ReactNode, useCallback, useEffect, useMemo, useState } from "react";

import LocationOnIcon from "@material-ui/icons/LocationOn";
import { DateTime } from "luxon";

import { useHistory } from "react-router";

import { ReactComponent as TicketIcon } from "assets/icons/icon-ticket.svg";
import { ReactComponent as VolunteerIcon } from "assets/icons/volunteer-slot.svg";
import { ConfirmActionModal, MenuDots } from "shared/Components";
import { useFeature, useMemberRoutes, useUser, useVolunteers } from "shared/hooks";
import { EventModel, MembersModel, VolunteerSlot } from "shared/types";
import { Attendee } from "shared/types/EventModel";
import { EventSectionsEnum } from "shared/types/EventsTypes";
import { VolunteerMember } from "shared/types/VolunteersTypes";
import { Box, Button, Icon, ReadMore, Text } from "shared/ui-kit";
import getCount from "utils/getCount";

import { InfoItem, List, SeeAllBtn } from "../../..";
import { ItemLink } from "../../../Contributors/style";
import EventMap from "../../../GoogleMap";
import { CancelButton } from "../../../Volunteer/styles";
import CreateUpdateVolunteerSlot from "../../components/CreateUpdateVolunteerSlot";
import SignUpVolunteer from "../../components/SignUpVolunteer";
import {
	EventDetails,
	EventInfo,
	EventLink,
	EventMembers,
	EventText,
	IconWrapper,
	MembersList,
	VolunteerSlotsList
} from "../../styles";

interface EventProps {
	event: EventModel | null;
	admins: MembersModel[];
	communityColors;
	isPrivilegedRole: boolean;
	handleSwitchSection?: (section: EventSectionsEnum) => void;
	isEventEnded: boolean;
}

const EventsAbout: FC<EventProps> = ({
	event,
	communityColors,
	admins,
	isPrivilegedRole,
	handleSwitchSection,
	isEventEnded
}) => {
	const zone = useMemo(() => Intl.DateTimeFormat().resolvedOptions().timeZone, []);
	const [hoverVolunteerId, setHoverVolunteerId] = useState<string>();
	const [volunteerSlotDeleteId, setVolunteerSlotDeleteId] = useState<string>();
	const [signUpVolunteer, setSignUpVolunteer] = useState<{
		open: boolean;
		signUpVolunteerDetail?: VolunteerSlot;
		eventDetail?: EventModel;
	}>({
		open: false
	});

	const { getProfilePicture, getData: getUserData } = useUser();
	const { user } = getUserData();

	const {
		deleteVolunteerSlot,
		getVolunteerSlots,
		setCancelVolunteerSlot,
		joinLeaveForVolunteerSlot,
		setCreateUpdateDialog,
		getData: getVolunteerData
	} = useVolunteers();
	const { volunteersSlots, cancelVolunteerSlot, createUpdateVolunteerDialog } = getVolunteerData();
	const { isCommunityFeatureEnabled } = useFeature();

	const eventRange = useMemo(() => {
		if (event) {
			const start = DateTime.fromISO(event.startTime ? event.startTime : "", { zone }).toFormat("MMM d, h:mm a");
			const end = DateTime.fromISO(event.endTime ? event.endTime : "", { zone }).toFormat("MMM d, h:mm a");

			return `${start} - ${end}`;
		}
	}, [event, zone]);

	const byAdmin = useMemo(
		() => admins.find(admin => `${admin.firstName} ${admin.lastName}` === event?.by),
		[admins, event]
	);

	const isEventAdmin = useMemo(() => byAdmin && user && byAdmin?.personaId === user?.activeProfile, [byAdmin, user]);

	const volunteersSlotList = useMemo(() => volunteersSlots.reverse().slice(-3) || [], [volunteersSlots]);
	const areSlotsMoreThan4 = useMemo(() => volunteersSlots.length > 3, [volunteersSlots.length]);

	const isAlreadySignedUp = useCallback(
		(members: VolunteerMember[]) => members.find(m => m.personaId === user?.activeProfile),
		[user?.activeProfile]
	);

	const slotsRemainingQuantity = useCallback((quantity, filledQuantity) => quantity - filledQuantity, []);

	const history = useHistory();

	const { getMemberRoutesData } = useMemberRoutes();
	const { routes } = getMemberRoutesData();

	const RenderItem = useCallback(
		(item: MembersModel) => (
			<ItemLink onClick={() => history.push(`${routes?.member.profile.getPath()}/${item.personaId}`)}>
				<InfoItem avatarUrl={item.photos[0].profilePicture} title={`${item.firstName} ${item.lastName}`} />
			</ItemLink>
		),
		[history, routes?.member.profile]
	);

	const RenderGuest = useCallback((item: Attendee) => <InfoItem avatarUrl={item.photo} title={`${item.name}`} />, []);

	useEffect(() => {
		if (event?.eventId) {
			getVolunteerSlots({ eventId: event.eventId, sortOrder: 1, offset: 1, limit: 4 });
		}
	}, [event?.eventId, getVolunteerSlots]);

	const getOptions = useCallback(
		(rowData: VolunteerSlot) => {
			const options: {
				name: string;
				icon: ReactNode;
				onClick: () => void;
			}[] = [
				{
					name: "Edit Details",
					icon: <Icon group={"filled"} fill={"#8f9bb3"} name={"pencil"} width={20} height={20} />,
					onClick: () => setCreateUpdateDialog({ open: true, editDetails: rowData, action: "update" })
				},
				{
					name: "Delete",
					icon: <Icon group={"filled"} fill={"#8f9bb3"} name={"trash"} width={20} height={20} />,
					onClick: () => setVolunteerSlotDeleteId(rowData._id)
				}
			];

			if (isEventAdmin || isPrivilegedRole) {
				if (
					!isAlreadySignedUp(rowData.members) &&
					!(slotsRemainingQuantity(rowData.quantity, rowData.filledQuantity) === 0)
				) {
					options.unshift({
						name: "Sign Up",
						icon: <Icon name="user-plus" group="filled" width={18} height={18} fill={"#8f9bb3"} />,
						onClick: () => {
							setSignUpVolunteer({
								open: true,
								signUpVolunteerDetail: rowData,
								eventDetail: event || undefined
							});
						}
					});
				} else if (isAlreadySignedUp(rowData.members)) {
					options.unshift({
						name: "Cancel",
						icon: <Icon name="user-minus" group="filled" width={18} height={18} fill={"#8f9bb3"} />,
						onClick: () => setCancelVolunteerSlot(rowData)
					});
				}
			}

			return options;
		},
		[
			setVolunteerSlotDeleteId,
			setCreateUpdateDialog,
			isAlreadySignedUp,
			slotsRemainingQuantity,
			setSignUpVolunteer,
			setCancelVolunteerSlot,
			isEventAdmin,
			event,
			isPrivilegedRole
		]
	);

	const volunteerDeleteHandler = useCallback(async () => {
		if (volunteerSlotDeleteId) {
			const success = await deleteVolunteerSlot(volunteerSlotDeleteId, event?.eventId);
			success && setVolunteerSlotDeleteId(undefined);
		}
	}, [setVolunteerSlotDeleteId, deleteVolunteerSlot, volunteerSlotDeleteId, event?.eventId]);

	const volunteerCancelHandler = useCallback(async () => {
		if (cancelVolunteerSlot?._id) {
			const userDetail = {
				personaId: user?.activeProfile,
				quantity: cancelVolunteerSlot?.members.find(m => m.personaId === user?.activeProfile)?.quantity || 0,
				firstName: user?.profiles[0].firstName,
				lastName: user?.profiles[0].lastName,
				photos: [
					{
						profilePicture: getProfilePicture(user)
					}
				]
			};

			joinLeaveForVolunteerSlot(cancelVolunteerSlot?._id, { action: "leave" }, userDetail);
			setCancelVolunteerSlot(undefined);
		}
	}, [setCancelVolunteerSlot, joinLeaveForVolunteerSlot, cancelVolunteerSlot, getProfilePicture, user]);

	return (
		<>
			<EventInfo>
				<EventDetails>
					<Text variant="h4">Event Details</Text>
					<EventDetails.EventInfoRows>
						{eventRange && (
							<EventDetails.EventInfoRow>
								<IconWrapper>
									<Icon name="clock" fill={communityColors.primary} />
								</IconWrapper>
								<Text>{eventRange}</Text>
							</EventDetails.EventInfoRow>
						)}
						{/* <EventDetails.EventInfoRow>
							<IconWrapper>
								<Icon name="globe" group="linear" fill={communityColors.primary} />
							</IconWrapper>
							<Box>
								<Text>Public event</Text>
								<Text className="secondary">Anyone can attend</Text>
							</Box>
						</EventDetails.EventInfoRow> */}
						<EventDetails.EventInfoRow>
							<IconWrapper>
								<Icon name="user-friend" group="filled" fill={communityColors.primary} />
							</IconWrapper>
							<Box>
								<Text>
									{getCount({
										count: (event?.attendees?.length || 0) + (event?.interests?.length || 0),
										singular: "",
										plural: ""
									})}
								</Text>
								<Text className="secondary">People responded</Text>
							</Box>
						</EventDetails.EventInfoRow>
						{event?.location?.address && (
							<EventDetails.EventInfoRow>
								<IconWrapper>
									<LocationOnIcon htmlColor={communityColors.primary} />
								</IconWrapper>
								<Box>
									<Text>{event!.location!.address}</Text>
									<Text className="secondary">{event!.location!.address}</Text>
								</Box>
							</EventDetails.EventInfoRow>
						)}
						{event?.ticketUrl && (
							<EventDetails.EventInfoRow>
								<IconWrapper>
									<TicketIcon fill={communityColors.primary} />
								</IconWrapper>
								<Box>
									<Text>Buy Tickets</Text>
									<Text className="secondary">{event!.ticketUrl}</Text>
								</Box>
							</EventDetails.EventInfoRow>
						)}
						{byAdmin && (
							<EventDetails.EventInfoRow>
								{!!byAdmin!.photos.length && (
									<IconWrapper>
										<img alt={event?.by} src={byAdmin.photos[0].profilePicture} />
									</IconWrapper>
								)}
								<Text>
									by {byAdmin?.firstName} {byAdmin?.lastName}
								</Text>
							</EventDetails.EventInfoRow>
						)}
						{event?.onlineUrl && (
							<EventDetails.EventInfoRow>
								<IconWrapper>
									<Icon name="globe" group="linear" fill={communityColors.primary} />
								</IconWrapper>
								<Box>
									<Text>Event Link</Text>
									<EventLink href={event!.onlineUrl} target="_blank" className="secondary">
										<EventText>{event!.onlineUrl}</EventText>
									</EventLink>
								</Box>
							</EventDetails.EventInfoRow>
						)}
					</EventDetails.EventInfoRows>
					<EventDetails.Description>
						<Text className="title">Description</Text>
						<ReadMore text={event?.description} showCharCount={300} wrapClassName="" wrapTextClassName="" />
					</EventDetails.Description>
					{!!event?.location?.lon && !!event?.location?.lat && (
						<EventMap lat={event.location.lat} lng={event.location.lon} />
					)}
				</EventDetails>
				<EventMembers className="flex flex-col gap-3">
					{isCommunityFeatureEnabled("enableVolunteers") && (
						<>
							{!!volunteersSlotList?.length && (
								<MembersList className="p-2">
									<Text variant="h4" className="px-2 mb-4 mt-2">
										Volunteers needed
									</Text>
									{volunteersSlotList.map((volunteer, i) => (
										<VolunteerSlotsList
											key={volunteer._id}
											index={i}
											className={`vList_${i} mb-0 p-2 rounded-md`}
											onMouseEnter={() => {
												setHoverVolunteerId(volunteer._id);
											}}
											onMouseLeave={() => {
												setHoverVolunteerId(undefined);
											}}
										>
											<Box className="flex">
												<IconWrapper isSquareShape>
													<>
														{volunteer._id === hoverVolunteerId &&
														!isAlreadySignedUp(volunteer.members) &&
														!(isEventAdmin || isPrivilegedRole) &&
														!(slotsRemainingQuantity(volunteer.quantity, volunteer.filledQuantity) === 0) ? (
															<Button
																className="min-w-full h-full p-0"
																removeSideMargin
																onClick={() =>
																	setSignUpVolunteer({
																		open: true,
																		signUpVolunteerDetail: volunteer,
																		eventDetail: event || undefined
																	})
																}
																disabled={isEventEnded}
															>
																<Icon
																	name="plus"
																	group="filled"
																	width={18}
																	height={18}
																	fill={isEventEnded ? "#000000" : "white"}
																/>
															</Button>
														) : volunteer._id === hoverVolunteerId &&
														  isAlreadySignedUp(volunteer.members) &&
														  !(isEventAdmin || isPrivilegedRole) ? (
															<CancelButton
																className="min-w-full h-full p-0"
																onClick={() => setCancelVolunteerSlot(volunteer)}
																disabled={isEventEnded}
															>
																<Icon name="minus" group="linear" width={35} height={35} fill={"#000000"} />
															</CancelButton>
														) : (
															<VolunteerIcon fill={communityColors.primary} />
														)}
													</>
												</IconWrapper>
												<Box>
													<Text variant="h6">{volunteer.need}</Text>
													<Text className="flex items-center">
														{volunteer.quantity - volunteer.filledQuantity === 0 && (
															<Icon className="mr-1" fill={"#23b34a"} name={"check-circle"} width={18} height={18} />
														)}
														{volunteer.quantity - volunteer.filledQuantity === 0
															? `Slots filled: ${volunteer.filledQuantity} of ${volunteer.quantity}`
															: `Available slots: ${volunteer.quantity - volunteer.filledQuantity} of ${
																	volunteer.quantity
															  }`}
													</Text>
													<Text>
														{isAlreadySignedUp(volunteer.members) &&
															`Your slots: ${isAlreadySignedUp(volunteer.members)?.quantity}`}
													</Text>
												</Box>
											</Box>
											{(isEventAdmin || isPrivilegedRole) && (
												<Box className="menuDotsContainer">
													<MenuDots
														options={getOptions(volunteer)}
														rounded
														onClick={() => {
															(document.querySelector(`.vList_${i}`)?.lastChild as HTMLElement)?.classList.add(
																"visible"
															);
														}}
														onClose={() => {
															(document.querySelector(`.vList_${i}`)?.lastChild as HTMLElement)?.classList.remove(
																"visible"
															);
														}}
													/>
												</Box>
											)}
										</VolunteerSlotsList>
									))}
									{areSlotsMoreThan4 && (
										<SeeAllBtn
											click={() => {
												handleSwitchSection && handleSwitchSection(EventSectionsEnum.Volunteers);
											}}
										>
											See All
										</SeeAllBtn>
									)}
								</MembersList>
							)}
						</>
					)}
					<List
						title="Admins & Speakers"
						items={admins.slice(0, 3) || []}
						totalCount={10}
						renderItem={RenderItem}
						preview
					/>
					{event?.attendees && !!event?.attendees?.length && (
						<List
							title="Guests"
							items={event?.attendees.slice(0, 3) || []}
							totalCount={10}
							renderItem={RenderGuest}
							preview
						/>
					)}
				</EventMembers>
			</EventInfo>
			<ConfirmActionModal
				open={!!volunteerSlotDeleteId}
				onClose={() => setVolunteerSlotDeleteId(undefined)}
				onConfirm={volunteerDeleteHandler}
				title={"Delete Volunteering"}
				bodyText={"Are you sure you want to permanently delete your volunteering slot?"}
				closeText="Keep"
				confirmText="Delete"
				reverseButtons
				confirmButtonTheme="danger"
			/>
			{signUpVolunteer.open && (
				<SignUpVolunteer
					open={signUpVolunteer.open}
					volunteerSlotDetail={signUpVolunteer.signUpVolunteerDetail}
					onClose={() => {
						setSignUpVolunteer({ open: false, signUpVolunteerDetail: undefined, eventDetail: undefined });
					}}
					eventDetail={signUpVolunteer.eventDetail}
					eventRange={eventRange}
				/>
			)}
			<ConfirmActionModal
				open={!!cancelVolunteerSlot?._id}
				onClose={() => setCancelVolunteerSlot(undefined)}
				onConfirm={volunteerCancelHandler}
				title={"Cancel Volunteering"}
				bodyText={"Are you sure you want to cancel your volunteering?"}
				closeText="Keep"
				confirmText="Cancel"
				reverseButtons
				confirmButtonTheme="danger"
			/>
			{createUpdateVolunteerDialog && createUpdateVolunteerDialog.open && (
				<CreateUpdateVolunteerSlot
					open={createUpdateVolunteerDialog.open}
					volunteerSlotDetail={createUpdateVolunteerDialog?.editDetails}
					onClose={() => {
						setCreateUpdateDialog({ open: false, editDetails: undefined });
					}}
					event={event}
					action={createUpdateVolunteerDialog.action || "create"}
				/>
			)}
		</>
	);
};

export default EventsAbout;
