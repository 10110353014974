import { EmailStateModel } from "shared/types";
import { IStateProps } from "utils/StateOf";

import createStore from "utils/createStore";

import { TeamMembersModel } from "../../types";

export interface Values {
	users: TeamMembersModel[];
	usersEmails: string[];
	toastMessage?: string;
	modalIsOpen?: boolean;
	keyword?: string | null;
	emailAddresses: EmailStateModel[];
	role?: string;
	isInvited: boolean | null;
	showToastMessage?: boolean;
	editMemberRole?: string;
	modalTitle?: string;
	modalContent?: string;
	modalFooterActionsText?: { cancelText: string; submitText: string; cancelId?: string; submitId?: string };
	confirmActionMessage?: string;
	apiRequest?: any;
	apiRequestParams?: any;
	page: number;
	teamMemberShowPerPage: number;
	totalCount: number;
	loadingTeamMembers: boolean;
	isLoading?: boolean;
}

export const initialState: IStateProps<Values> = {
	users: [],
	role: "",
	page: 1,
	teamMemberShowPerPage: 20,
	totalCount: 0,
	modalContent: "default",
	loadingTeamMembers: false,
	usersEmails: [],
	isInvited: null,
	emailAddresses: []
};

export default createStore<Values>({
	initialState
});
