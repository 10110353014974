import styled, { css } from "styled-components";

import { Button } from "shared/ui-kit";

export const StyledBtn = styled(Button)`
	border-radius: 8px;
	background-color: #edf1f7;
	margin-top: 8px;
	&:hover {
		color: #000000;
	}
	${({ isMemberView }) =>
		isMemberView &&
		css`
			box-shadow: 0 2px 4px 0 rgb(0 0 0 / 8%), inset 0 0 0 0.5px #c5cee0;
			margin: 0;
		`}
`;
