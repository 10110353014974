import React from "react";

import { Select } from "@material-ui/core";
import styled from "styled-components";

import { Box, Button, Text } from "shared/ui-kit";

export const ToggleWrapper = styled(Box)`
	width: 100%;
	height: 40px;

	display: flex;
	align-items: center;
	justify-content: center;

	margin-bottom: 16px;
`;

export const CardList = styled(Box)`
	text-align: center;
`;

export const EmptyCard = styled(Box)`
	width: 312px;
	height: 394px;

	border-radius: 12px;

	display: inline-flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	cursor: pointer;

	vertical-align: top;

	box-shadow: inset 0 0 0 0.5px #c5cee0, 0 2px 4px 0 rgba(0, 0, 0, 0.12);

	background-color: #fff;

	&.full-mode {
		width: 100%;
	}

	${props => props.theme.breakpoints.down("sm")} {
		width: 100%;
	}
`;

EmptyCard.PlusIconWrapper = styled(Box)`
	width: 80px;
	height: 80px;

	svg {
		width: 100%;
		height: 100%;
	}
`;

EmptyCard.Title = styled(Text)`
	font-size: 18px;
	font-weight: bold;
	line-height: 1.33;
	letter-spacing: normal;
	text-align: center;
	color: #222b45;

	margin-top: 24px;
`;

export const Card = styled(Box)``;

Card.Wrapper = styled(Box)`
	width: 312px;
	height: 394px;

	margin: 0 16px 16px 0;
	padding: 16px;

	border-radius: 12px;

	box-shadow: inset 0 0 0 0.5px #c5cee0, 0 2px 4px 0 rgba(0, 0, 0, 0.12);

	background-color: #fff;

	display: inline-flex;

	vertical-align: top;

	text-align: left;

	${props => props.theme.breakpoints.down("sm")} {
		width: 100%;
	}
`;

Card.Content = styled(Box)`
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	width: 100%;
	height: 100%;
`;

Card.Body = styled(Box)`
	display: block;
	width: 100%;
`;

Card.Header = styled(Box)`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

Card.Name = styled(Text)`
	font-size: 18px;
	font-weight: bold;
	line-height: 1.33;
	letter-spacing: normal;
	color: #222b45;
	white-space: nowrap;
	text-overflow: ellipsis;

	max-width: 170px;
	overflow: hidden;
`;

Card.DropdownWrapper = styled(Box)`
	width: 100px;
	height: 25px;
`;

Card.Select = styled(props => <Select {...props} />)`
	background: white;

	height: 100%;
	width: 100%;

	padding: 8px;

	border-radius: 4px;
	border: solid 1px #e4e9f2;

	&:before,
	&:after {
		display: none;
	}

	.MuiSelect-root {
		padding: 0;

		width: 100%;
		height: 100%;

		display: flex;
		align-items: center;
	}

	fieldset {
		height: 100%;
		top: 0;

		border-color: #c5cee0 !important;

		span {
			display: none;
		}
	}

	svg {
		width: 12px;
		color: #8f9bb3;
	}
`;

Card.SelectItem = styled(Box)`
	display: flex;
	align-items: center;

	padding: 8px;

	cursor: pointer;
`;

Card.SelectItemIcon = styled(Box)`
	width: 8px;
	height: 8px;

	border-radius: 50%;

	overflow: hidden;

	margin-right: 5px;

	background: #db2c33;

	&.success {
		background: #23b34a;
	}
`;

Card.SelectItemText = styled(Text)`
	font-size: 15px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.33;
	letter-spacing: normal;
	color: #222b45;
`;

Card.Price = styled(Text)`
	font-size: 36px;
	font-weight: bold;
	line-height: 1.33;
	letter-spacing: normal;
	color: #222b45;

	margin-top: 4px;
`;

Card.Description = styled(Text)`
	font-size: 13px;
	font-weight: normal;
	line-height: 1.54;
	letter-spacing: normal;
	color: #222b45;

	margin-top: 26px;

	word-break: break-word;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
`;

Card.DesciprionNote = styled(Text)`
	font-size: 13px;
	font-weight: 600;
	font-style: italic;
	line-height: 1.54;
	letter-spacing: normal;
	color: #222b45;

	margin-top: 16px;
`;

Card.CreatedDate = styled(Text)`
	font-size: 13px;
	font-weight: normal;
	line-height: 1.23;
	letter-spacing: normal;
	color: #8f9bb3;

	margin-top: 52px;
`;

Card.Subscribers = styled(Text)`
	font-size: 15px;
	font-weight: 600;
	line-height: 1.6;
	letter-spacing: normal;
	color: #222b45;

	margin-top: 4px;
`;

Card.Footer = styled(Box)`
	margin-top: 24px;

	display: flex;
	align-items: center;
	justify-content: space-between;
`;

Card.BtnWrapper = styled(Box)`
	width: calc(100% - 48px);
`;

Card.Btn = styled(Button)`
	margin: 0;

	width: 100%;

	border-radius: 4px;
`;

Card.MenuDotsWrapper = styled(Box)``;
