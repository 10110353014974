import React, { useCallback, useEffect, useMemo, useState } from "react";

import { pick } from "ramda";

import { useMessaging } from "modules/Messaging/Data";
import { InviteMembersModal } from "shared/Components";

import { SearchUsersModel } from "shared/types";

import { useLiveConversation } from "../../../Data/hooks";

interface InviteParticipantsModalProps {
	visible: boolean;
	onHide: () => void;
	personaId: number;
}

const InviteParticipantsModal: React.FC<InviteParticipantsModalProps> = ({ visible, onHide, personaId }) => {
	const [keyword, setKeyword] = useState("");

	const [offset, setOffset] = useState(1);

	const { getRecentConnections, getData: getMessagingData } = useMessaging();
	const { connectionList, loadingConnections, searchingConnections, allowLoadMoreConnections } = getMessagingData();

	const { bulkNotifyUsers } = useLiveConversation();

	useEffect(() => {
		getRecentConnections({
			offset,
			personaId,
			limit: 7,
			keyword
		});
	}, [getRecentConnections, offset, keyword, personaId]);

	const loadMore = useCallback(() => {
		if (!loadingConnections && !searchingConnections && allowLoadMoreConnections) {
			setOffset(offset => offset + 1);
		}
	}, [loadingConnections, searchingConnections, allowLoadMoreConnections]);

	const sendInvitation = useCallback(
		(personaIds: number[]) => {
			bulkNotifyUsers(personaIds);
			onHide();
		},
		[bulkNotifyUsers, onHide]
	);

	const members: SearchUsersModel[] = useMemo(
		() =>
			connectionList.map(x => ({
				...pick(["firstName", "lastName", "personaId"], x),
				photos: [{ profilePicture: x.profilePhoto }]
			})),
		[connectionList]
	);

	return (
		<InviteMembersModal
			title="Invite to Conversation"
			show={visible}
			onHide={onHide}
			members={members}
			onChangeKeyword={keyword => {
				setKeyword(keyword);
				setOffset(1);
			}}
			loading={loadingConnections || searchingConnections}
			onLoadMore={loadMore}
			onInvite={sendInvitation}
			entityText={"conversation"}
		/>
	);
};

export default InviteParticipantsModal;
