import React, { useCallback, useEffect, useState } from "react";

import { Box, ButtonBase, ClickAwayListener } from "@material-ui/core";

import { useParams } from "react-router-dom";
import { getFileExtension } from "types/getFileExtension";

import {
	ClosePopupButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	SearchTracksWrapper,
	TracksScrollableList
} from "modules/Manage/View/Components/AddTracksToPlaylist/style";
import ConfirmLeavePopup from "shared/Components/ConfirmLeave";
import useConfirmLeavePopup from "shared/Components/ConfirmLeave/hooks/useConfirmLeavePopup";
import { useDebounce, useFiles, useNotification, useSeries, useTrack, useVideo } from "shared/hooks";
import { useS3Uploader } from "shared/services/s3Uploader";
import { Button, Icon, InPlaceModal, Search, Text } from "shared/ui-kit";

import { getValidationMessage } from "utils/getValidationMessage";
import { dataUrlToFile, filterFiles } from "utils/serviceUtils/helpers";

import { ButtonGroupButton, ButtonGroupWrapper } from "./style";
import FilesTab from "./tabs/FilesTab";
import TracksTab from "./tabs/TracksTab";
import VideosTab from "./tabs/VideosTab";

const correctAudioTypes = ["audio/*"];
const correctVideoTypes = ["video/*"];

interface Props {
	onlyAddSelfContent?: boolean;
}

const AddContentToSeries: React.FC<Props> = ({ onlyAddSelfContent }) => {
	const { setTrackFileUrl, setTrackName, setTrackInfoPopup, setTrackDuration, getData: getTrackData } = useTrack();
	const { trackUploadUrl } = getTrackData();
	const { id } = useParams<{ id?: string }>();

	const { showMessage } = useNotification();
	const { uploadFile } = useS3Uploader();

	const { setFileUrl, setFileInfoPopup, setFileName, setFileExtension, getData: getFilesData } = useFiles();
	const { fileUploadUrl } = getFilesData();

	const [loadData, setLoadData] = useState(true);
	const [searchText, setSearchText] = useState("");
	const [activeTab, setActiveTab] = useState<"VIDEOS" | "TRACKS" | "FILES">("VIDEOS");
	const [displayOptions, setDisplayOptions] = useState(false);
	const [loading, setLoading] = useState(false);

	const { setSeriesDetailsDialog, getData: getSeriesData, getCollection } = useSeries();
	const { seriesDetailsDialog } = getSeriesData();

	const { setVideoDuration, setVideoFileUrl, setVideoName, setVideoInfoPopup, getData: getVideoData } = useVideo();
	const { videoUploadUrl } = getVideoData();

	const debouncedSearchTerm = useDebounce(searchText, 300);

	const loadCollection = useCallback(() => {
		if (seriesDetailsDialog?.data?._id) {
			setLoading(true);
			getCollection({ id: seriesDetailsDialog?.data?._id, offset: 1, paginate: true }).finally(() => {
				setLoading(false);
			});
		}
	}, [getCollection, seriesDetailsDialog?.data?._id]);

	useEffect(() => {
		if (!id) loadCollection();
	}, [loadCollection, id]);

	const onClose = () => setSeriesDetailsDialog();

	const handleSwitchTab = (type: "VIDEOS" | "TRACKS" | "FILES") => {
		setActiveTab(type);
		setSearchText("");
	};

	const handleAddToSeries = useCallback(() => {
		if (seriesDetailsDialog?.data) {
			setSeriesDetailsDialog({
				...seriesDetailsDialog,
				addContent: false,
				seriesDetails: seriesDetailsDialog.data._id
			});
		}
	}, [setSeriesDetailsDialog, seriesDetailsDialog]);

	const onUploadFile = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { target } = e;
		const { files } = target;

		if (files?.length) {
			const extObj = getFileExtension(files[0].name);

			if (!extObj) {
				showMessage("We don't accept this file type, please try with a different file.");
				return;
			}

			uploadFile({
				file: files[0],
				communityName: fileUploadUrl,
				checkProgress: true,
				customCallback: data => setFileUrl(data?.publicUrl)
			});

			setFileName(files && files[0].name.slice(0, files[0].name.lastIndexOf(".")));

			setFileInfoPopup({ open: true, model: undefined });

			setFileExtension(extObj.ext);

			setSeriesDetailsDialog({ ...seriesDetailsDialog, addContent: false, seriesDetails: undefined });
		}
	};

	const onUploadVideo = async (e: React.ChangeEvent<HTMLInputElement>) => {
		const { target } = e;
		const { files } = target;

		if (files) {
			const filteredFiles = filterFiles(files, correctVideoTypes);

			const videoFile = !!filteredFiles?.length ? files[0] : null;

			if (videoFile) {
				if (videoFile.size / 1024 / 1024 > 2048) {
					showMessage(
						getValidationMessage({
							name: "File size is too large. Use file that less than 2GB.",
							emoji: "⚠️"
						})
					);
					return;
				}
				const correctFile = typeof videoFile === "string" ? await dataUrlToFile(videoFile, "test") : videoFile;

				const video = document.createElement("video");
				video.preload = "metadata";

				video.onloadedmetadata = function () {
					window.URL.revokeObjectURL(video.src);

					if (video.duration < 1) return;

					setVideoDuration(`${parseInt(`${video.duration}`)}`);
				};

				video.src = URL.createObjectURL(correctFile);

				setVideoFileUrl("");

				uploadFile({
					file: correctFile as File,
					communityName: videoUploadUrl,
					checkProgress: true,
					customCallback: data => setVideoFileUrl(data?.publicUrl)
				});

				setVideoName(files && files[0].name.slice(0, files[0].name.lastIndexOf(".")));

				setVideoInfoPopup({ open: true, model: undefined });

				setSeriesDetailsDialog({ ...seriesDetailsDialog, addContent: false, seriesDetails: undefined });
			}
		}
	};

	const onUploadTrack = async (e: React.ChangeEvent<HTMLInputElement>) => {
		const { target } = e;
		const { files } = target;

		if (files) {
			const filteredFiles = filterFiles(files, correctAudioTypes);

			const trackFile = !!filteredFiles?.length ? files[0] : null;

			if (trackFile) {
				const correctFile = typeof trackFile === "string" ? await dataUrlToFile(trackFile, "test") : trackFile;

				const audio = document.createElement("audio");
				const reader = new FileReader();

				reader.onload = e => {
					audio.src = (e.target?.result as string) || "";
					audio.addEventListener("loadedmetadata", () => {
						const duration = audio.duration;
						setTrackDuration(`${duration}`);
					});
				};

				reader.readAsDataURL(correctFile);

				uploadFile({
					file: correctFile as File,
					communityName: trackUploadUrl,
					checkProgress: true,
					customCallback: data => setTrackFileUrl(data?.publicUrl)
				});

				setTrackName(files && files[0].name.slice(0, files[0].name.lastIndexOf(".")));

				if (seriesDetailsDialog?.data) {
					setTrackInfoPopup({
						open: true,
						model: undefined,
						collection: {
							label: seriesDetailsDialog.data.title,
							value: seriesDetailsDialog.data._id
						}
					});

					setSeriesDetailsDialog({ ...seriesDetailsDialog, addContent: false, seriesDetails: undefined });
				}
			}
		}
	};

	const triggerUpload = (id: "trackFileUrl" | "fileFileUrl" | "videoFileUrl") => {
		const el: any = document.getElementById(id);
		if (el) {
			el.value = null;
			el.click();
		}
	};

	const {
		handleClose,
		handleLeavePageConfirmed,
		closeConfirmPopup,
		getData: getConfirmLeavePopupData
	} = useConfirmLeavePopup({
		onClose,
		open: true
	});

	const { showConfirmPopup } = getConfirmLeavePopupData();

	return (
		<>
			<ConfirmLeavePopup
				handleLeavePage={handleLeavePageConfirmed}
				open={showConfirmPopup}
				onClose={closeConfirmPopup}
				popup
			/>
			<ModalOverlay>
				<InPlaceModal
					open={true}
					onClose={() => handleClose(seriesDetailsDialog && seriesDetailsDialog.shouldUpdate)}
					maxWidth="sm"
					modalHeight={700}
				>
					<ModalHeader>
						<Text variant="h6">Add content to {seriesDetailsDialog ? seriesDetailsDialog.data?.title : "Series"}</Text>
						<ClosePopupButton
							onClick={() => handleClose(seriesDetailsDialog && seriesDetailsDialog.shouldUpdate)}
							size="small"
						>
							<Icon name="close" group="filled" fill="#c5cee0" />
						</ClosePopupButton>
					</ModalHeader>
					<ModalContent>
						<ButtonGroupWrapper>
							<ButtonGroupButton
								id="selectVideos"
								onClick={() => handleSwitchTab("VIDEOS")}
								active={activeTab === "VIDEOS"}
							>
								Videos
							</ButtonGroupButton>
							<ButtonGroupButton
								id="selectTracks"
								onClick={() => handleSwitchTab("TRACKS")}
								active={activeTab === "TRACKS"}
							>
								Tracks
							</ButtonGroupButton>
							<ButtonGroupButton
								id="selectFiles"
								onClick={() => handleSwitchTab("FILES")}
								active={activeTab === "FILES"}
							>
								Files
							</ButtonGroupButton>
						</ButtonGroupWrapper>
						<SearchTracksWrapper whiteBG>
							<Search
								id="searchForContent"
								placeholder="Search"
								onChange={e => {
									setSearchText(e.target.value);
									if (!loadData) {
										setLoadData(true);
									}
								}}
							/>
						</SearchTracksWrapper>
						<TracksScrollableList>
							<Box display={activeTab === "VIDEOS" ? "block" : "none"}>
								<VideosTab active searchTerm={debouncedSearchTerm} showOnlySelfContent={onlyAddSelfContent} />
							</Box>
							<Box display={activeTab === "TRACKS" ? "block" : "none"}>
								<TracksTab active searchTerm={debouncedSearchTerm} showOnlySelfContent={onlyAddSelfContent} />
							</Box>
							<Box display={activeTab === "FILES" ? "block" : "none"}>
								<FilesTab active searchTerm={debouncedSearchTerm} showOnlySelfContent={onlyAddSelfContent} />
							</Box>
						</TracksScrollableList>
					</ModalContent>
					<ModalFooter>
						<ClickAwayListener onClickAway={() => setDisplayOptions(false)}>
							<Box className="with-dropdown">
								{displayOptions && (
									<Box className="dropdown">
										<ButtonBase onClick={() => triggerUpload("trackFileUrl")} id="createTrack">
											Create Track
										</ButtonBase>
										<ButtonBase onClick={() => triggerUpload("videoFileUrl")} id="createVideo">
											Create Video
										</ButtonBase>
										<ButtonBase onClick={() => triggerUpload("fileFileUrl")} id="createDocument">
											Create Document
										</ButtonBase>
									</Box>
								)}
								<Button
									buttonTheme="light"
									palette="light"
									onClick={() => setDisplayOptions(!displayOptions)}
									id="createNewContent"
								>
									Create New Content
								</Button>
							</Box>
						</ClickAwayListener>
						<Button
							disabled={!seriesDetailsDialog?.shouldUpdate || loading}
							onClick={handleAddToSeries}
							id="addToSeries"
						>
							Add to Series
						</Button>
					</ModalFooter>
				</InPlaceModal>
			</ModalOverlay>
			<input
				id="trackFileUrl"
				type="file"
				onChange={onUploadTrack}
				name="trackFileUrl"
				hidden
				accept={correctAudioTypes.join(",")}
			/>
			<input
				id="videoFileUrl"
				type="file"
				onChange={onUploadVideo}
				name="videoFileUrl"
				hidden
				accept={correctVideoTypes.join(",")}
			/>
			<input id="fileFileUrl" type="file" onChange={onUploadFile} name="fileFileUrl" hidden accept="*" />
		</>
	);
};

export default AddContentToSeries;
