export enum PlaceholderImageType {
	WORKSPACE_SEARCH = "workspace_search",
	EVENT_DETAILS = "event_details",
	GROUP_DETAILS = "group_details",
	PROFILE_IMAGE = "profile_image",
	PROFILE_COVER = "profile_cover",
	POST_PREVIEW = "post_preview",
	BOARD_PREVIEW = "board_preview",
	SERIES_PREVIEW = "series_preview",
	FILE_PREVIEW = "file_preview",
	TRACK_PREVIEW = "track_preview",
	VIDEO_PREVIEW = "video_preview",
	REQUEST_MONEY = "request_money",
	ALBUM_PREVIEW = "album_preview",
	FUNDRAISER_DETAILS = "group_details"
}
