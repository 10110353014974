import { useMemo } from "react";

import useHttpActions from "shared/services/api/core/useHttpActions";
import BaseConverter from "shared/services/converters/baseConverter";

import { ProfileType } from "shared/types";

import { API_URLS } from "../constants";
import { CreateCommunityPersonaType, WorkspaceMetaResponseType } from "../types";
import { UserSelfieVerificationModel } from "../types/UserModel";

const usePersonaService = () => {
	const actions = useHttpActions();

	return useMemo(
		() => ({
			updatePersonaAddress: async ({
				city,
				country,
				state,
				userId
			}: {
				userId: string;
				city?: string;
				country?: string;
				state?: string;
			}) => {
				return BaseConverter.convert(
					await actions.put(API_URLS.COMMUNITY.UPDATE_USER_ADDRESS, {
						city,
						country,
						state,
						userId
					})
				);
			},
			updateCommunityPersona: async (payload: Partial<ProfileType>) => {
				delete payload["_id"];
				return BaseConverter.convert(await actions.put(API_URLS.COMMUNITY.UPDATE_WORKSPACE_PERSONA, payload));
			},
			verifySelfie: async (profileImage: string, verificationImage: string) => {
				return BaseConverter.convert<UserSelfieVerificationModel>(
					await actions.post(API_URLS.COMMUNITY.VERIFY_WORKSPACE_PERSONA_SELFIE, {
						profileImage,
						verificationImage
					})
				);
			},
			createCommunityPersona: async (payload: Partial<ProfileType>) => {
				return BaseConverter.convert(
					await actions.post<CreateCommunityPersonaType>(API_URLS.COMMUNITY.CREATE_WORKSPACE_PERSONA, {
						...payload
					})
				);
			},
			createAutomatedConnection: async () => {
				return BaseConverter.convert(
					await actions.put<WorkspaceMetaResponseType>(API_URLS.COMMUNITY.AUTOMATED_CONNECTION, {})
				);
			}
		}),
		[actions]
	);
};

export default usePersonaService;
