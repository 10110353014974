import React, { useEffect, useMemo, useState } from "react";

import { Box } from "@material-ui/core";
import * as flags from "country-flag-icons/string/3x2";
import countriesList from "country-list-js";
import { Controller, useForm } from "react-hook-form";

import { useNotification, usePersona, useUser } from "shared/hooks";
import { ProfileType } from "shared/types";
import { Text } from "shared/ui-kit";
import { validateEmail, validatePhoneNumber, validateURL } from "utils/serviceUtils/validators";

import { MemberDialog, SelectInput, VyooSwitch } from "../../shared";
import LabeledInput from "../../shared/LabeledInput";
import { InputWrapper } from "../../shared/style";
import { EditProfileWrapper } from "../EditProfileDialog/style";

interface Country {
	dial: string;
	flag: string;
}

const ProfileDetailsDialog = () => {
	const {
		control,
		watch,
		formState: { isDirty, errors, isSubmitting },
		handleSubmit,
		setValue
	} = useForm({ mode: "onChange" });

	const { setProfileDetailsDialog, loadSuggestedLocations, getData: getUserData } = useUser();
	const { profileDetailsDialog, suggestedLocations, user } = getUserData();

	const { updatePersona, updatePersonaAddress, getData: getPersonaData } = usePersona();
	const { persona: profile } = getPersonaData();

	const { showMessage } = useNotification();
	const [countries, setCountries] = useState<{
		list: Country[];
		filtered: Country[];
	}>({ list: [], filtered: [] });
	const [hidden, setHidden] = useState({ email: false, number: false });
	const [dataChanged, setDataChanged] = useState(false);

	const [keyword, setKeyword] = useState("");

	useEffect(() => {
		const countryList = Object.keys(countriesList.all).map(c => {
			return { dial: countriesList.all[c].dialing_code, flag: flags[c] };
		});

		if (keyword) {
			setCountries(ctx => ({ ...ctx, filtered: countryList.filter(c => c.dial.startsWith(keyword)) }));
		} else {
			setCountries(ctx => ({ ...ctx, list: countryList }));
		}
	}, [keyword]);

	const onSubmit = async data => {
		if (!profile) return null;

		const newPersona = {
			...profile,
			aboutMe: data.aboutMe || "",
			address: data.address.label || "",
			contactInfo: {
				...profile?.contactInfo,
				email: data.email || "",
				phoneCode: data.phoneCode?.value || "",
				phoneNumber: data.phoneNumber || "",
				address: data.address.label || "",
				emailHidden: hidden.email,
				phoneHidden: hidden.number
			},

			portfolioUrl: data.portfolioUrl || ""
		} as ProfileType;

		await updatePersona(newPersona, true);

		if (data.address) {
			const fullAddress = suggestedLocations.find(c => c.place_id === data.address.value);
			if (!fullAddress || !user?.userId) return;

			const city = fullAddress.name.trim();
			const vicinityArr = fullAddress.vicinity?.split(",");

			const country =
				!!vicinityArr?.length && vicinityArr[vicinityArr.length - 1]
					? vicinityArr[vicinityArr.length - 1].trim()
					: undefined;
			const state =
				!!vicinityArr?.length && vicinityArr[vicinityArr.length - 2]
					? vicinityArr[vicinityArr.length - 2].trim()
					: undefined;

			await updatePersonaAddress({ city, country, state, userId: user.userId });
		}

		showMessage("Successfully updated your profile");
		setProfileDetailsDialog(false);
	};

	const { phoneCode, phoneNumber } = watch(["phoneCode", "phoneNumber"]);

	useEffect(() => {
		setHidden({
			email: profile?.contactInfo?.emailHidden || false,
			number: profile?.contactInfo?.phoneHidden || false
		});
	}, [profile]);

	useEffect(() => {
		const myCountryCode = phoneCode
			? countries.list.find(c => c.dial === (phoneCode.value as string))
			: profile
			? countries.list.find(c => c.dial === profile.contactInfo?.phoneCode)
			: null;

		if (myCountryCode && !phoneCode) {
			setValue("phoneCode", {
				label: myCountryCode.dial,
				value: myCountryCode.dial
			});
		}
	}, [countries.list, phoneCode, profile, setValue]);

	const countryOptions = useMemo(
		() =>
			!!keyword.length
				? countries.filtered.map(suggestion => ({
						value: suggestion.dial,
						label: suggestion.dial,
						flag: suggestion.flag
				  }))
				: countries.list.map(suggestion => ({
						value: suggestion.dial,
						label: suggestion.dial,
						flag: suggestion.flag
				  })),
		[countries.filtered, countries.list, keyword.length]
	);

	if (!profile) return null;

	return (
		<MemberDialog
			title="Edit Profile Details"
			open={profileDetailsDialog}
			onClose={() => setProfileDetailsDialog(false)}
			confirmLeave={isDirty}
			customWidth={512}
			footerPrimary={{
				text: "Save changes",
				disabled: (!isDirty && !dataChanged) || isSubmitting,
				loading: isSubmitting,
				onClick: handleSubmit(onSubmit)
			}}
		>
			<form onSubmit={handleSubmit(onSubmit)}>
				<EditProfileWrapper>
					<Controller
						name="aboutMe"
						control={control}
						defaultValue={profile.aboutMe}
						render={({ onChange, value, ref }) => (
							<InputWrapper>
								<LabeledInput
									name="aboutMe"
									onChange={onChange}
									value={value}
									placeholder=" "
									label="About Me"
									textarea
									inputRef={ref}
								/>
							</InputWrapper>
						)}
					/>
					<Controller
						name="email"
						control={control}
						defaultValue={profile.contactInfo?.email}
						rules={{
							validate: value => {
								if (value && !!value.length && !validateEmail(value)) return "Email is not correct";
								return undefined;
							}
						}}
						render={({ onChange, value, ref }) => (
							<InputWrapper>
								<LabeledInput
									name="email"
									onChange={onChange}
									value={value}
									placeholder=" "
									label="Email"
									error={errors?.email?.message}
									inputRef={ref}
								/>
								<InputWrapper.HideItem>
									<Text>Hide email</Text>
									<VyooSwitch
										checked={hidden.email}
										onChange={(_, checked: boolean) => {
											setHidden(ctx => ({ ...ctx, email: checked }));
											setDataChanged(true);
										}}
									/>
								</InputWrapper.HideItem>
							</InputWrapper>
						)}
					/>
					<Controller
						name="address"
						control={control}
						defaultValue={{
							value: profile.address,
							label: profile.address
						}}
						render={({ onChange, value, ref }) => (
							<InputWrapper>
								<SelectInput
									name="address"
									onChange={onChange}
									value={value && value.label}
									placeholder=" "
									label="City/country"
									loadSuggestions={val => loadSuggestedLocations(val)}
									maxHeight={260}
									error={errors?.address?.message}
									noOptionsHeadline="Type your city/country"
									options={
										suggestedLocations
											? suggestedLocations.map(suggestion => ({
													value: suggestion.place_id,
													label: suggestion.description
											  }))
											: []
									}
									selectInputRef={ref}
								/>
							</InputWrapper>
						)}
					/>
					<Box className="two-inputs-row flex-end">
						<Controller
							name="phoneCode"
							control={control}
							rules={
								!!phoneNumber
									? {
											required: "Country code is required"
									  }
									: undefined
							}
							render={({ onChange, value, ref }) => (
								<InputWrapper mb={1} width="25%">
									<SelectInput
										name="phoneCode"
										onChange={onChange}
										value={value && value.label}
										placeholder=" "
										label="Country code"
										loadSuggestions={val => setKeyword(val)}
										maxHeight={140}
										noOptionsHeadline="Type your country"
										options={countryOptions}
										selectInputRef={ref}
									/>
								</InputWrapper>
							)}
						/>
						<Controller
							name="phoneNumber"
							control={control}
							defaultValue={profile.contactInfo?.phoneNumber}
							rules={{
								minLength: { value: 8, message: "Phone number must be at least 8 numbers" },
								validate: value => {
									if (value && !!value.length && !validatePhoneNumber(value)) return "Phone number is not correct";
									return undefined;
								}
							}}
							render={({ onChange, value, ref }) => (
								<InputWrapper mb={1} width="73%">
									<LabeledInput
										name="phoneNumber"
										error={errors?.phoneCode?.message || errors?.phoneNumber?.message}
										onChange={onChange}
										value={value}
										placeholder=" "
										label=" "
										inputRef={ref}
									/>
								</InputWrapper>
							)}
						/>
					</Box>
					<InputWrapper.HideItem>
						<Text>Hide number</Text>
						<VyooSwitch
							checked={hidden.number}
							onChange={(_, checked: boolean) => {
								setHidden(ctx => ({ ...ctx, number: checked }));
								setDataChanged(true);
							}}
						/>
					</InputWrapper.HideItem>
					<Controller
						name="portfolioUrl"
						control={control}
						defaultValue={profile.portfolioUrl}
						rules={{
							validate: value => {
								if (!!value.length && !validateURL(value)) return "Incorrect URL";
								return undefined;
							}
						}}
						render={({ onChange, value, ref }) => (
							<InputWrapper>
								<LabeledInput
									name="portfolioUrl"
									onChange={onChange}
									value={value}
									placeholder=" "
									label="Website / portfolio URL"
									error={errors?.portfolioUrl?.message}
									inputRef={ref}
								/>
							</InputWrapper>
						)}
					/>
				</EditProfileWrapper>
			</form>
		</MemberDialog>
	);
};

export default ProfileDetailsDialog;
