import React, { FC, useCallback, useEffect, useMemo, useState } from "react";

import { ButtonBase, useMediaQuery } from "@material-ui/core";

import YouTube from "react-youtube";

import { routes } from "apps/RegularUser/constants";
import { ReactComponent as ShareIcon } from "assets/icons/fundraiser-share.svg";
import { ReactComponent as IconMail } from "assets/icons/icon-mail-filled.svg";
import { ReactComponent as ReportIcon } from "assets/icons/liveconvo/report.svg";
import { ReactComponent as PlayIcon } from "assets/icons/play-circle-icon.svg";

import { ActionButton, ActionItemWrapper, StyledWrapper } from "modules/MemberHome/View/shared/style";
import { Members, MenuDots, PlaceholderImage, SkeletonBase } from "shared/Components";
import { getVideoId } from "shared/Components/Youtube/AddYoutube";
import { useCommunity, useFundraisers, useReportContent, useUser } from "shared/hooks";
import { PlaceholderImageType, ProfilePhotoSizes, ReportContentType } from "shared/types";
import { FundraiserStatusEnum } from "shared/types/FundraiserModel";
import { Icon } from "shared/ui-kit";
import * as appTheme from "theme/default";
import { getFileFromVideoUrl } from "utils/getFileFromVideoUrl";
import { getVideoImage } from "utils/getVideoImage";
import { getResizedImage } from "utils/serviceUtils/cdnImages";
import { validateYoutube } from "utils/serviceUtils/validators";

const FundraiserHeader: FC<{
	id: string;
	setShareModal: (url: string) => void;
	contactId: string | null;
}> = ({ id, contactId, setShareModal }) => {
	const [coverImg, setCoverImg] = useState<string>("");

	const { getData: getCommunityData } = useCommunity();
	const { workspace } = getCommunityData();

	const {
		setInviteDialog,
		setMyContactsDialog,
		setFullScreenVideoDialog,
		setDonationDialog,
		getData: getFundraiserData
	} = useFundraisers();
	const { fundraiser, onBehalfOf } = getFundraiserData();

	const { getActiveProfile, isPrivilegedRole, getData: getUserData } = useUser();
	const { user } = getUserData();
	const { getReportTypeOptions } = useReportContent();

	const activeProfile = useMemo(() => getActiveProfile(user), [getActiveProfile, user]);

	const isMobile = useMediaQuery(appTheme.default.breakpoints.down("sm"));

	const isFundraiserCreatorOrPriviliged = useMemo(
		() => isPrivilegedRole || user?.isInfluencer || fundraiser?.creator[0]?.personaId === user?.activeProfile,
		[user, fundraiser, isPrivilegedRole]
	);
	const isMember = useMemo(
		() => user?.fundraiserParticipantOfIds?.some(f => f._id === fundraiser?._id),
		[user?.fundraiserParticipantOfIds, fundraiser]
	);

	const hasVideoUrl = useMemo(() => !!fundraiser?.videoUrl, [fundraiser?.videoUrl]);
	const isYoutubeUrl = useMemo(
		() => hasVideoUrl && validateYoutube(fundraiser?.videoUrl),
		[hasVideoUrl, fundraiser?.videoUrl]
	);
	const hasThumbnailUrl = useMemo(() => !!fundraiser?.thumbnail?.url, [fundraiser?.thumbnail?.url]);

	const getVideoCover = useCallback(async (url: string) => {
		const file = await getFileFromVideoUrl(url);
		await getVideoImage(file, 2)
			.then(data => URL.createObjectURL(data))
			.then(url => setCoverImg(url))
			.catch(e => console.error({ e }));
	}, []);

	useEffect(() => {
		if (!!fundraiser?.videoUrl && !hasThumbnailUrl && hasVideoUrl && !isYoutubeUrl) getVideoCover(fundraiser?.videoUrl);
	}, [fundraiser?.videoUrl, hasThumbnailUrl, hasVideoUrl, isYoutubeUrl, getVideoCover]);

	const renderPlayIcon = useMemo(
		() => (
			<StyledWrapper.PlayIcon className="absolute z-20 top-0 left-0 w-full h-full flex items-center justify-center">
				<ButtonBase
					onClick={() =>
						setFullScreenVideoDialog({
							open: true,
							url: fundraiser?.videoUrl || ""
						})
					}
					className="p-0 rounded-full"
				>
					<PlayIcon width={80} height={80} />
				</ButtonBase>
			</StyledWrapper.PlayIcon>
		),
		[setFullScreenVideoDialog, fundraiser?.videoUrl]
	);

	const renderThumbnailCover = useMemo(
		() => <img src={fundraiser?.thumbnail?.url || ""} alt={fundraiser?.name} />,
		[fundraiser?.thumbnail?.url, fundraiser?.name]
	);

	const renderYoutubePlayer = useMemo(
		() => (
			<YouTube
				videoId={`${getVideoId(fundraiser?.videoUrl || "")}`}
				opts={{
					width: "100%",
					height: "100%",
					playerVars: {
						autoplay: 0
					}
				}}
			/>
		),
		[fundraiser?.videoUrl]
	);

	const renderCoverImg = useMemo(() => {
		return coverImg ? (
			<img src={coverImg} alt={"cover-image"} />
		) : (
			<SkeletonBase variant="rect" animation="wave" width={"100%"} height={"100%"} />
		);
	}, [coverImg]);

	const renderPlaceholderCover = useMemo(
		() => (
			<PlaceholderImage
				type={PlaceholderImageType.FUNDRAISER_DETAILS}
				width={1170}
				height={480}
				viewBox={"0 0 990 405"}
			/>
		),
		[]
	);

	const renderCover = useMemo(() => {
		if (hasThumbnailUrl) {
			return hasVideoUrl ? (
				/* show play icon on thumbnail */
				<>
					{renderPlayIcon}
					{renderThumbnailCover}
				</>
			) : (
				/* show thumbnail */
				renderThumbnailCover
			);
		} else if (hasVideoUrl) {
			return isYoutubeUrl ? (
				/* show youtube player */
				renderYoutubePlayer
			) : (
				/* show play icon on video img */
				<>
					{renderPlayIcon}
					{renderCoverImg}
				</>
			);
		} else {
			/* show placeholder */
			return renderPlaceholderCover;
		}
	}, [
		hasThumbnailUrl,
		hasVideoUrl,
		renderPlayIcon,
		renderThumbnailCover,
		isYoutubeUrl,
		renderYoutubePlayer,
		renderCoverImg,
		renderPlaceholderCover
	]);

	const onAddContacts = useCallback(
		() =>
			fundraiser &&
			setInviteDialog({
				open: true,
				id,
				name: fundraiser.name,
				onBehalfOf:
					!isFundraiserCreatorOrPriviliged && activeProfile
						? {
								label: activeProfile.firstName,
								value: activeProfile.personaId
						  }
						: undefined
			}),
		[activeProfile, fundraiser, id, isFundraiserCreatorOrPriviliged, setInviteDialog]
	);

	const handleShare = useCallback(() => {
		const url = `${window.location.origin}/fundraiser/${id}?workspace=${workspace!.name}&workspaceId=${workspace!._id}`;

		if (navigator.share && fundraiser?.name) {
			navigator.share({
				title: fundraiser.name,
				url: url
			});
		} else {
			setShareModal(url);
		}
	}, [fundraiser?.name, setShareModal, workspace, id]);

	const optionList = useMemo(
		() => [
			{
				name: "Report",
				icon: <ReportIcon className="w-5 h-5" viewBox="0 -2 22 22" />,
				onClick: () => {},
				submenuOptions: getReportTypeOptions({
					reportType: ReportContentType.GROUP,
					reportContentId: `${fundraiser?._id}`,
					reportContentName: fundraiser?.name
				})
			},
			{
				name: "Share",
				icon: <ShareIcon />,
				onClick: handleShare
			}
		],
		[handleShare, getReportTypeOptions, fundraiser?._id, fundraiser?.name]
	);

	const handleDonateNow = useCallback(() => {
		if (!onBehalfOf) return;
		setDonationDialog({
			open: true,
			behalf: {
				member: {
					name: `${onBehalfOf.persona.firstName} ${onBehalfOf.persona.lastName}`,
					img: onBehalfOf.persona.profilePicture,
					personaId: onBehalfOf.persona.personaId
				},
				group: {
					img: onBehalfOf.group.coverUrl || "",
					name: onBehalfOf.group.name
				}
			}
		});
	}, [onBehalfOf, setDonationDialog]);

	const isDonateDisabled = useMemo(
		() =>
			fundraiser?.status === FundraiserStatusEnum.ENDED.toUpperCase() ||
			fundraiser?.status === FundraiserStatusEnum.PAUSED.toUpperCase() ||
			fundraiser?.status === FundraiserStatusEnum.PENDING.toUpperCase() ||
			fundraiser?.status === FundraiserStatusEnum.ARCHIVED.toUpperCase(),
		[fundraiser?.status]
	);

	const isAddContactsDisabled = useMemo(
		() => fundraiser?.status === FundraiserStatusEnum.ENDED.toUpperCase(),
		[fundraiser?.status]
	);

	const fundraiserActions = useMemo(
		() => (
			<>
				<div className="w-full flex items-center flex-wrap my-2 sm:mr-2 sm:my-0">
					{onBehalfOf && !!contactId && (
						<ActionItemWrapper>
							<ActionButton
								className="w-full"
								palette="primary"
								buttonTheme="main"
								onClick={handleDonateNow}
								disabled={isDonateDisabled}
							>
								Donate
							</ActionButton>
						</ActionItemWrapper>
					)}
					{isFundraiserCreatorOrPriviliged ? (
						<>
							<ActionItemWrapper className="flex-1 flex-shrink-0 sm:flex-initial">
								<ActionButton
									className="w-full"
									palette="basic"
									buttonTheme="light"
									onClick={onAddContacts}
									leftIcon={<IconMail />}
									disabled={isAddContactsDisabled}
								>
									Add Contacts
								</ActionButton>
							</ActionItemWrapper>
							<ActionItemWrapper className="flex-1 flex-shrink-0 sm:flex-initial">
								<ActionButton
									className="w-full"
									palette="basic"
									buttonTheme="light"
									onClick={() => window.open(`${routes.fundraising.fundraisers.getPath()}?edit=${id}`, "_blank")}
									leftIcon={<Icon name="pencil" group="filled" />}
								>
									Edit Details
								</ActionButton>
							</ActionItemWrapper>
						</>
					) : (
						isMember && (
							<>
								<ActionItemWrapper className="flex-1 flex-shrink-0 sm:flex-initial">
									<ActionButton
										className="w-full"
										palette="light"
										onClick={
											activeProfile?.personaId
												? () => setMyContactsDialog({ open: true, personaId: activeProfile.personaId })
												: undefined
										}
									>
										My Contacts
									</ActionButton>
								</ActionItemWrapper>
								<ActionItemWrapper className="flex-1 flex-shrink-0 sm:flex-initial">
									<ActionButton
										className="w-full"
										palette={onBehalfOf && !!contactId ? "light" : "primary"}
										onClick={onAddContacts}
										disabled={isAddContactsDisabled}
									>
										Add Contacts
									</ActionButton>
								</ActionItemWrapper>
							</>
						)
					)}
				</div>
				{!!optionList?.length && (
					<ActionItemWrapper className="absolute top-3 right-3 sm:relative sm:top-0 sm:right-0">
						<MenuDots options={optionList} memberView dontFillSvg removeSideMargin />
					</ActionItemWrapper>
				)}
			</>
		),
		[
			isFundraiserCreatorOrPriviliged,
			onAddContacts,
			isMember,
			activeProfile?.personaId,
			onBehalfOf,
			handleDonateNow,
			optionList,
			setMyContactsDialog,
			id,
			isDonateDisabled,
			isAddContactsDisabled,
			contactId
		]
	);

	const showSponsors = useMemo(
		() =>
			fundraiser && !!fundraiser?.top?.length
				? fundraiser?.top.map(x => (x.image ? getResizedImage(x.image as string, ProfilePhotoSizes.size50x50) : null))
				: [],
		[fundraiser]
	);

	return (
		<StyledWrapper.Header>
			<StyledWrapper.HeaderIconWrapper>{renderCover}</StyledWrapper.HeaderIconWrapper>
			<StyledWrapper.HeaderBody className="relative">
				<StyledWrapper.HeaderInfoRow>
					<StyledWrapper.TypeWrapper>
						<Icon name={"fundraiser"} width={18} heigth={18} group="color" fill="#8f9bb3" />
						<StyledWrapper.TypeText>Fundraiser</StyledWrapper.TypeText>
					</StyledWrapper.TypeWrapper>
					{!isMobile && <StyledWrapper.ActionsWrapper>{fundraiserActions}</StyledWrapper.ActionsWrapper>}
				</StyledWrapper.HeaderInfoRow>
				<StyledWrapper.Title variant="h1" className="mt-2 sm:mt-0">
					{fundraiser?.name}
				</StyledWrapper.Title>
				<StyledWrapper.MembersBlock className="mt-2 sm:mt-3">
					{!!fundraiser!.totalDonations && (
						<Members memberImgs={showSponsors} total={fundraiser!.totalDonations} isFundraiser />
					)}
				</StyledWrapper.MembersBlock>
				{isMobile && <StyledWrapper.ActionsWrapper>{fundraiserActions}</StyledWrapper.ActionsWrapper>}
			</StyledWrapper.HeaderBody>
		</StyledWrapper.Header>
	);
};

export default FundraiserHeader;
