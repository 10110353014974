import React, { useEffect, useMemo, useState } from "react";

import { Avatar, TextField } from "@material-ui/core";

import { Controller, SubmitHandler, useForm } from "react-hook-form";

import { AutoPersonaModel, ForumPersonaModel } from "types";

import { useTeam } from "modules/Team/Data/hooks";
import { useDebounce, useFeature, useNotification } from "shared/hooks";

import {
	AutoConnectMainContainer,
	ButtonContainer,
	ChipsContainer,
	FeatureLabel,
	FormWrapper,
	InfoWrapper,
	InputContainer,
	SaveButton,
	StyleOptionText,
	StyledAutocomplete,
	StyledChip,
	StyledLoader,
	StyledOption,
	StyledOptionAvatar
} from "./style";

import { convertUserToAutomated } from "../../../Data/utils/converter";

type FormValues = {
	automatedConnectionPersonas: AutoPersonaModel[];
};

const defaultValues = {
	automatedConnectionPersonas: []
};

interface AutoConnectProps {
	onUnsavedChanges: (allow: boolean) => void;
}

const AutoConnect: React.FC<AutoConnectProps> = ({ onUnsavedChanges }) => {
	const { updateMeta, getDetails, getData: getFeatureData } = useFeature();
	const { loadingDetails, autoConnectData } = getFeatureData();

	const { getTeamMembers, getData: getTeamData } = useTeam();
	const { users } = getTeamData();

	const { showMessage } = useNotification();

	const [keyword, setKeyword] = useState<string>("");
	const [selectedPersonas, setSelectedPersonas] = useState<AutoPersonaModel[]>([]);

	const debouncedKeyword = useDebounce(keyword, 250);

	const {
		formState: { isSubmitting, isValid, isDirty },
		control,
		setValue,
		handleSubmit,
		reset
	} = useForm<FormValues>({
		mode: "onChange",
		defaultValues
	});

	const disabledAction = useMemo(() => !isValid || !isDirty || isSubmitting, [isValid, isDirty, isSubmitting]);

	useEffect(() => {
		getTeamMembers({
			offset: 1,
			limit: 50,
			type: "team",
			keyword: debouncedKeyword
		});
	}, [getTeamMembers, debouncedKeyword]);

	useEffect(() => {
		if (!!autoConnectData.length) {
			const selectedUsers = convertUserToAutomated(autoConnectData);
			setSelectedPersonas(selectedUsers);
			setValue("automatedConnectionPersonas", selectedUsers, { shouldValidate: true, shouldDirty: false });
		}
	}, [autoConnectData, setValue]);

	useEffect(() => {
		onUnsavedChanges(!disabledAction);
	}, [disabledAction, onUnsavedChanges]);

	const options = useMemo(() => {
		return convertUserToAutomated(users).filter(x => !selectedPersonas.some(p => p.personaId === x.personaId));
	}, [users, selectedPersonas]);

	const handleSelectUser = newValue => {
		const arr = selectedPersonas.concat(newValue[newValue.length - 1]);
		const uniqueValues = Array.from(new Map(arr.map(item => [item["personaId"], item])).values());
		setSelectedPersonas(uniqueValues);
		setValue("automatedConnectionPersonas", uniqueValues, { shouldValidate: true, shouldDirty: true });
	};

	const handleDeleteUser = (option: ForumPersonaModel | AutoPersonaModel) => {
		const filtered = selectedPersonas.filter(x => x.personaId !== option.personaId);
		setSelectedPersonas(filtered);
		setValue("automatedConnectionPersonas", filtered, { shouldValidate: true, shouldDirty: true });
	};

	const onSubmit: SubmitHandler<FormValues> = async data => {
		const { automatedConnectionPersonas } = data;
		const onlyPersonas = automatedConnectionPersonas.map(x => x.personaId);
		const payload = { automatedConnectionPersonaIds: onlyPersonas };
		const response = await updateMeta(payload);
		if (response.success) {
			showMessage("AutoConnect was successfully saved.");
			getDetails();
			reset({
				automatedConnectionPersonas
			});
		}
	};

	return (
		<InfoWrapper>
			<FormWrapper onSubmit={handleSubmit(onSubmit)}>
				<AutoConnectMainContainer>
					<InputContainer>
						<FeatureLabel>Autoconnect to</FeatureLabel>
						<Controller
							name="automatedConnectionPersonas"
							variant="outlined"
							rules={{
								validate: d => !!d.length
							}}
							control={control}
							render={({ value, ...props }) => (
								<StyledAutocomplete
									{...props}
									popupIcon={""}
									filterSelectedOptions
									closeIcon={""}
									renderOption={(option: AutoPersonaModel) => (
										<StyledOption>
											<StyledOptionAvatar alt={option.firstName} src={option.profilePicture} />
											<StyleOptionText>
												{option.firstName} {option.lastName || ""}
											</StyleOptionText>
										</StyledOption>
									)}
									renderTags={() => null}
									options={options}
									multiple
									getOptionLabel={(option: AutoPersonaModel) => `${option.firstName} ${option.lastName || ""}`}
									value={value}
									onChange={(event, newValue) => handleSelectUser(newValue)}
									id="searchAutoconnect"
									renderInput={params => (
										<TextField
											{...params}
											onChange={e => setKeyword(e.target.value)}
											placeholder="Type to search in admins..."
											variant="outlined"
										/>
									)}
								/>
							)}
						/>

						{loadingDetails && <StyledLoader size="2rem" show color="inherit" variant="indeterminate" />}

						{!!selectedPersonas.length && !loadingDetails && (
							<ChipsContainer>
								{selectedPersonas.map((option, index) => (
									<StyledChip
										key={index}
										avatar={<Avatar alt={option.firstName} src={option.profilePicture} />}
										label={`${option.firstName} ${option.lastName}`}
										onDelete={() => handleDeleteUser(option)}
										id={`memberDelete_${index + 1}`}
									/>
								))}
							</ChipsContainer>
						)}
					</InputContainer>
					<ButtonContainer>
						<SaveButton
							disabled={disabledAction}
							removeSideMargin
							size="medium"
							palette="primary"
							buttonTheme="main"
							type="submit"
							id="saveAutoconnect"
						>
							Save
						</SaveButton>
					</ButtonContainer>
				</AutoConnectMainContainer>
			</FormWrapper>
		</InfoWrapper>
	);
};

export default AutoConnect;
