import React, { FC, useCallback, useEffect, useState } from "react";

import { InviteMembersModal } from "shared/Components";
import { useConnection, useEvent, useMembers, useNotification, useUser } from "shared/hooks";
import { MemberStatus, SearchUsersModel, UserStatus } from "shared/types";
import { MemberPhoto } from "shared/types/MembersListModel";
import { formatCount } from "utils/serviceUtils/helpers";

const InviteToEvent: FC<{ id: string; handleClose: () => void }> = ({ id, handleClose }) => {
	const { getMembers, getData: getMembersData } = useMembers();
	const { loadingMembers, users } = getMembersData();
	const { getData: getUserData, isPrivilegedRole } = useUser();
	const { user } = getUserData();
	const { inviteToEvent } = useEvent();

	const { getUsersConnections } = useConnection();

	const { showMessage } = useNotification();

	const [offset, setOffset] = useState(1);
	const [keyword, setKeyword] = useState("");

	const [loadingConnections, setLoadingConnections] = useState(false);
	const [connections, setConnections] = useState<SearchUsersModel[]>([]);

	useEffect(() => {
		if (isPrivilegedRole) {
			getMembers(
				{
					offset,
					limit: 10,
					keyword: keyword || undefined,
					filterBy: [UserStatus.JOINED, UserStatus.ON_BOARDED],
					type: "all"
				},
				false,
				true
			);
		} else {
			if (!isPrivilegedRole) {
				setLoadingConnections(true);

				getUsersConnections({
					offset,
					limit: 10,
					keyword: keyword || undefined
				})
					.then(({ connections }) => {
						const data: SearchUsersModel[] = connections.map(c => ({
							_id: c._id,
							createdAt: new Date().toString(),
							firstName: c.firstName,
							lastName: c.lastName,
							personaId: c.personaId,
							photos: c.photos as MemberPhoto[],
							status: MemberStatus.joined
						}));

						setConnections(data);
					})
					.finally(() => {
						setLoadingConnections(false);
					});
			}
		}
	}, [getMembers, getUsersConnections, isPrivilegedRole, keyword, offset]);

	const loadMore = () => {
		if (!loadingMembers) {
			setOffset(offset => offset + 1);
		}
	};

	const handleInvite = useCallback(
		async (personaIds: number[]) => {
			const list = personaIds;

			await inviteToEvent(id, list);

			handleClose();

			showMessage(`${formatCount(list.length, "user")} ${list.length > 1 ? "have" : "has"} been invited! ✨`);
		},
		[handleClose, id, inviteToEvent, showMessage]
	);

	return (
		<InviteMembersModal
			show
			title="Invite to event"
			onHide={handleClose}
			members={isPrivilegedRole ? users.filter(p => p._id !== user?.personaDocId) : connections}
			onChangeKeyword={keyword => {
				setKeyword(keyword);
				setOffset(1);
			}}
			loading={loadingConnections || loadingMembers}
			onLoadMore={loadMore}
			onInvite={handleInvite}
			entityText={"event"}
		/>
	);
};

export default InviteToEvent;
