export enum ToolbarActions {
	ADD_IMAGE = "image",
	ADD_YOUTUBE = "youtube",
	ADD_TRACK = "select_track",
	SELECT_TRACK = "create_track",
	ADD_GIF = "gif",
	ADD_POLL = "poll",
	SELECT_EVENT = "select_event",
	ADD_EVENT = "add_event",
	SELECT_GROUP = "select_group",
	ADD_GROUP = "create_group",
	ADD_REQUEST_MONEY = "request_money",
	ADD_LINK = "add_link",
	SEND_VIDEO = "send_video",
	ADD_VIDEO = "add_video",
	SELECT_FILE = "select_file",
	ADD_FILE = "add_file"
}
