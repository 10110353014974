import { IStateProps } from "utils/StateOf";

import createStore from "utils/createStore";

export interface Values {
	routes: { [key: string]: any } | null;
	workspace?: string;
}

export const initialState: IStateProps<Values> = {
	routes: null
};

export default createStore<Values>({
	initialState
});
