import React, { FC, useMemo } from "react";

import { Box, Popover } from "@material-ui/core";
import styled from "styled-components";

import { Icon, Text } from "shared/ui-kit";

const PasswordPopover = styled(Popover)`
	.MuiPopover-paper {
		width: 320px;
		height: 200px;
	}
`;

const PasswordWrapper = styled(Box)`
	width: 320px;
	height: 200px;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24);
	border: solid 1px #c5cee0;
	background-color: #fff;
	padding: 16px;
	position: absolute;
	z-index: 10;
	border-radius: 4px;

	${props => props.theme.breakpoints.up("lg")} {
		&:after,
		&:before {
			right: 100%;
			top: 20%;
			border: solid transparent;
			content: "";
			height: 0;
			width: 0;
			position: absolute;
			pointer-events: none;
		}

		&:after {
			border-color: rgba(136, 183, 213, 0);
			border-right-color: white;
			border-width: 12px;
			margin-top: -12px;
		}
		&:before {
			border-color: rgba(194, 225, 245, 0);
			border-right-color: #c5cee0;
			border-width: 14px;
			margin-top: -14px;
		}
	}
`;

PasswordWrapper.Block = styled(Box)`
	display: flex;
	align-items: center;
	margin-top: 12px;
	svg {
		width: 16px;
		height: 16px;
		margin-right: 8px;
	}
`;

const PasswordInstructions: FC<{
	rect?: {
		top: number;
		left: number;
		height: number;
		width: number;
	};
	password: string;
	anchorEl?;
	setAnchorEl?;
}> = ({ password, anchorEl, setAnchorEl }) => {
	const isCorrectLength = useMemo(() => !!password && password.length > 7 && password.length < 21, [password]);
	const includesNumber = useMemo(() => !!password && password.match(/\d/), [password]);
	const hasLowercaseLetter = useMemo(() => !!password && password.match(/[a-z]/), [password]);
	const hasUppercaseLetter = useMemo(() => !!password && password.match(/[A-Z]/), [password]);

	if (!anchorEl) return null;
	return (
		<PasswordPopover
			open={Boolean(anchorEl)}
			anchorEl={anchorEl}
			onClose={() => {
				setAnchorEl(null);
			}}
			anchorOrigin={{
				vertical: "top",
				horizontal: "right"
			}}
			disableAutoFocus={true}
			disableEnforceFocus={true}
		>
			<PasswordWrapper>
				<Text variant="subtitle1">Password must:</Text>
				<PasswordWrapper.Block>
					<Icon name="check-circle" group="filled" fill={isCorrectLength ? "#23b34a" : "#c5cee0"} />
					<Text variant="body2">Be 8-20 characters in length</Text>
				</PasswordWrapper.Block>
				<PasswordWrapper.Block>
					<Icon name="check-circle" group="filled" fill={hasUppercaseLetter ? "#23b34a" : "#c5cee0"} />
					<Text variant="body2">Have at least one capital letter (A-Z)</Text>
				</PasswordWrapper.Block>
				<PasswordWrapper.Block>
					<Icon name="check-circle" group="filled" fill={hasLowercaseLetter ? "#23b34a" : "#c5cee0"} />
					<Text variant="body2">Have at least one lower case letter (a-z)</Text>
				</PasswordWrapper.Block>
				<PasswordWrapper.Block>
					<Icon name="check-circle" group="filled" fill={includesNumber ? "#23b34a" : "#c5cee0"} />
					<Text variant="body2">Have at least one number (0-9)</Text>
				</PasswordWrapper.Block>
			</PasswordWrapper>
		</PasswordPopover>
	);
};

export default PasswordInstructions;
