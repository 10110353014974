import React from "react";

import { Box, BoxProps, CircularProgress } from "@material-ui/core";

import styled from "styled-components";

let primaryColor;
const LoaderBox = styled(props => (
	<Box {...props}>
		<CircularProgress color="primary" />
	</Box>
))`
	width: 10%;
	min-height: 150px;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: auto;

	.MuiCircularProgress-colorPrimary {
		color: ${primaryColor};
	}
`;

const Loader: React.FC<BoxProps & { variant?: string }> = props => {
	const [customPrimaryColor] = React.useState<string>("#f03aaa");
	primaryColor = customPrimaryColor;
	return <LoaderBox {...props} />;
};
export default Loader;
