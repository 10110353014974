import styled from "styled-components";

import { ReactComponent as EmptyStateWomanIcon } from "assets/icons/emptyState.svg";
import { ReactComponent as ManInLotusIcon } from "assets/icons/manInLotus.svg";
import { Box, Button, Text } from "shared/ui-kit";

export const BoxItemWrapper = styled(Box)`
	:not(:last-child) {
		margin-bottom: 24px;
	}

	&.no-margin {
		margin-bottom: 0;
	}
`;

export const ListWrapper = styled(Box)`
	padding: 12px 0;

	.slick-list {
		padding-bottom: 10px;
	}
`;

export const ItemWrapper = styled(Box)`
	width: 208px;
	min-width: 208px;

	margin: 0 8px;

	&.inline {
		display: inline-flex;
		padding-bottom: 16px;
	}
`;

export const EmptyBox = styled(Box)`
	width: 100%;
	min-height: 64px;

	border-radius: 12px;

	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08), inset 0 0 0 0.5px #c5cee0;

	background-color: #fff;

	display: flex;
	flex-direction: column;
	justify-content: center;

	margin-top: 16px;
	padding: 22px 24px;
`;

export const MessageText = styled(Text)`
	font-size: 15px;
	font-weight: normal;
	line-height: 1.33;
	letter-spacing: normal;
	color: #222b45;
`;

export const NoConnectionsBlock = styled(Box)`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	padding: 38px 0 26px 0;

	&.with-bg {
		width: 100%;
		padding: 24px 16px;
		border-radius: 12px;
		box-shadow: 0 2px 4px 0 rgb(0 0 0 / 8%), inset 0 0 0 0.5px #c5cee0;
		background-color: #fff;
	}
`;

export const ManInLotus = styled(ManInLotusIcon)`
	margin-bottom: 24px;
`;

export const EmptyStateWoman = styled(EmptyStateWomanIcon)`
	margin-bottom: 24px;
`;

export const StyledButton = styled(Button)`
	border-radius: 8px;

	margin-top: 16px;
`;

export const ConnectionBlockWrapper = styled(Box)`
	margin-bottom: 24px;
`;
