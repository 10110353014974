import React from "react";

import styled from "styled-components";

const TextWrapper = styled.div`
	display: block;
	line-height: 0;

	.wrap {
		word-break: break-word;
	}

	.text {
		font-size: 13px;
		font-weight: normal;
		line-height: 1.23;
		color: #222b45;
		display: inline-block;
		white-space: pre-wrap;
	}

	.read-more {
		display: inline-block;
		font-size: 12px;
		font-weight: 500;
		line-height: 1.33;
		color: #1998d5;

		margin: 0;

		white-space: nowrap;

		cursor: pointer;
	}
`;

export type ReadMoreProps = {
	showCharCount?: number;
	text?: string;
	wrapClassName: string;
	wrapTextClassName: string;
};

export default ({
	text = "",
	wrapClassName = "",
	wrapTextClassName = "",
	showCharCount = 150
}: ReadMoreProps): JSX.Element => {
	const [charCount, setCharCount] = React.useState<number | null>(showCharCount);

	const showAll = () => {
		setCharCount(null);
	};

	return (
		<TextWrapper className={wrapClassName ? wrapClassName : ""}>
			<p className={`text ${wrapTextClassName ? wrapTextClassName : ""}`}>
				{text.slice(0, charCount ? charCount : text.length)}
				{charCount && text.length > charCount ? "... " : ""}
				{charCount && text.length > charCount && (
					<span className={"read-more"} onClick={showAll}>
						Read More
					</span>
				)}
			</p>
		</TextWrapper>
	);
};
