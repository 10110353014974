import React, { FC } from "react";

import { Loader, Text } from "shared/ui-kit";

import { LoadingTracksWrapper } from "./style";

const LoadingTracks: FC<{ page: number; series?: boolean }> = ({ page, series }) => {
	const loadingType = series ? "series" : "tracks";
	return (
		<LoadingTracksWrapper>
			<Loader size="16px" show variant="indeterminate" />
			<Text variant="body2">{page === 1 ? `Loading your ${loadingType}` : `Loading more ${loadingType}`}</Text>
		</LoadingTracksWrapper>
	);
};

export default LoadingTracks;
