import { Box, ButtonBase } from "@material-ui/core";
import styled, { css } from "styled-components";

export const ButtonGroupWrapper = styled(Box)`
	padding: 2px;
	border-radius: 4px;
	border: solid 0.5px #c6cfe0;
	background-color: #f7f9fc;
	width: 100%;
	display: grid;
	grid-template-columns: ${({ grid }) => (grid ? `repeat(${grid}, 1fr)` : "repeat(3, 1fr)")};
	grid-gap: 4px;
	margin-bottom: 16px;
`;

export const ButtonGroupButton = styled(ButtonBase)`
	font-size: 13px;
	line-height: 1.23;
	letter-spacing: normal;
	color: #222b45;
	padding: 8px 0;
	${({ active }) =>
		active &&
		css`
			border-radius: 3px;
			border: solid 0.5px rgba(198, 207, 224, 0.4);
			background-color: #fff;
			box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
		`}
`;
