import React, { FC } from "react";

import { Box } from "@material-ui/core";

import { Text } from "shared/ui-kit";

import { Choice, MultichoiceInputWrapper } from "./style";

interface Props {
	label: string;
	onChange;
	value: string;
	error?: string;
	options: {
		icon?: JSX.Element;
		title: string;
		value: string;
	}[];
	primaryColor?: string;
}

const MultichoiceBoxesInput: FC<Props> = ({ label, options, onChange, value, error, primaryColor }) => {
	return (
		<MultichoiceInputWrapper>
			<Text variant="label">{label}</Text>
			<Box className="choices">
				{options.map(option => (
					<Choice
						primaryColor={primaryColor}
						onClick={() => onChange(option.value)}
						key={option.value}
						className={option.value === value && "active"}
						id={option.title}
					>
						{option.icon}
						<Text>{option.title}</Text>
					</Choice>
				))}
			</Box>
			{error && <Box className="input-error">{error}</Box>}
		</MultichoiceInputWrapper>
	);
};

export default MultichoiceBoxesInput;
