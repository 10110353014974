import React, { useEffect, useRef } from "react";

import DateFnsAdapter from "@date-io/date-fns";

import { ButtonBase } from "@material-ui/core";

import { ReactComponent as CertificationIcon } from "assets/icons/icon-certification.svg";
import { ReactComponent as EducationIcon } from "assets/icons/icon-education.svg";
import { ReactComponent as VolunteerIcon } from "assets/icons/icon-volunteer.svg";
import { useCommunity, usePersona, useUser } from "shared/hooks";
import { Icon, Text } from "shared/ui-kit";
import { getDuration } from "utils/getDuration";

import { BackgroundWrapper } from "./style";

import { EducationRow, ExperienceRow, InfoRow, MemberDialog } from "../../shared";

const dateFns = new DateFnsAdapter();

const MyExperienceDialog = () => {
	const {
		setMyExperienceDialog,
		setNewJobDialog,
		setNewVolunteerJobDialog,
		setNewEducationDialog,
		setNewLicenseDialog,
		getData: getUserData
	} = useUser();
	const { myExperienceDialog, experienceDialogLocation } = getUserData();

	const { communityColors } = useCommunity();

	const { getData: getPersonaData } = usePersona();
	const { persona: profile } = getPersonaData();

	const dialogRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (dialogRef.current && experienceDialogLocation) {
			const section = dialogRef.current.querySelector(`#${experienceDialogLocation}`) as HTMLDivElement;
			const dialog = document.querySelector(".MuiDialogContent-root") as HTMLDivElement;

			if (section && dialog) {
				dialog.scrollTop = section.offsetTop;
			}
		}
	}, [experienceDialogLocation]);

	if (!profile) return null;

	return (
		<MemberDialog
			title="Background"
			open={myExperienceDialog}
			onClose={() => setMyExperienceDialog(false)}
			customWidth={512}
			footerPrimary={{ text: "Close", onClick: () => setMyExperienceDialog(false) }}
			modalRef={dialogRef}
		>
			<BackgroundWrapper>
				<BackgroundWrapper.Headline>
					<Text variant="h3">Experience</Text>
					<ButtonBase
						onClick={() => {
							setMyExperienceDialog(false);
							setNewJobDialog({
								open: true
							});
						}}
					>
						<Icon width={28} height={28} name="plus-oval" group="color" fill="#8f9bb3" />
					</ButtonBase>
				</BackgroundWrapper.Headline>
				<BackgroundWrapper.Section>
					{!!profile.jobs?.length ? (
						profile.jobs.map((job, i) => {
							const diff = getDuration(new Date(job.startAt), job.present ? new Date() : new Date(job.endAt || ""));
							const duration = diff ? ` ( ${diff} )` : "";

							return (
								<BackgroundWrapper.ExpRow key={i}>
									<ExperienceRow
										width="90%"
										icon={<Icon name="briefcase" group="filled" fill={communityColors.primary} />}
										title={job.jobTitle.label}
										company={`${job.company.label} • ${dateFns.format(new Date(job.startAt), "LLL Y" as any)} - ${
											job.present
												? `Present ${duration}`
												: `${dateFns.format(new Date(job.endAt || ""), "LLL Y" as any)} ${duration}`
										}`}
										location={job.location}
										regularCompany
									/>
									<ButtonBase
										onClick={() => {
											setMyExperienceDialog(false);
											setNewJobDialog({
												open: true,
												item: job
											});
										}}
									>
										<Icon width={20} height={20} name="pencil" group="filled" fill="#8f9bb3" />
									</ButtonBase>
								</BackgroundWrapper.ExpRow>
							);
						})
					) : (
						<BackgroundWrapper.NoExperienceHeadline>No Job Experience Added Yet</BackgroundWrapper.NoExperienceHeadline>
					)}
				</BackgroundWrapper.Section>
				<BackgroundWrapper.Headline>
					<Text variant="h3">Volunteer experience</Text>
					<ButtonBase
						onClick={() => {
							setMyExperienceDialog(false);
							setNewVolunteerJobDialog({
								open: true
							});
						}}
					>
						<Icon width={28} height={28} name="plus-oval" group="color" fill="#8f9bb3" />
					</ButtonBase>
				</BackgroundWrapper.Headline>
				<BackgroundWrapper.Section>
					{!!profile.volunterExperience?.length ? (
						profile.volunterExperience.map((job, i) => {
							const duration = getDuration(new Date(job.startAt), job.present ? new Date() : new Date(job.endAt));
							return (
								<BackgroundWrapper.ExpRow key={i}>
									<ExperienceRow
										width="90%"
										icon={<VolunteerIcon className="fill-second-path" />}
										title={job.cause.label}
										company={`${job.organization.label} • ${dateFns.format(new Date(job.startAt), "LLL Y" as any)} - ${
											job.present
												? `Present ${duration ? `( ${duration} )` : ""}`
												: `${dateFns.format(new Date(job.endAt), "LLL Y" as any)} ${duration ? `( ${duration} )` : ""}`
										}`}
										location={job.location}
									/>
									<ButtonBase
										onClick={() => {
											setMyExperienceDialog(false);
											setNewVolunteerJobDialog({
												open: true,
												item: job
											});
										}}
									>
										<Icon width={20} height={20} name="pencil" group="filled" fill="#8f9bb3" />
									</ButtonBase>
								</BackgroundWrapper.ExpRow>
							);
						})
					) : (
						<BackgroundWrapper.NoExperienceHeadline>
							No Volunteer Experience Added Yet
						</BackgroundWrapper.NoExperienceHeadline>
					)}
				</BackgroundWrapper.Section>
				<BackgroundWrapper.Headline>
					<Text variant="h3">Education</Text>
					<ButtonBase
						onClick={() => {
							setMyExperienceDialog(false);
							setNewEducationDialog({
								open: true
							});
						}}
					>
						<Icon width={28} height={28} name="plus-oval" group="color" fill="#8f9bb3" />
					</ButtonBase>
				</BackgroundWrapper.Headline>
				<BackgroundWrapper.Section id="Education">
					{!!profile.education?.length ? (
						profile.education.map((edu, i) => {
							const duration = getDuration(new Date(edu.startAt), edu.present ? new Date() : new Date(edu.endAt || ""));
							return (
								<BackgroundWrapper.ExpRow key={i}>
									<EducationRow
										key={i}
										icon={<EducationIcon className="fill-first-path" />}
										degree={edu.degree.label}
										category={edu.specialization.label}
										school={`${edu.school.label} • ${dateFns.format(new Date(edu.startAt), "LLL Y" as any)} - ${
											edu.present
												? `Present ${duration ? `( ${duration} )` : ""}`
												: `${dateFns.format(new Date(edu.endAt || ""), "LLL Y" as any)} ${
														duration ? `( ${duration} )` : ""
												  }`
										}`}
										location={edu.description}
										boxClassName="content"
									/>
									<ButtonBase
										onClick={() => {
											setMyExperienceDialog(false);
											setNewEducationDialog({
												open: true,
												item: edu
											});
										}}
									>
										<Icon width={20} height={20} name="pencil" group="filled" fill="#8f9bb3" />
									</ButtonBase>
								</BackgroundWrapper.ExpRow>
							);
						})
					) : (
						<BackgroundWrapper.NoExperienceHeadline>
							No Education Experience Added Yet
						</BackgroundWrapper.NoExperienceHeadline>
					)}
				</BackgroundWrapper.Section>
				<BackgroundWrapper.Headline>
					<Text variant="h3">License & certifications</Text>
					<ButtonBase
						onClick={() => {
							setMyExperienceDialog(false);
							setNewLicenseDialog({
								open: true
							});
						}}
					>
						<Icon width={28} height={28} name="plus-oval" group="color" fill="#8f9bb3" />
					</ButtonBase>
				</BackgroundWrapper.Headline>
				<BackgroundWrapper.Section id="License">
					{!!profile.licensesAndCertifications?.length ? (
						profile.licensesAndCertifications.map((cer, i) => (
							<BackgroundWrapper.ExpRow key={i}>
								<InfoRow
									fw
									key={i}
									icon={<CertificationIcon className="fill-second-path" />}
									title={cer.license.label}
									subtitle={cer.organization.label}
								/>
								<ButtonBase
									onClick={() => {
										setMyExperienceDialog(false);
										setNewLicenseDialog({
											open: true,
											item: cer
										});
									}}
								>
									<Icon width={20} height={20} name="pencil" group="filled" fill="#8f9bb3" />
								</ButtonBase>
							</BackgroundWrapper.ExpRow>
						))
					) : (
						<BackgroundWrapper.NoExperienceHeadline>No Certifications Added Yet</BackgroundWrapper.NoExperienceHeadline>
					)}
				</BackgroundWrapper.Section>
			</BackgroundWrapper>
		</MemberDialog>
	);
};

export default MyExperienceDialog;
