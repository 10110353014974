import { Box, ButtonBase } from "@material-ui/core";
import styled, { css } from "styled-components";

import { Text } from "shared/ui-kit";

export const Wrapper = styled(Box)`
	padding: ${({ noTitle }) => (noTitle ? "18px" : "12px 18px")};
	border-radius: 8px;
	border: solid 1px #e4e9f2;
`;

Wrapper.Headline = styled(Text)`
	font-size: 15px;
	font-weight: 600;
	color: #222b45;
`;

Wrapper.Options = styled(Box)`
	margin-top: ${({ noMT }) => (noMT ? 0 : "16px")};
`;

Wrapper.Option = styled(ButtonBase)`
	&:nth-child(odd) {
		margin-bottom: 8px;
	}
	&:nth-child(even) {
		margin-top: 8px;
	}
	border-radius: 8px;
	border: solid 1px #e4e9f2;
	background-color: #fff;
	padding: 4px 8px;
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	width: 100%;

	${({ checked }) =>
		checked &&
		css`
			background-color: #f5f5ff;
			border-color: #6173fe;
		`}
`;

Wrapper.Info = styled(Box)`
	margin-left: 4px;
	text-align: left;
	padding: 4px 0 8px;
`;
Wrapper.Label = styled(Box)`
	display: flex;
	align-items: center;
	font-size: 15px;
	font-weight: 600;
	line-height: 1.6;
	svg {
		margin-right: 6px;
		margin-top: 4px;
	}

	${({ checked }) =>
		checked &&
		css`
			color: #6173fe;
			svg {
				fill: #6173fe;
				&.fill-first-path path:first-child {
					fill: #6173fe;
				}
				&.fill-second-path path:nth-child(2) {
					fill: #6173fe;
				}
			}
		`}
`;
Wrapper.Description = styled(Text)`
	font-size: 13px;
	line-height: 1.23;
	color: #aaadb3;
	margin-left: 30px;
	width: 80%;
`;
