import React from "react";

import { useParams } from "react-router";

import { view } from "modules/MemberHome";

import { useMemberRoutes } from "shared/hooks";

const BoardPage = () => {
	const { id } = useParams() as { id: string };
	const { getMemberRoutesData } = useMemberRoutes();
	const { routes } = getMemberRoutesData();

	return <view.Board routes={routes} id={id} />;
};

export default BoardPage;
