import styled from "styled-components";

import { ReactComponent as LockIcon } from "assets/icons/lock.svg";

const LockIconGray = styled(LockIcon)`
	& > g > path {
		fill: #8f9bb3;
	}
`;

export { LockIconGray };
