import React, { useCallback } from "react";

import { Box } from "@material-ui/core";
import axios from "axios";

import { useMembers, useNotification } from "shared/hooks";

import { Divider, StyledQRContainer } from "./style";

import { FooterWrapper, QrCodeContainer, StyledButton } from "../style";

const QRCode = () => {
	const { getData: getMembersData } = useMembers();
	const { selectedQRCode } = getMembersData();

	const { showMessage } = useNotification();

	const handleDownloadQR = useCallback(async url => {
		return await axios({
			url,
			method: "GET",
			responseType: "blob"
		}).then(response => {
			const url = window.URL.createObjectURL(response.data);
			const link = document.createElement("a");
			link.href = url;
			link.setAttribute("download", "qr_code.png");
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		});
	}, []);

	const handleCopyLink = useCallback(
		async (link: string) => {
			showMessage("Link Successfully Copied 📋", 2);
			navigator.clipboard.writeText(link as string);
		},
		[showMessage]
	);

	const selectedQRLink = selectedQRCode as string;

	return (
		<QrCodeContainer>
			<StyledQRContainer>
				<img src={selectedQRCode} alt="QR Code" />
			</StyledQRContainer>
			<Box marginTop="12px" width="100%">
				<Divider />
			</Box>
			<FooterWrapper className="center" paddingTop="12px">
				<StyledButton
					buttonTheme="light"
					type="submit"
					palette="primary"
					size="large"
					onClick={() => handleCopyLink(selectedQRLink)}
				>
					Copy link
				</StyledButton>
				<StyledButton
					buttonTheme="main"
					type="submit"
					palette="primary"
					size="large"
					onClick={() => handleDownloadQR(selectedQRLink)}
				>
					Download
				</StyledButton>
			</FooterWrapper>
		</QrCodeContainer>
	);
};

export default QRCode;
