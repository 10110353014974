import React, { useCallback, useEffect, useMemo, useState } from "react";

import { IconButton, TextField } from "@material-ui/core";
import * as R from "ramda";
import { Controller, useForm } from "react-hook-form";

import { AutoPersonaModel } from "types";

import { useTeam } from "modules/Team/Data/hooks";
import { useDebounce, useFeature, useNotification } from "shared/hooks";
import { Icon } from "shared/ui-kit";

import {
	AutoConnectMainContainer,
	ButtonContainer,
	FeatureLabel,
	FormWrapper,
	InfoWrapper,
	InputContainer,
	MessageLabel,
	MessageOwner,
	SaveButton,
	StyleOptionText,
	StyledAutocomplete,
	StyledOption,
	StyledOptionAvatar
} from "./style";

import { convertUserToAutomated } from "../../../Data/utils/converter";

interface AutoMessageProps {
	onUnsavedChanges: (allow: boolean) => void;
}

const AutoMessage: React.FC<AutoMessageProps> = ({ onUnsavedChanges }) => {
	const { getTeamMembers, getData: getTeamData } = useTeam();
	const { users } = getTeamData();

	const { showMessage } = useNotification();

	const { updateMeta, getData: getFeatureData } = useFeature();
	const { autoMessageData } = getFeatureData();

	const [keyword, setKeyword] = useState<string>("");
	const debouncedKeyword = useDebounce(keyword, 250);

	const {
		formState: { isValid, isDirty, isSubmitting },
		control,
		handleSubmit,
		setValue,
		errors,
		reset
	} = useForm({
		mode: "onChange",
		defaultValues: {
			conversationPersonas: undefined,
			promotionText: ""
		}
	});

	const disabledAction = useMemo(() => !isValid || !isDirty || isSubmitting, [isValid, isDirty, isSubmitting]);

	const options = useMemo(() => {
		const userList = convertUserToAutomated(users);
		if (autoMessageData.conversationPersonas.length) {
			const selectedUser = convertUserToAutomated(autoMessageData.conversationPersonas)[0];
			if (!userList.some(x => x.personaId === selectedUser.personaId)) {
				userList.unshift(selectedUser);
			}
		}
		return userList;
	}, [users, autoMessageData]);

	useEffect(() => {
		onUnsavedChanges(!disabledAction);
	}, [disabledAction, onUnsavedChanges]);

	useEffect(() => {
		getTeamMembers({
			offset: 1,
			limit: 50,
			type: "team",
			keyword: debouncedKeyword
		});
	}, [getTeamMembers, debouncedKeyword]);

	useEffect(() => {
		if (autoMessageData.conversationPersonas.length) {
			setValue("conversationPersonas", convertUserToAutomated(autoMessageData.conversationPersonas)[0], {
				shouldValidate: true,
				shouldDirty: false
			});
		}
		if (autoMessageData?.promotionText) {
			setValue("promotionText", autoMessageData.promotionText, { shouldValidate: true, shouldDirty: false });
		}
	}, [autoMessageData, setValue]);

	const onSubmit = useCallback(
		async data => {
			const { promotionText, conversationPersonas } = data;
			const payload = { conversationPersonaIds: [conversationPersonas.personaId], promotionText };
			const response = await updateMeta(payload);
			if (response.success) {
				showMessage("AutoMessage was successfully saved.");
				reset(data);
			}
		},
		[showMessage, updateMeta, reset]
	);

	return (
		<InfoWrapper>
			<FormWrapper onSubmit={handleSubmit(onSubmit)}>
				<AutoConnectMainContainer>
					<InputContainer>
						<FeatureLabel>Message from</FeatureLabel>

						<Controller
							name="conversationPersonas"
							variant="outlined"
							rules={{
								required: "Message sender is required.",
								validate: d => {
									return !R.isEmpty(d);
								}
							}}
							control={control}
							render={({ onChange, value }) => (
								<StyledAutocomplete
									id="searchMember"
									popupIcon={""}
									filterSelectedOptions
									renderOption={(option: AutoPersonaModel) => {
										return (
											<StyledOption id={`member${option.id}`}>
												<StyledOptionAvatar alt={option.firstName} src={option.profilePicture} />
												<StyleOptionText>{`${option.firstName} ${option.lastName || ""}`}</StyleOptionText>
											</StyledOption>
										);
									}}
									options={options}
									value={value}
									getOptionLabel={(option: AutoPersonaModel) => `${option.firstName} ${option.lastName || ""}`}
									clearOnBlur
									onChange={(event, newValue) => {
										onChange(newValue);
									}}
									inputValue={keyword}
									onInputChange={(event, newInputValue) => {
										setKeyword(newInputValue);
										onChange(undefined);
									}}
									renderInput={params => {
										return value ? (
											<MessageOwner>
												<StyledOption>
													<StyledOptionAvatar alt={value.firstName} src={value.profilePicture} />
													<TextField
														{...params}
														inputProps={{
															...params.inputProps,
															value: `${value.firstName} ${value.lastName || ""}`
														}}
														className={"fake-input"}
													/>
												</StyledOption>
												<IconButton
													aria-label="delete"
													onClick={() => {
														onChange(undefined);
														setKeyword("");
													}}
												>
													<Icon fill="#8f9bb3" group="filled" name="close-circle" />
												</IconButton>
											</MessageOwner>
										) : (
											<TextField
												{...params}
												required
												error={!!errors?.conversationPersonas?.message}
												helperText={errors?.conversationPersonas?.message}
												placeholder="Type to search..."
												variant="outlined"
												id={`member${params.index}`}
											/>
										);
									}}
								/>
							)}
						/>

						<MessageLabel>Message text</MessageLabel>

						<Controller
							name={"promotionText"}
							control={control}
							rules={{
								required: "Message is required"
							}}
							render={({ onChange, value, ref }) => (
								<TextField
									placeholder="Type the message here…"
									variant="outlined"
									multiline
									rows={6}
									error={!!errors?.promotionText?.message}
									helperText={errors?.promotionText?.message}
									onChange={onChange}
									value={value}
									ref={ref}
									id="messageText"
								/>
							)}
						/>
					</InputContainer>

					<ButtonContainer>
						<SaveButton
							disabled={disabledAction}
							removeSideMargin
							size="medium"
							palette="primary"
							buttonTheme="main"
							type="submit"
							id="saveAutomatedMessage"
						>
							Save
						</SaveButton>
					</ButtonContainer>
				</AutoConnectMainContainer>
			</FormWrapper>
		</InfoWrapper>
	);
};

export default AutoMessage;
