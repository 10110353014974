import React, { FC, useCallback, useEffect, useMemo, useState } from "react";

import { ClickAwayListener } from "@material-ui/core";

import { SkeletonBase } from "shared/Components";
import { Button, Checkbox, Icon } from "shared/ui-kit";

import { Option } from "./style";

import { FilterOptionType, ListOptionType } from ".";

const FilterSelectOption: FC<{
	item: FilterOptionType;
	offsetLeft: number;
	onShowOptions?: (item: string) => void;
}> = ({ item, offsetLeft, onShowOptions }) => {
	const [selectedOptionIndex, setSelectedOptionIndex] = useState<string[]>([]);
	const [showOptions, setShowOptions] = useState(false);
	const [activeValue, setActiveValue] = useState("");

	useEffect(() => {
		const _item = item.listOptions?.find(p => p.default);
		if (_item) {
			setSelectedOptionIndex([_item.label]);
			setActiveValue(_item.label);
		}
	}, [setSelectedOptionIndex, item.listOptions]);

	const handleListOptionChange = useCallback(
		(_item: ListOptionType) => {
			const idx = selectedOptionIndex.findIndex(op => op === _item.value);

			if (!item.multipleChoice) {
				idx > -1 ? setSelectedOptionIndex([]) : setSelectedOptionIndex([_item.value]);
			} else {
				idx > -1
					? setSelectedOptionIndex(opts => opts.filter((_, i) => i !== idx))
					: setSelectedOptionIndex(opts => [...opts, _item.value]);
			}
		},
		[item.multipleChoice, selectedOptionIndex]
	);

	const handleShowOptions = useCallback(() => {
		if (!showOptions) {
			setShowOptions(true);
			item.label && onShowOptions && onShowOptions(item.label);
		}
	}, [item.label, onShowOptions, showOptions]);

	const label = useMemo(
		() =>
			item.multipleChoice && selectedOptionIndex.length > 1
				? `${selectedOptionIndex[0]} (+${selectedOptionIndex.length - 1})`
				: !selectedOptionIndex.length || !item.listOptions
				? item.label
				: selectedOptionIndex[0],
		[item.label, item.listOptions, item.multipleChoice, selectedOptionIndex]
	);

	const onClickAway = useCallback(() => {
		setShowOptions(false);
		setSelectedOptionIndex(activeValue ? [activeValue] : []);
	}, [activeValue]);

	return (
		<Option.ItemWrapper data-option={item.label} className="menuItem" value={item}>
			<Option.Item
				removeSideMargin
				active={!!selectedOptionIndex.length}
				buttonTheme="outline"
				palette="basic"
				onClick={handleShowOptions}
			>
				{label}
			</Option.Item>
			{item.listOptions && showOptions && (
				<ClickAwayListener onClickAway={onClickAway}>
					<Option.ItemOptions left={offsetLeft}>
						<Option.Items>
							{item.loading
								? Array.from(Array(6).keys()).map((_, i) => (
										<Option.OptionRow key={i} skeleton>
											<SkeletonBase width="100%" height="32px" />
										</Option.OptionRow>
								  ))
								: item.listOptions.map(op => {
										const checked = selectedOptionIndex.some(item => item === op.value);
										return (
											<Option.OptionRow
												className={`${!item.multipleChoice && checked ? "checked" : ""}`}
												key={op.value}
												onClick={() => handleListOptionChange(op)}
											>
												{op.label}
												{!item.multipleChoice && checked && (
													<Icon name="close-circle" height={20} width={20} fill="#b0b7bf" />
												)}
												{item.multipleChoice && (
													<Option.CheckboxWrapper>
														<Checkbox controlPrimaryColor="red" checked={checked} />
													</Option.CheckboxWrapper>
												)}
											</Option.OptionRow>
										);
								  })}
						</Option.Items>
						<Option.Footer>
							<Button
								className="clear-btn"
								onClick={() => {
									setSelectedOptionIndex([]);
									setActiveValue("");
									item.onClick && item.onClick();
									setShowOptions(false);
								}}
							>
								Clear
							</Button>
							<Button
								className="save-btn"
								palette="primary"
								onClick={() => {
									const val = (
										Array.isArray(selectedOptionIndex) && selectedOptionIndex.length === 1
											? selectedOptionIndex[0]
											: selectedOptionIndex
									) as string;
									item.onClick && item.onClick(val);
									setShowOptions(false);
									setActiveValue(val);
								}}
							>
								Save
							</Button>
						</Option.Footer>
					</Option.ItemOptions>
				</ClickAwayListener>
			)}
		</Option.ItemWrapper>
	);
};

export default FilterSelectOption;
