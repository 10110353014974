import styled from "styled-components";

import { Box } from "shared/ui-kit";

export const ListWrapper = styled(Box)`
	margin: 32px 0 0 -16px;
	width: calc(100% + 32px);
	padding-bottom: 16px;
	display: flex;
	align-items: flex-start;
	.slick-list {
		padding: 0 16px 8px !important;
	}
	.btn-base {
		border-radius: 12px;
	}
`;

export const ItemWrapper = styled(Box)`
	width: 360px;
	min-width: 360px;
	margin-right: 16px;
`;
