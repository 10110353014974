import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { FileType } from "types/FilesContextValuesType";

import { useFiles, useOnScreen, useSeries, useUser } from "shared/hooks";
import { Text } from "shared/ui-kit";

import LoadingItems from "../LoadingItems";
import FileBlock from "../block/FileBlock";

interface Props {
	searchTerm: string;
	active?: boolean;
	showOnlySelfContent?: boolean;
}

const FilesTab: React.FC<Props> = ({ searchTerm, active, showOnlySelfContent }) => {
	const { getAllFilesCount, getFiles, getData: getFilesData } = useFiles();
	const { files, isLoading, filesTotalCount } = getFilesData();

	const lastFileRef = useRef<HTMLDivElement>(null);
	const [page, setPage] = useState(1);
	const { setSeriesDetailsDialog, getData: getSeriesData } = useSeries();
	const { seriesDetailsDialog } = getSeriesData();

	const { getData: getUserData } = useUser();
	const { user } = getUserData();

	const onScreen = useOnScreen(lastFileRef);

	const hasMoreTracks = useMemo(() => filesTotalCount > files.length, [files.length, filesTotalCount]);

	const handleToggleFile = useCallback(
		(file: FileType, checked: boolean | undefined) => {
			if (seriesDetailsDialog?.data) {
				if (!checked) {
					setSeriesDetailsDialog({
						addContent: true,
						shouldUpdate: true,
						data: {
							...seriesDetailsDialog.data,
							itemIds: [{ _id: file._id, type: "FILE", file, new: true }, ...seriesDetailsDialog.data.itemIds]
						}
					});
				} else {
					setSeriesDetailsDialog({
						addContent: true,
						shouldUpdate: true,
						data: {
							...seriesDetailsDialog.data,
							itemIds: seriesDetailsDialog.data.itemIds.filter(item => item._id !== file._id)
						}
					});
				}
			}
		},
		[seriesDetailsDialog, setSeriesDetailsDialog]
	);

	useEffect(() => {
		if (onScreen && hasMoreTracks) {
			setPage(state => state + 1);
		}
	}, [hasMoreTracks, onScreen]);

	useEffect(() => {
		if (active) {
			const restProps: Record<string, unknown> = {};
			if (showOnlySelfContent && user?.personaDocId) {
				restProps.personaId = user.personaDocId;
			}
			getFiles({ ...restProps, keyword: searchTerm, limit: 10, offset: page, paginate: true });
		}
	}, [active, getFiles, page, searchTerm, user?.personaDocId, showOnlySelfContent]);

	useEffect(() => {
		if (active) {
			getAllFilesCount({ searchTerm });
		}
	}, [active, getAllFilesCount, searchTerm]);

	return (
		<>
			{!files.length && !isLoading && <Text variant="h5">No Results</Text>}
			{isLoading && !files.length ? (
				<LoadingItems />
			) : (
				!!files.length &&
				files.map((file, i) => {
					if (i + 1 === files.length) {
						return (
							<FileBlock
								handleToggleFile={handleToggleFile}
								lastFileRef={lastFileRef}
								file={file}
								key={file._id}
								id={`files${i + 1}`}
							/>
						);
					}
					return <FileBlock handleToggleFile={handleToggleFile} file={file} key={file._id} id={`files${i + 1}`} />;
				})
			)}
		</>
	);
};

export default FilesTab;
