import React, { FC } from "react";

import styled from "styled-components";

import { ModalOverlay } from "modules/Manage/View/Components/AddTracksToPlaylist/style";
import { useSeries } from "shared/hooks";
import { InPlaceModal } from "shared/ui-kit";

const PreviewVideo = styled.video`
	width: 420px;
	height: 420px;
`;

const PreviewVideoComponent: FC<{ url: string }> = ({ url }) => {
	const { setPlayPreview } = useSeries();
	return (
		<ModalOverlay allowOverflow customMaxWidth="420px">
			<InPlaceModal open={true} onClose={() => setPlayPreview()} maxWidth="md" modalHeight={320}>
				<PreviewVideo autoPlay controls>
					<source src={url} />
				</PreviewVideo>
			</InPlaceModal>
		</ModalOverlay>
	);
};

export default PreviewVideoComponent;
