import { useCallback, useMemo } from "react";

import appConfig from "config/appConfig";

import { EnvApplicationNames } from "types";

import { routesGenerator } from "apps/Member/constants";

import { useRoutesStore } from "shared/contexts";

const useMemberRoutes = () => {
	const { setState, ...store } = useRoutesStore();

	const methods = useMemo(
		() => ({
			generateRoutes: (workspace: string) => {
				const isWhiteLabel =
					appConfig.GLOBAL_CONSTANTS.ENV_APPLICATION_NAME === EnvApplicationNames.Akina ||
					appConfig.GLOBAL_CONSTANTS.ENV_APPLICATION_NAME === EnvApplicationNames.Relias ||
					appConfig.GLOBAL_CONSTANTS.ENV_APPLICATION_NAME === EnvApplicationNames.Bitcoin;

				const routes = routesGenerator(workspace, isWhiteLabel);
				setState({ routes, workspace });
			},
			clearRoutes: () => setState({ routes: null, workspace: undefined })
		}),
		[setState]
	);

	const getMemberRoutesData = useCallback(() => {
		return store;
	}, [store]);

	return { ...methods, getMemberRoutesData };
};

export default useMemberRoutes;
