import React from "react";

import { view } from "modules/MemberHome";
import { useMemberRoutes } from "shared/hooks";

const MyProfilePage = () => {
	const { getMemberRoutesData } = useMemberRoutes();
	const { routes } = getMemberRoutesData();

	return <view.MemberProfile connectionsPageUrl={routes?.member.connections.getPath()} />;
};

export default MyProfilePage;
