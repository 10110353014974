import { useCallback, useMemo } from "react";

import { ForumPostModel } from "types";

import { useSmartBlock } from "shared/hooks";
import { useMembersListApiService } from "shared/services";
import { PostFormModel, PostUpdateFormModel, UserStatus } from "shared/types";

import { usePostStore } from "../contexts";

import { usePostApiService } from "../services";

const usePost = () => {
	const store = usePostStore();
	const { setState } = usePostStore();

	const postService = usePostApiService();
	const teamService = useMembersListApiService();
	const { encodeEditorContent, encodeComment } = useSmartBlock();

	const getHashtagsOrMention = (htmlContent: string, isHashtag = true) => {
		const content = new DOMParser().parseFromString(htmlContent, "text/html");
		const blocks = content.querySelectorAll(isHashtag ? "span[data-active-char='#']" : "span[data-active-char='@']");

		const items = Array.from(blocks).reduce((p, c) => {
			const { attributes } = c;
			if (isHashtag) {
				p.push((c as HTMLElement).innerText.toLowerCase().replace(isHashtag ? "#" : "@", ""));
			} else {
				const val = attributes["data-id"].value;
				if (!isNaN(val)) {
					p.push(Number(val));
				}
			}

			return p;
		}, [] as any[]);

		return [...items];
	};

	const methods = useMemo(
		() => ({
			loadPrerequisite: async (): Promise<void> => {
				setState({ loading: true });
				const categories = await postService.getCategories();

				setState({
					categories,
					loading: false
				});
			},
			getPost: async (pid: string) => {
				setState({ loading: true });
				const { basePost } = await postService.getPost(pid);
				setState({
					editablePost: basePost,
					loading: false
				});
			},
			setEditablePostData: (editablePost?: ForumPostModel) => {
				setState({
					editablePost
				});
			},
			createPost: async (data: PostFormModel) => {
				setState({ creatingOrUpdating: true });

				try {
					const posts = await postService.createPost({
						...data,
						content: data.content ? encodeEditorContent(data.content) : data.content,
						teaserContent: data.teaserContent ? encodeEditorContent(data.teaserContent) : data.teaserContent,
						tags: data.content ? getHashtagsOrMention(data.content) : [],
						mentions: data.content ? getHashtagsOrMention(data.content, false) : []
					} as PostFormModel);

					return posts[0].payload.basePost;
				} catch (error) {
					console.log(error);
				} finally {
					setState({ creatingOrUpdating: false });
				}
			},
			updatePost: async (data: PostUpdateFormModel) => {
				setState({ creatingOrUpdating: true });

				const { payload } = await postService.updatePost({
					...data,
					content: data.content ? encodeEditorContent(data.content) : data.content,
					teaserContent: data.teaserContent ? encodeEditorContent(data.teaserContent) : data.teaserContent,
					tags: data.content ? getHashtagsOrMention(data.content) : [],
					mentions: data.content ? getHashtagsOrMention(data.content, false) : []
				} as PostFormModel);

				setState({ creatingOrUpdating: false });

				return payload.basePost;
			},
			setLoading: (loading: boolean) => {
				setState(ctx => ({ ...ctx, loading }));
			},
			findHashtagData: async (title: string) => {
				const { tags } = await postService.findHashtagData(title);
				return tags;
			},
			loadHashtags: async (search: string, limit = 10, offset = 1) => {
				const { tags } = await postService.getHashTags(search, limit, offset);
				return tags;
			},
			saveHashtags: async (hashtags: string[]) => {
				postService.createHashTags(hashtags);
			},
			loadTeamMembers: async (keyword: string | null) => {
				return await teamService.getMembers({
					offset: 1,
					limit: 20,
					type: "members",
					keyword,
					filterBy: [UserStatus.JOINED, UserStatus.ON_BOARDED]
				});
			},
			reactOnPost: async (reaction?: string, postId?: string) => {
				if (postId) {
					await postService.reactOnPost(reaction, postId);
				}
			},
			commentPost: async ({ comment, postId, data }: { comment?: string; postId?: string; data?: PostFormModel }) => {
				if (postId) {
					const content =
						data && comment ? encodeEditorContent(encodeComment(comment)) : comment ? encodeComment(comment) : comment;
					const { payload } = await postService.commentPost({ comment: content, postId, data });
					return payload;
				}
			},
			updateComment: async (comment?: string, postId?: string) => {
				if (postId) {
					const content = comment ? encodeComment(comment) : comment;
					const { payload } = await postService.updateComment(content, postId);
					return payload;
				}
			},
			loadPostComments: async ({
				postId,
				offset = 1,
				limit = 2,
				extraLimit
			}: {
				postId: string;
				offset?: number;
				limit?: number;
				extraLimit?: number;
			}) => {
				const { basePost, posts, nextPosts } = await postService.getPostComments({ postId, offset, limit, extraLimit });
				return { comments: posts, totalCount: basePost.totalReplies, nextComments: nextPosts || [] };
			}
		}),
		[encodeEditorContent, encodeComment, postService, setState, teamService]
	);

	const getData = useCallback(() => {
		return store;
	}, [store]);

	return { ...methods, getData };
};

export default usePost;
