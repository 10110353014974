import { Box } from "@material-ui/core";
import styled from "styled-components";

import { Button } from "shared/ui-kit";

export const AlbumContainer = styled(Box)`
	padding: 24px;
	border-radius: 12px;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
	box-shadow: inset 0 0 0 0.5px #c5cee0;
	background-color: #fff;
	max-width: 960px;
	margin: 0 16px;
	${props => props.theme.breakpoints.up("md")} {
		padding: 40px;
		margin: auto;
	}
`;

export const AlbumHeader = styled(Box)`
	${props => props.theme.breakpoints.up("sm")} {
		display: flex;
	}
`;

AlbumHeader.AlbumCover = styled(Box)`
	display: flex;

	img,
	.skeleton-img {
		height: 120px;
		object-fit: cover;
	}

	.skeleton-img {
		min-width: 120px;
		min-height: 120px;
	}

	.albumCover {
		border-radius: 2px;
		box-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.1);
		margin-right: -1px;
		width: 120px;
	}

	.skeletonAlbumCover {
		min-width: 120px;
		min-height: 120px;

		border-radius: 50%;

		margin-left: -23%;
	}

	${props => props.theme.breakpoints.up("sm")} {
		img,
		.skeleton-img {
			height: 224px;
		}

		.albumCover {
			width: 224px;
		}

		.skeletonAlbumCover {
			min-width: 224px;
			min-height: 224px;
			border-radius: 50%;
			margin-left: -23%;
		}
	}
`;

AlbumHeader.AlbumDetails = styled(Box)`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	margin-top: 20px;
	.mobile-mb {
		margin-bottom: 20px;
	}
	h3 {
		margin: 4px 0 8px;
	}
	.releaseDate {
		color: #8f9bb3;
	}
	${props => props.theme.breakpoints.up("sm")} {
		margin-top: 0;
		margin-left: 40px;
		.mobile-mb {
			margin-bottom: 0;
		}
	}
`;

AlbumHeader.AlbumActions = styled(Box)`
	display: flex;
	align-items: center;

	.MuiSkeleton-root {
		&:not(:last-child) {
			margin-right: 16px;
		}
	}
`;

export const MoreActionsButton = styled(Button)`
	border-radius: 8px;
	padding: 0;
	margin: 0;
	width: 40px;
	height: 40px;
	margin-left: 12px;
	min-width: auto;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 8px;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08), inset 0 0 0 0.5px #c5cee0;
	border: 0;
	margin: 0;
	svg {
		transform: rotate(90deg);
		width: 20px;
		height: 20px;
	}
`;

export const AlbumDescription = styled(Box)`
	margin: 24px 0 36px;
`;

export const AlbumTracks = styled(Box)`
	overflow-x: auto;
`;
