import React, { FC, useMemo } from "react";

import { Box } from "@material-ui/core";

import { ReactComponent as SoundWaveIcon } from "assets/icons/iconSoundWave.svg";
import { TrackRow } from "modules/Manage/View/Components/AddTracksToPlaylist/style";
import { useSeries } from "shared/hooks";
import { TrackMeta, TrackModel } from "shared/types";
import { Checkbox, Text } from "shared/ui-kit";

interface Props {
	track: TrackModel;
	handleToggleTrack: (track: TrackModel, checked: boolean | undefined) => void;
	lastTrackRef?: React.RefObject<HTMLDivElement>;
	id?: string;
}

const TrackBlock: FC<Props> = ({ track, lastTrackRef, handleToggleTrack, id }) => {
	const { getData: getSeriesData } = useSeries();
	const { seriesDetailsDialog } = getSeriesData();
	const { title, meta } = track;

	const artworkUrl = (meta as TrackMeta)?.artwork?.url;

	const checked = useMemo(
		() => seriesDetailsDialog?.data?.itemIds.some(item => item._id === track._id),
		[track._id, seriesDetailsDialog]
	);

	return (
		<TrackRow ref={lastTrackRef} checked={checked} onClick={() => handleToggleTrack(track, checked)} id={id}>
			<Box className="track-info">
				{artworkUrl ? (
					<img src={artworkUrl} alt={track.title} />
				) : (
					<Box className="track-no-img">
						<SoundWaveIcon />
					</Box>
				)}
				<Box className="track-name">
					<Text variant="subtitle1">{title}</Text>
					<Text variant="body2">{meta.artist}</Text>
				</Box>
			</Box>
			<Box className="checkbox">
				<Checkbox checked={checked} />
			</Box>
		</TrackRow>
	);
};

export default TrackBlock;
