import React, { useCallback, useEffect, useState } from "react";

import { STORIES_OPEN_TYPE } from "shared/contexts/StoryContext/StoryContext";
import { useCommunity, usePopulateWithAds, useStory } from "shared/hooks";
import { PersonStoryModel } from "shared/types";

import { AdZone } from "utils/ads/getRandomAd";

import { Item, ListWrapper, Wrapper } from "./style";

import { BoxItem, Stories } from "../index";

const History: React.FC = () => {
	const { communityColors, getData: getCommunityData } = useCommunity();
	const { workspace } = getCommunityData();

	const { getPersonsStories, openStories, getData: getStoryData } = useStory();
	const { personsStoryList, isOpen } = getStoryData();

	const [color, setColor] = useState<string>();

	useEffect(() => {
		getPersonsStories(1, 10);
	}, [getPersonsStories, workspace]);

	useEffect(() => {
		setColor(communityColors.primary);
	}, [communityColors.primary]);

	const getPreview = useCallback((item: PersonStoryModel): string => {
		return item?.photos?.length ? item.photos[0].profilePicture : "";
	}, []);

	const storiesWithAds = usePopulateWithAds({
		list: personsStoryList,
		zone: AdZone.storyZone,
		every: 5,
		skip: 5
	});

	if (!storiesWithAds.length) return null;

	return (
		<>
			<Wrapper>
				<BoxItem>
					<ListWrapper>
						{personsStoryList.map((item, index) => (
							<Item.Wrapper
								key={index}
								onClick={() =>
									openStories({
										isOpen: STORIES_OPEN_TYPE.STORIES,
										storyId: item?.stories?.length ? item.stories[0]._id : undefined
									})
								}
							>
								<Item.IconWrapper
									className={`${item?.liveStory ? "live" : ""} ${item.stories?.some(x => !x.watched) ? "" : "watched"}`}
									color={color}
								>
									<Item.PreviewIcon url={getPreview(item)} />
								</Item.IconWrapper>
								<Item.Title>{item.firstName}</Item.Title>
							</Item.Wrapper>
						))}
					</ListWrapper>
				</BoxItem>
			</Wrapper>
			{isOpen === STORIES_OPEN_TYPE.STORIES && <Stories personsStoryList={storiesWithAds} filteredStories={[]} />}
		</>
	);
};

export default History;
