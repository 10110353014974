import config from "config/appConfig";

import { buildRouteTree } from "utils/routeUtils";

export const routes = buildRouteTree({
	auth: {
		signUp: null,
		signIn: null,
		forgotPassword: null,
		resetPassword: null,
		resetPasswordLink: null,
		createPassword: null,
		verification: null,
		communities: null,
		invitationCode: null,
		brokenLink: null
	}
});

export const VARS = {
	CONTACT_US_EMAIL: `${config.GLOBAL_CONSTANTS.CONTACT_US_EMAIL}`,
	TERMS_AND_CONDITIONS_LINK: `${config.GLOBAL_CONSTANTS.TERMS_AND_CONDITIONS_LINK}`,
	PRIVACY_POLICY_LINK: `${config.GLOBAL_CONSTANTS.PRIVACY_POLICY_LINK}`,
	COOKIE_USE_LINK: `${config.GLOBAL_CONSTANTS.COOKIE_USE_LINK}`
};
