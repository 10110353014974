import React, { FC, Suspense, memo, useCallback, useEffect, useMemo, useState } from "react";

import { Box } from "@material-ui/core";

import { DateTime } from "luxon";
import { useHistory } from "react-router-dom";

import { ReactComponent as CopyIcon } from "assets/icons/copy.svg";
import { ReactComponent as DeleteIcon } from "assets/icons/icon-delete.svg";
import { ReactComponent as LikeIcon } from "assets/icons/icon-heart-empty.svg";
import { ReactComponent as IconMail } from "assets/icons/icon-mail-filled.svg";

import { ReactComponent as ReportIcon } from "assets/icons/liveconvo/report.svg";
import JoinButton, { ScreenTypes } from "modules/LiveConversation/View/Components/JoinButton";
import { eventModalType } from "modules/Manage/View/Containers/ManageEvents";
import { ConfirmActionModal, MenuDots, MenuDotsOption, PlaceholderImage } from "shared/Components";
import {
	useCommunity,
	useEvent,
	useFeature,
	useNotification,
	useReportContent,
	useUser,
	useVolunteers
} from "shared/hooks";
import {
	EventModel,
	MediaModel,
	MembersModel,
	PlaceholderImageType,
	ProfilePhotoSizes,
	ReportContentType
} from "shared/types";
import { EventSectionsEnum } from "shared/types/EventsTypes";
import { Button, Icon, Text } from "shared/ui-kit";

import { getResizedImage } from "utils/serviceUtils/cdnImages";

import {
	EventContainer,
	EventDetails,
	EventHeader,
	EventInfo,
	EventMembers,
	MembersList,
	MoreEvents,
	SkeletonBase
} from "./style";

import { Events } from "../../Components";
import { EventsAbout, EventsCarpool, EventsVolunteers } from "../../Components/EventDetails";
import { Discussions, Files, Series, Tracks, Videos } from "../../Components/GroupDetails/sections";
import { InvitationBox } from "../../Components/GroupDetails/style";
import { Sections } from "../../shared";
import { IconWrapper, StyledWrapper } from "../../shared/style";

const CreateEvent = React.lazy(() => import("shared/Components/Event/CreateEvent"));
const InviteToEvent = React.lazy(() => import("../../Components/InviteToEvent"));
const ShareGroupViaMessageDialog = React.lazy(
	() => import("../../Components/GroupDetails/components/ShareGroupViaMessageDialog")
);

interface EventDetailsPageProps {
	id: string;
	routes: any;
	groupId?: string;
}

const EventDetailsPage: FC<EventDetailsPageProps> = memo(({ id, routes }) => {
	const {
		getEvent,
		getEventAdmins,
		setInviteEventDialog,
		setEventIntent,
		deleteEvent,
		getData: getEventData
	} = useEvent();
	const { eventCount, creating, inviteEventDialog } = getEventData();

	const { getData: getUserData, isPrivilegedRole } = useUser();
	const { user } = getUserData();

	const { showMessage } = useNotification();
	const { isCommunityFeatureEnabled } = useFeature();

	const history = useHistory();

	const { getReportTypeOptions } = useReportContent();

	const [event, setEvent] = useState<EventModel | null>(null);
	const [admins, setAdmins] = useState<MembersModel[]>([]);
	const [loadingButtons, setLoadingButtons] = useState(false);
	const [current, setCurrent] = useState<EventSectionsEnum>(EventSectionsEnum.About);
	const [loading, setLoading] = useState(false);
	const [eventDelete, setEventDelete] = useState(false);
	const [shareEventViaMessageDialog, setShareEventViaMessageDialog] = useState(false);

	const [eventInfoPopup, setEventInfoPopup] = useState<eventModalType>({
		open: false
	});

	const { setVolunteerSlot } = useVolunteers();

	const { communityColors } = useCommunity();

	const isBlocked = useMemo(() => {
		return event?.isBlocked;
	}, [event]);

	useEffect(() => {
		if (isBlocked) {
			history.go(-1);
		}
	}, [isBlocked, history]);

	useEffect(() => {
		setLoading(true);
		getEvent(id)
			.then(data => {
				setEvent(data);
			})
			.finally(() => {
				setLoading(false);
			});
	}, [getEvent, id]);

	useEffect(() => {
		if (id) {
			getEventAdmins(id).then(({ personas }) => {
				if (personas) {
					setAdmins(personas.personas);
				}
			});
		}
	}, [getEventAdmins, id]);

	const zone = useMemo(() => Intl.DateTimeFormat().resolvedOptions().timeZone, []);

	const eventRange = useMemo(() => {
		if (event) {
			const start = DateTime.fromISO(event.startTime ? event.startTime : "", { zone }).toFormat("MMM d, h:mm a");
			const end = DateTime.fromISO(event.endTime ? event.endTime : "", { zone }).toFormat("MMM d, h:mm a");

			return `${start} - ${end}`;
		}
	}, [event, zone]);

	const isEventEnded = useMemo(() => {
		if (event?.endTime) {
			const now = DateTime.fromJSDate(new Date(), { zone }).toMillis();
			const end = DateTime.fromISO(event.endTime ? event.endTime : "", { zone }).toMillis();

			if (now > end) return true;
		}

		return false;
	}, [event?.endTime, zone]);

	const isEventHappening = useMemo(() => {
		if (event?.startTime) {
			const now = DateTime.fromJSDate(new Date(), { zone }).toMillis();
			const start = DateTime.fromISO(event.startTime ? event.startTime : "", {
				zone
			}).toMillis();

			return now >= start;
		}

		return true;
	}, [event?.startTime, zone]);

	const determineStatus = useMemo(() => {
		if (event) {
			const now = DateTime.fromJSDate(new Date(), { zone }).toMillis();
			const start = DateTime.fromISO(event.startTime ? event.startTime : "", {
				zone
			}).toMillis();
			const end = DateTime.fromISO(event.endTime ? event.endTime : "", { zone }).toMillis();

			if (now > end) {
				return <Text variant="h6">Ended</Text>;
			}
			if (now < start) {
				return (
					<Text variant="h6" className="range">
						{eventRange}
					</Text>
				);
			}
			return <Text variant="h6">Happening now</Text>;
		}
	}, [event, eventRange, zone]);

	const isOwner = useMemo(() => event?.personaId === user?.activeProfile, [event, user]);

	const privlegedNotOwner = useMemo(() => isPrivilegedRole && !isOwner, [isOwner, isPrivilegedRole]);

	const isGoing = useMemo(
		() =>
			event?.attendees.some(
				attendee => attendee.name === `${user?.profiles[0].firstName} ${user?.profiles[0].lastName}`
			) || event?.attending,
		[event, user]
	);

	const isInterested = useMemo(
		() => event?.interests.some(int => int === user?.activeProfile) || event?.interested,
		[event, user]
	);

	const sections = useMemo(() => {
		const BASE_SECTIONS = [
			{
				name: EventSectionsEnum.About,
				visible: true
			},
			{
				name: EventSectionsEnum.Discussions,
				visible: true
			},
			{ name: EventSectionsEnum.Volunteers, visible: isCommunityFeatureEnabled("enableVolunteers") },
			{ name: EventSectionsEnum.Carpool, visible: isCommunityFeatureEnabled("enableCarpool") },
			{ name: EventSectionsEnum.Series, visible: isCommunityFeatureEnabled("enableCollections") },
			{ name: EventSectionsEnum.Videos, visible: isCommunityFeatureEnabled("enableVideos") },
			{ name: EventSectionsEnum.Tracks, visible: isCommunityFeatureEnabled("enableAudioTracks") },
			{ name: EventSectionsEnum.Files, visible: isCommunityFeatureEnabled("enableFiles") }
		];

		return BASE_SECTIONS.filter(section => section.visible)
			.map(section => section.name)
			.filter(s => (event?.isOnline ? s !== EventSectionsEnum.Carpool : s));
	}, [isCommunityFeatureEnabled, event?.isOnline]);

	const handleInterested = useCallback(() => {
		setLoadingButtons(true);

		setEventIntent(false, id, !isInterested).then(() => {
			setLoadingButtons(false);
			showMessage("Successfully updated your event intentions");

			if (event && user?.activeProfile) {
				setEvent({
					...event,
					attendees: isGoing
						? event.attendees.filter(atn => atn.name !== `${user.profiles[0].firstName} ${user.profiles[0].lastName}`)
						: event.attendees,
					interests: isInterested
						? event.interests.filter(int => int !== user?.activeProfile)
						: [user.activeProfile, ...event.interests]
				});
			}
		});
	}, [event, id, isGoing, isInterested, setEventIntent, showMessage, user]);

	const handleGoing = useCallback(() => {
		setLoadingButtons(true);

		setEventIntent(!isGoing, id, false).then(() => {
			setLoadingButtons(false);
			showMessage("Successfully updated your event intentions");

			if (event && user) {
				setEvent({
					...event,
					interests: isInterested ? event.interests.filter(int => int !== user?.activeProfile) : event.interests,
					attendees: isGoing
						? event.attendees.filter(atn => atn.name !== `${user.profiles[0].firstName} ${user.profiles[0].lastName}`)
						: [
								{
									name: `${user.profiles[0].firstName} ${user.profiles[0].lastName}`,
									photo: (user.profiles[0].photos[0] as MediaModel).profilePicture || "",
									purpose: "business",
									education: [],
									jobs: []
								},
								...event.attendees
						  ]
				});
			}
		});
	}, [event, id, isGoing, isInterested, setEventIntent, user, showMessage]);

	const optionList = useMemo(() => {
		let opts: MenuDotsOption[] = [
			{
				name: "Share via Vyoo Messages",
				icon: <Icon name="comment" group="linear" fill="#8f9bb3" />,
				onClick: () => setShareEventViaMessageDialog(true)
			},
			{
				name: "Copy Event Link",
				icon: <CopyIcon />,
				onClick: async () => {
					await navigator.clipboard.writeText(window.location.href);
					showMessage("Link copied to your clipboard!");
				}
			}
		];

		if (privlegedNotOwner) {
			opts = [
				...opts,
				{
					name: isGoing ? "Undo Going" : "Going",
					icon: <Icon name="check-circle" fill="#8f9bb3" />,
					onClick: handleGoing
				},
				{
					name: isInterested ? "Undo Interested" : "Interested",
					icon: <LikeIcon />,
					onClick: handleInterested
				}
			];
		}

		if (event?.personaId !== user?.activeProfile && !isPrivilegedRole) {
			opts.push({
				name: "Report",
				icon: <ReportIcon viewBox="0 -2 22 22" />,
				onClick: () => {},
				submenuOptions: getReportTypeOptions({
					reportType: ReportContentType.EVENT,
					reportContentId: `${event?.eventId}`,
					reportContentName: event?.title,
					customCallback: () => {
						history.go(-1);
					}
				})
			});
		} else {
			opts = [
				...opts,
				{
					name: "Delete Event",
					icon: <DeleteIcon fill="#8f9bb3" />,
					onClick: () => setEventDelete(true)
				}
			];
		}

		return opts;
	}, [
		privlegedNotOwner,
		event?.personaId,
		event?.eventId,
		event?.title,
		user?.activeProfile,
		isPrivilegedRole,
		showMessage,
		isGoing,
		handleGoing,
		isInterested,
		handleInterested,
		getReportTypeOptions,
		history
	]);

	const handleSwitchSection = useCallback(
		(section: EventSectionsEnum) => {
			setCurrent(section);
			setVolunteerSlot([]);
		},
		[setCurrent, setVolunteerSlot]
	);

	const eventDeleteHandler = useCallback(async () => {
		if (eventDelete) {
			try {
				await deleteEvent(id);
				setEventDelete(false);
				history.push(routes?.member.events.getPath());
			} catch (error) {
				showMessage("Could not delete event");
			}
		}
	}, [deleteEvent, eventDelete, history, id, routes?.member.events, showMessage]);

	const currentSection = useMemo(() => {
		switch (current) {
			case EventSectionsEnum.About:
				return (
					<EventsAbout
						event={event}
						admins={admins}
						communityColors={communityColors}
						isPrivilegedRole={isPrivilegedRole || false}
						handleSwitchSection={handleSwitchSection}
						isEventEnded={isEventEnded}
					/>
				);
			case EventSectionsEnum.Discussions:
				return <Discussions routes={routes} totalPosts={event?.totalPosts} eventId={event?.postId} />;
			case EventSectionsEnum.Volunteers:
				return (
					<EventsVolunteers
						event={event}
						canCreate={isPrivilegedRole || isOwner}
						eventId={id}
						isEventEnded={isEventEnded}
					/>
				);
			case EventSectionsEnum.Carpool:
				return (
					<EventsCarpool
						event={event}
						canCreate={isPrivilegedRole || isOwner}
						isEventEnded={isEventEnded}
						isEventHappening={isEventHappening}
					/>
				);
			case EventSectionsEnum.Series:
				return <Series id={event?.eventId || ""} canCreate={isPrivilegedRole || isOwner} event />;
			case EventSectionsEnum.Videos:
				return <Videos id={event?.eventId || ""} canCreate={isPrivilegedRole || isOwner} event />;
			case EventSectionsEnum.Tracks:
				return <Tracks id={event?.eventId || ""} canCreate={isPrivilegedRole || isOwner} event />;
			case EventSectionsEnum.Files:
				return <Files id={event?.eventId || ""} canCreate={isPrivilegedRole || isOwner} event />;
			default:
				return null;
		}
	}, [
		current,
		event,
		admins,
		communityColors,
		isPrivilegedRole,
		handleSwitchSection,
		isEventEnded,
		routes,
		isOwner,
		id,
		isEventHappening
	]);

	const intentActions = useMemo(
		() => (
			<>
				<EventHeader.ActionButton
					palette={isInterested ? "primary" : "light"}
					disabled={loadingButtons}
					buttonTheme={isInterested ? "main" : "light"}
					onClick={handleInterested}
					active={isInterested}
					bgColor={communityColors.btn}
				>
					<LikeIcon /> Interested
				</EventHeader.ActionButton>
				<EventHeader.ActionButton
					palette={isGoing ? "primary" : "light"}
					disabled={loadingButtons}
					buttonTheme={isGoing ? "main" : "light"}
					onClick={handleGoing}
				>
					<Icon name="check-circle" group={isGoing ? "filled" : ""} fill={isGoing ? "#ffffff" : communityColors.btn} />
					Going
				</EventHeader.ActionButton>
			</>
		),
		[communityColors.btn, handleGoing, handleInterested, isGoing, isInterested, loadingButtons]
	);

	return loading ? (
		<EventContainer>
			<EventHeader>
				<EventHeader.IconWrapper>
					<SkeletonBase variant="rect" animation="wave" width={"100%"} height={"100%"} />
				</EventHeader.IconWrapper>
				<EventHeader.Info>
					<SkeletonBase variant="text" animation="wave" width={150} height={30} />
					<SkeletonBase variant="text" animation="wave" width={100} height={30} />
					<SkeletonBase variant="text" animation="wave" width={120} height={36} />
					<SkeletonBase variant="text" animation="wave" width={200} height={50} />
					<SkeletonBase variant="text" animation="wave" width={100} height={30} />
					<EventHeader.EventActions active={isInterested}>
						<SkeletonBase variant="rect" animation="wave" width={140} height={40} className={"side-offset"} />
						<SkeletonBase variant="rect" animation="wave" width={140} height={40} className={"side-offset"} />
						<SkeletonBase variant="rect" animation="wave" width={40} height={40} className={"side-offset"} />
					</EventHeader.EventActions>
				</EventHeader.Info>
			</EventHeader>
			<EventInfo>
				<EventDetails>
					<SkeletonBase variant="text" animation="wave" width={140} height={50} />
					<EventDetails.EventInfoRows>
						<EventDetails.EventInfoRow>
							<IconWrapper>
								<SkeletonBase variant="circle" animation="wave" width={40} height={40} />
							</IconWrapper>
							<SkeletonBase variant="text" animation="wave" width={"80%"} height={30} />
						</EventDetails.EventInfoRow>
						<EventDetails.EventInfoRow>
							<IconWrapper>
								<SkeletonBase variant="circle" animation="wave" width={40} height={40} />
							</IconWrapper>
							<SkeletonBase variant="text" animation="wave" width={"80%"} height={30} />
						</EventDetails.EventInfoRow>
						<EventDetails.EventInfoRow>
							<IconWrapper>
								<SkeletonBase variant="circle" animation="wave" width={40} height={40} />
							</IconWrapper>
							<SkeletonBase variant="text" animation="wave" width={"80%"} height={30} />
						</EventDetails.EventInfoRow>
						<EventDetails.EventInfoRow>
							<IconWrapper>
								<SkeletonBase variant="circle" animation="wave" width={40} height={40} />
							</IconWrapper>
							<SkeletonBase variant="text" animation="wave" width={"80%"} height={30} />
						</EventDetails.EventInfoRow>
						<EventDetails.EventInfoRow>
							<IconWrapper>
								<SkeletonBase variant="circle" animation="wave" width={40} height={40} />
							</IconWrapper>
							<SkeletonBase variant="text" animation="wave" width={"80%"} height={30} />
						</EventDetails.EventInfoRow>
						<EventDetails.EventInfoRow>
							<IconWrapper>
								<SkeletonBase variant="circle" animation="wave" width={40} height={40} />
							</IconWrapper>
							<SkeletonBase variant="text" animation="wave" width={"80%"} height={30} />
						</EventDetails.EventInfoRow>
					</EventDetails.EventInfoRows>
					<EventDetails.Description>
						<SkeletonBase variant="text" animation="wave" width={100} height={36} />
						<SkeletonBase variant="text" animation="wave" width={200} height={32} />
					</EventDetails.Description>
					<SkeletonBase variant="rect" animation="wave" width={"100%"} height={300} />
				</EventDetails>
				<EventMembers>
					<MembersList>
						<SkeletonBase variant="text" animation="wave" width={"60%"} height={36} />
						<MembersList.MemberRow>
							<SkeletonBase variant="circle" animation="wave" width={48} height={48} />
							<Box>
								<SkeletonBase variant="text" animation="wave" width={150} height={32} className={"side-offset"} />
								<SkeletonBase variant="text" animation="wave" width={100} height={28} className={"side-offset"} />
							</Box>
						</MembersList.MemberRow>
					</MembersList>
					<MembersList>
						<SkeletonBase variant="text" animation="wave" width={"60%"} height={36} />
						<MembersList.MemberRow>
							<SkeletonBase variant="circle" animation="wave" width={48} height={48} />
							<Box>
								<SkeletonBase variant="text" animation="wave" width={150} height={32} className={"side-offset"} />
								<SkeletonBase variant="text" animation="wave" width={100} height={28} className={"side-offset"} />
							</Box>
						</MembersList.MemberRow>
					</MembersList>
				</EventMembers>
			</EventInfo>
			<MoreEvents>
				<SkeletonBase variant="text" animation="wave" width={200} height={50} />
				<Events routes={routes} noContainer />
			</MoreEvents>
		</EventContainer>
	) : (
		<>
			<EventContainer>
				<EventHeader>
					<EventHeader.IconWrapper>
						{!!event?.eventImages?.length ? (
							<img src={event!.eventImages[0]} alt={event!.title} />
						) : (
							<PlaceholderImage height={480} type={PlaceholderImageType.EVENT_DETAILS} />
						)}
					</EventHeader.IconWrapper>
					<EventHeader.Info>
						<Text>{event?.categories.map(cat => `#${cat} `)}</Text>
						{determineStatus}
						<Text variant="h4">{event?.title}</Text>
						{event?.location?.name && <Text>{event!.location!.name}</Text>}
						{!!event?.liveConversationId && <JoinButton event={event!} screen={ScreenTypes.eventdetail} />}
						<EventHeader.EventActions>
							{isOwner || privlegedNotOwner ? (
								<div className="flex">
									<Button
										buttonTheme="light"
										palette="basic"
										className="flex items-center md:w-36"
										onClick={() => setInviteEventDialog(true)}
									>
										<IconMail className="mr-1" />
										Invite
									</Button>
									<Button
										buttonTheme="light"
										palette="basic"
										className="flex items-center md:w-36"
										onClick={
											event
												? () =>
														setEventInfoPopup({
															open: true,
															model: event
														})
												: undefined
										}
									>
										<Icon name="pencil" group="filled" width={16} height={16} className="mr-1" />
										Edit Event
									</Button>
								</div>
							) : !event?.invitedBy ? (
								intentActions
							) : null}
							<MenuDots options={optionList} memberView />
						</EventHeader.EventActions>
					</EventHeader.Info>
					{event?.invitedBy && (
						<InvitationBox className="m-4 -mt-2">
							<InvitationBox.InfoWrapper>
								{!!event.invitedBy.photos.length && (event.invitedBy.photos[0] as MediaModel)?.profilePicture && (
									<InvitationBox.Avatar
										to={`${routes?.member.profile.getPath()}/${event.invitedBy.personaId}`}
										style={{
											backgroundImage: `url(${getResizedImage(
												(event.invitedBy.photos[0] as MediaModel).profilePicture || "",
												ProfilePhotoSizes.size50x50
											)})`
										}}
									/>
								)}
								<InvitationBox.Text>
									<b>{`${event.invitedBy.firstName} ${event.invitedBy.lastName}`}</b> invited you to this event
								</InvitationBox.Text>
							</InvitationBox.InfoWrapper>
							<div>{intentActions}</div>
						</InvitationBox>
					)}
				</EventHeader>
				<Sections
					sections={sections}
					current={current}
					handleSwitchSection={s => handleSwitchSection(s as EventSectionsEnum)}
				/>
				<StyledWrapper.Content className={current === EventSectionsEnum.Carpool ? "flex-col" : ""}>
					{currentSection}
				</StyledWrapper.Content>
				{eventCount > 0 && (
					<MoreEvents>
						<Text variant="h4">More Events Happening Now</Text>
						<Events routes={routes} noContainer />
					</MoreEvents>
				)}
			</EventContainer>
			{eventInfoPopup.open && (
				<Suspense fallback={null}>
					<CreateEvent
						open
						editableModel={eventInfoPopup.model}
						onClose={e => {
							setEventInfoPopup({ open: false });
							if (e.created) {
								setLoading(true);
								getEvent(id)
									.then(data => {
										setEvent(data);
									})
									.finally(() => {
										setLoading(false);
									});
							}
						}}
					/>
				</Suspense>
			)}
			{eventDelete && (
				<ConfirmActionModal
					open
					onClose={() => setEventDelete(false)}
					onConfirm={eventDeleteHandler}
					title={"Delete Volunteering"}
					bodyText={"Are you sure you want to permanently delete this event?"}
					closeText="Keep"
					confirmText="Delete"
					reverseButtons
					confirmButtonTheme="danger"
					confirming={creating}
				/>
			)}
			{shareEventViaMessageDialog && (
				<Suspense fallback={null}>
					<ShareGroupViaMessageDialog
						open
						setShareViaMessageDialog={setShareEventViaMessageDialog}
						event={event || undefined}
					/>
				</Suspense>
			)}
			{event && inviteEventDialog && (
				<Suspense fallback={null}>
					<InviteToEvent id={event.eventId} handleClose={() => setInviteEventDialog()} />
				</Suspense>
			)}
		</>
	);
});

export default EventDetailsPage;
