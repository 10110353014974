import React from "react";

import config from "config/appConfig";

import { PlaceholderImage } from "shared/Components";

import { PlaceholderImageType } from "shared/types";

import { Banner, PageWrapper } from "./styles";

import { WorkspaceSearch } from "../../Components";

export interface CommunitiesPageProps {
	marketingUrl: string;
}

const CommunitiesPage: React.FC<CommunitiesPageProps> = ({ marketingUrl }) => {
	// const history = useHistory();
	// const { searchCommunities, searching, searchedCommunities } = useCommunity();

	// useEffect(() => {
	// 	searchCommunities({});
	// }, [searchCommunities]);

	return (
		<PageWrapper>
			<Banner>
				<PlaceholderImage wrapClassName={"banner-image"} type={PlaceholderImageType.WORKSPACE_SEARCH} />
				<Banner.Title>Find Your Community On {config.GLOBAL_CONSTANTS.ENV_APPLICATION_NAME}</Banner.Title>
				<Banner.Subtitle>Over 800 communities for your taste</Banner.Subtitle>
				<Banner.InputWrapper>
					<WorkspaceSearch marketingUrl={marketingUrl} />
					{/*{searching ? <Skeleton variant="rect" width="100%" height={164} /> : <WorkspaceSearch />}*/}
				</Banner.InputWrapper>
			</Banner>
			{/*{searching ? (*/}
			{/*	<Content>*/}
			{/*		<Content.HeadRow>*/}
			{/*			<Skeleton variant="rect" width={250} height={50} />*/}
			{/*			<Skeleton variant="rect" width={100} height={50} />*/}
			{/*		</Content.HeadRow>*/}
			{/*		<Content.Body>*/}
			{/*			{[...Array.from(Array(6).keys())].map((i, rowIndex) => (*/}
			{/*				<WorkspaceCardWrapper key={rowIndex}>*/}
			{/*					<WorkspaceCard skeleton />*/}
			{/*				</WorkspaceCardWrapper>*/}
			{/*			))}*/}
			{/*		</Content.Body>*/}
			{/*	</Content>*/}
			{/*) : (*/}
			{/*	<Content>*/}
			{/*		<Content.HeadRow>*/}
			{/*			<Content.Title>Popular Communities</Content.Title>*/}
			{/*			<SeeAllBtn removeSideMargin buttonTheme={"light"} palette={"basic"}>*/}
			{/*				See All*/}
			{/*			</SeeAllBtn>*/}
			{/*		</Content.HeadRow>*/}
			{/*		<Content.Body>*/}
			{/*			{(searchedCommunities || []).map((workspace, i) => (*/}
			{/*				<WorkspaceCardWrapper onClick={() => history.push(`${marketingUrl}/${workspace.name}`)} key={i}>*/}
			{/*					<ButtonBase>*/}
			{/*						<WorkspaceCard item={workspace} />*/}
			{/*					</ButtonBase>*/}
			{/*				</WorkspaceCardWrapper>*/}
			{/*			))}*/}
			{/*		</Content.Body>*/}
			{/*	</Content>*/}
			{/*)}*/}
		</PageWrapper>
	);
};

export default CommunitiesPage;
