import styled from "styled-components";

import { StyledAbout } from "modules/MemberHome/View/shared/style";
import { CircularProgress } from "shared/ui-kit";

export const FundraiserItemsHeader = styled(StyledAbout)`
	width: 100%;
	height: 400px;
	position: relative;

	.main-img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	iframe {
		height: 100%;
	}

	.play-icon {
		height: 100%;
	}

	${[props => props.theme.breakpoints.down("md")]} {
		height: auto;

		.main-img {
			height: 400px;
		}

		iframe {
			height: 400px;
		}

		.play-icon {
			height: 400px;
		}
	}
`;

export const ProgressBar = styled(CircularProgress)`
	.MuiLinearProgress-root {
		border-radius: 4px;
	}
`;

export const YoutubeWrapper = styled.div`
	height: 100%;
	> div {
		height: 100%;
	}
`;
