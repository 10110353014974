import React, { FC } from "react";

import { Box } from "@material-ui/core";

import { useCommunity } from "shared/hooks";
import { Text } from "shared/ui-kit";

import { IconWrapper, InfoRow } from "../style";

interface Props {
	icon: JSX.Element;
	title: string;
	subtitle?: string;
	className?: string;
	fw?: boolean;
	distanceLevel?: 1 | 2;
}

const SharedInfoRow: FC<Props> = ({ icon, title, subtitle, className, fw, distanceLevel = 2 }) => {
	const { communityColors } = useCommunity();
	return (
		<InfoRow className={className} fw={fw}>
			<IconWrapper fillColor={communityColors.primary} distanceLevel={distanceLevel}>
				{icon}
			</IconWrapper>
			<Box className="info" title={title}>
				<Text>{title}</Text>
				{subtitle && <Text className="secondary">{subtitle}</Text>}
			</Box>
		</InfoRow>
	);
};

export default SharedInfoRow;
