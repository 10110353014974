import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import LoadingTracks from "modules/Manage/View/Components/AddTracksToPlaylist/LoadingTracks";
import NoMoreTracks from "modules/Manage/View/Components/AddTracksToPlaylist/NoMoreTracks";
import { useOnScreen, useSeries, useTrack, useUser } from "shared/hooks";

import { TrackModel } from "shared/types";
import { Text } from "shared/ui-kit";

import LoadingItems from "../LoadingItems";
import TrackBlock from "../block/TrackBlock";

interface Props {
	searchTerm: string;
	active?: boolean;
	showOnlySelfContent?: boolean;
}

const TracksTab: React.FC<Props> = ({ searchTerm, active, showOnlySelfContent }) => {
	const { getTracks, getTrackCount, getData: getTrackData } = useTrack();
	const { filteredTracks, loading, trackCount } = getTrackData();
	const { getData: getUserData, getActiveProfile } = useUser();
	const { user } = getUserData();
	const activeProfile = getActiveProfile(user);

	const lastTrackRef = useRef<HTMLDivElement>(null);
	const [page, setPage] = useState(1);

	const onScreen = useOnScreen(lastTrackRef);
	const { setSeriesDetailsDialog, getData: getSeriesData } = useSeries();
	const { seriesDetailsDialog } = getSeriesData();

	const hasMoreTracks = useMemo(
		() => trackCount > (filteredTracks ? filteredTracks.length : 0),
		[filteredTracks, trackCount]
	);

	useEffect(() => {
		if (onScreen && hasMoreTracks) {
			setPage(state => state + 1);
		}
	}, [hasMoreTracks, onScreen]);

	useEffect(() => {
		if (!trackCount && active) {
			getTrackCount({ keyword: searchTerm });
		}
	}, [getTrackCount, searchTerm, trackCount, active]);

	const handleToggleTrack = useCallback(
		(track: TrackModel, checked: boolean | undefined) => {
			if (seriesDetailsDialog?.data) {
				if (!checked) {
					setSeriesDetailsDialog({
						addContent: true,
						shouldUpdate: true,
						data: {
							...seriesDetailsDialog.data,
							itemIds: [{ _id: track._id, type: "TRACK", music: track, new: true }, ...seriesDetailsDialog.data.itemIds]
						}
					});
				} else {
					setSeriesDetailsDialog({
						addContent: true,
						shouldUpdate: true,
						data: {
							...seriesDetailsDialog.data,
							itemIds: seriesDetailsDialog.data.itemIds.filter(item => item._id !== track._id)
						}
					});
				}
			}
		},
		[seriesDetailsDialog, setSeriesDetailsDialog]
	);

	useEffect(() => {
		if (onScreen && hasMoreTracks && !searchTerm?.length) {
			setPage(state => state + 1);
		}
	}, [hasMoreTracks, onScreen, searchTerm?.length]);

	useEffect(() => {
		if (active) {
			const restProps: Record<string, unknown> = {};
			if (showOnlySelfContent && activeProfile?.personaId) {
				restProps.personaId = activeProfile.personaId;
			}
			getTracks({ ...restProps, keyword: searchTerm, limit: 10, offset: page });
		}
	}, [active, activeProfile?.personaId, getTracks, page, searchTerm, showOnlySelfContent]);

	useEffect(() => {
		if (active) {
			getTrackCount({ keyword: searchTerm });
		}
	}, [getTrackCount, searchTerm, trackCount, active]);

	return (
		<>
			{!filteredTracks?.length && !loading && <Text variant="h5">No Results</Text>}
			{loading && !filteredTracks ? (
				<LoadingItems />
			) : (
				filteredTracks &&
				filteredTracks.map((track, i) => {
					if (i + 1 === filteredTracks.length) {
						return (
							<TrackBlock
								handleToggleTrack={handleToggleTrack}
								lastTrackRef={lastTrackRef}
								track={track}
								key={track._id}
								id={`track${i + 1}`}
							/>
						);
					}
					return (
						<TrackBlock handleToggleTrack={handleToggleTrack} track={track} key={track._id} id={`track${i + 1}`} />
					);
				})
			)}
			{loading && <LoadingTracks page={page} />}
			{trackCount === (filteredTracks && filteredTracks.length) && trackCount > 0 && !searchTerm && <NoMoreTracks />}
		</>
	);
};

export default TracksTab;
