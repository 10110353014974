import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { Box, IconButton } from "@material-ui/core";

import InputRange, { Range } from "react-input-range";

import { useHistory, useLocation } from "react-router";

import { ReactComponent as IconRepeat } from "assets/icons/icon-repeat.svg";
import { ReactComponent as IconShuffle } from "assets/icons/icon-shuffle.svg";
import { ReactComponent as IconSoundOn } from "assets/icons/icon-sound.svg";
import { ReactComponent as SoundWaveIcon } from "assets/icons/iconSoundWave.svg";
import { ReactComponent as IconPause } from "assets/icons/pause-track.svg";
import { ReactComponent as IconPrev } from "assets/icons/prev.svg";
import { ReactComponent as IconSoundOff } from "assets/icons/track-sound-off.svg";

import "react-input-range/lib/css/index.css";
import { useCommunity, useMemberRoutes, usePlayer, useSeries, useStream } from "shared/hooks";
import { PodcastMeta, StreamEntityType, TrackMeta } from "shared/types";

import { Icon, Text, Tooltip } from "shared/ui-kit";
import { validateYoutube } from "utils/serviceUtils/validators";

import SoundControl from "./SoundControl";

import { Player, PlayerContainer } from "./style";

import { OpenDocumentButton, PlayTrack } from "../AlbumTrack/style";

const TrackPlayer = () => {
	const {
		setPlay,
		setShowVoiceControl,
		setRepeatTrack,
		setShuffleTracks,
		setPlayTracks,
		setVolume,
		setAlbumPlaying,
		getData: getPlayerData
	} = usePlayer();

	const { getMemberRoutesData } = useMemberRoutes();
	const { routes } = getMemberRoutesData();

	const { communityColors } = useCommunity();

	const { play, showVoiceControl, repeatTrack, shuffleTracks, volume, playTracks } = getPlayerData();

	const { startStream, pauseStream } = useStream();
	const { setSeriesPlayState, getCollection, getData: getSeriesData } = useSeries();
	const { seriesPlayState, isLoading: loadingCollection } = getSeriesData();

	const [totalDuration, setTotalDuration] = useState<string | null>(null);
	const [currentDuration, setCurrentDuration] = useState<string>("0:00");
	const [songProgress, setSongProgress] = useState<number | Range>(0);
	const [fileUrl, setFileUrl] = useState("");
	const [muted, setMuted] = useState(false);

	const audioRef = useRef<any>(null);

	const currentSong = useMemo(
		() => (playTracks.tracks?.items ? playTracks.tracks.items[playTracks.idx] : undefined),
		[playTracks]
	);
	const currentSeries = useMemo(
		() => (playTracks.series?.items ? playTracks.series.items[playTracks.idx] : undefined),
		[playTracks]
	);

	const isPodcast = useMemo(() => currentSong && currentSong.type === "podCast", [currentSong]);

	const entityId = useMemo(
		() => (currentSong ? currentSong._id : currentSeries ? currentSeries._id : ""),
		[currentSeries, currentSong]
	);

	const tracksList = useMemo(
		() => playTracks.tracks?.items || playTracks.series?.items || [],
		[playTracks.series, playTracks.tracks]
	);

	const itemType = useMemo(
		() => (currentSeries?.file ? "FILE" : currentSeries?.video ? "VIDEO" : "MUSIC"),
		[currentSeries]
	);

	const isTrackLengthOne = useMemo(() => playTracks?.tracks?.items.length === 1, [playTracks]);

	const isYT = useMemo(() => validateYoutube(currentSeries?.video?.meta?.video?.url), [currentSeries]);
	const location = useLocation();

	const isLockedItem = useMemo(
		() =>
			currentSong?.locked ||
			currentSeries?.file?.locked ||
			currentSeries?.music?.locked ||
			currentSeries?.video?.locked,
		[currentSeries, currentSong]
	);

	const noPlayer = useMemo(() => itemType === "FILE" || isYT || isLockedItem, [isYT, itemType, isLockedItem]);

	useEffect(() => {
		if (currentSong || currentSeries) {
			if (isPodcast && currentSong) {
				setFileUrl((currentSong.meta as PodcastMeta).enclosure[0].url);
			} else {
				if (currentSong) setFileUrl((currentSong.meta as TrackMeta).track.url);
				if (currentSeries) {
					setFileUrl(
						currentSeries.file
							? currentSeries.file?.file.url
							: currentSeries.music
							? (currentSeries.music?.meta as TrackMeta).track.url
							: currentSeries.video
							? currentSeries.video.meta.video.url
							: ""
					);
				}
			}

			if (!noPlayer) {
				startStream({ entityId, type: itemType === "VIDEO" ? StreamEntityType.VIDEO : StreamEntityType.MUSIC });
			}
		}
	}, [currentSeries, currentSong, entityId, isPodcast, itemType, noPlayer, setPlay, startStream]);

	useEffect(() => {
		if (
			audioRef.current &&
			currentSeries &&
			(currentSeries.music?.stream.current || currentSeries.video?.stream.current)
		) {
			audioRef.current.currentTime = currentSeries.music?.stream.current || currentSeries.video?.stream.current;
		}
	}, [currentSeries]);

	useEffect(() => {
		if (audioRef.current && fileUrl) {
			if (audioRef.current.currentSrc !== fileUrl) {
				setPlay(false);
				audioRef.current.pause();
				audioRef.current.setAttribute("src", fileUrl);
				audioRef.current.load();
			}

			if (play && !loadingCollection) {
				audioRef.current.play();
				setPlay(true);
			}
		}
	}, [fileUrl, loadingCollection, play, setPlay]);

	const isVideoPage = useMemo(
		() => location.pathname.includes(`${routes?.member.video.getPath()}/`),
		[location.pathname, routes?.member.video]
	);

	useEffect(() => {
		if (isVideoPage) setPlay(false);
	}, [setPlay, isVideoPage]);

	useEffect(() => {
		if (!play) {
			audioRef.current?.pause();
			entityId &&
				pauseStream({
					entityId,
					current: audioRef.current?.currentTime || 0,
					type: itemType === "VIDEO" ? StreamEntityType.VIDEO : StreamEntityType.MUSIC
				});
		}
	}, [entityId, itemType, pauseStream, play]);

	useEffect(() => {
		const audio = audioRef?.current;
		return () => {
			entityId &&
				pauseStream({
					entityId,
					current: audio?.currentTime || 0,
					type: itemType === "VIDEO" ? StreamEntityType.VIDEO : StreamEntityType.MUSIC
				});
		};
	}, [entityId, itemType, pauseStream]);

	const randomTrack = useCallback(
		(except: number) => {
			const track = Math.floor(Math.random() * tracksList.length);
			if (track === except) return 0;
			return track;
		},
		[tracksList.length]
	);

	const nextSong = useCallback(() => {
		if (shuffleTracks) {
			return randomTrack(playTracks.idx);
		} else if (playTracks.idx + 1 < tracksList.length) {
			return playTracks.idx + 1;
		} else {
			return 0;
		}
	}, [playTracks.idx, randomTrack, shuffleTracks, tracksList.length]);

	useEffect(() => {
		if (audioRef.current) {
			if (repeatTrack) {
				audioRef.current.loop = true;
			} else {
				audioRef.current.loop = false;
			}
		}
	}, [audioRef, repeatTrack]);

	useEffect(() => {
		const audio = audioRef.current;
		const handleTrackEnded = () => {
			if (audioRef) {
				setPlayTracks({
					...playTracks,
					idx: nextSong()
				});
			}
		};

		if (audio) {
			audio.addEventListener("ended", handleTrackEnded);
		}

		return () => {
			if (audio) {
				audio.removeEventListener("ended", handleTrackEnded);
			}
		};
	}, [audioRef, nextSong, playTracks, playTracks.idx, playTracks.tracks, setPlayTracks]);

	useEffect(() => {
		if (audioRef.current && volume) {
			audioRef.current.volume = volume / 100;
		}
	}, [audioRef, volume]);

	useEffect(() => {
		if (audioRef.current?.duration) {
			const currentDurationInSeconds = currentDuration.split(":");
			const currentSeconds = parseInt(currentDurationInSeconds[0]) * 60 + parseInt(currentDurationInSeconds[1]);
			setSongProgress((100 * currentSeconds) / audioRef.current.duration);
		}
	}, [currentDuration, audioRef, setSongProgress]);

	const seriesPage = useMemo(
		() => `${routes?.member.collection.getPath()}/${seriesPlayState?.sId}/start`,
		[routes?.member.collection, seriesPlayState?.sId]
	);

	useEffect(() => {
		if (!seriesPlayState || seriesPlayState.sId === playTracks.series?.id || seriesPage === location.pathname) return;

		getCollection({
			id: seriesPlayState.sId,
			offset: 1,
			limit: 25
		}).then(series => {
			if (!series) return;
			setPlayTracks({
				idx: seriesPlayState.currentIdx,
				series: {
					id: seriesPlayState.sId,
					items: series.itemIds
				}
			});
			setPlay(false);
		});
	}, [getCollection, seriesPlayState, setPlayTracks, seriesPage, location, setPlay, playTracks]);

	if (audioRef.current && currentDuration !== totalDuration) {
		audioRef.current.addEventListener("timeupdate", (e: any) => {
			const currentDuration = e.target.currentTime;
			const musicDuration = audioRef.current?.duration;

			if (musicDuration) {
				const totalMin = `${Math.floor(musicDuration / 60)}`;
				let totalSec = `${Math.floor(musicDuration % 60)}`;
				if (parseInt(totalSec) < 10) {
					totalSec = `0${totalSec}`;
				}
				const total = `${totalMin}:${totalSec}`;
				if (totalDuration !== total) {
					setTotalDuration(total);
				}
			}

			if (currentDuration) {
				const totalMin = `${Math.floor(currentDuration / 60)}`;
				let totalSec = `${Math.floor(currentDuration % 60)}`;
				if (parseInt(totalSec) < 10) {
					totalSec = `0${totalSec}`;
				}
				setCurrentDuration(`${totalMin}:${totalSec}`);
			}
		});
	}

	// When there is a single track being played in an album,
	// That track will be paused after the track finishes
	useEffect(() => {
		if (isTrackLengthOne && !repeatTrack && totalDuration === currentDuration && currentDuration != "0:00") {
			setPlay(false);
			audioRef.current.pause();
			audioRef.current.setAttribute("src", fileUrl);
			audioRef.current.load();
		}
	}, [totalDuration, currentDuration, isTrackLengthOne, setPlay, repeatTrack, fileUrl]);

	const handleSlide = useCallback(
		e => {
			if (audioRef.current) {
				setSongProgress(parseInt(e));
				const newSecond = (e * audioRef.current.duration) / 100;

				const totalMin = `${Math.floor(newSecond / 60)}`;
				let totalSec = `${Math.floor(newSecond % 60)}`;
				if (parseInt(totalSec) < 10) {
					totalSec = `0${totalSec}`;
				}

				setCurrentDuration(`${totalMin}:${totalSec}`);
				audioRef.current.currentTime = newSecond;
			}
		},
		[audioRef]
	);

	const handlePrevious = useCallback(() => {
		if (audioRef.current && (audioRef.current.currentTime > 3 || playTracks.idx === 0)) {
			audioRef.current.currentTime = 0;
		} else {
			setPlayTracks({
				...playTracks,
				idx: playTracks.idx - 1
			});

			if (currentSeries && seriesPlayState) {
				setSeriesPlayState({
					...seriesPlayState,
					currentIdx: seriesPlayState.currentIdx - 1
				});
			}
		}
	}, [currentSeries, playTracks, seriesPlayState, setPlayTracks, setSeriesPlayState]);

	const handleNextTrack = useCallback(() => {
		setPlayTracks({
			...playTracks,
			idx: nextSong()
		});

		if (currentSeries && seriesPlayState) {
			setSeriesPlayState({
				...seriesPlayState,
				currentIdx: seriesPlayState.currentIdx + 1
			});
		}
	}, [currentSeries, nextSong, playTracks, seriesPlayState, setPlayTracks, setSeriesPlayState]);

	const handleMuteSound = useCallback(() => {
		if (audioRef.current) {
			if (volume && volume > 0) {
				audioRef.current.muted = true;
				setMuted(true);
				setVolume(0);
			} else {
				audioRef.current.muted = false;
				setMuted(false);
				setVolume(50);
			}
		}
	}, [volume, setVolume]);

	const muteSound = useCallback(() => {
		if (audioRef.current) {
			audioRef.current.muted = true;
			setMuted(true);
		}
	}, [setMuted]);

	const playSound = useCallback(() => {
		audioRef.current.muted = false;
		setMuted(false);
	}, [setMuted]);

	const getUrl = () => {
		if (currentSong) {
			if (isPodcast) return (currentSong.meta as PodcastMeta).image;
			return (currentSong.meta as TrackMeta).artwork.url;
		}

		if (currentSeries) {
			return currentSeries.file
				? currentSeries.file?.coverPhoto.url
				: currentSeries.music
				? (currentSeries.music?.meta as TrackMeta).artwork.url
				: currentSeries.video
				? currentSeries.video?.meta?.artwork?.url
				: undefined;
		}
	};

	const url = getUrl();

	const title = useMemo(
		() =>
			currentSong?.title ||
			currentSeries?.file?.title ||
			currentSeries?.music?.title ||
			currentSeries?.video?.title ||
			"",
		[currentSeries, currentSong]
	);

	const summary = useMemo(
		() =>
			currentSong
				? (currentSong.meta as TrackMeta).artist
				: playTracks.series?.items && seriesPlayState
				? `Series content - ${seriesPlayState.currentIdx + 1}/${playTracks.series.items.length}`
				: "",
		[currentSong, playTracks.series, seriesPlayState]
	);

	const history = useHistory();

	const handleClickInfo = useCallback(() => {
		if (currentSeries) history.push(`${routes?.member.collection.getPath()}/${seriesPlayState?.sId}/start`);
	}, [currentSeries, history, routes?.member.collection, seriesPlayState?.sId]);

	const TrackInfo = useMemo(
		() => (
			<Player.TrackInfo onClick={handleClickInfo}>
				{itemType === "VIDEO" && !isYT ? (
					<video muted={muted} key={url} ref={audioRef}>
						<source src={fileUrl} />
					</video>
				) : url ? (
					<img src={url} alt={title} />
				) : (
					<Box className="track-no-img">
						<SoundWaveIcon />
					</Box>
				)}
				<Box>
					<Text variant="subtitle2">{title}</Text>
					<Text variant="caption" className="artistName">
						{summary}
					</Text>
				</Box>
			</Player.TrackInfo>
		),
		[handleClickInfo, itemType, isYT, muted, url, title, summary, fileUrl]
	);

	const TrackControls = useMemo(
		() => (
			<Player.TrackControls bgColor={communityColors.btn}>
				{!noPlayer && (
					<>
						<Box
							position="relative"
							onMouseEnter={() => setShowVoiceControl(true)}
							onMouseLeave={() => setShowVoiceControl(false)}
						>
							{showVoiceControl && <SoundControl muted={muted} muteSound={muteSound} playSound={playSound} />}
							<IconButton className="noMl hideMobile" size="small" onClick={handleMuteSound}>
								{muted ? <IconSoundOff /> : <IconSoundOn />}
							</IconButton>
						</Box>
						<Tooltip text={`Repeat ${isPodcast ? "episode" : "track"}`} placement="top">
							<IconButton
								size="small"
								className={`hideMobile ${repeatTrack && "active"}`}
								onClick={() => setRepeatTrack(!repeatTrack)}
							>
								<IconRepeat />
							</IconButton>
						</Tooltip>
					</>
				)}
				<Tooltip text={`Previous ${isPodcast ? "episode" : "track"}`} placement="top">
					<IconButton className="hideMobile" size="small" onClick={handlePrevious} disabled={isTrackLengthOne}>
						<IconPrev />
					</IconButton>
				</Tooltip>
				{noPlayer ? (
					<OpenDocumentButton
						btnColor={communityColors.btn}
						href={seriesPage}
						target="_blank"
						rel="noopener noreferrer"
					>
						Open
					</OpenDocumentButton>
				) : (
					<PlayTrack btnColor={communityColors.btn} medium noMr onClick={() => setPlay(!play)}>
						{play ? (
							<IconPause className="fw" fill="white" width={22} height={22} />
						) : (
							<Icon name="play" group="filled" fill="white" width={16} height={16} />
						)}
					</PlayTrack>
				)}
				<Tooltip text={`Next ${isPodcast ? "episode" : "track"}`} placement="top">
					<IconButton size="small" onClick={handleNextTrack} disabled={isTrackLengthOne}>
						<IconPrev className="flip-svg" />
					</IconButton>
				</Tooltip>
				{!noPlayer && (
					<Tooltip text="Shuffle" placement="top">
						<IconButton
							size="small"
							className={`hideMobile ${shuffleTracks && "active"}`}
							onClick={() => setShuffleTracks(!shuffleTracks)}
						>
							<IconShuffle />
						</IconButton>
					</Tooltip>
				)}
				<Tooltip text="Close player" placement="top">
					<IconButton
						className="noMr"
						size="small"
						onClick={() => {
							setPlayTracks({ idx: 0 });
							if (seriesPlayState) {
								setSeriesPlayState();
							}
							setAlbumPlaying();
						}}
					>
						<Icon name="close" group="filled" fill="#8f9bb3" />
					</IconButton>
				</Tooltip>
			</Player.TrackControls>
		),
		[
			showVoiceControl,
			muted,
			seriesPlayState,
			noPlayer,
			isPodcast,
			repeatTrack,
			shuffleTracks,
			handleMuteSound,
			seriesPage,
			play,
			handleNextTrack,
			handlePrevious,
			setAlbumPlaying,
			setPlay,
			setPlayTracks,
			setRepeatTrack,
			setSeriesPlayState,
			setShowVoiceControl,
			setShuffleTracks,
			muteSound,
			playSound,
			isTrackLengthOne,
			communityColors.btn
		]
	);

	if (
		!tracksList.length ||
		seriesPage === location.pathname ||
		(!playTracks.series?.items && !playTracks.tracks?.items)
	)
		return null;

	return (
		<PlayerContainer>
			<Player>
				{TrackInfo}
				<Player.CurrentTrack bgColor={communityColors.btn}>
					{!noPlayer && (
						<>
							{itemType === "MUSIC" && (
								<audio muted={muted} ref={audioRef}>
									<source src={fileUrl} />
								</audio>
							)}
							<Box className="duration played">{currentDuration}</Box>
							<InputRange value={songProgress} minValue={0} maxValue={100} onChange={handleSlide} />
							<Box className="duration">{totalDuration}</Box>
						</>
					)}
				</Player.CurrentTrack>
				{TrackControls}
			</Player>
		</PlayerContainer>
	);
};

export default TrackPlayer;
