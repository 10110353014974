import styled from "styled-components";

import { Box, Text } from "shared/ui-kit";

export const RecommendedConnections = styled(Box)`
	margin-top: 40px;
`;

RecommendedConnections.Header = styled(Text)`
	font-size: 22px;
	font-weight: bold;
	color: #222b45;
	margin-bottom: 16px;
`;

export const ProfileContainerSkeleton = styled(Box)`
	width: 100%;
	padding: 0 16px;
	max-width: 1120px;
	margin: auto;
`;

export const ProfileSkeleton = styled(Box)`
	border-radius: 12px;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
	box-shadow: inset 0 0 0 0.5px #c5cee0;
	background-color: #fff;
	height: 600px;
`;

ProfileSkeleton.IconWrapper = styled(Box)`
	width: 100%;
	height: 400px;
	border-top-left-radius: 12px;
	border-top-right-radius: 12px;
	overflow: hidden;
`;

export const BoxWrapper = styled(Box)`
	.circle {
		width: 158px;
		height: 158px;
		border-radius: 50%;
		background-color: #f9f9f9;
		border: 4px solid white;
	}
`;
