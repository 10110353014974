import React, { memo, useMemo } from "react";

import styled from "styled-components";

import { ReactComponent as Activity } from "assets/icons/activity.svg";

import { ReactComponent as IcAddConn } from "assets/icons/addConnection.svg";
import { ReactComponent as IcAlert } from "assets/icons/alert.svg";
import { ReactComponent as IcAppleSignin } from "assets/icons/apple.svg";
import { ReactComponent as Bell } from "assets/icons/bell.svg";

import { ReactComponent as IcBlock } from "assets/icons/block.svg";
import { ReactComponent as Blog } from "assets/icons/blog.svg";

import { ReactComponent as IcBook } from "assets/icons/book.svg";
import { ReactComponent as IcCamera } from "assets/icons/camera.svg";
import { ReactComponent as IcCameraFilled } from "assets/icons/camera_filled.svg";
import { ReactComponent as IcCards } from "assets/icons/cards.svg";
import { ReactComponent as IcCart } from "assets/icons/cart.svg";
import { ReactComponent as IcCertification } from "assets/icons/certification.svg";
import { ReactComponent as IcCheckCircle } from "assets/icons/check_circle.svg";
import { ReactComponent as IcShareoff } from "assets/icons/circle_border.svg";
import { ReactComponent as IcClock } from "assets/icons/clock.svg";
import { ReactComponent as IcClose } from "assets/icons/close.svg";
import { ReactComponent as IcCopy } from "assets/icons/copy.svg";
import { ReactComponent as IcDownload } from "assets/icons/download.svg";
import { ReactComponent as IcEdit } from "assets/icons/edit.svg";
import { ReactComponent as IcLeaveGrp } from "assets/icons/exit.svg";
import { ReactComponent as IcEye } from "assets/icons/eye1.svg";
import { ReactComponent as IcFilledStar } from "assets/icons/filled_star.svg";
import { ReactComponent as IcGallery } from "assets/icons/gallery.svg";
import { ReactComponent as IcGlobe } from "assets/icons/globe.svg";
import { ReactComponent as IcGraduationCap } from "assets/icons/graduation_cap.svg";
import { ReactComponent as IcGroups } from "assets/icons/groups.svg";
import { ReactComponent as IcHeartFilled } from "assets/icons/heart_filled.svg";
import { ReactComponent as Job } from "assets/icons/job.svg";
import { ReactComponent as IcLeft } from "assets/icons/left.svg";
import { ReactComponent as IcLegal } from "assets/icons/legal.svg";
import { ReactComponent as IcLocation } from "assets/icons/location.svg";
import { ReactComponent as IcLocFilled } from "assets/icons/location_filled.svg";
import { ReactComponent as IcMail } from "assets/icons/mail.svg";
import { ReactComponent as IcStoryTagUser } from "assets/icons/mention.svg";
import { ReactComponent as Message } from "assets/icons/message.svg";
import { ReactComponent as IcMsgboard } from "assets/icons/msgboard.svg";
import { ReactComponent as IcMute } from "assets/icons/mute.svg";
import { ReactComponent as IcNavigation } from "assets/icons/navigation.svg";
import { ReactComponent as IcNavigationFilled } from "assets/icons/navigation_filled.svg";
import { ReactComponent as IcPaperclip } from "assets/icons/paperclip.svg";
import { ReactComponent as IcGrppeople } from "assets/icons/people.svg";
import { ReactComponent as IcProfile } from "assets/icons/profile1.svg";
import { ReactComponent as IcRemoveConn } from "assets/icons/remove_conn.svg";
import { ReactComponent as IcRight } from "assets/icons/right.svg";
import { ReactComponent as IcRight2 } from "assets/icons/right_arrow.svg";
import { ReactComponent as IcSave } from "assets/icons/save.svg";
import { ReactComponent as IcSettings } from "assets/icons/settings.svg";
import { ReactComponent as IcShare } from "assets/icons/share.svg";
import { ReactComponent as IcStrikeEye } from "assets/icons/strikethrough.svg";
import { ReactComponent as IcGrpvideo } from "assets/icons/video.svg";
import { ReactComponent as IcVolunteer } from "assets/icons/volunteer.svg";

import { BoardIconType } from "modules/Manage/Data/types";
import { Box, Text } from "shared/ui-kit";

const Container = styled(Box)`
	margin: 16px 0;
`;

const IconWrapper = styled(Box)`
	height: 48px;
	width: 48px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 4px;
	border: solid 1px #e4e9f2;

	&:hover {
		border-color: grey;
	}

	> svg {
		fill: black;

		use {
			fill: black;
		}

		${({ selected, theme }) => selected && `fill: ${theme.palette.primary.main};`}

		&:hover {
			fill: grey;

			use {
				fill: grey;
			}
		}
	}

	&.color-first-path {
		> svg {
			path {
				&:first-child {
					fill: black;
				}
			}
		}

		&:hover {
			> svg {
				path {
					&:first-child {
						fill: grey;
					}
				}
			}
		}
	}

	&.color-all-paths {
		> svg {
			path {
				fill: black;
			}
		}

		&:hover {
			> svg {
				path {
					fill: grey;
				}
			}
		}
	}

	&.selected {
		border-color: ${({ theme }) => theme.palette.primary.main};

		> svg {
			fill: ${({ theme }) => theme.palette.primary.main};

			use {
				fill: ${({ theme }) => theme.palette.primary.main};
			}
		}

		&.color-first-path {
			> svg {
				path {
					&:first-child {
						fill: ${({ theme }) => theme.palette.primary.main};
					}
				}
			}
		}

		&.color-all-paths {
			> svg {
				path {
					fill: ${({ theme }) => theme.palette.primary.main};
				}
			}
		}
	}
`;

const Wrapper = styled(Box)`
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(48px, 1fr));
	grid-gap: 4px;
	margin: 10px 0;
`;

export const boardIcons: BoardIconType[] = [
	{
		value: "ic_job",
		icon: <Job />
	},
	{
		value: "ic_activity",
		icon: <Activity />
	},
	{
		value: "ic_bell_2",
		icon: <Bell />
	},
	{
		value: "ic_blog",
		icon: <Blog />
	},
	{
		value: "ic_message",
		icon: <Message />
	},
	{
		value: "ic_strike_eye",
		icon: <IcStrikeEye />
	},
	{
		value: "ic_navigation",
		icon: <IcNavigation />
	},
	{
		value: "ic_navigation_filled",
		icon: <IcNavigationFilled />
	},
	{
		value: "ic_shareoff",
		icon: <IcShareoff />
	},
	{
		value: "ic_check_circle",
		icon: <IcCheckCircle />
	},
	{
		value: "ic_heart_filled",
		icon: <IcHeartFilled />
	},
	{
		value: "ic_filledStar",
		icon: <IcFilledStar />
	},
	{
		value: "ic_camera",
		icon: <IcCamera />
	},
	{
		value: "ic_camera_filled",
		icon: <IcCameraFilled />
	},
	{
		value: "ic_right_2",
		icon: <IcRight2 />
	},
	{
		value: "ic_close",
		icon: <IcClose />
	},
	{
		value: "ic_location",
		icon: <IcLocation />
	},
	{
		value: "ic_loc_filled",
		icon: <IcLocFilled />
	},
	{
		value: "ic_share",
		icon: <IcShare />
	},
	{
		value: "ic_right",
		icon: <IcRight />
	},
	{
		value: "ic_clock",
		icon: <IcClock />
	},
	{
		value: "ic_settings",
		icon: <IcSettings />
	},
	{
		value: "ic_left",
		icon: <IcLeft />
	},
	{
		value: "ic_paperclip",
		icon: <IcPaperclip />
	},
	{
		value: "ic_grpvideo",
		icon: <IcGrpvideo />,
		colorFirstPath: true
	},
	{
		value: "ic_grppeople",
		icon: <IcGrppeople />,
		colorFirstPath: true
	},
	{
		value: "ic_mute",
		icon: <IcMute />
	},
	{
		value: "ic_leaveGrp",
		icon: <IcLeaveGrp />,
		colorFirstPath: true
	},
	{
		value: "ic_addConn",
		icon: <IcAddConn />
	},
	{
		value: "ic_cart",
		icon: <IcCart />,
		colorFirstPath: true
	},
	{
		value: "ic_book",
		icon: <IcBook />,
		colorFirstPath: true
	},
	{
		value: "ic_legal",
		icon: <IcLegal />
	},
	{
		value: "ic_apple_signin",
		icon: <IcAppleSignin />,
		colorFirstPath: true
	},
	{
		value: "ic_remove_conn",
		icon: <IcRemoveConn />
	},
	{
		value: "ic_msgboard",
		icon: <IcMsgboard />
	},
	{
		value: "ic_groups",
		icon: <IcGroups />,
		colorFirstPath: true
	},
	{
		value: "ic_gallery",
		icon: <IcGallery />,
		colorFirstPath: true
	},
	{
		value: "ic_download",
		icon: <IcDownload />
	},
	{
		value: "ic_copy",
		icon: <IcCopy />,
		colorFirstPath: true
	},
	{
		value: "ic_cards",
		icon: <IcCards />,
		colorAllPaths: true
	},
	{
		value: "ic_block",
		icon: <IcBlock />
	},
	{
		value: "ic_profile",
		icon: <IcProfile />
	},
	{
		value: "ic_globe",
		icon: <IcGlobe />,
		colorFirstPath: true
	},
	{
		value: "ic_graduation_cap",
		icon: <IcGraduationCap />
	},
	{
		value: "ic_certification",
		icon: <IcCertification />,
		colorFirstPath: true
	},
	{
		value: "ic_volunteer",
		icon: <IcVolunteer />
	},
	{
		value: "ic_edit",
		icon: <IcEdit />
	},
	{
		value: "ic_eye",
		icon: <IcEye />
	},
	{
		value: "ic_alert",
		icon: <IcAlert />
	},
	{
		value: "ic_save",
		icon: <IcSave />,
		colorFirstPath: true
	},
	{
		value: "ic_mail",
		icon: <IcMail />
	},
	{
		value: "ic_storyTagUser",
		icon: <IcStoryTagUser />
	}
];

interface Props {
	value: string;
	onChange: (value: string) => void;
}

const SelectIconField: React.FC<Props> = memo(({ value, onChange }: Props) => {
	const Icons = useMemo(
		() =>
			boardIcons.map((icon, i) => (
				<IconWrapper
					className={`${icon?.colorFirstPath ? "color-first-path" : icon?.colorAllPaths ? "color-all-paths" : ""} ${
						icon.value === value ? "selected" : ""
					}`}
					key={i}
					onClick={() => onChange(icon.value)}
				>
					{icon.icon}
				</IconWrapper>
			)),
		[value, onChange]
	);
	return (
		<Container>
			<Text variant="h6">Select Icon</Text>
			<Wrapper>{Icons}</Wrapper>
		</Container>
	);
});

export default SelectIconField;
