import React, { useEffect, useRef, useState } from "react";

import { useOnScreen } from "shared/hooks";

const EnableOnVisible = ({ children }) => {
	const [isAlwaysVisible, setIsAlwaysVisible] = useState(false);

	const wrapperRef = useRef<HTMLElement | any>(null);

	const isVisible = useOnScreen(wrapperRef);

	useEffect(() => {
		if (isVisible) {
			setIsAlwaysVisible(true);
		}
	}, [isVisible]);

	return <section ref={wrapperRef}>{isAlwaysVisible && children}</section>;
};

export default EnableOnVisible;
