import { useMemo } from "react";

import { ContactType } from "types";

import useHttpActions from "shared/services/api/core/useHttpActions";
import {
	FundraiserDonationType,
	FundraiserModel,
	LeaderboardGroup,
	LeaderboardMember,
	LeaderboardSponsor,
	ProfileType,
	SponsorModel
} from "shared/types";

import { API_URLS } from "./../constants";
import { BaseConverter } from "./converters";

import { FundraiserIntentType } from "../../shared/types/FundraiserLeaderboardTypes";

const useFundraisersApiService = () => {
	const actions = useHttpActions();

	return useMemo(
		() => ({
			getFundraisers: async (offset: number, limit: number, keyword?: string) => {
				return BaseConverter.convert<{ fundraisers: FundraiserModel[] }>(
					await actions.get(API_URLS.FUNDRAISER.BASE, { offset, limit, keyword })
				);
			},
			getFundraisersCount: async (keyword?: string) => {
				return BaseConverter.convert<{ totalCount: number }>(await actions.get(API_URLS.FUNDRAISER.COUNT, { keyword }));
			},
			getDonationsCount: async params => {
				return BaseConverter.convert<{ count: number }>(await actions.get(API_URLS.FUNDRAISER.DONATIONS_COUNT, params));
			},
			getFundraiserMembers: async ({
				id,
				keyword,
				limit,
				offset,
				addContactCount,
				keepDuplicates,
				workspace
			}: {
				id: string;
				keyword?: string;
				limit?: number;
				offset?: number;
				addContactCount?: boolean;
				keepDuplicates?: boolean;
				workspace?: string;
			}) => {
				const data = {
					keyword,
					limit,
					offset,
					addContactCount,
					keepDuplicates
				};

				return BaseConverter.convert<{ members: ProfileType[] }>(
					workspace
						? await actions.getWithCustomWorkspace(API_URLS.FUNDRAISER.MEMBERS(id), workspace, data)
						: await actions.get(API_URLS.FUNDRAISER.MEMBERS(id), data)
				);
			},
			getFundraisersDonations: async ({
				sortBy,
				startDate,
				endDate,
				fundraiserId,
				keyword,
				limit,
				offset,
				order
			}: {
				offset: number;
				limit: number;
				sortBy?: string;
				startDate?: Date;
				endDate?: Date;
				keyword?: string;
				fundraiserId?: string;
				order: number;
			}) => {
				return BaseConverter.convert<{ donations: FundraiserDonationType[] }>(
					await actions.get(API_URLS.FUNDRAISER.DONATIONS_LIST, {
						sortBy: sortBy || "sponsor",
						startDate,
						endDate,
						fundraiserId,
						keyword,
						limit,
						offset,
						order
					})
				);
			},
			getPaymentIntent: async ({
				name,
				amount,
				groupId,
				fundraiserId,
				personaId,
				uniqueIdentifierType,
				uniqueIdentifier,
				workspace
			}: {
				name: string;
				amount: number;
				groupId: string;
				fundraiserId: string;
				personaId: number;
				uniqueIdentifierType: string;
				uniqueIdentifier: string;
				workspace: string;
			}) => {
				return BaseConverter.convert<{ paymentIntent: FundraiserIntentType }>(
					await actions.postWithCustomWorkspace(API_URLS.FUNDRAISER.PAYMENT_INTENT, workspace, {
						name,
						amount,
						groupId,
						fundraiserId,
						personaId,
						uniqueIdentifierType,
						uniqueIdentifier
					})
				);
			},
			confirmDonation: async ({
				donationId,
				contactId,
				workspace
			}: {
				donationId: string;
				contactId?: string;
				workspace: string;
			}) => {
				return BaseConverter.convert<{ success: boolean }>(
					await actions.putWithCustomWorkspace(API_URLS.FUNDRAISER.DONATION_CONFIRM, workspace, {
						donationId,
						contactId: contactId || null
					})
				);
			},
			getFundraisersCSVFile: async ({
				sortBy,
				startDate,
				endDate,
				fundraiserId,
				keyword
			}: {
				sortBy?: string;
				startDate?: Date;
				endDate?: Date;
				keyword?: string;
				fundraiserId?: string;
			}) => {
				return BaseConverter.convert<string | undefined>(
					await actions.get(API_URLS.FUNDRAISER.DONATIONS_LIST_CSV, {
						sortBy: sortBy || "sponsor",
						startDate,
						endDate,
						fundraiserId,
						keyword
					})
				);
			},

			donateToFundraiser: async (data, workspace?: string) => {
				return BaseConverter.convert<{ members: ProfileType[] }>(
					workspace
						? await actions.postWithCustomWorkspace(API_URLS.FUNDRAISER.DONATE, workspace, data)
						: await actions.post(API_URLS.FUNDRAISER.DONATE, data)
				);
			},
			createFundraiser: async (data: Partial<FundraiserModel>) => {
				return BaseConverter.convert(await actions.post(API_URLS.FUNDRAISER.BASE, data));
			},
			updateFundraiser: async (id: string, fundraiser: Partial<FundraiserModel>) => {
				return BaseConverter.convert(await actions.put(API_URLS.FUNDRAISER.DETAILS(id), fundraiser));
			},
			updateFundraiserContacts: async (id: string, data: Partial<ContactType>) => {
				return BaseConverter.convert<{ contact: ContactType }>(
					await actions.put(API_URLS.FUNDRAISER.CONTACT_INFO(id), data)
				);
			},
			deleteFundraiserContacts: async (id: string) => {
				return BaseConverter.convert<{ success: boolean }>(await actions.delete(API_URLS.FUNDRAISER.CONTACT_INFO(id)));
			},
			inviteContact: async data => {
				return BaseConverter.convert(await actions.post(API_URLS.FUNDRAISER.CONTACTS, data));
			},
			deleteFundraiser: async (id: string) => {
				return BaseConverter.convert(await actions.delete(API_URLS.FUNDRAISER.DETAILS(id)));
			},
			getMyContacts: async (fundraiserId: string, myContactsOnly?: boolean) => {
				return BaseConverter.convert<{ contacts: ContactType[] }>(
					await actions.get(API_URLS.FUNDRAISER.CONTACTS, { fundraiserId, myContactsOnly })
				);
			},
			getLeaderboardMembers: async (id: string, customWorkspace?: string | null) => {
				return BaseConverter.convert<{
					members: LeaderboardMember[];
				}>(
					await actions.get(
						API_URLS.FUNDRAISER.LEADERBOARD_CONTACTS(id),
						null,
						customWorkspace
							? {
									headers: {
										workspace: customWorkspace
									}
							  }
							: undefined
					)
				);
			},
			getLeaderboardGroups: async (id: string, customWorkspace?: string | null) => {
				return BaseConverter.convert<{
					groups: LeaderboardGroup[];
				}>(
					await actions.get(
						API_URLS.FUNDRAISER.LEADERBOARD_GROUPS(id),
						null,
						customWorkspace
							? {
									headers: {
										workspace: customWorkspace
									}
							  }
							: undefined
					)
				);
			},
			getLeaderboardSponsors: async (id: string, customWorkspace?: string | null) => {
				return BaseConverter.convert<{
					sponsors: LeaderboardSponsor[];
				}>(
					await actions.get(
						API_URLS.FUNDRAISER.LEADERBOARD_SPONSORS(id),
						null,
						customWorkspace
							? {
									headers: {
										workspace: customWorkspace
									}
							  }
							: undefined
					)
				);
			},
			getContactInfo: async (id: string, workspace?: string) => {
				return BaseConverter.convert<{ contact: ContactType }>(
					workspace
						? await actions.getWithCustomWorkspace(API_URLS.FUNDRAISER.CONTACT_INFO(id), workspace)
						: await actions.get(API_URLS.FUNDRAISER.CONTACT_INFO(id))
				);
			},
			openContact: async (id: string, workspace: string) => {
				return BaseConverter.convert<{ contact: ContactType }>(
					await actions.putWithCustomWorkspace(API_URLS.FUNDRAISER.OPEN_CONTACT(id), workspace)
				);
			},
			pauseFundraiser: async (id: string, action: "pause" | "unpause") => {
				return BaseConverter.convert<{ contact: ContactType }>(
					await actions.put(API_URLS.FUNDRAISER.PAUSE(id), { action })
				);
			},
			getFundraiserDetails: async (id: string, workspace?: string) => {
				return BaseConverter.convert<{ fundraisers: FundraiserModel[] }>(
					workspace
						? await actions.getWithCustomWorkspace(API_URLS.FUNDRAISER.DETAILS(id), workspace)
						: await actions.get(API_URLS.FUNDRAISER.DETAILS(id))
				);
			},
			getFundraiserSponsors: async ({ id, limit }: { id: string; limit?: number }) => {
				return BaseConverter.convert<{ sponsors: SponsorModel[] }>(
					await actions.get(API_URLS.FUNDRAISER.LEADERBOARD_SPONSORS(id), { limit })
				);
			},
			subscribe: async ({
				action,
				workspace,
				contactId
			}: {
				action: "unsub" | "resub";
				contactId: string;
				workspace: string;
			}) => {
				return BaseConverter.convert(
					await actions.putOriginal(API_URLS.FUNDRAISER.UNSUBSCRIBE(contactId), { action }, undefined, {
						headers: {
							workspace
						}
					})
				);
			}
		}),
		[actions]
	);
};

export default useFundraisersApiService;
