import React, { FC } from "react";

import { Box, ButtonBase } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

import { CategoryProps } from "modules/Marketplace/Data/types";
import { useMemberRoutes } from "shared/hooks";
import { Text } from "shared/ui-kit";

import { Wrapper } from "./style";

const InlineCategoryCard: FC<{ cat?: CategoryProps; skeleton?: boolean; grayHover?: boolean; small?: boolean }> = ({
	cat,
	grayHover,
	small,
	skeleton
}) => {
	const { getMemberRoutesData } = useMemberRoutes();
	const { routes } = getMemberRoutesData();

	return (
		<Wrapper small={small} grayHover={grayHover} to={`${routes?.member.collections.getPath()}/all?cId=${cat?._id}`}>
			<ButtonBase>
				{skeleton && (
					<>
						<Skeleton className="mr-16" height={80} width={80} />
						<Box>
							<Skeleton className="mb-8" width={80} height={20} />
							<Skeleton width={32} height={16} />
						</Box>
					</>
				)}
				{cat && (
					<>
						<img src={cat.photo} alt={cat.name} className="mr-4" />
						<Box>
							<Text variant="h4">{cat.name}</Text>
							<Text>{!!cat.seriesCount ? cat.seriesCount : "Empty"} Series</Text>
						</Box>
					</>
				)}
			</ButtonBase>
		</Wrapper>
	);
};

export default InlineCategoryCard;
