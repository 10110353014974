import React, { FC, useEffect, useMemo, useRef, useState } from "react";

import { ClickAwayListener, InputAdornment } from "@material-ui/core";
import { ToolbarComponentProps } from "@material-ui/pickers/Picker/Picker";

import clsx from "clsx";

import { ReactComponent as CalendarIcon } from "assets/icons/calendar-filled.svg";

import { TwoOptionsSwitch } from "modules/MemberHome/View/shared";
import { CustomToolbarComponent, IconWrapper, Wrapper } from "modules/MemberHome/View/shared/DateInput/style";

import { InputWrapper } from "modules/MemberHome/View/shared/style";
import { useCommunity } from "shared/hooks";

import { Text } from "shared/ui-kit";

import { UIKitDateInput } from "./style";

import { SizesEnum } from "../../types";
import { BaseInputProps } from "./index";

export interface DatepickerProps extends Omit<BaseInputProps, "value"> {
	value?: string;
	onChange: (date?: any) => void;
	minDate?: Date | number;
	shouldDisableDate?: (day: number | Date) => boolean;
	defaultCalendarMonth?: Date | number;
	disablePast?: boolean;
	includeTime?: boolean;
	wrapperWidth?: string;
	labelClassName?: string;
	rightIconColor?: string;
	isBackgroundGray?: boolean;
	inputRef?: React.MutableRefObject<HTMLInputElement>;
	narrow?: boolean;
	placeholder?: string;
	isOpen: boolean;
	setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
	dateFormat?: string;
}

const Datepicker: FC<DatepickerProps> = ({
	// loading,
	// disabled,
	// placeholder,
	// error,
	// errorText,
	// warning,
	// warningText,
	minDate,
	error,
	onBlur,
	disablePast,
	value,
	onChange,
	label,
	includeTime,
	wrapperWidth,
	size,
	isOpen,
	setIsOpen,
	dateFormat
}) => {
	const [time, setTime] = useState({
		hours: 1,
		minutes: 1,
		PM: true
	});

	const pickerRef = useRef<HTMLDivElement>();

	useEffect(() => {
		if (isOpen) {
			pickerRef.current && pickerRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
		}
	}, [isOpen]);

	const { communityColors } = useCommunity();

	useEffect(() => {
		if (includeTime) {
			const now = new Date();
			setTime({
				hours: now.getHours() >= 12 ? now.getHours() - 12 : now.getHours(),
				minutes: now.getMinutes(),
				PM: now.getHours() >= 12
			});
		}
	}, [includeTime]);

	const maxDate = useMemo(() => new Date().setFullYear(new Date().getFullYear() + 5), []);

	const CustomToolbar: FC<ToolbarComponentProps> = () => <CustomToolbarComponent />;

	const Calender = useMemo(
		() => (
			<UIKitDateInput
				open={isOpen}
				onClick={() => {
					setIsOpen(true);
				}}
				allowKeyboardControl
				autoOk
				variant={includeTime ? "static" : "inline"}
				inputVariant="outlined"
				label={label}
				format={dateFormat || "MM/dd/yyyy"}
				value={value}
				InputAdornmentProps={{ position: "start" }}
				disablePast={disablePast}
				minDate={minDate}
				onBlur={onBlur}
				onChange={date =>
					onChange(
						includeTime ? new Date(date?.setHours(time.PM ? time.hours + 12 : time.hours, time.minutes) || "") : date
					)
				}
				views={["date"]}
				ToolbarComponent={CustomToolbar}
				className={`size_${size}`}
				margin="none"
				id="date-picker-inline"
				PopoverProps={{
					className: "member-calendar-container"
				}}
				onOpen={() => {
					setTimeout(() => setIsOpen(true), 100);
				}}
				onClose={() => setIsOpen(false)}
				maxDate={maxDate}
				InputProps={{
					endAdornment: (
						<InputAdornment position="end">
							<IconWrapper className={isOpen && "active"}>
								<CalendarIcon />
							</IconWrapper>
						</InputAdornment>
					),
					style: {
						padding: "0 8px",
						borderRadius: "8px"
					},
					className: clsx(size === SizesEnum.MD && "h-12")
				}}
				KeyboardButtonProps={{ disabled: true, style: { display: "none" } }}
				minDateMessage={"Date should not be before current date"}
				maxDateMessage={"Date is too far in future"}
			/>
		),
		[
			disablePast,
			includeTime,
			isOpen,
			label,
			maxDate,
			minDate,
			onBlur,
			onChange,
			size,
			time.PM,
			time.hours,
			time.minutes,
			value,
			setIsOpen,
			dateFormat
		]
	);

	if (!includeTime) return <>{Calender}</>;

	return (
		<Wrapper width={wrapperWidth}>
			<ClickAwayListener
				onClickAway={() => {
					setIsOpen(false);
				}}
			>
				<InputWrapper className={!!error && "error"} mb={-1}>
					<Wrapper.Picker color={communityColors.primary} className={isOpen ? "show" : ""} ref={pickerRef}>
						{Calender}
						{includeTime && (
							<Wrapper.HourPickerWrapper>
								<input
									type="number"
									name="hour"
									placeholder="H"
									title="Hours"
									onChange={e =>
										setTime({
											...time,
											hours: isNaN(e.target.valueAsNumber) ? 1 : e.target.valueAsNumber
										})
									}
									value={time.hours}
								/>
								<Text>:</Text>
								<input
									type="number"
									name="minutes"
									placeholder="M"
									title="Minutes"
									onChange={e =>
										setTime({
											...time,
											minutes: isNaN(e.target.valueAsNumber) ? 1 : e.target.valueAsNumber
										})
									}
									value={time.minutes}
								/>
								<TwoOptionsSwitch
									optionOne={{ value: "PM", label: "PM" }}
									optionTwo={{ value: "AM", label: "AM" }}
									onChange={val => setTime(ctx => ({ ...ctx, PM: val === "PM" }))}
									small
									disableAnimation
									transformXZero
								/>
							</Wrapper.HourPickerWrapper>
						)}
					</Wrapper.Picker>
				</InputWrapper>
			</ClickAwayListener>
			{error && <Wrapper.ErrorText>{error}</Wrapper.ErrorText>}
		</Wrapper>
	);
};

export default Datepicker;
