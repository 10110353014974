import React from "react";

import { useLocation } from "react-router";

import { routes } from "apps/Auth/constants";
import { view } from "modules/App";

const ResetPasswordLinkPage = () => {
	const { search } = useLocation();
	const query = new URLSearchParams(search);
	const email = query.get("email") || undefined;

	return (
		<view.ResetPasswordLink
			forgotPasswordLink={routes.auth.forgotPassword.getPath()}
			signInLink={routes.auth.signIn.getPath()}
			email={email}
		/>
	);
};

export default ResetPasswordLinkPage;
