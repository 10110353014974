import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";

import { Controller, useForm } from "react-hook-form";

import { Link, useHistory } from "react-router-dom";

import { VARS } from "apps/Auth/constants";
import { useAuth } from "modules/App/Data";
import {
	AuthPagesContainer,
	ContentWrapperBlock,
	InputWrapper,
	ManageBtn,
	OtherLoginOptions
} from "modules/App/View/Container/style";
import { GLOBAL_VARS } from "shared/constants";
import { useCommunity } from "shared/hooks";
import { Box, Input, Loader, Text } from "shared/ui-kit";

import { validators } from "shared/ui-kit/utils/dynamicForm/validators";

import { PasswordInstructions } from "../../Components";
import AuthError from "../../Components/AuthError";
import { ContactUsText, Subtitle } from "../CreatePassword/style";

export interface ResetPasswordProps {
	signInLink: string;
	forgotPasswordLink: string;
	token?: string;
	communityUrl?: string;
	workspace?: string;
	email?: string;
	tenantLevel?: boolean;
}

const ResetPassword: FC<ResetPasswordProps> = ({
	signInLink,
	forgotPasswordLink,
	token,
	communityUrl,
	workspace,
	email,
	tenantLevel
}) => {
	const history = useHistory();
	const { resetUserPassword, resetWhitelabelUserPassword, getData: getAuthData } = useAuth();
	const { isLoading } = getAuthData();

	const { getCustomWorkspaceDetails, getData: getCommunityData } = useCommunity();
	const { isWhitelabelAppMode } = getCommunityData();

	const { handleSubmit, control, errors, formState, getValues, watch } = useForm({ mode: "onChange" });
	const { password, passwordConfirm } = watch(["password", "passwordConfirm"]);

	const [authError, setAuthError] = useState("");
	const [isWhitelabelApp, setIsWhitelabelApp] = useState(false);

	const passwordRef = useRef<HTMLDivElement>();
	const passwordConfirmRef = useRef<HTMLDivElement>();

	const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
	const [inputType, setInputType] = useState<"password" | "conformPassword">("password");

	const correctWorkspaceName = useMemo(
		() => workspace || (communityUrl ? communityUrl.replace(GLOBAL_VARS.COMMUNITY.EXTENSION_LINK, "") : "") || "",
		[workspace, communityUrl]
	);

	const checkCommunityStatus = useCallback(async () => {
		if (isWhitelabelAppMode) {
			setIsWhitelabelApp(true);
		} else if (correctWorkspaceName && tenantLevel) {
			const workspaceInfo = await getCustomWorkspaceDetails(correctWorkspaceName);
			if ("whiteLabelApp" in workspaceInfo) {
				setIsWhitelabelApp(!!workspaceInfo.whiteLabelApp);
			}
		}
	}, [correctWorkspaceName, getCustomWorkspaceDetails, tenantLevel, isWhitelabelAppMode]);

	useEffect(() => {
		checkCommunityStatus();

		return () => setAuthError("");
	}, [setAuthError, checkCommunityStatus]);

	if (!token) {
		history.push(forgotPasswordLink);
		return null;
	}

	const onSubmit = async data => {
		try {
			const status = isWhitelabelApp
				? await resetWhitelabelUserPassword(data.password, token, `${email}`, correctWorkspaceName)
				: await resetUserPassword(data.password, token);

			if (status) {
				history.push(`${signInLink}?passwordReset=true`);
			}
		} catch (error) {
			setAuthError((error as Error).message);
		}
	};

	const handleFocusPassword = (event: React.MouseEvent<HTMLElement>, confirm = false) => {
		setAnchorEl(event.currentTarget);
		setInputType(confirm ? "conformPassword" : "password");
	};

	return (
		<ContentWrapperBlock>
			<AuthPagesContainer>
				<Box className="header">
					<Text variant="h2">Reset your password</Text>
					<Subtitle variant="body1">Password should have 8 or more characters.</Subtitle>
				</Box>
				<PasswordInstructions
					password={inputType === "password" ? password : passwordConfirm}
					anchorEl={anchorEl}
					setAnchorEl={setAnchorEl}
				/>
				<Box className="inputs">
					<AuthError message={authError} />
					<form onSubmit={handleSubmit(onSubmit)}>
						<Controller
							name="password"
							control={control}
							rules={{
								required: "Field is required.",
								minLength: {
									value: 8,
									message: "Password length should be between 8 to 20"
								},
								maxLength: {
									value: 20,
									message: "Password length should be between 8 to 20"
								},
								validate: validators.password
							}}
							render={({ onChange, value }) => (
								<InputWrapper ref={passwordRef}>
									<Input
										label="Password"
										errorText={errors.password?.message}
										type="password"
										value={value}
										onChange={onChange}
										disabled={authError}
										onFocus={handleFocusPassword}
									/>
								</InputWrapper>
							)}
						/>
						<Controller
							name="passwordConfirm"
							control={control}
							rules={{
								required: "Field is required.",
								minLength: {
									value: 8,
									message: "Password length should be between 8 to 20"
								},
								maxLength: {
									value: 20,
									message: "Password length should be between 8 to 20"
								},
								validate: value => {
									if (value !== getValues("password")) {
										return "Passwords are not equal";
									}
								}
							}}
							render={({ onChange, value }) => (
								<InputWrapper ref={passwordConfirmRef}>
									<Input
										label="Confirm Password"
										errorText={errors.passwordConfirm?.message}
										type="password"
										value={value}
										onChange={onChange}
										disabled={authError}
										onFocus={e => handleFocusPassword(e, true)}
									/>
								</InputWrapper>
							)}
						/>
						<ManageBtn type="submit" removeSideMargin fullWidth size="large" disabled={!formState.isValid || authError}>
							{isLoading ? <Loader size="15px" color="secondary" show variant="intermediate" /> : "Continue"}
						</ManageBtn>
					</form>

					<OtherLoginOptions>
						<Link to={signInLink}>Return to Login </Link>
					</OtherLoginOptions>
				</Box>
			</AuthPagesContainer>
			<ContactUsText href={`mailto:${VARS.CONTACT_US_EMAIL}?subject=Community help`}>Contact Us</ContactUsText>
		</ContentWrapperBlock>
	);
};

export default ResetPassword;
