import React from "react";

import { Members, SkeletonBase } from "shared/Components";

import { ActionItemWrapper, StyledWrapper } from "../style";

const HeaderLoader = () => (
	<StyledWrapper.Header>
		<StyledWrapper.HeaderIconWrapper>
			<SkeletonBase variant="rect" animation="wave" width={"100%"} height={"100%"} />
		</StyledWrapper.HeaderIconWrapper>
		<StyledWrapper.HeaderBody>
			<StyledWrapper.HeaderInfoRow>
				<StyledWrapper.TypeWrapper>
					<SkeletonBase variant="circle" animation="wave" width={16} height={16} fullScale />
					<SkeletonBase variant="text" animation="wave" width={100} height={30} className={"side-offset"} />
				</StyledWrapper.TypeWrapper>
				<StyledWrapper.ActionsWrapper>
					<ActionItemWrapper>
						<SkeletonBase width={140} height={40} fullScale />
					</ActionItemWrapper>
					<ActionItemWrapper>
						<SkeletonBase width={140} height={40} fullScale />
					</ActionItemWrapper>
				</StyledWrapper.ActionsWrapper>
			</StyledWrapper.HeaderInfoRow>
			<SkeletonBase variant="text" animation="wave" width={160} height={50} />
			<StyledWrapper.MembersBlock>
				<Members memberImgs={[]} total={0} loading />
			</StyledWrapper.MembersBlock>
		</StyledWrapper.HeaderBody>
	</StyledWrapper.Header>
);

export default HeaderLoader;
