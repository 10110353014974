import styled from "styled-components";

export const ContactsWrapper = styled.div`
	min-height: 50vh;
	.info-gray {
		color: #8f9bb3;
	}
	.info-text {
		font-size: 12px;
		font-weight: 400;
		text-align: center;
		line-height: 16px;
	}

	${props => props.theme.breakpoints.up("sm")} {
		min-height: 500px;
	}
`;

export const StatusChip = styled.div`
	font-size: 10px;
	padding: 2px 0;

	&.pending,
	&.paused {
		border-color: #6173fe;
		color: #6173fe;
	}

	&.sent {
		border-color: #ff463d;
		color: #ff463d;
	}
	&.donated {
		border-color: #30d14d;
		color: #30d14d;
	}

	&.viewed {
		border-color: #c5cee0;
		color: #222b45;
	}
`;
