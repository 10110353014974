import { SeriesCollection } from "modules/Manage/Data/types";
import { CategoryProps } from "modules/Marketplace/Data/types";

import { StreamEntityType } from "./StreamEntityType";

import { MediaObjType, PriceTag, WorkspaceProductModel } from "./index";

export interface StreamType {
	_id: string;
	videoId: string | null;
	personaId: number;
	type: StreamEntityType;
	start: string;
	totalPlays: number;
	musicId: string | null;
	fileId: string | null;
	current: number;
	updatedAt: string;
	createdAt: string;
}
export interface VideoModel {
	_id: string;

	title: string;
	categoryId?: string;
	collections: SeriesCollection[];
	category?: CategoryProps;
	description: string;
	duration: string;

	creator?: boolean;
	creatorId: number | string;

	product: WorkspaceProductModel[];

	locked: boolean;
	totalPlays: number;
	meta: {
		video: MediaObjType;
		artwork: MediaObjType;
		previewStart: number;
		previewStop: number;
		canBeDownloaded: boolean;
		scheduleDate?: string;
		private: boolean;
	};

	priceTags?: PriceTag[];

	createdAt: string;
	updatedAt: string;
	stream: StreamType;

	groupId?: string;
}

export interface VideoFormModel
	extends Pick<VideoModel, "title" | "category" | "creatorId" | "description" | "duration" | "priceTags"> {
	videoFileUrl: string;
	videoImageUrl: string;

	price: { value: string };
	private: boolean;
	canBeDownloaded: boolean;
	preview: {
		start: number;
		end: number;
	};
	scheduleDate?: string;
}

export interface UpdateVideoFormModel
	extends Pick<VideoModel, "title" | "category" | "description" | "duration" | "_id" | "meta" | "priceTags"> {
	videoFileUrl: string;
	videoImageUrl: string;

	price: { value: string };
	private: boolean;
	canBeDownloaded: boolean;
	preview: {
		start: number;
		end: number;
	};
	scheduleDate?: string;
}

export interface VideoRequest
	extends Pick<VideoModel, "title" | "category" | "description" | "duration" | "meta" | "priceTags"> {
	_id?: string;
}

export enum VideoSortBy {
	timestamp = "timestamp",
	popular = "popular"
}

export interface VideoFilterType {
	sortBy?: VideoSortBy;
	categoryId?: string;
	offset: number;
	limit?: number;
	creatorId?: string;
	notEmptyBy?: string;
	personaId?: number;
	personaDocId?: string;
	keyword?: string;
}
