import { useCallback, useMemo } from "react";

import { CreateUpdateVolunteerDialog } from "shared/contexts/VolunteersContext/VolunteersContext";

import { useVolunteersApiService } from "shared/services";
import { EventModel } from "shared/types";

import { actionMethod, actionTypes, getValidationMessage } from "utils/getValidationMessage";

import { useVolunteerStore } from "../contexts";
import { CreateUpdateSlot, VolunteerMember, VolunteerSlot } from "../types/VolunteersTypes";
import { useEvent, useNotification } from "./index";

const useVolunteers = () => {
	const store = useVolunteerStore();
	const { setState } = useVolunteerStore();
	const service = useVolunteersApiService();

	const { setRequestEvents, getData: getEventData } = useEvent();
	const { requestEvents } = getEventData();

	const { showMessage } = useNotification();

	const setCreateUpdateDialog = useCallback(
		(createUpdateVolunteerDialog: CreateUpdateVolunteerDialog) => {
			setState({ createUpdateVolunteerDialog });
		},
		[setState]
	);

	const methods = useMemo(
		() => ({
			async getVolunteerSlots({
				eventId,
				offset,
				limit,
				sortOrder
			}: {
				eventId: string;
				offset: number;
				limit: number;
				sortOrder?: 1 | -1;
			}) {
				setState({ loading: true });

				try {
					const { success, volunteerslots } = await service.getVolunteerSlots({ eventId, offset, limit, sortOrder });

					if (!success) {
						throw Error();
					}

					setState(ctx => ({
						volunteersSlots: offset === 1 ? volunteerslots : [...ctx.volunteersSlots, ...volunteerslots],
						shouldRequestStop: volunteerslots.length < limit
					}));
				} catch (error) {
					showMessage(
						getValidationMessage({
							name: "Failed to fetch the volunteers",
							emoji: "⚠️"
						}),
						3,
						true
					);
				} finally {
					setState({ loading: false });
				}
			},
			async createVolunteerSlots(data: CreateUpdateSlot, eventId: string, onClose?: () => void, event?: EventModel) {
				setState({ creating: true });

				try {
					const { volunteerslot } = await service.createVolunteerSlot(data, eventId);

					if (event) {
						const _requestEvents = requestEvents.find(r => r._id === eventId);

						if (_requestEvents) {
							setRequestEvents(
								requestEvents.map(r =>
									r._id === eventId
										? {
												...r,
												requests: [...r.requests, { type: "volunteerslot", ...volunteerslot, filledQuantity: 0 }],
												volunteerSlotIds: [volunteerslot._id, ...r.volunteerSlotIds]
										  }
										: r
								)
							);
						} else {
							setRequestEvents([
								{
									...event,
									_id: event.eventId,
									name: event.title,
									eventDateAndTime: event.startTime,
									requests: [{ type: "volunteerslot", ...volunteerslot, filledQuantity: 0 }],
									volunteerSlotIds: [volunteerslot._id],
									carpoolRequestIds: [],
									carpoolOfferIds: []
								},
								...requestEvents
							]);
						}
					} else {
						setState(ctx => ({
							volunteersSlots: [{ ...volunteerslot, filledQuantity: 0 }, ...ctx.volunteersSlots]
						}));
					}
					setCreateUpdateDialog({ open: false });
					onClose && onClose();
					showMessage(
						getValidationMessage({
							name: "Volunteer slot created successfully"
						}),
						3
					);
				} catch (error) {
					showMessage(
						getValidationMessage({
							name: "Failed to create the volunteer",
							emoji: "⚠️"
						}),
						3,
						true
					);
				} finally {
					setState({ creating: false });
				}
			},
			async deleteVolunteerSlot(id: string, eventId?: string) {
				setState({ creating: true });

				try {
					const { success } = await service.deleteVolunteerSlot(id);

					if (!success) {
						throw Error();
					}

					if (eventId) methods.getVolunteerSlots({ eventId, sortOrder: 1, offset: 1, limit: 4 });
					else {
						setState(ctx => ({
							volunteersSlots: ctx.volunteersSlots.filter(l => l._id !== id)
						}));
					}

					showMessage(
						getValidationMessage({
							name: "Volunteer Slot",
							actionType: actionTypes.CRUD,
							actionMethod: actionMethod.deleted,
							emoji: "🗑"
						}),
						3
					);
					return { success };
				} catch (error) {
					showMessage(
						getValidationMessage({
							name: "Failed to delete the volunteer slot",
							emoji: "⚠️"
						}),
						3,
						true
					);
					return { success: false };
				} finally {
					setState({
						creating: false
					});
				}
			},
			async joinLeaveForVolunteerSlot(
				id: string,
				data: { action: "join" | "leave"; quantity?: number },
				userDetail: VolunteerMember
			) {
				try {
					const { success } = await service.JoinLeaveVolunteerSlot(id, data);

					if (!success) {
						throw Error();
					}

					if (data.action === "join") {
						setState(ctx => ({
							volunteersSlots: ctx.volunteersSlots.map(s =>
								s._id === id
									? { ...s, filledQuantity: s.filledQuantity + data.quantity, members: [...s.members, userDetail] }
									: s
							)
						}));
					} else {
						setState(ctx => ({
							volunteersSlots: ctx.volunteersSlots.map(s =>
								s._id === id
									? {
											...s,
											filledQuantity: s.filledQuantity - userDetail.quantity,
											members: s.members.filter(m => m.personaId !== userDetail.personaId)
									  }
									: s
							)
						}));
					}

					showMessage(
						getValidationMessage({
							name: `You've successfully ${data.action === "join" ? "signed up" : "cancelled"} your volunteering`
						}),
						3
					);
					return { success };
				} catch (error) {
					showMessage(
						getValidationMessage({
							name: `Failed to ${data.action === "join" ? "sign up" : "cancel"} the volunteer slot`,
							emoji: "⚠️"
						}),
						3,
						true
					);
					return { success: false };
				}
			},
			setDeleteVolunteerId: (volunteerDeleteId?: string) => {
				setState({ volunteerDeleteId });
			},
			setCancelVolunteerSlot: (cancelVolunteerSlot?: VolunteerSlot) => {
				setState({ cancelVolunteerSlot });
			},
			setVolunteerSlot: (volunteersSlots?: VolunteerSlot | []) => {
				setState({ volunteersSlots });
			},
			setSignUpVolunteerSlot: (signUpDialog: {
				open: boolean;
				signUpVolunteerDetail?: VolunteerSlot;
				eventDetail?: EventModel;
			}) => {
				setState({ signUpDialog });
			}
		}),
		[setState, service, showMessage, setCreateUpdateDialog, requestEvents, setRequestEvents]
	);

	const updateVolunteerSlots = useCallback(
		async (data: CreateUpdateSlot, volunteerId: string) => {
			setState({ creating: true });

			try {
				const { success } = await service.updateVolunteerSlot(data, volunteerId);

				if (!success) {
					throw new Error();
				}

				const findExistingSlotIndex = store.volunteersSlots.findIndex(s => s._id === volunteerId);

				if (typeof findExistingSlotIndex === "number" && findExistingSlotIndex >= 0) {
					setState(ctx => ({
						volunteersSlots: ctx.volunteersSlots.map(s => (s._id === volunteerId ? { ...s, ...data } : s))
					}));
				}
				setCreateUpdateDialog({ open: false, editDetails: undefined });
			} catch (error) {
				showMessage(
					getValidationMessage({
						name: "Failed to create the volunteer",
						emoji: "⚠️"
					}),
					3,
					true
				);
			} finally {
				setState({ creating: false });
			}
		},
		[store.volunteersSlots, service, setState, showMessage, setCreateUpdateDialog]
	);

	const getData = useCallback(() => {
		return store;
	}, [store]);

	return { ...methods, updateVolunteerSlots, getData, setCreateUpdateDialog };
};

export default useVolunteers;
