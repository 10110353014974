import React from "react";

import { ReactComponent as IconBubble } from "assets/icons/icon-chat-bubble.svg";
import { ReactComponent as HandFilledIcon } from "assets/icons/liveconvo/icon-hand-fill.svg";

import { EmptyBox } from "./style";

interface EmptyProps {
	hand?: boolean;
}

const Empty: React.FC<EmptyProps> = ({ hand }) => (
	<EmptyBox>
		<EmptyBox.IconWrapper className={hand ? "hand" : ""}>
			{hand ? (
				<HandFilledIcon viewBox="0 0 22 27" width={40} height={40} />
			) : (
				<IconBubble fill="rgba(255, 255, 255, 0.32)" width={40} height={40} />
			)}
		</EmptyBox.IconWrapper>
		<EmptyBox.Title>{"It's empty here yet"}</EmptyBox.Title>
		<EmptyBox.Text>{`We'll update you once you have ${
			hand ? "raised\nhands" : "\ncomments"
		} in your LIVE.`}</EmptyBox.Text>
	</EmptyBox>
);

export default Empty;
