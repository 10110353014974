import { useCallback, useMemo } from "react";

import { Omit } from "@material-ui/core";

import { EventType } from "types";

import { useUser } from "shared/hooks";
import { useEventApiService, useLocationApiService } from "shared/services";

import { EventFormData } from "shared/types/EventModel";
import { actionMethod, actionTypes, getValidationMessage } from "utils/getValidationMessage";

import { useEventStore } from "../contexts";

import { EventModel, EventSearchParams } from "../types";
import { useNotification } from "./index";

const useEvent = (isMarketing = false) => {
	const store = useEventStore();
	const { setState } = useEventStore();
	const { getData: getUserData } = useUser();
	const { userIPDetails } = getUserData();

	const service = useEventApiService(isMarketing);
	const locationService = useLocationApiService();

	const { showMessage } = useNotification();

	type PaginatedEventSearchParams = Omit<EventSearchParams, "count"> & { page: number; isLoading?: boolean };

	const methods = useMemo(
		() => ({
			getEvent: async (eventId: string) => {
				try {
					return await service.getEvent(eventId);
				} catch (error) {
					console.log((error as Error).message);
					return null;
				}
			},
			getEventsWithRequests: async (offset: number, limit: number) => {
				try {
					setState({ loading: true });
					const { events } = await service.getEventsWithRequests(offset, limit);
					setState(ctx => ({
						requestEvents: offset === 1 ? events : [...ctx.requestEvents, ...events],
						shouldRequestStop: events.length < limit
					}));
				} catch (error) {
					console.log((error as Error).message);
				} finally {
					setState({ loading: false });
				}
			},
			getEventAdmins: (id: string) => {
				return service.getEventAdmins(id);
			},
			loadCategories: async () => {
				const categories = await service.getCategories();
				setState(ctx => ({ ...ctx, categories }));
			},
			loadSuggestedLocations: async (keyWord: string) => {
				if (keyWord.length < 3) return;
				const suggestedLocations = await locationService.getLocationSuggestions(keyWord, {
					lat: userIPDetails.lat,
					lon: userIPDetails.lon
				}); // separate it to the it's own service

				setState(ctx => ({ ...ctx, suggestedLocations }));
			},
			getPaginatedEvents: async ({
				page = 1,
				limit = 10,
				keyword = "",
				eventSchedule = EventType.All,
				groupId,
				concatEvents = false,
				startDate,
				endDate
			}: PaginatedEventSearchParams & { concatEvents?: boolean }) => {
				const isInitial = keyword === "" && page === 1 && eventSchedule === EventType.All;
				setState(ctx => ({ ...ctx, loading: true }));
				const { events, totalEventsFound } = await service.getEvents({
					offset: page,
					limit,
					keyword,
					eventSchedule,
					groupId,
					startDate,
					endDate
				});
				setState(ctx => ({
					...ctx,
					loading: false,
					page: page,
					eventsShowPerPage: limit,
					eventCount: totalEventsFound,
					filteredEvents: concatEvents ? (page === 1 ? events : (ctx.filteredEvents || []).concat(events)) : events,
					allEventCount: isInitial ? totalEventsFound : ctx.allEventCount
				}));
			},
			inviteToEvent: (eventId: string, personaIds: number[]) => {
				return service.inviteToEvent(eventId, personaIds);
			},
			getEvents: async ({
				page = 1,
				limit = 10,
				keyword = "",
				eventSchedule = EventType.All,
				groupId,
				liveConversationIds,
				eventType,
				sortBy,
				sortOrder,
				categories,
				startDate,
				endDate,
				isLoading = true
			}: PaginatedEventSearchParams) => {
				isLoading && setState(ctx => ({ ...ctx, loading: true }));
				const { events, totalEventsFound } = await service.getEvents({
					offset: page,
					limit,
					keyword,
					eventSchedule,
					groupId,
					liveConversationIds,
					eventType,
					sortBy,
					sortOrder,
					categories,
					startDate,
					endDate
				});
				isLoading && setState(ctx => ({ ...ctx, loading: false }));
				return {
					events,
					totalEventsFound
				};
			},
			getUserEvents: async ({
				page = 1,
				limit = 10,
				personaId,
				potentialMatchingIds
			}: {
				page?: number;
				limit?: number;
				personaId: number;
				potentialMatchingIds: string[];
			}) => {
				return await service.getUserEvents(page, limit, personaId, potentialMatchingIds);
			},
			createEvent: async (data: EventFormData, isUpdate = false) => {
				setState({ creating: true });

				try {
					const event = isUpdate ? await service.updateEvent(data) : await service.createEvent(data);
					setState({ creating: false });

					showMessage(
						getValidationMessage({
							name: "Event",
							actionType: actionTypes.CRUD,
							actionMethod: isUpdate ? actionMethod.updated : actionMethod.created,
							emoji: isUpdate ? "✨" : "🎉"
						}),
						3
					);

					return event;
				} catch (e) {
					setState({ creating: false });
				}
			},
			updateEvent: async (data: Partial<EventFormData>) => {
				const event = await service.updateEvent(data);

				showMessage(
					getValidationMessage({
						name: "Event",
						actionType: actionTypes.CRUD,
						actionMethod: actionMethod.updated,
						emoji: "✨"
					}),
					3
				);

				return event;
			},
			setEventIntent: async (attending: boolean, eventId: string, interested: boolean) => {
				return await service.setEventIntent(attending, eventId, interested);
			},
			setCreating: (creating?: boolean) => {
				setState({ creating });
			},
			setInviteEventDialog: (inviteEventDialog?: boolean) => {
				setState({ inviteEventDialog });
			},
			async deleteEvent(id: string) {
				setState({ creating: true });

				const data = await service.deleteEvent(id);
				if (data) {
					setState(ctx => ({
						...ctx,
						filteredEvents: [...(ctx.filteredEvents as EventModel[]).filter(event => event.eventId !== id)],
						eventCount: (ctx.eventCount || 1) - 1
					}));

					showMessage(
						getValidationMessage({
							name: "Event",
							actionType: actionTypes.CRUD,
							actionMethod: actionMethod.deleted,
							emoji: "🗑"
						}),
						3
					);
				}

				setState({ creating: false });
			},
			setRequestEvents: requestEvents => {
				setState({ requestEvents });
			}
		}),
		[locationService, service, setState, showMessage, userIPDetails.lat, userIPDetails.lon]
	);

	const getData = useCallback(() => {
		return store;
	}, [store]);

	return { ...methods, getData };
};

export default useEvent;
