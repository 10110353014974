import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { useMediaQuery } from "@material-ui/core";

import { useCommunity } from "shared/hooks";
import { Icon, Text } from "shared/ui-kit";

import * as appTheme from "theme/default";

import FilterSelectOption from "./FilterSelectOption";
import { Action, Option, Wrapper } from "./style";

export interface ListOptionType {
	label: string;
	value: string;
	default?: boolean;
	id?: string;
}
interface BaseOptionType {
	label?: string;
	onClick?: (item?: string | string[]) => void;
	selectedOption?: string;
	inlineOptions?: boolean;
	id?: string;
}

export interface FilterOptionType extends BaseOptionType {
	listOptions: ListOptionType[];
	multipleChoice?: boolean;
	defaultValue?: number;
	loading?: boolean;
}

interface FilterBlockProps {
	options?: FilterOptionType[];
	createOption?: BaseOptionType;
	inline?: boolean;
	onShowOptions?: (item: string) => void;
	heading?: string;
}

const FilterBlock: React.FC<FilterBlockProps> = ({ options, createOption, inline, onShowOptions, heading }) => {
	const [activeVal, setActiveVal] = useState<{ idx: number; val: string }[]>([]);
	const [optionsRects, setOptionsRects] = useState<
		{
			label: string;
			rects: number;
		}[]
	>([]);

	const { communityColors } = useCommunity();

	const workspaceColor = useMemo(() => communityColors.btn, [communityColors.btn]);

	const listRef = useRef<HTMLDivElement>(null);

	const isMobile = useMediaQuery(appTheme.default.breakpoints.down("xs"));

	const calculateItemLeft = useCallback(() => {
		if (!listRef.current) return;
		const itemsRects: {
			label: string;
			rects: number;
		}[] = [];
		const items = listRef.current.querySelectorAll("[data-option]") as NodeListOf<HTMLElement>;
		items.forEach(item => {
			itemsRects.push({
				label: item.dataset["option"] as string,
				rects: isMobile ? item.getBoundingClientRect().left || 0 : 0
			});
		});

		setOptionsRects(itemsRects);
	}, [isMobile]);

	useEffect(() => {
		calculateItemLeft();
		listRef.current?.addEventListener("scroll", calculateItemLeft);
		window.addEventListener("resize", calculateItemLeft);
	}, [calculateItemLeft, isMobile]);

	const handleOption = useCallback(
		(l: ListOptionType, i: number, option: FilterOptionType) => {
			const idx = activeVal.findIndex(v => v.idx === i);

			if (idx > -1) {
				activeVal[idx].val === l.value
					? setActiveVal(ctx => ctx.filter(val => val.idx !== i))
					: setActiveVal(ctx =>
							ctx.map(val =>
								val.idx === i
									? {
											idx: i,
											val: l.value
									  }
									: val
							)
					  );
			} else {
				setActiveVal(ctx => [...ctx, { idx: i, val: l.value }]);
			}

			option.onClick && option.onClick(l.value);
		},
		[activeVal]
	);

	return (
		<Wrapper inline={inline}>
			<Option.List ref={listRef}>
				{heading && !options?.length && <Text variant="h5">{heading}</Text>}
				{(options || []).map((item, index) => {
					if (item.listOptions && !item.inlineOptions)
						return (
							<FilterSelectOption
								offsetLeft={optionsRects.find(op => op.label === item.label)?.rects || 0}
								item={item}
								onShowOptions={onShowOptions}
							/>
						);
					const val = activeVal.find(v => v.idx === index);
					return item.listOptions.map((l, i) => (
						<Option.ItemWrapper key={i} className="menuItem" value={item} inline={inline}>
							<Option.Item
								id={l?.id || ""}
								inline={inline}
								key={l.value}
								removeSideMargin
								active={val ? val.val === l.value : l.default}
								buttonTheme="outline"
								palette="basic"
								onClick={() => handleOption(l, index, item)}
							>
								{l.label}
							</Option.Item>
						</Option.ItemWrapper>
					));
				})}
			</Option.List>
			{createOption && (
				<Action.Wrapper>
					<Action.Item
						id={createOption?.id || ""}
						removeSideMargin
						buttonTheme={"light"}
						palette="primary"
						onClick={() => createOption?.onClick && createOption.onClick("")}
						leftIcon={<Icon className="fill-second-child" fill={workspaceColor || "#4350f7"} name={"plus"} />}
						bgColor={workspaceColor}
						cta
					>
						<span className="label">{createOption.label}</span>
					</Action.Item>
				</Action.Wrapper>
			)}
		</Wrapper>
	);
};
export default FilterBlock;
