import { Box } from "@material-ui/core";
import styled from "styled-components";

export const BoxItemWrapper = styled(Box)`
	margin-bottom: 16px;

	&.no-margin {
		margin-bottom: 0;
	}
`;
