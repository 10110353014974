import React, { FC, useCallback, useMemo, useRef } from "react";

import { Box, ButtonBase } from "@material-ui/core";

import styled from "styled-components";

import { ReactComponent as QuestionIcon } from "assets/icons/icon-question.svg";
import { ReactComponent as RestoreIcon } from "assets/icons/icon-restore.svg";
import { useCommunity, useConnection, useNotification } from "shared/hooks";
import { PersonStoryModel } from "shared/types";
import { Icon, Text } from "shared/ui-kit";

import { RefBox } from "../../shared/MemberDialog/style";
import { ConnectedType } from "./index";

const Wrapper = styled(Box)`
	width: 85vw;
	margin: auto;
	margin-top: ${({ marginTop }) => marginTop && `-${marginTop + 16}px`};
	z-index: 10;
	position: relative;
	h3 {
		font-size: 22px;
		font-weight: bold;
		line-height: 1.45;
		margin-left: 16px;
		color: #fff;
	}
	${props => props.theme.breakpoints.up("sm")} {
		width: 355px;
		opacity: 0;
		transition: 0.2s opacity ease-in-out;

		&.visible {
			opacity: 1;
		}
	}
`;

Wrapper.Actions = styled(Box)`
	margin-top: 32px;
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-gap: 16px;
	button {
		height: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
		border: 1px solid #fff;
		border-radius: 8px;
		color: #fff;
		p {
			font-size: 14px;
			font-weight: bold;
			line-height: 1.14;
			color: #fff;
		}

		&:disabled {
			opacity: 0.5;
		}
	}
`;

const RecommendedMatchesActions: FC<{
	showControls: boolean;
	personsStoryList: PersonStoryModel[];
	currentSlide: number;
	displayPosition: number;
	connectUser?: {
		loading: boolean;
		connected: ConnectedType[];
	};
	setConnectUser?: React.Dispatch<
		React.SetStateAction<{
			loading: boolean;
			connected: ConnectedType[];
		}>
	>;
}> = ({ showControls, personsStoryList, currentSlide, displayPosition, connectUser, setConnectUser }) => {
	const { showMessage } = useNotification();
	const { setMatchIntent, undoMatchPreference } = useConnection();

	const { getData: getCommnunityData } = useCommunity();
	const { isWhitelabelAppMode } = getCommnunityData();

	const headlineRef = useRef<HTMLDivElement>(null);

	const isAnswered = useMemo(
		() => connectUser && connectUser.connected.some(m => m.id === personsStoryList[currentSlide].personaId),
		[currentSlide, connectUser, personsStoryList]
	);

	const handleUndo = useCallback(async () => {
		const match = connectUser && connectUser.connected.find(s => s.id === personsStoryList[currentSlide].personaId);

		if (!match?.preferenceId) return;

		await undoMatchPreference(match.preferenceId);

		setConnectUser &&
			setConnectUser(ctx => ({
				...ctx,
				connected: ctx.connected.filter(s => s.id !== personsStoryList[currentSlide].personaId)
			}));
	}, [connectUser, currentSlide, personsStoryList, setConnectUser, undoMatchPreference]);

	const handleIntent = useCallback(
		async (response: "nope" | "maybe" | "yeah") => {
			setConnectUser && setConnectUser(ctx => ({ ...ctx, loading: true }));

			try {
				const data = {
					date: new Date().getTime().toString(),
					displayPosition,
					response,
					displayedPersonaId: personsStoryList[currentSlide].personaId
				};

				const { personaPreference } = await setMatchIntent(data);

				setConnectUser &&
					setConnectUser(ctx => ({
						...ctx,
						connected: [
							...ctx.connected,
							{
								id: personsStoryList[currentSlide].personaId,
								preferenceId: personaPreference._id
							}
						]
					}));

				showMessage("Successfully saved your answer");
			} catch (e: any) {
				showMessage(e.message);
			}

			setConnectUser && setConnectUser(ctx => ({ ...ctx, loading: false }));
		},
		[currentSlide, displayPosition, personsStoryList, setConnectUser, setMatchIntent, showMessage]
	);

	if (!connectUser || !setConnectUser) return null;

	return (
		<Wrapper className={showControls && "visible"} marginTop={headlineRef.current?.clientHeight}>
			<RefBox ref={headlineRef}>
				<Text variant="h3">
					{isAnswered
						? "Thanks! Your feedback will help us to better match you."
						: `Are you interested in this ${isWhitelabelAppMode ? "person" : "vyooer"}?`}
				</Text>
			</RefBox>
			<Wrapper.Actions>
				{isAnswered ? (
					<ButtonBase onClick={handleUndo} disabled={connectUser.loading}>
						<RestoreIcon />
						<Text>Undo</Text>
					</ButtonBase>
				) : (
					<>
						<ButtonBase onClick={() => handleIntent("nope")} disabled={connectUser.loading}>
							<Icon name="close" group="filled" fill="#fff" />
							<Text>Nope</Text>
						</ButtonBase>
						<ButtonBase onClick={() => handleIntent("maybe")} disabled={connectUser.loading}>
							<QuestionIcon />
							<Text>Maybe</Text>
						</ButtonBase>
						<ButtonBase onClick={() => handleIntent("yeah")} disabled={connectUser.loading}>
							<Icon name="check-mark" group="filled" fill="#fff" />
							<Text>Yeah</Text>
						</ButtonBase>
					</>
				)}
			</Wrapper.Actions>
		</Wrapper>
	);
};

export default RecommendedMatchesActions;
