import React, { ReactNode, useMemo, useState } from "react";

import DateFnsUtils from "@date-io/date-fns";

import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { useHistory } from "react-router-dom";

import { routes as authRoutes } from "apps/Auth/constants";
import { ReactComponent as HamburgerIcon } from "assets/icons/bars.svg";
import { ReactComponent as CompassFilledIcon } from "assets/icons/compass-filled.svg";
import { ReactComponent as VyooLogo } from "assets/icons/vyoo-logo.svg";

import { SideMenu } from "shared/Components";
import { useRoutes, useUser } from "shared/hooks";
// import { BackButton, BackButtonWrapper } from "shared/templates/BaseLayout/style";
import { Box, Icon } from "shared/ui-kit";

import NavMenu from "./NavMenu";

import {
	AdminLoginBtn,
	CreateCmtButton,
	HamburgerIconWrapper,
	Main,
	PageWrapper,
	TopBar,
	TopBarContainer
} from "./styles";

import { routes } from "../constants";

interface MarketingLayoutProps {
	children: ReactNode;
}

const MarketingLayout: React.FC<MarketingLayoutProps> = ({ children }) => {
	const history = useHistory();
	const { createNewCommunity, logout } = useRoutes();
	const [showMenu, setShowMenu] = useState(false);

	const { getData: getUserData } = useUser();
	const { workspaces } = getUserData();

	const options = useMemo(
		() => [
			{
				name: "Explore new communities",
				onClick: () => history.push(routes.communities.getPath()),
				icon: <CompassFilledIcon fill="#18212d" width={20} height={20} className="mr-1" />
			},
			{
				name: "Logout",
				onClick: logout,
				icon: <Icon name="log-out" group="filled" fill="#ff463d" width={20} height={20} className="mr-1" />
			}
		],
		[history, logout]
	);

	return (
		<PageWrapper>
			<TopBar>
				<TopBarContainer>
					<Box className="left-side">
						<Box className="brand">
							<Box className="brand-image">
								<VyooLogo />
							</Box>
						</Box>
					</Box>
					<div className="right-side">
						{!!workspaces?.length ? (
							<SideMenu dontFilterCurrentCommunity opts={options}>
								<AdminLoginBtn buttonTheme={"outline"}>Communities</AdminLoginBtn>
							</SideMenu>
						) : (
							<AdminLoginBtn buttonTheme={"outline"} onClick={() => history.push(authRoutes.auth.signIn.getPath())}>
								Login
							</AdminLoginBtn>
						)}
						<CreateCmtButton onClick={createNewCommunity}>Create Community</CreateCmtButton>
						<HamburgerIconWrapper onClick={() => setShowMenu(true)}>
							<HamburgerIcon />
						</HamburgerIconWrapper>
					</div>
				</TopBarContainer>
			</TopBar>
			<Main>
				{/* <BackButtonWrapper>
					<BackButton
						leftIcon={<Icon group={"filled"} fill={"black"} name={"arrow-left"} />}
						palette={"basic"}
						buttonTheme="light"
						onClick={() => history.go(-1)}
						size={"small"}
					>
						Back
					</BackButton>
				</BackButtonWrapper> */}
				<MuiPickersUtilsProvider utils={DateFnsUtils}>{children}</MuiPickersUtilsProvider>
			</Main>
			<NavMenu show={showMenu} onClose={() => setShowMenu(false)} />
		</PageWrapper>
	);
};

export default MarketingLayout;
