import React, { FC, useCallback, useEffect, useRef, useState } from "react";

import { Box, ClickAwayListener } from "@material-ui/core";
import clsx from "clsx";
import { Scrollbars } from "react-custom-scrollbars";

import { ReactComponent as ChevIcon } from "assets/icons/icon-chev.svg";

import { SuggestionTypes } from "shared/contexts/UserContext/UserContext";

import { useUser } from "shared/hooks";
import useScrollToXPosition from "shared/hooks/useScrollToXPosition";
import { updateElSize } from "utils/serviceUtils/helpers";

import { OptionList, SelectOptionsWrapper } from "./style";

import LabeledInput, { LabelInputProps } from "../LabeledInput";

export interface SelectInputOption {
	value: string;
	label: string;
	flag?: string;
	icon?: JSX.Element;
	image?: { mediaId: string; url: string };
}
interface Props extends LabelInputProps {
	options: SelectInputOption[];
	maxHeight?: number;
	handleSelectWillOpen?: (ref: React.RefObject<HTMLDivElement>) => void;
	loadSuggestions?: (value: string) => void;
	noOptionsHeadline?: string;
	allowCreatingSuggestion?: SuggestionTypes;
	customHeight?: string;
	minWidth?: number;
	selectInputRef?: React.MutableRefObject<HTMLInputElement>;
	renderOption?: () => JSX.Element;
	autoCloseWhenNoOption?: boolean;
	labelClassName?: string;
	rightIconColor?: string;
	isBackgroundGray?: boolean;
	numbersOnly?: boolean;
}

const SelectInput: FC<Props> = ({
	onChange,
	type = "text",
	options,
	maxHeight,
	handleSelectWillOpen,
	rightIcon,
	displayOnly,
	loadSuggestions,
	noOptionsHeadline,
	allowCreatingSuggestion,
	autoResize,
	customHeight,
	minWidth,
	selectInputRef,
	renderOption,
	autoCloseWhenNoOption = false,
	value,
	labelClassName,
	rightIconColor,
	isBackgroundGray,
	numbersOnly,
	...props
}) => {
	const [showSelect, setShowSelect] = useState(false);
	const [inputValue, setInputValue] = useState("");
	const [optionIcon, setOptionIcon] = useState<JSX.Element>();
	const { createNewSuggestion, getData: getUserData } = useUser();
	const { loading } = getUserData();

	const selectRef = useRef<HTMLDivElement>(null);
	const resizeInputRef = useRef<HTMLInputElement>(null);
	const scrollToXPositionOnFocus = useScrollToXPosition(selectInputRef?.current?.form?.parentElement);

	useEffect(() => {
		if (autoResize && resizeInputRef.current && value) {
			updateElSize(resizeInputRef.current, value, value.length > 20 ? -30 : 80);
		}
	}, [autoResize, value]);

	const handleClick = useCallback(() => {
		setShowSelect(show => !show);
		if (handleSelectWillOpen) {
			handleSelectWillOpen(selectRef);
		}
	}, [handleSelectWillOpen]);

	const handleSelectOption = useCallback(
		option => {
			onChange(option);
			setInputValue("");
			setShowSelect(false);
			option?.icon && setOptionIcon(option.icon);
		},
		[onChange]
	);

	const handleSelectNewOption = useCallback(
		async (value: string) => {
			if (!allowCreatingSuggestion) return;
			const { _id, label } = await createNewSuggestion(allowCreatingSuggestion, value);
			handleSelectOption({ value: _id, label });
		},
		[allowCreatingSuggestion, createNewSuggestion, handleSelectOption]
	);

	useEffect(() => {
		if (autoCloseWhenNoOption && inputValue) {
			setShowSelect(!!options.length);
		}
	}, [options.length, autoCloseWhenNoOption, inputValue]);

	const Row = ({ data, index, style }) => {
		const option = data[index] as SelectInputOption;
		return (
			<Box style={style}>
				<SelectOptionsWrapper.Option
					inputRef={resizeInputRef}
					className={`${props.narrow ? "narrow" : ""}`}
					onClick={() => handleSelectOption(option)}
					key={option.value}
					id={`${props?.id || ""}_${index + 1}`}
				>
					{option.flag && <SelectOptionsWrapper.WithFlag dangerouslySetInnerHTML={{ __html: option.flag }} />}
					{option.icon}
					{option.image && (
						<img className="w-6 h-6 rounded-full object-cover" src={option.image.url} alt={option.label} />
					)}
					{option.label}
				</SelectOptionsWrapper.Option>
			</Box>
		);
	};

	return (
		<ClickAwayListener onClickAway={() => setShowSelect(false)}>
			<div ref={resizeInputRef}>
				<Box
					position="relative"
					onKeyDown={e => displayOnly && (e.code === "Enter" || e.code === "ArrowDown") && setShowSelect(true)}
					onClick={scrollToXPositionOnFocus}
				>
					<LabeledInput
						type={type}
						customHeight={customHeight}
						onChange={
							loadSuggestions
								? e => {
										if (e) {
											loadSuggestions(e.target.value);
											setInputValue(e.target.value);
										}
										onChange(e);
								  }
								: onChange
						}
						leftIcon={optionIcon}
						rightIcon={
							rightIcon
								? { el: rightIcon.el, active: showSelect }
								: {
										el: <ChevIcon width={16} height={16} />,
										active: showSelect
								  }
						}
						displayOnly={displayOnly}
						activeState={showSelect}
						handleClick={handleClick}
						inputRef={selectInputRef}
						renderOption={renderOption}
						value={value}
						className={clsx("truncate", labelClassName)}
						rightIconColor={rightIconColor}
						isBackgroundGray={isBackgroundGray}
						numbersOnly={numbersOnly}
						{...props}
					/>
					<SelectOptionsWrapper
						minWidth={minWidth}
						show={showSelect}
						ref={selectRef}
						maxHeight={maxHeight}
						className="selectionBox"
					>
						{!!options.length ? (
							<OptionList
								height={maxHeight && options.length * 40 > maxHeight ? maxHeight - 20 : options.length * 40}
								itemSize={40}
								itemCount={options.length}
								itemData={options}
								outerElementType={Scrollbars}
							>
								{Row}
							</OptionList>
						) : !loading && inputValue && allowCreatingSuggestion ? (
							<SelectOptionsWrapper.Option onClick={() => handleSelectNewOption(inputValue)}>
								{inputValue}
							</SelectOptionsWrapper.Option>
						) : (
							<SelectOptionsWrapper.Headline>{noOptionsHeadline || "No Options"}</SelectOptionsWrapper.Headline>
						)}
					</SelectOptionsWrapper>
				</Box>
			</div>
		</ClickAwayListener>
	);
};

export default SelectInput;
