import React, { useMemo } from "react";

import Switch, { SwitchProps } from "@material-ui/core/Switch";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";

import styled, { css } from "styled-components";

import { useCommunity } from "shared/hooks";

const VyooSwitch = styled(Switch)`
	${props =>
		css`
			.Mui-checked span span {
				background-color: ${props.primaryColor};
			}
		`}
`;

interface Props extends SwitchProps {}

const IOSSwitch = ({ ...props }: Props) => {
	const { communityColors } = useCommunity();

	const color = useMemo(() => communityColors.primary || "#4350f7", [communityColors.primary]);

	const styles = (theme: Theme) =>
		createStyles({
			root: {
				width: 42,
				height: 26,
				padding: 0,
				margin: theme.spacing(1)
			},
			switchBase: {
				padding: 1,
				"&$checked": {
					transform: "translateX(16px)",
					color: theme.palette.common.white,
					"& + $track": {
						backgroundColor: "#fff",
						opacity: 1,
						border: `1px solid ${color}`
					}
				}
			},
			thumb: {
				width: 20,
				height: 20,
				backgroundColor: "#8f9bb3",
				marginTop: 2,
				marginLeft: 2,
				boxShadow: "none"
			},
			track: {
				borderRadius: 26 / 2,
				border: "1px solid #8f9bb3",
				backgroundColor: "#ffffff",
				opacity: 1,
				transition: theme.transitions.create(["background-color", "border"])
			},
			checked: {},
			focusVisible: {}
		});

	const useStyles = makeStyles(styles);
	const { checked, track, thumb, switchBase, root, focusVisible } = useStyles();

	return (
		<VyooSwitch
			focusVisibleClassName={focusVisible}
			disableRipple
			checked={props.checked}
			primaryColor={color}
			classes={{
				root: root,
				switchBase: switchBase,
				thumb: thumb,
				track: track,
				checked: checked
			}}
			{...props}
		/>
	);
};

export default IOSSwitch;
