import React, { memo, useCallback, useEffect, useState } from "react";

import { Link } from "react-router-dom";
import { ForumTagType } from "types";

import { useCommunity, useMemberRoutes, usePost } from "shared/hooks";

import { Item, ListWrapper } from "./style";

import { BoxItem } from "../index";

interface Props {
	hashtagsUrl: string;
	ignoreHashtag?: string;
}

const Hashtags: React.FC<Props> = memo(({ ignoreHashtag }) => {
	const { loadHashtags } = usePost();

	const { getData: getCommunityData } = useCommunity();
	const { workspace } = getCommunityData();
	const [tagList, setTagList] = useState<ForumTagType[]>([]);

	const { getMemberRoutesData } = useMemberRoutes();
	const { routes } = getMemberRoutesData();

	const fetchHashtags = useCallback(async () => {
		const tags = await loadHashtags("", 40);
		setTagList(tags);
	}, [loadHashtags]);

	useEffect(() => {
		fetchHashtags();
	}, [fetchHashtags, workspace]);

	return (
		<BoxItem title={"Popular hashtags"}>
			<ListWrapper>
				{tagList
					.slice(0, 20)
					.filter(tag => tag.value !== ignoreHashtag)
					.map((tag, index) => (
						<Item.Link component={Link} to={`${routes?.member.hashtag.getPath()}/${tag.value}`} key={index}>
							<Item.Title>#{tag.value}</Item.Title>
						</Item.Link>
					))}
			</ListWrapper>
			{/*{tagList.length > 20 && <SeeAllBtn click={() => history.push(hashtagsUrl)} />}*/}
		</BoxItem>
	);
});

export default Hashtags;
