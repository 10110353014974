import { RefObject, useEffect, useState } from "react";

const useOnScreen = (ref: RefObject<HTMLDivElement> | undefined) => {
	const [isIntersecting, setIntersecting] = useState(false);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const observer = new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting));

	useEffect(() => {
		if (ref?.current) {
			observer.observe(ref.current);
		}
		return () => {
			observer.disconnect();
		};
	}, [observer, ref]);

	return isIntersecting;
};

export default useOnScreen;
