import config from "config/appConfig";
import { BoardContextValuesType } from "types";

import { IStateProps } from "utils/StateOf";

import createStore from "utils/createStore";

const localStorageKey = `${config.GLOBAL_CONSTANTS.LOCAL_STORAGE_PREFIX}${config.HOOK_STORAGE_KEYS.BOARD_DATA}`;

export const initialState: IStateProps<BoardContextValuesType> = {
	boardsPerPage: 20,
	page: 1,
	isLoading: false
};

export default createStore<BoardContextValuesType>({
	initialState,
	persistant: true,
	persistantName: localStorageKey
});
