import { useCallback, useMemo } from "react";

import config from "config/appConfig";
import firebase from "firebase/app";

import * as R from "ramda";

import { User } from "react-gifted-chat";

import { CommunityModel } from "types";

import { useCommunity, useFeature, useNotification, useUser } from "shared/hooks";
import { useMembersListApiService } from "shared/services";
import { getGroupChatMembersDataType } from "shared/types";

import { getLastMessage } from "utils/getLastMessage";

import { ChatMessage, ChatType, InboxItem } from "./../types";

import { LastMessageActions } from "../../View/Components/Inbox";
import { useMessagingStore } from "../contexts/MessagingContext";

import useMessagingApiService from "../services/useMessagingApiService";

const {
	VYOO_DOMAIN,
	MAKEEN_COMMUNITY_DOMAIN,
	HOLLIBLU_DOMAIN,
	VYOO_UNIVERSITY_DOMAIN,
	KIJIJI_DOMAIN,

	FIRBASE_APP_ID,
	FIRBASE_AUTH_DOMAIN,
	FIRBASE_PROJECT_ID,
	FIRBASE_STORAGE_BUCKET,

	VYOOUNIVERSITY_FIRBASE_APP_ID,
	VYOOUNIVERSITY_FIRBASE_AUTH_DOMAIN,
	VYOOUNIVERSITY_FIRBASE_PROJECT_ID,
	VYOOUNIVERSITY_FIRBASE_STORAGE_BUCKET,

	HOLLIBLU_FIRBASE_APP_ID,
	HOLLIBLU_FIRBASE_AUTH_DOMAIN,
	HOLLIBLU_FIRBASE_PROJECT_ID,
	HOLLIBLU_FIRBASE_STORAGE_BUCKET,

	KIJIJI_FIRBASE_APP_ID,
	KIJIJI_FIRBASE_AUTH_DOMAIN,
	KIJIJI_FIRBASE_PROJECT_ID,
	KIJIJI_FIRBASE_STORAGE_BUCKET
} = config.GLOBAL_CONSTANTS;

const pageSize = 20;

let _authorising = false;

const useFirebase = () => {
	const { getActiveProfile, getProfilePicture, getUserFirebaseToken, getData: getUserData } = useUser();
	const { user } = getUserData();

	const { getData: getCommunityData } = useCommunity();
	const { workspace } = getCommunityData();

	const { isFeatureEnabled } = useFeature();

	const { setState } = useMessagingStore();

	const { getGroupChatMembers } = useMembersListApiService();
	const { createMessage, readConversation } = useMessagingApiService();
	const { notifyAllUsers } = useNotification();

	const activeProfile = useMemo(() => getActiveProfile(user), [getActiveProfile, user]);

	const hasDedicatedFirebase = useMemo(() => isFeatureEnabled("hasDedicatedFirebase"), [isFeatureEnabled]);

	const communityMail = useCallback((personaId, workspace) => {
		switch (workspace) {
			// we should use standart flow to prevent issues with "attempt too many requests" or incorrect pass
			// case "vyoo":
			// 	return `${personaId}@${VYOO_DOMAIN}`;
			case "vyoouniversity":
				return `${personaId}@${VYOO_UNIVERSITY_DOMAIN}`;
			case "holliblu":
				return `${personaId}@${HOLLIBLU_DOMAIN}`;
			case "kijiji":
			case "kijijimoms":
				return `${personaId}@${KIJIJI_DOMAIN}`;
			case "makeencommunity":
				return `${personaId}@${MAKEEN_COMMUNITY_DOMAIN}`;
			default:
				return `${personaId}_${workspace}@${VYOO_DOMAIN}`;
		}
	}, []);

	const firebaseOptions = useCallback((workspaceName: string) => {
		switch (workspaceName) {
			case "vyoouniversity":
				return {
					appId: VYOOUNIVERSITY_FIRBASE_APP_ID,
					projectId: VYOOUNIVERSITY_FIRBASE_PROJECT_ID,
					storageBucket: VYOOUNIVERSITY_FIRBASE_STORAGE_BUCKET,
					authDomain: VYOOUNIVERSITY_FIRBASE_AUTH_DOMAIN
				};
			case "holliblu":
				return {
					appId: HOLLIBLU_FIRBASE_APP_ID,
					projectId: HOLLIBLU_FIRBASE_PROJECT_ID,
					storageBucket: HOLLIBLU_FIRBASE_STORAGE_BUCKET,
					authDomain: HOLLIBLU_FIRBASE_AUTH_DOMAIN
				};
			case "kijiji":
			case "kijijimoms":
				return {
					appId: KIJIJI_FIRBASE_APP_ID,
					projectId: KIJIJI_FIRBASE_PROJECT_ID,
					storageBucket: KIJIJI_FIRBASE_STORAGE_BUCKET,
					authDomain: KIJIJI_FIRBASE_AUTH_DOMAIN
				};
			default:
				return {
					appId: FIRBASE_APP_ID,
					projectId: FIRBASE_PROJECT_ID,
					storageBucket: FIRBASE_STORAGE_BUCKET,
					authDomain: FIRBASE_AUTH_DOMAIN
				};
		}
	}, []);

	const generateChatId = useCallback(
		(friendId, isGroupChat) => {
			if (isGroupChat) return friendId;
			if (!activeProfile?.chatUserId) return null;
			const userId = activeProfile?.chatUserId || "";
			if (userId > friendId) {
				return `${userId}-${friendId}`;
			}
			return `${friendId}-${userId}`;
		},
		[activeProfile?.chatUserId]
	);

	const isGroupConversation = useCallback((type: ChatType) => {
		return [ChatType.GROUP, ChatType.FORUM_GROUP_CHAT, ChatType.EVENT_CHAT, ChatType.COMMUNITY_CHAT].includes(type);
	}, []);

	const getDatabaseRef = useCallback((refValue?: string): firebase.database.Reference => {
		return refValue ? firebase.database().ref(refValue) : firebase.database().ref();
	}, []);

	const chatRef = useCallback(
		(chat: InboxItem) => {
			return getDatabaseRef(hasDedicatedFirebase ? undefined : workspace?.name).child(
				`chat/${generateChatId(chat._id || chat.chatUserId, isGroupConversation(chat.type))}`
			);
		},
		[getDatabaseRef, workspace?.name, generateChatId, isGroupConversation, hasDedicatedFirebase]
	);

	const chatTypingRef = useCallback(
		chat => getDatabaseRef().child(`Typingchat/${chat.chatUserId || chat._id}`),
		[getDatabaseRef]
	);

	const subscribeTypingMessages = useCallback(
		chat => {
			return chatTypingRef(chat).orderByChild("order");
		},
		[chatTypingRef]
	);

	const subscribeMessages = useCallback(
		chat => {
			return chatRef(chat).orderByChild("order");
		},
		[chatRef]
	);

	const getChatMembers = useCallback(connection => {
		return connection.type === ChatType.GROUP
			? {
					initiatorId: connection.initiatorId,
					name: connection.name,
					photo: connection.photo,
					type: connection.type,
					_id: connection._id,
					open: connection.open,
					members: connection?.members.map(member => ({
						chatUserId: member.chatUserId,
						firstName: member.firstName,
						lastName: member.lastName,
						isMember: member.isMember,
						isOwner: member.isOwner,
						photos: member.photos?.map(photo => ({
							profilePicture: photo.profilePicture
						}))
					}))
			  }
			: {
					personaId: connection.personaId,
					active: connection.active,
					bbToken: connection.bbToken,
					bbUserId: connection.bbUserId,
					bbUsername: connection.bbUsername,
					chatUserId: connection.chatUserId,
					notify: connection.notify,
					vyooable: connection.vyooable,
					firstName: connection.firstName,
					lastName: connection.lastName
			  };
	}, []);

	const profilePicture = useMemo(() => getProfilePicture(user), [getProfilePicture, user]);

	const prepareMessage = useCallback(
		(message: Partial<ChatMessage>) => {
			const now = new Date().getTime();

			const lastMessage = getLastMessage(message);

			const msg: Partial<ChatMessage> = {
				_id: message._id || now,
				text: message.text || "",
				createdAt: now,
				uid: message.reacted ? message.uid : activeProfile?.chatUserId,
				order: -1 * (message?._id || now),
				lastMessage
			};

			msg.user = message.reacted
				? message.user
				: {
						_id: activeProfile?.chatUserId,
						name: activeProfile?.firstName,
						avatar: profilePicture
				  };

			if (message?.edited && typeof message?.edited !== "boolean") {
				msg.edited = message.edited;

				msg._id = message.edited._id;
				msg.createdAt = message.edited.createdAt;
				msg.order = message.edited.order;
			}

			if (message.images) {
				msg.images = Array.isArray(message.images) ? message.images : [message.images];
			}

			if (message.audios) {
				msg.audios = Array.isArray(message.audios) ? message.audios : [message.audios];
			}

			if (message.videos) {
				msg.videos = Array.isArray(message.videos) ? message.videos : [message.videos];
			}

			if (message?.files) {
				msg.files = Array.isArray(message.files) ? message.files : [message.files];
			}

			if (message?.group) {
				msg.group = message?.group;
			}

			if (message?.place) {
				msg.place = message?.place;
			}

			if (message?.track) {
				msg.track = message?.track;
			}

			if (message?.payreq) {
				msg.payreq = message?.payreq;
			}

			if (message?.reply) {
				msg.reply = message.reply;
			}

			if (message.location) {
				msg.location = message.location;
			}

			if (message.position) {
				msg.position = message.position;
			}

			if (message.disconnect) {
				msg.disconnect = message.disconnect;
			}

			if (message.topic) {
				msg.topic = message.topic;
			}

			if (message.profile) {
				msg.profile = message.profile;
			}

			if (message.gif) {
				msg.gif = message.gif;
			}

			if (message.recentLikes) {
				msg.recentLikes = message.recentLikes;
			}

			if (message.storyReply) {
				msg.storyReply = message.storyReply;
			}

			if (message.carpool) {
				msg.carpool = message.carpool;
			}

			if (message.canceledCarpool) {
				msg.canceledCarpool = message.canceledCarpool;
			}

			// if (message.album) {
			// 	msg.album = message.album;
			// }

			return msg;
		},
		[activeProfile?.chatUserId, activeProfile?.firstName, profilePicture]
	);

	const notifyUsers = useCallback(
		(text: string, chat: InboxItem) => {
			// This bloack will not execute for the enterprice clients. This block will send notification for the apps which support multitenancy.
			if (!workspace?.whiteLabelApp) {
				const nParams = {
					activeProfile: chat?.purpose,
					connection: getChatMembers(chat),
					workspaceid: workspace?._id,
					workspace: workspace?.name
				};

				const getMembersNotificationInfo = (members, userPersonaId) => {
					const tokens = {};

					members
						.filter(member => member.personaId !== userPersonaId && (member.notifyToken || member.notificationEnabed))
						.forEach(member => {
							tokens[member.personaId] = member.notifyToken;
						});

					return tokens;
				};

				const token =
					chat.type === ChatType.GROUP
						? Object.values(getMembersNotificationInfo(chat?.members, activeProfile?.personaId))
						: chat.notifyToken;

				const nObj = {
					type: chat?.type,
					local: false,
					senderId: `${activeProfile?.personaId}`,
					icon: "ic_calendar",
					color: "#6173fe",
					title: activeProfile?.firstName,
					name: activeProfile?.firstName,
					message: text,
					dismiss: "5",
					route: "ChatScreen",
					params: nParams,
					sound: "default",
					body: text
				};

				notifyAllUsers({
					notification: nObj, // Notification Object to be sent to the user
					chatMsg: {}, // This is not required as the message is stored in next step from mobile itself.
					recipientTokens: [], // This is not requierd as we are not storing any value from firebase function.
					recipientIds: [], // This is also not required
					notifyTokens: typeof token === "object" ? token : [token] // This is required to send push notification to the user.
				});
			}
		},
		[
			notifyAllUsers,
			workspace?.whiteLabelApp,
			workspace?._id,
			workspace?.name,
			activeProfile?.personaId,
			activeProfile?.firstName,
			getChatMembers
		]
	);

	const methods = useMemo(
		() => ({
			updateNewMessage: (msg: Partial<ChatMessage>, reacted?: boolean) => {
				setState(ctx => {
					const currentMsgs = ctx.messages;
					if (reacted) {
						const index = ctx.messages.findIndex(item => item._id === msg._id);
						if (index > -1) {
							currentMsgs[index] = msg as ChatMessage;
						}
					} else if (msg?.edited?._id) {
						const index = ctx.messages.findIndex(item => item._id === msg?.edited?._id);
						if (index > -1) {
							currentMsgs[index] = msg as ChatMessage;
						}
					} else {
						currentMsgs.unshift(msg as ChatMessage);
					}

					return {
						...ctx,
						messages: currentMsgs
					};
				});
			},
			setCurrentChat: async (currentChat?: InboxItem, onRead?: () => void) => {
				setState({ currentChat, activePanel: "chat" });
				if (currentChat?.connectionId && currentChat.unReadCount) {
					try {
						await readConversation(currentChat.connectionId, false);
						onRead && onRead();
					} catch {}
				}
			},
			addToMessages: newMessage => setState(ctx => ({ ...ctx, messages: [...ctx.messages, newMessage] })),
			addToGroupMembers: data => setState(ctx => ({ ...ctx, groupMembers: { ...ctx.groupMembers, [data._id]: data } })),
			clearMessages: () => setState(ctx => ({ ...ctx, messages: [], loadingMessages: true })),
			clearChat: () => setState(ctx => ({ ...ctx, messages: [], currentChat: undefined })),
			onSendLocal: ({ messages }: { messages: Partial<ChatMessage>[] }) => {
				messages.forEach(message => {
					const msg = prepareMessage(message);
					methods.updateNewMessage(msg);
				});
			},
			onSend: ({ messages, chat }: { messages: Partial<ChatMessage>[]; chat: InboxItem }) => {
				messages.forEach(message => {
					const msg = prepareMessage(message);
					methods.updateNewMessage(msg, message.reacted);

					if (message?.edited || message.reacted) {
						chatRef(chat)
							.orderByChild("_id")
							.equalTo(message.reacted ? msg._id : message.edited?._id || "")
							.once("value")
							.then(snapshot => {
								snapshot.forEach(childSnapshot => {
									childSnapshot.ref.update({
										...msg,
										edited: true
									});
								});
							});
						msg.edited = message.edited;
					} else {
						chatRef(chat).push(msg, error => {
							if (error) {
								console.log("SEND MESSAGE ERROR: ", error);
							} else {
								createMessage({
									senderId: activeProfile?.personaId,
									recipientId: chat.personaId || null,
									groupChatId: chat._id,
									timestamp: new Date(),
									lastMessage: msg.lastMessage || ""
								});
							}
						});
					}

					notifyUsers(msg?.text || "", chat);
				});
			},
			notifyUserStartTyping: chat => {
				const now = new Date().getTime();

				const msg = {
					_id: now,
					type: chat?.type,
					createdAt: now,
					senderId: activeProfile?.personaId,
					senderName: activeProfile?.firstName,
					chatId: chat?.chatUserId || chat._id
				};

				msg.chatId && chatTypingRef(chat).push(msg);
			},
			notifyUserStopTyping: chat => chat && chatTypingRef(chat).remove(),
			deleteChatMessage: (chat, msg) => {
				chatRef(chat)
					.orderByChild("_id")
					.equalTo(msg._id)
					.once("value")
					.then(snapshot => {
						snapshot.forEach(childSnapshot => {
							childSnapshot.ref.remove();
						});
					});
				setState(ctx => {
					return {
						...ctx,
						messages: ctx.messages.filter(message => message._id !== msg._id)
					};
				});
			},
			listenForTyping: (startAt, chat) => {
				const ref = subscribeTypingMessages(chat);
				ref.endAt(startAt).on("value", async snap => {
					setState(ctx => {
						return {
							...ctx,
							typingChat: snap.val()
						};
					});
				});
			},
			setLoadingMessages: (data: boolean) => setState(ctx => ({ ...ctx, loadingMessages: data })),
			loadChatMessages: (startAt, deletedConversation, chat) => {
				if (!chat) return;

				const isGroup = !!chat._id;
				let ref = subscribeMessages(chat);
				// when user removed from the group
				if (startAt && chat.removedAt) ref = ref.startAt(startAt);
				// else load next page
				else if (startAt) {
					ref = deletedConversation ? ref.startAt(startAt) : ref.startAt(startAt);
				}
				ref.limitToFirst(pageSize).once("value", async snap => {
					const messagesCount = snap.numChildren();
					let messages: ChatMessage[] = [];

					if (messagesCount) {
						messages = Object.values(snap.val()) as ChatMessage[];
						if (isGroup) {
							await methods.getCurrentGroupMembers({
								groupChatId: chat._id,
								chatUserIds: messages.filter(r => !!r.uid).map(r => r.uid || "")
							});
						}
						messages = messages.sort((a, b) => a.order - b.order);

						setState(ctx => {
							const chatMessages: ChatMessage[] = messages.map(r => ({
								...r,
								user: (ctx.groupMembers[r.uid || ""] as User) || { _id: r.uid, name: chat.firstName }
							}));
							if (ctx.currentChat !== chat) return ctx;

							const filteredMessages = R.uniqBy(({ _id }) => _id, [...ctx.messages, ...chatMessages]);

							if (filteredMessages.length) {
								if (filteredMessages.length > 10) {
									filteredMessages[filteredMessages.length - 10].observeMessage = true;
								} else if (filteredMessages.length > 5) {
									filteredMessages[filteredMessages.length - 5].observeMessage = true;
								} else {
									filteredMessages[filteredMessages.length - 1].observeMessage = true;
								}
							}

							return {
								...ctx,
								messages: filteredMessages
							};
						});
					}

					if (isGroup && ~[ChatType.EVENT_CHAT, ChatType.FORUM_GROUP_CHAT].indexOf(chat.type) && chat.open) {
						const timeInMS = new Date().getTime();
						// let text = "You have left the group chat.";
						let text = "";
						if (chat.meta && !chat.meta.going) {
							text =
								chat.type === ChatType.EVENT_CHAT
									? "You’re no longer a part of this event.\nOnly users who are going/interested in this\nevent will be able to send or recieve\nmessages in this event group chat."
									: "You’re no longer a member of this group.\nYou need to rejoin the group in order to send\nor recieve messages in this group chat.";
						}
						setState(ctx => ({
							...ctx,
							messages: [
								{
									_id: timeInMS,
									order: -timeInMS,
									text,
									system: true
								} as ChatMessage,
								...ctx.messages
							]
						}));
					}
					methods.setLoadingMessages(false);
				});
			},
			listenForMessages: (startAt, chat) => {
				const isGroup = !!chat._id;
				const ref = subscribeMessages(chat);
				ref.on("child_changed", async snap => {
					const updatedMessage = snap.val() as ChatMessage;
					if (updatedMessage && updatedMessage.user._id !== activeProfile?.chatUserId) {
						setState(ctx => {
							if (ctx.currentChat !== chat) return ctx;

							const index = ctx.messages.findIndex(x => x._id === updatedMessage._id);
							if (index > -1) {
								ctx.messages[index] = updatedMessage;
								return {
									...ctx,
									messages: ctx.messages
								};
							}
							return ctx;
						});
					}
				});

				ref.on("child_removed", async snap => {
					const removedMessage = snap.val() as ChatMessage;
					if (removedMessage && removedMessage.user._id !== activeProfile?.chatUserId) {
						setState(ctx => {
							if (ctx.currentChat !== chat) return ctx;

							return {
								...ctx,
								messages: ctx.messages.filter(x => x._id !== removedMessage._id)
							};
						});
					}
				});

				ref.endAt(startAt).on("value", async snap => {
					const vals = snap.val();
					if (vals) {
						const messages = Object.values(vals) as ChatMessage[];
						if (isGroup) {
							await methods.getCurrentGroupMembers({
								groupChatId: chat._id,
								chatUserIds: messages.filter(r => !!r.uid).map(r => r.uid || "")
							});
						}

						setState(ctx => {
							const filteredMessages = R.uniqBy(
								({ _id }) => _id,
								messages
									.map(r => ({
										...r,
										user: (ctx.groupMembers[r.uid || ""] as User) || { _id: r.uid }
									}))
									.reverse()
									.concat(ctx.messages)
							);

							if (ctx.currentChat !== chat) return ctx;
							return {
								...ctx,
								messages: filteredMessages
							};
						});
					}
				});
			},
			listenForIboxMessages: (
				startAt: number,
				chat: InboxItem,
				after: (messages: ChatMessage, action: LastMessageActions) => void
			) => {
				const ref = subscribeMessages(chat);
				ref.endAt(startAt).on("value", async snap => {
					const vals = snap.val();
					if (vals) {
						const messages = Object.values(vals) as ChatMessage[];
						messages.length && after(messages[messages.length - 1], LastMessageActions.create);
					}
				});

				ref.endAt(startAt).on("child_changed", async snap => {
					const updatedMessage = snap.val() as ChatMessage;
					if (updatedMessage) {
						after(updatedMessage, LastMessageActions.update);
					}
				});

				ref.endAt(startAt).on("child_removed", async snap => {
					const removedMessage = snap.val() as ChatMessage;
					if (removedMessage) {
						after(removedMessage, LastMessageActions.remove);
					}
				});
			},
			getCurrentGroupMembers: async (data: getGroupChatMembersDataType) => {
				try {
					const groupMembers = data.chatUserIds.length ? await getGroupChatMembers(data) : [];
					const membersById = {};
					groupMembers.forEach(r => {
						membersById[r.chatUserId || "_"] = {
							_id: r.chatUserId,
							name: r.firstName,
							avatar: (r.photos?.length && r.photos[0].profilePicture) || ""
						};
					});
					setState(ctx => {
						const newMembers = { ...ctx.groupMembers, ...membersById };
						return {
							...ctx,
							groupMembers: newMembers
						};
					});
				} catch (error) {
					return {};
				}
			}
		}),
		[
			activeProfile?.personaId,
			activeProfile?.firstName,
			activeProfile?.chatUserId,
			setState,
			getGroupChatMembers,
			createMessage,
			chatRef,
			chatTypingRef,
			subscribeMessages,
			subscribeTypingMessages,
			prepareMessage,
			notifyUsers,
			readConversation
		]
	);

	const initialiseFirebase = useCallback(
		(data: Partial<CommunityModel>) => {
			if (firebase.apps.length === 0) {
				firebase.initializeApp({
					databaseURL: data.firebaseDbUrl,
					apiKey: data.firebaseWebApiKey,
					...firebaseOptions(data.name!)
				});
			}
		},
		[firebaseOptions]
	);

	const authenticateUser = useCallback(
		async (
			personaId: number,
			data: Partial<CommunityModel>,
			existing?: boolean
		): Promise<{ user: firebase.User | null }> => {
			const email = communityMail(personaId, data.name);

			initialiseFirebase(data);
			const auth = firebase.auth();

			if (_authorising) {
				return new Promise((resolve, reject) => {
					auth.onAuthStateChanged(user => {
						if (user && email === user.email) {
							resolve({
								user
							});
							// User is signed in.
						} else {
							reject("LOGGED OUT");
						}
					});
				});
			}

			_authorising = true;

			if (auth.currentUser) {
				if (auth.currentUser?.email !== email) {
					await auth.signOut();
				} else {
					_authorising = false;
					return {
						user: auth.currentUser
					};
				}
			}

			const { signature: generatedUserToken } = await getUserFirebaseToken(personaId);

			const checkEmailExists = () => {
				return auth
					.fetchSignInMethodsForEmail(email)
					.then(res => res)
					.catch(err => err);
			};

			const signInUser = () => auth.signInWithEmailAndPassword(email, generatedUserToken);

			if (existing) {
				const data = await signInUser();

				_authorising = false;

				return {
					user: data.user
				};
			} else {
				const data = await checkEmailExists()
					.then(() => signInUser())
					.catch(() => auth.createUserWithEmailAndPassword(email, generatedUserToken));

				_authorising = false;

				return {
					user: data.user
				};
			}
		},
		[initialiseFirebase, communityMail, getUserFirebaseToken]
	);

	return { ...methods, authenticateUser, initialiseFirebase };
};

export default useFirebase;
