import { EventFormData } from "shared/types/EventModel";

import { getUserTimezone } from "utils/serviceUtils/helpers";

export const generateFakeLiveEvent = (persona): EventFormData => {
	const currentDate = new Date();

	return {
		title: `${persona.firstName}'s Live`,
		conversationEnabled: true,
		allowInvites: true,
		allowRaiseHand: true,
		eventImage: "",
		startTime: currentDate,
		endTime: new Date(currentDate.getTime() + 60 * 60 * 24 * 1000), // + 24 hour
		description: "Quick live",
		ticketUrl: "",
		worldwide: true,
		attendeesNumber: 500,
		chat: false,
		price: 0,
		priceTags: [],
		timezone: getUserTimezone(),
		isOnlineEvent: true,
		category: {
			label: "Quick live",
			value: "Quick live"
		},
		categoryId: "",
		liveconversation: true,
		isMemberView: true,
		link: ""
	};
};
