import React from "react";

import EventBlock from "shared/Components/Blocks/EventBlock";
import { RelativeWrapper } from "shared/styles";
import { EventMessageModel } from "shared/types/EventModel";

import Actions from "./Actions";

import Reactions from "./Reactions";

import { RenderActionType } from "..";

const PlaceBubble = props => (
	<RelativeWrapper data-message={props.currentMessage.place.id}>
		<EventBlock
			maxWidth
			event={props.currentMessage?.place as EventMessageModel}
			messageBlockCurrentUser={props.messageBlockCurrentUser}
		/>
		{props.currentMessage?.place.recentLikes && (
			<Reactions list={props.currentMessage?.place.recentLikes} isCurrentUser={props.isCurrentUser} />
		)}

		<Actions
			replyItem={{
				id: props.currentMessage?.place.id,
				text: "event"
			}}
			isCurrentUser={props.isCurrentUser}
			onDelete={props.onDelete}
			onEdit={props.onEdit}
			onForward={props.onForward}
			onReply={props.onReply}
			onLike={props.onLike}
			currentMessage={props.currentMessage}
			type={RenderActionType.EVENT}
		/>
	</RelativeWrapper>
);

export default PlaceBubble;
