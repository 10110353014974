import { Box } from "@material-ui/core";
import styled from "styled-components";

import { Text } from "shared/ui-kit";

export const Tier = styled(Box)``;

Tier.Block = styled(Box)``;

Tier.Title = styled(Text)`
	font-size: 12px;
	font-weight: 600;
	line-height: 1.33;
	letter-spacing: normal;
	color: #8f9bb3;
`;

Tier.RowWrapper = styled(Box)`
	display: flex;
	align-items: center;
	justify-content: space-between;

	width: 100%;
	height: 56px;

	&:not(:last-child) {
		margin-bottom: 8px;
	}

	&.header {
		height: 20px;
	}
`;

Tier.PlanNameWrapper = styled(Box)`
	width: calc(100% - 116px);
	height: 100%;

	display: flex;
	flex-direction: column;
	justify-content: center;

	border-radius: 8px;
	border: solid 1px #e4e9f2;

	padding: 7px 16px;

	background-color: #f7f9fc;

	&.active {
		border-color: #ffd044;
		background-color: rgba(255, 249, 217, 0.5);
	}

	&.header {
		justify-content: flex-start;
		border: none;
		background-color: transparent;
		padding: 0;
	}
`;

Tier.PlanName = styled(Text)`
	font-size: 15px;
	font-weight: normal;
	line-height: 1.33;
	color: #222b45;

	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
`;

Tier.PlanPrice = styled(Text)`
	font-size: 12px;
	font-weight: normal;
	line-height: 1.33;
	color: #8f9bb3;

	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
`;

Tier.PlanValueWrapper = styled(Box)`
	width: 100px;
	height: 100%;

	.MuiInputBase-root {
		width: 100%;
		height: 100%;

		border-radius: 8px;
	}

	.MuiSelect-root {
		display: flex;
		align-items: center;
	}

	fieldset {
		top: -5px;
	}

	&.active {
		fieldset {
			border-color: #ffd044;
		}
	}
`;

Tier.PriceOptionIcon = styled(Box)`
	display: flex;
	align-items: center;
	margin-left: 4px;
`;
