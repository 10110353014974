import React, { FC, useEffect, useMemo } from "react";

import { Box } from "@material-ui/core";
import { Controller, useForm } from "react-hook-form";

import { SuggestionTypes } from "shared/contexts/UserContext/UserContext";
import { months } from "shared/data/months";
import { useNotification, usePersona, useUser } from "shared/hooks";
import { ProfileType } from "shared/types";
import { ProfileEducationType } from "shared/types/UserModel";
import { getYearsList } from "utils/getYearsList";

import { EnableFeature, MemberDialog, SelectInput } from "../../shared";
import LabeledInput from "../../shared/LabeledInput";
import { InputWrapper } from "../../shared/style";
import { EditProfileWrapper } from "../EditProfileDialog/style";

const NewEducationDialog: FC<{ item?: ProfileEducationType | undefined }> = ({ item }) => {
	const {
		control,
		watch,
		formState: { isDirty, errors, isSubmitting },
		handleSubmit,
		setValue,
		getValues,
		setError
	} = useForm({ mode: "onChange" });

	const { searchSuggestions, setMyExperienceDialog, setNewEducationDialog, getData: getUserData } = useUser();
	const { schoolSuggestions, newEducationDialog, degreeSuggestions, specializationSuggestions } = getUserData();

	const { updatePersona, getData: getPersonaData } = usePersona();
	const { persona: profile } = getPersonaData();

	const { showMessage } = useNotification();
	const { startAt, endAt, currentPosition } = watch(["startAt", "endAt", "currentPosition"]);

	useEffect(() => {
		startAt && startAt > endAt && setValue("endAt", startAt);
	}, [endAt, setValue, startAt]);

	useEffect(() => {
		item && setValue("currentPosition", true);
	}, [item, setValue]);

	const handleDeleteEducation = () => {
		updatePersona(
			{
				...profile,
				education: profile?.education?.filter(
					e => e.degree !== item?.degree && e.school !== item?.school && e.startAt !== item?.startAt
				)
			},
			true
		);

		setNewEducationDialog();
		setMyExperienceDialog(true);
	};

	const onSubmit = async data => {
		const startAt = new Date().setFullYear(parseInt(data.startYear.value), parseInt(data.startMonth.value));
		const endAt = data.endYear
			? new Date().setFullYear(parseInt(data.endYear.value), parseInt(data.endMonth.value))
			: null;

		if (endAt && startAt > endAt) {
			setError("endYear", {
				message: "End date can't be before start date"
			});
			return;
		}

		const exp = {
			active: false,
			degree: {
				_id: data.degree.value,
				label: data.degree.label
			},
			description: data.description || "",
			endAt: currentPosition ? null : `${new Date(endAt || 0)}`,
			present: currentPosition,
			school: {
				_id: data.school.value,
				label: data.school.label
			},
			specialization: {
				_id: data.specialization.value,
				label: data.specialization.label
			},
			startAt: `${new Date(startAt)}`
		};

		if (!profile) return;

		const newPersona: ProfileType = {
			...profile,
			education: profile.education
				? item
					? profile.education.map(job =>
							job.specialization._id === item.specialization._id && job.school._id === item.school._id ? exp : job
					  )
					: [...profile.education, exp]
				: [exp]
		};

		await updatePersona(newPersona, true);

		showMessage("Successfully updated your volunteer experience");
		setNewEducationDialog();
		setMyExperienceDialog(true);
	};

	const years = useMemo(() => getYearsList().map(year => ({ label: year.toString(), value: year.toString() })), []);
	if (!profile) return null;

	return (
		<MemberDialog
			title="Education"
			open={newEducationDialog?.open || false}
			onClose={() => setNewEducationDialog()}
			confirmLeave={isDirty}
			goBack={() => {
				setNewEducationDialog();
				setMyExperienceDialog(true);
			}}
			customWidth={512}
			footerPrimary={{
				text: "Save changes",
				disabled: !isDirty || isSubmitting,
				loading: isSubmitting,
				onClick: handleSubmit(onSubmit)
			}}
			footerSecondary={item ? { text: "Delete", onClick: handleDeleteEducation } : undefined}
		>
			<form onSubmit={handleSubmit(onSubmit)}>
				<EditProfileWrapper>
					<Controller
						name="school"
						control={control}
						defaultValue={item && { value: item.school._id, label: item.school.label }}
						rules={{
							required: "School is required",
							validate: val => {
								if (!val.value || !val.label) {
									return "Please choose a school from the options";
								}
							}
						}}
						render={({ onChange, value, ref }) => (
							<InputWrapper>
								<SelectInput
									name="school"
									onChange={onChange}
									value={value ? value.label : null}
									placeholder=" "
									label="University or School *"
									loadSuggestions={val => searchSuggestions(SuggestionTypes.school, val)}
									maxHeight={260}
									error={errors?.school?.message}
									noOptionsHeadline="Type your school name ( ex: Yale )"
									allowCreatingSuggestion={SuggestionTypes.school}
									options={
										schoolSuggestions
											? schoolSuggestions.map(suggestion => ({
													value: suggestion._id,
													label: suggestion.label
											  }))
											: []
									}
									selectInputRef={ref}
								/>
							</InputWrapper>
						)}
					/>
					<Controller
						name="degree"
						control={control}
						defaultValue={item && { value: item.degree._id, label: item.degree.label }}
						rules={{
							required: "Degree is required",
							validate: val => {
								if (!val.value || !val.label) {
									return "Please choose a degree from the options";
								}
							}
						}}
						render={({ onChange, value, ref }) => (
							<InputWrapper>
								<SelectInput
									name="degree"
									onChange={onChange}
									value={value ? value.label : null}
									placeholder=" "
									label="Degree *"
									loadSuggestions={val => searchSuggestions(SuggestionTypes.degree, val)}
									maxHeight={260}
									error={errors?.degree?.message}
									noOptionsHeadline="Type your degree ( ex: M.SC. )"
									allowCreatingSuggestion={SuggestionTypes.degree}
									options={
										degreeSuggestions
											? degreeSuggestions.map(suggestion => ({
													value: suggestion._id,
													label: suggestion.label
											  }))
											: []
									}
									selectInputRef={ref}
								/>
							</InputWrapper>
						)}
					/>
					<Controller
						name="specialization"
						control={control}
						defaultValue={item && { value: item.specialization._id, label: item.specialization.label }}
						rules={{
							required: "Specialization is required",
							validate: val => {
								if (!val.value || !val.label) {
									return "Please choose a specialization from the options";
								}
							}
						}}
						render={({ onChange, value, ref }) => (
							<InputWrapper>
								<SelectInput
									name="specialization"
									onChange={onChange}
									value={value ? value.label : null}
									placeholder=" "
									label="Specialization *"
									loadSuggestions={val => searchSuggestions(SuggestionTypes.specialization, val)}
									maxHeight={260}
									error={errors?.specialization?.message}
									noOptionsHeadline="Type your Specialization ( ex: Computer Science )"
									allowCreatingSuggestion={SuggestionTypes.specialization}
									options={
										specializationSuggestions
											? specializationSuggestions.map(suggestion => ({
													value: suggestion._id,
													label: suggestion.label
											  }))
											: []
									}
									selectInputRef={ref}
								/>
							</InputWrapper>
						)}
					/>
					<Box className="two-inputs-row flex-end">
						<Controller
							name="startYear"
							control={control}
							rules={{
								required: "Start Year is required"
							}}
							defaultValue={years.find(y => y.value === `${new Date().getFullYear()}`)}
							render={({ onChange, value, ref }) => (
								<InputWrapper width="49%">
									<SelectInput
										label="Start date *"
										name="date"
										value={value && value.label}
										onChange={onChange}
										error={errors?.startYear?.message}
										placeholder="Year"
										options={years}
										maxHeight={180}
										selectInputRef={ref}
									/>
								</InputWrapper>
							)}
						/>
						<Controller
							name="startMonth"
							control={control}
							rules={{
								required: "Start month is required"
							}}
							defaultValue={months[0]}
							render={({ onChange, value, ref }) => (
								<InputWrapper width="49%">
									<SelectInput
										label=" "
										name="date"
										value={value && value.label}
										onChange={onChange}
										error={errors?.startAt?.message}
										placeholder="Month"
										options={months}
										maxHeight={180}
										selectInputRef={ref}
									/>
								</InputWrapper>
							)}
						/>
					</Box>
					{!currentPosition && (
						<Box className="two-inputs-row flex-end">
							<Controller
								name="endYear"
								control={control}
								rules={{
									required: "End Year is required",
									validate: ({ value }) => {
										const startVal = parseInt(getValues("startYear").value);
										if (startVal > parseInt(value)) return "End date can't be before start date";
										return undefined;
									}
								}}
								defaultValue={years.find(y => y.value === `${new Date().getFullYear()}`)}
								render={({ onChange, value, ref }) => (
									<InputWrapper width="49%">
										<SelectInput
											label="End date *"
											name="date"
											value={value && value.label}
											onChange={onChange}
											error={errors?.endYear?.message}
											placeholder="Year"
											options={years}
											maxHeight={180}
											selectInputRef={ref}
										/>
									</InputWrapper>
								)}
							/>
							<Controller
								name="endMonth"
								control={control}
								rules={{
									required: "End month is required"
								}}
								defaultValue={months[0]}
								render={({ onChange, value, ref }) => (
									<InputWrapper width="49%">
										<SelectInput
											label=" "
											name="date"
											value={value && value.label}
											onChange={onChange}
											error={errors?.startAt?.message}
											placeholder="Month"
											options={months}
											maxHeight={180}
											selectInputRef={ref}
										/>
									</InputWrapper>
								)}
							/>
						</Box>
					)}
					<Controller
						name="description"
						control={control}
						defaultValue={item?.description}
						render={({ onChange, value, ref }) => (
							<InputWrapper>
								<LabeledInput
									textarea
									name="description"
									onChange={onChange}
									placeholder=" "
									label="Description"
									value={value}
									error={errors?.description?.message}
									inputRef={ref}
								/>
							</InputWrapper>
						)}
					/>
					<Controller
						name="currentPosition"
						control={control}
						render={({ onChange, value }) => (
							<InputWrapper>
								<EnableFeature
									title="Present"
									description="I currently study here"
									onChange={onChange}
									value={value}
									verticalPadding
								/>
							</InputWrapper>
						)}
					/>
				</EditProfileWrapper>
			</form>
		</MemberDialog>
	);
};

export default NewEducationDialog;
