import React from "react";

import { useLocation } from "react-router";

import { view } from "modules/MemberHome";
import { FeatureFlags } from "shared/Components/MemberNavPanel/constants";
import useCheckIfEnabled from "shared/hooks/useCheckIfFlagEnabled";

const CollectionsPage = () => {
	useCheckIfEnabled(FeatureFlags.collections);

	const { search } = useLocation();
	const query = new URLSearchParams(search);
	const groupId = query.get("groupId") || undefined;

	return <view.Collections groupId={groupId} />;
};

export default CollectionsPage;
