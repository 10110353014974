import { Box } from "@material-ui/core";

import styled from "styled-components";

import { Text } from "shared/ui-kit";

export const PaginationWrapper = styled(Box)`
	display: flex;
	align-items: center;
	justify-content: space-between;

	width: 100%;

	${props => props.theme.breakpoints.down("sm")} {
		flex-direction: column;
	}

	.MuiPagination-root {
		.MuiPagination-ul {
			display: flex;
			align-items: center;

			${props => props.theme.breakpoints.down("sm")} {
				justify-content: center;
			}

			li {
				width: 32px;
				height: 32px;

				display: flex;
				align-items: center;

				&:not(:last-child) {
					margin-right: 5px;
				}

				&:first-child,
				&:last-child {
					.MuiPaginationItem-page {
						background-color: #f0f3fe;

						svg {
							font-size: 22px;

							path {
								fill: #6173fe;
							}
						}
					}
				}

				.MuiPaginationItem-page {
					font-size: 13px;
					font-weight: 600;
					font-stretch: normal;
					font-style: normal;
					line-height: 1.85;
					letter-spacing: normal;
					color: #222b45;

					border-radius: 4px;

					&.Mui-selected {
						background-color: #6173fe;
						color: #fff;
					}

					&.Mui-disabled {
						background-color: rgba(143, 155, 179, 0.16);

						svg {
							path {
								fill: #8f9bb3;
							}
						}
					}
				}
			}
		}
	}
`;

PaginationWrapper.PageInfoWrapper = styled(Box)`
	display: flex;
	align-items: center;
	justify-content: flex-end;

	.MuiInputBase-root {
		height: 32px;

		.MuiSelect-root {
			padding-top: 5px;
			padding-bottom: 5px;

			background-color: #f4f5f8;

			color: #6173fe;
		}

		fieldset {
			display: none;
		}
	}
`;

PaginationWrapper.Text = styled(Text)`
	font-size: 13px;
	font-weight: normal;
	line-height: 1.23;
	letter-spacing: normal;
	color: #222b45;

	margin-left: 16px;

	white-space: nowrap;
`;
