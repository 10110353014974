import React, { useCallback } from "react";

import Box from "@material-ui/core/Box";
import styled from "styled-components";

import { ReactComponent as CloseIcon } from "assets/icons/cancel.svg";

const Close = styled(p => (
	<Box {...p}>
		<CloseIcon />
	</Box>
))`
	width: 25px;
	height: 24px;

	color: white;

	margin: 0;

	cursor: pointer;

	position: absolute;

	top: 8px;
	right: 8px;

	z-index: 2;
`;

interface Props {
	onClick?: () => void;
	viewMode?: boolean;
}

const CloseBlock: React.FC<Props> = ({ onClick, viewMode = false }) => {
	const handleClick = useCallback(
		e => {
			e.stopPropagation();
			onClick && onClick();
		},
		[onClick]
	);

	return viewMode ? null : <Close onClick={handleClick} />;
};

export default CloseBlock;
