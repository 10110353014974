import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";

import { CircularProgress, useMediaQuery } from "@material-ui/core";

import config from "config/appConfig";
import { DateTime } from "luxon";

import { Controller, useForm, useWatch } from "react-hook-form";

import { useHistory } from "react-router-dom";

import { ReactComponent as DogIcon } from "assets/icons/animal-dog.svg";
import { ReactComponent as CameraFlipIcon } from "assets/icons/camera-flip.svg";
import { ReactComponent as VyooLogo } from "assets/icons/logo.svg";

import { useAuth } from "modules/App/Data/hooks";
import { FormBase } from "modules/MemberHome/View/Containers/FillProfile/style";
import { CircularUploader, SelectInput } from "modules/MemberHome/View/shared";
import { useCommunity, useMedia, usePersona, useRoutes } from "shared/hooks";
import { MediaModel, MediaType } from "shared/types";

import { Button, Input } from "shared/ui-kit";

import * as appTheme from "theme/default";

import { downscaleImage, toBase64 } from "utils/serviceUtils/helpers";

import {
	DescriptionText,
	FormFooterWrapper,
	InputContainerWrapper,
	InputWrapper,
	LogoWrapper,
	StyledAvatar,
	StyledForm,
	TitleText,
	WelcomeText
} from "./style";

const monthOptions = [
	{ label: "January", value: "1" },
	{ label: "February", value: "2" },
	{ label: "March", value: "3" },
	{ label: "April", value: "4" },
	{ label: "May", value: "5" },
	{ label: "June", value: "6" },
	{ label: "July", value: "7" },
	{ label: "August", value: "8" },
	{ label: "September", value: "9" },
	{ label: "October", value: "10" },
	{ label: "November", value: "11" },
	{ label: "December", value: "12" }
];

export interface ProfileProps {
	adminProfileView?: boolean;
	createCommunityRoute: string;
	homeRoute: string;
	createPasswordRoute?: string;
	setShowAnimalAvatarModal;
	communityUrl: string | null;
	invitationId: string;
	userId: string;
	imgUrl?: string;
}

const ProfileFields: FC<ProfileProps> = ({
	adminProfileView,
	createCommunityRoute,
	homeRoute,
	createPasswordRoute,
	setShowAnimalAvatarModal,
	communityUrl,
	invitationId,
	userId,
	imgUrl
}) => {
	const dayOptions = useMemo(() => {
		const days: { label: string; value: string }[] = [];
		for (let i = 1; i <= 31; i++) {
			days.push({ label: i.toString(), value: i.toString() });
		}
		return days;
	}, []);

	const yearOptions = useMemo(() => {
		const years: { label: string; value: string }[] = [];
		const currentYear = DateTime.local().year;
		for (let i = currentYear; i >= currentYear - 100; i--) {
			years.push({ label: i.toString(), value: i.toString() });
		}
		return years;
	}, []);

	const history = useHistory();

	const featuresKey = `${config.HOOK_STORAGE_KEYS.FEATURE_DATA}`;

	const { verifyOnboardingToken } = useAuth();

	const { removeStorageByKey } = useRoutes();

	const { getData: getCommunityData } = useCommunity();
	const { workspace, mainAdminColor } = getCommunityData();

	const { setPersona, createPersona, updatePersona, getData: getPersonaData } = usePersona();
	const { persona } = getPersonaData();

	const { uploadFiles } = useMedia();

	const [updatingAdminProfile, setUpdatingAdminProfile] = useState<boolean>(false);

	type CircularUploaderMethodsHandlers = React.ElementRef<typeof CircularUploader>;
	const circularUploaderRef = useRef<CircularUploaderMethodsHandlers>(null);

	useEffect(() => {
		removeStorageByKey(featuresKey);
	}, [removeStorageByKey, featuresKey]);

	const {
		control,
		handleSubmit,
		setValue,
		setError,
		clearErrors,
		formState: { isDirty },
		errors
	} = useForm({ mode: "onChange", defaultValues: persona ?? {} });

	const { coverImage, month, day, year } = useWatch({ control, name: ["coverImage", "month", "day", "year"] });

	useEffect(() => {
		imgUrl && setValue("profileImage", imgUrl);
	}, [setValue, imgUrl]);

	const isMobile = useMediaQuery(appTheme.default.breakpoints.down("xs"));

	useEffect(() => {
		if (month && day && year) {
			const correctDate = DateTime.fromObject({ day: day.value, month: month.value, year: year.value });
			if (correctDate.isValid) {
				const currentTime = DateTime.local();
				const diff = currentTime.diff(correctDate, ["years"]);

				if (correctDate > currentTime) {
					setValue("date", null);
					setError("date", {
						type: "manual",
						message: "Select past Date"
					});
				} else if (diff.years < 16) {
					setValue("date", null);
					setError("date", {
						type: "manual",
						message: "You must be 16 years old or over"
					});
				} else {
					setValue("date", correctDate);
					clearErrors("date");
				}
			} else {
				setValue("date", null);
				setError("date", {
					type: "manual",
					message: "Invalid Date"
				});
			}
		}
	}, [month, day, year, setValue, setError, clearErrors]);

	const onSubmit = useCallback(
		async data => {
			if (data) {
				let personaId: null | number = null;
				if (adminProfileView) {
					setUpdatingAdminProfile(true);
					const personaData = await createPersona(data.firstName.trim(), data.lastName.trim());
					if (personaData?.personaId) {
						personaId = personaData.personaId;
					}
					let photos: Partial<MediaModel>[] = [];
					let coverPhotos: Partial<MediaModel>[] = [];

					const uploads: (Promise<any> | null)[] = [];
					if (data?.coverImage) {
						uploads.push(
							new Promise(async resolve => {
								const coverPhotoMediaData = await uploadFiles({
									files: [data.coverImage],
									type: MediaType.coverPhoto
								});

								resolve({
									mediaId: coverPhotoMediaData[0]._id,
									uri: coverPhotoMediaData[0].uri,
									active: true
								});
							})
						);
					} else {
						uploads.push(null);
					}

					if (data?.profileImage) {
						uploads.push(
							new Promise(async resolve => {
								const profileImageMediaData = await uploadFiles({
									files: [data.profileImage],
									type: MediaType.profilePhoto
								});

								resolve({
									mediaId: profileImageMediaData[0]._id,
									id: `${profileImageMediaData[0].type}_${profileImageMediaData[0]._id}`,
									name: `${profileImageMediaData[0].type}_${profileImageMediaData[0]._id}`,
									active: true,
									profilePicture: profileImageMediaData[0].uri
								});
							})
						);
					} else {
						uploads.push(null);
					}

					await Promise.all(uploads).then(async values => {
						if (values[0]) {
							coverPhotos = values[0];
						}
						if (values[1]) {
							photos = values[1];
						}

						await updatePersona({
							firstName: data.firstName.trim(),
							lastName: data.lastName.trim(),
							personaId: Number(personaId),
							photos,
							coverPhotos,
							personalInfo: {
								birthday: data.date.toString(),
								birthdayHidden: true
							}
						});

						setUpdatingAdminProfile(false);
					});
				} else {
					if (data.coverImage && typeof data.coverImage !== "string") {
						data.coverImage =
							data.coverImage.size / 1000 > 450 // if size more than ~450Kb
								? await downscaleImage(URL.createObjectURL(data.coverImage), 500, data.coverImage.type, 0.5)
								: await toBase64(data.coverImage);
					}
					if (data.profileImage && typeof data.profileImage !== "string") {
						data.profileImage =
							data.profileImage.size / 1000 > 450 // if size more than ~450Kb
								? await downscaleImage(URL.createObjectURL(data.profileImage), 500, data.profileImage.type, 0.5)
								: await toBase64(data.profileImage);
					}
				}

				setPersona({
					...data,
					personaId,
					personalInfo: {
						birthday: data.date.toString(),
						birthdayHidden: true
					}
				});

				if (communityUrl && invitationId && userId) {
					await verifyOnboardingToken({
						communityUrl,
						invitationId,
						userId
					});
				}

				history.push(
					createPasswordRoute
						? `${createPasswordRoute}?next=dashboard`
						: adminProfileView
						? homeRoute
						: createCommunityRoute
				);
			}
		},
		[
			adminProfileView,
			setPersona,
			communityUrl,
			invitationId,
			userId,
			history,
			createPasswordRoute,
			homeRoute,
			createCommunityRoute,
			createPersona,
			uploadFiles,
			updatePersona,
			verifyOnboardingToken
		]
	);

	const getAvatarOptions = useCallback(
		(currentAvatar?: MediaModel) => [
			{
				icon: <CameraFlipIcon />,
				name: currentAvatar ? "Change Photo" : "Select Photo",
				onClick: () => {
					if (currentAvatar) {
						circularUploaderRef?.current!.clearValue();
						circularUploaderRef?.current!.initiateSelect();
					} else {
						circularUploaderRef?.current!.initiateSelect();
					}
				}
			},
			{
				icon: <DogIcon />,
				name: "Select Avatar",
				onClick: () => {
					setShowAnimalAvatarModal(true);
					clearErrors("profileImage");
				}
			}
		],
		[setShowAnimalAvatarModal, clearErrors]
	);

	return (
		<StyledForm onSubmit={handleSubmit(onSubmit)}>
			<LogoWrapper color={mainAdminColor}>
				{workspace?.name ? (
					<StyledAvatar width={64} height={64} title={workspace?.name} image={workspace?.meta?.img || undefined} />
				) : (
					<VyooLogo />
				)}
			</LogoWrapper>
			<WelcomeText variant="h2" color={mainAdminColor}>
				Welcome to {workspace?.name || "Vyoo"}
			</WelcomeText>
			<TitleText variant="h2">Let’s fill your profile</TitleText>
			<DescriptionText variant="body1">
				Your community members will recognize you by your full name and profile picture.
			</DescriptionText>
			<FormBase.AvatarUploaderWrapper>
				<FormBase.AvatarUploaderTitle>
					{coverImage ? (
						"Looking Good 😎"
					) : (
						<>
							Upload Profile Picture <FormBase.Asterisk>*</FormBase.Asterisk>
						</>
					)}
				</FormBase.AvatarUploaderTitle>
				<FormBase.UploaderWrapper>
					<Controller
						name="profileImage"
						control={control}
						defaultValue={!!persona?.photos?.length ? (persona?.photos[0] as MediaModel).profilePicture : undefined}
						rules={{
							required: "Profile picture is required."
						}}
						render={({ value, onChange }) => (
							<InputWrapper mb={32}>
								<CircularUploader
									simpleMode
									size={116}
									ref={circularUploaderRef}
									preview={value ? (typeof value === "string" ? value : window.URL.createObjectURL(value)) : undefined}
									onChange={files => onChange(files?.length ? files[0] : files)}
									options={getAvatarOptions(value)}
								/>
							</InputWrapper>
						)}
					/>
					{errors["profileImage"] && <FormBase.ErrorText>{(errors["profileImage"] as any).message}</FormBase.ErrorText>}
				</FormBase.UploaderWrapper>
			</FormBase.AvatarUploaderWrapper>
			<InputContainerWrapper direction="row" justify="center">
				<InputWrapper>
					<Controller
						name={"firstName"}
						control={control}
						rules={{
							required: "Field is required.",
							minLength: {
								value: 3,
								message: "First name should have at least 3 characters"
							},
							maxLength: {
								value: 50,
								message: "First name should not exceed 50 characters"
							},
							validate: value => {
								return value && value.trim().length >= 3 ? true : "First name should have at least 3 characters";
							}
						}}
						render={({ onChange, value, ref }) => (
							<Input
								name="firstName"
								placeholder="First name*"
								value={value}
								onChange={onChange}
								errorText={errors?.firstName?.message}
								ref={ref}
							/>
						)}
					/>
				</InputWrapper>
				<InputWrapper>
					<Controller
						name={"lastName"}
						control={control}
						rules={{
							required: "Field is required.",
							minLength: {
								value: 2,
								message: "Last name should have at least 3 characters"
							},
							maxLength: {
								value: 50,
								message: "Last name should not exceed 50 characters"
							},
							validate: value => {
								return value && value.trim().length >= 2 ? true : "Last name should have at least 3 characters";
							}
						}}
						render={({ onChange, value, ref }) => (
							<Input
								placeholder="Last name*"
								value={value}
								errorText={errors["lastName"]?.message}
								onChange={onChange}
								ref={ref}
							/>
						)}
					/>
				</InputWrapper>
			</InputContainerWrapper>
			<InputContainerWrapper>
				<FormBase.InfoForm>
					<FormBase.Label>
						Date of Birth <FormBase.Asterisk>*</FormBase.Asterisk>
					</FormBase.Label>
					<FormBase.Row>
						<Controller name="date" control={control} render={() => <input type="text" hidden />} />
						<FormBase.Column className={"three-col"}>
							<Controller
								name="month"
								control={control}
								rules={{
									required: "Required",
									validate: val => {
										if (!val.value || !val.label) {
											return "Required";
										}
									}
								}}
								render={({ onChange, value }) => (
									<InputWrapper.Birthday>
										<SelectInput
											name="month"
											onChange={onChange}
											value={value ? (isMobile ? value.label.slice(0, 3) : value.label) : null}
											placeholder="Month"
											maxHeight={260}
											error={errors?.month?.message || errors?.date?.message}
											options={monthOptions}
											displayOnly
										/>
									</InputWrapper.Birthday>
								)}
							/>
						</FormBase.Column>
						<FormBase.Column className={"three-col"}>
							<Controller
								name="day"
								control={control}
								rules={{
									required: "Required",
									validate: val => {
										if (!val.value || !val.label) {
											return "Required";
										}
									}
								}}
								render={({ onChange, value }) => (
									<InputWrapper.Birthday>
										<SelectInput
											name="day"
											onChange={onChange}
											value={value ? value.label : null}
											placeholder="Day"
											label=" "
											maxHeight={260}
											error={errors?.day?.message || errors?.date?.message ? " " : ""}
											options={dayOptions}
											displayOnly
										/>
									</InputWrapper.Birthday>
								)}
							/>
						</FormBase.Column>
						<FormBase.Column className={"three-col"}>
							<Controller
								name="year"
								control={control}
								rules={{
									required: "Required",
									validate: val => {
										if (!val.value || !val.label) {
											return "Required";
										}
									}
								}}
								render={({ onChange, value }) => (
									<InputWrapper.Birthday>
										<SelectInput
											name="year"
											onChange={onChange}
											value={value ? value.label : null}
											placeholder="Year"
											label=" "
											maxHeight={260}
											error={errors?.year?.message || errors?.date?.message ? " " : ""}
											options={yearOptions}
											displayOnly
										/>
									</InputWrapper.Birthday>
								)}
							/>
						</FormBase.Column>
					</FormBase.Row>
				</FormBase.InfoForm>
			</InputContainerWrapper>
			<FormFooterWrapper>
				<Button
					removeSideMargin
					fullWidth
					disabled={!isDirty || updatingAdminProfile}
					size="large"
					leftIcon={updatingAdminProfile ? <CircularProgress size={20} /> : null}
					type="submit"
				>
					Continue
				</Button>
			</FormFooterWrapper>
		</StyledForm>
	);
};

export default ProfileFields;
