import React from "react";

import styled, { css } from "styled-components";

import { ReactComponent as CompassIcon } from "assets/icons/icon-compass-filled.svg";
import { ReactComponent as ProfileIcon } from "assets/icons/icon-profile-linear.svg";
import { Box, Icon } from "shared/ui-kit";

interface FrameNavigationBlockProps {
	color?: string;
	customStyle?: string;
}

const FrameBlockWrapper = styled(Box)<FrameNavigationBlockProps>`
	width: 100%;
	height: 58px;

	padding: 8px 18px 6px 18px;
	box-shadow: 0 -1px 8px 0 rgba(0, 0, 0, 0.08);
	background-color: white;

	position: relative;

	${({ customStyle }) => {
		return customStyle
			? css`
					${customStyle}
			  `
			: "";
	}}
`;

const NavigationRow = styled(Box)`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const ManageLineWrapper = styled(Box)`
	display: flex;
	justify-content: center;

	width: 100%;

	position: absolute;
	left: 0;
	bottom: 6px;
`;

const ManageLine = styled(Box)`
	display: block;

	width: 80px;
	height: 3px;
	border-radius: 2px;
	background-color: #c5cee0;
`;

const CompassIconWrapper = styled(Box)<FrameNavigationBlockProps>`
	path {
		fill: ${({ color }) => (color ? color : "#979BA1")};
	}
`;

const FrameNavigationBlock: React.FC<FrameNavigationBlockProps> = (props: FrameNavigationBlockProps): JSX.Element => {
	return (
		<FrameBlockWrapper {...props}>
			<NavigationRow>
				<ProfileIcon />
				<Icon group={"linear"} fill={"#979BA1"} name={"comment"} width={24} height={24} viewBox={"0 0 26 24"} />
				<CompassIconWrapper color={props.color}>
					<CompassIcon />
				</CompassIconWrapper>
				<Icon group={"linear"} fill={"#979BA1"} name={"bell"} width={24} height={24} viewBox={"0 0 24 26"} />
				<Icon group={"filled"} fill={"#979BA1"} name={"menu"} width={24} height={24} viewBox={"0 0 36 24"} />
			</NavigationRow>
			<ManageLineWrapper>
				<ManageLine />
			</ManageLineWrapper>
		</FrameBlockWrapper>
	);
};

export default FrameNavigationBlock;
