import React, { useEffect } from "react";

import { useLocation } from "react-router-dom";
import { NavMenuItemModel } from "types";

import { Icon } from "shared/ui-kit";

import { ChildNavLabel, ExpansionLink, NavLabel, SubMenu } from "./styles";

interface ExpandItemProps {
	item: NavMenuItemModel;
	subItems: NavMenuItemModel[];
	highlighted: boolean;
	expandedItem: string | null;
	setExpandedItem: React.Dispatch<React.SetStateAction<string | null>>;
	scrollToTop?: () => void;
	color?: string;
}

const ExpandItem: React.FC<ExpandItemProps> = ({
	item,
	subItems,
	highlighted,
	expandedItem,
	setExpandedItem,
	scrollToTop,
	color
}) => {
	const location = useLocation();

	const open = item.label === expandedItem;

	useEffect(() => {
		if (typeof expandedItem !== "string" && subItems.some(x => location.pathname.includes(`${x?.route}`))) {
			setExpandedItem(item.label);
		}
	}, [expandedItem, item.label, location, setExpandedItem, subItems]);

	return (
		<ExpansionLink>
			<NavLabel.Container
				id={item?.id || ""}
				data-step={item.label.toLowerCase()}
				color={color}
				onClick={e => {
					e.stopPropagation();
					if (expandedItem === item.label) {
						setExpandedItem("");
					} else {
						setExpandedItem(item.label);
					}
				}}
			>
				<NavLabel.IconWrapper>
					<Icon group={item?.iconGroup} fill={highlighted ? color || "#6173fe" : "#c5cee0"} name={item.icon} />
				</NavLabel.IconWrapper>
				<NavLabel.Title active={+highlighted} color={color}>
					{item.label}
				</NavLabel.Title>
				<NavLabel.ArrowWrapper show={+open}>
					<Icon name={"dropdown-arrow-down"} fill={open ? "#8f9bb3" : "#c5cee0"} />
				</NavLabel.ArrowWrapper>
			</NavLabel.Container>
			<SubMenu show={+open}>
				{subItems.map(child => {
					const childHighlighted = location.pathname.includes(`${child?.route}`);
					return (
						<ChildNavLabel.Container
							key={child!.label}
							highlighted={+childHighlighted}
							to={child.route}
							id={child?.id || ""}
							onClick={scrollToTop}
							color={color}
						>
							<ChildNavLabel.Delimiter />
							<ChildNavLabel.Title highlighted={+childHighlighted} color={color}>
								{child!.label}
							</ChildNavLabel.Title>
						</ChildNavLabel.Container>
					);
				})}
			</SubMenu>
		</ExpansionLink>
	);
};

export default ExpandItem;
