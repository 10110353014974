import { Box } from "@material-ui/core";
import styled from "styled-components";

export const NoMutual = styled(Box)`
	width: 90%;
	margin: 32px auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	.fill-second-path {
		path:nth-child(2) {
			fill: #c5cee0;
		}
	}
	h2 {
		font-size: 15px;
		font-weight: 600;
		color: #222b45;
		margin: 8px 0;
	}
	p {
		font-size: 13px;
		color: #8f9bb3;
	}
`;

NoMutual.Actions = styled(Box)`
	margin-top: 24px;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	button {
		font-size: 12px;
		display: flex;
		align-items: center;
		border-radius: 8px;
		svg {
			margin-right: 4px;
		}
	}
`;
