import styled from "styled-components";

import { Box, Button } from "shared/ui-kit";

import colors from "theme/colors";
import theme from "theme/default";

export const PageWrapper = styled(Box)`
	height: 100vh;
`;

export const TopBar = styled.header`
	width: 100%;
	height: 100px;
	position: sticky;
	top: 0;
	z-index: 1200;

	display: flex;
	align-items: center;

	backdrop-filter: blur(20px);
	box-shadow: 0 0.5px 0 0 ${theme.palette.colors.basic[500]};
	background-color: white;

	${props => props.theme.breakpoints.down("sm")} {
		height: 70px;
	}
`;

export const TopBarContainer = styled(Box)`
	width: 100%;

	margin: -12px auto 0 auto;
	padding: 0 127px 0 74px;
	display: flex;
	align-items: center;

	${props => props.theme.breakpoints.down("sm")} {
		padding: 0 34px 0 0;
		margin-top: 0;
	}

	.left-side {
		display: flex;
		flex: 2 1 auto;
		padding: 0 16px;
	}

	.right-side {
		display: flex;
		justify-content: flex-end;
		margin-left: auto;
		min-width: 480px;
		${props => props.theme.breakpoints.down("sm")} {
			min-width: 0;
		}
		button {
			margin: auto 10px;

			&:last-child {
				margin-right: 0;
			}
		}
	}

	.brand {
		display: flex;

		&-image {
			margin-top: 5px;

			${props => props.theme.breakpoints.down("sm")} {
				margin-top: 5px;
			}

			svg {
				width: 120px;
				height: 47px;

				${props => props.theme.breakpoints.down("sm")} {
					width: 106px;
					height: 43px;
				}
			}
		}
	}
`;

export const AdminLoginBtn = styled(Button)`
	background: transparent;
	color: ${colors.palette.colors.purple};
	border: none;
	box-shadow: none;

	font-size: 16px;
	font-weight: 700;

	${props => props.theme.breakpoints.down("sm")} {
		display: none;
	}
`;

export const CreateCmtButton = styled(Button)`
	background-color: ${colors.palette.colors.purple};

	width: auto;
	height: 47px;

	padding: 10px 20px;

	border-radius: 12px;

	font-size: 16px;
	font-weight: 700;

	${props => props.theme.breakpoints.down("sm")} {
		display: none;
	}
`;

export const HamburgerIconWrapper = styled(Box)`
	display: flex;
	align-items: center;

	cursor: pointer;

	margin-left: 30px;

	svg {
		width: 22px;
		height: 22px;

		path {
			fill: ${colors.palette.colors.purple};
		}
	}
`;

export const Main = styled.main`
	overflow: auto;
	background-color: ${theme.palette.colors.basic[200]};
	display: flex;
	justify-content: space-between;

	position: relative;
`;
