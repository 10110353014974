import React, { useCallback, useMemo, useState } from "react";

import clsx from "clsx";
import { useHistory } from "react-router";

import { MenuDots, PlaceholderImage } from "shared/Components";
import { useCommunity, useMemberRoutes } from "shared/hooks";
import { MediaModel, PlaceholderImageType, ProfileType } from "shared/types";
import { Icon, Tooltip } from "shared/ui-kit";

import { Item, StyledButton } from "./style";

export enum ConnectionAction {
	accept = "accept",
	decline = "decline",
	remove = "remove",
	message = "message",
	requestConnect = "requestConnect"
}

export enum ConnectionStatus {
	accepted = "accepted",
	declined = "declined",
	removed = "removed",
	requestedConnection = "requestedConnection"
}

interface ConnectionCardProps {
	connection: ProfileType;
	isRequest?: boolean;
	isSuggestion?: boolean;
	isConnected?: boolean;
	onManage?: ({ connection, action }: { connection: ProfileType; action: ConnectionAction }) => void;
}

const ConnectionCard: React.FC<ConnectionCardProps> = ({
	connection,
	isRequest = false,
	isSuggestion = false,
	isConnected = false,
	onManage
}) => {
	const [correctStatus, setCorrectStatus] = useState<ConnectionStatus>();

	const history = useHistory();

	const { getMemberRoutesData } = useMemberRoutes();
	const { routes } = getMemberRoutesData();

	const { communityColors } = useCommunity();

	const handleManage = useCallback(
		(action: ConnectionAction) => {
			onManage && onManage({ connection, action });
			switch (action) {
				case ConnectionAction.accept:
					setCorrectStatus(ConnectionStatus.accepted);
					break;

				case ConnectionAction.decline:
					setCorrectStatus(ConnectionStatus.declined);
					break;

				case ConnectionAction.remove:
					setCorrectStatus(ConnectionStatus.removed);
					break;

				case ConnectionAction.message:
					history.push(`${routes?.member.messaging.getPath()}?messageTo=${connection.personaId}`);
					break;

				case ConnectionAction.requestConnect:
					setCorrectStatus(ConnectionStatus.requestedConnection);
					break;
			}
		},
		[onManage, connection, history, routes?.member.messaging]
	);

	const options = useMemo(() => {
		return [
			{
				name: "Remove Connection",
				onClick: () => {
					handleManage(ConnectionAction.remove);
				},
				value: 1
			}
		];
	}, [handleManage]);

	const coverPhoto = connection?.coverPhotos?.length ? connection?.coverPhotos[0] : undefined;
	const profilePhoto = connection?.photos?.length
		? (connection.photos[0] as Partial<MediaModel>)?.profilePicture
		: undefined;

	return (
		<Item color={communityColors.primary} className={clsx(isRequest ? "long" : "", "relative")}>
			<Item.CoverPhotoWrapper>
				{coverPhoto ? (
					<Item.CoverPhoto url={coverPhoto} />
				) : (
					<Item.CoverPhotoPlaceholderWrapper>
						<PlaceholderImage
							type={PlaceholderImageType.PROFILE_COVER}
							width={210}
							height={66}
							viewBox={"0 0 1000 300"}
						/>
					</Item.CoverPhotoPlaceholderWrapper>
				)}
				<Item.PhotoWrapper>
					<Item.PhotoLink to={`${routes?.member.profile.getPath()}/${connection.personaId}`}>
						{profilePhoto ? (
							<Item.Photo url={profilePhoto} />
						) : (
							<PlaceholderImage
								type={PlaceholderImageType.PROFILE_IMAGE}
								width={80}
								height={80}
								viewBox={"0 0 400 400"}
							/>
						)}
					</Item.PhotoLink>
				</Item.PhotoWrapper>
			</Item.CoverPhotoWrapper>
			<Item.TooltipWrapper>
				<Tooltip text={`${connection.firstName} ${connection.lastName}`}>
					{connection.firstName.length > 20 ? (
						<Item.Title className="px-4 truncate">
							{connection.firstName} <br /> {connection.lastName}
						</Item.Title>
					) : (
						<Item.Title>
							{connection.firstName} {connection.lastName}
						</Item.Title>
					)}
				</Tooltip>
			</Item.TooltipWrapper>
			<Item.Body className="absolute w-full bottom-0 d-flex flex-col pb-2">
				<Item.ConnectionsBlock>
					{!!connection?.connections?.length ? <></> : <Item.ConnectionText>No Mutual Connection</Item.ConnectionText>}
				</Item.ConnectionsBlock>
				<Item.ManageBlock className={isRequest && "long"}>
					{isSuggestion && (
						<StyledButton
							palette={correctStatus === ConnectionStatus.requestedConnection ? "basic" : "primary"}
							size="medium"
							removeSideMargin
							fullWidth
							onClick={() => !correctStatus && handleManage(ConnectionAction.requestConnect)}
							leftIcon={
								correctStatus === ConnectionStatus.requestedConnection && (
									<Icon fill={"#151a30"} name={"message-check"} />
								)
							}
						>
							{correctStatus === ConnectionStatus.requestedConnection ? "Request Sent" : "Connect"}
						</StyledButton>
					)}
					{isRequest && (
						<>
							{!correctStatus ? (
								<>
									<StyledButton
										palette="primary"
										size="medium"
										removeSideMargin
										fullWidth
										onClick={() => handleManage(ConnectionAction.accept)}
									>
										Accept
									</StyledButton>
									<StyledButton
										palette="basic"
										buttonTheme="light"
										size="medium"
										removeSideMargin
										fullWidth
										onClick={() => handleManage(ConnectionAction.decline)}
										className="hover-to-black"
									>
										Reject
									</StyledButton>
								</>
							) : (
								<StyledButton
									palette={correctStatus === ConnectionStatus.accepted ? "primary" : "basic"}
									size="medium"
									removeSideMargin
									fullWidth
								>
									{correctStatus === ConnectionStatus.accepted ? "Accepted" : "Rejected"}
								</StyledButton>
							)}
						</>
					)}
					{isConnected && (
						<Item.MessageRow>
							{correctStatus === ConnectionStatus.removed ? (
								<StyledButton palette="primary" size="medium" removeSideMargin fullWidth disabled>
									Removed
								</StyledButton>
							) : (
								<>
									<Item.TooltipWrapper>
										<StyledButton
											palette="primary"
											size="medium"
											removeSideMargin
											fullWidth
											onClick={() => handleManage(ConnectionAction.message)}
										>
											Message
										</StyledButton>
									</Item.TooltipWrapper>
									<Item.MenuWrapper>
										<MenuDots options={options} />
									</Item.MenuWrapper>
								</>
							)}
						</Item.MessageRow>
					)}
				</Item.ManageBlock>
			</Item.Body>
		</Item>
	);
};

export default ConnectionCard;
