import { Box, ButtonBase } from "@material-ui/core";
import styled, { css } from "styled-components";

export const Wrapper = styled(ButtonBase)`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 8px;
	padding: 8px 0;
	border-radius: 8px;
	text-align: left;
	background-color: ${({ current }) => (current ? "#f7f9fc" : "transparent")};

	&:hover {
		background-color: #f7f9fc;
	}

	${({ mini }) =>
		!mini
			? css`
					margin-left: -8px;
					width: calc(100% + 16px);
			  `
			: css`
					width: 100%;
			  `}
`;

Wrapper.LeftContent = styled(Box)`
	display: flex;
	align-items: center;
	width: ${({ fw }) => (fw ? "85%" : "45%")};
	padding-left: 8px;
	.order {
		width: 20px;
		font-size: 13px;
		line-height: 1.23;
		color: #222b45;
		margin-right: 6px;
		flex-shrink: 0;
	}

	.title {
		display: flex;
		align-items: center;
		position: relative;
		width: calc(100% - 6px);
		.space-between {
			margin-left: 16px;
			width: calc(100% - 40px);
		}
		.icon-placeholder {
			height: 40px;
			width: 40px;
			flex-shrink: 0;
			border-radius: 8px;
			background-color: rgb(237, 241, 247);
			display: flex;
			align-items: center;
			justify-content: center;
		}
		img,
		.icon-placeholder {
			flex-shrink: 0;
		}
		.img-wrapper,
		.icon-placeholder {
			position: relative;
			overflow: hidden;
			flex-shrink: 0;
			${({ progress }) =>
				progress &&
				css`
					&:before {
						content: "";
						position: absolute;
						bottom: 0;
						left: 0;
						height: 3px;
						width: ${({ progress }) => (progress ? `${progress}%` : 0)};
						background-color: blue;
						display: block;
					}
				`}
		}
		.icon {
			position: absolute;
			left: -8px;
			top: -2px;
			background-color: white;
			border-radius: 99%;
			width: 20px;
			height: 20px;
			flex-shrink: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			z-index: 10;
			svg {
				width: 14px;
				height: 14px;
				fill: ${({ fillColor }) => fillColor};
				&.fill-first-path {
					path:first-child {
						fill: ${({ fillColor }) => fillColor};
					}
				}
				&.fill-last-path {
					path:last-child {
						fill: ${({ fillColor }) => fillColor};
					}
				}
			}
		}
		.img-wrapper {
			height: 40px;
			width: 40px;
			border-radius: 8px;
			flex-shrink: 0;
			img {
				object-fit: cover;
				width: 100%;
				height: 100%;
			}
		}
		h3 {
			font-size: 15px;
			font-weight: 600;
			line-height: 1.6;
			color: #222b45;
			width: 100%;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
		p {
			font-size: 13px;
			line-height: 1.23;
			color: #8f9bb3;
		}
	}
`;

Wrapper.RightContent = styled(Box)`
	width: 50%;
	display: flex;
	align-items: center;
	padding: 0 8px;
	justify-content: flex-end;
	.duration,
	.createdAt {
		display: none;
	}
	.actions {
		display: flex;
		align-items: center;
	}

	${props => props.theme.breakpoints.up("sm")} {
		justify-content: space-between;
		.duration {
			width: 100px;
		}
		.duration,
		.createdAt {
			display: block;
		}
	}
`;

Wrapper.CurrentlyPlaying = styled(Box)`
	width: 20px;
	height: 20px;
	border-radius: 99%;
	flex-shrink: 0;
	background-color: ${({ btnColor }) => btnColor};
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 6px;
	svg {
		width: 12px;
		height: 12px;
		fill: white;
	}
`;

export const EyeButton = styled(ButtonBase)`
	margin-right: 8px;
	border-radius: 99%;
`;

export const DragIconWrapper = styled.div`
	padding-left: 10px;
	display: flex;
	justify-content: center;
	align-items: center;

	svg {
		transform: rotate(90deg);
	}
`;
