import config from "config/appConfig";
import { CategoriesContextValuesType } from "types";

import { IStateProps } from "utils/StateOf";

import createStore from "utils/createStore";

const localStorageKey = `${config.GLOBAL_CONSTANTS.LOCAL_STORAGE_PREFIX}${config.HOOK_STORAGE_KEYS.CATEGORIES_DATA}`;

export const initialState: IStateProps<CategoriesContextValuesType> = {
	categoriesPerPage: 20,
	page: 1,
	offset: 0,
	isLoading: false,
	categoryImageUploadUrl: "",
	createUpdateCategoryOpen: false,
	editObject: undefined,
	categories: [],
	categoriesCount: 0
};

export default createStore<CategoriesContextValuesType>({
	initialState,
	persistant: true,
	persistantName: localStorageKey
});
