import React, { FC } from "react";

import { Box } from "@material-ui/core";

import { useCommunity } from "shared/hooks";
import { ReadMore, Text } from "shared/ui-kit";

import { IconWrapper, InfoRow } from "../style";

interface Props {
	icon: JSX.Element;
	title: string;
	company?: string;
	location?: string;
	className?: string;
	width?: string;
	distanceLevel?: 1 | 2;
	description?: string;
	regularCompany?: boolean;
}

const ExperienceRow: FC<Props> = ({
	icon,
	title,
	company,
	location,
	className,
	width,
	distanceLevel = 2,
	description,
	regularCompany
}) => {
	const { communityColors } = useCommunity();
	return (
		<InfoRow className={className} width={width} regularCompany={regularCompany} fw>
			<IconWrapper fillColor={communityColors.btn} distanceLevel={distanceLevel}>
				{icon}
			</IconWrapper>
			<Box>
				<Text>{title}</Text>
				{company && <Text className="company wrap">{company}</Text>}
				{location && <Text className="secondary wrap">{location}</Text>}
				{description && <ReadMore text={description} showCharCount={300} wrapClassName="" wrapTextClassName="wrap" />}
			</Box>
		</InfoRow>
	);
};

export default ExperienceRow;
