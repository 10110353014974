import React, { useCallback, useEffect, useMemo, useState } from "react";

import axios from "axios";
import { DateTime } from "luxon";

import { ReactComponent as QRIcon } from "assets/icons/iconQrCode.svg";
import { INVITE } from "modules/Members/constants";
import { Dialog, MenuDots, Table } from "shared/Components";
import { Cell } from "shared/Components/NewTable/style";
import { useMembers, useNotification } from "shared/hooks";
import { InvitationModel } from "shared/types";
import { Icon, Loader } from "shared/ui-kit";
import { setHeightOfTable } from "utils/setHeightOfTable";

import {
	Container,
	CreateLinkButton,
	Divider,
	LoadingTableData,
	PopupDescription,
	PopupTitle,
	StyledBtn,
	StyledButtonContainer
} from "./style";

import { ButtonWrapper } from "../style";

const ShareableLinkList = () => {
	const {
		setModalState,
		setIsInvited,
		getAllShareableLinks,
		deleteShareableLink,
		activeDeactiveShareableLink,
		setSelectedQRCode,
		getData: getMembersData
	} = useMembers();
	const { allShareableLinks, stopLoadingLinks, allQRCodes, stopLoadingQR, viewType, isLoading } = getMembersData();

	const [offset, setOffset] = useState(1);

	const isSharableLinkView = viewType === INVITE.SHAREABLE_LINK;
	const modalContent = isSharableLinkView ? INVITE.INVITE_VIA_SHAREABLE_LINK : INVITE.INVITE_VIA_QR_CODE;
	const modalTitle = isSharableLinkView ? INVITE.SHAREABLE_LINK_TITLE : INVITE.QR_CODE_TITLE;

	useEffect(() => {
		isSharableLinkView
			? getAllShareableLinks({ type: viewType, offset: 1, limit: 20 })
			: getAllShareableLinks({ type: viewType, offset: 1, limit: 20 });
	}, [getAllShareableLinks, viewType, isSharableLinkView]);

	const loadMore = useCallback(() => {
		const stopLoading = isSharableLinkView ? stopLoadingLinks : stopLoadingQR;
		setOffset(offset + 1);
		!stopLoading && getAllShareableLinks({ type: viewType, offset: offset, limit: 20 });
	}, [getAllShareableLinks, isSharableLinkView, stopLoadingLinks, stopLoadingQR, offset, viewType]);

	const icon = useMemo(
		() => (isSharableLinkView ? <Icon name={"link"} fill="#8f9bb3" height={24} width={24} /> : <QRIcon />),
		[isSharableLinkView]
	);

	const [linkToDelete, setLinkToDelete] = useState<InvitationModel | null>();
	const [linkToDeactivate, setLinkToDeactivate] = useState<InvitationModel | null>();
	const [open, setOpen] = useState(false);
	const [openModal, setOpenModal] = useState(false);
	const { showMessage } = useNotification();

	const handleEditLink = useCallback(
		(rowData: InvitationModel) => {
			setModalState({
				isOpen: true,
				modalContent,
				modalTitle: INVITE.SHAREABLE_UPDATE_LINK_TITLE,
				viewType,
				editableModel: rowData
			});
			setIsInvited(false);
		},
		[setModalState, setIsInvited, modalContent, viewType]
	);

	const handleActiveDeactiveLink = useCallback(
		async (rowData: InvitationModel) => {
			const data = {
				active: !rowData.active,
				invitationId: rowData._id
			};
			await activeDeactiveShareableLink(data, viewType);
			await getAllShareableLinks({ type: viewType, offset: 1, limit: 20 });
		},
		[activeDeactiveShareableLink, getAllShareableLinks, viewType]
	);

	const getOptions = useCallback(
		(rowData: InvitationModel) => {
			const options: { name: string; onClick: () => void }[] = [
				{
					name: "Edit",
					onClick: () => handleEditLink(rowData)
				},
				{
					name: "Deactivate",
					onClick: () => {
						setLinkToDeactivate(rowData || null);
						setOpenModal(true);
					}
				},
				{
					name: "Delete Forever",
					onClick: () => {
						setLinkToDelete(rowData || null);
						setOpen(true);
					}
				}
			];
			if ((rowData.expireAt && DateTime.fromISO(rowData.expireAt) < DateTime.now()) || !rowData.active)
				return options.filter(item => item.name !== "Deactivate");

			return options;
		},
		[handleEditLink]
	);

	const handleAddNewLink = useCallback(() => {
		setModalState({ isOpen: true, modalContent, modalTitle, viewType });
		setIsInvited(false);
	}, [setModalState, setIsInvited, modalContent, modalTitle, viewType]);

	const downloadQR = useCallback(async url => {
		return await axios({
			url,
			method: "GET",
			responseType: "blob"
		}).then(response => {
			const url = window.URL.createObjectURL(response.data);
			const link = document.createElement("a");
			link.href = url;
			link.setAttribute("download", "qr_code.png");
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		});
	}, []);

	const handleCopyOrDownloadLink = useCallback(
		async (link: string) => {
			if (isSharableLinkView) {
				showMessage("Link Successfully Copied 📋", 2);
				navigator.clipboard.writeText(link as string);
			} else {
				downloadQR(link);
			}
		},
		[isSharableLinkView, downloadQR, showMessage]
	);

	const handleViewQrcode = useCallback(
		async (link: string) => {
			setSelectedQRCode(link);
			setModalState({ isOpen: true, modalContent: "viewQRCode", modalTitle: "QR code", viewType });
		},
		[setSelectedQRCode, setModalState, viewType]
	);

	const confirmPopupAction = useCallback(
		async (confirm: boolean) => {
			setOpen(false);
			if (confirm && linkToDelete) {
				await deleteShareableLink(linkToDelete._id, viewType);
				await getAllShareableLinks({ type: viewType, offset: 1, limit: 20 });
			}
		},
		[linkToDelete, deleteShareableLink, getAllShareableLinks, viewType]
	);

	const confirmDeactivatePopupAction = useCallback(
		async (confirm: boolean) => {
			setOpenModal(false);
			if (confirm && linkToDeactivate) {
				handleActiveDeactiveLink(linkToDeactivate);
			}
		},
		[linkToDeactivate, handleActiveDeactiveLink]
	);

	const ActionFooter = useMemo(
		() => (
			<>
				<StyledBtn
					buttonTheme={"outline"}
					size={"large"}
					onClick={() => (openModal ? confirmDeactivatePopupAction(false) : confirmPopupAction(false))}
				>
					Keep
				</StyledBtn>
				<StyledBtn
					size={"large"}
					onClick={() => (openModal ? confirmDeactivatePopupAction(true) : confirmPopupAction(true))}
				>
					{`${openModal ? "Deactivate" : "Delete Forever"}`}
				</StyledBtn>
			</>
		),
		[confirmPopupAction, confirmDeactivatePopupAction, openModal]
	);

	const getButton = useCallback(
		row => {
			if (!row.active) {
				return (
					<StyledBtn buttonTheme="main" onClick={() => handleActiveDeactiveLink(row)} palette="primary" size="small">
						{INVITE.ACTIVATE}
					</StyledBtn>
				);
			}
			if (row.expireAt && DateTime.fromISO(row.expireAt) < DateTime.now()) {
				return (
					<StyledBtn buttonTheme="main" disabled palette="primary" size="small">
						Expired
					</StyledBtn>
				);
			}
			if (row.totalRedemption && row.totalRedemption === row.redemptionUsed) {
				return (
					<StyledBtn buttonTheme="main" disabled palette="primary" size="small">
						Limit Reached
					</StyledBtn>
				);
			}
			return (
				<StyledButtonContainer>
					{!isSharableLinkView && (
						<StyledBtn buttonTheme="light" onClick={() => handleViewQrcode(row.link)} palette="primary" size="small">
							{INVITE.VIEW}
						</StyledBtn>
					)}
					<StyledBtn
						buttonTheme="main"
						onClick={() => handleCopyOrDownloadLink(row.link)}
						palette="primary"
						size="small"
					>
						{isSharableLinkView ? INVITE.COPY_LINK : INVITE.DOWNLOAD}
					</StyledBtn>
				</StyledButtonContainer>
			);
		},
		[isSharableLinkView, handleCopyOrDownloadLink, handleViewQrcode, handleActiveDeactiveLink]
	);

	const tableColumns = useMemo(
		() => [
			{
				alignment: "left",
				width: 220,
				label: <Cell.HeaderText>Created by</Cell.HeaderText>,
				Cell: ({ rowData: { creator, createdAt } }) => (
					<Cell.Wrapper>
						<Cell.ImageWrapper>{icon}</Cell.ImageWrapper>
						<Cell.Wrapper className="column with-image">
							<Cell.Text>
								{creator?.firstName} {creator?.lastName}
							</Cell.Text>
							<Cell.Text className="light">{DateTime.fromISO(createdAt).toFormat("MMM d, yyyy")}</Cell.Text>
						</Cell.Wrapper>
					</Cell.Wrapper>
				),
				dataKey: "content"
			},
			{
				alignment: "left",
				width: 145,
				label: <Cell.HeaderText>Assigned referee</Cell.HeaderText>,
				Cell: ({ rowData: { assignee } }) => (
					<Cell.Wrapper>
						<Cell.Text>{assignee ? `${assignee.firstName} ${assignee.lastName}` : "-"}</Cell.Text>
					</Cell.Wrapper>
				),
				dataKey: "assignee"
			},
			{
				alignment: "left",
				width: 80,
				label: <Cell.HeaderText>Code</Cell.HeaderText>,
				Cell: ({ rowData: { code } }) => (
					<Cell.Wrapper>
						<Cell.Text>{code}</Cell.Text>
					</Cell.Wrapper>
				),
				dataKey: "code"
			},
			{
				alignment: "left",
				width: 125,
				label: <Cell.HeaderText>Expiration</Cell.HeaderText>,
				Cell: ({ rowData: { expireAt } }) => (
					<Cell.Wrapper>
						<Cell.Text>{expireAt ? DateTime.fromISO(expireAt).toFormat("MMM d, yyyy") : "-"}</Cell.Text>
					</Cell.Wrapper>
				),
				dataKey: "expireAt"
			},
			{
				alignment: "left",
				width: 100,
				label: <Cell.HeaderText># Used</Cell.HeaderText>,
				Cell: ({ rowData: { redemptionUsed, totalRedemption } }) => (
					<Cell.Wrapper>
						<Cell.Text>{totalRedemption ? `${redemptionUsed} / ${totalRedemption}` : redemptionUsed || "-"}</Cell.Text>
					</Cell.Wrapper>
				),
				dataKey: "redemptionUsed"
			},
			{
				alignment: "right",
				width: 160,
				label: "",
				Cell: ({ rowData }) => (
					<Cell.Wrapper className="end">
						{getButton(rowData)}
						<MenuDots options={getOptions(rowData)} vertical removeBg removeshadow removeSideMargin />
					</Cell.Wrapper>
				),
				dataKey: "menu"
			}
		],
		[getButton, icon, getOptions]
	);

	return (
		<Container>
			<Dialog
				title={
					<PopupTitle>{`Delete ${
						isSharableLinkView ? INVITE.DELETE_SHAREABLE_LINK : INVITE.DELETE_QR_CODE
					}?`}</PopupTitle>
				}
				open={open}
				onClose={() => confirmPopupAction(false)}
				footer={ActionFooter}
				hasBackButton={false}
				onBack={() => confirmPopupAction(false)}
				ignorePadding
			>
				<PopupDescription>{`Are you sure you want to permanently delete ${
					isSharableLinkView ? "this link" : "the QR code"
				}?`}</PopupDescription>
			</Dialog>
			<Dialog
				title={
					<PopupTitle>{`Deactivate ${
						isSharableLinkView ? INVITE.DELETE_SHAREABLE_LINK : INVITE.DELETE_QR_CODE
					}?`}</PopupTitle>
				}
				open={openModal}
				onClose={() => confirmDeactivatePopupAction(false)}
				footer={ActionFooter}
				hasBackButton={false}
				onBack={() => confirmDeactivatePopupAction(false)}
				ignorePadding
			>
				<PopupDescription>{`Are you sure you want to deactivate this ${
					isSharableLinkView ? "link" : "QR code"
				}?`}</PopupDescription>
			</Dialog>
			{isLoading && !allShareableLinks.length ? (
				<LoadingTableData>
					<Loader show variant="indeterminate" />
				</LoadingTableData>
			) : (
				<Table
					rowHeight={72}
					columns={tableColumns}
					multigrid
					size="small"
					data={isSharableLinkView ? allShareableLinks : allQRCodes}
					height={setHeightOfTable(3)}
					onLastRowScrolled={loadMore}
				/>
			)}
			<Divider />
			<ButtonWrapper removeBottomMargin flexEnd>
				<CreateLinkButton
					fullWidth
					removeSideMargin
					size="medium"
					palette="primary"
					buttonTheme="light"
					onClick={handleAddNewLink}
				>
					{isSharableLinkView ? INVITE.CREATE_LINK : INVITE.CREATE_QR}
				</CreateLinkButton>
			</ButtonWrapper>
		</Container>
	);
};

export default ShareableLinkList;
