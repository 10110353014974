const validatePassword = (password: string) => {
	if (password.length < 7 && password.length > 21) {
		return "Password length should be between 8 to 20";
	}
	if (!password.match(/\d/)) {
		return "Password must have atleast one number";
	}
	if (!password.match(/[a-z]/) || !password.match(/[A-Z]/)) {
		return "Password must contain upper and lowercase letters";
	}
};

export default validatePassword;
