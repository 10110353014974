import React from "react";

import { GroupModel, PlaceholderImageType } from "shared/types";

import SelectItemTemplate from "../Blocks/SelectItemTemplate";
import { PlaceholderImage } from "../index";

const GroupItem: React.FC<GroupModel & { onSelect: () => void }> = ({
	name,
	private: privateGroup,
	members,
	totalMembers,
	coverUrl,
	onSelect
}) => (
	<SelectItemTemplate
		coverUrl={coverUrl}
		onSelect={onSelect}
		pretitle={privateGroup ? "Private Group" : "Public Group"}
		title={name}
		memberList={members}
		totalMembers={totalMembers}
		placeholderImg={
			<PlaceholderImage type={PlaceholderImageType.GROUP_DETAILS} width={80} height={80} viewBox={"0 0 400 400"} />
		}
		infoBlockClassName="flex-col-reverse"
	/>
);

export default GroupItem;
