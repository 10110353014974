import React, { memo, useEffect, useMemo, useState } from "react";

import { PostBlockEventType } from "types";

import { SeriesCollection } from "modules/Manage/Data/types";
import { useSeries } from "shared/hooks";

import ItemTemplate from "./ItemTemplate";

import { PlaceholderImageType } from "../../types";
import { PlaceholderImage } from "../index";

interface Props {
	collection?: SeriesCollection;
	collectionId?: string;
	id?: string;
	onEvent?: (eventType: PostBlockEventType, id: string) => void;
	maxWidth?: boolean;
	link?: string;
	isMarketing?: boolean;
	postView?: boolean;
}

const CollectionBlock: React.FC<Props> = memo(
	({ collection, collectionId, maxWidth, id, onEvent, link, isMarketing, postView }) => {
		const [collectionInfo, setCollectionInfo] = useState<SeriesCollection | undefined>(collection);
		const [loading, setLoading] = useState(false);
		const [isCollectionDeleted, setIsCollectionDeleted] = useState(false);
		const { getCollectionInfo } = useSeries(isMarketing);

		useEffect(() => {
			if (collectionId) {
				(async () => {
					setLoading(true);
					const data = await getCollectionInfo({ id: collectionId, offset: 1, limit: 10 });
					if (data && !!Object.keys(data).length) {
						setCollectionInfo(data);
					} else {
						setIsCollectionDeleted(true);
					}
					setLoading(false);
				})();
			}
		}, [getCollectionInfo, collectionId]);

		const subtitle = useMemo(() => {
			const videoCount = collectionInfo?.itemIds.filter(x => x.type === "VIDEO").length;
			const trackCount = collectionInfo?.itemIds.filter(x => x.type === "TRACK").length;
			const fileCount = collectionInfo?.itemIds.filter(x => x.type === "FILE").length;
			return `${videoCount ? `${videoCount} Videos ` : ""}${
				trackCount ? `${videoCount ? "| " : ""}${trackCount} Audio ` : ""
			}${fileCount ? `${trackCount || videoCount ? "| " : ""}${fileCount} Files` : ""}`;
		}, [collectionInfo]);

		return (
			<ItemTemplate
				link={link}
				id={id}
				wrapperProps={{
					maxWidth,
					id,
					onEvent,
					postView,
					"data-name": "collection",
					"data-collection": encodeURIComponent(
						JSON.stringify({
							...collectionInfo
						})
					)
				}}
				loading={loading}
				onClose={() => onEvent && id && onEvent(PostBlockEventType.delete, id)}
				coverUrl={collectionInfo?.meta?.artwork?.url}
				title={collectionInfo?.title}
				subtitle={subtitle}
				placeholderImg={
					<PlaceholderImage type={PlaceholderImageType.SERIES_PREVIEW} width={80} height={80} viewBox={"0 0 400 400"} />
				}
				isDeleted={isCollectionDeleted}
			/>
		);
	}
);

export default CollectionBlock;
