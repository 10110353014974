import React, { FC } from "react";

import { useCommunity } from "shared/hooks";

import { InPlaceModal, InPlaceModalProps, Loader, Text } from "shared/ui-kit";

import {
	CancelButton,
	ConfirmButton,
	ConfirmFooter,
	ConfirmHeader,
	StyledDivider,
	StyledParagraphText,
	StyledPopupOverlay
} from "./style";

interface Props extends InPlaceModalProps {
	open: boolean;
}

interface ConfirmActionModalProps {
	open: boolean;
	title?: string;
	bodyText?: string;
	closeText?: string;
	confirmText?: string;
	onClose: () => void;
	onConfirm?: () => void;
	confirming?: boolean;
	reverseButtons?: boolean;
	confirmButtonTheme?: "primary" | "danger";
	cancelButtonTheme?: "primary" | "secondary";
	confirmButtonId?: string;
}

const ConfirmLeavePopupWrapper: FC<Props> = ({ open, children, onClose, modalHeight, ...props }) => (
	<InPlaceModal {...props} onClose={onClose} maxWidth="xs" open={open} modalHeight={modalHeight}>
		{children}
	</InPlaceModal>
);

const ConfirmActionModal: FC<ConfirmActionModalProps> = ({
	open,
	title,
	bodyText,
	closeText,
	confirmText,
	onClose,
	onConfirm,
	confirming,
	reverseButtons = false,
	confirmButtonTheme = "primary",
	cancelButtonTheme = "secondary",
	confirmButtonId = ""
}) => {
	const { communityColors } = useCommunity();
	return (
		<StyledPopupOverlay open={open}>
			<ConfirmLeavePopupWrapper modalHeight={600} open={open} onClose={onClose}>
				<ConfirmHeader>
					<Text variant="h6">{title || "Confirm Action"}</Text>
				</ConfirmHeader>
				{bodyText && <StyledParagraphText variant="body1">{bodyText}</StyledParagraphText>}
				<StyledDivider thickness={0.5} borderColor="#c5cee0" />
				<ConfirmFooter container justify="flex-end" direction={reverseButtons ? "row-reverse" : "row"}>
					<CancelButton
						color={communityColors.primary}
						onClick={onClose}
						disabled={confirming}
						buttonTheme={cancelButtonTheme}
					>
						{closeText || "Close"}
					</CancelButton>
					<ConfirmButton
						onClick={() => onConfirm && onConfirm()}
						disabled={confirming}
						buttonTheme={confirmButtonTheme}
						id={confirmButtonId}
						color={communityColors.primary}
					>
						{confirming ? <Loader show variant="indeterminate" size="20px" /> : confirmText || "Confirm"}
					</ConfirmButton>
				</ConfirmFooter>
			</ConfirmLeavePopupWrapper>
		</StyledPopupOverlay>
	);
};

export default ConfirmActionModal;
