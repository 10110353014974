import { DateTime } from "luxon";

import { times } from "shared/types/times";

export const getDefaultTime = (end?: boolean, date?: string): { label: string; value: string } => {
	if (date) {
		const hour = DateTime.fromISO(date).get("hour");
		const minutes = DateTime.fromISO(date).get("minute");

		return times
			.filter(time => time.value.split(":")[0] === `${hour}`)
			.reduce((a, b) => {
				return Math.abs(parseInt(b.value.split(":")[1]) - minutes) < Math.abs(parseInt(a.value.split(":")[1]) - minutes)
					? b
					: a;
			});
	}

	const today = new Date();

	let hours = today.getHours() + (end ? 3 : 2);
	if (hours >= 24) {
		hours -= 24;
	}

	const currentTime = `${hours.toString().length === 1 ? `0${hours}` : hours}:${today.getMinutes()}`;

	return times
		.filter(time => time.value.split(":")[0] === currentTime.split(":")[0])
		.reduce((a, b) => {
			return Math.abs(parseInt(b.value.split(":")[1]) - today.getMinutes()) <
				Math.abs(parseInt(a.value.split(":")[1]) - today.getMinutes())
				? b
				: a;
		});
};

export const getDefaultDate = (end?: boolean): Date => {
	const today = new Date();

	const hours = today.getHours() + (end ? 3 : 2);
	if (hours >= 24) {
		const tomorrow = new Date();
		tomorrow.setDate(today.getDate() + 1);
		return tomorrow;
	}

	return today;
};
