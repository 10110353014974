import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import styled from "styled-components";

import { Box, Input, Text } from "shared/ui-kit";

export const Container = styled(Box)`
	display: flex;
	flex-direction: column;
`;

export const CardWrapper = styled(Box)`
	border-radius: 4px;
	background-color: #ffffff;

	border: 1px solid rgba(197, 206, 224, 0.5);

	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);

	padding-top: 25px;
	margin-bottom: 16px;

	max-width: 672px;
`;

export const BalanceBlock = styled(Box)`
	display: flex;
	flex-direction: column;
`;

export const RowWrapper = styled(Box)`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start;
`;

export const FieldWrapper = styled(Box)`
	display: flex;
	flex-direction: column;
	margin-right: 24px;
	margin-left: 24px;
`;

export const ColumnWrapper = styled(Box)<{ half?: boolean }>`
	display: flex;
	flex-direction: column;

	width: ${({ half }) => (half ? "47%" : "100%")};
`;

export const StyledText = styled(Text)`
	color: #222b45;
	font-size: 15px;
`;

export const BalanceText = styled(Text)`
	font-weight: 600;
	color: #222b45;
	font-size: 15px;
	margin-left: 8px;
`;

export const StyledTitle = styled(Text)`
	margin-bottom: 40px;
`;

export const BoldText = styled(Text)`
	font-size: 18px;
	font-weight: bold;
	color: #222b45;
	margin-bottom: 10px;
`;

export const StyledSubTitle = styled(Text)`
	margin-bottom: 10px;
	font-weight: 600;
	font-size: 15px;
	color: #222b45;
	margin-top: 20px;
`;

export const TextWrapper = styled(Box)`
	display: flex;
	flex-direction: row;
	flex: 1;
`;

export const PendingTextWrapper = styled(Box)`
	color: ${({ theme }) => theme.palette.solidWarning.main};
`;

export const ButtonWrapper = styled(Box)`
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
`;

export const FormWrapper = styled.form`
	width: 100%;
`;

export const StyledInput = styled(Input)`
	max-width: 440px;
`;

export const ErrorText = styled(Text)`
	color: red;
`;

export const BalanceAccordion = styled(Accordion)`
	width: 100%;

	box-shadow: none;
`;

BalanceAccordion.Summary = styled(AccordionSummary)`
	.MuiAccordionSummary-content {
		margin: 0;
	}

	&.Mui-expanded {
		min-height: auto;
	}
`;

BalanceAccordion.ArrowIconWrapper = styled.div`
	svg {
		path {
			fill: #8f9bb3;
		}
	}
`;

BalanceAccordion.Details = styled(AccordionDetails)`
	margin: 0;
	padding: 0;
`;

export const FeesTable = styled.div`
	border-radius: 4px;

	border: 1px solid #d2d9e7;

	overflow: hidden;
`;

FeesTable.Row = styled.div`
	padding: 20px 0;

	display: flex;
	justify-content: space-between;
	align-items: center;

	&:not(:last-of-type) {
		border-bottom: 1px solid rgba(197, 206, 224, 0.5);
	}
`;

FeesTable.Text = styled(Text)`
	font-size: 15px;
	line-height: 22px;
	color: #1d2844;
`;

FeesTable.Footer = styled.div`
	background-color: #f6f7f9;

	border-top: 1px solid rgba(197, 206, 224, 0.5);

	display: flex;
	justify-content: space-between;
	align-items: center;

	padding: 20px 16px;
`;
