import { Box } from "@material-ui/core";
import styled, { css } from "styled-components";

import { Text } from "shared/ui-kit";

export const ModalOverlay = styled(Box)`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	background-color: rgba(25, 36, 53, 0.1);
	z-index: 1401;
	.MuiDialogContent-root:first-child {
		padding-top: 0;
	}
	.MuiDialog-scrollPaper {
		height: 100vh;
	}

	${props => props.theme.breakpoints.down("sm")} {
		.MuiDialog-paperScrollPaper {
			margin: 0;
			width: calc(100% - 32px);
		}
	}
`;

export const ModalHeader = styled(Box)`
	padding: 16px;
	text-align: center;
	position: relative;
	border-bottom: 1px solid #edf1f7;
	h6 {
		font-size: 18px;
	}
	.close {
		position: absolute;
		top: 0;
		right: 16px;
		height: 100%;
		display: flex;
		align-items: center;
	}
`;

export const ModalBody = styled(Box)`
	padding: 16px 0;

	&.award-points {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
	}
`;

ModalBody.SelectUser = styled(Box)`
	width: calc(100% - 150px);

	.MuiIconButton-label {
		display: none;
	}

	.MuiFormControl-root {
		position: relative;
		top: -3px;
	}

	.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
		padding: 6px 9px 8px 9px;
	}
`;

ModalBody.ChoosePoints = styled(Box)`
	width: 134px;
`;

ModalBody.Heading = styled(Text)`
	font-size: 15px;
	font-weight: 600;
	line-height: 1.6;
	color: #222b45;

	margin-bottom: 12px;
`;

ModalBody.ChoosePointsInputWrapper = styled(Box)`
	position: relative;
`;

export const ModalFooter = styled(Box)`
	border-top: 1px solid #edf1f7;
	background-color: white;
	padding: 12px;
	position: sticky;
	bottom: 0;
	button {
		width: 100%;
		margin: 0;
	}
	${props => props.theme.breakpoints.up("sm")} {
		display: flex;
		justify-content: flex-end;
		padding: 16px;
		button {
			width: auto;
		}
	}
`;

export const OptionWrapper = styled(Box)`
	display: flex;
	align-items: center;
	img {
		width: 24px;
		height: 24px;
		border-radius: 99%;
		object-fit: cover;
	}
	p {
		font-size: 15px;
		line-height: 1.33;
		color: #222b45;
		margin-left: 6px;
	}
	${({ disabled }) =>
		disabled &&
		css`
			cursor: not-allowed;
		`}
`;

export const TagWrapper = styled(OptionWrapper)`
	border-radius: 16px;
	background-color: #edf1f7;
	padding: 4px 6px;
	margin-left: 4px;
	margin-top: 4px;
	p {
		padding: 0 4px;
		font-size: 12px;
	}
	&:hover {
		background-color: #dde1e6;
		cursor: pointer;
	}
`;

export const RewardHistoryWrapper = styled(Box)`
	display: flex;
	align-items: flex-start;
	justify-content: space-between;

	.member-name {
		font-size: 22px;
		font-weight: bold;
		line-height: 1.45;
		color: #222b45;
	}
`;

RewardHistoryWrapper.PointsWrapper = styled(Box)`
	margin-top: 24px;
	display: flex;
	align-items: center;
	> div {
		margin-right: 8px;
	}
`;

RewardHistoryWrapper.PointBox = styled(Box)`
	p {
		font-size: 12px;
		font-weight: 500;
		line-height: 1.33;
		color: #8f9bb3;
		margin-bottom: 8px;
	}
`;

export const RewardsHistoryList = styled(Box)`
	margin: 40px 0;
	max-height: 260px;
	overflow-y: auto;
`;

RewardsHistoryList.LoadingItems = styled(Box)``;

RewardsHistoryList.ListItem = styled(Box)`
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	padding: 12px 0;
	border-top: 1px solid #edf1f7;
	border-bottom: 1px solid #edf1f7;
	.left-side,
	.right-side {
		display: flex;
		align-items: center;
		width: 100%;
	}
	.left-side {
		margin-bottom: 12px;
		.skeleton-text {
			margin-left: 12px;
		}
		.event-description {
			margin-left: 12px;
			font-size: 15px;
			font-weight: 600;
			line-height: 1.6;
			color: #222b45;
		}
		.event-description-second {
			margin-left: 12px;
			font-size: 13px;
			line-height: 1.23;
			color: #8f9bb3;
		}
	}
	.right-side {
		justify-content: space-between;
		.date {
			font-size: 13px;
			line-height: 1.23;
			color: #8f9bb3;
		}
		.points {
			display: flex;
			align-items: center;
			font-size: 15px;
			font-weight: 600;
			line-height: 1.07;
			svg {
				margin-left: 8px;
				width: 16px;
				height: 16px;
			}
			.minus {
				color: #ff463d;
			}
			.plus {
				color: #23b34a;
			}
		}
	}
	.icon-wrapper {
		height: 40px;
		width: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 8px;
		background-color: #f7f9fc;
		svg {
			fill: #8f9bb3;
		}
	}

	${props => props.theme.breakpoints.up("sm")} {
		.left-side,
		.right-side {
			width: 50%;
		}
		.left-side {
			margin-bottom: 0;
		}
	}
`;

export const CellWrapper = styled(Box)`
	display: flex;
	align-items: center;
`;

CellWrapper.IconWrapper = styled(Box)`
	width: 40px;
	height: 40px;

	border-radius: 4px;

	margin-right: 16px;

	overflow: hidden; ;
`;

CellWrapper.Index = styled(Text)`
	font-size: 13px;
	line-height: 1.23;
	color: #222b45;
	&.index {
		width: 26px;
	}
`;

CellWrapper.InfoWrapper = styled(Box)`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
`;

CellWrapper.RewardInfo = styled(Box)`
	${({ noFlexOnMobile }) =>
		!noFlexOnMobile
			? css`
					display: flex;
					align-items: center;
			  `
			: css`
					${props => props.theme.breakpoints.up("sm")} {
						margin-top: 0;
						display: flex;
						align-items: center;
					}
			  `}
	img,
	.no-profile {
		width: ${({ largeImg }) => (largeImg ? "160px" : "40px")};
		height: ${({ largeImg }) => (largeImg ? "160px" : "40px")};
		border-radius: ${({ largeImg }) => (largeImg ? "16px" : "4px")};
		object-fit: cover;
		margin-left: 4px;
		${({ noFlexOnMobile }) =>
			noFlexOnMobile &&
			css`
				margin: 0;
				margin-bottom: 20px;
				${props => props.theme.breakpoints.up("sm")} {
					margin: 0;
					margin-right: 24px;
				}
			`}
	}
	.chip {
		padding: 4px 8px;
		border-radius: 16px;
		font-size: 10px;
		font-weight: 600;
		text-align: center;
		margin-top: 6px;
		&.free {
			color: #222b45;
			background-color: #f7f9fc;
		}
		&.premium {
			background-color: #ebecff;
			color: #6173fe;
		}
	}
`;

CellWrapper.PointsAmount = styled(Box)`
	position: relative;
	width: 120px;
	height: 40px;
	padding: 8px 16px;
	border-radius: 4px;
	background-color: #f7f9fc;
	.shield-box {
		position: absolute;
		height: 100%;
		right: 16px;
		top: 0;
		display: flex;
		align-items: center;
	}
`;

CellWrapper.MoreActionsWrapper = styled(Box)`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	button {
		font-size: 12px;
		font-weight: bold;
		line-height: 1.33;
	}
`;
