import { ItemId } from "modules/Manage/Data/types/SeriesTypes";
import { TrackModel } from "shared/types";
import { IStateProps } from "utils/StateOf";

import createStore from "utils/createStore";

export interface Values {
	playTracks: {
		idx: number;
		tracks?: {
			albumId: string;
			items: TrackModel[];
		};
		series?: {
			id: string;
			items: ItemId[];
		};
	};
	play: boolean;
	shuffleTracks: boolean;
	repeatTrack: boolean;
	showVoiceControl: boolean;
	volume: number;
	albumPlaying?: string;
}

export const initialState: IStateProps<Values> = {
	playTracks: {
		idx: 0
	},
	play: false,
	shuffleTracks: false,
	repeatTrack: false,
	showVoiceControl: false,
	volume: 50
};

export default createStore<Values>({
	initialState
});
