import React, { FC, memo, useCallback } from "react";

import Slider from "@material-ui/core/Slider";

import { makeStyles } from "@material-ui/core/styles";

import { usePlayer } from "shared/hooks";

import { SoundControlWrapper } from "./style";

const useStyles = makeStyles({ root: { height: 150 } });
const useSliderStyles = makeStyles({ root: { color: "#6173fe" } });

interface Props {
	muteSound: () => void;
	playSound: () => void;
	muted: boolean;
}

const SoundControl: FC<Props> = ({ muteSound, playSound, muted }) => {
	const { setVolume, getData: getPlayerData } = usePlayer();
	const { volume } = getPlayerData();

	const classes = useStyles();
	const { root } = useSliderStyles();

	const volumeChangeHandler = useCallback(
		(e, val) => {
			if (val === 0 && !muted) muteSound();
			if (val > 0 && muted) playSound();
			setVolume(val as number);
		},
		[muted, muteSound, playSound, setVolume]
	);

	return (
		<SoundControlWrapper className={classes.root}>
			<Slider
				className={root}
				orientation="vertical"
				value={volume}
				getAriaValueText={() => ""}
				aria-labelledby="vertical-slider"
				onChange={volumeChangeHandler}
			/>
		</SoundControlWrapper>
	);
};

export default memo(SoundControl);
