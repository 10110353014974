import { Box } from "@material-ui/core";
import styled from "styled-components";

export const ModalOverlay = styled(Box)`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	background-color: rgba(25, 36, 53, 0.4);
	z-index: 3000;
	.MuiDialog-container {
		height: 100vh;
	}
	.MuiDialogContent-root {
		&:first-child {
			padding: 0;
		}
	}
	.MuiDialog-paperWidthMd {
		border-radius: 16px;
		background-image: linear-gradient(to left bottom, #4a99f9, #368bfd, #2b7cff, #306bfe, #4157fb);
		background-color: #4157fb;
		max-width: 740px;
		overflow: hidden;
		padding: 26px 40px;
		color: white;
		a {
			color: white;
		}
		${props => props.theme.breakpoints.up("sm")} {
			max-height: 280px;
		}
	}
`;

export const DownloadCompletedText = styled(Box)`
	z-index: 10;
	h5 {
		margin-bottom: 20px;
	}
	.mb {
		margin-bottom: 8px;
	}
	button {
		margin-left: 0;
		border-radius: 8px;
		margin-top: 26px;
		width: 120px;
		height: 40px;
	}

	${props => props.theme.breakpoints.up("sm")} {
		width: 50%;
	}
`;

export const AlbumCovers = styled(Box)`
	position: absolute;
	top: -105px;
	right: -30px;
	width: 40%;
	transform: rotate(340deg);
	img {
		width: 144px;
		height: 144px;
		margin-right: 16px;
		border-radius: 8px;
		border: 1px solid white;
		object-fit: cover;
		&:nth-child(odd) {
			margin-top: -24px;
		}
	}
`;
