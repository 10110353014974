import { Avatar, Box, Chip } from "@material-ui/core";

import Autocomplete from "@material-ui/lab/Autocomplete";
import styled from "styled-components";

import { Button, Loader, Text } from "shared/ui-kit";

export const ChipsContainer = styled(Box)`
	padding: 10px 0;
	display: flex;
	gap: 12px;
	flex-wrap: wrap;
`;

export const StyledOption = styled(Box)`
	height: 40px;
	padding: 8px 12px;
	display: flex;
	gap: 8px;
	align-items: center;

	.fake-input {
		width: fit-content;
		min-width: 200px;
		border: none;
		pointer-events: none;

		.MuiAutocomplete-inputRoot {
			padding: 0;

			&:before,
			&:after {
				display: none;
			}

			input {
				padding: 0;
				user-select: none;
				pointer-events: none;
			}

			.MuiAutocomplete-endAdornment {
				display: none;
			}
		}
	}
`;

export const StyledOptionAvatar = styled(Avatar)`
	height: 24px;
	width: 24px;
`;

export const StyleOptionText = styled(Box)`
	font-size: 15px;
	line-height: 1.33;
	color: #222b45;
`;

export const StyledAutocomplete = styled(Autocomplete)`
	.MuiAutocomplete-tag.MuiChip-outlined {
		border: none !important;
	}
`;

export const StyledLoader = styled(Loader)`
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 12px;
`;

export const StyledChip = styled(Chip)`
	max-width: 180px;
	justify-content: space-between;
	background-color: #f7f9fc;
	.MuiChip-label {
		font-size: 15px;
	}
	.MuiChip-deleteIcon {
		color: #8f9bb3;
	}
`;

export const FormWrapper = styled.form``;

export const InfoWrapper = styled(Box)`
	margin-bottom: 20px;
	padding-left: 88px;
	padding-right: 32px;

	${props => props.theme.breakpoints.down("sm")} {
		padding: 20px;
		margin: 0;
	}
`;

export const FeatureLabel = styled(Box)`
	font-size: 15px;
	font-weight: 600;
	line-height: 1.6;
	color: #222b45;
	margin-bottom: 10px;
`;

export const AutoConnectMainContainer = styled(Box)`
	width: 100%;
	display: flex;

	${props => props.theme.breakpoints.down("sm")} {
		flex-direction: column;
	}
`;

export const InputContainer = styled(Box)`
	width: 60%;
	display: flex;
	flex-direction: column;

	${props => props.theme.breakpoints.down("sm")} {
		width: 100%;
	}
`;

export const ButtonContainer = styled(Box)`
	width: 40%;
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
	${props => props.theme.breakpoints.down("sm")} {
		width: 100%;
	}
`;

export const SaveButton = styled(Button)`
	font-size: 16px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	padding: 17px 42px;
	max-height: 48px;
`;

export const MessageLabel = styled(FeatureLabel)`
	margin-top: 24px;
`;

export const PageContainer = styled.main`
	width: 100%;
	position: relative;

	padding: 40px 140px;

	${props => props.theme.breakpoints.down("sm")} {
		padding: 0;
	}
`;

export const Title = styled(Text)`
	width: 100%;
	position: relative;

	margin-bottom: 20px;
`;

export const FeatureList = styled(Box)`
	width: 100%;
	padding: 40px;
	border-radius: 4px;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
	background-color: #ffffff;

	${props => props.theme.breakpoints.down("sm")} {
		padding: 20px;
	}
`;

export const FeatureWrapper = styled(Box)`
	width: 100%;
	margin-bottom: 16px;

	border-radius: 4px;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
	box-shadow: inset 0 0 0 0.5px #c5cee0;
	background-color: #ffffff;
`;

export const PopupTitle = styled(Text)`
	font-size: 18px;
	font-weight: bold;
	line-height: 1.33;
	color: #222b45;
	text-align: left;

	padding-left: 16px;
`;

export const Description = styled(Text)`
	font-size: 15px;
	font-style: normal;
	line-height: 1.47;
	color: #8f9bb3;
`;

export const MessageOwner = styled(Box)`
	display: flex;
	align-items: center;
	justify-content: space-between;

	background: white;

	padding: 0 8px;
	border: 1px solid #e4e9f2;
	border-radius: 4px;

	width: 100%;
	height: 54px;
`;
