import React, { FC, useEffect, useState } from "react";

import { useHistory } from "react-router";

import { useAlbums, useMemberRoutes } from "shared/hooks";
import { Button, Text } from "shared/ui-kit";

import Album from "./Album";

import { Container } from "./style";

import { Slider } from "..";

const MoreAlbums: FC<{ id: string }> = ({ id }) => {
	const history = useHistory();
	const { getAlbums, getData: getAlbumsData } = useAlbums();
	const { albums } = getAlbumsData();

	const { getMemberRoutesData } = useMemberRoutes();
	const { routes } = getMemberRoutesData();

	const [offset, setOffset] = useState(1);

	useEffect(() => {
		getAlbums({ limit: 8, offset, paginate: true });
	}, [getAlbums, offset]);

	if (!albums.length || albums.length === 1) return null;

	return (
		<Container>
			<Container.SliderHeader>
				<Text variant="h5">More Albums</Text>
				<Button palette="basic" buttonTheme="light" onClick={() => history.push(routes?.member.albums.getPath())}>
					See All
				</Button>
			</Container.SliderHeader>
			<Container.SliderWrapper>
				<Slider hideFrom={3} itemSize={266} count={albums.length} onEndScroll={() => setOffset(ctx => ctx + 1)}>
					{albums
						.filter(al => al._id !== id)
						.map(album => (
							<Album album={album} key={album._id} />
						))}
				</Slider>
			</Container.SliderWrapper>
		</Container>
	);
};

export default MoreAlbums;
