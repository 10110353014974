import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";

import { ConfirmActionModal } from "shared/Components";
import { STORIES_OPEN_TYPE } from "shared/contexts/StoryContext/StoryContext";
import { useConnection, useNotification, useStory, useUser } from "shared/hooks";
import { PersonStoryModel, StoryModel } from "shared/types";
import { Icon } from "shared/ui-kit";

import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import AdStory from "./AdStory";
import MatchesActions from "./MatchesActions";
import RecommendedMatchesActions from "./RecommendedMatchesActions";
import RemoveConnectionDialog from "./RemoveConnectionDialog";
import Story from "./Story";
import StoryCommentInput from "./StoryCommentInput";
import {
	ArrowIconButton,
	CloseIcon,
	SlideControlButtons,
	SliderWrapper,
	StoriesOverlay,
	StoriesWrapper
} from "./style";

export interface ConnectedType {
	id: number;
	preferenceId?: string;
}

const Stories: FC<{
	personsStoryList: (string | PersonStoryModel)[];
	handleEndScroll?: () => false | void;
	onSliderClose?: (personaIds: number[]) => void;
	filteredStories: number[];
	filterMatch?: (personaId: number) => void;
	updateStories?: React.Dispatch<React.SetStateAction<PersonStoryModel[]>>;
}> = ({ personsStoryList, handleEndScroll, onSliderClose, filteredStories, filterMatch, updateStories }) => {
	const { setIsOpen, watchStory, setCurrentStorySlide, setReportDialog, getData: getStoryData } = useStory();
	const { isOpen, defaultStoryId, reportDialog, currentStorySlide, removePersonConnection, slideId } = getStoryData();
	const { getActiveProfile, getData: getUserData } = useUser();
	const { user } = getUserData();

	const activeProfile = useMemo(() => getActiveProfile(user), [getActiveProfile, user]);

	const sliderRef = useRef<any>(null);

	const [showSlider, setShowSlider] = useState(false);
	const [showOverlay, setShowOverlay] = useState(true);
	const [currentSlide, setCurrentSlide] = useState(0);
	const [showControls, setShowControls] = useState(true);
	const [connectUser, setConnectUser] = useState<{
		loading: boolean;
		connected: ConnectedType[];
	}>({
		loading: false,
		connected: []
	});
	const [storiesList, setStoriesList] = useState<(string | PersonStoryModel)[]>(personsStoryList);

	const { report } = useConnection();
	const { showMessage } = useNotification();

	useEffect(() => {
		setTimeout(() => setShowSlider(true), 300);
	}, []);

	useEffect(() => {
		if (filteredStories.length > 0) {
			setStoriesList(
				personsStoryList.filter(story => !filteredStories.some(s => typeof story !== "string" && s === story.personaId))
			);
		}
	}, [filteredStories, personsStoryList]);

	const settings = useMemo(
		() => ({
			accessibility: false,
			className: "center",
			centerMode: true,
			centerPadding: "60px",
			slidesToShow: storiesList.length > 3 ? 3 : 1,
			slidesToScroll: 1,
			swipeToSlide: true,
			focusOnSelect: true,
			speed: 900,
			easing: "ease-in",
			arrows: false,
			initialSlide: 0,
			beforeChange: (oldIndex: number, newIndex: number) => {
				if (oldIndex === newIndex) return;
				const index = storiesList.findIndex(x =>
					typeof x !== "string" ? x.stories.some(s => s._id === defaultStoryId) : -1
				);
				if (newIndex === index) {
					setShowControls(true);
				} else {
					setShowControls(false);
				}
				setCurrentSlide(newIndex);
				setCurrentStorySlide(0);
			},
			afterChange: index => {
				setShowControls(true);
				index + 1 === storiesList.length - 3 && handleEndScroll && handleEndScroll();
			},
			variableWidth: true,
			infinite: storiesList.length > 3
		}),
		[defaultStoryId, handleEndScroll, storiesList, setCurrentStorySlide]
	);

	useEffect(() => {
		if (isOpen && defaultStoryId && sliderRef && sliderRef.current) {
			setStoriesList(items => {
				const index = items.findIndex(x =>
					typeof x === "string" ? -1 : x.stories.some(s => s._id === defaultStoryId)
				);
				return [...items.filter((_, i) => i >= index), ...items.slice(0, index)];
			});
		}
	}, [defaultStoryId, isOpen, updateStories]);

	const currentStory = useMemo(() => storiesList[currentSlide], [currentSlide, storiesList]);

	useEffect(() => {
		if (slideId && !!storiesList.length) {
			setCurrentStorySlide(
				typeof currentStory === "string" ? 0 : currentStory.stories.findIndex(s => s._id === slideId) || 0
			);
		}
	}, [currentStory, setCurrentStorySlide, slideId, storiesList.length]);

	const handleSlideLeft = useCallback(() => {
		if (currentStorySlide > 0) {
			setCurrentStorySlide(currentStorySlide - 1);
		} else {
			setCurrentStorySlide(0);
			sliderRef.current.slickPrev();
		}
	}, [currentStorySlide, setCurrentStorySlide]);

	const handleClose = useCallback(() => {
		setShowSlider(false);

		setShowOverlay(false);

		setTimeout(() => {
			setConnectUser({ loading: false, connected: [] });
			setIsOpen();
			setCurrentStorySlide(0);
			onSliderClose && onSliderClose(connectUser.connected.map(c => c.id));
		}, 400);
	}, [connectUser.connected, onSliderClose, setCurrentStorySlide, setIsOpen]);

	const handleSlideRight = useCallback(() => {
		if (
			currentStorySlide <
			(typeof storiesList[currentSlide] === "string"
				? -1
				: (storiesList[currentSlide] as PersonStoryModel).stories.length - 1)
		) {
			setCurrentStorySlide(currentStorySlide + 1);
		} else {
			setCurrentStorySlide(0);
			sliderRef.current.slickNext();
		}
	}, [currentStorySlide, storiesList, currentSlide, setCurrentStorySlide]);

	const handleKeyDown = e => {
		if (e.key === "ArrowRight") {
			handleSlideRight();
		}
		if (e.key === "ArrowLeft") {
			handleSlideLeft();
		}
		if (e.key === "Escape") {
			handleClose();
		}
	};

	const handleWatchStory = useCallback(
		(story: StoryModel) => {
			if (
				isOpen === STORIES_OPEN_TYPE.STORIES &&
				story &&
				!story.watched &&
				parseInt(story.personaId) !== activeProfile?.personaId
			) {
				watchStory(story.personaId, story._id, story.type);
			}
		},
		[activeProfile?.personaId, isOpen, watchStory]
	);

	const showPrevArrow = useMemo(
		() => currentSlide > 0 || (currentSlide === 0 && currentStorySlide > 0),
		[currentSlide, currentStorySlide]
	);

	const showNextArrow = useMemo(
		() =>
			!!storiesList.length &&
			(currentSlide < storiesList.length - 1 ||
				currentStorySlide <
					(typeof storiesList[currentSlide] === "string"
						? -1
						: (storiesList[currentSlide] as PersonStoryModel).stories.length - 1)),
		[currentSlide, storiesList, currentStorySlide]
	);

	const handleReportUser = useCallback(async () => {
		if (!reportDialog) return;
		await report({ ...reportDialog });

		showMessage("Story successfully reported.");
		setReportDialog();
		handleSlideRight();
	}, [handleSlideRight, report, reportDialog, showMessage, setReportDialog]);

	const filteredStoriesNoAds = useMemo(() => storiesList.filter(s => typeof s !== "string"), [storiesList]);

	const storiesJSX = useMemo(
		() =>
			storiesList.map((story, i) =>
				typeof story === "string" ? (
					<AdStory key={i} personStory={story} isActive={i === currentSlide} onEndStory={handleSlideRight} />
				) : (
					<Story
						key={typeof story === "string" ? i : story._id}
						personStory={story}
						isActive={i === currentSlide}
						onStartStory={handleWatchStory}
						onEndStory={handleSlideRight}
					/>
				)
			),
		[currentSlide, handleSlideRight, handleWatchStory, storiesList]
	);

	return (
		<StoriesOverlay onKeyDown={handleKeyDown} className={showOverlay && "showOverlay"}>
			<CloseIcon onClick={handleClose}>
				<Icon group={"filled"} name={"close"} fill={"white"} />
			</CloseIcon>
			{removePersonConnection && <RemoveConnectionDialog />}
			{reportDialog && (
				<ConfirmActionModal
					open={true}
					onClose={() => setReportDialog()}
					title="Report Story?"
					bodyText={`Are you sure you want to report this story for ${reportDialog.reason}?`}
					onConfirm={handleReportUser}
				/>
			)}
			<StoriesWrapper className={showSlider && "showSlider"}>
				<SlideControlButtons className={showControls && "visible"}>
					<ArrowIconButton size="small" onClick={handleSlideLeft} show={showPrevArrow}>
						<Icon name="arrow-left" height={16} width={16} group="filled" fill="white" />
					</ArrowIconButton>
					<ArrowIconButton size="small" onClick={handleSlideRight} show={showNextArrow}>
						<Icon name="arrow-right" height={16} width={16} group="filled" fill="white" />
					</ArrowIconButton>
				</SlideControlButtons>
				<SliderWrapper {...settings} ref={sliderRef} centerStory={storiesList.length === 1}>
					{storiesJSX}
				</SliderWrapper>
				{isOpen === STORIES_OPEN_TYPE.MATCHES && storiesList[currentSlide] !== "string" && (
					<MatchesActions
						showControls={showControls}
						personsStoryList={filteredStoriesNoAds as PersonStoryModel[]}
						currentSlide={currentSlide}
						filteredStories={filteredStories}
						filterMatch={filterMatch}
						displayPosition={currentSlide + 1}
						handleSlideRight={handleSlideRight}
						connectUser={connectUser}
						setConnectUser={setConnectUser}
					/>
				)}
				{isOpen === STORIES_OPEN_TYPE.RECOMMENDED_MATCHES && (
					<RecommendedMatchesActions
						showControls={showControls}
						personsStoryList={filteredStoriesNoAds as PersonStoryModel[]}
						currentSlide={currentSlide}
						displayPosition={currentSlide + 1}
						connectUser={connectUser}
						setConnectUser={setConnectUser}
					/>
				)}
				{isOpen === STORIES_OPEN_TYPE.STORIES &&
					typeof currentStory !== "string" &&
					currentStory.personaId !== activeProfile?.personaId && (
						<StoryCommentInput showControls={showControls} currentStory={currentStory} />
					)}
			</StoriesWrapper>
		</StoriesOverlay>
	);
};

export default Stories;
