import styled from "styled-components";

const Wrapper = styled.div`
	height: 100%;
	display: flex;
	flex-direction: column;
`;

const CardList = styled.div`
	> * {
		margin-right: 8px;

		&:last-child {
			margin-right: 0;
		}
	}
`;

const Form = styled.form`
	margin-top: 20px;
	flex: 1;
	display: flex;
	flex-direction: column;
`;

const FormField = styled.div`
	margin-bottom: 26px;
`;

const FormTwoColumns = styled.div`
	display: flex;
	justify-content: space-between;
	gap: 16px;

	> * {
		flex: 1;
	}
`;

const FormActions = styled.div`
	display: flex;
	justify-content: space-between;
	margin-top: auto;
`;

const LockIconWrapper = styled.div`
	width: 24px;
	height: 24px;
	display: flex;
	align-items: center;
	justify-content: center;

	svg {
		fill: #c5cee0 !important;
	}
`;

export { Wrapper, CardList, Form, FormField, FormTwoColumns, FormActions, LockIconWrapper };
