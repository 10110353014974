import { useMemo } from "react";

import { API_URLS } from "shared/constants";
import useHttpActions from "shared/services/api/core/useHttpActions";
import { BaseConverter } from "shared/services/converters";

import useMediaApiService from "shared/services/useMediaApiService";

import { MediaType } from "shared/types";

import { convertPaymentForRequest } from "./converters/PaymentConverter";

import { PaymentModel } from "../types";

const usePaymentApiService = () => {
	const actions = useHttpActions();
	const mediaService = useMediaApiService();

	return useMemo(
		() => ({
			createPayment: async (data: PaymentModel) => {
				const defaultQuery = {
					personaId: data.personaId as number,
					expireAt: "infinite" as number | "infinite"
				};

				const paymentMedia =
					data.imageUrl &&
					(await mediaService.createMedia({
						...defaultQuery,
						type: MediaType.coverArt,
						uri: data.imageUrl
					}));

				return (
					BaseConverter.convert(
						await actions.post(
							API_URLS.PAYMENT.CREATE,
							convertPaymentForRequest(data, paymentMedia ? paymentMedia[0] : null)
						)
					) as any
				).payment;
			}
		}),
		[actions, mediaService]
	);
};

export default usePaymentApiService;
