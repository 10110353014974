import React, { memo, useCallback, useEffect, useMemo, useState } from "react";

import { DateTime } from "luxon";

import { CreateVideo, MenuDots, PlaceholderImage, TableWrapper } from "shared/Components";
import { TableColumnLoaderType } from "shared/Components/NewTable/Components";
import { Cell } from "shared/Components/NewTable/style";
import { useDebounce, useNotification, usePremiumContent, useVideo } from "shared/hooks";
import { useS3Uploader } from "shared/services/s3Uploader/hooks";
import { PlaceholderImageType, VideoModel } from "shared/types";
import { getValidationMessage } from "utils/getValidationMessage";
import { dataUrlToFile, filterFiles, formatCount, secondsToHumanTime } from "utils/serviceUtils/helpers";

import { ConfirmDelete, PageTemplate } from "../../Components";

const Table = React.lazy(() => import("shared/Components/NewTable"));

const correctFileTypes = ["video/*"];

const ManageVideos: React.FC = memo(() => {
	const {
		getVideos,
		deleteVideo,
		getVideoCount,
		getAllVideoCount,
		setPage,
		setVideosShowPerPage,
		setVideoName,
		setVideoInfoPopup,
		setVideoFileUrl,
		setVideoDuration,
		getData: getVideoData
	} = useVideo();
	const {
		filteredVideos,
		loading,
		videoCount,
		allVideoCount,
		videoUploadUrl,
		page,
		videosShowPerPage,
		videoInfoPopup
	} = getVideoData();

	const { showMessage } = useNotification();
	const { uploadFile, getData: getS3UploaderData } = useS3Uploader();
	const { uploading } = getS3UploaderData();
	const { getCoinPrice } = usePremiumContent();
	const [searchText, setSearchText] = useState<string>("");
	const [open, setOpen] = useState(false);
	const [videoInfoToDelete, setVideoInfoToDelete] = useState<{ id: string | null; name: string }>({
		id: null,
		name: ""
	});

	const debouncedSearchTerm = useDebounce(searchText, 300);

	useEffect(() => {
		getVideos({ keyword: debouncedSearchTerm, limit: videosShowPerPage, offset: page });
	}, [debouncedSearchTerm, getVideos, page, videosShowPerPage]);

	useEffect(() => {
		getAllVideoCount({});
		setPage(1);
	}, [getAllVideoCount, setPage]);

	useEffect(() => {
		getVideoCount({ keyword: debouncedSearchTerm });
		setPage(1);
	}, [debouncedSearchTerm, getVideoCount, setPage]);

	const handleSearch = useCallback((val: string) => {
		if (val === "" || val.length >= 3) {
			setSearchText(val);
		}
	}, []);

	const getOptions = useCallback(
		(rowData: VideoModel) => [
			{
				name: "Edit",
				onClick: () => {
					setVideoInfoPopup({ open: true, model: rowData });
				},
				value: 1,
				comp: ""
			},
			{
				name: "Delete",
				onClick: () => {
					setVideoInfoToDelete({ id: rowData._id || null, name: rowData.title });
					setOpen(true);
				},
				value: 2,
				comp: ""
			}
		],
		[setVideoInfoPopup]
	);

	const tableColumns = useMemo(
		() => [
			{
				alignment: "left",
				width: 338,
				label: <Cell.HeaderText>{formatCount(videoCount, "Video", "Videos")}</Cell.HeaderText>,
				Cell: ({ rowData: { title, duration, meta } }: { rowData: VideoModel }) => {
					const coverImgUrl = meta?.artwork?.url;

					return (
						<Cell.Wrapper>
							<Cell.ImageWrapper>
								{coverImgUrl ? (
									<Cell.Image src={coverImgUrl} />
								) : (
									<PlaceholderImage
										type={PlaceholderImageType.VIDEO_PREVIEW}
										width={40}
										height={40}
										viewBox={"0 0 400 400"}
									/>
								)}
							</Cell.ImageWrapper>
							<Cell.Wrapper className="column with-image">
								<Cell.Text>{title}</Cell.Text>
								<Cell.Text className="light">{secondsToHumanTime(duration)}</Cell.Text>
							</Cell.Wrapper>
						</Cell.Wrapper>
					);
				},
				loaderTemplate: TableColumnLoaderType.imageWthTwoTextRows,
				dataKey: "title"
			},
			{
				alignment: "left",
				minWidth: 120,
				label: <Cell.HeaderText>Category</Cell.HeaderText>,
				Cell: ({ rowData: { category } }: { rowData: VideoModel }) => (
					<Cell.Wrapper>
						<Cell.Text>{category?.name || "-"}</Cell.Text>
					</Cell.Wrapper>
				),
				dataKey: "category"
			},
			{
				alignment: "left",
				minWidth: 100,
				label: <Cell.HeaderText>Collection/Series</Cell.HeaderText>,
				Cell: ({ rowData: {} }: { rowData: VideoModel }) => (
					<Cell.Wrapper>
						<Cell.Text>Series name</Cell.Text>
					</Cell.Wrapper>
				),
				dataKey: "collection"
			},
			{
				alignment: "left",
				minWidth: 100,
				label: <Cell.HeaderText>Price</Cell.HeaderText>,
				Cell: ({ rowData: { priceTags } }: { rowData: VideoModel }) => {
					const price = getCoinPrice(priceTags);
					return (
						<Cell.Wrapper>
							<Cell.Text>{price ? `${price} Coins` : "Free"}</Cell.Text>
						</Cell.Wrapper>
					);
				},
				dataKey: "price"
			},
			{
				alignment: "left",
				minWidth: 135,
				label: <Cell.HeaderText>Created Date</Cell.HeaderText>,
				Cell: ({ rowData: { createdAt } }: { rowData: VideoModel }) => {
					const correctDate = DateTime.fromISO(createdAt.toString());
					return (
						<Cell.Wrapper className="column">
							<Cell.Text>{correctDate.toFormat("MMM d, yyyy")}</Cell.Text>
							<Cell.Text className="light">{correctDate.toFormat("h:mm a")}</Cell.Text>
						</Cell.Wrapper>
					);
				},
				loaderTemplate: TableColumnLoaderType.twoTextRows,
				dataKey: "createdAt"
			},
			{
				alignment: "right",
				label: "",
				width: 100,
				Cell: ({ rowData }: { rowData: VideoModel }) => (
					<MenuDots options={getOptions(rowData)} vertical removeBg removeshadow removeSideMargin />
				),
				loaderTemplate: TableColumnLoaderType.menuDots,
				dataKey: "menu"
			}
		],
		[videoCount, getOptions, getCoinPrice]
	);

	const handleUpload = useCallback(() => {
		const el: any = document.getElementById("videoFileUrl");
		if (el) {
			el.value = null;
			el.click();
		}
	}, []);

	const onUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
		const { target } = e;
		const { files } = target;

		if (files) {
			const filteredFiles = filterFiles(files, correctFileTypes);

			const videoFile = !!filteredFiles?.length ? files[0] : null;

			if (videoFile) {
				if (videoFile.size / 1024 / 1024 > 2048) {
					showMessage(
						getValidationMessage({
							name: "File size is too large. Use file that less than 2GB.",
							emoji: "⚠️"
						})
					);
					return;
				}
				const correctFile = typeof videoFile === "string" ? await dataUrlToFile(videoFile, "test") : videoFile;

				const video = document.createElement("video");
				video.preload = "metadata";

				video.onloadedmetadata = function () {
					window.URL.revokeObjectURL(video.src);

					if (video.duration < 1) return;

					setVideoDuration(`${video.duration}`);
				};

				video.src = URL.createObjectURL(correctFile);

				setVideoFileUrl("");

				uploadFile({
					file: correctFile as File,
					communityName: videoUploadUrl,
					checkProgress: true,
					customCallback: data => setVideoFileUrl(data?.publicUrl)
				});

				setVideoName(files && files[0].name.slice(0, files[0].name.lastIndexOf(".")));

				setVideoInfoPopup({ open: true, model: undefined });
			}
		}
	};

	const confirmPopupAction = useCallback(
		async (confirm: boolean) => {
			setOpen(false);
			if (confirm && videoInfoToDelete.id) {
				await deleteVideo(videoInfoToDelete.id);
				getVideos({ keyword: debouncedSearchTerm, limit: videosShowPerPage, offset: page });
			}
		},
		[debouncedSearchTerm, deleteVideo, getVideos, page, videoInfoToDelete.id, videosShowPerPage]
	);

	const handleChangeRowsPerPage = useCallback(
		(newPageSize: number) => {
			setVideosShowPerPage(newPageSize);
			setPage(1);
		},
		[setVideosShowPerPage, setPage]
	);

	const TableBlock = useMemo(
		() => (
			<TableWrapper sizes={{ horizontalTablet: 900 }}>
				<Table
					columns={tableColumns}
					data={filteredVideos || []}
					loading={loading}
					paginated
					totalDataCount={searchText ? videoCount : allVideoCount || 10}
					page={page}
					pageSize={videosShowPerPage}
					onChangePage={setPage}
					onChangePageSize={handleChangeRowsPerPage}
				/>
			</TableWrapper>
		),
		[
			tableColumns,
			filteredVideos,
			loading,
			searchText,
			videoCount,
			allVideoCount,
			page,
			videosShowPerPage,
			setPage,
			handleChangeRowsPerPage
		]
	);

	return (
		<>
			<PageTemplate
				title={"Videos"}
				showReportedLink
				isLoading={loading}
				isNoData={!filteredVideos || !filteredVideos.length}
				emptyText={"You don’t have any Videos in your community yet."}
				searchPlaceholder={"Search Videos"}
				onSearchUpdate={handleSearch}
				actionText={"Create Video"}
				onCreateClick={handleUpload}
				isDisabledCreateAction={uploading}
				isLoadingAction={uploading}
			>
				{TableBlock}
			</PageTemplate>

			<input
				id="videoFileUrl"
				type="file"
				onChange={onUpload}
				name="videoFileUrl"
				hidden
				accept={correctFileTypes.join(",")}
			/>
			{open && (
				<ConfirmDelete
					headline="Video"
					name={videoInfoToDelete?.name}
					onClose={() => confirmPopupAction(false)}
					onDelete={() => confirmPopupAction(true)}
				/>
			)}
			<CreateVideo
				open={videoInfoPopup.open}
				editableModel={videoInfoPopup.model}
				onClose={e => {
					setVideoInfoPopup({ open: false });
					if (e?.created) {
						getVideos({ keyword: debouncedSearchTerm, limit: videosShowPerPage, offset: page });
					}
				}}
			/>
		</>
	);
});

export default ManageVideos;
