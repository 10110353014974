import React, { FC, ReactNode } from "react";

import { BoxItemWrapper } from "./style";

const isChildNull = children => {
	return children?.type && typeof children.type === "function" && Boolean(children.type() === null);
};

interface SmartBoxItemWrapperProps {
	children?: ReactNode;
	noValidate?: boolean;
}

const SmartBoxItemWrapper: FC<SmartBoxItemWrapperProps> = ({ children, noValidate = false }) => {
	if (noValidate) {
		return <BoxItemWrapper>{children}</BoxItemWrapper>;
	}

	if (isChildNull(children)) {
		return null;
	}

	return <BoxItemWrapper>{children}</BoxItemWrapper>;
};

export default SmartBoxItemWrapper;
