import { useCallback, useMemo } from "react";

import firebase from "firebase";
import { DateTime } from "luxon";

import { useHistory } from "react-router-dom";

import { EventType } from "types";

import { useCommunity, useEvent, useMemberRoutes, useUser } from "shared/hooks";

import { ProfileType } from "shared/types";

import { useLiveConversation } from ".";

import {
	ConversationWithEvent,
	ConversationWithId,
	ConvoConfig,
	ConvoControl,
	ConvoControlWithKey,
	ConvoParticipant,
	ConvoUserRoles,
	FirebaseParticipant,
	RaisedHand,
	Stream
} from "../types";

const conversationBasePath = "conversations/{{roomId}}/";
const participantsPath = `${conversationBasePath}participants`;
const participantPath = `${conversationBasePath}participants/{{personaId}}`;
const configPath = `${conversationBasePath}config`;
const audioLevelsPath = `${conversationBasePath}audioLevels`;
const audioLevelPath = `${conversationBasePath}audioLevels/{{personaId}}`;
const controlsPath = `${conversationBasePath}controls`;
const controlPath = `${conversationBasePath}controls/{{personaId}}`;
// const pingPath = `${conversationBasePath}ping`;
const raisedHandsPath = `${conversationBasePath}raisedHands`;
const commentsPath = `${conversationBasePath}comments`;
const reactionsPath = `${conversationBasePath}reactions`;
const reactionPath = `${conversationBasePath}reactions/{{id}}`;
const wasSpeakerPath = `${conversationBasePath}wasSpeaker`;
// const frozenFrames = `${conversationBasePath}frozenFrames`;

const formatParams = (str: string, params: Record<string, unknown>): string => {
	let finalStr: string = str;
	for (const key in params) {
		finalStr = finalStr.replace(`{{${key}}}`, params[key] as string);
	}
	return finalStr;
};

type IVoidFunction = () => void;

const useFirebaseLiveConversation = () => {
	const { getData: getCommunityData } = useCommunity();
	const { workspace } = getCommunityData();

	const {
		updateParticipants,
		updateControls,
		updateConfig,
		updateRaisedHands,
		resetConvo,
		updateAudioLevels,
		setOngoingLiveConvos,
		setOngoingLiveConvosLoading,
		getData: getLiveConversationData
	} = useLiveConversation();
	const { roomId, isMicOn, isVideoOn } = getLiveConversationData();

	const { getMemberRoutesData } = useMemberRoutes();
	const { routes: memberRoutes } = getMemberRoutesData();

	const { getEvents } = useEvent();

	const history = useHistory();

	const { getActiveProfile, getProfilePicture, getData: getUserData } = useUser();
	const { user } = getUserData();

	const activeProfile: ProfileType | undefined = useMemo(() => getActiveProfile(user), [getActiveProfile, user]);

	const databaseRef = useCallback((): firebase.database.Reference => {
		return firebase.database().ref(!workspace?.whiteLabelApp ? workspace?.name : undefined);
	}, [workspace]);

	const onParticipantsUpdate = useCallback(
		(snapshot: firebase.database.DataSnapshot) => {
			const data: FirebaseParticipant = snapshot.val();
			if (data) updateParticipants(Object.values(data));
			else updateParticipants([]);
		},
		[updateParticipants]
	);
	const onConfigUpdate = useCallback(
		(snapshot: firebase.database.DataSnapshot) => {
			const data: ConvoConfig = snapshot.val();
			if (data) updateConfig(data);
		},
		[updateConfig]
	);
	const onAudioLevelUpdate = useCallback(
		(snapshot: firebase.database.DataSnapshot) => {
			if (!snapshot.val()) return updateAudioLevels([]);
			updateAudioLevels(Object.values(snapshot.val()));
		},
		[updateAudioLevels]
	);
	const onControlsUpdate = useCallback(
		(snapshot: firebase.database.DataSnapshot) => {
			const data: ConvoControlWithKey = snapshot.val();
			if (data) updateControls(data);
		},
		[updateControls]
	);
	const onRaisedHandUpdate = useCallback(
		(snapshot: firebase.database.DataSnapshot) => {
			if (snapshot.val()) {
				updateRaisedHands(snapshot.val() as RaisedHand);
			} else {
				updateRaisedHands({});
			}
		},
		[updateRaisedHands]
	);
	const onLiveConvosUpdate = useCallback(
		(snap: firebase.database.DataSnapshot) => {
			if (!snap.val()) {
				setOngoingLiveConvosLoading(false);
				return;
			}

			const _conversations: ConversationWithId[] = [];
			snap.forEach(child => {
				const conversation = child.val();
				const startedAt = conversation.config?.startedAt && DateTime.fromISO(conversation.config?.startedAt);
				const diff = startedAt && DateTime.local().diff(startedAt).as("hours");

				if (conversation.participants && Object.keys(conversation.participants).length > 0 && diff < 3) {
					_conversations.push({ ...conversation, id: child.key });
				}
			});
			getEvents({
				page: 1,
				limit: 12,
				eventSchedule: EventType.Happening,
				liveConversationIds: _conversations.map(c => c.id)
			})
				.then(({ events }) => {
					const items: ConversationWithEvent[] = [];
					for (let i = 0; i < _conversations.length; i++) {
						const event = events.find(e => e.liveConversationId === _conversations[i].id);
						if (event) {
							items.push({ ..._conversations[i], event });
						}
					}
					setOngoingLiveConvos(items);
					setOngoingLiveConvosLoading(false);
				})
				.catch(() => {
					setOngoingLiveConvosLoading(false);
				});
		},
		[setOngoingLiveConvos, getEvents, setOngoingLiveConvosLoading]
	);

	const methods = useMemo(
		() => ({
			joinParticipant: ({
				streamId,
				shouldStart,
				isAdmin
			}: {
				streamId: string;
				shouldStart: boolean;
				isAdmin: boolean;
			}): Promise<void> => {
				return new Promise((resolve, reject) => {
					if (!activeProfile) return reject("No user found");

					const participantRef: firebase.database.Reference = databaseRef().child(
						`${formatParams(participantPath, { roomId, personaId: activeProfile?.personaId })}`
					);

					const data: FirebaseParticipant = {
						liveConversationId: roomId,
						personaId: activeProfile?.personaId,
						avatarUrl: getProfilePicture(user),
						firstName: activeProfile?.firstName,
						lastName: activeProfile?.lastName,
						streamId,
						userRole: isAdmin ? ConvoUserRoles.admin : ConvoUserRoles.participant,
						isSpeaker: isAdmin, // isAdmin || !!conversation?.isSpeaker,
						banned: false,
						isFrontCamera: true,
						joinedAt: DateTime.utc().toISO()
					};
					participantRef.set(data).then(resolve);
					if (shouldStart) {
						databaseRef()
							.child(`${formatParams(configPath, { roomId })}`)
							.set({
								startedAt: DateTime.utc().toISO(),
								enableHandRaise: true, // conversation.event.allowRaiseHand,
								enableInvites: true, // conversation.event.allowInvites,
								enableComments: true
							} as ConvoConfig);
					}

					databaseRef()
						.child(`${formatParams(controlPath, { roomId, personaId: activeProfile?.personaId })}`)
						.set({
							personaId: activeProfile?.personaId || "",
							muted: !isMicOn,
							videoOn: isVideoOn
						} as ConvoControl);
				});
			},
			leaveConversation: ({
				canDelete,
				totalParticipants,
				force
			}: {
				canDelete: boolean;
				totalParticipants: number;
				force: boolean;
			}) => {
				if ((canDelete && totalParticipants === 1) || force)
					databaseRef().child(formatParams(conversationBasePath, { roomId })).remove();
				else if (activeProfile?.personaId) methods.removeParticipant(activeProfile.personaId);
				resetConvo();
			},
			watchConversation: (
				roomId: string,
				onConversationUpdated: (snap: firebase.database.DataSnapshot) => void
			): IVoidFunction => {
				databaseRef()
					.child(formatParams(conversationBasePath, { roomId }))
					.on("value", snap => onConversationUpdated?.(snap));
				return () => {
					databaseRef()
						.child(formatParams(conversationBasePath, { roomId }))
						.off("value", snap => onConversationUpdated?.(snap));
				};
			},
			watchConversationUpdate: (roomId: string): IVoidFunction => {
				const dbRef = databaseRef();
				dbRef.child(formatParams(participantsPath, { roomId })).on("value", onParticipantsUpdate);
				dbRef.child(formatParams(configPath, { roomId })).on("value", onConfigUpdate);
				dbRef.child(formatParams(audioLevelsPath, { roomId })).on("value", onAudioLevelUpdate);
				dbRef.child(formatParams(controlsPath, { roomId })).on("value", onControlsUpdate);
				dbRef.child(formatParams(raisedHandsPath, { roomId })).on("value", onRaisedHandUpdate);

				return () => {
					dbRef.child(formatParams(participantsPath, { roomId })).off("value", onParticipantsUpdate);
					dbRef.child(formatParams(configPath, { roomId })).off("value", onConfigUpdate);
					dbRef.child(formatParams(audioLevelsPath, { roomId })).off("value", onAudioLevelUpdate);
					dbRef.child(formatParams(controlsPath, { roomId })).off("value", onControlsUpdate);
					dbRef.child(formatParams(raisedHandsPath, { roomId })).off("value", onRaisedHandUpdate);
				};
			},
			getListWithStreams: (streams: Stream[], participants: FirebaseParticipant[]): ConvoParticipant[] => {
				const finalList: ConvoParticipant[] = [];
				streams.forEach(({ stream, streamId }) => {
					const _participant = participants.find(participant => participant.streamId === streamId);
					if (_participant) finalList.push({ ..._participant, stream });
				});
				return finalList;
			},
			updateMyControls: (params: ConvoControl) => {
				databaseRef()
					.child(formatParams(controlPath, { roomId, personaId: activeProfile?.personaId }))
					.update({ ...params, personaId: activeProfile?.personaId });
			},
			watchComments: (onCommentsUpdate: (snap: firebase.database.DataSnapshot) => void): IVoidFunction => {
				databaseRef().child(formatParams(commentsPath, { roomId })).orderByChild("order").on("value", onCommentsUpdate);
				return () => {
					databaseRef().child(formatParams(commentsPath, { roomId })).off("value", onCommentsUpdate);
				};
			},
			watchReactions: (onReactionUpdate: (snap: firebase.database.DataSnapshot) => void): IVoidFunction => {
				databaseRef().child(formatParams(reactionsPath, { roomId })).on("child_added", onReactionUpdate);
				return () => {
					databaseRef().child(formatParams(reactionsPath, { roomId })).off("child_added", onReactionUpdate);
				};
			},
			addComment: (comment: string) => {
				const now = new Date().getTime();
				const firebaseComment = {
					_id: now,
					createdAt: now,
					order: -1 * now,
					text: comment,
					avatarUrl: getProfilePicture(user),
					firstName: activeProfile?.firstName,
					lastName: activeProfile?.lastName,
					personaId: activeProfile?.personaId
				};
				databaseRef().child(formatParams(commentsPath, { roomId })).push(firebaseComment);
			},
			raiseHand: (personaId: number, flag: boolean | string) => {
				databaseRef()
					.child(formatParams(raisedHandsPath, { roomId }))
					.update({ [personaId]: flag });
			},
			muteParticipant: (personaId: number) => {
				const field = { muted: true };
				const dbRef = databaseRef();
				dbRef.child(formatParams(participantPath, { roomId, personaId })).update(field);
				dbRef.child(formatParams(controlPath, { roomId, personaId })).update(field);
			},
			lowerRaisedHand: (personaId: number) => {
				databaseRef()
					.child(formatParams(raisedHandsPath, { roomId }))
					.update({ [personaId]: false });
			},
			toggleSpeaker: (personaId: number, flag: boolean) => {
				const dbRef = databaseRef();
				dbRef.child(formatParams(participantPath, { roomId, personaId })).update({ isSpeaker: flag });
				dbRef.child(formatParams(controlPath, { roomId, personaId })).update({ muted: true, videoOn: false });
				dbRef.child(formatParams(audioLevelPath, { roomId, personaId })).update({ volume: 0, personaId });
				dbRef.child(formatParams(wasSpeakerPath, { roomId })).update({ [personaId]: true });
				methods.lowerRaisedHand(personaId);
			},
			removeParticipant: (personaId: number) => {
				const dbRef = databaseRef();
				dbRef.child(`${formatParams(participantPath, { roomId, personaId })}`).remove();
				dbRef.child(formatParams(controlPath, { roomId, personaId })).remove();
				dbRef.child(formatParams(audioLevelPath, { roomId, personaId })).remove();
				dbRef.child(formatParams(raisedHandsPath, { roomId })).child(`${personaId}`).remove();
			},
			updateConversationConfig: (data: Pick<ConvoConfig, "enableComments"> | Pick<ConvoConfig, "enableHandRaise">) => {
				databaseRef().child(formatParams(configPath, { roomId })).update(data);
			},
			reactOnConversastion: (reaction: string) => {
				databaseRef().child(formatParams(reactionsPath, { roomId })).push({ reaction });
			},
			removeReaction: (id: string | null) => {
				if (id) databaseRef().child(formatParams(reactionPath, { roomId, id })).remove();
			},
			removeComment: (id: string) => {
				databaseRef().child(formatParams(commentsPath, { roomId })).child(id).remove();
			},
			updateParticipant: (
				personaId: number,
				field: Pick<FirebaseParticipant, "streamId"> | Pick<FirebaseParticipant, "blocked">
			) => {
				databaseRef().child(formatParams(participantPath, { roomId, personaId })).update(field);
			},
			updateAudioLevel: (personaId: number, volume: number) => {
				databaseRef().child(formatParams(audioLevelPath, { roomId, personaId })).update({ volume, personaId });
			},
			watchLiveConvos: (): IVoidFunction => {
				setOngoingLiveConvosLoading(true);
				databaseRef()
					.child("conversations")
					.orderByChild("config/startedAt")
					.limitToLast(12)
					.on("value", onLiveConvosUpdate);
				return () => {
					databaseRef()
						.child("conversations")
						.orderByChild("config/startedAt")
						.limitToLast(12)
						.off("value", onLiveConvosUpdate);
				};
			},
			onJoinConversation: (conversation: ConversationWithEvent) => {
				if (conversation.event.admins?.find(a => a.personaId === activeProfile?.personaId)) {
					setTimeout(() => {
						history.push(`${memberRoutes?.member.liveconversation.initiate.getPath()}/${conversation.id}`);
					}, 50);
					return;
				}
				setTimeout(() => {
					history.push(`${memberRoutes?.member.liveconversation.main.getPath()}/${conversation.id}`);
				}, 50);
			},
			startNewLiveConvo: () => {
				history.push(`${memberRoutes?.member.liveconversation.initiate.getPath()}/newevent`);
			}
		}),
		[
			roomId,
			activeProfile,
			getProfilePicture,
			isMicOn,
			isVideoOn,
			user,
			onParticipantsUpdate,
			onConfigUpdate,
			onAudioLevelUpdate,
			onControlsUpdate,
			onRaisedHandUpdate,
			resetConvo,
			databaseRef,
			onLiveConvosUpdate,
			history,
			setOngoingLiveConvosLoading,
			memberRoutes
		]
	);

	return useMemo(
		() => ({
			...methods
		}),
		[methods]
	);
};

export default useFirebaseLiveConversation;
