import styled from "styled-components";

import { Box, Text } from "shared/ui-kit";

export const PageContainer = styled(Box)`
	width: 100%;
	position: relative;

	max-width: 960px;

	${props => props.theme.breakpoints.up("sm")} {
		padding: 40px;
	}
`;

export const Title = styled(Text)`
	width: 100%;
	position: relative;
	margin-bottom: 20px;
	font-size: 22px;
	margin-top: 16px;
	${props => props.theme.breakpoints.up("sm")} {
		font-size: 36px;
	}
`;

export const Content = styled(Box)`
	width: 100%;
	padding: 24px 16px;
	border-radius: 4px;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
	background-color: #ffffff;

	${props => props.theme.breakpoints.up("sm")} {
		padding: 40px;
	}
`;

export const ProfilePhotoWrapper = styled(Box)`
	width: 120px;

	position: relative;

	z-index: 10;

	background-color: white;

	margin: -80px 0 50px 20px;
	padding: 5px;

	border-radius: 8px;

	${props => props.theme.breakpoints.up("sm")} {
		margin: -60px 0 50px 40px;
	}
`;
