import React, { FC, useCallback, useMemo, useState } from "react";

import { useMediaQuery } from "@material-ui/core";
import { useHistory } from "react-router-dom";

import { ForumPersonaModel } from "types";

import { ReactComponent as CopyIcon } from "assets/icons/copy.svg";
import { ReactComponent as IconChatBubble } from "assets/icons/icon-chat-bubble.svg";
import { ReactComponent as DeleteIcon } from "assets/icons/icon-delete.svg";
import { ReactComponent as IconLock } from "assets/icons/icon-lock.svg";
import { ReactComponent as IconMail } from "assets/icons/icon-mail-filled.svg";
import { ReactComponent as ReportIcon } from "assets/icons/liveconvo/report.svg";
import { ReactComponent as LockIcon } from "assets/icons/lock.svg";
import { ReactComponent as IcProfile } from "assets/icons/profile1.svg";
import { ActionButton, ActionItemWrapper, StyledWrapper } from "modules/MemberHome/View/shared/style";
import {
	ConfirmActionModal,
	Members,
	MenuDots,
	MenuDotsOption,
	PlaceholderImage,
	UnlockPremiumBtn
} from "shared/Components";
import { useGroup, useMemberRoutes, useNotification, useReportContent, useUser } from "shared/hooks";

import { PlaceholderImageType, ProfilePhotoSizes, ReportContentType, UnlockContentType } from "shared/types";
import { Icon } from "shared/ui-kit";
import * as appTheme from "theme/default";
import { getResizedImage } from "utils/serviceUtils/cdnImages";

import { MembersRequestsDialog, ShareGroupViaMessageDialog } from "./components";
import { InvitationBox, InvitationButton } from "./style";

const GroupHeader: FC<{
	members: ForumPersonaModel[];
	handleInvite: () => void;
	handleEditGroup: () => void;
	isPrivateClosedGroup: boolean;
	isPrivateGroup: boolean;
	id: string;
	groupChatId?: string | null;
}> = ({ members, handleInvite, handleEditGroup, isPrivateClosedGroup, id, groupChatId, isPrivateGroup }) => {
	const history = useHistory();

	const isMobile = useMediaQuery(appTheme.default.breakpoints.down("sm"));

	const {
		joinGroup,
		declineGroupInvitation,
		getGroupFromListing,
		deleteGroupBySlug,
		getData: getGroupData
	} = useGroup();
	const { group } = getGroupData();

	const { getData: getUserData, isPrivilegedRole } = useUser();
	const { user } = getUserData();

	const { getReportTypeOptions } = useReportContent();

	const { getMemberRoutesData } = useMemberRoutes();
	const { routes } = getMemberRoutesData();

	const { showMessage } = useNotification();

	const [submitting, setSubmitting] = useState(false);
	const [groupDelete, setGroupDelete] = useState(false);
	const [membersRequestsDialog, setMembersRequestsDialog] = useState(false);
	const [shareGroupViaMessageDialog, setShareGroupViaMessageDialog] = useState(false);
	const isInvited = useMemo(() => group?.roles?.isInvited || false, [group]);

	const handleDecline = useCallback(async () => {
		if (group && user) {
			setSubmitting(true);
			await declineGroupInvitation({
				slug: group.slug,
				personaId: user.activeProfile || user.profiles[0].personaId,
				updateStorage: true
			});
			setSubmitting(false);
		}
	}, [group, declineGroupInvitation, user]);

	const handleJoin = useCallback(async () => {
		if (group && user) {
			setSubmitting(true);
			await joinGroup({
				slug: group.slug,
				personaId: user.activeProfile || user.profiles[0].personaId,
				isPrivate: group.private === 1,
				isInvited,
				updateStorage: true
			});
			setSubmitting(false);
		}
	}, [group, joinGroup, user, isInvited]);

	const getGroupListing = useCallback(() => {
		id && getGroupFromListing(id);
	}, [getGroupFromListing, id]);

	const handleGoToChat = useCallback(
		() => history.push(`${routes?.member.messaging.getPath()}?chatId=${groupChatId}`),
		[groupChatId, history, routes?.member.messaging]
	);

	const showMembers = useMemo(
		() =>
			members
				.filter(x => x?.persona?.photos?.length && x?.persona?.photos[0]?.profilePicture)
				.slice(0, 3)
				.map(x => getResizedImage(x.persona.photos[0].profilePicture, ProfilePhotoSizes.size50x50)),
		[members]
	);

	const canEdit = useMemo(() => group?.roles?.isOwner || group?.roles?.isModerator || group?.roles?.isAdmin, [group]);
	const canInvite = useMemo(() => group?.roles?.isMember || canEdit, [group?.roles, canEdit]);

	const groupDeleteHandler = useCallback(async () => {
		if (groupDelete) {
			const success = await deleteGroupBySlug(id, false);
			if (success) {
				setGroupDelete(false);
				history.push(routes?.member.groups.getPath());
			}
		}
	}, [deleteGroupBySlug, id, groupDelete, history, routes]);

	const optionList = useMemo(() => {
		let options: { name: string; icon: React.ReactNode; onClick: () => void; submenuOptions?: MenuDotsOption[] }[] = [];
		if (group?.organizerId !== user?.activeProfile && !isPrivilegedRole) {
			options = [
				{
					name: "Report",
					icon: <ReportIcon viewBox="0 -2 22 22" />,
					onClick: () => {},
					submenuOptions: getReportTypeOptions({
						reportType: ReportContentType.GROUP,
						reportContentId: `${group?._id}`,
						reportContentName: group?.name,
						customCallback: () => {
							history.go(-1);
						}
					})
				}
			];
		} else {
			options = [
				{
					name: "Share via Vyoo Messages",
					icon: <Icon name="comment" group="linear" fill="#8f9bb3" />,
					onClick: () => setShareGroupViaMessageDialog(true)
				},
				{
					name: "Copy Group Link",
					icon: <CopyIcon />,
					onClick: async () => {
						await navigator.clipboard.writeText(window.location.href);
						showMessage("Link copied to your clipboard!");
					}
				},
				{
					name: "Delete Group",
					icon: <DeleteIcon fill="#8f9bb3" />,
					onClick: () => setGroupDelete(true)
				}
			];

			if (isPrivateGroup && group?.hasRequests) {
				options.unshift({
					name: "Member Requests",
					icon: <IcProfile className="fill-use" />,
					onClick: () => setMembersRequestsDialog(true)
				});
			}
		}

		return options;
	}, [getReportTypeOptions, history, group, user?.activeProfile, isPrivilegedRole, showMessage, isPrivateGroup]);

	const groupActions = useMemo(
		() =>
			isPrivateClosedGroup ? (
				<ActionItemWrapper>
					<ActionButton
						palette="basic"
						buttonTheme="light"
						onClick={handleJoin}
						leftIcon={<IconLock />}
						disabled={submitting || group?.roles?.isPending || group?.isPending}
					>
						{group?.roles?.isPending || group?.isPending ? "Request pending" : "Request to Join"}
					</ActionButton>
				</ActionItemWrapper>
			) : canInvite ? (
				<>
					{canInvite && (
						<ActionItemWrapper>
							<ActionButton
								palette="basic"
								buttonTheme="light"
								onClick={handleInvite}
								leftIcon={<IconMail />}
								className="fixed-size"
							>
								Invite
							</ActionButton>
						</ActionItemWrapper>
					)}
					{canEdit && (
						<ActionItemWrapper>
							<ActionButton
								palette="basic"
								buttonTheme="light"
								onClick={handleEditGroup}
								leftIcon={<Icon name="pencil" group="filled" />}
								className="fixed-size"
							>
								Edit Group
							</ActionButton>
						</ActionItemWrapper>
					)}
					{group?.chatEnabled && groupChatId && (
						<ActionItemWrapper>
							<ActionButton palette="basic" buttonTheme="light" onClick={handleGoToChat} leftIcon={<IconChatBubble />}>
								Chat
							</ActionButton>
						</ActionItemWrapper>
					)}
					{!!optionList?.length && (
						<ActionItemWrapper>
							<MenuDots options={optionList} memberView removeSideMargin />
						</ActionItemWrapper>
					)}
				</>
			) : (
				<>
					{group?.locked ? (
						<UnlockPremiumBtn
							premiumObject={group}
							premiumObjectType={UnlockContentType.GROUP}
							onUnlock={getGroupListing}
						/>
					) : !isInvited ? (
						<ActionItemWrapper>
							<ActionButton
								palette="basic"
								buttonTheme="light"
								onClick={handleJoin}
								disabled={submitting}
								leftIcon={<IconLock />}
							>
								{submitting ? "Joining.." : "Join Group"}
							</ActionButton>
						</ActionItemWrapper>
					) : null}
				</>
			),
		[
			isPrivateClosedGroup,
			handleJoin,
			submitting,
			group,
			canInvite,
			handleInvite,
			canEdit,
			handleEditGroup,
			optionList,
			groupChatId,
			handleGoToChat,
			isInvited,
			getGroupListing
		]
	);

	return (
		<>
			<StyledWrapper.Header>
				<StyledWrapper.HeaderIconWrapper>
					{group?.coverUrl ? (
						<img src={group?.coverUrl} alt={group?.name} />
					) : (
						<PlaceholderImage
							type={PlaceholderImageType.GROUP_DETAILS}
							width={1170}
							height={480}
							viewBox={"0 0 990 405"}
						/>
					)}
				</StyledWrapper.HeaderIconWrapper>
				<StyledWrapper.HeaderBody>
					<StyledWrapper.HeaderInfoRow>
						<StyledWrapper.TypeWrapper>
							{group?.private ? (
								<LockIcon width={20} height={20} />
							) : (
								<Icon name="globe" width={16} height={16} group="linear" fill="#8f9bb3" />
							)}
							<StyledWrapper.TypeText>{group?.private ? "Private" : "Public"} group</StyledWrapper.TypeText>
						</StyledWrapper.TypeWrapper>
						{!isMobile && <StyledWrapper.ActionsWrapper>{groupActions}</StyledWrapper.ActionsWrapper>}
					</StyledWrapper.HeaderInfoRow>
					<StyledWrapper.Title variant="h1">{group?.name}</StyledWrapper.Title>
					<StyledWrapper.MembersBlock>
						<Members
							memberImgs={isPrivateClosedGroup ? [] : showMembers}
							total={group!.totalMembers}
							isFundraiser={false}
						/>
					</StyledWrapper.MembersBlock>
					{isMobile && <StyledWrapper.ActionsWrapper>{groupActions}</StyledWrapper.ActionsWrapper>}
					{isInvited && (
						<InvitationBox>
							<InvitationBox.InfoWrapper>
								{group?.inviter?.photos?.length && group.inviter.photos[0]?.profilePicture && (
									<InvitationBox.Avatar
										to={`${routes?.member.profile.getPath()}/${group?.inviter.personaId}`}
										style={{
											backgroundImage: `url(${getResizedImage(
												group.inviter.photos[0].profilePicture,
												ProfilePhotoSizes.size50x50
											)})`
										}}
									/>
								)}
								<InvitationBox.Text>
									{group?.inviter ? (
										<>
											<b>{`${group?.inviter?.firstName} ${group?.inviter?.lastName}`}</b> invited you to this group.
										</>
									) : (
										"You have been invited to this group."
									)}
								</InvitationBox.Text>
							</InvitationBox.InfoWrapper>
							<div>
								<InvitationButton
									palette="basic"
									buttonTheme="light"
									onClick={handleDecline}
									disabled={submitting}
									leftIcon={<Icon height={20} width={20} name="close-circle" group="filled" fill="#222b45" />}
								>
									Decline
								</InvitationButton>
								<InvitationButton
									buttonTheme="main"
									onClick={handleJoin}
									disabled={submitting}
									leftIcon={<Icon height={20} width={20} name="check-circle" group="filled" fill="#fff" />}
								>
									Accept
								</InvitationButton>
							</div>
						</InvitationBox>
					)}
				</StyledWrapper.HeaderBody>
			</StyledWrapper.Header>
			<ConfirmActionModal
				open={groupDelete}
				onClose={() => setGroupDelete(false)}
				onConfirm={groupDeleteHandler}
				title={"Delete Volunteering"}
				bodyText={"Are you sure you want to permanently delete your volunteering slot?"}
				closeText="Keep"
				confirmText="Delete"
				reverseButtons
				confirmButtonTheme="danger"
			/>
			<MembersRequestsDialog
				open={membersRequestsDialog}
				setMembersRequestsDialog={setMembersRequestsDialog}
				slug={id}
				groupId={group?._id}
			/>
			<ShareGroupViaMessageDialog
				open={shareGroupViaMessageDialog}
				setShareViaMessageDialog={setShareGroupViaMessageDialog}
				group={group || undefined}
			/>
		</>
	);
};

export default GroupHeader;
