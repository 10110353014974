import * as R from "ramda";

import { MediaModel, UpdateVideoFormModel, VideoFormModel } from "shared/types";

import { VideoRequest } from "../../types";

export const convertVideo = (
	data: VideoFormModel,
	videoFileMedia: MediaModel,
	videoImageMedia: MediaModel | null
): VideoRequest => {
	return {
		...R.pick(
			["title", "description", "duration", "categoryId", "priceTags", "groupId", "eventId", "collectionIds"],
			data
		),
		meta: {
			video: {
				mediaId: videoFileMedia._id,
				url: videoFileMedia.uri
			},
			artwork: videoImageMedia
				? {
						mediaId: videoImageMedia._id,
						url: videoImageMedia.uri
				  }
				: undefined,
			previewStart: data.preview.start,
			previewStop: data.preview.end,
			canBeDownloaded: !!data.canBeDownloaded,
			scheduleDate: data.scheduleDate,
			private: data.private
		}
	};
};

export const convertUpdateVideo = (data: UpdateVideoFormModel, videoImageMedia?: MediaModel): VideoRequest => {
	return {
		...R.pick(["title", "description", "duration", "categoryId", "priceTags", "groupId", "collectionIds"], data),
		meta: {
			artwork: videoImageMedia
				? {
						mediaId: videoImageMedia._id,
						url: videoImageMedia.uri
				  }
				: data.videoImageUrl === null
				? undefined
				: data.meta.artwork,
			previewStart: data.preview.start,
			previewStop: data.preview.end,
			canBeDownloaded: !!data.canBeDownloaded,
			scheduleDate: data.scheduleDate,
			private: data.private
		}
	};
};
