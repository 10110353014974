import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";

import { saveAs } from "file-saver";
import JSZip from "jszip";
import { useHistory } from "react-router-dom";

import { ReactComponent as ReportIcon } from "assets/icons/liveconvo/report.svg";
import { ReactComponent as PencilIcon } from "assets/icons/pencil.svg";
import { AddTracksToPlaylist, AlbumDetailsDialog } from "modules/Manage/View/Components";
import { MenuDotsOption, UnlockPremiumBtn } from "shared/Components";
import { useAlbums, useOnScreen, useReportContent, useTrack, useUser } from "shared/hooks";
import { CrudType, ReportContentType, UnlockContentType } from "shared/types";

import { AlbumDetails, MoreAlbums, TracksDownloaded } from "../../Components";
import TracksProgress from "../../Components/TracksProgress";
interface AlbumProps {
	id: string;
	download?: string | null;
	musicIds?: string | null;
}

const Album: FC<AlbumProps> = ({ id, download, musicIds }) => {
	const {
		getAlbumDetails,
		getAlbumDownloadUrls,
		setTrackProgressDialog,
		readSong,
		setTrackDownloaded,
		getData: getAlbumsData,
		isUserCouldManageAlbum,
		setAlbumDetailsDialog
	} = useAlbums();
	const { albumDetails, trackDownloaded, albumDetailsDialog } = getAlbumsData();

	const { setTrackInfoPopup, getData: getTrackData } = useTrack();
	const { trackInfoPopup } = getTrackData();

	const history = useHistory();

	const { getReportTypeOptions } = useReportContent();

	const { isPrivilegedRole } = useUser();

	const [offset, setOffset] = useState(1);
	const [isLoading, setIsLoading] = useState(false);

	const lastTrackRef = useRef<HTMLDivElement>(null);

	const onScreen = useOnScreen(lastTrackRef);

	// const trackCount = albumDetails?.musicIds?.length || 0;
	const trackCount = useMemo(() => albumDetails?.musicIds?.length || 0, [albumDetails?.musicIds?.length]);

	const hasMoreItems = useMemo(
		() => trackCount > (albumDetails?.musics?.length || 0),
		[albumDetails?.musics?.length, trackCount]
	);

	const handleDownloadTracksFromUrl = useCallback(
		async (musics: { url: string; name: string }[]) => {
			if (albumDetails) {
				setTrackProgressDialog(
					musics.map(track => ({
						name: track.name,
						track: track.url,
						progress: 0
					}))
				);

				const zip = new JSZip();

				const files = await Promise.all(
					musics.map(async track => {
						return {
							title: `${albumDetails.title}/${track.name}.mp3`,
							blob: await readSong(track.url)
						};
					})
				);

				files.forEach(file => zip.file(file.title, file.blob));

				zip.generateAsync({ type: "blob" }).then(
					content => {
						saveAs(content, `${albumDetails.title}.zip`);
					},
					err => {
						console.log("error is", err);
					}
				);

				setTrackDownloaded(true);
			}
		},
		[albumDetails, readSong, setTrackDownloaded, setTrackProgressDialog]
	);

	const fetchAlbumInfo = useCallback(
		async (id: string, offset: number) => {
			setIsLoading(true);
			await getAlbumDetails(id, 10, offset);
			setIsLoading(false);
		},
		[getAlbumDetails]
	);

	useEffect(() => {
		if (download && download === "true") {
			getAlbumDownloadUrls(id, {
				musicIds: musicIds ? JSON.stringify(musicIds.split(",")) : null,
				enableNames: true
			}).then(({ musics }) => {
				if (musics.length) {
					handleDownloadTracksFromUrl(musics);
				}
			});
		}
	}, [download, getAlbumDownloadUrls, handleDownloadTracksFromUrl, id, musicIds]);

	useEffect(() => {
		if (onScreen && hasMoreItems) {
			setOffset(state => state + 1);
		}
	}, [hasMoreItems, onScreen]);

	useEffect(() => {
		fetchAlbumInfo(id, offset);
	}, [fetchAlbumInfo, id, offset]);

	const optionList = useMemo(() => {
		let BASE_OPTIONS: MenuDotsOption[] = [];
		BASE_OPTIONS =
			!albumDetails?.creator && !isPrivilegedRole
				? [
						{
							name: "Report",
							icon: <ReportIcon viewBox="0 -2 22 22" />,
							onClick: () => {},
							submenuOptions: getReportTypeOptions({
								reportType: ReportContentType.ALBUM,
								reportContentId: `${albumDetails?._id}`,
								reportContentName: albumDetails?.title,
								customCallback: () => {
									history.go(-1);
								}
							})
						}
				  ]
				: [];

		if (isUserCouldManageAlbum({ type: CrudType.UPDATE, isCreator: !!albumDetails?.creator })) {
			BASE_OPTIONS = [
				{
					name: "Edit Playlist",
					onClick: () => {
						setTrackInfoPopup({ open: true });
						setAlbumDetailsDialog({ ...albumDetailsDialog, addTracks: true, data: albumDetails });
					},
					icon: <PencilIcon />
				},
				...BASE_OPTIONS
			];
		}

		return BASE_OPTIONS;
	}, [
		getReportTypeOptions,
		history,
		albumDetails,
		isUserCouldManageAlbum,
		setTrackInfoPopup,
		setAlbumDetailsDialog,
		albumDetailsDialog,
		isPrivilegedRole
	]);

	return (
		<>
			{trackDownloaded && <TracksDownloaded />}
			<AlbumDetails
				loading={isLoading}
				lastTrackRef={lastTrackRef}
				details={albumDetails}
				options={optionList}
				unlockBlock={
					<UnlockPremiumBtn
						premiumObject={albumDetails}
						teaserContent={albumDetails?.title}
						premiumObjectType={UnlockContentType.ALBUM}
						onUnlock={() => {
							getAlbumDetails(id, 10, 1 * offset);
						}}
					/>
				}
			/>
			<MoreAlbums id={id} />
			<TracksProgress />
			{albumDetailsDialog.addTracks && trackInfoPopup.open && <AddTracksToPlaylist />}
			{albumDetailsDialog.open && <AlbumDetailsDialog />}
		</>
	);
};

export default Album;
