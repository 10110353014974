import { ButtonBase } from "@material-ui/core";
import styled, { css } from "styled-components";

import { ReactComponent as ShareIcon } from "assets/icons/icon-share.svg";
import { Box, Text } from "shared/ui-kit";

export const ChatWrapper = styled(Box)`
	display: flex;
	flex-direction: column;
	align-items: flex-start;

	min-height: 40px;
	position: relative;

	/* max-width: 75%; */
	/* padding-right: 100px; */

	&.member-message {
		margin-top: 20px;
	}

	&.current-user {
		align-items: flex-end;
	}

	/* &.current-user {
		padding-right: 0;
		padding-left: 100px;
	} */
	&:hover {
		.action-box {
			opacity: 1;
			pointer-events: auto;
		}
	}
`;

export const ReplyChatContainer = styled(ButtonBase)`
	display: block;
	width: 100%;
	text-align: left;
	${({ memberSide }) =>
		memberSide
			? css`
					background-color: rgb(0 0 0 / 16%);
					border-radius: 6px;
					.reply-text {
						color: #fff;
					}
			  `
			: css`
					background-color: #c5cee0;
			  `}
	border-left: 4px solid #6173fe;
	margin-bottom: 4px !important;
	padding: 8px;
	.reply-text {
		display: flex;
		align-items: center;
		svg {
			width: 16px;
			height: 16px;
			margin-right: 4px;
		}
	}
`;

export const MenuWrapper = styled(Box)`
	width: 32px;
	height: 32px;

	display: flex;
	justify-content: center;
	align-items: center;

	svg {
		path {
			&:nth-child(2) {
				fill: #8f9bb3;
			}
		}
	}
`;

export const MenuOptionText = styled(Text)<{ withIcon?: boolean }>`
	font-size: 15px;
	font-weight: 600;
	line-height: 1.85;
	letter-spacing: normal;
	color: #222b45;

	margin-left: ${({ withIcon }) => (withIcon ? "8px" : "0")};
`;

export const ActionContainer = styled(Box)`
	display: flex;
	align-items: center;

	transition: opacity 0.25s ease-in-out;

	position: absolute;
	padding: 0 16px;

	left: 100%;
	top: 0;

	height: 100%;

	opacity: 0;
	pointer-events: none;

	&.current-user {
		left: auto;
		right: 100%;
	}

	&.show-like-list {
		opacity: 1;
		pointer-events: auto;
	}
`;

export const ActionIconWrapper = styled(Box)`
	width: 32px;
	height: 32px;

	margin: 0 4px;

	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;

	cursor: pointer;

	border-radius: 50%;

	background-color: transparent;

	transition: background-color 0.25s ease-in-out;

	&:first-child {
		margin-left: 0;
	}

	&:last-child {
		margin-right: 0;
	}

	&:hover {
		background-color: #f7f9fc;
	}

	/* &.color-icon {
		.option {
			path {
				fill: #8f9bb3;
			}
		}
	} */
`;

export const ChatTextContainer = styled(Box)`
	background-color: #edf1f7;

	border-radius: 4px;

	margin-bottom: 6px;
	padding: 9px 12px 20px 12px;

	position: relative;
	margin-bottom: 6px;
	top: 0;

	max-width: 280px;
	min-height: 40px;

	div {
		margin: 0;
	}

	span {
		font-size: 15px;
		font-weight: normal;
		line-height: 1.33;
		letter-spacing: normal;
		color: #222b45;
	}

	&:hover {
		> .action-box {
			opacity: 1;
			pointer-events: auto;
		}
	}

	&.member {
		border-radius: 8px;
		padding-top: 12px;
	}
	&.current-user-bubble {
		background-color: #4a99f9;
		span {
			color: #ffffff;
		}
	}

	${props => props.theme.breakpoints.up("sm")} {
		min-width: 100px;
		max-width: 360px;
	}
`;

export const ForwardIcon = styled(ShareIcon)`
	path {
		fill: #8f9bb3;
	}
`;

export const MessageTimestamp = styled(Text)`
	font-size: 10px;
	text-align: right;
	color: #8f9bb3;

	&.current-user-ts {
		color: #ffffff;
		opacity: 0.7;
	}

	position: absolute;
	bottom: 4px;
	right: 12px;
`;

export const FilePreview = styled(Box)``;

FilePreview.Wrapper = styled(Box)`
	width: 360px;
	height: 64px;

	padding: 12px 15px 13px;
	margin-bottom: 6px;
	border: 1px solid #edf1f7;
	border-radius: 4px;

	background-color: #fff;

	display: flex;
	align-items: center;
	justify-content: space-between;

	.pdf-icon {
		margin-right: 8px;
	}
	&.messageBlockCurrentUser {
		background-color: #4a99f9;
		box-shadow: unset;
		border-radius: 8px;
	}

	&.withPDFThumbnail {
		border-top-left-radius: 0;
		border-top-right-radius: 0;
	}

	${props => props.theme.breakpoints.down("sm")} {
		width: 280px;
	}
`;

FilePreview.Info = styled(Box)`
	display: block;
	flex: 1;
`;

FilePreview.Name = styled(Text)`
	font-size: 13px;
	font-weight: 600;
	color: #222b45;

	max-width: 90%;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
`;

FilePreview.Description = styled(Text)`
	font-size: 12px;
	color: #8f9bb3;
`;

FilePreview.DownloadWrapper = styled(Box)`
	width: 32px;
	height: 32px;

	border-radius: 50%;

	cursor: pointer;

	background: #edf1f7;

	display: flex;
	justify-content: center;
	align-items: center;

	flex-shrink: 0;
`;

export const AudioPreview = styled(Box)`
	width: 280px;
	border-radius: 4px;
	overflow: hidden;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	margin-bottom: 6px;
	audio {
		width: 100%;
		height: 60px;
	}

	${props => props.theme.breakpoints.up("sm")} {
		width: 360px;
	}
`;

export const VideoPreview = styled(Box)`
	margin-bottom: 6px;
	width: 280px;

	display: flex;
	justify-content: flex-end;
	align-items: center;

	video {
		width: 100%;
		max-height: 300px;
		object-fit: contain;
		border: 1px solid #e7e7e7;
		border-radius: 4px;
		overflow: hidden;
	}

	${props => props.theme.breakpoints.up("sm")} {
		width: 360px;
	}
`;

export const MessageImageWrapper = styled(Box)`
	position: relative;
	border-radius: 8px;
	.react-pdf__Page__canvas {
		height: 200px !important;
		object-fit: cover;
		object-position: top;
	}
	.react-pdf__Page__textContent {
		height: 200px !important;
	}
`;

MessageImageWrapper.Overlay = styled(Box)`
	position: absolute;
	top: 0;
	left: 0;
	z-index: 100;
	width: 100%;
	height: 100%;
	opacity: 0;
	background-color: rgb(63 61 61 / 60%);
	border-radius: 8px;
	backdrop-filter: saturate(180%) blur(10px);
	transition: ease-in-out 0.2s all;
	display: flex;
	align-items: center;
	justify-content: center;
	&.fileBubbleView {
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
	}
	button {
		border-radius: 16px;
		padding: 8px 20px;
		border: 1px solid #fff;
		color: #fff;
		transition: ease-in-out 0.2s all;
		font-size: 13px;
		font-weight: bold;
		svg {
			margin-right: 6px;
			path:last-child {
				fill: #fff;
			}
		}
		&:hover {
			background-color: #fff;
			color: #000;
			svg path:last-child {
				fill: #000;
			}
		}
	}
	&:hover {
		opacity: 1;
	}
`;

export const MessageImage = styled(Box)`
	width: 280px;
	aspect-ratio: 16 / 9;
	border: 1px solid #e7e7e7;
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
	margin-bottom: 6px;
	border-radius: 4px;

	&.fileBubbleView {
		margin-bottom: 0;
		background-size: cover;
		max-height: 160px;
		border-radius: 0;
		border-top-left-radius: 4px;
		border-top-right-radius: 4px;
	}

	${props => props.theme.breakpoints.up("sm")} {
		width: 360px;
	}
`;

export const StoryMessagePreview = styled(Box)`
	display: flex;
	flex-direction: column;
	&.isCurrentUser {
		align-items: flex-end;
	}
	p {
		font-size: 12px;
		font-weight: 600;
		line-height: 1.33;
		color: #8f9bb3;
	}
	button {
		margin: 8px 0;
		border-radius: 12px;
		overflow: hidden;
		padding: 0;
	}
	img {
		width: 135px;
		height: 240px;
		object-fit: cover;
	}
`;

export const CarpoolBox = styled(Box)`
	background-color: #f7f9fc;
	border-radius: 12px 12px 0 0;
`;

export const CarpoolMapBox = styled(Box)`
	overflow: hidden;
	border-radius: 0 0 12px 12px;
`;
