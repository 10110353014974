import React from "react";

import { view } from "modules/MemberHome";
import { FeatureFlags } from "shared/Components/MemberNavPanel/constants";

import useCheckIfEnabled from "shared/hooks/useCheckIfFlagEnabled";

const RequestsPage = () => {
	useCheckIfEnabled([FeatureFlags.volunteer, FeatureFlags.carpool], true);

	return <view.Requests />;
};

export default RequestsPage;
