import React, { useCallback, useMemo, useState } from "react";

import clsx from "clsx";
import { take } from "ramda";

import { css } from "styled-components";

import { ReactComponent as PaperClipIcon } from "assets/icons/icon-paperclip.svg";
import { ReactComponent as AudioTrackIcon } from "assets/icons/iconAudioTrack.svg";
import { ReactComponent as CalendarIcon } from "assets/icons/iconCalendar.svg";
import { ReactComponent as DollarIcon } from "assets/icons/iconDollarFilled.svg";
import { ReactComponent as GifIcon } from "assets/icons/iconGif.svg";
import { ReactComponent as GroupsIcon } from "assets/icons/iconGroups.svg";
import { ReactComponent as ImageIcon } from "assets/icons/iconImage.svg";
import { ReactComponent as PollIcon } from "assets/icons/iconPoll.svg";
import { ReactComponent as YoutubeIcon } from "assets/icons/iconYoutube.svg";
import { Dialog, HiddenFileSelector } from "shared/Components";
import { useFeature, useUser } from "shared/hooks";
import { ToolbarActions } from "shared/types";
import { Tooltip } from "shared/ui-kit";

import { MobileAddElements, Toolbar, ToolbarMobileRow } from "./styles";

interface ToolbarItem {
	title: string;
	action: ToolbarActions;
	icon: React.ReactNode;
	featureValueName?: string;
}

const fullItems: ToolbarItem[] = [
	{
		title: "Add Photo/Video",
		action: ToolbarActions.ADD_IMAGE,
		icon: <ImageIcon />
	},
	{
		title: "Add Youtube video",
		action: ToolbarActions.ADD_YOUTUBE,
		icon: <YoutubeIcon />
	},
	{
		title: "Add Audio Track",
		action: ToolbarActions.SELECT_TRACK,
		featureValueName: "enableAudioTracks",
		icon: <AudioTrackIcon />
	},
	{
		title: "Add GIF",
		action: ToolbarActions.ADD_GIF,
		icon: <GifIcon />
	},
	{
		title: "Add Poll",
		featureValueName: "enablePolls",
		action: ToolbarActions.ADD_POLL,
		icon: <PollIcon />
	},
	{
		title: "Add Event",
		action: ToolbarActions.SELECT_EVENT,
		featureValueName: "enableEvents",
		icon: <CalendarIcon />
	},
	{
		title: "Add Group",
		featureValueName: "enableGroups",
		action: ToolbarActions.SELECT_GROUP,
		icon: <GroupsIcon />
	},
	{
		title: "Request Money",
		action: ToolbarActions.ADD_REQUEST_MONEY,
		icon: <DollarIcon />
	}
];

const teaserItems = take(4, fullItems);

interface ToolProps {
	onClick: (action: ToolbarActions | null, data?: any) => void;
	title?: string;
	isMobile?: boolean;
	items: ToolbarItem[];
	isMemberView?: boolean;
}

const RenderToolbar: React.FC<ToolProps> = React.memo(({ onClick, items, isMobile, isMemberView }) => {
	const { isFeatureEnabled } = useFeature();

	const activeItems = useMemo(() => items.filter(x => isFeatureEnabled(x.featureValueName)), [isFeatureEnabled, items]);

	const handleClick = useCallback(
		(action: ToolbarActions, data?: any) => {
			if (action !== ToolbarActions.ADD_IMAGE || !!data) {
				setTimeout(() => {
					onClick(action, data);
				});
			}
		},
		[onClick]
	);

	return (
		<Toolbar className={clsx(!isMemberView && "manage", isMobile && "mobile")}>
			{!isMemberView && !isMobile && <Toolbar.Title>Add elements to post</Toolbar.Title>}
			<Toolbar.Controls className={clsx(isMobile && "mobile")}>
				{activeItems.map(({ title, action, icon }, i) => (
					<Tooltip key={i} text={title}>
						<Toolbar.ControlWrapper onClick={() => handleClick(action)} className={clsx(isMobile && "mobile")}>
							{isMobile ? (
								<>
									<Toolbar.MobileIconWrapper>{icon}</Toolbar.MobileIconWrapper>
									<Toolbar.MobileControlTitle>{title}</Toolbar.MobileControlTitle>
								</>
							) : (
								icon
							)}
							{action === ToolbarActions.ADD_IMAGE && (
								<HiddenFileSelector onSelect={files => handleClick(action, files)} multiple />
							)}
						</Toolbar.ControlWrapper>
					</Tooltip>
				))}
			</Toolbar.Controls>
		</Toolbar>
	);
});

interface Props extends Pick<ToolProps, "onClick"> {
	type: "full" | "teaser";
	isMobile?: boolean;
}

const ToolbarWrapper: React.FC<Props> = ({ type, isMobile, onClick, ...props }) => {
	const [showDialog, setShowDialog] = useState(false);

	const { getData: getUserData } = useUser();
	const { isMemberView } = getUserData();

	const handleItemSelect = useCallback(
		(action: ToolbarActions | null, data?: any) => {
			setShowDialog(false);
			onClick(null);
			onClick(action, data);
		},
		[onClick]
	);

	const renderToolbar = useMemo(
		() => (
			<RenderToolbar
				onClick={handleItemSelect}
				isMobile={isMobile}
				title={type === "teaser" ? "Add different elements" : "Add elements to post"}
				items={type === "teaser" ? teaserItems : fullItems}
				isMemberView={isMemberView}
				{...props}
			/>
		),
		[handleItemSelect, isMobile, type, props, isMemberView]
	);

	return (
		<>
			{isMobile ? (
				<>
					<ToolbarMobileRow onClick={() => setShowDialog(true)}>
						<ToolbarMobileRow.IconWrapper>
							<PaperClipIcon />
						</ToolbarMobileRow.IconWrapper>
						<MobileAddElements>Add elements to post</MobileAddElements>
					</ToolbarMobileRow>
					<Dialog
						bodyCustomStyles={css`
							padding: 0 !important;
						`}
						title="Add To Post"
						open={showDialog}
						onClose={() => setShowDialog(false)}
					>
						{renderToolbar}
					</Dialog>
				</>
			) : (
				<>{renderToolbar}</>
			)}
		</>
	);
};

export default ToolbarWrapper;
