import styled, { css } from "styled-components";

import { Box } from "shared/ui-kit";

export const ListWrapper = styled(Box)`
	margin: 32px 0 0 -16px;
	width: calc(100% + 32px);
	padding: 0 0 32px 16px;
	display: flex;
	align-items: flex-start;

	.slick-slide {
		margin-right: 8px;
	}

	${({ noPB }) =>
		noPB &&
		css`
			padding-bottom: 0;
		`}
`;

export const ItemWrapper = styled(Box)`
	width: 248px;
	min-width: 248px;
	margin-right: 16px;
`;
