import React, { FC, ReactNode } from "react";

import { Box, ButtonBase } from "@material-ui/core";

import { ReactComponent as MastercardIcon } from "assets/icons/mastercard.svg";
import { ReactComponent as VisaIcon } from "assets/icons/visa.svg";
import AmericanExpress from "assets/images/american-express.png";
import { Text } from "shared/ui-kit";

import { CheckoutSummaryWrapper } from "./style";

const CheckoutSummary: FC<{
	card?: { brand?: string; last4: string };
	summaryItem?: ReactNode;
	itemPrice: number | string;
	onSwitch: () => void;
	isDonation?: boolean;
}> = ({ card, summaryItem, isDonation, itemPrice, onSwitch }) => (
	<CheckoutSummaryWrapper>
		{summaryItem && (
			<>
				<Box className="summary-card">
					{summaryItem}
					<Text>This is a one time {isDonation ? "donation" : "purchase"}.</Text>
					<hr />
					{card && (
						<Box className="card-info">
							<Box>
								{(!card.brand || card.brand) === "Visa" && <VisaIcon />}
								{card.brand === "MasterCard" && <MastercardIcon />}
								{card.brand === "American Express" && <img src={AmericanExpress} alt="American Express" />}

								<Text>•••• {card.last4}</Text>
							</Box>
							<ButtonBase onClick={onSwitch}>Switch</ButtonBase>
						</Box>
					)}
				</Box>
				<Text className="terms">
					{`By clicking “Confirm Payment” you acknowledge and agree that you will be charged $${itemPrice} right away. Read more about our`}{" "}
					<b>Terms & Conditions</b>
				</Text>
			</>
		)}
	</CheckoutSummaryWrapper>
);

export default CheckoutSummary;
