import { useMemo } from "react";

import useHttpActions from "shared/services/api/core/useHttpActions";
import BaseConverter from "shared/services/converters/baseConverter";

import { WorkspaceProductModel, WorkspaceProductPayloadType } from "shared/types";

import { CommunityModel, DefaultResponseType } from "../../types";
import { API_URLS } from "../constants";
import {
	CheckListModelResponse,
	CommunityTypeResponse,
	CreateCommunityType,
	CreateMonetizationPlanResponse,
	CreateMonetizationPlanType,
	CreatedCommunityDataModel,
	DefaultProductResponse,
	MonetizationDataModel,
	SubscriptionUpdate,
	SuggestionDataModel,
	WorkspaceMetaPayloadType,
	WorkspaceMetaResponseType
} from "../types";

const useCommunityService = () => {
	const actions = useHttpActions();

	return useMemo(
		() => ({
			getAllMonetization: async (): Promise<MonetizationDataModel> => {
				return BaseConverter.convert<MonetizationDataModel>(
					await actions.get(API_URLS.MONETIZATION.GET_ALL_MONETIZATION)
				);
			},
			withdrawFunds: async (): Promise<{ success: boolean }> => {
				return BaseConverter.convert<{ success: boolean }>(await actions.get(API_URLS.COMMUNITY.WITHDRAW_FUNDS));
			},
			getUrlSuggestions: async (url: string): Promise<SuggestionDataModel> => {
				return BaseConverter.convert<SuggestionDataModel>(await actions.get(API_URLS.COMMUNITY.GET_SUGGESTIONS(url)));
			},
			searchCommunities: async ({
				keyword,
				offset = 1,
				limit = 10
			}: {
				keyword?: string;
				offset: number;
				limit: number;
			}): Promise<{ workspaces: CommunityModel[] }> => {
				return BaseConverter.convert<{ workspaces: CommunityModel[] }>(
					await actions.get(API_URLS.COMMUNITY.SEARCH, {
						keyword,
						offset,
						limit
					})
				);
			},
			getCheckList: async (userId: string): Promise<CheckListModelResponse> => {
				return BaseConverter.convert<CheckListModelResponse>(
					await actions.get(API_URLS.WORKSPACE_CHECKLIST.GET_CHECKLIST(userId))
				);
			},
			manageChecklist: async (payload): Promise<DefaultResponseType> => {
				return BaseConverter.convert<DefaultResponseType>(
					await actions.put(API_URLS.WORKSPACE_CHECKLIST.UPDATE_CHECKLIST_META, payload)
				);
			},
			getTypeList: async (purpose?: "sports" | "general" | "all"): Promise<CommunityTypeResponse> => {
				return BaseConverter.convert<CommunityTypeResponse>(
					await actions.get(API_URLS.COMMUNITY.GET_TYPE_LIST, { purpose })
				);
			},
			updateWorkspaceMeta: async (payload: WorkspaceMetaPayloadType): Promise<WorkspaceMetaResponseType> => {
				return BaseConverter.convert<WorkspaceMetaResponseType>(
					await actions.put(API_URLS.COMMUNITY.UPDATE_WORKSPACE_META, payload)
				);
			},
			createMonetizationPlan: async (payload: CreateMonetizationPlanType): Promise<CreateMonetizationPlanResponse> => {
				return BaseConverter.convert<CreateMonetizationPlanResponse>(
					await actions.post(API_URLS.MONETIZATION.CREATE_MONETIZATION_PLAN, payload)
				);
			},
			getWorkspaceDetails: async () => {
				return BaseConverter.convert<CommunityModel>(await actions.get(API_URLS.WORKSPACE_SETTINGS.DETAILS));
			},
			getCustomWorkspaceDetails: async (workspace: string) => {
				return BaseConverter.convert<CommunityModel>(
					await actions.get(API_URLS.WORKSPACE_SETTINGS.DETAILS, undefined, {
						headers: {
							workspace
						}
					})
				);
			},
			getSubscriptionProducts: async (listFormat?: boolean) => {
				return BaseConverter.convert<DefaultProductResponse>(
					await actions.get(API_URLS.MONETIZATION.LIST_SUBSCRIPTION_PRODUCTS, {
						type: "subscription",
						format: listFormat ? "listing" : "joined"
					})
				);
			},
			getSubscriptionCanMakeFree: async () => {
				return BaseConverter.convert<{ canMakeFree: boolean }>(
					await actions.get(API_URLS.MONETIZATION.GET_SUBSCRIPTION_CAN_MAKE_IT_FREE)
				);
			},
			putSubscriptionCanMakeFree: async (subscriptionId: string) => {
				return BaseConverter.convert<{ subscriptionId: string }>(
					await actions.put(API_URLS.MONETIZATION.PUT_SUBSCRIPTION_CAN_MAKE_IT_FREE, { subscriptionId })
				);
			},
			deletePremiumProduct: async (payload: { id: string }) => {
				return BaseConverter.convert<{ success: boolean; message?: string }>(
					await actions.delete(API_URLS.MONETIZATION.UPDATE_PREMIUM_PRODUCTS, payload)
				);
			},
			updateSubscriptionType: async (payload: { subscriptionId: string }) => {
				return BaseConverter.convert<{ success: boolean }>(
					await actions.put(API_URLS.MONETIZATION.UPDATE_SUBSCRIPTION_TYPE, payload)
				);
			},
			updatePremiumProduct: async (payload: { data: WorkspaceProductPayloadType[] }) => {
				return BaseConverter.convert<{ products: WorkspaceProductModel[] }>(
					await actions.put(API_URLS.MONETIZATION.UPDATE_PREMIUM_PRODUCTS, payload)
				);
			},
			updateSubscriptionProduct: async (payload: SubscriptionUpdate) => {
				return BaseConverter.convert<{ success: boolean }>(
					await actions.put(API_URLS.MONETIZATION.UPDATE_SUBSCRIPTION_PRODUCTS, payload)
				);
			},
			getDefaultPremiumProducts: async () => {
				return BaseConverter.convert<DefaultProductResponse>(
					await actions.get(API_URLS.MONETIZATION.GET_DEFAULT_PRODUCTS)
				);
			},
			getCoinsList: async () => {
				return BaseConverter.convert<DefaultProductResponse>(
					await actions.get(API_URLS.MONETIZATION.LIST_PREMIUM_PRODUCTS, {
						type: "premium",
						format: "listing",
						coinBags: false
					})
				);
			},
			getBagsList: async () => {
				return BaseConverter.convert<DefaultProductResponse>(
					await actions.get(API_URLS.MONETIZATION.LIST_PREMIUM_PRODUCTS, {
						type: "premium",
						format: "listing",
						coinBags: true
					})
				);
			},
			createCommunity: async (newCommunity: CreateCommunityType): Promise<CreatedCommunityDataModel> => {
				return BaseConverter.convert<CreatedCommunityDataModel>(
					await actions.post(API_URLS.COMMUNITY.CREATE_COMMUNITY, {
						name: newCommunity.name,
						email: newCommunity.owner.email,
						isActive: true,
						isPrivate: newCommunity.isPrivate,
						communityUrl: newCommunity.url,
						meta: {
							firstName: newCommunity.owner.firstname,
							lastName: newCommunity.owner.lastname,
							displayName: newCommunity.name,
							types: newCommunity.typeList,
							theme: newCommunity.theme,
							img: newCommunity.imgUrl
						},
						persona: newCommunity.persona,
						isSportType: newCommunity?.isSportType || false
					})
				);
			},
			getCommunitySmartLink: async () => {
				return BaseConverter.convert<{ link: string }>(await actions.get(API_URLS.COMMUNITY.COMMUNITY_SMART_LINK));
			}
		}),
		[actions]
	);
};

export default useCommunityService;
