import { Box } from "@material-ui/core";
import styled from "styled-components";

import { ReactComponent as IconMailFilled } from "assets/icons/icon-mail-filled.svg";

export const Wrapper = styled(Box)`
	padding: 12px 0 16px 16px;
	border-radius: 12px;
	box-shadow: inset 0 0 0 0.5px #c5cee0, 0 1px 1px 0 rgb(0 0 0 / 8%);
	background-color: #fff;

	h2 {
		font-size: 22px;
		font-weight: bold;
		line-height: 1.45;
		color: #222b45;
	}
`;

Wrapper.TaskWrapper = styled(Box)`
	display: flex;
	flex-wrap: nowrap;
	overflow-x: auto;
	-ms-overflow-style: none;
	scrollbar-width: none;
	&::-webkit-scrollbar {
		display: none;
	}
`;

Wrapper.Task = styled(Box)`
	margin-top: 16px;
	width: 100%;
	padding: 12px 16px;
	border-radius: 12px;
	background-color: #edf1f7;
	flex-shrink: 0;
	&.hide-block {
		display: none !important;
	}
	p {
		font-size: 15px;
		line-height: 1.33;
		color: #222b45;
	}

	${props => props.theme.breakpoints.up("sm")} {
		width: 320px;
		margin-right: 16px;
	}
`;

Wrapper.TaskHeader = styled(Box)`
	display: flex;
	align-items: center;
	margin-bottom: 12px;
	.mail-icon-fill path {
		fill: ${({ color }) => color || "6173fe"};
	}
	h4 {
		font-size: 18px;
		font-weight: bold;
		line-height: 1.33;
		color: #222b45;
		margin-left: 8px;
	}
`;

Wrapper.TaskActions = styled(Box)`
	display: flex;
	justify-content: flex-end;
	button {
		height: 32px;
		padding: 8px 10px;
		border-radius: 8px;
		background-color: ${({ color }) => (color ? color : "#6173fd")};
		color: #fff;
		font-size: 14px;
		font-weight: bold;
		line-height: 1.14;
		&:hover {
			background-color: ${({ color }) => (color ? color : "#6173fd")};
		}

		.MuiCircularProgress-colorPrimary {
			color: #fff;
		}
		&.secondary {
			background-color: #fff;
			color: #222b45;
		}
	}
`;

export const StyledMailIcon = styled(IconMailFilled)`
	width: 24px;
	height: 24px;

	path {
		fill: #6173fe;
	}
`;
