import styled from "styled-components";

import { Box } from "shared/ui-kit";

const Splitter = styled(Box)`
	border-top: 1px solid #c5cee0;
	width: 100%;
	margin-bottom: 28px;
	margin-top: 1px;
`;

export default Splitter;
