import React, { FC } from "react";

import { Box } from "@material-ui/core";

import { useHistory } from "react-router";

import { useMemberRoutes } from "shared/hooks";
import { Text } from "shared/ui-kit";

import { Wrapper } from "./style";

import { SectionHeader } from "../../shared/style";

export interface MemberListProps {
	title: string;
	primaryButton?: {
		text: string;
		onClick: () => void;
	};
	marginBottom?: number;
	marginTop?: number;
}

interface Props extends MemberListProps {
	list: {
		profile?: string;
		name: string;
		subtitle?: string;
		id: number;
	}[];
	noMutual?: JSX.Element;
}

const MembersList: FC<Props> = ({ title, list, primaryButton, marginBottom, marginTop, noMutual }) => {
	const history = useHistory();
	const { getMemberRoutesData } = useMemberRoutes();
	const { routes } = getMemberRoutesData();

	const handleClick = (id: number) => {
		history.push(`${routes?.member.profile.getPath()}/${id}`);
	};

	return (
		<Wrapper marginBottom={marginBottom} marginTop={marginTop}>
			<SectionHeader variant="h4" className="mb-4 ml-2">
				{title}
			</SectionHeader>
			{noMutual ? (
				noMutual
			) : (
				<>
					{list.slice(0, 3).map((item, i) => (
						<Wrapper.MemberRow onClick={() => handleClick(item.id)} key={i}>
							{item.profile && <img alt={item.name} src={item.profile} />}
							<Box>
								<Text variant="h6">{item.name}</Text>
								{item.subtitle && <Text>{item.subtitle}</Text>}
							</Box>
						</Wrapper.MemberRow>
					))}
					{primaryButton && (
						<Wrapper.PrimaryButton palette="outlined" buttonTheme="outline" onClick={primaryButton.onClick}>
							{primaryButton.text}
						</Wrapper.PrimaryButton>
					)}
				</>
			)}
		</Wrapper>
	);
};

export default MembersList;
