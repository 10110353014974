import { Box } from "@material-ui/core";
import styled from "styled-components";

import { Text } from "shared/ui-kit";

export const HintText = styled(Text)`
	font-size: 13px;
	font-weight: normal;
	line-height: 1.23;
	color: #8f9bb3;
	margin-left: 20px;
	margin-top: 5px;
`;

export const SelectLabel = styled(Text)`
	font-size: 13px;
	font-weight: normal;
	line-height: 1.23;
	color: #8f9bb3;
	margin-left: 10px;
	margin-bottom: 5px;
`;

export const InputsRowWrapper = styled(Box)`
	${props => props.theme.breakpoints.up("sm")} {
		display: flex;
		align-items: center;
		> div {
			&:first-child {
				width: 60%;
				margin-right: 16px;
			}
			&:last-child {
				flex: 1;
			}
		}
	}
`;

export const OptionsHeadline = styled(Text)`
	font-size: 18px;
	font-weight: bold;
	line-height: 1.33;
	color: #222b45;
	margin-bottom: 16px;
	margin-top: 8px;
`;

export const ErrorText = styled(Text)`
	color: red;
`;
