import React, { ReactNode, memo, useEffect, useRef } from "react";

import { RefBox } from "modules/MemberHome/View/shared/MemberDialog/style";

import { useOnScreen } from "shared/hooks";

import { Wrapper } from "./style";

interface ScrollableBlockProps {
	children: ReactNode;
	onEndScroll?: () => void;
	loading?: boolean;
}

const ScrollableBlock: React.FC<ScrollableBlockProps> = memo(({ children, loading, onEndScroll }) => {
	const wrapperRef = useRef<HTMLDivElement>(null);

	const onScreen = useOnScreen(wrapperRef);

	useEffect(() => {
		if (onScreen && !loading && onEndScroll) {
			onEndScroll();
		}
	}, [loading, onEndScroll, onScreen]);

	return (
		<Wrapper>
			{children}
			{!loading && <RefBox ref={wrapperRef} />}
		</Wrapper>
	);
});

export default ScrollableBlock;
