import React, { FC, useCallback, useEffect, useRef } from "react";

import { Box } from "@material-ui/core";
import * as R from "ramda";
import { Controller, useForm } from "react-hook-form";

import { LabeledInput, MemberDialog } from "modules/MemberHome/View/shared";

import { InputWrapper, MemberDialogContentWrapper } from "modules/MemberHome/View/shared/style";

import { useVolunteers } from "shared/hooks";
import { EventModel, VolunteerSlot } from "shared/types";
import { CreateUpdateSlot } from "shared/types/VolunteersTypes";

interface CreateUpdateVolunteerSlotProps {
	open;
	volunteerSlotDetail?: VolunteerSlot;
	onClose?;
	event: EventModel | null;
	action: "view" | "create" | "update";
}

const CreateUpdateVolunteerSlot: FC<CreateUpdateVolunteerSlotProps> = ({
	open,
	onClose,
	volunteerSlotDetail,
	event,
	action
}) => {
	const {
		control,
		handleSubmit,
		reset,
		formState: { isDirty, errors, isValid }
	} = useForm({ mode: "onChange" });

	const memberDialogContentRef = useRef<null | HTMLDivElement>(null);

	const { createVolunteerSlots, updateVolunteerSlots, getData: getVolunteerData } = useVolunteers();
	const { creating } = getVolunteerData();

	useEffect(() => {
		if (volunteerSlotDetail) {
			reset({ ...R.pick(["need", "description", "quantity"], volunteerSlotDetail) });
		}
	}, [reset, volunteerSlotDetail]);

	const onSubmit = useCallback(
		async (data: CreateUpdateSlot) => {
			!volunteerSlotDetail
				? event && createVolunteerSlots(data, event.eventId)
				: volunteerSlotDetail?._id && updateVolunteerSlots(data, volunteerSlotDetail._id);
		},
		[createVolunteerSlots, volunteerSlotDetail, event, updateVolunteerSlots]
	);

	const customHandleSubmit = useCallback(
		e => {
			e?.preventDefault && e.preventDefault();
			e?.stopPropagation && e.stopPropagation();
			handleSubmit(onSubmit)();
		},
		[handleSubmit, onSubmit]
	);

	return (
		<MemberDialog
			customWidth={512}
			title={`${volunteerSlotDetail ? (action === "view" ? "Slot Details" : "Update a Slot") : "Create a Slot"}`}
			open={open}
			onClose={onClose}
			confirmLeave={isDirty}
			modalHeight={600}
			footerPrimary={{
				text: volunteerSlotDetail ? "Update" : "Create",
				disabled: creating || !isDirty || !isValid,
				loading: creating,
				id: `${volunteerSlotDetail ? "update" : "create"}VolunteerSlot`,
				onClick: handleSubmit(onSubmit)
			}}
		>
			<form onSubmit={customHandleSubmit}>
				<MemberDialogContentWrapper className="pv-20" ref={memberDialogContentRef}>
					<Box className="flex mb-2">
						<Box className="w-4/6 mr-2">
							<Controller
								name="need"
								control={control}
								rules={{
									required: "Need is required",
									maxLength: {
										value: 40,
										message: "Need can't be more than 40 letters"
									}
								}}
								render={({ onChange, value, ref }) => (
									<InputWrapper>
										<LabeledInput
											label="Need *"
											name="need"
											value={value}
											onChange={onChange}
											placeholder=" "
											error={errors?.need?.message}
											showCounter={40}
											inputRef={ref}
										/>
									</InputWrapper>
								)}
							/>
						</Box>
						<Box className="w-2/6 ml-2">
							<Controller
								name="quantity"
								control={control}
								rules={{
									required: "Quantity is required",
									min: {
										value: volunteerSlotDetail?.filledQuantity || 1,
										message: `${volunteerSlotDetail?.filledQuantity} already signed up`
									}
								}}
								render={({ onChange, value, ref }) => (
									<InputWrapper>
										<LabeledInput
											label="Quantity *"
											name="quantity"
											value={value}
											onChange={onChange}
											placeholder=" "
											error={errors?.quantity?.message}
											inputRef={ref}
											type="number"
											numbersOnly
										/>
									</InputWrapper>
								)}
							/>
						</Box>
					</Box>
					<Controller
						name="description"
						control={control}
						rules={{
							required: "Description is required",
							maxLength: {
								value: 1000,
								message: "Description can't be more than 1000 letters"
							}
						}}
						render={({ onChange, value, ref }) => (
							<InputWrapper>
								<LabeledInput
									label="Description *"
									name="description"
									value={value}
									onChange={onChange}
									placeholder=" "
									showCounter={1000}
									error={errors?.description?.message}
									inputRef={ref}
									textarea
								/>
							</InputWrapper>
						)}
					/>
				</MemberDialogContentWrapper>
			</form>
		</MemberDialog>
	);
};

export default CreateUpdateVolunteerSlot;
