import { useMemo } from "react";

import { API_URLS } from "modules/LoyaltyRewards/constants";
import useHttpActions from "shared/services/api/core/useHttpActions";
import BaseConverter from "shared/services/converters/baseConverter";

import { GetRule, RulePayload } from "../types/RulesType";

const useRulesIncentivesService = () => {
	const actions = useHttpActions();

	return useMemo(
		() => ({
			getRules: async (offset: number, limit: number, keyword?: string) =>
				BaseConverter.convert<{ rules: GetRule[] }>(
					await actions.get(`${API_URLS.RULES}${keyword ? `?keyword=${keyword}` : ""}`, { offset, limit })
				),
			createRule: async (data: RulePayload) =>
				BaseConverter.convert<{ success: boolean; rule: GetRule }>(await actions.post(API_URLS.RULES, data)),
			updateRule: async (data: Partial<RulePayload>, ruleId: number) =>
				BaseConverter.convert<{ success: boolean }>(await actions.put(`${API_URLS.RULES}/${ruleId}`, data)),
			updateRuleActivity: async (active, ruleId: number) =>
				BaseConverter.convert<{ success: boolean }>(await actions.put(`${API_URLS.RULES}/${ruleId}`, { active })),
			deleteRule: async (ruleId: number) =>
				BaseConverter.convert<{ success: boolean }>(await actions.delete(`${API_URLS.RULES}/${ruleId}`))
		}),
		[actions]
	);
};

export default useRulesIncentivesService;
