import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import Skeleton from "@material-ui/lab/Skeleton";

import clsx from "clsx";
import { useHistory } from "react-router-dom";

import { ReactComponent as BlogIcon } from "assets/icons/blog.svg";

import { SelectInput, TwoOptionsSwitch } from "modules/MemberHome/View/shared";
import { useFirebase, useMessaging } from "modules/Messaging/Data";
import { InboxOptions } from "modules/Messaging/Data/contexts/MessagingContext/MessagingContext";
import { INBOX_FILTERS } from "modules/Messaging/constants";

import { useCommunity, useDebounce, useNotification, useUser } from "shared/hooks";
import { MediaModel } from "shared/types";
import { Search, Select } from "shared/ui-kit";

import {
	ActionRow,
	ActionSeparatedRow,
	CreateChatBtn,
	FilterButton,
	InboxAction,
	PanelWrapper,
	RightButtonGroup,
	SearchbarRow,
	SearchbarWrapper,
	SelectWrapper,
	SkeletonRowWrapper,
	SkeletonWrapper
} from "./style";

import { ChatType } from "../../../Data/types";

import CreateChat from "../../Containers/Messaging/CreateChat/index";
import Inbox from "../Inbox";

interface InboxPanelProps {
	widget?: boolean;
	messagingPageUrl?: string;
	messageTo?: string;
	chatId?: string;
	widgetOffset?: number;
	setWidgetOffset?: React.Dispatch<React.SetStateAction<number>>;
}

const InboxPanel: React.FC<InboxPanelProps> = ({
	widget = false,
	messagingPageUrl,
	messageTo,
	chatId,
	widgetOffset,
	setWidgetOffset
}) => {
	const history = useHistory();
	const {
		getActiveProfile,
		setUser,
		getProfilePicture,
		updateUserChatId,
		getData: getUserData,
		isPrivilegedRole,
		getUserInfo
	} = useUser();
	const { user, isMemberView } = getUserData();

	const { getData: getCommunityData, communityColors } = useCommunity();
	const { workspace } = getCommunityData();

	const {
		setInboxType,
		updateInbox,
		findGroupChat,
		setGroupInbox,
		getGroupChatId,
		createGroupChatWithMembers,
		createConnection,
		getArchivedInbox,
		setCurrentInboxTab,
		getData: getMessagingData
	} = useMessaging();
	const { inbox, inboxType, loadingInbox, currentChat } = getMessagingData();

	const { authenticateUser, addToGroupMembers, setCurrentChat } = useFirebase();
	const { showMessage } = useNotification();

	const [keyword, setKeyword] = useState("");
	const [panelHeight, setPanelHeight] = useState<number>();
	const [offset, setOffset] = useState(1);
	const [redirected, setRedirected] = useState(false);
	const [pinned, setPinned] = useState(false);

	const panelRef = useRef<HTMLDivElement>();
	const inboxActionRef = useRef<HTMLDivElement>();

	const [InboxFilter, setInboxFilter] = useState(INBOX_FILTERS.ALL);
	const [showInvitePeoplePopUp, toggleInvitePeoplePopUp] = useState(false);

	const debouncedKeyword = useDebounce(keyword, 400);

	const activeProfile = useMemo(() => getActiveProfile(user), [getActiveProfile, user]);

	const checkChatByProfile = useCallback(
		async (messageTo: string) => {
			const personaId = activeProfile!.personaId;

			const members = [Number(messageTo), personaId].map(item => ({
				personaId: item,
				isOwner: personaId === item,
				isMember: true,
				notificationEnabed: true
			}));

			if (members.length > 2) {
				const groupChatExists = await getGroupChatId([Number(messageTo)]);

				if (groupChatExists?.groupChatId) {
					const findConversation = inbox?.find(item => item._id === groupChatExists.groupChatId);
					findConversation && setCurrentChat(findConversation);
				} else {
					await createGroupChatWithMembers(members, personaId!)?.then(groupChatResponse => {
						if (groupChatResponse?.groupChat._id) {
							setCurrentChat({ ...groupChatResponse.groupChat, type: ChatType.GROUP });
							showMessage("Group chat was created! ✨");
						}
					});
				}
			} else {
				let chatResponse = await createConnection(members[0].personaId);

				if (isPrivilegedRole && !chatResponse && user?.activeProfile) {
					const { persona } = await getUserInfo(members[0].personaId, user.activeProfile);

					chatResponse = {
						chatUserId: persona.chatUserId,
						connectionId: "",
						initiatorId: "",
						createdAt: `${new Date()}`,
						firstName: persona.firstName,
						lastName: persona.lastName,
						notifyToken: null,
						personaId: members[0].personaId,
						profilePhoto: !!persona.photos.length ? (persona.photos[0] as MediaModel).profilePicture : "",
						purpose: "business",
						lastMessage: "",
						type: ChatType.CONNECTION,
						allowMembersInvite: true,
						members: [],
						name: ""
					};
				}

				setCurrentChat(chatResponse);
				setRedirected(true);
			}
		},
		[
			activeProfile,
			getGroupChatId,
			inbox,
			setCurrentChat,
			createGroupChatWithMembers,
			showMessage,
			createConnection,
			isPrivilegedRole,
			user,
			getUserInfo
		]
	);

	const checkGroupEventChat = useCallback(
		async (chatId: string) => {
			const chat = inbox?.find(item => item._id === chatId);
			if (chat) {
				setCurrentChat(chat);
				setRedirected(true);
			} else {
				const { groupChat } = await findGroupChat(chatId);
				if (groupChat) {
					setCurrentChat(groupChat);
					setRedirected(true);
				}
			}
		},
		[findGroupChat, inbox, setCurrentChat]
	);

	useEffect(() => {
		updateInbox({ offset: 1, limit: 20, personaId: getActiveProfile(user)?.personaId, pinned });
	}, [updateInbox, getActiveProfile, user, pinned]);

	useEffect(() => {
		if (messageTo && !redirected && messageTo !== currentChat?.personaId?.toString()) {
			checkChatByProfile(messageTo);
		}
	}, [checkChatByProfile, currentChat, messageTo, redirected]);

	useEffect(() => {
		if (chatId && !redirected && chatId !== (currentChat?._id || "")) {
			checkGroupEventChat(chatId);
		}
	}, [chatId, checkGroupEventChat, currentChat, redirected]);

	useEffect(() => {
		const personaId = activeProfile?.personaId;
		const chatId = !!activeProfile?.chatUserId;

		if (personaId && user?.email && workspace && !user.profiles[0]?.chatUserId) {
			try {
				authenticateUser(personaId, workspace, chatId).then(res => {
					if (res) {
						updateUserChatId(personaId.toString(), res.user.uid);
						setUser({ ...user, profiles: [{ ...user.profiles[0], chatUserId: res.user.uid }] });
					}
				});
			} catch (error) {
				if ((error as any)?.code !== "app/duplicate-app") showMessage((error as Error).message);
			}

			addToGroupMembers({
				_id: activeProfile?.chatUserId,
				name: activeProfile?.firstName,
				avatar: getProfilePicture(user)
			});
		}
	}, [
		authenticateUser,
		addToGroupMembers,
		getProfilePicture,
		showMessage,
		user,
		workspace,
		updateUserChatId,
		setUser,
		activeProfile
	]);

	useEffect(() => {
		const resize = () => {
			setPanelHeight(panelRef.current?.clientHeight);
		};
		resize();
		window.addEventListener("resize", resize);

		return () => window.removeEventListener("resize", resize);
	}, []);

	const chatOptions = useMemo(
		() => [
			{
				label: "Active Chats",
				value: INBOX_FILTERS.ALL
			},
			{
				label: "Group Chats",
				value: INBOX_FILTERS.GROUP
			},
			{
				label: "Pinned Chats",
				value: INBOX_FILTERS.PINNED
			},
			{
				label: "Archived Chats",
				value: INBOX_FILTERS.ARCHIVED
			}
		],
		[]
	);

	const handleChangeChat = useCallback(
		(val: string) => {
			setInboxFilter(val);
			switch (val) {
				case INBOX_FILTERS.GROUP:
					setCurrentInboxTab(InboxOptions.GROUP_CHATS);
					setPinned(false);
					return setGroupInbox();
				case INBOX_FILTERS.PINNED:
					setCurrentInboxTab(InboxOptions.PINNED_CHATS);
					return setPinned(true);
				case INBOX_FILTERS.ARCHIVED:
					setCurrentInboxTab(InboxOptions.ARCHIVED_CHATS);
					return getArchivedInbox(1, 20);
				default:
					setCurrentInboxTab(InboxOptions.ALL_CHATS);
					setPinned(false);
					updateInbox({ offset: 1, limit: 20, personaId: getActiveProfile(user)?.personaId });
					break;
			}
		},
		[getActiveProfile, getArchivedInbox, setCurrentInboxTab, setGroupInbox, updateInbox, user]
	);

	return (
		<PanelWrapper ref={panelRef}>
			{loadingInbox && (widgetOffset || offset) === 1 ? (
				<SkeletonWrapper>
					<SkeletonRowWrapper>
						<Skeleton width="84%" height={66} />
						<Skeleton width="12%" height={66} />
					</SkeletonRowWrapper>
					<SkeletonRowWrapper>
						<Skeleton width="38%" height={66} />
						<Skeleton width="48%" height={66} />
					</SkeletonRowWrapper>
				</SkeletonWrapper>
			) : (
				<InboxAction ref={inboxActionRef}>
					{isMemberView && !widget && <InboxAction.Heading>Messages</InboxAction.Heading>}
					<ActionRow className={clsx(widget && "pb-0")}>
						<SearchbarRow>
							<SearchbarWrapper className={isMemberView ? "member-search" : "admin-search"}>
								<Search
									autoComplete="off"
									defaultValue=""
									value={keyword}
									onChange={e => setKeyword(e.target.value)}
									placeholder="Search"
								/>
							</SearchbarWrapper>
							<CreateChatBtn
								color={communityColors.primary}
								buttonTheme="main"
								palette="primary"
								size="large"
								className={isMemberView && "rounded-btn"}
								onClick={() => toggleInvitePeoplePopUp(true)}
							>
								<BlogIcon fill="#fff" height={32} width={32} />
							</CreateChatBtn>
						</SearchbarRow>
					</ActionRow>
					{!widget && (
						<ActionSeparatedRow memberView={isMemberView}>
							<SelectWrapper>
								{isMemberView ? (
									<SelectInput
										customHeight="40px"
										name="chats"
										value={chatOptions.find(chat => chat.value === InboxFilter)?.label || InboxFilter}
										onChange={val => handleChangeChat(val.value)}
										placeholder=" "
										options={chatOptions}
										maxHeight={180}
										displayOnly
									/>
								) : (
									<Select
										onChange={val => handleChangeChat(val)}
										value={InboxFilter}
										label="Select"
										showInputLabel={{ show: true }}
										id="true"
										showIcon={true}
										options={chatOptions}
									/>
								)}
							</SelectWrapper>
							{isMemberView ? (
								<TwoOptionsSwitch
									onChange={choice => setInboxType(choice)}
									optionOne={{
										label: "Recent",
										value: INBOX_FILTERS.RECENT
									}}
									optionTwo={{
										label: "Unread",
										value: INBOX_FILTERS.UNREAD
									}}
								/>
							) : (
								<RightButtonGroup>
									<FilterButton
										className={inboxType === INBOX_FILTERS.RECENT && "active"}
										onClick={() => setInboxType(INBOX_FILTERS.RECENT)}
									>
										Recent
									</FilterButton>
									<FilterButton
										className={inboxType === INBOX_FILTERS.UNREAD && "active"}
										onClick={() => setInboxType(INBOX_FILTERS.UNREAD)}
									>
										Unread
									</FilterButton>
								</RightButtonGroup>
							)}
						</ActionSeparatedRow>
					)}
				</InboxAction>
			)}
			<Inbox
				keyword={debouncedKeyword}
				widget={widget}
				messagingPageUrl={messagingPageUrl}
				availableHeight={panelHeight ? panelHeight - (inboxActionRef.current?.clientHeight || 129) : undefined}
				offset={widgetOffset || offset}
				setOffset={setWidgetOffset || setOffset}
			/>
			{showInvitePeoplePopUp && (
				<CreateChat
					open={showInvitePeoplePopUp}
					onClose={(e, type) => {
						toggleInvitePeoplePopUp(false);
						if (type !== undefined && widget && messagingPageUrl) {
							history.push(messagingPageUrl);
						}
					}}
				/>
			)}
		</PanelWrapper>
	);
};

export default InboxPanel;
