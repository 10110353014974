import { useEffect, useMemo, useState } from "react";

import { AdZone, getRandomAD } from "utils/ads/getRandomAd";
import { isAdSafe } from "utils/ads/isAdSafe";

import useCommunity from "./useCommunity";
import useFeature from "./useFeature";

function usePopulateWithAds<T>({
	list,
	zone,
	every = 10,
	skip = 5
}: {
	list: T[];
	zone: AdZone;
	every?: number;
	skip?: number;
}) {
	const { isCommunityFeatureEnabled } = useFeature();
	const { getData: getCommunityData } = useCommunity();
	const { workspace } = getCommunityData();

	const isAdsEnabled = useMemo(() => isCommunityFeatureEnabled("enableBannerAds"), [isCommunityFeatureEnabled]);
	const adConfig = useMemo(() => workspace?.bannerAdConfig, [workspace?.bannerAdConfig]);

	const [listWithAds, setListWithAds] = useState<(T | string)[]>([]);

	useEffect(() => {
		if (!adConfig || !isAdsEnabled) {
			setListWithAds(list);
		} else {
			const finalList: (T | string)[] = [...list];

			const ads = Math.floor((list.length - skip) / every);
			const adsIndexes = Array.from({ length: ads }, (_, i) => i * every + skip);

			adsIndexes.forEach(idx => {
				if (typeof finalList[idx] === "string" && (finalList[idx] as string).includes("iframe")) return;
				const adString = getRandomAD(zone, adConfig);
				const isSafe = isAdSafe(adString);

				if (adString && isSafe) finalList.splice(idx, 0, adString);
			});

			setListWithAds(finalList);
		}
	}, [adConfig, every, isAdsEnabled, list, skip, zone]);

	return listWithAds;
}

export default usePopulateWithAds;
