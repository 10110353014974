import React, { FC, useCallback, useEffect, useMemo, useState } from "react";

import { Box } from "@material-ui/core";
import { useHistory } from "react-router";

import styled from "styled-components";

import { ForumPostModel } from "types";

import { Connection } from "types/StoriesContextValuesType";

import { ReactComponent as FacebookIcon } from "assets/icons/facebook-rounded.svg";
import { ReactComponent as InstagramIcon } from "assets/icons/instagram-rounded.svg";
import { ReactComponent as LinkedInIcon } from "assets/icons/linkedin-rounded.svg";
import { ReactComponent as TiktokIcon } from "assets/icons/tiktok-rounded.svg";
import { ReactComponent as TwitterIcon } from "assets/icons/twitter-rounded.svg";

import { useConnection, useFeature, useMemberRoutes, usePersona, usePosts, useUser } from "shared/hooks";
import { MediaModel, PersonStoryModel, ProfileType } from "shared/types";
import { ExperienceLocationType, MutualConnection } from "shared/types/UserModel";
import { Icon } from "shared/ui-kit";

import ProfileSkeleton from "./ProfileSkeleton";
import { RecommendedConnections } from "./style";

import {
	AboutProfile,
	AddGoalsDialog,
	AddInterestsDialog,
	AddLanguagesDialog,
	AddSkillsDialog,
	AddSocialAccountsDialog,
	Delimiter,
	EditProfileDialog,
	Groups,
	InterestsBlock,
	MembersList,
	ModelBlock,
	MyActivities,
	MyConnectionsDialog,
	MyExperienceDialog,
	MyInfoBlock,
	NewEducationDialog,
	NewJobDialog,
	NewLicenseDialog,
	NoProfileDataTemp,
	ProfileDetailsDialog,
	ProfileHeader
} from "../../Components";
import { ConnectionAction } from "../../Components/Connections/ConnectionCard";
import { ModelBlockType } from "../../Components/ModelBlock";

const Container = styled(Box)`
	max-width: 960px;
	margin: auto;
	padding: 0 16px;
	margin-bottom: 32px;
`;

const Row = styled(Box)`
	${props => props.theme.breakpoints.up("md")} {
		display: flex;
		align-items: flex-start;
		margin-top: 16px;
		> div:first-child {
			margin-right: 16px;
			width: calc(100% - 336px);
		}
	}
`;

interface MemberProfilePageProps {
	id?: string;
	connectionsPageUrl: string;
}

const MemberProfile: FC<MemberProfilePageProps> = ({ id, connectionsPageUrl }) => {
	const history = useHistory();

	const { isCommunityFeatureEnabled } = useFeature();

	const { setPersona, getData: getPersonaData } = usePersona();
	const { persona } = getPersonaData();

	const {
		getTopConnections,
		setAddSkillsDialog,
		setAddGoalsDialog,
		setAddSocialAccountsDialog,
		setAddInterestsDialog,
		setAddLanguagesDialog,
		setMyConnectionsDialog,
		setMyExperienceDialog,
		setProfileDetailsDialog,
		getPotentialMatchIds,
		getUserInfo,
		getMutualConnections,
		getData: getUserData
	} = useUser();

	const {
		user,
		updateUserDialog,
		addSkillsDialog,
		addGoalsDialog,
		addLanguagesDialog,
		addInterestsDialog,
		addSocialAccountsDialog,
		myConnectionsDialog,
		myExperienceDialog,
		newJobDialog,
		newVolunteerDialog,
		newEducationDialog,
		newLicenseDialog,
		profileDetailsDialog
	} = getUserData();

	const { getRecommendedConnections, manageConnection, requestConnection } = useConnection();
	const [profileLoading, setProfileLoading] = useState(false);
	const [userDetails, setUserDetails] = useState<ProfileType | null>(null);
	const [posts, setPosts] = useState<ForumPostModel[]>([]);
	const [connections, setConnections] = useState<{
		totalCount: number;
		connections: (Connection | MutualConnection)[];
	}>();
	const [displayLimits, setDisplayLimits] = useState({
		skills: 5,
		goals: 5,
		interests: 5,
		languages: 3
	});
	const [recommendedConnections, setRecommendedConnections] = useState<PersonStoryModel[]>([]);
	const [refresh, setRefresh] = useState<number>(0);

	const { getMemberRoutesData } = useMemberRoutes();
	const { routes } = getMemberRoutesData();

	const isMyProfile = useMemo(() => !id || parseInt(id) === persona?.personaId, [id, persona?.personaId]);

	const userData = useMemo(() => (isMyProfile ? persona : userDetails), [isMyProfile, persona, userDetails]);

	const hasSocialMedia = useMemo(
		() =>
			!!userData &&
			(!!userData.linkedAccount?.facebook || !!userData.linkedAccount?.instagram || !!userData.linkedAccount?.linkedin),
		[userData]
	);

	const isHidden = useMemo(
		() => !isMyProfile && !userData?.vyooable && (!userData?.connection || userData.connection.status !== "accepted"),
		[isMyProfile, userData]
	);

	const { getUserPosts } = usePosts();

	useEffect(() => {
		if (isHidden) {
			const matchingIds = getPotentialMatchIds(user);
			getRecommendedConnections({ limit: 10, offset: 1, potentialMatchingIds: matchingIds }).then(data => {
				setRecommendedConnections(data.stories);
			});
		}
	}, [getPotentialMatchIds, getRecommendedConnections, isHidden, user]);

	useEffect(() => {
		if (isMyProfile && user && !persona) {
			setPersona(user.profiles[0]);
		}
	}, [isMyProfile, persona, setPersona, user]);

	useEffect(() => {
		if (user?.activeProfile && id && !isMyProfile) {
			(async () => {
				setProfileLoading(true);
				await getUserInfo(parseInt(id), user.activeProfile as number).then(({ persona }) => {
					setUserDetails(persona);
				});
				setProfileLoading(false);
			})();
		}
	}, [getUserInfo, id, isMyProfile, user?.activeProfile]);

	useEffect(() => {
		if (isMyProfile && user?.personaDocId) {
			getUserPosts(user.personaDocId).then(({ posts }) => {
				setPosts(posts);
			});
		}

		if (!isMyProfile && userDetails?.personaDocId) {
			getUserPosts(userDetails.personaDocId).then(({ posts }) => {
				setPosts(posts);
			});
		}
	}, [getUserPosts, isMyProfile, user, userDetails, refresh]);

	useEffect(() => {
		if (isMyProfile) {
			getTopConnections().then(({ totalCount, connections }) => {
				setConnections({
					totalCount,
					connections
				});
			});
		} else if (user && user.activeProfile && userData) {
			getMutualConnections(user.activeProfile, userData.personaId, 2, 1).then(({ connections, total }) => {
				setConnections({
					totalCount: total,
					connections
				});
			});
		}
	}, [getMutualConnections, getTopConnections, isMyProfile, user, userData]);

	const handleConnect = useCallback(async () => {
		if (userDetails && user?.activeProfile && id && !isMyProfile) {
			const { connectionId } = await requestConnection(userDetails.personaId);
			connectionId &&
				getUserInfo(parseInt(id), user.activeProfile).then(({ persona }) => {
					setUserDetails(persona);
				});
		}
	}, [userDetails, user, id, isMyProfile, requestConnection, getUserInfo]);

	const handleManage = useCallback(
		async ({ connection, action }: { connection: ProfileType; action: ConnectionAction }) => {
			if (connection) {
				switch (action) {
					case ConnectionAction.accept:
						const { success: approvedSuccess } = await manageConnection({
							connectionId: `${connection._id}`,
							isAccept: true
						});
						approvedSuccess &&
							setUserDetails(
								ctx => ctx && { ...ctx, connection: ctx.connection && { ...ctx.connection, status: "accepted" } }
							);
						break;

					case ConnectionAction.decline:
						const { success: declinedSuccess } = await manageConnection({
							connectionId: `${connection._id}`,
							isAccept: false
						});
						declinedSuccess &&
							setUserDetails(
								ctx => ctx && { ...ctx, connection: ctx.connection && { ...ctx.connection, status: "declined" } }
							);
						break;

					case ConnectionAction.requestConnect:
						requestConnection(connection.personaId);
						break;
				}
			}
		},
		[manageConnection, requestConnection]
	);
	const isLiteProfile = useMemo(() => isCommunityFeatureEnabled("enableProfileLite"), [isCommunityFeatureEnabled]);

	const getConnectionInfo = useMemo(
		() =>
			(con: Connection | MutualConnection, isMutual = false) => {
				if (isMutual) {
					return {
						profile: (con as MutualConnection)?.photos?.length
							? (con as MutualConnection).photos[0]?.profilePicture || ""
							: "",
						name: `${(con as MutualConnection).firstName} ${(con as MutualConnection).lastName}`,
						id: (con as MutualConnection).personaId
					};
				} else if ((con as Connection)?.persona?.length) {
					const personaInfo = (con as Connection)?.persona[0];
					return {
						profile: personaInfo?.photos?.length ? (personaInfo.photos[0] as MediaModel)?.profilePicture || "" : "",
						name: `${personaInfo.firstName} ${personaInfo.lastName}`,
						id: personaInfo.personaId
					};
				}

				return {
					profile: "",
					name: "",
					id: -1
				};
			},
		[]
	);

	if (profileLoading) return <ProfileSkeleton />;
	else if (!userData) return null;

	return (
		<>
			<Container>
				<ProfileHeader
					me={isMyProfile}
					isLiteProfile={isLiteProfile}
					handleConnect={handleConnect}
					handleManage={handleManage}
					profile={userData}
				/>
				{isHidden ? (
					<RecommendedConnections>
						<Delimiter />
						<RecommendedConnections.Header variant="h2">People you may know</RecommendedConnections.Header>
						<ModelBlock
							title=""
							type={ModelBlockType.connection}
							items={recommendedConnections}
							modelType={"suggestion"}
							onManage={handleManage}
							noContent={"You don’t have any suggested matches to connect with."}
						/>
					</RecommendedConnections>
				) : (
					<Row>
						<Box>
							<AboutProfile
								me={isMyProfile}
								profile={userData}
								editable={isMyProfile ? () => setProfileDetailsDialog(true) : undefined}
							/>
							<Groups
								fetchMyGroups
								title={`${isMyProfile ? "My" : `${userData.firstName}'s`} Groups`}
								groupsPageUrl={routes?.member.groups.getPath()}
							/>
							{(!!posts.length || isMyProfile) && (
								<MyActivities
									noData={isMyProfile && !posts.length}
									title={`${isMyProfile ? "My" : `${userData.firstName}'s`} Activities`}
									posts={posts}
									refresh={refresh}
									setRefresh={setRefresh}
								/>
							)}
							{!isLiteProfile && (
								<>
									{(!!userData.jobs?.length || isMyProfile) && (
										<MyInfoBlock
											editable={isMyProfile ? () => setMyExperienceDialog(true) : undefined}
											displayLimit={2}
											data={userData.jobs}
											title={`${isMyProfile ? "My" : `${userData.firstName}'s`} experience`}
										/>
									)}
									{(!!userData.education?.length || isMyProfile) && (
										<MyInfoBlock
											editable={
												isMyProfile ? () => setMyExperienceDialog(true, ExperienceLocationType.Education) : undefined
											}
											displayLimit={1}
											data={userData.education}
											title="Education"
											eduView
										/>
									)}
									{(!!userData.volunterExperience?.length || isMyProfile) && (
										<MyInfoBlock
											editable={isMyProfile ? () => setMyExperienceDialog(true) : undefined}
											displayLimit={1}
											data={userData.volunterExperience}
											title="Volunteer experience"
											volunteerView
										/>
									)}
									{(!!userData.licensesAndCertifications?.length || isMyProfile) && (
										<MyInfoBlock
											editable={
												isMyProfile ? () => setMyExperienceDialog(true, ExperienceLocationType.License) : undefined
											}
											displayLimit={2}
											data={userData.licensesAndCertifications}
											title="Certifications"
											certificationView
										/>
									)}
								</>
							)}
						</Box>
						<Box>
							<MembersList
								title={`${isMyProfile ? "My" : "Mutual"} Connections (${connections?.totalCount || 0})`}
								list={
									!!connections?.connections
										? isMyProfile
											? connections.connections.map(con => getConnectionInfo(con))
											: connections.connections.map(con => getConnectionInfo(con, true))
										: []
								}
								noMutual={
									!connections?.connections.length ? (
										<NoProfileDataTemp
											icon={<Icon name="user-friend" group="filled" width={40} height={40} fill="#c5cee0" />}
											subtitle={`Once you have ${isMyProfile ? "friends" : "mutual friends"} you will see them here`}
											actions={
												isMyProfile
													? [
															{
																onClick: () => {
																	history.push(connectionsPageUrl);
																},
																icon: <Icon name="search" fill="#ffffff" width={16} height={16} />,
																title: "Find Connections"
															}
													  ]
													: undefined
											}
										/>
									) : undefined
								}
								primaryButton={
									connections?.totalCount && connections?.totalCount > 2
										? { text: "See All", onClick: () => setMyConnectionsDialog(true) }
										: undefined
								}
								marginBottom={16}
							/>
							{!isLiteProfile && (!!userData.skills?.length || isMyProfile) && (
								<InterestsBlock
									displayLimit={displayLimits.skills}
									title="Skills"
									list={(userData.skills || []).map(skill => skill.label)}
									primaryButton={
										(userData.skills || []).length > displayLimits.skills
											? { text: "See All", onClick: () => setDisplayLimits({ ...displayLimits, skills: 99 }) }
											: undefined
									}
									editable={isMyProfile ? () => setAddSkillsDialog(true) : undefined}
									marginBottom={16}
								/>
							)}
							{(!!userData.goals?.length || isMyProfile) && (
								<InterestsBlock
									displayLimit={displayLimits.goals}
									title="Goals"
									list={(userData.goals || []).map(goal => goal.label)}
									primaryButton={
										(userData.goals || []).length > displayLimits.goals
											? { text: "See All", onClick: () => setDisplayLimits({ ...displayLimits, goals: 99 }) }
											: undefined
									}
									editable={isMyProfile ? () => setAddGoalsDialog(true) : undefined}
									marginBottom={16}
								/>
							)}
							{(!!userData.interests?.length || isMyProfile) && (
								<InterestsBlock
									displayLimit={displayLimits.interests}
									title="Interests"
									list={(userData.interests || []).map(int => int.label)}
									primaryButton={
										(userData.interests || []).length > displayLimits.interests
											? { text: "See All", onClick: () => setDisplayLimits({ ...displayLimits, interests: 99 }) }
											: undefined
									}
									editable={isMyProfile ? () => setAddInterestsDialog(true) : undefined}
									marginBottom={16}
								/>
							)}
							{(hasSocialMedia || isMyProfile) && (
								<InterestsBlock
									socialView
									displayLimit={5}
									title="Linked Accounts"
									list={[
										{
											name: "Facebook",
											icon: <FacebookIcon />,
											url: userData?.linkedAccount?.facebook,
											bg: "#1a77f2"
										},
										{
											name: "Instagram",
											icon: <InstagramIcon />,
											url: userData?.linkedAccount?.instagram,
											bg: "#e12c6f"
										},
										{
											name: "LinkedIn",
											icon: <LinkedInIcon />,
											url: userData?.linkedAccount?.linkedin,
											bg: "#1ea1f1"
										},
										{
											name: "Twitter",
											icon: <TwitterIcon />,
											url: userData?.linkedAccount?.twitter,
											bg: "#1ea1f1"
										},
										{
											name: "Tiktok",
											icon: <TiktokIcon />,
											url: userData?.linkedAccount?.tiktok,
											bg: "#1ea1f1"
										}
									].filter(social => !!social.url)}
									editable={isMyProfile ? () => setAddSocialAccountsDialog(true) : undefined}
									marginBottom={16}
								/>
							)}
							{(!!userData.languages?.length || isMyProfile) && (
								<InterestsBlock
									languageView
									displayLimit={displayLimits.languages}
									primaryButton={
										(userData.languages || []).length > displayLimits.languages
											? { text: "See All", onClick: () => setDisplayLimits({ ...displayLimits, languages: 99 }) }
											: undefined
									}
									title="Languages"
									list={(userData.languages || []).map(lang => lang.label)}
									editable={isMyProfile ? () => setAddLanguagesDialog(true) : undefined}
								/>
							)}
						</Box>
					</Row>
				)}
			</Container>
			{profileDetailsDialog && <ProfileDetailsDialog />}
			{updateUserDialog && <EditProfileDialog isLiteProfile={isLiteProfile} />}
			{myConnectionsDialog && (
				<MyConnectionsDialog
					isMyProfile={isMyProfile}
					viewerPersonaId={user?.activeProfile}
					displayedPersonaId={userData.personaId}
				/>
			)}
			{addSkillsDialog && <AddSkillsDialog />}
			{addGoalsDialog && <AddGoalsDialog />}
			{addInterestsDialog && <AddInterestsDialog />}
			{addSocialAccountsDialog && <AddSocialAccountsDialog />}
			{addLanguagesDialog && <AddLanguagesDialog />}
			{myExperienceDialog && <MyExperienceDialog />}
			{newJobDialog?.open && <NewJobDialog item={newJobDialog.item} />}
			{newVolunteerDialog?.open && <NewJobDialog volunteer item={newVolunteerDialog.item} />}
			{newEducationDialog?.open && <NewEducationDialog item={newEducationDialog.item} />}
			{newLicenseDialog?.open && <NewLicenseDialog item={newLicenseDialog.item} />}
		</>
	);
};

export default MemberProfile;
