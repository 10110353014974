import React, { useCallback, useMemo } from "react";

import useLocalStorage from "shared/services/localStorage/localStorage";

import { useS3UploaderStore } from "../contexts";

import useS3UploaderApiService from "../services/useS3UploaderApiService";

const useS3Uploader = () => {
	const store = useS3UploaderStore();
	const { setState } = useS3UploaderStore();

	const s3UploaderApiService = useS3UploaderApiService();

	const localStorage = useLocalStorage();

	const getSignedUrl = React.useMemo(
		() => async (name: string, type: string, communityName: string, noBucket: boolean) => {
			return await s3UploaderApiService.getSignedUrl(name, type, communityName, noBucket);
		},
		[s3UploaderApiService]
	);

	const methods = useMemo(
		() => ({
			uploadFile: async ({
				file,
				communityName,
				noBucket = true,
				checkProgress = false,
				handleProgressUpdate,
				customCallback,
				refId
			}: {
				file: File;
				communityName: string;
				noBucket?: boolean;
				checkProgress?: boolean;
				handleProgressUpdate?: (progress: number) => void;
				customCallback?: ({ fileName, publicUrl }: { fileName: string; publicUrl: string }) => void;
				refId?: string;
			}): Promise<{
				publicUrl: string;
				fileName?: string;
			} | null> => {
				let name = file.name;
				// replace all special characters with underscore, but save an extension of file
				const extension = name.split(".").pop() || "";
				name = name.slice(0, name.length - extension.length - 1);
				name = name.replace(/[&\/\\#, +()$~%.'":*?<>{}]/g, "_") + `.${extension}`;

				const type = file.type || name.split(".").pop() || "unknown";

				setState(ctx => {
					ctx.loadingSignedUrl = true;
					ctx.uploadingProgress = 0;
				});

				const signedInfo = await getSignedUrl(name, type, communityName, noBucket);

				setState(ctx => {
					ctx.loadingSignedUrl = false;
				});

				if (signedInfo) {
					setState(ctx => {
						ctx.uploading = true;
					});

					if (refId) {
						setState(ctx => ({ uploadingIds: [...ctx.uploadingIds, refId] }));
					}

					try {
						await s3UploaderApiService.uploadFile(
							signedInfo.signedUrl,
							file,
							type,
							checkProgress || handleProgressUpdate
								? uploadingProgress => {
										handleProgressUpdate
											? handleProgressUpdate(uploadingProgress)
											: setState(ctx => {
													ctx.uploadingProgress = uploadingProgress;
											  });
								  }
								: () => {},
							refId
						);

						setState(ctx => {
							ctx.uploading = false;
						});

						if (refId) {
							setState(ctx => ({ uploadingIds: ctx.uploadingIds.filter(id => id !== refId) }));
						}

						let link = signedInfo.publicUrl;
						const splitWord = "/uploads/";
						const cdn = localStorage.get("cdnUrl", null);
						if (cdn && link.includes(splitWord)) {
							const parts = link.split(splitWord);
							link = `${cdn}${splitWord}${parts[1]}`;
						}
						customCallback &&
							customCallback({
								fileName: signedInfo.filename,
								publicUrl: link
							});
						return {
							fileName: signedInfo.filename,
							publicUrl: link
						};
					} catch {
						return {
							fileName: "",
							publicUrl: ""
						};
					}
				}
				return null;
			},
			abortUploading() {
				s3UploaderApiService.abortRequests();
				setState(ctx => ({ ...ctx, loadingSignedUrl: false, uploadingProgress: 0, uploading: false }));
			},
			aboutRequestsById(ids: string[]) {
				s3UploaderApiService.aboutRequestsById(ids);
			},
			setUploadingIds(uploadingIds: string[] | []) {
				setState({ uploadingIds });
			}
		}),
		[s3UploaderApiService, setState, getSignedUrl, localStorage]
	);

	const getData = useCallback(() => {
		return store;
	}, [store]);

	return { ...methods, getData };
};

export default useS3Uploader;
