import React, { useCallback, useEffect, useMemo, useState } from "react";

import { useHistory } from "react-router";

import { ReactComponent as LockIcon } from "assets/icons/iconLock.svg";

// TEMPORARY hide until clarify what to do with it
// import SubscribeDialog from "modules/MemberHome/View/shared/SubscribeDialog";
import { useAuth } from "modules/App/Data";

import { BannedUserModal, PlaceholderImage } from "shared/Components";
// import { useStripe, useUser } from "shared/hooks";
import { useCommunity, useMarketing, useMemberRoutes, useUser } from "shared/hooks";
import useJwt from "shared/services/api/core/jwt";
import { IWorkspace, PlaceholderImageType, UserStatus } from "shared/types";
import { Icon } from "shared/ui-kit";

// import { MonetizationPlanSubscriptionId } from "shared/types/MonetizationPlansType";
import {
	Back,
	ContentBlock,
	InfoWrapper,
	ManageBtnList,
	PrivateBlock,
	StyledAvatar,
	StyledButton,
	SubTitle,
	Title
} from "./styles";

import { JoinPrivateCommunityModal } from "../index";

interface HeaderProps {
	imgUrl?: string;
	coverImgUrl?: string;
	title: string;
	subtitle: string;
	isPrivate: boolean;
	homeUrl: string;
	loginUrl: string;
	marketingUrl: string;
	fillProfileUrl: string;
	communityListRoute: string;
	joinCommunitiesUrl: string;
	communityName: string;
	switchCommunity: boolean;
}

const Header: React.FC<HeaderProps> = ({
	imgUrl,
	coverImgUrl,
	title,
	subtitle,
	isPrivate,
	homeUrl,
	loginUrl,
	fillProfileUrl,
	communityListRoute,
	joinCommunitiesUrl,
	communityName,
	switchCommunity
}) => {
	const { getWorkspaceMonetization, getData: getMarketingData, getWorkspaceByName } = useMarketing();
	const { workspace } = getMarketingData();

	const { setWorkspace, resetStore } = useCommunity();

	const { joinWorkspace, activateAccount, getData: getUserData } = useUser();
	const { workspaces, user } = getUserData();

	const { login, joinCommunity, getData: getAuthData } = useAuth();
	const { email } = getAuthData();

	const { generateRoutes } = useMemberRoutes();

	const jwtService = useJwt();
	const history = useHistory();
	// const { setSubscribeDialog } = useStripe();

	const [showInvitationCodeModal, setShowInvitationCodeModal] = useState(false);
	const [tempUserId, setTempUserId] = useState("");

	const [showBanned, setShowBanned] = useState<{ open: boolean; workspaceName?: string; workspaceUrl?: string }>();

	useEffect(() => {
		if (workspace?.name) {
			getWorkspaceMonetization(workspace.name);
		}
	}, [getWorkspaceMonetization, workspace]);

	// const currentSubscription = useMemo(() => {
	// 	return monetizationPlans?.find(x => x.selected)?.subscriptionId;
	// }, [monetizationPlans]);

	const isOnboarded = useMemo(() => {
		return workspaces?.some(
			x =>
				x._id === workspace?._id &&
				(x.status === UserStatus.ON_BOARDED || x.status === UserStatus.PENDING || x.status === UserStatus.ARCHIVE)
		);
	}, [workspaces, workspace]);

	const handleLogin = useCallback(
		async (workspace: IWorkspace, url?: string) => {
			resetStore();
			login(workspace.token, workspace.workspace);

			if (workspace.status === UserStatus.ARCHIVE) {
				await activateAccount("activate");
			}

			if (switchCommunity) {
				const workspaceInfo = await getWorkspaceByName(workspace.workspace);
				setWorkspace(workspaceInfo);
			}

			try {
				if (jwtService.getJwt()) {
					let urlString = url || homeUrl;

					if (switchCommunity) urlString = `${urlString}?switchCommunity=true`;

					history.push(urlString);
				}
			} catch (error) {
				const errorMessage = (error as Error).message;
				console.log(errorMessage);
			}
		},
		[
			login,
			switchCommunity,
			activateAccount,
			getWorkspaceByName,
			setWorkspace,
			jwtService,
			history,
			homeUrl,
			resetStore
		]
	);

	const handleAction = async (selectedWorkspace?: IWorkspace) => {
		const userEmail = email || user?.email;

		if (userEmail) {
			const workspaceInfo = selectedWorkspace || workspaces!.find(x => x._id === workspace?._id);

			if (communityName) generateRoutes(communityName);

			if (workspaceInfo) {
				if (isOnboarded) {
					handleLogin(workspaceInfo);
				}
				// else if (
				// 	currentSubscription === MonetizationPlanSubscriptionId.SUBSCRIPTION_ONLY &&
				// 	!user?.purchase?.productId
				// ) {
				// 	const onUnlock = () => {
				// 		handleLogin(workspaceInfo, fillProfileUrl);
				// 	};
				// 	setSubscribeDialog({ onUnlock });
				// }
				else {
					handleLogin(workspaceInfo, fillProfileUrl);
				}
			} else {
				const communityUrl = `${workspace?.communityUrl}`;

				const workspaceInfo = await joinCommunity(userEmail, communityUrl);

				if (workspaceInfo?.token) {
					joinWorkspace(workspaceInfo);
					handleAction(workspaceInfo);
				} else if (workspace?.isPrivate) {
					setTempUserId(workspaceInfo?.userId);
					setShowInvitationCodeModal(true);
				} else if (workspaceInfo?.status === UserStatus.BANNED) {
					setShowBanned({
						open: true,
						workspaceName: workspace?.name,
						workspaceUrl: workspace?.communityUrl
					});
				}
			}
		} else {
			history.push(`${loginUrl}?community=${workspace?.name}`);
		}
	};

	return (
		<>
			<ContentBlock>
				<Back coverImgUrl={coverImgUrl} isPrivate={isPrivate}>
					{!coverImgUrl && (
						<PlaceholderImage wrapClassName={"banner-image"} type={PlaceholderImageType.WORKSPACE_SEARCH} />
					)}
					<StyledAvatar title={title} image={imgUrl} />
				</Back>
				<InfoWrapper>
					<Title>{title}</Title>
					<SubTitle>{subtitle}</SubTitle>
				</InfoWrapper>
				<ManageBtnList>
					{isOnboarded ? (
						<StyledButton
							removeSideMargin
							palette="primary"
							buttonTheme="light"
							size={"medium"}
							onClick={() => handleAction()}
						>
							Login
						</StyledButton>
					) : (
						<>
							<StyledButton
								id="joinCommunity"
								removeSideMargin
								palette="primary"
								buttonTheme="light"
								size={"medium"}
								leftIcon={<Icon group={""} fill={"#6173fe"} name={"letter"} />}
								onClick={() => handleAction()}
							>
								Join Community
							</StyledButton>
							{/*{workspace?.isPrivate ? (*/}
							{/*	<StyledButton*/}
							{/*		removeSideMargin*/}
							{/*		palette="primary"*/}
							{/*		buttonTheme="light"*/}
							{/*		size={"medium"}*/}
							{/*		leftIcon={<Icon group={""} fill={"#6173fe"} name={"person-add"} />}*/}
							{/*		onClick={() => handleAction()}*/}
							{/*	>*/}
							{/*		Request to Join Community*/}
							{/*	</StyledButton>*/}
							{/*) : (*/}
							{/*	<StyledButton*/}
							{/*		removeSideMargin*/}
							{/*		palette="primary"*/}
							{/*		buttonTheme="light"*/}
							{/*		size={"medium"}*/}
							{/*		leftIcon={<Icon group={""} fill={"#6173fe"} name={"letter"} />}*/}
							{/*		onClick={() => handleAction()}*/}
							{/*	>*/}
							{/*		Join Community*/}
							{/*	</StyledButton>*/}
							{/*)}*/}
						</>
					)}
				</ManageBtnList>
				{isPrivate && (
					<PrivateBlock>
						<PrivateBlock.LockIconWrapper>
							<LockIcon width={30} height={30} viewBox={"3 3 18 18"} />
						</PrivateBlock.LockIconWrapper>
						<PrivateBlock.Title>Private Community</PrivateBlock.Title>
						<PrivateBlock.Text>
							Only Invited members can join this community. You can Request to join.
						</PrivateBlock.Text>
					</PrivateBlock>
				)}
				<JoinPrivateCommunityModal
					open={showInvitationCodeModal}
					userId={tempUserId}
					onClose={workspaceInfo => {
						setShowInvitationCodeModal(false);

						if (workspaceInfo?.token) {
							handleAction(workspaceInfo);
						}
					}}
				/>
				{/*<SubscribeDialog />*/}
			</ContentBlock>
			{showBanned?.open && (
				<BannedUserModal
					open
					communityName={showBanned?.workspaceName}
					workspaceUrl={showBanned?.workspaceUrl}
					communitiesPageLink={communityListRoute}
					joinCommunitiesUrl={joinCommunitiesUrl}
				/>
			)}
		</>
	);
};

export default Header;
