import React from "react";

import ConfirmLeavePopup from "shared/Components/ConfirmLeave";
import useConfirmLeavePopup from "shared/Components/ConfirmLeave/hooks/useConfirmLeavePopup";
import { Icon, InPlaceModal, Text } from "shared/ui-kit";

import { useTeam } from "../../../../Data";
import { ModalContent } from "../ModalContent";
import { AddMemberModalHeader, AddMemberModalWrapper } from "../style";

export const AddTeamMemberModal = () => {
	const { setStateOfModal, setEmailAddresses, getData: getTeamData } = useTeam();
	const { modalIsOpen, modalContent, modalTitle } = getTeamData();

	const {
		handleLeavePageConfirmed,
		closeConfirmPopup,
		handleClose,
		getData: getConfirmLeavePopupData
	} = useConfirmLeavePopup({
		onClose: () => {
			setStateOfModal({ isOpen: false });
			setEmailAddresses({ emailAddresses: [] });
		},
		open: modalIsOpen
	});
	const { showConfirmPopup } = getConfirmLeavePopupData();

	return (
		<>
			<ConfirmLeavePopup
				handleLeavePage={handleLeavePageConfirmed}
				open={showConfirmPopup}
				onClose={closeConfirmPopup}
				popup
			/>
			<AddMemberModalWrapper>
				<InPlaceModal
					modalHeight={600}
					open={modalIsOpen || false}
					onClose={handleClose}
					customStyle="padding: 0;"
					fullWidth={true}
					maxWidth={"md"}
				>
					<AddMemberModalHeader>
						<Text align="left" display="initial" variant="h6" style={{ fontSize: "18px" }}>
							{modalTitle}
						</Text>
						<Icon
							fill="#c5cee0"
							group="filled"
							height={24}
							name="close"
							width={24}
							style={{ marginLeft: "10px" }}
							onClick={handleClose}
							className="cursor-pointer"
						/>
					</AddMemberModalHeader>
					<ModalContent modalContent={modalContent} />
				</InPlaceModal>
			</AddMemberModalWrapper>
		</>
	);
};
