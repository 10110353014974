import { useCallback } from "react";

import config from "config/appConfig";
import { useHistory } from "react-router";

import { routes } from "apps/RegularUser/constants";

import ApiError from "../core/ApiError";

const { GLOBAL_CONSTANTS, CORE_API_URLS } = config;

const useUnAuthorizedInterceptor = () => {
	const history = useHistory();

	const url = `${window.location.pathname}${window.location.search}`;

	const isMemberSideLink = url.includes("/member/");

	const workspace = url.split("/")[1];

	return useCallback(
		({ status }: ApiError) => {
			if (status === 401) {
				for (const key in localStorage) {
					if (key.includes(GLOBAL_CONSTANTS.LOCAL_STORAGE_PREFIX ?? "")) localStorage.removeItem(key);
				}

				let redirectLink =
					isMemberSideLink && workspace ? `${routes.community.getPath()}/${workspace}` : CORE_API_URLS.LOGIN;

				if (url !== CORE_API_URLS.LOGIN) {
					redirectLink = `${redirectLink}${isMemberSideLink ? `?next=${url}` : ""}`;
				}

				history.push(redirectLink);
			}
		},
		[history, isMemberSideLink, url, workspace]
	);
};

export default useUnAuthorizedInterceptor;
