export enum ReportContentType {
	PERSONA = "persona",
	EVENT = "event",
	POST = "post",
	GROUP = "group",
	USERFILE = "userfile",
	VIDEO = "video",
	TRACK = "track",
	ALBUM = "album",
	SERIES = "series",
	LIVE_PARTICIPANT = "participant"
}

export enum ReportContentReasonType {
	SENDING_SPAM = "Sending spam",
	INAPROPRIATE_CONTENT = "Inapropriate content",
	STOLEN = "Stolen photo/video"
}

export enum ReportContentSortBy {
	TIMESTAMP = "timestamp",
	CONTENT_TYPE = "contentType",
	POPULAR = "popular"
}

export interface ReportContentModel {
	_id: string;
	reporteeId: string;
	reportType: ReportContentType;
	reporteeMeta: {
		name: string;
		artUrl?: string;
		creator?: {
			_id?: string;
			personaId?: number;
			firstName: string;
			lastName: string;
		};
	};
	reason: ReportContentReasonType[];
	reportedCount: number;
	initiatorIds: string[];
	createdAt: string;
	updatedAt: string;
}
