import React, { FC, useCallback, useEffect, useMemo, useState } from "react";

import { DateTime } from "luxon";
import { useHistory } from "react-router";

import { ReactComponent as ReportIcon } from "assets/icons/liveconvo/report.svg";
import { UnlockPremiumBtn } from "shared/Components";
import { useMemberRoutes, useReportContent, useUser, useVideo } from "shared/hooks";
import { ReportContentType, UnlockContentType, VideoModel } from "shared/types";
import { Button, Text } from "shared/ui-kit";

import { Container, MoreVideos } from "./style";

import { VideoCard } from "../../Components";
import ItemDetailsTemplate from "../ItemDetailsTemplate";

interface VideoPageProps {
	id: string;
	groupId?: string;
}

const VideoPage: FC<VideoPageProps> = ({ id, groupId }) => {
	const history = useHistory();

	const { getMemberRoutesData } = useMemberRoutes();
	const { routes } = getMemberRoutesData();

	const { getVideo, getVideos, getData: getVideoData } = useVideo();
	const { filteredVideos } = getVideoData();

	const { getReportTypeOptions } = useReportContent();

	const { isPrivilegedRole } = useUser();

	const [video, setVideo] = useState<VideoModel>();
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		setLoading(true);

		getVideo(id)
			?.then(({ video }) => {
				setVideo(video);
			})
			.finally(() => {
				setLoading(false);
			});
	}, [getVideo, id]);

	useEffect(() => {
		getVideos({
			limit: 4,
			keyword: "",
			offset: 1,
			groupId
		});
	}, [getVideos, groupId]);

	const optionList = useMemo(() => {
		return !video?.creator && !isPrivilegedRole
			? [
					{
						name: "Report",
						icon: <ReportIcon viewBox="0 -2 22 22" />,
						onClick: () => {},
						submenuOptions: getReportTypeOptions({
							reportType: ReportContentType.VIDEO,
							reportContentId: `${video?._id}`,
							reportContentName: video?.title,
							customCallback: () => {
								history.go(-1);
							}
						})
					}
			  ]
			: [];
	}, [getReportTypeOptions, history, video, isPrivilegedRole]);

	const goToOtherVideos = useCallback(() => {
		history.push(`${routes?.member.videos.getPath()}${groupId ? `?groupId=${groupId}` : ""}`);
	}, [groupId, history, routes?.member.videos]);

	return (
		<Container>
			<ItemDetailsTemplate
				video={video}
				title={video?.title || ""}
				loading={loading}
				summary={
					video ? `${video.totalPlays} views • ${DateTime.fromJSDate(new Date(video.createdAt)).toRelative()}` : ""
				}
				subtitle={video?.category?.name}
				description={video?.description}
				locked={video?.locked}
				options={optionList}
				allowDownload={!!video?.meta?.canBeDownloaded}
				unlockPremiumBlock={
					<UnlockPremiumBtn
						premiumObject={video}
						teaserContent={video?.title}
						premiumObjectType={UnlockContentType.VIDEO}
						onUnlock={() => {
							getVideo(id)?.then(({ video }) => {
								setVideo(video);
							});
						}}
					/>
				}
			/>
			<MoreVideos>
				<MoreVideos.Headline>
					<Text variant="h3">Videos</Text>
					<Button buttonTheme="light" palette="basic" onClick={goToOtherVideos}>
						See All
					</Button>
				</MoreVideos.Headline>
				<MoreVideos.Row>
					{filteredVideos.map(vid => (
						<MoreVideos.Video key={vid._id}>
							<VideoCard video={vid} highlightOnHover />
						</MoreVideos.Video>
					))}
				</MoreVideos.Row>
			</MoreVideos>
		</Container>
	);
};

export default VideoPage;
