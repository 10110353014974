import { useMemo } from "react";

import { API_URLS } from "shared/constants";
import useHttpActions from "shared/services/api/core/useHttpActions";

import { BaseConverter, convertSerie, convertUpdateSerie } from "shared/services/converters";

import { SeriesCollection } from "../../modules/Manage/Data/types";
import { FileTypesEnum } from "../../modules/Manage/Data/types/SeriesTypes";

const useSeriesApiService = (isMarketing = false) => {
	const params = useMemo(() => (isMarketing ? { customErrorHandlers: [] } : undefined), [isMarketing]);

	const actions = useHttpActions(params);

	return useMemo(
		() => ({
			createCollection: async data => {
				return BaseConverter.convert<{ success: boolean; collection: SeriesCollection }>(
					await actions.post(API_URLS.COLLECTIONS.GET_POST, convertSerie(data))
				);
			},
			updateCollection: async (id: string, data) => {
				return BaseConverter.convert<{ success: boolean; collection: SeriesCollection }>(
					await actions.put(API_URLS.COLLECTIONS.GET_UPDATE_DELETE_COLLECTION(id), convertUpdateSerie(data))
				);
			},
			getCollection: async (id: string, offset = 1, limit = 10) => {
				return BaseConverter.convert<{ success: boolean; collection: SeriesCollection }>(
					await actions.get(API_URLS.COLLECTIONS.GET_UPDATE_DELETE_COLLECTION(id), { offset, limit })
				);
			},
			getCollections: async (
				offset = 1,
				limit = 10,
				keyword = "",
				groupId?: string,
				sortBy?: string,
				categoryId?: string,
				eventId?: string
			) => {
				return BaseConverter.convert<{ success: boolean; collections: SeriesCollection[] }>(
					await actions.get(API_URLS.COLLECTIONS.GET_POST, {
						offset,
						limit,
						keyword,
						groupId,
						sortBy,
						categoryId,
						eventId
					})
				);
			},
			reorderCollection: async (id: string, itemIds: { _id: string; type: FileTypesEnum }[]) => {
				return BaseConverter.convert<{ success: boolean; collection: SeriesCollection }>(
					await actions.patch(API_URLS.COLLECTIONS.REORDER(id), { itemIds })
				);
			},
			getSeriesCount: async ({
				keyword,
				categoryId,
				groupId,
				eventId
			}: {
				keyword?: string;
				eventId?: string;
				groupId?: string;
				categoryId?: string;
			}) => {
				return BaseConverter.convert<{ totalCount: number }>(
					await actions.get(API_URLS.COLLECTIONS.COUNT, { keyword, groupId, categoryId, eventId })
				);
			},
			getSeriesUrls: async (id: string) => {
				return BaseConverter.convert<{ urls: { name: string; url: string; type: "FILE" | "VIDEO" | "TRACK" }[] }>(
					await actions.get(API_URLS.COLLECTIONS.URLS(id))
				);
			},
			deleteCollection: async (id: string) => {
				return BaseConverter.convert<{ success: boolean }>(
					await actions.delete(API_URLS.COLLECTIONS.GET_UPDATE_DELETE_COLLECTION(id))
				);
			}
		}),
		[actions]
	);
};

export default useSeriesApiService;
