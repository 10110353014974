import React, { useCallback, useMemo } from "react";

import { HiddenInput } from "./style";

interface HiddenFileSelectorProps {
	availableFileTypes?: string[];
	onSelect?: (files?: FileList) => void;
	multiple?: boolean;
}

const HiddenFileSelector: React.FC<HiddenFileSelectorProps> = ({ availableFileTypes, onSelect, multiple }) => {
	const correctFileTypes = useMemo(() => availableFileTypes || ["image/*", "video/mp4"], [availableFileTypes]);

	const handleSelect = useCallback(
		(files?: FileList) => {
			onSelect && onSelect(files);
		},
		[onSelect]
	);

	return (
		<HiddenInput
			id="hidden-file-selector"
			type="file"
			value={null}
			onChange={e => {
				handleSelect(e.target.files);
				// clear input state to allow select other files
				setTimeout(
					target => {
						target.value = null;
					},
					50,
					e.target
				);
			}}
			name="hidden-file-selector"
			accept={correctFileTypes.join(",")}
			multiple={multiple}
			title=""
		/>
	);
};

export default HiddenFileSelector;
