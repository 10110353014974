import React, { useEffect, useState } from "react";

import styled from "styled-components";

import { Box } from "shared/ui-kit";

import { dataUrlToFile } from "utils/serviceUtils/helpers";

const PreviewWrapper = styled(Box)`
	display: flex;
	align-items: center;
	justify-content: center;

	img {
		max-width: 120px;
		max-height: 120px;
	}
`;

interface PreviewBlockProps {
	file: File | string;
	customStyle?: string;
}

const Preview: React.FC<PreviewBlockProps> = ({ file }): JSX.Element => {
	const [url, setUrl] = useState<string>("");

	useEffect(() => {
		if (file) {
			(async () => {
				const correctFile = typeof file === "string" ? await dataUrlToFile(file, "test") : file;
				const url = URL.createObjectURL(correctFile);
				setUrl(url);
			})();
		}
	}, [file]);

	return <PreviewWrapper>{url && <img src={url} alt={"Community Icon"} />}</PreviewWrapper>;
};

export default Preview;
