import React from "react";

import { Theme } from "@material-ui/core";
import Fab, { FabProps } from "@material-ui/core/Fab";
import DownIcon from "@material-ui/icons/KeyboardArrowDown";
import styled, { css } from "styled-components";

export type FabButtonProps = FabProps & {
	customStyle?: string;
	expanded: boolean;
	icon?: JSX.Element;
	size?: "small" | "medium" | "large" | "tiny";
	theme: Theme;
};

export default styled(
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	({ icon, customStyle, expanded, ...rest }): JSX.Element => (
		<Fab {...rest}>{icon || <DownIcon className="fab-icon" />}</Fab>
	)
)<FabButtonProps>`
	${({ size }) => (!size || size === "small" || size === "tiny" ? "font-size: 12px" : "")};
	${({ size }) => (size === "tiny" ? "padding: 0.24em 0.68em !important" : "")};
	${({ size }) =>
		size === "small" ? "width:32px; height: 32px; min-width: 32px; min-height: 32px;padding: 0 !important;" : ""};
	${props => (props.size === "medium" ? "font-size: 14px" : "")};

	${props => {
		let customStyle = props.customStyle || "";
		const { palette } = props;
		const themePalette = props.theme.palette[palette];

		customStyle = customStyle.concat(`
      .fab-icon {
        transition: transform .25s linear;
        transform: ${props.expanded ? "rotate(180deg)" : "rotate(0)"};
      }
    `);

		if (palette) {
			customStyle = customStyle.concat(`
        padding: 0.48em 1.32em;
        background-color: ${themePalette.main};
        border: 1px solid ${themePalette.border || themePalette.main};
        color: ${themePalette.contrastText};
        box-shadow: none;

        transition: ${props.theme.transitions.create(["background-color", "box-shadow"])};
        &:hover {
          background-color: ${props.theme.palette.colors.basic[500]};
          @media (hover:none): { background-color: ${themePalette.main} }
    }`);
		}
		return css`
			${customStyle}
		`;
	}}
`;
