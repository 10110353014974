import React, { HTMLAttributes } from "react";

import { Theme } from "@material-ui/core";
import TableCell, { TableCellProps } from "@material-ui/core/TableCell";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import styled, { css } from "styled-components";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const TableHeaderWrapper = styled(({ customStyle, ...props }) => <TableCell {...props} />)`
	display: flex;
	align-items: center;
	box-sizing: border-box;
	flex: 1;
	height: 48px;

	.explorer-table.table-header {
		flex: 1;
		font-weight: normal;
		color: ${props => props.theme.palette.text.secondary};
		text-align: ${props => props.alignment || "center"};
		font-size: 12px;
	}

	${props => {
		const customStyle = props.customStyle || "";

		return css`
			${customStyle}
		`;
	}}
`;

const SortedHeader = styled.div<
	HTMLAttributes<HTMLDivElement> & {
		alignment: string;
		sortable: boolean;
		sortMarker?: any;
	}
>`
	color: ${props =>
		props.sortMarker ? props.theme.palette.infoLight.contrastText : props.theme.palette.text.secondary};
	cursor: ${props => (props.sortable ? "pointer" : "auto")};
	& svg {
		height: 14px;
		width: auto;
		margin-left: 2px;
		margin-top: 2px;
		margin-bottom: -2px;
	}
`;

interface IHeaderProps extends TableCellProps {
	alignment: string;
	customStyle?: () => void;
	dataKey: string;
	Header: any;
	label: string;
	sortable: boolean;
	sortBy: string;
	theme: Theme;
}

export default (props: IHeaderProps): JSX.Element => {
	const { label, Header, component = "div", variant = "head", theme, customStyle, alignment, ...rest } = props;

	const { dataKey, sortable, sortBy, sortDirection } = rest;
	const sortMarker = sortBy === dataKey ? sortDirection === "desc" ? <ArrowDownwardIcon /> : <ArrowUpwardIcon /> : null;

	return (
		<TableHeaderWrapper
			theme={theme}
			variant={variant}
			component={component}
			alignment={alignment}
			customStyle={customStyle}
		>
			<div className="explorer-table table-header">
				{Header ? (
					<Header {...props} />
				) : (
					<SortedHeader alignment={alignment} sortable={sortable} sortMarker={sortMarker}>
						{label} {sortMarker}
					</SortedHeader>
				)}
			</div>
		</TableHeaderWrapper>
	);
};
