import React, { FC, useCallback, useEffect, useMemo } from "react";

import { CreateTrack } from "shared/Components";

import { useTrack } from "shared/hooks";
import { useS3Uploader } from "shared/services/s3Uploader";
import { dataUrlToFile, filterFiles } from "utils/serviceUtils/helpers";

import { ModelBlockType } from "../../../ModelBlock";
import { GroupSectionTemplate } from "../../components";

const defaultFilters = {
	limit: 20,
	keyword: ""
};

const TracksSection: FC<{ id: string; canCreate?: boolean; event?: boolean }> = ({ id, canCreate, event }) => {
	const {
		getTracks,
		setTrackDuration,
		setTrackFileUrl,
		setTrackName,
		setTrackInfoPopup,
		setRefresh,
		getData: getTrackData
	} = useTrack();
	const { filteredTracks, trackUploadUrl, trackInfoPopup, loading, refresh } = getTrackData();

	const { uploadFile } = useS3Uploader();

	useEffect(() => {
		getTracks({ ...defaultFilters, offset: 1, groupId: !event ? id : undefined, eventId: event ? id : undefined });
	}, [event, getTracks, id]);

	const correctFileTypes = useMemo(() => ["audio/*"], []);

	const handleUpload = useCallback(() => {
		const el: any = document.getElementById("trackFileUrl");
		if (el) {
			el.value = null;
			el.click();
		}
	}, []);

	const onUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
		const { target } = e;
		const { files } = target;

		if (files) {
			setTrackFileUrl(null);
			const filteredFiles = filterFiles(files, correctFileTypes);

			const trackFile = !!filteredFiles?.length ? files[0] : null;

			if (trackFile) {
				const correctFile = typeof trackFile === "string" ? await dataUrlToFile(trackFile, "test") : trackFile;

				const audio = document.createElement("audio");
				const reader = new FileReader();

				reader.onload = e => {
					audio.src = (e.target?.result as string) || "";
					audio.addEventListener("loadedmetadata", () => {
						const duration = audio.duration;
						setTrackDuration(`${duration}`);
					});
				};

				reader.readAsDataURL(correctFile);

				uploadFile({
					file: correctFile as File,
					communityName: trackUploadUrl,
					checkProgress: true,
					customCallback: data => setTrackFileUrl(data?.publicUrl)
				});

				setTrackName(files && files[0].name.slice(0, files[0].name.lastIndexOf(".")));

				setTrackInfoPopup({ open: true, model: undefined });
			}
		}
	};

	return (
		<>
			<GroupSectionTemplate
				loading={loading}
				autoFit={!!filteredTracks?.length && filteredTracks.length > 3}
				items={filteredTracks || []}
				type={ModelBlockType.track}
				actionButton={canCreate ? { title: "Create New Track", onClick: handleUpload } : undefined}
				title="Audio Tracks"
				noItemsErrorText="This group don’t have any tracks yet."
			/>
			<input id="trackFileUrl" type="file" onChange={onUpload} name="trackFileUrl" hidden accept=".mp3,.wav" />
			<CreateTrack
				groupId={!event ? id : undefined}
				eventId={event ? id : undefined}
				open={trackInfoPopup.open}
				editableModel={trackInfoPopup.model}
				onClose={e => {
					setTrackInfoPopup({ open: false });
					if (e?.created) {
						setRefresh(refresh + 1);
					}
					setTrackDuration();
					getTracks({
						...defaultFilters,
						offset: 1,
						groupId: !event ? id : undefined,
						eventId: event ? id : undefined
					});
				}}
			/>
		</>
	);
};

export default TracksSection;
