import styled from "styled-components";

import { Box, Text } from "shared/ui-kit";

export const ParticipantItem = styled(Box)`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

ParticipantItem.Wrapper = styled(Box)`
	width: 100%;

	&:not(:last-child) {
		margin-bottom: 16px;
	}
`;

ParticipantItem.Info = styled(Box)`
	display: flex;
	align-items: center;
`;

ParticipantItem.AvatarWrapper = styled(Box)`
	position: relative;

	margin-right: 16px;
`;

ParticipantItem.Avatar = styled(Box)`
	width: 32px;
	height: 32px;

	border-radius: 8px;

	overflow: hidden;

	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
`;

ParticipantItem.AvatarIconWrapper = styled(Box)`
	height: 16px;
	width: 16px;

	position: absolute;
	right: -4px;
	bottom: -4px;

	background: #2f2f2f;

	display: flex;
	justify-content: center;
	align-items: center;

	border-radius: 50%;

	overflow: hidden;
`;

ParticipantItem.BaseInfo = styled(Box)``;

ParticipantItem.Name = styled(Text)`
	font-size: 15px;
	line-height: 1.33;
	color: #fff;
`;

ParticipantItem.Action = styled(Text)`
	font-size: 12px;
	line-height: 1.33;
	color: #fff;
	text-transform: capitalize;

	opacity: 0.56;
`;
