export default interface CommunityType {
	type: string;
	image: string;
	icon?: string;
	description?: string;
	active?: boolean;
	isSportType?: boolean;
}

export enum CommunityCategoryType {
	Artist = "Artist",
	GameCreator = "Game Creator",
	Podcaster = "Podcaster",
	NonprofitOrg = "Nonprofit Org.",
	Musician = "Musician",
	Educator = "Educator",
	VideoCreator = "Video Creator",
	Business = "Business",
	Writer = "Writer",
	Other = "Other"
}
