import React from "react";

import { DonationCard } from "modules/MemberHome/View/Components/FundraiserDonationDialog/style";
import { SkeletonBase } from "shared/Components";

const FundraiserItemsHeaderLoader = () => {
	return (
		<>
			<div className="w-full lg:w-3/5">
				<SkeletonBase className="rounded-lg" variant="rect" animation="wave" width={"100%"} height={"100%"} />
			</div>
			<div className="w-full lg:w-2/5 p-6">
				<DonationCard className="p-0 m-0 noBorder noShadow">
					<div className="on-behalf-of relative p-3 flex items-center justify-between gap-4">
						<div className="flex items-center gap-4 w-full">
							<SkeletonBase className="rounded-lg" variant="rect" animation="wave" width={"40px"} height={"40px"} />
							<SkeletonBase className="rounded-lg" variant="text" animation="wave" width={"100%"} height={"40px"} />
						</div>
					</div>
					<SkeletonBase className="mt-3 rounded-lg" variant="rect" animation="wave" width={"100%"} height={"128px"} />
					<div className="flex items-center gap-2">
						<SkeletonBase className="mt-3 rounded-lg" variant="rect" animation="wave" width={"100%"} height={"80px"} />
						<SkeletonBase className="mt-3 rounded-lg" variant="rect" animation="wave" width={"100%"} height={"80px"} />
					</div>
					<SkeletonBase
						className="w-full mt-4 rounded-lg"
						variant="rect"
						animation="wave"
						width={"100%"}
						height={"48px"}
					/>
				</DonationCard>
			</div>
		</>
	);
};

export default FundraiserItemsHeaderLoader;
