import { AxiosResponse } from "axios";
import { DateTime } from "luxon";
import * as R from "ramda";
import timezones from "timezones-list";

import BaseConverter from "shared/services/converters/baseConverter";
import {
	EventCategory,
	EventCategoryResponse,
	EventFormModel,
	EventLocation,
	EventModelRequest,
	LocationDetail,
	UpdateEventFormModel,
	UpdateEventModelRequest
} from "shared/types";
import { EventFormData, LabelValueType } from "shared/types/EventModel";

export const convertCategories = (data: AxiosResponse<EventCategoryResponse>): EventCategory[] => {
	return BaseConverter.convert<EventCategoryResponse>(data).categories;
};

export const convertEventRequest = (data: EventFormModel, placeDetail?: LocationDetail): EventModelRequest => {
	const getLocation = (): EventLocation | undefined => {
		if (!placeDetail) return undefined;

		const {
			place_id,
			name,
			geometry: {
				location: { lat, lng }
			},
			icon,
			meta: {
				result: { formatted_address, utc_offset }
			}
		} = placeDetail;

		return {
			id: place_id,
			name: name,
			lat: lat,
			address: formatted_address,
			lon: lng,
			icon: icon,
			placeId: place_id,
			utcOffset: utc_offset
		};
	};

	return {
		...R.pick(
			[
				"description",
				"onlineUrl",
				"price",
				"chatEnabled",
				"isNational",
				"conversationEnabled",
				"allowInvites",
				"allowRaiseHand",
				"inviteAllGroupMember",
				"priceTags",
				"timeZone",
				"isOnline",
				"groupId"
			],
			data
		),
		eventImages: data.eventImage ? [data.eventImage] : undefined,
		categories: [data.categoryId],
		location: getLocation(),
		name: data.title,
		date: data.startTime,
		invitations: [],
		eventDateAndTime: data.startTime,
		startTime: data.startTime,
		endTime: data.endTime,
		attendLimit: data.attendLimit || 500,
		admins: data.admins || [],
		inviteAllGroupMembers: true // TODO: probably we need to add a checkmark for it on the FE
	};
};

export const convertCreateUpdateEventRequest = (data: EventFormData, placeDetail?: LocationDetail | EventLocation) => {
	const getLocation = (): Partial<EventLocation> | undefined => {
		if (!placeDetail) return;

		const placeIdExists = placeDetail.id && placeDetail.id !== "undefined";
		return {
			id: placeIdExists ? (placeDetail as EventLocation).id : (placeDetail as LocationDetail).place_id,
			name: placeDetail.name,
			lat: placeIdExists ? (placeDetail as EventLocation).lat : (placeDetail as LocationDetail).geometry.location.lat,
			lon: placeIdExists ? (placeDetail as EventLocation).lon : (placeDetail as LocationDetail).geometry.location.lng,
			icon: placeDetail.icon,
			placeId: placeIdExists ? (placeDetail as EventLocation).placeId : (placeDetail as LocationDetail).place_id,
			utcOffset: placeIdExists
				? (placeDetail as EventLocation).utcOffset
				: (placeDetail as LocationDetail).meta.result.utc_offset
		};
	};

	const tz = timezones.find(t => t.tzCode === ((data.timezone as LabelValueType)?.value || data.timezone));

	let UTCStartTime: DateTime | string = DateTime.fromJSDate(new Date(data.startTime));
	let UTCEndTime: DateTime | string = DateTime.fromJSDate(new Date(data.endTime));
	const startTimeConfig = { ...UTCStartTime.toObject() };
	const endTimeConfig = { ...UTCEndTime.toObject() };

	UTCStartTime = UTCStartTime.setZone(tz!.tzCode).set(startTimeConfig).toUTC().toISO();
	UTCEndTime = UTCEndTime.setZone(tz!.tzCode).set(endTimeConfig).toUTC().toISO();

	console.log(data);

	return {
		...R.pick(["description", "groupId", "eventId"], data),
		allowInvites: data.liveconversation,
		allowRaiseHand: data.liveconversation,
		attendLimit: data.attendeesNumber
			? data.isMemberView
				? isNaN(data?.attendeesNumber as number)
					? parseInt((data.attendeesNumber as LabelValueType)?.value)
					: data.attendeesNumber
				: parseInt(`${data.attendeesNumber}`)
			: -1, // -1 = unlimited
		chatEnabled: data.chat,
		conversationEnabled: data.liveconversation,
		endTime: UTCEndTime,
		eventImages: data.eventImage ? [data.eventImage] : undefined,
		isNational: data.worldwide,
		isOnline: data.isOnlineEvent,
		name: data.title,
		onlineUrl: data.link || "",
		price: 0,
		priceTags: [],
		startTime: UTCStartTime,
		date: UTCStartTime,
		eventDateAndTime: UTCStartTime,
		ticketUrl: "",
		timeZone: data.timezone
			? data.isMemberView
				? {
						value: (data.timezone as LabelValueType).value,
						label: (data.timezone as LabelValueType).value
				  }
				: {
						value: data.timezone as string,
						label: data.timezone as string
				  }
			: undefined,
		location: placeDetail && (placeDetail as LocationDetail).place_id ? getLocation() : undefined,
		categories: data.isMemberView ? [data.category?.value || data.category] : [data.categoryId],
		admins: data.admins || []
	};
};

export const convertUpdateEventRequest = (
	data: UpdateEventFormModel,
	placeDetail: LocationDetail | EventLocation
): UpdateEventModelRequest => {
	const getLocation = (): Partial<EventLocation> => {
		const placeIdExists = placeDetail.id && placeDetail.id !== "undefined";
		return {
			id: placeIdExists ? (placeDetail as EventLocation).id : (placeDetail as LocationDetail).place_id,
			name: placeDetail.name,
			lat: placeIdExists ? (placeDetail as EventLocation).lat : (placeDetail as LocationDetail).geometry.location.lat,
			lon: placeIdExists ? (placeDetail as EventLocation).lon : (placeDetail as LocationDetail).geometry.location.lng,
			icon: placeDetail.icon,
			placeId: placeIdExists ? (placeDetail as EventLocation).placeId : (placeDetail as LocationDetail).place_id,
			utcOffset: placeIdExists
				? (placeDetail as EventLocation).utcOffset
				: (placeDetail as LocationDetail).meta.result.utc_offset
		};
	};

	return {
		...R.pick(
			["description", "onlineUrl", "price", "chatEnabled", "isNational", "conversationEnabled", "groupId", "timezone"],
			data
		),
		eventId: data.eventId,
		eventImages: data.eventImage ? [data.eventImage] : undefined,
		categories: [data.categoryId],
		location: placeDetail && (placeDetail as LocationDetail).place_id ? getLocation() : undefined,
		name: data.title,
		date: data.startTime,
		invitations: [],
		eventDateAndTime: data.startTime,
		startTime: data.startTime,
		endTime: data.endTime,
		attendLimit: data.attendLimit || 500,
		liveConversationId: data.liveConversationId || null
	};
};
