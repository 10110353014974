import { useMemo } from "react";

import useHttpActions from "shared/services/api/core/useHttpActions";

import { API_URLS } from "./../constants";
import { Card, GetCardsResponse } from "./../types/CardsType";
import { BaseConverter } from "./converters";

const useStripeApiService = () => {
	const actions = useHttpActions();

	return useMemo(
		() => ({
			getCards: async () => {
				return BaseConverter.convert<GetCardsResponse>(
					await actions.post(API_URLS.STRIPE.CARDS, {
						operation: "list"
					})
				);
			},
			newCardToken: async card => {
				return BaseConverter.convert<{ id: string }>(
					await actions.post(API_URLS.STRIPE.CARD_TOKEN, {
						operation: "create",
						payload: {
							card
						}
					})
				);
			},
			registerCard: async (id: string) => {
				return BaseConverter.convert<Card>(
					await actions.post(API_URLS.STRIPE.CARDS, {
						operation: "create",
						payload: {
							source: id
						}
					})
				);
			},
			updateCard: async (cardId: string, card) => {
				return BaseConverter.convert(
					await actions.post(API_URLS.STRIPE.CARDS, {
						operation: "update",
						payload: {
							id: cardId,
							name: card.name
						}
					})
				);
			},
			deleteCard: async (id: string) => {
				return BaseConverter.convert(
					await actions.post(API_URLS.STRIPE.CARDS, {
						operation: "delete",
						payload: {
							id
						}
					})
				);
			},
			chargeCard: async payload => {
				return BaseConverter.convert(
					await actions.post(API_URLS.STRIPE.CARD_CHARGE, {
						operation: "create",
						payload
					})
				);
			},
			buyCoins: async data => {
				return BaseConverter.convert<{ success: boolean }>(await actions.post(API_URLS.PURCHASE.BUY_COINS, data));
			},
			newSubscription: async (priceId: string) => {
				return BaseConverter.convert(
					await actions.post(API_URLS.STRIPE.SUBSCRIPE, { operation: "create", payload: { priceId } })
				);
			}
		}),
		[actions]
	);
};

export default useStripeApiService;
