import React, { FC } from "react";

import { IconButton } from "@material-ui/core";

import { Button, Icon, InPlaceModal, InPlaceModalProps, Text } from "shared/ui-kit";

import { ConfirmFooter, ConfirmHeader, StyledDivider, StyledParagraphText, StyledPopupOverlay } from "./style";

interface Props extends InPlaceModalProps {
	open: boolean;
}

interface ConfirmLeaveProps {
	open: boolean;
	popup?: boolean;
	onClose: () => void;
	onConfirm: () => void;
	title: string;
	message: string;
	cancelText?: string;
	okText?: string;
}

const ConfirmLeavePopupWrapper: FC<Props> = ({ open, children, onClose, modalHeight, ...props }) => (
	<InPlaceModal {...props} onClose={onClose} maxWidth="xs" open={open} modalHeight={modalHeight}>
		{children}
	</InPlaceModal>
);

const ConfirmAlertModal: FC<ConfirmLeaveProps> = props => {
	const { open, onClose, onConfirm, title, message, okText = "Ok", cancelText = "Cancel" } = props;
	return (
		<StyledPopupOverlay open={open}>
			<ConfirmLeavePopupWrapper modalHeight={600} open={open} onClose={onClose}>
				<ConfirmHeader>
					<Text variant="h6">{title}</Text>
					<IconButton onClick={onClose}>
						<Icon name="close" group="filled" fill="#c5cee0" />
					</IconButton>
				</ConfirmHeader>
				<StyledDivider thickness={2} borderColor="#edf1f7" />
				<StyledParagraphText variant="body1">{message}</StyledParagraphText>
				<StyledDivider thickness={2} borderColor="#edf1f7" />
				<ConfirmFooter container justify="flex-end">
					<Button buttonTheme="outline" onClick={onClose}>
						{cancelText}
					</Button>
					<Button onClick={onConfirm}>{okText}</Button>
				</ConfirmFooter>
			</ConfirmLeavePopupWrapper>
		</StyledPopupOverlay>
	);
};

export default ConfirmAlertModal;
