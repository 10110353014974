import styled from "styled-components";

import { Box, Text } from "shared/ui-kit";

export const Item = styled(Box)``;

Item.Wrapper = styled(Box)`
	display: flex;
	align-items: center;
	padding: 8px;
	width: 100%;
	&:hover {
		background: #f7f9fc;
		border-radius: 12px;
	}
`;

Item.IconWrapper = styled(Box)`
	width: 48px;
	height: 48px;

	border-radius: 50%;

	overflow: hidden;

	margin-right: 16px;
`;

Item.Icon = styled(Box)<{ imgUrl: string | undefined }>`
	width: 100%;
	height: 100%;

	background-color: #edf1f7;

	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;

	background-image: url(${({ imgUrl }) => imgUrl});
`;

Item.InfoBlock = styled(Box)`
	display: flex;
	flex-direction: column;
`;

Item.Title = styled(Text)`
	font-size: 15px;
	font-weight: 600;
	line-height: 1.6;
	color: #222b45;
`;

Item.Subtitle = styled(Text)`
	font-size: 13px;
	font-weight: 600;
	line-height: 1.85;
	color: #8f9bb3;
`;
