import { Button, Grow, Paper, PopperPlacementType } from "@material-ui/core";
import styled, { css } from "styled-components";

import { Box, Text } from "shared/ui-kit";

const Container = styled(Box)`
	.MuiList-padding {
		padding-top: 0;
		padding-bottom: 0;
	}

	.MuiMenuItem-root {
		padding: 8px;
		border-radius: 8px;

		&:hover {
			background-color: #f7f9fc;
		}

		span {
			font-weight: 600;
			margin-left: 8px;
		}
	}

	& > div {
		z-index: 5;
	}
`;

const UserProfileButton = styled(Button)`
	padding: 4px 4px 4px 7px;
	border-radius: 8px;
`;

const UserName = styled(Text)`
	font-size: 13px;
	text-transform: none;

	${props => props.theme.breakpoints.down("sm")} {
		display: none;
	}
`;

const UserAvatarWrapper = styled.div`
	border-radius: 50%;
	height: 32px;
	width: 32px;
	max-width: 30px;
	display: flex;
	overflow: hidden;
	margin-left: 8px;

	img {
		object-fit: cover;
		width: 100%;
		height: 100%;
	}
`;

const UserDropdownPaper = styled(Paper)`
	border-radius: 12px;
	overflow: hidden;
	padding: 8px;
	width: 240px;
`;

const UserProfileMenuItemIcon = styled.div<{ themeColor: string; name: string }>`
	display: flex;
	align-items: center;
	justify-content: center;

	${({ name, themeColor }) => {
		switch (name) {
			case "user":
			case "settings":
				return css`
					svg > g > g {
						fill: ${themeColor};
					}
				`;
			case "logout":
				return css`
					.colorful-logout-main {
						fill: ${themeColor};
					}
				`;
		}
	}}
`;

const StyledGrow = styled(Grow)<{ placement: PopperPlacementType }>`
	transform-origin: ${({ placement }) => (placement === "bottom" ? "left top" : "left bottom")};
`;

export {
	Container,
	UserProfileButton,
	UserName,
	UserDropdownPaper,
	UserProfileMenuItemIcon,
	UserAvatarWrapper,
	StyledGrow
};
