import React, { useCallback, useMemo } from "react";

import { useMediaQuery } from "@material-ui/core";

import { MemberDialog } from "modules/MemberHome/View/shared";
import { MemberDialogContentWrapper } from "modules/MemberHome/View/shared/style";
import { Dialog } from "shared/Components";
import { useNotification, useUser, useVideo } from "shared/hooks";
import { useS3Uploader } from "shared/services/s3Uploader";
import { ToolbarActions, VideoModel } from "shared/types";
import { Text } from "shared/ui-kit";
import * as appTheme from "theme/default";

import { getValidationMessage } from "utils/getValidationMessage";
import { dataUrlToFile, filterFiles } from "utils/serviceUtils/helpers";

import SelectVideoBody from "./SelectVideoBody";

import { CreateButton } from "../Event/SelectEvent";

const correctFileTypes = ["video/*"];

interface Props {
	onClose: (event: {}, reason: string) => void;
	onSelect: (group: VideoModel) => void;
	open: boolean;
}

const SelectVideo: React.FC<Props> = ({ onClose, onSelect, open }) => {
	const isMobile = useMediaQuery(appTheme.default.breakpoints.down("sm"));
	const { getData: getUserData } = useUser();
	const { isMemberView } = getUserData();

	const { showMessage } = useNotification();

	const { uploadFile } = useS3Uploader();

	const { setVideoDuration, setVideoFileUrl, setVideoInfoPopup, setVideoName, getData: getVideoData } = useVideo();

	const { videoUploadUrl } = getVideoData();

	const handleUpload = () => {
		const el: any = document.getElementById("videoFileUrl");
		if (el) {
			el.value = null;
			el.click();
		}
	};

	const onUpload = useCallback(
		async (e: React.ChangeEvent<HTMLInputElement>) => {
			const { target } = e;
			const { files } = target;

			if (files) {
				const filteredFiles = filterFiles(files, correctFileTypes);

				const videoFile = !!filteredFiles?.length ? files[0] : null;

				if (videoFile) {
					if (videoFile.size / 1024 / 1024 > 2048) {
						showMessage(
							getValidationMessage({
								name: "File size is too large. Use file that less than 2GB.",
								emoji: "⚠️"
							})
						);
						return;
					}
					const correctFile = typeof videoFile === "string" ? await dataUrlToFile(videoFile, "test") : videoFile;

					const video = document.createElement("video");
					video.preload = "metadata";

					video.onloadedmetadata = function () {
						window.URL.revokeObjectURL(video.src);

						if (video.duration < 1) return;

						setVideoDuration(`${video.duration}`);
					};

					video.src = URL.createObjectURL(correctFile);

					setVideoFileUrl("");

					uploadFile({
						file: correctFile as File,
						communityName: videoUploadUrl,
						checkProgress: true,
						customCallback: data => setVideoFileUrl(data?.publicUrl)
					});

					setVideoName(files && files[0].name.slice(0, files[0].name.lastIndexOf(".")));

					setVideoInfoPopup({ open: true, model: undefined });
					onClose(e, ToolbarActions.ADD_VIDEO);
				}
			}
		},
		[
			onClose,
			setVideoDuration,
			setVideoFileUrl,
			setVideoInfoPopup,
			setVideoName,
			showMessage,
			uploadFile,
			videoUploadUrl
		]
	);

	const onSelectVideo = useCallback(
		(data: VideoModel) => {
			setTimeout(() => {
				onSelect(data);
			}, 10);
		},
		[onSelect]
	);

	const dialogContent = useMemo(
		() => (
			<>
				<input
					id="videoFileUrl"
					type="file"
					onChange={onUpload}
					name="videoFileUrl"
					hidden
					accept={correctFileTypes.join(",")}
				/>
				<SelectVideoBody onSelect={onSelectVideo} />
			</>
		),
		[onSelectVideo, onUpload]
	);

	return isMemberView ? (
		<MemberDialog
			customWidth={512}
			title="Select a Video"
			open={open}
			onClose={onClose}
			footerPrimary={{ text: "Upload a new Video", onClick: handleUpload }}
		>
			<MemberDialogContentWrapper className="pt-5">{dialogContent}</MemberDialogContentWrapper>
		</MemberDialog>
	) : (
		<Dialog
			title={<Text variant={isMobile ? "h5" : "h3"}>Select a Video</Text>}
			open={open}
			onClose={onClose}
			footer={
				<CreateButton buttonTheme="light" fullWidth onClick={handleUpload}>
					Upload a new Video
				</CreateButton>
			}
		>
			{dialogContent}
		</Dialog>
	);
};

export default SelectVideo;
