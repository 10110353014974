import styled from "styled-components";

export const Wrapper = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	z-index: 99999;
	background-color: rgba(0, 0, 0, 0.8);
`;

Wrapper.VideoWrapper = styled.div`
	width: 90%;
	max-width: 1920px;
	height: 240px;
	display: flex;
	align-items: center;
	justify-content: center;
	> div {
		width: 100%;
		height: 100%;
	}

	${props => props.theme.breakpoints.up("sm")} {
		height: 80vh;
		max-height: 1080px;
	}
`;
