import React, { FC, useCallback, useEffect, useState } from "react";

import { CreateVideo } from "shared/Components";
import { useNotification, useVideo } from "shared/hooks";
import { useS3Uploader } from "shared/services/s3Uploader";
import { VideoSortBy } from "shared/types";
import { getValidationMessage } from "utils/getValidationMessage";
import { dataUrlToFile, filterFiles } from "utils/serviceUtils/helpers";

import { ModelBlockType } from "../../../ModelBlock";
import { GroupSectionTemplate } from "../../components";

const defaultFilters = {
	limit: 20,
	keyword: "",
	sortBy: VideoSortBy.timestamp,
	order: -1
};

const correctFileTypes = ["video/*"];

const VideosSection: FC<{ id: string; canCreate?: boolean; event?: boolean }> = ({ id, canCreate, event }) => {
	const {
		getVideos,
		getAllVideoCount,
		setVideoDuration,
		setVideoName,
		setVideoFileUrl,
		setVideoInfoPopup,
		getData: getVideoData
	} = useVideo();
	const { filteredVideos, allVideoCount, videoInfoPopup, videoUploadUrl, loading } = getVideoData();

	const [offset, setOffset] = useState(1);
	const { showMessage } = useNotification();
	const { uploadFile } = useS3Uploader();

	useEffect(() => {
		getVideos({
			...defaultFilters,
			offset,
			groupId: !event ? id : undefined,
			eventId: event ? id : undefined,
			paginate: true
		});
	}, [event, getVideos, id, offset]);

	useEffect(() => {
		getAllVideoCount({ groupId: !event ? id : undefined, eventId: event ? id : undefined });
	}, [event, getAllVideoCount, id]);

	const handleUpload = useCallback(() => {
		const el: any = document.getElementById("videoFileUrl");
		if (el) {
			el.value = null;
			el.click();
		}
	}, []);

	const onUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
		const { target } = e;
		const { files } = target;

		if (files) {
			const filteredFiles = filterFiles(files, correctFileTypes);

			const videoFile = !!filteredFiles?.length ? files[0] : null;

			if (videoFile) {
				if (videoFile.size / 1024 / 1024 > 2048) {
					showMessage(
						getValidationMessage({
							name: "File size is too large. Use file that less than 2GB.",
							emoji: "⚠️"
						})
					);
					return;
				}
				const correctFile = typeof videoFile === "string" ? await dataUrlToFile(videoFile, "test") : videoFile;

				const video = document.createElement("video");
				video.preload = "metadata";

				video.onloadedmetadata = function () {
					window.URL.revokeObjectURL(video.src);

					if (video.duration < 1) return;

					setVideoDuration(`${parseInt(`${video.duration}`)}`);
				};

				video.src = URL.createObjectURL(correctFile);

				setVideoFileUrl("");

				uploadFile({
					file: correctFile as File,
					communityName: videoUploadUrl,
					checkProgress: true,
					customCallback: data => setVideoFileUrl(data?.publicUrl)
				});

				setVideoName(files && files[0].name.slice(0, files[0].name.lastIndexOf(".")));

				setVideoInfoPopup({ open: true, model: undefined });
			}
		}
	};

	return (
		<>
			<GroupSectionTemplate
				loading={loading}
				autoFit={allVideoCount > 3}
				items={filteredVideos || []}
				type={ModelBlockType.video}
				actionButton={canCreate ? { title: "Create New Video", onClick: handleUpload } : undefined}
				title="Videos"
				noItemsErrorText={`This ${event ? "event" : "group"} don’t have any videos yet.`}
				onEndScroll={() => {
					if (allVideoCount && filteredVideos.length < allVideoCount) {
						setOffset(offset + 1);
					}
				}}
			/>
			<input
				id="videoFileUrl"
				type="file"
				onChange={onUpload}
				name="videoFileUrl"
				hidden
				accept={correctFileTypes.join(",")}
			/>
			<CreateVideo
				groupId={!event ? id : undefined}
				eventId={event ? id : undefined}
				open={!!videoInfoPopup.open}
				editableModel={videoInfoPopup.model}
				onClose={() => {
					setVideoInfoPopup({ open: false });
				}}
			/>
		</>
	);
};

export default VideosSection;
