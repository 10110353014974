import styled from "styled-components";

import { Box, Text } from "shared/ui-kit";

export const Wrapper = styled(Box)`
	.box-item {
		padding-right: 0;
		padding-left: 0;

		min-height: 130px;
	}
`;

export const ListWrapper = styled(Box)`
	display: flex;
	align-items: center;
	overflow-x: scroll;
	margin-top: 4px;

	::-webkit-scrollbar {
		display: none;
	}

	-ms-overflow-style: none;
	scrollbar-width: none;
`;

export const Item = styled(Box)``;

Item.Wrapper = styled(Box)`
	display: flex;
	flex-direction: column;
	align-items: center;

	margin: 0 4px;

	&:first-child {
		margin-left: 16px;
	}

	&:last-child {
		margin-right: 0;
	}
`;

Item.IconWrapper = styled(Box)<{ color?: string }>`
	width: 80px;
	height: 80px;

	border-radius: 50%;

	overflow: hidden;

	position: relative;

	box-shadow: inset 0 0 0 2px ${({ color }) => color};

	cursor: pointer;

	&:before {
		content: "";

		position: absolute;
		top: 0;
		left: 0;

		width: 100%;
		height: 100%;

		background: linear-gradient(150deg, #d555fa 12%, #48fdda 48%, #6173fe 88%);
		z-index: 1;

		border-radius: 50%;

		display: none;
	}

	&.live {
		&:before {
			display: block;
		}
	}

	&.watched {
		box-shadow: none;
	}
`;

Item.PreviewIcon = styled(Box)<{ url: string }>`
	position: absolute;

	top: 2px;
	left: 2px;

	width: calc(100% - 4px);
	height: calc(100% - 4px);

	background-color: black;

	border: 2px solid white;

	z-index: 2;

	border-radius: 50%;

	overflow: hidden;

	background-image: url(${({ url }) => url});
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
`;

Item.Title = styled(Text)`
	font-size: 12px;
	font-weight: 500;
	text-align: center;
	color: #2f3742;

	margin-top: 5px;
`;
