import React, { FC, ReactNode, useCallback, useMemo } from "react";

import TimeAgo from "react-timeago";

import { CategoryProps } from "modules/Marketplace/Data/types";

import { PlaceholderImage } from "shared/Components";
import { useMemberRoutes } from "shared/hooks";
import { PlaceholderImageType, VideoModel } from "shared/types";

import { ModalLink } from "../ModelItem/style";
import { ModelItem } from "../index";

const VideoCard: FC<{ video: VideoModel; highlightOnHover?: boolean }> = ({ video, highlightOnHover }) => {
	const { getMemberRoutesData } = useMemberRoutes();
	const { routes } = getMemberRoutesData();

	const getTimeTitle = useCallback((createdAt: string, category?: CategoryProps): ReactNode => {
		return (
			<>
				{category ? `${category.name} • ` : ""}
				<TimeAgo
					date={createdAt}
					live={false}
					formatter={(value, unit, suffix) =>
						value < 60 && unit === "second" ? "Just now" : `${value} ${unit} ${suffix}`
					}
				/>
			</>
		);
	}, []);

	const calculateDuration = useMemo(
		() => (time: string) => {
			const minutes = parseInt(`${parseFloat(time) / 60}`, 10);
			const seconds = parseInt(`${parseFloat(time) % 60}`);

			return `${minutes < 10 ? "0" : ""}${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
		},
		[]
	);

	const href = useMemo(
		() => `${routes?.member.video.getPath()}/${video._id}${video?.groupId ? `?groupId=${video.groupId}` : ""}`,
		[routes?.member.video, video._id, video?.groupId]
	);

	return (
		<ModalLink to={`${routes?.member.video.getPath()}/${video._id}`}>
			<ModelItem
				autoHeight
				item={{
					_id: video._id,
					wrapByHref: true,
					href,
					img: video.meta.artwork?.url,
					videoPreview: video.meta.video.url,
					duration: calculateDuration(video.duration),
					beforeTitle: getTimeTitle(video.createdAt, video.category),
					title: video.title,
					minimalistic: true,
					highlightOnHover,
					locked: video.locked,
					placeholderImg: (
						<PlaceholderImage
							type={PlaceholderImageType.VIDEO_PREVIEW}
							width={360}
							height={160}
							viewBox={"0 0 720 320"}
						/>
					)
				}}
			/>
		</ModalLink>
	);
};

export default VideoCard;
