import React, { FC } from "react";

import { ReactComponent as ManIcon } from "assets/icons/man-icon.svg";
import { ReactComponent as NoProfileIcon } from "assets/icons/no-profile.svg";
import { NoProfileDataTemp, Slider } from "modules/MemberHome/View/Components";
import { PlaceholderImage } from "shared/Components";
import { GroupAndSponsorsModel, PlaceholderImageType } from "shared/types";

import { Card, Group, GroupSponsors, Item, SponsorItem } from "../../styles";

const SponsorsSection: FC<{
	groupAndSponsors: GroupAndSponsorsModel[];
}> = ({ groupAndSponsors }) => (
	<div className="flex flex-col w-full">
		{groupAndSponsors.length ? (
			groupAndSponsors.map(g => (
				<Card key={g._id}>
					<Group>
						<Item.IconWrapper borderRadius={"12px"}>
							{!g.coverUrl ? (
								<PlaceholderImage
									type={PlaceholderImageType.PROFILE_IMAGE}
									width={80}
									height={80}
									viewBox={"0 0 400 400"}
								/>
							) : (
								<Item.Icon imgUrl={g.coverUrl} />
							)}
						</Item.IconWrapper>
						<Item.InfoBlock>
							<Group.Title>{g.name}</Group.Title>
							<Group.Subtitle>{`${g.sponsors.length} Sponsors`}</Group.Subtitle>
						</Item.InfoBlock>
					</Group>
					<GroupSponsors>
						{g.sponsors.length ? (
							<Slider count={g.sponsors.length} itemSize={176}>
								{g.sponsors.map(s => (
									<SponsorItem key={s.personaId}>
										<SponsorItem.CoverPhotoWrapper>
											{g.coverUrl ? (
												<SponsorItem.CoverPhoto url={g.coverUrl} />
											) : (
												<SponsorItem.CoverPhotoPlaceholderWrapper>
													<PlaceholderImage
														type={PlaceholderImageType.PROFILE_COVER}
														width={176}
														height={64}
														viewBox={"0 0 1000 300"}
													/>
												</SponsorItem.CoverPhotoPlaceholderWrapper>
											)}
											<div className="flex justify-center">
												<SponsorItem.PhotoWrapper className="-mt-8 bg-white rounded-full">
													{s.image?.url ? (
														<SponsorItem.Photo url={s.image.url} />
													) : (
														<NoProfileIcon className="w-16 h-16" />
													)}
												</SponsorItem.PhotoWrapper>
											</div>
										</SponsorItem.CoverPhotoWrapper>
										<SponsorItem.Title>{`$${s.amountRaised}`}</SponsorItem.Title>
										<SponsorItem.Subtitle>{s.name}</SponsorItem.Subtitle>
									</SponsorItem>
								))}
							</Slider>
						) : (
							<NoProfileDataTemp
								icon={<ManIcon width={150} height={150} />}
								subtitle="No Sponsors for this group yet"
								actions={[]}
							/>
						)}
					</GroupSponsors>
				</Card>
			))
		) : (
			<Card>
				<NoProfileDataTemp
					icon={<ManIcon width={160} height={160} />}
					subtitle="No Sponsors for this fundraiser yet"
					actions={[]}
				/>
			</Card>
		)}
	</div>
);

export default SponsorsSection;
