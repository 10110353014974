import React, { FC, memo } from "react";

import { Box, Grid, IconButton } from "@material-ui/core";
import styled from "styled-components";

import { Button, Divider, Icon, InPlaceModal, InPlaceModalProps, Text } from "shared/ui-kit";

const StyledPopupOverlay = styled.div`
	display: ${props => (props.open ? "block" : "none")};
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	background-color: rgba(25, 36, 53, 0.2);
	z-index: 10001;
	.MuiDialogContent-root {
		padding: 0;
	}

	.MuiDialog-scrollPaper {
		height: 100vh;
	}

	.container {
		max-width: 100%;
	}
`;

const StyledDivider = styled(Divider)`
	margin: 0;
	border-top-width: 1px;
`;

const StyledParagraphText = styled(Text)`
	color: #8f9bb3;
	padding: 16px;
`;

const ConfirmHeader = styled(Box)`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 8px 16px;
	padding-bottom: 0;
	button {
		padding: 12px;
		margin-right: -12px;
	}
`;

const ConfirmFooter = styled(Grid)`
	padding: 8px 16px;
`;

interface Props extends InPlaceModalProps {
	open: boolean;
}

interface ConfirmLeaveProps {
	open: boolean;
	popup?: boolean;
	onClose: () => void;
	handleLeavePage: () => void;
}

const ConfirmLeavePopupWrapper: FC<Props> = ({ open, children, onClose, modalHeight, ...props }) => (
	<InPlaceModal {...props} onClose={onClose} maxWidth="xs" open={open} modalHeight={modalHeight}>
		{children}
	</InPlaceModal>
);

const ConfirmLeavePopup: FC<ConfirmLeaveProps> = memo(({ open, onClose, handleLeavePage }) => (
	<StyledPopupOverlay open={open}>
		<ConfirmLeavePopupWrapper modalHeight={600} open={open} onClose={onClose}>
			<ConfirmHeader>
				<Text variant="h6">Leave Page?</Text>
				<IconButton onClick={onClose}>
					<Icon name="close" group="filled" fill="#c5cee0" />
				</IconButton>
			</ConfirmHeader>
			<StyledDivider thickness={2} borderColor="#edf1f7" />
			<StyledParagraphText variant="body1">
				Are you sure you want to leave this page? Your changes will be lost if you leave.
			</StyledParagraphText>
			<StyledDivider thickness={2} borderColor="#edf1f7" />
			<ConfirmFooter container justify="flex-end">
				<Button palette="light" buttonTheme="outline" onClick={onClose}>
					Stay on Page
				</Button>
				<Button onClick={handleLeavePage}>Leave Page</Button>
			</ConfirmFooter>
		</ConfirmLeavePopupWrapper>
	</StyledPopupOverlay>
));

export default ConfirmLeavePopup;
