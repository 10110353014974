import React, { FC } from "react";

import { Box } from "@material-ui/core";

import { PlaceholderImage } from "shared/Components";
import { PlaceholderImageType } from "shared/types";
import { Text } from "shared/ui-kit";

import { Wrapper } from "./style";

interface Props {
	photo?: string;
	date: string;
	title: string;
	subtitle?: string;
}

const ActivityCard: FC<Props> = ({ photo, date, title, subtitle }) => (
	<Wrapper>
		<Wrapper.IconWrapper>
			{photo ? (
				<img src={photo} alt={title} />
			) : (
				<PlaceholderImage type={PlaceholderImageType.PROFILE_IMAGE} width={96} height={96} viewBox={"0 0 400 400"} />
			)}
		</Wrapper.IconWrapper>
		<Box>
			<Text variant="label">{date}</Text>
			<Text variant="h3">{title}</Text>
			{subtitle && <Text variant="subtitle1">{subtitle}</Text>}
		</Box>
	</Wrapper>
);

export default ActivityCard;
