import React, { FC, useMemo } from "react";

import { Box, FormControl, useMediaQuery } from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";

import { Controller, useForm } from "react-hook-form";

import { ReactComponent as ClockIcon } from "assets/icons/clock-filled.svg";
import { ReactComponent as DownloadIcon } from "assets/icons/icon-download.svg";
import { CategoryProps } from "modules/Marketplace/Data/types";
import { DateInput, EnableFeature, MemberDialog, SelectInput } from "modules/MemberHome/View/shared";
import LabeledInput from "modules/MemberHome/View/shared/LabeledInput";

import { StepBox, StepColumn, UploaderWrapper, VideoPreviewWrapper } from "shared/Components/Video/style";
import { useUser, useVideo } from "shared/hooks";
import { useS3Uploader } from "shared/services/s3Uploader";
import { VideoModel } from "shared/types";
import { times } from "shared/types/times";
import { Icon, Uploader, orientationConst } from "shared/ui-kit";
import * as appTheme from "theme/default";
import { getDefaultTime } from "utils/getDefaultTime";

import { InputWrapper, FormControl as StyledFormControl, Wrapper } from "./style";

import { SetupPrice } from "..";
import { SetupPriceMethods } from "../SetupPrice";

const CreateVideoMemberView: FC<{
	open: boolean;
	onClose: () => void;
	suggestedCategories: CategoryProps[];
	loadSuggestedCategories: (name: string) => Promise<void>;
	onUploaderChange: (files: any[], onChange: (...event: any[]) => void) => Promise<void>;
	createVideo: (data: any) => Promise<void>;
	setupPriceFormRef: React.RefObject<SetupPriceMethods>;
	editableModel?: VideoModel;
}> = ({
	open,
	onClose,
	suggestedCategories,
	loadSuggestedCategories,
	onUploaderChange,
	createVideo,
	setupPriceFormRef,
	editableModel
}) => {
	const {
		control,
		formState: { isDirty, isSubmitting, errors },
		handleSubmit
	} = useForm({ mode: "onChange" });

	const isMobile = useMediaQuery(appTheme.default.breakpoints.down("sm"));

	const { getData: getS3UploaderData } = useS3Uploader();
	const { uploading, uploadingProgress } = getS3UploaderData();

	const { getData: getVideoData } = useVideo();
	const { videoFileUrl, videoName } = getVideoData();

	const { getData: getUserData } = useUser();
	const { user } = getUserData();

	const showPremiumAccess = useMemo(
		() => user?.isGlobalAdmin || user?.isGlobalOwner || user?.isGlobalModerator,
		[user]
	);

	const tomorrow = useMemo(() => new Date().setDate(new Date().getDate() + 1), []);

	const onSubmit = data => {
		delete data.priceChanged;

		const hours = data.time ? parseInt(data.time.value.split(":")[0]) : 0;
		const minutes = data.time ? parseInt(data.time.value.split(":")[1]) : 0;

		const scheduleDate = hours && minutes ? new Date(data.date).setHours(hours, minutes) : undefined;

		createVideo({
			...data,
			categoryId: data.category.value,
			scheduleDate
		});
	};

	const UploaderBlock = useMemo(
		() => (
			<StepColumn className="narrow">
				<Controller
					name="videoImageUrl"
					control={control}
					render={({ value, onChange }) => (
						<UploaderWrapper>
							<Uploader
								urls={value ? [value] : undefined}
								onChange={(files: any) => onUploaderChange(files, onChange)}
								label="Upload Thumbnail"
								orientation={orientationConst.vertical}
								width={"100%"}
								description={
									<>
										Drag and Drop File Here or <span className="anchor">Browse</span> to Choose a File
									</>
								}
								accept={[
									{
										fileType: "image/png, image/jpeg, image/svg+xml, image/x-eps",
										name: "png, jpg, svg, eps"
									}
								]}
								icon={
									<Icon
										group={""}
										fill={"#c5cee0"}
										name={"cloud-upload"}
										width={64}
										height={50}
										viewBox={"4 2 18 19"}
									/>
								}
							/>
						</UploaderWrapper>
					)}
				/>
			</StepColumn>
		),
		[control, onUploaderChange]
	);

	return (
		<MemberDialog
			customWidth={960}
			uploadingProgress={uploadingProgress}
			title="Create a Video"
			open={open}
			onClose={onClose}
			confirmLeave={true}
			footerPrimary={{
				text: "Create",
				disabled: !isDirty || !!Object.keys(errors).length || isSubmitting || uploading || !videoFileUrl,
				loading: isSubmitting,
				onClick: handleSubmit(onSubmit)
			}}
		>
			<form onSubmit={handleSubmit(onSubmit)}>
				<Wrapper className="pv-24 ph-40">
					<StepBox className="row active">
						<StepColumn className="wide">
							<FormControl>
								<VideoPreviewWrapper>
									<video src={videoFileUrl} controls height={"100%"} />
								</VideoPreviewWrapper>
							</FormControl>
							{isMobile && <StyledFormControl>{UploaderBlock}</StyledFormControl>}
							<FormControl>
								<Controller
									name="title"
									control={control}
									rules={{ required: "Title is required!" }}
									defaultValue={videoName}
									render={({ onChange, value, ref }) => (
										<InputWrapper>
											<LabeledInput
												name="title"
												value={value}
												onChange={onChange}
												label="Video title*"
												error={errors?.title?.message}
												inputRef={ref}
											/>
										</InputWrapper>
									)}
								/>
							</FormControl>
							<FormControl>
								<Controller
									name="category"
									control={control}
									rules={{ required: "Category is required!", validate: value => !!value }}
									render={({ onChange, value, ref }) => (
										<InputWrapper>
											<SelectInput
												name="category"
												onChange={onChange}
												value={value && value.label}
												placeholder=" "
												label="Category *"
												loadSuggestions={val => loadSuggestedCategories(val)}
												maxHeight={260}
												error={errors?.category?.message}
												noOptionsHeadline="Video Category ( ex: HipHop )"
												options={
													suggestedCategories
														? suggestedCategories.map(suggestion => ({
																value: suggestion._id,
																label: suggestion.name
														  }))
														: []
												}
												selectInputRef={ref}
											/>
										</InputWrapper>
									)}
								/>
							</FormControl>
							<FormControl>
								<Controller
									name="description"
									control={control}
									rules={{
										required: "Description is required!",
										maxLength: {
											value: 50,
											message: "Description should not exceed 50 characters"
										},
										validate: value => !!value
									}}
									render={({ onChange, value, ref }) => (
										<InputWrapper>
											<LabeledInput
												showCounter={50}
												textarea
												name="description"
												value={value}
												onChange={onChange}
												label="Description*"
												error={errors?.description?.message}
												inputRef={ref}
											/>
										</InputWrapper>
									)}
								/>
							</FormControl>
							{showPremiumAccess && (
								<FormControl>
									<InputWrapper>
										<Controller
											name="priceChanged"
											control={control}
											render={({ onChange }) => (
												<SetupPrice
													ref={setupPriceFormRef}
													defaultValue={editableModel?.priceTags}
													onPriceChanged={() => onChange(true)}
												/>
											)}
										/>
									</InputWrapper>
								</FormControl>
							)}
							<Controller
								name="scheduled"
								control={control}
								defaultValue={!!editableModel?.meta?.scheduleDate}
								render={({ onChange, value }) => (
									<FormControl>
										<InputWrapper>
											<EnableFeature
												icon={<Icon fill="#8f9bb3" name="clock" width={24} height={24} />}
												onChange={onChange}
												title="Schedule Video"
												value={value}
											/>
										</InputWrapper>
										{value && (
											<Box className="two-inputs-row">
												<Controller
													name="date"
													control={control}
													defaultValue={tomorrow}
													render={({ onChange, value, ref }) => (
														<InputWrapper width="60%">
															<DateInput
																label="Date *"
																name="date"
																value={value}
																onChange={onChange}
																error={errors?.date?.message}
																placeholder=" "
																displayOnly
																inputRef={ref}
															/>
														</InputWrapper>
													)}
												/>
												<Controller
													name="time"
													control={control}
													defaultValue={getDefaultTime()}
													render={({ onChange, value, ref }) => (
														<InputWrapper width="38%">
															<SelectInput
																label="Time *"
																name="time"
																value={value && value.label}
																onChange={onChange}
																placeholder=" "
																options={times}
																maxHeight={180}
																rightIcon={{
																	el: <ClockIcon />,
																	active: false
																}}
																displayOnly
																noOptionsHeadline="Select Time Option"
																selectInputRef={ref}
															/>
														</InputWrapper>
													)}
												/>
											</Box>
										)}
										<Controller
											name="canBeDownloaded"
											control={control}
											defaultValue={editableModel?.meta.canBeDownloaded}
											render={({ onChange, value }) => (
												<InputWrapper>
													<EnableFeature
														icon={<DownloadIcon />}
														onChange={onChange}
														title="Allow Download"
														value={value}
													/>
												</InputWrapper>
											)}
										/>
										<Controller
											name="private"
											control={control}
											defaultValue={editableModel?.meta?.private}
											render={({ onChange, value }) => (
												<InputWrapper>
													<EnableFeature
														fill="#8f9bb3"
														icon={<VisibilityIcon />}
														onChange={onChange}
														title="Set as Private"
														description="Choose this option to hide from others in the community"
														value={value}
														verticalPadding
													/>
												</InputWrapper>
											)}
										/>
									</FormControl>
								)}
							/>
						</StepColumn>
						{!isMobile && UploaderBlock}
					</StepBox>
				</Wrapper>
			</form>
		</MemberDialog>
	);
};

export default CreateVideoMemberView;
