import React, { useCallback, useEffect } from "react";

import { DateTime } from "luxon";

import { useEvent, useUser } from "shared/hooks";
import { useS3Uploader } from "shared/services/s3Uploader";
import { EventModel } from "shared/types";
import { dataUrlToFile } from "utils/serviceUtils/helpers";

import CreateEventManageView from "./ManageView";
import CreateEventMemberView from "./MemberView";

interface Props {
	onClose: (event: { created?: boolean }, reason: string) => void;
	onSelect?: (content: any) => void;
	editableModel?: EventModel;
	open: boolean;
	groupId?: string;
}

const CreateEvent: React.FC<Props> = ({ open, onClose, editableModel, groupId, onSelect }) => {
	const { uploadFile } = useS3Uploader();

	const { loadCategories, getData: getEventData, createEvent, setCreating } = useEvent();
	const { eventImageUploadUrl } = getEventData();

	const { getData: getUserData } = useUser();
	const { isMemberView } = getUserData();

	useEffect(() => {
		if (open) {
			loadCategories();
		}
	}, [loadCategories, open]);

	const onUploaderChange = useCallback(
		async (files: FileList | any[], onChange: (...event: any[]) => void) => {
			const img = files && files.length ? files[0] : null;
			const correctFile = typeof img === "string" ? await dataUrlToFile(img, "test") : img;
			if (img) {
				const data = await uploadFile({
					file: correctFile as File,
					communityName: eventImageUploadUrl
				});
				onChange(data?.publicUrl);
			} else {
				onChange(null);
			}
		},
		[uploadFile, eventImageUploadUrl]
	);

	const handleClose = useCallback(
		(event: { created?: boolean }, reason: string) => {
			setCreating(false);
			onClose(event, reason);
		},
		[onClose, setCreating]
	);

	const onSubmit = async ({
		data,
		isOnlineEvent,
		liveconversation,
		startTime,
		endTime,
		isUpdate
	}: {
		data;
		isOnlineEvent: boolean;
		liveconversation: boolean;
		startTime?: Date;
		endTime?: Date;
		isUpdate?: boolean;
	}) => {
		const event = await createEvent(
			{
				...data,
				isOnlineEvent,
				liveconversation,
				startTime,
				endTime,
				eventId: isUpdate ? data.eventId : undefined,
				groupId: isUpdate ? data.groupId : groupId
			},
			isUpdate
		);
		onSelect && onSelect(event);
		handleClose({ created: true }, "");
	};

	const checkValidDate = useCallback((startTime?: string | Date, endTime?: string | Date) => {
		if (!startTime || !endTime) return true;

		const startTimeLuxon = DateTime.fromJSDate(new Date(startTime));
		const endTimeLuxon = DateTime.fromJSDate(new Date(endTime));

		return startTimeLuxon < endTimeLuxon && startTimeLuxon > DateTime.now().minus({ minute: 1 });
	}, []);

	if (isMemberView) {
		return (
			<CreateEventMemberView
				open={open}
				onClose={handleClose}
				editableModel={editableModel}
				onUploaderChange={onUploaderChange}
				groupId={groupId}
				handleCreateEvent={onSubmit}
				checkValidDate={checkValidDate}
			/>
		);
	}

	return (
		<CreateEventManageView
			open={open}
			onClose={handleClose}
			editableModel={editableModel}
			onUploaderChange={onUploaderChange}
			handleCreateEvent={onSubmit}
			checkValidDate={checkValidDate}
		/>
	);
};

export default CreateEvent;
