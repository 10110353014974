import { Box } from "@material-ui/core";
import styled, { css } from "styled-components";

import { Text } from "shared/ui-kit";

export const PopupTitle = styled(Text)`
	font-size: 18px;
	font-weight: bold;
	line-height: 1.33;
	color: #222b45;
	text-align: left;

	padding-left: 16px;
`;

export const DialogBody = styled(Box)`
	padding: 16px 8px;

	${props => props.theme.breakpoints.up("sm")} {
		padding: 32px 40px 16px;
		.multiple-inputs {
			display: flex;
			> div {
				flex: 1;
				&:first-child {
					margin-top: 2px;
					margin-right: 8px;
				}
				&:last-child {
					margin: 0;
					margin-left: 8px;
				}
			}
		}
	}
`;

export const UploaderWrapper = styled(Box)`
	position: relative;
	margin-top: 6px;
	margin-bottom: 20px;
	.explorer-uploader-label-text {
		color: #222b45;
	}
	.explorer-uploader .description .anchor {
		color: #6173fe;
	}
	.uploader-wrapper {
		flex: 1;
	}
`;

export const InputWrapper = styled(Box)`
	margin-top: 6px;
	margin-bottom: 20px;

	${({ noLabel }) =>
		noLabel &&
		css`
			.MuiIconButton-label {
				display: none;
			}
		`}
`;

export const CreateDialogFooter = styled(Box)`
	display: flex;
	justify-content: flex-end;
	padding: 8px;
	position: sticky;
	bottom: 0;
	background-color: white;
	z-index: 1;
	border-top: 1px solid #edf1f7;

	${props => props.theme.breakpoints.up("sm")} {
		padding: 8px 40px 16px;
	}
`;

export const FeatureBlock = styled(Box)`
	&.pb-8 {
		padding-bottom: 8px;
	}
	.head {
		padding-top: 16px;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.input {
		padding: 8px 0 16px;
		&.multiple > div:first-child {
			margin-bottom: 6px;
		}
		${props => props.theme.breakpoints.up("sm")} {
			&.multiple {
				display: flex;
				> div:first-child {
					margin-bottom: 0;
					width: 65%;
					margin-right: 12px;
				}
				> div:last-child {
					width: 35%;
				}
			}
		}
	}
	${({ noBorder }) =>
		!noBorder &&
		css`
			margin-bottom: 8px;
			border-bottom: 1px solid #e4e9f2;
		`}
	.left-side {
		display: flex;
		svg {
			fill: #8f9bb3;
		}
		h6 {
			margin-left: 8px;
		}
		p {
			padding: 0 10px;
			color: #8f9bb3;
		}
	}
`;

export const SelectPriceSelectWrapper = styled(Box)`
	svg {
		width: 32px;
		height: 32px;
		top: unset;
	}
	.MuiOutlinedInput-root {
		width: 100%;
	}
`;

export const FooterWrapper = styled(Box)`
	display: flex;
	width: 100%;
	text-align: right;
	justify-content: flex-end;

	&.cancelBtn {
		justify-content: space-between;
	}
`;
