import { ButtonBase } from "@material-ui/core";
import styled from "styled-components";

import { Box } from "shared/ui-kit";

export const ListWrapper = styled(Box)`
	margin-top: 8px;
	height: 100%;
	padding: 1px;

	&.pageBlock {
		padding: 5px 16px;
	}
`;

export const BtnWrapper = styled(Box)`
	padding: 16px;
`;

export const ItemButtonClickable = styled(ButtonBase)`
	width: 100%;
	text-align: left;
	display: block;
`;

export const ItemButton = styled(Box)`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	button {
		text-align: left;
		flex: 1;
		display: flex;
		justify-content: flex-start;
		&.follow {
			flex-grow: 0;
			flex-shrink: 0;
			min-width: 88px;
			height: 32px;
			border-radius: 8px;
			justify-content: center;
		}
	}
`;

export const ItemWrapper = styled(Box)`
	display: flex;
	align-items: center;
	text-decoration: none;

	&.bordered {
		&:not(:last-child) {
			border-bottom: 1px solid #c5cee0;
		}
	}
`;

export const StyledSkeleton = styled(Box);

StyledSkeleton.Wrapper = styled(Box)`
	padding: 10px;

	width: 100%;

	display: flex;
	align-items: center;

	&.small {
		padding: 24px 16px;
	}
`;

StyledSkeleton.Body = styled(Box)`
	width: calc(100% - 40px);
	height: 40px;

	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 2px 16px;
`;
