import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";

import { saveAs } from "file-saver";

import JSZip from "jszip";

import { DateTime } from "luxon";

import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useHistory } from "react-router";

import { ReactComponent as CopyIcon } from "assets/icons/copy.svg";
import { ReactComponent as DeleteIcon } from "assets/icons/icon-delete.svg";
// import { ReactComponent as IconSlash } from "assets/icons/icon-slash.svg";
import { ReactComponent as ReportIcon } from "assets/icons/liveconvo/report.svg";
import { ReactComponent as IconManInLotus } from "assets/icons/manInLotus.svg";
import { ReactComponent as PencilIcon } from "assets/icons/pencil.svg";
// import { ReactComponent as ShareIcon } from "assets/icons/share.svg";

// import { ReactComponent as IconEyeStrikethrough } from "assets/icons/stories/icon-eye-strikethrough.svg";
// import { ReactComponent as IconPictureLinear } from "assets/icons/stories/icon-picture-linear.svg";
import { SeriesCollection } from "modules/Manage/Data/types";

import { reorder } from "modules/Manage/View/Components/AlbumDetailsDialog";
import {
	ConfirmActionModal,
	CreateUpdateSeriesWrapper,
	MenuDotsOption,
	PlaceholderImage,
	UnlockPremiumBtn
} from "shared/Components";
import {
	useAlbums,
	useMemberRoutes,
	useNotification,
	useOnScreen,
	useReportContent,
	useSeries,
	useUser
} from "shared/hooks";
import { CrudType, PlaceholderImageType, ReportContentType, UnlockContentType } from "shared/types";
import { Button, Icon } from "shared/ui-kit";
import { formatCount } from "utils/serviceUtils/helpers";

import { validateYoutube } from "utils/serviceUtils/validators";

import {
	AddContentButton,
	Container,
	ContentHeader,
	ContentHeaderWrapper,
	ContentText,
	EmptyContentWrapper,
	ItemWrapper,
	MoreItemsWrapper,
	UnlockedBlockWrapper
} from "./style";

import { CollectionRow, EntityTemplate, SeriesCard } from "../../Components";
import { Action } from "../../Components/FilterBlock/style";
import TracksProgress from "../../Components/TracksProgress";
import { RefBox } from "../../shared/MemberDialog/style";
import { ContentTitle } from "../Collections/style";
import { OtherBlock } from "../File/style";

interface CollectionPageProps {
	id: string;
	groupId?: string;
}

const CollectionPage: FC<CollectionPageProps> = ({ id, groupId }) => {
	const {
		getCollection,
		getCollections,
		setSeriesPlayState,
		getSeriesUrls,
		setDeleteSeriesDialog,
		deleteCollection,
		setSeriesDialog,
		reorderCollection,
		setSeriesDetailsDialog,
		isUserCouldManageSeries,
		getData: getSeriesData
	} = useSeries();

	const { isLoading, deleteSeriesDialog, seriesDetailsDialog, seriesSuggestions } = getSeriesData();

	const { readSong, setTrackProgressDialog } = useAlbums();
	const { getReportTypeOptions } = useReportContent();

	const { showMessage } = useNotification();

	const { getData: getUserData, isPrivilegedRole } = useUser();
	const { user } = getUserData();

	const history = useHistory();
	const [offset, setOffset] = useState(1);
	const [collection, setCollection] = useState<SeriesCollection | null>(null);
	const [loading, setLoading] = useState(false);

	const lastItemRef = useRef<HTMLDivElement>(null);
	const onScreen = useOnScreen(lastItemRef);

	const { getMemberRoutesData } = useMemberRoutes();
	const { routes } = getMemberRoutesData();

	const hasMoreItems = useMemo(
		() => collection && collection.itemIds.length < collection.originalItemIds.length,
		[collection]
	);

	useEffect(() => {
		if (onScreen && hasMoreItems) {
			setOffset(ctx => ctx + 1);
		}
	}, [onScreen, hasMoreItems]);

	const loadCollection = useCallback(() => {
		setLoading(true);
		getCollection({ id, offset, paginate: true })
			?.then(series => {
				if (series) {
					setCollection(ctx =>
						offset > 1 && ctx?.itemIds ? { ...series, itemIds: [...ctx.itemIds, ...series.itemIds] } : series
					);
				}
			})
			.finally(() => {
				setLoading(false);
			});
	}, [getCollection, id, offset]);

	useEffect(() => {
		loadCollection();
	}, [loadCollection]);

	useEffect(() => {
		getCollections({
			limit: 5,
			keyword: "",
			offset: 1,
			groupId
		});
	}, [getCollections, groupId]);

	const handleCollectionClicked = (id: string) => {
		history.push(`${routes?.member.collection.getPath()}/${id}`);
	};

	const handleStartCollection = () => {
		history.push(`${routes?.member.collection.getPath()}/${id}/start`);
	};

	const handleClick = (idx: number) => {
		setSeriesPlayState({
			currentIdx: idx,
			sId: id
		});

		handleStartCollection();
	};

	const onUpdate = useCallback(
		() =>
			getCollection({ id, offset, paginate: true })
				?.then(series => {
					if (series) {
						setCollection(series);
					}
				})
				.finally(() => {
					setLoading(false);
				}),
		[getCollection, id, offset]
	);

	const summary = useMemo(() => {
		if (collection?.originalItemIds) {
			let files = 0,
				audio = 0,
				videos = 0;

			const summary: string[] = [];

			collection.originalItemIds.forEach(file => {
				if (file.type === "VIDEO") {
					videos++;
				} else if (file.type === "TRACK") {
					audio++;
				} else {
					files++;
				}
			});

			if (videos > 0) summary.push(`${formatCount(videos, "Video")}`);
			if (audio > 0) summary.push(`${formatCount(audio, "Track")}`);
			if (files > 0) summary.push(`${formatCount(files, "File")}`);

			return `${summary}`.replaceAll(",", " | ");
		}
		return "";
	}, [collection]);

	const handleDownloadCollection = useCallback(async () => {
		const urls = await getSeriesUrls(id);

		if (!urls || !collection) return;

		const filteredUrls = urls.filter(({ url }) => !validateYoutube(url));

		setTrackProgressDialog(
			filteredUrls.map(url => ({
				name: url.name,
				track: url.url,
				progress: 0
			}))
		);

		const zip = new JSZip();

		const files = await Promise.all(
			filteredUrls.map(async url => {
				const extension = url.url.split(".")[url.url.split(".").length - 1];
				return {
					title: `${collection.title}/${url.name.replace(/\s/g, "-")}.${extension ? extension : "mp3"}`,
					blob: await readSong(url.url)
				};
			})
		);

		files.forEach(file => zip.file(file.title, file.blob));

		zip.generateAsync({ type: "blob" }).then(
			content => {
				saveAs(content, `${collection.title}.zip`);
			},
			err => {
				console.log("error is", err);
			}
		);
	}, [collection, getSeriesUrls, id, readSong, setTrackProgressDialog]);

	const isMember = useMemo(() => user?.isGlobalMember, [user?.isGlobalMember]);

	const onlyAddSelfContent = useMemo(
		() => isMember && !isPrivilegedRole && user?.userId === collection?.creatorId,
		[isMember, isPrivilegedRole, user?.userId, collection?.creatorId]
	);

	const options: MenuDotsOption[] = useMemo(() => {
		let BASE_OPTIONS: MenuDotsOption[] = [];

		BASE_OPTIONS = [
			// TODO: Share with members when messaging on member side is done ( https://zpl.io/L48GGBQ )
			// {
			// 	name: "Share with Connections",
			// 	onClick: () => {},
			// 	icon: <ShareIcon />
			// },
			{
				name: "Copy Link",
				onClick: async () => {
					await navigator.clipboard.writeText(window.location.href);
					showMessage("Successfully copied link to your clipboard");
				},
				icon: <CopyIcon />
			}
		];

		if (!collection?.creator && !isPrivilegedRole) {
			BASE_OPTIONS.push({
				name: "Report",
				icon: <ReportIcon viewBox="0 -2 22 22" />,
				onClick: () => {},
				submenuOptions: getReportTypeOptions({
					reportType: ReportContentType.SERIES,
					reportContentId: `${collection?._id}`,
					reportContentName: collection?.title,
					customCallback: () => {
						history.go(-1);
					}
				})
			});
		}

		if (isUserCouldManageSeries({ type: CrudType.UPDATE, isCreator: !!collection?.creator })) {
			BASE_OPTIONS = [
				...BASE_OPTIONS,
				{
					name: "Edit Series Details",
					onClick: () => setSeriesDialog({ open: true, data: collection || undefined, isEditingDetail: true }),
					icon: <PencilIcon />
				},
				{
					name: "Edit Series Content",
					onClick: () => {
						if (collection?.itemIds) {
							setSeriesDetailsDialog({
								...seriesDetailsDialog,
								addContent: true,
								data: collection
							});
						}
					},
					icon: <PencilIcon />
				},
				{ name: "Delete Series", onClick: () => setDeleteSeriesDialog(collection?._id), icon: <DeleteIcon /> }
			];
		}

		return BASE_OPTIONS;
	}, [
		collection,
		isUserCouldManageSeries,
		showMessage,
		setSeriesDialog,
		setDeleteSeriesDialog,
		getReportTypeOptions,
		history,
		setSeriesDetailsDialog,
		seriesDetailsDialog,
		isPrivilegedRole
	]);

	const handleConfirmDelete = async () => {
		await deleteCollection(deleteSeriesDialog || "");
		history.push(routes?.member.collections.getPath());
	};

	const goToOtherSeries = useCallback(() => {
		history.push(`${routes?.member.collections.getPath()}/all${groupId ? `?groupId=${groupId}` : ""}`);
	}, [groupId, history, routes?.member.collections]);

	const canEdit = Boolean(collection?.creator) || isPrivilegedRole;

	const onDragEnd = result => {
		if (result.destination && result.destination.index !== result.source.index) {
			const items = reorder(collection?.itemIds, result.source.index, result.destination.index) as any;
			const itemIds = items.map(item => ({ _id: item._id, type: item.type }));
			if (collection?._id) {
				setCollection({ ...collection, itemIds: items });
				reorderCollection(collection._id, itemIds);
			}
		}
	};

	const handleAddContentBtnClick = () => {
		if (collection) {
			setSeriesDetailsDialog({
				addContent: true,
				data: collection,
				seriesDetails: collection._id
			});
		}
	};

	const renderSeriesContent = () => {
		const title = <ContentTitle variant="subtitle1">Series content</ContentTitle>;

		if (Boolean(collection?.itemIds.length)) {
			if (canEdit) {
				return (
					<>
						<ContentHeaderWrapper>
							<ContentHeader>{title}</ContentHeader>
							{isUserCouldManageSeries({
								type: CrudType.UPDATE,
								isCreator: !!collection?.creator
							}) && (
								<ContentHeader>
									<AddContentButton size="small" palette="primary" onClick={handleAddContentBtnClick}>
										<Icon name="plus" width={16} height={16} fill="#ffffff" />
										<span>Add Content</span>
									</AddContentButton>
								</ContentHeader>
							)}
						</ContentHeaderWrapper>
						<DragDropContext onDragEnd={onDragEnd}>
							<Droppable droppableId="droppable">
								{provided => (
									<div {...provided.droppableProps} ref={provided.innerRef}>
										{collection?.itemIds.map((item, i) => (
											<Draggable key={item._id} draggableId={item._id} index={i}>
												{provided => (
													<div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
														<RefBox ref={lastItemRef} key={i}>
															<CollectionRow
																item={item}
																order={i + 1}
																onClick={!collection.locked ? () => handleClick(i) : undefined}
																canEdit={canEdit}
																allowDownload={collection.meta.canBeDownloaded && collection.meta.individualAccess}
															/>
														</RefBox>
													</div>
												)}
											</Draggable>
										))}
									</div>
								)}
							</Droppable>
						</DragDropContext>
					</>
				);
			} else {
				return (
					<>
						{title}
						{collection?.itemIds.map((item, i) => (
							<RefBox ref={lastItemRef} key={i}>
								<CollectionRow
									item={item}
									order={i + 1}
									onClick={!collection.locked ? () => handleClick(i) : undefined}
									canEdit={canEdit}
									allowDownload={collection.meta.canBeDownloaded && collection.meta.individualAccess}
								/>
							</RefBox>
						))}
					</>
				);
			}
		} else if (
			!collection?.itemIds.length &&
			isUserCouldManageSeries({ type: CrudType.UPDATE, isCreator: !!collection?.creator }) &&
			!isLoading
		) {
			return (
				<>
					<ContentHeader>{title}</ContentHeader>
					<EmptyContentWrapper>
						<IconManInLotus />
						<ContentText>You don’t have any content yet.</ContentText>
						<Action.Wrapper>
							<Action.Item
								removeSideMargin
								buttonTheme={"light"}
								palette="primary"
								onClick={handleAddContentBtnClick}
								leftIcon={<Icon fill="#ffffff" name="plus" />}
							>
								<span>Add Content</span>
							</Action.Item>
						</Action.Wrapper>
					</EmptyContentWrapper>
				</>
			);
		}

		return null;
	};

	return (
		<>
			<Container>
				<EntityTemplate
					loading={loading && offset === 1}
					coverPhoto={collection?.meta?.artwork?.url}
					placeholderImg={
						<PlaceholderImage
							type={PlaceholderImageType.SERIES_PREVIEW}
							width={224}
							height={224}
							viewBox={"0 0 400 400"}
						/>
					}
					pretitle={summary}
					title={collection?.title || ""}
					subtitle={collection?.category?.name}
					extra={
						collection?.createdAt
							? `Released: ${DateTime.fromJSDate(new Date(collection?.createdAt)).toRelative()}`
							: ""
					}
					options={options}
					locked={collection?.locked}
					description={collection?.description}
					unlockedBlock={
						!!collection?.originalItemIds.length ? (
							<UnlockedBlockWrapper>
								<Button onClick={handleStartCollection}>Start</Button>
								{collection?.meta?.canBeDownloaded && (
									<Button palette="light" buttonTheme="light" onClick={handleDownloadCollection}>
										Download
									</Button>
								)}
							</UnlockedBlockWrapper>
						) : undefined
					}
					unlockAction={
						<UnlockPremiumBtn
							premiumObject={collection}
							teaserContent={collection?.title}
							premiumObjectType={UnlockContentType.COLLECTION}
							onUnlock={() => {
								getCollection({ id, offset: 1 }).then(data => {
									if (data) {
										setCollection(data);
									}
								});
							}}
						/>
					}
				>
					{renderSeriesContent()}
					{(loading || isLoading) && (
						<>
							<CollectionRow skeleton />
							<CollectionRow skeleton />
							<CollectionRow skeleton />
							<CollectionRow skeleton />
							<CollectionRow skeleton />
						</>
					)}
				</EntityTemplate>
				{seriesSuggestions && !!seriesSuggestions.length && (
					<OtherBlock>
						<OtherBlock.Header>
							<OtherBlock.Title>More Series</OtherBlock.Title>
							<OtherBlock.ManageBtn removeSideMargin buttonTheme={"light"} palette={"basic"} onClick={goToOtherSeries}>
								See All
							</OtherBlock.ManageBtn>
						</OtherBlock.Header>
						<MoreItemsWrapper>
							{(seriesSuggestions || [])
								.filter(x => x._id !== collection?._id)
								.slice(0, 3)
								.map((collectionInfo, index) => (
									<ItemWrapper onClick={() => handleCollectionClicked(collectionInfo._id)} key={index}>
										<SeriesCard series={collectionInfo} />
									</ItemWrapper>
								))}
						</MoreItemsWrapper>
					</OtherBlock>
				)}
			</Container>
			<TracksProgress />
			<ConfirmActionModal
				open={!!deleteSeriesDialog}
				onClose={() => setDeleteSeriesDialog()}
				title="Delete Series?"
				bodyText={"Are you sure you want to delete this series?"}
				onConfirm={handleConfirmDelete}
				confirming={isLoading}
			/>
			<CreateUpdateSeriesWrapper
				onUpdateSeriesDialog={onUpdate}
				onUpdateSeriesContent={onUpdate}
				onlyAddSelfContent={onlyAddSelfContent}
				shouldUpdateIgnore={false}
			/>
		</>
	);
};

export default CollectionPage;
