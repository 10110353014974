import React, { useCallback, useMemo } from "react";

import { FormControl, MenuItem, Select } from "@material-ui/core";

import { Controller, useForm } from "react-hook-form";

import { UserRoles } from "types";

import { useUser } from "shared/hooks";
import { Button, Text } from "shared/ui-kit";

import { useTeam } from "../../../../Data";
import { EditRoleTeamMember } from "../../../../Data/types";
import {
	StyledButton,
	StyledFormColumn,
	StyledFormColumns,
	StyledFormContainer,
	StyledFormFooter,
	StyledInput
} from "../style";

const ChangeMemberRoleContent = () => {
	const { setStateOfModal, editRoleTeamMember, getData: getTeamData } = useTeam();
	const { editMemberRole, users } = getTeamData();

	const {
		handleSubmit,
		control,
		formState: { isDirty }
	} = useForm();

	const { getData: getUserData } = useUser();
	const { user } = getUserData();

	const RoleOptionsNewMember = useMemo(
		() =>
			[
				{
					label: "Owner",
					value: "isOwner",
					showOnModerator: false,
					showOnAdmin: false,
					showOnOwner: true
				},
				{
					label: "Admin",
					value: "isAdmin",
					showOnModerator: false,
					showOnAdmin: true,
					showOnOwner: true
				},
				{
					label: "Moderator",
					value: "isModerator",
					showOnModerator: true,
					showOnAdmin: true,
					showOnOwner: true
				},
				{
					label: "Influencer",
					value: "isInfluencer",
					showOnModerator: true,
					showOnAdmin: true,
					showOnOwner: true
				}
			].filter(item => {
				if (user?.role === UserRoles.Moderator) return item.showOnModerator;
				if (user?.role === UserRoles.Admin) return item.showOnAdmin;
				if (user?.role === UserRoles.Owner) return item.showOnOwner;
				return item;
			}),
		[user]
	);

	const editableUser = useMemo(() => {
		return users.find(x => x._id === editMemberRole);
	}, [editMemberRole, users]);

	const onSubmit = useCallback(
		({ invitedMemberRole }) => {
			setStateOfModal({ isOpen: false });

			editRoleTeamMember(
				{
					personaId: editableUser?.personaId,
					slug: "administrators"
				} as EditRoleTeamMember,
				invitedMemberRole
			);
		},
		[editableUser, setStateOfModal, editRoleTeamMember]
	);

	return (
		<StyledFormContainer
			alignContent="center"
			container
			direction="column"
			item
			justify="center"
			spacing={2}
			wrap="nowrap"
			onSubmit={handleSubmit(onSubmit)}
		>
			<StyledFormColumns>
				<FormControl component={StyledFormColumn} xs={12} sm={8}>
					<Controller
						name="invitedMembersEmails"
						control={control}
						defaultValue={editableUser?.email}
						render={({ value }) => <StyledInput label="Email Address" value={value} readonly />}
					/>
				</FormControl>
				<FormControl component={StyledFormColumn} xs={12} sm={4}>
					<Controller
						name="invitedMemberRole"
						as={Select}
						control={control}
						variant="outlined"
						id="invitedMemberRole"
						style={{ display: "flex", padding: "4px 0" }}
						defaultValue={editableUser?.role}
					>
						{RoleOptionsNewMember.map((item, index) => (
							<MenuItem key={index} className="menuItem" value={item.label}>
								<Text>{item.label}</Text>
							</MenuItem>
						))}
					</Controller>
				</FormControl>
			</StyledFormColumns>
			<StyledFormFooter textAlign="right">
				<Button palette="basic" buttonTheme="outline" size="large" onClick={() => setStateOfModal({ isOpen: false })}>
					Cancel
				</Button>
				<StyledButton buttonTheme="main" type="submit" palette="primary" size="large" disabled={!isDirty}>
					Save
				</StyledButton>
			</StyledFormFooter>
		</StyledFormContainer>
	);
};

export default ChangeMemberRoleContent;
