import { FormControl as MuiFormControl, makeStyles } from "@material-ui/core";

import styled from "styled-components";

import { MemberDialogContentWrapper } from "modules/MemberHome/View/shared/style";
import { Box, Button } from "shared/ui-kit";

export const FormControl = styled(MuiFormControl)`
	margin: 12px 0;
`;

export const UploadProgressBarWrapper = styled(Box)`
	position: absolute;
	top: 0;
	left: 0;

	width: 100%;

	z-index: 2;
`;

export const UploaderWrapper = styled(Box)`
	width: 100%;
	margin-top: 12px;

	.item-description {
		display: none;
	}

	> div {
		> div {
			width: 100%;
		}
	}

	.uploader-wrapper {
		height: 248px;
	}

	.preview-section {
		.close {
			z-index: 2;
		}

		.item-img-section {
			width: 100%;
			height: 100%;

			margin: 0;
			padding: 0;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
	}
	.explorer-uploader-label-text {
		color: #222b45;
	}
	.explorer-uploader .description .anchor {
		color: #6173fe;
	}

	.explorer-uploader {
		.icon svg {
			margin: auto;
		}
		${props => props.theme.breakpoints.down("sm")} {
			padding: 28px 24px;
		}
		.label {
			${props => props.theme.breakpoints.down("sm")} {
				font-size: 15px;
			}
		}
	}
`;

export const StyledActionButton = styled(Button)`
	height: 48px;

	&.full-screen {
		width: 100%;
	}

	${props => props.theme.breakpoints.down("sm")} {
		width: 100%;
	}
`;

export const useStyles = makeStyles(() => ({
	formControl: {
		width: "100%"
	}
}));

export const IconWrapper = styled(Box)`
	svg {
		path {
			fill: #8f9bb3;
		}
	}

	&.fill-second {
		svg {
			path {
				fill: none;

				&:nth-child(2) {
					fill: #8f9bb3;
				}
			}
		}
	}
`;

export const StepBox = styled(Box)`
	display: none;

	&.active {
		display: block;
	}

	&.row {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
	}

	&.hidden {
		display: none;
	}
`;

export const StepColumn = styled(Box)`
	display: block;
	width: 100%;

	&.wide {
		width: 61%;

		${props => props.theme.breakpoints.down("sm")} {
			width: 100%;
		}
	}

	&.narrow {
		width: 34%;

		${props => props.theme.breakpoints.down("sm")} {
			width: 100%;
		}
	}

	&.wide-center {
		width: 61%;
		max-width: 550px;

		margin: 0 auto;
	}

	${props => props.theme.breakpoints.down("sm")} {
		width: 100%;
	}
`;

export const VideoPreviewWrapper = styled(Box)`
	display: block;
	width: 100%;
	height: 248px;

	border-radius: 8px;

	overflow: hidden;

	background: #e4e9f2;

	video {
		width: 100%;
		height: 100%;
	}
`;

export const Wrapper = styled(MemberDialogContentWrapper)`
	.narrow > div {
		margin-top: 0;
	}
`;

export const InputWrapper = styled(Box)`
	margin-top: 20px;
`;

export const AutocompleteWrapper = styled(Box)`
	.MuiAutocomplete-inputRoot {
		width: 100%;
	}
`;
