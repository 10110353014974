import React, { FC, useCallback, useMemo } from "react";

import DateFnsAdapter from "@date-io/date-fns";

import LocationOnIcon from "@material-ui/icons/LocationOn";

import { ReactComponent as MailIcon } from "assets/icons/icon-mail-filled.svg";
import { ReactComponent as SexBothIcon } from "assets/icons/icon-sex-both.svg";
import { useCommunity } from "shared/hooks";
import { ProfileType } from "shared/types";
import { Icon, ReadMore } from "shared/ui-kit";

import { About } from "./style";

import { InfoRow } from "../../shared";
import { BioPlaceHolder, SectionHeader } from "../../shared/style";
import { Wrapper } from "../MembersList/style";

const dateFns = new DateFnsAdapter();

const AboutProfile: FC<{ profile: ProfileType; editable?: () => void; me?: boolean }> = ({ profile, editable, me }) => {
	const renderGender = useCallback(
		(gender: string) => (gender === "woman" ? "Female" : gender === "man" ? "Male" : "Other"),
		[]
	);
	const noContactInfo = useMemo(
		() => !profile.contactInfo?.email && !profile.address && !profile.phoneCode && !profile.portfolioUrl,
		[profile.address, profile.contactInfo, profile.phoneCode, profile.portfolioUrl]
	);

	const { communityColors } = useCommunity();

	const renderBio = useMemo(
		() => (
			<>
				{(profile.aboutMe || me) && <SectionHeader className="mb-2">About {profile.firstName}</SectionHeader>}
				{profile.aboutMe && (
					<ReadMore text={profile.aboutMe} showCharCount={300} wrapClassName="" wrapTextClassName="wrap" />
				)}
				{!profile.aboutMe && me && <BioPlaceHolder>No bio yet.</BioPlaceHolder>}
			</>
		),
		[me, profile.aboutMe, profile.firstName]
	);

	const noInfo = useMemo(
		() => noContactInfo && !profile.aboutMe && !profile?.personalInfo?.birthday && !profile?.personalInfo?.gender,
		[noContactInfo, profile]
	);

	// no data is available to display
	if (noInfo && !me) return null;

	return (
		<About color={communityColors.primary}>
			{editable && (
				<Wrapper.EditButton onClick={editable}>
					<Icon name={noInfo ? "plus" : "pencil"} group="filled" fill="#8f9bb3" />
				</Wrapper.EditButton>
			)}
			{renderBio}
			{!noContactInfo && (
				<>
					<SectionHeader className="mt-4 mb-4">Contact info</SectionHeader>
					<About.InfoRows>
						{((profile.contactInfo?.email && !profile.contactInfo.emailHidden) || me) && (
							<InfoRow
								icon={<MailIcon className="fill-first-path" />}
								title={profile.contactInfo?.email || "—"}
								subtitle="Email Address"
							/>
						)}
						{(profile.address || me) && (
							<InfoRow icon={<LocationOnIcon />} title={profile.address || "—"} subtitle="City/country" />
						)}
						{((profile.contactInfo?.phoneCode &&
							profile.contactInfo?.phoneNumber &&
							!profile.contactInfo?.phoneHidden) ||
							me) && (
							<InfoRow
								icon={<Icon name="phone" group="filled" className="fill-second-path" />}
								title={
									profile.contactInfo?.phoneNumber
										? `${profile.contactInfo?.phoneCode}-${profile.contactInfo?.phoneNumber}`
										: "—"
								}
								subtitle="Phone Number"
							/>
						)}
						{(profile.portfolioUrl || me) && (
							<InfoRow
								icon={<Icon name="globe" group="linear" className="fill-second-path" />}
								title={profile.portfolioUrl || "—"}
								subtitle="Website/portfolio"
							/>
						)}
					</About.InfoRows>
				</>
			)}
			{(!!profile?.personalInfo?.birthday || profile?.personalInfo?.gender) && (
				<>
					<SectionHeader className="mt-2 mb-4">Personal info</SectionHeader>
					<About.InfoRows>
						{profile.personalInfo.birthday && (me || !profile.personalInfo.birthdayHidden) && (
							<InfoRow
								className="mb-4"
								icon={<Icon name="gift" group="filled" />}
								title={dateFns.format(new Date(profile.personalInfo.birthday), "d / LLL / Y" as any)}
								subtitle="Birthday"
							/>
						)}
						{profile.personalInfo.gender && (me || !profile.personalInfo.genderHidden) && (
							<InfoRow
								className="mb-4"
								icon={<SexBothIcon />}
								title={renderGender(profile.personalInfo.gender)}
								subtitle="Gender"
							/>
						)}
					</About.InfoRows>
				</>
			)}
		</About>
	);
};

export default AboutProfile;
