import React from "react";

import { FormColumn, FormRow } from "shared/ui-kit";

import { BlockWrapper, ContentWrapper, PageWrapper, Title } from "./styles";

import { ActiveUsers, Metrics, NewUsers, Revenue, Sales, SignUps, Summary, TopReferees } from "../../Components";

const Analytics: React.FC = () => (
	<PageWrapper>
		<Title variant="h1">Analytics</Title>
		<ContentWrapper container spacing={3}>
			<FormColumn xs={12}>
				<FormRow xs={12}>
					<BlockWrapper>
						<Metrics />
					</BlockWrapper>
				</FormRow>
			</FormColumn>
		</ContentWrapper>

		<ContentWrapper container spacing={3}>
			<FormColumn md={6}>
				<FormRow xs={12}>
					<BlockWrapper>
						<Summary />
					</BlockWrapper>
				</FormRow>
				<FormRow xs={12}>
					<BlockWrapper>
						<SignUps />
					</BlockWrapper>
				</FormRow>
				<FormRow xs={12}>
					<BlockWrapper>
						<Sales />
					</BlockWrapper>
				</FormRow>
				<FormRow xs={12}>
					<BlockWrapper>
						<TopReferees displayCsvDownload />
					</BlockWrapper>
				</FormRow>
			</FormColumn>
			<FormColumn md={6}>
				<FormRow xs={12}>
					<BlockWrapper>
						<ActiveUsers displayCsvDownload />
					</BlockWrapper>
				</FormRow>
				<FormRow xs={12}>
					<BlockWrapper>
						<Revenue />
					</BlockWrapper>
				</FormRow>
				<FormRow xs={12}>
					<BlockWrapper>
						<NewUsers displayCsvDownload />
					</BlockWrapper>
				</FormRow>
			</FormColumn>
		</ContentWrapper>
	</PageWrapper>
);

export default Analytics;
