import React, { FC, useEffect, useState } from "react";

import { Stories } from "modules/MemberHome/View/Components";

import { STORIES_OPEN_TYPE } from "shared/contexts/StoryContext/StoryContext";
import { useStory } from "shared/hooks";

const MessagingLayout: FC = ({ children }) => {
	const [openStories, setOpenStories] = useState(false);
	const { getData: getStoryData, getPersonsStories } = useStory();
	const { personsStoryList, isOpen, dontRequestdata } = getStoryData();

	useEffect(() => {
		if (isOpen === STORIES_OPEN_TYPE.STORIES && !openStories) {
			if (dontRequestdata) {
				setOpenStories(true);
			} else {
				getPersonsStories(1, 10).then(() => {
					setOpenStories(true);
				});
			}
		}
	}, [getPersonsStories, isOpen, openStories, dontRequestdata]);

	return (
		<>
			{children}
			{openStories && (
				<Stories personsStoryList={personsStoryList} filteredStories={[]} onSliderClose={() => setOpenStories(false)} />
			)}
		</>
	);
};

export default MessagingLayout;
