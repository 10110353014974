import { useMemo } from "react";

import { CommunityModel, DefaultResponseType } from "types";

import useHttpActions from "shared/services/api/core/useHttpActions";
import BaseConverter from "shared/services/converters/baseConverter";

import {
	GetPostsType,
	GetWorkspaceSubscriptionType,
	MonetizationDataModel,
	WorkspaceStatisticModel
} from "shared/types";

import { API_URLS } from "../constants";

const useMarketingService = () => {
	const actions = useHttpActions();

	return useMemo(
		() => ({
			getWorkspaceInfo: async (workspace: string): Promise<CommunityModel> => {
				return BaseConverter.convert<CommunityModel>(
					await actions.get(API_URLS.WORKSPACE.INFO, null, {
						headers: {
							workspace
						}
					})
				);
			},
			getAllMonetization: async (workspace: string): Promise<MonetizationDataModel> => {
				return BaseConverter.convert<MonetizationDataModel>(
					await actions.getOriginal(API_URLS.WORKSPACE.MONETIZATION, null, {
						headers: {
							workspace
						}
					})
				);
			},
			getWorkspacePosts: async (workspace: string): Promise<GetPostsType> => {
				return BaseConverter.convert<GetPostsType>(
					await actions.post(
						API_URLS.WORKSPACE.POSTS,
						{
							path: "/v3/recent",
							method: "GET",
							payload: {
								type: "active",
								boardId: [],
								offset: 1,
								limit: 20
							}
						},
						{
							headers: {
								workspace
							}
						}
					)
				);
			},
			getWorkspaceStatistic: async (workspace: string): Promise<WorkspaceStatisticModel> => {
				return BaseConverter.convert<WorkspaceStatisticModel>(
					await actions.get(API_URLS.WORKSPACE.STATISTIC, null, {
						headers: {
							workspace
						}
					})
				);
			},
			getWorkspaceSubscriptionPlans: async (workspace: string): Promise<GetWorkspaceSubscriptionType> => {
				return BaseConverter.convert<GetWorkspaceSubscriptionType>(
					await actions.get(API_URLS.WORKSPACE.SUBSCRIPTIONS, null, {
						headers: {
							workspace
						}
					})
				);
			},
			sendSMSLink: async (phoneCode: string, phoneNumber: string, workspace: string): Promise<DefaultResponseType> => {
				return BaseConverter.convert<DefaultResponseType>(
					await actions.put(
						API_URLS.WORKSPACE.SMS_LINK,
						{
							phoneNumber,
							phoneCode,
							type: "invitationToApp"
						},
						{
							headers: {
								workspace
							}
						}
					)
				);
			}
		}),
		[actions]
	);
};

export default useMarketingService;
