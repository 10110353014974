const margin = 8;
const ratio = 3 / 4;

const area = (payload: { increment: number; height: number; width: number; total: number }) => {
	const { increment, height, width, total } = payload;

	let i = 0;
	let w = 0;
	let h = increment * ratio + margin * 2;

	while (i < total) {
		if (w + increment > width) {
			w = 0;
			h = h + increment * ratio + margin * 2;
		}
		w = w + increment + margin * 2;
		i++;
	}

	if (h > height || increment > width) return false;
	else return increment;
};

export const tilesResize = ({
	height,
	width,
	total
}: {
	width: number;
	height: number;
	total: number;
}): { width: number; height: number } => {
	// loop (i recommend you optimize this)
	let max = 0;
	let i = 1;
	while (i < 5000) {
		const _area = area({ increment: i, height, width, total });
		if (_area === false) {
			max = i - 1;
			break;
		}
		i++;
	}

	// remove margins
	max = max - margin * 2;

	return { width: max, height: max * ratio };
};
