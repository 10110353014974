import React, { FC } from "react";

import { PlaceholderImage } from "shared/Components";

import { PersonStoryModel, PlaceholderImageType } from "shared/types";
import { Text } from "shared/ui-kit";

import { Wrapper } from "./style";

const MatchCard: FC<{ match: PersonStoryModel }> = ({ match }) => (
	<Wrapper>
		{!!match.stories?.length ? (
			<img src={match.stories[0].uri} alt={match.firstName} className="story" />
		) : (
			<PlaceholderImage type={PlaceholderImageType.ALBUM_PREVIEW} width={200} height={320} viewBox={"0 0 200 320"} />
		)}
		<Wrapper.Header>
			{!!match.photos.length ? (
				<img src={match.photos[0].profilePicture} alt={match.firstName} />
			) : (
				<PlaceholderImage type={PlaceholderImageType.PROFILE_IMAGE} width={24} height={24} viewBox={"0 0 24 24"} />
			)}
			<Text>{match.firstName}</Text>
		</Wrapper.Header>
	</Wrapper>
);

export default MatchCard;
