import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";

import { ButtonBase, Chip, TextField } from "@material-ui/core";
import List from "@material-ui/core/List";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import Autocomplete from "@material-ui/lab/Autocomplete";

import { DatePicker, TimePicker } from "@material-ui/pickers";
import clsx from "clsx";
import { DateTime } from "luxon";
import * as R from "ramda";
import { Controller, useForm } from "react-hook-form";

import YouTube from "react-youtube";

import { useDebounce, useFundraisers, useGroup } from "shared/hooks";
import useScrollToXPosition from "shared/hooks/useScrollToXPosition";
import { useS3Uploader } from "shared/services/s3Uploader";
import { Icon, Loader, Text, Uploader, orientationConst } from "shared/ui-kit";

import { formatMoney } from "utils/formatMoney";
import { mergeDateAndTime } from "utils/serviceUtils/helpers";
import { validateYoutube } from "utils/serviceUtils/validators";
import { isValidYoutubeUrl } from "utils/urlValidator";

import { CloseButton, UploaderWrapper } from "./style";

import ConfirmLeavePopup from "../ConfirmLeave";
import useConfirmLeavePopup from "../ConfirmLeave/hooks/useConfirmLeavePopup";
import Dialog from "../Dialog";
import CreateGroup from "../Group/CreateGroup";
import { SubText } from "../Group/style";
import UploadProgressBar from "../UploadProgressBar";
import { FormControl, StyledActionButton, UploadProgressBarWrapper } from "../Video/style";
import { getVideoId } from "../Youtube/AddYoutube";

interface DialogProps {
	onClose: (created?: boolean) => void;
	onSubmit: (data: any) => void;
	onUploaderChange: (files: any[], onChange: (...event: any[]) => void, withId?: boolean) => Promise<void>;
}

const CreateFundraiserManageView: FC<DialogProps> = ({ onClose, onSubmit, onUploaderChange }) => {
	const { getData: getFundraiserData } = useFundraisers();
	const { createUpdateDialog, loading, submitting } = getFundraiserData();

	const { getData: getS3Data } = useS3Uploader();
	const { uploading, uploadingProgress } = getS3Data();

	const { getPaginatedGroup } = useGroup();

	const {
		control,
		formState: { errors, isValid, isDirty },
		handleSubmit,
		watch,
		setValue,
		reset
	} = useForm({ mode: "onChange" });

	const videoUrl = watch("videoUrl");
	const groupIds = watch("groupIds");
	const startDate = watch("startDate");
	const endDate = watch("endDate");

	const bodyRef = useRef<HTMLFormElement>();
	const scrollToXPositionOnFocus = useScrollToXPosition(bodyRef?.current);

	const [step, setStep] = useState(1);
	const [groupList, setGroupList] = useState<{ key: string; value: string }[]>([]);
	const [showYTInput, setShowYTInput] = useState(false);
	const [previewVideo, setPreviewVideo] = useState(false);
	const [keyword, setKeyword] = useState("");
	const [createGroupModal, setCreateGroupModal] = useState(false);

	const debouncedKeyword = useDebounce(keyword, 500);

	useEffect(() => {
		getPaginatedGroup({ page: 1, limit: 20, name: debouncedKeyword, preventStateUpdate: true }).then(groups => {
			if (groups) {
				if (groupIds?.length) {
					const filtered = groups
						.filter(({ _id, slug }) => !groupIds.some(({ key }) => _id === key) && slug !== "administrators")
						.map(g => ({ key: g._id, value: g.name }));
					setGroupList(filtered);
				} else {
					setGroupList(
						groups.filter(({ slug }) => slug !== "administrators").map(g => ({ key: g._id, value: g.name }))
					);
				}
			}
		});
	}, [debouncedKeyword, getPaginatedGroup, groupIds]);

	useEffect(() => {
		if (createUpdateDialog?.model) {
			reset({
				...R.pick(["name", "videoUrl", "thumbnail", "startDate", "endDate", "description"], createUpdateDialog.model),
				goalAmount: createUpdateDialog?.model.goalAmount ? formatMoney(createUpdateDialog.model.goalAmount) : "",
				groupIds: createUpdateDialog.model.groups.map(g => ({ key: g._id, value: g.name })),
				startTime: createUpdateDialog.model.startDate,
				endTime: createUpdateDialog.model.endDate
			});

			createUpdateDialog?.model.videoUrl && setPreviewVideo(true);
		}
	}, [createUpdateDialog?.model, reset]);

	const Title = useMemo(
		() => (
			<>
				<Text variant="h7">{!!createUpdateDialog?.model ? "Update" : "Create"} Fundraiser</Text>{" "}
				<SubText>• Step {step}/2</SubText>
			</>
		),
		[createUpdateDialog?.model, step]
	);

	const handleCreate = useCallback(
		data => {
			let startDate: Date | undefined;
			let endDate: Date | undefined;

			if (data.startTime && data.startDate) {
				startDate = mergeDateAndTime(data.startDate, data.startTime);
			}
			if (data.endTime && data.endDate) {
				endDate = mergeDateAndTime(data.endDate, data.endTime);
			}

			const goalAmount = parseInt(data.goalAmount.replace(/\D/g, ""));

			const formData: any = {
				name: data.name,
				description: data.description,
				startDate,
				endDate,
				goalAmount,
				videoUrl: data.videoUrl || null,
				thumbnail: {
					mediaId: data.thumbnail?.mediaId || null,
					url: data.thumbnail?.url || null
				},
				groupIds: data.groupIds.map(g => g.key)
			};

			if (createUpdateDialog?.model) formData._id = createUpdateDialog.model._id;

			onSubmit(formData);
		},
		[createUpdateDialog?.model, onSubmit]
	);

	const ActionButton = useMemo(
		() => (
			<StyledActionButton
				type="submit"
				leftIcon={
					loading || submitting || uploading ? (
						<Loader size="1rem" show color="primary" variant="indeterminate" />
					) : undefined
				}
				onClick={() => (step === 1 ? setStep(2) : handleSubmit(handleCreate)())}
				disabled={
					createUpdateDialog?.model
						? !isValid || (!isDirty && step === 2) || submitting || uploading
						: !isValid || !isDirty || submitting || uploading
				}
			>
				{step === 1 ? "Next" : createUpdateDialog?.model ? "Update Fundraiser" : "Create Fundraiser"}
			</StyledActionButton>
		),
		[createUpdateDialog?.model, handleSubmit, submitting, isDirty, isValid, loading, handleCreate, step, uploading]
	);

	const renderUploaderBlock = useCallback(
		(visible: "mobile" | "web") => (
			<UploaderWrapper
				className={clsx(
					visible === "mobile" && "hidden sm:block lg:hidden",
					visible === "web" && "block sm:hidden lg:block",
					"flex-shrink-0"
				)}
			>
				<Controller
					name="thumbnail"
					control={control}
					render={({ value, onChange }) => (
						<Uploader
							urls={value?.url ? [value.url] : undefined}
							onChange={(files: any) => onUploaderChange(files, onChange, true)}
							label="Upload Thumbnail"
							orientation={orientationConst.horizontal}
							width={"100%"}
							description={
								<>
									Drag and Drop File Here or <span className="anchor">Browse</span> to Choose a File
								</>
							}
							accept={[
								{
									fileType: "image/png, image/jpeg, image/svg+xml, image/x-eps",
									name: "png, jpg, svg, eps"
								}
							]}
							icon={<Icon group={"filled"} fill={"#c5cee0"} name={"image"} width={48} height={48} />}
						/>
					)}
				/>
			</UploaderWrapper>
		),
		[control, onUploaderChange]
	);

	const previewVideoBlock = useMemo(() => {
		const isYTVid = validateYoutube(videoUrl);
		const videoId = isYTVid && getVideoId(videoUrl);

		return (
			<div className="h-full relative">
				{isYTVid ? (
					<YouTube
						videoId={`${videoId}`}
						opts={{
							height: "246px",
							width: "100%",
							playerVars: {
								autoplay: 0
							}
						}}
						iframeClassName="rounded-lg"
					/>
				) : (
					<video controls className="w-full h-full rounded-lg">
						<source src={videoUrl} />
					</video>
				)}
				<div className="absolute -top-1 -right-1 h-full">
					<CloseButton
						onClick={e => {
							e.stopPropagation();
							setPreviewVideo(false);
							setValue("videoUrl", "");
						}}
						className="h-5 w-5 rounded-full"
					>
						<Icon name="close" />
					</CloseButton>
				</div>
			</div>
		);
	}, [setValue, videoUrl]);

	const stepOne = useMemo(
		() => (
			<div
				className={clsx(
					step === 1 ? "flex flex-col-reverse gap-4 items-start lg:flex-row lg:justify-between lg:gap-8" : "hidden"
				)}
			>
				<div className="w-full lg:flex-1">
					<div className="flex items-center gap-4">
						<UploaderWrapper className="full-w">
							<Controller
								name="videoUrl"
								control={control}
								rules={{
									validate: url => {
										if (showYTInput && url) {
											return isValidYoutubeUrl(url);
										}
										return true;
									}
								}}
								render={({ value, onChange }) =>
									previewVideo ? (
										previewVideoBlock
									) : (
										<Uploader
											urls={value ? [value] : undefined}
											onChange={(files: any) => onUploaderChange(files, onChange)}
											label="Upload Fundraiser Video"
											orientation={orientationConst.horizontal}
											width={"100%"}
											description={
												showYTInput ? (
													<div className="relative">
														<TextField
															variant="outlined"
															onClick={e => e.stopPropagation()}
															name="ytUrl"
															placeholder="Paste Youtube video URL here..."
															value={value}
															onChange={onChange}
															error={!!errors.videoUrl}
															helperText={errors.videoUrl?.message}
															InputProps={{
																startAdornment: <Icon name="link" group="filled" fill="#C5CEE0" className="mr-2" />
															}}
														/>
														<div className="absolute -top-1 -right-1 h-full">
															<CloseButton
																onClick={e => {
																	e.stopPropagation();
																	setShowYTInput(false);
																}}
																className="h-5 w-5 rounded-full"
															>
																<Icon name="close" />
															</CloseButton>
														</div>
													</div>
												) : (
													<>
														<span className="anchor">Browse</span> to choose a file or{" "}
														<ButtonBase
															onClick={e => {
																e.stopPropagation();
																setShowYTInput(true);
															}}
															className="anchor"
														>
															Click here
														</ButtonBase>{" "}
														<br /> to paste a video link.
													</>
												)
											}
											accept={[
												{
													fileType: "video/*",
													name: "mp4, mov"
												}
											]}
											icon={<Icon group={"filled"} fill={"#c5cee0"} name={"video"} width={64} height={64} />}
										/>
									)
								}
							/>
						</UploaderWrapper>
						{renderUploaderBlock("mobile")}
					</div>
					<FormControl>
						<Controller
							name="name"
							control={control}
							rules={
								step === 1
									? {
											required: "Name is required",
											maxLength: { value: 40, message: "Name should not exceed 40 characters" },
											minLength: { value: 3, message: "At least 3 characters are required" }
									  }
									: undefined
							}
							render={({ onChange, value }) => (
								<TextField
									value={value}
									onChange={onChange}
									label="Fundraiser name*"
									variant="outlined"
									error={errors?.name?.message}
									helperText={errors?.name?.message}
									onFocus={scrollToXPositionOnFocus}
								/>
							)}
						/>
					</FormControl>
					<FormControl>
						<Controller
							name="groupIds"
							variant="outlined"
							rules={step === 1 ? { required: "Group is required!", validate: value => !!value?.length } : undefined}
							control={control}
							render={({ onChange, value }) => (
								<>
									<Autocomplete
										multiple
										getOptionLabel={option => (typeof option === "string" ? option : option.value)}
										options={groupList}
										autoComplete
										disableClearable
										renderTags={() => <></>}
										ListboxComponent={({ children, ...props }) => (
											<List {...props} className={clsx(props.className, "pb-0")}>
												{children}
												<div
													onClick={() => setCreateGroupModal(true)}
													className="sticky bg-white z-20 border-t border-[#C5CEE0] bottom-0 left-0 w-full"
												>
													<ButtonBase className="flex items-center gap-2 justify-start p-3 w-full">
														<Icon name="plus" height={16} width={16} />
														<Text variant="body2" className="text-[#6173FE]">
															Add New Group
														</Text>
													</ButtonBase>
												</div>
											</List>
										)}
										fullWidth
										includeInputInList
										filterSelectedOptions
										value={value}
										onChange={(_, newValue) => onChange(newValue)}
										onInputChange={(_, newInputValue) => setKeyword(newInputValue)}
										renderInput={params => (
											<TextField
												{...params}
												label="Select group*"
												variant="outlined"
												placeholder="Select group*"
												fullWidth
												error={errors?.parentGroup?.message}
												helperText={errors?.parentGroup?.message}
											/>
										)}
										onFocus={scrollToXPositionOnFocus}
										id="selectParentGroup"
									/>
									<div className="flex items-center gap-2 flex-wrap mt-4">
										{value?.map((v, i) => (
											<Chip
												key={i}
												variant="default"
												label={v.value}
												onDelete={() => onChange(value.filter((_, index) => index !== i))}
											/>
										))}
									</div>
								</>
							)}
						/>
					</FormControl>
				</div>
				{renderUploaderBlock("web")}
			</div>
		),
		[
			step,
			control,
			renderUploaderBlock,
			showYTInput,
			previewVideo,
			previewVideoBlock,
			errors.videoUrl,
			errors?.name?.message,
			errors?.parentGroup?.message,
			onUploaderChange,
			scrollToXPositionOnFocus,
			groupList,
			setCreateGroupModal
		]
	);

	const getMinDate = useCallback(
		(type: "startDate" | "endDate") => {
			if (type === "startDate") {
				return DateTime.now();
			} else {
				return createUpdateDialog?.model
					? DateTime.now().plus({ day: 1 })
					: startDate
					? DateTime.fromJSDate(startDate).plus({ day: 1 })
					: DateTime.now().plus({ day: 1 });
			}
		},
		[createUpdateDialog?.model, startDate]
	);

	const getMaxDate = useCallback(
		(type: "startDate" | "endDate") => {
			if (type === "startDate") {
				return createUpdateDialog?.model
					? DateTime.now().plus({ years: 5 })
					: endDate
					? DateTime.fromJSDate(endDate).minus({ day: 1 })
					: DateTime.now().plus({ years: 5 });
			} else {
				return createUpdateDialog?.model
					? DateTime.now().plus({ years: 5 })
					: startDate
					? DateTime.fromJSDate(startDate).plus({ years: 5 })
					: DateTime.now().plus({ years: 5 });
			}
		},
		[createUpdateDialog?.model, endDate, startDate]
	);

	const stepTwo = useMemo(
		() => (
			<div className={clsx(step === 2 ? "flex flex-col gap-7" : "hidden")}>
				<Controller
					name="goalAmount"
					control={control}
					rules={step === 2 ? { required: "Amount is required" } : undefined}
					render={({ onChange, value }) => (
						<TextField
							InputProps={{
								startAdornment: <MonetizationOnIcon htmlColor="#8F9BB3" className="mr-1" />
							}}
							placeholder="Target amount"
							id="amount"
							error={errors.goalAmount}
							helperText={errors.goalAmount?.message}
							name="amount"
							onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
								const replacedValue = Number(e.target.value.replace(/\D/g, "") || 0);
								onChange(replacedValue ? formatMoney(replacedValue) : "");
							}}
							value={value}
							variant="outlined"
						/>
					)}
				/>
				<div className="flex flex-col gap-3">
					<div className="w-full flex items-center gap-2">
						<Controller
							name="startDate"
							rules={step === 2 ? { required: "Start Date is required" } : undefined}
							control={control}
							render={({ onChange, value = null }) => (
								<DatePicker
									placeholder="Start Date"
									autoOk
									InputProps={{
										startAdornment: (
											<Icon className="mr-2" name="calendar" fill="#8F9BB3" group="filled" width={24} height={24} />
										)
									}}
									minDate={getMinDate("startDate")}
									maxDate={getMaxDate("startDate")}
									inputVariant="outlined"
									variant="inline"
									value={value}
									onChange={d => onChange(d)}
									error={!!errors.startDate}
									helperText={errors.startDate?.message}
									className="flex-1"
								/>
							)}
						/>
						<Controller
							name="startTime"
							rules={step === 2 ? { required: "Start Time is required" } : undefined}
							control={control}
							render={({ onChange, value = null }) => (
								<TimePicker
									InputProps={{
										startAdornment: (
											<Icon className="mr-2" name="clock" fill="#8F9BB3" group="filled" width={24} height={24} />
										)
									}}
									placeholder="Select Time"
									autoOk
									inputVariant="outlined"
									variant="inline"
									value={value}
									onChange={d => onChange(d)}
									error={!!errors.startTime}
									helperText={errors.startTime?.message}
									className="w-40"
								/>
							)}
						/>
					</div>
					<div className="w-full flex items-center gap-2">
						<Controller
							name="endDate"
							rules={step === 2 ? { required: "End Date is required" } : undefined}
							control={control}
							render={({ onChange, value = null }) => (
								<DatePicker
									placeholder="End Date"
									autoOk
									InputProps={{
										startAdornment: (
											<Icon className="mr-2" name="calendar" fill="#8F9BB3" group="filled" width={24} height={24} />
										)
									}}
									minDate={getMinDate("endDate")}
									maxDate={getMaxDate("endDate")}
									inputVariant="outlined"
									variant="inline"
									value={value}
									onChange={d => onChange(d)}
									error={!!errors.endDate}
									helperText={errors.endDate?.message}
									className="flex-1"
								/>
							)}
						/>
						<Controller
							name="endTime"
							rules={step === 2 ? { required: "End Time is required" } : undefined}
							control={control}
							render={({ onChange, value = null }) => (
								<TimePicker
									InputProps={{
										startAdornment: (
											<Icon className="mr-2" name="clock" fill="#8F9BB3" group="filled" width={24} height={24} />
										)
									}}
									placeholder="Select Time"
									autoOk
									inputVariant="outlined"
									variant="inline"
									value={value}
									onChange={d => onChange(d)}
									error={!!errors.endTime}
									helperText={errors.endTime?.message}
									className="w-40"
								/>
							)}
						/>
					</div>
				</div>
				<Controller
					name="description"
					control={control}
					rules={
						step === 2
							? {
									required: "Description is required",
									minLength: { value: 10, message: "At least 10 characters are required" },
									maxLength: { value: 2000, message: "Maximum 2000 characters are allowed" }
							  }
							: undefined
					}
					render={({ onChange, value }) => (
						<TextField
							id="description"
							error={errors.description}
							helperText={errors.description?.message}
							name="description"
							onChange={onChange}
							value={value}
							label="Description*"
							variant="outlined"
							multiline
							rows={3}
							onFocus={scrollToXPositionOnFocus}
							className="pb-16"
						/>
					)}
				/>
			</div>
		),
		[control, errors, scrollToXPositionOnFocus, step, getMinDate, getMaxDate]
	);

	const {
		handleLeavePageConfirmed,
		closeConfirmPopup,
		handleClose,
		getData: getConfirmLeavePopupData
	} = useConfirmLeavePopup({
		onClose,
		open: true
	});

	const { showConfirmPopup } = getConfirmLeavePopupData();

	return (
		<>
			<ConfirmLeavePopup
				handleLeavePage={handleLeavePageConfirmed}
				open={showConfirmPopup}
				onClose={closeConfirmPopup}
				popup
			/>
			<Dialog
				title={Title}
				hasBackButton={step === 2}
				onBack={() => setStep(1)}
				open
				onClose={() => handleClose(isDirty)}
				footer={ActionButton}
				bodyCustomStyles={"padding: 0;"}
				bodyRef={bodyRef}
				maxWidth="md"
				customWidth={step === 1 ? 800 : 520}
			>
				<UploadProgressBarWrapper>
					<UploadProgressBar
						progress={uploadingProgress}
						successText={"Your item has been successfully uploaded!"}
						successTimeout={5500}
					/>
				</UploadProgressBarWrapper>
				<div className="py-3 px-6 lg:py-6 lg:px-10">
					{stepOne}
					{stepTwo}
				</div>
			</Dialog>
			<CreateGroup
				open={createGroupModal}
				onClose={({ group }) => {
					setCreateGroupModal(false);
					if (group) {
						setValue("groupIds", [...(groupIds || []), { key: group._id, value: group.name }], {
							shouldDirty: true,
							shouldValidate: true
						});
					}
				}}
			/>
		</>
	);
};

export default CreateFundraiserManageView;
