import React, { useCallback, useEffect, useState } from "react";

import firebase from "firebase";

import { useFirebaseLiveConversation } from "modules/LiveConversation/Data/hooks";

import Reaction, { ReactionType } from "./Reaction";

import { ReactionWrapper } from "./style";

const getRandomNumber = (min, max) => Math.random() * (max - min) + min;

const Reactions = () => {
	const [reactions, setReactions] = useState<ReactionType[]>([]);

	const { watchReactions, removeReaction } = useFirebaseLiveConversation();

	const doRemoveReaction = useCallback(
		id => {
			setReactions(prevReactions => {
				return prevReactions.filter(reaction => reaction.id !== id);
			});
			removeReaction(id);
		},
		[removeReaction]
	);

	const onReaction = (snap: firebase.database.DataSnapshot) => {
		const data = snap.val();
		if (data) {
			setReactions((prevReactions: ReactionType[]) => {
				const reaction: ReactionType = {
					id: snap.key,
					reaction: snap.val().reaction,
					right: getRandomNumber(10, 50)
				};
				return [...prevReactions, reaction];
			});
		}
	};

	useEffect(() => {
		const removeListener = watchReactions(onReaction);
		return removeListener;
	}, [watchReactions]);

	return (
		<ReactionWrapper>
			{reactions.map(reaction => (
				<Reaction key={reaction.id} {...reaction} onComplete={doRemoveReaction} />
			))}
		</ReactionWrapper>
	);
};

export default Reactions;
